import { ref } from "vue";

export default function useCalculation() {
  const round_digit = ref(2);
  /**
   * Round number to specify digit.
   *
   * @param value Input number.
   * @param digit number of decimal places (default = 2 from round_digit param the default value might be change by any user)
   * @returns Rounded number.
   */
  const round = function (value = 0, digit = round_digit) {
    return parseFloat((+(Math.round(+(value + 'e' + digit.value)) + 'e' + -digit.value)).toFixed(digit.value));
    // return parseFloat(value.toFixed(digit.value));
  };
  /**
   * Sum up array of number with automatic rounding.
   *
   * @param numbers Array of number.
   * @param digit number of decimal places (default = 2 from round_digit param the default value might be change by any user)
   * @returns Summarize number.
   */
  const sum = function (numbers = [0], digit = round_digit) {
    let result = 0;
    numbers.forEach((element) => {
      result = round(result + element, digit);
    });
    return result;
  };

  /**
   * subtract 2 number with automatic rounding.
   *
   * @param n1 first number.
   * @param n2 second number.
   * @param digit number of decimal places (default = 2 from round_digit param the default value might be change by any user)
   * @returns Summarize number.
   */
  const subtract = function (n1 = 0, n2 = 0, digit = round_digit) {
    let result = round(n1 - n2, digit);
    return result;
  }

  const ruleOfThree = (total = 0, part = 0, base = 0) => {
    const product = part * total;
    const roundedProduct = round(product);

    const roundedSum = round(base);

    const result = roundedProduct / roundedSum;
    const roundedResult = Math.round(result * 100) / 100;

    return roundedResult;
  }

  return {
    round,
    sum,
    subtract,
    ruleOfThree
  };
}
