export default {
  setDbdBusinessType(state, payload) {
    let dbdBusinessTypeList = [...state.dbdBusinessType.dbdBusinessTypeList];
    for (const dbdBusinessType of payload.dbdBusinessTypeList) {
      if (dbdBusinessTypeList.findIndex((c) => c.id == dbdBusinessType.id) < 0) {
        dbdBusinessTypeList.push(dbdBusinessType);
      }
    }
    state.dbdBusinessType.dbdBusinessTypeList = dbdBusinessTypeList;
    state.dbdBusinessType.currentPage = payload.currentPage;
    state.dbdBusinessType.totalPage = payload.totalPage;
  },
  resetDbdBusinessType(state) {
    const dbdBusinessType = {
      dbdBusinessTypeList: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.dbdBusinessType = dbdBusinessType;
  },
};
