<template>
  <PvDialog
    :breakpoints="{ '1280px': '80vw', '640px': '80vw' }"
    :style="{ width: '80vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="lang.notificationDialogHeader"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
    ></base-dialog>

    <template #header>
      <div class="flex flex-wrap justify-content-between align-items-right p-0">
        <div class="p-0">
          <label class="font-lg">{{ lang.itemsGuarantee }}</label>
        </div>
        <div>
          <PvButton
            icon="pi pi-times"
            class="p-button-link p-icon-secondary pb-0 pt-0"
            style="width: 15px"
            @click="closeDialog()"
          />
        </div>
      </div>
      <PvDivider></PvDivider>

      <div
        class="flex flex-wrap justify-content-between align-items-center pb-3 pt-1"
      >
        <div class="flex lg:col-6 md:col-6 col-6 p-0">
          <span class="p-input-icon-left w-full">
            <i class="pi pi-search" />
            <PvInputText
              class="inputfield w-full"
              v-model="searchText"
              :placeholder="lang.searchTextPlaceholder"
              @keyup.enter="filterGuarantee"
              autofocus
            />
          </span>

          <PvButton class="ml-2" style="width: 6rem;" @click="filterGuarantee">
            <span class="font-normal" style="width: 6rem;">{{ lang.search }}</span>
          </PvButton>
        </div>

        <PvButton class="ml-2" @click="selectGuarantee">
          <icon-documents-file-checkmark iconColor="white" />
          <span class="font-normal w-full ml-1">{{ lang.selectedConfirm }}</span>
        </PvButton>
      </div>
    </template>

    <div class="p-datatable-border my-2">
      <PvDataTable
        :value="filteredGuaranteeList"
        v-model:selection="selectedGuarantee"
        dataKey="receiptDetailId"
        :class="textTableClass"
        :loading="isLoading"
        :scrollable="true"
        :scrollHeight="halfViewportHeight + 'px'"
        selectionMode="single"
        @row-dblclick="selectGuarantee"
      >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
        <PvColumn selectionMode="single" style="flex: 0; height: 56px" />

        <PvColumn
          field="receiptCode"
          :header="lang.code"
          style="min-width: 9rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
        />

        <PvColumn
          field="receiptDate"
          :header="lang.date"
          style="min-width: 4rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
        >
          <template #body="slotProps">
            {{ formatDate(slotProps.data.receiptDate) }}
          </template>
        </PvColumn>

        <PvColumn
          field="productCode"
          :header="lang.productCode"
          style="min-width: 2rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
        />

        <PvColumn
          field="productName"
          :header="lang.productName"
          style="min-width: 15rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
        />

        <PvColumn
          field="amountLeft"
          :header="lang.balanceAmount"
          style="min-width: 3rem; height: 56px"
          headerClass="h-right font-normal"
          bodyClass="text-right font-normal"
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.amountLeft) }}
          </template>
        </PvColumn>
      </PvDataTable>
    </div>

    <template #footer></template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  emits: ["close", "guarantee-selected"],
  setup() {
    const { lang } = useLang();
    const { textTableClass, } = useTextStyle();
    const { formatCurrency, formatDateToISOString, formatDate } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.receipt.listing,
          ...lang.th.ar.receipt.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.receipt.listing,
          ...lang.en.ar.receipt.edit,
        };
      }
    });

    return {
      lang: mergeLang,

      textTableClass,

      formatCurrency,
      formatDateToISOString,
      formatDate,
    };
  },
  data() {
    return {
      isLoading: false,
      searchText: "",
      selectedGuarantee: null,
      filteredGuarantee: [],
      isFilter: false,

      showValidateDialog: false,
      validateWording: this.lang.validateNoDetailSelect,
      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "w-full p-button-outlined p-button-secondary",
          action: "confirm",
        },
      ],
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    initiateData() {
      this.isLoading = false;
      this.searchText = "";
      this.selectedGuarantee = null;
      this.filteredGuarantee = [];
      this.isFilter = false;
    },
    filterGuarantee() {
      if (!this.searchText) {
        this.filteredGuarantee = [...this.guaranteeList];
        this.isFilter = false;
      } else {
        this.filteredGuarantee = this.guaranteeList.filter((guarantee) => {
          return (
            guarantee.receiptCode.toLowerCase() +
            guarantee.productCode.toLowerCase() +
            guarantee.productName.trim().toLowerCase() +
            guarantee.receiptDate
          ).includes(this.searchText.toLowerCase());
        });
        this.isFilter = true;
      }
      this.selectedPending = [];
    },
    selectGuarantee() {
      if (!this.selectedGuarantee) {
        this.showValidateDialog = true;
        return;
      }
      this.$emit("guarantee-selected", this.selectedGuarantee);
      this.closeDialog();
    },
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    availableGuarantee() {
      return this.$store.getters["arGuarantee/availableGuaranteeByUnit"];
    },
    guaranteeList() {
      let result = [];
      if (this.availableGuarantee) {
        result = this.availableGuarantee.guaranties;
        result = result.filter((data) => data.amountLeft > 0);
      }
      return result;
    },
    filteredGuaranteeList() {
      if (!this.isFilter) {
        return this.guaranteeList;
      } else {
        return this.filteredGuarantee;
      }
    },
  },
};
</script>
