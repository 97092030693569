export default {
	setProductAps(state, payload) {
        state.productAps = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
	},
  	// setProductAp(state, payload) {
	// 	let productAps = [...state.productAps];
	// 	for (const productAp of payload.productAps) {
	// 		if (productAps.findIndex((c) => c.id === productAp.id) < 0) {
	// 			productAps.push(productAp);
	// 		}
	// 	}
	// 	state.productAps = productAps;
	// 	state.currentPage = payload.currentPage;
	// 	state.totalPage = payload.totalPage;
	// },
	
	setProductApById(state, payload) {
		state.productAp = payload;
	},
	resetProductAp(state) {
	  state.productAp = null;
	  state.totalPage = 0;
	  state.currentPage = 0;
	},
  	resetProductAps(state) {
		state.productAps = [];
		state.totalPage = 0;
		state.currentPage = 0;
  	},
};