<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoSave="checkBeforeSave"
    @gotoCreate="askForCreate"
    @gotoDelete="askForDelete"
  >
  </base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="titleConfirm"
      :classTitle="baseDialogClassTitle"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      :isVisibleCloseButton="isVisibleCloseButton"
      @confirm="confirmBaseDialog"
      @close="closeBaseDialog"
      @goto-create="createData"
      @goto-delete="deleteData"
      @goto-list="goBack"
    >
    </base-dialog>

    <div class="surface-card shadow-2 p-5 border-round my-5">
      <div class="flex justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.containerTitle }}</label>
      </div>

      <PvDivider></PvDivider>

      <base-card
        :titleHeader="this.lang.generalInformation"
        :isVisibleExpandButton="false"
        :isHideBackground="true"
        :isVisibleCard="true"
      >
        <section class="flex flex-wrap fontlabel" :class="textFormClass">
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="code">{{ lang.labelCode + " *" }}</label>
            <PvInputText
              id="code"
              type="text"
              class="inputfield w-full mt-2"
              v-model="code"
              :placeholder="lang.placeholderCode"
              :class="{ 'p-invalid': isCodeError }"
              @keyup="isCodeError = false"
              @blur="isCodeError = false"
            />
            <small v-if="isCodeError" id="isCodeError-help" class="p-error">
              {{ massageIsCodeError }}
            </small>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="taxId">{{ lang.labelTaxId }}</label>
            <PvInputText
              id="taxId"
              type="text"
              class="inputfield w-full mt-2"
              :placeholder="lang.placeholderTaxId"
              v-model.trim="taxId"
              maxlength="13"
              :class="{ 'p-invalid': isTaxIdError }"
              @keyup="isTaxIdError = false"
              @blur="isTaxIdError = false"
            />
            <!-- เอาออกเนื่องจากลูกค้าไม่ต้องการให้ validate 
              @keyup="checkTaxId()"
              @blur="checkTaxId()" -->
            <small v-if="isTaxIdError" id="isTaxIdError-help" class="p-error">
              {{ massageIsTaxIdError }}
            </small>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="name">{{ lang.labelName + " *" }}</label>
            <PvInputText
              id="name"
              type="text"
              class="inputfield w-full mt-2"
              v-model="name"
              :placeholder="lang.placeholderName"
              :class="{ 'p-invalid': isNameError }"
              @keyup="isNameError = false"
              @blur="isNameError = false"
            />
            <small v-if="isNameError" id="isNameError-help" class="p-error">
              {{ lang.validateName }}
            </small>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="gender">{{ lang.labelGender }}</label>
            <PvDropdown
              id="gender"
              class="inputfield w-full mt-1"
              :options="itemGenders"
              optionLabel="name"
              optionValue="code"
              v-model="selectedGender"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.businessType }}</label>
            <base-dbd-business-type-auto-complete
              id="dbdBusinessType"
              v-model="selectedDbdBusinessType"
              class="inputfield w-full mt-2"
              @load-finish="dbdBusinessTypeDataReady"
							:isLoadingData="true" 
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4"></div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="address1">{{ lang.labelAddress1 }}</label>
            <PvInputText
              id="address1"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="address1"
              :placeholder="lang.placeholderAddress1"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="address2">{{ lang.labelAddress2 }}</label>
            <label for="address2" class="p-text-secondary"> *{{ lang.labelAddress2Remark }}</label>
            <PvInputText
              id="address2"
              type="text"
              class="inputfield w-full mt-1 p-inputtext-disabled"
              v-model.trim="address2"
              :placeholder="lang.placeholderAddress2"
              :disabled="true"
            />
          </div>
          
          <div class="flex flex-wrap col-12 p-0">
            <base-address-selector 
              :key="keyReload" 
              :defaultValue="addressDefault"
              :isAddressError="isAddressError"
							:isLoadingData="true" 
              @addressDataReady="isSharedAddressReady = true"
              @itemSelected="setDataAddress">
            </base-address-selector>
          </div>

          <!-- <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="address3">{{ lang.labelAddress3 }}</label>
            <PvInputText
              id="address3"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="address3"
              :placeholder="lang.placeholderAddress3"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="postCode">{{ lang.labelPostCode }}</label>
            <PvInputText
              id="postCode"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="postCode"
              :placeholder="lang.placeholderPostCode"
            />
          </div> -->

          <div class="lg:col-2 md:col-2 col-6 pl-0 pr-2 pt-3">
            <label>{{ lang.countryCode }}</label>
            <base-country-code-auto-complete
              id="telephoneCountryCode"
              v-model="selectedTelephoneCountryCode"
              class="inputfield w-full mt-1"
              @load-finish="countryCodeDataReady"
							:isLoadingData="true" 
              @item-select="selectTelephoneCountryCode"
            />
          </div>
          <div class="lg:col-4 md:col-4 col-6 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="telephoneNumber">{{ lang.labelTelephoneNumber }}</label>
            <PvInputText
              id="telephoneNumber"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="telephoneNumber"
              :placeholder="lang.placeholderTelephoneNumber"
            />
          </div>

          <div class="lg:col-2 md:col-2 col-6 pl-0 pr-2 pt-3">
            <label>{{ lang.countryCode }}</label>
            <base-country-code-auto-complete
              id="mobileCountryCode"
              v-model="selectedMobileCountryCode"
              class="inputfield w-full mt-1"
              @item-select="selectMobileCountryCode"
            />
          </div>
          <div class="lg:col-4 md:col-4 col-6 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="mobileNumber">{{ lang.labelMobileNumber }}</label>
            <PvInputText
              id="mobileNumber"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="mobileNumber"
              :placeholder="lang.placeholderMobileNumber"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="faxNumber">{{ lang.labelFaxNumber }}</label>
            <PvInputText
              id="faxNumber"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="faxNumber"
              :placeholder="lang.placeholderFaxNumber"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="email">{{ lang.labelEmail }}</label>
            <PvInputText
              id="email"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="email"
              :placeholder="lang.placeholderEmail"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <div class="row flex align-items-center pt-2">
              <PvCheckbox
                id="selectedIsCustomer"
                v-model="selectedIsCustomer"
                :binary="true"
              />
              <label class="pl-3">{{ lang.labelIsCustomer }}</label>
            </div>
          </div>
        </section>
      </base-card>

      <base-card
        :titleHeader="this.lang.contactInformation"
        :isVisibleExpandButton="false"
        :isHideBackground="true"
        :isVisibleCard="true"
      >
        <section class="flex flex-wrap fontlabel" :class="textFormClass">
          <div class="lg:col-2 md:col-2 col-6 pl-0 pr-2 pt-3">
            <label>{{ lang.prefix }}</label>
            <base-prefix-auto-complete
              id="emergencyContactPrefix"
              v-model="emergencyContactPrefix"
              class="inputfield w-full mt-1"
            />
          </div>
          <div class="lg:col-4 md:col-4 col-6 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="emergencyContactName">{{ lang.labelEmergencyContactName }}</label>
            <PvInputText
              id="emergencyContactName"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="emergencyContactName"
              :placeholder="lang.placeholderEmergencyContactName"
            />
          </div>

          <div class="lg:col-2 md:col-2 col-6 pl-0 pr-2 pt-3">
            <label>{{ lang.countryCode }}</label>
            <base-country-code-auto-complete
              id="emergencyContactMobileCountryCode"
              v-model="selectedEmergencyContactMobileCountryCode"
              class="inputfield w-full mt-1"
              @item-select="selectEmergencyContactMobileCountryCode"
            />
          </div>
          <div class="lg:col-4 md:col-4 col-6 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="emergencyContactMobile">{{ lang.labelTelephoneNumber }}</label>
            <PvInputText
              id="emergencyContactMobile"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="emergencyContactMobile"
              :placeholder="lang.placeholderTelephoneNumber"
            />
          </div>
        </section>
      </base-card>

      <base-card
        :titleHeader="this.lang.accountingData"
        :isVisibleExpandButton="false"
        :isHideBackground="true"
        :isVisibleCard="true"
      >
        <section class="flex flex-wrap fontlabel" :class="textFormClass">
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="supplierCreditTerm">{{
              lang.labelSupplierCreditTerm
            }}</label>
            <PvInputNumber
              id="supplierCreditTerm"
              mode="decimal"
              class="inputfield w-full mt-1"
              v-model.trim="supplierCreditTerm"
              placeholder="0"
              inputStyle="color: #6E7073"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="bankAccountNumber">{{
              lang.labelBankAccountNumber
            }}</label>
            <PvInputText
              id="bankAccountNumber"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="bankAccountNumber"
              :placeholder="lang.placeholderBankAccountNumber"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="withholdingTaxRate">{{
              lang.labelWithholdingTaxRate
            }}</label>
            <PvInputNumber
              id="withholdingTaxRate"
              mode="decimal"
              class="inputfield w-full mt-1"
              v-model.trim="withholdingTaxRate"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              placeholder="0.00"
              inputStyle="color: #6E7073"
              :min="0"
              :max="100"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="bank">{{ lang.labelBank }}</label>
            <base-bank-auto-complete
              id="bank"
              v-model="selectedBank"
              class="inputfield w-full mt-1"
              @load-finish="bankDataReady"
              :placeholder="lang.validateBank"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="vatRate">{{ lang.labelVatRate }}</label>
            <PvInputNumber
              id="vatRate"
              mode="decimal"
              class="inputfield w-full mt-1"
              v-model.trim="vatRate"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              placeholder="0.00"
              inputStyle="color: #6E7073"
              :min="0"
              :max="100"
            />
          </div>
        </section>
      </base-card>

      <!-- <base-card
        :titleHeader="this.lang.cardTitle3"
        :isVisibleExpandButton="false"
        :isHideBackground="true"
        :isVisibleCard="true"
      >
        <section class="flex flex-wrap fontlabel" :class="textFormClass">
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="contactName">{{ lang.labelContactName }}</label>
            <PvInputText
              id="contactName"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="contactName"
              :placeholder="lang.validateContactName"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="contactAddress1">{{ lang.labelContactAddress1 }}</label>
            <PvInputText
              id="contactAddress1"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="contactAddress1"
              :placeholder="lang.validateContactAddress1"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="contactAddress2">{{ lang.labelContactAddress2 }}</label>
            <PvInputText
              id="contactAddress2"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="contactAddress2"
              :placeholder="lang.validateContactAddress2"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="contactAddress3">{{ lang.labelContactAddress3 }}</label>
            <PvInputText
              id="contactAddress3"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="contactAddress3"
              :placeholder="lang.validateContactAddress3"
            />
          </div>
        </section>
      </base-card> -->
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject, ref } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import usePageDirty from "../../../hooks/pageDirty.js"
import useCommon from "../../../constant/common.js"
export default {
  props: ["id", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString, formatSubdistrict } = useFormat();
    const isHideBack = inject("isHideBack");
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();
    const { compareObject, isCompareObject } = usePageDirty();
    const prefixes = ref(useCommon.CUSTOMER_PREFIX);

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.supplier.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.supplier.edit,
        };
      }
    });
    return {
      lang: mergeLang,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      compareObject,
      isCompareObject,
      isHideBack,
      formatSubdistrict,
      prefixes,
    };
  },
  data() {
    return {
      objToCompare: null,
      storageName: "",
      isSharedBankReady: false,

      code: "",
      isCodeError: false,
      massageIsCodeError: "",
      name: "",
      isNameError: false,
      massageIsNameErrorError: "",
      taxId: "",
      isTaxIdError: false,
      massageIsTaxIdError: "",
      selectedGender: "Company",
      itemGenders: [
        { name: this.lang.itemGender1, code: "Company" },
        { name: this.lang.itemGender2, code: "Male" },
        { name: this.lang.itemGender3, code: "Female" },
      ],
      address1: "",
      address2: "",
      address3: "",
      postCode: "",
      telephoneNumber: "",
      mobileNumber: "",
      faxNumber: "",
      email: "",
      selectedIsCustomer: false,
      supplierCreditTerm: 0,
      bankAccountNumber: "",
      withholdingTaxRate: 3,
      selectedBank: null,
      vatRate: 7,
      contactName: "",
      contactAddress1: "",
      contactAddress2: "",
      contactAddress3: "",
      selectedDbdBusinessType: null,
      isSharedDbdBusinessTypeReady: false,
      selectedTelephoneCountryCode: "",
      selectedMobileCountryCode: "",
      isSharedCountryCodeReady: false,
      emergencyContactPrefix: "",
      emergencyContactName: "",
      selectedEmergencyContactMobileCountryCode: "",
      emergencyContactMobile: "",

      //dialog
      titleConfirm: this.lang.notificationDialogHeader,
      showValidateDialog: false,
      baseDialogClassTitle: "",
      validateWording: "",
      isVisibleCloseButton: false,
      isCreateNewDocument: false,
      isCreateDialog: false,
      isDeleteDialog: false,
      isDeletedDialog: false,

      isSharedAddressReady: false,
      addressDefault: {
        province: "",
        district: "",
        subdistrict: "",
        postCode: "",
      },
      keyReload: 0,
      addressDistrict: "",
      addressSubDistrict: "",
      isAddressError: {
        province: false,
        district: false,
        subdistrict: false,
        postCode: false,
      },
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    if (!this.isCreateDocument) {
      await this.getSupplier();
    }
    await this.checkShouldSetData();
    this.storageName = `erp_search_suppliers_${this.selectedProject.code}_${this.userCode}`;
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$store.dispatch("supplier/resetSupplier");
      const localSearch = localStorage.getItem(this.storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "Entry",
      };
      localStorage.setItem(this.storageName, JSON.stringify(search));
      this.$router.push({name: "supplier"});
    },
    validTaxId(id) {
      if (id.length != 13) return false;
      let i = 0;
      let sum = 0;
      for (i = 0, sum = 0; i < 12; i++) {
        sum += parseInt(id.charAt(i)) * (13 - i);
      }
      let mod = sum % 11;
      let check = (11 - mod) % 10;
      if (check == parseInt(id.charAt(12))) {
        return true;
      }
      return false;
    },
    checkTaxId() {
      this.isTaxIdError = false;
      let tax = this.taxId;
      if (tax) {
        if (tax.length == 13) {
          if (!this.validTaxId(tax)) {
            this.isTaxIdError = true;
            this.massageIsTaxIdError = this.lang.validateTaxIdIsNotValid;
          } else {
            this.isTaxIdError = false;
          }
        } 
      }
    },
    bankDataReady() {
      this.isSharedBankReady = true;
      this.checkShouldSetData();
    },
    async checkShouldSetData() {
      if (this.isSharedRoleByMenuReady && 
          this.isSharedBankReady && 
          this.isSharedAddressReady && 
          this.isSharedDbdBusinessTypeReady && 
          this.isSharedCountryCodeReady
        ) {
        if (this.isCreateDocument) {
        //set default country code
        let defaultCountryCode = this.findCountryCode("66");
        this.selectedTelephoneCountryCode = defaultCountryCode;
        this.selectedMobileCountryCode = defaultCountryCode;
        this.selectEmergencyContactMobileCountryCode = defaultCountryCode;
          this.setLoading(false);
        } else if (this.supplier) {
          this.setData();
        }
      }
      this.setCompareData();
    },
    async getSupplier() {
      try {
        await this.$store.dispatch("supplier/getSupplier", this.id);
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
        this.$router.replace({name: "supplier-edit", params: { id: "create" }});
      }
    },
    setData() {
      this.setLoading(true);
      this.code = this.supplier.code ? this.supplier.code.trim() : "";
      this.taxId = this.supplier.taxId ? this.supplier.taxId.trim() : "";
      this.name = this.supplier.name ? this.supplier.name.trim() : "";
      this.selectedGender = this.supplier.gender;
      this.address1 = this.supplier.address1
        ? this.supplier.address1.trim()
        : "";
      this.address2 = this.supplier.address2
        ? this.supplier.address2.trim()
        : "";
      this.address3 = this.supplier.address3
        ? this.supplier.address3.trim()
        : "";
        
      this.postCode = this.supplier.postCode
        ? this.supplier.postCode.trim()
        : "";
      this.addressDistrict = this.supplier.addressDistrict
        ? this.supplier.addressDistrict.trim()
        : "";
      this.addressSubDistrict = this.supplier.addressSubDistrict
        ? this.supplier.addressSubDistrict.trim()
        : "";

      this.addressDefault = {
        province: this.address3,
        district: this.addressDistrict,
        subdistrict: this.addressSubDistrict,
        postCode: this.postCode,
      }
        
      this.telephoneNumber = this.supplier.telephoneNumber
        ? this.supplier.telephoneNumber.trim()
        : "";
      this.mobileNumber = this.supplier.mobileNumber
        ? this.supplier.mobileNumber.trim()
        : "";
      this.faxNumber = this.supplier.faxNumber
        ? this.supplier.faxNumber.trim()
        : "";
      this.email = this.supplier.email ? this.supplier.email.trim() : "";
      this.selectedIsCustomer = this.supplier.isCustomer == "Y" ? true : false;
      this.supplierCreditTerm = this.supplier.supplierCreditTerm;
      this.bankAccountNumber = this.supplier.bankAccountNumber
        ? this.supplier.bankAccountNumber.trim()
        : "";
      this.withholdingTaxRate = this.supplier.withholdingTaxRate;
      this.selectedBank = this.sharedBanks.find(
        (bank) => bank.name === this.supplier.bank
      );
      this.vatRate = this.supplier.vatRate;
      this.contactName = this.supplier.contactName
        ? this.supplier.contactName.trim()
        : "";
      this.contactAddress1 = this.supplier.contactAddress1
        ? this.supplier.contactAddress1.trim()
        : "";
      this.contactAddress2 = this.supplier.contactAddress2
        ? this.supplier.contactAddress2.trim()
        : "";
      this.contactAddress3 = this.supplier.contactAddress3
        ? this.supplier.contactAddress3.trim()
        : "";

      this.selectedDbdBusinessType = this.sharedDbdBusinessTypes.find(
        (dbdBusinessType) => dbdBusinessType.id === this.supplier.dbdBusinessTypeId
      );
      this.selectedTelephoneCountryCode = this.findCountryCode(this.supplier.telephoneCountryCode);
      this.selectedMobileCountryCode = this.findCountryCode(this.supplier.mobileCountryCode);
      this.emergencyContactPrefix = this.prefixes.find(
        (prefix) => prefix === this.supplier.emergencyContactPrefix
      );
      this.emergencyContactName = this.supplier.emergencyContactName;
      this.selectedEmergencyContactMobileCountryCode = this.findCountryCode(this.supplier.emergencyContactMobileCountryCode);
      this.emergencyContactMobile = this.supplier.emergencyContactMobile;
      this.keyReload++;  
      this.setLoading(false);
    },
    setCompareData() {
      let ecPrefix = this.emergencyContactPrefix? this.emergencyContactPrefix:"";
      if (this.emergencyContactName && !this.emergencyContactPrefix) {
        ecPrefix = "คุณ";
      }

      let objToCompare = {
        projectId: this.selectedProject.id,
        code: this.code,
        name: this.name,
        taxId: this.taxId,
        gender: this.selectedGender,
        address1: this.address1,
        address2: this.address2,
        address3: this.address3,
        postCode: this.postCode,
        telephoneCountryCode: this.selectedTelephoneCountryCode,
        telephoneNumber: this.telephoneNumber,
        mobileCountryCode: this.selectedMobileCountryCode,
        mobileNumber: this.mobileNumber,
        faxNumber: this.faxNumber,
        email: this.email,
        isCustomer: this.selectedIsCustomer ? "Y" : "N",
        supplierCreditTerm: this.supplierCreditTerm,
        bankAccountNumber: this.bankAccountNumber,
        withholdingTaxRate: this.withholdingTaxRate,
        bank: this.selectedBank ? this.selectedBank.name : "",
        vatRate: this.vatRate,
        contactName: this.contactName,
        contactAddress1: this.contactAddress1,
        contactAddress2: this.contactAddress2,
        contactAddress3: this.contactAddress3,
        addressDistrict: this.addressDistrict,
        addressSubDistrict: this.addressSubDistrict,
        type: "Add",
        isIgnoreValidateAddress: false,
        dbdBusinessTypeId: this.selectedDbdBusinessType? this.selectedDbdBusinessType.id:null,
        emergencyContactPrefix: ecPrefix,
        emergencyContactName: this.emergencyContactName,
        emergencyContactMobileCountryCode: this.selectedEmergencyContactMobileCountryCode,
        emergencyContactMobile: this.emergencyContactMobile,
      };
      
      if (!this.isCreateDocument) {
        objToCompare = { ...objToCompare, id: this.id, type: "Update" };
      }
      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
    },
    async initiateData() {
      this.code = "";
      this.isCodeError = false;
      this.name = "";
      this.isNameError = false;
      this.taxId = "";
      this.isTaxIdError = false;
      this.selectedGender = "Company";
      this.address1 = "";
      this.address2 = "";
      this.address3 = "";
      this.postCode = "";
      this.telephoneNumber = "";
      this.mobileNumber = "";
      this.faxNumber = "";
      this.email = "";
      this.selectedIsCustomer = false;
      this.supplierCreditTerm = 0;
      this.bankAccountNumber = "";
      this.withholdingTaxRate = 3;
      this.selectedBank = null;
      this.vatRate = 7;
      this.contactName = "";
      this.contactAddress1 = "";
      this.contactAddress2 = "";
      this.contactAddress3 = "";

      this.isCreateDialog = false;
      this.isDeleteDialog = false;
      this.isDeletedDialog = false;

      this.addressDefault = {
        province: "",
        district: "",
        subdistrict: "",
        postCode: "",
      };
      this.isAddressError = {
        province: false,
        district: false,
        subdistrict: false,
        postCode: false,
      }
      this.keyReload++;

      this.selectedDbdBusinessType = null;
      this.selectedTelephoneCountryCode = "";
      this.selectedMobileCountryCode = "";
      this.emergencyContactPrefix = "";
      this.emergencyContactName = "";
      this.selectedEmergencyContactMobileCountryCode = "";
      this.emergencyContactMobile = "";

      if (this.isCreateDocument && this.isSharedCountryCodeReady) {
        //set default country code
        let defaultCountryCode = this.findCountryCode("66");
        this.selectedTelephoneCountryCode = defaultCountryCode;
        this.selectedMobileCountryCode = defaultCountryCode;
        this.selectEmergencyContactMobileCountryCode = defaultCountryCode;
      }
      
      //this.$store.dispatch("supplier/resetSupplier");
    },
    closeBaseDialog() {
      this.showValidateDialog = false;
      this.clearBaseDialog();
    },
    confirmBaseDialog() {
      this.showValidateDialog = false;
      this.clearBaseDialog();
    },
    clearBaseDialog() {
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.validateWording = "";
    },
    async askForCreate() {
      this.clearBaseDialog();
      if (!this.isRoleInsert) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return;
      }
      let result = this.isCompareObject(this.objToCompare, this.payload);
      this.isCreateDialog = true;
      if (result) {
        this.titleConfirm = this.lang.dialogTitleCreate;
        this.validateWording = this.lang.dialogContentCreate;
        this.showValidateDialog = true;
      } else {
        await this.createData();
      }
    },
    async createData() {
      this.setLoading(true);
      this.initiateData();
      await this.$router.push({name: "supplier-edit", params: { id: "create" }});
      this.setCompareData();
      this.showValidateDialog = false;
      this.setLoading(false);
    },
    async checkBeforeSave() {
      this.clearBaseDialog();
      if (this.validateData()) {
        this.setLoading(false);
        // this.baseDialogClassTitle = "p-error";
        // this.titleConfirm = this.lang.dialogValidateTitle;
				// let massage = [this.massageIsCodeError,this.massageIsNameErrorError,this.massageIsTaxIdError]; 
        // this.validateWording = massage;
        // this.showValidateDialog = true;
        return;
      }
      await this.saveData();
    },
    validateData() {
      let returnStatus = false

      if (this.isCreateDocument && !this.isRoleInsert) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return true;
      } else if (!this.isCreateDocument && !this.isRoleUpdate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleUpdate;
        this.showValidateDialog = true;
        return true;
      }

      if (!this.code.trim()) {
        this.massageIsCodeError = this.lang.validateCodeIsNull;
        this.isCodeError = true;
        returnStatus = true;
      }
      if (!this.name.trim()) {
        this.isNameError = true;
        this.massageIsNameErrorError = this.lang.validateName;
        returnStatus = true;
      }
      
      //address
      if (this.address3 ||
          this.addressDistrict ||
          this.addressSubDistrict ||
          this.postCode
      ) {
        if (!this.address3) {
          this.isAddressError.province = true;
          returnStatus = true;
        }
        if (!this.addressDistrict) {
          this.isAddressError.district = true;
          returnStatus = true;
        }
        if (!this.addressSubDistrict) {
          this.isAddressError.subdistrict = true;
          returnStatus = true;
        }
        if (!this.postCode) {
          this.isAddressError.postCode = true;
          returnStatus = true;
        }
      }
      /* เอาออกเนื่องจากลูกค้าไม่ต้องการให้ validate 
      let tax = this.taxId;
      if (tax) {
        if (!this.validTaxId(tax)) {
          this.massageIsTaxIdError = this.lang.validateTaxIdIsNotValid;
          this.isTaxIdError = true;
          returnStatus = true;
        } 
      } else {
        this.massageIsTaxIdError = this.lang.validateTaxIdIsNull;
        this.isTaxIdError = true;
        returnStatus = true;    
      }*/
      if (returnStatus) {
        this.keyReload++;
        this.baseDialogClassTitle = "p-text-error";
        this.titleConfirm = this.lang.validateSaveTitle;
        this.validateWording = this.wordingValidate();
        this.showValidateDialog = true;
      }
      return returnStatus;
    },
    wordingValidate(){
      if (!this.code.trim()) {
        return this.lang.validateFieldNoValue + this.lang.labelCode;
      }

      if (!this.name.trim()) {
        return this.lang.validateFieldNoValue + this.lang.labelName;
      }
      
      if(!this.isAddressError.province||
         !this.isAddressError.district||
         !this.isAddressError.subdistrict||
         !this.isAddressError.postCode
        ) {
        return this.lang.invalidAddress;
      }
    },
    async saveData() {
      this.setLoading(true);
      try {
        await this.$store.dispatch("supplier/createOrUpdateSupplier", this.payload);
        this.clearBaseDialog();
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.dialogTitleSave;
        this.validateWording = this.lang.dialogContentSave;
        this.showValidateDialog = true;
        if (this.isCreateDocument) {
          await this.$router.replace({name: "supplier-edit", params: { id: this.supplier.id }});
          this.setData();
          this.setCompareData();
        }
        this.setLoading(false);
      } catch (error) {
        this.baseDialogClassTitle = "p-error";
        this.titleConfirm = this.lang.dialogValidateTitle;
        this.setMassageError(error);
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    askForDelete() {
      this.clearBaseDialog();
      if (!this.isRoleDelete) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleDelete;
        this.showValidateDialog = true;
        return;
      }
      
      this.isDeleteDialog = true;
      this.titleConfirm = this.lang.dialogTitleDelete;
      this.validateWording = `${this.lang.dialogContentDelete.replace(
        "{0}",
        this.code.trim()
      )}`;
      this.showValidateDialog = true;
    },
    async deleteData() {
      this.setLoading(true);
      try {
        await this.$store.dispatch("supplier/deleteSupplier", this.id);
        this.clearBaseDialog();
        this.isDeleteDialog = false;
        this.isDeletedDialog = true;
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.dialogTitleDeleted;
        this.validateWording = `${this.lang.dialogContentDeleted.replace(
          "{0}",
          this.code.trim()
        )}`;
        this.isVisibleCloseButton = true;
        this.showValidateDialog = true;
        this.setLoading(false);
      } catch (error) {
        this.isDeleteDialog = false;
        this.isDeletedDialog = false;
        this.isVisibleCloseButton = false;
        this.baseDialogClassTitle = "p-error";
        this.titleConfirm = this.lang.dialogValidateTitle;
        this.setMassageError(error);
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    setDataAddress(data) {
      this.address2 = (data.district && data.subdistrict)?this.formatSubdistrict(data):"";
      this.address3 = data.province;
      this.addressDistrict = data.district;
      this.addressSubDistrict = data.subdistrict;
      this.postCode = data.postCode;
      this.addressDefault = {
        province: data.province,
        district: data.district,
        subdistrict: data.subdistrict,
        postCode: data.postCode,
      }
      this.isAddressError.province = false;
      this.isAddressError.district = false;
      this.isAddressError.subdistrict = false;
      this.isAddressError.postCode = false;
    },
    dbdBusinessTypeDataReady() {
      this.isSharedDbdBusinessTypeReady = true;
      this.checkShouldSetData();
    },
    countryCodeDataReady() {
      this.isSharedCountryCodeReady = true;
      this.checkShouldSetData();
    },
    setMassageError(error) {
      let massage = error.massage;
      if (error.code == "102") {
        massage = this.lang.dialogValidateParamsIdNotFound;
      } else if (error.code == "103") {
        massage = this.lang.dialogValidateParamsProjectId;
      } else if (error.code == "104") {
        massage = this.lang.dialogValidateParamsCode;
      } else if (error.code == "105") {
        massage = this.lang.dialogValidateParamsName;
        }else if (error.code == "106") {
        massage = this.lang.dialogValidateParamsTaxId;
      } else if (error.code == "107") {
        massage = this.lang.dialogValidateParamsGendar;
      } else if (error.code == "108") {
        massage = this.lang.dialogValidateParamsId;
      } else if (error.code == "111") {
        massage = this.lang.dialogValidateInsertRole;
      } else if (error.code == "112") {
        massage = this.lang.dialogValidateUpdateRole;
      } else if (error.code == "113") {
        massage = this.lang.dialogValidateDeleteRole;
      } else if (error.code == "120") {
        massage = this.lang.dialogValidateInsertAndDuplicateCode;
        this.massageIsCodeError = this.lang.validateCodeDuplicate;
        this.isCodeError = true;
      } else if (error.code == "121") {
        massage = this.lang.dialogValidateInsertAndDuplicateTaxId;
        this.massageIsTaxIdError = this.lang.validateTaxIdDuplicate;
        this.isTaxIdError = true;
      } else if (error.code == "130") {
        massage = this.lang.dialogValidateReferencePurchaseRequisition;
      } else if (error.code == "131") {
        massage = this.lang.dialogValidateReferencePurchaseOrder;
      } else if (error.code == "132") {
        massage = this.lang.dialogValidateReferencePurchaseInvoice;
      } else if (error.code == "133") {
        massage = this.lang.dialogValidateReferencePurchaseReceipt;
      } else if (error.code == "134") {
        massage = this.lang.dialogValidateReferencePayment;
      } else if (error.code == "135") {
        massage = this.lang.dialogValidateReferenceWithholdingTax;
      } else if (error.code == "136") {
        massage = this.lang.dialogValidateReferenceUnitRersident;
      } else if (error.code == "137") {
        massage = this.lang.dialogValidateReferenceUnitHistory;
      } else if (error.code == "151") {
        this.isAddressError.province = true;
        this.isAddressError.district = true;
        this.isAddressError.subdistrict = true;
        this.isAddressError.postCode = true;
        massage = this.lang.invalidAddress;
        this.keyReload++;
      } else if (error.code == "152") {
        this.isAddressError.subdistrict = true;
        massage = this.lang.subdistrict+this.lang.dataNotFound;
        this.keyReload++;
      } else if (error.code == "153") {
        this.isAddressError.district = true;
        massage = this.lang.district+this.lang.dataNotFound;
        this.keyReload++;
      } else if (error.code == "154") {
        this.isAddressError.province = true;
        massage = this.lang.province+this.lang.dataNotFound;
        this.keyReload++;
      } else if (error.code == "155") {
        this.isAddressError.postCode = true;
        massage = this.lang.postcode+this.lang.dataNotFound;
        this.keyReload++;
      } else if (error.code == "156") {
        this.isAddressError.province = true;
        this.isAddressError.district = true;
        this.isAddressError.subdistrict = true;
        this.isAddressError.postCode = true;
        massage = this.lang.inconsistentAddress;
        this.keyReload++;
      }  
      this.validateWording = massage;
    },
    selectTelephoneCountryCode(data) {
      this.selectedTelephoneCountryCode = data.value.callingCode;
    },
    selectMobileCountryCode(data) {
      this.selectedMobileCountryCode = data.value.callingCode;
    },
    selectEmergencyContactMobileCountryCode(data) {
      this.selectedEmergencyContactMobileCountryCode = data.value.callingCode;
    },
    findCountryCode(data) {
      const countryCode = this.sharedCountryCodes.find((countryCode) => countryCode.callingCode == data);
      let result = countryCode? countryCode.callingCode:"";
      return result;
    },
  },
  computed: {
    payload() {
      let ecPrefix = this.emergencyContactPrefix? this.emergencyContactPrefix:"";
      if (this.emergencyContactName && !this.emergencyContactPrefix) {
        ecPrefix = "คุณ";
      }

      let payload = {
        projectId: this.selectedProject.id,
        code: this.code,
        name: this.name,
        taxId: this.taxId,
        gender: this.selectedGender,
        address1: this.address1,
        address2: this.address2,
        address3: this.address3,
        postCode: this.postCode,
        telephoneCountryCode: this.selectedTelephoneCountryCode,
        telephoneNumber: this.telephoneNumber,
        mobileCountryCode: this.selectedMobileCountryCode,
        mobileNumber: this.mobileNumber,
        faxNumber: this.faxNumber,
        email: this.email,
        isCustomer: this.selectedIsCustomer ? "Y" : "N",
        supplierCreditTerm: this.supplierCreditTerm,
        bankAccountNumber: this.bankAccountNumber,
        withholdingTaxRate: this.withholdingTaxRate,
        bank: this.selectedBank ? this.selectedBank.name : "",
        vatRate: this.vatRate,
        contactName: this.contactName,
        contactAddress1: this.contactAddress1,
        contactAddress2: this.contactAddress2,
        contactAddress3: this.contactAddress3,
        addressDistrict: this.addressDistrict,
        addressSubDistrict: this.addressSubDistrict,
        type: "Add",
        isIgnoreValidateAddress: false,
        dbdBusinessTypeId: this.selectedDbdBusinessType? this.selectedDbdBusinessType.id:null,
        emergencyContactPrefix: ecPrefix,
        emergencyContactName: this.emergencyContactName,
        emergencyContactMobileCountryCode: this.selectedEmergencyContactMobileCountryCode,
        emergencyContactMobile: this.emergencyContactMobile,
      };
      if (!this.isCreateDocument) {
        payload = { ...payload, id: this.id, type: "Update" };
      }
      return payload;
    },
    isCreateDocument() {
      return this.id === "create";
    },
    title() {
      let result = this.lang.createTitle;
      if (!this.isCreateDocument) {
        result = this.lang.editTitle;
      }
      return result;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    supplier() {
      return this.$store.getters["supplier/selectedSupplier"];
    },
    role() {
      return this.getRoleByMenuByCode("tmCOR");
    },
    isRoleInsert() {
      if (this.role && this.role.insert == "Y") {
        return true;
      }
      return false;
    },
    isRoleUpdate() {
      if (this.role && this.role.update == "Y") {
        return true;
      }
      return false;
    },
    isRoleDelete() {
      if (this.role && this.role.delete == "Y") {
        return true;
      }
      return false;
    },
    dataButtons() {
      let result = [
        {
          id: "deleteCaption",
          action: "gotoDelete",
          isDisable: this.isCreateDocument,
          type: "delete",
        },
        {
          id: "createCaption",
          action: "gotoCreate",
          type: "create",
        },
        {
          id: "saveCaption",
          action: "gotoSave",
          type: "save",
        },
      ];
      return result;
    },
    dataButtonDialog() {
      let result = [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];
      if (this.isCreateDialog) {
        result = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "gotoCreate",
            caption: this.lang.buttonDialogSuccess,
            class: "w-full",
            action: "goto-create",
          },
        ];
      } else if (this.isDeleteDialog) {
        result = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "confirmCancel",
            caption: this.lang.buttonDialogSuccess,
            class: "w-full",
            action: "goto-delete",
          },
        ];
      } else if (this.isDeletedDialog) {
        result = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "goto-list",
          },
        ];
      }
      return result;
    },
    sharedBanks() {
      return this.$store.getters["shared/banks"];
    },
    sharedDbdBusinessTypes() {
      return this.$store.getters["shared/dbdBusinessType"];
    },
    sharedCountryCodes() {
      return this.$store.getters["shared/countryCodes"];
    },
  },
  watch: {
    supplier() {
      this.checkShouldSetData();
    },
    isSharedRoleByMenuReady() {
      this.checkShouldSetData();
    },
    payload(curValue) {
      if (this.objToCompare) this.compareObject(this.objToCompare, curValue);
    },
    isSharedAddressReady() {
      this.checkShouldSetData();
    }
  },
};
</script>