<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '90vw' }"
    :modal="true"
    :closable="false"
  >
    <base-product-ap-modal
      v-model:visible="showProductModal"
      :search="searchText"
      @close="showProductModal = false"
      @product-selected="productModalSelected"
    ></base-product-ap-modal>

    <purchase-order-dialog
      v-model:visible="showPurchaseOrder"
      @close="showPurchaseOrder = false"
      @select-purchase-order="selectedPurchaseOrder"
    ></purchase-order-dialog>

    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <PvButton
            icon="pi pi-file"
            :label="lang.create"
            class="p-button-secondary mr-2 mb-2"
            @click="createDocument"
          />
          <PvButton
            icon="pi pi-save"
            :label="lang.save"
            class="mr-2 mb-2"
            @click="save"
            :disabled="isCheckDisabled"
          />
          <PvButton
            icon="pi pi-upload"
            :label="lang.import"
            class="mr-2 mb-2"
            @click="importPO"
            :disabled="isCheckDisabled"
          />
        </div>
          <h2 class="p-dialog-header-icons">{{ parameterTitleText }}</h2>
        
      </div>
    </template>

    <div class="surface-card shadow-2 p-3 border-round">
      <!-- *********** -->
      <div class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 flex flex-wrap">
          <!-- ************************ -->
          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
            <label for="code">{{ lang.code }}</label>
            <PvInputText
              id="code"
              v-model="code"
              type="text"
              class="inputfield w-full mt-1 p input-disable"
              readonly
            />
          </div>
          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
            <label for="refCode">{{ lang.refCode }}</label>
            <PvInputText
              id="refCode"
              v-model="refCode"
              type="text"
              class="inputfield w-full mt-1 p"
              :class="{ 'p-invalid': refCodeError }"
            />
            <small v-if="refCodeError" id="refCode-help" class="p-error">{{
              lang.validateRefCode
            }}</small>
          </div>
          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
            <label for="date">{{ lang.date }}</label>
            <PvCalendar
              id="date"
              v-model="date"
              class="inputfield w-full mt-1"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>

          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
            <label for="vatSendDate">{{ lang.requestDate }}</label>
            <PvCalendar
              id="vatSendDate"
              v-model="vatSendDate"
              class="inputfield w-full mt-1"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>

          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
            <label for="dueDate">{{ lang.dueDate }}</label>
            <PvCalendar
              id="dueDate"
              v-model="dueDate"
              class="inputfield w-full mt-1"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
            <label for="periodDate">{{ lang.periodDate }}</label>
            <PvInputNumber
              id="periodDate"
              v-model="periodDate"
              class="inputfield w-full mt-1 p"
              inputClass=" text-right"
            />
          </div>

          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="supplier">{{ lang.supplier }}</label>
            <base-supplier-auto-complete
              id="supplier"
              v-model="selectedSupplier"
              class="inputfield w-full mt-1"
              @load-finish="supplierDataReady"
              :class="{ 'p-invalid': supplierError }"
            />
            <small v-if="supplierError" id="supplier-help" class="p-error">{{
              lang.validateNoSupplierSelect
            }}</small>
          </div>
          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="receiptEmployee">{{ lang.employee }}</label>
            <base-employee-auto-complete
              id="receiptEmployee"
              v-model="selectedEmployee"
              class="inputfield w-full mt-1"
              @load-finish="employeeDataReady"
              :readonly="isDocumentLocked"
              :class="{ 'p-invalid': employeeError }"
            />
            <small v-if="employeeError" id="employee-help" class="p-error">{{
              lang.validateNoEmployeeSelect
            }}</small>
          </div>

          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="remark1">{{ lang.remark1 }}</label>
            <PvInputText
              id="remark1"
              v-model="remark1"
              type="text"
              class="inputfield w-full mt-1 p"
            />
          </div>

          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="remark2">{{ lang.remark2 }}</label>
            <PvInputText
              id="remark2"
              v-model="remark2"
              type="text"
              class="inputfield w-full mt-1 p"
            />
          </div>
          <!-- ************************ -->
        </div>
      </div>
      <!-- *********** -->
      <PvDataTable
        showGridlines
        :value="details"
        dataKey="id"
        responsiveLayout="scroll"
        :class="textTableClass"
        editMode="cell"
        class="editable-cells-table"
        :scrollHeight="halfViewportHeight + 'px'"
        @cell-edit-complete="onCellEditComplete"
      >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
        <PvColumn
          field="itemNo"
          :header="lang.itemNo"
          style="min-width: 4rem"
          headerClass="h-center"
          bodyClass="text-center"
        >
        </PvColumn>
        <PvColumn
          field="productCode"
          :header="lang.columnCode"
          style="min-width: 4rem"
          headerClass="h-center"
          sortable
        >
          <template #editor="{ data, field }">
            <PvInputText type="text" v-model.trim="data[field]" />
          </template>
        </PvColumn>
        <PvColumn
          field="productName"
          :header="lang.columnCodeName"
          style="min-width: 4rem"
          headerClass="h-center"
          sortable
        ></PvColumn>
        <PvColumn
          field="productUnitName"
          :header="lang.columnUnitName"
          style="min-width: 6rem"
          headerClass="h-center"
          sortable
        ></PvColumn>
        <PvColumn
          field="unitPrice"
          :header="lang.columnUnitPrice"
          style="min-width: 6rem"
          headerClass="h-center"
          bodyClass="text-right"
          sortable
        >
          <template #editor="{ data, field }">
            <PvInputText
              type="number"
              v-model.trim="data[field]"
              @focus="$event.target.select()"
            />
          </template>
          <template #body="slotProps">
            {{ formatCurrencyTable(slotProps.data, "unitPrice") }}
          </template>
        </PvColumn>

        <PvColumn
          field="quantity"
          :header="lang.columnQuantity"
          style="min-width: 4rem"
          headerClass="h-center"
          bodyClass="text-right"
          sortable
        >
          <template #editor="{ data, field }">
            <PvInputText
              type="number"
              v-model.number="data[field]"
              @focus="$event.target.select()"
            />
          </template>
        </PvColumn>

        <PvColumn
          field="discountDescription"
          :header="lang.columnDiscount"
          headerClass="h-center"
          bodyClass="text-right"
          style="min-width: 4rem"
          sortable
        >
          <template #editor="{ data, field }">
            <PvInputText
              type="text"
              v-model.trim="data[field]"
              @focus="$event.target.select()"
              @keypress="checkImputDiscount($event)"
            />
          </template>
        </PvColumn>

        <PvColumn
          field="discountAmount"
          :header="lang.columnDiscountAmt"
          headerClass="h-center"
          bodyClass="text-right"
          style="min-width: 4rem"
          sortable
        >
          <template #body="slotProps">
            {{ formatCurrencyTable(slotProps.data, "discountAmount") }}
          </template>
        </PvColumn>

        <PvColumn
          field="totalAmount"
          :header="lang.columnNetAmount"
          style="min-width: 6rem"
          headerClass="h-center"
          bodyClass="text-right"
          sortable
        >
          <template #body="slotProps">
            {{ formatCurrencyTable(slotProps.data, "totalAmount") }}
          </template>
        </PvColumn>
        <PvColumn
          style="min-width: 4rem"
          headerClass="h-center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <PvButton
              type="button"
              class="p-button-danger p-button-text py-0"
              icon="pi pi-times"
              @click="deleteRow(slotProps.data.id)"
            ></PvButton>
          </template>
        </PvColumn>
      </PvDataTable>
      <!-- *********** -->
      <div class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 flex flex-wrap">
          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="totalAmount">{{ lang.inputTotalAmount }}</label>
            <PvInputNumber
              id="totalAmount"
              class="inputfield w-full mt-1"
              inputClass="input-number-disable text-right"
              v-model="totalAmount"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              disabled
            />
          </div>

          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="discount">{{ lang.inputDiscount }}</label>
            <PvInputText
              id="discount"
              v-model="discount"
              type="text"
              class="inputfield w-full mt-1 p text-right"
              @focus="$event.target.select()"
              @keypress="checkImputDiscount($event)"
            />
          </div>
          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="discountTotalAmount">{{
              lang.inputDiscountTotalAmount
            }}</label>
            <PvInputNumber
              id="discountTotalAmount"
              class="inputfield w-full mt-1"
              inputClass="input-number-disable text-right"
              v-model="discountTotalAmount"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              disabled
            />
          </div>

          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="paymentTotalAmount">{{
              lang.inputPaymentTotalAmount
            }}</label>
            <PvInputNumber
              id="paymentTotalAmount"
              class="inputfield w-full mt-1"
              inputClass="input-number-disable text-right"
              v-model="paymentTotalAmount"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              disabled
            />
          </div>

          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="tax">{{ lang.inputTax }}</label>
            <PvInputNumber
              id="tax"
              class="inputfield w-full mt-1"
              inputClass="input-number-disable text-right"
              v-model="tax"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              disabled
            />
          </div>
          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="taxAmount">{{ lang.inputTaxAmount }}</label>
            <PvInputNumber
              id="taxAmount"
              class="inputfield w-full mt-1"
              inputClass="input-number-disable text-right"
              v-model="taxAmount"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              disabled
            />
          </div>

          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="netAmount">{{ lang.inputNetAmount }}</label>
            <PvInputNumber
              id="netAmount"
              class="inputfield w-full mt-1"
              inputClass="input-number-disable text-right"
              v-model="netAmount"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              disabled
            />
          </div>
        </div>
      </div>
      <!-- *********** -->
    </div>

    <template #footer>
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog"
      />
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import useGetProductAP from "../../hooks/getProductAP.js";
import purchaseOrderDialog from "../../components/AP/BasePurchaseOrderDialog.vue";
import useGetBookPurchase from "../../hooks/getBookPurchase.js";
export default {
  components: {
    purchaseOrderDialog,
  },
  props: ["openFrom", "voucherId", "purchaseBookSelected", "purchaseInvoiceId"],
  emits: ["close", "create-purchase-invoice"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass, textAlertClass } =
      useTextStyle();
    const {
      formatCurrencyTable,
      formatCurrency,
      formatDate,
      formatDateToISOString,
    } = useFormat();
    const { isSharedProductReady, getProductByCode, searchText } =
      useGetProductAP();
    const store = useStore();
    const { filteredBookPurchases } = useGetBookPurchase();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.accounting.edit,
          ...lang.th.gl.accounting.listing,
          ...lang.th.ap.purchaseInvoice.listing,
          ...lang.th.ap.purchaseInvoice.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.th.gl.accounting.edit,
          ...lang.th.gl.accounting.listing,
          ...lang.en.ap.purchaseInvoice.listing,
          ...lang.en.ap.purchaseInvoice.edit,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      textAlertClass,
      formatCurrencyTable,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      isSharedProductReady,
      getProductByCode,
      searchText,
      filteredBookPurchases,
    };
  },
  data() {
    return {
      showProductModal: false,
      showPurchaseOrder: false,
      editingDetail: null,
      code: "",
      refCode: "",
      refCodeError: false,
      date: null,
      vatSendDate: null,
      periodDate: 0,
      dueDate: null,
      selectedSupplier: null,
      supplierError: false,
      selectedEmployee: null,
      employeeError: false,
      section: "",
      project: "",
      task: "",
      remark1: "",
      remark2: "",

      currencyId: "",
      // CurrencyAmount: "",
      totalAmount: 0,
      discount: "",
      discountTotalAmount: 0,
      payment: 0,
      paymentTotalAmount: 0,
      tax: 0,
      taxAmount: 0,
      netAmount: 0,
      vatInclude: "",

      details: [],
      isSharedSupplierReady: false,
      isSharedEmployeeReady: false,

      selectedAPBook: null,
      selectedBookId: "",
      selectedBookCode: "",
      selectedModuleCode: "",

      showFormPrintModal: false,
      path: "",
      dataPrint: [],
      categoryPrint: "",
      isSetData: true,
    };
  },
  async created() {
    this.initiateData();
    this.setLoading(false);
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    supplierDataReady() {
      this.isSharedSupplierReady = true;
    },
    employeeDataReady() {
      this.isSharedEmployeeReady = true;
      this.selectedEmployee = this.sharedEmployees.find(
        (employee) => employee.code === this.employee.code
      );
    },
    async getPurchaseInvoiceId() {
      try {
        this.setLoading(true);
        const where = {
          id: this.purchaseInvoiceId,
        };
        await this.$store.dispatch("apPurchaseInvoice/getPurchaseInvoice", {
          where,
        });
        this.setLoading(false);
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }

      this.setLoading(false);
    },
    checkShouldSetData() {
      if (
        this.isSharedSupplierReady &&
        this.purchaseInvoice &&
        this.isSharedEmployeeReady
        // !this.isCreateDocument
      ) {
        this.setData();
      }
    },
    setData() {
      // console.log("setData=", this.purchaseInvoice);
      this.isSetData = false;
      this.code = this.purchaseInvoice.code;
      this.refCode = this.purchaseInvoice.refCode;
      this.date = new Date(this.purchaseInvoice.date);
      this.vatSendDate = new Date(this.purchaseInvoice.vatSendDate);
      this.dueDate = new Date(this.purchaseInvoice.dueDate);
      this.section = this.purchaseInvoice.section;
      this.project = this.purchaseInvoice.project;
      this.task = this.purchaseInvoice.task;
      this.remark1 = this.purchaseInvoice.remark1;
      this.remark2 = this.purchaseInvoice.remark2;
      this.details = this.purchaseInvoice.details;

      this.currencyId = this.purchaseInvoice.currencyId;
      // this.CurrencyAmount = this.purchaseInvoice.exchRate;
      this.totalAmount = this.purchaseInvoice.totalAmount;
      this.discount = this.purchaseInvoice.discountDescription;
      this.discountTotalAmount = this.purchaseInvoice.discountAmount;
      this.payment = 0;
      this.paymentTotalAmount = 0;
      this.tax = this.purchaseInvoice.vatRate;
      this.taxAmount = this.purchaseInvoice.vatAmount;
      this.netAmount = this.purchaseInvoice.netAmount;

      this.selectedSupplier = this.sharedSuppliers.find(
        (supplier) => supplier.id === this.purchaseInvoice.supplierId
      );

      this.selectedEmployee = this.sharedEmployees.find(
        (employee) => employee.id === this.purchaseInvoice.employeeId
      );

      // console.log("this.vatInclude=", this.vatInclude);
      if (this.purchaseInvoice.isVatInclude === "Y") {
        this.paymentTotalAmount = this.netAmount - this.taxAmount;
      } else {
        this.paymentTotalAmount = this.totalAmount - this.discountTotalAmount;
      }

      this.SetPeriodDate();

      this.addEmptyRow();
      this.updateItemNumber();

      if (this.filteredBookPurchases && this.purchaseInvoiceId) {
        let book = this.filteredBookPurchases.find(
          (data) => data.bookId === this.purchaseInvoice.documentBookId
        );

        // console.log("this.filteredBookPurchases=",book);
        this.selectedAPBook = book;
        this.selectedBookId = book.bookId;
        this.selectedBookCode = book.bookCode;
        this.selectedModuleCode = book.moduleCode;

        this.tax = book.taxRate;
        this.vatInclude = book.vatInclude;
      }

      this.setLoading(false);
    },
    onCellEditComplete(event) {
      // console.log("onCellEditComplete=", event);
      //  console.log("this.isDocumentLocked=", this.isDocumentLocked);
      if (!this.isDocumentLocked) {
        let value = event.value;
        let newValue = event.newValue;
        if (typeof value === "string") {
          value = value.trim();
        }
        if (typeof newValue === "string") {
          newValue = newValue.trim();
        }
        if (value === newValue) {
          return;
        }

        if (event.field === "unitPrice") {
          // console.log("event.data=",event.data);
          if (event.data.referenceId != null && event.data.referenceId.trim()) {
            if (value != newValue) {
              // console.log("ข้อมูลจาก PO ไม่สามารถเปลี่ยนราคาได้");
              this.$swal({
                icon: "warning",
                title: this.lang.validateUpdatePrice,
                confirmButtonText: this.lang.close,
                allowOutsideClick: false,
              });
              return;
            }
          }

          if (newValue < 0 || !newValue) {
            event.data.unitPrice = 0;
          } else {
            this.calculateDetail(
              event.data,
              newValue,
              event.data.quantity,
              event.data.discountDescription
            );
          }
        } else if (event.field === "quantity") {
          // console.log("onCellEditComplete_quantity=", event);
          // console.log("onCellEditComplete_quantity00=", event.data);
          if (newValue < 1 || !newValue) {
            newValue = 1;
            this.calculateDetail(
              event.data,
              event.data.unitPrice,
              newValue,
              event.data.discountDescription
            );
          } else if (newValue > event.data.sourceQuantity) {
            event.data.quantity = event.data.sourceQuantity;
            this.calculateDetail(
              event.data,
              event.data.unitPrice,
              event.data.sourceQuantity,
              event.data.discountDescription
            );
          } else {
            this.calculateDetail(
              event.data,
              event.data.unitPrice,
              newValue,
              event.data.discountDescription
            );
          }
        } else if (event.field === "discountDescription") {
          if (newValue < 0 || !newValue) {
            newValue = "";
            event.data.discountAmount = 0;
          }
          //else {
          this.calculateDetail(
            event.data,
            event.data.unitPrice,
            event.data.quantity,
            newValue
          );
          //}
          // this.calculateDetailDiscountDescription(event.data,newValue)
        } else if (event.field === "productCode") {
          //หาว่า Code ที่ระบุมีอยู่จริงหรือไม่
          // console.log("productCode=",newValue);
          const product = this.getProductByCode(newValue);
          //หากมี ก็ update ข้อมูลใน record นั้น
          if (product) {
            if (newValue === "") {
              return;
            }
            if (!value) {
              // console.log("!value");
              this.productSelect(event.data, product);
            } else if (value !== newValue) {
              this.$confirm.require({
                message: this.lang.askForProductChange,
                header: this.lang.confirmDialogHeader,
                icon: "pi pi-exclamation-triangle",
                accept: () => {
                  //callback to execute when user confirms the action
                  this.productSelect(event.data, product);
                },
              });
            }
            // console.log("this.$refs=",this.$refs);
          } else {
            // console.log("ไม่มีcode!!!", newValue);
            this.$confirm.require({
              message: this.lang.askForSelectProduct.replace(":code", newValue),
              header: this.lang.confirmDialogHeader,
              icon: "pi pi-exclamation-triangle",
              accept: () => {
                //callback to execute when user confirms the action
                this.editingDetail = event.data;
                this.searchText = newValue;
                this.showProductModal = true;
              },
            });
          }
        }
      }
    },
    calculateDetail(detail, unitPrice, quantity, discountDescription) {
      detail.unitPrice = unitPrice;
      detail.quantity = quantity;
      detail.discountDescription = discountDescription;
      // console.log("discountDescription=",discountDescription);
      if (!discountDescription) discountDescription = "0";
      if (discountDescription.includes("%")) {
        let totalAmt = unitPrice * quantity;
        detail.discountAmount =
          (parseFloat(discountDescription) / 100) * totalAmt;
        detail.totalAmount =
          unitPrice * quantity -
          (parseFloat(discountDescription) / 100) * totalAmt;
      } else {
        detail.discountAmount = parseFloat(discountDescription);
        detail.totalAmount =
          unitPrice * quantity - parseFloat(discountDescription);
      }
      this.updateTotalAmount();
    },
    SetPeriodDate() {
      // console.log("this.date=",this.date);
      // console.log("this.dueDate=",this.dueDate);
      if (this.date === null) this.date = new Date();
      if (this.dueDate === null) this.dueDate = new Date();
      const utc1 = Date.UTC(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate()
      );
      const utc2 = Date.UTC(
        this.dueDate.getFullYear(),
        this.dueDate.getMonth(),
        this.dueDate.getDate()
      );
      // console.log("utc1=",utc1);
      // console.log("utc2=",utc2);
      let PeriodDate = Math.floor((utc2 - utc1) / (1000 * 3600 * 24));
      this.periodDate = PeriodDate;
    },
    GetPeriodDate(StartDate, EndDate) {
      var date1 = new Date(StartDate);
      var date2 = new Date(EndDate);
      var Difference_In_Time = date2.getTime() - date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      return Difference_In_Days;
    },
    createDocument() {
      this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForCreateNewDocument}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.cancel,
        allowOutsideClick: false,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (this.purchaseBookSelected) {
            this.initiateData();
          } else {
            this.$emit("create-purchase-invoice", null);
            this.$emit("close");
          }
        }
      });
    },
    async initiateData() {
      // console.log("initiateData=", this.sharedEmployees);
      this.selectedEmployee = this.sharedEmployees.find(
        (employee) => employee.code === this.employee.code
      );

      this.code = "";
      this.refCode = "";
      this.date = new Date();
      this.vatSendDate = new Date();
      this.periodDate = 0;
      this.dueDate = new Date();
      this.selectedSupplier = null;
      this.section = "";
      this.project = "";
      this.task = "";
      this.remark1 = "";
      this.remark2 = "";
      this.details = [];

      this.totalAmount = 0;
      this.discount = null;
      this.discountTotalAmount = 0;
      this.payment = 0;
      this.paymentTotalAmount = 0;
      // this.tax = 0;
      this.taxAmount = 0;
      this.netAmount = 0;

      this.refCodeError = false;
      this.employeeError = false;
      this.supplierError = false;
      this.isSetData = true;

      await this.$store.dispatch(
        "apPurchaseInvoice/resetSelectedPurchaseInvoice"
      );

      this.addEmptyRow();
    },
    addEmptyRow() {
      // console.log("this.details",this.details);
      this.details.push({
        id: "dummy" + (parseInt(this.details.length) + 1),
        headerId: null,
        itemNo: null,
        productId: null,
        productCode: null,
        productName: null,
        productUnitId: null,
        productUnitCode: null,
        productUnitName: null,
        quantity: null,
        unitPrice: null,
        discountDescription: null,
        discountAmount: null,
        totalAmount: null,
        remark: null,
        stockEffect: null,
        referenceId: null,
      });
    },
    validateData() {
      if (!this.refCode) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateRefCode,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.refCodeError = true;
          }
        });
        return true;
      }

      if (!this.selectedSupplier) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoSupplierSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.supplierError = true;
          }
        });
        return true;
      }

      if (!this.selectedEmployee) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoEmployeeSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.employeeError = true;
          }
        });
        return true;
      }

      if (this.details.length <= 1) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateDetailSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      if (this.totalAmount < 0) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateTotalAmount,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      if (this.discount) {
        let isText = false;
        const keysAllowed = [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          ".",
          "%",
        ];
        for (let i = 0; i < this.discount.length; i++) {
          if (!keysAllowed.includes(this.discount[i])) {
            isText = true;
            break;
          }
        }
        if (isText) {
          this.$swal({
            icon: "warning",
            title: this.lang.validateCheckDiscount,
            confirmButtonText: this.lang.close,
            allowOutsideClick: false,
          });
          return true;
        }
      }

      if (this.netAmount < 0) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNetTotalAmount,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      return false;
    },
    productModalSelected(product) {
      this.productSelect(this.editingDetail, product);
      this.showProductModal = false;
    },
    productSelect(detail, product) {
      // const today = new Date();
      // detail.id = "dummy" + today.getSeconds();
      detail.headerId = null;
      detail.itemNo = detail.itemNo || this.lastItemNo + 1;
      detail.productId = product.id;
      detail.productCode = product.code;
      detail.productName = product.nameThai;
      detail.productUnitId = product.unitId;
      detail.productUnitCode = product.unitCode;
      detail.productUnitName = product.unitName;
      detail.quantity = 1;
      detail.unitPrice = product.price;
      detail.discountDescription = "0";
      detail.discountAmount = 0;
      detail.totalAmount = detail.unitPrice * detail.quantity;
      detail.remark = "";
      detail.stockEffect = null;
      detail.referenceId = null;

      this.addRow(detail);
      //หาว่ามีบรรทัดที่ รหัสเป็น null หรือยัง (บรรทัดว่าง)
      const emptyRow = this.details.find((detail) => !detail.productCode);
      if (!emptyRow) {
        //ถ้ายังไม่มีให้เพิ่มไว้
        this.addEmptyRow();
        // this.updateItemNumber();
      }

      this.updateTotalAmount();
      this.updateItemNumber();
    },
    addRow(detail) {
      //let total = 0;
      this.details.forEach((element) => {
        if (element.id === detail.id) {
          element = { ...element, ...detail };
        }
        //total += element.productId ? element.totalAmount : 0;
      });
      //this.totalAmount = total;
    },
    deleteRow(id) {
      const index = this.details.findIndex((detail) => detail.id === id);
      this.details.splice(index, 1);

      this.updateTotalAmount();
      this.updateItemNumber();
    },
    updateItemNumber() {
      let number = 1;
      this.details.forEach((element) => {
        if (element.productCode) {
          element.itemNo = number;
        }
        number++;
      });
    },
    updateTotalAmount() {
      let totalAmount = 0;
      this.details.forEach((detail) => {
        totalAmount += detail.totalAmount;
      });
      this.totalAmount = totalAmount;
    },
    calculateNetAmount() {
      let vatInclude = this.vatInclude;

      if (this.discount) {
        if (this.discount.includes("%")) {
          this.discountTotalAmount =
            (parseFloat(this.discount) / 100) * this.totalAmount;
        } else {
          this.discountTotalAmount = parseFloat(this.discount);
        }
        // console.log("vatInclude=",vatInclude);
        if (vatInclude === "Y") {
          this.netAmount = this.totalAmount - this.discountTotalAmount;
          this.taxAmount = (this.netAmount * this.tax) / 107;
          this.paymentTotalAmount = this.netAmount - this.taxAmount;
        } else {
          this.paymentTotalAmount = this.totalAmount - this.discountTotalAmount;
          this.taxAmount = (this.tax / 100) * this.paymentTotalAmount;
          this.netAmount =
            this.totalAmount - this.discountTotalAmount + this.taxAmount;
        }
      } else {
        this.discountTotalAmount = 0;
        if (vatInclude === "Y") {
          this.netAmount = this.totalAmount - this.discountTotalAmount;
          this.taxAmount = (this.netAmount * this.tax) / 107;
          this.paymentTotalAmount = this.netAmount - this.taxAmount;
        } else {
          this.paymentTotalAmount = this.totalAmount - this.discountTotalAmount;
          this.taxAmount = (this.tax / 100) * this.paymentTotalAmount;
          this.netAmount =
            this.totalAmount - this.discountTotalAmount + this.taxAmount;
        }
      }
    },
    async importPO() {
      // console.log("importPO=",this.showPurchaseOrder);

      if (!this.selectedSupplier) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoSupplierSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.supplierError = true;
          }
        });
        return true;
      }

      let where = {
        projectId: this.selectedProject.id,
        supplierId: this.selectedSupplier.id,
        VatRate: this.tax,
      };
      // console.log('where',JSON.stringify(where));
      const payload = { where };
      try {
        await this.$store.dispatch(
          "apPurchaseInvoice/getPurchaseOrders",
          payload
        );
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }

      this.showPurchaseOrder = true;
    },
    selectedPurchaseOrder(data) {
      // console.log("selectPurchaseOrder=", data);
      // this.totalAmount = data.totalAmount;
      this.discount = data.discount;
      this.discountTotalAmount = data.discountAmount;
      // this.payment = 0;
      // this.paymentTotalAmount = data.totalAmount - data.discountAmount;
      // this.tax = data.vatRate;
      // this.taxAmount = data.vatAmount;
      // this.netAmount = data.netAmount;

      this.remark2 =
        "PO/" +
        data.code +
        " : " +
        this.formatDate(data.date) +
        " Amt(non vat) = " +
        data.totalAmount;

      data.details.forEach((e) => {
        // let products = [];
        let productDetail = null;
        productDetail = this.details.find((x) => x.referenceId === e.id);
        if (productDetail == null) {
          let product = {
            // id: e.id,
            id: "dummy" + (parseInt(this.details.length) + 1),
            headerId: null,
            itemNo: 1,
            productId: e.productId,
            productCode: e.productCode,
            productName: e.productName,
            productUnitId: e.productUnitId,
            productUnitCode: e.productUnitCode,
            productUnitName: e.productUnitName,
            quantity: e.quantity - e.dlvrQuantity,
            unitPrice: e.unitPrice,
            discountDescription: e.discount,
            discountAmount: e.discountAmount,
            totalAmount: e.totalAmount,
            remark: "",
            stockEffect: null,
            referenceId: e.id,
            unitRatio: e.unitRatio,
            sourceQuantity: e.sourceQuantity,
          };
          // products.push(product);
          // this.details = products;
          this.details.unshift(product);
        }
      });

      const emptyRow = this.details.find((detail) => !detail.productCode);
      if (!emptyRow) {
        //ถ้ายังไม่มีให้เพิ่มไว้
        this.addEmptyRow();
      }
      this.updateTotalAmount();
      this.updateItemNumber();
      // this.calculateNetAmount();
      // this.details.sort((a, b) => a.itemNo - b.itemNo);
      // this.details.sort((a,b)=> a.itemNo < b.itemNo?1:-1)
    },
    async save() {
      if (this.validateData()) {
        return;
      }
      this.setLoading(true);
      // console.log("save_payload=", this.payload);
      try {
        await this.$store.dispatch(
          "apPurchaseInvoice/AddUpdatePurchaseInvoice",
          this.payload
        );
        // this.$swal({
        //   icon: "success",
        //   title: this.lang.saveSuccess,
        //   confirmButtonText: this.lang.close,
        //   allowOutsideClick: false,
        // });

        //****************
        // console.log("this.purchaseInvoice=",this.purchaseInvoice);
        if (this.purchaseInvoice) {
          let purchaseInvoice = this.purchaseInvoice;
          purchaseInvoice = {
            ...purchaseInvoice,
            ...{ moduleCode: this.selectedModuleCode },
            ...{ documentBookId: this.selectedBookId },
            ...{ documentBookCode: this.selectedBookCode },
            ...{ voucherId: "" },
          };

          let purchaseInvoiceSelected = this.purchaseInvoiceSelected;
          const data = purchaseInvoiceSelected.find(
            (d) => d.id === this.purchaseInvoice.id
          );
          if (!data) {
            purchaseInvoiceSelected.push(purchaseInvoice);
          } else {
            data.netAmount = purchaseInvoice.netAmount;
          }

          this.$emit("close");
        }
        //****************
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
    },
    checkImputDiscount(event) {
      // console.log("event=",event);
      //const keysAllowed = ["0","1","2","3","4","5","6","7","8","9",".","%"];
      const keysAllowed = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        ".",
        "%",
      ];
      const keyPressed = event.key;
      if (!keysAllowed.includes(keyPressed)) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateCheckDiscount,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
    },
  },
  computed: {
    payload() {
      let details = [];
      for (const each of this.details.filter((each) => each.productId)) {
        let detail = null;
        if (each.id.includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        details.push(detail);
      }

      let payload = {
        projectId: this.selectedProject.id,
        refCode: this.refCode,
        documentBookId: this.selectedBookId,
        code: this.code,
        date: this.formatDateToISOString(this.date),
        dueDate: this.formatDateToISOString(this.dueDate),
        vatSendDate: this.formatDateToISOString(this.vatSendDate),
        supplierId: this.selectedSupplier.id,
        employeeId: this.selectedEmployee.id,
        IsVatInclude: this.vatInclude,
        // currencyId: this.currencyId,
        remark1: this.remark1,
        remark2: this.remark2,

        totalAmount: this.totalAmount,
        discountDescription: this.discount,
        discountAmount: this.discountTotalAmount,
        vatRate: this.tax,
        vatAmount: this.taxAmount,
        diffAmount: this.netAmount,
        netAmount: this.netAmount,
        typeInsert: "Add",
        // section: this.section,
        // project: this.project,
        // task: this.task,
        details: details,
      };

      if (this.purchaseInvoiceId) {
        payload = {
          ...payload,
          id: this.purchaseInvoiceId,
          typeInsert: "Update",
          IsVatInclude: this.purchaseInvoice.isVatInclude,
        };
      }

      return payload;
    },
    parameterTitleText() {
      return this.lang.createBill + " : " + this.selectedBookCode;
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    employee() {
      return this.$store.getters.employee;
    },
    purchaseInvoice() {
      return this.$store.getters["apPurchaseInvoice/selectedPurchaseInvoice"];
    },
    purchaseInvoiceSelected() {
      return this.$store.getters["glPurchaseInvoice/purchaseInvoiceSelected"];
    },
    sharedSuppliers() {
      return this.$store.getters["shared/supplierSuppliers"];
    },
    sharedEmployees() {
      return this.$store.getters["shared/employeeEmployees"];
    },
    lastItemNo() {
      const itemNo = Math.max.apply(
        Math,
        this.details.map(function (o) {
          return o.itemNo;
        })
      );
      return itemNo;
    },
    isDocumentLocked() {
      if (this.purchaseInvoice) {
        if (
          this.purchaseInvoice.paidStatus.trim() ||
          this.purchaseInvoice.postStep.trim() ||
          this.purchaseInvoice.cancelStatus.trim().toLowerCase() === "c"
        ) {
          return true;
        }
      }
      return false;
    },
    isCheckDisabled() {
      let isCheck = false;

      if (this.purchaseInvoice && this.purchaseInvoice.postStep.trim() !== "")
        isCheck = true;
      if (this.purchaseInvoice && this.purchaseInvoice.postStep === "A")
        isCheck = true;
      if (this.purchaseInvoice && this.purchaseInvoice.postStep === "P")
        isCheck = true;
      if (this.purchaseInvoice && this.purchaseInvoice.cancelStatus === "C")
        isCheck = true;

      return isCheck;
    },
  },
  watch: {
    isSharedSupplierReady() {
      this.checkShouldSetData();
    },
    purchaseInvoice() {
      this.checkShouldSetData();
    },
    isSharedEmployeeReady() {
      this.checkShouldSetData();
    },
    openFrom() {
      if (this.purchaseBookSelected) {
        // console.log("openFrom_purchaseBookSelected=",this.purchaseBookSelected);
        this.selectedAPBook = this.purchaseBookSelected;
        this.selectedBookId = this.purchaseBookSelected.bookId;
        this.selectedBookCode = this.purchaseBookSelected.bookCode;
        this.selectedModuleCode = this.purchaseBookSelected.moduleCode;

        this.tax = this.purchaseBookSelected.taxRate;
        this.vatInclude = this.purchaseBookSelected.vatInclude;

        this.initiateData();
      } else {
        // console.log("openFrom_purchaseInvoiceId=",this.purchaseInvoiceId);
        this.initiateData();
        if (this.purchaseInvoiceId) {
          this.getPurchaseInvoiceId();
        }
      }
    },
    // purchaseInvoiceId(){
    //   console.log("purchaseInvoiceId=",this.purchaseInvoiceId);
    //   if(this.purchaseInvoiceId){
    //     this.getPurchaseInvoiceId();
    //   }
    // },
    date() {
      // console.log("curValue=",curValue);
      let day = this.periodDate;
      this.dueDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate() + day,
        0
      );

      this.vatSendDate = this.date;
      this.SetPeriodDate();
    },
    dueDate() {
      this.SetPeriodDate();
    },
    selectedSupplier(curValue, oldValue) {
      if (oldValue === null && curValue) {
        if(this.isSetData) this.periodDate = curValue.supplierCreditTerm;
        this.supplierError = false;        
        this.isSetData = true;
      }
    },
    selectedEmployee(curValue, oldValue) {
      if (oldValue === null && curValue) {
        this.employeeError = false;
      }
    },
    refCode() {
      this.refCodeError = false;
    },
    discount() {
      this.calculateNetAmount();
    },
    totalAmount() {
      this.calculateNetAmount();
    },
    periodDate(curValue) {
      let dueDay = curValue;
      if (dueDay < 0) {
        this.dueDate = new Date(
          this.date.getFullYear(),
          this.date.getMonth(),
          this.date.getDate() + 0,
          0
        );
      } else {
        this.dueDate = new Date(
          this.date.getFullYear(),
          this.date.getMonth(),
          this.date.getDate() + curValue,
          0
        );
      }
    },
  },
};
</script>

<style >
.p-dialog-header {
    display: block !important;
}
</style>
