export default({
    title:"ใบรับสินค้า/ใบรับวางบิล",
    changeAPBook: "เปลี่ยนเล่มเอกสาร",
    selectAPBook: "เลือกเล่มเอกสาร",
    code: "รหัส",
    nameTH: "ชื่อ(ไทย)",
    nameEN: "ชื่อ(อังกฤษ)",

    columnApprove: "อนุมัติ",
    columnIsCancel:"ยกเลิก",
    columnCode: "เลขที่เอกสาร",
    columnRefCode : "เลขที่อ้างอิง",
    columnDate: "วันที่เอกสาร",
    columnDueDate: "วันที่ครบกำหนด",
    columnSupplier:"ผู้จัดจำหน่าย",
    columnSupplierName:"ชื่อผู้จัดจำหน่าย",
    columnPaidAmount: "ชำระ",
    columnNetAmount: "จำนวนเงิน",
    type: "ประเภท",

    


    


})