export default {
	assetRegisters(state) {
		return state.assetRegisters;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	selectedAssetRegister(state) {
		return state.selectedAssetRegister;
	},
	blob(state) {
		return state.blob;
	},
	assetCodeByType(state) {
		return state.assetCodeByTypes;
	},

	//modify 30/12/2022 แยกตัวเก็บ data dropdown กับหน้า list
	assetRegisterDropDowns(state) {
		return state.assetRegisterDropDowns;
	},
	totalPageDropDown(state) {
		return state.totalPageDropDown;
	},
	currentPageDropDown(state) {
		return state.currentPageDropDown;
	},
	assetRegisterIsLastPage(_, getters) {
		return getters.currentPageDropDown === getters.totalPageDropDown && getters.totalPageDropDown !== 0;
	},
	assetRegisterNextPage(state) {
		return state.currentPageDropDown + 1;
	},
};
