<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoPrint="getPrintForm"
    @gotoCancel="askForCancel"
    @gotoCreate="askForCreate"
    @gotoSave="save"
  ></base-toolbar>

  <base-container>
    <refund-guarantee-pending-dialog
      v-model:visible="showPendingDialog"
      :unit="selectedUnit"
      :date="date"
      :isOpen="showPendingDialog"
      :refundGuaranteeDetails="details"
      @close="closePendingDialog"
      @pending-selected="selectedPending"
    ></refund-guarantee-pending-dialog>

    <refund-guarantee-product-dialog
      v-model:visible="showProductDialog"
      :data="selectedDetail"
      :isOpen="showProductDialog"
      :isDocumentLocked="isDocumentLocked"
      @close="closeProductDialog"
      @product-selected="selectedProduct"
    ></refund-guarantee-product-dialog>

    <base-dialog
      v-model:visible="showValidateDialog"
      :classTitle="baseDialogClassTitle"
      :titleConfirm="titleConfirm"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
      @goto-cancel="cancel"
      @goto-create="createNewDocument"
    >
      <template #dialogBody v-if="showCancelDialog">
          <div class="lg:col-12 md:col-12 col-12 p-0 w-full">
            <label for="reasonCancel">{{ lang.cancelReason }}</label>
            <PvInputText
              id="reasonCancel"
              type="text"
              class="inputfield w-full mt-2"
              v-model="reasonCancel"
              :class="{ 'p-invalid': reasonCancelError }"
              @keypress="reasonCancelError = false"
              autofocus
            />
            <small v-if="reasonCancelError" id="reasonCancel-help" class="p-error">
              {{ lang.validateNoCancelReason }}
            </small>
          </div>
        </template>
    </base-dialog>

    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchTextPrint"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>

    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{ lang.titleEdit }}</label>

      <PvDivider></PvDivider>

      <section class="flex flex-wrap fontlabel" :class="textFormClass">
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="code">{{ lang.documentCode }}</label>
          <PvInputText
            id="code"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="code"
            disabled
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="date">{{ lang.documentDate }}</label>
          <PvCalendar
            id="date"
            v-model="date"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :inputClass="{'p-inputtext-disabled' : !isCreateDocument, 'p-invalid': dateError}"
            :disabled="!isCreateDocument"
            @date-select="clearDataDetails"
          />
          <small v-if="dateError" id="unit-help" class="p-error">
            {{ lang.validateNoDate }}
          </small>
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label>{{ lang.unitCode }}</label>
          <PvSkeleton
            v-if="!isSharedUnitReady"
            class="inputfield w-full mt-1"
            height="39px"
          />
          <base-unit-auto-complete
            id="unit"
            v-model="selectedUnit"
            class="inputfield w-full mt-1 mr-2"
            @load-finish="unitDataReady"
            @item-select="selectUnit"
            v-show="isSharedUnitReady"
            :disabled="!isCreateDocument"
            :inputClass="{ 'p-inputtext-disabled': !isCreateDocument, 'p-invalid': unitError }"
            :isValidateActiveStatus="true"
            @is-inactive="initiateData"
          />
          <small v-if="unitError" id="unit-help" class="p-error">
            {{ lang.validateNoUnitSelect }}
          </small>
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="referenceCode">{{ lang.referenceCode }}</label>
          <PvInputText
            id="referenceCode"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="referenceCode"
            disabled
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="customerName">{{ lang.customerName }}</label>
          <PvInputText
            id="customerName"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="customerName"
            disabled
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="employeeName">{{ lang.employeeName }}</label>
          <PvInputText
            id="employeeName"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="employeeName"
            disabled
          />
        </div>
      </section>

      <div class="pt-3 pb-3">
        <PvButton
          icon="pi pi-search"
          :label="lang.searchPendingList"
          class="p-button-primary mr-2 mb-2"
          :disabled="!isSharedUnitReady"
          @click="openPendingDialog"
        />
      </div>

      <div v-if="detailError" class="pb-2">
        <PvInlineMessage severity="error" class="w-full justify-content-start">
          {{ lang.validateDetail}}
        </PvInlineMessage>
      </div>

      <PvDataTable
        :value="details"
        dataKey="id"
        responsiveLayout="scroll"
        :class="textTableClass"
        v-model:selection="selectedDetail"
        selectionMode="single"
        @row-dblclick="openProductDialog(this.selectedDetail)"
      >
        <PvColumn
          field="itemNo"
          :header="lang.itemNo"
          style="min-width: 4rem"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="productCode"
          :header="lang.productCode"
          style="min-width: 4rem"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="productName"
          :header="lang.productName"
          style="min-width: 8rem"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="remark"
          :header="lang.description"
          style="min-width: 8rem"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="period"
          :header="lang.period"
          style="min-width: 4rem"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="unitPrice"
          :header="lang.price"
          style="min-width: 4rem"
          headerClass="h-right"
          bodyClass="text-right"
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.unitPrice) }}
          </template>
        </PvColumn>
        <PvColumn
          field="quantity"
          :header="lang.quantity"
          style="min-width: 4rem"
          headerClass="h-right"
          bodyClass="text-right"
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.quantity) }}
          </template>
        </PvColumn>
        <PvColumn
          field="billAmount"
          :header="lang.balance"
          style="min-width: 4rem"
          headerClass="h-right"
          bodyClass="text-right"
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.billAmount) }}
          </template>
        </PvColumn>
        <PvColumn
          field="totalAmount"
          :header="lang.refund"
          style="min-width: 4rem"
          headerClass="h-right"
          bodyClass="text-right"
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.totalAmount) }}
          </template>
        </PvColumn>
        <PvColumn
          style="min-width: 4rem"
          headerClass="h-center"
          bodyStyle="text-align: center; justify-content: center; align-items: center;"
          alignFrozen="right"
          frozen
        >
          <template #body="slotProps">
            <PvButton
              type="button"
              class="p-button-secondary p-button-text p-0 mr-3"
              @click="openProductDialog(slotProps.data)"
            >
              <icon-pen-edit iconColor="secondary" width="24" height="24"/>
            </PvButton>
            <PvButton
              type="button"
              class="p-button-secondary p-button-text p-0"
              @click="deleteRow(slotProps.data.id)"
            >
              <icon-trash-delete-bin
                iconColor="secondary"
                width="24"
                height="24"
              />
            </PvButton>
          </template>
        </PvColumn>

        <template #empty>
          <div class="w-full" style="text-align: center">
            <label>{{ this.lang.tableEmpty }}</label>
          </div>
        </template>
      </PvDataTable>

      <!-- <section class="flex flex-wrap fontlabel" :class="textFormClass"></section> -->
      <div class="flex flex-wrap fontlabel pt-4" :class="textFormClass">
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="paidFor">{{ lang.paidFor }}</label>
          <PvInputText
            id="paidFor"
            type="text"
            class="inputfield w-full mt-1"
            v-model="paidFor"
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="netAmount">{{ lang.netAmount }}</label>
          <PvInputNumber
            id="netAmount"
            class="inputfield w-full mt-1"
            inputClass="p-inputtext-disabled text-right w-full"
            v-model="netAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            disabled
          />
        </div>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useGetRoleByBook from "../../../hooks/getRoleByBook.js";
import RefundGuaranteeProductDialog from "../../../components/AR/refundGuarantee/RefundGuaranteeProductDialog.vue";
import RefundGuaranteePendingDialog from "../../../components/AR/refundGuarantee/RefundGuaranteePendingDialog.vue";
import usePageDirty from "../../../hooks/pageDirty";
export default {
  props: ["id", "projectCode"],
  components: {
    RefundGuaranteeProductDialog,
    RefundGuaranteePendingDialog
  },
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.refundGuarantee.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.refundGuarantee.edit,
        };
      }
    });

    const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();

    const {
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
    } = useGetFormPrint();

    const isHideBack = inject("isHideBack");

    const { compareObject, isCompareObject } = usePageDirty();

    return {
      lang: mergeLang,

      textTableClass,
      textFormClass,

      formatCurrency,
      formatDate,
      formatDateToISOString,
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
      isHideBack,
      getRoleByBookByCode,
      isSharedRoleByBookReady,    
      compareObject,
      isCompareObject,
    };
  },
  data() {
    return {
      //Dialog
      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: "",
      validateWording: "",
      showCancelDialog: false,
      reasonCancel: "",
      reasonCancelError: false,

      code: "",
      date: null,
      referenceCode: "",
      selectedUnit: null,
      isSharedUnitReady: false,
      customerName: "",
      employeeName: "",
      details: [],
      selectedDetail: null,
      paidFor: "",

      showProductDialog: false,

      showFormPrintModal: false,
      path: "",

      showPendingDialog: false,
      objToCompare: null,
      isCreateNewDocument: false,
      employeeId: "",

      unitError: false,
      dateError: false,
      detailError: false,
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    await this.roleByBookDataReady();
    await this.checkShouldSetData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.setLocalSearch();
      this.$router.push({name: "ar-refund-guarantee"});
    },
    unitDataReady() {
      this.isSharedUnitReady = true;
      this.checkShouldSetData();
    },
    async selectUnit() {
      await this.initiateData(false);

      this.customerName = this.selectedUnit.customerName;
    },
    async initiateData(unSelectUnit = true) {
      if (unSelectUnit) {
        this.selectedUnit = null;
        this.date = new Date();
      }
      this.code = "";
      this.referenceCode = "";
      this.customerName = "";
      this.employeeName = this.selectedEmployee.name;
      this.paidFor = "";
      this.details = [];
      this.employeeId = this.selectedEmployee.id;

      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.validateWording = "";

      this.unitError = false;
      this.dateError = false;
      this.detailError = false;
      this.reasonCancelError = false;
      
      await this.$store.dispatch("arRefundGuarantee/resetRefundGuarantee");
    },
    async roleByBookDataReady() {
      if(this.isSharedRoleByBookReady) {
        if(!this.isCreateDocument) {
          await this.getRefundGuarantee();
        }
      }
    },
    async getRefundGuarantee() {
      try {
        await this.$store.dispatch("arRefundGuarantee/getRefundGuaranteeById", this.id);
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    async checkShouldSetData() {
      if(this.isSharedRoleByBookReady) {
        if(this.refundGuarantee && this.isSharedUnitReady) {
          await this.setData();
          await this.setDataUnit();
        } else if(this.refundGuarantee && !this.isSharedUnitReady) {
          await this.setData();
        } else if(this.isCreateDocument && this.isSharedUnitReady) {
          this.setLoading(false);
        }
        await this.setCompareData();
      }
    },
    async setData() {
      // console.log("setData", this.refundGuarantee);
      this.code = this.refundGuarantee.code;
      this.date = new Date(this.refundGuarantee.date);
      this.referenceCode = this.refundGuarantee.refCode;
      this.customerName = this.refundGuarantee.customerName;
      this.employeeName = this.refundGuarantee.employeeName;
      this.paidFor = this.refundGuarantee.remark;
      this.details = this.refundGuarantee.refundGuaranteeDetail.sort(function (a, b) {
        return a.itemNo - b.itemNo;
      });
      this.employeeId = this.refundGuarantee.employeeId;

      this.setLoading(false);
    },
    async setDataUnit() {
      this.selectedUnit = this.sharedUnits.find(
        (unit) => unit.id === this.refundGuarantee.unitId
      );
      this.setLoading(false);
    },
    openProductDialog(data) {
      if (this.validateDocument(true)) {
        return;
      }
      this.selectedDetail = data;
      this.showProductDialog = true;
    },
    closeProductDialog() {
      this.showProductDialog = false;
      this.selectedDetail = null;
    },
    deleteRow(id) {
      if (this.validateDocument(true)) {
        return;
      }
      // console.log("deleteRow", id);
      const index = this.details.findIndex((detail) => detail.id === id);
      this.details.splice(index, 1);
      this.updateItemNumber();
    },
    updateItemNumber() {
      let number = 1;
      this.details.forEach((element) => {
        if (element.id) {
          element.itemNo = number;
        }
        number++;
      });
    },
    async getPrintForm(data) {
      if (!this.isRolePrint) {
        this.validateWording = this.lang.validatePrintPermission;
        this.showValidateDialog = true;
        return;
      }

      this.selectedRefundGuarantee = data;
      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: "SC",
      };
      const payload = { where };
      await this.getFormPrints(payload);

      if (this.filteredFormPrints.length == 1) {
        this.formPrintModalSelected(this.filteredFormPrints[0]);
      } else {
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path + ".pdf";
      this.print();
      this.showFormPrintModal = false;
    },
    async print() {
      this.clearBaseDialog();
      this.setLoading(true);
      try {
        let parameter = {
          Id: this.id,
        };

        let payload = {
          id: this.id,
          path: this.path,
          fileName: "RefundGuarantee",
          parameter: JSON.stringify(parameter),
        };

        await this.$store.dispatch("arRefundGuarantee/print", payload);
        await this.getPreviewBlob(this.fileBlob);
        await this.getRefundGuarantee();
        
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    clearBaseDialog() {
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.validateWording = "";

      this.showCancelDialog = false;
      this.reasonCancel = "";
      this.reasonCancelError = false;
      this.isCreateNewDocument = false;
    },
    async askForCancel() {
      this.clearBaseDialog();
      if (!this.isRoleCancel) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleCancel;
        this.showValidateDialog = true;
        return;
      }
      else if (this.validateDocument()) {
        return;
      }
      this.showCancelDialog = true;
      this.showValidateDialog = true;
    },
    async cancel() {
      if (this.reasonCancel.trim() == "") {
        this.reasonCancelError = true;
        return;
      } 
      this.showValidateDialog = false;

      this.setLoading(true);
      try {
        let payload = {
          id: this.id,
          remarkCancel: this.reasonCancel,
        };

        // console.log(payload);
        await this.$store.dispatch("arRefundGuarantee/cancelRefundGuarantee", payload);

        this.showCancelDialog = false;
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.cancelSuccess;
        this.validateWording = `${this.lang.cancelRefundGuaranteeSuccess.replace(
          ":code",
          this.code.trim()
        )}`;
        this.showValidateDialog = true;
      } catch (error) {
        this.clearBaseDialog();
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    openPendingDialog() {
      if (this.validateDocument(true)) {
        return;
      }
      this.showPendingDialog = true;
    },
    closePendingDialog() {
      this.showPendingDialog = false;
    },
    selectedProduct(data) {
      this.setLoading(true);
      if(data.id) {
        let detail = this.details.find((detail) => detail.id == data.id);
        detail.productId = data.productId;
        detail.remark = data.remark,
        detail.period = data.period,
        detail.unitPrice = data.unitPrice,
        detail.quantity = data.quantity,
        detail.billAmount = data.billAmount,
        detail.totalAmount = data.totalAmount,
        detail.discountDescription = data.discountDescription,
        detail.discountAmount = data.discountAmount,
        detail.referenceId = data.referenceId
      } else {
        data.itemNo = this.countDetails + 1;
        data.id = "dummy" + (this.countDetails + 1);
        this.details.push(data);
      }

      this.setLoading(false);
    },
    selectedPending(data) {
      this.detailError = false;
      this.showPendingDialog = false;
      let paidBy = this.paidFor;
      if (data) {
        data.forEach((receipt) => {
          let number = this.countDetails;
          this.referenceCode =
            "INV No: " + receipt.documentBookCode.trim() + "/" + receipt.code;
          let details = null;
          details = this.details.find(
            (detail) => detail.referenceId === receipt.detailId
          );

          if(!paidBy){
            paidBy = receipt.paidBy;
          }

          if (details == null) {
            let detail = {
              id: "dummy" + (number + 1),
              itemNo: (number + 1),
              headerId: this.id,
              productId: receipt.productId,
              productCode: receipt.productCode,
              productName: receipt.productName,
              quantity: receipt.quantity,
              unitPrice: receipt.unitPrice,
              discountDescription: receipt.detailDiscountDescription,
              discountAmount: receipt.detailDiscountAmount,
              totalAmount: receipt.detailPendingAmount,
              billAmount: receipt.detailPendingAmount,
              remark: receipt.detailRemark,
              referenceId: receipt.detailId,
              period: receipt.detailPeriod,
            };
            this.details.push(detail);
          } 
        });
        this.paidFor = paidBy;
      }
    },
    async save() {
      this.clearBaseDialog();
      if (this.validateData()) {
        return;
      }

      this.setLoading(true);
      try {
        // console.log("this.payload", this.payload);
        await this.$store.dispatch(
          "arRefundGuarantee/createOrUpdateRefundGuarantee",
          this.payload
        );

        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.saveSuccess;
        this.validateWording = this.saveEditSuccess;
        this.showValidateDialog = true;
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);

      if (this.isCreateDocument) {
        await this.$router.replace({name: "ar-refund-guarantee-edit", params: {id: this.refundGuarantee.id}});
        await this.setCompareData();
      }
    },
    async setCompareData() {
      let details = [];

      for (const each of this.details.filter((each) => each.productId)) {
        let detail = null;
        if (each.id.includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        details.push(detail);
      }
      let objToCompare = {
        projectId: this.selectedProject.id,
        unitId: this.selectedUnit ? this.selectedUnit.id : "",
        customerId: this.selectedUnit ? this.selectedUnit.customerId : "",
        employeeId: this.employeeId,
        refCode: this.referenceCode,
        date: this.date ? this.formatDateToISOString(this.date) : null,
        remark: this.paidFor,
        payType: "Cash",
        diffAmt: 0.00,
        refundGuaranteeDetail: details,
        type: "Add",
      };

      if (!this.isCreateDocument) {
        objToCompare = {
          ...objToCompare,
          id: this.id,
          type: "Update",
        };
      }

      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
    },
    async askForCreate() {
      this.clearBaseDialog();
      if (!this.isRoleCreate) {
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return;
      }
      let result = this.isCompareObject(this.objToCompare, this.payload);
      this.isCreateNewDocument = true;
      if(result) {
        this.titleConfirm = this.lang.createNewRefundGuarantee;
        this.validateWording = [this.lang.askForCreateNewDoc,this.lang.askForCreateNewDocConfirm];
        this.showValidateDialog = true; 
      } else {
        await this.createNewDocument();
      }     
    },
    async createNewDocument() {      
      this.setLoading(true);
      await this.initiateData();
      await this.$router.push({name: "ar-refund-guarantee-edit", params: {id: "create"}});
      await this.setCompareData();
      this.showValidateDialog = false; 
      
      if (this.isSharedUnitReady) {
        this.setLoading(false);
      }
    },
    setLocalSearch() {
      let storageName = `erp_search_RefundGuaranteeList_${this.selectedProject.code}_${this.userCode}`;
      const localSearch = localStorage.getItem(storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      // console.log("search=",search);
      localStorage.setItem(storageName, JSON.stringify(search));
    },
    clearDataDetails() {
      this.dateError = false;
      this.details = [];
      this.referenceCode = "";
    },
    validateDocument(isCheckPrinted = false) {
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.notificationDialogHeader;
      if (this.refundGuarantee && this.refundGuarantee.cancelStatus.trim().toLowerCase() === "c") {
        this.validateWording = this.lang.validateCancelled;
        this.showValidateDialog = true;
        return true;
      }
      if (isCheckPrinted && this.refundGuarantee && this.refundGuarantee.postStep.trim().toLowerCase() === "p") {
        this.validateWording = this.lang.validatePrinted;
        this.showValidateDialog = true;
        return true;
      }
      if (this.refundGuarantee && this.refundGuarantee.postStep.trim().toLowerCase() === "a") {
        this.validateWording = this.lang.validateReferenceVoucher;
        this.showValidateDialog = true;
        return true;
      }
    },
    validateData() {
      let returnStatus = false;

      if (this.validateDocument(true)) {
        return true;
      }
      
      if (!this.date) {
        this.dateError = true;
        returnStatus = true;
      }

      if (this.isSharedUnitReady && !this.selectedUnit) {
        this.unitError = true;
        returnStatus = true;
      }

      if (this.countDetails <= 0) {
        this.detailError = true;
        returnStatus = true;
      }

      if (this.netTotalAmount <= 0) {
        returnStatus = true;
      }

      if (returnStatus) {
        this.clearBaseDialog();
        this.baseDialogClassTitle = "p-text-error";
        this.titleConfirm = this.lang.validateSaveTitle;
        this.validateWording = this.wordingValidate();
        this.showValidateDialog = true;
      }

      return returnStatus;
    },
    wordingValidate(){
      if (!this.date) {
        return this.lang.validateDropDownNoSelect + this.lang.documentDate;
      }
      
      if (!this.selectedUnit) {
        return this.lang.validateDropDownNoSelect + this.lang.unitCode;
      }

      if (this.countDetails <= 0) {
        return this.lang.validateDetail;
      }

      if (this.netTotalAmount <= 0) {
        return this.lang.validateNetTotalAmount;
      }
    },
  },
  computed: {
    payload() {
      let details = [];

      for (const each of this.details.filter((each) => each.productId)) {
        let detail = null;
        if (each.id.includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        details.push(detail);
      }
      let payload = {
        projectId: this.selectedProject.id,
        unitId: this.selectedUnit ? this.selectedUnit.id : "",
        customerId: this.selectedUnit ? this.selectedUnit.customerId : "",
        employeeId: this.employeeId,
        refCode: this.referenceCode,
        date: this.date ? this.formatDateToISOString(this.date) : null,
        remark: this.paidFor,
        payType: "Cash",
        diffAmt: 0.00,
        refundGuaranteeDetail: details,
        type: "Add",
      };

      if (!this.isCreateDocument) {
        payload = {
          ...payload,
          id: this.id,
          type: "Update",
        };
      }

      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    isCreateDocument() {
      return this.id === "create";
    },
    roleByDocumentBook() {
      return this.getRoleByBookByCode("SC");
    },
    isRoleCreate() {
      if (this.roleByDocumentBook && this.roleByDocumentBook.insert == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRoleUpdate() {
      if (this.roleByDocumentBook && this.roleByDocumentBook.update == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRoleCancel() {
      if (this.roleByDocumentBook && this.roleByDocumentBook.cancel == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRolePrint() {
      if (this.roleByDocumentBook && this.roleByDocumentBook.print == "Y") {
        return true;
      } else {
        return false;
      }
    },
    title() {
      let result = this.lang.createRefundGuarantee;
      if (!this.isCreateDocument) {
        result = this.lang.editRefundGuarantee;
      }
      return result;
    },
    sharedUnits() {
      return this.$store.getters["shared/unitUnits"];
    },
    selectedEmployee() {
      return this.$store.getters.employee;
    },
    isSelectedUnit() {
      if (this.isSharedUnitReady && this.selectedUnit) {
        return true;
      }
      return false;
    },
    refundGuarantee() {
      return this.$store.getters["arRefundGuarantee/selectedRefundGuarantee"];
    },
    countDetails() {
      let result = this.details.length;
      return result;
    },
    fileBlob() {
      return this.$store.getters["arRefundGuarantee/blob"];
    },
    isDocumentLocked() {
      if(this.refundGuarantee) {
        if(this.refundGuarantee.postStep.trim() == "A" || this.refundGuarantee.postStep.trim() == "P" || this.refundGuarantee.cancelStatus.trim() == "C") {
          return true;
        }
      }
      return false;
    },
    netAmount() {
      let netAmount = 0;
      if (this.details) {
        this.details.forEach((detail) => {
          netAmount += detail.totalAmount;
        });
      }
      return netAmount;
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "cancel",
          type: "cancel",
          action: "gotoCancel",
          isDisable: !this.isSharedUnitReady || this.isCreateDocument,
        },
        {
          id: "create",
          type: "create",
          action: "gotoCreate",
        },
        {
          id: "print",
          type: "print",
          action: "gotoPrint",
          isDisable: !this.isSharedUnitReady || this.isCreateDocument,
        },
        {
          id: "save",
          type: "save",
          action: "gotoSave",
          isDisable: !this.isSharedUnitReady,
        },
      ];
      return dataButtons;
    },
    dataButtonDialog() {
      let dataButtonDialog = [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];

      if (this.showCancelDialog) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "confirmCancel",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-cancel",
          },
        ];
      } else if (this.isCreateNewDocument) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "gotoCreate",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-create",
          },
        ];
      }

      return dataButtonDialog;
    },
    saveEditSuccess() {
      let result = this.lang.editRefundGuaranteeSuccess;
      if (this.isCreateDocument) {
        result = this.lang.createRefundGuaranteeSuccess;
      }
      return result;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
  },
  watch: {
    refundGuarantee() {
      this.checkShouldSetData();
    },
    isSharedRoleByBookReady() {
      this.roleByBookDataReady();
    },
    payload(curValue) {
      if (this.objToCompare) this.compareObject(this.objToCompare, curValue);
    },
  }
};
</script>
