export default {
	title: "หนังสือทวงถาม",
    printLot: "พิมพ์หนังสือทวงถามเป็นชุด", 
    selectDate: "วันที่",
    selectMonth: "เดือน",
    selectCode: "เลขที่เอกสาร",
    selectUnit: "ยูนิต",
    selectAddress: "บ้านเลขที่",
    selectCustomerName: "ชื่อ",
    selectAll: "ทั้งหมด",
    
    columnDate: "วันที่เอกสาร",
    columnCode: "เลขที่เอกสาร",
    columnUnit: "เลขที่ห้อง/ยูนิต",
    columnAddress:"บ้านเลขที่",
    columnCustomerName: "ชื่อเจ้าของ",
    columnPostStep:"พิมพ์",
    columnIsPaid:"ชำระ",
    columnIsCancel:"ยกเลิก",  
    columnTotalAmount: "จำนวนเงิน",
    columnCreateBy: "ผู้สร้าง",
    columnCreateDate: "วันที่สร้าง",
    columnUpdateBy: "ผู้แก้ไข",
    columnUpdateDate: "วันที่แก้ไข",
    
    printed: "พิมพ์แล้ว",
    fullPayment: "จ่ายเต็มจำนวน",
    splitRefund: "แบ่งชำระ",
    codeStart: "เลขที่เอกสาร ตั้งแต่",
    codeEnd: "เลขที่เอกสาร ถึง",
    unitStart: "เลขที่ห้อง/ยูนิต ตั้งแต่",
    unitEnd: "เลขที่ห้อง/ยูนิต ถึง",
    isIgnoreZero: "พิมพ์เฉพาะใบที่มีมูลค่า",
    isGroup: "พิมพ์แบบรวมรายการ",
	no: "ไม่ใช่",
	labelSearchBy: "ค้นหารายการตาม",
    
};

