export default {
	setInvoice(state, payload) {
		// state.project.length = 0;
		state.invoices = payload.data;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
	setSelectedInvoice(state, payload) {
		state.selectedInvoice = payload;
	},
	setDataReferenceDocument(state, payload) {
		state.dataReferenceDocument = payload.data;
	},
	setBlob(state, payload) {
		state.blob = payload;
	},
	resetInvoiceDateForPrintLot(state) {
		state.invoiceDateForPrintLots = [];
		state.invoiceDateForPrintLotTotalPage = 0;
		state.invoiceDateForPrintLotCurrentPage = 0;
	},
	setInvoiceDateForPrintLot(state, payload) {
		let invoiceDateForPrintLots = [...state.invoiceDateForPrintLots];
		for (const invoiceDateForPrintLot of payload.invoiceDateForPrintLots) {
			if (invoiceDateForPrintLots.findIndex((c) => c.date === invoiceDateForPrintLot.date) < 0) {
				invoiceDateForPrintLots.push(invoiceDateForPrintLot);
			}
		}
		state.invoiceDateForPrintLots = invoiceDateForPrintLots;
		state.invoiceDateForPrintLotCurrentPage = payload.invoiceDateForPrintLotCurrentPage;
		state.invoiceDateForPrintLotTotalPage = payload.invoiceDateForPrintLotTotalPage;
	},
	resetInvoiceDateForCombineUnit(state) {
		state.invoiceDateForCombineUnits = [];
		state.invoiceDateForCombineUnitTotalPage = 0;
		state.invoiceDateForCombineUnitCurrentPage = 0;
	},
	setInvoiceDateForCombineUnit(state, payload) {
		let invoiceDateForCombineUnits = [...state.invoiceDateForCombineUnits];
		for (const invoiceDateForCombineUnit of payload.invoiceDateForCombineUnits) {
			if (
				invoiceDateForCombineUnits.findIndex((c) => c.date === invoiceDateForCombineUnit.date) < 0
			) {
				invoiceDateForCombineUnits.push(invoiceDateForCombineUnit);
			}
		}
		state.invoiceDateForCombineUnits = invoiceDateForCombineUnits;
		state.invoiceDateForCombineUnitCurrentPage = payload.invoiceDateForCombineUnitCurrentPage;
		state.invoiceDateForCombineUnitTotalPage = payload.invoiceDateForCombineUnitTotalPage;
	},
	resetInvoiceDataByAtDate(state) {
		state.invoiceDataByAtDates = [];
		state.invoiceDataByAtDateTotalPage = 0;
		state.invoiceDataByAtDateCurrentPage = 0;
	},
	setInvoiceDataByAtDate(state, payload) {
		let invoiceDataByAtDates = [...state.invoiceDataByAtDates];
		for (const invoiceDataByAtDate of payload.invoiceDataByAtDates) {
			if (invoiceDataByAtDates.findIndex((c) => c.id === invoiceDataByAtDate.id) < 0) {
				invoiceDataByAtDates.push(invoiceDataByAtDate);
			}
		}
		state.invoiceDataByAtDates = invoiceDataByAtDates;
		state.invoiceDataByAtDateCurrentPage = payload.invoiceDataByAtDateCurrentPage;
		state.invoiceDataByAtDateTotalPage = payload.invoiceDataByAtDateTotalPage;
	},
	setInvoiceNotification(state, payload) {
		state.dataInvoiceNotification = payload.data;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
	setAllInvoiceNotification(state, payload) {
		// state.project.length = 0;
		let allInvoiceNotification = [...state.allInvoiceNotification];
		for (const notify of payload.data) {
			if (allInvoiceNotification.findIndex((c) => c.id === notify.id) < 0) {
				notify.markStatus = "";
				allInvoiceNotification.push(notify);
			}
		}
		state.allInvoiceNotification = allInvoiceNotification;
		state.invoiceNotifyTotalPage = payload.page.pageCount;
		state.invoiceNotifyCurrentPage = payload.page.currentPage;
	},

	resetAllInvoiceNotification(state) {
		state.allInvoiceNotification = [];
		state.invoiceNotifyTotalPage = 0;
		state.invoiceNotifyCurrentPage = 0;
	},

	//หน้าใบเสร็จ ใช้หายอดค้างคงเหลือ
	setPendingAmountByUnitAndLivingType(state, payload) {
		state.pendingAmountByUnitAndLivingType = payload;
	},
	setPendingList(state, payload) {
		// let pendingLists = [...state.pendingLists];
		// for (const pendingList of payload.data) {
		//     if(pendingLists.findIndex(c => c.detailId === pendingList.detailId) < 0){
		//         pendingLists.push(pendingList);
		//     }
		// }
		state.pendingLists = payload.data;
		state.pendingListCurrentPage = payload.page.currentPage;
		state.pendingListTotalPage = payload.page.pageCount;
	},
	resetPendingList(state) {
		state.pendingLists = [];
		state.pendingListTotalPage = 0;
		state.pendingListCurrentPage = 0;
	},
	setPendingByAmount(state, payload) {
		state.pendingByAmount = payload;
	},
	
	//  importInvoice	
	resetImportInvoiceList(state) {
		state.importInvoiceList = [];
	},
    setImportInvoiceList(state, payload){
        state.importInvoiceList = payload
    },
    setValidatedImportInvoice(state, payload){
        state.validatedImportInvoice = payload
    },
};