export default {
  receiptCodeStart: "เลขที่ใบเสร็จตั้งแต่",
  receiptPrintListPage: "พิมพ์รายการใบเสร็จ",
  receiptPrintEditPage: "พิมพ์รายละเอียดใบเสร็จ",
  lot: "เป็นชุด",
	validateNoCode: "กรุณาระบุเลขที่ใบเสร็จ",
	validateDateSelect: "กรุณาเลือกวันที่",
  receiptDateStart: "วันที่ใบเสร็จตั้งแต่",
	to: "ถึง",  
	fileName: "ชื่อไฟล์",
};
