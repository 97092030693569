<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoSave="save"
  ></base-toolbar>

  <base-dialog
    v-model:visible="showValidateDialog"
    :classTitle="baseDialogClassTitle"
    :titleConfirm="titleConfirm"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    @confirm="showValidateDialog = false"
    @close="showValidateDialog = false"
    @confirm-change-status="confirmChangeStatus"
  ></base-dialog>

  <stock-keycard-history-dialog
    v-model:visible="showHistoryDialog"
    :history="selectedHistory"
    @close="showHistoryDialog = false"
    :key="keyToReLoad"
  ></stock-keycard-history-dialog>
  <base-container>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{ lang.title }}</label>
      <PvDivider></PvDivider>

      <PVTabView v-model:activeIndex="indexTabView">
        <PVTabPanel>
          <template #header>
            <span>{{ lang.details }}</span>
          </template>
        </PVTabPanel>

        <PVTabPanel>
          <template #header>
            <span>{{ lang.editHistory }}</span>
          </template>
        </PVTabPanel>
      </PVTabView>
      <!-- รายละเอียดทั่วไป -->
      <div v-show="indexTabView === 0">
        <section class="flex flex-wrap fontlabel pt-3" :class="textFormClass">
          <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
            <label for="date">{{ lang.date }}*</label>
            <PvCalendar
              id="date"
              v-model="date"
              class="inputfield w-full mt-1"
              showIcon
              dateFormat="dd/mm/yy"
              :class="{ 'p-invalid': dateError }"
              @date-select="dateError = false"
            />
            <small v-if="dateError" id="code-help" class="p-error">
              {{ lang.validateDateError }}
            </small>
          </div>
          <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
            <label for="createBy">{{ lang.createBy }}</label>
            <PvInputText
              id="createBy"
              type="text"
              class="inputfield w-full mt-1 p-inputtext-disabled"
              v-model="createBy"
              disabled
            />
          </div>
          <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
            <label for="createDate">{{ lang.createDate }}</label>
            <PvCalendar
              id="createDate"
              v-model="createDate"
              class="inputfield w-full mt-1"
              inputClass="p-inputtext-disabled"
              showIcon
              dateFormat="dd/mm/yy"
              disabled
            />
          </div>
          <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
            <label for="productCode">{{ lang.productCode }}*</label>
            <PvSkeleton
              v-if="!isSharedProductReady"
              class="inputfield w-full mt-1"
              height="39px"
            />
            <base-product-ar-auto-complete
              id="product"
              v-model="selectedProduct"
              class="inputfield w-full mt-1"
              @load-finish="productDataReady"
              :class="{ 'p-invalid': productError }"
              v-show="isSharedProductReady"
              @item-select="productError = false"
              filterType="keycard"
            />
            <small v-if="productError" id="product-help" class="p-error">
              {{ lang.validateProduct }}
            </small>
          </div>
          <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
            <label for="runningNo">{{ lang.runningNo }}*</label>
            <PvInputText
              id="runningNo"
              type="text"
              class="inputfield w-full mt-1"
              v-model="runningNo"
              :class="{ 'p-invalid': runningNoError }"
              @keyup="runningNoError = false"
            />
            <small v-if="runningNoError" id="runningNo-help" class="p-error">
              {{ lang.validateRunningNo }}
            </small>
          </div>
          <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
            <label for="serialNo">{{ lang.serialNo }}</label>
            <PvInputText
              id="serialNo"
              type="text"
              class="inputfield w-full mt-1"
              v-model="serialNo"
            />
          </div>
          <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
            <label for="status">{{ lang.status }}</label>
            <PvDropdown
              id="status"
              class="inputfield w-full mt-1"
              :options="status"
              optionLabel="name"
              optionValue="code"
              v-model="selectedStatus"
              scrollHeight="300px"
              @change="changeStatus"
            />
          </div>
          <div class="lg:col-8 md:col-12 col-12 pl-0 pr-2">
            <!-- <label for="remark"
              >{{ lang.remark }}{{ !isStatusReady ? "*" : "" }}</label
            > -->
            <!-- <PvInputText
              id="remark"
              type="text"
              class="inputfield w-full mt-1"
              v-model="remark"
              :class="{ 'p-invalid': remarkError }"
              @keyup="remarkError = false"
            /> -->
            <!-- <small v-if="remarkError" id="remark-help" class="p-error">
              {{ lang.validateRemark }}
            </small> -->
            <base-input-text-limit
              :caption="isvalidateRemark ? lang.remark+'*' : lang.remark"
              :limitMessage="250"
              :isError="remarkError"
              :errorMessage="lang.validateRemark"
              :data="remark"
              @data="updateRemark"
            />
          </div>

          <div class="flex flex-wrap w-full pt-4">
            <div class="col-12 pl-0">
              <label class="font-lg font-normal">{{
                lang.ownerDetails
              }}</label>
            </div>
            <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
              <label for="unit">{{ lang.unit }}</label>
              <PvSkeleton
                v-if="!isSharedUnitReady"
                class="inputfield w-full mt-1"
                height="39px"
              />
              <base-unit-auto-complete
                id="unit"
                v-model="selectedUnit"
                class="inputfield w-full mt-1"
                @load-finish="unitDataReady"
                v-show="isSharedUnitReady"
                @item-select="selectUnit"
                :isValidateActiveStatus="true"
                @is-inactive="unitIsInactive"
                :class="
                  !isStatusSold
                    ? 'p-inputtext-disabled'
                    : unitError
                    ? 'p-invalid'
                    : ''
                "
                :disabled="!isStatusSold"
              />
              <small v-if="unitError" id="unit-help" class="p-error">
                {{ lang.validateUnit }}
              </small>
            </div>
            <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
              <label for="receiptCode">{{ lang.receiptCode }}</label>
              <PvInputText
                id="receiptCode"
                type="text"
                class="inputfield w-full mt-1 p-inputtext-disabled"
                v-model="receiptCode"
                disabled
              />
            </div>
          </div>
        </section>
      </div>
      <!-- ประวัติการแก้ไข -->
      <div class="pt-4" v-show="indexTabView === 1">
        <div class="p-datatable-border my-2">
          <PvDataTable
            :value="histories"
            v-model:selection="selectedHistory"
            selectionMode="single"
            dataKey="id"
            responsiveLayout="scroll"
            class="p-datatable-xs"
            :class="textTableClass"
            :paginator="true"
            :rows="rowsPerPage"
            :rowHover="true"
            paginatorTemplate="CurrentPageReport    FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 50, 100]"
            :currentPageReportTemplate="
              lang.page + ` {currentPage} / {totalPages}`
            "
            :globalFilterFields="[
              'name',
              'country.name',
              'representative.name',
              'status',
            ]"
            scrollable
          >
            <PvColumn
              field="updateDate"
              :header="lang.updateDate"
              style="min-width: 10rem; height: 56px"
              headerClass="h-left font-normal"
              bodyClass="text-left"
              sortable
            >
              <template #body="slotProps">
                {{ formatDateTime(slotProps.data.updateDate) }}
              </template>
            </PvColumn>
            <PvColumn
              field="updateBy"
              :header="lang.updateBy"
              style="min-width: 6rem; height: 56px"
              headerClass="h-left font-normal"
              bodyClass="text-left"
            />
            <PvColumn
              field="productCode"
              :header="lang.productCode"
              style="min-width: 4rem; height: 56px"
              headerClass="h-left font-normal"
              bodyClass="text-left"
            />
            <PvColumn
              field="code"
              :header="lang.runningNo"
              style="min-width: 8rem; height: 56px"
              headerClass="h-left font-normal"
              bodyClass="text-left"
            />
            <PvColumn
              field="unitCode"
              :header="lang.unitCode"
              style="min-width: 4rem; height: 56px"
              headerClass="h-left font-normal"
              bodyClass="text-left"
            />
            <PvColumn
              field="unitAddressNo"
              :header="lang.unitAddressNo"
              style="min-width: 4rem; height: 56px"
              headerClass="h-left font-normal"
              bodyClass="text-left"
            />
            <PvColumn
              field="receiptCode"
              :header="lang.receiptCode"
              style="min-width: 8rem; height: 56px"
              headerClass="h-left font-normal"
              bodyClass="text-left"
            />
            <PvColumn
              field="status"
              :header="lang.status"
              style="min-width: 10rem; height: 56px"
              headerClass="h-left font-normal"
              bodyClass="text-left"
            >
              <template #body="slotProps">
                <base-tag
                  :Caption="setBaseTag(slotProps.data.status).caption"
                  :Class="setBaseTag(slotProps.data.status).class"
                />
              </template>
            </PvColumn>
            <PvColumn
              field="remark"
              :header="lang.remark"
              style="min-width: 10rem; height: 56px"
              headerClass="h-left font-normal"
              bodyClass="text-left"
            />
            <PvColumn
              style="min-width: 3rem"
              headerClass="h-center"
              bodyStyle="text-align: center;justify-content: center;align-items: center;"
              alignFrozen="right"
              frozen
            >
              <template #body="slotProps">
                <PvButton
                  type="button"
                  class="p-button-secondary p-button-text p-0"
                  @click="viewHistory(slotProps.data)"
                >
                  <icon-eye iconColor="secondary" width="24" height="24" />
                </PvButton>
              </template>
            </PvColumn>
            <template #empty>
              <div class="w-full p-3" style="text-align: center">
                <label>{{ this.lang.tableEmpty }}</label>
              </div>
            </template>
          </PvDataTable>
        </div>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, ref, onMounted } from "vue";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import StockKeycardHistoryDialog from "../../../components/AR/stockKeycard/StockKeycardHistoryDialog.vue";
import BaseDialog from "../../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import usePageDirty from "../../../hooks/pageDirty";
export default {
  components: {
    StockKeycardHistoryDialog,
  },
  props: ["id", "projectCode"],
  setup(props) {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatDateToISOString, formatDate, formatDateTime } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.stockKeycard.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.stockKeycard.edit,
        };
      }
    });
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();
    const { compareObject, isCompareObject } = usePageDirty();
    const router = useRouter();

    let showValidateDialog = ref(false);
    let baseDialogClassTitle = ref("");
    let titleConfirm = ref("");
    let validateWording = ref("");

    let selectedPage = ref(1);
    let rowsPerPage = ref(10);

    let date = ref(null);
    let dateError = ref(false);
    let createBy = ref("");
    let createDate = ref(null);
    let selectedProduct = ref(null);
    let isSharedProductReady = ref(false);
    let productError = ref(false);
    let runningNo = ref("");
    let runningNoError = ref(false);
    let serialNo = ref("");
    let remark = ref("");
    let remarkError = ref(false);
    let isSharedUnitReady = ref(false);
    let selectedUnit = ref(null);
    let unitError = ref(false);
    let receiptCode = ref("");
    let receiptDetailId = ref("");

    const status = ref([
      { name: mergeLang.value.statusReady, code: "ready" },
      { name: mergeLang.value.statusSold, code: "sold" },
      { name: mergeLang.value.statusLost, code: "lost" },
      { name: mergeLang.value.statusBroken, code: "broken" },
      { name: mergeLang.value.statusSpare, code: "spare" },
      { name: mergeLang.value.statusOther, code: "other" },
    ]);
    let selectedStatus = ref("ready");

    let histories = ref([]);
    let selectedHistory = ref(null);
    let showHistoryDialog = ref(false);
    let keyToReLoad = ref(0);
    let isChangeStatus = ref(false);
    let isConfirmChangeStatus = ref(false);
    let objToCompare = ref(null);
    let isPageDirty = ref(false);
    let indexTabView = ref(0);

    onMounted(async () => {
      setLoading(true);
      initiateData();
      await roleByMenuDataReady();
      checkShouldSetData();
      setLocalSearch();
    });

    const dataButtons = computed(() => {
      let result = [
        {
          id: "save",
          action: "gotoSave",
          type: "save",
          isDisable: !isSharedProductReady.value || !isSharedUnitReady.value,
        },
      ];
      return result;
    });
    const dataButtonDialog = computed(() => {
      let result = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];
      if (isChangeStatus.value) {
        result = [
          {
            id: "close",
            caption: mergeLang.value.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "confirmChangeStatus",
            caption: mergeLang.value.buttonDialogConfirm,
            class: "w-full",
            action: "confirm-change-status",
          },
        ];
      }
      return result;
    });

    const userName = computed(() => {
      return store.getters.userName;
    });
    const userCode = computed(() => {
      return store.getters.userCode;
    });
    const isCreateDocument = computed(() => {
      return props.id === "create";
    });
    const title = computed(() => {
      let result = mergeLang.value.createKeycard;
      if (!isCreateDocument.value) {
        result = mergeLang.value.editKeycard;
      }
      return result;
    });
    const selectedStockKeycard = computed(() => {
      return store.getters["arStockKeycard/selectedStockKeycard"];
    });
    const roleByMenu = computed(() => {
      return getRoleByMenuByCode("pms_keycard");
    });
    const isRoleCreate = computed(() => {
      if (roleByMenu.value && roleByMenu.value.insert == "Y") {
        return true;
      }
      return false;
    });
    const isRoleUpdate = computed(() => {
      if (roleByMenu.value && roleByMenu.value.update == "Y") {
        return true;
      }
      return false;
    });
    const isStatusReady = computed(() => {
      if (selectedStatus.value == "ready") {
        return true;
      }
      return false;
    });
    const isStatusSold = computed(() => {
      if (selectedStatus.value == "sold") {
        return true;
      }
      return false;
    });
    const isvalidateRemark = computed(() => {
      if (!isStatusReady.value && !isStatusSold.value) {
        return true;
      }
      return false;
    });
    const sharedProducts = computed(() => {
      return store.getters["shared/productArProducts"];
    });
    const sharedUnits = computed(() => {
      return store.getters["shared/unitUnits"];
    });
    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const payload = computed(() => {
      return {
        projectId: selectedProject.value.id,
        code: runningNo.value ? runningNo.value.trim() : "",
        serial: serialNo.value ? serialNo.value.trim() : "",
        date: date.value ? formatDateToISOString(date.value) : "",
        productId: selectedProduct.value ? selectedProduct.value.id : "",
        unitId: selectedUnit.value ? selectedUnit.value.id : "",
        buyPrice: 0,
        status: selectedStatus.value,
        remark: remark.value ? remark.value.trim() : "",
        receiptDetailId: receiptDetailId.value ? receiptDetailId.value : "",
      };
    });

    const setLoading = (bool) => {
      store.dispatch("setLoading", { value: bool });
    };

    const goBack = () => {
      setCompareObject();
      router.push({ name: "ar-stock-keycard" });
    };

    const productDataReady = () => {
      isSharedProductReady.value = true;
      checkShouldSetData();
    };

    const unitDataReady = () => {
      isSharedUnitReady.value = true;
      checkShouldSetData();
    };

    const getStockKeycardById = async () => {
      try {
        await store.dispatch("arStockKeycard/getStockKeycardById", props.id);
      } catch (error) {
        baseDialogClassTitle.value = "";
        titleConfirm.value = mergeLang.value.notificationDialogHeader;
        validateWording.value = error;
        showValidateDialog.value = true;
        setLoading(false);
      }
    };

    const clearBaseDialog = () => {
      baseDialogClassTitle.value = "";
      titleConfirm.value = "";
      validateWording.value = "";
      isChangeStatus.value = false;
    };

    const initiateData = () => {
      indexTabView.value = 0;
      date.value = null;
      dateError.value = false;
      createBy.value = userName.value;
      createDate.value = new Date();
      selectedProduct.value = null;
      isSharedProductReady.value = false;
      productError.value = false;
      runningNo.value = "";
      runningNoError.value = false;
      serialNo.value = "";
      remark.value = "";
      selectedStatus.value = "ready";
      selectedUnit.value = null;
      receiptCode.value = "";
      isSharedUnitReady.value = false;
      histories.value = [];
      selectedHistory.value = null;
      keyToReLoad.value = 0;
      isChangeStatus.value = false;
      isConfirmChangeStatus.value = false;
      isPageDirty.value = false;
      receiptDetailId.value = "";
      clearBaseDialog();
      store.dispatch("arStockKeycard/resetStockKeycard");
    };

    const roleByMenuDataReady = async () => {
      if (isSharedRoleByMenuReady.value && !isCreateDocument.value) {
        await getStockKeycardById();
      }
    };

    const setData = () => {
      date.value = new Date(selectedStockKeycard.value.date);
      createBy.value = selectedStockKeycard.value.createBy;
      createDate.value = new Date(selectedStockKeycard.value.createDate);
      runningNo.value = selectedStockKeycard.value.code;
      serialNo.value = selectedStockKeycard.value.serial;
      remark.value = selectedStockKeycard.value.remark;
      selectedStatus.value = selectedStockKeycard.value.status;
      histories.value = selectedStockKeycard.value.histories;
      receiptDetailId.value = selectedStockKeycard.value.receiptDetailId;
      receiptCode.value = selectedStockKeycard.value.receiptCode;
      setLoading(false);
    };

    const setDataProduct = () => {
      selectedProduct.value = sharedProducts.value.find(
        (data) => data.id == selectedStockKeycard.value.productId
      );
    };

    const setDataUnit = () => {
      selectedUnit.value = sharedUnits.value.find(
        (data) => data.id == selectedStockKeycard.value.unitId
      );
    };

    const checkShouldSetData = () => {
      if (isSharedRoleByMenuReady.value) {
        if (
          selectedStockKeycard.value &&
          isSharedProductReady.value &&
          isSharedUnitReady.value
        ) {
          setData();
          setDataProduct();
          setDataUnit();
        } else if (selectedStockKeycard.value && isSharedProductReady.value) {
          setData();
          setDataProduct();
        } else if (
          isCreateDocument.value &&
          isSharedProductReady.value &&
          isSharedUnitReady.value
        ) {
          setLoading(false);
        }
        setCompareData();
      }
    };

    const validateRole = () => {
      clearBaseDialog();
      if (isCreateDocument.value && !isRoleCreate.value) {
        baseDialogClassTitle.value = "";
        titleConfirm.value = mergeLang.value.notificationDialogHeader;
        validateWording.value = mergeLang.value.validateRoleInsert;
        showValidateDialog.value = true;
        return true;
      } else if (!isCreateDocument.value && !isRoleUpdate.value) {
        baseDialogClassTitle.value = "";
        titleConfirm.value = mergeLang.value.notificationDialogHeader;
        validateWording.value = mergeLang.value.validateRoleUpdate;
        showValidateDialog.value = true;
        return true;
      }
      return false;
    };

    const wordingValidate = () => {
      if (!date.value) {
        return mergeLang.value.validateDropDownNoSelect + mergeLang.value.date;
      }
      if (!selectedProduct.value) {
        return (
          mergeLang.value.validateDropDownNoSelect + mergeLang.value.productCode
        );
      }
      if (!runningNo.value) {
        return mergeLang.value.validateFieldNoValue + mergeLang.value.runningNo;
      }
      if (isvalidateRemark.value && !remark.value) {
        return mergeLang.value.validateFieldNoValue + mergeLang.value.remark;
      }
      if (!selectedUnit.value && isStatusSold.value) {
        return mergeLang.value.validateDropDownNoSelect + mergeLang.value.unit;
      }
    };

    const validateData = () => {
      let returnStatus = false;
      if (!date.value) {
        dateError.value = true;
        returnStatus = true;
      }
      if (!selectedProduct.value) {
        productError.value = true;
        returnStatus = true;
      }
      if (!runningNo.value) {
        runningNoError.value = true;
        returnStatus = true;
      }
      if (isvalidateRemark.value && !remark.value) {
        remarkError.value = true;
        returnStatus = true;
      }
      if (!selectedUnit.value && isStatusSold.value) {
        unitError.value = true;
        returnStatus = true;
      }
      if (returnStatus) {
        clearBaseDialog();
        baseDialogClassTitle.value = "p-error";
        titleConfirm.value = mergeLang.value.validateSaveTitle;
        validateWording.value = wordingValidate();
        showValidateDialog.value = true;
      }
      return returnStatus;
    };

    const validateChangeStatus = () => {
      let statusBefore = selectedStockKeycard.value.status;
      let statusAfter = selectedStatus.value;
      if (statusBefore == "sold" && statusAfter != "sold") {
        receiptDetailId.value = "";
        isChangeStatus.value = true;
        baseDialogClassTitle.value = "";
        titleConfirm.value = mergeLang.value.notificationDialogHeader;
        validateWording.value = [
          mergeLang.value.validateChangeStatusLine1,
          mergeLang.value.validateChangeStatusLine2,
          mergeLang.value.validateChangeStatusLine3,
        ];
        showValidateDialog.value = true;
        setLoading(false);
        return true;
      }
      return false;
    };

    const addStockKeycard = async () => {
      await store.dispatch("arStockKeycard/createStockKeycard", payload.value);
    };

    const updateStockKeycard = async () => {
      let payloadUpdate = {
        ...payload.value,
        id: props.id,
      };
      await store.dispatch("arStockKeycard/updateStockKeycard", payloadUpdate);
    };

    const save = async () => {
      if (validateRole()) {
        return;
      }
      if (validateData()) {
        return;
      }
      setLoading(true);
      try {
        if (isCreateDocument.value) {
          await addStockKeycard();
        } else {
          if (!isConfirmChangeStatus.value) {
            if (validateChangeStatus()) {
              return;
            }
          }
          await updateStockKeycard();
        }

        clearBaseDialog();
        baseDialogClassTitle.value = "p-text-success";
        titleConfirm.value = mergeLang.value.saveSuccess;
        validateWording.value = mergeLang.value.stockKeycardSaved;
        showValidateDialog.value = true;
        if (isCreateDocument.value) {
          await router.replace({
            name: "ar-stock-keycard-edit",
            params: {
              id: selectedStockKeycard.value.id,
            },
          });
        }
        setCompareData();
      } catch (error) {
        baseDialogClassTitle.value = "";
        titleConfirm.value = mergeLang.value.notificationDialogHeader;
        if (error.code == "106") {
          validateWording.value = mergeLang.value.validateDuplicateCode.replace(
            ":code",
            runningNo.value.trim()
          );
        } else {
          validateWording.value = error.message;
        }
        showValidateDialog.value = true;
        setLoading(false);
      }
      setLoading(false);
    };

    const setBaseTag = (status) => {
      let result = {
        caption: "",
        class: "",
      };
      if (status.toLowerCase() === "ready") {
        result.caption = mergeLang.value.statusReady;
        result.class = "success";
      } else if (status.toLowerCase() === "sold") {
        result.caption = mergeLang.value.statusSold;
        result.class = "secondary";
      } else if (status.toLowerCase() === "lost") {
        result.caption = mergeLang.value.statusLost;
        result.class = "error";
      } else if (status.toLowerCase() === "broken") {
        result.caption = mergeLang.value.statusBroken;
        result.class = "warning";
      } else if (status.toLowerCase() === "spare") {
        result.caption = mergeLang.value.statusSpare;
        result.class = "primary";
      } else if (status.toLowerCase() === "other") {
        result.caption = mergeLang.value.statusOther;
        result.class = "other";
      }

      return result;
    };

    const viewHistory = (data) => {
      keyToReLoad.value++;
      selectedHistory.value = data;
      showHistoryDialog.value = true;
    };

    const changeStatus = () => {
      if (isStatusReady.value) {
        remarkError.value = false;
      }
    };

    const unitIsInactive = () => {
      selectedUnit.value = null;
    };

    const updateRemark = (data) => {
      remark.value = data;
      remarkError.value = false;
    };

    const selectUnit = () => {
      unitError.value = false;
      receiptCode.value = "";
    };

    const confirmChangeStatus = () => {
      isConfirmChangeStatus.value = true;
      showValidateDialog.value = false;
      selectedUnit.value = null;
      save();
    };

    const setLocalSearch = () => {
      let storageName = `erp_search_StockKeycardList_${selectedProject.value.code}_${userCode.value}`;
      const localSearch = localStorage.getItem(storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      localStorage.setItem(storageName, JSON.stringify(search));
    };

    const setCompareData = () => {
      let data = {
        projectId: selectedProject.value.id,
        code: runningNo.value ? runningNo.value.trim() : "",
        serial: serialNo.value ? serialNo.value.trim() : "",
        date: date.value ? formatDateToISOString(date.value) : "",
        productId: selectedProduct.value ? selectedProduct.value.id : "",
        unitId: selectedUnit.value ? selectedUnit.value.id : "",
        buyPrice: 0,
        status: selectedStatus.value,
        remark: remark.value ? remark.value.trim() : "",
        receiptDetailId: receiptDetailId.value ? receiptDetailId.value : "",
      };
      if (!isCreateDocument.value) {
        data = {
          ...data,
          id: props.id,
        };
      }
      objToCompare.value = data;
      setCompareObject();
    };

    const setCompareObject = () => {
      let result = false;
      if (isCreateDocument.value) {
        result = isCompareObject(objToCompare.value, payload.value);
      } else {
        let data = {
          ...payload.value,
          id: props.id,
        };
        result = isCompareObject(objToCompare.value, data);
      }
      isPageDirty.value = result;
    };

    const confirmDialog = createConfirmDialog(BaseDialog, {
      titleConfirm: mergeLang.value.notificationDialogHeader,
      wordingConfirm: mergeLang.value.pageDirtyConfirmMessage,
      dataButtons: [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "cancel",
        },
        {
          id: "confirm",
          caption: mergeLang.value.buttonDialogConfirm,
          class: "w-full",
          action: "confirm",
        },
      ],
      display: true,
    });

    onBeforeRouteLeave(async (to, from) => {
      if (isPageDirty.value) {
        var curValue = to.params.projectCode;
        var oldValue = from.params.projectCode;
        if (curValue == oldValue) {
          const answer = await confirmDialog.reveal();
          return !answer.isCanceled;
        }
      }
      return true;
    });

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatDateToISOString,
      formatDate,
      formatDateTime,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      compareObject,
      isCompareObject,
      setCompareData,

      //data
      selectedPage,
      rowsPerPage,
      date,
      dateError,
      createBy,
      createDate,
      selectedProduct,
      isSharedProductReady,
      productError,
      runningNo,
      runningNoError,
      serialNo,
      remark,
      remarkError,
      status,
      selectedStatus,
      histories,
      selectedHistory,
      isSharedUnitReady,
      selectedUnit,
      unitError,
      receiptCode,
      showHistoryDialog,
      indexTabView,

      //dialog
      showValidateDialog,
      baseDialogClassTitle,
      titleConfirm,
      validateWording,
      dataButtonDialog,

      keyToReLoad,

      //methods
      productDataReady,
      unitDataReady,
      goBack,
      save,
      checkShouldSetData,
      setBaseTag,
      viewHistory,
      changeStatus,
      unitIsInactive,
      updateRemark,
      selectUnit,
      confirmChangeStatus,

      //computed
      dataButtons,
      title,
      isStatusReady,
      isStatusSold,
      isvalidateRemark,
      selectedStockKeycard,
    };
  },
  watch: {
    selectedStockKeycard() {
      this.checkShouldSetData();
    },
    isSharedRoleByMenuReady() {
      this.roleByMenuDataReady();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-right: auto;
  }
}
</style>
