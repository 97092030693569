export default {
	title: "Meter auto create list.",
	confirmAutoCreate: "Confirm Auto Create",
	autoCreateMeter: "Auto Create Meter",

	columnUnitCode: "Unit Code",
	columnAddressNo: "Address No",
	columnStatus: "Status",
	columnProductCode: "Product Code",
	columnProductName: "Product Name",

	activeDate: "Please select Active Date",

	waiting: "waiting",
	created: "created",
	error: "error",
	errorProductType: "Please check product type.",
};
