<template>
    <PvDialog
      :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
      :style="{ width: '30vw' }"
      :modal="true"
      :closable="false"
    >
    <template #header>
      <div class="flex justify-content-between align-items-right">
        <div class="pb-1">
          <label class="font-lg">{{ title }}</label>
        </div>       
      </div>
      <PvDivider></PvDivider>
    </template>
    <div class="mb-2 pb-2">
      <div class="mt-2"><label class="font-normal">{{ pendingPrepaidAndDeposit }}</label></div>
      <div class="mt-2"><label class="font-normal">{{ usePrepaidAndDeposit }}</label></div>
    </div>

    <div v-if="!shouldShowCloseButton ">
        <label class="font-sm">{{ progressSummary }}</label>
        <PvProgressBar
          :value="percentage" :showValue="false"
        ></PvProgressBar>
    </div>

    <template #footer>
      <div class="m-3 mb-1" v-if="shouldShowCloseButton ">
        <PvButton
          :label="lang.close"
          class="p-button-outlined p-button-secondary w-full"
          @click="closeDialog"
        />
      </div>
    </template>
    </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../../hooks/lang.js";
export default {
  props: {
    title: {
      type: String,
      default: "Progress",
    },
    pendingPrepaidAndDepositCount: {
      type: Number,
      default: 0,
    },
    usePrepaidAndDepositCount: {
      type: Number,
      default: 0,
    },
    valueObj: {
      type: Object,
      default: null     
    },
  },
  emits: ["close"],
  setup(props, context) {
    const { lang } = useLang();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.calculateInvoice.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.th.ar.calculateInvoice.listing,
        };
      }
    });

    const shouldShowCloseButton = computed(() => {
      let isCompleted = false;
      if (percentage.value === 100) {
        isCompleted = true;
      }
      return isCompleted;
    });

    const pendingPrepaidAndDeposit = computed(() => {
      let progressLine1 = mergeLang.value.progressContentLine1;
      let counting = props.pendingPrepaidAndDepositCount.toString();
      return progressLine1.replace("{0}", counting);
    });

    const usePrepaidAndDeposit = computed(() => {
      let progressLine2 = mergeLang.value.progressContentLine2;
      let counting = props.usePrepaidAndDepositCount.toString();
      return progressLine2.replace("{0}", counting);
    });

    const percentage = computed(() => {
      if (props.valueObj) {
        if (markedItemsCount.value === 0) {
          return 1
        }
        return Math.floor((markedItemsCount.value / totalItemsCount.value) * 100)
      }
      return 0
    })

    const totalItemsCount = computed(() => {
      if (props.valueObj) {
        return props.valueObj.length
      }
      return 0
    })

    const markedItemsCount = computed(() => {
      if (props.valueObj) {
        const data = props.valueObj.filter(e => e.markStatus !== '')
        if (data) {
          return data.length
        }
      }
      return 0
    })

    const progressSummary = computed(() => {
      let progressContent = mergeLang.value.progressContentSummary;
      let current = markedItemsCount.value; 
      let total = totalItemsCount.value; 
      return progressContent.replace("{0}", current).replace("{1}", total);
    });

    const closeDialog = () => {
      context.emit("close");
    };

    return {
      lang: mergeLang,
      pendingPrepaidAndDeposit,
      usePrepaidAndDeposit,
      closeDialog,
      shouldShowCloseButton,
      percentage,
      progressSummary,
    };
  },
  data() {
    return {          
    }
  },
};
</script>