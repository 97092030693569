export default {
	title: "ใบรับสินค้า/ใบรับวางบิล",
	createBill: "สร้างใบรับสินค้า/ใบรับวางบิล",
	editBill: "แก้ใบรับสินค้า/ใบรับวางบิล",
	create: "สร้างใหม่",
	add: "เพิ่ม",
	edit: "แก้ไข",
	print: "พิมพ์",
	approve: "อนุมัติ",
	import: "นำเข้า",
	cancel: "ยกเลิก",
	saveAs: "บันทึกเป็น",

	code: "เลขที่",
	refCode: "เลขที่อ้างอิง",
	date: "วันที่เอกสาร",
	requestDate: "วันที่ยื่นภาษี",
	periodDate: "เครดิตเทอม",
	dueDate: "ครบกำหนด",
	supplier: "ผู้จัดจำหน่าย",
	employee: "ผู้รับของ",

	section: "แผนก",
	project: "โครงการ",
	task: "งาน",
	remark1: "หมายเหตุ (1)",
	remark2: "หมายเหตุ (2)",

	columnBookCode: "รหัสเล่ม",
	columnDate: "วันที่",
	columnCode: "รหัสสินค้า",
	columnCodeName: "ชื่อสินค้า",
	columnUnitName: "ชื่อหน่วยนับ",
	columnUnitPrice: "ราคา",
	columnQuantity: "ปริมาณ",
	columnDiscount: "ส่วนลด",
	columnDiscountAmt: "มูลค่าที่ลด",
	columnNetAmount: "รวมเงิน",
	columnAmount: "จำนวนเงิน",

	askForProductChange: "คุณยืนยันที่จะแก้ไขรหัสสินค้าใช่หรือไม่?",
	askForSelectProduct: "ไม่พบรหัสสินค้า : :code ต้องการค้นหาหรือไม่?",
	askApprove: "คุณยืนยันที่จะ อนุมัติใบวางบิล ใช่หรือไม่?",
	approveSuccess: "อนุมัติ สำเร็จ",

	inputCurrency: "สกุลเงิน",
	inputTotalAmount: "มูลค่ารวมทุกรายการ",
	inputDiscount: "ส่วนลด",
	inputDiscountTotalAmount: "มูลค่าส่วนลด",
	inputPayment: "เงินมัดจำ",
	inputPaymentTotalAmount: "มูลค่าก่อนภาษี",
	inputTax: "อัตราภาษี",
	inputTaxAmount: "มูลค่าภาษี",
	inputNetAmount: "มูลค่าสุทธิ",

	validateNoSupplierSelect: "กรุณาเลือกผู้จัดจำหน่าย",
	validateNoEmployeeSelect: "กรุณาเลือกพนักงาน",
	validateRefCode: "ระบุเลขที่อ้างอิง",
	validateDetailSelect: "กรุณาระบุรายการ",

	titleImportPO: "รายการ ใบสั่งซื้อ",
	validateUpdatePrice: "รายละเอียดใบสั่งซื้อ (PO) ไม่สามารถแก้ไขราคาได้",
    validateCheckDiscount: "รูปแบบส่วนลด ระบุได้เฉพาะตัวเลข และ %",
	validateTotalAmount: "มูลค่ารวมทุกรายการ ต้องไม่น้อยกว่า 0",
    reApprove: "ถอยอนุมัติ",
    askForRevertApprove: "คุณยืนยันที่จะ ถอยอนุมัติเอกสารนี้ ใช่หรือไม่?",
    revertApproveSuccess: "ถอยอนุมัติสำเร็จ",
	validateNetTotalAmount: "มูลค่าสุทธิ ต้องไม่น้อยกว่า 0",
	validateDiscountAmount: "มูลค่าส่วนลด ต้องไม่เกินมูลค่าสุทธิ",
    calculatedFromSystem: "  *ค่าคำนวณที่ระบบแนะนำ : ",
};
