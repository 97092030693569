<template>
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredAccountBooks"
    field="codeAndName"
    @complete="searchAccountBook"
    @dropdown-click="filteredAccountBooks"
    forceSelection
  />
</template>

<script>
import useGetAccountBook from "../../hooks/getAccountBook";
export default {
  setup() {
    const {
      filteredAccountBooks,
      searchAccountBook,
      isSharedAccountBookReady,
      getAllAccountBooks,
      getAccountBook,
    } = useGetAccountBook();

    return {
      filteredAccountBooks,
      searchAccountBook,
      isSharedAccountBookReady,
      getAllAccountBooks,
      getAccountBook,
    };
  },
  emits: ["load-finish"],
  props: {
    isLoadingData: {
      type: Boolean,
      default: false,
    },
    accountBook: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.getAccountBook(this.accountBook);

    if (this.isLoadingData) {
      this.getAllAccountBooks();
    }
  },
  watch: {
    isSharedAccountBookReady(curValue) {
      if (curValue) {
        this.$emit("load-finish");
      }
    },
  },
};
</script>

<!-- ตัวอย่างการเอา AutoComplete ไปใช้
    
        <base-account-book-auto-complete      
            id="selectedAccountBook"
            v-model="selectedAccountBook"
            class="inputfield w-full mt-2"
            accountBook="JV"
            :isLoadingData="true"
        />
        
        <base-account-book-auto-complete      
            id="selectedAccountBook1"
            v-model="selectedAccountBook1"
            class="inputfield w-full mt-2"
            accountBook="RV"
            :isLoadingData="false"   
        />

        <base-account-book-auto-complete      
            id="selectedAccountBook2"
            v-model="selectedAccountBook2"
            class="inputfield w-full mt-2"
            :isLoadingData="false"   
        />

        //รายการที่ไม่เอาเล่ม PC
        <base-account-book-auto-complete      
          id="selectedAccountBook3"
          v-model="selectedAccountBook3"
          class="inputfield w-full mt-2"
          accountBook="!PC"
          :isLoadingData="false"   
        />

-->
