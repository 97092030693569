export default {
  productUnits(state) {
    return state.productUnit.productUnits;
  },
  productUnitCurrentPage(state) {
    return state.productUnit.currentPage;
  },
  productUnitNextPage(state) {
    return state.productUnit.currentPage + 1;
  },
  productUnitTotalPage(state) {
    return state.productUnit.totalPage;
  },
  productUnitIsLastPage(_, getters) {
    return (
      getters.productUnitCurrentPage === getters.productUnitTotalPage &&
      getters.productUnitTotalPage !== 0
    );
  },
};
