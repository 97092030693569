export default {
  setCountryCode(state, payload) {
    let countryCodes = [...state.countryCode.countryCodes];
    for (const countryCode of payload.countryCodes) {
      if (countryCodes.findIndex((c) => c.id == countryCode.id) < 0) {
        countryCodes.push(countryCode);
      }
    }
    state.countryCode.countryCodes = countryCodes;
    state.countryCode.currentPage = payload.currentPage;
    state.countryCode.totalPage = payload.totalPage;
  },
  resetCountryCode(state) {
    const countryCode = {
      countryCodes: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.countryCode = countryCode;
  },
};
