<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
    :header="lang.accountingItem"
  >
    <PvDataTable
      ref="baseAccountModelTable"
      showGridlines
      :value="filteredAccountingDetails"
      v-model:selection="selectedAccounting"
      selectionMode="single"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollHeight="halfViewportHeight + 'px'"
      class="p-datatable-sm"
      :class="textTableClass"
      :loading="!isSharedAccountingDetailReady"
      @dblclick="selectAccounting"
      @keydown="buttonPress"
    >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
      <template #header>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <PvInputText
            v-model="searchTextAccountingDetail"
            :placeholder="lang.keywordSearch"
            autofocus
            ref="searchInput"
          />
        </span>
      </template>
      <PvColumn selectionMode="single" bodyClass="text-center"></PvColumn>
      <PvColumn
        field="code"
        :header="lang.code"
        style="min-width: 6rem"
        headerClass="h-center"
        bodyClass="text-left"
      ></PvColumn>
      <PvColumn
        field="name"
        :header="lang.name"
        style="min-width: 6rem"
        headerClass="h-center"
        bodyClass="text-left"
      ></PvColumn>
    </PvDataTable>
    <template #footer>
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog"
      />
      <PvButton
        :label="lang.ok"
        icon="pi pi-check"
        autofocus
        @click="selectAccounting"
      />
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useGetAccountingDetail from "../../hooks/getAccountingDetail.js";
export default {
  props: {
    shouldGetData: {
      type: Boolean,
      default: true,
    },
    search: {
      type: String,
    }
  },
  emits: ["close", "accounting-selected"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textTableClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.accounting.edit,
          ...lang.th.gl.accounting.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.accounting.edit,
          ...lang.en.gl.accounting.listing,
        };
      }
    });

    const {
      filteredAccountingDetails,
      searchTextAccountingDetail,
      isSharedAccountingDetailReady,
      getAllAccountingDetails
    } = useGetAccountingDetail();

    return {
      filteredAccountingDetails,
      isSharedAccountingDetailReady,
      textHeaderClass,
      textTableClass,
      lang: mergeLang,
      searchTextAccountingDetail,
      getAllAccountingDetails
    };
  },
  data() {
    return {
      selectedAccounting: null,
    };
  },
  mounted() {
    this.getAllAccountingDetails();
  },
  methods: {
    selectAccounting() {
      // console.log("selectedAccounting", this.selectedAccounting);
      this.$emit("accounting-selected", this.selectedAccounting);
      this.selectedAccounting = null;
      
    },
    closeDialog() {
      this.$emit("close");
    },
    buttonPress(event) {
      // console.log("buttonPress", event);
      if (event.target.tagName == "INPUT") {
        if (event.key == "Enter" || event.key == "ArrowDown") {
          // console.log("buttonPress", event.target.tagName);
          let table = this.$refs.baseAccountModelTable.$el;
          let tableRow = table.getElementsByTagName("tr");
   
          tableRow[1].focus();
        }
      } else if (event.key == "Enter") {
        if (this.selectedAccounting) {
          this.selectAccounting();
        }
      }
    }
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
  },
  watch: {
    search(curValue) {
      if (curValue) {
        this.searchTextAccountingDetail = curValue;
      }
    }
  },
};
</script>
