<template>
  <div class="p-datatable-border my-2">
    <PvDataTable
      :value="dataProps"
      dataKey="id"
      selectionMode="single"
      responsiveLayout="scroll"
      :class="textTableClass"
      class="p-datatable-xs"
    >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
      <PvColumn
        v-for="col of columns"
        :field="col.field"
        :header="col.header"
        :style="col.style"
        :headerClass="col.headerClass"
        :bodyClass="col.bodyClass"
        :key="col.field"
        :headerStyle="{background: 'inherit'}"
      >
        <template #body="slotProps">
            <component
                :is="col.iconName"
                :key="col.iconName"
                :iconColor="slotProps.data.iconColor"
                class="w-2"
                style="margin-bottom: -7px; margin-right: 5px;"
            />
          <span :class="slotProps.data.textColor" v-if="col.type == 'currency'">
            {{ this.formatCurrency(slotProps.data[col.field]) }}
          </span>
          <span :class="slotProps.data.textColor" v-if="col.type == 'string'">
            {{ slotProps.data[col.field] }}
          </span>
        </template>
      </PvColumn>
    </PvDataTable>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
export default {
  props: {
    dataTable: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => null,
    },
  },
  setup() {
    const { lang } = useLang();
    const { textTableClass } = useTextStyle();
    const { formatCurrency } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
        };
      } else {
        return {
          ...lang.en.base,
        };
      }
    });

    return {
      lang: mergeLang,
      textTableClass,
      formatCurrency,
    };
  },
  data() {
    return {
        comp:"icon-path",
    }
  },
  computed: {
    dataProps() {
      return this.dataTable;
    }
  }
};
</script>

<style></style>


<!---

	<div class="w-5">
		<base-list-view 
			:dataTable="dataTable1" 
			:columns="columns1"> 
		</base-list-view>
	</div>
	
	<div class="w-5">
		<base-list-view 
			:dataTable="dataTable2" 
			:columns="columns2"> 
		</base-list-view>
	</div>
	
	<div class="w-5">
		<base-list-view 
			:dataTable="dataTable3" 
			:columns="columns3"> 
		</base-list-view>
	</div>
	
	<div class="w-5">
		<base-list-view 
			:dataTable="dataTable4" 
			:columns="columns4"> 
		</base-list-view>
	</div>


    data() {
    return {
      dataTable1: [
        {
          name: "test1",
          totalAmount: 10,
          netAmount: 1000,
		  iconColor: "primary",
		  textColor: "p-text-primary",
        },
        {
          name: "test2",
          totalAmount: 20,
          netAmount: 2000,
		  iconColor: "success",
		  textColor: "p-text-success",
        },
        {
          name: "test3",
          totalAmount: 30,
          netAmount: 3000,
		  iconColor: "error",
		  textColor: "p-text-error",
        },
      ],
      columns1: [
        {
          field: "name",
          header: "name",
          type: "string",
          style: "width: 10%;height: 46px;",
          headerClass: "h-left font-sm ",
          bodyClass: "text-left",
		  iconName: "icon-home-modern-circle",
        },
        {
          field: "totalAmount",
          header: "totalAmount",
          type: "currency",
          style: "width: 10%;",
          headerClass: "h-right font-sm ",
          bodyClass: "text-right",
        },
        {
          field: "netAmount",
          header: "netAmount",
          type: "currency",
          style: "width: 10%;",
          headerClass: "h-right font-sm ",
          bodyClass: "text-right",
        },
      ],

      dataTable2: [
        {
          name: "test1",
          totalAmount: 10,
          netAmount: 1000,
		  iconColor: "primary",
		  textColor: "p-text-secondary",
        },
        {
          name: "test2",
          totalAmount: 20,
          netAmount: 2000,
		  iconColor: "success",
		  textColor: "p-text-secondary",
        },
        {
          name: "test3",
          totalAmount: 30,
          netAmount: 3000,
		  iconColor: "error",
		  textColor: "p-text-secondary",
        },
      ],
	  columns2: [
        {
          field: "name",
          header: "name",
          type: "string",
          style: "width: 10%;height: 46px;",
          headerClass: "h-left font-sm ",
          bodyClass: "text-left p-text-secondary",
		  iconName: "icon-home-modern-circle",
        },
        {
          field: "totalAmount",
          header: "totalAmount",
          type: "currency",
          style: "width: 10%;",
          headerClass: "h-right font-sm p-text-secondary",
          bodyClass: "text-right p-text-secondary",
        },
        {
          field: "netAmount",
          header: "netAmount",
          type: "currency",
          style: "width: 10%;",
          headerClass: "h-right font-sm p-text-secondary",
          bodyClass: "text-right p-text-secondary",
        },
      ],

	  dataTable3: [
        {
          name: "test1",
          totalAmount: 10,
          netAmount: 1000,
		  iconColor: "primary",
        },
        {
          name: "test2",
          totalAmount: 20,
          netAmount: 2000,
		  iconColor: "success",
        },
        {
          name: "test3",
          totalAmount: 30,
          netAmount: 3000,
		  iconColor: "error",
        },
      ],
	  columns3: [
        {
          field: "name",
          header: "name",
          type: "string",
          style: "width: 10%;height: 46px;",
          headerClass: "h-left font-sm ",
          bodyClass: "text-left p-text-secondary",
        },
        {
          field: "totalAmount",
          header: "totalAmount",
          type: "currency",
          style: "width: 10%;",
          headerClass: "h-right font-sm p-text-primary",
          bodyClass: "text-right p-text-primary",
        },
        {
          field: "netAmount",
          header: "netAmount",
          type: "currency",
          style: "width: 10%;",
          headerClass: "h-right font-sm p-text-success",
          bodyClass: "text-right p-text-success",
        },
      ],

      dataTable4: [
        {
          name: "test1",
          totalAmount: 10,
          netAmount: 1000,
		  iconColor: "primary",
        },
        {
          name: "test2",
          totalAmount: 20,
          netAmount: 2000,
		  iconColor: "success",
        },
        {
          name: "test3",
          totalAmount: 30,
          netAmount: 3000,
		  iconColor: "error",
        },
      ],
	  columns4: [
        {
          field: "name",
          header: "name",
          type: "string",
          style: "width: 10%;height: 46px;",
          headerClass: "h-left font-sm ",
          bodyClass: "text-left p-text-secondary",
		  iconName: "icon-home-modern-circle",
        },
        {
          field: "totalAmount",
          header: "totalAmount",
          type: "currency",
          style: "width: 10%;",
          headerClass: "h-right font-sm p-text-primary",
          bodyClass: "text-right p-text-primary",
        },
        {
          field: "netAmount",
          header: "netAmount",
          type: "currency",
          style: "width: 10%;",
          headerClass: "h-right font-sm p-text-error",
          bodyClass: "text-right p-text-error",
        },
      ],

    };
  },




-->

<!-- <div class="lg:col-6 md:col-12 col-12 pl-0 pt-4 pr-2">
  <div class="content-border h-full">
      <label class="font-lg font-bold pl-0">สถานะที่พักอาศัย</label>
      <PvChart
          type="bar"
          :data="chartDataResidentStatus"
          :options="chartOptionsResidentStatus"
          :plugins="plugins"
          class="md:w-15rem"
      /> 
      <div class="flex flex-wrap justify-content-center">
          <div class="lg:col-4 md:col-12 col-12">
          </div>
          <div class="lg:col-8 md:col-12 col-12">
              <base-list-view 
                  :dataTable="dataTableResidentStatus" 
                  :columns="residentColumnStatus"> 
              </base-list-view>
          </div>
      </div>
  </div>
</div> -->