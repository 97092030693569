<template>
    <base-toolbar :title="lang.paymentRequestSetting" @goBack="goBack"> </base-toolbar>

    <base-container>
        <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5" :class="textFormClass">
            <div class="flex flex-wrap justify-content-between align-items-center">
                <label class="font-lg font-normal">{{ lang.paymentRequestTypeSetting }}</label>
            </div>

            <PvDivider></PvDivider>

            <div class="p-datatable-border my-2">
                <PvDataTable
                    :value="paymentRequestTypeList"
                    v-model:selection="selectedPaymentRequestType"
                    selectionMode="single"
                    dataKey="id"
                    responsiveLayout="scroll"
                    class="p-datatable-xs"
                    :class="textTableClass"
                    :loading="isLoading"
                    @row-dblclick="gotoEdit(selectedPaymentRequestType.id)"
                    scrollable
                >
                    <template #empty>
                        <div class="w-full p-3" style="text-align: center">
                            <label>{{ lang.tableEmpty }}</label>
                        </div>
                    </template>
                    
                    <PvColumn
                        field="itemNo"
                        :header="lang.itemNo"
                        style="max-width: 8rem; height: 56px"
                        headerClass="h-left"
                        bodyClass="text-left"
                    />
                    <PvColumn
                        field="name"
                        :header="lang.columnName"
                        style="min-width: 30rem; height: 56px"
                        headerClass="h-left"
                        bodyClass="text-left"
                    >
                    </PvColumn>
                    <PvColumn
                        style="max-width: 10rem"
                        headerClass="h-center"
                        bodyStyle="text-align: center; justify-content: center; align-items: center;"
                        alignFrozen="right"
                        frozen
                    >
                        <template #body="slotProps">
                            <PvButton
                                type="button"
                                class="p-button-secondary p-button-text p-0 mr-0"
                                @click="gotoEdit(slotProps.data.id)"
                            >
                                <icon-pen-edit iconColor="secondary" width="24" height="24" />
                            </PvButton>
                        </template>
                    </PvColumn>
                </PvDataTable>
            </div>

        </div>
    </base-container>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref, onMounted, computed } from "vue";
import useLang from "../../hooks/lang.js";
import useFormat from "../../hooks/format.js";
import BaseDialog from "../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
export default {
    props: ["projectCode"],
    setup() {
        const router = useRouter();
        const store = useStore();
        const { lang } = useLang();
        const mergeLang = computed(function () {
            if (store.getters.selectedLanguage === "TH") {
                return {
                ...lang.th.base,
                ...lang.th.ap.paymentRequestType.listing,
                };
            } else {
                return {
                ...lang.en.base,
                ...lang.en.ap.paymentRequestType.listing,
                };
            }
        });
        const { textTableClass, textFormClass } = useFormat();

        let selectedPaymentRequestType = ref(null);
        let isLoading = ref(false);
        let dataConfigDialog = ref(null);
        let paymentRequestTypeList = ref([]);

        onMounted(() => {
            initiateData();
            getPaymentRequestType();
        });

        //computed
        const selectedProject = computed(() => {
            return store.getters.selectedProject;
        });

        const paymentRequestType = computed(() => {
            return store.getters["paymentRequestType/paymentRequestType"];
        });

        //methods
        const goBack = () => {
            router.push({ name: "home"});
        };

        const initiateData = () => {
            dataConfigDialog.value = null;
            selectedPaymentRequestType.value = null;
            paymentRequestTypeList.value = [];
        };

        const getPaymentRequestType = async () => {
            isLoading.value = true;
            try {
                let payload = {
                    projectId: selectedProject.value.id
                };
                await store.dispatch("paymentRequestType/getPaymentRequestType", payload);
                let data = paymentRequestType.value;
                let number = 1;
                if (data) {
                    data.forEach((e) => {
                        paymentRequestTypeList.value.push({...e, itemNo: number});
                        number++;
                    });
                }
            } catch (error) {
                setDialog({
                    classTitle: "error",
                    title: mergeLang.value.notificationDialogHeader,
                    message: error,
                });
                createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
                isLoading.value = false;
            }
            isLoading.value = false;
        };

        const gotoEdit = (id) => {
            router.push({ name: "payment-request-type-edit", params: { id: id } });
        };

        const setDialog = (dataConfig) => {
            const classMap = {
                success: "p-text-success",
                error: "p-error",
            };

            dataConfig.classTitle = classMap[dataConfig.classTitle] || "";

            dataConfig.button = [
                {
                id: "close",
                caption: mergeLang.value.close,
                class: "p-button-outlined p-button-secondary w-full",
                action: "cancel",
                },
            ];

            dataConfigDialog.value = {
                titleConfirm: dataConfig.title,
                classTitle: dataConfig.classTitle,
                wordingConfirm: dataConfig.message,
                dataButtons: dataConfig.button,
                display: true,
            };
        };

        return {
            lang: mergeLang,
            textTableClass,
            textFormClass,

            //data
            isLoading,
            selectedPaymentRequestType,
            paymentRequestTypeList,

            //methods
            goBack,
            gotoEdit,

            //computed
            paymentRequestType,
        }
    }
}
</script>