<template>
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredPolicy"
    field="codeAndName"
    @complete="searchPolicy"
    @dropdown-click="filteredPolicy"
    forceSelection
  />
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default {
  emits: ["load-finish"],
  setup() {
    const store = useStore();
    let searchText = ref(null);

    const policy = computed(() => {
      return store.getters["policy/policy"];
    });

    // console.log("policy dropdown", policy);

    const filteredPolicy = computed(() => {
      if (!searchText.value) {
        //ช่องว่าง
        const result = [...policy.value];
        return result;
      } else {
        return policy.value.filter((data) => {
          return data.codeAndName
            .toLowerCase()
            .includes(searchText.value.toLowerCase());
        });
      }
    });

    const searchPolicy = function (event) {
      // console.log(event);
      if (!event.query.trim().length) {
        //ช่องว่าง
        // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
        searchText.value = "";
        searchText.value = null;
      } else {
        searchText.value = event.query;
      }
    };

    let isPolicyReady = ref(false);

    const getPolicyByCode = function (code) {
      return policy.value.find((data) => {
        return data.code.toLowerCase() === code.trim().toLowerCase();
      });
    };

    onMounted(async () => {
      await store.dispatch("policy/getAllPolicy");
      isPolicyReady.value = true;
    });

    return {
      searchText,
      filteredPolicy,
      searchPolicy,
      getPolicyByCode,
      isPolicyReady,
    };
  },
  watch: {
    isPolicyReady(curValue) {
      if (curValue) {
        this.$emit("load-finish");
      }
    },
  },
};
</script>
