import actions from "./actions.js";
import getters from "./getters.js";
import mutations from "./mutations.js";

export default {
	namespaced: true,
	state() {
		return {			
            purchaseInvoices: [],
            purchaseInvoiceSelected: [],
            purchaseInvoiceToGl: [],
            totalPage: 0,
            totalListPage: 0,
            currentPage: 0,
            rows: 100,
			cancelPurchaseInvoice: false,
		};
	},
	actions,
	getters,
	mutations,
};
