<template>
	<PvDialog
		:breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
		:style="{ width: '70vw' }"
		:modal="true"
		:closable="false"
	>
		<div class="flex justify-content-between align-items-center">
			<div class="my-3">
				<PvButton
					icon="pi pi-chevron-left"
					:label="lang.back"
					@click="goBack"
					class="mr-2 p-button-secondary"
				/>
			</div>

			<h2 class="my-0">{{ lang.titleImportPurchaseReceipt }}</h2>
		</div>

		<transition>
			<section class="flex flex-wrap" :class="textFormClass">
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="bookCode">{{ lang.documentBookCode }}</label>
					<PvDropdown
						class="inputfield w-full mt-1"
						:options="bookCode"
						:showClear="clearBookCode"
						optionLabel="bookCode"
						optionValue="bookId"
						v-model="selectedBookId"
						@change="setBookName()"
						scrollHeight="300px"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="dateStart">{{ lang.dateStart }}</label>
					<PvCalendar
						id="dateStart"
						v-model="dateStart"
						class="inputfield w-full mt-1"
						showIcon
						dateFormat="dd/mm/yy"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="dateEnd">{{ lang.dateEnd }}</label>
					<PvCalendar
						id="dateEnd"
						v-model="dateEnd"
						class="inputfield w-full mt-1"
						showIcon
						dateFormat="dd/mm/yy"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="codeStart">{{ lang.codeStart }}</label>
					<PvInputText
						id="codeStart"
						type="text"
						class="inputfield w-full mt-1"
						placeholder="6501xxxxx"
						v-model="codeStart"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="codeEnd">{{ lang.codeEnd }}</label>
					<PvInputText
						id="codeEnd"
						type="text"
						class="inputfield w-full mt-1"
						placeholder="6501xxxxx"
						v-model="codeEnd"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<PvButton
						icon="pi pi-search"
						:label="lang.search"
						@click="getPurchaseReceiptList()"
						class="mr-2 mb-2 mt-4"
					/>
				</div>
			</section>
		</transition>

		<PvDataTable
			showGridlines
			:value="filteredPurchaseReceipt"
			v-model:selection="selectedPurchaseReceipt"
			dataKey="receiptId"
			:loading="isLoading"
			responsiveLayout="scroll"
			:scrollHeight="'300px'"
			class="p-datatable-sm"
			:class="textTableClass"
			:metaKeySelection="false"
			selectionMode="multiple"
		>
			<template #empty>
				<div class="w-full p-3" Style="text-align: center;">
				<label>{{ lang.tableEmpty }}</label>
				</div>
			</template>
			<template #header>
				<span class="p-input-icon-left">
					<i class="pi pi-search" />
					<PvInputText v-model="searchText" :placeholder="lang.keywordSearch" />
				</span>
			</template>
			<PvColumn selectionMode="multiple"></PvColumn>

			<!-- <PvColumn
				field="receiptPostStep"
				:header="lang.columnPostStep"
				style="min-width: 2rem"
				headerClass="h-center"
				bodyClass="text-center"
				sortable
			></PvColumn> -->

			<PvColumn
				field="receiptCode"
				:header="lang.columnCode"
				style="min-width: 4rem"
				headerClass="h-center"
				sortable
			></PvColumn>

			<PvColumn
				field="receiptRefCode"
				:header="lang.columnReferenceCode"
				style="min-width: 4rem"
				headerClass="h-center"
				sortable
			></PvColumn>

			<PvColumn
				field="receiptDate"
				:header="lang.columnDate"
				style="min-width: 3rem"
				headerClass="h-center"
				bodyClass="text-center"
				sortable
			>
				<template #body="slotProps">
					{{ formatDate(slotProps.data.receiptDate) }}
				</template>
			</PvColumn>

			<PvColumn
				field="receiptSupplierCode"
				:header="lang.columnSupplierCode"
				style="min-width: 5rem"
				headerClass="h-center"
				sortable
			></PvColumn>

			<PvColumn
				field="receiptSupplierName"
				:header="lang.columnSupplierName"
				style="min-width: 6rem"
				headerClass="h-center"
				sortable
			></PvColumn>

			<PvColumn
				field="receiptNetAmount"
				:header="lang.columnReceiptNetAmount"
				style="min-width: 4rem"
				headerClass="h-center"
				bodyClass="text-right"
				sortable
			>
				<template #body="slotProps">
					{{ formatCurrency(slotProps.data.receiptNetAmount) }}
				</template>
			</PvColumn>

			<PvColumn
				field="receiptActualPayAmount"
				:header="lang.columnreceiptActualPayAmount"
				style="min-width: 4rem"
				headerClass="h-center"
				bodyClass="text-right"
				sortable
			>
				<template #body="slotProps">
					{{ formatCurrency(slotProps.data.receiptActualPayAmount) }}
				</template>
			</PvColumn>
		</PvDataTable>

		<template #footer>
			<PvButton
				:label="lang.close"
				icon="pi pi-times"
				class="p-button-text"
				@click="closeDialog()"
			/>
			<PvButton
				:label="lang.btnConfirm"
				icon="pi pi-check"
				@click="confirmImportPurchaseReceipt()"
			/>
		</template>
	</PvDialog>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import useGetBookPurchase from "../../hooks/getBookPurchase.js";
export default {
	props: ["openFrom", "voucherId"],
	emits: ["close"],
	setup() {
		const { lang } = useLang();
		const { textHeaderClass, textFormClass, textTableClass, textAlertClass } = useTextStyle();
		const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
		const store = useStore();
		const { filteredBookPurchases } = useGetBookPurchase();

		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return {
					...lang.th.base,
					...lang.th.ap.purchaseReceipt.listing,
					...lang.th.gl.accounting.edit,
					...lang.th.gl.accounting.listing,
				};
			} else {
				return {
					...lang.en.base,
					...lang.en.ap.purchaseReceipt.listing,
					...lang.th.gl.accounting.edit,
					...lang.th.gl.accounting.listing,
				};
			}
		});

		return {
			lang: mergeLang,
			textHeaderClass,
			textFormClass,
			textTableClass,
			textAlertClass,
			formatCurrency,
			formatDate,
			formatDateToISOString,
			filteredBookPurchases,
		};
	},
	data() {
		return {
			selectedPage: 1,
			rowsPerPage: 100,
			isLoading: true,
			clearBookCode: false,
			selectedBookId: null,

			selectedPurchaseReceipt: [],
			searchText: "",

			dateStart: null,
			dateEnd: null,
			codeStart: "",
			codeEnd: "",
		};
	},
	async created() {
		let date = new Date();
		this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
		this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

		this.isLoading = false;
	},
	methods: {
		goBack() {
			this.$emit("close");
		},
		closeDialog() {
			this.$emit("close");
		},
		async getPurchaseReceiptList() {
			this.isLoading = true;
			try {
				// console.log("getPurchaseReceiptList");

				let where = {
					projectId: this.selectedProject.id,
					receiptDateStart: this.selectedDateStart,
					receiptDateEnd: this.selectedDateEnd,
					receiptCodeStart: this.codeStart || null,
					receiptCodeEnd: this.codeEnd || null,
					documentBookId: this.selectedBookId,
					receiptVoucherId: "",
				};

				const payload = {
					page: this.selectedPage,
					limit: this.rowsPerPage,
					where,
				};

				// console.log("getPurchaseReceiptList", payload);
				await this.$store.dispatch("glPurchaseReceipt/getVoucherReferenceDocuments", payload);
				// console.log(this.purchaseReceiptLists);

				this.isLoading = false;
			} catch (error) {
				this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				this.isLoading = false;
			}
		},
		setBookName() {
			// this.$store.dispatch("glBillAcceptance/resetBillAcceptance", []);
			if (this.filteredBookPurchases) {
				let bookCode = this.filteredBookPurchases.find(
					(data) => data.bookId === this.selectedBookId
				);
				this.selectedBookName = bookCode.bookNameTH + "/" + bookCode.bookNameEN;
			}
		},
		async confirmImportPurchaseReceipt() {
			// console.log("confirmImportPurchaseReceipt", this.selectedPurchaseReceipt);
			if (this.selectedPurchaseReceipt.length > 0) {
				await this.$store.dispatch(
					"apPurchaseReceipt/setPurchaseReceiptImport",
					this.selectedPurchaseReceipt
				);
				this.$emit("close");
			} else {
				this.$swal({
					icon: "warning",
					title: this.lang.validateDetailSelect,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				return;
			}
		},
		setBookSelected() {
			// console.log("this.bookCode=", this.bookCode);
			// console.log("this.filteredBookPurchases=", this.filteredBookPurchases);
			if (this.bookCode && this.filteredBookPurchases) {
				if (this.filteredBookPurchases.length > 0) {
					this.selectedBookId = this.filteredBookPurchases[0].bookId;
					this.getPurchaseReceiptList();
				}
			}
		},
	},
	computed: {
		halfViewportHeight() {
			return this.$store.getters.halfViewportHeight;
		},
		selectedProject() {
			return this.$store.getters.selectedProject;
		},
		bookCode() {
			let response = [];
			let books = this.filteredBookPurchases;
			if (books) {
				books.forEach((data) => {
					let book = {
						bookId: data.bookId,
						bookCode: data.bookCode + " :  " + data.bookNameTH + "/" + data.bookNameEN,
					};
					response.push(book);
				});
			}
			return response;
		},
		filteredPurchaseReceipt() {
			if (!this.searchText) {
				//ช่องว่าง
				const result = [...this.purchaseReceiptLists];
				return result;
			} else {
				return this.purchaseReceiptLists.filter((data) => {
					return (
						data.receiptCode.toLowerCase().includes(this.searchText.toLowerCase()) ||
						data.receiptSupplierCode.toLowerCase().includes(this.searchText.toLowerCase()) ||
						data.receiptSupplierName.toLowerCase().includes(this.searchText.toLowerCase())
					);
				});
			}
		},
		selectedDateStart() {
			let dateStart = null;
			if (this.dateStart) {
				dateStart = this.formatDateToISOString(this.dateStart);
			}
			return dateStart;
		},
		selectedDateEnd() {
			let dateEnd = null;
			if (this.dateEnd) {
				dateEnd = this.formatDateToISOString(this.dateEnd);
			}
			return dateEnd;
		},
		purchaseReceiptLists() {
			return this.$store.getters["glPurchaseReceipt/voucherReferenceDocuments"];
		},
	},
	watch: {
		filteredBookPurchases() {
			this.setBookSelected();
		},
		openFrom() {
			this.setBookSelected();
		},
		bookCode() {
			this.setBookSelected();
		},
	},
};
</script>
