export default {
    title: "Debt Free Request Detail",
    createDebtFreeRequest: "Create Debt Free Request",
    editDebtFreeRequest: "Edit Debt Free Request",
    submitApprove: "Submit For Approve",
    debtFreeAttachment: "Documentation",
    //ส่วนที่ 1
    titleSectionFirst: "1 : Unit Information",
    unitCode: "Unit Code",
    unitArea: "Area",
    unitAddressNo: "AddressNo",
    pendingCurrent: "Pending Current",
    pendingCalculateDate: "Pending Date",
    ownerIdCardAddress1: "Id Card Address (1)",
    pendingAmount: "Pending Amount",
    ownerIdCardAddress2: "Id Card Address (2)",
    stringBahtPendingAmount: "Pending Amount (String)",
    ownerIdCardAddress3: "Id Card Address (3)",
    unitCount: "Unit Count",
    //ส่วนที่ 2
    titleSectionSecond: "2 : Document Information",
    requestDate: "Request Date",
    requestBy: "Request By",
    documentDate: "Document Date",
    requestPhone: "Request Mobile Phone",
    isForeigner: "Foreigner",
    requestDocument: "Request Document",
    isGetCopyOfOwnerIdCard: "Copy Of Owner ID Card",
    isGetCopyOfOwnerHouseRegistration: "Copy Of Owner House Registration",
    isGetCopyOfJointOwnerShip: "Copy Of Joint Owner Ship",
    isGetPowerOfAttorney: "Power Of Attorney",
    isGetCopyOfProxyIdCard: "Copy Of Proxy ID Card",
    isGetCopyOfNameChangedCertificate: "Copy Of Changed Name Certificate",
    isOtherDocument: "Other Document",
    otherDocumentDescription: "Please Enter Value",
    requestDetail: "Bill Payment List",
    isRequestCutOfPublicUtilities: "Request Cut Of Public Utilities",
    selectDate: "Date",
    isPaidFee: "Paid Fee",
    isPaidExpenses: "Paid By Receipt",
    receiptCode: "Receipt Code",
    receiptAmount: "Receipt Amount",
    pleaseInputValue: "Please Input Value",
    //ส่วนที่ 3
    titleSectionThird: "3 : Checking",
    isValidateExpenses: "Validated No Expenses",
    approve: "Approve",
    disApprove: "DisApprove",
    approvedDate: "Approv Date",
    debtFreeCode: "Debt Free Code",
    approvedBy: "Approve By",

    cancelReason: "Please Select Reason",
    cancelDebtFreeRequestTitle: "Cancel Debt Free Request",
    cancelDebtFreeRequestTitleDetail: "Do you want to cancel debt free request ?",
    cancelReason1: "Incomplete evidence",
    cancelReason2: "Still pending/Not yet debt free",
    cancelReason3: "Suspended/Request for cancel",
    cancelDebtFreeRequestSuccess: "Cancel debt free request unit : :unitCode successfully",
    submitApproveTitle: "Do you want to request debt free ?",
    submitApproveTitleDetail: "You are entering debt free approval process. Requires a person to proceed",
    submitApproveSuccess: "You have to request debt free successfully",
    isPendingAmountTitle: "Failed to complete the transaction",
    isPendingAmountTitleDetail: "This room still have outstanding debt in system",

    createDebtFreeRequestSuccess: "You have created a request debt free successfully",
    editDebtFreeRequestSuccess: "You have edited a request debt free successfully",

    validateRequestBy: "Please enter request by",
    validateRequestDate: "Please enter request date",
    validateRequestPhone: "Please enter request mobile phone",

    createNewDebtFreeRequestTitle: "Create new",
    askForCreateNewDebtFreeRequest: "If you create a new request debt free, will not be saved",
    createNewDebtFreeRequestTitleDetail: "Do you want to create a request debt free ?",

    stringBahtPendingAmountIsZero: "ศูนย์บาทถ้วน",
    validateNoSelectDate: "Please select date",

    validateDebtFreeApproval: "Document is approved",
    validateDebtFreeSubmittedApprove: "Document is submitted approve",
}