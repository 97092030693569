// import 'primevue/resources/themes/saga-blue/theme.css';
// import "primevue/resources/themes/lara-light-blue/theme.css";
import "./assets/lara-light-blue/theme.scss";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import ToastService from "primevue/toastservice";
import * as ConfirmDialog from "vuejs-confirm-dialog";
import VueExcelEditor from 'vue3-excel-editor';

const app = createApp(App);

//components
import BaseContainer from "./components/UI/BaseContainer.vue";
app.component("base-container", BaseContainer);
import BaseUnitAutoComplete from "./components/UI/BaseUnitAutoComplete.vue";
app.component("base-unit-auto-complete", BaseUnitAutoComplete);
import BaseProductARAutoComplete from "./components/UI/BaseProductArAutoComplete.vue";
app.component("base-product-ar-auto-complete", BaseProductARAutoComplete);
import BaseProductArModal from "./components/UI/BaseProductArModal.vue";
app.component("base-product-ar-modal", BaseProductArModal);
import BasePeriodAutoComplete from "./components/UI/BasePeriodAutoComplete.vue";
app.component("base-period-auto-complete", BasePeriodAutoComplete);
import BaseFormPrintModal from "./components/UI/BaseFormPrintModal.vue";
app.component("base-form-print-modal", BaseFormPrintModal);
import BaseGlBookModal from "./components/UI/BaseGlBookModal.vue";
app.component("base-gl-book-modal", BaseGlBookModal);
import BaseAccountingFrequentlyModal from "./components/UI/BaseAccountingFrequentlyModal.vue";
app.component("base-accounting-frequently-modal", BaseAccountingFrequentlyModal);
import BaseAccountingModal from "./components/UI/BaseAccountingModal";
app.component("base-accounting-modal", BaseAccountingModal);
import BaseSupplierAutoComplete from "./components/UI/BaseSupplierAutoComplete.vue";
app.component("base-supplier-auto-complete", BaseSupplierAutoComplete);
import BaseTaxBookAutoComplete from "./components/UI/BaseTaxBookAutoComplete.vue";
app.component("base-tax-book-auto-complete", BaseTaxBookAutoComplete);
import BaseCustomerAutoComplete from "./components/UI/BaseCustomerAutoComplete.vue";
app.component("base-customer-auto-complete", BaseCustomerAutoComplete);
import BaseEmployeeAutoComplete from "./components/UI/BaseEmployeeAutoComplete.vue";
app.component("base-employee-auto-complete", BaseEmployeeAutoComplete);
import BaseAPBookModal from "./components/UI/BaseAPBookModal.vue";
app.component("base-ap-book-modal", BaseAPBookModal);
import BaseBookPurchaseModal from "./components/UI/BaseBookPurchaseModal.vue";
app.component("base-book-purchase-modal", BaseBookPurchaseModal);
import BaseTaxAutoComplete from "./components/UI/BaseTaxAutoComplete";
app.component("base-tax-auto-complete", BaseTaxAutoComplete);
import BaseProductApModal from "./components/UI/BaseProductApModal.vue";
app.component("base-product-ap-modal", BaseProductApModal);
import BaseCard from "./components/UI/BaseCard.vue";
app.component("base-card", BaseCard);
import BaseAccountingAutoComplete from "./components/UI/BaseAccountingAutoComplete.vue";
app.component("base-accounting-auto-complete", BaseAccountingAutoComplete);
import BaseBuildingAutoComplete from "./components/UI/BaseBuildingAutoComplete.vue";
app.component("base-building-auto-complete", BaseBuildingAutoComplete);
import BaseJobAdminAutoComplete from "./components/UI/BaseJobAdminAutoComplete.vue";
app.component("base-job-admin-auto-complete", BaseJobAdminAutoComplete);
import BaseLocationAutoComplete from "./components/UI/BaseLocationAutoComplete.vue";
app.component("base-location-auto-complete", BaseLocationAutoComplete);
import BaseToolbar from "./components/UI/BaseToolbar.vue";
app.component("base-toolbar", BaseToolbar);
import BaseDialog from "./components/UI/BaseDialog.vue";
app.component("base-dialog", BaseDialog);
import BaseInputTextLimit from "./components/UI/BaseInputTextLimit.vue";
app.component("base-input-text-limit", BaseInputTextLimit);
import BaseAccountingDetailModal from "./components/UI/BaseAccountingDetailModal";
app.component("base-accounting-detail-modal", BaseAccountingDetailModal);
import BaseAccountingDetailAutoComplete from "./components/UI/BaseAccountingDetailAutoComplete.vue";
app.component("base-accounting-detail-auto-complete", BaseAccountingDetailAutoComplete);
import BaseAccountingParentAutoComplete from "./components/UI/BaseAccountingParentAutoComplete.vue";
app.component("base-accounting-parent-auto-complete", BaseAccountingParentAutoComplete);
import BaseInputMobileWithCountryCode from "./components/UI/BaseInputMobileWithCountryCode.vue";
app.component("base-input-mobile-with-country-code", BaseInputMobileWithCountryCode);
import BaseMobileTable from "./components/UI/BaseMobileTable.vue";
app.component("base-mobile-table", BaseMobileTable);
import BaseTag from "./components/UI/BaseTag.vue";
app.component("base-tag", BaseTag);
import BaseSidebar from "./components/UI/BaseSidebar.vue";
app.component("base-sidebar", BaseSidebar);
import BaseNationalityAutoComplete from "./components/UI/BaseNationalityAutoComplete.vue";
app.component("base-nationality-auto-complete", BaseNationalityAutoComplete);
import BaseCustomerGroupAutoComplete from "./components/UI/BaseCustomerGroupAutoComplete.vue";
app.component("base-customer-group-auto-complete", BaseCustomerGroupAutoComplete);
import BaseProductUnitAutoComplete from "./components/master/product/BaseProductUnitAutoComplete.vue";
app.component("base-product-unit-auto-complete", BaseProductUnitAutoComplete);
import BaseProductPenaltyAutoComplete from "./components/master/product/BaseProductPenaltyAutoComplete.vue";
app.component("base-product-penalty-auto-complete", BaseProductPenaltyAutoComplete);
import BaseProductPrepaidAutoComplete from "./components/master/product/BaseProductPrepaidAutoComplete.vue";
app.component("base-product-prepaid-auto-complete", BaseProductPrepaidAutoComplete);
import BaseReferenceDocumentDialog from "./components/UI/BaseReferenceDocumentDialog.vue";
app.component("base-reference-document-dialog", BaseReferenceDocumentDialog);
import BaseBankAutoComplete from "./components/UI/BaseBankAutoComplete.vue";
app.component("base-bank-auto-complete", BaseBankAutoComplete);
import BaseProgressBar from "./components/UI/BaseProgressBar.vue";
app.component("base-progress-bar", BaseProgressBar);
import BaseResidentDialog from "./components/UI/BaseResidentDialog.vue";
app.component("base-resident-dialog", BaseResidentDialog);
import BaseBookBankArAutoComplete from "./components/UI/BaseBookBankArAutoComplete.vue";
app.component("base-book-bank-ar-auto-complete", BaseBookBankArAutoComplete);
import BaseReportPrintModal from "./components/UI/BaseReportPrintModal.vue";
app.component("base-report-print-modal", BaseReportPrintModal);
import BaseUnitDialog from "./components/UI/BaseUnitDialog.vue";
app.component("base-unit-dialog", BaseUnitDialog);
import BaseProductGuaranteeAutoComplete from "./components/UI/BaseProductGuaranteeAutoComplete.vue";
app.component("base-product-guarantee-auto-complete", BaseProductGuaranteeAutoComplete);
import BaseAccountBookAutoComplete from "./components/UI/BaseAccountBookAutoComplete.vue";
app.component("base-account-book-auto-complete", BaseAccountBookAutoComplete);
import BaseAccountCodeEquityAutoComplete from "./components/UI/BaseAccountCodeEquityAutoComplete.vue";
app.component("base-account-code-equity-auto-complete", BaseAccountCodeEquityAutoComplete);
import BaseToastReportRequest from "./components/UI/BaseToastReportRequest.vue";
app.component("base-toast-report-request", BaseToastReportRequest);
import BaseSelectCard from "./components/UI/BaseSelectCard.vue";
app.component("base-select-card", BaseSelectCard);
import BaseListView from "./components/UI/BaseListView.vue";
app.component("base-list-view", BaseListView);
import LanguageSettingDialog from "./components/setting/LanguageSettingDialog.vue";
app.component("language-setting-dialog", LanguageSettingDialog);
import BaseProjectModal from "./components/UI/BaseProjectModal.vue";
app.component("base-project-modal", BaseProjectModal);
import BaseToolTipImage from "./components/UI/BaseToolTipImage.vue";
app.component("base-tooltip-image", BaseToolTipImage);
import BaseImagePreview from "./components/UI/BaseImagePreview.vue";
app.component("base-image-preview", BaseImagePreview);
import BaseDiscountInputText from "./components/UI/BaseDiscountInputText.vue";
app.component("base-discount-input-text", BaseDiscountInputText);
import BaseAddressAutoComplete from "./components/UI/BaseAddressAutoComplete.vue";
app.component("base-address-auto-complete", BaseAddressAutoComplete);
import BaseAddressSelector from "./components/UI/BaseAddressSelector.vue";
app.component("base-address-selector", BaseAddressSelector);
import BaseCountryCodeAutoComplete from "./components/UI/BaseCountryCodeAutoComplete.vue";
app.component("base-country-code-auto-complete", BaseCountryCodeAutoComplete);
import BaseDbdBusinessTypeAutoComplete from "./components/UI/BaseDbdBusinessTypeAutoComplete.vue";
app.component("base-dbd-business-type-auto-complete", BaseDbdBusinessTypeAutoComplete);
import BasePrefixAutoComplete from "./components/UI/BasePrefixAutoComplete.vue";
app.component("base-prefix-auto-complete", BasePrefixAutoComplete);
import BudgetYearAutoComplete from "./components/Budget/BudgetYearAutoComplete.vue";
app.component("budget-year-auto-complete", BudgetYearAutoComplete);
import BaseProductApAutoComplete from "./components/UI/BaseProductApAutoComplete.vue";
app.component("base-product-ap-auto-complete", BaseProductApAutoComplete);

import PvCard from "primevue/card";
app.component("PvCard", PvCard);
import PvInputText from "primevue/inputtext";
app.component("PvInputText", PvInputText);
import PvCheckbox from "primevue/checkbox";
app.component("PvCheckbox", PvCheckbox);
import PvButton from "primevue/button";
app.component("PvButton", PvButton);
import PvDialog from "primevue/dialog";
app.component("PvDialog", PvDialog);
import PvMenubar from "primevue/menubar";
app.component("PvMenubar", PvMenubar);
import DataTable from "primevue/datatable";
app.component("PvDataTable", DataTable);
import PvColumn from "primevue/column";
app.component("PvColumn", PvColumn);
import PvColumnGroup from "primevue/columngroup";
app.component("PvColumnGroup", PvColumnGroup);
import PvRow from "primevue/row";
app.component("PvRow", PvRow);
import PvCalendar from "primevue/calendar";
app.component("PvCalendar", PvCalendar);
import PvDropdown from "primevue/dropdown";
app.component("PvDropdown", PvDropdown);
import PvPaginator from "primevue/paginator";
app.component("PvPaginator", PvPaginator);
import PvAutoComplete from "primevue/autocomplete";
app.component("PvAutoComplete", PvAutoComplete);
import PvVirtualScroller from "primevue/virtualscroller";
app.component("PvVirtualScroller", PvVirtualScroller);
import PvSelectButton from "primevue/selectbutton";
app.component("PvSelectButton", PvSelectButton);
import PvProgressSpinner from "primevue/progressspinner";
app.component("PvProgressSpinner", PvProgressSpinner);
import PvBlockUI from "primevue/blockui";
app.component("PvBlockUI", PvBlockUI);
import PvConfirmDialog from "primevue/confirmdialog";
app.component("PvConfirmDialog", PvConfirmDialog);
import PvInputNumber from "primevue/inputnumber";
app.component("PvInputNumber", PvInputNumber);
import PvSkeleton from "primevue/skeleton";
app.component("PvSkeleton", PvSkeleton);
import PVTabView from "primevue/tabview";
app.component("PVTabView", PVTabView);
import PVTabPanel from "primevue/tabpanel";
app.component("PVTabPanel", PVTabPanel);
import PVTooltip from "primevue/tooltip";
app.directive("tooltip", PVTooltip);
import PvDivider from "primevue/divider";
app.component("PvDivider", PvDivider);
import PvBreadcrumb from "primevue/breadcrumb";
app.component("PvBreadcrumb", PvBreadcrumb);
import PvToolbar from "primevue/toolbar";
app.component("PvToolbar", PvToolbar);
import PvMenu from "primevue/menu";
app.component("PvMenu", PvMenu);
import PvToast from "primevue/toast";
app.component("PvToast", PvToast);
import PvProgressBar from "primevue/progressbar";
app.component("PvProgressBar", PvProgressBar);
import PvTextarea from "primevue/textarea";
app.component("PvTextarea", PvTextarea);
import PvBadgeDirective from "primevue/badgedirective";
app.directive("badge", PvBadgeDirective);
import PvTreeTable from "primevue/treetable";
app.component("PvTreeTable", PvTreeTable);
import PvInputSwitch from "primevue/inputswitch";
app.component("PvInputSwitch", PvInputSwitch);
import PvSidebar from "primevue/sidebar";
app.component("PvSidebar", PvSidebar);
import PvSplitButton from "primevue/splitbutton";
app.component("PvSplitButton", PvSplitButton);
import PvInputMask from "primevue/inputmask";
app.component("PvInputMask", PvInputMask);
import PvRadioButton from "primevue/radiobutton";
app.component("PvRadioButton", PvRadioButton);
import PvMessage from 'primevue/message';
app.component("PvMessage", PvMessage);
import PvCarousel from 'primevue/carousel';
app.component("PvCarousel", PvCarousel);
import PvChart from 'primevue/chart';
app.component("PvChart", PvChart);
import PvInlineMessage from 'primevue/inlinemessage';
app.component("PvInlineMessage", PvInlineMessage);
import PvDeferredContent from 'primevue/deferredcontent';
app.component("PvDeferredContent", PvDeferredContent);
import PvEditor from 'primevue/editor';
app.component("PvEditor", PvEditor);
import PvImage from 'primevue/image';
app.component("PvImage", PvImage);
import PvGalleria from 'primevue/galleria';
app.component("PvGalleria", PvGalleria);

//icons
import IconFileBlankCopyAddPlus from "./components/icons/IconFileBlankCopyAddPlus";
app.component("icon-file-blank-copy-add-plus", IconFileBlankCopyAddPlus);
import IconPenEdit from "./components/icons/IconPenEdit";
app.component("icon-pen-edit", IconPenEdit);
import IconPlusAdd from "./components/icons/IconPlusAdd";
app.component("icon-plus-add", IconPlusAdd);
import IconTrashDeleteBin from "./components/icons/IconTrashDeleteBin";
app.component("icon-trash-delete-bin", IconTrashDeleteBin);
import IconDocumentsFileCheckmark from "./components/icons/IconDocumentsFileCheckmark";
app.component("icon-documents-file-checkmark", IconDocumentsFileCheckmark);
import IconCalendarSchedule from "./components/icons/IconCalendarSchedule";
app.component("icon-calendar-schedule", IconCalendarSchedule);
import IconCloudStorageUpload from "./components/icons/IconCloudStorageUpload";
app.component("icon-cloud-storage-upload", IconCloudStorageUpload);
import IconHomeModernCircle from "./components/icons/IconHomeModernCircle";
app.component("icon-home-modern-circle", IconHomeModernCircle);
import IconChartCircle from "./components/icons/IconChartCircle.vue";
app.component("icon-chart-circle", IconChartCircle);
import IconPackageBoxLabel from "./components/icons/IconPackageBoxLabel.vue";
app.component("icon-package-box-label", IconPackageBoxLabel);
import IconSettingSquare from "./components/icons/IconSettingSquare.vue";
app.component("icon-setting-square", IconSettingSquare);
import IconShop from "./components/icons/IconShop.vue";
app.component("icon-shop", IconShop);
import IconCarCheckmark from "./components/icons/IconCarCheckmark.vue";
app.component("icon-car-checkmark", IconCarCheckmark);
import IconBoxDeliveryPackageReturnCustomer from "./components/icons/IconBoxDeliveryPackageReturnCustomer.vue";
app.component("icon-box-delivery-package-return-customer", IconBoxDeliveryPackageReturnCustomer);
import IconAccountingCalculatorCircle from "./components/icons/IconAccountingCalculatorCircle.vue";
app.component("icon-accounting-calculator-circle", IconAccountingCalculatorCircle);
import IconDocumentSearch from "./components/icons/IconDocumentSearch.vue";
app.component("icon-document-search", IconDocumentSearch);
import IconServerDatabase from "./components/icons/IconServerDatabase.vue";
app.component("icon-server-database", IconServerDatabase);
import IconDocumentMoreDots from "./components/icons/IconDocumentMoreDots.vue";
app.component("icon-document-more-dots", IconDocumentMoreDots);
import IconBookCursor from "./components/icons/IconBookCursor.vue";
app.component("icon-book-cursor", IconBookCursor);
import IconMassageBubble from "./components/icons/IconMassageBubble.vue";
app.component("icon-messages-bubble", IconMassageBubble);
import IconMassageQuestionCheckmark from "./components/icons/IconMassageQuestionCheckmark.vue";
app.component("icon-message-question-checkmark", IconMassageQuestionCheckmark);
import IconHomeSmileys from "./components/icons/IconHomeSmileys.vue";
app.component("icon-home-smileys", IconHomeSmileys);
import IconDotsMenu from "./components/icons/IconDotsMenu.vue";
app.component("icon-dots-menu", IconDotsMenu);
import IconArrowLeft from "./components/icons/IconArrowLeft.vue";
app.component("icon-arrow-left", IconArrowLeft);
import IconArrowRight from "./components/icons/IconArrowRight.vue";
app.component("icon-arrow-right", IconArrowRight);
import IconArrowDown from "./components/icons/IconArrowDown.vue";
app.component("icon-arrow-down", IconArrowDown);
import IconWarning from "./components/icons/IconWarning.vue";
app.component("icon-warning", IconWarning);
import IconDelete from "./components/icons/IconDelete.vue";
app.component("icon-delete", IconDelete);
import IconSetting from "./components/icons/IconSetting.vue";
app.component("icon-setting", IconSetting);
import IconEye from "./components/icons/IconEye.vue";
app.component("icon-eye", IconEye);
import IconDownload from "./components/icons/IconDownload.vue";
app.component("icon-download", IconDownload);
import IconRefreshLoading from "./components/icons/IconRefreshLoading.vue";
app.component("icon-refresh-loading", IconRefreshLoading);
import IconClockTimeArrow from "./components/icons/IconClockTimeArrow.vue";
app.component("icon-clock-time-arrow", IconClockTimeArrow);
import IconPrinter from "./components/icons/IconPrinter.vue";
app.component("icon-printer", IconPrinter);
import IconFilter from "./components/icons/IconFilter.vue";
app.component("icon-filter", IconFilter);
import IconBellNotificationAlarm from "./components/icons/IconBellNotificationAlarm.vue";
app.component("icon-bell-notification-alarm", IconBellNotificationAlarm);
import IconPath from "./components/icons/IconPath.vue";
app.component("icon-path", IconPath);
import IconBellCircle from "./components/icons/IconBellCircle.vue";
app.component("icon-bell-circle", IconBellCircle);
import IconAttachmentLink from "./components/icons/IconAttachmentLink.vue";
app.component("icon-attachment-link", IconAttachmentLink);
import IconCar from "./components/icons/IconCar.vue";
app.component("icon-car", IconCar);
import IconRightArrow from "./components/icons/IconRightArrow.vue";
app.component("icon-right-arrow", IconRightArrow);
import IconLeftArrow from "./components/icons/IconLeftArrow.vue";
app.component("icon-left-arrow", IconLeftArrow);
import IconLock from "./components/icons/IconLock.vue";
app.component("icon-lock", IconLock);
import IconUnLock from "./components/icons/IconUnLock.vue";
app.component("icon-unlock", IconUnLock);
import IconSave from "./components/icons/IconSave.vue";
app.component("icon-save", IconSave);
import IconCreate from "./components/icons/IconCreate.vue";
app.component("icon-create", IconCreate);
import IconCancel from "./components/icons/IconCancel.vue";
app.component("icon-cancel", IconCancel);
import IconDocumentEdit from "./components/icons/IconDocumentEdit.vue";
app.component("icon-document-edit", IconDocumentEdit);
import IconDocumentCheckmark from "./components/icons/IconDocumentCheckmark.vue";
app.component("icon-document-checkmark", IconDocumentCheckmark);
import IconHand from "./components/icons/IconHand.vue";
app.component("icon-hand", IconHand);
import IconChartRate from "./components/icons/IconChartRate.vue";
app.component("icon-chart-rate", IconChartRate);
import IconDocumentClip from "./components/icons/IconDocumentClip.vue";
app.component("icon-document-clip", IconDocumentClip);
import IconSearchListCheckmark from "./components/icons/IconSearchListCheckmark";
app.component("icon-search-list-checkmark", IconSearchListCheckmark);
import IconDownloadArrow from "./components/icons/IconDownloadArrow.vue";
app.component("icon-download-arrow", IconDownloadArrow);
import IconArrowRotate from "./components/icons/IconArrowRotate.vue";
app.component("icon-arrow-rotate", IconArrowRotate);
import IconCopy from "./components/icons/IconCopy.vue";
app.component("icon-copy", IconCopy);
import IconSpot from "./components/icons/IconSpot.vue";
app.component("icon-spot", IconSpot);
import IconThaiFlag from "./components/icons/IconThaiFlag.vue";
app.component("icon-thai-flag", IconThaiFlag);
import IconEarth from "./components/icons/IconEarth.vue";
app.component("icon-earth", IconEarth);
import IconPayment from "./components/icons/IconPayment.vue";
app.component("icon-payment", IconPayment);
import IconParcel from "./components/icons/IconParcel.vue";
app.component("icon-parcel", IconParcel);
import IconResident from "./components/icons/IconResident.vue";
app.component("icon-resident", IconResident);
import IconError from "./components/icons/IconError.vue";
app.component("icon-error", IconError);
import IconMobiles from "./components/icons/IconMobiles.vue";
app.component("icon-mobiles", IconMobiles);
import IconHome from "./components/icons/IconHome.vue";
app.component("icon-home", IconHome);
import IconCoins from "./components/icons/IconCoins.vue";
app.component("icon-coins", IconCoins);
import IconBank from "./components/icons/IconBank.vue";
app.component("icon-bank", IconBank);
import IconCalculator from "./components/icons/IconCalculator.vue";
app.component("icon-calculator", IconCalculator);
import IconChair from "./components/icons/IconChair.vue";
app.component("icon-chair", IconChair);
import IconInvoiceHandSelect from "./components/icons/IconInvoiceHandSelect.vue";
app.component("icon-invoice-hand-select", IconInvoiceHandSelect);
import IconHighRiseBuilding from "./components/icons/IconHighRiseBuilding.vue";
app.component("icon-high-rise-building", IconHighRiseBuilding);
import IconPackageBox from "./components/icons/IconPackageBox.vue";
app.component("icon-package-box", IconPackageBox);
import IconDeliveryTruckFast from "./components/icons/IconDeliveryTruckFast.vue";
app.component("icon-delivery-truck-fast", IconDeliveryTruckFast);
import ToolsWench from "./components/icons/IconToolsWench.vue";
app.component("tools-wench", ToolsWench);
import HeadphonesMicrophone from "./components/icons/IconHeadphonesMicrophone.vue";
app.component("headphones-microphone", HeadphonesMicrophone);
import BookSearch from "./components/icons/IconBookSearch.vue";
app.component("book-search", BookSearch);
import SwimmingPool from "./components/icons/IconSwimmingPool.vue";
app.component("swimming-pool", SwimmingPool);
import MessagesChat from "./components/icons/IconMessagesChat.vue";
app.component("messages-chat", MessagesChat);
import HornTrumpet from "./components/icons/IconHornTrumpet.vue";
app.component("horn-trumpet", HornTrumpet);
import VideoCameraSecurity from "./components/icons/IconVideoCameraSecurity.vue";
app.component("video-camera-security", VideoCameraSecurity);
import IconArrowDownload from "./components/icons/IconArrowDownload.vue";
app.component("arrow-download", IconArrowDownload);
import Basket from "./components/icons/IconBasket.vue";
app.component("icon-basket", Basket);
import IconArrangeFilterSort from "./components/icons/IconArrangeFilterSort.vue";
app.component("icon-arrange-filter-sort", IconArrangeFilterSort);
import IconPenEditText from "./components/icons/IconPenEditText.vue";
app.component("icon-pen-edit-text", IconPenEditText);
import IconSearchZoomMinus from "./components/icons/IconSearchZoomMinus.vue";
app.component("icon-search-zoom-minus", IconSearchZoomMinus);
import IconSearchZoomPlus from "./components/icons/IconSearchZoomPlus.vue";
app.component("icon-search-zoom-plus", IconSearchZoomPlus);
import IconSecurity from "./components/icons/IconSecurity";
app.component("icon-seurity", IconSecurity);
import IconExternalLink from "./components/icons/IconExternalLink.vue";
app.component("icon-external-link", IconExternalLink);
import IconReject from "./components/icons/IconReject.vue";
app.component("icon-reject", IconReject);
import IconSynchronize from "./components/icons/IconSynchronize.vue";
app.component("icon-synchronize", IconSynchronize);
import IconMoneybag from "./components/icons/IconMoneybag.vue";
app.component("icon-moneybag", IconMoneybag);
import IconEditClear from "./components/icons/IconEditClear.vue";
app.component("icon-edit-clear", IconEditClear);
import IconExpand from "./components/icons/IconExpand.vue";
app.component("icon-expand", IconExpand);
import IconCollapse from "./components/icons/IconCollapse.vue";
app.component("icon-collapse", IconCollapse);
import iconAverage from "./components/icons/IconAverage.vue";
app.component("icon-average", iconAverage);



app.use(store);
app.use(router);
app.use(PrimeVue, { ripple: true });
app.use(ConfirmationService);
app.use(ToastService);
app.use(ConfirmDialog);
app.use(VueExcelEditor);

const options = {
	confirmButtonColor: "#3B82F6",
	cancelButtonColor: "#EF4444",
};
//https://sweetalert2.github.io/#download
//https://github.com/avil13/vue-sweetalert2

app.use(VueSweetalert2, options);

app.mount("#app");
