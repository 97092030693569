export default {
	editProject:"ข้อมูลโครงการ",	
	projectTitleGeneral: "ข้อมูลทั่วไป",
	projectTitleAddress: "ข้อมูลที่อยู่",
	projectTitleRegistered: "ข้อมูลการจดทะเบียน",
	projectTitlePerson: "ข้อมูลบุคคล",
	projectTitleAdvertise: "ประชาสัมพันธ์-โฆษณา",
	projectCode:"รหัสโครงการ",
	projectType:"ประเภทโครงการ",
	projectNameTh:"ชื่อโครงการ (ภาษาไทย)",
	projectNameEn:"ชื่อโครงการ (ภาษาอังกฤษ)",
	projectTel:"เบอร์โทร",
	projectHouseTel:"เบอร์บ้าน",
	projectFax:"เบอร์ Fax",
	projectTaxId:"เลขประจำตัวผู้เสียภาษี",
	projectComNickName:"ชื่อย่อโครงการ",
	projectComShortCode:"รหัสย่อโครงการ",

    //ข้อมูลที่อยู่
	tabAddressProject: "ที่อยู่โครงการ",
	tabAddressForFinancialDocuments: "ที่อยู่สำหรับออกเอกสารทางการเงิน",
	tabAddressParcel: "ที่อยู่สำหรับรับ/ฝากพัสดุ",
	address1: "ที่อยู่",	
	addressTh: "ที่อยู่ (ภาษาไทย)",	
	addressEn: "ที่อยู่ (ภาษาอังกฤษ)",
	address2: "แขวง/เขต, ตำบล/อำเภอ",
	address3: "จังหวัด, รหัสไปรษณีย์",

	parcelName: "ชื่อผู้ติดต่อ",
	parcelTel: "เบอร์โทร",
	parcelEmail: "อีเมล",
	
    //ข้อมูลบุคคล
	projectManagerNameTh: "ผู้จัดการอาคาร (ภาษาไทย)",
	projectManagerNameEn: "ผู้จัดการอาคาร (ภาษาอังกฤษ)",
	projectManagerEmail: "E-mail ผู้จัดการอาคาร",
	debtFreeAuthEmail: "E-mail ผู้มีอำนาจอนุมัติใบปลอดหนี้",
	communityManagerNameTh: "ชื่อผู้จัดการนิติ (ภาษาไทย)",
	communityManagerNameEn: "ชื่อผู้จัดการนิติ (ภาษาอังกฤษ)",
	accountantNameTh: "ชื่อผู้จัดการ/เจ้าหน้าที่บัญชี (ภาษาไทย)",
	accountantNameEn: "ชื่อผู้จัดการ/เจ้าหน้าที่บัญชี (ภาษาอังกฤษ)",
	accManagerName: "ชื่อผู้จัดการฝ่ายการเงิน-บัญชี",
	communityManageBy: "บริหารงานโดย",

	//ประชาสัมพันธ์ - โฆษณา	
	tabAdvertiseInvoice: "ใบแจ้งหนี้",
	tabAdvertiseNotice: "หนังสือทวงถาม",
	tabAdvertiseReceipt: "ใบเสร็จ",
	tabAdvertiseInvoiceReceipt: "ข้อความท้าย ใบแจ้งหนี้/ใบเสร็จ",
	advertiseInvoiceTh: "ใบแจ้งหนี้ (ภาษาไทย)",
	advertiseInvoiceEn: "ใบแจ้งหนี้ (ภาษาอังกฤษ)",
	advertiseNoticeTh: "หนังสือทวงถาม (ภาษาไทย)",
	advertiseNoticeEn: "หนังสือทวงถาม (ภาษาอังกฤษ)",
	advertiseReceiptTh: "ใบเสร็จ (ภาษาไทย)",
	advertiseReceiptEn: "ใบเสร็จ (ภาษาอังกฤษ)",
	invoiceFooter: "ข้อความท้ายใบแจ้งหนี้",
	receiptFooter: "ข้อความท้ายใบเสร็จ",

    //ข้อมูลการจดทะเบียน
	juristicId: "เลขทะเบียนนิติ",
	juristicDate: "วันที่จดทะเบียน",
	averageWaterMeter: "ค่าเฉลี่ย มิเตอร์ น้ำ/ไฟ (ใช้ในการแจ้งเตือนเวลาจดมิเตอร์)",
	capitalFirst: "เงินกองทุนจัดเก็บครั้งแรก",
	capitalBalance: "เงินกองทุนคงเหลือ",
	ownershipRatio: "อัตราส่วนกรรมสิทธิ์",
	projectArea: "พื้นที่โครงการ",
	financialStatements: "รอบงบการเงิน",
	budget: "รอบงบประมาณ",
	numberofRoom: "จำนวนห้องชุด",
	numberofStores: "จำนวนร้านค้า",
	numberofCars: "จำนวนรถที่จอดซ้อนคัน",

	province: "จังหวัด",
	district: "เขต/อำเภอ",
	subarea: "แขวง/ตำบล",
	postcode: "รหัสไปรษณีย์",
	selectAddress: "เลือกที่อยู่",

	FinanceDateStart: "วันที่เริ่มรอบงบการเงิน",
	FinanceDateEnd: "วันที่สิ้นสุดรอบงบการเงิน",
	BudgetDateStart: "วันที่เริ่มงบประมาณ",
	BudgetDateEnd:"วันที่สิ้นสุดงบประมาณ",
	
	validateProjectCode:"ระบุรหัสโครงการ",
	validateProjectType:"เลือกประเภทโครงการ",
	validateProjectName: "ระบุชื่อโครงการ",
	validateProjectNickName: "ระบุชื่อย่อโครงการ",
	validateProjectShortCode: "ระบุรหัสโครงการ",
	validateProjectTel: "ระบุเบอร์โทร",
	validateProjectFax: "ระบุเบอร์ Fax",
	validateProjectHouseTel: "ระบุเบอร์บ้าน",
	
	validateParcelAddress1: "กรุณาระบุเลขที่ ซอย ถนน",
	validateParcelAddress2: "ระบุแขวง/เขต, ตำบล/อำเภอ",
	validateParcelAddress3: "ระบุจังหวัด, รหัสไปรษณีย์",
	validateParcelName: "ระบุชื่อผู้ติดต่อ",
	validateParcelEmail: "ระบุอีเมล",
	
	validateProjectManagerNameTh: "ระบุผู้จัดการอาคาร (ภาษาไทย)",
	validateProjectManagerNameEn: "ระบุผู้จัดการอาคาร (ภาษาอังกฤษ)",
	validateProjectManagerEmail: "ระบุอีเมล ผู้จัดการอาคาร",
	validateDebtFreeAuthEmail: "ระบุอีเมล ผู้มีอำนาจอนุมัติใบปลอดหนี้",
	validateCommunityManagerNameTh: "ระบุชื่อผู้จัดการนิติ (ภาษาไทย)",
	validateCommunityManagerNameEn: "ระบุชื่อผู้จัดการนิติ (ภาษาอังกฤษ)",
	validateAccountantNameTh: "ระบุชื่อผู้จัดการ/เจ้าหน้าที่บัญชี (ภาษาไทย)",
	validateAccountantNameEn: "ระบุชื่อผู้จัดการ/เจ้าหน้าที่บัญชี (ภาษาอังกฤษ)",
	validateAccManagerName: "ระบุชื่อผู้จัดการฝ่ายการเงิน-บัญชี",
	validateCommunityManageBy: "ระบุบริหารงานโดย",
	
	validateAdvertiseInvoiceTh: "ระบุใบแจ้งหนี้ (ภาษาไทย)",
	validateAdvertiseInvoiceEn: "ระบุใบแจ้งหนี้ (ภาษาอังกฤษ)",
	validateAdvertiseNoticeTh: "ระบุหนังสือทวงถาม (ภาษาไทย)",
	validateAdvertiseNoticeEn: "ระบุหนังสือทวงถาม (ภาษาอังกฤษ)",
	validateAdvertiseReceiptTh: "ระบุใบเสร็จ (ภาษาไทย)",
	validateAdvertiseReceiptEn: "ระบุใบเสร็จ (ภาษาอังกฤษ)",
	validateInvoiceFooter: "ระบุข้อความท้ายใบแจ้งหนี้",
	validateReceiptFooter: "ระบุข้อความท้ายใบเสร็จ",

	validateJuristicId: "ระบุเลขทะเบียนนิติ",
	validateAverageWaterMeter: "ระบุค่าเฉลี่ย มิเตอร์ น้ำ/ไฟ (ใช้ในการแจ้งเตือนเวลาจดมิเตอร์)",
	validateOwnershipRatio: "ระบุอัตราส่วนกรรมสิทธิ์",
	validateProjectArea: "ระบุพื้นที่โครงการ",
	validateNumberofRoom: "ระบุจำนวนห้องชุด",
	validateNumberofStores: "ระบุจำนวนร้านค้า",
	validateNumberofCars: "ระบุจำนวนรถที่จอดซ้อนคัน",
	validateNumber: "กรุณาระบุเป็นตัวเลขเท่านั้น", 
	validateDate: "เลือกวันที่",
	titleEdit: "แก้ไขข้อมูล",
	validateProvince: "ระบุจังหวัด",
	searchText: "กรอกข้อมูลเพื่อค้นหา",
	validateTaxNo: "กรุณาระบุเป็นตัวเลขประจำตัวผู้เสียภาษี หรือ เลขทะเบียนนิติบุคคลที่ออกโดย กรมพัฒนาธุรกิจการค้า 13 หลัก ตัวอย่าง 0-0000-00000-00-0",    
	projectTypes: [
        { name: "คอนโดมิเนียม", code: "คอนโดมิเนียม" },
        { name: "หอพัก/อพาร์ทเม้นท์", code: "หอพัก/อพาร์ทเม้นท์" },
        { name: "หมู่บ้านจัดสรร", code: "หมู่บ้านจัดสรร" },
        { name: "พื้นที่เช่า/คอมมูนิตี้ มอลล์", code: "พื้นที่เช่า/คอมมูนิตี้ มอลล์" },
        { name: "บ้านเอื้ออาทร", code: "บ้านเอื้ออาทร" },
    ],	
	validateTel:"กรุณาระบุเป็นตัวเลข และ อักขระพิเศษ +,(),-",
	validateEmailError: "กรุณาระบุรูปแบบ E-mail ให้ถูกต้อง",
	
	searchaddress: "ค้นหาที่อยู่",
	tooltipAddressParcel: "ใช้สำหรับการเข้ารับพัสดุ",
	tooltipTaxNo:"ระบุเป็นตัวเลขประจำตัวผู้เสียภาษี หรือเลขทะเบียนนิติบุคคล ที่ออกโดยกรมพัฒนาธุรกิจการค้า 13 หลัก ตัวอย่าง 0-0000-00000-00-0",
	textTaxNo:"ข้อมูลนี้จำเป็นต้องใช้ในการออกใบเสร็จ",
	editAdvertisement:"ข้อมูลประชาสัมพันธ์-โฆษณา",
	suffix: "เลข Suffix (Bill payment)",
	suffixPlaceholder: "ระบุเลข suffix สำหรับบริการ bill payment เช่น 00",
	validateSuffixError: "กรุณาระบุเลข suffix สำหรับบริการ bill payment 2หลัก เช่น 00",


};
