export default {
  setTaxBook(state, payload) {
    let taxBooks = [...state.taxBook.taxBooks];
    for (const taxBook of payload.taxBooks) {
      if(taxBooks.findIndex(c => c.id === taxBook.id) < 0){
        taxBooks.push(taxBook);
      }
    }
    state.taxBook.taxBooks = taxBooks;
    state.taxBook.currentPage = payload.currentPage;
    state.taxBook.totalPage = payload.totalPage;
  },
  resetTaxBook(state) {
    const taxBook = {
      taxBooks: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.taxBook = taxBook;
  },
  setTaxType(state, payload){
    state.taxTypes = payload.data
    state.totalPage = payload.page.pageCount
    state.currentPage = payload.page.currentPage
}
};
