<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66667 5.33333V2.66667C4.66667 2.29867 4.96533 2 5.33333 2H10.6667C11.0347 2 11.3333 2.29867 11.3333 2.66667V5.33333"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.66667 11.3333H3.33333C2.59667 11.3333 2 10.7367 2 10V6.66666C2 5.92999 2.59667 5.33333 3.33333 5.33333H12.6667C13.4033 5.33333 14 5.92999 14 6.66666V10C14 10.7367 13.4033 11.3333 12.6667 11.3333H11.3333"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.66667 9.2H11.3333V13.3333C11.3333 13.7013 11.0347 14 10.6667 14H5.33333C4.96533 14 4.66667 13.7013 4.66667 13.3333V9.2Z"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.66667 7.33333H5.33333"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
