export default {
  residents(state) {
    return state.residents;
  },
  selectedResident(state) {
    return state.selectedResident;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
  residentNextPage(state) {
    return state.currentPage + 1;
  },
  residentIsLastPage(_, getters) {
    return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
  },
  unitCountById(state) {
    return state.unitCountById;
  },
};
