import actions from "./actions.js";
import getters from "./getters.js";
import mutations from "./mutations.js";

export default {
	namespaced: true,
	state() {
		return {
			products: [],
			penaltyRateStep: [],
			arProducts: [],
			selectedArProduct: null,
			totalPage: 0,
			currentPage: 1,
			rows: 100,
			totalListPage: 0,
			unitProducts: [],
			unitProductTotalPage: 1,
			unitProductCurrentPage: 0,
			allUnitProducts: [],
		};
	},
	actions,
	getters,
	mutations,
};
