<template>
  <base-toolbar
    :title="lang.title"
    :dataButtons="parameters.dataButtonToolbar"
    @goBack="goBack"
    @gotoCreate="gotoEdit('create')"
  ></base-toolbar>
  <import-stock-keycard-dialog 
    v-model:visible="showUploadStockKeycardDialog"
    @close="showUploadStockKeycardDialog = false"
    @data-upload="uploadStockKeycard"
  />
  <base-container>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.title }}</label>

        <PvButton class="p-button-outlined" @click="importStockKeycard">
          <icon-download iconColor="primary" width="16" height="16" />
          <span class="p-button-label font-normal ml-1">{{ lang.import }}</span>
        </PvButton>
      </div>

      <PvDivider></PvDivider>

      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >
        <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            id="sortStatus"
            class="inputfield w-full mr-2"
            :options="parameters.sortStatus"
            optionLabel="name"
            optionValue="code"
            v-model="parameters.selectedSortStatus"
            scrollHeight="300px"
          />
        </div>
        <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            id="filterStatus"
            class="inputfield w-full mr-2"
            :options="parameters.filterStatus"
            optionLabel="name"
            optionValue="code"
            v-model="parameters.selectedFilterStatus"
            scrollHeight="300px"
          />
        </div>
        <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            id="filterSearch"
            class="inputfield w-full mr-2"
            :options="parameters.filterSearch"
            optionLabel="name"
            optionValue="code"
            v-model="parameters.selectedFilterSearch"
            scrollHeight="300px"
            @change="changeFilterSearch"
          />
        </div>
        <div class="flex lg:col-4 md:col-12 col-12 p-0" v-if="isSearchDate && !isSearchAll">
          <div class="col-6 pl-0 pr-2">
            <PvCalendar
              id="dateStart"
              v-model="parameters.dateStart"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
          <label for="dateEnd" class="pt-1 mt-3 mr-2">{{ "-" }}</label>
          <div class="col-6 pl-0 pr-2">
            <PvCalendar
              id="dateEnd"
              v-model="parameters.dateEnd"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
        </div>
        <div class="flex lg:col-4 md:col-12 col-12 p-0" v-if="!isSearchDate && !isSearchAll">
          <div class="col-12 pl-0 pr-0">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="parameters.searchText"
                :placeholder="lang.searchTextPlaceholder"
                @keypress="checkInputSearch"
                autofocus
              />
            </span>
          </div>
        </div>
        <PvButton @click="getStockKeycards" class="ml-3">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="stockKeycards"
          v-model:selection="parameters.selectedStockKeycard"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="parameters.isLoading"
          @row-dblclick="gotoEdit(parameters.selectedStockKeycard.id)"
          scrollable
        >
          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>

          <PvColumn
            field="productCode"
            :header="lang.productCode"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />

          <PvColumn
            field="unitCode"
            :header="lang.unit"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />

          <PvColumn
            field="unitAddressNo"
            :header="lang.address"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />

          <PvColumn
            field="date"
            :header="lang.date"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.date) }}
            </template>
          </PvColumn>

          <PvColumn
            field="code"
            :header="lang.runningNo"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />

          <PvColumn
            field="serial"
            :header="lang.serialNo"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />

          <PvColumn
            field="receiptCode"
            :header="lang.receiptCode"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />

          <PvColumn
            field="status"
            :header="lang.status"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="{ data, field }">
              <base-tag
                :Caption="classTagStatus(data[field]).label"
                :Class="classTagStatus(data[field]).class"
              />
            </template>
          </PvColumn>

          <PvColumn
            style="min-width: 3rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
            </template>
          </PvColumn>

          <template #footer>
            <PvPaginator
              :rows="parameters.rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              class="p-pagination-radius"
              :first="showCurrentPage"
            >
              <template #start="slotProps">
                {{ lang.page }}:
                {{ totalPage ? slotProps.state.page + 1 : 0 }}/{{ totalPage }}
              </template>
            </PvPaginator>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import BaseDialog from "../../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import ImportStockKeycardDialog from "../../../components/AR/stockKeycard/ImportStockKeycardDialog.vue";
export default {
  props: ["projectCode"],
  components: {
    ImportStockKeycardDialog,
  },
  setup() {
    const router = useRouter();

    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.stockKeycard.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.stockKeycard.listing,
        };
      }
    });

    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    onMounted(async () => {
      parameters.value.isLoading = true;
      initiateData();
      await roleByMenuDataReady();
    });

    //parameterss
    let parameters = ref({
      isLoading: ref(true),
      selectedStockKeycard: ref(null),
      selectedPage: ref(1),
      rowsPerPage: ref(10),

      dataButtonToolbar: ref([
        {
          id: "createCaption",
          caption: mergeLang.value.createStockKeycard,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
        },
      ]),
      dataConfigDialog: ref(null),

      filterStatus: ref([
        { name: mergeLang.value.allFilter, code: "all" },
        { name: mergeLang.value.ready, code: "ready" },
        { name: mergeLang.value.sold, code: "sold" },
        { name: mergeLang.value.lost, code: "lost" },
        { name: mergeLang.value.broken, code: "broken" },
        { name: mergeLang.value.spare, code: "spare" },
        { name: mergeLang.value.other, code: "other" },
      ]),
      selectedFilterStatus: ref("all"),
      filterSearch: ref([
        { name: mergeLang.value.allFilter, code: "all" },
        { name: mergeLang.value.dateRange, code: "date" },
        { name: mergeLang.value.productCode, code: "productCode" },
        { name: mergeLang.value.unit, code: "unitCode" },
        { name: mergeLang.value.address, code: "unitAddressNo" },
        { name: mergeLang.value.runningNo, code: "code" },
        { name: mergeLang.value.serialNo, code: "serial" },
        { name: mergeLang.value.receiptCode, code: "receiptCode" },
      ]),
      selectedFilterSearch: ref("all"),
      sortStatus: ref([
        { name: mergeLang.value.codeSortASC, code: "code_asc" },
        { name: mergeLang.value.codeSortDESC, code: "code_desc" },
        { name: mergeLang.value.dateSortASC, code: "date_asc" },
        { name: mergeLang.value.dateSortDESC, code: "date_desc" },
      ]),
      selectedSortStatus: ref("code_asc"),
      dateStart: ref(null),
      dateEnd: ref(null),
      searchText: ref(""),
      storageName: ref(""),
    });

    //computeds
    const roleByMenu = computed(() => {
      return getRoleByMenuByCode("pms_keycard");
    });
    const isRoleCreate = computed(() => {
      if (roleByMenu.value && roleByMenu.value.insert == "Y") {
        return true;
      }
      return false;
    });
    const isRoleSelect = computed(() => {
      if (roleByMenu.value && roleByMenu.value.select == "Y") {
        return true;
      }
      return false;
    });
    const isSearchDate = computed(() => {
      if (parameters.value.selectedFilterSearch == "date") {
        return true;
      }
      return false;
    });
    const isSearchAll = computed(() => {
      if (parameters.value.selectedFilterSearch == "all") {
        return true;
      }
      return false;
    });
    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const userCode = computed(() => {
      return store.getters.userCode;
    });
    const selectedDateStart = computed(() => {
      if (parameters.value.dateStart) {
        return formatDateToISOString(parameters.value.dateStart);
      }
      return null;
    });
    const selectedDateEnd = computed(() => {
      if (parameters.value.dateEnd) {
        return formatDateToISOString(parameters.value.dateEnd);
      }
      return null;
    });
    const payloadGetDatas = computed(() => {
      let where = {
        projectId: selectedProject.value.id,
        status:
          parameters.value.selectedFilterStatus != "all"
            ? parameters.value.selectedFilterStatus
            : null,
        dateStart:
          parameters.value.selectedFilterSearch === "date"
            ? selectedDateStart.value
            : null,
        dateEnd:
          parameters.value.selectedFilterSearch === "date"
            ? selectedDateEnd.value
            : null,
        productCode:
          parameters.value.selectedFilterSearch === "productCode"
            ? parameters.value.searchText
            : null,
        unitCode:
          parameters.value.selectedFilterSearch === "unitCode"
            ? parameters.value.searchText
            : null,
        unitAddressNo:
          parameters.value.selectedFilterSearch === "unitAddressNo"
            ? parameters.value.searchText
            : null,
        code:
          parameters.value.selectedFilterSearch === "code"
            ? parameters.value.searchText
            : null,
        serial:
          parameters.value.selectedFilterSearch === "serial"
            ? parameters.value.searchText
            : null,
        receiptCode:
          parameters.value.selectedFilterSearch === "receiptCode"
            ? parameters.value.searchText
            : null,
        sortBy: parameters.value.selectedSortStatus
      };
      return {
        page: parameters.value.selectedPage,
        limit: parameters.value.rowsPerPage,
        ...where,
      };
    });
    const stockKeycards = computed(() => {
      return store.getters["arStockKeycard/stockKeycards"];
    });
    const totalPage = computed(() => {
      return store.getters["arStockKeycard/totalPage"];
    });
    const currentPage = computed(() => {
      return store.getters["arStockKeycard/currentPage"];
    });
    const totalRecords = computed(() => {
      return totalPage.value * parameters.value.rowsPerPage;
    });
    const showCurrentPage = computed(() => {
      let page = 0;
      page = parameters.value.rowsPerPage * (parameters.value.selectedPage - 1);
      return page;
    });

    //method
    const setDialog = (dataConfig) => {
      if (dataConfig.classTitle === "") {
        dataConfig.classTitle = "";
      } else if (dataConfig.classTitle === "success") {
        dataConfig.classTitle = "p-text-success";
      } else if (dataConfig.classTitle === "error") {
        dataConfig.classTitle = "p-error";
      }

      dataConfig.button = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "cancel",
        },
      ];

      parameters.value.dataConfigDialog = {
        titleConfirm: dataConfig.title,
        classTitle: dataConfig.classTitle,
        wordingConfirm: dataConfig.message,
        dataButtons: dataConfig.button,
        display: true,
      };
    };

    const roleByMenuDataReady = async () => {
      if (isSharedRoleByMenuReady.value) {
        setLocalSearch();
        await getStockKeycards();
      }
    };

    const goBack = () => {
      router.push({ name: "home" });
    };

    const gotoEdit = (id) => {
      if (id == "create" && !isRoleCreate.value) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.validateRoleInsert,
        });
        createConfirmDialog(
          BaseDialog,
          parameters.value.dataConfigDialog
        ).reveal();
        return true;
      }
      else if (id != "create" && !isRoleSelect.value) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.validateRoleSelect,
        });
        createConfirmDialog(
          BaseDialog,
          parameters.value.dataConfigDialog
        ).reveal();
        return true;
      }

      router.push({ name: "ar-stock-keycard-edit", params: { id: id } });
    };

    const initiateData = () => {
      let date = new Date();
      parameters.value.dateStart = new Date(
        date.getFullYear(),
        date.getMonth(),
        1
      );
      parameters.value.dateEnd = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      );
      parameters.value.searchText = "";
      parameters.value.storageName = `erp_search_StockKeycardList_${selectedProject.value.code}_${userCode.value}`;
    };

    const changeFilterSearch = () => {
      initiateData();
    };

    const checkInputSearch = async (event) => {
      if (parameters.value.selectedFilterSearch == "receiptCode") {
        const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        const keyPressed = event.key;
        if (event.key != "Enter" && !keysAllowed.includes(keyPressed)) {
          event.preventDefault();
        }
      }

      if (event.key == "Enter") {
        await getStockKeycards();
      }
    };

    const validateSearchButton = () => {
      if (!isSearchAll.value && (isSearchDate.value && (!selectedDateStart.value || !selectedDateEnd.value)) ) {
        return true;
      } else if (!isSearchAll.value && (!isSearchDate.value && parameters.value.searchText == "")) {
        return true;
      }

      return false;
    };

    const getStockKeycards = async () => {
      if (validateSearchButton()) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.validateSearchText,
        });
        createConfirmDialog(
          BaseDialog,
          parameters.value.dataConfigDialog
        ).reveal();
        return;
      }

      parameters.value.isLoading = true;
      try {
        // console.log(payloadGetDatas.value);
        await store.dispatch(
          "arStockKeycard/getStockKeycards",
          payloadGetDatas.value
        );
        localStorage.setItem(parameters.value.storageName,JSON.stringify(payloadGetDatas.value));
      } catch (error) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: error,
        });
        createConfirmDialog(
          BaseDialog,
          parameters.value.dataConfigDialog
        ).reveal();
        parameters.value.isLoading = false;
      }
      parameters.value.isLoading = false;
    };

    const onPageChange = async (event) => {
      if (event.rows != parameters.value.rowsPerPage) {
        parameters.value.selectedPage = 1;
      } else {
        parameters.value.selectedPage = event.page + 1;
      }
      parameters.value.rowsPerPage = event.rows;
      await getStockKeycards();
    };

    const setLocalSearch = () => {
      const localSearch = localStorage.getItem(parameters.value.storageName);
      if (localSearch) {
        let search = JSON.parse(localSearch);
        
        if (search.entry === "entry") {
          if (search.status != null && search.status != "") {
            parameters.value.selectedFilterStatus = search.status;
          }

          if (search.dateStart != null && search.dateStart != "") {
            parameters.value.selectedFilterSearch = "date";
            parameters.value.dateStart = new Date(search.dateStart);
          } else parameters.value.dateStart = "";
          if (search.dateEnd != null && search.dateEnd != "") {
            parameters.value.selectedFilterSearch = "date";
            parameters.value.dateEnd = new Date(search.dateEnd);
          } else parameters.value.dateEnd = "";

          if (search.code != null && search.code != "") {
            parameters.value.selectedFilterSearch = "code";
            parameters.value.searchText = search.code;
          } else if (search.unitCode != null && search.unitCode != "") {
            parameters.value.selectedFilterSearch = "unitCode";
            parameters.value.searchText = search.unitCode;
          } else if (search.unitAddressNo != null && search.unitAddressNo != "") {
            parameters.value.selectedFilterSearch = "unitAddressNo";
            parameters.value.searchText = search.unitAddressNo;
          } else if (search.productCode != null && search.productCode != "") {
            parameters.value.selectedFilterSearch = "productCode";
            parameters.value.searchText = search.productCode;
          } else if (search.serial != null && search.serial != "") {
            parameters.value.selectedFilterSearch = "serial";
            parameters.value.searchText = search.serial;
          } else if (search.receiptCode != null && search.receiptCode != "") {
            parameters.value.selectedFilterSearch = "receiptCode";
            parameters.value.searchText = search.receiptCode;
          } else {
            parameters.value.searchText = "";
          }
          parameters.value.rowsPerPage = search.limit;
          parameters.value.selectedPage = search.page;
          search.entry = "";

          localStorage.setItem(parameters.value.storageName, JSON.stringify(search));
        }
      }
    };

    const classTagStatus = (data) => {
      let status = {
        label: mergeLang.value.ready,
        class: "success",
      };
      if (data == "sold") {
        status.label = mergeLang.value.sold;
        status.class = "secondary";
      } else if (data == "lost") {
        status.label = mergeLang.value.lost;
        status.class = "error";
      } else if (data == "broken") {
        status.label = mergeLang.value.broken;
        status.class = "warning";
      } else if (data == "spare") {
        status.label = mergeLang.value.spare;
        status.class = "primary";
      } else if (data == "other") {
        status.label = mergeLang.value.other;
        status.class = "other";
      }
      return status;
    };

    let showUploadStockKeycardDialog = ref(false);
    const importStockKeycard = () => {
      showUploadStockKeycardDialog.value = true;
    };
    const uploadStockKeycard = () => {
      showUploadStockKeycardDialog.value = false;
      // this.$router.push({name: "preview-stock-keycard"});
    }; 

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatDate,
      formatDateToISOString,

      //parameterss
      parameters,

      showUploadStockKeycardDialog,
      
      //computeds
      isSearchDate,
      isSearchAll,
      stockKeycards,
      totalPage,
      currentPage,
      totalRecords,
      showCurrentPage,

      //method
      goBack,
      gotoEdit,
      changeFilterSearch,
      checkInputSearch,
      getStockKeycards,
      classTagStatus,
      importStockKeycard,
      uploadStockKeycard,
      onPageChange,
    };
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.roleByMenuDataReady();
    }
  }
};
</script>
