export default {
  async getMeters(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Voucher/Meter";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get meter");
      throw error;
    }
    // console.log("MeterToGl responseData", responseData);

    // const data = responseData.data;
    context.commit("setMeters", responseData);
  },
  setMeterSelected(context, payload) {
    context.commit("setMeterSelected", payload);
  },
  async getMeterSelected(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Voucher/Meter";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get invoice");
      throw error;
    }
    // console.log("MeterToGl responseData", responseData);

    // const data = responseData.data;
    context.commit("getMeterSelected", responseData);
  },
  async getMeterToGl(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Voucher/MeterToGl";
  
    const token = context.rootGetters.token;      
    // console.log("getReceiptToGl_payload=",payload);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();       

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || "Failed to get project");
      throw error;
    }

    // console.log("getReceiptToGl>>>",responseData);            
    await context.commit('setMeterToGls', responseData);       
        
  },
};
