export default {
    toolbarTitle: "อนุมัติปลอดหนี้",
    title: "รายการอนุมัติปลอดหนี้",
    approve: "อนุมัติ",
    dateRange: "ช่วงวันที่เอกสาร",
    unitAddress: "ยูนิต/บ้านเลขที่",
    ownerName: "ชื่อเจ้าของ",
    searchTextPlaceholder: "กรอกข้อมูลเพื่อค้นหา",
    unitCode: "ยูนิต",
    unitAddressNo: "บ้านเลขที่",
    requestDateList: "วันที่ขอเอกสาร",
    docBy: "ผู้จัดทำเอกสาร",
    pendingCalculateDate: "วันที่ต้องการเอกสาร",
    tableEmptyList: "ไม่พบข้อมูล",
}