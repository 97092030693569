export default {
	async getMeterUsageList(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/MeterUsage";

		if (!payload.limit) {
			url = url + "?limit=100";
		} else {
			url = url + "?limit=" + payload.limit;
		}
		if (payload.page) {
			url = url + "&page=" + payload.page;
		}
		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}
		// if (payload.where) {
		// 	url = url + "?where=" + JSON.stringify(payload.where);
		// }

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Meter Usage");
			throw error;
		}
		// console.log(responseData);
		context.commit("setMeterUsages", responseData);
	},
	// async getAllMeterUsages(context, payload) {
	// 	while (!context.getters.meterUsageIsLastPage) {
	// 	  let where = payload.where;
	// 	  const setPayload = {
	// 		page: context.getters.meterUsageNextPage || 1,
	// 		where,
	// 	  };
	// 	  await context.dispatch("getMeterUsageList", setPayload);
	// 	}
	// },
	resetMeterUsages(context) {
		context.commit("resetMeterUsages");
	},
	async addUpdateMeterUsage(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + `/MeterUsage/${payload.type}`;
		// const fallbackErrorMessage = `Failed to ${payload.type}`;
		const token = context.rootGetters.token;     
		
		const response = await fetch(url, {
			method: payload.type === "AddMeterUsage" ? "post" : "put",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},

			body: JSON.stringify(payload),
		});
	
		//  console.log("payload=",JSON.stringify(payload));
		const responseData = await response.json();
		
		if (!response.ok) {
			// let message = null;
			// if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
			// 	message = responseData.title;
			// 	for (const key in responseData.errors) {
			// 		message += ", " + key.replace("$.", "");
			// 	}
			// } else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
			// 	message = responseData.message;
			// }
			// let error = new Error(message || fallbackErrorMessage);
			// if(responseData.code=="101") error = responseData.code;
			throw responseData;
		}   
		// console.log(' responseData.data', responseData);
		await context.commit('setSelectedMeterUsage', responseData);      
	},
	async getMeterUsageById(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL+'/MeterUsage/' + payload.id;
		url = encodeURI(url);

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			let message = null;
			if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
				message = responseData.title;
				for (const key in responseData.errors) {
					message += ", " + key.replace("$.", "");
				}
			} else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
				message = responseData.message;
			}
			const error = new Error(message || "Failed to get meter usage");
			throw error;
		}

		await context.commit('setSelectedMeterUsage', responseData.data);  
	},
	async importMeterUsage(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + `/MeterUsage/ImportMeterUsage`;
		const token = context.rootGetters.token;     
		
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},

			body: JSON.stringify(payload),
		});
	
		const responseData = await response.json();

		// console.log("importMeterUsage", responseData);
		
		if (!response.ok) {
			let message = null;
			if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
				message = responseData.title;
				for (const key in responseData.errors) {
					message += ", " + key.replace("$.", "");
				}
			} else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
				message = responseData.message;
			}
			let error = new Error(message || 'Failed to import meter usage');
			if(responseData.code=="101") error = responseData.code;
			throw error;
		}   
		await context.commit('setSelectedMeterUsage', responseData);      
	},
	async deleteMeterUsage(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + `/MeterUsage/DeleteMeterUsageByPeriod`;
		const token = context.rootGetters.token;
	
		const response = await fetch(url, {
		  method: 'DELETE',
		  headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + token,
		  },
	
		  body: JSON.stringify(payload),
		});
	
		const responseData = await response.json();

		// console.log("deleteMeterUsage", responseData);
	
		if (!response.ok) {
		  // console.log(responseData);
		  let message = null
		  if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
			message = responseData.title;
			for (const key in responseData.errors) {
			  message += ", " + key.replace("$.","")
			}
		  } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
			message = responseData.message
		  }
		  const error = new Error(message || 'Failed to delete meter usage');
		  throw error;
		}

		if(responseData.code == "128") {
			throw responseData.code;
		}
	
		// context.commit("setMeterUsages", responseData);
	},
	resetSelectedMeterUsage(context) {
		context.commit("resetSelectedMeterUsage");
	},
};
