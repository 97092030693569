export default {
	createTitle: "Create closing entry", 
	editTitle: "Edit closing entry", 
	containerTitle: "Closing entry inforamtion",

	labelDate: "Voucher date", 
	labelAccountBook: "Account book", 
	validateAccountBookIsNull: "Please select account book",
	labelAccountCode: "Account code", 
	validateAccountCodeIsNull: "Please select account code",
	labelCode: "Voucher code", 
	labelDescription: "Voucher description", 
	labelSearch: "Search",
	labelGrandTotalDebit: "Debit amount",
	labelGrandTotalCredit: "Credit amount",
	labelGrandTotalDifferent: "Different amount",

	columnItemNumber: "Item Number",
	columnCode:"Code",
	columnName: "Name",
	columnDebit: "Debit",
	columnCredit: "Credit",

	dialogTitleCancel: "Cancel closing entry.",
	dialogTitleExported: "Export success.",
	dialogContentNoneAccountBook: "Please check account book code: PV in the system?",
	dialogContentCancel: "Are you sure you want to cancel document code {0} data?",
	dialogContentExported: "Export to excel success.",
	dialogValidateParamsProjectId: "The project id parameter is empty.", 
	dialogValidateParamsAccountBook: "The account book id parameter is empty.", 
	dialogValidateParamsId: "The voucher id parameter is empty.", 
	dialogValidateParamsDateAtId: "The date at is empty.", 
	dialogValidateParamsAccountId: "The account id parameter is empty.", 
	dialogValidateInsertVoucherService: "Couldn't insert voucher data. Something went wrong in voucher service.", 
	dialogValidateCancelVoucherService: "Couldn't cancel voucher data. Something went wrong in voucher service.", 
	dialogValidateCancelled: "Couldn't cancel voucher data. Because voucher has cancelled.", 
	dialogValidateAlreadyClosingEntry: "Couldn't insert voucher. Because closing entry date {0} already in the system.", 
	dialogValidateSaveInsertOnly: "Couldn't update voucher data. Because this system insert only.", 
	dialogValidateAccountCodeInvalid: "Couldn't search data. Because the account code doesn't exist.",
	dialogValidateNoDetail: "Couldn't insert voucher data. Because detail data is empty.",
	validateDateSelect: "Please select date.",
};
