import { computed } from "vue";
export default function useTextStyle() {
	const textHeaderClass = computed(() => "text-xl md:text-3xl");
	const textTableClass = computed(() => "text-sm md:text-base");
	const textFormClass = computed(() => "text-sm md:text-base");
	const textAlertClass = computed(() => "text-md md:text-2xl");
	const textCardTitleClass = computed(() => "text-lg md:text-1xl");

	return {
		textHeaderClass,
		textTableClass,
		textFormClass,
		textAlertClass,
		textCardTitleClass,
	};
}
