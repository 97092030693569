export default {
  setProject(state, payload) {
    // state.project.length = 0;
    let projects = [...state.project.data];
    for (const product of payload.projects) {
      if(projects.findIndex(c => c.id === product.id) < 0){
        projects.push(product);
      }
    }
    state.project.data = projects;
    state.project.currentPage = payload.currentPage;
    state.project.totalPage = payload.totalPage;
  },
  resetProject(state) {
    const project = {
      data: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.project = project;
  },
};
