export default {
	title: "Receipt / Tax Invoice",

	inputReceiptCode: "Code",
	inputReceiptReferenceCode: "Reference Code",
	inputReceiptDate: "Receipt Date",
	inputReceiptVatSendDate: "Receipt VAT Send Date",
	inputReceiptSupplier: "Supplier",
	inputEmployeeName: "Employee",

	inputTotalAmount: "Total Amount",
	inputDifferenceAmount: "Difference Amount",
	inputNetAmount: "Net Amount",
	inputActualPayAmount: "Actual Pay Amount",
	inputWithholdingTaxAmount: "Withholding Tax Amount",
	inputDiscountAmount: "Discount Amount",
	inputSubAmount: "Sub Amount",
	inputAddAmount: "Add Amount",
	inputDiscount: "Discount",
	inputCurrencyCode: "Currency Code",
	inputTaxAmount: "VAT Amount",

	inputRemark1: "Remark 1",
	inputRemark2: "Remark 2",

	columnItemNo: "No.",
	columnModuleCode: "Module Code",
	columnDocumentBookCode: "Doc Code",
	columnReceiptDetailCode: "Code",
	columnReferenceDocument: "Ref. Doc",
	columnReceiptDetailDate: "Date",
	columnStatusPaid: "Status",
	columnStatusWithholdingTax: "Tax",
	columnNetAmount: "Net Amount",
	columnDifferenceAmount: "Diff Amount",
	columnPayAmount: "Pay Amount",
	columnPaidAmount: "Paid Amount",

	importPurchaseInvoice: "Import",
	titlePurchaseInvoice: "Purchase Invoice",
	columnInvoiceModuleCode: "Module Code",
	columnInvoiceDocumentBookCode: "Doc Code",
	columnInvoiceDetailCode: "Code",
	columnInvoiceReferenceDocument: "Ref. Doc",
	columnInvoiceDetailDate: "Date",
	columnInvoiceDueDate: "Due Date",
	columnInvoiceNetAmount: "Net Amount",
	columnInvoiceDifferenceAmount: "Diff Amount",
	columnInvoicePayAmount: "Pay Amount",

	validateNoReceiptSupplierSelect: "Please select Supplier",
	validateNoReceiptEmployeeSelect: "Please select Employee",
	validateReceiptDetails: "Please add Purchase Invoice",
	validateReceiptDiscount: "DiscountString format error!",
	validateDiscountAmount: "Discount value must not exceed net amount.",
	validatePurchaseInvoice: "Already this Purchase Invoice",
	validateActualPayAmount: "Actual PayAmount more than 0",
	validateMoreActualPayAmount: "Actual PayAmount less than cheque",
	validateCheque: "Please add Cheque",
	validateMorePurchaseInvoice: "Please complete the first item first.",
	validateTax: "Withholding Tax more than Actual PayAmount",

	buttonWithholdingTax: "Withholding Tax",
	buttonPayout: "Payout",
};
