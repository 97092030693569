export default {
    setAssetGroup(state, payload) {
      // state.project.length = 0;
      state.assetGroups = payload.data;
      state.totalPage = payload.page.pageCount;
      state.currentPage = payload.page.currentPage;
    },
    resetAssetGroup(state) {
      state.assetGroups = [];
      state.totalPage = 0;
      state.currentPage = 0;
    },
  };
  