export default {
	title: "รายการปิดรอบบัญชี",
	createCaption: "ปิดรอบบัญชี",

	containerTitle: "รายการปิดรอบบัญชี",

	columnCode: "เลขที่เอกสาร",
	columnDate: "วันที่เอกสาร",
	colummBookCode: "เล่มเอกสาร",
	colummCancelStatus: "สถานะ",
};
