export default {
  setResidents(state, payload) {
    // state.project.length = 0;
    let residents = [...state.residents];
    for (const resident of payload.residents) {
      if(residents.findIndex(c => c.id === resident.id) < 0){
        residents.push(resident);
      }
    }
    state.residents = residents;
    state.currentPage = payload.currentPage;
    state.totalPage = payload.totalPage;
  },
  setSelectedResident(state, payload){
    state.selectedResident = payload
  },
  resetResident(state) {
    state.residents = [];
    state.currentPage = 0;
    state.totalPage = 0;
  },
  resetResidentSelected(state) {
    state.selectedResident = null;
  },
  setResident(state, payload) {
    state.residents = payload.residents;
    state.totalPage = payload.totalPage;
    state.currentPage = payload.currentPage;
  },
  setUnitCountById(state, payload){
    state.unitCountById = payload
  },
};
  