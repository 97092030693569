export default {
    debtFreeFollowUpList(state) {
      return state.debtFreeFollowUpList;
    },
    totalPage(state) {
      return state.totalPage;
    },
    currentPage(state) {
      return state.currentPage;
    },
    rows(state) {
      return state.rows;
    },
    selectedDebtFreeFollowUp(state){
      return state.selectedDebtFreeFollowUp;
    },
};
  