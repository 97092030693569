<template>
  <base-toolbar
    :title="lang.title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoSave="save"
  ></base-toolbar>

  <base-dialog
    v-model:visible="showValidateDialog"
    :classTitle="baseDialogClassTitle"
    :titleConfirm="titleConfirm"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    @confirm="showValidateDialog = false"
    @close="showValidateDialog = false"
  ></base-dialog>

  <base-container>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{ lang.settingStockKeycard }}</label>

      <PvDivider></PvDivider>

      <div class="pt-3">
        <label for="controlStockKeycard">{{ lang.controlStockKeycard }}</label>
        <div class="flex mt-5">
          <div
            v-for="controlStockKeycard of controlStockKeycards"
            :key="controlStockKeycard.code"
            class="field-radiobutton mr-5"
          >
            <PvRadioButton
              class="mr-3"
              :inputId="controlStockKeycard.code"
              name="controlStockKeycard"
              :value="controlStockKeycard.code"
              v-model="selectedControlStockKeycard"
            />
            <label :for="controlStockKeycard.code">{{
              controlStockKeycard.name
            }}</label>
          </div>
        </div>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import { useStore } from "vuex";
import { computed, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
export default {
  props: ["projectCode"],
  setup() {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.stockKeycard.controlStockKeycard,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.stockKeycard.controlStockKeycard,
        };
      }
    });

    const router = useRouter();

    const dataButtons = ref([
      {
        id: "save",
        action: "gotoSave",
        type: "save",
      },
    ]);

    const controlStockKeycards = ref([
      { name: mergeLang.value.active, code: "Y" },
      { name: mergeLang.value.inactive, code: "N" },
    ]);
    let selectedControlStockKeycard = ref("N");

    let showValidateDialog = ref(false);
    let baseDialogClassTitle = ref("");
    let titleConfirm = ref("");
    let validateWording = ref("");
    const dataButtonDialog = ref([
      {
        id: "confirm",
        caption: mergeLang.value.close,
        class: "p-button-outlined p-button-secondary w-full",
        action: "confirm",
      },
    ]);

    onMounted(async () => {
      setLoading(true);
      initiateData();
      await getControlStockKeycard();
      setData();
    });

    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const controlStockKeycard = computed(() => {
      return store.getters["controlStockKeycard/controlStockKeycard"];
    });
    const payload = computed(() => {
      return {
        projectId: selectedProject.value.id,
        isControlStockKeycard: selectedControlStockKeycard.value,
      };
    });

    const setLoading = (bool) => {
      store.dispatch("setLoading", { value: bool });
    };

    const goBack = () => {
      router.push({ name: "home" });
    };

    const initiateData = () => {
      selectedControlStockKeycard.value = "N";
    };

    const getControlStockKeycard = async () => {
      try {
        let projectId = selectedProject.value.id;
        await store.dispatch(
          "controlStockKeycard/getControlStockKeycard",
          projectId
        );
      } catch (error) {
        baseDialogClassTitle.value = "";
        titleConfirm.value = mergeLang.value.notificationDialogHeader;
        validateWording.value = error;
        showValidateDialog.value = true;
        setLoading(false);
      }
    };

    const setData = () => {
      selectedControlStockKeycard.value =
        controlStockKeycard.value.isControlStockKeycard;
      setLoading(false);
    };

    const save = async () => {
      setLoading(true);
      try {
        await store.dispatch(
          "controlStockKeycard/updateControlStockKeycard",
          payload.value
        );
        baseDialogClassTitle.value = "p-text-success";
        titleConfirm.value = mergeLang.value.saveSuccess;
        validateWording.value = mergeLang.value.saveControlStockKeycardSuccess;
        showValidateDialog.value = true;
      } catch (error) {
        baseDialogClassTitle.value = "";
        titleConfirm.value = mergeLang.value.notificationDialogHeader;
        validateWording.value = error;
        showValidateDialog.value = true;
        setLoading(false);
      }
      setLoading(false);
    };

    return {
      lang: mergeLang,
      textFormClass,

      //data
      dataButtons,
      controlStockKeycards,
      selectedControlStockKeycard,
      showValidateDialog,
      baseDialogClassTitle,
      titleConfirm,
      validateWording,
      dataButtonDialog,

      //methods
      goBack,
      save,
    };
  },
};
</script>
