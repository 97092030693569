<template>
    <PvAutoComplete
        :dropdown="true"
		:suggestions="filteredTaxes"
		field="codeAndName"
		@complete="searchTax"
		@dropdown-click="filteredTaxes"
		forceSelection
    />
</template>

<script>
import useGetTax from "../../hooks/getTax.js";
export default {
    setup() {
        const { filteredTaxes, searchTextTax, isSharedTaxReady, searchTax } = useGetTax();

        return {
            filteredTaxes,
            searchTextTax,
            isSharedTaxReady,
            searchTax
        }
    },
    emits: ["load-finish"],
    watch: {
        isSharedTaxReady(curValue) 
        {
            if (curValue) {
				this.$emit("load-finish");
			}
        }
    }
}
</script>