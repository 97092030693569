<template>
    <base-toolbar :title="lang.autoGl" @goBack="goBack"></base-toolbar>

    <base-container>
        <payment-request-preview-voucher-dialog
            v-model:visible="showPreviewVouvherDialog"
            :key="keyToReLoad"
            @close="closePreviewVouvherDialog"
            :data="selectedPaymentRequest"
        ></payment-request-preview-voucher-dialog>
        <base-progress-bar
            v-model:visible="showProgressBar"
            :title="lang.autoGlProcessing"
            :textProgressBar="lang.autoGlProcessing + '...'"
            :valueObj="valueObj"
            :progressBar="`Template`"
        ></base-progress-bar>
        <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5" :class="textFormClass">
            <div class="flex flex-wrap justify-content-between align-items-center">
                <label class="font-lg font-normal">{{ lang.autoGl }}</label>
                <PvButton class="p-button ml-3" @click="gotoAutoGl">
                    <icon-save
                        iconColor="white"
                        width="16"
                        height="16"
                    />
                    <span class="p-button-label font-normal ml-2">{{lang.saveAll}}</span>
                </PvButton>
            </div>

            <PvDivider></PvDivider>

            <div class="p-datatable-border my-2">
                <PvDataTable
                    :value="paymentRequestForAutoGlList"
                    v-model:selection="selectedPaymentRequest"
                    selectionMode="single"
                    dataKey="itemNo"
                    responsiveLayout="scroll"
                    class="p-datatable-xs"
                    :class="textTableClass"
                    :loading="isLoading"
                    :paginator="true"
                    :rows="rowsPerPage"
                    paginatorTemplate="CurrentPageReport    FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[10, 50, 100]"
                    :currentPageReportTemplate="
                        lang.page + ` {currentPage} / {totalPages}`
                    "
                    :globalFilterFields="[
                        'name',
                        'country.name',
                        'representative.name',
                        'status',
                    ]"
                    scrollable
                >
                    <template #empty>
                        <div class="w-full p-3" style="text-align: center">
                            <label>{{ lang.tableEmpty }}</label>
                        </div>
                    </template>
                    <PvColumn
                        field="itemNo"
                        :header="lang.itemNo"
                        style="max-width: 6rem; height: 56px"
                        headerClass="h-left"
                        bodyClass="text-left"
                    />
                    <PvColumn
                        field="status"
                        :header="lang.status"
                        style="min-width: 12rem; height: 56px"
                        headerClass="h-left"
                        bodyClass="text-left"
                    >
                        <template #body="slotProps">
                        <base-tag
                            :Caption="
                            setBaseTagStatus(slotProps.data.status)
                                .caption
                            "
                            :Class="
                            setBaseTagStatus(slotProps.data.status).class
                            "
                        />
                        </template>
                    </PvColumn>
                    <PvColumn
                        field="ledgerBookCode"
                        :header="lang.ledgerBookCode"
                        style="min-width: 6rem; height: 56px"
                        headerClass="h-left"
                        bodyClass="text-left"
                    />
                    <PvColumn
                        field="voucherDate"
                        :header="lang.voucherDate"
                        style="min-width: 12rem; height: 56px"
                        headerClass="h-left"
                        bodyClass="text-left"
                        sortable
                    >
                        <template #body="slotProps">
                        {{ formatDate(slotProps.data.voucherDate) }}
                        </template>
                    </PvColumn>
                    <PvColumn
                        field="code"
                        :header="lang.paymentRequestCode"
                        style="min-width: 10rem; height: 56px"
                        headerClass="h-left"
                        bodyClass="text-left"
                        sortable
                    />
                    <PvColumn
                        field="date"
                        :header="lang.paymentRequestDate"
                        style="min-width: 10rem; height: 56px"
                        headerClass="h-left"
                        bodyClass="text-left"
                        sortable
                    >
                        <template #body="slotProps">
                        {{ formatDate(slotProps.data.date) }}
                        </template>
                    </PvColumn>
                    <PvColumn
                        field="paymentRequestTypeName"
                        :header="lang.paymentRequestType"
                        style="min-width: 10rem; height: 56px"
                        headerClass="h-left"
                        bodyClass="text-left"
                        sortable
                    />
                    <PvColumn
                        field="netAmount"
                        :header="lang.columnTotalAmount"
                        style="min-width: 10rem; height: 56px"
                        headerClass="h-right"
                        bodyClass="text-right"
                        sortable
                    >
                        <template #body="slotProps">
                        <span class="text-right w-full">{{
                            formatCurrency(slotProps.data.netAmount)
                        }}</span>
                        </template>
                    </PvColumn>
                    <PvColumn
                        style="min-width: 4rem"
                        headerClass="h-center"
                        bodyStyle="text-align: center; justify-content: center; align-items: center;"
                        alignFrozen="right"
                        frozen
                    >
                        <template #body="slotProps">
                            <PvButton
                                type="button"
                                class="p-button-secondary p-button-text p-0"
                                @click="gotoPreviewVoucher(slotProps.data)"
                            >
                                <icon-eye iconColor="secondary" width="24" height="24" />
                            </PvButton>
                        </template>
                    </PvColumn>

                </PvDataTable>
            </div>

        </div>
    </base-container>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, ref, onMounted } from "vue";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import PaymentRequestPreviewVoucherDialog from "../../components/PaymentRequest/PaymentRequestPreviewVoucherDialog.vue";
import BaseDialog from "../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
export default {
    components: {
        PaymentRequestPreviewVoucherDialog,
    },
    props: ["projectCode"],
    setup() {
        const router = useRouter();
        const store = useStore();
        const { lang } = useLang();
        const mergeLang = computed(function () {
            if (store.getters.selectedLanguage === "TH") {
                return {
                ...lang.th.base,
                ...lang.th.ap.paymentRequest.listing,
                };
            } else {
                return {
                ...lang.en.base,
                ...lang.en.ap.paymentRequest.listing,
                };
            }
        });
        const { textTableClass, textFormClass } = useTextStyle();
        const { formatDate, formatCurrency } = useFormat();

        onMounted(() => {
            if (paymentRequestForAutoGl.value.data) {
                paymentRequestForAutoGlList.value = paymentRequestForAutoGl.value.data.map((data) => ({
                    ...data.paymentRequestData,
                    voucherData: data.voucherData,
                }));
            }
            // console.log("paymentRequestForAutoGlList", paymentRequestForAutoGlList.value);
        });

        let isLoading = ref(false);
        let selectedPaymentRequest = ref(null);
        let showPreviewVouvherDialog = ref(false);
        let keyToReLoad = ref(0);
        let paymentRequestForAutoGlList = ref([]);
        let selectedPage = ref(1);
        let rowsPerPage = ref(10);
        let showProgressBar = ref(false);
        let valueObj = ref([]);
        let dataConfigDialog = ref(null);

        //computed
        const paymentRequestForAutoGl = computed(() => {
            return store.getters["paymentRequest/paymentRequestForAutoGl"];
        });
        
        const voucherFromAutoGl = computed(() => {
            return store.getters["glAccountingEntry/selectedAccountingEntry"];
        });

        //methods
        const goBack = () => {
            router.push({ name: "payment-request" });
        };

        const addVoucher = async (data) => {
            // console.log("addVoucher", data);
            let payload = {
                ...data.voucherData,
                typeInsert: "Add",
            }
            try {
                await store.dispatch("glAccountingEntry/createOrUpdateAccountingEntry", payload);
                if (voucherFromAutoGl.value) {
                    data.status = "success";
                    data.markStatus = "1";
                }
                return true;
            } catch (error) {
                data.status = "error";
                data.markStatus = "1";
                return false;
            }
        };

        const validateData = () => {
            if (paymentRequestForAutoGl.value.length == 0) {
                setDialog({
                    classTitle: "",
                    title: mergeLang.value.notificationDialogHeader,
                    message: mergeLang.value.validateNoDataForAutoGl,
                    type: "",
                });
                return true;
            }
            return false;
        };

        const gotoAutoGl = async () => {
            if (validateData()) {
                await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
                return;
            }

            valueObj.value = [];
            valueObj.value = paymentRequestForAutoGlList.value;
            showProgressBar.value = true;
            for (const data of paymentRequestForAutoGlList.value) {
                await addVoucher(data);
            }
            showProgressBar.value = false;

            setDialog({
                classTitle: "success",
                title: mergeLang.value.saveSuccess,
                message: mergeLang.value.saveSuccess,
                type: "",
            });
            await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();

            await store.dispatch("paymentRequest/resetPaymentRequestForAutoGl");
        };
        
        const gotoPreviewVoucher = (data) => {
            let result = data.voucherData;
            selectedPaymentRequest.value = {
                ...result,
                voucherDate: data.voucherDate,
                ledgerBookCode: data.ledgerBookCode, 
                ledgerBookName: data.ledgerBookName
            };
            showPreviewVouvherDialog.value = true;
            keyToReLoad.value++;
        };

        const closePreviewVouvherDialog = () => {
            showPreviewVouvherDialog.value = false;
        };

        const setBaseTagStatus = (status) => {
            let result = {
                caption: "",
                class: "",
            };

            if (status.toLowerCase() === "wait") {
                result.caption = mergeLang.value.wait;
                result.class = "warning";
            } else if (status.toLowerCase() === "success") {
                result.caption = mergeLang.value.success;
                result.class = "success";
            } else if (status.toLowerCase() === "error") {
                result.caption = mergeLang.value.error;
                result.class = "error";
            }

            return result;
        }

        const setDialog = (dataConfig) => {
            const classMap = {
                success: "p-text-success",
                error: "p-error",
            };

            dataConfig.classTitle = classMap[dataConfig.classTitle] || "";

            dataConfig.button = [
                {
                id: "close",
                caption: mergeLang.value.close,
                class: "p-button-outlined p-button-secondary w-full",
                action: "cancel",
                },
            ];

            dataConfigDialog.value = {
                titleConfirm: dataConfig.title,
                classTitle: dataConfig.classTitle,
                wordingConfirm: dataConfig.message,
                dataButtons: dataConfig.button,
                display: true,
            };
        };


        return {
            lang: mergeLang,
            textTableClass,
            textFormClass,
            formatDate,
            formatCurrency,

            //data
            isLoading,
            selectedPaymentRequest,
            paymentRequestForAutoGlList,
            showPreviewVouvherDialog,
            keyToReLoad,
            selectedPage,
            rowsPerPage,
            showProgressBar,
            valueObj,

            //methods
            goBack,
            gotoAutoGl,
            gotoPreviewVoucher,
            setBaseTagStatus,
            closePreviewVouvherDialog,

            //computed
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-right: auto;
  }
}
</style>