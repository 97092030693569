export default {
    setFormType(state, payload){
        // state.project.length = 0; 
        state.formTypes = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },
    setSelectedFormType(state,payload){
        state.selectedFormType = payload
    }
};