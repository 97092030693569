<template>
  <PvDialog
    :breakpoints="{ '1280px': '80vw', '640px': '80vw' }"
    :style="{ width: '80vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="lang.notificationDialogHeader"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
    ></base-dialog>

    <template #header>
      <div class="flex flex-wrap justify-content-between align-items-right p-0">
        <div class="p-0">
          <label class="font-lg">{{ lang.pendingList }}</label>
        </div>
        <div>
          <PvButton
            icon="pi pi-times"
            class="p-button-link p-icon-secondary pb-0 pt-0"
            style="width: 15px"
            @click="closeDialog()"
          />
        </div>
      </div>
      <PvDivider></PvDivider>

      <div
        class="flex flex-wrap justify-content-between align-items-center pb-3 pt-1"
      >
        <div class="flex lg:col-6 md:col-6 col-6 p-0">
          <span class="p-input-icon-left w-full mr-2">
            <i class="pi pi-search" />
            <PvInputText
              class="inputfield w-full"
              v-model="searchText"
              :placeholder="lang.searchTextPlaceholder"
              @keyup.enter="getPendingList()"
              autofocus
            />
          </span>

          <PvButton @click="getPendingList()" style="width: 6rem">
            <span class="font-normal" style="width: 6rem">{{
              lang.search
            }}</span>
          </PvButton>
        </div>

        <PvButton class="ml-2" @click="selectPending(this.selectedPending)">
          <icon-documents-file-checkmark iconColor="white" />
          <span class="font-normal w-full ml-1">{{ lang.paySelected }}</span>
        </PvButton>
      </div>
    </template>

    <div class="p-datatable-border my-2">
      <PvDataTable
        :value="filteredPendings"
        v-model:selection="selectedPending"
        dataKey="detailId"
        :class="textTableClass"
        :loading="isLoading"
        :scrollHeight="halfViewportHeight + 'px'"
        selectionMode="multiple"
        :metaKeySelection="false"
        responsiveLayout="scroll"
        class="p-datatable-xs"
        :scrollable="true"
      >
        <template #empty>
          <div
            style="height: 56px"
            class="text-center flex align-items-center justify-content-center w-full"
          >
            <label class="font-normal">
              {{ lang.tableEmpty }}
            </label>
          </div>
        </template>
        <PvColumn
          selectionMode="multiple"
          style="flex: 0; height: 56px"
        ></PvColumn>

        <PvColumn
          field="itemNo"
          :header="lang.itemNo"
          style="max-width: 5rem; height: 56px"
          headerClass="h-center font-normal"
          bodyClass="h-center font-normal"
        />

        <PvColumn
          field="code"
          :header="lang.documentCode"
          style="min-width: 10rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
          sortable
        />

        <PvColumn
          field="date"
          :header="lang.date"
          style="min-width: 10rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
          sortable
        >
          <template #body="slotProps">
            {{ formatDate(slotProps.data.date) }}
          </template>
        </PvColumn>

        <PvColumn
          field="productCode"
          :header="lang.productCode"
          style="min-width: 10rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
          sortable
        />

        <PvColumn
          field="productName"
          :header="lang.productName"
          style="min-width: 20rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
          sortable
        />

        <PvColumn
          field="period"
          :header="lang.period"
          style="min-width: 5rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
          sortable
        />

        <PvColumn
          field="totalAmount"
          :header="lang.totalAmount"
          style="min-width: 5rem; height: 56px"
          headerClass="h-right font-normal"
        >
          <template #body="slotProps">
            <span class="text-right w-full">
              {{ formatCurrency(slotProps.data.totalAmount) }}
            </span>
          </template>
        </PvColumn>

        <PvColumn
          field="pendingAmount"
          :header="lang.columnDiffAmount"
          style="min-width: 5rem; height: 56px"
          headerClass="h-right font-normal"
        >
          <template #body="slotProps">
            <span class="text-right w-full">
              {{ formatCurrency(slotProps.data.pendingAmount) }}
            </span>
          </template>
        </PvColumn>
        <template #footer>
          <PvPaginator
            :rows="rowsPerPage"
            :totalRecords="totalRecords"
            @page="onPageChange($event)"
            :rowsPerPageOptions="[10, 50, 100]"
            class="p-pagination-radius"
          >
            <template #start="slotProps">
              {{ lang.page }}:
              {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
            </template>
          </PvPaginator>
        </template>
      </PvDataTable>
    </div>

    <template #footer></template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  props: ["isOpen", "unit", "date", "creditNoteDetails"],
  emits: ["close", "pending-selected"],
  setup() {
    const { lang } = useLang();
    const { textTableClass } = useTextStyle();
    const { formatCurrency, formatDateToISOString, formatDate } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.creditNote.listing,
          ...lang.th.ar.creditNote.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.creditNote.listing,
          ...lang.en.ar.creditNote.edit,
        };
      }
    });

    return {
      lang: mergeLang,

      textTableClass,

      formatCurrency,
      formatDateToISOString,
      formatDate,
    };
  },
  data() {
    return {
      searchText: "",
      isLoading: false,
      selectedPending: [],
      filteredPending: [],
      isFilter: false,

      showValidateDialog: false,
      validateWording: this.lang.validateNoDetailSelect,
      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ],
      selectedPage: 1,
      rowsPerPage: 100,
      invoicePendingList: null,
    };
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    initiateData() {
      this.searchText = "";
      this.isLoading = false;
      this.selectedPending = [];
      this.filteredPending = [];
      this.isFilter = false;
      this.invoicePendingList = null;
      this.$store.dispatch("arInvoice/resetPendingList");
    },
    async getPendingList() {
      this.isLoading = true;
      this.selectedPending = [];

      try {
        await this.$store.dispatch(
          "arInvoice/getPendingList",
          this.payloadGetData
        );
        this.invoicePendingList = this.pendings;
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
      }

      this.isLoading = false;
      this.setLoading(false);
    },
    selectPending(data) {
      if (data.length <= 0) {
        this.showValidateDialog = true;
        return;
      }
      // console.log("selectPending", data);
      this.$emit("pending-selected", data);
      this.closeDialog;
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    filterPending() {
      if (!this.searchText) {
        this.filteredPending = [...this.pendingExceptCreditNoteDetails];
        this.isFilter = false;
      } else {
        this.filteredPending = this.pendingExceptCreditNoteDetails.filter(
          (pending) => {
            return pending.searchText
              .toLowerCase()
              .includes(this.searchText.toLowerCase());
          }
        );
        this.isFilter = true;
      }
      this.selectedPending = [];
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getPendingList();
    },
  },
  computed: {
    payloadGetData() {
      let creditNoteDetailId = [];
      this.creditNoteDetails.forEach((element) => {
        creditNoteDetailId.push(element.referenceId);
      });

      let where = {
        projectId: this.selectedProject.id,
        unitId: this.unit.id,
        date: this.formatDateToISOString(this.date),
        searchText: this.searchText,
        referenceId: creditNoteDetailId,
      };

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };
      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    pendingList() {
      return this.$store.getters["arInvoice/pendingLists"];
    },
    pendings() {
      let pendings = [];
      let i = 1;
      if (this.pendingList.length > 0) {
        this.pendingList.forEach((element) => {
          element = { ...element, ...{ itemNo: i } };
          pendings.push(element);
          i++;
        });
      }
      return pendings;
    },
    filteredPendings() {
      if (!this.isFilter) {
        return this.pendingExceptCreditNoteDetails;
      } else {
        return this.filteredPending;
      }
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    pendingExceptCreditNoteDetails() {
      let details = this.pendings;
      if (details.length > 0) {
        this.creditNoteDetails.forEach((element) => {
          const index = details.findIndex(
            (p) => p.detailId === element.referenceId
          );
          if (index >= 0) {
            details.splice(index, 1);
          }
        });

        let i = 1;
        let data = [];
        details.forEach((element) => {
          element = { ...element, ...{ itemNo: i } };
          data.push(element);
          i++;
        });
        details = data;
      }

      return details;
    },

    totalPage() {
      return this.$store.getters["arInvoice/pendingListTotalPage"];
    },
    currentPage() {
      return this.$store.getters["arInvoice/pendingListCurrentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
  },
  watch: {
    isOpen(curValue) {
      if (curValue) {
        this.initiateData();
        this.getPendingList();
      }
    },
  },
};
</script>
