<template>
  <div class="w-full">
    <iframe class="responsive-iframe" :src="url" :title="title"> </iframe>
  </div>
</template>

<script>
export default {
  computed: {
    url() {
      return this.$route.query.url;
    },
    title() {
      let result = this.$route.query.title ? this.$route.query.title + " | " : "";
      document.title = result + this.projectName + " | The LivingOS";
      return result;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    projectName() {
      let result = "";
      if (this.selectedProject) {
        if (this.$store.getters.selectedLanguage === "TH") {
          result = this.selectedProject.nameTh.trim();
        } else {
          result = this.selectedProject.nameEn.trim();
        }
      }
      return result;
    },
  },
};
</script>

<style scoped>
.responsive-iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  border: none;
  width: calc(100% - 260px);
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
