export default {
	title: "ผู้จัดจำหน่าย",
	createCaption: "เพิ่มผู้จัดจำหน่าย",

	containerTitle: "รายการผู้จัดจำหน่าย",
	dropdownAll: "ทั้งหมด",
	dropdownCode: "รหัสผู้จัดจำหน่าย",
	dropdownName: "ชื่อผู้จัดจำหน่าย",
	dropdownTelephoneNumber: "เบอร์โทรศัพท์",

	columnCode: "รหัสผู้จัดจำหน่าย",
	columnName: "ชื่อผู้จัดจำหน่าย",
	colummTaxId: "เลขผู้เสียภาษี",
	colummTelephoneNumber: "เบอร์โทรศัพท์",
	colummEmail: "อีเมล",

	importSupplier: "นำเข้าผู้จัดจำหน่าย",
};
