export default {
  async getAvailableGuaranteeByUnit(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Guarantee/AvailableGuaranteeByUnit";

    if (payload.unitId) {
      url = url + "?" + encodeURI("unitId=" + payload.unitId);
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get available guarantee by unit");
      throw error;
    }

    await context.commit("setAvailableGuaranteeByUnit", responseData.data);
  },
  resetAvailableGuaranteeByUnit(context) {
    context.commit("setAvailableGuaranteeByUnit", []);
  },
};
