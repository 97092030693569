export default {
	title: "Asset Register",
	assetCodeStart: "Code Start",
    assetCodeEnd: "Code End",
	
	columnAssetCode: "Code",
    columnAssetName: "Asset Name",
    columnAssetAccountCode: "Account Code",
    columnBeginDate: "Begin Date",
    columnBuyPrice: "Buy Price",
    columnDeprePrice: "Depreciation Price",
    importAsset: "Import Asset",
};
