export default {
    title: "ใบรับฝากเงิน",
    addDeposit: "สร้างใบรับฝากเงิน",
    dateRange: "ช่วงวันที่",
    documentCode: "เลขที่เอกสาร",
    date: "วันที่เอกสาร",
    unit: "ยูนิต",
    address:"บ้านเลขที่",
    customerName: "ชื่อลูกค้า",
    searchTextPlaceholder: "กรอกข้อมูลเพื่อค้นหา",
    payAmount: "จำนวนเงิน",
    paidAmount: "หักเงิน",
    balance: "คงเหลือ",
    columnCreateDate:"วันที่สร้าง",
    columnUpdateDate:"วันที่แก้ไขล่าสุด", 
    columnCreateBy: "ผู้สร้าง",
    columnUpdateBy: "ผู้แก้ไข",
    validateRoleSelect: "คุณไม่มีสิทธิ์ เข้าถึงเอกสาร",
    statusAll: "สถานะทั้งหมด",
    statusWaitForDeduct: "รอหักค่าใช้จ่าย",
    statusDeducted: "หักเงินฝากหมดแล้ว",
    statusCancel: "ยกเลิก",
    documentBookCode: "เล่มเอกสาร",
    receiptCode: "เลขที่ใบเสร็จ",
    receiptDate: "วันที่ใบเสร็จ",
    titleListing: "รายการใบรับฝากเงิน",
    importDeposit: "นำเข้าใบรับฝากเงิน",
}