import actions from "./actions.js";
import getters from "./getters.js";
import mutations from "./mutations.js";

export default {
	namespaced: true,
	state() {
		return {
			purchaseReceipts: [],
			purchaseInvoices: [],
			selectedPurchaseReceipt: null,
			totalPage: 0,
			currentPage: 1,
			rows: 100,
			blob: null,
			totalPageInvoice: 0,
			currentPageInvoice: 1,
			purchaseReceiptImport: [],
			purchaseReceiptsToGl: [],
			purchaseReceiptsVoucher: [],
			totalPageVoucher: 0,
			currentPageVoucher: 1,
			purchaseReceiptReference:[],
		};
	},
	actions,
	getters,
	mutations,
};
