<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '640px': '80vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <span class="font-lg">{{ lang.detailSupplier }}</span>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <section class="flex flex-wrap" :class="textFormClass">
      <!-- ข้อมูลทั่วไป -->
      <div class="flex flex-wrap lg:col-12 md:col-12 col-12 p-0">
        <div class="lg:col-12 md:col-12 col-12 p-0">
          <span class="font-lg">{{ lang.generalInformation }}</span>
        </div>

        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('code') }"
          >
            {{ lang.labelCode }}
          </label>
          <div class="mt-2">
            <label
              for="code"
              class="font-md"
              :class="{ 'p-text-error': checkError('code') }"
            >
              {{ supplier.code }}
            </label>
          </div>
        </div>

        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('taxId') }"
          >
            {{ lang.labelTaxId }}
          </label>
          <div class="mt-2">
            <label
              for="taxId"
              class="font-md"
              :class="{ 'p-text-error': checkError('taxId') }"
            >
              {{ supplier.taxId }}</label
            >
          </div>
        </div>

        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('name') }"
          >
            {{ lang.labelName }}
          </label>
          <div class="mt-2">
            <label
              for="name"
              class="font-md"
              :class="{ 'p-text-error': checkError('name') }"
              >{{ supplier.name }}</label
            >
          </div>
        </div>

        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('gender') }"
          >
            {{ lang.labelGender }}
          </label>
          <div class="mt-2">
            <label
              for="gender"
              class="font-md"
              :class="{ 'p-text-error': checkError('gender') }"
            >
              {{ supplier.gender }}
            </label>
          </div>
        </div>

        <div class="lg:col-12 md:col-12 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('dbdBusinessType') }"
          >
            {{ lang.businessType }}
          </label>
          <div class="mt-2">
            <label
              for="dbdBusinessType"
              class="font-md"
              :class="{ 'p-text-error': checkError('dbdBusinessType') }"
            >
              {{ supplier.dbdBusinessType }}
            </label>
          </div>
        </div>

        <div class="lg:col-12 md:col-12 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('address1') }"
          >
            {{ lang.labelContactAddress1 }}
          </label>
          <div class="mt-2">
            <label
              for="address1"
              class="font-md"
              :class="{ 'p-text-error': checkError('address1') }"
            >
              {{ supplier.address1 }}
            </label>
          </div>
        </div>

        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkAddressError('addressSubDistrict') }"
          >
            {{ lang.subdistrict }}
          </label>
          <div class="mt-2">
            <label
              for="addressSubDistrict"
              class="font-md"
              :class="{
                'p-text-error': checkAddressError('addressSubDistrict'),
              }"
            >
              {{ supplier.addressSubDistrict }}
            </label>
          </div>
        </div>

        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkAddressError('addressDistrict') }"
          >
            {{ lang.district }}
          </label>
          <div class="mt-2">
            <label
              for="addressDistrict"
              class="font-md"
              :class="{ 'p-text-error': checkAddressError('addressDistrict') }"
            >
              {{ supplier.addressDistrict }}
            </label>
          </div>
        </div>

        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkAddressError('address3') }"
          >
            {{ lang.province }}
          </label>
          <div class="mt-2">
            <label
              for="address3"
              class="font-md"
              :class="{ 'p-text-error': checkAddressError('address3') }"
            >
              {{ supplier.address3 }}
            </label>
          </div>
        </div>

        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkAddressError('postCode') }"
          >
            {{ lang.postcode }}
          </label>
          <div class="mt-2">
            <label
              for="postCode"
              class="font-md"
              :class="{ 'p-text-error': checkAddressError('postCode') }"
            >
              {{ supplier.postCode }}
            </label>
          </div>
        </div>

        <div class="flex flex-wrap lg:col-6 md:col-6 col-12 p-0">
          <div class="p-0 pr-3 pt-3">
            <label
              class="p-text-secondary"
              :class="{
                'p-text-error': checkError('telephoneCountryCode'),
              }"
            >
              {{ lang.countryCode }}
            </label>
            <div class="mt-2">
              <label
                for="telephoneCountryCode"
                class="font-md"
                :class="{
                  'p-text-error': checkError('telephoneCountryCode'),
                }"
              >
                {{ supplier.telephoneCountryCode }}
              </label>
            </div>
          </div>
          <div class="p-0 pt-3">
            <label
              class="p-text-secondary"
              :class="{ 'p-text-error': checkError('telephoneNumber') }"
            >
              {{ lang.labelTelephoneNumber }}
            </label>
            <div class="mt-2">
              <label
                for="telephoneNumber"
                class="font-md"
                :class="{ 'p-text-error': checkError('telephoneNumber') }"
              >
                {{ supplier.telephoneNumber }}
              </label>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap lg:col-6 md:col-6 col-12 p-0">
          <div class="p-0 pr-3 pt-3">
            <label
              class="p-text-secondary"
              :class="{
                'p-text-error': checkError('mobileCountryCode'),
              }"
            >
              {{ lang.countryCode }}
            </label>
            <div class="mt-2">
              <label
                for="mobileCountryCode"
                class="font-md"
                :class="{
                  'p-text-error': checkError('mobileCountryCode'),
                }"
              >
                {{ supplier.mobileCountryCode }}
              </label>
            </div>
          </div>
          <div class="p-0 pt-3">
            <label
              class="p-text-secondary"
              :class="{ 'p-text-error': checkError('mobileNumber') }"
            >
              {{ lang.labelMobileNumber }}
            </label>
            <div class="mt-2">
              <label
                for="mobileNumber"
                class="font-md"
                :class="{ 'p-text-error': checkError('mobileNumber') }"
              >
                {{ supplier.mobileNumber }}
              </label>
            </div>
          </div>
        </div>

        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{
              'p-text-error': checkError('faxNumber'),
            }"
          >
            {{ lang.labelFaxNumber }}
          </label>
          <div class="mt-2">
            <label
              for="faxNumber"
              class="font-md"
              :class="{
                'p-text-error': checkError('faxNumber'),
              }"
            >
              {{ supplier.faxNumber }}
            </label>
          </div>
        </div>

        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('email') }"
          >
            {{ lang.labelEmail }}
          </label>
          <div class="mt-2">
            <label
              for="email"
              class="font-md"
              :class="{ 'p-text-error': checkError('email') }"
            >
              {{ supplier.email }}
            </label>
          </div>
        </div>
      </div>
      <!-- ข้อมูลทั่วไป -->

      <!-- ข้อมูลติดต่อฉุกเฉิน -->
      <div class="flex flex-wrap lg:col-12 md:col-12 col-12 p-0 pt-6">
        <div class="lg:col-12 md:col-12 col-12 p-0">
          <span class="font-lg">{{ lang.emergencyContact }}</span>
        </div>

        <div class="flex flex-wrap lg:col-6 md:col-6 col-12 p-0">
          <div class="p-0 pr-3 pt-3">
            <label
              class="p-text-secondary"
              :class="{ 'p-text-error': checkError('emergencyContactPrefix') }"
            >
              {{ lang.prefix }}
            </label>
            <div class="mt-2">
              <label
                for="emergencyContactPrefix"
                class="font-md"
                :class="{
                  'p-text-error': checkError('emergencyContactPrefix'),
                }"
              >
                {{ supplier.emergencyContactPrefix }}
              </label>
            </div>
          </div>
          <div class="p-0 pt-3">
            <label
              class="p-text-secondary"
              :class="{ 'p-text-error': checkError('emergencyContactName') }"
            >
              {{ lang.contactName }}
            </label>
            <div class="mt-2">
              <label
                for="emergencyContactName"
                class="font-md"
                :class="{
                  'p-text-error': checkError('emergencyContactName'),
                }"
              >
                {{ supplier.emergencyContactName }}
              </label>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap lg:col-6 md:col-6 col-12 p-0">
          <div class="p-0 pr-3 pt-3">
            <label
              class="p-text-secondary"
              :class="{ 'p-text-error': checkError('emergencyCountryCode') }"
            >
              {{ lang.contactCountryCode }}
            </label>
            <div class="mt-2">
              <label
                for="emergencyCountryCode"
                class="font-md"
                :class="{ 'p-text-error': checkError('emergencyCountryCode') }"
              >
                {{ supplier.emergencyCountryCode }}
              </label>
            </div>
          </div>
          <div class="p-0 pt-3">
            <label
              class="p-text-secondary"
              :class="{ 'p-text-error': checkError('emergencyContactMobile') }"
            >
              {{ lang.contactMobile }}
            </label>
            <div class="mt-2">
              <label
                for="emergencyContactMobile"
                class="font-md"
                :class="{
                  'p-text-error': checkError('emergencyContactMobile'),
                }"
              >
                {{ supplier.emergencyContactMobile }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <!-- ข้อมูลติดต่อฉุกเฉิน -->

      <!-- ข้อมูลทางบัญชี -->
      <div class="flex flex-wrap lg:col-12 md:col-12 col-12 p-0 pt-6">
        <div class="lg:col-12 md:col-12 col-12 p-0">
          <span class="font-lg">{{ lang.accountingData }}</span>
        </div>

        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('supplierCreditTerm') }"
          >
            {{ lang.labelSupplierCreditTerm }}
          </label>
          <div class="mt-2">
            <label
              for="supplierCreditTerm"
              class="font-md"
              :class="{ 'p-text-error': checkError('supplierCreditTerm') }"
            >
              {{ supplier.supplierCreditTerm }}
            </label>
          </div>
        </div>

        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('bankAccountNumber') }"
          >
            {{ lang.labelBankAccountNumber }}
          </label>
          <div class="mt-2">
            <label
              for="bankAccountNumber"
              class="font-md"
              :class="{ 'p-text-error': checkError('bankAccountNumber') }"
            >
              {{ supplier.bankAccountNumber }}
            </label>
          </div>
        </div>

        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('withholdingTaxRate') }"
          >
            {{ lang.labelWithholdingTaxRate }}
          </label>
          <div class="mt-2">
            <label
              for="withholdingTaxRate"
              class="font-md"
              :class="{ 'p-text-error': checkError('withholdingTaxRate') }"
            >
              {{ supplier.withholdingTaxRate }}
            </label>
          </div>
        </div>

        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('bank') }"
          >
            {{ lang.labelBank }}
          </label>
          <div class="mt-2">
            <label
              for="bank"
              class="font-md"
              :class="{ 'p-text-error': checkError('bank') }"
            >
              {{ supplier.bank }}
            </label>
          </div>
        </div>

        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('vatRate') }"
          >
            {{ lang.labelVatRate }}
          </label>
          <div class="mt-2">
            <label
              for="vatRate"
              class="font-md"
              :class="{ 'p-text-error': checkError('vatRate') }"
            >
              {{ supplier.vatRate }}
            </label>
          </div>
        </div>
      </div>
      <!-- ข้อมูลทางบัญชี -->
    </section>

    <template #footer>
      <PvButton
        :label="lang.close"
        class="p-button-outlined p-button-secondary w-3"
        @click="closeDialog"
      />
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  emits: ["close"],
  props: {
    selectedSupplier: {
      type: Object,
    },
  },
  setup(props, context) {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const store = useStore();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.supplier.edit,
          ...lang.th.master.supplier.import,
          ...lang.th.validateImport,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.supplier.edit,
          ...lang.en.master.supplier.import,
          ...lang.en.validateImport,
        };
      }
    });

    //computeds
    const supplier = computed(() => {
      return props.selectedSupplier;
    });
    const errorLength = computed(() => {
      return props.selectedSupplier.error.length;
    });

    //methods
    const closeDialog = () => {
      context.emit("close");
    };
    const checkError = (field) => {
      if (errorLength.value > 0) {
        let error = supplier.value.error.find((error) => error.field == field);
        if (error) {
          return true;
        }
      }
      return false;
    };
    const checkAddressError = (field) => {
      let result = false;
      if (errorLength.value > 0) {
        let error = supplier.value.error.find(
          (error) => error.field == "address"
        );
        if (error) {
          result = true;
        } else {
          error = supplier.value.error.find((error) => error.field == field);
          if (error) {
            result = true;
          }
        }
      }
      return result;
    };

    return {
      lang: mergeLang,
      textFormClass,

      //computeds
      supplier,

      //methods
      closeDialog,
      checkError,
      checkAddressError,
    };
  },
};
</script>
