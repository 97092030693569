export default {
    title: "Calculate Invoice",
    validateInsert: "You may not have permission to calculate invoice.",
    titleDetail: "Calculate Invoice Detail",
    titleSelectDate: "1. Please select invoice date and payment due date.",
    invoiceDate: "Invoice Date",
    dueDate: "Due Date",
    calculatePenalty: "Calculate Penalty",
    calculatePenaltyDate: "Calculate Penalty Date",
    titleDetailCalculating: "The system is calculating the invoice.",
    titleDetailCalculatingDesc: "Please wait a moment, If successful The system will go to next step.",
    titleSelectPolicy: "2. Check policy.",
    next: "Next",
    validateInvoiceDate: "Invoice date must be before the Due date.",
    goBack: "Back",
    getPolicyList: "Policy List",
    columnProductCode: "Product Code",
    columnProductName: "Product Name",
    columnRemark: "Remark",
    columnPeriod: "Period",
    gotoCalculateInvoice: "Start Calculating Invoice",
    policy: "Policy",
    validateCalculateInvoiceTitle: "Failed to calculate invoice",
    invoiceChecklist: "Invoices List",
    printInvoiceChecklist: "Print Invoice Summary Report",

    //invoice
    columnInvoiceCode: "Invoice Code",
    columnUnitCode: "Unit Code",
    columnAddressNo: "Address No.",
    columnCustomerName: "Customer Name",
    columnNetAmount: "Net Amount",
    cancelInvoice: "Cancel Invoices",
    approveAll: "Confirm",
    calculateInvoiceDetail: "calculate Invoice Detail",
    invoiceDetail: "Invoice Detail",
    columnInvoiceDate: "Invoice Date",
    unitCode: "Unit Code",
    invoiceDetailList: "Detail List",
    columnUnitPrice: "Unit Price",
    columnRatePenalty: "Penalty Rate",
    columnPenaltyDay: "Penalty Day",
    columnAmount: "Total Amount",
    titleConfirmCancelInvoice: "Are you sure you want to cancel invoices ?",
    saveSuccess: "You have completed the transaction.",
    titleConfirmSaveInvoice: "Are you sure you want to confirm ?",
    titleSaveSuccess: "Confirm successful",

    //ตัดรับล่วงหน้าและเงินฝาก
    titleUsePrepaid: "Use Prepaid And Deposit",
    headerUsePrepaid: "Set conditions for use prepaid and deposit.",
    confirmUsePrepaid: "Are you sure you want to use prepaid and deposit?",
    usePrepaid: "Use prepaid",
    useDeposit: "Use deposit",
    confirmTitleUsePrepaid: "Confirm use prepaid and deposit",
    tableEmpty: "Data Empty",
    validateDueDate: "Please select Due date.",

    validateNoSelectedPolicy: "Please select policy",

    dialogContentUsePrepapidAndDeposit: "Do you want to deduct the prepaid and deposit now?",
    titlePreviewUsePrepaidAndDeposit: "Preview Use Prepaid And Deposit",
    tableUsePrepaidAndDepositEmpty: "Data Empty",
    progressTitle: "In Process",
    progressGenerating: "In Process...",
    progressContentLine1: "Prepaid/Deposit Remaining {0} units",
    progressContentLine2: "Use Prepaid/Deposit {0} units",
    progressContentSummary: "Checked {0}/{1} units",
    dialogContentSaveErrorNullData: "Unable to save because there are no records",
    dialogContentSaveError: "Unable to save item at {0}",
    dialogContentSaveErrorCode101: "Unable to save item at {0} because the deducted payment amount must be greater than 0 baht",
    dialogContentSaveErrorCode105: "There's no remaining amount to use from the prepaid.",
    dialogContentSaveErrorCode107: "The debt amount exceeds the requested prepaid amount",
    tableUsePrepaidAndDepositUnitCode: "Unit Code",
    tableUsePrepaidAndDepositReceiptCode: "Receipt Code/Deposit Code",
    tableUsePrepaidAndDepositInvoiceCode: "Invoice Code",
    tableUsePrepaidAndDepositProductName: "Product Name",
    tableUsePrepaidAndDepositPeriod: "Period",
    tableUsePrepaidAndDepositTotalAmount: "Total Amount",
    tableUsePrepaidAndDepositUseAmount: "Use Amount",
    tableUsePrepaidAndDepositUseType: "Use Type",
    columnUsePrepaidAndDepositUseTypePrepaid: "Prepaid",
    columnUsePrepaidAndDepositUseTypeDeposit: "Deposit",
}