export default {
  createWht:"Create Withholding Tax",
  editWht:"Edit Withholding Tax",
  taxbook:"Tax Book",
  taxType: "Tax Type",
  taxDate:"Pay Date",
  taxDueDate:"Tax Due Date",
  supplier:"Supplier",
  address1:"Address",
  address2:"Sub District/District",
  address3:"Province",
  taxId:"Tax Id",
  typOfIncome:"Typ Of Income",
  rate:"% Rate",
  paidAmount:"Paid Amount",
  taxAmount:"Tax Amount",
  remark:"Remark",
  status:"Status",
  status0:"Withholding Tax",
  status1:"Forever",
  status2:"Once",
  status3:"Other",
  totalAmount:"Total Amount",
  validateTotalAmount:"Pay Total Amount must be more than 0.",
  line01Description:"1. เงินเดือน ค่าจ้าง เบี้ยเลี้ยง โบนัส ฯลฯ ตามมาตรา 40 (1)",
  line02Description:"2. ค่าธรรมเนียม ค่านายหน้า ฯลฯ ตามมาตรา 40 (2)",
  line03Description:"3. ค่าแห่งลิขสิทธิ์ ฯลฯ  ตามมาตรา 40 (3)",
  line04Description:"4. (ก) ค่าดอกเบี้ย ฯลฯ  ตามมาตรา 40 (4) (ก)",
  line05Description:"    (ข) เงินปันผล เงินส่วนแบ่งกำไร ฯลฯ  ตามมาตรา 40 (4) (ข)",
  line06Description:"5. การจ่ายเงินที่ต้องหักภาษี ณ ที่จ่ายตามคำสั่งกรมสรรพากรที่ออกตามมาตรา 3 เตรส เช่น ค่าโฆษณา",
  validateCancel: "Can't cancel document because already have reference document.",
};
