export default {
	banksAr(state) {
		return state.banksAr;
	},
	selectedBank(state) {
		return state.selectedBank;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	transactionType(state) {
		return state.transactionType;
	},
	typeInOut(state) {
		return state.typeInOut;
	},
	receiptNotPayIn(state) {
		return state.receiptNotPayIn;
	},
	bookBankList(state) {
		return state.bookBankList;
	},
	bookBankIsLastPage(_, getters) {
		return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
	},
	bookBankNextPage(state) {
		return state.currentPage + 1;
	},
	receiptExtra(state) {
		return state.receiptExtra;
	},
	currentPageExtra(state) {
		return state.currentPageExtra;
	},
	totalPageExtra(state) {
		return state.totalPageExtra;
	},
	blob(state) {
		return state.blob;
	},
	newBanksAr(state) {
		return state.newBanksAr;
	},
	banksForSelect(state) {
		return state.banksForSelect;
	},
	banksSelected(state) {
		return state.banksSelected;
	},
	totalPageSelected(state) {
		return state.totalPageSelected;
	},
	currentPageSelected(state) {
		return state.currentPageSelected;
	},
	banksForSelectNextPage(state) {
		return state.currentPageSelected + 1;
	},
	banksForSelectIsLastPage(_, getters) {
		if (getters.currentPageSelected > 0 && getters.totalPageSelected === 0) {
			return true;
		} else {
			return (
				getters.currentPageSelected === getters.totalPageSelected && getters.totalPageSelected !== 0
			);
		}
	},
	getPrintLot(state){
		return state.printLots;
	},  	
	printLotNextPage(state) {
		return state.currentPage + 1;
	},
	printLotIsLastPage(_, getters) {
	return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
	}
};
