export default {
    setAccountingFrequentlys(state, payload){
        state.accountingFrequentlys = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },
    setSelectedAccountingFrequently(state, payload){
        state.selectedAccountingFrequently = payload
    },
    resetAccountingFrequentlys(state) {
        state.accountingFrequentlys = []
        state.totalPage = 0
        state.currentPage = 0
    }
};