export default {
	title: "Purchase Order",
    createOrder: "Create Purchase Order",
    editOrder: "Edit Purchase Order",    
    create: "Create",
    add:"Add",
    edit: "Edit",
    print: "Print",
    approve: "Approve",
    reApprove: "Revert Approve",
    import: "Import",
	closePO: "Close PO",

	code: "Code",
	inputReferDocument: "Reference Code",
	inputDate: "Date",
	inputDueDate: "Due Date",
	inputVatSendDate: "Delivery Date",
	inputCreditTerm: "Credit Term (Day)",
	inputSupplier: "Supplier",
	inputEmployeeName: "Buyer",
	inputDifferenceAmount: "Difference Amount",
	inputActualPayAmount: "Actual Pay Amount",
	inputSubAmount: "Sub Amount",
	inputAddAmount: "Add Amount",
	inputRemark1: "Report Detail",
	inputRemark2: "Installment",

	inputCurrency:"Currency",
    inputTotalAmount:"Total Amount",
    inputDiscount:"Discount",
    inputDiscountAmount:"Discount Amount",    
    inputPayment :"Payment",
    inputTotalAmountBeforeTax:"Total Amount Before Tax",
    inputTax: "Tax Rate",
    inputTaxAmount:"Vat Amount",
    inputNetAmount: "Net Amount",

	columnItemNo: "No.",
	columnBookCode: "รหัสเล่ม",
    columnDate: "Date",
    columnCode: "Code",
    columnCodeName : "CodeName",
    columnUnitName: "Unit",
    columnUnitPrice: "UnitPrice",
    columnQuantity:"Quantity",
    columnDiscount:"Discount",
    columnDiscountAmt: "DiscountAmt",
    columnNetAmount: "NetAmount",
    columnAmount: "Amount",

	titleImportPR: "List Purchase Requisition",

    askForClosePO: "Are you sure you want to close Purchase Order?",
    closePOSuccess: "Close Purchase Order Success",
    askForRevertApprove: "Are you sure you want to revert approve Document?",
    revertApproveSuccess: "Revert approve successfully",

    validateTotalAmount: "Total amount must be greater than 0.",
    validateCheckDiscount: "Discount format can be enter only numbers and %.",
    validateUpdatePrice: "You Can't update price in purchase requisition details.",
    validateDiscountAmount: "Discount amount must not be more than total amount.",
    calculatedFromSystem: "*Calculated From System : ",
};
