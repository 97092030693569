<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoSave="save"
    @gotoCreate="askForCreate"
    @gotoDelete="askForDelete"
  />
  <base-dialog
    v-model:visible="showValidateDialog"
    :titleConfirm="titleConfirm"
    :classTitle="baseDialogClassTitle"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    :isVisibleCloseButton="isVisibleCloseButton"
    @confirm="confirmDialog"
    @close="closeBaseDialog"
    @gotoDelete="deleteProductAp"
    @gotoCreate="createProductAp"
    @gotoBack="goBack"
  />
  <base-container>
    <div class="surface-card shadow-2 p-5 border-round my-5">
      <div class="flex justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.productApDetail }}</label>
      </div>
      <PvDivider></PvDivider>
      <section class="flex flex-wrap mb-1" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4">
          <label for="code">{{ lang.code }} </label
          ><label class="p-text-secondary">{{ "*" }} </label>
          <PvInputText
            id="code"
            type="text"
            class="inputfield w-full mt-1"
            v-model="code"
            @keypress="isCodeError = false"
            @blur="isCodeError = false"
            :class="{ 'p-invalid': isCodeError }"
          />
          <small v-if="isCodeError" id="unit-help" class="p-error">{{
            lang.validateCode
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4">
          <label for="type">{{ lang.type }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="types"
            optionLabel="name"
            optionValue="code"
            v-model="selectedType"
          />
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4">
          <label for="nameThai">{{ lang.nameThai }}</label
          ><label class="p-text-secondary">{{ "*" }} </label>
          <PvInputText
            id="nameThai"
            type="text"
            class="inputfield w-full mt-1"
            v-model="nameThai"
            @keypress="isNameThaiError = false"
            @blur="isNameThaiError = false"
            :class="{ 'p-invalid': isNameThaiError }"
          />
          <small v-if="isNameThaiError" id="unit-help" class="p-error">{{
            lang.validateNameThai
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4">
          <label for="nameEnglish">{{ lang.nameEnglish }}</label
          ><label class="p-text-secondary">{{ "*" }} </label>
          <PvInputText
            id="nameEnglish"
            type="text"
            class="inputfield w-full mt-1"
            v-model="nameEnglish"
            @keypress="isNameEnglishError = false"
            @blur="isNameEnglishError = false"
            :class="{ 'p-invalid': isNameEnglishError }"
          />
          <small v-if="isNameEnglishError" id="unit-help" class="p-error">{{
            lang.validateNameEnglish
          }}</small>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 lg:pr-4 md:pr-4">
          <label for="price">{{ lang.price }}</label>
          <PvInputNumber
            id="price"
            class="inputfield w-full mt-1"
            inputClass="text-right w-full"
            v-model="price"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            :min="0"
            @focus="$event.target.select()"
          />
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 lg:pr-4 md:pr-4">
          <label for="unitId">{{ lang.unit }}</label
          ><label class="p-text-secondary">{{ "*" }} </label>
          <PvSkeleton
            v-if="!isProductUnitReady"
            class="inputfield w-full mt-1"
            height="39px"
          />
          <base-product-unit-auto-complete
            id="selectedProductUnit"
            v-model="selectedProductUnit"
            class="inputfield w-full mt-1"
            @load-finish="productUnitDataReady"
            @item-select="isProductUnitError = false"
            v-show="isProductUnitReady"
            :class="{ 'p-invalid': isProductUnitError }"
          />
          <small v-if="isProductUnitError" id="unit-help" class="p-error">{{
            lang.validateProductUnit
          }}</small>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 lg:pr-4 md:pr-4">
          <label for="withholding">{{ lang.withholding }}</label>
          <!-- <PvInputText
            id="withholding"
            type="text"
            class="inputfield w-full mt-1"
            v-model="withholding"
          /> -->
          <PvInputNumber
            id="withholding"
            class="inputfield w-full mt-1"
            inputClass="text-right w-full"
            v-model="withholding"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            :min="0"
            @focus="$event.target.select()"
          />
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 lg:pr-4 md:pr-4">
          <label for="isActive">{{ lang.isActive }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="status"
            optionLabel="name"
            optionValue="code"
            v-model="selectedIsActive"
          />
        </div>
        <!-- <div
          class="lg:col-4 md:col-4 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 "
        >
          <label for="isLock">{{ lang.isLock }}</label>
          <PvInputText
            id="isLock"
            type="text"
            class="inputfield w-full mt-1"
            v-model="isLock"
          />
        </div> -->
      </section>

      <base-card
        :titleHeader="lang.editTitleAccount"
        :isVisibleExpandButton="false"
        :isHideBackground="true"
        :isVisibleCard="true"
        class="col-12 pr-4 pl-0 pb-0"
      >
      </base-card>
      <section class="flex flex-wrap mb-1">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4">
          <label for="accountCash">{{ lang.accountCash }}</label>
          <PvSkeleton
            v-if="!isAccountingReady"
            class="inputfield w-full mt-1"
            height="39px"
          />
          <base-accounting-auto-complete
            id="selectedAccountCash"
            v-model="selectedAccountCash"
            class="inputfield w-full mt-1"
            @load-finish="accountingDataReady"
            v-show="isAccountingReady"
          >
          </base-accounting-auto-complete>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4">
          <label for="accountCredit">{{ lang.accountCredit }}</label>
          <PvSkeleton
            v-if="!isAccountingReady"
            class="inputfield w-full mt-1"
            height="39px"
          />
          <base-accounting-auto-complete
            id="selectedAccountCredit"
            v-model="selectedAccountCredit"
            class="inputfield w-full mt-1"
            v-show="isAccountingReady"
            :isGetData="false"
          >
          </base-accounting-auto-complete>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4">
          <label for="accountDebit">{{ lang.accountDebit }}</label>
          <PvSkeleton
            v-if="!isAccountingReady"
            class="inputfield w-full mt-1"
            height="39px"
          />
          <base-accounting-auto-complete
            id="selectedAccountDebit"
            v-model="selectedAccountDebit"
            class="inputfield w-full mt-1"
            v-show="isAccountingReady"
            :isGetData="false"
          >
          </base-accounting-auto-complete>
        </div>
      </section>

      <!-- <div>
        <base-card
          :titleHeader="lang.settingAccountPq"
          :isVisibleExpandButton="false"
          :isHideBackground="true"
          :isVisibleCard="true"
          class="col-12 pr-4 pl-0 pb-0"
        />
        <section class="flex flex-wrap mb-1">
          <label for="accountPqDebit" class="lg:col-6 md:col-6 col-12 font-lg pl-0">{{ lang.accountPqDebit }}</label>
          <label for="accountPqCredit" class="lg:col-6 md:col-6 col-12 font-lg pl-0">{{ lang.accountPqCredit }}</label>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4">
            <label for="acountPqDebit">{{ lang.labelAcountPqDebit }}</label>
            <PvSkeleton
              v-if="!isAccountingReady"
              class="inputfield w-full mt-1"
              height="39px"
            />
            <base-accounting-auto-complete
              id="selectedAcountDebitPaymentRequest"
              v-model="selectedAcountDebitPaymentRequest"
              class="inputfield w-full mt-1"
              v-show="isAccountingReady"
              :isGetData="false"
            />
          </div>
          
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4">
            <label for="acountPqCreditPrevious">{{ lang.labelAcountPqCreditPrevious }}</label>
            <PvSkeleton
              v-if="!isAccountingReady"
              class="inputfield w-full mt-1"
              height="39px"
            />
            <base-accounting-auto-complete
              id="selectedAcountCreditPaymentRequestPrevious"
              v-model="selectedAcountCreditPaymentRequestPrevious"
              class="inputfield w-full mt-1"
              v-show="isAccountingReady"
              :isGetData="false"
            />
          </div>
          <div class="lg:col-6 md:col-6 col-12 p-0"></div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4">
            <label for="acountPqCreditCurrent">{{ lang.labelAcountPqCreditCurrent }}</label>
            <PvSkeleton
              v-if="!isAccountingReady"
              class="inputfield w-full mt-1"
              height="39px"
            />
            <base-accounting-auto-complete
              id="selectedAcountCreditPaymentRequestCurrent"
              v-model="selectedAcountCreditPaymentRequestCurrent"
              class="inputfield w-full mt-1"
              v-show="isAccountingReady"
              :isGetData="false"
            />
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 p-0"></div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4">
            <label for="acountPqCreditNext">{{ lang.labelAcountPqCreditNext }}</label>
            <PvSkeleton
              v-if="!isAccountingReady"
              class="inputfield w-full mt-1"
              height="39px"
            />
            <base-accounting-auto-complete
              id="selectedAcountCreditPaymentRequestNext"
              v-model="selectedAcountCreditPaymentRequestNext"
              class="inputfield w-full mt-1"
              v-show="isAccountingReady"
              :isGetData="false"
            />
          </div>
        </section>
      </div> -->
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import useGetGlAccounting from "../../../hooks/getAccounting.js";
import usePageDirty from "../../../hooks/pageDirty";
import BaseProductUnitAutoComplete from "../../../components/master/product/BaseProductUnitAutoComplete.vue";
export default {
  props: ["id", "projectCode"],
  components: {
    BaseProductUnitAutoComplete,
  },
  setup() {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();
    const { accountings } = useGetGlAccounting();
    const { compareObject, isCompareObject } = usePageDirty();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.productAp.listing,
          ...lang.th.master.productAp.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.productAp.listing,
          ...lang.en.master.productAp.edit,
        };
      }
    });
    return {
      lang: mergeLang,
      textFormClass,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      accountings,
      compareObject,
      isCompareObject,
    };
  },
  data() {
    return {
      code: "",
      isCodeError: false,
      nameThai: "",
      isNameThaiError: false,
      nameEnglish: "",
      isNameEnglishError: false,
      price: 0,
      withholding: 1.5,
      isProductUnitReady: false,
      selectedProductUnit: null,
      isProductUnitError: false,
      isLock: "N",
      filteredAccount: [],
      isAccountingReady: false,
      selectedAccountCash: null,
      selectedAccountCredit: null,
      selectedAccountDebit: null,

      types: [
        { name: "P : สินค้า", code: "P" },
        { name: "S : บริการ", code: "S" },
      ],
      selectedType: "S",

      status: [
        { name: "Y : ใช้งาน", code: "Y" },
        { name: "N : ไม่ใช้งาน", code: "N" },
      ],
      selectedIsActive: "Y",
      dataButtonDialog: [
      {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        }
      ],
      isVisibleCloseButton: false,
      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: this.lang.notificationDialogHeader,
      validateWording: "",
      objToCompare: null,

      selectedAcountDebitPaymentRequest: null,
      selectedAcountCreditPaymentRequestPrevious: null,
      selectedAcountCreditPaymentRequestCurrent: null,
      selectedAcountCreditPaymentRequestNext: null,
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    await this.roleByMenuDataReady();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.setLocalSearch();
      this.$router.push({name: "product-ap"})
    },
    async initiateData() {
      this.code = "";
      this.nameThai = "";
      this.nameEnglish = "";
      this.price = 0;
      this.withholding = 1.5;
      this.selectedType = "S"; 
      this.selectedIsActive = "Y";
      this.isLock = "N";
      this.selectedAccountCash = null;
      this.selectedAccountCredit = null;
      this.selectedAccountDebit = null;
      this.showValidateDialog = false;
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.validateWording = "";
      this.dataButtonDialog = [
      {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        }
      ];
      this.isCodeError = false;
      this.isNameThaiError = false;
      this.isNameEnglishError = false;
      this.isProductUnitError = false;
      this.selectedAcountDebitPaymentRequest = null;
      this.selectedAcountCreditPaymentRequestPrevious = null;
      this.selectedAcountCreditPaymentRequestCurrent = null;
      this.selectedAcountCreditPaymentRequestNext = null;

      await this.$store.dispatch("productAp/resetProductAp");
    },
    async setData() {
      this.code = this.productAp.code;
      this.nameThai = this.productAp.nameThai;
      this.nameEnglish = this.productAp.nameEnglish;
      this.price = this.productAp.price;
      this.withholding = this.productAp.withholding
        ? parseFloat(this.productAp.withholding)
        : 0;
      this.unitId = this.productAp.unitId;
      this.selectedType = this.productAp.type;
      this.selectedIsActive = this.productAp.isActive;
      this.isLock = this.productAp.isLock;

      this.selectedProductUnit = this.productUnits.find(
        (e) => e.id === this.productAp.unitId
      );
      this.selectedAccountCash = this.accountings.find(
        (e) => e.id === this.productAp.accountCashId
      );
      this.selectedAccountCredit = this.accountings.find(
        (e) => e.id === this.productAp.accountCreditId
      );
      this.selectedAccountDebit = this.accountings.find(
        (e) => e.id === this.productAp.accountDebitId
      );
      this.selectedAcountDebitPaymentRequest = this.accountings.find(
        (e) => e.id === this.productAp.accountDebitPaymentRequestId
      );
      this.selectedAcountCreditPaymentRequestPrevious = this.accountings.find(
        (e) => e.id === this.productAp.accountCreditPaymentRequestPreviousId
      );
      this.selectedAcountCreditPaymentRequestCurrent = this.accountings.find(
        (e) => e.id === this.productAp.accountCreditPaymentRequestCurrentId
      );
      this.selectedAcountCreditPaymentRequestNext = this.accountings.find(
        (e) => e.id === this.productAp.accountCreditPaymentRequestNextId
      );

      this.setLoading(false);
    },
    async getProductAp() {
      try {
        await this.$store.dispatch("productAp/getProductApById", this.id);
      } catch (error) {
        this.validateDialogWording = error;
        this.showValidateDialog = true;
      }
    },
    setLocalSearch() {
      let storageName = `erp_search_ProductAPList_${this.selectedProject.code}_${this.userCode}`;
      const localSearch = localStorage.getItem(storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      localStorage.setItem(storageName, JSON.stringify(search));
    },
    async roleByMenuDataReady() {
      if (this.isSharedRoleByMenuReady) {
        if (!this.isCreateDocument) {
          await this.getProductAp();
        }
      }
    },
    productUnitDataReady() {
      this.isProductUnitReady = true;
      if (!this.isCreateDocument) {
        this.checkShouldSetData();
      } else {
        this.selectedProductUnit = this.productUnits[0];
      }
    },
    accountingDataReady() {
      this.isAccountingReady = true;
      this.checkShouldSetData();
    },
    async checkShouldSetData() {
      if (
        !this.isCreateDocument &&
        this.productAp &&
        this.isProductUnitReady &&
        this.isAccountingReady
      ) {
        await this.setData();
      }
      else if ( this.isProductUnitReady &&
        this.isAccountingReady &&
        this.isCreateDocument
      ) {
        this.setLoading(false);
      }
      await this.setCompareData();
    },
    async setCompareData() {
      let objToCompare = {
        projectId: this.selectedProject.id,
        code: this.code,
        nameThai: this.nameThai,
        nameEnglish: this.nameEnglish,
        price: this.price ? this.price : 0,
        withholding: this.withholding ? this.withholding.toString() : "0.00",
        unitId: this.selectedProductUnit ? this.selectedProductUnit.id : null,
        type: this.selectedType,
        isActive: this.selectedIsActive,
        isLock: this.isLock,
        accountCashId: this.selectedAccountCash
          ? this.selectedAccountCash.id
          : null,
        accountCreditId: this.selectedAccountCredit
          ? this.selectedAccountCredit.id
          : null,
        accountDebitId: this.selectedAccountDebit
          ? this.selectedAccountDebit.id
          : null,
        typeAddEdit: "Add",
        accountDebitPaymentRequestId: this.selectedAcountDebitPaymentRequest
          ? this.selectedAcountDebitPaymentRequest.id
          : "",
        accountCreditPaymentRequestPreviousId: this.selectedAcountCreditPaymentRequestPrevious
          ? this.selectedAcountCreditPaymentRequestPrevious.id
          : "",
        accountCreditPaymentRequestCurrentId: this.selectedAcountCreditPaymentRequestCurrent
          ? this.selectedAcountCreditPaymentRequestCurrent.id
          : "",
          accountCreditPaymentRequestNextId: this.selectedAcountCreditPaymentRequestNext
          ? this.selectedAcountCreditPaymentRequestNext.id
          : "",
      };

      if (!this.isCreateDocument) {
        objToCompare = {
          ...objToCompare,
          id: this.id,
          typeAddEdit: "Update",
        };
      }

      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
    },

    async askForCreate() {
      if (!this.isRoleCreate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return;
      }

      let result = this.isCompareObject(this.objToCompare, this.payload);
      if (result) {
        this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "gotoCreate",
            caption: this.lang.confirm,
            class: "w-full",
            action: "gotoCreate",
          },
        ];

        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.createNewProductAp;
        this.validateWording = [
          this.lang.askForCreateNewProductAp,
          this.lang.askForCreateNewProductApConfirm,
        ];
        this.showValidateDialog = true;
      } else {
        await this.createProductAp();
      }
    },
    async createProductAp() {
      this.setLoading(true);
      this.initiateData();
      await this.$router.push({name: "product-ap-edit", params: { id: "create" }})
      this.selectedProductUnit = this.productUnits[0];
      await this.setCompareData();
      this.showValidateDialog = false;
      this.setLoading(false);
    },
    validateData() {
      if (this.isCreateDocument && !this.isRoleCreate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return true;
      } else if (!this.isCreateDocument && !this.isRoleUpdate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleUpdate;
        this.showValidateDialog = true;
        return true;
      }

      let isError = false;
      if (this.code == "") {
        (this.isCodeError = true), (isError = true);
      }

      if (this.nameThai == "") {
        (this.isNameThaiError = true), (isError = true);
      }

      if (this.nameEnglish == "") {
        (this.isNameEnglishError = true), (isError = true);
      }

      if (!this.selectedProductUnit) {
        (this.isProductUnitError = true), (isError = true);
      }

      if (isError) {
        this.baseDialogClassTitle = "p-text-error";
        this.titleConfirm = this.lang.validateSaveTitle;
        this.validateWording = this.wordingValidate();
        this.showValidateDialog = true;
      }

      return isError;
    },
    wordingValidate(){
      if (this.code == "") {
        return this.lang.validateFieldNoValue + this.lang.code;
      }

      if (this.nameThai == "") {
        return this.lang.validateFieldNoValue + this.lang.nameThai;
      }

      if (this.nameEnglish == "") {
        return this.lang.validateFieldNoValue + this.lang.nameEnglish;
      }

      if (!this.selectedProductUnit) {
        return this.lang.validateDropDownNoSelect + this.lang.unit;
      }
    },
    async save() {
      if (this.validateData()) {
        return;
      }
      this.setLoading(true);
      try {
        // console.log("this.payload=", this.payload);
        await this.$store.dispatch("productAp/createOrUpdateProductAp", this.payload);

        this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
        ];
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.saveSuccess;
        this.validateWording = this.saveEditSuccess;
        this.showValidateDialog = true;

        if (this.isCreateDocument) {
          await this.$router.replace({name: "product-ap-edit", params: { id: this.productAp.id }})
          await this.setCompareData();
        }

      } catch (error) {
        this.openAlertDialogError(error);
      }
      this.setLoading(false);
    },

    askForDelete() {
      if (!this.isRoleDelete) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleDelete;
        this.showValidateDialog = true;
        return;
      }
      this.dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
        {
          id: "gotoDelete",
          caption: this.lang.buttonDialogConfirm,
          class: "w-full",
          action: "gotoDelete",
        },
      ];
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.deleteProductAp;
      this.validateWording = `${this.lang.askForDeleteProductAp.replace(
        ":code",
        this.code
      )}`;
      this.showValidateDialog = true;
    },
    async deleteProductAp() {
      this.showValidateDialog = false;
      try {
        let payload = {
          projectId: this.selectedProject.id,
          id: this.id,
        };

        await this.$store.dispatch("productAp/deleteProductAp", payload);

        this.dataButtonDialog = [
          {
            id: "gotoBack",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "gotoBack",
          },
        ];
        this.isVisibleCloseButton = true;
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.deleteSuccess;
        this.validateWording = `${this.lang.deleteProductApSuccess.replace(
          ":code",
          this.code
        )}`;
        this.showValidateDialog = true;
      } catch (error) {
        this.openAlertDialogError(error);
      }
    },

    confirmDialog() {
      this.showValidateDialog = false;
      this.baseDialogClassTitle = "";
    },
    closeBaseDialog() {
      this.showValidateDialog = false;
    },
    openAlertDialogError(error) {
      this.dataButtonDialog = [
        {
          id: "confirm",
          caption: this.lang.confirm,
          class: "w-full",
          action: "confirm",
        },
      ];

      this.isVisibleCloseButton = false;
      this.baseDialogClassTitle = "p-error";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.validateWording = error.message;
      this.showValidateDialog = true;
    },
  },
  computed: {
    payload() {
      let payload = {
        projectId: this.selectedProject.id,
        code: this.code,
        nameThai: this.nameThai,
        nameEnglish: this.nameEnglish,
        price: this.price ? this.price : 0,
        withholding: this.withholding ? this.withholding.toString() : "0.00",
        unitId: this.selectedProductUnit ? this.selectedProductUnit.id : null,
        type: this.selectedType,
        isActive: this.selectedIsActive,
        isLock: this.isLock,
        accountCashId: this.selectedAccountCash
          ? this.selectedAccountCash.id
          : null,
        accountCreditId: this.selectedAccountCredit
          ? this.selectedAccountCredit.id
          : null,
        accountDebitId: this.selectedAccountDebit
          ? this.selectedAccountDebit.id
          : null,
        typeAddEdit: "Add",
        accountDebitPaymentRequestId: this.selectedAcountDebitPaymentRequest
          ? this.selectedAcountDebitPaymentRequest.id
          : "",
        accountCreditPaymentRequestPreviousId: this.selectedAcountCreditPaymentRequestPrevious
          ? this.selectedAcountCreditPaymentRequestPrevious.id
          : "",
        accountCreditPaymentRequestCurrentId: this.selectedAcountCreditPaymentRequestCurrent
          ? this.selectedAcountCreditPaymentRequestCurrent.id
          : "",
          accountCreditPaymentRequestNextId: this.selectedAcountCreditPaymentRequestNext
          ? this.selectedAcountCreditPaymentRequestNext.id
          : "",
      };
      if (!this.isCreateDocument) {
        payload = {
          ...payload,
          id: this.id,
          typeAddEdit: "Update",
        };
      }
      return payload;
    },

    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    productAp() {
      return this.$store.getters["productAp/productAp"];
    },
    productUnits() {
      return this.$store.getters["shared/productUnits"];
    },
    isCreateDocument() {
      return this.id === "create";
    },
    saveEditSuccess() {
      let result = this.lang.editProductApSuccess;
      if (this.isCreateDocument) {
        result = this.lang.createProductApSuccess;
      }
      return result;
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmPRO");
    },
    isRoleCreate() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRoleUpdate() {
      let result = false;
      if (this.roleByMenu && this.roleByMenu.update == "Y") {
        result = true;
      }
      return result;
    },
    isRoleDelete() {
      let result = false;
      if (this.roleByMenu && this.roleByMenu.delete == "Y") {
        result = true;
      }
      return result;
    }, 
    title() {
      let result = this.lang.createTitle;
      if (!this.isCreateDocument) {
        result = this.lang.editTitle;
      }
      return result;
    },
    dataButtons() {
      let result = [
        {
          id: "gotoDelete",
          action: "gotoDelete",
          isDisable: this.isCreateDocument || !this.isAccountingReady,
          type: "delete"
        },
        {
          id: "gotoCreate",
          action: "gotoCreate",
          type: "create"
        },
        {
          id: "gotoSave",
          action: "gotoSave",
          isDisable: !this.isAccountingReady,
          type: "save"
        },
      ];

      return result;
    },
  },
  watch: {
    productAp() {
      this.checkShouldSetData();
    },
    isSharedRoleByMenuReady() {
      this.roleByMenuDataReady();
    },
    payload(curValue) {
      if (this.objToCompare) {
        this.compareObject(this.objToCompare, curValue);
      }
    },
  },
};
</script>

<style></style>
