<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.68469 9.6847C9.92172 8.66314 10.8522 7.95484 11.8999 7.99832C13.0726 7.93337 14.0779 8.82697 14.1509 9.99916C14.1509 11.5037 12 12 12 13.0004"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M12.1252 15.7517C12.1251 15.8207 12.0691 15.8766 12.0001 15.8766C11.9311 15.8766 11.8751 15.8206 11.8751 15.7516C11.8751 15.6826 11.931 15.6266 12 15.6265C12.0332 15.6265 12.0651 15.6396 12.0886 15.6631C12.1121 15.6866 12.1252 15.7185 12.1252 15.7517"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.6284 17.1604C1.9453 13.3307 2.63481 8.08534 6.21651 5.07913C9.79821 2.07293 15.0836 2.30335 18.3901 5.60985C21.6966 8.91634 21.927 14.2018 18.9208 17.7835C15.9146 21.3652 10.6693 22.0547 6.83959 19.3716L3.99667 20.0033L4.6284 17.1604Z"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: 24,
		},
		height: {
			type: [Number, String],
			default: 24,
		},
		iconColor: {
			type: String,
			default: "secondary",
		},
	},
	computed: {
		strokeColor() {
			return "p-stroke-" + this.iconColor;
		},
	},
};
</script>

<style lang="scss" scoped>
svg {
	display: inline-block;
	vertical-align: baseline;
	margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
