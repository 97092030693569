export default {
  async getPurchaseInvoices(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Voucher/PurchaseInvoice";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    // console.log("getAccountPayables_payload=",payload);
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get PurchaseInvoice");
      throw error;
    }
      
    // console.log("add_getAccountPayables=",responseData);
    context.commit("setPurchaseInvoices", responseData);    
    
  },
  async getPurchaseInvoiceSelected(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Voucher/PurchaseInvoice";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    // console.log("getAccountPayables_payload=",payload);
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get PurchaseInvoice");
      throw error;
    }
   
    context.commit("setPurchaseInvoiceSelected", responseData.data);    
    
  },
  async resetPurchaseInvoice(context, payload) {
    
    await context.commit("resetPurchaseInvoice", payload);
  },

  async setPurchaseInvoiceSelected(context, payload) {
    
    await context.commit("setPurchaseInvoiceSelected", payload);
  },
  
  async getPurchaseInvoiceToGl(context, payload) {        
    let url = process.env.VUE_APP_BACKEND_URL + "/V2/Voucher/PurchaseInvoiceToGl";
  
    const token = context.rootGetters.token;      
    // console.log("getReceiptToGl_payload=",payload);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();       
    // console.log("00getReceiptToGl>>>",responseData);
    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || "Failed to get project");
      throw error;
    }

    // console.log("getReceiptToGl>>>",responseData);            
    await context.commit('setPurchaseInvoiceToGl', responseData);       
        
  },

  async deletePurchaseInvoiceWithVoucher(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Voucher/DeletePurchaseInvoiceWithVoucher";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("PurchaseReceipt>>>", responseData);
			const error = new Error(responseData.message || "Failed to Cancel Purchase Invoice");
			throw error;
		}

    await context.commit('setCancelPurchaseInvoice', responseData);   
  },

};
