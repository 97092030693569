<template>
	<PvAutoComplete
		:dropdown="true"
		:suggestions="filteredAccountings"
		field="codeAndName"
		@complete="searchAccounting"
		@dropdown-click="filteredAccountings"
		forceSelection
	/>
</template>

<script>
import useGetAccounting from "../../hooks/getAccounting.js";
export default {
	setup() {
		const { filteredAccountings, searchTextAccounting, isSharedAccountingReady, searchAccounting, getAllAccountings } =
			useGetAccounting();

		return {
			filteredAccountings,
			searchTextAccounting,
			isSharedAccountingReady,
			searchAccounting,
			getAllAccountings,
		};
	},
	emits: ["load-finish"],
	props: {		
		accountType : {
			type: String,
		},
		isGetData : {
			type: Boolean,
      		default: true,
		}
	},
	mounted() {
		if(this.isGetData){
			this.getAllAccountings();
		}
	},
	watch: {
		isSharedAccountingReady(curValue) {			
			if (curValue) {
				this.$emit("load-finish");
			}
		},
	},
};
</script>
