import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default function useGetBookBankAr() {
	const store = useStore();
	let searchBookBankArText = ref(null);

	const bookBankAr = computed(() => {
		return store.getters["shared/bookBankArs"];
	});

	const filteredBookBankAr = computed(() => {
		if (!searchBookBankArText.value) {
			//ช่องว่าง
			if (bookBankAr.value) {
				const result = [...bookBankAr.value];
				return result;
			} else {
				return null;
			}
		} else {
			return bookBankAr.value.filter((bookBankAr) => {
				return bookBankAr.code.toLowerCase().includes(searchBookBankArText.value.toLowerCase());
			});
		}
	});

	const searchBookBankAr = function (event) {
		if (event.query && event.query.trim().length) {
			searchBookBankArText.value = event.query;
		}else{
			searchBookBankArText.value = "";
			searchBookBankArText.value = null;
		}
	};

	let isSharedBookBankArReady = ref(false);

	const getAllBookBankArs = async function () {
		await store.dispatch("shared/getAllBookBankArs");
		isSharedBookBankArReady.value = true;
	};

	onMounted(async () => {
		await getAllBookBankArs();		
	});

	return {
		searchBookBankAr,
		filteredBookBankAr,
		searchBookBankArText,
		isSharedBookBankArReady,
		bookBankAr,
		getAllBookBankArs,
	};
}
