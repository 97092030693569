<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.99798 14.0017H13.0021C13.5547 14.0017 14.0026 13.5538 14.0026 13.0013V11.0004C14.0026 10.4479 13.5547 10 13.0021 10H2.99798C2.44546 10 1.99756 10.4479 1.99756 11.0004V13.0013C1.99756 13.5538 2.44546 14.0017 2.99798 14.0017Z"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.6893 4.03516L11.4138 5.31069L10.647 4.54612"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.33311 2.66406H2.99798C2.44546 2.66406 1.99756 3.11196 1.99756 3.66448V5.66531C1.99756 6.21783 2.44546 6.66573 2.99798 6.66573H7.33311"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <ellipse
      cx="11.6682"
      cy="4.66531"
      rx="3.00125"
      ry="3.00125"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
