export default {
  address(state) {
    return state.address.addressList;
  },
  addressCurrentPage(state) {
    return state.address.currentPage;
  },
  addressNextPage(state) {
    return state.address.currentPage + 1;
  },
  addressTotalPage(state) {
    return state.address.totalPage;
  },
  addressIsLastPage(_, getters) {
    return (
      getters.addressCurrentPage === getters.addressTotalPage &&
      getters.addressTotalPage !== 0
    );
  },
};
