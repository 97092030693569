<template>
	<PvDialog
		:breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
		:style="{ width: '70vw' }"
		:modal="true"
		:closable="false"
		:header="parameterTitleText"
	>
		<base-purchase-receipt-model
			v-model:visible="showPurchaseReceiptModal"
			@close="checkModuleCode(false)"
			:openFrom="openImportPurchaseReceipt"
			:voucherId="voucherId"
		>
		</base-purchase-receipt-model>

		<base-edit-purchase-receipt-view
			v-model:visible="showCreatePurchaseReceipt"
			@close="checkModuleCode(true)"
			:openFrom="openCreatePurchaseReceipt"
			:id="idPurchaseReceipt"
			:apBook="purchaseBookSelected"
		></base-edit-purchase-receipt-view>

		<base-book-purchase-modal
			v-model:visible="showBookPurchaseModal"
			:search="searchTextBookPurchase"
			:isSharedLoading="isSharedBookPurchaseReady"
			:documentTypes="documentTypes"
			@close="showBookPurchaseModal = false"
			@bookPurchase-selected="bookPurchaseModalSelected"
		></base-book-purchase-modal>

		<div class="pt-2 pb-1">
			<PvButton
				icon="pi pi-file"
				:label="lang.create"
				class="mr-2 mb-2"
				@click="createPurchaseReceiptToGl()"
			/>
			<PvButton
				icon="pi pi-plus"
				:label="lang.importDocument"
				class="mr-2 mb-2"
				@click="importPurchaseReceipt()"
			/>
			<PvButton
				icon="pi pi-minus"
				:label="lang.removeDocument"
				class="p-button-outlined p-button-danger mr-2 mb-2"
				@click="askForRemove()"
			/>
		</div>

		<PvDataTable
			showGridlines
			:value="purchaseReceiptImportItem"
			v-model:selection="selectedPurchaseReceipt"
			dataKey="receiptId"
			:loading="isLoading"
			responsiveLayout="scroll"
			:scrollHeight="halfViewportHeight + 'px'"
			:stripedRows="true"
			class="p-datatable-sm"
			:class="textTableClass"
		>
			<template #empty>
				<div class="w-full p-3" Style="text-align: center;">
				<label>{{ lang.tableEmpty }}</label>
				</div>
			</template>
			<PvColumn
				field="itemNumber"
				:header="lang.itemNo"
				style="min-width: 4rem"
				headerClass="h-center"
				bodyClass="text-center"
			></PvColumn>			
			<PvColumn
				field="documentBookCode"
				:header="lang.documentBookCode"
				style="min-width: 3rem"
				headerClass="h-center"
				bodyClass="text-center"
			></PvColumn>
			<!-- <PvColumn
				field="receiptPostStep"
				:header="lang.columnPostStep"
				style="min-width: 2rem"
				headerClass="h-center"
				bodyClass="text-center"
				sortable
			></PvColumn> -->

			<PvColumn
				field="receiptCode"
				:header="lang.columnCode"
				style="min-width: 4rem"
				headerClass="h-center"
				sortable
			></PvColumn>

			<PvColumn
				field="receiptRefCode"
				:header="lang.columnReferenceCode"
				style="min-width: 4rem"
				headerClass="h-center"
				sortable
			></PvColumn>

			<PvColumn
				field="receiptDate"
				:header="lang.columnDate"
				style="min-width: 3rem"
				headerClass="h-center"
				bodyClass="text-center"
				sortable
			>
				<template #body="slotProps">
					{{ formatDate(slotProps.data.receiptDate) }}
				</template>
			</PvColumn>

			<PvColumn
				field="receiptSupplierCode"
				:header="lang.columnSupplierCode"
				style="min-width: 5rem"
				headerClass="h-center"
				sortable
			></PvColumn>

			<PvColumn
				field="receiptSupplierName"
				:header="lang.columnSupplierName"
				style="min-width: 6rem"
				headerClass="h-center"
				sortable
			></PvColumn>

			<PvColumn
				field="receiptNetAmount"
				:header="lang.columnReceiptNetAmount"
				style="min-width: 4rem"
				headerClass="h-center"
				bodyClass="text-right"
				sortable
			>
				<template #body="slotProps">
					{{ formatCurrency(slotProps.data.receiptNetAmount) }}
				</template>
			</PvColumn>

			<PvColumn
				field="receiptActualPayAmount"
				:header="lang.columnreceiptActualPayAmount"
				style="min-width: 4rem"
				headerClass="h-center"
				bodyClass="text-right"
				sortable
			>
				<template #body="slotProps">
					{{ formatCurrency(slotProps.data.receiptActualPayAmount) }}
				</template>
			</PvColumn>
			<PvColumn
				style="min-width: 4rem"
				headerClass="h-center"
				bodyStyle="text-align: right; overflow: visible"
			>
				<template #body="slotProps">
					<PvButton
						type="button"
						class="p-button-secondary p-button-text py-0"
						icon="pi pi-pencil"
						@click="gotoEdit(slotProps.data.receiptId)"
						v-if="(slotProps.data.moduleCode.trim() === 'PT') || (slotProps.data.moduleCode.trim() === 'PZ')"
					></PvButton>

					<PvButton
						type="button"
						class="p-button-secondary p-button-text py-0"
						icon="pi pi-trash"
						v-if="slotProps.data.receiptVoucherId === ''"
						@click="deleteRowSelected(slotProps.data.receiptId)"
					></PvButton>

					<PvButton
						type="button"
						class="p-button-danger p-button-text py-0"
						icon="pi pi-times"
						v-else
						@click="deletePurchaseReceiptWithVoucher(slotProps.data)"
					></PvButton>
				</template>
			</PvColumn>
		</PvDataTable>

		<template #footer>
			<PvButton
				:label="lang.close"
				icon="pi pi-times"
				class="p-button-text"
				@click="closeDialog()"
			/>
			<PvButton v-if="isAutoGL" :label="lang.autoGl" icon="pi pi-check" @click="confirmPurchaseReceiptToGl()" />
			<PvButton v-else :label="lang.ok" icon="pi pi-check" @click="confirmPurchaseReceiptlReference()" />
		</template>
	</PvDialog>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import useGetBookPurchase from "../../hooks/getBookPurchase.js";
import basePurchaseReceiptModel from "../GL/BasePurchaseReceiptModel.vue";
import baseEditPurchaseReceiptView from "../purchaseReceipt/BaseEditPurchaseReceiptView.vue";
export default {
	components: {
		basePurchaseReceiptModel,
		baseEditPurchaseReceiptView,
	},
	props: ["openFrom", "voucherId"],
	emits: ["close", "confirm-purchase-receipt-to-gl"],
	setup() {
		const { lang } = useLang();
		const { textHeaderClass, textFormClass, textTableClass, textAlertClass } = useTextStyle();
		const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
		const store = useStore();
		const { isSharedBookPurchaseReady, searchTextBookPurchase, setBookPurchaseCode } =
			useGetBookPurchase();

		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return {
					...lang.th.base,
					...lang.th.ap.purchaseReceipt.listing,
					...lang.th.gl.accounting.edit,
					...lang.th.gl.accounting.listing,
				};
			} else {
				return {
					...lang.en.base,
					...lang.en.ap.purchaseReceipt.listing,
					...lang.th.gl.accounting.edit,
					...lang.th.gl.accounting.listing,
				};
			}
		});

		return {
			lang: mergeLang,
			textHeaderClass,
			textFormClass,
			textTableClass,
			textAlertClass,
			formatCurrency,
			formatDate,
			formatDateToISOString,
			isSharedBookPurchaseReady,
			searchTextBookPurchase,
			setBookPurchaseCode,
		};
	},
	data() {
		return {
			selectedPage: 1,
			rowsPerPage: 100,
			isLoading: true,
			idPurchaseReceipt: null,

			purchaseReceiptSelectedItem: null,
			selectedPurchaseReceipt: null,

			purchaseBookSelected: null,

			showBookPurchaseModal: false,
			openFromEditPurchaseReceipt: "",
			showEditPurchaseReceipt: false,

			showPurchaseReceiptModal: false,
			openImportPurchaseReceipt: "",

			showCreatePurchaseReceipt: false,
			openCreatePurchaseReceipt: "",

			isAutoGL: false,
			documentTypes: ["PT","PZ"],
		};
	},

	async created() {
		this.isLoading = false;
	},

	methods: {
		setLoading(bool) {
			this.$store.dispatch("setLoading", { value: bool });
		},
		closeDialog() {
			this.$emit("close");
		},
		async getPurchaseReceiptList() {
			this.isLoading = true;
			try {
				let where = {
					projectId: this.selectedProject.id,
					receiptVoucherId: this.voucherId,
				};

				const payload = {
					page: this.selectedPage,
					limit: this.rowsPerPage,
					where,
				};

				// console.log("getPurchaseReceiptList", payload);
				await this.$store.dispatch("glPurchaseReceipt/getSelectedVoucherReferenceDocument", payload);

				this.isLoading = false;
			} catch (error) {
				this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				this.isLoading = false;
			}
		},
		async bookPurchaseModalSelected(data) {
			//book ที่เลือก
			//ปิดDialogเลือกเล่มใบวางบิล ,เปิดDialogสร้างใบวางบิล
			this.purchaseBookSelected = data;

			this.idPurchaseReceipt = "create";
			const today = new Date();
			this.openCreatePurchaseReceipt = this.voucherId + today.getSeconds();
			this.showCreatePurchaseReceipt = true;
		},
		createPurchaseReceiptToGl() {
			this.isSharedBookPurchaseReady = false;
			this.showBookPurchaseModal = true;
			const today = new Date();
			this.openFromEditPurchaseReceipt = this.voucherId + today.getSeconds();
		},
		async importPurchaseReceipt() {
			//import ข้อมูล PT
			await this.$store.dispatch("apPurchaseReceipt/resetPurchaseReceiptImport");
			await this.$store.dispatch("glPurchaseReceipt/resetSelectedVoucherReferenceDocument");
			this.showPurchaseReceiptModal = true;
			const today = new Date();
			this.openImportPurchaseReceipt = this.voucherId + today.getSeconds();
		},
		async askForRemove() {
			const result = await this.$swal({
				icon: "info",
				html: `<p class="${this.textAlertClass}">${this.lang.askForRemoveDocument}</p>`,
				confirmButtonText: this.lang.confirm,
				showCancelButton: true,
				cancelButtonText: this.lang.close,
				allowOutsideClick: false,
				confirmButtonColor: "#EF4444",
				cancelButtonColor: "#64748B",
			});

			if (result.isConfirmed) {
				this.setLoading(true);
				for (const data of this.purchaseReceiptImportItem) {
					try {
						if (!data.receiptId.includes("dummy")) {
							let payload = {
								voucherId: data.receiptVoucherId.trim(),
								receiptId: data.receiptId,
								projectId: data.projectId,
							};
							await this.$store.dispatch("glPurchaseReceipt/deleteVoucherPurchaseReferenceDocument", payload);
						}
					} catch (error) {
						this.$swal({
							icon: "error",
							html: `<p class="${this.textAlertClass}">${error}</p>`,
							confirmButtonText: this.lang.close,
							allowOutsideClick: false,
						});
						this.setLoading(false);
						break;
					}
				}
				await this.$store.dispatch("apPurchaseReceipt/resetPurchaseReceiptImport");
				await this.$store.dispatch("glPurchaseReceipt/resetSelectedVoucherReferenceDocument");
				this.setLoading(false);
			}
		},
		deleteRowSelected(id) {
			let i = this.purchaseReceiptImport.map((item) => item.receiptId).indexOf(id);
			this.purchaseReceiptImport.splice(i, 1);
		},
		async gotoEdit(id) {
			this.idPurchaseReceipt = id;
			const today = new Date();
			this.openCreatePurchaseReceipt = this.voucherId + today.getSeconds();
			this.showCreatePurchaseReceipt = true;
		},
		async confirmPurchaseReceiptToGl() {
			if (this.purchaseReceiptImportItem.length <= 0) {
				this.$swal({
					icon: "warning",
					title: this.lang.validateDetailSelect,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				return;
			}
			this.setLoading(true);
			let isPaymentRequest = this.purchaseReceiptImportItem.some(data => data.documentBookCode.trim() === "PQ");
			let details = [];
			let request = {
				projectId: this.selectedProject.id,
				isPaymentRequest: isPaymentRequest,
			};
			if (this.purchaseReceiptImportItem) {
				this.purchaseReceiptImportItem.forEach((data) => {
					let detail = {
						headerId: data.receiptId,
						code: data.receiptCode,
						bookId: data.documentBookId,
					};
					details.push(detail);
				});
			}

			let purchaseReceipt = { data: details };
			request = { ...request, ...purchaseReceipt };
			try {
				// console.log("confirmPurchaseReceiptToGl", request);
				await this.$store.dispatch("apPurchaseReceipt/getPurchaseReceiptToGl", request);
				await this.purchaseReceiptToGlMessageError();

				if (this.messageError != "") {
					this.setLoading(false);
					this.$swal({
						icon: "error",
						html: `<p class="${this.textAlertClass}">${this.messageError}</p>`,
						confirmButtonText: this.lang.close,
						allowOutsideClick: false,
					});
				} else {
					this.addPurchaseReceiptImportToGl();
					this.setLoading(false);
					// console.log("this.purchaseReceiptToGl=",this.purchaseReceiptToGl);
				}
			} catch (error) {
				this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				this.setLoading(false);
			}

			this.setLoading(false);
		},
		addPurchaseReceiptImportToGl() {
			// console.log("addPurchaseReceiptImportToGl");
			let details = [];
			let number = 1;
			this.purchaseReceiptToGl.detail.forEach((res) => {
				let detail = {
					accountId: res.accountId,
					accountCode: res.accountCode,
					accountName: res.accountName,
					creditAmount: res.creditAmount,
					debitAmount: res.debitAmount,
					description: res.description,
					group: null,
					headerId: res.headerId,
					id: "dummy" + number,
					itemNumber: number,
					jobId: null,
					projectId: null,
					sectionId: null,
					paytype: null,
					remark1: this.purchaseReceiptImportItem[0].remark1,
				};

				details.push(detail);
				number++;
			});
			this.$emit(
				"confirm-purchase-receipt-to-gl", 
				details, 
				this.purchaseReceiptToGl.voucherDate,
				this.purchaseReceiptToGl.voucherDescription,
			);
			this.$emit("close");
		},
		async purchaseReceiptToGlMessageError() {
			this.messageError = "";
			let messages = "";
			this.purchaseReceiptToGl.detail.forEach((detail) => {
				if (detail.error != null && detail.error != "") {
					messages += detail.error + "</br>";
				}
			});
			this.messageError = messages;
		},
		async deletePurchaseReceiptWithVoucher(data) {
			this.setLoading(true);
			const result = await this.$swal({
				icon: "info",
				html: `<p class="${this.textAlertClass}">${this.lang.askForDeleteDocument}</p>`,
				confirmButtonText: this.lang.confirm,
				showCancelButton: true,
				cancelButtonText: this.lang.close,
				allowOutsideClick: false,
				confirmButtonColor: "#EF4444",
				cancelButtonColor: "#64748B",
			});

			if (result.isConfirmed) {
				try {
					let payload = {
						voucherId: data.receiptVoucherId,
						receiptId: data.receiptId,
						projectId: data.projectId,
					};
					// console.log(payload);
					await this.$store.dispatch("glPurchaseReceipt/deleteVoucherPurchaseReferenceDocument", payload);
					this.setLoading(false);
				} catch (error) {
					this.$swal({
						icon: "error",
						html: `<p class="${this.textAlertClass}">${error}</p>`,
						confirmButtonText: this.lang.close,
						allowOutsideClick: false,
					});
					this.setLoading(false);
				}
			} else if (result.isDenied) {
				//
			}
			this.setLoading(false);
		},
		checkModuleCode(isCreate) { 
			this.purchaseReceiptImportItem.forEach((data) => {
				if (data.moduleCode.trim() === "PT" || data.moduleCode.trim() === "PZ" || data.moduleCode.trim() === "PQ") {
					this.isAutoGL = true
				}
			});
			isCreate ? this.showCreatePurchaseReceipt = false : this.showPurchaseReceiptModal = false
		},
		confirmPurchaseReceiptlReference() { 
			this.$emit("close");
		}
	},

	computed: {
		parameterTitleText() {
			return this.lang.titleImportPurchaseReceipt;
		},
		halfViewportHeight() {
			return this.$store.getters.halfViewportHeight;
		},
		selectedProject() {
			return this.$store.getters.selectedProject;
		},
		selectedDateStart() {
			let dateStart = null;
			if (this.dateStart) {
				dateStart = this.formatDateToISOString(this.dateStart);
			}
			return dateStart;
		},
		selectedDateEnd() {
			let dateEnd = null;
			if (this.dateEnd) {
				dateEnd = this.formatDateToISOString(this.dateEnd);
			}
			return dateEnd;
		},
		purchaseReceiptVoucherLists() {
			return this.$store.getters["glPurchaseReceipt/selectedVoucherReferenceDocument"];
		},
		purchaseReceiptImport() {
			return this.$store.getters["apPurchaseReceipt/purchaseReceiptImport"];
		},
		purchaseReceiptToGl() {
			return this.$store.getters["apPurchaseReceipt/purchaseReceiptsToGl"];
		},
		purchaseReceiptImportItem() {
			let itemNumber = [];
			let i = 1;
			if (this.purchaseReceiptVoucherLists) {
				this.purchaseReceiptVoucherLists.forEach((element) => {
					element = { ...element, ...{ itemNumber: i } };
					itemNumber.push(element);
					i++;
				});
			}
			if (this.purchaseReceiptImport) {
				this.purchaseReceiptImport.forEach((element) => {
					element = { ...element, ...{ itemNumber: i } };
					itemNumber.push(element);
					i++;
				});
			}
			return itemNumber;
		},
	},

	watch: {
		openFrom() {
			this.setBookPurchaseCode("PV");
			if (this.voucherId !== "create") {
				this.getPurchaseReceiptList();
			}
		},
	},
};
</script>
