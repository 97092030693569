export default {
  dbdBusinessType(state) {
    return state.dbdBusinessType.dbdBusinessTypeList;
  },
  dbdBusinessTypeCurrentPage(state) {
    return state.dbdBusinessType.currentPage;
  },
  dbdBusinessTypeNextPage(state) {
    return state.dbdBusinessType.currentPage + 1;
  },
  dbdBusinessTypeTotalPage(state) {
    return state.dbdBusinessType.totalPage;
  },
  dbdBusinessTypeIsLastPage(_, getters) {
    return (
      getters.dbdBusinessTypeCurrentPage === getters.dbdBusinessTypeTotalPage &&
      getters.dbdBusinessTypeTotalPage !== 0
    );
  },
};
