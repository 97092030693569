export default {
    createAccountingFrequently: "Create Accounting Frequently",
    editAccountingFrequently: "Edit Accounting Frequently",
    titleEdit: "Accounting Frequently Detail",
    active: "Active",
    inActive: "InActive",
    format: "Format Dr./Cr.",
    setproportion: "Set Proportion",
    nonSetproportion: "Non Set Proportion",
    accountCode: "Account Code",
    accountName: "Account Name",
    debit: "Debit",
    credit: "Credit",
    addEdit: "Add/ Edit Item",
    account: "Account",
    createNewAccountingFrequently: "Create new accounting frequently",
    askForCreateNewAccountingFrequently: "Are you sure you want to discard change and create new ?",
    createAccountingFrequentlySuccess: "Create accounting frequently successfully",
    editAccountingFrequentlySuccess: "Edit accounting frequently successfully",
    duplicateCode: "Can not save, the account code : :code is duplicate",
    deleteAccountingFrequentlyTitle: "Confirm Delete",
    askForAccountingFrequentlyTitle: "Are you sure you want to delete account code :code ?",
    deleteAccountingFrequentlySuccess: "Delete successfully",
    validateDiffAmount: "Total debit and credit must be equal and more than 0",
    validateCode: "Please enter code",
    validateName: "Please enter name",
    validateDetail: "Please add item",
    validateSelectedAccount: "Please select account",
}