export default({
    title:"Stock Keycard",
    createStockKeycard: "Create Stock Keycard",
    import: "Import Stock Keycard",

    ready: "ready",
    sold: "sold",
    lost: "lost",
    broken: "broken",
    spare: "spare",
    other: "other",
    
    date: "Date",
    dateRange: "Date Range",
    productCode: "Product Code",
    unit: "Unit",
    address:"Address no.",
    runningNo: "Running No.",
    serialNo: "Serial No.",
    receiptCode: "Receipt Code",

    codeSortASC: "Running No. least to greatest",
    codeSortDESC: "Running No. greatest to least",
    dateSortASC: "Date least to greatest",
    dateSortDESC: "Date greatest to least",
})