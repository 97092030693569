export default {
  setBookPurchase(state, payload) {
    let bookPurchases = [...state.bookPurchase.bookPurchases];
    for (const bookPurchase of payload.bookPurchases) {
      if (bookPurchases.findIndex((c) => c.bookId === bookPurchase.bookId) < 0) {
        bookPurchases.push(bookPurchase);
      }
    }
    state.bookPurchase.bookPurchases = bookPurchases;
    state.bookPurchase.currentPage = payload.currentPage;
    state.bookPurchase.totalPage = payload.totalPage;
  },
  resetBookPurchase(state) {
    const bookPurchase = {
      bookPurchases: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.bookPurchase = bookPurchase;
  },
};
