<template>
  <PvDialog
    :breakpoints="{ '1280px': '70vw', '640px': '60vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="pb-1">
        <label class="font-lg">{{ lang.selectReport }}</label>
      </div>
    </template>

    <PvDataTable
      showGridlines
      :value="filteredReportPrints"
      v-model:selection="selectedReport"
      selectionMode="single"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollHeight="halfViewportHeight + 'px'"
      class="p-datatable-sm"
      :class="textTableClass"
      :loading="isSharedReportPrintReady"
      @dblclick="selectReport"
    >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
      <template #header>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <PvInputText
            v-model="searchTextReportPrint"
            :placeholder="lang.keywordSearch"
          />
        </span>
      </template>
      <PvColumn selectionMode="single" bodyClass="text-center"></PvColumn>
      <!-- <PvColumn
        field="category"
        :header="lang.category"
        style="min-width: 4rem"
        headerClass="h-center"
        bodyClass="text-center"
      ></PvColumn> -->
      <!-- <PvColumn
        field="projectCode"
        :header="lang.project"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn> -->
      <PvColumn
        field="itemNo"
        :header="lang.itemNo"
        headerClass="h-center"
        bodyClass="text-center"
      ></PvColumn>
      <PvColumn
        field="nameThai"
        :header="lang.nameTh"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>
    </PvDataTable>
    <template #footer>
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog"
      />
      <PvButton
        :label="lang.ok"
        icon="pi pi-check"
        autofocus
        :disabled="!selectedReport"
        @click="selectReport"
      />
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useGetReportPrint from "../../hooks/getReportPrint.js";
export default {
  emits: ["close", "path-selected"],
  props: ["openReport"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.admin.report };
      } else {
        return { ...lang.en.base, ...lang.en.admin.report };
      }
    });

    const { filteredReportPrints, searchTextReportPrint, isSharedReportPrintReady } = useGetReportPrint()

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      filteredReportPrints,
      searchTextReportPrint,
      isSharedReportPrintReady
    };
  },
  data() {
    return {
        selectedReport: null,
    };
  },
  mounted() {
    this.selectedReport = this.filteredReportPrints[0];
  },
  methods: {
    closeDialog() {
      this.$emit("close");
      this.searchTextReportPrint = "";
    },
    selectReport() {
        this.$emit("path-selected", this.selectedReport.path);
        this.searchTextReportPrint = "";
    },
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
  },
  watch: {
    filteredReportPrints() {
        this.selectedReport = this.filteredReportPrints[0];
    },
  }
};
</script>
