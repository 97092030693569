import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export default {
    namespaced: true,
    state(){
        return {
            signatures: [],
            totalPage: 0,
            currentPage: 1,
            rows: 100,
            selectedSignature: null,
            signatureReference: [],
            signatureAll: [],
            signatureTotalPage: 0, 
            signatureCurrentPage: 0         
        }
    },
    actions,
    getters,
    mutations
}