export default {
	createAsset: "Create Asset",
    editAsset: "Edit Asset",

    createAssetRegister: "Create",
    saveLot: "Save Lot",
    code: "Asset Code*",
    type: "Asset Type Running",
    type1: "1. Asset purchased by juristic person",
    type2: "2. Asset provided by owner",
    type3: "3. Free assets (donations, gifts, etc.)",
    typePlaceholder: "Select asset type.",
    ownership: "Ownership",
    ownership1: "Juristic Asset",
    ownership2: "Joint Asset",
    nameTh: "Asset Name(Thai)*",
    nameEn: "Asset Name(English)",
    assetAccount: "Account Code*",
    depreciationAccount: "Depreciation Account*",
    accumAccount: "Accumulated Account*",
    validateNoAssetAccountSelect: "Please select account code",
    validateNoDepreAccountSelect: "Please select depreciation account",
    validateNoAccumAccountSelect: "Please select accumulated account",
    validateNoNameTh: "Please enter asset name(Thai)",
    validateNoCode: "Please enter asset number",
    purchaseInformation: "Purchase Information",
    voucherCode: "Voucher Code/Tax Invoice Code*",
    validateNoVoucherCode: "Please enter voucher code/tax invoice code",
    buyDate: "Buy Date/Received Date",
    buyPrice: "Buy Price/Received Price*",
    validateNoBuyPrice: "Please enter buy price/received price",
    depreciationSchedule: "Depreciation Schedule",
    calculateValue: "Calculate Value*",
    validateNodepreciationPrice: "Please enter calculate value",
    validateDepreciationPrice: "Depreciation price must not be more than buy price/received price",
    marketPrice: "Market Price",
    otherCost: "Other Cost",
    depreciationDate: "Depreciation Date*",
    validateNoDepreciationDate: "Please enter depreciation date",
    depreciationRate: "Depreciation Rate*",
    validateNoDepreciationRate: "Please enter depreciation rate",
    accumDepreciationBeforeCal: "Accumulated Depreciation Before Calculate",
    depreciationFormula: "Depreciation Formula",
    depreciationFormula1: "Straight",
    depreciationFormula2: "Diminishing",
    depreciationPeriod: "Depreciation Period",
    depreciationPeriod1: "Month",
    depreciationPeriod2: "Year",
    status: "Active Status",
    status1: "Active",
    status2: "Defective",
    cutOffAsset: "Cut Off Asset",
    depreciationRemain: "Depreciation Remain",
    validateDepreciationRemain: "Depreciation remain must not be more than buy price/received price",
    saleReference: "sale Reference",
    cutOffAssetDate: "Cut Off Asset Date",
    salePrice: "Sale Price",
    categoryLocation: "Category Location",
    accompanyAsset: "To Accompany The Asset",
    assetGroup: "Asset Group",
    building: "Building",
    location: "Location",
    admin: "Admin",

    addLot: "Add Item Lot",
    addValueToCopy: "Please Enter Value To Copy.",
    validateValueToCopy: "Value tp copy must be more than 0.",
    askForSaveLot: "Are you sure you want to copy asset <br> code : :code <br>name : :name <br> value : :value item ?",
    detailTitle : "Detail Asset",
};
