<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ this.title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog()"
        />
      </div>
      <PvDivider></PvDivider>
    </template>
    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="date">{{ lang.date }}</label>
          <PvCalendar
            id="date"
            v-model="date"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateError }"
            @date-select="dateError = false"
          />
          <small v-if="dateError" id="date-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>

        <div class="col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="typeReport">{{ lang.typeReport }}</label>
          <div class="flex">
            <div
              v-for="typeReport of typeReports"
              :key="typeReport.code"
              class="field-radiobutton pt-3 mr-5"
            >
              <PvRadioButton
                class="mr-2"
                :inputId="typeReport.code"
                name="typeReport"
                :value="typeReport.code"
                v-model="selectedTypeReport"
              />
              <label :for="typeReport.code">{{ typeReport.name }}</label>
            </div>
          </div>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText
            id="fileNameText"
            type="text"
            class="inputfield w-full mt-1"
            v-model="fileNameText"
          />
        </div>
      </section>
    </transition>

    <template #footer>
      <div
				class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
			>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
					<PvButton
						icon="pi pi-file-pdf"
						:label="lang.downloadPDF"
						@click="download('PDF')"
						class="w-full"
					/>
				</div>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
					<PvButton
						icon="pi pi-file-excel"
						:label="lang.downloadExcel"
						@click="download('JASPEREXCEL')"
						class="w-full"
					/>
				</div>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
					<PvButton
						icon="pi pi-file-excel"
						:label="lang.downloadExcelRaw"
						@click="download('EXCEL')"
						class="w-full"
					/>
				</div>
			</div>
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useTextStyle from "../../../../hooks/textStyle.js";
import useLang from "../../../../hooks/lang.js";
import useGetFormPrint from "../../../../hooks/getFormPrint.js";
import useFormat from "../../../../hooks/format.js";
import useTracking from "../../../../hooks/tracking";
import useReportRequestValidate from "../../../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "toast-request"],
  setup() {
    const { lang } = useLang();
    const store = useStore();
    const { textFormClass } = useTextStyle();
    const { getPreviewBlob, getDownloadBlob } = useGetFormPrint();
    const { formatDateToISOString } = useFormat();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
        };
      }
    });

    return {
      lang: mergeLang,
      textFormClass,
      getPreviewBlob,
      getDownloadBlob,
      formatDateToISOString,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      date: "",
      dateError: false,
      typeReports: [
        { name: this.lang.type1, code: "1" },
        { name: this.lang.type2, code: "2" },
      ],
      selectedTypeReport: "1",
      payload: {},
      fileNameText: "receipt_cash_control",
      docType: "",
    };
  },
  async created() {
    this.initiateData();
    this.getDocumentBook();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
    },
    initiateData() {
      // let date = new Date();
      this.date = new Date(); //new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateError = false;
    },
    async download(typeReport) {
      if (this.validateData()) {
        return;
      }
      this.setLoading(true);
      try {
        await this.getPayload(typeReport);
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    async getPayload(typeReport) {
      let path = this.reportObj.path;
      this.docType = typeReport;
      if (this.selectedTypeReport == "2") {
        path = path + "_type";
      }

      if (typeReport == "PDF") {
        path = path + "_pdf.pdf";
      } else if (typeReport == "JASPEREXCEL") {
        path = path + "_pdf.xlsx";
      } else {
        path = path + "_excel.xlsx";
      }
      
      this.payload = {
        RequestId: 0,
        ReportName: "CashControl",
        FileName: this.fileNameText + this.fileType,
        JasperPath: path,
        DocType: typeReport,
        UserId: this.userId,
        PrintBy: this.userName,
        ProjectId: this.selectedProject.id,
        DocumentBookId: this.documentBook.id,
        BranchId: this.selectedProject.branchId ? this.selectedProject.branchId : "",
        DateAt: this.selectedDate,
        IsForApi: true,
        ReportMenuDetailId: this.reportObj.id,
      };
    },
    validateData() {
      if (this.selectedDate == null) {
        this.dateError = true;
        return true;
      }
      return false;
    },
    async getDocumentBook() {
      try {
        let where = {
          projectId: this.selectedProject.id,
          code: "INV",
        };

        const payload = {
          page: 1,
          limit: 100,
          where,
        };

        await this.$store.dispatch("documentBook/getDocumentBooks",
        payload
        );

      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 5000,
        });
      }
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    title() {
      if (this.$store.getters.selectedLanguage === "TH")
        return this.reportObj.nameThai;
      else return this.reportObj.nameEnglish;
    },
    fileBlob() {
      return this.$store.getters["shared/blob"];
    },
    selectedDate() {
      let date = null;
      if (this.date) {
        date = this.formatDateToISOString(this.date);
      }
      return date;
    },
    documentBook() {
      return this.$store.getters["documentBook/documentBooks"][0];
    },
    userId() {
      return this.$store.getters.userId;
    },
    fileType() {
      let fileType = this.docType == "PDF" ? ".pdf" : ".xlsx";
      return fileType;
    },
    userName() {
      return this.$store.getters.userName;
    },
  },
  watch: {
    async reportObj() {
      this.initiateData();
    },
  },
};
</script>
