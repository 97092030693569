export default {
	setBudgets(state, payload) {
		state.budgets = payload;
	},
	setSelectedBudget(state, payload){
        state.selectedBudget = payload
    },
	setBudgetLastDate(state, payload){
        state.budgetLastDate = payload
    },	
	resetSelectedBudget(state){
        state.selectedBudget = null
    },
};