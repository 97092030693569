<template>
  <!-- v-model="selectedUnit" -->
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredBookBank"
    field="codeAndName"
    @complete="searchBookBank"
    @dropdown-click="filteredBookBank"
    forceSelection
  />
</template>

<script>
import useGetBookBank from "../../hooks/getBookBanks.js";
export default {
  setup() {
    const {
      filteredBookBank,
      searchBookBankText,
      isSharedBookBankReady,
      searchBookBank,
    } = useGetBookBank("Y");

    return {
      filteredBookBank,
      searchBookBankText,
      isSharedBookBankReady,
      searchBookBank,
    };
  },
  emits: ["load-finish"],
  mounted() {
    // console.log("mounted", this.filteredBookBank);
  },
  watch: {
    isSharedBookBankReady(curValue) {
      // console.log("isSharedBookBankReady", curValue);
      if (curValue) {
        this.$emit("load-finish");
      }
    },
  },
};
</script>
