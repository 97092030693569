export default {
	title: "ผังบัญชี",
	createAccountChart: "สร้างผังบัญชี",
	editAccountChart: "แก้ไขผังบัญชี",
	titleEdit: "รายละเอียดผังบัญชี",
	isParentTitle: "ใช้เป็นบัญชีคุมหรือไม่",
	isParent: "ใช้เป็นบัญชีคุม",
	unParent: "ไม่ใช้เป็นบัญชีคุม",
	accountCategory: "หมวดบัญชี",
	selectAccountCategory: "เลือกหมวดบัญชี",
	accountParent: "บัญชีคุม",
	selectAccountParent: "เลือกบัญชีคุม",
	activeStatus: "สถานะการใช้งาน",
	validateAccountCategory: "กรุณาเลือกหมวดบัญชี",
	validateAccountParenty: "กรุณาเลือกบัญชีคุม",
	validateAccountCode: "กรุณาระบุรหัสผังบัญชี",
	validateAccountNameTh: "กรุณาระบุชื่อผังบัญชี (ภาษาไทย)",
	createNewAccountChart: "สร้างผังบัญชีใหม่",
	askForCreateNewAccountChart: "หากคุณสร้างผังบัญชีใหม่ระบบจะไม่บันทึกการแก้ไข คุณยืนยันที่จะสร้างผังบัญชีใหม่ใช่หรือไม่?",
	deleteAccountChartTitle: "ยืนยันการลบข้อมูล",
	askForAccountChart: "ต้องการยืนยันที่จะลบรายการ :code ใช่หรือไม่?",
	deleteAccountChartSuccessTitle: "ลบสำเร็จ",
	deleteAccountChartSuccess: "ลบผังบัญชีนี้สำเร็จ",
	duplicateCode: "ไม่สามารถทำรายการได้ เนื่องจากรหัสผังบัญชี : :code ซ้ำ",
	validateIsParent: "ไม่สามารถลบได้ เนื่องจากผังบัญชีนี้ถูกนำไปใช้เป็นบัญชีคุมแล้ว", //130
	validateHasVoucher: "ไม่สามารถลบได้ เนื่องจากผังบัญชีนี้มีการนำไปบันทึกบัญชีแล้ว", //131
	validateHasAccountingFrequently: "ไม่สามารถลบได้ เนื่องจากผังบัญชีนี้มีการนำไปสร้างรูปแบบบัญชีใช้บ่อยแล้ว", //132
	validateHasAccountingPolicy: "ไม่สามารถลบได้ เนื่องจากผังบัญชีนี้มีการนำไปสร้างนโยบายบันทึกบัญชีแล้ว", //133
	validateHasBookBank: "ไม่สามารถลบได้ เนื่องจากผังบัญชีนี้มีการนำไปผูกบัญชีธนาคารแล้ว", //134
	validateHasAsset: "ไม่สามารถลบได้ เนื่องจากผังบัญชีนี้มีการนำไปบันทึกทรัพย์สินแล้ว", //135
	validateHasProduct: "ไม่สามารถลบได้ เนื่องจากผังบัญชีนี้มีการนำไปผูกรหัสรายรับแล้ว", //136
	validateHasBudget: "ไม่สามารถลบได้ เนื่องจากผังบัญชีนี้มีการนำไปสร้างงบประมาณแล้ว", //137
	accountCategory1: "1 : สินทรัพย์",
	accountCategory2: "2 : หนี้สิน",
	accountCategory3: "3 : ทุน",
	accountCategory4: "4 : รายรับ/รายได้",
	accountCategory5: "5 : รายจ่าย/ค่าใช้จ่าย",

	validateActiveStatusTitle: "ไม่สามารถเลือกผังบัญชีนี้ได้",
	validateActiveStatusWording: "ผังบัญชี :code ถูกปิดการใช้งาน",
	pleaseSelectNewAccountChart: "กรุณาเลือกผังบัญชีใหม่",
};
