export default {
  async getReportMenus(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/ReportMenu/ReportMenu";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get report menu"
      );
      throw error;
    }

    // console.log("report menu", responseData);

    await context.commit("setReportMenu", responseData);
  },
  async getReportMenu(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/ReportMenu/ReportMenu?Page=1&Limit=10";

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to get report menu"
      );
      throw error;
    }

    if (responseData.data.length > 0) {
        context.commit("setSelectedReportMenu", responseData.data[0]);
    }

  },
  setSelectedReportMenu(context, payload){
    context.commit('setSelectedReportMenu', payload);
  },
  resetSelectedReportMenu(context) {
    context.commit("setSelectedReportMenu", null);
  },
  resetReportMenu(context) {
    context.commit("resetReportMenu");
  },
  async createOrUpdateReportMenu(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/ReportMenu/${payload.type}ReportMenu`;

    const fallbackErrorMessage = `Failed to ${payload.type} report menu`
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: payload.type === 'Add' ? 'post' : 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    
    if (!response.ok) {
        let message = null
        if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
          message = responseData.title;
          for (const key in responseData.errors) {
            message += ", " + key.replace("$.","")
          }
        } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
          message = responseData.message
        }
        const error = new Error(message || fallbackErrorMessage);
        throw error;
    }
  
    context.commit("setSelectedReportMenu", responseData.data);

  },
};
