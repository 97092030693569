export default {
  buildingBuildings(state) {
    return state.building.buildings;
  },
  buildingCurrentPage(state) {
    return state.building.currentPage;
  },
  buildingNextPage(state) {
    return state.building.currentPage + 1;
  },
  buildingTotalPage(state) {
    return state.building.totalPage;
  },
  buildingIsLastPage(_, getters) {
    return getters.buildingCurrentPage === getters.buildingTotalPage && getters.buildingTotalPage !== 0;
  }
};
