<template>
  <PvDialog
    :breakpoints="{ '1280px': '70vw', '640px': '50vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <label class="font-lg font-normal">{{ lang.selectProduct }}</label>
      <PvDivider></PvDivider>
      <section class="flex flex-wrap justify-content align-items-center">
        <div class="lg:col-6 md:col-6 col-12 p-0 mr-2">
            <span class="p-input-icon-left w-full">
            <i class="pi pi-search" />
            <PvInputText
                class="inputfield w-full"
                v-model="searchText"
                :placeholder="lang.keywordSearch"
                @keyup.enter="filterProduct"
            />
            </span>
        </div>
        <PvButton @click="filterProduct">
            <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>
    </template>

    <div v-if="isErrorProduct" class="pb-2 mt-2">
      <PvInlineMessage severity="error" class="w-full justify-content-start">{{
        lang.validateNoDetailSelect
      }}</PvInlineMessage>
    </div>
    
    <div class="p-datatable-border my-2">
      <PvDataTable
        :value="filteredProducts"
        v-model:selection="selectedProducts"
        selectionMode="multiple"
        dataKey="id"
        responsiveLayout="scroll"
        class="p-datatable-xs"
        :loading="isLoading"
        :scrollHeight="halfViewportHeight + 'px'"
        :metaKeySelection="false"
        @row-select="isErrorProduct = false"
        scrollable
      >
        <PvColumn
          selectionMode="multiple"
          style="flex: 0; height: 56px"
        ></PvColumn>
        <PvColumn
          field="code"
          :header="lang.code"
          style="max-width: 10rem; height: 56px"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="nameThai"
          :header="lang.productName"
          style="min-width: 20rem; height: 56px"
          headerClass="h-left"
          bodyClass="text-left"
        />

        <template #empty>
          <div class="w-full p-3" style="text-align: center">
            <label>{{ this.lang.tableEmpty }}</label>
          </div>
        </template>

      </PvDataTable>
    </div>

    <template #footer>
      <PvButton
        :label="lang.close"
        class="p-button-outlined p-button-secondary w-2 ml-3"
        @click="closeDialog"
      />
      <PvButton 
        :label="lang.confirm" 
        class="w-full mr-3 w-2" 
        @click="selectProduct"
      />
    </template>
  </PvDialog>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
export default {
  props: ["isOpen","detailProducts"],
  emits: ["close", "product-selected"],
  setup() {
    const { lang } = useLang();
    const { textFormClass, textTableClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.noticePrintSetting,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.noticePrintSetting,
        };
      }
    });

    return {
      lang: mergeLang,
      textFormClass,
      textTableClass,
    };
  },
  data() {
    return {
      searchText: "",
      isLoading: false,
      selectedProducts: [],
      productIgnoreDetails: [],
      filteredProduct: [],
      isSearch: false,
      isErrorProduct: false,
    };
  },
  async mounted() {
    await this.initiateData();
    await this.getProducts();
  },
  methods: {
    closeDialog() {
      this.initiateData();
      this.$emit("close");
    },
    async initiateData() {
      this.selectedProducts = [];
      this.filteredProduct = [];
      this.searchText = "";
      this.isSearch = false;
      this.isErrorProduct = false
    },
    async getProducts() {
        this.isLoading = true;
        try {
            await this.$store.dispatch("shared/getAllProductARs");
            // console.log(this.products);
        } catch (error) {
            this.isLoading = false;
        }
        this.isLoading = false;
    },
    selectProduct() {
      if (!this.isNoSelectProduct) {
        this.$emit("product-selected", this.selectedProducts);
        this.closeDialog();
      } else {
        this.isErrorProduct = true;
      }
    },
    filterProduct() {
        if (!this.searchText) {
            this.filteredProduct = [...this.productExceptDetailProducts];
            this.isSearch = false;
        } else {
            this.filteredProduct = this.productExceptDetailProducts.filter(
                (data) => {
                    return data.code.toLowerCase()
                    .includes(this.searchText.toLowerCase()) || 
                    data.nameThai.toLowerCase()
                    .includes(this.searchText.toLowerCase());
                }
            );
            this.isSearch = true;
        }
        this.selectedProducts = [];
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    products() {
      let products = this.$store.getters["shared/productArProducts"];
      products = products.filter((product) => product.type == "N")
      return products;
    },
    isNoSelectProduct() {
        if(this.selectedProducts.length == 0) {
            return true;
        }
        return false;
    },
    productExceptDetailProducts() {
        let details = this.products;
        if(details.length > 0) {
            this.detailProducts.forEach((element) => {
                const index = details.findIndex(
                    (p) => p.id === element.productId
                );

                if (index >= 0) {
                    details.splice(index, 1);
                }
            })

            let i = 1;
            let data = [];
            details.forEach((element) => {
                element = { ...element, ...{ itemNo: i } };
                data.push(element);
                i++;
            });

            details = data;
        }
        return details;
    },
    filteredProducts() {
      if (!this.isSearch) {
        return this.productExceptDetailProducts;
      } else {
        return this.filteredProduct;
      }
    },
  },
};
</script>
