export default {
  uploadFile: "อัปโหลดไฟล์",
  dragFile: "ลากไฟล์มาวาง หรือ ",
  addFile: "เพิ่มไฟล์ใหม่",
  importFileDesc: "กรุณาเพิ่มไฟล์ทีละ 1 ไฟล์ ที่มีนามสกุล .XLSX เท่านั้น หรือ ",
  downloadfile: "ดาวน์โหลดตัวอย่างไฟล์",
  fileName: "ชื่อเอกสาร",
  validateLimitFile: "สามารถอัปโหลดไฟล์ได้ทีละ 1 ไฟล์",
  validateFileType: "ไม่สามารถอัปโหลดไฟล์ :name ได้",
  validateImportFile: "สกุลไฟล์ที่อนุญาตมีดังนี้ .XLSX เท่านั้น",

  importReceipts: "นำเข้าข้อมูลใบเสร็จ",
  importAll: "นำเข้าทั้งหมด",
  importSelected: "นำเข้ารายการที่เลือก",
  columnUnitCode: "ยูนิต",
  columnUnitAddressNo: "บ้านเลขที่",
  columnUnitCustomerName: "ชื่อลูกค้า",
  columnDate: "วันที่",
  columnReferCode: "เลขที่อ้างอิง",
  columnCustomerName: "ชื่อผู้ชำระ",
  columnCustomerAddressNo: "ที่อยู่ผู้ชำระ",
  columnProductCode: "รหัสรายรับ",
  columnPeriod: "งวด",
  columnDescription: "รายละเอียด",
  columnAmount: "จำนวนเงิน",
  columnPayType: "ประเภทการชำระ",
  columnPayCode: "เลขที่โอน/เลขที่เช็ค",
  columnBookBankCode: "รหัสสมุดบัญชีธนาคาร",
  columnBookBankBranch: "ชื่อธนาคาร/สาขา",
  columnPayDate: "วันที่รับเงิน/วันที่โอน/วันที่เช็ค",
  columnBranch: "สาขา",

  validateImportNoData: "เอกสาร : :file ไม่มีข้อมูล",
  validateUnitCode: "ข้อมูลแถวที่ :row ไม่ได้ระบุรหัสห้อง/ยูนิต",
  validateReceiptDate: "ข้อมูลแถวที่ :row ไม่ได้ระบุวันที่ใบเสร็จ",
  validateFormatReceiptDate: "ข้อมูลแถวที่ :row ระบุรูปแบบวันที่ใบเสร็จไม่ถูกต้อง(dd/MM/yyyy(ค.ศ.))",
  validateProductCode: "ข้อมูลแถวที่ :row ไม่ได้ระบุรหัสรายรับ",
  validatePeriod: "ข้อมูลแถวที่ :row ไม่ได้ระบุงวด",
  validateFormatPeriod: "ข้อมูลแถวที่ :row ระบุรูปแบบงวดไม่ถูกต้อง(MM/YYYY(พ.ศ.))",
  validateTotalAmount: "ข้อมูลแถวที่ :row ไม่ได้ระบุจำนวนเงิน",
  validateFormatTotalAmount: "ข้อมูลแถวที่ :row ระบุจำนวนเงินไม่ถูกต้อง",
  validatePayType: "ข้อมูลแถวที่ :row ไม่ได้ระบุประเภทการชำระ",
  validatePayTypeEpay: "ข้อมูลแถวที่ :row ไม่รองรับการนำเข้าใบเสร็จ E-Payment",
  validateFormatPayType: "ข้อมูลแถวที่ :row ประเภทการชำระ :payType ไม่ถูกต้อง",
  validatePayCode: "ข้อมูลแถวที่ :row ประเภทการชำระ :type ไม่ได้ระบุเลขที่โอน/เลขที่เช็ค",
  validateCreditCardPayCode: "ข้อมูลแถวที่ :row ประเภทการชำระ :type ไม่ได้ระบุเลขที่บัตรเครดิต",
  validateBookBankCode: "ข้อมูลแถวที่ :row ประเภทการชำระ :type ไม่ได้ระบุบัญชีธนาคาร",
  validatePayDate: "ข้อมูลแถวที่ :row ไม่ได้ระบุวันที่รับเงิน/วันที่โอน/วันที่เช็ค",
  validateFormatPayDate: "ข้อมูลแถวที่ :row ระบุวันที่รับเงิน/วันที่โอน/วันที่เช็ค(dd/MM/yyyy(ค.ศ.)) ไม่ถูกต้อง",
  validateUnitCodeInvalid: "รหัสห้อง/ยูนิต : :unitCode ไม่ถูกต้อง",
  validateProductCodeInvalid: "รหัสห้อง/ยูนิต : :unitCode ระบุรหัสรายรับไม่ถูกต้อง",
  validateBookBankCodeInvalid: "รหัสห้อง/ยูนิต : :unitCode ระบุบัญชีธนาคารไม่ถูกต้อง",

  statusWaiting: "รอบันทึก",
  statusSuccess: "บันทึกสำเร็จ",
  importReceiptSuccess: "นำเข้าใบเสร็จสำเร็จ",
  pleaseSelectItem: "กรุณาเลือกรายการ",
  uploadStatus: "สถานะอัปโหลด",
  uploading: "กำลังอัปโหลด...",
  import: "นำเข้า",
  receiptDetail: "รายละเอียดใบเสร็จ",
  error: "ข้อผิดพลาด",
  errorDetail: "ยอดชำระมากกว่ายอดค้าง",
  
  validatingData: "กำลังตรวจสอบข้อมูล",
  mappingInvoice: "กำลังจับคู่ใบแจ้งหนี้",
  unitCodeIsNotValid: "ไม่สามารถนำเข้าได้ เนื่องจากไม่พบเลขที่ห้อง/ยูนิต “:unitCode”",
  productCodeIsNotValid: "ไม่สามารถนำเข้าได้ เนื่องจากไม่พบรหัสรายรับ “:productCode”",  
  unitCodeIsNotActive: "ไม่สามารถนำเข้าได้ เนื่องจากเลขที่ห้อง/ยูนิต “:unitCode” มีสถานะปิดการใช้งานอยู่",

};
