<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.0041 8.08746V19.003C21.0041 20.108 20.1083 21.0038 19.0033 21.0038H4.99742C3.89239 21.0038 2.99658 20.108 2.99658 19.003V8.08746C2.9962 7.81615 3.051 7.5476 3.15765 7.29813L4.4782 4.20784C4.79395 3.47239 5.5176 2.99586 6.31797 2.99634H17.6827C18.4838 2.99634 19.2076 3.47421 19.5225 4.21084L20.843 7.29813C20.9495 7.54765 21.0043 7.81617 21.0041 8.08746Z"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.99658 7.9985H20.9941"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.0011 2.99634L15.0015 7.99842V11.2248C14.9768 11.6758 14.5924 12.0222 14.1412 12.0001H9.85938C9.40818 12.0222 9.02379 11.6758 8.99902 11.2248V7.99842L9.99944 2.99634"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.99756 18.0026H7.99839"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
