export default {
  uploadFile: "Upload File",
  dragFile: "Drag drop file or ",
  addFile: "Add File",
  importFileDesc: "Please add only 1 file at a time with .XLSX or ",
  downloadfile: "Download example file",
  fileName: "File Name",
  validateLimitFile: "Can be upload 1 file at a time",
  validateFileType: "Can't upload file :name",
  validateImportFile: "The allowed file extensions are .XLSX only",
  validateImportNoData: "File : :file no data.",

  validateImportMaxData:"Cannot import more than :maxRow items",
  validateImportMaxColumn: "Cannot import data because data not equal to :maxColumn column.",  
  validateImportColumnNotMatch: "Column name does not match the system.",
  
  unitCode: "unitCode*",
  validateCannotBeBlank: "Row :row :name Cannot be blank.",
  validateLimitText: "Row :row :name specify a message with a maximum length of :limit characters.",

  toolbarTitle: "Import unit resident",
  title: "Import unit resident",
  statusImport: "Status Import",
  reason: "Reason",
  customerCode: "Customer Code",
  cannotImport: "Can't Import",
  noItemToImportWording: "Unable to save due to no waiting items.",
  editAndReuploadWording: "Please edit file and upload again",
  wordingConfirmImportUnit: "From :total items, you can create only :value items because there have “Invalid status” :invalidValue items.",
  creatingUnit: "Creating Unit",
  generating: "Creating...",
  residentType: "Resident Type",
  stayDate: "Stay Date",
  moveDate: "Move Date",
  residentInformation: "Rental/Resident Information",
  unitResidentDuplicate: "CustomerCode and residentType and stayDate",
  validateDuplicateInDatabase: "':field' duplicate ':table'", 
  validateStayDateAndMoveDate: "StayDate more than MoveDate",
};
