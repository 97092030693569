<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <section class="flex flex-wrap" :class="textFormClass">
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="dateAt">{{ lang.dateAt }}</label>
        <PvCalendar
          id="dateAt"
          v-model="dateAt"
          class="inputfield w-full mt-1"
          showIcon
          dateFormat="dd/mm/yy"
          :class="{ 'p-invalid': dateAtError }"
          @date-select="dateAtError = false"
        />
        <small v-if="dateAtError" id="dateAt-help" class="p-error">{{
          lang.validateDateSelect
        }}</small>
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0"></div>

      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="productStart">{{ lang.productStart }}</label>
        <base-product-guarantee-auto-complete
          id="productStart"
          v-model="selectedProductStart"
          class="inputfield w-full mt-1"
          @load-finish="productDataReady"
          :isLoadingData="true"
        />
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="productEnd">{{ lang.to }}</label>
        <base-product-guarantee-auto-complete
          id="productEnd"
          v-model="selectedProductEnd"
          class="inputfield w-full mt-1"
          @load-finish="productDataReady"
        />
      </div>

      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="unitStart">{{ lang.unitStart }}</label>
        <base-unit-auto-complete
          id="unit"
          v-model="selectedUnitStart"
          class="inputfield w-full mt-1"
          @load-finish="unitDataReady"
          :isLoadingData="true"
        />
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="unitEnd">{{ lang.to }}</label>
        <base-unit-auto-complete
          id="unit"
          v-model="selectedUnitEnd"
          class="inputfield w-full mt-1"
          @load-finish="unitDataReady"
        />
      </div>

      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="fileNameText">{{ lang.fileName }}</label>
        <PvInputText
          id="fileNameText"
          type="text"
          class="inputfield w-full mt-1"
          v-model="fileNameText"
        />
      </div>
      <div class="col-12 pl-0 pr-2 pt-3">
        <PvCheckbox
          id="isIgnoreZero"
          inputId="binary"
          v-model="isIgnoreZero"
          :binary="true"
        />
        <label for="isIgnoreZero" class="pl-3">{{ lang.isIgnoreZero }}</label>
      </div>

    </section>

    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
        </div>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useTextStyle from "../../../hooks/textStyle.js";
import useLang from "../../../hooks/lang.js";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useFormat from "../../../hooks/format.js";
import useTracking from "../../../hooks/tracking";
import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "toast-request"],
  setup() {
    const { lang } = useLang();
    const store = useStore();
    const { textFormClass } = useTextStyle();
    const { getPreviewBlob, getDownloadBlob } = useGetFormPrint();
    const { formatDateToISOString } = useFormat();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
        };
      }
    });

    return {
      lang: mergeLang,
      textFormClass,
      getPreviewBlob,
      getDownloadBlob,
      formatDateToISOString,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      dateAt: null,
      dateAtError: false,
      selectedUnitStart: null,
      selectedUnitEnd: null,
      isSharedUnitReady: false,
      selectedProductStart: null,
      selectedProductEnd: null,
      isSharedProductReady: false,
      isIgnoreZero: true,
      type: "PDF",
      fileNameText: "guarantee_summary",
    };
  },
  mounted() {
    this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.initiateData();
      this.$emit("close");
    },
    initiateData() {
      this.dateAt = new Date();
      this.dateAtError = false;
      this.selectedUnitStart = null;
      this.selectedUnitEnd = null;
      this.selectedProductStart = null;
      this.selectedProductEnd = null;
      this.isIgnoreZero = true;
      this.type = "PDF";
      this.fileNameText = "guarantee_summary";
    },
    unitDataReady() {
      this.isSharedUnitReady = true;
    },
    productDataReady() {
      this.isSharedProductReady = true;
    },
    validateData() {
      if (this.selectedDateAt == null) {
        this.dateAtError = true;
        return true;
      }
      return false;
    },
    async download(typeReport) {
      this.type = typeReport;
      if (this.validateData()) {
        return;
      }

      this.setLoading(true);
      try {
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
    title() {
      if (this.$store.getters.selectedLanguage === "TH")
        return this.reportObj.nameThai;
      else return this.reportObj.nameEnglish;
    },
    selectedDateAt() {
      let dateAt = null;
      if (this.dateAt) {
        dateAt = this.formatDateToISOString(this.dateAt);
      }
      return dateAt;
    },
    fileBlob() {
      return this.$store.getters["shared/blob"];
    },
    units() {
      return this.$store.getters["shared/unitUnits"];
    },
    products() {
      return this.$store.getters["shared/productArProducts"];
    },
    fileName() {
      let fileName = this.type == "PDF" ? ".pdf" : ".xlsx";
      return this.fileNameText + fileName;
    },
    fileType() {
      let fileType =
        this.type == "EXCEL"
          ? "_excel.xlsx"
          : this.type == "JASPEREXCEL"
          ? ".xlsx"
          : ".pdf";
      return fileType;
    },
    payload() {
      let payload = {
        ProjectId: this.selectedProject.id,
        DateAt: this.selectedDateAt,
        UnitStart: this.selectedUnitStart ? this.selectedUnitStart.code : "",
        UnitEnd: this.selectedUnitEnd ? this.selectedUnitEnd.code : "",
        ProductStart: this.selectedProductStart ? this.selectedProductStart.code : "",
        ProductEnd: this.selectedProductEnd ? this.selectedProductEnd.code : "",
        IsIgnoreZero: this.isIgnoreZero,
        FileName: this.fileName,
        JasperPath: this.reportObj.path + this.fileType,
        DocType: this.type,
        UserId: this.userId,
        PrintBy: this.userName,
        RequestId: 0,
        ReportName: "GuaranteeSummary",
      };
      return payload;
    }
  },
  watch: {
    reportObj() {
      this.initiateData();
    },
  },
};
</script>
