export default {
	username: "Username",
	password: "Password",
	signIn: "Sign In",
	yes: "Yes",
	no: "No",
	close: "Close",
	ok: "OK",
	baseError: "Sorry, something went wrong.",
	notfound: "We think you just went to a non-existing page",
	notfoundButton: "Back to HOME",
	dateStart: "Date start",
	dateEnd: "Date end",
	codeStart: "Code start",
	codeEnd: "Code end",
	search: "Search",
	titleLanguage: "Change Language",
	language: "Display Language",
	keywordSearch: "Keyword Search",
	searchUnit: "Search Unit",
	page: "Page",
	hideParameters: "Hide Parameters",
	showParameters: "Show Parameters",
	date: "Date",
	code: "Code",
	dueDate: "Due date",
	customerName: "Customer Name",
	customerMobile: "Customer Mobile",
	createBy: "Create by",
	updateBy: "Update by",
	productCode: "Product Code",
	productName: "Product Name",
	itemNo: "Item No.",
	description: "Description",
	period: "Period",
	unitPrice: "Unit Price",
	quantity: "Quantity",
	totalAmount: "Total Amount",
	paidAmount: "Paid Amount",
	unit: "Unit Code",
	price: "Price",
	livingType: "Living Type",
	allFilter: "All Filter",

	back: "Back",
	exit: "Exit",
	confirmDialogHeader: "Confirmation",
	create: "Create Document",
	save: "Save",
	saveAll: "Save All",
	saveAllSucess: "Save Successfully",
	print: "Print",
	preview: "Preview",
	export: "Export",
	printPdf: "Download PDF",
	cancel: "Cancel",
	request: "Request",
	askForCreateNewDocument: "Are you sure you want to discard change and create new document?",
	askForCancelDocument: "Are you sure you want to cancel document?",
	askForNonExistPeriod: "Can not find period :period. The default period will be use instead.",
	validateNoUnitSelect: "Please select unit.",
	notificationDialogHeader: "Notification",
	remark: "Remark",
	remark1: "Remark1",
	remark2: "Remark2",
	saveSuccess: "Save successfully",
	confirm: "Confirm",
	cancelSuccess: "Cancel successfully",
	askRevertGeneralLedger: "Are you sure you want to revert document?",
	askForDeleteDocument: "Are you sure you want to unlink document?",
	askForDeleteDocumentAll: "Are you sure you want to unlink all document?",
	successfully: "successfully",
	askForRemoveDocument: "Are you sure you want to remove document?",

	type: "Type",
	status: "Status",
	validateNoSupplierSelect: "Please select supplier",
	validatePrintPermission: "You may not have print permission",
	validateNoTaxBookSelect: "Please select tax book",
	validateNoEmployeeSelect: "Please select Employee",
	validateNoDetailSelect: "Please select Item.",
	validateNoTaxSelect: "Please select Tax.",

	askForProductChange: "Are you sure you want to update product code?",
	askForSelectProduct: "Can't find product code : :code Do you want to search?",
	askForApproveDocument: "Are you sure you want to approve document?",
	approveSuccess: "Approve successfully",

	saveAs: "SaveAs",
	askForSaveAs: "Are you sure you want to copy Document?",
	saveAsSuccess: "Copy Document successfully",

	validateRoleInsert: "You don't have permission to create document",
	validateRoleUpdate: "You don't have permission to update document",
	validateRoleCancel: "You don't have permission to cancel document",
	validateRoleApprove: "You don't have permission to approve document",
	validateRoleDelete: "You don't have permission to delete data",
	validateRoleClose: "You don't have permission to Close document",
	validateRoleSelect: "You don't have permission to select document",
	validateRoleImport: "You don't have permission to import document",

	editCaption: "Edit data",
	saveCaption: "Save",
	createCaption: "Add List",
	reportCaption: "Report",
	printCaption: "Print",
	confirmEditSave: "Confirm",
	askForEditDocument: "Are you sure to edit :title?",

	showhideParameters: "show/hide",
	saveEditSuccess: "Edit successfully",
	validategoBackTitle: "discard Edit",
	validategoBackEdit: "Do you want to discard edit :title data?",
	titleConfirmDelete: "Confirm delete data",
	askForDeleteDataDetail: "Are you sure to delete :title?",
	deleteSuccess: "Delete Success",
	askconfirmData: "Are you sure to Confirm :title?",
	pageDirtyConfirmMessage: "Do you want to discard change?",//"You're editing this page, Are you sure you want to leave?",
	referenceDocument: "Reference Document",

	searchProject: "Search Project",
	confirmChangeProjectMessage: "Confirm to change project",
	changeProjectMessage: "Are you sure you want to change project?",
	dataNotFound: "Data Not Found",
	cancelDocument: "Cancel Document",
	tableEmpty: "Empty Data",

	buttonDialogSuccess: "Agree",
	buttonDialogConfirm: "Confirm",
	buttonDialogCancel: "Cancel",
	validateSearchText: "Please enter searchText.",
	createNew: "CreateNew",
	printLot: "Print Lot",
	documentDate: "Document Date",
	address: "Address",
	saveUnsuccess: "Save Unsuccess",
	delete: "Delete",
	documentCode: "Document Code",
	toastRequestTitle: "In process...",
	toastRequestContent: "You can view your request at ",
	toastRequestLink: "request list",
	copy: "Copy",
	allData: "All",
	active: "Active",
	inActive: "InActive",
	searchTextPlaceholder: "Fill up to search.",

	createText: "Create",
	edit: "Edit",
	selectProject: "Select Project",
	import: "Import",
	uploadFile: "Upload File",

	validateSaveTitle: "Can not save",
	validateDeleteTitle: "Can not delete",
	validateFieldNoValue: "You have not entered value in ",
	validateDropDownNoSelect: "You have not selected ",
	validateDetail: "Please add item",
	validateFomatInvalid: "You have entered :field invalid",
	validateCancelled: "This document was cancelled",
	validateReferenceDocument: "This document had reference",
	validateReferenceVoucher: "This document had voucher",
	validatePrinted: "This document was printed",

	documentIsLockedTitle: "This document's is locked.",
	documentIsLockDetail: "You cannot edit or change the status of this document.<br>Because this document's books are locked.",
	updateDate: "Update Date",

	downloadfile: "Download example file",
	askForNewImport: "Do you want to upload new file to replace old data or not?",
	statusImport: "Import Status",
	reason: "Reason",
	reupload: "Reupload",
	importAll: "Import All (:value)",
	allStatus: "All status",
	wait: "Wait",
	success: "Success",
	invalid: "Invalid",
	error: "Error",
	generating: "Generating...",
	statusContent: ":number",
	mobileNumber: "Telephone",
	noItemToImportWording: "Unable to save due to no waiting items.",
	askForNextStep: "Are you sure you would like to continue or not?",
	cannotImportWording: "Cannot Import",
	listCannotImport: "list cannot Import",
	noItemToExportWording: "Unable to export due to no list cannot Import.",
	subdistrict: "Subdistrict",
	district: "District",
	province: "Province",
	postcode: "Postcode",
	countryCode: "Country Code",
	prefix: "Prefix",
	contactName: "Contact Name",
	contactCountryCode: "Contact Country Code",
	contactMobile: "Contact Mobile",
	subdistrictPlaceholder: "Enter Subdistrict",
	districtPlaceholder: "Enter District",
	provincePlaceholder: "Enter Province",
	postcodePlaceholder: "Enter Postcode",

	validateSubdistrict: "Please Enter Subdistrict",
	validateDistrict: "Please Enter District",
	validateProvince: "Please Enter Province",
	validatePostcode: "Please Enter Postcode",
	
	generalInformation: "General Information",
	reportRequestConfirmMessage: "You have already called for the report. and is waiting in queue | Want to repeat the call?",
    invalidAddress: "Invalid address information.",
	inconsistentAddress: "Address information is inconsistent.",
	contactInformation: "Contact Information",
	notShowAgainToday: "Don't show again today.",
	defaultFormat: "Default Format",
};
