export default {
	async getPurchaseReceipts(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseReceipt";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Purchase Receipt");
			throw error;
		}
		// console.log(responseData);

		// const data = responseData.data;
		context.commit("setPurchaseReceipts", responseData);
	},

	async getPurchaseReceipt(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseReceipt?limit=1";

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get invoice");
			throw error;
		}

		// const data = responseData.data;
		if (responseData.data.length > 0) {
			context.commit("setSelectedPurchaseReceipt", responseData.data[0]);
		}
	},
	async getPurchaseInvoiceFroImport(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseReceipt/GetPurchaseInvoiceFroImport";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + encodeURI(JSON.stringify(payload.where));
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Purchase Receipt");
			throw error;
		}
		// console.log("getPurchaseInvoiceFroImport", responseData);

		// const data = responseData.data;
		context.commit("setPurchaseInvoiceFroImport", responseData);
	},

	resetPurchaseReceipts(context) {
		context.commit("resetSelectedPurchaseReceipt");
	},
	resetPurchaseInvoiceFroImport(context) {
		context.commit("resetPurchaseInvoiceFroImport");
	},
	resetPurchaseReceiptsVoucher(context) {
		context.commit("resetPurchaseReceiptsVoucher");
	},
	async addPurchaseReceipt(context, payload) {
		// console.log('AddBankTransaction',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseReceipt/AddPurchaseReceipt";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("PurchaseReceipt>>>", responseData);
			const error = new Error(responseData.message || "Failed to Add Purchase Receipt");
			throw error;
		}
		// console.log("PurchaseReceipt>>>", responseData.data);
		await context.commit("setSelectedPurchaseReceipt", responseData.data);
	},

	async updatePurchaseReceipt(context, payload) {
		// console.log('AddBankTransaction',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseReceipt/UpdatePurchaseReceipt";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("PurchaseReceipt>>>", responseData);
			const error = new Error(responseData.message || "Failed to Update Purchase Receipt");
			throw error;
		}
		// console.log("PurchaseReceipt>>>", responseData.data);
		await context.commit("setSelectedPurchaseReceipt", responseData.data);
	},

	async cancelPurchaseReceipt(context, payload) {
		// console.log('AddBankTransaction',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseReceipt/CancelPurchaseReceipt";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("PurchaseReceipt>>>", responseData);
			const error = new Error(responseData.message || "Failed to Cancel Purchase Receipt");
			throw error;
		}
		// console.log("PurchaseReceipt>>>", responseData.data);
		await context.commit("setSelectedPurchaseReceipt", responseData.data);
	},
	async printPurchaseReceipt(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseReceipt/Print";

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
			mode: "cors", // no-cors, *cors, same-origin
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			credentials: "same-origin", // include, *same-origin, omit
			redirect: "follow", // manual, *follow, error
			referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
		});

		let urlBlob = "";
		let blob = await response.blob();
		urlBlob = window.URL.createObjectURL(blob);
		context.commit("setBlob", urlBlob);
	},
	resetPurchaseReceiptImport(context) {
		context.commit("resetPurchaseReceiptImport");
	},
	async setPurchaseReceiptImport(context, payload) {
		await context.commit("setPurchaseReceiptImport", payload);
	},
	async getPurchaseReceiptToGl(context, payload) {
		// console.log('AddBankTransaction',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/V2/Voucher/PurchaseReceiptToGl";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("PurchaseReceipt>>>", responseData);
			const error = new Error(responseData.message || "Failed to Cancel Purchase Receipt");
			throw error;
		}
		// console.log("setPurchaseReceiptToGl>>>", responseData.data);
		await context.commit("setPurchaseReceiptToGl", responseData.data);
	},
	async getPurchaseReceiptsVoucher(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseReceipt";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Purchase Receipt");
			throw error;
		}
		// console.log(responseData);

		// const data = responseData.data;
		context.commit("setPurchaseReceiptsVoucher", responseData);
	},
	async deletePurchaseReceiptWithVoucher(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Voucher/DeletePurchaseReceiptWithVoucher";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("PurchaseReceipt>>>", responseData);
			const error = new Error(responseData.message || "Failed to Cancel Purchase Receipt");
			throw error;
		}
		// console.log("setPurchaseReceiptToGl>>>", responseData.data);
		context.commit("setPurchaseReceiptsVoucher", responseData);
	},
	async addPurchaseReceiptVoucher(context, payload) {
		// console.log('AddBankTransaction',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseReceipt/AddPurchaseReceipt";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("PurchaseReceipt>>>", responseData);
			const error = new Error(responseData.message || "Failed to Add Purchase Receipt");
			throw error;
		}
		// console.log("PurchaseReceipt>>>", responseData.data);
		await context.commit("setSelectedPurchaseReceipt", responseData.data);
		// responseData.data = [responseData.data];
		// await context.commit("setPurchaseReceiptsVoucher", responseData);
	},

	async updatePurchaseReceiptVoucher(context, payload) {
		// console.log('AddBankTransaction',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseReceipt/UpdatePurchaseReceipt";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("PurchaseReceipt>>>", responseData);
			const error = new Error(responseData.message || "Failed to Update Purchase Receipt");
			throw error;
		}
		// console.log("PurchaseReceipt>>>", responseData.data);
		await context.commit("setSelectedPurchaseReceipt", responseData.data);
		// responseData.data = [responseData.data];
		// await context.commit("setPurchaseReceiptsVoucher", responseData);
	},
	async getPurchaseReceiptReference(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseReceipt/PurchaseReceiptReference";

		const token = context.rootGetters.token;

		const response = await fetch(url, {
		method: 'POST',
		headers: {
		"Content-Type": "application/json",
		Authorization: "Bearer " + token,
		},

		body: JSON.stringify(payload),
		});
		const responseData = await response.json();

		if (!response.ok) {
			const error = new Error(responseData.message || "Failed to get Purchase Orders");
			throw error;
		}
		// console.log("setPurchaseReceiptReference>>>", responseData.data);
		context.commit("setPurchaseReceiptReference", responseData.data);
	},
  
};
