export default {
  setCreditNotes(state, payload) {
    state.creditNotes = payload.data;
    state.totalPage = payload.page.pageCount;
    state.currentPage = payload.page.currentPage;
  },
  setSelectedCreditNote(state, payload) {
    state.selectedCreditNote = payload;
  },
  setCreditNoteReferenceDocument(state, payload){
    state.creditNoteReferenceDocument = payload
  },
  setBlob(state, payload){        
    state.blob = payload
  },
};
