export default({
    validateBlank: "':field' cannot be blank.",
    validateDuplicateInFile: "':field' must not be duplicate in the file.", 
    validateDuplicateInDatabase: "':field' duplicate ':table' master data.", 
    validateLength : "':field' specify length not exceeding :length characters.", 
    validateDataNotFound: "':field' data is not found",
    validateDateFormat: "':field' invalid date format 'DD/MM/YYYY (A.D.)'.",
    validateDecimalFormat: "':field' invalid decimal format '###.##'.",
    validateType: "':field' invalid data type.",
    validateAddfile: "Please add file",
    validateIncompleteData: "':field' Incomplete Data",
    validateAddress: "Address information is inconsistent.",
    validateImportMaxColumn: "Cannot import data because data not equal to :maxColumn column.",  
    validateInactive: "':field' is inActive.",
})