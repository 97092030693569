export default {
  formTypes(state) {
    return state.formTypes;
  },
  selectedFormType(state){
    return state.selectedFormType;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
};
