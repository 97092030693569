export default {
	meters(state) {
		return state.meters;
	},
	selectedMeter(state) {
		return state.selectedMeter;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	product(state) {
		return state.product;
	},
	meterForAutoCreateByProject(state) {
		return state.meterForAutoCreateByProject;
	},
	totalPageForAutoCreate(state) {
		return state.totalPageForAutoCreate;
	},
	currentPageForAutoCreate(state) {
		return state.currentPageForAutoCreate;
	},
	meterForAutoCreateNextPage(state) {
		return state.currentPageForAutoCreate + 1;
	},
	meterForAutoCreateIsLastPage(_, getters) {
		// return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
		if (getters.currentPageForAutoCreate > 0 && getters.totalPageForAutoCreate === 0) {
			return true;
		} else {
			return (
				getters.currentPageForAutoCreate === getters.totalPageForAutoCreate &&
				getters.totalPageForAutoCreate !== 0
			);
		}
	},
};
