export default({
    title:"รายการใบแจ้งหนี้",
    titleRefdocument:"เอกสารอ้างอิง",
    columnDate: "วันที่เอกสาร",
    columnCode: "เลขที่เอกสาร",
    columnUnit: "ยูนิต",
    columnAddress:"บ้านเลขที่",
    columnCustomerName: "ชื่อลูกค้า",
    columnPrice:"ราคา",
    columnIsPaid:"สถานะการชำระ",
    columnIsCancel:"ยกเลิก",
    columnPostStep:"พิมพ์/ลงบัญชี",
    columnCreateBy:"ผู้สร้าง",
    columnCreateDate:"วันที่สร้าง",
    columnUpdateBy:"ผู้แก้ไข",
    columnUpdateDate:"วันที่แก้ไขล่าสุด",    
    columnTotalAmount: "ยอดรวม",
    columnQuantity: "หน่วย",
    columnPeriod: "งวด",
    customerSearch:"รหัสหรือชื่อลูกค้า",
    unPaidOnly:"เฉพาะค้างจ่าย",
    unPaidOptions: [
        { name: "ใช่", code: "A1" },
        { name: "ไม่ใช่", code: "A2" }
      ],
    createInvoice: "สร้างใบแจ้งหนี้",
    sendNotification: "ส่งแจ้งเตือน",
    printInvoice: "พิมพ์เป็นชุด",
    date: "ช่วงวันที่ตามปี ค.ศ.",
    code: "เลขที่เอกสาร",
    unit: "ยูนิต",
    address: "บ้านเลขที่",
    customerName: "ชื่อลูกค้า",
    price: "ราคา",
    unlockDocument: "ปลดล็อกเอกสาร",
    referenceDocument: "เอกสารที่อ้างถึง",    
	  validateNumber: "กรุณาระบุเป็นตัวเลขเท่านั้น",     
    contactNumber: "เบอร์ติดต่อ",
    remark1: "หมายเหตุ (1)",
    remark2: "หมายเหตุ (2)",
    fullPayment: "จ่ายเต็มจำนวน",
    splitRefund: "แบ่งชำระ",
    printed: "พิมพ์แล้ว",
    accRecorded: "ลงบัญชี",
    askForUnLockInvoice: "คุณต้องการปลดล็อกเอกสาร เพื่อแก้ไขรายละเอียดใบแจ้งหนี้นี้หรือไม่?",
    askForUnLockSuccess: "คุณปลดล็อกเอกสารสำเร็จ",
    documentPaid: "เอกสาร มีการชำระแล้ว",
    documentAccRecorded: "เอกสาร ลงบัญชีแล้ว",
    documentCancel: "เอกสาร ถูกยกเลิกไปแล้ว",
    toastPrintLotTitle: "ระบบกำลังประมวลผลคำขอของคุณ...",
    toastPrintLotContent: "คุณสามารถดูรายละเอียดคำขอได้ที่ ",
    toastPrintLotLink: "หน้ารายการคำขอ",
    validateSearchText:"กรุณาระบุคำที่ต้องการค้นหา",
})