export default {
  customerCustomers(state) {
    return state.customer.customers;
  },
  customerCurrentPage(state) {
    return state.customer.currentPage;
  },
  customerNextPage(state) {
    return state.customer.currentPage + 1;
  },
  customerTotalPage(state) {
    return state.customer.totalPage;
  },
  customerIsLastPage(_, getters) {
    return getters.customerCurrentPage === getters.customerTotalPage && getters.customerTotalPage !== 0;
  },
  customerCustomerGroups(state) {
    return state.customer.customerGroups;
  },
  customerGroupCurrentPage(state) {
    return state.customer.customerGroupCurrentPage;
  },
  customerGroupNextPage(state) {
    return state.customer.customerGroupCurrentPage + 1;
  },
  customerGroupTotalPage(state) {
    return state.customer.customerGroupTotalPage;
  },
  customerGroupIsLastPage(_, getters) {
    return getters.customerGroupCurrentPage === getters.customerGroupTotalPage && getters.customerGroupTotalPage !== 0;
  },
};
