export default {
	username: "ชื่อผู้ใช้",
	password: "รหัสผ่าน",
	signIn: "เข้าสู่ระบบ",
	yes: "ใช่",
	no: "ไม่",
	close: "ปิด",
	ok: "ตกลง",
	baseError: "ขออภัย มีบางอย่างผิดพลาด",
	notfound: "เราคิดว่าคุณกำลังไปยัง page ที่ไม่มีอยู่",
	notfoundButton: "กลับหน้าหลัก",
	dateStart: "วันที่เริ่ม",
	dateEnd: "วันที่สิ้นสุด",
	codeStart: "เลขที่เริ่มต้น",
	codeEnd: "เลขที่สิ้นสุด",
	search: "ค้นหา",
	titleLanguage: "เปลี่ยนภาษา",
	language: "ภาษาที่แสดงผล",
	keywordSearch: "ระบุคำค้นหา",
	searchUnit: "ค้นหายูนิต",
	page: "หน้า",
	hideParameters: "ซ่อนตัวเลือก",
	showParameters: "แสดงตัวเลือก",
	date: "วันที่",
	code: "รหัส",
	dueDate: "วันครบกำหนด",
	customerName: "ชื่อลูกค้า",
	customerMobile: "เบอร์โทรศัพท์",
	createBy: "ชื่อผู้สร้าง",
	updateBy: "ชื่อผู้แก้ไขล่าสุด",
	productCode: "รหัสรายรับ",
	productName: "ชื่อรายรับ",
	itemNo: "ลำดับ",
	description: "รายละเอียด",
	period: "งวด",
	unitPrice: "ราคาต่อหน่วย",
	quantity: "จำนวน",
	totalAmount: "รวมเงิน",
	paidAmount: "ชำระ",
	unit: "ยูนิต",
	price: "ราคา",
	livingType: "ประเภทการอยู่อาศัย",
	allFilter: "ทั้งหมด",

	back: "กลับ",
	exit: "ออก",
	confirmDialogHeader: "ข้อความยืนยัน",
	create: "สร้างเอกสารใหม่",
	save: "บันทึก",
	saveAll: "บันทึกทั้งหมด",
	saveAllSucess: "บันทึกรายการสำเร็จ",
	print: "พิมพ์",
	preview: "ดูตัวอย่าง",
	export: "ส่งออก",
	printPdf: "ดาวน์โหลด PDF",
	cancel: "ยกเลิก",
	request: "ส่งคำขอ",
	askForCreateNewDocument: "คุณยืนยันที่จะละทิ้งการแก้ไข และสร้างเอกสารใหม่ใช่หรือไม่?",
	askForCancelDocument: "คุณยืนยันที่จะยกเลิกเอกสารนี้ ใช่หรือไม่?",
	askForNonExistPeriod: "งวดที่ระบุ :period ไม่ถูกต้อง ระบบจะใช้งวดปัจจุบันแทน",
	validateNoUnitSelect: "กรุณาเลือกห้อง",
	notificationDialogHeader: "ข้อความแจ้งเตือน",
	remark: "หมายเหตุ",
	remark1: "หมายเหตุ 1",
	remark2: "หมายเหตุ 2",
	saveSuccess: "บันทึกสำเร็จ",
	confirm: "ตกลง",
	cancelSuccess: "ยกเลิกสำเร็จ",
	askRevertGeneralLedger: "คุณยืนยันที่จะกลับรายการเอกสารนี้ ใช่หรือไม่?",
	askForDeleteDocument: "คุณยืนยันที่จะยกเลิกความสัมพันธ์นี้ ใช่หรือไม่?",
	askForDeleteDocumentAll: "คุณยืนยันที่จะยกเลิกความสัมพันธ์ทั้งหมดนี้ ใช่หรือไม่?",
	successfully: "ทำรายการสำเร็จ",
	askForRemoveDocument: "คุณยืนยันที่จะนำเอกสารออกทั้งหมด ใช่หรือไม่?",

	type: "ประเภท",
	status: "สถานะ",
	validateNoSupplierSelect: "กรุณาเลือกผู้จัดจำหน่าย",
	validatePrintPermission: "คุณไม่มีสิทธิ์พิมพ์เอกสาร",
	validateNoTaxBookSelect: "กรุณาเลือกเล่มภาษี",
	validateNoEmployeeSelect: "กรุณาเลือกพนักงาน",
	validateNoDetailSelect: "กรุณาเลือกรายการ",
	validateNoTaxSelect: "กรุณาเลือกภาษี",

	askForProductChange: "คุณยืนยันที่จะแก้ไขรหัสสินค้าใช่หรือไม่?",
	askForSelectProduct: "ไม่พบรหัสสินค้า : :code ต้องการค้นหาหรือไม่?",
	askForApproveDocument: "คุณยืนยันที่จะอนุมัติเอกสารนี้ ใช่หรือไม่?",
	approveSuccess: "อนุมัติสำเร็จ",

	saveAs: "บันทึกเป็น",
	askForSaveAs: "คุณยืนยันที่จะคัดลอกเอกสารนี้ ใช่หรือไม่?",
	saveAsSuccess: "คัดลอกสำเร็จ",

	validateRoleInsert: "คุณไม่มีสิทธิ์ สร้างเอกสาร",
	validateRoleUpdate: "คุณไม่มีสิทธิ์ แก้ไขเอกสาร",
	validateRoleCancel: "คุณไม่มีสิทธิ์ ยกเลิกเอกสาร",
	validateRoleApprove: "คุณไม่มีสิทธิ์ อนุมัติเอกสาร",
	validateRoleDelete: "คุณไม่มีสิทธิ์ ลบข้อมูล",
	validateRoleClose: "คุณไม่มีสิทธิ์ ปิดใบสั่งซื้อ",
	validateRoleSelect: "คุณไม่มีสิทธิ์ เข้าถึงเอกสาร",
	validateRoleImport: "คุณไม่มีสิทธิ์ นำเข้าเอกสาร",

	editCaption: "แก้ไขข้อมูล",
	saveCaption: "บันทึก",
	createCaption: "เพิ่มรายการ",
	reportCaption: "รายงาน",
	printCaption: "พิมพ์",
	confirmEditSave: "ยืนยันการแก้ไข",
	askForEditDocument: "คุณต้องการยืนยันการแก้ไข:titleใช่หรือไม่?",

	showhideParameters: "แสดง/ซ่อน",
	saveEditSuccess: "แก้ไขข้อมูลสำเร็จ",
	validategoBackTitle: "ละทิ้งการแก้ไข",
	validategoBackEdit: "คุณต้องการละทิ้งแก้ไข:titleใช่หรือไม่?",
	titleConfirmDelete: "ยืนยันลบข้อมูล",
	askForDeleteDataDetail: "คุณต้องการลบข้อมูล:titleใช่หรือไม่?",
	deleteSuccess: "ลบข้อมูลสำเร็จ",
	askconfirmData: "คุณต้องการยืนยัน:titleใช่หรือไม่?",
	pageDirtyConfirmMessage: "คุณต้องการละทิ้งการแก้ไขใช่หรือไม่?",//"ดูเหมือนคุณกำลังแก้ไขเอกสารอยู่ ต้องการออกจากหน้านี้จริงๆ หรือไม่?",
	referenceDocument: "ความสัมพันธ์",

	searchProject: "ค้นหาโครงการ",
	confirmChangeProjectMessage: "ยืนยันการเปลี่ยนโครงการ",
	changeProjectMessage: "คุณแน่ใจที่จะเปลี่ยนโครงการ ใช่หรือไม่?",
	dataNotFound: "ไม่พบข้อมูล",
	cancelDocument: "ยกเลิกเอกสาร",
	tableEmpty: "ไม่มีรายการ",

	buttonDialogSuccess: "ตกลง",
	buttonDialogConfirm: "ยืนยัน",
	buttonDialogCancel: "ยกเลิก",
	validateSearchText: "กรุณาระบุคำที่ต้องการค้นหา",
	createNew: "สร้างใหม่",
	printLot: "พิมพ์เป็นชุด",
	documentDate: "วันที่เอกสาร",
	address: "ที่อยู่",
	saveUnsuccess: "บันทึกไม่สำเร็จ",
	delete: "ลบ",
	documentCode: "เลขที่เอกสาร",
	toastRequestTitle: "ระบบกำลังประมวลผลคำขอของคุณ...",
	toastRequestContent: "คุณสามารถดูรายละเอียดคำขอได้ที่ ",
	toastRequestLink: "หน้ารายการคำขอ",
	searchTextPlaceholder: "กรอกข้อมูลเพื่อค้นหา",
	copy: "คัดลอก",
	allData: "ทั้งหมด",
	active: "ใช้งาน",
	inActive: "ไม่ใช้งาน",

	createText: "สร้าง",
	edit: "แก้ไข",
	selectProject: "เลือกโครงการ",
	import: "นำเข้า",
	uploadFile: "อัปโหลดไฟล์",

	validateSaveTitle: "ไม่สามารถบันทึกรายการได้",
	validateDeleteTitle: "ไม่สามารถลบรายการได้",
	validateFieldNoValue: "เนื่องจากคุณยังไม่ระบุข้อมูลในช่อง ",
	validateDropDownNoSelect: "เนื่องจากคุณยังไม่เลือกข้อมูล ",
	validateDetail: "กรุณาเพิ่มรายการ",
	validateFomatInvalid: "เนื่องจากคุณระบุข้อมูล:field ไม่ถูกต้อง",
	validateCancelled: "เอกสารนี้ถูกยกเลิกแล้ว",
	validateReferenceDocument: "เอกสารนี้มีเอกสารอ้างถึง",
	validateReferenceVoucher: "เอกสารนี้ถูกนำไปลงบัญชีแล้ว",
	validatePrinted: "เอกสารนี้ถูกพิมพ์แล้ว",

	documentIsLockedTitle: "สมุดบัญชีของเอกสารนี้ถูกล็อกอยู่",
	documentIsLockDetail: "คุณไม่สามารถแก้ไขหรือเปลี่ยนสถานะเอกสารนี้ได้<br>เนื่องจากสมุดบัญชีของเอกสารนี้ถูกล็อกอยู่",
	updateDate: "วันที่แก้ไข",

	downloadfile: "ดาวน์โหลดตัวอย่างไฟล์",
	askForNewImport: "ท่านต้องการอัปโหลดรายการใหม่แทนที่รายการเดิมหรือไม่",
	statusImport: "สถานะการนำเข้า",
	reason: "เหตุผล",
	reupload: "อัปโหลดใหม่",
	importAll: "นำเข้าทั้งหมด (:value)",
	allStatus: "ทั้งหมด",
	wait: "รอบันทึก",
	success: "สร้างสำเร็จ",
	invalid: "ค่าไม่ถูกต้อง",
	error: "สร้างไม่สำเร็จ",
	generating: "กำลังสร้าง...",
	statusContent: ":number รายการ",
	mobileNumber: "เบอร์โทรศัพท์",
	noItemToImportWording: "ไม่สามารถบันทึกได้ เนื่องจากไม่มีรายการรอบันทึก",
	askForNextStep: "ท่านยืนยันที่จะดำเนินการต่อหรือไม่",
	cannotImportWording: "ไม่สามารถนำเข้า",
	listCannotImport: "รายการที่ไม่สามารถนำเข้า",
	noItemToExportWording: "ไม่สามารถส่งออกได้ เนื่องจากไม่มีรายการที่ไม่สามารถนำเข้า",
	subdistrict: "แขวง/ตำบล",
	district: "เขต/อำเภอ",
	province: "จังหวัด",
	postcode: "รหัสไปรษณีย์",
	countryCode: "รหัสประเทศ",
	prefix: "คำนำหน้า",
	contactName: "ชื่อ - ผู้ติดต่อ",
	contactCountryCode: "รหัสประเทศ - ผู้ติดต่อ",
	contactMobile: "เบอร์โทรศัพท์- ผู้ติดต่อ",
	subdistrictPlaceholder: "ระบุ แขวง/ตำบล",
	districtPlaceholder: "ระบุ เขต/อำเภอ",
	provincePlaceholder: "ระบุ จังหวัด",
	postcodePlaceholder: "ระบุ รหัสไปรษณีย์",

	validateSubdistrict: "กรุณาระบุแขวง/ตำบล",
	validateDistrict: "กรุณาระบุเขต/อำเภอ",
	validateProvince: "กรุณาระบุจังหวัด",
	validatePostcode: "กรุณาระบุรหัสไปรษณีย์",
	
	generalInformation: "ข้อมูลทั่วไป",
	reportRequestConfirmMessage: "คุณได้มีการเรียกรายงานดังกล่าวแล้ว และอยู่ในระหว่างรอคิว | ต้องการที่จะเรียกซ้ำหรือไม่",
    invalidAddress: "ข้อมูลที่อยู่ไม่สมบูรณ์",
	inconsistentAddress: "ข้อมูลที่อยู่ไม่สอดคล้องกัน",
	contactInformation: "ข้อมูลติดต่อ",
	notShowAgainToday: "ไม่ต้องแสดงอีกในวันนี้",
	defaultFormat: "รูปแบบมาตรฐาน",
};
