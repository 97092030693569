<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoCreate="askForCreate"
    @gotoDelete="askForDelete"
    @gotoPrint="checkBeforePrint"
    @gotoSave="checkBeforeSave"
  >
  </base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="showDialog"
      :titleConfirm="baseDialogTitle"
      :classTitle="baseDialogClassTitle"
      :wordingConfirm="baseDialogContent"
      :dataButtons="dataButtonDialogs"
      @close="closeBaseDialog"
      @goto-detail="gotoDetail"
      @goto-delete="deleteNoticeCutomReport"
      @goto-list="goBack"
      @goto-create="createNewDocument"
      @try-save="trySave"
    ></base-dialog>
    <notice-product-dialog
      v-model:visible="showNoticeProductDialog"
      :isOpen="showNoticeProductDialog"
      :detailProducts="details"
      @close="showNoticeProductDialog = false"
      @product-selected="productSelected"
      :key="keyToReLoad"
    ></notice-product-dialog>

    <base-crop-image-dialog
      v-model:visible="showCropImageDialog"
      :width="imageWidth"
      :height="imageHeight"
      :image="image"
      @close="showCropImageDialog = false"
      @confirm-crop="cropImageDialog"
    ></base-crop-image-dialog>

    <div class="surface-card shadow-2 p-5 border-round mt-5" :class="textFormClass">
      <div class="flex justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.containerTitle }}</label>
      </div>

      <PvDivider class="mb-3"></PvDivider>

      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
        <label>{{ lang.labelName }}*</label>
        <div class="mt-1">
          <PvInputText
            type="text"
            class="inputfield w-full mt-1"
            v-model="name"
            :placeholder="lang.placeholderName"
            :class="{ 'p-invalid': nameError }"
            @keyup="nameError = false"
          />
          <small v-if="nameError" id="name-help" class="p-error">
            {{ lang.validateName }}
          </small>
        </div>
      </div>

      <PVTabView v-model:activeIndex="indexTabView" @tab-click="validateDetailIsNotNull()">
        <PVTabPanel>
          <template #header>
            <span>{{ lang.tabManageProduct }} </span>
          </template>
        </PVTabPanel>
        <PVTabPanel>
          <template #header>
            <span>{{ lang.tabManageReport }} </span>
          </template>
        </PVTabPanel>
      </PVTabView>
      <div v-show="indexTabView === 0">
        <div class="lg:col-4 md:col-6 col-12 pl-0 mt-2">
          <label for="isGroupProduct">{{ lang.labelIsGroupProduct }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="itemIsGroupProducts"
            optionLabel="name"
            optionValue="code"
            v-model="selectedIsGroupProduct"
          />
        </div>

        <div class="justify-content-between align-items-center flex flex-wrap p-0 pb-1 mt-2 pt-1">
          <label class="font-lg font-normal">{{ lang.labelManageProduct }}</label>
          <PvButton class="p-button-outlined" @click="addProduct">
            <icon-plus-add iconColor="primary" height="16" width="16" />
            <span class="font-normal pl-1">{{ lang.addProduct }}</span>
          </PvButton>
        </div>

        <div v-if="detailError" class="pb-2 mt-2">
          <PvInlineMessage severity="error" class="w-full justify-content-start">{{
            lang.validateDetail
          }}</PvInlineMessage>
        </div>

        <div class="mt-2">
          <PvDataTable
            :value="details"
            selectionMode="single"
            dataKey="id"
            responsiveLayout="scroll"
            class="p-datatable-xs"
            :class="textTableClass"
            scrollable
          >
            <PvColumn
              field="itemNumber"
              :header="lang.itemNo"
              style="max-width: 5rem; height: 56px"
              headerClass="h-left"
              bodyClass="text-left"
            />
            <PvColumn
              field="productCode"
              :header="lang.code"
              style="max-width: 15rem; height: 56px"
              headerClass="h-left"
              bodyClass="text-left"
              sortable
            />
            <PvColumn
              field="productNameTh"
              :header="lang.productName"
              style="min-width: 30rem; height: 56px"
              headerClass="h-left"
              bodyClass="text-left"
              sortable
            />
            <PvColumn
              field="productCode"
              :header="lang.columnIsGroupProduct"
              style="min-width: 5rem; height: 56px"
              headerClass="h-left"
              bodyClass="text-left"
            >
              <template #body="{ data, field }">
                <base-tag
                  v-if="this.selectedIsGroupProduct == 'Y' || data[field].charAt(0).toUpperCase() == 'Z'"
                  :Caption="lang.tagGroup"
                  Class="primary"
                />
                <base-tag
                  v-if="this.selectedIsGroupProduct == 'N' && data[field].charAt(0).toUpperCase() != 'Z'"
                  :Caption="lang.tagUngroup"
                  Class="secondary"
                />
                <i
                  v-if="data[field].charAt(0).toUpperCase() == 'Z'"
                  style="cursor: pointer"
                  class="pi pi-info-circle p-text-secondary ml-2"
                  v-tooltip.right="{
                    value: lang.tooltipPenaltyProduct,
                    class: 'custom-tooltip',
                  }"
                ></i>
              </template>
            </PvColumn>
            <PvColumn
              style="max-width: 6rem"
              headerClass="h-center"
              bodyStyle="text-align: center; justify-content: center; align-items: center;"
              alignFrozen="right"
              frozen
            >
              <template #body="slotProps">
                <PvButton
                  type="button"
                  class="p-button-outlined p-button-danger p-button-text p-0 mr-3"
                  @click="deleteDetail(slotProps.data.id)"
                >
                  <icon-trash-delete-bin iconColor="error" width="24" height="24" />
                </PvButton>
              </template>
            </PvColumn>

            <template #empty>
              <div class="w-full p-3" style="text-align: center">
                <label>{{ this.lang.tableEmpty }}</label>
              </div>
            </template>
          </PvDataTable>
        </div>
      </div>
      <div v-show="indexTabView === 1">
        <!-- ตัวเลือกวันที่ระงับ -->
        <div class="lg:col-12 md:col-12 col-12 pl-0 mt-3">
          <PvCheckbox id="isSuspend" v-model="selectedIsSuspend" :binary="true" />
          <label class="font-normal pl-2">{{ lang.labelIsSuspend }}</label>
        </div>

        <PvDivider></PvDivider>

        <!-- โลโก้ -->
        <div class="lg:col-12 md:col-12 col-12 pl-0 mt-2">
          <PvCheckbox id="isDisplayLogo" v-model="selectedIsDisplayLogo" :binary="true" @change="resetlogoUrlError" />
          <label class="font-normal pl-2">{{ lang.labelIsDisplayLogo }}</label>
          <base-tooltip-image
            :urlImage="tooltipImageUrl.imageLogoUrl"
            :textHeader="lang.tooltipDisplayLogoHeader"
            :textFooter="lang.tooltipExampleFooter"
          />
          <div class="mt-1">
            <small class="font-normal p-text-secondary">{{ lang.smallDisplayLogoInfo1 }}</small>
          </div>
        </div>

        <div v-if="selectedIsDisplayLogo">
          <div class="lg:col-12 md:col-12 col-12 pl-0 mt-1">
            <label class="font-normal">{{ lang.labelDisplayLogo }}*</label>
            <div class="mt-1">
              <small class="font-normal p-text-secondary">{{ lang.smallDisplayLogoInfo2 }}</small>
            </div>
          </div>

          <PvButton v-if="!logoImageBase64" class="p-button-primary" @click="openFileDialog('imageLogo')">
            <icon-cloud-storage-upload iconColor="white" width="16" height="16" />
            <label class="p-button-label ml-1">{{ lang.uploadFile }}</label>
          </PvButton>
          <input
            type="file"
            id="add-file"
            style="display: none"
            ref="fileInput"
            accept="image/jpeg, image/jpg, image/png"
            @change="handleFileChange"
            @click="resetFile"
          />
          <base-image-preview
            v-if="logoImageBase64"
            :imageUrl="logoImageBase64"
            @delete-image="deleteImageLogo('imageLogo')"
          />
        </div>
        <div>
          <small v-if="logoUrlError" id="logo-url-help" class="p-error">
            {{ lang.validateImage }}
          </small>
        </div>

        <PvDivider></PvDivider>

        <!-- เรื่อง -->
        <div class="lg:col-12 md:col-12 col-12 pl-0 mt-2">
          <label class="font-normal pl-0">{{ lang.labelSubject }}</label>
          <base-tooltip-image
            :urlImage="tooltipImageUrl.imageSubjectUrl"
            :textHeader="lang.tooltipSubject"
            :textFooter="lang.tooltipExampleFooter"
          />
        </div>

        <div class="flex flex-wrap fontlabel">
          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pr-3">
            <editor-markup
              :label="lang.labelEditorSubjectTh"
              :limit="100"
              fieldName="subjectTh"
              :defaultValue="`${subjectTh}`"
              @editor-change="setDataMarkup"
              :key="keyToReLoad"
            />
          </div>

          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pl-3">
            <editor-markup
              :label="lang.labelEditorSubjectEn"
              :limit="100"
              fieldName="subjectEn"
              :defaultValue="`${subjectEn}`"
              @editor-change="setDataMarkup"
              :key="keyToReLoad"
            />
          </div>
        </div>

        <PvDivider></PvDivider>

        <!-- ชื่อผู้รับ -->
        <div class="lg:col-12 md:col-12 col-12 pl-0 mt-2">
          <label class="font-normal pl-0">{{ lang.labelTo }}</label>
          <base-tooltip-image
            :urlImage="tooltipImageUrl.imageToUrl"
            :textHeader="lang.tooltipTo"
            :textFooter="lang.tooltipExampleFooter"
          />
        </div>

        <div class="flex flex-wrap fontlabel">
          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pr-3">
            <editor-markup
              :label="lang.labelToTh"
              :limit="100"
              fieldName="toTh"
              :defaultValue="`${toTh}`"
              @editor-change="setDataMarkup"
              :isError="false"
              :key="keyToReLoad"
            />
          </div>

          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pl-3">
            <editor-markup
              :label="lang.labelToEn"
              :limit="100"
              fieldName="toEn"
              :defaultValue="`${toEn}`"
              @editor-change="setDataMarkup"
              :isError="false"
              :key="keyToReLoad"
            />
          </div>
        </div>

        <PvDivider></PvDivider>

        <!-- ที่อยู่ -->
        <div class="lg:col-12 md:col-12 col-12 pl-0 mt-2">
          <label class="font-normal pl-0">{{ lang.labelAddress }}</label>
          <base-tooltip-image
            :urlImage="tooltipImageUrl.imageAddressUrl"
            :textHeader="lang.tooltipAddress"
            :textFooter="lang.tooltipExampleFooter"
          />
        </div>

        <div class="flex flex-wrap fontlabel">
          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pr-3">
            <editor-markup
              :label="lang.labelAddressTh"
              :limit="100"
              fieldName="addressTh"
              :defaultValue="`${addressTh}`"
              @editor-change="setDataMarkup"
              :isError="false"
              :key="keyToReLoad"
            />
          </div>

          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pl-3">
            <editor-markup
              :label="lang.labelAddressEn"
              :limit="100"
              fieldName="addressEn"
              :defaultValue="`${addressEn}`"
              @editor-change="setDataMarkup"
              :isError="false"
              :key="keyToReLoad"
            />
          </div>
        </div>

        <PvDivider></PvDivider>

        <!-- ย่อหน้าที่ 1 -->
        <div class="lg:col-12 md:col-12 col-12 pl-0 mt-2">
          <label class="font-normal pl-0">{{ lang.labelContentHeader }}</label>
          <base-tooltip-image
            :urlImage="tooltipImageUrl.imageContentHeaderUrl"
            :textHeader="lang.tooltipContentHeader"
            :textFooter="lang.tooltipExampleFooter"
          />
        </div>

        <div class="flex flex-wrap fontlabel">
          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pr-3">
            <editor-markup
              :label="lang.labelContentHeaderTh"
              :limit="1000"
              height="193"
              fieldName="contentHeaderTh"
              :defaultValue="`${contentHeaderTh}`"
              @editor-change="setDataMarkup"
              :isError="false"
              :key="keyToReLoad"
            />
          </div>

          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pl-3">
            <editor-markup
              :label="lang.labelContentHeaderEn"
              :limit="1000"
              height="193"
              fieldName="contentHeaderEn"
              :defaultValue="`${contentHeaderEn}`"
              @editor-change="setDataMarkup"
              :isError="false"
              :key="keyToReLoad"
            />
          </div>
        </div>

        <PvDivider></PvDivider>

        <!-- ย่อหน้าที่ 2 -->
        <div class="lg:col-12 md:col-12 col-12 pl-0 mt-2">
          <PvCheckbox
            id="isDisplayContentFooter"
            v-model="selectedIsDisplayContentFooter"
            :binary="true"
            @change="resetContentFooterError"
          />
          <label class="font-normal pl-2">{{ lang.labelContentFooter }}</label>
          <base-tooltip-image
            :urlImage="tooltipImageUrl.imageContentFooterUrl"
            :textHeader="lang.tooltipContentFootert"
            :textFooter="lang.tooltipExampleFooter"
          />
        </div>

        <div v-if="selectedIsDisplayContentFooter" class="flex flex-wrap fontlabel">
          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pr-3">
            <editor-markup
              :label="lang.labelContentFooterTh"
              :limit="1000"
              height="193"
              fieldName="contentFooterTh"
              :defaultValue="`${contentFooterTh}`"
              @editor-change="setDataMarkup"
              :isError="contentFooterThError"
              :key="keyToReLoad"
            />
            <small v-if="contentFooterThError" id="content-footer-th-help" class="p-error">
              {{ lang.validateContentFooterTh }}
            </small>
          </div>

          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pl-3">
            <editor-markup
              :label="lang.labelContentFooterEn"
              :limit="1000"
              height="193"
              fieldName="contentFooterEn"
              :defaultValue="`${contentFooterEn}`"
              @editor-change="setDataMarkup"
              :isError="contentFooterEnError"
              :key="keyToReLoad"
            />
            <small v-if="contentFooterEnError" id="content-footer-en-help" class="p-error">
              {{ lang.validateContentFooterEn }}
            </small>
          </div>
        </div>

        <PvDivider></PvDivider>

        <!-- ลายเซ็นผู้ออกเอกสาร -->
        <div class="lg:col-12 md:col-12 col-12 pl-0 mt-2">
          <PvCheckbox
            id="isDisplaySignatureFullName"
            v-model="selectedIsDisplaySignature"
            :binary="true"
            @change="resetSignatureError"
          />
          <label class="font-normal pl-2">{{ lang.labelSignatureUrl }}</label>
          <base-tooltip-image
            :urlImage="tooltipImageUrl.imageSignatureUrl"
            :textHeader="lang.tooltipSignature"
            :textFooter="lang.tooltipExampleFooter"
          />
        </div>
        <div v-if="selectedIsDisplaySignature">
          <div class="lg:col-4 md:col-6 col-12 pl-0 mt-2">
            <label for="isGroupProduct">{{ lang.labelIsSameSignatureOnBothLang }}</label>
            <PvDropdown
              class="inputfield w-full mt-1"
              :options="itemIsSameSignatureOnBothLangs"
              optionLabel="name"
              optionValue="code"
              v-model="selectedIsSameSignatureOnBothLang"
              @change="resetSignatureError"
            />
          </div>

          <div class="flex flex-wrap fontlabel">
            <!-- รูปภาพลายเซ็นไทย -->
            <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pr-3">
              <div class="lg:col-12 md:col-12 col-12 pl-0 mt-1">
                <label class="font-normal">{{ lang.labelSignatureUrlTh }}*</label>
                <div class="mt-1">
                  <small class="font-normal p-text-secondary">{{ lang.smallSignatureUrl }}</small>
                </div>
              </div>

              <PvButton v-if="!signatureThImageBase64" class="p-button-primary" @click="openFileDialog('imageSignatureTh')">
                <icon-cloud-storage-upload iconColor="white" width="16" height="16" />
                <label class="p-button-label ml-1">{{ lang.uploadFile }}</label>
              </PvButton>
              <input
                type="file"
                id="add-file"
                style="display: none"
                ref="fileInput"
                accept="image/jpeg, image/jpg, image/png"
                @change="handleFileChange"
                @click="resetFile"
              />
              <base-image-preview
                v-if="signatureThImageBase64"
                :imageUrl="signatureThImageBase64"
                @delete-image="deleteImageLogo('imageSignatureTh')"
              />
              <div>
                <small v-if="signatureUrlThError" id="signature-url-th-help" class="p-error">
                  {{ lang.validateImage }}
                </small>
              </div>
            </div>
            <!-- รูปภาพลายเซ็นอังกฤษ -->
            <div
              v-if="selectedIsSameSignatureOnBothLang == 'N'"
              class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pl-3"
            >
              <div class="lg:col-12 md:col-12 col-12 pl-0 mt-1">
                <label class="font-normal">{{ lang.labelSignatureUrlEn }}*</label>
                <div class="mt-1">
                  <small class="font-normal p-text-secondary">{{ lang.smallSignatureUrl }}</small>
                </div>
              </div>

              <PvButton v-if="!signatureEnImageBase64" class="p-button-primary" @click="openFileDialog('imageSignatureEn')">
                <icon-cloud-storage-upload iconColor="white" width="16" height="16" />
                <label class="p-button-label ml-1">{{ lang.uploadFile }}</label>
              </PvButton>
              <input
                type="file"
                id="add-file"
                style="display: none"
                ref="fileInput"
                accept="image/jpeg, image/jpg, image/png"
                @change="handleFileChange"
                @click="resetFile"
              />
              <base-image-preview
                v-if="signatureEnImageBase64"
                :imageUrl="signatureEnImageBase64"
                @delete-image="deleteImageLogo('imageSignatureEn')"
              />
              <div>
                <small v-if="signatureUrlEnError" id="signature-url-en-help" class="p-error">
                  {{ lang.validateImage }}
                </small>
              </div>
            </div>
          </div>
        </div>
        <PvDivider></PvDivider>

        <!-- ชื่อผู้ออกเอกสาร -->
        <div class="lg:col-12 md:col-12 col-12 pl-0 mt-2">
          <PvCheckbox
            id="isDisplaySignatureFullName"
            v-model="selectedIsDisplaySignatureFullName"
            :binary="true"
            @change="resetSignatureFullNameError"
          />
          <label class="font-normal pl-2">{{ lang.labelSignatureFullName }}</label>
          <base-tooltip-image
            :urlImage="tooltipImageUrl.imageSignatureNameUrl"
            :textHeader="lang.tooltipSignatureName"
            :textFooter="lang.tooltipExampleFooter"
          />
        </div>

        <div v-if="selectedIsDisplaySignatureFullName" class="flex flex-wrap fontlabel">
          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pr-3">
            <base-input-text-limit
              :caption="`${lang.labelSignatureFullNameTh}*`"
              :data="signatureFullNameTh"
              :isError="signatureFullNameThError"
              :errorMessage="lang.validateSignatureFullNameTh"
              @data="updateSignatureFullNameTh"
            />
          </div>
          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pl-3">
            <base-input-text-limit
              :caption="`${lang.labelSignatureFullNameEn}*`"
              :data="signatureFullNameEn"
              :isError="signatureFullNameEnError"
              :errorMessage="lang.validateSignatureFullNameEn"
              @data="updateSignatureFullNameEn"
            />
          </div>
        </div>

        <PvDivider></PvDivider>

        <!-- ชื่อตำแหน่งผู้ออกเอกสาร -->
        <div class="lg:col-12 md:col-12 col-12 pl-0 mt-2">
          <PvCheckbox
            id="isDisplaySignaturePosition"
            v-model="selectedIsDisplaySignaturePosition"
            :binary="true"
            @change="resetSignaturePositionError"
          />
          <label class="font-normal pl-2">{{ lang.labelSignaturePosition }}</label>
          <base-tooltip-image
            :urlImage="tooltipImageUrl.imageSignaturePositionUrl"
            :textHeader="lang.tooltipSignaturePosition"
            :textFooter="lang.tooltipExampleFooter"
          />
        </div>

        <div v-if="selectedIsDisplaySignaturePosition" class="flex flex-wrap fontlabel">
          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pr-3">
            <base-input-text-limit
              :caption="`${lang.labelSignaturePositionTh}*`"
              :data="signaturePositionTh"
              :isError="signaturePositionThError"
              :errorMessage="lang.validateSignaturePositionTh"
              @data="updateSignaturePositionTh"
            />
          </div>
          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pl-3">
            <base-input-text-limit
              :caption="`${lang.labelSignaturePositionEn}*`"
              :data="signaturePositionEn"
              :isError="signaturePositionEnError"
              :errorMessage="lang.validateSignaturePositionEn"
              @data="updateSignaturePositionEn"
            />
          </div>
        </div>

        <PvDivider></PvDivider>

        <!-- ท้ายกระดาษ -->
        <div class="lg:col-12 md:col-12 col-12 pl-0 mt-2">
          <label class="font-normal pl-0">{{ lang.labelPageFooter }}</label>
          <base-tooltip-image
            :urlImage="tooltipImageUrl.imagePageFooterUrl"
            :textHeader="lang.tooltipPageFooter"
            :textFooter="lang.tooltipExampleFooter"
          />
        </div>

        <div class="flex flex-wrap fontlabel">
          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pr-3">
            <editor-markup
              :label="lang.labelPageFooterTh"
              :limit="1000"
              height="193"
              fieldName="pageFooterTh"
              :defaultValue="`${pageFooterTh}`"
              @editor-change="setDataMarkup"
              :isError="false"
              :key="keyToReLoad"
            />
          </div>

          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pl-3">
            <editor-markup
              :label="lang.labelPageFooterEn"
              :limit="1000"
              height="193"
              fieldName="pageFooterEn"
              :defaultValue="`${pageFooterEn}`"
              @editor-change="setDataMarkup"
              :isError="false"
              :key="keyToReLoad"
            />
          </div>
        </div>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import usePageDirty from "../../../hooks/pageDirty";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import NoticeProductDialog from "../../../components/master/notice/NoticeProductDialog.vue";
import EditorMarkup from "../../../components/AR/notice/EditorMarkup.vue";
import BaseCropImageDialog from "../../../components/UI/BaseCropImageDialog.vue";
import { useStore } from "vuex";
import { computed, inject, reactive } from "vue";
export default {
  components: {
    NoticeProductDialog,
    EditorMarkup,
    BaseCropImageDialog,
  },
  props: ["id", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textFormClass, textTableClass, textAlertClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const isHideBack = inject("isHideBack");
    const { compareObject, isCompareObject } = usePageDirty();
    const { getFormPrints, searchTextPrint, getPreviewBlob, filteredFormPrints } = useGetFormPrint();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.notice.customReportEdit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.notice.customReportEdit,
        };
      }
    });

    const tooltipImageUrl = reactive({
      imageLogoUrl: `${process.env.VUE_APP_IMAGE_NOTICE_TOOLTIP_LOGO_URL}`,
      imageSubjectUrl: `${process.env.VUE_APP_IMAGE_NOTICE_TOOLTIP_SUBJECT_URL}`,
      imageToUrl: `${process.env.VUE_APP_IMAGE_NOTICE_TOOLTIP_TO_URL}`,
      imageAddressUrl: `${process.env.VUE_APP_IMAGE_NOTICE_TOOLTIP_ADDRESS_SUBJECT_URL}`,
      imageContentHeaderUrl: `${process.env.VUE_APP_IMAGE_NOTICE_TOOLTIP_CONTENT_HEADER_SUBJECT_URL}`,
      imageContentFooterUrl: `${process.env.VUE_APP_IMAGE_NOTICE_TOOLTIP_CONTENT_FOOTER_SUBJECT_URL}`,
      imageSignatureUrl: `${process.env.VUE_APP_IMAGE_NOTICE_TOOLTIP_SIGNATURE_URL}`,
      imageSignatureNameUrl: `${process.env.VUE_APP_IMAGE_NOTICE_TOOLTIP_SIGNATURE_NAME_URL}`,
      imageSignaturePositionUrl: `${process.env.VUE_APP_IMAGE_NOTICE_TOOLTIP_SIGNATURE_POSITION_URL}`,
      imagePageFooterUrl: `${process.env.VUE_APP_IMAGE_NOTICE_TOOLTIP_PAGE_FOOTER_URL}`,
    });

    return {
      lang: mergeLang,
      textFormClass,
      textTableClass,
      textAlertClass,

      formatCurrency,
      formatDate,
      formatDateToISOString,

      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,

      compareObject,
      isCompareObject,

      isHideBack,

      tooltipImageUrl,
    };
  },
  data() {
    return {
      //dropdown
      selectedIsGroupProduct: "Y",
      itemIsGroupProducts: [
        { name: this.lang.isGroupProduct1, code: "Y" },
        { name: this.lang.isGroupProduct2, code: "N" },
      ],
      //dialog
      showDialog: false,
      baseDialogClassTitle: "",
      baseDialogTitle: this.lang.notificationDialogHeader,
      baseDialogContent: "",
      baseDialogType: "",
      errorFieldName: "",
      //dialog add product
      showNoticeProductDialog: false,
      //table
      detailError: false,
      details: [],
      //tab
      indexTabView: 0,
      // crop image dialog
      showCropImageDialog: false,
      imageWidth: 86,
      imageHeight: 48,
      image: null,
      imageTracking: "",
      //image logo
      selectedIsDisplayLogo: false,
      logoImageBase64: "",
      logoUrl: "",
      logoUrlError: false,
      //image signature
      selectedIsDisplaySignature: false,
      selectedIsSameSignatureOnBothLang: "Y",
      itemIsSameSignatureOnBothLangs: [
        { name: this.lang.isGroupProduct1, code: "Y" },
        { name: this.lang.isGroupProduct2, code: "N" },
      ],
      signatureThImageBase64: "",
      signatureUrlTh: "",
      signatureUrlThError: false,
      signatureEnImageBase64: "",
      signatureUrlEn: "",
      signatureUrlEnError: false,
      //editor point cursor
      indexOfEditor: 0,
      //general
      name: "",
      nameError: false,
      selectedIsSuspend: false,
      subjectTh: "",
      subjectEn: "",
      toTh: "{{CustomerName}}",
      toEn: "{{ECustomerName}}",
      addressTh: "ห้องชุดเลขที่ : {{UnitCode}}    บ้านเลขที่ : {{UnitAddressNo}}",
      addressEn: "Unit code : {{UnitCode}}    Address Number : {{UnitAddressNo}}",
      contentHeaderTh:
        "            ตามที่นิติบุคคลอาคารชุดฯ ได้มีหนังสือแจ้งหนี้ เพื่อขอให้ท่านชำระหนี้ ที่ค้างชำระ ให้กับนิติบุคคลอาคารฯ ตามหนังสือที่อ้างอิงถึงนั้น บัดนี้ได้ล่วงเลยมาพอสมควรแล้ว นิติบุคคลอาคารชุดฯ ยังไม่ได้รับชำระหนี้ดังกล่าวจากท่าน เป็นจำนวนเงินรวม {{TotalAmount}} บาท ({TotalAmountText}) ดังรายการต่อไปนี้",
      contentHeaderEn:
        "            This is a letter to notify you tha you have a bill needs to be paid amounting to {{TotalAmount}} THB ({ETotalAmountText}) as the following list",
      selectedIsDisplayContentFooter: true,
      contentFooterTh:
        "            จึงเรียนมาเพื่อโปรดชำระหนี้ดังกล่าวข้างต้นให้แก่นิติบุคคลอา คารชุดฯภายในวันที่ {{DueDate}} มิฉะนั้นแล้ว นิติบุคคลอาคารชุดฯอาจจำเป็นต้องใช้สิทธิตามข้อบังคับ ระงับการให้บริการส่วนรวม อาทิ การงดจ่ายน้ำประปา การระงับ สิทธิจอดรถในอาคารชุด ฯลฯ จนกว่าท่านชำระหนี้ดังกล่าวให้ครบเรียบร้อย อนึ่งหากท่านได้ชำระก่อนได้รับหนังสือฉบับนี้ นิติบุคคลอาคารชุด ใคร่ขออภัยมา ณ โอกาสนี้ ถ้ามีข้อสงสัยสอบถาม ได้ที่สำนักงานนิติบุคคลอาคารชุดฯ ในวันและเวลาทำการ",
      contentFooterEn:
        "            This outstanding balance needs to be paid on or before this date {{DueDate}} to avoid inconvenience like suspend water supply, suspend carpark keycard and etc. In addition, if you have paid before receiving this letter the juristic person apologize on this occasion. If you have any questions, kindly contact us at juristic office.",
      contentFooterThError: false,
      contentFooterEnError: false,
      selectedIsDisplaySignatureFullName: true,
      signatureFullNameTh: "",
      signatureFullNameEn: "",
      signatureFullNameThError: false,
      signatureFullNameEnError: false,
      selectedIsDisplaySignaturePosition: true,
      signaturePositionTh: "",
      signaturePositionEn: "",
      signaturePositionThError: false,
      signaturePositionEnError: false,
      pageFooterTh: "",
      pageFooterEn: "",
      keyToReLoad: 0,
      isTrySaveAndGotoPrintPreview: false,
    };
  },
  async mounted() {
    await this.initiateData();
    if (!this.isCreateDocument) {
      await this.getNoticeCutomReport();
      await this.setData();
    }
    await this.setCompareData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({ name: "notice-custom-report" });
    },
    async initiateData() {
      this.initiateBaseDialog();
      this.indexOfEditor = 0;
      this.indexTabView = 0;
      this.image = null;
      this.name = "";
      this.nameError = false;
      //tab 1
      this.selectedIsGroupProduct = "Y";
      this.details = [];
      this.detailError = false;
      //tab 2
      this.selectedIsSuspend = false;
      this.selectedIsDisplayLogo = false;
      this.logoImageBase64 = "";
      this.logoUrl = "";
      this.logoUrlError = false;
      this.subjectTh = "";
      this.subjectEn = "";
      this.toTh = "{{CustomerName}}";
      this.toEn = "{{ECustomerName}}";
      this.addressTh = "ห้องชุดเลขที่ : {{UnitCode}}    บ้านเลขที่ : {{UnitAddressNo}}";
      this.addressEn = "Unit code : {{UnitCode}}    Address Number : {{UnitAddressNo}}";
      this.contentHeaderTh =
        "            ตามที่นิติบุคคลอาคารชุดฯ ได้มีหนังสือแจ้งหนี้ เพื่อขอให้ท่านชำระหนี้ ที่ค้างชำระ ให้กับนิติบุคคลอาคารฯ ตามหนังสือที่อ้างอิงถึงนั้น บัดนี้ได้ล่วงเลยมาพอสมควรแล้ว นิติบุคคลอาคารชุดฯ ยังไม่ได้รับชำระหนี้ดังกล่าวจากท่าน เป็นจำนวนเงินรวม {{TotalAmount}} บาท {{TotalAmountText}} ดังรายการต่อไปนี้";
      this.contentHeaderEn =
        "            This is a letter to notify you tha you have a bill needs to be paid amounting to {{TotalAmount}} THB {{ETotalAmountText}} as the following list";
      this.selectedIsDisplayContentFooter = true;
      this.contentFooterTh =
        "            จึงเรียนมาเพื่อโปรดชำระหนี้ดังกล่าวข้างต้นให้แก่นิติบุคคลอา คารชุดฯภายในวันที่ {{DueDate}} มิฉะนั้นแล้ว นิติบุคคลอาคารชุดฯอาจจำเป็นต้องใช้สิทธิตามข้อบังคับ ระงับการให้บริการส่วนรวม อาทิ การงดจ่ายน้ำประปา การระงับ สิทธิจอดรถในอาคารชุด ฯลฯ จนกว่าท่านชำระหนี้ดังกล่าวให้ครบเรียบร้อย อนึ่งหากท่านได้ชำระก่อนได้รับหนังสือฉบับนี้ นิติบุคคลอาคารชุด ใคร่ขออภัยมา ณ โอกาสนี้ ถ้ามีข้อสงสัยสอบถาม ได้ที่สำนักงานนิติบุคคลอาคารชุดฯ ในวันและเวลาทำการ";
      this.contentFooterEn =
        "            This outstanding balance needs to be paid on or before this date {{DueDate}} to avoid inconvenience like suspend water supply, suspend carpark keycard and etc. In addition, if you have paid before receiving this letter the juristic person apologize on this occasion. If you have any questions, kindly contact us at juristic office.";
      this.contentFooterThError = false;
      this.contentFooterEnError = false;
      this.selectedIsDisplaySignature = false;
      this.selectedIsSameSignatureOnBothLang = "Y";
      this.signatureThImageBase64 = "";
      this.signatureUrlTh = "";
      this.signatureUrlThError = false;
      this.signatureEnImageBase64 = "";
      this.signatureUrlEn = "";
      this.signatureUrlEnError = false;
      this.selectedIsDisplaySignatureFullName = true;
      this.signatureFullNameTh = "";
      this.signatureFullNameEn = "";
      this.signatureFullNameThError = false;
      this.signatureFullNameEnError = false;
      this.selectedIsDisplaySignaturePosition = true;
      this.signaturePositionTh = "";
      this.signaturePositionEn = "";
      this.signaturePositionThError = false;
      this.signaturePositionEnError = false;
      this.pageFooterTh = "";
      this.pageFooterEn = "";
      this.keyToReLoad++;
      this.isTrySaveAndGotoPrintPreview = false;
      await this.$store.dispatch("noticeCustomReport/resetNoticeCustomReport");
    },
    async setData() {
      this.name = this.noticePrintSetting.name;
      this.selectedIsGroupProduct = this.noticePrintSetting.isGroupProduct;
      this.selectedIsSuspend = this.noticePrintSetting.isSuspend === "Y" ? true : false;
      this.selectedIsDisplayLogo = this.noticePrintSetting.isDisplayLogo === "Y" ? true : false;
      this.logoUrl = this.noticePrintSetting.logoUrl;
      this.logoImageBase64 = this.noticePrintSetting.logoUrl ? this.noticePrintSetting.logoUrl : "";
      this.subjectTh = this.noticePrintSetting.subjectTh;
      this.subjectEn = this.noticePrintSetting.subjectEn;
      this.toTh = this.noticePrintSetting.toTh;
      this.toEn = this.noticePrintSetting.toEn;
      this.addressTh = this.noticePrintSetting.addressTh;
      this.addressEn = this.noticePrintSetting.addressEn;
      this.contentHeaderTh = this.noticePrintSetting.contentHeaderTh;
      this.contentHeaderEn = this.noticePrintSetting.contentHeaderEn;
      this.selectedIsDisplayContentFooter = this.noticePrintSetting.isDisplayContentFooter === "Y" ? true : false;
      this.contentFooterTh = this.noticePrintSetting.contentFooterTh;
      this.contentFooterEn = this.noticePrintSetting.contentFooterEn;
      this.selectedIsDisplaySignature = this.noticePrintSetting.isDisplaySignature == "Y" ? true : false;
      this.selectedIsSameSignatureOnBothLang = this.noticePrintSetting.isSameSignatureOnBothLang;
      this.signatureUrlTh = this.noticePrintSetting.signatureUrlTh;
      this.signatureThImageBase64 = this.noticePrintSetting.signatureUrlTh
        ? this.noticePrintSetting.signatureUrlTh
        : "";
      this.signatureUrlEn = this.noticePrintSetting.signatureUrlEn;
      this.signatureEnImageBase64 = this.noticePrintSetting.signatureUrlEn
        ? this.noticePrintSetting.signatureUrlEn
        : "";
      this.selectedIsDisplaySignatureFullName =
        this.noticePrintSetting.isDisplaySignatureFullName === "Y" ? true : false;
      this.signatureFullNameTh = this.noticePrintSetting.signatureFullNameTh;
      this.signatureFullNameEn = this.noticePrintSetting.signatureFullNameEn;
      this.selectedIsDisplaySignaturePosition =
        this.noticePrintSetting.isDisplaySignaturePosition === "Y" ? true : false;
      this.signaturePositionTh = this.noticePrintSetting.signaturePositionTh;
      this.signaturePositionEn = this.noticePrintSetting.signaturePositionEn;
      this.pageFooterTh = this.noticePrintSetting.pageFooterTh;
      this.pageFooterEn = this.noticePrintSetting.pageFooterEn;
      this.details = this.noticePrintSetting.details;
      this.keyToReLoad++;
    },
    async setCompareData() {
      let details = [];
      for (const each of this.details.filter((each) => each.id)) {
        let detail = null;
        if (each.id.toString().includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        details.push(detail);
      }
      let objToCompare = {
        projectId: this.selectedProject.id,
        name: this.name,
        isGroupProduct: this.selectedIsGroupProduct ? this.selectedIsGroupProduct : "Y",
        isSuspend: this.selectedIsSuspend ? "Y" : "N",
        isDisplayLogo: this.selectedIsDisplayLogo ? "Y" : "N",
        logoUrl: this.logoUrl,
        subjectTh: this.subjectTh,
        subjectEn: this.subjectEn,
        toTh: this.toTh,
        toEn: this.toEn,
        addressTh: this.addressTh,
        addressEn: this.addressEn,
        contentHeaderTh: this.contentHeaderTh,
        contentHeaderEn: this.contentHeaderEn,
        isDisplayContentFooter: this.selectedIsDisplayContentFooter ? "Y" : "N",
        contentFooterTh: this.contentFooterTh,
        contentFooterEn: this.contentFooterEn,
        isDisplaySignature: this.selectedIsDisplaySignature ? "Y" : "N",
        isSameSignatureOnBothLang: this.selectedIsSameSignatureOnBothLang
          ? this.selectedIsSameSignatureOnBothLang
          : "Y",
        signatureUrlTh: this.signatureUrlTh,
        signatureUrlEn: this.signatureUrlEn,
        isDisplaySignatureFullName: this.selectedIsDisplaySignatureFullName ? "Y" : "N",
        signatureFullNameTh: this.signatureFullNameTh,
        signatureFullNameEn: this.signatureFullNameEn,
        isDisplaySignaturePosition: this.selectedIsDisplaySignaturePosition ? "Y" : "N",
        signaturePositionTh: this.signaturePositionTh,
        signaturePositionEn: this.signaturePositionEn,
        pageFooterTh: this.pageFooterTh,
        pageFooterEn: this.pageFooterEn,
        details: details,
      };
      if (this.isCreateDocument) {
        objToCompare = { ...objToCompare, type: "Add" };
      } else {
        objToCompare = { ...objToCompare, type: "Update", id: this.id };
      }
      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
    },
    initiateBaseDialog() {
      this.baseDialogClassTitle = "";
      this.baseDialogTitle = this.lang.notificationDialogHeader;
      this.baseDialogContent = "";
      this.baseDialogType = "close";
    },
    closeBaseDialog() {
      this.initiateBaseDialog();
      this.showDialog = false;
    },

    // markup manage
    openMarkupDialog(fieldName) {
      // this.indexOfEditor = 0;
      this.selectedFieldName = fieldName;
      const refsDataRange = this.$refs[fieldName].quill.selection.savedRange;
      this.indexOfEditor = refsDataRange.index;
      this.showMarkupSelectDialog = true;
    },
    // markupSelected(data) {
    //   let refsDataRoot = this.$refs[this.selectedFieldName].quill.root;
    //   if (refsDataRoot.innerText == "\n") refsDataRoot.innerText = "";
    //   if (this.selectedFieldName === "subjectTh") {
    //     refsDataRoot.innerText = this.concatStringWithIndex(refsDataRoot.innerText, data.value);
    //   }
    //   this.showMarkupSelectDialog = false;
    // },
    concatStringWithIndex(originalString, insertionText) {
      let modifiedArray = originalString.split("");
      modifiedArray.splice(this.indexOfEditor, 0, ...insertionText.split(""));
      let modifiedString = modifiedArray.join("");
      return modifiedString;
    },
    setDataMarkup(rawTextData, htmlTextData, fieldName = "") {
      switch (fieldName) {
        case "subjectTh":
          this.subjectTh = rawTextData;
          break;
        case "subjectEn":
          this.subjectEn = rawTextData;
          break;
        case "toTh":
          this.toTh = rawTextData;
          break;
        case "toEn":
          this.toEn = rawTextData;
          break;
        case "addressTh":
          this.addressTh = rawTextData;
          break;
        case "addressEn":
          this.addressEn = rawTextData;
          break;
        case "contentHeaderTh":
          this.contentHeaderTh = rawTextData;
          break;
        case "contentHeaderEn":
          this.contentHeaderEn = rawTextData;
          break;
        case "contentFooterTh":
          this.contentFooterTh = rawTextData;
          break;
        case "contentFooterEn":
          this.contentFooterEn = rawTextData;
          break;
        case "pageFooterTh":
          this.pageFooterTh = rawTextData;
          break;
        case "pageFooterEn":
          this.pageFooterEn = rawTextData;
          break;
        default:
          console.warn("Invalid fieldName:", fieldName);
      }
    },
    // markup manage

    // product manage
    addProduct() {
      this.detailError = false;
      this.showNoticeProductDialog = true;
    },
    updateItemNumber() {
      this.details.map((element, index) => {
        element.itemNumber = index + 1;
      });
    },
    productSelected(data) {
      data.forEach((product) => {
        if (product.id) {
          let detail = {
            id: "dummy" + this.details.length + 1,
            headerId: this.noticePrintSetting ? this.noticePrintSetting.id : null,
            itemNumber: 0,
            productId: product.id,
            productCode: product.code ? product.code.trim() : "",
            productNameTh: product.nameThai ? product.nameThai.trim() : "",
          };
          this.details.push(detail);
          this.details = this.details.sort((a, b) => (a.productCode < b.productCode ? -1 : 1));
          this.updateItemNumber();
        }
      });
    },
    deleteDetail(id) {
      const index = this.details.findIndex((detail) => detail.id === id);
      this.details.splice(index, 1);
      this.updateItemNumber();
    },
    // product manage

    // validate detail
    validateDetailIsNotNull() {
      if (this.indexTabView === 1 && this.countDetails === 0) {
        this.baseDialogClassTitle = "p-text-error";
        this.baseDialogTitle = this.lang.validateDetailTitle;
        this.baseDialogContent = this.lang.validateDetailContent;
        this.baseDialogType = "detail";
        this.showDialog = true;
      }
    },
    gotoDetail() {
      this.indexTabView = 0;
      this.detailError = true;
      this.showDialog = false;
    },
    // validate detail

    // create button
    async askForCreate() {
      let result = this.isCompareObject(this.objToCompare, this.payload);
      if (result) {
        this.baseDialogClassTitle = "";
        this.baseDialogTitle = this.lang.notificationDialogHeader;
        this.baseDialogContent = this.lang.pageDirtyConfirmMessage;
        this.baseDialogType = "create";
        this.showDialog = true;
      } else {
        await this.createNewDocument();
      }
    },
    async createNewDocument() {
      this.showDialog = false;
      await this.initiateData();
      await this.$router.replace({ name: "notice-custom-report-edit", params: { id: "create" } });
      await this.setCompareData();
    },
    // create button

    // delete button
    async askForDelete() {
      if (this.isCreateDocument) {
        this.baseDialogClassTitle = "p-text-error";
        this.baseDialogTitle = this.lang.validateDeleteTitle;
        this.baseDialogContent = this.lang.validateDeleteContent;
        this.baseDialogType = "close";
        this.showDialog = true;
        return;
      }
      this.baseDialogClassTitle = "p-text-error";
      this.baseDialogTitle = this.lang.titleNoticeCustomReportDeleteConfirm;
      this.baseDialogContent = this.lang.contentNoticeCustomReportDeleteConfirm;
      this.baseDialogType = "delete";
      this.showDialog = true;
    },
    // delete button

    // save button
    async checkBeforeSave() {
      this.initiateBaseDialog();
      if (this.validateSave()) {
        this.isTrySaveAndGotoPrintPreview = false;
        return;
      }
      await this.saveNoticeCutomReport();
    },
    validateSave() {
      let validateStatus = false;
      this.errorFieldName = "";
      // input text validate
      if (!this.name) {
        this.errorFieldName = this.errorFieldName || this.lang.labelName;
        this.nameError = true;
        validateStatus = true;
      }
      if (this.countDetails === 0) {
        this.detailError = true;
        this.baseDialogClassTitle = "p-text-error";
        this.baseDialogTitle = this.lang.validateSaveTitle;
        this.baseDialogContent = this.nameError ? this.lang.validateField.replace("{0}", this.errorFieldName) : this.lang.validateDetailContent;
        this.baseDialogType = this.nameError ? "close" : "detail";
        this.showDialog = true;
        this.isTrySaveAndGotoPrintPreview = false;
        return true;
      }
      // image validate
      if (this.selectedIsDisplayLogo && !this.logoImageBase64) {
        this.errorFieldName = this.errorFieldName || this.lang.labelDisplayLogo;
        this.indexTabView = 1;
        this.logoUrlError = true;
        validateStatus = true;
      }
      if (this.selectedIsDisplaySignature && !this.signatureThImageBase64) {
        this.errorFieldName = this.errorFieldName || this.lang.labelSignatureUrlTh;
        this.indexTabView = 1;
        this.signatureUrlThError = true;
        validateStatus = true;
      }
      if (
        this.selectedIsDisplaySignature &&
        this.selectedIsSameSignatureOnBothLang == "N" &&
        !this.signatureEnImageBase64
      ) {
        this.errorFieldName = this.errorFieldName || this.lang.labelSignatureUrlEn;
        this.indexTabView = 1;
        this.signatureUrlEnError = true;
        validateStatus = true;
      }
      // editor validate
      if (this.selectedIsDisplayContentFooter && !this.contentFooterTh) {
        //เมื่อ selectedIsDisplayContentFooter = checked และ contentFooterTh != ""
        this.errorFieldName = this.errorFieldName || this.lang.labelContentFooterTh;
        this.indexTabView = 1;
        this.contentFooterThError = true;
        validateStatus = true;
      }
      if (this.selectedIsDisplayContentFooter && !this.contentFooterEn) {
        //เมื่อ selectedIsDisplayContentFooter = checked และ contentFooterEn != ""
        this.errorFieldName = this.errorFieldName || this.lang.labelContentFooterEn;
        this.indexTabView = 1;
        this.contentFooterEnError = true;
        validateStatus = true;
      }
      // input text validate
      if (this.selectedIsDisplaySignatureFullName && !this.signatureFullNameTh) {
        this.errorFieldName = this.errorFieldName || this.lang.labelSignatureFullNameTh;
        this.indexTabView = 1;
        this.signatureFullNameThError = true;
        validateStatus = true;
      }
      if (this.selectedIsDisplaySignatureFullName && !this.signatureFullNameEn) {
        this.errorFieldName = this.errorFieldName || this.lang.labelSignatureFullNameEn;
        this.indexTabView = 1;
        this.signatureFullNameEnError = true;
        validateStatus = true;
      }
      if (this.selectedIsDisplaySignaturePosition && !this.signaturePositionTh) {
        this.errorFieldName = this.errorFieldName || this.lang.labelSignaturePositionTh;
        this.indexTabView = 1;
        this.signaturePositionThError = true;
        validateStatus = true;
      }
      if (this.selectedIsDisplaySignaturePosition && !this.signaturePositionEn) {
        this.errorFieldName = this.errorFieldName || this.lang.labelSignaturePositionEn;
        this.indexTabView = 1;
        this.signaturePositionEnError = true;
        validateStatus = true;
      }
      if (validateStatus) {
        this.baseDialogClassTitle = "p-text-error";
        this.baseDialogTitle = this.lang.validateSaveTitle;
        this.baseDialogContent = this.lang.validateField.replace("{0}", this.errorFieldName);
        this.baseDialogType = "close";
        this.showDialog = true;
      }
      return validateStatus;
    },
    // save button

    // print button
    async trySave() {
      this.isTrySaveAndGotoPrintPreview = true;
      await this.checkBeforeSave();
    },
    async checkBeforePrint() {
      let result = this.isCompareObject(this.objToCompare, this.payload);
      if (result) {
        this.baseDialogClassTitle = "";
        this.baseDialogTitle = this.lang.validateSaveAndPreviewTitle;
        this.baseDialogContent = this.lang.validateSaveAndPreviewContent;
        this.baseDialogType = "trySave";
        this.showDialog = true;
      } else if (this.isCreateDocument) {
        this.baseDialogClassTitle = "";
        this.baseDialogTitle = this.lang.validateSaveAndPreviewTitle;
        this.baseDialogContent = this.lang.validateSaveAndPreviewContent;
        this.baseDialogType = "trySave";
        this.showDialog = true;
      } else {
        await this.printPreview();
      }
    },
    // print button

    // image manage
    openFileDialog(imageTracking) {
      this.imageTracking = imageTracking;
      if (imageTracking === "imageLogo") {
        this.logoUrlError = false;
      } else if (imageTracking === "imageSignatureTh") {
        this.signatureUrlThError = false;
      } else if (imageTracking === "imageSignatureEn") {
        this.signatureUrlEnError = false;
      }
      this.$refs.fileInput.click();
    },
    async handleFileChange(event) {
      if (event) {
        const selectedFile = event.target.files[0];
        if (this.validateFileType(selectedFile) || this.validateFileSize(selectedFile)) {
          return;
        }
        const { files } = event.target;
        if (files && files[0]) {
          const blob = URL.createObjectURL(files[0]);
          this.image = {
            src: blob,
            type: files[0].type,
          };
        }
        this.imageWidth = 86;
        this.imageHeight = 48;
        this.showCropImageDialog = true;
      }
    },
    resetFile(event) {
      if (event.target.files.length > 0) {
        event.target.value = null;
      }
    },
    validateFileType(file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (file && !allowedTypes.includes(file.type)) {
        this.baseDialogClassTitle = "p-text-error";
        this.baseDialogTitle = this.lang.validateImageLogoTitle.replace("{0}", file.name);
        this.baseDialogContent = this.lang.validateTypeImageLogoContent;
        this.showDialog = true;
        return true;
      }
      return false;
    },
    validateFileSize(file) {
      const maxSize = 50 * 1024; // 50 KB
      if (file && file.size > maxSize) {
        this.baseDialogClassTitle = "p-text-error";
        this.baseDialogTitle = this.lang.validateImageLogoTitle.replace("{0}", file.name);
        this.baseDialogContent = this.lang.validateSizeImageLogoContent;
        this.showDialog = true;
        return true;
      }
      return false;
    },
    async cropImageDialog(imgBase64, file) {
      this.showCropImageDialog = false;
      await this.uploadFileToApi(file);
      if (file) {
        switch (this.imageTracking) {
          case "imageLogo":
            this.logoUrl = `${process.env.VUE_APP_BACKEND_URL}/Aws/inline/public/${this.selectedFile.key}`;
            this.logoImageBase64 = imgBase64;
            break;
          case "imageSignatureTh":
            this.signatureUrlTh = `${process.env.VUE_APP_BACKEND_URL}/Aws/inline/public/${this.selectedFile.key}`;
            this.signatureThImageBase64 = imgBase64;
            break;
          case "imageSignatureEn":
            this.signatureUrlEn = `${process.env.VUE_APP_BACKEND_URL}/Aws/inline/public/${this.selectedFile.key}`;
            this.signatureEnImageBase64 = imgBase64;
            break;
          default:
            console.error("Invalid file:", file);
        }
      } else {
        console.error("Both rawTextData and htmlTextData must have values.");
      }
    },
    async uploadFileToApi(file) {
      this.$store.getters["upload/resetSelectedFile"];
      try {
        await this.$store.dispatch("upload/UploadFileToS3AsPublic", { file: file });
      } catch (error) {
        this.baseDialogClassTitle = "p-text-error";
        this.baseDialogTitle = this.lang.notificationDialogHeader;
        this.baseDialogContent = error;
        this.showDialog = true;
        //this.showCropImageDialog = false;
      }
    },
    deleteImageLogo(imageTracking) {
      switch (imageTracking) {
        case "imageLogo":
          this.logoImageBase64 = "";
          break;
        case "imageSignatureTh":
          this.signatureThImageBase64 = "";
          break;
        case "imageSignatureEn":
          this.signatureEnImageBase64 = "";
          break;
        default:
          console.error("Invalid image tracking:", imageTracking);
      }
    },
    // image manage

    // call API
    async getNoticeCutomReport() {
      this.setLoading(true);
      try {
        await this.$store.dispatch("noticeCustomReport/noticeCustomReport", this.id);
        if (this.noticePrintSetting) {
          this.details = this.noticePrintSetting.details;
        }
      } catch (error) {
        this.baseDialogClassTitle = "p-text-error";
        this.baseDialogTitle = this.lang.notificationDialogHeader;
        this.baseDialogContent = error;
        this.baseDialogType = "close";
        this.showDialog = true;
        this.setLoading(false);
      } finally {
        this.setLoading(false);
      }
    },
    async saveNoticeCutomReport() {
      this.setLoading(true);
      try {
        await this.$store.dispatch("noticeCustomReport/createOrUpdateNoticeCustomReport", this.payload);
      } catch (error) {
        this.baseDialogClassTitle = "p-text-error";
        this.baseDialogTitle = this.lang.notificationDialogHeader;
        this.baseDialogContent = error;
        this.baseDialogType = "close";
        this.showDialog = true;
        this.setLoading(false);
      } finally {
        this.baseDialogClassTitle = "p-text-success";
        this.baseDialogTitle = this.lang.saveSuccess;
        this.baseDialogContent = this.lang.contentSaved;
        this.baseDialogType = "close";
        this.showDialog = true;
        if (this.isCreateDocument) {
          await this.$router.replace({ name: "notice-custom-report-edit", params: { id: this.noticePrintSetting.id } });
        }
        await this.setData();
        await this.setCompareData();
        if (this.isTrySaveAndGotoPrintPreview) {
          await this.printPreview();
        }
        this.setLoading(false);
      }
    },
    async deleteNoticeCutomReport() {
      this.setLoading(true);
      try {
        await this.$store.dispatch("noticeCustomReport/deleteNoticeCustomReport", this.id);
      } catch (error) {
        this.baseDialogClassTitle = "p-text-error";
        this.baseDialogTitle = this.lang.notificationDialogHeader;
        this.baseDialogContent = error;
        this.baseDialogType = "close";
        this.showDialog = true;
        this.setLoading(false);
      } finally {
        this.baseDialogClassTitle = "p-text-success";
        this.baseDialogTitle = this.lang.titleNoticeCustomReportDeleted;
        this.baseDialogContent = this.lang.contentNoticeCustomReportDeleted;
        this.baseDialogType = "deleted";
        this.showDialog = true;
        this.setLoading(false);
      }
    },
    async printPreview() {
      this.setLoading(true);
      try {
        let today = new Date();
        let date = this.formatDateToISOString(today);
        let duedate = new Date();
        duedate.setDate(today.getDate() + 15);
        duedate = this.formatDateToISOString(duedate);
        let parameter = {
          ProjectId: this.selectedProject.id,
          NoticePrintSettingHeaderId: this.id === "create" ? "" : this.id,
          IsDummyPrintPreview: "Y",
          Date: date,
          DueDate: duedate,
          UnitCodeStart: "",
          UnitCodeEnd: "",
          DebtAmountMoreThan: 0,
          OverdueDayMoreThan: 0,
          SuspendDate: duedate,
        };
        let payload = {
          docType: "PDF",
          path: "notice/notice_custom_report.pdf",
          fileName: "notice_custom_report",
          parameter: JSON.stringify(parameter),
        };
        await this.$store.dispatch("shared/printReport", payload);
        this.getPreviewBlob(this.fileBlob);
        if (this.isTrySaveAndGotoPrintPreview) {
          this.isTrySaveAndGotoPrintPreview = false;
        }
      } catch (error) {
        this.baseDialogClassTitle = "p-text-error";
        this.baseDialogTitle = this.lang.notificationDialogHeader;
        this.baseDialogContent = error;
        this.baseDialogType = "close";
        this.showDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    // call API

    resetlogoUrlError() {
      this.logoUrlError = false;
    },
    resetSignatureError() {
      this.signatureUrlThError = false;
      this.signatureUrlEnError = false;
    },
    resetContentFooterError() {
      this.contentFooterThError = false;
      this.contentFooterEnError = false;
    },
    resetSignatureFullNameError() {
      this.signatureFullNameThError = false;
      this.signatureFullNameEnError = false;
    },
    resetSignaturePositionError() {
      this.signaturePositionThError = false;
      this.signaturePositionEnError = false;
    },
    updateSignatureFullNameTh(inputTextValue) {
      this.signatureFullNameTh = inputTextValue;
      this.signatureFullNameThError = false;
    },
    updateSignatureFullNameEn(inputTextValue) {
      this.signatureFullNameEn = inputTextValue;
      this.signatureFullNameEnError = false;
    },
    updateSignaturePositionTh(inputTextValue) {
      this.signaturePositionTh = inputTextValue;
      this.signaturePositionThError = false;
    },
    updateSignaturePositionEn(inputTextValue) {
      this.signaturePositionEn = inputTextValue;
      this.signaturePositionEnError = false;
    },
  },
  computed: {
    payload() {
      let details = [];
      for (const each of this.details.filter((each) => each.id)) {
        let detail = null;
        if (each.id.toString().includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        details.push(detail);
      }
      let payload = {
        projectId: this.selectedProject.id,
        name: this.name,
        isGroupProduct: this.selectedIsGroupProduct ? this.selectedIsGroupProduct : "Y",
        isSuspend: this.selectedIsSuspend ? "Y" : "N",
        isDisplayLogo: this.selectedIsDisplayLogo ? "Y" : "N",
        logoUrl: this.logoUrl,
        subjectTh: this.subjectTh,
        subjectEn: this.subjectEn,
        toTh: this.toTh,
        toEn: this.toEn,
        addressTh: this.addressTh,
        addressEn: this.addressEn,
        contentHeaderTh: this.contentHeaderTh,
        contentHeaderEn: this.contentHeaderEn,
        isDisplayContentFooter: this.selectedIsDisplayContentFooter ? "Y" : "N",
        contentFooterTh: this.contentFooterTh,
        contentFooterEn: this.contentFooterEn,
        isDisplaySignature: this.selectedIsDisplaySignature ? "Y" : "N",
        isSameSignatureOnBothLang: this.selectedIsSameSignatureOnBothLang
          ? this.selectedIsSameSignatureOnBothLang
          : "Y",
        signatureUrlTh: this.signatureUrlTh,
        signatureUrlEn: this.signatureUrlEn,
        isDisplaySignatureFullName: this.selectedIsDisplaySignatureFullName ? "Y" : "N",
        signatureFullNameTh: this.signatureFullNameTh,
        signatureFullNameEn: this.signatureFullNameEn,
        isDisplaySignaturePosition: this.selectedIsDisplaySignaturePosition ? "Y" : "N",
        signaturePositionTh: this.signaturePositionTh,
        signaturePositionEn: this.signaturePositionEn,
        pageFooterTh: this.pageFooterTh,
        pageFooterEn: this.pageFooterEn,
        details: details,
      };
      if (this.isCreateDocument) {
        payload = { ...payload, type: "Add" };
      } else {
        payload = { ...payload, type: "Update", id: this.id };
      }
      return payload;
    },
    isCreateDocument() {
      return this.id === "create";
    },
    title() {
      return this.isCreateDocument ? this.lang.createTitle : this.lang.editTitle;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
    noticePrintSetting() {
      return this.$store.getters["noticeCustomReport/selectedNoticeCustomReport"];
    },
    countDetails() {
      return this.details.length;
    },
    selectedFile() {
      return this.$store.getters["upload/selectedFile"];
    },
    fileBlob() {
      return this.$store.getters["shared/blob"];
    },
    dataButtons() {
      let result = [
        {
          id: "gotoDelete",
          action: "gotoDelete",
          type: "delete",
        },
        {
          id: "gotoCreate",
          action: "gotoCreate",
          type: "create",
        },
        {
          id: "gotoPrint",
          caption: this.lang.preview,
          class: "p-button-outlined mr-2",
          icon: "icon-eye",
          iconColor: "primary",
          action: "gotoPrint",
          isShow: true,
        },
        {
          id: "gotoSave",
          action: "gotoSave",
          type: "save",
        },
      ];
      return result;
    },
    dataButtonDialogs() {
      let result = [];
      if (this.baseDialogType == "close") {
        result = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
        ];
      }
      if (this.baseDialogType == "detail") {
        result = [
          {
            id: "detail",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "goto-detail",
          },
        ];
      }
      if (this.baseDialogType == "create") {
        result = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "create",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-create",
          },
        ];
      }
      if (this.baseDialogType == "trySave") {
        result = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "trySave",
            caption: this.lang.buttonDialogSaveAndPreview,
            class: "w-full",
            action: "try-save",
          },
        ];
      }
      if (this.baseDialogType == "delete") {
        result = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "delete",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-delete",
          },
        ];
      }
      if (this.baseDialogType == "deleted") {
        result = [
          {
            id: "deleted",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "goto-list",
          },
        ];
      }
      return result;
    },
  },
  watch: {
    payload(curValue) {
      if (this.objToCompare) {
        this.compareObject(this.objToCompare, curValue);
      }
    },
  },
};
</script>
