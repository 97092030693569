<template>
  <base-toolbar
    :title="lang.title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoChangeBook="openAccountBookDialog"
    @gotoCreate="gotoEdit('create')"
    :hideBackButton="isHideBack"
  ></base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="lang.notificationDialogHeader"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
    ></base-dialog>

    <base-gl-book-modal
      v-model:visible="showAccountBookDialog"
      :search="searchText"
      :isSharedLoading="isSharedGlBookReady"
      @close="showAccountBookDialog = false"
      @glBook-selected="selectedAccountBook"
    ></base-gl-book-modal>

    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{ titleListing }}</label>

      <PvDivider></PvDivider>

      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >
        <div class="lg:col-2 md:col-3 col-12 pl-0 pr-2">
          <PvDropdown
            id="filterSearch"
            class="inputfield w-full mr-2"
            :options="filterSearch"
            optionLabel="name"
            optionValue="code"
            v-model="selectedFilterSearch"
            scrollHeight="300px"
            @change="changeFilterSearch"
          />
        </div>
        <div class="flex lg:col-6 md:col-6 col-12 p-0 mr-2" v-if="!isSearchAll">
          <div class="col-12 pl-0 pr-0">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search"/>
              <PvInputText
                class="inputfield w-full"
                v-model="filterSearchText"
                :placeholder="lang.searchTextPlaceholder"
                @keyup.enter="getAccountingFrequentlys"
                :disabled="isSearchAll"
                :class="{ 'p-inputtext-disabled': isSearchAll }"
                autofocus
              />
            </span>
          </div>
        </div>
        <PvButton @click="getAccountingFrequentlys">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="accountingFrequentlys"
          v-model:selection="selectedAccountingFrequently"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(this.selectedAccountingFrequently.id)"
          scrollable
        >
          <PvColumn
            field="code"
            :header="lang.code"
            style="max-width: 15rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <u>{{ slotProps.data.code }}</u>
            </template>
          </PvColumn>
          <PvColumn
            field="name"
            :header="lang.name"
            style="min-width: 20rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="description"
            :header="lang.description"
            style="min-width: 20rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="status"
            :header="lang.status"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <base-tag
                v-if="slotProps.data.status != ''"
                :Caption="
                  slotProps.data.status.trim() == 'Y'
                    ? lang.active
                    : lang.inActive
                "
                :Class="
                  slotProps.data.status.trim() == 'Y'
                    ? 'success'
                    : 'secondary'
                "
              />
            </template>
          </PvColumn>
          <PvColumn
            style="max-width: 6rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
            </template>
          </PvColumn>

          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              class="p-pagination-radius"
              :first="showCurrentPage"
            >
              <template #start="slotProps">
                {{ lang.page }}:
                {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
              </template>
            </PvPaginator>
          </template>

          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>

        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import useGetGlBook from "../../../hooks/getGlBook.js";
export default {
  props: ["accountBookId", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.accountingFrequently.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.accountingFrequently.listing,
        };
      }
    });

    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    const { isSharedGlBookReady, searchText, SetBookCode } = useGetGlBook();

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      isSharedGlBookReady,
      searchText,
      SetBookCode,
      isHideBack,
    };
  },
  data() {
    return {
      isLoading: false,
      showValidateDialog: false,
      validateWording: "",
      showAccountBookDialog: false,
      accountBook: null,
      filterSearchText: "",
      selectedAccountingFrequently: null,
      selectedPage: 1,
      rowsPerPage: 50,
      filterSearch: [
        { name: this.lang.all, code: "all" },
        { name: this.lang.code, code: "code" },
        { name: this.lang.name, code: "name" },
      ],
      selectedFilterSearch: "all",

      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ],
    }
  },
  async mounted() {
    await this.initiateData();
    await this.roleByMenuDataReady();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    gotoEdit(id) {
      if (!this.isRoleSelect && id != "create") {
        this.validateWording = this.lang.validateRoleSelect;
        this.showValidateDialog = true;
      } else if (!this.isRoleCreate && id == "create") {
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
      } else {
        this.$router.push({name: "accounting-frequently-accountBookId-edit", params:{accountBookId: this.accountBookId,id: id}});
      }
    },
    async initiateData() {
      this.filterSearchText = "";
      this.selectedAccountingFrequently = null;
      this.storageName = `erp_search_AccountingFrequentlyList_${this.selectedProject.code}_${this.userCode}`;
    },
    async changeFilterSearch() {
      await this.initiateData();
    },
    async roleByMenuDataReady() {
      if (this.isSharedRoleByMenuReady) {
        if(!this.accountBookId) {
          this.setLoading(true);
          await this.openAccountBookDialog();
        }
        await this.validateAccountBook();
        if(this.accountBookId) {
          await this.setLocalSearch();
          await this.getAccountingFrequentlys();
        }
      }
    },
    openAccountBookDialog() {
      this.SetBookCode("All");
      this.showAccountBookDialog = true;
      this.setLoading(false);
    },
    changeBook() {
      this.showAccountBookDialog = true;
    },
    async selectedAccountBook(data) {
      this.accountBook = data;
      let accountBookId = data.generalLedgerBookId;
      await this.$router.push({name: "accounting-frequently-accountBookId", params:{accountBookId: accountBookId}});
      this.showAccountBookDialog = false;
      await this.roleByMenuDataReady();
    },
    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      if (localSearch) {
        let search = JSON.parse(localSearch);
        if (search.entry === "entry") {
          if (search.accountCode != null && search.accountCode != "") {
            this.selectedFilterSearch = "code";
            this.filterSearchText = search.accountCode;
          } else if (search.accountName != null && search.accountName != "") {
            this.selectedFilterSearch = "name";
            this.filterSearchText = search.accountName;
          } else {
            this.filterSearchText = "";
          }
          this.rowsPerPage = search.limit;          
          this.selectedPage = search.page;

          search.entry = "";

          localStorage.setItem(this.storageName, JSON.stringify(search));
        }
      }
    },
    async validateAccountBook() {
      if(this.sharedAccountBooks.length == 0) {
        await this.SetBookCode("All");
      }
      this.accountBook = this.sharedAccountBooks.find(
        (book) => book.generalLedgerBookId.trim() == this.accountBookId
      );
    },
    validateSearchButton() {
      if (!this.isSearchAll && this.filterSearchText == "") {
        return true;
      }
      return false;
    },
    async getAccountingFrequentlys() {
      if (this.validateSearchButton()) {
        this.validateWording = this.lang.validateSearchText;
        this.showValidateDialog = true;
        return;
      }

      this.isLoading = true;
      try {
        await this.$store.dispatch(
          "accountingFrequently/getAccountingFrequentlys",
          this.payload
        );

        localStorage.setItem(this.storageName,JSON.stringify(this.payload));

      } catch (error) {
        this.validateWording = error;
        this.showValidateDialog = true;
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async onPageChange(event) {
      if(event.rows != this.rowsPerPage){
        this.selectedPage = 1;
      } else{
        this.selectedPage = event.page + 1;        
      }
      this.rowsPerPage = event.rows;
      await this.getAccountingFrequentlys();
    },
  },
  computed: {
    payload() {
      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        projectId: this.selectedProject.id,
        accountBookId: this.accountBookId,
        accountCode: this.selectedFilterSearch == "code" ? this.filterSearchText : null,
        accountName: this.selectedFilterSearch == "name" ? this.filterSearchText : null
      };
      
      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("VCHPTN");
    },
    isRoleSelect() {
      if (this.roleByMenu && this.roleByMenu.select == "Y") {
        return true;
      }
      return false;
    },
    isRoleCreate() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    sharedAccountBooks() {
      return this.$store.getters["shared/glBookGls"];
    },
    titleListing() {
      let result = "";      
      if(this.accountBook) {
        result = this.accountBook.generalLedgerBookCode.trim() + " : " + this.accountBook.generalLedgerBookName.trim();
      }
      return result;
    },
    isSearchAll() {
      if (this.selectedFilterSearch == "all") {
        return true;
      } else {
        return false;
      }
    },
    accountingFrequentlys() {
      return this.$store.getters["accountingFrequently/accountingFrequentlys"];
    },
    totalPage() {
      return this.$store.getters["accountingFrequently/totalPage"];
    },
    currentPage() {
      return this.$store.getters["accountingFrequently/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    showCurrentPage(){
      let page = 0;
      page = this.rowsPerPage * (this.selectedPage - 1)
      return page;
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "changeBook",
          caption: this.lang.changeBook,
          class: "p-button-outlined mr-2",
          icon: "icon-arrow-rotate",
          iconColor: "primary",
          action: "gotoChangeBook",
          isShow: true,
        },
        {
          id: "create",
          caption: this.lang.createAccountingFrequently,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
        },
      ];
      return dataButtons;
    },
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.roleByMenuDataReady();
    },
  },
};
</script>
