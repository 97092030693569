export default {
    toolbarCreateBookBank: "Create Book Bank",
    toolbarEditBookBank: "Edit Book Bank",
    activeStatusY: "Active",
    activeStatusN: "Inactive",
    bookBankInformation: "Book Bank Information",
    depositPeriod: "Number of deposit days",
    day: "Day",
    bookBankType: "Book Bank Type",
    bookBankTypeC: "Current",
    bookBankTypeS: "Accumulate Deposit",
    bookBankTypeF: "Fixed Deposit",
    bookBankDetail: "Book Bank Details",
    bankBookUseType: "Book Bank Use Type",
    bankBookUseTypeR: "Receipt",
    bankBookUseTypeP: "Paid",
    bankBookUseTypeB: "Receipt And Paid",
    depositName: "Deposit Name",
    accounting: "Accounting",
    objective: "Objective",
    depositAmount: "Deposit Amount",
    interestRate: "Interest Rate",
    balanceAmount: "Balance Amount",
    dueDate: "Due Date",
    remark: "Remark",
    validateDepositPeriod: "Enter number of day",
    bank: "Bank",
    validateBank: "Select bank",
    branch: "Branch",
    validateBranch: "Enter branch",
    bookBankName: "Book Bank Name",
    validateBookBankName: "Enter book bank name",
    bookBankNo: "Book Bank No.",
    validateBookBankNo: "Enter book bank no.",
    validateCode: "Enter code",
    validateDepositName: "Enter deposit name",
    validateObjective: "Enter objective",
    validateRemark: "Enter remark",
    validateAccointing: "Select accointing",
    validateDate: "Select date",
    validateDepositAmount: "Enter deposit amount",
    activeStatus: "Active Status",
    isForTransfer: "Book Bank For Transfer",
    deleteBookBank: "Delete",
    deleteBookBankSuccess: "Delete book bank successfully",
    saveBookBankSuccess: "Add book bank successfully",
    editBookBankSuccess: "edit book bank successfully",
    confirmDeleteBookBank: "Are you sure you want to delete book bank ?",
    confirmSaveBookBankDialogHeader: "Confirm edit book bank",
    confirmSaveBookBank: "Are you sure you want to edit book bank ?",
    beginDate: "Begin Date",
}