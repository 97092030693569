export default {
  title: "แก้ไขใบเสร็จ Visitor control",
  labelCode: "เลขที่ใบเสร็จ",
  labelDate: "วันที่เอกสาร",
  labelUnit: "รหัสห้อง/บ้าน",
  labelCustomer: "ชื่อลูกค้า",
  labelPayType: "ประเภทการชำระ",
  labelTotalAmount: "รวมเงิน",
  labelBookBank: "โอนเข้าบัญชี",
  labelPayDate: "วันที่โอน",
  labelBranch: "สาขา",
  labelPayReferCode: "เลขที่ใบโอน",
  labelPaidBy: "ชื่อผู้ชำระ",
  labelPaidByAddress: "ที่อยู่ผู้ชำระ",
  labelMultiReceipts: "หลายใบเสร็จ",
	cashType: "เงินสด",
	transferType: "เงินโอน",
	chequeType: "เช็ค",
	creditCardType: "บัตรเครดิต",
  validateNoBookbank: "กรุณาเลือกบัญชีธนาคาร",
  validateNoBookbankBranch: "กรุณาระบุสาขาธนาคาร",
  validateNoPayReference: "กรุณาระบุ:no",
  validateReferenceBank: "ไม่สามารถแก้ไขเอกสารนี้ได้ เนื่องจากมีเอกสารธนาคารอ้างถึง",
  validateReferenceVoucher: "ไม่สามารถแก้ไขเอกสารนี้ได้ เนื่องจากเอกสารถูกนำไปลงบัญชีแล้ว",
};
