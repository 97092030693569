<template>
  <base-container>
    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchTextPrint"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>
    <base-accounting-detail-modal
      v-model:visible="showAccountingModal"
      :search="searchTextAccountingDetail"
      @close="showAccountingModal = false"
      @accounting-selected="accountingModalSelected"
    >
    </base-accounting-detail-modal>
    <base-accounting-frequently-modal
      v-model:visible="showAccountingFrequentlyModal"
      :search="searchText"
      :meterToGl="meterDetail"
      :isActive="true"
      @close="showAccountingFrequentlyModal = false"
      @accountingFrequently-selected="accountingFrequentlyModalSelected"
    >
    </base-accounting-frequently-modal>
    <base-receipt-modal
      v-model:visible="showAccountingReceipt"
      @close="showAccountingReceipt = false"
      @receipt-selected="selectedReceiptToGl"
      @auto-receipt-to-gl-success="autoReceiptToGlSuccess"
      :openFrom="openFrom"
      :voucherId="id"
      :autoReceiptToGl="autoReceiptToGl"
      :date="date"
    >
    </base-receipt-modal>
    <base-invoice-modal
      v-model:visible="showInvoiceModal"
      @close="showInvoiceModal = false"
      @invoice-selected="invoiceModalSelected"
      :openFrom="openFrom"
      :voucherId="id"
    >
    </base-invoice-modal>
    <base-meter-to-gl-modal
      v-model:visible="showMeterToGlModal"
      :openFrom="openFrom"
      @close="showMeterToGlModal = false"
      @meter-to-gl-selected="meterToGlSelected"
      :voucherId="id"
    >
    </base-meter-to-gl-modal>

    <!-- *********************PItoGl************************** -->

    <base-purchase-invoice-selected-model
      v-model:visible="showPurchaseInvoiceSelectedModal"
      @close="showPurchaseInvoiceSelectedModal = false"
      @confirm-purchase-invoice-to-gl="confirmPurchaseInvoiceToGl"
      :openFrom="openFromPurchaseInvoiceSelected"
      :voucherId="id"
      :purchaseInvoiceId="purchaseInvoiceId"
    >
    </base-purchase-invoice-selected-model>

    <!-- *********************PItoGl************************** -->

    <!-- *********************PTtoGl************************** -->
    <base-purchase-receipt-selected
      v-model:visible="showPurchaseReceiptSelected"
      @close="showPurchaseReceiptSelected = false"
      @confirm-purchase-receipt-to-gl="confirmPurchaseReceiptToGl"
      :openFrom="openFromPurchaseReceiptSelected"
      :voucherId="id"
      :purchaseReceiptId="purchaseReceiptId"
    >
    </base-purchase-receipt-selected>
    <!-- *********************PTtoGl************************** -->
    <PvDialog
      :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
      :style="{ width: '80vw' }"
      :modal="true"
      :closable="false"
      v-model:visible="showWithholdingTax"
    >
      <base-withholding-tax-view :voucherId="this.id">
      </base-withholding-tax-view>
      <template #footer>
        <PvButton
          :label="lang.close"
          icon="pi pi-times"
          class="p-button-text"
          @click="closeWithholdingTax"
        />
      </template>
    </PvDialog>

    <PvDialog
      :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
      :style="{ width: '90vw' }"
      :modal="true"
      :closable="false"
      v-model:visible="showPayinPayout"
    >
      <base-bank-view :voucherId="this.id" :type="-1"> </base-bank-view>
      <template #footer>
        <PvButton
          :label="lang.close"
          icon="pi pi-times"
          class="p-button-text"
          @click="closePayinPayout"
        />
      </template>
    </PvDialog>

    <div class="flex justify-content-between align-items-center">
      <div class="my-3">
        <PvButton
          icon="pi pi-chevron-left"
          :label="lang.back"
          @click="goBack"
          class="mr-2 p-button-secondary"
        />
      </div>
      <h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
    </div>
    <transition name="fade" mode="out-in">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex pt-2 pb-1">
          <PvButton
            ref="hotkeyF2"
            icon="pi pi-file"
            :label="lang.createNewDocument"
            @click="askForCreateDocument"
            class="p-button-secondary mr-2 mb-2"
          />
          <PvButton
            ref="hotkeyF8"
            icon="pi pi-save"
            :label="lang.saveDocument"
            class="mr-2 mb-2"
            @click="save"
            :disabled="isDocumentLocked || isCheckEditPettyCash"
          />
          <PvButton
            icon="pi pi-save"
            :label="lang.saveAs"
            class="mr-2 mb-2"
            @click="askForSaveAs"
            :disabled="isCreateDocument"
          />
          <PvButton
            ref="hotkeyF6"
            icon="pi pi-file-pdf"
            :label="lang.downloadPdf"
            class="mr-2 mb-2"
            @click="selectPrintForm"
            :disabled="isCreateDocument || isCheckPrint"
          />
          <PvButton
            icon="pi pi-times"
            :label="lang.cancel"
            class="p-button-outlined p-button-danger mr-2 mb-2"
            @click="askForCancel"
            :disabled="
              isCreateDocument || isCanceled || isPrint || isCheckCancel
            "
          />
          
          <div class="flex align-items-center ml-auto" v-if="!GetPathGlPettyCash">
            <PvInputSwitch v-model="activeStatus" class="mr-2" @change="askForUpdateLockStatus()"/>
            <label for="status">{{ activeStatus ? lang.activeLockStatus : lang.activeUnlockStatus }}</label>
          </div>
        </div>
        <div class="flex flex-wrap" :class="textFormClass">
          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
            <label for="code">{{ lang.columnCode }}</label>
            <PvInputText
              id="code"
              v-model="code"
              type="text"
              class="inputfield w-full mt-1 p input-disable"
              readonly
            />
          </div>
          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
            <label for="date">{{ lang.date }}</label>
            <div class="flex justify-content-between align-items-center">
              <PvCalendar
                id="date"
                v-model="date"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
              />
              <PvButton
                :label="lang.receiptToGl"
                class="mt-1 ml-2"
                style="width: 250px"
                @click="ReceiptToGlAuto"
                :disabled="isPrint || isCanceled"
                v-if="showReceiptToGl"
              />
            </div>
          </div>
          <div
            class="lg:col-4 md:col-6 col-12 pl-0 pr-2"
            v-if="GetPathGlPettyCash"
          >
            <label for="remark">{{ lang.remark }}</label>
            <PvInputText
              id="remark1"
              v-model="remark"
              type="text"
              class="inputfield w-full mt-1"
            />
          </div>
          <div
            class="lg:col-4 md:col-6 col-12 pl-0 pr-2"
            v-if="!GetPathGlPettyCash"
          >
            <label for="type">{{ lang.type }}</label>
            <PvDropdown
              id="AccountType"
              class="inputfield w-full mt-1"
              :options="types"
              optionValue="code"
              optionLabel="name"
              v-model="selectType"
            />
          </div>

          <div class="lg:col-8 md:col-6 col-12 pl-0 pr-2">
            <label for="description">{{ lang.description }}</label>
            <PvInputText
              id="description"
              v-model="description"
              type="text"
              class="inputfield w-full mt-1"
            />
          </div>

          <!-- <div
            class="lg:col-4 md:col-6 col-12 pl-0 pr-2"
            v-if="!GetPathGlPettyCash"
          >
            <label for="status">{{ lang.status }}</label>
            <PvDropdown
              id="AccountStatus"
              class="inputfield w-full mt-1"
              :options="status"
              optionLabel="name"
              optionValue="code"
              v-model="selectStatus"
            />
          </div> -->

          <div
            class="lg:col-4 md:col-6 col-12 pl-0 pr-2"
            v-if="GetPathGlPettyCash"
          >
            <label for="pettyCashPeriod">{{ lang.pettyCashPeriod }}</label>
            <PvInputText
              id="pettyCashPeriod"
              type="text"
              class="inputfield w-full mt-1"
              v-model="pettyCashPeriod"
              readonly
            />
          </div>

          <div
            class="lg:col-4 md:col-6 col-12 pl-0 pr-2"
            v-if="!GetPathGlInvoice && !GetPathGlReceipt"
          >
            <label for="approveStatus">{{ lang.columnApprove }}</label>
            <PvDropdown
              id="AccountApprove"
              class="inputfield w-full mt-1"
              :options="approve"
              :showClear="clearApproved"
              optionLabel="name"
              optionValue="code"
              v-model="selectApprove"
              @change="getApproveName"
            />
            <!-- :disabled="isCheckApprove" -->
          </div>
          <div
            class="lg:col-4 md:col-6 col-12 pl-0 pr-2"
            v-if="!GetPathGlInvoice && !GetPathGlReceipt"
          >
            <label for="approveName">{{ lang.approveName }}</label>
            <PvInputText
              id="approvedName"
              type="text"
              class="inputfield w-full mt-1"
              v-model="approveName"
              readonly
            />
          </div>

          <div
            class="lg:col-4 md:col-6 col-12 pl-0 pr-2"
            v-if="GetPathGlPettyCash"
          >
            <label for="reasonNonApprove">{{ lang.reasonNonApprove }}</label>
            <PvInputText
              id="reasonNonApprove"
              type="text"
              class="inputfield w-full mt-1"
              v-model="reasonNonApprove"
            />
          </div>
          <div
            class="lg:col-4 md:col-6 col-12 pl-0 pr-2"
            v-if="!GetPathGlPettyCash && !GetPathGlInvoice && !GetPathGlReceipt"
          ></div>
          <div class="pt-2 pb-1">
            <PvButton
              type="button"
              icon="pi pi-file"
              class="mr-2 mb-2"
              :label="lang.withholdingTax"
              :disabled="
                isCreateDocument || isPrint || isCanceled || isEditDetail
              "
              v-if="showWithholdingTaxButton"
              @click="getWithholdingTax"
            />
            <PvButton
              type="button"
              icon="pi pi-file"
              class="mr-2 mb-2"
              :label="lang.payinPayoutBtn"
              :disabled="isCreateDocument || isPrint || isCanceled"
              v-if="showPayinPayoutButton"
              @click="getPayinPayout"
            />

            <PvButton
              ref="hotkeyF3"
              icon="pi pi-plus"
              :label="lang.getDocument"
              class="mr-2 mb-2"
              @click="getInvoice"
              :disabled="isPrint || isCanceled"
              v-if="showInvoiceToGlButton"
            />
            <PvButton
              icon="pi pi-plus"
              :label="lang.meterToGl"
              @click="getMeterToGl"
              class="mr-2 mb-2"
              :disabled="isPrint || isCanceled"
              v-if="showMeterToGlButton"
            />
            <PvButton
              icon="pi pi-plus"
              :label="lang.revert"
              class="mr-2 mb-2"
              v-if="showRevertButton"
              :disabled="isPrint || isCanceled"
              @click="askForRevert"
            />
            <!-- {{this.isCreateDocument}}{{this.isPrint}}{{this.isCanceled}} -->
            <PvButton
              ref="hotkeyF3"
              icon="pi pi-plus"
              :label="lang.getDocument"
              class="mr-2 mb-2"
              @click="AddReceiptToGl"
              :disabled="isPrint || isCanceled"
              v-if="showReceiptToGl"
            />
            <PvButton
              icon="pi pi-bars"
              :label="lang.compound"
              class="mr-2 mb-2"
              @click="compound"
              :disabled="isPrint || isCanceled"
              v-if="showReceiptToGl"
            />

            <PvButton
              ref="hotkeyF3"
              icon="pi pi-plus"
              :label="lang.getDocument"
              class="mr-2 mb-2"
              :disabled="isPrint || isCanceled"
              @click="addPurchaseInvoiceToGl"
              v-if="
                GetPathGlAccounting && this.checkGlBook && this.glBook == 'PJ'
              "
            />

            <PvButton
              ref="hotkeyF3"
              icon="pi pi-plus"
              :label="lang.getDocument"
              class="mr-2 mb-2"
              :disabled="isPrint || isCanceled"
              @click="addPurchaseReceiptToGl()"
              v-if="
                GetPathGlAccounting && this.checkGlBook && this.glBook == 'PV'
              "
            />
            <!-- <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 flex align-items-end"> -->
            <PvButton
              type="button"
              icon="pi pi-search"
              :label="lang.accountingFrequently"
              @click="getAccountingFrequently"
              :disabled="isPrint || isCanceled || isEditDetail"
              class="mr-2 mb-2"
            />
            <!-- </div> -->
            <PvButton
              ref="hotkeyF10"
              type="button"
              icon="pi pi-plus"
              :label="lang.insertRow"
              @click="insertRow"
              :disabled="isPrint || isCanceled || isEditDetail"
              class="mr-2 mb-2"
            />
            <PvButton
              ref="hotkeyF11"
              type="button"
              icon="pi pi-times"
              :label="lang.removeRow"
              @click="removeRow"
              :disabled="isPrint || isCanceled || isEditDetail"
              class="mr-2 mb-2"
            />
            <PvButton
              ref="hotkeyF4"
              type="button"
              :label="lang.difference"
              @click="calDifferent"
              :disabled="isPrint || isCanceled || isEditDetail"
              class="mr-2 mb-2"
            >
              <icon-accounting-calculator-circle
                iconColor="white"
                width="20"
                height="20"
              />
              <label class="p-button-label ml-1">{{ lang.difference }}</label>
            </PvButton>
            <PvButton
              type="button"
              class="mr-2 mb-2"
              @click="switchDebitCredit"
            >
              <icon-arrange-filter-sort iconColor="white" width="20" height="20"/>
              <label class="p-button-label ml-1">{{ lang.switchDebitCredit }}</label>
            </PvButton>
          </div>
        </div>
        <vue-excel-editor
          ref="grid"
          v-if="shouldShowDataGrid"
          v-model="details"
          :readonly="isPrint || isCanceled || isEditDetail || isDocumentLocked"
          disable-panel-filter
          disable-panel-setting
          no-footer
          no-num-col
          @update="onUpdateTable"
          @cell-focus="onCellFocus"
          @page-changed="onPageChange"
          @keyup.enter="onGridPressEnter"
        >
          <vue-excel-column
            field="id"
            label="id"
            type="number"
            text-align="center"
            readonly
            invisible
            :sort="
              () => {
                return 0;
              }
            "
          />
          <vue-excel-column
            field="itemNumber"
            :label="lang.itemNo"
            type="number"
            text-align="center"
            readonly
            width="50px"
            :sort="
              () => {
                return 0;
              }
            "
          />
          <vue-excel-column
            field="accountCode"
            :label="lang.code"
            type="string"
            :sort="
              () => {
                return 0;
              }
            "
          />
          <vue-excel-column
            field="accountName"
            :label="lang.name"
            type="string"
            width="200px"
            readonly
            :sort="
              () => {
                return 0;
              }
            "
          />
          <vue-excel-column
            field="description"
            :label="lang.description"
            type="string"
            autoFillWidth
            :sort="
              () => {
                return 0;
              }
            "
          />
          <vue-excel-column
            field="debitAmount"
            :label="lang.debit"
            type="number"
            width="120px"
            :sort="
              () => {
                return 0;
              }
            "
            :to-text="formatCurrency"
            :to-value="Number"
          />
          <vue-excel-column
            field="creditAmount"
            :label="lang.credit"
            type="number"
            width="120px"
            :sort="
              () => {
                return 0;
              }
            "
            :to-text="formatCurrency"
            :to-value="Number"
          />
          <vue-excel-column
            width="30px"
            readonly
            :sort="
              () => {
                return 0;
              }
            "
          />
          <!-- <PvButton
                type="button"
                class="p-button-text py-0"
                icon="pi pi-plus"
                @click="insertRow(slotProps)"
                :disabled="isPrint || isCanceled || isEditDetail"
              ></PvButton>
              <PvButton
                type="button"
                class="p-button-danger p-button-text py-0"
                icon="pi pi-times"
                @click="deleteRow(slotProps.data.id)"
                :disabled="isPrint || isCanceled || isEditDetail"
              ></PvButton> -->
        </vue-excel-editor>

        <div class="flex flex-wrap" :class="textFormClass">
          <!-- <div class="flex flex-row-reverse flex-wrap card-container mr-3"> -->
          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
            <label for="totalDebit">{{ lang.totalDebit }}</label>
            <PvInputNumber
              id="totalDebit"
              class="inputfield w-full mt-1"
              inputClass="input-number-disable text-right"
              v-model="totalDebit"
              disabled
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              placeholder=".00"
            />
          </div>
          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
            <label for="totalCredit">{{ lang.totalCredit }}</label>
            <PvInputNumber
              id="totalCredit"
              class="inputfield w-full mt-1"
              inputClass="input-number-disable text-right"
              v-model="totalCredit"
              disabled
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              placeholder=".00"
            />
          </div>
          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
            <label for="diff">{{ lang.diff }}</label>
            <PvInputNumber
              id="diff"
              class="inputfield w-full mt-1"
              inputClass="input-number-disable text-right"
              v-model="diff"
              disabled
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              placeholder=".00"
            />
          </div>
        </div>
      </div>
    </transition>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import useCalculation from "../../../hooks/calculation.js";
import useGetAccountingFrequently from "../../../hooks/getAccountingFrequently.js";
import useGetAccountingDetail from "../../../hooks/getAccountingDetail";
import BaseReceiptModal from "../../../components/GL/BaseReceiptModal.vue";
import baseInvoiceModal from "../../../components/GL/BaseInvoiceModal.vue";
import baseMeterToGlModal from "../../../components/GL/BaseMeterToGlModal.vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import baseWithholdingTaxView from "../../../components/withholdingTax/BaseWithholdingTaxView.vue";
import BaseBankView from "../../../components/bank/BaseBankView.vue";
import basePurchaseInvoiceSelectedModel from "../../../components/GL/BasePurchaseInvoiceSelectedModel.vue";
import basePurchaseReceiptSelected from "../../../components/GL/BasePurchaseReceiptSelected.vue";
import { useStore } from "vuex";
import { computed } from "vue";
import useHotkey from "../../../hooks/hotkey.js";
import BaseDialog from "../../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import usePageDirty from "../../../hooks/pageDirty";
export default {
  props: ["glBook", "idBook", "id", "projectCode"],
  components: {
    BaseReceiptModal,
    baseInvoiceModal,
    baseMeterToGlModal,
    baseWithholdingTaxView,
    BaseBankView,
    basePurchaseInvoiceSelectedModel,
    basePurchaseReceiptSelected,
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass, textAlertClass } =
      useTextStyle();
    const {
      formatCurrencyTable,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getCurrentPeriod,
    } = useFormat();
    const { isSharedAccountingFrequentlyReady, searchText } =
      useGetAccountingFrequently();
    // const { isSharedAccountingReady, getAccountingByCode, searchTextAccounting, setAccounting } =
    // 	useGetAccounting();
    const {
      searchTextAccountingDetail,
      isSharedAccountingDetailReady,
      getAccountingDetailByCode,
      getAllAccountingDetails,
    } = useGetAccountingDetail();

    const {
      getFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints,
    } = useGetFormPrint();

    const {
      hotkeyF2,
      hotkeyF3,
      hotkeyF4,
      hotkeyF6,
      hotkeyF8,
      hotkeyF10,
      hotkeyF11,
      hotkeyEscape,
    } = useHotkey();
    const store = useStore();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.accounting.edit,
          ...lang.th.gl.accounting.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.accounting.edit,
          ...lang.en.gl.accounting.listing,
        };
      }
    });

    const { sum, subtract, round } = useCalculation();

    const { isCompareObject, compareObject } = usePageDirty();

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      textAlertClass,
      formatCurrencyTable,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getCurrentPeriod,
      isSharedAccountingFrequentlyReady,
      isSharedAccountingDetailReady,
      getAccountingDetailByCode,
      // setAccounting,
      searchText,
      searchTextAccountingDetail,
      getFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints,
      hotkeyF2,
      hotkeyF3,
      hotkeyF4,
      hotkeyF6,
      hotkeyF8,
      hotkeyF10,
      hotkeyF11,
      hotkeyEscape,
      getAllAccountingDetails,
      sum,
      subtract,
      round,
      isCompareObject,
      compareObject,
    };
  },
  data() {
    return {
      code: "",
      date: null,
      description: null,
      remark: "",
      selectType: "",
      // selectStatus: "",
      selectApprove: "",
      approveName: null,
      reasonNonApprove: "",
      clearApproved: false,
      details: [
        {
          id: "dummy1",
          accountCode: "",
          accountId: "",
          accountName: "",
          creditAmount: 0.0,
          debitAmount: 0.0,
          description: "",
          headerId: "",
          itemNumber: 1,
        },
      ],
      compoundEpay: [],
      totalDebit: 0,
      totalCredit: 0,
      diff: 0,
      types: [
        { name: this.lang.general, code: " " },
        { name: this.lang.adjust, code: "A" },
        { name: this.lang.closePeriod, code: "C" },
      ],
      status: [
        { name: this.lang.nonPosting, code: "A" },
        { name: this.lang.posting, code: "P" },
      ],
      // approve: [
      //   { name: this.lang.columnApprove, code: "Y" },
      //   { name: this.lang.notApprove, code: "" },
      // ],

      showAccountingFrequentlyModal: false,
      showAccountingModal: false,
      showInvoiceModal: false,
      editingDetail: null,
      showAccountingReceipt: false,
      // showInvoiceToGl: false,
      showReceiptToGl: false,
      showInvoiceToGlButton: false,
      showMeterToGlButton: false,
      showRevertButton: false,
      showMeterToGlModal: false,
      meterDetail: null,
      openFrom: "",
      isInvoiceToGl: false,
      isReceiptToGl: false,
      isMeterToGl: false,
      isPettyCashToGl: false,
      isPurchaseInvoiceToGl: false,
      isPurchaseReceiptToGl: false,
      isSelectApprove: "",

      showFormPrintModal: false,
      isPrinted: false,
      path: "",
      categoryPrint: "",
      pettyCashPeriod: "",
      bookModalSelected: null,
      showWithholdingTaxButton: true,
      showWithholdingTax: false,
      showPayinPayoutButton: true,
      showPayinPayout: false,

      editingDetailAmount: 0,
      showPurchaseInvoiceSelectedModal: false,
      openFromPurchaseInvoiceSelected: "",
      purchaseInvoiceId: "",

      showPurchaseReceiptSelected: false,
      openFromPurchaseReceiptSelected: "",
      purchaseReceiptId: "",
      autoReceiptToGl: "",
      currentRowPos: null,
      currentColPos: null,
      pageTopPos: 0,
      pageBottomPos: 0,
      justCameFromAccountCode: false,
      selectedRow: null,
      shouldShowDataGrid: true,
      storageName: "",
      activeStatus: false,
      dataConfig: null,
      objToCompare: null,
      isInvoiceSelectReady: false,
      isReceiptSelectReady: true,
    };
  },
  //change from created to mounted
  async mounted() {
    this.getAllAccountingDetails();
    this.initiateData();

    this.storageName = `erp_search_accountingEntryList_${this.selectedProject.code}_${this.userCode}`;
    await this.$store.dispatch("shared/resetAccountingFrequently");
    if (!this.isCreateDocument) {
      const where = {
        id: this.id,
      };
      this.setLoading(true);
      await this.$store.dispatch("glAccountingEntry/getAccountingEntry", {
        where,
      });

      //Get ข้อมูล ReceiptSelected ที่บันทึกไว้
      if (this.GetPathGlReceipt) {
        await this.$store.dispatch("glAccountingReceipt/resetReceiptSelected");
        const payload = {
          page: 1,
          limit: 100,
          where: {
            projectId: this.selectedProject.id,
            voucherId: this.id,
            postType: "edit",
          },
        };
        // console.log("getReceiptSelected=", payload);
        await this.$store.dispatch(
          "glAccountingReceipt/getAllReceiptSelected",
          payload
        );
        // await this.$store.dispatch("glAccountingReceipt/getReceiptSelected", payload);
      } else {
        this.getDataInvoiceSelected();
      }
    } else {
      this.isInvoiceSelectReady = true;
      this.isReceiptSelectReady = true;
      this.initiateData();
    }

    const localSearch = localStorage.getItem(this.storageName);
    if (localSearch) {
      let search = JSON.parse(localSearch);
      this.bookModalSelected = search.bookModalSelected;
    }

    if (this.GetPathGlInvoice) {
      this.showInvoiceToGlButton = true;
      this.showMeterToGlButton = true;
      this.showRevertButton = true;
      this.showWithholdingTaxButton = false;
      this.showPayinPayoutButton = false;
    }

    if (this.GetPathGlReceipt) {
      this.showReceiptToGl = true;
      this.showWithholdingTaxButton = false;
      this.showPayinPayoutButton = false;
    }
    if (this.GetPathGlAccounting) {
      // if (this.bookModalSelected.generalLedgerBookCode == "PV") {
      if (this.glBook == "PV") {
        this.showWithholdingTaxButton = true;
        this.showPayinPayoutButton = true;
      } else if (this.glBook == "JV" || this.glBook == "PJ") {
        this.showWithholdingTaxButton = true;
        this.showPayinPayoutButton = false;
      } else {
        this.showWithholdingTaxButton = false;
        this.showPayinPayoutButton = false;
      }
      await this.getCheckGlBooks();
      this.getWithholdingTaxWithVouchers(); //get data withholding tax voucher
    }

    if (this.GetPathGlPettyCash) {
      this.getWithholdingTaxWithVouchers(); //get data withholding tax voucher
    }

    if (this.GetPathGlPettyCash) {
      this.showPayinPayoutButton = false;
    }

    if (this.GetPathGlAccounting && this.checkGlBook) {
      this.setPurchaseInvoiceSelected();
    }
    // this.setAccounting("Y");
    // this.setLoading(false);
  },
  methods: {
    // async moveToCell(increment = 0) {
    //   this.shouldShowDataGrid = false;
    //   await setTimeout(async () => {
    //     this.shouldShowDataGrid = true;
    //     await setTimeout(async () => {
    //       let currentIndex = this.selectedRowIndex + increment;
    //       let pageBottomPos = this.pageBottomPos;
    //       while (currentIndex > pageBottomPos) {
    //         // await setTimeout(async () => {
    //         this.$refs.grid.nextPage();
    //         pageBottomPos += pageBottomPos;
    //         // },100);
    //       }
    //       currentIndex = currentIndex - this.pageTopPos;
    //       this.$refs.grid.moveTo(currentIndex, this.currentColPos);
    //     }, 100);
    //   }, 10);
    // },
    goBack() {
      this.compareObject(this.objToCompare, this.payload);
      const localSearch = localStorage.getItem(this.storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        editAccountingEntry: "Edit",
      };
      // console.log("search",search);
      localStorage.setItem(this.storageName, JSON.stringify(search));

      // this.$router.push(`/gl/accounting/${this.glBook}`);

      if (this.GetPathGlAccounting)
        this.$router.push({
          name: "gl-accounting-book",
          params: {
            glBook: this.glBook,
            idBook: this.idBook,
          }
        });
      if (this.GetPathGlReceipt)
        this.$router.push({
          name: "gl-receipt-book",
          params: {
            glBook: this.glBook,
            idBook: this.idBook,
          }
        });
      if (this.GetPathGlInvoice)
        this.$router.push({
          name: "gl-invoice-book",
          params: {
            glBook: this.glBook,
            idBook: this.idBook,
          }
        });
      if (this.GetPathGlPettyCash)
        this.$router.push({
          name: "gl-pettycash-book",
          params: {
            glBook: this.glBook,
            idBook: this.idBook,
          }
        });
    },
    getApproveName() {
      this.clearApproved = true;
      // console.log("this.selectApprove=",this.selectApprove)
      if (this.selectApprove === null || this.selectApprove === "") {
        this.approveName = null;
      } else this.approveName = localStorage.getItem("erp_userName");
      if (this.selectApprove == "Y") {
        this.reasonNonApprove = "";
      }
    },
    async initiateData() {
      this.code = "";
      this.date = new Date();
      this.description = null;
      this.remark = null;
      this.selectType = " ";
      // this.selectStatus = "A";
      this.activeStatus = false;
      this.selectApprove = "";
      this.approveName = null;
      this.reasonNonApprove = "";
      this.totalDebit = 0;
      this.totalCredit = 0;
      this.diff = 0;
      this.details = [];
      this.addEmptyRow(true);
      this.meterDetail = null;
      this.isPrinted = false;

      this.isInvoiceToGl = false;
      this.isReceiptToGl = false;
      this.isMeterToGl = false;
      this.isPettyCashToGl = false;
      this.isPurchaseInvoiceToGl = false;
      this.isPurchaseReceiptToGl = false;
      this.isSelectApprove = "";
      this.pettyCashPeriod = "";
      this.$store.dispatch("glAccountingEntry/resetAccountingEntry");
      this.$store.dispatch("glMeterToGl/setMeterSelected", []);
      this.$store.dispatch("glAccountingInvoice/setSelectedInvoice", []);
      this.$store.dispatch("glAccountingReceipt/setReceiptSelected", []);
      this.$store.dispatch("glAccountingReceipt/clearReceiptToGl", []);
      // this.$store.dispatch("shared/resetAccounting");
      this.$store.dispatch("apWithholdingTax/resetWithholdingTaxList");
      this.$store.dispatch("glPurchaseInvoice/resetPurchaseInvoice", []);
      this.$store.dispatch("glPurchaseInvoice/setPurchaseInvoiceSelected", []);
      this.$store.dispatch("apPurchaseReceipt/resetPurchaseReceiptImport");
      this.$store.dispatch("apPurchaseReceipt/resetPurchaseReceiptsVoucher");
      this.$store.dispatch(
        "glPurchaseReceipt/resetSelectedVoucherReferenceDocument"
      );
    },
    async setData() {
      // console.log("this.accountingEntry=",this.accountingEntry)
      this.code = this.accountingEntry.code;
      this.date = new Date(this.accountingEntry.date);
      this.description = this.accountingEntry.description;
      this.remark = this.accountingEntry.remark1;
      this.selectType = this.accountingEntry.type;
      // this.selectStatus = this.accountingEntry.postStep;
      this.activeStatus = this.accountingEntry.postStep == "P" ? true : false;
      this.selectApprove = this.accountingEntry.approveStatus;
      this.isSelectApprove = this.accountingEntry.approveStatus;
      this.approveName = this.accountingEntry.approveName;
      this.reasonNonApprove = this.accountingEntry.reasonNonApprove;
      this.details = this.accountingEntry.accountingEntryDetail;
      this.diff =
        this.formatCurrency(this.totalDebit) -
        this.formatCurrency(this.totalCredit);
      this.addEmptyRow(true);
      this.updateTotal();
      this.updateItemNumber();
      this.pettyCashPeriod = this.accountingEntry.period;

      this.setLoading(false);
    },
    getNextDummyNumber() {
      let dummies = [];
      if (this.details.length > 0) {
        this.details.forEach((c) => {
          if (c.id.substring(0, 5) == "dummy") {
            dummies.push(parseInt(c.id.substring(5)));
          }
        });
        // console.log(Math.max(...dummies) );
        if (dummies.length > 0) {
          return Math.max(...dummies) + 1;
        }
      }
      return 1;
    },
    getNextItemNumber() {
      let items = [];
      if (this.details.length > 0) {
        this.details.forEach((c) => {
          if (c.itemNumber) {
            items.push(c.itemNumber);
          }
        });
        // console.log(Math.max(...dummies) );
        return Math.max(...items) + 1;
      }
      return 1;
    },
    isLastRowIsEmptyRow() {
      let lastRow = this.details.slice(-1).pop();
      if (!lastRow) {
        return false;
      }

      if (!lastRow.accountId) {
        return true;
      }

      return false;
    },
    async addEmptyRow(isPreparingData = false) {
      let rec = {
        // id: "dummy" + (parseInt(this.details.length) + 1),
        id: "dummy" + this.getNextDummyNumber(),
        headerId: null,
        itemNumber: null,
        description: null,
        accountId: null,
        accountCode: null,
        accountName: null,
        sectionId: null,
        projectId: null,
        jobId: null,
        debitAmount: null,
        creditAmount: null,
        group: null,
      };

      if (isPreparingData) {
        //ในขั้นตอนเตรียมข้อมูล data grid ยังไม่พร้อมทำงาน ให้ push เข้า details ตรงๆก่อน
        this.details.push(rec);
      } else {
        //หากมีข้อมูลใน Detail แล้ว และต้องการเพิ่มบรรทัด ต้องเพิ่มใน Datagrid
        // let currentRowPos = this.currentRowPos;
        let currentRowPos = this.currentRowPosWithPageTop;
        let currentColPos = this.currentColPos;
        this.$refs.grid.newRecord(rec);
        await setTimeout(async () => {
          this.$refs.grid.moveTo(currentRowPos, currentColPos);
        }, 100);
      }
      // console.log("addEmptyRow : ", this.details);
    },
    onPageChange(pageTopPos, pageBottomPos) {
      // console.log("pageTopPos : ", pageTopPos);
      // console.log("pageBottomPos : ", pageBottomPos);
      this.pageTopPos = pageTopPos;
      this.pageBottomPos = pageBottomPos;
      if (pageTopPos > 0) {
        let currentRowPos = this.currentRowPos + pageTopPos;
        let currentColPos = this.currentColPos;
        // console.log("currentRowPos : ", this.currentRowPos);
        // console.log("currentColPos : ", this.currentColPos);
        this.$refs.grid.moveTo(currentRowPos, currentColPos);
      }
      // await setTimeout(async () => {
      //   console.log("test");
      //   this.$refs.grid.moveTo(currentRowPos, currentColPos);
      // }, 100);
    },
    onCellFocus(event) {
      let { record, rowPos, colPos } = event;
      this.selectedRow = { ...record };
      this.currentRowPos = rowPos;
      this.currentColPos = colPos;
    },
    onUpdateTable(data) {
      // console.log("onUpdateTable data", data);
      let { $id, name, newVal, oldVal } = data[0];
      let currentRow = this.details.find((c) => c.$id == $id);
      // console.log("onUpdateTable currentRow", currentRow);
      //ถ้าเป็นตัวอักษรให้ตัดช่องว่างหัวท้ายออก
      if (oldVal) {
        if (typeof oldVal === "string") {
          oldVal = oldVal.trim();
        }
      }

      if (newVal) {
        if (typeof newVal === "string") {
          newVal = newVal.trim();
        }
      }
      //ถ้าเป็นตัวอักษรให้ตัดช่องว่างหัวท้ายออก

      if (name === "debitAmount") {
        if (newVal < 0 || !newVal) {
          // return;
          this.updateDebitAmount(currentRow, 0);
        } else {
          this.updateDebitAmount(currentRow, newVal);
          this.updateCreditAmount(currentRow, 0);
        }
      } else if (name === "creditAmount") {
        if (newVal < 0 || !newVal) {
          // console.log("debitAmount=",newValue);
          // return;
          this.updateCreditAmount(currentRow, 0);
        } else {
          this.updateCreditAmount(currentRow, newVal);
          this.updateDebitAmount(currentRow, 0);
        }
      } else if (name === "description") {
        this.updateDescription(currentRow, newVal);
      } else if (name === "accountCode") {
        //กันบัคเพิ่มบรรทัดแล้ว accountCode บรรทัดถัดไปเป็น null
        if (!newVal) {
          return;
        }
        //หาว่า Code ที่ระบุมีอยู่จริงหรือไม่
        const accounting = this.getAccountingDetailByCode(newVal);
        //หากมี ก็ update ข้อมูลใน record นั้น
        if (accounting) {
          if (newVal === "") {
            return;
          }
          if (!oldVal) {
            this.accountingSelect(currentRow, accounting);
          } else if (oldVal !== newVal) {
            this.$confirm.require({
              message: this.lang.askForAccountingChange,
              header: this.lang.confirmDialogHeader,
              icon: "pi pi-exclamation-triangle",
              accept: () => {
                //callback to execute when user confirms the action
                let currentDebitAmount = currentRow.debitAmount;
                let currentCreditAmount = currentRow.creditAmount;
                let currentDescription = currentRow.description;
                this.accountingSelect(currentRow, accounting);
                // console.log("currentRow", currentRow);
                // console.log("selectedRow", this.selectedRow);
                if (currentDebitAmount > 0) {
                  this.updateDebitAmount(
                    currentRow,
                    currentDebitAmount
                  );
                }
                if (currentCreditAmount > 0) {
                  this.updateCreditAmount(
                    currentRow,
                    currentCreditAmount
                  );
                }
                if (currentDescription != "") {
                  this.updateDescription(
                    currentRow,
                    currentDescription
                  );
                }
                
                // if (this.selectedRow.debitAmount > 0) {
                //   this.updateDebitAmount(
                //     currentRow,
                //     this.selectedRow.debitAmount
                //   );
                // }
                // if (this.selectedRow.creditAmount > 0) {
                //   this.updateCreditAmount(
                //     currentRow,
                //     this.selectedRow.creditAmount
                //   );
                // }
                // if (this.selectedRow.description != "") {
                //   this.updateDescription(
                //     currentRow,
                //     this.selectedRow.description
                //   );
                // }
              },
            });
          }
        } else {
          this.$confirm.require({
            message: this.lang.askForSelectAccounting.replace(":code", newVal),
            header: this.lang.confirmDialogHeader,
            icon: "pi pi-exclamation-triangle",
            accept: () => {
              //callback to execute when user confirms the action
              this.editingDetail = currentRow;
              this.editingDetailAmount = this.selectedRow;
              this.searchTextAccountingDetail = newVal;
              this.showAccountingModal = true;
            },
          });
        }
      }
      this.updateTotal();

      // if (name == "accountCode") {
      // }
      // console.log(name);
      // console.log(data);
      // console.log('onUpdate detail,',this.details);
      // console.log('onUpdate grid,',this.$refs.grid);
      // console.log(currentRow);
      // this.$refs.grid.undoTransaction();
    },
    onGridPressEnter() {
      //ถ้าอยู่ช่อง สุดท้าย เมื่อ กดปุ่ม Enter เสร็จให้ไปบรรทัดถัดไป
      // console.log(this.currentColPos);
      if (this.currentColPos == 7) {
        // console.log("test");
        // let currentRowPos = this.currentRowPos + this.pageTopPos;
        this.$refs.grid.moveTo(this.selectedRowIndex + 1, 2);
      }
    },
    async removeRow() {
      // let currentRowId = this.selectedRow.Id;
      // this.deleteRow(currentRowId);

      if (this.details.length > 1) {
        // console.log("removing id ", this.selectedRow.id);
        // let indexToRemove = this.details.findIndex((c) => c.id == this.selectedRow.id);
        // console.log("removing index ", indexToRemove);
        // this.$refs.grid.deleteRecord(this.currentRowPos);
        let detailsToKeep = this.details.filter(
          (c) => c.$id !== this.selectedRow.$id
        );
        this.details = [...detailsToKeep];
        // console.log("current details",this.details)
        // console.log(indexToRemove);
        // this.details.splice(this.selectedRowIndex, 1);
        this.updateTotal();
        this.updateItemNumber();
        this.$refs.grid.moveTo(this.selectedRowIndex, 2);
        // await this.moveToCell(0);
      }
    },
    async calDifferent() {
      let debit = 0;
      let credit = 0;
      this.details.forEach((detail) => {
        if (detail.id != this.selectedRow.id) {
          debit = this.sum([debit, detail.debitAmount]);
          credit = this.sum([credit, detail.creditAmount]);
        }
      });

      let index = this.details.findIndex((c) => c.id == this.selectedRow.id);

      if (this.details[index].accountId) {
        if (debit > credit) {
          this.details[index].creditAmount = this.subtract(debit, credit);
          this.details[index].debitAmount = 0.0;
        } else if (credit > debit) {
          this.details[index].creditAmount = 0.0;
          this.details[index].debitAmount = this.subtract(credit, debit);
        }
        let newDetails = this.details;
        this.details = [...newDetails];
        this.updateTotal();
      }

      // await this.moveToCell(0);
    },
    deleteRow(id) {
      const index = this.details.findIndex((detail) => detail.id === id);
      this.details.splice(index, 1);

      this.updateTotal();
      this.updateItemNumber();
    },
    addRow(detail) {
      let total = 0;
      this.details.forEach((element) => {
        if (element.id === detail.id) {
          element = { ...element, ...detail };
          // console.log(element);
        }
        total += element.id ? element.debitAmount : 0;
      });
      this.debitAmount = total;
    },
    async save() {
      this.setLoading(true);
      // console.log("set_isMeterToGl",this.meterSelected);
      if (this.invoiceSelected.length > 0) {
        // console.log("set_isInvoiceToGl",this.invoiceSelected.length);
        this.isInvoiceToGl = true;
      }
      if (this.meterSelected.length > 0) {
        // console.log("set_isMeterToGl",this.meterSelected);
        this.isMeterToGl = true;
      } else {
        this.isMeterToGl = false;
      }

      if (this.ReceiptSelected.length > 0) {
        // console.log("set_isMeterToGl",this.ReceiptSelected);
        this.isReceiptToGl = true;
      }
      if (this.validateData()) {
        this.setLoading(false);
        return;
      }

      if (this.GetPathGlInvoice && this.checkGlBook) {
        this.isInvoiceToGl = true;
      }

      if (this.GetPathGlReceipt && this.checkGlBook) {
        this.isReceiptToGl = true;
      }

      if (this.GetPathGlPettyCash && this.checkGlBook) {
        this.isPettyCashToGl = true;
      }

      if (this.GetPathGlAccounting && this.checkGlBook && this.glBook == "PJ") {
        this.isPurchaseInvoiceToGl = true;
      }

      if (this.GetPathGlAccounting && this.checkGlBook && this.glBook == "PV") {
        this.isPurchaseReceiptToGl = true;
      }

      try {
        // console.log("this.payload", this.payload);
        await this.$store.dispatch(
          "glAccountingEntry/createOrUpdateAccountingEntry",
          this.payload
        );

        if (this.banksSelected) {
          await this.importCheque();
        }

        if (this.withholdingTaxsImport) {
          await this.importWithholdingTax();
        }

        this.$swal({
          icon: "success",
          title: this.lang.saveSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        await this.$store.dispatch(
          "apPurchaseReceipt/resetPurchaseReceiptImport"
        );
        await this.$store.dispatch(
          "apPurchaseReceipt/resetPurchaseReceiptsVoucher"
        );
        await this.$store.dispatch(
          "glPurchaseReceipt/resetSelectedVoucherReferenceDocument"
        );
        if (this.isCreateDocument) {
          if (this.GetPathGlAccounting)
            await this.$router.replace({
              name: "gl-accounting-edit",
              params: {
                glBook: this.glBook,
                idBook: this.idBook,
                id: this.accountingEntry.id
              }
            });
          if (this.GetPathGlReceipt)
            await this.$router.replace({
              name: "gl-receipt-edit",
              params: {
                glBook: this.glBook,
                idBook: this.idBook,
                id: this.accountingEntry.id
              }
            });
          if (this.GetPathGlInvoice)
            await this.$router.replace({
              name: "gl-invoice-edit",
              params: {
                glBook: this.glBook,
                idBook: this.idBook,
                id: this.accountingEntry.id
              }
            });
          if (this.GetPathGlPettyCash) {
            await this.$router.replace({
              name: "gl-pettycash-edit",
              params: {
                glBook: this.glBook,
                idBook: this.idBook,
                id: this.accountingEntry.id
              }
            });
          }
        }
        await this.setCompareData();

        // if (this.GetPathGlAccounting && this.checkGlBook) {
        //   this.setPurchaseInvoiceSelected();
        // }
      } catch (error) {
        let message = error
        let classTitle = "error";
        if (error instanceof Object && error !== null) {
          if (error.code && error.code == "423") {
            message = this.lang.documentIsLockDetail.split("<br>");
            classTitle = "";
          }
        }
        await this.setDialog({
          title: this.lang.documentIsLockedTitle,
          message: message,
          classTitle: classTitle,
          button: null,
        });
        this.setLoading(false);
        await this.dialogNotification.reveal();
        // this.$swal({
        //   icon: "error",
        //   html: `<p class="${this.textAlertClass}">${message}</p>`,
        //   confirmButtonText: this.lang.close,
        //   allowOutsideClick: false,
        // });
      }
      this.setLoading(false);
    },
    validateData() {
      if (this.isCheckApprove && this.selectApprove === "Y") {
        this.$swal({
          icon: "warning",
          title: this.lang.validateApprovePermission,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }
      if (this.details.length <= 1) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateDetailSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }
      if (
        this.formatCurrency(this.totalDebit) !==
        this.formatCurrency(this.totalCredit)
      ) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateTotalAmount,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }
      if (
        this.GetPathGlPettyCash &&
        this.pettyCashPeriod == "" &&
        (this.selectApprove == "Y" || this.selectApprove == "N")
      ) {
        this.$swal({
          icon: "warning",
          title: this.lang.validatePettyCashPeriod,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }
      if (
        this.GetPathGlPettyCash &&
        this.selectApprove == "N" &&
        this.reasonNonApprove == ""
      ) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNonApprove,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }
      if (this.totalDebit <= 0 && this.totalCredit <= 0) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateTotalAmount2,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }
      if (this.details) {
        let setDataAmount = "";
        let i = 0;
        let checkDescriptionLength = false;
        let rowNumber = "";
        this.details.forEach((data) => {
          // console.log(data);
          // console.log(data.creditAmount, data.debitAmount);
          if (
            data.creditAmount <= 0 &&
            data.debitAmount <= 0 &&
            i < this.details.length - 1 &&
            data.accountId != null
          ) {
            setDataAmount = "Empty";
            return;
          }
          i++;
          // if((data.creditAmount == data.debitAmount)
          //     || (data.creditAmount > 0 && data.debitAmount > 0)
          //     && data.id != "dummy"){
          //   setDataAmount = "Equal"
          //   return
          // }
          if (
            data.description &&
            data.description.length > 500 &&
            !checkDescriptionLength
          ) {
            rowNumber = data.itemNumber;
            checkDescriptionLength = true;
            return;
          }
        });
        if (setDataAmount == "Empty") {
          this.$swal({
            icon: "warning",
            title: this.lang.validateTotalAmount3,
            confirmButtonText: this.lang.close,
            allowOutsideClick: false,
          });
          return true;
        }
        if (checkDescriptionLength) {
          this.$swal({
            icon: "warning",
            title: this.lang.validateDescriptionLength.replace(
              ":number",
              rowNumber
            ),
            confirmButtonText: this.lang.close,
            allowOutsideClick: false,
          });
          return true;
        }
      }

      if (this.isAnyNullAccountId) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateTotalAmount4,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      return false;
    },
    updateDebitAmount(detail, debitAmount) {
      // console.log("updateDebitAmount", detail);
      // console.log("updateDebitAmount", debitAmount);
      detail.debitAmount = debitAmount;
      this.addRow(detail);
    },
    updateCreditAmount(detail, creditAmount) {
      // console.log("updateCreditAmount", detail);
      // console.log("updateCreditAmount", creditAmount);
      detail.creditAmount = creditAmount;
      this.addRow(detail);
    },
    updateDescription(detail, description) {
      detail.description = description;
      this.addRow(detail);
    },
    updateItemNumber() {
      let number = 1;
      this.details.forEach((element) => {
        // console.log("element", element.accountId);
        // if (!element.id.includes("dummy")) {
        if (element.accountId) {
          element.itemNumber = number;
          number++;
        }
      });
    },
    updateTotal() {
      let sumDebit = 0;
      let sumCredit = 0;
      this.details.forEach((detail) => {
        sumDebit = this.sum([sumDebit, detail.debitAmount]);
        sumCredit = this.sum([sumCredit, detail.creditAmount]);
      });
      // this.totalDebit = parseFloat(this.formatCurrency(sumDebit).replace(",", ""));
      // this.totalCredit = parseFloat(this.formatCurrency(sumCredit).replace(",", ""));
      this.totalDebit = sumDebit;
      this.totalCredit = sumCredit;
      // console.log("this.totalDebit", this.totalDebit);
      // console.log("this.totalCredit", this.totalCredit);
      // this.diff = this.totalDebit.toFixed(2) - this.totalCredit.toFixed(2);
      this.diff = this.subtract(this.totalDebit, this.totalCredit)
      // console.log("this.diff", this.diff);
    },
    async accountingSelect(detail, accounting) {
      // const today = new Date();
      // let number = this.details.length;
      // console.log("detail", detail);
      let number = 0;
      if (detail.id.includes("dummy")) {
        number = this.details.length;
      } else {
        number = detail.itemNumber;
      }
      detail.accountCode = accounting.code;
      detail.accountId = accounting.id;
      detail.accountName = accounting.name;
      detail.creditAmount = 0;
      detail.debitAmount = 0;
      detail.description = "";
      detail.group = null;
      detail.headerId = null;
      // detail.id = accounting.id + today.getSeconds();
      detail.itemNumber = number;
      detail.jobId = null;
      detail.projectId = null;
      detail.sectionId = null;

      this.addRow(detail);
      this.updateItemNumber();
      if (!this.isLastRowIsEmptyRow()) {
        this.emptyRow();
      } else {
        await setTimeout(async () => {
          // console.log("selectedRowIndex",this.selectedRowIndex);
          // this.$refs.grid.moveTo(this.currentRowPos, this.currentColPos);
          this.$refs.grid.moveTo(this.selectedRowIndex, this.currentColPos);
        }, 100);
      }
    },
    async getAccountingFrequently() {
      try {
        this.setLoading(true);
        const localSearch = localStorage.getItem(this.storageName);
        if (localSearch) {
          let search = JSON.parse(localSearch);
          search.generalLedgerBookCode = this.glBook;
          search.generalLedgerBookId = this.idBook;
          localStorage.setItem(this.storageName, JSON.stringify(search));
        }
        await this.$store.dispatch("shared/resetAccountingFrequently");
        await this.$store.dispatch("shared/getAllAccountingFrequentlies");
        this.setLoading(false);
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.meterDetail = null;
      this.showAccountingFrequentlyModal = true;
    },
    getAccounting() {
      this.showAccountingModal = true;
    },
    async getInvoice() {
      await this.getCheckGlBooks();

      // console.log("checkGlBook=", this.checkGlBook);

      if (!this.checkGlBook) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateInvoiceCheckGlBook.replace(
            ":code",
            this.glBook
          ),
          // title: this.lang.validateInvoiceCheckGlBook.replace(":code", this.bookModalSelected.generalLedgerBookCode),
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      const today = new Date();
      this.showInvoiceModal = true;
      this.openFrom = this.id + today.getSeconds();
    },
    addDetail(data) {
      // console.log("data", data);
      this.details.push(data);
    },
    async accountingFrequentlyModalSelected(data) {
      this.showAccountingFrequentlyModal = false;
      if (this.details[this.details.length - 1].accountId == null) {
        this.details.pop();
      }
      if (data) {
        data.datails.forEach((accounting) => {
          const today = new Date();
          // let credit = parseFloat(this.formatCurrency(accounting.creditAmount).replace(",", ""));
          // let debit = parseFloat(this.formatCurrency(accounting.debitAmount).replace(",", ""));
          let credit = accounting.creditAmount;
          let debit = accounting.debitAmount;
          let detail = {
            accountCode: accounting.accountCode,
            accountId: accounting.accountId,
            accountName: accounting.accountName,
            creditAmount: credit ? credit : 0,
            debitAmount: debit ? debit : 0,
            description: accounting.description,
            group: null,
            id: accounting.id + today.getSeconds(),
            headerId: null,
            // itemNumber: parseInt(this.details.length) + 1,
            itemNumber: this.getNextItemNumber(),
            jobId: null,
            projectId: null,
            sectionId: null,
            code: null,
            date: new Date(),
          };
          // console.log("detail", detail)
          this.addDetail(detail);
        });
      }
      this.description = data.description;
      this.updateItemNumber();
      this.updateTotal();
      // this.emptyRow();
      this.addEmptyRow(true);
      this.shouldShowDataGrid = false;
      await setTimeout(async () => {
        this.shouldShowDataGrid = true;
      }, 10);
    },
    accountingModalSelected(accounting) {
      this.accountingSelect(this.editingDetail, accounting);
      // console.log('accountingModalSelected',this.selectedRow);
      if (this.selectedRow.debitAmount > 0) {
        this.updateDebitAmount(
          this.editingDetail,
          this.selectedRow.debitAmount
        );
      }
      if (this.selectedRow.creditAmount > 0) {
        this.updateCreditAmount(
          this.editingDetail,
          this.selectedRow.creditAmount
        );
      }
      if (this.selectedRow.description != "") {
        this.updateDescription(
          this.editingDetail,
          this.selectedRow.description
        );
      }
      this.updateTotal();
      this.showAccountingModal = false;
    },
    async invoiceModalSelected(data) {
      this.setLoading(true);
      this.details = data;
      if (this.meterSelected.length > 0) {
        if (this.meterToGl.length <= 0) {
          // const localSearch = localStorage.getItem(this.storageName);
          // let search = JSON.parse(localSearch);
          let meterDetail = [];
          let requestMeter = {
            projectId: this.selectedProject.id,
            // voucherBookId: search.generalLedgerBookId,
            voucherBookId: this.idBook,
          };

          this.meterSelected.forEach((element) => {
            let product = "";
            if (element.productCode.substring(0, 1) === "W") {
              product = "W0";
            } else if (element.productCode.substring(0, 1) === "E") {
              product = "E0";
            }
            let detail = {
              ProductCode: product,
              Period: element.period,
              TotalAmount: element.totalAmount,
            };
            meterDetail.push(detail);
          });

          let meters = { Meters: meterDetail };
          requestMeter = { ...requestMeter, ...meters };

          await this.$store.dispatch("glMeterToGl/getMeterToGl", requestMeter);
        }

        this.meterToGl.forEach((Detail) => {
          let meter = [
            {
              ProductType: Detail.period.substring(0, 1),
              Period: Detail.period.substring(2),
            },
          ];

          let detail = {
            accountId: Detail.accountId,
            accountCode: Detail.accountCode,
            accountName: Detail.accountName,
            creditAmount: Detail.creditAmount,
            debitAmount: Detail.debitAmount,
            description: Detail.description,
            group: null,
            headerId: Detail.headerId,
            id: Detail.id,
            itemNumber: 0,
            jobId: null,
            projectId: this.selectedProject.id,
            sectionId: null,
            meters: meter,
          };
          this.details.push(detail);
        });
      }
      this.isInvoiceToGl = true;

      this.updateTotal();
      // this.emptyRow();
      this.updateItemNumber();
      this.setLoading(false);
      this.addEmptyRow(true);
    },
    emptyRow() {
      const emptyRow = this.details.find((detail) => !detail.accountId);
      if (emptyRow != null) {
        let item = this.details.indexOf(emptyRow);
        this.details.splice(item, 1);
        this.addEmptyRow();
      } else {
        // console.log("emptyRow == null here")
        this.addEmptyRow();
      }
    },
    async createDocument() {
      if (!this.isCreateDocument) {
        if (this.GetPathGlAccounting)
          await this.$router.replace({
            name: "gl-accounting-edit",
            params: {
              glBook: this.glBook,
              idBook: this.idBook,
              id: "create"
            }
          });
        if (this.GetPathGlReceipt)
          await this.$router.replace({
            name: "gl-receipt-edit",
            params: {
              glBook: this.glBook,
              idBook: this.idBook,
              id: "create"
            }
          });
        if (this.GetPathGlInvoice)
          await this.$router.replace({
            name: "gl-invoice-edit",
            params: {
              glBook: this.glBook,
              idBook: this.idBook,
              id: "create"
            }
          });
        if (this.GetPathGlPettyCash)
          await this.$router.replace({
            name: "gl-pettycash-edit",
            params: {
              glBook: this.glBook,
              idBook: this.idBook,
              id: "create"
            }
          });
      }
      this.isInvoiceSelectReady = true;
      await this.initiateData();
      await this.setCompareData();
    },
    async askForCancel() {
      //
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForCancelDocument}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        await this.CancelAccounting();
      } else if (result.isDenied) {
        //
      }
    },
    validateAccountingCancel() {
      if (this.GetPathGlPettyCash && this.ValidatorPettyCashCancel != "") {
        this.$swal({
          icon: "warning",
          title: this.ValidatorPettyCashCancel,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }
      // console.log("withholdingTaxs", this.withholdingTaxs);
      let title = "";
      let rvhCode = "";
      let rvhBookCode = "";
      let cancelLink = "";
      let cancelLinkList = [];
      if (this.withholdingTaxs) {
        this.withholdingTaxs.forEach((withholdingTax) => {
          rvhCode = withholdingTax.rvhcode;
          rvhBookCode = withholdingTax.rvhbookCode;
          cancelLink = withholdingTax.cancelStatus;
          if (cancelLink != "C") cancelLinkList.push(cancelLink);
        });
      }
      // console.log("rvhCode", rvhCode);
      // console.log("cancelLinkList", cancelLinkList);
      if (cancelLinkList.length > 0) {
        if (rvhCode != "") {
          title =
            this.lang.validateCancel + "\n" + rvhBookCode + " : " + rvhCode;
        } else {
          title = this.lang.validateCancel;
        }
        this.$swal({
          icon: "warning",
          title: title,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }
      return false;
    },
    async CancelAccounting() {
      if (this.validateAccountingCancel()) {
        return;
      }
      this.setLoading(true);
      const payload = {
        ProjectId: this.selectedProject.id,
        Id: this.id,
      };

      try {
        await this.$store.dispatch(
          "glAccountingEntry/CancelAccounting",
          payload
        );
        this.$swal({
          icon: "success",
          title: this.lang.cancelSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      } catch (error) {
        let message = error
        let classTitle = "error";
        if (error instanceof Object && error !== null) {
          if (error.code && error.code == "423") {
            message = this.lang.documentIsLockDetail.split("<br>");
            classTitle = "";
          }
        }
        await this.setDialog({
          title: this.lang.documentIsLockedTitle,
          message: message,
          classTitle: classTitle,
          button: null,
        });
        this.setLoading(false);
        await this.dialogNotification.reveal();
      }
      this.setLoading(false);
    },
    async checkShouldSetData() {
      if (this.GetPathGlInvoice) {
        if (this.isInvoiceSelectReady && this.accountingEntry) {
          this.setData();
        }
      } else if(this.GetPathGlReceipt){
        if (this.isReceiptSelectReady && this.accountingEntry) {
          this.setData();
        }        
      } else {
        if (this.accountingEntry) {
          this.setData();
        }
      }
      await this.setCompareData();  
    },
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    async AddReceiptToGl() {
      await this.getCheckGlBooks();

      if (!this.checkGlBook) {
        this.$swal({
          icon: "warning",
          // title: this.lang.validateReceiptCheckGlBook.replace(":code", this.bookModalSelected.generalLedgerBookCode),
          title: this.lang.validateReceiptCheckGlBook.replace(
            ":code",
            this.glBook
          ),
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      const today = new Date();
      this.showAccountingReceipt = true;
      this.openFrom = this.id + today.getSeconds();
    },
    selectedReceiptToGl(Data) {
      this.isReceiptToGl = true;
      this.details = Data;

      if (Data.length > 0) {
        let date = "วันที่ " + this.CompoundGetDateFirst;
        if (this.CompoundGetDateFirst != this.CompoundGetDateLast)
          date += " ถึง: " + this.CompoundGetDateLast;
        let Code = " เลขที่เอกสารประกอบ: " + this.CompoundGetCodeFirst.trim();
        if (this.CompoundGetCodeFirst.trim() != this.CompoundGetCodeLast.trim())
          Code += " ถึง: " + this.CompoundGetCodeLast.trim();
        this.description = date + Code;
      } else {
        this.description = "";
      }

      this.updateTotal();
      // this.emptyRow();
      this.updateItemNumber();

      this.setLoading(false);
      this.addEmptyRow(true);
    },
    async getMeterToGl() {
      await this.$store.dispatch("shared/resetAccountingFrequently");
      await this.$store.dispatch("shared/getAllAccountingFrequentlies");
      const today = new Date();
      this.showMeterToGlModal = true;
      this.openFrom = this.id + today.getSeconds();
    },
    async meterToGlSelected(data) {
      this.setLoading(true);
      this.details = data;
      // console.log(this.invoiceToGl);
      // console.log(this.invoiceSelected);
      if (this.invoiceSelected.length > 0) {
        if (this.invoiceToGl.length <= 0) {
          let invoiceDetails = [];
          let requestInvoice = { projectId: this.selectedProject.id };
          this.invoiceSelected.forEach((invoice) => {
            let detail = {
              headerId: invoice.id,
              code: invoice.code,
            };
            invoiceDetails.push(detail);
          });
          let invoices = { data: invoiceDetails };
          requestInvoice = { ...requestInvoice, ...invoices };
          await this.$store.dispatch(
            "glAccountingInvoice/getInvoiceToGl",
            requestInvoice
          );
        }
        this.invoiceToGl.forEach((invoice) => {
          let detail = {
            accountId: invoice.accountId,
            accountCode: invoice.accountCode,
            accountName: invoice.accountName,
            creditAmount: invoice.creditAmount,
            debitAmount: invoice.debitAmount,
            description: invoice.description,
            group: null,
            headerId: null,
            id: invoice.id,
            // itemNumber: parseInt(this.details.length) + 1,
            itemNumber: this.getNextItemNumber(),
            jobId: null,
            projectId: null,
            sectionId: null,
            meters: [],
          };

          this.details.push(detail);
        });
      }
      this.isMeterToGl = true;

      this.updateTotal();
      // this.emptyRow();
      this.updateItemNumber();
      this.setLoading(false);
      this.addEmptyRow(true);
    },
    async compoundReceiptToGl() {
      let details = [];
      let requestReceipt = { projectId: this.selectedProject.id };
      this.ReceiptSelected.forEach((Receipt) => {
        let detail = {
          headerId: Receipt.id,
          code: Receipt.code,
        };
        details.push(detail);
      });
      let Receipts = { data: details };
      requestReceipt = { ...requestReceipt, ...Receipts };

      await this.$store.dispatch(
        "glAccountingReceipt/getReceiptToGl",
        requestReceipt
      );
    },
    async compound() {
      this.compoundEpay = [];
      this.setLoading(true);

      // console.log("this.ReceiptSelected=",this.ReceiptSelected);
      // if (this.ReceiptSelected.length <= 0) {
      // 	this.setLoading(false);
      // 	return;
      // }

      if (this.ReceiptToGl.length <= 0) {
        await this.compoundReceiptToGl();
        this.details = this.ReceiptToGl;
      }

      let description = "";
      if (this.ReceiptSelected.length > 0) {
        let date = "วันที่ " + this.CompoundGetDateFirst;
        if (this.CompoundGetDateFirst != this.CompoundGetDateLast)
          date += " ถึง: " + this.CompoundGetDateLast;
        let Code = " เลขที่เอกสารประกอบ: " + this.CompoundGetCodeFirst.trim();
        if (this.CompoundGetCodeFirst.trim() != this.CompoundGetCodeLast.trim())
          Code += " ถึง: " + this.CompoundGetCodeLast.trim();
        description = date + Code;
        this.description = date + Code;
      }

      // console.log("this.details=",this.details);
      //this.ReceiptToGl.forEach((detail) => {
      this.details.forEach((detail) => {
        let data = detail;
        // console.log("detail=",detail);
        if (detail.accountId) {
          if (detail.creditAmount == 0) {
            // console.log("compound_debitAmount=", num);
            // if (detail.payType == "cash" || detail.payType == "deposit") { //ยุราบอกเอาแต่ "cash"
            if (detail.payType == "cash") {
              // console.log("detail.payType=", detail.payType);
              if (detail.payType == "cash")
                data.description = "เงินสด " + description;
              // if (detail.payType == "deposit") data.description = "หักจากเงินฝาก " + description; //ยุราบอกเอาแต่ "cash"
              this.SetCompoundDebitCashy(data);
              //this.compoundEpay.push(detail);
            } else {
              this.SetCompoundDebitEpay(detail);
            }
          } else {
            // console.log("compound_creditAmount",detail)
            data.description = description;
            this.SetCompoundCreditEpay(data);
          }
        }
      });

      this.details = this.compoundEpay;
      this.$store.dispatch(
        "glAccountingReceipt/clearReceiptToGl",
        this.compoundEpay
      );
      // console.log("this.compoundEpay=", this.compoundEpay);

      this.updateTotal();
      // this.emptyRow();
      this.updateItemNumber();
      // this.description = date + Code;

      this.setLoading(false);
      this.addEmptyRow(true);

      // console.log("this.ReceiptSelected=", this.ReceiptSelected);
      // console.log("this.CompoundGetCodeFirst=", this.CompoundGetCodeFirst);
      // console.log("this.CompoundGetCodeLast=", this.CompoundGetCodeLast);
      // console.log("this.CompoundGetDateFirst=", this.CompoundGetDateFirst);
      // console.log("this.CompoundGetDateLast=", this.CompoundGetDateLast);
    },
    SetCompoundDebitCashy(detail) {
      // console.log("detail",detail);
      const data = this.compoundEpay.find(
        (d) =>
          d.accountCode.trim() === detail.accountCode.trim() &&
          d.payType === detail.payType &&
          // d.code === detail.code &&
          d.creditAmount === 0
      );
      if (data) {
        data.debitAmount = parseFloat(
          (data.debitAmount + detail.debitAmount).toFixed(2)
        );
      } else {
        this.compoundEpay.push(detail);
      }
    },
    SetCompoundDebitEpay(detail) {
      // console.log("detail",detail);
      const data = this.compoundEpay.find(
        (d) =>
          d.accountCode.trim() === detail.accountCode.trim() &&
          d.payType === detail.payType &&
          d.code === detail.code &&
          d.creditAmount === 0
      );
      if (data) {
        data.debitAmount = parseFloat(
          (data.debitAmount + detail.debitAmount).toFixed(2)
        );
      } else {
        this.compoundEpay.push(detail);
      }
    },
    SetCompoundCreditEpay(detail) {
      const data = this.compoundEpay.find(
        (d) =>
          d.accountCode.trim() === detail.accountCode.trim() &&
          d.debitAmount === 0
      );
      if (data) {
        data.creditAmount = parseFloat(
          (data.creditAmount + detail.creditAmount).toFixed(2)
        );
      } else {
        this.compoundEpay.push(detail);
      }

      // console.log("Compound",Compound);
    },
    async askForRevert() {
      if (this.isCreateDocument) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateSaveAndGotoRevert,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }
      let result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askRevertGeneralLedger}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748B",
      });
      if (result.isConfirmed) {
        this.$router.replace({
          name: "revert-accounting",
          params: {
            glBook: this.selectedGlBook,
            idBook: this.glBookId,
            id: this.id,
          }
        });
      } 
    },
    async getDataInvoiceSelected() {
      //Get ข้อมูล invoiceSelected ที่บันทึกไว้
      if (this.GetPathGlInvoice) {
        await this.$store.dispatch(
          "glAccountingInvoice/resetInvoiceSelectedList"
        );
        const payload = {
          page: 1,
          limit: 100,
          where: {
            projectId: this.selectedProject.id,
            voucherId: this.id,
            postType: "edit",
          },
        };
        // console.log("payload", payload);

        // await this.$store.dispatch("glAccountingInvoice/getInvoiceSelected", payload);
        await this.$store.dispatch(
          "glAccountingInvoice/getAllInvoiceSelected",
          payload
        );
        this.isInvoiceSelectReady = true;

        await this.$store.dispatch("glMeterToGl/getMeterSelected", payload);
      }
    },
    async selectPrintForm() {
      let category = "";
      // const localSearch = localStorage.getItem(this.storageName);
      // if (localSearch) {
      // 	let search = JSON.parse(localSearch);
      // 	category = search.generalLedgerBookCode;
      // }
      category = this.glBook;
      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: category,
      };
      const payload = { where };
      await this.getFormPrints(payload);

      if (this.filteredFormPrints.length == 1) {
        this.path = this.filteredFormPrints[0].path;
        if (this.GetPathGlPettyCash) {
          this.PrintPettyCash();
        } else {
          this.PrintAccounting();
        }
      } else {
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path;
      this.categoryPrint = event.category;
      if (this.GetPathGlPettyCash) {
        this.PrintPettyCash();
      } else {
        this.PrintAccounting();
      }
      this.showFormPrintModal = false;
    },
    async PrintAccounting() {
      this.setLoading(true);
      try {
        let parameter = {
          ProjectId: this.selectedProject.id,
          GlbId: this.idBook,
          Id: this.id,
          CodeStart: '',
          CodeEnd: '',
        };

        let payload = {
          path: this.path +".pdf",
          fileName: "Voucher",
          parameter: JSON.stringify(parameter),
        };

        await this.$store.dispatch("shared/printForm", payload);
        this.isPrinted = true;

        let blob = this.$store.getters["shared/blob"];
        this.getPreviewBlob(blob);

        this.setLoading(false);
      } catch (error) {
        // console.log("error=", error);
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        this.isPrinted = false;
        this.setLoading(false);
      }
    },
    async PrintPettyCash() {
      this.isPrinted = false;
      this.setLoading(true);
      let category = this.glBookId;
      let printType = "";
      const payload = {
        id: this.id,
        projectId: this.selectedProject.id,
        Type: printType,
        path: this.path,
        GlbId: category,
      };

      // console.log("PrintPettyCash=", payload);
      try {
        await this.$store.dispatch("glPettyCash/PrintPettyCash", payload);
      } catch (error) {
        // console.log("error=", error);
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        this.setLoading(false);
      }
      let blob = this.$store.getters["glPettyCash/blob"];
      // console.log('blob',blob);
      this.getPreviewBlob(blob);

      this.setLoading(false);
    },
    getWithholdingTax() {
      this.showWithholdingTax = true;
    },
    getPayinPayout() {
      this.showPayinPayout = true;
    },
    closeWithholdingTax() {
      this.showWithholdingTax = false;
    },
    closePayinPayout() {
      this.showPayinPayout = false;
    },
    async getWithholdingTaxWithVouchers() {
      let where = {
        projectId: this.selectedProject.id,
        voucherId: this.id,
      };

      const payload = {
        page: 1,
        limit: 100,
        where,
      };

      await this.$store.dispatch(
        "apWithholdingTax/getWithholdingTaxWithVouchers",
        payload
      );
    },
    async getCheckGlBooks() {
      let dcb = "";
      if (this.GetPathGlInvoice) dcb = "BILL";
      else if (this.GetPathGlReceipt) dcb = "INV";
      else if (this.GetPathGlAccounting && this.glBook === "PJ")
        dcb = "PI-0,PI-7,PV-0,PV-7";
      else if (this.GetPathGlAccounting && this.glBook === "PV")
        dcb = "PT-0,PT-7,PZ-7";
      let payload = {
        projectId: this.selectedProject.id,
        dcbCode: dcb,
        glbid: this.idBook,
      };

      // console.log("payload", payload);

      await this.$store.dispatch("glCheckDocumentToGL/getGlBooks", payload);
    },
    async addPurchaseInvoiceToGl() {
      //เปิดDialog รายการใบวางบิลที่เลือก
      await this.getCheckGlBooks();

      if (!this.checkGlBook) {
        this.$swal({
          icon: "warning",
          title: this.lang.validatePurchaseInvoiceCheckGlBook.replace(
            ":code",
            this.bookModalSelected.generalLedgerBookCode
          ),
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      const today = new Date();
      this.openFromPurchaseInvoiceSelected = this.id + today.getSeconds();
      this.showPurchaseInvoiceSelectedModal = true;
    },
    confirmPurchaseInvoiceToGl(data, voucherDate, voucherDescription) {
      this.details = data;
      if(voucherDate) this.date = new Date(voucherDate);
      if (this.purchaseInvoiceSelected.length == 1) 
      {
        this.description = data[0].description;
      }
      if (voucherDescription)
      {
        this.description = voucherDescription;
      }
      this.updateTotal();
      this.updateItemNumber();
      this.setLoading(false);
      this.addEmptyRow(true);
    },
    async setPurchaseInvoiceSelected() {
      this.$store.dispatch("glPurchaseInvoice/setPurchaseInvoiceSelected", []);
      let where = {
        projectId: this.selectedProject.id,
        PostType: "edit",
        VoucherId: this.id,
      };

      const payload = {
        page: 1,
        limit: 100,
        where,
      };

      try {
        // console.log("payload=", payload);
        await this.$store.dispatch(
          "glPurchaseInvoice/getPurchaseInvoiceSelected",
          payload
        );
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
    },

    async addPurchaseReceiptToGl() {
      //เปิด Dialog รายการ PT ที่เลือก
      // console.log("addPurchaseReceiptToGl");
      await this.getCheckGlBooks();

      if (!this.checkGlBook) {
        this.$swal({
          icon: "warning",
          title: this.lang.validatePurchaseReceiptCheckGlBook.replace(
            ":code",
            this.glBook
          ),
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      const today = new Date();
      this.openFromPurchaseReceiptSelected = this.id + today.getSeconds();
      this.showPurchaseReceiptSelected = true;
    },
    confirmPurchaseReceiptToGl(data, voucherDate, voucherDescription) {
      // this.setLoading(true);
      this.details = data;
      if(voucherDate) this.date = new Date(voucherDate);
      if (this.purchaseReceiptImport.length == 1) 
      {
        this.description = data[0].description;
      }
      if (voucherDescription)
      {
        this.description = voucherDescription;
      }
      this.updateTotal();
      this.updateItemNumber();
      this.emptyRow();
      // this.setLoading(false);
      this.addEmptyRow(true);
    },
    async importCheque() {
      this.banksSelected.forEach((item) => {
        let bankVoucher = {
          PayId: item.id,
          VoucherId: this.accountingEntry.id,
          RefType: "VCH",
          PayAmount: item.payAmount,
          ProjectId: this.selectedProject.id,
        };
        // console.log(bankVoucher);
        this.$store.dispatch("bank/AddBankVoucher", bankVoucher);
      });
      await this.$store.dispatch("bank/resetBanksSelected");
    },
    async importWithholdingTax() {
      this.withholdingTaxsImport.forEach((item) => {
        let whtImport = {
          TaxId: item.id,
          VCHId: this.accountingEntry.id,
          RefType: "VCH",
          taxTotalAmount: item.taxTotalAmount,
          ProjectId: this.selectedProject.id,
          documentBookCode: item.documentBookCode,
        };
        // console.log(whtImport);
        this.$store.dispatch(
          "apWithholdingTax/addWithholdingTaxImport",
          whtImport
        );
      });
      await this.$store.dispatch("apWithholdingTax/resetWithholdingTaxsImport");
    },

    async insertRow() {
      // console.log("insertRow");
      let rec = {
        // id: "dummy" + (parseInt(this.details.length) + 1),
        id: "dummy" + this.getNextDummyNumber(),
        headerId: null,
        itemNumber: null,
        description: null,
        accountId: null,
        accountCode: null,
        accountName: null,
        sectionId: null,
        projectId: null,
        jobId: null,
        debitAmount: null,
        creditAmount: null,
        group: null,
      };
      this.details.splice(this.selectedRowIndex + 1, 0, rec);
      let newDetails = this.details;
      this.details = [...newDetails];
      this.updateTotal();
      this.updateItemNumber();
      // await this.moveToCell(1);
      // this.shouldShowDataGrid = false;
      // await setTimeout(async () => {
      //   this.shouldShowDataGrid = true;
      // }, 10);
      // await setTimeout(async () => {
      //   let currentIndex = this.selectedRowIndex + 1
      //   let pageBottomPos = this.pageBottomPos;
      //   while (currentIndex > pageBottomPos) {
      //     // await setTimeout(async () => {
      //       this.$refs.grid.nextPage();
      //       pageBottomPos += pageBottomPos;
      //     // },100);
      //   }
      //   currentIndex = currentIndex - this.pageTopPos;
      //   this.$refs.grid.moveTo(currentIndex, this.currentColPos);
      // }, 100);

      // if (isPreparingData) {
      //   //ในขั้นตอนเตรียมข้อมูล data grid ยังไม่พร้อมทำงาน ให้ push เข้า details ตรงๆก่อน
      //   this.details.splice(this.currentRowPos + 1, 0, rec);
      // } else {
      //   //หากมีข้อมูลใน Detail แล้ว และต้องการเพิ่มบรรทัด ต้องเพิ่มใน Datagrid
      //   this.$refs.grid.newRecord(rec);
      // }

      // console.log("insertRow", this.details);
    },
    async ReceiptToGlAuto() { 
      await this.$store.dispatch("glAccountingEntry/resetAccountingEntry");     
      this.details = [];
      this.isReceiptSelectReady = false;
      this.setLoading(true);
      await this.getCheckGlBooks();
      if (!this.checkGlBook) {
        this.$swal({
          icon: "warning",
          // title: this.lang.validateReceiptCheckGlBook.replace(":code", this.bookModalSelected.generalLedgerBookCode),
          title: this.lang.validateReceiptCheckGlBook.replace(
            ":code",
            this.glBook
          ),
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      const today = new Date();
      this.autoReceiptToGl = "auto" + today.getSeconds();
      // this.showAccountingReceipt = true;
    },
    autoReceiptToGlSuccess() {
      this.setLoading(false);
      this.isReceiptSelectReady = true;
      this.checkShouldSetData();
    },
    async updateLockStatus() {
      this.setLoading(true);
      if (this.isCheckApprove) {
        await this.setDialog({
          title: this.lang.notificationDialogHeader,
          message: this.lang.validateRoleApprove,
          classTitle: "error",
          button: null,
        });
        this.setLoading(false);
        this.activeStatus = this.accountingEntry.postStep == "P" ? true : false;
        await this.dialogNotification.reveal();
        return;
      }

      try {
        let postStep = this.accountingEntry.postStep.trim();
        let payload = {
          id: this.id,
          status: this.activeStatus ? "P" : "A",
        };
        let response = await this.$store.dispatch(
          "glAccountingEntry/updateLockStatus",
          payload
        );
        if (response) {
          await this.setDialog({
            title:
              postStep === "P"
                ? this.lang.unlockSuccessTitle
                : this.lang.lockSuccessTitle,
            message: postStep === "P" ? this.lang.unlockSuccess : this.lang.lockSuccess,
            classTitle: "success",
            button: null,
          });
          const where = {
            id: this.id,
          };
          await this.$store.dispatch("glAccountingEntry/getAccountingEntry", {
            where,
          });
          this.setLoading(false);
          await this.dialogNotification.reveal();
        }
      } catch (error) {
        await this.setDialog({
          title: this.lang.notificationDialogHeader,
          message: error,
          classTitle: "error",
          button: null,
        });
        this.setLoading(false);
        await this.dialogNotification.reveal();
      }
    },
    async setDialog(dataConfig) {
      if (!dataConfig.button) {
        dataConfig.button = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "cancel",
          },
        ];
      }

      if (dataConfig.classTitle === "") {
        dataConfig.classTitle = "";
      } else if (dataConfig.classTitle === "success") {
        dataConfig.classTitle = "p-text-success";
      } else if (dataConfig.classTitle === "error") {
        dataConfig.classTitle = "p-error";
      }

      this.dataConfigDialog = {
        titleConfirm: dataConfig.title,
        classTitle: dataConfig.classTitle,
        wordingConfirm: dataConfig.message,
        dataButtons: dataConfig.button,
        display: true,
      };
    },
    async askForUpdateLockStatus() {
      this.objToCompare.status = this.activeStatus ? "P" : "A"
      let result = this.isCompareObject(this.objToCompare, this.payload);
      if (result) {
        let dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "cancel",
        },
        {
          id: "updateLockStatus",
          caption: this.lang.buttonDialogConfirm,
          class: "w-full",
          action: "confirm",
        },
      ];
        await this.setDialog({
          title: this.lang.notificationDialogHeader,
          message: this.lang.pageDirtyConfirmMessage,
          classTitle: "",
          button: dataButtonDialog,
        });
        this.setLoading(false);
        const dialogResponse = await this.dialogConfirm.reveal();
        if (!dialogResponse.isCanceled) {
          await this.updateLockStatus();
        }
      } else {
        await this.updateLockStatus();
      } 
    },
    async setCompareData() {
      let details = [];
      let refers = [];

      for (const each of this.details.filter((each) => each.accountId)) {
        let detail = null;
        if (each.id.includes("dummy") && !each.accountId) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        details.push(detail);
      }

      if (this.GetPathGlInvoice) {
        this.invoiceSelected.forEach((invoice) => {
          let refer = {
            referId: invoice.id,
          };
          refers.push(refer);
        });
      } else if (this.GetPathGlReceipt) {
        this.ReceiptSelected.forEach((Receipt) => {
          let refer = {
            referId: Receipt.id,
          };
          refers.push(refer);
        });
      } else if (
        this.GetPathGlAccounting &&
        this.checkGlBook &&
        this.glBook == "PJ"
      ) {
        this.purchaseInvoiceSelected.forEach((data) => {
          let refer = {
            referId: data.id,
          };
          refers.push(refer);
        });
      } else if (
        this.GetPathGlAccounting &&
        this.checkGlBook &&
        this.glBook == "PV"
      ) {
        if (this.purchaseReceiptVoucherLists.length > 0) {
          this.purchaseReceiptVoucherLists.forEach((data) => {
            let refer = {
              referId: data.receiptId,
            };
            refers.push(refer);
          });
        }

        if (this.purchaseReceiptImport.length > 0) {
          this.purchaseReceiptImport.forEach((data) => {
            let refer = {
              referId: data.receiptId,
            };
            refers.push(refer);
          });
        }
      }

      let objToCompare = {
        projectId: this.selectedProject.id,
        generalLedgerBookId: this.idBook,
        date: this.date ? this.formatDateToISOString(this.date) : null,
        description: this.description,
        type: this.selectType,
        status: this.activeStatus ? "P" : "A",
        approveStatus: this.selectApprove,
        approveName: this.approveName,
        reasonNonApprove: this.reasonNonApprove,
        remark: this.remark,
        isInvoiceToGl: this.isInvoiceToGl,
        isReceiptToGl: this.isReceiptToGl,
        isMeterToGl: this.isMeterToGl,
        isPettyCashToGl: this.isPettyCashToGl,
        isPurchaseInvoiceToGl: this.isPurchaseInvoiceToGl,
        isPurchaseReceiptToGl: this.isPurchaseReceiptToGl,
        details: details,
        refers: refers,
        typeInsert: "Add",
      };

      // console.log("payload", payload)

      if (!this.isCreateDocument) {
        objToCompare = { ...objToCompare, id: this.id, typeInsert: "Update" };
      }
      
      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
    },
    async askForCreateDocument() {
      this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForCreateNewDocument}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.cancel,
        allowOutsideClick: false,
      }).then((result) => {
        //callback to execute when user confirms the action
        if (result.isConfirmed) {
          this.createDocument();
        } else if (result.isDenied) {
          //
        }
      });
    },
    async askForSaveAs() {
      this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForSaveAs}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.cancel,
        allowOutsideClick: false,
      }).then((result) => {
        //callback to execute when user confirms the action
        if (result.isConfirmed) {
          this.setLoading(true);
          this.saveAs();
        } else if (result.isDenied) {
          //
        }
      });
    },
    async saveAs() {
      let oldDetails = this.details;
      await this.createDocument();

      let details = [];
      let i = 1;
      for (const each of oldDetails.filter((each) => each.accountId)) {
        let detail = null;
        detail = { ...each, id: "dummy" + i };
        details.push(detail);
        i++;
      }
      this.details = details;
      this.addEmptyRow(true);
      this.updateTotal();
      this.setLoading(false);
    },
    async switchDebitCredit() {
      this.setLoading(true);
      let details = [];
      for (const each of this.details.filter((each) => each.accountId)) {
        let detail = { ...each };
        if (each.debitAmount > 0) {
          detail.creditAmount = detail.debitAmount;
          detail.debitAmount = 0;
        }
        else {
          detail.debitAmount = detail.creditAmount;
          detail.creditAmount = 0;
        }
        details.push(detail);
      }
      this.details = details;
      this.addEmptyRow(true);
      this.setLoading(false);
    },
  },
  computed: {
    payload() {
      let details = [];
      let refers = [];

      for (const each of this.details.filter((each) => each.accountId)) {
        let detail = null;
        if (each.id.includes("dummy") && !each.accountId) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        detail.creditAmount = this.round(detail.creditAmount); 
        detail.debitAmount = this.round(detail.debitAmount);
        details.push(detail);
      }

      // console.log("referId", refers)
      if (this.GetPathGlInvoice) {
        this.invoiceSelected.forEach((invoice) => {
          let refer = {
            referId: invoice.id,
          };
          refers.push(refer);
        });
      } else if (this.GetPathGlReceipt) {
        this.ReceiptSelected.forEach((Receipt) => {
          let refer = {
            referId: Receipt.id,
          };
          refers.push(refer);
        });
      } else if (
        this.GetPathGlAccounting &&
        this.checkGlBook &&
        this.glBook == "PJ"
      ) {
        this.purchaseInvoiceSelected.forEach((data) => {
          let refer = {
            referId: data.id,
          };
          refers.push(refer);
        });
      } else if (
        this.GetPathGlAccounting &&
        this.checkGlBook &&
        this.glBook == "PV"
      ) {
        if (this.purchaseReceiptVoucherLists.length > 0) {
          this.purchaseReceiptVoucherLists.forEach((data) => {
            let refer = {
              referId: data.receiptId,
            };
            refers.push(refer);
          });
        }

        if (this.purchaseReceiptImport.length > 0) {
          this.purchaseReceiptImport.forEach((data) => {
            let refer = {
              referId: data.receiptId,
            };
            refers.push(refer);
          });
        }
      }

      let payload = {
        projectId: this.selectedProject.id,
        // generalLedgerBookId: search.generalLedgerBookId,
        generalLedgerBookId: this.idBook,
        date: this.date ? this.formatDateToISOString(this.date) : null,
        description: this.description,
        type: this.selectType,
        // status: this.selectStatus,
        status: this.activeStatus ? "P" : "A",
        approveStatus: this.selectApprove,
        approveName: this.approveName,
        reasonNonApprove: this.reasonNonApprove,
        remark: this.remark,
        isInvoiceToGl: this.isInvoiceToGl,
        isReceiptToGl: this.isReceiptToGl,
        isMeterToGl: this.isMeterToGl,
        isPettyCashToGl: this.isPettyCashToGl,
        isPurchaseInvoiceToGl: this.isPurchaseInvoiceToGl,
        isPurchaseReceiptToGl: this.isPurchaseReceiptToGl,
        details: details,
        refers: refers,
        typeInsert: "Add",
      };

      // console.log("payload", payload)

      if (!this.isCreateDocument) {
        payload = { ...payload, id: this.id, typeInsert: "Update" };
      }
      return payload;
    },
    selectedRowIndex() {
      if (!this.details) {
        return 0;
      }
      return this.details.findIndex((c) => c.id == this.selectedRow.id);
    },
    isCreateDocument() {
      return this.id === "create";
    },
    isDocumentLocked() {
      if (this.accountingEntry) {
        if (this.accountingEntry.cancelStatus === "C") {
          return true;
        }
      }
      if (this.GetPathGlReceipt || this.GetPathGlInvoice) {
        if (
          this.accountingEntry &&
          this.accountingEntry.postStep.trim() === "P"
        ) {
          return true;
        }
      }
      return false;
    },
    isPrint() {
      if (this.accountingEntry) {
        if (this.accountingEntry.postStep.trim() === "P") {
          return true;
        }
      }
      return false;
    },
    isCanceled() {
      if (this.accountingEntry) {
        if (this.accountingEntry.cancelStatus === "C") {
          return true;
        }
      }
      return false;
    },
    title() {
      let result = this.lang.editAccountingEntry;
      if (this.isCreateDocument) {
        if (this.GetPathGlAccounting) {
          result = this.lang.createAccountingEntry + " : " + this.glBook;
        } else if (this.GetPathGlReceipt) {
          result = this.lang.createReceiptToGl + " : " + this.glBook;
        } else if (this.GetPathGlInvoice) {
          result = this.lang.createInvoiceToGl + " : " + this.glBook;
        } else if (this.GetPathGlPettyCash) {
          result = this.lang.createPettyCashToGl + " : " + this.glBook;
        }
      } else {
        if (this.GetPathGlAccounting) {
          result = this.lang.editAccountingEntry + " : " + this.glBook;
        } else if (this.GetPathGlReceipt) {
          result = this.lang.editReceiptToGl + " : " + this.glBook;
        } else if (this.GetPathGlInvoice) {
          result = this.lang.editInvoiceToGl + " : " + this.glBook;
        } else if (this.GetPathGlPettyCash) {
          result = this.lang.editPettyCashToGl + " : " + this.glBook;
        }
      }
      return result;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    accountingEntry() {
      return this.$store.getters["glAccountingEntry/selectedAccountingEntry"];
    },
    GetPathGlAccounting() {
      const accountingPath = this.$route.fullPath.indexOf("gl/accounting");
      let accounting = true;
      if (accountingPath < 0) accounting = false;
      return accounting;
    },
    GetPathGlReceipt() {
      const receiptPath = this.$route.fullPath.indexOf("gl/receipt");
      let receipt = true;
      if (receiptPath < 0) receipt = false;
      return receipt;
    },
    GetPathGlInvoice() {
      const invoicePath = this.$route.fullPath.indexOf("gl/invoice");
      let invoice = true;
      if (invoicePath < 0) invoice = false;
      return invoice;
    },
    GetPathGlPettyCash() {
      const PettyCashPath = this.$route.fullPath.indexOf("gl/pettycash");
      let PettyCash = true;
      if (PettyCashPath < 0) PettyCash = false;
      return PettyCash;
    },
    CompoundGetCodeFirst() {
      if (!this.ReceiptSelected) return null;
      return this.ReceiptSelected[0]["code"];
    },
    CompoundGetCodeLast() {
      if (!this.ReceiptSelected) return null;
      return this.ReceiptSelected[this.ReceiptSelected.length - 1].code;
    },

    CompoundGetDateFirst() {
      if (!this.ReceiptSelected) return null;
      const DateFirst = this.ReceiptSelected[0]["date"];
      return this.formatDate(DateFirst);
    },
    CompoundGetDateLast() {
      if (!this.ReceiptSelected) return null;
      const DateLast =
        this.ReceiptSelected[this.ReceiptSelected.length - 1].date;
      return this.formatDate(DateLast);
    },
    meterSelected() {
      return this.$store.getters["glMeterToGl/meterSelected"];
    },
    meterToGl() {
      return this.$store.getters["glMeterToGl/meterToGl"];
    },

    invoiceSelected() {
      return this.$store.getters["glAccountingInvoice/selectedInvoice"];
    },
    invoiceToGl() {
      return this.$store.getters["glAccountingInvoice/invoiceToGl"];
    },
    ReceiptSelected() {
      return this.$store.getters["glAccountingReceipt/receiptSelected"];
    },
    ReceiptToGl() {
      return this.$store.getters["glAccountingReceipt/receiptToGl"];
    },
    purchaseInvoiceSelected() {
      return this.$store.getters["glPurchaseInvoice/purchaseInvoiceSelected"];
    },
    purchaseReceiptImport() {
      return this.$store.getters["apPurchaseReceipt/purchaseReceiptImport"];
    },
    purchaseReceiptVoucherLists() {
      return this.$store.getters[
        "glPurchaseReceipt/selectedVoucherReferenceDocument"
      ];
    },
    approve() {
      let approve = [
        { name: this.lang.columnApprove, code: "Y" },
        { name: this.lang.notApprove, code: "" },
      ];
      if (this.GetPathGlPettyCash) {
        approve = [
          { name: "", code: "" },
          { name: this.lang.columnApprove, code: "Y" },
          { name: this.lang.notApprove, code: "N" },
        ];
      }
      return approve;
    },
    ValidatorPettyCashCancel() {
      let message = "";
      if (this.GetPathGlPettyCash && this.selectApprove == "Y") {
        message = this.lang.cancelApprovePettyCash;
      }

      return message;
    },
    isCheckCancel() {
      let isCheck = true;
      if (
        this.bookModalSelected != null &&
        this.bookModalSelected.cancel === "Y"
      )
        isCheck = false;

      return isCheck;
    },
    isCheckPrint() {
      let isCheck = true;
      if (
        this.bookModalSelected != null &&
        this.bookModalSelected.print === "Y"
      )
        isCheck = false;
      return isCheck;
    },
    isCheckApprove() {
      let isCheck = true;
      if (
        this.bookModalSelected != null &&
        this.bookModalSelected.approve === "Y"
      )
        isCheck = false;
      return isCheck;
    },
    isCheckEditPettyCash() {
      // console.log("this.bookModalSelected=",this.bookModalSelected);
      let isCheck = true;

      if (!this.GetPathGlPettyCash) {
        return false;
      }

      if (this.GetPathGlPettyCash && this.code.trim() == "") {
        return false;
      }

      if (
        this.GetPathGlPettyCash &&
        this.code != "" &&
        this.pettyCashPeriod == ""
      ) {
        return false;
      }

      // if (
      //   this.GetPathGlPettyCash &&
      //   this.code != "" &&
      //   this.pettyCashPeriod != true
      // ){
      //   return true;
      // }

      if (
        this.GetPathGlPettyCash &&
        this.code != "" &&
        this.isSelectApprove == "N"
      )
        isCheck = false;

      if (
        this.GetPathGlPettyCash &&
        this.code != "" &&
        this.pettyCashPeriod != "" &&
        this.bookModalSelected != null &&
        this.bookModalSelected.approve == "Y"
      ) {
        return false;
      }

      return isCheck;
    },
    isEditDetail() {
      let isCheck = true;

      if (!this.GetPathGlPettyCash) {
        return false;
      }

      if (this.GetPathGlPettyCash && this.code.trim() == "") {
        return false;
      }

      if (
        this.GetPathGlPettyCash &&
        this.code != "" &&
        this.pettyCashPeriod == ""
      )
        isCheck = false;

      if (
        this.GetPathGlPettyCash &&
        this.code != "" &&
        this.pettyCashPeriod != "" &&
        this.isSelectApprove != "Y"
      )
        isCheck = true;

      if (
        this.GetPathGlPettyCash &&
        this.code != "" &&
        this.bookModalSelected != null &&
        this.bookModalSelected.approve == "Y"
      )
        isCheck = false;

      if (
        this.GetPathGlPettyCash &&
        this.code != "" &&
        this.isSelectApprove == "N"
      )
        isCheck = false;

      if (
        this.GetPathGlPettyCash &&
        this.code != "" &&
        this.isSelectApprove == "Y"
      )
        isCheck = true;

      return isCheck;
    },
    withholdingTaxs() {
      return this.$store.getters["apWithholdingTax/withholdingTaxs"];
    },
    checkGlBook() {
      return this.$store.getters["glCheckDocumentToGL/glBooks"];
    },
    banksSelected() {
      return this.$store.getters["bank/banksSelected"];
    },
    withholdingTaxsImport() {
      return this.$store.getters["apWithholdingTax/withholdingTaxsImport"];
    },
    isAnyNullAccountId() {
      if (!this.details) {
        return false;
      }

      let result = this.details.find(
        (c) =>
          c.accountId === null && (c.debitAmount > 0.0 || c.creditAmount > 0.0)
      );

      if (result) {
        return true;
      }
      return false;
    },
    dialogConfirm() {
      return createConfirmDialog(BaseDialog, this.dataConfigDialog);
    },
    dialogNotification() {
      return createConfirmDialog(BaseDialog, this.dataConfigDialog);
    },
    currentRowPosWithPageTop() {
      return this.currentRowPos + this.$refs.grid.findPageTop();
    },
  },
  watch: {
    accountingEntry() {
      this.checkShouldSetData();
    },
    invoiceSelected() {
      this.checkShouldSetData();
    },
    ReceiptSelected() {
      this.checkShouldSetData();
    },
    purchaseInvoiceSelected() {
      this.checkShouldSetData();
    },
    purchaseReceiptVoucherLists() {
      this.checkShouldSetData();
    },
    purchaseReceiptImport() {
      this.checkShouldSetData();
    },
    isInvoiceSelectReady() { 
      this.checkShouldSetData();
    },
  },
};
</script>

<style scoped>
.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem !important;
  font-size: 0.8rem !important;
}
.p-inputtext {
  width: 100%;
}
</style>
