<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div
        class="flex justify-content-between align-items-center flex flex-wrap"
      >
        <span class="font-lg">{{ lang.searchaddress }}</span>

        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary "
          @click="closeDialog"
        />
      </div>
      <PvDivider class="mt-3 mb-3" />
    </template>
    <div
      class="flex justify-content-between align-items-center flex flex-wrap pb-2"
    >
      <div class="lg:col-5 md:col-5 col-12 pl-0 pr-2">
        <PvInputText
          id="province"
          type="text"
          class="inputfield w-full"
          :placeholder="lang.validateProvince"
          v-model="province"
          @focus="$event.target.select()"
          :class="{ 'p-invalid': provinceError }"
          @keyup="checkEnter($event)"
        />

        <small v-if="provinceError" id="unit-help" class="p-error">{{
          lang.validateProvince
        }}</small>
      </div>
      <div class="lg:col-5 md:col-5 col-12 pl-0 pr-2">
        <span class="p-input-icon-left" :style="{ width: '100%' }">
          <i class="pi pi-search" />
          <PvInputText
            v-model="searchText"
            :placeholder="lang.searchText"
            :style="{ width: '100%' }"
            @keyup="checkEnter($event)"
          />
        </span>
      </div>

      <PvButton
        @click="GetFlashAddress()"
      >   
      <span class="w-full mr-2 ml-2">{{this.lang.search}}</span>
        </PvButton>
    </div>

    <PvDataTable
				:value="flashAddress"
        v-model:selection="selectedFlashAddress"
				dataKey="id"
				selectionMode="single"
				responsiveLayout="scroll"
				:class="textTableClass"
				class="p-datatable-radius"
        @row-dblclick="SelectedAddress()"
        :scrollable="true"
        scrollHeight="350px">
        
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
    <!-- <PvDataTable
      showGridlines
      :value="flashAddress"
      v-model:selection="selectedFlashAddress"
      dataKey="id"
      :loading="isLoading"
      responsiveLayout="scroll"
      class="p-datatable-sm"
      :class="textTableClass"
      @row-dblclick="SelectedAddress()"
      selectionMode="single"
      :scrollable="true"
      scrollHeight="350px"
    > -->
      <!-- <PvColumn selectionMode="single"></PvColumn> -->

      <PvColumn
        field="provinceTH"
        :header="lang.province"
        style="min-width: 4rem"
        headerClass="h-center"
				bodyClass="text-center"
      ></PvColumn>

      <PvColumn
        field="cityTH"
        :header="lang.district"
        style="min-width: 4rem"
        headerClass="h-center"
				bodyClass="text-center"
      ></PvColumn>

      <PvColumn
        field="districtTH"
        :header="lang.subarea"
        style="min-width: 4rem"
        headerClass="h-center"
				bodyClass="text-center"
      ></PvColumn>

      <PvColumn
        field="zipcode"
        :header="lang.postcode"
        style="min-width: 2rem"
        headerClass="h-center"
				bodyClass="text-center"
      ></PvColumn>
    </PvDataTable>

    <template #footer>
      <div class="md:text-center">
        <PvButton
          @click="closeDialog"
          class="p-button-outlined p-icon-secondary"
          :style="{width: '158px' }"
        >
        <span class="w-full">{{this.lang.cancel}}</span>
        </PvButton>
        <PvButton
          @click="SelectedAddress()"
          :style="{ width: '158px' }"
        >
        <span class="w-full">{{this.lang.buttonDialogConfirm}}</span>
        </PvButton>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
export default {
  props: ["openFrom"],
  emits: ["close", "flashAddress-selected"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.project.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.project.edit,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
    };
  },
  data() {
    return {
      isLoading: true,
      selectedFlashAddress: null,
      province: "",
      searchText: "",
      provinceError: false,
    };
  },
  async created() {
    this.isLoading = false;
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
    },
    initiateData() {
      this.$store.dispatch("flashAddress/resetFlashAddress");
      (this.province = ""), (this.searchText = "");
      this.provinceError = false;
      this.selectedFlashAddress = null;
    },
    async GetFlashAddress() {
      try {
        if (!this.province) {
          this.provinceError = true;
          return null;
        }
        this.setLoading(true);
        const payload = {
          province: this.province,
          limit: 1000,
          search: this.searchText,
        };
        // console.log("getDataPrint=", payload);
        await this.$store.dispatch("flashAddress/GetFlashAddress", payload);

        this.isLoading = false;
        this.setLoading(false);
      } catch (error) {
        // this.$swal({
        //   icon: "error",
        //   html: `<p class="${this.textAlertClass}">${error}</p>`,
        //   confirmButtonText: this.lang.close,
        //   allowOutsideClick: false,
        // });
        this.$toast.add({
          severity: "error", // severity:'success',severity:'info',severity:'warn',severity:'error'
          summary: this.lang.notificationDialogHeader, //TitleHeader
          detail: error,
          life: 5000, //TimeClose
        });
        this.setLoading(false);
      }
    },
    SelectedAddress() {
      if (!this.selectedFlashAddress) {
        // this.$swal({
        //   icon: "warning",
        //   title: this.lang.validateNoDetailSelect,
        //   confirmButtonText: this.lang.close,
        //   allowOutsideClick: false,
        // });
        this.$toast.add({
          severity: "warn", // severity:'success',severity:'info',severity:'warn',severity:'error'
          summary: this.lang.notificationDialogHeader, //TitleHeader
          detail: this.lang.validateNoDetailSelect,
          life: 5000, //TimeClose
        });
        return true;
      }
      // console.log("selectedFlashAddress=", this.selectedFlashAddress);
      this.$emit("flashAddress-selected", this.selectedFlashAddress);
      this.$emit("close");
    },
    checkEnter(event) {
      this.provinceError = false;
      if (event.code == "Enter") {
        this.GetFlashAddress();
      }
    },
  },
  computed: {
    flashAddress() {
      return this.$store.getters["flashAddress/flashAddress"];
    },
  },
  watch: {
    openFrom() {
      this.initiateData();
    },
    // province(){
    //     this.provinceError = false;
    // }
  },
};
</script>

<style scoped>
.p-dialog .p-dialog-header {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}
</style>
