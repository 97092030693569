export default {
	titleBack: "Policy of Month",
	title: "Policy of Month",
	createPolicy: "Create Policy",
	emptyPolicy: "Policy not found",

	columnItemNo: "No",
	columnCode: "Code",
	columnName: "Name",
	columnCreateBy: "Create By",
	columnCreateDate: "Create Date",
	columnUpdateBy: "Update By",
	columnUpdateDate: "Update Date",
	columnAction: "",

	askForDeletePolicy: "Do you want to Delete policy {policyCodeAndName}?",
};
