<template>
  <div class="mytooltip" :style="cssVars">
    <i class="tooltip-item" @mouseover="matchHeight"
     :class="tooltipIcon"></i>
    <span class="tooltip-content" ref="tooltipContent">
      <div class="tooltip-text-header font-sm pl-2 pt-1">{{
        this.textHeader
      }}</div>
      <div class="tooltip-detail">
        <PvDeferredContent>
          <img :src="urlImage" />
        </PvDeferredContent>
      </div>
      <div class="tooltip-text-footer text-center font-sm">{{
        this.textFooter
      }}</div>

    </span>    
  </div>
</template>
<script>
export default {
  props: {
    urlImage: {
      type: String,
    },
    textHeader: {
      type: String,
    },
    textFooter: {
      type: String,
    },
    tooltipIcon: {
      type: String,
      default: "pi pi-exclamation-circle"
    }
  },
  data() {
    return {
        contentPosition: "-165px", 
    }
  },
  methods: {
    matchHeight(){
        let height = this.$refs.tooltipContent.clientHeight-30;
        this.contentPosition = ((height/2) * -1)+"px";
    }
  },
  computed: {
    cssVars() {
      return {
        "--content-position": this.contentPosition,
      };
    },
  },
};
</script>

<style scoped>
.mytooltip {
  display: inline;
  position: relative;
}
.mytooltip .tooltip-item {
  cursor: pointer;
  display: inline-block;
  padding: 0 10px;
}
.mytooltip .tooltip-content {
  position: absolute;
  z-index: 9999;
  bottom: var(--content-position);
  background: var(--bluegray-700);
  opacity: 0;
  cursor: default;
  pointer-events: none;
  border-radius: 6px;
  margin-left: 15px;
  width: 270px;
}
.mytooltip .tooltip-content::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent var(--bluegray-700) transparent transparent;
}
.tooltip-detail {
  margin: 0px 25px 0px 25px;
  text-align: center;
}
.mytooltip .tooltip-content img {
  width: 210px;
  height: auto;
  opacity: 50%;
  border-radius: 6px;
}
.mytooltip:hover img {
  opacity: 100%;
  transition: all 1s;
  border-radius: 6px;
}
.mytooltip:hover .tooltip-content {
  pointer-events: auto;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0deg);
  transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0deg);
}
.mytooltip .tooltip-text-header {
  width: 100%;
  display: block;
  color: var(--surface-a);
  line-height: 30px;
}
.mytooltip .tooltip-text-footer {
  display: block;
  color: var(--surface-a);
  position: sticky;
  line-height: 30px;
  
  width: 100%;
}
</style>

<!-- เอาไปใช้
  <br /><br /><br /><br /><br /><br /><br /><br />
  <label> เพิ่มโลโก้บนหัวกระดาษ </label> 
  <base-tooltip-image 
   urlImage="https://thumbor.forbes.com/thumbor/fit-in/900x510/https://www.forbes.com/advisor/wp-content/uploads/2023/07/top-20-small-dog-breeds.jpeg.jpg"
   textHeader="ข้อมูลที่เพิ่มนี้จะถูกเพิ่มลงส่วน ชื่อผู้รับ11"
   textFooter="ตัวอย่างตำแหน่งของข้อมูล11"
   tooltipIcon="pi pi-times"/>
  <br />  
  <br />
  <base-tooltip-image 
   urlImage="https://cdn.pixabay.com/photo/2017/11/01/20/12/background-2909232_1280.jpg"
   textHeader="ข้อมูลที่เพิ่มนี้จะถูกเพิ่มลงส่วน ชื่อผู้รับ22"
   textFooter="ตัวอย่างตำแหน่งของข้อมูล22"/>
 -->
