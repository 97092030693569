export default {
	editTitle: "รายละเอียดสมุดบัญชีรายวัน", 

	dialogTitleNotification: "แจ้งเตือน",
	dialogTitleAddSuccess: "สร้างสำเร็จ", 
	dialogContentAddSuccess: "คุณสร้างสมุดบัญชีรายวันสำเร็จ",
	dialogTitleEditSuccess: "บันทึกสำเร็จ",
	dialogContentEditSuccess: "คุณตั้งค่าสมุดบัญชีรายวันสำเร็จ",
	dialogTitleDeleteSuccess: "ลบสำเร็จ", 
	dialogContentDeleteSuccess: "คุณลบสมุดบัญชีสำเร็จ", 
	dialogTitleDeleteConfirm: "ยืนยันการลบสมุดบัญชี",
	dialogContentDeleteConfirm: "คุณต้องการลบสมุดบัญชี {0} ที่ท่านทำอยู่ใช่หรือไม่?", 

	validateParams: "กรุณาระบุให้ครบถ้วน", 
	validateRole: "คุณไม่มีสิทธิ์", 
	validateDuplicate: "ตรวจพบรหัสซ้ำในระบบ", 
	validateNotFound: "ไม่มีข้อมูล", 
	validateReference: "ไม่สามารถลบสมุดบัญชีนี้ได้ เนื่องจากมีการใช้งานสมุดบัญชีนี้แล้ว", 
	validateNoneId: "ไม่พบข้อมูลสมุดรายวัน",

	labelCode: "รหัสสมุดบัญชี*", 
	placeholderCode: "กรุณาระบุรหัสสมุดบัญชี", 
	validateNoneCode: "กรุณาระบุรหัสสมุดบัญชี", 
	validateDuplicateCode: "มีรหัสสมุดบัญชีนี้แล้ว", 
	labelNameThai: "ชื่อสมุดบัญชี* (ภาษาไทย)", 
	placeholderNameThai: "กรุณาระบุชื่อภาษาไทย", 
	validateNameThai: "กรุณาระบุชื่อภาษาไทย", 
	labelNameEnglish: "ชื่อสมุดบัญชี (ภาษาอังกฤษ)", 
	placeholderNameEnglish: "กรุณาระบุชื่อภาษาอังกฤษ", 
	validateNameEnglish: "กรุณาระบุชื่อภาษาอังกฤษ", 
	labelLockDate: "วันที่สิ้นสุดการล็อกข้อมูล", 
	validateLimitText: "กรุณาระบุ {0} ยาวไม่เกิน 50 ตัวอักษร",
	validateChangeCode: "รหัสสมุดบัญชี {0} ไม่สามารถเปลี่ยนแปลงได้ (รองรับภาษาอังกฤษ และตัวเลขเท่านั้น)",
	validateChangeUniqueCode: "รหัสสมุดบัญชี {0} ไม่สามารถเปลี่ยนแปลงได้ (เนื่องจากเป็นรหัสบัญชีหลัก)",

	buttonSave: "บันทึก",
	buttonDelete: "ลบสมุดบัญชีรายวัน", 

	buttonDialogSuccess: "ตกลง",
	buttonDialogConfirm: "ยืนยัน",
	buttonDialogCancel: "ยกเลิก",

	titleCretae: "สร้างสมุดบัญชีรายวัน", 
	titleEdit: "แก้ไขสมุดบัญชีรายวัน", 
	settingPettyCashAccount: "ตั้งค่าบัญชีจ่ายเงินสดย่อย",
	pettyCashAccount: "รหัสบัญชีจ่ายเงินสดย่อย",
	enterPettyCashAccount: "ระบุรหัสบัญชีจ่ายเงินสดย่อย",
	showPettyCashUseOtherExpenses: "แสดงผลต่างเงินสดย่อย สำหรับรายงาน",
	validateSelectedPettyCashAccount: "กรุณาเลือกรหัสบัญชีจ่ายเงินสดย่อย",
	pettyCashUseOtherExpensesAcoount: "รหัสบัญชี สำหรับบันทึกส่วนต่าง*",
	enterPettyCashUseOtherExpensesAcoount: "ระบุรหัสบัญชี สำหรับบันทึกส่วนต่าง*",
	validateSelectedPettyCashUseOtherExpensesAcoount: "กรุณาเลือกรหัสบัญชี สำหรับบันทึกส่วนต่าง",
	checkboxIsDefaultVoucherDate: "เมื่ออ้างอิงเอกสาร จะระบุวันที่เอกสาร ตามวันที่อ้างอิงของเอกสารประกอบ",
};
