<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="pt-2 pb-1">
        <label class="font-lg font-normal">{{ lang.titleCreateDialog }}</label>
      </div>
      <PvDivider></PvDivider>
    </template>
    <div class="pt-2 pb-2 mb-2">
      <div>
        <base-input-text-limit
          :caption="lang.nameBudget"
          :limitMessage="60"
          :isError="isNameBudgetError"
          :errorMessage="lang.validateNameBudget"
          :data="nameBudget"
          :textHolder="lang.nameBudgetPlaceholder"
          @data="updateNameBudget"
        />
      </div>
    </div>
    <div class="pt-2 pb-2 mb-2 w-6">
      <label class="font-normal">{{ lang.yearBudget }}</label>
      <PvDropdown
        id="category"
        class="inputfield w-full mt-1"
        :options="years"
        v-model="selectedYearBudget"
        optionLabel="name"
        optionValue="value"
        :placeholder="lang.yearBudgetPlaceholder"
        :class="{ 'p-invalid': isYearBudgetError }"
        @change="isYearBudgetError = false"
      />
      <small v-if="isYearBudgetError" id="menu-help" class="p-error">{{ lang.validateYearBudget }}</small>
    </div>
    <div class="pt-2 pb-2 mb-2">
      <label class="font-normal">{{ lang.remarkBudget }}</label>
      <PvInputText
        id="remark"
        type="text"
        class="inputfield w-full mt-1"
        :placeholder="lang.remarkBudgetPlaceholder"
        v-model="remark"
      />
    </div>
    <div class="pt-2 pb-2 mb-2">
      <div class="flex flex-wrap w-full col-12 p-0">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label class="font-normal">{{ lang.periodBudgetFrom }}</label>
          <PvCalendar
            id="dateStart"
            view="month"
            v-model="dateStart"
            class="inputfield w-full mt-1"
            :class="{ 'p-invalid': isDateStartError || isDateError }"
            showIcon
            dateFormat="mm/yy"
            @date-select="dateStartSelected"
            :minDate="budgetLastDate"
          />
          <small v-if="isDateStartError" id="menu-help" class="p-error">{{ lang.validatePeriodBudgetFrom }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-2 pr-0">
          <label class="font-normal">{{ lang.periodBudgetTo }}</label>
          <PvCalendar
            id="dateEnd"
            view="month"
            v-model="dateEnd"
            class="inputfield w-full mt-1"
            :class="{ 'p-invalid': isDateEndError || isDateError}"
            showIcon
            dateFormat="mm/yy"
            @date-select="dateEndSelected"
            :minDate="budgetLastDate"
          />
          <small v-if="isDateEndError" id="menu-help" class="p-error">{{ lang.validatePeriodBudgetTo }}</small>
        </div>
      </div>
      <div class="lg:col-12 md:col-12col-12 pl-0 pr-2" v-if="isDateError">
        <small class="p-error"> {{ lang.validateBudgetDate }} </small>
      </div>
    </div>

    <!-- วิธีการกรอกงบ -->
    <div class="pt-2 pb-2 mb-2" v-if="isHaveBudget">
      <label class="font-normal flex flex-wrap">{{ lang.budgetMethod }}</label>
      <div class="flex flex-wrap">
        <div v-for="budgetMethod of budgetMethods" :key="budgetMethod.value" class="field-radiobutton pt-3 mr-5">
          <PvRadioButton
            class="mr-2"
            :inputId="budgetMethod.value"
            name="budgetMethod"
            :value="budgetMethod.value"
            v-model="selectedBudgetMethod"
            @change="changeBudgetMethod()"
          />
          <label :for="budgetMethod.value">{{ budgetMethod.name }}</label>
        </div>
      </div>
    </div>
    <div class="pt-2 pb-2 mb-2" v-show="isCopyBudget">
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label class="font-normal flex flex-wrap">{{ lang.copyBudgetSelect }}</label>
        <budget-year-auto-complete
          id="copyBudgetSelect"
          v-model="selectedBudgetToCopy"
          class="inputfield w-full mt-1"
          :isActive="true"
          :isLoadingData="true"
          @load-finish="budgetsDataReady"
        />
      </div>
    </div>
    <template #footer>
      <div class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-end align-items-center">
        <div class="lg:col-3 md:col-4 col-6 pl-1 pr-1">
          <PvButton :label="lang.close" class="p-button-outlined p-button-secondary w-full" @click="closeDialog()" />
        </div>
        <div class="lg:col-3 md:col-4 col-6 pl-1 pr-1">
          <PvButton :label="lang.confirm" @click="confirmDialog()" class="w-full" />
        </div>
      </div>
    </template>
  </PvDialog>
</template>
<script>
import useLang from "../../hooks/lang.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
import useGetBudgetYear from "../../hooks/getBudgetYear.js";

export default {
  emits: ["close", "confirm"],
  props: {
    isCreate: {
      type: String,
    },
    budgetHeader: {
      type: Object,
      default: null
    }
  },
  setup(props, { emit }) {
    const { lang } = useLang();
    const store = useStore();
    const mergeLang = computed(() => {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.budget.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.budget.listing,
        };
      }
    });
    const { formatDateToISOString } = useFormat();
    const isCreateDocument = computed(() => {
      return props.isCreate == "create";
    });
    const budgets = computed(() => {
      return store.getters["budget/budgets"];
    });
    const budgetLastDate = computed(() => {
      let date = new Date();
      if(isCreateDocument.value){
        if(store.getters["budget/budgetLastDate"]){
          date = new Date(store.getters["budget/budgetLastDate"].lastDate);
          date.setMonth(date.getMonth() + 1);
        }else{
          date.setYear(date.getFullYear() - 6);
        }
        date.setDate(1);
        return date;
      }else{        
        let date = new Date(props.budgetHeader.dateStart);
        return date;
      }
    });
    const isHaveBudget = computed(() => {
      return budgetList.value.length > 0;
    });
    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });

    let nameBudget = ref(null);
    let isNameBudgetError = ref(false);

    let selectedYearBudget = ref(null);
    let isYearBudgetError = ref(false);

    let remark = ref(null);

    let dateStart = ref();
    let isDateStartError = ref();
    let dateEnd = ref();
    let isDateEndError = ref();
    let isDateError = ref();
    let budgetMethods = ref([
      {
        name: mergeLang.value.newBudgetMethod,
        value: "new",
      },
      {
        name: mergeLang.value.copyBudgetMethod,
        value: "copy",
      },
    ]);
    let selectedBudgetMethod = ref("new");
    let isCopyBudget = ref(false);
    let selectedBudgetToCopy = ref(null);
    let isSharedBudgetsReady = ref(false);

    const { years, budgetList } = useGetBudgetYear();

    const confirmDialog = () => {
      if (validateDataSubmit()) {
        return;
      }
      let payload = {
        projectId: selectedProject.value.id,
        name: nameBudget.value,
        yearOf: selectedYearBudget.value,
        dateStart: formatDateToISOString(dateStart.value),
        dateEnd: formatDateToISOString(dateEnd.value),
        remark: remark.value,
        copyBudget: selectedBudgetToCopy.value,
      };
      emit("confirm", payload);
    };

    const closeDialog = () => {
      emit("close");
    };

    const validateDataSubmit = () => {
      let returnStatus = false;
      if (!nameBudget.value) {
        returnStatus = true;
        isNameBudgetError.value = true;
      }
      if (!selectedYearBudget.value) {
        returnStatus = true;
        isYearBudgetError.value = true;
      }
      if (!dateStart.value) {
        returnStatus = true;
        isDateStartError.value = true;
      }
      if (!dateEnd.value) {
        returnStatus = true;
        isDateEndError.value = true;
      }

      if ((dateStart.value && dateEnd.value) && (dateStart.value > dateEnd.value)) {        
        returnStatus = true;
        isDateError.value = true;
      }
      
      return returnStatus;
    };

    const updateNameBudget = (data) => {
      nameBudget.value = data;
      isNameBudgetError.value = false;
    };

    const changeBudgetMethod = () => {
      if (selectedBudgetMethod.value == "copy") {
        if (!isSharedBudgetsReady.value) {
          setLoading(true);
        }
        isCopyBudget.value = true;
      } else {
        isCopyBudget.value = false;
        selectedBudgetToCopy.value = null;
      }
    };

    const setData = () => {
      // console.log("onMounted_setData=",props.budgetHeader);
      if(props.budgetHeader){
        nameBudget.value = props.budgetHeader.name;
        remark.value = props.budgetHeader.remark;
        selectedYearBudget.value = props.budgetHeader.yearOf;
        dateStart.value = new Date(props.budgetHeader.dateStart);
        dateEnd.value = new Date(props.budgetHeader.dateEnd);
      }
    };

    const dateStartSelected = () => {
      isDateStartError.value = false; 
      isDateError.value = false;
    };

    const dateEndSelected = () => {
      isDateEndError.value = false; 
      isDateError.value = false;
    };

    const setLoading = (bool) => {
      store.dispatch("setLoading", { value: bool });
    };

    const budgetsDataReady = () => {
      isSharedBudgetsReady.value = true;
      setLoading(false);
    };

    onMounted(async () => {      
      await store.dispatch("budget/getBudgetLastDate", selectedProject.value.id);
      setData();
    });

    return {
      lang: mergeLang,
      confirmDialog,
      closeDialog,
      updateNameBudget,
      changeBudgetMethod,
      setData,
      dateStartSelected,
      dateEndSelected,
      budgetsDataReady,

      years,
      nameBudget,
      isNameBudgetError,
      selectedYearBudget,
      isYearBudgetError,
      remark,
      dateStart,
      isDateStartError,
      dateEnd,
      isDateEndError,
      selectedBudgetMethod,
      isCreateDocument,
      isHaveBudget,
      budgetMethods,
      isCopyBudget,
      selectedBudgetToCopy,
      budgetLastDate,
      budgets,
      isDateError,
    };
  },
};
</script>
