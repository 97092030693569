export default {
    title: "จัดการรายงาน",
    addNewReportMenu: "เพิ่มประเภทรายงาน",
    editReportMenu: "แก้ไขประเภทรายงาน",
    menuName: "เมนูหลัก",
    sequence: "ลำดับ",
    nameTh: "ชื่อ (ไทย)",
    nameEn: "ชื่อ (อังกฤษ)",
    path: "Path",
    activeStatus: "สถานะ",
    addNewReportMenuDetail: "เพิ่มรายงาน",
    editReportMenuDetail: "แก้ไขรายงาน",
    category: "หมวด",
    project: "โครงการ",
    component: "Component",
    validateSequence: "กรุณาระบุลำดับ",
    validateNameThai: "กรุณาระบุชื่อ(ไทย)",
    validateNameEng: "กรุณาระบุชื่อ(อังกฤษ)",
    validatePath: "กรุณาระบุ Path",
    validateComponent: "กรุณาระบุ Component",
    titleDetail: "จัดการรายงาน : :reportMenu",
    validateProject: "กรุณาเลือกโครงการ",
    confirmDeleteTitle: "ยืนยันลบข้อมูล",
    confirmDeleteDesc: "คุณต้องการลบรายงาน : :name ใช่หรือไม่?",
    active: "ใช้งาน",
    inActive: "ไม่ใช้งาน",
    selectReport: "เลือกรูปแบบรายงาน",
    reportCategory: "หมวดรายงาน",
    settingSignature: "ตั้งค่าการลงนาม",
	yes: "ใช่",
	no: "ไม่ใช่",
}