export default {
  async noticeCustomReports(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Notice/CustomReport";
    if (payload.projectId) {
      url = url + "?ProjectId=" + payload.projectId;
    }
    url = encodeURI(url);
    const token = context.rootGetters.token;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to get closingEntry"
      );
      throw error;
    }
    context.commit("setNoticeCustomReport", responseData);
  },
  async noticeCustomReport(context, id) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Notice/CustomReport/"+id;
		url = encodeURI(url);
		const token = context.rootGetters.token;
		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});
		const responseData = await response.json();
		if (!response.ok) {
			const error = new Error(responseData.message || "Failed to get phone book");
			throw error;
		}
		context.commit("setSelectedNoticeCustomReport", responseData.data);
	},
  async createOrUpdateNoticeCustomReport(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Notice/CustomReport/${payload.type}`;
    const fallbackErrorMessage = `Failed to ${payload.type} notice custom report`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: payload.type === "Add" ? "post" : "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });
    const responseData = await response.json();
    if (!response.ok) {
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }
    context.commit("setSelectedNoticeCustomReport", responseData.data);
  },
  async deleteNoticeCustomReport(context, id) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Notice/CustomReport/Delete/"+id;
    url = encodeURI(url);
    const token = context.rootGetters.token;
    const response = await fetch(url, {
      method: "Delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to delete account chart");
      throw error;
    }
    context.commit("resetSelectedNoticeCustomReport");
  },
  resetNoticeCustomReport(context) {
    context.commit("resetNoticeCustomReport");
    context.commit("resetSelectedNoticeCustomReport");
  },
};
