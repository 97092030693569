<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>
    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateAt">{{ lang.dateAt }}</label>
          <PvCalendar
            id="dateAt"
            v-model="dateAt"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': isDateAtError }"
            @date-select="isDateAtError = false"
          />
          <small v-if="isDateAtError" id="dateAt-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0"></div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="unitStart">{{ lang.unitStart }}</label>
          <base-unit-auto-complete
            id="unit"
            v-model="selectedUnitStart"
            class="inputfield w-full mt-1"
            @load-finish="unitDataReady"
            :class="{ 'p-invalid': isUnitStartError }"
            @item-select="isUnitStartError = false"
          />
          <small v-if="isUnitStartError" id="unitStart-help" class="p-error">{{
            lang.validateNoUnitSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="unitEnd">{{ lang.to }}</label>
          <base-unit-auto-complete
            id="unit"
            v-model="selectedUnitEnd"
            class="inputfield w-full mt-1"
            @load-finish="unitDataReady"
            :class="{ 'p-invalid': isUnitEndError }"
            @item-select="isUnitEndError = false"
          />
          <small v-if="isUnitEndError" id="unitEnd-help" class="p-error">{{
            lang.validateNoUnitSelect
          }}</small>
        </div>
				<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
					<label for="fileNameText">{{ lang.fileName }}</label>
					<PvInputText
						id="fileNameText"
						type="text"
						class="inputfield w-full mt-1"
						v-model="fileNameText"
					/>
				</div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0"></div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3 flex">
          <PvCheckbox
            id="isDocCode"
            inputId="binary"
            v-model="isDocCode"
            :binary="true"
          />
          <label for="isDocCode" class="pl-3">{{
            lang.showDocumentCode
          }}</label>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3 flex">
          <PvCheckbox
            id="isPrintZero"
            inputId="binary"
            v-model="isPrintZero"
            :binary="true"
          />
          <label for="isPrintZero" class="pl-3">{{
            lang.captionCheckboxPrintZero
          }}</label>
        </div>
      </section>
    </transition>
    <template #footer>
      <div
				class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
			>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
				</div>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
				</div>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
				</div>
			</div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useTracking from "../../../hooks/tracking";
import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "toast-request"],
  setup() {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const { formatDateToISOString } = useFormat();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
        };
      }
    });
    return {
      lang: mergeLang,
      textFormClass,
      formatDateToISOString,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      dateAt: null,
      isDateAtError: false,
      isUnitStartError: false,
      isUnitEndError: false,
      selectedUnitStart: null,
      selectedUnitEnd: null,
      isSharedUnitReady: false,
      isDocCode: true,
			type: "PDF",
      fileNameText: "deposit_summary",
      isPrintZero: true,
    };
  },
  mounted() {
    this.setLoading(true);
    this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    initiateData() {
      this.dateAt = new Date();
      this.selectedUnitStart = null;
      this.selectedUnitEnd = null;
      this.isDateAtError = false;
      this.isSharedUnitReady = false;
      this.isUnitStartError = false;
      this.isUnitEndError = false;
      this.isDocCode = true;
			this.type = "PDF";
      this.fileNameText = "deposit_summary";
      this.isPrintZero = true;
    },
    unitDataReady() {
      this.isSharedUnitReady = true;
      this.checkShouldSetData();
    },
    checkShouldSetData() {
      if (this.isSharedUnitReady ) {
        this.setData();
        this.setLoading(false);
      }
    },
    setData(){
      this.selectedUnitStart = this.units[0];
      this.selectedUnitEnd =  this.units[this.units.length - 1];
    },
		validateData() {
			let validateError = false;
      if (this.selectedDateAt == null) {
        this.isDateAtError = true;
        validateError = true;
      }
			// if (this.selectedUnitStart == null) {
			// 	this.unitStartError = true;
			//   	validateError = true;
			// }
			// if (this.selectedUnitEnd == null) {
			//   	this.unitEndError = true;
			//   	validateError = true;
			// }
			return validateError;
		},
    async download(typeReport) {
			this.type = typeReport;
			if (this.validateData()) {
				return;
			}
			this.setLoading(true);
			try {
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
				const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
				this.setLoading(false);
			} catch (error) {
				this.$toast.add({
					severity: "error",
					summary: this.lang.notificationDialogHeader,
					detail: error,
					life: 3000,
				});
				this.setLoading(false);
			}
		},
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    title() {
      if (this.$store.getters.selectedLanguage === "TH")
        return this.reportObj.nameThai;
      else return this.reportObj.nameEnglish;
    },
		userId() {
        	return this.$store.getters.userId;
		},
		userName() {
			return this.$store.getters.userName;
		},
    selectedDateAt() {
      let dateAt = null;
      if (this.dateAt) {
        dateAt = this.formatDateToISOString(this.dateAt);
      }
      return dateAt;
    },
    units() {
        return this.$store.getters["shared/unitUnits"];
    },
		fileName() {
			let fileName = this.type == "PDF" ? ".pdf" : ".xlsx";
			return this.fileNameText + fileName;
		},
		fileType() {
			let fileType =
				this.type == "EXCEL"
				? this.isDocCode ? "_doccode_excel.xlsx" : "_excel.xlsx" 
				: this.type == "JASPEREXCEL"
				? this.isDocCode ? "_doccode.xlsx" : ".xlsx"
				: this.isDocCode ? "_doccode.pdf" : ".pdf";
			return fileType;
		},
    payload() {
      let payload = {
        ProjectId: this.selectedProject.id,
        DateStart: this.selectedDateAt,
        DateEnd: this.selectedDateAt,
        UnitStart: this.selectedUnitStart ? this.selectedUnitStart.code : "",
        UnitEnd: this.selectedUnitEnd ? this.selectedUnitEnd.code : "",
        IsPrintZero: this.isPrintZero ? "Y" : "N",
        FileName: this.fileName,
        JasperPath: this.reportObj.path + this.fileType,
        DocType: this.type,
        UserId: this.userId,
        PrintBy: this.userName,
        RequestId: 0,
      };
      return payload;
    },
  },
  watch: {
		reportObj() {
			this.initiateData();
		},
  },
};
</script>
