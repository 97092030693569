export default {
    formPrintForms(state) {
      return state.formPrint.forms;
    },
    formPrintCurrentPage(state) {
      return state.formPrint.currentPage;
    },
    formPrintNextPage(state) {
      return state.formPrint.currentPage + 1;
    },
    formPrintTotalPage(state) {
      return state.formPrint.totalPage;
    },
    formPrintIsLastPage(_, getters) {
      return getters.formPrintCurrentPage === getters.formPrintTotalPage && getters.formPrintTotalPage !== 0;
    }
  };