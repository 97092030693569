export default {
  setLocation(state, payload) {
    let locations = [...state.location.locations];
    for (const location of payload.locations) {
      if(locations.findIndex(c => c.id === location.id) < 0){
        locations.push(location);
      }
    }
    state.location.locations = locations;
    state.location.currentPage = payload.currentPage;
    state.location.totalPage = payload.totalPage;
  },
  resetLocation(state) {
    const location = {
      locations: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.location = location;
  },
};
