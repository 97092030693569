<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5307 9.52934C17.6711 9.38871 17.75 9.19809 17.75 8.99934C17.75 8.80059 17.6711 8.60997 17.5307 8.46934L12.5307 3.46934C12.39 3.32889 12.1994 3.25 12.0007 3.25C11.8019 3.25 11.6113 3.32889 11.4707 3.46934L6.47066 8.46934C6.33021 8.60997 6.25132 8.80059 6.25132 8.99934C6.25132 9.19809 6.33021 9.38871 6.47066 9.52934C6.61128 9.66979 6.80191 9.74868 7.00066 9.74868C7.19941 9.74868 7.39004 9.66979 7.53066 9.52934L12.0007 5.05934L16.4707 9.52934C16.6113 9.66979 16.8019 9.74868 17.0007 9.74868C17.1994 9.74868 17.39 9.66979 17.5307 9.52934Z"
      :class="strokeColor"
    />
    <path
      d="M12.5311 20.5293L17.5311 15.5293C17.6636 15.3872 17.7357 15.1991 17.7323 15.0048C17.7289 14.8105 17.6502 14.6251 17.5128 14.4877C17.3753 14.3503 17.19 14.2716 16.9957 14.2682C16.8014 14.2647 16.6133 14.3369 16.4711 14.4693L12.0011 18.9393L7.53113 14.4693C7.39051 14.3289 7.19988 14.25 7.00113 14.25C6.80238 14.25 6.61175 14.3289 6.47113 14.4693C6.33068 14.61 6.25179 14.8006 6.25179 14.9993C6.25179 15.1981 6.33068 15.3887 6.47113 15.5293L11.4711 20.5293C11.6118 20.6698 11.8024 20.7487 12.0011 20.7487C12.1999 20.7487 12.3905 20.6698 12.5311 20.5293Z"
      :class="strokeColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "primary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
