<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoExport="exportToExcel"
    @gotoSave="askBeforeSave"
    @gotoCreate="confirmCreate"
    @gotoCancel="confirmCancel"
  >
  </base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="isVisibleDialog"
      :titleConfirm="titleDialog"
      :classTitle="classTitleDialog"
      :wordingConfirm="contentDialog"
      :dataButtons="dataDialogButtons"
      :isVisibleCloseButton="isVisibleCloseButton"
      @close="closeBaseDialog"
      @goto-list="goBack"
      @goto-create="createData"
      @goto-cancel="cancelData"
    >
    </base-dialog>

    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <div class="flex justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.createTitle }}</label>
      </div>

      <PvDivider class="mb-3"></PvDivider>

      <section class="flex flex-wrap fontlabel" :class="textFormClass">

        <div
          class="lg:col-4 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0"
        >
          <label for="code">{{ lang.labelCode }}</label>
          <PvInputText
            id="code"
            type="text"
            v-model="code"
            class="inputfield w-full mt-2 p-inputtext-disabled"
            disabled
          />
        </div>

        <div
          class="lg:col-4 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0"
        >
          <label for="date">{{ lang.labelDate }}</label>
          <PvCalendar
            id="date"
            v-model="date"
            class="inputfield w-full mt-2"
            :inputClass="{ 'p-inputtext-disabled': !isCreateDocument, 'p-invalid': dateError }"
            :disabled="!isCreateDocument"
            showIcon
            dateFormat="dd/mm/yy"
            @date-select="dateError = false"
          />
          <small v-if="dateError" id="date-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>

        <div
          class="lg:col-4 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0"
        >
          <label for="description">{{ lang.labelDescription }}</label>
          <PvInputText
            id="description"
            type="text"
            v-model="description"
            class="inputfield w-full mt-2"
            :disabled="!isCreateDocument"
            :class="{'p-inputtext-disabled' : !isCreateDocument}"
          />
          <!-- <PvInputText
            id="description"
            type="text"
            v-model="description"
            class="inputfield w-full mt-2"
            :disabled="!isDisableSearch"
            :class="{'p-inputtext-disabled' : !isDisableSearch}"
          /> -->
        </div>

        <div
          class="lg:col-4 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0"
        >
          <label for="accountBook">{{ lang.labelAccountBook }}</label>
          <base-account-book-auto-complete
            id="accountBook"
            v-model="selectedAccountBook"
            @load-finish="accountBookDataReady"
            :disabled="true"
            class="inputfield w-full mt-2"
            inputClass="p-inputtext-disabled"
            :isLoadingData="true"
            :placeholder="lang.validateAccountBookIsNull"
          />
        </div>

        <div
          class="lg:col-4 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0"
        >
          <label for="accountCook">{{ lang.labelAccountCode }}</label>

          <base-account-code-equity-auto-complete
            id="accountCook"
            v-model="selectedAccountCode"
            @load-finish="accountCodeDataReady"
            @item-select="selectAccountCode()"
            @clear="resetDescription()"
            class="inputfield w-full mt-2"
            :class="{ 'p-invalid': accountCodeError }"
            :inputClass="{ 'p-inputtext-disabled': !isCreateDocument }"
            :disabled="!isCreateDocument"
            :isLoadingData="true"
            :isShowParent="false"
            :placeholder="lang.validateAccountCodeIsNull"
          />
          <small v-if="accountCodeError" id="account-code-help" class="p-error">
            {{ lang.validateAccountCodeIsNull }}
          </small>
        </div>

        <div
          class="lg:col-4 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-5 pb-0"
        >
          <PvButton
            icon="pi pi-search"
            :label="lang.search"
            class="p-button-primary mt-1"
            @click="getClosingVoucher()"
            :disabled="!isCreateDocument"
          />
        </div>
      </section>

      <div v-if="detailError" class="pt-3">
        <PvInlineMessage severity="error" class="w-full justify-content-start">{{ lang.dialogValidateNoDetail}}</PvInlineMessage>
      </div>
      <div class="p-datatable-border my-2 pt-2">
        <PvDataTable
          :value="closingEntryDetails"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          scrollable
          :scrollHeight="halfViewportHeight + 'px'"
        >
          <PvColumn
            field="itemNumber"
            :header="lang.columnItemNumber"
            style="height: 45px"
            headerClass="h-left"
            bodyClass="text-left tableBodyTop"
          />
          <PvColumn
            field="accountCode"
            :header="lang.columnCode"
            
            headerClass="h-left"
            bodyClass="text-left tableBodyTop"
          />

          <PvColumn
            field="accountName"
            :header="lang.columnName"
            
            headerClass="h-left"
            bodyClass="text-left tableBodyTop"
          />

          <PvColumn
            field="debitAmount"
            :header="lang.columnDebit"
            
            headerClass="h-right"
            bodyClass="text-right tableBodyTop"
          >
            <template #body="slotProps">
              <span class="text-right w-full">{{
                formatCurrency(slotProps.data.debitAmount)
              }}</span>
            </template>
          </PvColumn>

          <PvColumn
            field="creditAmount"
            :header="lang.columnCredit"
            
            headerClass="h-right"
            bodyClass="text-right tableBodyTop"
          >
            <template #body="slotProps">
              <span class="text-right w-full">{{
                formatCurrency(slotProps.data.creditAmount)
              }}</span>
            </template>
          </PvColumn>

          <PvColumnGroup type="footer">
            <PvRow>
              
              <PvColumn
                :footer="lang.totalAmount"
                :colspan="3"
                footerStyle="text-align:right"
                style="height: 45px;"
              />
              <PvColumn 
                class="text-right" 
            
                :footer="formatCurrency(grandTotalDebit)"
              />
              <PvColumn 
                class="text-right" 
              
                :footer="formatCurrency(grandTotalCredit)"
              />
            </PvRow>
          </PvColumnGroup>

          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>


        </PvDataTable>
      </div>

    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import usePageDirty from "../../../hooks/pageDirty";
import * as XLSX from "xlsx";
export default {
  props: ["id", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const isHideBack = inject("isHideBack");
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();
    const { compareObject, isCompareObject } = usePageDirty();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.closingEntry.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.closingEntry.edit,
        };
      }
    });
    return {
      lang: mergeLang,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      compareObject,
      isCompareObject,
      isHideBack,
    };
  },
  data() {
    return {
      objToCompare: null,

      //base_account_book_auto_complete
      isSharedAccountBookReady: false,
      selectedAccountBook: null,

      //base_account_code_auto_complete
      isSharedAccountCodeReady: false,
      selectedAccountCode: null,
      accountCodeError: false,

      //data
      code: "",
      date: null,
      description: "",
      accountCodeEquitys: [],
      closingEntryDetails: [],
			dateError: false,
      detailError: false,

      //base_dialog
      titleDialog: this.lang.notificationDialogHeader,
      classTitleDialog: "",
      contentDialog: "",
      isVisibleDialog: false,
      typeDialog: "close",
      isVisibleCloseButton: false,
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    if (!this.isCreateDocument) {
      await this.getClosingEntry();
    }
    await this.checkShouldSetData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({ name: "closing-entry"});
    },
    exportToExcel() {
      if (this.validateExport()) {
        return;
      }
      this.setLoading(true);
      this.classTitleDialog = "";
      this.typeDialog = "close";
      this.isVisibleCloseButton = false;
      let data = [];
      try {
        this.closingEntryDetails.forEach((e) => {
          e = {
            itemNumber: e.itemNumber,
            accountCode: e.accountCode,
            accountName: e.accountName,
            debitAmount: e.debitAmount,
            creditAmount: e.creditAmount,
          }
          data.push(e);
        });
        const dataWS = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataWS);
        XLSX.writeFile(wb, "ClosingEntry.xlsx");
        this.titleDialog = this.lang.dialogTitleExported;
        this.classTitleDialog = "p-text-success";
        this.contentDialog = this.lang.dialogContentExported;
        this.isVisibleDialog = true; 
        this.setLoading(false);
      } catch (error) {
        this.titleDialog = this.lang.notificationDialogHeader;
        this.classTitleDialog = "p-error";
        this.contentDialog = error;
        this.isVisibleDialog = true;
        this.setLoading(false);
      } 
    },
    validateExport() {
      this.titleDialog = this.lang.notificationDialogHeader;
      this.classTitleDialog = "";
      this.typeDialog = "close";
      this.isVisibleCloseButton = true;
      if (!this.isRolePrint) {
        this.contentDialog = this.lang.validatePrintPermission;
        this.isVisibleDialog = true;
        return true;
      }
      if (!this.selectedAccountCode) {
        this.contentDialog = this.lang.validateAccountCodeIsNull;
        this.isVisibleDialog = true;
        this.accountCodeError = true;
        return true;
      }
      if (this.closingEntryDetails <= 0) {
        this.contentDialog = this.lang.validateNoDetailSelect;  
        this.isVisibleDialog = true;
        return true;
      }
      return false;
    },
    async checkShouldSetData() {
      if (
        this.isSharedRoleByMenuReady &&
        this.isSharedAccountBookReady &&
        this.isSharedAccountCodeReady
      ) {
        this.setAccountBook();
        if (this.closingEntry) {
          this.setData();
        } else if (this.isCreateDocument) {
          this.setLoading(false);
        }
      }
      await this.setCompareData();
    },
    accountBookDataReady() {
      this.isSharedAccountBookReady = true;
      this.checkShouldSetData();
    },
    accountCodeDataReady() {
      this.isSharedAccountCodeReady = true;
      this.checkShouldSetData();
    },
    selectAccountCode() {
      this.accountCodeError = false;
      this.detailError = false;
    },
    resetDescription() {
      // this.description = "";
    },
    setAccountBook() {
      this.selectedAccountBook = this.sharedAccountBooks.find(
        (accountBook) =>
          accountBook.generalLedgerBookCode.trim().toLowerCase() === "jv"
      );
      if (!this.selectedAccountBook) {
        this.titleDialog = this.lang.baseError;
        this.classTitleDialog = "";
        this.contentDialog = this.lang.dialogContentNoneAccountBook;
        this.typeDialog = "back";
        this.isVisibleCloseButton = true;
        this.isVisibleDialog = true;
      }
    },
    closeBaseDialog() {
      this.titleDialog = this.lang.notificationDialogHeader;
      this.classTitleDialog = "";
      this.contentDialog = "";
      this.typeDialog = "close";
      this.isVisibleDialog = false;
    },
    async initiateData() {
      this.code = "";
      this.date = new Date();
      this.description = "";
      // this.selectedAccountBook = null;
      this.selectedAccountCode = null;
      this.accountCodeEquitys = [];
      this.closingEntryDetails = [];
      this.accountCodeError = false;

      this.dateError = false;
      this.detailError = false;

      await this.$store.dispatch("closingEntry/resetClosingEntry");
    },
    async getClosingEntry() {
      try {
        await this.$store.dispatch("closingEntry/getClosingEntry", this.id);
      } catch (error) {
        this.titleDialog = this.lang.notificationDialogHeader;
        this.classTitleDialog = "";
        this.contentDialog = error;
        this.typeDialog = "close";
        this.isVisibleCloseButton = false;
        this.isVisibleDialog = true;
        this.setLoading(false);
        this.$router.push({ name: "closing-entry-edit", params: { id: "create" }});
      }   
    },
    async getClosingVoucher() {
      if (this.validateGetClosingVoucher()) {
        return;
      }
      this.setLoading(true);
      const payload = {
        projectId: this.selectedProject.id,
        dateAt: this.formatDateToISOString(this.date),
        accountId: this.selectedAccountCode.id,
      };
      try {
        await this.$store.dispatch("closingEntry/getClosingVoucher", payload);
        let accountCodeEquitys = this.$store.getters["closingEntry/closingVouchers"];  
        let number = 1;
        accountCodeEquitys.forEach((element) => {
          element.id = "dummy" +number;
          number++;
        });
        this.closingEntryDetails = accountCodeEquitys;
        if (this.closingEntryDetails.length > 0) {
          this.detailError = false;
        }
      } catch (error) {
        this.titleDialog = this.lang.notificationDialogHeader;
        this.classTitleDialog = "";
        this.typeDialog = "close";
        this.isVisibleCloseButton = false;
				let massage = error.massage;
				if (error.code == "106") {
					massage = this.lang.dialogValidateAccountCodeInvalid;

				} else if (error.code == "103") {
					massage = this.lang.dialogValidateParamsProjectId;
				} else if (error.code == "104") {
					massage = this.lang.dialogValidateParamsDateAtId;
				} else if (error.code == "105") {
					massage = this.lang.dialogValidateParamsAccountId;
				} else if (error.code == "110") {
					massage = this.lang.validateRoleSelect;
				}
        this.contentDialog = massage;
        this.isVisibleDialog = true;
        this.setLoading(false);
        this.$router.replace({ name: "closing-entry-edit", params: { id: "create" }});
      }   
      this.setLoading(false);
    },
    validateGetClosingVoucher() {
      // this.titleDialog = this.lang.notificationDialogHeader;
      // this.classTitleDialog = "";
      // this.typeDialog = "close";
      // this.isVisibleCloseButton = true;
      if (!this.selectedAccountCode) {
        // this.contentDialog = this.lang.validateAccountCodeIsNull;
        // this.isVisibleDialog = true;
        this.accountCodeError = true;
        return true;
      }
      return false;
    },
    setData() {
      this.setLoading(true);
      this.date = new Date(this.closingEntry.date);
      this.code = this.closingEntry.code ? this.closingEntry.code.trim() : "";
      this.description = this.closingEntry.description
        ? this.closingEntry.description.trim()
        : "";
      this.selectedAccountBook = this.sharedAccountBooks.find(
        (e) => e.generalLedgerBookId === this.closingEntry.generalLedgerBookId
      );
      let accountCodeEquitys = this.closingEntry.accountCodeEquitys.sort(
        function (a, b) {
          return a.itemNumber - b.itemNumber;
        }
      );
      this.selectedAccountCode = this.sharedAccountCodes.find(
        (e) => e.id === accountCodeEquitys[0].accountId
      );
      this.closingEntryDetails = this.closingEntry.closingEntryDetails.sort(
        function (a, b) {
          return a.itemNumber - b.itemNumber;
        }
      );
      this.setLoading(false);
    },
    async setCompareData() {
      let accountCodeEquitys = [];
      for (const each of this.accountCodeEquitys.filter((each) => each.accountId)) {
        let detail = null;
        if (each.id.includes("dummy") && !each.accountId) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        accountCodeEquitys.push(detail);
      }

      let closingEntryDetails = [];
      for (const each of this.closingEntryDetails.filter((each) => each.accountId)) {
        let detail = null;
        if (each.id.includes("dummy") && !each.accountId) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        closingEntryDetails.push(detail);
      }

      let objToCompare = {
        projectId: this.selectedProject.id,
        generalLedgerBookId: this.selectedAccountBook ? this.selectedAccountBook.generalLedgerBookId : null,
        accountCodeId: this.selectedAccountCode ? this.selectedAccountCode.id : null,
        date: this.date ? this.formatDateToISOString(this.date) : null,
        code: this.code,
        description: this.description,
        accountCodeEquitys: accountCodeEquitys,
        closingEntryDetails: closingEntryDetails,
        typeInsert: "Add",
      };
      if (!this.isCreateDocument) {
        objToCompare = { ...objToCompare, id: this.id, typeInsert: "Update" };
      }
      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
    },
    async askBeforeSave() {
      if (this.validateSave()) {
        return;
      }
      await this.saveData();
    },
    validateSave() {
      this.titleDialog = this.lang.notificationDialogHeader;
      this.classTitleDialog = "";
      this.typeDialog = "close";
      this.isVisibleCloseButton = true;
      if (!this.isCreateDocument) {
        this.contentDialog = this.lang.dialogValidateSaveInsertOnly;
        this.isVisibleDialog = true;
        return true;
      }
      if (!this.isRoleInsert) {
        this.contentDialog = this.lang.dialogValidateInsertVoucherService;
        this.isVisibleDialog = true;
        return true;
      }
      let returnStatus = false;
      if (!this.date) {
        this.dateError = true;
        returnStatus = true;
      }
      if (!this.selectedAccountCode) {
        this.accountCodeError = true;
        returnStatus = true;
      }
      if (this.selectedAccountCode && this.closingEntryDetails <= 0) {
        this.detailError = true;
        returnStatus = true;
      }

      if (returnStatus) {
        this.classTitleDialog = "p-text-error";
        this.titleDialog = this.lang.validateSaveTitle;
        this.contentDialog = this.wordingValidate();
        this.isVisibleDialog = true;
      }

      return returnStatus;
    },
    wordingValidate(){
      if (!this.date) {
        return this.lang.validateDropDownNoSelect + this.lang.date;
      }

      if (!this.selectedAccountCode) {
        return this.lang.validateDropDownNoSelect + this.lang.labelAccountCode;
      }

      if (this.closingEntryDetails <= 0) {
        return this.lang.dialogValidateNoDetail;
      }
    },
    async saveData() {
      this.setLoading(true);
      try {
        await this.$store.dispatch("closingEntry/createClosingEntry", this.payload);
        this.titleDialog = this.lang.notificationDialogHeader;
        this.classTitleDialog = "p-text-success";
        this.contentDialog = this.lang.saveSuccess;
        this.typeDialog = "close";
        this.isVisibleCloseButton = true;
        this.isVisibleDialog = true;
        if (this.isCreateDocument) {
          await this.$router.replace({ name: "closing-entry-edit", params: { id: this.closingEntry.id }});
          await this.setCompareData();
        }
      } catch (error) {
        this.titleDialog = this.lang.notificationDialogHeader;
        this.classTitleDialog = "p-error";
        this.typeDialog = "close";
        this.isVisibleCloseButton = true;
				let massage = error.massage;
				if (error.code == "103") {
					massage = this.lang.dialogValidateParamsProjectId;
				} else if (error.code == "107") {
					massage = this.lang.dialogValidateParamsAccountBook;
				} else if (error.code == "111") {
					massage = this.lang.validateRoleInsert;
				} else if (error.code == "130") {
					massage = this.lang.dialogValidateInsertVoucherService;
				} else if (error.code == "132") {
					massage = `${this.lang.dialogValidateAlreadyClosingEntry.replace(
            "{0}",
            this.formatDateToISOString(this.date)
          )}`;
				}
        this.contentDialog = massage;
        this.isVisibleDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    async confirmCreate() {
      let result = this.isCompareObject(this.objToCompare, this.payload);
      if (result) {
        this.titleDialog = this.lang.notificationDialogHeader;
        this.classTitleDialog = "";
        this.contentDialog = this.lang.askForCreateNewDocument;
        this.typeDialog = "create";
        this.isVisibleCloseButton = true;
        this.isVisibleDialog = true;
      } else {
        await this.createData();
      }
    },
    async createData() {
      this.setLoading(true);
      await this.initiateData();
      await this.$router.push({ name: "closing-entry-edit", params: { id: "create" }});
      this.setCompareData();
      this.isVisibleDialog = false;
      this.setLoading(false);
    },
    async confirmCancel() {
      if (this.validateCancel()) {
        return;
      }
      this.titleDialog = this.lang.dialogTitleCancel;
      this.classTitleDialog = "";
      this.contentDialog = this.lang.dialogContentCancel;
      this.typeDialog = "cancel";
      this.isVisibleCloseButton = true;
      this.isVisibleDialog = true;
    },
    validateCancel() {
      this.titleDialog = this.lang.notificationDialogHeader;
      this.classTitleDialog = "";
      this.typeDialog = "close";
      this.isVisibleCloseButton = true;
      if (this.isCreateDocument) {
        this.contentDialog = this.lang.dataNotFound;
        this.isVisibleDialog = true;
        return true;
      }
      if (!this.isRoleCancel) {
        this.contentDialog = this.lang.validateRoleCancel;
        this.isVisibleDialog = true;
        return true;
      }
      if (!this.selectedAccountCode) {
        this.contentDialog = this.lang.validateAccountCodeIsNull;
        this.isVisibleDialog = true;
        this.accountCodeError = true;
        return true;
      }
      if (this.closingEntryDetails <= 0) {
        this.contentDialog = this.lang.validateNoDetailSelect;  
        this.isVisibleDialog = true;
        return true;
      }
      if (this.closingEntry) {
        if (this.closingEntry.cancelStatus.trim() == "C") {
          this.contentDialog = this.lang.dialogValidateCancelled;
          this.isVisibleDialog = true;
          return true; 
        }
      }
      return false;
    },
    async cancelData() {
      this.setLoading(true);
      const payload = {
        projectId: this.selectedProject.id,
        id: this.id,
      };
      try {
        await this.$store.dispatch("closingEntry/cancelClosingEntry", payload);
        this.titleDialog = this.lang.notificationDialogHeader;
        this.classTitleDialog = "p-text-success";
        this.contentDialog = this.lang.cancelSuccess;
        this.typeDialog = "close";
        this.isVisibleCloseButton = true;
        this.isVisibleDialog = true;
        this.setCompareData();
      } catch (error) {
        this.titleDialog = this.lang.notificationDialogHeader;
        this.classTitleDialog = "p-error";
        this.typeDialog = "close";
        this.isVisibleCloseButton = true;
				let massage = error.massage;
				if (error.code == "101") {
					massage = this.lang.dialogValidateParamsId;
				} else if (error.code == "103") {
					massage = this.lang.dialogValidateParamsProjectId;
				} else if (error.code == "108") {
					massage = this.lang.dataNotFound;
				} else if (error.code == "114") {
					massage = this.lang.validateRoleCancel;
				} else if (error.code == "131") {
					massage = this.lang.dialogValidateCancelVoucherService;
				} 
        this.contentDialog = massage;
        this.isVisibleDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
  },
  computed: {
    payload() {
      let accountCodeEquitys = [];
      for (const each of this.accountCodeEquitys.filter((each) => each.accountId)) {
        let detail = null;
        if (each.id.includes("dummy") && !each.accountId) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        accountCodeEquitys.push(detail);
      }

      let closingEntryDetails = [];
      for (const each of this.closingEntryDetails.filter((each) => each.accountId)) {
        let detail = null;
        if (each.id.includes("dummy") && !each.accountId) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        closingEntryDetails.push(detail);
      }

      let payload = {
        projectId: this.selectedProject.id,
        generalLedgerBookId: this.selectedAccountBook ? this.selectedAccountBook.generalLedgerBookId : null,
        accountCodeId: this.selectedAccountCode ? this.selectedAccountCode.id : null,
        date: this.date ? this.formatDateToISOString(this.date) : null,
        code: this.code,
        description: this.description,
        accountCodeEquitys: accountCodeEquitys,
        closingEntryDetails: closingEntryDetails,
        typeInsert: "Add",
      };
      if (!this.isCreateDocument) {
        payload = { ...payload, id: this.id, typeInsert: "Update" };
      }

      return payload;
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    isCreateDocument() {
      return this.id === "create";
    },
    title() {
      let result = this.lang.createTitle;
      if (!this.isCreateDocument) {
        result = this.lang.editTitle;
      }
      return result;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    closingEntry() {
      return this.$store.getters["closingEntry/selectedClosingEntry"];
    },
    grandTotalDebit() {
      let debitAmount = 0;
      if (this.closingEntryDetails) {
        this.closingEntryDetails.forEach((data) => {
          debitAmount += data.debitAmount;
        });
      }
      return debitAmount;
    },
    grandTotalCredit() {
      let creditAmount = 0;
      if (this.closingEntryDetails) {
        this.closingEntryDetails.forEach((data) => {
          creditAmount += data.creditAmount;
        });
      }
      return creditAmount;
    },
    sharedAccountBooks() {
      let data = [];
      let accountBooks = this.$store.getters["shared/glBookGls"];
      if (accountBooks) {
        accountBooks.forEach((element) => {
          element = {
            ...element,
            ...{
              codeAndName:
                element.generalLedgerBookCode +
                " : " +
                element.generalLedgerBookName,
            },
          };
          data.push(element);
        });
      }
      return data;
    },
    sharedAccountCodes() {
      return this.$store.getters["shared/accountingAccounts"];
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("VC");
    },
    isRoleSelect() {
      if (this.roleByMenu && this.roleByMenu.select == "Y") {
        return true;
      }
      return false;
    },
    isRoleInsert() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    isRoleCancel() {
      if (this.roleByMenu && this.roleByMenu.cancel == "Y") {
        return true;
      }
      return false;
      // let result = false;
      // if (this.roleByMenu && this.roleByMenu.cancel == "Y") {
      //   result = true;
      //   if (
      //     this.closingEntry &&
      //     this.closingEntry.cancelStatus.trim().toLowerCase() === "c"
      //   ) {
      //     result = false;
      //   }
      // }
      // return result;
    },
    isRolePrint() {
      if (this.roleByMenu && this.roleByMenu.print == "Y") {
        return true;
      }
      return false;
    },
    isSelectedAccountBook() {
      if (this.isSharedAccountBookReady && this.selectedAccountBook) {
        return true;
      }
      return false;
    },
    isSelectedAccountCode() {
      if (this.isSharedAccountCodeReady && this.selectedAccountCode) {
        return true;
      }
      return false;
    },
    // isDisableSearch() {
    //   if (this.isCreateDocument && this.selectedAccountCode) {
    //     return true;
    //   }
    //   return false;
    // },
    // isDisableSave() {
    //   if (this.isCreateDocument) {
    //     if (!this.isRoleInsert) {
    //       return true;
    //     } else if (
    //       !this.isSelectedAccountBook ||
    //       !this.isSelectedAccountCode ||
    //       this.closingEntryDetails <= 0
    //     ) {
    //       return true;
    //     }
    //   } else {
    //     return true;
    //   }
    //   return false; // false=enable, true-disable
    // },
    // isDisablePrint() {
    //   if (
    //     !this.isRolePrint ||
    //     this.closingEntryDetails.length <= 0
    //   ) {
    //     return true;
    //   }
    //   return false;
    // },
    dataButtons() {
      let result = [
        {
          id: "cancelCaption",
          caption: this.lang.cancel,
          class: "p-button-outlined p-button-danger mr-2",
          icon: "icon-cancel",
          iconColor: "error",
          action: "gotoCancel",
          isShow: true,
          isDisable: this.isCreateDocument,
          // isDisable: !this.isRoleCancel || this.isCreateDocument,
        },
        {
          id: "createCaption",
          caption: this.lang.createNew,
          class: "p-button-outlined mr-2",
          icon: "icon-create",
          iconColor: "primary",
          action: "gotoCreate",
          isShow: true,
          // isDisable: !this.isRoleInsert,
        },
        {
          id: "exportCaption",
          caption: this.lang.export,
          class: "p-button-outlined mr-2",
          icon: "icon-download-arrow",
          iconColor: "primary",
          action: "gotoExport",
          isShow: true,
          isDisable: this.isCreateDocument,
          // isDisable: this.isDisablePrint,
        },
        {
          id: "saveCaption",
          caption: this.lang.save,
          class: "mr-2",
          icon: "icon-save",
          iconColor: "white",
          action: "gotoSave",
          isShow: true,
          // isDisable: this.isDisableSave,
        },
      ];
      return result;
    },
    dataDialogButtons() {
      let result = [];
      if (this.typeDialog == "close") {
        result = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
        ];
      }
      if (this.typeDialog == "back") {
        result = [
          {
            id: "back",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "goto-list",
          },
        ];
      }
      if (this.typeDialog == "create") {
        result = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "gotoCreate",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-create",
          },
        ];
      }
      if (this.typeDialog == "cancel") {
        result = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "confirmCancel",
            caption: this.lang.buttonDialogConfirm, 
            class: "w-full",
            action: "goto-cancel",
          },
        ];
      }
      return result;
    },
  },
  watch: {
    closingEntry() {
      this.checkShouldSetData();
    },
    isSharedRoleByMenuReady() {
      this.checkShouldSetData();
    },
    payload(curValue) {
      if (this.objToCompare) this.compareObject(this.objToCompare, curValue);
    },
  },
};
</script>

<style></style>
