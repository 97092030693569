<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoCreate="askForCreate"
    @gotoSave="save"
    @gotoDelete="askForDelete"
  />

  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :classTitle="baseDialogClassTitle"
      :titleConfirm="titleConfirm"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
      @goto-create="createNew"
      @goto-delete="deleteData"
      @go-back="goBack"
    ></base-dialog>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{ lang.titleEdit }}</label>

      <PvDivider></PvDivider>

      <base-card
        :titleHeader="lang.contactInfo"
        :isVisibleExpandButton="false"
        :isHideBackground="true"
        :isVisibleCard="true"
        class="col-12 pr-0 pl-0 pb-0"
      >
        <div class="flex flex-wrap" :class="textFormClass">
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="contactType">{{ lang.contactType }}*</label>
            <PvDropdown
              id="contactType"
              class="inputfield w-full mt-1"
              :options="contactTypes"
              optionLabel="nameThai"
              optionValue="id"
              v-model="selectedContactType"
              :placeholder="lang.contactTypePlaceholder"
              :class="{ 'p-invalid': contactTypeError }"
              @change="selectContactType"
            />
            <small
              v-if="contactTypeError"
              id="contactTypeError-help"
              class="p-error"
            >
              {{ lang.contactTypePlaceholder }}
            </small>
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="sequence">{{ lang.itemNo }}*</label>
            <PvInputText
              id="sequence"
              type="text"
              class="inputfield w-full mt-1"
              v-model="sequence"
              :class="{ 'p-invalid': sequenceError }"
              @keypress="keySequence"
            />
            <small v-if="sequenceError" id="sequenceError-help" class="p-error">
              {{ lang.validateSequence }}
            </small>
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="nameTh">{{ lang.nameTh }}*</label>
            <PvInputText
              id="nameTh"
              type="text"
              class="inputfield w-full mt-1"
              v-model="nameTh"
              :class="{ 'p-invalid': nameThError }"
              @keypress="nameThError = false"
            />
            <small v-if="nameThError" id="nameThError-help" class="p-error">
              {{ lang.validateNameTh }}
            </small>
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="nameEn">{{ lang.nameEn }}</label>
            <PvInputText
              id="nameEn"
              type="text"
              class="inputfield w-full mt-1"
              v-model="nameEn"
            />
          </div>
        </div>
      </base-card>

      <base-card
        :titleHeader="lang.phoneInfo"
        :isVisibleExpandButton="false"
        :isHideBackground="true"
        :isVisibleCard="true"
        class="col-12 pr-0 pl-0 pb-0"
      >
        <div class="flex flex-wrap" :class="textFormClass">
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="phoneType">{{ lang.phoneType }}*</label>
            <PvDropdown
              id="phoneType"
              class="inputfield w-full mt-1"
              :options="phoneTypes"
              optionLabel="nameThai"
              optionValue="id"
              v-model="selectedPhoneType"
              :placeholder="lang.phoneTypePlaceholder"
              :class="{ 'p-invalid': phoneTypeError }"
              @change="phoneTypeError = false"
            />
            <small
              v-if="phoneTypeError"
              id="phoneTypeError-help"
              class="p-error"
            >
              {{ lang.phoneTypePlaceholder }}
            </small>
          </div>
          <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
            <label for="phoneNumber">{{ lang.phoneNumber }}*</label>
            <PvInputText
              id="phoneNumber"
              type="text"
              class="inputfield w-full mt-1"
              v-model="phoneNumber"
              :class="{ 'p-invalid': phoneNumberError }"
              @keypress="keyPhoneNumber"
            />
            <small
              v-if="phoneNumberError"
              id="phoneNumberError-help"
              class="p-error"
            >
              {{ lang.validatePhoneNumber }}
            </small>
          </div>
          <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
            <label for="phoneExtension">{{ lang.phoneExtension }}</label>
            <PvInputText
              id="phoneExtension"
              type="text"
              class="inputfield w-full mt-1"
              v-model="phoneExtension"
              @keypress="checkInputNumber"
            />
          </div>
          <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
            <label>{{ lang.activeStatus }}</label>
            <div class="mt-3">
              <PvInputSwitch
                v-model="activeStatus"
                trueValue="1"
                falseValue="0"
              ></PvInputSwitch>
            </div>
          </div>
        </div>
      </base-card>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import usePageDirty from "../../../hooks/pageDirty";
export default {
  props: ["id", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.phoneBook.edit,
          ...lang.th.master.phoneBook.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.phoneBook.edit,
          ...lang.en.master.phoneBook.listing,
        };
      }
    });

    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    const { compareObject, isCompareObject } = usePageDirty();

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      compareObject,
      isCompareObject,
    };
  },
  data() {
    return {
      //Dialog
      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: this.lang.notificationDialogHeader,
      validateWording: "",

      objToCompare: null,

      selectedContactType: "",
      sequence: "",
      nameTh: "",
      nameEn: "",
      selectedPhoneType: "",
      phoneNumber: "",
      phoneExtension: "",
      activeStatus: "1",

      contactTypeError: false,
      sequenceError: false,
      nameThError: false,
      phoneTypeError: false,
      phoneNumberError: false,

      isCreateNew: false,
      isDelete: false,
      isDeleteSuccess: false,
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    await this.getData();
    await this.checkShouldSetData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.setLocalSearch();
      this.setCompareObject();

      this.$router.push({name: "phone-book"});
    },
    async initiateData() {
      this.selectedContactType = "";
      this.sequence = "";
      this.nameTh = "";
      this.nameEn = "";
      this.selectedPhoneType = "";
      this.phoneNumber = "";
      this.phoneExtension = "";
      this.activeStatus = "1";

      this.contactTypeError = false;
      this.sequenceError = false;
      this.nameThError = false;
      this.phoneTypeError = false;
      this.phoneNumberError = false;

      this.showValidateDialog = false;
      this.clearBaseDialog();

      await this.$store.dispatch("phoneBook/resetPhoneBook");
    },
    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      localStorage.setItem(this.storageName, JSON.stringify(search));
    },
    clearBaseDialog() {
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.validateWording = "";

      this.isCreateNew = false;
      this.isDelete = false;
      this.isDeleteSuccess = false;
    },
    async roleByMenuDataReady() {
      if (this.isSharedRoleByMenuReady) {
        if (!this.isCreateDocument) {
          await this.getPhoneBookById();
        }
      }
    },
    async getData() {
      if (this.contactTypes.length <= 0) {
        await this.getContactTypes();
      }
      if (this.phoneTypes.length <= 0) {
        await this.getPhoneTypes();
      }
      if (!this.isCreateDocument) {
        await this.getPhoneBookById();
      }
    },
    async getContactTypes() {
      try {
        await this.$store.dispatch("phoneBook/getAllContactType");

        if (this.contactTypes) {
          this.isSharedContactTypeReady = true;
        }
      } catch (error) {
        this.clearBaseDialog();
        this.validateWording = error;
        this.showValidateDialog = true;
      }
    },
    async getPhoneTypes() {
      try {
        await this.$store.dispatch("phoneBook/getAllPhoneType");
      } catch (error) {
        this.clearBaseDialog();
        this.validateWording = error;
        this.showValidateDialog = true;
      }
    },
    async getPhoneBookById() {
      try {
        await this.$store.dispatch("phoneBook/getPhoneBookById", this.id);
      } catch (error) {
        this.clearBaseDialog();
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error.message;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    async checkShouldSetData() {
      if (this.isSharedRoleByMenuReady) {
        if (this.phoneBook) {
          await this.setData();
        } else if (this.isCreateDocument) {
          this.setLoading(false);
        }
        await this.setCompareData();
      }
    },
    async setData() {
      this.sequence = this.phoneBook.sequence;
      this.nameTh = this.phoneBook.nameThai;
      this.nameEn = this.phoneBook.nameEnglish;
      this.phoneNumber = this.phoneBook.phone;
      this.phoneExtension = this.phoneBook.phoneExtension;
      this.activeStatus = this.phoneBook.activeStatus;
      this.selectedContactType = this.phoneBook.contactTypeId;
      this.selectedPhoneType = this.phoneBook.phoneTypeId;

      this.setLoading(false);
    },
    async setCompareData() {
      let objToCompare = {
        projectId: this.selectedProject.id,
        sequence: this.sequence,
        nameThai: this.nameTh,
        nameEnglish: this.nameEn,
        phone: this.phoneNumber,
        phoneExtension: this.phoneExtension,
        activeStatus: this.activeStatus,
        contactTypeId: this.selectedContactType,
        phoneTypeId: this.selectedPhoneType
          ? this.selectedPhoneType.toString()
          : "",
      };

      if (!this.isCreateDocument) {
        objToCompare = {
          ...objToCompare,
          id: this.id,
        };
      }

      this.objToCompare = objToCompare;
      this.setCompareObject();
    },
    async setCompareObject() {
      if (this.isCreateDocument) {
        this.compareObject(this.objToCompare, this.payload);
      } else {
        let payload = {
          ...this.payload,
          id: this.id,
        };
        this.compareObject(this.objToCompare, payload);
      }
    },
    async askForCreate() {
      this.clearBaseDialog();
      if (!this.isRoleCreate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return;
      }

      let payload = this.payload;
      if (!this.isCreateDocument) {
        payload = {
          ...this.payload,
          id: this.id,
        };
      }
      let result = this.isCompareObject(this.objToCompare, payload);
      this.isCreateNew = true;
      if (result) {
        this.titleConfirm = this.lang.createNewPhoneBook;
        this.validateWording = this.lang.askForCreateNewPhoneBook;
        this.showValidateDialog = true;
      } else {
        await this.createNew();
      }
    },
    validateData() {
      let returnStatus = false;
      this.clearBaseDialog();
      if (this.isCreateDocument && !this.isRoleCreate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return true;
      } else if (!this.isCreateDocument && !this.isRoleUpdate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleUpdate;
        this.showValidateDialog = true;
        return true;
      }

      if (!this.selectedContactType) {
        this.contactTypeError = true;
        returnStatus = true;
      }

      if (!this.sequence) {
        this.sequenceError = true;
        returnStatus = true;
      }

      if (!this.nameTh) {
        this.nameThError = true;
        returnStatus = true;
      }

      if (!this.selectedPhoneType) {
        this.phoneTypeError = true;
        returnStatus = true;
      }

      if (!this.phoneNumber) {
        this.phoneNumberError = true;
        returnStatus = true;
      }

      if (returnStatus) {
        this.baseDialogClassTitle = "p-text-error";
        this.titleConfirm = this.lang.validateSaveTitle;
        this.validateWording = this.wordingValidate();
        this.showValidateDialog = true;
      }

      return returnStatus;
    },
    wordingValidate(){
      if (!this.selectedContactType) {
        return this.lang.validateDropDownNoSelect + this.lang.contactType;
      }

      if (!this.sequence) {
        return this.lang.validateFieldNoValue + this.lang.itemNo;
      }

      if (!this.nameTh) {
        return this.lang.validateFieldNoValue + this.lang.nameTh;
      }

      if (!this.selectedPhoneType) {
        return this.lang.validateDropDownNoSelect + this.lang.phoneType;
      }

      if (!this.phoneNumber) {
        return this.lang.validateFieldNoValue + this.lang.phoneNumber;
      }
    },
    async createNew() {
      this.setLoading(true);
      await this.initiateData();
      await this.$router.push({name: "phone-book-edit", params: { id: "create" }});
      await this.setCompareData();
      this.showValidateDialog = false;
      this.clearBaseDialog();
      this.setLoading(false);
    },
    askForDelete() {
      this.clearBaseDialog();
      if (!this.isRoleDelete) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleDelete;
        this.showValidateDialog = true;
        return;
      }
      this.isDelete = true;
      this.titleConfirm = this.lang.deletePhoneBookTitle;
      this.validateWording = this.lang.askForDeletePhoneBook.replace(
        ":name",
        this.nameTh
      );
      this.showValidateDialog = true;
    },
    async deleteData() {
      this.showValidateDialog = false;
      this.clearBaseDialog();
      this.setLoading(true);
      try {
        let payload = {
          projectId: this.selectedProject.id,
          id: this.id,
        };
        // console.log('delete',payload);

        await this.$store.dispatch("phoneBook/deletePhoneBook", payload);

        this.isDeleteSuccess = true;
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.successfully;
        this.validateWording = this.lang.deleteSuccess;
        this.showValidateDialog = true;
      } catch (error) {
        this.clearBaseDialog();
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error.message;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    async save() {
      if (this.validateData()) {
        return;
      }

      this.clearBaseDialog();
      this.setLoading(true);
      try {
        if (this.isCreateDocument) {
          await this.addData();
        } else {
          await this.updateData();
        }

        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.saveSuccess;
        this.validateWording = this.lang.dialogContentSave;
        this.showValidateDialog = true;
        this.setLoading(false);
      } catch (error) {
        this.clearBaseDialog();
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error.message;
        this.showValidateDialog = true;
        this.setLoading(false);
      }

      if (this.isCreateDocument) {
        await this.$router.replace({name: "phone-book-edit", params: { id: this.phoneBook.id }});
      }
      await this.setCompareData();
    },
    async addData() {
      // console.log("addData", this.payload);
      await this.$store.dispatch("phoneBook/addPhoneBook", this.payload);
    },
    async updateData() {
      let payload = {
        ...this.payload,
        id: this.id,
      };
      // console.log("updateData", payload);
      await this.$store.dispatch("phoneBook/updatePhoneBook", payload);
    },
    async checkInputNumber(event) {
      const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const keyPressed = event.key;
      if (!keysAllowed.includes(keyPressed)) {
        event.preventDefault();
      }
    },
    keySequence(event) {
      this.sequenceError = false;
      this.checkInputNumber(event);
    },
    keyPhoneNumber(event) {
      this.phoneNumberError = false;
      this.checkInputNumber(event);
    },
    async getPhoneBookWithContactType() {
      this.setLoading(true);
      try {
        const payload = {
          projectId: this.selectedProject.id,
          contactTypeId: this.selectedContactType
        };

        await this.$store.dispatch("phoneBook/getAllPhoneBook", payload);
      } catch (error) {
        this.clearBaseDialog();
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    async selectContactType() {
      this.contactTypeError = false;
      await this.getPhoneBookWithContactType();

      this.sequence = (this.phoneBookWithContactType.sequence +1);
      this.sequenceError = false;
    },
  },
  computed: {
    payload() {
      let payload = {
        projectId: this.selectedProject.id,
        sequence: this.sequence,
        nameThai: this.nameTh,
        nameEnglish: this.nameEn,
        phone: this.phoneNumber,
        phoneExtension: this.phoneExtension,
        activeStatus: this.activeStatus,
        contactTypeId: this.selectedContactType,
        phoneTypeId: this.selectedPhoneType,
      };

      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    storageName() {
      return `erp_search_PhoneBookList_${this.selectedProject.code}_${this.userCode}`;
    },
    isCreateDocument() {
      return this.id === "create";
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("erp_phonebook");
    },
    isRoleCreate() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    isRoleUpdate() {
      if (this.roleByMenu && this.roleByMenu.update == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRoleDelete() {
      if (this.roleByMenu && this.roleByMenu.delete == "Y") {
        return true;
      } else {
        return false;
      }
    },
    title() {
      let result = this.lang.createPhoneBook;
      if (!this.isCreateDocument) {
        result = this.lang.editPhoneBook;
      }
      return result;
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "delete",
          action: "gotoDelete",
          isDisable: this.isCreateDocument,
          type: "delete",
        },
        {
          id: "create",
          action: "gotoCreate",
          type: "create",
        },
        {
          id: "save",
          action: "gotoSave",
          type: "save",
        },
      ];
      return dataButtons;
    },
    dataButtonDialog() {
      let dataButtonDialog = [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];

      if (this.isCreateNew) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "gotoCreate",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-create",
          },
        ];
      } else if (this.isDelete) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "gotoDelete",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-delete",
          },
        ];
      } else if (this.isDeleteSuccess) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "go-back",
          },
        ];
      }
      return dataButtonDialog;
    },
    phoneBook() {
      return this.$store.getters["phoneBook/selectedPhoneBook"];
    },
    contactTypes() {
      return this.$store.getters["phoneBook/contactTypes"];
    },
    phoneTypes() {
      return this.$store.getters["phoneBook/phoneTypes"];
    },
    phoneBookWithContactType() {
      let data = this.$store.getters["phoneBook/phoneBooks"];
      return data.sort((a, b) => (a.sequence < b.sequence ? 1 : -1))[0];
    },
  },
  watch: {
    phoneBook() {
      this.checkShouldSetData();
    },
    isSharedRoleByMenuReady() {
      this.checkShouldSetData();
    },
  },
};
</script>
