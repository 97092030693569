export default {
    title: "คำนวณใบแจ้งหนี้",
    validateInsert: "คุณไม่มีสิทธิ์คำนวณใบแจ้งหนี้",
    titleDetail: "รายละเอียดการคำนวณหนี้",
    titleSelectDate: "1. กรุณาเลือกวันที่ที่ต้องการแจ้งหนี้ และวันครบกำหนดชำระ",
    invoiceDate: "วันที่แจ้งหนี้",
    dueDate: "วันที่ครบกำหนด",
    calculatePenalty: "คำนวณค่าปรับ",
    calculatePenaltyDate: "วันที่สิ้นสุดการคำนวณค่าปรับ",
    titleDetailCalculating: "ระบบกำลังคำนวณใบแจ้งหนี้",
    titleDetailCalculatingDesc: "กรุณารอสักครู่ หากสำเร็จแล้ว ระบบจะพาคุณไปในขั้นตอนต่อไป...",
    titleSelectPolicy: "2. ตรวจสอบรายการชุดเรียกเก็บที่ต้องการ",
    next: "ถัดไป",
    validateInvoiceDate: "วันที่แจ้งหนี้จะต้องถึงก่อนวันที่ครบกำหนด",
    goBack: "ย้อนกลับ",
    getPolicyList: "รายการค่าใช้จ่ายตามชุดเรียกเก็บ",
    columnProductCode: "รหัสรายรับ",
    columnProductName: "ชื่อรายรับ",
    columnRemark: "รายละเอียด",
    columnPeriod: "งวด",
    gotoCalculateInvoice: "เริ่มคำนวณใบแจ้งหนี้",
    policy: "ชุดเรียกเก็บ",
    validateCalculateInvoiceTitle: "เริ่มคำนวณไม่สำเร็จ",
    invoiceChecklist: "รายการตรวจสอบใบแจ้งหนี้",
    printInvoiceChecklist: "พิมพ์รายงานสรุปการแจ้งหนี้",

    //invoice
    columnInvoiceCode: "เลขที่เอกสาร",
    columnUnitCode: "ยูนิต",
    columnAddressNo: "บ้านเลขที่",
    columnCustomerName: "ชื่อลูกค้า",
    columnNetAmount: "จำนวนเงิน",
    cancelInvoice: "ยกเลิกใบแจ้งหนี้ทั้งหมด",
    approveAll: "ยืนยันทั้งหมด",
    calculateInvoiceDetail: "รายละเอียดการคำนวณหนี้",
    invoiceDetail: "รายละเอียดหนี้",
    columnInvoiceDate: "วันที่ออกเอกสาร",
    unitCode: "เลขที่ยูนิต",
    invoiceDetailList: "รายการใช้จ่าย",
    columnUnitPrice: "ราคาต่อหน่วย",
    columnRatePenalty: "อัตราค่าปรับ",
    columnPenaltyDay: "จำนวนวันคิดค่าปรับ",
    columnAmount: "ยอดรวม",
    titleConfirmCancelInvoice: "คุณต้องการยกเลิกใบแจ้งหนี้ทั้งหมดใช่หรือไม่ ?",
    saveSuccess: "คุณทำรายการสำเร็จ",
    titleConfirmSaveInvoice: "คุณต้องการยืนยันรายการตรวจสอบใบแจ้งหนี้ทั้งหมดใช่หรือไม่ ?",
    titleSaveSuccess: "ยืนยันสำเร็จ",

    //ตัดรับล่วงหน้าและเงินฝาก
    titleUsePrepaid: "ตัดรับล่วงหน้าและเงินฝาก",
    headerUsePrepaid: "กำหนดเงื่อนไขการตัดรับล่วงหน้าและเงินฝาก",
    confirmUsePrepaid: "คุณต้องการตัดรับล่วงหน้าและเงินฝากหรือไม่ ?",
    usePrepaid: "ตัดรับล่วงหน้า",
    useDeposit: "ตัดเงินฝาก",
    confirmTitleUsePrepaid: "ยืนยันตัดรับล่วงหน้าและเงินฝาก",
    tableEmpty: "ไม่มีรายการชุดเรียกเก็บ",
    validateDueDate: "กรุณาเลือกวันที่ครบกำหนด",

    validateNoSelectedPolicy: "กรุณาเลือกชุดเรียกเก็บ",
    dialogContentUsePrepapidAndDeposit: "คุณต้องการตัดรับล่วงหน้าและเงินฝาก ในตอนนี้หรือไม่?",
    titlePreviewUsePrepaidAndDeposit: "รายการรอการตัด",
    tableUsePrepaidAndDepositEmpty: "ไม่มีรายการรอการตัด",
    progressTitle: "ระบบกำลังทำงาน",
    progressGenerating: "กำลังทำงาน...",
    progressContentLine1: "รับล่วงหน้า/เงินฝากคงเหลือ {0} ยูนิต",
    progressContentLine2: "หักรับล่วงหน้า/เงินฝากได้ {0} ยูนิต",
    progressContentSummary: "ตรวจสอบแล้ว {0}/{1} ยูนิต",
    dialogContentSaveErrorNullData: "ไม่สามารถบันทึกได้ เนื่องจากไม่มีรายการรอบันทึก",
    dialogContentSaveError: "ระบบขัดข้อง ไม่สามารถบันทึกรายการลำดับที่ {0}",
    dialogContentSaveErrorCode: 'ไม่สามารถบันทึกรายการลำดับที่ {0}',
    dialogContentSaveErrorCode101: "เนื่องจากจำนวนเงินที่ใช้หักชำระต้องมากกว่า 0 บาท",
    dialogContentSaveErrorCode105: "ไม่มีจำนวนเงินเหลือให้ใช้หักรับล่วงหน้า",
    dialogContentSaveErrorCode107: "จำนวนหนี้เกินจำนวนเงินรับล่วงหน้า",
    tableUsePrepaidAndDepositUnitCode: "รหัสห้อง/บ้าน",
    tableUsePrepaidAndDepositReceiptCode: "เลขที่ใบเสร็จ/ใบรับฝาก",
    tableUsePrepaidAndDepositInvoiceCode: "เลขที่ใบแจ้งหนี้",
    tableUsePrepaidAndDepositProductName: "ชื่อรายรับ",
    tableUsePrepaidAndDepositPeriod: "งวด",
    tableUsePrepaidAndDepositTotalAmount: "ยอดแจ้งหนี้",
    tableUsePrepaidAndDepositUseAmount: "ยอดที่จะหัก",
    tableUsePrepaidAndDepositUseType: "หักจาก",
    columnUsePrepaidAndDepositUseTypePrepaid: "รับล่วงหน้า",
    columnUsePrepaidAndDepositUseTypeDeposit: "เงินฝาก",
}