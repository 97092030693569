export default {
  async getReceipts(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Receipt";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + encodeURIComponent(JSON.stringify(payload.where));
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get project");
      throw error;
    }
    // console.log("setReceipts=",responseData);

    context.commit("setReceipts", responseData);
  },
  async getReceipt(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Receipt?limit=1";

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get receipt");
      throw error;
    }
    // console.log(responseData);

    // const data = responseData.data;
    if (responseData.data.length > 0) {
      context.commit("setSelectedReceipt", responseData.data[0]);
    }
  },
  resetReceipt(context) {
    context.commit("setSelectedReceipt", null);
  },
  async getReceiptUniqueKey(context) {
    let url = process.env.VUE_APP_BACKEND_URL +"/Receipt/ReceiptUniqueKey";

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get unique key");
      throw error;
    }

    await context.commit("setReceiptUniqueKey", responseData.data);
  },
  async cancelReceipt(context,payload){
    let url = process.env.VUE_APP_BACKEND_URL + `/Receipt/Cancel`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to cancel receipt');
      throw error;
    }
    
    context.commit("setSelectedReceipt", responseData.data);
  },
  async createOrUpdateReceipt(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + `/Receipt/${payload.type}`;
    const fallbackErrorMessage = `Failed to ${payload.type} receipt`
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: payload.type === 'Add' ? 'post' : 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }
    // console.log(responseData);

    context.commit("setSelectedReceipt", responseData.data);
  },
  async printReceipt(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL +"/Receipt/Print";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(payload),
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      
    });  
    
    let urlBlob = ''
    let blob = await response.blob();
    urlBlob = window.URL.createObjectURL(blob);
    context.commit('setBlob', urlBlob);
  },
  async getReceiptReferenceDocument(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/ReferenceDocument/Receipt";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.projectId) {
      url = url + "&projectId=" + payload.projectId;
    }

    if (payload.id) {
      url = url + "&id=" + payload.id;
    }
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get Reference Document");
      throw error;
    }
    // console.log(responseData);

    // const data = responseData.data;
    context.commit("setReceiptReferenceDocument", responseData);
  },
  async validateImportReceipt(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Receipt/ValidateImportReceipt`;

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to validate import receipt.");
      throw error;
    }

    // console.log("validateImportReceipt", responseData);

    await context.commit('setSelectedReceipt', responseData);  

  },
  importReceiptList(context, payload) {
    context.commit("setImportReceiptList", payload);
    // console.log("importReceiptList", payload);
  },
  async updateReceiptVicitorControl(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + `/Receipt/VisitorControl/${payload.type}`;
    const token = context.rootGetters.token;
    const response = await fetch(url, {
      method: payload.type === 'Add' ? 'post' : 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });
    const responseData = await response.json();
		if (!response.ok) {
		  // console.log(responseData);
		  let message = null
		  if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
			message = responseData.title;
			for (const key in responseData.errors) {
			  message += ", " + key.replace("$.","")
			}
		  } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
			message = responseData.message
		  }
		  const error = new Error(message || 'Failed to delete meter usage');
		  throw error;
		}
		if(responseData.code != "200") {
			throw responseData.code;
		}
    context.commit("setSelectedReceipt", responseData.data);
  },
  async validateAndPrepareImportReceipt(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Receipt/ValidateAndPrepareImportReceipt`;

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to validate and prepare import receipt.");
      throw error;
    }

    // console.log("validateAndPrepareImportReceipt", responseData);

    await context.commit('setValidatedImportReceipt', responseData);  

  },
  async mappingInvoiceWithImportReceipt(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Receipt/MappingInvoiceWithImportReceipt`;

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to mapping invoice with import receipt.");
      throw error;
    }

    // console.log("mappingInvoiceWithImportReceipt", responseData);

    await context.commit('setMappedImportReceipt', responseData);  

  },
  setDocumentBookId(context, payload) {
    context.commit("setDocumentBookId", payload);
  },
};
