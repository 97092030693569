<template>
	<PvDialog
		:breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
		:style="{ width: '80vw' }"
		:modal="true"
		:closable="false"
		v-model:visible="showEditView"
	>
		<base-edit-withholding-tax-view
			:id="withholdingTaxId"
			:voucherId="this.voucherId"
			:rvhid="this.rvhid"
			:supplierId="this.supplierId"
		>
		</base-edit-withholding-tax-view>
		<template #footer>
			<PvButton
				:label="lang.close"
				icon="pi pi-times"
				class="p-button-text"
				@click="closeWithholdingTax"
			/>
		</template>
	</PvDialog>

	<base-form-print-modal
		v-model:visible="showFormPrintModal"
		:search="searchTextPrint"
		@close="showFormPrintModal = false"
		@path-selected="formPrintModalSelected"
	></base-form-print-modal>

	<PvDialog
		:breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
		:style="{ width: '80vw' }"
		:modal="true"
		:closable="false"
		v-model:visible="showWithholdingTaxSelected"
	>
		<base-withholding-tax-selected-view
			@close="closeWithholdingTaxSelected()"
			:voucherId="this.voucherId"
			:rvhid="this.rvhid"
			:supplierId="this.supplierId"
		></base-withholding-tax-selected-view>
		<template #footer>
			<PvButton
				:label="lang.close"
				icon="pi pi-times"
				class="p-button-text mt-3"
				@click="closeWithholdingTaxSelected()"
			/>
		</template>
	</PvDialog>

	<withholding-tax-print-lot-dialog
	  v-model:visible="showPrintLotDialog"
      @close="showPrintLotDialog = false"
	  :key="keyToReLoad"
      @toast-request="toastRequest"
	></withholding-tax-print-lot-dialog>
    <base-toast-report-request
      :showToast="showToast"
      :toastMessage="toastMessage"
      :toastLink="toastLink"
      :actionToast="actionToast"
    ></base-toast-report-request>

	<div class="flex justify-content-between align-items-center" style="padding-top: 20px">
		<div class="my-3">
			<PvButton
				icon="pi pi-chevron-left"
				:label="lang.back"
				@click="goBack"
				class="mr-2 p-button-secondary"
				v-if="isNotAccounting && isHideBack"
			/>
		</div>
		<h2 :class="textHeaderClass" class="my-0">{{ lang.title }}</h2>
	</div>

	<div class="surface-card shadow-2 p-3 border-round">
		<div class="pt-2 pb-1">
			<PvButton
				icon="pi pi-file"
				:label="lang.create"
				@click="gotoEdit('create')"
				class="mr-2 mb-2"
			/>
			<PvButton
				icon="pi pi-plus"
				:label="lang.importDocument"
				class="mr-2 mb-2"
				@click="getWithholdingTaxSelected()"
				v-if="rvhid || voucherId"
			/>
			<PvButton
				icon="pi pi-list"
				:label="parameterButtonText"
				@click="toggleParameterVisible"
				class="p-button-help mr-2 mb-2"
				v-if="isNotAccounting"
			/>
			<PvButton
				icon="pi pi-print"
				:label="lang.printLot"
				@click="gotoPrintLot"
				class="mr-2 mb-2"
			/>
		</div>
		<transition>
			<div v-if="isParameterVisible">
				<section class="flex flex-wrap" :class="textFormClass">
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2" v-if="isNotAccounting">
						<label for="dateStart">{{ lang.dateWhtStart }}</label>
						<PvCalendar
							id="dateStart"
							v-model="dateStart"
							class="inputfield w-full mt-1"
							showIcon
							dateFormat="dd/mm/yy"
						/>
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2" v-if="isNotAccounting">
						<label for="dateEnd">{{ lang.dateWhtEnd }}</label>
						<PvCalendar
							id="dateEnd"
							v-model="dateEnd"
							class="inputfield w-full mt-1"
							showIcon
							dateFormat="dd/mm/yy"
						/>
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2" v-if="isNotAccounting">
						<label for="selectedSupplier">{{ lang.customerSearch }}</label>
						<base-supplier-auto-complete
							id="selectedSupplier"
							v-model="selectedSupplier"
							class="inputfield w-full mt-1"
							@load-finish="supplierDataReady"
						/>
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2" v-show="isNotAccounting">
						<label for="taxBook">{{ lang.columnBook }}</label>
						<base-tax-book-auto-complete
							id="taxBook"
							v-model="selectedTaxBook"
							class="inputfield w-full mt-1"
							@load-finish="taxBookDataReady"
						/>
					</div>

					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2" v-if="isNotAccounting">
						<label for="codeStart">{{ lang.codeStart }}</label>
						<PvInputText
							id="codeStart"
							type="text"
							class="inputfield w-full mt-1"
							placeholder="6501xxxxx"
							v-model="codeStart"
						/>
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2" v-if="isNotAccounting">
						<label for="codeEnd">{{ lang.codeEnd }}</label>
						<PvInputText
							id="codeEnd"
							type="text"
							class="inputfield w-full mt-1"
							placeholder="6501xxxxx"
							v-model="codeEnd"
						/>
					</div>
					<div class="flex col-12 pl-0 align-content-center" v-if="isNotAccounting">
						<PvButton
							icon="pi pi-search"
							:label="lang.search"
							@click="getWithholdingTaxs"
							class="mr-2"
						/>
					</div>
				</section>
			</div>
		</transition>

		<PvDataTable
			showGridlines
			:value="withholdingTaxs"
			v-model:selection="selectedWithholdingTax"
			selectionMode="single"
			dataKey="id"
			responsiveLayout="scroll"
			:stripedRows="true"
			class="p-datatable-sm"
			:class="textTableClass"
			:loading="isLoading"
			@row-dblclick="gotoEdit(this.selectedWithholdingTax.id)"
		>
			<template #empty>
				<div class="w-full p-3" Style="text-align: center;">
				<label>{{ lang.tableEmpty }}</label>
				</div>
			</template>
			<PvColumn
				field="cancelStatus"
				:header="lang.columnIsCancel"
				style="min-width: 3rem"
				headerClass="h-center"
				bodyClass="text-center"
				sortable
			></PvColumn>
			<PvColumn
				field="documentBookCode"
				:header="lang.columnBook"
				style="min-width: 3rem"
				headerClass="h-center"
				bodyClass="text-center"
				sortable
			></PvColumn>
			<PvColumn
				field="code"
				:header="lang.columnCode"
				style="min-width: 4rem"
				headerClass="h-center"
				sortable
			></PvColumn>
			<PvColumn
				field="date"
				:header="lang.columnDate"
				style="min-width: 4rem"
				headerClass="h-center"
				bodyClass="text-center"
				sortable
			>
				<template #body="slotProps">
					{{ formatDate(slotProps.data.date) }}
				</template>
			</PvColumn>
			<PvColumn
				field="dueDate"
				:header="lang.columnDueDate"
				style="min-width: 4rem"
				headerClass="h-center"
				bodyClass="text-center"
				sortable
			>
				<template #body="slotProps">
					{{ formatDate(slotProps.data.dueDate) }}
				</template>
			</PvColumn>
			<PvColumn
				field="customerName"
				:header="lang.customerSearch"
				style="min-width: 4rem"
				headerClass="h-center"
				sortable
			></PvColumn>
			<PvColumn
				field="payTotalAmount"
				:header="lang.columnPrice"
				style="min-width: 3rem"
				headerClass="h-center"
				bodyClass="text-right"
				sortable
			>
				<template #body="slotProps">
					{{ formatCurrency(slotProps.data.payTotalAmount) }}
				</template>
			</PvColumn>
			<PvColumn
				field="taxTotalAmount"
				:header="lang.columnTaxAmount"
				style="min-width: 3rem"
				headerClass="h-center"
				bodyClass="text-right"
				sortable
			>
				<template #body="slotProps">
					{{ formatCurrency(slotProps.data.taxTotalAmount) }}
				</template>
			</PvColumn>
			<PvColumn
				field="voucherCode"
				:header="lang.columnVoucherCode"
				style="min-width: 3rem"
				headerClass="h-center"
				bodyClass="text-center"
				sortable
				v-if="isNotAccounting"
			></PvColumn>
			<PvColumn
				style="min-width: 4rem"
				headerClass="h-center"
				bodyStyle="text-align: center; overflow: visible"
			>
				<template #body="slotProps">
					<PvButton
						type="button"
						class="p-button-secondary p-button-text py-0"
						icon="pi pi-pencil"
						@click="gotoEdit(slotProps.data.id)"
					></PvButton>
					<PvButton
						type="button"
						class="p-button-secondary p-button-text py-0"
						icon="pi pi-print"
						@click="selectPrintForm(slotProps.data)"
					></PvButton>
					<PvButton
						type="button"
						class="p-button-danger p-button-text py-0"
						icon="pi pi-times"
						v-if="
							!isNotAccounting &&
							(slotProps.data.voucherId != '' || this.isCreateRVH)
						"
						@click="deleteWithholdingTax(slotProps.data.id)"
						readonly=""
					></PvButton>
					<PvButton
						type="button"
						class="p-button-secondary p-button-text py-0"
						icon="pi pi-trash"
						v-if="!isNotAccounting && (slotProps.data.voucherId == '' || this.isHaveRVH)"
						@click="deleteWithholdingTaxImport(slotProps.data.id)"
					></PvButton>
				</template>
			</PvColumn>
			<template #footer v-if="isNotAccounting">
				<PvPaginator
					:rows="rowsPerPage"
					:totalRecords="totalRecords"
					@page="onPageChange($event)"
					:rowsPerPageOptions="[10, 50, 100]"
					:first="showCurrentPage"
				>
					<template #start="slotProps">
						{{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /
						{{ totalPage }}
					</template>
				</PvPaginator>
			</template>
		</PvDataTable>
	</div>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import baseEditWithholdingTaxView from "../withholdingTax/BaseEditWithholdingTaxView.vue";
import baseWithholdingTaxSelectedView from "../withholdingTax/BaseWithholdingTaxSelectedView.vue";
import useGetFormPrint from "../../hooks/getFormPrint.js";
import WithholdingTaxPrintLotDialog from "../withholdingTax/WithholdingTaxPrintLotDialog.vue"
export default {
	props: ["id", "openFrom", "voucherId", "rvhid", "supplierId"],
	components: {
		baseEditWithholdingTaxView,
		baseWithholdingTaxSelectedView,
		WithholdingTaxPrintLotDialog,
	},
	setup() {
		const { lang } = useLang();
		const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
		const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
		const { getFormPrints, searchTextPrint, isSharedFormPrintReady, getPreviewBlob, filteredFormPrints } =
			useGetFormPrint();

		const store = useStore();
		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return { ...lang.th.base, ...lang.th.ap.withholdingTax.listing };
			} else {
				return { ...lang.en.base, ...lang.en.ap.withholdingTax.listing };
			}
		});

		const isHideBack = inject("isHideBack");

		return {
			lang: mergeLang,
			textHeaderClass,
			textFormClass,
			textTableClass,
			formatCurrency,
			formatDate,
			formatDateToISOString,
			getFormPrints,
			searchTextPrint,
			isSharedFormPrintReady,
			getPreviewBlob,
			filteredFormPrints,

			isHideBack,
		};
	},
	data() {
		return {
			isParameterVisible: true,

			selectedSupplier: "",
			supplierSearch: "",
			isSharedSupplierReady: false,

			selectedTaxBook: "",
			taxBookSearch: "",
			isSharedTaxBookReady: false,

			selectedWithholdingTax: null,
			selectedEditId: null,
			isLoading: false,
			dateStart: null,
			dateEnd: null,
			codeStart: "",
			codeEnd: "",
			selectedPage: 1,
			rowsPerPage: 10,
			showFormPrintModal: false,
			path: "",
			dataPrint: [],
			isNotAccounting: true,
			showEditView: false,
			beforePath: "",
			withholdingTaxId: "",

			showWithholdingTaxSelected: false,
			storageName: "",

			showPrintLotDialog: false,
			keyToReLoad: 0,

			showToast: 0,
			toastMessage: {},
			toastLink: "",
			actionToast: "",
		};
	},
	async created() {
		await this.$store.dispatch("apWithholdingTax/resetWithholdingTaxList");
		this.beforePath =
			this.$route.fullPath.includes("gl/") || this.$route.fullPath.includes("ap/purchase-receipt/");
		if (this.beforePath) {
			this.isNotAccounting = false;
			// this.isParameterVisible = false;
		}

		let date = new Date();
		this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
		this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
		this.storageName = `erp_search_withholdingTaxList_${this.selectedProject.code}_${this.userCode}`;

		const localSearch = localStorage.getItem(this.storageName);
		if (localSearch) {
			const search = JSON.parse(localSearch);
			if (search.entry === "Entry") {
				if (search.dateStart != null && search.dateStart != "") {
					this.dateStart = new Date(search.dateStart);
				} else this.dateStart = "";

				if (search.dateEnd != null && search.dateEnd != "") {
					this.dateEnd = new Date(search.dateEnd);
				} else this.dateEnd = "";
				this.codeStart = search.codeStart || this.codeStart;
				this.codeEnd = search.codeEnd || this.codeEnd;
				this.supplierSearch = search.customerId || this.selectedSupplier.id;
				this.taxbookSearch = search.documentBookId || this.selectedTaxBook.id;
				this.selectedSupplier = search.selectedSupplier || this.selectedSupplier;
				this.selectedTaxBook = search.selectedTaxBook || this.selectedTaxBook;
				this.rowsPerPage = search.rowsPerPage || this.rowsPerPage;
          		this.selectedPage = search.selectedPage || this.selectedPage;
				search.entry = "";
				localStorage.setItem(this.storageName, JSON.stringify(search));
			}
		}
		if (this.beforePath) {
			await this.getWithholdingTaxWithVouchers();
		} else {
			await this.getWithholdingTaxs();
		}
	},
	methods: {
		setLoading(bool) {
			this.$store.dispatch("setLoading", { value: bool });
		},
		goBack() {
			this.$router.push({name: "home"})
		},
		gotoEdit(data) {
			// console.log(data);
			this.selectedEditId = data;
			if (this.beforePath) {
				this.showEditView = true;
				this.withholdingTaxId = data;
			} else {
				this.$router.push({ name: "ap-withholding-tax-edit", params: { id: data } })
			}
			// this.$router.push(`/ap/withholding-tax/${data}`);
		},
		toggleParameterVisible() {
			this.isParameterVisible = !this.isParameterVisible;
		},
		async onPageChange(event) {
			this.selectedPage = event.page + 1;
			this.rowsPerPage = event.rows;
			await this.getWithholdingTaxs();
		},
		async getWithholdingTaxs() {
			this.isLoading = true;
			let customerId = null;
			let documentBookId = null;
			if (this.selectedSupplier) {
				customerId = this.selectedSupplier.id;
			}
			if (this.selectedTaxBook) {
				documentBookId = this.selectedTaxBook.id;
			}

			let where = {
				projectId: this.selectedProject.id,
				dateStart: this.selectedDateStart,
				dateEnd: this.selectedDateEnd,
				customerId: customerId,
				codeStart: this.codeStart || null,
				codeEnd: this.codeEnd || null,
				documentBookId: documentBookId,
			};

			const payload = {
				page: this.selectedPage,
				limit: this.rowsPerPage,
				where,
			};

			await this.$store.dispatch("apWithholdingTax/getWithholdingTaxs", payload);

			where = {
				...where,
				selectedTaxBook: this.selectedTaxBook,
				selectedSupplier: this.selectedSupplier,
				dateStart: this.dateStart,
				dateEnd: this.dateEnd,
				rowsPerPage: this.rowsPerPage,
				selectedPage: this.selectedPage,
			};

			localStorage.setItem(this.storageName, JSON.stringify(where));
			this.isLoading = false;
		},
		async getWithholdingTaxWithVouchers() {
			this.isLoading = true;
			let where = {
				projectId: this.selectedProject.id,
			};
			if (this.voucherId !== null) {
				where = { ...where, ...{ voucherId: this.voucherId } };
			}
			if (this.rvhid !== null) {
				where = { ...where, ...{ RVHId: this.rvhid } };
			}

			const payload = {
				page: this.selectedPage,
				limit: this.rowsPerPage,
				where,
			};

			await this.$store.dispatch("apWithholdingTax/getWithholdingTaxWithVouchers", payload);

			where = {
				...where,
				rowsPerPage: this.rowsPerPage,
				selectedPage: this.selectedPage,
			};

			localStorage.setItem(this.storageName, JSON.stringify(where));
			this.isLoading = false;
		},
		supplierDataReady() {
			this.isSharedSupplierReady = true;
		},
		taxBookDataReady() {
			this.isSharedTaxBookReady = true;
		},
		async selectPrintForm(data) {
			let printPermission = this.sharedTaxBooks.find(
				(taxBook) => taxBook.code === data.documentBookCode.trim()
			);

			if (printPermission.print == "N") {
				this.$swal({
					icon: "warning",
					title: this.lang.validatePrintPermission,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				return true;
			} else {
				this.dataPrint = data;
				await this.$store.dispatch("shared/resetFormPrint");
				let where = {
					projectId: this.selectedProject.id,
					documentBookCode: data.documentBookCode.trim(),
				};
				const payload = { where };
				await this.getFormPrints(payload);
				
				if(this.filteredFormPrints.length == 1) {
					this.path = this.filteredFormPrints[0].path;
					this.PrintWithholdingTax();
				}else{
					this.showFormPrintModal = true;
     	 		}
			}
		},
		formPrintModalSelected(event) {
			this.path = event.path;
			this.PrintWithholdingTax();
			this.showFormPrintModal = false;
		},
		async PrintWithholdingTax() {
			this.setLoading(true);
			const WithholdingTax = {
				id: this.dataPrint.id,
				projectId: this.selectedProject.id,
				path: this.path,
			};
			try {
				await this.$store.dispatch("apWithholdingTax/PrintWithholdingTax", WithholdingTax);
			} catch (error) {
				// console.log("error=", error);
				this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});

				this.setLoading(false);
			}
			let blob = this.$store.getters["apWithholdingTax/blob"];
			this.getPreviewBlob(blob);

			this.setLoading(false);
		},
		async closeWithholdingTax() {
			this.setLoading(true);
			await this.getWithholdingTaxWithVouchers();
			this.showEditView = false;

			if (this.selectedEditId !== "create") {
				let data = this.withholdingTaxsImport;
				let i = data.map((item) => item.id).indexOf(this.selectedEditId);
				data[i] = this.withholdingTaxUpdate;
				await this.$store.dispatch("apWithholdingTax/setWithholdingTaxsImport", data);
			}

			this.setLoading(false);
		},
		async deleteWithholdingTax(id) {
			this.setLoading(true);
			const result = await this.$swal({
				icon: "info",
				html: `<p class="${this.textAlertClass}">${this.lang.askForDeleteDocument}</p>`,
				confirmButtonText: this.lang.confirm,
				showCancelButton: true,
				cancelButtonText: this.lang.close,
				allowOutsideClick: false,
				confirmButtonColor: "#EF4444",
				cancelButtonColor: "#64748B",
			});

			if (result.isConfirmed) {
				let rvhCode = "";
				let rvhBookCode = "";
				const data = this.withholdingTaxs.find((withholdingTax) => withholdingTax.id === id);
				if (!this.$route.fullPath.includes("ap/purchase-receipt/"))
				{
					if(data){
						rvhCode = data.rvhcode;
						rvhBookCode = data.rvhbookCode;
					}
				}
	
				// this.withholdingTaxs.forEach((withholdingTax) => {
				// 	rvhCode = withholdingTax.rvhcode;
				// 	rvhBookCode = withholdingTax.rvhbookCode;
				// });
				// console.log(rvhCode);
				// console.log(rvhBookCode);

				if (rvhCode && !this.$route.fullPath.includes("ap/purchase-receipt/")) {
					this.$swal({
						icon: "warning",
						title: this.lang.unLink + "\n" + rvhBookCode + " : " + rvhCode,
						confirmButtonText: this.lang.close,
						allowOutsideClick: false,
					});
					this.setLoading(false);
					return true;
				}

				try {
					let where = { id: id };
					if (this.rvhid) {
						where = { ...where, ...{ RVHId: this.rvhid } };
					}
					await this.$store.dispatch("apWithholdingTax/deleteWithholdingTaxWithVoucher", where);

					// let i = this.withholdingTaxs.map((item) => item.id).indexOf(id);
					// this.withholdingTaxs.splice(i, 1);

					if (this.beforePath) {
						await this.getWithholdingTaxWithVouchers();
					} else {
						await this.getWithholdingTaxs();
					}

					this.$swal({
						icon: "success",
						title: this.lang.successfully,
						confirmButtonText: this.lang.close,
						allowOutsideClick: false,
					});
				} catch (error) {
					this.$swal({
						icon: "error",
						html: `<p class="${this.textAlertClass}">${error}</p>`,
						confirmButtonText: this.lang.close,
						allowOutsideClick: false,
					});
					this.setLoading(false);
				}
			} else if (result.isDenied) {
				//
			}
			this.setLoading(false);
		},

		getWithholdingTaxSelected() {
			this.showWithholdingTaxSelected = true;
		},
		closeWithholdingTaxSelected() {
			this.showWithholdingTaxSelected = false;
		},
		deleteWithholdingTaxImport(id) {
			let i = this.withholdingTaxsImport.map((item) => item.id).indexOf(id);
			this.withholdingTaxsImport.splice(i, 1);
		},
		gotoPrintLot() {
			this.keyToReLoad++;
			this.showPrintLotDialog = true;
		},
		toastRequest() {
			this.toastMessage = {
				severity: "info",
				summary: this.lang.toastRequestTitle,
				detail: this.lang.toastRequestContent,
				life: 5000,
			};
			this.actionToast = `/${this.selectedProject.code}/report/request?back=${this.$route.fullPath}`;
			this.toastLink = this.lang.toastRequestLink;

			const today = new Date();
			this.showToast = today.getSeconds();
		},
	},
	computed: {
		parameterButtonText() {
			return this.isParameterVisible ? this.lang.hideParameters : this.lang.showParameters;
		},
		halfViewportHeight() {
			return this.$store.getters.halfViewportHeight;
		},
		selectedProject() {
			return this.$store.getters.selectedProject;
		},
		userCode() {
			return this.$store.getters.userCode;
		},
		totalPage() {
			return this.$store.getters["apWithholdingTax/totalPage"];
		},
		currentPage() {
			return this.$store.getters["apWithholdingTax/currentPage"];
		},
		totalRecords() {
			return this.totalPage * this.rowsPerPage;
		},
		selectedDateStart() {
			let dateStart = null;
			if (this.dateStart) {
				dateStart = this.formatDateToISOString(this.dateStart);
			}
			return dateStart;
		},
		selectedDateEnd() {
			let dateEnd = null;
			if (this.dateEnd) {
				dateEnd = this.formatDateToISOString(this.dateEnd);
			}
			return dateEnd;
		},
		withholdingTaxs() {
			let result = [
				...this.$store.getters["apWithholdingTax/withholdingTaxs"],
				...this.$store.getters["apWithholdingTax/withholdingTaxsImport"],
			];
			return result;
			// return this.$store.getters["apWithholdingTax/withholdingTaxs"];
		},
		withholdingTaxUpdate() {
			return this.$store.getters["apWithholdingTax/selectedWithholdingTax"];
		},
		withholdingTaxsImport() {
			return this.$store.getters["apWithholdingTax/withholdingTaxsImport"];
		},
		sharedTaxBooks() {
			return this.$store.getters["shared/taxBookTaxBooks"];
		},
		showCurrentPage() {
			let page = 0;
			page = this.rowsPerPage * (this.selectedPage - 1);
			return page;
		},
		isCreateRVH() {
			if(this.isHaveRVH && this.rvhid.trim() != 'create') return true
			return false
		},
		isHaveRVH() {
			if (this.rvhid) {
				if(this.rvhid.trim() != '') return true
			}
			return false
		}
	},
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
	transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
	transform: translateY(-20px);
	opacity: 0;
}
</style>
