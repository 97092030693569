export default {
    title: "Debt Free Follow Up",
    debtFreeList: "Debt Free Follow Up",
    dateRange: "Doucument Date",
    unitAddress: "Unit/Address no.",
    ownerName: "Owner Name",
    searchTextPlaceholder: "Fill up to search.",
    followupStatusAll: "All Status",
    followupStatus1: "1.Transfer",
    followupStatus2: "2.Unsaleable",
    followupStatus3: "3.Refinance",
    followupStatus4: "4.Expire",
    followupStatus5: "5.Other",
    unitCode: "Unit Code",
    unitAddressNo: "Address .No",
    documentCode: "Document Code",
    requestDate: "Request Date",
    followupStatus: "Follow Up Status",
    followupDate: "Follow Up Date",
    followup: "Follow Up",
    validateUnFollowupTitle: "Can't make a transaction",
    validateUnFollowupTitleDetail: "The debt free has already been adjusted status.",
}