<template>
  <base-toolbar
    :title="lang.title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoCreate="gotoEdit('create')"
    @gotoPrint="tryPrintInvoice(null)"
    :hideBackButton="isHideBack"
  >
  </base-toolbar>

  <base-dialog
    v-model:visible="showValidateDialog"
    :titleConfirm="titleConfirm"
    :classTitle="baseDialogClassTitle"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    @confirm="confirmDialog"
    @close="showValidateDialog = false"
    @gotoUnlock="unlockInvoice"
  ></base-dialog>

  <base-reference-document-dialog
    v-model:visible="showReferenceDocument"
    @close="showReferenceDocument = false"
    :dataTable="referenceDocumentData"
    :columns="columnsReferenceDocument"
    :title="lang.titleRefdocument"
  >
  </base-reference-document-dialog>

  <base-form-print-modal
    v-model:visible="showFormPrintModal"
    :search="searchTextPrint"
    @close="showFormPrintModal = false"
    @path-selected="formPrintModalSelected"
  ></base-form-print-modal>

  <invoice-print-lot-dialog
    :openDialog="openDialog"
    :documentBookId="documentBookId"
    v-model:visible="showInvoicePrintLotDialog"
    @confirm="confirmPrintLot"
    @close="closePrintLot"
  ></invoice-print-lot-dialog>

  <base-toast-report-request
    :showToast="showToast"
    :toastMessage="toastMessage"
    :toastLink="toastLink"
    :actionToast="actionToast"
  ></base-toast-report-request>
  
  <import-invoice-dialog
      v-model:visible="showUploadInvoiceDialog"
      @close="showUploadInvoiceDialog = false"
    ></import-invoice-dialog>

  <base-container>
    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5" :class="textFormClass">
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.title }}</label>
        <div>
          <PvButton class="p-button-outlined ml-2" @click="sendNotification()">
            <icon-box-delivery-package-return-customer
              class="p-button-outlined p-button-primary mr-2"
              iconColor="primary"
              height="16"
              width="16"
            />
            <span style="font-size: 14px">
              {{ lang.sendNotification }}
            </span>
          </PvButton>
          
          <PvButton
            class="p-button-outlined ml-3"
            @click="importInvoice"
          >
            <icon-download iconColor="primary" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{ lang.import }}</span>
          </PvButton>

          <!-- <PvButton
            class="p-button-outlined ml-2"
            @click="tryPrintInvoice(null)"
            :disabled="!this.isRolePrint"
          >
            <icon-printer
              class="p-button-outlined p-button-primary mr-2"
              iconColor="primary"
              height="16"
              width="16"
            />
            <span style="font-size: 14px">
              {{ lang.printInvoice }}
            </span>
          </PvButton> -->
        </div>
      </div>

      <PvDivider></PvDivider>
      <div class="flex flex-wrap mb-3">
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pb-0">
          <PvDropdown
            class="inputfield w-full"
            :options="selectType"
            optionLabel="name"
            optionValue="code"
            v-model="selectedType"
            @change="changeSelectedType()"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
          <div class="surface-card flex justify-content-between">
            <div
              class="surface-card flex justify-content-between"
              v-if="this.selectedType === 'Date'"
            >
              <PvCalendar
                id="dateStart"
                v-model="dateStart"
                class="inputfield w-full"
                showIcon
                dateFormat="dd/mm/yy"
              />
              <label class="ml-2 mr-2 pt-3">{{ " - " }}</label>
              <PvCalendar
                id="dateEnd"
                v-model="dateEnd"
                class="inputfield w-full"
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>

            <span
              class="p-input-icon-left w-full"
              v-else-if="this.selectedType === 'Code' || this.selectedType === 'Price'"
            >
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchNumber"
                :placeholder="this.selectedTypePlaceholder"
                @keypress="searchNumberEnter($event)"
                autofocus
              />
            </span>

            <span class="p-input-icon-left w-full" v-else>
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchText"
                :placeholder="this.selectedTypePlaceholder"
                @keyup="searchTextEnter($event)"
                autofocus
              />
            </span>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pb-0 flex flex-wrap">
          <div class="field-checkbox m-0 mr-2 ml-2">
            <PvCheckbox
              id="selectedIsPending"
              v-model="selectedIsPending"
              :binary="true"
            />
            <label for="selectedIsPending">{{ lang.unPaidOnly }}</label>
          </div>

          <PvButton class="ml-2" @click="getInvoices()">
            <span class="font-normal">{{ lang.search }}</span>
          </PvButton>

          <div></div>
        </div>
      </div>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="invoices"
          v-model:selection="selectedInvoice"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(this.selectedInvoice.id)"
          scrollable
        >
          <PvColumn
            field="code"
            :header="lang.columnCode"
            style="min-width: 10rem; height: 45px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <u>{{ slotProps.data.code }}</u>
            </template>
          </PvColumn>
          <PvColumn
            field="date"
            :header="lang.columnDate"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.date) }}
            </template>
          </PvColumn>
          <PvColumn
            field="unitCode"
            :header="lang.columnUnit"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>
          <PvColumn
            field="unitAddressNo"
            :header="lang.columnAddress"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>
          <PvColumn
            field="customerName"
            :header="lang.columnCustomerName"
            style="min-width: 20rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>
          <PvColumn
            field="totalAmount"
            style="min-width: 8rem"
            :header="lang.columnPrice"
            headerClass="h-right"
            bodyClass="text-right"
            sortable
          >
            <template #body="slotProps">
              <span class="text-right w-full">
                {{ formatCurrency(slotProps.data.totalAmount) }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            :header="lang.columnIsPaid"
            style="min-width: 12rem"
            headerClass="h-center"
            bodyClass="text-left font-normal"
          >
            <template #body="slotProps">
              <base-tag
                :Caption="lang.fullPayment"
                Class="success"
                v-if="slotProps.data.paidStatus == 'F'"
              />
              <base-tag
                :Caption="lang.splitRefund"
                Class="primary"
                v-else-if="slotProps.data.paidStatus == 'D'"
              />
            </template>
          </PvColumn>
          <PvColumn
            field="postStep"
            :header="lang.columnPostStep"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <base-tag
                :Caption="lang.printed"
                Class="primary"
                v-if="slotProps.data.postStep == 'P'"
              />
              <base-tag
                :Caption="lang.accRecorded"
                Class="primary"
                v-else-if="slotProps.data.postStep == 'A'"
              />
            </template>
          </PvColumn>

          <PvColumn
            :header="lang.columnIsCancel"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <base-tag
                Caption="ยกเลิก"
                Class="error"
                v-if="slotProps.data.cancelStatus == 'C'"
              />
            </template>
          </PvColumn>

          <PvColumn
            field="createDate"
            :header="lang.columnCreateDate"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.createDate) }}
            </template>
          </PvColumn>
          <PvColumn
            field="updateDate"
            :header="lang.columnUpdateDate"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <label v-if="slotProps.data.updateDate">
                {{ formatDate(slotProps.data.updateDate) }}
              </label>
            </template>
          </PvColumn>

          <PvColumn
            field="createBy"
            :header="lang.columnCreateBy"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>
          <PvColumn
            field="updateBy"
            :header="lang.columnUpdateBy"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>

          <PvColumn
            style="min-width: 15rem"
            headerClass="h-center"
            bodyStyle="text-align: center;justify-content: center;align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>

              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                @click="tryPrintInvoice(slotProps.data)"
              >
                <icon-printer iconColor="secondary" width="24" height="24" />
              </PvButton>
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                :disabled="slotProps.data.postStep.trim() == ''"
                @click="unlockDocument(slotProps.data)"
              >
                <icon-lock iconColor="secondary" width="24" height="24" v-if="slotProps.data.postStep == 'P' || slotProps.data.postStep == 'A'" />
                <icon-unlock iconColor="secondary" width="24" height="24"  v-else />               
              </PvButton>
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                @click="openReferenceDocument(slotProps.data.id)"
              >
                <icon-attachment-link width="24" height="24"></icon-attachment-link>
              </PvButton>
            </template>
          </PvColumn>     

          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>

          <template #footer>
            <div>
              <PvPaginator
                :rows="rowsPerPage"
                :totalRecords="totalRecords" 
                @page="onPageChange($event)"
                :rowsPerPageOptions="[10, 50, 100]"
                class="p-pagination-radius"
                :first="showCurrentPage"
              >
                <template #start="slotProps">     
                  {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{
                    totalPage
                  }}
                </template>
              </PvPaginator>
            </div>
          </template>
        </PvDataTable>
      </div>

    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useGetRoleByBook from "../../../hooks/getRoleByBook.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import InvoicePrintLotDialog from "../../../components/AR/invoice/InvoicePrintLotDialog.vue";
import BaseToastReportRequest from '@/components/UI/BaseToastReportRequest.vue';
import ImportInvoiceDialog from "../../../components/AR/invoice/ImportInvoiceDialog.vue";
import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
export default {
  components: {
    InvoicePrintLotDialog,
    BaseToastReportRequest,
    ImportInvoiceDialog
  },
  setup() {
    const { lang } = useLang();
    const { textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const isHideBack = inject("isHideBack");
    const { getFormPrints, searchTextPrint, getPreviewBlob, filteredFormPrints } = useGetFormPrint();
    const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();
    const { invoicePrintOrderChanges } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.invoice.listing,
          ...lang.th.shared.referenceDocumen,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.invoice.listing,
          ...lang.en.shared.referenceDocumen,
        };
      }
    });
    return {
      lang: mergeLang,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
      getRoleByBookByCode,
      isSharedRoleByBookReady,
      isHideBack,
      invoicePrintOrderChanges,
    };
  },
  data() {
    return {
      isParameterVisible: true,
      isLoading: false,
      dateStart: null,
      dateEnd: null,
      code: "",
      customerName: "",
      selectedInvoice: null,
      selectedIsPending: true,
      selectedPage: 1,
      rowsPerPage: 10,
      columnsReferenceDocument: [
        {
          field: "documentBookCode",
          header: this.lang.documentBookCode,
          type: "string",
          style:
            "width: 10%;height: 56px;border-radius: 12px 0 0 0;border-bottom-left-radius: 12px;",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
        },
        {
          field: "referenceCode",
          header: this.lang.code,
          type: "string",
          style: "width: 15%",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
        },
        {
          field: "date",
          header: this.lang.date,
          type: "date",
          style: "width: 10%",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
        },
        {
          field: "cancelStatus",
          header: this.lang.cancelStatus,
          type: "string",
          style: "width: 10%",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-center p-text-secondary",
        },
        // {
        //   field: "referenceDocument",
        //   header: this.lang.referenceDocument,
        //   type: "string",
        //   style: "width: 10%",
        //   headerClass: "h-left font-sm font-normal",
        //   bodyClass: "text-left p-text-secondary",
        // },
        {
          field: "createBy",
          header: this.lang.createBy,
          type: "string",
          style: "width: 15%",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
        },
        {
          field: "netAmount",
          header: this.lang.amount,
          type: "currency",
          style:
            "width: 10%; border-radius: 0 12px 0 0;border-bottom-right-radius: 12px;",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-right p-text-secondary",
        },
      ],
      searchText: "",
      searchNumber: "",
      unitCode: "",
      unitAddress: "",
      price: 0,
      baseDialogClassTitle: "",
      titleConfirm: this.lang.notificationDialogHeader,
      showValidateDialog: false,
      validateWording: "",
      dataButtonDialog: [
        // {
        //   id: "close",
        //   caption: this.lang.cancel,
        //   class: "p-button-outlined p-button-secondary w-full",
        //   action: "closeDialog",
        // },
        {
          id: "confirm",
          caption: this.lang.confirm,
          class: "w-full",
          action: "confirm",
        },
      ],
      selectType: [
        { name: this.lang.date, code: "Date" },
        { name: this.lang.code, code: "Code" },
        { name: this.lang.unit, code: "Unit" },
        { name: this.lang.address, code: "Address" },
        { name: this.lang.customerName, code: "CustomerName" },
        { name: this.lang.price, code: "Price" },
      ],
      selectedType: "Date",

      items: [
        {
          label: this.lang.unlockDocument,
          icon: "pi pi-lock",
          command: () => {
            this.$toast.add({
              severity: "success",
              summary: "Updated",
              detail: "Data Updated",
              life: 3000,
            });
          },
        },
        {
          label: this.lang.referenceDocument,
          icon: "pi pi-external-link",
          command: () => {
            this.openReferenceDocument();
          },
        },
      ],
      showReferenceDocument: false,
      referenceDocumentData: [],
      showFormPrintModal: false,
      path: "",
      selectedDetail: [],
      categoryPrint: "",
      showInvoicePrintLotDialog: false,
      invoicePrintLot: [],
      openDialog: "",
      documentBookId: "",
      showConfirmDialog: false,
      confirmTitle: "",
      confirmContent: "",
      confirmClassTitle: "",
      storageName: "",

      showToast: 0,
      toastMessage: {},
      toastLink: "",
      actionToast: "",
      showUploadInvoiceDialog: false,

    };
  },
  mounted() {
    this.isLoading = true;
    this.initiateData();
    this.storageName = `erp_search_InvoiceList_${this.selectedProject.code}_${this.userCode}`;
    this.checkShouldSetData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    gotoEdit(id) {
      if (!this.isRoleSelect && id != "create") {
        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.confirm,
            class: "p-button-outlined p-button-secondary w-full",
            action: "confirm",
          },
        ];

        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleSelect;
        this.showValidateDialog = true;
        return true;
      } else if (!this.isRoleInsert && id == "create") {
        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.confirm,
            class: "p-button-outlined p-button-secondary w-full",
            action: "confirm",
          },
        ];

        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return true;
      }

      // this.$router.push(`/ar/invoice/${encodeURI(id)}`);
      this.$router.push({ name: "ar-invoice-edit", params: { id: id } })
    },
    initiateData() {
      let date = new Date();
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.code = "";
      this.unitCode = "";
      this.unitAddress = "";
      this.customerName = "";
      this.price = "";
      this.searchText = "";
      this.searchNumber = "";
      this.showUploadInvoiceDialog = false;
    },

    async onPageChange(event) {
      if(event.rows != this.rowsPerPage){
        this.selectedPage = 1;
      }else{
        this.selectedPage = event.page + 1;        
      }
      this.rowsPerPage = event.rows;
      await this.getInvoices();
    },
    async getInvoices() {
      try {
        if (this.validateData()) {
          return;
        }

        this.isLoading = true;

        if (this.selectedType === "Code") this.code = this.searchNumber;
        if (this.selectedType === "Unit") this.unitCode = this.searchText;
        if (this.selectedType === "Address") this.unitAddress = this.searchText;
        if (this.selectedType === "CustomerName") this.customerName = this.searchText;
        if (this.selectedType === "Price") this.price = this.searchNumber;

        let where = {
          projectId: this.selectedProject.id,
          dateStart: this.selectedDateStart,
          dateEnd: this.selectedDateEnd,
          code: this.code || null,
          unitCode: this.unitCode || null,
          unitAddressNo: this.unitAddress || null,
          customerName: this.customerName || null,
          totalAmount: this.price || null,
          IsPending: this.selectedIsPending,
        };
        const payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          where,
        };
        await this.$store.dispatch("arInvoice/getInvoices", payload);

        where = {
          ...where,
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
          selectedType: this.selectedType,
          searchText: this.searchText,
          searchNumber: this.searchNumber,
          rowsPerPage: this.rowsPerPage,
          selectedPage: this.selectedPage,
        };
        localStorage.setItem(this.storageName, JSON.stringify(where));
        this.isLoading = false;
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.isLoading = false;
      }
    },
    validateData() {
      if (this.selectedType === "Price") {
        let Price = this.formatCurrency(this.searchNumber.replaceAll(",",""));
        if (isNaN(parseFloat(Price))) {
          this.$toast.add({
            severity: "warn",
            summary: this.lang.notificationDialogHeader, //TitleHeader
            detail: this.lang.validateNumber,
            life: 5000, //TimeClose
          });
          return true;
        }
      }

      if (
        ((this.selectedType == "Unit" ||
          this.selectedType == "Address" ||
          this.selectedType == "CustomerName") &&
          this.searchText == "") ||
        ((this.selectedType == "Code" || this.selectedType == "Price") &&
          this.searchNumber == "")
      ) {
        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.confirm,
            class: "w-full",
            action: "confirm",
          },
        ];
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateSearchText;
        this.showValidateDialog = true;

        return true;
      }

      return false;
    },
    sendNotification() {
      this.$router.push({ name: "ar-invoice-notification" })
    },
    changeSelectedType() {
      this.initiateData();
    },
    confirmDialog() {
      this.showValidateDialog = false;
      this.baseDialogClassTitle = "";
    },
    async openReferenceDocument(id) {
      if (!this.isRoleSelect) {
        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "confirm",
          },
        ];

        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleSelect;
        this.showValidateDialog = true;
        return true;
      }
      this.setLoading(true);
      try {
        let where = {
          ProjectId: this.selectedProject.id,
          Id: id,
        };

        const payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          where,
        };
        await this.$store.dispatch("arInvoice/getReferenceDocument", payload);
        let dataReference = this.dataReferenceDocument;

        this.referenceDocumentData = dataReference;
        this.setLoading(false);
        this.showReferenceDocument = true;
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.isLoading = false;
      }
    },
    async tryPrintInvoice(data) {
      if (!this.isRolePrint) {
        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "confirm",
          },
        ];
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validatePrintPermission;
        this.showValidateDialog = true;
        return true;
      }

      if (data) {
        this.selectedDetail = data;
        await this.$store.dispatch("shared/resetFormPrint");
        let where = {
          projectId: this.selectedProject.id,
          documentBookCode: "BILL",
        };
        const payload = { where };
        await this.getFormPrints(payload);       
        // console.log("formPrint=", this.formPrint);
        if(this.filteredFormPrints.length == 1) {
          this.path = this.filteredFormPrints[0].path;
          this.printInvoice();
        }else{
          this.showFormPrintModal = true;
        }
      } else {
        this.openDialog = new Date().getSeconds().toString();
        let element = document.getElementsByTagName("body")[0];
        element.style.overflow = "hidden";
        this.showInvoicePrintLotDialog = true;        
      }

      this.invoicePrintLot = [];
    },
    async formPrintModalSelected(event) {
      this.path = event.path;
      this.categoryPrint = event.category;
      if (this.invoicePrintLot.length == 0) {
        await this.printInvoice();
      } else {
        this.invoicePrintLot.jasperPath = event.path + ".pdf";
        await this.requestInvoicePrintOrderChanges(this.invoicePrintLot);
      }
      this.showFormPrintModal = false;
    },
    async printInvoice() {
      this.setLoading(true);

      let parameter = {
        DocumentType: "BILL",
        CustomerType: "owner",
        InvoiceDateStart: "",
        InvoiceDateEnd: "",
        ProjectCode: this.selectedProject.code,
        OrderBy: "doc_code",
        InvoiceCodeStart: "",
        InvoiceCodeEnd: "",
        UnitCodeStart: "",
        UnitCodeEnd: "",
        CustomerGroup: "",
        IsIgnoreZero: "N",
        InvoiceId: this.selectedDetail.id,
        IsExcludeMultiple: "N",
        CustomerCode: "",
        IsIgnoreBillZero: "N",
        IsForApi: "true",
      };

      let payload = {
        DocumentType: "BILL",
        InvoiceId: this.selectedDetail.id,
        path: this.path + ".pdf",
        fileName: "Invoice_" + this.selectedDetail.code.trim(),
        parameter: JSON.stringify(parameter),
      };

      let blob = null;
      try {
        if (this.selectedDetail && this.selectedDetail.postStep == "P") {
          await this.$store.dispatch("shared/printForm", payload);
          blob = this.$store.getters["shared/blob"];
        } else {
          await this.$store.dispatch("arInvoice/PrintInvoice", payload);
          blob = this.$store.getters["arInvoice/blob"];
        }
        await this.getInvoices();
        // this.isPrinted = true;
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.isLoading = false;
      }

      this.getPreviewBlob(blob);
      this.setLoading(false);
    },
    unlockDocument(data) {
      this.selectedDetail = data;
      if (this.validateUnlock()) {
        return;
      }
      this.dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.cancel,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
        {
          id: "gotoUnlock",
          caption: this.lang.confirm,
          class: "w-full",
          action: "gotoUnlock",
        },
      ];
      this.titleConfirm = this.lang.unlockDocument;
      this.validateWording = this.lang.askForUnLockInvoice;
      this.showValidateDialog = true;
    },
    validateUnlock() {
      this.dataButtonDialog = [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];
      this.titleConfirm = this.lang.notificationDialogHeader;

      if (!this.isRoleUpdate) {
        this.validateWording = this.lang.validateRoleUpdate;
        this.showValidateDialog = true;
        return true;
      } 

      if (this.selectedDetail.paidStatus.trim() != "") {
        this.validateWording = this.lang.documentPaid;
        this.showValidateDialog = true;
        return true;
      }

      if (this.selectedDetail.postStep.trim() == "A") {
        this.validateWording = this.lang.validateReferenceVoucher;
        this.showValidateDialog = true;
        return true;
      }

      if (this.selectedDetail.cancelStatus.trim() == "C") {
        this.validateWording = this.lang.validateCancelled;
        this.showValidateDialog = true;
        return true;
      }

      return false;
    },
    async unlockInvoice() {
      // if (this.validateUnlock()) {
      //   return;
      // }

      this.showValidateDialog = false;
      try {
        this.isLoading = true;

        const payload = {
          id: this.selectedDetail.id,
        };
        await this.$store.dispatch("arInvoice/unlockInvoice", payload);
        await this.getInvoices();

        this.isLoading = false;

        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.confirm,
            class: "w-full",
            action: "confirm",
          },
        ];
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.unlockDocument;
        this.validateWording = this.lang.askForUnLockSuccess;
        this.showValidateDialog = true;
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.isLoading = false;
      }
    },
    searchTextEnter(event) {
      if (event.key == "Enter") {
        this.getInvoices();
      }
    },
    searchNumberEnter(event) {
      let keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const keyPressed = event.key;
      if (this.selectedType == "Price") {
        keysAllowed.push(".");
      }
      if (!keysAllowed.includes(keyPressed)) {
        event.preventDefault();
      }
      if (event.key == "Enter") {
        this.getInvoices();
      }
    },
    async confirmPrintLot(data) {
      if (data) {
        this.invoicePrintLot = data;
        let element = document.getElementsByTagName("body")[0];
        element.style.overflow = "scroll";
        // this.showInvoicePrintLotDialog = false;
        try {
          await this.$store.dispatch("shared/resetFormPrint");
          let where = {
            projectId: this.selectedProject.id,
            documentBookCode: "BILL",
          };
          const payload = { where };
          await this.getFormPrints(payload);        
          if(this.filteredFormPrints.length == 1) {
            this.invoicePrintLot.jasperPath = this.filteredFormPrints[0].path + ".pdf";
            await this.requestInvoicePrintOrderChanges(this.invoicePrintLot);
          }else{
            this.showFormPrintModal = true;
          }
        } catch (error) {
          this.$swal({
            icon: "error",
            html: `<p class="${this.textAlertClass}">${error}</p>`,
            confirmButtonText: this.lang.close,
            allowOutsideClick: false,
          });
        }
      }
    },
    closePrintLot() {
      let element = document.getElementsByTagName("body")[0];
      element.style.overflow = "scroll";
      this.showInvoicePrintLotDialog = false;
    },
    openAlertDialog(dataConfig) {
      if (dataConfig.severity === "") {
        this.confirmClassTitle = "";
      } else if (dataConfig.severity === "success") {
        this.confirmClassTitle = "p-text-success";
      } else if (dataConfig.severity === "error") {
        this.confirmClassTitle = "p-error";
      }
      this.confirmTitle = dataConfig.title;
      this.confirmContent = dataConfig.content;
      this.showConfirmDialog = true;
    },
    closeAlertDialog() {
      this.showConfirmDialog = false;
    },
    async requestInvoicePrintOrderChanges(data) {
      this.setLoading(true);
      try {
        // await this.$store.dispatch("produces/addInvoicePrintOrderChanges", data);
        const response = await this.invoicePrintOrderChanges(data);
        if (response) {
          this.toastMessage = {
            severity: "info",
            summary: this.lang.toastPrintLotTitle,
            detail: this.lang.toastPrintLotContent,
            life: 5000,
          };
          this.actionToast = `/${this.selectedProject.code}/report/request?back=${this.$route.fullPath}`;
          this.toastLink = this.lang.toastRequestLink;
        
          const today = new Date();
          this.showToast = today.getSeconds();
        }
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    // gotoRequestInvoicePrintOrderChanges() {
    //   this.$router.push({ name: "report-request", query: { back: this.$route.fullPath } })
    // },
    async checkShouldSetData() {
      if (this.isSharedRoleByBookReady) {
        this.documentBookId = this.roleByBook.documentBookId;
        await this.setLocalSearch();
        await this.getInvoices();
      }
    },
    setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      if (localSearch) {
        let search = JSON.parse(localSearch);
        if (search.invoiceEntry === "Entry") {
          this.selectedType = search.selectedType;
          this.searchText = search.searchText;
          this.searchNumber = search.searchNumber;

          if (search.dateStart != null && search.dateStart != "") {
            this.dateStart = new Date(search.dateStart);
          } else this.dateStart = "";

          if (search.dateEnd != null && search.dateEnd != "") {
            this.dateEnd = new Date(search.dateEnd);
          } else this.dateEnd = "";

          if (search.IsPending) {
            this.selectedIsPending = true;
          } else {
            this.selectedIsPending = false;
          }
          this.rowsPerPage = search.rowsPerPage;          
          this.selectedPage = search.selectedPage;
        }
      }
    },    
    importInvoice() {
      if (!this.isRoleInsert) {
        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "confirm",
          },
        ];

        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return true;
      }
      this.showUploadInvoiceDialog = true;
    },
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart && this.selectedType == "Date") {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd && this.selectedType == "Date") {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    invoices() {
      return this.$store.getters["arInvoice/invoices"];
    },
    totalPage() {
      return this.$store.getters["arInvoice/totalPage"];
    },
    currentPage() {
      return this.$store.getters["arInvoice/currentPage"];
    },
    dataReferenceDocument() {
      return this.$store.getters["arInvoice/dataReferenceDocument"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    selectedTypePlaceholder() {
      let Placeholder = this.lang.search;
      if (this.selectedType === "Code") Placeholder += this.lang.code;
      if (this.selectedType === "Unit") Placeholder += this.lang.unit;
      if (this.selectedType === "Address") Placeholder += this.lang.address;
      if (this.selectedType === "CustomerName") Placeholder += this.lang.customerName;
      if (this.selectedType === "Price") Placeholder += this.lang.price;
      return Placeholder;
    },
    dataButtons() {
      return [
        {
          id: "printInvoice",
          caption: this.lang.printInvoice,
          class: "mr-2",
          icon: "icon-printer",
          iconColor: "white",
          action: "gotoPrint",
          isShow: true,
        },
        {
          id: "createInvoice",
          caption: this.lang.createInvoice,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
        }
      ];
    },
    roleByBook() {
      return this.getRoleByBookByCode("BILL");
    },
    isRoleSelect() {
      if (this.roleByBook && this.roleByBook.select == "Y") {
        return true;
      }
      return false;
    },
    isRoleInsert() {
      if (this.roleByBook && this.roleByBook.insert == "Y") {
        return true;
      }
      return false;
    },
    isRoleUpdate() {
      if (this.roleByBook && this.roleByBook.update == "Y") {
        return true;
      }
      return false;
    },
    isRolePrint() {
      if (this.roleByBook && this.roleByBook.print == "Y") {
        return true;
      }
      return false;
    },
    printForms() {
      return this.$store.getters["shared/formPrintForms"];
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    showCurrentPage(){
      return this.rowsPerPage * (this.selectedPage - 1);
    }
  },
  watch: {
    isSharedRoleByBookReady() {
      this.checkShouldSetData();
    },
  },
};
</script>
