<template>
  <base-toolbar
    :title="lang.title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoCreate="showDialog(null)"
    :hideBackButton="isHideBack"
  >
  </base-toolbar>

  <base-container>
    <edit-form-version-dialog
      v-model:visible="isShowDialog"
			@close="isShowDialog = false"
      :data="data"
      :openFrom="openFrom"
    ></edit-form-version-dialog>
    
    <div class="surface-card shadow-2 p-5 border-round mt-5">
      <div class="flex justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.title }}</label>
      </div>

      <PvDivider></PvDivider>

      <div class="p-datatable-border my-5">
        <PvDataTable
          :value="formVersions"
          v-model:selection="selectedFormVersion"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-radius p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="showDialog(this.selectedFormVersion)"
        >
          <template #header>
            <div
              class="flex justify-content align-items-center"
              style="height: 72px"
            >
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <PvInputText
                  v-model="searchText"
                  :placeholder="lang.searchText"
                  autofocus
                />
              </span>
              <PvButton class="ml-2" @click="getFormVersions">
                <span class="font-normal">{{ lang.search }}</span>
              </PvButton>
            </div>
          </template>
          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
          <PvColumn
            field="projectCode"
            :header="lang.projectCode"
            style="width: 10%; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal"
          ></PvColumn>
          <PvColumn
            field="projectName"
            :header="lang.projectName"
            style="width: 20%; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal"
          ></PvColumn>
          <PvColumn
            field="menuCode"
            :header="lang.menuCode"
            style="width: 10%; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal"
          ></PvColumn>
          <PvColumn
            field="menuName"
            :header="lang.menuName"
            style="width: 12%; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal"
          ></PvColumn>
          <PvColumn
            field="isJasperReport"
            :header="lang.formVersion"
            style="width: 12%; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal"
          >
            <template #body="slotProps">
              {{ slotProps.data.isJasperReport == "Y" ? lang.jasperReport : lang.fastReport }}
            </template>
          </PvColumn>
          <PvColumn
            field="createBy"
            :header="lang.createBy"
            style="width: 11%; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal"
          ></PvColumn>
          <PvColumn
            field="createDate"
            :header="lang.createDate"
            style="width: 7%; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.createDate) }}
            </template>
          </PvColumn>
          <PvColumn
            field="updateBy"
            :header="lang.updateBy"
            style="width: 11%; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal"
          ></PvColumn>
          <PvColumn
            field="updateDate"
            :header="lang.updateDate"
            style="width: 7%; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal"
          >
            <template #body="slotProps">
              {{ slotProps.data.updateDate ? formatDate(slotProps.data.updateDate) : "" }}
            </template>
          </PvColumn>
          <PvColumn bodyClass="text-center">
						<template #body="slotProps">
							<PvButton
								type="button"
								class="p-button-secondary p-button-text p-0"
								@click="showDialog(slotProps.data)">
								<icon-pen-edit iconColor="secondary" width="24" height="24" />
							</PvButton>
						</template>
					</PvColumn>
          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              class="p-pagination-radius"
            >
              <template #start="slotProps">
                {{ lang.page }}:
                {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
              </template>
            </PvPaginator>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import EditFormVersionDialog from "../../../components/admin/formVersion/EditFormVersionDialog.vue";
export default {
  components: {
    EditFormVersionDialog
  },
  setup() {
    const isHideBack = inject("isHideBack");
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();

    const { formatDate, formatDateToISOString } = useFormat();

    const store = useStore();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.admin.formVersion,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.admin.formVersion,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatDate,
      formatDateToISOString,
      isHideBack,
    };
  },
  data() {
    return {
      isLoading: false,
      isShowDialog: false,
      selectedFormVersion: null,
      searchText: "",
      selectedPage: 1,
      rowsPerPage: 10,
      id: "",
      openFrom: "create",
      data: null,

      dataButtons: [
        {
          id: "createFormVersion",
          caption: this.lang.add,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
        },
      ],
    };
  },
  async created() {
    await this.getFormVersions();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
			this.$router.push({name: "home"})
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getFormVersions();
    },
    async getFormVersions() {
      this.isLoading = true;

      let where = {
        searchText: this.searchText,
      };

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };

      try {
        await this.$store.dispatch("formVersion/getFormVersions", payload);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 5000,
        });
      }

      this.isLoading = false;
    },
    showDialog(data) {
      // console.log(data);

      const today = new Date();

      if(data) {
        this.data = data
        this.openFrom = data.id.toString() + today.getSeconds().toString();
      }
      else {
        this.data = null;
        this.openFrom = "create" + today.getSeconds();
      }

      this.isShowDialog = true;
    },
  },
  computed: {
    formVersions() {
      return this.$store.getters["formVersion/formVersions"];
    },
    totalPage() {
      return this.$store.getters["formVersion/totalPage"];
    },
    currentPage() {
      return this.$store.getters["formVersion/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    formVersion() {
      return this.$store.getters["formVersion/selectedFormVersion"];
    },
  },
  watch: {
    formVersion() {
      this.isLoading = true;
      this.getFormVersions();
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.p-pagination-radius {
  border-radius: 0 0 12px 12px;
}
</style>
