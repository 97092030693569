<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '70vw' }"
    :modal="true"
    :closable="false"
    :header="parameterTitleText"
  >
    <transition>
      <section
        class="flex flex-wrap"
        :class="textFormClass"
        v-if="isParameterVisible"
      >
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateStart">{{ lang.dateStart }}</label>
          <PvCalendar
            id="dateStart"
            v-model="dateStart"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateEnd">{{ lang.dateEnd }}</label>
          <PvCalendar
            id="dateEnd"
            v-model="dateEnd"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="codeStart">{{ lang.codeStart }}</label>
          <PvInputText
            id="codeStart"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="6501xxxxx"
            v-model="codeStart"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="codeEnd">{{ lang.codeEnd }}</label>
          <PvInputText
            id="codeEnd"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="6501xxxxx"
            v-model="codeEnd"
          />
        </div>
        <!-- <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="customerSearch">{{ lang.customerSearch }}</label>
          <PvInputText
            id="customerSearch"
            type="text"
            class="inputfield w-full mt-1"
            v-model="customerSearch"
          />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="selectedUnit">{{ lang.searchUnit }}</label>
          <base-unit-auto-complete
            id="selectedUnit"
            v-model="selectedUnit"
            class="inputfield w-full mt-1"
          />
        </div> -->
      </section>
    </transition>

    <div class="pt-2 pb-1">
      <PvButton
        icon="pi pi-plus"
        :label="lang.importDocument"
        class="mr-2 mb-2"
        @click="AddReceiptToGl"
        v-if="!isAddReceipt"
      />
      <PvButton
        icon="pi pi-chevron-left"
        :label="lang.back"
        @click="goBack"
        class="p-button-secondary mr-2 mb-2"
        v-if="isHideBack"
      />
      <!-- <PvButton
        icon="pi pi-list"
        :label="parameterButtonText"
        @click="toggleParameterVisible"
        class="p-button-help mr-2 mb-2"
        v-if="isAddReceipt"
      /> -->
      <PvButton
        icon="pi pi-check"
        :label="lang.confirm"
        @click="getReceipt"
        class="mr-2 mb-2"
        v-if="isAddReceipt"
      />

      <PvButton
        icon="pi pi-minus"
        :label="lang.removeDocument"
        class="p-button-outlined p-button-danger mr-2 mb-2"
        @click="askForRemove"
        v-if="!isAddReceipt"
      />
    </div>

    <!-- ----------------------------list------------------------------------- -->
    <PvDataTable
      showGridlines
      :value="ReceiptSelectedItem"
      dataKey="id"
      :loading="isLoading"
      responsiveLayout="scroll"
      :scrollHeight="halfViewportHeight + 'px'"
      :stripedRows="true"
      class="p-datatable-sm"
      :class="textTableClass"
      v-if="!isAddReceipt"
    >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
      <PvColumn
        field="itemNumber"
        :header="lang.itemNo"
        style="min-width: 4rem"
        headerClass="h-center"
        bodyClass="text-center"
      ></PvColumn>
      <PvColumn
        field="code"
        :header="lang.columnCode"
        style="min-width: 4rem"
        headerClass="h-center"
        sortable
      ></PvColumn>
      <PvColumn
        field="refCode"
        :header="lang.codeReference"
        style="min-width: 4rem"
        headerClass="h-center"
        sortable
      ></PvColumn>
      <PvColumn
        field="date"
        :header="lang.columnDate"
        style="min-width: 3rem"
        headerClass="h-center"
        bodyClass="text-center"
        sortable
      >
        <template #body="slotProps">
          {{ formatDate(slotProps.data.date) }}
        </template>
      </PvColumn>

      <PvColumn
        field="unitCode"
        :header="lang.unitCode"
        style="min-width: 2rem"
        headerClass="h-center"
        sortable
      ></PvColumn>

      <PvColumn
        field="unitAddressNo"
        :header="lang.unitAddress"
        style="min-width: 2rem"
        headerClass="h-center"
        sortable
      ></PvColumn>

      <PvColumn
        field="customerName"
        :header="lang.customerName"
        style="min-width: 5rem"
        headerClass="h-center"
        sortable
      ></PvColumn>

      <PvColumn
        field="netAmount"
        :header="lang.columnTotal"
        style="min-width: 3rem"
        headerClass="h-center"
        bodyClass="text-right"
        sortable
      >
        <template #body="slotProps">
          {{ formatCurrency(slotProps.data.netAmount) }}
        </template>
      </PvColumn>

      <PvColumn
        field="payType"
        :header="lang.payType"
        style="min-width: 3rem"
        headerClass="h-center"
        sortable
      ></PvColumn>

      <PvColumn
        style="min-width: 4rem"
        headerClass="h-center"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <PvButton
            type="button"
            class="p-button-danger p-button-text py-0"
            icon="pi pi-times"
            @click="deleteRowReceiptSelected(slotProps.data.id)"
          ></PvButton>
        </template>
      </PvColumn>

      <!-- <template #footer>
        <PvPaginator
          :rows="rowsPerListPage"
          :totalRecords="totalListRecords"
          @page="onPageListChange($event)"
        >
          <template #start="slotProps">
            {{ lang.page }}: {{ totalListPage ? slotProps.state.page + 1 : 0 }} /
            {{ totalListPage }}
          </template>
        </PvPaginator>
      </template> -->
    </PvDataTable>

    <!-- ----------------------------list------------------------------------- -->

    <template #footer>
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog"
      />
      <PvButton
        :label="lang.autoGl"
        icon="pi pi-check"
        @click="ConfirmReceiptToGl"
        autofocus
        v-if="!isAddReceipt"
      />
      <!-- @click="selectedReceiptToGl" -->
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  props: ["openFrom", "voucherId", "autoReceiptToGl","date"],
  emits: ["close", "receipt-selected","auto-receipt-to-gl-success"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass, textAlertClass } =
      useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.accounting.edit,
          ...lang.th.gl.accounting.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.accounting.edit,
          ...lang.en.gl.accounting.listing,
        };
      }
    });
    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      textAlertClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
    };
  },
  data() {
    return {
      isAddReceipt: false,
      selectedPage: 1,
      rowsPerPage: 100,
      selectedListPage: 1,
      rowsPerListPage: 100,
      isParameterVisible: false,
      isLoading: true,
      selectedReceipts: null,
      dateStart: null,
      dateEnd: null,
      codeStart: "",
      codeEnd: "",
      customerSearch: "",
      selectedUnit: "",
      messageError: [],
    };
  },
  created() {
    let date = new Date();
    this.dateStart = new Date();
    this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    // console.log("this.$route.params.id=",this.$route.params.id);
    this.isLoading = false;
    const payload = [];
    this.$store.dispatch("glAccountingReceipt/setReceiptSelected", payload);
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    AddReceiptToGl() {
      this.isAddReceipt = true;
      this.isParameterVisible = true;
    },
    async removeReceiptToGl() {
      // console.log("removeInvoiceToGl")
      await this.$store.dispatch("glAccountingReceipt/resetReceipt");
      await this.$store.dispatch("glAccountingReceipt/resetReceiptSelected");
    },
    goBack() {
      this.isAddReceipt = false;
      this.isParameterVisible = false;
    },
    toggleParameterVisible() {
      this.isParameterVisible = !this.isParameterVisible;
    },
    closeDialog() {
      this.isAddReceipt = false;
      this.isParameterVisible = false;
      this.$emit("close");
    },
    onRowUnselect() {
      this.selectedReceipts = null;
    },

    async getReceiptSelected() {
      if (this.ReceiptSelected) {
        this.isAddReceipt = false;
        this.isParameterVisible = false;
      }
    },

    async getReceipt() {
      this.setLoading(true);
      try {
        await this.$store.dispatch("glAccountingReceipt/resetReceipt");
        let unitId = null;
        if (this.selectedUnit) {
          unitId = this.selectedUnit.id;
        }

        let voucherId = null;
        if (this.voucherId != "create") voucherId = this.voucherId;

        let where = {
          projectId: this.selectedProject.id,
          dateStart: this.selectedDateStart,
          dateEnd: this.selectedDateEnd,
          codeStart: this.codeStart || null,
          codeEnd: this.codeEnd || null,
          customerSearch: this.customerSearch || null,
          unitId: unitId,
          postType: "add",
          voucherId: voucherId,
        };

        const payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          where,
        };

        //   console.log("payload=",payload);
        await this.$store.dispatch(
          "glAccountingReceipt/getAllReceipts",
          payload
        );

        // console.log("getReceipts=",this.Receipts)
        this.SetReceiptSelected();
        //this.ReceiptSelectedMessageError();
        this.ReceiptSelected.sort((a, b) => a.code - b.code);
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        this.setLoading(false);
      }

      this.isAddReceipt = false;
      this.isParameterVisible = false;
      this.setLoading(false);
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getReceipt();
    },
    async SetReceiptSelected() {
      // await this.$store.dispatch(
      //   "glAccountingReceipt/setReceiptSelected",
      //   this.Receipts
      // );
      if (this.ReceiptSelected) {
        let ReceiptSelected = this.ReceiptSelected;
        const Receipts = this.Receipts;
        // console.log("ReceiptSelected=",ReceiptSelected);
        Receipts.forEach((Detail) => {
          //  console.log("Receipts_Detail",Detail);
          const data = ReceiptSelected.find((d) => d.id === Detail.id);
          if (!data) {
            //  Detail.remark4 = "new";
            ReceiptSelected.push(Detail);
          }
        });
      }

      // this.setLoading(false);
    },
    ReceiptSelectedMessageError() {
      this.messageError = [];

      this.ReceiptToGl.forEach((detail) => {
        //  console.log("detail_message",detail.error);
        if (detail.error != null && detail.error != "") {
          let message = { message: detail.error };
          this.messageError.push(message);
        }
      });
      // this.ReceiptSelected.forEach((Receipt) => {
      //   // console.log("ReceiptSelectedMessageError_Receipt",Receipt);
      //   if(Receipt.details != null){
      //     Receipt.details.forEach((detail) => {
      //       let message = { message: detail.error };
      //       this.messageError.push(message);
      //     });
      //   }
      // });
    },
    async onPageListChange(event) {
      this.selectedListPage = event.page + 1;
      this.rowsPerListPage = event.rows;
      await this.getReceiptSelected();
    },
    deleteRowReceiptSelected(id) {
      const index = this.ReceiptSelected.findIndex((detail) => detail.id == id);
      this.ReceiptSelected.splice(index, 1);
    },
    async ConfirmReceiptToGl() {
      this.$emit("close");
      this.setLoading(true);

      // if (this.ReceiptSelected.length <= 0) {
      //   this.setLoading(false);
      //   return;
      // }

      let details = [];
      let requestReceipt = { projectId: this.selectedProject.id };
      // console.log("ReceiptSelected=",this.ReceiptSelected);
      if (this.ReceiptSelected) {
        this.ReceiptSelected.forEach((Receipt) => {
          let detail = {
            headerId: Receipt.id,
            code: Receipt.code,
          };
          details.push(detail);
        });
      }

      let Receipts = { data: details };
      requestReceipt = { ...requestReceipt, ...Receipts };

      try {
        await this.$store.dispatch(
          "glAccountingReceipt/getReceiptToGl",
          requestReceipt
        );
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        this.setLoading(false);
      }

      this.ReceiptSelectedMessageError();
      // console.log("ReceiptSelectedMessage=",this.ReceiptSelectedMessage);
      if (this.isReceiptSelectedMessage) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${this.ReceiptMessageError}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      } else {
        this.AddReceiptSelectedToGl();
        this.isAddReceipt = false;
        this.isParameterVisible = false;
      }

      this.setLoading(false);
    },
    AddReceiptSelectedToGl() {
      // console.log("AddReceiptSelectedToGl=",this.ReceiptToGl);
      let details = [];
      let number = 1;
      this.ReceiptToGl.forEach((Detail) => {
        //  console.log("messageError=",Detail);
        let detail = {
          accountId: Detail.accountId,
          accountCode: Detail.accountCode,
          accountName: Detail.accountName,
          creditAmount: Detail.creditAmount,
          debitAmount: Detail.debitAmount,
          description: Detail.description,
          group: null,
          headerId: Detail.headerId,
          id: Detail.id,
          itemNumber: number,
          jobId: null,
          projectId: null,
          sectionId: null,
          payType: Detail.payType,
          code: Detail.code,
          itemOrder: Detail.itemOrder,
        };

        details.push(detail);
        number++;
      });
      //  console.log("receipt-selected=",details);
      this.$emit(
        "receipt-selected",
        // details.sort((a, b) => a.accountCode - b.accountCode)
        details.sort((a, b) => {
          if (a.itemOrder < b.itemOrder) return -1;
          if (a.itemOrder > b.itemOrder) return 1;

          if (a.accountCode < b.accountCode) return -1;
          if (a.accountCode > b.accountCode) return 1;
          return 0;
        })
      );
    },
    async askForRemove() {
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForRemoveDocument}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        await this.removeReceiptToGl();
      } else if (result.isDenied) {
        //
      }
    },
    async ReceiptToGlAuto(){      
     await this.removeReceiptToGl();
    //  await this.getReceipt();
    //  await this.ConfirmReceiptToGl();
      await this.getReceiptAuto();
      await this.ConfirmReceiptToGlAuto();
      await this.$emit("auto-receipt-to-gl-success",true);
    },
    async getReceiptAuto() {
      try {
        await this.$store.dispatch("glAccountingReceipt/resetReceipt");
        let unitId = null;
        if (this.selectedUnit) {
          unitId = this.selectedUnit.id;
        }

        let voucherId = null;
        if (this.voucherId != "create") voucherId = this.voucherId;

        let where = {
          projectId: this.selectedProject.id,
          dateStart: this.selectedDateStart,
          dateEnd: this.selectedDateEnd,
          codeStart: this.codeStart || null,
          codeEnd: this.codeEnd || null,
          customerSearch: this.customerSearch || null,
          unitId: unitId,
          postType: "add",
          voucherId: voucherId,
        };

        const payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          where,
        };

        //   console.log("payload=",payload);
        await this.$store.dispatch(
          "glAccountingReceipt/getAllReceipts",
          payload
        );

        // console.log("getReceipts=",this.Receipts)
        this.SetReceiptSelected();
        this.ReceiptSelected.sort((a, b) => a.code - b.code);
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }

    },
    async ConfirmReceiptToGlAuto() {   
      let details = [];
      let requestReceipt = { projectId: this.selectedProject.id };
      // console.log("ReceiptSelected=",this.ReceiptSelected);
      if (this.ReceiptSelected) {
        this.ReceiptSelected.forEach((Receipt) => {
          let detail = {
            headerId: Receipt.id,
            code: Receipt.code,
          };
          details.push(detail);
        });
      }

      let Receipts = { data: details };
      requestReceipt = { ...requestReceipt, ...Receipts };

      try {
        await this.$store.dispatch(
          "glAccountingReceipt/getReceiptToGl",
          requestReceipt
        );
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }

      await this.ReceiptSelectedMessageError();
      // console.log("ReceiptSelectedMessage=",this.ReceiptSelectedMessage);
      if (this.isReceiptSelectedMessage) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${this.ReceiptMessageError}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      } else {
        await this.AddReceiptSelectedToGl();
        this.isAddReceipt = false;
        this.isParameterVisible = false;
      }       
      this.$emit("close");
    },
    
  },
  computed: {
    parameterTitleText() {
      return this.isAddReceipt
        ? this.lang.receiptTitle
        : this.lang.listReceiptTitle;
    },
    parameterButtonText() {
      return this.isParameterVisible
        ? this.lang.hideParameters
        : this.lang.showParameters;
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    Receipts() {
      let receipts = this.$store.getters["glAccountingReceipt/receipts"];
      return receipts.sort((a, b) => a.date - b.date);
    },
    ReceiptSelected() {
      return this.$store.getters["glAccountingReceipt/receiptSelected"];
    },

    totalPage() {
      return this.$store.getters["glAccountingReceipt/totalPage"];
    },

    ReceiptToGl() {
      return this.$store.getters["glAccountingReceipt/receiptToGl"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    sortedMessageError() {
      // console.log("sortedMessageError00000");
      const cats = this.messageError.reduce((p, c) => {
        p[c.message] = c;
        return p;
      }, {});
      return Object.values(cats).sort((a, b) =>
        a.message.localeCompare(b.message)
      );
    },
    isReceiptSelectedMessage() {
      let Error = false;
      if (this.messageError.length > 0) Error = true;
      return Error;
    },
    ReceiptMessageError() {
      let messages = "";
      this.sortedMessageError.forEach((message) => {
        messages += message.message + "</br>";
      });
      return messages;
    },
    // sortedReceiptSelected(){
    //   return this.ReceiptSelected.sort((a, b) => a.code - b.code );
    // },
    ReceiptSelectedItem() {
      let itemNumber = [];
      let i = 1;
      if (this.ReceiptSelected) {
        this.ReceiptSelected.forEach((element) => {
          element = { ...element, ...{ itemNumber: i } };
          itemNumber.push(element);
          i++;
        });
      }
      return itemNumber;
    },
    isHideBack() {
      if (this.ReceiptSelected.length > 0 && this.isAddReceipt) return true;
      else return false;
    },
  },
  watch: {
    openFrom(curValue) {
      // console.log("openFrom1=",curValue);
      const Check = curValue.indexOf("create");
      //  console.log("openFrom2=",Check);
      if (Check < 0) {
        this.getReceiptSelected();
      } else if (Check >= 0) {
        // console.log("ReceiptSelected=",this.ReceiptSelected.length)
        if (this.ReceiptSelected.length <= 0) {
          this.isAddReceipt = true;
          this.isParameterVisible = true;
        }
      }      
      let date = new Date();
      this.dateStart = new Date();
      this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    },
    autoReceiptToGl(curValue){
      const Check = curValue.indexOf("auto");
     if(Check >= 0){
      this.dateStart = this.date;
      this.dateEnd = this.date;
      this.ReceiptToGlAuto();      
     }
    }
  },
};
</script>

<style scoped>
.swal2-popup {
  width: 40em;
}

.p-dialog-footer {
  padding: 1rem 1rem 1rem 1rem;
}
</style>
