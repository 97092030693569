export default {
    editDebtFreeFollowUpTitle: "รายละเอียดติดตามสถานะปลอดหนี้",
    debtFreeFollowUp: "ติดตามสถานะปลอดหนี้",
    unitCode: "ยูนิต",
    unitAddressNo: "บ้านเลขที่",
    ownerName: "ชื่อเจ้าของ",
    ownerPhone: "เบอร์โทรศัพท์",
    debtFreeCode: "เลขที่ปลอดหนี้",
    debtFreeDate: "วันที่ปลอดหนี้",
    newOwner: "เจ้าของใหม่*",
    transferDate: "วันที่โอนฯ*",
    validateNewOwner: "กรุณาเลือกเจ้าของใหม่",
    validateTransferDate: "กรุณาระบุวันที่โอนฯ",
    validateStatus: "กรุณาเลือกสถานะ",
    followUpSuccess: "ติดตามสถานะปลอดหนี้ เลขที่ : :code สำเร็จ",
    customerReference: " (อ้างอิงจากข้อมูลสมาชิกในระบบ)",
}