<template>
    <PvDialog
      :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
      :closable="false"
    >
      <template #header>
        <div class="flex justify-content-between align-items-center">
          <div class="pt-2 pb-1">
            <label class="font-lg font-normal">
              {{ this.title }}
            </label>
          </div>
          <PvButton
            icon="pi pi-times"
            class="p-button-link p-icon-secondary"
            @click="closeDialog"
          />
        </div>
        <PvDivider></PvDivider>
      </template>
      <transition>
        <section class="flex flex-wrap" :class="textFormClass">
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
            <label for="unitStart">{{ lang.unitStart }}</label>
            <base-unit-auto-complete
              id="unit"
              v-model="selectedUnitStart"
              class="inputfield w-full mt-1"
              @load-finish="unitDataReady"
              :class="{ 'p-invalid': unitStartError }"
              @item-select="unitStartError = false"
            />
            <small v-if="unitStartError" id="unit-help" class="p-error">{{
              lang.validateNoUnitSelect
            }}</small>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
            <label for="unitEnd">{{ lang.to }}</label>
            <base-unit-auto-complete
              id="unit"
              v-model="selectedUnitEnd"
              class="inputfield w-full mt-1"
              @load-finish="unitDataReady"
              :class="{ 'p-invalid': unitEndError }"
              @item-select="unitEndError = false"
            />
            <small v-if="unitEndError" id="unit-help" class="p-error">{{
              lang.validateNoUnitSelect
            }}</small>
          </div>
  
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
            <label for="productStart">{{ lang.productStart }}</label>
            <base-product-prepaid-auto-complete
              id="productPrepaid"
              v-model="selectedProductStart"
              class="inputfield w-full mt-1"
              @load-finish="productPrepaidDataReady"
              :class="{ 'p-invalid': productStartError }"
              @item-select="productStartError = false"
            />
            <small v-if="productStartError" id="unit-help" class="p-error">{{
              lang.validateNoProductSelect
            }}</small>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
            <label for="productEnd">{{ lang.to }}</label>
            <base-product-prepaid-auto-complete
              id="productPrepaid"
              v-model="selectedProductEnd"
              class="inputfield w-full mt-1"
              @load-finish="productPrepaidDataReady"
              :class="{ 'p-invalid': productEndError }"
              @item-select="productEndError = false"
            />
            <small v-if="productEndError" id="unit-help" class="p-error">{{
              lang.validateNoProductSelect
            }}</small>
          </div>
  
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
            <label for="dateAt">{{ lang.receiveDateAt }}</label>
            <PvCalendar
              id="dateAt"
              v-model="dateAt"
              class="inputfield w-full mt-1"
              showIcon
              dateFormat="dd/mm/yy"
              :class="{ 'p-invalid': dateAtError }"
              @date-select="dateAtError = false"
            />
            <small v-if="dateAtError" id="unit-help" class="p-error">{{
              lang.validateDateSelect
            }}</small>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
            <label for="fileNameText">{{ lang.fileName }}</label>
            <PvInputText
              id="fileNameText"
              type="text"
              class="inputfield w-full mt-1"
              v-model="fileNameText"
            />
          </div>
        </section>
      </transition>
      <template #footer>
        <div
          class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
        >
          <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
            <PvButton
              icon="pi pi-file-pdf"
              :label="lang.downloadPDF"
              @click="download('PDF')"
              class="w-full"
            />
          </div>
          <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
            <PvButton
              icon="pi pi-file-excel"
              :label="lang.downloadExcel"
              @click="download('JASPEREXCEL')"
              class="w-full"
            />
          </div>
          <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
            <PvButton
              icon="pi pi-file-excel"
              :label="lang.downloadExcelRaw"
              @click="download('EXCEL')"
              class="w-full"
            />
          </div>
        </div>
      </template>
    </PvDialog>
  </template>
  
  <script>
  import useLang from "../../../hooks/lang.js";
  import useTextStyle from "../../../hooks/textStyle.js";
  import useFormat from "../../../hooks/format.js";
  import { useStore } from "vuex";
  import { computed } from "vue";
  import useGetFormPrint from "../../../hooks/getFormPrint.js";
  import useTracking from "../../../hooks/tracking";
  import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
  export default {
    props: {
      reportObj: {
        type: Object,
        default: null,
      },
    },
    emits: ["close", "toast-request"],
    setup() {
      const { lang } = useLang();
      const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
      const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
      const { getPreviewBlob, getDownloadBlob } = useGetFormPrint();
      const { addReportTracking } = useTracking();
      const { reportPrintOrder } = useReportRequestValidate();
      const store = useStore();
      const mergeLang = computed(function () {
        if (store.getters.selectedLanguage === "TH") {
          return {
            ...lang.th.base,
            ...lang.th.gl.reportGl,
          };
        } else {
          return {
            ...lang.en.base,
            ...lang.en.gl.reportGl,
          };
        }
      });
  
      return {
        lang: mergeLang,
        textHeaderClass,
        textFormClass,
        textTableClass,
        formatCurrency,
        formatDate,
        formatDateToISOString,
        getPreviewBlob,
        getDownloadBlob,
        addReportTracking,
        reportPrintOrder,
      };
    },
    data() {
      return {
        dateAt: null,
        dateAtError: false,
        unitStartError: false,
        unitEndError: false,
        selectedUnitStart: null,
        selectedUnitEnd: null,
        isSharedUnitReady: false,
        selectedProductStart: null,
        selectedProductEnd: null,
        productStartError: false,
        productEndError: false,
        isSharedProductPrepaidReady: false,

        type: "PDF",
        fileNameText: "prepaid_summary",
      };
    },
    async mounted() {
      this.setLoading(true);
      await this.initiateData();
    },
    methods: {
      setLoading(bool) {
        this.$store.dispatch("setLoading", { value: bool });
      },
      closeDialog() {
        this.$emit("close");
        this.initiateData();
      },
      async initiateData() {
        this.dateAt = new Date();
        this.dateAtError = false;
        this.selectedUnitStart = null;
        this.selectedUnitEnd = null;
        this.selectedProductStart = null;
        this.selectedProductEnd = null;
        this.unitStartError = false;
        this.unitEndError = false;
        this.productStartError = false;
        this.productEndError = false;
        this.isSharedProductPrepaidReady = false;
        this.isSharedUnitReady = false;
        this.type = "PDF";
        this.fileNameText = "prepaid_summary";
      },
      validateData() {
        if (this.selectedDateAt == null) {
          this.dateAtError = true;
          return true;
        }
        // if (this.selectedUnitStart == null) {
        //   this.unitStartError = true;
        //   return true;
        // }
        // if (this.selectedUnitEnd == null) {
        //   this.unitEndError = true;
        //   return true;
        // }
        // if (this.selectedProductStart == null) {
        //   this.productStartError = true;
        //   return true;
        // }
        // if (this.selectedProductEnd == null) {
        //   this.productEndError = true;
        //   return true;
        // }
        return false;
      },
      unitDataReady() {
        this.isSharedUnitReady = true;
        this.checkShouldSetData();
      },
      productPrepaidDataReady() {
        this.isSharedProductPrepaidReady = true;
        this.checkShouldSetData();
      },
      checkShouldSetData() {
        if (this.isSharedProductPrepaidReady && this.isSharedUnitReady) {
          this.setData();
        }
        // this.setLoading(false);
      },
      setData() {
        // this.setLoading(true);
        this.selectedUnitStart = this.units[0];
        this.selectedUnitEnd = this.units[this.units.length - 1];
        this.selectedProductStart = this.prepaidProducts[0];
        this.selectedProductEnd = this.prepaidProducts[this.prepaidProducts.length - 1];
        this.setLoading(false);
      },
      async download(typeReport) {
        this.type = typeReport;
        if (this.validateData()) {
          return;
        }

        this.setLoading(true);
        try {
          this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
          const response = await this.reportPrintOrder(this.payload);
          if (response) {
            this.$emit("toast-request");
          }
          this.setLoading(false);
        } catch (error) {
          this.$toast.add({
            severity: "error",
            summary: this.lang.notificationDialogHeader,
            detail: error,
            life: 3000,
          });
          this.setLoading(false);
        }
      },
    },
    computed: {
      selectedProject() {
        return this.$store.getters.selectedProject;
      },
      title() {
        if (this.$store.getters.selectedLanguage === "TH")
          return this.reportObj.nameThai;
        else return this.reportObj.nameEnglish;
      },
      selectedDateAt() {
        let dateAt = null;
        if (this.dateAt) {
          dateAt = this.formatDateToISOString(this.dateAt);
        }
        return dateAt;
      },
      fileBlob() {
        return this.$store.getters["shared/blob"];
      },
      prepaidProducts() {
        let itemList = [];
        let productArs = this.$store.getters["shared/productArProducts"];
        if (productArs) {
          productArs.forEach((element) => {
            //   console.log("productArs", element);
            if (element.code.substr(0, 1) == "D") {
              element = {
                ...element,
                ...{ codeAndName: element.code + " : " + element.nameThai },
              };
              itemList.push(element);
            }
          });
        }
        return itemList;
      },
      units() {
        return this.$store.getters["shared/unitUnits"];
      },
      userId() {
        return this.$store.getters.userId;
      },
      userName() {
        return this.$store.getters.userName;
      },
      fileType() {
        let fileType =
          this.type == "EXCEL"
            ? "_excel.xlsx"
            : (this.type == "JASPEREXCEL")
            ? ".xlsx"
            : ".pdf";
        return fileType;
      },
      fileName() {
        let fileName = this.type == "PDF" ? ".pdf" : ".xlsx";
        return this.fileNameText + fileName;
      },
      payload() {
        let payload = {
          ProjectId: this.selectedProject.id,
          DataAtDate: this.selectedDateAt,
          UnitCodeStart: this.selectedUnitStart
            ? this.selectedUnitStart.code
            : this.units[0].code,
          UnitCodeEnd: this.selectedUnitEnd ? this.selectedUnitEnd.code : this.units[this.units.length - 1].code,
          ProductCodeStart: this.selectedProductStart
            ? this.selectedProductStart.code
            : this.prepaidProducts[0].code,
          ProductCodeEnd: this.selectedProductEnd
            ? this.selectedProductEnd.code
            : this.prepaidProducts[this.prepaidProducts.length - 1].code,
          FileName: this.fileName,
          JasperPath: this.reportObj.path + this.fileType,
          DocType: this.type,
          UserId: this.userId,
          PrintBy: this.userName,
          RequestId: 0,
          ReportName: "PrepaidSummary",
        };
        return payload;
      }
    },
    watch: {
      // isSharedProductPrepaidReady() {
      //   this.checkShouldSetData();
      // },
      // isSharedUnitReady() {
      //   this.checkShouldSetData();
      // },
      reportObj() {
        this.initiateData();
      },
    },
  };
  </script>
  