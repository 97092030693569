export default {
	title: "Notice",
    printLot: "Print Lot",
    selectDate: "Date",
    selectMonth: "Month",
    selectCode: "Code",
    selectUnit: "Unit",
    selectAddress: "Address",
    selectCustomerName: "CustomerName",
    selectAll: "All",
	
    columnDate: "Date",
    columnCode: "Code",
    columnUnit: "Unit",
    columnAddress:"Address์น",
    columnCustomerName: "CustomerName",
    columnPostStep:"Print",
    columnIsPaid:"Paid",
    columnIsCancel:"Cancel",  
    columnTotalAmount: "Amount",
    columnCreateBy: "CreateBy",
    columnCreateDate: "CreateDate",
    columnUpdateBy: "UpdateBy",
    columnUpdateDate: "UpdateDate",

    printed: "Printed",
    fullPayment: "Full Payment",
    splitRefund: "Split Refund",
    codeStart: "Code Start",
    codeEnd: "Code End",
    unitStart: "Unit Start",
    unitEnd: "Unit End",
    isIgnoreZero: "Print IgnoreZero",
    isGroup: "Print Group",
	no: "No",
	labelSearchBy: "Search by.",

};
