<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.0038 8.08721V19.0028C21.0038 20.1078 20.108 21.0036 19.003 21.0036H4.99717C3.89215 21.0036 2.99634 20.1078 2.99634 19.0028V8.08721C2.99596 7.81591 3.05076 7.54735 3.15741 7.29789L4.47796 4.2076C4.7937 3.47214 5.51736 2.99561 6.31773 2.99609H17.6825C18.4835 2.99609 19.2074 3.47397 19.5222 4.2106L20.8428 7.29789C20.9493 7.5474 21.0041 7.81592 21.0038 8.08721Z"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.99634 7.99826H20.9938"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.0009 2.99609L15.0013 7.99818V11.2245C14.9765 11.6756 14.5921 12.022 14.1409 11.9998H9.85914C9.40793 12.022 9.02355 11.6756 8.99878 11.2245V7.99818L9.9992 2.99609"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.99756 18.0022H7.99839"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
