export default {
	accountingEntries(state) {
		return state.accountingEntries;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	selectedAccountingEntry(state) {
		return state.selectedAccountingEntry;
	},
	blob(state) {
		return state.blob;
	},
	accountingEntriesIsLastPage(_, getters) {
		if (getters.currentPage > 0 && getters.totalPage === 0) {
			return true;
		} else {
			return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
		}
	},
	accountingEntriesNextPage(state) {
		// console.log("accountingEntriesNextPage", state.currentPage);
		return state.currentPage + 1;
	},
};
