<template>
  <PvDialog
    :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
    :style="{ width: '40vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div
        class="flex justify-content-between align-items-center flex flex-wrap"
      >
        <div>
          <span class="font-lg">{{ lang.accountingPolicyItem }}</span>
          <span class="font-xs p-icon-disabled">{{
            " *" + lang.accountingPolicyItemRemark
          }}</span>
        </div>
      </div>
      <PvDivider></PvDivider>
    </template>
    <section class="flex flex-wrap" :class="textFormClass">
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
        <label for="linkFunction">{{ lang.columnLinkFunction }}</label>
        <PvSkeleton
          v-if="!isLinkFunctionReady"
          class="inputfield w-full mt-1"
          height="39px"
        />
        <accounting-policy-pettern-auto-complete
          id="selectedLinkFunction"
          v-model="selectedLinkFunction"
          class="inputfield w-full mt-1"
          @load-finish="linkFunctionReady"
          @item-select="isLinkFunctionError = false"
          :class="{ 'p-invalid': isLinkFunctionError }"
          :placeholder="lang.validateNoSelectData"
          v-show="isLinkFunctionReady"
        />
        <small v-if="isLinkFunctionError" id="supplier-help" class="p-error">{{
          lang.validateNoLinkFunctionSelect
        }}</small>
      </div>
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
        <label for="debitCredit">{{ lang.columnDebitCredit }}</label>
        <PvDropdown
          class="inputfield w-full mt-1"
          :options="debitCredit"
          optionLabel="name"
          optionValue="code"
          v-model="selectedDebitCredit"
          @change="isDebitCreditError = false"
          :placeholder="lang.validateNoSelectData"
          :class="{ 'p-invalid': isDebitCreditError }"
        />
        <small v-if="isDebitCreditError" id="supplier-help" class="p-error">{{
          lang.validateNoDebitCreditSelect
        }}</small>
      </div>
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
        <label for="accountCodeAndName">{{
          lang.columnAccountCodeAndName
        }}</label>
        <PvSkeleton
          v-if="!isAccountReady"
          class="inputfield w-full mt-1"
          height="39px"
        />
        <base-accounting-detail-auto-complete
          id="selectedAccount"
          v-model="selectedAccount"
          class="inputfield w-full mt-1"
          @load-finish="accountDataReady"
          @item-select="isAccountError = false"
          :placeholder="lang.validateNoSelectData"
          :class="{ 'p-invalid': isAccountError }"
          :isLoadingData="true"
          v-show="isAccountReady"
          @is-inactive="selectedAccount = null"
        />
        <small v-if="isAccountError" id="supplier-help" class="p-error">{{
          lang.validateNoAccountCodeAndNameSelect
        }}</small>
      </div>
    </section>

    <template #footer>
      <PvButton
        :label="lang.close"
        class="p-button-outlined p-button-secondary w-2 ml-3"
        @click="closeDialog"
      />
      <PvButton
        :label="lang.confirm"
        class="w-full mr-3 w-2"
        @click="selectItem"
      />
    </template>
  </PvDialog>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import AccountingPolicyPetternAutoComplete from "../../../components/master/accountingPolicy/AccountingPolicyPetternAutoComplete.vue";
export default {
  components: {
    AccountingPolicyPetternAutoComplete,
  },
  props: {
    selectedAccountingPolicy: {
      type: Object,
    },
    openFrom: {
      type: String,
    },
  },
  emits: ["close", "accounting-policy-item"],
  setup() {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.accountingPolicy,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.th.master.accountingPolicy,
        };
      }
    });

    return {
      lang: mergeLang,
      textFormClass,
    };
  },
  data() {
    return {
      selectedLinkFunction: null,
      isLinkFunctionError: false,
      isLinkFunctionReady: false,

      debitCredit: [
        { name: this.lang.debit, code: "D" },
        { name: this.lang.credit, code: "C" },
      ],
      selectedDebitCredit: null,
      isDebitCreditError: false,

      selectedAccount: null,
      isAccountError: false,
      isAccountReady: false,
    };
  },  
  mounted() {
    this.initiateData();
  },
  methods: {
    closeDialog() {
      this.initiateData();
      this.$emit("close");
    },
    initiateData(){
      this.selectedLinkFunction = null;
      this.isLinkFunctionError= false;
      
      this.selectedDebitCredit = null;
      this.isDebitCreditError= false;
      
      this.selectedAccount = null;
      this.isAccountError= false;
    },
    async setData(){
      this.selectedLinkFunction = this.accountingPolicPetterns.find(
        (e) => e.linkFunction === this.selectedAccountingPolicy.linkFunction
      );
      this.selectedDebitCredit = this.selectedAccountingPolicy.debitCredit;      
      this.selectedAccount = this.sharedAccounts.find(
        (account) => account.id === this.selectedAccountingPolicy.accountId
      );
    },
    checkShouldSetData(){
      if(this.selectedAccountingPolicy && this.isLinkFunctionReady && this.isAccountReady ){
        this.setData();
      }
    },
    selectItem() {
      if (!this.selectedLinkFunction) {
        this.isLinkFunctionError = true;
      }

      if (!this.selectedDebitCredit) {
        this.isDebitCreditError = true;
      }

      if (!this.selectedAccount) {
        this.isAccountError = true;
      }
      if(this.isLinkFunctionError || this.isDebitCreditError || this.isAccountError){
        return true;
      }
      let id = null;
      let item = 0;
      if(this.selectedAccountingPolicy){
        id = this.selectedAccountingPolicy.id;
        item = this.selectedAccountingPolicy.item;
      } 

      let payload = {
            id : id,
            item: item,
            linkFunction: this.selectedLinkFunction.linkFunction,
            description: this.selectedLinkFunction.description,
            nameTh: this.selectedLinkFunction.nameTh,
            nameEn: this.selectedLinkFunction.nameEn,
            accountId: this.selectedAccount.id,
            accountCode: this.selectedAccount.code.trim(),
            accountName: this.selectedAccount.name,
            debitCredit: this.selectedDebitCredit,
        }

      // console.log("selectItem=",payload);
      this.$emit("accounting-policy-item",payload);
      this.closeDialog();
    },
    linkFunctionReady() {
      this.isLinkFunctionReady = true;
    },
    accountDataReady() {
      this.isAccountReady = true;
    },
  },
  computed: {
    accountingPolicPetterns() {
      return this.$store.getters["accountingPolicy/petterns"];
    }, 
    sharedAccounts() {
      return this.$store.getters["shared/accountingDetailAccounts"];
    },
  },
  watch: {
    openFrom() {
      this.checkShouldSetData();
    },
    isLinkFunctionReady() {
      this.checkShouldSetData();
    },
    isAccountReady() {
      this.checkShouldSetData();
    },

  }
};
</script>

<style></style>
