import { useStore } from "vuex";
import { ref, computed} from "vue";
export default function useGetAccounting() {
  const store = useStore();
  let searchTextAccounting = ref(null);

  const accountings = computed(() => {
    return store.getters["shared/accountingAccounts"];
  });

  const accountingIsLastPage = computed(() => {
    return store.getters["shared/accountingIsLastPage"];
  });

  const filteredAccountings = computed(() => {
    if (!searchTextAccounting.value) {
      //ช่องว่าง
      const result = [...accountings.value];
      return result;
    } else {
      return accountings.value.filter((accounting) => {
        return accounting.searchText
          .toLowerCase()
          .includes(searchTextAccounting.value.toLowerCase());
      });
    }
  });

  const searchAccounting = function (event) {
    // console.log(event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchTextAccounting.value = "";
      searchTextAccounting.value = null;
    } else {
      searchTextAccounting.value = event.query;
    }
  };

  let isSharedAccountingReady = ref(false);

  const getAllAccountings = async function () {
    if (!accountingIsLastPage.value) {
      await store.dispatch("shared/getAllAccountings");
    }
    isSharedAccountingReady.value = true;
  };

  const getAccountingByCode = function (code) {
    return accountings.value.find((accounting) => {
      return accounting.code.trim().toLowerCase() === code.trim().toLowerCase();
    });
  };

  // const setAccounting = async function (payload) {
  //   isSharedAccountingReady.value = true;
  //   if(payload === "Y" || payload === "N") {
  //     const where = {
  //       isDetail : payload
  //     };
  //     await store.dispatch("shared/getDetailAccountings",where);
  //   } else {
  //     await getAllAccountings();
  //   }
  //   isSharedAccountingReady.value = false;
  // };

  // onMounted(async () => {
  //   await getAllAccountings();
  // });

  return {
    searchAccounting,
    filteredAccountings,
    searchTextAccounting,
    getAccountingByCode,
    isSharedAccountingReady,
    // setAccounting,
    accountings,
    getAllAccountings
  };
}
