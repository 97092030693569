<template>
  <base-toolbar
    :title="lang.title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoCreate="gotoEdit('create')"
    :hideBackButton="isHideBack"
  ></base-toolbar>

  <base-dialog
    v-model:visible="showValidateDialog"
    :titleConfirm="lang.notificationDialogHeader"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    @confirm="showValidateDialog = false"
    @close="showValidateDialog = false"
  ></base-dialog>

  <base-form-print-modal
    v-model:visible="showFormPrintModal"
    :search="searchTextPrint"
    @close="showFormPrintModal = false"
    @path-selected="formPrintModalSelected"
  ></base-form-print-modal>

  <base-reference-document-dialog
    v-model:visible="showReferenceDocument"
    @close="showReferenceDocument = false"
    :dataTable="referenceDocumentData"
    :columns="columnsReferenceDocument"
  ></base-reference-document-dialog>

  <base-container>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.title }}</label>
      </div>

      <PvDivider></PvDivider>

      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >
        <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            id="filterSearch"
            class="inputfield w-full mr-2"
            :options="filterSearch"
            optionLabel="name"
            optionValue="code"
            v-model="selectedFilterSearch"
            scrollHeight="300px"
            @change="changeFilterSearch"
          />
        </div>
        <div
          class="flex lg:col-6 md:col-12 col-12 pl-0 pr-2 mr-2"
          v-if="isSearchDate"
        >
          <div class="col-6 pl-0 pr-2">
            <PvCalendar
              id="dateStart"
              v-model="dateStart"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
          <label for="dateEnd" class="pt-1 mt-3 mr-2">{{ "-" }}</label>
          <div class="col-6 pl-0 pr-2">
            <PvCalendar
              id="dateEnd"
              v-model="dateEnd"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
        </div>

        <div
          class="flex lg:col-6 md:col-12 col-12 pl-0 pr-0 mr-2"
          v-if="!isSearchDate"
        >
          <div class="col-12 pl-0 pr-0">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchText"
                :placeholder="lang.searchTextPlaceholder"
                @keypress="checkInputSearch"
                autofocus
              />
            </span>
          </div>
        </div>

        <PvButton @click="getCreditNotes">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="creditNotes"
          v-model:selection="selectedCreditNote"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(this.selectedCreditNote.id)"
          scrollable
        >
          <PvColumn
            field="code"
            :header="lang.documentCode"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <u>{{ slotProps.data.code }}</u>
            </template>
          </PvColumn>

          <PvColumn
            field="date"
            :header="lang.documentDate"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.date) }}
            </template>
          </PvColumn>

          <PvColumn
            field="unitCode"
            :header="lang.unit"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />

          <PvColumn
            field="addressNo"
            :header="lang.address"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />

          <PvColumn
            field="customerName"
            :header="lang.customerName"
            style="min-width: 15rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />

          <PvColumn
            field="netAmount"
            :header="lang.columnPrice"
            style="min-width: 10rem; height: 56px"
            headerClass="h-right"
            sortable
          >
            <template #body="slotProps">
              <span class="text-right w-full">{{
                formatCurrency(slotProps.data.netAmount)
              }}</span>
            </template>
          </PvColumn>

          <PvColumn
            field="cancelStatus"
            :header="lang.cancel"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="{ data, field }">
              <base-tag
                v-if="data[field].trim() != ''"
                :Caption="lang.cancel"
                Class="error"
              />
            </template>
          </PvColumn>

          <PvColumn
            field="postStep"
            :header="lang.print"
            style="min-width: 11rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="{ data, field }">
              <base-tag
                v-if="data[field].trim() != ''"
                :Caption="postStep(data[field]).caption"
                Class="primary"
              />
            </template>
          </PvColumn>

          <PvColumn
            field="createDate"
            :header="lang.columnCreateDate"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.createDate) }}
            </template>
          </PvColumn>
          <PvColumn
            field="updateDate"
            :header="lang.columnUpdateDate"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <label v-if="slotProps.data.updateDate">
                {{ formatDate(slotProps.data.updateDate) }}
              </label>
            </template>
          </PvColumn>

          <PvColumn
            field="createBy"
            :header="lang.columnCreateBy"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />

          <PvColumn
            field="updateBy"
            :header="lang.columnUpdateBy"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />

          <PvColumn
            style="min-width: 12rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                @click="getPrintForm(slotProps.data)"
              >
                <icon-printer iconColor="secondary" width="24" height="24" />
              </PvButton>
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                @click="openReferenceDocument(slotProps.data.id)"
              >
                <icon-attachment-link
                  width="24"
                  height="24"
                ></icon-attachment-link>
              </PvButton>
            </template>
          </PvColumn>

          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              class="p-pagination-radius"
              :first="showCurrentPage"
            >
              <template #start="slotProps">
                {{ lang.page }}:
                {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
              </template>
            </PvPaginator>
          </template>
          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetRoleByBook from "../../../hooks/getRoleByBook.js";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
export default {
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.creditNote.listing,
          ...lang.th.ar.creditNote.edit,
          ...lang.th.shared.referenceDocumen,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.creditNote.listing,
          ...lang.en.ar.creditNote.edit,
          ...lang.en.shared.referenceDocumen,
        };
      }
    });

    const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();
    const {
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
    } = useGetFormPrint();
    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,

      textTableClass,
      textFormClass,

      formatCurrency,
      formatDate,
      formatDateToISOString,

      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,

      isHideBack,

      getRoleByBookByCode,
      isSharedRoleByBookReady,
    };
  },
  data() {
    return {
      //Search
      filterSearch: [
        { name: this.lang.dateRange, code: "date" },
        { name: this.lang.documentCode, code: "code" },
        { name: this.lang.unit, code: "unitCode" },
        { name: this.lang.address, code: "addressNo" },
        { name: this.lang.customerName, code: "customerName" },
      ],
      selectedFilterSearch: "date",
      dateStart: null,
      dateEnd: null,
      searchText: "",

      //Table
      isLoading: false,
      selectedCreditNote: null,
      selectedPage: 1,
      rowsPerPage: 10,

      //dialog
      showValidateDialog: false,
      validateWording: "",
      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ],

      storageName: "",

      //form
      showFormPrintModal: false,
      path: "",

      //Reference
      showReferenceDocument: false,
      referenceDocumentData: [],
      columnsReferenceDocument: [
        {
          field: "referenceDocumentBookCode",
          header: this.lang.documentBookCode,
          type: "string",
          style: "width: 10%;height: 56px;",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
        },
        // {
        //   field: "documentBookName",
        //   header: this.lang.documentBookName,
        //   type: "string",
        //   style: "width: 5%;",
        //   headerClass: "h-left font-sm font-normal",
        //   bodyClass: "text-left p-text-secondary",
        // },
        {
          field: "referenceCode",
          header: this.lang.documentCode,
          type: "string",
          style: "width: 15%",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
        },
        {
          field: "referenceDate",
          header: this.lang.date,
          type: "date",
          style: "width: 10%",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
        },
        {
          field: "referenceCancelStatus",
          header: this.lang.cancelStatus,
          type: "string",
          style: "width: 10%",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-center p-text-secondary",
        },
        {
          field: "referenceNetAmount",
          header: this.lang.amount,
          type: "currency",
          style: "width: 12%;",
          headerClass: "h-right font-sm font-normal",
          bodyClass: "text-right p-text-secondary",
        },
        {
          field: "referenceCreateDate",
          header: this.lang.createDate,
          type: "date",
          style: "width: 11%",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
        },
        {
          field: "referenceUpdateDate",
          header: this.lang.columnUpdateDate,
          type: "date",
          style: "width: 12%",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
        },
        {
          field: "referenceCreateBy",
          header: this.lang.createBy,
          type: "string",
          style: "width: 10%",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
        },
        {
          field: "referenceUpdateBy",
          header: this.lang.updateBy,
          type: "string",
          style: "width: 10%",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
        },
      ],
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.initiateData();
    await this.roleByBookDataReady();
  },
  methods: {
    async roleByBookDataReady() {
      if (this.isSharedRoleByBookReady) {
        await this.setLocalSearch();
        await this.getCreditNotes();
      }
    },
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    async gotoEdit(id) {
      if (id != "create" && !this.isRoleSelect) {
        this.validateWording = this.lang.validateRoleSelect;
        this.showValidateDialog = true;
        return
      } else if (id == "create" && !this.isRoleCreate){
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return
      }
      this.$router.push({name: "ar-credit-note-edit", params: {id: id}});
    },
    async initiateData() {
      let date = new Date();
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      this.searchText = "";
      this.selectedCreditNote = null;

      this.showReferenceDocument = false;
      this.referenceDocumentData = [];

      this.showValidateDialog = false;
      this.validateWording = "";

      this.storageName = `erp_search_CreditNoteList_${this.selectedProject.code}_${this.userCode}`;
    },
    changeFilterSearch() {
      this.initiateData();
    },
    async getCreditNotes() {
      if (this.validateSearchButton()) {
        this.validateWording = this.lang.validateSearchText;
        this.showValidateDialog = true;
        return;
      }

      this.isLoading = true;
      try {
        // console.log(this.payloadGetDatas);
        await this.$store.dispatch(
          "arCreditNote/getCreditNotes",
          this.payloadGetDatas
        );

        let local = {
          ...this.payloadGetDatas.where,
          rowsPerPage: this.rowsPerPage,
          selectedPage: this.selectedPage,
        };
        localStorage.setItem(this.storageName,JSON.stringify(local));
      } catch (error) {
        // this.$toast.add({
        //   severity: "error",
        //   summary: this.lang.notificationDialogHeader,
        //   detail: error,
        //   life: 5000,
        // });
        this.validateWording = error;
        this.showValidateDialog = true;
      }

      this.isLoading = false;
    },
    async onPageChange(event) {
      if(event.rows != this.rowsPerPage){
        this.selectedPage = 1;
      }else{
        this.selectedPage = event.page + 1;        
      }
      this.rowsPerPage = event.rows;
      await this.getCreditNotes();
    },
    async checkInputSearch(event) {
      // console.log(event.key);
      if (this.selectedFilterSearch == "code") {
        const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        const keyPressed = event.key;
        if (event.key != "Enter" && !keysAllowed.includes(keyPressed)) {
          event.preventDefault();
        }
      }

      if (event.key == "Enter") {
        await this.getCreditNotes();
      }
    },
    validateSearchButton() {
      if (
        this.isSearchDate &&
        (!this.selectedDateStart || !this.selectedDateEnd)
      ) {
        return true;
      } else if (!this.isSearchDate && this.searchText == "") {
        return true;
      }

      return false;
    },
    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      // console.log("created_localSearch=",localSearch);
      if (localSearch) {
        let search = JSON.parse(localSearch);

        if (search.entry === "entry") {
          if (search.dateStart != null && search.dateStart != "") {
            this.selectedFilterSearch = "date";
            this.dateStart = new Date(search.dateStart);
          } else this.dateStart = "";

          if (search.dateEnd != null && search.dateEnd != "") {
            this.selectedFilterSearch = "date";
            this.dateEnd = new Date(search.dateEnd);
          } else this.dateEnd = "";

          if (search.code != null && search.code != "") {
            this.selectedFilterSearch = "code";
            this.searchText = search.code;
          } else if (search.unitCode != null && search.unitCode != "") {
            this.selectedFilterSearch = "unitCode";
            this.searchText = search.unitCode;
          } else if (search.addressNo != null && search.addressNo != "") {
            this.selectedFilterSearch = "addressNo";
            this.searchText = search.addressNo;
          } else if (search.customerName != null && search.customerName != "") {
            this.selectedFilterSearch = "customerName";
            this.searchText = search.customerName;
          } else {
            this.searchText = "";
          }
          this.rowsPerPage = search.rowsPerPage;          
          this.selectedPage = search.selectedPage;

          search.entry = "";

          localStorage.setItem(this.storageName, JSON.stringify(search));
        }
      }
    },
    postStep(data) {
      let postStep = {
        caption: "",
        class: "primary",
      };

      if (data == "P") {
        postStep.caption = this.lang.printed;
      }
      //   if (data == "A") {
      //     postStep.caption = this.lang.voucherCreated;
      //   }

      return postStep;
    },
    async getPrintForm(data) {
      if (!this.isRolePrint) {
        this.validateWording = this.lang.validatePrintPermission;
        this.showValidateDialog = true;
        return
      }

      this.selectedCreditNote = data;

      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: "INVCN",
      };
      const payload = { where };
      await this.getFormPrints(payload);
      // console.log(this.filteredFormPrints);
      if (this.filteredFormPrints.length == 1) {
        this.formPrintModalSelected(this.filteredFormPrints[0]);
      } else {
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path + ".pdf";

      this.printCreditNote();

      this.showFormPrintModal = false;
    },
    async printCreditNote() {
      this.setLoading(true);
      try {
        let parameter = {
          Id: this.selectedCreditNote.id,
        };

        let payload = {
          id: this.selectedCreditNote.id,
          path: this.path,
          fileName: "CreditNote",
          parameter: JSON.stringify(parameter),
        };
        // console.log("print", payload);
        await this.$store.dispatch("arCreditNote/printCreditNote", payload);

        await this.getPreviewBlob(this.fileBlob);
        await this.getCreditNotes();
      } catch (error) {
        this.validateWording = error;
        this.showValidateDialog = true;
      }

      this.setLoading(false);
    },
    async openReferenceDocument(id) {
      if (!this.isRoleSelect) {
        this.validateWording = this.lang.validateRoleSelect;
        this.showValidateDialog = true;
        return
      }

      this.setLoading(true);
      try {
        const payload = {
          page: 1,
          projectId: this.selectedProject.id,
          id: id,
        };
        await this.$store.dispatch( "arCreditNote/getCreditNoteReferenceDocument", payload );
        // console.log(this.dataReferenceDocument);

        this.referenceDocumentData = this.dataReferenceDocument;
        this.setLoading(false);
        this.showReferenceDocument = true;
      } catch (error) {
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    roleByDocumentBook() {
      return this.getRoleByBookByCode("INVCN");
    },
    isRoleSelect() {
      if (this.roleByDocumentBook && this.roleByDocumentBook.select == "Y") {
        return true;
      }
      return false;
    },
    isRoleCreate() {
      if (this.roleByDocumentBook && this.roleByDocumentBook.insert == "Y") {
        return true;
      }
      return false;
    },
    isRolePrint() {
      if (this.roleByDocumentBook && this.roleByDocumentBook.print == "Y") {
        return true;
      }
      return false;
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "createCaption",
          caption: this.lang.createCreditNote,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
        },
      ];
      return dataButtons;
    },
    isSearchDate() {
      if (this.selectedFilterSearch == "date") {
        return true;
      } else {
        return false;
      }
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    payloadGetDatas() {
      let where = {
        projectId: this.selectedProject.id,
        dateStart:
          this.selectedFilterSearch == "date" ? this.selectedDateStart : null,
        dateEnd:
          this.selectedFilterSearch == "date" ? this.selectedDateEnd : null,
        code: this.selectedFilterSearch == "code" ? this.searchText : null,
        unitCode:
          this.selectedFilterSearch == "unitCode" ? encodeURIComponent(this.searchText) : null,
        addressNo:
          this.selectedFilterSearch == "addressNo" ? encodeURIComponent(this.searchText) : null,
        customerName:
          this.selectedFilterSearch == "customerName" ? this.searchText : null,
      };

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };
      return payload;
    },
    creditNotes() {
      return this.$store.getters["arCreditNote/creditNotes"];
    },
    totalPage() {
      return this.$store.getters["arCreditNote/totalPage"];
    },
    currentPage() {
      return this.$store.getters["arCreditNote/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    dataReferenceDocument() {
      return this.$store.getters["arCreditNote/creditNoteReferenceDocument"];
    },
    fileBlob() {
      return this.$store.getters["arCreditNote/blob"];
    },
    showCurrentPage(){
      let page = 0;
      page = this.rowsPerPage * (this.selectedPage - 1)
      return page;
    },
  },
  watch: {
    isSharedRoleByBookReady() {
      this.roleByBookDataReady();
    },
  },
};
</script>
