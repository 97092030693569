export default {
    accountingFrequentlyAccountings(state) {
      return state.accountingFrequently.accountingFrequentlies;
    },
    accountingFrequentlyCurrentPage(state) {
      return state.accountingFrequently.currentPage; 
    },
    accountingFrequentlyNextPage(state) {
      return state.accountingFrequently.currentPage + 1;
    },
    accountingFrequentlyTotalPage(state) {
      return state.accountingFrequently.totalPage;
    },
    accountingFrequentlyIsLastPage(_, getters) {
      return (getters.accountingFrequentlyCurrentPage === getters.accountingFrequentlyTotalPage) && (getters.accountingFrequentlyTotalPage !== 0);
    }
};
  