export default {
	meterUsages(state) {
		return state.meterUsages;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	meterUsageNextPage(state) {
	  return state.currentPage + 1;
	},
	meterUsageIsLastPage(_, getters) {
	  return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
	},
	selectedMeterUsage(state) {
		return state.selectedMeterUsage;
	},
};
