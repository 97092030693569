export default {
  setProductUnit(state, payload) {
    // state.project.length = 0;
    let productUnits = [...state.productUnit.productUnits];
    for (const productUnit of payload.productUnits) {
      if (productUnits.findIndex((c) => c.id === productUnit.id) < 0) {
        productUnits.push(productUnit);
      }
      // productUnits.push(productUnit);
    }
    state.productUnit.productUnits = productUnits;
    state.productUnit.currentPage = payload.currentPage;
    state.productUnit.totalPage = payload.totalPage;
  },
  resetProductUnit(state) {
    const productUnit = {
      productUnits: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.productUnit = productUnit;
  },
};
