export default {
	async getReportRequests(context, payload) {
		let url = process.env.VUE_APP_REPORT_API_URL + "/Requests";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.userId) {
			url = url + "&userId=" + payload.userId;
		}

		if (payload.projectId) {
			url = url + "&projectId=" + payload.projectId;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}
		url = encodeURI(url);

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Report Requests");
			throw error;
		}
		// console.log("getReportRequests responseData", responseData);
		await context.commit("setRequests", responseData);
	},

	async getReportRequestById(context, payload) {
		let url = process.env.VUE_APP_REPORT_API_URL + "/Requests";

		if (payload.id) {
			url = url + "/" + payload.id;
		}
		url = encodeURI(url);

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Report Request By Id");
			throw error;
		}
		// console.log("getReportRequestById responseData", responseData);
		await context.commit("setSelectedRequests", responseData.data);
	},

	async createReportRequest(context, payload) {
		// console.log('AddBankTransaction',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/Requests";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("createReportRequest", responseData);
			const error = new Error(responseData.message || "Failed to Create Report Request");
			throw error;
		}
		// console.log("createReportRequest responseData", responseData.data);
		await context.commit("setSelectedRequests", responseData.data);
	},

	async updateReportRequest(context, payload) {
		// console.log('AddBankTransaction',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/Requests";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("updateReportRequest", responseData);
			const error = new Error(responseData.message || "Failed to Update Report Request");
			throw error;
		}
		// console.log("updateReportRequest responseData", responseData.data);
		await context.commit("setSelectedRequests", responseData.data);
	},

	async deleteReportRequestById(context, payload) {
		let url = process.env.VUE_APP_REPORT_API_URL + "/Requests";

		if (payload.id) {
			url = url + "&id=" + payload.id;
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to Delete Report Request");
			throw error;
		}
		// console.log("deleteReportRequestById responseData", responseData);
		await context.commit("setSelectedRequests", responseData.data);
	},
	resetRequests(context) {
		context.commit("resetRequests", null);
	},
	async getFile(context, payload) {
		let url = process.env.VUE_APP_REPORT_API_URL + "/Files/" + payload.url;
		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token,
			},
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url

		});

		let urlBlob = ''
		let blob = await response.blob();
		urlBlob = window.URL.createObjectURL(blob);
		context.commit('setBlob', urlBlob);
	},
	
	async getCurrentQueue(context) {
		let url = process.env.VUE_APP_REPORT_API_URL + "/Requests/Queue/Current";
		url = encodeURI(url);
		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get current request queue");
			throw error;
		}
		// console.log("getReportRequestById responseData", responseData);
		await context.commit("setCurrentQueue", responseData.data);
	},

	async getPresignedUrl(context, payload) {
		let url = process.env.VUE_APP_REPORT_API_URL + "/Files/url/" + payload.url + "?filename=" + payload.fileName;

		url = encodeURI(url);

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Presigned URL");
			throw error;
		}
		// console.log("getReportRequestById responseData", responseData);
		await context.commit("setPresignedUrl", responseData);
	},
	resetPresignedUrl(context) {
		context.commit("resetPresignedUrl");
	},
	async cancelReportRequest(context, payload) {
		let url = process.env.VUE_APP_REPORT_API_URL + "/Requests/"+payload.id+"/Cancel";
		const token = context.rootGetters.token;
		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});
		const responseData = await response.json();
		if (!response.ok) {
			const error = new Error(responseData.message || "Failed to Update Report Request");
			throw error;
		}
		await context.commit("setCurrentQueue", responseData.data);
	},
};
