<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
    :header="lang.selectAPBook"
  >
    <PvDataTable   
      showGridlines
      :value="filteredAPBooks"
      v-model:selection="selectedAPBook"
      selectionMode="single"
      dataKey="bookId"
      responsiveLayout="scroll"
      :scrollHeight="halfViewportHeight + 'px'"
      class="p-datatable-sm"
      :class="textTableClass"
      :loading="isSharedAPBookReady"
      @row-dblclick="selectAPBook()"
    >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
      <template #header>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <PvInputText
            v-model="searchText"
            :placeholder="lang.keywordSearch"
            autofocus
          />
        </span>
      </template>
      <PvColumn selectionMode="single" bodyClass="text-center"></PvColumn>
      <PvColumn
        field="bookCode"
        :header="lang.code"
        headerClass="h-center"
        bodyClass="text-center"
      ></PvColumn>
      <PvColumn
        field="bookNameTH"
        :header="lang.nameTH"
        headerClass="h-center"
      ></PvColumn>
      <PvColumn
        field="bookNameEN"
        :header="lang.nameEN"
        headerClass="h-center"
      ></PvColumn>
    </PvDataTable> 
     <template #footer>
      <PvButton
        :label="lang.ok"
        icon="pi pi-check"
        autofocus
        @click="selectAPBook"
      />
    </template> 
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useGetAPBook from "../../hooks/getAPBook.js";
export default {
  props: {
    shouldGetData: {
      type: Boolean,
      default: true,
    },
    search: {
      type: String,
    },
    isSharedLoading: {
      type: Boolean
    }
  },
  emits: ["close", "apBook-selected"],
  setup() {    
    const { lang } = useLang();
    const { textHeaderClass, textTableClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.ap.purchaseInvoice.listing };
      } else {
        return { ...lang.en.base, ...lang.en.ap.purchaseInvoice.listing };
      }
    });
    const { filteredAPBooks, searchText, isSharedAPBookReady } = useGetAPBook();

    return {
      filteredAPBooks,
      isSharedAPBookReady,
      textHeaderClass,
      textTableClass,
      lang: mergeLang,
      searchText 
    };
  },
  data() {
    return {
      selectedAPBook: null,
    };
  },
  created() {
    // console.log("created_BaseAPBookModal");
    // this.SetBookCode(null);
    // this.$store.dispatch("shared/resetAPBook");
  },
  methods: {
    selectAPBook() {
      if(!this.selectedAPBook) return true;
      this.$emit("apBook-selected", this.selectedAPBook);
    },
    closeDialog() {
      this.$emit("close");
    },
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
  },
  watch: {
    search(curValue) {   
      // console.log("search=",curValue); 
      if (curValue) {
        this.searchText = curValue;
      }
    },
    filteredAPBooks(){
      // console.log("filteredAPBooks=",this.filteredAPBooks[0]);
      this.selectedAPBook = this.filteredAPBooks[0];
    },
    isSharedLoading(curValue){
      this.isSharedAPBookReady = curValue;
    }
  },
};
</script> 
