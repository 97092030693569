export default {
  title: "ฟอร์มพิมพ์เล่มเอกสารรหัส : ",
  addNewFormType: "เพิ่มฟอร์มใหม่",
  columnCategory:"หมวด",
  columnSequence: 'ลำดับ',
  columnName: "ชื่อ",
  columnPath: "Path",
  columnBookCode: "รหัสเล่ม",
  columnProject: "โครงการ",
  isActive:"ใช้งาน",
  columnProjectCode: "รหัสโครงการ",
};
