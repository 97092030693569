export default {
  title: "Receipts",
  addReceipt: "Add Receipt",
  dateRange: "Date Range",
  documentCode: "Document Code",
  date: "Document Date",
  unit: "Unit",
  address:"Address no.",
  customerName: "Customer Name",
  price: "Price",
  paid: "Paid",
  payIn: "PayIn",
  refunded: "Refunded",
  splitRefunded: "Split Refunded",
  paidCancel: "Cancel",
	cancel: "Canceled",
  printOrVoucherCreated: "Printed/Voucher Created",
  printed: "Printed",
  voucherCreated: "Voucher Created",
  columnCreateBy: "Create By",
  columnUpdateBy: "Update By",
  receiptCode: "Receipt Code",
  columnCreateDate:"Create Date",
  columnUpdateDate:"Update Date", 

	addItem: "Add Item",
	editItem: "Edit Item",
  employeeName:"Employee Name",
	validateRoleSelect: "You don't have permission to select document.",
	validateNoProductSelect: "Please select product.",
	validateNoPeriod: "Please select period.",
	confirmCancel: "Confirm Cancel",
	validateInactiveUnitSelect: "This unit is inactive.",
	searchTextPlaceholder: "Fill up to search.",
	noDetail: "No Detail",
	cancelDocument: "Cancel Document",
	other: "Other",
  receiptPrintListPage: "Receipt Print ",
  buttonGotoVisitorControl: "Edit pay type visitor",
	import: "Import",
  validateSearchText:"Please enter searchText.",
  tableEmptyList: "Data not found.",

  billPayment: "Bill Payment",
};
