<template>
  <PvDialog
    :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
    :style="{ width: '40vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <label class="font-lg pb-1">{{ lang.titleSettingDialog }}</label>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary pb-0 pt-0"
          style="width: 15px"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="py-3 col-12">
          <label class="font-normal">{{ lang.descriptionSettingDialog }}</label>
        </div>
        <div class="col-12 flex flex-wrap p-0">
          <div class="col-12">
            <label class="font-normal">{{ lang.confirmUseVoucher }}</label>
          </div>
          <div class="col-12">
            <PvCheckbox
              id="isUseExpenseVoucher"
              inputId="binary"
              v-model="isUseExpenseVoucher"
              :binary="true"
              @change="expenseVoucherChange"
              :disabled="isAlreadyExpense"
            />
            <label for="isUseExpenseVoucher" class="pl-3">{{ lang.isUseExpenseVoucher }}</label>
            <PvCheckbox
              id="isUsePaymentVoucher"
              class="ml-3"
              inputId="binary"
              v-model="isUsePaymentVoucher"
              :binary="true"
              :disabled="isAlreadyPayment"
            />
            <label for="isUsePaymentVoucher" class="pl-3">{{ lang.isUsePaymentVoucher }}</label>
          </div>
          <div class="col-12">
            <label for="documentVoucherDate">{{ lang.documentDateSetting }}</label>
            <PvCalendar
              id="documentVoucherDate"
              v-model="documentVoucherDate"
              class="inputfield w-full mt-1"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="col-12" v-if="isShowProductData">
            <label for="date">{{ lang.productVoucherSetting }}</label>
            <PvDataTable
              class="w-full"
              :value="products"
              dataKey="id"
              responsiveLayout="scroll"
              :class="textTableClass"
              v-model:selection="selectedProducts"
              selectionMode="multiple"
              scrollHeight="294px"
              :scrollable="true"
              :metaKeySelection="false"
            >
              <template #empty>
                <div class="w-full" style="text-align: center">
                  <label>{{ lang.tableEmpty }}</label>
                </div>
              </template>
              <PvColumn selectionMode="multiple" style="max-width: 3rem; height: 56px" />
              <PvColumn
                field="productCode"
                :header="lang.columnProductCode"
                style="min-width: 10rem; height: 56px"
                headerClass="h-left font-normal"
                bodyClass="text-left font-normal"
              >
                <template #body="slotProps">
                  <PvSkeleton v-if="isLoading"></PvSkeleton>
                  <label v-else>{{ slotProps.data.productCode }}</label>
                </template>
              </PvColumn>
              <PvColumn
                field="productName"
                :header="lang.columnProductName"
                style="min-width: 15rem; height: 56px"
                headerClass="h-left font-normal"
                bodyClass="text-left font-normal"
              >
                <template #body="slotProps">
                  <PvSkeleton v-if="isLoading"></PvSkeleton>
                  <label v-else>{{ slotProps.data.productName }}</label>
                </template>
              </PvColumn>
              <PvColumn
                field="period"
                :header="lang.columnPeriod"
                style="min-width: 5rem; height: 56px"
                headerClass="h-left font-normal"
                bodyClass="text-left font-normal"
              >
                <template #body="slotProps">
                  <PvSkeleton v-if="isLoading"></PvSkeleton>
                  <label v-else>{{ slotProps.data.period }}</label>
                </template>
              </PvColumn>
            </PvDataTable>
          </div>
          <div class="col-12 mt-3" v-if="isShowVoucherData">
            <label for="date">{{ lang.productHasVoucher }}</label>
            <PvDataTable
              class="w-full"
              :value="vouchers"
              dataKey="id"
              responsiveLayout="scroll"
              :class="textTableClass"
              scrollHeight="294px"
              :scrollable="true"
            >
              <template #empty>
                <div class="w-full" style="text-align: center">
                  <label>{{ lang.tableEmpty }}</label>
                </div>
              </template>
              <PvColumn
                field="productCode"
                :header="lang.columnProductCode"
                style="min-width: 10rem; height: 56px"
                headerClass="h-left font-normal"
                bodyClass="text-left font-normal"
              >
                <template #body="slotProps">
                  <PvSkeleton v-if="isLoading"></PvSkeleton>
                  <label v-else>{{ slotProps.data.productCode }}</label>
                </template>
              </PvColumn>
              <PvColumn
                field="productName"
                :header="lang.columnProductName"
                style="min-width: 15rem; height: 56px"
                headerClass="h-left font-normal"
                bodyClass="text-left font-normal"
              >
                <template #body="slotProps">
                  <PvSkeleton v-if="isLoading"></PvSkeleton>
                  <label v-else>{{ slotProps.data.productName }}</label>
                </template>
              </PvColumn>
              <PvColumn
                field="period"
                :header="lang.columnPeriod"
                style="min-width: 5rem; height: 56px"
                headerClass="h-left font-normal"
                bodyClass="text-left font-normal"
              >
                <template #body="slotProps">
                  <PvSkeleton v-if="isLoading"></PvSkeleton>
                  <label v-else>{{ slotProps.data.period }}</label>
                </template>
              </PvColumn>
              <PvColumn
                field="referenceCode"
                :header="lang.columnCode"
                style="min-width: 10rem; height: 56px"
                headerClass="h-left font-normal"
                bodyClass="text-left font-normal"
              >
                <template #body="slotProps">
                  <PvSkeleton v-if="isLoading"></PvSkeleton>
                  <label v-else>{{ slotProps.data.referenceCode }}</label>
                </template>
              </PvColumn>
            </PvDataTable>
          </div>
        </div>
      </section>
    </transition>

    <template #footer>
      <div class="flex justify-content-between align-items-center">
        <PvButton :label="lang.close" class="p-button-outlined p-button-secondary w-full ml-3" @click="closeDialog" />
        <PvButton :label="lang.confirm" class="w-full mr-3" @click="confirm" />
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { ref, computed, onMounted, watch, toRefs } from "vue";
import BaseDialog from "../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";

export default {
  props: {
    dataProducts: {
      type: Object,
      default: () => ({}),
    },
    documentDate: {
      type: Date,
      default: () => new Date(),
    },
  },
  emits: ["close", "confirm"],
  setup(props, { emit }) {
    const { lang } = useLang();
    const { textFormClass, textTableClass } = useTextStyle();
    const { formatDateToISOString } = useFormat();
    const store = useStore();

    const mergeLang = computed(() => ({
      ...(store.getters.selectedLanguage === "TH" ? lang.th.base : lang.en.base),
      ...(store.getters.selectedLanguage === "TH" ? lang.th.ap.paymentRequest.edit : lang.en.ap.paymentRequest.edit),
    }));

    const isUseExpenseVoucher = ref(true);
    const isUsePaymentVoucher = ref(true);
    const documentVoucherDate = ref(new Date());
    const products = ref([]);
    const selectedProducts = ref([]);
    const vouchers = ref([]);
    const paymentRequestHeaderId = ref(null);
    const isAlreadyPayment = ref(false);
    const isAlreadyExpense = ref(false);
    const isLoading = ref(true);
    const { dataProducts } = toRefs(props);
    const isShowProductData = ref(true);
    const isShowVoucherData = ref(false);
    const dataConfigDialog = ref(null);

    onMounted(() => {
      setData();
    });

    const closeDialog = () => {
      emit("close");
    };

    const confirm = () => {
      emit(
        "confirm",
        selectedProducts.value,
        isUseExpenseVoucher.value ? "Y" : "N",
        isUsePaymentVoucher.value ? "Y" : "N"
      );
    };

    const setData = async () => {
      const { details, isUsePaymentVoucher, isUseExpenseVoucher } = props.dataProducts;

      if (!details) return;

      const productsList = details.filter((product) => !product.referenceId);
      const vouchersList = details.filter((product) => product.referenceId);

      products.value = productsList;
      vouchers.value = vouchersList;
      paymentRequestHeaderId.value = productsList[0]?.paymentRequestHeaderId || null;
      documentVoucherDate.value = new Date(formatDateToISOString(props.documentDate));
      isUsePaymentVoucher.value = isUsePaymentVoucher === "Y";
      isUseExpenseVoucher.value = isUseExpenseVoucher === "Y";

      if (!paymentRequestHeaderId.value) {
        isLoading.value = false;
        return;
      }

      isLoading.value = true;
      await getReferencePaymentRequest();

      if (!store.getters["paymentRequest/paymentRequestReference"]) {
        isLoading.value = false;
        return;
      }

      const referenceMap = new Map(
        store.getters["paymentRequest/paymentRequestReference"].map((ref) => [ref.paymentRequestDetailId, ref])
      );

      vouchersList.forEach((voucher) => {
        const reference = referenceMap.get(voucher.id);
        if (reference) {
          voucher.referenceId = reference.referenceId;
          voucher.referenceCode = reference.referenceCode;
        }
      });

      isAlreadyExpense.value = productsList.length === 0;
      isAlreadyPayment.value = store.getters["paymentRequest/paymentRequestReference"].some(
        (ref) => ref.paymentRequestHeaderId
      );

      isShowVoucherData.value = vouchersList.length > 0;
      isShowProductData.value = productsList.length > 0;
      selectedProducts.value = productsList.filter((product) => product.isUseExpenseVoucher === "Y");

      isLoading.value = false;
    };

    const expenseVoucherChange = () => {
      isShowProductData.value = !isShowProductData.value;
      selectedProducts.value = isUseExpenseVoucher.value
        ? products.value.filter((product) => product.isUseExpenseVoucher === "Y")
        : [];
    };

    const getReferencePaymentRequest = async () => {
      try {
        await store.dispatch("paymentRequest/getPaymentRequestReferece", {
          id: paymentRequestHeaderId.value,
          isListPage: false,
        });
      } catch (error) {
        setDialog({ classTitle: "error", title: mergeLang.value.notificationDialogHeader, message: error });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
      }
    };

    const setDialog = (dataConfig) => {
      dataConfig.classTitle = { success: "p-text-success", error: "p-error" }[dataConfig.classTitle] || "";
      dataConfig.button = dataConfig.button || [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "cancel",
        },
      ];
      dataConfigDialog.value = {
        titleConfirm: dataConfig.title,
        classTitle: dataConfig.classTitle,
        wordingConfirm: dataConfig.message,
        dataButtons: dataConfig.button,
        display: true,
      };
    };

    watch(dataProducts, setData);

    return {
      lang: mergeLang,
      textFormClass,
      textTableClass,
      closeDialog,
      confirm,
      expenseVoucherChange,
      isUseExpenseVoucher,
      isUsePaymentVoucher,
      documentVoucherDate,
      products,
      selectedProducts,
      vouchers,
      isAlreadyPayment,
      isAlreadyExpense,
      isLoading,
      isShowProductData,
      isShowVoucherData,
    };
  },
};
</script>
