<template>
  <base-container class="mb-5">
    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchTextPrint"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>

    <base-product-ap-modal
      v-model:visible="showProductModal"
      :search="searchText"
      @close="showProductModal = false"
      @product-selected="productModalSelected"
    ></base-product-ap-modal>

    <purchase-requisition-dialog
      v-model:visible="showPurchaseRequisition"
      @close="showPurchaseRequisition = false"
      @select-purchase-requisition="selectedPurchaseRequisition"
    ></purchase-requisition-dialog>

    <div
      class="flex justify-content-between align-items-center"
      style="padding-top: 20px"
    >
      <div class="my-3">
        <PvButton
          icon="pi pi-chevron-left"
          :label="lang.back"
          @click="goBack"
          class="mr-2 p-button-secondary"
        />
      </div>
      <h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
    </div>

    <transition name="fade" mode="out-in">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="pt-2 pb-1">
          <PvButton
            icon="pi pi-file"
            :label="lang.create"
            class="p-button-secondary mr-2 mb-2"
            @click="createDocument"
          />
          <PvButton
            icon="pi pi-save"
            :label="lang.save"
            class="mr-2 mb-2"
            @click="save"
            :disabled="isCheckDisabled"
          />
          <PvButton
            icon="pi pi-save"
            :label="lang.saveAs"
            class="mr-2 mb-2"
            @click="askForSaveAs"
            :disabled="isCheckSaveAs"
          />
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.printPdf"
            class="mr-2 mb-2"
            @click="selectPrintForm"
            :disabled="isCreateDocument"
          />
          <PvButton
            icon="pi pi-check-circle"
            :label="lang.approve"
            class="mr-2 mb-2"
            @click="askForApprove"
            :disabled="isCreateDocument || isCheckDisabled"
          />
          <PvButton
            v-if="isCheckRoleApprove"
            icon="pi pi-replay"
            :label="lang.reApprove"
            class="mr-2 mb-2"
            @click="askForRevertApprove"
            :disabled="isCreateDocument || isApproved"
          />
          <PvButton
            icon="pi pi-times"
            :label="lang.closePO"
            class="mr-2 mb-2"
            @click="askForClose"
            :disabled="isCreateDocument || isCheckCloseDisabled"
          />
          <PvButton
            icon="pi pi-upload"
            :label="lang.import"
            class="mr-2 mb-2"
            @click="importPR"
            :disabled="isCheckDisabled"
          />
          <PvButton
            icon="pi pi-times"
            :label="lang.cancel"
            class="p-button-outlined p-button-danger mr-2 mb-2"
            @click="askForCancel"
            :disabled="isCreateDocument || isCheckDisabled"
          />
        </div>
        <div class="flex flex-wrap" :class="textFormClass">
          <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 flex flex-wrap">
            <!-- ************************ -->
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="code">{{ lang.code }}</label>
              <PvInputText
                id="code"
                v-model="code"
                type="text"
                class="inputfield w-full mt-1 p input-number-disable"
                disabled
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="referDocument">{{ lang.inputReferDocument }}</label>
              <PvInputText
                id="referDocument"
                v-model="referDocument"
                type="text"
                class="inputfield w-full mt-1 p"
                :disabled="isCheckDisabled"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="creditTerm">{{ lang.inputCreditTerm }}</label>
              <PvInputNumber
                id="creditTerm"
                v-model="creditTerm"
                class="inputfield w-full mt-1 p"
                inputStyle="text-align: right"
                :disabled="isCheckDisabled"
                @focus="$event.target.select()"
                @blur="editCreditTerm($event)"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="date">{{ lang.inputDate }}</label>
              <PvCalendar
                id="date"
                v-model="date"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
                :disabled="isCheckDisabled"
                @date-select="dateSelected"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="vatSendDate">{{ lang.inputVatSendDate }}</label>
              <PvCalendar
                id="vatSendDate"
                v-model="vatSendDate"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
                :disabled="isCheckDisabled"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="dueDate">{{ lang.inputDueDate }}</label>
              <PvCalendar
                id="dueDate"
                v-model="dueDate"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
                :disabled="isCheckDisabled"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="supplier">{{ lang.inputSupplier }}</label>
              <base-supplier-auto-complete
                id="supplier"
                v-model="selectedSupplier"
                class="inputfield w-full mt-1"
                @load-finish="supplierDataReady"
                :disabled="isCheckDisabled"
                :class="{ 'p-invalid': supplierError }"
                @item-select="selectSupplier()"
              />
              <small v-if="supplierError" id="supplier-help" class="p-error">{{
                lang.validateNoSupplierSelect
              }}</small>
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="employee">{{ lang.inputEmployeeName }}</label>
              <base-employee-auto-complete
                id="employee"
                v-model="selectedEmployee"
                class="inputfield w-full mt-1"
                @load-finish="employeeDataReady"
                :disabled="isCheckDisabled"
                :class="{ 'p-invalid': employeeError }"                
                @item-select="employeeError = false"
              />
              <small v-if="employeeError" id="employee-help" class="p-error">{{
                lang.validateNoEmployeeSelect
              }}</small>
            </div>

            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="remark1">{{ lang.inputRemark1 }}</label>
              <PvInputText
                id="remark1"
                v-model="remark1"
                type="text"
                class="inputfield w-full mt-1 p"
                :disabled="isCheckDisabled"
              />
            </div>

            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="remark2">{{ lang.inputRemark2 }}</label>
              <PvInputText
                id="remark2"
                v-model="remark2"
                type="text"
                class="inputfield w-full mt-1 p"
                :disabled="isCheckDisabled"
              />
            </div>
            <!-- ************************ -->
          </div>
        </div>

        <PvDataTable
          showGridlines
          :value="details"
          dataKey="id"
          responsiveLayout="scroll"
          :class="textTableClass"
          editMode="cell"
          class="editable-cells-table"
          @cell-edit-complete="onCellEditComplete"
        >
          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
          <PvColumn
            field="itemNo"
            :header="lang.itemNo"
            style="min-width: 4rem"
            headerClass="h-center"
            bodyClass="text-center"
          >
          </PvColumn>
          <PvColumn
            field="productCode"
            :header="lang.columnCode"
            style="min-width: 4rem"
            headerClass="h-center"
            bodyClass="text-left"
            sortable
          >
            <template #editor="{ data, field }">
              <PvInputText
                type="text"
                v-model.trim="data[field]"
                :disabled="isCheckDisabled"
              />
            </template>
          </PvColumn>
          <PvColumn
            field="productName"
            :header="lang.columnCodeName"
            style="min-width: 4rem"
            headerClass="h-center"
            bodyClass="text-left"
            sortable
          ></PvColumn>
          <PvColumn
            field="remark"
            :header="lang.description"
            style="min-width: 4rem"
            headerClass="h-center"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
             <pre class="p-0 m-0">{{ slotProps.data.remark }}</pre>
            </template>
            <template #editor="{ data, field }">
              <PvTextarea
                v-model="data[field]"
                :autoResize="true"
                rows="1"
                class="inputfield w-full mt-1"
                :maxlength="750"
                cols="20"
						    :disabled="isCheckDisabled"
                @focus="$event.target.select()"
              />
            </template>
          </PvColumn>
          <PvColumn
            field="productUnitName"
            :header="lang.columnUnitName"
            style="min-width: 6rem"
            headerClass="h-center"
            bodyClass="text-left"
            sortable
          ></PvColumn>
          <PvColumn
            field="unitPrice"
            :header="lang.columnUnitPrice"
            style="min-width: 6rem"
            headerClass="h-center"
            bodyClass="text-right"
            sortable
          >
            <template #editor="{ data, field }">
              <PvInputNumber
                id="unitPrice"
                class="inputfield w-full"
                inputClass="text-right"
                v-model="data[field]"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                :min="0"
                @focus="$event.target.select()"
                :disabled="isCheckDisabled"
              />
            </template>
            <template #body="slotProps">
              {{ formatCurrencyTable(slotProps.data, "unitPrice") }}
            </template>
          </PvColumn>

          <PvColumn
            field="quantity"
            :header="lang.columnQuantity"
            style="min-width: 4rem"
            headerClass="h-center"
            bodyClass="text-right"
            sortable
          >
            <template #editor="{ data, field }">
              <PvInputNumber
                id="quantity"
                class="inputfield w-full"
                inputClass="text-right"
                v-model="data[field]"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                :min="0"
                @focus="$event.target.select()"
                :disabled="isCheckDisabled"
              />
            </template>
          </PvColumn>

          <PvColumn
            field="discount"
            :header="lang.columnDiscount"
            headerClass="h-center"
            bodyClass="text-right"
            style="min-width: 4rem"
            sortable
          >
            <template #editor="{ data, field }">
              <!-- <PvInputText
                type="text"
                v-model.trim="data[field]"
                :disabled="isCheckDisabled"
                @focus="$event.target.select()"
                @keypress="checkInputDiscount($event)"
              /> -->
    					<base-discount-input-text 
                @focus="$event.target.select()"
                @emit-discount-amount="setDiscountColumnText"
                :disabled="isCheckDisabled"
                :defaultDiscount="data[field]"/>
            </template>
          </PvColumn>

          <PvColumn
            field="discountAmount"
            :header="lang.columnDiscountAmt"
            headerClass="h-center"
            bodyClass="text-right"
            style="min-width: 4rem"
            sortable
          >
            <template #body="slotProps">
              {{ formatCurrencyTable(slotProps.data, "discountAmount") }}
            </template>
          </PvColumn>

          <PvColumn
            field="totalAmount"
            :header="lang.columnNetAmount"
            style="min-width: 6rem"
            headerClass="h-center"
            bodyClass="text-right"
            sortable
          >
            <template #body="slotProps">
              {{ formatCurrencyTable(slotProps.data, "totalAmount") }}
            </template>
          </PvColumn>
          <PvColumn
            style="min-width: 4rem"
            headerClass="h-center"
            bodyStyle="text-align: center; overflow: visible"
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-danger p-button-text py-0"
                icon="pi pi-times"
                @click="deleteRow(slotProps.data.id)"
                :disabled="isCheckDisabled || !slotProps.data.productCode"
              ></PvButton>
            </template>
          </PvColumn>
        </PvDataTable>

        <div class="flex flex-wrap" :class="textFormClass">
          <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 flex flex-wrap">
            <!-- <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="currency">{{ lang.inputCurrency }}</label>
              <PvInputText
                id="currency"
                v-model="currency"
                type="text"
                class="inputfield w-full mt-1 p input-disable text-right"
                :placeholder=currency
                readonly
              />
            </div> -->
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="totalAmount">{{ lang.inputTotalAmount }}</label>
              <PvInputNumber
                id="totalAmount"
                class="inputfield w-full mt-1"
                inputClass="input-number-disable text-right"
                v-model="totalAmount"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                disabled
              />
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="discount">{{ lang.inputDiscount }}</label>
              <!-- <PvInputText
                id="discount"
                v-model="discount"
                type="text"
                class="inputfield w-full mt-1 p text-right"
                :disabled="isCheckDisabled"
                @keypress="checkInputDiscount($event)"                
                @input="editDiscount($event)"
              /> -->
    					<base-discount-input-text 
                class="inputfield w-full mt-1 p text-right" 
                @emit-discount-amount="setDiscountText"
                @focus="$event.target.select()"
                :defaultDiscount="discount"
								:disabled="isCheckDisabled"/>
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="discountAmount">{{ lang.inputDiscountAmount }}</label>
              <PvInputNumber
                id="discountAmount"
                class="inputfield w-full mt-1"
                inputClass="input-number-disable text-right"
                v-model="discountAmount"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                disabled
                :class="{ 'p-invalid': discountAmountError }"
              />
              <small v-if="discountAmountError" id="supplier-help" class="p-error">{{
                lang.validateDiscountAmount
              }}</small>
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="actualPayAmount">{{ lang.inputPayment }}</label>
              <PvInputNumber
                id="actualPayAmount"
                v-model="actualPayAmount"
                class="inputfield w-full mt-1"
                inputClass="text-right"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
              />
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="totalAmountBeforeTax">{{
                lang.inputTotalAmountBeforeTax
              }}</label>
              <PvInputNumber
                id="totalAmountBeforeTax"
                class="inputfield w-full mt-1"
                inputClass="input-number-disable text-right"
                v-model="totalAmountBeforeTax"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                disabled
              />
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="tax">{{ lang.inputTax }}</label>
              <base-tax-auto-complete
                id="tax"
                v-model="selectedTax"
                class="inputfield w-full mt-1"
                @load-finish="taxDataReady"
                :class="{ 'p-invalid': taxError }"
                :disabled="isCheckDisabled"
                @item-select="selectTax()"
              />
              <small v-if="taxError" id="tax-help" class="p-error">{{
                lang.validateNoTaxSelect
              }}</small>
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="taxAmount">{{ lang.inputTaxAmount }}</label>
              <label class="font-xs p-text-disabled">{{lang.calculatedFromSystem}}{{ this.formatCurrency(this.calculateTaxFromSystem) }}</label>              
              <div class="flex justify-content-between align-items-center">
                <div class="w-full pr-2">
                  <PvInputNumber
                    id="taxAmount"
                    class="inputfield w-full mt-1"
                    v-model="taxAmount"
                    mode="decimal"
                    :minFractionDigits="2"
                    :maxFractionDigits="2"
                    placeholder="0.00"
                    @dblclick="doubleClickTaxAmount($event)"
                    @blur="setNetAmount($event)"
                    :disabled="isTaxAmountDisabled" 
                    :inputClass="classDisableTaxAmount"
                  />                 
                </div>
                <PvButton
                  type="button"
                  class="p-button-secondary p-button-text mt-1 p-1"
                  @click="doubleClickTaxAmount()"
                  v-if="!this.tax == 0"    
                >
                  <icon-pen-edit-text iconColor="secondary" width="24" height="24" />
                </PvButton>
              </div>
            </div>

            <!-- ************************************ -->
            <!-- <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2 pb-0"></div> -->
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="netAmount">{{ lang.inputNetAmount }}</label>
              <PvInputNumber
                id="netAmount"
                class="inputfield w-full mt-1"
                inputClass="input-number-disable text-right"
                v-model="netAmount"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                disabled
              />
            </div>

            <!-- ************************************ -->
          </div>
        </div>
      </div>
    </transition>
  </base-container>
</template>
<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useGetProductAP from "../../../hooks/getProductAP.js";
import purchaseRequisitionDialog from "../../../components/AP/BasePurchaseRequisitionDialog.vue";
export default {
  props: ["apBookId", "id", "projectCode"],
  components: {
    purchaseRequisitionDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrencyTable, formatDate, formatDateToISOString,formatCurrency } =
      useFormat();
    const {
      getFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints
    } = useGetFormPrint();
    const { isSharedProductReady, getProductByCode, searchText } =
      useGetProductAP();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.ap.purchaseOrder.edit };
      } else {
        return { ...lang.en.base, ...lang.en.ap.purchaseOrder.edit };
      }
    });


    return {
      lang: mergeLang,

      textHeaderClass,
      textFormClass,
      textTableClass,

      formatCurrencyTable,
      formatDate,
      formatDateToISOString,
      formatCurrency,

      getFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints,

      isSharedProductReady,
      getProductByCode,
      searchText,

    };
  },
  data() {
    return {
      selectedAPBook: null,
      selectedBookId: "",
      selectedBookCode: "",

      code: "",
      referDocument: "",
      date: null,
      dueDate: null,
      vatSendDate: null,
      creditTerm: 0,
      remark1: "",
      remark2: "",

      totalAmount: 0,
      netAmount: 0,
      actualPayAmount: 0,
      vatrate: 0,
      differenceAmount: 0,
      discountAmount: 0,
      discount: "",
      totalAmountBeforeTax: 0,
      tax: 0,
      taxAmount: 0,
      vatInclude: "",
      details: [],
      currency: "",

      selectedSupplier: null,
      isSharedSupplierReady: false,
      supplierError: false,

      selectedEmployee: null,
      isSharedEmployeeReady: false,
      employeeError: false,
      employeeName: null,

      isLoading: false,
      editingRows: [],

      showProductModal: false,
      showPurchaseRequisition: false,

      selectedTax: null,
      isSharedTaxReady: false,
      taxError: false,

      showFormPrintModal: false,
      path: "",

      discountAmountError : false,
      isSetData: true,

      calculateTaxFromSystem: 0.00,
      isTaxAmountDisabled: true,
      discountText: ""
    };
  },
  async mounted() {
    const localSearch = localStorage.getItem( this.storageName );
    if (localSearch) {
      let search = JSON.parse(localSearch);
      this.selectedAPBook = search.selectedAPBook;
      this.selectedBookId = search.selectedAPBook.bookId;
      this.selectedBookCode = search.selectedAPBook.bookCode;
      // this.tax = search.selectedAPBook.taxRate;
      this.vatInclude = search.selectedAPBook.vatInclude;
    }
    if (!this.isCreateDocument) {
      this.setLoading(true);      
      await this.$store.dispatch("apPurchaseOrder/resetSelectedPurchaseOrder");
      const where = {
        projectId: this.selectedProject.id,
        id: this.id,
      };
      await this.$store.dispatch("apPurchaseOrder/getPurchaseOrder", { where });
      // this.setLoading(false);
      // console.log('create', this.purchaseOrder.purchaseOrderDetails);
    } else {
      this.initiateData();
    }

    await this.getRoleByDocumentBook();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      const localSearch = localStorage.getItem( this.storageName );
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      // console.log("search=",search);
      localStorage.setItem( this.storageName ,JSON.stringify(search));
      this.$router.push({ name: "ap-purchase-order-apBookId", params: { apBookId: this.apBookId }});
    },
    supplierDataReady() {
      this.isSharedSupplierReady = true;
    },
    employeeDataReady() {
      this.isSharedEmployeeReady = true;
      if (this.isCreateDocument) {
        this.selectedEmployee = this.sharedEmployees.find(
          (employee) => employee.code === this.selectedUserName.code
        );
      }
    },
    taxDataReady() {
      this.isSharedTaxReady = true;
      if (this.isCreateDocument) {
        this.selectedTax = this.sharedTaxes.find(
          (tax) => tax.taxRate === this.selectedAPBook.taxRate
        );
        this.tax = this.selectedTax.taxRate;
      }
    },
    checkShouldSetData() {
      if (
        this.isSharedSupplierReady &&
        this.isSharedEmployeeReady &&
        this.isSharedTaxReady &&
        this.purchaseOrder
      ) {
        this.setData();
      }
    },
    async initiateData() {
      this.selectedEmployee = this.sharedEmployees.find(
        (employee) => employee.code === this.selectedUserName.code
      );

      this.selectedTax = this.sharedTaxes.find(
        (tax) => tax.taxRate === this.selectedAPBook.taxRate
      );
      this.selectedSupplier = null;
      this.code = "";
      this.referDocument = "";
      this.date = new Date();
      this.dueDate = new Date();
      this.vatSendDate = new Date();
      this.creditTerm = 0;
      this.selectedSupplier = null;
      this.remark1 = "";
      this.remark2 = "";
      this.details = [];
      this.totalAmount = 0;
      this.discount = "";
      this.discountAmount = 0;
      this.actualPayAmount = 0;
      this.totalAmountBeforeTax = 0;
      // this.tax = 0;
      this.taxAmount = 0;
      this.netAmount = 0;
      this.currency = "";

      this.showFormPrintModal = false;
      this.path = "";
      this.employeeError = false;
      this.supplierError = false;
      this.taxError = false;
      this.discountAmountError = false;
      this.isSetData = true;
      
      this.calculateTaxFromSystem = 0.00;
      this.isTaxAmountDisabled = true;

      await this.$store.dispatch("apPurchaseOrder/resetSelectedPurchaseOrder");

      this.addEmptyRow();
    },
    addEmptyRow() {
      //   console.log("this.details",this.details);
      this.details.push({
        id: "dummy" + (parseInt(this.details.length) + 1),
        headerId: null,
        itemNo: null,
        productId: null,
        productCode: null,
        productName: null,
        productUnitId: null,
        productUnitCode: null,
        productUnitName: null,
        quantity: null,
        unitPrice: null,
        discount: null,
        discountAmount: null,
        totalAmount: null,
        remark: null,
        stockEffect: null,
        referDocumentId: null,
      });
    },
    setData() {
      this.isSetData = false;
      // this.getRoleByDocumentBook();
      this.code = this.purchaseOrder.code;
      this.referDocument = this.purchaseOrder.referDocument;
      this.date = new Date(this.purchaseOrder.date);
      this.dueDate = new Date(this.purchaseOrder.dueDate);
      this.vatSendDate = new Date(this.purchaseOrder.vatSendDate);
      this.remark1 = this.purchaseOrder.remark1;
      this.remark2 = this.purchaseOrder.remark2;
      this.details = this.purchaseOrder.purchaseOrderDetails;
      this.currency =
        this.$store.getters.selectedLanguage === "TH"
          ? this.purchaseOrder.currencyName
          : this.purchaseOrder.currencyCode;
      this.totalAmount = this.purchaseOrder.totalAmount;
      this.discount = this.purchaseOrder.discount;
      this.discountAmount = this.purchaseOrder.discountAmount;
      this.actualPayAmount = this.purchaseOrder.actualPayAmount;
      this.totalAmountBeforeTax = 0;
      this.tax = this.purchaseOrder.vatRate;
      this.taxAmount = this.purchaseOrder.vatAmount;
      this.netAmount = this.purchaseOrder.netAmount;

      this.selectedSupplier = this.sharedSuppliers.find(
        (supplier) => supplier.id === this.purchaseOrder.supplierId
      );
      this.selectedEmployee = this.sharedEmployees.find(
        (employee) => employee.id === this.purchaseOrder.employeeId
      );
      this.selectedTax = this.sharedTaxes.find(
        (tax) => tax.taxRate === this.purchaseOrder.vatRate
      );

      if (this.vatInclude === "Y") {
        this.totalAmountBeforeTax = this.netAmount - this.taxAmount;
      } else {
        this.totalAmountBeforeTax = this.totalAmount - this.discountAmount;
      }

      this.setCreditTerm();
      this.addEmptyRow();
      this.updateItemNumber();

      this.calculateNetAmount();
      this.setNetAmount(null);
      this.setLoading(false);
    },
    setCreditTerm() {
      //   console.log("this.date=", this.date);
      //   console.log("this.dueDate=", this.dueDate);
      if (this.date === null) this.date = new Date();
      if (this.dueDate === null) this.dueDate = new Date();
      const utc1 = Date.UTC(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate()
      );
      const utc2 = Date.UTC(
        this.dueDate.getFullYear(),
        this.dueDate.getMonth(),
        this.dueDate.getDate()
      );
      //   console.log("utc1=",utc1);
      //   console.log("utc2=",utc2);
      let creditTerm = Math.floor((utc2 - utc1) / (1000 * 3600 * 24));
      this.creditTerm = creditTerm;
    },
    createDocument() {
      this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForCreateNewDocument}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.cancel,
        allowOutsideClick: false,
      }).then((result) => {
        //callback to execute when user confirms the action
        if (result.isConfirmed) {
          if (!this.isCreateDocument) {
            this.$router.replace({ name: "ap-purchase-order-edit", params: { apBookId: this.apBookId,id: "create" }});
          }
          this.initiateData();
        } else if (result.isDenied) {
          //
        }
      });
    },
    async save() {
      this.setLoading(true);
      if (this.validateData()) {
        this.setLoading(false);
        return;
      }
      if (this.validateRoles()) {
        this.setLoading(false);
        return;
      }

      try {
        // console.log('save',this.payload);
        await this.$store.dispatch(
          "apPurchaseOrder/addUpdatePurchaseOrder",
          this.payload
        );
        this.$swal({
          icon: "success",
          title: this.lang.saveSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        if (this.isCreateDocument) {
          this.$router.replace({ name: "ap-purchase-order-edit", params: { apBookId: this.apBookId,id: this.purchaseOrder.id }});
        }
        this.isTaxAmountDisabled = true;
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
    },
    async askForSaveAs() {
      if (
        this.roleByDocumentBook.length > 0 &&
        this.roleByDocumentBook[0].insert === "N" &&
        this.isCreateDocument
      ) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateRoleInsert,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return;
      }
      // if (this.validateRoles()) {
      //   return;
      // }
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForSaveAs}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        await this.saveAs();
      } else if (result.isDenied) {
        //
      }
    },
    async saveAs() {
      this.setLoading(true);
      if (this.validateData()) {
        this.setLoading(false);
        return;
      }
      // console.log("save_payload=",this.payload);

      let details = [];
      for (const each of this.details.filter((each) => each.productId)) {
        let detail = null;
        if (each.id.includes("dummy")) {
          detail = { ...each, id: null, referDocumentId: null };
        } else {
          detail = { ...each, id: null, referDocumentId: null };
        }
        details.push(detail);
      }

      let payload = {
        projectId: this.selectedProject.id,
        documentBookId: this.selectedBookId,
        referDocument: "",
        date: this.formatDateToISOString(this.date),
        dueDate: this.formatDateToISOString(this.dueDate),
        vatSendDate: this.formatDateToISOString(this.vatSendDate),
        actualPayAmount: this.actualPayAmount,
        totalAmount: this.totalAmount,
        discount: this.discount,
        discountAmount: this.discountAmount,
        vatAmount: this.taxAmount,
        netAmount: this.netAmount,
        vatRate: this.tax,
        remark1: this.remark1,
        remark2: this.remark2,
        supplierId: this.selectedSupplier.id,
        employeeId: this.selectedEmployee.id,
        purchaseOrderDetails: details,
        type: "Add",
      };

      try {
        // console.log('payload',payload);
        await this.$store.dispatch(
          "apPurchaseOrder/addUpdatePurchaseOrder",
          payload
        );
        this.$swal({
          icon: "success",
          title: this.lang.saveAsSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        this.$router.replace({ name: "ap-purchase-order-edit", params: { apBookId: this.apBookId,id: this.purchaseOrder.id }});
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
    },
    async askForCancel() {

      if (
        this.roleByDocumentBook.length > 0 &&
        this.roleByDocumentBook[0].cancel === "N" &&
        !this.isCreateDocument
      ) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateRoleCancel,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return;
      }
      // if (this.validateRoles()) {
      //   return;
      // }
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForCancelDocument}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        await this.cancelPurchaseOrder();
      } else if (result.isDenied) {
        //
      }
    },
    async cancelPurchaseOrder() {
      this.setLoading(true);

      let requestPO = {
        ProjectId: this.selectedProject.id,
        Id: this.id,
      };

      // console.log("requestPO=", requestPO);
      try {
        await this.$store.dispatch(
          "apPurchaseOrder/cancelPurchaseOrder",
          requestPO
        );
        this.$swal({
          icon: "success",
          title: this.lang.cancelSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
    },
    async askForApprove() {
      if (
        this.roleByDocumentBook.length > 0 &&
        this.roleByDocumentBook[0].approve === "N" &&
        !this.isCreateDocument
      ) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateRoleApprove,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return;
      }
      // if (this.validateRoles()) {
      //   return;
      // }
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}"> ${this.lang.askForApproveDocument} </br> ${this.code}   </p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        await this.approvePurchaseOrder();
      }
    },
    async approvePurchaseOrder() {
      this.setLoading(true);

      let requestPO = {
        ProjectId: this.selectedProject.id,
        Id: this.id,
      };

      // console.log("requestPO=", requestPO);
      try {
        await this.$store.dispatch(
          "apPurchaseOrder/approvePurchaseOrder",
          requestPO
        );
        this.$swal({
          icon: "success",
          title: this.lang.approveSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
    },
    async askForClose() {
      if (
        this.roleByDocumentBook.length > 0 && 
        (this.roleByDocumentBook[0].insert === "N" && 
         this.roleByDocumentBook[0].update === "N" && 
         this.roleByDocumentBook[0].approve === "N") &&
        !this.isCreateDocument ) 
      {
        this.$swal({
          icon: "warning",
          title: this.lang.validateRoleClose,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return;
      }

      // if (this.validateRoles()) {
      //   return;
      // }
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForClosePO}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        await this.closePO();
      } else if (result.isDenied) {
        //
      }
    },
    async closePO() {
      this.setLoading(true);
      let payload = {
        id: this.id,
        projectId: this.selectedProject.id,
      };

      try {
        await this.$store.dispatch(
          "apPurchaseOrder/closePurchaseOrder",
          payload
        );

        this.$swal({
          icon: "success",
          title: this.lang.closePOSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
    },
    async selectPrintForm() {
      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: this.selectedBookCode,
      };
      // console.log('selectPrintForm', where);
      const payload = { where };
      await this.getFormPrints(payload);
      
      if(this.filteredFormPrints.length == 1) {
        this.path = this.filteredFormPrints[0].path;
        this.printPurchaseOrder();
      }else{
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path;
      this.printPurchaseOrder();
      this.showFormPrintModal = false;
    },
    async printPurchaseOrder() {
      this.setLoading(true);
      const purchaseOrder = {
        id: this.id,
        path: this.path,
      };

      try {
        await this.$store.dispatch(
          "apPurchaseOrder/printPurchaseOrder",
          purchaseOrder
        );
      } catch (error) {
        // console.log("error=", error);
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        this.setLoading(false);
      }
      let blob = this.$store.getters["apPurchaseOrder/blob"];
      this.getPreviewBlob(blob);
      this.setLoading(false);
    },
    validateData() {
      if (!this.selectedSupplier) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoSupplierSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.supplierError = true;
          }
        });
        return true;
      }

      if (!this.selectedEmployee) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoEmployeeSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.employeeError = true;
          }
        });
        return true;
      }

      if (!this.selectedTax) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoTaxSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.taxError = true;
          }
        });
        return true;
      }

      if (this.details.length <= 1) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoDetailSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      if (this.totalAmount <= 0) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateTotalAmount,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      if (this.discount) {
        let isText = false;
        const keysAllowed = ["0","1","2","3","4","5","6","7","8","9",".","%"];
        for (let i = 0; i < this.discount.length; i++) {
          if (!keysAllowed.includes(this.discount[i])) {
            isText = true;
            break;
          }
        }
        if (isText) {
          this.$swal({
            icon: "warning",
            title: this.lang.validateCheckDiscount,
            confirmButtonText: this.lang.close,
            allowOutsideClick: false,
          });
          return true;
        }
      }

      if(this.netAmount < 0){
        this.$swal({
          icon: "warning",
          title: this.lang.validateDiscountAmount,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.discountAmountError = true;
          }
        });
        return true;
      }
      return false;
    },
    onCellEditComplete(event) {
      // console.log("onCellEditComplete=", event);
      if (!this.isCheckDisabled) {
        if (event.field === "discount") {
          this.calculateDetail(
            event.data,
            event.data.unitPrice,
            event.data.quantity,
            this.discountText
          );
        }
        let value = event.value;
        let newValue = event.newValue;
        if (typeof value === "string") {
          value = value.trim();
        }
        if (typeof newValue === "string") {
          newValue = newValue.trim();
        }
        if (value === newValue && event.field !== "remark") {
          return;
        }

        if (event.field === "unitPrice") {
          if (
            event.data.referDocumentId != null &&
            event.data.referDocumentId.trim()
          ) {
            if (value != newValue) {
              this.$swal({
                icon: "warning",
                title: this.lang.validateUpdatePrice,
                confirmButtonText: this.lang.close,
                allowOutsideClick: false,
              });
              return;
            }
          }

          if (newValue < 0 || !newValue) {
            newValue = 0;
            event.data.totalAmount = 0;
          }
          // else {
          this.calculateDetail(
            event.data,
            newValue,
            event.data.quantity,
            event.data.discount
          );
          //this.calculateDetailUnitPrice(event.data,newValue)
          // }
        } else if (event.field === "quantity") {
          if (newValue < 1 || !newValue) {
            newValue = 1;
            this.calculateDetail(
              event.data,
              event.data.unitPrice,
              newValue,
              event.data.discount
            );
          } else {
            if (event.data.referDocumentId) {
              if (newValue > event.data.sourceQuantity) {
                newValue = event.data.sourceQuantity;
              }
            }

            this.calculateDetail(
              event.data,
              event.data.unitPrice,
              newValue,
              event.data.discount
            );
          }
          // this.calculateDetailQuantity(event.data,newValue)
        } else if (event.field === "productCode") {
          //หาว่า Code ที่ระบุมีอยู่จริงหรือไม่
          // console.log("productCode=",newValue);
          const product = this.getProductByCode(newValue);
          //หากมี ก็ update ข้อมูลใน record นั้น
          if (product) {
            if (newValue === "") {
              return;
            }
            if (!value) {
              // console.log("!value");
              this.productSelect(event.data, product);
            } else if (value !== newValue) {
              this.$confirm.require({
                message: this.lang.askForProductChange,
                header: this.lang.confirmDialogHeader,
                icon: "pi pi-exclamation-triangle",
                accept: () => {
                  //callback to execute when user confirms the action
                  this.productSelect(event.data, product);
                },
              });
            }
            // console.log("this.$refs=",this.$refs);
            // this.$refs.unitPrice0.focus();
            // this.$refs["field-" + (this.fields.length - 1)][0].$el.focus()
          } else {
            // console.log("ไม่มีcode!!!", newValue);
            this.$confirm.require({
              message: this.lang.askForSelectProduct.replace(":code", newValue),
              header: this.lang.confirmDialogHeader,
              icon: "pi pi-exclamation-triangle",
              accept: () => {
                //callback to execute when user confirms the action
                this.editingDetail = event.data;
                this.searchText = newValue;
                this.showProductModal = true;
              },
            });
          }
        } else if (event.field === "remark") {
          if(event.type == "enter" && event.originalEvent.shiftKey) {
            event.preventDefault();
          }
          event.data.remark = newValue;
        }
      }
    },
    productModalSelected(product) {
      this.productSelect(this.editingDetail, product);
      this.showProductModal = false;
    },
    productSelect(detail, product) {
      // console.log('product',product);
      detail.headerId = null;
      detail.itemNo = detail.itemNo || this.lastItemNo + 1;
      detail.productId = product.id;
      detail.productCode = product.code;
      detail.productName = product.nameThai;
      detail.productUnitId = product.unitId;
      detail.productUnitCode = product.unitCode;
      detail.productUnitName = product.unitName;
      detail.quantity = 1;
      detail.unitPrice = product.price;
      detail.discount = "";
      detail.discountAmount = 0;
      detail.totalAmount = detail.unitPrice * detail.quantity;
      detail.remark = "";
      detail.stockEffect = null;
      detail.referDocumentId = null;

      this.addRow(detail);
      //หาว่ามีบรรทัดที่ รหัสเป็น null หรือยัง (บรรทัดว่าง)
      const emptyRow = this.details.find((detail) => !detail.productCode);
      if (!emptyRow) {
        //ถ้ายังไม่มีให้เพิ่มไว้
        this.addEmptyRow();
        // this.updateItemNumber();
      }
      this.updateItemNumber();
      this.updateTotalAmount();
      
      this.calculateNetAmount();
      this.setTaxAmountFromSystem();
    },
    addRow(detail) {
      //let total = 0;
      this.details.forEach((element) => {
        if (element.id === detail.id) {
          element = { ...element, ...detail };
        }
        //total += element.productId ? element.totalAmount : 0;
      });
      //this.totalAmount = total;
    },
    deleteRow(id) {
      const index = this.details.findIndex((detail) => detail.id === id);
      this.details.splice(index, 1);

      this.updateTotalAmount();
      this.updateItemNumber();
      
      this.calculateNetAmount();
      this.setTaxAmountFromSystem();
    },
    updateItemNumber() {
      let number = 1;
      this.details.forEach((element) => {
        if (element.productCode) {
          element.itemNo = number;
        }
        number++;
      });
    },
    updateTotalAmount() {
      let totalAmount = 0;
      // console.log('updateTotalAmount this.details =',this.details);
      this.details.forEach((detail) => {
        // console.log('updateTotalAmount',detail);
        totalAmount += detail.totalAmount;
      });
      this.totalAmount = totalAmount;
    },
    calculateNetAmount() {
      if (this.discount) {
        if (this.discount.includes("%")) {
          this.discountAmount =
            (parseFloat(this.discount) / 100) * this.totalAmount;
        } else {
          this.discountAmount = parseFloat(this.discount);
        }
        // console.log("this.vatInclude=",this.vatInclude);
        if (this.vatInclude === "Y") {
          this.netAmount = this.totalAmount - this.discountAmount;
          this.calculateTaxFromSystem = (this.netAmount * this.tax) / 107;
          this.totalAmountBeforeTax = this.netAmount - this.calculateTaxFromSystem;
        } else {
          this.totalAmountBeforeTax = this.totalAmount - this.discountAmount;
          this.calculateTaxFromSystem = (this.tax / 100) * this.totalAmountBeforeTax;
          this.netAmount =
            this.totalAmount - this.discountAmount + this.calculateTaxFromSystem;
        }
      } else {
        this.discountAmount = 0;
        if (this.vatInclude === "Y") {
          this.netAmount = this.totalAmount - this.discountAmount;
          this.calculateTaxFromSystem = (this.netAmount * this.tax) / 107;
          this.totalAmountBeforeTax = this.netAmount - this.calculateTaxFromSystem;
        } else {
          this.totalAmountBeforeTax = this.totalAmount - this.discountAmount;
          this.calculateTaxFromSystem = (this.tax / 100) * this.totalAmountBeforeTax;
          this.netAmount =
            this.totalAmount - this.discountAmount + this.calculateTaxFromSystem;
        }
      }
    },
    calculateDetail(detail, unitPrice, quantity, discount) {
      detail.unitPrice = unitPrice;
      detail.quantity = quantity;
      detail.discount = discount;
      if (!discount) discount = "0";
      if (discount.includes("%")) {
        let totalAmt = unitPrice * quantity;
        detail.discountAmount = (parseFloat(discount) / 100) * totalAmt;
        detail.totalAmount =
          unitPrice * quantity - (parseFloat(discount) / 100) * totalAmt;
      } else {
        detail.discountAmount = parseFloat(discount);
        detail.totalAmount = unitPrice * quantity - parseFloat(discount);
      }
      if (detail.totalAmount < 0) {
        detail.discount = "";
        detail.discountAmount = 0;
        detail.totalAmount = unitPrice * quantity;
      }
      this.updateItemNumber();
      this.updateTotalAmount();
      
      this.calculateNetAmount();
      this.setTaxAmountFromSystem();
    },
    async importPR() {
      if (!this.selectedSupplier) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoSupplierSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.supplierError = true;
          }
        });
        return true;
      }
      this.setLoading(true);

      let where = "";
      // if (this.selectedSupplier == null) {
      //   where = {
      //     projectId: this.selectedProject.id,
      //     approveStatus: "P",
      //     cancelStatus: "!C",
      //     paidStatus: "!F,!C",
      //   };
      // }
      // else{
      where = {
        projectId: this.selectedProject.id,
        supplierId: this.selectedSupplier.id,
        approveStatus: "P",
        cancelStatus: "!C",
        paidStatus: "!F,!C",
      };
      // }
      // console.log('where',JSON.stringify(where));
      const payload = { where };
      try {
        await this.$store.dispatch(
          "apPurchaseOrder/getPurchaseRequisitiontoPOs",
          payload
        );
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
      this.showPurchaseRequisition = true;
    },
    selectedPurchaseRequisition(data) {
      // console.log("selectedPurchaseRequisition=", data);

      if (!this.selectedSupplier) {
        this.selectedSupplier = this.sharedSuppliers.find(
          (supplier) => supplier.id === data.supplierId
        );
      }
      this.discount = data.discount;
      this.discountTotalAmount = data.discountAmount;
      this.actualPayAmount = 0;
      this.tax = this.selectedTax.taxRate;
      this.referDocument = this.referDocument + ", PRPR/" + data.code;

      let i = this.details.length-1;
      data.purchaseRequisitionDetails.forEach((e) => {
        let detail = null;
        detail = this.details.find((x) => x.referDocumentId === e.id);
        // console.log('prDetail',detail);
        if (
          detail == null &&
          (e.dlvrQuantity == 0 || e.dlvrQuantity < e.quantity)
        ) {
          let pr = {
            // id: e.id,
            id: "dummy" + (parseInt(this.details.length)),
            headerId: null,
            itemNo: 1,
            productId: e.productId,
            productCode: e.productCode.trim(),
            productName: e.productName,
            productUnitId: e.productUnitId,
            productUnitCode: e.productUnitCode,
            productUnitName: e.productUnitName,
            quantity: e.quantity - e.dlvrQuantity,
            unitPrice: e.unitPrice,
            discount: "",
            discountAmount: e.discountAmount,
            totalAmount: e.unitPrice * (e.quantity - e.dlvrQuantity),
            remark: e.remark,
            stockEffect: null,
            referDocumentId: e.id,
            dlvrQuantity: e.dlvrQuantity,
            sourceQuantity: e.quantity - e.dlvrQuantity,
          };
          this.details[i]=pr;
          i++;
          this.addEmptyRow();
          // this.details.unshift(pr);
        } else if (
          detail != null &&
          (e.dlvrQuantity == 0 || e.dlvrQuantity < e.quantity)
        ) {
          detail.quantity =
            (detail ? (detail.oldQuantity ? detail.oldQuantity : 0) : 0) +
            e.quantity -
            e.dlvrQuantity;
          detail.dlvrQuantity = e.quantity - e.dlvrQuantity;
          detail.sourceQuantity = e.quantity - e.dlvrQuantity;
          detail.discount = "";
          detail.totalAmount = detail.unitPrice * detail.quantity;
          detail.remark = e.remark;
        }
      });
      
      // const emptyRow = this.details.find((detail) => !detail.productCode);
      // if (!emptyRow) {
      //   //ถ้ายังไม่มีให้เพิ่มไว้
      //   this.addEmptyRow();
      // }
      this.updateItemNumber();
      this.updateTotalAmount();
      
      this.calculateNetAmount();
      this.setTaxAmountFromSystem();
      // console.log('this.details',this.details);
    },
    async getRoleByDocumentBook() {
      let where = {
        projectId: this.selectedProject.id,
        documentBookId: this.apBookId,
      };

      const payload = {
        page: 1,
        limit: 100,
        where,
      };

      await this.$store.dispatch(
        "roleRoleByDocumentBook/getRoleByDocumentBooks",
        payload
      );
    },
    async askForRevertApprove() {
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForRevertApprove}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        await this.revertApprove();
      } else if (result.isDenied) {
        //
      }
    },
    async revertApprove() {
      this.setLoading(true);
      let payload = {
        id: this.id,
        projectId: this.selectedProject.id,
      };
      try {
        await this.$store.dispatch(
          "apPurchaseOrder/revertApprovePurchaseOrder",
          payload
        );

        this.$swal({
          icon: "success",
          title: this.lang.revertApproveSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
    },
    validateRoles() {
      if (
        this.roleByDocumentBook.length > 0 &&
        this.roleByDocumentBook[0].insert === "N" &&
        this.isCreateDocument

      ) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateRoleInsert,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      if (
        this.roleByDocumentBook.length > 0 &&
        this.roleByDocumentBook[0].update === "N" &&
        !this.isCreateDocument
      ) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateRoleUpdate,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      // if (
      //   this.roleByDocumentBook.length > 0 &&
      //   this.roleByDocumentBook[0].cancel === "N" &&
      //   !this.isCreateDocument
      // ) {
      //   this.$swal({
      //     icon: "warning",
      //     title: this.lang.validateRoleCancel,
      //     confirmButtonText: this.lang.close,
      //     allowOutsideClick: false,
      //   });
      //   return true;
      // }

      // if (
      //   this.roleByDocumentBook.length > 0 &&
      //   this.roleByDocumentBook[0].approve === "N" &&
      //   !this.isCreateDocument
      // ) {
      //   this.$swal({
      //     icon: "warning",
      //     title: this.lang.validateRoleApprove,
      //     confirmButtonText: this.lang.close,
      //     allowOutsideClick: false,
      //   });
      //   return true;
      // }
    },
    setDiscountColumnText(data) { 
      this.discountText = data
    },
    setDiscountText(data) { 
      this.discount = data;
      this.editDiscount();
    },
    checkInputDiscount(event) {
      // console.log("event=",event);
      const keysAllowed = ["0","1","2","3","4","5","6","7","8","9",".","%"];
      const keyPressed = event.key;
      if (!keysAllowed.includes(keyPressed)) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateCheckDiscount,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      } 
      // else {
      //   this.calculateNetAmount();
      // }
    },    
    doubleClickTaxAmount(){
      if(!this.isCheckDisabled && this.isTaxAmountDisabled && this.tax != 0){
        document.getElementById('taxAmount').value = "";
        this.isTaxAmountDisabled = false;
        // this.taxAmount = 0;
      }
    },
    editDiscount(){   
      this.calculateNetAmount();
      this.setTaxAmountFromSystem();      
    },
    selectTax(){
      this.tax = this.selectedTax.taxRate;
      this.calculateNetAmount();
      this.taxError = false;
      this.setTaxAmountFromSystem();
      if(this.tax == 0){
        this.isTaxAmountDisabled = true;
      }
    },
    setTaxAmountFromSystem(){
      this.taxAmount = parseFloat(this.calculateTaxFromSystem.toFixed(2));
      this.setNetAmount(null);
    },
    setNetAmount(event){
      // console.log("setNetAmount_event=",event);
      let taxAmount = this.taxAmount;      
      if(event !== null){
        taxAmount = event.target.value.replaceAll(",","");
      }  

      if (this.vatInclude === "Y") {
        this.netAmount = this.totalAmount - this.discountAmount;
      } else {
        this.netAmount = this.totalAmount - this.discountAmount + parseFloat(taxAmount);
      }    

      if(this.netAmount >= 0) this.discountAmountError = false;  
    },    
    dateSelected(){
      let day = this.creditTerm;
      this.dueDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate() + day,
        0
      );
      this.setCreditTerm();
    },
    editCreditTerm(event) {
      let creditTerm = parseFloat(event.target.value);
      let newValue = 0;
      if(creditTerm > 0)
      { 
        newValue = creditTerm;
      }
      this.dueDate = new Date(this.date.getFullYear(), this.date.getMonth(),this.date.getDate() + newValue,0);
    },
    selectSupplier() {      
      if(this.selectedSupplier){ 
        this.creditTerm = this.selectedSupplier.supplierCreditTerm;
      }
      this.supplierError = false;   
    },
  },
  computed: {
    payload() {
      let details = [];

      for (const each of this.details.filter((each) => each.productId)) {
        let detail = null;
        if (each.id.includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        details.push(detail);
      }

      let payload = {
        projectId: this.selectedProject.id,
        documentBookId: this.selectedBookId,
        referDocument: this.referDocument,
        date: this.formatDateToISOString(this.date),
        dueDate: this.formatDateToISOString(this.dueDate),
        vatSendDate: this.formatDateToISOString(this.vatSendDate),
        actualPayAmount: this.actualPayAmount,
        totalAmount: this.totalAmount,
        discount: this.discount,
        discountAmount: this.discountAmount,
        vatAmount: this.taxAmount,
        netAmount: this.netAmount,
        vatRate: this.tax,
        remark1: this.remark1,
        remark2: this.remark2,
        supplierId: this.selectedSupplier.id,
        employeeId: this.selectedEmployee.id,
        purchaseOrderDetails: details,
        type: "Add",
      };

      if (!this.isCreateDocument) {
        payload = { ...payload, id: this.id, type: "Update" };
      }

      return payload;
    },
    isCreateDocument() {
      return this.id === "create";
    },
    title() {
      let result = this.lang.editOrder + " : " + this.selectedBookCode;
      if (this.isCreateDocument) {
        result = this.lang.createOrder + " : " + this.selectedBookCode;
      }
      return result;
    },
    parameterButtonText() {
      return this.isParameterVisible
        ? this.lang.hideParameters
        : this.lang.showParameters;
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    selectedUserName() {
      return this.$store.getters.employee;
    },
    totalPage() {
      return this.$store.getters["apPurchaseOrder/totalPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    purchaseOrder() {
      return this.$store.getters["apPurchaseOrder/selectedPurchaseOrder"];
    },
    sharedSuppliers() {
      return this.$store.getters["shared/supplierSuppliers"];
    },
    sharedEmployees() {
      return this.$store.getters["shared/employeeEmployees"];
    },
    sharedTaxes() {
      return this.$store.getters["shared/taxTaxes"];
    },
    selectedDate() {
      let date = null;
      if (this.date) {
        date = this.formatDateToISOString(this.date);
      } else {
        date = this.formatDateToISOString(new Date());
      }
      return date;
    },
    selectedVatSendDate() {
      let vatSendDate = null;
      if (this.vatSendDate) {
        vatSendDate = this.formatDateToISOString(this.vatSendDate);
      } else {
        vatSendDate = this.formatDateToISOString(new Date());
      }
      return vatSendDate;
    },
    isCheckDisabled() {
      let isCheck = false;
      if (this.purchaseOrder && this.purchaseOrder.approveStatus === "P")
        isCheck = true;
      if (this.purchaseOrder && this.purchaseOrder.cancelStatus === "C")
        isCheck = true;
      if (
        this.purchaseOrder &&
        (this.purchaseOrder.paidStatus === "F" ||
          this.purchaseOrder.paidStatus === "D" ||
          this.purchaseOrder.paidStatus === "C")
      )
        isCheck = true;
      return isCheck;
    },
    isCheckCloseDisabled() {
      let isCheck = false;
      if (this.purchaseOrder && this.purchaseOrder.cancelStatus === "C")
        isCheck = true;
      if (
        this.purchaseOrder &&
        (this.purchaseOrder.paidStatus === "F" ||
          this.purchaseOrder.paidStatus === "D" ||
          this.purchaseOrder.paidStatus === "C")
      )
        isCheck = true;
      return isCheck;
    },
    lastItemNo() {
      const itemNo = Math.max.apply(
        Math,
        this.details.map(function (i) {
          return i.itemNo;
        })
      );
      return itemNo;
    },
    isCheckSaveAs() {
      let isCheck = false;
      if (!this.code) isCheck = true;
      return isCheck;
    },
    roleByDocumentBook() {
      return this.$store.getters["roleRoleByDocumentBook/roles"];
    },
    isCheckRoleApprove() {
      let isCheck = false;

      if (this.roleByDocumentBook.length > 0) {
        // console.log(this.roleByDocumentBook);
        if (this.roleByDocumentBook[0].approve === "Y") isCheck = true;
      }

      return isCheck;
    },
    isApproved() {
      let isCheck = false;
      if (this.purchaseOrder && this.purchaseOrder.approveStatus != "P")
        isCheck = true;
      if (this.purchaseOrder && this.purchaseOrder.cancelStatus === "C")
        isCheck = true;
      if (
        this.purchaseOrder &&
        (this.purchaseOrder.paidStatus === "F" ||
          this.purchaseOrder.paidStatus === "D" ||
          this.purchaseOrder.paidStatus === "C")
      )
        isCheck = true;
      return isCheck;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    storageName() {
      return `erp_search_PurchaseOrderList_${this.selectedProject.code}_${this.userCode}`;
    },    
    classDisableTaxAmount() {
      let setClass = "text-right ";
      if (this.isTaxAmountDisabled) {
        setClass = "text-right input-number-disable";
      }
      return setClass;
    },
  },
  watch: {
    purchaseOrder() {
      this.checkShouldSetData();
    },
    isSharedSupplierReady() {
      this.checkShouldSetData();
    },
    isSharedEmployeeReady() {
      this.checkShouldSetData();
    },
    isSharedTaxReady() {
      this.checkShouldSetData();
    },
  },
};
</script>
