<template>
  <markup-select-dialog
    v-model:visible="showMarkupSelectDialog"
    @close="showMarkupSelectDialog = false"
    @markup-selected="markupSelected"
  ></markup-select-dialog>
  <div class="flex justify-content-between">
    <label for="inputText">{{ label }}</label>
    <small style="color: #bebebe" class="mr-0">{{ `${countText}/${limit}` }}</small>
  </div>
  <div
    id="toolbar"
    class="p-editor-toolbar ql-toolbar ql-snow mt-1"
    :class="{ 'toolbar-invalid': isError, 'toolbar-container': !isError }"
  >
    <span class="ql-formats">
      <div class="flex align-items-center justify-content-center cursor-pointer" @click="openMarkupDialog()">
        <icon-plus-add iconColor="secondary" height="14" width="14" />
        <small for="downloadfile" class="p-text-secondary ml-1">{{ lang.labelMarkup }} </small>
      </div>
    </span>
  </div>
  <div
    :id="fieldName"
    class="p-editor-content ql-container ql-snow"
    :class="{ 'editor-invalid': isError, 'editor-container': !isError }"
    :style="{ height: `${this.height}px` }"
  ></div>
</template>
<script>
import Quill from "quill";
import useLang from "../../../hooks/lang.js";
import { useStore } from "vuex";
import { computed } from "vue";
import MarkupSelectDialog from "../../../components/AR/notice/MarkupSelectDialog.vue";

export default {
  components: {
    MarkupSelectDialog,
  },
  props: {
    fieldName: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "52",
    },
    defaultValue: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    limit: {
      type: Number,
      default: 0,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["editor-change"],
  setup() {
    const { lang } = useLang();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.notice.customReportEdit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.notice.customReportEdit,
        };
      }
    });

    return {
      lang: mergeLang,
    };
  },
  data() {
    return {
      showMarkupSelectDialog: false,
      quill: null,
      options: {
        modules: {
          toolbar: "#toolbar",
        },
        theme: "snow",
      },
      indexCursor: 0,
      lengthCursor: 0,
      rawTextData: "",
      htmlTextData: "",
      countText: 0,
    };
  },
  mounted() {
    this.createQuill();
  },
  methods: {
    openMarkupDialog() {
      this.showMarkupSelectDialog = true;
    },
    markupSelected(data) {
      if (this.lengthCursor > 0) {
        this.quill.deleteText(this.indexCursor, this.lengthCursor + 1);
      }
      if (this.indexCursor == 0) {
        if (this.quill.root.innerText) {
          this.quill.root.innerText = data.value + this.quill.root.innerText;
        } else {
          this.quill.root.innerText = data.value;
        }
      } else {
        this.quill.insertText(this.indexCursor, data.value);
      }

      let newCursor = this.indexCursor + data.value.length;
      this.quill.setSelection(newCursor, 0);
      this.showMarkupSelectDialog = false;
    },
    createQuill() {
      this.quill = new Quill(`#${this.fieldName}`, this.options);
      this.quill.on("text-change", this.setTextChange);
      this.quill.on("selection-change", this.setDataCursor);
      if (this.defaultValue) {
        this.setDefaultValue();
      }
    },
    setDataCursor(range, oldRange) {
      if (range) {
        this.indexCursor = range.index + 1;
        this.lengthCursor = range.length;
      } else if (oldRange) {
        this.indexCursor = oldRange.index;
        this.lengthCursor = oldRange.length - 1;
      }
    },
    setTextChange() {
      this.rawTextData = this.quill.getText();
      this.htmlTextData = this.quill.getContents().ops[0].insert;
      this.countText = this.quill.getLength() - 1;
      if (this.countText > this.limit) {
        this.quill.deleteText(this.limit - 1, 1);
      }
      this.$emit("editor-change", this.rawTextData.replaceAll("\n", ""), this.htmlTextData, this.fieldName);
    },
    setDefaultValue() {
      this.quill.root.innerText = this.defaultValue;
    },
  },
  // watch: {
  //   defaultValue(curValue) {
  //     if (curValue) {
  //       this.setDefaultValue();
  //     }
  //   },
  // },
};
</script>
<style scoped>
.editor-container {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border: 1px solid #dee2e6 !important;
}
.toolbar-container {
  background: #f8f9fa;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  border: 1px solid #dee2e6 !important;
}
.editor-invalid {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border: 1px solid #f03737 !important;
  border-top: 1px solid #dee2e6 !important;
}
.toolbar-invalid {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  border: 1px solid #f03737 !important;
  border-bottom: 1px solid #dee2e6 !important;
}
</style>
