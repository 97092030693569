export default {
	title: "Report",
	searchReport: "Search Report",
	reportNotfound: "Sorry, something went wrong. Please try again later.",
	viewhReportButton: "View Report",
	titleReportPettyCash: "Report PettyCash",
	approveAll: "Approve All",
	glBookCode: "Book",
	reportType: "Report",
	downloadPDF: "Download PDF",
	downloadExcel: "Download Excel",
	selectGlBook: "Select Book",
	titleAccountFrequentlyReport: "AccountFrequently Report",
	titleAccountReport: "Chart Of Account Report",
	accountCodeFrom: "Account chart from",
	to: "To",
	validateGlBookSelect: "Please select book",
	validateAccountSelect: "Please select account code",
	ledgerAcoountReport: "Ledger Acoount Report",
	descriptionPrint: "พิมพ์รายละเอียด",
	descriptionPrint1: "1) รายละเอียด 1",
	descriptionPrint2: "2) รายละเอียด 2",
	descriptionPrint3: "3) รายละเอียด 1+2",
	descriptionLinePrint: "พิมพ์รายละเอียดหลายบรรทัด",
	yes: "Yes",
	no: "No",
	newAccountNewPage: "เมื่อขึ้นรหัสบัญชีใหม่ให้ขึ้นหน้าใหม่",
	accountMovementPrint: "พิมพ์รายการบัญชีที่",
	accountMovementPrint1: "1) มีการเคลื่อนไหวหรือมียอดยกมา",
	accountMovementPrint2: "2) มีการเคลื่อนไหว",
	statusPrint: "พิมพ์สถานะ",
	statusPrint1: "1) รวมทั้งที่ยังไม่ Post(A) และ Post(P) แล้ว",
	statusPrint2: "2) A: ลงบัญชีแต่ยังไม่ผ่านรายการ (Post)",
	statusPrint3: "3) P: ผ่านรายการ (Post)",
	titleTrialBalance: "งบทดลอง",
	validateDateSelect: "กรุณาเลือกวันที่",
	titleWorksheet: "กระดาษทำการ",

	titleAccountingEntryReport: "รายงานการบันทึกบัญชี",
	validateCodeSelect: "กรุณาเลือกเลขที่",
	codeFrom: "เลขที่ ตั้งแต่",
	codeTo: "ถึง",
	typeReport: "Report type",
	orderReport: "Order by",
	typeSummary: "Summary type",
	typeDetail: "Detail type",
	orderCode: "Sort by code",
	orderDate: "Sort by date",
	askForSelectBook: "Document not found",

	columnSequence: "No.",
	columnName: "Report",

	titleDocumentNoReferenceReport: "Document No Reference Report",
	dateStart: "From Date",
	dateEnd: "To Date",
	dateAt: "At Date",

	titleIncomeAndExpense: "Income /Expense Statement (Detailed)",
	titleIncomeAndExpenseSummary: "Income /Expense Statement (Summary)",
	titleCompareIncomeAndExpense: "Income /Expense Statement (Compare)",
	titleStatementOfFinancialPosition: "Statement of Financial Position (Detailed)",
	titleStatementOfFinancialPositionSummary: "Statement of Financial Position (Summary)",
	titleCompareStatementOfFinancialPosition: "Statement of Financial Position (Compare)",
	type: "Type",
	byMonth: "By Month",
	byYear: "By Year",

	downloadExcelRaw: "Excel Raw",
	dateAtData: "Data At Date",
	monthStart: "From Month",
	monthEnd: "To Month",

	titleAssetWithDetailReport: "Asset With Detail Report",
	assetCodeStart: "Asset Code From",
	assetCodeEnd: "Asset Code To",
	validateAssetCodeSelect: "Please select asset code",
	titleAssetAndDepreciationExpenseReport: "Asset And Depreciation Expense Report",
	assetStatus: "Asset status",
	assetStatus0: "All",
	assetStatus1: "exist",
	assetStatus2: "Cut off",
	accountCodeStart: "Account Code From",
	accountCodeEnd: "Account Code To",
	calculationType: "DepreciationCalculationType",
	calculationType1: "One Time Rounding Off",
	calculationType2: "Daily Rounding Off",

	supplierStart: "From supplier",
	supplierEnd: "To supplier",
	bookCode: "Book",
	reportPortraitOrLandscape: "Report type",
	portrait: "Portrait",
	landscape: "Landscape",
	language: "English",

	titleWithholdingTaxAttachmentPnd: "Attachment P.N.D. (Withholding Tax)",
	taxType: "P.N.D.",
	validateNoTaxTypeSelect: "Please select P.N.D.",
	position: "Position",
	submissionDate: "Submission Date",
	portraitPage: "Portrait",
	landscapePage: "Landscape",
	titleWithholdingTaxCoverPage: "Cover Page P.N.D.",
	payMonth: "Pay Month",
	validatePayMonthSelect: "Please select pay month",
	law: "Law",
	pnd3Law1: "มาตรา 3 เตรส",
	pnd3Law2: "มาตรา 48 ทวิ",
	pnd3Law3: "มาตรา 50 (3) (4) (5)",
	pnd53Law1: "มาตรา 3 เตรส แห่งประมวลรัษฎากร",
	pnd53Law2: "มาตรา 65 จัตวา แห่งประมวลรัษฎากร",
	pnd53Law3: "มาตรา 69 ทวิ แห่งประมวลรัษฎากร",
	taxFilingType: "Tax Filing Type",
	taxFilingType1: "General Submit",
	taxFilingType2: "Additional Submit",
	pageNumber: "Page Number",
	time: "Additional Submit Time",
	titleWithholdingTaxText: "Online Filing (Export Text)",
	downloadText: "Download Text",
	status: "Status",
	bankPayoutStart: "From Bank Payout",
	bankPayoutEnd: "To Bank Payout",
	dateType: "Date Type",
	sortBy: "SortBy",
	validateType: "Please select Type",
	taxDueDateStart: "Tax DueDate Start",
	taxDueDateEnd: "Tax DueDate End",
	validateTaxDueDateSelect: "Please select tax duedate",

	atDate: "At Date",
	annualClosing: "Show Annual Closing",
	invoiceDateStart: "Invoice Date Start",
	unitStart: "Unit Start",
	productStart: "Product Start",
	penaltyStart: "Penalty Start",
	validateNoProductSelect: "Please select product",
	receiptInformation: "Payment",
	receiveDateAt: "Receive Date At",
	receiveAll: "Receive All",
	receiveDate: "Receive Date",
	periodStart: "Period Start",

	creditNoteDateStart: "CreditNote date start",
	accountPeriodStart: "Account period start",

	bookBankNumberStart: "BookBank Start",
	checkedAllCode: "Show All code",
	invoiceCodeStart: "Invoice Code Start",
	cashType: "Cash Type",
	customerStart: "Customer Start",
	validateCustomer: "Please select customer",
	validateBank: "Please select bookbank",
	date: "Document Date",
	dueDate: "Due Date",
	toBankDate: "To Bank Date",
	passDate: "Pass Date",
	returnDate: "Return Date",
	typeRefDoc: "Only without invoice",
	paperType: "Paper Type",
	documentDateStart: "Document Date Start",
	
	showProduct: "Show Product",
	accountStatement: "Account statements",
	receiptableDate: "Receiptable date at",
	ignoreCancelDate: "Ignore cancel date",
    pending: "Pending (Baht)",
    overDueDay:"OverDue (Day)",
	captionCheckboxPrintZero: "Print 0 Baht",
	showDocumentCode: "Show document code",
	showPenalty: "Show penalty",

	createReportToPdf: "Create Report To PDF",
	createReportToExcel: "Create Report To Excel",

	unitReportType: "Report Type",
	unitReportTypeByUnit: "Unit Code",
	unitReportTypeByAddressNo: "Address Number",
	unitReportUnitCodeStart: "Unit Code Start",
	unitReportUnitCodeEnd: "To",
	unitReportBuilding: "Building",
	unitReportBuildingPlaceholder: "Select Building",
	unitReportUnitType: "Unit Type",
	unitReportCustomerType: "Customer Type",
	unitReportAddressType: "Address Type",
	unitReportPlaceholderAll: "All",
	unitReportPlaceholderUnit: "Select Unit",
	unitReportPlaceholderBuilding: "Select Building",
	unitReportUnitType1: "Ownership Transfer - Owner Staying",
	unitReportUnitType2: "Ownership Transfer - Not Stay",
	unitReportUnitType3: "Ownership Transfer - Lease",
	unitReportUnitType4: "Not Be Transferred",
	unitReportUnitType5: "Rent Space/Shop",
	unitReportCustomerType1: "Owner 1",
	unitReportCustomerType2: "Owner 2",
	unitReportCustomerType3: "Owner 3",
	unitReportCustomerType4: "Tenant",
	unitReportAddressType1: "Address From Id Card",
	unitReportAddressType2: "Address For Contract",
	unitReportAddressType3: "Address For Invoice",
	type1: "Type 1",
	type2: "Type 2",
	cancelDate: "Cancel Date",
	receiptDate: "Receipt Date",
	from: "From",
    group:"Group", 
	receiptDateStart: "Receipt Date Start",
	bankDateStart: "Bank Date Start",
	bookbank: "BookBank",
	dear: "Dear",
	signature: "Signature",
	dataType: "Data Type",
    ratio: "Ratio",
    area: "Area",
	fileName: "File Name",

	saveMessageSuccess: "You successfully saved the message.",
	isIgnoreZero: "Show only items with value",

	outstandingAgingByMonthType: "Account display type",
	typeDetailByUnit: "Detail",
	
	budgetYearCurrent: "Budget Year (Current)",
	budgetYearCompare: "Budget Year (Compare)",
	validateCodeStart: "Please enter code start.",
	validateCodeEnd: "Please enter code end.",
};
