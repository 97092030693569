<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.22 10.89V20.89"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M19.78 10.89V20.89"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M14.222 20.889V16.445C14.222 15.218 13.227 14.223 12 14.223C10.773 14.223 9.77802 15.218 9.77802 16.445V20.889"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M2 20.89H22"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.30101 10.889C2.60301 10.877 1.49601 9.196 2.23201 7.747L3.94701 4.37C4.33801 3.6 5.16001 3.111 6.06201 3.111H17.938C18.84 3.111 19.662 3.6 20.053 4.37L21.769 7.747C22.505 9.196 21.398 10.877 19.7 10.889C18.283 10.889 17.134 9.921 17.134 8.726V8.678C17.134 9.899 15.985 10.889 14.568 10.889C13.151 10.889 12.002 9.899 12.002 8.678C12.002 9.899 10.853 10.889 9.43601 10.889C8.01901 10.889 6.87001 9.899 6.87001 8.678V8.726C6.86701 9.921 5.71801 10.889 4.30101 10.889Z"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: 24,
		},
		height: {
			type: [Number, String],
			default: 24,
		},
		iconColor: {
			type: String,
			default: "secondary",
		},
	},
	computed: {
		strokeColor() {
			return "p-stroke-" + this.iconColor;
		},
	},
};
</script>

<style lang="scss" scoped>
svg {
	display: inline-block;
	vertical-align: baseline;
	margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
