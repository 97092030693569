export default {
  roleByMenus(state) {
    return state.roleByMenu.roleByMenus;
  },
  roleByMenuCurrentPage(state) {
    return state.roleByMenu.currentPage;
  },
  roleByMenuTotalPage(state) {
    return state.roleByMenu.totalPage;
  },
  roleByMenuNextPage(state) {
    return state.roleByMenu.currentPage + 1;
  },
  roleByMenuIsLastPage(_, getters) {
    return getters.roleByMenuCurrentPage === getters.roleByMenuTotalPage && getters.roleByMenuTotalPage !== 0;
  }
};
