export default {
    reportProblem: "Contract Customer Service",
    reportProblemDescription: "You can contact, inquire, or report issues to The LivingOS team through the channels below.",
    scanMe: "Scan Me!",
    serviceTime: "Service day and time",
    setviceTimeDescription: "Monday - Saturday from 9:00 AM - 6:00 PM.",
    line: "LINE Official Account",
    lineAdd: "@thelivingos",
    contactNumber: "Phone",
    tel: "02-481-5234",
    email: "Email",
    emailOfficial: "support@thelivingos.com",
    title: "Back",
}