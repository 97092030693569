import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default function useGetBookBank(props) {
	const store = useStore();
	let searchBookBankText = ref(null);

	const bookBank = computed(() => {
		return store.getters["bookBank/bookBanks"];
	});

	const typeInOut = computed(() => {
		return store.getters["bank/typeInOut"];
	});

	const activeStatus = computed(() => {
		if (store.getters["bookBank/activeStatus"]) return store.getters["bookBank/activeStatus"];
		return props;
	});

	// const bookBankIsLastPage = computed(() => {
	//   return store.getters["bank/bookBankIsLastPage"];
	// });

	const filteredBookBank = computed(() => {
		// console.log('filteredBookBank', bookBank.value);
		// console.log('filteredBookBank_2', JSON.parse(JSON.stringify(bookBank.value)));
		if (!searchBookBankText.value) {
			//ช่องว่าง
			if (bookBank.value) {
				// const result = JSON.parse(JSON.stringify(bookBank.value));
				const result = [...bookBank.value];
				return result;
			} else {
				return null;
			}
		} else {
			return bookBank.value.filter((bookBank) => {
				return bookBank.code.toLowerCase().includes(searchBookBankText.value.toLowerCase());
			});
		}
	});

	const searchBookBank = function (event) {
		// console.log('searchPayType',event);
		if (!event.query.trim().length) {
			//ช่องว่าง
			// จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
			searchBookBankText.value = "";
			searchBookBankText.value = null;
		} else {
			searchBookBankText.value = event.query;
		}
	};

	let isSharedBookBankReady = ref(false);

	const getBookBankList = async function () {
		await store.dispatch("bank/GetBookBankList");
		isSharedBookBankReady.value = true;
	};

	const getAllBookBanksPayOutTypes = async function () {
		// if (!bookBankIsLastPage.value) {
		let payload = {
			bankBookUseType: "P",
			activeStatus: activeStatus.value
		}
		await store.dispatch("bookBank/getAllBookBanks", payload);
		// }
		isSharedBookBankReady.value = true;
	};

	const getAllBookBanksPayInTypes = async function () {
		// if (!bookBankIsLastPage.value) {
		let payload = {
			bankBookUseType: "R",
			activeStatus: activeStatus.value
		}
		await store.dispatch("bookBank/getAllBookBanks", payload);
		// }
		isSharedBookBankReady.value = true;
	};

	onMounted(async () => {
		// console.log('onMounted', props);
		if (typeInOut.value === 1) {
			await getAllBookBanksPayInTypes();
		} else if (typeInOut.value === -1) {
			await getAllBookBanksPayOutTypes();
		} 
	});

	return {
		searchBookBank,
		filteredBookBank,
		searchBookBankText,
		isSharedBookBankReady,
		getBookBankList,
	};
}
