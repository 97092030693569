<template>
  <base-container>
    <base-withholding-tax-view></base-withholding-tax-view>
  </base-container>
</template>

<script>
import baseWithholdingTaxView from "../../../components/withholdingTax/BaseWithholdingTaxView.vue";
export default {
  components: {
    baseWithholdingTaxView,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
