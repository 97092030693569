import unitActions from "./unit/actions.js";
import productArActions from "./productAR/actions.js";
import documentDistinctActions from "./documentBookDistinct/actions.js";
import projectActions from "./project/actions.js";
import formPrintActions from "./formPrint/actions.js";
import supplierActions from "./supplier/actions.js";

import glBookActions from "./glBook/actions.js";
import accountingFrequentlyActions from "./accountingFrequently/actions.js";
import accountingActions from "./accounting/accountingAll/actions.js";
import taxBookActions from "./taxBook/actions.js";

import customerActions from "./customer/actions.js";
import apBookActions from "./apBook/actions.js";
import productApActions from "./productAP/actions.js";
import employeeActions from "./employee/actions.js";
import purchaseActions from "./billAcceptance/actions.js";
import taxActions from "./tax/actions.js";
import buildingActions from "./building/actions.js";
import jobAdminActions from "./jobAdmin/actions.js";
import locationActions from "./location/actions.js";
import accountingDetailActions from "./accounting/accountingDetail/actions.js";
import accountingParentActions from "./accounting/accountingParent/actions.js";
import productUnitActions from "./productUnit/actions.js";
import nationalityActions from "./nationality/actions.js";
import bankActions from "./bank/actions.js";
import printActions from "./print/actions.js";
import roleByMenuActions from "./roleByMenu/actions.js"
import roleByBookActions from "./roleByBook/actions.js"
import currencyActions from "./currency/actions.js";
import bookBankArActions from "./bookBankAr/actions.js";
import productGuaranteeActions from "./productGuarantee/actions.js";
import addressActions from "./address/actions.js";
import dbdBusinessTypeActions from "./dbdBusinessType/actions.js";
import countryCodeActions from "./countryCode/actions.js";

const mergeActions = {
	...unitActions,
	...productArActions,
	...documentDistinctActions,
	...projectActions,
	...formPrintActions,
	...glBookActions,
	...accountingFrequentlyActions,
	...accountingActions,
	...supplierActions,
	...taxBookActions,
	...customerActions,
	...apBookActions,
	...productApActions,
	...employeeActions,
	...purchaseActions,
	...taxActions,
	...buildingActions,
	...jobAdminActions,
	...locationActions,
	...accountingDetailActions,
	...accountingParentActions,
	...productUnitActions,
	...nationalityActions,
	...bankActions,
	...printActions,
	...roleByMenuActions,
	...roleByBookActions,
	...currencyActions,
	...bookBankArActions,
	...productGuaranteeActions,
	...addressActions,
	...dbdBusinessTypeActions,
	...countryCodeActions,
};

export default mergeActions;
