import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export default {
    namespaced: true,
    state(){
        return {
            bookBanks: [],
            selectedBookBank: null,
            totalPage: 0,
            currentPage: 0,
            rows: 100,
            activeStatus: null
        }
    },
    actions,
    getters,
    mutations
}