export default {
  createInvoice:"Create Invoice",
  editInvoice:"Edit Invoice",
  descriptionInvoice:"Description Invoice",
  askForProductChange: "Are you sure you want to update product code?",
  askForSelectProduct: "Can't find product code : :code. Do you want to search?",
  askForCreateNewDocument: "Are you sure you want to discard change and create new document?",
  askForNonExistPeriod: "Can not find period :period. First row or default period will be use depends on existing data.",
  date: "Create Document",
  titleDetail: "Detail List",
  addProduct:"Add Product",
	totalAmount: "Total Amount",
	paidAmount: "Paid Amount",
  printInvoice: "Print Invoice",
  cancelDocument: "Cancel Document",
  addEdit: "Add/ Edit",
  product: "Product*",
  unitPrice: "Price*",
  quantity: "Quantity*",
  period: "Period*",  
	validateProductSelect: "Please select product",
  validateUnitPrice:"UnitPrice must be more than 0",
  validateQuantity:"Quantity must be more than 0",
	validatePeriod: "Please select period",
	confirmCancel: "Confirm Cancel",
  generateProduct:"Generate Product By Input Number",
  createItem: "Create Item",
	validateInactiveUnitSelect: "This unit is inactive.",
  validateDetailSelect: "Please select Item.",
  validateTotalAmount: "Total amount must be greater than 0.",
  validateCustomerIdIncorrect: "CustomerId is incorrect please contact our customer service.",
  validateEmployeeId: "EmployeeId is incorrect please contact our customer service.",
  askForCancelInvoiceDocument: "Are you sure to Cancel InvoiceCode :code ? ",
  createNewInvoice: "Create Invoice",
  askForCreateNewInvoice: "Are you sure you want to discard change ",
  askForCreateNewInvoiceConfirm: "Are you create new document?",
  createInvoiceSuccess: "Create Invoice Success",
  editInvoiceSuccess: "Edit Invoice Success",
  cancelInvoiceSuccess: "Cancel Invoice :code Success",
	dueDate: "DueDate",
  tableEmpty: "Empty Data",
  showRemark: "Show remark",
  hideRemark: "Hide remark",

  validateInvoiceIsPaid: "Document is paid",
  validateDate: "Please select date",
  validateDueDate: "Please select due date",
  validateDueDateIsInvalid: "The due date can't be less than the document date",
};
