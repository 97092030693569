import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default function useGetBuilding() {
  const store = useStore();
  let searchText = ref(null);

  const buildings = computed(() => {
    return store.getters["shared/buildingBuildings"];
  });

  const buildingIsLastPage = computed(() => {
    return store.getters["shared/buildingIsLastPage"];
  });

  const filteredBuildings = computed(() => {
    if (!searchText.value) {
      //ช่องว่าง
      const result = [...buildings.value];
      return result;
    } else {
      return buildings.value.filter((building) => {
        return building.codeAndName
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const searchBuilding = function (event) {
    // console.log(event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchText.value = "";
      searchText.value = null;
    } else {
      searchText.value = event.query;
    }
  };

  let isSharedBuildingReady = ref(false);

  const getAllBuildings = async function () {
    if (!buildingIsLastPage.value) {
      await store.dispatch("shared/getAllBuildings");
    }
    isSharedBuildingReady.value = true;
  };

  const getBuildingByCode = function (code) {
    return buildings.value.find((building) => {
      return building.code.toLowerCase() === code.trim().toLowerCase();
    });
  };

  onMounted(async () => {
    await getAllBuildings();
  });

  return {
    searchBuilding,
    filteredBuildings,
    searchText,
    getBuildingByCode,
    isSharedBuildingReady
  }
}
