<template>
  <base-toolbar :title="lang.importAsset" @goBack="goBack"></base-toolbar>
  <import-asset-dialog
    v-model:visible="showUploadAssetDialog"
    @close="showUploadAssetDialog = false"
    @data-upload="showUploadAssetDialog = false"
  />
  <base-progress-bar
    v-model:visible="showProgressBar"
    :title="lang.creatingAsset"
    :textProgressBar="lang.generating"
    :valueObj="valueObj"
    :progressBar="`Template`"
  ></base-progress-bar>
  <preview-asset-detail-dialog
    v-model:visible="showPreviewDetailDialog"
    @close="showPreviewDetailDialog = false"
    :selectedAsset="selectedAsset"
    :key="keyToReLoad"
  ></preview-asset-detail-dialog>
  <base-container>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.importAsset }}</label>

        <div class="flex">
          <PvButton class="p-button-outlined ml-3" @click="exportErrorExcel">
            <i class="pi pi-file-excel" style="width: 16px; height: 16px"></i>
            <span class="p-button-label font-normal ml-1">{{
              lang.listCannotImport
            }}</span>
          </PvButton>

          <PvButton class="p-button-outlined ml-3" @click="askForUploadAsset">
            <icon-cloud-storage-upload
              iconColor="primary"
              width="16"
              height="16"
            />
            <span class="p-button-label font-normal ml-1">{{
              lang.reupload
            }}</span>
          </PvButton>

          <PvButton class="ml-3" @click="importAsset">
            <icon-documents-file-checkmark
              iconColor="white"
              width="16"
              height="16"
            />
            <span class="p-button-label font-normal ml-1">{{
              labelImportAll
            }}</span>
          </PvButton>
        </div>
      </div>

      <PvDivider></PvDivider>

      <div class="flex flex-wrap justify-content-left">
        <div class="lg:col-2 md:col-6 col-12 ml-1 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.allStatus"
            :content="contentAllStatus"
            :color="'primary'"
            :isClick="allStatusClick"
            @click="clickFilter('all')"
          ></base-select-card>
        </div>
        <div class="lg:col-2 md:col-6 col-12 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.wait"
            :content="contentWaitStatus"
            :color="'warning'"
            :isClick="waitStatusClick"
            @click="clickFilter('wait')"
          ></base-select-card>
        </div>
        <div class="lg:col-2 md:col-6 col-12 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.invalid"
            :content="contentInvalidStatus"
            :color="'secondary'"
            :isClick="invalidStatusClick"
            @click="clickFilter('invalid')"
          ></base-select-card>
        </div>
        <div class="lg:col-2 md:col-6 col-12 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.error"
            :content="contentErrorStatus"
            :color="'error'"
            :isClick="errorStatusClick"
            @click="clickFilter('error')"
          ></base-select-card>
        </div>
        <div class="lg:col-2 md:col-6 col-12 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.success"
            :content="contentSuccessStatus"
            :color="'success'"
            :isClick="successStatusClick"
            @click="clickFilter('success')"
          ></base-select-card>
        </div>
      </div>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="dataTables"
          :paginator="true"
          class="p-datatable-sm"
          :class="textTableClass"
          :rows="rowsPerPage"
          dataKey="id"
          :rowHover="true"
          paginatorTemplate="CurrentPageReport    FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 50, 100]"
          :currentPageReportTemplate="
            lang.page + ` {currentPage} / {totalPages}`
          "
          :globalFilterFields="[
            'name',
            'country.name',
            'representative.name',
            'status',
          ]"
          responsiveLayout="scroll"
        >
          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
          <PvColumn
            field="itemNo"
            :header="lang.itemNo"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="importStatusCode"
            :header="lang.statusImport"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <base-tag
                :Caption="
                  setBaseTagStatusImport(slotProps.data.importStatusCode)
                    .caption
                "
                :Class="
                  setBaseTagStatusImport(slotProps.data.importStatusCode).class
                "
              />
            </template>
          </PvColumn>
          <PvColumn
            field="importStatusReason"
            :header="lang.reason"
            style="min-width: 13rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="code"
            :header="lang.code"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <span
                :class="{
                  'p-error': slotProps.data.importStatusDescription == 'code',
                }"
              >
                {{ slotProps.data.code }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            field="name"
            :header="lang.assetName"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <span
                :class="{
                  'p-error': slotProps.data.importStatusDescription == 'name',
                }"
              >
                {{ slotProps.data.name }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            field="buyDate"
            :header="lang.buyDate"
            style="min-width: 9rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <span
                :class="{
                  'p-error':
                    slotProps.data.importStatusDescription == 'buyDate',
                }"
                v-if="slotProps.data.buyDate"
              >
                {{ formatDate(slotProps.data.buyDate) }}
              </span>
            </template>
          </PvColumn>

          <PvColumn
            style="min-width: 5rem"
            headerClass="h-center font-normal"
            bodyStyle="text-align: center;"
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="openPreviewDetailDialog(slotProps.data)"
              >
                <div v-if="slotProps.data.error.length > 0" v-badge.danger>
                  <icon-eye
                    class="p-button-profile"
                    iconColor="secondary"
                    width="20"
                    height="20"
                  />
                </div>
                <div v-else>
                  <icon-eye
                    class="p-button-profile"
                    iconColor="secondary"
                    width="20"
                    height="20"
                  />
                </div>
              </PvButton>
            </template>
          </PvColumn>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import BaseDialog from "../../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import ImportAssetDialog from "../../../components/Asset/ImportAssetDialog.vue";
import PreviewAssetDetailDialog from "../../../components/Asset/PreviewAssetDetailDialog.vue";
import useExportExcel from "../../../hooks/exportExcel.js";
export default {
  props: ["projectCode"],
  components: {
    ImportAssetDialog,
    PreviewAssetDetailDialog
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { lang } = useLang();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.asset.assetRegister.listing,
          ...lang.th.asset.assetRegister.edit,
          ...lang.th.asset.assetRegister.import,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.asset.assetRegister.listing,
          ...lang.en.asset.assetRegister.edit,
          ...lang.en.asset.assetRegister.import,
        };
      }
    });

    const { formatDate, textTableClass, textFormClass } = useFormat();
    const { getRoleByMenuByCode } = useGetRoleByMenu();
    const { generateFile } = useExportExcel();

    onMounted(() => {
      initiateData;
      window.addEventListener("beforeunload", handleBeforeUnload);
    });

    //params
    let dataConfigDialog = ref(null);
    let showUploadAssetDialog = ref(false);
    let showPreviewDetailDialog = ref(false);
    let selectedPage = ref(1);
    let rowsPerPage = ref(10);
    let selectedAsset = ref(null);
    let keyToReLoad = ref(0);
    //base-select-card
    let allStatusClick = ref(true);
    let waitStatusClick = ref(false);
    let invalidStatusClick = ref(false);
    let errorStatusClick = ref(false);
    let successStatusClick = ref(false);
    //ProgressBar
    let showProgressBar = ref(false);
    let valueObj = ref([]);

    //computeds
    const roleByMenu = computed(() => {
      return getRoleByMenuByCode("FA");
    });
    const isRoleInsert = computed(() => {
      if (roleByMenu.value && roleByMenu.value.insert == "Y") {
        return true;
      }
      return false;
    });
    const assets = computed(() => {
      return store.getters["importAsset/importAssets"];
    });

    const asset = computed(() => {
      return store.getters["assetAssetRegister/selectedAssetRegister"];
    });
    const lengthAssets = computed(() => {
      return assets.value.length;
    });
    const dataWaitStatus = computed(() => {
      if (lengthAssets.value > 0) {
        return assets.value.filter((data) => data.importStatusCode == "wait");
      }
      return [];
    });
    const dataInvalidStatus = computed(() => {
      if (lengthAssets.value > 0) {
        return assets.value.filter(
          (data) => data.importStatusCode == "invalid"
        );
      }
      return [];
    });
    const dataErrorStatus = computed(() => {
      if (lengthAssets.value > 0) {
        return assets.value.filter((data) => data.importStatusCode == "error");
      }
      return [];
    });
    const dataSuccessStatus = computed(() => {
      if (lengthAssets.value > 0) {
        return assets.value.filter(
          (data) => data.importStatusCode == "success"
        );
      }
      return [];
    });
    const labelImportAll = computed(() => {
      let countData = 0;
      if (lengthAssets.value > 0) {
        countData = dataWaitStatus.value.length;
      }
      return mergeLang.value.importAll.replace(":value", countData);
    });
    const contentAllStatus = computed(() => {
      let countData = 0;
      if (lengthAssets.value > 0) {
        countData = lengthAssets.value;
      }
      return mergeLang.value.statusContent.replace(":number", countData);
    });
    const contentWaitStatus = computed(() => {
      let countData = 0;
      if (lengthAssets.value > 0) {
        countData = dataWaitStatus.value.length;
      }
      return mergeLang.value.statusContent.replace(":number", countData);
    });
    const contentInvalidStatus = computed(() => {
      let countData = 0;
      if (lengthAssets.value > 0) {
        countData = dataInvalidStatus.value.length;
      }
      return mergeLang.value.statusContent.replace(":number", countData);
    });
    const contentErrorStatus = computed(() => {
      let countData = 0;
      if (lengthAssets.value > 0) {
        countData = dataErrorStatus.value.length;
      }
      return mergeLang.value.statusContent.replace(":number", countData);
    });
    const contentSuccessStatus = computed(() => {
      let countData = 0;
      if (lengthAssets.value > 0) {
        countData = dataSuccessStatus.value.length;
      }
      return mergeLang.value.statusContent.replace(":number", countData);
    });
    const dataTables = computed(() => {
      switch (true) {
        case waitStatusClick.value:
          return dataWaitStatus.value;
        case invalidStatusClick.value:
          return dataInvalidStatus.value;
        case errorStatusClick.value:
          return dataErrorStatus.value;
        case successStatusClick.value:
          return dataSuccessStatus.value;
        default:
          return assets.value;
      }
    });
    const dataExportError = computed(() => [
      ...dataInvalidStatus.value,
      ...dataErrorStatus.value,
    ]);

    //methods
    const goBack = () => {
      router.push({ name: "asset-register" });
    };
    const initiateData = () => {
      showUploadAssetDialog.value = false;
      showPreviewDetailDialog.value = false;
      selectedPage.value = 1;
      rowsPerPage.value = 10;
      allStatusClick.value = true;
      waitStatusClick.value = false;
      invalidStatusClick.value = false;
      errorStatusClick.value = false;
      successStatusClick.value = false;
      dataConfigDialog.value = null;
      showProgressBar.value = false;
      valueObj.value = [];
      selectedAsset.value = null;
      keyToReLoad.value = 0;
    };
    const clickFilter = (status) => {
      allStatusClick.value = false;
      waitStatusClick.value = false;
      invalidStatusClick.value = false;
      errorStatusClick.value = false;
      successStatusClick.value = false;

      if (status == "all") {
        allStatusClick.value = true;
      } else if (status == "wait") {
        waitStatusClick.value = true;
      } else if (status == "invalid") {
        invalidStatusClick.value = true;
      } else if (status == "error") {
        errorStatusClick.value = true;
      } else if (status == "success") {
        successStatusClick.value = true;
      }

      selectedPage.value = 1;
      rowsPerPage.value = 10;
    };
    const setBaseTagStatusImport = (status) => {
      let result = {
        caption: "",
        class: "",
      };
      if (status.toLowerCase() === "wait") {
        result.caption = mergeLang.value.wait;
        result.class = "warning";
      } else if (status.toLowerCase() === "success") {
        result.caption = mergeLang.value.success;
        result.class = "success";
      } else if (status.toLowerCase() === "invalid") {
        result.caption = mergeLang.value.invalid;
        result.class = "secondary";
      } else if (status.toLowerCase() === "error") {
        result.caption = mergeLang.value.error;
        result.class = "error";
      }

      return result;
    };
    const setBaseTagStatus = (data) => {
      let status = {
        label: mergeLang.value.ready,
        class: "success",
      };
      if (data == "sold") {
        status.label = mergeLang.value.sold;
        status.class = "secondary";
      } else if (data == "lost") {
        status.label = mergeLang.value.lost;
        status.class = "error";
      } else if (data == "broken") {
        status.label = mergeLang.value.broken;
        status.class = "warning";
      } else if (data == "spare") {
        status.label = mergeLang.value.spare;
        status.class = "primary";
      } else if (data == "other") {
        status.label = mergeLang.value.other;
        status.class = "other";
      }
      return status;
    };
    const setDialog = (dataConfig) => {
      if (dataConfig.classTitle === "") {
        dataConfig.classTitle = "";
      } else if (dataConfig.classTitle === "success") {
        dataConfig.classTitle = "p-text-success";
      } else if (dataConfig.classTitle === "error") {
        dataConfig.classTitle = "p-error";
      }

      dataConfig.button = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "cancel",
        },
      ];
      if (dataConfig.type === "confirm") {
        dataConfig.button = [
          {
            id: "close",
            caption: mergeLang.value.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "cancel",
          },
          {
            id: "confirm",
            caption: mergeLang.value.buttonDialogConfirm,
            class: "w-full",
            action: "confirm",
          },
        ];
      }

      dataConfigDialog.value = {
        titleConfirm: dataConfig.title,
        classTitle: dataConfig.classTitle,
        wordingConfirm: dataConfig.message,
        dataButtons: dataConfig.button,
        display: true,
      };
    };
    const askForUploadAsset = async () => {
      if (lengthAssets.value > 0) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.askForNewImport,
          type: "confirm",
        });
        const dialogResponse = await createConfirmDialog(
          BaseDialog,
          dataConfigDialog.value
        ).reveal();
        if (dialogResponse.isCanceled) {
          return;
        }
      }
      uploadAsset();
    };
    const uploadAsset = () => {
      showUploadAssetDialog.value = true;
    };
    const importAsset = async () => {
      if (validateData()) {
        const dialogResponse = await createConfirmDialog(
          BaseDialog,
          dataConfigDialog.value
        ).reveal();
        if (dialogResponse.isCanceled) {
          return;
        }
      }

      valueObj.value = [];
      valueObj.value = dataWaitStatus.value;
      showProgressBar.value = true;
      for (const data of dataWaitStatus.value) {
        await addAsset(data);
      }
      showProgressBar.value = false;

      setDialog({
        classTitle: "success",
        title: mergeLang.value.saveSuccess,
        message: mergeLang.value.saveSuccess,
        type: "",
      });
      await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
      clickFilter("all");
    };
    const setPayload = (data) => {
      data = {
        ...data,
        depreFormula: data.depreciationFormula == "แบบเส้นตรง" ? "A" : "B",
        deprePreriod: data.depreciationPeriod == "เดือน" ? "M" : "Y",
        activeStatus: data.status == "ไม่ใช้งาน" ? "N" : "Y",
        parentAssetId: "",
        assetGroupId: "",
        buildingId: "",
        LocationId: "",
        adminId: "",
      };
      data.saleDate = data.saleDate == "" ? null : data.saleDate;
      return data;
    };
    const addAsset = async (data) => {
      try {
        let result = setPayload(data);
        let payload = { ...result, type: "Add" };
        // console.log("addAsset=", payload);
        await store.dispatch("assetAssetRegister/createOrUpdateAssetRegister", payload);
        if (asset.value) {
          data.id = asset.value.id;
          data.importStatusCode = "success";
          data.markStatus = "1";
        }
        return true;
      } catch (error) {
        data.importStatusCode = "error";
        data.importStatusReason = mergeLang.value.cannotImportWording;
        data.markStatus = "1";
        return false;
      }
    };
    const validateData = () => {
      if (!isRoleInsert.value) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.validateRoleImport,
          type: "",
        });
        return true;
      }
      if (dataWaitStatus.value.length == 0) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.noItemToImportWording,
          type: "",
        });
        return true;
      } else if (dataInvalidStatus.value.length > 0) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: [
            mergeLang.value.wordingConfirmImportAsset
              .replace(":total", lengthAssets.value)
              .replace(":value", dataWaitStatus.value.length)
              .replace(":invalidValue", dataInvalidStatus.value.length),
            mergeLang.value.askForNextStep,
          ],
          type: "confirm",
        });
        return true;
      }
      return false;
    };
    const openPreviewDetailDialog = async (data) => {
    //   console.log("openPreviewDetailDialog", data);
      keyToReLoad.value++;
      selectedAsset.value = data;
      showPreviewDetailDialog.value = true;
    };

    onBeforeRouteLeave(async (to, from) => {
      if (
        dataWaitStatus.value.length > 0 ||
        dataInvalidStatus.value.length > 0
      ) {
        var curValue = to.params.projectCode;
        var oldValue = from.params.projectCode;
        if (curValue == oldValue) {
          setDialog({
            classTitle: "",
            title: mergeLang.value.notificationDialogHeader,
            message: mergeLang.value.pageDirtyConfirmMessage,
            type: "confirm",
          });
          const dialogResponse = await createConfirmDialog(
            BaseDialog,
            dataConfigDialog.value
          ).reveal();
          return !dialogResponse.isCanceled;
        }
      }
      return true;
    });

    onBeforeUnmount(() => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    });

    const handleBeforeUnload = async (event) => {
      if (
        dataWaitStatus.value.length > 0 ||
        dataInvalidStatus.value.length > 0
      ) {
        event.preventDefault();
      }
    };

    const exportErrorExcel = async () => {
      if (dataExportError.value.length > 0) {
        const columnName = [
          {
            field: "code",
            name: "หมายเลขทรัพย์สิน*",
          },
          {
            field: "model",
            name: "กรรมสิทธิ์*",
          },
          {
            field: "name",
            name: "ชื่อทรัพย์สิน (ไทย)*",
          },
          {
            field: "nameEn",
            name: "ชื่อทรัพย์สิน (อังกฤษ)",
          },
          {
            field: "assetAccountCode",
            name: "รหัสบัญชี*",
          },
          {
            field: "depreAccountCode",
            name: "บัญชีค่าเสื่อมราคา*",
          },
          {
            field: "accumAccountCode",
            name: "บัญชีค่าเสื่อมราคาสะสม*",
          },
          {
            field: "taxInvoiceCode",
            name: "เลขที่ใบสำคัญ/ใบกำกับภาษี*",
          },
          {
            field: "buyDate",
            name: "วันที่ซื้อ/วันที่ได้มา",
          },
          {
            field: "buyPrice",
            name: "ราคาที่ซื้อ/มูลค่าที่ได้มา*",
          },
          {
            field: "deprePrice",
            name: "มูลค่าที่นำไปคำนวณ*",
          },
          {
            field: "marketPrice",
            name: "ราคาตลาด",
          },
          {
            field: "otherCost",
            name: "ค่าใช้จ่ายอื่นๆ",
          },
          {
            field: "depreCalDate",
            name: "วันที่เริ่มคิดค่าเสื่อม*",
          },
          {
            field: "depreRate",
            name: "อัตราค่าเสื่อม ร้อยละ*",
          },
          {
            field: "beforeDepre",
            name: "ค่าเสื่อมสะสมก่อนวันเริ่มคำนวณ",
          },
          {
            field: "depreciationFormula",
            name: "วิธีการคำนวนค่าเสื่อม*",
          },
          {
            field: "depreciationPeriod",
            name: "ลงบัญชีค่าเสื่อมทุกๆ*",
          },
          {
            field: "status",
            name: "สถานะ*",
          },
          {
            field: "depreRemain",
            name: "ราคาซาก",
          },
          {
            field: "saleRefer",
            name: "เลขที่เอกสารประกอบการขาย",
          },
          {
            field: "saleDate",
            name: "วันที่ตัดทรัพย์สิน",
          },
          {
            field: "salePrice",
            name: "ราคาขาย",
          },
        ];
        generateFile(
          dataExportError.value,
          columnName,
          "asset_error",
          mergeLang.value.asset,
          2
        );
      } else {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.noItemToExportWording,
          type: "",
        });
        await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
      }
    };

    return {
      lang: mergeLang,
      formatDate,
      textTableClass,
      textFormClass,

      //params
      showUploadAssetDialog,
      showPreviewDetailDialog,
      selectedPage,
      rowsPerPage,
      allStatusClick,
      waitStatusClick,
      invalidStatusClick,
      errorStatusClick,
      successStatusClick,
      showProgressBar,
      valueObj,
      keyToReLoad,
      selectedAsset,

      //computeds
      labelImportAll,
      contentAllStatus,
      contentWaitStatus,
      contentInvalidStatus,
      contentErrorStatus,
      contentSuccessStatus,
      dataTables,

      //methods
      goBack,
      clickFilter,
      setBaseTagStatus,
      setBaseTagStatusImport,
      askForUploadAsset,
      importAsset,
      openPreviewDetailDialog,
      exportErrorExcel,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-right: auto;
  }
}
::v-deep(.p-overlay-badge) {
  .p-badge {
    transform: translate(0%, 30%) !important;
  }
}

::v-deep(.p-badge) {
  padding: 0 0rem;
}
::v-deep(.p-button) {
  .p-badge {
    min-width: 7px !important;
    height: 7px !important;
  }
}
</style>
