<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '640px': '80vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-right">
        <div class="pb-1">
          <label class="font-lg">{{ lang.titleDetailDialog }}</label>
        </div>
        <div>
          <PvButton
            icon="pi pi-times"
            class="p-button-link p-icon-secondary pb-0 pt-0"
            style="width: 15px"
            @click="closeDialog()"
          />
        </div>
      </div>

      <PvDivider></PvDivider>
    </template>

    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="col-12 pl-0 pr-2">
          <label for="product">{{ lang.product }}</label>
          <base-product-ap-auto-complete
            id="product"
            v-model="selectedProduct"
            class="inputfield w-full mt-1"
            @load-finish="productDataReady"
            :class="{ 'p-invalid': productError }"
            @item-select="selectProduct"
            autofocus
          />
          <small v-if="productError" id="product-help" class="p-error">{{ lang.validateNoProductSelect }}</small>
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
          <label for="period">{{ lang.period }}</label>
          <base-period-auto-complete
            class="w-full mt-1"
            v-model="selectedPeriod"
            @item-select="selectPeriod"
            id="period"
          ></base-period-auto-complete>
        </div>
        <div class="lg:col-8 md:col-8 col-12 pl-0 pr-2">
          <label for="remark">{{ lang.remark }}</label>
          <PvInputText id="remark" v-model="remark" type="text" class="inputfield w-full mt-1" />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="price">{{ lang.price }}</label>
          <PvInputNumber
            id="price"
            class="inputfield w-full mt-1"
            inputClass="text-right"
            v-model="price"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            :class="{ 'p-invalid': priceError }"
            @focus="$event.target.select()"
            @input="price = $event.value; calculateTotalAmount()"
          />
          <small v-if="priceError" id="price-help" class="p-error">{{ lang.validateNoPrice }}</small>
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="quantity">{{ lang.quantity }}</label>
          <PvInputNumber
            id="quantity"
            class="inputfield w-full mt-1"
            inputClass="text-right"
            v-model="quantity"
            mode="decimal"
            :minFractionDigits="0"
            :class="{ 'p-invalid': quantityError }"
            @focus="$event.target.select()"
            @input="quantity = $event.value; calculateTotalAmount()"
          />
          <small v-if="quantityError" id="quantity-help" class="p-error">{{ lang.validateNoQuantity }}</small>
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2"></div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="discountDetail">{{ lang.discountDetail }}</label>
          <PvInputNumber
            id="discountDetail"
            class="inputfield w-full mt-1"
            inputClass="text-right"
            v-model="discountDetail"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            @focus="$event.target.select()"
            @input="discountDetail = $event.value; calculateTotalAmount()"
          />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="discountAverage">{{ lang.discountHeader }}</label>
          <PvInputNumber
            id="discountAverage"
            class="inputfield w-full mt-1"
            inputClass="input-number-disable text-right"
            v-model="discountAverage"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            disabled
          />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="totalAmount">{{ lang.totalAmountDetail }}</label>
          <PvInputNumber
            id="totalAmount"
            class="inputfield w-full mt-1"
            inputClass="input-number-disable text-right"
            v-model="totalAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            disabled
          />
        </div>
        <PvDivider></PvDivider>
        <div class="col-12 pl-0 pr-2">
          <label class="font-lg">{{ lang.titleCompareBudget }}</label>
        </div>
        <div class="flex col-12 p-0">
          <div class="lg:col-6 md:col-6 col-12 p-0">
            <div class="col-12 px-0">
              <label for="expenseVoucher">{{ lang.totalAmountDetail }}</label>
            </div>
            <div class="col-12 px-0">
              <base-tag
                id="expenseVoucher"
                :Caption="calculateStatusExpense().caption"
                :Class="calculateStatusExpense().class"
              />
            </div>
            <div class="flex col-12 py-0 pl-0 pr-3">
              <div class="lg:col-6 md:col-6 col-12 p-0">
                <label for="expenseAccumulated">{{ lang.expenseAccumulated }}</label>
                <PvInputNumber
                  id="expenseAccumulated"
                  class="inputfield w-full mt-1 pr-3"
                  inputClass="input-number-disable text-right"
                  v-model="expenseAccumulated"
                  mode="decimal"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  disabled
                />
              </div>
              <div class="lg:col-6 md:col-6 col-12 p-0">
                <label for="expenseBalance">{{ lang.expenseBalance }}</label>
                <PvInputNumber
                  id="expenseBalance"
                  class="inputfield w-full mt-1"
                  inputClass="input-number-disable text-right"
                  v-model="expenseBalance"
                  mode="decimal"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  disabled
                />
              </div>
            </div>
          </div>
          <PvDivider layout="vertical" class="m-0"></PvDivider>
          <div class="lg:col-6 md:col-6 col-12 py-0 pr-0 pl-3">
            <div class="col-12 px-0">
              <label for="paymentVoucher">{{ lang.totalAmountDetail }}</label>
            </div>
            <div class="col-12 px-0">
              <base-tag
                id="paymentVoucher"
                :Caption="calculateStatusPayment().caption"
                :Class="calculateStatusPayment().class"
              />
            </div>
            <div class="flex col-12 p-0">
              <div class="lg:col-6 md:col-6 col-12 p-0">
                <label for="paymentAccumulated">{{ lang.paymentAccumulated }}</label>
                <PvInputNumber
                  id="paymentAccumulated"
                  class="inputfield w-full mt-1 pr-3"
                  inputClass="input-number-disable text-right"
                  v-model="paymentAccumulated"
                  mode="decimal"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  disabled
                />
              </div>
              <div class="lg:col-6 md:col-6 col-12 p-0">
                <label for="paymentBalance">{{ lang.paymentBalance }}</label>
                <PvInputNumber
                  id="paymentBalance"
                  class="inputfield w-full mt-1"
                  inputClass="input-number-disable text-right"
                  v-model="paymentBalance"
                  mode="decimal"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 p-0 my-3">
          <label for="accountCompare">{{ lang.accountCompare }}</label>
          <PvInputText
            id="accountCompare"
            class="inputfield w-full mt-1 input-disable"
            v-model="accountCompare"
            readonly
          />
        </div>
      </section>
    </transition>

    <template #footer>
      <div class="flex justify-content-between align-items-center">
        <PvButton :label="lang.close" class="p-button-outlined p-button-secondary w-full ml-3" @click="closeDialog()" />
        <PvButton :label="lang.confirm" class="w-full mr-3" @click="confirm()" />
      </div>
    </template>
  </PvDialog>
</template>
<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import BaseDialog from "../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import { useStore } from "vuex";
import { ref, computed, watch, onMounted } from "vue";

export default {
  props: {
    data: {
      type: Object,
    },
    budgetHeaderId: {
      type: Number,
    },
    dateAt: {
      type: Date,
    },
    documentDate: {
      type: Date,
    },
  },
  emits: ["close", "confirm"],
  setup(props, { emit }) {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString, formatDateTimeToISOString } = useFormat();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ap.paymentRequest.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ap.paymentRequest.edit,
        };
      }
    });
    let dataConfigDialog = ref(null);
    let isLoadingBudget = ref(false);
    let productError = ref(false);
    let isSharedProduct = ref(false);
    let selectedProduct = ref(null);
    let selectedPeriod = ref(null);
    let remark = ref(null);
    let price = ref(null);
    let priceError = ref(false);
    let quantity = ref(1);
    let quantityError = ref(false);
    let discountDetail = ref(0);
    let discountAverage = ref(0);
    let vatAverage = ref(0);
    let totalAmount = ref(null);

    let expenseAccumulated = ref(0);
    let expenseBalance = ref(0);
    let paymentAccumulated = ref(0);
    let paymentBalance = ref(0);
    let accountCompare = ref(null);
    let accountDebit = ref(null);
    let accountCreditPrevious = ref(null);
    let accountCreditCurrent = ref(null);
    let accountCreditNext = ref(null);

    onMounted(async () => {
      if (props.budgetHeaderId && props.dateAt) {
        await getBudgetSummary();
        setData();
      }
    });

    const closeDialog = () => {
      emit("close");
    };
    const productDataReady = () => {
      isSharedProduct.value = true;
    };
    const checkShouldSetData = async () => {
      if (isSharedProduct.value && props.data) {
        selectedProduct.value = productList.value.find((pro) => pro.id.trim() === props.data.productId.trim());
      }
    };
    const setData = () => {
      if (props.data) {
        console.log(props.data);
        selectedPeriod.value = props.data.period;
        remark.value = props.data.description;
        price.value = props.data.price;
        quantity.value = props.data.quantity;
        discountDetail.value = props.data.discountAmount;
        discountAverage.value = props.data.discountAverage;
        vatAverage.value = props.data.vatAverage;
        totalAmount.value = props.data.totalAmount;
        claculateBudget();
      }
    };
    const selectProduct = () => {
      productError.value = false;
      if (selectedPeriod.value) {
        claculateBudget();
      }
    };
    const selectPeriod = () => {
      if (selectedProduct.value) {
        claculateBudget();
      }
    };
    const claculateBudget = () => {
      if (selectedProduct.value) {
        accountDebit.value = budgetSummary.value.find(
          (budget) => budget.accountId.trim() === selectedProduct.value.accountDebitPaymentRequestId.trim()
        );
        accountCreditCurrent.value = budgetSummary.value.find(
          (budget) => budget.accountId.trim() === selectedProduct.value.accountCreditPaymentRequestCurrentId.trim()
        );
        accountCreditNext.value = budgetSummary.value.find(
          (budget) => budget.accountId.trim() === selectedProduct.value.accountCreditPaymentRequestNextId.trim()
        );
        accountCreditPrevious.value = budgetSummary.value.find(
          (budget) => budget.accountId.trim() === selectedProduct.value.accountCreditPaymentRequestPreviousId.trim()
        );

        if (accountDebit.value) {
          expenseAccumulated.value = accountDebit.value.requestAmount;
          expenseBalance.value = accountDebit.value.budgetAmount;
          accountCompare.value = accountDebit.value.accountCode + " : " + accountDebit.value.accountName;
          paymentBalance.value = accountDebit.value.budgetAmount;
          paymentBalance.value = accountDebit.value.actualAmount;
        }
      }
    };
    const confirm = () => {
      if (!validateConfirm()) {
        return;
      }
      const paymentRequest = {
        id: props.data?.id ?? null,
        itemNo: props.data?.itemNo ?? null,
        productId: selectedProduct.value.id,
        productCode: selectedProduct.value.code,
        productName: selectedProduct.value.nameThai,
        period: selectedPeriod.value?.name ?? selectedPeriod.value,
        description: remark.value,
        isUseExpenseVoucher: "Y",
        price: price.value,
        quantity: quantity.value,
        discountDescription: discountDetail.value?.toString() ?? null,
        discountAmount: discountDetail.value,
        totalAmount: totalAmount.value,
        accountDebitId: selectedProduct.value.accountDebitPaymentRequestId,
        accountCreditPreviousId: selectedProduct.value.accountCreditPaymentRequestPreviousId,
        accountCreditCurrentId: selectedProduct.value.accountCreditPaymentRequestCurrentId,
        accountCreditNextId: selectedProduct.value.accountCreditPaymentRequestNextId,
        discountAverage: discountAverage.value,
        vatAverage: vatAverage.value,
      };
      emit("confirm", paymentRequest);
    };
    const validateConfirm = () => {
      let isValidate = true;
      if (!selectedProduct.value) {
        isValidate = false;
        productError.value = true;
      }
      if (!price.value) {
        isValidate = false;
        priceError.value = true;
      }
      if (!quantity.value) {
        isValidate = false;
        quantityError.value = true;
      }
      return isValidate;
    };
    const calculateStatusExpense = () => {
      let result = {
        caption: "",
        class: "",
      };
      if (accountCompare.value == null) {
        result.caption = mergeLang.value.budgetNotfound;
        result.class = "secondary";
      } else if (expenseAccumulated.value <= expenseBalance.value) {
        result.caption = mergeLang.value.withinBudget;
        result.class = "success";
      } else if (expenseAccumulated.value > expenseBalance.value) {
        result.caption = mergeLang.value.overBudget;
        result.class = "error";
      }
      return result;
    };
    const calculateStatusPayment = () => {
      let result = {
        caption: "",
        class: "",
      };
      if (!(accountCreditNext.value || accountCreditCurrent.value || accountCreditPrevious.value)) {
        result.caption = mergeLang.value.budgetNotfound;
        result.class = "secondary";
      } else if (paymentAccumulated.value <= paymentBalance.value) {
        result.caption = mergeLang.value.withinBudget;
        result.class = "success";
      } else if (paymentAccumulated.value > paymentBalance.value) {
        result.caption = mergeLang.value.overBudget;
        result.class = "error";
      }
      return result;
    };
    const calculateTotalAmount = () => {
      priceError.value = false;
      quantityError.value = false;
      totalAmount.value = price.value * quantity.value - discountDetail.value;
    };
    const getBudgetSummary = async () => {
      isLoadingBudget.value = true;
      try {
        let payload = {
          budgetHeaderId: props.budgetHeaderId,
          dateAt: formatDateTimeToISOString(props.dateAt),
        };
        await store.dispatch("paymentRequest/getBudgetSummary", payload);
        isLoadingBudget.value = false;
      } catch (error) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: error,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        isLoadingBudget.value = false;
      }
    };
    const setDialog = (dataConfig) => {
      const classMap = {
        success: "p-text-success",
        error: "p-error",
      };

      dataConfig.classTitle = classMap[dataConfig.classTitle] || "";

      dataConfig.button = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "cancel",
        },
      ];

      dataConfigDialog.value = {
        titleConfirm: dataConfig.title,
        classTitle: dataConfig.classTitle,
        wordingConfirm: dataConfig.message,
        dataButtons: dataConfig.button,
        display: true,
      };
    };

    watch(isSharedProduct, async () => {
      checkShouldSetData();
    });

    const budgetSummary = computed(() => {
      return store.getters["paymentRequest/budgetSummary"];
    });
    const productList = computed(() => {
      return store.getters["shared/productApProducts"];
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      closeDialog,
      confirm,
      productDataReady,
      selectProduct,
      selectPeriod,
      calculateStatusExpense,
      calculateStatusPayment,
      calculateTotalAmount,

      productError,
      selectedProduct,
      selectedPeriod,
      remark,
      price,
      priceError,
      quantity,
      quantityError,
      discountDetail,
      discountAverage,
      totalAmount,
      expenseAccumulated,
      expenseBalance,
      paymentAccumulated,
      paymentBalance,
      accountCompare,

      budgetSummary,
    };
  },
};
</script>
