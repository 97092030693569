<template>
  <base-container>
    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchTextPrint"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>

    <base-ap-book-modal
      v-model:visible="showAPBookModal"
      :search="searchText"
      :isSharedLoading="isSharedAPBookReady"
      @close="showAPBookModal = false"
      @apBook-selected="apBookModalSelected"
    ></base-ap-book-modal>

    <div
      class="flex justify-content-between align-items-center"
      style="padding-top: 20px"
    >
      <div class="my-3">
        <PvButton
          icon="pi pi-chevron-left"
          :label="lang.back"
          @click="goBack"
          class="mr-2 p-button-secondary"
          v-if="isHideBack"
        />
      </div>
      <h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
    </div>

    <div class="surface-card shadow-2 p-3 border-round">
      <div class="pt-2 pb-1">
        <PvButton
          icon="pi pi-file"
          :label="lang.create"
          @click="gotoEdit('create')"
          class="mr-2 mb-2"
        />
        <PvButton
          icon="pi pi-list"
          :label="parameterButtonText"
          @click="toggleParameterVisible"
          class="p-button-help mr-2 mb-2"
        />
        <PvButton
          icon="pi pi-replay"
          :label="lang.changeBook"
          @click="changeBook"
          class="mr-2 mb-2"
        />
      </div>

      <transition>
        <div v-if="isParameterVisible">
          <section class="flex flex-wrap" :class="textFormClass">
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
              <label for="dateStart">{{ lang.dateStart }}</label>
              <PvCalendar
                id="dateStart"
                v-model="dateStart"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
              <label for="dateEnd">{{ lang.dateEnd }}</label>
              <PvCalendar
                id="dateEnd"
                v-model="dateEnd"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
              <label for="codeStart">{{ lang.codeStart }}</label>
              <PvInputText
                id="codeStart"
                type="text"
                class="inputfield w-full mt-1"
                placeholder="6501xxxxx"
                v-model="codeStart"
              />
            </div>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
              <label for="codeEnd">{{ lang.codeEnd }}</label>
              <PvInputText
                id="codeEnd"
                type="text"
                class="inputfield w-full mt-1"
                placeholder="6501xxxxx"
                v-model="codeEnd"
              />
            </div>
            <PvButton
              icon="pi pi-search"
              :label="lang.search"
              @click="getPurchaseOrderList"
              class="mr-2 mb-2"
            />
          </section>
        </div>
      </transition>
      <PvDataTable
        showGridlines
        :value="purchaseOrderLists"
        v-model:selection="selectedPurchaseOrder"
        selectionMode="single"
        dataKey="id"
        responsiveLayout="scroll"
        :stripedRows="true"
        class="p-datatable-sm"
        :class="textTableClass"
        :loading="isLoading"
        @row-dblclick="gotoEdit(this.selectedPurchaseOrder.id)"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn
          field="approveStatus"
          :header="lang.columnApprove"
          style="min-width: 2rem"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        ></PvColumn>
		<PvColumn
          field="cancelStatus"
          :header="lang.columnCancelStatus"
          style="min-width: 2rem"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        ></PvColumn>
        <PvColumn
          field="code"
          :header="lang.columnCode"
          style="min-width: 4rem"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        ></PvColumn>
        <PvColumn
          field="date"
          :header="lang.columnDate"
          style="min-width: 3rem"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        >
          <template #body="slotProps">
            {{ formatDate(slotProps.data.date) }}
          </template></PvColumn
        >
        <PvColumn
          field="dueDate"
          :header="lang.columnDueDate"
          style="min-width: 4rem"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        >
          <template #body="slotProps">
            {{ formatDate(slotProps.data.dueDate) }}
          </template></PvColumn
        >
        <PvColumn
          field="supplierCode"
          :header="lang.columnSupplierCode"
          style="min-width: 4rem"
          headerClass="h-center"
          bodyClass="text-left"
          sortable
        ></PvColumn>
		<PvColumn
          field="supplierName"
          :header="lang.columnSupplierName"
          style="min-width: 7rem"
          headerClass="h-center"
          bodyClass="text-left"
          sortable
        ></PvColumn>
		<PvColumn
          field="paidStatus"
          :header="lang.columnPaidStatus"
          style="min-width: 3rem"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        ></PvColumn>
		<PvColumn
          field="netAmount"
          :header="lang.columnNetAmount"
          style="min-width: 5rem"
          headerClass="h-center"
          bodyClass="text-right"
          sortable
          ><template #body="slotProps">
            {{ formatCurrency(slotProps.data.netAmount) }}
          </template>
        </PvColumn>
        <PvColumn>
          <template #body="slotProps">
            <PvButton
              type="button"
              class="p-button-secondary p-button-text py-0"
              icon="pi pi-pencil"
              @click="gotoEdit(slotProps.data.id)"
            ></PvButton>
            <PvButton
              type="button"
              class="p-button-secondary p-button-text py-0"
              icon="pi pi-print"
              @click="selectPrintForm(slotProps.data)"
            ></PvButton>
          </template>
        </PvColumn>
        <template #footer>
          <PvPaginator
            :rows="rowsPerPage"
            :totalRecords="totalRecords"
            @page="onPageChange($event)"
            :rowsPerPageOptions="[10, 50, 100]"
            :first="showCurrentPage"
          >
            <template #start="slotProps">
              {{ lang.page }}:
              {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
            </template>
          </PvPaginator>
        </template>
      </PvDataTable>
    </div>
  </base-container>
</template>
<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetAPBook from "../../../hooks/getAPBook.js";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
export default {
  props: ["apBookId"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { isSharedAPBookReady, searchText, setBookCode } = useGetAPBook();
    const { getFormPrints, searchTextPrint, isSharedFormPrintReady, getPreviewBlob, filteredFormPrints } = useGetFormPrint();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.ap.purchaseOrder.listing };
      } else {
        return { ...lang.en.base, ...lang.en.ap.purchaseOrder.listing };
      }
    });

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,

      textHeaderClass,
      textFormClass,
      textTableClass,

      formatCurrency,
      formatDate,
      formatDateToISOString,

      isSharedAPBookReady,
      searchText,
      setBookCode,

      getFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints,

      isHideBack,
    };
  },
  data() {
    return {
      isLoading: false,
      showAPBookModal: false,
      selectedBookId: "",
      selectedAPBook: null,
      bookCode: "",

      isParameterVisible: true,
      dateStart: "",
      dateEnd: "",
      codeStart: "",
      codeEnd: "",

      selectedPurchaseOrder: null,

      selectedPage: 1,
      rowsPerPage: 10,

      showFormPrintModal: false,
      path: "",
      dataPrint: [],
      categoryPrint: "",

      isChangeBook: false,
    };
  },
  async created() {
    this.setLoading(true);
    let date = new Date();
    this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
    this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    await this.setLocalSearch();
    this.setBookCode("PO");
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
			this.$router.push({name: "home"})
    },
    toggleParameterVisible() {
      this.isParameterVisible = !this.isParameterVisible;
    },
    gotoEdit(Id) {
      this.$router.push({ name: "ap-purchase-order-edit", params: { apBookId: this.selectedBookId, id: Id }});
    },
    apBookModalSelected(data) {
      if (!data) {
        data = this.apBooks[0];
      }
      // console.log("apBookModalSelected=", data);
      this.selectedAPBook = data;
      this.$router.push({ name: "ap-purchase-order-apBookId", params: { apBookId: data.bookId }});
      this.getPurchaseOrderList();
      this.showAPBookModal = false;
    },
    changeBook() {
      this.isChangeBook = true;
      this.setBookCode("PO");
      this.showAPBookModal = true;
    },
    async getPurchaseOrderList() {
      // console.log("getPurchaseOrderList", this.selectedAPBook);
      this.isLoading = true;
      this.selectedBookId = this.selectedAPBook.bookId;
      this.bookCode = this.selectedAPBook.bookCode;

      let where = {
        projectId: this.selectedProject.id,
        dateStart: this.selectedDateStart,
        dateEnd: this.selectedDateEnd,
        codeStart: this.codeStart || null,
        codeEnd: this.codeEnd || null,
        documentBookId: this.selectedBookId,
      };

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };

      //   console.log("getPurchaseOrderList", payload);
      try {
        await this.$store.dispatch("apPurchaseOrder/getPurchaseOrders",payload);
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      // console.log("data", this.purchaseOrderLists);

      where = {
        ...where,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        selectedAPBook: this.selectedAPBook,
        rowsPerPage: this.rowsPerPage,
        selectedPage: this.selectedPage,
      };

      localStorage.setItem(this.storageName,JSON.stringify(where));

      this.isLoading = false;
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getPurchaseOrderList();
    },
    async selectPrintForm(data) {
      this.dataPrint = data;
      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: this.bookCode,
      };
      const payload = { where };
      await this.getFormPrints(payload);

      if(this.filteredFormPrints.length == 1) {
        this.path = this.filteredFormPrints[0].path;
        this.printPurchaseOrder();
      }else{
        this.showFormPrintModal = true;
      }
    },
    async formPrintModalSelected(event) {
      this.path = event.path;
      this.categoryPrint = event.category;
      await this.printPurchaseOrder();
      this.showFormPrintModal = false;
    },
    async printPurchaseOrder() {
      this.setLoading(true);
      const purchaseOrder = {
        id: this.dataPrint.id,
        path: this.path,
      };

      //  console.log("Print=", purchaseOrder);
      try {
        await this.$store.dispatch("apPurchaseOrder/printPurchaseOrder", purchaseOrder);
        
      } catch (error) {
        // console.log("error=", error);
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        this.setLoading(false);
      }
      let blob = this.$store.getters["apPurchaseOrder/blob"];
      // console.log('blob',blob);
      this.getPreviewBlob(blob);

      this.setLoading(false);
    },
    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      if (localSearch) {
        let search = JSON.parse(localSearch);
        //   console.log("localSearch", search);

        if (search.entry === "entry") {
          if (search.dateStart != null && search.dateStart != "") {
            this.dateStart = new Date(search.dateStart);
          } else this.dateStart = "";

          if (search.dateEnd != null && search.dateEnd != "") {
            this.dateEnd = new Date(search.dateEnd);
          } else this.dateEnd = "";

          this.codeStart = search.codeStart || this.codeStart;
          this.codeEnd = search.codeEnd || this.codeEnd;

          this.rowsPerPage = search.rowsPerPage;
          this.selectedPage = search.selectedPage;

          search.entry = "";

          // localStorage.setItem("erp_search_ap_purchase_order_List", JSON.stringify(search));
        }
        this.selectedAPBook = search.selectedAPBook;
        this.selectedBookId = search.selectedAPBook.bookId;
      }
    },
  },
  computed: {
    title() {
      return this.lang.title + " : " + this.bookCode;
    },
    parameterButtonText() {
      return this.isParameterVisible
        ? this.lang.hideParameters
        : this.lang.showParameters;
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    totalPage() {
      return this.$store.getters["apPurchaseOrder/totalPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    purchaseOrderLists() {
      return this.$store.getters["apPurchaseOrder/purchaseOrders"];
    },
    apBooks() {
      return this.$store.getters["shared/apBooks"];
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    storageName() {
      return `erp_search_PurchaseOrderList_${this.selectedProject.code}_${this.userCode}`;
    },
    showCurrentPage() {
      let page = 0;
      page = this.rowsPerPage * (this.selectedPage - 1);
      return page;
    },
  },
  watch: {
    apBooks(curValue) {
      // console.log('watch');
      if (curValue.length == 1 && !this.isChangeBook) {
        this.setLoading(false);
        this.apBookModalSelected();
      } else if (curValue.length > 1 && !this.isChangeBook) {
        this.setLoading(false);
        if (this.apBookId) {
          this.showAPBookModal = false;
          this.apBookModalSelected();
        } else {
          this.showAPBookModal = true;
        }
      }
    },
    apBookId(curValue) {
      if (curValue == null) {
        if (this.apBooks.length == 1 && !this.isChangeBook) {
          this.setLoading(false);
          this.apBookModalSelected();
        }
        else this.showAPBookModal = true;
      }
    },
  },
};
</script>
