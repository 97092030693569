export default {
  taxBookTaxBooks(state) {
    return state.taxBook.taxBooks;
  },
  taxBookCurrentPage(state) {
    return state.taxBook.currentPage;
  },
  taxBookNextPage(state) {
    return state.taxBook.currentPage + 1;
  },
  taxBookTotalPage(state) {
    return state.taxBook.totalPage;
  },
  taxBookIsLastPage(_, getters) {
    return getters.taxBookCurrentPage === getters.taxBookTotalPage && getters.taxBookTotalPage !== 0;
  },
  taxTypes(state) {
    return state.taxTypes;
  },
};
