<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">{{ title }}</label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>
    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="productStart">{{ lang.productStart }}</label>
          <base-product-ar-auto-complete
            id="productStart"
            v-model="selectedProductStart"
            class="inputfield w-full mt-1"
            @load-finish="productDataReady"
            filterType="keycard"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="productEnd">{{ lang.to }}</label>
          <base-product-ar-auto-complete
            id="productEnd"
            v-model="selectedProductEnd"
            class="inputfield w-full mt-1"
            @load-finish="productDataReady"
            filterType="keycard"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateStart">{{ `${lang.updateDate} ${lang.from}` }}</label>
          <PvCalendar
            id="dateStart"
            v-model="dateStart"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateStartError }"
            @date-select="dateStartError = false"
          />
          <small v-if="dateStartError" id="date-help" class="p-error">
            {{ lang.validateDateSelect }}
          </small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="dateEnd">{{ lang.to }}</label>
          <PvCalendar
            id="dateEnd"
            v-model="dateEnd"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateEndError }"
            @date-select="dateEndError = false"
          />
          <small v-if="dateEndError" id="date-help" class="p-error">
            {{ lang.validateDateSelect }}
          </small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText
            id="fileNameText"
            type="text"
            class="inputfield w-full mt-1"
            v-model="fileNameText"
          />
        </div>
      </section>
    </transition>
    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
        </div>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../../hooks/lang.js";
import useTextStyle from "../../../../hooks/textStyle.js";
import useFormat from "../../../../hooks/format.js";
import useTracking from "../../../../hooks/tracking";
import { useStore } from "vuex";
import { ref, onMounted, computed } from "vue";
import useReportRequestValidate from "../../../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "toast-request"],
  setup(props, { emit }) {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const { formatDateToISOString } = useFormat();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
        };
      }
    });

    //parameters
    let isSharedProductReady = ref(false);
    let selectedProductStart = ref(null);
    let selectedProductEnd = ref(null);
    let dateStart = ref(null);
    let dateEnd = ref(null);
    let dateStartError = ref(false);
    let dateEndError = ref(false);
    let type = ref("PDF");
    let fileNameText = ref("stock_keycard_movement");

    onMounted(() => {
      setLoading(true);
      initiateData();
    });

    //computeds
    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const userId = computed(() => {
      return store.getters.userId;
    });
    const userName = computed(() => {
      return store.getters.userName;
    });
    const title = computed(() => {
      if (store.getters.selectedLanguage === "TH") {
        return props.reportObj.nameThai;
      }
      return props.reportObj.nameEnglish;
    });
    const products = computed(() => {
      return store.getters["shared/productArProducts"];
    });
    const keycardProducts = computed(() => {
      return products.value.filter((product) => {
        return product.code.substring(0, 2).toLowerCase() === "kc";
      });
    });
    const selectedDateStart = computed(() => {
      let date = null;
      if (dateStart.value) {
        date = formatDateToISOString(dateStart.value);
      }
      return date;
    });
    const selectedDateEnd = computed(() => {
      let date = null;
      if (dateEnd.value) {
        date = formatDateToISOString(dateEnd.value);
      }
      return date;
    });
    const fileName = computed(() => {
      let fileName = type.value == "PDF" ? ".pdf" : ".xlsx";
      return fileNameText.value + fileName;
    });
    const fileType = computed(() => {
      let fileType =
        type.value == "EXCEL"
          ? "_excel.xlsx"
          : type.value == "JASPEREXCEL"
          ? ".xlsx"
          : ".pdf";
      return fileType;
    });
    const payload = computed(() => {
      let payload = {
        ProjectId: selectedProject.value.id,
        ProductCodeStart: selectedProductStart.value
          ? selectedProductStart.value.code
          : keycardProducts.value[0].code,
        ProductCodeEnd: selectedProductEnd.value
          ? selectedProductEnd.value.code
          : keycardProducts.value[keycardProducts.value.length - 1].code,
        DateStart: selectedDateStart.value,
        DateEnd: selectedDateEnd.value,
        FileName: fileName.value,
        JasperPath: props.reportObj.path + fileType.value,
        DocType: type.value,
        UserId: userId.value,
        PrintBy: userName.value,
        RequestId: 0,
      };
      return payload;
    });

    //method
    const setLoading = (bool) => {
      store.dispatch("setLoading", { value: bool });
    };

    const closeDialog = () => {
      emit("close");
      initiateData();
    };

    const initiateData = () => {
      let date = new Date();
      isSharedProductReady.value = false;
      selectedProductStart.value = null;
      selectedProductEnd.value = null;
      dateStart.value = new Date(date.getFullYear(), date.getMonth(), 1);
      dateEnd.value = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      dateStartError.value = false;
      dateEndError.value = false;
      type.value = "PDF";
      fileNameText.value = "stock_keycard_movement";
    };

    const productDataReady = () => {
      isSharedProductReady.value = true;
      checkShouldSetData();
    };

    const checkShouldSetData = () => {
      if (isSharedProductReady.value) {
        setData();
      }
    };

    const setData = () => {
      if (isSharedProductReady.value) {
        selectedProductStart.value = keycardProducts.value[0];
        selectedProductEnd.value =
          keycardProducts.value[keycardProducts.value.length - 1];
        setLoading(false);
      }
    };

    const validateData = () => {
      let validateError = false;
      if (selectedDateStart.value == null) {
        dateStartError = true;
        validateError = true;
      }
      if (selectedDateEnd.value == null) {
        dateEndError.value = true;
        validateError = true;
      }
      return validateError;
    };

    return {
      lang: mergeLang,
      textFormClass,
      formatDateToISOString,
      addReportTracking,
      reportPrintOrder,

      //parameters
      selectedProductStart,
      selectedProductEnd,
      dateStart,
      dateEnd,
      dateStartError,
      dateEndError,
      type,
      fileNameText,
      payload,

      //computeds
      title,
      selectedProject,

      //method
      setLoading,
      closeDialog,
      productDataReady,
      validateData,
    };
  },
  methods: {
    async download(typeReport) {
      this.type = typeReport;
      if (this.validateData()) {
        return;
      }
      this.setLoading(true);
      try {
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: "error",
          life: 3000,
        });
        this.setLoading(false);
      }
    },
  },
};
</script>
