<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoAskForCancel="askForCancel"
    @gotoAskForCreate="askForCreate"
    @gotoPrint="selectPrintForm"
    @gotoSave="save"
    
  >
  </base-toolbar>

  <base-dialog
    v-model:visible="showValidateDialog"
    :titleConfirm="titleConfirm"
    :classTitle="baseDialogClassTitle"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    :isCloseDialog="true"
    @confirm="confirmDialog"
    @close="closeBaseDialog"
    @gotoCancel="cancelInvoice"
    @gotoCreate="createInvoice"
    @goto-copy-item="copyItemSelected"
  >
    <template #dialogBody>
      <div class="lg:col-12 md:col-12 col-12 p-0 w-full" v-if="isCopyItem">
        <label for="copyQuantity">{{ lang.generateProduct }}</label>
        <PvInputNumber
          id="copyQuantity"
          class="mt-1 w-full"
          v-model="copyQuantity"
          mode="decimal"
          @focus="$event.target.select()"
          :min="1"
          :max="100"
          inputClass="w-full text-right"
          showButtons
        />
      </div>
    </template>
  </base-dialog>

  <invoice-product-dialog
    v-model:visible="showProductDialog"
    :data="selectedDetail"
    :openDialog="openDialog"
    @close="showProductDialog = false"
    @product-selected="productModalSelected"
  ></invoice-product-dialog>

  <base-form-print-modal
    v-model:visible="showFormPrintModal"
    :search="searchTextPrint"
    @close="showFormPrintModal = false"
    @path-selected="formPrintModalSelected"
  ></base-form-print-modal>

  <base-container class="mb-5">
    <div
      class="surface-card shadow-2 p-5 border-round mt-5"
      :class="textFormClass"
    >
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.descriptionInvoice }}</label>
        <!-- <div>
          <PvButton
            class="p-button-toolbar ml-2"
            @click="selectPrintForm"
            :disabled="this.isCreateDocument"
          >
            <icon-printer
              class="p-button-outlined p-button-primary mr-2"
              iconColor="white"
              height="16"
              width="16"
            />
            <span style="font-size: 14px">
              {{ lang.printInvoice }}
            </span>
          </PvButton>
        </div> -->
      </div>
      <PvDivider></PvDivider>
      <section class="flex flex-wrap mb-1">
        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
          <label for="code">{{ lang.code }}</label>
          <PvInputText
            id="code"
            type="text"
            class="inputfield w-full mt-1 input-disable"
            v-model="code"
            readonly
          />
        </div>

        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
          <label for="unit">{{ lang.searchUnit }}</label>
          <PvSkeleton
            v-if="!isSharedUnitReady"
            class="inputfield w-full mt-1"
            height="39px"
          />
          <base-unit-auto-complete
            id="unit"
            v-model="selectedUnit"
            class="inputfield w-full mt-1"
            @load-finish="unitDataReady"
            @item-select="unitSelected()"
            :class="{ 'p-invalid': isUnitError }"
            v-show="isSharedUnitReady"
            :disabled="!isCreateDocument"
            :isValidateActiveStatus="true"
            @is-inactive="initiateData"
          />
          <small v-if="isUnitError" id="unit-help" class="p-error">{{
            lang.validateNoUnitSelect
          }}</small>
        </div>
        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
          <label for="date">{{ lang.date }}</label>
          <PvCalendar
            id="date"
            v-model="date"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateError }" 
            @date-select="dateError = false"
          />
          <small v-if="dateError" id="date-help" class="p-error">
            {{ lang.validateDate }}
          </small>
        </div>
        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
          <label for="dueDate">{{ lang.dueDate }}</label>
          <PvCalendar
            id="dueDate"
            v-model="dueDate"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dueDateError }" 
            @date-select="dueDateError = false"
          />
          <small v-if="dueDateError" id="date-help" class="p-error">
            {{ lang.validateDueDate }}
          </small>
        </div>
        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
          <label for="unitAddress">{{ lang.address }}</label>
          <PvInputText
            id="unitAddress"
            type="text"
            class="inputfield w-full mt-1 input-disable"
            v-model.trim="unitAddress"
            readonly
          />
        </div>
        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
          <label for="customerName">{{ lang.customerName }}</label>
          <PvInputText
            id="customerName"
            type="text"
            class="inputfield w-full mt-1 input-disable"
            v-model.trim="customerName"
            readonly
          />
        </div>
        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
          <label for="customerMobileNumber">{{ lang.contactNumber }}</label>
          <PvInputText
            id="customerMobileNumber"
            type="text"
            class="inputfield w-full mt-1 input-disable"
            v-model.trim="contactNumber"
            readonly
          />
        </div>
        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
          <!-- <PvButton
            :label="lang.showhideRemark"
            @click="toggleRemarkVisible()"
            class="p-button-link lg:mt-4 md:mt-0 "
          /> -->
          <div class="lg:mt-5 md:mt-5 sm:mt-2 pt-1">
            <span class="p-text-link p-2"
              :style="{ cursor: 'pointer' }"
              @click="toggleRemarkVisible"
            >
              {{ showHideRemarkTitle }}
            </span>
          </div>
        </div>
        <div v-if="isRemarkVisible" class="flex flex-wrap mb-3 w-full">
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
            <label for="remark1">{{ lang.remark1 }}</label>
            <PvInputText
              id="remark1"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="remark1"
            />
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
            <label for="remark2">{{ lang.remark2 }}</label>
            <PvInputText
              id="remark2"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="remark2"
            />
          </div>
        </div>
      </section>
      <div class="flex flex-wrap justify-content-between align-items-center mt-2">
        <label class="font-lg font-normal">{{ lang.titleDetail }}</label>
        <div>
          <PvButton
            class="p-button-outlined ml-2"
            @click="addUpdateItem('create')"
            :disabled="!this.isSharedUnitReady"
          >
            <icon-plus-add
              class="p-button-outlined p-button-primary mr-2"
              iconColor="primary"
              height="16"
              width="16"
            />
            <span style="font-size: 14px">
              {{ lang.addProduct }}
            </span>
          </PvButton>
        </div>
      </div>

      <div v-if="detailError" class="pb-2">
        <PvInlineMessage severity="error" class="w-full justify-content-start">{{ lang.validateDetail}}</PvInlineMessage>
      </div>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="details"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          @row-dblclick="addUpdateItem(this.selectedDetail)"
          v-model:selection="selectedDetail"
          :loading="isLoading"
          scrollHeight="294px"
        >
          <!-- :scrollHeight="halfViewportHeight + 'px'" -->
          <PvColumn
            field="itemNo"
            :header="lang.itemNo"
            style="min-width: 4rem; height: 45px; "
            headerClass="h-center "
            bodyClass="text-center tableBodyTop"
          >
          </PvColumn>
          <PvColumn
            field="productCode"
            :header="lang.productCode"
            style="min-width: 7rem; "
            headerClass="h-left"
            bodyClass="text-left tableBodyTop"
          >
          </PvColumn>
          <PvColumn
            field="productName"
            :header="lang.productName"
            style="min-width: 10rem; "
            headerClass="h-left"
            bodyClass="text-left tableBodyTop"
          >
          </PvColumn>
          <PvColumn
            field="remark"
            :header="lang.description"
            style="min-width: 10rem; "
            headerClass="h-left"
            bodyClass="text-left tableBodyTop"
          >
          </PvColumn>
          <PvColumn
            field="period"
            :header="lang.columnPeriod"
            style="min-width: 4rem; "
            headerClass="h-left"
            bodyClass="text-left tableBodyTop"
          >
          </PvColumn>
          <PvColumn
            field="unitPrice"
            :header="lang.columnPrice"
            style="min-width: 4rem; "
            headerClass="h-right"
            bodyClass="text-right tableBodyTop"
          >
            <template #body="slotProps">
              {{ formatCurrencyTable(slotProps.data, "unitPrice") }}
            </template>
          </PvColumn>
          <PvColumn
            field="quantity"
            :header="lang.columnQuantity"
            style="min-width: 4rem;"
            headerClass="h-right"
            bodyClass="text-right tableBodyTop"
          >
            <template #body="slotProps">
              {{ formatCurrencyTable(slotProps.data, "quantity") }}
            </template>
          </PvColumn>
          <PvColumn
            field="totalAmount"
            :header="lang.columnTotalAmount"
            style="min-width: 5rem;"
            headerClass="h-right"
            bodyClass="text-right tableBodyTop"
          >
            <template #body="slotProps">
              {{ formatCurrencyTable(slotProps.data, "totalAmount") }}
            </template>
          </PvColumn>
          <PvColumn
            field="paidAmount"
            :header="lang.paidAmount"
            style="min-width: 7rem;"
            headerClass="h-right"
            bodyClass="text-right tableBodyTop"
          >
            <template #body="slotProps">
              {{ formatCurrencyTable(slotProps.data, "paidAmount") }}
            </template>
          </PvColumn>
          <PvColumn
            style="min-width: 11rem; vertical-align: top;"
            headerClass="h-center"
            bodyStyle="text-align: center; overflow: visible tableBodyTop"
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3 mt-2"
                style="background: white; border: white"
                @click="addUpdateItem(slotProps.data)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3 mt-2"
                style="background: white; border: white"
                @click="generateItem(slotProps.data)"
              >
                <icon-file-blank-copy-add-plus
                  iconColor="secondary"
                  width="24"
                  height="24"
                />
              </PvButton>
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3 mt-2"
                style="background: white; border: white"
                @click="deleteItem(slotProps.data.id)"
              >
                <icon-trash-delete-bin
                  iconColor="secondary"
                  width="24"
                  height="24"
                />
              </PvButton>
            </template>
          </PvColumn>

          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>
        </PvDataTable>
      </div>
      <div class="flex flex-wrap justify-content-between align-items-center">
        <div></div>
        <div>
          <div class="flex flex-wrap">
            <label class="pr-2 pt-3">{{ this.lang.totalAmount }}</label>
            <PvInputNumber
              class="inputfield"
              inputClass="input-number-disable text-right w-full"
              v-model="sumDetailTotalAmount"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
    
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import usePageDirty from "../../../hooks/pageDirty";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useGetRoleByBook from "../../../hooks/getRoleByBook.js";
import { useStore } from "vuex";
import { computed } from "vue";
import InvoiceProductDialog from "../../../components/AR/invoice/InvoiceProductDialog.vue";
export default {
  props: ["id", "projectCode"],
  components: {
    InvoiceProductDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textFormClass, textTableClass, textAlertClass } = useTextStyle();
    const { formatCurrencyTable, formatDate, formatDateToISOString } =
      useFormat();

    const { compareObject, isCompareObject } = usePageDirty();
    const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();

    const {
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
    } = useGetFormPrint();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.invoice.listing,
          ...lang.th.ar.invoice.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.invoice.listing,
          ...lang.en.ar.invoice.edit,
        };
      }
    });
    return {
      lang: mergeLang,
      textFormClass,
      textTableClass,
      textAlertClass,
      formatCurrencyTable,
      formatDate,
      formatDateToISOString,
      compareObject,
      isCompareObject,
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
      getRoleByBookByCode,
      isSharedRoleByBookReady,
    };
  },
  data() {
    return {
      code: "",
      date: null,
      dueDate: null,
      unit: null,
      selectedUnit: null,
      isUnitError: false,
      customerName: "",
      createBy: "",
      updateBy: "",
      unitAddress: "",
      contactNumber: "",
      details: null,
      selectedDetail: null,
      isSharedUnitReady: false,
      showProductDialog: false,
      copyItems: null,
      totalAmount: null,
      remark1: "",
      remark2: "",
      openDialog: "",
      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: this.lang.notificationDialogHeader,
      validateWording: "",
      showFormPrintModal: false,
      path: "",
      dataPrint: [],
      categoryPrint: "",
      dataButtonDialog: [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
        {
          id: "confirm",
          caption: this.lang.confirm,
          class: "w-full",
          action: "confirm",
        },
      ],

      objToCompare: null,
      storageName: "",
      isLoading: false,
      isRemarkVisible: false,

      isCopyItem: false,
      copyQuantity: 1,

      detailError: false,
      dateError: false,
      dueDateError: false,
    };
  },
  async mounted() {
    this.setLoading(true);
    this.initiateData();
    if (!this.isCreateDocument) {
      await this.getInvoice();
    }
    this.checkShouldSetData();
    this.storageName = `erp_search_InvoiceList_${this.selectedProject.code}_${this.userCode}`;
  },
  methods: {
    goBack() {
      const localSearch = localStorage.getItem(this.storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        invoiceEntry: "Entry",
      };
      localStorage.setItem(this.storageName, JSON.stringify(search));
      this.$router.push({ name: "ar-invoice"}) 
    },
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    async getInvoice() {
      try {
        const where = {
          id: this.id,
        };
        await this.$store.dispatch("arInvoice/getInvoice", { where });
      } catch (error) {
        this.setLoading(false);
        this.openAlertDialogError(error);
      }
    },
    async askForCreate() {
      if(!this.isRoleInsert) {
        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "confirm",
          },
        ];
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return true;
      }
      let result = this.isCompareObject(this.objToCompare, this.payload);
      if (result) {
        this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "gotoCreate",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "gotoCreate",
          },
        ];

        this.titleConfirm = this.lang.createNewInvoice;
        this.validateWording = [
          this.lang.askForCreateNewInvoice,
          this.lang.askForCreateNewInvoiceConfirm,
        ];
        this.showValidateDialog = true;
      } else {
        await this.createInvoice();
      }
    },
    async createInvoice() {
      this.setLoading(true);
      this.initiateData();
      await this.$router.push({ name: "ar-invoice-edit", params: { id: "create" } })
      this.setCompareData();
      this.showValidateDialog = false;
      this.setLoading(false);
    },
    unitDataReady() {
      this.isSharedUnitReady = true;
      this.checkShouldSetData();
    },
    async setDataUnit() {
      this.selectedUnit = this.sharedUnits.find(
        (unit) => unit.id === this.invoice.unitId
      );
    },
    async initiateData(unselectUnit = true) {
      if (unselectUnit) {
        this.selectedUnit = null;
      }
      this.code = "";
      this.date = new Date();
      this.dueDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth() + 1,
        0
      );
      this.customerName = "";
      this.remark1 = "";
      this.remark2 = "";
      this.createBy = "";
      this.updateBy = "";
      this.totalAmount = null;
      this.details = [];
      this.selectedDetail = null;
      this.copyItems = null;
      this.unitAddress = "";
      this.contactNumber = "";
      this.baseDialogClassTitle = "";
      this.isCopyItem = false;
      this.copyQuantity = 1;
      this.$store.dispatch("arInvoice/resetInvoice");
    },
    setData() {
      // this.selectedUnit = this.sharedUnits.find(
      //   (unit) => unit.id === this.invoice.unitId
      // );
      this.code = this.invoice.code;
      this.date = new Date(this.invoice.date);
      this.dueDate = new Date(this.invoice.dueDate);
      this.customerName = this.invoice.customerName;
      this.createBy = this.invoice.createBy;
      this.updateBy = this.invoice.updateBy;
      this.details = this.invoice.invoiceDetails;
      this.totalAmount = this.invoice.totalAmount;
      this.unitAddress = this.invoice.unitAddressNo;
      this.contactNumber = this.invoice.customerMobileNumber;
      this.remark1 = this.invoice.remark1;
      this.remark2 = this.invoice.remark2;

      this.setLoading(false);
      this.isLoading = false;
    },
    async unitSelected() {
      this.isUnitError = false;
      await this.initiateData(false);

      this.customerName = this.selectedUnit.customerName;
      this.unitAddress = this.selectedUnit.addressNo;
      this.contactNumber = this.selectedUnit.customerMobilePhone;
    },
    async checkShouldSetData() {
      if (this.invoice && this.isSharedRoleByBookReady) {
        this.setData();
      }

      if (
        this.isSharedUnitReady &&
        this.invoice &&
        this.isSharedRoleByBookReady
      ) {
        this.setDataUnit();
      } else if (
        this.isSharedUnitReady &&
        this.isCreateDocument &&
        this.isSharedRoleByBookReady
      ) {
        this.setLoading(false);
      }

      await this.setCompareData();
    },
    addUpdateItem(data) {
      if (this.validateDocument()) {
        return;
      }
      if (data == "create") {
        this.selectedDetail = null;
      } else {
        this.selectedDetail = data;
      }

      this.openDialog = new Date().getSeconds().toString();

      // if (!this.isDocumentLocked) {
      //   this.showProductDialog = true;
      // }
      this.showProductDialog = true;
    },
    generateItem(data) {
      if (this.validateDocument()) {
        return;
      }
      this.dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
        {
          id: "gotoCopyItem",
          caption: this.lang.buttonDialogConfirm,
          class: "w-full",
          action: "goto-copy-item",
        },
      ];

      this.isCopyItem = true;
      this.copyItems = data;
      this.titleConfirm = this.lang.addEdit;
      this.validateWording = "";
      this.showValidateDialog = true;
    },
    deleteItem(id) {
      if (this.validateDocument()) {
        return;
      }
      const index = this.details.findIndex((detail) => detail.id === id);
      this.details.splice(index, 1);
      this.updateItemNumber();
    },
    updateItemNumber() {
      let number = 1;
      this.details.forEach((element) => {
        if (element.productId) {
          element.itemNo = number;
          number++;
        }
      });
    },
    productModalSelected(product) {
      if (product) {
        if (product.id) {
          const detail = this.details.find(
            (detail) => detail.id === product.id
          );
          if (detail) {
            detail.productId = product.productId;
            detail.productCode = product.productCode;
            detail.productName = product.productName;
            detail.quantity = product.quantity;
            detail.unitPrice = product.unitPrice;
            detail.totalAmount = product.totalAmount;
            detail.remark = product.remark;
            detail.paidAmount = 0;
            detail.period = product.period;
          }
        } else {
          this.details.push({
            id: "dummy" + (parseInt(this.details.length) + 1),
            headerId: null,
            itemNo: parseInt(this.details.length) + 1,
            productId: product.productId,
            productCode: product.productCode,
            productName: product.productName,
            quantity: product.quantity,
            unitPrice: product.unitPrice,
            totalAmount: product.totalAmount,
            remark: product.remark,
            paidAmount: 0,
            period: product.period,
            idforComputePenalty: null,
            amountforComputePenalty: null,
            ratePenalty: null,
            amountPenalty: null,
            tenantId: "",
          });
        }
      }

      this.updateItemNumber();
      this.showProductDialog = false;
      this.detailError = false;
    },
    copyItemSelected() {
      if (this.copyItems) {
        let period = this.periodToDate(this.copyItems.period);
        for (let index = 1; index <= this.copyQuantity; index++) {
          let periodNext = new Date(
            period.getFullYear(),
            period.getMonth() + 1 + index,
            0
          );
          //console.log("periodNext=", (periodNext.getMonth()+1).toString().padStart(2, "0")+"/"+periodNext.getFullYear().toString() );
          this.details.push({
            id: "dummy" + (parseInt(this.details.length) + 1),
            headerId: this.copyItems.headerId,
            itemNo: parseInt(this.details.length) + 1,
            productId: this.copyItems.productId,
            productCode: this.copyItems.productCode,
            productName: this.copyItems.productName,
            quantity: this.copyItems.quantity,
            unitPrice: this.copyItems.unitPrice,
            totalAmount: this.copyItems.totalAmount,
            remark: this.copyItems.remark,
            paidAmount: 0,
            period:
              (periodNext.getMonth() + 1).toString().padStart(2, "0") +
              "/" +
              periodNext.getFullYear().toString(),
            idforComputePenalty: null,
            amountforComputePenalty: null,
            ratePenalty: null,
            amountPenalty: null,
            tenantId: "",
          });
        }
      }
      
      this.showValidateDialog = false;
      this.isCopyItem = false;
      this.copyQuantity = 1;
    },
    periodToDate(data) {
      let month = data.substring(0, 2);
      let year = data.slice(-4);
      //let year = data.substring(3, 4);
      const period = new Date(year + "-" + month + "-01");
      return period;
    },
    validateRole() {
      this.dataButtonDialog = [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];
      if(this.isCreateDocument && !this.isRoleInsert) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return true;
      } else if(!this.isCreateDocument && !this.isRoleUpdate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateRoleUpdate;
        this.showValidateDialog = true;
        return true;
      }
      return false;
    },
    validateDocument(isCheck) {
      this.dataButtonDialog = [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];
      let returnStatus = false;
      if(this.invoice) {
        if(this.invoice.cancelStatus.trim().toLowerCase() === "c") {
          this.validateWording = this.lang.validateCancelled;
          returnStatus = true;
        } else if(this.invoice.postStep.trim().toLowerCase() === "a") {
          this.validateWording = this.lang.validateReferenceVoucher;
          returnStatus = true;
        } else if(isCheck && this.invoice.postStep.trim().toLowerCase() === "p") {
          this.validateWording = this.lang.validatePrinted;
          returnStatus = true;
        } else if(this.invoice.paidStatus.trim()) {
          this.validateWording = this.lang.validateInvoiceIsPaid;
          returnStatus = true;
        }
      }
      if(returnStatus) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.showValidateDialog = true;
      }
      return returnStatus;
    },
    async save() {
      if (this.validateRole()) {
        return;
      }
      if (this.validateDocument(true)) {
        return;
      }
      if (this.validateData()) {
        return;
      }
      this.setLoading(true);
      try {
        await this.$store.dispatch(
          "arInvoice/createOrUpdateInvoice",
          this.payload
        );

        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "confirm",
          },
        ];
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.saveSuccess;
        this.validateWording = this.saveEditSuccess;
        this.showValidateDialog = true;

        if (this.isCreateDocument) {
          await this.$router.push({ name: "ar-invoice-edit", params: { id: this.invoice.id } })
          await this.setCompareData();
        }
      } catch (error) {
        this.openAlertDialogError(error);
      }
      this.setLoading(false);
    },
    wordingValidate() {
      if(!this.selectedUnit) {
        return this.lang.validateDropDownNoSelect + this.lang.unit;
      }

      if(!this.date) {
        return this.lang.validateDropDownNoSelect + this.lang.date;
      }

      if(!this.dueDate) {
        return this.lang.validateDropDownNoSelect + this.lang.dueDate;
      }

      if(this.dueDate < this.date) {
        return this.lang.validateDueDateIsInvalid;
      }

      if (this.selectedUnit && this.selectedUnit.customerId == "") {
        return this.lang.validateCustomerId;
      }

      if (this.employee && this.employee.id == "") {
        return this.lang.validateEmployeeId;
      }

      if (this.details.length <= 0) {
        return this.lang.validateDetail;
      }

      if (this.sumDetailTotalAmount < 0) {
        return this.lang.validateTotalAmount;
      }
    },
    validateData() {
      // if (!this.selectedUnit) {
      //   this.$swal({
      //     icon: "warning",
      //     title: this.lang.validateNoUnitSelect,
      //     confirmButtonText: this.lang.close,
      //     allowOutsideClick: false,
      //   });
      //   this.isUnitError = true;
      //   return true;
      // }
      // if (this.selectedUnit.isActive == "N") {
      //   if (this.isCreateDocument) {
      //     this.$swal({
      //       icon: "warning",
      //       title: this.lang.validateInactiveUnitSelect,
      //       confirmButtonText: this.lang.close,
      //       allowOutsideClick: false,
      //     });
      //     this.selectedUnit = null;
      //     this.isUnitError = true;
      //   }
      //   return true;
      // }

      // if (this.selectedUnit && this.selectedUnit.customerId == "") {
      //   this.$swal({
      //     icon: "warning",
      //     title: this.lang.validateCustomerId,
      //     confirmButtonText: this.lang.close,
      //     allowOutsideClick: false,
      //   });
      //   return true;
      // }

      // if (this.employee && this.employee.id == "") {
      //   this.$swal({
      //     icon: "warning",
      //     title: this.lang.validateEmployeeId,
      //     confirmButtonText: this.lang.close,
      //     allowOutsideClick: false,
      //   });
      //   return true;
      // }

      // if (this.details.length <= 0) {
      //   this.$swal({
      //     icon: "warning",
      //     title: this.lang.validateDetailSelect,
      //     confirmButtonText: this.lang.close,
      //     allowOutsideClick: false,
      //   });
      //   return true;
      // }

      // if (this.sumDetailTotalAmount < 0) {
      //   this.$swal({
      //     icon: "warning",
      //     title: this.lang.validateTotalAmount,
      //     confirmButtonText: this.lang.close,
      //     allowOutsideClick: false,
      //   });
      //   return true;
      // }
      
      let returnStatus = false;

      if(!this.selectedUnit) {
        this.isUnitError = true;
        returnStatus = true;
      }

      if(!this.date) {
        this.dateError = true;
        returnStatus = true;
      }

      if(!this.dueDate) {
        this.dueDateError = true;
        returnStatus = true;
      }

      if(this.dueDate < this.date) {
        this.dueDateError = true;
        returnStatus = true;
      }


      if (this.selectedUnit && this.selectedUnit.customerId == "") {
        returnStatus = true;
      }

      if (this.employee && this.employee.id == "") {
        returnStatus = true;
      }

      if (this.details.length <= 0) {
        this.detailError = true;
        returnStatus = true;
      }

      if (this.sumDetailTotalAmount < 0) {
        returnStatus = true;
      }

      if(returnStatus) {
        this.baseDialogClassTitle = "p-error";
        this.titleConfirm = this.lang.validateSaveTitle;
        this.validateWording = this.wordingValidate();
        this.showValidateDialog = true;
      }

      return returnStatus;
    },
    async askForCancel() {
      if (this.validateDocument()) {
        return;
      }
      if (!this.isRoleCancel) {
        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "confirm",
          },
        ];
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleCancel;
        this.showValidateDialog = true;
        return true;
      }
      this.dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
        {
          id: "gotoCancel",
          caption: this.lang.buttonDialogConfirm,
          class: "w-full",
          action: "gotoCancel",
        },
      ];
      this.titleConfirm = this.lang.cancelDocument;
      this.validateWording = `${this.lang.askForCancelInvoiceDocument.replace(
        ":code",
        this.code
      )}`;
      this.showValidateDialog = true;
    },
    async cancelInvoice() {
      this.setLoading(true);
      try {
        await this.$store.dispatch("arInvoice/cancelInvoice", {
          id: this.id,
        });

        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "confirm",
          },
        ];

        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.cancelSuccess;
        this.validateWording = `${this.lang.cancelInvoiceSuccess.replace(
          ":code",
          this.code
        )}`;
        this.showValidateDialog = true;
      } catch (error) {
        this.openAlertDialogError(error);
      }
      this.setLoading(false);
    },
    async selectPrintForm() {
      if (!this.isRolePrint) {
        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "confirm",
          },
        ];
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validatePrintPermission;
        this.showValidateDialog = true;
        return true;
      }
      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: "BILL",
      };
      const payload = { where };
      await this.getFormPrints(payload);
      if (this.filteredFormPrints.length == 1) {
        this.path = this.filteredFormPrints[0].path;
        this.printInvoice();
      } else {
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path;
      this.categoryPrint = event.category;
      this.printInvoice();
      this.showFormPrintModal = false;
    },
    async printInvoice() {
      this.setLoading(true);

      let parameter = {
        DocumentType: "BILL",
        CustomerType: "owner",
        InvoiceDateStart: "",
        InvoiceDateEnd: "",
        ProjectCode: this.selectedProject.code,
        OrderBy: "doc_code",
        InvoiceCodeStart: "",
        InvoiceCodeEnd: "",
        UnitCodeStart: "",
        UnitCodeEnd: "",
        CustomerGroup: "",
        IsIgnoreZero: "N",
        InvoiceId: this.id,
        IsExcludeMultiple: "N",
        CustomerCode: "",
        IsIgnoreBillZero: "N",
        IsForApi: "true",
      };

      let payload = {
        DocumentType: "BILL",
        InvoiceId: this.id,
        path: this.path + ".pdf",
        fileName: "Invoice_" + this.code.trim(),
        parameter: JSON.stringify(parameter),
      };

      let blob = null;
      try {
        if (this.invoice && this.invoice.postStep == "P") {
          await this.$store.dispatch("shared/printForm", payload);
          blob = this.$store.getters["shared/blob"];
        } else {
          await this.$store.dispatch("arInvoice/PrintInvoice", payload);
          blob = this.$store.getters["arInvoice/blob"];
        }
        await this.getInvoice();
      } catch (error) {
        this.openAlertDialogError(error);
        this.setLoading(false);
      }

      this.getPreviewBlob(blob);
      this.setLoading(false);
    },
    async setCompareData() {
      let details = [];
      if (this.details) {
        for (const each of this.details.filter((each) => each.productId)) {
          let detail = null;
          if (each.id.includes("dummy")) {
            detail = { ...each, id: null };
          } else {
            detail = { ...each };
          }
          details.push(detail);
        }
      }

      let objToCompare = {
        projectId: this.selectedProject.id,
        unitId: this.selectedUnit ? this.selectedUnit.id : null,
        customerId: this.selectedUnit ? this.selectedUnit.customerId : null,
        employeeId: this.employee.id,
        date: this.date ? this.formatDateToISOString(this.date) : null,
        duedate: this.dueDate ? this.formatDateToISOString(this.dueDate) : null,
        remark1: this.remark1,
        remark2: this.remark2,
        details: details,
        type: "Add",
      };
      if (!this.isCreateDocument) {
        objToCompare = {
          ...objToCompare,
          id: this.id,
          type: "Update",
          employeeId: this.invoice ? this.invoice.employeeId : null,
        };
      }
      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
    },
    confirmDialog() {
      this.showValidateDialog = false;
      this.baseDialogClassTitle = "";
    },
    openAlertDialogError(error) {
      this.dataButtonDialog = [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.validateWording = error;
      this.showValidateDialog = true;
    },
    toggleRemarkVisible() {
      this.isRemarkVisible = !this.isRemarkVisible;
    },
    closeBaseDialog() {
      this.showValidateDialog = false;
      this.isCopyItem = false;
    },
  },
  computed: {
    payload() {
      let details = [];
      if (this.details) {
        for (const each of this.details.filter((each) => each.productId)) {
          let detail = null;
          if (each.id.includes("dummy")) {
            detail = { ...each, id: null };
          } else {
            detail = { ...each };
          }
          details.push(detail);
        }
      }

      let payload = {
        projectId: this.selectedProject.id,
        unitId: this.selectedUnit ? this.selectedUnit.id : null,
        customerId: this.selectedUnit ? this.selectedUnit.customerId : null,
        employeeId: this.employee.id,
        date: this.date ? this.formatDateToISOString(this.date) : null,
        duedate: this.dueDate ? this.formatDateToISOString(this.dueDate) : null,
        remark1: this.remark1,
        remark2: this.remark2,
        details: details,
        type: "Add",
      };
      if (!this.isCreateDocument) {
        payload = {
          ...payload,
          id: this.id,
          type: "Update",
          employeeId: this.invoice ? this.invoice.employeeId : null,
        };
      }
      return payload;
    },
    title() {
      let result = this.lang.editInvoice;
      if (this.isCreateDocument) {
        result = this.lang.createInvoice;
      }
      return result;
    },
    saveEditSuccess() {
      let result = this.lang.editInvoiceSuccess;
      if (this.isCreateDocument) {
        result = this.lang.createInvoiceSuccess;
      }
      return result;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    employee() {
      return this.$store.getters.employee;
    },
    isCreateDocument() {
      return this.id === "create";
    },
    invoice() {
      return this.$store.getters["arInvoice/selectedInvoice"];
    },
    sharedUnits() {
      return this.$store.getters["shared/unitUnits"];
    },

    sumDetailTotalAmount() {
      let TotalAmount = 0;
      if (this.details) {
        this.details.forEach((element) => {
          TotalAmount += element.totalAmount;
        });
      }
      return TotalAmount;
    },
    isCanceled() {
      if (this.invoice) {
        if (this.invoice.cancelStatus.trim().toLowerCase() === "c") {
          return true;
        }

        if (this.invoice.postStep.trim().toLowerCase() === "a") {
          return true;
        }

        if (this.invoice.paidStatus.trim().toLowerCase() === "d") {
          return true;
        }

        if (this.invoice.paidStatus.trim().toLowerCase() === "f") {
          return true;
        }
      }

      if (!this.isRoleCancel) {
        return true;
      }

      if (this.isCreateDocument) {
        return true;
      }

      return false;
    },
    isLockedSave() {
      if (this.isDocumentLocked) {
        return true;
      }
      if (!this.selectedUnit) {
        return true;
      }
      if (this.details.length <= 0) {
        return true;
      }

      return false;
    },
    isDocumentLocked() {
      if (this.invoice) {
        if (
          this.invoice.paidStatus.trim() ||
          this.invoice.postStep.trim() ||
          this.invoice.cancelStatus.trim().toLowerCase() === "c" ||
          !this.isRoleUpdate
        ) {
          return true;
        }
      } else {
        if (!this.isRoleInsert) {
          return true;
        }
      }
      return false;
    },
    isPrinted() {
      if (!this.isRolePrint) {
        return true;
      }

      if (this.isCreateDocument) {
        return true;
      }

      return false;
    },
    roleByBook() {
      return this.getRoleByBookByCode("BILL");
    },
    isRoleInsert() {
      if (this.roleByBook && this.roleByBook.insert == "Y") {
        return true;
      }
      return false;
    },
    isRoleUpdate() {
      if (this.roleByBook && this.roleByBook.update == "Y") {
        return true;
      }
      return false;
    },
    isRoleCancel() {
      if (this.roleByBook && this.roleByBook.cancel == "Y") {
        return true;
      }
      return false;
    },
    isRolePrint() {
      if (this.roleByBook && this.roleByBook.print == "Y") {
        return true;
      }
      return false;
    },
    dataButtons() {
      return [
        {
          id: "gotoAskForCancel",
          action: "gotoAskForCancel",
          isDisable: this.isCreateDocument || !this.isSharedUnitReady, //this.isCanceled,
          type: "cancel"
        },
        {
          id: "gotoAskForCreate",
          action: "gotoAskForCreate",
          type: "create"
        },
        {
          id: "printInvoice",
          action: "gotoPrint",
          isDisable: this.isCreateDocument || !this.isSharedUnitReady, //this.isPrinted,
          type: "print"
        },
        {
          id: "saveInvoice",
          action: "gotoSave",
          isDisable: !this.isSharedUnitReady, //this.isLockedSave,
          type: "save"
        },
      ];
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    showHideRemarkTitle() {
      if(this.isRemarkVisible) {
        return this.lang.hideRemark;
      }
      return this.lang.showRemark;
    }
  },
  watch: {
    // isSharedUnitReady() {
    //   this.checkShouldSetData();
    // },
    invoice() {
      this.checkShouldSetData();
    },
    isSharedRoleByBookReady() {
      this.checkShouldSetData();
    },
    payload(curValue) {
      if (this.objToCompare) this.compareObject(this.objToCompare, curValue);
    },
  },
};
</script>
