export default {
  async getClosingEntrys(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/ClosingEntry";
    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }
    if (!payload.page) {
      url = url + "&page=1";
    } else {
      url = url + "&page=" + payload.page;
    }
    if (payload.projectId) {
      url = url + "&projectId=" + payload.projectId;
    }
		url = encodeURI(url);
    const token = context.rootGetters.token;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to get closingEntry"
      );
      throw error;
    }
    context.commit("setClosingEntry", responseData);
  },
  async getAllClosingEntrys(context, payload) {
    while (!context.getters.isLastPage) {
      let projectId = payload.projectId;
      const payloadPrepare = {
        page: context.getters.nextPage || 1,
        projectId,
      };
      await context.dispatch("getClosingEntrys", payloadPrepare);
    }
  },
  async getClosingEntry(context, id) {
    let url = process.env.VUE_APP_BACKEND_URL + "/ClosingEntry/"+ id;
		url = encodeURI(url);
    const token = context.rootGetters.token;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to get closingEntry"
      );
      throw error;
    }
    if (responseData.data != null) {
      context.commit("setSelectedClosingEntry", responseData.data);
    }
  },
  async getClosingVoucher(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/ClosingEntry/GetClosingVoucher";
    url = url + "?projectId=" + payload.projectId;    
    url = url + "&dateAt=" + payload.dateAt;
    url = url + "&accountId=" + payload.accountId;
		url = encodeURI(url);
    const token = context.rootGetters.token;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      throw responseData;
    }
    context.commit("setClosingVoucher", responseData);
  },
  async createClosingEntry(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/ClosingEntry/Add`;
    const token = context.rootGetters.token;
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});
    const responseData = await response.json();
    if (!response.ok) {
      throw responseData;
    }
    context.commit("setSelectedClosingEntry", responseData.data);
  },
  async cancelClosingEntry(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/ClosingEntry/Cancel`;
    const token = context.rootGetters.token;
		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});
    const responseData = await response.json();
    if (!response.ok) {
      throw responseData;
    }
    context.commit("setSelectedClosingEntry", responseData.data);
  },
	resetClosingEntry(context) {
		context.commit("resetClosingEntry");
    context.commit("setClosingVoucher", []);
    context.commit("setSelectedClosingEntry", null);
	},
};
