export default {
    async getFormDetails(context, payload) {

        let url = process.env.VUE_APP_BACKEND_URL + "/Form/FormDetail";

        if (!payload.limit) {
          url = url + "?limit=10";
        } else {
          url = url + "?limit=" + payload.limit;
        }
    
        if (payload.page) {
          url = url + "&page=" + payload.page;
        }
    
        if (payload.where) {
          url = url + "&where=" + JSON.stringify(payload.where);
        }
        
        const token = context.rootGetters.token;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
          },
        });
    
        const responseData = await response.json();
    
        if (!response.ok) {
          // console.log(responseData);
          const error = new Error(responseData.message || "Failed to get form detail");
          throw error;
        }
        // console.log(responseData);
    
        // const data = responseData.data;
        context.commit('setFormDetail', responseData);
      },
      async createOrUpdateFormDetail(context, payload){
        let url = process.env.VUE_APP_BACKEND_URL + `/Form/FormDetail/${payload.type}`;
        const fallbackErrorMessage = `Failed to ${payload.type} formDetail`
        const token = context.rootGetters.token;
    
        const response = await fetch(url, {
          method: payload.type === 'Add' ? 'post' : 'put',
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
    
          body: JSON.stringify(payload),
        });
    
        const responseData = await response.json();
    
        if (!response.ok) {
          // console.log(responseData);
          let message = null
          if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
            message = responseData.title;
            for (const key in responseData.errors) {
              message += ", " + key.replace("$.","")
            }
          } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
            message = responseData.message
          }
          const error = new Error(message || fallbackErrorMessage);
          throw error;
        }
        // console.log(responseData);
    
        // const data = responseData.data;
        // if (responseData.data.length > 0) {
        //   context.commit("setSelectedInvoice", responseData.data);
        // }
        // context.commit("setSelectedInvoice", responseData.data);
      },
      async deleteFormDetail(context, payload){
        let url = process.env.VUE_APP_BACKEND_URL + "/Form/FormDetail/Delete";
        const fallbackErrorMessage = `Failed to Delete formDetail`
        const token = context.rootGetters.token;
    
        const response = await fetch(url, {
          method: "Delete",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
    
          body: JSON.stringify(payload),
        });
    
        const responseData = await response.json();
    
        if (!response.ok) {
          // console.log(responseData);
          let message = null
          if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
            message = responseData.title;
            for (const key in responseData.errors) {
              message += ", " + key.replace("$.","")
            }
          } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
            message = responseData.message
          }
          const error = new Error(message || fallbackErrorMessage);
          throw error;
        }
        // console.log("responseData=",responseData);
        context.commit('setFormDetail', responseData);
      },
};