<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <section class="flex flex-wrap" :class="textFormClass">
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="dateStart">{{ lang.monthStart }}</label>
        <PvCalendar
          id="dateStart"
          v-model="dateStart"
          class="inputfield w-full mt-1"
          showIcon
          dateFormat="mm/yy"
          :class="{ 'p-invalid': dateStartError }"
          view="month"
          @date-select="changeDateStart"
        />
        <small v-if="dateStartError" id="unit-help" class="p-error">{{
          lang.validateDateSelect
        }}</small>
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="dateEnd">{{ lang.monthEnd }}</label>
        <PvCalendar
          id="dateEnd"
          v-model="dateEnd"
          class="inputfield w-full mt-1"
          showIcon
          dateFormat="mm/yy"
          :class="{ 'p-invalid': dateEndError }"
          view="month"
          @date-select="dateEndError = false"
          disabled
        />
        <small v-if="dateEndError" id="unit-help" class="p-error">{{
          lang.validateDateSelect
        }}</small>
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="typePrint">{{ lang.type }}</label>
        <PvDropdown
          class="inputfield w-full mt-1"
          :options="typePrint"
          :showClear="cleartypePrint"
          optionLabel="name"
          optionValue="code"
          v-model="selecttypePrint"
          @change="setDate"
        />
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="dateAt">{{ lang.dateAtData }}</label>
        <PvCalendar
          id="dateAt"
          v-model="dateAt"
          class="inputfield w-full mt-1"
          showIcon
          dateFormat="dd/mm/yy"
          :class="{ 'p-invalid': dateAtError }"
          @date-select="dateAtError = false"
        />
        <small v-if="dateAtError" id="unit-help" class="p-error">{{
          lang.validateDateSelect
        }}</small>
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="fileNameText">{{ lang.fileName }}</label>
        <PvInputText
          id="fileNameText"
          type="text"
          class="inputfield w-full mt-1"
          v-model="fileNameText"
        />
      </div>
      <div class="col-12 pl-0 pr-2 pt-3 flex">
        <PvCheckbox
          id="isPrintZero"
          inputId="binary"
          v-model="isPrintZero"
          :binary="true"
        />
        <label for="isPrintZero" class="pl-3">
          {{ lang.captionCheckboxPrintZero }}
        </label>
      </div>
    </section>
    
    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
        </div>
      </div>
    </template>

  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetFormPrint from "../../hooks/getFormPrint.js";
import useTracking from "../../hooks/tracking";
import useReportRequestValidate from "../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "toast-request"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { getPreviewBlob } = useGetFormPrint();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.PettyCash,
          ...lang.th.gl.accounting.listing,
          ...lang.th.gl.accounting.edit,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.PettyCash,
          ...lang.en.gl.accounting.listing,
          ...lang.en.gl.accounting.edit,
          ...lang.en.gl.reportGl,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getPreviewBlob,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      dateStart: null,
      dateEnd: null,
      dateStartError: false,
      dateEndError: false,
      dateAt: null,
      dateAtError: false,
      typePrint: [
        { name: this.lang.byMonth, code: "1" },
        { name: this.lang.byYear, code: "11" },
      ],
      selecttypePrint: "1",
      cleartypePrint: false,

      type: "PDF",
      fileNameText: "compare_income_and_expense",
      isPrintZero: true,
    };
  },
  async mounted() {
    await this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    validateData() {
      let returnStatus = false;
      if (this.selectedDateStart == null) {
        this.dateStartError = true;
        returnStatus = true;
      }
      if (this.selectedDateEnd == null) {
        this.dateEndError = true;
        returnStatus = true;
      }
      if (this.selectedDateAt == null) {
        this.dateAtError = true;
        returnStatus = true;
      }
      return returnStatus;
    },
    async initiateData() {
      let date = new Date();
      this.dateAt = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 2, 0);
      this.dateStartError = false;
      this.dateEndError = false;
      this.dateAtError = false;
      this.type = "PDF";
      this.fileNameText = "compare_income_and_expense";
    },
    async download(typeReport) {
      this.type = typeReport;
      if (this.validateData()) {
        return;
      }

      this.setLoading(true);
      try {
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
    setDate() {
      if (this.selecttypePrint == "1") {
        this.dateEnd = new Date(
          this.dateStart.getFullYear(),
          this.dateStart.getMonth() + 2,
          0
        );
      } else {
        this.dateEnd = new Date(
          this.dateStart.getFullYear(),
          this.dateStart.getMonth() + 12,
          0
        );
      }
    },
    changeDateStart() {
      this.setDate();
      this.dateStartError = false;
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    selectedDateAt() {
      let dateAt = null;
      if (this.dateAt) {
        dateAt = this.formatDateToISOString(this.dateAt);
      }
      return dateAt;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    fileBlob() {
      return this.$store.getters["shared/blob"];
    },
    title() {
      if (this.$store.getters.selectedLanguage === "TH")
        return this.reportObj.nameThai;
      else return this.reportObj.nameEnglish;
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
    fileType() {
      let fileType =
        this.type == "EXCEL"
          ? "_excel.xlsx"
          : (this.type == "JASPEREXCEL")
          ? ".xlsx"
          : ".pdf";
      return fileType;
    },
    fileName() {
      let fileName = this.type == "PDF" ? ".pdf" : ".xlsx";
      let result = "";
      if(this.selecttypePrint == "1") {
        result = this.fileNameText + "_by_month";
      } else {
        result = this.fileNameText + "_by_year";
      }
      return result + fileName;
    },
    jasperPath() {
      let result = "";
      if(this.selecttypePrint == "1") {
        result = this.reportObj.path + "_by_month";
      } else {
        result = this.reportObj.path + "_by_year";
      }
      return result;
    },
    payload() {
      let payload = {
        ProjectId: this.selectedProject.id,
        DateStart: this.selectedDateStart,
        DateEnd: this.selectedDateEnd,
        DateAt: this.selectedDateAt,
        Period: this.selecttypePrint,
        FileName: this.fileName,
        JasperPath: this.jasperPath + this.fileType,
        DocType: this.type,
        UserId: this.userId,
        PrintBy: this.userName,
        RequestId: 0,
        ReportName: "IncomeAndExpenseCompare",
        IsPrintZero: this.isPrintZero
      };
      return payload;
    },
  },
  watch: {
    reportObj() {
      this.initiateData();
    },
  },
};
</script>
