<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '70vw' }"
    :modal="true"
    :closable="false"
    :header="lang.invoiceItem"
  >
    <transition>
      <section
        class="flex flex-wrap"
        :class="textFormClass"
        v-if="isParameterVisible"
      >
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateStart">{{ lang.dateStart }}</label>
          <PvCalendar
            id="dateStart"
            v-model="dateStart"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateEnd">{{ lang.dateEnd }}</label>
          <PvCalendar
            id="dateEnd"
            v-model="dateEnd"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="codeStart">{{ lang.codeStart }}</label>
          <PvInputText
            id="codeStart"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="6501xxxxx"
            v-model="codeStart"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="codeEnd">{{ lang.codeEnd }}</label>
          <PvInputText
            id="codeEnd"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="6501xxxxx"
            v-model="codeEnd"
          />
        </div>
        <!-- <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="customerSearch">{{ lang.customerSearch }}</label>
          <PvInputText
            id="customerSearch"
            type="text"
            class="inputfield w-full mt-1"
            v-model="customerSearch"
          />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="selectedUnit">{{ lang.searchUnit }}</label>
          <base-unit-auto-complete
            id="selectedUnit"
            v-model="selectedUnit"
            class="inputfield w-full mt-1"
          />
        </div> -->
      </section>
    </transition>

    <div class="pt-2 pb-1">
      <!-- <PvButton
        icon="pi pi-search"
        :label="lang.search"
        @click="getInvoices"
        class="mr-2 mb-2"
      /> -->
      <!-- <PvButton
        icon="pi pi-list"
        :label="parameterButtonText"
        @click="toggleParameterVisible"
        class="p-button-help mr-2 mb-2"
      /> -->
      <PvButton
        icon="pi pi-plus"
        :label="lang.importDocument"
        class="mr-2 mb-2"
        @click="addInvoiceToGl"
        v-if="!isAddInvoice"
        :loading="isLoading"
      />
      <PvButton
        icon="pi pi-minus"
        :label="lang.removeDocument"
        class="p-button-outlined p-button-danger mr-2 mb-2"
        @click="askForRemove"
        v-if="!isAddInvoice"
        :loading="isLoading"
      />
      <PvButton
        icon="pi pi-chevron-left"
        :label="lang.back"
        @click="goBack"
        class="p-button-secondary mr-2 mb-2"
        v-if="isHideBack"
      />
      <PvButton
        icon="pi pi-check"
        :label="lang.confirm"
        @click="getInvoices"
        class="mr-2 mb-2"
        v-if="isAddInvoice"
      />
    </div>

    <PvDataTable
      showGridlines
      :value="invoiceItems"
      dataKey="id"
      :loading="isLoading"
      responsiveLayout="scroll"
      :scrollHeight="halfViewportHeight + 'px'"
      :stripedRows="true"
      class="p-datatable-sm"
      :class="textTableClass"
      v-if="!isAddInvoice"
    >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
      <!-- <PvColumn selectionMode="multiple" bodyClass="text-center" headerClass="h-center"></PvColumn> -->
      <PvColumn
        field="itemNumber"
        :header="lang.itemNo"
        style="min-width: 4rem"
        headerClass="h-center"
        bodyClass="text-center"
      ></PvColumn>
      <PvColumn
        field="code"
        :header="lang.columnCode"
        style="min-width: 6rem"
        headerClass="h-center"
        bodyClass="text-center"
        sortable
      ></PvColumn>
      <PvColumn
        field="refCode"
        :header="lang.codeReference"
        style="min-width: 6rem"
        headerClass="h-center"
        bodyClass="text-left"
        sortable
      ></PvColumn>
      <PvColumn
        field="date"
        :header="lang.columnDate"
        style="min-width: 6rem"
        headerClass="h-center"
        bodyClass="text-center"
        sortable
      >
        <template #body="slotProps">
          {{ formatDate(slotProps.data.date) }}
        </template>
      </PvColumn>
      <PvColumn
        field="unitCode"
        :header="lang.unitCode"
        style="min-width: 6rem"
        headerClass="h-center"
        bodyClass="text-left"
        sortable
      ></PvColumn>
      <PvColumn
        field="unitAddressNo"
        :header="lang.unitAddress"
        style="min-width: 6rem"
        headerClass="h-center"
        bodyClass="text-left"
        sortable
      ></PvColumn>
      <!-- <PvColumn
        field="customerCode"
        :header="lang.customerCode"
        style="min-width: 6rem"
        headerClass="h-center"
        bodyClass="text-left"
        sortable
      ></PvColumn> -->
      <PvColumn
        field="customerName"
        :header="lang.customerName"
        style="min-width: 6rem"
        headerClass="h-center"
        bodyClass="text-left"
        sortable
      ></PvColumn>
      <PvColumn
        field="totalAmount"
        :header="lang.columnTotal"
        style="min-width: 6rem"
        headerClass="h-center"
        bodyClass="text-right"
        sortable
        ><template #body="slotProps">
          {{ formatCurrency(slotProps.data.totalAmount) }}
          <!-- {{ formatCurrencyTable(slotProps.data, "totalAmount") }} -->
        </template></PvColumn
      >
      <PvColumn
        style="min-width: 4rem"
        headerClass="h-center"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <PvButton
            type="button"
            class="p-button-danger p-button-text py-0"
            icon="pi pi-times"
            @click="deleteInvoiceSelected(slotProps.data.id)"
          ></PvButton>
        </template>
      </PvColumn>
      <!-- <template #footer>
        <PvPaginator
          :rows="rowsPerPage"
          :totalRecords="totalRecords"
          @page="onPageChange($event)"
        >
          <template #start="slotProps">
            {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /
            {{ totalPage }}
          </template>
        </PvPaginator>
      </template> -->
    </PvDataTable>

    <template #footer>
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog"
      />
      <PvButton
        :label="lang.autoGl"
        icon="pi pi-check"
        autofocus
        @click="selectInvoice"
        v-if="!isAddInvoice"
        :loading="isLoading"
      />
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
export default {
  props: ["openFrom", "voucherId"],
  emits: ["close", "invoice-selected"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const store = useStore();
    const {
      formatCurrency,
      formatCurrencyTable,
      formatDate,
      formatDateToISOString,
    } = useFormat();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.accounting.edit,
          ...lang.th.gl.accounting.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.accounting.edit,
          ...lang.en.gl.accounting.listing,
        };
      }
    });

    return {
      textHeaderClass,
      textFormClass,
      textTableClass,
      lang: mergeLang,
      formatCurrency,
      formatCurrencyTable,
      formatDate,
      formatDateToISOString,
    };
  },
  data() {
    return {
      isAddInvoice: false,
      isParameterVisible: false,
      isLoading: true,
      dateStart: null,
      dateEnd: null,
      codeStart: "",
      codeEnd: "",
      customerSearch: "",
      selectedUnit: "",
      selectedInvoice: null,
      selectedPage: 1,
      rowsPerPage: 100,
      rowsPerListPage: 100,
      selectedListPage: 1,
      messageError: [],
      invoiceItems: null
    };
  },
  mounted() {
    let date = new Date();
    this.dateStart = new Date();
    this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    if (this.invoiceSelected == null) {
      this.isAddInvoice = false;
      this.isParameterVisible = false;
    }

    this.isLoading = false;
    const payload = [];
    this.$store.dispatch("glAccountingInvoice/setSelectedInvoice", payload);
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    onRowUnselect() {
      this.selectedInvoice = null;
    },
    closeDialog() {
      this.isAddInvoice = false;
      this.isParameterVisible = false;
      this.$emit("close");
    },
    toggleParameterVisible() {
      this.isParameterVisible = !this.isParameterVisible;
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getInvoices();
    },
    async getInvoices() {
      this.isParameterVisible = false;
      this.isAddInvoice = false;
      this.setLoading(true);
      try {
        await this.$store.dispatch("glAccountingInvoice/resetInvoiceList");
        this.invoiceItems = [];
        // this.isLoading = true;
        // this.onRowUnselect();
        let unitId = null;
        if (this.selectedUnit) {
          unitId = this.selectedUnit.id;
        }
        let voucherId = null;
        if (this.voucherId != "create") voucherId = this.voucherId;

        let where = {
          projectId: this.selectedProject.id,
          dateStart: this.selectedDateStart,
          dateEnd: this.selectedDateEnd,
          codeStart: this.codeStart || null,
          codeEnd: this.codeEnd || null,
          customerSearch: this.customerSearch || null,
          unitId: unitId,
          postType: "add",
          voucherId: voucherId,
        };

        const payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          where,
        };

        await this.$store.dispatch("glAccountingInvoice/getAllInvoices", payload);

        this.setInvoiceSelected();
      } catch (error) {
        this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				this.setLoading(false);
      }
      // this.isLoading = false;
      this.setLoading(false);
    },
    async getInvoiceSelected() {
      if (this.invoiceSelected <= 0) {
        this.isAddInvoice = true;
        this.isParameterVisible = true;
      }
      // if(this.invoiceSelected) {
      //   this.isAddInvoice = false;
      //   this.isParameterVisible = false;
      // }

      // this.isLoading = true;
      // const Id = this.$route.params.id;

      // let where = {
      //   projectId: this.selectedProject.id,
      //   voucherId: Id,
      // };

      // const payload = {
      //   page: this.selectedPage,
      //   limit: this.rowsPerPage,
      //   where,
      // };

      // // console.log("payload=",payload);
      // await this.$store.dispatch(
      //   "glAccountingInvoice/getInvoiceSelected",
      //   payload
      // );

      // this.isLoading = false;
    },
    async setInvoiceSelected() {
      // await this.$store.dispatch(
      //   "glAccountingInvoice/setSelectedInvoice",
      //   this.invoices
      // );
      // console.log("this.invoiceSelected=",this.invoiceSelected);
      if (this.invoiceSelected) {
        let invoiceSelected = this.invoiceSelected;
        // console.log("invoices",this.invoiceSelected);
        const invoices = this.invoices;

        invoices.forEach((detail) => {
          //  console.log("invoices",detail);
          const data = invoiceSelected.find((d) => d.id === detail.id);
          if (!data) {
            invoiceSelected.push(detail);
          }
        });
        this.setLoopAddData();
      }
    },
    async selectInvoice() {
      // console.log("selectedInvoice", this.invoiceSelected);
      // this.invoiceSelected.forEach((invoice) => {
      //   if (invoice.postStep == "A") {
      //     console.log("IsPostStep");
      //   //   let code = [invoice.code]
      //   //   this.$swal({
      //   //     icon: "warning",
      //   //     title: "เอกสารเลขที่ " + code + " ลงบัญชีแล้ว",
      //   //     confirmButtonText: this.lang.close,
      //   //     allowOutsideClick: false,
      //   //   });
      //   //   return true;
      //   }
      //   // return false;
      // });

      // let details = [];
      // let requestInvoice = { projectId: this.selectedProject.id };
      // this.invoiceSelected.forEach((invoice) => {
      //     //  if(Receipt.remark4 == "new"){
      //         let detail = {
      //           headerId: invoice.id,
      //           code: invoice.code,
      //         };
      //         details.push(detail);
      //     //  }
      // });
      // let invoices = { Codes: details };
      // requestInvoice = {...requestInvoice, ...invoices };
      // await this.$store.dispatch("glAccountingInvoice/getInvoices",requestInvoice);

      // this.$emit("invoice-selected", this.selectedInvoice);
      // this.onRowUnselect();

      this.$emit("close");
      // this.isLoading = true;
      this.setLoading(true);
      let details = [];
      let requestInvoice = { projectId: this.selectedProject.id };
      if (this.invoiceSelected) {
        this.invoiceSelected.forEach((invoice) => {
          let detail = {
            headerId: invoice.id,
            code: invoice.code,
          };
          details.push(detail);
        });
      }
      let invoices = { data: details };
      requestInvoice = { ...requestInvoice, ...invoices };

      try {
        await this.$store.dispatch("glAccountingInvoice/getInvoiceToGl",requestInvoice);
      } catch (error) {
        this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				this.setLoading(false);
      }


      this.invoiceSelectedMessageError();

      if (this.isInvoiceSelectedMessage) {
        this.$swal({
          icon: "warning",
          html: `<p class="${this.textAlertClass}">${this.invoiceMessageError}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      } else {
        this.addInvoiceSelectedToGl();
        this.isAddInvoice = false;
        this.isParameterVisible = false;
      }

      // this.addInvoiceSelectedToGl();
      // this.closeDialog();

      // this.isLoading = false;
      this.setLoading(false);
    },
    addInvoiceSelectedToGl() {
      let details = [];
      let number = 1;
      this.invoiceToGl.forEach((invoice) => {
        let detail = {
          accountId: invoice.accountId,
          accountCode: invoice.accountCode,
          accountName: invoice.accountName,
          creditAmount: invoice.creditAmount,
          debitAmount: invoice.debitAmount,
          description: invoice.description,
          group: null,
          headerId: invoice.headerId,
          id: invoice.id,
          itemNumber: number,
          jobId: null,
          projectId: null,
          sectionId: null,
          meters: [],
        };

        details.push(detail);

        number++;
      });

      // this.invoiceSelected.forEach((invoice) => {
      //   let refer = {
      //     referId : invoice.id
      //   }
      //   refers.push(refer);
      // })
      // console.log("referId", refers)

      this.$emit("invoice-selected", details);
    },
    addInvoiceToGl() {
      this.isAddInvoice = true;
      this.isParameterVisible = true;
      this.codeStart = "";
      this.codeEnd = "";
    },
    async removeInvoiceToGl() {
      // console.log("removeInvoiceToGl")
      await this.$store.dispatch("glAccountingInvoice/resetInvoiceList");
      await this.$store.dispatch("glAccountingInvoice/resetInvoiceSelectedList");
      this.invoiceItems = [];
    },
    goBack() {
      this.isAddInvoice = false;
      this.isParameterVisible = false;
      this.setLoopAddData();
    },
    deleteInvoiceSelected(id) {
      // console.log("deleteId", id);
      const index = this.invoiceSelected.findIndex(
        (detail) => detail.id === id
      );
      this.invoiceSelected.splice(index, 1);
      this.invoiceItems.splice(index, 1);
    },
    invoiceSelectedMessageError() {
      this.messageError = [];
      this.invoiceToGl.forEach((detail) => {
        // console.log("error", detail.error);
        if (detail.error != null && detail.error != "") {
          let message = { message: detail.error };
          this.messageError.push(message);
        }
      });
    },
    async askForRemove() {
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForRemoveDocument}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        await this.removeInvoiceToGl();
      } else if (result.isDenied) {
        //
      }
    },
    async sleep(ms) {
      await new Promise((resolve) => setTimeout(resolve, ms));
    },
    async setLoopAddData() {
      this.isLoading = true;
      let tempInvoiceSelectedItem = [...this.invoiceSelected];
      this.invoiceItems = [];
      let loopLength = 50;
      let i = 1;
      
      while (tempInvoiceSelectedItem.length > 0) {
        if (tempInvoiceSelectedItem.length < loopLength || i > 1) {
          loopLength = tempInvoiceSelectedItem.length;
        }

        let spliceData = tempInvoiceSelectedItem.splice(0, loopLength);
      
        if (spliceData) {
          spliceData.forEach((element) => {
            element.itemNumber = i
            i++;
          });
        }

        this.invoiceItems = [...this.invoiceItems, ...spliceData];
        if (i <= 51) {
          await this.sleep(500);
        }
      }
      this.isLoading = false;
    }
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    parameterButtonText() {
      return this.isParameterVisible
        ? this.lang.hideParameters
        : this.lang.showParameters;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    invoices() {
      return this.$store.getters["glAccountingInvoice/invoices"];
    },
    invoiceSelected() {
      return this.$store.getters["glAccountingInvoice/selectedInvoice"];
    },
    invoiceToGl() {
      return this.$store.getters["glAccountingInvoice/invoiceToGl"];
    },
    totalListPage() {
      return this.$store.getters["glAccountingInvoice/totalListPage"];
    },
    totalPage() {
      return this.$store.getters["glAccountingInvoice/totalPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    totalListRecords() {
      return this.totalListPage * this.rowsPerListPage;
    },
    currentPage() {
      return this.$store.getters["glAccountingInvoice/currentPage"];
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    sortedMessageError() {
      // console.log("sortedMessageError")
      const cats = this.messageError.reduce((p, c) => {
        p[c.message] = c;
        return p;
      }, {});
      return Object.values(cats).sort((a, b) =>
        a.message.localeCompare(b.message)
      );
    },
    isInvoiceSelectedMessage() {
      let Error = false;
      if (this.messageError.length > 0) Error = true;
      return Error;
    },
    invoiceMessageError() {
      let messages = "";
      this.messageError.forEach((message) => {
        messages += message.message + "</br>";
      });
      return messages;
    },
    invoiceSelectedItem() {
      let itemNumber = [];
      let i = 1;
      if (this.invoiceSelected) {
        this.invoiceSelected.forEach((element) => {
          element = { ...element, ...{ itemNumber: i } };
          itemNumber.push(element);
          i++;
        });
      }
      return itemNumber;
    },
    isHideBack() {
      if (this.invoiceSelected.length > 0 && this.isAddInvoice) return true;
      else return false;
    },
  },
  watch: {
    openFrom(curValue) {
      const Check = curValue.indexOf("create");
      if (Check < 0) {
        this.setLoopAddData();
        this.getInvoiceSelected();
      } else if (Check >= 0) {
        if (this.invoiceSelected.length <= 0) {
          this.isAddInvoice = true;
          this.isParameterVisible = true;
        }
      }
    },
  },
};
</script>
