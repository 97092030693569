export default {
	title: "Account Book",
	createAccountBook: "Create Account Book",

	columnCode: "Code",
	columnName: "Name",
	colummLockDate: "Lock Date",

	editButton:"Setting",
};
