export default {
    toolbarUnit: "รายการห้อง/บ้าน",
    title: "รายการห้อง/บ้าน",
    createReport: "สร้างรายงาน",
    addRoom: "สร้างห้อง/บ้าน",
    searchText: "กรอกข้อมูลเพื่อค้นหา",
    unitCode: "เลขที่ห้อง/ยูนิต",
    addressNo: "บ้านเลขที่",
    ownerName: "ชื่อเจ้าของ",
    columnRatio: "อัตราส่วน",
    area: "พื้นที่",
    type: "ประเภทห้องชุด",
    unitProductSetupForInvoice: "รายการแจ้งหนี้",
    reportPrintButton: "พิมพ์รายงาน",
    reportTitle: "รายงานทะเบียนห้อง",
    unitList: "รายชื่อห้อง/ยูนิต",
    activeStatus: "สถานะ",
    statusY: "ใช้งาน",
    statusN: "ไม่ใช้งาน",
    importUnitResident: "นำเข้าข้อมูลสมาชิก",
    buttonImportUnit: "นำเข้าห้อง/บ้าน",
}