<template>
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredByFilterType"
    field="codeAndName"
    @complete="searchProduct"
    @dropdown-click="filteredByFilterType"
    forceSelection
  />
</template>

<script>
import { onMounted } from "vue";
import useGetProductAR from "../../hooks/getProductAR.js";
export default {
  props: {
    shouldGetData: {
      type: Boolean,
      default: true,
    },
    filterType: {
      type: String,
      default: "",
    },
  },
  emits: ["load-finish"],
  setup(props, { emit }) {
    const {
      searchProduct,
      filteredProducts,
      getAllProducts,
      filteredIgnorePenaltyProducts,
      filteredPenaltyProducts,
      filteredKeycardProducts,
    } = useGetProductAR();

    onMounted(async () => {
      if (props.shouldGetData) {
        await getAllProducts();
      }
      emit("load-finish");
    });

    return {
      searchProduct,
      filteredProducts,
      filteredIgnorePenaltyProducts,
      filteredPenaltyProducts,
      filteredKeycardProducts,
    };
  },
  computed: {
    filteredByFilterType() {
      let result = [];
      if(this.filterType == "ignorePenalty") {
        result = this.filteredIgnorePenaltyProducts;
      } else if(this.filterType == "penalty") {
        result = this.filteredPenaltyProducts;
      } else if(this.filterType == "keycard") {
        result = this.filteredKeycardProducts;
      } else {
        result = this.filteredProducts;
      }
      return result;
    }
  }

  // data() {
  //   return {
  //     searchText: null
  //   };
  // },
  // async created() {
  //   // console.log(this.unitNextPage)
  //   if (this.shouldGetData){
  //     await this.getAllProducts();
  //   }

  // },
  // methods: {
  //   searchProduct(event) {
  //     console.log(event)
  //     if (!event.query.trim().length) {
  //       //ช่องว่าง
  //       // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
  //       this.searchText = '';
  //       this.searchText = null;
  //     } else {
  //       this.searchText = event.query;
  //     }
  //   },
  //   async getAllProducts() {
  //     if(!this.productIsLastPage){
  //       await this.$store.dispatch("shared/getAllProductARs");
  //     }
  //     this.$emit('load-finish');

  //   },
  // },
  // computed: {
  //   selectedProject() {
  //     return this.$store.getters.selectedProject;
  //   },
  //   products() {
  //     return this.$store.getters["shared/productArProducts"];
  //   },
  //   shouldLoadNextPage() {
  //     return this.nextPage !== this.totalPage;
  //   },
  //   productIsLastPage() {
  //     return this.$store.getters["shared/productArIsLastPage"]
  //   },
  //   filteredProducts(){
  //     if (!this.searchText) {
  //       //ช่องว่าง
  //       // console.log('search',this.units)
  //       const result = [...this.products];
  //       return result;
  //     } else {
  //       // this.resetData();
  //       // console.log('here')
  //       // await this.getUnits(event.query);
  //       return this.products.filter((product) => {
  //         return product.codeAndAddressNo
  //           .toLowerCase()
  //           .startsWith(this.searchText.toLowerCase());
  //       });
  //     }
  //   }
  // },
};
</script>
