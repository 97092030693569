<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
    :header="lang.titleReceiptList"
  >
    <PvDataTable
      showGridlines
      :value="filteredPrepaidReceipts"
      v-model:selection="selectedPrepaidReceipt"
      dataKey="detailId"
      :loading="isLoading"
      responsiveLayout="scroll"
      :scrollHeight="halfViewportHeight + 'px'"
      class="p-datatable-sm"
      :class="textTableClass" 
        
    >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
    <!-- @row-unselect="onRowUnselect" -->
      <template #header>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <PvInputText
            v-model="searchText"
            :placeholder="lang.keywordSearch"
            autofocus
          />
        </span>
      </template>
      <PvColumn selectionMode="multiple"></PvColumn>
      <PvColumn
        field="date"
        :header="lang.columnDate"
        style="min-width: 4rem"
        headerClass="h-center"
        bodyClass="text-center"
      >
        <template #body="slotProps">
          {{ formatDate(slotProps.data.date) }}
        </template>
      </PvColumn>

      <PvColumn
        field="code"
        :header="lang.columnCodeReceipt"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>
      <PvColumn
        field="productCode"
        :header="lang.columnProductCode"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>

      <PvColumn
        field="productName"
        :header="lang.columnProductName"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>

      <!-- <PvColumn
        field="unitCode"
        :header="lang.columnUnit"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>

      <PvColumn
        field="unitAddress"
        :header="lang.columnAddress"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>

      <PvColumn
        field="customerName"
        :header="lang.columnCustomerName"
        style="min-width: 8rem"
        headerClass="h-center"
      ></PvColumn> -->

      <PvColumn
        field="period"
        :header="lang.columnPeriod"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>

      <PvColumn
        field="totalAmount"
        :header="lang.columnPrice"
        style="min-width: 3rem"
        headerClass="h-center"
        bodyClass="text-right"
      >
        <template #body="slotProps">
          {{ formatCurrency(slotProps.data.totalAmount) }}
        </template>
      </PvColumn>

      <PvColumn
        field="balanceAmount"
        :header="lang.columnBalance"
        style="min-width: 3rem"
        headerClass="h-center"
        bodyClass="text-right"
      >
        <template #body="slotProps">
          {{ formatCurrency(slotProps.data.balanceAmount) }}
        </template>
      </PvColumn>
    </PvDataTable>
    <template #footer>
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog"
      />
      <PvButton
        :label="lang.ok"
        icon="pi pi-check"
        autofocus
        @click="selectPrepaidReceipt"
      />
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  // props: ["load-receipt"],
  emits: ["select-prepaid-receipt"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString} =
      useFormat();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.ar.prepaidRefund };
      } else {
        return { ...lang.en.base, ...lang.en.ar.prepaidRefund };
      }
    });
    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
    };
  },
  data() {
    return {
      isLoading: true,
      selectedPrepaidReceipt: null,
      totalRecords: 0,
      selectAll: false,
      searchText: null,
    };
  },
  methods: {
    selectPrepaidReceipt() {
      this.$emit("select-prepaid-receipt", this.selectedPrepaidReceipt);

      this.isLoading = true;
    },
    onRowUnselect() {
      this.selectedPrepaidReceipt = null;
    },
    closeDialog() {      
      this.isLoading = true;
      this.$emit("close");
    },
    searchPrepaidReceipt(event) {
      if (!event.query.trim().length) {
        //ช่องว่าง
        // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
        this.searchText = "";
        this.searchText = null;
      } else {
        this.searchText = event.query;
      }
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    PrepaidReceipts() {
      this.onRowUnselect();
      return this.$store.getters["arPrepaidRefund/prepaidReceipts"];
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    filteredPrepaidReceipts() {
      // console.log('filtered', this.searchText);
      if (!this.searchText) {
        //ช่องว่าง
        const result = [...this.PrepaidReceipts];
        return result;
      } else {
        return this.PrepaidReceipts.filter((prepaidReceipt) => {
          return (prepaidReceipt.code.toLowerCase()
            .includes(this.searchText.toLowerCase())

            || prepaidReceipt.productCode
            .toLowerCase()
            .includes(this.searchText.toLowerCase())

            || prepaidReceipt.productName
            .toLowerCase()
            .includes(this.searchText.toLowerCase())

            || prepaidReceipt.period
            .toLowerCase()
            .includes(this.searchText.toLowerCase())
            );
        });
      }
    },
  },
  watch: {
    PrepaidReceipts() {
      this.isLoading = false;
    },
    searchText(curValue) {
      if (curValue) {
        this.searchText = curValue;
      }
    },
  },
};
</script>
