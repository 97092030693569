export default {
  async getControlStockKeycard(context, payload) {
    let url =
      process.env.VUE_APP_BACKEND_URL + `/StockKeycard/ControlStockKeyCard/${payload}`;
    url = encodeURI(url);
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get control stock keycard"
      );
      throw error;
    }
    // console.log("getControlStockKeycard",responseData);

    context.commit("setControlStockKeycard", responseData);
  },
  async updateControlStockKeycard(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + "/StockKeycard/ControlStockKeyCard";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || "Failed to update control stock keycard");
      throw error;
    }
    // console.log(responseData);

    context.commit("setControlStockKeycard", responseData);
  },
};
