export default {
  setAccounting(state, payload) {
    // state.project.length = 0;
    let accountings = [...state.accounting.accountings];
    for (const accounting of payload.accountings) {
      if (accountings.findIndex((c) => c.id === accounting.id) < 0) {
        accountings.push(accounting);
      }
      // accountings.push(accounting);
    }
    state.accounting.accountings = accountings;
    state.accounting.currentPage = payload.currentPage;
    state.accounting.totalPage = payload.totalPage;
  },
  resetAccounting(state) {
    const accounting = {
      accountings: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.accounting = accounting;
  },
};
