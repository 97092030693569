<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '640px': '80vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <span class="font-lg">{{ lang.residentDetail }}</span>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <section class="flex flex-wrap" :class="textFormClass">
    <!-- ข้อมูลทั่วไป -->
        <div class="flex flex-wrap lg:col-12 md:col-12 col-12">
            <div class="lg:col-12 md:col-12 col-12 p-0">
              <span class="font-lg">{{ lang.generalInfo }}</span>
            </div>
        <!-- รหัสสมาชิก/ลูกค้า -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('code')}">{{ lang.code }}
              </label>
              <div class="mt-2">
                <label for="code" class="font-md" :class="{ 'p-text-error': checkError('code')}">{{ code }}</label>
              </div>
            </div>
        <!-- เลขประจำตัวประชาชน/ผู้เสียภาษี -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('identificationCardNumber')}">{{ lang.individualTaxId }}
              </label>
              <div class="mt-2">
                <label for="idCard" class="font-md" :class="{ 'p-text-error': checkError('identificationCardNumber')}">{{ identificationCardNumber }}</label>
              </div>
            </div>
        <!-- คำนำหน้า -->
            <div class="lg:col-3 md:col-3 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('prefix')}">{{ lang.prefix }}
              </label>
              <div class="mt-2">
                <label for="prefix" class="font-md" :class="{ 'p-text-error': checkError('prefix')}">{{ prefix }}</label>
              </div>
            </div>
        <!-- ชื่อสมาชิก/ลูกค้า -->
            <div class="lg:col-3 md:col-3 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('name')}">{{ lang.name }}
              </label>
              <div class="mt-2">
                <label for="name" class="font-md" :class="{ 'p-text-error': checkError('name')}">{{ name }}</label>
              </div>
            </div>
        <!-- รหัสประเทศ -->
            <div class="lg:col-3 md:col-3 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('mobileCountryCode')}">{{ lang.countryCode }}
              </label>
              <div class="mt-2">
                <label for="mobileCountryCode" class="font-md" :class="{ 'p-text-error': checkError('mobileCountryCode')}">{{ mobileCountryCode }}</label>
              </div>
            </div>
        <!-- เบอร์โทรศัพท์ -->
            <div class="lg:col-3 md:col-3 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('mobileNumber')}">{{ lang.mobileNumber }}
              </label>
              <div class="mt-2">
                <label for="mobileNumber" class="font-md" :class="{ 'p-text-error': checkError('mobileNumber')}">{{ mobileNumber }}</label>
              </div>
            </div>
        <!-- วันเกิด (DD/MM/YYYY (ค.ศ.)) -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('birthDate')}">{{ lang.birthDate + " " + lang.formatDate }}
              </label>
              <div class="mt-2">
                <label for="birthDate" class="font-md" :class="{ 'p-text-error': checkError('birthDate')}">{{ birthDate }}</label>
              </div>
            </div>
        <!-- อีเมล -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('email')}">{{ lang.email }}
              </label>
              <div class="mt-2">
                <label for="email" class="font-md" :class="{ 'p-text-error': checkError('email')}">{{ email }}</label>
              </div>
            </div>
        <!-- สัญชาติ -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('nationality')}">{{ lang.nationality }}
              </label>
              <div class="mt-2">
                <label for="nationality" class="font-md" :class="{ 'p-text-error': checkError('nationality')}">{{ nationality }}</label>
              </div>
            </div>
        <!-- ประเภทสมาชิก/ ลูกค้า -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('gender')}">{{ lang.gender }}
              </label>
              <div class="mt-2">
                <label for="gender" class="font-md" :class="{ 'p-text-error': checkError('gender')}">{{ gender }}</label>
              </div>
            </div>
        </div>
    <!-- ข้อมูลทั่วไป -->

    <!-- ข้อมูลติดต่อฉุกเฉิน -->
        <div class="flex flex-wrap lg:col-12 md:col-12 col-12 pt-4">
            <div class="lg:col-12 md:col-12 col-12 p-0">
              <span class="font-lg">{{ lang.emergencyContact }}</span>
            </div>
        <!-- ชื่อผู้ติดต่อ -->
            <div class="lg:col-3 md:col-3 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkError('emergencyContactPrefix')}">{{ lang.prefix }}
                </label>
                <div class="mt-2">
                <label for="emergencyContactPrefix" class="font-md" :class="{ 'p-text-error': checkError('emergencyContactPrefix')}">{{ emergencyContactPrefix }}</label>
                </div>
            </div>
            <div class="lg:col-3 md:col-3 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkError('emergencyContactName')}">{{ lang.contactName }}
                </label>
                <div class="mt-2">
                <label for="emergencyContactName" class="font-md" :class="{ 'p-text-error': checkError('emergencyContactName')}">{{ emergencyContactName }}</label>
                </div>
            </div>
        <!-- รหัสประเทศ -->
            <div class="lg:col-3 md:col-3 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('emergencyCountryCode')}">{{ lang.countryCode }}
              </label>
              <div class="mt-2">
                <label for="emergencyCountryCode" class="font-md" :class="{ 'p-text-error': checkError('emergencyCountryCode')}">{{ emergencyCountryCode }}</label>
              </div>
            </div>
        <!-- เบอร์โทรศัพท์ -->
            <div class="lg:col-3 md:col-3 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('emergencyContactMobileNumber')}">{{ lang.mobileNumber }}
              </label>
              <div class="mt-2">
                <label for="emergencyContactMobileNumber" class="font-md" :class="{ 'p-text-error': checkError('emergencyContactMobileNumber')}">{{ emergencyContactMobileNumber }}</label>
              </div>
            </div>
        </div>
    <!-- ข้อมูลติดต่อฉุกเฉิน -->

    <!-- ข้อมูลที่อยู่สำหรับออกเอกสาร (ใบแจ้งหนี้, ใบเสร็จ, ฯลฯ) -->
        <div class="flex flex-wrap lg:col-12 md:col-12 col-12 pt-4">
            <div class="lg:col-12 md:col-12 col-12 p-0">
              <span class="font-lg">{{ lang.billingContact }}</span>
            </div>
        <!-- ที่อยู่ -->
            <div class="lg:col-12 md:col-12 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkError('billingAddress1')}">{{ lang.address }}
                </label>
                <div class="mt-2">
                <label for="billingAddress" class="font-md" :class="{ 'p-text-error': checkError('billingAddress1')}">{{ billingAddress }}</label>
                </div>
            </div>
        <!-- แขวง/ตำบล -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkAddressError('billingAddressSubDistrict', 'billingAddress')}">{{ lang.subDistrict }}
                </label>
                <div class="mt-2">
                <label for="billingAddressSubDistrict" class="font-md" :class="{ 'p-text-error': checkAddressError('billingAddressSubDistrict', 'billingAddress')}">{{ billingAddressSubDistrict }}</label>
                </div>
            </div>
        <!-- เขต/อำเภอ -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkAddressError('billingAddressDistrict', 'billingAddress')}">{{ lang.addressDistrict }}
                </label>
                <div class="mt-2">
                <label for="billingAddressDistrict" class="font-md" :class="{ 'p-text-error': checkAddressError('billingAddressDistrict', 'billingAddress')}">{{ billingAddressDistrict }}</label>
                </div>
            </div>
        <!-- รหัสไปรษณีย์ -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkAddressError('billingPostCode', 'billingAddress')}">{{ lang.postCode }}
                </label>
                <div class="mt-2">
                <label for="billingPostCode" class="font-md" :class="{ 'p-text-error': checkAddressError('billingPostCode', 'billingAddress')}">{{ billingPostCode }}</label>
                </div>
            </div>
        <!-- จังหวัด -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkAddressError('billingAddress3', 'billingAddress')}">{{ lang.province }}
                </label>
                <div class="mt-2">
                <label for="billingProvince" class="font-md" :class="{ 'p-text-error': checkAddressError('billingAddress3', 'billingAddress')}">{{ billingProvince }}</label>
                </div>
            </div>
        <!-- ภาษาของเอกสาร -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkError('billingLanguage')}">{{ lang.documentLang }}
                </label>
                <div class="mt-2">
                <label for="billingLanguage" class="font-md" :class="{ 'p-text-error': checkError('billingLanguage')}">{{ billingLanguage }}</label>
                </div>
            </div>
        <!-- ช่องทางการส่งเอกสาร -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkError('customerGroup')}">{{ lang.deliveryChannel }}
                </label>
                <div class="mt-2">
                <label for="customerGroup" class="font-md" :class="{ 'p-text-error': checkError('customerGroup')}">{{ customerGroup }}</label>
                </div>
            </div>
        <!-- รวมใบแจ้งหนี้ (สำหรับเจ้าของที่มีหลายห้อง) -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkError('isGroupingInvoice')}">{{ lang.isGroupDoc }}
                </label>
                <div class="mt-2">
                <label for="isGroupingInvoice" class="font-md" :class="{ 'p-text-error': checkError('isGroupingInvoice')}">{{ isGroupingInvoice }}</label>
                </div>
            </div>
        </div>
    <!-- ข้อมูลที่อยู่สำหรับออกเอกสาร (ใบแจ้งหนี้, ใบเสร็จ, ฯลฯ) -->

    <!-- ข้อมูลตามที่อยู่บัตรประชาชน -->
        <div class="flex flex-wrap lg:col-12 md:col-12 col-12 pt-4">
            <div class="lg:col-12 md:col-12 col-12 p-0">
              <span class="font-lg">{{ lang.idCardContact }}</span>
            </div>
        <!-- ที่อยู่ -->
            <div class="lg:col-12 md:col-12 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkError('idCardAddress1')}">{{ lang.address }}
                </label>
                <div class="mt-2">
                <label for="idCardAddress" class="font-md" :class="{ 'p-text-error': checkError('idCardAddress1')}">{{ idCardAddress }}</label>
                </div>
            </div>
        <!-- แขวง/ตำบล -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkAddressError('idCardAddressSubDistrict', 'idCardAddress')}">{{ lang.subDistrict }}
                </label>
                <div class="mt-2">
                <label for="idCardAddressSubDistrict" class="font-md" :class="{ 'p-text-error': checkAddressError('idCardAddressSubDistrict', 'idCardAddress')}">{{ idCardAddressSubDistrict }}</label>
                </div>
            </div>
        <!-- เขต/อำเภอ -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkAddressError('idCardAddressDistrict', 'idCardAddress')}">{{ lang.addressDistrict }}
                </label>
                <div class="mt-2">
                <label for="idCardAddressDistrict" class="font-md" :class="{ 'p-text-error': checkAddressError('idCardAddressDistrict', 'idCardAddress')}">{{ idCardAddressDistrict }}</label>
                </div>
            </div>
        <!-- รหัสไปรษณีย์ -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkAddressError('idCardPostCode', 'idCardAddress')}">{{ lang.postCode }}
                </label>
                <div class="mt-2">
                <label for="idCardPostCode" class="font-md" :class="{ 'p-text-error': checkAddressError('idCardPostCode', 'idCardAddress')}">{{ idCardPostCode }}</label>
                </div>
            </div>
        <!-- จังหวัด -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkAddressError('idCardAddress3', 'idCardAddress')}">{{ lang.province }}
                </label>
                <div class="mt-2">
                <label for="idCardProvince" class="font-md" :class="{ 'p-text-error': checkAddressError('idCardAddress3', 'idCardAddress')}">{{ idCardProvince }}</label>
                </div>
            </div>
        <!-- วันที่ออกบัตร (DD/MM/YYYY (ค.ศ.)) -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('registerDate')}">{{ lang.registerDate + " " + lang.formatDate }}
              </label>
              <div class="mt-2">
                <label for="registerDate" class="font-md" :class="{ 'p-text-error': checkError('registerDate')}">{{ registerDate }}</label>
              </div>
            </div>
        <!-- วันที่หมดอายุ  (DD/MM/YYYY (ค.ศ.)) -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('expiredDate')}">{{ lang.expiredDate + " " + lang.formatDate }}
              </label>
              <div class="mt-2">
                <label for="expiredDate" class="font-md" :class="{ 'p-text-error': checkError('expiredDate')}">{{ expiredDate }}</label>
              </div>
            </div>
        </div>
    <!-- ข้อมูลตามที่อยู่บัตรประชาชน -->

    <!-- ข้อมูลตามที่อยู่สำหรับติดต่อ -->
        <div class="flex flex-wrap lg:col-12 md:col-12 col-12 pt-4">
            <div class="lg:col-12 md:col-12 col-12 p-0">
              <span class="font-lg">{{ lang.contact }}</span>
            </div>
        <!-- ที่อยู่ -->
            <div class="lg:col-12 md:col-12 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkError('contactAddress1')}">{{ lang.address }}
                </label>
                <div class="mt-2">
                <label for="contactAddress" class="font-md" :class="{ 'p-text-error': checkError('contactAddress1')}">{{ contactAddress }}</label>
                </div>
            </div>
        <!-- แขวง/ตำบล -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkAddressError('contactAddressSubDistrict','contactAddress')}">{{ lang.subDistrict }}
                </label>
                <div class="mt-2">
                <label for="contactAddressSubDistrict" class="font-md" :class="{ 'p-text-error': checkAddressError('contactAddressSubDistrict','contactAddress')}">{{ contactAddressSubDistrict }}</label>
                </div>
            </div>
        <!-- เขต/อำเภอ -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkAddressError('contactAddressDistrict','contactAddress')}">{{ lang.addressDistrict }}
                </label>
                <div class="mt-2">
                <label for="contactAddressDistrict" class="font-md" :class="{ 'p-text-error': checkAddressError('contactAddressDistrict','contactAddress')}">{{ contactAddressDistrict }}</label>
                </div>
            </div>
        <!-- รหัสไปรษณีย์ -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkAddressError('contactPostCode','contactAddress')}">{{ lang.postCode }}
                </label>
                <div class="mt-2">
                <label for="contactPostCode" class="font-md" :class="{ 'p-text-error': checkAddressError('contactPostCode','contactAddress')}">{{ contactPostCode }}</label>
                </div>
            </div>
        <!-- จังหวัด -->
            <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
                <label class="p-text-secondary" :class="{ 'p-text-error': checkAddressError('contactAddress3','contactAddress')}">{{ lang.province }}
                </label>
                <div class="mt-2">
                <label for="contactProvince" class="font-md" :class="{ 'p-text-error': checkAddressError('contactAddress3','contactAddress')}">{{ contactProvince }}</label>
                </div>
            </div>
        </div>
    <!-- ข้อมูลตามที่อยู่สำหรับติดต่อ -->

    </section>

    <template #footer>
        <PvButton
          :label="lang.close"
          class="p-button-outlined p-button-secondary w-3"
          @click="closeDialog"
        />
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
export default {
  emits: ["close"],
  props: {
    selectedResident: {
      type: Object,
    },
  },
  setup(props, context) {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const { formatDate } = useFormat();
    const store = useStore();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.resident.edit,
          ...lang.th.master.resident.import,
          ...lang.th.validateImport,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.resident.edit,
          ...lang.en.master.resident.import,
          ...lang.en.validateImport,
        };
      }
    });

    // ข้อมูลทั่วไป
    let code = ref("");
    let identificationCardNumber = ref("");
    let prefix = ref("");
    let name = ref("");
    let mobileCountryCode = ref("");
    let mobileNumber = ref("");
    let birthDate = ref("");
    let email = ref("");
    let nationality = ref("");
    let gender = ref("");
 
    //ข้อมูลติดต่อฉุกเฉิน
    let emergencyContactPrefix = ref("");
    let emergencyContactName = ref("");
    let emergencyCountryCode = ref("");
    let emergencyContactMobileNumber = ref("");

    //ข้อมูลที่อยู่สำหรับออกเอกสาร (ใบแจ้งหนี้, ใบเสร็จ, ฯลฯ)
    let billingAddress = ref("");
    let billingAddressSubDistrict = ref("");
    let billingAddressDistrict = ref("");
    let billingPostCode = ref("");
    let billingProvince = ref("");
    let billingLanguage = ref("");
    let customerGroup = ref("");
    let isGroupingInvoice = ref("");

    //ข้อมูลตามที่อยู่บัตรประชาชน
    let idCardAddress = ref("");
    let idCardAddressSubDistrict = ref("");
    let idCardAddressDistrict = ref("");
    let idCardPostCode = ref("");
    let idCardProvince = ref("");
    let registerDate = ref("");
    let expiredDate = ref("");

    //ข้อมูลตามที่อยู่สำหรับติดต่อ
    let contactAddress = ref("");
    let contactAddressSubDistrict = ref("");
    let contactAddressDistrict = ref("");
    let contactPostCode = ref("");
    let contactProvince = ref("");

    onMounted(() => {
      initiateData();
      if (props.selectedResident) {
        setData();
      }
    });

    const closeDialog = () => {
      context.emit("close");
    };

    const initiateData = () => {
        code.value = "";
        identificationCardNumber.value = "";
        prefix.value = "";
        name.value = "";
        mobileCountryCode.value = "";
        mobileNumber.value = "";
        birthDate.value = "";
        email.value = "";
        nationality.value = "";
        gender.value = "";
        emergencyContactPrefix.value = "";
        emergencyContactName.value = "";
        emergencyCountryCode.value = "";
        emergencyContactMobileNumber.value = "";
        billingAddress.value = "";
        billingAddressSubDistrict.value = "";
        billingAddressDistrict.value = "";
        billingPostCode.value = "";
        billingProvince.value = "";
        billingLanguage.value = "";
        customerGroup.value = "";
        isGroupingInvoice.value = "";
        idCardAddress.value = "";
        idCardAddressSubDistrict.value = "";
        idCardAddressDistrict.value = "";
        idCardPostCode.value = "";
        idCardProvince.value = "";
        registerDate.value = "";
        expiredDate.value = "";
        contactAddress.value = "";
        contactAddressSubDistrict.value = "";
        contactAddressDistrict.value = "";
        contactPostCode.value = "";
        contactProvince.value = "";
    };

    const checkError = (field) => {
      let error = props.selectedResident.error.find((error) => error.field == field);
      if (error) {
        return true;
      }
      return false;
    };

    const setData = () => {
        let data = props.selectedResident;
        code.value = data.code;
        identificationCardNumber.value = data.identificationCardNumber;
        prefix.value = data.prefix;
        name.value = data.name;
        mobileCountryCode.value = data.mobileCountryCode;
        mobileNumber.value = data.mobileNumber;

        let birthDateValue = data.birthDate;
        if (birthDateValue && birthDateValue != "" && !checkError("birthDate")) {
            birthDateValue = formatDate(birthDateValue, false);
        }
        birthDate.value = birthDateValue;
        email.value = data.email;
        nationality.value = data.nationality;
        gender.value = data.gender;
        emergencyContactPrefix.value = data.emergencyContactPrefix;
        emergencyContactName.value = data.emergencyContactName;
        emergencyCountryCode.value = data.emergencyCountryCode;
        emergencyContactMobileNumber.value = data.emergencyContactMobileNumber;
        billingAddress.value = data.billingAddress1;
        billingAddressSubDistrict.value = data.billingAddressSubDistrict;
        billingAddressDistrict.value = data.billingAddressDistrict;
        billingPostCode.value = data.billingPostCode;
        billingProvince.value = data.billingAddress3;
        billingLanguage.value = data.billingLanguage;
        customerGroup.value = data.customerGroup;
        isGroupingInvoice.value = data.isGroupingInvoice;
        idCardAddress.value = data.idCardAddress1;
        idCardAddressSubDistrict.value = data.idCardAddressSubDistrict;
        idCardAddressDistrict.value = data.idCardAddressDistrict;
        idCardPostCode.value = data.idCardPostCode;
        idCardProvince.value = data.idCardAddress3;

        let registerDateValue = data.registerDate;
        let expiredDateValue = data.expiredDate;
        if (registerDateValue && registerDateValue != "" && !checkError("registerDate")) {
            registerDateValue = formatDate(registerDateValue, false);
        }
        if (expiredDateValue && expiredDateValue != "" && !checkError("expiredDate")) {
            expiredDateValue = formatDate(expiredDateValue, false);
        }
        registerDate.value = registerDateValue;
        expiredDate.value = expiredDateValue;
        contactAddress.value = data.contactAddress1;
        contactAddressSubDistrict.value = data.contactAddressSubDistrict;
        contactAddressDistrict.value = data.contactAddressDistrict;
        contactPostCode.value = data.contactPostCode;
        contactProvince.value = data.contactAddress3;
    };

    const checkAddressError = (field, group) => {
      let data = props.selectedResident;
      let result = false;
      if (data.error.length > 0) {
        let error = data.error.find(
          (error) => error.field == group
        );
        if (error) {
          result = true;
        } else {
          error = data.error.find((error) => error.field == field);
          if (error) {
            result = true;
          }
        }
      }
      return result;
    };

    return {
        lang: mergeLang,
        textFormClass,
        formatDate,

        //data
        code,
        identificationCardNumber,
        prefix,
        name,
        mobileCountryCode,
        mobileNumber,
        birthDate,
        email,
        nationality,
        gender,
        emergencyContactPrefix,
        emergencyContactName,
        emergencyCountryCode,
        emergencyContactMobileNumber,
        billingAddress,
        billingAddressSubDistrict,
        billingAddressDistrict,
        billingPostCode,
        billingProvince,
        billingLanguage,
        customerGroup,
        isGroupingInvoice,
        idCardAddress,
        idCardAddressSubDistrict,
        idCardAddressDistrict,
        idCardPostCode,
        idCardProvince,
        registerDate,
        expiredDate,
        contactAddress,
        contactAddressSubDistrict,
        contactAddressDistrict,
        contactPostCode,
        contactProvince,

        //methods
        closeDialog,
        checkError,
        checkAddressError,
    }
  }
};
</script>
