export default {
	title: "ใบเสร็จรับเงิน / ใบกำกับภาษี",

	inputReceiptCode: "เลขที่",
	inputReceiptReferenceCode: "เลขที่อ้างอิง",
	inputReceiptDate: "วันที่เอกสาร",
	inputReceiptVatSendDate: "วันที่ยื่นภาษี",
	inputReceiptSupplier: "ผู้จัดจำหน่าย",
	inputEmployeeName: "พนักงาน",

	inputTotalAmount: "รวมยอดชำระ",
	inputDifferenceAmount: "เงินขาด / เงินเกิน",
	inputNetAmount: "มูลค่าสุทธิ",
	inputActualPayAmount: "ยอดชำระจริง",
	inputWithholdingTaxAmount: "ภาษีหัก ณ ที่จ่าย",
	inputDiscountAmount: "มูลค่าส่วนลด",
	inputSubAmount: "หัก อื่นๆ",
	inputAddAmount: "รับ อื่นๆ",
	inputDiscount: "ส่วนลด",
	inputCurrencyCode: "สกุลเงิน",
	inputTaxAmount: "อัตราภาษี",

	inputRemark1: "หมายเหตุ 1",
	inputRemark2: "หมายเหตุ 2",

	columnItemNo: "ลำดับ",
	columnModuleCode: "ประเภท",
	columnDocumentBookCode: "เล่มที่",
	columnReceiptDetailCode: "เลขที่",
	columnReferenceDocument: "เลขที่อ้างอิง",
	columnReceiptDetailDate: "วันที่",
	columnStatusPaid: "ชำระ",
	columnStatusWithholdingTax: "ภงด.",
	columnNetAmount: "จำนวนเงิน",
	columnDifferenceAmount: "ค้างชำระ",
	columnPayAmount: "ยอดชำระ",
	columnPaidAmount: "ยอดชำระแล้ว",

	importPurchaseInvoice: "นำเข้า",
	titlePurchaseInvoice: "ใบรับสินค้า/ใบรับวางบิล",
	columnInvoiceModuleCode: "ประเภท",
	columnInvoiceDocumentBookCode: "เล่มที่",
	columnInvoiceDetailCode: "เลขที่",
	columnInvoiceReferenceDocument: "เลขที่อ้างอิง",
	columnInvoiceDetailDate: "วันที่",
	columnInvoiceDueDate: "วันที่ครบกำหนด",
	columnInvoiceNetAmount: "จำนวนเงิน",
	columnInvoiceDifferenceAmount: "ค้างชำระ",
	columnInvoicePayAmount: "ชำระแล้ว",

	validateNoReceiptSupplierSelect: "กรุณาเลือกผู้จัดจำหน่าย",
	validateNoReceiptEmployeeSelect: "กรุณาเลือกพนักงาน",
	validateReceiptDetails: "กรุณาเพิ่มใบวางบิล",
	validateReceiptDiscount: "ระบุได้เฉพาะตัวเลข และ %",
	validateDiscountAmount: "มูลค่าส่วนลด ต้องไม่เกินมูลค่าสุทธิ",
	validatePurchaseInvoice: "มีใบวางบิลนี้อยู่แล้ว",
	validateActualPayAmount: "ยอดชำระต้องมากกว่า 0",
	validateMoreActualPayAmount: "ยอดชำระต้องไม่มากกว่าผลรวมของเช็ค",
	validateCheque: "กรุณาเพิ่มรายการจ่ายชำระก่อน",
	validateMorePurchaseInvoice: "กรุณาชำระรายการแรกให้ครบก่อน",
	validateTax: "ยอดภาษี มากกว่า ยอดชำระ",

	buttonWithholdingTax: "ภาษีหัก ณ ที่จ่าย",
	buttonPayout: "รายการจ่าย",
};
