export default {
	setMeterUsages(state, payload) {
		state.meterUsages = payload.data;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
	// setMeterUsages(state, payload){
    //     let meterUsages = [...state.meterUsages];
    //     for (const meterUsage of payload.data) {
    //         if(meterUsages.findIndex(c => c.meterId === meterUsage.meterId) < 0){
    //             meterUsages.push(meterUsage);
    //         }
    //     }
    //     state.meterUsages = meterUsages;
    //     state.currentPage = payload.page.currentPage;
    //     state.totalPage = payload.page.pageCount;
    // },
    setSelectedMeterUsage(state, payload) {
        state.selectedMeterUsage = payload;
    },
    resetMeterUsages(state) {
		state.meterUsages = [];
        state.currentPage = 0;
        state.totalPage = 0;
	},
    resetSelectedMeterUsage(state) {
        state.selectedMeterUsage = null;
    },
};
