export default {
  resetImportUnitResidents(context) {
    context.commit("resetImportUnitResidents");
  },
  importUnitResidents(context, payload) {
    context.commit("setImportUnitResidents", payload);
  },
  async getUnits(context, payload) {
    let url =
      process.env.VUE_APP_BACKEND_URL + "/Unit/GetUnitImportByCode";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get units");
      throw error;
    }
    // console.log("getUnitImportStockKeycard",responseData);

    context.commit("setUnits", responseData);
  }, 
  async getCustomers(context, payload) {
    let url =
      process.env.VUE_APP_BACKEND_URL + "/Customer/GetCustomerImport";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get units");
      throw error;
    }
    // console.log("getUnitImportStockKeycard",responseData);

    context.commit("setCustomers", responseData);
  },
};
