export default {
  setSupplier(state, payload) {
    let suppliers = [...state.supplier.suppliers];
    for (const supplier of payload.suppliers) {
      if(suppliers.findIndex(c => c.id === supplier.id) < 0){
        suppliers.push(supplier);
      }
    }
    state.supplier.suppliers = suppliers;
    state.supplier.currentPage = payload.currentPage;
    state.supplier.totalPage = payload.totalPage;
  },
  resetSupplier(state) {
    const supplier = {
      suppliers: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.supplier = supplier;
  },
};
