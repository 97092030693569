<template>
	<base-toolbar
		:title="this.title"
		:dataButtons="dataButtons"
		@goBack="goBack()"
		@gotoCreate="gotoCreate()"
		:hideBackButton="isHideBack"
  	>
	</base-toolbar>
	<base-dialog
		v-model:visible="showConfirmDialog"
		:titleConfirm="confirmTitle"
		:wordingConfirm="confirmContent"
		:classTitle="confirmClassTitle"
		:dataButtons="dataAlertButtons" 
		@confirm="confirmDialog()"
		@close="closeDialog()"
	>
	</base-dialog>
	<base-container>
		<div class="surface-card shadow-2 p-5 border-round my-5">
			<div class="flex justify-content-between align-items-center">
				<!-- <h2 :class="textHeaderClass" class="my-0" style="font-size:18px">{{ title }}</h2> -->
				<label class="font-lg font-normal">{{ title }}</label>
			</div>
			<PvDivider></PvDivider>

			<div class="p-datatable-border my-2">
				<PvDataTable
					:value="accountBooks"
					dataKey="id"
					selectionMode="single"
					responsiveLayout="scroll"
					:class="textTableClass"
					class="p-datatable-xs"
					:loading="isLoading"
					v-model:selection="selectedAccountBook"
					@row-dblclick="gotoEdit(this.selectedAccountBook.id)">
					<template #empty>
					<div class="w-full p-3" Style="text-align: center;">
						<label>{{ lang.tableEmpty }}</label>
					</div>
					</template>

					<PvColumn
						field="code"
						:header="lang.columnCode"
						headerClass="h-left font-normal"
						bodyClass="text-left font-normal"
						sortable 
                        style="height: 56px;"
                    />
					<PvColumn
						field="nameTh"
						:header="lang.columnName"
						headerClass="h-left font-normal"
						bodyClass="text-left font-normal"
                        sortable
                        style="height: 56px;"
                    />
                    <PvColumn
						field="lockDateEnd"
						:header="lang.colummLockDate"
						headerClass="h-left font-normal"
						bodyClass="text-left font-normal"
                        sortable
                        style="height: 56px;"
                    >
						<template #body="slotProps">
							{{ slotProps.data.lockDateEnd ? formatDate(slotProps.data.lockDateEnd) : "" }}
						</template>
					</PvColumn>

					<PvColumn
						style="max-width: 6rem"
						headerClass="h-center"
						bodyStyle="text-align: center; justify-content: center; align-items: center;"
						alignFrozen="right"
						frozen
					>
						<template #body="slotProps">
						<PvButton
							type="button"
							class="p-button-secondary p-button-text p-0 mr-3"
							@click="gotoEdit(slotProps.data.id)"
						>
							<icon-pen-edit iconColor="secondary" width="24" height="24" />
						</PvButton>
						</template>
					</PvColumn>

				</PvDataTable>
			</div>
		</div>
	</base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js"
import { useStore } from "vuex";
import { computed, inject } from "vue";
export default {
    setup() {
		const { lang } = useLang();
		const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
		const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
		const { getRoleByMenuByCode, isSharedRoleByMenuReady }  = useGetRoleByMenu();
		const isHideBack = inject("isHideBack");
		const store = useStore();
		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return {
					...lang.th.base,
					...lang.th.master.accountBook.listing,
				};
			} else {
				return {
					...lang.en.base,
					...lang.en.master.accountBook.listing,
				};
			}
		});
		return {
			lang: mergeLang,
			textHeaderClass,
			textFormClass,
			textTableClass,
			formatCurrency,
			formatDate,
			formatDateToISOString,
			getRoleByMenuByCode,
			isSharedRoleByMenuReady,
			isHideBack,
		};
	},
	data() {
		return {
			isLoading: false,
            selectedAccountBook: null,
			showConfirmDialog: false,
			confirmTitle: "",
			confirmContent: "",
			confirmClassTitle: "",
			isDisableCreateButton: true,
		};
	},
	async created() {
		this.isLoading = true;
		//await this.getAccountBookList();
	},
	async mounted() {
		//this.isLoading = true;
		await this.checkShouldSetData();
	},
	methods: {
		setLoading(bool) {
			this.$store.dispatch("setLoading", { value: bool });
		},
		openDialog(dataConfig) {
			// severity,title,content,isConfirm
			if (dataConfig.severity === "") {
				this.confirmClassTitle = "";
			} else if (dataConfig.severity === "success") {
				this.confirmClassTitle = "p-text-success";
			} else if (dataConfig.severity === "error") {
				this.confirmClassTitle = "p-error";
			}
			this.confirmTitle = dataConfig.title; 
			this.confirmContent = dataConfig.content; 
			this.showConfirmDialog = true;
		},
		closeDialog() {
			this.showConfirmDialog = false;	
		},
		goBack() {
			this.$router.push({name: "home"})
		},
		async checkShouldSetData() {
			if (this.isSharedRoleByMenuReady) {
				this.isDisableCreateButton = false;
				await this.getAccountBookList();
			}
		},
		gotoCreate() {
			if (this.isSharedRoleByMenuReady === true && this.roleByMenu.insert === "Y") {
				this.$router.push({name: "account-book-edit", params:{id: "create"}});
			} else {
				this.openDialog({
					severity: "",
					title: this.lang.notificationDialogHeader,
					content: this.lang.validateRoleInsert,
					isConfirm: false,
				});
			}
		},
		gotoEdit(accountBookId) {
			if (this.isSharedRoleByMenuReady === true && this.roleByMenu.select === "Y") {
				this.$router.push({name: "account-book-edit", params:{id: accountBookId}});
			} else {
				this.openDialog({
					severity: "",
					title: this.lang.notificationDialogHeader,
					content: this.lang.validateRoleSelect,
					isConfirm: false,
				});
			}
		},
        async getAccountBookList() {
			this.isLoading = true;
			try {
				let where = {
					projectId: this.selectedProject.id,
				};
				if (this.searchText != null) {
					where = {
						...where,
						searchText: this.searchText,
					};
				}
				const payload = {
					page: 1,
					limit: 100,
					where,
				};
				await this.$store.dispatch("masterAccountBook/getAccountBooks", payload);
				this.isLoading = false;
			} catch (error) {
				this.$toast.add({
					severity: "error",
					summary: "error",
					detail: error,
					life: 3000,
				});
				this.isLoading = false;
			}
		},
    },
    computed: {
		title() {
			return this.lang.title;
		},
		selectedProject() {
			return this.$store.getters.selectedProject;
		},
		accountBooks() {
			return this.$store.getters["masterAccountBook/accountBooks"];
		},
		roleByMenu() {
			return this.getRoleByMenuByCode("tmGLB");
		},
		dataAlertButtons() {
			let dataButtons = [
				{
					id: "close",
					caption: this.lang.close,
					class: "p-button-outlined p-button-secondary w-full",
					action: "close",
				},
			]
			return dataButtons
		},

		dataButtons() { 
			return [
			{
				id: "editCaption",
				caption: this.lang.createAccountBook,
				class: "mr-2",
				icon: "icon-plus-add",
				iconColor: "white",
				action: "gotoCreate",
				isShow: true,
			}]
		},
    },
	watch: {
		isSharedRoleByMenuReady() {
			this.checkShouldSetData();
		},
	},
}
</script>

<style scoped>
.p-pagination-radius {
  border-radius: 0 0 0px 0px;
}
</style>
