export default {
  uploadFile: "Upload File",
  dragFile: "Drag drop file or ",
  addFile: "Add File",
  importFileDesc: "Please add only 1 file at a time with .XLSX or ",
  downloadfile: "Download example file",
  fileName: "File Name",
  validateLimitFile: "Can be upload 1 file at a time",
  validateFileType: "Can't upload file :name",
  validateImportFile: "The allowed file extensions are .XLSX only",

  importReceipts: "Import Receipt",
  importAll: "Import All",
  importSelected: "Import Selected",
  columnUnitCode: "Unit",
  columnUnitAddressNo: "Address No.",
  columnUnitCustomerName: "Customer Name",
  columnDate: "Date",
  columnReferCode: "Refer Code",
  columnCustomerName: "Paid Name",
  columnCustomerAddressNo: "Paid Address No.",
  columnProductCode: "Product Code",
  columnPeriod: "Period",
  columnDescription: "Description",
  columnAmount: "Total Amount",
  columnPayType: "Pay Type",
  columnPayCode: "Pay Code",
  columnBookBankCode: "BookBank Code",
  columnBookBankBranch: "BookBank Branch",
  columnPayDate: "Pay Date",
  columnBranch: "Branch",

  validateImportNoData: "File : :file no data.",
  validateUnitCode: "Row :row no enter unit code.",
  validateReceiptDate: "Row :row no enter receipt date.",
  validateFormatReceiptDate: "Row :row invalid format receipt date(dd/MM/yyyy).",
  validateProductCode: "Row :row no enter product date.",
  validatePeriod: "Row :row no enter period.",
  validateFormatPeriod: "Row :row invalid format period(MM/YYYY(Thai)).",
  validateTotalAmount: "Row :row no enter total amount.",
  validateFormatTotalAmount: "Row :row invalid format total amount.",
  validatePayType: "Row :row no enter pay type.",
  validatePayTypeEpay: "Row :row doesn't support the receipts E-Payment",
  validateFormatPayType: "Row :row, pay type is invalid",
  validatePayCode: "Row :row pay type :type no enter pay code.",
  validateCreditCardPayCode: "Row :row pay type :type no enter credit card no.",
  validateBookBankCode: "Row :row pay type :type no enter book bank code.",
  validatePayDate: "Row :row no enter pay date.",
  validateFormatPayDate: "Row :row invalid format pay date(dd/MM/yyyy).",
  validateUnitCodeInvalid: "Unit code : :unitCode is invalid.",
  validateProductCodeInvalid: "Unit code : :unitCode invalid product code.",
  validateBookBankCodeInvalid: "Unit code : :unitCode invalid book bank code.",

  statusWaiting: "Waiting",
  statusSuccess: "Success",
  importReceiptSuccess: "import Receipt Success.",
  pleaseSelectItem: "Please select item.",
  uploadStatus: "Upload status",
  uploading: "Uploading...",
  import: "Import",
  receiptDetail: "Receipt Detail",
  error: "Error",
  errorDetail: "Receipt amount more than pending amount.",
  
  validatingData: "Validating",
  mappingInvoice: "Mapping invoice",
  unitCodeIsNotValid: "UnitCode is not valid “:unitCode”",
  productCodeIsNotValid: "ProductCode is not valid “:productCode”",
  unitCodeIsNotActive: "UnitCode is not active “:unitCode”",

};
