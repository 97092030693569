<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
    :header="lang.bookBank"
  >
    <PvDataTable
      showGridlines
      :value="filteredBookBankOrder"
      v-model:selection="selectedBookBank"
      dataKey="id"
      :loading="isLoading"
      responsiveLayout="scroll"
      class="p-datatable-sm"
      :class="textTableClass"
      @row-dblclick="getSelectedBookBank()"
      selectionMode="single"
    >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
      <template #header>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <PvInputText
            v-model="searchText"
            :placeholder="lang.keywordSearch"
            autofocus
          />
        </span>
      </template>
      <PvColumn selectionMode="single"></PvColumn>

      <PvColumn
        field="code"
        :header="lang.columnPayCode"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>

      <PvColumn
        field="accountCode"
        :header="lang.columnAccountCode"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>

      <PvColumn
        field="branchName"
        :header="lang.columnBranch"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>

      <PvColumn
        field="bankName"
        :header="lang.columnBank"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>
    </PvDataTable>

    <template #footer>
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog"
      />
      <PvButton
        :label="lang.ok"
        icon="pi pi-check"
        autofocus
        @click="getSelectedBookBank()"
      />
    </template>
  </PvDialog>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import useGetBookBank from "../../hooks/getBookBanks.js";
export default {
  props: ["inputSearchText"],
  emits: ["close", "load-finish", "select-book-bank"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const { filteredBookBank, isSharedBookBankReady } = useGetBookBank();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
          ...lang.th.ar.bank.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
          ...lang.en.ar.bank.listing,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      filteredBookBank,
      isSharedBookBankReady,
    };
  },
  data() {
    return {
      isLoading: true,
      searchText: null,
      selectedBookBank: [],
    };
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.isLoading = false;
      this.$emit("close");
    },
    getSelectedBookBank() {
      // console.log("this.selectedBookBank=",this.selectedBookBank);
      this.$emit("select-book-bank", this.selectedBookBank);
      this.isLoading = false;
      this.$emit("close");
    },
  },
  computed: {
    filteredBookBankOrder() {
      if (this.filteredBookBank) {
        if (!this.searchText) {
          const result = [...this.filteredBookBank];
          return result;
        } else {
          return this.filteredBookBank.filter((data) => {
            return (
              data.code ? data.code.toLowerCase().includes(this.searchText.toLowerCase()) : "" ||
              data.accountCode ? data.accountCode.toLowerCase().includes(this.searchText.toLowerCase()) : "" ||
              data.branchName ? data.branchName.toLowerCase().includes(this.searchText.toLowerCase()) : "" ||
              data.bankName ? data.bankName.toLowerCase().includes(this.searchText.toLowerCase()) : ""
            );
          });
        }
      }else{
        // console.log("this.filteredBookBank = null");
        return [];
      }
    },
  },
  watch: {
    isSharedBookBankReady(curValue) {
      if (curValue) {
        this.$emit("load-finish");
        this.isLoading = false;
      }
    },
    searchText(curValue) {
      if (curValue) {
        this.searchText = curValue;
      }
    },
    inputSearchText(curValue) {
        this.searchText = curValue;      
    },
    filteredBookBankOrder() {
      this.selectedBookBank = this.filteredBookBankOrder[0];
    },
  },
};
</script>
