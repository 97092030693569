<template>
  <PvDialog
    :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
    :style="{ width: '40vw' }"
    :modal="true"
    :closable="false"
    :header="lang.changPassword"
  >
    <template #header>
      <div
        class="flex justify-content-between align-items-center flex flex-wrap pt-3 pb-3"
      >
        <span class="font-lg">{{ lang.changPassword }}</span>
      </div>
      <PvDivider class="mt-3 mb-3" />
    </template>
    <section class="flex flex-wrap mt-0 mb-0" :class="textFormClass">
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pb-0">
        <label for="currentPassword">{{ lang.currentPassword }}</label>
        <PvInputText
          id="currentPassword"
          :type="passwordFieldType"
          class="inputfield w-full mt-1"
          v-model="currentPassword"
          :class="{ 'p-invalid': isCurrentPasswordError }"
          @keypress="isCurrentPasswordError = false"
        />
        <small v-if="isCurrentPasswordError" class="p-error">{{
          lang.currentPassword
        }}</small>
      </div>

      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pb-0">
        <label for="newPassword">{{ lang.newPassword }}</label>
        <PvInputText
          id="newPassword"
          :type="passwordFieldType"
          class="inputfield w-full mt-1"
          v-model="newPassword"
          :class="{
            'p-invalid': isNewPasswordError,
            'p-invalid': isPasswordNotMatch,
          }"
          @blur="blurNewPassword()"
        />
        <small v-if="isNewPasswordError" class="p-error">{{
          lang.newPassword
        }}</small>
        <small v-if="isPasswordNotMatch" class="p-error">{{
          lang.passwordNotMatch
        }}</small>
      </div>

      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pb-0">
        <label for="confirmNewPassword">{{ lang.confirmNewPassword }}</label>
        <PvInputText
          id="confirmNewPassword"
          :type="passwordFieldType"
          class="inputfield w-full mt-1"
          v-model="confirmNewPassword"
          :class="{
            'p-invalid': isConfirmNewPasswordError,
            'p-invalid': isPasswordNotMatch,
          }"
          @blur="blurConfirmNewPassword()"
        />
        <small v-if="isConfirmNewPasswordError" class="p-error">{{
          lang.confirmNewPassword
        }}</small>
        <small v-if="isPasswordNotMatch" class="p-error">{{
          lang.passwordNotMatch
        }}</small>
      </div>
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pb-0">
        <div class="field-checkbox mt-2">
          <PvCheckbox
            id="selectedIsShowPassword"
            v-model="selectedIsShowPassword"
            :binary="true"
            @change="switchVisibility()"
          />
          <label for="selectedIsShowPassword">{{ lang.showPassword }}</label>
        </div>
      </div>
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pb-0">
        <label class="p-error" >{{ this.errorMessage }}</label>
      </div>
    </section>

    <template #footer>
      <div class="flex justify-content-between align-items-center m-3">
        <PvButton
          :label="lang.close"
          class="p-button-outlined p-button-secondary w-full"
          @click="closeDialog()"
        />
        <PvButton :label="lang.confirm" class="w-full" @click="confirm()" />
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  props: {
    errorMessage: {
      type: String,
    },
  },
  emits: ["close","confirm-change-password"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass } = useTextStyle();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.auth.changpassword };
      } else {
        return { ...lang.en.base, ...lang.en.auth.changpassword };
      }
    });
    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
    };
  },
  data() {
    return {
      isLoading: true,
      currentPassword: "",
      isCurrentPasswordError: false,
      newPassword: "",
      isNewPasswordError: false,
      confirmNewPassword: "",
      isConfirmNewPasswordError: false,
      selectedIsShowPassword: false,
      isPasswordNotMatch: false,
      passwordFieldType: "password",

    };
  },  
  mounted() {
    // console.log("mounted");
    this.initiateData();
  },
  methods: {
    initiateData() {
      this.currentPassword = "";
      this.isCurrentPasswordError = false;
      this.newPassword = "";
      this.isNewPasswordError = false;
      this.confirmNewPassword = "";
      this.isConfirmNewPasswordError = false;
      this.selectedIsShowPassword = false;
      this.isPasswordNotMatch = false;
      this.passwordFieldType = "password";
    },
    closeDialog() {
      this.initiateData();
      this.$emit("close");
    },
    async confirm() {
      if (this.validateData()) {
        return true;
      }

      const payload = {
        userId: this.userId,
        usercode: this.userCode,
        username: this.userName,
        oldPassword: this.currentPassword,
        newPassword: this.newPassword,
        confirmNewPassword: this.confirmNewPassword,
      };

      this.$emit("confirm-change-password", payload);
    },
    validateData() {
      let isError = false;
      if (!this.currentPassword) {
        this.isCurrentPasswordError = true;
        isError = true;
      }

      if (!this.newPassword) {
        this.isNewPasswordError = true;
        isError = true;
      }

      if (!this.confirmNewPassword) {
        this.isConfirmNewPasswordError = true;
        isError = true;
      }

      if (this.newPassword !== this.confirmNewPassword) {
        if (!this.isNewPasswordError && !this.isConfirmNewPasswordError) {
          this.isPasswordNotMatch = true;
          isError = true;
        }
      }

      return isError;
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    blurNewPassword() {
      this.isNewPasswordError = false;
      this.isPasswordNotMatch = false;
    },
    blurConfirmNewPassword() {
      this.isConfirmNewPasswordError = false;
      this.isPasswordNotMatch = false;
    },   
  },
  computed: {
    userId() {
      return this.$store.getters.userId;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    userName() {
      return this.$store.getters.userName;
    },
  },
};
</script>
