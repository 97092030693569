export default {
  async getNoticeGenerateQueueStatus(context, payload) {
    let url =
      process.env.VUE_APP_BACKEND_URL + "/Invoice/InvoiceGenerateQueue/Status";

    if (payload) {
      url = url + "?projectId=" + payload;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get notice generate queue status."
      );
      throw error;
    }
    // console.log("getNoticeGenerateQueueStatus",responseData);

    context.commit("setNoticeGenerateQueueStatus", responseData);
  },
  async addNoticeGenerateQueue(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Invoice/InvoiceGenerateQueue/Add`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || "Failed to add notice generate queue.");
      throw error;
    }

    // console.log("addNoticeGenerateQueue", responseData);

    context.commit("setSelectedNoticeGenerateQueue", responseData);
  },
  async getNotices(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Notice/NoticeWaitForApprove";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get notices wait for approve");
      throw error;
    }

    // console.log("getNotices", responseData);

    context.commit("setNotice", responseData);
  },
  async getNoticeById(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Notice/NoticeWaitForApproveById";

    if (payload) {
      url = url + "?Id=" + payload;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get notice by id."
      );
      throw error;
    }
    // console.log("getNoticeById",responseData);

    context.commit("setSelectedNotice", responseData.data);
  },
  resetSelectedNotice(context) {
    context.commit("setSelectedNotice", null);
  },
  async cancelGeneratedNotice(context,payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Invoice/CancelGeneratedInvoice";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to cancel notice wait for approve.');
      throw error;
    }

    // console.log("cancelNotice", responseData);
    
    context.commit("setNoticeIsCanceled", responseData);
  },
  async addGenerateNotice(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Invoice/AddGeneratedInvoice`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || "Failed to add generate notice.");
      throw error;
    }

    // console.log("addGenerateNotice", responseData);

    context.commit("setGeneratedNotice", responseData);
  },
  resetNoticeGenerateQueue(context) {
    context.commit("resetNoticeGenerateQueue", null);
  },
};
