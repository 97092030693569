<template>
  <PvInputText
    id="discountInputText"
    type="text"
    class="inputfield"
    v-model.trim="discountAmountText"
    @focus="$event.target.select()"
    @keyup="checkInputDiscount('keyup')"
    @paste="onPaste"
  />
</template>
<script>
import useCalculation from "../../hooks/calculation.js";
export default {
  emits: ["emit-discount-amount"],
  props: ["defaultDiscount"],
  setup() {
    const { round } = useCalculation();
    return { round };
  },
  data() {
    return {
      discountAmountText: null,
      discountAmount: "",
    };
  },
  methods: {
    checkInputDiscount(event) {
      if (this.discountAmountText) {
        this.discountAmountText = this.discountAmountText.toString().replaceAll(",", "").replaceAll("-", "");
        const pattern = /^\d+(\.\d{0,2})?%?$/;
        const isnum = pattern.test(this.discountAmountText);

        if (isnum) {
          this.discountAmount = this.discountAmountText;
        } else if (!isnum && event === "paste") {
          if (!this.discountAmountText.includes("%")) {
            this.discountAmount = this.round(this.discountAmountText);
          } else {
            this.discountAmountText = this.discountAmountText.toString().replaceAll("%", "");
            this.discountAmount = this.round(this.discountAmountText);
            this.discountAmount += "%";
          }
          const reIsnum = pattern.test(this.discountAmount);
          if (!reIsnum) {
            this.discountAmount = "";
          }
        }
        this.discountAmountText = this.discountAmount;
      } else {
        this.discountAmount = ""
      }
      this.$emit("emit-discount-amount", this.discountAmount);
    },
    onPaste(event) {
      this.discountAmountText = event.clipboardData.getData("text");
      this.checkInputDiscount("paste");
    },
  },
  watch: {
    defaultDiscount() {
      this.discountAmountText = this.defaultDiscount;
    }
  },
};
</script>
