<template>
	<PvDialog
		:breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
		:style="{ width: '30vw' }"
		:modal="true"
		:closable="false">
		<template #header>
			<div class="flex justify-content-between align-items-center">
				<div class="pt-2 pb-1">
					<label class="font-lg font-normal">
						{{ this.title }}
					</label>
				</div>
				<PvButton
					icon="pi pi-times"
					class="p-button-link p-icon-secondary"
					@click="closeDialog" />
			</div>
			<PvDivider></PvDivider>
		</template>

		<transition>
			<section class="flex flex-wrap" :class="textFormClass">
				<div class="col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
					<label for="reportType">{{ lang.reportType }}</label>
					<div class="flex">
						<div
							v-for="reportType of reportTypes"
							:key="reportType.code"
							class="field-radiobutton pt-3 mr-5">
							<PvRadioButton
								class="mr-2"
								:inputId="reportType.code"
								name="reportType"
								:value="reportType.code"
								v-model="selectReportType"
								@change="changeReportType()" />
							<label :for="reportType.code">{{ reportType.name }}</label>
						</div>
					</div>
				</div>
				<div class="col-12 pl-0 pr-2">
					<label for="period">{{ lang.period }}</label>
					<PvCalendar
						id="period"
						v-model="period"
						class="inputfield w-full mt-1"
						showIcon
						view="month"
						dateFormat="mm/yy" 
						:class="{ 'p-invalid': periodError }"
						@date-select="periodError = false"
					/>
					<small v-if="periodError" id="period-help" class="p-error">
						{{lang.validateDateSelect}}
					</small>
				</div>

				<div class="col-12 pl-0 pr-2" v-if="showStatusUsageSelect">
					<label for="statusMeterUsage">{{ lang.statusMeterUsage }}</label>
					<PvDropdown
						class="inputfield w-full mt-1"
						:options="statusMeterUsage"
						optionLabel="name"
						optionValue="code"
						v-model="selectStatusMeterUsage" />
				</div>
				<div class="col-12 pl-0 pr-2">
					<label for="fileNameText">{{ lang.fileName }}</label>
					<PvInputText
						id="fileNameText"
						type="text"
						class="inputfield w-full mt-1"
						v-model="fileNameText"
					/>
        		</div>
			</section>
		</transition>
		<div class="pt-4 pb-1 flex justify-content-between align-items-center">
			<PvButton
				icon="pi pi-file-pdf"
				:label="lang.downloadPDF"
				@click="download('PDF')"
				class="mr-2 mb-2 w-full" 
			/>
			<PvButton
				icon="pi pi-file-excel"
				:label="lang.downloadExcel"
				@click="download('EXCEL')"
				class="mr-2 mb-2 w-full" 
			/>
		</div>
	</PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useTextStyle from "../../hooks/textStyle.js";
import useLang from "../../hooks/lang.js";
import useFormat from "../../hooks/format.js";
import useTracking from "../../hooks/tracking";
import useReportRequestValidate from "../../hooks/reportRequestValidate.js";
export default {
	props: {
		reportObj: {
			type: Object,
			default: null,
		},
	},
	emits: ["close", "toast-request"],
	setup() {
		const { lang } = useLang();
		const store = useStore();
		const { textFormClass } = useTextStyle();
		const { formatDateToISOString, formatPeriodToISOString } = useFormat();
    	const { addReportTracking } = useTracking();
		const { reportPrintOrder } = useReportRequestValidate();
		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return {
					...lang.th.base,
					...lang.th.master.meter.report,
					...lang.th.gl.reportGl,
				};
			} else {
				return {
					...lang.en.base,
					...lang.en.master.meter.report,
					...lang.th.gl.reportGl,
				};
			}
		});
		return {
			lang: mergeLang,
			textFormClass,
			formatDateToISOString,
			formatPeriodToISOString,
			addReportTracking,
			reportPrintOrder,
		};
	},
	data() {
		return {
			reportTypes: [
				{ name: this.lang.summaryReportType, code: "summary" },
				{ name: this.lang.historyReportType, code: "history" },
			],
			selectReportType: "summary",
			period: new Date(),
			periodError: false,
			statusMeterUsage: [
				{ name: this.lang.statusAll, code: "A" },
				{ name: this.lang.statusGreen, code: "G" },
				{ name: this.lang.statusYellow, code: "Y" },
				{ name: this.lang.statusRed, code: "R" },
			],
			selectStatusMeterUsage: "A",
			showStatusUsageSelect: true,
			type: "PDF",
			fileNameText: "meter_usage",
		};
	},
	mounted() {
    	this.initiateData();
	},
	methods: {
		setLoading(bool) {
			this.$store.dispatch("setLoading", { value: bool });
		},
		closeDialog() {
			this.$emit("close");
			this.initiateData();
		},
		initiateData() {
			this.selectReportType = "summary";
			this.selectStatusMeterUsage = "A";
			this.period = new Date();
			this.periodError = false;
			this.type = "PDF";
      		this.fileNameText = "meter_usage";
		},
		validateData() {
			let validateError = false;
			if (this.selectedPeriod == null) {
				this.periodError = true;
				validateError = true;
			}
			return validateError;
		},
		changeReportType() {
			if (this.selectReportType === "history") {
				this.showStatusUsageSelect = false;
				this.selectStatusMeterUsage = "";
			} else {
				this.showStatusUsageSelect = true;
				this.selectStatusMeterUsage = "A";
			}
			// this.period = new Date();
		},
		async download(typeReport) {
			this.type = typeReport;
			if (this.validateData()) {
				return;
			}
			this.setLoading(true);
			try {
        		this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
				const response = await this.reportPrintOrder(this.payload);
				if (response) {
					this.$emit("toast-request");
				}
				this.setLoading(false);
			} catch (error) {
				this.$toast.add({
					severity: "error",
					summary: this.lang.notificationDialogHeader,
					detail: error,
					life: 3000,
				});
				this.setLoading(false);
			}
		},
	},
	computed: {
		selectedProject() {
			return this.$store.getters.selectedProject;
		},
		title() {
			if (this.$store.getters.selectedLanguage === "TH") return this.reportObj.nameThai;
			else return this.reportObj.nameEnglish;
		},
		userId() {
			return this.$store.getters.userId;
		},
		userName() {
			return this.$store.getters.userName;
		},
		selectedPeriod() {
			let period = null;
			if (this.period) {
				period = this.formatDateToISOString(this.period);
				if (this.selectReportType === "summary") {
					period = this.formatPeriodToISOString(this.period);
				}
			}
			return period;
		},
		selectedMeterActiveDate() {
			let period = null;
			if (this.period) {
				period = this.formatDateToISOString(this.period);
			}
			return period;
		},
		productType() {
			let productType = "";
			if (this.reportObj.sequence === "1") {
				productType = "W";
			}
			if (this.reportObj.sequence === "2") {
				productType = "E";
			}
			if (this.reportObj.sequence === "3") {
				productType = "A";
			}
			return productType;
		},
		fileName() {
			let fileName = this.type == "PDF" ? ".pdf" : ".xlsx";
			return this.fileNameText + fileName;
		},
		fileType() {
			let fileType =
				this.type == "EXCEL"
				? "_excel.xlsx"
				: this.type == "JASPEREXCEL"
				? ".xlsx"
				: ".pdf";
			return fileType;
		},
		reportPath(){
			let report = "meter/meter_usage_history";
			if (this.selectReportType === "summary") {
				report = "meter/meter_usage_monthly";
			}
			return report; 
    	},
		payload() {
			let payload = {
				ProjectId: this.selectedProject.id,
					ProductType: this.productType,
					Period: this.selectedPeriod,
				FileName: this.fileName,
				JasperPath: this.reportPath + this.fileType,
				DocType: this.type,
				UserId: this.userId,
				PrintBy: this.userName,
				RequestId: 0,
			};
			if (this.selectReportType === "summary") {
				payload = {
					...payload,
					MeterActiveStatus: this.selectStatusMeterUsage,
					MeterActiveDate: this.selectedMeterActiveDate,
				};
			}
			return payload;
		},
	},
	watch: {
		reportObj() {
			this.initiateData();
		},
	},
};
</script>
<style>
.p-dialog .p-dialog-header {
	padding: 1.5rem 1.5rem 0.5rem 1.5rem;
}
</style>
