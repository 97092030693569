import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default function useGetFormPrint() {
  const store = useStore();
  let searchTextPrint = ref(null);

  const forms = computed(() => {
    return store.getters["shared/formPrintForms"];
  });

  // const selectFormPrint = computed(() => {    
  //   return forms.value;
  // });

  const formPrintIsLastPage = computed(() => {
    return store.getters["shared/formPrintIsLastPage"];
  });

  const filteredFormPrints = computed(() => {
    let formPrints = forms.value ;
    let formDefault = forms.value.filter((e) => {return (e.category.toLowerCase() == "all")}).length ;
    if(formPrints.length > 1 && formDefault <= 1){  
      formPrints = forms.value.filter((e) => {return (e.category.toLowerCase() != "all")});
    }

    let formPrintsAddItem = [];
    let number = 1;
    if(formPrints){
      formPrints.forEach((e) => {
        formPrintsAddItem.push({...e, itemNo: number});
        number++;
      });
    }

    if (!searchTextPrint.value) {
      //ช่องว่าง
      const result = [...formPrintsAddItem];
      return result;
    } else {
      return formPrintsAddItem.filter((form) => {
        return (
          form.name.toLowerCase().includes(searchTextPrint.value.toLowerCase())
        );
      });
    }
  });

  let isSharedFormPrintReady = ref(true);

  const getFormPrints = async function (payload) {
    isSharedFormPrintReady.value = true;
    if (payload != null) {
      if (!formPrintIsLastPage.value) {
        await store.dispatch("shared/getFormPrints", payload);
      }
    }    
    isSharedFormPrintReady.value = false;
  };

  const getPreviewBlob = async function (blob) {
    // console.log('getPreviewBlob: ', blob);
    if (blob != null) {
      window.open(blob);
    }
  };

  const getDownloadBlob = async function (filename, blob) {
    if (blob != null) {
      // window.location.assign(blob);
      let a = document.createElement('a');
      a.href = blob;
      a.download = filename;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    }
  };

  const getPreviewUrl = async function (url) {
    if (url != "") {
      window.open(url, '_blank', 'noreferrer');
    }
  };

  onMounted(async () => {
    // console.log('on')
    await getFormPrints();
  });

  // watch(getFormPrints(), function () {
  //   isSharedFormPrintReady.value = true;
  // });

  return {
    getFormPrints,
    filteredFormPrints,
    searchTextPrint,
    isSharedFormPrintReady,
    getPreviewBlob,
    getDownloadBlob,
    getPreviewUrl
  };
}
