export default {
	bookBanks(state) {
		return state.bookBanks;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	selectedBookBank(state) {
		return state.selectedBookBank;
	},
	bookBankNextPage(state) {
		return state.currentPage + 1;
	},
	bookBankIsLastPage(_, getters) {
		// return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
		if (getters.currentPage > 0 && getters.totalPage === 0) {
			return true;
		} else {
			return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
		}
	},
	activeStatus(state) {
		return state.activeStatus;
	},
};
