export default {
	title: "Withholding Tax",
	selectTaxBook: "Select Tax Book",
	type: "Type",
	book: "Book",
	customerSearch: "Customer/ Supplier",
	dateWhtStart: "Pay Date Start",
	dateWhtEnd: "Pay Date End",
	columnDate: "Pay Date",
	columnDueDate: "Tax Due Date",
	columnCode: "Code",
	columnBook: "Book",
	columnType: "Type",
	columnPrice: "Price Pay Amount",
	columnIsCancel: "Canceled",
	columnVoucherCode: "Voucher Code",
	changeBook: "Change Book",
	columnTaxAmount: "Tax Total Amount",
	unLink: "Can't unlink document because already have reference document.",
	importDocument: "Import Document",
	confirmDocument: "Confirm document",
	removeDocument: "Remove document",
	withholdingTaxPrintLot: "Print Lot Withholding Tax",
	validateDateStart: "Please select date start",
	validateDateEnd: "Please select date end",
	validateCodeStart: "Please enter code start",
	validateCodeEnd: "Please enter code end"
};
