import { useStore } from "vuex";
import { computed } from "vue";
export default function useGetImportExistSupplier() {

    const store = useStore();

    const params = computed(() => {
        return store.getters["importExistSupplier/params"];
    });

    const resetImportExistSupplier = () => {
        store.dispatch("importExistSupplier/resetImportExistSuppliers");
    };

    const setImportExistSupplierParams = (data, field) => {
        const uniqueDatas = [...new Set(data.map(item => item[field]))];
        store.dispatch("importExistSupplier/setParams",uniqueDatas);
    };

    const loadImportExistSupplier = async (field = "code",limit = 100) => {
        let payload = {
            field: field,
            limit: limit ,
        };
        if (params.value && params.value.length > 0) {
            await store.dispatch("importExistSupplier/getImportExistSuppliers", payload);
        }
    }    

    const getImportExistSupplier = () => {
        return store.getters["importExistSupplier/importExistSuppliers"];
    }

    return {
        resetImportExistSupplier,
        setImportExistSupplierParams,
        loadImportExistSupplier,
        getImportExistSupplier,
    };
}