<template>
  <PvDialog
    :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
    :style="{ width: '30vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ titleAverage }}</label>
      </div>
      <PvDivider></PvDivider>
    </template>
    <section class="flex flex-wrap">
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
        <label class="p-text-secondary">{{ lang.labelAverageAmount }}</label>
        <div class="w-full">
          <PvInputNumber
            class="inputfield w-full mt-1"
            inputClass="text-right"
            v-model="amount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            :min="0"
            @input="amount = $event.value"
            @keyup="calculateAverageAmount()"
            @focus="$event.target.select()"
            :class="{ 'p-invalid': amountError }"
          />
          <small v-if="amountError" id="amount-help" class="p-error">{{
            lang.validateAverageAmount
          }}</small>
        </div>
      </div>
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
        <label class="p-text-secondary">{{
          lang.labelExplainAverageAmount
        }}</label>
        <div>
          <PvInputNumber
            id="averageAmount"
            class="inputfield w-full mt-1"
            inputClass="input-number-disable text-right"
            v-model="averageAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            disabled
          />
        </div>
      </div>
    </section>

    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-6 md:col-6 col-12 pl-1 pr-1">
          <PvButton
            :label="lang.close"
            class="p-button-outlined p-button-secondary w-full"
            @click="closeDialog()"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-1 pr-1">
          <PvButton
            :label="lang.confirm"
            class="w-full"
            @click="confirmDialog()"
          />
        </div>
      </div>
    </template>
  </PvDialog>
</template>
<script>
import useLang from "../../hooks/lang.js";
import useCalculation from "../../hooks/calculation.js";
import { useStore } from "vuex";
import { ref, computed } from "vue";

export default {
  emits: ["close", "confirm"],
  props: {
    period: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { lang } = useLang();
    const { sum, subtract, round } = useCalculation();
    const store = useStore();
    const mergeLang = computed(() => {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.budget.listing,
          ...lang.th.budget.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.budget.listing,
          ...lang.en.budget.edit,
        };
      }
    });

    let amount = ref(0);
    let amountError = ref(false);  
    let averageAmount = ref(0); 
    let totalAmounts = ref([]);
    

    const calculateAverageAmount = () => {
      totalAmounts.value = [];      
      amountError.value = false;
      let totalAmount = 0;
      let amountAverage = amount.value / props.period;
      averageAmount.value = round(amountAverage);
      
      for (let index = 0; index < props.period; index++) {
        totalAmount = sum([totalAmount, amountAverage]);   
        totalAmounts.value.push(averageAmount.value);  
      }
      
    //   console.log("amount.value=",amount.value);
    //   console.log("totalAmount=",totalAmount);

      let fraction = subtract(amount.value, totalAmount);
      if(fraction != 0){        
        totalAmounts.value[props.period-1] = sum([averageAmount.value, fraction]);
      }
    //   console.log("totalAmounts.value=",totalAmounts.value);
    };

    const closeDialog = () => {
      emit("close");
    };

    const confirmDialog = () => {
      if(!amount.value || amount.value == 0){
        amountError.value = true;
        return;
      }
      emit("confirm",totalAmounts.value);
    };

    const titleAverage = computed(() => {
      let title = mergeLang.value.titleAverageAmount.replace(
        ":month",
        props.period
      );
      return title;
    });

    return {
      lang: mergeLang,
      sum, 
      subtract, 
      round,
      amount,
      amountError,
      averageAmount,
      totalAmounts,

      calculateAverageAmount,
      closeDialog,
      confirmDialog,
      titleAverage,
    };
  },
};
</script>
