export default {
    async GetProjectById(context, payload) {

        let url = process.env.VUE_APP_BACKEND_URL + "/Project/GetProjectById";
        
        if(payload){
            url = url + '?ProjectId='+ payload ;
        }
		    url = encodeURI(url);
        
        const token = context.rootGetters.token;
       
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
          },
        });
    
        const responseData = await response.json();
    
        if (!response.ok) {
          // console.log(responseData);
          let message = null
          if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
            message = responseData.title;
            for (const key in responseData.errors) {
              message += ", " + key.replace("$.","")
            }
          } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
            message = responseData.message
          }
          const error = new Error(message || "Failed to get project");
          throw error;
        }
        // console.log("GetProjectById=",responseData);
    
        context.commit('setProject', responseData);
      },

      async GetProjectType(context) {

        let url = process.env.VUE_APP_BACKEND_URL + "/Project/GetProjectType";
       
        const token = context.rootGetters.token;
       
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
          },
        });
    
        const responseData = await response.json();
    
        if (!response.ok) {
          // console.log(responseData);
          let message = null
          if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
            message = responseData.title;
            for (const key in responseData.errors) {
              message += ", " + key.replace("$.","")
            }
          } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
            message = responseData.message
          }
          const error = new Error(message || "Failed to get project");
          throw error;
        }
        // console.log("GetProjectType=",responseData);
    
        context.commit('setProjectType', responseData);
      },

      async UpdateProjectById(context, payload){
        let url = process.env.VUE_APP_BACKEND_URL + `/Project/UpdateProject`;
        const token = context.rootGetters.token;
    
        const response = await fetch(url, {
          method: 'PUT',
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
    
          body: JSON.stringify(payload),
        });
    
        const responseData = await response.json();
    
        if (!response.ok) {
          // console.log(responseData);
          let message = null
          if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
            message = responseData.title;
            for (const key in responseData.errors) {
              message += ", " + key.replace("$.","")
            }
          } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
            message = responseData.message
          }
          const error = new Error(message || 'Failed to revert');
          throw error;
        }
        
        context.commit("setProject", responseData);
      },  

      
      async UpdateAdvertisement(context, payload){
        let url = process.env.VUE_APP_BACKEND_URL + `/Project/UpdateAdvertisement`;
        const token = context.rootGetters.token;
    
        const response = await fetch(url, {
          method: 'PUT',
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
    
          body: JSON.stringify(payload),
        });
    
        const responseData = await response.json();
    
        if (!response.ok) {
          // console.log(responseData);
          let message = null
          if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
            message = responseData.title;
            for (const key in responseData.errors) {
              message += ", " + key.replace("$.","")
            }
          } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
            message = responseData.message
          }
          const error = new Error(message || 'Failed to revert');
          throw error;
        }
        
        context.commit("setProject", responseData);
      },  
       
      async UpdatePenaltyCalculation(context, payload){
        let url = process.env.VUE_APP_BACKEND_URL + `/Project/UpdatePenaltyCalculation`;
        const token = context.rootGetters.token;
    
        const response = await fetch(url, {
          method: 'PUT',
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
    
          body: JSON.stringify(payload),
        });
    
        const responseData = await response.json();
    
        if (!response.ok) {
          // console.log(responseData);
          let message = null
          if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
            message = responseData.title;
            for (const key in responseData.errors) {
              message += ", " + key.replace("$.","")
            }
          } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
            message = responseData.message
          }
          const error = new Error(message || 'Failed to revert');
          throw error;
        }
        
        context.commit("setProject", responseData);
      },  

};