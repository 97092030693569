<template>
  <base-container>
    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchTextPrint"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>
    <div class="flex justify-content-between align-items-center" style="padding-top: 20px">
      <div class="my-3">
        <PvButton
          icon="pi pi-chevron-left"
          :label="lang.back"
          @click="goBack"
          class="mr-2 p-button-secondary"
          v-if="isHideBack"
        />
      </div>
      <h2 :class="textHeaderClass" class="my-0">{{ lang.title }}</h2>
    </div>

    <div class="surface-card shadow-2 p-3 border-round">
      <div class="pt-2 pb-1">
        <PvButton
          icon="pi pi-file"
          :label="lang.create"
          @click="gotoEdit('create')"
          class="mr-2 mb-2"
        />
        <PvButton
          icon="pi pi-list"
          :label="parameterButtonText"
          @click="toggleParameterVisible"
          class="p-button-help mr-2 mb-2"
        />
      </div>
      <transition>
        <section
          class="flex flex-wrap"
          :class="textFormClass"
          v-if="isParameterVisible"
        >
          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
            <label for="dateStart">{{ lang.dateStart }}</label>
            <PvCalendar
              id="dateStart"
              v-model="dateStart"
              class="inputfield w-full mt-1"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
            <label for="dateEnd">{{ lang.dateEnd }}</label>
            <PvCalendar
              id="dateEnd"
              v-model="dateEnd"
              class="inputfield w-full mt-1"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
            <label for="codeStart">{{ lang.codeStart }}</label>
            <PvInputText
              id="codeStart"
              type="text"
              class="inputfield w-full mt-1"
              placeholder="6501xxxxx"
              v-model="codeStart"
            />
          </div>
          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
            <label for="codeEnd">{{ lang.codeEnd }}</label>
            <PvInputText
              id="codeEnd"
              type="text"
              class="inputfield w-full mt-1"
              placeholder="6501xxxxx"
              v-model="codeEnd"
            />
          </div>
          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
            <label for="customerSearch">{{ lang.customerSearch }}</label>
            <PvInputText
              id="customerSearch"
              type="text"
              class="inputfield w-full mt-1"
              v-model="customerSearch"
            />
          </div>
          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
            <label for="selectedUnit">{{ lang.searchUnit }}</label>
            <base-unit-auto-complete
              id="selectedUnit"
              v-model="selectedUnit"
              class="inputfield w-full mt-1"
            />
          </div>
        </section>
      </transition>

      <div class="pt-2 pb-1">
        <PvButton
          icon="pi pi-search"
          :label="lang.search"
          @click="getPrepaids"
          class="mr-2 mb-2"
        />
      </div>

      <PvDataTable
        showGridlines
        :value="Prepaids"
        v-model:selection="selectedPrepaid"
        selectionMode="single"
        dataKey="id"
        responsiveLayout="scroll"
        :stripedRows="true"
        class="p-datatable-sm"
        :class="textTableClass"
        :loading="isLoading"
        @row-dblclick="gotoEdit(this.selectedPrepaid.id)"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn
          field="date"
          :header="lang.columnDate"
          style="min-width: 4rem"
          headerClass="h-center"
          bodyClass="text-center"
          :sortable="true"
        >
          <template #body="slotProps">
            {{ formatDate(slotProps.data.date) }}
          </template>
        </PvColumn>
        <PvColumn
          field="code"
          :header="lang.columnCode"
          style="min-width: 4rem"
          headerClass="h-center"
          :sortable="true"
        ></PvColumn>
        <PvColumn
          field="unitCode"
          :header="lang.columnUnit"
          style="min-width: 4rem"
          headerClass="h-center"
          :sortable="true"
        ></PvColumn>
        <PvColumn
          field="unitAddress"
          :header="lang.columnAddress"
          style="min-width: 4rem"
          headerClass="h-center"
          :sortable="true"
        ></PvColumn>
        <PvColumn
          field="customerName"
          :header="lang.columnCustomerName"
          style="min-width: 9rem"
          headerClass="h-center"
          :sortable="true"
        ></PvColumn>
        <PvColumn
          field="netAmount"
          :header="lang.columnPrice"
          style="min-width: 4rem"
          headerClass="h-center"
          bodyClass="text-right"
          :sortable="true"
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.netAmount) }}
          </template>
        </PvColumn>
        <PvColumn
          field="isPaid"
          :header="lang.columnIsPaid"
          style="min-width: 2rem"
          headerClass="h-center"
          bodyClass="text-center"
          :sortable="true"
        ></PvColumn>
        <PvColumn
          field="postStep"
          :header="lang.columnPostStep"
          style="min-width: 2rem"
          headerClass="h-center"
          bodyClass="text-center"
          :sortable="true"
        ></PvColumn>
        <PvColumn
          field="cancelStatus"
          :header="lang.columnIsCancel"
          style="min-width: 2rem"
          headerClass="h-center"
          :sortable="true"
        ></PvColumn>
        <PvColumn
          style="min-width: 4rem"
          headerClass="h-center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <PvButton
              type="button"
              class="p-button-secondary p-button-text py-0"
              icon="pi pi-pencil"
              @click="gotoEdit(slotProps.data.id)"
            ></PvButton>
            <PvButton
              type="button"
              class="p-button-secondary p-button-text py-0"
              icon="pi pi-print"
              @click="selectPrintForm(slotProps.data)"
            ></PvButton>
          </template>
        </PvColumn>
        <template #footer>
          <PvPaginator
            :rows="rowsPerPage"
            :totalRecords="totalRecords"
            @page="onPageChange($event)"
            :rowsPerPageOptions="[10, 50, 100]"
            :first="showCurrentPage"
          >
            <template #start="slotProps">
              {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /
              {{ totalPage }}
            </template>
          </PvPaginator>
        </template>
      </PvDataTable>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
export default {
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.ar.prepaidRefund };
      } else {
        return { ...lang.en.base, ...lang.en.ar.prepaidRefund };
      }
    });

    const isHideBack = inject('isHideBack');
    // console.log('isHideBack', isHideBack);

    const { getFormPrints, searchTextPrint, isSharedFormPrintReady, getPreviewBlob, filteredFormPrints } = useGetFormPrint();

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      // formatPrice,
      formatDate,
      formatDateToISOString,
      isHideBack,
      getFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints
    };
  },
  data() {
    return {
      isParameterVisible: true,
      isLoading: false,
      dateStart: null,
      dateEnd: null,
      codeStart: "",
      codeEnd: "",
      customerSearch: "",
      selectedUnit: "",
      selectedPrepaid: null,
      selectedPage: 1,
      rowsPerPage: 10,

      showFormPrintModal: false,
      path: "",
      dataPrint: [],
      categoryPrint: "",
    };
  },
  async created() {
    // const params = new Proxy(new URLSearchParams(window.location.search), {
    //   get: (searchParams, prop) => searchParams.get(prop),
    // });
    
    let date = new Date();
    this.dateStart = new Date();
    this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    await this.setLocalSearch();
    await this.getPrepaids();
  },
  methods: {
    goBack() {
      this.$router.push({name: "home"})
    },
    gotoEdit(PrepaidId) {
      // console.log("openPrepaids>>>", PrepaidId);
      this.$router.push({name: "ar-prepaid-refund-edit", params: {id: PrepaidId}});
      // this.$router.push("/ar/prepaid-refund-entry?Id=" + data.id);
    },
    toggleParameterVisible() {
      this.isParameterVisible = !this.isParameterVisible;
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getPrepaids();
    },
    async getPrepaids() {
      this.isLoading = true;

      let unitId = null;
      if (this.selectedUnit) {
        unitId = this.selectedUnit.id;
      }
      // console.log(this.selectedDateEnd);
      // console.log(this.codeStart);
      // console.log(this.codeEnd);
      let where = {
        projectId: this.selectedProject.id,
        dateStart: this.selectedDateStart,
        dateEnd: this.selectedDateEnd,
        codeStart: this.codeStart || null,
        codeEnd: this.codeEnd || null,
        customerSearch: this.customerSearch || null,
        unitId: unitId,
      };

      //console.log(JSON.stringify(where));

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };

      await this.$store.dispatch("arPrepaidRefund/getPrepaids", payload);
      this.isLoading = false;

      where = {
        ...where,
        selectedUnit: this.selectedUnit,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        rowsPerPage: this.rowsPerPage,
        selectedPage: this.selectedPage,
      };
      // console.log("getPrepaids_where=",where);

      localStorage.setItem(this.storageName,JSON.stringify(where));
    },
    async PrintPrepaid(data) {
      // this.setLoading(true);
      // console.log(data);
      const Prepaid = {
        id: data.id,
        projectId: this.selectedProject.id,
        unitCode: data.unitCode,
        path: this.path,
        category: this.categoryPrint,
      }; 
      try {
        await this.$store.dispatch("arPrepaidRefund/PrintPrepaid", Prepaid);
        // this.$swal({
        //   icon: "success",
        //   title: this.lang.downloadPDFSuccess,
        //   confirmButtonText: this.lang.close,
        //   allowOutsideClick: false,
        // });
      } catch (error) {
        // console.log("error=", error);
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        // this.setLoading(false);
      }
      let blob = this.$store.getters["arPrepaidRefund/blob"];
      this.getPreviewBlob(blob)

      // this.setLoading(false);
    },
    async selectPrintForm(data){
      await this.$store.dispatch("shared/resetFormPrint");
      this.dataPrint = data;
      let where = {
          projectId: this.selectedProject.id,
          documentBookCode: "RETURN",
        };
      const payload = { where };
      await this.getFormPrints(payload);
      // await this.$store.dispatch("shared/getFormPrints",payload);
      
      if(this.filteredFormPrints.length == 1) {
        this.path = this.filteredFormPrints[0].path;
        this.categoryPrint = this.filteredFormPrints[0].category;
        this.PrintPrepaid(this.dataPrint);
      }else{
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path;
      this.categoryPrint = event.category
      this.PrintPrepaid(this.dataPrint);
      this.showFormPrintModal = false;
    },
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      // console.log("created_localSearch=",localSearch);
      if (localSearch) {
        let search = JSON.parse(localSearch);

        if (search.PrepaidEntry === "Entry") {

          // console.log("search=", search.dateStart);
          if (search.dateStart != null && search.dateStart != "") {
            this.dateStart = new Date(search.dateStart);
          } else this.dateStart = "";

          if (search.dateEnd != null && search.dateEnd != "") {
            this.dateEnd = new Date(search.dateEnd);
          } else this.dateEnd = "";

          this.codeStart = search.codeStart || this.codeStart;
          this.codeEnd = search.codeEnd || this.codeEnd;
          this.customerSearch = search.customerSearch || this.customerSearch;
          this.selectedUnit = search.selectedUnit || this.selectedUnit;
          this.rowsPerPage = search.rowsPerPage;
          this.selectedPage = search.selectedPage;

          search.PrepaidEntry = ""

          localStorage.setItem(this.storageName,JSON.stringify(search));
        }
      }
    },
  },
  computed: {
    parameterButtonText() {
      return this.isParameterVisible
        ? this.lang.hideParameters
        : this.lang.showParameters;
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    Prepaids() {
      return this.$store.getters["arPrepaidRefund/prepaids"];
    },

    totalPage() {
      return this.$store.getters["arPrepaidRefund/totalPage"];
    },
    currentPage() {
      return this.$store.getters["arPrepaidRefund/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    storageName() {
      return `erp_search_PrepaidRefundList_${this.selectedProject.code}_${this.userCode}`;
    },
    showCurrentPage() {
      let page = 0;
      page = this.rowsPerPage * (this.selectedPage - 1);
      return page;
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   console.log("to=",to);
  //   console.log("from=",from);
  //   console.log("next=",next);
  //   next(vm => {
  //     vm.prevRoute = from
  //   })
  // },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
