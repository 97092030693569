export default {
  creditNotes(state) {
    return state.creditNotes;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
  selectedCreditNote(state) {
    return state.selectedCreditNote;
  },
  creditNoteReferenceDocument(state){
    return state.creditNoteReferenceDocument;
  },
  blob(state) {
    return state.blob;
  },
};
