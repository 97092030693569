export default {
    units(state) {
        return state.units;
    },
    receipts(state) {
        return state.receipts;
    },
    invoices(state) {
        return state.invoices;
    },
    receiptTotalPage(state) {
        return state.receiptTotalPage;
    },
    receiptCurrentPage(state) {
        return state.receiptCurrentPage;
    },
    receiptNextPage(state) {
        return state.receiptCurrentPage + 1;
    },
    receiptIsLastPage(_, getters) {
        return getters.receiptCurrentPage === getters.receiptTotalPage && getters.receiptTotalPage !== 0;
    },
    billPayments(state) {
      return state.billPayments;
    },
}