export default {
	withholdingTaxs(state) {
		return state.withholdingTaxs;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	selectedWithholdingTax(state) {
		return state.selectedWithholdingTax;
	},
	blob(state) {
		return state.blob;
	},
	withholdingTaxsForImport(state) {
		return state.withholdingTaxsForImport;
	},
	withholdingTaxsImport(state) {
		return state.withholdingTaxsImport;
	},
	totalPageImport(state) {
		return state.totalPageImport;
	},
	currentPageImport(state) {
		return state.currentPageImport;
	},
	withholdingTaxsForImportNextPage(state) {
		return state.currentPageImport + 1;
	},
	withholdingTaxsForImportIsLastPage(_, getters) {
		if (getters.currentPageImport > 0 && getters.totalPageImport === 0) {
			return true;
		} else {
			return getters.currentPageImport === getters.totalPageImport && getters.totalPageImport !== 0;
		}
	},
	selectedWithholdingTaxWithVoucher(state) {
		return state.selectedWithholdingTaxWithVoucher;
	},
};
