export default {
	units(state) {
		return state.units;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	selectedUnit(state) {
		return state.selectedUnit;
	},
	unitNextPage(state) {
		return state.currentPage + 1;
	},
	unitIsLastPage(_, getters) {
		return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
	},
};
