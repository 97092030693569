<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoSave="save"
    @gotoCreate="askForCreate"
    @gotoCancel="askForCancel"
    @gotoPrint="getPrintForm()"
  >
  </base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="titleConfirm"
      :classTitle="baseDialogClassTitle"
      :wordingConfirm="validateDialogWording"
      :dataButtons="dataButtonDialog"
      @close="closeBaseDialog"
      @confirm="closeBaseDialog"
      @confirm-create="createNewDocument"
      @confirm-cancel="cancel"
    ></base-dialog>

    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchTextPrint"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>

    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{ lang.title }}</label>

      <PvDivider></PvDivider>

      <section class="flex flex-wrap fontlabel" :class="textFormClass">
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="code">{{ lang.depositCode }}</label>
          <PvInputText
            id="code"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="code"
            disabled
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="date">{{ lang.depositDate }}</label>
          <PvCalendar
            id="date"
            v-model="date"
            class="inputfield w-full mt-1"
            inputClass="p-inputtext-disabled"
            showIcon
            dateFormat="dd/mm/yy"
            disabled
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="unitCode">{{ lang.unitCode + "*" }}</label>
          <PvSkeleton
            v-if="!isSharedUnitReady"
            class="inputfield w-full mt-1"
            height="39px"
          />
          <base-unit-auto-complete
            id="unit"
            v-model="selectedUnit"
            class="inputfield w-full mt-1 mr-2"
            :inputClass="{ 'p-inputtext-disabled': isRefer }"
            @load-finish="unitDataReady"
            @item-select="selectUnit()"
            v-show="isSharedUnitReady"
            :disabled="isRefer"
            :class="{ 'p-invalid': unitError }"
            :isValidateActiveStatus="true"
            @is-inactive="initiateData"
          />
          <small v-if="unitError" id="unit-help" class="p-error">{{
            lang.validateSelectUnit
          }}</small>
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="customerName">{{ lang.customerName }}</label>
          <PvInputText
            id="customerName"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="customerName"
            disabled
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="depositAmount">{{ lang.depositAmount }}*</label>
          <PvInputNumber
            id="depositAmount"
            class="inputfield w-full mt-1"
            :inputClass="{
              'text-right': true,
              'p-inputtext-disabled': isRefer,
            }"
            v-model="depositAmount"
            mode="decimal"
            :min="0"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            :class="{ 'p-invalid': depositAmountError }"
            @focus="$event.target.select()"
            @focusout="validateDepositAmount"
            :disabled="isRefer"
            @keydown="depositAmountError = false"
          />
          <small
            v-if="depositAmountError"
            id="deposit-amount-help"
            class="p-error"
          >
            {{ lang.validateDepositAmount }}
          </small>
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="type">{{ lang.depositType }}*</label>
          <PvDropdown
            id="type"
            class="inputfield w-full mt-1 mr-2"
            :inputClass="{ 'p-inputtext-disabled': isRefer }"
            :options="type"
            optionLabel="name"
            optionValue="code"
            v-model="selectedDepositType"
            scrollHeight="300px"
            @change="checkDepositType"
            :disabled="isRefer"
          />
          <!-- <div class="flex flex-wrap">
            <div
              v-for="depositType of type"
              :key="depositType.code"
              class="field-radiobutton mt-3 mr-6 ml-2"
            >
              <PvRadioButton
                class="mr-2"
                :inputId="depositType.code"
                name="depositType"
                :value="depositType.code"
                v-model="selectedDepositType"
                @change="checkDepositType()"
              />
              <label :for="depositType.code">{{ depositType.name }}</label>
            </div>
          </div> -->
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="bookBankCode">{{ lang.bookBankCode }}*</label>
          <div>
            <PvSkeleton
              v-if="!isSharedBookBankReady"
              class="inputfield w-full mt-1"
              height="39px"
            />
            <base-book-bank-auto-complete
              id="bookBank"
              v-model="selectedBookbank"
              class="inputfield w-full mt-1 mr-2"
              :inputClass="{ 'p-inputtext-disabled': isRefer }"
              @load-finish="bookBankDataReady"
              v-show="isSharedBookBankReady"
              :disabled="isRefer"
              @item-select="setDataBank"
              :class="{ 'p-invalid': bookBankError }"
            />
            <small v-if="bookBankError" id="book-bank-help" class="p-error">{{
              lang.validateSelectBookBank
            }}</small>
          </div>
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="transferDate">{{ dateTypeLabel }}</label>
          <PvCalendar
            id="transferDate"
            v-model="transferDate"
            class="inputfield w-full mt-1"
            :inputClass="{ 'p-inputtext-disabled': isRefer || isPayTypeCash }"
            showIcon
            dateFormat="dd/mm/yy"
            :disabled="isRefer || isPayTypeCash"
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="bank">{{ lang.bank }}</label>
          <PvInputText
            id="bank"
            type="text"
            class="inputfield w-full mt-1"
            :class="{ 'p-inputtext-disabled': isCheckDisable }"
            v-model="bank"
            :disabled="isCheckDisable"
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="transferCode">{{ payCodeLabel }}</label>
          <PvInputText
            id="transferCode"
            type="text"
            class="inputfield w-full mt-1"
            :class="{ 'p-inputtext-disabled': isRefer || isPayTypeCash }"
            v-model="transferCode"
            :disabled="isRefer || isPayTypeCash"
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="remark">{{ lang.remark }}</label>
          <PvInputText
            id="remark"
            type="text"
            class="inputfield w-full mt-1"
            :class="{ 'p-inputtext-disabled': isRefer }"
            v-model="remark"
            :disabled="isRefer"
          />
        </div>
      </section>

      <div class="font-lg font-normal pt-4">
        <label for="referenceDocument">{{ lang.referenceDocument }}</label>
        <PvDivider></PvDivider>
        <PvDataTable
          :value="dataReferenceDocument"
          dataKey="id"
          responsiveLayout="scroll"
          :class="textTableClass"
          class="pt-2"
          selectionMode="single"
        >
          <PvColumn
            field="code"
            :header="lang.receiptCode"
            style="min-width: 7rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="date"
            :header="lang.receiptDate"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.date) }}
            </template>
          </PvColumn>
          <PvColumn
            field="netAmount"
            :header="lang.payAmount"
            style="min-width: 10rem"
            headerClass="h-right"
            bodyClass="text-right"
          >
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.netAmount) }}
            </template>
          </PvColumn>
          <PvColumn
            field="createDate"
            :header="lang.columnCreateDate"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.createDate) }}
            </template>
          </PvColumn>
          <PvColumn
            field="updateDate"
            :header="lang.columnUpdateDate"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <label v-if="slotProps.data.updateDate">
                {{ formatDate(slotProps.data.updateDate) }}
              </label>
            </template>
          </PvColumn>
          <PvColumn
            field="createBy"
            :header="lang.columnCreateBy"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="updateBy"
            :header="lang.columnUpdateBy"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <template #empty>
            <div class="w-full" style="text-align: center">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import usePageDirty from "../../../hooks/pageDirty";
import BaseBookBankAutoComplete from "../../../components/bank/BaseBookBankAutoComplete.vue";
export default {
  props: ["id", "projectCode"],
  components: {
    BaseBookBankAutoComplete,
  },
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.deposit.edit,
          ...lang.th.ar.deposit.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.deposit.edit,
          ...lang.en.ar.deposit.listing,
        };
      }
    });

    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();
    const {
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
    } = useGetFormPrint();

    const { compareObject, isCompareObject } = usePageDirty();

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,

      textTableClass,
      textFormClass,

      formatCurrency,
      formatDate,
      formatDateToISOString,
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
      isHideBack,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      compareObject,
      isCompareObject,
    };
  },
  data() {
    return {
      code: "",
      date: null,
      selectedUnit: null,
      isSharedUnitReady: false,
      customerName: "",
      depositAmount: 0,
      type: [
        { name: this.lang.cash, code: "CAD" },
        { name: this.lang.cheque, code: "CQD" },
        { name: this.lang.transfer, code: "TRD" },
      ],
      selectedDepositType: "CAD",
      isSharedBookBankReady: false,
      selectedBookbank: null,
      bookBankCode: "",
      transferDate: null,
      bank: "",
      transferCode: "",
      remark: "",

      showValidateDialog: false,
      titleConfirm: "",
      validateDialogWording: "",
      baseDialogClassTitle: "",
      isDisableCash: false,
      objToCompare: null,
      isCreateNewDocument: false,
      depositAmountError: false,
      isRefer: false,

      showFormPrintModal: false,
      path: "",
      isConfirmCancel: false,

      unitError: false,
      bookBankError: false,
    };
  },
  async created() {
    await this.$store.dispatch("bank/setTypeInOut", { type: 1 }); // 1="PayIn" ,-1= "PayOut"
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    if (!this.isCreateDocument) {
      await this.getDepositById();
      await this.getReference();
    }
    await this.checkShouldSetData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.setLocalSearch();
      this.$router.push({name: "ar-deposit"});
    },
    setLocalSearch() {
      let storageName = `erp_search_DepositList_${this.selectedProject.code}_${this.userCode}`;
      const localSearch = localStorage.getItem(storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      localStorage.setItem(storageName, JSON.stringify(search));
    },
    async initiateData() {
      // await this.$store.dispatch("bank/setTypeInOut", { type: 1 }); // 1="PayIn" ,-1= "PayOut"
      await this.$store.dispatch("arDeposit/resetSelectedDeposit");
      await this.$store.dispatch("arDeposit/resetDepositReferenceDocument");
      await this.checkDepositType();
      this.code = "";
      this.date = new Date();
      this.selectedUnit = null;
      this.customerName = "";
      this.depositAmount = 0;
      this.selectedDepositType = "CAD";
      this.selectedBookbank = null;
      this.bookBankCode = "", 
      this.transferDate = new Date();
      this.bank = "";
      this.transferCode = "";
      this.remark = "";
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.validateDialogWording = "";
      this.isDisableCash = false;
      this.isCreateNewDocument = false;
      this.depositAmountError = false;
      this.isRefer = false;
      this.showFormPrintModal = false;
      this.path = "";
      this.isConfirmCancel = false;
    },
    // async roleByMenuDataReady() {
    //   if (this.isSharedRoleByMenuReady) {
    //     if (!this.isCreateDocument) {
    //       await this.getDepositById();
    //       await this.getReference();
    //     }
    //   }
    // },
    unitDataReady() {
      this.isSharedUnitReady = true;
      this.checkShouldSetData();
    },
    bookBankDataReady() {
      this.isSharedBookBankReady = true;
      this.checkShouldSetData();
    },
    async checkShouldSetData() {
      if (this.isSharedRoleByMenuReady) {
        if (
          this.selectedDeposit &&
          !this.isSharedUnitReady &&
          !this.isSharedBookBankReady
        ) {
          await this.setData();
        } else if (
          this.selectedDeposit &&
          this.isSharedUnitReady &&
          this.isSharedBookBankReady
        ) {
          // console.log("111");
          await this.setData();
          await this.setDataBookBank();
          await this.setDataUnit();
        } else if (
          !this.isSharedUnitReady &&
          this.selectedDeposit &&
          this.isSharedBookBankReady
        ) {
          // console.log("222");
          await this.setData();
          await this.setDataBookBank();
        } else if (
          !this.isSharedBookBankReady &&
          this.selectedDeposit &&
          this.isSharedUnitReady
        ) {
          // console.log("333");
          await this.setData();
          await this.setDataUnit();
        } else if (
          this.isCreateDocument &&
          this.isSharedUnitReady &&
          this.isSharedBookBankReady
        ) {
          // console.log("444");
          this.setLoading(false);
          this.isRefer = false;
        }
      }
      await this.setCompareData();

      // if (
      //   this.selectedDeposit &&
      //   this.isSharedUnitReady &&
      //   this.isSharedBookBankReady
      // ) {
      //   console.log("111");
      //   await this.setData();
      //   await this.setDataBookBank();
      //   await this.setDataUnit();
      // } else if (
      //   this.selectedDeposit &&
      //   !this.isSharedUnitReady &&
      //   this.isSharedBookBankReady
      // ) {
      //   console.log("222");
      //   await this.setData();
      //   await this.setDataBookBank();
      // } else if (
      //   !this.selectedDeposit &&
      //   this.isSharedUnitReady &&
      //   this.isSharedBookBankReady
      // ) {
      //   this.isRefer = false;
      //   // this.setLoading(false);
      // }
      // this.setLoading(false);
    },
    async getDepositById() {
      try {
        let id = this.id;
        await this.$store.dispatch("arDeposit/getDepositById", id);
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateDialogWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    async getReference() {
      try {
        const payload = {
          page: 1,
          id: this.id,
        };
        await this.$store.dispatch("arDeposit/getReferenceDocument", payload);

        // console.log(this.dataReferenceDocument);

        if (this.dataReferenceDocument.length > 0) {
          this.isRefer = true;
        }
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateDialogWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    async setData() {
      // console.log("setData", this.selectedDeposit);
      this.code = this.selectedDeposit.code;
      this.date = new Date(this.selectedDeposit.date);
      this.depositAmount = this.selectedDeposit.payAmount;
      this.selectedDepositType = this.selectedDeposit.paymentMethodCode.trim();
      this.transferDate = new Date(this.selectedDeposit.transferDate);
      this.bank = this.selectedDeposit.docBankBranchName;
      this.transferCode = this.selectedDeposit.depositor;
      this.remark = this.selectedDeposit.remark;
      this.setLoading(false);
    },
    async setDataUnit() {
      this.selectedUnit = this.sharedUnits.find(
        (unit) => unit.id === this.selectedDeposit.unitId
      );
      this.customerName = this.selectedUnit.customerName;
    },
    async setDataBookBank() {
      this.selectedBookbank = this.sharedBookBanks.find(
        (bookbank) => bookbank.id === this.selectedDeposit.bookBankId
      );
    },
    async setCompareData() {
      let objToCompare = {
        projectId: this.selectedProject.id,
        date: this.date ? this.formatDateToISOString(this.date) : null,
        paymentMethodCode: this.selectedDepositType,
        unitId: this.selectedUnit ? this.selectedUnit.id : "",
        customerId: this.selectedUnit ? this.selectedUnit.customerId : "",
        bookBankId: this.selectedBookbank ? this.selectedBookbank.id : "",
        docBankBranchName: this.bank,
        bankId: "",
        payAmount: this.depositAmount,
        transferDate: this.transferDate
          ? this.formatDateToISOString(this.transferDate)
          : null,
        depositor: this.transferCode,
        remark: this.remark,
        type: "Add",
      };

      if (!this.isCreateDocument) {
        objToCompare = { ...objToCompare, id: this.id, type: "Update" };
      }

      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
    },
    async askForCreate() {
      if(!this.isRoleCreate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateDialogWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return;
      }
      let result = this.isCompareObject(this.objToCompare, this.payload);
      // console.log("result", result);
      // console.log("this.objToCompare", this.objToCompare);
      // console.log("this.payload", this.payload);
      this.clearDataBaseDialog();
      this.isCreateNewDocument = true;
      if (result) {
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateDialogWording = this.lang.askForCreateNewDeposit;
        this.showValidateDialog = true;
      } else {
        await this.createNewDocument();
      }
    },
    async createNewDocument() {
      this.showValidateDialog = false;
      this.setLoading(true);
      await this.initiateData();
      await this.$router.replace({name: "ar-deposit-edit", params: {id: "create"}});
      if (this.isSharedUnitReady) {
        this.setLoading(false);
      }
      await this.setCompareData();
    },
    validateDepositAmount() {
      if (this.depositAmount < 0) {
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateDialogWording = this.lang.validateDepositAmount;
        this.showValidateDialog = true;
        this.depositAmountError = true;
      } else {
        this.depositAmountError = false;
      }
    },
    askForCancel() {
      if (this.validateDocument()) {
        return;
      }
      if(!this.isRoleCancel) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateDialogWording = this.lang.validateRoleCancel;
        this.showValidateDialog = true;
        return;
      }
      this.clearDataBaseDialog();
      this.isConfirmCancel = true;
      this.titleConfirm = this.lang.cancelDepositTitle;
      this.validateDialogWording = this.lang.askForcancelDeposit;
      this.showValidateDialog = true;
    },
    async cancel() {
      this.setLoading(true);
      try {
        let payload = {
          id: this.id,
        };

        await this.$store.dispatch("arDeposit/cancelDeposit", payload);

        this.showCancelDialog = false;
        this.clearDataBaseDialog();
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.cancelSuccess;
        this.validateDialogWording = this.lang.cancelDepositSuccess.replace(
          ":code",
          this.code.trim()
        );
        this.showValidateDialog = true;
      } catch (error) {
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateDialogWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    validateRole() {
      if(this.isCreateDocument && !this.isRoleCreate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateDialogWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return true;
      } else if(!this.isCreateDocument && !this.isRoleUpdate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateDialogWording = this.lang.validateRoleUpdate;
        this.showValidateDialog = true;
        return true;
      }
      return false;
    },
    validateDocument() {
      if(this.selectedDeposit && this.selectedDeposit.cancelStatus.trim() == "C") {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateDialogWording = this.lang.validateCancelled;
        this.showValidateDialog = true;
        return true;
      } else if(this.isRefer) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateDialogWording = this.lang.validateReferenceDocument;
        this.showValidateDialog = true;
        return true;
      }
      return false;
    },
    wordingValidate() {
      if(!this.selectedUnit) {
        return this.lang.validateDropDownNoSelect + this.lang.unitCode;
      }

      if (this.depositAmount <= 0) {
        return this.lang.validateDepositAmount;
      }

      if(!this.selectedBookbank) {
        return this.lang.validateDropDownNoSelect + this.lang.bookBankCode;
      }
    },
    validateData() {
      let returnStatus = false;

      if(!this.selectedUnit) {
        this.unitError = true;
        returnStatus = true;
      }

      if (this.depositAmount <= 0) {
        // this.titleConfirm = this.lang.notificationDialogHeader;
        // this.validateDialogWording = this.lang.validateDepositAmount;
        // this.showValidateDialog = true;
        // return true;
        this.depositAmountError = true;
        returnStatus = true;
      }

      if(!this.selectedBookbank) {
        this.bookBankError = true;
        returnStatus = true;
      }

      if(returnStatus) {
        this.baseDialogClassTitle = "p-error";
        this.titleConfirm = this.lang.validateSaveTitle;
        this.validateDialogWording = this.wordingValidate();
        this.showValidateDialog = true;
      }

      return returnStatus;
    },
    async save() {
      if (this.validateRole()) {
        return;
      }
      if (this.validateDocument()) {
        return;
      }
      if (this.validateData()) {
        return;
      }
      this.setLoading(true);
      try {
        // console.log("save", this.payload);
        await this.$store.dispatch(
          "arDeposit/createOrUpdateDeposit",
          this.payload
        );

        this.clearDataBaseDialog();
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.saveSuccess;
        this.validateDialogWording = this.isCreateDocument
          ? this.lang.saveDepositSuccess
          : this.lang.editDepositSuccess.replace(":code", this.code.trim());
        this.showValidateDialog = true;
      } catch (error) {
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateDialogWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);

      if (this.isCreateDocument) {
        await this.$router.replace({name: "ar-deposit-edit", params: {id: this.selectedDeposit.id}});
        await this.setCompareData();
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path + ".pdf";
      this.printDeposit();
      this.showFormPrintModal = false;
    },
    async getPrintForm() {
      if(!this.isRolePrint) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateDialogWording = this.lang.validatePrintPermission;
        this.showValidateDialog = true;
        return;
      }
      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: "DEPOSIT",
      };
      const payload = { where };
      await this.getFormPrints(payload);
      if (this.filteredFormPrints.length == 1) {
        this.formPrintModalSelected(this.filteredFormPrints[0]);
      } else {
        this.showFormPrintModal = true;
      }
    },
    async printDeposit() {
      this.setLoading(true);
      try {
        let parameter = {
          Id: this.id,
        };

        let payload = {
          path: this.path,
          fileName: "Deposit",
          parameter: JSON.stringify(parameter),
        };

        await this.$store.dispatch("shared/printForm", payload);
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateDialogWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }

      let blob = this.$store.getters["shared/blob"];
      this.getPreviewBlob(blob);
      this.setLoading(false);
    },
    closeBaseDialog() {
      this.showValidateDialog = false;
      this.clearDataBaseDialog();
    },
    clearDataBaseDialog() {
      this.baseDialogClassTitle = "";
      this.titleConfirm = "";
      this.validateDialogWording = "";
      this.isCreateNewDocument = false;
      this.isConfirmCancel = false;
    },
    async checkDepositType() {
      this.selectedBookbank = null;
      this.bank = "";
      this.bookBankCode = "";
      if (this.selectedDepositType == "CAD") {
        this.isDisableCash = true;
      } else {
        this.isDisableCash = false;
      }
    },
    selectUnit() {
      this.customerName = this.selectedUnit.customerName;
      this.unitError = false;
    },
    setDataBank() {
      if (this.isPayTypeTransfer) {
        this.bank = this.selectedBookbank.branchName;
      }
      this.bookBankError = false;
    },
  },
  computed: {
    payload() {
      let payload = {
        projectId: this.selectedProject.id,
        date: this.date ? this.formatDateToISOString(this.date) : null,
        paymentMethodCode: this.selectedDepositType,
        unitId: this.selectedUnit ? this.selectedUnit.id : "",
        customerId: this.selectedUnit ? this.selectedUnit.customerId : "",
        bookBankId: this.selectedBookbank ? this.selectedBookbank.id : "",
        docBankBranchName: this.bank,
        bankId: "",
        payAmount: this.depositAmount,
        transferDate: this.transferDate
          ? this.formatDateToISOString(this.transferDate)
          : null,
        depositor: this.transferCode,
        remark: this.remark,
        type: "Add",
      };

      if (!this.isCreateDocument) {
        payload = { ...payload, id: this.id, type: "Update" };
      }

      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("PayIn");
    },
    isRoleCreate() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRoleUpdate() {
      if (this.roleByMenu && this.roleByMenu.update == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRolePrint() {
      if (this.roleByMenu && this.roleByMenu.print == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRoleCancel() {
      if (this.roleByMenu && this.roleByMenu.cancel == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isCreateDocument() {
      return this.id === "create";
    },
    sharedUnits() {
      return this.$store.getters["shared/unitUnits"];
    },
    sharedBookBanks() {
      return this.$store.getters["bookBank/bookBanks"];
    },
    title() {
      let result = this.lang.createDeposit;
      if (!this.isCreateDocument) {
        result = this.lang.editDeposit;
      }
      return result;
    },
    dateTypeLabel() {
      let result = this.lang.payDate;
      if (this.selectedDepositType == "CQD") {
        result = this.lang.chequeDate;
      } else {
        result = this.lang.transferDate;
      }
      return result;
    },
    payCodeLabel() {
      let result = this.lang.payCode;
      if (this.selectedDepositType == "CQD") {
        result = this.lang.chequeCode;
      } else {
        result = this.lang.transferCode;
      }
      return result;
    },
    isDisableSave() {
      if (
        !this.isRoleCreate ||
        !this.selectedUnit ||
        !this.selectedBookbank ||
        (this.selectedDeposit &&
          this.selectedDeposit.cancelStatus.trim() == "C") ||
        this.isRefer
      ) {
        return true;
      }
      if (!this.isCreateDocument) {
        if (!this.isRoleUpdate) {
          return true;
        }
      }
      return false;
    },
    isDisableCancel() {
      if (
        !this.isRoleCancel ||
        this.isCreateDocument ||
        !this.selectedUnit ||
        (this.selectedDeposit &&
          this.selectedDeposit.cancelStatus.trim() == "C") ||
        this.isRefer
      ) {
        return true;
      }
      return false;
    },
    isDisablePrint() {
      if (!this.isRolePrint || this.isCreateDocument || !this.selectedUnit) {
        return true;
      }
      return false;
    },
    isDisableCreate() {
      if (!this.isRoleCreate) {
        return true;
      }
      return false;
    },
    selectedDeposit() {
      return this.$store.getters["arDeposit/selectedDeposit"];
    },
    dataReferenceDocument() {
      return this.$store.getters["arDeposit/depositReferenceDocument"];
    },
    isPayTypeCash() {
      if (this.selectedDepositType == "CAD") {
        return true;
      }
      return false;
    },
    isPayTypeCheque() {
      if (this.selectedDepositType == "CQD") {
        return true;
      }
      return false;
    },
    isPayTypeTransfer() {
      if (this.selectedDepositType == "TRD") {
        return true;
      }
      return false;
    },
    isCheckDisable() {
      if (this.isRefer || this.isPayTypeCash || this.isPayTypeTransfer) {
        return true;
      }
      return false;
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "cancel",
          action: "gotoCancel",
          isDisable: this.isCreateDocument || !this.isSharedDataReady, //this.isDisableCancel,
          type:"cancel"
        },
        {
          id: "create",
          action: "gotoCreate",
          isDisable: !this.isSharedDataReady, //this.isDisableCreate,
          type:"create"
        },
        {
          id: "print",
          action: "gotoPrint",
          isDisable: !this.isSharedDataReady, //this.isDisablePrint,
          type:"print"
        },
        {
          id: "save",
          action: "gotoSave",
          isShow: true,
          isDisable: !this.isSharedDataReady, //this.isDisableSave,
          type:"save"
        },
      ];
      return dataButtons;
    },
    dataButtonDialog() {
      let dataButtonDialog = [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];

      if (this.isCreateNewDocument) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "create",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "confirm-create",
          },
        ];
      }

      if (this.isConfirmCancel) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "cancel",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "confirm-cancel",
          },
        ];
      }

      return dataButtonDialog;
    },
    isSharedDataReady() {
      if (this.isSharedUnitReady && this.isSharedBookBankReady) {
        return true;
      }
      return false;
    }
  },
  watch: {
    selectedDeposit() {
      this.checkShouldSetData();
    },
    isSharedRoleByMenuReady() {
      this.checkShouldSetData();
    },
    payload(curValue) {
      if (this.objToCompare) {
        this.compareObject(this.objToCompare, curValue);
      }
    },
  },
};
</script>
