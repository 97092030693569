import { createRouter, createWebHistory } from "vue-router";
// import HomeView from '../views/HomeView.vue'
import LoginView from "../pages/auth/LoginView.vue";
// import HomeView from "../pages/HomeView.vue";
import NotFound from "../pages/NotFound.vue";
import Setting from "../pages/SettingView.vue";
import SelectProjectView from "../pages/v2/SelectProjectView.vue";
import ArInvoiceView from "../pages/AR/Invoice/InvoiceView.vue";
import ArInvoiceEditView from "../pages/AR/Invoice/EditInvoiceView.vue";
import InvoiceNotification from "../pages/AR/Invoice/InvoiceNotification.vue";
import AdminFormTypeView from "../pages/admin/Form/FormTypeView.vue";
import AdminFormDetailView from "../pages/admin/Form/FormDetailView.vue";
import ArPrepaidRefund from "../pages/AR/PrepaidRefund/PrepaidRefundView.vue";
import ArPrepaidRefundEdit from "../pages/AR/PrepaidRefund/EditPrepaidRefundView.vue";
import GlAccountingView from "../pages/GL/Accounting/AccountingView.vue";
import GlAccountingEditView from "../pages/GL/Accounting/EditAccountingView.vue";
import RevertAccountingView from "../pages/GL/Accounting/RevertAccountingView.vue";
import PettyCashListView from "../pages/GL/Accounting/PettyCashListingView.vue";
import ApWithholdingTaxView from "../pages/AP/WithholdingTax/WithholdingTaxView.vue";
import ApWithholdingTaxEditView from "../pages/AP/WithholdingTax/EditWithholdingTaxView.vue";
import ArBank from "../pages/AR/Bank/BankArView.vue";
import EditBankArView from "../pages/AR/Bank/EditBankArView.vue";
import ApBank from "../pages/AP/Bank/BankApView.vue";
import EditBankApView from "../pages/AP/Bank/EditBankApView.vue";
import ReportListingView from "../pages/Report/ReportListingView.vue";
import PurchaseInvoiceView from "../pages/AP/PurchaseInvoice/PurchaseInvoiceView.vue";
import EditPurchaseInvoiceView from "../pages/AP/PurchaseInvoice/EditPurchaseInvoiceView.vue";
import PurchaseReceiptView from "../pages/AP/PurchaseReceipt/PurchaseReceiptView.vue";
import EditPurchaseReceiptView from "../pages/AP/PurchaseReceipt/EditPurchaseReceiptView.vue";
import PurchaseRequisitionView from "../pages/AP/PurchaseRequisition/PurchaseRequisitionView.vue";
import EditPurchaseRequisitionView from "../pages/AP/PurchaseRequisition/EditPurchaseRequisitionView.vue";
import PurchaseOrderView from "../pages/AP/PurchaseOrder/PurchaseOrderView.vue";
import EditPurchaseOrderView from "../pages/AP/PurchaseOrder/EditPurchaseOrderView.vue";
import EditProjectView from "../pages/master/project/EditProjectView.vue";
import ProjectView from "../pages/master/project/ProjectView.vue";
import AssetRegisterView from "../pages/ASSET/AssetRegister/AssetRegisterView.vue";
import EditAssetRegisterView from "../pages/ASSET/AssetRegister/EditAssetRegisterView.vue";
import ProjectDocumentView from "../pages/master/projectDocument/ProjectDocumentView.vue";
import UnitView from "../pages/master/unit/UnitView.vue";
import EditUnitView from "../pages/master/unit/EditUnitView.vue";
import AdvertisementView from "../pages/master/product/AdvertisementView.vue";
import EditAdvertisementView from "../pages/master/product/EditAdvertisementView.vue";
import EditPenaltyCalculationView from "../pages/master/product/EditPenaltyCalculationView.vue";
import EditPenaltyProductView from "../pages/master/product/EditPenaltyProductView.vue";
import MeterView from "../pages/master/meter/MeterView.vue";
import EditMeterView from "../pages/master/meter/EditMeterView.vue";
import MeterAutoCreateView from "../pages/master/meter/MeterAutoCreateView.vue";
import MeterUsageSettingView from "../pages/master/meter/MeterUsageSettingView.vue";
import ResidentView from "../pages/master/resident/ResidentView.vue";
import EditResidentView from "../pages/master/resident/EditResidentView.vue";
import EditUnitProductSetupForInvoice from "../pages/master/unit/EditUnitProductSetupForInvoice.vue";
import EditArProductView from "../pages/master/product/EditArProductView.vue";
import ArProductView from "../pages/master/product/ArProductView.vue";
import BookBankView from "../pages/master/bookBank/BookBankView.vue";
import EditBookBankView from "../pages/master/bookBank/EditBookBankView.vue";
import FormVersionView from "../pages/admin/FormVersion/FormVersionView.vue";
import EditMeterUsageView from "../pages/meter/meterUsage/EditMeterUsageView.vue";
import ContactUsView from "../pages/ContactUsView.vue";
import ReportMenuView from "../pages/admin/Report/ReportMenuView.vue";
import ReportMenuDetailView from "../pages/admin/Report/ReportMenuDetailView.vue";
import AccountBookView from "../pages/master/accountBook/AccountBookView.vue";
import EditAccountBookView from "../pages/master/accountBook/EditAccountBookView.vue";
import RequestListingView from "../pages/Report/RequestListingView.vue";
import ArReceiptView from "../pages/AR/Receipt/ReceiptView.vue";
import ArReceiptEditView from "../pages/AR/Receipt/EditReceiptView.vue";
import ArImportReceiptView from "../pages/AR/Receipt/ImportReceiptView.vue";
import ArNoticeView from "../pages/AR/Notice/NoticeView.vue";
import ArEditNoticeView from "../pages/AR/Notice/EditNoticeView.vue";
import ArOutstandingLetter from "../pages/AR/Invoice/OutstandingLetter.vue";
import VisitorControlView from "../pages/AR/VisitorControl/VisitorControlView.vue";
import EditVisitorControlView from "../pages/AR/VisitorControl/EditVisitorControlView.vue";
import ArCalculateInvoiceView from "../pages/AR/CalculateInvoice/CalculateInvoiceView.vue";
import ArInvoiceWaitForApproveView from "../pages/AR/CalculateInvoice/InvoiceWaitForApproveView.vue";
import ArEditInvoiceWaitForApproveView from "../pages/AR/CalculateInvoice/EditInvoiceWaitForApproveView.vue";
import ArUsePrepaidAndDepositView from "../pages/AR/CalculateInvoice/UsePrepaidAndDepositView.vue";
import ArPreviewUsePrepaidAndDepositView from "../pages/AR/CalculateInvoice/PreviewUsePrepaidAndDepositView.vue";
import ArCalculateNoticeView from "../pages/AR/CalculateNotice/CalculateNoticeView.vue";
import ArNoticeWaitForApproveView from "../pages/AR/CalculateNotice/NoticeWaitForApproveView.vue";
import ArEditNoticeWaitForApproveView from "../pages/AR/CalculateNotice/EditNoticeWaitForApproveView.vue";
import PolicyView from "../pages/master/policy/PolicyView.vue";
import EditPolicyView from "../pages/master/policy/EditPolicyView.vue";
import ArDepositView from "../pages/AR/Deposit/DepositView.vue";
import ArEditDepositView from "../pages/AR/Deposit/EditDepositView.vue";
import ArCreditNoteView from "../pages/AR/CreditNote/CreditNoteView.vue";
import ArEditCreditNoteView from "../pages/AR/CreditNote/EditCreditNoteView.vue";
import CustomerGroupView from "../pages/master/customerGroup/CustomerGroupView.vue";
import EditCustomerGroupView from "../pages/master/customerGroup/EditCustomerGroupView.vue";
import RefundGuaranteeView from "../pages/AR/RefundGuarantee/RefundGuaranteeView.vue";
import EditRefundGuaranteeView from "../pages/AR/RefundGuarantee/EditRefundGuaranteeView.vue";
import DebtFreeView from "../pages/debtFree/DebtFreeView.vue";
import DebtFreeRequestView from "../pages/debtFree/request/DebtFreeRequestView.vue";
import EditDebtFreeRequestView from "../pages/debtFree/request/EditDebtFreeRequestView.vue";
import ProductAPView from "../pages/master/productAP/ProductAPView.vue";
import EditProductAPView from "../pages/master/productAP/EditProductAPView.vue";
import DebtFreeApproveView from "../pages/debtFree/approve/DebtFreeApproveView.vue";
import EditDebtFreeApproveView from "../pages/debtFree/approve/EditDebtFreeApproveView.vue";
import DebtFreePrintView from "../pages/debtFree/print/DebtFreePrintView.vue";
import DebtFreeFollowUpView from "../pages/debtFree/followUp/DebtFreeFollowUpView.vue";
import EditDebtFreeFollowUpView from "../pages/debtFree/followUp/EditDebtFreeFollowUpView.vue";
import SupplierView from "../pages/master/supplier/SupplierView.vue";
import EditSupplierView from "../pages/master/supplier/EditSupplierView.vue";
import ClosingEntryView from "../pages/GL/ClosingEntry/ClosingEntryView.vue";
import EditClosingEntryView from "../pages/GL/ClosingEntry/EditClosingEntryView.vue";
import AccountingFrequentlyView from "../pages/master/accountingFrequently/AccountingFrequentlyView.vue";
import EditAccountingFrequentlyView from "../pages/master/accountingFrequently/EditAccountingFrequentlyView.vue";
import AccountChartView from "../pages/master/accountChart/AccountChartView.vue";
import EditAccountChartView from "../pages/master/accountChart/EditAccountChartView.vue";
import MobileRegisterView from "../pages/dashboard/MobileRegisterView.vue";
import AccountingPolicyView from "../pages/master/accountingPolicy/AccountingPolicyView.vue";
import PhoneBookView from "../pages/master/phoneBook/PhoneBookView.vue";
import EditPhoneBookView from "../pages/master/phoneBook/EditPhoneBookView.vue";
import DashboardView from "../pages/dashboard/DashboardView.vue";
import IFrameView from "../pages/IFrameView.vue";
import NoticePrintSettingView from "../pages/master/notice/NoticePrintSettingView.vue";
import ArImportInvoiceView from "../pages/AR/Invoice/ImportInvoiceView.vue";
import NoticeCustomReportView from "../pages/AR/Notice/NoticeCustomReportView.vue";
import EditNoticeCustomReportView from "../pages/AR/Notice/EditNoticeCustomReportView.vue";
import ArBillPaymentView from "../pages/AR/Receipt/BillPaymentView.vue";
import TransferSlipView from "../pages/AR/TransferSlip/TransferSlipView.vue";
import EditTransferSlipView from "../pages/AR/TransferSlip/EditTransferSlipView.vue";
import ArStockKeycardView from "../pages/AR/StockKeycard/StockKeycardView.vue";
import ArEditStockKeycardView from "../pages/AR/StockKeycard/EditStockKeycardView.vue";
import ControlStockKeycardView from "../pages/AR/StockKeycard/ControlStockKeycardView.vue";
import PreviewStockKeyCardView from "../pages/AR/StockKeycard/PreviewStockKeyCardView.vue";
import PreviewResidentView from "../pages/master/resident/PreviewResidentView.vue";
import PreviewUnitResidentView from "../pages/master/unit/PreviewUnitResidentView.vue";
import PreviewUnitView from "../pages/master/unit/PreviewUnitView.vue";
import PreviewSupplierView from "../pages/master/supplier/PreviewSupplierView.vue";
import PreviewDepositView from "../pages/AR/Deposit/PreviewDepositView.vue";
import PreviewAssetView from "../pages/ASSET/AssetRegister/PreviewAssetView.vue";
import BudgetView from "../pages/budget/BudgetView.vue";
import EditBudgetView from "../pages/budget/EditBudgetView.vue";
import SignatureView from "../pages/master/signatureManagement/SignatureView.vue";
import EditSignatureView from "../pages/master/signatureManagement/EditSignatureView.vue";
import SignatureManagementView from "../pages/master/signatureManagement/SignatureManagementView.vue";
import EditSignatureManagementView from "../pages/master/signatureManagement/EditSignatureManagementView.vue";
import PaymentRequestTypeView from "../pages/paymentRequest/PaymentRequestTypeView.vue";
import EditPaymentRequestTypeView from "../pages/paymentRequest/EditPaymentRequestTypeView.vue";
import PaymentRequestView from "../pages/paymentRequest/PaymentRequestView.vue";
import EditPaymentRequestView from "../pages/paymentRequest/EditPaymentRequestView.vue";
import PreviewPaymentRequestForAutoGlView from "../pages/paymentRequest/PreviewPaymentRequestForAutoGlView.vue";

import store from "../store/index.js";
import useLang from "../hooks/lang.js";
import useGetRoleByBook from "../hooks/getRoleByBook.js";
import useGetRoleByMenu from "../hooks/getRoleByMenu.js";
import { computed, watch, ref } from "vue";
import useTracking from "../hooks/tracking";

const { trackingSend } = useTracking();

const { lang } = useLang();
const selectedLanguage = computed(() => store.getters.selectedLanguage);
let routes = [];
let langTitle = {};
changeMenuLanguage(selectedLanguage.value);
function changeMenuLanguage(language) {
  if (language === "TH") {
    langTitle = { ...lang.th.base, ...lang.th.menuV2 };
  } else {
    langTitle = { ...lang.en.base, ...lang.en.menuV2 };
  }

  routes = [
    {
      path: "/login",
      name: "login",
      component: LoginView,
      meta: { notRequireAuth: true, title: langTitle.signIn },
    },
    {
      path: "/",
      name: "",
      redirect: "/login",
      meta: { notRequireAuth: true, title: langTitle.signIn },
    },
    {
      path: "/select-project",
      name: "select-project",
      component: SelectProjectView,
      meta: {
        notRequireProject: true,
        title: langTitle.selectProject,
        mappingNameSidebar: "select-project",
      },
    },
    {
      path: "/:projectCode",
      name: "projectCode",
      meta: { title: "" },
      props: true,
      children: [
        {
          path: "home",
          name: "home",
          component: DashboardView,
          meta: {
            title: langTitle.projectOverview,
            mappingNameSidebar: "home",
          },
        },
        {
          path: "setting",
          name: "setting",
          component: Setting,
          meta: { notRequireProject: true, mappingNameSidebar: "setting" },
        },
        {
          path: "ar/invoice",
          name: "ar-invoice",
          component: ArInvoiceView,
          meta: { title: langTitle.invoice, mappingNameSidebar: "ar-invoice" },
        },
        {
          path: "ar/invoice/:id",
          name: "ar-invoice-edit",
          props: true,
          component: ArInvoiceEditView,
          meta: { title: langTitle.invoice, mappingNameSidebar: "ar-invoice" },
        },
        {
          path: "ar/invoice/notification",
          name: "ar-invoice-notification",
          props: true,
          component: InvoiceNotification,
          meta: {
            title: langTitle.notification,
            mappingNameSidebar: "ar-invoice",
          },
        },
        {
          path: "ar/invoice/outstanding-letter",
          name: "outstanding-letter",
          component: ArOutstandingLetter,
          meta: {
            title: langTitle.outstandingLetter,
            mappingNameSidebar: "ar-outstanding-letter",
          },
        },
        {
          path: "ar/prepaid-refund",
          name: "ar-prepaid-refund",
          component: ArPrepaidRefund,
          meta: {
            title: langTitle.prepaidRefund,
            mappingNameSidebar: "ar-prepaid-refund",
          },
        },
        {
          path: "ar/prepaid-refund/:id",
          name: "ar-prepaid-refund-edit",
          props: true,
          component: ArPrepaidRefundEdit,
          meta: {
            title: langTitle.prepaidRefund,
            mappingNameSidebar: "ar-prepaid-refund",
          },
        },
        {
          path: "admin/form",
          name: "admin-form",
          component: AdminFormTypeView,
          meta: {
            title: langTitle.adminFormManage,
            mappingNameSidebar: "admin-form",
          },
        },
        {
          path: "admin/form/:id",
          name: "admin-form-detail",
          props: true,
          component: AdminFormDetailView,
          meta: {
            notChangeTitle: true,
            title: langTitle.adminFormManage,
            mappingNameSidebar: "admin-form",
          },
        },
        {
          path: "ap/withholding-tax",
          name: "ap-withholding-tax",
          component: ApWithholdingTaxView,
          meta: {
            title: langTitle.withholdingTax,
            mappingNameSidebar: "ap-withholding-tax",
          },
        },
        {
          path: "gl/accounting",
          name: "gl-accounting",
          component: GlAccountingView,
          meta: {
            title: langTitle.glAccounting,
            mappingNameSidebar: "gl-accounting",
          },
        },
        {
          path: "gl/accounting/:glBook/:idBook",
          name: "gl-accounting-book",
          component: GlAccountingView,
          props: true,
          children: [{ path: "accounting", component: GlAccountingView }],
          meta: {
            title: langTitle.glAccounting,
            mappingNameSidebar: "gl-accounting",
          },
        },
        {
          path: "gl/accounting/:glBook/:idBook/:id",
          name: "gl-accounting-edit",
          props: true,
          component: GlAccountingEditView,
          meta: {
            title: langTitle.glAccounting,
            mappingNameSidebar: "gl-accounting",
          },
        },
        {
          path: "gl/invoice",
          name: "gl-invoice",
          component: GlAccountingView,
          meta: {
            title: langTitle.glInvoice,
            mappingNameSidebar: "gl-invoice",
          },
        },
        {
          path: "gl/invoice/:glBook/:idBook",
          name: "gl-invoice-book",
          component: GlAccountingView,
          props: true,
          children: [{ path: "invoice", component: GlAccountingView }],
          meta: {
            title: langTitle.glInvoice,
            mappingNameSidebar: "gl-invoice",
          },
        },
        {
          path: "gl/invoice/revert-accounting/:glBook/:idBook/:id",
          name: "revert-accounting",
          props: true,
          component: RevertAccountingView,
          meta: {
            title: langTitle.revertAccounting,
            mappingNameSidebar: "revert-accounting",
          },
        },
        {
          path: "gl/invoice/:glBook/:idBook/:id",
          name: "gl-invoice-edit",
          props: true,
          component: GlAccountingEditView,
          meta: {
            title: langTitle.glInvoice,
            mappingNameSidebar: "gl-invoice",
          },
        },
        {
          path: "gl/receipt",
          name: "gl-receipt",
          component: GlAccountingView,
          meta: {
            title: langTitle.glReceipt,
            mappingNameSidebar: "gl-receipt",
          },
        },
        {
          path: "gl/receipt/:glBook/:idBook",
          name: "gl-receipt-book",
          component: GlAccountingView,
          props: true,
          children: [{ path: "receipt", component: GlAccountingView }],
          meta: {
            title: langTitle.glReceipt,
            mappingNameSidebar: "gl-receipt",
          },
        },
        {
          path: "gl/receipt/:glBook/:idBook/:id",
          name: "gl-receipt-edit",
          props: true,
          component: GlAccountingEditView,
          meta: {
            title: langTitle.glReceipt,
            mappingNameSidebar: "gl-receipt",
          },
        },
        {
          path: "gl/pettycash",
          name: "gl-pettycash",
          component: PettyCashListView,
          meta: {
            title: langTitle.glPettyCash,
            mappingNameSidebar: "gl-pettycash",
          },
        },
        {
          path: "gl/pettycash/:glBook/:idBook",
          name: "gl-pettycash-book",
          component: PettyCashListView,
          props: true,
          children: [{ path: "pettycash", component: PettyCashListView }],
          meta: {
            title: langTitle.glPettyCash,
            mappingNameSidebar: "gl-pettycash",
          },
        },
        {
          path: "gl/pettycash/:glBook/:idBook/:id",
          name: "gl-pettycash-edit",
          props: true,
          component: GlAccountingEditView,
          meta: {
            title: langTitle.glPettyCash,
            mappingNameSidebar: "gl-pettycash",
          },
        },
        {
          path: "account-book",
          name: "account-book",
          component: AccountBookView,
          meta: {
            title: langTitle.accountBook,
            mappingNameSidebar: "account-book",
          },
        },
        {
          path: "account-book/:id",
          name: "account-book-edit",
          props: true,
          component: EditAccountBookView,
          meta: {
            title: langTitle.accountBook,
            mappingNameSidebar: "account-book",
          },
        },
        {
          path: "ap/withholding-tax/:id",
          name: "ap-withholding-tax-edit",
          props: true,
          component: ApWithholdingTaxEditView,
          meta: {
            title: langTitle.withholdingTax,
            mappingNameSidebar: "ap-withholding-tax",
          },
        },
        {
          path: "ar/bank-ar",
          name: "ar-bank-ar",
          component: ArBank,
          meta: { title: langTitle.bankAR, mappingNameSidebar: "ar-bank-ar" },
        },
        {
          path: "ar/bank-ar/:id",
          name: "ar-bank-ar-edit",
          props: true,
          component: EditBankArView,
          meta: { title: langTitle.bankAR, mappingNameSidebar: "ar-bank-ar" },
        },
        {
          path: "ap/bank-ap",
          name: "ap-bank-ap",
          component: ApBank,
          meta: { title: langTitle.bankAP, mappingNameSidebar: "ap-bank-ap" },
        },
        {
          path: "ap/bank-ap/:id",
          name: "ap-bank-ap-edit",
          props: true,
          component: EditBankApView,
          meta: { title: langTitle.bankAP, mappingNameSidebar: "ap-bank-ap" },
        },
        {
          path: "report/:type",
          name: "report",
          props: true,
          component: ReportListingView,
          meta: { title: langTitle.report, mappingNameSidebar: "report" },
        },
        {
          path: "ap/purchase-invoice",
          name: "ap-purchase-invoice",
          component: PurchaseInvoiceView,
          meta: {
            title: langTitle.purchaseInvoice,
            mappingNameSidebar: "ap-purchase-invoice",
          },
        },
        {
          path: "ap/purchase-invoice/:apBook",
          name: "ap-purchase-invoice-apBook",
          component: PurchaseInvoiceView,
          props: true,
          children: [
            { path: "purchase-invoice", component: PurchaseInvoiceView },
          ],
          meta: {
            title: langTitle.purchaseInvoice,
            mappingNameSidebar: "ap-purchase-invoice",
          },
        },
        {
          path: "ap/purchase-invoice/:apBook/:id",
          name: "ap-purchase-invoice-edit",
          props: true,
          component: EditPurchaseInvoiceView,
          meta: {
            title: langTitle.purchaseInvoice,
            mappingNameSidebar: "ap-purchase-invoice",
          },
        },
        {
          path: "ap/purchase-receipt",
          name: "ap-purchase-receipt",
          props: true,
          component: PurchaseReceiptView,
          meta: {
            title: langTitle.purchaseReceipt,
            mappingNameSidebar: "ap-purchase-receipt",
          },
        },
        {
          path: "ap/purchase-receipt/:apBook",
          name: "ap-purchase-receipt-apbook",
          props: true,
          component: PurchaseReceiptView,
          children: [
            { path: "purchase-receipt", component: PurchaseReceiptView },
          ],
          meta: {
            title: langTitle.purchaseReceipt,
            mappingNameSidebar: "ap-purchase-receipt",
          },
        },
        {
          path: "ap/purchase-receipt/:apBook/:id",
          name: "ap-purchase-receipt-edit",
          props: true,
          component: EditPurchaseReceiptView,
          meta: {
            title: langTitle.purchaseReceipt,
            mappingNameSidebar: "ap-purchase-receipt",
          },
        },
        {
          path: "ap/purchase-requisition",
          name: "ap-purchase-requisition",
          component: PurchaseRequisitionView,
          meta: {
            title: langTitle.purchaseRequisition,
            mappingNameSidebar: "ap-purchase-requisition",
          },
        },
        {
          path: "ap/purchase-requisition/:apBook",
          name: "ap-purchase-requisition-apBook",
          component: PurchaseRequisitionView,
          props: true,
          children: [
            {
              path: "purchase-requisition",
              component: PurchaseRequisitionView,
            },
          ],
          meta: {
            title: langTitle.purchaseRequisition,
            mappingNameSidebar: "ap-purchase-requisition",
          },
        },
        {
          path: "ap/purchase-requisition/:apBook/:id",
          name: "ap-purchase-requisition-edit",
          props: true,
          component: EditPurchaseRequisitionView,
          meta: {
            title: langTitle.purchaseRequisition,
            mappingNameSidebar: "ap-purchase-requisition",
          },
        },
        {
          path: "ap/purchase-order",
          name: "ap-purchase-order",
          component: PurchaseOrderView,
          meta: {
            title: langTitle.purchaseOrder,
            mappingNameSidebar: "ap-purchase-order",
          },
        },
        {
          path: "ap/purchase-order/:apBookId",
          name: "ap-purchase-order-apBookId",
          component: PurchaseOrderView,
          props: true,
          children: [{ path: "purchase-order", component: PurchaseOrderView }],
          meta: {
            title: langTitle.purchaseOrder,
            mappingNameSidebar: "ap-purchase-order",
          },
        },
        {
          path: "ap/purchase-order/:apBookId/:id",
          name: "ap-purchase-order-edit",
          props: true,
          component: EditPurchaseOrderView,
          meta: {
            title: langTitle.purchaseOrder,
            mappingNameSidebar: "ap-purchase-order",
          },
        },
        {
          path: "master/project",
          name: "master-project",
          component: ProjectView,
          meta: {
            title: langTitle.projectInformation,
            mappingNameSidebar: "master-project",
          },
        },
        {
          path: "master/project/edit",
          name: "master-project-edit",
          component: EditProjectView,
          meta: {
            title: langTitle.projectInformation,
            mappingNameSidebar: "master-project",
          },
        },
        {
          path: "asset/register",
          name: "asset-register",
          component: AssetRegisterView,
          meta: {
            title: langTitle.assetRegister,
            mappingNameSidebar: "asset-register",
          },
        },
        {
          path: "asset/register/:id",
          name: "asset-register-edit",
          props: true,
          component: EditAssetRegisterView,
          meta: {
            title: langTitle.assetRegister,
            mappingNameSidebar: "asset-register",
          },
        },
        {
          path: "project-document",
          name: "project-document",
          component: ProjectDocumentView,
          meta: {
            title: langTitle.projectDocument,
            mappingNameSidebar: "project-document",
          },
        },
        {
          path: "unit",
          name: "unit",
          component: UnitView,
          meta: { title: langTitle.unit, mappingNameSidebar: "unit" },
        },
        {
          path: "unit/:id",
          name: "unit-edit",
          props: true,
          component: EditUnitView,
          meta: { title: langTitle.unit, mappingNameSidebar: "unit" },
        },
        {
          path: "master/product-advertisement",
          name: "product-advertisement",
          component: AdvertisementView,
          meta: {
            title: langTitle.announceAdvertising,
            mappingNameSidebar: "product-advertisement",
          },
        },
        {
          path: "master/product-advertisement/edit",
          name: "product-advertisement-edit",
          component: EditAdvertisementView,
          meta: {
            title: langTitle.announceAdvertising,
            mappingNameSidebar: "product-advertisement-edit",
          },
        },
        {
          path: "meter/:meterType",
          name: "master-meter",
          props: true,
          component: MeterView,
          meta: { title: langTitle.meter, mappingNameSidebar: "master-meter" },
        },
        {
          path: "meter/:meterType/:id",
          name: "master-meter-edit",
          props: true,
          component: EditMeterView,
          meta: { title: langTitle.meter, mappingNameSidebar: "master-meter" },
        },
        {
          path: "meter/auto-create/:meterType",
          name: "master-meter-auto-create",
          props: true,
          component: MeterAutoCreateView,
          meta: {
            title: langTitle.autoCreateMeter,
            mappingNameSidebar: "master-meter",
          },
        },
        {
          path: "project-penaltycalculation/edit",
          name: "project-penaltycalculation-edit",
          component: EditPenaltyCalculationView,
          meta: {
            title: langTitle.projectPenaltyCalculation,
            mappingNameSidebar: "project-penaltycalculation-edit",
          },
        },
        {
          path: "product-penaltycalculation/:productId/:productTypeId",
          name: "product-penaltycalculation-edit",
          props: true,
          component: EditPenaltyProductView,
          meta: {
            title: langTitle.productAR,
            mappingNameSidebar: "project-penaltycalculation-edit",
          },
        },
        {
          path: "meter-usage-seting",
          name: "master-meter-usage-seting",
          component: MeterUsageSettingView,
          meta: {
            title: langTitle.meterUsageSetting,
            mappingNameSidebar: "meter-usage-seting",
          },
        },
        {
          path: "resident/:id",
          name: "resident-edit",
          props: true,
          component: EditResidentView,
          meta: { title: langTitle.residents, mappingNameSidebar: "resident" },
        },
        {
          path: "unit/resident/:unitId/:id",
          name: "unit-resident-edit",
          props: true,
          component: EditResidentView,
          meta: {
            title: langTitle.residents,
            mappingNameSidebar: "unit-resident",
          },
        },
        {
          path: "unit/product-invoice/:unitId",
          name: "unit-product-setup-invoice",
          props: true,
          component: EditUnitProductSetupForInvoice,
          meta: {
            title: langTitle.settingUnitProduct,
            mappingNameSidebar: "unit",
          },
        },
        {
          path: "ar-product",
          name: "ar-product",
          component: ArProductView,
          meta: {
            title: langTitle.productAR,
            mappingNameSidebar: "ar-product",
          },
        },
        {
          path: "ar-product/:id",
          name: "ar-product-edit",
          props: true,
          component: EditArProductView,
          meta: {
            title: langTitle.productAR,
            mappingNameSidebar: "ar-product",
          },
        },
        {
          path: "resident",
          name: "resident",
          component: ResidentView,
          meta: { title: langTitle.customer, mappingNameSidebar: "resident" },
        },
        {
          path: "book-bank",
          name: "book-bank",
          component: BookBankView,
          meta: { title: langTitle.bookbank, mappingNameSidebar: "book-bank" },
        },
        {
          path: "book-bank/:id",
          name: "book-bank-edit",
          props: true,
          component: EditBookBankView,
          meta: { title: langTitle.bookbank, mappingNameSidebar: "book-bank" },
        },
        {
          path: "form-version",
          name: "form-version",
          component: FormVersionView,
          meta: {
            title: langTitle.formVersion,
            mappingNameSidebar: "form-version",
          },
        },
        {
          path: "meter-usage/water/:productType",
          name: "meter-usage-water",
          props: true,
          component: EditMeterUsageView,
          meta: {
            title: langTitle.meterUsageWater,
            mappingNameSidebar: "meter-usage-water",
          },
        },
        {
          path: "meter-usage/electricity/:productType",
          name: "meter-usage-electricity",
          props: true,
          component: EditMeterUsageView,
          meta: {
            title: langTitle.meterUsageElectric,
            mappingNameSidebar: "meter-usage-electricity",
          },
        },
        {
          path: "meter-usage/cooling-air/:productType",
          name: "meter-usage-cooling-air",
          props: true,
          component: EditMeterUsageView,
          meta: {
            title: langTitle.meterUsageCoolingAir,
            mappingNameSidebar: "meter-usage-cooling-air",
          },
        },
        {
          path: "meter-usage/water/:productType/:page",
          name: "meter-usage-water-edit",
          props: true,
          component: EditMeterUsageView,
          meta: {
            title: langTitle.meterUsageWater,
            mappingNameSidebar: "meter-usage-water",
          },
        },
        {
          path: "meter-usage/electricity/:productType/:page",
          name: "meter-usage-electricity-edit",
          props: true,
          component: EditMeterUsageView,
          meta: {
            title: langTitle.meterUsageElectric,
            mappingNameSidebar: "meter-usage-electricity",
          },
        },
        {
          path: "meter-usage/cooling-air/:productType/:page",
          name: "meter-usage-cooling-air-edit",
          props: true,
          component: EditMeterUsageView,
          meta: {
            title: langTitle.meterUsageCoolingAir,
            mappingNameSidebar: "meter-usage-cooling-air",
          },
        },
        {
          path: "contact-us",
          name: "contact-us",
          component: ContactUsView,
          meta: {
            notRequireAuth: true,
            title: langTitle.contactStaff,
            mappingNameSidebar: "contact-us",
          },
        },
        {
          path: "admin/report",
          name: "admin-report-menu",
          component: ReportMenuView,
          meta: {
            title: langTitle.reportManage,
            mappingNameSidebar: "admin-report-menu",
          },
        },
        {
          path: "admin/report/:id",
          name: "admin-report-menu-detail",
          props: true,
          component: ReportMenuDetailView,
          meta: {
            notChangeTitle: true,
            title: langTitle.reportManage,
            mappingNameSidebar: "admin-report-menu",
          },
        },
        {
          path: "report/request",
          name: "report-request",
          component: RequestListingView,
          meta: {
            title: langTitle.reportRequest,
            mappingNameSidebar: "report-request",
          },
        },
        {
          path: "ar/receipt",
          name: "ar-receipt",
          component: ArReceiptView,
          meta: { title: langTitle.receipt, mappingNameSidebar: "ar-receipt" },
        },
        {
          path: "ar/receipt/:id",
          name: "ar-receipt-edit",
          props: true,
          component: ArReceiptEditView,
          meta: { title: langTitle.receipt, mappingNameSidebar: "ar-receipt" },
        },
        {
          path: "ar/visitor-control",
          name: "visitor-control",
          component: VisitorControlView,
          meta: {
            title: langTitle.visitorControl,
            mappingNameSidebar: "ar-receipt",
          },
        },
        {
          path: "ar/visitor-control/:id",
          name: "visitor-control-edit",
          props: true,
          component: EditVisitorControlView,
          meta: {
            title: langTitle.visitorControl,
            mappingNameSidebar: "ar-receipt",
          },
        },
        {
          path: "ar/receipt/import",
          name: "ar-receipt-import",
          component: ArImportReceiptView,
          meta: { title: langTitle.receipt, mappingNameSidebar: "ar-receipt" },
        },
        {
          path: "ar/notice",
          name: "ar-notice",
          component: ArNoticeView,
          meta: { title: langTitle.notice, mappingNameSidebar: "ar-notice" },
        },
        {
          path: "ar/notice/:id",
          name: "ar-notice-edit",
          props: true,
          component: ArEditNoticeView,
          meta: { title: langTitle.notice, mappingNameSidebar: "ar-notice" },
        },
        {
          path: "ar/calculate-invoice",
          name: "ar-calculate-invoice",
          component: ArCalculateInvoiceView,
          meta: {
            title: langTitle.calculateInvoice,
            mappingNameSidebar: "ar-calculate-invoice",
          },
        },
        {
          path: "ar/calculate-invoice/invoice-wait-for-approve",
          name: "ar-invoice-wait-for-approve",
          component: ArInvoiceWaitForApproveView,
          meta: {
            title: langTitle.calculateInvoice,
            mappingNameSidebar: "ar-calculate-invoice",
          },
        },
        {
          path: "ar/calculate-invoice/invoice-wait-for-approve/:id",
          name: "ar-edit-invoice-wait-for-approve",
          props: true,
          component: ArEditInvoiceWaitForApproveView,
          meta: {
            notChangeTitle: true,
            title: langTitle.calculateInvoice,
            mappingNameSidebar: "ar-calculate-invoice",
          },
        },
        {
          path: "ar/use-prepaid-and-deposit",
          name: "ar-use-prepaid-and-deposit",
          component: ArUsePrepaidAndDepositView,
          meta: {
            title: langTitle.usePrepaidAndDeposit,
            mappingNameSidebar: "ar-use-prepaid-and-deposit",
          },
        },
        {
          path: "ar/preview-use-prepaid-and-deposit/:isUsePrepaid/:isUseDeposit/:isBackToHomePage",
          name: "ar-preview-use-prepaid-and-deposit",
          props: true,
          component: ArPreviewUsePrepaidAndDepositView,
          meta: {
            title: langTitle.previewUsePrepaidAndDeposit,
            mappingNameSidebar: "ar-use-prepaid-and-deposit",
          },
        },
        {
          path: "ar/calculate-notice",
          name: "ar-calculate-notice",
          component: ArCalculateNoticeView,
          meta: {
            title: langTitle.calculateNotice,
            mappingNameSidebar: "ar-calculate-notice",
          },
        },
        {
          path: "ar/calculate-notice/notice-wait-for-approve",
          name: "ar-notice-wait-for-approve",
          component: ArNoticeWaitForApproveView,
          meta: {
            title: langTitle.calculateNotice,
            mappingNameSidebar: "ar-calculate-notice",
          },
        },
        {
          path: "ar/calculate-notice/notice-wait-for-approve/:id",
          name: "ar-edit-notice-wait-for-approve",
          props: true,
          component: ArEditNoticeWaitForApproveView,
          meta: {
            notChangeTitle: true,
            title: langTitle.calculateNotice,
            mappingNameSidebar: "ar-calculate-notice",
          },
        },
        {
          path: "master/policy",
          name: "master-policy",
          component: PolicyView,
          meta: {
            title: langTitle.policy,
            mappingNameSidebar: "master-policy",
          },
        },
        {
          path: "master/policy/:id",
          name: "master-policy-edit",
          component: EditPolicyView,
          props: true,
          meta: {
            title: langTitle.policy,
            mappingNameSidebar: "master-policy",
          },
        },
        {
          path: "ar/deposit",
          name: "ar-deposit",
          component: ArDepositView,
          meta: { title: langTitle.deposit, mappingNameSidebar: "ar-deposit" },
        },
        {
          path: "ar/deposit/:id",
          name: "ar-deposit-edit",
          props: true,
          component: ArEditDepositView,
          meta: { title: langTitle.deposit, mappingNameSidebar: "ar-deposit" },
        },
        {
          path: "ar/credit-note",
          name: "ar-credit-note",
          component: ArCreditNoteView,
          meta: {
            title: langTitle.creditNote,
            mappingNameSidebar: "ar-credit-note",
          },
        },
        {
          path: "ar/credit-note/:id",
          name: "ar-credit-note-edit",
          props: true,
          component: ArEditCreditNoteView,
          meta: {
            title: langTitle.creditNote,
            mappingNameSidebar: "ar-credit-note",
          },
        },
        {
          path: "customer-group",
          name: "customer-group",
          component: CustomerGroupView,
          meta: {
            title: langTitle.customerGroup,
            mappingNameSidebar: "customer-group",
          },
        },
        {
          path: "customer-group/:id",
          name: "customer-group-edit",
          props: true,
          component: EditCustomerGroupView,
          meta: {
            title: langTitle.customerGroup,
            mappingNameSidebar: "customer-group",
          },
        },
        {
          path: "ar/refund-guarantee",
          name: "ar-refund-guarantee",
          component: RefundGuaranteeView,
          meta: {
            title: langTitle.refundGuarantee,
            mappingNameSidebar: "ar-refund-guarantee",
          },
        },
        {
          path: "ar/refund-guarantee/:id",
          name: "ar-refund-guarantee-edit",
          component: EditRefundGuaranteeView,
          props: true,
          meta: {
            title: langTitle.refundGuarantee,
            mappingNameSidebar: "ar-refund-guarantee",
          },
        },
        {
          path: "debt-free",
          name: "debt-free",
          component: DebtFreeView,
          meta: { title: langTitle.debtfree, mappingNameSidebar: "debt-free" },
        },
        {
          path: "debt-free/request",
          name: "debt-free-request",
          component: DebtFreeRequestView,
          meta: {
            title: langTitle.debtfreeRequest,
            mappingNameSidebar: "debt-free-request",
          },
        },
        {
          path: "debt-free/request/:id",
          name: "debt-free-request-edit",
          component: EditDebtFreeRequestView,
          props: true,
          meta: {
            title: langTitle.debtfreeRequest,
            mappingNameSidebar: "debt-free-request",
          },
        },
        {
          path: "product-ap",
          name: "product-ap",
          component: ProductAPView,
          meta: {
            title: langTitle.productAP,
            mappingNameSidebar: "product-ap",
          },
        },
        {
          path: "product-ap/:id",
          name: "product-ap-edit",
          component: EditProductAPView,
          props: true,
          meta: {
            title: langTitle.productAP,
            mappingNameSidebar: "product-ap",
          },
        },
        {
          path: "debt-free/approve",
          name: "debt-free-approve",
          component: DebtFreeApproveView,
          meta: {
            title: langTitle.debtfreeApprove,
            mappingNameSidebar: "debt-free-approve",
          },
        },
        {
          path: "debt-free/approve/:id",
          name: "debt-free-approve-edit",
          component: EditDebtFreeApproveView,
          props: true,
          meta: {
            title: langTitle.debtfreeApprove,
            mappingNameSidebar: "debt-free-approve",
          },
        },
        {
          path: "debt-free/print",
          name: "debt-free-print",
          component: DebtFreePrintView,
          meta: {
            title: langTitle.debtfreePrint,
            mappingNameSidebar: "debt-free-print",
          },
        },
        {
          path: "debt-free/follow-up",
          name: "debt-free-follow-up",
          component: DebtFreeFollowUpView,
          meta: {
            title: langTitle.debtfreeFollowUp,
            mappingNameSidebar: "debt-free-follow-up",
          },
        },
        {
          path: "debt-free/follow-up/:id",
          name: "debt-free-follow-up-edit",
          component: EditDebtFreeFollowUpView,
          props: true,
          meta: {
            title: langTitle.debtfreeFollowUp,
            mappingNameSidebar: "debt-free-follow-up",
          },
        },
        {
          path: "supplier",
          name: "supplier",
          component: SupplierView,
          meta: { title: langTitle.supplier, mappingNameSidebar: "supplier" },
        },
        {
          path: "supplier/:id",
          name: "supplier-edit",
          component: EditSupplierView,
          props: true,
          meta: { title: langTitle.supplier, mappingNameSidebar: "supplier" },
        },
        {
          path: "gl/closing-entry",
          name: "closing-entry",
          component: ClosingEntryView,
          meta: {
            title: langTitle.closingEntry,
            mappingNameSidebar: "closing-entry",
          },
        },
        {
          path: "gl/closing-entry/:id",
          name: "closing-entry-edit",
          component: EditClosingEntryView,
          props: true,
          meta: {
            title: langTitle.closingEntry,
            mappingNameSidebar: "closing-entry",
          },
        },
        {
          path: "accounting-frequently",
          name: "accounting-frequently",
          component: AccountingFrequentlyView,
          meta: {
            title: langTitle.accountFrequently,
            mappingNameSidebar: "accounting-frequently",
          },
        },
        {
          path: "accounting-frequently/:accountBookId",
          name: "accounting-frequently-accountBookId",
          props: true,
          component: AccountingFrequentlyView,
          meta: {
            title: langTitle.accountFrequently,
            mappingNameSidebar: "accounting-frequently",
          },
        },
        {
          path: "accounting-frequently/:accountBookId/:id",
          name: "accounting-frequently-accountBookId-edit",
          props: true,
          component: EditAccountingFrequentlyView,
          meta: {
            title: langTitle.accountFrequently,
            mappingNameSidebar: "accounting-frequently",
          },
        },
        {
          path: "account-chart",
          name: "account-chart",
          component: AccountChartView,
          meta: {
            title: langTitle.accountChart,
            mappingNameSidebar: "account-chart",
          },
        },
        {
          path: "account-chart/:id",
          name: "account-chart-edit",
          props: true,
          component: EditAccountChartView,
          meta: {
            title: langTitle.accountChart,
            mappingNameSidebar: "account-chart",
          },
        },
        {
          path: "dashboard/mobile-register",
          name: "dashboard-mobile-register",
          component: MobileRegisterView,
          meta: {
            title: langTitle.registerApp,
            mappingNameSidebar: "dashboard-mobile-register",
          },
        },
        {
          path: "accounting-policy",
          name: "accounting-policy",
          props: true,
          component: AccountingPolicyView,
          meta: {
            title: langTitle.accountPolicy,
            mappingNameSidebar: "accounting-policy",
          },
        },
        {
          path: "phone-book",
          name: "phone-book",
          component: PhoneBookView,
          meta: {
            title: langTitle.phoneBook,
            mappingNameSidebar: "phone-book",
          },
        },
        {
          path: "phone-book/:id",
          name: "phone-book-edit",
          props: true,
          component: EditPhoneBookView,
          meta: {
            title: langTitle.phoneBook,
            mappingNameSidebar: "phone-book",
          },
        },
        {
          path: "iframe",
          name: "iframe",
          component: IFrameView,
          meta: { title: "", mappingNameSidebar: "iframe" },
        },
        {
          path: "notice-print-setting",
          name: "notice-print-setting",
          component: NoticePrintSettingView,
          meta: {
            title: langTitle.noticePrintSetting,
            mappingNameSidebar: "notice-print-setting",
          },
        },
        {
          path: "report/Notice/notice-print-setting",
          name: "report-notice-print-setting",
          component: NoticePrintSettingView,
          meta: {
            title: langTitle.noticePrintSetting,
            mappingNameSidebar: "report-Notice",
          },
        },
        {
          path: "ar/invoice/import",
          name: "ar-invoice-import",
          component: ArImportInvoiceView,
          meta: { title: langTitle.invoice, mappingNameSidebar: "ar-invoice" },
        },
        {
          path: "ar/notice-custom-report",
          name: "notice-custom-report",
          component: NoticeCustomReportView,
          meta: { title: langTitle.noticePrintSetting, mappingNameSidebar: "notice-custom-report" }
        },
        {
          path: "ar/notice-custom-report/:id",
          name: "notice-custom-report-edit",
          props: true,
          component: EditNoticeCustomReportView,
          meta: { title: langTitle.noticePrintSetting, mappingNameSidebar: "notice-custom-report" }
        },
        {
          path: "ar/receipt/bill-payment",
          name: "ar-receipt-bill-payment",
          props: true,
          component: ArBillPaymentView,
          meta: { title: langTitle.billPayment, mappingNameSidebar: "ar-receipt" },
        },
        {
          path: "ar/transfer-slip",
          name: "transfer-slip",
          props: true,
          component: TransferSlipView,
          meta: { title: langTitle.transferSlip, mappingNameSidebar: "transfer-slip" },
        },
        {
          path: "ar/transfer-slip/:id",
          name: "transfer-slip-edit",
          props: true,
          component: EditTransferSlipView,
          meta: { title: langTitle.transferSlip, mappingNameSidebar: "transfer-slip" },
        },
        {
          path: "ar/stock-keycard",
          name: "ar-stock-keycard",
          props: true,
          component: ArStockKeycardView,
          meta: { title: langTitle.stockKeycard, mappingNameSidebar: "ar-stock-keycard" },
        },
        {
          path: "ar/stock-keycard/:id",
          name: "ar-stock-keycard-edit",
          props: true,
          component: ArEditStockKeycardView,
          meta: { title: langTitle.stockKeycard, mappingNameSidebar: "ar-stock-keycard" },
        },
        {
          path: "control-stock-keycard",
          name: "control-stock-keycard",
          props: true,
          component: ControlStockKeycardView,
          meta: { title: langTitle.controlStockKeycard, mappingNameSidebar: "control-stock-keycard" },
        },
        {
          path: "ar/stock-keycard/preview-stock-keycard",
          name: "preview-stock-keycard",
          props: true,
          component: PreviewStockKeyCardView,
          meta: { title: langTitle.controlStockKeycard, mappingNameSidebar: "ar-stock-keycard" },
        },
        {
          path: "resident/preview-resident",
          name: "preview-resident",
          props: true,
          component: PreviewResidentView,
          meta: { title: langTitle.importResident, mappingNameSidebar: "resident" },
        },
        {
          path: "unit/preview-unit-resident",
          name: "preview-unit-resident",
          props: true,
          component: PreviewUnitResidentView,
          meta: { title: langTitle.previewUnitResidentTitle, mappingNameSidebar: "unit" },
        },
        {
          path: "unit/preview-unit",
          name: "preview-unit",
          props: true,
          component: PreviewUnitView,
          meta: { title: langTitle.importUnit, mappingNameSidebar: "unit" },
        },
        {
          path: "supplier/preview-supplier",
          name: "preview-supplier",
          component: PreviewSupplierView,
          props: true,
          meta: { title: langTitle.importSupplier, mappingNameSidebar: "supplier" },
        },
        {
          path: "ar/deposit/preview-deposit",
          name: "preview-deposit",
          component: PreviewDepositView,
          props: true,
          meta: { title: langTitle.importDeposit, mappingNameSidebar: "ar-deposit" },
        },   
        {
          path: "asset/preview-asset",
          name: "preview-asset",
          component: PreviewAssetView,
          props: true,
          meta: { title: langTitle.importAsset, mappingNameSidebar: "asset-register" },
        },
        {
          path: "budget",
          name: "budget",
          component: BudgetView,
          props: true,
          meta: { title: langTitle.budget, mappingNameSidebar: "budget" },
        },  
        {
          path: "budget/:id",
          name: "budget-edit",
          component: EditBudgetView,
          props: true,
          meta: { title: langTitle.budget, mappingNameSidebar: "budget" },
        },
        {
          path: "signature",
          name: "signature",
          component: SignatureView,
          props: true,
          meta: { title: langTitle.reportSignator, mappingNameSidebar: "signature" },
        },
        {
          path: "signature/:id",
          name: "signature-edit",
          component: EditSignatureView,
          props: true,
          meta: { title: langTitle.signator, mappingNameSidebar: "signature" },
        },
        {
          path: "signature-management",
          name: "signature-management",
          component: SignatureManagementView,
          props: true,
          meta: { title: langTitle.signatureManagement, mappingNameSidebar: "signature-management" },
        },
        {
          path: "signature-management/:reportMenuDetailId/:id",
          name: "signature-management-edit",
          component: EditSignatureManagementView,
          props: true,
          meta: { title: langTitle.signatureManagement, mappingNameSidebar: "signature-management" },
        },
        {
          path: "payment-request-type",
          name: "payment-request-type",
          component: PaymentRequestTypeView,
          props: true,
          meta: { title: langTitle.paymentRequestType, mappingNameSidebar: "payment-request" },
        },
        {
          path: "payment-request-type/:id",
          name: "payment-request-type-edit",
          component: EditPaymentRequestTypeView,
          props: true,
          meta: { title: langTitle.paymentRequestType, mappingNameSidebar: "payment-request" },
        },
        {
          path: "payment-request",
          name: "payment-request",
          component: PaymentRequestView,
          props: true,
          meta: { title: langTitle.paymentRequestManagement, mappingNameSidebar: "payment-request" },
        },
        {
          path: "payment-request/:id",
          name: "payment-request-edit",
          component: EditPaymentRequestView,
          props: true,
          meta: { title: langTitle.paymentRequestManagement, mappingNameSidebar: "payment-request" },
        },
        {
          path: "payment-request/preview-payment-request-auto-gl",
          name: "preview-payment-request-auto-gl",
          props: true,
          component: PreviewPaymentRequestForAutoGlView,
          meta: { title: langTitle.paymentRequestManagement, mappingNameSidebar: "payment-request" },
        },
        { path: ":notFound(.*)", component: NotFound },
      ],
    },
  ];
}

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLISH_PATH),
  routes,
});

router.beforeEach(async function (to, _, next) {
  const isAuthenticated = store.getters.isAuthenticated;
  if (to.params.projectCode) {
    await store.dispatch("trySetProject", to.params.projectCode);
  }
  const isProjectSelected = store.getters.isProjectSelected;

  if (
    to.path === "/contact-us" &&
    !to.meta.notRequireAuth &&
    !isAuthenticated
  ) {
    next("/contact-us");
  } else if (!to.meta.notRequireAuth && !isAuthenticated) {
    let redirectUrl = "/login";
    if (to.path) {
      redirectUrl = "/login?redirect=" + to.path;
    }
    next(redirectUrl);
  } else if (to.path === "/login" && isAuthenticated && isProjectSelected) {
    //check
    const selectedProjectCode = store.getters.selectedProject.code;
    next(`/${selectedProjectCode}/home`);
  } else if (
    isAuthenticated &&
    !isProjectSelected &&
    !to.meta.notRequireProject
  ) {
    let redirectUrl = "/select-project";
    if (to.path) {
      redirectUrl = "/select-project?redirect=" + to.path;
    }
    next(redirectUrl);
  } else {
    next();
  }
});

watch(selectedLanguage, function (curValue, oldValue) {
  if (curValue !== oldValue) {
    changeMenuLanguage(curValue);
    setTitle(curValue);
  }
});
let routerTo = ref(null);
let paramTo = ref(null);
router.afterEach(async function (to) {
  let routesFound = routes.find((route) => route.name == to.name);
  if (!routesFound) {
    const routesChildren = routes.find(
      (route) => route.name == "projectCode"
    ).children;
    routesFound = routesChildren.find((route) => route.name == to.name);
  }
  routerTo.value = routesFound;
  paramTo.value = to.params;

  changeMenuLanguage(selectedLanguage.value);
  setTitle(selectedLanguage.value);
  const isAuthenticated = store.getters.isAuthenticated;
  const isProjectSelected = store.getters.isProjectSelected;

  let trackingProject = {};
  if (isAuthenticated && isProjectSelected) {
    const { getAllRoleByBooks } = useGetRoleByBook();
    const { getAllRoleByMenus } = useGetRoleByMenu();
    getAllRoleByBooks();
    getAllRoleByMenus();

    const selectedProject = store.getters.selectedProject;
    trackingProject = {
      id: selectedProject.id,
      code: selectedProject.code,
    };
  }

  let trackingData = {
    current_screen: routerTo.value.name,
    current_project: trackingProject,
    ref_screen: router.options.history.state.back ? router.options.history.state.back : ""
  }

  trackingSend("track_current_screen", trackingData);
});

const setTitle = function (lang) {
  let dataRouter = routerTo.value;
  let paramRouter = paramTo.value;
  if (dataRouter && dataRouter.meta) {
    const isProjectSelected = store.getters.isProjectSelected;

    let title = dataRouter.meta.title ? dataRouter.meta.title + " | " : "";
    let webTitle = title + "The LivingOS";
    let projectName = "";
    if (isProjectSelected) {
      if (lang === "TH") {
        projectName = store.getters.selectedProject.nameTh.trim();
      } else {
        projectName = store.getters.selectedProject.nameEn.trim();
      }
    }
    if (
      dataRouter.path !== "/login" &&
      dataRouter.path !== "/select-project" &&
      isProjectSelected
    ) {
      if (!dataRouter.meta.notChangeTitle && paramRouter.id) {
        if (paramRouter.id == "create") {
          title = langTitle.createText + dataRouter.meta.title + " | ";
        } else {
          title = langTitle.edit + dataRouter.meta.title + " | ";
        }
      }
      webTitle = title + projectName + " | The LivingOS";
    }
    document.title = webTitle;
  }
};

export default router;
