export default {
  pettyCash(state) {
    return state.pettyCash;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
  blob(state) {
    return state.blob;
  },
  pettyCashUseOtherExpenses(state) {
    return state.pettyCashUseOtherExpenses;
  },
};
