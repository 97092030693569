<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateEnd">{{ lang.budgetYearCurrent }}</label>
          <budget-year-auto-complete
            id="budgetYearCurrent"
            v-model="selectedBudgetCurrent"
            class="inputfield w-full mt-1"
            @load-finish="budgetsDataReady"
            :isLoadingData="true"
            :class="{ 'p-invalid': selectedBudgetCurrentError }"
            @item-select="selectedBudgetCurrentError = false"
          />
          <small
            v-if="selectedBudgetCurrentError"
            id="budget-current-help"
            class="p-error"
          >
            {{ lang.validateNoDetailSelect }}
          </small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateEnd">{{ lang.budgetYearCompare }}</label>
          <budget-year-auto-complete
            id="budgetYearCompare"
            v-model="selectedBudgetCompare"
            class="inputfield w-full mt-1"
            :class="{ 'p-invalid': selectedBudgetCompareError }"
            @item-select="selectedBudgetCompareError = false"
          />
          <small
            v-if="selectedBudgetCompareError"
            id="budget-compare-help"
            class="p-error"
          >
            {{ lang.validateNoDetailSelect }}
          </small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText
            id="fileNameText"
            type="text"
            class="inputfield w-full mt-1"
            v-model="fileNameText"
          />
        </div>
      </section>
    </transition>
    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
        </div>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import { useStore } from "vuex";
import { ref, computed, onMounted, watch } from "vue";
import useTracking from "../../../hooks/tracking";
import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "toast-request"],
  setup(props, { emit }) {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
        };
      }
    });

    let type = ref("PDF");
    let fileNameText = ref("budget_compare_actual");
    let selectedBudgetCurrent = ref(null);
    let selectedBudgetCompare = ref(null);
    let selectedBudgetCurrentError = ref(false);
    let selectedBudgetCompareError = ref(false);

    onMounted(() => {
      setLoading(true);
      initiateData();
    });

    const title = computed(() => {
      if (store.getters.selectedLanguage === "TH") {
        return props.reportObj.nameThai;
      } else {
        return props.reportObj.nameEnglish;
      }
    });
    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const userId = computed(() => {
      return store.getters.userId;
    });
    const userName = computed(() => {
      return store.getters.userName;
    });
    const fileType = computed(() => {
      return type.value == "PDF" ? ".pdf" : ".xlsx";
    });
    const fileName = computed(() => {
      return fileNameText.value + fileType.value;
    });
    const payload = computed(() => {
      let payload = {
        ProjectId: selectedProject.value.id,
        FileName: fileName.value,
        JasperPath: props.reportObj.path + fileType.value,
        DocType: type.value,
        UserId: userId.value,
        PrintBy: userName.value,
        RequestId: 0,
        ReportName: "BudgetCompareActual",
        BudgetCurrentId: selectedBudgetCurrent.value
          ? selectedBudgetCurrent.value.id
          : 0,
        BudgetCompareId: selectedBudgetCompare.value
          ? selectedBudgetCompare.value.id
          : 0,
      };
      return payload;
    });

    const setLoading = (bool) => {
      store.dispatch("setLoading", { value: bool });
    };
    const initiateData = () => {
      type.value = "PDF";
      fileNameText.value = "budget_compare_actual";
      selectedBudgetCurrent.value = null;
      selectedBudgetCompare.value = null;
      selectedBudgetCurrentError.value = false;
      selectedBudgetCompareError.value = false;
    };
    const closeDialog = () => {
      emit("close");
      initiateData();
    };
    const validateData = () => {
      let returnStatus = false;
      if (selectedBudgetCurrent.value == null) {
        selectedBudgetCurrentError.value = true;
        returnStatus = true;
      }
      if (selectedBudgetCompare.value == null) {
        selectedBudgetCompareError.value = true;
        returnStatus = true;
      }
      return returnStatus;
    };
    const budgetsDataReady = () => {
      setLoading(false);
    };

    watch(props.reportObj, function () {
      initiateData();
    });

    return {
      lang: mergeLang,
      textFormClass,
      addReportTracking,
      reportPrintOrder,

      selectedProject,
      title,
      type,
      payload,

      fileNameText,
      selectedBudgetCurrent,
      selectedBudgetCompare,
      selectedBudgetCurrentError,
      selectedBudgetCompareError,

      setLoading,
      closeDialog,
      validateData,
      budgetsDataReady,
    };
  },
  methods: {
    async download(typeReport) {
      this.type = typeReport;
      if (this.validateData()) {
        return;
      }

      this.setLoading(true);
      try {
        this.addReportTracking(
          this.payload,
          this.reportObj,
          this.selectedProject,
          this.$route.name
        );
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
  },
};
</script>
