<template>
	<base-toolbar
		:title="lang.productAr"
		:dataButtons="dataButtons"
		@goBack="goBack"
		@gotoSave="confirmSave">
	</base-toolbar>

	<base-dialog
		v-model:visible="showConfirmDialog"
		:titleConfirm="titleConfirmDialog"
		:wordingConfirm="wordingDialog"
		@confirm="confirm"
		@close="close"></base-dialog>

	<base-penalty-rate-step-dialog
		v-model:visible="showPenaltyCalculation"
		@close="showPenaltyCalculation = false"
		@penalty-rate-step="addPenaltyRateStep"
		:openFrom="openFrom"
		:productCode="productName"
		:penaltyCode="penaltyName"
		:dateStartLast="dateStartLast">
	</base-penalty-rate-step-dialog>
	<base-container>
		<div class="surface-card shadow-2 border-round mt-5 mb-5">
			<div class="font-lg pl-4 pt-4">
				<label>{{ this.lang.productAr }}</label>
			</div>

			<!-- <base-card
        :titleHeader="this.lang.titleFineRate"
        :isVisibleExpandButton="false"
        :isHideBackground="true"
        :isVisibleCard="true"
      >
        <PvDataTable
          showGridlines
          :value="products"
          v-model:selection="selectedProduct"
          dataKey="id"
          :loading="isLoading"
          responsiveLayout="scroll"
          class="p-datatable-sm"
          @row-dblclick="SelectedAddress()"
          selectionMode="single"
          :scrollable="true"
          scrollHeight="200px"
        >
          <PvColumn
            field="code"
            :header="lang.columnCode"
            style="min-width: 4rem"
            headerClass="h-center"
          ></PvColumn>

          <PvColumn
            field="nameThai"
            :header="lang.columnName"
            style="min-width: 4rem"
            headerClass="h-center"
          ></PvColumn>

          <PvColumn
            field="paidPriority"
            :header="lang.columnPaidPriority"
            style="min-width: 4rem"
            headerClass="h-center"
          ></PvColumn>

          <PvColumn
            field="price"
            :header="lang.columnPrice"
            style="min-width: 4rem"
            headerClass="h-center"
          ></PvColumn>

          <PvColumn
            field="isCalculatePenalty"
            :header="lang.columnPenalty"
            style="min-width: 4rem"
            headerClass="h-center"
          ></PvColumn>

          <PvColumn
            style="min-width: 4rem"
            headerClass="h-center"
            bodyStyle="text-align: center; overflow: visible"
            :header="lang.columnEdit"
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0"
                icon="pi pi-pencil"
                @click="gotoEdit(slotProps.data.receiptId)"
              ></PvButton>
            </template>
          </PvColumn>
        </PvDataTable>
      </base-card> -->

			<base-card
				:titleHeader="this.lang.titlePenaltyProduct"
				:isVisibleExpandButton="false"
				:isHideBackground="true"
				:isVisibleCard="true">
				<section class="flex flex-wrap">
					<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
						<label>{{ lang.productMethodCode }}</label>
						<PvInputText
							type="text"
							v-model="productMethodCode"
							class="inputfield w-full mt-1 disableInputText"
							:placeholder="lang.validateProductMethodCode"
							readonly />
						<!-- <base-product-auto-complete
              id="productMethodCode"
              v-model="productMethodCode"
              class="inputfield w-full mt-1"
              :textPlaceholder="lang.validateProductMethodCode"
            /> -->
					</div>
					<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
						<label>{{ lang.penaltyMethodCode }}</label>
						<PvInputText
							type="text"
							v-model="penaltyMethodCode"
							class="inputfield w-full mt-1 disableInputText"
							:placeholder="lang.validatePenaltyMethodCode"
							readonly />
						<!-- <base-penalty-auto-complete
              id="penaltyMethodCode"
              v-model="penaltyMethodCode"
              class="inputfield w-full mt-1"
              :textPlaceholder="lang.validatePenaltyMethodCode"
            /> -->
					</div>
				</section>

				<div class="flex justify-content-between align-items-center flex flex-wrap mt-5">
					<h2 class="font-lg font-weight-400">
						{{ lang.titleFineRate }}
					</h2>

					<PvButton
						class="p-button-outlined p-button-primary mr-2"
						@click="openPenaltyRateStep">
						<span class="">{{ this.lang.addPenaltyRateStep }}</span>
					</PvButton>
				</div>
				<PvDivider />

				<div>
					<PvDataTable
						:value="details"
						responsiveLayout="scroll"
						dataKey="id"
						:loading="isLoading"
						editMode="cell"
						class="editable-cells-table font-weight-400"
						@cell-edit-complete="onCellEditComplete"
						selectionMode="single">
						<template #empty>
						<div class="w-full p-3" Style="text-align: center;">
							<label>{{ lang.tableEmpty }}</label>
						</div>
						</template>
						<PvColumn
							field="itemNumber"
							:header="lang.itemNo"
							style="width: 15%"
							headerClass="h-center "
							bodyClass="text-center"></PvColumn>
						<PvColumn
							field="dateStart"
							:header="lang.columndateStart"
							style="width: 35%"
							headerClass="h-center "
							bodyClass="text-center">
							<template #editor="{ data, field }">
								<PvInputNumber
									v-model.number="data[field]"
									@focus="$event.target.select()" />
							</template>
						</PvColumn>
						<PvColumn
							field="penaltyRate"
							:header="lang.columnpenaltyRate"
							style="width: 35%"
							headerClass="h-center "
							bodyClass="text-center">
							<template #editor="{ data, field }">
								<PvInputNumber
									v-model.number="data[field]"
									mode="decimal"
									:minFractionDigits="2"
									:maxFractionDigits="2"
									placeholder=".00"
									@focus="$event.target.select()" />
							</template>
						</PvColumn>
						<PvColumn
							style="width: 15%"
							headerClass="h-center"
							bodyStyle="text-align: center; overflow: visible">
							<template #body="slotProps">
								<!-- <PvButton
                  type="button"
                  class="p-0"
                  style="background: white; border: white"
                  @click="DeleteDataDummy(slotProps.data.id)"
                  v-if="slotProps.data.id.includes('dummy')"
                >
                  <icon-delete iconColor="error" />
                </PvButton> -->
								<PvButton
									v-if="slotProps.data.id.includes('dummy')"
									type="button"
									class="p-button-danger p-button-text p-0"
									@click="DeleteDataDummy(slotProps.data.id)">
									<icon-delete iconColor="error" />
								</PvButton>

								<!-- <PvButton
                  type="button"
                  class="p-0"
                  style="background: white; border: white"
                  @click="confirmDelete(slotProps.data.id)"
                  v-else
                >
                  <icon-trash-delete-bin iconColor="error" />
                </PvButton> -->
								<PvButton
									v-else
									type="button"
									class="p-button-danger p-button-text p-0"
									@click="confirmDelete(slotProps.data.id)">
									<icon-trash-delete-bin iconColor="error" />
								</PvButton>
							</template>
						</PvColumn>
					</PvDataTable>
				</div>
			</base-card>
			<br />
			<br />
			<br />
			<br />
		</div>
	</base-container>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../../hooks/lang.js";
// import BaseProductAutoComplete from "../../../components/master/product/BaseProductAutoComplete.vue";
// import BasePenaltyAutoComplete from "../../../components/master/product/BasePenaltyAutoComplete.vue";
import BasePenaltyRateStepDialog from "../../../components/master/product/BasePenaltyRateStepDialog.vue";
export default {
	props: ["productId", "productTypeId", "projectCode"],
	components: {
		// BaseProductAutoComplete,
		// BasePenaltyAutoComplete,
		BasePenaltyRateStepDialog,
	},
	setup() {
		const { lang } = useLang();
		const store = useStore();
		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return {
					...lang.th.base,
					...lang.th.master.product,
				};
			} else {
				return {
					...lang.en.base,
					...lang.en.master.product,
				};
			}
		});

		return {
			lang: mergeLang,
		};
	},
	data() {
		return {
			dataButtons: [
				{
					id: "saveCaption",
					caption: this.lang.saveCaption,
					class: "mr-3 p-button-toolbar",
					icon: "",
					iconColor: "",
					action: "gotoSave",
					isShow: true,
				},
			],

			showConfirmDialog: false,
			wordingDialog: "",
			showConfirmDialogName: "",
			titleConfirmDialog: "",

			productMethodCode: null,
			penaltyMethodCode: null,
			details: [],
			penaltyId: "",

			isLoading: true,
			selectedProduct: null,
			openFrom: "",
			showPenaltyCalculation: false,
		};
	},
	async created() {
		await this.$store.dispatch("shared/getAllProductARs");
		// await this.$store.dispatch("masterProduct/resetProductAR");
		// await this.GetProduct();
		await this.GetPenaltyRateStep();
		this.setData();
		this.isLoading = false;
	},
	methods: {
		setLoading(bool) {
			this.$store.dispatch("setLoading", { value: bool });
		},
		openPenaltyRateStep() {
			// console.log("details=", this.details);
			const today = new Date();
			this.openFrom = today.getSeconds();
			this.showPenaltyCalculation = true;
		},
		addPenaltyRateStep(data) {
			let detail = {
				id: "dummy" + (parseInt(this.details.length) + 1),
				itemNumber: parseInt(this.details.length) + 1,
				dateStart: data.dateStart,
				penaltyRate: data.penaltyRate,
			};
			this.details.push(detail);
			// console.log("this.penaltyRateStep=", this.penaltyRateStep);
		},
		goBack() {
			this.titleConfirmDialog = this.lang.validategoBackTitle;
			this.showConfirmDialogName = "goBack";
			if (this.checkDatagoBack()) {
				this.wordingDialog = `${this.lang.validategoBackEdit.replace(
					":title",
					this.lang.titleFineRate
				)}`;
				this.showConfirmDialog = true;
			} else {
				this.$router.push({name: "ar-product"})
			}
		},
		setData() {
			this.productMethodCode = this.productName;
			this.penaltyMethodCode = this.penaltyName;
			this.details = this.penaltyRateStep;
		},

		// async GetProduct() {
		//   try {
		//     let where = {
		//       projectId: this.selectedProject.id,
		//       // IsCalculatePenalty: "Y",
		//     };
		//     const payload = {
		//       page: 1,
		//       limit: 100,
		//       where,
		//     };
		//     await this.$store.dispatch("masterProduct/getAllProductARs", payload);
		//   } catch (error) {
		//     this.$toast.add({
		//       severity: "error", // severity:'success',severity:'info',severity:'warn',severity:'error'
		//       summary: this.lang.notificationDialogHeader, //TitleHeader
		//       detail: error,
		//       life: 5000, //TimeClose
		//     });
		//   }
		// },

		async GetPenaltyRateStep() {
			try {
				let where = {
					projectId: this.selectedProject.id,
					productId: this.productId,
				};
				const payload = {
					page: 1,
					limit: 100,
					where,
				};
				await this.$store.dispatch("masterProduct/getPenaltyRateStep", payload);
			} catch (error) {
				this.$toast.add({
					severity: "error", // severity:'success',severity:'info',severity:'warn',severity:'error'
					summary: this.lang.notificationDialogHeader, //TitleHeader
					detail: error,
					life: 5000, //TimeClose
				});
			}
		},
		onCellEditComplete(event) {
			// console.log("event=", event);
			let value = event.value;
			let newValue = event.newValue;
			let index = event.index;
			if (typeof value === "string") {
				value = value.trim();
			}
			if (typeof newValue === "string") {
				newValue = newValue.trim();
			}
			if (value === newValue) {
				return;
			}
			if (event.field === "dateStart") {
				if (newValue <= 0 || !newValue) {
					return;
				}
				if (index === 0 && newValue !== 1) {
					this.$toast.add({
						severity: "warn", // severity:'success',severity:'info',severity:'warn',severity:'error'
						summary: this.lang.notificationDialogHeader, //TitleHeader
						detail: this.lang.validateDataStartFirst,
						life: 5000, //TimeClose
					});
					event.data.dateStart = 1;
				} else {
					let checkDateStart = this.checkDateStart(index, newValue);
					if (!checkDateStart) {
						event.data.dateStart = newValue;
					}
				}
			}

			if (event.field === "penaltyRate") {
				if (newValue < 0 || !newValue) {
					return;
				}
				event.data.penaltyRate = newValue;
			}
		},
		checkDateStart(i, data) {
			let detailsTotal = this.details.length - 1;
			// console.log("detailsTotal=",detailsTotal);
			if (detailsTotal === i) {
				let dateBefore = this.details[i - 1].dateStart;
				if (dateBefore >= data) {
					this.$toast.add({
						severity: "warn", // severity:'success',severity:'info',severity:'warn',severity:'error'
						summary: this.lang.notificationDialogHeader, //TitleHeader
						detail: this.lang.validateDataStartOver,
						life: 5000, //TimeClose
					});
					return true;
				}
			} else {
				let dateBefore = this.details[i - 1].dateStart;
				let dateAfter = this.details[i + 1].dateStart;

				if (dateBefore >= data) {
					this.$toast.add({
						severity: "warn", // severity:'success',severity:'info',severity:'warn',severity:'error'
						summary: this.lang.notificationDialogHeader, //TitleHeader
						detail: this.lang.validateDataStartOver,
						life: 5000, //TimeClose
					});
					return true;
				}
				if (dateAfter <= data) {
					this.$toast.add({
						severity: "warn", // severity:'success',severity:'info',severity:'warn',severity:'error'
						summary: this.lang.notificationDialogHeader, //TitleHeader
						detail: this.lang.validateDataStartLow,
						life: 5000, //TimeClose
					});
					return true;
				}
			}
			return false;
		},
		confirmSave() {
			this.showConfirmDialogName = "confirmSave";
			this.titleConfirmDialog = this.lang.confirmEditSave;
			this.wordingDialog = `${this.lang.askForEditDocument.replace(
				":title",
				this.lang.titleFineRate
			)}`;
			this.showConfirmDialog = true;
		},
		confirmDelete(id) {
			this.penaltyId = id;
			this.showConfirmDialogName = "confirmDelete";
			this.titleConfirmDialog = this.lang.titleConfirmDelete;
			this.wordingDialog = `${this.lang.askForDeleteDataDetail.replace(
				":title",
				this.lang.titleFineRate
			)}`;
			this.showConfirmDialog = true;
		},

		confirm() {
			this.showConfirmDialog = false;
			if (this.showConfirmDialogName === "confirmSave") {
				this.saveData();
			} else if (this.showConfirmDialogName === "confirmDelete") {
				this.DeleteData();
			} else if (this.showConfirmDialogName === "goBack") {
				this.$router.push({name: "ar-product"})
			}
		},
		close() {
			this.showConfirmDialog = false;
		},
		async saveData() {
			this.setLoading(true);
			try {
				let details = [];
				this.details.forEach((data) => {
					let detail = null;
					if (data.id.includes("dummy")) {
						detail = { ...data, id: null };
					} else {
						detail = { ...data };
					}
					details.push(detail);
				});

				let payload = {
					ProjectId: this.selectedProject.id,
					ProductId: this.productId,
					PenaltyRateStep: details,
				};

				// console.log("payload=", payload);
				await this.$store.dispatch("masterProduct/AddEditPenaltyRateStep", payload);
				this.$toast.add({
					severity: "success", // severity:'success',severity:'info',severity:'warn',severity:'error'
					summary: this.lang.notificationDialogHeader, //TitleHeader
					detail: this.lang.saveSuccess,
					life: 5000, //TimeClose
				});
				this.details = this.penaltyRateStep;
			} catch (error) {
				this.$toast.add({
					severity: "error", // severity:'success',severity:'info',severity:'warn',severity:'error'
					summary: this.lang.notificationDialogHeader, //TitleHeader
					detail: error,
					life: 5000, //TimeClose
				});
			}
			this.setLoading(false);
		},
		async DeleteData() {
			this.setLoading(true);
			try {
				if (this.penaltyId.includes("dummy")) {
					return true;
				}

				let payload = {
					ProjectId: this.selectedProject.id,
					ProductId: this.productId,
					PenaltyId: this.penaltyId,
				};

				// console.log("payload=", payload);
				await this.$store.dispatch("masterProduct/DeletePenaltyRateStep", payload);
				this.$toast.add({
					severity: "success", // severity:'success',severity:'info',severity:'warn',severity:'error'
					//summary: this.lang.notificationDialogHeader, //TitleHeader
					detail: this.lang.deleteSuccess,
					life: 5000, //TimeClose
				});
				this.DeleteDataDummy(this.penaltyId);
			} catch (error) {
				this.$toast.add({
					severity: "error", // severity:'success',severity:'info',severity:'warn',severity:'error'
					summary: this.lang.notificationDialogHeader, //TitleHeader
					detail: error,
					life: 5000, //TimeClose
				});
			}
			this.setLoading(false);
		},
		DeleteDataDummy(id) {
			const index = this.details.findIndex((detail) => detail.id == id);
			this.details.splice(index, 1);
			this.updateItemNumber();
		},
		updateItemNumber() {
			let number = 1;
			this.details.forEach((element) => {
				// console.log("element", element.accountId);
				element.itemNumber = number;
				number++;
			});
		},
		checkDatagoBack() {
			let editdata = false;
			this.details.forEach((data) => {
				if (data.id.includes("dummy")) {
					editdata = true;
					return true;
				}
			});
			if (editdata) {
				return true;
			}

			return false;
		},
	},
	computed: {
		selectedProject() {
			return this.$store.getters.selectedProject;
		},
		// products() {
		//   return this.$store.getters["masterProduct/productArProducts"];
		// },
		penaltyRateStep() {
			let itemNumber = [];
			let i = 1;
			const penaltyRateStep = this.$store.getters["masterProduct/penaltyRateStep"];
			if (penaltyRateStep) {
				penaltyRateStep.forEach((element) => {
					// element = { ...element, ...{ itemNumber: i } };
					let data = {
						id: element.id,
						itemNumber: i,
						dateStart: element.dateStart,
						penaltyRate: element.penaltyRate,
					};
					itemNumber.push(data);
					i++;
				});
			}
			return itemNumber;
		},
		productName() {
			if (this.productAr) {
				let productAr = this.productAr.find((data) => data.id === this.productId);
				if (productAr) {
					return productAr.code + ":" + productAr.nameThai;
				}
			}
			return "";
		},
		penaltyName() {
			if (this.productAr) {
				let productAr = this.productAr.find((data) => data.id === this.productTypeId);
				if (productAr) {
					return productAr.code + ":" + productAr.nameThai;
				}
			}
			return "";
		},

		productAr() {
			return this.$store.getters["shared/productArProducts"];
		},
		dateStartLast() {
			if (this.details) {
				let detailsTotal = this.details.length;
				if (detailsTotal == 0) {
					return 0;
				} else {
					let dateLast = this.details[detailsTotal - 1].dateStart;
					return dateLast;
				}
			} else return 0;
		},
	},
};
</script>

<style scoped>
.font-weight-400 {
	font-weight: 400 !important;
}

.p-datatable .p-datatable-thead > tr > th {
	font-weight: 400 !important;
}
</style>
