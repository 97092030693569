export default {
	setPolicy(state, payload) {
		let policies = [...state.policy];
		for (const policy of payload.data) {
			if (policies.findIndex((c) => c.id === policy.id) < 0) {
				policies.push(policy);
			}
		}
		state.policy = policies;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
	setSelectedPolicy(state, payload) {
		state.selectedPolicy = payload;
	},
	resetPolicy(state) {
		state.policy = [];
		state.totalPage = 0;
		state.currentPage = 0;
		// state.selectedPolicy = null;
	},
	resetSelectedPolicy(state) {
		state.selectedPolicy = null;
	},
};