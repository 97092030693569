export default {
    banks(state) {
      return state.bank.banks;
    },
    bankCurrentPage(state) {
      return state.bank.currentPage; 
    },
    bankNextPage(state) {
      return state.bank.currentPage + 1;
    },
    bankTotalPage(state) {
      return state.bank.totalPage;
    },
    bankIsLastPage(_, getters) {
      return (getters.bankCurrentPage === getters.bankTotalPage) && (getters.bankTotalPage !== 0);
    }
};
  