<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '70vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="titleHeaderImportData"
      :wordingConfirm="titleValidateImportData"
      :dataButtons="dataButtonDialog"
      @close="showValidateDialog = false"
    ></base-dialog>

    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">{{ lang.uploadFile }}</label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          :disabled="shouldDisableCloseButton"
          @click="closeDialog"
        />
      </div>
    </template>

    <div class="dropZone">
      <input
        type="file"
        id="dropZone-file"
        class="dropZone-file"
        ref="dropZoneFile"
        @click="resetFile"
        @change="handleFiles"
        multiple
        accept=".xlsx"
      />
      <div
        class="dropZone-wrapper"
        @dragenter.prevent=""
        @dragover.prevent=""
        @drop.prevent="handleFiles"
      >
        <label for="dropZone-file" class="cursor-pointer">
          <div class="pt-6"></div>
          <PvButton
            class="cursor-pointer p-button-rounded show-icon"
            disabled="disabled"
          >
            <icon-cloud-storage-upload iconColor="white" />
          </PvButton>
          <div class="cursor-pointer dropZone-label-text pt-4">
            <label
              for="dragFile"
              class="cursor-pointer font-md p-text-secondary"
              >{{ lang.dragFile }}</label
            >
            <label
              for="addFile"
              class="cursor-pointer font-md p-text-primary"
              @click="addFile"
              >{{ lang.addFile }}</label
            >
            <input
              id="add-file"
              type="file"
              style="display: none"
              accept=".xlsx"
              @click="resetFile"
              @change="handleFiles"
              class="cursor-pointer"
            />
          </div>
          <div class="cursor-pointer font-xs p-text-secondary pt-2 pb-8">
            <label for="importFileDesc" class="cursor-pointer">{{
              lang.importFileDesc
            }}</label>
            <label
              for="downloadfile"
              class="cursor-pointer p-text-primary"
              @click="downloadfile"
              >{{ lang.downloadfile }}</label
            >
          </div>
        </label>
      </div>
    </div>

    <div class="my-5 pt-6">
      <PvDataTable
        :value="files"
        dataKey="id"
        responsiveLayout="scroll"
        class="p-datatable-xs"
        :class="textTableClass"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn style="width: 87px; height: 56px" headerClass="h-center">
          <template #body>
            <PvButton
              v-if="isOpen"
              type="button"
              style="background: white; border: white; opacity: 1"
              disabled="disabled"
            >
              <icon-documents-file-checkmark iconColor="secondary" />
            </PvButton>
          </template>
        </PvColumn>

        <PvColumn
          field="detailName"
          :header="lang.fileName"
          style="min-width: 12rem"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-left font-normal p-text-primary"
        >
        </PvColumn>

        <PvColumn
          style="width: 2rem"
          headerClass="h-center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="">
            <PvButton
              v-if="isOpen"
              type="button"
              class="p-button-danger"
              style="background: white; border: white"
              @click="deleteFile"
            >
              <icon-trash-delete-bin iconColor="error" />
            </PvButton>
          </template>
        </PvColumn>
      </PvDataTable>
    </div>

    <div class="flex justify-content-center pt-4">
      <PvButton class="w-3" @click="uploadFile" :disabled="checkDisable">
        <span class="w-full text-center font-md font-normal p-button-label">
          <i
            v-if="isUpLoading"
            class="pi pi-spin pi-spinner font-normal mr-1"
          ></i>
          {{ lang.uploadFile }}
        </span>
      </PvButton>
    </div>
    <template #footer></template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, ref, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import useImportExcel from "../../../hooks/importExcel.js";
import useGetImportExistSupplier from "../../../hooks/getImportExistSupplier.js";
import useGetDbdBusinessType from "../../../hooks/getDbdBusinessType";
import useGetAddress from "../../../hooks/getAddress";
import useGetCountryCode from "../../../hooks/getCountryCode";
import useGetBank from "../../../hooks/getBank";
export default {
  emits: ["close", "data-upload"],
  setup(props, context) {
    const { lang } = useLang();
    const { textTableClass } = useTextStyle();
    const { formatSubdistrict } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.supplier.edit,
          ...lang.th.master.supplier.import,
          ...lang.th.validateImport,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.supplier.edit,
          ...lang.en.master.supplier.import,
          ...lang.en.validateImport,
        };
      }
    });

    const router = useRouter();

    const {
      validateFileCount,
      validateFileType,
      readFileContent,
      validatePrefix,
      validateAddress,
      validateAddressInDatabase,
      validateAddressRelationship,
      validateCountryCode,
    } = useImportExcel();
    const {
      setImportExistSupplierParams,
      loadImportExistSupplier,
      getImportExistSupplier,
    } = useGetImportExistSupplier();
    const { dbdBusinessType, isSharedDbdBusinessTypeReady, getAllDbdBusinessType } =
      useGetDbdBusinessType();
    const { banks, isSharedBankReady } = useGetBank();
    const { isSharedAddressReady, getAllAddress } = useGetAddress();
    const { isSharedCountryCodeReady, getAllCountryCode } = useGetCountryCode();

    onMounted(async () => {
      await getAllDbdBusinessType();
      await getAllAddress();
      await getAllCountryCode();
    });

    //params
    let files = ref([]);
    let isOpen = ref(false);
    let file = ref(null);
    let rawDatas = ref([]);
    let isUpLoading = ref(false);
    let uploadFiles = ref([]);
    let titleHeaderImportData = ref("");
    let titleValidateImportData = ref("");
    let showValidateDialog = ref(false);
    let supplierCodeImportSuppliers = ref([]);
    let supplierTaxIdImportSuppliers = ref([]);
    let genders = ref(["บริษัท/ห้างร้าน", "ชาย", "หญิง"]);

    //computeds
    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const shouldDisableCloseButton = computed(() => {
      return isUpLoading.value;
    });
    const checkDisable = computed(() => {
      if (!file.value || isUpLoading.value) {
        return true;
      }
      return false;
    });
    const dataButtonDialog = computed(() => {
      let result = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
      return result;
    });

    //methods
    const setLoading = (bool) => {
      isUpLoading.value = bool;
    };

    const initiateData = () => {
      files.value = [];
      isOpen.value = false;
      file.value = null;
      rawDatas.value = [];
      uploadFiles.value = [];
      titleHeaderImportData.value = mergeLang.value.notificationDialogHeader;
      titleValidateImportData.value = "";
      supplierCodeImportSuppliers.value = [];
      supplierTaxIdImportSuppliers.value = [];
    };

    const closeDialog = () => {
      context.emit("close");
      initiateData();
    };

    const resetFile = (event) => {
      if (event.target.files.length > 0) {
        event.target.value = null;
      }
    };

    const addFile = () => {
      document.getElementById("add-file").click();
    };

    const deleteFile = () => {
      initiateData();
    };

    const handleFiles = async (event) => {
      const inputValue = event.target.files || event.dataTransfer.files;
      uploadFiles.value = Array.from(inputValue);

      const checkFileCount = validateFileCount(uploadFiles.value, 1);
      if (checkFileCount.isError) {
        titleHeaderImportData.value = mergeLang.value.validateLimitFile.replace(
          ":number",
          1
        );
        titleValidateImportData.value =
          mergeLang.value.validateFileType.replace(
            ":name",
            checkFileCount.message
          );
        showValidateDialog.value = true;
        return;
      }

      file.value = inputValue[0];
      const checkFileType = validateFileType(file.value);
      if (checkFileType.isError) {
        titleHeaderImportData.value = mergeLang.value.validateImportFile;
        titleValidateImportData.value =
          mergeLang.value.validateFileType.replace(
            ":name",
            checkFileType.message
          );
        showValidateDialog.value = true;
        file.value = null;
        return;
      }

      isOpen.value = true;
      const fileName = file.value.name;
      files.value = [
        {
          detailName: fileName,
        },
      ];
    };

    const downloadfile = async () => {
      setLoading(true);
      await getImportSupplierForm();
      setLoading(false);
    };

    const getImportSupplierForm = async () => {
      try {
        let payload = {
          key: process.env.VUE_APP_IMPORT_SUPPLIER_TEMPLATE_S3_KEY,
          name: "template_import_supplier.xlsx",
        };
        await store.dispatch("upload/downloadFile", payload);
      } catch (error) {
        titleHeaderImportData.value = mergeLang.value.notificationDialogHeader;
        titleValidateImportData.value = error;
        showValidateDialog.value = true;
        setLoading(false);
      }
    };

    const uploadFile = async () => {
      setLoading(true);
      if (file.value) {
        let condition = [
          {
            name: "code",
            type: "string",
            wordLength: 25,
            isRequire: true,
            isUnique: true,
          },
          {
            name: "name",
            type: "string",
            wordLength: 100,
            isRequire: true,
          },
          {
            name: "taxId",
            type: "taxId",
            wordLength: 20,
            isUnique: true,
          },
          {
            name: "gender",
            type: "string",
            wordLength: 100,
            isRequire: true,
          },
          {
            name: "dbdBusinessType",
            type: "string",
            wordLength: 500,
          },
          {
            name: "address1",
            type: "string",
            wordLength: 100,
          },
          {
            name: "addressSubDistrict", //address2
            type: "string",
            wordLength: 50,
          },
          {
            name: "addressDistrict", //address2
            type: "string",
            wordLength: 50,
          },
          {
            name: "address3",
            type: "string",
            wordLength: 100,
          },
          {
            name: "postCode",
            type: "string",
            wordLength: 10,
          },
          {
            name: "telephoneCountryCode",
            type: "string",
            wordLength: 25,
          },
          {
            name: "telephoneNumber",
            type: "string",
            wordLength: 50,
          },
          {
            name: "mobileCountryCode",
            type: "string",
            wordLength: 25,
          },
          {
            name: "mobileNumber",
            type: "string",
            wordLength: 100,
          },
          {
            name: "faxNumber",
            type: "string",
            wordLength: 50,
          },
          {
            name: "email",
            type: "string",
            wordLength: 100,
          },
          {
            name: "emergencyContactPrefix",
            type: "string",
            wordLength: 100,
          },
          {
            name: "emergencyContactName",
            type: "string",
            wordLength: 100,
          },
          {
            name: "emergencyCountryCode",
            type: "string",
            wordLength: 25,
          },
          {
            name: "emergencyContactMobile",
            type: "string",
            wordLength: 100,
          },
          {
            name: "supplierCreditTerm",
            type: "integer",
            wordLength: 5,
          },
          {
            name: "bankAccountNumber",
            type: "string",
            wordLength: 100,
          },
          {
            name: "withholdingTaxRate",
            type: "decimal",
          },
          {
            name: "bank",
            type: "string",
            wordLength: 100,
          },
          {
            name: "vatRate",
            type: "decimal",
          },
        ];
        const result = await readFileContent(file.value, 2, 30000, condition);
        if (!result.success) {
          if (result.code == "001") {
            titleHeaderImportData.value =
              mergeLang.value.notificationDialogHeader;
            titleValidateImportData.value =
              mergeLang.value.validateImportNoData.replace(
                ":file",
                file.value.name
              );
          } else if (result.code == "002") {
            titleHeaderImportData.value =
              mergeLang.value.notificationDialogHeader;
            titleValidateImportData.value =
              mergeLang.value.validateImportMaxColumn.replace(
                ":maxColumn",
                "30,000"
              );
          } else {
            titleHeaderImportData.value =
              mergeLang.value.notificationDialogHeader;
            titleValidateImportData.value = result.message;
          }
          setLoading(false);
          showValidateDialog.value = true;
          return true;
        }
        rawDatas.value = result.data;
        validateAndPrepareData();
      } else {
        titleHeaderImportData.value = mergeLang.value.notificationDialogHeader;
        titleValidateImportData.value = mergeLang.value.validateAddfile;
        showValidateDialog.value = true;
        return true;
      }
    };

    const validateAndPrepareData = async () => {
      await getSupplierCodes();
      await getSupplierTaxIds();
      await checkShouldPrepareData();
    };

    const getSupplierCodes = async () => {
      setImportExistSupplierParams(rawDatas.value, "code");
      await loadImportExistSupplier();
      supplierCodeImportSuppliers.value = getImportExistSupplier();

      // console.log("supplierCodeImportSuppliers.value", supplierCodeImportSuppliers.value);
    };
    const getSupplierTaxIds = async () => {
      setImportExistSupplierParams(rawDatas.value, "taxId");
      await loadImportExistSupplier("taxId");
      supplierTaxIdImportSuppliers.value = getImportExistSupplier();

      // console.log("supplierTaxIdImportSuppliers.value", supplierTaxIdImportSuppliers.value);
    };

    const prepareData = async () => {
      let dataResult = [];
      let i = 0;
      for (const data of rawDatas.value) {
        let result = await mappingData(data);
        if (result) {
          i++;
          result = { ...result, id: i, itemNo: i };
          dataResult.push(result);
        }
      }

      // console.log("dataResult", dataResult);

      setLoading(false);
      await store.dispatch("importSupplier/importSuppliers", dataResult);
      context.emit("data-upload");
      router.push({ name: "preview-supplier" });
      initiateData();
    };

    const mappingData = async (data) => {
      // console.log("mapingData", data);
      data.importStatusCode = "wait";
      data.importStatusReason = "";
      data.importStatusDescription = "";
      data.markStatus = "";

      //เช็คข้อมูล supplier code
      if (data.code) {
        let supplierCode = supplierCodeImportSuppliers.value.filter(
          (supplier) => supplier.code.trim() == data.code.trim()
        )[0];

        if (supplierCode) {
          let error = {
            field: "code",
            type: "duplicateInDatabase",
            data: data.code,
          };
          data.error.push(error);
        }
      }

      //เช็คข้อมูล supplier tax id
      if (
        data.taxId &&
        data.taxId.trim() != "-" &&
        data.taxId.trim() != "0000000000000"
      ) {
        let supplierTaxId = supplierTaxIdImportSuppliers.value.filter(
          (supplier) => supplier.taxId.trim() == data.taxId.trim()
        )[0];

        if (supplierTaxId) {
          let error = {
            field: "taxId",
            type: "duplicateInDatabase",
            data: data.taxId,
          };
          data.error.push(error);
        }
      }

      //เช็คข้อมูล dbd business type
      let dbdBusinessTypeId = null;
      if (data.dbdBusinessType) {
        let businessType = dbdBusinessType.value.filter(
          (item) => item.nameDbd.trim() == data.dbdBusinessType.trim()
        )[0];

        if (!businessType) {
          let error = {
            field: "dbdBusinessType",
            type: "doNotExist",
            data: data.dbdBusinessType,
          };
          data.error.push(error);
        } else {
          dbdBusinessTypeId = businessType.id;
        }
      }

      if (data.gender) {
        if (!genders.value.includes(data.gender)) {
          let errorObj = {
            field: "gender",
            type: "doNotExist",
            data: data.gender,
          };
          data.error.push(errorObj);
        }
      }

      let addressForCheck = [
        {
          subdistrict: data.addressSubDistrict,
          district: data.addressDistrict,
          province: data.address3,
          postCode: data.postCode,
          field: "address",
        },
        // {
        //   province: data.address3,
        //   district: data.addressDistrict,
        //   subDistrict: data.addressSubDistrict,
        //   postCode: data.address1,
        //   field: "address"
        // },
      ];
      let addressIncomplete = validateAddress(addressForCheck);
      addressIncomplete.forEach((item) => {
        if (item.isError) {
          let errorObj = {
            field: item.field,
            type: "incompleteData",
            data: "",
          };
          data.error.push(errorObj);
        } else if (
          !item.isError &&
          item.subdistrict != "" &&
          item.district != "" &&
          item.province != "" &&
          item.postCode != ""
        ) {
          let addressDatabase = validateAddressInDatabase(item);
          if (addressDatabase.isError) {
            if (!addressDatabase.subdistrict) {
              let errorObj = {
                field: "addressSubDistrict",
                type: "doNotExist",
                data: data.addressSubDistrict,
              };
              data.error.push(errorObj);
            }
            if (!addressDatabase.district) {
              let errorObj = {
                field: "addressDistrict",
                type: "doNotExist",
                data: data.addressDistrict,
              };
              data.error.push(errorObj);
            }
            if (!addressDatabase.province) {
              let errorObj = {
                field: "address3",
                type: "doNotExist",
                data: data.address3,
              };
              data.error.push(errorObj);
            }
            if (!addressDatabase.postCode) {
              let errorObj = {
                field: "postCode",
                type: "doNotExist",
                data: data.postCode,
              };
              data.error.push(errorObj);
            }
          } else {
            let addressRelationship = validateAddressRelationship(item);
            if (!addressRelationship) {
              let errorObj = {
                field: item.field,
                type: "invalidAddress",
                data: "",
              };
              data.error.push(errorObj);
            } else {
              data.address2 = formatSubdistrict(item);
            }
          }
        }
      });

      //เช็คข้อมูล country code
      if (data.telephoneCountryCode) {
        let telCountryCode = validateCountryCode(data.telephoneCountryCode);
        if (!telCountryCode) {
          let errorObj = {
            field: "telephoneCountryCode",
            type: "doNotExist",
            data: data.telephoneCountryCode,
          };
          data.error.push(errorObj);
        }
      }
      //เช็คข้อมูล country code
      if (data.mobileCountryCode) {
        let mobileCountryCode = validateCountryCode(data.mobileCountryCode);
        if (!mobileCountryCode) {
          let errorObj = {
            field: "mobileCountryCode",
            type: "doNotExist",
            data: data.mobileCountryCode,
          };
          data.error.push(errorObj);
        }
      }
      //เช็คข้อมูล prefix
      if (data.emergencyContactPrefix) {
        let prefix = validatePrefix(data.emergencyContactPrefix);
        if (!prefix) {
          let error = {
            field: "emergencyContactPrefix",
            type: "doNotExist",
            data: data.emergencyContactPrefix,
          };
          data.error.push(error);
        }
      }
      //เช็คข้อมูล country code
      if (data.emergencyCountryCode) {
        let erCountryCode = validateCountryCode(data.emergencyCountryCode);
        if (!erCountryCode) {
          let errorObj = {
            field: "emergencyCountryCode",
            type: "doNotExist",
            data: data.emergencyCountryCode,
          };
          data.error.push(errorObj);
        }
      }
      //เช็คข้อมูล bank
      if (data.bank) {
        let bankData = banks.value.filter(
          (bank) => bank.name.trim() == data.bank.trim()
        )[0];

        if (!bankData) {
          let error = {
            field: "bank",
            type: "doNotExist",
            data: data.bank,
          };
          data.error.push(error);
        }
      }

      if (data.error.length > 0) {
        data.importStatusCode = "invalid";
        data.importStatusDescription = data.error[0].field;
        let errorType = data.error[0].type;

        switch (data.error[0].type) {
          case "blank":
            errorType = mergeLang.value.validateBlank.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
          case "duplicate":
            errorType = mergeLang.value.validateDuplicateInFile.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
          case "duplicateInDatabase":
            errorType = mergeLang.value.validateDuplicateInDatabase
              .replace(":field", setFieldLangError(data.error[0].field))
              .replace(":table", mergeLang.value.createTitle);
            break;
          case "overflow":
            errorType = mergeLang.value.validateLength
              .replace(":field", setFieldLangError(data.error[0].field))
              .replace(":length", data.error[0].wordLength);
            break;
          case "doNotExist":
            errorType = mergeLang.value.validateDataNotFound.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
          case "invalid":
            errorType = mergeLang.value.validateType.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
          case "invalidDecimal":
            errorType = mergeLang.value.validateDecimalFormat.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
          case "format":
            errorType = mergeLang.value.validateDateFormat.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
          case "invalidAddress":
            errorType = mergeLang.value.validateAddress;
            break;
          case "incompleteData":
            errorType = mergeLang.value.validateIncompleteData.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
        }
        data.importStatusReason = errorType;
      }

      data = {
        ...data,
        dbdBusinessTypeId: dbdBusinessTypeId,
        projectId: selectedProject.value.id,
        isCustomer: "N",
        type: "Add",
      };
      // console.log(data);
      return data;
    };

    const setFieldLangError = (field) => {
      switch (field) {
        case "code":
          return mergeLang.value.labelCode;
        case "name":
          return mergeLang.value.labelName;
        case "taxId":
          return mergeLang.value.labelTaxId;
        case "gender":
          return mergeLang.value.labelGender;
        case "dbdBusinessType":
          return mergeLang.value.businessType;
        case "address1":
          return mergeLang.value.labelAddress1;
        case "addressSubDistrict":
          return mergeLang.value.subdistrict;
        case "addressDistrict":
          return mergeLang.value.district;
        case "address3":
          return mergeLang.value.province;
        case "postCode":
          return mergeLang.value.postcode;
        case "telephoneCountryCode":
          return mergeLang.value.countryCode;
        case "telephoneNumber":
          return mergeLang.value.labelTelephoneNumber;
        case "mobileCountryCode":
          return mergeLang.value.countryCode;
        case "mobileNumber":
          return mergeLang.value.labelMobileNumber;
        case "faxNumber":
          return mergeLang.value.labelFaxNumber;
        case "email":
          return mergeLang.value.labelEmail;
        case "emergencyContactPrefix":
          return mergeLang.value.prefix;
        case "emergencyContactName":
          return mergeLang.value.contactName;
        case "emergencyCountryCode":
          return mergeLang.value.contactCountryCode;
        case "emergencyContactMobile":
          return mergeLang.value.contactMobile;
        case "supplierCreditTerm":
          return mergeLang.value.labelSupplierCreditTerm;
        case "bankAccountNumber":
          return mergeLang.value.labelBankAccountNumber;
        case "withholdingTaxRate":
          return mergeLang.value.labelWithholdingTaxRate;
        case "bank":
          return mergeLang.value.labelBank;
        case "vatRate":
          return mergeLang.value.labelVatRate;
        case "address":
          return mergeLang.value.labelAddress1;
        default:
          return field;
      }
    };

    const checkShouldPrepareData = async () => {
      if (
        isSharedAddressReady.value &&
        isSharedDbdBusinessTypeReady.value &&
        isSharedBankReady.value &&
        isSharedCountryCodeReady.value &&
        rawDatas.value.length > 0
      ) {
        await prepareData();
      }
    };

    watch(isSharedAddressReady, function () {
      checkShouldPrepareData();
    });
    watch(isSharedDbdBusinessTypeReady, function () {
      checkShouldPrepareData();
    });
    watch(isSharedBankReady, function () {
      checkShouldPrepareData();
    });
    watch(isSharedCountryCodeReady, function () {
      checkShouldPrepareData();
    });

    return {
      lang: mergeLang,
      textTableClass,

      files,
      isOpen,
      file,
      isUpLoading,
      showValidateDialog,
      titleHeaderImportData,
      titleValidateImportData,

      //computeds
      shouldDisableCloseButton,
      checkDisable,
      dataButtonDialog,

      //methods
      closeDialog,
      uploadFile,
      resetFile,
      handleFiles,
      addFile,
      deleteFile,
      downloadfile,
    };
  },
};
</script>

<style scoped>
.show-icon {
  opacity: 1;
  width: 48px;
  height: 48px;
  padding: 0.75rem;
}
</style>
