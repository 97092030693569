import { ref, onMounted, computed } from 'vue'
import { useStore } from "vuex";
export default function useGetBudgetYear() {
    const store = useStore();
    let date = new Date();
    let years = ref([]);
    let isFilterBudgetActive = ref(false);
    let searchTextYearOfBudget = ref(null);

    const selectedProject = computed(() => {
        return store.getters.selectedProject;
    });
    const budgets = computed(() => {
        return store.getters["budget/budgets"];
    });

    const checkAvailableYearCopy = (targetYear) => {
        const found = years.value.find((year) => year.toLowerCase() === targetYear.toLowerCase());
        return found;
    }

    const concatYear = (year) => {
        return {
            name: "ค.ศ." + year + " (พ.ศ." + (year + 543) + ")",
            value: year
        };
    }

    let isSharedBudgetsReady = ref(false);
    const getYearOfBudgetByProject = async () => {
        await store.dispatch("budget/getBudgets", { projectId: selectedProject.value.id });
    }

    onMounted(() => {
        date.setYear(date.getFullYear() - 6);
        for (let index = 0; index <= 10; index++) {
            date.setYear(date.getFullYear() + 1);
            years.value.push(concatYear(date.getFullYear()));
        }
        // if (budgets.value.length <= 0) {
        //     getYearOfBudgetByProject()
        // }
        // isSharedBudgetsReady.value = true;
    });

    const budgetsApproved = computed(() => {
        let budgetActive = [];
        budgets.value.forEach((element) => {
            if (element.isApproveStatus && element.isApproveStatus.toLowerCase() == "p") {
                let year = concatYear(element.yearOf);
                year.id = element.id;
                budgetActive.push(year);
            }
        });

        return budgetActive;
    });
    const budgetsAll = computed(() => {
        let budgetActive = [];
        budgets.value.forEach((element) => {
            let year = concatYear(element.yearOf);
            year.id = element.id;
            budgetActive.push(year);
        });

        return budgetActive;
    });
    
    const budgetList = computed(() => {
        let results = [];
        let datas = budgets.value;
        if (isFilterBudgetActive.value) {
            datas = budgets.value.filter((budget) => budget.isActive.toLowerCase() == "y");
        }
        datas.forEach((element) => {
            let year = concatYear(element.yearOf);
            year.id = element.id;
            results.push(year);
        });

        return results;
    });

    const getAllBudgets = async () => {
        if (budgets.value.length <= 0) {
            await getYearOfBudgetByProject()
        }
        isSharedBudgetsReady.value = true;
    }

    const filteredYearOfBudgets = computed(() => {
        if (!searchTextYearOfBudget.value) {
          //ช่องว่าง
          const result = [...budgetList.value];
          return result;
        } else {
          return budgetList.value.filter((budget) => {
            return budget.name.toLowerCase()
              .includes(searchTextYearOfBudget.value.toLowerCase());
          });
        }
    });

    const searchYearOfBudget = function (event) {
        // console.log(event);
        if (!event.query.trim().length) {
            //ช่องว่าง
            // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
            searchTextYearOfBudget.value = "";
            searchTextYearOfBudget.value = null;
        } else {
            searchTextYearOfBudget.value = event.query;
        }
    };

    return {
        years,
        checkAvailableYearCopy,
        concatYear,
        budgetsApproved,
        budgetsAll,
        isSharedBudgetsReady,
        getAllBudgets,
        isFilterBudgetActive,
        searchTextYearOfBudget,
        filteredYearOfBudgets,
        searchYearOfBudget,
        budgetList,
    }
}