export default {
  async addNoticeByUnit(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Notice/AddNoticeByUnit";

    const fallbackErrorMessage = "Failed to add notice by unit";
    const token = context.rootGetters.token;
    //  console.log("payload=",payload);

    const response = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    //  console.log("payload=",JSON.stringify(payload));
    const responseData = await response.json();

    if (!response.ok) {
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      let error = new Error(message || fallbackErrorMessage);
      if (responseData.code == "101") error = responseData.code;
      throw error;
    }
    await context.commit("setAddNoticeByUnit", responseData.data);
  },

  async getNotices(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Notice";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + encodeURIComponent(JSON.stringify(payload.where));
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get project");
      throw error;
    }
    //  console.log("setNotices=",responseData);

    context.commit("setNotices", responseData);
  },

  async getNotice(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Notice/"+payload.id;  
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const responseData = await response.json();

    if (!response.ok) {      
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get project");
      throw error;
    }

    // console.log("setNotice=",responseData);
    context.commit("setNotice", responseData.data);
  },
  async getNoticesGroupDate(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Notice/GetNoticeGroupDate";

    if (payload.ProjectId) {
      url = url + "?ProjectId=" + payload.ProjectId;
    }
		url = encodeURI(url);
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get project");
      throw error;
    }
    //  console.log("setNoticesGroupDate=",responseData);
    context.commit("setNoticesGroupDate", responseData);
  },

  async getNoticesByAtDate(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Notice/GetNoticeAtDate";

    if (payload.ProjectId) {
      url = url + "?ProjectId=" + payload.ProjectId;
    }

    if (payload.DateAt) {
      url = url + "&Date=" + payload.DateAt;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get project");
      throw error;
    }
    //  console.log("setNoticesByAtDate=",responseData);
    context.commit("setNoticesByAtDate", responseData);
  },

  async cancelNotice(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Notice/Cancel`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if (Object.prototype.hasOwnProperty.call(responseData, 'errors')) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "")
        }
      } else if (Object.prototype.hasOwnProperty.call(responseData, 'message')) {
        message = responseData.message
      }
      const error = new Error(message || 'Failed to cancel invoice');
      throw error;
    }

    context.commit("setNotice", responseData.data);
  },

  async printNotice(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Notice/Print";    
    const token = context.rootGetters.token;    
    // console.log("PrintPrepaid_payload=",payload);
    // console.log("PrintPrepaid_payloadtoken=",token);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(payload),
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url      
    });  
     
    let urlBlob = ''
    let blob = await response.blob();
    urlBlob = window.URL.createObjectURL(blob);
    context.commit('setBlob', urlBlob);
  },

};
