<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
    :header="lang.printLot"
  >
    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchTextPrint"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>
    <transition>
      <section
        v-if="!isShowVouchers"
        class="flex flex-wrap"
        :class="textFormClass"
      >
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-4">
          <label for="codeStart">{{ lang.codeStart }}</label>
          <PvInputText
            id="codeStart"
            type="text"
            class="inputfield w-full mt-1"
            v-model="codeStart"
            placeholder="6501xxxxx"
            :class="{ 'p-invalid': codeStartError }"
          />
          <small v-if="codeStartError" id="code-start-help" class="p-error"
            >{{ lang.validateCodeStart }}
          </small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-2 pr-2 pt-4">
          <label for="codeEnd">{{ lang.codeEnd }}</label>
          <PvInputText
            id="codeEnd"
            type="text"
            class="inputfield w-full mt-1"
            v-model="codeEnd"
            placeholder="6501xxxxx"
            :class="{ 'p-invalid': codeEndError }"
          />
          <small v-if="codeEndError" id="code-end-help" class="p-error"
            >{{ lang.validateCodeEnd }}
          </small>
        </div>
      </section>
    </transition>

    <!-- <PvDataTable
      showGridlines
      :value="vouchers"
      dataKey="id"
      :loading="isLoading"
      responsiveLayout="scroll"
      :scrollHeight="halfViewportHeight + 'px'"
      :stripedRows="true"
      class="p-datatable-sm pt-2"
      :class="textTableClass"
      v-if="isShowVouchers"
    >
      <PvColumn
        field="postStep"
        :header="lang.columnStatus"
        style="min-width: 4rem"
        headerClass="h-center"
        bodyClass="text-center"
        sortable
      ></PvColumn>
      <PvColumn
        field="cancelStatus"
        :header="lang.columnIsCancel"
        style="min-width: 4rem"
        headerClass="h-center"
        bodyClass="text-center"
        sortable
      ></PvColumn>
      <PvColumn
        field="code"
        :header="lang.columnCode"
        style="min-width: 6rem"
        headerClass="h-center"
        bodyClass="text-center"
        sortable
      ></PvColumn>
      <PvColumn
        field="date"
        :header="lang.columnDate"
        style="min-width: 3rem"
        headerClass="h-center"
        bodyClass="text-center"
        sortable
      >
        <template #body="slotProps">
          {{ formatDate(slotProps.data.date) }}
        </template></PvColumn
      >
      <PvColumn
        field="period"
        :header="lang.columnPeriod"
        style="min-width: 4rem"
        headerClass="h-center"
        bodyClass="text-center"
        sortable
      ></PvColumn>
      <PvColumn
        field="description"
        :header="lang.description"
        bodyClass="description-column-style"
        headerClass="h-center"
        sortable
      >
      </PvColumn>
      <PvColumn
        field="totalAmount"
        :header="lang.columnTotal"
        style="min-width: 6rem"
        headerClass="h-center"
        bodyClass="text-right"
        sortable
        ><template #body="slotProps">
          {{ formatCurrency(slotProps.data.totalAmount) }}
        </template>
      </PvColumn>
      <PvColumn
        field="approveStatus"
        :header="lang.columnApprove"
        style="min-width: 4rem"
        headerClass="h-center"
        bodyClass="text-center"
        sortable
      ></PvColumn>
      <template #footer>
          <PvPaginator
            :rows="rowsPerPage"
            :totalRecords="totalRecords"
            @page="onPageChange($event)"
            :rowsPerPageOptions="[10, 50, 100]"
          >
            <template #start="slotProps">
              {{ lang.page }}:
              {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
            </template>
          </PvPaginator>
        </template>
    </PvDataTable> -->

    <!-- <div>
      <PvButton
        icon="pi pi-file-pdf"
        :label="lang.downloadPDF"
        @click="selectPrintForm"
        class="mr-2 mb-2 mt-4"
      />
    </div> -->

    <template #footer>
      <PvButton
        icon="pi pi-times"
        class="p-button-text"
        :label="lang.close"
        @click="closeDialog"
      />
      <PvButton
        icon="pi pi-file-pdf"
        :label="lang.downloadPDF"
        @click="selectPrintForm"
      />
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetFormPrint from "../../hooks/getFormPrint.js";
export default {
  props: ["glBookId", "glBook"],
  emits: ["close"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const {
      getFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints
    } = useGetFormPrint();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.accounting.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.accounting.listing,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints
    };
  },
  data() {
    return {
      isLoading: false,
      codeStart: "",
      codeEnd: "",
      isShowVouchers: false,
      showFormPrintModal: false,
      codeStartError: false,
      codeEndError: false,
      selectedPage: 1,
      rowsPerPage: 100,
      payload: {},
    };
  },
  created() {
    //
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.initiateData();
      this.$emit("close");
    },
    initiateData() {
      this.codeStart = "";
      this.codeEnd = "";
      this.codeStartError = false;
      this.codeEndError = false;
    },
    async getVouchers() {
      //   this.setLoading(true);
      try {
        let where = {
          projectId: this.selectedProject.id,
          codeStart: this.codeStart,
          codeEnd: this.codeEnd,
          generalLedgerBookId: this.glBookId,
        };

        const payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          where,
        };

        await this.$store.dispatch("glAccountingEntry/getAccountingEntries",payload);

      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        // this.setLoading(false);
      }

      //   this.setLoading(false);
    },
    validateData() {
      if (!this.codeStart) {
        this.codeStartError = true;
        return true;
      }

      if (!this.codeEnd) {
        this.codeEndError = true;
        return true;
      }

      return false;
    },
    async selectPrintForm() {
      if (this.validateData()) {
        this.setLoading(false);
        return;
      }
      await this.getVouchers();

      if (this.vouchers.length == 0) {
        this.$swal({
          icon: "warning",
          title: "ไม่พบข้อมูล",
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      } else {
        let category = this.glBook;
        await this.$store.dispatch("shared/resetFormPrint");
        let where = {
          projectId: this.selectedProject.id,
          documentBookCode: category,
        };
        const payload = { where };
        await this.getFormPrints(payload);
        
        if(this.filteredFormPrints.length == 1) {
          this.path = this.filteredFormPrints[0].path + ".pdf";
          this.printVouchetLot();
        }else{
          this.showFormPrintModal = true;
        }
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path + ".pdf";
      //this.categoryPrint = event.category;

      this.printVouchetLot();

      this.showFormPrintModal = false;
      this.closeDialog();
    },
    async printVouchetLot() {
      this.setLoading(true);
      try {
        let parameter = {
          ProjectId : this.selectedProject.id,
          GlbId : this.glBookId,
          Id : "",
          CodeStart : this.codeStart,
          CodeEnd : this.codeEnd
        }

        this.payload = {
          path: this.path,
          fileName: this.lang.voucher,
          parameter: JSON.stringify(parameter)
        };

        await this.$store.dispatch("shared/printForm", this.payload);
        
        let blob = this.$store.getters["shared/blob"];
        this.getPreviewBlob(blob);

        this.setLoading(false);
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        this.setLoading(false);
      }
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    vouchers() {
      return this.$store.getters["glAccountingEntry/accountingEntries"];
    },
    GetPathGlReceipt() {
      const receiptPath = this.$route.fullPath.indexOf("gl/receipt");
      let receipt = true;
      if (receiptPath < 0) receipt = false;
      return receipt;
    },
    GetPathGlInvoice() {
      const invoicePath = this.$route.fullPath.indexOf("gl/invoice");
      let invoice = true;
      if (invoicePath < 0) invoice = false;
      return invoice;
    },
  },
  watch: {
    codeStart(curValue, oldValue) {
      if (oldValue === "" && curValue) {
        this.codeStartError = false;
      }
    },
    codeEnd(curValue, oldValue) {
      if (oldValue === "" && curValue) {
        this.codeEndError = false;
      }
    },
  },
};
</script>
