export default {
    title: "Setting Budget",
    createBudget: "Create Budget",
    columnName: "Budget Name",
    columnYear: "Budget Year",
    columnPeriod: "Budget Period",
    columnUpdateDate: "Update Date",
    columnUpdateBy: "Update By",
    columnStatus: "Status",

    statusWait: "Wait",
    statusApprove: "Approve",
    statusCancel: "Cancel",

    titleCreateDialog: "Create budget",
    nameBudget: "Budget name*",
    nameBudgetPlaceholder: "Enter Budget name",
    validateNameBudget: "Please Enter Budget name",

    yearBudget: "Budget year*",
    yearBudgetPlaceholder: "Enter Budget year",
    validateYearBudget: "Please Enter Budget year",

    remarkBudget: "Remark",
    remarkBudgetPlaceholder: "Enter Remark",

    periodBudgetFrom: "Budget range from*",
    validatePeriodBudgetFrom: "Please Enter range from",

    periodBudgetTo: "To*",
    validatePeriodBudgetTo: "Please Enter range To",

    budgetMethod: "How to fill budget",
    newBudgetMethod: "Customized",
    copyBudgetMethod: "Copy the budget",

    copyBudgetSelect: "Budget copied",
    validateBudgetDate: "Please enter a valid budget range where the start of the range precedes or matches the end of the range.",
};
