export default {
    accountingFrequentlys(state) {
      return state.accountingFrequentlys;
    },
    totalPage(state) {
      return state.totalPage;
    },
    currentPage(state) {
      return state.currentPage;
    },
    rows(state) {
      return state.rows;
    },
    selectedAccountingFrequently(state){
      return state.selectedAccountingFrequently;
    },
  };
  