export default {
  documentBookDistinctData(state) {
    return state.documentBookDistinct.data;
  },
  documentBookDistinctCurrentPage(state) {
    return state.documentBookDistinct.currentPage;
  },
  documentBookDistinctNextPage(state) {
    return state.documentBookDistinct.currentPage + 1;
  },
  documentBookDistinctTotalPage(state) {
    return state.documentBookDistinct.totalPage;
  },
  documentBookDistinctIsLastPage(_, getters) {
    return getters.documentBookDistinctCurrentPage === getters.documentBookDistinctTotalPage && getters.documentBookDistinctTotalPage !== 0;
  }
};
