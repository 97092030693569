export default({
    title:"ทะเบียนคีย์การ์ด",
    createStockKeycard: "สร้างคีย์การ์ด",
    import: "นำเข้าคีย์การ์ด",

    ready: "พร้อมขาย",
    sold: "ขายแล้ว",
    lost: "หาย",
    broken: "ชำรุด",
    spare: "สำรอง",
    other: "อื่นๆ",

    date: "วันที่นำเข้า",
    dateRange: "ช่วงวันที่นำเข้า",
    productCode: "รหัสสินค้า",
    unit: "ยูนิต",
    address: "บ้านเลขที่",
    runningNo: "Running No.",
    serialNo: "Serial No.",
    receiptCode: "เลขที่ใบเสร็จ",

    codeSortASC: "Running No. น้อยไปมาก",
    codeSortDESC: "Running No. มากไปน้อย",
    dateSortASC: "วันที่นำเข้า เก่าไปใหม่",
    dateSortDESC: "วันที่นำเข้า ใหม่ไปเก่า",
})