<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <div v-if="isErrorMarkup" class="pb-2 mt-0">
      <PvInlineMessage severity="error" class="w-full justify-content-start">{{
        lang.validateNoDetailSelect
      }}</PvInlineMessage>
    </div>
    <template #header>
      <div class="flex justify-content-between align-items-center flex flex-wrap pt-3 pb-3">
        <span class="font-lg">{{ lang.markUpTitle }}</span>
      </div>
      <PvDivider class="mt-3 mb-3" />
    </template>

    <PvDataTable
      :value="filteredMarkUpData"
      v-model:selection="selectedMarkUp"
      selectionMode="single"
      dataKey="value"
      responsiveLayout="scroll"
      :scrollHeight="halfViewportHeight + 'px'"
      class="p-datatable-xs"
      @row-dblclick="confirm()"
      @row-select="isErrorMarkup = false"
    >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ this.lang.tableEmpty }}</label>
        </div>
      </template>

      <template #header>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <PvInputText v-model="searchText" :placeholder="lang.keywordSearch" autofocus />
        </span>
      </template>
      <PvColumn selectionMode="single" bodyClass="text-center" style="min-width: 1rem; height: 45px"></PvColumn>
      <PvColumn
        field="value"
        :header="lang.columnValue"
        headerClass="h-left"
        bodyClass="text-left"
        style="min-width: 3rem; height: 45px"
      ></PvColumn>
      <PvColumn
        field="name"
        :header="lang.columnName"
        headerClass="h-left"
        style="min-width: 20rem; height: 45px"
      ></PvColumn>
    </PvDataTable>
    <template #footer>
      <div class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-end align-items-center">
        <div class="lg:col-3 md:col-4 col-6 pl-1 pr-1">
          <PvButton :label="lang.close" class="p-button-outlined p-button-secondary w-full" @click="closeDialog()" />
        </div>
        <div class="lg:col-3 md:col-4 col-6 pl-1 pr-1">
          <PvButton :label="lang.confirm" @click="confirm()" class="w-full" />
        </div>
      </div>
    </template>
  </PvDialog>
</template>
<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  emits: ["close", "markup-selected"],
  setup() {
    const { lang } = useLang();
    const store = useStore();
    const { textTableClass } = useTextStyle();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.notice.report,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.notice.report,
        };
      }
    });
    return {
      lang: mergeLang,
      textTableClass,
    };
  },
  data() {
    return {
      markUpData: [
        { value: "{{ProjectName}}", name: this.lang.projectNameThMarkUp },
        { value: "{{EProjectName}}", name: this.lang.projectNameEnMarkUp },
        { value: "{{UnitCode}}", name: this.lang.unitCodeMarkUp },
        { value: "{{UnitAddressNo}}", name: this.lang.unitAddressNoMarkUp },
        { value: "{{CustomerName}}", name: this.lang.customerNameThMarkUp },
        { value: "{{ECustomerName}}", name: this.lang.customerNameEnMarkUp },
        { value: "{{TotalAmount}}", name: this.lang.totalAmountMarkUp },
        { value: "{{TotalAmountText}}", name: this.lang.totalAmountTextThMarkUp },
        { value: "{{ETotalAmountText}}", name: this.lang.totalAmountTextEnMarkUp },
        { value: "{{DueDate}}", name: this.lang.dueDateThMarkUp },
        { value: "{{EDueDate}}", name: this.lang.dueDateEnMarkUp },
        { value: "{{SuspendDate}}", name: this.lang.suspendDateThMarkUp },
        { value: "{{ESuspendDate}}", name: this.lang.suspendDateEnMarkUp },
      ],
      searchText: "",
      selectedMarkUp: null,
      isErrorMarkup: false,
    };
  },
  mounted() {
    this.initiateData();
  },
  methods: {
    initiateData() {
      this.searchText = "";
      this.selectedMarkUp = null;
      this.isErrorMarkup = false;
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    confirm() {
      if (this.selectedMarkUp) {
        this.$emit("markup-selected", this.selectedMarkUp);
        this.initiateData();
      } else {
        this.isErrorMarkup = true;
      }
    },
  },
  computed: {
    filteredMarkUpData() {
      if (!this.searchText) {
        return this.markUpData;
      } else {
        return this.markUpData.filter((markUp) => {
          return (
            markUp.value.toLowerCase().includes(this.searchText.toLowerCase()) ||
            markUp.name.toLowerCase().includes(this.searchText.toLowerCase())
          );
        });
      }
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
  },
};
</script>
