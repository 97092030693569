export default {
    async getInvoices(context, payload) {
      let url = process.env.VUE_APP_BACKEND_URL + "/Voucher/Invoice";
  
      if (!payload.limit) {
        url = url + "?limit=100";
      } else {
        url = url + "?limit=" + payload.limit;
      }
  
      if (payload.page) {
        url = url + "&page=" + payload.page;
      }
  
      if (payload.where) {
        url = url + "&where=" + JSON.stringify(payload.where);
      }
      // console.log("setInvoices_payload=",payload);
      const token = context.rootGetters.token;
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        // console.log(responseData);
        const error = new Error(responseData.message || "Failed to get invoice");
        throw error;
      }
      //  console.log("getInvoices=", responseData);

      const invoice = {
        invoices: responseData.data,
        totalPage: responseData.page.pageCount, 
        currentPage: responseData.page.currentPage, 
      };

      context.commit("setInvoice", invoice);
    },
    async getAllInvoices(context, payload) {
      while (!context.getters.invoiceIsLastPage) {
        let where = payload.where
        const payloadInvoice = {
          page: context.getters.invoiceNextPage || 1,
          where,
        };
        await context.dispatch("getInvoices", payloadInvoice);
      }
    }, 
    async resetInvoiceList(context, payload) {
      context.commit('resetInvoiceList', payload);
    },
    async setSelectedInvoice(context, payload) {
      context.commit("setSelectedInvoice", payload);
    },
    async getInvoiceSelected(context, payload) {
      let url = process.env.VUE_APP_BACKEND_URL + "/Voucher/Invoice";
  
      if (!payload.limit) {
        url = url + "?limit=100";
      } else {
        url = url + "?limit=" + payload.limit;
      }
  
      if (payload.page) {
        url = url + "&page=" + payload.page;
      }
  
      if (payload.where) {
        url = url + "&where=" + JSON.stringify(payload.where);
      }

      // console.log("payload=",payload);
  
      const token = context.rootGetters.token;
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        // console.log(responseData);
        const error = new Error(responseData.message || "Failed to get invoice");
        throw error;
      }
      // console.log("responseData", responseData);

      const invoice = {
        invoices: responseData.data,
        totalPage: responseData.page.pageCount, 
        currentPage: responseData.page.currentPage, 
      }; 
  
      context.commit("getInvoiceSelected", invoice);
    },
    async getAllInvoiceSelected(context, payload) {
      while (!context.getters.invoiceIsLastPage) {
        let where = payload.where
        const payloadInvoice = {
          page: context.getters.invoiceNextPage || 1,
          where,
        };
        await context.dispatch("getInvoiceSelected", payloadInvoice);
      }
    }, 
    async resetInvoiceSelectedList(context, payload) {
      context.commit('resetInvoiceSelectedList', payload);
    },
    async getInvoiceToGl(context, payload) {
      let url = process.env.VUE_APP_BACKEND_URL + "/Voucher/InvoiceToGl";
  
      const token = context.rootGetters.token;
  
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(payload),
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        // console.log(responseData);
        const error = new Error(responseData.message || "Failed to get invoice");
        throw error;
      }
      // console.log("getInvoiceToGl", responseData);
  
      context.commit("setInvoiceToGl", responseData);
    },

    // async getInvoice(context, payload) {
    //   let url = process.env.VUE_APP_BACKEND_URL + "/Voucher/InvoiceToGl?limit=1";
  
    //   if (payload.where) {
    //     url = url + "&where=" + JSON.stringify(payload.where);
    //   }
  
    //   const token = context.rootGetters.token;
  
    //   const response = await fetch(url, {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + token,
    //     },
    //   });
  
    //   const responseData = await response.json();
  
    //   if (!response.ok) {
    //     // console.log(responseData);
    //     const error = new Error(responseData.message || "Failed to get invoice");
    //     throw error;
    //   }
    //   // console.log(responseData);
  
    //   // const data = responseData.data;
    //   if (responseData.data.length > 0) {
    //     context.commit("setSelectedInvoice", responseData.data);
    //   }
    // },
    resetInvoice(context) {
      context.commit("setSelectedInvoice", null);
    },
};
  