export default {
	createTitle: "ผู้จัดจำหน่าย", 
	editTitle: "ผู้จัดจำหน่าย", 
	containerTitle: "รายการผู้จัดจำหน่าย",

	cardTitle1: "ข้อมูลส่วนที่ 1",
	cardTitle2: "ข้อมูลส่วนที่ 2",
	cardTitle3: "ข้อมูลส่วนที่ 3",

	labelCode: "รหัสผู้จัดจำหน่าย", 
	placeholderCode: "ระบุรหัสผู้จัดจำหน่าย", 
	validateCodeIsNull: "โปรดระบุรหัสผู้จัดจำหน่าย", 
	validateCodeDuplicate: "รหัสผู้จัดจำหน่ายซ้ำ", 
	labelTaxId: "เลขผู้เสียภาษี/บัตรประชาชน", 
	placeholderTaxId: "ระบุเลขผู้เสียภาษี/บัตรประชาชน", 
	validateTaxIdIsNull: "โปรดระบุเลขผู้เสียภาษี/บัตรประชาชน", 
	validateTaxIdDuplicate: "เลขผู้เสียภาษี/บัตรประชาชนซ้ำ", 
	validateTaxIdIsNotValid: "เลขผู้เสียภาษี/บัตรประชาชนไม่ถูกต้อง", 
	labelName: "ชื่อผู้จัดจำหน่าย", 
	placeholderName: "ระบุชื่อผู้จัดจำหน่าย", 
	validateName: "โปรดระบุชื่อผู้จัดจำหน่าย", 
	labelGender: "ประเภทผู้จัดจำหน่าย",
	itemGender1: "บริษัท/ห้างร้าน",
	itemGender2: "ชาย",
	itemGender3: "หญิง",
	labelAddress1: "ที่อยู่",
	placeholderAddress1: "ระบุที่อยู่สำหรับแสดงในเอกสาร", 
	labelAddress2: "แขวง/ตำบล, เขต/อำเภอ",
	placeholderAddress2: "ระบุแขวง/ตำบล, เขต/อำเภอ", 
	labelAddress3: "จังหวัด",
	placeholderAddress3: "ระบุจังหวัด",
	labelPostCode: "รหัสไปรษณีย์",
	placeholderPostCode: "ระบุรหัสไปรษณีย์",
	labelTelephoneNumber: "เบอร์โทรศัพท์",
	placeholderTelephoneNumber: "ระบุเบอร์โทรศัพท์",
	labelMobileNumber: "เบอร์มือถือ",
	placeholderMobileNumber: "ระบุเบอร์มือถือ",
	labelFaxNumber: "โทรสาร",
	placeholderFaxNumber: "ระบุโทรสาร",
	labelEmail: "อีเมล",
	placeholderEmail: "ระบุอีเมล",
	labelIsCustomer: "คุณต้องการเพิ่มข้อมูลผู้จัดจำหน่ายเป็นสมาชิกหรือไม่",
	labelSupplierCreditTerm: "จำนวนวันเครดิต",
	labelBankAccountNumber: "เลขที่บัญชี",
	placeholderBankAccountNumber: "ระบุเลขที่บัญชี",
	labelWithholdingTaxRate: "ภาษีหัก ณ ที่จ่าย %",
	labelBank: "ธนาคาร",
	labelVatRate: "ภาษีซื้อ %",
	labelContactName: "ผู้รับการสั่งซื้อ/ขาย",
	validateContactName: "ระบุผู้รับการสั่งซื้อ/ขาย",
	labelContactAddress1: "ที่อยู่",
	validateContactAddress1: "ระบุที่อยู่สำหรับจัดส่งสินค้า/คลังสินค้า/สาขา",
	labelContactAddress2: "แขวง/ตำบล, เขต/อำเภอ",
	validateContactAddress2: "ระบุแขวง/ตำบล, เขต/อำเภอ",
	labelContactAddress3: "จังหวัด, รหัสไปรษณีย์",
	validateContactAddress3: "ระบุจังหวัด และ รหัสไปรษณีย์",

	dialogTitleCreate: "สร้างผู้จัดจำหน่ายใหม่",
	dialogContentCreate: "หากคุณสร้างใหม่ระบบจะไม่บันทึกข้อมูล คุณยืนยันที่จะสร้างใหม่ใช่หรือไม่?",
	dialogTitleDelete: "ลบผู้จัดจำหน่าย",
	dialogContentDelete: "คุณต้องการลบผู้จัดจำหน่าย {0} ที่ท่านทำอยู่ใช่หรือไม่?",
	dialogTitleDeleted: "ลบผู้จัดจำหน่ายสำเร็จ",
	dialogContentDeleted: "คุณลบผู้จัดจำหน่าย {0} สำเร็จ",
	dialogTitleSave: "บันทึกสำเร็จ",
	dialogContentSave: "บันทึกรายการสำเร็จ",

	dialogValidateTitle: "ทำรายการไม่สำเร็จ",
	dialogValidateParamsIdNotFound: "ไม่สามารถทำรายการได้ เนื่องจากไม่พบข้อมูลผู้จัดจำหน่าย", // code: 102
	dialogValidateParamsProjectId: "ไม่สามารถทำรายการได้ เนื่องจากไม่พบข้อมูลโครงการ", // code: 103
	dialogValidateParamsCode: "ไม่สามารถทำรายการได้ เนื่องจากข้อมูลรหัสผู้จัดจำหน่ายไม่ถูกต้อง", // code: 104
	dialogValidateParamsName: "ไม่สามารถทำรายการได้ เนื่องจากข้อมูลชื่อผู้จัดจำหน่ายไม่ถูกต้อง", // code: 105
	dialogValidateParamsTaxId: "ไม่สามารถทำรายการได้ เนื่องจากข้อมูลเลขผู้เสียภาษีไม่ถูกต้อง", // code: 106
	dialogValidateParamsGendar: "ไม่สามารถทำรายการได้ เนื่องจากข้อมูลประเภทผู้จัดจำหน่ายไม่ถูกต้อง", // code: 107
	dialogValidateParamsId: "ไม่สามารถทำรายการได้ เนื่องจากข้อมูลไอดีผู้จัดจำหน่ายไม่ถูกต้อง", // code: 108
	dialogValidateInsertRole: "ไม่สามารถทำรายการได้ เนื่องจากคุณไม่มีสิทธิ์สร้างข้อมูล", // code: 111
	dialogValidateUpdateRole: "ไม่สามารถทำรายการได้ เนื่องจากคุณไม่มีสิทธิ์แก้ไขข้อมูล", // code: 112
	dialogValidateDeleteRole: "ไม่สามารถทำรายการได้ เนื่องจากคุณไม่มีสิทธิ์แก้ไขข้อมูล", // code: 113
	dialogValidateInsertAndDuplicateCode: "ไม่สามารถทำรายการได้ เนื่องจากตรวจพบรหัสผู้จัดจำหน่ายซ้ำในระบบ", // code: 120
	dialogValidateInsertAndDuplicateTaxId: "ไม่สามารถทำรายการได้ เนื่องจากตรวจพบเลขผู้เสียภาษีซ้ำในระบบ", // code: 121
	dialogValidateReferencePurchaseRequisition: "ไม่สามารถทำรายการได้ เนื่องจากมีใบขอซื้ออ้างถึง (PR)", // code: 130
	dialogValidateReferencePurchaseOrder: "ไม่สามารถทำรายการได้ เนื่องจากมีใบสั่งซื้ออ้างถึง (PO)", // code: 131
	dialogValidateReferencePurchaseInvoice: "ไม่สามารถทำรายการได้ เนื่องจากมีใบแจ้งหนี้อ้างถึง (PI)", // code: 132
	dialogValidateReferencePurchaseReceipt: "ไม่สามารถทำรายการได้ เนื่องจากมีใบเสร็จอ้างถึง (PT)", // code: 133
	dialogValidateReferencePayment: "ไม่สามารถทำรายการได้ เนื่องจากมีเอกสารธนาคารอ้างถึง (Payment)", // code: 134
	dialogValidateReferenceWithholdingTax: "ไม่สามารถทำรายการได้ เนื่องจากมีใบหัก ณ ที่จ่ายอ้างถึง (Withholding tax)", // code: 135
	dialogValidateReferenceUnitRersident: "ไม่สามารถทำรายการได้ เนื่องจากมีข้อมูลห้อง/บ้านอ้างถึง (Unit)", // code: 136
	dialogValidateReferenceUnitHistory: "ไม่สามารถทำรายการได้ เนื่องจากมีข้อมูลประวัติเจ้าของอ้างถึง (Unit history)", // code: 137
	
	buttonCreate: "สร้างใหม่",
	buttonSave: "บันทึก",
	buttonDelete: "ลบ", 
	businessType: "ประเภทดำเนินธุรกิจ",
	emergencyContact: "ข้อมูลติดต่อฉุกเฉิน",
	accountingData: "ข้อมูลทางบัญชี",
	labelAddress2Remark: "ข้อมูลนี้จะเปลี่ยนตามช่อง แขวง/ตำบล และเขต/อำเภอ",
	labelEmergencyContactName: "ชื่อผู้ติดต่อ",
	placeholderEmergencyContactName: "ระบุชื่อผู้ติดต่อ",
};
