<template>
  <base-toolbar
    :title="lang.editProject"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoEdit="gotoEdit"
    :hideBackButton="isHideBack"
  >
  </base-toolbar>

  <base-dialog
    v-model:visible="showConfirmDialog"
    :titleConfirm="titleConfirmDialog"
    :wordingConfirm="wordingDialog"
    :dataButtons="dataButtonDialog"
    @close="showConfirmDialog = false"
  ></base-dialog>

  <div class="mt24"></div>
  <base-container>
    <base-card
      :titleHeader="lang.projectTitleGeneral"
      :isVisibleExpandButton="true"
      :isHideBackground="false"
      :isVisibleCard="true"
    >
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.projectCode }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.Code }}</label>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.projectType }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.SelectedProjectType }}</label>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.projectComNickName }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.NickName }}</label>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.projectComShortCode }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.ShortCode }}</label>
          </div>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.projectNameTh }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.NameTh }}</label>
          </div>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.projectNameEn }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.NameEn }}</label>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.projectTel }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.Tel }}</label>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.projectFax }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.Fax }}</label>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.projectTaxId }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.TaxNo }}</label>
          </div>
        </div>
        <!-- <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.suffix }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.suffix }}</label>
          </div>
        </div> -->
      </section>
    </base-card>    
    <div class="mt24"></div>
    <base-card
      :titleHeader="this.lang.projectTitleAddress"
      :isVisibleExpandButton="true"
      :isHideBackground="false"
      :isVisibleCard="true"
    >
      <PVTabView>
        <PVTabPanel>
          <template #header>
            <span>{{ lang.tabAddressProject }} </span>
          </template>
          <!-- *************************************************** -->
          <section class="flex flex-wrap " :class="textFormClass">
            <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-4">
              <label Class="p-text-secondary">{{ lang.addressTh }}</label>
            </div>
            <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.address1 }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.AddressTh1 }}</label>
              </div>
            </div>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.address2 }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.AddressTh2 }}</label>
              </div>
            </div>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.address3 }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.AddressTh3 }}</label>
              </div>
            </div>
            <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-4">
              <label  style="color: #181819">{{ lang.addressEn }}</label>
            </div>
            <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.address1 }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.AddressEn1 }}</label>
              </div>
            </div>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.address2 }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.AddressEn2 }}</label>
              </div>
            </div>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.address3 }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.AddressEn3 }}</label>
              </div>
            </div>
          </section>
          <!-- *************************************************** -->
        </PVTabPanel>
        <PVTabPanel>
          <template #header>
            <span>{{ lang.tabAddressParcel }} </span>
            &nbsp; &nbsp;<i
              class="pi pi-exclamation-circle"
              v-tooltip.top="{
                value: lang.tooltipAddressParcel ,
                class: 'custom-tooltip',
              }"
            ></i>
          </template>

          <!-- *************************************************** -->
          <section class="flex flex-wrap" :class="textFormClass">
            <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.parcelName }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.ContactName }}</label>
              </div>
            </div>
            <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.parcelTel }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.Telephone }}</label>
              </div>
            </div>
            <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.parcelEmail }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.Email }}</label>
              </div>
            </div>

            <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.address1 }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.FlashAddress }}</label>
              </div>
            </div>

            <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.province }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.Province }}</label>
              </div>
            </div>
            <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.district }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.District }}</label>
              </div>
            </div>
            <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.subarea }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.Subarea }}</label>
              </div>
            </div>
            <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.postcode }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.Postcode }}</label>
              </div>
            </div>
          </section>
          <!-- *************************************************** -->
        </PVTabPanel>
      </PVTabView>
    </base-card>    
    <div class="mt24"></div>   
    <base-card
      :titleHeader="this.lang.projectTitlePerson"
      :isVisibleExpandButton="true"
      :isHideBackground="false"
      :isVisibleCard="true"
    >
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.projectManagerNameTh }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.ProjectManagerNameTh }}</label>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.projectManagerNameEn }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.ProjectManagerNameEn }}</label>
          </div>
        </div>

        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.projectManagerEmail }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.ProjectManagerEmail }}</label>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.debtFreeAuthEmail }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.DebtFreeAuthEmail }}</label>
          </div>
        </div>

        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.communityManagerNameTh }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.CommunityManagerNameTh }}</label>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.communityManagerNameEn }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.CommunityManagerNameEn }}</label>
          </div>
        </div>

        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.accountantNameTh }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.AccountantNameTh }}</label>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.accountantNameEn }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.AccountantNameEn }}</label>
          </div>
        </div>

        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.accManagerName }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.AccManagerName }}</label>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.communityManageBy }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.CommunityManageBy }}</label>
          </div>
        </div>
      </section>
    </base-card>  
    <div class="mt24"></div>   
<!-- 
    <base-card
      :titleHeader="this.lang.projectTitleAdvertise"
      :isVisibleExpandButton="true"
      :isHideBackground="false"
      :isVisibleCard="true"
    >
    
    <PVTabView>
        <PVTabPanel>
          <template #header>
            <span 
              >{{ lang.tabAdvertiseInvoice }}
            </span>
          </template>
          <section class="flex flex-wrap" :class="textFormClass">
            <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.advertiseInvoiceTh }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.AdvertiseInvoiceTh }}</label>
              </div>
            </div>
            <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.advertiseInvoiceEn }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.AdvertiseInvoiceEn }}</label>
              </div>
            </div>          
          </section>
        </PVTabPanel>
        <PVTabPanel>
          <template #header>
            <span 
              >{{ lang.tabAdvertiseNotice }}
            </span>
          </template>
          <section class="flex flex-wrap" :class="textFormClass">
            <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.advertiseNoticeTh }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.AdvertiseNoticeTh }}</label>
              </div>
            </div>
            <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.advertiseNoticeEn }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.AdvertiseNoticeEn }}</label>
              </div>
            </div>   
          </section>
        </PVTabPanel>
        <PVTabPanel>
          <template #header>
            <span 
              >{{ lang.tabAdvertiseReceipt }}
            </span>
          </template>
          <section class="flex flex-wrap " :class="textFormClass">
            <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.advertiseReceiptTh }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.AdvertiseReceiptTh }}</label>
              </div>
            </div>
            <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.advertiseReceiptEn }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.AdvertiseReceiptEn }}</label>
              </div>
            </div>   
          </section>
        </PVTabPanel>
        <PVTabPanel>
          <template #header>
            <span
              >{{ lang.tabAdvertiseInvoiceReceipt }}
            </span>
          </template>
          <section class="flex flex-wrap" :class="textFormClass">
            <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.invoiceFooter }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.InvoiceFooter }}</label>
              </div>
            </div>
            <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
              <label Class="p-text-secondary">{{ lang.receiptFooter }}</label>
              <div class="mt-1">
                <label class="fontData">{{ this.ReceiptFooter }}</label>
              </div>
            </div>   
          </section>
        </PVTabPanel>
      </PVTabView>

    </base-card> -->

    <base-card
      :titleHeader="this.lang.projectTitleRegistered"
      :isVisibleExpandButton="true"
      :isHideBackground="false"
      :isVisibleCard="true"
    >
      <!-- *************************************************** -->
      <section class="flex flex-wrap " :class="textFormClass">
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.juristicId }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.RegisteredNo }}</label>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.juristicDate }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.JuristicRegisteredDate }}</label>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.capitalFirst }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.FirstFundAmount }}</label>
          </div>
        </div>

        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.capitalBalance }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.FundAmountLeft }}</label>
          </div>
        </div>

        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.ownershipRatio }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.formatCurrency(this.TotalOwnerRatio) }}</label>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.projectArea }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.formatCurrency(this.TotalArea) }}</label>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.financialStatements }}</label>
          <div class="mt-1">
            <label class="fontData">{{
              this.FinanceDateStart + " - " + this.FinanceDateEnd
            }}</label>
          </div>
        </div>

        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.budget }}</label>
          <div class="mt-1">
            <label class="fontData">{{
              this.BudgetDateStart + " - " + this.BudgetDateEnd
            }}</label>
          </div>
        </div>

        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.numberofRoom }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.TotalUnit }}</label>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.numberofStores }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.TotalShop }}</label>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.numberofCars }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.TotalCarPark }}</label>
          </div>
        </div>
        <!-- <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pt-3">
          <label Class="p-text-secondary">{{ lang.averageWaterMeter }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.AverageWaterMeter }}</label>
          </div>
        </div> -->
      </section>
      <!-- *************************************************** -->
    </base-card>

    <div class="mt24"></div>   
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
export default {
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const {
      formatCurrency,
      formatDate,
      formatDateToISOString,
      formatISODateToLocaleDateString,
    } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.project.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.project.edit,
        };
      }
    });

    const isHideBack = inject("isHideBack");
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      formatISODateToLocaleDateString,
      isHideBack,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
    };
  },
  data() {
    return {
      //ข้อมูลทั่วไป
      Code: "",
      SelectedProjectType: null,
      NameTh: "",
      NameEn: "",
      NickName: "",
      ShortCode: "",
      Tel: "",
      HouseTel: "",
      Fax: "",
      TaxNo: "",
      suffix: "",

      //ข้อมูลที่อยู่
      AddressTh1: "",
      AddressTh2: "",
      AddressTh3: "",
      AddressEn1: "",
      AddressEn2: "",
      AddressEn3: "",

      //ข้อมูลบุคคล
      ProjectManagerNameTh: "",
      ProjectManagerNameEn: "",
      ProjectManagerEmail: "",
      DebtFreeAuthEmail: "",
      CommunityManagerNameTh: "",
      CommunityManagerNameEn: "",
      AccountantNameTh: "",
      AccountantNameEn: "",
      AccManagerName: "",
      CommunityManageBy: "",

      //ประชาสัมพันธ์ - โฆษณา
      AdvertiseInvoiceTh: "",
      AdvertiseInvoiceEn: "",
      AdvertiseNoticeTh: "",
      AdvertiseNoticeEn: "",
      AdvertiseReceiptTh: "",
      AdvertiseReceiptEn: "",
      InvoiceFooter: "",
      ReceiptFooter: "",

      //ข้อมูลการจดทะเบียน
      RegisteredNo: "",
      JuristicRegisteredDate: null,
      AverageWaterMeter: 0,
      TotalOwnerRatio: 0,
      TotalArea: 0,
      TotalUnit: 0,
      TotalShop: 0,
      TotalCarPark: 0,
      FirstFundAmount: 0,
      FundAmountLeft: 0,
      FinanceDateStart: null,
      FinanceDateEnd: null,
      BudgetDateStart: null,
      BudgetDateEnd: null,
      isUpdateLosa: false,
      ContactName: "",
      Telephone: "",
      Email: "",
      FlashAddress: "",
      PostAddressId: null,
      Province: "",
      District: "",
      Subarea: "",
      Postcode: "",

      //Props Toolbar
      dataButtons: [
        {
          id: "editCaption",
          caption: this.lang.editCaption,
          class: "p-button-outlined p-button-primary mr-2",
          icon: "icon-pen-edit",
          iconColor: "primary",
          action: "gotoEdit",
          isShow: true,
        },
      ],

      //BaseDialog
      showConfirmDialog: false,
      wordingDialog: "",
      titleConfirmDialog: this.lang.notificationDialogHeader,
      dataButtonDialog: [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ],
    };
  },
  async mounted() {
    await this.GetProject();
    await this.checkShouldSetData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    gotoEdit() {
      if (!this.isRoleUpdate) {
        this.openDialog({
          severity: "",
          content: this.lang.validateRoleUpdate,
          isConfirm: false,
        });
        return;
      }
      this.$router.replace({ name: "master-project-edit" });
    },
    async GetProject() {
      try {
        this.setLoading(true);
        await this.$store.dispatch(
          "masterProject/GetProjectById",
          this.selectedProject.id
        );
      } catch (error) {
        this.openDialog({
          severity: "",
          content: error,
          isConfirm: false,
        });
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    async setData() {
      // console.log("project=", this.project);
      //ข้อมูลทั่วไป
      this.Code = this.project.code;
      this.SelectedProjectType = this.project.type;
      this.NameTh = this.project.nameTh;
      this.NameEn = this.project.nameEn;
      this.NickName = this.project.nickName;
      this.ShortCode = this.project.shortCode;
      this.Tel = this.project.tel;
      this.HouseTel = this.project.houseTel;
      this.Fax = this.project.fax;
      this.TaxNo = this.project.taxId;      
      this.suffix = this.project.billPaymentSuffix; 
      //ข้อมูลที่อยู่
      this.AddressTh1 = this.project.addressTh1;
      this.AddressTh2 = this.project.addressTh2;
      this.AddressTh3 = this.project.addressTh3;
      this.AddressEn1 = this.project.addressEn1;
      this.AddressEn2 = this.project.addressEn2;
      this.AddressEn3 = this.project.addressEn3;

      //ข้อมูลบุคคล
      this.ProjectManagerNameTh = this.project.projectManagerNameTh;
      this.ProjectManagerNameEn = this.project.projectManagerNameEn;
      this.ProjectManagerEmail = this.project.projectManagerEmail;
      this.DebtFreeAuthEmail = this.project.debtFreeAuthEmail;
      this.CommunityManagerNameTh = this.project.communityManagerNameTh;
      this.CommunityManagerNameEn = this.project.communityManagerNameEn;
      this.AccountantNameTh = this.project.accountantNameTh;
      this.AccountantNameEn = this.project.accountantNameEn;
      this.AccManagerName = this.project.accManagerName;
      this.CommunityManageBy = this.project.communityManageBy;

      // //ประชาสัมพันธ์ - โฆษณา
      // this.AdvertiseInvoiceTh = this.project.advertiseInvoiceTh;
      // this.AdvertiseInvoiceEn = this.project.advertiseInvoiceEn;
      // this.AdvertiseNoticeTh = this.project.advertiseNoticeTh;
      // this.AdvertiseNoticeEn = this.project.advertiseNoticeEn;
      // this.AdvertiseReceiptTh = this.project.advertiseReceiptTh;
      // this.AdvertiseReceiptEn = this.project.advertiseReceiptEn;
      // this.InvoiceFooter = this.project.invoiceFooter;
      // this.ReceiptFooter = this.project.receiptFooter;

      //ข้อมูลการจดทะเบียน
      this.RegisteredNo = this.project.registeredNo;
      this.JuristicRegisteredDate = this.formatISODateToLocaleDateString(
        this.project.juristicRegisteredDate
      );
      // this.AverageWaterMeter = this.project.averageWaterMeter;
      this.TotalOwnerRatio = this.project.totalOwnerRatio;
      this.TotalArea = this.project.totalArea;
      this.TotalUnit = this.project.totalUnit;
      this.TotalShop = this.project.totalShop;
      this.TotalCarPark = this.project.totalCarPark;
      this.FirstFundAmount = this.formatCurrency(this.project.firstFundAmount);
      this.FundAmountLeft = this.formatCurrency(this.project.fundAmountLeft);
      this.FinanceDateStart = this.formatISODateToLocaleDateString(
        this.project.financeDateStart
      );
      this.FinanceDateEnd = this.formatISODateToLocaleDateString(
        this.project.financeDateEnd
      );
      this.BudgetDateStart = this.formatISODateToLocaleDateString(
        this.project.budgetDateStart
      );
      this.BudgetDateEnd = this.formatISODateToLocaleDateString(
        this.project.budgetDateEnd
      );

      if (this.project.firstmileProject) {
        this.ContactName = this.project.firstmileProject.contactName;
        this.Telephone = this.project.firstmileProject.telephone;
        this.Email = this.project.firstmileProject.email;
        this.FlashAddress = this.project.firstmileProject.address;
        this.PostAddressId = this.project.firstmileProject.postAddress.id;
        this.Province = this.project.firstmileProject.postAddress.provinceTH;
        this.District = this.project.firstmileProject.postAddress.cityTH;
        this.Subarea = this.project.firstmileProject.postAddress.districtTH;
        this.Postcode = this.project.firstmileProject.postAddress.zipcode;
      }

      this.setLoading(false);
    },
    async checkShouldSetData() {
      if (this.isSharedRoleByMenuReady && this.project) {
        await this.setData();
      }
    },
    openDialog(dataConfig) {
      this.wordingDialog = dataConfig.content;
      this.showConfirmDialog = true;
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    project() {
      return this.$store.getters["masterProject/project"];
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmCOM");
    },
    isRoleUpdate() {
      if (this.roleByMenu && this.roleByMenu.update == "Y") {
        return true;
      }
      return false;
    },
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.checkShouldSetData();
    },
  },
};
</script>

<style scoped>
/* .p-text-secondary {
  font-size: 14px;
  color: #6e7073;
} */
.fontData {
  font-size: 16px;
  /* color: #181819; */
  letter-spacing: 0.005em;
  /* font-weight: bold; */
  font-weight: 400;
}

.mt24 {
  margin-top: 24px;
}


</style>
