import { useStore } from "vuex";
import { ref, computed } from "vue";
export default function useGetAccountBook() {
  const store = useStore();
  let searchText = ref(null);
  let accountBookCode = ref(null);

  const accountBooks = computed(() => {
    return store.getters["shared/glBookGls"];
  });

  const accountBookIsLastPage = computed(() => {
    return store.getters["shared/glBookIsLastPage"];
  });

  const filteredAccountBooks = computed(() => {
    if (!searchText.value) {
      //ช่องว่าง
      const result = [...accountBookList.value];
      return result;
    } else {
      return accountBookList.value.filter((accountBook) => {
        return accountBook.generalLedgerBookCode
          .toLowerCase()
          .includes(searchText.value.toLowerCase())
          || accountBook.generalLedgerBookName
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  // const accountBooksCodeAndName = computed(() => {
  //   let data = [];
  //   if(accountBooks.value){        
  //       // console.log("accountBookCode.value=",accountBookCode.value);
  //       accountBooks.value.forEach((e) => {
  //         if (accountBookCode.value) {
  //           if (e.generalLedgerBookCode.includes(accountBookCode.value)) {              
  //                   let accountBook = {
  //                       ...e,
  //                       ...{ codeAndName: e.generalLedgerBookCode + " : " + e.generalLedgerBookName },
  //                   };
  //                   data.push(accountBook); 
  //               }
  //          }else{
  //               let accountBook = {
  //                   ...e,
  //                   ...{ codeAndName: e.generalLedgerBookCode + " : " + e.generalLedgerBookName },
  //               };
  //               data.push(accountBook);   
  //          } 
                    
  //       });
  //   }
  //   return data;
  // });
  const accountBookList = computed(() => {
    let data = accountBooks.value;
    if (accountBookCode.value) {
      if (accountBookCode.value == "!PC") {
        data = accountBooks.value.filter((accountBook) =>
          !accountBook.generalLedgerBookCode.toLowerCase().includes("pc")
        );
      }
      else {
        data = accountBooks.value.filter((accountBook) =>
          accountBook.generalLedgerBookCode.includes(accountBookCode.value)
        );
      }
    }
    return data;
  });

  const searchAccountBook = function (event) {
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchText.value = "";
      searchText.value = null;
    } else {
      searchText.value = event.query;
    }
  };

  let isSharedAccountBookReady = ref(false);

  const getAllAccountBooks = async function () {
    if (!accountBookIsLastPage.value) {   
      await store.dispatch("shared/getAllGlBooks");
    }
    isSharedAccountBookReady.value = true;
  }; 

  const getAccountBook = async function (code) {
    if (code) {
        accountBookCode.value = code;
    }
  }; 

  // onMounted(async () => {
  //   await getAllAccountBooks();
  // });

  return {
    searchAccountBook,
    filteredAccountBooks,
    searchText,
    isSharedAccountBookReady,
    getAllAccountBooks,
    getAccountBook,
    accountBooks,
  };
}
