export default({
    Title: "เงินสดย่อย",
    reasonNonApprove:"เหตุผล",
    refundPettyCash:"เบิกคืนเงินสดย่อย",
    approvePettyCash:"อนุมัติคืนเงินสดย่อย",
    columnPeriodC:"ครั้งที่",
    askRefundPettyCash: "คุณยืนยันที่จะ เบิกคืนเงินสดย่อย ใช่หรือไม่?",
    askApprovePettyCash: "คุณยืนยันที่จะ อนุมัติคืนเงินสดย่อย ใช่หรือไม่?",
    labelSortBy: "เรียงข้อมูลตาม",
    sortByCode: "เรียงข้อมูลตามเลขที่เอกสาร",
    sortByDate: "เรียงข้อมูลตามวันที่",

    reportPettyCash: "ใบเบิกคืนเงินสดย่อย",
    reportPettyCashNotWithDraw: "รายงานสรุปรายการจ่ายที่ค้างเบิกคืน",
    reportPettyCashWithDraw: "รายงานที่เบิกคืนวงเงินสดย่อยแล้ว",
    reportPettyCashNotApprove: "รายงานที่ไม่อนุมัติคืนวงเงินสดย่อย",
    reportPettyCashApprove: "รายงานที่อนุมัติคืนวงเงินสดย่อย",
    reportPettyCashToGl: "รายงานแสดงการลงบัญชีวงเงินสดย่อย",

    withdrawStatus: "สถานะการเบิก",
    statusAll: "ทั้งหมด",
    statusWait: "รอเบิก",
    statusRequested: "เบิกแล้ว",
  });