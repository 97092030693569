<template>
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredYearOfBudgets"
    field="name"
    @complete="searchYearOfBudget"
    @dropdown-click="filteredYearOfBudgets"
    forceSelection
  />
</template>

<script>
import { onMounted, watch } from "vue";
import useGetBudgetYear from "../../hooks/getBudgetYear.js";
export default {
  emits: ["load-finish"],
  props: {
    isLoadingData: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const {
      getAllBudgets,
      isSharedBudgetsReady,
      searchTextYearOfBudget,
      filteredYearOfBudgets,
      searchYearOfBudget,
      isFilterBudgetActive,
    } = useGetBudgetYear();

    onMounted(() => {
      isFilterBudgetActive.value = props.isActive;
      if (props.isLoadingData) {
        getAllBudgets();
      }
    });

    watch(isSharedBudgetsReady, function (curValue) {
      if (curValue) {
        context.emit("load-finish");
      }
    });

    return {
      searchTextYearOfBudget,
      filteredYearOfBudgets,
      searchYearOfBudget,
    };
  },
};
</script>
