<template>
  <svg
  	:width="width"
	:height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99884 21.0036H4.99717C3.89214 21.0036 2.99634 20.1078 2.99634 19.0028V4.99693C2.99634 3.8919 3.89214 2.99609 4.99717 2.99609H17.0022C18.1072 2.99609 19.003 3.8919 19.003 4.99693V7.99818"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.99805 7.99826H13.0005"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.99805 11.9983H8.99888"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 21.0022L14.6261 20.678C14.8107 20.6548 14.9823 20.571 15.1143 20.4399L21.4759 14.0783C21.8153 13.7404 22.0061 13.2812 22.0061 12.8023C22.0061 12.3233 21.8153 11.8642 21.4759 11.5262V11.5262C21.138 11.1869 20.6788 10.9961 20.1999 10.9961C19.721 10.9961 19.2618 11.1869 18.9239 11.5262L12.6213 17.8289C12.4936 17.9566 12.4107 18.1223 12.3852 18.3011L12 21.0022Z"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
