<template>
  <div>
    <div class="flex justify-content-between">
      <label for="inputText">{{ caption }}</label>
      <small style="color: #bebebe" class="mr-2">
        {{ TextLength }}/{{ limitMessage }}</small
      >
    </div>
    <PvInputText
      id="inputText"
      type="text"
      class="inputfield w-full mt-1"
      :maxlength="limitMessage"
      v-model="inputText"
      :placeholder="textHolder"
      :class="{ 'p-invalid': isError }"
    />
    <small v-if="isError" id="error-help" class="p-error">{{errorMessage}}</small>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: String,
      default: "",
    },   
    caption: {
      type: String,
      default: "No title",
    },   
    errorMessage: {
      type: String,
      default: "No error message",
    },
    textHolder: {
      type: String,
      default: "",
    },
    limitMessage: {
      type: Number,
      default: 255,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputText: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit("data", value);
      },
    },
    TextLength() {
      if (this.inputText) return this.inputText.length;
      else return 0;
    },
  },
};
</script>