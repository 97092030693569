export default {
	titleEdit: "Detail",
	editSignator: "Edit Signator",
    validatePosition: "Please enter position",
	validateName: "Please enter name",
	signature: "Signature",
	signatureUrlInfo: "A 16:9 ratio image (e.g., 62x35  px) is preferred. Only .jpeg, .jpg, .png with max size of 50 KB",
	validateSignatureUrlTitle: "Can not upload file :name.",
    validateTypeSignatureUrlContent: "The allowed file extensions are .JPEG, .JPG and .PNG only.",
    validateSizeSignatureUrlContent: "Allowed File Size Cannot Exceed 50 Kb only.",
	validateImage: "Please upload file.",
	validateDeleteTitle: "Can not delete",
    validateDeleteContent: "You have not save data yet.",
    titleSignatorDeleteConfirm: "Delete Data",
    contentSignatorDeleteConfirm: "Do you want to delete signator :position (:name)?",
	errorSignatorDeleteHaveRefer: "Can't delete signator because already have reference document.",
    signatorDeleteSuccess: "Delete Signator Success.",
};
