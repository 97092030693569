export default {
	titleBack: "แก้ไขชุดเรียกเก็บ",
	title: "รายละเอียดชุดเรียกเก็บ",
	titleDetail: "รายการรายรับ",
	deletePolicy: "ลบรายการชุดเรียกเก็บ",
	createPolicy: "สร้างใหม่",
	addNewPolicyDetail: "เพิ่มรายการ",
	addProductToPolicyDetail: "สร้างรายการ",
	emptyPolicy: "ไม่มีรายการชุดเรียกเก็บ",

	createNewPolicy: "สร้างชุดเรียกเก็บใหม่",
	askForCreateNewPolicy: "หากคุณสร้างชุดเรียกเก็บใหม่ระบบจะไม่บันทึกการแก้ไข",
	askForCreateNewPolicyConfirm: "คุณยืนยันที่จะสร้างชุดเรียกเก็บใหม่ใช่หรือไม่?",

	policyCodeLabel: "รหัสชุดเรียกเก็บ",
	policyCodePlaceholder: "รหัส",
	policyNameLabel: "ชื่อชุดเรียกเก็บ",
	policyNamePlaceholder: "ชื่อ",
	productCodeLabel: "รหัสรายรับ*",
	policyPeriodLabel: "งวดเดือน* (งวดเดือนเริ่มต้นของการระบุจำนวนงวด)",
	detailRemarkLabel: "รายละเอียด",

	columnItemNo: "ลำดับ",
	columnCode: "รหัสรายรับ",
	columnName: "ชื่อรายรับ",
	columnPeriod: "งวด",
	columnRemark: "รายละเอียด",
	columnAction: "",

	askForDeletePolicy: "คุณยืนยันที่จะ ลบรายรับ {policyCodeAndName} ใช่หรือไม่?",
	dialogPolicyTitle: "เพิ่ม/ แก้ไขรายการ",

	dialogDuplicateTitle: "บันทึกไม่สำเร็จ",
	dialogDuplicateMassage:
		"คุณทำรายการไม่สำเร็จ เนื่องจากรายรับซ้ำกันกับ ชุดเรียกเก็บ {policyCodeName} (รายการที่: {itemNo} )",
	
	validateCode: "กรุณาระบุรหัส",
	validateName: "กรุณาระบุชื่อ",
	titleCreate: "สร้างชุดเรียกเก็บ",
	titleEdit: "แก้ไขชุดเรียกเก็บ",
	validateProduct: "กรุณาเลือกใหม่",
	validatePeriod: "กรุณาเลือกใหม่",
};
