<template>
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredProductGuarantees"
    field="codeAndName"
    @complete="searchProductGuarantee"
    @dropdown-click="filteredProductGuarantees"
    forceSelection
  />
</template>

<script>
import useProductGuarantee from "../../hooks/getProductGuarantee";
export default {
  props: {
    isGetData: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["load-finish"],
  setup() {
    const {
      filteredProductGuarantees,
      searchProductGuarantee,
      getAllProductGuarantees,
      isSharedProductGuaranteeReady,
    } = useProductGuarantee();

    return {
      filteredProductGuarantees,
      searchProductGuarantee,
      getAllProductGuarantees,
      isSharedProductGuaranteeReady,
    };
  },
  mounted() {
		if(this.isGetData){
			this.getAllProductGuarantees();
		}
	},
	watch: {
		isSharedProductGuaranteeReady(curValue) {			
			if (curValue) {
				this.$emit("load-finish");
			}
		},
	},
};
</script>


<!--ตัวอย่างการเอา AutoComplete ไปใช้

      <base-product-guarantee-auto-complete
        id="selectedProductGuarantee"
        v-model="selectedProductGuarantee"
        class="inputfield w-full mt-1"
        :isGetData="true"      
      />

      <base-product-guarantee-auto-complete
        id="selectedProductGuarantee1"
        v-model="selectedProductGuarantee1"
        class="inputfield w-full mt-1"
        :isGetData="false"      
      />

      <base-product-guarantee-auto-complete
        id="selectedProductGuarantee2"
        v-model="selectedProductGuarantee2"
        class="inputfield w-full mt-1"
        :isGetData="false"      
      />

-->