export default {
  setProductAP(state, payload) {
    let products = [...state.productAP.products];
    for (const product of payload.products) {
      if(products.findIndex(c => c.id === product.id) < 0){
        products.push(product);
      }
    }
    state.productAP.products = products;
    state.productAP.currentPage = payload.currentPage;
    state.productAP.totalPage = payload.totalPage;
  },
  resetProductAP(state) {
    const productAP = {
      products: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.productAP = productAP;
  },
};
