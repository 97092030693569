<template>
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
    >
        <path 
            d="M16 19H18C20.2091 19 22 17.2091 22 15V15C22 12.7909 20.2091 11 18 11V11C18 7.68629 15.3137 5 12 5V5C9.43218 5.00125 7.15 6.63691 6.3238 9.06818C3.85166 9.39888 2.00446 11.5058 2 14H2C2 16.7614 4.23858 19 7 19H8" 
            :class="strokeColor"
            stroke-width="1.5" 
            stroke-linecap="round" 
            stroke-linejoin="round"
        />
        <path 
            d="M14 16L12 14L10 16" 
            :class="strokeColor" 
            stroke-width="1.5" 
            stroke-linecap="round" 
            stroke-linejoin="round"
        />
        <path d="M12 19V14" 
            :class="strokeColor"
            stroke-width="1.5" 
            stroke-linecap="round" 
            stroke-linejoin="round"/>
    </svg>
  </template>
  
  <script>
  export default {
    props: {
      width: {
        type: [Number, String],
        default: 24,
      },
      height: {
        type: [Number, String],
        default: 24,
      },
      iconColor: {
        type: String,
        default: "primary",
      },
    },
    computed: {
      strokeColor() {
        return "p-stroke-" + this.iconColor;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  svg {
    display: inline-block;
    vertical-align: baseline;
    margin-bottom: -2px; /* yes, I'm that particular about formatting */
  }
  </style>
  