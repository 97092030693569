<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '70vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="titleHeaderImportData"
      :wordingConfirm="titleValidateImportData"
      :dataButtons="dataButtonDialog"
      @close="showValidateDialog = false"
    ></base-dialog>

    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">{{ lang.uploadFile }}</label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          :disabled="shouldDisableCloseButton"
          @click="closeDialog"
        />
      </div>
    </template>

    <div class="dropZone">
      <input
        type="file"
        id="dropZone-file"
        class="dropZone-file"
        ref="dropZoneFile"
        @click="resetFile"
        @change="handleFiles"
        multiple
        accept=".xlsx"
      />
      <div
        class="dropZone-wrapper"
        @dragenter.prevent=""
        @dragover.prevent=""
        @drop.prevent="handleFiles"
      >
        <label for="dropZone-file" class="cursor-pointer">
          <div class="pt-6"></div>
          <PvButton
            class="cursor-pointer p-button-rounded show-icon"
            disabled="disabled"
          >
            <icon-cloud-storage-upload iconColor="white" />
          </PvButton>
          <div class="cursor-pointer dropZone-label-text pt-4">
            <label
              for="dragFile"
              class="cursor-pointer font-md p-text-secondary"
              >{{ lang.dragFile }}</label
            >
            <label
              for="addFile"
              class="cursor-pointer font-md p-text-primary"
              @click="addFile"
              >{{ lang.addFile }}</label
            >
            <input
              id="add-file"
              type="file"
              style="display: none"
              accept=".xlsx"
              @click="resetFile"
              @change="handleFiles"
              class="cursor-pointer"
            />
          </div>
          <div class="cursor-pointer font-xs p-text-secondary pt-2 pb-8">
            <label for="importFileDesc" class="cursor-pointer">{{
              lang.importFileDesc
            }}</label>
            <label
              for="downloadfile"
              class="cursor-pointer p-text-primary"
              @click="downloadfile"
              >{{ lang.downloadfile }}</label
            >
          </div>
        </label>
      </div>
    </div>

    <div class="my-5 pt-6">
      <PvDataTable
        :value="files"
        dataKey="id"
        responsiveLayout="scroll"
        class="p-datatable-xs"
        :class="textTableClass"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn style="width: 87px; height: 56px" headerClass="h-center">
          <template #body>
            <PvButton
              v-if="isOpen"
              type="button"
              style="background: white; border: white; opacity: 1"
              disabled="disabled"
            >
              <icon-documents-file-checkmark iconColor="secondary" />
            </PvButton>
          </template>
        </PvColumn>

        <PvColumn
          field="detailName"
          :header="lang.fileName"
          style="min-width: 12rem"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-left font-normal p-text-primary"
        >
        </PvColumn>

        <PvColumn
          style="width: 2rem"
          headerClass="h-center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="">
            <PvButton
              v-if="isOpen"
              type="button"
              class="p-button-danger"
              style="background: white; border: white"
              @click="deleteFile"
            >
              <icon-trash-delete-bin iconColor="error" />
            </PvButton>
          </template>
        </PvColumn>
      </PvDataTable>
    </div>

    <div class="flex justify-content-center pt-4">
      <PvButton class="w-3" @click="uploadFile" :disabled="checkDisable">
        <span class="w-full text-center font-md font-normal p-button-label">
          <i
            v-if="isUpLoading"
            class="pi pi-spin pi-spinner font-normal mr-1"
          ></i>
          {{ lang.uploadFile }}
        </span>
      </PvButton>
    </div>
    <template #footer></template>
  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import useImportExcel from "../../hooks/importExcel.js";
import useGetAccountingDetail from "../../hooks/getAccountingDetail.js";
import { useStore } from "vuex";
import { computed, ref, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
export default {
  emits: ["close", "data-upload"],
  setup(props, context) {
    const { lang } = useLang();
    const { textTableClass } = useTextStyle();
    const { formatDate, formatDateToISOString } = useFormat();
    const { validateFileCount, validateFileType, readFileContent } =
      useImportExcel();
    const {
      isSharedAccountingDetailReady,
      getAllAccountingDetails,
      accountingDetails,
    } = useGetAccountingDetail();

    const store = useStore();
    const router = useRouter();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.validateImport,
          ...lang.th.asset.assetRegister.edit,
          ...lang.th.asset.assetRegister.import,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.validateImport,
          ...lang.en.asset.assetRegister.edit,
          ...lang.en.asset.assetRegister.import,
        };
      }
    });

    onMounted(async () => {
      await getAllAccountingDetails();
    });

    let files = ref([]);
    let isOpen = ref(false);
    let file = ref(null);
    let isUpLoading = ref(false);
    let uploadFiles = ref([]);
    let titleHeaderImportData = ref("");
    let titleValidateImportData = ref("");
    let showValidateDialog = ref(false);
    let columnsArray = ref([]);

    let rawDatas = ref([]);

    // function
    const setLoading = (bool) => {
      isUpLoading.value = bool;
    };

    const initiateData = () => {
      files.value = [];
      isOpen.value = false;
      file.value = null;
      titleHeaderImportData.value = mergeLang.value.notificationDialogHeader;
      rawDatas.value = [];
    };

    const closeDialog = () => {
      context.emit("close");
      initiateData();
    };

    const resetFile = (event) => {
      if (event.target.files.length > 0) {
        event.target.value = null;
      }
    };

    const getImportAssetForm = async () => {
      try {
        const d = new Date();
        let dateText = d.toISOString();
        let payload = {
          key: `${process.env.VUE_APP_IMPORT_ASSET_TEMPLATE_S3_KEY}`,
          name: `import_asset_form_${dateText}.xlsx`,
        };
        await store.dispatch("upload/downloadFile", payload);
      } catch (error) {
        titleValidateImportData.value = error;
        showValidateDialog.value = true;
        setLoading(false);
      }
    };

    const downloadfile = async () => {
      setLoading(true);
      await getImportAssetForm();
      setLoading(false);
    };

    const addFile = () => {
      document.getElementById("add-file").click();
    };

    const deleteFile = () => {
      initiateData();
    };

    const handleFiles = async (event) => {
      const inputValue = event.target.files || event.dataTransfer.files;
      uploadFiles.value = Array.from(inputValue);

      //เช็คจำนวนไฟล์
      const checkFileCount = validateFileCount(uploadFiles.value, 1);
      if (checkFileCount.isError) {
        titleHeaderImportData.value = mergeLang.value.validateLimitFile;
        (titleValidateImportData.value =
          mergeLang.value.validateFileType.replace(
            ":name",
            checkFileCount.message
          )),
          (showValidateDialog.value = true);
        return;
      }

      //เช็คประเภทไฟล์
      file.value = inputValue[0];
      const checkFileType = validateFileType(file.value);
      if (checkFileType.isError) {
        titleHeaderImportData.value = mergeLang.value.validateImportFile;
        titleValidateImportData.value =
          mergeLang.value.validateFileType.replace(
            ":name",
            checkFileType.message
          );
        showValidateDialog.value = true;
        file.value = null;
        return;
      }

      isOpen.value = true;
      const fileName = file.value.name;
      files.value = [
        {
          detailName: fileName,
        },
      ];
    };

    const uploadFile = async () => {
      setLoading(true);
      if (file.value) {
        let condition = [
          {
            //หมายเลขทรัพย์สิน*
            name: "code",
            type: "string",
            wordLength: 30,
            isRequire: true,
          },
          {
            //กรรมสิทธิ์*
            name: "model",
            type: "string",
            wordLength: 100,
            isRequire: true,
          },
          {
            //ชื่อทรัพย์สิน (ไทย)*
            name: "name",
            type: "string",
            wordLength: 100,
            isRequire: true,
          },

          {
            //ชื่อทรัพย์สิน (อังกฤษ)
            name: "nameEn",
            type: "string",
            wordLength: 50,
            isRequire: false,
          },
          {
            //รหัสบัญชี*
            name: "assetAccountCode",
            type: "string",
            isRequire: true,
          },
          {
            //บัญชีค่าเสื่อมราคา*
            name: "depreAccountCode",
            type: "string",
            isRequire: true,
          },
          {
            //บัญชีค่าเสื่อมราคาสะสม*
            name: "accumAccountCode",
            type: "string",
            isRequire: true,
          },
          {
            //เลขที่ใบสำคัญ/ใบกำกับภาษี*
            name: "taxInvoiceCode",
            type: "string",
            wordLength: 50,
            isRequire: true,
          },
          {
            //วันที่ซื้อ/วันที่ได้มา*
            name: "buyDate",
            type: "date",
            isRequire: true,
          },
          {
            //ราคาที่ซื้อ/มูลค่าที่ได้มา*
            name: "buyPrice",
            type: "decimal",
            wordLength: 25,
            isRequire: true,
          },
          {
            //มูลค่าที่นำไปคำนวณ*
            name: "deprePrice",
            type: "decimal",
            wordLength: 25,
            isRequire: true,
          },
          {
            //ราคาตลาด
            name: "marketPrice",
            type: "decimal",
            wordLength: 25,
            isRequire: false,
          },
          {
            //ค่าใช้จ่ายอื่นๆ
            name: "otherCost",
            type: "decimal",
            wordLength: 25,
            isRequire: false,
          },
          {
            //วันที่เริ่มคิดค่าเสื่อม*
            name: "depreCalDate",
            type: "date",
            isRequire: true,
          },
          {
            //อัตราค่าเสื่อม ร้อยละ*
            name: "depreRate",
            type: "decimal",
            wordLength: 25,
            isRequire: true,
          },
          {
            //ค่าเสื่อมสะสมก่อนวันเริ่มคำนวณ
            name: "beforeDepre",
            type: "decimal",
            isRequire: false,
          },
          {
            //วิธีการคำนวนค่าเสื่อม*
            name: "depreciationFormula",
            type: "string",
            isRequire: true,
          },
          {
            //ลงบัญชีค่าเสื่อมทุกๆ*
            name: "depreciationPeriod",
            type: "string",
            isRequire: true,
          },
          {
            //สถานะ*
            name: "status",
            type: "string",
            isRequire: true,
          },
          {
            //ราคาซาก
            name: "depreRemain",
            type: "decimal",
            wordLength: 25,
            isRequire: false,
          },
          {
            //เลขที่เอกสารประกอบการขาย
            name: "saleRefer",
            type: "string",
            wordLength: 20,
            isRequire: false,
          },
          {
            //วันที่ตัดทรัพย์สิน
            name: "saleDate",
            type: "date",
            isRequire: false,
          },
          {
            //ราคาขาย
            name: "salePrice",
            type: "decimal",
            wordLength: 25,
            isRequire: false,
          },
        ];

        const result = await readFileContent(file.value, 2, 20000, condition);
        if (!result.success) {
          if (result.code == "001") {
            titleHeaderImportData.value =
              mergeLang.value.notificationDialogHeader;
            titleValidateImportData.value =
              mergeLang.value.validateImportNoData.replace(
                ":file",
                file.value.name
              );
          } else if (result.code == "002") {
            titleHeaderImportData.value =
              mergeLang.value.notificationDialogHeader;
            titleValidateImportData.value =
              mergeLang.value.validateImportMaxColumn.replace(
                ":maxColumn",
                "20,000"
              );
          } else {
            titleHeaderImportData.value =
              mergeLang.value.notificationDialogHeader;
            titleValidateImportData.value = result.message;
          }
          setLoading(false);
          showValidateDialog.value = true;
          return true;
        }
        rawDatas.value = result.data;
        validateAndPrepareData();
      } else {
        titleValidateImportData.value = mergeLang.value.validateAddfile;
        showValidateDialog.value = true;
        return true;
      }
    };

    const validateAndPrepareData = async () => {
      await getImportAssetByCodes();
      await checkShouldPrepareData();
    };

    const getImportAssetByCodes = async () => {
      const codes = [...new Set(rawDatas.value.map((item) => item["code"]))];
      let payload = {
        projectId: selectedProject.value.id,
        code: codes,
      };
      await store.dispatch("importAsset/getAssetCodes", payload);
      // console.log("assetCodes=", assetCodes.value);
    };

    const prepareData = async () => {
      let dataResult = [];
      let i = 0;
      for (const data of rawDatas.value) {
        let result = await mappingData(data);
        if (result) {
          i++;
          result = { ...result, id: i, itemNo: i };
          dataResult.push(result);
        }
      }
      // console.log("dataResult", dataResult);
      setLoading(false);
      await store.dispatch("importAsset/setImportAssets", dataResult);
      context.emit("data-upload");
      router.push({ name: "preview-asset" });
      initiateData();
    };

    const mappingData = async (data) => {
      data.importStatusCode = "wait";
      data.importStatusReason = "";
      data.importStatusDescription = "";
      data.markStatus = "";

      //เช็คข้อมูล หมายเลขทรัพย์สิน*
      if (data.code) {
        let assetCode = assetCodes.value.filter(
          (e) => e.code.trim() == data.code.trim()
        )[0];
        if (assetCode) {
          let error = {
            field: "code",
            type: "duplicateInDatabase",
            data: data.code,
          };
          data.error.push(error);
        }
      }

      //เช็คข้อมูล รหัสบัญชี*
      let assetAccountId = null;
      if (data.assetAccountCode) {
        let accounting = accountingDetails.value.find(
          (item) => item.code.trim() == data.assetAccountCode.trim()
        );
        if (!accounting) {
          let error = {
            field: "assetAccountCode",
            type: "doNotExist",
            data: data.assetAccountCode,
          };
          data.error.push(error);
        } else {
          if(accounting.activeStatus == "N"){
            let error = { field: "assetAccountCode", type: "inActive" };
            data.error.push(error);
          }else{
            assetAccountId = accounting.id;
          }
        }
      }

      //เช็คข้อมูล บัญชีค่าเสื่อมราคา*
      let depreAccountId = null;
      if (data.depreAccountCode) {
        let accounting = accountingDetails.value.find(
          (item) => item.code.trim() == data.depreAccountCode.trim()
        );

        if (!accounting) {
          let error = {
            field: "depreAccountCode",
            type: "doNotExist",
            data: data.depreAccountCode,
          };
          data.error.push(error);
        } else {
          if(accounting.activeStatus == "N"){
            let error = { field: "depreAccountCode", type: "inActive" };
            data.error.push(error);
          }else{
            depreAccountId = accounting.id;
          }
        }
      }

      //เช็คข้อมูล บัญชีค่าเสื่อมราคาสะสม*
      let accumAccountId = null;
      if (data.accumAccountCode) {
        let accounting = accountingDetails.value.find(
          (item) => item.code.trim() == data.accumAccountCode.trim()
        );

        if (!accounting) {
          let error = {
            field: "accumAccountCode",
            type: "doNotExist",
            data: data.accumAccountCode,
          };
          data.error.push(error);
        } else {
          if(accounting.activeStatus == "N"){
            let error = { field: "accumAccountCode", type: "inActive" };
            data.error.push(error);
          }else{
            accumAccountId = accounting.id;
          }
        }
      }

      //เช็คข้อมูล กรรมสิทธิ์*
      if (data.model) {
        let ownerships = ["ทรัพย์สินนิติ", "ทรัพย์สินร่วม"];
        if (!ownerships.includes(data.model)) {
          let error = { field: "model", type: "doNotExist" };
          data.error.push(error);
        }
      }

      //เช็คราคาที่ซื้อ/มูลค่าที่ได้มา
      if (!data.buyPrice || data.buyPrice <= 0) {
          let error = { field: "buyPrice", type: "invalid" };
          data.error.push(error);
      }
      //เช็คมูลค่าที่นำไปคำนวณ
      if (!data.deprePrice || data.deprePrice <= 0) {
          let error = { field: "deprePrice", type: "invalid" };
          data.error.push(error);
      }
      //เช็คอัตราค่าเสื่อมร้อยละ
      if (!data.depreRate || data.depreRate <= 0) {
          let error = { field: "depreRate", type: "invalid" };
          data.error.push(error);
      }   

      //เช็คข้อมูล วิธีการคำนวนค่าเสื่อม*
      if (data.depreciationFormula) {
        let depreciationFormulas = ["แบบเส้นตรง", "แบบลดน้อยถอยลง"]; // A, B
        if (!depreciationFormulas.includes(data.depreciationFormula)) {
          let error = { field: "depreciationFormula", type: "doNotExist" };
          data.error.push(error);
        }
      }

      //เช็คข้อมูล ลงบัญชีค่าเสื่อมทุกๆ*
      if (data.depreciationPeriod) {
        let depreciationPeriods = ["เดือน", "ปี"]; // M, Y
        if (!depreciationPeriods.includes(data.depreciationPeriod)) {
          let error = { field: "depreciationPeriod", type: "doNotExist" };
          data.error.push(error);
        }
      }

      //เช็คข้อมูล สถานะ*
      if (data.status) {
        let activeStatus = ["ใช้งาน", "ไม่ใช้งาน"]; // Y, N
        if (!activeStatus.includes(data.status)) {
          let error = { field: "status", type: "doNotExist" };
          data.error.push(error);
        }
      }

      if (data.error.length > 0) {
        data.importStatusCode = "invalid";
        data.importStatusDescription = data.error[0].field;
        let errorType = data.error[0].type;

        switch (data.error[0].type) {
          case "blank":
            errorType = mergeLang.value.validateBlank.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
          case "duplicate":
            errorType = mergeLang.value.validateDuplicateInFile.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
          case "duplicateInDatabase":
            errorType = mergeLang.value.validateDuplicateInDatabase
              .replace(":field", setFieldLangError(data.error[0].field))
              .replace(":table", mergeLang.value.asset);
            break;
          case "overflow":
            errorType = mergeLang.value.validateLength
              .replace(":field", setFieldLangError(data.error[0].field))
              .replace(":length", data.error[0].wordLength);
            break;
          case "doNotExist":
            errorType = mergeLang.value.validateDataNotFound.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
          case "invalid":
            errorType = mergeLang.value.validateType.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
          case "invalidDecimal":
            errorType = mergeLang.value.validateDecimalFormat.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
          case "format":
            errorType = mergeLang.value.validateDateFormat.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
          case "invalidAddress":
            errorType = mergeLang.value.validateAddress;
            break;
          case "incompleteData":
            errorType = mergeLang.value.validateIncompleteData.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
          case "inActive":
            errorType = mergeLang.value.validateInactive.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
            
        }
        data.importStatusReason = errorType;
      }

      data = {
        ...data,
        assetAccountId: assetAccountId,
        depreAccountId: depreAccountId,
        accumAccountId: accumAccountId,
        projectId: selectedProject.value.id,
        type: "Add",
      };
      return data;
    };

    const setFieldLangError = (field) => {
      switch (field) {
        case "code":
          return mergeLang.value.code;
        case "model":
          return mergeLang.value.ownership;
        case "name":
          return mergeLang.value.nameTh;
        case "nameEn":
          return mergeLang.value.nameEn;
        case "assetAccountCode":
          return mergeLang.value.assetAccount;
        case "depreAccountCode":
          return mergeLang.value.depreciationAccount;
        case "accumAccountCode":
          return mergeLang.value.accumAccount;
        case "taxInvoiceCode":
          return mergeLang.value.voucherCode;
        case "buyDate":
          return mergeLang.value.buyDate;
        case "buyPrice":
          return mergeLang.value.buyPrice;
        case "deprePrice":
          return mergeLang.value.calculateValue;
        case "marketPrice":
          return mergeLang.value.marketPrice;
        case "otherCost":
          return mergeLang.value.otherCost;
        case "depreCalDate":
          return mergeLang.value.depreciationDate;
        case "depreRate":
          return mergeLang.value.depreciationRate;
        case "beforeDepre":
          return mergeLang.value.accumDepreciationBeforeCal;
        case "depreciationFormula":
          return mergeLang.value.depreciationFormula;
        case "depreciationPeriod":
          return mergeLang.value.depreciationPeriod;
        case "activeStatus":
          return mergeLang.value.status;
        case "depreRemain":
          return mergeLang.value.depreciationRemain;
        case "saleRefer":
          return mergeLang.value.saleReference;
        case "saleDate":
          return mergeLang.value.cutOffAssetDate;
        case "salePrice":
          return mergeLang.value.salePrice;
        default:
          return field;
      }
    };

    const checkShouldPrepareData = async () => {
      if (isSharedAccountingDetailReady.value && rawDatas.value.length > 0) {
        await prepareData();
      }
    };

    //***computed***
    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });

    const shouldDisableCloseButton = computed(() => {
      return isUpLoading.value;
    });

    const checkDisable = computed(() => {
      if (!file.value || isUpLoading.value) {
        return true;
      } else {
        return false;
      }
    });

    const assetCodes = computed(() => {
      return store.getters["importAsset/assetCodes"];
    });

    const dataButtonDialog = computed(() => {
      let result = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
      return result;
    });

    watch(isSharedAccountingDetailReady, function () {
      checkShouldPrepareData();
    });

    return {
      lang: mergeLang,
      textTableClass,
      formatDate,
      formatDateToISOString,

      // data
      files,
      isOpen,
      file,
      isUpLoading,
      showValidateDialog,
      titleHeaderImportData,
      titleValidateImportData,
      columnsArray,

      //function
      setLoading,
      closeDialog,
      uploadFile,
      resetFile,
      handleFiles,
      downloadfile,
      addFile,
      deleteFile,

      //computed
      selectedProject,
      shouldDisableCloseButton,
      checkDisable,
      dataButtonDialog,
    };
  },
};
</script>

<style scoped>
.show-icon {
  opacity: 1;
  width: 48px;
  height: 48px;
  padding: 0.75rem;
}
</style>
