import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default function useGetEmployee() {
	const store = useStore();
	let searchText = ref(null);

	const Employees = computed(() => {
		return store.getters["shared/employeeEmployees"];
	});

	const EmployeeIsLastPage = computed(() => {
		return store.getters["shared/EmployeeIsLastPage"];
	});

	const filteredEmployees = computed(() => {
		if (!searchText.value) {
			//ช่องว่าง
			if (Employees.value) {
				const result = [...Employees.value];
				return result;
			} else {
				return null;
			}
		} else {
			return Employees.value.filter((Employee) => {
				return Employee.codeAndName.toLowerCase().includes(searchText.value.toLowerCase());
			});
		}
	});

	const searchEmployee = function (event) {
		// console.log(event);
		if (!event.query.trim().length) {
			//ช่องว่าง
			// จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
			searchText.value = "";
			searchText.value = null;
		} else {
			searchText.value = event.query;
		}
	};

	let isSharedEmployeeReady = ref(false);

	const getAllEmployees = async function () {
		if (!EmployeeIsLastPage.value) {
			await store.dispatch("shared/getAllEmployees");
		}
		isSharedEmployeeReady.value = true;
	};

	const getEmployeeByCode = function (code) {
		return Employees.value.find((Employee) => {
			return Employee.code.toLowerCase() === code.trim().toLowerCase();
		});
	};

	onMounted(async () => {
		await getAllEmployees();
	});

	return {
		searchEmployee,
		filteredEmployees,
		searchText,
		getEmployeeByCode,
		isSharedEmployeeReady,
	};
}
