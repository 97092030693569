export default {
    title: "คืนเงินค้ำประกัน",
    createRefundGuarantee: "สร้างคืนเงินค้ำประกัน",
    editRefundGuarantee: "แก้ไขคืนเงินค้ำประกัน",
    titleEdit: "รายละเอียดคืนเงินค้ำประกัน",
    documentCode: "เลขที่เอกสาร",
    documentDate: "วันที่เอกสาร",
    referenceCode: "เลขที่อ้างอิง",
    unitCode: "รหัสห้อง/ บ้าน",
    customerName: "ชื่อลูกค้า",
    employeeName: "ชื่อพนักงาน",
    searchPendingList: "ค้นหารายการเงินค้ำประกัน",
    productName: "ชื่อรายรับ",
    description: "รายละเอียด",
    refund: "ยอดคืน",
    tableEmpty: "ไม่มีรายการ",
    paidFor: "จ่ายให้",
    netAmount: "มูลค่าสุทธิ",
    addEditItem: "เพิ่ม/ แก้ไขรายการ",
    product: "รายรับ",
    cancelReason: "สาเหตุการยกเลิก",
    validateNoCancelReason: "กรุณาระบุสาเหตุการยกเลิก",
    cancelRefundGuaranteeSuccess: "คุณยกเลิกเอกสารคืนเงินค้ำประกัน :code สำเร็จ",
    balance: "คงเหลือ",
    price: "ราคา",
    pendingList: "รายการเงินค้ำประกัน",
    selectedAll: "จ่ายทุกรายการ",
    selectedList: "จ่ายรายการที่เลือก",
    receiptCode: "เลขที่ใบเสร็จ",
    receiptDate: "วันที่ใบเสร็จ",
    createRefundGuaranteeSuccess: "คุณสร้างรายการคืนเงินค้ำประกันสำเร็จ",
    editRefundGuaranteeSuccess: "คุณแก้ไขรายการคืนเงินค้ำประกันสำเร็จ",
    createNewRefundGuarantee: "สร้างเอกสารคืนเงินค้ำประกันใหม่",
    askForCreateNewDoc: "หากคุณสร้างเอกสารคืนเงินค้ำประกันใหม่ระบบจะไม่บันทึกการแก้ไข",
    askForCreateNewDocConfirm: "คุณยืนยันที่จะสร้างเอกสารคืนเงินค้ำประกันใหม่ใช่หรือไม่?",
    validateNoDate: "กรุณาเลือกวันที่เอกสาร",
}