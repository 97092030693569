export default {
  project(state) {
    return state.project;
  },
  projectTypes(state) {
    return state.projectTypes;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
};
