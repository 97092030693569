export default {
    toolbarCreateBookBank: "สร้างบัญชีธนาคาร",
    toolbarEditBookBank: "แก้ไขบัญชีธนาคาร",
    activeStatusY: "ใช้งานอยู่",
    activeStatusN: "ไม่ใช้งาน",
    bookBankInformation: "ข้อมูลบัญชี",
    depositPeriod: "จำนวนวันที่ฝาก",
    day: "วัน",
    bookBankType: "ประเภทบัญชี",
    bookBankTypeC: "กระแสรายวัน",
    bookBankTypeS: "สะสมทรัพย์",
    bookBankTypeF: "ฝากประจำ",
    bookBankDetail: "รายละเอียดบัญชี",
    bankBookUseType: "บัญชีใช้งาน",
    bankBookUseTypeR: "รับชำระ",
    bankBookUseTypeP: "จ่ายชำระ",
    bankBookUseTypeB: "รับและจ่ายชำระ",
    depositName: "ชื่อเงินฝาก",
    accounting: "การบันทึกบัญชี",
    objective: "วัตถุประสงค์",
    depositAmount: "ยอดเงินฝาก",
    interestRate: "อัตราดอกเบี้ย",
    balanceAmount: "ยอดเงินคงเหลือ",
    dueDate: "วันครบกำหนด",
    remark: "หมายเหตุ",
    validateDepositPeriod: "ระบุจำนวนวัน",
    bank: "ธนาคาร",
    validateBank: "เลือกธนาคาร",
    branch: "สาขา",
    validateBranch: "ระบุสาขา",
    bookBankName: "ชื่อบัญชี",
    validateBookBankName: "ระบุชื่อบัญชี",
    bookBankNo: "เลขที่บัญชี",
    validateBookBankNo: "ระบุเลขที่บัญชี",
    validateCode: "ระบุรหัส",
    validateDepositName: "ระบุชื่อเงินฝาก",
    validateObjective: "ระบุวัตถุประสงค์",
    validateRemark: "ระบุหมายเหตุ",
    validateAccointing: "เลือกรหัสบัญชี",
    validateDate: "เลือกวันที่",
    validateDepositAmount: "ระบุยอดเงินฝาก",
    activeStatus: "การใช้งาน",
    isForTransfer: "เป็นบัญชีสำหรับโอนเท่านั้น",
    deleteBookBank: "ลบรายการ",
    deleteBookBankSuccess: "ลบบัญชีธนาคารสำเร็จ",
    saveBookBankSuccess: "เพิ่มบัญชีธนาคารสำเร็จ",
    editBookBankSuccess: "แก้ไขบัญชีธนาคารสำเร็จ",
    confirmDeleteBookBank: "คุณคุณต้องการยืนยันการลบบัญชีธนาคารใช่หรือไม่ ?",
    confirmSaveBookBankDialogHeader: "ยืนยันการแก้ไข",
    confirmSaveBookBank: "คุณต้องการยืนยันการแก้ไขข้อมูลธนาคารใช่หรือไม่?",
    beginDate: "วันที่เปิดบัญชี",
}