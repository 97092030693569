export default {
    invoices(state) {
      return state.invoices;
    },
    totalListPage(state) {
      return state.totalListPage;
    },
    totalPage(state) {
      return state.totalPage;
    },
    currentPage(state) {
      return state.currentPage;
    },
    rows(state) {
      return state.rows;
    },
    selectedInvoice(state) {
      return state.selectedInvoice;
    },
    invoiceToGl(state) {
      return state.invoiceToGl;
    },
    invoiceNextPage(state) {
      return state.currentPage + 1;
    },
    invoiceIsLastPage(_, getters) {
      return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
    }
};