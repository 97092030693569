export default {
  resetImportResidents(context) {
    context.commit("resetImportResidents");
  },
  importResidents(context, payload) {
    context.commit("setImportResidents", payload);
  },
  async getResidentCodes(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Customer/GetCustomerImport";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get resident codes");
      throw error;
    }
    // console.log("getResidentCodes",responseData);

    context.commit("setResidentCodes", responseData);
  },
};
