export default {
    addNoticeByUnit(state){
      return state.addNoticeByUnit;
    },
    notices(state) {
      return state.notices;
    },
    notice(state) {
      return state.notice;
    },
    totalPage(state) {
      return state.totalPage;
    },
    currentPage(state) {
      return state.currentPage;
    },
    rows(state) {
      return state.rows;
    },
    noticesGroupDate(state){
      return state.noticesGroupDate;
    },
    noticesByAtDate(state){
      return state.noticesByAtDate;
    },
    blob(state) {
      return state.blob;
    }
  };
  