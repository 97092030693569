export default {
    setParams(state, payload) {
        state.params = payload;
    },
    setImportExistUnitResidents(state, payload) {
        state.importExistUnitResidents = [
            ...state.importExistUnitResidents, 
            ...payload,
        ];
    },
    resetImportExistUnitResidents(state) {
        state.importExistUnitResidents = [];
    },
}