export default {
  async getDebtFreeRequests(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Debtfree/DebtfreeRequest";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }
    if (payload.page) {
      url = url + "&page=" + payload.page;
    }
    if (payload.projectId) {
      url = url + "&projectId=" + payload.projectId;
    }
    if (payload.dateStart) {
        url = url + "&dateStart=" + payload.dateStart;
    }
    if (payload.dateEnd) {
        url = url + "&dateEnd=" + payload.dateEnd;
    }
    if (payload.unitCodeAndAddressNo) {
        url = url + "&unitCodeAndAddressNo=" + payload.unitCodeAndAddressNo;
    }
    if (payload.ownerName) {
        url = url + "&ownerName=" + payload.ownerName;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get debt free request.");
      throw error;
    }

    // console.log("debtFreeRequests",responseData);

    context.commit("setDebtFreeRequests", responseData);
  },
  async getDebtFreeRequestById(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + '/Debtfree/DebtfreeRequest/' + payload;
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || `Failed to get debt free request id : ${payload}`);
      throw error;
    }

    // console.log("getDebtFreeRequestById", responseData);

    context.commit("setSelectedDebtFreeRequest", responseData.data);
  },
  resetDebtFreeRequest(context) {
    context.commit("setSelectedDebtFreeRequest", null);
  },
  selectedUnit(context, payload) {
    context.commit("setSelectedUnit", payload);
  },
  resetSelectedUnit(context) {
    context.commit("setSelectedUnit", null);
  },
  async cancelDebtFreeRequest(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Debtfree/DebtfreeRequest/Cancel`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to cancel debt free request.');
      throw error;
    }

    context.commit("setSelectedDebtFreeRequest", responseData.data);
  },
  async approveDebtFreeRequest(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Debtfree/DebtfreeRequest/Approve`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to submit approve debt free request.');
      throw error;
    }

    context.commit("setSelectedDebtFreeRequest", responseData.data);
  },
  async createOrUpdateDebtFreeRequest(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Debtfree/DebtfreeRequest/${payload.type}`;

    const fallbackErrorMessage = `Failed to ${payload.type} debt free request.`
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: payload.type === 'Add' ? 'post' : 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }

    context.commit("setSelectedDebtFreeRequest", responseData.data);
  }
};
