<template>
	<base-container>
		<base-product-ap-modal
			v-model:visible="showProductModal"
			:search="searchText"
			@close="showProductModal = false"
			@product-selected="productModalSelected"
		></base-product-ap-modal>

		<purchase-order-dialog
			v-model:visible="showPurchaseOrder"
			@close="showPurchaseOrder = false"
			@select-purchase-order="selectedPurchaseOrder"
		></purchase-order-dialog>

		<base-form-print-modal
			v-model:visible="showFormPrintModal"
			:search="searchTextPrint"
			@close="showFormPrintModal = false"
			@path-selected="formPrintModalSelected"
		></base-form-print-modal>

		<div class="flex justify-content-between align-items-center">
			<div class="my-3">
				<PvButton
					icon="pi pi-chevron-left"
					:label="lang.back"
					@click="goBack"
					class="mr-2 p-button-secondary"
				/>
			</div>
			<h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
		</div>
		<transition name="fade" mode="out-in">
			<div class="surface-card shadow-2 p-3 border-round">
				<div class="pt-2 pb-1">
					<PvButton
						icon="pi pi-file"
						:label="lang.create"
						class="p-button-secondary mr-2 mb-2"
						@click="createDocument"
					/>
					<PvButton
						icon="pi pi-save"
						:label="lang.save"
						class="mr-2 mb-2"
						@click="save"
						:disabled="isCheckDisabled"
					/>
					<PvButton
						icon="pi pi-save"
						:label="lang.saveAs"
						class="mr-2 mb-2"
						@click="saveAs"
						:disabled="isCheckSaveAs"
					/>
					<PvButton
						icon="pi pi-file-pdf"
						:label="lang.printPdf"
						class="mr-2 mb-2"
						@click="selectPrintForm"
						:disabled="isCreateDocument"
					/>
					<PvButton
						icon="pi pi-check-circle"
						:label="lang.approve"
						class="mr-2 mb-2"
						@click="askApprove"
						:disabled="isCreateDocument || isCheckDisabled"
					/>
					<PvButton
						icon="pi pi-replay"
						:label="lang.reApprove"
						class="mr-2 mb-2"
						@click="askForRevertApprove"
						:disabled="isCreateDocument || isApproved"
						v-if="isCheckRoleApprove"
					/>
					<PvButton
						icon="pi pi-upload"
						:label="lang.import"
						class="mr-2 mb-2"
						@click="importPO"
						:disabled="isCheckDisabled"
					/>
					<PvButton
						icon="pi pi-times"
						:label="lang.cancel"
						class="p-button-outlined p-button-danger mr-2 mb-2"
						@click="askForCancel"
						:disabled="isCreateDocument || isCheckDisabled"
					/>
				</div>
				<div class="flex flex-wrap" :class="textFormClass">
					<div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 flex flex-wrap">
						<!-- ************************ -->
						<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
							<label for="code">{{ lang.code }}</label>
							<PvInputText
								id="code"
								v-model="code"
								type="text"
								class="inputfield w-full mt-1 p input-disable"
								readonly
							/>
						</div>
						<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
							<label for="refCode">{{ lang.refCode }}</label>
							<PvInputText
								id="refCode"
								v-model="refCode"
								type="text"
								class="inputfield w-full mt-1 p"
								:class="{ 'p-invalid': refCodeError }"
                @keypress="refCodeError = false"
							/>
							<small v-if="refCodeError" id="refCode-help" class="p-error">{{
								lang.validateRefCode
							}}</small>
						</div>
						<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
							<label for="date">{{ lang.date }}</label>
							<PvCalendar
								id="date"
								v-model="date"
								class="inputfield w-full mt-1"
								showIcon
								dateFormat="dd/mm/yy"
                @date-select="dateSelected"
							/>
						</div>

						<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
							<label for="vatSendDate">{{ lang.requestDate }}</label>
							<PvCalendar
								id="vatSendDate"
								v-model="vatSendDate"
								class="inputfield w-full mt-1"
								showIcon
								dateFormat="dd/mm/yy"
							/>
						</div>

						<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
							<label for="dueDate">{{ lang.dueDate }}</label>
							<PvCalendar
								id="dueDate"
								v-model="dueDate"
								class="inputfield w-full mt-1"
								showIcon
								dateFormat="dd/mm/yy"
                @date-select="setCreditTerm"
							/>
						</div>
						<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
							<label for="periodDate">{{ lang.periodDate }}</label>
							<PvInputNumber
								id="periodDate"
								v-model="periodDate"
								class="inputfield w-full mt-1 p"
								inputClass=" text-right"
                @blur="editCreditTerm($event)"
                @focus="$event.target.select()"
							/>
						</div>

						<div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
							<label for="supplier">{{ lang.supplier }}</label>
							<base-supplier-auto-complete
								id="supplier"
								v-model="selectedSupplier"
								class="inputfield w-full mt-1"
								@load-finish="supplierDataReady"
								:class="{ 'p-invalid': supplierError }"
                @item-select="selectSupplier()"
							/>
							<small v-if="supplierError" id="supplier-help" class="p-error">{{
								lang.validateNoSupplierSelect
							}}</small>
						</div>
						<div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
							<label for="receiptEmployee">{{ lang.employee }}</label>
							<base-employee-auto-complete
								id="receiptEmployee"
								v-model="selectedEmployee"
								class="inputfield w-full mt-1"
								@load-finish="employeeDataReady"
								:disabled="isDocumentLocked"
								:class="{ 'p-invalid': employeeError }"                
                @item-select="employeeError = false"
							/>
							<small v-if="employeeError" id="employee-help" class="p-error">{{
								lang.validateNoEmployeeSelect
							}}</small>
						</div>

						<div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
							<label for="remark1">{{ lang.remark1 }}</label>
							<PvInputText
								id="remark1"
								v-model="remark1"
								type="text"
								class="inputfield w-full mt-1 p"
							/>
						</div>

						<div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
							<label for="remark2">{{ lang.remark2 }}</label>
							<PvInputText
								id="remark2"
								v-model="remark2"
								type="text"
								class="inputfield w-full mt-1 p"
							/>
						</div>

						<!-- ************************ -->
					</div>
				</div>

				<PvDataTable
					showGridlines
					:value="details"
					dataKey="id"
					responsiveLayout="scroll"
					:class="textTableClass"
					editMode="cell"
					class="editable-cells-table"
					@cell-edit-complete="onCellEditComplete"
				>
          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
					<PvColumn
						field="itemNo"
						:header="lang.itemNo"
						style="min-width: 4rem"
						headerClass="h-center"
						bodyClass="text-center"
					>
					</PvColumn>
					<PvColumn
						field="productCode"
						:header="lang.columnCode"
						style="min-width: 4rem"
						headerClass="h-center"
						sortable
					>
						<template #editor="{ data, field }">
							<PvInputText type="text" v-model.trim="data[field]" 
								:disabled="isDocumentLocked"/>
						</template>
					</PvColumn>
					<PvColumn
						field="productName"
						:header="lang.columnCodeName"
						style="min-width: 4rem"
						headerClass="h-center"
						sortable
					></PvColumn>
					<PvColumn
						field="productUnitName"
						:header="lang.columnUnitName"
						style="min-width: 6rem"
						headerClass="h-center"
						sortable
					></PvColumn>
					<PvColumn
						field="unitPrice"
						:header="lang.columnUnitPrice"
						style="min-width: 6rem"
						headerClass="h-center"
						bodyClass="text-right"
						sortable
					>
						<template #editor="{ data, field }">
              <PvInputNumber
                id="unitPrice"
                class="inputfield w-full"
                inputClass="text-right"
                v-model="data[field]"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                :min="0"
                @focus="$event.target.select()"
								:disabled="isDocumentLocked"
              />
						</template>
						<template #body="slotProps">
							{{ formatCurrencyTable(slotProps.data, "unitPrice") }}
						</template>

						<!-- <template #editor="{ data, field }">
              <PvInputText type="number" v-model.number="data[field]" />
            </template> -->
						<!-- <template #body="slotProps">
              {{ formatCurrency(slotProps.data.unitPrice) }}
            </template> -->
					</PvColumn>

					<PvColumn
						field="quantity"
						:header="lang.columnQuantity"
						style="min-width: 4rem"
						headerClass="h-center"
						bodyClass="text-right"
						sortable
					>
						<template #editor="{ data, field }">
              <PvInputNumber
                id="quantity"
                class="inputfield w-full"
                inputClass="text-right"
                v-model="data[field]"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                :min="0"
                @focus="$event.target.select()"
								:disabled="isDocumentLocked"
              />
						</template>
					</PvColumn>

          <PvColumn
            field="discountDescription"
            :header="lang.columnDiscount"
            headerClass="h-center"
            bodyClass="text-right"
            style="min-width: 4rem"
            sortable
          >
            <template #editor="{ data, field }">
              <!-- <PvInputText
                type="text"
                v-model.trim="data[field]"
                @focus="$event.target.select()"
                @keypress="checkInputDiscount($event)"
								:disabled="isDocumentLocked"
              /> -->
    					<base-discount-input-text 
                @focus="$event.target.select()"
                @emit-discount-amount="setDiscountColumnText"
								:disabled="isDocumentLocked"
                :defaultDiscount="data[field]"/>
            </template>
          </PvColumn>

					<PvColumn
						field="discountAmount"
						:header="lang.columnDiscountAmt"
						headerClass="h-center"
						bodyClass="text-right"
						style="min-width: 4rem"
						sortable
					>
						<!-- <template #body="slotProps">
              {{ formatCurrency(slotProps.data.discountAmount) }}
            </template> -->
						<template #body="slotProps">
							{{ formatCurrencyTable(slotProps.data, "discountAmount") }}
						</template>
					</PvColumn>

					<PvColumn
						field="totalAmount"
						:header="lang.columnNetAmount"
						style="min-width: 6rem"
						headerClass="h-center"
						bodyClass="text-right"
						sortable
					>
						<!-- <template #body="slotProps">
              {{ formatCurrency(slotProps.data.totalAmount) }}
            </template> -->
						<template #body="slotProps">
							{{ formatCurrencyTable(slotProps.data, "totalAmount") }}
						</template>
					</PvColumn>
					<PvColumn
						style="min-width: 4rem"
						headerClass="h-center"
						bodyStyle="text-align: center; overflow: visible"
					>
						<template #body="slotProps">
							<PvButton
								type="button"
								class="p-button-danger p-button-text py-0"
								icon="pi pi-times"
								@click="deleteRow(slotProps.data.id)"
                :disabled="!slotProps.data.productCode"
							></PvButton>
						</template>
					</PvColumn>
				</PvDataTable>

				<div class="flex flex-wrap" :class="textFormClass">
					<div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 flex flex-wrap">
						<div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
							<label for="totalAmount">{{ lang.inputTotalAmount }}</label>
							<PvInputNumber
								id="totalAmount"
								class="inputfield w-full mt-1"
								inputClass="input-number-disable text-right"
								v-model="totalAmount"
								mode="decimal"
								:minFractionDigits="2"
								:maxFractionDigits="2"
								disabled
							/>
						</div>

            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="discount">{{ lang.inputDiscount }}</label>
              <!-- <PvInputText
                id="discount"
                v-model="discount"
                type="text"
                class="inputfield w-full mt-1 p text-right"
                @focus="$event.target.select()"
                @keypress="checkInputDiscount($event)"                
                @input="editDiscount($event)"
								:disabled="isDocumentLocked"
              /> -->
    					<base-discount-input-text 
                class="inputfield w-full mt-1 p text-right" 
                @emit-discount-amount="setDiscountText"
                @focus="$event.target.select()"
                :defaultDiscount="discount"
								:disabled="isDocumentLocked"/>
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="discountTotalAmount">{{
                lang.inputDiscountTotalAmount
              }}</label>
              <PvInputNumber
                id="discountTotalAmount"
                class="inputfield w-full mt-1"
                inputClass="input-number-disable text-right"
                v-model="discountTotalAmount"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                disabled
                :class="{ 'p-invalid': discountAmountError }"
              />
              <small v-if="discountAmountError" id="supplier-help" class="p-error">{{
                lang.validateDiscountAmount
              }}</small>
            </div>

						<div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
							<label for="paymentTotalAmount">{{ lang.inputPaymentTotalAmount }}</label>
							<PvInputNumber
								id="paymentTotalAmount"
								class="inputfield w-full mt-1"
								inputClass="input-number-disable text-right"
								v-model="paymentTotalAmount"
								mode="decimal"
								:minFractionDigits="2"
								:maxFractionDigits="2"
								disabled
							/>
						</div>

						<div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
							<label for="tax">{{ lang.inputTax }}</label>
							<PvInputNumber
								id="tax"
								class="inputfield w-full mt-1"
								inputClass="input-number-disable text-right"
								v-model="tax"
								mode="decimal"
								:minFractionDigits="2"
								:maxFractionDigits="2"
								disabled
							/>
						</div>
            
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="taxAmount">{{ lang.inputTaxAmount }}</label>
              <label class="font-xs p-text-disabled">{{lang.calculatedFromSystem}}{{ this.formatCurrency(this.calculateTaxFromSystem) }}</label>              
              <div class="flex justify-content-between align-items-center">
                <div class="w-full pr-2">
                  <PvInputNumber
                    id="taxAmount"
                    class="inputfield w-full mt-1"
                    v-model="taxAmount"
                    mode="decimal"
                    :minFractionDigits="2"
                    :maxFractionDigits="2"
                    placeholder="0.00"
                    @dblclick="doubleClickTaxAmount($event)"
                    @blur="setNetAmount($event)"
                    :disabled="isTaxAmountDisabled" 
                    :inputClass="classDisableTaxAmount"
                  />                 
                </div>
                <PvButton
                  type="button"
                  class="p-button-secondary p-button-text mt-1 p-1"
                  @click="doubleClickTaxAmount()"
                  v-if="!this.tax == 0"    
                >
                  <icon-pen-edit-text iconColor="secondary" width="24" height="24" />
                </PvButton>
              </div>
            </div>
						<div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
							<label for="netAmount">{{ lang.inputNetAmount }}</label>
							<PvInputNumber
								id="netAmount"
								class="inputfield w-full mt-1"
								inputClass="input-number-disable text-right"
								v-model="netAmount"
								mode="decimal"
								:minFractionDigits="2"
								:maxFractionDigits="2"
								disabled
							/>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useGetProductAP from "../../../hooks/getProductAP.js";
import purchaseOrderDialog from "../../../components/AP/BasePurchaseOrderDialog.vue";
export default {
  props: ["apBook", "id", "projectCode"],
  components: {
    purchaseOrderDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass, textAlertClass } =
      useTextStyle();
    const {
      formatCurrencyTable,
      formatCurrency,
      formatDate,
      formatDateToISOString,
    } = useFormat();
    const { isSharedProductReady, getProductByCode, searchText } =
      useGetProductAP();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ap.purchaseInvoice.listing,
          ...lang.th.ap.purchaseInvoice.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ap.purchaseInvoice.listing,
          ...lang.en.ap.purchaseInvoice.edit,
        };
      }
    });

    const {
      getFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints
    } = useGetFormPrint();

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrencyTable,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      textAlertClass,
      getFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints,
      isSharedProductReady,
      getProductByCode,
      searchText,
    };
  },
  data() {
    return {
      showProductModal: false,
      showPurchaseOrder: false,
      editingDetail: null,
      code: "",
      refCode: "",
      refCodeError: false,
      date: null,
      vatSendDate: null,
      periodDate: 0,
      dueDate: null,
      selectedSupplier: null,
      supplierError: false,
      selectedEmployee: null,
      employeeError: false,
      section: "",
      project: "",
      task: "",
      remark1: "",
      remark2: "",

      currencyId: "",
      // CurrencyAmount: "",
      totalAmount: 0,
      discount: "",
      discountTotalAmount: 0,
      payment: 0,
      paymentTotalAmount: 0,
      tax: 0,
      taxAmount: 0,
      netAmount: 0,
      vatInclude: "",

      details: [],
      isSharedSupplierReady: false,
      isSharedEmployeeReady: false,

      selectedAPBook: null,
      selectedBookId: "",
      selectedBookCode: "",

      showFormPrintModal: false,
      path: "",
      dataPrint: [],
      categoryPrint: "",
      discountAmountError : false,
      isSetData: true,

      calculateTaxFromSystem: 0.00,
      isTaxAmountDisabled: true,
      discountText: "",
    };
  },
  async mounted() {
    // console.log("this.isCreateDocument=", this.isCreateDocument);
    this.initiateData();
    this.storageName = `erp_search_PurchaseInvoiceEntryList_${this.selectedProject.code}_${this.userCode}`;

    const localSearch = localStorage.getItem(this.storageName);
    if (localSearch) {
      let search = JSON.parse(localSearch);
      this.selectedAPBook = search.selectedAPBook;
      this.selectedBookId = search.selectedAPBook.bookId;
      this.selectedBookCode = search.selectedAPBook.bookCode;

      this.tax = search.selectedAPBook.taxRate;
      this.vatInclude = search.selectedAPBook.vatInclude;
    }

    if (!this.isCreateDocument) {
      this.setLoading(true);
      const where = {
        id: this.id,
      };
      await this.$store.dispatch("apPurchaseInvoice/getPurchaseInvoice", {
        where,
      });
    }

    this.setLoading(false);
    await this.getRoleByDocumentBook();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    supplierDataReady() {
      this.isSharedSupplierReady = true;
    },
    employeeDataReady() {
      this.isSharedEmployeeReady = true;
      if (this.isCreateDocument) {
        this.selectedEmployee = this.sharedEmployees.find(
          (employee) => employee.code === this.employee.code
        );
      }
    },
    goBack() {
      const localSearch = localStorage.getItem(this.storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        PurchaseInvoiceEntry: "Entry",
      };
      // console.log("search=",search);
      localStorage.setItem(this.storageName, JSON.stringify(search));
      this.$router.push({ name: "ap-purchase-invoice-apBook", params: { apBook: this.apBook }});
    },
    checkShouldSetData() {
      if (
        this.isSharedSupplierReady &&
        this.purchaseInvoice &&
        this.isSharedEmployeeReady
        // !this.isCreateDocument
      ) {
        this.setData();
      }
    },
    setData() {
      this.isSetData = false;
      // console.log("setData=", this.purchaseInvoice);
      // let periodDate = this.GetPeriodDate(this.purchaseInvoice.vatSendDate,this.purchaseInvoice.dueDate)
      this.code = this.purchaseInvoice.code;
      this.refCode = this.purchaseInvoice.refCode;
      this.date = new Date(this.purchaseInvoice.date);
      this.vatSendDate = new Date(this.purchaseInvoice.vatSendDate);
      // this.periodDate = periodDate;
      this.dueDate = new Date(this.purchaseInvoice.dueDate);
      // this.selectedSupplier = this.purchaseInvoice.supplierId;
      // this.selectedEmployee = this.purchaseInvoice.employeeId;
      this.section = this.purchaseInvoice.section;
      this.project = this.purchaseInvoice.project;
      this.task = this.purchaseInvoice.task;
      this.remark1 = this.purchaseInvoice.remark1;
      this.remark2 = this.purchaseInvoice.remark2;
      this.details = this.purchaseInvoice.details;

      this.currencyId = this.purchaseInvoice.currencyId;
      // this.CurrencyAmount = this.purchaseInvoice.exchRate;
      this.totalAmount = this.purchaseInvoice.totalAmount;
      this.discount = this.purchaseInvoice.discountDescription;
      this.discountTotalAmount = this.purchaseInvoice.discountAmount;
      this.payment = 0;
      this.paymentTotalAmount = 0;
      this.tax = this.purchaseInvoice.vatRate;
      this.taxAmount = this.purchaseInvoice.vatAmount;
      this.netAmount = this.purchaseInvoice.netAmount;

      this.selectedSupplier = this.sharedSuppliers.find(
        (supplier) => supplier.id === this.purchaseInvoice.supplierId
      );

      this.selectedEmployee = this.sharedEmployees.find(
        (employee) => employee.id === this.purchaseInvoice.employeeId
      );

      // console.log("this.vatInclude=", this.vatInclude);
      if (this.purchaseInvoice.isVatInclude === "Y") {
        this.paymentTotalAmount = this.netAmount - this.taxAmount;
      } else {
        this.paymentTotalAmount = this.totalAmount - this.discountTotalAmount;
      }

      this.setCreditTerm();

      this.addEmptyRow();
      this.updateItemNumber();
      // this.updateTotalAmount();
      
      this.calculateNetAmount();
      this.setNetAmount(null);

      this.setLoading(false);
    },
    onCellEditComplete(event) {
      // console.log("onCellEditComplete=", event);
      // console.log("this.isDocumentLocked=", this.isDocumentLocked);
      if (!this.isDocumentLocked) {
        if (event.field === "discountDescription") {
          this.calculateDetail(
            event.data,
            event.data.unitPrice,
            event.data.quantity,
            this.discountText
          );
        }

        let value = event.value;
        let newValue = event.newValue;
        if (typeof value === "string") {
          value = value.trim();
        }
        if (typeof newValue === "string") {
          newValue = newValue.trim();
        }
        if (value === newValue) {
          return;
        }

        if (event.field === "unitPrice") {
          // console.log("event.data=",event.data);
          if (event.data.referenceId != null && event.data.referenceId.trim()) {
            if (value != newValue) {
              // console.log("ข้อมูลจาก PO ไม่สามารถเปลี่ยนราคาได้");
              this.$swal({
                icon: "warning",
                title: this.lang.validateUpdatePrice,
                confirmButtonText: this.lang.close,
                allowOutsideClick: false,
              });
              return;
            }
          }

          if (newValue < 0 || !newValue) {
            event.data.unitPrice = 0;
          } else {
            this.calculateDetail(
              event.data,
              newValue,
              event.data.quantity,
              event.data.discountDescription
            );
          }
        } else if (event.field === "quantity") {
          // console.log("onCellEditComplete_quantity=", event);
          // console.log("onCellEditComplete_quantity00=", event.data);
          if (newValue < 1 || !newValue) {
            newValue = 1;
            this.calculateDetail(
              event.data,
              event.data.unitPrice,
              newValue,
              event.data.discountDescription
            );
          } else if (newValue > event.data.sourceQuantity) {
            event.data.quantity = event.data.sourceQuantity;
            this.calculateDetail(
              event.data,
              event.data.unitPrice,
              event.data.sourceQuantity,
              event.data.discountDescription
            );
          } else {
            this.calculateDetail(
              event.data,
              event.data.unitPrice,
              newValue,
              event.data.discountDescription
            );
          }
        } else if (event.field === "productCode") {
          //หาว่า Code ที่ระบุมีอยู่จริงหรือไม่

          const product = this.getProductByCode(newValue);
          //หากมี ก็ update ข้อมูลใน record นั้น
          if (product) {
            if (newValue === "") {
              return;
            }
            if (!value) {
              // console.log("!value");
              this.productSelect(event.data, product);
            } else if (value !== newValue) {
              this.$confirm.require({
                message: this.lang.askForProductChange,
                header: this.lang.confirmDialogHeader,
                icon: "pi pi-exclamation-triangle",
                accept: () => {
                  //callback to execute when user confirms the action
                  this.productSelect(event.data, product);
                },
              });
            }
            // console.log("this.$refs=",this.$refs);
            // this.$refs.unitPrice0.focus();
            // this.$refs["field-" + (this.fields.length - 1)][0].$el.focus()
          } else {
            // console.log("ไม่มีcode!!!", newValue);
            this.$confirm.require({
              message: this.lang.askForSelectProduct.replace(":code", newValue),
              header: this.lang.confirmDialogHeader,
              icon: "pi pi-exclamation-triangle",
              accept: () => {
                //callback to execute when user confirms the action
                this.editingDetail = event.data;
                this.searchText = newValue;
                this.showProductModal = true;
              },
            });
          }
        }
      }
    },
    calculateDetail(detail, unitPrice, quantity, discountDescription) {
      detail.unitPrice = unitPrice;
      detail.quantity = quantity;
      detail.discountDescription = discountDescription;
      // console.log("discountDescription=",discountDescription);
      if (!discountDescription) discountDescription = "0";
      if (discountDescription.includes("%")) {
        let totalAmt = unitPrice * quantity;
        detail.discountAmount =
          (parseFloat(discountDescription) / 100) * totalAmt;
        detail.totalAmount =
          unitPrice * quantity -
          (parseFloat(discountDescription) / 100) * totalAmt;
      } else {
        detail.discountAmount = parseFloat(discountDescription);
        detail.totalAmount =
          unitPrice * quantity - parseFloat(discountDescription);
      }
      
      if (detail.totalAmount < 0) {
        detail.discountDescription = "";
        detail.discountAmount = 0;
        detail.totalAmount = unitPrice * quantity;
      }

      this.updateTotalAmount();

      this.calculateNetAmount();
      this.setTaxAmountFromSystem();
    },
    setCreditTerm() {
      // console.log("this.date=",this.date);
      // console.log("this.dueDate=",this.dueDate);
      if (this.date === null) this.date = new Date();
      if (this.dueDate === null) this.dueDate = new Date();
      const utc1 = Date.UTC(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate()
      );
      const utc2 = Date.UTC(
        this.dueDate.getFullYear(),
        this.dueDate.getMonth(),
        this.dueDate.getDate()
      );
      // console.log("utc1=",utc1);
      // console.log("utc2=",utc2);
      let PeriodDate = Math.floor((utc2 - utc1) / (1000 * 3600 * 24));
      this.periodDate = PeriodDate;
    },
    GetPeriodDate(StartDate, EndDate) {
      var date1 = new Date(StartDate);
      var date2 = new Date(EndDate);
      var Difference_In_Time = date2.getTime() - date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      return Difference_In_Days;
    },
    createDocument() {
      this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForCreateNewDocument}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.cancel,
        allowOutsideClick: false,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (!this.isCreateDocument && !this.beforePath) {
            this.$router.replace({ name: "ap-purchase-invoice-apBook", params: { apBook: "create" }});
          } else {
            //
          }
          this.initiateData();
        }
      });
    },
    async initiateData() {
      // console.log("initiateData=", this.sharedEmployees);
      this.selectedEmployee = this.sharedEmployees.find(
        (employee) => employee.code === this.employee.code
      );

      this.code = "";
      this.refCode = "";
      this.date = new Date();
      this.vatSendDate = new Date();
      this.periodDate = 0;
      this.dueDate = new Date();
      this.selectedSupplier = null;
      this.section = "";
      this.project = "";
      this.task = "";
      this.remark1 = "";
      this.remark2 = "";
      this.details = [];

      this.totalAmount = 0;
      this.discount = null;
      this.discountTotalAmount = 0;
      this.payment = 0;
      this.paymentTotalAmount = 0;
      // this.tax = 0;
      this.taxAmount = 0;
      this.netAmount = 0;

      this.refCodeError = false;
      this.employeeError = false;
      this.supplierError = false;
      this.discountAmountError = false;
      this.isSetData = true;
      
      this.calculateTaxFromSystem = 0.00;
      this.isTaxAmountDisabled = true;

      await this.$store.dispatch(
        "apPurchaseInvoice/resetSelectedPurchaseInvoice"
      );

      this.addEmptyRow();
    },
    addEmptyRow() {
      // console.log("this.details",this.details);
      this.details.push({
        id: "dummy" + (parseInt(this.details.length) + 1),
        headerId: null,
        itemNo: null,
        productId: null,
        productCode: null,
        productName: null,
        productUnitId: null,
        productUnitCode: null,
        productUnitName: null,
        quantity: null,
        unitPrice: null,
        discountDescription: null,
        discountAmount: null,
        totalAmount: null,
        remark: null,
        stockEffect: null,
        referenceId: null,
      });
    },
    validateData() {
      if (!this.refCode) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateRefCode,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.refCodeError = true;
          }
        });
        return true;
      }

      if (!this.selectedSupplier) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoSupplierSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.supplierError = true;
          }
        });
        return true;
      }

      if (!this.selectedEmployee) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoEmployeeSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.employeeError = true;
          }
        });
        return true;
      }

      if (this.details.length <= 1) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateDetailSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      if (this.totalAmount < 0) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateTotalAmount,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      if (this.discount) {
        let isText = false;
        const keysAllowed = [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          ".",
          "%",
        ];
        for (let i = 0; i < this.discount.length; i++) {
          if (!keysAllowed.includes(this.discount[i])) {
            isText = true;
            break;
          }
        }
        if (isText) {
          this.$swal({
            icon: "warning",
            title: this.lang.validateCheckDiscount,
            confirmButtonText: this.lang.close,
            allowOutsideClick: false,
          });
          return true;
        }
      }      

      if (this.netAmount < 0) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNetTotalAmount,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }
      
      if(this.discountAmount > this.netAmount){
        this.$swal({
          icon: "warning",
          title: this.lang.validateDiscountAmount,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.discountAmountError = true;
          }
        });
        return true;
      }

      return false;
    },
    productModalSelected(product) {
      this.productSelect(this.editingDetail, product);
      this.showProductModal = false;
    },
    productSelect(detail, product) {
      // const today = new Date();
      // detail.id = "dummy" + today.getSeconds();
      detail.headerId = null;
      detail.itemNo = detail.itemNo || this.lastItemNo + 1;
      detail.productId = product.id;
      detail.productCode = product.code;
      detail.productName = product.nameThai;
      detail.productUnitId = product.unitId;
      detail.productUnitCode = product.unitCode;
      detail.productUnitName = product.unitName;
      detail.quantity = 1;
      detail.unitPrice = product.price;
      detail.discountDescription = "0";
      detail.discountAmount = 0;
      detail.totalAmount = detail.unitPrice * detail.quantity;
      detail.remark = "";
      detail.stockEffect = null;
      detail.referenceId = null;

      this.addRow(detail);
      //หาว่ามีบรรทัดที่ รหัสเป็น null หรือยัง (บรรทัดว่าง)
      const emptyRow = this.details.find((detail) => !detail.productCode);
      if (!emptyRow) {
        //ถ้ายังไม่มีให้เพิ่มไว้
        this.addEmptyRow();
        // this.updateItemNumber();
      }

      this.updateTotalAmount();
      this.updateItemNumber();
      
      this.calculateNetAmount();
      this.setTaxAmountFromSystem();
    },
    addRow(detail) {
      //let total = 0;
      this.details.forEach((element) => {
        if (element.id === detail.id) {
          element = { ...element, ...detail };
        }
        //total += element.productId ? element.totalAmount : 0;
      });
      //this.totalAmount = total;
    },
    deleteRow(id) {
      const index = this.details.findIndex((detail) => detail.id === id);
      this.details.splice(index, 1);

      this.updateTotalAmount();
      this.updateItemNumber();
      
      this.calculateNetAmount();
      this.setTaxAmountFromSystem();
    },
    updateItemNumber() {
      let number = 1;
      this.details.forEach((element) => {
        if (element.productCode) {
          element.itemNo = number;
        }
        number++;
      });
    },
    updateTotalAmount() {
      let totalAmount = 0;
      this.details.forEach((detail) => {
        totalAmount += detail.totalAmount;
      });
      this.totalAmount = totalAmount;
    },
    calculateNetAmount() {
      let vatInclude = this.vatInclude;
      if (!this.isCreateDocument && this.purchaseInvoice) {
        vatInclude = this.purchaseInvoice.isVatInclude;
      }

      if (this.discount) {
        if (this.discount.includes("%")) {
          this.discountTotalAmount =
            (parseFloat(this.discount) / 100) * this.totalAmount;
        } else {
          this.discountTotalAmount = parseFloat(this.discount);
        }
        // console.log("vatInclude=",vatInclude);
        if (vatInclude === "Y") {
          this.netAmount = this.totalAmount - this.discountTotalAmount;
          this.calculateTaxFromSystem = (this.netAmount * this.tax) / 107;
          this.paymentTotalAmount = this.netAmount - this.calculateTaxFromSystem;
        } else {
          this.paymentTotalAmount = this.totalAmount - this.discountTotalAmount;
          this.calculateTaxFromSystem = (this.tax / 100) * this.paymentTotalAmount;
          this.netAmount =
            this.totalAmount - this.discountTotalAmount + this.calculateTaxFromSystem;
        }
      } else {
        this.discountTotalAmount = 0;
        if (vatInclude === "Y") {
          this.netAmount = this.totalAmount - this.discountTotalAmount;
          this.calculateTaxFromSystem = (this.netAmount * this.tax) / 107;
          this.paymentTotalAmount = this.netAmount - this.calculateTaxFromSystem;
        } else {
          this.paymentTotalAmount = this.totalAmount - this.discountTotalAmount;
          this.calculateTaxFromSystem = (this.tax / 100) * this.paymentTotalAmount;
          this.netAmount =
            this.totalAmount - this.discountTotalAmount + this.calculateTaxFromSystem;
        }
      }

    },
    async importPO() {
      // console.log("importPO=",this.showPurchaseOrder);

      if (!this.selectedSupplier) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoSupplierSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.supplierError = true;
          }
        });
        return true;
      }

      let where = {
        projectId: this.selectedProject.id,
        supplierId: this.selectedSupplier.id,
        VatRate: this.tax,
      };
      // console.log('where',JSON.stringify(where));
      const payload = { where };
      try {
        await this.$store.dispatch(
          "apPurchaseInvoice/getPurchaseOrders",
          payload
        );
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }

      this.showPurchaseOrder = true;
    },
    selectedPurchaseOrder(data) {
      // console.log("selectPurchaseOrder=", data);

      // this.totalAmount = data.totalAmount;
      this.discount = data.discount;
      this.discountTotalAmount = data.discountAmount;
      // this.payment = 0;
      // this.paymentTotalAmount = data.totalAmount - data.discountAmount;
      // this.tax = data.vatRate;
      // this.taxAmount = data.vatAmount;
      // this.netAmount = data.netAmount;

      this.remark2 =
        "PO/" +
        data.code +
        " : " +
        this.formatDate(data.date) +
        " Amt(non vat) = " +
        data.totalAmount;

      let i = this.details.length-1;
      data.details.forEach((e) => {
        // let products = [];
        let productDetail = null;
        productDetail = this.details.find((x) => x.referenceId === e.id);
        if (productDetail == null) {
          let product = {
            // id: e.id,
            id: "dummy" + (parseInt(this.details.length)),
            headerId: null,
            itemNo: this.lastItemNo + 1,
            productId: e.productId,
            productCode: e.productCode,
            productName: e.productName,
            productUnitId: e.productUnitId,
            productUnitCode: e.productUnitCode,
            productUnitName: e.productUnitName,
            quantity: e.quantity - e.dlvrQuantity,
            unitPrice: e.unitPrice,
            discountDescription: e.discount,
            discountAmount: e.discountAmount,
            totalAmount: e.totalAmount,
            remark: "",
            stockEffect: null,
            referenceId: e.id,
            unitRatio: e.unitRatio,
            sourceQuantity: e.sourceQuantity,
          };
          // products.push(product);
          // this.details = products;
          // this.details.unshift(product);
          this.details[i]=product;
          i++;
          this.addEmptyRow();
        }
      });

      // const emptyRow = this.details.find((detail) => !detail.productCode);
      // if (!emptyRow) {
      //   //ถ้ายังไม่มีให้เพิ่มไว้
      //   this.addEmptyRow();
      // }
      this.updateTotalAmount();
      this.updateItemNumber();
      // this.calculateNetAmount();
      
      this.calculateNetAmount();
      this.setTaxAmountFromSystem();

      // this.details.sort((a, b) => a.itemNo - b.itemNo);
      // this.details.sort((a,b)=> a.itemNo < b.itemNo?1:-1)
    },
    async save() {
      if (this.validateData()) {
        return;
      }
      this.setLoading(true);
      // console.log("save_payload=",this.payload);
      try {
        await this.$store.dispatch(
          "apPurchaseInvoice/AddUpdatePurchaseInvoice",
          this.payload
        );
        this.$swal({
          icon: "success",
          title: this.lang.saveSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        if (this.isCreateDocument) {
          this.$router.replace({ name: "ap-purchase-invoice-edit", params: { apBook: this.apBook, id: this.purchaseInvoice.id }});
        }
        this.isTaxAmountDisabled = true;
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
    },
    async saveAs() {
      if (this.validateData()) {
        return;
      }
      this.setLoading(true);
      // console.log("save_payload=",this.payload);

      let details = [];
      for (const each of this.details.filter((each) => each.productId)) {
        let detail = null;
        if (each.id.includes("dummy")) {
          detail = { ...each, id: null, referenceId: null };
        } else {
          detail = { ...each, id: null, referenceId: null };
        }
        details.push(detail);
      }

      let payload = {
        projectId: this.selectedProject.id,
        refCode: this.refCode,
        documentBookId: this.selectedBookId,
        code: this.code,
        date: this.formatDateToISOString(this.date),
        dueDate: this.formatDateToISOString(this.dueDate),
        vatSendDate: this.formatDateToISOString(this.vatSendDate),
        supplierId: this.selectedSupplier.id,
        employeeId: this.selectedEmployee.id,
        IsVatInclude: this.vatInclude,
        // currencyId: this.currencyId,
        remark1: this.remark1,
        remark2: this.remark2,

        totalAmount: this.totalAmount,
        discountDescription: this.discount,
        discountAmount: this.discountTotalAmount,
        vatRate: this.tax,
        vatAmount: this.taxAmount,
        diffAmount: this.netAmount,
        netAmount: this.netAmount,
        typeInsert: "Add",
        // section: this.section,
        // project: this.project,
        // task: this.task,
        details: details,
      };

      try {
        await this.$store.dispatch(
          "apPurchaseInvoice/AddUpdatePurchaseInvoice",
          payload
        );
        this.$swal({
          icon: "success",
          title: this.lang.saveSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        this.$router.replace({ name: "ap-purchase-invoice-edit", params: { apBook: this.apBook, id: this.purchaseInvoice.id }});
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
    },
    async askApprove() {
      if (this.totalAmount <= 0) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateTotalAmount,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      //
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}"> ${this.lang.askApprove} </br> ${this.code}   </p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        // if (this.validateData()) {
        //   return;
        // }
        await this.approvePurchaseInvoice();
      }
    },
    async approvePurchaseInvoice() {
      this.setLoading(true);

      let details = [{ Id: this.id, code: this.code }];
      let requestPI = {
        ProjectId: this.selectedProject.id,
        Id: this.id,
        DocumentBookId: this.selectedBookId,
        FormApprove: "Entry",
        data: details,
      };

      // console.log("requestPI=", requestPI);
      try {
        await this.$store.dispatch(
          "apPurchaseInvoice/ApprovePurchaseInvoice",
          requestPI
        );
        this.$swal({
          icon: "success",
          title: this.lang.approveSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
    },
    async askForCancel() {
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForCancelDocument} </br> ${this.code}  </p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        await this.CancelPurchaseInvoice();
      } else if (result.isDenied) {
        //
      }
    },
    async CancelPurchaseInvoice() {
      this.setLoading(true);

      let details = [{ Id: this.id, code: this.code }];
      let requestPI = {
        ProjectId: this.selectedProject.id,
        Id: this.id,
        DocumentBookId: this.selectedBookId,
        FormCancel: "Entry",
        data: details,
      };

      // console.log("requestPI=", requestPI);
      try {
        await this.$store.dispatch(
          "apPurchaseInvoice/CancelPurchaseInvoice",
          requestPI
        );
        this.$swal({
          icon: "success",
          title: this.lang.cancelSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
    },
    async selectPrintForm() {
      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: this.selectedBookCode,
      };
      const payload = { where };
      await this.getFormPrints(payload);      

      if(this.filteredFormPrints.length == 1) {
        this.path = this.filteredFormPrints[0].path;
        this.PrintPurchaseInvoice();
      }else{
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path;
      this.categoryPrint = event.category;
      this.PrintPurchaseInvoice();
      this.showFormPrintModal = false;
    },
    async PrintPurchaseInvoice() {
      this.setLoading(true);
      const PurchaseInvoice = {
        id: this.id,
        projectId: this.selectedProject.id,
        path: this.path,
        printBy: this.employee.name,
      };

      //  console.log("PrintPettyCash=", PurchaseInvoice);
      try {
        await this.$store.dispatch(
          "apPurchaseInvoice/PrintPurchaseInvoice",
          PurchaseInvoice
        );
        this.isPrinted = true;
      } catch (error) {
        // console.log("error=", error);
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        this.isPrinted = false;
        this.setLoading(false);
      }
      let blob = this.$store.getters["apPurchaseInvoice/blob"];
      // console.log('blob',blob);
      this.getPreviewBlob(blob);

      this.setLoading(false);
    },
    setDiscountColumnText(data) { 
      this.discountText = data
    },
    setDiscountText(data) { 
      this.discount = data;
      this.editDiscount();
    },
    async getRoleByDocumentBook() {
      let where = {
        projectId: this.selectedProject.id,
        documentBookId: this.apBook,
      };

      const payload = {
        page: 1,
        limit: 100,
        where,
      };

      await this.$store.dispatch(
        "roleRoleByDocumentBook/getRoleByDocumentBooks",
        payload
      );
    },
    async askForRevertApprove() {
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForRevertApprove}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        await this.revertApprove();
      } else if (result.isDenied) {
        //
      }
    },
    async revertApprove() {
      this.setLoading(true);

      let details = [{ Id: this.id, code: this.code }];
      let requestPI = {
        ProjectId: this.selectedProject.id,
        Id: this.id,
        DocumentBookId: this.selectedBookId,
        FormApprove: "Entry",
        data: details,
      };
      // console.log("requestPI=", requestPI);
      try {
        await this.$store.dispatch(
          "apPurchaseInvoice/RevertApprovePurchaseInvoice",
          requestPI
        );
        this.$swal({
          icon: "success",
          title: this.lang.revertApproveSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
    },    
    doubleClickTaxAmount(){
      // console.log("doubleClickTaxAmount=",event);
      if(!this.isDocumentLocked && this.isTaxAmountDisabled && this.tax != 0){
        document.getElementById('taxAmount').value = "";
        this.isTaxAmountDisabled = false;
      }
    },    
    editDiscount(){   
      this.calculateNetAmount();
      this.setTaxAmountFromSystem();      
    },
    setTaxAmountFromSystem(){
      this.taxAmount = parseFloat(this.calculateTaxFromSystem.toFixed(2));
      this.setNetAmount(null);
    },
    setNetAmount(event){
      // console.log("setNetAmount_event=",event);
      let taxAmount = this.taxAmount;      
      if(event !== null){
        taxAmount = event.target.value.replaceAll(",","");
      }  
      if (this.vatInclude === "Y") {
        this.netAmount = this.totalAmount - this.discountTotalAmount;
      } else {
        this.netAmount = this.totalAmount - this.discountTotalAmount + parseFloat(taxAmount);
      }    

      if(this.netAmount >= 0) this.discountAmountError = false; 
    },
    dateSelected() {
      // console.log("curValue=",curValue);
      let day = this.periodDate;
      this.dueDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate() + day,
        0
      );

      this.vatSendDate = this.date;
      this.setCreditTerm();
    },
    editCreditTerm(event) {
      let creditTerm = parseFloat(event.target.value);
      let newValue = 0;
      if(creditTerm > 0)
      { 
        newValue = creditTerm;
      }
      this.dueDate = new Date(this.date.getFullYear(), this.date.getMonth(),this.date.getDate() + newValue,0);
    },
    selectSupplier() {      
      if(this.selectedSupplier){ 
        this.periodDate = this.selectedSupplier.supplierCreditTerm;
      }
      this.supplierError = false;   
    },
  },
  computed: {
    payload() {
      let details = [];
      for (const each of this.details.filter((each) => each.productId)) {
        let detail = null;
        if (each.id.includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        details.push(detail);
      }

      let payload = {
        projectId: this.selectedProject.id,
        refCode: this.refCode,
        documentBookId: this.selectedBookId,
        code: this.code,
        date: this.formatDateToISOString(this.date),
        dueDate: this.formatDateToISOString(this.dueDate),
        vatSendDate: this.formatDateToISOString(this.vatSendDate),
        supplierId: this.selectedSupplier.id,
        employeeId: this.selectedEmployee.id,
        IsVatInclude: this.vatInclude,
        // currencyId: this.currencyId,
        remark1: this.remark1,
        remark2: this.remark2,

        totalAmount: this.totalAmount,
        discountDescription: this.discount,
        discountAmount: this.discountTotalAmount,
        vatRate: this.tax,
        vatAmount: this.taxAmount,
        diffAmount: this.netAmount,
        netAmount: this.netAmount,
        typeInsert: "Add",
        // section: this.section,
        // project: this.project,
        // task: this.task,
        details: details,
      };

      if (!this.isCreateDocument) {
        payload = {
          ...payload,
          id: this.id,
          typeInsert: "Update",
          IsVatInclude: this.purchaseInvoice.isVatInclude,
        };
      }

      return payload;
    },

    isCreateDocument() {
      return this.id === "create";
    },
    title() {
      let result = this.lang.editBill + " : " + this.selectedBookCode;
      if (this.isCreateDocument) {
        result = this.lang.createBill + " : " + this.selectedBookCode;
      }
      return result;
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
		userCode() {
			return this.$store.getters.userCode;
		},
    employee() {
      return this.$store.getters.employee;
    },
    purchaseInvoice() {
      return this.$store.getters["apPurchaseInvoice/selectedPurchaseInvoice"];
    },
    sharedSuppliers() {
      return this.$store.getters["shared/supplierSuppliers"];
    },
    sharedEmployees() {
      return this.$store.getters["shared/employeeEmployees"];
    },
    lastItemNo() {
      const itemNo = Math.max.apply(
        Math,
        this.details.map(function (o) {
          return o.itemNo;
        })
      );
      return itemNo;
    },
    isCanceled() {
      if (this.purchaseInvoice) {
        if (this.purchaseInvoice.cancelStatus === "C") {
          return true;
        }
      }
      return false;
    },
    isDocumentLocked() {
      if (this.purchaseInvoice) {
        if (
          this.purchaseInvoice.paidStatus.trim() ||
          this.purchaseInvoice.postStep.trim() ||
          this.purchaseInvoice.cancelStatus.trim().toLowerCase() === "c"
        ) {
          return true;
        }
      }
      return false;
    },
    isCheckDisabled() {
      let isCheck = false;

      if (this.purchaseInvoice && this.purchaseInvoice.postStep.trim() !== "")
        isCheck = true;
      if (this.purchaseInvoice && this.purchaseInvoice.postStep === "A")
        isCheck = true;
      if (this.purchaseInvoice && this.purchaseInvoice.postStep === "P")
        isCheck = true;
      if (this.purchaseInvoice && this.purchaseInvoice.cancelStatus === "C")
        isCheck = true;

      return isCheck;
    },
    isCheckSaveAs() {
      let isCheck = false;
      if (!this.code) isCheck = true;
      return isCheck;
    },
    roleByDocumentBook() {
      return this.$store.getters["roleRoleByDocumentBook/roles"];
    },
    isCheckRoleApprove() {
      let isCheck = false;
      // console.log("this.roleByDocumentBook=",this.roleByDocumentBook);
      // console.log("this.purchaseInvoice=",this.purchaseInvoice);
      if (this.roleByDocumentBook.length > 0) {
        if (this.roleByDocumentBook[0].approve === "Y") isCheck = true;
      }

      return isCheck;
    },
    isApproved() {
      let isCheck = false;
      if (this.purchaseInvoice && this.purchaseInvoice.postStep != "P")
        isCheck = true;
      if (this.purchaseInvoice && this.purchaseInvoice.cancelStatus === "C")
        isCheck = true;
      if (
        this.purchaseInvoice && this.purchaseInvoice.paidStatus === "P")      
        isCheck = true;
      return isCheck;
    },    
    classDisableTaxAmount() {
      let setClass = "text-right ";
      if (this.isTaxAmountDisabled) {
        setClass = "text-right input-number-disable";
      }
      return setClass;
    },
  },
  watch: {
    isSharedSupplierReady() {
      this.checkShouldSetData();
    },
    purchaseInvoice() {
      this.checkShouldSetData();
    },
    isSharedEmployeeReady() {
      this.checkShouldSetData();
    },
  },
};
</script>

<style></style>
