export default {
	projectOverview: "Project Overview",
	registerApp: "Register Application",


	manageResidents: "Manage Residents",
	residents: "Residents",
	unit: "Unit",
	customer: "Customer",
	customerGroup: "Customer Group",
	debtfree: "Debtfree",
	invitationCode: "Invitation Code",


	financialAccount: "Financial Account",
	arSystem: "AR System",

	meter: "Meter",
	meterUsageWater: "Meter Usage Water",
	meterUsageElectric: "Meter Usage Electric",
	meterUsageCoolingAir: "Meter Usage Air",
	meterUsageReport: "Meter Usage Report",

	invoiceSystem: "Invoice System",
	invoice: "Invoice",
	calculateInvoice: "Calculate Invoice",
	outstandingLetter: "Outstanding Letter",

	noticeSystem: "Notice System",
	notice: "Notice",
	calculateNotice: "Calculate Notice",
	noticeReport: "Notice Report",
	noticeCustomReport: "Notice Custom Report",

	receiptSystem: "Receipt System",
	receipt: "Receipt",
	receiptReport: "Receipt Report",
	deposit: "Deposit",
	creditNote: "Credit Note",
	bankAR: "Bank AR",
	usePrepaidAndDeposit: "Use Prepaid and Deposit",
	previewUsePrepaidAndDeposit: "Preview Use Prepaid And Deposit",
	refundSystem: "Refund System",
	prepaidRefund: "Prepaid Refund",
	refundGuarantee: "Refund Guarantee",
	arReport: "AR Report",
	checkTransfer: "Check Transfer",


	apSystem: "AP System",
	purchaseRequisition: "Purchase Requisition",
	purchaseOrder: "Purchase Order",
	purchaseInvoice: "Purchase Invoice",
	purchaseReceipt: "Purchase Receipt",
	bankAP: "Bank AP",
	withholdingTax: "Withholding Tax",
	apReport: "AP Report",
	withholdingTaxReport: "Withholding Tax Report",

	glSystem: "Accounting",
	glPettyCash: "Petty Cash",
	pettyCashReport: "Report PC",
	glInvoice: "Invoice To GL",
	glReceipt: "Receipt To GL",
	glAccounting: "Accounting",
	revertAccounting: "Revert Accounting",
	closingEntry: "Closing Entry",
	glReport: "Report GL",

	assetSystem: "Asset",
	assetRegister: "Asset Register",
	reportAsset: "Report Asset",


	setting: "Setting",
	meterUsageSetting: "Meter Usage Setting",
	productAR: "Product AR",
	policy: "Policy",
	projectPenaltyCalculation: "Project Penalty Calculation",
	supplier: "Supplier",
	productAP: "Product AP",
	noticePrintSetting: "Notice Print Setting",

	accountSetup: "Account Setup",
	accountChart: "Account Chart",
	bookbank: "Bookbank",
	accountBook: "Account Book",
	accountFormat: "Account Format",
	accountFrequently: "Account Frequently",
	accountPolicy: "Account Policy",

	projectSetup: "Project Setup",
	projectInformation: "Project Information",
	projectDocument: "Project Document",
	announceAdvertising: "Announce - Advertising",
	phoneBook: "Phone Book",
	smartAudit: "Smart audit",


	other: "Management",
	parcel: "Parcel",
	parcelReport: "Parcel Report",
	chat: "Chat",
	news: "News",
	facilityBooking: "Facility Booking",
	serviceMaintenence: "Service Maintenence",
	visitorControl: "Visitor Control",


	help: "Help",
	manualWebService: "Manual Web Service",
	contactStaff: "Contact Staff",


	productAndService: "Product and Service",
	reportRequest: "Report Request",

	notification: "Notification",
	voucherBook: "Account Book",
	report: "Report",
	autoCreateMeter: "Auto Create Meter",
	settingUnitProduct: "Setting Unit Product",
	importReceipts: "Import Receipt",

	adminFormManage: "Form Management",
	formVersion: "Form Version Setting",
	reportManage: "Report Management",

	debtfreeRequest: "Debt Free Request",
	debtfreeApprove: "Debt Free Approve",
	debtfreePrint: "Debt Free Print",
	debtfreeFollowUp: "Debt Free Follow Up",
	debtfreeReport: "Report Debt Free",
	importInvoices: "Import Invoice",

	billPayment: "Bill Payment",
	transferSlip: "TransferSlip",
	stockKeycardSystem: "Stock Keycard System",
	stockKeycard: "Stock Keycard",
	controlStockKeycard: "Stock Keycard",
	stockKeycardReport: "Report Stock Keycard",

	importResident: "Import Resident",
	importUnit: "Import Unit",
	importSupplier: "Import Supplier",
	importDeposit: "Import Deposit",
	importAsset: "Import Asset",
	manageBudget: 'Manage Budget',
	budget: "Budget",
	budgetReport: "Budget Report",
	signatureSetup: "Signature Setup",
	reportSignator: "Report Signator",
	signator: "Signator",
	signatureManagement: "Signature Management",

	paymentRequest: "Payment Request",
	paymentRequestManagement: "Payment Request Management",
	paymentRequestReport: "Payment Request Report",
	paymentRequestType: "Payment Request Setting",
};
