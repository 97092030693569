export default {
  async getTaxBooks(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/WithholdingTax/TaxBook";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get unit");
      throw error;
    }
    // console.log("taxBook",responseData);

    // const data = responseData.data;

    const taxBook = {
      taxBooks: responseData.data,
      totalPage: responseData.page.pageCount,
      currentPage: responseData.page.currentPage,
    };
    context.commit("setTaxBook", taxBook);
  },
  async getAllTaxBooks(context) {
      while (!context.getters.taxBookIsLastPage) {
        let where = {
          projectId: context.rootGetters.selectedProject.id,
        };
        const payload = {
          page: context.getters.taxBookNextPage || 1,
          where,
        };
        await context.dispatch("getTaxBooks", payload);
      }
    
  },
  async getTaxTypes(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/WithholdingTax/TaxType";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get tax type");
      throw error;
    }
    // console.log("getTaxTypes", responseData);

    // const data = responseData.data;
    context.commit("setTaxType", responseData);
  },
  resetTaxBook(context) {
    context.commit("resetTaxBook");
  },
};
