export default {
    setParams(state, payload) {
        state.params = payload;
    },
    setImportExistResidents(state, payload) {
        state.importExistResidents = [
            ...state.importExistResidents, 
            ...payload,
        ];
    },
    resetImportExistResidents(state) {
        state.importExistResidents = [];
    },
}