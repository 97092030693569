export default {
	title: "Purchase Requisition",
	changeAPBook: "Change Book",

	columnApproveStatus: "Approve",
    columnCancelStatus:"Cancel",
    columnCode: "Code",
    columnReferDocument : "Refer Document",
    columnDate: "Date",
    columnDueDate: "DueDate",
    columnSupplierCode:"Supplier Code",
    columnSupplierName:"Supplier Name",
    columnNetAmount: "Total Amount",
	columnPaidStatus: "Status",
    closePR: "Close PR"
};
