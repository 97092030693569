<template>
  <base-toolbar
    :title="lang.debtFreeFollowUp"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoSave="save"
  ></base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :classTitle="baseDialogClassTitle"
      :titleConfirm="titleConfirm"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
      @goto-list="goBack"
    ></base-dialog>

    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{
        lang.editDebtFreeFollowUpTitle
      }}</label>
      <PvDivider></PvDivider>

      <section class="flex flex-wrap fontlabel" :class="textFormClass">
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="unitCode">{{ lang.unitCode }}</label>
          <PvInputText
            id="unitCode"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="unitCode"
            disabled
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="unitAddressNo">{{ lang.unitAddressNo }}</label>
          <PvInputText
            id="unitAddressNo"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="unitAddressNo"
            disabled
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="ownerName">{{ lang.ownerName }}</label>
          <PvInputText
            id="ownerName"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="ownerName"
            disabled
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="ownerPhone">{{ lang.ownerPhone }}</label>
          <PvInputText
            id="ownerPhone"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="ownerPhone"
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="debtFreeCode">{{ lang.debtFreeCode }}</label>
          <PvInputText
            id="debtFreeCode"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="debtFreeCode"
            disabled
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="approvedDate">{{ lang.debtFreeDate }}</label>
          <PvCalendar
            id="approvedDate"
            v-model="approvedDate"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            inputClass="p-inputtext-disabled"
            disabled
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="status">{{ lang.status }}*</label>
          <PvDropdown
            id="filterStatus"
            class="inputfield w-full mt-1"
            :options="filterStatus"
            optionLabel="name"
            v-model="selectedFilterStatus"
            scrollHeight="300px"
            :showClear="true"
            @change="changeFilterStatus"
            :class="{ 'p-invalid': statusError }"
            :placeholder="lang.validateStatus"
          />
          <small v-if="statusError" id="status-help" class="p-error">
            {{ lang.validateStatus }}
          </small>
        </div>
        <div
          class="lg:col-6 md:col-12 col-12 pl-0 pr-2"
        >
          <label for="newOwner">{{ lang.newOwner }}</label><label class="p-text-secondary">{{ lang.customerReference }}</label>
          <PvSkeleton
            v-show="!isSharedCustomerReady"
            class="inputfield w-full mt-1"
            height="39px"
          />
          <base-customer-auto-complete
            v-show="isSharedCustomerReady"
            id="newOwner"
            v-model="selectedNewOwner"
            class="inputfield w-full mt-1"
            @load-finish="customerDataReady"
            :class="{ 'p-invalid': newOwnerError }"
            @item-select="newOwnerError = false"
            :disabled="!isTransferStatus"
            :inputClass="{ 'p-inputtext-disabled': !isTransferStatus }"
          />
          <small v-if="newOwnerError" id="new-owner-help" class="p-error">
            {{ lang.validateNewOwner }}
          </small>
        </div>
        <div
          class="lg:col-6 md:col-12 col-12 pl-0 pr-2"
        >
          <label for="transferDate">{{ lang.transferDate }}</label>
          <PvCalendar
            id="transferDate"
            v-model="transferDate"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': transferDateError }"
            @date-select="transferDateError = false"
            :disabled="!isTransferStatus"
            :inputClass="{ 'p-inputtext-disabled': !isTransferStatus }"
          />
          <small
            v-if="transferDateError"
            id="transfer-date-help"
            class="p-error"
          >
            {{ lang.validateTransferDate }}
          </small>
        </div>
        <div
          class="lg:col-6 md:col-12 col-12 pl-0 pr-2"
        >
          <label for="remark">{{ lang.remark }}</label>
          <PvInputText
            id="remark"
            type="text"
            class="inputfield w-full mt-1"
            v-model="remark"
            :disabled="!isOtherStatus"
            :class="{ 'p-inputtext-disabled': !isOtherStatus }"
          />
        </div>
      </section>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import usePageDirty from "../../../hooks/pageDirty";
import useGetCustomers from "../../../hooks/getCustomers.js";
export default {
  props: ["id", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString, formatDateToLocaleDateString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.debtFree.followUp.edit,
          ...lang.th.debtFree.followUp.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.debtFree.followUp.edit,
          ...lang.en.debtFree.followUp.listing,
        };
      }
    });

    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    const { compareObject, isCompareObject } = usePageDirty();

    const { refreshCustomers } = useGetCustomers();

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      compareObject,
      isCompareObject,
      refreshCustomers,
      formatDateToLocaleDateString,
    };
  },
  data() {
    return {
      unitCode: "",
      unitAddressNo: "",
      ownerName: "",
      ownerPhone: "",
      debtFreeCode: "",
      approvedDate: null,
      filterStatus: [
        { name: this.lang.followupStatus1, code: "1" },
        { name: this.lang.followupStatus2, code: "2" },
        { name: this.lang.followupStatus3, code: "3" },
        { name: this.lang.followupStatus4, code: "4" },
        { name: this.lang.followupStatus5, code: "5" },
      ],
      selectedFilterStatus: null,
      selectedNewOwner: null,
      isSharedCustomerReady: false,
      transferDate: null,
      remark: "",
      newOwnerError: false,
      transferDateError: false,
      statusError: false,

      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: "",
      validateWording: "",

      isTransferStatus: false,
      isOtherStatus: false,
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    await this.roleByMenuDataReady();
    await this.checkShouldSetData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.setLocalSearch();
      this.$router.push({name: "debt-free-follow-up"});
    },
    async initiateData() {
      this.unitCode = "";
      this.unitAddressNo = "";
      this.ownerName = "";
      this.ownerPhone = "", 
      this.debtFreeCode = "";
      this.approvedDate = null;
      this.selectedFilterStatus = null;
      this.selectedNewOwner = null;
      this.isSharedCustomerReady = false;
      this.transferDate = null;
      this.remark = "";
      this.newOwnerError = false;
      this.transferDateError = false;
      this.statusError = false;
      this.isTransferStatus = false;
      this.isOtherStatus = false;
      await this.$store.dispatch("debtFreeFollowUp/resetDebtFreeFollowUp");
    },
    clearBaseDialog() {
      this.baseDialogClassTitle = "";
      this.titleConfirm = "";
      this.validateWording = "";
    },
    async roleByMenuDataReady() {
      if (this.isSharedRoleByMenuReady) {
        await this.getDebtFreeFollowUp();
      }
    },
    async checkShouldSetData() {
      if (this.isSharedRoleByMenuReady) {
        if (this.debtFreeFollowUp) {
          await this.setData();
        }
      }
      await this.setCompareData();
    },
    async getDebtFreeFollowUp() {
      try {
        await this.$store.dispatch(
          "debtFreeFollowUp/getDebtFreeFollowUpById",
          this.id
        );

        if (this.isFollowUp) {
          this.clearBaseDialog();
          this.baseDialogClassTitle = "p-error";
          this.titleConfirm = this.lang.validateUnFollowupTitle;
          this.validateWording = this.lang.validateUnFollowupTitleDetail;
          this.showValidateDialog = true;
        }
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    async setData() {
      this.unitCode = this.debtFreeFollowUp.unitCode;
      this.unitAddressNo = this.debtFreeFollowUp.unitAddressNo;
      this.ownerName = this.debtFreeFollowUp.debtfreeRequestOwnerName;
      this.ownerPhone = this.debtFreeFollowUp.ownerPhone;
      this.debtFreeCode = this.debtFreeFollowUp.debtfreeCode;
      this.approvedDate = new Date(this.debtFreeFollowUp.approvedDate);
      this.setLoading(false);
    },
    setLocalSearch() {
      let storageName = `erp_search_DebtFreeFollowUpList_${this.selectedProject.code}_${this.userCode}`;
      const localSearch = localStorage.getItem(storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      localStorage.setItem(storageName, JSON.stringify(search));
    },
    changeFilterStatus() {
      if(this.selectedFilterStatus) {
        this.statusError = false;
        this.isTransferStatus = false;
        this.isOtherStatus = false;
        if(this.selectedFilterStatus.code == "1") {
          this.refreshCustomers();
          this.transferDate = new Date();
          this.isTransferStatus = true;
          this.isOtherStatus = true;
          this.remark = "";
        } else if(this.selectedFilterStatus.code == "5") {
          this.isOtherStatus = true;
          this.clearData();
        } else {
          this.clearData();
        }
      } else {
        this.isTransferStatus = false;
        this.isOtherStatus = false;
        this.clearData();
      }
    },
    customerDataReady() {
      this.isSharedCustomerReady = true;
    },
    validateData() {
      let returnStatus = false;

      if (!this.selectedFilterStatus) {
        this.statusError = true;
        returnStatus = true;
      }

      if (this.isTransferStatus) {
        if (!this.selectedNewOwner) {
          this.newOwnerError = true;
          returnStatus = true;
        }

        if (!this.transferDate) {
          this.transferDateError = true;
          returnStatus = true;
        }
      }

      return returnStatus;
    },
    async save() {
      if (this.validateData()) {
        return;
      }
      this.setLoading(true);
      try {
        // console.log("save", this.payload);

        await this.$store.dispatch(
          "debtFreeFollowUp/updateDebtfreeFollowUp",
          this.payload
        );

        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.saveSuccess;
        this.validateWording = this.lang.followUpSuccess.replace(
          ":code",
          this.debtFreeFollowUp.debtfreeCode
        );
        this.showValidateDialog = true;
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);

      await this.setCompareData();
    },
    async setCompareData() {
      let objToCompare = {
        id: this.id,
        followupStatus: this.selectedFilterStatus ? this.selectedFilterStatus.name : null,
        followupDate: this.approvedDate
          ? this.formatDateToISOString(this.approvedDate)
          : null,
        approvedDateString: this.approvedDate ? this.formatDateToLocaleDateString(this.approvedDate) : null,
        unitId: this.debtFreeFollowUp ? this.debtFreeFollowUp.unitId : null,
        currentOwnerId: this.debtFreeFollowUp ? this.debtFreeFollowUp.ownerId : null,
        newOwnerId: this.selectedNewOwner ? this.selectedNewOwner.id : null,
        transferDate: this.transferDate
          ? this.formatDateToISOString(this.transferDate)
          : null,
        remark: this.remark,
      };

      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
    },
    clearData() {
      this.selectedNewOwner = null;
      this.remark = "";
      this.transferDate = null;
    },
  },
  computed: {
    payload() {
      let payload = {
        id: this.id,
        followupStatus: this.selectedFilterStatus ? this.selectedFilterStatus.name : null,
        followupDate: this.approvedDate
          ? this.formatDateToISOString(this.approvedDate)
          : null,
        approvedDateString: this.approvedDate ? this.formatDateToLocaleDateString(this.approvedDate) : null,
        unitId: this.debtFreeFollowUp ? this.debtFreeFollowUp.unitId : null,
        currentOwnerId: this.debtFreeFollowUp ? this.debtFreeFollowUp.ownerId : null,
        newOwnerId: this.selectedNewOwner ? this.selectedNewOwner.id : null,
        transferDate: this.transferDate
          ? this.formatDateToISOString(this.transferDate)
          : null,
        remark: this.remark,
      };

      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmCOR");
    },
    isRoleUpdate() {
      if (this.roleByMenu && this.roleByMenu.update == "Y") {
        return true;
      }
      return false;
    },
    debtFreeFollowUp() {
      return this.$store.getters["debtFreeFollowUp/selectedDebtFreeFollowUp"];
    },
    isFollowUp() {
      if (this.debtFreeFollowUp && this.debtFreeFollowUp.followupStatus != "") {
        return true;
      }
      return false;
    },
    isDisableSave() {
      if (
        !this.isRoleUpdate ||
        this.isFollowUp ||
        !this.selectedFilterStatus ||
        (this.isTransferStatus && !this.selectedNewOwner)
      ) {
        return true;
      }
      return false;
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "save",
          caption: this.lang.save,
          class: "mr-2",
          icon: "icon-save",
          iconColor: "white",
          action: "gotoSave",
          isShow: true,
          isDisable: this.isDisableSave,
        },
      ];
      return dataButtons;
    },
    dataButtonDialog() {
      let dataButtons = [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];

      if (this.isFollowUp) {
        dataButtons = [
          {
            id: "confirm",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "goto-list",
          },
        ];
      }
      return dataButtons;
    },
  },
  watch: {
    payload(curValue) {
      if (this.objToCompare) {
        this.compareObject(this.objToCompare, curValue);
      }
    },
  },
};
</script>
