<template>
  <base-toolbar
    :title="lang.title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoCreate="gotoEdit('create')"
    :hideBackButton="isHideBack"
  ></base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="lang.notificationDialogHeader"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
    ></base-dialog>

    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{ lang.titleListing }}</label>

      <PvDivider></PvDivider>

      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >
        <div class="lg:col-2 md:col-3 col-12 pl-0 pr-2">
          <PvDropdown
            id="filterSearch"
            class="inputfield w-full mr-2"
            :options="filterSearch"
            optionLabel="name"
            optionValue="code"
            v-model="selectedFilterSearch"
            scrollHeight="300px"
            @change="changeFilterSearch"
          />
        </div>
        <div class="flex lg:col-6 md:col-6 col-12 p-0 mr-2" v-if="!isSearchAll">
          <div class="col-12 pl-0 pr-0">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchText"
                :placeholder="lang.searchTextPlaceholder"
                @keyup.enter="filterAccountChart"
                :disabled="isSearchAll"
                :class="{ 'p-inputtext-disabled': isSearchAll }"
                autofocus
              />
            </span>
          </div>
        </div>
        <PvButton @click="filterAccountChart">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="tempAccountChart"
          v-model:selection="selectedAccountChart"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(this.selectedAccountChart.id)"
          scrollable
        >
          <PvColumn
            field="code"
            :header="lang.accountCode"
            style="min-width: 15rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="nameTh"
            :header="lang.accountNameTh"
            style="min-width: 20rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="nameEn"
            :header="lang.accountNameEn"
            style="min-width: 20rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="parentCode"
            :header="lang.parentCode"
            style="min-width: 15rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="activeStatus"
            :header="lang.status"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <base-tag
                v-if="slotProps.data.activeStatus != ''"
                :Caption="
                  slotProps.data.activeStatus.trim() == 'Y'
                    ? lang.active
                    : lang.inActive
                "
                :Class="
                  slotProps.data.activeStatus.trim() == 'Y'
                    ? 'success'
                    : 'secondary'
                "
              />
            </template>
          </PvColumn>
          <PvColumn
            style="max-width: 6rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
            </template>
          </PvColumn>

          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
export default {
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.accountChart.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.accountChart.listing,
        };
      }
    });

    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      isHideBack,
    };
  },
  data() {
    return {
      isLoading: false,
      showValidateDialog: false,
      validateWording: "",
      searchText: "",
      filterSearch: [
        { name: this.lang.all, code: "all" },
        { name: this.lang.accountCode, code: "code" },
        { name: this.lang.accountName, code: "name" },
      ],
      selectedFilterSearch: "all",

      tempAccountChart: [],
      selectedAccountChart: null,

      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ],
    };
  },
  async mounted() {
    await this.initiateData();
    await this.roleByMenuDataReady();
  },
  methods: {
    goBack() {
      this.$router.push({name: "home"})
    },
    gotoEdit(id) {
      if (id != "create") {
        if(!this.isRoleSelect) {
          this.validateWording = this.lang.validateRoleSelect;
          this.showValidateDialog = true;
          return;
        }
      }
      else {
        if(!this.isRoleCreate) {
          this.validateWording = this.lang.validateRoleInsert;
          this.showValidateDialog = true;
          return;
        }
      }
      this.$router.push({name: "account-chart-edit", params:{ id: id }});
    },
    async initiateData() {
      this.searchText = "";
      this.selectedAccountChart = null;
      this.storageName = `erp_search_AccountChartList_${this.selectedProject.code}_${this.userCode}`;
    },
    async roleByMenuDataReady() {
      if (this.isSharedRoleByMenuReady) {
        await this.setLocalSearch();
        await this.getAccountChartList();
      }
    },
    changeFilterSearch() {
      this.initiateData();
    },
    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      if (localSearch) {
        let search = JSON.parse(localSearch);
        if (search.entry === "entry") {
          if (search.code != null && search.code != "") {
            this.selectedFilterSearch = "code";
            this.searchText = search.code;
          } else if (search.name != null && search.name != "") {
            this.selectedFilterSearch = "name";
            this.searchText = search.name;
          } else {
            this.searchText = "";
          }

          search.entry = "";

          localStorage.setItem(this.storageName, JSON.stringify(search));
        }
      }
    },
    validateSearchButton() {
      if (!this.isSearchAll && this.searchText == "") {
        return true;
      }
      return false;
    },
    async getAccountChartList() {
      await this.$store.dispatch("masterAccountChart/resetAccountCharts");
      this.isLoading = true;
      try {

        let payload = {
          projectId: this.selectedProject.id,
        }

        await this.$store.dispatch(
          "masterAccountChart/getAllAccountChart",
          payload
        );
        
        this.tempAccountChart = this.accountChartList;

        if(this.selectedFilterSearch != "all") {
          await this.filterAccountChart();
        }

        this.setLocalStorage();

      } catch (error) {
        this.validateWording = error;
        this.showValidateDialog = true;
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async filterAccountChart() {
      if (this.validateSearchButton()) {
        this.validateWording = this.lang.validateSearchText;
        this.showValidateDialog = true;
        return;
      }

      if (this.selectedFilterSearch == "code") {
        this.tempAccountChart = this.accountChartList.filter((data) =>{
          const code = data.code ? data.code.trim().toLowerCase() : "";
          return code.includes(this.searchText.trim())
        });
      } else if (this.selectedFilterSearch == "name") {
        this.tempAccountChart = this.accountChartList.filter((data) => {
          const nameTh = data.nameTh ? data.nameTh.trim().toLowerCase() : "";
          const nameEn = data.nameEn ? data.nameEn.trim().toLowerCase() : "";
          return (nameTh + nameEn).includes(this.searchText.trim().toLowerCase());
        });
      } else {
        this.getAccountChartList();
      }

      this.setLocalStorage();
    },
    setLocalStorage() {
      localStorage.setItem(this.storageName,JSON.stringify(this.payload));
    },
  },
  computed: {
    payload() {
      const payload = {
        projectId: this.selectedProject.id,
        code: this.selectedFilterSearch == "code" ? this.searchText : null,
        name: this.selectedFilterSearch == "name" ? this.searchText : null,
      };

      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmACC");
    },
    isRoleSelect() {
      if (this.roleByMenu && this.roleByMenu.select == "Y") {
        return true;
      }
      return false;
    },
    isRoleCreate() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    isSearchAll() {
      if (this.selectedFilterSearch == "all") {
        return true;
      }
      return false;
    },
    accountChartList() {
      return this.$store.getters["masterAccountChart/accountCharts"];
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "create",
          caption: this.lang.createAccountChart,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
        },
      ];
      return dataButtons;
    },
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.roleByMenuDataReady();
    },
  },
};
</script>
