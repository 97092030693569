export default {
	title: "ใบสั่งซื้อ",
	changeBook: "เปลี่ยนเล่มเอกสาร",

	columnApprove: "อนุมัติ",
	columnCancelStatus: "ยกเลิก",
	columnPaidStatus: "สถานะ",
	columnCode: "เลขที่",
	columnReferenceDocument: "เอกสารอ้างอิง",
	columnDate: "วันที่",
	columnDueDate: "วันที่ครบกำหนด",
	columnSupplierCode: "รหัสผู้จัดจำหนาย",
	columnSupplierName: "ชื่อผู้จัดจำหนาย",
	columnNetAmount: "มูลค่าสุทธิ",
	columnActualPayAmount: "ยอดชำระจริง",
	columnCurrencyUnit: "หน่วย",
};
