export default {
	title: "รายการสมุดบัญชีรายวัน",
	createAccountBook: "สร้างสมุดบัญชีรายวัน",

	columnCode: "รหัสสมุดบัญชี",
	columnName: "ชื่อสมุดบัญชี",
	colummLockDate: "วันที่สิ้นสุดการล็อกข้อมูล",

	editButton:"ตั้งค่าสมุดรายวัน",
};
