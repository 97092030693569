export default {
	createCustomerGroup: "สร้างช่องทางการส่งเอกสาร", 
	editCustomerGroup: "แก้ไขช่องทางการส่งเอกสาร",
	editTitle: "รายละเอียดช่องทางการส่งเอกสาร", 

	dialogTitleNotification: "แจ้งเตือน",
	dialogTitleAddSuccess: "สร้างสำเร็จ", 
	dialogContentAddSuccess: "คุณสร้างช่องทางการส่งเอกสารสำเร็จ",
	dialogTitleEditSuccess: "บันทึกสำเร็จ",
	dialogContentEditSuccess: "คุณตั้งค่าช่องทางการส่งเอกสารสำเร็จ",
	dialogTitleDeleteSuccess: "ลบสำเร็จ", 
	dialogContentDeleteSuccess: "คุณลบช่องทางการส่งเอกสารสำเร็จ", 
	dialogTitleDeleteConfirm: "ยืนยันการลบช่องทางการส่งเอกสาร",
	dialogContentDeleteConfirm: "คุณต้องการลบช่องทางการส่งเอกสาร {0} ที่ท่านทำอยู่ใช่หรือไม่?", 

	validateParams: "กรุณาระบุให้ครบถ้วน", 
	validateRole: "คุณไม่มีสิทธิ์", 
	validateDuplicate: "ตรวจพบรหัสซ้ำในระบบ", 
	validateNotFound: "ไม่มีข้อมูล", 
	validateReference: "ไม่สามารถลบช่องทางการส่งเอกสารนี้ได้ เนื่องจากมีการใช้งานช่องทางการส่งเอกสารนี้แล้ว", 
	validateNoneId: "ไม่พบข้อมูลช่องทางการส่งเอกสาร",

	labelCode: "รหัสช่องทางการส่งเอกสาร*", 
	placeholderCode: "กรุณาระบุรหัสช่องทางการส่งเอกสาร", 
	validateNoneCode: "กรุณาระบุรหัสช่องทางการส่งเอกสาร", 
	validateDuplicateCode: "มีรหัสช่องทางการส่งเอกสารนี้แล้ว", 
	labelNameThai: "ชื่อช่องทางการส่งเอกสาร* (ภาษาไทย)", 
	placeholderNameThai: "กรุณาระบุชื่อภาษาไทย", 
	validateNameThai: "กรุณาระบุชื่อภาษาไทย", 
	validateLimitText: "กรุณาระบุ {0} ยาวไม่เกิน 50 ตัวอักษร",
	validateChangeCode: "รหัสช่องทางการส่งเอกสาร {0} ไม่สามารถเปลี่ยนแปลงได้ (รองรับภาษาอังกฤษ และตัวเลขเท่านั้น)",
	validateChangeUniqueCode: "รหัสช่องทางการส่งเอกสาร {0} ไม่สามารถเปลี่ยนแปลงได้ (เนื่องจากเป็นรหัสบัญชีหลัก)",
	labelActiveStatus: "สถานะการใช้งาน",
};
