<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoCreate="askForCreate"
    @gotoSave="save"
    @gotoDelete="askForDelete"
  ></base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :classTitle="baseDialogClassTitle"
      :titleConfirm="titleConfirm"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
      @goto-create="createNew"
      @goto-delete="deleteData"
      @back="goBack"
    ></base-dialog>

    <accounting-frequently-item-dialog
      v-model:visible="showAccountDialog"
      :data="selectedDetail"
      :isOpen="showAccountDialog"
      @close="showAccountDialog = false"
      @item-selected="selectedAccountItem"
    ></accounting-frequently-item-dialog>

    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{ lang.titleEdit }}</label>

      <PvDivider></PvDivider>

      <section class="flex flex-wrap fontlabel" :class="textFormClass">
        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
          <label for="code">{{ lang.code }}*</label>
          <PvInputText
            id="code"
            type="text"
            class="inputfield w-full mt-1"
            v-model="code"
            :class="{ 'p-invalid': codeError }"
            @keypress="codeError = false"
          />
          <small v-if="codeError" id="code-help" class="p-error">
            {{ lang.validateCode }}
          </small>
        </div>
        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
          <label for="name">{{ lang.name }}*</label>
          <PvInputText
            id="name"
            type="text"
            class="inputfield w-full mt-1"
            v-model="name"
            :class="{ 'p-invalid': nameError }"
            @keypress="nameError = false"
          />
          <small v-if="nameError" id="name-help" class="p-error">
            {{ lang.validateName }}
          </small>
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="description">{{ lang.description }}</label>
          <PvInputText
            id="description"
            type="text"
            class="inputfield w-full mt-1"
            v-model="description"
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="status">{{ lang.status }}</label>
          <PvDropdown
            id="status"
            class="inputfield w-full mt-1"
            :options="status"
            optionLabel="name"
            optionValue="code"
            v-model="selectedStatus"
            scrollHeight="300px"
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="format">{{ lang.format }}</label>
          <PvDropdown
            id="format"
            class="inputfield w-full mt-1"
            :options="format"
            optionLabel="name"
            optionValue="code"
            v-model="selectedFormat"
            scrollHeight="300px"
          />
        </div>
      </section>

      <div class="flex justify-content-end pt-3 pb-3 pr-2">
        <PvButton class="p-button-outlined ml-2" @click="openAccountDialog('create')">
          <icon-plus-add
            iconColor="primary"
            height="16"
            width="16"
          />
          <span class="font-sm ml-2">{{ lang.createCaption }}</span>
        </PvButton>
      </div>

      <div v-if="detailError" class="pb-2">
        <PvInlineMessage severity="error" class="w-full justify-content-start">{{ lang.validateDetail}}</PvInlineMessage>
      </div>

      <PvDataTable
        :value="details"
        dataKey="id"
        responsiveLayout="scroll"
        :class="textTableClass"
        v-model:selection="selectedDetail"
        selectionMode="single"
        @row-dblclick="openAccountDialog(this.selectedDetail)"
      >
        <PvColumn
          field="itemNumber"
          :header="lang.itemNo"
          style="max-width: 3rem"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="accountCode"
          :header="lang.accountCode"
          style="min-width: 4rem"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="accountName"
          :header="lang.accountName"
          style="min-width: 8rem"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="description"
          :header="lang.description"
          style="min-width: 8rem"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="debitAmount"
          :header="lang.debit"
          style="min-width: 4rem"
          headerClass="h-right"
          bodyClass="text-right"
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.debitAmount) }}
          </template>
        </PvColumn>
        <PvColumn
          field="creditAmount"
          :header="lang.credit"
          style="min-width: 4rem"
          headerClass="h-right"
          bodyClass="text-right"
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.creditAmount) }}
          </template>
        </PvColumn>
        <PvColumn
          style="min-width: 3rem"
          headerClass="h-center"
          bodyStyle="text-align: end; justify-content: end; align-items: end;"
          alignFrozen="right"
          frozen
        >
          <template #body="slotProps">
            <PvButton
              type="button"
              class="p-button-secondary p-button-text p-0 mr-3"
              @click="openAccountDialog(slotProps.data)"
            >
              <icon-pen-edit iconColor="secondary" width="24" height="24"/>
            </PvButton>
            <PvButton
              type="button"
              class="p-button-secondary p-button-text p-0"
              @click="deleteRow(slotProps.data.id)"
            >
              <icon-trash-delete-bin
                iconColor="secondary"
                width="24"
                height="24"
              />
            </PvButton>
          </template>
        </PvColumn>

        <template #empty>
          <div class="w-full" style="text-align: center">
            <label>{{ this.lang.tableEmpty }}</label>
          </div>
        </template>
      </PvDataTable>

    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import AccountingFrequentlyItemDialog from "../../../components/master/accountingFrequently/AccountingFrequentlyItemDialog.vue";
import usePageDirty from "../../../hooks/pageDirty";
export default {
  props: ["accountBookId", "id", "projectCode"],
  components :{
    AccountingFrequentlyItemDialog
  },
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.accountingFrequently.edit,
          ...lang.th.master.accountingFrequently.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.accountingFrequently.edit,
          ...lang.en.master.accountingFrequently.listing,
        };
      }
    });

    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    const { compareObject, isCompareObject } = usePageDirty();

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      compareObject,
      isCompareObject,
    };
  },
  data() {
    return {
      //Dialog
      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: "",
      validateWording: "",

      code: "",
      name: "",
      description: "",
      status: [
        { name: this.lang.active, code: "Y" },
        { name: this.lang.inActive, code: "N" },
      ],
      selectedStatus: "Y",
      format: [
        { name: this.lang.setproportion, code: "P" },
        { name: this.lang.nonSetproportion, code: "" },
      ],
      selectedFormat: "P",
      details: [],
      selectedDetail: null,
      showAccountDialog: false,

      isCreateNew: false,
      isDelete: false,
      isDeleteSuccess: false,
      totalDebitAmount: 0,
      totalCreditAmount: 0,
      diffAmount: 0,

      codeError: false,
      nameError: false,
      detailError: false,
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    await this.roleByMenuDataReady();
    await this.checkShouldSetData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.setLocalSearch();
      this.setCompareObject();
      this.$router.push({name: "accounting-frequently-accountBookId", params:{accountBookId: this.accountBookId}});
    },
    async initiateData() {
      this.code = "";
      this.name = "";
      this.description = "";
      this.selectedStatus = "Y";
      this.selectedFormat = "P";
      this.details = [];
      this.showAccountDialog = false;
      this.totalDebitAmount = 0;
      this.totalCreditAmount = 0;
      this.diffAmount = 0;
      this.codeError = false;
      this.nameError = false;
      this.detailError = false;
      await this.$store.dispatch("accountingFrequently/resetSelectedAccountingFrequently");
    },
    clearBaseDialog() {
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.validateWording = "";

      this.isCreateNew = false;
      this.isDelete = false;
      this.isDeleteSuccess = false;
    },
    setLocalSearch() {
      let storageName = `erp_search_AccountingFrequentlyList_${this.selectedProject.code}_${this.userCode}`;
      const localSearch = localStorage.getItem(storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      localStorage.setItem(storageName, JSON.stringify(search));
    },
    async roleByMenuDataReady() {
      if(this.isSharedRoleByMenuReady) {
        if(!this.isCreateDocument) {
          await this.getAccountingFrequentlyById();
        }
      }
    },
    async checkShouldSetData() {
      if(this.isSharedRoleByMenuReady) {
        if(this.accountingFrequently) {
          await this.setData();
        } else if(this.isCreateDocument) {
          this.setLoading(false);
        }
        await this.setCompareData();
      }
    },
    async setData() {
      this.code = this.accountingFrequently.code;
      this.name = this.accountingFrequently.name;
      this.description = this.accountingFrequently.description;
      this.selectedStatus = this.accountingFrequently.status;
      this.selectedFormat = this.accountingFrequently.type;
      this.details = this.accountingFrequently.accountingFrequentlyDetail.sort(function (a, b) {
        return a.itemNumber - b.itemNumber;
      });
      this.updateTotalAmount();
      this.setLoading(false);
    },
    async getAccountingFrequentlyById() {
      try {
        await this.$store.dispatch("accountingFrequently/getAccountingFrequentlyById", this.id);

        // console.log("getAccountingFrequentlyById", this.accountingFrequently);
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    openAccountDialog(data) {
      if (data == "create") {
        this.selectedDetail = null;
      } else {
        this.selectedDetail = data;
      }
      this.showAccountDialog = true;
    },
    selectedAccountItem(data) {
      if(data.id) {
        let detail = this.details.find((detail) => detail.id == data.id);
        detail.accountId = data.accountId;
        detail.accountCode = data.accountCode.trim();
        detail.accountName = data.accountName.trim();
        detail.description = data.description.trim();
        detail.debitAmount = data.debitAmount;
        detail.creditAmount = data.creditAmount;
      } else {
        data.itemNumber = this.countDetails + 1;
        data.id = "dummy" + (this.countDetails + 1);
        this.details.push(data);
      }
      this.updateTotalAmount();
      this.detailError = false;
    },
    deleteRow(id) {
      const index = this.details.findIndex((detail) => detail.id === id);
      this.details.splice(index, 1);
      this.updateItemNumber();
      this.updateTotalAmount();
    },
    updateItemNumber() {
      let number = 1;
      this.details.forEach((element) => {
        if (element.id) {
          element.itemNumber = number;
        }
        number++;
      });
    },
    updateTotalAmount() {
      let sumDebit = 0;
      let sumCredit = 0;
      this.details.forEach((detail) => {
        sumDebit += detail.debitAmount;
        sumCredit += detail.creditAmount;
      });
      this.totalDebitAmount = sumDebit;
      this.totalCreditAmount = sumCredit;
      this.diffAmount = this.totalDebitAmount.toFixed(2) - this.totalCreditAmount.toFixed(2);
    },
    async setCompareData() {
      let details = [];

      for (const each of this.details.filter((each) => each.id)) {
        let detail = null;
        if (each.id.includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        details.push(detail);
      }

      let objToCompare = {
        projectId: this.selectedProject.id,
        accountBookId: this.accountBookId,
        code: this.code.trim(),
        name: this.name.trim(),
        description: this.description.trim(),
        type: this.selectedFormat,
        status: this.selectedStatus,
        details: details,
      }
      if (!this.isCreateDocument) {
        objToCompare = {
          ...objToCompare,
          id: this.id,
        };
      }

      this.objToCompare = objToCompare;
      this.setCompareObject();
    },
    async setCompareObject() {
      if (this.isCreateDocument) {
        this.compareObject(this.objToCompare, this.payload);
      } else {
        let payload = {
          ...this.payload,
          id: this.id
        }
        this.compareObject(this.objToCompare, payload);
      }
    },
    async askForCreate() {
      this.clearBaseDialog();
      if(!this.isRoleCreate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return;
      }
      let payload = this.payload;
      if (!this.isCreateDocument) {
        payload = {
          ...this.payload,
          id: this.id
        }
      }
      let result = this.isCompareObject(this.objToCompare, payload);
      this.isCreateNew = true;
      if (result) {
        this.titleConfirm = this.lang.createNewAccountingFrequently;
        this.validateWording = this.lang.askForCreateNewAccountingFrequently;
        this.showValidateDialog = true;
      } else {
        await this.createNew();
      }
    },
    async createNew() {
      this.setLoading(true);
      await this.initiateData();
      await this.$router.push({name: "accounting-frequently-accountBookId-edit", params:{accountBookId: this.accountBookId, id: "create"}});
      await this.setCompareData();
      this.showValidateDialog = false; 
      this.setLoading(false);
    },
    validateRole() {
      this.clearBaseDialog();
      if(this.isCreateDocument && !this.isRoleCreate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return true;
      } else if(!this.isCreateDocument && !this.isRoleUpdate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateRoleUpdate;
        this.showValidateDialog = true;
        return true;
      }
      return false;
    },
    wordingValidate() {
      if(!this.code) {
        return this.lang.validateFieldNoValue + this.lang.code;
      }

      if(!this.name) {
        return this.lang.validateFieldNoValue + this.lang.name;
      }

      if(this.countDetails == 0) {
        return this.lang.validateDetail;
      }

      if(this.totalDebitAmount == 0 || this.totalCreditAmount == 0 || this.diffAmount > 0) {
        return this.lang.validateDiffAmount;
      }
    },
    validateData() {
      let returnStatus = false;

      if(!this.code) {
        this.codeError = true;
        returnStatus = true;
      }

      if(!this.name) {
        this.nameError = true;
        returnStatus = true;
      }

      if(this.countDetails == 0) {
        this.detailError = true;
        returnStatus = true;
      }

      if(this.totalDebitAmount == 0 || this.totalCreditAmount == 0 || this.diffAmount > 0) {
        returnStatus = true;
      }

      if(returnStatus) {
        this.clearBaseDialog();
        this.baseDialogClassTitle = "p-error";
        this.titleConfirm = this.lang.validateSaveTitle;
        this.validateWording = this.wordingValidate();
        this.showValidateDialog = true;
      }
      
      return returnStatus;
    },
    validateDetail() {
      if(this.countDetails == 0) {
        this.detailError = true;
        this.baseDialogClassTitle = "p-error";
        this.titleConfirm = this.lang.validateSaveTitle
        this.validateWording = this.lang.validateDetail;
        this.showValidateDialog = true;
        return true;
      }

      if(this.totalDebitAmount == 0 || this.totalCreditAmount == 0 || this.diffAmount > 0) {
        this.baseDialogClassTitle = "p-error";
        this.titleConfirm = this.lang.validateSaveTitle
        this.validateWording = this.lang.validateDiffAmount;
        this.showValidateDialog = true;
        return true;
      }
      return false;
    },
    async save() {
      if (this.validateRole()) {
        return;
      }
      if (this.validateData()) {
        return;
      }
      // if (this.validateDetail()) {
      //   return;
      // }
      this.clearBaseDialog();
      this.setLoading(true);
      try {
        if(this.isCreateDocument) {
          await this.addData();
        } else {
          await this.updateData();
        }

        if(this.accountingFrequently.code == "104") {
          this.baseDialogClassTitle = "";
          this.titleConfirm = this.lang.notificationDialogHeader
          this.validateWording = this.lang.duplicateCode.replace(":code",this.code.trim());
          this.showValidateDialog = true;
          this.setLoading(false);
          return;
        }

        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.saveSuccess;
        this.validateWording = this.lang.code + " " + this.accountingFrequently.data.code;
        this.showValidateDialog = true;
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);

      if (this.isCreateDocument) {
        await this.$router.replace({
          name: "accounting-frequently-accountBookId-edit",
          params: {
            accountBookId: this.accountBookId,
            id: this.accountingFrequently.data.id
          }
        });
        // await this.setCompareData();
      }
      await this.setCompareData();
    },
    async addData() {
      // console.log("addData", this.payload);
      await this.$store.dispatch(
        "accountingFrequently/createAccountingFrequently",
        this.payload
      );
    },
    async updateData() {
      let payload = {
        ...this.payload,
        id: this.id,
      }
      // console.log("updateData", payload);
      await this.$store.dispatch(
        "accountingFrequently/updateAccountingFrequently", payload);
    },
    askForDelete() {
      this.clearBaseDialog();
      if(!this.isCreateDocument && !this.isRoleDelete) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateRoleDelete;
        this.showValidateDialog = true;
        return;
      }
      this.isDelete = true;
      this.titleConfirm = this.lang.deleteAccountingFrequentlyTitle;
      this.validateWording = this.lang.askForAccountingFrequentlyTitle.replace(":code", this.code);
      this.showValidateDialog = true;
    },
    async deleteData() {
      this.showValidateDialog = false;
      this.setLoading(true);
      try {
        let payload = {
          projectId: this.selectedProject.id,
          id: this.id,
        };

        await this.$store.dispatch(
          "accountingFrequently/deleteAccountingFrequently",
          payload
        );

        this.isDelete = false;
        this.isDeleteSuccess = true;
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.successfully;
        this.validateWording = this.lang.deleteAccountingFrequentlySuccess;
        this.showValidateDialog = true;
        
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
  },
  computed: {
    payload() {
      let details = [];

      for (const each of this.details.filter((each) => each.accountId)) {
        let detail = null;
        if (each.id.includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        details.push(detail);
      }

      let payload = {
        projectId: this.selectedProject.id,
        accountBookId: this.accountBookId,
        code: this.code.trim(),
        name: this.name.trim(),
        description: this.description.trim(),
        type: this.selectedFormat,
        status: this.selectedStatus,
        details: details,
      }

      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    isCreateDocument() {
      return this.id === "create";
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("VCHPTN");
    },
    isRoleCreate() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    isRoleUpdate() {
      if (this.roleByMenu && this.roleByMenu.update == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRoleDelete() {
      if (this.roleByMenu && this.roleByMenu.delete == "Y") {
        return true;
      } else {
        return false;
      }
    },
    title() {
      let result = this.lang.createAccountingFrequently;
      if (!this.isCreateDocument) {
        result = this.lang.editAccountingFrequently;
      }
      return result;
    },
    // isDisableSave() {
    //   if (this.isCreateDocument) {
    //     if (!this.isRoleCreate || !this.code || !this.name || this.countDetails <= 0) {
    //       return true;
    //     }
    //   } else {
    //     if (!this.isRoleUpdate || !this.code || !this.name || this.countDetails <= 0) {
    //       return true;
    //     }
    //   }
    //   return false;
    // },
    // isDisableDelete() {
    //   if (!this.isRoleDelete || this.isCreateDocument) {
    //     return true;
    //   }
    //   return false;
    // },
    accountingFrequently() {
      return this.$store.getters["accountingFrequently/selectedAccountingFrequently"];
    },
    countDetails() {
      let length = this.details.length;
      return length;
    },
    saveEditSuccess() {
      let result = this.lang.editAccountingFrequentlySuccess;
      if (this.isCreateDocument) {
        result = this.lang.createAccountingFrequentlySuccess;
      }
      return result;
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "delete",
          action: "gotoDelete",
          isDisable: this.isCreateDocument,
          type: "delete"
        },
        {
          id: "create",
          action: "gotoCreate",
          type: "create"
        },
        {
          id: "save",
          action: "gotoSave",
          type: "save"
        },
      ];
      return dataButtons;
    },
    dataButtonDialog() {
      let dataButtonDialog = [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];

      if(this.isCreateNew) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "gotoCreate",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-create",
          },
        ]
      } else if(this.isDelete) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "gotoDelete",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-delete",
          },
        ]
      } else if(this.isDeleteSuccess) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "back",
          },
        ]
      }
      return dataButtonDialog;
    },
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.checkShouldSetData();
    },
  }
};
</script>
