export default {
	titleEdit: "รายละเอียดผู้ลงนาม",
	editSignator: "แก้ไขผู้ลงนาม",
    validatePosition: "กรุณาระบุตำแหน่ง",
	validateName: "กรุณาระบุชื่อผู้ลงนาม",
	signature: "ลายเซ็น",
	signatureUrlInfo: "อัตราส่วน 16:9 (เช่น 62x35 เป็นต้น) รองรับไฟล์ .jpeg, .jpg, .png ไม่เกิน 50kb",
	validateSignatureUrlTitle: "ไม่สามารถอัปโหลดไฟล์ :name ได้",
    validateTypeSignatureUrlContent: "สกุลไฟล์ที่อนุญาติมีดังนี้ .JPEG, .JPG และ .PNG เท่านั้น",
    validateSizeSignatureUrlContent: "ขนาดไฟล์ที่อนุญาติต้องไม่เกิน 50 Kb เท่านั้น",
	validateImage: "โปรดอัปโหลดไฟล์",
	validateDeleteTitle: "ไม่สามารถลบรายการได้",
    validateDeleteContent: "เนื่องจากคุณยังไม่บันทึกรายการ",
    titleSignatorDeleteConfirm: "ลบข้อมูล",
    contentSignatorDeleteConfirm: "คุณต้องการลบผู้ลงนาม :position (:name) ใช่หรือไม่?",
	errorSignatorDeleteHaveRefer: "ไม่สามารถลบผู้ลงนามนี้ได้ เนื่องจากมีเอกสารอ้างถึง",
    signatorDeleteSuccess: "คุณลบผู้ลงนามสำเร็จ",
};
