export default {
	title: "Meter Usage Monthly Report",
	downloadPDF: "Download PDF",
	downloadExcel: "Download Excel",
	reportType: "Report Type",
	summaryReportType: "Summary monthly",
	historyReportType: "History for the past 4 months",
	statusMeterUsage: "Status",
	validateStatusMeterUsage: "Please select status.",
	statusAll: "All",
	statusGreen: "Regular",
	statusYellow: "Pass inspection",
	statusRed: "Irregular",

	waterType: "Water",
	electricityType: "Electricity",
	coolingAirType: "Cooling Air",
};
