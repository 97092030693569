<template>
  <base-toolbar
    :title="lang.labelFromResident"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoCreate="gotoEdit('create')"
    @gotoReport="gotoReport"
    :hideBackButton="isHideBack"
  >
  </base-toolbar>

  <base-dialog
    v-model:visible="showValidateDialog"
    :titleConfirm="titleConfirm"
    :classTitle="baseDialogClassTitle"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    @confirm="showValidateDialog = false"
    @close="showValidateDialog = false"
  ></base-dialog>

  <import-resident-dialog
    v-model:visible="showUploadResidentDialog"
    @close="showUploadResidentDialog = false"
  ></import-resident-dialog>

  <!-- <base-sidebar
    :isVisibleSidebar="visibleRight"
    @visibleSidebar="setVisibleSidebar"
    @gotoEdit="gotoEdit(this.selectedResident.id)"
  >
    <template #sidebarHeader>
      <span class="font-3xl pr-4">
        <b>{{ this.selectedResident.name }}</b>
      </span>
      <base-tag :caption="this.activeStatus" :class="this.activeStatusClass" />
    </template>

    <base-card
      :titleHeader="lang.generalInformation"
      :isVisibleExpandButton="false"
      :isHideBackground="true"
      :isVisibleCard="true"
      :isVisibleIconButton="true"
      :screenSizeRegular="false"
      class="mt-2"
    >
      <base-mobile-table :dataMobileTable="generalInformationTable">
      </base-mobile-table>
    </base-card>

    <base-card
      :titleHeader="lang.living"
      :isVisibleExpandButton="false"
      :isHideBackground="true"
      :isVisibleCard="true"
      :isVisibleIconButton="true"
      :screenSizeRegular="false"
      class="mt-4"
    >
      <base-mobile-table :dataMobileTable="unitInformationTable">
      </base-mobile-table>
    </base-card>

    <base-card
      :titleHeader="lang.addressInformation"
      :isVisibleExpandButton="false"
      :isHideBackground="true"
      :isVisibleCard="true"
      :isVisibleIconButton="true"
      :screenSizeRegular="false"
      class="mt-4"
    >
      <base-mobile-table :dataMobileTable="addressInformationTable">
      </base-mobile-table>
    </base-card>
  </base-sidebar> -->

  <base-container>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.title }}</label>

        <div class="flex">
          <PvButton
            class="p-button-outlined"
            @click="importResident"
            v-if="isShowImportDataButton"
          >
            <icon-download iconColor="primary" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{ lang.importResident }}</span>
          </PvButton>
        </div>

        <PvDivider></PvDivider>
      </div>
      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >
        <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            id="filterSearch"
            class="inputfield w-full mr-2"
            :options="filterSearch"
            optionLabel="name"
            optionValue="code"
            v-model="selectedFilterSearch"
            scrollHeight="300px"
            @change="changeFilterSearch"
          />
        </div>
        <div class="flex lg:col-6 md:col-12 col-12 p-0 mr-2" v-if="!isSearchTextDisabled">
          <div class="col-12 pl-0 pr-0">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                v-model="searchText"
                :placeholder="lang.searchText"
                class="inputfield w-full"
                @keyup.enter="getResidents"
                autofocus
                :disabled="isSearchTextDisabled"
                :class="{ 'p-inputtext-disabled': isSearchTextDisabled }"
              />
            </span>
          </div>
        </div>
        <PvButton @click="getResidents">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>
      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="residents"
          v-model:selection="selectedResident"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(this.selectedResident.id)"
          scrollable
        >
          <PvColumn
            field="code"
            :header="lang.residentCode"
            style="min-width: 14rem; height: 56px"
            headerClass="h-left "
            bodyClass="text-left "
            sortable
          >
            <template #body="slotProps">
              <u>{{ slotProps.data.code }}</u>
            </template>
          </PvColumn>
          <PvColumn
            field="name"
            :header="lang.residentName"
            style="min-width: 20rem"
            headerClass="h-left "
            bodyClass="text-left "
            sortable
          >
          </PvColumn>
          <PvColumn
            field="mobileNumber"
            :header="lang.mobileNumber"
            style="min-width: 8rem"
            headerClass="h-left "
            bodyClass="text-left "
          >
          </PvColumn>
          <PvColumn
            field="email"
            :header="lang.residentEmail"
            style="min-width: 20rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
          </PvColumn>
          <PvColumn
            style="min-width: 2rem"
            headerClass="h-center"
            bodyStyle="justify-content: end; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
            </template>
          </PvColumn>

          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              :first="showCurrentPage"
            >
              <template #start="slotProps">
                {{ lang.page }}:
                {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
              </template>
            </PvPaginator>
          </template>          

          <template #empty>
            <div class="w-full m-3" style="text-align: center">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>

        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import ImportResidentDialog from "../../../components/master/resident/ImportResidentDialog.vue";
export default {
  components: {
    ImportResidentDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textFormClass, textTableClass } = useTextStyle();
    const { formatDate } = useFormat();
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.resident.listing,
          ...lang.th.master.resident.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.resident.listing,
          ...lang.en.master.resident.edit,
        };
      }
    });

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,
      textFormClass,
      textTableClass,
      formatDate,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      isHideBack,
    };
  },
  data() {
    return {
      isLoading: false,
      selectedResident: null,
      searchText: "",
      selectedPage: 1,
      rowsPerPage: 10,

      // visibleRight: false,
      // activeStatus: "",
      // activeStatusClass: "",
      // generalInformationTable: [],
      // unitInformationTable: [],
      // addressInformationTable: [],

      //Search
      filterSearch: [
        { name: this.lang.residentAll, code: "all" },
        { name: this.lang.residentCode, code: "code" },
        { name: this.lang.residentName, code: "name" },
        { name: this.lang.mobileNumber, code: "phone" },
      ],
      selectedFilterSearch: "all",
      storageName: "",

      //dialog
      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: this.lang.notificationDialogHeader,
      validateWording: "",
      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ],

      showUploadResidentDialog: false,
    };
  },
  // async created() {
  //   await this.getResidents();
  // },
  async mounted() {
    this.isLoading = true;
    this.initiateData();
    this.roleByMenuDataReady();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    async onPageChange(event) {      
      if (event.rows != this.rowsPerPage) {
        this.selectedPage = 1;
      } else {
        this.selectedPage = event.page + 1;
      }
      this.rowsPerPage = event.rows;
      this.getResidents();
    },
    // gotoEdit(id) {
    //   // console.log("gotoEdit", id);
    //   this.$router.push(`/resident/${id}`);
    // },
    gotoEdit(id) {
      if (!this.isRoleSelect && id != "create") {
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleSelect;
        this.showValidateDialog = true;
        return true;
      } else if (!this.isRoleInsert && id == "create") {
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return true;
      }
      this.$router.push({ name: "resident-edit", params: { id: id } });
    },
    gotoReport() {
      // console.log("gotoReport");
    },
    initiateData() {
      this.selectedFilterSearch = "all";
      this.searchText = "";
      this.baseDialogClassTitle = "";
      this.storageName = `erp_search_ResidentList_${this.selectedProject.code}_${this.userCode}`;
      this.showUploadResidentDialog = false;
    },
    async roleByMenuDataReady() {
      if (this.isSharedRoleByMenuReady) {
        await this.setLocalSearch();
        await this.getResidents();
      }
    },
    async getResidents() {
      if (this.selectedFilterSearch != "all" && this.searchText == "") {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateSearchText;
        this.showValidateDialog = true;
        return;
      }

      this.isLoading = true;
      let code = null;
      let name = null;
      let phone = null;
      let searchText = null;
      if (this.selectedFilterSearch == "code") {
        code = this.searchText;
      } else if (this.selectedFilterSearch == "name") {
        name = this.searchText;
      } else if (this.selectedFilterSearch == "phone") {
        phone = this.searchText;
      } else if (this.selectedFilterSearch == "all") {
        searchText = this.searchText;
      }  

      let where = {
        projectId: this.selectedProject.id,
        code: code,
        name: name,
        mobileNumber: phone,
        searchText: searchText
      };

      let payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };

      try {
        await this.$store.dispatch("masterResident/getResidents", payload);

        payload = {
          ...where,
          selectedFilterSearch: this.selectedFilterSearch,
          rowsPerPage: this.rowsPerPage,
          selectedPage: this.selectedPage,
          searchText: this.searchText
        };

        localStorage.setItem(this.storageName, JSON.stringify(payload));
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 5000,
        });
      }

      this.isLoading = false;
    },
    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      if (localSearch) {
        let search = JSON.parse(localSearch);
        if (search.entry === "entry") {
          this.selectedFilterSearch = search.selectedFilterSearch;
          this.searchText = search.searchText;
          this.rowsPerPage = search.rowsPerPage;
          this.selectedPage = search.selectedPage;
          search.entry = "";

          localStorage.setItem(this.storageName, JSON.stringify(search));
        }
      }
    },
    // async getUnitResidents(customerId) {
    //   await this.$store.dispatch("unitResident/resetUnitResidents");
    //   let where = {
    //     projectId: this.selectedProject.id,
    //     customerId: customerId,
    //   };

    //   try {
    //     await this.$store.dispatch(
    //       "unitResident/getAllUnitResidentsByCustomerId",
    //       where
    //     );
    //   } catch (error) {
    //     this.$toast.add({
    //       severity: "error",
    //       summary: this.lang.notificationDialogHeader,
    //       detail: error,
    //       life: 5000,
    //     });
    //   }
    // },
    // setVisibleSidebar(data) {
    //   this.visibleRight = data;
    // },
    // getGeneralInformationTable() {
    //   this.generalInformationTable = [
    //     {
    //       data: [
    //         {
    //           name: this.lang.residentCode,
    //           value: this.selectedResident.code
    //             ? this.selectedResident.code
    //             : "-",
    //         },
    //       ],
    //     },
    //     {
    //       data: [
    //         {
    //           name: this.lang.fieldName,
    //           value: this.selectedResident.name
    //             ? this.selectedResident.name
    //             : "-",
    //         },
    //       ],
    //     },
    //     {
    //       data: [
    //         {
    //           name: this.lang.mobileNumber,
    //           value: this.selectedResident.mobileNumber
    //             ? this.selectedResident.mobileNumber
    //             : "-",
    //         },
    //       ],
    //     },
    //   ];
    // },
    // async getUnitInformationTable() {
    //   await this.getUnitResidents(this.selectedResident.id);

    //   this.unitInformationTable = [];
    //   if (this.unitResidents.length > 0) {
    //     this.unitResidents.forEach((element) => {
    //       let unitdata = {
    //         data: [
    //           {
    //             name: this.lang.room + " " + element.unitCode,
    //             value: element.erpLivingTypeTh,
    //           },
    //         ],
    //       };
    //       this.unitInformationTable.push(unitdata);
    //     });
    //   } else {
    //     this.unitInformationTable = [
    //       {
    //         data: [
    //           {
    //             name: "-",
    //             value: "",
    //           },
    //         ],
    //       },
    //     ];
    //   }
    // },
    // getAddressInformationTable() {
    //   let billAddress =
    //     this.selectedResident.billingAddress1 +
    //     " " +
    //     this.selectedResident.billingAddress2 +
    //     " " +
    //     this.selectedResident.billingAddress3 +
    //     " " +
    //     this.selectedResident.billingPostCode;

    //   this.addressInformationTable = [
    //     {
    //       data: [
    //         {
    //           name: this.lang.receiptAddress,
    //           value: "",
    //         },
    //         {
    //           name: billAddress.trim() == "" ? "-" : billAddress,
    //           value: "",
    //         },
    //       ],
    //     },
    //  ];
    // },
    // async showSidebar(data) {
    //   this.setLoading(true);
    //   this.selectedResident = data;

    //   if (data.activeStatus == "Y") {
    //     this.activeStatus = "ใช้งานอยู่";
    //     this.activeStatusClass = "success";
    //   } else {
    //     this.activeStatus = "ไม่ใช้งาน";
    //     this.activeStatusClass = "error";
    //   }

    //   this.getGeneralInformationTable();
    //   await this.getUnitInformationTable();
    //   this.getAddressInformationTable();

    //   this.setLoading(false);
    //   this.visibleRight = true;
    // },
    changeFilterSearch() {
      this.searchText = "";
    },
    async checkShouldSetData() {
      // console.log(
      //   "this.isSharedRoleByMenuReady=",
      //   this.isSharedRoleByMenuReady
      // );
      if (this.isSharedRoleByMenuReady) {
        await this.getResidents();
      }
    },
    importResident() {
      if (!this.isRoleInsert) {
        this.validateWording = this.lang.validateRoleImport;
        this.showValidateDialog = true;
        return true;
      }
      this.showUploadResidentDialog = true;
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    residents() {
      return this.$store.getters["masterResident/residents"];
    },
    totalPage() {
      return this.$store.getters["masterResident/totalPage"];
    },
    currentPage() {
      return this.$store.getters["masterResident/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    // unitResidents() {
    //   return this.$store.getters["unitResident/unitResidents"];
    // },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmCOR");
    },
    isRoleSelect() {
      if (this.roleByMenu && this.roleByMenu.select == "Y") {
        return true;
      }
      return false;
    },
    isRoleInsert() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },

    dataButtons() {
      return [
        {
          id: "createCaption",
          caption: this.lang.add,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
          // isDisable: !this.isRoleInsert,
        },
      ];
    },    
    showCurrentPage(){
      let page = 0;
      page = this.rowsPerPage * (this.selectedPage - 1)
      return page;
    },
    isSearchTextDisabled(){
      if(this.selectedFilterSearch == "all"){
        return true;
      }
      return false;
    },
    isShowImportDataButton() {
      let userGroupId = process.env.VUE_APP_USER_GROUP_ID.split("|")
      return userGroupId.includes(this.roleByMenu.userGroupId);
    }
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.checkShouldSetData();
    },
  },
};
</script>
