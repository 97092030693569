export default {
  setAddress(state, payload) {
    let addressList = [...state.address.addressList];
    for (const address of payload.addressList) {
      if (addressList.findIndex((c) => c.id == address.id) < 0) {
        addressList.push(address);
      }
    }
    state.address.addressList = addressList;
    state.address.currentPage = payload.currentPage;
    state.address.totalPage = payload.totalPage;
  },
  resetAddress(state) {
    const address = {
      addressList: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.address = address;
  },
};
