<template>
  <base-toolbar
    :title="lang.calculateInvoiceDetail"
    @goBack="goBack"
  ></base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="titleConfirmDialog"
      :wordingConfirm="wordingConfirmDialog"
      :dataButtons="dataButtonDialog"
      @close="closeValidateDialog"
    ></base-dialog>
    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5">
      <div>
        <label class="font-lg font-semibold">{{ lang.invoiceDetail }}</label>
      </div>

      <PvDivider></PvDivider>

      <section class="flex flex-wrap fontlabel" :class="textFormClass">
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="code">{{ lang.columnInvoiceCode }}</label>
          <PvInputText
            id="code"
            type="text"
            class="inputfield w-full mt-1 input-number-disable"
            v-model="code"
            disabled
          />
        </div>
        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
          <label for="date">{{ lang.columnInvoiceDate }}</label>
          <PvCalendar
            id="date"
            v-model="date"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            disabled
          />
        </div>
        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
          <label for="dueDate">{{ lang.dueDate }}</label>
          <PvCalendar
            id="dueDate"
            v-model="dueDate"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            disabled
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label>{{ lang.unitCode }}</label>
          <PvSkeleton
            v-if="!isSharedUnitReady"
            class="inputfield w-full mt-1"
            height="39px"
          />
          <base-unit-auto-complete
            id="unit"
            v-model="selectedUnit"
            class="inputfield w-full mt-1 mr-2"
            @load-finish="unitDataReady"
            v-show="isSharedUnitReady"
            disabled
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="unitAddressNo">{{ lang.columnAddressNo }}</label>
          <PvInputText
            id="unitAddressNo"
            type="text"
            class="inputfield w-full mt-1 input-number-disable"
            v-model="unitAddressNo"
            disabled
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="customerName">{{ lang.columnCustomerName }}</label>
          <PvInputText
            id="customerName"
            type="text"
            class="inputfield w-full mt-1 input-number-disable"
            v-model="customerName"
            disabled
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="netAmount">{{ lang.columnNetAmount }}</label>
          <PvInputNumber
            id="netAmount"
            class="inputfield w-full mt-1"
            inputClass="input-number-disable"
            v-model="netAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            disabled
          />
        </div>

        <div class="pt-6">
          <label class="font-lg font-semibold">{{
            lang.invoiceDetailList
          }}</label>
        </div>
      </section>

      <div class="my-4">
        <PvDataTable
          :value="selectedInvoiceDetail"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-sm"
          :class="textTableClass"
          :loading="isLoading"
        >
          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
          <PvColumn
            field="itemNo"
            :header="lang.itemNo"
            style="min-width: 4rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-center font-normal"
          ></PvColumn>
          <PvColumn
            field="productCode"
            :header="lang.columnProductCode"
            style="min-width: 6rem"
            headerClass="h-left"
            bodyClass="text-left font-normal"
          ></PvColumn>
          <PvColumn
            field="productName"
            :header="lang.columnProductName"
            style="min-width: 16rem"
            headerClass="h-left"
            bodyClass="text-left font-normal"
          ></PvColumn>
          <PvColumn
            field="remark"
            :header="lang.columnRemark"
            style="min-width: 20rem"
            headerClass="h-left"
            bodyClass="text-left font-normal"
          ></PvColumn>
          <PvColumn
            field="period"
            :header="lang.columnPeriod"
            style="min-width: 6rem"
            headerClass="h-left"
            bodyClass="text-left font-normal"
          ></PvColumn>
          <PvColumn
            field="unitPrice"
            :header="lang.columnUnitPrice"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-right font-normal"
          >
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.unitPrice) }}
            </template>
          </PvColumn>
          <PvColumn
            field="quantity"
            :header="lang.quantity"
            style="min-width: 6rem"
            headerClass="h-left"
            bodyClass="text-right font-normal"
          ></PvColumn>
          <PvColumn
            field="ratePenalty"
            :header="lang.columnRatePenalty"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-right font-normal"
          ></PvColumn>
          <PvColumn
            field="penaltyDay"
            :header="lang.columnPenaltyDay"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-right font-normal"
          ></PvColumn>
          <PvColumn
            field="totalAmount"
            :header="lang.columnAmount"
            style="min-width: 6rem"
            headerClass="h-right"
            bodyClass="text-right font-normal"
          >
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.totalAmount) }}
            </template>
          </PvColumn>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
export default {
  props: ["id", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const {
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getCurrentPeriod,
    } = useFormat();
    const store = useStore();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.calculateInvoice.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.calculateInvoice.listing,
        };
      }
    });

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getCurrentPeriod,
      isHideBack,
    };
  },
  data() {
    return {
      isLoading: false,
      code: "",
      date: null,
      dueDate: null,
      selectedUnit: null,
      isSharedUnitReady: false,
      unitAddressNo: "",
      customerName: "",
      netAmount: 0,
      
      showValidateDialog: false,
      titleConfirmDialog: "",
      wordingConfirmDialog: "",
      dataButtonDialog: [
        {
          id: "close",
          caption: this.lang.close,
          class: "w-full p-button-outlined p-button-secondary",
          action: "close",
        },
      ],
      
    };
  },
  async mounted() {
    await this.initiateData();
    await this.getInvoiceById();

    // console.log(this.selectedInvoice);
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      let printed = this.$route.query.printed;
      this.$router.push({ name: "ar-invoice-wait-for-approve", query: {printed: printed} });
    },
    closeValidateDialog() {
      this.showValidateDialog = false;
    },
    async getInvoiceById() {
      this.setLoading(true);
      try {
        let id = this.id;
        await this.$store.dispatch("arCalculateInvoice/getInvoiceById", id);
      } catch (error) {
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    unitDataReady() {
      this.isSharedUnitReady = true;
      this.checkShouldSetData();
    },
    async checkShouldSetData() {
      if (this.selectedInvoice) {
        await this.setData();
      }

      if (this.selectedInvoice && this.isSharedUnitReady) {
        await this.setDataUnit();
      }
    },
    async initiateData() {
      this.code = "";
      this.date = new Date();
      this.dueDate = new Date();
      this.unitAddressNo = "";
      this.customerName = "";
      this.netAmount = 0;
      this.selectedUnit = null;

      this.$store.dispatch("arCalculateInvoice/resetSelectedInvoice");
    },
    async setData() {
    //   console.log("setData", this.selectedInvoice);
      this.setLoading(true);

      this.code = this.selectedInvoice.code;
      this.date = new Date(this.selectedInvoice.date);
      this.dueDate = new Date(this.selectedInvoice.dueDate);
      this.unitAddressNo = this.selectedInvoice.unitAddressNo;
      this.customerName = this.selectedInvoice.customerName;
      this.netAmount = this.selectedInvoice.netAmount;

      this.setLoading(false);
    },
    async setDataUnit() {
      this.selectedUnit = this.sharedUnits.find(
        (unit) => unit.id === this.selectedInvoice.unitId
      );
    },
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    sharedUnits() {
      return this.$store.getters["shared/unitUnits"];
    },
    selectedInvoice() {
      return this.$store.getters["arCalculateInvoice/selectedInvoice"];
    },
    selectedInvoiceDetail() {
      let result = [];
      if (this.selectedInvoice) {
        result = this.selectedInvoice.invoiceDetails;
        return result;
      }
      return result;
    },
  },
  watch: {
    selectedInvoice() {
      this.checkShouldSetData();
    },
  },
};
</script>
