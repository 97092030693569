<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '640px': '80vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <span class="font-lg">{{ lang.detailTitle }}</span>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <section class="flex flex-wrap" :class="textFormClass">
        <!-- ข้อมูลทั่วไป -->
        <div class="flex flex-wrap lg:col-12 md:col-12 col-12"> 
            <div class="lg:col-12 md:col-12 col-12 p-0">
              <span class="font-lg">{{ lang.generalInformation }}</span>
            </div>
            <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('code')}">{{ lang.code }}
              </label>
              <div class="mt-2">
                <label for="code" class="font-md" :class="{ 'p-text-error': checkError('code')}">{{ code }}</label>
              </div>
            </div>
            <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('addressNo')}">{{ lang.addressNo }}
              </label>
              <div class="mt-2">
                <label for="addressNo" class="font-md" :class="{ 'p-text-error': checkError('addressNo')}">{{ addressNo }}</label>
              </div>
            </div>
            <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('building')}">{{ lang.building }}
              </label>
              <div class="mt-2">
                <label for="building" class="font-md" :class="{ 'p-text-error': checkError('building')}">{{ building }}</label>
              </div>
            </div>
            <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('floor')}">{{ lang.floor }}
              </label>
              <div class="mt-2">
                <label for="floor" class="font-md" :class="{ 'p-text-error': checkError('floor')}">{{ floor }}</label>
              </div>
            </div>
            <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('name')}">{{ lang.roomType }}
              </label>
              <div class="mt-2">
                <label for="roomType" class="font-md" :class="{ 'p-text-error': checkError('name')}">{{ roomType }}</label>
              </div>
            </div>
            <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('room')}">{{ lang.room }}
              </label>
              <div class="mt-2">
                <label for="room" class="font-md" :class="{ 'p-text-error': checkError('room')}">{{ room }}</label>
              </div>
            </div>
            <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('contract')}">{{ lang.contractNo }}
              </label>
              <div class="mt-2">
                <label for="contractNo" class="font-md" :class="{ 'p-text-error': checkError('contract')}">{{ contractNo }}</label>
              </div>
            </div>
            <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('qrPayment')}">{{ lang.QRPayment }}
              </label>
              <div class="mt-2">
                <label for="QRPayment" class="font-md" :class="{ 'p-text-error': checkError('qrPayment')}">{{ qrPayment }}</label>
              </div>
            </div>
            <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('ownerRatio')}">{{ lang.ratio }}
              </label>
              <div class="mt-2">
                <label for="ratio" class="font-md" :class="{ 'p-text-error': checkError('ownerRatio')}">{{ ownerRatio }}</label>
              </div>
            </div>
            <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('area')}">{{ lang.area }}
              </label>
              <div class="mt-2">
                <label for="area" class="font-md" :class="{ 'p-text-error': checkError('area')}">{{ area }}</label>
              </div>
            </div>
            <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('cooling')}">{{ lang.cooling }}
              </label>
              <div class="mt-2">
                <label for="cooling" class="font-md" :class="{ 'p-text-error': checkError('cooling')}">{{ cooling }}</label>
              </div>
            </div>
            <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('activeStatus')}">{{ lang.activeStatus }}
              </label>
              <div class="mt-2">
                <label for="activeStatus" class="font-md" :class="{ 'p-text-error': checkError('activeStatus')}">{{ activeStatus }}</label>
              </div>
            </div>
        </div>
        <!-- ข้อมูลทั่วไป -->

        <!-- ข้อมูลสมาชิก -->
        <div class="flex flex-wrap lg:col-12 md:col-12 col-12 pt-4"> 
            <div class="lg:col-12 md:col-12 col-12 p-0">
              <span class="font-lg">{{ lang.memberInformation }}</span>
            </div>
            <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('ownerCode')}">{{ `${lang.customerCode} (${lang.owner1})` }}
              </label>
              <div class="mt-2">
                <label for="owner1" class="font-md" :class="{ 'p-text-error': checkError('ownerCode')}">{{ ownerCode }}</label>
              </div>
            </div>
            <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('livingType')}">{{ lang.living }}
              </label>
              <div class="mt-2">
                <label for="living" class="font-md" :class="{ 'p-text-error': checkError('livingType')}">{{ livingType }}</label>
              </div>
            </div>
            <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('appointmentDate')}">{{ lang.appointmentDate }}
              </label>
              <div class="mt-2">
                <label for="appointmentDate" class="font-md" :class="{ 'p-text-error': checkError('appointmentDate')}">{{ appointmentDate }}</label>
              </div>
            </div>
            <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('transferDate')}">{{ lang.transferDate }}
              </label>
              <div class="mt-2">
                <label for="transferDate" class="font-md" :class="{ 'p-text-error': checkError('transferDate')}">{{ transferDate }}</label>
              </div>
            </div>
            <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
              <label class="p-text-secondary" :class="{ 'p-text-error': checkError('expirdInsuranceDate')}">{{ lang.warrantyEndDate }}
              </label>
              <div class="mt-2">
                <label for="expirdInsuranceDate" class="font-md" :class="{ 'p-text-error': checkError('expirdInsuranceDate')}">{{ expirdInsuranceDate }}</label>
              </div>
            </div>
        </div>
        <!-- ข้อมูลสมาชิก -->
    </section>

    <template #footer>
        <PvButton
          :label="lang.close"
          class="p-button-outlined p-button-secondary w-3"
          @click="closeDialog"
        />
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
export default {
  emits: ["close"],
  props: {
    selectedUnit: {
      type: Object,
    },
  },
  setup(props, context) {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const { formatDate, formatDateToLocaleDateString } = useFormat();
    const store = useStore();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.unit.edit,
          ...lang.th.master.unit.import,
          ...lang.th.validateImport,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.unit.edit,
          ...lang.en.master.unit.import,
          ...lang.en.validateImport,
        };
      }
    });

    // ข้อมูลทั่วไป
    let code = ref("");
    let addressNo = ref("");
    let building = ref("");
    let floor = ref("");
    let roomType = ref("");
    let room = ref("");
    let contractNo = ref("");
    let qrPayment = ref("");
    let ownerRatio = ref("");
    let area = ref("");
    let cooling = ref("");
    let activeStatus = ref("");

    // ข้อมูลสมาชิก
    let ownerCode = ref("");
    let livingType = ref("");
    let appointmentDate = ref("");
    let transferDate = ref("");
    let expirdInsuranceDate = ref("");


    onMounted(() => {
      initiateData();
      if (props.selectedUnit) {
        setData();
      }
    });

    const closeDialog = () => {
      context.emit("close");
    };

    const initiateData = () => {
      code.value = "";
      addressNo.value = "";
      building.value = "";
      floor.value = "";
      roomType.value = "";
      room.value = "";
      contractNo.value = "";
      qrPayment.value = "";
      ownerRatio.value = "";
      area.value = "";
      cooling.value = "";
      activeStatus.value = "";
      ownerCode.value = "";
      livingType.value = "";
      appointmentDate.value = "";
      transferDate.value = "";
      expirdInsuranceDate.value = "";
    };

    const checkError = (field) => {
      let error = props.selectedUnit.error.find((error) => error.field == field);
      if (error) {
        return true;
      }
      return false;
    };

    const setData = () => {
      let appointmentDateValue = props.selectedUnit.appointmentDate;
      if (appointmentDateValue && appointmentDateValue != "" && !checkError("appointmentDate")) {
        appointmentDateValue = formatDateToLocaleDateString(new Date(props.selectedUnit.appointmentDate));
      }
      let transferDateValue = props.selectedUnit.transferDate;
      if (transferDateValue && transferDateValue != "" && !checkError("transferDate")) {
        transferDateValue = formatDateToLocaleDateString(new Date(props.selectedUnit.transferDate));
      }
      let expirdInsuranceDateValue = props.selectedUnit.expirdInsuranceDate;
      if (expirdInsuranceDateValue && expirdInsuranceDateValue != "" && !checkError("expirdInsuranceDate")) {
        expirdInsuranceDateValue = formatDateToLocaleDateString(new Date(props.selectedUnit.expirdInsuranceDate));
      }
      
      code.value = props.selectedUnit.code;
      addressNo.value = props.selectedUnit.addressNo;
      building.value = props.selectedUnit.building;
      floor.value = props.selectedUnit.floor;
      roomType.value = props.selectedUnit.name;
      room.value = props.selectedUnit.room;
      contractNo.value = props.selectedUnit.contract;
      qrPayment.value = props.selectedUnit.qrPayment;
      ownerRatio.value = props.selectedUnit.ownerRatio;
      area.value = props.selectedUnit.area;
      cooling.value = props.selectedUnit.cooling;
      activeStatus.value = props.selectedUnit.activeStatus;
      ownerCode.value = props.selectedUnit.ownerCode;
      livingType.value = props.selectedUnit.livingType;
      appointmentDate.value = appointmentDateValue;
      transferDate.value = transferDateValue;
      expirdInsuranceDate.value = expirdInsuranceDateValue;
    };

    return {
      lang: mergeLang,
      textFormClass,
      formatDate,

      // data
      code,
      addressNo,
      building,
      floor,
      roomType,
      room,
      contractNo,
      qrPayment,
      ownerRatio,
      area,
      cooling,
      activeStatus,
      ownerCode,
      livingType,
      appointmentDate,
      transferDate,
      expirdInsuranceDate,

      //methods
      closeDialog,
      checkError,
    };
  },
};
</script>
