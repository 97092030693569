<template>
<!-- {{this.dataMobileTable}} -->
<div v-for="(items,indexs) in dataMobileTable" v-bind:key="items.data">
    <div v-for="(item,index) in items.data" v-bind:key="item.name" class="item">
        <div class="flex justify-content-between align-items-center ">
            <div :class="{'name': index <= 0,'value': index > 0}"
            >{{ item.name }}</div>
            <div class="value">{{ item.value }}</div>
        </div>    
    </div>
    <PvDivider v-if="indexs < dataMobileTable.length-1" class="mt-2 mb-2"/>
</div>

</template>

<script>
export default {
  props: {
    dataMobileTable: {
        type: Array,
        default: () => []
    },
  },
  data() {
    return {
        numberRow: 0,
    }
  },

};
</script>

<style scoped>
 .item {
    padding: 5px 0px;
 }
 .value {
    color:#181819
 }
 .value {
    color:#6E7073
 }
</style>





<!-- 
    
    ตัวอย่างใช้งานและข้อมูลที่ส่ง
    <base-mobile-table
      :dataMobileTable="mobileTable"
      >
    </base-mobile-table>

    mobileTable: [
                    {
                        data: [
                            {
                            name: "เดอะ ลีฟวิ่ง โอเอส",
                            value: "012-23898479-56-8",
                            },
                            {
                            name: "ธนาคารกสิกรไทย",
                            value: "พระราม 9",
                            },
                        ],
                    },
                    {
                        data: [
                            {
                            name: "รอบการฝาก",
                            value: "30 วัน",
                            },
                        ],
                    },
                    {
                        data: [
                            {
                            name: "ประเภทบัญชี",
                            value: "กระแสรายวัน",
                            },
                        ],
                    },
                ],

-->



