export default {
	title: "รายการใบนำฝาก",
	status: "สถานะ",
	date: "วันที่ใบนำฝาก",
	customerSearch: "รหัสหรือชื่อลูกค้า",
	bookBankAccountNumber: "เลขที่บัญชี",
	bookBankName: "ชื่อบัญชี",
	paymentMethodCode: "ประเภทใบนำฝาก",
	createBank: "สร้างใบนำฝาก",

	codeBank: "เลขที่เอกสาร",
	columnPayDate: "วันที่ใบนำฝาก",
	columnToBankDate: "วันที่เข้าธนาคาร",
	columnCode: "เลขที่เอกสาร",
	columnCustomerCode: "รหัสสมาชิก",
	columnCustomerName: "ชื่อลูกค้า",
	columnPrice: "ราคา",
	columnPayCode: "รหัส",
	columnPayName: "ประเภทใบนำฝาก",
	columnIsCancel: "ยกเลิก",
	columnStatus: "สถานะ",
	unPaidOnly: "เฉพาะที่ยังไม่ได้ชำระ",
	unPaidOptions: [
		{ name: "ใช่", code: "A1" },
		{ name: "ไม่ใช่", code: "A2" },
	],
	importDocument: "นำเข้าเอกสาร",
	confirmDocument: "ยืนยันผูกเอกสาร",
	removeDocument: "นำเอกสารออกทั้งหมด",
	btnPrintLot: "พิมพ์เช็คเป็นชุด",
	bookBank: "สมุดบัญชีเงินฝาก",
	CodeStart: "เลขที่เริ่มต้น",
	CodeEnd: "ถึงเลขที่",
	columnAccountCode: "เลขที่บัญชี",
	columnBranch: "สาขา",
	columnBank: "ธนาคาร",
	validatebbookBankError: "ระบุ สมุดบัญชีเงินฝาก",
	validateDetail: "ไม่พบข้อมูลพิมพ์",
	cancelStatus: "พิมพ์เอกสารรวมสถานะยกเลิก",
	dropdownSearchByReceiptCode: "เลขที่ใบเสร็จ",
};
