<template>
	<div class="flex justify-content-between align-items-center" style="padding-top: 20px">
		<h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
	</div>
	<transition name="fade" mode="out-in">
		<div class="surface-card shadow-2 p-3 mt-3 border-round">
			<PvDataTable
				showGridlines
				:value="filteredPurchaseInvoice"
				editMode="cell"
				dataKey="receiptId"
				responsiveLayout="scroll"
				:scrollHeight="halfViewportHeight + 'px'"
				class="p-datatable-sm my-5"
				:class="textTableClass"
				:loading="isLoading"
				:stripedRows="true"
				v-model:selection="selectedInvoiceDetail"
				selectionMode="single"
				@row-dblclick="importPurchaseInvoice()"
				:scrollable="true"
			>
				<template #empty>
					<div class="w-full p-3" Style="text-align: center;">
					<label>{{ lang.tableEmpty }}</label>
					</div>
				</template>
				<template #header>
					<span class="p-input-icon-left">
						<i class="pi pi-search" />
						<PvInputText v-model="searchText" :placeholder="lang.keywordSearch" autofocus />
					</span>
				</template>
				<PvColumn selectionMode="single" headerStyle="width: 3rem" style="min-width: 2rem; max-width: 3rem"></PvColumn>
				<PvColumn
					field="moduleCode"
					:header="lang.columnInvoiceModuleCode"
					style="min-width: 2rem"
					headerClass="h-center"
					bodyClass="text-center"
				>
				</PvColumn>
				<PvColumn
					field="documentBookCode"
					:header="lang.columnInvoiceDocumentBookCode"
					style="min-width: 2rem"
					headerClass="h-center"
					bodyClass="text-center"
				>
				</PvColumn>
				<PvColumn
					field="receiptDetailCode"
					:header="lang.columnInvoiceDetailCode"
					style="min-width: 12rem"
					headerClass="h-center"
				>
				</PvColumn>
				<PvColumn
					field="referenceDocument"
					:header="lang.columnInvoiceReferenceDocument"
					style="min-width: 12rem"
					headerClass="h-center"
				>
				</PvColumn>
				<PvColumn
					field="receiptDetailDate"
					:header="lang.columnInvoiceDetailDate"
					style="min-width: 3rem"
					headerClass="h-center"
					bodyClass="text-center"
				>
					<template #body="slotProps">
						{{ formatDate(slotProps.data.receiptDetailDate) }}
					</template>
				</PvColumn>
				<PvColumn
					field="receiptDetailDueDate"
					:header="lang.columnInvoiceDueDate"
					style="min-width: 3rem"
					headerClass="h-center"
					bodyClass="text-center"
				>
					<template #body="slotProps">
						{{ formatDate(slotProps.data.receiptDetailDueDate) }}
					</template>
				</PvColumn>
				<PvColumn
					field="netAmount"
					:header="lang.columnInvoiceNetAmount"
					style="min-width: 2rem"
					headerClass="h-center"
					bodyClass="text-right"
				>
					<template #body="slotProps">
						{{ formatCurrency(slotProps.data.netAmount) }}
					</template>
				</PvColumn>
				<PvColumn
					field="actualPayAmount"
					:header="lang.columnInvoicePayAmount"
					style="min-width: 2rem"
					headerClass="h-center"
					bodyClass="text-right"
				>
					<template #body="slotProps">
						{{ formatCurrency(slotProps.data.actualPayAmount) }}
					</template>
				</PvColumn>
				<PvColumn
					field="differenceAmount"
					:header="lang.columnInvoiceDifferenceAmount"
					style="min-width: 2rem"
					headerClass="h-center"
					bodyClass="text-right"
				>
					<template #body="slotProps">
						{{ formatCurrency(slotProps.data.differenceAmount) }}
					</template>
				</PvColumn>
			</PvDataTable>
		</div>
	</transition>
	<div class="pt-5 pb-1 flex justify-content-end">
		<PvButton
			:label="lang.close"
			icon="pi pi-times"
			class="p-button-text mx-2"
			@click="closeDialog()"
		/>
		<PvButton
			:label="lang.ok"
			icon="pi pi-check"
			class="mx-2"
			autofocus
			@click="importPurchaseInvoice()"
		/>
	</div>
</template>
<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetFormPrint from "../../hooks/getFormPrint.js";
export default {
	props: ["receiptSupplierId", "invouceCode", "moduleCode", "vatRate"],
	emits: ["import", "close"],
	setup() {
		const { lang } = useLang();
		const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
		const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
		const { getFormPrints, searchTextPrint, isSharedFormPrintReady, getPreviewBlob } =
			useGetFormPrint();
		const store = useStore();
		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return { ...lang.th.base, ...lang.th.ap.purchaseReceipt.edit };
			} else {
				return { ...lang.en.base, ...lang.en.ap.purchaseReceipt.edit };
			}
		});

		const isHideBack = inject("isHideBack");

		return {
			lang: mergeLang,

			textHeaderClass,
			textFormClass,
			textTableClass,

			formatCurrency,
			formatDate,
			formatDateToISOString,

			getFormPrints,
			searchTextPrint,
			isSharedFormPrintReady,
			getPreviewBlob,

			isHideBack,
		};
	},
	data() {
		return {
			isLoading: false,
			selectedInvoiceDetail: [],

			selectedPage: 1,
			rowsPerPage: 100,
			vatDocumentBookRate: 7.0,
			searchText: "",
			bookModalSelected: [],
		};
	},
	async created() {
		this.vatDocumentBookRate = this.vatRate;
		await this.getPurchaseInvoice();
	},
	methods: {
		setLoading(bool) {
			this.$store.dispatch("setLoading", { value: bool });
		},
		async getPurchaseInvoice() {
			// console.log("getPurchaseInvoice");
			this.isLoading = true;
			try {
				let where = {
					projectId: this.selectedProject.id,
					supplierId: this.receiptSupplierId,
					vatRate: this.vatDocumentBookRate,
					receiptDetailCode: this.invouceCode,
					moduleCode: this.moduleCode,
				};

				const payload = {
					page: this.selectedPage,
					limit: this.rowsPerPage,
					where,
				};

				// console.log("getPurchaseInvoice", payload);
				await this.$store.dispatch("apPurchaseReceipt/getPurchaseInvoiceFroImport", payload);

				this.isLoading = false;
			} catch (error) {
				this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				this.isLoading = false;
			}
		},
		importPurchaseInvoice() {
			// console.log("importPurchaseInvoice", this.selectedInvoiceDetail);
			if (this.selectedInvoiceDetail) {
				this.$emit("import", this.selectedInvoiceDetail);
			} else {
				this.$swal({
					icon: "warning",
					title: this.lang.validateReceiptDetails,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				}).then((result) => {
					if (result.isConfirmed) {
						this.receiptSupplierError = true;
					}
				});
			}
		},
		closeDialog() {
			this.$emit("close");
		},
	},
	computed: {
		title() {
			return this.lang.titlePurchaseInvoice;
		},
		parameterButtonText() {
			return this.isParameterVisible ? this.lang.hideParameters : this.lang.showParameters;
		},
		halfViewportHeight() {
			return this.$store.getters.halfViewportHeight;
		},
		selectedProject() {
			return this.$store.getters.selectedProject;
		},
		totalPage() {
			return this.$store.getters["apPurchaseReceipt/totalPageInvoice"];
		},
		totalRecords() {
			return this.totalPage * this.rowsPerPage;
		},

		invoiceDetails() {
			return this.$store.getters["apPurchaseReceipt/purchaseInvoices"];
		},
		filteredPurchaseInvoice() {
			if (!this.searchText) {
				//ช่องว่าง
				const result = [...this.invoiceDetails];
				return result;
			} else {
				return this.invoiceDetails.filter((invoiceDetail) => {
					return invoiceDetail.receiptDetailCode
						.toLowerCase()
						.includes(this.searchText.toLowerCase());
				});
			}
		},
	},
	watch: {
		filteredPurchaseInvoice() {
			this.selectedInvoiceDetail = this.filteredPurchaseInvoice[0];
		},
	},
};
</script>
<style scoped></style>
