import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default function useGetNationality() {
  const store = useStore();
  let searchText = ref(null);

  const nationalities = computed(() => {
    return store.getters["shared/nationalityNationalities"];
  });

  const nationalityIsLastPage = computed(() => {
    return store.getters["shared/nationalityIsLastPage"];
  });

  const filteredNationalities = computed(() => {
    if (!searchText.value) {
      //ช่องว่าง
      const result = [...nationalities.value];
      return result;
    } else {
      return nationalities.value.filter((nationality) => {
        return nationality.countryAndNationality
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const searchNationality = function (event) {
    // console.log(event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchText.value = "";
      searchText.value = null;
    } else {
      searchText.value = event.query;
    }
  };

  let isSharedNationalityReady = ref(false);

  const getAllNationalities = async function () {
    if (!nationalityIsLastPage.value) {
      await store.dispatch("shared/getAllNationalities");
    }
    isSharedNationalityReady.value = true;
  };

  onMounted(async () => {
    await getAllNationalities();
  });

  return {
    searchNationality,
    filteredNationalities,
    searchText,
    isSharedNationalityReady,
    getAllNationalities,
  }
}
