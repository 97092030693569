import { useStore } from "vuex";
import { ref, computed } from "vue";
export default function useGetCustomer() {
  const store = useStore();
  let searchText = ref(null);

  const customers = computed(() => {
    return store.getters["shared/customerCustomers"];
  });

  const customerIsLastPage = computed(() => {
    return store.getters["shared/customerIsLastPage"];
  });

  const filteredCustomers = computed(() => {
    if (!searchText.value) {
      //ช่องว่าง
      // console.log("filteredCustomers", customers.value);
      if (customers.value) {
        const result = [...customers.value];
        return result;
      } else {
        return null;
      }
    } else {
      return customers.value.filter((customer) => {
        return customer.codeAndName
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const searchCustomer = function (event) {
    // console.log(event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchText.value = "";
      searchText.value = null;
    } else {
      searchText.value = event.query;
    }
  };

  let isSharedCustomerReady = ref(false);

  const getAllCustomers = async function () {
    if (!customerIsLastPage.value) {
      await store.dispatch("shared/getAllCustomers");
    }
    isSharedCustomerReady.value = true;
  };

  const getCustomerByCode = function (code) {
    return customers.value.find((customer) => {
      return customer.code.toLowerCase() === code.trim().toLowerCase();
    });
  };

  const refreshCustomers = async function () {
    isSharedCustomerReady.value = false;
    await store.dispatch("shared/resetCustomer");
    await getAllCustomers();
  };

  // onMounted(async () => {
  //   await getAllCustomers();
  // });


  return {
    searchCustomer,
    filteredCustomers,
    searchText,
    getCustomerByCode,
    isSharedCustomerReady,
    refreshCustomers,
    getAllCustomers
  };
}
