export default {
	title: "ทะเบียนทรัพย์สิน",
    assetCodeStart: "รหัสเริ่มต้น",
    assetCodeEnd: "รหัสสิ้นสุด",
    
    columnAssetCode: "รหัส",
    columnAssetName: "ชื่อสินทรัพย์",
    columnAssetAccountCode: "รหัสบัญชี",
    columnBeginDate: "วันที่เริ่ม",
    columnBuyPrice: "มูลค่าที่ซื้อ/ได้มา",
    columnDeprePrice: "มูลค่าที่นำไปคำนวณ",
    importAsset: "นำเข้าทรัพย์สิน",
};
