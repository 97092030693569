export default {
  setBuilding(state, payload) {
    let buildings = [...state.building.buildings];
    for (const building of payload.buildings) {
      if(buildings.findIndex(c => c.id === building.id) < 0){
        buildings.push(building);
      }
    }
    state.building.buildings = buildings;
    state.building.currentPage = payload.currentPage;
    state.building.totalPage = payload.totalPage;
  },
  resetBuilding(state) {
    const building = {
      buildings: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.building = building;
  },
};
