export default {
	setProductAR(state, payload) {
		// state.project.length = 0;
		let products = [...state.products];
		for (const product of payload.products) {
			if (products.findIndex((c) => c.id === product.id) < 0) {
				products.push(product);
			}
		}
		state.products = products;
		state.currentPage = payload.currentPage;
		state.totalPage = payload.totalPage;
	},
	resetProductAR(state) {
		(state.products = []), (state.totalPage = 0), (state.currentPage = 0);
	},
	setPenaltyRateStep(state, payload) {
		state.penaltyRateStep = payload.data;
		state.currentPage = payload.currentPage;
		state.totalPage = payload.totalPage;
	},
	setArProducts(state, payload) {
		state.arProducts = payload.data;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
	resetArProduct(state) {
		state.selectedArProduct = null;
	},
	setSelectedArProduct(state, payload) {
		state.selectedArProduct = payload;
	},
	setUnitProduct(state, payload) {
		state.unitProducts = payload.data;
		state.unitProductTotalPage = payload.page.pageCount;
		state.unitProductCurrentPage = payload.page.currentPage;
	},
	setAllUnitProduct(state, payload) {
		state.allUnitProducts = payload.data;
		state.unitProductTotalPage = payload.page.pageCount;
		state.unitProductCurrentPage = payload.page.currentPage;
	},
	resetAllUnitProduct(state) {
		state.allUnitProducts = [];
		state.unitProductTotalPage = 0;
		state.unitProductCurrentPage = 0;
	},
};
