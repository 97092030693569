<template>
  <base-toolbar
    :title="lang.title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoCreate="gotoEdit('create')"
    :hideBackButton="isHideBack"
  >
  </base-toolbar>

  <base-container>
    <import-deposit-dialog
      v-model:visible="showUploadDepositDialog"
      @close="showUploadDepositDialog = false"
    ></import-deposit-dialog>

    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="lang.notificationDialogHeader"
      :wordingConfirm="validateDialogWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
    ></base-dialog>

    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchTextPrint"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>

    <base-reference-document-dialog
      v-model:visible="showReferenceDocument"
      @close="showReferenceDocument = false"
      :dataTable="referenceDocumentData"
      :columns="columnsReferenceDocument"
    ></base-reference-document-dialog>

    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.titleListing }}</label>
        <div class="flex">
          <PvButton
            class="p-button-outlined"
            @click="importDeposit"
            v-if="isShowImportDataButton"
          >
            <icon-download iconColor="primary" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{ lang.importDeposit }}</span>
          </PvButton>
        </div>
      </div>

      <PvDivider></PvDivider>

      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >
        <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            id="filterStatus"
            class="inputfield w-full mr-2"
            :options="filterStatus"
            optionLabel="name"
            optionValue="code"
            v-model="selectedFilterStatus"
            scrollHeight="300px"
            @change="changeFilterStatus"
          />
        </div>
        <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            id="filterSearch"
            class="inputfield w-full mr-2"
            :options="filterSearch"
            optionLabel="name"
            optionValue="code"
            v-model="selectedFilterSearch"
            scrollHeight="300px"
            @change="changeFilterSearch"
          />
        </div>
        <div
          class="flex lg:col-6 md:col-12 col-12 pl-0 pr-2 mr-2"
          v-if="isSearchDate"
        >
          <div class="col-6 pl-0 pr-2">
            <PvCalendar
              id="dateStart"
              v-model="dateStart"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
          <label for="dateEnd" class="pt-1 mt-3 mr-2">{{ "-" }}</label>
          <div class="col-6 pl-0 pr-2">
            <PvCalendar
              id="dateEnd"
              v-model="dateEnd"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
        </div>
        <div
          class="flex lg:col-6 md:col-12 col-12 pl-0 pr-0 mr-2"
          v-if="isSearchOther"
        >
          <div class="col-12 pl-0 pr-0">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchText"
                :placeholder="lang.searchTextPlaceholder"
                @keypress="checkInputSearch"
                autofocus
              />
            </span>
          </div>
        </div>
        <PvButton @click="getDeposits">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="deposits"
          v-model:selection="selectedDeposit"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(this.selectedDeposit.id)"
          scrollable
        >
          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
          <PvColumn
            field="code"
            :header="lang.documentCode"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <u>{{ slotProps.data.code }}</u>
            </template>
          </PvColumn>
          <PvColumn
            field="date"
            :header="lang.date"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.date) }}
            </template>
          </PvColumn>
          <PvColumn
            field="unitCode"
            :header="lang.unit"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="addressNo"
            :header="lang.address"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="customerName"
            :header="lang.customerName"
            style="min-width: 15rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="cancelStatus"
            :header="lang.cancel"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <base-tag
                v-if="slotProps.data.cancelStatus.trim() == 'C'"
                :Caption="lang.cancel"
                Class="error"
              />
            </template>
          </PvColumn>
          <PvColumn
            field="payAmount"
            :header="lang.payAmount"
            style="min-width: 10rem; height: 56px"
            headerClass="h-right"
            bodyClass="text-right"
            sortable
          >
            <template #body="slotProps">
              <span class="text-right w-full">{{
                formatCurrency(slotProps.data.payAmount)
              }}</span>
            </template>
          </PvColumn>
          <PvColumn
            field="paidAmount"
            :header="lang.paidAmount"
            style="min-width: 10rem; height: 56px"
            headerClass="h-right"
            bodyClass="text-right"
            sortable
          >
            <template #body="slotProps">
              <span class="text-right w-full">{{
                formatCurrency(slotProps.data.paidAmount)
              }}</span>
            </template>
          </PvColumn>
          <PvColumn
            field="balance"
            :header="lang.balance"
            style="min-width: 10rem; height: 56px"
            headerClass="h-right"
            bodyClass="text-right"
            sortable
          >
            <template #body="slotProps">
              <span class="text-right w-full">{{
                formatCurrency(slotProps.data.balance)
              }}</span>
            </template>
          </PvColumn>
          <PvColumn
            field="createDate"
            :header="lang.columnCreateDate"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.createDate) }}
            </template>
          </PvColumn>
          <PvColumn
            field="updateDate"
            :header="lang.columnUpdateDate"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <label v-if="slotProps.data.updateDate">
                {{ formatDate(slotProps.data.updateDate) }}
              </label>
            </template>
          </PvColumn>
          <PvColumn
            field="createBy"
            :header="lang.columnCreateBy"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="updateBy"
            :header="lang.columnUpdateBy"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            style="min-width: 12rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                @click="getPrintForm(slotProps.data)"
              >
                <icon-printer iconColor="secondary" width="24" height="24" />
              </PvButton>
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                @click="openReferenceDocument(slotProps.data.id)"
              >
                <icon-attachment-link
                  width="24"
                  height="24"
                ></icon-attachment-link>
              </PvButton>
            </template>
          </PvColumn>

          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              :first="showCurrentPage"
            >
              <template #start="slotProps">
                {{ lang.page }}:
                {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
              </template>
            </PvPaginator>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import ImportDepositDialog from "../../../components/AR/deposit/ImportDepositDialog.vue";
export default {
  components: {
    ImportDepositDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.deposit.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.deposit.listing,
        };
      }
    });

    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();
    const {
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
    } = useGetFormPrint();
    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,

      textTableClass,
      textFormClass,

      formatCurrency,
      formatDate,
      formatDateToISOString,
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
      isHideBack,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
    };
  },
  data() {
    return {
      filterStatus: [
        { name: this.lang.statusAll, code: "all" },
        { name: this.lang.statusWaitForDeduct, code: "balance" },
        { name: this.lang.statusDeducted, code: "empty" },
        { name: this.lang.statusCancel, code: "cancel" },
      ],
      selectedFilterStatus: "all",
      filterSearch: [
        { name: this.lang.dateRange, code: "date" },
        { name: this.lang.documentCode, code: "code" },
        { name: this.lang.unit, code: "unitCode" },
        { name: this.lang.address, code: "unitAddressNo" },
        { name: this.lang.customerName, code: "customerName" },
      ],
      selectedFilterSearch: "date",
      dateStart: null,
      dateEnd: null,
      searchText: "",
      storageName: "",
      isLoading: false,
      selectedDeposit: null,
      selectedPage: 1,
      rowsPerPage: 10,

      showValidateDialog: false,
      validateDialogWording: "",
      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ],

      showFormPrintModal: false,
      path: "",

      showReferenceDocument: false,
      referenceDocumentData: [],
      columnsReferenceDocument: [
        {
          field: "code",
          header: this.lang.receiptCode,
          type: "string",
          style: "width: 10%;height: 56px;",
          headerClass: "h-left font-sm",
          bodyClass: "text-left p-text-secondary",
        },
        {
          field: "date",
          header: this.lang.receiptDate,
          type: "date",
          style: "width: 10%",
          headerClass: "h-left font-sm",
          bodyClass: "text-left p-text-secondary",
        },
        // {
        //   field: "totalAmount",
        //   header: this.lang.payAmount,
        //   type: "currency",
        //   style: "width: 10%;",
        //   headerClass: "h-right font-sm",
        //   bodyClass: "text-right p-text-secondary",
        // },
        {
          field: "netAmount",
          header: this.lang.payAmount,
          type: "currency",
          style: "width: 10%;",
          headerClass: "h-right font-sm",
          bodyClass: "text-right p-text-secondary",
        },
        {
          field: "createDate",
          header: this.lang.columnCreateDate,
          type: "date",
          style: "width: 10%",
          headerClass: "h-left font-sm",
          bodyClass: "text-left p-text-secondary",
        },
        {
          field: "updateDate",
          header: this.lang.columnUpdateDate,
          type: "date",
          style: "width: 10%",
          headerClass: "h-left font-sm",
          bodyClass: "text-left p-text-secondary",
        },
        {
          field: "createBy",
          header: this.lang.columnCreateBy,
          type: "string",
          style: "width: 10%",
          headerClass: "h-left font-sm",
          bodyClass: "text-left p-text-secondary",
        },
        {
          field: "updateBy",
          header: this.lang.columnUpdateBy,
          type: "string",
          style: "width: 10%",
          headerClass: "h-left font-sm",
          bodyClass: "text-left p-text-secondary",
        },
      ],
      showUploadDepositDialog: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.initiateData();
    await this.roleByMenuDataReady();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    async roleByMenuDataReady() {
      if (this.isSharedRoleByMenuReady) {
        await this.setLocalSearch();
        await this.getDeposits();
      }
    },
    async gotoEdit(id) {
      if (!this.isRoleSelect && id != "create") {
        this.validateDialogWording = this.lang.validateRoleSelect;
        this.showValidateDialog = true;
      } else if (!this.isRoleCreate && id == "create") {
        this.validateDialogWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
      } else {
        this.$router.push({name: "ar-deposit-edit", params: {id: id}});
      }
    },
    changeFilterSearch() {
      this.initiateData();
    },
    async changeFilterStatus() {
      await this.initiateData();
      this.selectedFilterSearch = "date";
      this.getDeposits();
    },
    async initiateData() {
      let date = new Date();
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.searchText = "";
      this.storageName = `erp_search_DepositList_${this.selectedProject.code}_${this.userCode}`;
      this.referenceDocumentData = [];
      this.showUploadDepositDialog = false;
    },
    async checkInputSearch(event) {
      if (this.selectedFilterSearch == "code") {
        const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        const keyPressed = event.key;
        if (event.key != "Enter" && !keysAllowed.includes(keyPressed)) {
          event.preventDefault();
        }
      }

      if (event.key == "Enter") {
        await this.getDeposits();
      }
    },
    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      if (localSearch) {
        let search = JSON.parse(localSearch);
        if (search.entry === "entry") {
          if (search.dateStart != null && search.dateStart != "") {
            this.selectedFilterSearch = "date";
            this.dateStart = new Date(search.dateStart);
          } else {
            this.dateStart = "";
          }

          if (search.dateEnd != null && search.dateEnd != "") {
            this.selectedFilterSearch = "date";
            this.dateEnd = new Date(search.dateEnd);
          } else {
            this.dateEnd = "";
          }

          if (search.code != null && search.code != "") {
            this.selectedFilterSearch = "code";
            this.searchText = search.code;
          } else if (search.unitCode != null && search.unitCode != "") {
            this.selectedFilterSearch = "unitCode";
            this.searchText = search.unitCode;
          } else if (
            search.unitAddressNo != null &&
            search.unitAddressNo != ""
          ) {
            this.selectedFilterSearch = "unitAddressNo";
            this.searchText = search.unitAddressNo;
          } else if (search.customerName != null && search.customerName != "") {
            this.selectedFilterSearch = "customerName";
            this.searchText = search.customerName;
          } else {
            this.searchText = "";
          }

          this.rowsPerPage = search.rowsPerPage;          
          this.selectedPage = search.selectedPage;

          search.entry = "";

          localStorage.setItem(this.storageName, JSON.stringify(search));
        }
      }
    },
    validateSearchButton() {
      if (
        this.selectedFilterSearch == "date" &&
        (!this.selectedDateStart || !this.selectedDateEnd)
      ) {
        return true;
      } else if (this.selectedFilterSearch != "date" && this.searchText == "") {
        return true;
      }

      return false;
    },
    async getDeposits() {
      if(this.validateSearchButton()) {
        this.validateDialogWording = this.lang.validateSearchText;
        this.showValidateDialog = true;
        return;
      }

      this.isLoading = true;
      try {
        await this.$store.dispatch(
          "arDeposit/getDeposits",
          this.payloadGetDeposits
        );

        let local = {
          ...this.payloadGetDeposits.where,
          rowsPerPage: this.rowsPerPage,
          selectedPage: this.selectedPage,
        };

        localStorage.setItem(this.storageName,JSON.stringify(local));
        // console.log(this.deposits);
      } catch (error) {
        this.validateDialogWording = error;
        this.showValidateDialog = true;
      }

      this.isLoading = false;
    },
    async onPageChange(event) {
      if(event.rows != this.rowsPerPage) {
        this.selectedPage = 1;
      } else {
        this.selectedPage = event.page + 1;        
      }
      this.rowsPerPage = event.rows;
      await this.getDeposits();
    },
    formPrintModalSelected(event) {
      this.path = event.path + ".pdf";
      this.printDeposit();
      this.showFormPrintModal = false;
    },
    async getPrintForm(data) {
      if (!this.isRolePrint) {
        this.validateDialogWording = this.lang.validatePrintPermission;
        this.showValidateDialog = true;
        return true;
      }
      this.selectedDeposit = data;
      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: "DEPOSIT",
      };
      const payload = { where };
      await this.getFormPrints(payload);
      //   console.log("filteredFormPrints", this.filteredFormPrints);
      if (this.filteredFormPrints.length == 1) {
        this.formPrintModalSelected(this.filteredFormPrints[0]);
      } else {
        this.showFormPrintModal = true;
      }
    },
    async printDeposit() {
      this.setLoading(true);
      try {
        let parameter = {
          Id: this.selectedDeposit.id,
        };

        let payload = {
          path: this.path,
          fileName: "Deposit",
          parameter: JSON.stringify(parameter),
        };

        await this.$store.dispatch("shared/printForm", payload);
      } catch (error) {
        this.validateDialogWording = error;
        this.showValidateDialog = true;
      }

      let blob = this.$store.getters["shared/blob"];
      this.getPreviewBlob(blob);
      this.setLoading(false);
    },
    async openReferenceDocument(id) {
      if (!this.isRoleSelect) {
        this.validateDialogWording = this.lang.validateRoleSelect;
        this.showValidateDialog = true;
        return true;
      }
      this.setLoading(true);
      try {
        const payload = {
          page: this.selectedPage,
          id: id,
        };

        await this.$store.dispatch("arDeposit/getReferenceDocument", payload);

        // console.log("openReferenceDocument", this.dataReferenceDocument);
      } catch (error) {
        this.validateDialogWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.referenceDocumentData = this.dataReferenceDocument;
      this.setLoading(false);
      this.showReferenceDocument = true;
    },
    importDeposit() {
      if (!this.isRoleCreate) {
        this.validateDialogWording = this.lang.validateRoleImport;
        this.showValidateDialog = true;
        return true;
      }
      this.showUploadDepositDialog = true;
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("PayIn");
    },
    isRoleSelect() {
      if (this.roleByMenu && this.roleByMenu.select == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRoleCreate() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRolePrint() {
      if (this.roleByMenu && this.roleByMenu.print == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isSearchDate() {
      if (this.selectedFilterSearch == "date") {
        return true;
      } else {
        return false;
      }
    },
    isSearchOther() {
      if (this.selectedFilterSearch != "date") {
        return true;
      } else {
        return false;
      }
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    payloadGetDeposits() {
      let where = {
        projectId: this.selectedProject.id,
        dateStart:
          this.selectedFilterSearch == "date" ? this.selectedDateStart : null,
        dateEnd:
          this.selectedFilterSearch == "date" ? this.selectedDateEnd : null,
        code: this.selectedFilterSearch == "code" ? this.searchText : null,
        unitCode:
          this.selectedFilterSearch == "unitCode" ? this.searchText : null,
        addressNo:
          this.selectedFilterSearch == "unitAddressNo" ? this.searchText : null,
        customerName:
          this.selectedFilterSearch == "customerName" ? this.searchText : null,
        filterStatus:
          this.selectedFilterStatus == "all" ? null : this.selectedFilterStatus,
      };

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };
      return payload;
    },
    deposits() {
      return this.$store.getters["arDeposit/deposits"];
    },
    totalPage() {
      return this.$store.getters["arDeposit/totalPage"];
    },
    currentPage() {
      return this.$store.getters["arDeposit/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    dataReferenceDocument() {
      return this.$store.getters["arDeposit/depositReferenceDocument"];
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "create",
          caption: this.lang.addDeposit,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
        },
      ];
      return dataButtons;
    },
    showCurrentPage() {
      return this.rowsPerPage * (this.selectedPage - 1);
    },
    isShowImportDataButton() {
      let userGroupId = process.env.VUE_APP_USER_GROUP_ID.split("|")
      return userGroupId.includes(this.roleByMenu.userGroupId);
    }
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.roleByMenuDataReady();
    },
  },
};
</script>
