export default {
	setMeters(state, payload) {
		state.meters = payload.data;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
	setSelectedMeter(state, payload) {
		state.selectedMeter = payload;
	},
	resetMeter(state) {
		state.selectedMeter = null;
	},
	resetMeterForAutoCreateByProject(state) {
		state.meterForAutoCreateByProject = [];
		state.totalPageForAutoCreate = 0;
		state.currentPageForAutoCreate = 0;
	},
	setProduct(state, payload) {
		state.product = payload;
	},
	setForAutoCreateByProject(state, payload) {
		let meterForAutoCreateByProjects = [...state.meterForAutoCreateByProject];
		for (const data of payload.data) {
			if (meterForAutoCreateByProjects.findIndex((c) => c.unitId + c.productId === data.unitId + data.productId) < 0) {
				meterForAutoCreateByProjects.push(data);
			}
		}
		state.meterForAutoCreateByProject = meterForAutoCreateByProjects;
		state.totalPageForAutoCreate = payload.page.pageCount;
		state.currentPageForAutoCreate = payload.page.currentPage;
	},
};
