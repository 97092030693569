export default {
	registerMobileSummary(state) {
		return state.registerMobileSummary;
	},
	registerMobileDetail(state) {
		return state.registerMobileDetail;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
};
