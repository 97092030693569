export default {
    receipts(state) {
      return state.receipts;
    },
    receiptSelected(state) {
      return state.receiptSelected;
    },   
    receiptToGl(state) {
      return state.receiptToGl;
    },   
    totalPage(state) {
      return state.totalPage;
    },
    totalListPage(state) {
      return state.totalListPage;
    },
    currentPage(state) {
      return state.currentPage;
    },
    rows(state) {
      return state.rows;
    },
    receiptNextPage(state) {
      return state.currentPage + 1;
    },
    receiptIsLastPage(_, getters) {
      return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
    }
  };
  