<template>
	<base-container>
		<base-bank-view :type="-1"></base-bank-view>
	</base-container>
</template>

<script>
import BaseBankView from "../../../components/bank/BaseBankView.vue";
export default {
	components: {
		BaseBankView,
	},
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
	transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
	transform: translateY(-20px);
	opacity: 0;
}
</style>
