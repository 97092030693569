export default {
    async getProjects(context, payload) {

        let url = process.env.VUE_APP_BACKEND_URL + "/Project";

        if(!payload.limit){
            url = url + '?limit=10';
        } else {
            url = url + '?limit='+payload.limit;
        }

        if(payload.page){
            url = url + '&page='+payload.page;
        }

        if(payload.where){
            url = url + '&where={"search":"'+ payload.where + '"}';
        }
        
        const token = context.rootGetters.token;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
          },
        });
    
        const responseData = await response.json();
    
        if (!response.ok) {
          // console.log(responseData);
          let message = null
          if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
            message = responseData.title;
            for (const key in responseData.errors) {
              message += ", " + key.replace("$.","")
            }
          } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
            message = responseData.message
          }
          const error = new Error(message || "Failed to get project");
          throw error;
        }
        // console.log(responseData);
    
        // const data = responseData.data;
        context.commit('setProject', responseData);
    },
    async getForeignOwnershipRatioMessage(context, payload) {
      let url = process.env.VUE_APP_BACKEND_URL + "/Project/GetForeignOwnershipRatioMessageById";
  
      if (payload) {
        url = url + "?projectId=" + payload.projectId;
      }
		  url = encodeURI(url);
  
      const token = context.rootGetters.token;
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        // console.log(responseData);
        const error = new Error(responseData.message || "Failed to get foreign ownership ratio message");
        throw error;
      }
      // console.log(responseData.data);
  
      context.commit("setForeignOwnershipRatioMessage", responseData.data);
    },
    async updateForeignOwnershipRatioMessage(context, payload){
      let url = process.env.VUE_APP_BACKEND_URL + "/Project/UpdateForeignOwnershipRatioMessage";
      const token = context.rootGetters.token;
  
      const response = await fetch(url, {
        method: 'put',
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
  
        body: JSON.stringify(payload),
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        throw responseData;
      }
      // console.log(responseData);
  
      context.commit("setForeignOwnershipRatioMessage", responseData.data);
    },
};