export default {
	async getAdvertisement(context, isFirst) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Advertisement";

		if (isFirst) {
			url = url + "?isFirst=" + isFirst;
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Advertisement");
			throw error;
		}
		// console.log("advertisement", responseData);
    if (responseData.data.losaError) {
			const error = new Error(responseData.data.losaError || "Failed to get Losa");
			throw error;
    }

		context.commit("setAdvertisement", responseData.data.result);
  },
  async getFirstAdvertisement(context, isFirst) {
		let url = process.env.VUE_APP_BACKEND_URL + "/UnanimousAdvertisement";

		if (isFirst) {
			url = url + "?isFirst=" + isFirst;
		}

		const response = await fetch(url, {
			method: "GET",
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Advertisement");
			throw error;
		}
		// console.log("advertisement", responseData);
    if (responseData.data.losaError) {
			const error = new Error(responseData.data.losaError || "Failed to get Losa");
			throw error;
    }

		context.commit("setFirstAdvertisement", responseData.data.result[0]);
	},
	async resetAdvertisement(context) {
		await context.commit("resetAdvertisement");
	},
};
