export default {
  setTransferSlips(state, payload){
      state.transferSlips = payload.data
      state.totalPage = payload.page.pageCount
      state.currentPage = payload.page.currentPage
  },
  setTransferSlip(state, payload){
      state.transferSlip = payload
  },
};
