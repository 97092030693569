<template>
	<PvDialog
		:breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
		:style="{ width: '50vw' }"
		:modal="true"
		:closable="false"
		:header="lang.accountingFrequently"
	>
		<PvDataTable
			showGridlines
			:value="filterAccountingFrequently"
			v-model:selection="selectedAccountingFrequently"
			selectionMode="single"
			dataKey="id"
			responsiveLayout="scroll"
			:scrollHeight="halfViewportHeight + 'px'"
			class="p-datatable-sm"
			:class="textTableClass"
			:loading="!isSharedAccountingFrequentlyReady"
			@row-select="onRowSelect"
		>
		<template #empty>
			<div class="w-full p-3" Style="text-align: center;">
			<label>{{ lang.tableEmpty }}</label>
			</div>
		</template>
			<template #header>
				<span class="p-input-icon-left">
					<i class="pi pi-search" />
					<PvInputText v-model="searchText" :placeholder="lang.keywordSearch" autofocus />
				</span>
			</template>
			<PvColumn selectionMode="single" bodyClass="text-center"></PvColumn>
			<PvColumn
				field="code"
				:header="lang.code"
				style="min-width: 3rem"
				headerClass="h-center"
				bodyClass="text-center"
			></PvColumn>
			<PvColumn
				field="name"
				:header="lang.name"
				style="min-width: 5rem"
				headerClass="h-center"
				bodyClass="text-left"
			></PvColumn>
			<PvColumn
				field="glbCode"
				:header="lang.type"
				style="min-width: 3rem"
				headerClass="h-center"
				bodyClass="text-center"
			></PvColumn>
		</PvDataTable>
		<div class="flex flex-wrap card-container align-items-center">
			<div class="lg:col-8 md:col-6 col-12 flex align-items-center justify-content-end">
				<label for="total" v-if="isDisabledTotal">{{ lang.total }}</label>
				<PvInputNumber
					type="text"
					mode="decimal"
					:minFractionDigits="2"
					:maxFractionDigits="2"
					placeholder=".00"
					class="flex p-3 border-round mr-1"
					v-model="total"
					v-if="isDisabledTotal"
				/>
			</div>
			<div class="lg:col-4 md:col-6 flex col-12 justify-content-end">
				<PvButton
					:label="lang.close"
					icon="pi pi-times"
					class="p-button-text"
					@click="closeDialog"
				/>
				<PvButton
					:label="lang.ok"
					icon="pi pi-check"
					autofocus
					@click="selectAccountingFrequently"
				/>
			</div>
		</div>
	</PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useGetAccountingFrequently from "../../hooks/getAccountingFrequently.js";
export default {
  props: {
    shouldGetData: {
      type: Boolean,
      default: true,
    },
    search: {
      type: String,
    },
	isActive: {
      type: Boolean,
	  default: false,
    },
  },
  emits: ["close", "accountingFrequently-selected"],
  setup(props) {
    const { lang } = useLang();
    const { textHeaderClass, textTableClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.accounting.edit,
          ...lang.th.gl.accounting.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.accounting.edit,
          ...lang.en.gl.accounting.listing,
        };
      }
    });

    const formatTwoDigit = new Intl.NumberFormat('en-US', { 
      minimumFractionDigits: 2, 
      maximumFractionDigits: 2, 
    });

    const {
      filteredAccountingFrequentlies,
      searchText,
      isSharedAccountingFrequentlyReady,
    } = useGetAccountingFrequently();

	const filterAccountingFrequently = computed(() => {
		if(props.isActive) {
			return filteredAccountingFrequentlies.value.filter((data) => data.status.toLowerCase() == "y");
		} else {
			return filteredAccountingFrequentlies.value;
		}
    });

    return {
      filteredAccountingFrequentlies,
      isSharedAccountingFrequentlyReady,
      textHeaderClass,
      textTableClass,
      lang: mergeLang,
      searchText,
      formatTwoDigit,
	  filterAccountingFrequently
    };
  },
  data() {
    return {
      total: 0,
      selectedAccountingFrequently: null,
      isDisabledTotal: true,
    };
  },
  methods: {
    validateSelectAccountingFrequently() {
      if(this.total < 0){
        this.closeDialog();

				this.$swal({
					icon: "warning",
					title: this.lang.validateTotalAmount2,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				return true;
			}

			if (!this.selectedAccountingFrequently) {
				this.$swal({
					icon: "warning",
					title: this.lang.validateDetailSelect,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				return true;
			}

			return false;
		},
		selectAccountingFrequently() {
			if (this.validateSelectAccountingFrequently()) {
				return true;
			}

			let sumdebit = 0;
			let sumcredit = 0;
			//loop เพื่อหา sum
			this.selectedAccountingFrequently.accountingFrequentlyDetail.forEach((detail) => {
				sumdebit += detail.debitAmount;
				sumcredit += detail.creditAmount;
			});

			//loop เพื่ออัพเดตค่า dr, cr
			let selectedUpdate = [];
			this.selectedAccountingFrequently.accountingFrequentlyDetail.forEach((detail) => {
				let details = {
					accountCode: detail.accountCode,
					accountId: detail.accountId,
					accountName: detail.accountName,
					creditAmount: 0,
					debitAmount: 0,
					description: detail.description,
					id: detail.id,
				};
          // console.log("detail", detail)
          if (this.selectedAccountingFrequently.type === "P") {
            details.debitAmount = parseFloat(this.formatTwoDigit.format((this.total * detail.debitAmount) / sumdebit).replaceAll(",", "")) ; 
            details.creditAmount = parseFloat(this.formatTwoDigit.format((this.total * detail.creditAmount) / sumcredit).replaceAll(",", "")) ;
            // detail.debitAmount = (this.total * detail.debitAmount) / this.sumdebit;
            // detail.creditAmount = (this.total * detail.creditAmount) / this.sumcredit;
          } else {
            if (detail.debitAmount > 0) {
              details.debitAmount = parseFloat(this.formatTwoDigit.format(detail.debitAmount).replaceAll(",", ""));
              // detail.debitAmount;
            }
            if (detail.creditAmount > 0) {
              details.creditAmount = parseFloat(this.formatTwoDigit.format(detail.creditAmount).replaceAll(",", ""));
              // detail.creditAmount;
            }
          }
          selectedUpdate.push(details);
        }
      );
      this.$emit("accountingFrequently-selected", {
        // ...this.selectedAccountingFrequently,
        datails: selectedUpdate,
        total: this.total,
        sumdebit: sumdebit,
        sumcredit: sumcredit,
		description: this.selectedAccountingFrequently != null ? this.selectedAccountingFrequently.description:"",
      });
      this.selectedAccountingFrequently = null;
      this.total = 0;
    },
    onRowSelect(event) {
      this.total = 0;
      //   console.log("onRowSelect:", event.data.type);
      if (event.data.type != "P") {
        this.isDisabledTotal = false;
      } else {
        this.isDisabledTotal = true;
      }
    },
    closeDialog() {
      this.$emit("close");
    },
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
  },
  watch: {
    search(curValue) {
      if (curValue) {
        this.searchText = curValue;
      }
    },
    // filteredAccountingFrequentlies(){
    //   this.selectedAccountingFrequently = this.filteredAccountingFrequentlies[0];
    // },
  },
};
</script>
