<template>
  <base-toolbar
    :title="lang.title"
    @goBack="goBack"
    :hideBackButton="isHideBack"
  ></base-toolbar>

  <base-container>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{ lang.menuTitle }}</label>

      <PvDivider></PvDivider>

      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >
        <!-- 1 -->
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-4 pt-4">
          <PvButton class="p-button-outlined text-left w-full" @click="gotoRequest">
            <icon-document-edit iconColor="primary"/>
            <span class="p-button-label font-normal m-2">{{
              lang.debtFreeRequest
            }}</span>
          </PvButton>
        </div>
        <!-- 2 -->
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-4 pt-4">
          <PvButton class="p-button-outlined text-left w-full" @click="gotoApprove">
            <icon-document-checkmark iconColor="primary"/>
            <span class="p-button-label font-normal m-2">{{
              lang.debtFreeApprove
            }}</span>
          </PvButton>
        </div>
        <!-- 3 -->
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-4 pt-4">
          <PvButton class="p-button-outlined text-left w-full" @click="gotoPrint">
            <icon-printer iconColor="primary"/>
            <span class="p-button-label font-normal m-2">{{
              lang.debtFreePrint
            }}</span>
          </PvButton>
        </div>
        <!-- 4 -->
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-4 pt-4">
          <PvButton class="p-button-outlined text-left w-full" @click="gotoFollowUp">
            <icon-hand iconColor="primary"/>
            <span class="p-button-label font-normal m-2">{{
              lang.debtFreeFollowUp
            }}</span>
          </PvButton>
        </div>
        <!-- 5 -->
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-4 pt-4">
          <PvButton class="p-button-outlined text-left w-full" @click="gotoReport">
            <icon-chart-rate iconColor="primary"/>
            <span class="p-button-label font-normal m-2">{{
              lang.debtFreeReport
            }}</span>
          </PvButton>
        </div>

      </section>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
export default {
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.debtFree.menu,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.debtFree.menu,
        };
      }
    });

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      isHideBack,
    };
  },
  methods: {
    goBack() {
      this.$router.push({name: "home"})
    },
    gotoRequest() {
      this.$router.push({name: "debt-free-request"});
    },
    gotoApprove() {
      this.$router.push({name: "debt-free-approve"});
    },
    gotoPrint() {
      this.$router.push({name: "debt-free-print"});
    },
    gotoFollowUp() {
      this.$router.push({name: "debt-free-follow-up"});
    },
    gotoReport() {
      this.$router.push({name: "report", params:{type: "DebtFree"}, query: {back: "debt-free"}});
    }
  },
};
</script>
