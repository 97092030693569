export default {
	titleIn: "รายการรับชำระ",
	titleOut: "รายการจ่ายชำระ",
	codeBank: "เลขที่",
	columnPayDate: "วันที่",
	columnToBankDate: "เข้าธนาคาร",
	columnCode: "เลขที่",
	columnCustomerCode: "รหัสสมาชิก",
	columnCustomerName: "ชื่อลูกค้า",
	columnPrice: "ราคา",
	columnPayCode: "รหัส",
	columnPayNameIn: "ประเภทรายรับ",
	columnPayNameOut: "ประเภทรายจ่าย",
	columnIsCancel: "ยกเลิก",
	columnStatus: "สถานะ",
	customerSearch: "รหัสหรือชื่อลูกค้า",
	unPaidOnly: "เฉพาะที่ยังไม่ได้ชำระ",
	unPaidOptions: [
		{ name: "ใช่", code: "A1" },
		{ name: "ไม่ใช่", code: "A2" },
	],
	bookBankAccountNumber: "เลขที่บัญชี",
	bookBankName: "ชื่อบัญชี",
	paymentMethodCode: "ประเภทการชำระ",
	Status: "สถานะ",
	importDocument: "นำเข้าเอกสาร",
	confirmDocument: "ยืนยันผูกเอกสาร",
	removeDocument: "นำเอกสารออกทั้งหมด",
	btnPrintLot: "พิมพ์เช็คเป็นชุด",
	bookBank: "สมุดบัญชีเงินฝาก",
	CodeStart: "เลขที่เริ่มต้น",
	CodeEnd: "ถึงเลขที่",	
	columnAccountCode: "เลขที่บัญชี",
	columnBranch: "สาขา",
	columnBank: "ธนาคาร",
	validatebbookBankError: "ระบุ สมุดบัญชีเงินฝาก",
	validateDetail: "ไม่พบข้อมูลพิมพ์",
	cancelStatus: "พิมพ์เอกสารรวมสถานะยกเลิก",
};
