<template>
	<span
		class="Tag"
		:class="{
			TagSuccess: Class === 'success',
			TagInfo: Class === 'primary',
			TagWarn: Class === 'warning',
			TagError: Class === 'error',
			TagSecondary: Class === 'secondary',
			TagOther: Class === 'other',
		}">
		<i class="pi pi-circle-fill" style="font-size: 10px" v-if="TagIcon === ''" />
		<component :is="TagIcon" :key="TagIcon" :iconColor="Class" height="13" width="13" v-else />
		<!-- <img :src="TagIcon" height="13" v-else /> -->
		<span> {{ this.Caption }} </span>
	</span>
</template>
<script>
export default {
	props: {
		Caption: {
			type: String,
			default: "ใช้งานอยู่",
		},
		Class: {
			type: String,
			default: "success",
		},
		TagIcon: {
			type: String,
			default: "",
		},
	},
};
</script>

<style scoped>
.Tag {
	display: inline-flex;
	/* flex-direction: row; */
	/* justify-content: center; */
	align-items: center;
	padding: 1px 11px;
	gap: 7px;
	/* width: 100px; */
	height: 26px;
	border-radius: 8px;
	/* flex: none; */
	/* order: 1; */
	/* flex-grow: 0; */
}

/* .TagSuccess {
    background: #F2FFF9;
    color: #05A861;
}
.TagInfo {
    background: #F5F9FF;
    color: #1C70F7;
}
.TagWarn {
    background: #FFF8EB;
    color: #EE9F00;
}
.TagError {
    background: #FFF5F5;
    color: #E00707;
} */
</style>

<!-- ตัวอย่าง
    <base-tag Caption="ใช้งานอยู่" Class="success" />
    <base-tag Caption="ใช้งานอยู่" Class="primary" />
    <base-tag Caption="ใช้งานอยู่" Class="warning" TagIcon="icon-pen-edit"/>
    <base-tag Caption="ใช้งานอยู่" Class="error" TagIcon="icon-pen-edit" />
-->
