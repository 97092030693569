<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33301 10.6H2.59967C1.89967 10.6 1.33301 10.0333 1.33301 9.33333V3.26667C1.33301 2.56667 1.89967 2 2.59967 2H12.733C13.433 2 13.9997 2.56667 13.9997 3.26667V6.66667"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.78584 5.31398C9.40384 5.93198 9.40384 6.93464 8.78584 7.55331C8.16784 8.17131 7.16517 8.17131 6.54651 7.55331C5.92851 6.93531 5.92851 5.93264 6.54651 5.31398C7.16517 4.69531 8.16784 4.69531 8.78584 5.31398"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.6663 11.3334C14.6663 12.8094 13.4757 14 11.9997 14C10.5237 14 9.33301 12.8094 9.33301 11.3334C9.33301 9.85735 10.5237 8.66669 11.9997 8.66669C13.4757 8.66669 14.6663 9.85735 14.6663 11.3334Z"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.99967 8.66665H3.33301"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.9997 4.00002H11.333"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.1152 13.218L13.8852 9.448"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "primary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
