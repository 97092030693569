export default {
  async getSignatures(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/SignatureManagement/Signature";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.projectId) {
			url = url + "&projectId=" + payload.projectId;
		}

		url = encodeURI(url);
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
        // console.log(responseData);
        const error = new Error(responseData.message || "Failed to get signature");
        throw error;
    }

    context.commit("setSignatures", responseData);
  },
  async getSignatureById(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/SignatureManagement/Signature/${payload.projectId}/${payload.id}`;
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
        // console.log(responseData);
        const error = new Error(responseData.message || `Failed to get signature id : ${payload}`);
        throw error;
    }

    context.commit("setSelectedSignature", responseData.data);
  },
  resetSignature(context) {
    context.commit("setSelectedSignature", null);
  },
  async getSignatureReferences(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/SignatureManagement/Signature/Reference/${payload}`;

    url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
        // console.log(responseData);
        const error = new Error(responseData.message || `Failed to get reference signature id : ${payload}`);
        throw error;
    }

    context.commit("setSignatureReference", responseData.data);
  },
  async deleteSignature(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/SignatureManagement/Signature/${payload.id}/Delete`;
    url = encodeURI(url);
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "Delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();
    if (!response.ok) {
      throw responseData;
    }
    context.commit("resetSignature");
  },
  async createSignature(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + "/SignatureManagement/Signature/Add";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw responseData;
    }

    context.commit("setSelectedSignature", responseData.data);
  },
  async updateSignature(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + `/SignatureManagement/Signature/${payload.id}/Update`;
    url = encodeURI(url);
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw responseData;
    }
    // console.log(responseData);

    context.commit("setSelectedSignature", responseData.data);
  },
  async getSignatureAll(context) {
      while (!context.getters.signatureIsLastPage) {      
        const payload = {
          page: context.getters.signatureNextPage || 1,
          projectId: context.rootGetters.selectedProject.id,
        };
        await context.dispatch("getAllSignatures", payload);
      }
  },
  async getAllSignatures(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/SignatureManagement/Signature";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.projectId) {
      url = url + "&projectId=" + payload.projectId;
    }

    url = encodeURI(url);
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
        const error = new Error(responseData.message || "Failed to get signatureAll");
        throw error;
    }
    context.commit("setSignatureAll", responseData);
  },
};
