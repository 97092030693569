export default {
  async getAvailableDepositByUnit(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL +"/Deposit/AvailableDepositByUnit";

    if (payload.unitId) {
      url = url + "?" + "unitId=" + payload.unitId;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get available deposit by unit");
      throw error;
    }

    await context.commit("setAvailableDepositByUnit", responseData.data);
  },
  resetAvailableDepositByUnit(context) {
    context.commit("setAvailableDepositByUnit", null);
  },
  async getDeposits(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Deposit";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get deposit."
      );
      throw error;
    }

    // console.log("getDeposits",responseData);

    context.commit("setDeposits", responseData);
  },
  async getDepositById(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Deposit/${payload}`;
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get deposit by id."
      );
      throw error;
    }
    // console.log("getDepositById",responseData);

    context.commit("setSelectedDeposit", responseData.data);
  },
  resetSelectedDeposit(context) {
    context.commit("setSelectedDeposit", null);
  },
  async getReferenceDocument(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Deposit/ReferenceDocument`;

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.id) {
      url = url + "&id=" + payload.id;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get references."
      );
      throw error;
    }
    // console.log("getReferenceDocument",responseData);

    context.commit("setDepositReferenceDocument", responseData.data);
  },
  resetDepositReferenceDocument(context) {
    context.commit("setDepositReferenceDocument", null);
  },
  async createOrUpdateDeposit(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Deposit/${payload.type}`;

    const fallbackErrorMessage = `Failed to ${payload.type} deposit.`
    const token = context.rootGetters.token;
    
    const response = await fetch(url, {
      method: payload.type === 'Add' ? 'post' : 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }

    context.commit("setSelectedDeposit", responseData.data);
  },
  async cancelDeposit(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Deposit/Cancel`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to cancel deposit.');
      throw error;
    }

    context.commit("setSelectedDeposit", responseData.data);
  },
  async unitExistDeposits(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Deposit/UnitExistDeposit";
    if (payload.projectId) {
      url = url + "?ProjectId=" + encodeURI(payload.projectId);
    }
    const token = context.rootGetters.token;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to get closingEntry"
      );
      throw error;
    }
    context.commit("setUnitExistDeposits", responseData.data);
  },  
  resetUnitExistDeposits(context) {
    context.commit("resetUnitExistDeposits");
  },
};
