<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
		:style="{ width: '50vw' }"
		:modal="true"
		:closable="false"
  >
    <template #header>
			<div class="flex justify-content-between align-items-center">
				<div class="pt-2 pb-1">
					<label class="font-lg font-normal">
						{{ title }}
					</label>
				</div>
				<PvButton
					icon="pi pi-times"
					class="p-button-link p-icon-secondary"
					@click="closeDialog()" />
			</div>
			<PvDivider></PvDivider>
		</template>
    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateStart">{{ lang.dateStart }}</label>
          <PvCalendar
            id="dateStart"
            v-model="dateStart"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': isDateStartError }"
            @date-select="isDateStartError = false"
          />
          <small v-if="isDateStartError" id="dateStart-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateEnd">{{ lang.dateEnd }}</label>
          <PvCalendar
            id="dateEnd"
            v-model="dateEnd"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': isDateEndError }"
            @date-select="isDateEndError = false"
          />
          <small v-if="isDateEndError" id="unit-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pb-0">
          <label for="accountCodeStart">{{
            lang.accountCodeStart
          }}</label>
          <base-accounting-auto-complete
            id="assetAccount"
            accountType="Y"
            v-model="selectedAccountCodeStart"
            class="inputfield w-full mt-1"
            @load-finish="accountDataReady"
            :class="{ 'p-invalid': accountCodeStartError }"
          />
          <small
            v-if="accountCodeStartError"
            id="assetAccount-help"
            class="p-error"
            >{{ lang.validateNoAssetAccountSelect }}</small
          >
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pb-0">
          <label for="accountCodeEnd">{{
            lang.accountCodeEnd
          }}</label>
          <base-accounting-auto-complete
            id="assetAccount"
            accountType="Y"
            v-model="selectedAccountCodeEnd"
            class="inputfield w-full mt-1"
            @load-finish="accountDataReady"
            :class="{ 'p-invalid': accountCodeEndError }"
          />
          <small
            v-if="accountCodeEndError"
            id="assetAccount-help"
            class="p-error"
            >{{ lang.validateNoAssetAccountSelect }}</small
          >
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pb-0">
          <label for="assetRegister">{{ lang.assetCodeStart }}</label>
          <base-asset-register-auto-complete
            id="assetRegister"
            class="inputfield w-full mt-1"
            v-model="selectedAssetCodeStart"
            @load-finish="assetRegisterDataReady"
            :class="{ 'p-invalid': assetCodeStartError }"
          />
          <small
            v-if="assetCodeStartError"
            id="assetCodeStart-help"
            class="p-error"
            >{{ lang.validateAssetCodeSelect }}</small
          >
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pb-0">
          <label for="assetRegister">{{ lang.assetCodeEnd }}</label>
          <base-asset-register-auto-complete
            id="assetRegister"
            class="inputfield w-full mt-1"
            v-model="selectedAssetCodeEnd"
            @load-finish="assetRegisterDataReady"
            :class="{ 'p-invalid': assetCodeEndError }"
          />
          <small
            v-if="assetCodeEndError"
            id="assetCodeEnd-help"
            class="p-error"
            >{{ lang.validateAssetCodeSelect }}</small
          >
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="newPage">{{ lang.newAccountNewPage }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="newPage"
            :showClear="clearNewPage"
            optionLabel="name"
            optionValue="code"
            v-model="selectNewPage"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="assetStatus">{{ lang.assetStatus }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="assetStatus"
            :showClear="clearAssetStatus"
            optionLabel="name"
            optionValue="code"
            v-model="selectAssetStatus"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="calculationType">{{ lang.calculationType }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="calculationType"
            :showClear="clearCalculationType"
            optionLabel="name"
            optionValue="code"
            v-model="selectCalculationType"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
					<label for="fileNameText">{{ lang.fileName }}</label>
					<PvInputText
						id="fileNameText"
						type="text"
						class="inputfield w-full mt-1"
						v-model="fileNameText"
					/>
				</div>
      </section>
    </transition>
		<template #footer>
			<div
				class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
			>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
					<PvButton
						icon="pi pi-file-pdf"
						:label="lang.downloadPDF"
						@click="download('PDF')"
						class="w-full"
					/>
				</div>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
					<PvButton
						icon="pi pi-file-excel"
						:label="lang.downloadExcel"
						@click="download('JASPEREXCEL')"
						class="w-full"
					/>
				</div>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
					<PvButton
						icon="pi pi-file-excel"
						:label="lang.downloadExcelRaw"
						@click="download('EXCEL')"
						class="w-full"
					/>
				</div>
			</div>
		</template>
  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import BaseAssetRegisterAutoComplete from "../../components/Asset/BaseAssetRegisterAutoComplete.vue";
import useTracking from "../../hooks/tracking";
import useReportRequestValidate from "../../hooks/reportRequestValidate.js";
export default {
  components: {
    BaseAssetRegisterAutoComplete,
  },
	props: {
		reportObj: {
			type: Object,
			default: null,
		},
	},
	emits: ["close", "toast-request"],
  setup() {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const { formatDateToISOString } = useFormat();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.asset.assetRegister.listing,
          ...lang.th.asset.assetRegister.edit,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.asset.assetRegister.listing,
          ...lang.en.asset.assetRegister.edit,
          ...lang.en.gl.reportGl,
        };
      }
    });
    return {
      lang: mergeLang,
      textFormClass,
      formatDateToISOString,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      dateStart: null,
      dateEnd: null,
      isDateStartError: false,
      isDateEndError: false,
      selectedAssetCodeStart: null,
      selectedAssetCodeEnd: null,
      assetCodeStartError: false,
      assetCodeEndError: false,
      isAssetRegisterReady: false,
      selectedAccountCodeStart: null,
      selectedAccountCodeEnd: null,
      accountCodeStartError: false,
      accountCodeEndError:false,
      isSharedAccountReady: false,
      newPage: [
        { name: this.lang.yes, code: "Y" },
        { name: this.lang.no, code: "N" },
      ],
      selectNewPage: "Y",
      clearNewPage: false,
      assetStatus: [
        { name: this.lang.assetStatus0, code: "0" },
        { name: this.lang.assetStatus1, code: "1" },
        { name: this.lang.assetStatus2, code: "2" },
      ],
      selectAssetStatus: "0",
      clearAssetStatus: false,
      calculationType: [
        { name: this.lang.calculationType1, code: "1" },
        { name: this.lang.calculationType2, code: "2" },
      ],
      selectCalculationType: "1",
      clearCalculationType: false,
			type: "PDF",
      fileNameText: "asset_and_depreciation_expense",
    };
  },
	mounted() {
		this.setLoading(true);
		this.initiateData();
	},
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
		initiateData() {
			let date = new Date();
			this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
			this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.isDateStartError = false;
      this.isDateEndError = false;
      this.isAssetRegisterReady = false;
      this.selectedAssetCodeStart = null;
      this.assetCodeStartError = false;
      this.selectedAssetCodeEnd = null;
      this.assetCodeEndError = false;
      this.isSharedAccountReady = false;
      this.selectedAccountCodeStart = null;
      this.accountCodeStartError = false;
      this.selectedAccountCodeEnd = null;
      this.accountCodeEndError = false;
      this.selectNewPage = "Y";
      this.selectAssetStatus = "0";
      this.selectCalculationType = "1";
			this.type = "PDF";
      this.fileNameText = "asset_and_depreciation_expense";
		},    
    assetRegisterDataReady() {
      this.isAssetRegisterReady = true;
      this.checkShouldSetData();
    },
    accountDataReady() {
      this.isSharedAccountReady = true;
      this.checkShouldSetData();
    },
    checkShouldSetData() {
      if(this.isAssetRegisterReady && this.isSharedAccountReady){
        this.setData();
        this.setLoading(false);
      }
    },
    setData() {
      this.selectedAssetCodeStart = this.assets[0];
      this.selectedAssetCodeEnd = this.assets[this.assets.length - 1];
      this.selectedAccountCodeStart = this.accounts[0];
      this.selectedAccountCodeEnd = this.accounts[this.accounts.length - 1];
    },
		validateData() {
			let validateError = false;
			if (this.selectedDateStart == null) {
				this.dateStartError = true;
				validateError = true;
			}
			if (this.selectedDateEnd == null) {
				this.dateEndError = true;
				validateError = true;
			}
			if (this.selectedAccountCodeStart == null) {
				this.accountCodeStartError = true;
			  	validateError = true;
			}
			if (this.selectedAccountCodeEnd == null) {
			  	this.accountCodeEndError = true;
			  	validateError = true;
			}
			if (this.selectedAssetCodeStart == null) {
				this.assetCodeStartError = true;
			  	validateError = true;
			}
			if (this.selectedAssetCodeEnd == null) {
			  	this.assetCodeEndError = true;
			  	validateError = true;
			}
			return validateError;
		},
    async download(typeReport) {
			this.type = typeReport;
			if (this.validateData()) {
				return;
			}
			this.setLoading(true);
			try {
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
				const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
				this.setLoading(false);
			} catch (error) {
				this.$toast.add({
					severity: "error",
					summary: this.lang.notificationDialogHeader,
					detail: error,
					life: 3000,
				});
				this.setLoading(false);
			}
		},
  },
  computed: {
		selectedProject() {
			return this.$store.getters.selectedProject;
		},
		title() {
			if (this.$store.getters.selectedLanguage === "TH") return this.reportObj.nameThai;
			else return this.reportObj.nameEnglish;
		},
		userId() {
			return this.$store.getters.userId;
		},
		userName() {
			return this.$store.getters.userName;
		},
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    assets() {
      return this.$store.getters["assetAssetRegister/assetRegisterDropDowns"];
    },
    accounts() {
      return this.$store.getters["shared/accountingAccounts"];
    },
    fileName() {
			let fileName = this.type == "PDF" ? ".pdf" : ".xlsx";
			return this.fileNameText + fileName;
		},
		fileType() {
			let fileType =
				this.type == "EXCEL"
				? "_excel.xlsx"
				: this.type == "JASPEREXCEL"
				? ".xlsx"
				: ".pdf";
			return fileType;
		},
    reportPath(){
        let report = this.reportObj.path;
        if(this.selectNewPage == "Y" && this.fileType !== "_excel.xlsx"){
            report = "asset/asset_and_depreciation_expense_new_page"; 
        }
        return report; 
    },
    payload() {
			let payload = {
				ProjectId: this.selectedProject.id,
          CalDateStart: this.selectedDateStart,
          CalDateEnd: this.selectedDateEnd,
          AccountCodeStart: this.selectedAccountCodeStart ? this.selectedAccountCodeStart.code : "",
          AccountCodeEnd: this.selectedAccountCodeEnd ? this.selectedAccountCodeEnd.code : "",
          AssetCodeStart: this.selectedAssetCodeStart ? this.selectedAssetCodeStart.code : "",
          AssetCodeEnd: this.selectedAssetCodeEnd ? this.selectedAssetCodeEnd.code : "",
          NewPage: this.selectNewPage,
          AssetStatus: this.selectAssetStatus,
          Type: this.selectCalculationType,
				FileName: this.fileName,
				JasperPath: this.reportPath + this.fileType,
				DocType: this.type,
				UserId: this.userId,
				PrintBy: this.userName,
				RequestId: 0,
			};
			return payload;
		},
  },
  watch: {
    reportObj() {
			this.initiateData();
		},
  },
};
</script>
