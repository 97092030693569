import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export default {
    state(){
        return {
            token: null,
            userId: null,
            userCode: null,
            userName: null,
            didAutoLogout: false,
            tokenLosaPM: null
        }
    },
    actions,
    getters,
    mutations
}