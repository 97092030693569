<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
    :header="lang.titleImportPO"
  >
    <PvDataTable
      showGridlines
      :value="filteredPurchaseOrder"
      v-model:selection="selectedPurchaseOrder"
      dataKey="id"
      :loading="isLoading"
      responsiveLayout="scroll"
      :scrollHeight="halfViewportHeight + 'px'"
      class="p-datatable-sm"
      :class="textTableClass"   
      @row-dblclick="selectPurchaseOrder()" 
      selectionMode="single"     
    >
      <template #header>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <PvInputText
            v-model="searchText"
            :placeholder="lang.keywordSearch"
            autofocus
          />
        </span>
      </template>
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
      <PvColumn selectionMode="single"></PvColumn>
      <PvColumn
        field="documentBookCode"
        :header="lang.columnBookCode"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>
      <PvColumn
        field="vatRate"
        :header="lang.inputTax"
        style="min-width: 4rem"
        bodyClass="text-right"
      >
        <template #body="slotProps">
          {{ formatCurrency(slotProps.data.vatRate) }}
        </template>
      </PvColumn>
      <PvColumn
        field="code"
        :header="lang.columnCode"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>
      <PvColumn
        field="supplierName"
        :header="lang.supplier"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>    
      <PvColumn
        field="referDocument"
        :header="lang.columnRefCode"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>
      <PvColumn
        field="totalAmount"
        :header="lang.columnAmount"
        style="min-width: 3rem"
        headerClass="h-center"
        bodyClass="text-right"
      >
        <template #body="slotProps">
          {{ formatCurrency(slotProps.data.totalAmount) }}
        </template>
      </PvColumn>
      
      <PvColumn
        field="date"
        :header="lang.columnDate"
        style="min-width: 4rem"
        headerClass="h-center"
        bodyClass="text-center"
      >
        <template #body="slotProps">
          {{ formatDate(slotProps.data.date) }}
        </template>
      </PvColumn>
      
      <PvColumn
        field="dueDate"
        :header="lang.dueDate"
        style="min-width: 4rem"
        headerClass="h-center"
        bodyClass="text-center"
      >
        <template #body="slotProps">
          {{ formatDate(slotProps.data.date) }}
        </template>
      </PvColumn>

      <!-- <PvColumn
        field="balanceAmount"
        :header="lang.columnBalance"
        style="min-width: 3rem"
        headerClass="h-center"
        bodyClass="text-right"
      >
        <template #body="slotProps">
          {{ formatCurrency(slotProps.data.balanceAmount) }}
        </template>
      </PvColumn> -->
    </PvDataTable>
    <template #footer>
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog"
      />
      <PvButton
        :label="lang.ok"
        icon="pi pi-check"
        autofocus
        @click="selectPurchaseOrder"
      />
    </template> 

  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  emits: ["close","select-purchase-order"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString} =
      useFormat();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { 
          ...lang.th.base,        
          ...lang.th.ap.purchaseInvoice.listing,
          ...lang.th.ap.purchaseInvoice.edit };
      } else {
        return { 
          ...lang.en.base,         
          ...lang.en.ap.purchaseInvoice.listing,
          ...lang.th.ap.purchaseInvoice.edit };
      }
    });
    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
    };
  },
  data() {
    return {
      isLoading: true,
      selectedPurchaseOrder: [],
      totalRecords: 0,
      selectAll: false,
      searchText: null,
    };
  },
  methods: {
    selectPurchaseOrder() {
      // console.log("this.selectedPurchaseOrder=",this.selectedPurchaseOrder);
      this.$emit("select-purchase-order", this.selectedPurchaseOrder);
      this.$emit("close");
      this.isLoading = true;
    },
    onRowUnselect() {
      this.selectedPurchaseOrder = null;
    },
    closeDialog() {      
      this.isLoading = true;
      this.$emit("close");
    },
    searchPurchaseOrder(event) {
      if (!event.query.trim().length) {
        //ช่องว่าง
        // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
        this.searchText = "";
        this.searchText = null;
      } else {
        this.searchText = event.query;
      }
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    PurchaseOrders() {
      this.onRowUnselect();
      return this.$store.getters["apPurchaseInvoice/purchaseOrders"];
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    filteredPurchaseOrder() {
      //console.log('filteredPurchaseOrder=', this.searchText);
      if (!this.searchText) {
        //ช่องว่าง
        // console.log('0this.PurchaseOrders=',this.PurchaseOrders);
        const result = [...this.PurchaseOrders];
        return result;
      } else {
        
      // console.log('1this.PurchaseOrders=',this.PurchaseOrders);
        return this.PurchaseOrders.filter((PurchaseOrder) => {
          return (PurchaseOrder.code.toLowerCase()
            .includes(this.searchText.toLowerCase())

            // || PurchaseOrder.productCode
            // .toLowerCase()
            // .includes(this.searchText.toLowerCase())

            // || PurchaseOrder.productName
            // .toLowerCase()
            // .includes(this.searchText.toLowerCase())

            // || PurchaseOrder.period
            // .toLowerCase()
            // .includes(this.searchText.toLowerCase())
            );
        });
      }
    },
  },
  watch: {
    PurchaseOrders() {
      this.isLoading = false;
    },
    searchText(curValue) {
      if (curValue) {
        this.searchText = curValue;
      }
    },
    filteredPurchaseOrder(){
      this.selectedPurchaseOrder = this.filteredPurchaseOrder[0];
    }
  },
};
</script>
