export default {
    setAvailableDepositByUnit(state, payload){
        state.availableDepositByUnit = payload
    },
    setDeposits(state, payload){
        state.deposits = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },
    setSelectedDeposit(state, payload){
        state.selectedDeposit = payload
    },
    setDepositReferenceDocument(state, payload) {
        state.depositReferenceDocument = payload
    },
    resetUnitExistDeposits(state) {
        state.unitExistDeposits = [];
    },
    setUnitExistDeposits(state, payload) {
        state.unitExistDeposits = payload;
    },
};