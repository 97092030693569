export default {
	setBanksAr(state, payload) {
		state.banksAr = payload.data;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
	setSelectedBank(state, payload) {
		state.selectedBank = payload;
	},
	setTransactionType(state, payload) {
		state.transactionType = payload;
	},
	setTypeInOut(state, payload) {
		state.typeInOut = payload;
	},
	setBookBankList(state, payload) {
		state.bookBankList = payload.data;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
	setReceiptNotPayIn(state, payload) {
		state.receiptNotPayIn = payload.data;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
	setNewBanksAr(state, payload) {
		state.newBanksAr = payload;
	},
	setReceiptExtra(state, payload) {
		state.receiptExtra = payload.data;
		state.totalPageExtra = payload.page.pageCountExtra;
		state.currentPageExtra = payload.page.currentPageExtra;
	},
	setBlob(state, payload) {
		state.blob = payload;
	},
	resetBank(state) {
		state.selectedBank = null;
	},
	setBanksForSelect(state, payload) {
		state.banksForSelect = payload.data;
		state.totalPageSelected = payload.page.pageCount;
		state.currentPageSelected = payload.page.currentPage;
	},
	resetBanksForSelect(state) {
		state.banksForSelect = [];
		state.totalPageSelected = 0;
		state.currentPageSelected = 0;
	},
	setBanksSelected(state, payload) {
		state.banksSelected = payload;
	},
	resetBanksSelected(state) {
		state.banksSelected = [];
	},
	setPrintLot(state, payload) {	
		let printLots = [...state.printLots];
        for (const printLot of payload.printLots) {
            if(printLots.findIndex(c => c.id === printLot.id) < 0){
				printLots.push(printLot);
            }
        }
        state.printLots = printLots;
        state.currentPage = payload.currentPage;
        state.totalPage = payload.totalPage;
	},
	resetPrintLot(state) {		
		state.printLots = [];
        state.currentPage = 0;
        state.totalPage = 0;
	},
};
