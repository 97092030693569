export default {
  async getGlBooks(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Role/ByVoucherBook";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to get glBook"
      );
      throw error;
    }
    // console.log('glBook',responseData);

    // const data = responseData.data;

    const glBook = {
      glBooks: responseData.data,
      totalPage: responseData.page.pageCount, 
      currentPage: responseData.page.currentPage, 
    };
    // console.log('9999setGlBook',glBook);
    context.commit("setGlBook", glBook);
  },
  async getAllGlBooks(context) {
    while (!context.getters.glBookIsLastPage) {
      let where = {
        projectId: context.rootGetters.selectedProject.id
      };
      const payload = {
        page: context.getters.glBookNextPage || 1,
        where,
      };
      // console.log("payloadA=",payloadA);
      await context.dispatch("getGlBooks", payload);
    }
  },
  
  async getReceiptGlBooks(context,payload) {
    // console.log("getReceiptGlBooks11=",payload); 
    while (!context.getters.glBookIsLastPage) {   
      let where = {
        projectId: context.rootGetters.selectedProject.id,
        generalLedgerBookCode: payload.generalLedgerBookCode,
        PettyCashCode: payload.PettyCashCode,
      };
      const payloadR = {
        page: context.getters.glBookNextPage || 1,
        where,
      };
      // console.log("getReceiptGlBooks22=",payloadR);
      await context.dispatch("getGlBooks", payloadR);
    }
    
  },
  resetGlBook(context) {
    context.commit("resetGlBook");
  },

  async getPettyCashGlBooks(context,payload) {
    // console.log("getReceiptGlBooks11=",payload); 
    while (!context.getters.glBookIsLastPage) {   
      let where = {
        projectId: context.rootGetters.selectedProject.id,
        PettyCashCode: payload.PettyCashCode
      };
      const payloadR = {
        page: context.getters.glBookNextPage || 1,
        where,
      };
      // console.log("getReceiptGlBooks22=",payloadR);
      await context.dispatch("getPettyCashBooks", payloadR);
    }
    
  },
  async getPettyCashBooks(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/DocumentBook/DocumentBookGL";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    // console.log('glBookwhere=',payload.where);
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to get glBook"
      );
      throw error;
    }
    //  console.log('glBook=',responseData.data);

    // const data = responseData.data;

    const glBook = {
      glBooks: responseData.data,
      totalPage: responseData.page.pageCount, 
      currentPage: responseData.page.currentPage, 
    };
    // console.log('9999setGlBook=',glBook);
    context.commit("setGlBook", glBook);
  },



};
