export default {
  async getProductAps(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/ProductAp";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.projectId) {
			url = url + "&ProjectId=" + payload.projectId;
		}
		
		if (payload.code) {
			url = url + "&Code=" + payload.code;
		}
		
		if (payload.name) {
			url = url + "&Name=" + payload.name;
		}
		url = encodeURI(url);

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			const error = new Error(responseData.message || "Failed to get productAR");
			throw error;
		}

		// const productAp = {
		// 	productAps: responseData.data,
		// 	totalPage: responseData.page.pageCount == 0 ? 1 : responseData.page.pageCount,
		// 	currentPage: responseData.page.currentPage,
		// };
		// // console.log('setProductAp=', productAp);
		context.commit("setProductAps", responseData);
	},
	async getAllProductAPs(context, payload) {
		while (!context.getters.productApIsLastPage) {
			const payloadP = {
				page: context.getters.productApNextPage || 1,
				projectId: payload.projectId,
				code: payload.code,
				searchText: payload.searchText,
			};
			await context.dispatch("getProductAps", payloadP);
		}
	},
	resetProductAps(context) {
		context.commit("resetProductAps");
	},
	async getProductApById(context, id) {
		let url = process.env.VUE_APP_BACKEND_URL + "/ProductAp/" + id;
		url = encodeURI(url);

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get policy");
			throw error;
		}
		// console.log("policy", responseData);
		context.commit("setProductApById", responseData.data);
	},
	resetProductAp(context) {
		context.commit("resetProductAp");
	},
	async createOrUpdateProductAp(context, payload){
		let url = process.env.VUE_APP_BACKEND_URL + `/ProductAp/${payload.typeAddEdit}`;
		const fallbackErrorMessage = `Failed to ${payload.typeAddEdit} productAp`
		const token = context.rootGetters.token;
	
		const response = await fetch(url, {
		  method: payload.typeAddEdit === 'Add' ? 'post' : 'put',
		  headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + token,
		  },
	
		  body: JSON.stringify(payload),
		});
	
		const responseData = await response.json();
	
		if (!response.ok) {
		  // console.log(responseData);
		  let message = null
		  if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
			message = responseData.title;
			for (const key in responseData.errors) {
			  message += ", " + key.replace("$.","")
			}
		  } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
			message = responseData.message
		  }
		  const error = new Error(message || fallbackErrorMessage);
		  throw error;
		}
		// console.log(responseData);
	
		context.commit("setProductApById", responseData.data);
	},
	async deleteProductAp(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/ProductAp/Delete";
		const token = context.rootGetters.token;
		const response = await fetch(url, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});
		const responseData = await response.json();
		if (!response.ok) {
			// console.log(responseData);
			throw responseData;
		}
		// context.commit("setArProducts", responseData);
	},


};
