export default {
    setParams(state, payload) {
        state.params = payload;
    },
    setImportExistUnits(state, payload) {
        state.importExistUnits = [
            ...state.importExistUnits, 
            ...payload,
        ];
    },
    resetImportExistUnits(state) {
        state.importExistUnits = [];
    },
}