export default {
	editTitle: "Edit Meter Register",
	addTitle: "Add Meter Register",
	updateMeterData: "Update Meter Data",
	saveMeter: "save",
	newMeter: "Add Meter",

	code: "Code",
	unitCode: "Unit Code",
	addressNo: "Address No",
	active: "Usage Status",
	activeDate: "Active Date",
	remark: "Remark",
	productCode: "Product Code",

	validateNoCode: "Please enter Code.",
	validateNoProduct: "Please select Product.",

	warrningUnit: "No expense items.",
	warrningProduct: "",

	errorMeterDuplicate: "Duplicate Meter Code",
	errorMeterActiveDate: "Active Date more than Meter usage date.",

	deleteMeterTitle: "Confirm delete",
	deleteMeterSuccessTitle: "Delete success",
	deleteMeterSuccess: "Delete meter success",
	validateActiveDate: "Please select active date",
};
