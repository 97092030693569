<template>
  <PvDialog :style="{ width: '80vw' }" :modal="true" :closable="false">
    <base-dialog
      v-model:visible="showConfirmDialog"
      :titleConfirm="confirmTitle"
      :classTitle="confirmClassTitle"
      :wordingConfirm="confirmWording"
      :dataButtons="dataButtonDialog"
      @close="showConfirmDialog = false"
      @confirm="showConfirmDialog = false"
    ></base-dialog>

    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">{{ lang.importReceiptTitle }}</label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog()"
        />
      </div>
      <PvDivider></PvDivider>
      <div class="flex flex-wrap justify-content-between align-items-center pb-3 pt-1">
        <div class="flex lg:col-6 md:col-6 col-6 p-0">
          <div class="pl-0 pr-2 col-12">
            <PvButton
              icon="pi pi-plus"
              :label="lang.extra"
              class="mr-2 p-button-secondary"
              @click="getExtra"
            />
          </div>
        </div>

        <PvButton class="ml-2" @click="confirmImport()">
          <icon-documents-file-checkmark iconColor="white" />
          <span class="font-normal w-full ml-1">{{ lang.confirmImport }}</span>
        </PvButton>
      </div>
    </template>
    <div class="p-datatable-border my-2">
      <PvDataTable
        :value="receiptLists"
        v-model:selection="selectedReceipt"
        dataKey="id"
        :class="textTableClass"
        :loading="isLoading"
        :scrollHeight="halfViewportHeight + 'px'"
        selectionMode="multiple"
        :metaKeySelection="false"
        responsiveLayout="scroll"
        class="p-datatable-xs"
        :scrollable="true"
      >
        <template #empty>
          <div
            style="height: 56px"
            class="text-center flex align-items-center justify-content-center w-full"
          >
            <label class="font-normal">
              {{ lang.tableEmpty }}
            </label>
          </div>
        </template>
        <PvColumn selectionMode="multiple" style="flex: 0; height: 56px"></PvColumn>

        <PvColumn
          field="itemNo"
          :header="lang.itemNo"
          style="max-width: 5rem; height: 56px"
          headerClass="h-center font-normal"
          bodyClass="h-center font-normal"
        />

        <PvColumn
          field="code"
          :header="lang.columnCodeReceipt"
          style="min-width: 9rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
          sortable
        />

        <PvColumn
          field="date"
          :header="lang.columnReceiptDate"
          style="min-width: 4rem"
          headerClass="h-center"
          bodyClass="text-center"
          :sortable="true"
        >
          <template #body="slotProps">
            {{ formatDate(slotProps.data.date) }}
          </template>
        </PvColumn>

        <PvColumn
          field="payDate"
          :header="lang.columnDate"
          style="min-width: 4rem"
          headerClass="h-center"
          bodyClass="text-center"
          :sortable="true"
        >
          <template #body="slotProps">
            {{ formatDate(slotProps.data.payDate) }}
          </template>
        </PvColumn>

        <PvColumn
          field="unitCode"
          :header="lang.columnUnitCode"
          style="min-width: 4rem"
        ></PvColumn>

        <PvColumn
          field="refCode"
          :header="lang.columnInvoice"
          style="min-width: 4rem"
        ></PvColumn>

        <PvColumn
          field="customerName"
          :header="lang.columnCustomerName"
          style="min-width: 4rem"
        ></PvColumn>

        <PvColumn
          field="totalAmount"
          :header="lang.columnTotal"
          style="min-width: 3rem"
          headerClass="h-right"
        >
          <template #body="slotProps">
            <span class="text-right w-full">
              {{ formatCurrency(slotProps.data.totalAmount) }}
            </span>
          </template>
        </PvColumn>
      </PvDataTable>
    </div>
    <template #footer></template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  props: {
    openFrom: {
      type: Number,
    },
    startDate: {
      type: Date,
      default: new Date(),
    },
    endDate: {
      type: Date,
      default: new Date(),
    },
    cashType: {
      type: String,
    },
  },
  emits: ["closeImport", "importReceiptToPayin"],
  setup() {
    const { lang } = useLang();
    const { textTableClass } = useTextStyle();
    const { formatCurrency, formatDateToISOString, formatDate } = useFormat();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.ar.bank.import };
      } else {
        return { ...lang.en.base, ...lang.en.ar.bank.import };
      }
    });

    return {
      lang: mergeLang,
      textTableClass,
      formatCurrency,
      formatDateToISOString,
      formatDate,
    };
  },
  data() {
    return {
      receiptLists: [],
      isLoading: false,
      selectedReceipt: [],
      showValidateDialog: false,
      confirmTitle: "",
      confirmWording: "",
      confirmClassTitle: "",
      showConfirmDialog: false,
      dataButtonDialog: [
        {
          id: "close",
          caption: this.lang.buttonDialogCancel,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ],
    };
  },
  mounted() {
    this.isLoading = true;
    this.checkShouldSetData;
  },
  methods: {
    async getReceiptForImport() {
      await this.callGetReceiptForPayin();
    },
    async getExtra() {
      this.isLoading = true;
      await this.callGetReceiptForPayin("Other");
    },
    async callGetReceiptForPayin(payType = null) {
      try {
        let payload = {
          projectId: this.selectedProject.id,
          dateStart: this.selectedDateStart,
          dateEnd: this.selectedDateEnd,
          payType: payType ?? this.cashType,
        };
        await this.$store.dispatch("arBank/getReceiptForPayin", payload);
        this.receiptLists = this.selectedPayin;
        this.isLoading = false;
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.isLoading = false;
      }
    },
    closeDialog() {
      this.$emit("closeImport");
    },
    confirmImport() {
      if (this.selectedReceipt.length <= 0) {
        this.showValidateDialog = true;
        return;
      }
      this.$emit("importReceiptToPayin", this.selectedReceipt);
    },
    async checkShouldSetData() {
      this.selectedReceipt = [];
      await this.getReceiptForImport();
    },
    openAlertDialog(dataConfig) {
      if (dataConfig.severity === "") {
        this.confirmClassTitle = "";
      } else if (dataConfig.severity === "success") {
        this.confirmClassTitle = "p-text-success";
      } else if (dataConfig.severity === "error") {
        this.confirmClassTitle = "p-error";
      }
      this.confirmTitle = dataConfig.title;
      this.confirmWording = dataConfig.content;
      this.showConfirmDialog = true;
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.startDate) {
        dateStart = this.formatDateToISOString(this.startDate);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.endDate) {
        dateEnd = this.formatDateToISOString(this.endDate);
      }
      return dateEnd;
    },
    selectedPayin() {
      return this.$store.getters["arBank/receiptForPayin"];
    },
  },
  watch: {
    openFrom() {
      this.checkShouldSetData();
    },
  },
};
</script>
