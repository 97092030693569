<!--<template>
  <base-toolbar
    :title="lang.projectTitleAdvertise"
    :isShowGoToEdit="true"
    :isShowConfirm="false"
    @goBack="goBack"
    @gotoEdit="gotoEdit"
  >
  </base-toolbar>
  <div class="mt24"></div>
  <base-container>
    <base-card
      :titleHeader="this.lang.projectTitleAdvertise"
      :isVisibleExpandButton="true"
      :isHideBackground="false"
      :isVisibleCard="true"
    >
      <section class="flex flex-wrap fontlabel" :class="textFormClass">
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
          <label>{{ lang.advertiseInvoiceTh }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.AdvertiseInvoiceTh }}</label>
          </div>
        </div>
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
          <label>{{ lang.advertiseInvoiceEn }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.AdvertiseInvoiceEn }}</label>
          </div>
        </div>
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
          <label>{{ lang.advertiseNoticeTh }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.AdvertiseNoticeTh }}</label>
          </div>
        </div>
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
          <label>{{ lang.advertiseNoticeEn }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.AdvertiseNoticeEn }}</label>
          </div>
        </div>
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
          <label>{{ lang.advertiseReceiptTh }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.AdvertiseReceiptTh }}</label>
          </div>
        </div>
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
          <label>{{ lang.advertiseReceiptEn }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.AdvertiseReceiptEn }}</label>
          </div>
        </div>
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
          <label>{{ lang.invoiceFooter }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.InvoiceFooter }}</label>
          </div>
        </div>
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
          <label>{{ lang.receiptFooter }}</label>
          <div class="mt-1">
            <label class="fontData">{{ this.ReceiptFooter }}</label>
          </div>
        </div>
      </section>
    </base-card>

    <div class="mt24"></div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const {
      formatCurrency,
      formatDate,
      formatDateToISOString,
      formatISODateToLocaleDateString,
    } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.project.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.project.edit,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      formatISODateToLocaleDateString,
    };
  },
  data() {
    return {
      //ประชาสัมพันธ์ - โฆษณา
      AdvertiseInvoiceTh: "",
      AdvertiseInvoiceEn: "",
      AdvertiseNoticeTh: "",
      AdvertiseNoticeEn: "",
      AdvertiseReceiptTh: "",
      AdvertiseReceiptEn: "",
      InvoiceFooter: "",
      ReceiptFooter: "",
    };
  },
  async created() {
    await this.GetProject();
    if (this.project) {
      this.setData();
    }
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    gotoEdit() {
      this.$router.replace("/master/product-advertisement/edit");
    },
    async GetProject() {
      try {
        this.setLoading(true);
        await this.$store.dispatch(
          "masterProject/GetProjectById",
          this.selectedProject.id
        );
      } catch (error) {
        this.$toast.add({
          severity: "error", // severity:'success',severity:'info',severity:'warn',severity:'error'
          summary: this.lang.notificationDialogHeader, //TitleHeader
          detail: error,
          life: 5000, //TimeClose
        });
      }
      this.setLoading(false);
    },
    setData() {
      //console.log("project=", this.project);

      //ประชาสัมพันธ์ - โฆษณา
      this.AdvertiseInvoiceTh = this.project.advertiseInvoiceTh;
      this.AdvertiseInvoiceEn = this.project.advertiseInvoiceEn;
      this.AdvertiseNoticeTh = this.project.advertiseNoticeTh;
      this.AdvertiseNoticeEn = this.project.advertiseNoticeEn;
      this.AdvertiseReceiptTh = this.project.advertiseReceiptTh;
      this.AdvertiseReceiptEn = this.project.advertiseReceiptEn;
      this.InvoiceFooter = this.project.invoiceFooter;
      this.ReceiptFooter = this.project.receiptFooter;

      this.setLoading(false);
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    project() {
      return this.$store.getters["masterProject/project"];
    },
  },
};
</script>

<style scoped>
.fontlabel {
  font-size: 14px;
  color: #6e7073;
}
.fontData {
  font-size: 16px;
  color: #181819;
  letter-spacing: 0.005em;
  /* font-weight: bold; */
  font-weight: 400;
}

.mt24 {
  margin-top: 24px;
}
</style>-->

 <template>
  <base-container id="AdvertisementView">
    <PvButton
      icon="pi pi-arrow-left"
      @click="visibleRight = true"
      class="mr-2"
    />
    <br /><br />
    <base-tag Caption="ใช้งานอยู่" Class="success" />
    <base-tag Caption="ใช้งานอยู่" Class="primary" />
    <base-tag Caption="ใช้งานอยู่" Class="warning" TagIcon="icon-pen-edit"/>
    <base-tag Caption="ใช้งานอยู่" Class="error" TagIcon="icon-pen-edit" />
    <br /><br />
    <base-sidebar
      :isVisibleSidebar="visibleRight"
      @VisibleSidebar="setvisibleSidebar"
    >
      <template v-slot:SidebarHeader>      
        <span class="text-3xl"><b> รหัสบัญชี SCB-SA 001 </b></span> 
        <base-tag Caption="ใช้งานอยู่" Class="success" />
      </template>
      <base-card
        :titleHeader="lang.projectTitleGeneral"
        :isVisibleExpandButton="false"
        :isHideBackground="true"
        :isVisibleCard="true"
        :isVisibleIconButton="true"
        :screenSizeRegular="false"
        class="mt-2"
      >
        <base-mobile-table :dataMobileTable="mobileTable"> </base-mobile-table>
      </base-card>
      <base-card
        :titleHeader="lang.projectTitleGeneral"
        :isVisibleExpandButton="false"
        :isHideBackground="true"
        :isVisibleCard="true"
        :isVisibleIconButton="true"
        :screenSizeRegular="false"
        class="mt-2"
      >
        <base-mobile-table :dataMobileTable="mobileTable"> </base-mobile-table>
      </base-card>
      <base-card
        :titleHeader="lang.projectTitleGeneral"
        :isVisibleExpandButton="false"
        :isHideBackground="true"
        :isVisibleCard="true"
        :isVisibleIconButton="true"
        :screenSizeRegular="false"
        class="mt-2"
      >
        <base-mobile-table :dataMobileTable="mobileTable"> </base-mobile-table>
      </base-card>
      <base-card
        :titleHeader="lang.projectTitleGeneral"
        :isVisibleExpandButton="false"
        :isHideBackground="true"
        :isVisibleCard="true"
        :isVisibleIconButton="true"
        :screenSizeRegular="false"
        class="mt-2"
      >
        <base-mobile-table :dataMobileTable="mobileTable"> </base-mobile-table>
      </base-card>
    </base-sidebar>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
	setup() {
		const { lang } = useLang();
		const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
		const { formatCurrency, formatDate, formatDateToISOString, formatISODateToLocaleDateString } =
			useFormat();
		const store = useStore();
		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return {
					...lang.th.base,
					...lang.th.master.project.edit,
				};
			} else {
				return {
					...lang.en.base,
					...lang.en.master.project.edit,
				};
			}
		});

		return {
			lang: mergeLang,
			textHeaderClass,
			textFormClass,
			textTableClass,
			formatCurrency,
			formatDate,
			formatDateToISOString,
			formatISODateToLocaleDateString,
		};
	},
	data() {
		return {
			visibleRight: false,
			mobileTable: [
				{
					data: [
						{
							name: "เดอะ ลีฟวิ่ง โอเอส",
							value: "012-23898479-56-8",
						},
						{
							name: "ธนาคารกสิกรไทย",
							value: "พระราม 9",
						},
					],
				},
				{
					data: [
						{
							name: "รอบการฝาก",
							value: "30 วัน",
						},
					],
				},
				{
					data: [
						{
							name: "ประเภทบัญชี",
							value: "กระแสรายวัน",
						},
					],
				},
			],
		};
	},
	methods: {
		setvisibleSidebar(data) {
			this.visibleRight = data;
		},
	},
};
</script>
