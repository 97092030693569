import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export default {
    namespaced: true,
    state(){
        return {
            addNoticeByUnit: null,
            notices: [],
            notice: null,
            totalPage: 0,
            currentPage: 1,
            rows: 100,
            totalListPage: 0,
            noticesGroupDate: [],
            noticesByAtDate: [],
        }
    },
    actions,
    getters,
    mutations
}