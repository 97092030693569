<template>
  <base-toolbar
    :title="title"
    @goBack="goBack()"
    :dataButtons="dataButtonsToolbar"
    @deletePolicy="askForDeletePolicy()"
    @savePolicy="savePolicy()"
    @askForCreate="askForCreate()"
  ></base-toolbar>
  <base-container>
    <base-dialog
      v-model:visible="showConfirmDialog"
      :titleConfirm="confirmTitle"
      :wordingConfirm="confirmWording"
      :classTitle="confirmClassTitle"
      :dataButtons="buttonDialog"
      @confirm="deletePolicy()"
      @close="showConfirmDialog = false"
      @createPolicy="createPolicy()"
    ></base-dialog>

    <base-dialog
      v-model:visible="isShowDialogAddProduct"
      :titleConfirm="lang.dialogPolicyTitle"
      :dataButtons="buttonDialogAddPolicyDetail"
      :style="{ width: '50vw' }"
      @addProductToPolicyDetail="addProductToPolicyDetail()"
      @close="isShowDialogAddProduct = false"
    >
      <template #dialogBody>
        <section class="flex flex-wrap mb-3">
          <div class="col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="selectedProduct">{{ lang.productCodeLabel }}</label>
            <PvSkeleton
              v-if="!selectProductReady"
              class="inputfield w-full mt-1"
              height="39px"
            />
            <base-product-auto-complete
              id="selectedProduct"
              v-model="selectedProduct"
              class="inputfield w-full mt-1"
              @load-finish="productReady()"
              :class="{ 'p-invalid': isDuplicateError }"
              @item-select="changeProductOrPeriod()"
              v-show="selectProductReady"
            />
            <small
              v-if="isDuplicateError"
              id="isDuplicateError-help"
              class="p-error"
            >
              {{ lang.validateProduct }}
            </small>
          </div>
          <div class="col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="detailPeriod">{{ lang.policyPeriodLabel }}</label>
            <base-period-auto-complete
              id="detailPeriod"
              v-model="detailPeriod"
              class="inputfield w-full mt-1"
              :isOrderby="true"
              :placeholder="lang.policyPeriodPlaceholder"
              :class="{ 'p-invalid': isDuplicateError }"
              @item-select="changeProductOrPeriod()"
            />
            <small
              v-if="isDuplicateError"
              id="isDuplicateError-help"
              class="p-error"
            >
              {{ lang.validatePeriod }}
            </small>
          </div>
          <div class="col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="policyCode">{{ lang.detailRemarkLabel }}</label>
            <PvInputText
              id="policyCode"
              type="text"
              class="inputfield w-full mt-1"
              v-model="detailRemark"
            />
          </div>
        </section>
      </template>
    </base-dialog>

    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5" :class="textFormClass">
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.title }}</label>
      </div>
      <PvDivider></PvDivider>
      <section class="flex flex-wrap mb-3">
        <div class="lg:col-3 md:col-4 col-6 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="policyCode">{{ lang.policyCodeLabel }}</label>
          <PvInputText
            id="policyCode"
            type="text"
            class="inputfield w-full mt-1"
            v-model="policyCode"
            :placeholder="lang.policyCodePlaceholder"
            :class="{ 'p-invalid': policyCodeError }"
            @keypress="policyCodeError = false"
          />
          <small v-if="policyCodeError" id="policy-code-help" class="p-error">
            {{ lang.validateCode }}
          </small>
        </div>
        <div class="lg:col-3 md:col-4 col-6 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="policyName">{{ lang.policyNameLabel }}</label>
          <PvInputText
            id="policyName"
            type="text"
            class="inputfield w-full mt-1"
            v-model="policyName"
            :placeholder="lang.policyNamePlaceholder"
            :class="{ 'p-invalid': policyNameError }"
            @keypress="policyNameError = false"
          />
          <small v-if="policyNameError" id="policy-name-help" class="p-error">
            {{ lang.validateName }}
          </small>
        </div>
      </section>
      <div class="flex flex-wrap justify-content-between align-items-center mt-5">
        <label class="font-lg font-normal">{{ lang.titleDetail }}</label>
        <div>
          <PvButton class="p-button-outlined ml-2" @click="addNewPolicyDetail()">
            <icon-plus-add
              class="p-button-outlined p-button-primary mr-2"
              iconColor="primary"
              height="16"
              width="16"
            />
            <span style="font-size: 14px">
              {{ lang.addNewPolicyDetail }}
            </span>
          </PvButton>
        </div>
      </div>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="policyDetailLists"
          v-model:selection="selectedPolicyDetail"
          selectionMode="single"
          dataKey="id"
          class="p-datatable-xs"
          :class="textTableClass"
          responsiveLayout="scroll"
          scrollable
          @row-dblclick="editPolicyDetail(selectedPolicyDetail)"
        >
          <template #empty>
            <div
              style="height: 56px"
              class="text-center flex align-items-center justify-content-center w-full"
            >
              <label class="font-normal">
                {{ lang.emptyPolicy }}
              </label>
            </div>
          </template>
          <PvColumn
            field="itemNo"
            :header="lang.columnItemNo"
            style="min-width: 2rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="productCode"
            :header="lang.columnCode"
            style="min-width: 2rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="productName"
            :header="lang.columnName"
            style="min-width: 25rem"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="period"
            :header="lang.columnPeriod"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="remark"
            :header="lang.columnRemark"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            style="min-width: 3rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: end; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="editPolicyDetail(slotProps.data)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0"
                @click="deletePolicyDetail(slotProps.data.id)"
              >
                <icon-trash-delete-bin iconColor="secondary" />
              </PvButton>
            </template>
          </PvColumn>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>
<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import baseProductAutoComplete from "../../../components/master/unitProduct/BaseProductAutoComplete.vue";
import usePageDirty from "../../../hooks/pageDirty";

export default {
  props: ["id", "projectCode"],
  components: {
    baseProductAutoComplete,
  },
  setup() {
    const { lang } = useLang();
    const { textFormClass, textTableClass } = useTextStyle();
    const { formatDate } = useFormat();
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();
    const { compareObject, isCompareObject } = usePageDirty();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.master.policy.edit };
      } else {
        return { ...lang.en.base, ...lang.en.master.policy.edit };
      }
    });

    return {
      lang: mergeLang,
      textFormClass,
      textTableClass,
      formatDate,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      compareObject,
      isCompareObject,
    };
  },
  data() {
    return {
      showConfirmDialog: false,
      confirmTitle: "",
      confirmWording: "",
      confirmClassTitle: "",
      policyCode: "",
      policyName: "",
      policyDetailLists: [],
      selectedPolicyDetail: null,
      isShowDialogAddProduct: false,
      selectedProduct: null,
      detailPeriod: [],
      detailRemark: "",
      selectProductReady: false,
      isConfirm: true,
      isEditProduct: false,
      selectedDetail: null,
      isDeleteButton: false,
      objToCompare: [],
      dataTocCheck: null,

      policyCodeError: false,
      policyNameError: false,
      isDuplicateError: false,
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    if (!this.isCreateDocument) {
      await this.getPolicyById();
    }
    await this.checkShouldSetData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "master-policy"});
    },
    async initiateData() {
      this.policyDetailLists = [];
      this.policyCode = "";
      this.policyName = "";

      this.policyCodeError = false;
      this.policyNameError = false;
      this.isDuplicateError = false;
      await this.$store.dispatch("policy/resetPolicy");
      await this.$store.dispatch("policy/resetSelectedPolicy");
    },
    async setData() {
      this.policyCode = this.selectedPolicy.code;
      this.policyName = this.selectedPolicy.name;
      this.policyDetailLists = this.selectedPolicy.policyDetails;
      await this.updateItemNo();

      this.setLoading(false);
    },
    async setCompareData() {
      let objToCompare = {
        id: this.id,
        projectId: this.selectedProject.id,
        code: this.policyCode,
        name: this.policyName,
        policyDetails: this.policyDetailLists,
      };
      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
    },
    async checkShouldSetData() {
      if (this.isSharedRoleByMenuReady)
      {
        if (this.selectedPolicy) {
          await this.setData();
        }
        else if (this.isCreateDocument) {
          this.setLoading(false);
        }
        await this.setCompareData();
      }
    },
    async getPolicyById() {
      try {
        this.setLoading(true);
        await this.$store.dispatch("policy/getPolicyById", this.id);
        this.setLoading(false);
      } catch (error) {
        this.isDeleteButton = false;
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.setLoading(false);
      }
    },
    askForDeletePolicy() {
      if(!this.isRoleDelete) {
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateRoleDelete
        });
        return;
      }
      this.isDeleteButton = true;
      this.openAlertDialog({
        severity: "",
        title: this.lang.notificationDialogHeader,
        content: this.lang.askForDeletePolicy.replaceAll(
          "{policyCodeAndName}",
          this.selectedPolicy.codeAndName
        ),
        isConfirm: true,
      });
    },
    async deletePolicy() {
      try {
        this.showConfirmDialog = false;
        let payloadDelete = {
          id: this.id,
          projectId: this.selectedProject.id,
        };
        await this.$store.dispatch("policy/deletePolicy", payloadDelete);
        this.$router.push({name: "master-policy"});
      } catch (error) {
        this.showConfirmDialog = false;
        this.isDeleteButton = false;
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
      }
    },
    openAlertDialog(dataConfig) {
      if (dataConfig.severity === "") {
        this.confirmClassTitle = "";
      } else if (dataConfig.severity === "success") {
        this.confirmClassTitle = "p-text-success";
      } else if (dataConfig.severity === "error") {
        this.confirmClassTitle = "p-error";
      }
      this.confirmTitle = dataConfig.title;
      this.confirmWording = dataConfig.content;
      this.showConfirmDialog = true;
      this.isConfirm = dataConfig.isConfirm;
    },
    validateData() {
      let returnStatus = false;
      if (this.isCreateDocument && !this.isRoleInsert) {
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateRoleInsert
        });
        return true;
      } else if (!this.isCreateDocument && !this.isRoleUpdate) {
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateRoleUpdate
        });
        return true;
      }

      if(!this.policyCode) {
        this.policyCodeError = true;
        returnStatus = true;
      }

      if(!this.policyName) {
        this.policyNameError = true;
        returnStatus = true;
      }

      if (returnStatus) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.validateSaveTitle,
          content: this.wordingValidate()
        });
      }

      return returnStatus;
    },
    wordingValidate(){
      if(!this.policyCode) {
        return this.lang.validateFieldNoValue + this.lang.policyCodeLabel;
      }

      if(!this.policyName) {
        return this.lang.validateFieldNoValue + this.lang.policyNameLabel;
      }
    },
    async savePolicy() {
      if (this.validateData()) {
        return;
      }
      this.setLoading(true);
      try {
        this.isDeleteButton = false;
        if (this.isCreateDocument) {
          await this.$store.dispatch("policy/addPolicy", this.payload);
          this.openAlertDialog({
            severity: "success",
            title: this.lang.notificationDialogHeader,
            content: this.lang.saveSuccess,
            isConfirm: false,
          });
          this.$router.push({name: "master-policy-edit", params: {id: this.selectedPolicy.id}});
        } else {
          await this.$store.dispatch("policy/updatePolicy", this.payload);
          this.openAlertDialog({
            severity: "success",
            title: this.lang.notificationDialogHeader,
            content: this.lang.saveEditSuccess,
            isConfirm: false,
          });
        }
        this.setLoading(false);
      } catch (error) {
        this.isDeleteButton = false;
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error.message,
          isConfirm: false,
        });
        this.setLoading(false);
      }
    },
    async addProductToPolicyDetail() {
      let period = this.detailPeriod.name ? this.detailPeriod.name : this.detailPeriod;

      let isDuplicateProduct = false;
      let policyDuplicate = [];

      // if (this.policy.length <= 0) {
      //   await this.getPolicyList();
      // }
      await this.getPolicyList();
      let idNotCheck = null;
      let periodNotCheck = null;
      let productIdNotCheck = null;

      if (this.isEditProduct) {
        // idNotCheck = this.dataTocCheck.productId.trim();
        periodNotCheck = this.dataTocCheck.period.trim();
        productIdNotCheck = this.dataTocCheck.productId.trim();
        idNotCheck = this.dataTocCheck.id;
      }
      
      // for (let i = 0; i < this.policy.length; i++) {
      //   isDuplicateProduct = this.policy[i].policyDetails.find(
      //     (data) =>
      //       data.productId.trim() === this.selectedProduct.id.trim() &&
      //       data.period.trim() === period.trim() &&
      //       (idNotCheck != data.productId.trim() ||
      //         periodNotCheck != data.period.trim())
      //   );
      //   if (isDuplicateProduct) {
      //     policyDuplicate = this.policy[i];
      //     break;
      //   }
      // }

      //check duplicate in DB except selected
      const policyExceptSelected = this.policy.filter((policy) => policy.id != this.id);
      for (let i = 0; i < policyExceptSelected.length; i++) {
        isDuplicateProduct = policyExceptSelected[i].policyDetails.find(
          (data) =>
            data.productId.trim() === this.selectedProduct.id.trim() &&
            data.period.trim() === period.trim() &&
            (productIdNotCheck != data.productId.trim() ||
              periodNotCheck != data.period.trim() )
        );
        if (isDuplicateProduct) {
          policyDuplicate = policyExceptSelected[i];
          break;
        }
      }
      //check duplicate with selected
      if (!isDuplicateProduct) {
        isDuplicateProduct = 
          this.policyDetailLists.find((each) =>
            each.productId.trim() === this.selectedProduct.id.trim() &&
            each.period.trim() === period.trim() &&
            (productIdNotCheck != each.productId.trim() ||
            periodNotCheck != each.period.trim() || idNotCheck != each.id)
          );
        if (isDuplicateProduct) {
          policyDuplicate = {
            codeAndName: `${this.policyCode} : ${this.policyName}`
          };
        }
      }

      if (!isDuplicateProduct) {
        if (!this.isEditProduct) {
          let itemNo = this.policyDetailLists.length + 1;
          let detail = {
            headerId: this.id,
            id: "dummy" + itemNo,
            itemNo: itemNo,
            period: period,
            productId: this.selectedProduct.id,
            productCode: this.selectedProduct.code,
            productName: this.selectedProduct.nameThai,
            remark: this.detailRemark,
          };

          this.policyDetailLists.push(detail);
          this.isShowDialogAddProduct = false;
        } else {
          let period = this.detailPeriod.name
            ? this.detailPeriod.name
            : this.detailPeriod;

          let detailPayload = {
            headerId: this.id,
            id: this.selectedDetail.id,
            itemNo: this.selectedDetail.itemNo,
            period: period,
            productId: this.selectedProduct.id,
            productCode: this.selectedProduct.code,
            productName: this.selectedProduct.nameThai,
            remark: this.detailRemark,
          };
          this.selectedDetail = detailPayload;
          let i = this.policyDetailLists
            .map((item) => item.id)
            .indexOf(this.selectedDetail.id);
          this.policyDetailLists[i] = detailPayload;
          this.isShowDialogAddProduct = false;
        }
      } else {
        this.isDuplicateError = true;
        this.isDeleteButton = false;
        this.openAlertDialog({
          severity: "error",
          title: this.lang.dialogDuplicateTitle,
          content: this.lang.dialogDuplicateMassage
            .replaceAll("{policyCodeName}", policyDuplicate.codeAndName)
            .replaceAll("{itemNo}", isDuplicateProduct.itemNo),
          isConfirm: false,
        });
      }
    },
    async updateItemNo() {
      let number = 1;
      if (this.policyDetailLists) {
        this.policyDetailLists.forEach((element) => {
          element.itemNo = number;
          number++;
        });
      }
    },
    addNewPolicyDetail() {
      this.isDuplicateError = false;
      this.isShowDialogAddProduct = true;
      this.isEditProduct = false;
      this.selectedProduct = null;
      this.detailPeriod = null;
      this.detailRemark = "";
      this.selectedDetail = null;
    },
    editPolicyDetail(detail) {
      this.isDuplicateError = false;
      this.isShowDialogAddProduct = true;
      this.isEditProduct = true;

      this.detailPeriod = detail.period;
      this.detailRemark = detail.remark;
      this.selectedDetail = detail;
      this.dataTocCheck = detail;
    },
    async deletePolicyDetail(id) {
      let i = this.policyDetailLists.map((item) => item.id).indexOf(id);
      this.policyDetailLists.splice(i, 1);
      await this.updateItemNo();
    },
    productReady() {
      this.selectProductReady = true;
      if (this.selectedDetail) {
        this.selectedProduct = this.products.find(
          (data) => data.id === this.selectedDetail.productId
        );
      }
    },
    async createPolicy() {
      this.showConfirmDialog = false;
      await this.initiateData();
      await this.setCompareData();
      this.$router.push({name: "master-policy-edit", params: {id: "create"}});
    },
    async askForCreate() {
      if(!this.isRoleInsert) {
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateRoleInsert
        });
        return;
      }
      let result = this.isCompareObject(this.objToCompare, this.payload);
      if (result) {
        this.isDeleteButton = false;
        this.openAlertDialog({
          severity: "",
          title: this.lang.createNewPolicy,
          content: [
            this.lang.askForCreateNewPolicy,
            this.lang.askForCreateNewPolicyConfirm,
          ],
          isConfirm: true,
        });
      } else {
        await this.createPolicy();
      }
    },
    async getPolicyList() {
      try {
        await this.$store.dispatch("policy/getAllPolicy");
      } catch (error) {
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
      }
    },
    changeProductOrPeriod() {
        this.isDuplicateError = false;
    }
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    roleByBook() {
      return this.getRoleByMenuByCode("erp_policy");
    },
    policy() {
      return this.$store.getters["policy/policy"];
    },
    selectedPolicy() {
      return this.$store.getters["policy/selectedPolicy"];
    },
    payload() {
      let payload = {
        id: this.id,
        projectId: this.selectedProject.id,
        code: this.policyCode,
        name: this.policyName,
        policyDetails: this.policyDetailLists,
      };
      return payload;
    },
    dataButtonsToolbar() {
      return [
        {
          id: "deleteCaption",
          action: "deletePolicy",
          isDisable: this.isCreateDocument,
          type: "delete"
        },
        {
          id: "askForCreate",
          action: "askForCreate",
          type: "create"
        },
        {
          id: "saveCaption",
          action: "savePolicy",
          type: "save"
        },
      ];
    },
    buttonDialog() {
      if (this.isConfirm) {
        if (this.isDeleteButton) {
          return [
            {
              id: "close",
              caption: this.lang.close,
              class: "p-button-outlined p-button-secondary w-full",
              action: "close",
            },
            {
              id: "confirmCaption",
              caption: this.lang.buttonDialogConfirm,
              action: "confirm",
              class: "w-full",
            },
          ];
        } else {
          return [
            {
              id: "closeCaption",
              caption: this.lang.close,
              action: "close",
              class: "p-button-outlined p-button-secondary w-full",
            },
            {
              id: "createCaption",
              caption: this.lang.buttonDialogConfirm,
              action: "createPolicy",
              class: "w-full",
            },
          ];
        }
      } else {
        return [
          {
            id: "closeCaption",
            caption: this.lang.close,
            action: "close",
            class: "p-button-outlined p-button-secondary w-full",
          },
        ];
      }
    },
    buttonDialogAddPolicyDetail() {
      let isDisableSave = true;
      if (this.detailPeriod && this.selectedProduct) {
        isDisableSave = false;
      }

      return [
        {
          id: "closeCaption",
          caption: this.lang.close,
          action: "close",
          class: "w-full p-button-outlined p-button-secondary",
        },
        {
          id: "addProductToPolicyDetailCaption",
          caption: this.lang.addProductToPolicyDetail,
          action: "addProductToPolicyDetail",
          class: "w-full",
          isDisable: isDisableSave,
        },
      ];
    },
    isCreateDocument() {
      return this.id === "create";
    },
    products() {
      let itemList = [];
      let productArs = this.$store.getters["shared/productArProducts"];
      if (productArs) {
        productArs.forEach((element) => {
          //   console.log("productArs", element);
          if (element.type === "N") {
            element = {
              ...element,
              ...{ codeAndName: element.code + " : " + element.nameThai },
            };
            itemList.push(element);
          }
        });
      }
      return itemList;
    },

    isRoleSelect() {
      if (this.roleByBook && this.roleByBook.select == "Y") {
        return true;
      }
      return false;
      // return true;
    },
    isRoleInsert() {
      if (this.roleByBook && this.roleByBook.insert == "Y") {
        return true;
      }
      return false;
      // return true;
    },
    isRoleUpdate() {
      if (this.roleByBook && this.roleByBook.update == "Y") {
        return true;
      }
      return false;
      // return true;
    },
    isRoleDelete() {
      if (this.roleByBook && this.roleByBook.delete == "Y") {
        return true;
      }
      return false;
      // return true;
    },
    title() {
      let result = this.lang.titleCreate;
      if (!this.isCreateDocument) {
        result = this.lang.titleEdit;
      }
      return result;
    },
  },
  watch: {
    selectedPolicy() {
      this.checkShouldSetData();
    },
    isSharedRoleByMenuReady() {
      this.checkShouldSetData();
    },
  },
};
</script>
