export default {
  async getProductAPs(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Product/AP";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get productAP");
      throw error;
    }
    // console.log('productAP',responseData);

    const productAP = {
      products: responseData.data,
      // totalPage: responseData.page.pageCount,
			totalPage: responseData.page.pageCount == 0 ? 1 : responseData.page.pageCount,
      currentPage: responseData.page.currentPage,
    };
    context.commit("setProductAP", productAP);
  },
  async getAllProductAPs(context) {
    
      while (!context.getters.productApIsLastPage) {
        let where = {
          projectId: context.rootGetters.selectedProject.id,
        };
        const payload = {
          page: context.getters.productApNextPage || 1,
          where,
        };
        await context.dispatch("getProductAPs", payload);
      }
    
  },
  resetProductAP(context) {
    context.commit("resetProductAP");
  },
};
