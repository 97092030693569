<template>
	<base-container>
		<base-ap-book-modal
			v-model:visible="showAPBookModal"
			:search="searchText"
			:isSharedLoading="isSharedAPBookReady"
			@close="showAPBookModal = false"
			@apBook-selected="apBookModalSelected"></base-ap-book-modal>

		<base-form-print-modal
			v-model:visible="showFormPrintModal"
			:search="searchTextPrint"
			@close="showFormPrintModal = false"
			@path-selected="formPrintModalSelected"></base-form-print-modal>

		
		<base-reference-document-dialog  
		v-model:visible="showReferenceDocument" 
		@close="showReferenceDocument = false"
		:dataTable="purchaseReceiptReference"
		>
		</base-reference-document-dialog>	

		<div class="flex justify-content-between align-items-center" style="padding-top: 20px">
			<div class="my-3">
				<PvButton
					icon="pi pi-chevron-left"
					:label="lang.back"
					@click="goBack"
					class="mr-2 p-button-secondary"
					v-if="isHideBack" />
			</div>
			<h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
		</div>

		<div class="surface-card shadow-2 p-3 border-round">
			<div class="pt-2 pb-1">
				<PvButton
					icon="pi pi-file"
					:label="lang.create"
					@click="gotoEdit('create')"
					class="mr-2 mb-2" />
				<PvButton
					icon="pi pi-list"
					:label="parameterButtonText"
					@click="toggleParameterVisible"
					class="p-button-help mr-2 mb-2" />
				<PvButton
					icon="pi pi-replay"
					:label="lang.changeBook"
					@click="changeBook"
					class="mr-2 mb-2" />
			</div>

			<transition>
				<div v-if="isParameterVisible">
					<section class="flex flex-wrap" :class="textFormClass">
						<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
							<label for="dateStart">{{ lang.dateStart }}</label>
							<PvCalendar
								id="dateStart"
								v-model="dateStart"
								class="inputfield w-full mt-1"
								showIcon
								dateFormat="dd/mm/yy" />
						</div>
						<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
							<label for="dateEnd">{{ lang.dateEnd }}</label>
							<PvCalendar
								id="dateEnd"
								v-model="dateEnd"
								class="inputfield w-full mt-1"
								showIcon
								dateFormat="dd/mm/yy" />
						</div>
						<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
							<label for="codeStart">{{ lang.codeStart }}</label>
							<PvInputText
								id="codeStart"
								type="text"
								class="inputfield w-full mt-1"
								placeholder="6501xxxxx"
								v-model="codeStart" />
						</div>
						<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
							<label for="codeEnd">{{ lang.codeEnd }}</label>
							<PvInputText
								id="codeEnd"
								type="text"
								class="inputfield w-full mt-1"
								placeholder="6501xxxxx"
								v-model="codeEnd" />
						</div>
						<PvButton
							icon="pi pi-search"
							:label="lang.search"
							@click="getPurchaseReceiptList()"
							class="mr-2 mb-2" />
					</section>
				</div>
			</transition>

			<PvDataTable
				showGridlines
				:value="purchaseReceiptLists"
				v-model:selection="selectedReceipt"
				selectionMode="single"
				dataKey="receiptId"
				responsiveLayout="scroll"
				:stripedRows="true"
				class="p-datatable-sm"
				:class="textTableClass"
				:loading="isLoading"
				@row-dblclick="gotoEdit(this.selectedReceipt.receiptId)">
				<template #empty>
				<div class="w-full p-3" Style="text-align: center;">
					<label>{{ lang.tableEmpty }}</label>
				</div>
				</template>
				<PvColumn
					field="receiptPostStep"
					:header="lang.columnPostStep"
					style="min-width: 2rem"
					headerClass="h-center"
					bodyClass="text-center"
					sortable></PvColumn>

				<PvColumn
					field="receiptCancelStatus"
					:header="lang.columnCancelStatus"
					style="min-width: 2rem"
					headerClass="h-center"
					bodyClass="text-center"
					sortable></PvColumn>

				<PvColumn
					field="receiptCode"
					:header="lang.columnCode"
					style="min-width: 4rem"
					headerClass="h-center"
					sortable></PvColumn>

				<PvColumn
					field="receiptRefCode"
					:header="lang.columnReferenceCode"
					style="min-width: 4rem"
					headerClass="h-center"
					sortable></PvColumn>

				<PvColumn
					field="receiptDate"
					:header="lang.columnDate"
					style="min-width: 3rem"
					headerClass="h-center"
					bodyClass="text-center"
					sortable>
					<template #body="slotProps">
						{{ formatDate(slotProps.data.receiptDate) }}
					</template>
				</PvColumn>

				<PvColumn
					field="receiptSupplierCode"
					:header="lang.columnSupplierCode"
					style="min-width: 5rem"
					headerClass="h-center"
					sortable></PvColumn>

				<PvColumn
					field="receiptSupplierName"
					:header="lang.columnSupplierName"
					style="min-width: 6rem"
					headerClass="h-center"
					sortable></PvColumn>

				<PvColumn
					field="receiptNetAmount"
					:header="lang.columnReceiptNetAmount"
					style="min-width: 4rem"
					headerClass="h-center"
					bodyClass="text-right"
					sortable>
					<template #body="slotProps">
						{{ formatCurrency(slotProps.data.receiptNetAmount) }}
					</template>
				</PvColumn>

				<PvColumn
					field="receiptActualPayAmount"
					:header="lang.columnreceiptActualPayAmount"
					style="min-width: 4rem"
					headerClass="h-center"
					bodyClass="text-right"
					sortable>
					<template #body="slotProps">
						{{ formatCurrency(slotProps.data.receiptActualPayAmount) }}
					</template>
				</PvColumn>

				<!-- <PvColumn
					field="currencyUnit"
					:header="lang.columnCurrencyUnit"
					style="min-width: 2rem"
					headerClass="h-center"
					bodyClass="text-center"
					sortable
				></PvColumn> -->
   
				<PvColumn
				:header="lang.referenceDocument"
				style="width:  5%"
				headerClass="h-center"
				bodyClass="text-center"
				>
				<template #body="slotProps">
					<PvButton
					type="button"
					class="p-button-secondary p-button-text py-0"
					icon="pi pi-eye"
					@click="gotoReferenceDocument(slotProps.data.receiptId)"
					></PvButton>
				</template>
				</PvColumn>
				<PvColumn>
					<template #body="slotProps">
						<PvButton
							type="button"
							class="p-button-secondary p-button-text py-0"
							icon="pi pi-pencil"
							@click="gotoEdit(slotProps.data.receiptId)"></PvButton>
						<PvButton
							type="button"
							class="p-button-secondary p-button-text py-0"
							icon="pi pi-print"
							@click="selectPrintForm(slotProps.data)"></PvButton>
					</template>
				</PvColumn>

				<template #footer>
					<PvPaginator
						:rows="rowsPerPage"
						:totalRecords="totalRecords"
						@page="onPageChange($event)"
						:rowsPerPageOptions="[10, 50, 100]"
						:first="showCurrentPage">
						<template #start="slotProps">
							{{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
						</template>
					</PvPaginator>
				</template>
			</PvDataTable>
		</div>
	</base-container>
</template>
<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useGetAPBook from "../../../hooks/getAPBook.js";
export default {
	props: ["apBook", "projectCode"],
	setup() {
		const { lang } = useLang();
		const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
		const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
		const { getFormPrints, searchTextPrint, isSharedFormPrintReady, getPreviewBlob, filteredFormPrints } =
			useGetFormPrint();
		const { isSharedAPBookReady, searchText, setBookCode } = useGetAPBook();
		const store = useStore();
		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return { ...lang.th.base, ...lang.th.ap.purchaseReceipt.listing };
			} else {
				return { ...lang.en.base, ...lang.en.ap.purchaseReceipt.listing };
			}
		});

		const isHideBack = inject("isHideBack");

		return {
			lang: mergeLang,

			textHeaderClass,
			textFormClass,
			textTableClass,

			formatCurrency,
			formatDate,
			formatDateToISOString,

			isSharedAPBookReady,
			searchText,
			setBookCode,

			getFormPrints,
			searchTextPrint,
			isSharedFormPrintReady,
			getPreviewBlob,
			filteredFormPrints,

			isHideBack,
		};
	},
	data() {
		return {
			isLoading: false,
			selectedBook: null,
			showAPBookModal: true,

			isParameterVisible: true,
			dateStart: "",
			dateEnd: "",
			codeStart: "",
			codeEnd: "",

			selectedReceipt: [],

			selectedPage: 1,
			rowsPerPage: 10,

			dataPrint: [],
			path: "",
			showFormPrintModal: false,
			bookModalSelected: null,
			selectedBookId: null,
			selectedBookCode: null,
			showReferenceDocument: false,
			purchaseReceiptReference: [],
			storageName: "",
		};
	},
	async created() {
		let date = new Date();
		this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
		this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

		this.storageName = `erp_search_ap_purchase_receiptList_${this.selectedProject.code}_${this.userCode}`;
		const localSearch = localStorage.getItem(this.storageName);
		if (localSearch) {
			let search = JSON.parse(localSearch);
			// console.log("localSearch", search);

			if (search.BankEntry === "Entry") {
				if (search.dateStart != null && search.dateStart != "") {
					this.dateStart = new Date(search.dateStart);
				} else this.dateStart = "";

				if (search.dateEnd != null && search.dateEnd != "") {
					this.dateEnd = new Date(search.dateEnd);
				} else this.dateEnd = "";

				this.codeStart = search.codeStart || this.codeStart;
				this.codeEnd = search.codeEnd || this.codeEnd;

				this.rowsPerPage = search.rowsPerPage || this.rowsPerPage;
          		this.selectedPage = search.selectedPage || this.selectedPage;

				search.BankEntry = "";

				localStorage.setItem(this.storageName, JSON.stringify(search));
			}
			this.selectedBookCode = search.documentBookCode;
			this.selectedBookId = search.documentBookId;
			this.bookModalSelected = JSON.parse(search.bookModalSelected);
		}

		this.setBookCode("PT,PZ");

		if (this.apBook) {
			this.showAPBookModal = false;
			await this.getPurchaseReceiptList();
		} else {
			this.showAPBookModal = true;
		}
	},
	methods: {
		setLoading(bool) {
			this.$store.dispatch("setLoading", { value: bool });
		},
		goBack() {
			localStorage.removeItem(this.storageName);
			this.$router.push({name: "home"})
		},
		toggleParameterVisible() {
			this.isParameterVisible = !this.isParameterVisible;
		},
		gotoEdit(receiptID) {
			// console.log("gotoEdit", receiptID);
			this.$router.push({name: "ap-purchase-receipt-edit", params: {apBook: this.selectedBookId, id: receiptID}})
		},
		changeBook() {
			// console.log("changeBook");
			this.setBookCode("PT,PZ");
			this.showAPBookModal = true;
		},
		async getPurchaseReceiptList() {
			this.isLoading = true;
			try {
				// console.log("getPurchaseReceiptList");

				let where = {
					projectId: this.selectedProject.id,
					receiptDateStart: this.selectedDateStart,
					receiptDateEnd: this.selectedDateEnd,
					receiptCodeStart: this.codeStart || null,
					receiptCodeEnd: this.codeEnd || null,
					documentBookCode: this.selectedBookCode,
					documentBookId: this.selectedBookId,
				};

				const payload = {
					page: this.selectedPage,
					limit: this.rowsPerPage,
					where,
				};

				// console.log("getPurchaseReceiptList", payload);
				await this.$store.dispatch("apPurchaseReceipt/getPurchaseReceipts", payload);

				where = {
					...where,
					dateStart: this.dateStart,
					dateEnd: this.dateEnd,
					bookModalSelected: JSON.stringify(this.bookModalSelected),
					rowsPerPage: this.rowsPerPage,
					selectedPage: this.selectedPage,
				};

				localStorage.setItem(this.storageName, JSON.stringify(where));

				this.isLoading = false;
			} catch (error) {
				this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				this.isLoading = false;
			}
		},
		async onPageChange(event) {
			this.selectedPage = event.page + 1;
			this.rowsPerPage = event.rows;
			await this.getPurchaseReceiptList();
		},
		async selectPrintForm(data) {
			this.setLoading(true);
			try {
				this.dataPrint = data;
				await this.$store.dispatch("shared/resetFormPrint");

				let where = {
					projectId: this.selectedProject.id,
					documentBookCode: this.bookModalSelected.bookCode,
				};
				const payload = { where };

				await this.getFormPrints(payload);

				if(this.filteredFormPrints.length == 1) {
					this.path = this.filteredFormPrints[0].path;
					await this.printPurchaseReceipt();
				}else{
					this.showFormPrintModal = true;
				}

				this.setLoading(false);
			} catch (error) {
				this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				this.setLoading(false);
			}
		},
		async formPrintModalSelected(event) {
			// console.log(event);
			this.setLoading(true);
			this.path = event.path;

			await this.printPurchaseReceipt();

			this.showFormPrintModal = false;
			this.setLoading(false);
		},
		async printPurchaseReceipt() {
			const payLoad = {
				Id: this.dataPrint.receiptId,
				path: this.path,
				EmployeeName: this.selectedUserName.createBy,
			};
			// console.log(this.selectedUserName.createBy);
			try {
				await this.$store.dispatch("apPurchaseReceipt/printPurchaseReceipt", payLoad);
				let blob = this.$store.getters["apPurchaseReceipt/blob"];
				this.getPreviewBlob(blob);
			} catch (error) {
				this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
			}
		},
		async apBookModalSelected(data) {
			// console.log("apBookModalSelected=", data);
			this.$router.push({name: "ap-purchase-receipt-apbook", params: {apBook: data.bookId}})
			this.bookModalSelected = data;
			this.selectedBookId = data.bookId;
			this.selectedBookCode = data.bookCode;

			await this.getPurchaseReceiptList();
			this.showAPBookModal = false;
		},
        async gotoReferenceDocument(id){
			this.setLoading(true);
			try {
				const payload = {
				ProjectId: this.selectedProject.id,
				PurchaseReceiptId: id
				};
				
				await this.$store.dispatch("apPurchaseReceipt/getPurchaseReceiptReference",payload);
				let dataReference = await this.$store.getters["apPurchaseReceipt/purchaseReceiptReference"];   
				
				this.purchaseReceiptReference = dataReference; 
				this.setLoading(false);       
				this.showReferenceDocument = true;
			} catch (error) {
				this.$swal({
				icon: "error",
				html: `<p class="${this.textAlertClass}">${error}</p>`,
				confirmButtonText: this.lang.close,
				allowOutsideClick: false,
				});
				this.setLoading(false);  
			}

		},
	},
	computed: {
		title() {
			if (this.selectedBookCode) {
				return this.lang.title + " : " + this.selectedBookCode;
			} else {
				return this.lang.title;
			}
		},
		parameterButtonText() {
			return this.isParameterVisible ? this.lang.hideParameters : this.lang.showParameters;
		},
		halfViewportHeight() {
			return this.$store.getters.halfViewportHeight;
		},
		selectedProject() {
			return this.$store.getters.selectedProject;
		},
		userCode() {
			return this.$store.getters.userCode;
		},
		totalPage() {
			return this.$store.getters["apPurchaseReceipt/totalPage"];
		},
		totalRecords() {
			return this.totalPage * this.rowsPerPage;
		},
		selectedDateStart() {
			let dateStart = null;
			if (this.dateStart) {
				dateStart = this.formatDateToISOString(this.dateStart);
			}
			return dateStart;
		},
		selectedDateEnd() {
			let dateEnd = null;
			if (this.dateEnd) {
				dateEnd = this.formatDateToISOString(this.dateEnd);
			}
			return dateEnd;
		},
		selectedUserName() {
			return this.$store.getters.employee;
		},

		purchaseReceiptLists() {
			return this.$store.getters["apPurchaseReceipt/purchaseReceipts"];
		},
		showCurrentPage() {
			let page = 0;
			page = this.rowsPerPage * (this.selectedPage - 1);
			return page;
		},
	},
	watch: {
		apBook(curValue) {
			if (curValue == null) {
				this.showAPBookModal = true;
			}
		},
	},
};
</script>
