import { useStore } from "vuex";
import { computed } from "vue";
export default function useGetImportExistResident() {

    const store = useStore();

    const params = computed(() => {
        return store.getters["importExistResident/params"];
    });

    const resetImportExistResident = () => {
        store.dispatch("importExistResident/resetImportExistResidents");
    };
  
    const setImportExistResidentParams = (data, field) => {
        const uniqueDatas = [...new Set(data.map(item => item[field]))];
        store.dispatch("importExistResident/setParams",uniqueDatas);
    };

    const loadImportExistResident = async (field = "customerCode",limit = 100) => {
        let payload = {
            field: field,
            limit: limit ,
        };
        if (params.value && params.value.length > 0) {
            await store.dispatch("importExistResident/getImportExistResidents", payload);
        }
    }    

    const getImportExistResident = () => {
        return store.getters["importExistResident/importExistResidents"];
    }

    return {
        resetImportExistResident,
        setImportExistResidentParams,
        loadImportExistResident,
        getImportExistResident,
    };
}