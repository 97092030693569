export default {
	createBudget: "สร้างงบประมาณ",
    editBudget: "แก้ไขงบประมาณ",
	settingBudget: "ปรับแต่งผังบัญชี",
	
	columnAccountCode: "รหัสผังบัญชี",
	columnAccountName: "รายการ",
	columnTotal: "รวม",
	searchNoDetail: "ไม่พบรายการที่ค้นหา โปรดใช้คำค้นหาอื่น",
	periodRange: "ช่วงงบประมาณ",
	clearDataBudget: "ล้างข้อมูลในตาราง",
	expandAll: "ขยายทั้งหมด",
	collapseAll: "ย่อทั้งหมด",
	averageAmount: "เฉลี่ยยอด",
	confirmSelectedAccount: "ยืนยันการปรับแต่งผังบัญชี",
	editSelectedAccount: "เนื่องจากมีการลบรายการบัญชีที่เลือกไว้ก่อนหน้า",
	validateChangeAccount: "คุณยืนยันที่จะบันทึกการปรับแต่งผังบัญชีใช่หรือไม่ ?",
	deleteAccount: "ลบรายการบัญชี",
	validateDeleteAccount: "คุณยืนยันที่จะลบรายการบัญชีนี้หรือไม่ ?",
	approved: "อนุมัติแล้ว",
	advice: "คำแนะนำ",
	tip: "คุณสามารถ กด shift และเลื่อน scroll mouse ขึ้น-ลง เพื่อเลื่อนดูเนื้อหาของตารางได้",
	approve: "อนุมัติ",	
    budgetSaved: "บันทึกรายการสำเร็จ",
	saveAndApprove: "บันทึกและอนุมัติ",
	approveBudget: "อนุมัติร่างงบประมาณ",
	validateApproveBudget: "คุณยืนยันที่จะอนุมัติหรือไม่",
	validateApproveWording: "หลังจากที่คุณอนุมัติแล้ว คุณจะไม่สามารถแก้ไขข้อมูลได้",
	validateNotApproved: "โปรดบันทึกรายการ ก่อนกดอนุมัติ",
	validateNotApprovedWording: "คุณยืนยันที่จะบันทึกการแก้ไขและอนุมัติหรือไม่",
	approveSuccess: "อนุมัติรายการสำเร็จ",
	validateClearBudgetWording: "คุณยืนยันที่จะล้างข้อมูลทั้งหมดในตารางใช่หรือไม่ ?",
	cancelBudget: "ยกเลิกร่างงบประมาณ",
	validateCancelBudgetWording: "คุณยืนยันที่จะยกเลิกร่างงบประมาณใช่หรือไม่ ?",
    cancelBudgetSuccess: "คุณยกเลิกรายการสำเร็จ",
	budgetDetail: "รายละเอียดงบประมาณ",
    periodBudgetFrom: "ช่วงงบประมาณ ตั้งแต่",
    periodBudgetTo: "ถึง",
	createDate:"สร้างวันที่",
	updateDate:"วันที่แก้ไขล่าสุด",
	cannotApprove: "ไม่สามารถอนุมัติได้",
	validateCannotApproveWording: "เนื่องจากในรายการผังบัญชี :account มีผลรวมเท่ากับ 0 ซึ่งคุณยังไม่ได้ระบุข้อมูล",
	titleAverageAmount: "กรอกยอดเฉลี่ย :month เดือน",
	labelAverageAmount: "ยอดที่จะนำไปเฉลี่ย",
	labelExplainAverageAmount: "ตัวอย่างยอดเฉลี่ยแต่ละเดือน",
	titleAverageAmountBudget: "แทนที่ข้อมูลเดิม ด้วยตัวเลขเฉลี่ย",
	averageAmountBudgetWording: "คุณยืนยันที่ใส่ตัวเลขเฉลี่ยแทนที่ใช่หรือไม่ ?",
	validateAverageAmount: "กรุณากรอก ยอดที่จะนำไปเฉลี่ย",
	validateDateStartAndLastDate: "วันที่เริ่มต้นต้องไม่น้อยกว่าวันที่ล่าสุดที่อนุมัติแล้ว",
	noItemToSaveBudgetWording: "ไม่สามารถบันทึกได้ เนื่องจากยังไม่ระบุผังบัญชี",
	noItemToApproveBudgetWording: "ไม่สามารถอนุมัติได้ เนื่องจากยังไม่ระบุผังบัญชี",
	noItemToClearBudgetWording: "ไม่สามารถล้างข้อมูลได้ เนื่องจากยังไม่ระบุผังบัญชี",
	contentConfirmSaveAndActive: "คุณยืนยันที่จะบันทึกการปรับแต่งงบประมาณ และแก้ไขสถานะการใช้งานหรือไม่?",
	dialogTitleUpdateActiveSuccess: "เปิดการใช้งานสำเร็จ",
	dialogContentUpdateActiveSuccess: "คุณเปิดการใช้งานรายการสำเร็จ",
	dialogTitleUpdateInactiveSuccess: "ยุติการใช้งานสำเร็จ",
	dialogContentUpdateInactiveSuccess: "คุณยุติการใช้งานรายการสำเร็จ",
	dialogContentPeriodAlreadyActive: "ไม่สามารถแก้ไขสถานะการใช้งาน เนื่องจากมีช่วงงบประมาณซ้ำกับงบประมาณที่เปิดใช้งานแล้ว",
	dialogContentSaveBeforeActive: "โปรดบันทึกรายการ ก่อนแก้ไขสถานะใช้งาน",
};
