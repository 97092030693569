<template>
  <PvDialog
    :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
    :style="{ width: '30vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="pt-2 pb-1">
        <label class="font-lg font-normal">{{ lang.updatePreviousUsed }}</label>
      </div>
    </template>

    <div class="pt-2 pb-2 mb-2">
      <label class="font-md font-normal p-text-secondary">{{ lang.from }}</label>
      <PvButton
        class="p-button-outlined p-button-text p-text-disabled button-text"
        disabled
      >
        <span class="p-button-label font-sm font-normal">{{
          dataMeterUsage.previousUsed
        }}</span>
      </PvButton>
      <label class="font-md font-normal p-text-secondary">{{ lang.to }}</label>
      <PvInputText
        id="previousUsed"
        type="text"
        :maxlength="5"
        class="inputfield button-text"
        v-model.trim="previousUsed"
        @focus="$event.target.select()"
        @keypress="checkInputNumber"
      />
    </div>

    <div>
      <base-input-text-limit
        :caption="lang.previousUsedReason + '*'"
        :limitMessage="50"
        :isError="isReasonError"
        :errorMessage="lang.validatePreviousUsedReason"
        :data="reason"
        :textHolder="lang.validatePreviousUsedReason"
        @data="updateReason"
      />
    </div>

    <template #footer>
      <div class="flex justify-content-between align-items-center">
        <PvButton
          class="p-button-outlined p-text-secondary ml-2"
          @click="closeDialog"
          :style="{ width: '50%', height: '48px' }"
        >
          <span class="p-button-label p-text-secondary font-md font-normal">{{
            lang.cancel
          }}</span>
        </PvButton>
        <PvButton
          @click="save"
          :style="{ width: '50%', height: '48px', opacity:1 }"
          :class="buttonConfirm"
          :disabled="!reason"
        >
          <span class="p-button-label font-md font-normal">{{
            lang.buttonDialogConfirm
          }}</span>
        </PvButton>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  props: {
    dataMeterUsage: {
      type: Object,
    },
  },
  emits: ["close", "save"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.meter.meterUsage.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.meter.meterUsage.listing,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
    };
  },
  data() {
    return {
      previousUsed: "",
      reason: "",
      isReasonError: false,
      meterUsages: null,
    };
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    initiateData() {
      this.previousUsed = "";
      this.reason = "";
      this.isReasonError = false;
    },
    checkShouldSetData() {
      if (this.dataMeterUsage) {
        this.setData();
      }
      this.setLoading(false);
    },
    setData() {
      this.meterUsages = this.dataMeterUsage;
      this.previousUsed = this.dataMeterUsage.previousUsed;
      this.reason = this.dataMeterUsage.updatePreviousUsedReason;
      this.isReasonError = false;
    },
    validateData() {
      if (!this.reason) {
        this.isReasonError = true;
        return true;
      }
      return false;
    },
    updateReason(data) {
      this.reason = data;
      this.isReasonError = false;
    },
    async save() {
      if (this.validateData()) {
        return;
      }

      this.meterUsages.updatePreviousUsedReason = this.reason;
      this.meterUsages.previousUsed = this.previousUsed;

      // console.log(this.meterUsages);
      this.$emit("save", this.meterUsages, true, this.meterUsages.currentUsed);
      this.$emit("close");
    },
    checkInputNumber(event) {
      // let text = ""
      const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const keyPressed = event.key;
      if (!keysAllowed.includes(keyPressed)) {
        event.preventDefault()
      }
    },
    closeDialog() {
      this.initiateData();
      this.$emit("close");
    },
  },
  computed: {
    selectedMeterUsage() {
      return this.$store.getters["meterUsage/selectedMeterUsage"];
    },
    buttonConfirm() {
      if(this.reason){
        return "p-button-primary ml-2"
      }
      else{
        return "p-button-search ml-2"
      }
    },
  },
  watch: {
    dataMeterUsage() {
      this.checkShouldSetData();
    },
  },
};
</script>

<style scoped>
.button-text {
  width: 80px; 
  text-align: right;
  margin-left: 8px;
  margin-right: 8px;
}
/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
/* input[type="number"] {
  -moz-appearance: textfield;
} */
</style>
