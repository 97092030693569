export default {
    title: "คำนวณหนังสือทวงถาม",
    validateInsert: "คุณไม่มีสิทธิ์คำนวณหนังสือทวงถาม",
    titleDetail: "รายละเอียดการคำนวณ",
    titleSelectDate: "กรุณาเลือกวันที่ที่ต้องการคำนวณหนังสือทวงถาม และวันครบกำหนดชำระ",
    calculateDate: "วันที่เริ่มคำนวณหนังสือทวงถาม (วันที่เอกสาร)",
    dueDate: "วันที่ครบกำหนด",
    calculatePenaltyDate: "วันที่สิ้นสุดการคำนวณค่าปรับ",
    validateCalculateDate: "วันที่เริ่มคำนวณจะต้องน้อยกว่าหรือเท่ากับวันที่ครบกำหนด",
    gotoCalculateNotice: "เริ่มคำนวณหนังสือทวงถาม",
    titleDetailCalculating: "ระบบกำลังคำนวณหนังสือทวงถาม",
    titleDetailCalculatingDesc: "กรุณารอสักครู่ หากสำเร็จแล้ว ระบบจะพาคุณไปในขั้นตอนต่อไป...",
    noticeChecklist: "รายการตรวจสอบหนังสือทวงถาม",
    cancelNotice: "ยกเลิกการคำนวณ",
    approveAll: "ยืนยันทั้งหมด",

    columnCode: "เลขที่เอกสาร",
    columnDate: "วันที่เอกสาร",
    columnUnitCode: "ยูนิต",
    columnAddressNo: "บ้านเลขที่",
    columnCustomerName: "ชื่อลูกค้า",
    columnNetAmount: "จำนวนเงิน",
    unitCode: "เลขที่ยูนิต",

    calculateNoticeDetail: "ตรวจสอบรายละเอียด",
    noticeDetail: "รายละเอียดหนังสือทวงถาม",
    noticeDetailList: "รายการใช้จ่าย",

    columnProductCode: "รหัสรายรับ",
    columnProductName: "ชื่อรายรับ",
    columnRemark: "รายละเอียด",
    columnPeriod: "งวด",
    columnUnitPrice: "ราคาต่อหน่วย",
    columnRatePenalty: "อัตราค่าปรับ",
    columnPenaltyDay: "จำนวนวันคิดค่าปรับ",
    columnAmount: "ยอดรวม",

    wordingConfirmCancelNotice: "คุณต้องการยกเลิกการคำนวณหนังสือทวงถามทั้งหมดใช่หรือไม่?",
    wordingConfirmSaveNotice: "คุณต้องการยืนยันข้อมูลหนังสือทวงถามทั้งหมดใช่หรือไม่?",
    wordingSaveSuccess: "คุณทำรายการคำนวณหนังสือทวงถามสำเร็จ",
    saveSuccess: "คุณทำรายการสำเร็จ",
    validateDueDate: "กรุณาเลือกวันที่ครบกำหนด",
    printConfirmNoticePenalty: "พิมพ์รายงานสรุปค่าปรับ/เงินเพิ่ม",
}