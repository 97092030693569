export default {
    debtFreeApproves(state) {
      return state.debtFreeApproves;
    },
    totalPage(state) {
      return state.totalPage;
    },
    currentPage(state) {
      return state.currentPage;
    },
    rows(state) {
      return state.rows;
    },
    selectedDebtFreeApprove(state){
      return state.selectedDebtFreeApprove;
    },
};
  