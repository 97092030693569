import { useStore } from "vuex";
import { ref, computed, onMounted} from "vue";
export default function useGetUnit() {
  const store = useStore();
  let searchText = ref(null);

  const units = computed(() => {
    return store.getters["shared/unitUnits"];
  });

  const unitIsLastPage = computed(() => {
    return store.getters["shared/unitIsLastPage"];
  });

  const filteredUnits = computed(() => {
    if (!searchText.value) {
      //ช่องว่าง
      const result = [...units.value];
      return result;
    } else {
      return units.value.filter((unit) => {
        return unit.codeAndAddressNo
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const searchUnit = function (event) {
    // console.log(event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchText.value = "";
      searchText.value = null;
    } else {
      searchText.value = event.query;
    }
  };

  let isSharedUnitReady = ref(false);

  const getAllUnits = async function () {
    if (!unitIsLastPage.value) {
      await store.dispatch("shared/getAllUnits");
    }
    isSharedUnitReady.value = true;
  };

  const getUnitByCode = function (code) {
    return units.value.find((unit) => {
      return unit.code.toLowerCase() === code.trim().toLowerCase();
    });
  };

  onMounted(async () => {
    await getAllUnits();
  });


  return {
    searchUnit,
    filteredUnits,
    searchText,
    getUnitByCode,
    isSharedUnitReady,
  };
}
