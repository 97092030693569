export default {
  title: "รายการใบเสร็จรับเงิน",
  addReceipt: "สร้างใบเสร็จรับเงิน",
  dateRange: "ช่วงวันที่",
  documentCode: "เลขที่เอกสาร",
  date: "วันที่เอกสาร",
  unit: "ยูนิต",
  address:"บ้านเลขที่",
  customerName: "ชื่อลูกค้า",
  price: "ราคา",
  paid: "จ่าย",
  payIn: "นำเข้าธนาคาร",
  refunded: "คืนเต็มจำนวน",
  splitRefunded: "แบ่งชำระคืน",
  paidCancel: "ยกเลิกการชำระ",
  cancel: "ยกเลิก",
  printOrVoucherCreated: "พิมพ์/ลงบัญชี",
  printed: "พิมพ์แล้ว",
  voucherCreated: "ลงบัญชี",
  columnCreateBy: "ผู้สร้าง",
  columnUpdateBy: "ผู้แก้ไข",
  receiptCode: "เลขที่ใบเสร็จ",
  columnCreateDate:"วันที่สร้าง",
  columnUpdateDate:"วันที่แก้ไขล่าสุด",   

	addItem: "เพิ่มรายการ",
	editItem: "แก้ไขรายการ",
  employeeName:"ชื่อพนักงาน",
	validateRoleSelect: "คุณไม่มีสิทธิ์ เข้าถึงเอกสาร",
	validateNoProductSelect: "กรุณาเลือกรายรับ",
	validateNoPeriod: "กรุณาเลือกงวด",
	confirmCancel: "ยืนยันยกเลิก",
	validateInactiveUnitSelect: "ห้องนี้ปิดการใช้งาน",
	searchTextPlaceholder: "กรอกข้อมูลเพื่อค้นหา",
	noDetail: "ไม่มีรายการ",
	cancelDocument: "ยกเลิกเอกสาร",
	other: "อื่นๆ",
  receiptPrintListPage: "พิมพ์รายการใบเสร็จ",
  buttonGotoVisitorControl: "แก้ไขประเภทชำระ Visitor",
  import: "นำเข้า",
  validateSearchText:"กรุณาระบุคำที่ต้องการค้นหา",
  tableEmptyList: "ไม่พบข้อมูล",

  billPayment: "Bill Payment",
};
