<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="titleConfirm"
      :classTitle="baseDialogClassTitle"
      :wordingConfirm="validateDialogWording"
      :dataButtons="dataButtonDialog"
      @close="showValidateDialog = false"
    />

    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ this.title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <section class="flex flex-wrap" :class="textFormClass">
      <div class="col-12 pl-0 pt-0">
        <label for="documentDate">{{ lang.documentDate }}</label>
        <PvCalendar
          id="documentDate"
          v-model="documentDate"
          class="inputfield w-full mt-1"
          inputClass="p-inputtext-disabled"
          showIcon
          dateFormat="dd/mm/yy"
          disabled
        />
      </div>

      <div class="col-12 pl-0">
        <label for="dear">{{ lang.dear }}</label>
        <PvInputText
          id="dear"
          type="text"
          class="inputfield w-full mt-1"
          v-model="dear"
        />
      </div>

      <div class="col-12 pl-0">
        <label>{{ lang.signature }}</label>
        <PvTextarea
          v-model="signature"
          rows="3"
          class="inputfield w-full mt-1"
        />
      </div>

      <!-- <div class="col-12 pl-0 flex justify-content-between">
        <div></div>
        <PvButton @click="updateForeignOwnershipRatioMessage">
          <icon-documents-file-checkmark iconColor="white" />
          <span class="font-normal font-normal font-sm w-full ml-1">{{ lang.save }}</span>
        </PvButton>
      </div> -->

      <!-- <div class="col-12 pl-0 lg:pr-4 md:pr-4"> -->
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="dataType">{{ lang.dataType }}</label>
        <div class="flex flex-wrap">
          <div
            v-for="dataType of dataTypes"
            :key="dataType.code"
            class="field-radiobutton pt-3 mr-5"
          >
            <PvRadioButton
              class="mr-2"
              :inputId="dataType.code"
              name="dataType"
              :value="dataType.code"
              v-model="selectedDataType"
            />
            <label :for="dataType.code">{{ dataType.name }}</label>
          </div>
        </div>
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="fileNameText">{{ lang.fileName }}</label>
        <PvInputText
          id="fileNameText"
          type="text"
          class="inputfield w-full mt-1"
          v-model="fileNameText"
        />
      </div>
    </section>

    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
        </div>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useTextStyle from "../../../hooks/textStyle.js";
import useLang from "../../../hooks/lang.js";
import usePageDirty from "../../../hooks/pageDirty";
import useTracking from "../../../hooks/tracking";
import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "toast-request"],
  setup() {
    const { lang } = useLang();
    const store = useStore();
    const { textFormClass } = useTextStyle();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
        };
      }
    });
    const { isCompareObject } = usePageDirty();
    return {
      lang: mergeLang,
      textFormClass,
      isCompareObject,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      documentDate: null,
      dear: "",
      signature: "",
      dataTypes: [
        { name: this.lang.ratio, code: "OwnerRatio" },
        { name: this.lang.area, code: "Area" },
      ],
      selectedDataType: "OwnerRatio",
      objToCompare: null,
      showValidateDialog: false,
      titleConfirm: this.lang.notificationDialogHeader,
      baseDialogClassTitle: "",
      validateDialogWording: "",
      type: "PDF",
      fileNameText: "foreign_ownership_ratio",
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    await this.getForeignOwnershipRatioMessage();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.initiateData();
      this.$emit("close");
    },
    async initiateData() {
      this.documentDate = new Date();
      this.dear = "";
      this.signature = "";
      this.selectedDataType = "OwnerRatio";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.baseDialogClassTitle = "";
      this.showValidateDialog = false;
      this.validateDialogWording = "";
      this.type = "PDF";
      this.fileNameText = "foreign_ownership_ratio";
    },
    async setData() {
      this.dear = this.foreignOwnershipRatioMessage.to;
      this.signature = this.foreignOwnershipRatioMessage.signature;
      this.setLoading(false);
    },
    async setCompareSignature() {
      let objToCompare = {
        projectId: this.selectedProject.id,
        to: this.dear? this.dear.trim() : "",
        signature: this.signature? this.signature.trim() : "",
      };
      this.objToCompare = objToCompare;
      // this.compareObject(this.objToCompare, this.payloadSignature);
    },
    checkCompareSignature() {
      let result = this.isCompareObject(this.objToCompare, this.payloadSignature);
      if (result) {
        return true;
      }
      return false;
    },
    async getForeignOwnershipRatioMessage() {
      try {
        let payload = {
          projectId: this.selectedProject.id,
        };
        await this.$store.dispatch("project/getForeignOwnershipRatioMessage", payload);
        await this.setData();
        await this.setCompareSignature();
      } catch (error) {
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.baseDialogClassTitle = "";
        this.validateDialogWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    async updateForeignOwnershipRatioMessage() {
      this.setLoading(true);
      try {
        await this.$store.dispatch("project/updateForeignOwnershipRatioMessage", this.payloadSignature);
        // await this.setData();
        await this.setCompareSignature();
        // this.titleConfirm = this.lang.saveSuccess;
        // this.baseDialogClassTitle = "p-text-success";
        // this.validateDialogWording = this.lang.saveMessageSuccess;
        // this.showValidateDialog = true;
      } catch (error) {
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.baseDialogClassTitle = "";
        this.validateDialogWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    async download(typeReport) {
      this.setLoading(true);
      if (this.isEditData) {
        await this.updateForeignOwnershipRatioMessage();
      }
			this.type = typeReport;
			try {
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
				const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
				this.setLoading(false);
			} catch (error) {
				this.$toast.add({
					severity: "error",
					summary: this.lang.notificationDialogHeader,
					detail: error,
					life: 3000,
				});
				this.setLoading(false);
			}
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    title() {
      if (this.$store.getters.selectedLanguage === "TH")
        return this.reportObj.nameThai;
      else return this.reportObj.nameEnglish;
    },
		userId() {
			return this.$store.getters.userId;
		},
		userName() {
			return this.$store.getters.userName;
		},
    payloadSignature() {
      let payload = {
        projectId: this.selectedProject.id,
        to: this.dear? this.dear.trim() : "",
        signature: this.signature? this.signature.trim() : "",
      };
      return payload;
    },
    foreignOwnershipRatioMessage() {
      return this.$store.getters["project/foreignOwnershipRatioMessage"];
    },
    isEditData() {
      return this.isCompareObject(this.objToCompare, this.payloadSignature);
    },
    fileName() {
			let fileName = this.type == "PDF" ? ".pdf" : ".xlsx";
			return this.fileNameText + fileName;
		},
		fileType() {
			let fileType =
				this.type == "EXCEL"
				? "_excel.xlsx"
				: this.type == "JASPEREXCEL"
				? ".xlsx"
				: ".pdf";
			return fileType;
		},
    payload() {
      let payload = {
        ProjectId: this.selectedProject.id,
          SelectedAreaOrOwnerRatio: this.selectedDataType,
        FileName: this.fileName,
				JasperPath: this.reportObj.path + this.fileType,
				DocType: this.type,
				UserId: this.userId,
				PrintBy: this.userName,
				RequestId: 0,
      };
      return payload;
    },
    dataButtonDialog() {
      return [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
    },
  },
  watch: {
    reportObj() {
      this.initiateData();
      this.getForeignOwnershipRatioMessage();
    },
  },
};
</script>
