export default {
    createPR: "สร้างใบขอซื้อ",
    editPR: "แก้ไขใบขอซื้อ",
    saveAs: "บันทึกเป็น",
    approve: "อนุมัติ",
    reApprove: "ถอยอนุมัติ",
    requestDate: "วันที่ต้องการ",
    quotationDate: "วันที่ยืนราคา",
    creditTerm: "เครดิตเทอม (วัน)",
    supplier:"ผู้จัดจำหน่าย",
    buyer: "ผู้ขอซื้อ",
    remark: "หมายเหตุ",
    deliveryDate: "กำหนดส่งสินค้า",
    payment: "การชำระเงิน",
    guarantee: "การรับประกัน",
    columnIsProduct: "ชนิด",
    columnProductCode: "รหัสสินค้า",
    columnProductName: "ชื่อสินค้า",
    columnProductUnitName: "ชื่อหน่วยนับ",
    columnUnitPrice: "ราคาต่อหน่วย",
    columnQuantity:"ปริมาณ",    
    columnTotalAmount: "รวม",
    currency: "สกุลเงิน",
    currencyName: "บาท",
    discount:"ส่วนลด",
    actualPayAmount: "เงินมัดจำ",
    totalAmount: "มูลค่ารวมทุกสินค้า",
    discountAmount: "มูลค่าส่วนลด",
    totalAmountBeforeTax: "มูลค่าก่อนภาษี",
    tax: "อัตราภาษี",
    taxAmount: "มูลค่าภาษี",
    netAmount: "มูลค่าสุทธิ",
    askForProductChange: "คุณยืนยันที่จะแก้ไขรหัสสินค้าใช่หรือไม่?",
    askForSelectProduct: "ไม่พบรหัสสินค้า : :code ต้องการค้นหาหรือไม่?",
    validateNoSupplierSelect: "กรุณาเลือกผู้จัดจำหน่าย",
	validateNoEmployeeSelect: "กรุณาเลือกพนักงาน",
    validateRemark: "กรุณาระบุหมายเหตุ",
    validateDetailSelect: "กรุณาเลือกรายการ",
    validateNoTaxSelect: "กรุณาเลือกภาษี",
    askForClosePR: "คุณยืนยันที่จะ ปิดใบขอซื้อนี้ ใช่หรือไม่?",
    closePRSuccess: "ปิดใบขอซื้อสำเร็จ",
    askForRevertApprove: "คุณยืนยันที่จะ ถอยอนุมัติเอกสารนี้ ใช่หรือไม่?",
    reApproveSuccess: "ถอยอนุมัติสำเร็จ",
    validateTotalAmount: "มูลค่ารวมทุกสินค้า ต้องมากกว่า 0",
    validateCheckDiscount: "รูปแบบส่วนลด ระบุได้เฉพาะตัวเลข และ %",
    validateDiscountAmount: "มูลค่าส่วนลด ต้องไม่เกินมูลค่าสุทธิ",
    calculatedFromSystem: "  *ค่าคำนวณที่ระบบแนะนำ : ",
};
