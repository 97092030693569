<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="assetRegister">{{ lang.assetCodeStart }}</label>
          <base-asset-register-auto-complete
            id="assetRegister"
            class="inputfield w-full mt-1"
            v-model="selectedAssetCodeStart"
            @load-finish="assetRegisterDataReady"
            :class="{ 'p-invalid': assetCodeStartError }"
            @item-select="assetCodeStartError = false"
          />
          <small
            v-if="assetCodeStartError"
            id="assetCodeStart-help"
            class="p-error"
            >{{ lang.validateAssetCodeSelect }}</small
          >
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="assetRegister">{{ lang.assetCodeEnd }}</label>
          <base-asset-register-auto-complete
            id="assetRegister"
            class="inputfield w-full mt-1"
            v-model="selectedAssetCodeEnd"
            @load-finish="assetRegisterDataReady"
            :class="{ 'p-invalid': assetCodeEndError }"
            @item-select="assetCodeEndError = false"
          />
          <small
            v-if="assetCodeEndError"
            id="assetCodeEnd-help"
            class="p-error"
            >{{ lang.validateAssetCodeSelect }}</small
          >
        </div>
				<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
					<label for="fileNameText">{{ lang.fileName }}</label>
					<PvInputText
						id="fileNameText"
						type="text"
						class="inputfield w-full mt-1"
						v-model="fileNameText"
					/>
				</div>
      </section>
    </transition>
    <template #footer>
      <div
				class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
			>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
				</div>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
				</div>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
				</div>
			</div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import { useStore } from "vuex";
import { computed } from "vue";
import BaseAssetRegisterAutoComplete from "../../components/Asset/BaseAssetRegisterAutoComplete.vue";
import useTracking from "../../hooks/tracking";
import useReportRequestValidate from "../../hooks/reportRequestValidate.js";
export default {
  components: {
    BaseAssetRegisterAutoComplete,
  },
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "toast-request"],
  setup() {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.asset.assetRegister.listing,
          ...lang.th.asset.assetRegister.edit,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.asset.assetRegister.listing,
          ...lang.en.asset.assetRegister.edit,
          ...lang.en.gl.reportGl,
        };
      }
    });
    return {
      lang: mergeLang,
      textFormClass,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      selectedAssetCodeStart: null,
      assetCodeStartError: false,
      selectedAssetCodeEnd: null,
      assetCodeEndError: false,
      isAssetRegisterReady: false,
			type: "PDF",
      fileNameText: "asset_detail",
    };
  },
  mounted() {
    this.setLoading(true);
    this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    initiateData() {
      this.selectedAssetCodeStart = null;
      this.selectedAssetCodeEnd = null;
      this.assetCodeStartError = false;
      this.assetCodeEndError = false;
			this.type = "PDF";
      this.fileNameText = "asset_detail";
    },
    assetRegisterDataReady() {
      this.isAssetRegisterReady = true;
      this.checkShouldSetData();
    },
    checkShouldSetData() {
      if (this.isAssetRegisterReady) {
        this.setData();
        this.setLoading(false);
      }
    },
    setData() {
      this.selectedAssetCodeStart = this.assets[0];
      this.selectedAssetCodeEnd = this.assets[this.assets.length - 1];
    },
    async download(typeReport) {
			this.type = typeReport;
			this.setLoading(true);
			try {
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
				const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
				this.setLoading(false);
			} catch (error) {
				this.$toast.add({
					severity: "error",
					summary: this.lang.notificationDialogHeader,
					detail: error,
					life: 3000,
				});
				this.setLoading(false);
			}
		},
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    title() {
      if (this.$store.getters.selectedLanguage === "TH")
        return this.reportObj.nameThai;
      else return this.reportObj.nameEnglish;
    },
		userId() {
        	return this.$store.getters.userId;
		},
		userName() {
			return this.$store.getters.userName;
		},
    assets() {
      return this.$store.getters["assetAssetRegister/assetRegisterDropDowns"];
    },
		fileName() {
			let fileName = this.type == "PDF" ? ".pdf" : ".xlsx";
			return this.fileNameText + fileName;
		},
		fileType() {
			let fileType =
				this.type == "EXCEL"
				? "_excel.xlsx"
				: this.type == "JASPEREXCEL"
				? ".xlsx"
				: ".pdf";
			return fileType;
		},
		payload() {
			let payload = {
				ProjectId: this.selectedProject.id,
          AssetCodeStart: this.selectedAssetCodeStart ? this.selectedAssetCodeStart.code : "",
          AssetCodeEnd: this.selectedAssetCodeEnd ? this.selectedAssetCodeEnd.code : "",
				FileName: this.fileName,
				JasperPath: this.reportObj.path + this.fileType,
				DocType: this.type,
				UserId: this.userId,
				PrintBy: this.userName,
				RequestId: 0,
			};
			return payload;
		},
  },
  watch: {
		reportObj() {
			this.initiateData();
		},
  },
};
</script>
