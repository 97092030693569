export default {
    setDebtFreeRequests(state, payload){
        state.debtFreeRequests = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },
    setSelectedDebtFreeRequest(state, payload){
        state.selectedDebtFreeRequest = payload
    },
    setSelectedUnit(state, payload){
        state.selectedUnit = payload
    }
};