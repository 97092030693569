<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ this.title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>
    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateAt">{{ lang.dateAt }}</label>
          <PvCalendar
            id="dateAt"
            v-model="dateAt"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateAtError }"
            @date-select="dateAtError = false"
          />
          <small v-if="dateAtError" id="unit-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0"></div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="unitStart">{{ lang.unitStart }}</label>
          <base-unit-auto-complete
            id="unit"
            v-model="selectedUnitStart"
            class="inputfield w-full mt-1"
            @load-finish="unitDataReady"
            :class="{ 'p-invalid': unitStartError }"
            @item-select="unitStartError = false"
          />
          <small v-if="unitStartError" id="unit-help" class="p-error">{{
            lang.validateNoUnitSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="unitEnd">{{ lang.to }}</label>
          <base-unit-auto-complete
            id="unit"
            v-model="selectedUnitEnd"
            class="inputfield w-full mt-1"
            @load-finish="unitDataReady"
            :class="{ 'p-invalid': unitEndError }"
            @item-select="unitEndError = false"
          />
          <small v-if="unitEndError" id="unit-help" class="p-error">{{
            lang.validateNoUnitSelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="productStart">{{ lang.productStart }}</label>
          <base-product-ar-auto-complete
            id="productStart"
            v-model="selectedProductStart"
            class="inputfield w-full mt-1"
            @load-finish="productDataReady"
            :class="{ 'p-invalid': productStartError }"
            @item-select="productStartError = false"
          />
          <small v-if="productStartError" id="product-help" class="p-error">{{
            lang.validateNoProductSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="productEnd">{{ lang.to }}</label>
          <base-product-ar-auto-complete
            id="productEnd"
            v-model="selectedProductEnd"
            class="inputfield w-full mt-1"
            @load-finish="productDataReady"
            :class="{ 'p-invalid': productEndError }"
            @item-select="productEndError = false"
          />
          <small v-if="productEndError" id="product-help" class="p-error">{{
            lang.validateNoProductSelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText
            id="fileNameText"
            type="text"
            class="inputfield w-full mt-1"
            v-model="fileNameText"
          />
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2"></div>

        <div class="lg:col-4 col-12 pl-0 pr-2 pt-3">
          <PvCheckbox
            id="isDocCode"
            inputId="binary"
            v-model="isDocCode"
            :binary="true"
          />
          <label for="isDocCode" class="pl-3">{{
            lang.showDocumentCode
          }}</label>
        </div>

        <div class="lg:col-4 col-12 pl-0 pr-2 pt-3">
          <PvCheckbox
            id="isFines"
            inputId="binary"
            v-model="isFines"
            :binary="true"
          />
          <label for="isFines" class="pl-3">{{ lang.showPenalty }}</label>
        </div>
        <div class="lg:col-4 col-12 pl-0 pr-2 pt-3">
          <PvCheckbox
            id="isIgnoreCancelDate"
            inputId="binary"
            v-model="isIgnoreCancelDate"
            :binary="true"
          />
          <label for="isIgnoreCancelDate" class="pl-3">{{
            lang.ignoreCancelDate
          }}</label>
        </div>
      </section>
    </transition>
    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
        </div>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useTextStyle from "../../../hooks/textStyle.js";
import useLang from "../../../hooks/lang.js";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useFormat from "../../../hooks/format.js";
import useTracking from "../../../hooks/tracking";
import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "toast-request"],
  setup() {
    const { lang } = useLang();
    const store = useStore();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { getPreviewBlob, getDownloadBlob } = useGetFormPrint();
    const { formatDateToISOString, formatPeriodToISOString } = useFormat();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      getPreviewBlob,
      getDownloadBlob,
      formatDateToISOString,
      formatPeriodToISOString,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      dateAt: "",
      dateAtError: false,
      selectedUnitStart: "",
      selectedUnitEnd: "",
      isSharedUnitReady: false,
      unitEndError: false,
      unitStartError: false,
      selectedProductStart: null,
      selectedProductEnd: null,
      productStartError: false,
      productEndError: false,
      isSharedProductReady: false,
      isDocCode: true,
      isFines: true,
      isIgnoreCancelDate: false,
      type: "PDF",
      fileNameText: "summary_of_outstanding_items",
    };
  },

  created() {
    this.setLoading(true);
    this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },

    closeDialog() {
      this.$emit("close");
    },
    initiateData() {
      // let date = new Date();
      this.dateAt = new Date();
      this.dateAtError = false;
      this.selectedUnitStart = null;
      this.selectedUnitEnd = null;
      this.isSharedUnitReady = false;
      this.unitStartError = false;
      this.unitEndError = false;
      this.selectedProductStart = null;
      this.selectedProductEnd = null;
      this.unitStartError = false;
      this.unitEndError = false;
      this.productStartError = false;
      this.productEndError = false;
      this.isSharedProductReady = false;
      this.isDocCode = true;
      this.isFines = true;
      this.isIgnoreCancelDate = false;
      this.type = "PDF";
      this.fileNameText = "summary_of_outstanding_items";
    },
    setData() {
      this.selectedUnitStart = this.units[0];
      this.selectedUnitEnd = this.units[this.units.length - 1];
      this.selectedProductStart = this.products[0];
      this.selectedProductEnd = this.products[this.products.length - 1];
      this.setLoading(false);
    },
    unitDataReady() {
      this.isSharedUnitReady = true;
      this.checkShouldSetData();
    },
    productDataReady() {
      this.isSharedProductReady = true;
      this.checkShouldSetData();
    },
    checkShouldSetData() {
      if (this.isSharedProductReady && this.isSharedUnitReady) {
        this.setData();
      }
    },
    async download(typeReport) {
      this.type = typeReport;
      if (this.validateData()) {
        return;
      }
      this.setLoading(true);
      try {
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
    validateData() {
      if (this.selectedDateAt == null) {
        this.dateAtError = true;
        return true;
      }
      if (this.selectedUnitStart == null) {
        this.unitStartError = true;
        return true;
      }
      if (this.selectedUnitEnd == null) {
        this.unitEndError = true;
        return true;
      }
      if (this.selectedProductStart == null) {
        this.productStartError = true;
        return true;
      }
      if (this.selectedProductEnd == null) {
        this.productEndError = true;
        return true;
      }
      return false;
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    title() {
      if (this.$store.getters.selectedLanguage === "TH")
        return this.reportObj.nameThai;
      else return this.reportObj.nameEnglish;
    },
    fileBlob() {
      return this.$store.getters["shared/blob"];
    },
    units() {
      return this.$store.getters["shared/unitUnits"];
    },
    products() {
      return this.$store.getters["shared/productArProducts"];
    },
    selectedDateAt() {
      let dateAt = null;
      if (this.dateAt) {
        dateAt = this.formatDateToISOString(this.dateAt);
      }
      return dateAt;
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
    payload() {
      let payload = {
        FileName: this.fileNameText + this.fileType,
        JasperPath: this.path + this.fileType,
        DocType: this.type,
        UserId: this.userId,
        PrintBy: this.userName,
        ProjectId: this.selectedProject.id,
        DateAt: this.selectedDateAt,
        UnitStart: this.selectedUnitStart.code,
        UnitEnd: this.selectedUnitEnd.code,
        ProductStart: this.selectedProductStart.code,
        ProductEnd: this.selectedProductEnd.code,
        IsDocCode: this.isDocCode ? "true" : "false",
        IsFines: this.isFines ? "true" : "false",
        IsIgnoreCancelDate: this.isIgnoreCancelDate ? "true" : "false",
        RequestId: 0,
        ReportName: "ReportSummaryOfOutstandingItems",
      };
      return payload;
    },
    path() {
      let path = "";

      if (this.type == "EXCEL") {
        path = this.reportObj.path + "DocCode_v2_excel";
      } 
      else {
        if (this.isDocCode) {
          path = this.reportObj.path + "DocCode";
        } else {
          path = this.reportObj.path;
        }
      }

      return path;
    },
    fileType() {
      let fileType = this.type == "PDF" ? ".pdf" : ".xlsx";
      return fileType;
    },
  },
  watch: {
    reportObj() {
      this.initiateData();
    },
  },
};
</script>
