<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7927 9.2052L15.2069 5.79105"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.5748 5.65454C11.575 5.60902 11.612 5.57222 11.6575 5.57231C11.703 5.57239 11.7399 5.60932 11.7399 5.65484C11.7399 5.70036 11.703 5.73729 11.6575 5.73738C11.612 5.73746 11.575 5.70066 11.5748 5.65514V5.65454"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.99833 6.9979V5.29719C7.99833 3.47389 9.47641 1.99582 11.2997 1.99582H15.7015C17.5248 1.99582 19.0029 3.47389 19.0029 5.29719V9.69903C19.0029 11.5223 17.5248 13.0004 15.7015 13.0004H15.0012"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.4264 9.3417C15.4263 9.38722 15.3893 9.42401 15.3437 9.42393C15.2982 9.42385 15.2614 9.38692 15.2614 9.34139C15.2614 9.29587 15.2982 9.25894 15.3437 9.25886C15.3893 9.25878 15.4263 9.29557 15.4264 9.34109V9.3417"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.0029 16.0016V21.0037"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.0021 18.0025L19.0029 16.0016"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.0037 18.0025L19.0029 16.0016"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.99625 12.6003V19.4031C2.99739 20.2869 4.78785 21.0037 6.99791 21.0037C9.20797 21.0037 10.9984 20.2869 10.9996 19.4031V12.6003"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.9984 12.6002C10.9984 13.484 9.20683 14.2009 6.99677 14.2009C4.78671 14.2009 2.99625 13.484 2.99625 12.6002C2.99625 11.7153 4.78899 10.9996 6.99791 10.9996C9.20683 10.9996 10.9984 11.7164 10.9996 12.6002"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.99682 16.0016C2.99682 16.8854 4.78728 17.6023 6.99734 17.6023C9.20741 17.6023 10.999 16.8854 10.999 16.0016"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
