<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
    :header="lang.titleWithholdingTaxText"
  >
    <section class="flex flex-wrap" :class="textFormClass">
      <div class="lg:col-12 md:col-6 col-12 pl-0 pr-2">
					<label for="taxTypes">{{ lang.taxType }}</label>
					<PvDropdown
            class="inputfield w-full mt-1"
            :options="taxTypes"
            optionLabel="codeAndName"
            v-model="selectedTaxType"
            :class="{ 'p-invalid': taxTypeError }"
            />
            <small v-if="taxTypeError" id="unit-help" class="p-error">{{
              lang.validateNoTaxTypeSelect
            }}</small>
			</div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="dueDateStart">{{ lang.taxDueDateStart }}</label>
        <PvCalendar
          id="dueDateStart"
          v-model="dueDateStart"
          class="inputfield w-full mt-1"
          showIcon
          dateFormat="dd/mm/yy"
          :class="{ 'p-invalid': dueDateStartError }"
          />
          <small v-if="dueDateStartError" id="date-help" class="p-error">{{
            lang.validateTaxDueDateSelect
          }}</small>
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="dueDateEnd">{{ lang.taxDueDateEnd }}</label>
        <PvCalendar
          id="dueDateEnd"
          v-model="dueDateEnd"
          class="inputfield w-full mt-1"
          showIcon
          dateFormat="dd/mm/yy"
          :class="{ 'p-invalid': dueDateEndError }"
          />
          <small v-if="dueDateEndError" id="date-help" class="p-error">{{
            lang.validateTaxDueDateSelect
          }}</small>
      </div>

    </section>
    <div class="pt-8 pb-1">
      <div class="pt-6 pb-1"></div>
      <PvButton
        icon="pi pi-file-excel"
        :label="lang.downloadText"
        @click="downloadText"
        class="mr-2 mb-2"
      />
      <PvButton
        icon="pi pi-times"
        :label="lang.close"
        @click="closeDialog"
        class="mr-2 mb-2"
      />
    </div>
  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetFormPrint from "../../hooks/getFormPrint.js";
import useTracking from "../../hooks/tracking";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { getPreviewBlob } = useGetFormPrint();
    const { addReportTracking } = useTracking();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.PettyCash,
          ...lang.th.gl.accounting.listing,
          ...lang.th.gl.accounting.edit,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.PettyCash,
          ...lang.en.gl.accounting.listing,
          ...lang.en.gl.accounting.edit,
          ...lang.en.gl.reportGl,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getPreviewBlob,
      addReportTracking,
    };
  },
  data() {
    return {
      selectedTaxType: null,
      taxTypeError: false,
      dueDateStart: null,
      dueDateEnd: null, 
      dueDateStartError: null,
      dueDateEndError: null, 
    };
  },
  created() {
    this.setLoading(true);
    this.getTaxType();
    let date = new Date();
    this.dueDateStart = new Date(date.getFullYear(), date.getMonth(), 1);
    this.dueDateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
      this.taxTypeError = false;
      this.dueDateStartError = false;
      this.dueDateEndError = false;
    },
    async getTaxType() {
			let where = {
				projectId: this.selectedProject.id,
				taxType: "WHT",
			};
			const payload = {
				page: 1,
				limit: 100,
				where,
			};
			await this.$store.dispatch("shared/getTaxTypes", payload);

      this.setLoading(false);
		},
    validateData() {
      if (!this.selectedTaxType) {
				this.$swal({
					icon: "warning",
					title: this.lang.validateNoTaxTypeSelect,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				}).then((result) => {
					/* Read more about isConfirmed, isDenied below */
					if (result.isConfirmed) {
						this.taxTypeError = true;
					}
				});
				return true;
			}

      if (this.selectedDueDateStart == null || this.selectedDueDateEnd == null ) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateTaxDueDateSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            if (this.selectedDueDateStart == null)this.dueDateStartError = true;
            if (this.selectedDueDateEnd == null)this.dueDateEndError = true; 
          }
        });
        return true;
      }

      return false;
    },
    async downloadText() {
      this.setLoading(true);

      if (this.validateData()) {
        this.setLoading(false);
        return;
      }

      try {
        let where = { ...this.payload, ...{ docType: "TEXT"} };
        let tracking = { ...this.payload, ...{JasperPath: "", DocType: "TEXT",} };
        this.addReportTracking(tracking, this.reportObj, this.selectedProject, this.$route.name);
        const payload = {
          path: "/Report/WithholdingTaxText",
          TextName: "WithholdingTaxText",
          where,
        };
        // console.log("payload", payload);
        await this.$store.dispatch("report/exportText", payload);

        this.setLoading(false);
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        this.setLoading(false);
      }
    },
  },
  computed: {
    payload() {

      let payload = {
        projectId: this.selectedProject.id,
        taxTypeId: this.selectedTaxType.id,
        taxTypeCode: this.selectedTaxType.code,
        dueDateStart: this.selectedDueDateStart,
        dueDateEnd: this.selectedDueDateEnd,
      };

      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    selectedDueDateStart() {
      let dueDateStart = null;
      if (this.dueDateStart) {
        dueDateStart = this.formatDateToISOString(this.dueDateStart);
      }
      return dueDateStart;
    },
    selectedDueDateEnd() {
      let dueDateEnd = null;
      if (this.dueDateEnd) {
        dueDateEnd = this.formatDateToISOString(this.dueDateEnd);
      }
      return dueDateEnd;
    },
    selectedPayMonth() {
      // console.log('selectedPayMonth');
      let payMonth = null;
      if (this.payMonth) {
        payMonth = this.formatDateToISOString(this.payMonth);
      }
      return payMonth;
    },
    taxTypes() {
			return this.$store.getters["shared/taxTypes"];
		},
    isTaxFilingType1(){
      let show = false;
      if (this.selectTaxFilingType == '2') {
        show = true;
      }
      return show;
    },
  },
  watch:{
    selectedTaxType(curValue) {
        this.taxTypeError = false;
        this.selectLaw = "1";
        if(curValue.code == 'WT3'){
          this.law= [
            { name: this.lang.pnd3Law1, code: "1" },
            { name: this.lang.pnd3Law2, code: "2" },
            { name: this.lang.pnd3Law3, code: "3" },
          ]
        } 
        else{
          this.law= [
            { name: this.lang.pnd53Law1, code: "1" },
            { name: this.lang.pnd53Law2, code: "2" },
            { name: this.lang.pnd53Law3, code: "3" },
          ]
        }
        
		},
    selectedDueDateStart() {
      // if (oldValue === null && curValue) {
        this.dueDateStartError = false;
      // }
    },
    selectedDueDateEnd() {
      // if (oldValue === null && curValue) {
        this.dueDateEndError = false;
      // }
    },
    selectedPayMonth() {
      // if (oldValue === null && curValue) {
        this.payMonthError = false;
      // }
    },
  }
};
</script>
