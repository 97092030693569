import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default function useGetTax() {
    const store = useStore();
    let searchTextTax = ref(null);
  
    const taxes = computed(() => {
      return store.getters["shared/taxTaxes"];
    });
  
    const taxIsLastPage = computed(() => {
      return store.getters["shared/taxIsLastPage"];
    });

    const filteredTaxes = computed(() => {
        if (!searchTextTax.value) {
          //ช่องว่าง
          const result = [...taxes.value];
          return result;
        } else {
          return taxes.value.filter((tax) => {
            return tax.codeAndName
              .toLowerCase()
              .includes(searchTextTax.value.toLowerCase());
          });
        }
    });

    const searchTax = function (event) {
        // console.log(event);
        if (!event.query.trim().length) {
          //ช่องว่าง
          // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
          searchTextTax.value = "";
          searchTextTax.value = null;
        } else {
          searchTextTax.value = event.query;
        }
    };

    let isSharedTaxReady = ref(false);

    const getAllTaxes = async function () {
        if (!taxIsLastPage.value) {
          await store.dispatch("shared/getAllTaxes");
        }
        isSharedTaxReady.value = true;
    };

    const getTaxByCode = function (code) {
        return taxes.value.find((tax) => {
          return tax.code.toLowerCase() === code.trim().toLowerCase();
        });
    };

    onMounted(async () => {
        await getAllTaxes();
    });

    return {
        searchTax,
        filteredTaxes,
        searchTextTax,
        getTaxByCode,
        isSharedTaxReady,
    };
}