export default {
  async getPurchaseRequisitions(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseRequisition";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get purchase requisition");
      throw error;
    }
    // console.log(responseData);

    // const data = responseData.data;
    context.commit("setPurchaseRequisition", responseData);
  },
  async getPurchaseRequisition(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseRequisition?limit=1";

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get purchase requisition");
      throw error;
    }
    // console.log(responseData);

    // const data = responseData.data;
    if (responseData.data.length > 0) {
      context.commit("setSelectedPurchaseRequisition", responseData.data[0]);
    }
  },
  async createOrUpdatePurchaseRequisition(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/PurchaseRequisition/${payload.type}`;

    const fallbackErrorMessage = `Failed to ${payload.type} purchase requisition`
    const token = context.rootGetters.token;

    
    const response = await fetch(url, {
      method: payload.type === 'Add' ? 'post' : 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }

    context.commit("setSelectedPurchaseRequisition", responseData.data);
  },
  async approvePurchaseRequisition(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/PurchaseRequisition/Approve`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to approve purchase requisition');
      throw error;
    }

    context.commit("setSelectedPurchaseRequisition",responseData.data);
  },
  async cancelPurchaseRequisition(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/PurchaseRequisition/Cancel`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to cancel purchase requisition');
      throw error;
    }

    context.commit("setSelectedPurchaseRequisition",responseData.data);
  },
  async closePurchaseRequisition(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/PurchaseRequisition/Close`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to close purchase requisition');
      throw error;
    }
    
    context.commit("setSelectedPurchaseRequisition",responseData.data);
  },
  async revertApprovePurchaseRequisition(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/PurchaseRequisition/RevertApprove`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to revert approve purchase requisition');
      throw error;
    }

    context.commit("setSelectedPurchaseRequisition",responseData.data);
  },
  async printPurchaseRequisition(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/PurchaseRequisition/Print`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(payload),
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      
    });  
           
    // response.blob().then(function(blob) {
    //   // console.log("PrintPrepaid_blob>>>",blob);
    //     var url = window.URL.createObjectURL(blob);
    //     //window.location.assign(url);
    //     var a = document.createElement('a');
    //     a.href = url;
    //     const d = new Date();
    //     let dateText = d.toISOString();
    //     a.download = `PettyCash_${dateText}.pdf`;
    //     document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    //     a.click();
    //     a.remove(); //afterwards we remove the element again
    // });
    
    let urlBlob = ''
    let blob = await response.blob();
    urlBlob = window.URL.createObjectURL(blob);
    context.commit('setBlob', urlBlob);
  },
  resetSelectedPurchaseRequisition(context) {
    context.commit("setSelectedPurchaseRequisition",null);
  },
};
