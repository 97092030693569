export default {
  createCreditNote: "Create Credit Note",
  editCreditNote: "Edit Credit Note",
  creditNotePrintEditPage: "Print Credit Note",
  creditNoteDetail: "Credit Note Detail",

  createNewCreditNote: "Create New CreditNote",
  askForCreate: "Are you sure you want to discard change ",
  askForCreateConfirm: "Are you create new document?",

  code: "Document code",
	refCode: "Reference code",
	unitCode: "Unit Code",
  employeeName:"Employee Name",
  pendingList: "Pending List",
	validateInactiveUnitSelect: "This unit is inactive.",
  columnBalance: "Balance",
	reduceDebt: "Reduce Debt",
  reasons: "Reason",
	columnDiffAmount: "Diff Amount",
  invoiceCode: "Invoice Code",
	invoiceDate: "Invoice Date",
  paySelected: "Pay Selected",

  validateCreditNoteAmount: "Credit Note amount more than balance amount.",
  titleDetailDialog:"Edit credit note amount.",
  cancelReason: "Cancel reason",
  validateNoCancelReason: "Please enter cancel reason.",
  cancelCreditNoteSuccess: "Cancel credit note :code Success",
	validateNetTotalAmount: "Net Total Amount not less or more than 0.",
  validateNoReasons: "Please enter reason.",
  createCreditNoteSuccess: "Create credit note success",
  editCreditNoteSuccess: "Edit credit note success",
  productName: "Product Name",
};
