<template>
  <base-container>
    <h2>{{ lang.title }}</h2>
    <div class="surface-card shadow-2 p-3 border-round">
      <div class="flex flex-wrap">
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="language">{{ lang.language }}</label>
          <PvDropdown
            id="language"
            class="inputfield w-full mt-1"
            v-model="selectedLanguage"
            :options="languages"
            optionLabel="name"
          />
        </div>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../hooks/lang.js";
import { ref,computed, watch } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    //hooks
    const store = useStore();
    const { lang } = useLang();

    //data
    let selectedLanguage = ref({
      name: "ภาษาไทย",
      code: "TH",
    });
    let languages = ref([
      {
        name: "English",
        code: "EN",
      },
      {
        name: "ภาษาไทย",
        code: "TH",
      },
    ]);

    selectedLanguage.value = languages.value.find((language) => {
      return language.code === store.getters.selectedLanguage;
    });

    //computed
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.setting };
      } else {
        return { ...lang.en.base, ...lang.en.setting };
      }
    });

    //watcher
    watch([selectedLanguage], function (curValue, oldValue) {
      if (curValue[0] !== oldValue[0]) {
        store.dispatch("setLanguage", { value: curValue[0].code });
      }
    });
    // const currentLanguage = computed(function () {
    //   return store.getters.selectedLanguage;
    // });

    return { lang: mergeLang, selectedLanguage, languages };
  },
};
</script>