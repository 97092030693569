<template>
  <PvDialog
    :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
    :style="{ width: '40vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ this.title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <!-- <div class="col-12 pl-0 pr-2 pt-1 lg:pr-4 md:pr-4">
          <label for="reportType"> {{ lang.unitReportType }} </label>
          <div class="flex">
            <div
              v-for="reportType of reportTypes"
              :key="reportType.code"
              class="field-radiobutton pt-3 mr-5"
            >
              <PvRadioButton
                class="mr-2"
                :inputId="reportType.code"
                name="reportType"
                :value="reportType.code"
                v-model="selectReportType"
                @change="changePayment()"
              />
              <label :for="reportType.code"> {{ reportType.name }} </label>
            </div>
          </div>
        </div> -->

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="unitStart">{{ lang.unitReportUnitCodeStart }}</label>
          <base-unit-auto-complete
            id="unit"
            v-model="selectedUnitStart"
            class="inputfield w-full mt-1"
            @load-finish="unitDataReady"
            :class="{ 'p-invalid': unitStartError }"
            @item-select="unitStartError = false"
          />
          <small v-if="unitStartError" id="unit-help" class="p-error">{{
            lang.validateNoUnitSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="unitEnd">{{ lang.unitReportUnitCodeEnd }}</label>
          <base-unit-auto-complete
            id="unit"
            v-model="selectedUnitEnd"
            class="inputfield w-full mt-1"
            @load-finish="unitDataReady"
            :class="{ 'p-invalid': unitEndError }"
            @item-select="unitEndError = false"
          />
          <small v-if="unitEndError" id="unit-help" class="p-error">{{
            lang.validateNoUnitSelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 mt-2">
          <label for="buildings"> {{ lang.unitReportBuilding }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="buildings"
            optionLabel="building"
            optionValue="building"
            v-model="selectedBuilding"
            :filter="false"
            :placeholder="lang.unitReportPlaceholderBuilding"
          />
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0 mt-2">
          <label>{{ lang.unitReportUnitType }}</label>
          <PvDropdown
            :options="unitTypes"
            optionLabel="label"
            class="inputfield w-full mt-1"
            v-model="selectedUnitType"
            :filter="false"
            :placeholder="lang.unitReportPlaceholderAll"
          />
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 mt-2">
          <label>{{ lang.unitReportCustomerType }}</label>
          <PvDropdown
            :options="customerTypes"
            optionLabel="label"
            class="inputfield w-full mt-1"
            v-model="selectedCustomerType"
            :placeholder="lang.unitReportPlaceholderAll"
          />
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0 mt-2">
          <label>{{ lang.unitReportAddressType }}</label>
          <PvDropdown
            :options="addressTypes"
            optionLabel="label"
            class="inputfield w-full mt-1"
            v-model="selectedAddressType"
            :placeholder="lang.unitReportPlaceholderAll"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText
            id="fileNameText"
            type="text"
            class="inputfield w-full mt-1"
            v-model="fileNameText"
          />
        </div>
      </section>
    </transition>

    <template #footer>
      <div
				class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
			>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
				</div>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
				</div>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
				</div>
			</div>
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useTextStyle from "../../../hooks/textStyle.js";
import useLang from "../../../hooks/lang.js";
import useTracking from "../../../hooks/tracking";
export default {
  props: {
    title: {
      type: String,
    },
    openDialog: {
      type: Number,
    },
  },
  emits: ["close", "confirm"],
  setup() {
    const { lang } = useLang();
    const store = useStore();
    const { textFormClass } = useTextStyle();
    const { addReportTracking } = useTracking();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.th.gl.reportGl,
        };
      }
    });
    return {
      lang: mergeLang,  
      textFormClass,
      addReportTracking,
    };
  },
  data() {
    return {
      unitStartError: false,
      unitEndError: false,
      selectedUnitStart: null,
      selectedUnitEnd: null,
      isSharedUnitReady: false,
      selectedBuilding: null,
      unitTypes: [
        {
          value: "โอนแล้ว-อยู่เอง",
          label: this.lang.unitReportUnitType1,
        },
        {
          value: "โอนแล้ว-ยังไม่เข้าพักอาศัย",
          label: this.lang.unitReportUnitType2,
        },
        {
          value: "โอนแล้ว-ปล่อยเช่า",
          label: this.lang.unitReportUnitType3,
        },
        {
          value: "ยังไม่โอน",
          label: this.lang.unitReportUnitType4,
        },
        {
          value: "เช่าพื้นที่ส่วนกลาง/ร้านค้า",
          label: this.lang.unitReportUnitType5,
        },
      ],
      selectedUnitType: null,
      customerTypes: [
        {
          value: "owner1",
          label: this.lang.unitReportCustomerType1,
        },
        {
          value: "owner2",
          label: this.lang.unitReportCustomerType2,
        },
        {
          value: "owner3",
          label: this.lang.unitReportCustomerType3,
        },
        {
          value: "tenant",
          label: this.lang.unitReportCustomerType4,
        },
      ],
      selectedCustomerType: {
        value: "owner1",
        label: this.lang.unitReportCustomerType1,
      },
      addressTypes: [
        {
          value: "customerAddress",
          label: this.lang.unitReportAddressType1,
        },
        {
          value: "customerContactAddress",
          label: this.lang.unitReportAddressType2,
        },
        {
          value: "customerBillingAddress",
          label: this.lang.unitReportAddressType3,
        },
      ],
      selectedAddressType: {
        value: "customerAddress",
        label: this.lang.unitReportAddressType1,
      },
      type: "PDF",
      fileNameText: "unit",
    };
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    initiateData() {
      this.selectedUnitStart = null;
      this.selectedUnitEnd = null;
      this.unitStartError = false;
      this.unitEndError = false;
      this.isSharedUnitReady = false;
      this.selectedUnitType = null;
      this.selectedCustomerType = null;
      this.selectedAddressType = null;
      this.type = "PDF";
      this.fileNameText = "unit";
    },
    unitDataReady() {
      this.isSharedUnitReady = true;
      this.checkShouldSetData();
    },
    checkShouldSetData() {
      if (this.isSharedUnitReady) {
        this.setData();
      }
    },
    setData() {
      this.selectedUnitStart = this.units[0];
      this.selectedUnitEnd = this.units[this.units.length - 1];
      this.setLoading(false);
    },
    async download(typeReport) {
      this.type = typeReport;
      let reportObj = {
        menuGroupTh: "unit",
        menuGroupEn: "unit",
        nameThai: this.title,
        nameEnglish: "unit report",
      }   
      this.addReportTracking(this.payload, reportObj, this.selectedProject, this.$route.name);
      this.$emit("confirm", this.payload);
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
    units() {
      return this.$store.getters["shared/unitUnits"];
    },
    buildings() {
      const result = [];
      if (this.isSharedUnitReady && this.units) {
        this.units.forEach((item) => {
          const data = result.find((d) => d.building.trim() === item.building);
          if (!data && item.building.trim() != "") {
            let Building = {
              building: item.building.trim(),
            };
            result.push(Building);
          }
        });
      }
      result.sort((a, b) => (a.building > b.building ? 1 : -1));
      return result;
    },
    fileName() {
      let fileName = this.type == "PDF" ? ".pdf" : ".xlsx";
      return this.fileNameText + fileName;
    },
    fileType() {
      let fileType =
        this.type == "EXCEL"
          ? "_excel.xlsx"
          : this.type == "JASPEREXCEL"
          ? ".xlsx"
          : ".pdf";
      return fileType;
    },
    payload() {
      let payload = {
        ProjectId: this.selectedProject.id,
          UnitCodeStart: this.selectedUnitStart ? this.selectedUnitStart.code : "",
          UnitCodeEnd: this.selectedUnitEnd ? this.selectedUnitEnd.code : "",
          UnitAddressNoStart: "",
          UnitAddressNoEnd: "",
          UnitBuilding: this.selectedBuilding ? this.selectedBuilding : "",
          UnitType: this.selectedUnitType ? this.selectedUnitType.value : "all",
          CustomerType: this.selectedCustomerType ? this.selectedCustomerType.value : "owner1",
          CustomerAddress: this.selectedAddressType ? this.selectedAddressType.value : "customerAddress",
        FileName: this.fileName,
        JasperPath: "unit/unit_report" + this.fileType,
        DocType: this.type,
        UserId: this.userId,
        PrintBy: this.userName,
        RequestId: 0,
      };
      return payload;
    },
  },
  watch: {
    openDialog() {
      this.setLoading(true);
      this.checkShouldSetData();
    },
  },
};
</script>
