<template>
  <base-container>
    <base-gl-book-modal
      v-model:visible="showGlBookModal"
      :search="searchText"
      :isSharedLoading="isSharedGlBookReady"
      @close="showGlBookModal = false"
      @glBook-selected="glBookModalSelected"
    ></base-gl-book-modal>
    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchTextPrint"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>
    <div class="flex justify-content-between align-items-center" style="padding-top: 20px">
      <div class="my-3">
        <PvButton
          icon="pi pi-chevron-left"
          :label="lang.back"
          @click="goBack"
          class="mr-2 p-button-secondary"
          v-if="isHideBack"
        />
      </div>
      <h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
    </div>
    <div class="surface-card shadow-2 p-3 border-round">
      <div class="pt-2 pb-1">
        <PvButton
          icon="pi pi-file"
          :label="lang.create"
          @click="gotoEdit('create')"
          class="mr-2 mb-2"
          :disabled="!isCheckInsert"
        />
        <PvButton
          icon="pi pi-list"
          :label="parameterButtonText"
          @click="toggleParameterVisible"
          class="p-button-help mr-2 mb-2"
        />
        <PvButton
          icon="pi pi-replay"
          :label="lang.changeGlBook"
          @click="changeGlBook"
          class="mr-2 mb-2"
        />

        <PvButton
          icon="pi pi-dollar"
          :label="lang.refundPettyCash"
          class="mr-2 mb-2"
          @click="askRefundPettyCash"
        />

        <PvButton
          icon="pi pi-check-circle"
          :label="lang.approvePettyCash"
          class="mr-2 mb-2"
          @click="askApprovePettyCash"
          :disabled="!isCheckApprove"
        />
      </div>
      <transition>
        <div v-if="isParameterVisible">
          <section class="flex flex-wrap" :class="textFormClass">
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
              <label for="dateStart">{{ lang.dateStart }}</label>
              <PvCalendar
                id="dateStart"
                v-model="dateStart"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
              <label for="dateEnd">{{ lang.dateEnd }}</label>
              <PvCalendar
                id="dateEnd"
                v-model="dateEnd"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
              <label for="withdrawStatus">{{ lang.withdrawStatus }}</label>
              <PvDropdown
                class="inputfield w-full mt-1"
                :options="withdrawStatus"
                optionLabel="name"
                optionValue="code"
                v-model="selectedWithdrawStatus"
              />
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
              <label for="codeStart">{{ lang.codeStart }}</label>
              <PvInputText
                id="codeStart"
                type="text"
                class="inputfield w-full mt-1"
                placeholder="6501xxxxx"
                v-model="codeStart"
              />
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
              <label for="codeEnd">{{ lang.codeEnd }}</label>
              <PvInputText
                id="codeEnd"
                type="text"
                class="inputfield w-full mt-1"
                placeholder="6501xxxxx"
                v-model="codeEnd"
              />
            </div>
            <!-- <div class="pt-5 pb-1"> -->
            <div class="col-12 pl-0 pr-2">
              <PvButton
                icon="pi pi-search"
                :label="lang.search"
                @click="getPettyCashEntries"
                class="mr-2 mb-2"
              />
            </div>
            <!-- </div> -->
          </section>
        </div>
      </transition>
      <PvDataTable
        showGridlines
        :value="PettyCashEntries"
        v-model:selection="selectedPettyCashEntry"
        dataKey="fcId"
        responsiveLayout="scroll"
        :scrollHeight="halfViewportHeight + 'px'"
        class="p-datatable-sm"
        :class="textTableClass"
        :loading="isLoading"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn selectionMode="multiple"></PvColumn>
        <PvColumn
          field="fcPostStep"
          :header="lang.columnStatus"
          style="min-width: 2rem"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        ></PvColumn>
        <PvColumn
          field="fcIsCancel"
          :header="lang.columnIsCancel"
          style="min-width: 2rem"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        ></PvColumn>
        <PvColumn
          field="fcCode"
          :header="lang.columnCode"
          style="min-width: 3rem"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        ></PvColumn>
        <PvColumn
          field="ftDate"
          :header="lang.columnDate"
          style="min-width: 3rem"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        >
          <template #body="slotProps">
            {{ formatDate(slotProps.data.ftDate) }}
          </template></PvColumn
        >
        <PvColumn
          field="fcPeriod"
          :header="lang.columnPeriodC"
          style="min-width: 6rem"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        ></PvColumn>
        <PvColumn
          field="fcDesc"
          :header="lang.description"
          style="min-width: 7rem"
          headerClass="h-center"
          bodyClass="text-left"
          sortable
        ></PvColumn>
        <PvColumn
          field="totalAmount"
          :header="lang.columnTotal"
          style="min-width: 5rem"
          headerClass="h-center"
          bodyClass="text-right"
          sortable
          ><template #body="slotProps">
            {{ formatCurrency(slotProps.data.totalAmount) }}
          </template></PvColumn
        >
        <PvColumn
          field="fcIsApprove"
          :header="lang.columnApprove"
          style="min-width: 4rem"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        ></PvColumn>
        <PvColumn
          field="fcAppvName"
          :header="lang.approveName"
          style="min-width: 7rem"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        ></PvColumn>
        <PvColumn
          field="fcReasonNonApprove"
          :header="lang.reasonNonApprove"
          style="min-width: 7rem"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        ></PvColumn>
        <PvColumn style="min-width: 6rem">
          <template #body="slotProps">
            <PvButton
              type="button"
              class="p-button-secondary p-button-text py-0"
              icon="pi pi-pencil"
              @click="gotoEdit(slotProps.data.fcId)"
              :disabled="!isCheckUpdate"
            ></PvButton>
            <PvButton
              type="button"
              class="p-button-secondary p-button-text py-0"
              icon="pi pi-print"
              @click="selectPrintForm(slotProps.data)"
              :disabled="!isCheckPrint"
            ></PvButton>
          </template>
        </PvColumn>
        <template #footer>
          <PvPaginator
            :rows="rowsPerPage"
            :totalRecords="totalRecords"
            @page="onPageChange($event)"
            :rowsPerPageOptions="[10, 50, 100]"
						:first="showCurrentPage"
          >
            <template #start="slotProps">
              {{ lang.page }}:
              {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
            </template>
          </PvPaginator>
        </template>
      </PvDataTable>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import useGetGlBook from "../../../hooks/getGlBook.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import accountDetailWithoutParent from "../../../hooks/accountDetailWithoutParent.js";
export default {
  props: ["glBook", "idBook", "id", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { isSharedGlBookReady, searchText, SetBookCode } = useGetGlBook();
    const {
      getFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints
    } = useGetFormPrint();
    const { checkAccountDetailNoParent} = accountDetailWithoutParent();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.PettyCash,
					...lang.th.gl.accounting.edit,
          ...lang.th.gl.accounting.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.PettyCash,
					...lang.en.gl.accounting.edit,
          ...lang.en.gl.accounting.listing,
        };
      }
    });

    const isHideBack = inject('isHideBack');

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      isSharedGlBookReady,
      searchText,
      SetBookCode,

      getFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints,

      isHideBack,
      checkAccountDetailNoParent,
    };
  },
  data() {
    return {
      showGlBookModal: true,
      glBookId: null,
      isParameterVisible: true,
      isLoading: false,
      selectedGlBook: null,
      dateStart: null,
      dateEnd: null,
      codeStart: "",
      codeEnd: "",
      selectedPettyCashEntry: null,
      selectedPage: 1,
      rowsPerPage: 10,

      showFormPrintModal: false,
      path: "",
      dataPrint: [],
      categoryPrint: "",
      bookModalSelected: null,
      storageName: "",

      withdrawStatus: [
        { name: this.lang.statusAll, code: "" },
        { name: this.lang.statusWait, code: "wait" },
        { name: this.lang.statusRequested, code: "requested" },
      ],
      selectedWithdrawStatus: "",
    };
  },
  created() {
    let date = new Date();
    //this.dateStart = new Date();
    this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
    this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

		this.storageName = `erp_search_accountingEntryList_${this.selectedProject.code}_${this.userCode}`;

    const localSearch = localStorage.getItem(this.storageName);
    if (localSearch) {
      let search = JSON.parse(localSearch);

      if (search.editAccountingEntry === "Edit") {
        if (search.dateStart != null && search.dateStart != "") {
          this.dateStart = new Date(search.dateStart);
        } else this.dateStart = "";

        if (search.dateEnd != null && search.dateEnd != "") {
          this.dateEnd = new Date(search.dateEnd);
        } else this.dateEnd = "";

        this.codeStart = search.codeStart || this.codeStart;
        this.codeEnd = search.codeEnd || this.codeEnd;

				this.rowsPerPage = search.rowsPerPage || this.rowsPerPage;
        this.selectedPage = search.selectedPage || this.selectedPage;

        this.selectedWithdrawStatus = search.withdrawStatus || this.selectedWithdrawStatus;
        
        search.editAccountingEntry = "";

        localStorage.setItem(
          this.storageName,
          JSON.stringify(search)
        );
      }

      this.selectedGlBook = this.glBook;
      this.glBookId = this.idBook;
      this.bookModalSelected = search.bookModalSelected;
    }
    this.SetBookCode("PC");
    // console.log("this.glBook=",this.glBook)
    if (this.glBook) {
      this.showGlBookModal = false;
      this.getPettyCashEntries();
    }
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$store.dispatch("glPettyCash/resetPettyCash");
      this.$router.push({name: "home"})
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    toggleParameterVisible() {
      this.isParameterVisible = !this.isParameterVisible;
    },
    changeGlBook() {
      this.SetBookCode("PC");
      this.showGlBookModal = true;
    },
    glBookModalSelected(data) {
      // console.log("glBookModalSelected_data=",data)
      this.bookModalSelected = data;
      this.$router.push({
        name: "gl-pettycash-book",
        params: {
          glBook: data.generalLedgerBookCode,
          idBook: data.generalLedgerBookId,
        }
      });
      this.selectedGlBook = data.generalLedgerBookCode;
      this.glBookId = data.generalLedgerBookId;

      this.getPettyCashEntries();
      this.showGlBookModal = false;
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getPettyCashEntries();
    },
    async getPettyCashEntries() {
      this.selectedPettyCashEntry = null;
      this.isLoading = true;
      // console.log("this.glBook=", this.glBook);
      // console.log("this.selectedGlBook=", this.selectedGlBook);

      let where = {
        projectId: this.selectedProject.id,
        GLBId: this.glBookId,
        dateStart: this.selectedDateStart,
        dateEnd: this.selectedDateEnd,
        codeStart: this.codeStart || null,
        codeEnd: this.codeEnd || null,
        withdrawStatus: this.selectedWithdrawStatus,
      };

      // console.log("where", where);

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };

      //   console.log("getPettyCashEntries_payload", payload);

      await this.$store.dispatch("glPettyCash/getPettyCash", payload);

      this.isLoading = false;

      where = {
        ...where,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        generalLedgerBookId: this.glBookId,
        generalLedgerBookCode: this.selectedGlBook,
        bookModalSelected: this.bookModalSelected,
        rowsPerPage: this.rowsPerPage,
        selectedPage: this.selectedPage,
      };
      // console.log("this.bookModalSelected=", this.bookModalSelected);
      localStorage.setItem(
        this.storageName,
        JSON.stringify(where)
      );
    },
    async gotoEdit(accountingId) {
      // console.log("this.selectedGlBook",);
      let result = await this.checkAccountDetailNoParent();
      if(!result){
        this.$router.push({
          name: "gl-pettycash-edit",
          params: {
            glBook: this.selectedGlBook,
            idBook: this.glBookId,
            id: accountingId
          }
        });
      }
    },
    async askRefundPettyCash() {
      //
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">  ${this.checkRefundPettyCash} </br> ${this.lang.askRefundPettyCash}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        if (this.validateData()) {
          return;
        }
        await this.RefundPettyCash();
      } else if (result.isDenied) {
        //
      }
    },
    validateData() {
      if (!this.selectedPettyCashEntry) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateDetailSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }
      return false;
    },
    async RefundPettyCash() {
      this.setLoading(true);
      //   console.log("pettyCashBack=",this.selectedPettyCashEntry);
      if (this.selectedPettyCashEntry == null) {
        this.selectedPettyCashEntry = null;
        this.setLoading(false);
        return;
      }

      try {
        let details = [];
        let PettyCashSelected = {
          projectId: this.selectedProject.id,
          GlbId: this.glBookId,
          dateStart: this.selectedDateStart,
          dateEnd: this.selectedDateEnd,
          codeStart: this.codeStart || null,
          codeEnd: this.codeEnd || null,
          page: this.selectedPage,
          limit: this.rowsPerPage,
        };
        this.selectedPettyCashEntry.forEach((data) => {
          if (data.fcIsApprove.trim() != "Y" && data.fcIsCancel.trim() != "C") {
            let detail = {
              Id: data.fcId,
              Code: data.fcCode,
            };
            details.push(detail);
          }
        });
        let PettyCash = { Data: details };
        PettyCashSelected = { ...PettyCashSelected, ...PettyCash };
        // console.log("pettyCashBack=", PettyCashSelected);

        await this.$store.dispatch(
          "glPettyCash/AddPettyCash",
          PettyCashSelected
        );
        // this.getPettyCashEntries();
        this.selectedPettyCashEntry = null;
        this.setLoading(false);
      } catch (error) {
        // console.log("error=", error);
        this.setLoading(false);
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
    },
    async askApprovePettyCash() {
      //
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}"> ${this.checkApprovePettyCash} </br> ${this.lang.askApprovePettyCash} </p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {        
        if (this.validateData()) {
          return;
        }
        await this.approvePettyCash();
      } else if (result.isDenied) {
        //
      }
    },
    async approvePettyCash() {
      this.setLoading(true);
      //   console.log("pettyCashBack=",this.selectedPettyCashEntry);
      if (this.selectedPettyCashEntry == null) {
        this.selectedPettyCashEntry = null;
        this.setLoading(false);
        return;
      }

      try {
        let details = [];
        let PettyCashSelected = {
          projectId: this.selectedProject.id,
          GlbId: this.glBookId,
          dateStart: this.selectedDateStart,
          dateEnd: this.selectedDateEnd,
          codeStart: this.codeStart || null,
          codeEnd: this.codeEnd || null,
          page: this.selectedPage,
          limit: this.rowsPerPage,
        };
        this.selectedPettyCashEntry.forEach((data) => {
          if (
            data.fcIsApprove.trim() != "Y" &&
            data.fcIsCancel.trim() != "C" &&
            data.fcPeriod.trim() != ""
          ) {
            let detail = {
              Id: data.fcId,
              Code: data.fcCode,
            };
            details.push(detail);
          }
        });
        let PettyCash = { Data: details };
        PettyCashSelected = { ...PettyCashSelected, ...PettyCash };
        // console.log("pettyCashBack=", PettyCashSelected);

        await this.$store.dispatch(
          "glPettyCash/ApprovePettyCash",
          PettyCashSelected
        );
        // this.getPettyCashEntries();
        this.selectedPettyCashEntry = null;
        this.setLoading(false);
      } catch (error) {
        // console.log("error=", error);
        this.setLoading(false);
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
    },
    async selectPrintForm(data) {
      this.dataPrint = data;
      let category = this.selectedGlBook;
      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: category,
      };
      const payload = { where };
      await this.getFormPrints(payload);

      if(this.filteredFormPrints.length == 1) {
        this.path = this.filteredFormPrints[0].path;
        this.PrintPettyCash();
      }else{
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path;
      this.categoryPrint = event.category;
      this.PrintPettyCash();
      this.showFormPrintModal = false;
    },
    async PrintPettyCash() {
      this.setLoading(true);
      let category = this.glBookId;
      let printType = "";
      const Prepaid = {
        id: this.dataPrint.fcId,
        projectId: this.selectedProject.id,
        Type: printType,
        path: this.path,
        GlbId: category,
      };

      // console.log("PrintPettyCash=", Prepaid);
      try {
        await this.$store.dispatch("glPettyCash/PrintPettyCash", Prepaid);
        this.isPrinted = true;
      } catch (error) {
        // console.log("error=", error);
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        this.isPrinted = false;
        this.setLoading(false);
      }
      let blob = this.$store.getters["glPettyCash/blob"];
      // console.log('blob',blob);
      this.getPreviewBlob(blob);

      this.setLoading(false);
    },
  },
  computed: {
    parameterButtonText() {
      return this.isParameterVisible
        ? this.lang.hideParameters
        : this.lang.showParameters;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
		userCode() {
			return this.$store.getters.userCode;
		},
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    PettyCashEntries() {
      return this.$store.getters["glPettyCash/pettyCash"];
    },
    totalPage() {
      return this.$store.getters["glPettyCash/totalPage"];
    },
    currentPage() {
      return this.$store.getters["glPettyCash/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    checkRefundPettyCash() {
      let message = "";
      let list = " รายการ ";
      let baht = " บาท";
      let money = " เป็นเงิน ";
      let messageNum = "เบิกได้จำนวน ";
      let number = 0;
      let TotalAmount = 0;

      if (this.selectedPettyCashEntry == null) return "";
      this.selectedPettyCashEntry.forEach((data) => {
        if (data.fcIsCancel.trim() != "C" && data.fcIsApprove.trim() != "Y") {
          number++;
          TotalAmount += data.totalAmount;
        }
      });

      if (number > 0)
        message =
          messageNum +
          number +
          list +
          money +
          this.formatCurrency(TotalAmount) +
          baht;

      return message;
    },
    checkApprovePettyCash() {
      let message = "";
      let list = " รายการ ";
      let baht = " บาท";
      let money = " เป็นเงิน ";
      let messageNum = "อนุมัติได้จำนวน ";
      let number = 0;
      let TotalAmount = 0;

      let messageN = "";
      let messageNumN = "อนุมัติไม่ได้จำนวน ";
      let numberN = 0;
      let TotalAmountN = 0;

      if (this.selectedPettyCashEntry == null) return "";
      this.selectedPettyCashEntry.forEach((data) => {
        if (
          data.fcIsCancel.trim() != "C" &&
          data.fcIsApprove.trim() != "Y" &&
          data.fcPeriod.trim() != ""
        ) {
          number++;
          TotalAmount += data.totalAmount;
        } else {
          numberN++;
          TotalAmountN += data.totalAmount;
        }
      });

      if (number > 0)
        message =
          messageNum +
          number +
          list +
          money +
          this.formatCurrency(TotalAmount) +
          baht;
      if (numberN > 0)
        messageN =
          messageNumN +
          numberN +
          list +
          money +
          this.formatCurrency(TotalAmountN) +
          baht;

      return message + "</br>" + messageN;
    },
    isCheckInsert() {
      let isCheck = false;
      if (
        this.bookModalSelected != null &&
        this.bookModalSelected.insert == "Y"
      )
        isCheck = true;
      return isCheck;
    },
    isCheckUpdate() {
      let isCheck = false;
      if (
        this.bookModalSelected != null &&
        this.bookModalSelected.update == "Y"
      )
        isCheck = true;
      return isCheck;
    },
    isCheckCancel() {
      let isCheck = false;
      if (
        this.bookModalSelected != null &&
        this.bookModalSelected.cancel == "Y"
      )
        isCheck = true;
      return isCheck;
    },
    isCheckPrint() {
      let isCheck = false;
      if (this.bookModalSelected != null && this.bookModalSelected.print == "Y")
        isCheck = true;
      return isCheck;
    },
    isCheckApprove() {
      let isCheck = false;
      if (
        this.bookModalSelected != null &&
        this.bookModalSelected.approve == "Y"
      )
        isCheck = true;
      return isCheck;
    },
    title() {
      let titleBook = "";
      if (this.glBook) titleBook = " : " + this.glBook;
      let result = this.lang.Title + titleBook;
      return result;
    },
		showCurrentPage() {
			let page = 0;
			page = this.rowsPerPage * (this.selectedPage - 1);
			return page;
		},
  },
  watch: {
    glBook(curValue) {
      if (curValue == null) {
        this.$store.dispatch("glPettyCash/resetPettyCash");
        this.showGlBookModal = true;
      }
    },
  },
};
</script>
