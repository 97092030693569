export default {
    setPrepaid(state, payload){
        // state.project.length = 0; 
        // console.log("payload.page:",payload.page);
        state.prepaids = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },
    setSelectedPrepaid(state, payload){        
    //  console.log("setSelectedPrepaid:",payload);
        state.selectedPrepaid = payload
    },
    setPrepaidReceipt(state, payload){
        state.prepaidReceipts = payload.data
        state.totalRecords = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },
    setBlob(state, payload){        
        // console.log("setBlob:",payload);
        state.blob = payload
    },
};