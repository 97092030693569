<template>
  <PvDialog
    :breakpoints="{ '1280px': '30vw', '640px': '40vw' }"
    :style="{ width: '30vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pb-1">
          <label class="font-lg">{{ lang.calculatePenalty }}</label>
        </div>
        <div>
          <PvButton
            icon="pi pi-times"
            class="p-button-link p-icon-secondary pb-0 pt-0"
            style="width: 15px"
            @click="closeDialog()"
          />
        </div>
      </div>

      <PvDivider></PvDivider>
    </template>

    <section class="flex flex-wrap" :class="textFormClass">
      <div class="lg:col-11 md:col-11 col-11 pl-0 pr-2">
        <label for="date">{{ lang.calculatePenaltyDate }}</label>
        <PvCalendar
          id="date"
          v-model="date"
          class="inputfield w-full mt-1"
          showIcon
          dateFormat="dd/mm/yy"
        />
      </div>
    </section>

    <template #footer>
      <div class="flex justify-content-between align-items-center">
        <PvButton
          :label="lang.close"
          class="p-button-outlined p-button-secondary w-full ml-3"
          @click="closeDialog()"
        />
        <PvButton :label="lang.confirm" class="w-full mr-3" @click="confirm()" />
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  props: {
    isOpen: {
      type: Boolean,
    },
  },
  emits: ["close", "confirm"],
  setup() {
    const { lang } = useLang();
    const { textFormClass, } = useTextStyle();
    const { formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.receipt.listing,
          ...lang.th.ar.receipt.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.receipt.listing,
          ...lang.en.ar.receipt.edit,
        };
      }
    });

    return {
      lang: mergeLang,

      textFormClass,

      formatDateToISOString,
    };
  },
  data() {
    return {
      date: null,
    };
  },
  created() {
    this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    initiateData() {
      this.date = new Date();
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    confirm() {
      this.$emit("confirm",this.selectedDateStart);
      this.initiateData();
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    selectedDateStart() {
      let date = null;
      if (this.date) {
        date = this.formatDateToISOString(this.date);
      }
      return date;
    },
  },
};
</script>
