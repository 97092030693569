<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18.2186 20.8627C19.8763 20.8627 21.2198 19.5191 21.2198 17.8614V10.5254C21.2198 9.59898 20.7927 8.72461 20.0614 8.15638L14.0589 3.48743C12.9754 2.64408 11.4578 2.64408 10.3733 3.48743L4.37082 8.15638C3.63952 8.72461 3.21234 9.59898 3.21234 10.5254V17.8614C3.21234 19.5191 4.5559 20.8627 6.21359 20.8627"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M18.2186 20.8627H6.21359"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M10.2153 10.8585V11.8589"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M14.2169 10.8585V11.8589"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M15.7175 15.5485C15.7175 15.5485 14.405 16.861 12.2161 16.861C10.0272 16.861 8.71463 15.5485 8.71463 15.5485"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: 24,
		},
		height: {
			type: [Number, String],
			default: 24,
		},
		iconColor: {
			type: String,
			default: "secondary",
		},
	},
	computed: {
		strokeColor() {
			return "p-stroke-" + this.iconColor;
		},
	},
};
</script>

<style lang="scss" scoped>
svg {
	display: inline-block;
	vertical-align: baseline;
	margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
