export default {
  setTax(state, payload) {
    let taxes = [...state.tax.taxes];
    for (const tax of payload.taxes) {
      if(taxes.findIndex(c => c.id === tax.id) < 0){
        taxes.push(tax);
      }
    }
    state.tax.taxes = taxes;
    state.tax.currentPage = payload.currentPage;
    state.tax.totalPage = payload.totalPage;
  },
  resetTax(state) {
    const tax = {
      taxes: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.tax = tax;
  },
};
