export default {
  title: "Edit receipt visitor control",
  labelCode: "Receipt code.",
  labelDate: "Receipt date.",
  labelUnit: "Unit code.",
  labelCustomer: "Customer name.",
  labelPayType: "Payment type.",
  labelTotalAmount: "Grand total.",
  labelBookBank: "Transfer to.",
  labelPayDate: "Transfer date.",
  labelBranch: "Branch",
  labelPayReferCode: "Pay reference code.",
  labelPaidBy: "Payer.",
  labelPaidByAddress: "Payer address",
  labelMultiReceipts: "Multi receipt.",
	cashType: "Cash",
	transferType: "Transfer",
	chequeType: "Cheque",
	creditCardType: "Credit Card",
  validateNoBookbank: "Please select book bank",
  validateNoBookbankBranch: "Please enter book bank branch.",
  validateNoPayReference: "Please enter:no.",
  validateReferenceBank: "Can't update document because already have reference bank document.",
  validateReferenceVoucher: "Can't update document because already have reference voucher document.",
};
