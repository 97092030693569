export default {
	title: "Meter Register",
	createMeter: "Create Meter",
	autoCreateMeter: "Auto Create Meter",

	columnCode: "Code",
	columnUnitCode: "Unit Code",
	columnAddressNo: "Address No",
	columnActive: "Status",
	columnActiveDate: "Active Date",
	columnRemark: "Remark",
	columnProductCode: "Product Code",
	columnProductName: "Product Name",
	columnEditMeter: "Edit Meter",
	columnDeleteMeter: "Delete Meter",
	askForDeleteMeter: "Are you sure you want to delete this Meter?",
	errorMeterUsage: "You can't delete this Meter because there are references.",

	waterType: "Water",
	electricityType: "Electricity",
	coolingAirType: "Cooling Air",
};
