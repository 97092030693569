import { useStore } from "vuex";
import { ref, computed } from "vue";
export default function useGetGlBook() {
  const store = useStore();
  let searchText = ref(null);

  const glBooks = computed(() => {
    return store.getters["shared/glBookGls"];
  });

  const glBookIsLastPage = computed(() => {
    return store.getters["shared/glBookIsLastPage"];
  });

  const filteredGlBooks = computed(() => {
    if (!searchText.value) {
      //ช่องว่าง
      const result = [...glBooks.value];
      return result;
    } else {
      return glBooks.value.filter((glBook) => {
        return glBook.generalLedgerBookCode
          .toLowerCase()
          .includes(searchText.value.toLowerCase())
          || glBook.generalLedgerBookName
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const searchGlBook = function (event) {
    //  console.log("searchGlBook=",event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchText.value = "";
      searchText.value = null;
    } else {
      searchText.value = event.query;
    }
  };

  let isSharedGlBookReady = ref(true);

  const getAllGlBooks = async function () {
    if (!glBookIsLastPage.value) {
      await store.dispatch("shared/getAllGlBooks");
    }
  };

  const SetBookCode = async function (payload) {
    isSharedGlBookReady.value = true;
    
    await store.dispatch("shared/resetGlBook");
    // console.log("111SetBookCode=",payload);

      if(payload == "RV" || payload == "SJ"){  
        const where = {
          generalLedgerBookCode: payload
        };
        // console.log("99onMounted_searchReceiptBook",where);
        await store.dispatch("shared/getReceiptGlBooks",where);

        // console.log("GetglBooks",glBooks.value.length)
        if(glBooks.value.length <= 0){ //ถ้าไม่มีข้อมูลแสดงทั้งหมด
          await getAllGlBooks(); 
        }
      } 
      else if(payload == "PC" ){  
        const where = {
          PettyCashCode: payload
        };        
        await store.dispatch("shared/getReceiptGlBooks",where);
        // await store.dispatch("shared/getPettyCashGlBooks",where);
      }
      else if(payload == "All" ){  
        const where = {
          VoucherCode: null
        };        
        await store.dispatch("shared/getAllGlBooks",where);
        // await store.dispatch("shared/getPettyCashGlBooks",where);
      }
      else{  
       // await getAllGlBooks("!PC");    const where = {
        const where = {
          VoucherCode: "!PC"
        };          
        await store.dispatch("shared/getAllGlBooks",where); 
      }
      isSharedGlBookReady.value = false;
  };

  return {
    searchGlBook,
    filteredGlBooks,
    searchText,
    isSharedGlBookReady,
    getAllGlBooks,
    SetBookCode,
    glBooks,
  };
}
