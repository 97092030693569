export default {
	editTitle: "แก้ไขทะเบียนมิเตอร์",
	addTitle: "สร้างทะเบียนมิเตอร์",
	updateMeterData: "ปรับปรุงข้อมูลมิเตอร์",
	saveMeter: "ยืนยัน",
	newMeter: "เพิ่มมิเตอร์",

	code: "รหัสมิเตอร์",
	unitCode: "รหัสห้อง/บ้าน",
	addressNo: "บ้านเลขที่",
	active: "สถานะการใช้งาน",
	activeDate: "วันที่ติดตั้ง",
	remark: "หมายเหตุ",
	productCode: "รายรับ",

	validateNoCode: "กรุณากรอกรหัสมิเตอร์",
	validateNoProduct: "กรุณาเลือกรายรับ",

	warrningUnit: "ไม่มีรายการค่าใช้จ่ายที่ผูกไว้",
	warrningProduct: "",

	errorMeterDuplicate: "รหัสมิเตอร์ซ้ำ",
	errorMeterActiveDate: "วันที่ติดตั้ง มากกว่า วันที่มีการจดมิเตอร์",

	deleteMeterTitle: "ยืนยันการลบข้อมูล",
	deleteMeterSuccessTitle: "ลบสำเร็จ",
	deleteMeterSuccess: "ลบมิเตอร์นี้สำเร็จ",
	validateActiveDate: "กรุณาเลือกวันที่ติดตั้ง",
};
