export default {
  async getDebtFreeApproves(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Debtfree/DebtfreeApprove";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }
    if (payload.page) {
      url = url + "&page=" + payload.page;
    }
    if (payload.projectId) {
      url = url + "&projectId=" + encodeURI(payload.projectId);
    }
    if (payload.dateStart) {
        url = url + "&dateStart=" + payload.dateStart;
    }
    if (payload.dateEnd) {
        url = url + "&dateEnd=" + payload.dateEnd;
    }
    if (payload.unitCodeAndAddressNo) {
        url = url + "&unitCodeAndAddressNo=" + payload.unitCodeAndAddressNo;
    }
    if (payload.ownerName) {
        url = url + "&ownerName=" + payload.ownerName;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get debt free approve.");
      throw error;
    }

    // console.log("debtFreeApproves",responseData);

    context.commit("setDebtFreeApproves", responseData);
  },
  async getDebtFreeApproveById(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + '/Debtfree/DebtfreeApprove/' + payload;
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || `Failed to get debt free approve id : ${payload}`);
      throw error;
    }

    // console.log("getDebtFreeApproveById", responseData);

    context.commit("setSelectedDebtFreeApprove", responseData.data);
  },
  async approveDebtfree(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Debtfree/DebtfreeApprove/Approve";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to approve debtfree');
      throw error;
    }
    
    context.commit("setSelectedDebtFreeApprove", responseData.data);       
  },
};
