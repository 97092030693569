<template>
    <base-toolbar 
        :title="lang.paymentRequestManagement"
        :dataButtons="toolbarDataButtons"
        @goBack="goBack"
        @gotoCreate="gotoEdit('create')"
    ></base-toolbar>
    <base-reference-document-dialog  
        v-model:visible="showReferenceDocument" 
        @close="showReferenceDocument = false"
        :dataTable="paymentRequestReference"
        :columns="columnsReferenceDocument"
    ></base-reference-document-dialog>

    <base-container>
        <payment-request-auto-gl-dialog
            v-model:visible="showAutoGlDialog"
            :key="keyToReLoad"
            @close="closeAutoGlDialog"
            @confirm="confirmAutoGlDialog"
        ></payment-request-auto-gl-dialog>
        <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5" :class="textFormClass">
            <div class="flex flex-wrap justify-content-between align-items-center">
                <label class="font-lg font-normal">{{ lang.paymentRequest }}</label>
                <PvButton class="p-button-outlined ml-3" @click="gotoAutoGl">
                    <icon-accounting-calculator-circle iconColor="primary" width="16" height="16" />
                    <span class="p-button-label font-normal ml-2">{{ lang.autoGl }}</span>
                </PvButton>
            </div>

            <PvDivider></PvDivider>

            <section
                class="flex flex-wrap justify-content align-items-center"
                :class="textFormClass"
            >
                <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
                    <PvSkeleton
                        v-if="!isPaymentRequestTypeReady"
                        class="inputfield w-full"
                        height="43px"
                    />
                    <payment-request-type-dropdown
                        id="selectedFilterPaymentRequestType"
                        v-model="selectedFilterPaymentRequestType"
                        class="inputfield w-full mr-2"
                        optionValue="code"
                        @load-finish="paymentRequestTypeDataReady"
                        :isAllType="true"
                        v-show="isPaymentRequestTypeReady"
                    />
                </div>
                <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
                    <PvDropdown
                        id="filterSearch"
                        class="inputfield w-full mr-2"
                        :options="filterSearch"
                        optionLabel="name"
                        optionValue="code"
                        v-model="selectedFilterSearch"
                        scrollHeight="300px"
                        @change="changeFilterSearch"
                    />
                </div>
                <div
                    class="flex lg:col-6 md:col-12 col-12 pl-0 pr-2 mr-2"
                    v-if="isSearchDate"
                >
                    <div class="col-6 pl-0 pr-2">
                        <PvCalendar
                        id="dateStart"
                        v-model="dateStart"
                        class="inputfield w-full"
                        showIcon
                        dateFormat="dd/mm/yy"
                        />
                    </div>
                    <label for="dateEnd" class="pt-1 mt-3 mr-2">{{ "-" }}</label>
                    <div class="col-6 pl-0 pr-2">
                        <PvCalendar
                        id="dateEnd"
                        v-model="dateEnd"
                        class="inputfield w-full"
                        showIcon
                        dateFormat="dd/mm/yy"
                        />
                    </div>
                </div>
                <div
                    class="flex lg:col-6 md:col-12 col-12 pl-0 pr-0 mr-2"
                    v-if="isSearchOther"
                >
                    <div class="col-12 pl-0 pr-0">
                        <span class="p-input-icon-left w-full">
                        <i class="pi pi-search" />
                        <PvInputText
                            class="inputfield w-full"
                            v-model="searchText"
                            :placeholder="lang.searchTextPlaceholder"
                            @keypress="checkInputSearch"
                            autofocus
                        />
                        </span>
                    </div>
                </div>
                <div
                    class="flex lg:col-6 md:col-12 col-12 pl-0 pr-2 mr-2"
                    v-if="isSearchAmount"
                >
                    <div class="col-4 pl-0 pr-2">
                        <PvInputNumber
                            id="totalAmountStart"
                            class="inputfield w-full"
                            inputClass="text-right w-full"
                            v-model="totalAmountStart"
                            mode="decimal"
                            :minFractionDigits="2"
                            :maxFractionDigits="2"
                            @focus="$event.target.select()"
                        />
                    </div>
                    <label for="to" class="pt-1 mt-3 mr-2">{{ "-" }}</label>
                    <div class="col-4 pl-0 pr-2">
                        <PvInputNumber
                            id="totalAmountEnd"
                            class="inputfield w-full"
                            inputClass="text-right w-full"
                            v-model="totalAmountEnd"
                            mode="decimal"
                            :minFractionDigits="2"
                            :maxFractionDigits="2"
                            @focus="$event.target.select()"
                        />
                    </div>
                    <div class="col-4 pl-0 pr-2">
                        <PvDropdown
                            id="filterSortTotalAmount"
                            class="inputfield w-full mr-2"
                            :options="filterSortTotalAmount"
                            optionLabel="name"
                            optionValue="code"
                            v-model="selectedFilterSortTotalAmount"
                            scrollHeight="300px"
                        />
                    </div>
                </div>
                <PvButton @click="getPaymentRequests">
                    <span class="font-normal">{{ lang.search }}</span>
                </PvButton>
            </section>

            <div class="p-datatable-border my-2">
                <PvDataTable
                    :value="paymentRequests"
                    v-model:selection="selectedPaymentRequest"
                    selectionMode="single"
                    dataKey="id"
                    responsiveLayout="scroll"
                    class="p-datatable-xs"
                    :class="textTableClass"
                    :loading="isLoading"
                    @row-dblclick="gotoEdit(selectedPaymentRequest.id)"
                    scrollable
                >
                    <template #empty>
                        <div class="w-full p-3" style="text-align: center">
                            <label>{{ lang.tableEmpty }}</label>
                        </div>
                    </template>
                    <PvColumn
                        field="code"
                        :header="lang.documentCode"
                        style="min-width: 10rem; height: 56px"
                        headerClass="h-left"
                        bodyClass="text-left"
                        sortable
                    />
                    <PvColumn
                        field="date"
                        :header="lang.documentDate"
                        style="min-width: 10rem; height: 56px"
                        headerClass="h-left"
                        bodyClass="text-left"
                        sortable
                    >
                        <template #body="slotProps">
                        {{ formatDate(slotProps.data.date) }}
                        </template>
                    </PvColumn>
                    <PvColumn
                        field="paymentRequestTypeName"
                        :header="lang.type"
                        style="min-width: 10rem; height: 56px"
                        headerClass="h-left"
                        bodyClass="text-left"
                        sortable
                    />
                    <PvColumn
                        field="supplierName"
                        :header="lang.supplier"
                        style="min-width: 16rem; height: 56px"
                        headerClass="h-left"
                        bodyClass="text-left"
                    />
                    <PvColumn
                        field="netAmount"
                        :header="lang.columnTotalAmount"
                        style="min-width: 10rem; height: 56px"
                        headerClass="h-right"
                        bodyClass="text-right"
                        sortable
                    >
                        <template #body="slotProps">
                        <span class="text-right w-full">{{
                            formatCurrency(slotProps.data.netAmount)
                        }}</span>
                        </template>
                    </PvColumn>
                    <PvColumn
                        field="expenseVoucherPostedStatus"
                        :header="lang.columnExpense"
                        style="min-width: 12rem; height: 56px"
                        headerClass="h-left"
                        bodyClass="text-left"
                    >
                        <template #body="{ data, field }">
                            <base-tag
                                v-if="data.expenseVoucherPostedStatus != ''"
                                :Caption="classTagStatus(data[field]).label"
                                :Class="classTagStatus(data[field]).class"
                            />
                        </template>
                    </PvColumn>
                    <PvColumn
                        field="paymentVoucherPostedStatus"
                        :header="lang.columnPayment"
                        style="min-width: 10rem; height: 56px"
                        headerClass="h-left"
                        bodyClass="text-left"
                    >
                        <template #body="{ data, field }">
                            <base-tag
                                v-if="data.paymentVoucherPostedStatus != ''"
                                :Caption="classTagStatus(data[field]).label"
                                :Class="classTagStatus(data[field]).class"
                            />
                        </template>
                    </PvColumn>
                    <PvColumn
                        field="isCancel"
                        :header="lang.cancel"
                        style="min-width: 6rem; height: 56px"
                        headerClass="h-left"
                        bodyClass="text-left"
                    >
                        <template #body="{ data, field }">
                            <base-tag
                                v-if="data[field] == 'Y'"
                                :Caption="lang.cancel"
                                Class="error"
                            />
                        </template>
                    </PvColumn>
                    <PvColumn
                        style="min-width: 8rem"
                        headerClass="h-center"
                        bodyStyle="text-align: center; justify-content: center; align-items: center;"
                        alignFrozen="right"
                        frozen
                    >
                        <template #body="slotProps">
                            <PvButton
                                type="button"
                                class="p-button-secondary p-button-text p-0 mr-3"
                                @click="gotoEdit(slotProps.data.id)"
                            >
                                <icon-pen-edit iconColor="secondary" width="24" height="24" />
                            </PvButton>
                            <PvButton
                                type="button"
                                class="p-button-secondary p-button-text p-0"
                                @click="openReferenceDocument(slotProps.data.id)"
                            >
                                <icon-attachment-link width="24" height="24"/>
                            </PvButton>
                        </template>
                    </PvColumn>

                    <template #footer>
                        <PvPaginator
                            :rows="rowsPerPage"
                            :totalRecords="totalRecords"
                            @page="onPageChange($event)"
                            :rowsPerPageOptions="[10, 50, 100]"
                            class="p-pagination-radius"
                            :first="showCurrentPage"
                        >
                            <template #start="slotProps">
                                {{ lang.page }}:
                                {{ totalPage ? slotProps.state.page + 1 : 0 }}/{{ totalPage }}
                            </template>
                        </PvPaginator>
                    </template>

                </PvDataTable>
            </div>

        </div>
    </base-container>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref, onMounted, computed, watch } from "vue";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import BaseDialog from "../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import useGetRoleByBook from "../../hooks/getRoleByBook.js";
import PaymentRequestTypeDropdown from "../../components/PaymentRequest/PaymentRequestTypeDropdown.vue";
import PaymentRequestAutoGlDialog from "../../components/PaymentRequest/PaymentRequestAutoGlDialog.vue";
export default {
    components: {
        PaymentRequestTypeDropdown,
        PaymentRequestAutoGlDialog,
    },
    props: ["projectCode"],
    setup() {
        const router = useRouter();
        const store = useStore();
        const { lang } = useLang();
        const mergeLang = computed(function () {
            if (store.getters.selectedLanguage === "TH") {
                return {
                ...lang.th.base,
                ...lang.th.ap.paymentRequest.listing,
                };
            } else {
                return {
                ...lang.en.base,
                ...lang.en.ap.paymentRequest.listing,
                };
            }
        });
        const { textTableClass, textFormClass } = useTextStyle();
        const { formatDateToISOString, formatDate, formatCurrency } = useFormat();
        const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();

        let isLoading = ref(false);
        let dataConfigDialog = ref(null);
        let paymentRequestTypeList = ref([]);
        let selectedFilterPaymentRequestType = ref(null);
        let filterSearch = ref([
            { name: mergeLang.value.filterSearchDate, code: "date" },
            { name: mergeLang.value.documentCode, code: "code" },
            { name: mergeLang.value.supplier, code: "supplier" },
            { name: mergeLang.value.filterSearchAmount, code: "amount" },
        ]);
        let selectedFilterSearch = ref("date");
        let dateStart = ref(null);
        let dateEnd = ref(null);
        let searchText = ref("");
        let totalAmountStart = ref(0);
        let totalAmountEnd = ref(0);
        let filterSortTotalAmount = ref([
            { name: mergeLang.value.totalAmountAscending, code: "asc" },
            { name: mergeLang.value.totalAmountDescending, code: "desc" },
        ]);
        let selectedFilterSortTotalAmount = ref("asc");
        let selectedPage = ref(1);
        let rowsPerPage = ref(10);
        let selectedPaymentRequest = ref(null);
        let storageName = ref("");
        let showAutoGlDialog = ref(false);
        let keyToReLoad = ref(0);
        let isPaymentRequestTypeReady = ref(false);
        let showReferenceDocument = ref(false);
        let columnsReferenceDocument =  ref([
            {
                field: "itemNo",
                header: mergeLang.value.columnItemNo,
                type: "string",
                style: "width: 5%;height: 56px;",
                headerClass: "h-left font-sm font-normal",
                bodyClass: "text-left p-text-secondary",
            },
            {
                field: "ledgerBookCode",
                header: mergeLang.value.columnBookCode,
                type: "string",
                style: "width: 10%",
                headerClass: "h-left font-sm font-normal",
                bodyClass: "text-left p-text-secondary",
            },
            {
                field: "ledgerBookName",
                header: mergeLang.value.columnBookName,
                type: "string",
                style: "width: 15%",
                headerClass: "h-left font-sm font-normal",
                bodyClass: "text-left p-text-secondary",
            },
            {
                field: "referenceCode",
                header: mergeLang.value.columnReferenceCode,
                type: "string",
                style: "width: 15%",
                headerClass: "h-left font-sm font-normal",
                bodyClass: "text-left p-text-secondary",
            },
            {
                field: "referenceDate",
                header: mergeLang.value.columnReferenceDate,
                type: "date",
                style: "width: 10%;",
                headerClass: "h-left font-sm font-normal",
                bodyClass: "text-left p-text-secondary",
            },
        ]);

        onMounted(async() => {
            initiateData();
            await roleByBookDataReady();
        });

        //computed
        const toolbarDataButtons = computed(() => {
            let dataButtons = [
                {
                id: "create",
                caption: mergeLang.value.createPaymentRequest,
                class: "mr-2",
                icon: "icon-plus-add",
                iconColor: "white",
                action: "gotoCreate",
                isShow: true,
                },
            ];
            return dataButtons;
        });
        const roleByMenu = computed(() => {
            return getRoleByBookByCode("PQ");
        });
        const isRoleCreate = computed(() => {
            if (roleByMenu.value && roleByMenu.value.insert == "Y") {
                return true;
            }
            return false;
        });
        const isRoleSelect = computed(() => {
            if (roleByMenu.value && roleByMenu.value.select == "Y") {
                return true;
            }
            return false;
        });
        const selectedProject = computed(() => {
            return store.getters.selectedProject;
        });
        const userCode = computed(() => {
            return store.getters.userCode;
        });
        const paymentRequests = computed(() => {
            return store.getters["paymentRequest/paymentRequests"];
        });
        const totalPage = computed(() => {
            return store.getters["paymentRequest/totalPage"];
        });
        const totalRecords = computed(() => {
            return totalPage.value * rowsPerPage.value;
        });
        const showCurrentPage = computed(() => {
            let page = 0;
            page = rowsPerPage.value * (selectedPage.value - 1);
            return page;
        });
        const isSearchDate = computed(() => {
            if (selectedFilterSearch.value == "date") {
                return true;
            } else {
                return false;
            }
        });
        const isSearchOther = computed(() => {
            if (selectedFilterSearch.value != "date" && selectedFilterSearch.value != "amount") {
                return true;
            } else {
                return false;
            }
        });
        const isSearchAmount = computed(() => {
            if (selectedFilterSearch.value == "amount") {
                return true;
            } else {
                return false;
            }
        });

        const payloadGetDatas = computed(() => {
            const payload = {
                page: selectedPage.value,
                limit: rowsPerPage.value,
                projectId: selectedProject.value.id,
                paymentRequestType: selectedFilterPaymentRequestType.value,
                dateStart: selectedFilterSearch.value == "date" ? formatDateToISOString(dateStart.value) : null,
                dateEnd: selectedFilterSearch.value == "date" ? formatDateToISOString(dateEnd.value) : null,
                paymentRequestCode: selectedFilterSearch.value == "code" ? searchText.value : "",
                supplierName: selectedFilterSearch.value == "supplier" ? searchText.value : "",
                totalAmountStart: totalAmountStart.value ? totalAmountStart.value : 0,
                totalAmountEnd: totalAmountEnd.value ? totalAmountEnd.value : 0,
                isAscending: isSearchAmount.value && selectedFilterSortTotalAmount.value == "asc" ? true : false
            };
            return payload;
        });

        const paymentRequestReference = computed(() => {
            if (store.getters["paymentRequest/paymentRequestReference"]) {
                return store.getters["paymentRequest/paymentRequestReference"].map((item, index) => {
                    return {
                        ...item,
                        itemNo: index + 1
                    };
                });
            } else {
                return store.getters["paymentRequest/paymentRequestReference"]
            }
        });


        //methods
        // const setLoading = (bool) => {
        //     store.dispatch("setLoading", { value: bool });
        // };

        const goBack = () => {
            router.push({ name: "home"});
        };

        const initiateData = () => {
            let date = new Date();
            isLoading.value = false;
            dataConfigDialog.value = null;
            selectedFilterPaymentRequestType.value = "";
            dateStart.value = new Date(date.getFullYear(), date.getMonth(), 1);
            dateEnd.value = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            searchText.value = "";
            totalAmountStart.value = 0;
            totalAmountEnd.value = 0;
            storageName.value = `erp_search_paymentRequest_${selectedProject.value.code}_${userCode.value}`;
            keyToReLoad.value = 0;
        };

        const roleByBookDataReady = async () => {
            if (isSharedRoleByBookReady.value) {
                await setLocalSearch();
                await getPaymentRequests();
            }
        };

        const setLocalSearch = async () => {
            const localSearch = localStorage.getItem(storageName.value);
            if (localSearch) {
                let search = JSON.parse(localSearch);
                if (search.entry === "entry") {
                    if (search.dateStart != null && search.dateStart != "") {
                        selectedFilterSearch.value = "date";
                        dateStart.value = new Date(search.dateStart);
                    } else {
                        dateStart.value = "";
                    }

                    if (search.dateEnd != null && search.dateEnd != "") {
                        selectedFilterSearch.value = "date";
                        dateEnd.value = new Date(search.dateEnd);
                    } else {
                        dateEnd.value = "";
                    }

                    if (search.paymentRequestCode != null && search.paymentRequestCode != "") {
                        selectedFilterSearch.value = "code";
                        searchText.value = search.paymentRequestCode;
                    } 
                    else if (search.supplierName != null && search.supplierName != "") {
                        selectedFilterSearch.value = "supplier";
                        searchText.value = search.supplierName;
                    } 
                    else {
                        this.searchText = "";
                    }
                     
                    if (search.totalAmountStart != null && search.totalAmountStart != "") {
                        selectedFilterSearch.value = "amount";
                        totalAmountStart.value = search.totalAmountStart;
                    } else {
                        totalAmountStart.value = 0;
                    }

                    if (search.totalAmountEnd != null && search.totalAmountEnd != "") {
                        selectedFilterSearch.value = "amount";
                        totalAmountEnd.value = search.totalAmountEnd;
                    } else {
                        totalAmountEnd.value = 0;
                    }

                    rowsPerPage.value = search.rowsPerPage;          
                    selectedPage = search.selectedPage;
                    search.entry = "";

                    localStorage.setItem(storageName.value, JSON.stringify(search));
                }
            }
        };

        const validateSearchButton = () => {
            if (isSearchDate.value && (!dateStart.value || !dateEnd.value)) {
                return true;
            } 
            else if (isSearchOther.value && searchText.value == "") {
                return true;
            } 
            else if (isSearchAmount.value && (totalAmountStart.value == null || totalAmountEnd.value == null)) {
                return true;
            }
            return false;
        };

        const getPaymentRequests = async () => {
            if (validateSearchButton()) {
                setDialog({
                    classTitle: "",
                    title: mergeLang.value.notificationDialogHeader,
                    message: mergeLang.value.validateSearchText,
                });
                createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
                return;
            }

            isLoading.value = true;
            try {
                await store.dispatch("paymentRequest/getPaymentRequests", payloadGetDatas.value);
                localStorage.setItem(storageName.value ,JSON.stringify(payloadGetDatas.value));
            } catch (error) {
                setDialog({
                    classTitle: "error",
                    title: mergeLang.value.notificationDialogHeader,
                    message: error,
                });
                createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
                isLoading.value = false;
            }
            isLoading.value = false;
        };

        const onPageChange = async (event) => {
            if (event.rows != rowsPerPage.value) {
                selectedPage.value = 1;
            } else {
                selectedPage.value = event.page + 1;
            }
            rowsPerPage.value = event.rows;
            await getPaymentRequests();
        };

        const changeFilterSearch = () => {
            initiateData();
        };

        const checkInputSearch = async (event) => {
            if (selectedFilterSearch.value == "code") {
                const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
                const keyPressed = event.key;
                if (event.key != "Enter" && !keysAllowed.includes(keyPressed)) {
                event.preventDefault();
                }
            }

            if (event.key == "Enter") {
                await getPaymentRequests();
            }
        };

        const gotoEdit = (id) => {
            if (id == "create" && !isRoleCreate.value) {
                setDialog({
                    classTitle: "",
                    title: mergeLang.value.notificationDialogHeader,
                    message: mergeLang.value.validateRoleInsert,
                });
                createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
                return true;
            } else if (id != "create" && !isRoleSelect.value) {
                setDialog({
                    classTitle: "",
                    title: mergeLang.value.notificationDialogHeader,
                    message: mergeLang.value.validateRoleSelect,
                });
                createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
                return true;
            }

            router.push({ name: "payment-request-edit", params: { id: id } });
        };

        const openReferenceDocument = async (id) => {
            isLoading.value = true;
            try {
                let payload = {
                    id: id,
                    isListPage: true,
                };
                await store.dispatch("paymentRequest/getPaymentRequestReferece", payload);
                isLoading.value = false;
                showReferenceDocument.value = true;
            } catch (error) {
                isLoading.value = false;
                setDialog({
                    classTitle: "error",
                    title: mergeLang.value.notificationDialogHeader,
                    message: error,
                });
                createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
            }
        };

        const paymentRequestTypeDataReady = () => {
            isPaymentRequestTypeReady.value = true;
            selectedFilterPaymentRequestType.value = "";
        };

        const gotoAutoGl = () => {
            showAutoGlDialog.value = true;
            keyToReLoad.value++;
        };

        const closeAutoGlDialog = () => {
            showAutoGlDialog.value = false;
        };

        const confirmAutoGlDialog = () => {
            showAutoGlDialog.value = false;
            router.push({ name: "preview-payment-request-auto-gl" });
        };

        const setDialog = (dataConfig) => {
            const classMap = {
                success: "p-text-success",
                error: "p-error",
            };

            dataConfig.classTitle = classMap[dataConfig.classTitle] || "";

            dataConfig.button = [
                {
                id: "close",
                caption: mergeLang.value.close,
                class: "p-button-outlined p-button-secondary w-full",
                action: "cancel",
                },
            ];

            dataConfigDialog.value = {
                titleConfirm: dataConfig.title,
                classTitle: dataConfig.classTitle,
                wordingConfirm: dataConfig.message,
                dataButtons: dataConfig.button,
                display: true,
            };
        };

        const classTagStatus = (data) => {
            let status = {
                label: "",
                class: "",
            };
            if (data == "Partial") {
                status.label = mergeLang.value.partialStatus;
                status.class = "warning";
            } else if (data == "Posted") {
                status.label = mergeLang.value.postedStatus;
                status.class = "success";
            }
            return status;
        }

        watch(isSharedRoleByBookReady, async () => {
            roleByBookDataReady();
        });

        return {
            lang: mergeLang,
            textTableClass,
            textFormClass,
            formatDate,
            formatCurrency,

            //data
            isLoading,
            selectedFilterPaymentRequestType,
            paymentRequestTypeList,
            filterSearch,
            selectedFilterSearch,
            dateStart,
            dateEnd,
            searchText,
            totalAmountStart,
            totalAmountEnd,
            filterSortTotalAmount,
            selectedFilterSortTotalAmount,
            selectedPaymentRequest,
            rowsPerPage,
            showAutoGlDialog,
            keyToReLoad,
            isPaymentRequestTypeReady,
            showReferenceDocument,
            columnsReferenceDocument,

            //methods
            goBack,
            gotoEdit,
            changeFilterSearch,
            getPaymentRequests,
            checkInputSearch,
            openReferenceDocument,
            onPageChange,
            classTagStatus,
            paymentRequestTypeDataReady,
            gotoAutoGl,
            closeAutoGlDialog,
            confirmAutoGlDialog,

            //computed
            toolbarDataButtons,
            isSearchDate,
            isSearchOther,
            isSearchAmount,
            paymentRequests,
            totalPage,
            totalRecords,
            showCurrentPage,
            paymentRequestReference,
        }
    },
}
</script>