<template>
	<PvAutoComplete
		:dropdown="true"
		:suggestions="filteredAccountCodeEquitys"
		field="codeAndName"
		@complete="searchAccountCodeEquity"
		@dropdown-click="filteredAccountCodeEquitys"
		forceSelection
	/>
</template>

<script>
import useGetAccountCodeEquity from "../../hooks/getAccountCodeEquity.js";
export default {
	setup() {
		const { filteredAccountCodeEquitys, searchAccountCodeEquity, isSharedAccountCodeEquityReady, getAllAccountCodeEquitys,setShowParent } = useGetAccountCodeEquity();

		return {
			filteredAccountCodeEquitys,
			searchAccountCodeEquity,
			isSharedAccountCodeEquityReady,
			getAllAccountCodeEquitys,
            setShowParent
		};
	},
	emits: ["load-finish"],
	props: {
		isLoadingData: {
			type: Boolean,
			default: false
		},
        isShowParent: {
			type: Boolean,
      		default: false,
		},

	},
	mounted() {
        this.setShowParent(this.isShowParent);
		if (this.isLoadingData) {
			this.getAllAccountCodeEquitys();
		}
	},
	watch: {
		isSharedAccountCodeEquityReady(curValue) {
			if (curValue) {
				this.$emit("load-finish");
			}
		},
	},
};
</script>
<!-- ตัวอย่างการเอา AutoComplete ไปใช้

    <base-account-code-equity-auto-complete
    id="selectedAccountCodeEquity"
    v-model="selectedAccountCodeEquity"
    class="inputfield w-full mt-2"
    :isLoadingData="true"
    :isShowParent="false"
    />
    
    <base-account-code-equity-auto-complete
    id="selectedAccountCodeEquity1"
    v-model="selectedAccountCodeEquity1"
    class="inputfield w-full mt-2"
    :isLoadingData="false"
    :isShowParent="true"
    />
-->