export default {
  uploadFile: "Upload File",
  dragFile: "Drag drop file or ",
  addFile: "Add File",
  importFileDesc: "Please add only 1 file at a time with .XLSX or ",
  downloadfile: "Download example file",
  fileName: "File Name",
  validateLimitFile: "Can be upload 1 file at a time",
  validateFileType: "Can't upload file :name",
  validateImportFile: "The allowed file extensions are .XLSX only",
  validateImportNoData: "File : :file no data.",

  validateDataProductCode: "Row :row no enter product code.",
  validateDataLimitTextProduct: "Row :row enter product code more than 25 digits.",
  validateDataRunningNumber: "Row :row no enter running No.",
  validateDataLimitTextRunningNumber: "Row :row enter running no. more than 25 digits.",
  validateDataLimitTextSerialNumber: "Row :row enter serial no. more than 25 digits.",
  validateDataLimitTextStatus: "Row :row invalid status.",
  validateDataLimitTextRemark: "Row :row enter remark more than 255 digits.",

  validateImportRunningNumberDuplicate: "Running no. duplicate in file at item no. ",
  validateImportProductCode: "Invalid product",
  validateImportLimitTextProduct: "Product code more than 25 digits",
  validateImportRunningNumber: "Invalid running no.",
  validateImportLimitTextRunningNumber: "Running no. more than 25 digits",
  validateImportLimitTextSerialNumber: "Serial no. more than 25 digits",
  validateImportStatus: "Invalid status",
  validateImportLimitTextRemark: "Remark more than 255 digits",
  validateImportRemark: "Invalid remark",
  validateImportDate: "Invalid date format",
  
  reupload: "Reupload",
  importAll: "Import All (:value)",
  allStatus: "All status",
  wait: "Wait",
  success: "Success",
  invalid: "Invalid",
  error: "Error",
  statusContent: ":number",
  statusImport: "Import Status",
  reason: "Reason",
  askForNewImport: "Do you want to upload new file to replace old data or not?",
  cannotImport: "Can't Import",
  cannotImportWording: "Because there have “Invalid status” :value item.",
  editAndReuploadWording: "Please edit file and upload again",
  noItemToImportWording: "Unable to save due to no waiting items.",
  creatingStockKeycard: "Creating Stock Keycard",
  generating: "Generating...",
  alidateImportUnitCode: "Invalid unit",
  validateImportRunningNumberDuplicateInDatabase: "Running no. duplicate",
};
