<template>
	<base-toolbar 
        :title="this.title"
		:dataButtons="dataButtons"  
        @goBack="goBack" 
		@gotoDelete="confirmDelete"
        @gotoSave="saveData">
	</base-toolbar>
	<base-dialog
		v-model:visible="showConfirmDialog"
		:titleConfirm="confirmTitle"
		:wordingConfirm="confirmContent"
		:classTitle="confirmClassTitle"
		:dataButtons="isConfirmDialog ? dataConfirmButtons : dataAlertButtons" 
		@confirm="confirmDialog()"
		@close="closeDialog()"
	>
	</base-dialog>
	<base-container>
		<div class="surface-card shadow-2 p-5 border-round my-5">
			<div class="flex justify-content-between align-items-center">
				<label class="font-lg font-normal">{{ lang.editTitle }}</label>
			</div>
			<PvDivider></PvDivider>
			<section class="flex flex-wrap fontlabel" :class="textFormClass">
				
				<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
					<!-- <label>{{ lang.labelCode }}</label>  -->
					<div class="flex justify-content-between">
						<label for="code">{{ lang.labelCode }}</label>
						<small style="color: #bebebe" class="mr-2">
							{{ TextLength }}/{{ limitMessage }}</small
						>
					</div>
					<div class="mt-1">
						<PvInputText
							id="code"
							type="text"
							class="inputfield w-full mt-1"
							v-model="code"
							:placeholder="lang.placeholderCode"
							:class="{ 'p-invalid': codeError }" 
							:maxlength="limitMessage"
							@keyup="validateChangeDataAndDisableSaveButton('codeError')"
						/>
						<small v-if="codeError" id="code-help" class="p-error">
							{{ this.isDuplicateCode ? lang.validateDuplicateCode : lang.validateNoneCode }}
						</small>
					</div>
				</div>	

				
				<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
					<label>{{ lang.labelNameThai }}</label>
					<div class="mt-1">
						<PvInputText
							type="text"
							class="inputfield w-full mt-1"
							v-model="name"
							:placeholder="lang.placeholderNameThai"
							:class="{ 'p-invalid': nameThaiError }" 
							@keyup="validateChangeDataAndDisableSaveButton('nameThaiError')"
						/>
						<small v-if="nameThaiError" id="nameThai-help" class="p-error">
							{{ lang.validateNameThai }}
						</small>
					</div>
				</div>	

				<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
					<label for="activeStatus">{{ lang.labelActiveStatus }}</label>
					<div class="mt-3">
						<PvInputSwitch 
							class="ml-2" 
							v-model="activeStatus" 
							@change="validateChangeDataAndDisableSaveButton"
						/>
					</div>
				</div>


			</section>

			<!-- <section class="flex flex-wrap fontlabel pt-3" :class="textFormClass">
				
				<div>
					<PvButton 
						class="p-button-outlined p-button-danger" 
						@click="confirmDelete"
						v-if="!isCreateDocument"
					>
						<icon-printer
							class="p-button-outlined mr-2"
							iconColor="error"
							height="16"
							width="16"
						/>
						<span style="font-size: 14px">
							{{ lang.buttonDelete }}
						</span>
					</PvButton>
				</div>

			</section> -->

		</div>
	</base-container> 
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import usePageDirty from "../../../hooks/pageDirty";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js"
import _ from 'underscore';
export default {
	props: ["id", "projectCode"],
    setup() {
		const { lang } = useLang();
		const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
		const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
		const { getRoleByMenuByCode, isSharedRoleByMenuReady }  = useGetRoleByMenu();
		const store = useStore();
		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return {
					...lang.th.base,
					...lang.th.master.customerGroup.edit,
				};
			} else {
				return {
					...lang.en.base,
					...lang.en.master.customerGroup.edit,
				};
			}
		});
		const { compareObject } = usePageDirty();
		return {
			lang: mergeLang,
			textHeaderClass,
			textFormClass,
			textTableClass,
			formatCurrency,
			formatDate,
			formatDateToISOString,
			compareObject,
			getRoleByMenuByCode,
			isSharedRoleByMenuReady,
		};
	},
	data() {
		return {
			code: "",
			codeError:false,
			limitMessage: 20,
			isDuplicateCode: false,
			name: "",
			nameThaiError: false,
			customerGroups: null,
			objToCompare: null,
			isDisableSaveButton: true,
			isDeleteCustomerGroup: false,
			showConfirmDialog: false,
			confirmTitle: "",
			confirmContent: "",	
			confirmClassTitle: "", 
			isConfirmDialog: false,
			activeStatus: true,
		};
	},
	async mounted() {
		this.setLoading(true);
		await this.initiateData();
		await this.getCustomerGroups();
		if (!this.isCreateDocument) {
			await this.getCustomerGroup();
			await this.setData();
			this.deleteCustomerGroups(this.id);
		} else {
			await this.initiateData();
		}
		this.setLoading(false);
	},
	methods: {
		async setLoading(bool) {
			this.$store.dispatch("setLoading", { value: bool });
		},
		goBack() {
			if (this.objToCompare) {
				this.compareObject(this.objToCompare, this.payload);
			}
			this.$router.push({name: "customer-group"});
		},
		async initiateData() {
			await this.$store.dispatch("customerGroup/resetCustomerGroup");
			this.code = "";
			this.name = "";
			this.activeStatus = true;
			await this.setCompareData();
			// this.setLoading(false);
		},
		async getCustomerGroups() {
			try {
				let where = {
					projectId: this.selectedProject.id,
				};
				if (this.searchText != null) {
					where = {
						...where,
						searchText: this.searchText,
					};
				}
				const payload = {
					page: 1,
					limit: 100,
					where,
				};
				await this.$store.dispatch("customerGroup/getCustomerGroups", payload);
				this.customerGroups = this.$store.getters["customerGroup/customerGroups"];
			} catch (error) {
				// this.$toast.add({
				// 	severity: "error",
				// 	summary: this.lang.dialogTitleNotification,
				// 	detail: error,
				// 	life: 3000,
				// });
				this.openDialog({
					severity: "error",
					title: this.lang.dialogTitleNotification,
					content: error,
					isConfirm: false,
				});
			}
		},
		async getCustomerGroup() {
			try {
				await this.$store.dispatch("customerGroup/getCustomerGroup", this.id);
			} catch (error) {
				// this.$toast.add({
				// 	severity: "error",
				// 	summary: this.lang.dialogTitleNotification,
				// 	detail: error,
				// 	life: 3000,
				// });
				this.openDialog({
					severity: "error",
					title: this.lang.dialogTitleNotification,
					content: error,
					isConfirm: false,
				});
			}
		},
		async setData() {
			if (this.selectedCustomerGroup) {
				const customerGroup = this.selectedCustomerGroup;
				this.code = customerGroup.code.trim();
				this.name = customerGroup.name.trim();
				this.activeStatus = customerGroup.activeStatus == "Y" ? true : false;
				await this.setCompareData();
			}
		},
		async setCompareData() {
			let type = "add";
			if (!this.isCreateDocument) {
				type = "update";
			}
			const objToCompare = {
				type: type,
				id: this.id,
				projectId: this.selectedProject.id,
				code: this.code,
				name: this.name,
				activeStatus: this.activeStatus == true ? "Y" : "N",
			};
			this.objToCompare = objToCompare;
			this.validateChangeDataAndDisableSaveButton();
			this.compareObject(objToCompare, this.payload);
		},
		deleteCustomerGroups(id){
			const index = this.customerGroups.findIndex((data) => data.id.trim() == id.trim());
			this.customerGroups.splice(index, 1);
		},
		validateChangeDataAndDisableSaveButton(resetValidate) {
			if (!_.isEqual(this.payload, this.objToCompare)) {		
				this.isDisableSaveButton = false;
				if (resetValidate != null || resetValidate != "") {
					if (resetValidate == "codeError") {
						this.codeError = false;
						this.validateDuplicateCode(this.code);
					} else if (resetValidate == "nameThaiError") {
						this.nameThaiError = false;
					}
				}	
			} else {
				this.isDisableSaveButton = true;
			}
		},
		validateDuplicateCode(code) {
			const index = this.customerGroups.findIndex((data) => data.code.trim() == code.trim());
			if (index >= 0) {
				this.codeError = true;
				this.isDuplicateCode = true;
			} else {
				this.codeError = false;
				this.isDuplicateCode = false;
			}		
		},
		validateRole() {
			if (this.isCreateDocument) {
				if (this.isSharedRoleByMenuReady === true && this.roleByMenu.insert === "N") {
					this.openDialog({
						severity: "",
						title: this.lang.notificationDialogHeader,
						content: this.lang.validateRoleInsert,
						isConfirm: false,
					});
					return true;
				}
			} else {
				if (this.isSharedRoleByMenuReady === true && this.roleByMenu.update === "N") {
					this.openDialog({
						severity: "",
						title: this.lang.notificationDialogHeader,
						content: this.lang.validateRoleUpdate,
						isConfirm: false,
					});
					return true;			
					
				}
			}
			return false;
		},
		wordingValidate() {
			if(!this.code) {
				return this.lang.validateFieldNoValue + this.lang.labelCode.replace("*","");
			}

			if(!this.name) {
				return this.lang.validateFieldNoValue + this.lang.labelNameThai.replace("*","");
			}

			if (this.name.trim().length > 50) {
				return this.lang.validateLimitText.replace("{0}",this.lang.labelNameThai.replace("*",""));
			}
		},
		validateData() {
			let returnStatus = false;

			if (!this.code) {
				this.codeError = true;
				returnStatus = true;
			}
			if (!this.name) {
				this.nameThaiError = true;
				returnStatus = true;
			}

			if (this.name.trim().length > 50) {
				returnStatus = true;
			}
			
			// if (this.code.trim().length > 50) {
			// 	this.openDialog({
			// 		severity: "error",
			// 		title: this.lang.notificationDialogHeader,
			// 		content: this.lang.validateLimitText.replace("{0}",this.lang.labelCode.replace("*","")),
					
			// 		isConfirm: false,
			// 	});
			// 	return true;
			// }
			// if (this.name.trim().length > 50) {
			// 	this.openDialog({
			// 		severity: "error",
			// 		title: this.lang.notificationDialogHeader,
			// 		content: this.lang.validateLimitText.replace("{0}",this.lang.labelNameThai.replace("*","")),
			// 		isConfirm: false,
			// 	});
			// 	return true;
			// }

			if(returnStatus) {
				this.openDialog({
					severity: "error",
					title: this.lang.validateSaveTitle,
					content: this.wordingValidate(),
					isConfirm: false,
				});
			}

			return returnStatus;

			// if ( this.code != this.objToCompare.code && this.objToCompare.type === "update") {
			// 	const array1 = ["CR","JV","PC1","PC2","PJ","PV","RV","SJ","WT"];
			// 	if (array1.includes(this.objToCompare.code)) {
			// 		this.openDialog({
			// 		severity: "error",
			// 		title: this.lang.notificationDialogHeader,
			// 		content: this.lang.validateChangeUniqueCode.replace("{0}",this.objToCompare.code),
			// 		isConfirm: false,
			// 		});
			// 		return true ;
			// 	}
      		// }
			// if (!this.isCharactersOrNumbers(this.code)) {
			// 	this.openDialog({
			// 		severity: "error",
			// 		title: this.lang.notificationDialogHeader,
			// 		content: this.lang.validateChangeCode.replace("{0}",this.code),
			// 		isConfirm: false,
			// 	});
			// 	return true ;		
			// }
		},
		// isCharactersOrNumbers(str) {
		// 	return /^[A-Za-z0-9*&@!-_]+$/.test(str);		
		// },
		async saveData() {
			// this.setLoading(true);
			if (this.validateRole()) {
				return;
			}
			if (this.validateData()) 
			{
				// this.setLoading(false);
				return;
			}
			try {
				this.setLoading(true);

				let titleMessage = "";
				let contentMessage = "";
				if (this.payload.type === "add") {
					titleMessage = this.lang.dialogTitleAddSuccess;
					contentMessage = this.lang.dialogContentAddSuccess;
				} else {
					titleMessage = this.lang.dialogTitleEditSuccess;
					contentMessage = this.lang.dialogContentEditSuccess;
				}

				await this.$store.dispatch("customerGroup/createOrUpdateCustomerGroup", this.payload);
				if (this.isCreateDocument) {
					this.$router.replace({name: "customer-group-edit", params:{ id: this.selectedCustomerGroup.id }});
				}
				await this.$store.dispatch("customerGroup/getCustomerGroup", this.selectedCustomerGroup.id);
				await this.setCompareData();
				// this.$toast.add({
				// 	severity: "success",
				// 	summary: this.lang.dialogTitleAlert,
				// 	detail: this.lang.saveSuccess,
				// 	life: 5000,
				// });
				this.openDialog({
					severity: "success",
					title: titleMessage,
					content: contentMessage,
					isConfirm: false,
				});
				this.setLoading(false);
			} 
			catch (error) 
			{	
				let message = error.message;
				if (error.code == "122") {
					message = this.lang.validateParams;
				} 
				else if (error.code == "111") {
					message = this.lang.validateRole;
				} 
				else if (error.code == "101") {
					message = this.lang.validateDuplicate;
				} 
				this.openDialog({
					severity: "error",
					title: this.lang.notificationDialogHeader,
					content: message,
					isConfirm: false,
				});
				this.setLoading(false);
			}
			this.setLoading(false);
		},
		async deleteData() {
			this.setLoading(true);
			try {
				this.setLoading(true);
				await this.$store.dispatch("customerGroup/deleteCustomerGroup", this.id);
				await this.$store.dispatch("customerGroup/resetCustomerGroup");
				this.isDeleteCustomerGroup = true;
				this.openDialog({
					severity: "success",
					title: this.lang.dialogTitleDeleteSuccess,
					content: this.lang.dialogContentDeleteSuccess,
					isConfirm: false,
				});
				this.setLoading(false);
			} 
			catch (error) 
			{
				let message = error.message;
				if (error.code == "122") {
					message = this.lang.validateParams;
				} 
				else if (error.code == "103") {
					message = this.lang.validateNotFound;
				} 
				else if (error.code == "111") {
					message = this.lang.validateRole;
				} 
				else if (error.code == "126") {
					message = this.lang.validateReference;
				} 
				this.openDialog({
					severity: "error",
					title: this.lang.dialogTitleNotification,
					content: message,
					isConfirm: false,
				});
				this.setLoading(false);
			}
			this.setLoading(false);
		},
		confirmDelete() {
			if (this.isSharedRoleByMenuReady === true && this.roleByMenu.delete === "N") {
				this.openDialog({
					severity: "error",
					title: this.lang.notificationDialogHeader,
					content: this.lang.validateRoleDelete,
					isConfirm: false,
				});
				return;
			}
			if (!this.id && this.id !=="create") 
			{
				this.openDialog({
					severity: "error",
					title: this.lang.dialogTitleNotification,
					content: this.lang.validateNoneId,
					isConfirm: false,
				});
				this.setLoading(false);
				return;
			}
			this.openDialog({
				severity: "",
				title: this.lang.dialogTitleDeleteConfirm,
				content: this.lang.dialogContentDeleteConfirm.replace("{0}",this.code),
				isConfirm: true,
			});
		},
		openDialog(dataConfig) {
			if (dataConfig.severity === "") {
				this.confirmClassTitle = "";
			} else if (dataConfig.severity === "success") {
				this.confirmClassTitle = "p-text-success";
			} else if (dataConfig.severity === "error") {
				this.confirmClassTitle = "p-error";
			}
			this.confirmTitle = dataConfig.title; 
			this.confirmContent = dataConfig.content; 
			if (dataConfig.isConfirm) {
				this.isConfirmDialog = true;
			} else {
				this.isConfirmDialog = false;
			}
			this.showConfirmDialog = true;
		},
		async confirmDialog() {
			await this.deleteData();
		},
		closeDialog() {
			if (this.isDeleteCustomerGroup) {
				this.$router.push({name: "customer-group"});
			}
			this.showConfirmDialog = false;	
		}
	},
	computed: {
        title() {
			if (this.isCreateDocument) {
				return this.lang.createCustomerGroup;
			}
			return this.lang.editCustomerGroup;
        },
        selectedProject() {
            return this.$store.getters.selectedProject;
        },
		roleByMenu() {
			return this.getRoleByMenuByCode("tmCOR");
		},
        isCreateDocument() {
            return this.id === "create";
        },
		selectedCustomerGroup() {
			return this.$store.getters["customerGroup/selectedCustomerGroup"];
		},
		dataButtons() {
			let dataButtons = [
				{
					id: "deleteCaption",
					action: "gotoDelete",
					isDisable: this.isCreateDocument,
					type: "delete",
				},
				{
					id: "saveCaption",
					action: "gotoSave",
					// isDisable: this.isDisableSaveButton,
					type:"save",
				},
			]
			return dataButtons
		},
		dataAlertButtons() {
			let dataButtons = [
				{
					id: "close",
					caption: this.lang.close,
          			class: "p-button-outlined p-button-secondary w-full",
					action: "close",
				},
			]
			return dataButtons
		},
		dataConfirmButtons() {
			let dataButtons = [
			{
					id: "close",
					caption: this.lang.close,
					class: "p-button-outlined p-button-secondary w-full",
					action: "close",
				},
				{
					id: "confirm",
					caption: this.lang.buttonDialogConfirm,
					class: "w-full",
					action: "confirm",
				},
			]
			return dataButtons
		},
		payload() {
			let type = "add";
			let payload = {};
			if (!this.isCreateDocument) {
				type = "update";
			}
			payload = {
				type: type,
				id: this.id,
				projectId: this.selectedProject.id,
				code: this.code,
				name: this.name,
				activeStatus: this.activeStatus == true ? "Y" : "N",
			};
			return payload;
		},
		TextLength() {
			if (this.code) return this.code.length;
			else return 0;
		},
    },
}
</script>