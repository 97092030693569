<template>
	<PvAutoComplete
		:dropdown="true"
		:suggestions="filteredPayTypesAddAll"
		field="name"
		@complete="searchPayType"
		@dropdown-click="filteredPayTypesAddAll"
		forceSelection
	/>
</template>

<script>
import useGetPayType from "../../hooks/getPaymentMethod.js";
export default {
	setup() {
		const {
			filteredPayTypes,
			searchText,
			type,
			isSharedPayTypeReady,
			searchPayType,
		} = useGetPayType();

		return {
			filteredPayTypes,
			searchText,
			type,
			isSharedPayTypeReady,
			searchPayType,
		};
	},
	emits: ["load-finish"],
	computed: {
		filteredPayTypesAddAll(){
			let data = [];
            if(this.filteredPayTypes){
				let PayType = [{code: "All",id: "All",name: "All",name2: "All"}];
				data = [...PayType,...this.filteredPayTypes];
			}
			return data
		},
	},
	watch: {
		isSharedPayTypeReady(curValue) {
			if (curValue) {
				this.$emit("load-finish");
			}
		},
	},
};
</script>
