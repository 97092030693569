<template>
  <base-dialog
    v-model:visible="showValidateDialog"
    :classTitle="baseDialogClassTitle"
    :titleConfirm="titleConfirmDialog"
    :wordingConfirm="wordingConfirmDialog"
    :dataButtons="dataAlertButtons"
    @close="closeValidateDialog"
    @confirm-use="confirmUse"
    @confirm="closeValidateDialog"
    @confirm-after-save="afterSaved"
  ></base-dialog>

  <div class="pt-1">
    <label class="font-lg font-semibold">{{ lang.headerUsePrepaid }}</label>
  </div>

  <PvDivider></PvDivider>

  <div>
    <label class="font-md font-normal">{{ lang.confirmUsePrepaid }}</label>
    <div class="pt-4 pb-4">
      <PvCheckbox
        id="usePrepaid"
        inputId="binary"
        v-model="isUsePrepaid"
        :binary="true"
      />
      <label for="usePrepaid" class="pl-3">{{ lang.usePrepaid }}</label>
    </div>
    <div class="pb-6">
      <PvCheckbox
        id="useDeposit"
        inputId="binary"
        v-model="isUseDeposit"
        :binary="true"
      />
      <label for="useDeposit" class="pl-3">{{ lang.useDeposit }}</label>
    </div>
  </div>

  <PvButton
    type="button"
    :label="lang.buttonDialogConfirm"
    icon="pi pi-check"
    @click="askForUse()"
    v-if="isPage"
    :disabled="isLockedButton"
  />
  <div class="flex justify-content-between align-items-center" v-else>
    <PvButton
      :label="lang.close"
      class="p-button-outlined p-button-secondary w-full mr-1 mb-2"
      @click="closeDialog()"
    />
    <PvButton 
      :label="lang.confirm" 
      class="w-full ml-1 mb-2" 
      @click="usePrepaidAndDeposit()"
    />
  </div>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  props: ["page"],
  emits: ["close", "is-used"],
  setup() {
    const { lang } = useLang();
    const store = useStore();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.calculateInvoice.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.calculateInvoice.listing,
        };
      }
    });

    return {
      lang: mergeLang,
    };
  },
  data() {
    return {
      isUsePrepaid: true,
      isUseDeposit: false,

      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirmDialog: this.lang.confirmTitleUsePrepaid,
      wordingConfirmDialog: "",
      actionDataButton: "",
    };
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    initiateData() {
      this.isUsePrepaid = true;
      this.isUseDeposit = false;
      this.showValidateDialog = false;
      this.baseDialogClassTitle = "";
      this.titleConfirmDialog = this.lang.notificationDialogHeader;
      this.wordingConfirmDialog = "";
      this.actionDataButton = "";
    },
    askForUse() {
      // this.actionDataButton = "confirm-use";
      // this.titleConfirmDialog = this.lang.confirmTitleUsePrepaid;
      // this.wordingConfirmDialog = this.lang.confirmUsePrepaid;
      // this.showValidateDialog = true;
      let isBackToHomePage = "Y";
      if (this.$route.query.back == "ar-calculate-invoice") {
        isBackToHomePage = "N";
      }
      this.$router.push({
        name: "ar-preview-use-prepaid-and-deposit",
        params: {
          isUsePrepaid: this.isUsePrepaid ? "Y" : "N",
          isUseDeposit: this.isUseDeposit ? "Y" : "N",
          isBackToHomePage: isBackToHomePage,
        },
      });
    },
    confirmUse() {
      this.showValidateDialog = false;
      if(this.isPage){
        this.usePrepaidAndDeposit()
      }
      this.actionDataButton = "";
    },
    closeValidateDialog() {
      this.showValidateDialog = false;
    },
    closeDialog() {
      this.initiateData();
      this.$emit("close");
    },
    async usePrepaidAndDeposit() {
      // this.showValidateDialog = false;
      // this.setLoading(true);
      // try {
      //   if(this.isUsePrepaid) {
      //     await this.$store.dispatch("arCalculateInvoice/usePrepaid",this.payload);
      //   }
      //   if(this.isUseDeposit) {
      //     await this.$store.dispatch("arCalculateInvoice/useDeposit",this.payload);
      //   }

      //   if(this.isUsePrepaid || this.isUseDeposit) {
      //     if(!this.isPage) {
      //       this.$emit("is-used");
      //     } else {
      //       this.actionDataButton = "confirm-after-save";
      //       this.baseDialogClassTitle = "p-text-success";
      //       this.titleConfirmDialog = this.lang.saveSuccess;
      //       this.wordingConfirmDialog =  this.lang.successfully;
      //       this.showValidateDialog = true; 
      //     }
      //   }
      //   else {
      //     if(!this.isPage) {
      //       this.closeDialog();
      //     }
      //   }

      // } catch (error) {
      //   this.actionDataButton = "";
      //   this.baseDialogClassTitle = "";
      //   this.titleConfirmDialog = this.lang.notificationDialogHeader;
      //   this.wordingConfirmDialog = error;
      //   this.showValidateDialog = true;
      // }
      // this.setLoading(false);
      let isBackToHomePage = "Y";
      if (this.$route.query.back == "ar-calculate-invoice") {
        isBackToHomePage = "N";
      }
      this.$router.push({
        name: "ar-preview-use-prepaid-and-deposit",
        params: {
          isUsePrepaid: this.isUsePrepaid === true ? "Y" : "N",
          isUseDeposit: this.isUseDeposit === true ? "Y" : "N",
          isBackToHomePage: isBackToHomePage,
        },
      });
    },
    afterSaved() {
      if(!this.isPage){
        this.closeDialog()
      }
      else {
        this.showValidateDialog = false;   
      }
    },
  },
  computed: {
    payload() {
      let payload = {
        projectId: this.project.id,
        userCode: this.userCode,
      };

      return payload;
    },
    project() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    isLockedButton() {
      if(this.isUsePrepaid || this.isUseDeposit){
        return false;
      }
      return true;
    },
    isPage() {
      return this.page == "page";
    },
    dataAlertButtons() {
      let data = [
        {
          id: "confirm",
          caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];
      if(this.actionDataButton == "confirm-use") {
        data = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "confirm",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "confirm-use",
          },
        ];
      } 
      else if(this.actionDataButton == "confirm-after-save") {
        data = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "confirm",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "confirm-after-save",
          },
        ];
      }
      return data;
    },
  },
};
</script>
