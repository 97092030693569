export default {
  purchaseOrders(state) {
    return state.purchaseOrders;
  },
  selectedPurchaseOrder(state) {
    return state.selectedPurchaseOrder;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
  blob(state) {
    return state.blob;
  },
  purchaseRequisitions(state) {
    return state.purchaseRequisitions;
  },

};
