export default {
    title: "Refund Guarantee",
    createRefundGuarantee: "Create Refund Guarantee",
    editRefundGuarantee: "Edit Refund Guarantee",
    titleEdit: "Refund Guarantee Detail",
    documentCode: "Document Code",
    documentDate: "Document Date",
    referenceCode: "Reference Code",
    unitCode: "Unit Code",
    customerName: "Customer Name",
    employeeName: "Employee Name",
    searchPendingList: "Search Pending",
    productName: "Produc Name",
    description: "Description",
    refund: "Refund",
    tableEmpty: "Data Empty",
    paidFor: "Paid For",
    netAmount: "Net Amount",
    addEditItem: "Add/ Edit Item",
    product: "Product",
    cancelReason: "Cancel reason",
    validateNoCancelReason: "Please enter cancel reason.",
    cancelRefundGuaranteeSuccess: "Cancel Refund Guarantee :code Success",
    balance: "Balance",
    price: "Price",
    pendingList: "Refund Guarantee Pending List",
    selectedAll: "select All",
    selectedList: "select",
    receiptCode: "Receipt Code",
    receiptDate: "Receipt Date",
    createRefundGuaranteeSuccess: "Create refund guarantee success",
    editRefundGuaranteeSuccess: "Edit refund guarantee success",
    createNewRefundGuarantee: "Create new refund guarantee",
    askForCreateNewDoc: "Are you sure you want to discard change ",
    askForCreateNewDocConfirm: "Are you create new document?",
    validateNoDate: "Please select date.",
}