<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4 9V5C4 3.89543 4.89543 3 6 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H14"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<circle
			cx="6.5"
			cy="16.5"
			r="4.5"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M6.50006 15.25C6.52766 15.25 6.55002 15.2724 6.55 15.3C6.54998 15.3276 6.5276 15.35 6.5 15.35C6.4724 15.35 6.45002 15.3276 6.45 15.3C6.44998 15.2724 6.47234 15.25 6.49994 15.25H6.50006"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M6.50006 17.65C6.52766 17.65 6.55002 17.6724 6.55 17.7C6.54998 17.7276 6.5276 17.75 6.5 17.75C6.4724 17.75 6.45002 17.7276 6.45 17.7C6.44998 17.6724 6.47234 17.65 6.49994 17.65H6.50006"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M17 8H8"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M17 12H14"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M17 16H15"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>
<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: 24,
		},
		height: {
			type: [Number, String],
			default: 24,
		},
		iconColor: {
			type: String,
			default: "secondary",
		},
	},
	computed: {
		strokeColor() {
			return "p-stroke-" + this.iconColor;
		},
	},
};
</script>

<style lang="scss" scoped>
svg {
	display: inline-block;
	vertical-align: baseline;
	margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
