<template>
	<div class="flex justify-content-between align-items-center" style="padding-top: 20px">
		<div class="my-3">
			<PvButton
				icon="pi pi-chevron-left"
				:label="lang.back"
				@click="goBack"
				class="mr-2 p-button-secondary"
			/>
			<PvButton
				icon="pi pi-check"
				:label="lang.confirmDocument"
				@click="addBankToRV()"
				class="mr-2"
			/>
		</div>
		<h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
	</div>

	<div class="surface-card shadow-2 p-3 border-round">
		<transition>
			<section class="flex flex-wrap" :class="textFormClass" v-if="isParameterVisible">
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="dateStart">{{ lang.dateStart }}</label>
					<PvCalendar
						id="dateStart"
						v-model="dateStart"
						class="inputfield w-full mt-1"
						showIcon
						dateFormat="dd/mm/yy"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="dateEnd">{{ lang.dateEnd }}</label>
					<PvCalendar
						id="dateEnd"
						v-model="dateEnd"
						class="inputfield w-full mt-1"
						showIcon
						dateFormat="dd/mm/yy"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="code">{{ lang.codeBank }}</label>
					<PvInputText
						id="code"
						type="text"
						class="inputfield w-full mt-1"
						placeholder="6501xxxxx"
						v-model="code"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="customerSearch">{{ lang.customerSearch }}</label>
					<PvInputText
						id="customerSearch"
						type="text"
						class="inputfield w-full mt-1"
						v-model="customerSearch"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="bookBankName">{{ lang.bookBankName }}</label>
					<PvInputText
						id="bookBankName"
						type="text"
						class="inputfield w-full mt-1"
						v-model="bookBankName"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="paymentMethodCode">{{ lang.paymentMethodCode }}</label>
					<base-payment-method-auto-complete
						id="paymentMethodCode"
						v-model="paymentMethodCode"
						class="inputfield w-full mt-1"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="selectedStatus">{{ lang.Status }}</label>
					<PvDropdown
						id="selectedStatus"
						class="inputfield w-full mt-1"
						:options="statusOptions"
						optionLabel="label"
						v-model="selectedStatus"
					/>
				</div>
			</section>
		</transition>

		<div class="pt-2 pb-1">
			<PvButton icon="pi pi-search" :label="lang.search" @click="getBankList" class="mr-2 mb-2" />
		</div>

		<PvDataTable
			showGridlines
			:value="banks"
			v-model:selection="selectedBank"
			selectionMode="multiple"
			dataKey="id"
			responsiveLayout="scroll"
			:stripedRows="true"
			class="p-datatable-sm"
			:class="textTableClass"
			:loading="isLoading"
			:metaKeySelection="false"
		>
			<template #empty>
				<div class="w-full p-3" Style="text-align: center;">
				<label>{{ lang.tableEmpty }}</label>
				</div>
			</template>
			<PvColumn selectionMode="multiple" style="width: 3rem" :exportable="false"></PvColumn>
			<PvColumn
				field="payDate"
				:header="lang.columnPayDate"
				style="min-width: 4rem"
				headerClass="h-center"
				bodyClass="text-center"
			>
				<template #body="slotProps">
					{{ formatDate(slotProps.data.payDate) }}
				</template>
			</PvColumn>
			<PvColumn
				field="toBankDate"
				:header="lang.columnToBankDate"
				style="min-width: 4rem"
				headerClass="h-center"
				bodyClass="text-center"
			>
				<template #body="slotProps">
					{{ formatDate(slotProps.data.toBankDate) }}
				</template>
			</PvColumn>
			<PvColumn
				field="code"
				:header="lang.columnCode"
				style="min-width: 4rem"
				headerClass="h-center"
			></PvColumn>
			<PvColumn
				field="customerCode"
				:header="lang.columnCustomerCode"
				style="min-width: 4rem"
				headerClass="h-center"
			></PvColumn>
			<PvColumn
				field="customerName"
				:header="lang.columnCustomerName"
				style="min-width: 8rem"
				headerClass="h-center"
			></PvColumn>
			<PvColumn
				field="payAmount"
				:header="lang.columnPrice"
				style="min-width: 2rem"
				headerClass="h-center"
				bodyClass="text-right"
			>
				<template #body="slotProps">
					{{ formatCurrency(slotProps.data.payAmount) }}
				</template>
			</PvColumn>
			<PvColumn
				field="paymentMethodCode"
				:header="lang.columnPayCode"
				style="min-width: 2rem"
				headerClass="h-center"
				bodyClass="text-center"
			></PvColumn>
			<PvColumn
				field="paymentMethodName"
				:header="columnPayName"
				style="min-width: 2rem"
				headerClass="h-center"
				bodyClass="text-center"
			></PvColumn>
			<PvColumn
				field="status"
				:header="lang.columnStatus"
				style="min-width: 1rem"
				headerClass="h-center"
				bodyClass="text-center"
			></PvColumn>
		</PvDataTable>
	</div>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import BasePaymentMethodAutoComplete from "../../components/bank/BasePaymentMethodAutoComplete.vue";
export default {
	props: ["voucherId", "type", "rvhid", "supplierCode"],
	emits: ["close"],
	components: {
		BasePaymentMethodAutoComplete,
	},
	setup() {
		const { lang } = useLang();
		const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
		const { formatCurrency, formatDate, formatDateToISOString } = useFormat();

		const store = useStore();
		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return { ...lang.th.base, ...lang.th.ar.bank.listing };
			} else {
				return { ...lang.en.base, ...lang.en.ar.bank.listing };
			}
		});

		const isHideBack = inject("isHideBack");

		return {
			lang: mergeLang,
			textHeaderClass,
			textFormClass,
			textTableClass,
			formatCurrency,
			formatDate,
			formatDateToISOString,
			isHideBack,
		};
	},
	data() {
		return {
			isParameterVisible: true,
			isLoading: false,
			dateStart: new Date(),
			dateEnd: "",
			code: "",
			customerSearch: "",
			bookBankName: "",
			paymentMethodCode: "",
			selectedBank: [],
			selectedPage: 1,
			rowsPerPage: 10,

			showFormPrintModal: false,
			path: "",
			dataPrint: [],
			categoryPrint: "",
			statusOptions: [
				{ value: "N", label: "N : ในมือ" },
				{ value: "B", label: "B : เข้าธนาคาร" },
			],
			selectedStatus: { value: "N", label: "N : ในมือ" },

			showDialogEditView: false,
			bankId: "",
		};
	},
	async created() {
		await this.$store.dispatch("bank/resetBanksSelected");
		let date = new Date();
		this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
		this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

		if (this.rvhid) {
			this.customerSearch = this.supplierCode;
		}

		await this.getBankList();
	},
	methods: {
		goBack() {
			this.$emit("close");
		},
		async getBankList() {
			this.isLoading = true;
			let paymentCode = this.paymentMethodCode ? this.paymentMethodCode.code : null;

			let where = {
				projectId: this.selectedProject.id,
				// date: this.selectedDateStart,
				code: this.code || null,
				customerName: this.customerSearch || null,
				customerCode: this.customerSearch || null,
				bookBankAccountNumber: this.bookBankName || null,
				bookBankName: this.bookBankName || null,
				paymentMethodCode: paymentCode,
				paymentMethodType: this.type || 0,
				status: this.selectedStatus.value || null,
				startDate: this.selectedDateStart || null,
				endDate: this.selectedDateEnd || null,
			};

			const payload = {
				page: this.selectedPage,
				limit: this.rowsPerPage,
				type: this.type,
				where,
			};

			// console.log("getBankList", payload);

			await this.$store.dispatch("bank/getAllBanksForSelect", payload);
			this.isLoading = false;

			where = {
				...where,
				date: this.date,
			};

			localStorage.setItem("erp_search_BankList", JSON.stringify(where));
		},
		async getTransactionType() {
			const payload = {
				InOut: this.type,
			};
			await this.$store.dispatch("bank/getTransactionType", payload);
			this.isLoading = false;
		},
		setLoading(bool) {
			this.$store.dispatch("setLoading", { value: bool });
		},
		async addBankToRV() {
			// console.log("addBankToRV", this.selectedBank);
			await this.$store.dispatch("bank/setBanksSelected", this.selectedBank);
			this.$emit("close");
		},
	},
	computed: {
		parameterButtonText() {
			return this.isParameterVisible ? this.lang.hideParameters : this.lang.showParameters;
		},
		halfViewportHeight() {
			return this.$store.getters.halfViewportHeight;
		},
		selectedProject() {
			return this.$store.getters.selectedProject;
		},
		banks() {
			return this.$store.getters["bank/banksForSelect"];
		},
		banksSelected() {
			return this.$store.getters["bank/banksSelected"];
		},
		totalRecords() {
			return this.totalPage * this.rowsPerPage;
		},
		selectedDateStart() {
			let dateStart = null;
			if (this.dateStart) {
				dateStart = this.formatDateToISOString(this.dateStart);
			}
			return dateStart;
		},
		selectedDateEnd() {
			let dateEnd = null;
			if (this.dateEnd) {
				dateEnd = this.formatDateToISOString(this.dateEnd);
			}
			return dateEnd;
		},
		title() {
			// console.log("title", this.type);
			let result = this.lang.titleIn;
			if (this.type === 1) {
				result = this.lang.titleIn;
				return result;
			} else {
				result = this.lang.titleOut;
				return result;
			}
		},
		columnPayName() {
			let result = this.lang.columnPayNameIn;
			if (this.type === 1) {
				result = this.lang.columnPayNameIn;
				return result;
			} else {
				result = this.lang.columnPayNameOut;
				return result;
			}
		},
		sharedPaymentType() {
			return this.$store.getters["bank/transactionType"];
		},
	},
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
	transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
	transform: translateY(-20px);
	opacity: 0;
}
</style>
