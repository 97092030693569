export default {
	title: "Receipt / Tax Invoice",
	changeBook: "Change Book",

	columnPostStep: "status",
	columnCancelStatus: "cancel",
	columnCode: "code",
	columnReferenceCode: "ref. code",
	columnDate: "date",
	columnSupplierCode: "supplier code",
	columnSupplierName: "supplier name",
	columnReceiptNetAmount: "net amount",
	columnreceiptActualPayAmount: "act. amount",
	columnCurrencyUnit: "unit",
};
