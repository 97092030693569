<template>
  <div class="flex justify-content-between align-items-center pt-4 pb-3">
    <div class="text-right">
      <span
        class="cursor-pointer text-primary font-lg font-normal"
        @click="toggleParameterVisible"
      >
        {{ title }}
        <i :class="icon" class="font-sm font-normal" />
      </span>
    </div>
  </div>

  <PvCarousel
    :value="advertisement"
    :numVisible="4"
    :numScroll="1"
    circular
    :responsiveOptions="responsiveOptions"
    v-if="!isParameterVisible"
  >
    <template #item="slotProps">
      <div
        class="text-center px-4"
        :class="{ 'cursor-pointer title-hover': slotProps.data.linkUrl }"
        @click="gotoAdvertisement(slotProps.data.linkUrl)"
      >
        <div class="p-image-container">
          <img
            :src="slotProps.data.imgUrl"
            class="image-advertisement w-full h-full"
          />

          <div class="p-image-overlay" v-if="slotProps.data.linkUrl"></div>
          <div class="p-image-text font-lg font-bold" v-if="slotProps.data.linkUrl">{{ lang.more }}</div>
        </div>
        <div class="p-carousel-indicators justify-content-start pb-0 pr-0">
          <label
            class="text-left"
            :class="{ 'cursor-pointer': slotProps.data.linkUrl }"
            >{{ slotProps.data.description }}</label
          >
        </div>
      </div>
    </template>
  </PvCarousel>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  setup() {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.advertisement,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.advertisement,
        };
      }
    });

    return {
      lang: mergeLang,

      textFormClass,
    };
  },
  data() {
    return {
      isParameterVisible: false,
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 4,
          numScroll: 1,
        },
        {
          breakpoint: "900px",
          numVisible: 2,
          numScroll: 1,
        },
        {
          breakpoint: "480px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },
  async mounted() {
    await this.getAdvertisement();
  },
  methods: {
    toggleParameterVisible() {
      this.isParameterVisible = !this.isParameterVisible;
    },
    async getAdvertisement() {
      this.advertisementLoading = true;
      try {
        await this.$store.dispatch("advertisement/getAdvertisement");
      } catch (error) {
        this.errorMessage = error.message || this.lang.baseError;
        this.isError = true;
        this.advertisementLoading = false;
      }
    },
    gotoAdvertisement(linkUrl) {
      if(linkUrl) { window.open(linkUrl, "_blank"); }
    },
  },
  computed: {
    title() {
      let title = this.isParameterVisible ? this.lang.show : this.lang.hide;
      return title + this.lang.title;
    },
    icon() {
      return this.isParameterVisible ? "pi pi-angle-down" : "pi pi-angle-up";
    },
    advertisements() {
      return this.$store.getters["advertisement/advertisement"];
    },
    advertisement() {
      let result = this.advertisements;
      if (this.advertisements.length > 0) {
        result.splice(0, 1);
      }
      return result;
    },
  },
};
</script>

<style scoped>
.image-advertisement {
  border-radius: 12px;
}
.p-carousel-indicators {
  padding-left: 0;
}

.p-image-container {
  position: relative;
}
.p-image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: none;
  border-radius: 12px;
}
.p-image-container:hover .p-image-overlay {
  opacity: 0.7;
  background-color: #153f7c;
  display: block;
}
.p-image-container:hover .p-image-text {
  opacity: 1;
  display: block;
  font-size: 18px;
}
.p-image-text {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: none;
}
.title-hover:hover {
  color: #1c70f7;
}
.p-carousel-items-container {
  display: -webkit-box !important;
}
</style>
