export default {
  setCustomerGroup(state, payload) {
    state.customerGroups = payload.data;
    state.totalPage = payload.page.pageCount;
    state.currentPage = payload.page.currentPage;
  },
  setSelectedCustomerGroup(state, payload) {
    state.selectedCustomerGroup = payload;
  },
  resetCustomerGroup(state) {
    state.customerGroups = [];
    state.totalPage = 0;
    state.currentPage = 0;
  },
};