export default {
  customer: {
    customers: [],
    currentPage: 0,
    totalPage: 0,
    isUpdating: false,
    customerGroups: [],
    customerGroupCurrentPage: 0,
    customerGroupTotalPage: 0,
  },
};
