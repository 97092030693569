export default {
    setPaymentRequestType(state, payload){
        state.paymentRequestType = payload;
    },
    setSelectedPaymentRequestType(state, payload){
        state.selectedPaymentRequestType = payload;
    },
    resetSelectedPaymentRequestType(state){
        state.selectedPaymentRequestType = null
    },
}