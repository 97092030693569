export default {
	title: "Bill Acceptance",
	createBill: "Create Bill Acceptance",
	editBill: "Edit Bill Acceptance",
	create: "Create",
	add: "Add",
	edit: "Edit",
	print: "Print",
	approve: "Approve",
	import: "Import",
	cancel: "Cancel",
	saveAs: "SaveAs",

	code: "Code",
	refCode: "Tax Code",
	date: "Date",
	requestDate: "RequestDate",
	periodDate: "PeriodDay",
	dueDate: "DueDate",
	supplier: "Supplier",
	employee: "Employee",

	section: "Department",
	project: "Project",
	task: "Task",
	remark1: "Remark (1)",
	remark2: "Remark (2)",

	columnBookCode: "BookCode",
	columnDate: "Date",
	columnCode: "Code",
	columnCodeName: "CodeName",
	columnUnitName: "Unit",
	columnUnitPrice: "UnitPrice",
	columnQuantity: "Quantity",
	columnDiscount: "Discount",
	columnDiscountAmt: "DiscountAmt",
	columnNetAmount: "NetAmount",
	columnAmount: "Amount",

	askForProductChange: "Are you sure you want to update product code?",
	askForSelectProduct: "Can't find product code : :code. Do you want to search?",
	askApprove: "ํYou want to Approve?",
	approveSuccess: "Approve Success",

	inputCurrency: "Currency",
	inputTotalAmount: "Total Amount",
	inputDiscount: "Discount",
	inputDiscountTotalAmount: "Discount Amount",
	inputPayment: "Payment",
	inputPaymentTotalAmount: "Payment Amount",
	inputTax: "Vat",
	inputTaxAmount: "Vat Amount",
	inputNetAmount: "Net Amount",

	validateNoSupplierSelect: "Please select Supplier",
	validateNoEmployeeSelect: "Please select Employee",
	validateRefCode: "Invalid RefCode",
	validateDetailSelect: "Please select Item.",

	titleImportPO: "List PurchaseOrder",
	validateUpdatePrice: "You Can't update price in purchase order details.",
    validateCheckDiscount: "Discount format can be enter only numbers and %.",
	validateTotalAmount: "Total Amount  not less than 0.",
    reApprove: "Revert Approve",
    askForRevertApprove: "Are you sure you want to Revert approve Document?",
    revertApproveSuccess: "Revert approve successfully",
	validateNetTotalAmount: "Net Total Amount not less than 0.",
	validateDiscountAmount: "Discount amount must not be more than total amount.",
    calculatedFromSystem: "*Calculated From System : ",
};
