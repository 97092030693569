export default {
  accountingDetailAccounts(state) {
    return state.accountingDetail.accountingDetails;
  },
  accountingDetailCurrentPage(state) {
    return state.accountingDetail.currentPage;
  },
  accountingDetailNextPage(state) {
    return state.accountingDetail.currentPage + 1;
  },
  accountingDetailTotalPage(state) {
    return state.accountingDetail.totalPage;
  },
  accountingDetailIsLastPage(_, getters) {
    return (
      getters.accountingDetailCurrentPage === getters.accountingDetailTotalPage &&
      getters.accountingDetailTotalPage !== 0
    );
  },
};
