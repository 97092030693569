<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '70vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="titleHeaderImportData"
      :wordingConfirm="titleValidateImportData"
      :dataButtons="dataButtonDialog"
      @close="showValidateDialog = false"
    ></base-dialog>

    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">{{ lang.uploadFile }}</label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          :disabled="shouldDisableCloseButton"
          @click="closeDialog"
        />
      </div>
    </template>
    <div class="dropZone">
      <input
        type="file"
        id="dropZone-file"
        class="dropZone-file"
        ref="dropZoneFile"
        @click="resetFile"
        @change="handleFiles"
        multiple
        accept=".xlsx"
      />
      <div
        class="dropZone-wrapper"
        @dragenter.prevent=""
        @dragover.prevent=""
        @drop.prevent="handleFiles"
      >
        <label for="dropZone-file" class="cursor-pointer">
          <div class="pt-6"></div>
          <PvButton
            class="cursor-pointer p-button-rounded show-icon"
            disabled="disabled"
          >
            <icon-cloud-storage-upload iconColor="white" />
          </PvButton>
          <div class="cursor-pointer dropZone-label-text pt-4">
            <label
              for="dragFile"
              class="cursor-pointer font-md p-text-secondary"
              >{{ lang.dragFile }}</label
            >
            <label
              for="addFile"
              class="cursor-pointer font-md p-text-primary"
              @click="addFile"
              >{{ lang.addFile }}</label
            >
            <input
              id="add-file"
              type="file"
              style="display: none"
              accept=".xlsx"
              @click="resetFile"
              @change="handleFiles"
              class="cursor-pointer"
            />
          </div>
          <div class="cursor-pointer font-xs p-text-secondary pt-2 pb-8">
            <label for="importFileDesc" class="cursor-pointer">{{
              lang.importFileDesc
            }}</label>
            <label
              for="downloadfile"
              class="cursor-pointer p-text-primary"
              @click="downloadfile"
              >{{ lang.downloadfile }}</label
            >
          </div>
        </label>
      </div>
    </div>

    <div class="my-5 pt-6">
      <PvDataTable
        :value="files"
        dataKey="id"
        responsiveLayout="scroll"
        class="p-datatable-xs"
        :class="textTableClass"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn style="width: 87px; height: 56px" headerClass="h-center">
          <template #body>
            <PvButton
              v-if="isOpen"
              type="button"
              style="background: white; border: white; opacity: 1"
              disabled="disabled"
            >
              <icon-documents-file-checkmark iconColor="secondary" />
            </PvButton>
          </template>
        </PvColumn>

        <PvColumn
          field="detailName"
          :header="lang.fileName"
          style="min-width: 12rem"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-left font-normal p-text-primary"
        >
        </PvColumn>

        <PvColumn
          style="width: 2rem"
          headerClass="h-center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="">
            <PvButton
              v-if="isOpen"
              type="button"
              class="p-button-danger"
              style="background: white; border: white"
              @click="deleteFile"
            >
              <icon-trash-delete-bin iconColor="error" />
            </PvButton>
          </template>
        </PvColumn>
      </PvDataTable>
    </div>

    <div class="flex justify-content-center pt-4">
      <PvButton class="w-3" @click="uploadFile" :disabled="checkDisable">
        <span class="w-full text-center font-md font-normal p-button-label">
          <i
            v-if="isUpLoading"
            class="pi pi-spin pi-spinner font-normal mr-1"
          ></i>
          {{ lang.uploadFile }}
        </span>
      </PvButton>
    </div>
    <template #footer></template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { ref, computed } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useImportExcel from "../../../hooks/importExcel.js";
import { useRouter } from "vue-router";
import useGetImportExistResident from '../../../hooks/getImportExistResident.js';
import useGetImportExistUnit from '../../../hooks/getImportExistUnit.js';
export default {
  props: [],
  emits: ["close", "data-upload"],
  setup(props, context) {
    const { lang } = useLang();
    const { textTableClass } = useTextStyle();
    const { formatDate, formatDateToISOString } = useFormat();
    const { getDownloadBlob } = useGetFormPrint();
    const { validateFileCount, validateFileType, readFileContent } = useImportExcel();
    const { setImportExistResidentParams, loadImportExistResident, getImportExistResident } = useGetImportExistResident();
    const { setImportExistUnitParams, loadImportExistUnit, getImportExistUnit } = useGetImportExistUnit();
    const store = useStore();
    const router = useRouter();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.unit.edit,
          ...lang.th.master.unit.import,
          ...lang.th.validateImport,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.unit.edit,
          ...lang.en.master.unit.import,
          ...lang.en.validateImport,
        };
      }
    });

    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });

    const fileBlob = computed(() => {
      return store.getters["shared/blob"];
    });

    const checkDisable = computed(() => {
      if (!file.value || isUpLoading.value) {
        return true;
      } else {
        return false;
      }
    });

    const shouldDisableCloseButton = computed(() => {
      return isUpLoading.value;
    });

    const dataButtonDialog = computed(() => {
      let result = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
      return result;
    });

    // const unitByCodes = computed(() => {
    //   return store.getters["importUnit/unitByCodes"];
    // });
    // const unitByAddressNumbers = computed(() => {
    //   return store.getters["importUnit/unitByAddressNumbers"];
    // });
    // const customerByCodes = computed(() => {
    //   return store.getters["importUnit/customerByCodes"];
    // });

    let isUpLoading = ref(false);
    let files = ref([]);
    let isOpen = ref(false);
    let file = ref(null);
    let uploadFiles = ref([]);
    let fileName = ref("");
    let titleHeaderImportData = ref(mergeLang.value.notificationDialogHeader);
    let titleValidateImportData = ref("");
    let showValidateDialog = ref(false);
    let condition = ref([
      {
        name: "code",
        type: "string",
        wordLength: 50,
        isRequire: true,
        isUnique: true,
      },
      {
        name: "ownerCode",
        type: "string",
        wordLength: 25,
        isRequire: true,
      },
      {
        name: "addressNo",
        type: "string",
        wordLength: 50,
        isRequire: true,
        isUnique: true,
      },
      {
        name: "building",
        type: "string",
        wordLength: 255,
      },
      {
        name: "floor",
        type: "string",
        wordLength: 50,
      },
      {
        name: "name", //roomType
        type: "string",
        wordLength: 50,
        isRequire: true,
      },
      {
        name: "room",
        type: "string",
        wordLength: 50,
      },
      {
        name: "contract",
        type: "string",
        wordLength: 50,
      },
      {
        name: "qrPayment",
        type: "string",
        wordLength: 50,
      },
      {
        name: "ownerRatio",
        type: "decimal",
        wordLength: 50,
        isRequire: true,
      },
      {
        name: "area",
        type: "decimal",
        isRequire: true,
      },
      {
        name: "cooling",
        type: "decimal",
        wordLength: 50,
      },
      {
        name: "activeStatus",
        type: "string",
        wordLength: 100,
      },
      {
        name: "livingType",
        type: "string",
        wordLength: 50,
        isRequire: true,
      },
      {
        name: "appointmentDate",
        type: "date",
        // isRequire: true,
      },
      {
        name: "transferDate",
        type: "date",
        // isRequire: true,
      },
      {
        name: "expirdInsuranceDate",
        type: "date",
        // isRequire: true,
      },
    ]);
    let roomTypes = ref(["เช่าพื้นที่ส่วนกลาง/ร้านค้า","ยังไม่โอน","โอนแล้ว-ปล่อยเช่า","โอนแล้ว-ยังไม่เข้าพักอาศัย","โอนแล้ว-อยู่เอง"]);
    let livingTypes = ref(["อยู่เอง","ปล่อยเช่า","เช่าพื้นที่ส่วนกลาง"]);
    let activeStatus = ref(["ใช้งาน","ไม่ใช้งาน"]);
    let importUnits = ref([]);
    let importUnitByCodes = ref([]);
    let importUnitByAddressNumbers = ref([]);
    let importCustomerByCodes = ref([]);

    const setLoading = (bool) => {
      isUpLoading.value = bool;
    };

    const closeDialog = () => {
      context.emit("close");
      initiateData();
    };

    const initiateData = () => {
      files.value = [];
      isOpen.value = false;
      file.value = null;
      importUnits.value = [];
      importUnitByCodes = [];
      importUnitByAddressNumbers = [];
      importCustomerByCodes = [];
    };

    const downloadfile = async () => {
      setLoading(true);
      let payload = {
        key: `${process.env.VUE_APP_IMPORT_UNIT_TEMPLATE_S3_KEY}`,
        name: "import_unit.xlsx",
      };
      try {
        await store.dispatch("upload/downloadFile", payload);
        const d = new Date();
        let dateText = d.toISOString();
        getDownloadBlob(`${payload.fileName}_${dateText}.xlsx`, fileBlob.value);
      } catch (error) {
        titleValidateImportData.value = error;
        showValidateDialog.value = true;
        setLoading(false);
      }
      setLoading(false);
    };

    const handleFiles = async (event) => {
      const inputValue = event.target.files || event.dataTransfer.files;
      uploadFiles.value = Array.from(inputValue);
      const checkFileCount = validateFileCount(uploadFiles.value, 1);
      if (checkFileCount.isError) {
        titleHeaderImportData.value = mergeLang.value.validateLimitFile;
        titleValidateImportData.value = mergeLang.value.validateFileType.replace(":name", checkFileCount.message),
        showValidateDialog.value = true;
        return;
      }
      file.value = inputValue[0];
      const checkFileType = validateFileType(file.value);
      if (checkFileType.isError) {
        titleHeaderImportData.value = mergeLang.value.validateImportFile;
        titleValidateImportData.value = mergeLang.value.validateFileType.replace(":name", checkFileType.message);
        showValidateDialog.value = true;
        file.value = null;
        return;
      }
      isOpen.value = true;
      const fileName = file.value.name;
      files.value = [
        {
          detailName: fileName,
        },
      ];
    };

    const addFile = () => {
      document.getElementById("add-file").click();
    };

    const deleteFile = () => {
      initiateData();
    };  

    const resetFile = (event) => {
      if (event.target.files.length > 0) {
        event.target.value = null;
      }
    };

    const uploadFile = async () => {
      if (file.value) {
        try {
          setLoading(true);
          const result = await readFileContent(file.value, 3, 20000, condition.value);
          if(!result.success){
            if(result.code == "001"){
              titleHeaderImportData.value = mergeLang.value.notificationDialogHeader;
              titleValidateImportData.value = mergeLang.value.validateImportNoData.replace(":file", file.value.name);              
            } else if(result.code == "002"){
              titleHeaderImportData.value = mergeLang.value.notificationDialogHeader;
              titleValidateImportData.value = mergeLang.value.validateImportMaxColumn.replace(":maxColumn", "20000");
            } else {
              titleHeaderImportData.value = mergeLang.value.notificationDialogHeader;
              titleValidateImportData.value = result.message;
            }
            setLoading(false);
            showValidateDialog.value = true;
            return true;
          }          
          importUnits.value = result.data;
          await getDataForValidate();
          await prepareData();
          setLoading(false);
        } catch (error) {
          titleHeaderImportData.value = mergeLang.value.notificationDialogHeader;
          titleValidateImportData.value = error;
          showValidateDialog.value = true;
          setLoading(false);
        }
      } else {
        titleHeaderImportData.value = mergeLang.value.validateLimitFile;
        titleValidateImportData.value = mergeLang.value.validateAddfile;
        showValidateDialog.value = true;
        return true;
      }
    };

    const getDataForValidate = async () => {
      await getUnitImportByCode();
      await getUnitImportByAddressNo();
      await getCustomerImport();
    };

    const getUnitImportByCode = async () => {
      setImportExistUnitParams(importUnits.value,"code");
      await loadImportExistUnit("unitCode");
      importUnitByCodes.value = getImportExistUnit();
    };

    const getUnitImportByAddressNo = async () => {
      setImportExistUnitParams(importUnits.value,"addressNo");
      await loadImportExistUnit("unitAddressNo");
      importUnitByAddressNumbers.value = getImportExistUnit();
    };

    const getCustomerImport = async () => {
      setImportExistResidentParams(importUnits.value,"ownerCode");
      await loadImportExistResident();
      importCustomerByCodes.value = getImportExistResident();
    };

    const prepareData = async () => {
      let index = 0;
      let dataResult = [];
      for (const data of importUnits.value) {
        let result = data;
        result = validateData(data); 
        dataResult.push({
          ...result,
          id: index,
          itemNo: index+1,
        });
        index++;
      }
      await store.dispatch("importUnit/importUnits", dataResult);
      context.emit("data-upload");
      router.push({ name: "preview-unit" });
    };

    const validateData = (data) => {
      data = {
        ...data,
        projectId: selectedProject.value.id,
        customerId: "",
        customerName: "",
        importStatusCode: "wait",
        importStatusReason: "",
        importStatusDescription: "",
        markStatus: "",
      } 
      if (data.code) {
        let code = importUnitByCodes.value.filter((unit) => unit.code == data.code)[0];
        if (code) {
          let errorObj = {
            field: "code",
            type: "duplicateInDatabase",
            data: data.code,
          }; 
          data.error.push(errorObj);
        }
      }
      if (data.addressNo) {
        let addressNo = importUnitByAddressNumbers.value.filter((unit) => unit.addressNo == data.addressNo)[0];
        if (addressNo) {
          let errorObj = {
            field: "addressNo",
            type: "duplicateInDatabase",
            data: data.addressNo,
          }; 
          data.error.push(errorObj);
        }
      }
      if (data.ownerCode) {
        let ownerCode = importCustomerByCodes.value.filter((customer) => customer.code == data.ownerCode)[0];
        if (!ownerCode) {
          let errorObj = {
            field: "ownerCode",
            type: "doNotExist",
            data: data.ownerCode,
          }; 
          data.error.push(errorObj);
        } 
        data.ownerId = ownerCode ? ownerCode.id : "";
        data.ownerName = ownerCode ? ownerCode.name : "";
      }
      if (data.name) 
      {
        if (!roomTypes.value.includes(data.name)) 
        {
          let errorObj = {
            field: "name",
            type: "doNotExist",
            data: data.name,
          }; 
          data.error.push(errorObj);
        }
      }
      if (data.activeStatus) 
      {
        if (!activeStatus.value.includes(data.activeStatus)) 
        {
          let errorObj = {
            field: "activeStatus",
            type: "doNotExist",
            data: data.activeStatus,
          }; 
          data.error.push(errorObj);
        }
      }
      if (data.livingType) 
      {
        if (!livingTypes.value.includes(data.livingType)) 
        {
          let errorObj = {
            field: "livingType",
            type: "doNotExist",
            data: data.livingType,
          }; 
          data.error.push(errorObj);
        }
      }
      if (data.error.length > 0) {
        data.importStatusCode = "invalid";
        data.importStatusDescription = data.error[0].field;
        let errorType = data.error[0].type;

        switch (data.error[0].type) {
          case "blank":
            errorType = mergeLang.value.validateBlank.replace(":field", setFieldLangError(data.error[0].field));
            break;
          case "duplicate":
            errorType = mergeLang.value.validateDuplicateInFile.replace(":field",setFieldLangError(data.error[0].field));
            break;
          case "duplicateInDatabase":
            errorType = mergeLang.value.validateDuplicateInDatabase.replace(":field",setFieldLangError(data.error[0].field)).replace(":table", mergeLang.value.unit);
            break;
          case "overflow":
            errorType = mergeLang.value.validateLength.replace(":field", setFieldLangError(data.error[0].field)).replace(":length", data.error[0].wordLength);
            break;
          case "doNotExist":
            errorType = mergeLang.value.validateDataNotFound.replace(":field", setFieldLangError(data.error[0].field));
            break;
          case "invalid":
            errorType = mergeLang.value.validateType.replace(":field", data.error[0].field);
            break;
          case "invalidDecimal":
            errorType = mergeLang.value.validateDecimalFormat.replace(":field", data.error[0].field);
            break;
          case "format":
            errorType = mergeLang.value.validateDateFormat.replace(":field", setFieldLangError(data.error[0].field));
            break;
        }
        data.importStatusReason = errorType;
      }
      return data;
    };

    const setFieldLangError = (field) => {
      switch (field) {
        case "code":
          return mergeLang.value.code;
        case "ownerCode":
          return mergeLang.value.customerCode;
        case "addressNo":
          return mergeLang.value.addressNo;
        case "building":
          return mergeLang.value.building;
        case "floor":
          return mergeLang.value.floor;
        case "name":
          return mergeLang.value.roomType;
        case "room":
          return mergeLang.value.room;
        case "contract":
          return mergeLang.value.contractNo;
        case "qrPayment":
          return mergeLang.value.QRPayment;
        case "ownerRatio":
          return mergeLang.value.ratio;
        case "area":
          return mergeLang.value.area;
        case "cooling":
          return mergeLang.value.cooling;
        case "activeStatus":
          return mergeLang.value.activeStatus;
        case "livingType":
          return mergeLang.value.livingType;
        case "appointmentDate":
          return mergeLang.value.appointmentDate;
        case "transferDate":
          return mergeLang.value.transferDate;
        case "expirdInsuranceDate":
          return mergeLang.value.warrantyEndDate;
        default:
          return field;
      }
    };

    return {
      // common
      lang: mergeLang,
      textTableClass,
      formatDate,
      formatDateToISOString,
      // computed
      selectedProject,
      fileBlob,
      checkDisable,
      shouldDisableCloseButton,
      dataButtonDialog,
      // data
      isUpLoading,
      files,
      isOpen,
      file,
      fileName,
      titleHeaderImportData,
      titleValidateImportData,
      showValidateDialog,
      // function
      setLoading,
      closeDialog,
      handleFiles,
      addFile,
      deleteFile,
      resetFile,
      downloadfile,
      uploadFile,
    };
  },
};
</script>

<style scoped>
.show-icon {
  opacity: 1;
  width: 48px;
  height: 48px;
  padding: 0.75rem;
}
</style>