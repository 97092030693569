export default{    
	title: "Invoice List {unitCodeAndAddress}",
	columnProductCode: "ProductCode",
	columnProductName: "ProductName",
	columnPrice: "Price",
	columnOwner: "Owner",
	columnRemark: "Remark",

	addUnitProduct: "AddUnitProductInvoice",
	editUnitProduct: "EditddUnitProductInvoice",
	productCode: "productCode",

	validateProductCode: "select  Product",
	validatePrice: "Enter Price",
	validateOwner: "select Owner",
	validateRemark: "Enter Remark",
	validatePriceNotLessThanZero : "Price not less than 0.",
	askForDeleteProductInvoice: "Confirm delete :title",



};