export default {
  async getAssetGroupDropDown(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Asset/AssetGroup";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get asset group"
      );
      throw error;
    }
    // console.log("asset group", responseData);

    // const data = responseData.data;

    responseData.data = [
      ...context.getters.assetGroups,
      ...responseData.data,
    ];

    await context.commit("setAssetGroup", responseData);
  },
  async getAllAssetGroups(context) {
    await context.commit("resetAssetGroup");
    while (!context.getters.assetGroupIsLastPage) {
      let where = {
        projectId: context.rootGetters.selectedProject.id,
      };
      const payload = {
        page: context.getters.assetGroupNextPage || 1,
        where,
      };
      // console.log(payload);
      await context.dispatch("getAssetGroupDropDown", payload);
    }
  },
};
