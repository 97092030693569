export default {
    setAccountingEntry(state, payload){
        // state.project.length = 0; 
        state.accountingEntries = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },
    setSelectedAccountingEntry(state, payload){
        state.selectedAccountingEntry = payload
    },
    setBlob(state, payload){        
        // console.log("setBlob:",payload);
        state.blob = payload
    },
    resetAccountingEntryListing(state) {
        state.accountingEntries = []
        state.totalPage = 0
        state.currentPage = 0
    }
};