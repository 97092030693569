<template>
	<base-form-print-modal
		v-model:visible="showFormPrintModal"
		:search="searchText"
		@close="showFormPrintModal = false"
		@path-selected="formPrintModalSelected"
	></base-form-print-modal>
	<base-list-print-lot-dialog
		:openFrom="openFrom"
		v-model:visible="showFormListPrintLot"
		@close="showFormListPrintLot = false"
	>
	</base-list-print-lot-dialog>

	<PvDialog
		:breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
		:style="{ width: '90vw' }"
		:modal="true"
		:closable="false"
		v-model:visible="showDialogEditView"
	>
		<base-edit-bank-view
			:id="bankId"
			:voucherId="this.voucherId"
			:rvhid="this.rvhid"
			:type="this.type"
			:supplierCode="this.supplierCode"
			:receiptDifferenceAmount="this.receiptDifferenceAmount"
			@close="closeBank"
		></base-edit-bank-view>
		<template #footer>
			<PvButton :label="lang.close" icon="pi pi-times" class="p-button-text" @click="closeBank" />
		</template>
	</PvDialog>

	<PvDialog
		:breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
		:style="{ width: '80vw' }"
		:modal="true"
		:closable="false"
		v-model:visible="showBankSelected"
	>
		<base-bank-selected-view
			@close="closeBankSelected()"
			:type="this.type"
			:voucherId="this.voucherId"
			:rvhid="this.rvhid"
			:supplierCode="this.supplierCode"
		></base-bank-selected-view>
		<template #footer>
			<PvButton
				:label="lang.close"
				icon="pi pi-times"
				class="p-button-text mt-3"
				@click="closeBankSekected()"
			/>
		</template>
	</PvDialog>

	<div class="flex justify-content-between align-items-center" style="padding-top: 20px">
		<div class="my-3">
			<PvButton
				icon="pi pi-chevron-left"
				:label="lang.back"
				@click="goBack"
				class="mr-2 p-button-secondary"
				v-if="isHideBack && isOwnPage"
			/>
		</div>
		<h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
	</div>

	<div class="surface-card shadow-2 p-3 border-round">
		<div class="pt-2 pb-1">
			<PvButton
				icon="pi pi-file"
				:label="lang.create"
				@click="gotoEdit('create')"
				class="mr-2 mb-2"
			/>
			<PvButton
				icon="pi pi-list"
				:label="parameterButtonText"
				@click="toggleParameterVisible"
				class="p-button-help mr-2 mb-2"
				v-if="isOwnPage"
			/>
			<PvButton
				icon="pi pi-plus"
				:label="lang.importDocument"
				class="mr-2 mb-2"
				@click="getBank()"
				v-if="rvhid || voucherId"
			/>
			<!-- <PvButton
				icon="pi pi-check"
				:label="lang.confirmDocument"
				@click="addBankToRV()"
				class="mr-2 mb-2"
				v-if="rvhid"
			/> -->
			<PvButton
				icon="pi pi-print"
				:label="lang.btnPrintLot"
				class="mr-2 mb-2"
				@click="PrintLot()"
				v-if="isOwnPage"
			/>
		</div>
		<transition>
			<section class="flex flex-wrap" :class="textFormClass" v-if="isParameterVisible && isOwnPage">
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="dateStart">{{ lang.dateStart }}</label>
					<PvCalendar
						id="dateStart"
						v-model="dateStart"
						class="inputfield w-full mt-1"
						showIcon
						dateFormat="dd/mm/yy"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="dateEnd">{{ lang.dateEnd }}</label>
					<PvCalendar
						id="dateEnd"
						v-model="dateEnd"
						class="inputfield w-full mt-1"
						showIcon
						dateFormat="dd/mm/yy"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="code">{{ lang.codeBank }}</label>
					<PvInputText
						id="code"
						type="text"
						class="inputfield w-full mt-1"
						placeholder="6501xxxxx"
						v-model="code"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="customerSearch">{{ lang.customerSearch }}</label>
					<PvInputText
						id="customerSearch"
						type="text"
						class="inputfield w-full mt-1"
						v-model="customerSearch"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="bookBankName">{{ lang.bookBankName }}</label>
					<PvInputText
						id="bookBankName"
						type="text"
						class="inputfield w-full mt-1"
						v-model="bookBankName"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="paymentMethodCode">{{ lang.paymentMethodCode }}</label>
					<base-payment-method-auto-complete
						id="paymentMethodCode"
						v-model="paymentMethodCode"
						class="inputfield w-full mt-1"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="selectedStatus">{{ lang.Status }}</label>
					<PvDropdown
						id="selectedStatus"
						class="inputfield w-full mt-1"
						:options="this.type == 1 ? statusOptionsPayin : statusOptionsPayout"
						optionLabel="label"
						v-model="selectedStatus"
					/>
				</div>
			</section>
		</transition>

		<div class="pt-2 pb-1" v-if="isOwnPage">
			<PvButton icon="pi pi-search" :label="lang.search" @click="getBankList" class="mr-2 mb-2" />
		</div>

		<PvDataTable
			showGridlines
			:value="banksAr"
			v-model:selection="selectedBank"
			selectionMode="single"
			dataKey="id"
			responsiveLayout="scroll"
			:stripedRows="true"
			class="p-datatable-sm"
			:class="textTableClass"
			:loading="isLoading"
			@row-dblclick="gotoEdit(this.selectedBank.id)"
		>
			<template #empty>
				<div class="w-full p-3" Style="text-align: center;">
				<label>{{ lang.tableEmpty }}</label>
				</div>
			</template>
			<PvColumn
				field="payDate"
				:header="lang.columnPayDate"
				style="min-width: 4rem"
				headerClass="h-center"
				bodyClass="text-center"
				:sortable="true"
			>
				<template #body="slotProps">
					{{ formatDate(slotProps.data.payDate) }}
				</template>
			</PvColumn>
			<PvColumn
				field="toBankDate"
				:header="lang.columnToBankDate"
				style="min-width: 4rem"
				headerClass="h-center"
				bodyClass="text-center"
				:sortable="true"
			>
				<template #body="slotProps">
					{{ formatDate(slotProps.data.toBankDate) }}
				</template>
			</PvColumn>
			<PvColumn
				field="code"
				:header="lang.columnCode"
				style="min-width: 4rem"
				headerClass="h-center"
				:sortable="true"
			></PvColumn>
			<PvColumn
				field="customerCode"
				:header="lang.columnCustomerCode"
				style="min-width: 4rem"
				headerClass="h-center"
				:sortable="true"
			></PvColumn>
			<PvColumn
				field="customerName"
				:header="lang.columnCustomerName"
				style="min-width: 8rem"
				headerClass="h-center"
				:sortable="true"
			></PvColumn>
			<PvColumn
				field="payAmount"
				:header="lang.columnPrice"
				style="min-width: 2rem"
				headerClass="h-center"
				bodyClass="text-right"
				:sortable="true"
			>
				<template #body="slotProps">
					{{ formatCurrency(slotProps.data.payAmount) }}
				</template>
			</PvColumn>
			<PvColumn
				field="paymentMethodCode"
				:header="lang.columnPayCode"
				style="min-width: 2rem"
				headerClass="h-center"
				bodyClass="text-center"
				:sortable="true"
			></PvColumn>
			<PvColumn
				field="paymentMethodName"
				:header="columnPayName"
				style="min-width: 2rem"
				headerClass="h-center"
				bodyClass="text-center"
				:sortable="true"
			></PvColumn>
			<PvColumn
				field="status"
				:header="lang.columnStatus"
				style="min-width: 1rem"
				headerClass="h-center"
				bodyClass="text-center"
				:sortable="true"
			></PvColumn>
			<PvColumn
				field="cancelStatus"
				:header="lang.columnIsCancel"
				style="min-width: 1rem"
				headerClass="h-center"
				:sortable="true"
			></PvColumn>
			<PvColumn
				style="min-width: 10rem"
				headerClass="h-center"
				bodyStyle="text-align: center; overflow: visible"
			>
				<template #body="slotProps">
					<PvButton
						type="button"
						class="p-button-secondary p-button-text py-0"
						icon="pi pi-pencil"
						@click="gotoEdit(slotProps.data.id)"
					></PvButton>
					<PvButton
						type="button"
						class="p-button-secondary p-button-text py-0"
						icon="pi pi-print"
						@click="selectPrintForm(slotProps.data)"
					></PvButton>
					<PvButton
						type="button"
						class="p-button-danger p-button-text py-0"
						icon="pi pi-times"
						v-if="
							!isOwnPage &&
							(slotProps.data.voucherId.trim() != '' ||
								(this.rvhid && this.rvhid.trim() != 'create'))
						"
						@click="removeVoucher(slotProps.data)"
					></PvButton>
					<PvButton
						type="button"
						class="p-button-secondary p-button-text py-0"
						icon="pi pi-trash"
						v-if="!isOwnPage && slotProps.data.voucherId.trim() == '' && this.rvhid.trim() != ''"
						@click="deleteChequeImport(slotProps.data.id)"
					></PvButton>
				</template>
			</PvColumn>
			<template #footer v-if="!voucherId && !rvhid">
				<PvPaginator
					:rows="rowsPerPage"
					:totalRecords="totalRecords"
					@page="onPageChange($event)"
					:rowsPerPageOptions="[10, 50, 100]"
					:first="showCurrentPage"
				>
					<template #start="slotProps">
						{{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /
						{{ totalPage }}
					</template>
				</PvPaginator>
			</template>
		</PvDataTable>
	</div>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import BasePaymentMethodAutoComplete from "../../components/bank/BasePaymentMethodAutoComplete.vue";
import BaseEditBankView from "../../components/bank/BaseEditBankView.vue";
import useGetFormPrint from "../../hooks/getFormPrint.js";
import BaseListPrintLotDialog from "../bank/BaseListPrintLotDialog.vue";
import BaseBankSelectedView from "../../components/bank/BaseBankSelectedView.vue";
export default {
	props: ["voucherId", "type", "rvhid", "supplierCode", "receiptDifferenceAmount"],
	components: {
		BasePaymentMethodAutoComplete,
		BaseEditBankView,
		BaseListPrintLotDialog,
		BaseBankSelectedView,
	},
	setup() {
		const { lang } = useLang();
		const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
		const { formatCurrency, formatDate, formatDateToISOString } = useFormat();

		const store = useStore();
		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return { ...lang.th.base, ...lang.th.ap.bank.listing };
			} else {
				return { ...lang.en.base, ...lang.en.ap.bank.listing };
			}
		});

		const isHideBack = inject("isHideBack");

		const { getFormPrints, searchText, isSharedFormPrintReady, getPreviewBlob, filteredFormPrints } = useGetFormPrint();

		return {
			lang: mergeLang,
			textHeaderClass,
			textFormClass,
			textTableClass,
			formatCurrency,
			formatDate,
			formatDateToISOString,
			isHideBack,
			getFormPrints,
			searchText,
			isSharedFormPrintReady,
			getPreviewBlob,
			filteredFormPrints
		};
	},
	data() {
		return {
			isParameterVisible: true,
			isLoading: false,
			dateStart: null,
			dateEnd: null,
			code: "",
			customerSearch: "",
			bookBankName: "",
			paymentMethodCode: "",
			selectedBank: null,
			selectedPage: 1,
			rowsPerPage: 10,

			showFormPrintModal: false,
			path: "",
			dataPrint: [],
			categoryPrint: "",
			statusOptionsPayout: [
				{ value: "All", label: "All : ทั้งหมด" },
				{ value: "N", label: "N : ในมือ" },
				{ value: "B", label: "B : เข้าธนาคาร" },
				{ value: "P", label: "P : ผ่าน" },
				{ value: "R", label: "R : คืน" },
			],
			statusOptionsPayin: [
				{ value: "N", label: "N : ในมือ" },
				{ value: "B", label: "B : เข้าธนาคาร" },
			],
			selectedStatus: { value: "All", label: "All : ทั้งหมด" },

			showDialogEditView: false,
			bankId: "",

			showBankSelected: false,
			showFormListPrintLot: false,
			openFrom: "",
			selectedEditId: null,
			storageName: "",
		};
	},
	async created() {
		let date = new Date();
		this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
		this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

		this.storageName = `erp_search_BankList_${this.selectedProject.code}_${this.userCode}`;
		const localSearch = localStorage.getItem(this.storageName);
		if (localSearch) {
			let search = JSON.parse(localSearch);
			// console.log("localSearch", search);

			if (search.BankEntry === "Entry") {
				if (search.startDate != null && search.startDate != "") {
					this.dateStart = new Date(search.startDate);
				} else this.dateStart = "";

				if (search.endDate != null && search.endDate != "") {
					this.dateEnd = new Date(search.endDate);
				} else this.dateEnd = "";

				this.code = search.code || this.code;
				this.customerSearch = search.customerSearch || this.customerSearch;
				this.bookBankName = search.bookBankName || this.bookBankName;
				if (this.type == 1) {
					this.selectedStatus = this.statusOptionsPayin.find((v) => v.value === search.status);
				} else {
					this.selectedStatus = this.statusOptionsPayout.find((v) => v.value === search.status);
				}

				this.paymentMethodCode = this.sharedPaymentType.find(
					(paymentType) => paymentType.code === search.paymentMethodCode
				);
				
				this.rowsPerPage = search.rowsPerPage || this.rowsPerPage;
          		this.selectedPage = search.selectedPage || this.selectedPage;

				search.BankEntry = "";

				localStorage.setItem(this.storageName, JSON.stringify(search));
			}
		}

		if (this.voucherId || this.rvhid) {
			await this.getBankListWithVouchers();
		} else {
			await this.getBankList();
		}
	},
	methods: {
		goBack() {
			this.$router.push({name: "home"})
		},
		gotoEdit(BankId) {
			this.selectedEditId = BankId;
			if (this.voucherId || this.rvhid) {
				this.showDialogEditView = true;
				this.bankId = BankId;
			} else {
				if (this.type == 1) {
					this.$router.push({ name: "ar-bank-ar-edit", params: {id: BankId} })
				} else {
					this.$router.push({ name: "ap-bank-ap-edit", params: {id: BankId} })
				}
			}
		},
		toggleParameterVisible() {
			this.isParameterVisible = !this.isParameterVisible;
		},
		async onPageChange(event) {
			this.selectedPage = event.page + 1;
			this.rowsPerPage = event.rows;
			await this.getBankList();
		},
		async getBankList() {
			this.isLoading = true;
			let paymentCode = this.paymentMethodCode ? this.paymentMethodCode.code : null;

			let where = {
				projectId: this.selectedProject.id,
				// date: this.selectedDateStart,
				code: this.code || null,
				customerName: this.customerSearch || null,
				customerCode: this.customerSearch || null,
				bookBankAccountNumber: this.bookBankName || null,
				bookBankName: this.bookBankName || null,
				paymentMethodCode: paymentCode,
				paymentMethodType: this.type || 0,
				status: this.selectedStatus.value == "All" ? null : this.selectedStatus.value,
				startDate: this.selectedDateStart || null,
				endDate: this.selectedDateEnd || null,
			};

			const payload = {
				page: this.selectedPage,
				limit: this.rowsPerPage,
				type: this.type,
				where,
			};

			// console.log("getBankList", payload);

			await this.$store.dispatch("bank/getBanks", payload);
			this.isLoading = false;

			where.status = this.selectedStatus.value
			where = {
				...where,
				startDate: this.dateStart,
				endDate: this.dateEnd,
				rowsPerPage: this.rowsPerPage,
				selectedPage: this.selectedPage,
			};

			localStorage.setItem(this.storageName, JSON.stringify(where));
		},
		async getBankListWithVouchers() {
			this.isLoading = true;

			let where = {
				projectId: this.selectedProject.id,
				voucherId: this.voucherId ?? this.rvhid,
			};

			const payload = {
				page: this.selectedPage,
				limit: this.rowsPerPage,
				where,
				type: this.type,
			};

			await this.$store.dispatch("bank/getBankTransactionWithVouchers", payload);
			this.isLoading = false;

			where = {
				...where,
				date: this.date,
			};
		},
		async getTransactionType() {
			const payload = {
				InOut: this.type,
			};
			await this.$store.dispatch("bank/getTransactionType", payload);
			this.isLoading = false;
		},
		async PrintCheque(data) {
			const Prepaid = {
				id: data.id,
				path: this.path,
			};
			try {
				await this.$store.dispatch("bank/PrintCheque", Prepaid);
			} catch (error) {
				this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
			}
			let blob = this.$store.getters["bank/blob"];
			this.getPreviewBlob(blob);
		},
		async selectPrintForm(data) {
			await this.$store.dispatch("shared/resetFormPrint");
			this.dataPrint = data;
			let where = {
				projectId: this.selectedProject.id,
				// documentBookCode: "PayIn",				
				documentBookCode: "PayOut",
			};
			const payload = { where };
			await this.getFormPrints(payload);
			
			if(this.filteredFormPrints.length == 1) {
				this.path = this.filteredFormPrints[0].path ;
				this.PrintCheque(this.dataPrint);
			}else{
				this.showFormPrintModal = true;
			}
		},
		formPrintModalSelected(event) {
			this.path = event.path;
			this.PrintCheque(this.dataPrint);
			this.showFormPrintModal = false;
		},
		async closeBank() {
			this.showDialogEditView = false;
			this.setLoading(true);
			await this.getBankListWithVouchers();

			if (this.selectedEditId !== "create") {
				let data = this.banksSelected;
				let i = data.map((item) => item.id).indexOf(this.selectedEditId);
				data[i] = this.selectedBankUpdate;
				await this.$store.dispatch("bank/setBanksSelected", data);
			}

			this.setLoading(false);
		},
		setLoading(bool) {
			this.$store.dispatch("setLoading", { value: bool });
		},
		async removeVoucher(data) {
			// console.log('removeVoucher', data);
			this.setLoading(true);
			const result = await this.$swal({
				icon: "info",
				html: `<p class="${this.textAlertClass}">${this.lang.askForDeleteDocument}</p>`,
				confirmButtonText: this.lang.confirm,
				showCancelButton: true,
				cancelButtonText: this.lang.close,
				allowOutsideClick: false,
				confirmButtonColor: "#EF4444",
				cancelButtonColor: "#64748B",
			});

			if (result.isConfirmed) {
				let payload = {
					PayId: data.id,
					VoucherId: this.voucherId ?? this.rvhid,
					RefType: "VCH",
					ProjectId: this.selectedProject.id,
				};
				if (this.rvhid) {
					payload = { ...payload, ...{ RVHId: this.rvhid } };
					payload.RefType = "RVH";
				}
				// console.log('isConfirmed', payload);
				try {
					await this.$store.dispatch("bank/deleteBankWithVoucher", payload);
					await this.$swal({
						icon: "success",
						title: this.lang.successfully,
						confirmButtonText: this.lang.close,
						allowOutsideClick: false,
					});
					this.setLoading(true);
					await this.getBankListWithVouchers();
					this.setLoading(false);
				} catch (error) {
					this.$swal({
						icon: "error",
						html: `<p class="${this.textAlertClass}">${error}</p>`,
						confirmButtonText: this.lang.close,
						allowOutsideClick: false,
					});
				}
			} else if (result.isDenied) {
				//
			}
			this.setLoading(false);
		},
		PrintLot() {
			const today = new Date();
			this.openFrom = today.getSeconds();
			this.showFormListPrintLot = true;
		},
		getBank() {
			this.showBankSelected = true;
		},
		async askForRemoveBank() {
			const result = await this.$swal({
				icon: "info",
				html: `<p class="${this.textAlertClass}">${this.lang.askForRemoveDocument}</p>`,
				confirmButtonText: this.lang.confirm,
				showCancelButton: true,
				cancelButtonText: this.lang.close,
				allowOutsideClick: false,
				confirmButtonColor: "#EF4444",
				cancelButtonColor: "#64748B",
			});

			if (result.isConfirmed) {
				await this.removeBankToRV();
			}
		},
		closeBankSekected() {
			this.showBankSelected = false;
		},
		async closeBankSelected() {
			this.showBankSelected = false;
		},
		deleteChequeImport(id) {
			let i = this.banksSelected.map((item) => item.id).indexOf(id);
			this.banksSelected.splice(i, 1);
		},
	},
	computed: {
		parameterButtonText() {
			return this.isParameterVisible ? this.lang.hideParameters : this.lang.showParameters;
		},
		halfViewportHeight() {
			return this.$store.getters.halfViewportHeight;
		},
		selectedProject() {
			return this.$store.getters.selectedProject;
		},
		userCode() {
			return this.$store.getters.userCode;
		},
		banksAr() {
			let result = [
				...this.$store.getters["bank/banksAr"],
				...this.$store.getters["bank/banksSelected"],
			];
			return result;
		},
		banksSelected() {
			return this.$store.getters["bank/banksSelected"];
		},
		selectedBankUpdate() {
			return this.$store.getters["bank/selectedBank"];
		},
		totalPage() {
			return this.$store.getters["bank/totalPage"];
		},
		currentPage() {
			return this.$store.getters["bank/currentPage"];
		},
		totalRecords() {
			return this.totalPage * this.rowsPerPage;
		},
		selectedDateStart() {
			let dateStart = null;
			if (this.dateStart) {
				dateStart = this.formatDateToISOString(this.dateStart);
			}
			return dateStart;
		},
		selectedDateEnd() {
			let dateEnd = null;
			if (this.dateEnd) {
				dateEnd = this.formatDateToISOString(this.dateEnd);
			}
			return dateEnd;
		},
		title() {
			// console.log("title", this.type);
			let result = this.lang.titleIn;
			if (this.type === 1) {
				result = this.lang.titleIn;
				return result;
			} else {
				result = this.lang.titleOut;
				return result;
			}
		},
		columnPayName() {
			let result = this.lang.columnPayNameIn;
			if (this.type === 1) {
				result = this.lang.columnPayNameIn;
				return result;
			} else {
				result = this.lang.columnPayNameOut;
				return result;
			}
		},
		sharedPaymentType() {
			return this.$store.getters["bank/transactionType"];
		},
		isOwnPage() {
			return !this.voucherId && !this.rvhid;
		},
		showCurrentPage() {
			let page = 0;
			page = this.rowsPerPage * (this.selectedPage - 1);
			return page;
		},
	},
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
	transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
	transform: translateY(-20px);
	opacity: 0;
}
</style>
