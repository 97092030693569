export default({
	referenceDocument: "ความสัมพันธ์",
    documentBookCode: "เล่มเอกสาร",
    documentBookName: "ชื่อเอกสาร",
    code: "เลขที่",
    date: "วันที่",
    cancelStatus: "ยกเลิก",
    postStatus: "สถานะ",
    amount: "จำนวนเงิน",
    createBy: "ชื่อผู้สร้าง",
    createDate: 'วันที่สร้าง',
    updateBy: "ชื่อผู้แก้ไข",
    updateDate: "วันที่แก้ไข",
    tableEmpty: "ไม่พบข้อมูล",
    columnIsPaid:"การชำระ",
})