export default {
    titleEdit: "แก้ไขใบอนุมัติปลอดหนี้",

    pendingDate: "ค่าใช้จ่ายค้างชำระ ณ วันที่",
    pendingAmount: "ยอดค้างชำระ",
    phoneNumber: "เบอร์โทรศัพท์",

    cancelReason1: "หลักฐานไม่ครบถ้วนไม่สมบูรณ์",
    cancelReason2: "ยังมีหนี้ค้างชำระ/ตรวจสอบแล้วยังไม่ปลอดหนี้",
    cancelReason3: "ระงับ/แจ้งขอยกเลิกการขอใบปลอดหนี้",
    reasonPlaceholder: "ระบุเหตุผล",
    validateReason: "กรุณาระบุเหตุผล",

    code: "เลขที่เอกสาร : :code",
    debtFreeApproveSuccess: "คุณอนุมัติหนังสือรับรองหนี้สำเร็จ",
    debtFreeDisapproveSuccess: "คุณทำรายการไม่อนุมัติหนังสือรับรองหนี้สำเร็จ",
}