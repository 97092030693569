import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default function useGetAccountingParent() {
  const store = useStore();
  let searchTextAccountingParent = ref(null);

  const accountingParents = computed(() => {
    return store.getters["shared/accountingParentAccounts"];
  });

  const accountingParentIsLastPage = computed(() => {
    return store.getters["shared/accountingParentIsLastPage"];
  });

  const filteredAccountingParents = computed(() => {
    if (!searchTextAccountingParent.value) {
      //ช่องว่าง
      const result = [...accountingParents.value];
      return result;
    } else {
      return accountingParents.value.filter((accounting) => {
        return accounting.searchText
          .toLowerCase()
          .includes(searchTextAccountingParent.value.toLowerCase());
      });
    }
  });

  const searchAccountingParent = function (event) {
    // console.log(event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchTextAccountingParent.value = "";
      searchTextAccountingParent.value = null;
    } else {
      searchTextAccountingParent.value = event.query;
    }
  };

  let isSharedAccountingParentReady = ref(false);

  const getAllAccountingParents = async function () {
    if (!accountingParentIsLastPage.value) {
      await store.dispatch("shared/getAllAccountingParents");
    }
    isSharedAccountingParentReady.value = true;
  };

  const getAccountingParentByCode = function (code) {
    return accountingParents.value.find((accounting) => {
      return accounting.code.trim().toLowerCase() === code.trim().toLowerCase();
    });
  };

  onMounted(async () => {
    await getAllAccountingParents();
  });

  const accountingParentIsReload = computed(() => {
    return store.getters["shared/accountingParentReload"];
  });

  // if(accountingParentIsReload.value){
  //   console.log('if');
  //   getAllAccountingParents();
  //   store.dispatch("shared/setAccountingParentReload",false);
  // }
  const setAccountingParentReload = async function () {
    if(accountingParentIsReload.value){
      await store.dispatch("shared/resetAccountingParent");
      await getAllAccountingParents();
      await store.dispatch("shared/setAccountingParentReload",false);
    }
  };


  return {
    searchAccountingParent,
    filteredAccountingParents,
    searchTextAccountingParent,
    getAccountingParentByCode,
    isSharedAccountingParentReady,
    setAccountingParentReload,
  };
}
