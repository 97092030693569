<template>
  <PvDialog :breakpoints="{ '1280px': '60vw', '960px': '80vw' }" :style="{ width: '50vw' }" :modal="true"
    :closable="false">
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ this.title }}
          </label>
        </div>
        <PvButton icon="pi pi-times" class="p-button-link p-icon-secondary" @click="closeDialog" />
      </div>
      <PvDivider></PvDivider>
    </template>

    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateStart">{{ lang.invoiceDateStart }}</label>
          <PvCalendar id="dateStart" v-model="dateStart" class="inputfield w-full mt-1" showIcon dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateStartError }" @date-select="dateStartError = false" />
          <small v-if="dateStartError" id="unit-help" class="p-error">{{
    lang.validateDateSelect
  }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="dateEnd">{{ lang.to }}</label>
          <PvCalendar id="dateEnd" v-model="dateEnd" class="inputfield w-full mt-1" showIcon dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateEndError }" @date-select="dateEndError = false" />
          <small v-if="dateEndError" id="unit-help" class="p-error">{{
    lang.validateDateSelect
  }}</small>
        </div>

        <div class="col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="payment">{{ lang.payment }}</label>
          <div class="flex flex-wrap">
            <div v-for="payment of payments" :key="payment.code" class="field-radiobutton pt-3 mr-5">
              <PvRadioButton class="mr-2" :inputId="payment.code" name="payment" :value="payment.code"
                v-model="selectPayment" @change="changePayment()" />
              <label :for="payment.code">{{ payment.name }}</label>
            </div>
          </div>
        </div>

        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2" v-if="showReceiveDate">
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
            <label for="receiveDate">{{ lang.receiveDateAt }}</label>
            <PvCalendar id="receiveDate" v-model="receiveDate" class="inputfield w-full mt-1" showIcon
              dateFormat="dd/mm/yy" :class="{ 'p-invalid': receiveDateError }"
              @date-select="receiveDateError = false" />
            <small v-if="receiveDateError" id="unit-help" class="p-error">{{
    lang.validateDateSelect
  }}</small>
          </div>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="unitStart">{{ lang.unitStart }}</label>
          <base-unit-auto-complete id="unit" v-model="selectedUnitStart" class="inputfield w-full mt-1"
            @load-finish="unitDataReady" :class="{ 'p-invalid': unitStartError }"
            @item-select="unitStartError = false" />
          <small v-if="unitStartError" id="unit-help" class="p-error">{{
    lang.validateNoUnitSelect
  }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="unitEnd">{{ lang.to }}</label>
          <base-unit-auto-complete id="unit" v-model="selectedUnitEnd" class="inputfield w-full mt-1"
            @load-finish="unitDataReady" :class="{ 'p-invalid': unitEndError }" @item-select="unitEndError = false" />
          <small v-if="unitEndError" id="unit-help" class="p-error">{{
    lang.validateNoUnitSelect
  }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="productStart">{{ lang.productStart }}</label>
          <base-product-ar-auto-complete id="productPrepaid" v-model="selectedProductStart"
            class="inputfield w-full mt-1" @load-finish="productArDataReady" :class="{ 'p-invalid': productStartError }"
            @item-select="productStartError = false" />
          <small v-if="productStartError" id="unit-help" class="p-error">{{
    lang.validateNoProductSelect
  }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="productEnd">{{ lang.to }}</label>
          <base-product-ar-auto-complete id="productPrepaid" v-model="selectedProductEnd" class="inputfield w-full mt-1"
            @load-finish="productArDataReady" :class="{ 'p-invalid': productEndError }"
            @item-select="productEndError = false" />
          <small v-if="productEndError" id="unit-help" class="p-error">{{
    lang.validateNoProductSelect
  }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="periodStart">{{ lang.periodStart }}</label>
          <!-- <PvInputText
            id="periodStart"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="MM/YYYY(พ.ศ.)"
            v-model="periodStart"
          /> -->
          <PvInputMask id="periodStart" mask="99/9999" v-model.trim="periodStart" class="inputfield w-full mt-1"
            placeholder="MM/YYYY(พ.ศ.)" />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="periodEnd">{{ lang.to }}</label>
          <!-- <PvInputText
            id="periodEnd"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="MM/YYYY(พ.ศ.)"
            v-model="periodEnd"
          /> -->
          <PvInputMask id="periodEnd" mask="99/9999" v-model.trim="periodEnd" class="inputfield w-full mt-1"
            placeholder="MM/YYYY(พ.ศ.)" />
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText id="fileNameText" type="text" class="inputfield w-full mt-1" v-model="fileNameText" />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 align-self-center pt-5" v-if="isShowIsIgnoreCancelDate">
          <PvCheckbox id="isIgnoreCancelDate" inputId="binary" v-model="isIgnoreCancelDate" :binary="true" />
          <label for="isIgnoreCancelDate" class="pl-3">{{
    lang.ignoreCancelDate
  }}</label>
        </div>
      </section>
    </transition>

    <template #footer>
      <div class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center">
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton icon="pi pi-file-pdf" :label="lang.downloadPDF" @click="download('PDF')" class="w-full" />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton icon="pi pi-file-excel" :label="lang.downloadExcel" @click="download('JASPEREXCEL')"
            class="w-full" />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton icon="pi pi-file-excel" :label="lang.downloadExcelRaw" @click="download('EXCEL')" class="w-full" />
        </div>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useTracking from "../../../hooks/tracking";
import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const { formatDate, formatDateToISOString } = useFormat();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder, splitPayloadByUnitCode } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
        };
      }
    });

    return {
      lang: mergeLang,
      textFormClass,
      formatDate,
      formatDateToISOString,
      addReportTracking,
      reportPrintOrder,
      splitPayloadByUnitCode
    };
  },
  data() {
    return {
      dateStart: null,
      dateEnd: null,
      dateStartError: false,
      dateEndError: false,
      payments: [
        { name: this.lang.receiveAll, code: "1" },
        { name: this.lang.receiveDate, code: "2" },
      ],
      selectPayment: "1",
      showReceiveDate: false,
      receiveDate: null,
      receiveDateError: false,
      unitStartError: false,
      unitEndError: false,
      selectedUnitStart: null,
      selectedUnitEnd: null,
      isSharedUnitReady: false,
      selectedProductStart: null,
      selectedProductEnd: null,
      productStartError: false,
      productEndError: false,
      isSharedProductArReady: false,
      periodStart: "",
      periodEnd: "",

      isIgnoreCancelDate: false,
      type: "PDF",
      fileNameText: "",
    };
  },
  mounted() {
    this.setLoading(true);
    let date = new Date();
    this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
    this.dateEnd = new Date();
    this.receiveDate = new Date();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    initiateData() {
      let date = new Date();
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date();
      this.receiveDate = new Date();
      this.dateStartError = false;
      this.dateEndError = false;
      this.receiveDateError = false;
      this.showReceiveDate = false;
      this.selectPayment = "1";
      this.selectedUnitStart = null;
      this.selectedUnitEnd = null;
      this.unitStartError = false;
      this.unitEndError = false;
      this.selectedProductStart = null;
      this.selectedProductEnd = null;
      this.productStartError = false;
      this.productEndError = false;
      this.periodStart = "";
      this.periodEnd = "";
      this.isSharedProductArReady = false;
      this.isSharedUnitReady = false;
      this.isIgnoreCancelDate = false;
      this.type = "PDF";
      this.fileNameText = this.fileName;
    },
    validateData() {
      let returnStatus = false;
      if (this.selectedDateStart == null) {
        this.dateStartError = true;
        returnStatus = true;
      }
      if (this.selectedDateEnd == null) {
        this.dateEndError = true;
        returnStatus = true;
      }
      if (this.selectedReceiptDate == null && this.showReceiveDate) {
        this.receiveDateError = true;
        returnStatus = true;
      }
      return returnStatus;
    },
    changePayment() {
      if (this.selectPayment == "1") {
        this.showReceiveDate = false;
      } else {
        this.showReceiveDate = true;
      }
    },
    async download(typeReport) {
      this.type = typeReport;
      if (this.validateData()) {
        return;
      }

      this.setLoading(true);
      try {
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);

        let payloadArray = [];
        if (this.reportObj.path == "invoice/invoice_detail_by_unit_code") {
          payloadArray = this.splitPayloadByUnitCode(this.payload, this.units, this.payload.UnitCodeStart, this.payload.UnitCodeEnd, this.fileNameText, this.fileType)
        } else {
          payloadArray.push(this.payload)
        }
        const response = await this.reportPrintOrder(payloadArray);
        if (response) {
          this.$emit("toast-request");
        }

        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
    unitDataReady() {
      this.isSharedUnitReady = true;
      this.checkShouldSetData();
    },
    productArDataReady() {
      this.isSharedProductArReady = true;
      this.checkShouldSetData();
    },
    checkShouldSetData() {
      if (this.isSharedProductArReady && this.isSharedUnitReady) {
        this.setData();
      }
    },
    setData() {
      this.fileNameText = this.fileName;
      this.selectedUnitStart = this.units[0];
      this.selectedUnitEnd = this.units[this.units.length - 1];
      this.selectedProductStart = this.products[0];
      this.selectedProductEnd = this.products[this.products.length - 1];
      this.setLoading(false);
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    title() {
      if (this.$store.getters.selectedLanguage === "TH")
        return this.reportObj.nameThai;
      else return this.reportObj.nameEnglish;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    selectedReceiptDate() {
      let receiveDate = null;
      if (this.showReceiveDate && this.receiveDate) {
        receiveDate = this.formatDateToISOString(this.receiveDate);
      } else {
        receiveDate = this.formatDateToISOString(new Date());
      }
      return receiveDate;
    },
    fileName() {
      let fileName = "";
      if (this.reportObj.path.includes("invoice/invoice_detail_by_unit_code")) {
        fileName = "invoice_detail_by_unit_code";
      } else if (
        this.reportObj.path.includes("invoice/invoice_detail_by_document_code")
      ) {
        fileName = "invoice_detail_by_document_code";
      } else if (
        this.reportObj.path.includes("invoice/invoice_detail_by_product_ar")
      ) {
        fileName = "invoice_detail_by_product_ar";
      } else if (
        this.reportObj.path.includes("invoice/invoice_summary_by_product_ar")
      ) {
        fileName = "invoice_summary_by_product_ar";
      }
      return fileName;
    },
    payload() {
      let payload = {
        ProjectId: this.selectedProject.id,
        DateStart: this.selectedDateStart,
        DateEnd: this.selectedDateEnd,
        ReceiptDate: this.selectedReceiptDate ? this.selectedReceiptDate : "",
        UnitCodeStart: this.selectedUnitStart
          ? this.selectedUnitStart.code
          : this.units[0].code,
        UnitCodeEnd: this.selectedUnitEnd
          ? this.selectedUnitEnd.code
          : this.units[this.units.length - 1].code,
        ProductCodeStart: this.selectedProductStart
          ? this.selectedProductStart.code
          : "",
        ProductCodeEnd: this.selectedProductEnd
          ? this.selectedProductEnd.code
          : "",
        PeriodStart: this.periodStart ? this.periodStart : "",
        PeriodEnd: this.periodEnd ? this.periodEnd : "",
        RequestId: 0,
        UserId: this.userId,
        PrintBy: this.userName,
        FileName: this.fileNameText + this.fileType,
        JasperPath: this.jasperPath,
        DocType: this.type,
      };

      if (this.reportObj.path.includes("invoice/invoice_detail_by_unit_code")) {
        let addParam = {
          OrderBy: "cor",
          IsIncludePenalty: "Y",
          ReportName: "InvoiceDetailByUnit",
        };
        payload = { ...payload, ...addParam };
      } else if (
        this.reportObj.path.includes("invoice/invoice_detail_by_document_code")
      ) {
        let addParam = {
          OrderBy: "Y",
          IsExcel: "N",
          ReportName: "InvoiceDetailByCode",
        };
        payload = { ...payload, ...addParam };
      } else if (
        this.reportObj.path.includes("invoice/invoice_detail_by_product_ar")
      ) {
        let addParam = {
          ReportName: "InvoiceDetailByProduct",
        };
        payload = { ...payload, ...addParam };
      } else if (
        this.reportObj.path.includes("invoice/invoice_summary_by_product_ar")
      ) {
        let addParam = {
          IsIncludePenalty: "Y",
          IsExcel: "N",
          IsIgnoreCancelDate: this.isIgnoreCancelDate,
          ReportName: "InvoiceSummaryByProduct",
        };
        payload = { ...payload, ...addParam };
      }

      return payload;
    },
    products() {
      return this.$store.getters["shared/productArProducts"];
    },
    units() {
      return this.$store.getters["shared/unitUnits"];
    },
    isShowIsIgnoreCancelDate() {
      if (this.reportObj.path.includes("invoice/invoice_summary_by_product_ar")) {
        return true;
      }
      return false;
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
    fileType() {
      let fileType = this.type == "PDF" ? ".pdf" : ".xlsx";
      return fileType;
    },
    jasperPath() {
      if (this.type == "EXCEL") {
        return this.reportObj.path + "_excel" + this.fileType;
      }
      return this.reportObj.path + this.fileType;
    },
  },
  watch: {
    reportObj() {
      this.initiateData();
    },
  },
};
</script>
