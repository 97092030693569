export default {
  async getDbdBusinessType(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/BusinessType/GetDbdBusinessTypes";

    if (!payload.limit) {
      url = url + "?limit=500";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get dbd business type"
      );
      throw error;
    }
    // console.log("dbd business type", responseData);

    // const data = responseData.data;
    const dbdBusinessType = {
      dbdBusinessTypeList: responseData.data,
      totalPage: responseData.page.pageCount,
      currentPage: responseData.page.currentPage,
    };
    context.commit("setDbdBusinessType", dbdBusinessType);
  },
  async getAllDbdBusinessType(context) {
    while (!context.getters.dbdBusinessTypeIsLastPage) {
      const payload = {
        cacheEnable: true,
        isActive: "Y",
        page: context.getters.dbdBusinessTypeNextPage || 1,
        limit: 500,
      };
      await context.dispatch("getDbdBusinessType", payload);
    }
  },
  resetDbdBusinessType(context) {
    context.commit("resetDbdBusinessType");
  },
};
