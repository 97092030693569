<template>
  <base-toolbar
    :title="lang.titlePreviewUsePrepaidAndDeposit"
    :hideBackButton="isHideBack"
    @goBack="goBack"
    @gotoSave="save()"
  ></base-toolbar>
  <base-progress-bar
    v-model:visible="showProgressBar"
    :title="lang.progressTitle"
    :textProgressBar="lang.progressGenerating"
    :valueObj="valueObj"
    :progressBar="`Template`"
  ></base-progress-bar>
  <prepare-use-prepaid-and-deposit-dialog
    v-model:visible="showPrepareDialog"
    :title="lang.progressTitle"
    :pendingPrepaidAndDepositCount="pendingPrepaidAndDepositCount"
    :usePrepaidAndDepositCount="usePrepaidAndDepositCount"
    :valueObj="valueObj"
    @close="showPrepareDialog = false"
  ></prepare-use-prepaid-and-deposit-dialog>
  <base-container>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{
          lang.titlePreviewUsePrepaidAndDeposit
        }}</label>

        <div class="flex">
          <PvButton class="ml-3" @click="save">
            <icon-save iconColor="white" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{
              lang.saveAll
            }}</span>
          </PvButton>
        </div>
      </div>

      <PvDivider></PvDivider>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="dataTables"
          :paginator="true"
          class="p-datatable-sm"
          :class="textTableClass"
          :rows="rowsPerPage"
          dataKey="id"
          :rowHover="true"
          paginatorTemplate="CurrentPageReport    FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 50, 100]"
          :currentPageReportTemplate="lang.page + ` {currentPage} / {totalPages}`"
          responsiveLayout="scroll"
        >
          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ lang.tableUsePrepaidAndDepositEmpty }}</label>
            </div>
          </template>
          
          <PvColumn
            field="itemNo"
            :header="lang.itemNo"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="saveStatusCode"
            :header="lang.status"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <base-tag
                :Caption="setBaseTagStatus(slotProps.data.saveStatusCode).caption"
                :Class="setBaseTagStatus(slotProps.data.saveStatusCode).class"
              />
            </template>
          </PvColumn>
          <PvColumn
            field="unitCode"
            :header="lang.tableUsePrepaidAndDepositUnitCode"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="receiptCode"
            :header="lang.tableUsePrepaidAndDepositReceiptCode"
            style="min-width: 7rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="invoiceCodeAndNoticeCode"
            :header="lang.tableUsePrepaidAndDepositInvoiceCode"
            style="min-width: 7rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="productName"
            :header="lang.tableUsePrepaidAndDepositProductName"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="period"
            :header="lang.tableUsePrepaidAndDepositPeriod"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="pendingAmount"
            :header="lang.tableUsePrepaidAndDepositTotalAmount"
            style="min-width: 7rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-right"
          >
            <template #body="slotProps">
              <span class="text-right w-full">
                {{ formatCurrency(slotProps.data.pendingAmount) }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            field="useAmount"
            :header="lang.tableUsePrepaidAndDepositUseAmount"
            style="min-width: 7rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-right"
          >
            <template #body="slotProps">
              <span class="text-right w-full">
                {{ formatCurrency(slotProps.data.useAmount) }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            field="useType"
            :header="lang.tableUsePrepaidAndDepositUseType"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <span class="text-left w-full">
                {{ setUseType(slotProps.data.useType) }}
              </span>
            </template>
          </PvColumn>

        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import { useStore } from "vuex";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { computed, inject, onMounted, ref , onBeforeUnmount } from "vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import BaseDialog from "../../../components/UI/BaseDialog.vue";
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import PrepareUsePrepaidAndDepositDialog from './PrepareUsePrepaidAndDepositDialog.vue';
export default {
  components: { PrepareUsePrepaidAndDepositDialog },
  props: ["isUsePrepaid", "isUseDeposit", "isBackToHomePage", "projectCode"],
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const isHideBack = inject("isHideBack");
    const { lang } = useLang();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.calculateInvoice.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.th.ar.calculateInvoice.listing,
        };
      }
    });

    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const unitExistPrepaids = computed(() => {
      return store.getters["usePrepaidAndDeposit/unitExistPrepaids"];
    });
    const unitExistDeposits = computed(() => {
      return store.getters["arDeposit/unitExistDeposits"];
    });
    const unitUsePrepaidAndDeposit = computed(() => {
      return store.getters["usePrepaidAndDeposit/usePrepaidAndDeposits"];
    });
    const pendingPrepaidAndDepositCount = computed(() => {
      let counting = 0;
      if (units.value) {
        counting = units.value.length;
      }
      return counting;
    });
    const selectedUsePrepaid = computed(() => {
      return store.getters["usePrepaidAndDeposit/selectedUsePrepaid"];
    });
    const receipt = computed(() => {
      return store.getters["arReceipt/selectedReceipt"];
    });
    const lengthDatas = computed(() => {
      return dataTables.value.length;
    });
    const dataWaitStatus = computed(() => {
      if (lengthDatas.value > 0) {
        return dataTables.value.filter((data) => data.saveStatusCode == "wait");
      }
      return [];
    });

    // data table
    let isLoading = ref(false);
    let selectedPage = ref(1);
    let rowsPerPage = ref(10);

    // progress bar
    let showProgressBar = ref(false);
    let valueObj = ref([]);

    // dialog
    let dataConfigDialog = ref(null);

    // dialog validate use prepaid and deposit
    let showPrepareDialog = ref(false);
    let usePrepaidAndDepositCount = ref(0);

    let units = ref([]);
    let dataTables = ref([]);
    let dataTableIndex = ref(0);

    onMounted(async () => {
      await initiateData();
      setLoading(true);
      try {
        await getUnitExistPrepaidAndDeposit();
        await getUsePrepaidAndDeposits();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
      window.addEventListener("beforeunload", handleBeforeUnload);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    });

    const handleBeforeUnload = async (event) => {
      if (dataWaitStatus.value.length > 0) {
        event.preventDefault();
      }
    };

    onBeforeRouteLeave(async (to, from) => {
      if (dataWaitStatus.value.length > 0) {
        var curValue = to.params.projectCode;
        var oldValue = from.params.projectCode;
        if (curValue == oldValue) {
          setDialog({
            classTitle: "",
            title: mergeLang.value.notificationDialogHeader,
            message: mergeLang.value.pageDirtyConfirmMessage,
            type: "confirm",
          });
          const dialogResponse = await createConfirmDialog(
            BaseDialog,
            dataConfigDialog.value
          ).reveal();
          return !dialogResponse.isCanceled;
        }
      }
      return true;
    });

    const setLoading = (bool) => {
      store.dispatch("setLoading", { value: bool });
    };

    const goBack = () => {
      if (props.isBackToHomePage == "Y") {
        router.push({
          name: "ar-use-prepaid-and-deposit",
        });
      } else {
        router.push({
          name: "ar-use-prepaid-and-deposit",
          query: { back: "ar-calculate-invoice" },
        });
      }
    };

    const setDialog = (dataConfig) => {
      const classMap = {
        success: "p-text-success",
        error: "p-error",
      };
      dataConfig.classTitle = classMap[dataConfig.classTitle] || "";
      dataConfig.button = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "cancel",
        },
      ];
      if (dataConfig.type === "confirm") {
        dataConfig.button.push({
          id: "confirm",
          caption: mergeLang.value.buttonDialogConfirm,
          class: "w-full",
          action: "confirm",
        });
      }
      dataConfigDialog.value = {
        titleConfirm: dataConfig.title,
        classTitle: dataConfig.classTitle,
        wordingConfirm: dataConfig.message,
        dataButtons: dataConfig.button,
        display: true,
      };
    };

    const setBaseTagStatus = (saveStatus) => {
      let result = {
        caption: "",
        class: "",
      };
      if (saveStatus.toLowerCase() === "wait") {
        result.caption = mergeLang.value.wait;
        result.class = "warning";
      } else if (saveStatus.toLowerCase() === "success") {
        result.caption = mergeLang.value.success;
        result.class = "success";
      } else if (saveStatus.toLowerCase() === "error") {
        result.caption = mergeLang.value.error;
        result.class = "error";
      }
      return result;
    };

    const setUseType = (useType) => {
      let result = "";
      if (useType.toLowerCase() === "prepaid") {
        result = mergeLang.value.columnUsePrepaidAndDepositUseTypePrepaid;
      } else if (useType.toLowerCase() === "deposit") {
        result = mergeLang.value.columnUsePrepaidAndDepositUseTypeDeposit;
      }
      return result;
    };

    const initiateData = async () => {
      dataConfigDialog.value = null;
      showProgressBar.value = false;
      showPrepareDialog.value = false;
      usePrepaidAndDepositCount.value = 0;
      valueObj.value = [];
      selectedPage.value = 1;
      rowsPerPage.value = 10;
      units.value = [];
      dataTables.value = []; 
      dataTableIndex.value = 0;
      await store.dispatch("usePrepaidAndDeposit/resetUnitExistPrepaids");
      await store.dispatch("arDeposit/resetUnitExistDeposits");
      await store.dispatch("usePrepaidAndDeposit/resetUsePrepaidAndDeposits");
    };

    const getUnitExistPrepaid = async () => {
      let payload = {
        projectId: selectedProject.value.id,
      };
      await store.dispatch("usePrepaidAndDeposit/unitExistPrepaids", payload);
    };  

    const getUnitExistDeposit = async () => {
      let payload = {
        projectId: selectedProject.value.id,
      };
      await store.dispatch("arDeposit/unitExistDeposits", payload);
    };
    
    const getUnitExistPrepaidAndDeposit = async () => {
      if (props.isUsePrepaid == "Y") {
        await getUnitExistPrepaid();
      }
      if (props.isUseDeposit == "Y") {
        await getUnitExistDeposit();
      }
      let prepaidData = unitExistPrepaids.value ? unitExistPrepaids.value : [];
      let depositData = unitExistDeposits.value ? unitExistDeposits.value : [];
      let mergedData = [...prepaidData, ...depositData];
      mergedData.sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0));
      let uniqueUnits = mergedData.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id === value.id
        ))
      );
      uniqueUnits = uniqueUnits.map(unit => {
        return { ...unit, markStatus: "" };
      });
      units.value = uniqueUnits;
    };

    const getUsePrepaidAndDeposits = async () => {
      setLoading(false);
      valueObj.value = [];
      valueObj.value = units.value;
      usePrepaidAndDepositCount.value = 0;
      showPrepareDialog.value = true;
      let itemNo = 1;
      for (const unit of units.value) {
        unit.markStatus = "1";
        let payload = {
          projectId: selectedProject.value.id,
          unitId: unit.id,
          isUsePrepaid: props.isUsePrepaid,
          isUseDeposit: props.isUseDeposit,
        };
        await store.dispatch("usePrepaidAndDeposit/pendingUsePrepaidDeposit", payload);
        if (unitUsePrepaidAndDeposit.value && unitUsePrepaidAndDeposit.value.data) {
          if (unitUsePrepaidAndDeposit.value.data.length > 0) {
            usePrepaidAndDepositCount.value++;
          }
          let datas = unitUsePrepaidAndDeposit.value.data;
          for (const data of datas) {
            let addData = {
              ...data,
              itemNo: itemNo++,
              saveStatusCode: "wait",
              saveStatusReason: "",
              markStatus: "",
            }
            dataTables.value.push(addData);
          }
        }
      }
      if ( pendingPrepaidAndDepositCount.value == 0) {
        showPrepareDialog.value = false;
      } else if (usePrepaidAndDepositCount.value > 0) {
        showPrepareDialog.value = false;
      }
    };

    const save = async () => {
      if (lengthDatas.value == 0 || (lengthDatas.value > 0 && dataWaitStatus.value == 0)) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.dialogContentSaveErrorNullData,
          type: "",
        });
        await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        return;
      }
      valueObj.value = [];
      valueObj.value = dataTables.value;
      showProgressBar.value = true;
      for (const [index, data] of dataTables.value.entries()) {
        if (index >= dataTableIndex.value) { // try processing continues error records
          if (data.saveStatusCode !== "success") {
            if (data.useType == "prepaid") {
              let isSavePrepaidSuccess = await savePrepaid(data);
              if (!isSavePrepaidSuccess) {
                dataTableIndex.value = index;
                showProgressBar.value = false;
                return;
              }
            } else {
              let isSaveDepositSuccess = await saveDeposit(data);
              if (!isSaveDepositSuccess) {
                dataTableIndex.value = index;
                showProgressBar.value = false;
                return;
              }
            }
          }
        }
      }
      dataTableIndex.value = 0; // reset index when all save successful
      showProgressBar.value = false;
      setDialog({
        classTitle: "",
        title: mergeLang.value.saveAllSucess,
        message: mergeLang.value.saveAllSucess,
        type: "",
      });
      await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
    };

    const savePrepaid = async (data) => {
      data.markStatus = "1";
      try {
        let payload = {
          invoiceId: data.invoiceDetailId,
          receiptId: data.receiptDetailId,
          padAmt: data.useAmount,
        };
        await store.dispatch("usePrepaidAndDeposit/pendingUsePrepaid", payload);
        if (selectedUsePrepaid.value.code == "201") {
          data.saveStatusCode = "success";
          return true;
        } else {
          data.saveStatusCode = "error";
          let msg = selectedUsePrepaid.value.message;
          if (selectedUsePrepaid.value.code == "101") {
            msg = `${mergeLang.value.dialogContentSaveErrorCode.replace("{0}", data.itemNo)} `+mergeLang.value.dialogContentSaveErrorCode101;
          } else if (selectedUsePrepaid.value.code == "105") {
            msg = `${mergeLang.value.dialogContentSaveErrorCode.replace("{0}", data.itemNo)} `+mergeLang.value.dialogContentSaveErrorCode105;
          } else if (selectedUsePrepaid.value.code == "107") {
            msg = `${mergeLang.value.dialogContentSaveErrorCode.replace("{0}", data.itemNo)} `+mergeLang.value.dialogContentSaveErrorCode107;
          }
          setDialog({
            classTitle: "",
            title: mergeLang.value.notificationDialogHeader,
            message: msg,
            type: "",
          });
          await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
          return false;
        }
      } catch (error) {
        data.saveStatusCode = "error";
        data.saveStatusReason = error;
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: `${mergeLang.value.dialogContentSaveError.replace("{0}", data.itemNo)}`,
          type: "",
        });
        await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        return false;
      }
    };

    const saveDeposit = async (data) => {
      data.markStatus = "1";
      try {
        let payload = {
          ...data.receipt,
          type: "Add",
        };
        await store.dispatch("arReceipt/createOrUpdateReceipt", payload);
        if (receipt.value) {
          data.saveStatusCode = "success";
          return true;
        } else {
          data.saveStatusCode = "error";
          return false;
        }
      } catch (error) {
        data.saveStatusCode = "error";
        data.saveStatusReason = error;
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: `${mergeLang.value.dialogContentSaveError.replace("{0}", data.itemNo)}`,
          type: "",
        });
        await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        return false;
      }
    };

    return {
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      isHideBack,
      lang: mergeLang,
      showProgressBar,
      valueObj,
      isLoading,
      rowsPerPage,
      dataTables,
      selectedPage,
      goBack,
      setBaseTagStatus,
      setUseType,
      save,
      showPrepareDialog,
      pendingPrepaidAndDepositCount,
      usePrepaidAndDepositCount,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-right: auto;
  }
}
::v-deep(.p-overlay-badge) {
  .p-badge {
    transform: translate(0%, 30%) !important;
  }
}
::v-deep(.p-badge) {
  padding: 0 0rem;
}
::v-deep(.p-button) {
  .p-badge {
    min-width: 7px !important;
    height: 7px !important;
  }
}
</style>