export default {
  async getReportMenuDetail(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/ReportMenu/ReportMenuDetail";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get report menu deatil");
      throw error;
    }

    await context.commit("setReportMenuDetail", responseData);
  },
  async createOrUpdateReportMenuDetail(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/ReportMenu/${payload.type}ReportMenuDetail`;

    const fallbackErrorMessage = `Failed to ${payload.type} report menu detail`
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: payload.type === 'Add' ? 'post' : 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    
    if (!response.ok) {
        let message = null
        if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
          message = responseData.title;
          for (const key in responseData.errors) {
            message += ", " + key.replace("$.","")
          }
        } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
          message = responseData.message
        }
        const error = new Error(message || fallbackErrorMessage);
        throw error;
    }
  
    context.commit("setSelectedReportMenuDetail", responseData.data);

  },
  async deleteReportMenuDetail(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + "/ReportMenu/DeleteReportMenuDetail";
    const fallbackErrorMessage = `Failed to Delete report menu detail`
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "Delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }
    context.commit('setReportMenuDetail', responseData);
  },
  async resetReportMenuDetail(context) {
    await context.commit("resetReportMenuDetail");
  },
  async getReportMenuDetailDropDown(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/ReportMenu/ReportMenuDetail";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get report menu deatil drop down");
      throw error;
    }

    // console.log("getReportMenuDetailDropDown", responseData);

    await context.commit("setReportMenuDetailDropDown", responseData);

  },
  async getAllReportMenuDetailDropDown(context) {
		while (!context.getters.isLastPageDropDown) {
      let where = {
        category: "All"
      };
			const payload = {
				page: context.getters.isNextPageDropDown || 1,
				where,
			};
			await context.dispatch("getReportMenuDetailDropDown", payload);
		}
	},
};
