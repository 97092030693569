import actions from "./actions.js";
import getters from "./getters.js";
import mutations from "./mutations.js";

export default {
	namespaced: true,
	state() {
		return {
			banksAr: [],
			banksForSelect: [],
			banksSelected: [],
			selectedBank: null,
			totalPage: 0,
			currentPage: 1,
			rows: 100,
			totalPageSelected: 0,
			currentPageSelected: 0,
			printLots: [],
		};
	},
	actions,
	getters,
	mutations,
};
