export default {
  setAccountingDetail(state, payload) {
    // state.project.length = 0;
    let accountingDetails = [...state.accountingDetail.accountingDetails];
    for (const accountingDetail of payload.accountingDetails) {
      if (accountingDetails.findIndex((c) => c.id === accountingDetail.id) < 0) {
        accountingDetails.push(accountingDetail);
      }
    }
    state.accountingDetail.accountingDetails = accountingDetails;
    state.accountingDetail.currentPage = payload.currentPage;
    state.accountingDetail.totalPage = payload.totalPage;
  },
  resetAccountingDetail(state) {
    const accountingDetail = {
      accountingDetails: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.accountingDetail = accountingDetail;
  },
};
