export default {
	logout: "ออกจากระบบ",
	logoutMessage: "คุณแน่ใจที่จะออกจากระบบ ใช่หรือไม่?",
	admin: "ผู้ดูแลระบบ",
	report: "รายงาน",
	glReport: "รายงาน GL",

	home: "หน้าหลัก",

	dashboard: "วิเคราะห์ภาพรวม",
	debtDashboard: "หนี้คงค้าง",
	parcelDashboard: "พัสดุ",
	livingDashboard: "สถานะพักอาศัย",
	mobileReigterDashboard: "รายงานลงทะเบียน LOS APP",

	chat: "แชท",

	parcel: "รับ-ส่งพัสดุ",
	parcelReport: "รายงานพัสดุ",

	serviceMaintenance: "ระบบแจ้งซ่อม",
	repairReport: "รายการแจ้งซ่อม",
	monthlyReport: "รายงานประจำเดือน",

	productAndService: "สินค้าและบริการ",

	visitor: "ระบบผู้ติดต่อ",

	reservation: "ระบบการจอง",

	residentInformation: "ข้อมูลสมาชิก/ลูกค้า",

	financialAccounting: "ระบบบัญชีการเงิน",
	settingAccount: "ตั้งค่าบัญชี",
	masterAccountChart: "ผังบัญชี",
	bookBank: "บัญชีธนาคาร",
	penaltyCalculation: "การคำนวณค่าปรับ",
	ar: "ระบบลูกหนี้",
	accountReceivable: "ระบบลูกหนี้",
	accountReceivableProduct: "ทะเบียนรายรับ",
	debtManagement: "การจัดการรับชำระหนี้",
	invoiceCalculation: "คำนวณใบแจ้งหนี้",
	arInvoice: "ใบแจ้งหนี้",
	arOutstandingLetter: "หนังสือยืนยันยอดค้าง",
	acceptPayment: "รับชำระ",
	arReceipt: "ใบเสร็จรับเงิน",
	arDeposit: "ใบรับฝากเงิน",
	arReduceDebt: "ใบลดหนี้",
	arBank: "รายการใบนำฝาก",
	guarantee: "เงินค้ำประกัน",
	receivableReport: "รายงานลูกหนี้",
	moneyTransfer: "ตรวจการโอนเงิน",
	arPrepaidRefund: "คืนเงินรับล่วงหน้า",
	notice: "หนังสือทวงถาม",
	noticeReport: "รายการหนังสือทวงถาม",
	noticeCalculation: "คำนวณหนังสือทวงถาม",
	noticeFormat: "หนังสือทวงถามรูปแบบอื่น",
	ap: "ระบบเจ้าหนี้",
	apWithholdingTax: "ภาษีหัก ณ ที่จ่าย",
	apBank: "รายการจ่ายชำระ",
	apPurchaseRequisition: "ใบขอซื้อ",
	apPurchaseOrder: "ใบสั่งซื้อ",
	apPurchaseInvoice: "ใบรับสินค้า/ใบรับวางบิล",
	apReceipt: "ใบเสร็จด้านจ่าย",
	reportAP: "รายงานเจ้าหนี้",
	reportWithholdingTax: "รายงาน ภ.ง.ด.",
	gl: "ระบบบัญชี",
	glInvoice: "ใบแจ้งหนี้มาลงบัญชี",
	glReceipt: "ใบเสร็จมาลงบัญชี",
	glAccounting: "บันทึกบัญชี",
	glPettyCash: "เงินสดย่อย",
	reportGL: "รายงานบัญชี",
	reportPC: "รายงานเงินสดย่อย",
	asset: "ระบบทรัพย์สิน",
	assetRegister: "ทะเบียนทรัพย์สิน",
	reportAsset: "รายงานทรัพย์สิน",

	master: "กำหนดฐานข้อมูล",
	project: "โครงการ",
	masterProject: "ข้อมูลโครงการ",
	projectDocument: "เอกสารโครงการ",
	masterAdvertisement: "ประชาสัมพันธ์-โฆษณา",
	meterRegistration: "ทะเบียนมิเตอร์น้ำไฟ",
	meterRegister: "ทะเบียนมิเตอร์",
	calculationFormat: "รูปแบบการคำนวณ",
	meter: "มิเตอร์",
	meterUsageWater: "บันทึกมิเตอร์ประปา",
	meterUsageElectricity : "บันทึกมิเตอร์ไฟ",
	meterUsageCoolingAir: "บันทึกมิเตอร์ความเย็น",
	meterUsageReport: "รายงานบันทึกมิเตอร์",
	policy: "ชุดเรียกเก็บ",

	smartAudit: "Smart Audit",

	news: "ประกาศข่าวสาร",

	userManual: "คู่มือการใช้งาน",

	help: "ช่วยเหลือ",
	changeProject: "เปลี่ยนโครงการ",
	setting: "ตั้งค่า",
	adminFormManage: "จัดการฟอร์มพิมพ์",
	formVersion: "ตั้งค่าเวอร์ชันแบบฟอร์ม",

	incomeRegister: "ตั้งค่าทะเบียนรายรับ",

	online: "ออนไลน์",
	unit: "ห้อง/บ้าน",
	productArPenalty: "อัตราค่าปรับ",
	unitProductInvoice: "รายการแจ้งหนี้",
	unitInformation: "รายการห้อง/บ้าน",
	resident: "สมาชิก/ลูกค้า",
	reportManage: "จัดการรายงาน",
	masterAccountBook: "ตั้งค่าสมุดบัญชี",

	reportRequest: "รายการคำขอ",
	reportReceipt: "รายงานใบเสร็จรับเงิน",

	usePrepaidAndDeposit: "ตัดรับล่วงหน้าและเงินฝาก",
	masterCustomerGroup: "ช่องทางการส่งเอกสาร",
	refundGuarantee: "คืนเงินค้ำประกัน",
	debtFree: "ปลอดหนี้",
	debtFreeMenu: "ระบบปลอดหนี้",
	productAp: "ทะเบียนรายจ่าย",
	supplier: "ผู้จัดจำหน่าย",
	closingEntry: "ปิดรอบบัญชี",
	accountingFrequently: "รูปแบบบัญชีใช้บ่อย",
	accountingPolicy: "นโยบายการบันทึกบัญชี",
	masterPhoneBook: "สมุดโทรศัพท์",
	searchMenu: "ค้นหาเมนู",
	menuEmpty: "ไม่พบเมนูที่ค้นหา โปรดใช้คำค้นหาอื่น",
	changePassword: "แก้ไขรหัสผ่าน",
	changeLanguage: "เปลี่ยนภาษา",
    passwordNotMatch: "รหัสผ่านใหม่ไม่ตรงกัน กรุณากรอกรหัสผ่านใหม่ให้ตรงกัน",
    titleChangePasswordSuccess: "เปลี่ยนรหัสผ่านสำเร็จ",
    changePasswordSuccess: "รหัสผ่านถูกเปลี่ยนเรียบร้อยแล้ว",
	oldPasswordNotMatch: "รหัสผ่านเก่าไม่ถูกต้อง โปรดลองอีกครั้ง",
	menuTitle: "PMS LivingOS",
	subMenuTitle: "ชีวิตที่ดีขึ้นในทุกๆวัน",
};
