// import { computed } from "vue";
// import {useStore} from "vuex";
import baseEn from "../lang/en.js";
import baseTh from "../lang/th.js";
import authLoginViewEn from "../lang/auth/loginView/en.js";
import authLoginViewTh from "../lang/auth/loginView/th.js";
import menuEn from "../lang/menu/en.js";
import menuTh from "../lang/menu/th.js";
import menuV2En from "../lang/menuV2/en.js";
import menuV2Th from "../lang/menuV2/th.js";
import settingEn from "../lang/setting/en.js";
import settingTh from "../lang/setting/th.js";
import selectProjectEn from "../lang/selectProject/en.js";
import selectProjectTh from "../lang/selectProject/th.js";
import arPrepaidRefundEn from "../lang/ar/prepaidRefund/en.js";
import arPrepaidRefundTh from "../lang/ar/prepaidRefund/th.js";
import arInvoiceListingEn from "../lang/ar/invoice/listing/en.js";
import arInvoiceListingTh from "../lang/ar/invoice/listing/th.js";
import arInvoiceNotificationEn from "../lang/ar/invoice/notification/en.js";
import arInvoiceNotificationTh from "../lang/ar/invoice/notification/th.js";
import arInvoicePrintLotTh from "../lang/ar/invoice/printLot/th.js";
import arInvoicePrintLotEn from "../lang/ar/invoice/printLot/en.js";
import arBankListingEn from "../lang/ar/bank/listing/en.js";
import arBankListingTh from "../lang/ar/bank/listing/th.js";
import arBankEditEn from "../lang/ar/bank/edit/en.js";
import arBankEditTh from "../lang/ar/bank/edit/th.js";
import arBankImportEn from "../lang/ar/bank/import/en.js";
import arBankImportTh from "../lang/ar/bank/import/th.js";
import apBankListingEn from "../lang/bank/listing/en.js";
import apBankListingTh from "../lang/bank/listing/th.js";
import apBankEditEn from "../lang/bank/edit/en.js";
import apBankEditTh from "../lang/bank/edit/th.js";
import arInvoiceEditEn from "../lang/ar/invoice/edit/en.js";
import arInvoiceEditTh from "../lang/ar/invoice/edit/th.js";
import sharedProductArModalEn from "../lang/shared/productArModal/en.js";
import sharedProductArModalTh from "../lang/shared/productArModal/th.js";
import adminFormListingEn from "../lang/admin/form/listing/en.js";
import adminFormListingTh from "../lang/admin/form/listing/th.js";
import adminFormDetailEn from "../lang/admin/form/detail/en.js";
import adminFormDetailTh from "../lang/admin/form/detail/th.js";
import sharedFormPrintModalEn from "../lang/shared/formPrintModal/en.js";
import sharedFormPrintModalTh from "../lang/shared/formPrintModal/th.js";
import glAccountingListingEn from "../lang/gl/accounting/listing/en.js";
import glAccountingListingTh from "../lang/gl/accounting/listing/th.js";
import glAccountingEditTh from "../lang/gl/accounting/edit/th.js";
import glAccountingEditEn from "../lang/gl/accounting/edit/en.js";
import glPettyCashEditTh from "../lang/gl/pettycash/th.js";
import glPettyCashEditEn from "../lang/gl/pettycash/en.js";
import apWithholdingTaxListingEn from "../lang/ap/withholdingTax/listing/en.js";
import apWithholdingTaxListingTh from "../lang/ap/withholdingTax/listing/th.js";
import apWithholdingTaxEditEn from "../lang/ap/withholdingTax/edit/en.js";
import apWithholdingTaxEditTh from "../lang/ap/withholdingTax/edit/th.js";
import apPurchaseReceiptListingEn from "../lang/ap/purchaseReceipt/listing/en.js";
import apPurchaseReceiptListingTh from "../lang/ap/purchaseReceipt/listing/th.js";
import apPurchaseReceiptEditEn from "../lang/ap/purchaseReceipt/edit/en.js";
import apPurchaseReceiptEditTh from "../lang/ap/purchaseReceipt/edit/th.js";
import glReportTh from "../lang/report/th.js";
import glReportEn from "../lang/report/en.js";
import apPurchaseInvoiceListingEn from "../lang/ap/purchaseInvoice/listing/en.js";
import apPurchaseInvoiceListingTh from "../lang/ap/purchaseInvoice/listing/th.js";
import apPurchaseInvoiceEditEn from "../lang/ap/purchaseInvoice/edit/en.js";
import apPurchaseInvoiceEditTh from "../lang/ap/purchaseInvoice/edit/th.js";
import sharedProductApModalEn from "../lang/shared/productApModal/en.js";
import sharedProductApModalTh from "../lang/shared/productApModal/th.js";
import apPurchaseRequisitionListingEn from "../lang/ap/purchaseRequisition/listing/en.js";
import apPurchaseRequisitionListingTh from "../lang/ap/purchaseRequisition/listing/th.js";
import apPurchaseRequisitionEditEn from "../lang/ap/purchaseRequisition/edit/en.js";
import apPurchaseRequisitionEditTh from "../lang/ap/purchaseRequisition/edit/th.js";
import apPurchaseOrderListingEn from "../lang/ap/purchaseOrder/listing/en.js";
import apPurchaseOrderListingTh from "../lang/ap/purchaseOrder/listing/th.js";
import apPurchaseOrderEditEn from "../lang/ap/purchaseOrder/edit/en.js";
import apPurchaseOrderEditTh from "../lang/ap/purchaseOrder/edit/th.js";
import masterProjectTh from "../lang/master/project/th.js";
import masterProjectEn from "../lang/master/project/en.js";
import assetAssetRegisterListingEn from "../lang/asset/assetRegister/lising/en.js";
import assetAssetRegisterListingTh from "../lang/asset/assetRegister/lising/th.js";
import assetAssetRegisterEditEn from "../lang/asset/assetRegister/edit/en.js";
import assetAssetRegisterEditTh from "../lang/asset/assetRegister/edit/th.js";
import navigationEn from "../lang/navigation/en.js";
import navigationTh from "../lang/navigation/th.js";
import masterProjectDocumentEn from "../lang/master/projectDocument/en.js";
import masterProjectDocumentTh from "../lang/master/projectDocument/th.js";
import unitListingEn from "../lang/master/unit/listing/en.js";
import unitListingTh from "../lang/master/unit/listing/th.js";
import unitEditEn from "../lang/master/unit/edit/en.js";
import unitEditTh from "../lang/master/unit/edit/th.js";
import productTh from "../lang/master/product/th.js";
import productEn from "../lang/master/product/en.js";
import meterListingTh from "../lang/master/meter/listing/th.js";
import meterListingEn from "../lang/master/meter/listing/en.js";
import meterAutoCreateTh from "../lang/master/meter/autoCreate/th.js";
import meterAutoCreateEn from "../lang/master/meter/autoCreate/en.js";
import meterEditTh from "../lang/master/meter/edit/th.js";
import meterEditEn from "../lang/master/meter/edit/en.js";
import meterUsageSettingTh from "../lang/master/meterUsageSetting/th.js";
import meterUsageSettingEn from "../lang/master/meterUsageSetting/en.js";
import residentListingTh from "../lang/master/resident/listing/th.js";
import residentListingEn from "../lang/master/resident/listing/en.js";
import residentEditTh from "../lang/master/resident/edit/th.js";
import residentEditEn from "../lang/master/resident/edit/en.js";
import unitProductEn from "../lang/master/unitProduct/en.js";
import unitProductTh from "../lang/master/unitProduct/th.js";
import accountReceivableProductListingTh from "../lang/master/product/listing/th.js";
import accountReceivableProductListingEn from "../lang/master/product/listing/en.js";
import accountReceivableProductEditTh from "../lang/master/product/edit/th.js";
import accountReceivableProductEditEn from "../lang/master/product/edit/en.js";
import sharedReferenceDocumentEn from "../lang/shared/referenceDocument/en.js";
import sharedReferenceDocumentTh from "../lang/shared/referenceDocument/th.js";
import bookBankListingTh from "../lang/master/bookBank/listing/th.js";
import bookBankListingEn from "../lang/master/bookBank/listing/en.js";
import bookBankEditTh from "../lang/master/bookBank/edit/th.js";
import bookBankEditEn from "../lang/master/bookBank/edit/en.js";
import formVersionTh from "../lang/admin/formVersion/th.js";
import formVersionEn from "../lang/admin/formVersion/en.js";
import meterUsageReportTh from "../lang/meter/meterUsage/report/th.js";
import meterUsageReportEn from "../lang/meter/meterUsage/report/en.js";
import meterUsageTh from "../lang/meter/meterUsage/listing/th.js";
import meterUsageEn from "../lang/meter/meterUsage/listing/en.js";
import arNoticeTh from "../lang/ar/notice/report/th.js";
import arNoticeEn from "../lang/ar/notice/report/en.js";
import contactUsTh from "../lang/contactUs/th.js";
import contactUsEn from "../lang/contactUs/en.js";
import reportTh from "../lang/admin/report/th.js";
import reportEn from "../lang/admin/report/en.js";
import accountBookListingTh from "../lang/master/accountBook/listing/th.js";
import accountBookListingEn from "../lang/master/accountBook/listing/en.js";
import accountBookEditTh from "../lang/master/accountBook/edit/th.js";
import accountBookEditEn from "../lang/master/accountBook/edit/en.js";
import reportRequestTh from "../lang/report/request/th.js";
import reportRequestEn from "../lang/report/request/en.js";
import arReceiptListingEn from "../lang/ar/receipt/listing/en.js";
import arReceiptListingTh from "../lang/ar/receipt/listing/th.js";
import arReceiptEditEn from "../lang/ar/receipt/edit/en.js";
import arReceiptEditTh from "../lang/ar/receipt/edit/th.js";
import sharedResidentEn from "../lang/shared/resident/en.js";
import sharedResidentTh from "../lang/shared/resident/th.js";
import arReceiptImportTh from "../lang/ar/receipt/import/th.js";
import arReceiptImportEn from "../lang/ar/receipt/import/en.js";
import arReceiptFormEn from "../lang/ar/receipt/form/en.js";
import arReceiptFormTh from "../lang/ar/receipt/form/th.js";
import arNoticeLisingTh from "../lang/ar/notice/lising/th.js";
import arNoticeLisingEn from "../lang/ar/notice/lising/en.js";
import arNoticeEditTh from "../lang/ar/notice/edit/th.js";
import arNoticeEditEn from "../lang/ar/notice/edit/en.js";
import outstandingLetterTh from "../lang/ar/invoice/outstandingLetter/th.js";
import outstandingLetterEn from "../lang/ar/invoice/outstandingLetter/en.js";
import visitorControlListingEn from "../lang/ar/visitorControl/listing/en.js";
import visitorControlListingTh from "../lang/ar/visitorControl/listing/th.js";
import visitorControlEditEn from "../lang/ar/visitorControl/edit/en.js";
import visitorControlEditTh from "../lang/ar/visitorControl/edit/th.js";
import arCalculateInvoiceListingEn from "../lang/ar/calculateInvoice/listing/en.js";
import arCalculateInvoiceListingTh from "../lang/ar/calculateInvoice/listing/th.js";
import arCalculateNoticeListingEn from "../lang/ar/calculateNotice/listing/en.js";
import arCalculateNoticeListingTh from "../lang/ar/calculateNotice/listing/th.js";
import policyListingTh from "../lang/master/policy/listing/th.js";
import policyListingEn from "../lang/master/policy/listing/en.js";
import policyEditTh from "../lang/master/policy/edit/th.js";
import policyEditEn from "../lang/master/policy/edit/en.js";
import arDepositLisingTh from "../lang/ar/deposit/listing/th.js";
import arDepositLisingEn from "../lang/ar/deposit/listing/en.js";
import arDepositEditTh from "../lang/ar/deposit/edit/th.js";
import arDepositEditEn from "../lang/ar/deposit/edit/en.js";
import arCreditNoteListingEn from "../lang/ar/creditNote/listing/en.js";
import arCreditNoteListingTh from "../lang/ar/creditNote/listing/th.js";
import arCreditNoteEditEn from "../lang/ar/creditNote/edit/en.js";
import arCreditNoteEditTh from "../lang/ar/creditNote/edit/th.js";
import customerGroupListingEn from "../lang/master/customerGroup/listing/en.js";
import customerGroupListingTh from "../lang/master/customerGroup/listing/th.js";
import customerGroupEditEn from "../lang/master/customerGroup/edit/en.js";
import customerGroupEditTh from "../lang/master/customerGroup/edit/th.js";
import refundGuaranteeListingTh from "../lang/ar/refundGuarantee/listing/th.js";
import refundGuaranteeListingEn from "../lang/ar/refundGuarantee/listing/en.js";
import refundGuaranteeEditTh from "../lang/ar/refundGuarantee/edit/th.js";
import refundGuaranteeEditEn from "../lang/ar/refundGuarantee/edit/en.js";
import debtFreeMenuTh from "../lang/debtFree/menu/th.js";
import debtFreeMenuEn from "../lang/debtFree/menu/en.js";
import productApListingTh from "../lang/master/productAp/listing/th.js";
import productApListingEn from "../lang/master/productAp/listing/en.js";
import productApEditTh from "../lang/master/productAp/edit/th.js";
import productApEditEn from "../lang/master/productAp/edit/en.js";
import debtFreeRequestListingTh from "../lang/debtFree/request/listing/th.js";
import debtFreeRequestListingEn from "../lang/debtFree/request/listing/en.js";
import debtFreeRequestEditTh from "../lang/debtFree/request/edit/th.js";
import debtFreeRequestEditEn from "../lang/debtFree/request/edit/en.js";
import debtFreeApproveListingTh from "../lang/debtFree/approve/listing/th.js";
import debtFreeApproveListingEn from "../lang/debtFree/approve/listing/en.js";
import debtFreeApproveEditTh from "../lang/debtFree/approve/edit/th.js";
import debtFreeApproveEditEn from "../lang/debtFree/approve/edit/en.js";
import supplierListingTh from "../lang/master/supplier/listing/th.js";
import supplierListingEn from "../lang/master/supplier/listing/en.js";
import supplierEditTh from "../lang/master/supplier/edit/th.js";
import supplierEditEn from "../lang/master/supplier/edit/en.js";
import debtFreePrintListingTh from "../lang/debtFree/print/listing/th.js";
import debtFreePrintListingEn from "../lang/debtFree/print/listing/en.js";
import debtFreeFollowUpListingTh from "../lang/debtFree/followUp/listing/th.js";
import debtFreeFollowUpListingEn from "../lang/debtFree/followUp/listing/en.js";
import debtFreeFollowUpEditTh from "../lang/debtFree/followUp/edit/th.js";
import debtFreeFollowUpEditEn from "../lang/debtFree/followUp/edit/en.js";
import closingEntryListingTh from "../lang/gl/closingEntry/listing/th.js";
import closingEntryListingEn from "../lang/gl/closingEntry/listing/en.js";
import closingEntryEditTh from "../lang/gl/closingEntry/edit/th.js";
import closingEntryEditEn from "../lang/gl/closingEntry/edit/en.js";
import AccountingFrequentlyListingTh from "../lang/master/accountingFrequently/listing/th.js";
import AccountingFrequentlyListingEn from "../lang/master/accountingFrequently/listing/en.js";
import AccountingFrequentlyEditTh from "../lang/master/accountingFrequently/edit/th.js";
import AccountingFrequentlyEditEn from "../lang/master/accountingFrequently/edit/en.js";
import accountChartListingTh from "../lang/master/accountChart/listing/th.js";
import accountChartListingEn from "../lang/master/accountChart/listing/en.js";
import accountChartEditTh from "../lang/master/accountChart/edit/th.js";
import accountChartEditEn from "../lang/master/accountChart/edit/en.js";
import MobileRegisterListingTh from "../lang/dashboard/mobileRegister/listing/th.js";
import MobileRegisterListingEn from "../lang/dashboard/mobileRegister/listing/en.js";
import AccountingPolicyTh from "../lang/master/accountingPolicy/th.js";
import AccountingPolicyEn from "../lang/master/accountingPolicy/en.js";
import PhoneBookListingTh from "../lang/master/phoneBook/listing/th.js";
import PhoneBookListingEn from "../lang/master/phoneBook/listing/en.js";
import PhoneBookEditTh from "../lang/master/phoneBook/edit/th.js";
import PhoneBookEditEn from "../lang/master/phoneBook/edit/en.js";
import AdvertisementCarouselTh from "../lang/advertisement/th.js";
import AdvertisementCarouselEn from "../lang/advertisement/en.js";
import dashboardTh from "../lang/dashboard/dashboard/th.js";
import dashboardEn from "../lang/dashboard/dashboard/en.js";
import authChangPasswordTh from "../lang/auth/changPassword//th.js";
import authChangPasswordEn from "../lang/auth/changPassword/en.js";
import noticePrintSettingTh from "../lang/master/noticePrintSetting/th.js";
import noticePrintSettingEn from "../lang/master/noticePrintSetting/en.js";
import arInvoiceImportTh from "../lang/ar/invoice/import/th.js";
import arInvoiceImportEn from "../lang/ar/invoice/import/en.js";
import sharedUnitEn from "../lang/shared/unit/en.js";
import sharedUnitTh from "../lang/shared/unit/th.js";
import noticeCustomReportTh from "../lang/ar/notice/customReport/listing/th.js";
import noticeCustomReportEn from "../lang/ar/notice/customReport/listing/en.js";
import noticeCustomReportEditTh from "../lang/ar/notice/customReport/edit/th.js";
import noticeCustomReportEditEn from "../lang/ar/notice/customReport/edit/en.js";
import sharedCropImageEn from "../lang/shared/cropImage/en.js";
import sharedCropImageTh from "../lang/shared/cropImage/th.js";
import arReceiptBillPaymentTh from "../lang/ar/receipt/billPayment/th.js";
import arReceiptBillPaymentEn from "../lang/ar/receipt/billPayment/en.js";
import transferSlipTh from "../lang/ar/transferSlip/lising/th.js";
import transferSlipEn from "../lang/ar/transferSlip/lising/en.js";
import transferSlipEditTh from "../lang/ar/transferSlip/edit/th.js";
import transferSlipEditEn from "../lang/ar/transferSlip/edit/en.js";
import arStockKeycardTh from "../lang/ar/stockKeycard/listing/th.js";
import arStockKeycardEn from "../lang/ar/stockKeycard/listing/en.js";
import arStockKeycardEditTh from "../lang/ar/stockKeycard/edit/th.js";
import arStockKeycardEditEn from "../lang/ar/stockKeycard/edit/en.js";
import controlStockKeycardTh from "../lang/ar/stockKeycard/controlStockKeycard/th.js";
import controlStockKeycardEn from "../lang/ar/stockKeycard/controlStockKeycard/en.js";
import stockKeycardImportTh from "../lang/ar/stockKeycard/import/th.js";
import stockKeycardImportEn from "../lang/ar/stockKeycard/import/en.js";
import residentImportTh from "../lang/master/resident/import/th.js";
import residentImportEn from "../lang/master/resident/import/en.js";
import unitResidentImportTh from "../lang/master/unit/import/th.js";
import unitResidentImportEn from "../lang/master/unit/import/en.js";
import validateImportTh from "../lang/validateImport/th.js";
import validateImportEn from "../lang/validateImport/en.js";
import supplierImportTh from "../lang/master/supplier/import/th.js";
import supplierImportEn from "../lang/master/supplier/import/en.js";
import depositImportTh from "../lang/ar/deposit/import/th.js";
import depositImportEn from "../lang/ar/deposit/import/en.js";
import assetImportTh from "../lang/asset/assetRegister/import/th.js";
import assetImportEn from "../lang/asset/assetRegister/import/en.js";
import budgetTh from "../lang/budget/listing/th.js";
import budgetEn from "../lang/budget/listing/en.js";
import budgetEditTh from "../lang/budget/edit/th.js";
import budgetEditEn from "../lang/budget/edit/en.js";
import signatureTh from "../lang/master/signatureManagement/signature/listing/th.js";
import signatureEn from "../lang/master/signatureManagement/signature/listing/en.js";
import signatureEditTh from "../lang/master/signatureManagement/signature/edit/th.js";
import signatureEditEn from "../lang/master/signatureManagement/signature/edit/en.js";
import signatureManagementTh from "../lang/master/signatureManagement/signatureManagement/listing/th.js";
import signatureManagementEn from "../lang/master/signatureManagement/signatureManagement/listing/en.js";
import signatureManagementEditTh from "../lang/master/signatureManagement/signatureManagement/edit/th.js";
import signatureManagementEditEn from "../lang/master/signatureManagement/signatureManagement/edit/en.js";
import paymentRequestTypeTh from "../lang/paymentRequest/paymentRequestType/listing/th.js";
import paymentRequestTypeEn from "../lang/paymentRequest/paymentRequestType/listing/en.js";
import paymentRequestTypeEditTh from "../lang/paymentRequest/paymentRequestType/edit/th.js";
import paymentRequestTypeEditEn from "../lang/paymentRequest/paymentRequestType/edit/en.js";
import paymentRequestEditTh from "../lang/paymentRequest/edit/th.js";
import paymentRequestEditEn from "../lang/paymentRequest/edit/en.js";
import paymentRequestTh from "../lang/paymentRequest/listing/th.js";
import paymentRequestEn from "../lang/paymentRequest/listing/en.js";

export default function useLanguage() {
	let lang = {
		th: {
			base: baseTh,
			menu: menuTh,
			menuV2: menuV2Th,
			setting: settingTh,
			selectProject: selectProjectTh,
			reportRequest: reportRequestTh,
			auth: {
				loginView: authLoginViewTh,
				changpassword: authChangPasswordTh,
			},
			ar: {
				invoice: {
					listing: arInvoiceListingTh,
					edit: arInvoiceEditTh,
					notification: arInvoiceNotificationTh,
					printLot: arInvoicePrintLotTh,
					outstandingLetter: outstandingLetterTh,
					import: arInvoiceImportTh,
				},
				bank: {
					listing: arBankListingTh,
					edit: arBankEditTh,
					import: arBankImportTh,
				},
				prepaidRefund: arPrepaidRefundTh,
				notice: {
					listing: arNoticeLisingTh,
					edit: arNoticeEditTh,
					report: arNoticeTh,
					customReport: noticeCustomReportTh,
					customReportEdit: noticeCustomReportEditTh,
				},
				receipt: {
					listing: arReceiptListingTh,
					edit: arReceiptEditTh,
					import: arReceiptImportTh,
					form: arReceiptFormTh,
					billPayment: arReceiptBillPaymentTh,
				},
				visitorControl: {
					listing: visitorControlListingTh,
					edit: visitorControlEditTh,
				},
				calculateInvoice: {
					listing: arCalculateInvoiceListingTh,
				},
				calculateNotice: {
					listing: arCalculateNoticeListingTh,
				},
				deposit: {
					listing: arDepositLisingTh,
					edit: arDepositEditTh,
					import: depositImportTh,
				},
				creditNote: {
					listing: arCreditNoteListingTh,
					edit: arCreditNoteEditTh,
				},
				refundGuarantee: {
					listing: refundGuaranteeListingTh,
					edit: refundGuaranteeEditTh,
				},
				transferSlip: {
					listing: transferSlipTh,
					edit: transferSlipEditTh,
				},
				stockKeycard: {
					listing: arStockKeycardTh,
					edit: arStockKeycardEditTh,
					controlStockKeycard: controlStockKeycardTh,
					import: stockKeycardImportTh,
				},
			},
			gl: {
				accounting: {
					listing: glAccountingListingTh,
					edit: glAccountingEditTh,
				},
				PettyCash: glPettyCashEditTh,
				reportGl: glReportTh,
				closingEntry: {
					listing: closingEntryListingTh,
					edit: closingEntryEditTh,
				},
			},
			shared: {
				productArModal: sharedProductArModalTh,
				formPrintModal: sharedFormPrintModalTh,
				productApModal: sharedProductApModalTh,
				referenceDocumen: sharedReferenceDocumentTh,
				resident: sharedResidentTh,
				unit: sharedUnitTh,
				cropImageModal: sharedCropImageTh,
			},
			admin: {
				form: {
					listing: adminFormListingTh,
					detail: adminFormDetailTh,
				},
				formVersion: formVersionTh,
				report: reportTh,
			},
			ap: {
				withholdingTax: {
					listing: apWithholdingTaxListingTh,
					edit: apWithholdingTaxEditTh,
				},
				purchaseInvoice: {
					listing: apPurchaseInvoiceListingTh,
					edit: apPurchaseInvoiceEditTh,
				},
				purchaseReceipt: {
					listing: apPurchaseReceiptListingTh,
					edit: apPurchaseReceiptEditTh,
				},
				purchaseRequisition: {
					listing: apPurchaseRequisitionListingTh,
					edit: apPurchaseRequisitionEditTh,
				},
				purchaseOrder: {
					listing: apPurchaseOrderListingTh,
					edit: apPurchaseOrderEditTh,
				},
				bank: {
					listing: apBankListingTh,
					edit: apBankEditTh,
				},
				paymentRequestType: {
					listing: paymentRequestTypeTh,
					edit: paymentRequestTypeEditTh,
				},
				paymentRequest: {
					edit: paymentRequestEditTh,
					listing: paymentRequestTh,
				},
			},
			master: {
				project: {
					edit: masterProjectTh,
				},
				projectDocument: masterProjectDocumentTh,
				meter: {
					listing: meterListingTh,
					edit: meterEditTh,
					report: meterUsageReportTh,
					autoCreate: meterAutoCreateTh,
				},
				meterUsageSetting: meterUsageSettingTh,
				product: productTh,
				accountReceivableProduct: {
					listing: accountReceivableProductListingTh,
					edit: accountReceivableProductEditTh,
				},
				unit: {
					listing: unitListingTh,
					edit: unitEditTh,
					import: unitResidentImportTh,
				},
				resident: {
					listing: residentListingTh,
					edit: residentEditTh,
					import: residentImportTh,
				},
				unitProduct: unitProductTh,
				bookBank: {
					listing: bookBankListingTh,
					edit: bookBankEditTh,
				},
				accountBook: {
					listing: accountBookListingTh,
					edit: accountBookEditTh,
				},
				policy: {
					listing: policyListingTh,
					edit: policyEditTh,
				},
				customerGroup: {
					listing: customerGroupListingTh,
					edit: customerGroupEditTh,
				},
				productAp: {
					listing: productApListingTh,
					edit: productApEditTh,
				},
				supplier: {
					listing: supplierListingTh,
					edit: supplierEditTh,
					import: supplierImportTh,
				},
				accountingFrequently: {
					listing: AccountingFrequentlyListingTh,
					edit: AccountingFrequentlyEditTh,
				},
				accountChart: {
					listing: accountChartListingTh,
					edit: accountChartEditTh,
				},
				accountingPolicy: AccountingPolicyTh,
				phoneBook: {
					listing: PhoneBookListingTh,
					edit: PhoneBookEditTh,
				},
				noticePrintSetting: noticePrintSettingTh,
				signatureManagement: {
					signature: {
						listing: signatureTh,
						edit: signatureEditTh
					},
					signatureManagement: {
						listing: signatureManagementTh,
						edit: signatureManagementEditTh
					},
				},
			},
			asset: {
				assetRegister: {
					listing: assetAssetRegisterListingTh,
					edit: assetAssetRegisterEditTh,
					import: assetImportTh,
				},
			},
			navigation: navigationTh,
			meter: {
				meterUsage: {
					report: meterUsageReportTh,
					listing: meterUsageTh,
				},
			},
			contactUs: contactUsTh,
			debtFree: {
				menu: debtFreeMenuTh,
				request: {
					listing: debtFreeRequestListingTh,
					edit: debtFreeRequestEditTh
				},
				approve: {
					listing: debtFreeApproveListingTh,
					edit: debtFreeApproveEditTh
				},
				print: {
					listing: debtFreePrintListingTh,
				},
				followUp: {
					listing: debtFreeFollowUpListingTh,
					edit: debtFreeFollowUpEditTh
				},
			},
			dashboard: {
				mobileRegister: {
					listing: MobileRegisterListingTh
				},
				dashboard: dashboardTh,
			},
			advertisement: AdvertisementCarouselTh,
			validateImport: validateImportTh,
			budget: {
				listing: budgetTh,
				edit: budgetEditTh,
			},

		},
		en: {
			base: baseEn,
			menu: menuEn,
			menuV2: menuV2En,
			setting: settingEn,
			selectProject: selectProjectEn,
			reportRequest: reportRequestEn,
			auth: {
				loginView: authLoginViewEn,
				changpassword: authChangPasswordEn,
			},
			ar: {
				invoice: {
					listing: arInvoiceListingEn,
					edit: arInvoiceEditEn,
					notification: arInvoiceNotificationEn,
					printLot: arInvoicePrintLotEn,
					outstandingLetter: outstandingLetterEn,
					import: arInvoiceImportEn,
				},
				bank: {
					listing: arBankListingEn,
					edit: arBankEditEn,
					import: arBankImportEn,
				},
				prepaidRefund: arPrepaidRefundEn,
				notice: {
					listing: arNoticeLisingEn,
					edit: arNoticeEditEn,
					report: arNoticeEn,
					customReport: noticeCustomReportEn,
					customReportEdit: noticeCustomReportEditEn,
				},
				receipt: {
					listing: arReceiptListingEn,
					edit: arReceiptEditEn,
					import: arReceiptImportEn,
					form: arReceiptFormEn,
					billPayment: arReceiptBillPaymentEn,
				},
				visitorControl: {
					listing: visitorControlListingEn,
					edit: visitorControlEditEn,
				},
				calculateInvoice: {
					listing: arCalculateInvoiceListingEn,
				},
				calculateNotice: {
					listing: arCalculateNoticeListingEn,
				},
				deposit: {
					listing: arDepositLisingEn,
					edit: arDepositEditEn,
					import: depositImportEn,
				},
				creditNote: {
					listing: arCreditNoteListingEn,
					edit: arCreditNoteEditEn,
				},
				refundGuarantee: {
					listing: refundGuaranteeListingEn,
					edit: refundGuaranteeEditEn,
				},
				transferSlip: {
					listing: transferSlipEn,
					edit: transferSlipEditEn,
				},
				stockKeycard: {
					listing: arStockKeycardEn,
					edit: arStockKeycardEditEn,
					controlStockKeycard: controlStockKeycardEn,
					import: stockKeycardImportEn,
				},
			},
			gl: {
				accounting: {
					listing: glAccountingListingEn,
					edit: glAccountingEditEn,
				},
				PettyCash: glPettyCashEditEn,
				reportGl: glReportEn,
				closingEntry: {
					listing: closingEntryListingEn,
					edit: closingEntryEditEn,
				},
			},
			shared: {
				productArModal: sharedProductArModalEn,
				formPrintModal: sharedFormPrintModalEn,
				productApModal: sharedProductApModalEn,
				referenceDocumen: sharedReferenceDocumentEn,
				resident: sharedResidentEn,
				unit: sharedUnitEn,
				cropImageModal: sharedCropImageEn,
			},
			admin: {
				form: {
					listing: adminFormListingEn,
					detail: adminFormDetailEn,
				},
				formVersion: formVersionEn,
				report: reportEn,
			},
			ap: {
				withholdingTax: {
					listing: apWithholdingTaxListingEn,
					edit: apWithholdingTaxEditEn,
				},
				purchaseInvoice: {
					listing: apPurchaseInvoiceListingEn,
					edit: apPurchaseInvoiceEditEn,
				},
				purchaseReceipt: {
					listing: apPurchaseReceiptListingEn,
					edit: apPurchaseReceiptEditEn,
				},
				purchaseRequisition: {
					listing: apPurchaseRequisitionListingEn,
					edit: apPurchaseRequisitionEditEn,
				},
				purchaseOrder: {
					listing: apPurchaseOrderListingEn,
					edit: apPurchaseOrderEditEn,
				},
				bank: {
					listing: apBankListingEn,
					edit: apBankEditEn,
				},
				paymentRequestType: {
					listing: paymentRequestTypeEn,
					edit: paymentRequestTypeEditEn,
				},
				paymentRequest: {
					edit: paymentRequestEditEn,
					listing: paymentRequestEn,
				},
			},
			master: {
				project: {
					edit: masterProjectEn,
				},
				projectDocument: masterProjectDocumentEn,
				product: productEn,
				accountReceivableProduct: {
					listing: accountReceivableProductListingEn,
					edit: accountReceivableProductEditEn,
				},
				meter: {
					listing: meterListingEn,
					edit: meterEditEn,
					report: meterUsageReportEn,
					autoCreate: meterAutoCreateEn,
				},
				meterUsageSetting: meterUsageSettingEn,
				unit: {
					listing: unitListingEn,
					edit: unitEditEn,
					import: unitResidentImportEn,
				},
				resident: {
					listing: residentListingEn,
					edit: residentEditEn,
					import: residentImportEn,
				},
				unitProduct: unitProductEn,
				bookBank: {
					listing: bookBankListingEn,
					edit: bookBankEditEn,
				},
				accountBook: {
					listing: accountBookListingEn,
					edit: accountBookEditEn,
				},
				policy: {
					listing: policyListingEn,
					edit: policyEditEn,
				},
				customerGroup: {
					listing: customerGroupListingEn,
					edit: customerGroupEditEn,
				},
				productAp: {
					listing: productApListingEn,
					edit: productApEditEn,
				},
				supplier: {
					listing: supplierListingEn,
					edit: supplierEditEn,
					import: supplierImportEn,
				},
				accountingFrequently: {
					listing: AccountingFrequentlyListingEn,
					edit: AccountingFrequentlyEditEn,
				},
				accountingPolicy: AccountingPolicyEn,
				phoneBook: {
					listing: PhoneBookListingEn,
					edit: PhoneBookEditEn,
				},
				accountChart: {
					listing: accountChartListingEn,
					edit: accountChartEditEn,
				},
				noticePrintSetting: noticePrintSettingEn,
				signatureManagement: {
					signature: {
						listing: signatureEn,
						edit: signatureEditEn
					},
					signatureManagement: {
						listing: signatureManagementEn,
						edit: signatureManagementEditEn
					},
				},
			},
			asset: {
				assetRegister: {
					listing: assetAssetRegisterListingEn,
					edit: assetAssetRegisterEditEn,
					import: assetImportEn,
				},
			},
			navigation: navigationEn,
			meter: {
				meterUsage: {
					report: meterUsageReportTh,
					listing: meterUsageEn,
				},
			},
			contactUs: contactUsEn,
			debtFree: {
				menu: debtFreeMenuEn,
				request: {
					listing: debtFreeRequestListingEn,
					edit: debtFreeRequestEditEn
				},
				approve: {
					listing: debtFreeApproveListingEn,
					edit: debtFreeApproveEditEn
				},
				print: {
					listing: debtFreePrintListingEn,
				},
				followUp: {
					listing: debtFreeFollowUpListingEn,
					edit: debtFreeFollowUpEditEn
				},
			},
			dashboard: {
				mobileRegister: {
					listing: MobileRegisterListingEn
				},
				dashboard: dashboardEn,
			},
			advertisement: AdvertisementCarouselEn,
			validateImport: validateImportEn,
			budget: {
				listing: budgetEn,
				edit: budgetEditEn,
			},
		},
	};

	return { lang };
}
