<template>
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredCountryCode"
    field="codeAndNameTh"
    @complete="searchCountryCode"
    @dropdown-click="filteredCountryCode"
    forceSelection
  />
</template>

<script>
import { onMounted } from "vue";
import useGetCountryCode from "../../hooks/getCountryCode.js";
export default {
  emits: ["load-finish"],
  props: {
    isLoadingData: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { searchCountryCode, filteredCountryCode, isSharedCountryCodeReady, getAllCountryCode } = useGetCountryCode();

    onMounted(async () => {
      if (props.isLoadingData) {
        getAllCountryCode();
      }
    });

    return {
        searchCountryCode,
        filteredCountryCode,
        isSharedCountryCodeReady,
        getAllCountryCode,
    }
  },
  watch: {
    isSharedCountryCodeReady(curValue) {
      if (curValue) {
        this.$emit("load-finish");
      }
    },
  },
};
</script>
