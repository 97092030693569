<template>
  <base-toolbar
    :title="lang.title"
    @goBack="goBack"
    @gotoCreate="gotoEdit('create')"
  ></base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :classTitle="baseDialogClassTitle"
      :titleConfirm="titleConfirm"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
    ></base-dialog>

    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{ lang.debtFreeList }}</label>

      <PvDivider></PvDivider>

      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >
        <!-- <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            id="filterStatus"
            class="inputfield w-full mr-2"
            :options="filterStatus"
            optionLabel="name"
            v-model="selectedFilterStatus"
            scrollHeight="300px"
            @change="changeFilterStatus"
          />
        </div> -->
        <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            id="filterSearch"
            class="inputfield w-full mr-2"
            :options="filterSearch"
            optionLabel="name"
            optionValue="code"
            v-model="selectedFilterSearch"
            scrollHeight="300px"
            @change="changeFilterSearch"
          />
        </div>
        <div
          class="flex lg:col-6 md:col-12 col-12 pl-0 pr-2 mr-2"
          v-if="isSearchDate"
        >
          <div class="col-6 pl-0 pr-2">
            <PvCalendar
              id="dateStart"
              v-model="dateStart"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
          <label for="dateEnd" class="pt-1 mt-3 mr-2">{{ "-" }}</label>
          <div class="col-6 pl-0 pr-2">
            <PvCalendar
              id="dateEnd"
              v-model="dateEnd"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
        </div>
        <div class="flex lg:col-6 md:col-12 col-12 pl-0 pr-2 mr-2" v-else>
          <div class="col-12 pl-0 pr-0">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchText"
                :placeholder="lang.searchTextPlaceholder"
                @keyup.enter="getDebtFreeFollowUpList"
                autofocus
              />
            </span>
          </div>
        </div>
        <PvButton @click="getDebtFreeFollowUpList">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="debtFreeFollowUpList"
          v-model:selection="selectedDebtFreeFollowUp"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(this.selectedDebtFreeFollowUp)"
          scrollable
        >
          <PvColumn
            field="unitCode"
            :header="lang.unitCode"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <u>{{ slotProps.data.unitCode }}</u>
            </template>
          </PvColumn>
          <PvColumn
            field="unitAddressNo"
            :header="lang.unitAddressNo"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="ownerName"
            :header="lang.ownerName"
            style="min-width: 18rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="debtfreeCode"
            :header="lang.documentCode"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="documentDate"
            :header="lang.documentDate"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.documentDate) }}
            </template>
          </PvColumn>
          <PvColumn
            field="requestDate"
            :header="lang.requestDate"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.requestDate) }}
            </template>
          </PvColumn>
          <PvColumn
            field="followupStatus"
            :header="lang.followupStatus"
            style="min-width: 12rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="followupDate"
            :header="lang.followupDate"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ slotProps.data.followupDate?formatDate(slotProps.data.followupDate):'' }}
            </template>
          </PvColumn>
          <PvColumn
            style="min-width: 4rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="gotoEdit(slotProps.data)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
            </template>
          </PvColumn>

          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              :first="showCurrentPage"
              class="p-pagination-radius"
            >
              <template #start="slotProps">
                {{ lang.page }}:
                {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
              </template>
            </PvPaginator>
          </template>

          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
export default {
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.debtFree.followUp.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.debtFree.followUp.listing,
        };
      }
    });

    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
    };
  },
  data() {
    return {
      isLoading: false,
      filterStatus: [
        { name: this.lang.followupStatusAll, code: "all" },
        { name: this.lang.followupStatus1, code: "1" },
        { name: this.lang.followupStatus2, code: "2" },
        { name: this.lang.followupStatus3, code: "3" },
        { name: this.lang.followupStatus4, code: "4" },
        { name: this.lang.followupStatus5, code: "5" },
      ],
      selectedFilterStatus: null,
      filterSearch: [
        { name: this.lang.dateRange, code: "date" },
        { name: this.lang.unitAddress, code: "unitCodeAndAddressNo" },
        { name: this.lang.ownerName, code: "ownerName" },
      ],
      selectedFilterSearch: "date",
      dateStart: null,
      dateEnd: null,
      searchText: "",
      storageName: "",
      selectedPage: 1,
      rowsPerPage: 10,
      selectedDebtFreeFollowUp: null,

      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: "",
      validateWording: "",
      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ],
    };
  },
  async mounted() {
    await this.initiateData();
    // await this.setDataFilterStatus();
    await this.roleByMenuDataReady();
  },
  methods: {
    goBack() {
      this.$router.push({name: "home"});
    },
    gotoEdit(data) {
      let id = data.id;

      if(data.followupStatus != "") {
        this.clearBaseDialog();
        this.baseDialogClassTitle = "p-error";
        this.titleConfirm = this.lang.validateUnFollowupTitle;
        this.validateWording = this.lang.validateUnFollowupTitleDetail;
        this.showValidateDialog = true;
      } else {
        if (!this.isRoleSelect) {
          this.clearBaseDialog();
          this.titleConfirm = this.lang.notificationDialogHeader;
          this.validateWording = this.lang.validateRoleSelect;
          this.showValidateDialog = true;
        } else {
          this.$router.push({ name: "debt-free-follow-up-edit", params: {id: id} });
        }
      }
    },
    async initiateData() {
      let date = new Date();
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.searchText = "";
      this.storageName = `erp_search_DebtFreeFollowUpList_${this.selectedProject.code}_${this.userCode}`;
    },
    clearBaseDialog() {
      this.baseDialogClassTitle = "";
      this.titleConfirm = "";
      this.validateWording = "";
    },
    changeFilterSearch() {
      this.initiateData();
    },
    // changeFilterStatus() {
    // //   console.log(this.selectedFilterStatus);
    //   this.initiateData();
    //   this.selectedFilterSearch = "date";
    // },
    async roleByMenuDataReady() {
      if (this.isSharedRoleByMenuReady) {
        await this.setLocalSearch();
        await this.getDebtFreeFollowUpList();
      }
    },
    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      if (localSearch) {
        let search = JSON.parse(localSearch);
        if (search.entry === "entry") {
          if (search.dateStart != null && search.dateStart != "") {
            this.selectedFilterSearch = "date";
            this.dateStart = new Date(search.dateStart);
          } else {
            this.dateStart = "";
          }

          if (search.dateEnd != null && search.dateEnd != "") {
            this.selectedFilterSearch = "date";
            this.dateEnd = new Date(search.dateEnd);
          } else {
            this.dateEnd = "";
          }

          if (
            search.unitCodeAndAddressNo != null &&
            search.unitCodeAndAddressNo != ""
          ) {
            this.selectedFilterSearch = "unitCodeAndAddressNo";
            this.searchText = search.unitCodeAndAddressNo;
          } else if (search.ownerName != null && search.ownerName != "") {
            this.selectedFilterSearch = "ownerName";
            this.searchText = search.ownerName;
          } else {
            this.searchText = "";
          }
          this.rowsPerPage = search.limit;
          this.selectedPage = search.page;

          search.entry = "";
          localStorage.setItem(this.storageName, JSON.stringify(search));
        }
      }
    },
    async onPageChange(event) {
      if (event.rows != this.rowsPerPage) {
        this.selectedPage = 1;
      } else {
        this.selectedPage = event.page + 1;
      }
      this.rowsPerPage = event.rows;
      await this.getDebtFreeFollowUpList();
    },
    // async setDataFilterStatus() {
    //   this.selectedFilterStatus = this.filterStatus.find(
    //     (data) => (data.code = "all")
    //   );
    // },
    validateSearchButton() {
      if ( this.selectedFilterSearch == "date" && (!this.selectedDateStart || !this.selectedDateEnd)
      ) {
        return true;
      } else if (this.selectedFilterSearch != "date" && this.searchText.trim() == "") {
        return true;
      }
      return false;
    },
    async getDebtFreeFollowUpList() {
      if (this.validateSearchButton()) {
        this.clearBaseDialog();
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateSearchText;
        this.showValidateDialog = true;
        return;
      }

      this.isLoading = true;
      try {
        // console.log(this.payload);
        await this.$store.dispatch(
          "debtFreeFollowUp/getDebtFreeFollowUpList",
          this.payload
        );

        localStorage.setItem(this.storageName, JSON.stringify(this.payload));

      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
      }
      this.isLoading = false;
    },
  },
  computed: {
    payload() {
      let payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        projectId: this.selectedProject.id,
        dateStart:
          this.selectedFilterSearch == "date" ? this.selectedDateStart : null,
        dateEnd:
          this.selectedFilterSearch == "date" ? this.selectedDateEnd : null,
        unitCodeAndAddressNo:
          this.selectedFilterSearch == "unitCodeAndAddressNo"
            ? this.searchText
            : null,
        ownerName:
          this.selectedFilterSearch == "ownerName" ? this.searchText : null,
        // status: this.selectedFilterStatus.code == "all" ? null : this.selectedFilterStatus.name
      };
      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmCOR");
    },
    isRoleSelect() {
      if (this.roleByMenu && this.roleByMenu.select == "Y") {
        return true;
      }
      return false;
    },
    isRoleCreate() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    isSearchDate() {
      if (this.selectedFilterSearch == "date") {
        return true;
      }
      return false;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    debtFreeFollowUpList() {
      return this.$store.getters["debtFreeFollowUp/debtFreeFollowUpList"];
    },
    totalPage() {
      return this.$store.getters["debtFreeFollowUp/totalPage"];
    },
    currentPage() {
      return this.$store.getters["debtFreeFollowUp/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    showCurrentPage() {
      return this.rowsPerPage * (this.selectedPage - 1);
    },
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.roleByMenuDataReady();
    },
  },
};
</script>
