<template>
	<div class="flex justify-content-between align-items-center" style="padding-top: 20px">
		<div class="my-3">
			<PvButton
				icon="pi pi-chevron-left"
				:label="lang.back"
				@click="goBack"
				class="mr-2 p-button-secondary"
			/>
			<PvButton
				icon="pi pi-check"
				:label="lang.confirmDocument"
				@click="addWithholdingTaxToRV()"
				class="mr-2"
			/>
		</div>
		<h2 :class="textHeaderClass" class="my-0">{{ lang.title }}</h2>
	</div>

	<div class="surface-card shadow-2 p-3 border-round">
		<transition>
			<div>
				<section class="flex flex-wrap" :class="textFormClass">
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="dateStart">{{ lang.dateWhtStart }}</label>
						<PvCalendar
							id="dateStart"
							v-model="dateStart"
							class="inputfield w-full mt-1"
							showIcon
							dateFormat="dd/mm/yy"
						/>
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="dateEnd">{{ lang.dateWhtEnd }}</label>
						<PvCalendar
							id="dateEnd"
							v-model="dateEnd"
							class="inputfield w-full mt-1"
							showIcon
							dateFormat="dd/mm/yy"
						/>
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="selectedSupplier">{{ lang.customerSearch }}</label>
						<base-supplier-auto-complete
							id="selectedSupplier"
							v-model="selectedSupplier"
							class="inputfield w-full mt-1"
							@load-finish="supplierDataReady"
						/>
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="taxBook">{{ lang.columnBook }}</label>
						<base-tax-book-auto-complete
							id="taxBook"
							v-model="selectedTaxBook"
							class="inputfield w-full mt-1"
							@load-finish="taxBookDataReady"
						/>
					</div>

					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="codeStart">{{ lang.codeStart }}</label>
						<PvInputText
							id="codeStart"
							type="text"
							class="inputfield w-full mt-1"
							placeholder="6501xxxxx"
							v-model="codeStart"
						/>
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="codeEnd">{{ lang.codeEnd }}</label>
						<PvInputText
							id="codeEnd"
							type="text"
							class="inputfield w-full mt-1"
							placeholder="6501xxxxx"
							v-model="codeEnd"
						/>
					</div>
					<div class="flex col-12 pl-0 align-content-center">
						<PvButton
							icon="pi pi-search"
							:label="lang.search"
							@click="getWithholdingTaxs()"
							class="mr-2"
						/>
					</div>
				</section>
			</div>
		</transition>

		<PvDataTable
			showGridlines
			:value="withholdingTaxs"
			v-model:selection="selectedWithholdingTax"
			selectionMode="multiple"
			dataKey="id"
			responsiveLayout="scroll"
			:stripedRows="true"
			class="p-datatable-sm"
			:class="textTableClass"
			:loading="isLoading"
			:metaKeySelection="false"
		>
			<template #empty>
				<div class="w-full p-3" Style="text-align: center;">
				<label>{{ lang.tableEmpty }}</label>
				</div>
			</template>
			<PvColumn selectionMode="multiple" style="width: 3rem" :exportable="false"></PvColumn>
			<PvColumn
				field="cancelStatus"
				:header="lang.columnIsCancel"
				style="min-width: 3rem"
				headerClass="h-center"
				bodyClass="text-center"
				sortable
			></PvColumn>
			<PvColumn
				field="documentBookCode"
				:header="lang.columnBook"
				style="min-width: 3rem"
				headerClass="h-center"
				bodyClass="text-center"
				sortable
			></PvColumn>
			<PvColumn
				field="code"
				:header="lang.columnCode"
				style="min-width: 4rem"
				headerClass="h-center"
				sortable
			></PvColumn>
			<PvColumn
				field="date"
				:header="lang.columnDate"
				style="min-width: 4rem"
				headerClass="h-center"
				bodyClass="text-center"
				sortable
			>
				<template #body="slotProps">
					{{ formatDate(slotProps.data.date) }}
				</template>
			</PvColumn>
			<PvColumn
				field="dueDate"
				:header="lang.columnDueDate"
				style="min-width: 4rem"
				headerClass="h-center"
				bodyClass="text-center"
				sortable
			>
				<template #body="slotProps">
					{{ formatDate(slotProps.data.dueDate) }}
				</template>
			</PvColumn>
			<PvColumn
				field="customerName"
				:header="lang.customerSearch"
				style="min-width: 4rem"
				headerClass="h-center"
				sortable
			></PvColumn>
			<PvColumn
				field="payTotalAmount"
				:header="lang.columnPrice"
				style="min-width: 3rem"
				headerClass="h-center"
				bodyClass="text-right"
				sortable
			>
				<template #body="slotProps">
					{{ formatCurrency(slotProps.data.payTotalAmount) }}
				</template>
			</PvColumn>
			<PvColumn
				field="taxTotalAmount"
				:header="lang.columnTaxAmount"
				style="min-width: 3rem"
				headerClass="h-center"
				bodyClass="text-right"
				sortable
			>
				<template #body="slotProps">
					{{ formatCurrency(slotProps.data.taxTotalAmount) }}
				</template>
			</PvColumn>
		</PvDataTable>
	</div>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
export default {
	props: ["id", "openFrom", "voucherId", "rvhid", "supplierId"],
	emits: ["close"],
	components: {},
	setup() {
		const { lang } = useLang();
		const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
		const { formatCurrency, formatDate, formatDateToISOString } = useFormat();

		const store = useStore();
		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return { ...lang.th.base, ...lang.th.ap.withholdingTax.listing };
			} else {
				return { ...lang.en.base, ...lang.en.ap.withholdingTax.listing };
			}
		});

		const isHideBack = inject("isHideBack");

		return {
			lang: mergeLang,
			textHeaderClass,
			textFormClass,
			textTableClass,
			formatCurrency,
			formatDate,
			formatDateToISOString,

			isHideBack,
		};
	},
	data() {
		return {
			isParameterVisible: true,

			selectedSupplier: "",
			supplierSearch: "",
			isSharedSupplierReady: false,

			selectedTaxBook: "",
			taxBookSearch: "",
			isSharedTaxBookReady: false,

			selectedWithholdingTax: null,
			isLoading: false,
			dateStart: null,
			dateEnd: null,
			codeStart: "",
			codeEnd: "",
			selectedPage: 1,
			rowsPerPage: 10,
			showFormPrintModal: false,
			path: "",
			dataPrint: [],
			showEditView: false,
			beforePath: "",
			withholdingTaxId: "",
		};
	},
	async created() {
		await this.$store.dispatch("apWithholdingTax/resetWithholdingTaxsImport");
		let date = new Date();
		this.dateStart = new Date(date.getFullYear(), date.getMonth() - 2, 1);
		this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

		await this.getWithholdingTaxs();
		if (this.supplierId) {
			this.selectedSupplier = this.sharedSuppliers.find(
				(supplier) => supplier.id === this.supplierId
			);
		}
	},
	methods: {
		setLoading(bool) {
			this.$store.dispatch("setLoading", { value: bool });
		},
		goBack() {
			this.$emit("close");
		},
		async onPageChange(event) {
			this.selectedPage = event.page + 1;
			this.rowsPerPage = event.rows;
			await this.getWithholdingTaxs();
		},
		async getWithholdingTaxs() {
			this.isLoading = true;
			let customerId = this.supplierId ?? null;
			let documentBookId = null;
			if (this.selectedSupplier) {
				customerId = this.selectedSupplier.id;
			}
			if (this.selectedTaxBook) {
				documentBookId = this.selectedTaxBook.id;
			}

			let where = {
				projectId: this.selectedProject.id,
				dateStart: this.selectedDateStart,
				dateEnd: this.selectedDateEnd,
				customerId: customerId,
				codeStart: this.codeStart || null,
				codeEnd: this.codeEnd || null,
				documentBookId: documentBookId,
			};

			const payload = {
				page: this.selectedPage,
				limit: this.rowsPerPage,
				where,
			};
			// console.log(payload);

			await this.$store.dispatch("apWithholdingTax/getAllWithholdingTaxsImport", payload);

			this.isLoading = false;
		},
		supplierDataReady() {
			this.isSharedSupplierReady = true;
		},
		taxBookDataReady() {
			this.isSharedTaxBookReady = true;
		},
		async addWithholdingTaxToRV() {
			await this.$store.dispatch(
				"apWithholdingTax/setWithholdingTaxsImport",
				this.selectedWithholdingTax
			);
			this.$emit("close");
		},
	},
	computed: {
		parameterButtonText() {
			return this.isParameterVisible ? this.lang.hideParameters : this.lang.showParameters;
		},
		halfViewportHeight() {
			return this.$store.getters.halfViewportHeight;
		},
		selectedProject() {
			return this.$store.getters.selectedProject;
		},
		totalPage() {
			return this.$store.getters["apWithholdingTax/totalPageImport"];
		},
		currentPage() {
			return this.$store.getters["apWithholdingTax/currentPageImport"];
		},
		totalRecords() {
			return this.totalPage * this.rowsPerPage;
		},
		selectedDateStart() {
			let dateStart = null;
			if (this.dateStart) {
				dateStart = this.formatDateToISOString(this.dateStart);
			}
			return dateStart;
		},
		selectedDateEnd() {
			let dateEnd = null;
			if (this.dateEnd) {
				dateEnd = this.formatDateToISOString(this.dateEnd);
			}
			return dateEnd;
		},
		withholdingTaxs() {
			return this.$store.getters["apWithholdingTax/withholdingTaxsForImport"];
		},
		sharedTaxBooks() {
			return this.$store.getters["shared/taxBookTaxBooks"];
		},
		sharedSuppliers() {
			return this.$store.getters["shared/supplierSuppliers"];
		},
	},
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
	transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
	transform: translateY(-20px);
	opacity: 0;
}
</style>
