export default {
  async getResidents(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Customer";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get resident");
      throw error;
    }
    // console.log('responseData',responseData);

    // const data = responseData.data;

    const resident = {
      residents: responseData.data,
      totalPage: responseData.page.pageCount,
      currentPage: responseData.page.currentPage,
    };
    context.commit("setResident", resident);
  },
  async getAllResidents(context) {
    while (!context.getters.residentIsLastPage) {
      let where = {
        projectId: context.rootGetters.selectedProject.id,
      };
      const payload = {
        page: context.getters.residentNextPage || 1,
        where,
      };
      await context.dispatch("getResidents", payload);
    }
    
  },
  resetResident(context) {
    context.commit("resetResident");
  },
  async getResident(context, id) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Customer/" + id;
		url = encodeURI(url);

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get policy");
			throw error;
		}

    // console.log("responseData.data=",responseData.data);
    context.commit("setSelectedResident", responseData.data);
    
  },
  async addUpdateResident(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Customer/${payload.type}`;
		const fallbackErrorMessage = `Failed to ${payload.type}`;
		const token = context.rootGetters.token;     
    //  console.log("payload=",payload);
    
		const response = await fetch(url, {
			method: payload.type === "Add" ? "post" : "put",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},

			body: JSON.stringify(payload),
		});

    //  console.log("payload=",JSON.stringify(payload));
    const responseData = await response.json();
    
		if (!response.ok) {
			let message = null;
			if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
				message = responseData.title;
				for (const key in responseData.errors) {
					message += ", " + key.replace("$.", "");
				}
			} else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
        message = responseData.message;
			}
			let error = new Error(message || fallbackErrorMessage);
      if(responseData.code=="101") error = responseData.code;
			throw error;
		}   
    await context.commit('setSelectedResident', responseData.data);      
  },
  async resetResidentSelected(context) {
    context.commit("resetResidentSelected");
  },
  async getUnitCountById(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Customer/UnitCount/";

    if (payload) {
      url = url + encodeURI(payload);
    }
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get unit count by customer id");
      throw error;
    }
    // console.log(responseData.data);

    // const data = responseData.data;
    if (responseData.data) {
      context.commit("setUnitCountById", responseData.data);
    }
  },
  async deleteResident(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Customer/Delete";
		const token = context.rootGetters.token;
		const response = await fetch(url, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});
		const responseData = await response.json();
		if (!response.ok) {
			// console.log(responseData);
			throw responseData;
		}
		// context.commit("setArProducts", responseData);
	},

};
