export default {
  async getPurchaseOrders(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseOrder";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    // console.log("getPurchaseOrders_payload=",payload);
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get PurchaseOrder");
      throw error;
    }
    //  console.log("getPurchaseOrders=",responseData);
    context.commit("setPurchaseOrders", responseData);
  },
  async getPurchaseOrder(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseOrder?Page=1&Limit=10";

    if(payload.where){
        url = url + '&where=' + JSON.stringify(payload.where);
    }
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {      
      const error = new Error(responseData.message || "Failed to get PurchaseOrder.");
      throw error;
    }
      // console.log("setSelectedPurchaseOrder=",responseData);
    if(responseData.data.length > 0){
      context.commit('setSelectedPurchaseOrder', responseData.data[0]);
    }  
  },
  resetSelectedPurchaseOrder(context) {
    // console.log("resetAPBook");
    context.commit("setSelectedPurchaseOrder",null);
  },
  async addUpdatePurchaseOrder(context, payload) {

    let url = process.env.VUE_APP_BACKEND_URL + `/PurchaseOrder/${payload.type}`;
		const fallbackErrorMessage = `Failed to ${payload.type} purchase order`;
		const token = context.rootGetters.token;     
    //  console.log("payload=",payload);
    
		const response = await fetch(url, {
			method: payload.type === "Add" ? "post" : "put",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},

			body: JSON.stringify(payload),
		});

    //  console.log("payload=",JSON.stringify(payload));
    const responseData = await response.json();
    
		if (!response.ok) {
			// console.log(responseData);
			let message = null;
			if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
				message = responseData.title;
				for (const key in responseData.errors) {
					message += ", " + key.replace("$.", "");
				}
			} else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
				message = responseData.message;
			}
			const error = new Error(message || fallbackErrorMessage);
			throw error;
		}   
    await context.commit('setSelectedPurchaseOrder', responseData.data);      
  },
  async getPurchaseRequisitiontoPOs(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseRequisition";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + encodeURI(JSON.stringify(payload.where));
    }

    // console.log("getPurchaseRequisitions_payload=",payload);
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get Purchase Requisitions");
      throw error;
    }
    // console.log("setPurchaseRequisitions=",responseData);
    context.commit("setPurchaseRequisitions", responseData);
  }, 
  async cancelPurchaseOrder(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/PurchaseOrder/Cancel`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to cancel Purchase Order');
      throw error;
    }
    
    context.commit("setSelectedPurchaseOrder", responseData.data);
        
  },
  async approvePurchaseOrder(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/PurchaseOrder/Approve`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to approve Purchase Order');
      throw error;
    }
    
    context.commit("setSelectedPurchaseOrder", responseData.data);
        
  },
  async closePurchaseOrder(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/PurchaseOrder/Close`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to close Purchase Order');
      throw error;
    }
    
    context.commit("setSelectedPurchaseOrder", responseData.data);
        
  },
  async printPurchaseOrder(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/PurchaseOrder/Print`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(payload),
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      
    });  
    
    let urlBlob = ''
    let blob = await response.blob();
    urlBlob = window.URL.createObjectURL(blob);
    context.commit('setBlob', urlBlob);
  },
  async revertApprovePurchaseOrder(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/PurchaseOrder/RevertApprove`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to revert approve purchase order');
      throw error;
    }

    context.commit("setSelectedPurchaseOrder",responseData.data);
  },
};
