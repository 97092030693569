export default{    
	titlePenaltyCalculation: "PenaltyCalculation",
	titleFineRateCalculation:"Fine rate and calculation",
	titleFineRate: "Fine Rate",
	penaltyRateBefore181Day: "Penalty rate Before or equal 180 day",
	penaltyRateAfter180Day: "Penalty rate after 180 day",
	penaltyDigit: "number of penalty decimal digit on calculation",
	roundType: "Round type",
	penaltyType: "Penalty calculation type",
	productMethodCode: "Product",
	penaltyMethodCode: "Penalty",

	tooltipRoundType:"แบบมาตรฐาน:(มากกว่าหรือเท่ากับ 5 ปัดขึ้น)",
	tooltipPenaltyType:"แบบปกติ:วันที่สิ้นสุดจะคำนวณเป็นวันเดียวกับวันแจ้งหนี้ *ยกเว้นวันที่ 1 ระบบจะคำนวณถึงวันที่เดือนที่ผ่านมา",
	notPenalty: "กรณีไม่ระบุอัตราคาปรับ จะใช้การคำนวณตามหลักคำนวณค่าปรับ",

    validatePenaltyRateBefore181Day: "Enter Penalty rate Before or equal 180 day",
    validatePenaltyRateAfter180Day: "Enter Penalty rate after 180 day",
    validatePenaltyDigit: "Select number of penalty decimal digit on calculation",
    validateRoundType: "Select Round type",
    validatePenaltyType: "Select Penalty calculation type",
    validateProductMethodCode: "Select Product",
    validatePenaltyMethodCode: "Select Penalty",

	columnCode: "Code",
	columnName: "Name",
	columnPaidPriority: "PaidPriority",
	columnPrice: "Price",
	columnPenalty: "Penalty",
	columnEdit: "Edit",
	columndateStart: "DateStart",
	columnpenaltyRate: "PenaltyRate %",
	addProduct: "AddProduct",

	
	titlePenaltyProduct: "PenaltyProduct",
	productAr: "ProductAr",
	addPenaltyRateStep: "AddPenaltyRateStep",
	productCode: "ProductCode",
	penaltyCode: "PenaltyCode",
    validateDataStartFirst: "DataStartFirst Enter 1 always",
    validateDataStartOver: "Enter DataStart want a lot DataStart Before",
    validateDataStartLow: "Enter DataStart want less DataStart next",
    validateValueDataStart: "DataStart not less than ",
    validateValuePenaltyRate: "PenaltyRate not less than 0.",
	penaltyPrintInvoice: "Show Penalty Print Invoice",
	
	titleInvoiceDisplayOutstanding: "Display Information in Invoice",
	labelInvoiceDisplayOutstanding: "Display Outstanding",
};