<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoSave="gotoSave()"
    @gotoAskForCreate="gotoAskForCreate()"
    @gotoAskForCancel="gotoAskForCancel()"
    @gotoPrint="selectPrintForm()"
  >
  </base-toolbar>
  <base-container>
    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchText"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>

    <import-receipt-to-payin-dialog
      v-model:visible="showImportReceipt"
      :openFrom="openImport"
      :startDate="startDate"
      :endDate="endDate"
      :cashType="selectedCashType"
      @closeImport="showImportReceipt = false"
      @importReceiptToPayin="importReceiptToPayin"
    ></import-receipt-to-payin-dialog>

    <base-dialog
      v-model:visible="showConfirmDialog"
      :titleConfirm="confirmTitle"
      :classTitle="confirmClassTitle"
      :wordingConfirm="confirmWording"
      :dataButtons="dataButtonDialog"
      @close="showConfirmDialog = false"
      @confirm="showConfirmDialog = false"
      @gotoCancel="gotoCancel()"
      @gotoCreate="gotoCreate()"
    ></base-dialog>

    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5" :class="textFormClass">
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.detailTitle }}</label>
      </div>
      <PvDivider></PvDivider>
      <section class="flex flex-wrap mb-3">
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="code">{{ lang.documentCode }}</label>
          <PvInputText
            id="code"
            type="text"
            class="inputfield w-full mt-1 input-disable"
            v-model="code"
            readonly
          />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="payDate">{{ lang.payDate }}</label>
          <PvCalendar
            id="payDate"
            v-model="payDate"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="dueDate">{{ lang.dueDate }}</label>
          <PvCalendar
            id="dueDate"
            v-model="dueDate"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="selectedCustomer">
            {{ lang.customerName }}
          </label>
          <PvSkeleton
            v-show="!isSharedCustomerReady"
            class="inputfield w-full mt-1"
            height="3rem"
          />
          <base-customer-auto-complete
            v-show="isSharedCustomerReady"
            id="selectedCustomer"
            v-model="selectedCustomer"
            class="inputfield w-full mt-1"
            @load-finish="customerDataReady"
            :class="{ 'p-invalid': customerError }"
            @item-select="changeSelectedCustomer()"
          />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="selectedPayType">{{ lang.payType }}*</label>
          <PvSkeleton
            v-show="!isSharedPayTypeReady"
            class="inputfield w-full mt-1"
            height="3rem"
          />
          <base-payment-method-auto-complete
            v-show="isSharedPayTypeReady"
            id="selectedPayType"
            v-model="selectedPayType"
            class="inputfield w-full mt-1"
            @load-finish="paymentTypeDataReady"
            :typeInOut="payType"
            :class="{ 'p-invalid': payTypeError }"
            @item-select="payTypeError = false"
          />
          <small v-if="payTypeError" id="payType-help" class="p-error">
            {{ lang.validatePayType }}
          </small>
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="employeeName">{{ lang.employeeName }}</label>
          <PvInputText
            id="employeeName"
            type="text"
            class="inputfield w-full mt-1 input-disable"
            readonly
            v-model.trim="employeeName"
          />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="selectedBookbank">{{ lang.bankPayin }}*</label>
          <PvSkeleton
            v-show="!isSharedBookBankReady"
            class="inputfield w-full mt-1"
            height="3rem"
          />
          <base-book-bank-auto-complete
            v-show="isSharedBookBankReady"
            id="selectedBookbank"
            v-model="selectedBookbank"
            class="inputfield w-full mt-1"
            @load-finish="bookBankDataReady"
            :class="{ 'p-invalid': bookbankError }"
            @item-select="changeSelectedBookbank()"
          />
          <small v-if="bookbankError" id="bookbank-help" class="p-error">
            {{ lang.validateBookbank }}
          </small>
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="selectedStatus">{{ lang.status }}</label>
          <PvDropdown
            id="selectedStatus"
            class="inputfield w-full mt-1"
            :options="statusOptionsPayin"
            optionLabel="name"
            optionValue="code"
            v-model="selectedStatus"
          />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="toBankDate">{{ lang.toBankDatePayin }}</label>
          <PvCalendar
            id="toBankDate"
            v-model="toBankDate"
            class="inputfield w-full mt-1"
            :class="selectedStatus === 'N' ? 'input-disable' : ''"
            showIcon
            dateFormat="dd/mm/yy"
            :disabled="selectedStatus === 'N'"
          />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="Remark">{{ lang.remark }}</label>
          <PvInputText
            id="Remark"
            type="text"
            class="inputfield w-full mt-1"
            v-model="remark"
          />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="netAmount">{{ lang.netAmount }}</label>
          <PvInputNumber
            id="netAmount"
            class="inputfield w-full mt-1"
            v-model="totalAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            :min="0"
            @input="totalAmount = $event.value"
            @focus="$event.target.select()"
          />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="feeAmount">{{ lang.feeAmount }}</label>
          <PvInputNumber
            id="feeAmount"
            class="inputfield w-full mt-1"
            v-model="feeAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            :min="0"
            @input="feeAmount = $event.value"
            @focus="$event.target.select()"
          />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="usedAmount">{{ lang.usedAmount }}</label>
          <PvInputNumber
            id="usedAmount"
            class="inputfield w-full mt-1"
            inputClass="input-number-disable"
            v-model="usedAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            disabled
          />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="balance">{{ lang.balance }}</label>
          <PvInputNumber
            id="balance"
            class="inputfield w-full mt-1"
            inputClass="input-number-disable"
            v-model="balance"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            disabled
          />
        </div>
      </section>
      <label class="font-lg font-normal">{{ lang.referenceReceipt }}</label>
      <div class="flex flex-wrap mb-3">
        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
          <label for="startDate">{{ lang.startDate }}</label>
          <PvCalendar
            id="startDate"
            v-model="startDate"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>
        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
          <label for="endDate">{{ lang.endDate }}</label>
          <PvCalendar
            id="endDate"
            v-model="endDate"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>
        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
          <label for="selectedCashType">{{ lang.cashType }}</label>
          <PvDropdown
            id="selectedCashType"
            class="inputfield w-full mt-1"
            :options="cashTypeOptions"
            optionLabel="name"
            optionValue="code"
            v-model="selectedCashType"
          />
        </div>
        <div class="pl-0 pr-2 lg:col-3 md:col-6 col-12">
          <PvButton
            icon="pi pi-search"
            :label="lang.searchReceiptLabel"
            class="mr-2 mt-4"
            @click="openReceiptImportDialog()"
          />
        </div>
      </div>
      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="detailPayin"
          dataKey="id"
          :class="textTableClass"
          :loading="isLoading"
          selectionMode="multiple"
          :metaKeySelection="false"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :scrollable="true"
        >
          <template #empty>
            <div
              style="height: 56px"
              class="text-center flex align-items-center justify-content-center w-full"
            >
              <label class="font-normal">
                {{ lang.tableEmpty }}
              </label>
            </div>
          </template>

          <PvColumn
            field="itemNo"
            :header="lang.itemNo"
            style="min-width: 5rem; height: 56px"
            headerClass="h-center font-normal"
            bodyClass="h-center font-normal"
            sortable
          />

          <PvColumn
            field="code"
            :header="lang.columnCodeReceipt"
            style="min-width: 15rem; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal"
            sortable
          />

          <PvColumn
            field="date"
            :header="lang.columnReceiptDate"
            style="min-width: 10rem"
            headerClass="h-center font-normal"
            bodyClass="text-center font-normal"
            sortable
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.date) }}
            </template>
          </PvColumn>

          <PvColumn
            field="payDate"
            :header="lang.columnDate"
            style="min-width: 10rem"
            headerClass="font-normal"
            bodyClass="font-normal"
            sortable
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.payDate) }}
            </template>
          </PvColumn>

          <PvColumn
            field="unitCode"
            :header="lang.columnUnitCode"
            style="min-width: 5rem"
            headerClass="font-normal"
            bodyClass="font-normal"
          ></PvColumn>

          <PvColumn
            field="refCode"
            :header="lang.columnInvoice"
            style="min-width: 10rem"
            headerClass="font-normal"
            bodyClass="font-normal"
          ></PvColumn>

          <PvColumn
            field="customerName"
            :header="lang.columnCustomerName"
            style="min-width: 10rem"
            headerClass="font-normal"
            bodyClass="font-normal"
          ></PvColumn>

          <PvColumn
            field="totalAmount"
            :header="lang.columnTotal"
            style="min-width: 5rem"
            headerClass="h-right font-normal"
            bodyClass="text-right font-normal"
          >
            <template #body="slotProps">
              <span class="text-right w-full">
                {{ formatCurrency(slotProps.data.totalAmount) }}
              </span>
            </template>
          </PvColumn>
          <PvColumn style="min-width: 5rem">
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-danger p-button-text py-0"
                @click="deleteReceipt(slotProps.data.id)"
              >
                <icon-trash-delete-bin iconColor="error" />
              </PvButton>
            </template>
          </PvColumn>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import usePageDirty from "../../../hooks/pageDirty";
import basePaymentMethodAutoComplete from "../../../components/bank/BasePaymentMethodAutoComplete.vue";
import baseBookBankAutoComplete from "../../../components/bank/BaseBookBankAutoComplete.vue";
import importReceiptToPayinDialog from "../../../components/AR/bank/ImportReceiptToPayinDialog.vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import useCalculation from "../../../hooks/calculation.js";

export default {
  props: ["id", "projectCode"],
  components: {
    basePaymentMethodAutoComplete,
    baseBookBankAutoComplete,
    importReceiptToPayinDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.ar.bank.edit, ...lang.th.ar.bank.import };
      } else {
        return { ...lang.en.base, ...lang.en.ar.bank.edit, ...lang.th.ar.bank.import };
      }
    });

    const { compareObject, isCompareObject } = usePageDirty();
    const { round } = useCalculation();

    const {
      getFormPrints,
      searchText,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints,
    } = useGetFormPrint();

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getFormPrints,
      searchText,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      compareObject,
      isCompareObject,
      round,
    };
  },
  data() {
    return {
      payType: 1,
      confirmTitle: "",
      confirmWording: "",
      confirmClassTitle: "",
      showConfirmDialog: false,
      dataPayin: [],
      detailPayin: [],
      code: null,
      payDate: null,
      dueDate: null,
      selectedCustomer: "",
      customerName: "",
      customerCode: "",
      customerError: false,
      isSharedCustomerReady: false,
      selectedPayType: "",
      payTypeError: false,
      isSharedPayTypeReady: false,
      employeeName: null,
      selectedBookbank: "",
      bookbankError: false,
      isSharedBookBankReady: false,
      statusOptionsPayin: [
        { code: "N", name: "N : ในมือ" },
        { code: "B", name: "B : เข้าธนาคาร" },
        { code: "R", name: "R : คืน" },
      ],
      selectedStatus: "N",
      toBankDate: null,
      remark: null,
      totalAmount: null,
      selectedCross: "",
      selectedHolder: "",
      showFormPrintModal: false,
      path: "",
      // showReferenceReceipt: false,
      openFrom: 0,
      dataButtonDialog: [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ],
      //receipts reference
      cashTypeOptions: [
        { code: "Cash", name: this.lang.cash },
        { code: "CreditCard", name: this.lang.creditCard },
        { code: "Transfer", name: this.lang.transfer },
        { code: "Cheque", name: this.lang.cheque },
        { code: "E_Pay", name: this.lang.epay },
      ],
      selectedCashType: "",
      startDate: new Date(),
      endDate: new Date(),
      isLoading: false,
      openImport: 0,
      showImportReceipt: false,
      feeAmount: 0,
    };
  },
  async created() {
    await this.$store.dispatch("bank/setTypeInOut", { type: this.payType });
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    if (!this.isCreateDocument) {
      await this.getPayin();
    }
    await this.checkShouldSetData();
  },
  methods: {
    async goBack() {
      this.compareObject(this.objToCompare, this.payloadPayin);
      this.setLocalSearch();
      this.$router.push({ name: "ar-bank-ar" });
    },
    async gotoCreate() {
      this.setLoading(true);
      this.showConfirmDialog = false;
      await this.initiateData();
      this.$router.replace({ name: "ar-bank-ar-edit", params: {id: "create"} });
      if (this.isSharedDataReady) {
        this.setLoading(false);
      }
      await this.setCompareData();
    },
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    async getPayin() {
      try {
        this.isLoading = true;
        const where = {
          id: this.id,
        };
        await this.$store.dispatch("arBank/getPayinById", where);

        this.dataPayin = this.selectedPayin;
        this.detailPayin = this.selectedPayin.referenceReceiptDetails;
        await this.updateItemNo();

        this.setLoading(false);
        this.isLoading = false;
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.setLoading(false);
        this.isLoading = false;
      }
    },
    async setCompareData() {
      let objToCompare = {
        projectId: this.selectedProject.id ?? null,
        paymentMethodCode: this.selectedPayType ? this.selectedPayType.code : null,
        paymentMethodId: this.selectedPayType ? this.selectedPayType.id : null,
        bookBankId: this.selectedBookbank ? this.selectedBookbank.id : null,
        bankId: this.selectedBookbank ? this.selectedBookbank.bankId : null,
        docBankBranchName: this.selectedBookbank
          ? this.selectedBookbank.branchName
          : null,
        customerId: this.selectedCustomer ? this.selectedCustomer.id : null,
        customerCode: this.selectedCustomer ? this.selectedCustomer.code : null,
        payAmount: this.totalAmount ? this.totalAmount : 0,
        payDate: this.selectedPayDate,
        payDueDate: this.selectedDueDate,
        toBankDate: this.selectedToBankDate,
        passDate: this.selectedToBankDate,
        returnDate: this.selectedToBankDate,
        depositor: this.receiveMan,
        remark: this.remark,
        status: this.selectedStatus,
        feeAmount: this.feeAmount ? this.feeAmount : 0,
        isCross: "N",
        isHolder: "N",
        chequeReturnNote: "",
        referenceReceiptDetails: this.detailPayin,
        type: "Add",
      };
      if (!this.isCreateDocument) {
        objToCompare = {
          ...objToCompare,
          id: this.id,
          type: "Update",
        };
      }
      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payloadPayin);
    },
    async initiateData() {
      this.code = null;
      this.payDate = new Date();
      this.dueDate = new Date();
      this.selectedCustomer = "";
      this.selectedPayType = "";
      this.employeeName = this.selectedEmployee.name;
      this.selectedBookbank = "";
      this.selectedStatus = "N";
      this.toBankDate = new Date();
      this.remark = null;
      this.totalAmount = 0;
      this.feeAmount = 0;
      this.dataPayin = [];
      this.detailPayin = [];
      await this.$store.dispatch("arBank/resetArBank");

      //receipts reference
      this.updateItemNo();
      this.startDate = new Date();
      this.endDate = new Date();
      this.selectedCashType = "Cash";
      this.customerError = false;
      this.payTypeError = false;
      this.bookbankError = false;
    },
    async setData() {
      if (this.selectedPayin && this.isSharedRoleByMenuReady) {
        this.code = this.dataPayin.code;
        this.payDate = new Date(this.dataPayin.payDate);
        this.dueDate = new Date(this.dataPayin.payDueDate);
        this.employeeName = this.dataPayin.createBy;
        this.toBankDate = new Date(this.dataPayin.toBankDate);
        this.remark = this.dataPayin.remark;
        this.totalAmount = this.dataPayin.payAmount;
        this.feeAmount = this.dataPayin.feeAmount ? this.dataPayin.feeAmount : 0;

        this.selectedStatus = this.dataPayin.status;

        if (this.isSharedPayTypeReady) {
          this.selectedPayType = this.sharedPaymentType.find(
            (paymentType) => paymentType.id === this.dataPayin.paymentMethodId
          );
        }

        if (this.isSharedBookBankReady) {
          this.selectedBookbank = this.sharedBookBanks.find(
            (bookbank) => bookbank.id === this.dataPayin.bookBankId
          );
        }
        if (this.isSharedCustomerReady) {
          this.selectedCustomer = this.sharedCustomers.find(
            (customer) => customer.id === this.dataPayin.customerId
          );
        }
        if (
          this.isSharedPayTypeReady &&
          this.isSharedBookBankReady &&
          this.isSharedCustomerReady &&
          this.selectedPayType &&
          this.selectedBookbank &&
          this.selectedCustomer
        ) {
          await this.setCompareData();
        }
        this.setLoading(false);
      }
    },
    setLocalSearch() {
      let storageName = `erp_search_BankArList_${this.selectedProject.code}_${this.userCode}`;
      const localSearch = localStorage.getItem(storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      localStorage.setItem(storageName, JSON.stringify(search));
    },
    async checkShouldSetData() {
      if (this.selectedPayin && this.isSharedRoleByMenuReady) {
        await this.setData();
      } 
      else if (this.isSharedDataReady && this.isCreateDocument) {
        this.setLoading(false);
      }
      await this.setCompareData();
    },
    openAlertDialog(dataConfig) {
      if (dataConfig.severity === "") {
        this.confirmClassTitle = "";
      } else if (dataConfig.severity === "success") {
        this.confirmClassTitle = "p-text-success";
      } else if (dataConfig.severity === "error") {
        this.confirmClassTitle = "p-error";
      }
      this.confirmTitle = dataConfig.title;
      this.confirmWording = dataConfig.content;
      this.showConfirmDialog = true;
    },
    changeSelectedCustomer() {
      this.customerError = false;
      this.customerName = this.selectedCustomer.name;
      this.customerCode = this.selectedCustomer.code;
    },
    changeSelectedBookbank() {
      this.bookbankError = false;
    },
    paymentTypeDataReady() {
      this.isSharedPayTypeReady = true;
      this.checkShouldSetData();
    },
    customerDataReady() {
      this.isSharedCustomerReady = true;
      this.checkShouldSetData();
    },
    bookBankDataReady() {
      this.isSharedBookBankReady = true;
      this.checkShouldSetData();
    },
    async gotoSave() {
      if (this.validateData()) {
        return;
      }
      this.setLoading(true);
      try {
        if (!this.selectedCustomer) {
          this.selectedCustomer = this.sharedCustomers.find(
            (customer) => customer.code === "รอรับรู้"
          );
          if (!this.selectedCustomer) {
            this.setLoading(false);
            this.openAlertDialog({
              severity: "error",
              title: this.lang.validateSaveTitle,
              content: this.lang.validateFieldNoValue+ this.lang.customerName,
              isConfirm: false,
            });
            return;
          }
          this.customerName = this.selectedCustomer.name;
          this.customerCode = this.selectedCustomer.code;
        }

        await this.$store.dispatch("arBank/createOrUpdatePayin", this.payloadPayin);

        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.confirm,
            class: "w-full",
            action: "confirm",
          },
        ];

        this.openAlertDialog({
          severity: "success",
          title: this.lang.saveSuccess,
          content: this.lang.successfully,
          isConfirm: false,
        });

        this.dataPayin = this.selectedPayin;
        this.detailPayin = this.selectedPayin.referenceReceiptDetails;
        this.setData();

        if (this.isCreateDocument) {
          await this.$router.replace({ name: "ar-bank-ar-edit", params: {id: this.selectedPayin.id} });
          await this.setCompareData();
        }
        this.setLoading(false);
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.setLoading(false);
      }
    },
    async gotoAskForCreate() {
      if (!this.isRoleInsert) {
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateRoleInsert,
          isConfirm: false,
        });
        return;
      }
      this.dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
        {
          id: "gotoCreate",
          caption: this.lang.buttonDialogConfirm,
          class: "w-full",
          action: "gotoCreate",
        },
      ];
      let result = this.isCompareObject(this.objToCompare, this.payloadPayin);
      if (result) {
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.askForCreateNewDocument,
          isConfirm: false,
        });
      } else {
        this.gotoCreate();
      }
    },
    gotoAskForCancel() {
      if (!this.isRoleCancel) {
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateRoleCancel,
          isConfirm: false,
        });
        return true;
      }

      if (this.validateCancelled()) {
        return;
      }

      this.dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
        {
          id: "gotoCancel",
          caption: this.lang.buttonDialogConfirm,
          class: "w-full",
          action: "gotoCancel",
        },
      ];
      this.openAlertDialog({
        severity: "",
        title: this.lang.notificationDialogHeader,
        content: this.lang.askForCancelDocument,
        isConfirm: false,
      });
    },
    async gotoCancel() {
      this.showConfirmDialog = false;
      if (this.detailPayin.length > 0) {
        this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
        ];
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateReference,
          isConfirm: false,
        });
        return;
      }
      this.setLoading(true);
      try {
        await this.$store.dispatch("arBank/cancelPayin", { id: this.id });
        this.setLoading(false);

        this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
        ];

        this.openAlertDialog({
          severity: "success",
          title: this.lang.cancelSuccess,
          content: this.lang.cancelPayinSuccess.replace("{code}", this.code),
          isConfirm: false,
        });
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.setLoading(false);
      }
    },
    async selectPrintForm() {
      if (!this.isRolePrint) {
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validatePrintPermission,
          isConfirm: false,
        });
        return;
      }
      try {
        await this.$store.dispatch("shared/resetFormPrint");
        let where = {
          projectId: this.selectedProject.id,
          documentBookCode: "PayIn",
        };
        const payload = { where };
        await this.getFormPrints(payload);

        if (this.filteredFormPrints.length == 1) {
          this.path = this.filteredFormPrints[0].path;
          this.printPayin();
        } else {
          this.showFormPrintModal = true;
        }
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
      }
    },
    async printPayin() {
      try {
        const payin = {
          id: this.id,
          path: this.path,
        };
        await this.$store.dispatch("arBank/printPayin", payin);
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
      }
      let blob = this.$store.getters["arBank/blob"];
      this.getPreviewBlob(blob);
    },
    formPrintModalSelected(event) {
      this.path = event.path;
      this.printPayin();
      this.showFormPrintModal = false;
    },
    //receipt

    async updateReferenceReceipt() {
      this.showReferenceReceipt = false;
      // let totalAmount = 0;
      // this.detailPayin.forEach((data) => {
      //   totalAmount += data.netAmount;
      // });
      // this.totalAmount = totalAmount;
    },
    async updateItemNo() {
      let number = 1;
      this.detailPayin.forEach((element) => {
        element.itemNo = number;
        number++;
      });
    },
    openReceiptImportDialog() {
      const today = new Date();
      this.openImport = today.getSeconds();
      this.showImportReceipt = true;
    },
    async importReceiptToPayin(data) {
      this.detailPayin = data;
      await this.updateItemNo();
      await this.updateReferenceReceipt();
      this.showImportReceipt = false;
    },
    async deleteReceipt(id) {
      let i = this.detailPayin.map((item) => item.id).indexOf(id);
      this.detailPayin.splice(i, 1);
      await this.updateReferenceReceipt();
    },
    deleteReceiptAll() {
      this.detailPayin = [];
    },
    validateCancelled() {
      if (this.selectedPayin && this.selectedPayin.cancelStatus.trim().toLowerCase() === "c") {
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateCancelled,
          isConfirm: false,
        });
        return true;
      }
      return false;
    },
    validateRoleSave() {
      if (this.isCreateDocument && !this.isRoleInsert) {
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateRoleInsert,
          isConfirm: false,
        });
        return true;
      }
      
      if (!this.isCreateDocument && !this.isRoleUpdate) {
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateRoleUpdate,
          isConfirm: false,
        });
        return true;
      }
      return false;
    },
    validateData() {
      this.dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        }
      ];
      if (this.validateRoleSave()) {
        return true;
      }
      
      if (this.validateCancelled()) {
        return true;
      }

      let returnStatus = false;

      if (!this.selectedPayType) {
        this.payTypeError = true;
        returnStatus = true;
      }

      if (!this.selectedBookbank) {
        this.bookbankError = true;
        returnStatus = true;
      }

      if (this.totalAmount < 0) {
        returnStatus = true;
      }

      if (returnStatus) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.validateSaveTitle,
          content: this.wordingValidate(),
          isConfirm: false,
        });
      }

      return returnStatus;
    },
    wordingValidate(){
      if (!this.selectedPayType) {
        return this.lang.validateDropDownNoSelect + this.lang.payType;
      }

      if (!this.selectedBookbank) {
        return this.lang.validateDropDownNoSelect + this.lang.bankPayin;
      }

      if (this.totalAmount < 0) {
        return this.lang.validateTotalAmount;
      }
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    selectedEmployee() {
      return this.$store.getters.employee;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    isCreateDocument() {
      return this.id === "create";
    },
    title() {
      let result = this.lang.editTitle;
      if (this.isCreateDocument) {
        result = this.lang.createTitle;
      }
      return result;
    },
    selectedPayin() {
      return this.$store.getters["arBank/selectedPayin"];
    },
    isLockedSave() {
      if (this.isRoleInsert && this.validateData && this.isCreateDocument) {
        return false;
      } else if (
        this.isRoleUpdate &&
        this.validateData &&
        !this.isCreateDocument &&
        this.selectedPayin &&
        this.selectedPayin.cancelStatus.trim() !== "C"
      ) {
        return false;
      }

      return true;
    },
    isLockedImport() {
      if (this.selectedPayin && this.selectedPayin.cancelStatus.trim() !== "C") {
        if (this.isRoleInsert && this.isCreateDocument) {
          return false;
        } else if (this.isRoleUpdate && !this.isCreateDocument) {
          return false;
        }
      }
      return true;
    },
    dataButtons() {
      return [
        {
          id: "gotoAskForCancel",
          action: "gotoAskForCancel",
          isDisable: this.isCreateDocument || !this.isSharedDataReady,
          type: "cancel",
        },
        {
          id: "gotoAskForCreate",
          action: "gotoAskForCreate",
          type: "create",
        },
        {
          id: "printCaption",
          action: "gotoPrint",
          isDisable: this.isCreateDocument || !this.isSharedDataReady,
          type: "print",
        },
        {
          id: "saveCaption",
          action: "gotoSave",
          isDisable: !this.isSharedDataReady,
          type: "save",
        },
      ];
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("PayIn");
    },
    isRoleSelect() {
      if (this.roleByMenu && this.roleByMenu.select == "Y") {
        return true;
      }
      return false;
    },
    isRoleInsert() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    isRoleUpdate() {
      if (this.roleByMenu && this.roleByMenu.update == "Y") {
        return true;
      }
      return false;
    },
    isRolePrint() {
      if (this.roleByMenu && this.roleByMenu.print == "Y") {
        return true;
      }
      return false;
    },
    isRoleCancel() {
      if (this.roleByMenu && this.roleByMenu.cancel == "Y") {
        return true;
      }
      return false;
    },
    selectedPayDate() {
      let payDate = null;
      if (this.payDate) {
        payDate = this.formatDateToISOString(this.payDate);
      } else {
        payDate = this.formatDateToISOString(new Date());
      }
      return payDate;
    },
    selectedDueDate() {
      let dueDate = null;
      if (this.dueDate) {
        dueDate = this.formatDateToISOString(this.dueDate);
      } else {
        dueDate = this.formatDateToISOString(new Date());
      }
      return dueDate;
    },
    selectedToBankDate() {
      let toBankDate = null;
      if (this.toBankDate) {
        toBankDate = this.formatDateToISOString(this.toBankDate);
      } else {
        toBankDate = this.formatDateToISOString(new Date());
      }
      return toBankDate;
    },
    sharedCustomers() {
      return this.$store.getters["shared/customerCustomers"];
    },
    sharedBookBanks() {
      return this.$store.getters["bookBank/bookBanks"];
    },
    sharedPaymentType() {
      return this.$store.getters["bank/transactionType"];
    },
    payloadPayin() {
      let payload = {
        projectId: this.selectedProject.id ?? null,
        paymentMethodCode: this.selectedPayType ? this.selectedPayType.code : null,
        paymentMethodId: this.selectedPayType ? this.selectedPayType.id : null,
        bookBankId: this.selectedBookbank ? this.selectedBookbank.id : null,
        bankId: this.selectedBookbank ? this.selectedBookbank.bankId : null,
        docBankBranchName: this.selectedBookbank
          ? this.selectedBookbank.branchName
          : null,
        customerId: this.selectedCustomer ? this.selectedCustomer.id : null,
        customerCode: this.selectedCustomer ? this.selectedCustomer.code : null,
        payAmount: this.totalAmount ? this.totalAmount : 0,
        payDate: this.selectedPayDate,
        payDueDate: this.selectedDueDate,
        toBankDate: this.selectedToBankDate,
        passDate: this.selectedToBankDate,
        returnDate: this.selectedToBankDate,
        depositor: this.receiveMan,
        remark: this.remark,
        status: this.selectedStatus,
        feeAmount: this.feeAmount ? this.feeAmount : 0,
        isCross: "N",
        isHolder: "N",
        chequeReturnNote: "",
        referenceReceiptDetails: this.detailPayin,
        type: "Add",
      };
      if (!this.isCreateDocument) {
        payload = {
          ...payload,
          id: this.id,
          type: "Update",
        };
      }
      return payload;
    },
    isSharedDataReady() {
      if ( this.isSharedPayTypeReady && this.isSharedBookBankReady && this.isSharedCustomerReady ) {
        return true;
      }
      return false;
    },
    usedAmount() {
      let usedAmount = 0;
      if (this.detailPayin) {
        this.detailPayin.forEach((detail) => {
          usedAmount += detail.totalAmount;
        });
      }
      return this.round(usedAmount);
    },
    balance() {
      return this.round(this.totalAmount + this.feeAmount - this.usedAmount);
    },
  },
  watch: {
    selectedPayin() {
      this.checkShouldSetData();
    },
    isSharedRoleByMenuReady() {
      this.checkShouldSetData();
    },
  },
};
</script>
