<template>
  <PvDialog
    :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
    :style="{ width: widthStyle }"
    :modal="true"
    :closable="false"
    :visible="display"
  >
    <template #header>
      <div class="flex justify-content-between align-items-start">
        <div :class="`label-container pt-2 pb-1 ${classTitle}`">
          <label :class="`font-lg font-normal ${classTitle}`">{{ titleConfirm }}</label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
          v-if="!isVisibleCloseButton"
        />
      </div>
      <PvDivider class="mt-2 mb-3"></PvDivider>
    </template>
    <div class="pt-1">
      <label v-if="!Array.isArray(wordingConfirm)">{{ wordingConfirm }}</label>
      <p v-else v-for="(wording, index) in wordingConfirm" :key="index">{{ wording }}</p>
    </div>
    <div class="flex justify-content-between align-items-center p-0">
      <slot name="dialogBody"></slot>
    </div>

    <template #footer>
      <div class="flex justify-content-between align-items-center">
        <div v-for="dataButton in dataButtons" :key="dataButton.id" class="w-full m-1">
          <PvButton
            :class="dataButton.class"
            @click="$emit(dataButton.action)"
            :disabled="dataButton.isDisable"
            :id="dataButton.id"
          >
            <component
              :is="dataButton.icon"
              :key="dataButton.icon"
              :iconColor="dataButton.iconColor"
              :height="dataButton.iconHeight"
              :width="dataButton.iconWidth"
              class="mx-auto"
            />
            <span class="p-button-label font-md font-normal">{{
              dataButton.caption
            }}</span>
          </PvButton>
        </div>

        <!-- <PvButton
          class="p-button-outlined p-text-secondary ml-2"
          @click="closeDialog"
          :style="{ width: '50%' }"
        >
          <span class="p-button-label p-text-secondary font-md font-normal">{{
            lang.cancel
          }}</span>
        </PvButton>
        <PvButton @click="confirm" :style="{ width: '50%' }">
          <span class="p-button-label font-md font-normal">{{
            lang.saveCaption
          }}</span>
        </PvButton> -->
      </div>
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
export default {
  props: {
    titleConfirm: {
      type: String,
      default: "",
    },
    wordingConfirm: {
      // type: String,
      default: "",
    },
    display: {
      type: Boolean,
      default: false,
    },
    dataButtons: {
      type: Array,
      default: () => [
        {
          id: "close",
          caption: "ยกเลิก",
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
        {
          id: "confirm",
          caption: "ยืนยัน",
          class: "w-full",
          action: "confirm",
          // icon: "icon-plus-add",
          // iconColor: "white",
          // iconHeight: "24",
          // iconWidth: "24",
        },
      ],
    },
    classTitle: {
      type: String,
      default: "",
    },
    isVisibleCloseButton: {
      type: Boolean,
      default: false,
    },
    widthStyle: {
      type: String,
      default: "30vw",
    },
  },
  // emits: ["confirm", "close" , "cancel"],
  setup() {
    const { lang } = useLang();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
        };
      } else {
        return {
          ...lang.en.base,
        };
      }
    });

    return {
      lang: mergeLang,
    };
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
    closeDialog() {
      this.$emit("close");
      this.$emit("cancel");
    },
  },
  mounted() { 
    if (this.display && document.getElementById("confirm")) {
      this.$nextTick(() => document.getElementById("confirm").focus());
    }
  },
};
</script>

<style>
.p-dialog .p-dialog-header {
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
}
.label-container {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
