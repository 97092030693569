<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoCreate="askForCreate"
    @gotoCancel="askForCancel"
    @gotoPrint="getPrintForm"
    @gotoSubmitApprove="askForSubmitApprove"
    @gotoSave="save"
  ></base-toolbar>

  <base-container>
    <base-unit-dialog
        v-model:visible="showUnitDialog"
        @close="showUnitDialog = false"
        @unit-selected="unitSelected"
    ></base-unit-dialog>

    <base-dialog
      v-model:visible="showValidateDialog"
      :classTitle="baseDialogClassTitle"
      :titleConfirm="titleConfirm"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="closeBaseDialog"
      @close="closeBaseDialog"
      @goto-create="createNewDocument"
      @goto-cancel="cancel"
      @goto-submit-approve="submitApprove"
    >
      <template #dialogBody v-if="showCancelDialog">
        <div class="lg:col-12 md:col-12 col-12 p-0 w-full">
          <label for="cancelReason">{{ lang.cancelReason }}</label>
          <PvDropdown
            id="cancelReasons"
            class="inputfield w-full mt-2 mr-2"
            :options="cancelReasons"
            optionLabel="name"
            optionValue="name"
            v-model="selectedCancelReason"
            scrollHeight="300px"
          />
        </div>
      </template>
    </base-dialog>

    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchTextPrint"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>

    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <div class="flex flex-wrap justify-content-between align-items-center">
        <div>
          <label class="font-lg font-normal">{{ lang.title }}</label>
        </div>
        <div>
          <PvButton
            class="p-button-outlined ml-3"
            @click="gotoPrintDebtFreeAttachment"
          >
            <icon-document-clip iconColor="primary" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{
              lang.debtFreeAttachment
            }}</span>
          </PvButton>
        </div>
      </div>
      <PvDivider></PvDivider>
      <!-- ส่วนที่ 1 -->
      <base-card
        :titleHeader="lang.titleSectionFirst"
        :isVisibleExpandButton="false"
        :isHideBackground="true"
        :isVisibleCard="true"
        class="p-0"
      >
        <section class="flex flex-wrap fontlabel" :class="textFormClass">
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="unitCode">{{ lang.unitCode }}</label>
            <PvInputText
              id="unitCode"
              type="text"
              class="inputfield w-full mt-1 p-inputtext-disabled"
              v-model="unitCode"
              disabled
            />
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="unitArea">{{ lang.unitArea }}</label>
            <PvInputNumber
              id="unitArea"
              class="inputfield w-full mt-1"
              inputClass="p-inputtext-disabled"
              v-model="unitArea"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              disabled
            />
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="unitAddressNo">{{ lang.unitAddressNo }}</label>
            <PvInputText
              id="unitAddressNo"
              type="text"
              class="inputfield w-full mt-1 p-inputtext-disabled"
              v-model="unitAddressNo"
              disabled
            />
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="unitCount">{{ lang.unitCount }}</label>
            <PvInputNumber
              id="unitCount"
              class="inputfield w-full mt-1"
              inputClass="p-inputtext-disabled"
              v-model="unitCount"
              disabled
            />
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="ownerName">{{ lang.customerName }}</label>
            <PvInputText
              id="ownerName"
              type="text"
              class="inputfield w-full mt-1 p-inputtext-disabled"
              v-model="ownerName"
              disabled
            />
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="pendingCurrent">{{ lang.pendingCurrent }}</label>
            <PvInputNumber
              id="pendingCurrent"
              class="inputfield w-full mt-1"
              inputClass="p-inputtext-disabled text-right"
              v-model="pendingCurrent"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              disabled
            />
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="ownerIdCardAddress1">{{
              lang.ownerIdCardAddress1
            }}</label>
            <PvInputText
              id="ownerIdCardAddress1"
              type="text"
              class="inputfield w-full mt-1 p-inputtext-disabled"
              v-model="ownerIdCardAddress1"
              disabled
            />
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="pendingCalculateDate">{{
              lang.pendingCalculateDate
            }}</label>
            <PvCalendar
              id="pendingCalculateDate"
              v-model="pendingCalculateDate"
              class="inputfield w-full mt-1"
              showIcon
              dateFormat="dd/mm/yy"
              inputClass="p-inputtext-disabled"
              disabled
            />
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="ownerIdCardAddress2">{{
              lang.ownerIdCardAddress2
            }}</label>
            <PvInputText
              id="ownerIdCardAddress2"
              type="text"
              class="inputfield w-full mt-1 p-inputtext-disabled"
              v-model="ownerIdCardAddress2"
              disabled
            />
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="pendingAmount">{{ lang.pendingAmount }}</label>
            <PvInputNumber
              id="pendingAmount"
              class="inputfield w-full mt-1"
              inputClass="p-inputtext-disabled text-right"
              v-model="pendingAmount"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              disabled
            />
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="ownerIdCardAddress3">{{
              lang.ownerIdCardAddress3
            }}</label>
            <PvInputText
              id="ownerIdCardAddress3"
              type="text"
              class="inputfield w-full mt-1 p-inputtext-disabled"
              v-model="ownerIdCardAddress3"
              disabled
            />
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="stringBahtPendingAmount">{{
              lang.stringBahtPendingAmount
            }}</label>
            <PvInputText
              id="stringBahtPendingAmount"
              type="text"
              class="inputfield w-full mt-1 p-inputtext-disabled"
              v-model="stringBahtPendingAmount"
              disabled
            />
          </div>
        </section>
      </base-card>

      <!-- ส่วนที่ 2 -->
      <base-card
        :titleHeader="lang.titleSectionSecond"
        :isVisibleExpandButton="false"
        :isHideBackground="true"
        :isVisibleCard="true"
        class="p-0"
      >
        <section class="flex flex-wrap fontlabel" :class="textFormClass">
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="requestDate">{{ lang.requestDate }}*</label>
            <PvCalendar
              id="requestDate"
              v-model="requestDate"
              class="inputfield w-full mt-1"
              showIcon
              dateFormat="dd/mm/yy"
              :class="{ 'p-invalid': requestDateError }"
              @date-select="requestDateError = false"
            />
            <small v-if="requestDateError" id="requestDate-help" class="p-error">
              {{ lang.validateRequestDate }}
            </small>
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="requestBy">{{ lang.requestBy }}*</label>
            <PvInputText
              id="requestBy"
              type="text"
              class="inputfield w-full mt-1"
              v-model="requestBy"
              :class="{ 'p-invalid': requestByError }"
              @keypress="requestByError = false"
            />
            <small v-if="requestByError" id="requestBy-help" class="p-error">
              {{ lang.validateRequestBy }}
            </small>
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="documentDate">{{ lang.documentDate }}</label>
            <PvCalendar
              id="documentDate"
              v-model="documentDate"
              class="inputfield w-full mt-1"
              showIcon
              dateFormat="dd/mm/yy"
              inputClass="p-inputtext-disabled"
              disabled
            />
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="requestPhone">{{ lang.requestPhone }}*</label>
            <PvInputText
              id="requestPhone"
              type="text"
              class="inputfield w-full mt-1"
              v-model="requestPhone"
              :class="{ 'p-invalid': requestPhoneError }"
              @keypress="checkInputRequestPhone"
            />
            <small v-if="requestPhoneError" id="requestPhone-help" class="p-error">
              {{ lang.validateRequestPhone }}
            </small>
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2"></div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2 field-checkbox">
            <PvCheckbox
              id="isForeigner"
              inputId="binary"
              class="mt-1"
              v-model="isForeigner"
              :binary="true"
            />
            <label for="isForeigner" class="pl-2">{{ lang.isForeigner }}</label>
          </div>
          <!-- left -->
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <div class="content-border h-full">
              <label class="font-lg font-normal">{{
                lang.requestDocument
              }}</label>
              <PvDivider></PvDivider>
              <div class="pt-1 field-checkbox">
                <PvCheckbox
                  id="isGetCopyOfOwnerIdCard"
                  inputId="binary"
                  class="mt-1"
                  v-model="isGetCopyOfOwnerIdCard"
                  :binary="true"
                  :class="{ 'p-invalid': isUnSelectedCheckBox }"
                  @change="changeDataCheckBox"
                />
                <label for="isGetCopyOfOwnerIdCard" class="pl-2" :class="{ 'p-error': isUnSelectedCheckBox }">{{
                  lang.isGetCopyOfOwnerIdCard
                }}*</label>
              </div>
              <div class="pt-1 field-checkbox">
                <PvCheckbox
                  id="isGetCopyOfOwnerHouseRegistration"
                  inputId="binary"
                  class="mt-1"
                  v-model="isGetCopyOfOwnerHouseRegistration"
                  :binary="true"
                  :class="{ 'p-invalid': isUnSelectedCheckBox }"
                  @change="changeDataCheckBox"
                />
                <label for="isGetCopyOfOwnerHouseRegistration" class="pl-2" :class="{ 'p-error': isUnSelectedCheckBox }">{{ lang.isGetCopyOfOwnerHouseRegistration}}*</label>
              </div>
              <div class="pt-1 field-checkbox">
                <PvCheckbox
                  id="isGetCopyOfJointOwnerShip"
                  inputId="binary"
                  class="mt-1"
                  v-model="isGetCopyOfJointOwnerShip"
                  :binary="true"
                  :class="{ 'p-invalid': isUnSelectedCheckBox }"
                  @change="changeDataCheckBox"
                />
                <label for="isGetCopyOfJointOwnerShip" class="pl-2" :class="{ 'p-error': isUnSelectedCheckBox }">{{
                  lang.isGetCopyOfJointOwnerShip
                }}*</label>
              </div>
              <div class="pt-1 field-checkbox">
                <PvCheckbox
                  id="isGetPowerOfAttorney"
                  inputId="binary"
                  class="mt-1"
                  v-model="isGetPowerOfAttorney"
                  :binary="true"
                />
                <label for="isGetPowerOfAttorney" class="pl-2">{{
                  lang.isGetPowerOfAttorney
                }}</label>
              </div>
              <div class="pt-1 field-checkbox">
                <PvCheckbox
                  id="isGetCopyOfProxyIdCard"
                  inputId="binary"
                  class="mt-1"
                  v-model="isGetCopyOfProxyIdCard"
                  :binary="true"
                />
                <label for="isGetCopyOfProxyIdCard" class="pl-2">{{
                  lang.isGetCopyOfProxyIdCard
                }}</label>
              </div>
              <div class="pt-1 field-checkbox">
                <PvCheckbox
                  id="isGetCopyOfNameChangedCertificate"
                  inputId="binary"
                  class="mt-1"
                  v-model="isGetCopyOfNameChangedCertificate"
                  :binary="true"
                />
                <label for="isGetCopyOfNameChangedCertificate" class="pl-2">{{
                  lang.isGetCopyOfNameChangedCertificate
                }}</label>
              </div>
              <div class="flex flex-wrap align-items-center">
                <div class="field-checkbox mb-0">
                  <PvCheckbox
                    id="isOtherDocument"
                    inputId="binary"
                    class=""
                    v-model="isOtherDocument"
                    :binary="true"
                    @change="changeOtherDocument"
                  />
                  <label for="isOtherDocument" class="pl-2 pr-2">{{
                    lang.isOtherDocument
                  }}</label>
                </div>
                <div class="w-8 pl-0">
                  <PvInputText
                    id="otherDocumentDescription"
                    type="text"
                    class="inputfield w-full"
                    v-model="otherDocumentDescription"
                    :placeholder="lang.otherDocumentDescription"
                    :class="{ 'p-inputtext-disabled': !isOtherDocument, 'p-invalid': otherDocumentError}"
                    :disabled="!isOtherDocument"
                  />
                  <small v-if="otherDocumentError" id="other-document-help" class="p-error">{{
                    lang.otherDocumentDescription
                  }}</small>
                </div>
              </div>
            </div>
          </div>
          <!-- right -->
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <div class="content-border h-full">
              <label class="font-lg font-normal">{{
                lang.requestDetail
              }}</label>
              <PvDivider></PvDivider>
              <div class="pt-1 field-checkbox mb-0">
                <PvCheckbox
                  id="isRequestCutOfPublicUtilities"
                  inputId="binary"
                  class="mt-1"
                  v-model="isRequestCutOfPublicUtilities"
                  :binary="true"
                  @change="changeCutOfPublicUtilitie"
                />
                <label for="isRequestCutOfPublicUtilities" class="pl-2">{{
                  lang.isRequestCutOfPublicUtilities
                }}</label>
              </div>
              <div class="align-items-center flex flex-wrap w-auto pl-5 pb-1 mt-2">
                <label
                  for="startCutOfPublicUtilitiesDate"
                  class="pl-1 ml-1 pr-2"
                  >{{ lang.selectDate }}</label
                >
                <div class="col-10 p-0 flex align-items-center">
                  <PvCalendar
                    id="startCutOfPublicUtilitiesDate"
                    v-model="startCutOfPublicUtilitiesDate"
                    class="inputfield w-full"
                    showIcon
                    dateFormat="dd/mm/yy"
                    :inputClass="{ 'p-inputtext-disabled': !isRequestCutOfPublicUtilities }"
                    :disabled="!isRequestCutOfPublicUtilities"
                    :class="{ 'p-invalid': startCutOfPublicUtilitiesDateError }"
                    @date-select="startCutOfPublicUtilitiesDateError = false"
                  />
                  <small v-if="startCutOfPublicUtilitiesDateError" id="start-date-help" class="p-error">{{
                    lang.validateNoSelectDate
                  }}</small>
                  <label
                    for="endCutOfPublicUtilitiestDate"
                    class="mr-2 ml-2"
                    >{{ "-" }}</label
                  >
                  <PvCalendar
                    id="endCutOfPublicUtilitiestDate"
                    v-model="endCutOfPublicUtilitiestDate"
                    class="inputfield w-full"
                    showIcon
                    dateFormat="dd/mm/yy"
                    :inputClass="{ 'p-inputtext-disabled': !isRequestCutOfPublicUtilities }"
                    :disabled="!isRequestCutOfPublicUtilities"
                    :class="{ 'p-invalid': endCutOfPublicUtilitiestDateError }"
                    @date-select="endCutOfPublicUtilitiestDateError = false"
                  />
                  <small v-if="endCutOfPublicUtilitiestDateError" id="end-date-help" class="p-error">{{
                    lang.validateNoSelectDate
                  }}</small>
                </div>
              </div>
              <div class="pt-2 field-checkbox">
                <PvCheckbox
                  id="isPaidFee"
                  inputId="binary"
                  class="mt-1"
                  v-model="isPaidFee"
                  :binary="true"
                />
                <label for="isPaidFee" class="pl-2">{{ lang.isPaidFee }}</label>
              </div>
              <div class="pt-1 field-checkbox">
                <PvCheckbox
                  id="isPaidExpenses"
                  inputId="binary"
                  class="mt-1"
                  v-model="isPaidExpenses"
                  :binary="true"
                  @change="changePaidExpenses"
                />
                <label for="isPaidExpenses" class="pl-2">{{
                  lang.isPaidExpenses
                }}</label>
              </div>
              <div class="flex flex-wrap pl-5 align-items-center">
                <label for="receiptCode" class="pl-1 ml-1 pr-2">{{
                  lang.receiptCode
                }}</label>
                <div class="col-9 p-0">
                  <PvInputText
                    id="receiptCode"
                    type="text"
                    class="inputfield w-full"
                    v-model="receiptCode"
                    :placeholder="lang.pleaseInputValue"
                    :class="{ 'p-inputtext-disabled': !isPaidExpenses, 'p-invalid': receiptCodeError }"
                    :disabled="!isPaidExpenses"
                  />
                  <small v-if="receiptCodeError" id="receipt-code-help" class="p-error">{{
                    lang.pleaseInputValue + lang.receiptCode
                  }}</small>
                </div>
              </div>
              <div class="flex flex-wrap pt-2 pb-2 pl-5 align-items-center">
                <label for="receiptAmount" class="pl-1 ml-1 pr-4 mr-0">{{
                  lang.receiptAmount
                }}</label>
                <div class="col-9 p-0">
                  <PvInputNumber
                    id="receiptAmount"
                    class="inputfield w-full"
                    v-model="receiptAmount"
                    mode="decimal"
                    :minFractionDigits="2"
                    :maxFractionDigits="2"
                    :placeholder="lang.pleaseInputValue"
                    :inputClass="{ 'p-inputtext-disabled': !isPaidExpenses }"
                    :disabled="!isPaidExpenses"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </base-card>

      <!-- ส่วนที่ 3 -->
      <base-card
        :titleHeader="lang.titleSectionThird"
        :isVisibleExpandButton="false"
        :isHideBackground="true"
        :isVisibleCard="true"
        class="p-0 pt-2"
      >
        <section class="flex flex-wrap fontlabel" :class="textFormClass">
          <div class="lg:col-12 md:col-12 col-12 pb-0 pl-0 pr-2 field-checkbox">
            <PvCheckbox
              id="isValidateExpenses"
              inputId="binary"
              class="mt-1"
              v-model="isValidateExpenses"
              :binary="true"
            />
            <label for="isValidateExpenses" class="pl-2">{{
              lang.isValidateExpenses
            }}</label>
          </div>
          <div
            class="lg:col-6 md:col-12 col-12 pl-0 pr-2 flex justify-content-between"
            v-if="isDisableDebtfreeApproval"
          >
            <div class="flex align-items-center justify-content-left pt-1">
              <div
                v-for="debtfreeApproval of debtfreeApprovals"
                :key="debtfreeApproval.code"
                class="field-radiobutton pt-4 mr-7 mb-0"
              >
                <PvRadioButton
                  class="mr-2"
                  :inputId="debtfreeApproval.code"
                  name="livingType"
                  :value="debtfreeApproval.code"
                  v-model="isDebtfreeApproval"
                  :disabled="true"
                />
                <label :for="debtfreeApproval.code" class="ml-0">{{
                  debtfreeApproval.name
                }}</label>
              </div>
            </div>
            <div class="w-7">
              <PvInputText
                id="disApproveReason"
                type="text"
                class="inputfield w-full mt-4 p-inputtext-disabled"
                v-model="disApproveReason"
                disabled
              />
            </div>
          </div>
          <div
            class="lg:col-3 md:col-12 col-12 pl-0 pr-2"
            v-if="isDisableDebtfreeApproval"
          >
            <label for="approvedDate">{{ lang.approvedDate }}</label>
            <PvCalendar
              id="approvedDate"
              v-model="approvedDate"
              class="inputfield w-full mt-1"
              showIcon
              dateFormat="dd/mm/yy"
              inputClass="p-inputtext-disabled"
              disabled
            />
          </div>
          <div
            class="lg:col-3 md:col-12 col-12 pl-0 pr-2"
            v-if="isDisableDebtfreeApproval"
          >
            <label for="debtFreeCode">{{ lang.debtFreeCode }}</label>
            <PvInputText
              id="debtFreeCode"
              type="text"
              class="inputfield w-full mt-1 p-inputtext-disabled"
              v-model="debtFreeCode"
              disabled
            />
          </div>
          <div
            class="lg:col-6 md:col-12 col-12 pl-0 pr-2"
            v-if="isDisableDebtfreeApproval"
          ></div>
          <div
            class="lg:col-6 md:col-12 col-12 pl-0 pr-2"
            v-if="isDisableDebtfreeApproval"
          >
            <label for="approvedBy">{{ lang.approvedBy }}</label>
            <PvInputText
              id="approvedBy"
              type="text"
              class="inputfield w-full mt-1 p-inputtext-disabled"
              v-model="approvedBy"
              disabled
            />
          </div>
        </section>
      </base-card>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import THBText from 'thai-baht-text';
import useGetReportPrint from "../../../hooks/getReportPrint.js";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import usePageDirty from "../../../hooks/pageDirty";
export default {
  props: ["id", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatDate, formatDateToISOString, formatDateToLocaleDateString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.debtFree.request.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.debtFree.request.edit,
        };
      }
    });

    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    const { 
      getPreviewBlob,
    } = useGetReportPrint();

    const {
      getFormPrints,
      searchTextPrint,
      filteredFormPrints,
    } = useGetFormPrint();

    const { compareObject, isCompareObject } = usePageDirty();

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatDate,
      formatDateToISOString,
      formatDateToLocaleDateString,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      THBText,
      getPreviewBlob,
      getFormPrints,
      searchTextPrint,
      filteredFormPrints,
      compareObject,
      isCompareObject,
    };
  },
  data() {
    return {
      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: "",
      validateWording: "",
      // ส่วนที่ 1
      unitCode: "",
      unitArea: 0,
      unitAddressNo: "",
      pendingCurrent: 0,
      ownerName: "",
      pendingCalculateDate: null,
      ownerIdCardAddress1: "",
      pendingAmount: 0,
      ownerIdCardAddress2: "",
      stringBahtPendingAmount: "",
      ownerIdCardAddress3: "",
      // ส่วนที่ 2
      requestDate: null,
      requestBy: "",
      documentDate: null,
      requestPhone: "",
      isForeigner: false,
      isGetCopyOfOwnerIdCard: false,
      isGetCopyOfOwnerHouseRegistration: false,
      isGetCopyOfJointOwnerShip: false,
      isGetPowerOfAttorney: false,
      isGetCopyOfProxyIdCard: false,
      isGetCopyOfNameChangedCertificate: false,
      isOtherDocument: false,
      otherDocumentDescription: "",
      isRequestCutOfPublicUtilities: false,
      startCutOfPublicUtilitiesDate: null,
      endCutOfPublicUtilitiestDate: null,
      isPaidFee: false,
      isPaidExpenses: false,
      receiptCode: "",
      receiptAmount: 0,
      // ส่วนที่ 3
      isValidateExpenses: false,
      debtfreeApprovals: [
        { name: this.lang.approve, code: "Y" },
        { name: this.lang.disApprove, code: "N" },
      ],
      isDebtfreeApproval: null,
      disApproveReason: "",
      approvedDate: null,
      debtFreeCode: "",
      approvedBy: "",

      pendingAmountByUnit: [],
      
      isCreateNewDocument: false,
      showCancelDialog: false,
      cancelReasons: [
        { name: this.lang.cancelReason1 },
        { name: this.lang.cancelReason2 },
        { name: this.lang.cancelReason3 },
      ],
      selectedCancelReason: null,
      isConfirmSubmitApprove: false,

      //form
      showFormPrintModal: false,
      path: "",

      //validate
      requestDateError: false,
      requestByError: false,
      requestPhoneError: false,
      isGetCopyOfOwnerIdCardError: false,
      isGetCopyOfOwnerHouseRegistrationError: false,
      isGetCopyOfJointOwnerShipError: false,
      isUnSelectedCheckBox: false,
      isCheckBoxError: false,
      isTextError: false,

      showUnitDialog: false,

      startCutOfPublicUtilitiesDateError: false,
      endCutOfPublicUtilitiestDateError: false,
      otherDocumentError: false,
      receiptCodeError: false,
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    await this.roleByMenuDataReady();
    await this.checkShouldSetData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.compareObject(this.objToCompare, this.payload);
      this.setLocalSearch();
      this.$router.push({ name: "debt-free-request"});
    },
    setLocalSearch() {
      let storageName = `erp_search_DebtFreeRequestList_${this.selectedProject.code}_${this.userCode}`;
      const localSearch = localStorage.getItem(storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      localStorage.setItem(storageName, JSON.stringify(search));
    },
    async roleByMenuDataReady() {
      if (this.isSharedRoleByMenuReady) {
        if (!this.isCreateDocument) {
          await this.getDebtFreeRequest();
          await this.getPendingAmountByUnit();
        } else if(this.selectedUnit) {
          await this.getCustomerById();
          await this.getUnitCountByCustomerId();
          await this.getPendingAmountByUnit();
        }
      }
    },
    async initiateData() {
      await this.$store.dispatch("debtFreeRequest/resetDebtFreeRequest");

      // ส่วนที่ 1
      this.unitCode = "";
      (this.unitArea = 0), (this.unitAddressNo = "");
      this.pendingCurrent = 0;
      this.ownerName = "";
      this.pendingCalculateDate = null;
      this.ownerIdCardAddress1 = "";
      this.pendingAmount = 0;
      this.ownerIdCardAddress2 = "";
      this.stringBahtPendingAmount = "";
      this.ownerIdCardAddress3 = "";
      // ส่วนที่ 2
      this.requestDate = null;
      this.requestBy = "";
      this.documentDate = null;
      this.requestPhone = "";
      this.isForeigner = false;
      this.isGetCopyOfOwnerIdCard = false;
      this.isGetCopyOfOwnerHouseRegistration = false;
      this.isGetCopyOfJointOwnerShip = false;
      this.isGetPowerOfAttorney = false;
      this.isGetCopyOfProxyIdCard = false;
      this.isGetCopyOfNameChangedCertificate = false;
      this.isOtherDocument = false;
      this.otherDocumentDescription = "";
      this.isRequestCutOfPublicUtilities = false;
      this.startCutOfPublicUtilitiesDate = null;
      this.endCutOfPublicUtilitiestDate = null;
      this.isPaidFee = false;
      this.isPaidExpenses = false;
      this.receiptCode = "";
      this.receiptAmount = 0;
      // ส่วนที่ 3
      this.isValidateExpenses = false;
      this.isDebtfreeApproval = null;
      this.disApproveReason = "";
      this.approvedDate = null;
      this.debtFreeCode = "";
      this.approvedBy = "";

      this.isCreateNewDocument = false;
      this.pendingAmountByUnit = [];
      this.showCancelDialog = false;
      this.isConfirmSubmitApprove = false;

      this.showFormPrintModal = false;
      this.path = "";

      this.requestDateError = false;
      this.requestByError = false;
      this.requestPhoneError = false;
      this.isGetCopyOfOwnerIdCardError = false;
      this.isGetCopyOfOwnerHouseRegistrationError = false;
      this.isGetCopyOfJointOwnerShipError = false;
      this.isUnSelectedCheckBox = false;
      this.isCheckBoxError = false;
      this.isTextError = false;

      this.startCutOfPublicUtilitiesDateError = false;
      this.endCutOfPublicUtilitiestDateError = false;
      this.otherDocumentError = false;
      this.receiptCodeError = false;
    },
    clearBaseDialog() {
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.validateWording = "";
      this.isCreateNewDocument = false;
      this.isConfirmSubmitApprove = false;
      this.showCancelDialog = false;
    },
    closeBaseDialog() {
      let element = document.getElementsByTagName("body")[0];
      element.style.overflow = "scroll";

      this.showValidateDialog = false;
      this.clearBaseDialog();
    },
    async getDebtFreeRequest() {
      try {
        await this.$store.dispatch(
          "debtFreeRequest/getDebtFreeRequestById",
          this.id
        );

        if (this.debtFreeRequest) {
          await this.getUnitCountByCustomerId();
        }
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
      }
    },
    async getPendingAmountByUnit() {
      try {
        let unitId = !this.isCreateDocument
          ? this.debtFreeRequest.unitId
          : this.selectedUnit.id;
        let payload = {
          unitId: unitId,
        };

        await this.$store.dispatch(
          "arInvoice/getPendingAmountByUnitAndLivingType",
          payload
        );

        this.pendingAmountByUnit = this.pendingAmountByUnits.filter(
          (data) => data.livingType == "All"
        );
        await this.setPendingAmount();

      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
      }
    },
    async checkShouldSetData() {
      await this.checkDataSelectedUnit();
      
      if (this.isSharedRoleByMenuReady) {
        if(this.isCreateDocument && this.selectedUnit && this.customer) {
          await this.setDataCreate();
        }

        if (this.debtFreeRequest && this.pendingAmountByUnit.length > 0) {
          await this.setPendingAmount();
          await this.setData();
        } else if (this.debtFreeRequest) {
          await this.setData();
        } else if (this.isCreateDocument) {
          this.setLoading(false);
        }
      }
      await this.setCompareData();
    },
    async checkDataSelectedUnit() {
      if(this.isCreateDocument && !this.selectedUnit) {
        this.setLoading(false);
        this.showUnitDialog = true;
        return;
      }
    },
    async setData() {
      this.unitCode = this.debtFreeRequest.unitCode;
      this.unitArea = this.debtFreeRequest.unitArea;
      this.unitAddressNo = this.debtFreeRequest.unitAddressNo;
      this.ownerName = this.debtFreeRequest.debtfreeRequestOwnerName;
      this.pendingCalculateDate = new Date(
        this.debtFreeRequest.pendingCalculateDate
      );
      this.ownerIdCardAddress1 = this.debtFreeRequest.ownerIdCardAddress1;
      this.pendingAmount = this.debtFreeRequest.pendingAmount;
      this.ownerIdCardAddress2 = this.debtFreeRequest.ownerIdCardAddress2;
      this.stringBahtPendingAmount =
        this.debtFreeRequest.stringBahtPendingAmount;
      this.ownerIdCardAddress3 = this.debtFreeRequest.ownerIdCardAddress3;
      // ส่วนที่ 2
      this.requestDate = new Date(this.debtFreeRequest.requestDate);
      this.requestBy = this.debtFreeRequest.requestBy;
      this.documentDate = new Date(this.debtFreeRequest.documentDate);
      this.requestPhone = this.debtFreeRequest.requestPhone;
      this.isForeigner = this.debtFreeRequest.isForeigner == "Y" ? true : false;
      this.isGetCopyOfOwnerIdCard =
        this.debtFreeRequest.isGetCopyOfOwnerIdCard == "Y" ? true : false;
      this.isGetCopyOfOwnerHouseRegistration =
        this.debtFreeRequest.isGetCopyOfOwnerHouseRegistration == "Y"
          ? true
          : false;
      this.isGetCopyOfJointOwnerShip =
        this.debtFreeRequest.isGetCopyOfJointOwnerShip == "Y" ? true : false;
      this.isGetPowerOfAttorney =
        this.debtFreeRequest.isGetPowerOfAttorney == "Y" ? true : false;
      this.isGetCopyOfProxyIdCard =
        this.debtFreeRequest.isGetCopyOfProxyIdCard == "Y" ? true : false;
      this.isGetCopyOfNameChangedCertificate =
        this.debtFreeRequest.isGetCopyOfNameChangedCertificate == "Y"
          ? true
          : false;
      this.isOtherDocument =
        this.debtFreeRequest.isOtherDocument == "Y" ? true : false;
      this.otherDocumentDescription =
        this.debtFreeRequest.otherDocumentDescription;
      this.isRequestCutOfPublicUtilities =
        this.debtFreeRequest.isRequestCutOfPublicUtilities == "Y"
          ? true
          : false;
      this.startCutOfPublicUtilitiesDate = new Date(
        this.debtFreeRequest.startCutOfPublicUtilitiesDate
      );
      this.endCutOfPublicUtilitiestDate = new Date(
        this.debtFreeRequest.endCutOfPublicUtilitiestDate
      );
      this.isPaidFee = this.debtFreeRequest.isPaidFee == "Y" ? true : false;
      this.isPaidExpenses =
        this.debtFreeRequest.isPaidExpenses == "Y" ? true : false;
      this.receiptCode = this.debtFreeRequest.receiptCode;
      this.receiptAmount = this.debtFreeRequest.receiptAmount == "" ? 0 : parseInt(this.debtFreeRequest.receiptAmount);
      // ส่วนที่ 3
      this.isValidateExpenses =
        this.debtFreeRequest.isValidateExpenses == "Y" ? true : false;
      this.isDebtfreeApproval = this.debtFreeRequest.isDebtfreeApproval;
      this.disApproveReason = this.debtFreeRequest.disapproveReason;
      this.approvedDate = new Date(this.debtFreeRequest.approvedDate);
      this.debtFreeCode = this.debtFreeRequest.debtfreeCode;
      this.approvedBy = this.debtFreeRequest.approvedBy;

      this.setLoading(false);
    },
    async setPendingAmount() {
      let pendingCurrent = this.pendingAmountByUnit[0];
      this.pendingCurrent = pendingCurrent ? pendingCurrent.pendingAmount : 0;
    },
    async setDataCreate() {
      this.unitCode = this.selectedUnit.code;
      this.unitArea = this.selectedUnit.area;
      this.unitAddressNo = this.selectedUnit.addressNo;
      this.ownerName = this.customer.prefix ? this.customer.prefix + " " + this.customer.name : this.customer.name;
      this.pendingCalculateDate = new Date();
      await this.setPendingAmount();
      this.ownerIdCardAddress1 = this.customer.idCardAddress1?this.customer.idCardAddress1:"";
      this.pendingAmount = this.pendingCurrent;
      this.ownerIdCardAddress2 = this.customer.idCardAddress2?this.customer.idCardAddress2:"";
      this.stringBahtPendingAmount = this.pendingCurrent == 0 ? this.lang.stringBahtPendingAmountIsZero : THBText(this.pendingCurrent);
      this.ownerIdCardAddress3 = this.customer.idCardAddress3?this.customer.idCardAddress3:"";
      this.requestDate = new Date();
      this.documentDate = new Date();
      this.startCutOfPublicUtilitiesDate = new Date();
      this.endCutOfPublicUtilitiestDate = new Date();
    },
    async gotoPrintDebtFreeAttachment() {
      let parameter = {
        ProjectId: this.selectedProject.id,
        UnitCode: this.unitCode.trim(),
        DateAt: this.formatDateToISOString(new Date()),
      };
      let printing = {
        DocType: 'PDF',
        path: 'debtfree/debtfree_documentation.pdf',
        fileName: "DebtfreeAttachment",
        parameter: JSON.stringify(parameter),
      };
      this.setLoading(true);
      try {
        await this.$store.dispatch("shared/printReport", printing);
        this.setLoading(false);
        this.getPreviewBlob(this.fileBlob);
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    async getPrintForm() {
      if(!this.isRolePrint) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validatePrintPermission;
        this.showValidateDialog = true;
        return;
      }

      await this.$store.dispatch("shared/resetFormPrint");

      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: "REDEBTFREE",
      };
      const payload = { where };

      await this.getFormPrints(payload);

      if (this.filteredFormPrints.length == 1) {
        this.formPrintModalSelected(this.filteredFormPrints[0]);
      } else {
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path + ".pdf";
      this.printDebtFreeRequest();
      this.showFormPrintModal = false;
    },
    async printDebtFreeRequest() {
      this.setLoading(true);
      try {
        let parameter = {
          Id: this.id,
        };

        let payload = {
          path: this.path,
          fileName: "DebtFreeRequest",
          parameter: JSON.stringify(parameter),
        };

        await this.$store.dispatch("shared/printForm", payload);
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateDialogWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }

      let blob = this.$store.getters["shared/blob"];
      this.getPreviewBlob(blob);
      this.setLoading(false);
    },
    async askForCreate() {
      let result = this.isCompareObject(this.objToCompare, this.payload);
      this.clearBaseDialog();
      this.isCreateNewDocument = true;
      if (result) {
        this.titleConfirm = this.lang.createNewDebtFreeRequestTitle;
        this.validateWording = [this.lang.askForCreateNewDebtFreeRequest, this.lang.createNewDebtFreeRequestTitleDetail]
        this.showValidateDialog = true;
      } else {
        await this.createNewDocument();
      }
    },
    async createNewDocument() {
      this.showValidateDialog = false;
      this.showUnitDialog = true;
    },
    async setCompareData() {
      let unitId = "";
      if(this.isCreateDocument) {
        if(this.selectedUnit) {
          unitId = this.selectedUnit.id
        }
      } else {
        if(this.debtFreeRequest) {
          unitId = this.debtFreeRequest.unitId;
        } 
      }

      // let debtfreeRequestOwnerName = "";
      // if(this.isCreateDocument) {
      //   if(this.selectedUnit) {
      //     debtfreeRequestOwnerName = this.selectedUnit.customerName;
      //   }
      // } else {
      //   if(this.debtFreeRequest) {
      //     debtfreeRequestOwnerName = this.debtFreeRequest.debtfreeRequestOwnerName;
      //   } 
      // }

      let objToCompare = {
        projectId: this.selectedProject.id,
        unitId: unitId,
        documentDate: this.documentDate ? this.formatDateToISOString(this.documentDate) : null,
        pendingCalculateDate: this.pendingCalculateDate ? this.formatDateToISOString(this.pendingCalculateDate) : null,
        pendingAmount: this.pendingAmount,
        requestDate: this.requestDate ? this.formatDateToLocaleDateString(this.requestDate) : null,
        requestBy: this.requestBy,
        requestPhone: this.requestPhone,
        isGetCopyOfOwnerIdCard: this.isGetCopyOfOwnerIdCard ? "Y" : "N",
        isGetCopyOfOwnerHouseRegistration: this.isGetCopyOfOwnerHouseRegistration ? "Y" : "N",
        isGetCopyOfNameChangedCertificate: this.isGetCopyOfNameChangedCertificate ? "Y" : "N",
        isOtherDocument: this.isOtherDocument ? "Y" : "N",
        isRequestCutOfPublicUtilities: this.isRequestCutOfPublicUtilities ? "Y" : "N",
        isPaidFee: this.isPaidFee ? "Y" : "N",
        isPaidExpenses: this.isPaidExpenses ? "Y" : "N",
        isGetCopyOfJointOwnerShip: this.isGetCopyOfJointOwnerShip ? "Y" : "N",
        isGetPowerOfAttorney: this.isGetPowerOfAttorney ? "Y" : "N",
        isGetCopyOfProxyIdCard: this.isGetCopyOfProxyIdCard ? "Y" : "N",
        otherDocumentDescription: this.otherDocumentDescription,
        startCutOfPublicUtilitiesDate: this.startCutOfPublicUtilitiesDate ? this.formatDateToLocaleDateString(this.startCutOfPublicUtilitiesDate) : null,
        endCutOfPublicUtilitiestDate: this.endCutOfPublicUtilitiestDate ? this.formatDateToLocaleDateString(this.endCutOfPublicUtilitiestDate) : null,
        receiptCode: this.receiptCode,
        receiptAmount: this.receiptAmount.toString(),
        isValidateExpenses: this.isValidateExpenses ? "Y" : "N",
        debtfreeRequestOwnerName: this.ownerName,
        isForeigner: this.isForeigner ? "Y" : "N",
        type: "Add",
      }

      if (!this.isCreateDocument) {
        objToCompare = { ...objToCompare, id: this.id, type: "Update" };
      }

      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
      // console.log("objToCompare", this.objToCompare);
      // console.log("payload", this.payload);
    },
    askForCancel() {
      if (this.validateDocument()) {
        return;
      }
      if(!this.isRoleCancel) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateRoleCancel;
        this.showValidateDialog = true;
        return;
      }
      let element = document.getElementsByTagName("body")[0];
      element.style.overflow = "hidden";

      this.clearBaseDialog();
      this.showCancelDialog = true;
      this.titleConfirm = this.lang.cancelDebtFreeRequestTitle;
      this.showValidateDialog = true;
    },
    async cancel() {
      this.setLoading(true);
      try {
        let payload = {
          id: this.id,
          cancelReason: this.selectedCancelReason,
        };

        await this.$store.dispatch("debtFreeRequest/cancelDebtFreeRequest", payload);

        this.showCancelDialog = false;
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.cancelSuccess;
        this.validateWording = this.lang.cancelDebtFreeRequestSuccess.replace(":unitCode", this.debtFreeRequest.unitCode.trim());
        this.showValidateDialog = true;
        
      } catch (error) {
        this.showCancelDialog = false;
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    askForSubmitApprove() {
      if (this.validateDocument()) {
        return;
      }

      if (this.validatePendingAmount()) {
        return;
      } else {
        this.clearBaseDialog();
        this.isConfirmSubmitApprove = true;
        this.titleConfirm = this.lang.submitApproveTitle;
        this.validateWording = this.lang.submitApproveTitleDetail;
        this.showValidateDialog = true;
      }
    },
    validatePendingAmount() {
      if(this.isPendingAmount) {
        this.clearBaseDialog();
        this.baseDialogClassTitle = "p-error";
        this.titleConfirm = this.lang.isPendingAmountTitle;
        this.validateWording = this.lang.isPendingAmountTitleDetail;
        this.showValidateDialog = true;
        return true;
      }
      return false;
    },
    async submitApprove() {
      this.setLoading(true);
      try {
        let payload = {
          id: this.id,
        }

        await this.$store.dispatch("debtFreeRequest/approveDebtFreeRequest", payload);

        this.clearBaseDialog();
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.successfully;
        this.validateWording = this.lang.submitApproveSuccess;
        this.showValidateDialog = true;
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    wordingValidate() {
      if(!this.requestDate) {
        return this.lang.validateDropDownNoSelect + this.lang.requestDate;
      }

      if(!this.requestBy) {
        return this.lang.validateFieldNoValue + this.lang.requestBy;
      }

      if(!this.requestPhone) {
        return this.lang.validateFieldNoValue + this.lang.requestPhone;
      }

      if(!this.isGetCopyOfOwnerIdCard && !this.isGetCopyOfOwnerHouseRegistration && !this.isGetCopyOfJointOwnerShip) {
        return this.lang.validateDropDownNoSelect + this.lang.requestDocument;
      }

      if(this.isRequestCutOfPublicUtilities && !this.startCutOfPublicUtilitiesDate) {
        return this.lang.validateDropDownNoSelect + this.lang.date + this.lang.isRequestCutOfPublicUtilities;
      }

      if(this.isRequestCutOfPublicUtilities && !this.endCutOfPublicUtilitiestDate) {
        return this.lang.validateDropDownNoSelect + this.lang.date + this.lang.isRequestCutOfPublicUtilities;
      }

      if(this.isOtherDocument && !this.otherDocumentDescription) {
        return this.lang.validateFieldNoValue + this.lang.isOtherDocument;
      }

      if(this.isPaidExpenses && !this.receiptCode) {
        return this.lang.validateFieldNoValue + this.lang.receiptCode;
      }
    },
    validateData() {
      let returnStatus = false;
      if(!this.requestDate) {
        this.requestDateError = true;
        returnStatus = true;
      }

      if(!this.requestBy) {
        this.requestByError = true;
        returnStatus = true;
      }

      if(!this.requestPhone) {
        this.requestPhoneError = true;
        returnStatus = true;
      }

      if(!this.isGetCopyOfOwnerIdCard && !this.isGetCopyOfOwnerHouseRegistration && !this.isGetCopyOfJointOwnerShip) {
        this.isUnSelectedCheckBox = true;
        returnStatus = true;
      }

      if(this.isRequestCutOfPublicUtilities && !this.startCutOfPublicUtilitiesDate) {
        this.startCutOfPublicUtilitiesDateError = true;
        returnStatus = true;
      }

      if(this.isRequestCutOfPublicUtilities && !this.endCutOfPublicUtilitiestDate) {
        this.endCutOfPublicUtilitiestDateError = true;
        returnStatus = true;
      }

      if(this.isOtherDocument && !this.otherDocumentDescription) {
        this.otherDocumentError = true;
        returnStatus = true;
      }

      if(this.isPaidExpenses && !this.receiptCode) {
        this.receiptCodeError = true;
        returnStatus = true;
      }

      if(returnStatus) {
        this.baseDialogClassTitle = "p-error";
        this.titleConfirm = this.lang.validateSaveTitle;
        this.validateWording = this.wordingValidate();
        this.showValidateDialog = true;
      }

      return returnStatus;
    },
    validateDocument() {
      if(this.isDocumentCanceled) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateCancelled;
        this.showValidateDialog = true;
        return true;
      } else if(this.isDocumentApproved) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateDebtFreeApproval;
        this.showValidateDialog = true;
        return true;
      } else if(this.isSubmittedApprove) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateDebtFreeSubmittedApprove;
        this.showValidateDialog = true;
        return true;
      }
      return false;
    },
    validateRole() {
      if(this.isCreateDocument && !this.isRoleCreate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return true;
      } else if(!this.isCreateDocument && !this.isRoleUpdate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateRoleUpdate;
        this.showValidateDialog = true;
        return true;
      }
      return false;
    },
    async save() {
      if (this.validateRole()) {
        return;
      }
      if (this.validateDocument()) {
        return;
      }
      if (this.validateData() || this.isUnSelectedCheckBox) {
        return;
      }
      this.setLoading(true);
      try {
        // console.log("save", this.payload);
        await this.getPendingAmountByUnit();
        await this.setDataNewPendingAmountByUnit();

        await this.$store.dispatch(
          "debtFreeRequest/createOrUpdateDebtFreeRequest",
          this.payload
        );

        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.saveSuccess;
        this.validateWording = this.isCreateDocument ? this.lang.createDebtFreeRequestSuccess : this.lang.editDebtFreeRequestSuccess;
        this.showValidateDialog = true;
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);

      if (this.isCreateDocument) {
        await this.$router.replace({ name: "debt-free-request-edit", params: {id: this.debtFreeRequest.id} });
        await this.setCompareData();
      }
    },
    async unitSelected(data) {
      this.setLoading(true);
      await this.$store.dispatch("debtFreeRequest/resetSelectedUnit");
      await this.$store.dispatch("debtFreeRequest/selectedUnit", data);
      this.showUnitDialog = false;
      if(this.selectedUnit) {
        await this.$router.push({ name: "debt-free-request-edit", params: {id: "create"} });
        await this.initiateData();
        await this.getCustomerById();
        await this.getPendingAmountByUnit();
        await this.getUnitCountByCustomerId();
      }
      this.setLoading(false);
    },
    changeDataCheckBox() {
      this.isUnSelectedCheckBox = false;
    },
    async getUnitCountByCustomerId() {
      try {
        let customerId = "";
        if(!this.isCreateDocument) {
          customerId = this.debtFreeRequest.ownerId;
        } else {
          customerId = this.selectedUnit.customerId;
        }

        await this.$store.dispatch( "masterResident/getUnitCountById", customerId );

      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    checkInputRequestPhone($event) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-'];
      const keyPressed = $event.key;
      if (!keysAllowed.includes(keyPressed)) {
        $event.preventDefault()
      } else {
        this.requestPhoneError = false;
      }
    },
    async setDataNewPendingAmountByUnit() {
      // await this.setPendingAmount();
      this.pendingAmount = this.pendingCurrent;
    },
    async getCustomerById() {
      try {
        let customerId = "";
        if(!this.isCreateDocument) {
          customerId = this.debtFreeRequest.ownerId;
        } else {
          customerId = this.selectedUnit.customerId;
        }
        await this.$store.dispatch("masterResident/getResident", customerId);

      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    changeOtherDocument() {
      if(!this.isOtherDocument) {
        this.otherDocumentError = false;
        this.otherDocumentDescription = "";
      }
    },
    changeCutOfPublicUtilitie() {
      if(!this.isRequestCutOfPublicUtilities) {
        this.startCutOfPublicUtilitiesDate = new Date();
        this.endCutOfPublicUtilitiestDate = new Date();
      }
    },
    changePaidExpenses() {
      if(!this.isPaidExpenses) {
        this.receiptCodeError = false;
        this.receiptCode = "";
        this.receiptAmount = 0;
      }
    }
  },
  computed: {
    payload() {
      let unitId = "";
      if(this.isCreateDocument) {
        if(this.selectedUnit) {
          unitId = this.selectedUnit.id
        }
      } else {
        if(this.debtFreeRequest) {
          unitId = this.debtFreeRequest.unitId;
        } 
      }

      // let debtfreeRequestOwnerName = "";
      // if(this.isCreateDocument) {
      //   if(this.selectedUnit) {
      //     debtfreeRequestOwnerName = this.selectedUnit.customerName;
      //   }
      // } else {
      //   if(this.debtFreeRequest) {
      //     debtfreeRequestOwnerName = this.debtFreeRequest.debtfreeRequestOwnerName;
      //   } 
      // }
      
      let payload = {
        projectId: this.selectedProject.id,
        unitId: unitId,
        documentDate: this.documentDate ? this.formatDateToISOString(this.documentDate) : null,
        pendingCalculateDate: this.pendingCalculateDate ? this.formatDateToISOString(this.pendingCalculateDate) : null,
        pendingAmount: this.pendingAmount,
        requestDate: this.requestDate ? this.formatDateToLocaleDateString(this.requestDate) : null,
        requestBy: this.requestBy,
        requestPhone: this.requestPhone,
        isGetCopyOfOwnerIdCard: this.isGetCopyOfOwnerIdCard ? "Y" : "N",
        isGetCopyOfOwnerHouseRegistration: this.isGetCopyOfOwnerHouseRegistration ? "Y" : "N",
        isGetCopyOfNameChangedCertificate: this.isGetCopyOfNameChangedCertificate ? "Y" : "N",
        isOtherDocument: this.isOtherDocument ? "Y" : "N",
        isRequestCutOfPublicUtilities: this.isRequestCutOfPublicUtilities ? "Y" : "N",
        isPaidFee: this.isPaidFee ? "Y" : "N",
        isPaidExpenses: this.isPaidExpenses ? "Y" : "N",
        isGetCopyOfJointOwnerShip: this.isGetCopyOfJointOwnerShip ? "Y" : "N",
        isGetPowerOfAttorney: this.isGetPowerOfAttorney ? "Y" : "N",
        isGetCopyOfProxyIdCard: this.isGetCopyOfProxyIdCard ? "Y" : "N",
        otherDocumentDescription: this.otherDocumentDescription,
        startCutOfPublicUtilitiesDate: this.startCutOfPublicUtilitiesDate ? this.formatDateToLocaleDateString(this.startCutOfPublicUtilitiesDate) : null,
        endCutOfPublicUtilitiestDate: this.endCutOfPublicUtilitiestDate ? this.formatDateToLocaleDateString(this.endCutOfPublicUtilitiestDate) : null,
        receiptCode: this.receiptCode,
        receiptAmount: this.receiptAmount.toString(),
        isValidateExpenses: this.isValidateExpenses ? "Y" : "N",
        debtfreeRequestOwnerName: this.ownerName,
        isForeigner: this.isForeigner ? "Y" : "N",
        type: "Add",
      };

      if (!this.isCreateDocument) {
        payload = {
          ...payload,
          id: this.id,
          type: "Update",
        };
      }

      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    isCreateDocument() {
      return this.id === "create";
    },
    title() {
      let result = this.lang.createDebtFreeRequest;
      if (!this.isCreateDocument) {
        result = this.lang.editDebtFreeRequest;
      }
      return result;
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmCOR");
    },
    isRoleCreate() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    isRoleUpdate() {
      if (this.roleByMenu && this.roleByMenu.update == "Y") {
        return true;
      }
      return false;
    },
    isRoleCancel() {
      if (this.roleByMenu && this.roleByMenu.cancel == "Y") {
        return true;
      }
      return false;
    },
    isRolePrint() {
      if (this.roleByMenu && this.roleByMenu.print == "Y") {
        return true;
      }
      return false;
    },
    isDisableCancel() {
      if (!this.isRoleCancel || this.isCreateDocument || this.isDocumentCanceled) {
        return true;
      }
      return false;
    },
    isDisablePrint() {
      if (!this.isRolePrint || this.isCreateDocument) {
        return true;
      }
      return false;
    },
    isDisableSave() {
      if (this.isCreateDocument) {
        if (!this.isRoleCreate) {
          return true;
        }
      } else {
        if (!this.isRoleUpdate || this.isDocumentCanceled || this.isDocumentApproved || this.isSubmittedApprove) {
          return true;
        }
      }
      return false;
    },
    isDisableSubmitApprove() {
      if (this.isCreateDocument || this.isDocumentCanceled || this.isDocumentApproved || this.isSubmittedApprove) {
        return true;
      }
      return false;
    },
    debtFreeRequest() {
      return this.$store.getters["debtFreeRequest/selectedDebtFreeRequest"];
    },
    selectedUnit() {
      return this.$store.getters["debtFreeRequest/selectedUnit"];
    },
    pendingAmountByUnits() {
      return this.$store.getters["arInvoice/pendingAmountByUnitAndLivingType"];
    },
    isDisableDebtfreeApproval() {
      if (
        this.debtFreeRequest &&
        (this.debtFreeRequest.isDebtfreeApproval == "Y" ||
          this.debtFreeRequest.isDebtfreeApproval == "N")
      ) {
        return true;
      }
      return false;
    },
    fileBlob() {
      return this.$store.getters["shared/blob"];
    },
    isDocumentCanceled() {
      if(this.debtFreeRequest && this.debtFreeRequest.cancelDebtfreeRequestStatus.toLowerCase().trim() == "c") {
        return true;
      }
      return false;
    },
    isDocumentApproved() {
      if(this.debtFreeRequest && this.debtFreeRequest.isDebtfreeApproval.toLowerCase().trim() != "") {
        return true;
      }
      return false;
    },
    isSubmittedApprove() {
      if(this.debtFreeRequest && this.debtFreeRequest.approveStatusCode != "") {
        return true;
      }
      return false;
    },
    isPendingAmount() {
      if(this.debtFreeRequest && this.debtFreeRequest.pendingAmount > 0) {
        return true;
      }
      return false;
    },
    unitCountByCustomerId() {
      return this.$store.getters["masterResident/unitCountById"];
    },
    unitCount() {
      let unit = 0;
      if(this.unitCountByCustomerId) {
        unit = this.unitCountByCustomerId.unitCount;
      }

      return unit;
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "cancel",
          action: "gotoCancel",
          isDisable: this.isCreateDocument, //this.isDisableCancel,
          type: "cancel"
        },
        {
          id: "create",
          action: "gotoCreate",
          // isDisable: !this.isRoleCreate,
          type: "create"
        },
        {
          id: "print",
          action: "gotoPrint",
          isDisable: this.isCreateDocument, //this.isDisablePrint,
          type: "print"
        },
        {
          id: "save",
          action: "gotoSave",
          // isDisable: this.isDisableSave,
          type: "save"
        },
        {
          id: "submitApprove",
          caption: this.lang.submitApprove,
          class: "mr-2",
          icon: "icon-document-checkmark",
          iconColor: "white",
          action: "gotoSubmitApprove",
          isShow: true,
          isDisable: this.isCreateDocument, //this.isDisableSubmitApprove,
        },
      ];
      return dataButtons;
    },
    dataButtonDialog() {
      let dataButtonDialog = [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];

      if(this.isCreateNewDocument) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "create",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-create",
          },
        ];
      } else if(this.showCancelDialog) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "confirmCancel",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-cancel",
            isDisable: !this.selectedCancelReason
          },
        ];
      } else if(this.isConfirmSubmitApprove) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "confirmCancel",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-submit-approve",
          },
        ];
      }

      return dataButtonDialog;
    },
    customer() {
      return this.$store.getters["masterResident/selectedResident"];
    },
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.roleByMenuDataReady();
    },
    debtFreeRequest() {
      this.checkShouldSetData();
    },
    // pendingAmountByUnit() {
    //   this.checkShouldSetData();
    // },
    selectedUnit() {
      this.checkDataSelectedUnit();
    },
    customer() {
      this.checkShouldSetData();
    },
  },
};
</script>
