<template>
  <base-toolbar :title="lang.importSupplier" @goBack="goBack"></base-toolbar>
  <base-progress-bar
    v-model:visible="showProgressBar"
    :title="lang.creatingStockKeycard"
    :textProgressBar="lang.generating"
    :valueObj="valueObj"
    :progressBar="`Template`"
  ></base-progress-bar>

  <import-supplier-dialog
    v-model:visible="showUploadSupplierDialog"
    @close="showUploadSupplierDialog = false"
    @data-upload="uploadedSupplier"
  ></import-supplier-dialog>

  <preview-supplier-detail-dialog
    v-model:visible="showPreviewDetailDialog"
    @close="showPreviewDetailDialog = false"
    :selectedSupplier="selectedSupplier"
    :key="keyToReLoad"
  ></preview-supplier-detail-dialog>

  <base-container>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.importSupplier }}</label>

        <div class="flex">
          <PvButton class="p-button-outlined ml-3" @click="exportErrorExcel">
            <i class="pi pi-file-excel" style="width: 16px; height: 16px"></i>
            <span class="p-button-label font-normal ml-1">{{ lang.listCannotImport }}</span>
          </PvButton>

          <PvButton class="p-button-outlined ml-3" @click="askForReUpload">
            <icon-cloud-storage-upload
              iconColor="primary"
              width="16"
              height="16"
            />
            <span class="p-button-label font-normal ml-1">{{
              lang.reupload
            }}</span>
          </PvButton>

          <PvButton class="ml-3" @click="importSupplier">
            <icon-documents-file-checkmark
              iconColor="white"
              width="16"
              height="16"
            />
            <span class="p-button-label font-normal ml-1">{{
              labelImportAll
            }}</span>
          </PvButton>
        </div>
      </div>

      <PvDivider></PvDivider>

      <div class="flex flex-wrap justify-content-left">
        <div class="lg:col-2 md:col-6 col-12 ml-1 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.allStatus"
            :content="contentAllStatus"
            :color="'primary'"
            :isClick="allStatusClick"
            @click="clickFilter('all')"
          ></base-select-card>
        </div>
        <div class="lg:col-2 md:col-6 col-12 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.wait"
            :content="contentWaitStatus"
            :color="'warning'"
            :isClick="waitStatusClick"
            @click="clickFilter('wait')"
          ></base-select-card>
        </div>
        <div class="lg:col-2 md:col-6 col-12 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.invalid"
            :content="contentInvalidStatus"
            :color="'secondary'"
            :isClick="invalidStatusClick"
            @click="clickFilter('invalid')"
          ></base-select-card>
        </div>
        <div class="lg:col-2 md:col-6 col-12 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.error"
            :content="contentErrorStatus"
            :color="'error'"
            :isClick="errorStatusClick"
            @click="clickFilter('error')"
          ></base-select-card>
        </div>
        <div class="lg:col-2 md:col-6 col-12 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.success"
            :content="contentSuccessStatus"
            :color="'success'"
            :isClick="successStatusClick"
            @click="clickFilter('success')"
          ></base-select-card>
        </div>
      </div>
      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="previewDatas"
          :paginator="true"
          class="p-datatable-sm"
          :class="textTableClass"
          :rows="rowsPerPage"
          dataKey="id"
          :rowHover="true"
          :loading="isLoading"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 50, 100]"
          :currentPageReportTemplate="
            lang.page + ` {currentPage} / {totalPages}`
          "
          responsiveLayout="scroll"
        >
          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
          <PvColumn
            field="itemNo"
            :header="lang.itemNo"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="importStatusCode"
            :header="lang.statusImport"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <base-tag
                :Caption="
                  setBaseTagStatusImport(slotProps.data.importStatusCode)
                    .caption
                "
                :Class="
                  setBaseTagStatusImport(slotProps.data.importStatusCode).class
                "
              />
            </template>
          </PvColumn>
          <PvColumn
            field="importStatusReason"
            :header="lang.reason"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="code"
            :header="lang.labelCode"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <span
                :class="{
                  'p-error':
                    slotProps.data.importStatusDescription === 'code',
                }"
              >
                {{ slotProps.data.code }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            field="name"
            :header="lang.labelName"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <span
                :class="{
                  'p-error':
                    slotProps.data.importStatusDescription === 'name',
                }"
              >
                {{ slotProps.data.name }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            field="taxId"
            :header="lang.labelTaxId"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <span
                :class="{
                  'p-error':
                    slotProps.data.importStatusDescription === 'taxId',
                }"
              >
                {{ slotProps.data.taxId }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            style="min-width: 3rem"
            headerClass="h-center font-normal"
            bodyStyle="text-align: center;"
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="openPreviewDetailDialog(slotProps.data)"
              >
                <div v-if="slotProps.data.error.length > 0" v-badge.danger>
                  <icon-eye class="p-button-profile" iconColor="secondary" width="20" height="20" />
                </div>
                <div v-else>
                  <icon-eye class="p-button-profile" iconColor="secondary" width="20" height="20" />
                </div>
              </PvButton>
            </template>
          </PvColumn>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>
<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import BaseDialog from "../../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import ImportSupplierDialog from "../../../components/master/supplier/ImportSupplierDialog.vue";
import PreviewSupplierDetailDialog from '../../../components/master/supplier/PreviewSupplierDetailDialog.vue';
import useExportExcel from "../../../hooks/exportExcel.js";
export default {
  components: {
    ImportSupplierDialog,
    PreviewSupplierDetailDialog,
  },
  props: ["projectCode"],
  setup() {
    //use
    const router = useRouter();
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const { getRoleByMenuByCode } = useGetRoleByMenu();
    const { generateFile } = useExportExcel();

    //variable
    //Table
    let isLoading = ref(false);
    let selectedPage = ref(1);
    let rowsPerPage = ref(10);
    //base-select-card
    let allStatusClick = ref(true);
    let waitStatusClick = ref(false);
    let invalidStatusClick = ref(false);
    let errorStatusClick = ref(false);
    let successStatusClick = ref(false);
    //Dialog
    let dataConfigDialog = ref(null);
    let showUploadSupplierDialog = ref(false);
    let showPreviewDetailDialog = ref(false);
    let keyToReLoad = ref(0);
    let selectedSupplier = ref(null);
    //ProgressBar
    let showProgressBar = ref(false);
    let valueObj = ref([]);

    //computed
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.supplier.edit,
          ...lang.th.master.supplier.import,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.supplier.edit,
          ...lang.en.master.supplier.import,
        };
      }
    });
    const roleByMenu = computed(() => {
      return getRoleByMenuByCode("tmCOR");
    });
    const isRoleInsert = computed(() => {
      return roleByMenu.value && roleByMenu.value.insert === "Y";
    });
    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const supplierDatas = computed(() => {
      return store.getters["importSupplier/importSuppliers"];
    });

    const lengthSupplier = computed(() => {
      return supplierDatas.value.length;
    });

    const filterDataByStatus = (status) => {
      if (lengthSupplier.value > 0) {
        return supplierDatas.value.filter(
          (data) => data.importStatusCode === status
        );
      }
      return [];
    };

    const dataWaitStatus = computed(() => filterDataByStatus("wait"));
    const dataInvalidStatus = computed(() => filterDataByStatus("invalid"));
    const dataErrorStatus = computed(() => filterDataByStatus("error"));
    const dataSuccessStatus = computed(() => filterDataByStatus("success"));

    const labelImportAll = computed(() => {
      let countData = 0;
      if (lengthSupplier.value > 0) {
        countData = dataWaitStatus.value.length;
      }
      return mergeLang.value.importAll.replace(":value", countData);
    });
    const contentAllStatus = computed(() =>
      mergeLang.value.statusContent.replace(":number", lengthSupplier.value)
    );
    const contentWaitStatus = computed(() =>
      mergeLang.value.statusContent.replace(
        ":number",
        dataWaitStatus.value.length
      )
    );
    const contentInvalidStatus = computed(() =>
      mergeLang.value.statusContent.replace(
        ":number",
        dataInvalidStatus.value.length
      )
    );
    const contentErrorStatus = computed(() =>
      mergeLang.value.statusContent.replace(
        ":number",
        dataErrorStatus.value.length
      )
    );
    const contentSuccessStatus = computed(() =>
      mergeLang.value.statusContent.replace(
        ":number",
        dataSuccessStatus.value.length
      )
    );
    const previewDatas = computed(() => {
      switch (true) {
        case waitStatusClick.value:
          return dataWaitStatus.value;
        case invalidStatusClick.value:
          return dataInvalidStatus.value;
        case errorStatusClick.value:
          return dataErrorStatus.value;
        case successStatusClick.value:
          return dataSuccessStatus.value;
        default:
          return supplierDatas.value;
      }
    });
    const dataExportError = computed(() => [...dataInvalidStatus.value, ...dataErrorStatus.value]);

    //function
    const initiateData = async () => {
      selectedPage.value = 1;
      rowsPerPage.value = 10;
      allStatusClick.value = true;
      waitStatusClick.value = false;
      invalidStatusClick.value = false;
      errorStatusClick.value = false;
      successStatusClick.value = false;
      dataConfigDialog.value = null;
      showProgressBar.value = false;
      valueObj.value = [];
      showPreviewDetailDialog.value = false;
      keyToReLoad.value = 0;
      selectedSupplier.value = null;
    };
    const goBack = () => {
      router.push({ name: "supplier" });
    };
    const clickFilter = (status) => {
      allStatusClick.value = false;
      waitStatusClick.value = false;
      invalidStatusClick.value = false;
      errorStatusClick.value = false;
      successStatusClick.value = false;

      if (status == "all") {
        allStatusClick.value = true;
      } else if (status == "wait") {
        waitStatusClick.value = true;
      } else if (status == "invalid") {
        invalidStatusClick.value = true;
      } else if (status == "error") {
        errorStatusClick.value = true;
      } else if (status == "success") {
        successStatusClick.value = true;
      }

      selectedPage.value = 1;
      rowsPerPage.value = 10;
    };
    const setDialog = (dataConfig) => {
      const classMap = {
        success: "p-text-success",
        error: "p-error",
      };

      dataConfig.classTitle = classMap[dataConfig.classTitle] || "";

      dataConfig.button = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "cancel",
        },
      ];

      if (dataConfig.type === "confirm") {
        dataConfig.button.push({
          id: "confirm",
          caption: mergeLang.value.buttonDialogConfirm,
          class: "w-full",
          action: "confirm",
        });
      }

      dataConfigDialog.value = {
        titleConfirm: dataConfig.title,
        classTitle: dataConfig.classTitle,
        wordingConfirm: dataConfig.message,
        dataButtons: dataConfig.button,
        display: true,
      };
    };
    const askForReUpload = async () => {
      if (lengthSupplier.value > 0) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.askForNewImport,
          type: "confirm",
        });
        const dialogResponse = await createConfirmDialog(
          BaseDialog,
          dataConfigDialog.value
        ).reveal();
        if (dialogResponse.isCanceled) {
          return;
        }
      }
      uploadSupplier();
    };
    const validateData = () => {
      if (!isRoleInsert.value) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.validateRoleInsert,
          type: "",
        });
        return true;
      } else if (dataWaitStatus.value.length == 0) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.noItemToImportWording,
          type: "",
        });
        return true;
      }
      return false;
    };
    const importSupplier = async () => {
      if (validateData()) {
        await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        return;
      }

      valueObj.value = [];
      valueObj.value = dataWaitStatus.value;
      showProgressBar.value = true;
      for (const data of dataWaitStatus.value) {
        await addSupplier(data);
      }
      showProgressBar.value = false;

      setDialog({
        classTitle: "success",
        title: mergeLang.value.saveSuccess,
        message: mergeLang.value.saveSuccess,
        type: "",
      });
      await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();

      clickFilter("all");
    };
    const addSupplier = async (data) => {
      try {
        let gender = "Company";
        if(data.gender == "ชาย"){
            gender = "Male"
        }else if(data.gender == "หญิง"){
            gender = "Female"
        }
        
        if (data.emergencyContactName && data.emergencyContactPrefix == "") {
          data.emergencyContactPrefix = "คุณ";
        }

        let payload = {
            projectId: selectedProject.value.id,
            code: data.code,
            name: data.name,
            taxId: data.taxId,
            gender: gender,
            address1: data.address1,
            address2: data.address2,
            address3: data.address3,
            postCode: data.postCode,
            telephoneNumber: data.telephoneNumber,
            mobileNumber: data.mobileNumber,
            faxNumber: data.faxNumber,
            email: data.email,
            isCustomer: "N",
            supplierCreditTerm: data.supplierCreditTerm,
            bankAccountNumber: data.bankAccountNumber,
            withholdingTaxRate: data.withholdingTaxRate,
            bank: data.bank,
            vatRate: data.vatRate,
            contactName: "",
            contactAddress1: "",
            contactAddress2: "",
            contactAddress3: "",
            dbdBusinessTypeId: data.dbdBusinessTypeId,
            addressSubDistrict: data.addressSubDistrict,
            addressDistrict: data.addressDistrict,
            emergencyContactPrefix: data.emergencyContactPrefix,
            emergencyContactName: data.emergencyContactName,
            emergencyContactMobileCountryCode : data.emergencyCountryCode,
            emergencyContactMobile: data.emergencyContactMobile,
            telephoneCountryCode: data.telephoneCountryCode,
            mobileCountryCode: data.mobileCountryCode,
            type: "Add",
            isIgnoreValidateAddress: true,
        };
        // console.log("addsupplier", payload);
        await store.dispatch("supplier/createOrUpdateSupplier", payload);

        if (supplierDatas.value) {
          data.id = supplierDatas.value.id;
          data.importStatusCode = "success";
          data.markStatus = "1";
        }
        return true;
      } catch (error) {
        data.importStatusCode = "error";
        data.importStatusReason = error.errors;
        data.markStatus = "1";
        return false;
      }
    };
    const setBaseTagStatusImport = (status) => {
      switch (status.toLowerCase()) {
        case "wait":
          return {
            caption: mergeLang.value.wait,
            class: "warning",
          };
        case "success":
          return {
            caption: mergeLang.value.success,
            class: "success",
          };
        case "invalid":
          return {
            caption: mergeLang.value.invalid,
            class: "secondary",
          };
        case "error":
          return {
            caption: mergeLang.value.error,
            class: "error",
          };
        default:
          return {
            caption: "",
            class: "",
          };
      }
    };
    const handleBeforeUnload = async (event) => {
      if (
        dataWaitStatus.value.length > 0 ||
        dataInvalidStatus.value.length > 0
      ) {
        event.preventDefault();
      }
    };
    const uploadSupplier = () => {
      showUploadSupplierDialog.value = true;
    };
    const uploadedSupplier = () => {
      showUploadSupplierDialog.value = false;
    };

    onMounted(async () => {
      initiateData;
      window.addEventListener("beforeunload", handleBeforeUnload);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    });

    onBeforeRouteLeave(async (to, from) => {
      if (
        dataWaitStatus.value.length > 0 ||
        dataInvalidStatus.value.length > 0
      ) {
        var curValue = to.params.projectCode;
        var oldValue = from.params.projectCode;
        if (curValue == oldValue) {
          setDialog({
            classTitle: "",
            title: mergeLang.value.notificationDialogHeader,
            message: mergeLang.value.pageDirtyConfirmMessage,
            type: "confirm",
          });
          const dialogResponse = await createConfirmDialog(
            BaseDialog,
            dataConfigDialog.value
          ).reveal();
          return !dialogResponse.isCanceled;
        }
      }
      return true;
    });

    const openPreviewDetailDialog = async (data) => {
      // console.log("openPreviewDetailDialog", data);
      keyToReLoad.value++;
      selectedSupplier.value = data;
      showPreviewDetailDialog.value = true;
    };

    const exportErrorExcel = async () => {
      if (dataExportError.value.length > 0) {
        const columnName = [
          {
            field: "code",
            name: "รหัสผู้จัดจำหน่าย"
          },
          {
            field: "name",
            name: "ชื่อผู้จัดจำหน่าย"
          },
          {
            field: "taxId",
            name: "เลขผู้เสียภาษี/บัตรประชาชน"
          },
          {
            field: "gender",
            name: "ประเภทผู้จัดจำหน่าย"
          },
          {
            field: "dbdBusinessType",
            name: "ประเภทดำเนินธุรกิจ"
          },
          {
            field: "address1",
            name: "ที่อยู่"
          },
          {
            field: "addressSubDistrict",
            name: "แขวง/ตำบล"
          },
          {
            field: "addressDistrict",
            name: "เขต/อำเภอ"
          },
          {
            field: "address3",
            name: "จังหวัด"
          },
          {
            field: "postCode",
            name: "รหัสไปรษณีย์"
          },
          {
            field: "telephoneCountryCode",
            name: "รหัสประเทศ"
          },
          {
            field: "telephoneNumber",
            name: "เบอร์โทรศัพท์"
          },
          {
            field: "mobileCountryCode",
            name: "รหัสประเทศ"
          },
          {
            field: "mobileNumber",
            name: "เบอร์มือถือ"
          },
          {
            field: "faxNumber",
            name: "โทรสาร"
          },
          {
            field: "email",
            name: "อีเมล"
          },
          {
            field: "emergencyContactPrefix",
            name: "คำนำหน้า"
          },
          {
            field: "emergencyContactName",
            name: "ชื่อ - ผู้ติดต่อ"
          },
          {
            field: "emergencyCountryCode",
            name: "รหัสประเทศ - ผู้ติดต่อ"
          },
          {
            field: "emergencyContactMobile",
            name: "เบอร์โทรศัพท์- ผู้ติดต่อ"
          },
          {
            field: "supplierCreditTerm",
            name: "จำนวนวันเครดิต"
          },
          {
            field: "bankAccountNumber",
            name: "เลขที่บัญชี"
          },
          {
            field: "withholdingTaxRate",
            name: "ภาษีหัก ณ ที่จ่าย %"
          },
          {
            field: "bank",
            name: "ธนาคาร"
          },
          {
            field: "vatRate",
            name: "ภาษีซื้อ %"
          },
          {
            field: "error",
            name: "เหตุผล",
          },
        ];
        generateFile(dataExportError.value, columnName, "supplier_error", mergeLang.value.createTitle, 2);
      } else {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.noItemToExportWording,
          type: "",
        });
        await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
      }
    };

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatDate,
      formatDateToISOString,

      //variable
      isLoading,
      selectedPage,
      rowsPerPage,

      allStatusClick,
      waitStatusClick,
      invalidStatusClick,
      errorStatusClick,
      successStatusClick,

      showProgressBar,
      valueObj,
      showUploadSupplierDialog,
      showPreviewDetailDialog,
      keyToReLoad,
      selectedSupplier,

      //computeds
      labelImportAll,
      contentAllStatus,
      contentWaitStatus,
      contentInvalidStatus,
      contentErrorStatus,
      contentSuccessStatus,
      previewDatas,

      //method
      goBack,
      clickFilter,
      askForReUpload,
      importSupplier,
      setBaseTagStatusImport,
      uploadedSupplier,
      openPreviewDetailDialog,
      exportErrorExcel,
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-right: auto;
  }
}
::v-deep(.p-overlay-badge) {
  .p-badge {
    transform: translate(0%, 30%) !important;
  }
}

::v-deep(.p-badge) {
  padding: 0 0rem;
}
::v-deep(.p-button) {
  .p-badge {
    min-width: 7px !important;
    height: 7px !important;
  }
}
</style>
