import { jitsuClient } from "@jitsu/sdk-js";

export default function useTracking() {
  const shouldSendTracking = process.env.VUE_APP_SHOULD_SEND_TRACKING;

  const jitsu = jitsuClient({
    key: "js.i1zjx97g1e7yfebx6vspgn.5ysmcmlzytuwwz2oeqg96",
    tracking_host: "https://trk.thelivingos.com",
  });

  const trackingSetUser = (userData, doNotSendEvent = false) => {
    if (shouldSendTracking === 'true') {
      try {
        jitsu.id(userData, doNotSendEvent);
      } catch (error) {
        console.log("track id error : ", error);
      }
    }
  };

  const trackingSend = (eventName, data) => {
    if (shouldSendTracking === 'true') {
    //   console.log("trackingSend");
      try {
        jitsu.track(eventName, { ...data });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const trackingSetDefault = () => {
    if (shouldSendTracking === 'true') {
      const deviceInfo = {
        app: "pms-web",
        app_environemnt: process.env.VUE_APP_ENV,
        template_version: 1,
      };
      try {
        jitsu.set({ deviceInfo: deviceInfo }, { persist: true });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const addReportTracking = function (payload, reportObj, selectedProject, routeName) {
    // console.log("payload=", payload);
    // console.log("reportObj=", reportObj);
    // console.log("selectedProject=", selectedProject);
    // console.log("routeName=", routeName);
    let docType = "pdf";
    if(payload.DocType.toUpperCase() == "JASPEREXCEL"){
        docType = "excel";
    }else if(payload.DocType.toUpperCase() == "EXCEL"){
        docType = "raw";
    }else if(payload.DocType.toUpperCase() == "TEXT"){
        docType = "txt";
    }

    const trackingProject = {
      id: selectedProject.id,
      code: selectedProject.code,
    };

    let report_download = {
        jasper_path: payload.JasperPath, 
        report_download_type: docType,
        menu_group_th: reportObj.menuGroupTh,
        menu_group_en: reportObj.menuGroupEn,
        report_name_th: reportObj.nameThai,
        report_name_en: reportObj.nameEnglish,
    }
    
    let trackingData = {
        current_screen: routeName,
        current_project: trackingProject,
        button_data: {report_download},
    }    
    // console.log("trackingData=", trackingData); 
    trackingSend("track_current_screen", trackingData);
  }

  return {
    trackingSetUser,
    trackingSend,
    trackingSetDefault,
    addReportTracking,
  };
}
