import { useStore } from "vuex";
import { ref, computed, onMounted} from "vue";
export default function useGetCustomer() {
  const store = useStore();
  let searchText = ref(null);

  const customerGroups = computed(() => {
    return store.getters["shared/customerCustomerGroups"];
  });

  const customerGroupIsLastPage = computed(() => {
    return store.getters["shared/customerGroupIsLastPage"];
  });

  const filteredCustomerGroups = computed(() => {
    let dataCgs = customerGroups.value.filter((e) => {return (e.activeStatus.substring(0, 1).toLowerCase() == "y")});
    if (!searchText.value) {
      //ช่องว่าง
      if (dataCgs) {
        const result = [...dataCgs];
        return result;
      } else {
        return null;
      }
    } else {
      return dataCgs.filter((cgp) => {
        return cgp.name
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const searchCustomerGroup = function (event) {
    // console.log(event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchText.value = "";
      searchText.value = null;
    } else {
      searchText.value = event.query;
    }
  };

  let isSharedCustomerGroupReady = ref(false);

  const getAllCustomerGroups = async function () {
    if (!customerGroupIsLastPage.value) {
      await store.dispatch("shared/getAllCustomerGroups");
    }
    isSharedCustomerGroupReady.value = true;
  };

  onMounted(async () => {
    await getAllCustomerGroups();
  });


  return {
    searchCustomerGroup,
    filteredCustomerGroups,
    searchText,
    isSharedCustomerGroupReady,
    getAllCustomerGroups,
  };
}
