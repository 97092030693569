<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '640px': '80vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <span class="font-lg">{{ lang.detailTitle }}</span>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <section class="flex flex-wrap pt-0" :class="textFormClass">
      <!-- ข้อมูลทั่วไป -->
      <div class="flex flex-wrap lg:col-12 md:col-12 col-12 p-0">
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('code') }"
          >
            {{ lang.code }}
          </label>
          <div class="mt-2">
            <label
              for="code"
              class="font-md"
              :class="{ 'p-text-error': checkError('code') }"
            >
              {{ asset.code }}</label
            >
          </div>
        </div>
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('model') }"
          >
            {{ lang.ownership }}
          </label>
          <div class="mt-2">
            <label
              for="model"
              class="font-md"
              :class="{ 'p-text-error': checkError('model') }"
            >
              {{ asset.model }}</label
            >
          </div>
        </div>
        <div class="lg:col-4 md:col-4 col-0 p-0 pt-3"></div>
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('name') }"
          >
            {{ lang.nameTh }}
          </label>
          <div class="mt-2">
            <label
              for="name"
              class="font-md"
              :class="{ 'p-text-error': checkError('name') }"
            >
              {{ asset.name }}</label
            >
          </div>
        </div>

        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('nameEn') }"
          >
            {{ lang.nameEn }}
          </label>
          <div class="mt-2">
            <label
              for="nameEn"
              class="font-md"
              :class="{ 'p-text-error': checkError('nameEn') }"
            >
              {{ asset.nameEn }}</label
            >
          </div>
        </div>
        <div class="lg:col-4 md:col-4 col-0 p-0 pt-3"></div>
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('assetAccountCode') }"
          >
            {{ lang.assetAccount }}
          </label>
          <div class="mt-2">
            <label
              for="assetAccountCode"
              class="font-md"
              :class="{ 'p-text-error': checkError('assetAccountCode') }"
            >
              {{ asset.assetAccountCode }}</label
            >
          </div>
        </div>

        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('depreAccountCode') }"
          >
            {{ lang.depreciationAccount }}
          </label>
          <div class="mt-2">
            <label
              for="depreAccountCode"
              class="font-md"
              :class="{ 'p-text-error': checkError('depreAccountCode') }"
            >
              {{ asset.depreAccountCode }}</label
            >
          </div>
        </div>

        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('accumAccountCode') }"
          >
            {{ lang.accumAccount }}
          </label>
          <div class="mt-2">
            <label
              for="accumAccountCode"
              class="font-md"
              :class="{ 'p-text-error': checkError('accumAccountCode') }"
            >
              {{ asset.accumAccountCode }}</label
            >
          </div>
        </div>
      </div>

      <!-- ข้อมูลการซื้อ -->
      <div class="flex flex-wrap lg:col-12 md:col-12 col-12 p-0 pt-4">
        <div class="lg:col-12 md:col-12 col-12 p-0">
          <span class="font-lg">{{ lang.purchaseInformation }}</span>
        </div>
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('taxInvoiceCode') }"
          >
            {{ lang.voucherCode }}
          </label>
          <div class="mt-2">
            <label
              for="taxInvoiceCode"
              class="font-md"
              :class="{ 'p-text-error': checkError('taxInvoiceCode') }"
            >
              {{ asset.taxInvoiceCode }}</label
            >
          </div>
        </div>
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('date') }"
          >
            {{ lang.buyDate }}
          </label>
          <div class="mt-2">
            <label
              for="buyDate"
              class="font-md"
              :class="{ 'p-text-error': checkError('buyDate') }"
            >
              {{
                !checkError("buyDate")
                  ? formatDate(asset.buyDate)
                  : asset.buyDate
              }}
            </label>
          </div>
        </div>
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('buyPrice') }"
          >
            {{ lang.buyPrice }}
          </label>
          <div class="mt-2">
            <label
              for="buyPrice"
              class="font-md"
              :class="{ 'p-text-error': checkError('buyPrice') }"
              >{{
                !checkError("buyPrice")
                  ? formatCurrency(asset.buyPrice)
                  : asset.buyPrice
              }}</label
            >
          </div>
        </div>
      </div>

      <!-- กำหนดการคิดค่าเสื่อมราคา -->
      <div class="flex flex-wrap lg:col-12 md:col-12 col-12 p-0 pt-4">
        <div class="lg:col-12 md:col-12 col-12 p-0">
          <span class="font-lg">{{ lang.depreciationSchedule }}</span>
        </div>
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('deprePrice') }"
          >
            {{ lang.calculateValue }}
          </label>
          <div class="mt-2">
            <label
              for="deprePrice"
              class="font-md"
              :class="{ 'p-text-error': checkError('deprePrice') }"
              >{{
                !checkError("deprePrice")
                  ? formatCurrency(asset.deprePrice)
                  : asset.deprePrice
              }}</label
            >
          </div>
        </div>
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('marketPrice') }"
          >
            {{ lang.marketPrice }}
          </label>
          <div class="mt-2">
            <label
              for="marketPrice"
              class="font-md"
              :class="{ 'p-text-error': checkError('marketPrice') }"
              >{{
                !checkError("marketPrice")
                  ? formatCurrency(asset.marketPrice)
                  : asset.marketPrice
              }}</label
            >
          </div>
        </div>
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('otherCost') }"
          >
            {{ lang.otherCost }}
          </label>
          <div class="mt-2">
            <label
              for="otherCost"
              class="font-md"
              :class="{ 'p-text-error': checkError('otherCost') }"
              >{{
                !checkError("otherCost")
                  ? formatCurrency(asset.otherCost)
                  : asset.otherCost
              }}</label
            >
          </div>
        </div>
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('depreCalDate') }"
          >
            {{ lang.depreciationDate }}
          </label>
          <div class="mt-2">
            <label
              for="depreCalDate"
              class="font-md"
              :class="{ 'p-text-error': checkError('depreCalDate') }"
            >
              {{
                !checkError("depreCalDate")
                  ? formatDate(asset.depreCalDate)
                  : asset.depreCalDate
              }}
            </label>
          </div>
        </div>
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('depreRate') }"
          >
            {{ lang.depreciationRate }}
          </label>
          <div class="mt-2">
            <label
              for="depreRate"
              class="font-md"
              :class="{ 'p-text-error': checkError('depreRate') }"
              >{{
                !checkError("depreRate")
                  ? formatCurrency(asset.depreRate)
                  : asset.depreRate
              }}</label
            >
          </div>
        </div>
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('beforeDepre') }"
          >
            {{ lang.accumDepreciationBeforeCal }}
          </label>
          <div class="mt-2">
            <label
              for="beforeDepre"
              class="font-md"
              :class="{ 'p-text-error': checkError('beforeDepre') }"
              >{{
                !checkError("otherCost")
                  ? formatCurrency(asset.beforeDepre)
                  : asset.beforeDepre
              }}</label
            >
          </div>
        </div>
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('depreciationFormula') }"
          >
            {{ lang.depreciationFormula }}
          </label>
          <div class="mt-2">
            <label
              for="depreciationFormula"
              class="font-md"
              :class="{ 'p-text-error': checkError('depreciationFormula') }"
            >
              {{ asset.depreciationFormula }}
            </label>
          </div>
        </div>
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('depreciationPeriod') }"
          >
            {{ lang.depreciationPeriod }}
          </label>
          <div class="mt-2">
            <label
              for="depreciationPeriod"
              class="font-md"
              :class="{ 'p-text-error': checkError('depreciationPeriod') }"
            >
              {{ asset.depreciationPeriod }}
            </label>
          </div>
        </div>
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('status') }"
          >
            {{ lang.status }}
          </label>
          <div class="mt-2">
            <label
              for="status"
              class="font-md"
              :class="{ 'p-text-error': checkError('status') }"
            >
              {{ asset.status }}
            </label>
          </div>
        </div>
      </div>

      <!-- การตัดสินทรัพย์ -->
      <div class="flex flex-wrap lg:col-12 md:col-12 col-12 p-0 pt-4">
        <div class="lg:col-12 md:col-12 col-12 p-0">
          <span class="font-lg">{{ lang.cutOffAsset }}</span>
        </div>
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('depreRemain') }"
          >
            {{ lang.depreciationRemain }}
          </label>
          <div class="mt-2">
            <label
              for="depreRemain"
              class="font-md"
              :class="{ 'p-text-error': checkError('depreRemain') }"
              >{{
                !checkError("otherCost")
                  ? formatCurrency(asset.depreRemain)
                  : asset.depreRemain
              }}</label
            >
          </div>
        </div>
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('saleRefer') }"
          >
            {{ lang.saleReference }}
          </label>
          <div class="mt-2">
            <label
              for="saleRefer"
              class="font-md"
              :class="{ 'p-text-error': checkError('saleRefer') }"
            >
              {{ asset.saleRefer }}
            </label>
          </div>
        </div>
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('saleDate') }"
          >
            {{ lang.cutOffAssetDate }}
          </label>
          <div class="mt-2">
            <label
              for="saleDate"
              class="font-md"
              :class="{ 'p-text-error': checkError('saleDate') }"
            >
              {{
                !checkError("saleDate")
                  ? asset.saleDate ? formatDate(asset.saleDate) : asset.saleDate
                  : asset.saleDate
              }}
            </label>
          </div>
        </div>
        <div class="lg:col-4 md:col-4 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('salePrice') }"
          >
            {{ lang.salePrice }}
          </label>
          <div class="mt-2">
            <label
              for="beforeDepre"
              class="font-md"
              :class="{ 'p-text-error': checkError('salePrice') }"
              >{{
                !checkError("salePrice")
                  ? formatCurrency(asset.salePrice)
                  : asset.salePrice
              }}</label
            >
          </div>
        </div>
      </div>
    </section>

    <template #footer>
      <PvButton
        :label="lang.close"
        class="p-button-outlined p-button-secondary w-3"
        @click="closeDialog"
      />
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  emits: ["close"],
  props: {
    selectedAsset: {
      type: Object,
    },
  },
  setup(props, context) {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const { formatDate, formatCurrency } = useFormat();
    const store = useStore();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.asset.assetRegister.edit,
          ...lang.th.validateImport,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.asset.assetRegister.edit,
          ...lang.en.validateImport,
        };
      }
    });

    //computeds
    const asset = computed(() => {
      return props.selectedAsset;
    });
    const errorLength = computed(() => {
      return props.selectedAsset.error.length;
    });

    //methods
    const closeDialog = () => {
      context.emit("close");
    };
    const checkError = (field) => {
      if (errorLength.value > 0) {
        let error = asset.value.error.find((error) => error.field == field);
        if (error) {
          return true;
        }
      }
      return false;
    };

    return {
      lang: mergeLang,
      textFormClass,
      formatDate,
      formatCurrency,

      //computeds
      asset,

      //methods
      closeDialog,
      checkError,
    };
  },
};
</script>
