export default {
  unitUnits(state) {
    return state.unit.units;
  },
  unitCurrentPage(state) {
    return state.unit.currentPage;
  },
  unitNextPage(state) {
    return state.unit.currentPage + 1;
  },
  unitTotalPage(state) {
    return state.unit.totalPage;
  },
  unitIsLastPage(_, getters) {
    return getters.unitCurrentPage === getters.unitTotalPage && getters.unitTotalPage !== 0;
  }
};
