import { useStore } from "vuex";
import { ref, computed, onMounted} from "vue";
export default function useGetDocumentBookDistinct() {
  const store = useStore();
  let searchText = ref(null);

  const documentBookDistincts = computed(() => {
    return store.getters["shared/documentBookDistinctData"];
  });

  const documentBookDistinctIsLastPage = computed(() => {
    return store.getters["shared/documentBookDistinctIsLastPage"];
  });

  const filteredDocumentBookDistincts = computed(() => {
    if (!searchText.value) {
      //ช่องว่าง
      const result = [...documentBookDistincts.value];
      return result;
    } else {
      return documentBookDistincts.value.filter((documentBookDistinct) => {
        return documentBookDistinct.searchText
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const searchDocumentBookDistinct = function (event) {
    // console.log(event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchText.value = "";
      searchText.value = null;
    } else {
      searchText.value = event.query;
    }
  };

  let isSharedDocumentBookDistinctReady = ref(false);

  const getAllDocumentBookDistincts = async function () {
    if (!documentBookDistinctIsLastPage.value) {
      await store.dispatch("shared/getAllDocumentBookDistincts");
    }
    isSharedDocumentBookDistinctReady.value = true;
  };

  const getDocumentBookDistinctByCode = function (code) {
    return documentBookDistincts.value.find((documentBookDistinct) => {
      return documentBookDistinct.code.toLowerCase() === code.trim().toLowerCase();
    });
  };

  onMounted(async () => {
    await getAllDocumentBookDistincts();
  });


  return {
    searchDocumentBookDistinct,
    filteredDocumentBookDistincts,
    searchText,
    getDocumentBookDistinctByCode,
    isSharedDocumentBookDistinctReady,
  };
}
