import { useStore } from "vuex";
import { ref, computed, onMounted} from "vue";
export default function useGetProductAR() {
  const store = useStore();
  let searchText = ref(null);
  let filterType = ref(null);

  const products = computed(() => {
    return store.getters["shared/productArProducts"];
  });

  const ignorePenaltyProducts = computed(() => {
    return products.value.filter((product) => {
      return product.code.charAt(0).toLowerCase() !== "z";
    });
  });

  const penaltyProducts = computed(() => {
    return products.value.filter((product) => {
      return product.code.charAt(0).toLowerCase() === "z";
    });
  });

  const keycardProducts = computed(() => {
    return products.value.filter((product) => {
      return product.code.substring(0,2).toLowerCase() === "kc";
    });
  });

  const productIsLastPage = computed(() => {
    return store.getters["shared/productArIsLastPage"];
  });

  const filteredProducts = computed(() => {
    if (!searchText.value) {
      //ช่องว่าง
      const result = [...products.value];
      return result;
    } else {
      return products.value.filter((product) => {
        return product.searchText
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const filteredIgnorePenaltyProducts = computed(() => {
    if (!searchText.value) {
      //ช่องว่าง
      const result = [...ignorePenaltyProducts.value];
      return result;
    } else {
      return ignorePenaltyProducts.value.filter((product) => {
        return product.searchText
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const filteredPenaltyProducts = computed(() => {
    if (!searchText.value) {
      //ช่องว่าง
      const result = [...penaltyProducts.value];
      return result;
    } else {
      return penaltyProducts.value.filter((product) => {
        return product.searchText
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const filteredKeycardProducts = computed(() => {
    if (!searchText.value) {
      //ช่องว่าง
      const result = [...keycardProducts.value];
      return result;
    } else {
      return keycardProducts.value.filter((product) => {
        return product.searchText
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const searchProduct = function (event) {
    // console.log(event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchText.value = "";
      searchText.value = null;
    } else {
      searchText.value = event.query;
    }
  };

  let isSharedProductReady = ref(false);

  const getAllProducts = async function (prop) {
    filterType.value = prop;
    if (!productIsLastPage.value) {
      await store.dispatch("shared/getAllProductARs");
    }
    isSharedProductReady.value = true;
  };

  const getProductByCode = function (code) {
    return products.value.find((product) => {
      return product.code.toLowerCase() === code.trim().toLowerCase();
    });
  };

  const refreshProduct = async () => {
    isSharedProductReady.value = false;
    await store.dispatch("shared/resetProductAR");
    await getAllProducts();
  };

  onMounted(async () => {
    await getAllProducts();
  });


  return {
    searchProduct,
    filteredProducts,
    searchText,
    getProductByCode,
    isSharedProductReady,
    getAllProducts,
    filteredIgnorePenaltyProducts,
    filteredPenaltyProducts,
    filteredKeycardProducts,
    refreshProduct,
  };
}
