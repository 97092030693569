export default {
  async getJobAdmins(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/JobAdmin";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get job admin");
      throw error;
    }
    // console.log("jobAdmin",responseData);

    // const data = responseData.data;

    const jobAdmin = {
      jobAdmins: responseData.data,
      totalPage: responseData.page.pageCount == 0 ? 1 : responseData.page.pageCount,
      currentPage: responseData.page.currentPage,
    };
    context.commit("setJobAdmin", jobAdmin);
  },
  async getAllJobAdmins(context) {
    // console.log('context',context);
      while (!context.getters.jobAdminIsLastPage) {
        let where = {
          projectId: context.rootGetters.selectedProject.id,
        };
        const payload = {
          page: context.getters.jobAdminNextPage || 1,
          where,
        };
        await context.dispatch("getJobAdmins", payload);
      }
  },
  resetJobAdmin(context) {
    context.commit("resetJobAdmin");
  },
};
