export default {
	titleBack: "Edit Policy",
	title: "Detail Policy",
	titleDetail: "Product List",
	deletePolicy: "Delete Policy",
	createPolicy: "Create Policy",
	addNewPolicyDetail: "Add Policy Detail",
	addProductToPolicyDetail: "Create Policy Detail",
	emptyPolicy: "Policy not found",

	createNewPolicy: "Create New Policy",
	askForCreateNewPolicy: "Are you sure you want to discard change",
	askForCreateNewPolicyConfirm: "Are you create new document?",

	policyCodeLabel: "Policy Code",
	policyCodePlaceholder: "Code",
	policyNameLabel: "Policy Name",
	policyNamePlaceholder: "Name",
	productCodeLabel: "Product Code",
	policyPeriodLabel: "Period",
	detailRemarkLabel: "Remark",

	columnItemNo: "No",
	columnCode: "Product Code",
	columnName: "Product Name",
	columnPeriod: "Period",
	columnRemark: "Remark",
	columnAction: "",

	askForDeletePolicy: "Do you want to Delete policy {policyCodeAndName}?",
	dialogPolicyTitle: "Add/ Edit Policy Detail",

	dialogDuplicateTitle: "Save failed",
	dialogDuplicateMassage:
		"You save failed becuase of duplicate policy : {policyCodeName} (ItemNo: {itemNo} )",
	
	validateCode: "Please enter code",
	validateName: "Please enter name",
	titleCreate: "Create Policy",
	titleEdit: "Edit Policy",
	validateProduct: "Please select again.",
	validatePeriod: "Please select again.",
};
