export default {
  title: "Receipt visitor control",
  labelSearchText:"Document code.",
  searchTextPlaceholder: "Enter the document code to search.",
  dropdownCash: "Cash",
  dropdownTransfer: "Transfer",
  dropdownCheque: "Cheque",
  dropdownCreditCard: "Credit card",
  tableEmpty: "Empty data",
  documentCode: "Document Code",
  date: "Document Date",
  unit: "Unit",
  address:"Address no.",
  customerName: "Customer Name",
  price: "Price",
	validateRoleSelect: "You don't have permission to select document.",
};
