import { useStore } from "vuex";
import { computed, ref } from 'vue';
import BaseDialog from "../components/UI/BaseDialog.vue";
import { createConfirmDialog } from 'vuejs-confirm-dialog';
import useLang from "../hooks/lang.js";
import useFormat from "./format";
export default function useReportRequestValidate() {
  const store = useStore();

  const { lang } = useLang();

  const { removeSpecialCharacter } = useFormat();

  //computed
  const mergeLang = computed(function () {
    if (store.getters.selectedLanguage === "TH") {
      return { ...lang.th.base };
    } else {
      return { ...lang.en.base };
    }
  });

  const setLoading = (bool) => {
    store.dispatch("setLoading", { value: bool });
  }

  let reportRequestTitle = ref(mergeLang.value.notificationDialogHeader);
  let reportRequestMessage = ref(mergeLang.value.reportRequestConfirmMessage.split("|"));

  const confirmDialog = createConfirmDialog(BaseDialog,
    {
      titleConfirm: reportRequestTitle.value,
      wordingConfirm: reportRequestMessage.value,
      dataButtons: [{
        id: "close",
        caption: mergeLang.value.close,
        class: "p-button-outlined p-button-secondary w-full",
        action: "cancel",
      },
      {
        id: "confirm",
        caption: mergeLang.value.buttonDialogConfirm,
        class: "w-full",
        action: "confirm",
      }],
      display: true
    });

  const reportPrintOrder = async (payloadArrayOrObject) => {
    let payloadArray = [];
    if (Array.isArray(payloadArrayOrObject) && payloadArrayOrObject.length > 0) {
      payloadArray = [...payloadArray, ...payloadArrayOrObject];
    } else {
      payloadArray.push(payloadArrayOrObject);
    }

    let isIgnoreDuplicateRequest = "N";
    for (let i = 0; i < payloadArray.length; i++) {
      let request = {
        request: JSON.stringify(payloadArray[i]),
        isIgnoreDuplicateRequest: isIgnoreDuplicateRequest
      };
      // console.log(request);
      const isPostgres = payloadArray[i].JasperPath.indexOf("postgres");
      const response = await producesPrintOrder(isPostgres, request); 
      if (response.code == "105") {
        setLoading(false);
        const answer = await confirmDialog.reveal();
        if (!answer.isCanceled) {
          isIgnoreDuplicateRequest = "Y"
          request = { ...request, ...{ isIgnoreDuplicateRequest: isIgnoreDuplicateRequest } };
          const retryResponse = await producesPrintOrder(isPostgres, request);
          if (retryResponse.code != "000") {
            throw retryResponse;
          }
        } else {
          return false;
        }
      } else if (response.code != "000") {
        throw response;
      }
    }
    return true;

  }

  const producesPrintOrder = async (isPostgres, request) => {
    let response = null;
    if(isPostgres < 0){
      response = await store.dispatch("produces/addPrintOrder", request);
    }else{
      response = await store.dispatch("produces/addPrintOrderPostgres", request); 
    }
    return response;
  }

  const noticePrintOrder = async (payload) => {
    try {
      await store.dispatch("produces/addNoticePrintOrder", payload);
      return true;
    } catch (error) {
      setLoading(false);
      if (error.code == "105") {
        const answer = await confirmDialog.reveal();
        if (!answer.isCanceled) {
          payload = { ...payload, isIgnoreDuplicateRequest: "Y" }
          await store.dispatch("produces/addNoticePrintOrder", payload);
          return true;
        } else {
          return false;
        }
      }
    }
  }

  const invoicePrintOrderChanges = async (payload) => {
    try {
      await store.dispatch("produces/addInvoicePrintOrderChanges", payload);
      return true;
    } catch (error) {
      setLoading(false);
      if (error.code == "105") {
        const answer = await confirmDialog.reveal();
        if (!answer.isCanceled) {
          payload = { ...payload, isIgnoreDuplicateRequest: "Y" }
          await store.dispatch("produces/addInvoicePrintOrderChanges", payload);
          return true;
        } else {
          return false;
        }
      }
    }
  }

  const receiptPrintOrder = async (payload) => {
    try {
      await store.dispatch("produces/addReceiptPrintOrder", payload);
      return true;
    } catch (error) {
      setLoading(false);
      if (error.code == "105") {
        const answer = await confirmDialog.reveal();
        if (!answer.isCanceled) {
          payload = { ...payload, isIgnoreDuplicateRequest: "Y" }
          await store.dispatch("produces/addReceiptPrintOrder", payload);
          return true;
        } else {
          return false;
        }
      }
    }
  }

  const withholdingTaxPrintOrder = async (payload) => {
    try {
      await store.dispatch("produces/reportWithholdingTaxPrintOrder", payload);
      return true;
    } catch (error) {
      setLoading(false);
      if (error.code == "105") {
        const answer = await confirmDialog.reveal();
        if (!answer.isCanceled) {
          payload = { ...payload, isIgnoreDuplicateRequest: "Y" }
          await store.dispatch("produces/reportWithholdingTaxPrintOrder", payload);
          return true;
        } else {
          return false;
        }
      }
    }
  }

  /**
   * Split payload by unit range.
   *
   * @param payload Report payload object.
   * @param units units array
   * @param unitCodeStart unit code start like "A-0101"
   * @param unitCodeEnd unit code end like "Z-0909"
   * @param fileName output file name
   * @param fileType output file type
   * @param unitSplitRange the range of split payload base on units
   * @param unitCodeStartKey key of payload object for unitCodeStart varies by report
   * @param unitCodeEndKey key of payload object for unitCodeEnd varies by report
   * @param fileNameKey key of payload object for filename varies by report
   * @returns Rounded number.
   */
  const splitPayloadByUnitCode = function (payload = {}, units = [], unitCodeStart = "", unitCodeEnd = "", fileName = "default", fileType = ".pdf", unitSplitRange = 400, unitCodeStartKey = "UnitCodeStart", unitCodeEndKey = "UnitCodeEnd", fileNameKey = "FileName") {
    let payloadArray = []

    if (!units && units.length === 0) {
      throw new Error('Please provide units array');
    }

    let indexStart = this.units.map((item) => item.code).indexOf(unitCodeStart);
    let indexEnd = this.units.map((item) => item.code).indexOf(unitCodeEnd);

    if (indexStart === -1) {
      indexStart = 0;
      unitCodeStart = units[indexStart].code;
    }

    if (indexEnd === -1) {
      indexEnd = units.length - 1;
      unitCodeEnd = units[indexEnd].code;
    }

    const rangeSize = (indexEnd - indexStart) + 1;

    if (rangeSize <= unitSplitRange) {
      const mapPayload = {
        [unitCodeStartKey]: unitCodeStart,
        [unitCodeEndKey]: unitCodeEnd,
        [fileNameKey]: fileName + fileType
      }
      payload = { ...payload, ...mapPayload }

      payloadArray.push(payload)
    } else {

      let currentIndexStart = indexStart;
      let currentIndexEnd = indexStart + unitSplitRange;

      while (currentIndexStart < rangeSize) {
        let newFileName = `${fileName}_${units[currentIndexStart].code}_${units[currentIndexEnd].code}${fileType}`;

        const mapPayload = {
          [unitCodeStartKey]: units[currentIndexStart].code,
          [unitCodeEndKey]: units[currentIndexEnd].code,
          [fileNameKey]: removeSpecialCharacter(newFileName)
        }

        payload = { ...payload, ...mapPayload };

        payloadArray.push(payload)

        currentIndexStart = currentIndexEnd + 1;
        if (rangeSize - currentIndexEnd > unitSplitRange) {
          currentIndexEnd = currentIndexEnd + unitSplitRange;
        } else {
          currentIndexEnd = rangeSize - 1;
        }
      }
    }
    return payloadArray;
  }

  return {
    reportRequestTitle,
    reportRequestMessage,
    reportPrintOrder,
    noticePrintOrder,
    invoicePrintOrderChanges,
    receiptPrintOrder,
    withholdingTaxPrintOrder,
    splitPayloadByUnitCode
  };
}