export default {
  jobAdminJobAdmins(state) {
    return state.jobAdmin.jobAdmins;
  },
  jobAdminCurrentPage(state) {
    return state.jobAdmin.currentPage;
  },
  jobAdminNextPage(state) {
    return state.jobAdmin.currentPage + 1;
  },
  jobAdminTotalPage(state) {
    return state.jobAdmin.totalPage;
  },
  jobAdminIsLastPage(_, getters) {
    return getters.jobAdminCurrentPage === getters.jobAdminTotalPage && getters.jobAdminTotalPage !== 0;
  }
};
