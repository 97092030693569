<template>
    <base-toolbar 
      :title="lang.titleUsePrepaid" 
      @goBack="goBack"
      :hideBackButton="isHideBack"
    ></base-toolbar>
    <base-container>
        <div class="surface-card shadow-2 p-5 border-round mt-5">
            <use-prepaid-and-deposit-view :page="'page'"></use-prepaid-and-deposit-view>
        </div>
    </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import usePrepaidAndDepositView from "../../../components/AR/calculateInvoice/UsePrepaidAndDepositView.vue"
export default {
  components: {
    usePrepaidAndDepositView,
  },
  setup() {
    const { lang } = useLang();
    const store = useStore();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.calculateInvoice.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.calculateInvoice.listing,
        };
      }
    });

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,
      isHideBack,
    };
  },
  data() {
    return {
    }
  },
  methods: {
    goBack() {
      if (this.$route.query.back == "ar-calculate-invoice") {
        this.$router.push({ name: this.$route.query.back });
      } else {
        this.$router.push({ name: "home" })
      }
    },
  }
}
</script>