import * as XLSX from "xlsx";
import { getJsDateFromExcel } from "excel-date-to-js";
import useFormat from "../hooks/format.js";
import useGetAddress from "../hooks/getAddress";
import useCommon from "../constant/common.js"
import useGetCountryCode from "../hooks/getCountryCode";

export default function useImportExcel() {

  const { formatDateToISOString } = useFormat();

  const validateFileCount = (files, count) => {
    const fileCount = files.length;
    const fileNames = files.map(file => file.name).join(", ");

    if (fileCount > count) {
      return { isError: true, message: fileNames };
    } else {
      return { isError: false, message: "" };
    }
  };

  const validateFileType = (file) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (file.type != fileType) {
      return { isError: true, message: file.name };
    } else {
      return { isError: false, message: "" };
    }
  }

  const readFileContent = async (file, startWith, maxDataLength, condition) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = () => {
        try {
          const arrayBuffer = fileReader.result;
          let data = new Uint8Array(arrayBuffer);
          let arr = [];
          for (let i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          let bstr = arr.join("");
          let workbook = XLSX.read(bstr, { type: "binary" });
          let first_sheet_name = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[first_sheet_name];

          const rawDatas = XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false, raw: true });
          const rawDatasWithOutHeader = rawDatas.filter((item, index) => index >= startWith - 1);

          if (rawDatasWithOutHeader.length == 0) {
            resolve({ data: [], success: false, message: "data 0 row", code: "001" });
          } else if (rawDatasWithOutHeader.length > maxDataLength) {
            resolve({ data: [], success: false, message: `data more than ${maxDataLength}`, code: "002" });
          } else {
            let result = [];

            rawDatasWithOutHeader.forEach(rawData => {
              let newObj = {};
              let errorResult = [];
              for (let i = 0; i <= condition.length; i++) {
                let errorObj = {}
                if (condition.length == i) {
                  newObj.error = errorResult;
                } else {
                  let data = rawData[i];
                  if (data === undefined || data === null) {
                    if ('type' in condition[i] && (condition[i].type == "integer" || condition[i].type == "decimal")) {
                      data = 0;
                    } else {
                      data = "";
                    }
                  }
                  if ('isRequire' in condition[i]) {
                    if (condition[i].isRequire === true && data.toString().trim() === "") {
                      errorObj.field = condition[i].name;
                      errorObj.type = "blank";
                      errorObj.data = data;
                      errorResult.push(errorObj)
                    }
                  }
                  if ('isUnique' in condition[i]) {
                    if ('type' in condition[i] && condition[i].type === "taxId" &&  (data.toString().trim() === "-" || data.toString().trim() === "0000000000000")) {
                      data = data.toString();
                    } else if (!validateDuplicate(rawDatasWithOutHeader, i, data)) {
                      errorObj.field = condition[i].name;
                      errorObj.type = "duplicate";
                      errorObj.data = data;
                      errorResult.push(errorObj)
                    }
                  }
                  if ('wordLength' in condition[i]) {
                    if ((data).length > condition[i].wordLength) {
                      errorObj.field = condition[i].name;
                      errorObj.type = "overflow";
                      errorObj.wordLength = condition[i].wordLength
                      errorResult.push(errorObj)
                    }
                  }
                  if ('type' in condition[i]) {
                    let type = condition[i].type;
                    if ((type === "string" && !validateString(data.toString()))
                      || (type === "integer" && !validateInteger(data))) {
                      errorObj.field = condition[i].name;
                      errorObj.type = "invalid";
                      errorObj.data = data;
                      errorResult.push(errorObj)
                    } else if ((type === "decimal" && !validateDecimal(data) && (('isRequire' in condition[i] && condition[i].isRequire === true) || data !== ""))) {
                      errorObj.field = condition[i].name;
                      errorObj.type = "invalidDecimal";
                      errorObj.data = data;
                      errorResult.push(errorObj)
                    } else if ((type === "date" && !validateDate(data) && (('isRequire' in condition[i] && condition[i].isRequire === true) || data !== ""))) {
                      errorObj.field = condition[i].name;
                      errorObj.type = "format";
                      errorObj.data = data;
                      errorResult.push(errorObj)
                    } else if (type === "period" && !validatePeriod(data)) {
                      errorObj.field = condition[i].name;
                      errorObj.type = "format";
                      errorObj.data = data;
                      errorResult.push(errorObj)
                    } else if ((type === "prefix" && !validatePrefix(data) && (('isRequire' in condition[i] && condition[i].isRequire === true) || data !== ""))) {
                      errorObj.field = condition[i].name;
                      errorObj.type = "doNotExist";
                      errorObj.data = data;
                      errorResult.push(errorObj)
                    } 
                    else {
                      if (type === "string") {
                        data = data.toString();
                      } else if (type === "date" && data !== "") {
                        data = formatDateToISOString(convertDate(data));
                      } else if (type === "period" && data !== "") {
                        data = convertPeriod(data)
                      }
                    }
                  }
                  newObj[condition[i].name] = data;
                }
              }
              result.push(newObj);
            });
            resolve({ data: result, success: true, message: "", code: "200" });
          }
        } catch (error) {
          resolve({ data: [], success: false, message: error, code: "888" });
        }
      };

      fileReader.onerror = () => {
        reject(new Error("File reading error."));
      };
    });
  };

  const validateString = (value) => {
    return typeof value === 'string';
  }

  const validateInteger = (value) => {
    return !isNaN(parseInt(value, 10)) && Number.isInteger(Number(value));
  }

  const validateDecimal = (value, digit = 2) => {
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue) && isFinite(parsedValue)) {
      let splitValue = value.toString().split('.');
      if (splitValue.length == 1 ) {
        return true;
      } else if (splitValue.length > 1 && splitValue[1].length <= digit) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const validateYearEn = (value) => {
    let text = /^[0-9]+$/;
    if (value && value.length == 4) {
      if (value != 0) {
        if (value != "" && !text.test(value)) {
          return false;
        }
        let current_year = new Date().getFullYear(); //2023
        let current_yearTh = new Date().getFullYear() + 543; //2566
        if (value - current_year > 500 || value >= current_yearTh) {
          return false;
        }
        return true;
      }
    }
    return false;
  }

  const validateYearTh = (value) => {
    let text = /^[0-9]+$/;
    if (value && value.length == 4) {
      if (value != 0) {
        if (value != "" && !text.test(value)) {
          return false;
        }
        let current_year = new Date().getFullYear() + 543;
        let next_year = current_year + 1;
        if (value < 2464 || value > next_year) {
          //ค.ศ. 1920
          return false;
        }
        return true;
      }
    }
    return false;
  }

  const getDaysInMonth = (year, month) => {
    // month เริ่มจาก 0 (มกราคม) ถึง 11 (ธันวาคม)
    return new Date(year, month, 0).getDate();
  }

  const validateDate = (value) => {
    let result = true;
    if (value.toString().includes("/")) {
      if (value.length !== 10) {
        result = false;
        return result;
      }
      let dateSplit = value.split("/");
      let day = dateSplit[0];
      let month = dateSplit[1];
      let year = dateSplit[2];
      let checkYear = validateYearEn(year);
      if (!checkYear) {
        result = false;
      } else if (month <= 0 || month > 12) {
        result = false;
      } else {
        const daysInMonth = getDaysInMonth(year, month);
        if (day <= 0 || day > daysInMonth) {
          result = false;
        }
      }
    } else {
      if (!validateInteger(value)) {
        result = false;
      } else {
        let convertData = getJsDateFromExcel(value);
        if (convertData !== "Invalid Date") {
          let year = convertData.getFullYear().toString();
          let checkYear = validateYearEn(year);
          if (!checkYear) {
            result = false;
          }
        } else {
          result = false;
        }
      }
    }
    return result;
  }

  const convertDate = (value) => {
    let date = new Date();
    if (value.toString().includes("/")) {
      let dateSplit = value.split("/");
      let day = dateSplit[0];
      let month = dateSplit[1];
      let year = dateSplit[2];
      date = new Date(year, month - 1, day);
    } else {
      date = getJsDateFromExcel(value);
    }
    return date;
  }

  const validatePeriod = (value) => {
    let result = true;
    if (value.toString().includes("/")) {
      let dateSplit = value.split("/");
      let month = dateSplit[0];
      let year = dateSplit[1];
      let checkYear = validateYearTh(year);
      if (value.length !== 7) {
        result = false;
        return result;
      }
      if (dateSplit.length > 2) {
        result = false;
        return result;
      }
      if (!checkYear) {
        result = false;
      } else if (month <= 0 && month > 12) {
        result = false;
      }
    } else {
      if (!validateInteger(value)) {
        result = false;
      } else {
        let convertData = getJsDateFromExcel(value);
        if (convertData === "Invalid Date") {
          result = false;
        }
      }
    }
    return result;
  }

  const convertPeriod = (value) => {
    let date = "";
    if (value.toString().includes("/")) {
      date = value;
    } else {
      let convertData = getJsDateFromExcel(value);
      let month = (convertData.getMonth() + 1).toString();
      if (month.length < 2) {
        month = "0" + month.toString();
      }
      let year = convertData.getFullYear().toString();
      date = month + "/" + year;

    }
    return date;
  }

  const validateDuplicate = (arrayOfObjects, i, data) => {
    const duplicates = [];
    arrayOfObjects.forEach((obj) => {
      const current = obj[i];
      duplicates.push(current);
    });
    return duplicates.filter(item => item === data).length <= 1;
  }

  const getFields = (input, field) => {
    let output = [];
    for (let i = 0; i < input.length; ++i) output.push(input[i][field]);
    return output;
  };

  const removeDuplicates = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  };

  const addImportStatus = (value) => {
    return value
  }

  const validatePrefix = (data) => {
    if (useCommon.CUSTOMER_PREFIX.includes(data)) {
      return true;
    }
    return false;
  }

  const { address } = useGetAddress();
  const validateAddress = (arrayOfObjects) => {
    let results = [];

    arrayOfObjects.forEach((object) => {
      // ดึง key ทั้งหมดของอ็อบเจกต์
      const keys = Object.keys(object);
      // วนลูปตรวจสอบค่าของทุก key ในอ็อบเจกต์
      let i = 0;
      keys.forEach((key) => {
        // ถ้าค่าของ key ไม่เท่ากับ ""
        if (object[key] !== "" && key != "field") {
          i++;
        }
      });
      if (i == 0 || i == keys.length - 1) {
        object.isError = false;
      } else {
        object.isError = true;
      }
      results.push(object);
    });
    return results;
  }
  const validateAddressInDatabase = (object) => {
    let result = {
      subdistrict: true,
      district: true,
      province: true,
      postCode: true,
      field: object.field,
      isError: false
    };
    let checkError = false;
    if (object.subdistrict) {
      let check = address.value.find(
        (data) => data.subdistrictTh.includes(object.subdistrict)
      );
      if (!check) {
        result.subdistrict = false;
        checkError = true;
      }
    }
    if (object.district) {
      let check = address.value.find(
        (data) => data.districtTh.includes(object.district)
      );
      if (!check) {
        result.district = false;
        checkError = true;
      }
    }
    if (object.province) {
      let check = address.value.find(
        (data) => data.provinceTh.includes(object.province)
      );
      if (!check) {
        result.province = false;
        checkError = true;
      }
    }
    if (object.postCode) {
      let check = address.value.find(
        (data) => data.postCode.includes(object.postCode)
      );
      if (!check) {
        result.postCode = false;
        checkError = true;
      }
    }
    result.isError = checkError;
    return result;
  }
  const validateAddressRelationship = (object) => {
    let result = true;
    let datas = address.value.filter(
      (address) => address.subdistrictTh.trim() == object.subdistrict.trim()
                && address.districtTh.trim() == object.district.trim()
                && address.provinceTh.trim() == object.province.trim()
                && address.postCode.trim() == object.postCode.trim()
    );
    if (datas.length < 1) {
      result = false;
    }
    return result;
  }

  const { countryCodes } = useGetCountryCode();
  const validateCountryCode = (data) => {
    let check = countryCodes.value.find((countryCode) => countryCode.callingCode == data);
    if (check) {
      return true;
    }
    return false;
  }

  const validateGender = (data) => {
    if (useCommon.CUSTOMER_GENDER.includes(data)) {
      return true;
    }
    return false;
  }

  return {
    validateFileCount,
    validateFileType,
    readFileContent,
    getFields,
    removeDuplicates,
    validateDate,
    convertDate,
    validatePeriod,
    convertPeriod,
    addImportStatus,
    validatePrefix,
    validateAddress,
    validateAddressInDatabase,
    validateAddressRelationship,
    validateCountryCode,
    validateGender,
  }
}