<template>
  <base-toolbar
    :title="lang.title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoCreate="gotoEdit('create')"
    :hideBackButton="isHideBack"
  >
  </base-toolbar>
  <base-container>
    <import-supplier-dialog
      v-model:visible="showUploadSupplierDialog"
      @close="showUploadSupplierDialog = false"
    ></import-supplier-dialog>

    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="lang.notificationDialogHeader"
      :classTitle="baseDialogClassTitle"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
    ></base-dialog>

    <div
      class="surface-card shadow-2 p-5 border-round mt-5"
      :class="textFormClass"
    >
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.containerTitle }}</label>

        <div class="flex">
          <PvButton
            class="p-button-outlined"
            @click="importSupplier"
            v-if="isShowImportDataButton"
          >
            <icon-download iconColor="primary" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{ lang.importSupplier }}</span>
          </PvButton>
        </div>
      </div>

      <PvDivider></PvDivider>

      <div class="flex flex-wrap mb-3">
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pb-0">
          <PvDropdown
            class="inputfield w-full"
            :options="selectType"
            optionLabel="name"
            optionValue="code"
            v-model="selectedType"
            @change="changeSelectedType()"
          />
        </div>

        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0" v-if="selectedType != 'All'">
          <div class="surface-card flex justify-content-between">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchText"
                :placeholder="this.selectedTypePlaceholder"
                @keyup="searchTextEnter($event)"
                autofocus
                :class="{ 'p-inputtext-disabled': isDisableSearch }"
                :disabled="isDisableSearch"
              />
            </span>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pb-0 flex flex-wrap">
          <PvButton 
            class="ml-2" 
            @click="getDatas()" 
          >
            <span class="font-normal">{{ lang.search }}</span>
          </PvButton>
        </div>
      </div>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="suppliers"
          v-model:selection="selectedSupplier"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(this.selectedSupplier.id)"
          scrollable
        >
          <PvColumn
            field="code"
            :header="lang.columnCode"
            style="min-width: 5rem; height: 45px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>
          <PvColumn
            field="name"
            :header="lang.columnName"
            style="min-width: 20rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>
          <PvColumn
            field="taxId"
            :header="lang.colummTaxId"
            style="min-width: 5rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>
          <PvColumn
            field="telephoneNumber"
            :header="lang.colummTelephoneNumber"
            style="min-width: 5rem"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="email"
            :header="lang.colummEmail"
            style="min-width: 15rem"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>

          <PvColumn
            style="max-width: 5rem"
            headerClass="h-center"
            bodyStyle="text-align: center;justify-content: center;align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                :disabled="!this.isRoleSelect"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
            </template>
          </PvColumn>

          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>

          <template #footer>
            <div>
              <PvPaginator
                :rows="rowsPerPage"
                :totalRecords="totalRecords"
                @page="onPageChange($event)"
                :rowsPerPageOptions="[10, 50, 100]"
                class="p-pagination-radius"
                :first="showCurrentPage"
              >
                <template #start="slotProps">
                  {{ lang.page }}:
                  {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{
                    totalPage
                  }}
                </template>
              </PvPaginator>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import ImportSupplierDialog from "../../../components/master/supplier/ImportSupplierDialog.vue";
export default {
  components: {
    ImportSupplierDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const isHideBack = inject("isHideBack");
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.supplier.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.supplier.listing,
        };
      }
    });
    return {
      lang: mergeLang,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      isHideBack,
    };
  },
  data() {
    return {
      isLoading: false,
      storageName: "",
      searchText: "",
      all: "",
      code: "",
      name: "",
      telephoneNumber: "",
      selectedSupplier: null,
      //page
      selectedPage: 1,
      rowsPerPage: 10,
      //dropdown
      selectType: [
        { name: this.lang.dropdownAll, code: "All" },
        { name: this.lang.dropdownCode, code: "Code" },
        { name: this.lang.dropdownName, code: "Name" },
        { name: this.lang.dropdownTelephoneNumber, code: "TelephoneNumber" },
      ],
      selectedType: "All",
      //dialog
      validateWording: "",
      baseDialogClassTitle: "",
      showValidateDialog: false,
      showUploadSupplierDialog: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    this.initiateData();
    this.storageName = `erp_search_suppliers_${this.selectedProject.code}_${this.userCode}`;
    this.checkShouldSetData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    openAlertDialog(dataConfig) {
      if (dataConfig.severity === "") {
        this.confirmClassTitle = "";
      } else if (dataConfig.severity === "success") {
        this.confirmClassTitle = "p-text-success";
      } else if (dataConfig.severity === "error") {
        this.confirmClassTitle = "p-error";
      }
      this.confirmTitle = dataConfig.title;
      this.confirmContent = dataConfig.content;
      this.showConfirmDialog = true;
    },
    closeAlertDialog() {
      this.showConfirmDialog = false;
    },
    async checkShouldSetData() {
      if (this.isSharedRoleByMenuReady) {
        await this.setLocalSearch();
        await this.getDatas();
      }
    },
    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      if (localSearch) {
        let search = JSON.parse(localSearch);
        if (search.entry === "Entry") {
          this.selectedType = search.selectedType;
          this.searchText = search.searchText;
          this.rowsPerPage = search.rowsPerPage;
          this.selectedPage = search.selectedPage;
        }
      }
    },
    changeSelectedType() {
      this.initiateData();
      // if (this.selectedType === "All") {
      //   this.getDatas();
      // }    
    },
    initiateData() {
      this.searchText = "";
      this.all = "";
      this.code = "";
      this.name = "";
      this.telephoneNumber = "";
      this.showUploadSupplierDialog = false;
    },
    searchTextEnter(event) {
      if (event.key == "Enter") {
        this.getDatas();
      }
    },
    validateData() {
      if (this.searchText == "" && this.selectedType != "All") {
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateSearchText;
        this.showValidateDialog = true;
        return true;
      }
      return false;
    },
    async getDatas() {
      try {
        if (this.validateData()) {
          return;
        }
        this.isLoading = true;
        if (this.selectedType === "All") this.all = this.searchText;
        if (this.selectedType === "Code") this.code = this.searchText;
        if (this.selectedType === "Name") this.name = this.searchText;
        if (this.selectedType === "TelephoneNumber") this.telephoneNumber = this.searchText;
        let where = {
          projectId: this.selectedProject.id,
          code: this.code || null,
          name: this.name || null,
          telephoneNumber: this.telephoneNumber || null,
          codeAndName: this.all || null,
        };
        const payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          where,
        };
        await this.$store.dispatch("supplier/getSuppliers", payload);
        where = {
          ...where,
          selectedType: this.selectedType,
          searchText: this.searchText,
          rowsPerPage: this.rowsPerPage,
          selectedPage: this.selectedPage,
        };
        localStorage.setItem(this.storageName, JSON.stringify(where));
        this.isLoading = false;
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.isLoading = false;
      }
    },
    async onPageChange(event) {
      if(event.rows != this.rowsPerPage){
        this.selectedPage = 1;
      }else{
        this.selectedPage = event.page + 1;        
      }
      this.rowsPerPage = event.rows;
      await this.getDatas();
    },
    async gotoEdit(id) {
      if (!this.isRoleSelect && id != "create") {
        this.validateWording = this.lang.validateRoleSelect;
        this.showValidateDialog = true;
        return
      }
      if (!this.isRoleInsert && id == "create") {
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return
      }
      this.$router.push({name: "supplier-edit", params: { id: id }});
    },
    importSupplier() {
      if (!this.isRoleInsert) {
        this.validateWording = this.lang.validateRoleImport;
        this.showValidateDialog = true;
        return true;
      }
      this.showUploadSupplierDialog = true;
    },
  },
  computed: {
    title() {
      return this.lang.title;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    suppliers() {
      return this.$store.getters["supplier/suppliers"];
    },
    totalPage() {
      return this.$store.getters["supplier/totalPage"];
    },
    currentPage() {
      return this.$store.getters["supplier/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    showCurrentPage() {
      return this.rowsPerPage * (this.selectedPage - 1);
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmCOR");
    },
    isRoleSelect() {
      if (this.roleByMenu && this.roleByMenu.select == "Y") {
        return true;
      }
      return false;
    },
    isRoleInsert() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    dataButtons() {
      return [
        {
          id: "createCaption",
          caption: this.lang.createCaption,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
        },
      ];
    },
    dataButtonDialog() {
      return [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
    },
    selectedTypePlaceholder() {
      // let Placeholder = this.lang.search;
      // if (this.selectedType === "Code") Placeholder += this.lang.dropdownCode;
      // if (this.selectedType === "Name") Placeholder += this.lang.dropdownName;
      // if (this.selectedType === "TelephoneNumber")
      //   Placeholder += this.lang.dropdownTelephoneNumber;
      // return Placeholder;
      return this.lang.validateSearchText;
    },
    isDisableSearch() {
      let isDisable = false;
      if (this.selectedType === "All") {
        isDisable = true;
      } else {
        isDisable = false;
      }
      return isDisable;
    },
    isShowImportDataButton() {
      let userGroupId = process.env.VUE_APP_USER_GROUP_ID.split("|")
      return userGroupId.includes(this.roleByMenu.userGroupId);
    }
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.checkShouldSetData();
    },
  },
};
</script>