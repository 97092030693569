export default {
  setBank(state, payload) {
    let banks = [...state.bank.banks];
    for (const bank of payload.banks) {
      if (banks.findIndex((c) => c.id === bank.id) < 0) {
        banks.push(bank);
      }
    }
    state.bank.banks = banks;
    state.bank.currentPage = payload.currentPage;
    state.bank.totalPage = payload.totalPage;
  },
  resetBank(state) {
    const bank = {
      banks: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.bank = bank;
  },
};
  