export default {
	setWithholdingTax(state, payload) {
		// state.project.length = 0;
		state.withholdingTaxs = payload.data;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
	setSelectedWithholdingTax(state, payload) {
		state.selectedWithholdingTax = payload;
	},
	setBlob(state, payload) {
		// console.log("setBlob:",payload);
		state.blob = payload;
	},
	resetWithholdingTaxList(state) {
		state.withholdingTaxs = [];
	},
	setWithholdingTaxsForImport(state, payload) {
		state.withholdingTaxsForImport = payload.data;
		state.totalPageImport = payload.page.pageCount;
		state.currentPageImport = payload.page.currentPage;
	},
	setWithholdingTaxsImport(state, payload) {
		state.withholdingTaxsImport = payload;
	},
	resetWithholdingTaxsImport(state) {
		state.withholdingTaxsImport = [];
	},
	resetWithholdingTaxsForImport(state) {
		state.withholdingTaxsForImport = [];
		state.totalPageImport = 0;
		state.currentPageImport = 0;
	},
	setSelectedWithholdingTaxWithVoucher(state, payload) {
		state.selectedWithholdingTaxWithVoucher = payload;
	},
};
