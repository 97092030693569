export default({
  title: "รายการแจ้งเตือน",
  titleToolbaar: "ส่งแจ้งเตือน",
  unPaidOnly: "เฉพาะค้างจ่าย",
  clearFilter: "ล้างการกรอง",
  code: "เลขที่เอกสาร",
  unit: "ยูนิต",
  address: "บ้านเลขที่",
  customerName: "ชื่อลูกค้า",
  allStatus: "ทั้งหมด",
  installAppStatus: "สถานะลง App",
  isInstall: "ลง App",
  isNotInstall: "ไม่ลง App",
  sendNotifyStatus: "สถานะส่ง",
  sendNotify: "ส่งแล้ว",
  notSendNotify: "ยังไม่ส่ง",
  readNotifyStatus: "สถานะอ่าน",
  readNotify: "อ่านแล้ว",
  notReadNotify: "ยังไม่อ่าน",
  date: "วันที่เอกสาร",
  gotoSendNotify: "ส่งแจ้งเตือนทุกรายการ",
  columnDate: "วันที่เอกสาร",
  columnCode: "เลขที่เอกสาร",
  columnUnit: "ยูนิต",
  columnAddress:"บ้านเลขที่",
  columnCustomerName: "ชื่อลูกค้า",
  columnPrice:"ราคา",
  columnIsPaid:"สถานะการชำระ",
  columnIsCancel:"ยกเลิก",
  columnPostStep:"พิมพ์/ลงบัญชี",
  columnInstallApp:"ลง App",
  columnSendNotify:"ส่ง",
  columnReadNotify:"อ่าน",
    // unPaidOptions: [
    //     { name: "ใช่", code: "A1" },
    //     { name: "ไม่ใช่", code: "A2" }
    //   ],
    // createInvoice: "สร้างใบแจ้งหนี้",
    // sendNotification: "ส่งแจ้งเตือน",
    // printInvoice: "พิมพ์รายการใบแจ้งหนี้",
    // date: "ช่วงวันที่ตามปี ค.ศ.",
    // price: "ราคา",
    // unlockDocument: "ปลดล๊อคเอกสาร",
    // referenceDocument: "เอกสารที่อ้างถึง",    
	  // validateNumber: "กรุณาระบุเป็นตัวเลขเท่านั้น",     
    // contactNumber: "เบอร์ติดต่อ",
    // remark1: "Remark (1)",
    // remark2: "Remark (2)",
    // fullPayment: "จ่ายเต็มจำนวน",
    // splitRefund: "แบ่งชำระ",
    printed: "พิมพ์แล้ว",
    // accRecorded: "ลงบัญชี",
    sendNotifySuccess: "ส่งคิวแจ้งเตือนใบแจ้งหนี้สำเร็จ",
    sendNotifyError: "ส่งคิวแจ้งเตือนใบแจ้งหนี้ไม่สำเร็จ โปรดลองใหม่อีกครั้ง",
    notifyStatus: "ส่งแจ้งเตือน",
    sending: "กำลังส่งแจ้งเตือน...",
    stopProgressBar: "หยุดชั่วคราว",
    continueProgressBar: "ส่งแจ้งแตือนต่อ",
    errorSendAllNotify: "ส่งแจ้งเตือนไม่สำเร็จ โปรดลองใหม่อีกครั้ง",
    tryAgainSendNotify: "ลองใหม่อีกครั้ง",
    confirmSendAllWording: "ยืนยันการส่งแจ้งเตือนทั้งหมด",


})