<template>
  <PvCard class="p-0 -mx-3 select-card cursor-pointer" :class="classCardClick">
    <template #title>
      <slot name="titleLeft"></slot>
      <span class="font-lg" :class="classTitle">{{ title }}</span>
      <slot name="titleRight"></slot>
    </template>
    <template #content>
      <slot name="contentLeft"></slot>
      <span class="font-3xl font-bold" :class="classContent">
        {{ content }}
      </span>
      <span class="font-md font-normal" :class="classSubContent" v-if="subContent">
        {{ subContent }}
      </span>
      <slot name="contentRight"></slot>
    </template>
  </PvCard>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    subContent: {
      type: String,
    },
    color: {
      type: String,
      default: "primary",
    },
    isClick: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classContent() {
      let classContent = "select-card-text-" + this.color;
      return classContent;
    },
    classSubContent() {
      let classSubContent = "select-card-text-" + this.color;
      return classSubContent;
    },
    classTitle() {
      let classTitle = "";
      if (this.isClick) {
        classTitle += "font-semibold select-card-text-" + this.color;
      } else {
        classTitle += "font-normal ";
      }
      return classTitle;
    },
    classCardClick() {
      let classCardClick = "";
      if (this.isClick) {
        classCardClick += "select-card-border-" + this.color;
      }
      return classCardClick;
    },
  },
};
</script>

<style scoped>
.p-card :deep(.p-card-content) {
  padding: 0;
}
.p-card :deep(.p-card-body) {
  padding: 10px 24px 10px 24px;
}
</style>

<!--
  <div class="flex flex-wrap">
    <div class="lg:col-6 md:col-6 col-12 lg:w-2 md:w-3 w-4 mx-3">
      <base-select-card
        title="หนี้คงค้าง"
        :color="'primary'"
        :isClick="isOutstanding"
        @click="clickFilter('Outstanding')"
      >
        <template #titleLeft
          ><icon-path
            iconColor="success"
            width="24"
            height="24"
            style="margin-right: 5px; margin-bottom: -7px"
        /></template>
      </base-select-card>
    </div>

    <div class="lg:col-6 md:col-6 col-12 lg:w-2 md:w-3 w-4 mx-3">
      <base-select-card
        title="พัสดุ"
        :color="'primary'"
        :isClick="isParcel"
        @click="clickFilter('Parcel')"
      >
        <template #titleLeft
          ><icon-path
            iconColor="success"
            width="24"
            height="24"
            style="margin-right: 5px; margin-bottom: -7px"
        /></template>
      </base-select-card>
    </div>

    <div class="lg:col-6 md:col-6 col-12 lg:w-2 md:w-3 w-4 mx-3">
      <base-select-card
        class="font-3xl"
        title="สถานะพักอาศัย"
        :color="'primary'"
        :isClick="isResident"
        @click="clickFilter('Resident')"
      >
        <template #titleLeft
          ><icon-path
            iconColor="success"
            width="24"
            height="24"
            style="margin-right: 5px; margin-bottom: -7px"
        /></template>
      </base-select-card>
    </div>
  </div>
  <div class="flex flex-wrap">
    <div class="lg:col-6 md:col-6 col-12 lg:w-2 md:w-3 w-4 mx-3">
      <base-select-card
        title="ค่าส่วนกลาง"
        :content="this.contentAllStatus"
        :color="'primary'"
        :isClick="isCommonArea"
        @click="clickFilter('CommonArea')"
      >
        <template #contentRight
          ><icon-path
            iconColor="success"
            width="24"
            height="24"
            style="margin-left: 5px; margin-bottom: -7px"
        /></template>
      </base-select-card>
    </div>

    <div class="lg:col-6 md:col-6 col-12 lg:w-2 md:w-3 w-4 mx-3">
      <base-select-card
        title="ค่าน้ำ"
        :content="this.contentAllStatus"
        :color="'primary'"
        :isClick="isWaterBilling"
        @click="clickFilter('WaterBilling')"
      >
        <template #contentRight
          ><icon-path
            iconColor="success"
            width="24"
            height="24"
            style="margin-left: 5px; margin-bottom: -7px"
        /></template>
      </base-select-card>
    </div>
  </div>


isOutstanding: false,
isParcel: false,
isResident: false,

isCommonArea: false,
isWaterBilling: false,

async clickFilter(status) {
  this.isOutstanding = false;
  this.isParcel = false;
  this.isResident = false;

  this.isCommonArea = false;
  this.isWaterBilling = false;

  if (status == "Outstanding") {
    this.isOutstanding = true;
  } else if (status == "Parcel") {
    this.isParcel = true;
  } else if (status == "Resident") {
    this.isResident = true;
  }

  if (status == "CommonArea") {
    this.isCommonArea = true;
  } else if (status == "WaterBilling") {
    this.isWaterBilling = true;
  }
},


-->