export default {
  title: "Invoices",
  titleRefdocument:"Reference document",
  columnDate: "Date",
  columnCode: "Code",
  columnUnit: "Unit No.",
  columnAddress: "Addr No.",
  columnCustomerName: "Customer Name",
  columnPrice: "Price",
  columnIsPaid: "Paid",
  columnIsCancel: "Canceled",
  columnPostStep: "Printed/Debited",
  columnCreateBy:"CreateBy",
  columnCreateDate:"CreateDate",
  columnUpdateBy:"UpdateBy",
  columnUpdateDate:"UpdateDate", 
  columnTotalAmount: "Total Amount",  
  columnQuantity: "Quantity",
  columnPeriod: "Period",
  customerSearch: "Customer Code or Name",
  unPaidOnly: "Unpaid only",
  unPaidOptions: [
    { name: "Yes", code: "A1" },
    { name: "No", code: "A2" }
  ],
  createInvoice: "CreateInvoice",
  sendNotification: "Send Notification",
  printInvoice: "Print Invoice",
  date: "Date",
  code: "Code",
  unit: "Unit",
  address: "Address",
  customerName: "CustomerName",
  price: "Price",
  unlockDocument: "Unlock Document",
  referenceDocument: "Reference Document",
	validateNumber: "Please enter numbers only.", 	  
  contactNumber: "Contact Number",
  remark1: "Remark (1)",
  remark2: "Remark (2)",
  fullPayment: "Full Payment",
  splitRefund: "Split Refund",
  printed: "Printed",
  accRecorded: "Acc Recorded",
  askForUnLockInvoice: "Are you sure you want to unlock document?",
  askForUnLockSuccess: "UnLock Success",
  documentPaid: "Document Paid",
  documentAccRecorded: "Document Acc Recorded",
  documentCancel: "Document Canceled",
  toastPrintLotTitle: "In process...",
  toastPrintLotContent: "You can view your request at ",
  toastPrintLotLink: "request list",
  validateSearchText:"Please Enter SearchText",
};
