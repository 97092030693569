<template>
  <base-toolbar
    :title="lang.title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoSave="askForSave"
    @gotoCancel="askForCancel"
    :hideBackButton="isHideBack"
  ></base-toolbar>

  <base-container>

    <base-dialog
      v-model:visible="showValidateDialog"
      :classTitle="baseDialogClassTitle"
      :titleConfirm="titleConfirmDialog"
      :wordingConfirm="wordingConfirmDialog"
      :dataButtons="dataButtonDialog"
      @close="closeValidateDialog"
      @confirm-cancel="cancel"
      @confirm-save="save"
      @usePrepaid="askForUsePrepaid"
      @confirm-use-prepaid-and_deposit="gotoUsePrepaidAndDeposit"
    ></base-dialog>

    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5">
      <div class="flex justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.invoiceChecklist }}</label>
        <PvButton class="p-button-outlined m-0" @click="printInvoice" :disabled="!this.isRolePrint">
          <icon-printer iconColor="primary" width="16" height="16" />
          <span class="p-button-label font-normal pl-2">{{
            lang.printInvoiceChecklist
          }}</span>
        </PvButton>
      </div>

      <PvDivider></PvDivider>

      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >
        <div class="col-4 pr-2 pl-0">
          <span class="p-input-icon-left w-full">
            <i class="pi pi-search" />
            <PvInputText
              class="inputfield w-full"
              v-model="searchText"
              :placeholder="this.lang.search"
              @keyup.enter="getInvoices"
            />
          </span>
        </div>
        <PvButton @click="getInvoices">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>

      <div class="my-4">
        <PvDataTable
          :value="invoices"
          v-model:selection="selectedInvoice"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoPreview(this.selectedInvoice.id)"
          scrollable
        >
          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
          <PvColumn
            field="code"
            :header="lang.columnInvoiceCode"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left font-normal"
            sortable
          >
            <template #body="slotProps">
              <u>{{ slotProps.data.code }}</u>
            </template>
          </PvColumn>
          <PvColumn
            field="date"
            :header="lang.invoiceDate"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left font-normal"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.date) }}
            </template>
          </PvColumn>
          <PvColumn
            field="unitCode"
            :header="lang.columnUnitCode"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left font-normal"
          ></PvColumn>
          <PvColumn
            field="unitAddressNo"
            :header="lang.columnAddressNo"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left font-normal"
          ></PvColumn>
          <PvColumn
            field="customerName"
            :header="lang.columnCustomerName"
            style="min-width: 20rem"
            headerClass="h-left"
            bodyClass="text-left font-normal"
          ></PvColumn>
          <PvColumn
            field="totalAmount"
            :header="lang.columnNetAmount"
            style="min-width: 10rem"
            headerClass="h-right"
            bodyClass="text-right font-normal"
            sortable
          >
            <template #body="slotProps">
              <span class="text-right w-full">{{ formatCurrency(slotProps.data.totalAmount) }}</span>
            </template>
          </PvColumn>

          <PvColumn
            style="min-width: 4rem"
            headerClass="h-right"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            :frozen="true"
          >
            <template #body="slotProps">
              <PvButton class="p-button-secondary p-button-text py-0 px-0" @click="gotoPreview(slotProps.data.id)">
                <icon-eye iconColor="secondary" width="20" height="20" />
              </PvButton>
            </template>
            
          </PvColumn>

          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              class="p-pagination-radius"
            >
              <template #start="slotProps">
                {{ lang.page }}:
                {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
              </template>
            </PvPaginator>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetRoleByBook from "../../../hooks/getRoleByBook.js";
import useGetFormPrint from "../../../hooks/getFormPrint.js";

export default {
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const {
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getCurrentPeriod,
    } = useFormat();
    const store = useStore();

    const { getPreviewBlob } = useGetFormPrint();

    const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.calculateInvoice.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.calculateInvoice.listing,
        };
      }
    });

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getCurrentPeriod,
      isHideBack,
      getRoleByBookByCode,
      isSharedRoleByBookReady,
      getPreviewBlob,
    };
  },
  data() {
    return {
      isLoading: false,
      searchText: "",
      selectedInvoice: null,
      selectedPage: 1,
      rowsPerPage: 10,
      isprinted: false,

      showValidateDialog: false,
      titleConfirmDialog: "",
      wordingConfirmDialog: "",
      baseDialogClassTitle: "",
      confirmDialog: false,
      iscanceled: false,
      isConfirmCancel: false,
      isConfirmSave: false,
      isSaved: false,
      isUsePrepaidAndDeposit: false,
      isConfirmUsePrepaidAndDeposit: false,
    };
  },
  async mounted() {
    await this.setDataQueryParam();
    await this.roleByBookDataReady();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    async getInvoices() {
      this.isLoading = true;
      try {
        let where = {
          projectId: this.selectedProject.id,
          documentBookId: this.roleByBook.documentBookId,
          searchText: this.searchText,
        };

        const payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          where,
        };

        await this.$store.dispatch("arCalculateInvoice/getInvoices", payload);

      } catch (error) {
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = error;
        this.confirmDialog = true;
        this.showValidateDialog = true;
      }

      if(!this.invoices || this.invoices.length == 0) {
        this.$router.push({name: "ar-calculate-invoice"});
      }

      this.isLoading = false;
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getInvoices();
    },
    closeValidateDialog() {
      if(this.iscanceled || this.isSaved) {
        this.$router.push({name: "ar-calculate-invoice"});
      }
      this.baseDialogClassTitle = "";
      this.confirmDialog = false;
      this.iscanceled = false;
      this.isConfirmCancel = false;
      this.isConfirmSave = false;
      this.isSaved = false;
      this.isUsePrepaidAndDeposit = false;
      this.isConfirmUsePrepaidAndDeposit = false;
      this.showValidateDialog = false;
    },
    askForSave() {
      this.titleConfirmDialog = this.lang.approveAll;
      this.wordingConfirmDialog = this.lang.titleConfirmSaveInvoice;
      this.isConfirmSave = true;
      this.showValidateDialog = true;
    },
    async save() {
      this.setLoading(true);
      this.showValidateDialog = false;
      this.confirmDialog = true;
      try {
        const payload = {
          projectId: this.selectedProject.id,
          documentBookId: this.roleByBook.documentBookId,
        }

        // console.log("save payload", payload);

        await this.$store.dispatch("arCalculateInvoice/addGenerateInvoice", payload);

        if(this.generatedInvoice.success == true) {
          this.isSaved = true;
          this.isUsePrepaidAndDeposit = true;
          this.baseDialogClassTitle = "p-text-success";
          this.titleConfirmDialog = this.lang.titleSaveSuccess;
          this.wordingConfirmDialog = this.lang.saveSuccess;
          this.showValidateDialog = true; 
        }
      } catch (error) {
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = error;
        this.confirmDialog = true;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    askForUsePrepaid() {
      this.baseDialogClassTitle = "";
      this.titleConfirmDialog = this.lang.titleUsePrepaid;
      this.wordingConfirmDialog = this.lang.dialogContentUsePrepapidAndDeposit;
      this.confirmDialog = true;
      this.isConfirmUsePrepaidAndDeposit = true;
      this.showValidateDialog = true;
    },
    gotoUsePrepaidAndDeposit() {
      this.$router.push({
        name: "ar-use-prepaid-and-deposit",
        query: { back: "ar-calculate-invoice" },
      });
    },
    askForCancel() {
      this.titleConfirmDialog = this.lang.cancelInvoice;
      this.wordingConfirmDialog = this.lang.titleConfirmCancelInvoice;
      this.isConfirmCancel = true;
      this.showValidateDialog = true;
    },
    async cancel() {
      this.setLoading(true);
      this.showValidateDialog = false;
      this.confirmDialog = true;
      try {
        const payload = {
          projectId: this.selectedProject.id,
          documentBookId: this.roleByBook.documentBookId,
        };

        await this.$store.dispatch("arCalculateInvoice/cancelInvoice", payload);

        this.iscanceled = true;
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirmDialog = this.lang.cancelSuccess;
        this.wordingConfirmDialog = this.lang.saveSuccess;
        this.showValidateDialog = true; 
      } catch (error) {
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = error;
        this.confirmDialog = true;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    async printInvoice() {
      try {
        this.setLoading(true);

        let where = {
          projectId: this.selectedProject.id,
          documentBookId: this.roleByBook.documentBookId,
          invoiceDate: "",
          period: "",
          docType: "PDF",
        };

        const payload = {
          path: "/Report/InvoiceSummaryReport",
          where,
        };

        await this.$store.dispatch("report/exportPDF", payload);

        let blob = this.$store.getters["report/blob"];
        this.getPreviewBlob(blob);
        
      } catch (error) {
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = error;
        this.confirmDialog = true;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.isprinted = true;
      await this.$router.push({ name: "ar-invoice-wait-for-approve", query: {printed: this.isprinted} });
      this.setLoading(false);
    },
    async gotoPreview(id) {
      await this.$router.push({ name: "ar-edit-invoice-wait-for-approve", params: {id: id}, query: {printed: this.isprinted} });
    },
    usePrepaidSuccess() {
      this.$router.push({name: "ar-calculate-invoice"});
    },
    async roleByBookDataReady() {
      if(this.isSharedRoleByBookReady) {
        await this.getInvoices();
      }
    },
    async setDataQueryParam() {
      this.isprinted = this.queryParamPrinted;
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    roleByBook() {
      return this.getRoleByBookByCode("BILL");
    },
    invoices() {
      return this.$store.getters["arCalculateInvoice/invoices"];
    },
    totalPage() {
      return this.$store.getters["arCalculateInvoice/invoicesTotalPage"];
    },
    currentPage() {
      return this.$store.getters["arCalculateInvoice/invoicesCurrentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    invoiceIsCanceled() {
      return this.$store.getters["arCalculateInvoice/invoiceIsCanceled"];
    },
    generatedInvoice() {
      return this.$store.getters["arCalculateInvoice/generatedInvoice"];
    },    
    isRoleCancel() {
      if ((this.roleByBook) && this.roleByBook.cancel == "Y") {
        return true;
      }
      return false;
    },
    isRolePrint() {
      if ((this.roleByBook) && this.roleByBook.print == "Y") {
        return true;
      }
      return false;
    },
    isRoleApprove() {
      if ((this.roleByBook) && this.roleByBook.approve == "Y") {
        return true;
      }
      return false;
    },
    isRoleInsert() {
      if ((this.roleByBook) && this.roleByBook.insert == "Y") {
        return true;
      }
      return false;
    },
    queryParamPrinted() {
      return this.$route.query.printed == "true";
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "cancel",
          caption: this.lang.cancelInvoice,
          class: "p-button-outlined p-button-danger mr-2",
          action: "gotoCancel",
          isShow: true,
          isDisable: !this.isRoleInsert,
        },
        {
          id: "save",
          caption: this.lang.approveAll,
          class: "mr-2",
          action: "gotoSave",
          isShow: true,
          isDisable: !this.queryParamPrinted || !this.isRoleInsert,
        },
      ];
      return dataButtons;
    },
    dataButtonDialog() {
      let dataButtonDialog = [];

      if(this.isConfirmCancel) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "w-full p-button-outlined p-button-secondary",
            action: "close",
          },
          {
            id: "cancel",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "confirm-cancel",
          },
        ]
      }

      if(this.isConfirmSave) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "w-full p-button-outlined p-button-secondary",
            action: "close",
          },
          {
            id: "cancel",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "confirm-save",
          },
        ]
      }
      
      if(this.confirmDialog) {
        dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.close,
            class: "w-full p-button-outlined p-button-secondary",
            action: "close",
          },
        ]
      } 

      if(this.isUsePrepaidAndDeposit) {
        dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.close,
            class: "w-full p-button-outlined p-button-secondary",
            action: "usePrepaid",
          },
        ]
      }

      if(this.isConfirmUsePrepaidAndDeposit) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "w-full p-button-outlined p-button-secondary",
            action: "close",
          },
          {
            id: "cancel",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "confirm-use-prepaid-and_deposit",
          },
        ]
      }

      return dataButtonDialog;
    },
  },
  watch: {
    isSharedRoleByBookReady() {
      this.roleByBookDataReady();
    },
  },
};
</script>


<style>
.p-content-dialog {
  padding: 0 1.5rem 0rem 1.5rem !important;
}
</style>
