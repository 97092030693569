export default {
	title: " รายการแจ้งหนี้ {unitCodeAndAddress}",
	columnProductCode: "รหัส",
	columnProductName: "ชื่อรายรับ",
	columnPrice: "ราคา",
	columnOwner: "ผู้เช่ารับ",
	columnRemark: "หมายเหตุ",

	addUnitProduct: "เพิ่มรายการแจ้งหนี้",
	editUnitProduct: "แก้ไขรายการแจ้งหนี้",
	productCode: "รหัสรายรับ",

	validateProductCode: "เลือก รหัสรายรับ",
	validatePrice: "ระบุ ราคา",
	validateOwner: "เลือก ผู้เช่ารับ",
	validateRemark: "ระบุ หมายเหตุ",
	validatePriceNotLessThanZero: "ราคา ต้องไม่น้อยกว่า 0",
	askForDeleteProductInvoice: "ยืนยันจะลบ :title",
	

};
