export default {
  async getFormPrints(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Form/FormSelect";

    // console.log('action', payload)

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }
    // console.log("url", url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to get Form Path"
      );
      throw error;
    }
    // console.log("Form Path", responseData);

    // const data = responseData.data;

    const formPrint = {
      forms: responseData.data,
      totalPage: responseData.page.pageCount,
      currentPage: responseData.page.currentPage,
    };

    context.commit("setFormPrint", formPrint);
  },
  // async getAllFormPrints(context, data) {
  //   console.log("action all1",data.where);
  //   // while (!context.getters.formPrintIsLastPage) {
  //     let where = data.where
  //     const payload = {
  //       page: context.getters.formPrintNextPage || 1,
  //       where,
  //     };
  //     console.log("action all2",payload);
  //     await context.dispatch("getFormPrints", payload);
  //   // }
// },
  resetFormPrint(context) {
    context.commit("resetFormPrint");
  },
};
