<template>
  <base-toolbar :title="lang.importInvoices" @goBack="goBack"></base-toolbar>
  <base-container>
  
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="titleHeaderImportData"
      :wordingConfirm="titleValidateImportData"
      :dataButtons="dataButtonDialog"
      :classTitle="baseDialogClassTitle"
      @close="closeDialog"
    ></base-dialog>

    <base-progress-bar
      v-model:visible="showProgressBar"
      :title="lang.uploadStatus"
      :textProgressBar="lang.uploading"
      :valueObj="valueObj"
      :progressBar="`Template`"
    >
    </base-progress-bar>

    <import-invoice-detail-dialog
      v-model:visible="showInvoiceDetailDialog"
      :details="invoiceDetails"
      @close="showInvoiceDetailDialog = false"
    >
    </import-invoice-detail-dialog>

    <div class="surface-card shadow-2 p-5 border-round mt-5">
      <div class="flex align-items-center pt-2">
        <div>
          <PvButton @click="importAll()">
            <icon-documents-file-checkmark
              iconColor="white"
              width="24"
              height="24"
            />
            <span
              class="w-full text-center font-md font-normal p-button-label pl-2"
              >{{ lang.importAll }}</span
            >
          </PvButton>
        </div>
        <div class="pl-4 pt-0">
          <PvCheckbox
            id="isLock"
            inputId="binary"
            v-model="isLock"
            :binary="true"
          />
          <label for="isLock" class="pl-3">{{ lang.lockImportInvoice }}</label>
        </div>
      </div>
      <div class="p-datatable-border my-2 pt-3">
        <PvDataTable
          :value="importInvoiceList"
          v-model:selection="selectedInvoice"
          dataKey="itemNo"
          :class="textTableClass"
          :loading="isLoading"
          :scrollHeight="halfViewportHeight + 'px'"
          scrollable
        >
          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
          <PvColumn
            field="itemNo"
            :header="lang.itemNo"
            style="min-width: 5rem; max-width: 7rem;"
            headerClass="h-left"
            bodyClass="font-normal"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.error }">{{
                slotProps.data.itemNo
              }}</span>
            </template>
          </PvColumn>

          <PvColumn
            field="unitCode"
            :header="lang.columnUnitCode"
            style="min-width: 6rem"
            headerClass="h-left "
            bodyClass="font-normal"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.error }">{{
                slotProps.data.unitCode
              }}</span>
            </template>
          </PvColumn>

          <PvColumn
            field="date"
            :header="lang.columnDate"
            style="min-width: 8rem"
            headerClass="h-left "
            bodyClass="text-center font-normal"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.error }">{{
                formatDate(slotProps.data.date)
              }}</span>
            </template>
          </PvColumn>

          <PvColumn
            field="duedate"
            :header="lang.columnDueDate"
            style="min-width: 8rem"
            headerClass="h-left "
            bodyClass="text-center font-normal"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.error }">{{
                formatDate(slotProps.data.duedate)
              }}</span>
            </template>
          </PvColumn>

          <PvColumn
            field="remark1"
            :header="lang.columnReferCode"
            style="min-width: 10rem"
            headerClass="h-left "
            bodyClass="font-normal"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.error }">{{
                slotProps.data.remark1
              }}</span>
            </template>
          </PvColumn>

          <PvColumn
            field="totalAmount"
            :header="lang.columnAmount"
            style="min-width: 10rem"
            headerClass="h-right "
            bodyClass="text-right font-normal"
          >
            <template #body="slotProps">
              <span
                class="text-right w-full"
                :class="{ 'p-error': slotProps.data.error }"
                >{{ formatCurrency(slotProps.data.totalAmount) }}</span
              >
            </template>
          </PvColumn>

          <PvColumn
            field="status"
            style="min-width: 10rem; max-width: 12rem"
            headerClass="h-left"
            bodyStyle="text-align: left; justify-content: left; align-items: left;"
            alignFrozen="right"
            frozen
          >
            <template #header>
              <span style="text-align: left">{{ lang.status }}</span>
            </template>
            <template #body="slotProps">
              <base-tag
                v-if="slotProps.data.status != ''"
                :Caption="
                  slotProps.data.status == 'waiting'
                    ? lang.statusWaiting
                    : slotProps.data.status == 'success'
                    ? lang.statusSuccess
                    : lang.error
                "
                :Class="
                  slotProps.data.status == 'waiting'
                    ? 'warning'
                    : slotProps.data.status == 'success'
                    ? 'success'
                    : 'error'
                "
              />
            </template>
          </PvColumn>
          <PvColumn
            style="min-width: 6rem; max-width: 6rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-2 px-2 mr-2"
                @click="gotoDetails(slotProps.data.details)"
              >
                <icon-eye
                  iconColor="secondary"
                  width="24"
                  height="24"
                ></icon-eye>
              </PvButton>
            </template>
          </PvColumn>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import ImportInvoiceDetailDialog from "../../../components/AR/invoice/ImportInvoiceDetailDialog.vue";
export default {
  emits: ["close"],
  components: {
    ImportInvoiceDetailDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.invoice.import,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.invoice.import,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
    };
  },
  data() {
    return {
      isLoading: false,
      selectedInvoice: null,
      isLock: true,
      showInvoiceDetailDialog: false,
      invoiceDetails: [],
      showProgressBar: false,
      
      titleValidateImportData: "",
      titleHeaderImportData: "",      
      baseDialogClassTitle: "",
      dataButtonDialog: [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ],
      showValidateDialog: false,

    };
  },
  mounted() {
    // console.log("importInvoiceList=", this.importInvoiceList);
    this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({ name: "ar-invoice" });
    },
    gotoDetails(details) {
      // console.log("receipt details", details);
      this.invoiceDetails = details;
      this.showInvoiceDetailDialog = true;
    },
    initiateData() {
      this.selectedInvoice = null; 
      this.baseDialogClassTitle = "";
      this.isLock = true;
      this.titleValidateImportData = "";
      this.titleHeaderImportData = this.lang.notificationDialogHeader;
      this.showValidateDialog = false;
    },    
    closeDialog() {
      this.showValidateDialog = false;
      this.showProgressBar = false;
    },
    async importAll() {
      if(!this.importInvoiceList){
        return true;
      }
      let isSuccess = false;
      let checkDataImportSuccess = this.importInvoiceList.filter((c) => c.status == "success");
      if(this.importInvoiceList.length > 0 && checkDataImportSuccess.length == this.importInvoiceList.length){
        this.titleHeaderImportData = this.lang.notificationDialogHeader;
        this.titleValidateImportData = this.lang.itemsImportAllSuccess;
        this.baseDialogClassTitle = "";
        this.showValidateDialog = true;
        return true;
      }

      this.showProgressBar = true;
      for (const importInvoice of this.importInvoiceList) {
        if(importInvoice.status != "success"){
          isSuccess = await this.saveInvoice(importInvoice);          
        }
        //หากมีรายดารใด addReceipt ไม่สำเร็จก็จะหยุดทันที
        if (!isSuccess) {
          this.showProgressBar = false;
          return;
        }
      }

      if (isSuccess) {
        this.baseDialogClassTitle = "p-text-success";
        this.titleHeaderImportData = this.lang.importInvoiceSuccess;
        this.titleValidateImportData = "";
        this.showValidateDialog = true;
      }

      this.showProgressBar = false;
    },
    async saveInvoice(data) {
      // console.log("saveInvoice=", data);
      try {
        let payload = {
          projectId: this.selectedProject.id,
          unitId: data.unitId,
          customerId: data.customerId,
          employeeId: this.employee.id,
          date: data.date,
          duedate: data.duedate,
          remark1: data.remark1,
          remark2: data.remark2,
          details: data.details,
          postStep: this.isLock == true ? "P" : null,
          type: "Add",
        };
        // console.log("payload=", payload);

        await this.$store.dispatch(
          "arInvoice/createOrUpdateInvoice",
          payload
        );

        data.markStatus = "1";
        data.status = "success";
        data.error = "";
        return true;
      } catch (error) {
        this.titleHeaderImportData = this.lang.notificationDialogHeader;
        this.titleValidateImportData = error;
        this.baseDialogClassTitle = "";
        this.showValidateDialog = true;
        data.error = "error";
        data.status = this.lang.error;
        return false;
      }
    },
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    employee() {
      return this.$store.getters.employee;
    },
    importInvoiceList() {
      return this.$store.getters["arInvoice/importInvoiceList"];
    },
    valueObj() {
      if (this.importInvoiceList) {
        return this.importInvoiceList;
      }
      return null;
    },
  },
};
</script>

<style></style>
