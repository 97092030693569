export default {
  async pendingUsePrepaidDeposit(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Invoice/PendingUsePrepaidDeposit";
    if (payload.projectId) {
      url = url + "?ProjectId=" + encodeURI(payload.projectId);
    }
    if (payload.unitId) {
      url = url + "&UnitId=" + encodeURI(payload.unitId);
    }
    if (payload.isUsePrepaid) {
      let selectedPrepaid = payload.isUsePrepaid == "Y" ? true : false;
      url = url + "&IsUsePrepaid=" + selectedPrepaid;
    }    
    if (payload.isUseDeposit) {
      let selectedDeposit = payload.isUseDeposit == "Y" ? true : false;
      url = url + "&IsUseDeposit=" + selectedDeposit;
    }
    const token = context.rootGetters.token;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to get closingEntry"
      );
      throw error;
    }
    context.commit("setUsePrepaidAndDeposits", responseData);
  },  
  resetUsePrepaidAndDeposits(context) {
    context.commit("resetUsePrepaidAndDeposits");
  },
  async unitExistPrepaids(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Prepaid/UnitExistPrepaid";
    if (payload.projectId) {
      url = url + "?ProjectId=" + encodeURI(payload.projectId);
    }
    const token = context.rootGetters.token;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to get closingEntry"
      );
      throw error;
    }
    context.commit("setUnitExistPrepaids", responseData.data);
  },  
  resetUnitExistPrepaids(context) {
    context.commit("resetUnitExistPrepaids");
  },
  async pendingUsePrepaid(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + `/Prepaid/PendingUsePrepaid`;
    const fallbackErrorMessage = `Failed to add pending use prepaid`;
    const token = context.rootGetters.token;
    const response = await fetch(url, {
      method: 'Post',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });
    const responseData = await response.json();
    if (!response.ok) {
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }
    context.commit("setSelectedUsePrepaid", responseData);
  },
};
