export default {
    setOutstandingList(state, payload) {
        state.outstandingList = payload.data;
    },
    setResidentList(state, payload) {
        state.residentList = payload.data;
    },
    setParcelList(state, payload) {
        state.parcelList = payload.data;
    },
}