export default {
  productArProducts(state) {
    return state.productAR.products;
  },
  productArCurrentPage(state) {
    return state.productAR.currentPage;
  },
  productArNextPage(state) {
    return state.productAR.currentPage + 1;
  },
  productArTotalPage(state) {
    return state.productAR.totalPage;
  },
  productArIsLastPage(_, getters) {
    return getters.productArCurrentPage === getters.productArTotalPage && getters.productArTotalPage !== 0;
  }
};
