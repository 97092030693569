export default {
  async getAccountingFrequentlies(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/AccountingFrequently";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to get accounting frequently"
      );
      throw error;
    }
    // console.log('accountingFrequently',responseData);

    const accountingFrequently = {
      accountingFrequentlies: responseData.data,
      totalPage: responseData.page.pageCount == 0 ? 1 : responseData.page.pageCount,
      currentPage: responseData.page.currentPage,
    };

    context.commit("setAccountingFrequently", accountingFrequently);
  },
  async getAllAccountingFrequentlies(context) {
    const projectCode = context.rootGetters.selectedProject.code;
    const usercode = context.rootGetters.userCode;
    while (!context.getters.accountingFrequentlyIsLastPage) {
      const localSearch = localStorage.getItem(`erp_search_accountingEntryList_${projectCode}_${usercode}`);
      let search = JSON.parse(localSearch);

      let where = {
        projectId: context.rootGetters.selectedProject.id,
        glbCode: search.generalLedgerBookCode,
      };
      const payload = {
        page: context.getters.accountingFrequentlyNextPage || 1,
        where,
      };
      await context.dispatch("getAccountingFrequentlies", payload);
    }
  },
  resetAccountingFrequently(context) {
    context.commit("resetAccountingFrequently");
  },
};
