<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoSave="askForSave"
  >
  </base-toolbar>
  <!-- @gotoDelete="askForDelete" -->
  <!-- <base-dialog
    v-model:visible="showDeleteDialog"
    :titleConfirm="lang.notificationDialogHeader"
		:wordingConfirm="lang.confirmDeleteBookBank"
    @confirm="deleteBookBank"
    @close="showDeleteDialog = false"
  ></base-dialog> -->
  <base-dialog
    v-model:visible="showConfirmDialog"
    :titleConfirm="lang.confirmSaveBookBankDialogHeader"
		:wordingConfirm="lang.confirmSaveBookBank"
    @confirm="save"
    @close="showConfirmDialog = false"
  ></base-dialog>
  <base-container>

    <base-dialog
      v-model:visible="showValidateDialog"
      :classTitle="baseDialogClassTitle"
      :titleConfirm="titleConfirmDialog"
      :wordingConfirm="validateWordingDialog"
      :dataButtons="dataButtonDialog"
      @close="showValidateDialog = false"
    ></base-dialog>

    <div class="surface-card shadow-2 border-round mt-5 mb-5">
      <base-card
        :titleHeader="lang.bookBankInformation"
        :isVisibleExpandButton="true"
        :isHideBackground="true"
        :isVisibleCard="true"
        class="pb-0"
      >
        <section class="flex flex-wrap fontlabel" :class="textFormClass">
          <div
            class="lg:col-6 md:col-12 col-12 pl-0 pr-2 pt-3 pb-0 lg:pr-4 md:pr-4"
          >
            <label for="bookBankType">{{ lang.bookBankType }}</label>
            <div class="flex flex-wrap">
              <div
                v-for="bookBankType of itemBookBankType"
                :key="bookBankType.code"
                class="field-radiobutton pt-3 mr-5"
              >
                <PvRadioButton
                  class="mr-3"
                  :inputId="bookBankType.code"
                  name="bookBankType"
                  :value="bookBankType.code"
                  v-model="selectedBookBankType"
                />
                <label :for="bookBankType.code">{{ bookBankType.name }}</label>
              </div>
            </div>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="depositPeriod">{{ lang.depositPeriod }}</label>
            <PvInputNumber
              id="depositPeriod"
              class="inputfield w-full mt-2"
              inputClass="text-left"
              v-model="depositPeriod"
              @focus="$event.target.select()"
              :placeholder="lang.validateDepositPeriod"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="bank">{{ lang.bank + "*" }}</label>
            <base-bank-auto-complete
              id="bank"
              v-model="selectedBank"
              class="inputfield w-full mt-2"
              @load-finish="bankDataReady"
              :class="{ 'p-invalid': bankError }"
              :placeholder="lang.validateBank"
              @item-select="bankError = false"
            />
            <small v-if="bankError" id="bank-help" class="p-error">{{
              lang.validateBank
            }}</small>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="branch">{{ lang.branch }}</label>
            <PvInputText
              type="text"
              class="inputfield w-full mt-2"
              v-model="branch"
              :placeholder="lang.validateBranch"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="bookBankName">{{ lang.bookBankName + "*" }}</label>
            <PvInputText
              type="text"
              class="inputfield w-full mt-2"
              v-model="bookBankName"
              :placeholder="lang.validateBookBankName"
              :class="{ 'p-invalid': bookBankNameError }"
              @keyup="bookBankNameError = false"
            />
            <small
              v-if="bookBankNameError"
              id="bookBankName-help"
              class="p-error"
              >{{ lang.validateBookBankName }}</small
            >
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="bookBankNo">{{ lang.bookBankNo + "*" }}</label>
            <PvInputText
              type="text"
              class="inputfield w-full mt-2"
              v-model="bookBankNo"
              :placeholder="lang.validateBookBankNo"
              :class="{ 'p-invalid': bookBankNoError }"
              @keyup="bookBankNoError = false"
              @keypress="checkInputBookBankNo"
            />
            <small
              v-if="bookBankNoError"
              id="bookBankNo-help"
              class="p-error"
              >{{ lang.validateBookBankNo }}</small
            >
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <div class="row flex align-items-center pt-2">
              <PvCheckbox
                id="selectedIsForTransfer"
                v-model="selectedIsForTransfer"
                :binary="true"
              />
              <label class="pl-3">{{ lang.isForTransfer }}</label>
            </div>
          </div>
        </section>
      </base-card>

      <base-card
        :titleHeader="lang.bookBankDetail"
        :isVisibleExpandButton="true"
        :isHideBackground="true"
        :isVisibleCard="true"
        class="pt-3"
      >
        <section class="flex flex-wrap fontlabel" :class="textFormClass">
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <base-input-text-limit
              :caption="lang.code + '*'"
              :limitMessage="15"
              :isError="codeError"
              :errorMessage="lang.validateCode"
              :data="code"
              :textHolder="lang.validateCode"
              @data="updateCode"
            />
          </div>

          <div
            class="lg:col-6 md:col-12 col-12 pl-0 pr-2 pt-3 pb-0 lg:pr-4 md:pr-4"
          >
            <label for="bookBankUseType">{{ lang.bankBookUseType }}</label>
            <div class="flex flex-wrap">
              <div
                v-for="bookBankUseType of itemBookBankUseType"
                :key="bookBankUseType.code"
                class="field-radiobutton pt-3 mr-5"
              >
                <PvRadioButton
                  class="mr-3"
                  :inputId="bookBankUseType.code"
                  name="bookBankUseType"
                  :value="bookBankUseType.code"
                  v-model="selectedBookBankUseType"
                />
                <label :for="bookBankUseType.code">{{
                  bookBankUseType.name
                }}</label>
              </div>
            </div>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="depositName">{{ lang.depositName }}</label>
            <PvInputText
              type="text"
              class="inputfield w-full mt-2"
              v-model="depositName"
              :placeholder="lang.validateDepositName"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="accounting">{{ lang.accounting }}</label>
            <base-accounting-detail-auto-complete
              id="accounting"
              v-model="selectedAccounting"
              class="inputfield w-full mt-2"
              @load-finish="accountingDataReady"
              :placeholder="lang.validateAccointing"
              :isLoadingData="true"
              @is-inactive="selectedAccounting = null"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="objective">{{ lang.objective }}</label>
            <PvInputText
              type="text"
              class="inputfield w-full mt-2"
              v-model="objective"
              :placeholder="lang.validateObjective"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="depositAmount">{{ lang.depositAmount }}</label>
            <PvInputNumber
              id="depositAmount"
              class="inputfield w-full mt-2"
              inputClass="text-left"
              v-model="depositAmount"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              placeholder="0.00"
              @focus="$event.target.select()"
              :class="{ 'p-invalid': depositAmountError }"
              @keyup="depositAmountError = false"
            />
            <small
              v-if="depositAmountError"
              id="depositAmount-help"
              class="p-error"
              >{{ lang.validateDepositAmount }}
            </small>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="interestRate">{{ lang.interestRate }}</label>
            <PvInputNumber
              id="interestRate"
              class="inputfield w-full mt-2"
              inputClass="text-left"
              v-model="interestRate"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              placeholder="0.00"
              @focus="$event.target.select()"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="balanceAmount">{{ lang.balanceAmount }}</label>
            <PvInputNumber
              id="balanceAmount"
              class="inputfield w-full mt-2"
              inputClass="text-left"
              v-model="balanceAmount"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              placeholder="0.00"
              @focus="$event.target.select()"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="beginDate">{{ lang.beginDate }}</label>
            <PvCalendar
              id="beginDate"
              v-model="beginDate"
              class="inputfield w-full mt-2"
              showIcon
              dateFormat="dd/mm/yy"
              :placeholder="lang.validateDate"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="dueDate">{{ lang.dueDate }}</label>
            <PvCalendar
              id="dueDate"
              v-model="dueDate"
              class="inputfield w-full mt-2"
              showIcon
              dateFormat="dd/mm/yy"
              :placeholder="lang.validateDate"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0 pt-0 flex flex-wrap">
            <div
              class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4"
            >
              <label for="remark">{{ lang.remark }}</label>
              <PvInputText
                type="text"
                class="inputfield w-full mt-2"
                v-model="remark"
                :placeholder="lang.validateRemark"
              />
            </div>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="activeStatus">{{ lang.activeStatus }}</label>
            <div class="mt-3">
              <PvInputSwitch v-model="activeStatus" class="ml-2" />
            </div>
          </div>          
        </section>
      </base-card>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import usePageDirty from "../../../hooks/pageDirty.js";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
export default {
  props: ["id", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.bookBank.listing,
          ...lang.th.master.bookBank.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.bookBank.listing,
          ...lang.en.master.bookBank.edit,
        };
      }
    });

    const { compareObject } = usePageDirty();
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,

      compareObject,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
    };
  },
  data() {
    return {
      dataButtons: [
        // {
        //   id: "deleteBookBank",
        //   caption: this.lang.deleteBookBank,
        //   class: "mr-3 p-button-toolbar p-button-outlined p-stroke-primary",
        //   icon: "icon-trash-delete-bin",
        //   iconColor: "",
        //   action: "gotoDelete",
        //   isShow: true,
        // },
        {
          id: "saveBookBank",
          action: "gotoSave",
          type: "save",
        },
      ],
      itemBookBankType: [
        { name: this.lang.bookBankTypeC, code: "C" },
        { name: this.lang.bookBankTypeS, code: "S" },
        { name: this.lang.bookBankTypeF, code: "F" },
      ],
      selectedBookBankType: null,
      depositPeriod: null,
      selectedBank: null,
      bankError: false,
      branch: "",
      bookBankName: "",
      bookBankNameError: false,
      bookBankNo: "",
      bookBankNoError: false,
      code: "",
      codeError: false,
      itemBookBankUseType: [
        { name: this.lang.bankBookUseTypeR, code: "R" },
        { name: this.lang.bankBookUseTypeP, code: "P" },
        { name: this.lang.bankBookUseTypeB, code: "B" },
      ],
      selectedBookBankUseType: null,
      depositName: "",
      isSharedAccountingReady: false,
      selectedAccounting: null,
      objective: "",
      depositAmount: 0,
      interestRate: 0,
      balanceAmount: 0,
      dueDate: null,
      remark: "",
      isSharedBankReady: false,
      depositAmountError: false,
      activeStatus: false,
      selectedIsForTransfer: false,

      objToCompare: null,
      showDeleteDialog: false,
      showConfirmDialog: false,

      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirmDialog: this.lang.notificationDialogHeader,
      validateWordingDialog: "",
      beginDate: null,
    };
  },
  async mounted() {
    this.initiateData();
    this.setLoading(true);
    if (!this.isCreateDocument) {
      await this.getBookBank();
    }
    // console.log("created", this.selectedBookBank);
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.compareObject(this.objToCompare, this.payload);
      this.$router.push({name: "book-bank"});
    },
    initiateData() {
      this.selectedBookBankType = "C";
      this.depositPeriod = null;
      this.selectedBank = null;
      this.bankError = false;
      this.branch = "";
      this.bookBankName = "";
      this.bookBankNameError = false;
      this.bookBankNo = "";
      this.bookBankNoError = false;
      this.selectedIsForTransfer = false;
      this.code = "";
      this.codeError = false;
      this.selectedBookBankUseType = "R";
      this.depositName = "";
      this.selectedAccounting = null;
      this.objective = "";
      this.depositAmount = 0;
      this.depositAmountError = false;
      this.interestRate = 0;
      this.balanceAmount = 0;
      this.dueDate = null;
      this.activeStatus = true;
      this.remark = "";
      this.$store.dispatch("bookBank/resetSelectedBookBank");
      this.clearValidateDialog();
      this.beginDate = null;
    },
    validateData() {
      let returnStatus = false;

      if (!this.selectedBank) {
        this.bankError = true;
        returnStatus = true;
      }

      if (!this.bookBankName) {
        this.bookBankNameError = true;
        returnStatus = true;
      }

      if (!this.bookBankNo) {
        this.bookBankNoError = true;
        returnStatus = true;
      }

      if (!this.code) {
        this.codeError = true;
        returnStatus = true;
      }

      // if (this.depositAmount <= 0) {
      //   this.depositAmountError = true;
      //   return true;
      // }
      if (returnStatus) {
        this.clearValidateDialog();
        this.baseDialogClassTitle = "p-text-error";
        this.titleConfirmDialog = this.lang.validateSaveTitle;
        this.validateWordingDialog = this.wordingValidate();
        this.showValidateDialog = true;
      }

      return returnStatus;
    },
    wordingValidate(){
      let wording = this.lang.validateFieldNoValue;

      if (!this.selectedBank) {
        return this.lang.validateDropDownNoSelect + this.lang.bank;
      }

      if (!this.bookBankName) {
        return wording + this.lang.bookBankName;
      }

      if (!this.bookBankNo) {
        return wording + this.lang.bookBankNo;
      }

      if (!this.code) {
        return wording + this.lang.code;
      }
    },
    validateRole() {
      this.clearValidateDialog();
      if (this.isCreateDocument && !this.isRoleCreate) {
        this.baseDialogClassTitle = "";
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.validateWordingDialog = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return true;
      } else if (!this.isCreateDocument && !this.isRoleUpdate) {
        this.baseDialogClassTitle = "";
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.validateWordingDialog = this.lang.validateRoleUpdate;
        this.showValidateDialog = true;
        return true;
      }
    },
    askForSave() {
      if (this.validateRole()) {
        return;
      }

      if (this.validateData()) {
        return;
      }

      if(!this.isCreateDocument) {
        this.showConfirmDialog = true;
      } else {
        this.save();
      }
    },
    async save() {
      if(!this.isCreateDocument) {
        this.showConfirmDialog = false;
      }

      this.setLoading(true);

      try {
        // console.log("save", this.payload);
        await this.$store.dispatch("bookBank/createOrUpdateBookBank", this.payload);

        this.setLoading(false);
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirmDialog = this.titleSaveSuccess;
        this.validateWordingDialog = this.selectedBookBank.code + " " + this.selectedBookBank.name;
        this.showValidateDialog = true;

        if (this.isCreateDocument) {
          this.$router.replace({name: "book-bank-edit", params:{ id: this.selectedBookBank.id}});
        }

      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.validateWordingDialog = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    // askForDelete() {
    //   this.showDeleteDialog = true;
    // },
    // async deleteBookBank() {
    //   this.showDeleteDialog = false;
    //   this.setLoading(true);

    //   let payload = {
    //     id: this.id,
    //   }

    //   try {
    //     await this.$store.dispatch("bookBank/deleteBookBank", payload);

    //     this.$toast.add({
    //       severity: "success",
    //       summary: this.lang.deleteBookBankSuccess,
    //       detail: this.selectedBookBank.code + " " + this.selectedBookBank.name,
    //       life: 5000,
    //     });
        
    //   } catch (error) {
    //     this.$toast.add({
    //       severity: "error",
    //       summary: this.lang.notificationDialogHeader,
    //       detail: error,
    //       life: 5000,
    //     });
    //   }

    //   this.setLoading(false);
    // },
    accountingDataReady() {
      this.isSharedAccountingReady = true;
      this.checkShouldSetData();
    },
    bankDataReady() {
      this.isSharedBankReady = true;
      this.checkShouldSetData();
    },
    async checkShouldSetData() {
      if (this.isSharedRoleByMenuReady && this.isSharedAccountingReady && this.isSharedBankReady) {
        if(this.selectedBookBank) {
          this.setData();
        } else {
          this.setLoading(false);
        }
        await this.setCompareData();
      }
    },
    setData() {
      this.selectedBookBankType = this.selectedBookBank.bankBookType;
      this.depositPeriod = this.selectedBookBank.day ? this.selectedBookBank.day : null;
      this.selectedBank = this.sharedBanks.find(
        (bank) => bank.id === this.selectedBookBank.bankId);
      this.branch = this.selectedBookBank.branchName;
      this.bookBankName = this.selectedBookBank.name;
      this.bookBankNo = this.selectedBookBank.bookNo;
      this.selectedIsForTransfer = this.selectedBookBank.isForTransfer == "Y" ? true : false;
      this.code = this.selectedBookBank.code;
      this.selectedBookBankUseType = this.selectedBookBank.bankBookUseType;
      this.depositName = this.selectedBookBank.depositName;
      this.selectedAccounting = this.sharedAccounts.find(
        (account) => account.id === this.selectedBookBank.accountId);
      this.objective = this.selectedBookBank.objective;
      this.depositAmount = this.selectedBookBank.beginAmount;
      this.interestRate = Number(this.selectedBookBank.interestRate);
      this.balanceAmount = this.selectedBookBank.balanceAmount;
      this.dueDate = this.selectedBookBank.dueDate ? new Date(this.selectedBookBank.dueDate) : "";
      this.activeStatus = this.selectedBookBank.activeStatus == "Y" ? true : false;
      this.remark = this.selectedBookBank.remark;
      this.beginDate = this.selectedBookBank.beginDate ? new Date(this.selectedBookBank.beginDate) : "";

      this.setLoading(false);
    },
    async setCompareData() {
      let objToCompare = {
        projectId: this.selectedProject.id,
        code: this.code,
        name: this.bookBankName,
        bookNo: this.bookBankNo,
        bankId: this.selectedBank ? this.selectedBank.id : "",
        branchName: this.branch,
        isForTransfer: this.selectedIsForTransfer == true ? "Y" : "N",
        bankBookType: this.selectedBookBankType,
        bankBookUseType: this.selectedBookBankUseType,
        beginDate: this.beginDate ? this.formatDateToISOString(this.beginDate) : null,
        day: this.depositPeriod ? this.depositPeriod : null,
        beginAmount: this.depositAmount ? this.depositAmount : 0,
        dueDate: this.dueDate ? this.formatDateToISOString(this.dueDate) : null,
        depositName: this.depositName,
        interestRate: this.interestRate ? this.interestRate.toString() : "",
        objective: this.objective,
        remark: this.remark,
        balanceAmount: this.balanceAmount ? this.balanceAmount : 0,
        activeStatus: this.activeStatus == true ? "Y" : "N",
        accountId: this.selectedAccounting ? this.selectedAccounting.id : "",
        odaccountId: "",
        type: "Add",
      };
      if (!this.isCreateDocument) {
        objToCompare = { ...objToCompare, id: this.id, type: "Update" };
      }
			this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
    },
    checkInputBookBankNo($event) {
      // console.log($event.key); 
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-'];
      const keyPressed = $event.key;
      if (!keysAllowed.includes(keyPressed)) {
        $event.preventDefault()
      }
    },
    async roleByMenuDataReady() {
      if (this.isSharedRoleByMenuReady) {
        await this.getBookBank();
      }
    },
    async getBookBank() {
      this.setLoading(true);

      const where = {
        projectId: this.selectedProject.id,
        id: this.id,
      };

      try {
        await this.$store.dispatch("bookBank/getBookBank", { where });
      } catch (error) {
        this.clearValidateDialog();
        this.baseDialogClassTitle = "";
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.validateWordingDialog = error.message;
        this.showValidateDialog = true;
        this.setLoading(true);
      }
    },
    clearValidateDialog() {
      this.showValidateDialog = false;
      this.baseDialogClassTitle = "";
      this.titleConfirmDialog = this.lang.notificationDialogHeader;
      this.validateWordingDialog = "";
    },
    updateCode(data) {
      this.code = data;
      this.codeError = false;
    },
  },
  computed: {
    payload() {
      let payload = {
        projectId: this.selectedProject.id,
        code: this.code,
        name: this.bookBankName,
        bookNo: this.bookBankNo,
        bankId: this.selectedBank ? this.selectedBank.id : "",
        branchName: this.branch,
        isForTransfer: this.selectedIsForTransfer == true ? "Y" : "N",
        bankBookType: this.selectedBookBankType,
        bankBookUseType: this.selectedBookBankUseType,
        beginDate: this.beginDate ? this.formatDateToISOString(this.beginDate) : null,
        day: this.depositPeriod ? this.depositPeriod : null,
        beginAmount: this.depositAmount ? this.depositAmount : 0,
        dueDate: this.dueDate ? this.formatDateToISOString(this.dueDate) : null,
        depositName: this.depositName,
        interestRate: this.interestRate ? this.interestRate.toString() : "",
        objective: this.objective,
        remark: this.remark,
        balanceAmount: this.balanceAmount ? this.balanceAmount : 0,
        activeStatus: this.activeStatus == true ? "Y" : "N",
        accountId: this.selectedAccounting ? this.selectedAccounting.id : "",
        odaccountId: "",
        type: "Add",
      }

      if (!this.isCreateDocument) {
        payload = { ...payload, id: this.id, type: "Update" };
      }

      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    isCreateDocument() {
      return this.id === "create";
    },
    title() {
      let result = this.lang.toolbarEditBookBank;
      if (this.isCreateDocument) {
        result = this.lang.toolbarCreateBookBank;
      }
      return result;
    },
    sharedAccounts() {
      return this.$store.getters["shared/accountingDetailAccounts"];
    },
    sharedBanks() {
      return this.$store.getters["shared/banks"];
    },
    selectedBookBank() {
      return this.$store.getters["bookBank/selectedBookBank"];
    },
    titleSaveSuccess() {
      let result = this.lang.editBookBankSuccess;
      if (this.isCreateDocument) {
        result = this.lang.saveBookBankSuccess; 
      }

      return result;
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmBKB");
    },
    isRoleCreate() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    isRoleUpdate() {
      if (this.roleByMenu && this.roleByMenu.update == "Y") {
        return true;
      } else {
        return false;
      }
    },
    dataButtonDialog() {
      return [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
    },
  },
  watch: {
    selectedBookBank() {
      this.checkShouldSetData();
    },
    isSharedRoleByMenuReady() {
      this.checkShouldSetData();
    },
    payload(curValue) {
			if (this.objToCompare) this.compareObject(this.objToCompare, curValue);
		},
  }
};
</script>
