<template>
  <base-toolbar :title="title" @goBack="goBack"> </base-toolbar>

  <base-dialog
    v-model:visible="showConfirmDialog"
    :titleConfirm="titleConfirmDialog"
    :wordingConfirm="wordingDialog"
    @confirm="confirm"
    @close="close"
  ></base-dialog>
  <unit-product-setup-for-invoice-dialog
    v-model:visible="showUnitProductSetup"
    @close="showUnitProductSetup = false"
    :id="UnitProductSetupId"
    :unitId="unitId"
    :openFrom="openFrom"
    :selectedUnitProduct="selectedUnitProduct"
  />

  <base-container>
    <base-dialog
      v-model:visible="showAlertDialog"
      :titleConfirm="confirmTitle"
      :wordingConfirm="confirmWording"
      :classTitle="confirmClassTitle"
      :dataButtons="dialogDataButtons"
      @close="showAlertDialog = false"
    ></base-dialog>
    <div class="surface-card shadow-2 border-round mt-5">
      <base-card
        :titleHeader="title"
        :isVisibleExpandButton="false"
        :isHideBackground="true"
        :isVisibleCard="true"
      >
        <div class="text-right">
          <PvButton :label="lang.createCaption" @click="AddUnitProduct()" class="mb-2" />
        </div>
        <div class="p-datatable-border my-2">
          <PvDataTable
            :value="unitProducts"
            dataKey="id"
            :loading="isLoading"
            v-model:selection="selectedUnitProduct"
            @row-dblclick="gotoEdit()"
            selectionMode="single"
            responsiveLayout="scroll"
            class="p-datatable-xs"
            :class="textTableClass"
          >
            <template #empty>
              <div class="w-full p-3" Style="text-align: center;">
                <label>{{ lang.tableEmpty }}</label>
              </div>
            </template>
            <PvColumn
              style="width: 10%; height: 56px"
              field="productCode"
              :header="lang.columnProductCode"
              headerClass="h-left font-sm font-normal"
              bodyClass="text-left"
            ></PvColumn>

            <PvColumn
              field="productName"
              :header="lang.columnProductName"
              style="width: 30%"
              headerClass="h-left font-sm font-normal"
            ></PvColumn>

            <PvColumn
              field="price"
              :header="lang.columnPrice"
              style="width: 10%"
              headerClass="h-center font-sm font-normal"
              bodyClass="text-right"
            >
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.price) }}
              </template>
            </PvColumn>

            <PvColumn
              field="isCurrentOwnerPaid"
              :header="lang.columnOwner"
              style="width: 15%"
              headerClass="h-left font-sm font-normal"
            >
              <template #body="slotProps">
                <span v-if="slotProps.data.isCurrentOwnerPaid === 'N'">ไม่ใช่</span>
                <span v-else>ใช่</span>
              </template>
            </PvColumn>

            <PvColumn
              field="remark"
              :header="lang.columnRemark"
              style="width: 20%"
              headerClass="h-left font-sm font-normal"
            ></PvColumn>

            <PvColumn
              style="width: 8%"
              headerClass="h-center"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <!-- <PvButton
                type="button"
                class="p-0"
                style="background: white; border: white"
                @click="confirmDelete(slotProps.data.id)"
              >
                <icon-trash-delete-bin iconColor="error" />
              </PvButton> -->
                <PvButton
                  type="button"
                  class="p-button-danger p-button-text p-0"
                  @click="confirmDelete(slotProps.data)"
                >
                  <icon-trash-delete-bin iconColor="error" />
                </PvButton>
              </template>
            </PvColumn>
            <template #footer>
              <label class="p-pagination-radius">&nbsp;</label>
            </template>
          </PvDataTable>
        </div>
      </base-card>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import UnitProductSetupForInvoiceDialog from "../../../components/master/unitProduct/UnitProductSetupForInvoiceDialog.vue";

import { useStore } from "vuex";
import { computed } from "vue";
export default {
  props: ["unitId", "projectCode"],
  components: {
    UnitProductSetupForInvoiceDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.unitProduct,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.unitProduct,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
    };
  },
  data() {
    return {
      isLoading: true,
      selectedUnitProduct: null,

      showConfirmDialog: false,
      wordingDialog: "",
      showConfirmDialogName: "",
      titleConfirmDialog: "",

      showAlertDialog: false,
      confirmTitle: "",
      confirmWording: "",
      confirmClassTitle: "",

      showUnitProductSetup: false,
      openFrom: "",
      UnitProductSetupId: "create",
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.GetUnitProduct();
    if (!this.selectedUnit) {
      await this.getUnit();
    }
    this.isLoading = false;
  },
  methods: {
    goBack() {
      this.$router.push({name: "unit-edit", params: { id: this.unitId}});
    },
    async getUnit() {
      try {
        await this.$store.dispatch("unit/getUnitById", this.unitId);
      } catch (error) {
        this.openDialog(error);
      }
    },
    async GetUnitProduct() {
      try {
        let where = {
          RoomHID: this.unitId,
        };
        const payload = {
          page: 1,
          limit: 100,
          where,
        };
        await this.$store.dispatch("masterUnitProduct/getUnitProducts", payload);
      } catch (error) {
        this.openDialog(error);
      }
    },
    AddUnitProduct() {
      this.UnitProductSetupId = "create";
      const today = new Date();
      this.openFrom = today.getSeconds();
      this.showUnitProductSetup = true;
    },
    gotoEdit() {
      this.UnitProductSetupId = this.selectedUnitProduct.id;
      const today = new Date();
      this.openFrom = today.getSeconds();
      this.showUnitProductSetup = true;
    },
    confirmDelete(data) {
      this.penaltyId = data.id;
      this.showConfirmDialogName = "confirmDelete";
      this.titleConfirmDialog = this.lang.titleConfirmDelete;
      this.wordingDialog = `${this.lang.askForDeleteProductInvoice.replace(
        ":title",
        data.productCode + " : " + data.productName
      )}`;
      this.showConfirmDialog = true;
    },
    confirm() {
      this.showConfirmDialog = false;
      if (this.showConfirmDialogName === "confirmDelete") {
        this.DeleteData();
      }
    },
    close() {
      this.showConfirmDialog = false;
    },

    async DeleteData() {
      try {
        const payload = {
          PenaltyId: this.penaltyId,
        };
        // console.log("payload=", payload);
        await this.$store.dispatch("masterUnitProduct/DeleteUnitProduct", payload);
        this.openDialog("success", this.lang.deleteSuccess);
        this.$emit("close");
      } catch (error) {
        this.openDialog("error", error);
      }
    },
    openDialog(type, massage) {
      this.confirmClassTitle = "p-" + type;
      this.confirmTitle = this.lang.notificationDialogHeader;
      this.confirmWording = massage;
      this.showAlertDialog = true;
    },
  },
  computed: {
    unitProducts() {
      return this.$store.getters["masterUnitProduct/unitProducts"];
    },
    selectedUnit() {
      return this.$store.getters["unit/selectedUnit"];
    },
    dialogDataButtons() {
      return [
        {
          id: "close",
          caption: this.lang.buttonDialogCancel,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
    },
    title() {
      let title = this.lang.title.replaceAll("{unitCodeAndAddress}", "");
      // console.log(this.selectedUnit);
      if (this.selectedUnit) {
        title = this.lang.title.replaceAll(
          "{unitCodeAndAddress}",
          this.selectedUnit.code + " : " + this.selectedUnit.addressNo
        );
      }
      return title;
    },
  },
};
</script>

<style scoped>
.p-pagination-radius {
  border-radius: 0 0 12px 12px !important;
  /* padding: 0rem !important; */
}
</style>
