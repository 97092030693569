export default {
	currencycurrencies(state) {
		return state.currency.currencies;
	},
	currencyCurrentPage(state) {
		return state.currency.currentPage;
	},
	currencyNextPage(state) {
		return state.currency.currentPage + 1;
	},
	currencyTotalPage(state) {
		return state.currency.totalPage;
	},
	currencyIsLastPage(_, getters) {
		return (
			getters.currencyCurrentPage === getters.currencyTotalPage && getters.currencyTotalPage !== 0
		);
	},
};
