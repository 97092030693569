export default {
	importUnitResidents(state) {
		return state.importUnitResidents;
	},
	units(state) {
		return state.units;
	},
	customers(state) {
		return state.customers;
	},
};
