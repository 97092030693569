<template>
  <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
    <label>{{ mergeLang.province }}</label>
    <base-address-auto-complete
      class="inputfield w-full mt-2"
      type="province"
      v-model="province"
      :placeholder="mergeLang.provincePlaceholder"
      :isLoadingData="isLoadingData"
      :class="{ 'p-invalid': isProvinceError }"
      @load-finish="addressDataReady"
      @item-select="selectAddress"
      @clear="clearProvince"
    />
    <small v-if="isProvinceError" id="isProvinceError-help" class="p-error">
      {{ mergeLang.validateProvince }}
    </small>
  </div>
  <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
    <label>{{ mergeLang.district }}</label>
    <base-address-auto-complete
      class="inputfield w-full mt-2"
      type="district"
      v-model="district"
      :placeholder="mergeLang.districtPlaceholder"
      :class="{ 'p-invalid': isDistrictError }"
      @load-finish="addressDataReady"
      @item-select="selectAddress"
      @clear="clearDistrict"
    />
    <small v-if="isDistrictError" id="districtError-help" class="p-error">
      {{ mergeLang.validateDistrict }}
    </small>
  </div>
  <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
    <label>{{ mergeLang.subdistrict }}</label>
    <base-address-auto-complete
      class="inputfield w-full mt-2"
      type="subdistrict"
      v-model="subdistrict"
      :placeholder="mergeLang.subdistrictPlaceholder"
      :class="{ 'p-invalid': isSubdistrictError }"
      @load-finish="addressDataReady"
      @item-select="selectAddress"
      @clear="clearSubdistrict"
    />
    <small v-if="isSubdistrictError" id="isSubdistrictError-help" class="p-error">
      {{ mergeLang.validateSubdistrict }}
    </small>
  </div>
  <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
    <label>{{ mergeLang.postcode }}</label>
    <base-address-auto-complete
      class="inputfield w-full mt-2"
      type="postCode"
      v-model="postCode"
      :placeholder="mergeLang.postcodePlaceholder"
      :class="{ 'p-invalid': isPostCodeError }"
      @load-finish="addressDataReady"
      @item-select="selectAddress"
      @clear="clearPostCode"
    />
    <small v-if="isPostCodeError" id="isPostCodeError-help" class="p-error">
      {{ mergeLang.validatePostcode }}
    </small>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import useLang from "../../hooks/lang.js";
import { useStore } from "vuex";
export default {
  props: {
    defaultValue: {
      type: Object,
    },
    isAddressError: {
      type: Object,
      default() {
        return {
          province: false,
          district: false,
          subdistrict: false,
          postCode: false,
        };
      },
    },
    section: {
      type: String,
      default: "address",
    },
    isLoadingData: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["addressDataReady", "itemSelected"],
  setup(props, context) {
    const province = ref(props.defaultValue.province);
    const district = ref(props.defaultValue.district);
    const subdistrict = ref(props.defaultValue.subdistrict);
    const postCode = ref(props.defaultValue.postCode);
    const selectedAddress = ref(null);

    const isProvinceError = ref(props.isAddressError.province);
    const isDistrictError = ref(props.isAddressError.district);
    const isSubdistrictError = ref(props.isAddressError.subdistrict);
    const isPostCodeError = ref(props.isAddressError.postCode);

    const { lang } = useLang();
    const store = useStore();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
        };
      } else {
        return {
          ...lang.en.base,
        };
      }
    });

    const addressDataReady = () => {
      context.emit("addressDataReady", true);
    };

    const selectAddress = (data) => {
      const address = data.value;
      province.value = address.provinceTh;
      district.value = address.districtTh;
      subdistrict.value = address.subdistrictTh;
      postCode.value = address.postCode;

      isProvinceError.value = false;
      isDistrictError.value = false;
      isSubdistrictError.value = false;
      isPostCodeError.value = false;

      selectedAddress.value = {
        province: province.value,
        district: district.value,
        subdistrict: subdistrict.value,
        postCode: postCode.value,
      };
      context.emit("itemSelected", selectedAddress.value, props.section);
    };

    const clearProvince = () => {
      province.value = "";
      district.value = district.value? district.value:"";
      subdistrict.value = subdistrict.value? subdistrict.value:"";
      postCode.value = postCode.value? postCode.value:"";

      isProvinceError.value = false;

      selectedAddress.value = {
        province: province.value,
        district: district.value,
        subdistrict: subdistrict.value,
        postCode: postCode.value,
      };
      context.emit("itemSelected", selectedAddress.value, props.section);
    };
    
    const clearDistrict = () => {
      district.value = "";
      province.value = province.value? province.value:"";
      subdistrict.value = subdistrict.value? subdistrict.value:"";
      postCode.value = postCode.value? postCode.value:"";
      isDistrictError.value = false;

      selectedAddress.value = {
        province: province.value,
        district: district.value,
        subdistrict: subdistrict.value,
        postCode: postCode.value,
      };
      context.emit("itemSelected", selectedAddress.value, props.section);
    };
    
    const clearSubdistrict = () => {
      subdistrict.value = "";
      province.value = province.value? province.value:"";
      district.value = district.value? district.value:"";
      postCode.value = postCode.value? postCode.value:"";
      isSubdistrictError.value = false;

      selectedAddress.value = {
        province: province.value,
        district: district.value,
        subdistrict: subdistrict.value,
        postCode: postCode.value,
      };
      context.emit("itemSelected", selectedAddress.value, props.section);
    };

    const clearPostCode = () => {
      postCode.value = "";
      province.value = province.value? province.value:"";
      district.value = district.value? district.value:"";
      subdistrict.value = subdistrict.value? subdistrict.value:"";
      isPostCodeError.value = false;

      selectedAddress.value = {
        province: province.value,
        district: district.value,
        subdistrict: subdistrict.value,
        postCode: postCode.value,
      };
      context.emit("itemSelected", selectedAddress.value, props.section);
    };

    return {
      province,
      district,
      subdistrict,
      postCode,

      isProvinceError,
      isDistrictError,
      isSubdistrictError,
      isPostCodeError,

      mergeLang,

      addressDataReady,
      selectAddress,
      clearProvince,
      clearDistrict,
      clearSubdistrict,
      clearPostCode,
    };
  },
};
</script>
