export default {
  createCreditNote:"สร้างใบลดหนี้",
  editCreditNote:"แก้ไขใบลดหนี้",
  creditNotePrintEditPage: "พิมพ์รายละเอียดใบลดหนี้",
  creditNoteDetail: "รายละเอียดใบลดหนี้",

  createNewCreditNote: "สร้างใบลดหนี้ใหม่",
  askForCreate: "หากคุณสร้างรายการใบลดหนี้ใหม่ระบบจะไม่บันทึกการแก้ไข",
  askForCreateConfirm: "คุณยืนยันที่จะสร้างรายการใบลดหนี้ใหม่ใช่หรือไม่?",

  code: "เลขที่เอกสาร",
	refCode: "เลขที่อ้างอิง",
	unitCode: "รหัสห้อง/บ้าน",
  employeeName:"ชื่อพนักงาน",
  pendingList: "รายการค้างชำระ",
	validateInactiveUnitSelect: "ห้องนี้ปิดการใช้งาน",
  columnBalance: "คงเหลือ",
	reduceDebt: "ลดหนี้",
  reasons: "สาเหตุ",
	columnDiffAmount: "ค้างชำระ",
  invoiceCode: "เลขที่ใบแจ้งหนี้",
	invoiceDate: "วันที่ใบแจ้งหนี้",
  paySelected: "จ่ายรายการที่เลือก",

  validateCreditNoteAmount: "ยอดลดหนี้ที่ระบุมากกว่าจำนวนเงินคงเหลือ",
  titleDetailDialog:"แก้ไขจำนวนเงินที่ต้องการลดหนี้",
  cancelReason: "สาเหตุการยกเลิก",
  validateNoCancelReason: "กรุณาระบุสาเหตุการยกเลิก",
  cancelCreditNoteSuccess: "คุณยกเลิกเอกสารใบลดหนี้ :code สำเร็จ",
	validateNetTotalAmount: "มูลค่าสุทธิ ต้องไม่น้อยกว่าหรือเท่ากับ 0",
  validateNoReasons: "กรุณาระบุสาเหตุ",
  createCreditNoteSuccess: "คุณสร้างรายการใบลดหนี้สำเร็จ",
  editCreditNoteSuccess: "คุณแก้ไขรายการใบลดหนี้สำเร็จ",
  productName: "ชื่อรายรับ",
};
