export default {
	policy(state) {
		return state.policy;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	selectedPolicy(state) {
		return state.selectedPolicy;
	},
	policyNextPage(state) {
		return state.currentPage + 1;
	},
	policyIsLastPage(_, getters) {
		if (getters.currentPage > 0 && getters.totalPage === 0) {
			return true;
		} else {
			return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
		}
	},
};
