<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ this.title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog()"
        />
      </div>
      <PvDivider></PvDivider>
    </template>
    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2" v-if="isCancelReport">
          <label for="orderBy">{{ lang.orderReport }}</label>
          <PvDropdown
            id="orderBy"
            class="inputfield w-full mt-1"
            :options="orderByOptions"
            optionLabel="name"
            optionValue="code"
            v-model="selectedOrderBy"
          />
        </div>
        <div
          class="lg:col-6 md:col-6 col-12 pl-0 pr-2"
          v-if="isCancelReport"
        ></div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateStart">{{ lang.date }}</label>
          <PvCalendar
            id="dateStart"
            v-model="dateStart"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateStartError }"
            @date-select="dateStartError = false"
          />
          <small v-if="dateStartError" id="unit-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateEnd">{{ lang.to }}</label>
          <PvCalendar
            id="dateEnd"
            v-model="dateEnd"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateEndError }"
            @date-select="dateEndError = false"
          />
          <small v-if="dateEndError" id="unit-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="unitStart">{{ lang.unitStart }}</label>
          <base-unit-auto-complete
            id="unit"
            v-model="selectedUnitStart"
            class="inputfield w-full mt-1"
            @load-finish="unitDataReady"
            :class="{ 'p-invalid': unitStartError }"
            @item-select="unitStartError = false"
          />
          <small v-if="unitStartError" id="unit-help" class="p-error">{{
            lang.validateNoUnitSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="unitEnd">{{ lang.to }}</label>
          <base-unit-auto-complete
            id="unit"
            v-model="selectedUnitEnd"
            class="inputfield w-full mt-1"
            @load-finish="unitDataReady"
            :class="{ 'p-invalid': unitEndError }"
            @item-select="unitEndError = false"
          />
          <small v-if="unitEndError" id="unit-help" class="p-error">{{
            lang.validateNoUnitSelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="codeStart">{{ lang.codeStart }}</label>
          <PvInputText
            id="codeStart"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="6501xxxxx"
            v-model="codeStart"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="codeEnd">{{ lang.codeEnd }}</label>
          <PvInputText
            id="codeEnd"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="6501xxxxx"
            v-model="codeEnd"
          />
        </div>

        <div class="col-12 pl-0 pr-2" v-if="!isCancelReport">
          <PvCheckbox
            id="isPeriod"
            inputId="binary"
            v-model="isPeriod"
            :binary="true"
            @change="changeCheckbox"
          />
          <label for="isPeriod" class="pl-3">{{ lang.period }}</label>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2" v-if="!isCancelReport">
          <label for="periodStart">{{ lang.periodStart }}</label>
          <base-period-auto-complete
            id="periodStart"
            v-model="selectedPeriodStart"
            class="inputfield w-full mt-1"
            :inputClass="{ 'w-full input-number-disable': !isPeriod }"
            :disabled="!isPeriod"
          ></base-period-auto-complete>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2" v-if="!isCancelReport">
          <label for="periodEnd">{{ lang.to }}</label>
          <base-period-auto-complete
            id="periodEnd"
            v-model="selectedPeriodEnd"
            class="inputfield w-full mt-1"
            :inputClass="{ 'w-full input-number-disable': !isPeriod }"
            :disabled="!isPeriod"
          ></base-period-auto-complete>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="productStart">{{ lang.productStart }}</label>
          <PvSkeleton
            v-if="!isSharedProductArReady"
            class="inputfield w-full mt-1"
            height="39px"
          />
          <base-product-ar-auto-complete
            id="product"
            v-model="selectedProductStart"
            class="inputfield w-full mt-1"
            @load-finish="productArDataReady"
            :class="{ 'p-invalid': productStartError }"
            @item-select="productStartError = false"
            v-show="isSharedProductArReady"
          />
          <small v-if="productStartError" id="unit-help" class="p-error">{{
            lang.validateNoProductSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="productEnd">{{ lang.to }}</label>
          <PvSkeleton
            v-if="!isSharedProductArReady"
            class="inputfield w-full mt-1"
            height="39px"
          />
          <base-product-ar-auto-complete
            id="product"
            v-model="selectedProductEnd"
            class="inputfield w-full mt-1"
            @load-finish="productArDataReady"
            :class="{ 'p-invalid': productEndError }"
            @item-select="productEndError = false"
            v-show="isSharedProductArReady"
          />
          <small v-if="productEndError" id="unit-help" class="p-error">{{
            lang.validateNoProductSelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="payType">{{ lang.payType }}</label>
          <PvDropdown
            id="payType"
            class="inputfield w-full mt-1"
            :options="payTypes"
            optionValue="code"
            optionLabel="name"
            v-model="selectedPayType"
          />
        </div>

        <div
          class="flex lg:col-6 md:col-6 col-12 pl-0 pr-2"
          v-if="!isCancelReport"
        >
          <div class="col-6 p-0 mt-5 ml-1">
            <PvCheckbox
              id="isGroup"
              inputId="binary"
              v-model="isGroup"
              :binary="true"
            />
            <label for="isGroup" class="pl-3">{{ lang.group }}</label>
          </div>
          <div class="col-6 p-0 mt-5">
            <PvCheckbox
              id="isIgnoreCancelDate"
              inputId="binary"
              v-model="isIgnoreCancelDate"
              :binary="true"
            />
            <label for="isIgnoreCancelDate" class="pl-3">{{
              lang.ignoreCancelDate
            }}</label>
          </div>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText
            id="fileNameText"
            type="text"
            class="inputfield w-full mt-1"
            v-model="fileNameText"
          />
        </div>
      </section>
    </transition>

    <template #footer>
      <div
				class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
			>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
					<PvButton
						icon="pi pi-file-pdf"
						:label="lang.downloadPDF"
						@click="download('PDF')"
						class="w-full"
					/>
				</div>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
					<PvButton
						icon="pi pi-file-excel"
						:label="lang.downloadExcel"
						@click="download('JASPEREXCEL')"
						class="w-full"
					/>
				</div>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
					<PvButton
						icon="pi pi-file-excel"
						:label="lang.downloadExcelRaw"
						@click="download('EXCEL')"
						class="w-full"
					/>
				</div>
			</div>
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useTextStyle from "../../../../hooks/textStyle.js";
import useLang from "../../../../hooks/lang.js";
import useGetFormPrint from "../../../../hooks/getFormPrint.js";
import useFormat from "../../../../hooks/format.js";
import useTracking from "../../../../hooks/tracking";
import useReportRequestValidate from "../../../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "toast-request"],
  setup() {
    const { lang } = useLang();
    const store = useStore();
    const { textFormClass } = useTextStyle();
    const { getPreviewBlob, getDownloadBlob } = useGetFormPrint();
    const { formatDateToISOString } = useFormat();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
          ...lang.th.ar.receipt.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
          ...lang.en.ar.receipt.edit,
        };
      }
    });

    return {
      lang: mergeLang,
      textFormClass,
      getPreviewBlob,
      getDownloadBlob,
      formatDateToISOString,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      payload: {},
      parameter: {},
      orderByOptions: [
        { code: "CancelDate", name: this.lang.cancelDate },
        { code: "ReceiptDate", name: this.lang.receiptDate },
      ],
      selectedOrderBy: "CancelDate",
      dateStart: null,
      dateEnd: null,
      dateStartError: false,
      dateEndError: false,
      unitStartError: false,
      unitEndError: false,
      selectedUnitStart: null,
      selectedUnitEnd: null,
      isSharedUnitReady: false,
      codeStart: "",
      codeEnd: "",
      isPeriod: false,
      selectedPeriodStart: [],
      selectedPeriodEnd: [],
      periodStart: "",
      periodEnd: "",
      selectedProductStart: null,
      selectedProductEnd: null,
      productStartError: false,
      productEndError: false,
      isSharedProductArReady: false,
      payTypes: [
        { name: this.lang.all, code: "" },
        { name: this.lang.cashType, code: "Cash" },
        { name: this.lang.transferType, code: "Transfer" },
        { name: this.lang.chequeType, code: "Cheque" },
        { name: this.lang.creditCardType, code: "CreditCard" },
        { name: this.lang.depositType, code: "Deposit" },
        { name: this.lang.ePayType, code: "E_Pay" },
        { name: this.lang.otherType, code: "Other" },
      ],
      selectedPayType: "",
      isGroup: false,
      isIgnoreCancelDate: true,
      fileNameText: "",
      docType: "",
    };
  },
  async created() {
    this.initiateData();
    this.getDocumentBook();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    initiateData() {
      this.payload = {};
      this.parameter = {};
      this.selectedOrderBy = "CancelDate";
      let date = new Date();
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date();
      this.dateStartError = false;
      this.dateEndError = false;
      this.selectedUnitStart = null;
      this.selectedUnitEnd = null;
      this.unitStartError = false;
      this.unitEndError = false;
      this.isPeriod = false;
      this.selectedPeriodStart = null;
      this.selectedPeriodEnd = null;
      this.periodStart = "";
      this.periodEnd = "";
      this.selectedProductStart = null;
      this.selectedProductEnd = null;
      this.productStartError = false;
      this.productEndError = false;
      this.selectedPayType = "";
      this.codeStart = "";
      this.codeEnd = "";
      this.isGroup = false;
      this.isIgnoreCancelDate = true;
      this.fileNameText = this.fileName;
    },
    async download(typeReport) {
      if (this.validateData()) {
        return;
      }
      this.setLoading(true);
      try {
        await this.getPayload(typeReport);

        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    async getPayload(typeReport) {
      await this.getParameter();
      this.docType = typeReport;
      let path = this.reportObj.path;

      if (typeReport == "PDF") {
        path = path + ".pdf";
      } else if (typeReport == "JASPEREXCEL") {
        path = path + ".xlsx";
      } else {
        path = path + "_excel.xlsx";
      }
      this.payload = {
        RequestId: 0,
        ReportName: "receipt_cash_control",
        FileName: this.fileNameText + this.fileType,
        JasperPath: path,
        DocType: typeReport,
        UserId: this.userId,
        PrintBy: this.userName,
        ProjectId: this.selectedProject.id,
        DocumentBookId: this.documentBook.id,
        BranchId: this.selectedProject.branchId ? this.selectedProject.branchId : "",
      };
      this.payload = {...this.payload, ...this.parameter}
    },
    async getParameter() {
      if (this.isPeriod && this.selectedPeriodStart) {
        let month = this.selectedPeriodStart.name.substring(0, 2);
        let year = this.selectedPeriodStart.name.substring(3, 7);
        this.periodStart = year + month;
      }
      if (this.isPeriod && this.selectedPeriodEnd) {
        let month = this.selectedPeriodEnd.name.substring(0, 2);
        let year = this.selectedPeriodEnd.name.substring(3, 7);
        this.periodEnd = year + month;
      }
      let parameterData = {
        ProjectId: this.selectedProject.id,
        DateStart: this.selectedDateStart,
        DateEnd: this.selectedDateEnd,
        UnitCodeStart: this.selectedUnitStart
          ? this.selectedUnitStart.code
          : "",
        UnitCodeEnd: this.selectedUnitEnd ? this.selectedUnitEnd.code : "",
        CodeStart: this.codeStart.trim(),
        CodeEnd: this.codeEnd.trim(),
        ProductCodeStart: this.selectedProductStart
          ? this.selectedProductStart.code
          : "",
        ProductCodeEnd: this.selectedProductEnd
          ? this.selectedProductEnd.code
          : "",
        PaymentMethod: this.selectedPayType,
      };

      if (!this.isCancelReport) {
        parameterData = {
          ...parameterData,
          PeriodStart: this.periodStart,
          PeriodEnd: this.periodEnd,
          IsGroup: this.isGroup,
          IsIgnoreCancelDate: this.isIgnoreCancelDate,
        };
      } else {
        parameterData = {
          ...parameterData,
          DocumentBookId: this.documentBook.id,
          DateType: this.selectedOrderBy,
          IsIgnoreCancelDate: false,
        };
      }
      this.parameter = parameterData;
    },
    validateData() {
      if (this.selectedDateStart == null) {
        this.dateStartError = true;
        return true;
      }
      if (this.selectedDateEnd == null) {
        this.dateEndError = true;
        return true;
      }
      return false;
    },
    unitDataReady() {
      this.isSharedUnitReady = true;
    },
    productArDataReady() {
      this.isSharedProductArReady = true;
      this.setData();
    },
    setData() {
      // this.setLoading(true);
      //   this.selectedUnitStart = this.units[0];
      //   this.selectedUnitEnd = this.units[this.units.length - 1];
      this.selectedProductStart = this.sharedProducts[0];
      this.selectedProductEnd =
        this.sharedProducts[this.sharedProducts.length - 1];
    },
    async getDocumentBook() {
      try {
        let where = {
          projectId: this.selectedProject.id,
          code: "INV",
        };

        const payload = {
          page: 1,
          limit: 100,
          where,
        };

        await this.$store.dispatch("documentBook/getDocumentBooks",
        payload
        );

      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 5000,
        });
      }
    },
    changeCheckbox() {
      this.selectedPeriodStart = null;
      this.selectedPeriodEnd = null;
      this.periodStart = "";
      this.periodEnd = "";
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    title() {
      if (this.$store.getters.selectedLanguage === "TH")
        return this.reportObj.nameThai;
      else return this.reportObj.nameEnglish;
    },
    fileBlob() {
      return this.$store.getters["shared/blob"];
    },
    isCancelReport() {
      let result = false;
      if (this.reportObj.path == "receipt/receipt_cancel_report") {
        result = true;
      }
      return result;
    },
    fileName() {
      let fileName = "";
      if (this.reportObj.path == "receipt/receipt_detail") {
        fileName = "receipt_detail";
      } else if (this.reportObj.path == "receipt/receipt_cancel_report") {
        fileName = "receipt_cancel_report";
      }
      return fileName;
    },
    sharedProducts() {
      return this.$store.getters["shared/productArProducts"];
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    documentBook() {
      return this.$store.getters["documentBook/documentBooks"][0];
    },
    userId() {
      return this.$store.getters.userId;
    },
    fileType() {
      let fileType = this.docType == "PDF" ? ".pdf" : ".xlsx";
      return fileType;
    },
    userName() {
      return this.$store.getters.userName;
    },
  },
  watch: {
    reportObj() {
      this.initiateData();
    },
  },
};
</script>
