export default({
    date:"วันที่เอกสาร",  
    dueDate:"โปรดชำระภายในวันที่",
    printAmountMoreThan: "พิมพ์เฉพาะใบที่มีมูลค่ามากกว่า (บาท)", 
    unitCodeStart:"เลขที่ห้อง/ยูนิต ตั้งแต่", 
    unitCodeEnd:"เลขที่ห้อง/ยูนิต ถึง", 
    letterBy:"ออกเอกสารในนาม", 
    printGroup:"พิมพ์แบบรวมรายการ", 
    suspendDate:"วันที่ระงับ",
    overDueDay:"เฉพาะที่ค้างชำระมากกว่า (วัน)",
	language: "Thai",
    setupProduct: "ตั้งค่ารายงาน",

    markUpTitle: "เพิ่มมาร์คอัพเรียกข้อมูล",
    projectNameThMarkUp: "ชื่อโครงการ ภาษาไทย",
    projectNameEnMarkUp: "ชื่อโครงการ ภาษาอังกฤษ",
    unitCodeMarkUp: "เลขยูนิต",
    unitAddressNoMarkUp: "บ้านเลขที่",
    customerNameThMarkUp: "ชื่อลูกค้า ภาษาไทย",
    customerNameEnMarkUp: "ชื่อลูกค้า ภาษาอังกฤษ",
    totalAmountMarkUp: "ค่ารวมทั้งหมด",
    totalAmountTextThMarkUp: "ค่ารวมทั้งหมด (ตัวอักษร ภาษาไทย)",
    totalAmountTextEnMarkUp: "ค่ารวมทั้งหมด (ตัวอักษร ภาษาอังกฤษ)",
    dueDateThMarkUp: "โปรดชำระภายในวันที่ พ.ศ.",
    dueDateEnMarkUp: "โปรดชำระภายในวันที่ ค.ศ.",
    suspendDateThMarkUp: "วันที่ระงับ ภาษาไทย",
    suspendDateEnMarkUp: "วันที่ระงับ ภาษาอังกฤษ",
    columnValue: "มาร์คอัพเรียกข้อมูล",
    columnName: "ชื่อข้อมูล",
})