<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7807 3.63082C17.9211 3.77144 18 3.96207 18 4.16082C18 4.35957 17.9211 4.55019 17.7807 4.69082L12.7807 9.69082C12.64 9.83127 12.4494 9.91016 12.2507 9.91016C12.0519 9.91016 11.8613 9.83127 11.7207 9.69082L6.72066 4.69082C6.58021 4.55019 6.50132 4.35957 6.50132 4.16082C6.50132 3.96207 6.58021 3.77144 6.72066 3.63082C6.86128 3.49037 7.05191 3.41148 7.25066 3.41148C7.44941 3.41148 7.64004 3.49037 7.78066 3.63082L12.2507 8.10082L16.7207 3.63082C16.8613 3.49037 17.0519 3.41148 17.2507 3.41148C17.4494 3.41148 17.64 3.49037 17.7807 3.63082Z"
      :class="strokeColor"
    />
    <path
      d="M12.7811 14.3046L17.7811 19.3046C17.9136 19.4468 17.9857 19.6349 17.9823 19.8292C17.9789 20.0235 17.9002 20.2089 17.7628 20.3463C17.6253 20.4837 17.44 20.5624 17.2457 20.5658C17.0514 20.5692 16.8633 20.4971 16.7211 20.3646L12.2511 15.8946L7.78113 20.3646C7.64051 20.5051 7.44988 20.584 7.25113 20.584C7.05238 20.584 6.86175 20.5051 6.72113 20.3646C6.58068 20.224 6.50179 20.0334 6.50179 19.8346C6.50179 19.6359 6.58068 19.4453 6.72113 19.3046L11.7211 14.3046C11.8618 14.1642 12.0524 14.0853 12.2511 14.0853C12.4499 14.0853 12.6405 14.1642 12.7811 14.3046Z"
      :class="strokeColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "primary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
