export default {
  roleByBooks(state) {
    return state.roleByBook.roleByBooks;
  },
  roleByBookCurrentPage(state) {
    return state.roleByBook.currentPage;
  },
  roleByBookTotalPage(state) {
    return state.roleByBook.totalPage;
  },
  roleByBookNextPage(state) {
    return state.roleByBook.currentPage + 1;
  },
  roleByBookIsLastPage(_, getters) {
    return getters.roleByBookCurrentPage === getters.roleByBookTotalPage && getters.roleByBookTotalPage !== 0;
  }
};
