<template>
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredLocations"
    field="codeAndName"
    @complete="searchLocation"
    @dropdown-click="filteredLocations"
    forceSelection
  />
</template>

<script>
import useGetLocation from "../../hooks/getLocation.js";
export default {
  setup() {
    const {
      filteredLocations,
      searchText,
      isSharedLocationReady,
      searchLocation,
    } = useGetLocation();

    return {
      filteredLocations,
      searchText,
      isSharedLocationReady,
      searchLocation,
    };
  },
  emits: ["load-finish"],
  watch: {
    isSharedLocationReady(curValue) {
      if (curValue) {
        this.$emit("load-finish");
      }
    },
  },
};
</script>
