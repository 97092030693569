export default {
	async getWithholdingTaxs(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/WithholdingTax";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get withholding tax");
			throw error;
		}
		// console.log(responseData);

		// const data = responseData.data;
		context.commit("setWithholdingTax", responseData);
	},
	async getWithholdingTax(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/WithholdingTax?limit=1";

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get withholding tax");
			throw error;
		}

		if (responseData.data.length > 0) {
			context.commit("setSelectedWithholdingTax", responseData.data[0]);
		}
	},
	async createOrUpdateWithholdingTax(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + `/WithholdingTax/${payload.type}`;

		const fallbackErrorMessage = `Failed to ${payload.type} withholding tax`;
		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: payload.type === "Add" ? "post" : "put",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},

			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			let message = null;
			if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
				message = responseData.title;
				for (const key in responseData.errors) {
					message += ", " + key.replace("$.", "");
				}
			} else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
				message = responseData.message;
			}
			const error = new Error(message || fallbackErrorMessage);
			throw error;
		}

		context.commit("setSelectedWithholdingTax", responseData.data);
	},
	resetWithholdingTax(context) {
		context.commit("setSelectedWithholdingTax", null);
	},
	async cancelWithholdingTax(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + `/WithholdingTax/Cancel`;
		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "put",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},

			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			let message = null;
			if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
				message = responseData.title;
				for (const key in responseData.errors) {
					message += ", " + key.replace("$.", "");
				}
			} else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
				message = responseData.message;
			}
			const error = new Error(message || "Failed to cancel withholding tax");
			throw error;
		}

		context.commit("setSelectedWithholdingTax", responseData.data);
	},
	async PrintWithholdingTax(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/WithholdingTax/Print";

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
			mode: "cors", // no-cors, *cors, same-origin
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			credentials: "same-origin", // include, *same-origin, omit
			redirect: "follow", // manual, *follow, error
			referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
		});

		let urlBlob = "";
		let blob = await response.blob();
		urlBlob = window.URL.createObjectURL(blob);
		context.commit("setBlob", urlBlob);
	},
	async getWithholdingTaxWithVouchers(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/WithholdingTax/withVoucher";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get withholding tax");
			throw error;
		}
		// console.log("responseData",responseData);

		// const data = responseData.data;
		context.commit("setWithholdingTax", responseData);
	},
	async deleteWithholdingTaxWithVoucher(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + `/WithholdingTax/DeleteLinkVoucher`;
		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "put",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},

			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			let message = null;
			if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
				message = responseData.title;
				for (const key in responseData.errors) {
					message += ", " + key.replace("$.", "");
				}
			} else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
				message = responseData.message;
			}
			const error = new Error(message || "Failed to cancel withholding tax");
			throw error;
		}

		context.commit("setSelectedWithholdingTax", responseData.data);
	},
	resetWithholdingTaxList(context) {
		context.commit("resetWithholdingTaxList");
	},

	async getAllWithholdingTaxsImport(context, payload) {
		context.commit("resetWithholdingTaxsForImport");
		while (!context.getters.withholdingTaxsForImportIsLastPage) {
			// let where = payload.where;
			const payloadAll = {
				limit: payload.limit,
				page: context.getters.withholdingTaxsForImportNextPage || 1,
				where: payload.where,
			};
			await context.dispatch("getWithholdingTaxsImport", payloadAll);
		}
	},
	async getWithholdingTaxsImport(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/WithholdingTax/withImport";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get withholding tax");
			throw error;
		}
		// console.log(responseData);

		// const data = responseData.data;
		responseData.data = [...responseData.data, ...context.getters.withholdingTaxsForImport];
		context.commit("setWithholdingTaxsForImport", responseData);
	},
	resetWithholdingTaxsImport(context) {
		context.commit("resetWithholdingTaxsImport");
	},
	setWithholdingTaxsImport(context, payload) {
		context.commit("setWithholdingTaxsImport", payload);
	},
	async addWithholdingTaxImport(context, payload) {
		// console.log('AddBankTransaction',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/WithholdingTax/addWithImport";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("addWithholdingTaxImport>>>",responseData);
			const error = new Error(responseData.message || "Failed to Add Bank Voucher");
			throw error;
		}
		// console.log("addWithholdingTaxImport>>>",responseData.data);
		await context.commit("setSelectedWithholdingTax", responseData.data);
	},
	async getWithholdingTaxWithVoucher(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/WithholdingTax/withVoucher?limit=1";

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get withholding tax");
			throw error;
		}
		// console.log( responseData.data[0]);

		if (responseData.data.length > 0) {
			context.commit("setSelectedWithholdingTaxWithVoucher", responseData.data[0]);
		}
	},
	resetWithholdingTaxWithVoucher(context) {
		context.commit("setSelectedWithholdingTaxWithVoucher", null);
	},
};
