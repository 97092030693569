export default {
  setAccountBook(state, payload) {
    state.accountBooks = payload.data;
    state.totalPage = payload.page.pageCount;
    state.currentPage = payload.page.currentPage;
  },
  setSelectedAccountBook(state, payload) {
    state.selectedAccountBook = payload;
  },
  resetAccountBook(state) {
    state.accountBooks = [];
    state.totalPage = 0;
    state.currentPage = 0;
  },
};