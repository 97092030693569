<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '640px': '80vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <span class="font-lg">{{ lang.title }}</span>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <section class="flex flex-wrap" :class="textFormClass">
      <div class="flex flex-wrap lg:col-12 md:col-12 col-12 p-0">
        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('date') }"
          >
            {{ lang.depositDate }}
          </label>
          <div class="mt-2">
            <label
              for="date"
              class="font-md"
              :class="{ 'p-text-error': checkError('date') }"
            >
              {{ deposit.date&&!checkError('date')? formatDate(deposit.date, false):deposit.date }}
            </label>
          </div>
        </div>

        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('unitCode') }"
          >
            {{ lang.unitCode }}
          </label>
          <div class="mt-2">
            <label
              for="unitCode"
              class="font-md"
              :class="{ 'p-text-error': checkError('unitCode') }"
            >
              {{ deposit.unitCode }}</label
            >
          </div>
        </div>

        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('payAmount') }"
          >
            {{ lang.depositAmount }}
          </label>
          <div class="mt-2">
            <label
              for="payAmount"
              class="font-md"
              :class="{ 'p-text-error': checkError('payAmount') }"
              >{{ !checkError('payAmount')? formatCurrency(deposit.payAmount):deposit.payAmount }}</label
            >
          </div>
        </div>

        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('payType') }"
          >
            {{ lang.depositType }}
          </label>
          <div class="mt-2">
            <label
              for="payType"
              class="font-md"
              :class="{ 'p-text-error': checkError('payType') }"
            >
              {{ deposit.payType }}
            </label>
          </div>
        </div>

        <div class="lg:col-12 md:col-12 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('bookBankCode') }"
          >
            {{ lang.bookBankCode }}
          </label>
          <div class="mt-2">
            <label
              for="bookBankCode"
              class="font-md"
              :class="{ 'p-text-error': checkError('bookBankCode') }"
            >
              {{ deposit.bookBankCode }}
            </label>
          </div>
        </div>
        
        <div class="lg:col-6 md:col-6 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('transferDate') }"
          >
            {{ lang.transferDate }}
          </label>
          <div class="mt-2">
            <label
              for="transferDate"
              class="font-md"
              :class="{ 'p-text-error': checkError('transferDate') }"
            >
              {{ deposit.transferDate&&!checkError('transferDate')? formatDate(deposit.transferDate, false):deposit.transferDate }}
            </label>
          </div>
        </div>
        
        <div class="lg:col-12 md:col-12 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('docBankBranchName') }"
          >
            {{ lang.bank }}
          </label>
          <div class="mt-2">
            <label
              for="docBankBranchName"
              class="font-md"
              :class="{ 'p-text-error': checkError('docBankBranchName') }"
            >
              {{ deposit.docBankBranchName }}
            </label>
          </div>
        </div>
        
        <div class="lg:col-12 md:col-12 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('depositor') }"
          >
            {{ lang.transferCode }}
          </label>
          <div class="mt-2">
            <label
              for="depositor"
              class="font-md"
              :class="{ 'p-text-error': checkError('depositor') }"
            >
              {{ deposit.depositor }}
            </label>
          </div>
        </div>
        
        <div class="lg:col-12 md:col-12 col-12 p-0 pt-3">
          <label
            class="p-text-secondary"
            :class="{ 'p-text-error': checkError('remark') }"
          >
            {{ lang.remark }}
          </label>
          <div class="mt-2">
            <label
              for="remark"
              class="font-md"
              :class="{ 'p-text-error': checkError('remark') }"
            >
              {{ deposit.remark }}
            </label>
          </div>
        </div>
      </div>
    </section>

    <template #footer>
      <PvButton
        :label="lang.close"
        class="p-button-outlined p-button-secondary w-3"
        @click="closeDialog"
      />
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  emits: ["close"],
  props: {
    selectedDeposit: {
      type: Object,
    },
  },
  setup(props, context) {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const { formatDate, formatCurrency } = useFormat();
    const store = useStore();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.deposit.edit,
          ...lang.th.validateImport,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.deposit.edit,
          ...lang.en.validateImport,
        };
      }
    });

    //computeds
    const deposit = computed(() => {
      return props.selectedDeposit;
    });
    const errorLength = computed(() => {
      return props.selectedDeposit.error.length;
    });

    //methods
    const closeDialog = () => {
      context.emit("close");
    };
    const checkError = (field) => {
      if (errorLength.value > 0) {
        let error = deposit.value.error.find((error) => error.field == field);
        if (error) {
          return true;
        }
      }
      return false;
    };

    return {
      lang: mergeLang,
      textFormClass,
      formatDate,
      formatCurrency,

      //computeds
      deposit,

      //methods
      closeDialog,
      checkError,
    };
  },
};
</script>
