export default {
	toolbarTitle: "รายการคำขอ",
	title: "รายการไฟล์",
	subTitle: "รายการที่ร้องขอมีอายุไม่เกิน 14 วัน นับจากวันที่ส่งคำขอ",

	columnItemNo: "ลำดับ",
	columnRequestName: "รายการ",
	columnRequestDate: "วันที่ส่งคำขอ",
	columnRequestUser: "ผู้ส่งคำขอ",
	columnRequestStatus: "สถานะคำขอ",
	columnRequestQueue: "คิวของคุณ",

	tagQueuing: "รอดำเนินการ",
	tagProcessing: "กำลังดำเนินการ",
	tagSuccess: "เสร็จสิ้น",
	tagError: "ไม่สำเร็จ",

	preview: "ดูตัวอย่าง",
	download: "ดาวน์โหลด",
	retryRequest: "ลองใหม่อีกครั้ง",
	tagRequestQueue: "คิวของคุณ : :queue",
	tagWithQueue: "รออีก :queue คิว",
	errorRowExceed: "จำนวนข้อมูลเกินกว่า :row บรรทัด",
	cancelQueue: "ยกเลิกคิว",
	tagCancelQueue: "รอยกเลิก",
	dialogContentCancelQueue: "คุณต้องการยกเลิกคิวของรายการ {0} ใช่หรือไม่?",
	dialogContentCancelSuccessful: "คุณยกเลิกคิวสำเร็จ",
};
