<template>
  <base-toolbar
    :title="this.title"
    :dataButtons="dataButtons"
    :hideBackButton="isHideBack"
    @goBack="goBack()"
    @gotoCreate="gotoCreate()"
  >
  </base-toolbar>
  <base-dialog
    v-model:visible="showConfirmDialog"
    :titleConfirm="titleConfirmDialog"
    :wordingConfirm="contentConfirmDialog"
    :classTitle="confirmClassTitle"
    :dataButtons="dataButtonDialog"
    @confirm="confirm"
    @close="close"
  ></base-dialog>
  <base-container>
    <div class="surface-card shadow-2 p-5 border-round my-5">
      <div class="flex justify-content-between align-items-center">
        <!-- <h2 :class="textHeaderClass" class="my-0" style="font-size:18px">{{ title }}</h2> -->
        <label class="font-lg font-normal">{{ title }}</label>
      </div>
      <PvDivider></PvDivider>

      <!-- <div class="flex justify-content-between align-items-center">
        <div>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <PvInputText
              v-model="searchText"
              :placeholder="this.lang.inputSearch"
              @keyup.enter="getProductList()"
            />
          </span>
          <PvButton class="ml-2" @click="getProductList()">
            <span class="font-normal">{{ lang.search }}</span>
          </PvButton>
        </div>
      </div> -->
      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >
        <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            id="filterSearch"
            class="inputfield w-full mr-2"
            :options="filterSearch"
            optionLabel="name"
            optionValue="code"
            v-model="selectedFilterSearch"
            scrollHeight="300px"
            @change="changeFilterSearch()"
          />
        </div>

        <div class="flex lg:col-6 md:col-12 col-12 p-0 mr-2" v-if="selectedFilterSearch != 'all'">
          <div class="col-12 pl-0 pr-0">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchText"
                :placeholder="lang.searchTextPlaceholder"
                @keyup.enter="getProductList()"
                autofocus
                :disabled="selectedFilterSearch === 'all'"
                :class="{ 'p-inputtext-disabled': selectedFilterSearch === 'all' }"
              />
            </span>
          </div>
        </div>

        <PvButton @click="getProductList()">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="productData"
          dataKey="id"
          selectionMode="single"
          responsiveLayout="scroll"
          :class="textTableClass"
          class="p-datatable-xs"
          :loading="isLoading"
          v-model:selection="selectedArProduct"
          @row-dblclick="gotoEdit(this.selectedArProduct.id)"
        >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
          <PvColumn
            field="code"
            :header="lang.columnCode"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal"
            style="min-width: 10rem; height: 56px"
            sortable
          />
          <PvColumn
            field="nameThai"
            :header="lang.columnName"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal p-text-secondary"
            style="min-width: 30rem"
          />
          <PvColumn
            field="paidPriority"
            :header="lang.columnPriority"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal p-text-secondary"
            sortable
            style="min-width: 5rem"
          />
          <PvColumn
            field="price"
            :header="lang.columnPrice"
            headerClass="h-right font-normal"
            bodyClass="text-right font-normal p-text-secondary"
            style="min-width: 5rem"
            ><template #body="slotProps">
              {{ formatCurrency(slotProps.data.price) }}
            </template>
          </PvColumn>
          <PvColumn
            style="max-width: 6rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
              <!-- <PvButton
                type="button"
                class="p-button-danger p-button-text py-0"
                @click="gotoDelete(slotProps.data)"
              >
                <icon-trash-delete-bin iconColor="error" />
              </PvButton> -->
            </template>
          </PvColumn>

          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              :first="showCurrentPage"
            >
              <template #start="slotProps">
                {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{
                  totalPage
                }}
              </template>
            </PvPaginator>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
// import useGetFormPrint from "../../../hooks/getFormPrint.js";
export default {
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();
    const store = useStore();
    const isHideBack = inject("isHideBack");
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.accountReceivableProduct.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.accountReceivableProduct.listing,
        };
      }
    });

    // const { getPreviewBlob, getDownloadBlob } = useGetFormPrint();

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      isHideBack,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
    };
  },

  data() {
    return {
      isLoading: false,
      selectedPage: 1,
      rowsPerPage: 10,
      searchText: null,
      selectedArProduct: null,
      dataButtons: [
        {
          id: "editCaption",
          caption: this.lang.createProduct,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
        },
      ],
      showConfirmDialog: false,
      actionConfirmDialog: "",
      idConfirmDialog: "",
      penaltyIdConfirmDialog: "",
      titleConfirmDialog: "",
      contentConfirmDialog: "",
      confirmClassTitle: "",
      //Search
      filterSearch: [
        { name: this.lang.allData, code: "all" },
        { name: this.lang.productCode, code: "code" },
        { name: this.lang.productName, code: "name" },
      ],
      selectedFilterSearch: "all",
      searchCode: null,
      searchName: null,
      productData: [],
      dataButtonDialog: [],
      storageName: "",
    };
  },
  async mounted() {
    // await this.getProductList();
    this.storageName = `erp_search_ProductArList_${this.selectedProject.code}_${this.userCode}`;
    this.checkShouldSetData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    gotoCreate() {
      if (!this.isRoleInsert) {
        this.confirmClassTitle = "";
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.contentConfirmDialog = this.lang.validateRoleInsert;
        this.showConfirmDialog = true;
        this.isLoading = false;
        return;
      }
      this.$router.push({name: "ar-product-edit", params: { id: "create" }});
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getProductList();
    },
    close() {
      this.showConfirmDialog = false;
    },
    gotoSetupPenalty(productId, penaltyId) {
      this.actionConfirmDialog = "gotoSetupPenalty";
      this.idConfirmDialog = productId;
      this.penaltyIdConfirmDialog = penaltyId;
      this.titleConfirmDialog = this.lang.titleConfirmDialogGotoSetupPenalty;
      this.contentConfirmDialog = this.lang.contentConfirmDialogGotoSetupPenalty;
      this.showConfirmDialog = true;
    },
    gotoEdit(productId) {
      if (!this.isRoleUpdate) {
        this.confirmClassTitle = "";
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.contentConfirmDialog = this.lang.validateRoleUpdate;
        this.showConfirmDialog = true;
        this.isLoading = false;
        return;
      }
      this.$router.push({name: "ar-product-edit", params: { id: productId }});
    },
    gotoDelete(data) {
      if (!this.isRoleDelete) {
        this.confirmClassTitle = "";
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.contentConfirmDialog = this.lang.validateRoleDelete;
        this.showConfirmDialog = true;
        this.isLoading = false;
        return;
      }
      let lnagDelete = this.lang.contentConfirmDialogGotoDelete;
      this.actionConfirmDialog = "gotoDelete";
      this.idConfirmDialog = data.id;
      this.penaltyIdConfirmDialog = "";
      this.titleConfirmDialog = this.lang.titleConfirmDialogGotoEDelete;
      this.contentConfirmDialog = lnagDelete
        .replaceAll("{code}", data.code)
        .replaceAll("{name}", data.nameThai);
      this.showConfirmDialog = true;
    },
    confirm() {
      this.showConfirmDialog = false;
      if (this.actionConfirmDialog === "gotoEdit") {
        this.$router.push({name: "ar-product-edit", params: { id: this.idConfirmDialog }});
      } else if (this.actionConfirmDialog === "gotoDelete") {
        this.deleteProduct();
      } else if (this.actionConfirmDialog === "gotoSetupPenalty") {
        this.$router.push({
          name: "product-penaltycalculation-edit",
          params: {
            productId: this.idConfirmDialog,
            productTypeId: this.penaltyIdConfirmDialog
          }
        });
      }
    },
    async deleteProduct() {
      // console.log('deleteProduct.productId=',this.idConfirmDialog);
      this.isLoading = true;
      try {
        const payload = {
          projectId: this.selectedProject.id,
          productId: this.idConfirmDialog,
        };

        await this.$store.dispatch("arProduct/deleteArProduct", payload);
        this.getProductList();
        this.isLoading = false;
        this.$toast.add({
          severity: "success",
          summary: "success",
          detail: this.lang.deleteProductSuccess,
          life: 5000,
        });
      } catch (error) {
        let massage = error;
        if (error.code == "126") {
          massage = this.lang.errorArProductReference;
        }
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: massage,
          life: 5000,
        });
        this.isLoading = false;
      }
    },
    setDataSearch() {
      if (this.selectedFilterSearch === "all") {
        this.searchText = "";
      } else if (this.selectedFilterSearch === "code") {
        this.searchCode = this.searchText;
      } else if (this.selectedFilterSearch === "name") {
        this.searchName = this.searchText;
      }
    },
    async getProductList() {
      this.isLoading = true;
      this.dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
      try {
        if (this.validateSearchButton) {
          this.confirmClassTitle = "";
          this.titleConfirmDialog = this.lang.notificationDialogHeader;
          this.contentConfirmDialog = this.lang.validateSearchText;
          this.showConfirmDialog = true;
          this.isLoading = false;
          return;
        }
        this.setDataSearch();
        let where = {
          projectId: this.selectedProject.id,
          codeContains: this.searchCode || null,
          nameThai: this.searchName || null,
        };

        const payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          where,
        };

        let local = {
          ...where,
          rowsPerPage: this.rowsPerPage,
          selectedPage: this.selectedPage,
        };
        localStorage.setItem(this.storageName, JSON.stringify(local));

        // console.log("getProductList.payload=", payload);
        await this.$store.dispatch("arProduct/getArProduct", payload);
        this.productData = this.arProducts;

        this.isLoading = false;
      } catch (error) {
        this.confirmClassTitle = "error";
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.contentConfirmDialog = error;
        this.showConfirmDialog = true;
        this.isLoading = false;
      }
    },
    changeFilterSearch() {
      this.searchText = "";
      this.searchCode = null;
      this.searchName = null;
    },
    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      if (localSearch) {
        let search = JSON.parse(localSearch);

        if (search.entry === "entry") {
          if (search.codeContains != null && search.codeContains != "") {
            this.selectedFilterSearch = "code";
            this.searchText = search.codeContains;
          } else if (search.name != null && search.name != "") {
            this.selectedFilterSearch = "name";
            this.searchText = search.name;
          } else {
            this.selectedFilterSearch = "all";
            this.searchText = "";
          }

          this.rowsPerPage = search.rowsPerPage;
          this.selectedPage = search.selectedPage;

          search.entry = "";

          localStorage.setItem(this.storageName, JSON.stringify(search));
        }
      }
    },
    async checkShouldSetData() {
      if (this.isSharedRoleByMenuReady) {
        if (!this.isRoleSelect) {
          this.confirmClassTitle = "";
          this.titleConfirmDialog = this.lang.notificationDialogHeader;
          this.contentConfirmDialog = this.lang.validateRoleSelect;
          this.showConfirmDialog = true;
          this.isLoading = false;
          return;
        }
        await this.setLocalSearch();
        await this.getProductList();
      }
    },
  },
  computed: {
    title() {
      return this.lang.title;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    totalPage() {
      return this.$store.getters["arProduct/totalPage"];
    },
    currentPage() {
      return this.$store.getters["arProduct/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    arProducts() {
      return this.$store.getters["arProduct/arProducts"];
    },
    validateSearchButton() {
      if (this.selectedFilterSearch !== "all" && !this.searchText) {
        return true;
      }
      return false;
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmPro");
    },
    isRoleSelect() {
      if (this.roleByMenu && this.roleByMenu.select == "Y") {
        return true;
      }
      return false;
    },
    isRoleInsert() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    isRoleUpdate() {
      if (this.roleByMenu && this.roleByMenu.update == "Y") {
        return true;
      }
      return false;
    },
    isRoleDelete() {
      if (this.roleByMenu && this.roleByMenu.delete == "Y") {
        return true;
      }
      return false;
    },
    showCurrentPage(){
      let page = 0;
      page = this.rowsPerPage * (this.selectedPage - 1)
      return page;
    }
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.checkShouldSetData();
    },
  },
};
</script>
