<template>
  <PvDialog
    v-model:visible="isShowUploading"
    :style="{ width: '30vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">{{ lang.uploadStatus }}</label>
        </div>
        <!-- <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="isShowUploading = false"
        /> -->
      </div>
    </template>
    <div class="pt-4">
      <PvProgressBar
        :value="this.percentage"
        :showValue="false"
        style="width: 300px; height: 8px; background-color: #e9f1ff"
      ></PvProgressBar>
      <div class="flex justify-content-center pt-2">
        <label class="font-xs text-color-secondary" for="uploadFileDesc">{{ this.itemCount + "/" + this.itemTotal }}</label>
      </div>
    </div>
    <template #footer></template>
  </PvDialog>

  <!-- <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '70vw' }"
    :modal="true"
    :closable="false"
  > -->
    <!-- <template >
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">{{ lang.uploadFile2 }}</label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
    </template> -->

    <div class="dropZone" v-if="isShowDragDrop">
      <input
        type="file"
        id="dropZone-file"
        class="dropZone-file"
        ref="dropZoneFile"
        @click="resetFile"
        @change="handleFiles"
        multiple
        accept=".xlsx"
      />
      <div
        class="dropZone-wrapper"
        @dragenter.prevent=""
        @dragover.prevent=""
        @drop.prevent="handleFiles"
      >
        <label for="dropZone-file" class="cursor-pointer">
          <div class="pt-6"></div>
          <PvButton class="p-button-rounded show-icon" disabled="disabled">
            <icon-cloud-storage-upload iconColor="white" />
          </PvButton>
          <div class="cursor-pointer dropZone-label-text pt-4">
            <label for="dragFile" class="font-md p-text-secondary">{{
              lang.dragFile
            }}</label>
            <label for="addFile" class="cursor-pointer font-md p-text-primary" @click="addFile">{{ 
              lang.addFile
            }}</label>
            <input id="add-file" class="cursor-pointer" type="file" style="display: none" accept=".xlsx" @change="handleFiles"/>
          </div>
          <div class="cursor-pointer font-xs p-text-secondary pt-2 pb-8">
            <label for="importFileDesc" class="cursor-pointer">{{ lang.importFileDesc }}</label>
            <label
              for="downloadfile"
              class="p-text-primary cursor-pointer"
              @click="downloadfile"
              >{{ lang.downloadfile }}</label
            >
          </div>
        </label>
      </div>
    </div>

    <div class="dropZone" v-if="isShowUploadLoading">
      <div style="width: 100%">
        <!-- <div class="pt-4"></div> -->
        <div class="pt-7">
          <label for="uploadFileDesc" class="font-md text-color-secondary">{{
            lang.uploading
          }}</label>
        </div>
        <div class="pt-4">
          <label class="font-xs text-color-secondary" for="uploadFileDesc">{{
            this.fileName
          }}</label>
        </div>
        <div class="pt-4">
          <PvProgressBar
            :value="value1"
            :showValue="false"
            style="width: 360px; height: 8px; background-color: #e9f1ff"
          ></PvProgressBar>
        </div>
      </div>
    </div>

    <div class="p-datatable-border my-5 pt-6">
      <PvDataTable
        :value="files"
        dataKey="id"
        responsiveLayout="scroll"
        class="p-datatable-radius p-datatable-xs"
        :class="textTableClass"
      >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
        <PvColumn
          style ="
            width: 87px;
            height: 56px;
            border-radius: 12px 0 0 0;
            border-bottom-left-radius: 12px;
          "
          headerClass="h-center"
        >
          <template #body>
            <PvButton
              v-if="isOpen"
              type="button"
              style="background: white; border: white; opacity: 1"
              disabled="disabled"
            >
              <icon-documents-file-checkmark iconColor="secondary" />
            </PvButton>
          </template>
        </PvColumn>

        <PvColumn
          field="detailName"
          :header="lang.fileName"
          style="min-width: 12rem"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-left font-normal p-text-primary"
        >
          <!-- <template #body="slotProps">
            <PvButton
              type="button"
              @click="openfile(slotProps.data)"
              class="p-button-link pl-0"
            >
              {{ slotProps.data.detailName }}
            </PvButton>
          </template> -->
        </PvColumn>

        <PvColumn
          style="
            width: 2rem;
            border-radius: 0 12px 0 0;
            border-bottom-right-radius: 12px;"
            headerClass="h-center"
            bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="">
            <PvButton
              v-if="isOpen"
              type="button"
              class="p-button-danger"
              style="background: white; border: white"
              @click="deleteFile"
            >
              <icon-trash-delete-bin iconColor="error" />
            </PvButton>
          </template>
        </PvColumn>
        <template #footer>
          <label class="p-pagination-radius">&nbsp;</label>
        </template>
      </PvDataTable>
    </div>

    <div class="flex justify-content-center pt-4">
      <PvButton class="w-3" @click="uploadFile">
        <span class="w-full text-center font-md font-normal p-button-label">{{
          lang.uploadFile2
        }}</span>
      </PvButton>
    </div>

    <!-- <template #footer> </template> -->
  <!-- </PvDialog> -->
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import * as XLSX from "xlsx";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
export default {
  emits: ["close", "finish"],
  props: ["parameter"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();

    const { getDownloadBlob } = useGetFormPrint();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.projectDocument,
          ...lang.th.meter.meterUsage.listing,
        };
      } else {
        return {
          ...lang.en.base,  
          ...lang.en.master.projectDocument,
          ...lang.en.meter.meterUsage.listing,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getDownloadBlob,
    };
  },
  data() {
    return {
      uploadFiles: [],
      isLoading: false,
      isShowDragDrop: false,
      isShowUploadLoading: false,
      fileName: "",

      files: [{}],
      file: null,
      isOpen: false,
      value1: 0,
      isUploaded: false,

      arrayBuffer: null,
      result: [],

      isShowUploading: false,
      uploadingData: [],
      itemTotal: 0,
      itemCount: 0,
      percentage: 0,

    };
  },
  interval: null,
  created() {
    this.isShowDragDrop = true;
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    initiateData() {
      this.files = [{}];
      this.isOpen = false;
      this.file = null;
    },
    validateFileCount() {
      let check = this.uploadFiles.length;
      let textError = "";
      this.uploadFiles.forEach((file) => {
        textError += file.name + ", ";
      });
      let name = textError.substring(0, textError.length - 2);
      if (check > 1) {
        this.$toast.add({
          severity: "error", //success, warn, error
          summary: this.lang.validateLimitFile, //TitleHeader
          detail: this.lang.validateLimitDocumentDetail.replace(":name", name),
          life: 5000, //TimeClose
        });

        return true;
      }
    },
    validateFileType() {
      let error = "";
      if (
        this.file.type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        this.$toast.add({
          severity: "error", //success, warn, error
          summary: this.lang.validateFileType.replace(":name", this.file.name), //TitleHeader
          detail: this.lang.validateImportFile,
          life: 3000, //TimeClose
        });
        error = "error";
      }

      if (error === "error") return true;
      else return false;
    },
    async handleFiles(event) {
      const inputValue = event.target.files || event.dataTransfer.files;
      this.uploadFiles = Array.from(inputValue);

      if (this.validateFileCount()) {
        return;
      } //เช็คจำนวนไฟล์

      this.file = inputValue[0];

      if (this.validateFileType()) {
        return;
      } //เช็คประเภทไฟล์

      let fileName = this.file.name;
      // this.uploadFiles.forEach((file) => {
      //   fileName = file.name;
      // });
      // this.isShowDragDrop = false;
      // this.isShowUploadLoading = true;
      // this.startProgress();

      this.isOpen = true;
      this.files = [
        {
          detailName: fileName
        }
      ];

      // this.endProgress();
    },
    resetFile(event) {
      if(event.target.files.length > 0) {
        event.target.value = null
      }
    },
    startProgress() {
      this.interval = setInterval(() => {
        let newValue = this.value1 + Math.floor(Math.random() * 50) + 1;
        if (newValue >= 100) {
          newValue = 100;
        }
        this.value1 = newValue;
      }, 200);
      // this.isUploaded = true;
    },
    endProgress() {
      clearInterval(this.interval);
      this.interval = null;
    },
    async downloadfile() {
      this.setLoading(true);
      let parameter = {
        ProjectId: this.parameter.projectId,
        ProductType: this.parameter.productType,
        ActiveStatus: this.parameter.activeStatus,
        StatusUsage : this.parameter.statusUsage,
        ActiveDate: this.parameter.activeDate,
        Period: this.parameter.period,
      };

      let payload = {
        docType: "excel",
        path: "meter/meter_usage_excel.xlsx",
        fileName: "MeterUsage",
        parameter: JSON.stringify(parameter),
      };

      try {
        await this.$store.dispatch("shared/printReport", payload);
        const d = new Date();
        let dateText = d.toISOString();
        this.getDownloadBlob(
          `${payload.fileName}_${dateText}.xlsx`,
          this.fileBlob
        );
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        this.setLoading(false);
      }
      this.setLoading(false);
    },
    async uploadFile() {
      if(this.file) {
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(this.file);
        fileReader.onload = async () => {
          this.arrayBuffer = fileReader.result;
          let data = new Uint8Array(this.arrayBuffer);
          let arr = [];
          for (let i = 0; i != data.length; ++i)
            arr[i] = String.fromCharCode(data[i]);
          let bstr = arr.join("");
          let workbook = XLSX.read(bstr, { type: "binary" });
          let first_sheet_name = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[first_sheet_name];
          this.result = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          // console.log("result", this.result);
          this.itemTotal = this.result.length;
          await this.save();
        };
      } else {
        this.$toast.add({
          severity: "warn", //success, warn, error
          summary: this.lang.validateAddfile,
          detail: "",
          life: 5000, //TimeClose
        });

        return true;
      }
    },
    deleteFile() {
      this.initiateData();
    },
    stringToInt(data) {
      try{
        let currentUsed = parseInt(data);
        let checkDecimal = data.toString().indexOf(".");
        if(isNaN(currentUsed) || checkDecimal >= 0) {
          return null;
        }
        else {
          return currentUsed;
        }
      }
      catch (error) {
        return null;
      }
    },
    async save() {
      this.isShowUploading = true;
      for (const data of this.result) {
        if(data.CurrentUsed || data.CurrentUsed === 0) {
          let checkData = this.stringToInt(data.CurrentUsed)
          if(checkData == null) {
            this.$toast.add({
              severity: "error", 
              summary: this.lang.notificationDialogHeader,
              detail: this.lang.validateMeterIncorrect.replace(":meterCode", data.MeterCode),
              life: 5000, 
            });
            this.isShowUploading = false;
            return;
          }

          if( data.CurrentUsed >= 0 ) {
            try {
              let payload = {
                projectId: this.selectedProject.id,
                meterCode: data.MeterCode,
                productType: this.parameter.productType,
                period: this.parameter.period,
                currentUsed: data.CurrentUsed, 
              };
              // console.log("payload", payload);
              await this.$store.dispatch("meterUsage/importMeterUsage", payload);

              this.validateSaveMeterUsage(data);

              this.uploadingData.push(this.selectedMeterUsage.data); //dada = dada ที่ได้จากยิง api
              // this.itemCount = this.uploadingData.length;
              // this.percentage = (this.uploadingData.length / this.itemTotal)*100;            
            } catch (error) {
              this.$toast.add({
                severity: "error",
                summary: this.lang.notificationDialogHeader,
                detail: error,
                life: 5000,
              });
              this.isShowUploading = false;
              return;
            }
          }
        } else {
          this.uploadingData.push(data);
        }

        this.itemCount = this.uploadingData.length;
        this.percentage = (this.uploadingData.length / this.itemTotal)*100;
      }

      this.isShowUploading = false;
      this.$emit("finish");

      this.$toast.add({
        severity: "success",
        summary: this.lang.saveSuccess,
        detail: "",
        life: 5000,
      });

    },
    validateSaveMeterUsage(data) {
      if(this.selectedMeterUsage.code == "128") {
        this.$toast.add({
          severity: "error", 
          summary: this.lang.notificationDialogHeader,
          detail: this.lang.validateReferVoucher.replace(":period", this.parameter.period),
          life: 5000, 
        });
        this.isShowUploading = false;
        return;
      } 

      if(this.selectedMeterUsage.code == "130") {
        this.$toast.add({
          severity: "error", 
          summary: this.lang.notificationDialogHeader,
          detail: this.lang.validateNoSetupProduct.replace(":period", data.meterCode),
          life: 5000, 
        });
        this.isShowUploading = false;
        return;
      } 

      if(this.selectedMeterUsage.code == "129") {
        this.$toast.add({
          severity: "error", 
          summary: this.lang.notificationDialogHeader,
          detail: this.lang.validateSetupCalculater,
          life: 5000, 
        });
        this.isShowUploading = false;
        return;
      } 
    },
    addFile() {
      document.getElementById('add-file').click();
    }
    // async sleep(ms) {
    //   return new Promise(
    //     resolve => setTimeout(resolve, ms)
    //   );
    // }
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    fileBlob() {
      return this.$store.getters["shared/blob"];
    },
    selectedMeterUsage() {
			return this.$store.getters["meterUsage/selectedMeterUsage"];
    },
  },
};
</script>

<style scoped>
.dropZone {
  box-sizing: border-box;
  width: 100%;
  background: #fafafa;
  border: 2px dashed #ced0d6;
  border-radius: 10px;
  text-align: center;
  height: 194px;
  margin-top: 2rem;
}

.dropZone-file {
  position: absolute;
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.dropZone-wrapper {
  display: grid;
  width: 100%;
  height: 100%;
}

.p-progressbar {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.p-datatable {
  text-align: center !important;
  overflow: visible !important;
  /* padding-top: 3% !important;
  padding-bottom: 3% !important; */
}

.base-center {
  width: 75%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.show-icon {
  opacity: 1;
  width: 48px;
  height: 48px;
  padding: 0.75rem;
}

.base-margin {
  margin-top: 37px;
  margin-bottom: 37px;
}

.p-pagination-radius {
  border-radius: 0 0 12px 12px;
}
</style>
