<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoSave="checkBeforeSave"
  ></base-toolbar>
  <base-dialog
    v-model:visible="showValidateDialog"
    :titleConfirm="titleConfirm"
    :classTitle="baseDialogClassTitle"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    :isCloseDialog="true"
    @close="showValidateDialog = false"
  ></base-dialog>
  <base-container>
    <div class="surface-card shadow-2 p-5 border-round my-5">
      <div class="flex justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ title }}</label>
      </div>
      <PvDivider></PvDivider>
      <section class="flex flex-wrap fontlabel" :class="textFormClass">
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="code">{{ lang.labelCode }}</label>
          <PvInputText
            id="code"
            type="text"
            class="inputfield w-full mt-1 input-number-disable"
            v-model="code"
            disabled
          />
        </div>

        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="dateWithFormat">{{ lang.labelDate }}</label>
          <PvInputText
            id="dateWithFormat"
            type="text"
            class="inputfield w-full mt-1 input-number-disable"
            v-model="dateWithFormat"
            disabled
          />
        </div>

        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="unitCode">{{ lang.labelUnit }}</label>
          <PvInputText
            id="unitCode"
            type="text"
            class="inputfield w-full mt-1 input-number-disable"
            v-model="unitCode"
            disabled
          />
        </div>

        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="customerName">{{ lang.labelCustomer }}</label>
          <PvInputText
            id="customerName"
            type="text"
            class="inputfield w-full mt-1 input-number-disable"
            v-model="customerName"
            disabled
          />
        </div>

        <div class="flex flex-wrap pt-4" :class="textFormClass">
          <div class="flex lg:col-6 md:col-12 col-12 pl-0 align-items-center">
            <div class="lg:col-8 md:col-7 col-7 p-0" :class="classPayType">
              <label for="payType">{{ lang.labelPayType }}</label>
              <PvDropdown
                id="payType"
                class="inputfield w-full mt-1"
                :options="payTypes"
                optionValue="code"
                optionLabel="name"
                v-model="selectedPayType"
                @change="selectPayType"
              />
            </div>
            <div class="field-checkbox mb-0 mt-4 ml-3" v-if="selectedPayType == 'Cheque'">
              <PvCheckbox id="isReceipts" v-model="isReceipts" :binary="true" />
              <label for="isReceipts">{{ lang.labelMultiReceipts }}</label>
            </div>
          </div>

          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="netTotalAmount">{{ lang.labelTotalAmount }}</label>
            <PvInputNumber
              id="netTotalAmount"
              class="inputfield w-full mt-1"
              inputClass="input-number-disable"
              v-model="netTotalAmount"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              disabled
            />
          </div>

          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label>{{ lang.labelBookBank }}</label>
            <div v-if="selectedPayType != 'Cheque'">
              <base-book-bank-auto-complete
                id="selectedBookbank"
                v-model="selectedBookbank"
                class="inputfield w-full mt-1"
                @load-finish="bookBankDataReady"
                @item-select="selectBookbank()"
                :disabled="isDisableBookbank"
                :inputClass="classDisableBookbank"
                :class="{ 'p-invalid': bookBankError }"
              />
            </div>
            <div v-else>
              <PvInputText
                id="payBank"
                type="text"
                class="inputfield w-full mt-1"
                :disabled="isDisableBookbank"
                :class="{
                  'p-invalid': bookBankError,
                  'input-number-disable': isDisableBookbank,
                }"
                v-model.trim="payBank"
              />
            </div>
            <small v-if="bookBankError" id="bookBank-help" class="p-error">
              {{ lang.validateNoBookbank }}
            </small>
          </div>

          <div
            class="lg:col-6 md:col-12 col-12 pl-0 pr-2"
            v-if="selectedPayType != 'CreditCard'"
          >
            <label for="payDate">{{ lang.labelPayDate }}</label>
            <PvCalendar
              id="payDate"
              v-model="payDate"
              class="inputfield w-full mt-1"
              dateFormat="dd/mm/yy"
              showIcon
              :disabled="isDisablePayDate"
              :inputClass="classDisablePayDate"
            />
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2" v-else></div>

          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="payBookBankBranch">{{ lang.labelBranch }}</label>
            <PvInputText
              id="payBookBankBranch"
              type="text"
              class="inputfield w-full mt-1"
              v-model="payBookBankBranch"
              :disabled="isDisablePayBookBankBranch"
              :class="{
                'p-invalid': bookBankBranchError,
                'input-number-disable': isDisablePayBookBankBranch,
              }"
              @keypress="bookBankBranchError = false"
            />
            <small v-if="bookBankBranchError" id="bookBankBranch-help" class="p-error">
              {{ lang.validateNoBookbankBranch }}
            </small>
          </div>

          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="payReference">{{ lang.labelPayReferCode }}</label>
            <PvInputText
              id="payReference"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="payReference"
              :disabled="isDisablePayReference"
              :class="{
                'p-invalid': payReferenceError,
                'input-number-disable': isDisablePayReference,
              }"
              @keypress="payReferenceError = false"
            />
            <small v-if="payReferenceError" id="payReference-help" class="p-error">
              {{ validateNoPayReference }}
            </small>
          </div>

          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="paidBy">{{ lang.labelPaidBy }}</label>
            <PvInputText
              id="paidBy"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="paidBy"
            />
          </div>

          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="paidByAddress">{{ lang.labelPaidByAddress }}</label>
            <PvInputText
              id="paidByAddress"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="paidByAddress"
            />
          </div>

          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="remark3">{{ lang.remark1 }}</label>
            <PvInputText
              id="remark3"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="remark3"
            />
          </div>

          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
            <label for="remark4">{{ lang.remark2 }}</label>
            <PvInputText
              id="remark4"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="remark4"
            />
          </div>
        </div>
      </section>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import useGetRoleByBook from "../../../hooks/getRoleByBook.js";
import usePageDirty from "../../../hooks/pageDirty";
import { useStore } from "vuex";
import { computed } from "vue";
import BaseBookBankAutoComplete from "../../../components/bank/BaseBookBankAutoComplete.vue";
export default {
  props: ["id", "projectCode"],
  components: {
    BaseBookBankAutoComplete,
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();
    const { compareObject } = usePageDirty();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.visitorControl.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.visitorControl.edit,
        };
      }
    });
    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getRoleByBookByCode,
      isSharedRoleByBookReady,
      compareObject,
    };
  },
  data() {
    return {
      isLoading: false,
      objToCompare: null,
      code: "",
      date: null,
      dateWithFormat: "",
      unitId: "",
      unitCode: "",
      customerId: "",
      customerName: "",
      payTypes: [
        { name: this.lang.cashType, code: "Cash" },
        { name: this.lang.transferType, code: "Transfer" },
        { name: this.lang.chequeType, code: "Cheque" },
        { name: this.lang.creditCardType, code: "CreditCard" },
      ],
      selectedPayType: "Cash",
      netTotalAmount: 0,
      isReceipts: true,
      selectedBookbank: null,
      payDate: null,
      payBank: "",
      payBookBankBranch: "",
      payReference: "",
      bookBankError: false,
      bookBankBranchError: false,
      payReferenceError: false,
      paidBy: "",
      paidByAddress: "",
      remark3: "",
      remark4: "",
      isDisablePayBookBankBranch: false,
      isDisablePayReference: false,
      isDisableBookbank: false,
      isDisablePayDate: false,
      showValidateDialog: false,
      validateWording: "",
      baseDialogClassTitle: "",
      titleConfirm: this.lang.notificationDialogHeader,
    };
  },
  async created() {
    await this.$store.dispatch("bank/setTypeInOut", { type: 1 }); // 1="PayIn" ,-1= "PayOut"
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    this.roleByBookDataReady();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.setLocalSearch();
      this.$router.push({name: "visitor-control"});
    },
    setLocalSearch() {
      let storageName = `erp_search_visitor_control_list_${this.selectedProject.code}_${this.userCode}`;
      const localSearch = localStorage.getItem(storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      localStorage.setItem(storageName, JSON.stringify(search));
    },
    async roleByBookDataReady() {
      if (this.isSharedRoleByBookReady) {
        await this.getReceipt();
      }
    },
    bookBankDataReady() {
      this.isSharedBookBankReady = true;
      this.checkShouldSetData();
    },
    async checkShouldSetData() {
      if (this.receipt && this.isSharedBookBankReady) {
        await this.setData();
      }
    },
    async initiateData() {
      this.code = "";
      this.date = new Date();
      this.dateWithFormat = this.formatDate(this.date);
      this.unitId = "";
      this.unitCode = "";
      this.customerId = "";
      this.customerName = "";
      this.netTotalAmount = 0;
      this.selectedPayType = "Cash";
      this.payBank = "";
      this.selectedBookbank = null;
      this.isReceipts = true;
      this.payDate = new Date();
      this.payBookBankBranch = "";
      this.payReference = "";
      this.bookBankError = false;
      this.bookBankBranchError = false;
      this.payReferenceError = false;
      this.paidBy = "";
      this.paidByAddress = "";
      this.remark3 = "";
      this.remark4 = "";
      this.checkDisablePayData();
      this.$store.dispatch("arReceipt/resetReceipt");
    },
    async getReceipt() {
      this.setLoading(true);
      try {
        let where = {
          projectId: this.selectedProject.id,
          id: this.id,
        };
        const payload = {
          where,
        };
        await this.$store.dispatch("arReceipt/getReceipt", payload);
      } catch (error) {
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
      }
      await this.setData();
      await this.setCompareData();
      this.setLoading(false);
    },
    async setData() {
      this.code = this.receipt.code;
      this.date = new Date(this.receipt.date);
      this.dateWithFormat = this.formatDate(this.date);
      this.unitId = this.receipt.unitId;
      this.unitCode = this.receipt.unitCode;
      this.customerId = this.receipt.customerId;
      this.customerName = this.receipt.customerName;
      this.selectedPayType = this.receipt.payType;
      this.checkDisablePayData();
      this.paidStatus = this.receipt.paidStatus;
      if (this.selectedPayType == "Cheque" && this.paidStatus.trim() == "") {
        this.isReceipts = true;
      } else {
        this.isReceipts = false;
      }
      this.selectedBookbank = this.sharedBookBanks.find(
        (bookbank) => bookbank.code === this.receipt.payBookBankCode
      );
      this.payBank = this.receipt.payBookBankCode;
      this.netTotalAmount = this.receipt.netAmount;
      this.payDate = new Date(this.receipt.payDate);
      this.payBookBankBranch = this.receipt.payBookBankBranch;
      this.payReference = this.receipt.payReference;
      this.paidBy = this.receipt.paidBy;
      this.paidByAddress = this.receipt.paidByAddress;
      this.remark3 = this.receipt.remark3;
      this.remark4 = this.receipt.remark4;
      this.setLoading(false);
    },
    selectPayType() {
      this.isReceipts = true;
      this.selectedBookbank = null;
      this.payBank = "";
      this.payDate = new Date();
      this.payBookBankBranch = "";
      this.payReference = "";
      this.bookBankError = false;
      this.bookBankBranchError = false;
      this.payReferenceError = false;
      this.checkDisablePayData();
    },
    selectBookbank() {
      this.payBookBankBranch =
        this.selectedBookbank.bankName + " : " + this.selectedBookbank.branchName;
      this.payBank = this.selectedBookbank.code;
      this.bookBankError = false;
      this.bookBankBranchError = false;
    },
    checkDisablePayData() {
      if (this.selectedPayType == "Cash") {
        this.isDisableBookbank = true;
        this.isDisablePayDate = true;
        this.isDisablePayBookBankBranch = true;
        this.isDisablePayReference = true;
      } else if (this.selectedPayType == "Transfer") {
        this.isDisableBookbank = false;
        this.isDisablePayDate = false;
        this.isDisablePayBookBankBranch = false;
        this.isDisablePayReference = false;
      } else if (this.selectedPayType == "Cheque") {
        this.isDisableBookbank = false;
        this.isDisablePayDate = false;
        this.isDisablePayBookBankBranch = false;
        this.isDisablePayReference = false;
      } else if (this.selectedPayType == "CreditCard") {
        this.isDisableBookbank = false;
        this.isDisablePayDate = true;
        this.isDisablePayBookBankBranch = false;
        this.isDisablePayReference = false;
      }
    },
    async setCompareData() {
      let objToCompare = {
        type: "Update",
        id: this.id,
        projectId: this.selectedProject.id,
        payType: this.selectedPayType,
        payBookBankCode: this.payBank,
        payDate: this.payDate ? this.formatDateToISOString(this.payDate) : null,
        payBookBankBranch: this.payBookBankBranch,
        payReference: this.payReference,
        paidBy: this.paidBy,
        paidByAddress: this.paidByAddress,
        remark3: this.remark3,
        remark4: this.remark4,
      };
      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
    },
    async checkBeforeSave() {
      if (this.validateData()) {
        return;
      }
      await this.save();
    },
    validateData() {
      if (this.selectedPayType == "Transfer") {
        if (!this.selectedBookbank) {
          this.validateWording = this.lang.validateNoBookbank;
          this.showValidateDialog = true;
          this.bookBankError = true;
          return true;
        } else if (!this.payBookBankBranch) {
          this.validateWording = this.lang.validateNoBookbankBranch;
          this.showValidateDialog = true;
          this.bookBankBranchError = true;
          return true;
        }
      } else if (
        this.selectedPayType == "Cheque" ||
        this.selectedPayType == "CreditCard"
      ) {
        if (!this.selectedBookbank) {
          this.validateWording = this.lang.validateNoBookbank;
          this.showValidateDialog = true;
          this.bookBankError = true;
          return true;
        } else if (!this.payBookBankBranch) {
          this.validateWording = this.lang.validateNoBookbankBranch;
          this.showValidateDialog = true;
          this.bookBankBranchError = true;
          return true;
        } else if (!this.payReference) {
          let type =
            this.selectedPayType == "Cheque"
              ? this.lang.chequeNo
              : this.lang.creditCardNo;
          this.validateNoPayReference = this.lang.validateNoPayReference.replace(
            ":no",
            type
          );
          this.validateWording = this.validateNoPayReference;
          this.showValidateDialog = true;
          this.payReferenceError = true;
          return true;
        }
      }
      return false;
    },
    async save() {
      this.setLoading(true);
      try {
        await this.$store.dispatch("arReceipt/updateReceiptVicitorControl", this.payload);
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.saveSuccess;
        this.validateWording = this.lang.saveEditSuccess;
        this.showValidateDialog = true;
      } catch (error) {
        if (error == "110") {
          this.validateWording = this.lang.validateRoleUpdate;
        } else if (error == "120") {
          this.validateWording = this.lang.validateReferenceBank;
        } else if (error == "121") {
          this.validateWording = this.lang.validateReferenceVoucher;
        }
        this.titleConfirm = this.lang.notificationDialogHeader;
        // this.validateWording = error;
        this.showValidateDialog = true;
      }
      this.setLoading(false);
      this.$router.replace({name: "visitor-control-edit", params:{id: this.receipt.id}});
      await this.setCompareData();
    },
  },
  computed: {
    payload() {
      let payload = {
        type: "Update",
        id: this.id,
        projectId: this.selectedProject.id,
        payType: this.selectedPayType,
        payBookBankCode: this.payBank,
        payDate: this.payDate ? this.formatDateToISOString(this.payDate) : null,
        payBookBankBranch: this.payBookBankBranch,
        payReference: this.payReference,
        paidBy: this.paidBy,
        paidByAddress: this.paidByAddress,
        remark3: this.remark3,
        remark4: this.remark4,
      };
      return payload;
    },
    title() {
      return this.lang.title;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    roleByBook() {
      return this.getRoleByBookByCode("INV");
    },
    isRoleSelect() {
      if (this.roleByBook && this.roleByBook.select == "Y") {
        return true;
      }
      return false;
    },
    isRoleUpdate() {
      if (this.roleByBook && this.roleByBook.update == "Y") {
        return true;
      }
      return false;
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "saveCaption",
          caption: this.lang.save,
          class: "mr-3 p-button-toolbar",
          icon: "",
          iconColor: "",
          action: "gotoSave",
          isShow: true,
          isDisable: !this.isRoleUpdate,
        },
      ];
      return dataButtons;
    },
    dataButtonDialog() {
      let result = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
      return result;
    },
    sharedBookBanks() {
      return this.$store.getters["bookBank/bookBanks"];
    },
    receipt() {
      return this.$store.getters["arReceipt/selectedReceipt"];
    },
    classPayType() {
      let setClass = "";
      if (this.selectedPayType != "Cheque") {
        setClass = "w-full";
      }
      return setClass;
    },
    classDisableBookbank() {
      let classDisable = "";
      if (this.isDisableBookbank) {
        classDisable = "input-number-disable";
      }
      return classDisable;
    },
    classDisablePayDate() {
      let classDisable = "";
      if (this.isDisablePayDate) {
        classDisable = "input-number-disable";
      }
      return classDisable;
    },
  },
  watch: {
    receipt() {
      this.checkShouldSetData();
    },
    payload(curValue) {
      if (this.objToCompare) {
        this.compareObject(this.objToCompare, curValue);
      }
    },
    isSharedRoleByBookReady() {
      this.roleByBookDataReady();
    },
  },
};
</script>
