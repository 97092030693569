<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>
    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="getGlBook">{{ lang.glBookCode }}</label>
          <!-- <PvSkeleton
            v-if="!isSharedAccountBookReady"
            class="inputfield w-full mt-1"
            borderRadius="5px"
            height="38px"
          /> -->
          <base-account-book-auto-complete
            id="accountBook"
            v-model="selectedAccountBook"
            @load-finish="accountBookDataReady"
            class="inputfield w-full mt-1"
            :isLoadingData="true"
            :placeholder="lang.validateAccountBookIsNull"
            :class="{ 'p-invalid': selectedAccountBookError }"
            @item-select="selectedAccountBookError = false"
            :key="'BaseReportAccountFrequently'"
          />
          <small v-if="selectedAccountBookError" id="unit-help" class="p-error">{{
            lang.validateGlBookSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText
            id="fileNameText"
            type="text"
            class="inputfield w-full mt-1"
            v-model="fileNameText"
          />
        </div>
      </section>
    </transition>
    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
        </div>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetFormPrint from "../../hooks/getFormPrint.js";
import useTracking from "../../hooks/tracking";
import useReportRequestValidate from "../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "toast-request"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { getPreviewBlob } = useGetFormPrint();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getPreviewBlob,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      isSharedAccountBookReady: false,
      selectedAccountBookError: false,
      selectedAccountBook: null,
      docType: "PDF",
      fileNameText: "account_frequently",
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
    },
    async initiateData() {
      this.selectedAccountBook = null;
      this.selectedAccountBookError = false;
      this.docType = "PDF";
      this.fileNameText = "account_frequently";
    },
    async download(typeReport) {
      this.docType = typeReport;
      if (this.validateData()) {
        return;
      }

      this.setLoading(true);
      try {
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
    validateData() {
      let returnStatus = false;
      if ( !this.selectedAccountBook ) {
        this.selectedAccountBookError = true;
        returnStatus = true;
      }
      return returnStatus;
    },
    accountBookDataReady() {
      this.isSharedAccountBookReady = true;
      this.setLoading(false);
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    title() {
      if (this.$store.getters.selectedLanguage === "TH")
        return this.reportObj.nameThai;
      else return this.reportObj.nameEnglish;
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
    payload() {
      let payload = {
        RequestId: 0,
        ReportName: "AccountFrequently",
        FileName: this.fileNameText + this.fileType,
        JasperPath: this.path + this.fileType,
        DocType: this.docType,
        UserId: this.userId,
        PrintBy: this.userName,
        ProjectId: this.selectedProject.id,
        GlbId: this.selectedAccountBook ? this.selectedAccountBook.generalLedgerBookId : null,
      };
      return payload;
    },
    path() {
      let path = this.docType == "EXCEL" ? this.reportObj.path + "_excel" : this.reportObj.path;

      return path;
    },
    fileType() {
      let fileType = this.docType == "PDF" ? ".pdf" : ".xlsx";
      return fileType;
    },
  },
  watch: {
    reportObj() {
      this.initiateData();
    },
  },
};
</script>
