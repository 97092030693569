<template>
  <base-dialog
    v-model:visible="showValidateDialog"
    :titleConfirm="titleConfirm"
    :classTitle="baseDialogClassTitle"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    :isVisibleCloseButton="isVisibleCloseButton"
    @confirm="confirmDialog"
  />

  <change-password-dialog
    v-if="isChangePasswordDialogVisible"
    v-model:visible="isShowChangePasswordDialog"
    :errorMessage="errorMessage"
    @close="closeChangePasswordDialog"
    @confirm-change-password="confirmChangePassword"
  >
  </change-password-dialog>

  <language-setting-dialog
    v-model:visible="isShowLanguageSettingDialog"
    @close="isShowLanguageSettingDialog = false"
  ></language-setting-dialog>

  <div class="sidebar" :class="isOpened ? 'open' : ''" :style="cssVars">
    <!-- ****************Header************ -->
    <div class="sidebar-header">
      <div class="logo-header pt-2">
        <img
          :src="menuLogo"
          alt="menu-logo"
          class="menu-logo icon mt-1"
          style="width: 48px; height: 48px"
          @click="gotoHome()"
        />
        <div>
          <div v-if="isOpened" class="header_name mt-2 ml-2">
            {{ lang.menuTitle }}
          </div>
          <div v-if="isOpened" class="header_submenu_name ml-2 p-text-secondary font-xs">
            {{ lang.subMenuTitle }}
          </div>
        </div>
      </div>
      <div class="flex">
        <div style="width: 10%" v-if="!isOpened">
          <PvButton
            class="my-3 mr-3 p-button-rounded p-button-text"
            :class="{ 'item-opened': !isOpened }"
            @click="openMenu()"
          >
            <icon-arrow-right class="secondary" />
          </PvButton>
        </div>
        <div style="width: 87%; padding-top: 2px">
          <span class="p-input-icon-left w-full" v-if="isOpened">
            <i class="pi pi-search ml-1 p-icon-secondary" style="padding-top: 4px" />
            <PvInputText
              class="inputfield mx-2 mt-2 p-2 w-full"
              v-model="searchText"
              :placeholder="lang.searchMenu"
              style="width: 93% !important"
              @keyup="searchMenu()"
              autofocus
            />
          </span>
        </div>
        <div style="width: 13%" v-if="isOpened">
          <PvButton
            class="my-3 mr-3 p-button-rounded p-button-text"
            :class="{ 'item-opened': !isOpened }"
            @click="openMenu()"
          >
            <icon-arrow-left class="secondary" />
          </PvButton>
        </div>
      </div>
      <PvDivider class="my-1"></PvDivider>
    </div>
    <!-- *************************Header************************** -->

    <!-- ****************Body************ -->

    <div
      id="my-scroll"
      class="sidebar-body"
      :class="[
        { 'sidebar-body-open': isOpened },
        { 'sidebar-body-search': this.isSearchTextInput },
      ]"
      ref="sidebar_body"
    >
      <ul class="menu_items">
        <!-- ************** Level1 title*************** -->

        <div class="menu_empty pt-5" v-if="filteredMenu.length <= 0">
          <span>{{ this.lang.menuEmpty }} </span>
        </div>
        <span v-for="(menuItem, index) in filteredMenu" :key="index">
          <div class="menu_title" v-if="isOpened" v-show="menuItem.id !== '1'">
            {{ menuItem.label }}
          </div>
          <div class="menu_title" v-else v-show="menuItem.id !== '1'">
            <div
              class="sidebar-body-title-close p-progressbar"
              v-show="menuItem.id !== '1'"
            ></div>
          </div>
          <li class="item" :class="{ menu_item_close: !isOpened }">
            <!-- ************** Level2 menu*************** -->
            <span v-for="(menuItemlLevel1, index2) in menuItem.items" :key="index2">
              <router-link
                :to="menuItemlLevel1.type === '' ? `/${selectedProject.code}${menuItemlLevel1.to}` : ''"
                @click="gotoLevel1(menuItemlLevel1)"
                @contextmenu="disableRightClick($event,menuItemlLevel1)"
              >
                <div
                  class="menu_item_l1 submenu_item"
                  :class="[
                    'menu_item_l1_' + menuItemlLevel1.id,
                    {
                      menu_item_l1_active:
                        this.isSearchTextInput &&
                        menuItemlLevel1.items &&
                        menuItemlLevel1.items.length > 0,
                    },
                    {
                      menu_item_l1_active_close:
                        menuItemlLevel1.id == this.menuActive && !isOpened,
                    },
                    {
                      menu_item_active: menuItemlLevel1.id == this.menuActive && isOpened,
                    },
                  ]"
                  :ref="'menu_item_l1_' + menuItemlLevel1.id"
                >
                  <!-- :class="'menu_item_l1_' + menuItemlLevel1.id"  || menuItemlLevel1.id == this.menuActiveLevel1,:ref="'menu_item_l1_' + menuItemlLevel1.id"-->
                  <div
                    class="sidebar_menu_item_close"
                    :class="[
                      { menu_item_l1_open: isOpened },
                      {
                        menu_item_active:
                          menuItemlLevel1.id == this.menuActiveLevel1 && !isOpened,
                      },
                    ]"
                  >
                    <!-- :class="{ menu_item_l1_open: isOpened }" -->
                    <div
                      style="width: 24px"
                      v-show="
                        (isOpened && !menuItemlLevel1.items) ||
                        (menuItemlLevel1.items && menuItemlLevel1.items.length <= 0)
                      "
                    ></div>
                    <component
                      :is="this.menuIconRight"
                      :key="this.menuIconRight"
                      iconColor="secondary"
                      height="24"
                      width="24"
                      class="icon_active arrow_down"
                      :class="'icon_active'"
                      v-show="
                        isOpened &&
                        menuItemlLevel1.items &&
                        menuItemlLevel1.items.length > 0
                      "
                    />
                    <span class="navlink_icon">
                      <component
                        :is="menuItemlLevel1.icon"
                        :key="menuItemlLevel1.icon"
                        iconColor="secondary"
                        height="24"
                        width="24"
                        class="mx-auto"
                        :class="'icon_active'"
                      />
                    </span>
                    <span
                      class="navlink font-sm text_active"
                      :class="{ 'sidebar-close': !isOpened }"
                      >{{ menuItemlLevel1.label }}</span
                    >
                  </div>
                </div>
              </router-link>
              <ul
                class="menu_items submenu"
                :class="[
                  'submenu_l1_' + menuItemlLevel1.id,
                  {
                    show_submenu: this.isSearchTextInput,
                  },
                ]"
              >
                <!-- :class="'submenu_l1_' + menuItemlLevel1.id"  || menuItemlLevel1.id == this.menuActiveLevel1,-->
                <span
                  v-for="(menuItemlLevel2, index3) in menuItemlLevel1.items"
                  :key="index3"
                >
                  <div
                    class="menu_item_l2 submenu_item"
                    :class="{ 'sidebar-close': !isOpened }"
                  >
                    <router-link
                      :to="menuItemlLevel2.type === '' ? `/${selectedProject.code}${menuItemlLevel2.to}` : ''"
                      @click="gotoLevel2(menuItemlLevel2, menuItemlLevel1.id)"
                      @contextmenu="disableRightClick($event,menuItemlLevel2)"
                    >
                      <li
                        class="item w-full"
                        :class="[
                          'menu_item_l2_' + menuItemlLevel2.id,
                          'ref_menu_l1_' + menuItemlLevel1.id,
                          {
                            menu_item_l2_active:
                              this.isSearchTextInput &&
                              menuItemlLevel2.items &&
                              menuItemlLevel2.items.length > 0,
                          },
                          {
                            menu_item_active: menuItemlLevel2.id == this.menuActive,
                          },
                        ]"
                      >
                        <!--  || menuItemlLevel2.id == this.menuActiveLevel2,-->
                        <span class="navlink_icon">
                          <component
                            :is="this.menuIconRight"
                            :key="this.menuIconRight"
                            iconColor="secondary"
                            height="24"
                            width="24"
                            class="mx-auto icon_active arrow_down"
                            :class="'icon_active'"
                            v-show="
                              isOpened &&
                              menuItemlLevel2.items &&
                              menuItemlLevel2.items.length > 0
                            "
                          />
                        </span>
                        <span class="navlink font-sm text_active">
                          {{ menuItemlLevel2.label }}
                        </span>
                      </li>
                    </router-link>
                    <!-- ************** Level4 menu*************** -->
                    <ul
                      class="menu_items submenu"
                      :class="[
                        'submenu_l2_' + menuItemlLevel2.id,
                        ' ref_menu_l1_' + menuItemlLevel1.id,
                        {
                          show_submenu: this.isSearchTextInput,
                        },
                      ]"
                    >
                      <!-- || menuItemlLevel2.id == this.menuActiveLevel2 -->
                      <div class="menu_item_l3 submenu_item">
                        <span
                          v-for="(menuItemlLevel3, index4) in menuItemlLevel2.items"
                          :key="index4"
                        >
                          <router-link
                            :to="`/${selectedProject.code}${menuItemlLevel3.to}`"
                            @click="
                              gotoLevel3(
                                menuItemlLevel3,
                                menuItemlLevel2.id,
                                menuItemlLevel1.id
                              )
                            "
                            @contextmenu="disableRightClick($event,menuItemlLevel3)"
                          >
                            <li
                              class="item w-full"
                              :class="[
                                'submenu_l3_' + menuItemlLevel3.id,
                                {
                                  menu_item_active: menuItemlLevel3.id == this.menuActive,
                                },
                              ]"
                            >
                              <!-- :class="'submenu_l3_' + menuItemlLevel3.id" -->
                              <span class="text_active">
                                {{ menuItemlLevel3.label }}
                              </span>
                            </li>
                          </router-link>
                        </span>
                      </div>
                    </ul>
                    <!-- ************** Level4 menu*************** -->
                  </div>
                </span>
              </ul>
            </span>
          </li>
        </span>
      </ul>
    </div>

    <!-- ****************Body************ -->

    <!-- **************************Footer************************** -->
    <div class="sidebar-footer">
      <PvDivider class="my-1"></PvDivider>
      <div
        :class="[
          { 'sidebar-footer-open': isOpened },
          { 'sidebar-footer-close': !isOpened },
        ]"
      >
        <ul class="menu_items">
          <span v-for="(menuFooterItem, index) in menuFooterItems" :key="index">
            <router-link
              :to="menuFooterItem.type === '' ? `/${selectedProject.code}${menuFooterItem.to}` : ''"
              @click="gotoFooter(menuFooterItem)"
            >
              <li class="item" :class="{ menu_item_close: !isOpened }">
                <div
                  class="footer_item submenu_pointer"
                  :class="['footer_item_' + menuFooterItem.id]"
                >
                  <div
                    class="sidebar_menu_item_close"
                    :class="[
                      { footer_item_open: isOpened },
                      {
                        menu_item_active:
                          menuFooterItem.id == this.menuFooterActive ,
                      },
                    ]"
                  >
                    <span class="navlink_icon">
                      <component
                        :is="menuFooterItem.icon"
                        :key="menuFooterItem.icon"
                        iconColor="secondary"
                        height="24"
                        width="24"
                        class="mx-auto icon_active"
                      />
                    </span>
                    <span
                      class="navlink font-sm text_active"
                      :class="{ 'sidebar-close': !isOpened }"
                    >
                      {{ menuFooterItem.label }}
                    </span>
                  </div>
                </div>
              </li>
            </router-link>
          </span>
        </ul>
      </div>
      <div class="logo-footer">
        <div class="profile p-2 flex flex-wrap">
          <PvButton
            class="p-button-outlined p-button-profile"
            style="width: 48px; height: 48px; opacity: 1"
            @click="footerOpenMenu()"
          >
            <div class="mx-auto profile-img" style="width: 32px; height: 32px"
            v-badge.success="''">
              <component
                :is="this.profileImg"
                :key="this.profileImg"
                iconColor="primary"
                height="25"
                width="25"
              />
            </div>
          </PvButton>
        </div>
        <div class="surface-card w-full" v-if="isOpened">
          <div>
            <div class="mt-1 ml-2 flex justify-content-between">
              {{ this.employeeName }}
            </div>
            <div class="ml-2 font-xs p-text-success">
              {{ this.lang.online }}
            </div>
          </div>
        </div>
        <div class="mr-2" v-if="isOpened">
          <PvButton
            class="p-button-text px-3"
            aria-haspopup="true"
            aria-controls="footer_menu"
            @click="footerSelectMenu"
          >
            <component
              :is="this.dotButton"
              :key="this.dotButton"
              iconColor="secondary"
              height="24"
              width="24"
              class="mx-auto"
            />
          </PvButton>
          <PvMenu
            class="font-sm"
            :model="footerMenuItems"
            id="footer_menu"
            ref="footer_menu_items"
            :popup="true"
            style="width: 180px"
          />
        </div>
      </div>
    </div>
    <!-- ****************Footer************ -->
  </div>
</template>

<script>
import useLang from "../../../hooks/lang";
import useRouteMenu from "../../../hooks/getRouteMenu";
import { useStore } from "vuex";
import { ref, computed, watch } from "vue";
import ChangePasswordDialog from "../../auth/ChangePasswordDialog.vue";
export default {
  components: {
    ChangePasswordDialog,
  },
  name: "TheSidebarV2",
  emits: ["changeLanguage", "changePassword"],
  setup() {
    const { lang } = useLang();
    const { changeMenuHeaderLanguage, changeFooterMenuLanguage } = useRouteMenu();
    const store = useStore();

    let menuItems = ref(null);
    let menuFooterItems = ref(null);

    const mergeLang = computed(() => {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.menu };
      } else {
        return { ...lang.en.base, ...lang.en.menu };
      }
    });

    const selectedLanguage = computed(() => store.getters.selectedLanguage);
    menuItems.value = changeMenuHeaderLanguage(selectedLanguage.value, store).value;
    menuFooterItems.value = changeFooterMenuLanguage(selectedLanguage.value, store).value;

    watch(selectedLanguage, function (curValue, oldValue) {
      if (curValue !== oldValue) {
        // sidebarchangeMenuHeaderLanguage(curValue);
        menuItems.value = changeMenuHeaderLanguage(curValue, store).value;
        menuFooterItems.value = changeFooterMenuLanguage(curValue, store).value;
      }
    });

    const selectedProject = computed(() => store.getters.selectedProject);

    watch(selectedProject, function (curValue, oldValue) {
      if (curValue !== oldValue) {
        menuItems.value = changeMenuHeaderLanguage(selectedLanguage.value, store).value;
        menuFooterItems.value = changeFooterMenuLanguage(selectedLanguage.value, store).value;
      }
    });

    return {
      lang: mergeLang,
      menuItems,
      menuFooterItems,
    };
  },
  data() {
    return {
      isOpened: true,
      isMenuOpen: false,
      menuTitle: "PMS LivingOS",
      subMenuTitle: "ชีวิตที่ดีขึ้นในทุกๆวัน ",
      menuLogo: require("../../../assets/images/logo-new.svg"),
      menuIconLeft: "icon-arrow-left",
      menuIconRight: "icon-arrow-right",
      menuIconDown: "icon-arrow-down",
      profileImg: "icon-home-smileys",
      dotButton: "icon-dots-menu",
      isPaddingLeft: true,
      menuOpenedPaddingLeftBody: "260px",
      menuClosedPaddingLeftBody: "65px",
      menuLinkPaddingLeftBody: "216px",
      //! Profile detailes
      projectPaddingLeftBody: "200px",
      isExitButton: true,
      //! Styles
      menuItemsHoverColor: "#E9F1FF",
      menuItemsTextColor: "#585A5C",
      borderProfile: "#ced0d6",
      menuItemsActiveColor: "#1c70f7",

      searchText: "",
      isActive: false,
      menuActiveLevel1: "",
      menuActiveLevel2: "",
      menuActive: "",
      menuFooterActive: "",
      isShowChangePasswordDialog: true,
      isShowLanguageSettingDialog: false,
      isChangePasswordDialogVisible: false,

      dataButtonDialog: [],
      isVisibleCloseButton: true,
      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: this.lang.notificationDialogHeader,
      validateWording: "",
      errorMessage: "",
    };
  },
  // async created() {
  //   this.isOpened = this.isMenuOpen;
  //   // console.log("menuLevel1=", this.menuLevel1);
  //   // console.log("menuLevel2=", this.menuLevel2);
  //   // console.log("menuLevel3=", this.menuLevel3);
  //   // console.log("menuLevel4=", this.menuLevel4);
  //   // console.log("menuItems=", this.menuItems);
  //   // console.log("menuFooterItems=", this.menuFooterItems);
  // },
  mounted() {
    window.document.body.style.paddingLeft = this.menuOpenedPaddingLeftBody;
    this.isOpened = true;
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    initiateData() {
      this.menuActive = "";
      this.menuActiveLevel1 = "";
      this.menuActiveLevel2 = "";
      this.menuFooterActive = "";
    },
    openMenu() {
      this.isOpened = !this.isOpened;

      if (!this.isOpened) {
        this.searchText = "";
        // this.removeActiveMenuItemLevel2();
        // this.removeActiveMenuItemLevel1();
        // this.removeActiveShowSubmenu();
        this.removeActiveMenuFooterItem();
        this.removeClassMenuItemLevel1Active();
        this.$nextTick(function () {
          var container = this.$refs.sidebar_body;
          container.scrollTop = 0;
        });
      } else {
        this.removeClassMenuItemLevel1ActiveClose();
        if (this.menuActive) {
          this.searchMenu();
          this.scrollMenuTop(this.menuActiveLevel1);
        }
      }
    },
    footerOpenMenu() {
      if (!this.isOpened) {
        this.isOpened = !this.isOpened;
      }
    },
    scrollMenuTop(id) {
      this.$nextTick(function () {
        const name_menu_item_l1_scroll = "menu_item_l1_" + id;
        const scrollTopMenu = this.$refs[name_menu_item_l1_scroll][0];
        scrollTopMenu.scrollIntoView(1, { behavior: "smooth" });
      });
    },
    gotoLevel1(data) {
      //กดเมนูหลัก Level1
      if (!this.isOpened) {
        this.isOpened = true;
        this.scrollMenuTop(data.id);
      }

      /* เช็คว่าเคยเปิด เมนูย่อยแล้วปิด Sidebar ไปไหม */
      const menuItemLevel1Close = document.querySelectorAll(".menu_item_l1_active_close");
      let isSubMemuOpen = false;
      const name_menu_item_l1 = "menu_item_l1_" + data.id;
      for (var x = 0; x < menuItemLevel1Close.length; x++) {
        if (menuItemLevel1Close[x].classList.contains(name_menu_item_l1)) {
          isSubMemuOpen = true;
          break;
        }
      }
      const class_menu_item_l1 = document.getElementsByClassName(name_menu_item_l1)[0];
      if ((data.to || !data.items) && data.type === "") {
        this.removeClassMenuItemActive();
        // class_menu_item_l1.classList.add("menu_item_l1_active");
        class_menu_item_l1.classList.add("menu_item_active");
        this.removeClassMenuItemLevel1ActiveClose();
        this.initiateData();
        this.menuActive = data.id;
        this.menuActiveLevel1 = data.id;
      } else if (data.type && data.type !== "") {
        this.removeClassMenuItemActive();
        class_menu_item_l1.classList.add("menu_item_active");
        this.removeClassMenuItemLevel1ActiveClose();
        this.initiateData();
        this.menuActive = data.id;
        this.menuActiveLevel1 = data.id;

        if (data.type == "newTab") {
          this.$router.push(this.$route.fullPath)
          window.open(data.to);
        } else if (data.type == "ifarame") {
          this.$router.push({
            name: "iframe",
            query: { url: data.to, title: data.label, activeMenu: data.name},
          });
        }
      } else {
        if (menuItemLevel1Close.length > 0) {
          //เคยเปิดเมนูย่อยไว้แล้วปิด Sidebar
          //เปิดเมนูย่อยที่เคยเปิดออกมา เมื่อกดicon ตอนปิด Sidebar
          this.removeClassMenuItemLevel1ActiveClose();

          if (isSubMemuOpen == false) {
            //เปิดเมนูย่อย
            this.openCloseSubmenuLevel1(data.id);
          }
        } else {
          //ไม่เคยเปิดเมนูย่อย จะเปิดเมนูย่อยออก
          const name_ref_menu_l1 = "ref_menu_l1_" + data.id; //ปิดเมนูย่อยทั้งหมด
          const class_ref_menu_l1 = document.getElementsByClassName(name_ref_menu_l1);
          for (var i = 0; i < class_ref_menu_l1.length; i++) {
            class_ref_menu_l1[i].classList.remove("show_submenu", "menu_item_l2_active");
          }
          this.openCloseSubmenuLevel1(data.id); //เปิดเมนูย่อยของเมนูหลักที่กดมา
        }       
        if(this.$route.name == "iframe"){
          this.$router.push(this.$route.fullPath);
        }          
      }
    },
    gotoLevel2(data, munuId_l1) {
      // this.removeActiveMenuItemLevel3();
      const name_menu_item_l2 = "menu_item_l2_" + data.id;
      const class_menu_item_l2 = document.getElementsByClassName(name_menu_item_l2)[0];

      if ((data.to || !data.items) && data.type === "") {
        this.removeClassMenuItemActive();
        class_menu_item_l2.classList.add("menu_item_active");
        // this.$router.push(data.to);
        this.initiateData();
        this.menuActive = data.id;
        this.menuActiveLevel1 = munuId_l1;
      } else if (data.type && data.type !== "") {
        this.removeClassMenuItemActive();
        class_menu_item_l2.classList.add("menu_item_active");
        // this.$router.push(data.to);
        this.initiateData();
        this.menuActive = data.id;
        this.menuActiveLevel1 = munuId_l1;

        if (data.type == "newTab") {
          this.$router.push(this.$route.fullPath)
          window.open(data.to);
        } else if (data.type == "ifarame") {
          this.$router.push({
            name: "iframe",
            query: { url: data.to, title: data.label, activeMenu: data.name },
          });
        }
      } else {
        this.openCloseSubmenuLevel2(data.id);
        if(this.$route.name == "iframe"){
          this.$router.push(this.$route.fullPath);
        }
        // class_menu_item_l2.classList.toggle("menu_item_l2_active");
        // const name_submenu_l2 = "submenu_l2_" + data.id;
        // const class_submenu_l2 =
        //   document.getElementsByClassName(name_submenu_l2)[0];
        // class_submenu_l2.classList.toggle("show_submenu");
      }
    },
    openCloseSubmenuLevel2(id) {
      const name_menu_item_l2 = "menu_item_l2_" + id;
      const class_menu_item_l2 = document.getElementsByClassName(name_menu_item_l2)[0];
      class_menu_item_l2.classList.toggle("menu_item_l2_active");

      const name_submenu_l2 = "submenu_l2_" + id;
      const class_submenu_l2 = document.getElementsByClassName(name_submenu_l2)[0];
      class_submenu_l2.classList.toggle("show_submenu");
    },
    openSubmenuLevel2(id) {
      const name_menu_item_l2 = "menu_item_l2_" + id;
      const class_menu_item_l2 = document.getElementsByClassName(name_menu_item_l2)[0];
      class_menu_item_l2.classList.add("menu_item_l2_active");

      const name_submenu_l2 = "submenu_l2_" + id;
      const class_submenu_l2 = document.getElementsByClassName(name_submenu_l2)[0];
      class_submenu_l2.classList.add("show_submenu");
    },
    gotoLevel3(data, munuId_l2, munuId_l1) {
      this.removeClassMenuItemActive();
      const name_submenu_l3 = "submenu_l3_" + data.id;
      const class_submenu_l3 = document.getElementsByClassName(name_submenu_l3)[0];
      class_submenu_l3.classList.add("menu_item_active");

      if (data.to) {
        // this.$router.push(data.to);
        this.initiateData();
        this.menuActive = data.id;
        this.menuActiveLevel2 = munuId_l2;
        this.menuActiveLevel1 = munuId_l1;
      }
    },
    gotoFooter(data) {
      if (!this.isOpened) {
        this.isOpened = true;
      }

      this.removeClassMenuItemActive();
      const name_footer = "footer_item_" + data.id;
      const class_footer = document.getElementsByClassName(name_footer)[0];
      class_footer.classList.add("menu_item_active");

      if (data.to && data.type === "") {
        this.initiateData();
        this.menuFooterActive = data.id;
        // this.$router.push(data.to);
      } else if (data.type && data.type !== "") {
        if (data.type == "newTab") {
          this.$router.push(this.$route.fullPath)
          window.open(data.to);
        } else if (data.type == "ifarame") {
          this.$router.push({
            name: "iframe",
            query: { url: data.to, title: data.label, activeMenu: data.name },
          });
        }
      }
    },
    openCloseSubmenuLevel1(menuReference) {
      const name_menu_item_l1 = "menu_item_l1_" + menuReference;
      const class_menu_item_l1 = document.getElementsByClassName(name_menu_item_l1)[0];
      class_menu_item_l1.classList.toggle("menu_item_l1_active");

      const name_submenu_l1 = "submenu_l1_" + menuReference;
      const class_submenu_l1 = document.getElementsByClassName(name_submenu_l1)[0];
      class_submenu_l1.classList.toggle("show_submenu");
    },
    openSubmenuLevel1(menuReference) {
      const name_menu_item_l1 = "menu_item_l1_" + menuReference;
      const class_menu_item_l1 = document.getElementsByClassName(name_menu_item_l1)[0];
      class_menu_item_l1.classList.add("menu_item_l1_active");

      const name_submenu_l1 = "submenu_l1_" + menuReference;
      const class_submenu_l1 = document.getElementsByClassName(name_submenu_l1)[0];
      class_submenu_l1.classList.add("show_submenu");
    },
    removeClassMenuItemLevel1Active() {
      const menuItemLevel1Active = document.querySelectorAll(".menu_item_l1_active");
      menuItemLevel1Active.forEach((item) => {
        if (item.classList.contains("menu_item_l1_active")) {
          item.classList.add("menu_item_l1_active_close");
          item.classList.remove("menu_item_l1_active");
        }
      });
    },
    removeClassMenuItemLevel1ActiveClose() {
      const menuItemLevel1Close = document.querySelectorAll(".menu_item_l1_active_close");
      menuItemLevel1Close.forEach((item) => {
        if (item.classList.contains("menu_item_l1_active_close")) {
          item.classList.add("menu_item_l1_active");
          item.classList.remove("menu_item_l1_active_close");
        }
      });
    },
    removeActiveMenuFooterItem() {
      const menuItemLevel1 = document.querySelectorAll(".footer_item");
      menuItemLevel1.forEach((item) => {
        if (item.classList.contains("menu_item_active")) {
          item.classList.remove("menu_item_active");
        }
      });
    },
    removeClassMenuItemActive() {
      const menuItemActive = document.querySelectorAll(".menu_item_active");
      menuItemActive.forEach((item) => {
        if (item.classList.contains("menu_item_active")) {
          item.classList.remove("menu_item_active");
        }
      });
    },
    // removeActiveShowSubmenu() {
    //   const menuItems = document.querySelectorAll(".menu_items");
    //   menuItems.forEach((item) => {
    //     //  console.log("item.classList=",item.classList);
    //     if (item.classList.contains("show_submenu")) {
    //       item.classList.remove("show_submenu");
    //     }
    //   });
    // },
    // removeActiveMenuItemLevel1() {
    //   const menuItemLevel1 = document.querySelectorAll(".menu_item_l1");
    //   menuItemLevel1.forEach((item) => {
    //     //  console.log("item.classList=",item.classList);
    //     if (item.classList.contains("menu_item_l1_active")) {
    //       item.classList.remove("menu_item_l1_active");
    //     }
    //   });
    // },
    // removeActiveMenuItemLevel2() {
    //   const menuItemLevel2_li = document.querySelectorAll(".menu_item_l2 li");
    //   menuItemLevel2_li.forEach((item) => {
    //     // console.log("menuItemLevel2_li.item.classList=", item.classList);
    //     if (item.classList.contains("menu_item_l2_active")) {
    //       item.classList.remove("menu_item_l2_active");
    //     }
    //   });

    //   const menuItemLevel2_ul = document.querySelectorAll(".menu_item_l2 ul");
    //   menuItemLevel2_ul.forEach((item) => {
    //     // console.log("menuItemLevel2_ul.item.classList=", item.classList);
    //     if (item.classList.contains("show_submenu")) {
    //       item.classList.remove("show_submenu");
    //     }
    //   });
    // },
    // removeActiveMenuItemLevel3() {
    //   const menuItemLevel3_li = document.querySelectorAll(".menu_item_l3 li");
    //   menuItemLevel3_li.forEach((item) => {
    //     // console.log("menuItemLevel2_ul.item.classList=", item.classList);
    //     if (item.classList.contains("menu_item_active")) {
    //       item.classList.remove("menu_item_active");
    //     }
    //   });
    // },
    footerSelectMenu(event) {
      this.$refs.footer_menu_items.toggle(event);
    },
    changeLanguage() {
      this.isShowLanguageSettingDialog = true;
    },
    changePassword() {
      this.isChangePasswordDialogVisible = true;
      // this.isShowChangePasswordDialog = true;
    },
    logout() {
      window.document.body.style.paddingLeft = 0;
      this.isOpened = false;
      this.$store.dispatch("autoLogout");
    },
    searchMenu() {
      var container = this.$refs.sidebar_body;
      container.scrollTop = 0;
      // console.log("searchText=", this.searchText);
      // console.log("filteredMenuLevel3=", this.filteredMenuLevel3);
      // console.log("filteredMenuLevel2=", this.filteredMenuLevel2);
      // console.log("filteredMenu=", this.filteredMenu);

      if (!this.searchText && !(this.searchText == " ")) {
        if (this.menuActiveLevel1) {
          // const name_menu_item_l1 = "menu_item_l1_" + this.menuActiveLevel1;
          this.openSubmenuLevel1(this.menuActiveLevel1);
          if (this.menuActiveLevel2) {
            this.openSubmenuLevel2(this.menuActiveLevel2);
          }
        }
      }
    },
    async confirmChangePassword(data) {
      try {
        this.setLoading(true);

        await this.$store.dispatch("ChangePassword", data);

        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "confirm",
          },
        ];
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.titleChangePasswordSuccess;
        this.validateWording = this.lang.changePasswordSuccess;
        // this.isShowChangePasswordDialog = false;
        this.isChangePasswordDialogVisible = false;
        this.showValidateDialog = true;
      } catch (error) {
        this.openAlertDialogError(error);
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    closeChangePasswordDialog() {
      this.errorMessage = "";
      // this.isShowChangePasswordDialog = false;
      this.isChangePasswordDialogVisible = false;
    },
    confirmDialog() {
      this.errorMessage = "";
      this.showValidateDialog = false;
    },
    openAlertDialogError(error) {
      let message = error.message;
      if (error.code == "106") {
        message = this.lang.oldPasswordNotMatch;
      }
      this.errorMessage = message;
    },
    gotoHome(){
      this.initiateData();
      this.removeClassMenuItemActive();
      this.$router.push(`/${this.selectedProject.code}/home`)
    },
    getMenuActive(){      
      this.initiateData();      
      let route = this.$route;
      let path = route.path;
      let name = "";
      if(path.includes("/iframe")){        
        name = route.query.activeMenu;        
      }else if(route.name == "report"){
        name = "report-"+route.params.type;
      }else{
        name = route.meta.mappingNameSidebar;
      }
      // console.log("route=",route);
      // console.log("path=",path);
      // console.log("name=",name);
      let menuLevel4 = this.menuLevel4.find((x) => x.name == name);      
      
      if (menuLevel4) {
        this.menuActive = menuLevel4.id;
        this.menuActiveLevel2 = menuLevel4.reference;
        let menuLevel3 = this.menuLevel3.find((x) => x.id == menuLevel4.reference);
        if(menuLevel3){
          this.menuActiveLevel1 = menuLevel3.reference;
        }
      }else{
        let menuLevel3 = this.menuLevel3.find((x) => x.name == name);
        if (menuLevel3) {
          this.menuActive = menuLevel3.id;
          this.menuActiveLevel1 = menuLevel3.reference;
          this.menuActiveLevel2 = "";
        }else{
          let menuLevel2 = this.menuLevel2.find((x) => x.name == name);  
          if (menuLevel2) {
            this.menuActive = menuLevel2.id;
            this.menuActiveLevel1 = menuLevel2.id;
            this.menuActiveLevel2 = "";
          }
        }
      }
      let menuFooter = this.menuFooterItems.find((x) => x.name == name);
      if (menuFooter) {
        this.menuFooterActive = menuFooter.id;
      }
      if (this.menuActiveLevel1) {
        this.openSubmenuLevel1(this.menuActiveLevel1);
        if (this.menuActiveLevel2) {
          this.openSubmenuLevel2(this.menuActiveLevel2);
        }
      }
    },
    disableRightClick(event,data){
      if(!data.to){
        event.preventDefault();
      }
    }
  },
  computed: {
    cssVars() {
      return {
        "--padding-left-body": this.isOpened
          ? this.menuOpenedPaddingLeftBody
          : this.menuClosedPaddingLeftBody,
        "--padding-menu-icon": this.menuClosedPaddingLeftBody,
        "--padding-menu-link": this.isOpened ? this.menuLinkPaddingLeftBody : "0px",
        "--padding-menu-all": this.menuOpenedPaddingLeftBody,
        "--menu-items-hover-color": this.menuItemsHoverColor,
        "--menu-items-text-color": this.menuItemsTextColor,
        "--border-profile-color": this.borderProfile,
        "--menu-items-active-color": this.menuItemsActiveColor,
        "--menu-sidebar-body-height": this.sidebarBodyHeight + "px",
      };
    },
    employee() {
      return this.$store.getters.employee;
    },
    employeeName() {
      if (this.employee) {
        return this.employee.name;
      }
      return "";
    },
    menuLevel1() {
      let menuLevel1 = [];
      this.menuItems.forEach((e) => {
        menuLevel1.push(e);
      });
      return menuLevel1;
    },
    menuLevel2() {
      let menuLevel2 = [];
      this.menuItems.forEach((l1) => {
        if (l1.items) {
          l1.items.forEach((l2) => {
            menuLevel2.push(l2);
          });
        }
      });
      return menuLevel2;
    },
    menuLevel3() {
      let menuLevel3 = [];
      this.menuItems.forEach((l1) => {
        if (l1.items) {
          l1.items.forEach((l2) => {
            if (l2.items) {
              l2.items.forEach((l3) => {
                menuLevel3.push(l3);
              });
            }
          });
        }
      });
      return menuLevel3;
    },
    menuLevel4() {
      let menuLevel4 = [];
      this.menuItems.forEach((l1) => {
        if (l1.items) {
          l1.items.forEach((l2) => {
            if (l2.items) {
              l2.items.forEach((l3) => {
                if (l3.items) {
                  l3.items.forEach((l4) => {
                    menuLevel4.push(l4);
                  });
                }
              });
            }
          });
        }
      });
      return menuLevel4;
    },
    filteredMenu() {
      let menuLevel1 = [];
      if (this.searchText && this.isSearchTextInput) {
        let filteredMenuLevel1 = this.menuLevel1.filter((e) => {
          return e.to && e.label.toLowerCase().includes(this.searchText.toLowerCase());
        });

        this.menuLevel1.forEach((e) => {
          let menuLevel1s = this.filteredMenuLevel2.filter((x) => {
            return x.reference == e.id;
          });
          if (menuLevel1s.length > 0) {
            e = { ...e, items: menuLevel1s };
            menuLevel1.push(e);
          }
        });

        filteredMenuLevel1.forEach((e) => {
          let menu1 = menuLevel1.find((x) => x.id == e.id);
          if (!menu1) {
            e = { ...e, items: [] };
            menuLevel1.push(e);
          }
        });
      } else {
        menuLevel1 = this.menuLevel1;
      }

      return menuLevel1.sort((a, b) => (a.reference < b.reference ? -1 : 1));
    },
    filteredMenuLevel2() {
      let menuLevel2 = [];
      if (this.searchText) {
        let filteredMenuLevel2 = this.menuLevel2.filter((e) => {
          return e.to && e.label.toLowerCase().includes(this.searchText.toLowerCase());
        });

        this.menuLevel2.forEach((e) => {
          let menuLevel3s = this.filteredMenuLevel3.filter((x) => {
            return x.reference == e.id;
          });
          if (menuLevel3s.length > 0) {
            e = { ...e, items: menuLevel3s };
            menuLevel2.push(e);
          }
        });

        filteredMenuLevel2.forEach((e) => {
          let menu2 = menuLevel2.find((x) => x.id == e.id);
          if (!menu2) {
            e = { ...e, items: [] };
            menuLevel2.push(e);
          }
        });
      } else {
        menuLevel2 = this.menuLevel2;
      }

      return menuLevel2.sort((a, b) => (a.reference < b.reference ? -1 : 1));
    },
    filteredMenuLevel3() {
      let menuLevel3 = [];
      if (this.searchText) {
        let filteredMenuLevel3 = this.menuLevel3.filter((e) => {
          return e.to && e.label.toLowerCase().includes(this.searchText.toLowerCase());
        });

        this.menuLevel3.forEach((e) => {
          let menuLevel4s = this.menuLevel4.filter((x) => {
            return (
              x.to &&
              x.reference == e.id &&
              x.label.toLowerCase().includes(this.searchText.toLowerCase())
            );
          });
          if (menuLevel4s.length > 0) {
            e = { ...e, items: menuLevel4s };
            menuLevel3.push(e);
          }
        });

        filteredMenuLevel3.forEach((e) => {
          let menu3 = menuLevel3.find((x) => x.id == e.id);
          if (!menu3) {
            e = { ...e, items: [] };
            menuLevel3.push(e);
          }
        });
      } else {
        menuLevel3 = this.menuLevel3;
      }

      return menuLevel3.sort((a, b) => (a.reference < b.reference ? -1 : 1));
    },
    footerMenuItems() {
      let footerMenuItems = [
        {
          label: this.lang.titleLanguage,
          icon: "pi pi-globe",
          command: () => {
            this.changeLanguage();
          },
        },
        {
          label: this.lang.changePassword,
          icon: "pi pi-key",
          command: () => {
            this.changePassword();
          },
        },
        {
          label: this.lang.logout,
          icon: "pi pi-sign-out",
          command: () => {
            this.logout();
          },
        },
      ];

      return footerMenuItems;
    },
    isSearchTextInput() {
      if (this.searchText && this.searchText != " ") {
        return true;
      }
      return false;
    },
    viewportHeight() {
      return this.$store.getters.viewportHeight;
    },
    sidebarBodyHeight() {
      return this.viewportHeight - 270; //
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
  },
  watch: {
    isOpened() {
      window.document.body.style.paddingLeft =
        this.isOpened && this.isPaddingLeft
          ? this.menuOpenedPaddingLeftBody
          : this.menuClosedPaddingLeftBody;
    },
    $route(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.getMenuActive();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  transition: all 0.5s ease;
}
.menu-logo {
  width: 30px;
  margin: 0 10px 0 7px;
  cursor: pointer;
}
.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  min-height: min-content;
  /* overflow-y: auto; */
  width: var(--padding-left-body);
  background: var(--surface-0);
  /* padding: 6px 14px 0 14px; */
  z-index: 99;
  transition: all 0.5s ease;
  box-shadow: 0px 4px 10px rgba(132, 132, 132, 0.1);
}

.sidebar.open {
  width: var(--padding-left-body);
}

#my-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  /* height: calc(100% - 70px); */
  height: var(--menu-sidebar-body-height);
}
#my-scroll::-webkit-scrollbar {
  display: none;
}
.sidebar-header {
  height: 120px;
}
.sidebar-body {
  height: 900px;
}
.sidebar-body-search {
  height: 700px !important;
}
.sidebar-body-open {
  padding: 0px 10px;
}
.sidebar .logo-header {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}
.sidebar .logo-header .icon {
  opacity: 1;
  transition: all 0.5s ease;
}
.sidebar-header .header_name {
  line-height: 24px;
  letter-spacing: 0.5px;
  opacity: 1;
}
.sidebar-header .header_submenu_name {
  line-height: 16px;
  letter-spacing: 0.5px;
  opacity: 1;
  font-size: 10px;
  color: #6e7073;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2rem !important;
}
/*
input::placeholder {
  color: #b4b4b4;
  font-size: 12px;
  font-weight: 400px;
}*/

.p-button-rounded {
  border: 1px solid #ced0d6;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 0px 0px 1px 1px;
}

.item-opened {
  margin-left: 1.5rem;
}

.sidebar-footer {
  height: 150px;
  position: sticky;
  left: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 99;
  background-color: #ffff;
}

.sidebar-footer-open {
  padding: 0px 10px;
}
.sidebar-footer-close {
  padding-top: 5px;
}
.footer_item {
  display: flex;
  align-items: center;
  width: 100%;
  /* padding: 0px 8px; */
  border-radius: 8px;
  margin: 1px 0;
  line-height: 30px;
}
.footer_item_open {
  display: flex;
  align-items: center;
  width: 100%;
  /* padding: 5px 8px 5px 8px;
  border-radius: 8px; */
}

.footer_item_open:hover {
  background: var(--menu-items-hover-color);
}

.profile-img {
  background: var(--menu-items-hover-color);
  border-radius: 50%;
  padding-top: 5%;
}

.p-button-profile {
  border: 1px solid var(--border-profile-color);
  border-radius: 8px;
  cursor: pointer;
}

.sidebar-footer .logo-footer {
  height: 60px;
  display: flex;
  align-items: center;
  position: inherit;
  bottom: 0px;
  /* width: var(--padding-left-body); */
}

.menu_items {
  padding: 0;
  list-style: none;
}
.menu_title {
  /* margin: 15px 0; */
  padding: 10px 10px;
  letter-spacing: 0.5px;
  line-height: 24px;
  /* color: var(--menu-items-text-color); */
  color: #84868a;
}

.sidebar-body-title-close {
  content: "";
  /* position: absolute; */
  height: 0.5px;
  width: 30px;
  border-radius: 10px;
  /* padding: 1px; */
  margin-left: 8px;
}
.menu_item_close {
  padding: 0 0 0 13px;
}
.menu_item_l1 {
  display: flex;
  align-items: center;
  width: 100%;
  /* padding: 0px 0px 0px 13px; */
  border-radius: 8px;
  margin: 1px 0;
}
.menu_item_l1_active {
  color: var(--menu-items-active-color);
}
.menu_item_l1_active .arrow_down {
  transform: rotate(90deg);
  color: var(--menu-items-active-color);
}
.menu_item_l1_active .text_active {
  color: var(--menu-items-active-color);
}

.menu_item_l1_open {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 0px 5px 10px !important;
  border-radius: 8px;
}

.sidebar_menu_item_close {
  padding: 5px 0px 5px 0px;
  border-radius: 8px;
  color: var(--menu-items-text-color);
}
.sidebar_menu_item_close_active {
  background: var(--menu-items-hover-color);
}

.sidebar_menu_item_close:hover {
  background: var(--menu-items-hover-color);
}

.menu_item_l1_open:hover {
  background: var(--menu-items-hover-color);
}

.menu_item_l2 {
  /* display: flex;
  align-items: center; */
  width: 100%;
  padding: 0px 0px 0px 20px;
  border-radius: 8px;
  margin: 1px 0;
}

.menu_item_l2_active {
  color: var(--menu-items-active-color);
}

.menu_item_l2_active .arrow_down {
  transform: rotate(90deg);
  color: var(--menu-items-active-color);
}
.menu_item_l2_active .text_active {
  color: var(--menu-items-active-color);
}
.menu_item_l2 li {
  display: flex;
  align-items: center;
  padding: 5px 0px 5px 5px;
  line-height: 30px;
  border-radius: 8px;
  color: var(--menu-items-text-color);
}

.menu_item_l2 li:hover {
  background: var(--menu-items-hover-color);
}

.menu_item_l3 {
  /* display: flex; */
  align-items: center;
  width: 100%;
  padding: 0px 0px 0px 50px;
  border-radius: 8px;
}
.menu_item_l3 li {
  padding: 5px 0px 5px 15px;
  line-height: 30px;
  border-radius: 8px;
  color: var(--menu-items-text-color);
  margin: 1px 0;
}

.menu_item_l3 li:hover {
  background: var(--menu-items-hover-color);
}

.submenu_item {
  cursor: pointer;
}
.submenu_pointer {
  cursor: pointer;
}

.navlink_icon {
  position: relative;
  min-width: 40px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
  /* padding-top: 7px; */
  /* padding: 10px 0 10px 0px; */
}

.navlink_icon svg {
  display: flex;
}

.navlink {
  color: var(--menu-items-text-color);
  letter-spacing: 0.5px;
  /* line-height: 24px; */
  line-height: 30px;
}

.sidebar-body .submenu {
  display: none;
}
.show_submenu {
  display: block !important;
}

.show_submenu ~ .submenu {
  display: block;
}
.sidebar a {
  text-decoration: none;
}
.sidebar-close {
  display: none !important;
}
.menu_item_active {
  background: var(--menu-items-hover-color) !important;
}
.menu_item_active .text_active {
  color: var(--menu-items-active-color);
}
.menu_empty {
  padding: 5px;
  text-align: center;
  letter-spacing: 0.5px;
  color: var(--menu-items-text-color);
}

::v-deep(.p-overlay-badge) {
  .p-badge {
    transform: translate(0%, 0%) !important;
  }
}

::v-deep(.p-badge) {
  padding: 0 0rem;
}

::v-deep(.p-button) {
  padding: 0 0rem;
  .p-badge {
  min-width: 9px;
  height: 9px;
  }
}
</style>

<style>
/* .p-badge {
  padding: 0 0rem;
}
.p-overlay-badge .p-badge {
  transform: translate(0%, 0%) !important;
} */
/* .p-button .p-badge {
  min-width: 9px;
  height: 9px;
} */
.menu_item_l1_active .icon_active path {
  stroke: var(--menu-items-active-color);
}
.menu_item_l1_active .icon_active circle {
  stroke: var(--menu-items-active-color);
}
.menu_item_l1_active .icon_active rect {
  stroke: var(--menu-items-active-color);
}
.menu_item_l1_active .icon_active ellipse {
  stroke: var(--menu-items-active-color);
}
.menu_item_l1_active .menu_item_l1_active_close .icon_active path {
  stroke: var(--menu-items-text-color);
}
.menu_item_l1_active .menu_item_l1_active_close .icon_active circle {
  stroke: var(--menu-items-text-color);
}
.menu_item_l1_active .menu_item_l1_active_close .icon_active rect {
  stroke: var(--menu-items-text-color);
}
.menu_item_l1_active .menu_item_l1_active_close .icon_active ellipse {
  stroke: var(--menu-items-text-color);
}
.menu_item_l2_active .icon_active path {
  stroke: var(--menu-items-active-color);
}
.menu_item_l2_active .icon_active circle {
  stroke: var(--menu-items-active-color);
}
.menu_item_l2_active .icon_active rect {
  stroke: var(--menu-items-active-color);
}
.menu_item_l2_active .icon_active ellipse {
  stroke: var(--menu-items-active-color);
}

.menu_item_active .icon_active path {
  stroke: var(--menu-items-active-color);
}
.menu_item_active .icon_active circle {
  stroke: var(--menu-items-active-color);
}
.menu_item_active .icon_active rect {
  stroke: var(--menu-items-active-color);
}
.menu_item_active .icon_active ellipse {
  stroke: var(--menu-items-active-color);
}
</style>
