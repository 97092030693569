<template>
  <base-container>
    <edit-report-menu-dialog
      v-model:visible="showEditReportMenuModal"
      @close="closeReportMenuDialog"
      @saved="saved"
      :reportMenu="selectedReportMenu"
    ></edit-report-menu-dialog>
    <div class="flex justify-content-between align-items-center">
      <div class="my-3">
        <PvButton
          icon="pi pi-chevron-left"
          :label="lang.back"
          @click="goBack"
          class="mr-2 p-button-secondary"
        />
      </div>
      <h2 :class="textHeaderClass" class="my-0">{{ lang.title }}</h2>
    </div>
    <div class="surface-card shadow-2 p-3 border-round mt-5 mb-5">
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <PvButton
            icon="pi pi-plus"
            :label="lang.addNewReportMenu"
            class="mr-2 mb-2"
            @click="addReportMenu"
          />
        </div>
        <section class="flex flex-wrap" :class="textFormClass">
          <label for="searchText">{{ lang.searchText }}</label>
          <div class="p-inputgroup">
            <PvInputText
              id="searchText"
              type="text"
              class="inputfield w-full"
              :placeholder="lang.search"
              v-model="searchText"
              @keyup.enter="onSearchInputKeyUp"
            />
            <PvButton icon="pi pi-search" @click="getReportMenus" />
          </div>
        </section>
      </div>

      <PvDataTable
        showGridlines
        :value="reportMenus"
        v-model:selection="selectedReportMenu"
        selectionMode="single"
        dataKey="id"
        responsiveLayout="scroll"
        :stripedRows="true"
        class="p-datatable-sm"
        :class="textTableClass"
        :loading="isLoading"
        @row-dblclick="editReportMenu(selectedReportMenu.data)"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn
          field="sequence"
          :header="lang.sequence"
          style="min-width: 4rem"
          headerClass="h-center"
          bodyClass="text-center"
        ></PvColumn>
        <PvColumn
          field="nameThai"
          :header="lang.nameTh"
          style="min-width: 4rem"
          headerClass="h-center"
        ></PvColumn>
        <PvColumn
          field="nameEnglish"
          :header="lang.nameEn"
          style="min-width: 4rem"
          headerClass="h-center"
        ></PvColumn>
        <PvColumn
          field="path"
          :header="lang.path"
          style="min-width: 4rem"
          headerClass="h-center"
        ></PvColumn>
        <PvColumn
          field="isActive"
          :header="lang.activeStatus"
          style="min-width: 4rem"
          headerClass="h-center"
          bodyClass="text-center"
        ></PvColumn>
        <PvColumn
          style="max-width: 4rem"
          headerClass="h-center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <PvButton
              type="button"
              class="p-button-secondary p-button-text py-0"
              icon="pi pi-pencil"
              @click="editReportMenu(slotProps.data)"
            ></PvButton>
            <PvButton
              type="button"
              class="p-button-secondary p-button-text py-0"
              icon="pi pi-cog"
              @click="gotoDetail(slotProps.data)"
            ></PvButton>
          </template>
        </PvColumn>
        <template #footer>
          <PvPaginator
            :rows="rowsPerPage"
            :totalRecords="totalRecords"
            @page="onPageChange($event)"
            :rowsPerPageOptions="[10, 50, 100]"
          >
            <template #start="slotProps">
              {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /
              {{ totalPage }}
            </template>
          </PvPaginator>
        </template>
      </PvDataTable>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import EditReportMenuDialog from "../../../components/admin/report/EditReportMenuDialog.vue";
export default {
  components: {
    EditReportMenuDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.admin.report };
      } else {
        return { ...lang.en.base, ...lang.en.admin.report };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
    };
  },
  data() {
    return {
      isLoading: false,
      searchText: "",
      selectedReportMenu: null,
      selectedPage: 1,
      rowsPerPage: 10,
      showEditReportMenuModal: false,
    };
  },
  async mounted() {
    await this.getReportMenus();
    // console.log(this.reportMenus);
  },
  methods: {
    goBack() {
			this.$router.push({name: "home"})
    },
    addReportMenu() {
      this.showEditReportMenuModal = true;
    },
    onSearchInputKeyUp(event) {
      if (event.key == "Enter") {
        this.getReportMenus();
      }
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getReportMenus();
    },
    async getReportMenus() {
      this.isLoading = true;

      let where = {
        searchText: this.searchText,
      };

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };

      await this.$store.dispatch("reportMenu/getReportMenus", payload);

      this.isLoading = false;
    },
    editReportMenu(data) {
      this.selectedReportMenu = data;
      this.showEditReportMenuModal = true;
    },
    gotoDetail(data) {
      this.$store.dispatch('reportMenu/setSelectedReportMenu',data)
      this.$router.push({ name: "admin-report-menu-detail", params: { id: data.id} });
    },
    saved() {
      this.getReportMenus();
    },
    closeReportMenuDialog() {
      this.showEditReportMenuModal = false;
      this.selectedReportMenu = null;
    },
  },
  computed: {
    reportMenus() {
      return this.$store.getters["reportMenu/reportMenus"];
    },
    totalPage() {
      return this.$store.getters["reportMenu/totalPage"];
    },
    currentPage() {
      return this.$store.getters["reportMenu/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
  },
};
</script>
