<template>
  <base-toolbar
    :title="lang.titleEdit"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoSave="confirmSave"
  >
  </base-toolbar>

  <base-dialog
    v-model:visible="showConfirmDialog"
    :classTitle="classTitleErrorDialog"
    :titleConfirm="titleConfirmDialog"
    :wordingConfirm="wordingDialog"
    :dataButtons="dataButtonDialog"
    @confirm="confirm"
    @close="close"
    @close-saved="closeAfterSave"
  ></base-dialog>

  <base-flash-address-dialog
    v-model:visible="showFlashAddress"
    @close="showFlashAddress = false"
    @flashAddress-selected="selectedFlashAddress"
    :openFrom="openFrom"
  >
  </base-flash-address-dialog>
  <div class="mt24"></div>
  <base-container>
    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5">
      <base-card
        :titleHeader="lang.projectTitleGeneral"
        :isVisibleExpandButton="true"
        :isHideBackground="true"
        :isVisibleCard="true"
      >
        <section class="flex flex-wrap" :class="textFormClass">
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.projectCode + "*" }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1 disableInputText"
                v-model="Code"
                :placeholder="lang.validateProjectCode"
                readonly
              />
            </div>
            <!-- <div class="mt-1">
              <base-input-text-limit
                :caption="lang.projectCode + '*'"
                :limitMessage="20"
                :isError="isProjectCodeError"
                :errorMessage="lang.validateProjectCode"
                :data="Code"
                :textHolder="lang.validateProjectCode"
                @data="updateCode"
              />
            </div> -->
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label>{{ lang.projectType + "*" }}</label>
            <div class="mt-1">
              <PvDropdown
                class="inputfield w-full mt-1"
                :options="ProjectTypes"
                optionLabel="name"
                optionValue="code"
                v-model="SelectedProjectType"
                :placeholder="lang.validateProjectType"
                :class="{ 'p-invalid': isProjectTypeError }"
                @change="changeProjectType()"
              />
              <small
                v-if="isProjectTypeError"
                id="isProjectTypeError-help"
                class="p-error"
              >
                {{ lang.validateProjectType }}
              </small>
              <!-- <base-project-type-auto-complete
                id="id"
                v-model="selectedProjectType"
                class="inputfield w-full mt-1"
                @load-finish="ProjectTypeDataReady"
              /> -->
            </div>
          </div>
          <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
            <!-- <label>{{ lang.projectNameTh + "*" }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="NameTh"
                :placeholder="lang.validateProjectName"
                :class="{ 'p-invalid': isNameThError }"
                @keyup="keyupProjectNameTh()"
              />
              <small
                v-if="isNameThError"
                id="isTaxNoError-help"
                class="p-error"
              >
                {{ lang.validateProjectName }}
              </small>
            </div> -->
            <div class="mt-1">
              <base-input-text-limit
                :caption="lang.projectNameTh + '*'"
                :limitMessage="100"
                :isError="isNameThError"
                :errorMessage="lang.validateProjectName"
                :data="NameTh"
                :textHolder="lang.validateProjectName"
                @data="updateNameTh"
              />
            </div>
          </div>
          <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
            <!-- <label>{{ lang.projectNameEn }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="NameEn"
                :placeholder="lang.validateProjectName"
              />
            </div> -->
            <div class="mt-1">
              <base-input-text-limit
                :caption="lang.projectNameEn"
                :limitMessage="100"
                :data="NameEn"
                :textHolder="lang.validateProjectName"
                @data="updateNameEn"
              />
            </div>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.projectComNickName }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="NickName"
                :placeholder="lang.validateProjectNickName"
              />
            </div>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label>{{ lang.projectComShortCode }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="ShortCode"
                :placeholder="lang.validateProjectShortCode"
              />
            </div>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.projectTel }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="Tel"
                :placeholder="lang.validateProjectTel"
                :class="{ 'p-invalid': isProjectTelError }"
                @keyup="keyupTel()"
                :maxlength="13"
              />
              <small
                v-if="isProjectTelError"
                id="isProjectTelError-help"
                class="p-error"
              >
                {{ lang.validateTel }}
              </small>
            </div>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label>{{ lang.projectFax }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="Fax"
                :placeholder="lang.validateProjectFax"
              />
            </div>
          </div>
          <!-- <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.projectHouseTel }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="HouseTel"
                :placeholder="lang.validateProjectHouseTel"
                readonly
              />
            </div>
          </div> -->
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <div class="flex justify-content-between align-items-center">
              <label
                >{{ lang.projectTaxId + "*" }} &nbsp;
                <i
                  style="cursor: pointer"
                  class="pi pi-exclamation-circle"
                  v-tooltip.top="{
                    value: lang.tooltipTaxNo,
                    class: 'custom-tooltip',
                  }"
                ></i>
              </label>

              <label style="color: #585a5c">{{ lang.textTaxNo }}</label>
            </div>
            <div class="mt-1">
              <!-- <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="TaxNo"
                placeholder="0-0000-00000-00-0"
              /> -->
              <PvInputMask
                id="TaxNo"
                type="text"
                mask="9-9999-99999-99-9"
                v-model.trim="TaxNo"
                class="inputfield w-full mt-1"
                placeholder="0-0000-00000-00-0"
                maxlength="13"
                :class="{ 'p-invalid': isTaxNoError }"
                @keyup="checkTaxNo()"
                @blur="checkTaxNo()"
              />
              <small v-if="isTaxNoError" id="isTaxNoError-help" class="p-error">
                {{ lang.validateTaxNo }}
              </small>
              <!-- <PvInputText
                id="TaxNo"
                type="text"
                class="inputfield w-full mt-1"
                placeholder="0-0000-00000-00-0"
                v-model.trim="TaxNo"
                maxlength="13"
                :class="{ 'p-invalid': isTaxNoError }"
                @keyup="checkTaxNo()"
                @blur="checkTaxNo()"
              />
              <small v-if="isTaxNoError" id="isTaxNoError-help" class="p-error">
                {{ lang.validateTaxNo }}
              </small> -->
            </div>        
          </div>                
          <!-- <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
              <label>{{ lang.suffix }}</label>
              <div class="mt-1">
                <PvInputText
                  type="text"
                  class="inputfield w-full mt-1"
                  v-model="suffix"
                  :placeholder="lang.suffixPlaceholder"                  
                  :maxlength="2"
                  @keypress="onlyNumber"
                  :class="{ 'p-invalid': isSuffixError }"
                />                
                <small v-if="isSuffixError" id="isSuffixError-help" class="p-error">
                  {{ lang.validateSuffixError }}
                </small>
              </div>
          </div> -->
        </section>
      </base-card>

      <base-card
        :titleHeader="lang.projectTitleAddress"
        :isVisibleExpandButton="true"
        :isHideBackground="true"
        :isVisibleCard="true"
      >
        <PVTabView>
          <PVTabPanel>
            <template #header>
              <span>{{ lang.tabAddressProject }} </span>
            </template>
            <!-- *************************************************** -->
            <section class="flex flex-wrap" :class="textFormClass">
              <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                <label>{{ lang.addressTh }} </label>
              </div>
              <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                <label>{{ lang.address1 + "*" }}</label>
                <div class="mt-1">
                  <PvInputText
                    type="text"
                    class="inputfield w-full mt-1"
                    v-model="AddressTh1"
                    :placeholder="lang.validateParcelAddress1"
                    :class="{ 'p-invalid': isAddressError }"
                    @keyup="keyupAddressTh()"
                  />
                  <small
                    v-if="isAddressError"
                    id="isAddressError-help"
                    class="p-error"
                  >
                    {{ lang.validateParcelAddress1 }}
                  </small>
                </div>
              </div>
              <div
                class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4"
              >
                <label>{{ lang.address2 + "*" }}</label>
                <div class="mt-1">
                  <PvInputText
                    type="text"
                    class="inputfield w-full mt-1"
                    v-model="AddressTh2"
                    :placeholder="lang.validateParcelAddress2"
                    :class="{ 'p-invalid': isAddress2Error }"
                    @keyup="keyupAddress2Th()"
                  />
                  <small
                    v-if="isAddress2Error"
                    id="isAddress2Error-help"
                    class="p-error"
                  >
                    {{ lang.validateParcelAddress2 }}
                  </small>
                </div>
              </div>
              <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
                <label>{{ lang.address3 + "*" }}</label>
                <div class="mt-1">
                  <PvInputText
                    type="text"
                    class="inputfield w-full mt-1"
                    v-model="AddressTh3"
                    :placeholder="lang.validateParcelAddress3"
                    :class="{ 'p-invalid': isAddress3Error }"
                    @keyup="keyupAddress3Th()"
                  />
                  <small
                    v-if="isAddress3Error"
                    id="isAddress3Error-help"
                    class="p-error"
                  >
                    {{ lang.validateParcelAddress3 }}
                  </small>
                </div>
              </div>
              <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                <label>{{ lang.addressEn }}</label>
              </div>
              <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                <label>{{ lang.address1 }}</label>
                <div class="mt-1">
                  <PvInputText
                    type="text"
                    class="inputfield w-full mt-1"
                    v-model="AddressEn1"
                    :placeholder="lang.validateParcelAddress1"
                  />
                </div>
              </div>
              <div
                class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4"
              >
                <label>{{ lang.address2 }}</label>
                <div class="mt-1">
                  <PvInputText
                    type="text"
                    class="inputfield w-full mt-1"
                    v-model="AddressEn2"
                    :placeholder="lang.validateParcelAddress2"
                  />
                </div>
              </div>
              <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
                <label>{{ lang.address3 }}</label>
                <div class="mt-1">
                  <PvInputText
                    type="text"
                    class="inputfield w-full mt-1"
                    v-model="AddressEn3"
                    :placeholder="lang.validateParcelAddress3"
                  />
                </div>
              </div>
            </section>
            <!-- *************************************************** -->
          </PVTabPanel>

          <PVTabPanel>
            <template #header>
              <span>{{ lang.tabAddressParcel }} </span>
              &nbsp; &nbsp;<i
                class="pi pi-exclamation-circle"
                v-tooltip.top="{
                  value: lang.tooltipAddressParcel,
                  class: 'custom-tooltip',
                }"
              ></i>
            </template>

            <!-- *************************************************** -->
            <section class="flex flex-wrap" :class="textFormClass">
              <div
                class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4"
              >
                <label>{{ lang.parcelName }}</label>
                <div class="mt-1">
                  <PvInputText
                    type="text"
                    class="inputfield w-full mt-1"
                    v-model="ContactName"
                    :placeholder="lang.validateParcelName"
                  />
                </div>
              </div>
              <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
                <label>{{ lang.parcelTel }}</label>
                <div class="mt-1">
                  <PvInputText
                    type="text"
                    class="inputfield w-full mt-1"
                    v-model="Telephone"
                    :placeholder="lang.validateProjectTel"
                  />
                </div>
              </div>
              <div
                class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4"
              >
                <label>{{ lang.parcelEmail }}</label>
                <div class="mt-1">
                  <PvInputText
                    type="text"
                    class="inputfield w-full mt-1"
                    v-model="Email"
                    :placeholder="lang.validateParcelEmail"
                    :class="{ 'p-invalid': isEmailError }"
                    @blur="blurEmail"
                  />
                  <small
                    v-if="isEmailError"
                    id="isEmailError-help"
                    class="p-error"
                  >
                    {{ lang.validateEmailError }}
                  </small>
                </div>
              </div>
              <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
                <label>{{ lang.address1 }}</label>
                <div class="mt-1">
                  <PvInputText
                    type="text"
                    class="inputfield w-full mt-1"
                    v-model="Address"
                    :placeholder="lang.validateParcelAddress1"
                  />
                </div>
              </div>

              <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                <PvDivider />
              </div>

              <div
                class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4"
              >
                <label>{{ lang.province }}</label>
                <div class="mt-1">
                  <PvInputText
                    type="text"
                    class="inputfield w-full mt-1 disableInputText"
                    v-model="Province"
                    readonly
                  />
                </div>
              </div>
              <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
                <label>{{ lang.district }}</label>
                <div class="mt-1">
                  <PvInputText
                    type="text"
                    class="inputfield w-full mt-1 disableInputText"
                    v-model="District"
                    readonly
                  />
                </div>
              </div>
              <div
                class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4"
              >
                <label>{{ lang.subarea }}</label>
                <div class="mt-1">
                  <PvInputText
                    type="text"
                    class="inputfield w-full mt-1 disableInputText"
                    v-model="Subarea"
                    readonly
                  />
                </div>
              </div>
              <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
                <label>{{ lang.postcode }}</label>
                <div class="mt-1">
                  <PvInputText
                    type="text"
                    class="inputfield w-full mt-1 disableInputText"
                    v-model="Postcode"
                    readonly
                  />
                </div>
              </div>
            </section>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
              <PvButton
                class="mr-2 mb-2"
                @click="editFlashAddress"
              >
                <span class="w-full">{{this.lang.selectAddress}}</span>
              </PvButton>
            </div>

            <!-- *************************************************** -->
          </PVTabPanel>
        </PVTabView>
      </base-card>

      <base-card
        :titleHeader="this.lang.projectTitlePerson"
        :isVisibleExpandButton="true"
        :isHideBackground="true"
        :isVisibleCard="true"
      >
        <section class="flex flex-wrap" :class="textFormClass">
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.projectManagerNameTh }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="ProjectManagerNameTh"
                :placeholder="lang.validateProjectManagerNameTh"
              />
            </div>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label>{{ lang.projectManagerNameEn }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="ProjectManagerNameEn"
                :placeholder="lang.validateProjectManagerNameEn"
              />
            </div>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.projectManagerEmail }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="ProjectManagerEmail"
                :placeholder="lang.validateProjectManagerEmail"
                :class="{ 'p-invalid': isProjectManagerEmailError }"
                @blur="blurProjectManagerEmail"
              />
              <small
                v-if="isProjectManagerEmailError"
                id="isProjectManagerEmailError-help"
                class="p-error"
              >
                {{ lang.validateEmailError }}
              </small>
            </div>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label>{{ lang.debtFreeAuthEmail }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="DebtFreeAuthEmail"
                :placeholder="lang.validateDebtFreeAuthEmail"
                :class="{ 'p-invalid': isDebtFreeAuthEmailError }"
                @blur="blurDebtFreeAuthEmail"
              />
              <small
                v-if="isDebtFreeAuthEmailError"
                id="isDebtFreeAuthEmailError-help"
                class="p-error"
              >
                {{ lang.validateEmailError }}
              </small>
            </div>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.communityManagerNameTh }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="CommunityManagerNameTh"
                :placeholder="lang.validateCommunityManagerNameTh"
              />
            </div>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label>{{ lang.communityManagerNameEn }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="CommunityManagerNameEn"
                :placeholder="lang.validateCommunityManagerNameEn"
              />
            </div>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.accountantNameTh }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="AccountantNameTh"
                :placeholder="lang.validateAccountantNameTh"
              />
            </div>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label>{{ lang.accountantNameEn }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="AccountantNameEn"
                :placeholder="lang.validateAccountantNameEn"
              />
            </div>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.accManagerName }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="AccManagerName"
                :placeholder="lang.validateAccManagerName"
              />
            </div>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label>{{ lang.communityManageBy }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="CommunityManageBy"
                :placeholder="lang.validateCommunityManageBy"
              />
            </div>
          </div>
        </section>
      </base-card>

      <!-- 
      <base-card
        :titleHeader="this.lang.projectTitleAdvertise"
        :isVisibleExpandButton="true"
        :isHideBackground="true"
        :isVisibleCard="true"
      >
        <PVTabView>
          <PVTabPanel>
            <template #header>
              <span
                >{{ lang.tabAdvertiseInvoice }}
              </span>
            </template>            
            <section class="flex flex-wrap " :class="textFormClass">
              <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                <label>{{ lang.advertiseInvoiceTh }}</label>
                <div class="mt-1">
                  <PvTextarea
                    v-model="AdvertiseInvoiceTh"
                    rows="4"
                    :placeholder="lang.validateAdvertiseInvoiceTh"
                    class="inputfield w-full mt-1"
                    :maxlength="750"
                  />
                </div>
              </div>
              <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                <label>{{ lang.advertiseInvoiceEn }}</label>
                <div class="mt-1">
                  <PvTextarea
                    v-model="AdvertiseInvoiceEn"
                    rows="4"
                    :placeholder="lang.validateAdvertiseInvoiceEn"
                    class="inputfield w-full mt-1"
                    :maxlength="750"
                  />
                </div>
              </div>
            </section>
          </PVTabPanel>
          <PVTabPanel>
            <template #header>
              <span
                >{{ lang.tabAdvertiseNotice }}
              </span>
            </template>
            <section class="flex flex-wrap " :class="textFormClass">
              <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                <label>{{ lang.advertiseNoticeTh }}</label>
                <div class="mt-1">
                  <PvTextarea
                    v-model="AdvertiseNoticeTh"
                    rows="4"
                    :placeholder="lang.validateAdvertiseNoticeTh"
                    class="inputfield w-full mt-1"
                    :maxlength="750"
                  />
                </div>
              </div>
              <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                <label>{{ lang.advertiseNoticeEn }}</label>
                <div class="mt-1">
                  <PvTextarea
                    v-model="AdvertiseNoticeEn"
                    rows="4"
                    :placeholder="lang.validateAdvertiseNoticeEn"
                    class="inputfield w-full mt-1"
                    :maxlength="750"
                  />
                </div>
              </div>
            </section>
          </PVTabPanel>
          <PVTabPanel>
            <template #header>
              <span
                >{{ lang.tabAdvertiseReceipt }}
              </span>
            </template>
            <section class="flex flex-wrap " :class="textFormClass">
              <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                <label>{{ lang.advertiseReceiptTh }}</label>
                <div class="mt-1">
                  <PvTextarea
                    v-model="AdvertiseReceiptTh"
                    rows="4"
                    :placeholder="lang.validateAdvertiseReceiptTh"
                    class="inputfield w-full mt-1"
                    :maxlength="750"
                  />
                </div>
              </div>
              <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                <label>{{ lang.advertiseReceiptEn }}</label>
                <div class="mt-1">
                  <PvTextarea
                    v-model="AdvertiseReceiptEn"
                    rows="4"
                    :placeholder="lang.validateAdvertiseReceiptEn"
                    class="inputfield w-full mt-1"
                    :maxlength="750"
                  />
                </div>
              </div>
            </section>
          </PVTabPanel>
          <PVTabPanel>
            <template #header>
              <span
                >{{ lang.tabAdvertiseInvoiceReceipt }}
              </span>
            </template>
            <section class="flex flex-wrap " :class="textFormClass">
              <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                <label>{{ lang.invoiceFooter }}</label>
                <div class="mt-1">
                  <PvTextarea
                    v-model="InvoiceFooter"
                    rows="4"
                    :placeholder="lang.validateInvoiceFooter"
                    class="inputfield w-full mt-1"
                    :maxlength="3000"
                  />
                </div>
              </div>
              <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                <label>{{ lang.receiptFooter }}</label>
                <div class="mt-1">
                  <PvTextarea
                    v-model="ReceiptFooter"
                    rows="4"
                    :placeholder="lang.validateReceiptFooter"
                    class="inputfield w-full mt-1"
                    :maxlength="3000"
                  />
                </div>
              </div>
            </section>
          </PVTabPanel>
        </PVTabView>
      </base-card> -->

      <base-card
        :titleHeader="lang.projectTitleRegistered"
        :isVisibleExpandButton="true"
        :isHideBackground="true"
        :isVisibleCard="true"
      >
        <!-- *************************************************** -->
        <section class="flex flex-wrap" :class="textFormClass">
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.juristicId }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="RegisteredNo"
                :placeholder="lang.validateJuristicId"
              />
            </div>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label>{{ lang.juristicDate }}</label>
            <div class="mt-1">
              <PvCalendar
                id="JuristicRegisteredDate"
                v-model="JuristicRegisteredDate"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
                :placeholder="lang.validateDate"
              />
            </div>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.ownershipRatio }}</label>
            <div class="mt-1">
              <PvInputNumber
                id="TotalOwnerRatio"
                class="inputfield w-full mt-1"
                v-model="TotalOwnerRatio"
                mode="decimal"
                :placeholder="lang.validateOwnershipRatio"			
                :minFractionDigits="2"
                :maxFractionDigits="2"
              />
            </div>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label>{{ lang.projectArea }}</label>
            <div class="mt-1">
              <PvInputNumber
                id="TotalArea"
                class="inputfield w-full mt-1"
                v-model="TotalArea"
                mode="decimal"
                :placeholder="lang.validateProjectArea"
                :minFractionDigits="2"
                :maxFractionDigits="2"
              />
            </div>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.numberofRoom }}</label>
            <div class="mt-1">
              <PvInputNumber
                id="TotalUnit"
                class="inputfield w-full mt-1"
                v-model="TotalUnit"
                mode="decimal"
                :placeholder="lang.validateNumberofRoom"
              />
            </div>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label>{{ lang.numberofStores }}</label>
            <div class="mt-1">
              <PvInputNumber
                id="TotalShop"
                class="inputfield w-full mt-1"
                v-model="TotalShop"
                mode="decimal"
                :placeholder="lang.validateNumberofStores"
              />
            </div>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.numberofCars }}</label>
            <div class="mt-1">
              <PvInputNumber
                id="TotalCarPark"
                class="inputfield w-full mt-1"
                v-model="TotalCarPark"
                mode="decimal"
                :placeholder="lang.validateNumberofCars"
              />
            </div>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <!-- <label>{{ lang.averageWaterMeter }}</label>
            <div class="mt-1">
              <PvInputNumber
                id="AverageWaterMeter"
                class="inputfield w-full mt-1"
                v-model="AverageWaterMeter"
                mode="decimal"
                :placeholder="lang.validateAverageWaterMeter"
                :class="{ 'p-invalid': isAverageWaterMeterError }"
                @keydown="keyupAverageWaterMeter"  
              />
              <small
                v-if="isAverageWaterMeterError"
                id="isAverageWaterMeterError-help"
                class="p-error"
              >
                {{ lang.validateAverageWaterMeter }}
              </small>
            </div> -->
          </div>
          <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
            <PvDivider />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.capitalFirst }}</label>
            <div class="mt-1">
              <PvInputNumber
                id="FirstFundAmount"
                class="inputfield w-full mt-1"
                v-model="FirstFundAmount"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                :placeholder="lang.validateNumber"
              />
            </div>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label>{{ lang.capitalBalance }}</label>
            <div class="mt-1">
              <PvInputNumber
                id="FundAmountLeft"
                class="inputfield w-full mt-1"
                v-model="FundAmountLeft"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                :placeholder="lang.validateNumber"
              />
            </div>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.FinanceDateStart }}</label>
            <div class="mt-1">
              <PvCalendar
                id="FinanceDateStart"
                v-model="FinanceDateStart"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
                :placeholder="lang.validateDate"
              />
            </div>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label>{{ lang.FinanceDateEnd }}</label>
            <div class="mt-1">
              <PvCalendar
                id="FinanceDateEnd"
                v-model="FinanceDateEnd"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
                :placeholder="lang.validateDate"
              />
            </div>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.BudgetDateStart }}</label>
            <div class="mt-1">
              <PvCalendar
                id="BudgetDateStart"
                v-model="BudgetDateStart"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
                :placeholder="lang.validateDate"
              />
            </div>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label>{{ lang.BudgetDateEnd }}</label>
            <div class="mt-1">
              <PvCalendar
                id="BudgetDateEnd"
                v-model="BudgetDateEnd"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
                :placeholder="lang.validateDate"
              />
            </div>
          </div>
        </section>
        <!-- *************************************************** -->
      </base-card>
    </div>
    <div class="mt24"></div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
// import BaseProjectTypeAutoComplete from "../../../components/master/project/BaseProjectTypeAutoComplete.vue";
import BaseFlashAddressDialog from "../../../components/master/project/BaseFlashAddressDialog.vue";

import { useStore } from "vuex";
import { computed } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
export default {
  components: {
    // BaseProjectTypeAutoComplete,
    BaseFlashAddressDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.project.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.project.edit,
        };
      }
    });
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
    };
  },
  data() {
    return {
      showFlashAddress: false,
      // selectedProjectType: null,
      // isProjectTypeReady: false,
      Code: "",
      SelectedProjectType: null,
      ProjectTypes: this.lang.projectTypes,
      NameTh: "",
      NameEn: "",
      NickName: "",
      ShortCode: "",
      Tel: "",
      HouseTel: "",
      Fax: "",
      TaxNo: "",
      suffix: "",

      //ข้อมูลที่อยู่
      AddressTh1: "",
      AddressTh2: "",
      AddressTh3: "",
      AddressEn1: "",
      AddressEn2: "",
      AddressEn3: "",

      //ข้อมูลบุคคล
      ProjectManagerNameTh: "",
      ProjectManagerNameEn: "",
      ProjectManagerEmail: "",
      DebtFreeAuthEmail: "",
      CommunityManagerNameTh: "",
      CommunityManagerNameEn: "",
      AccountantNameTh: "",
      AccountantNameEn: "",
      AccManagerName: "",
      CommunityManageBy: "",

      // //ประชาสัมพันธ์ - โฆษณา
      // AdvertiseInvoiceTh: "",
      // AdvertiseInvoiceEn: "",
      // AdvertiseNoticeTh: "",
      // AdvertiseNoticeEn: "",
      // AdvertiseReceiptTh: "",
      // AdvertiseReceiptEn: "",
      // InvoiceFooter: "",
      // ReceiptFooter: "",

      //ข้อมูลการจดทะเบียน
      RegisteredNo: "",
      JuristicRegisteredDate: null,
      // AverageWaterMeter: 0,
      TotalOwnerRatio: 0,
      TotalArea: 0,
      TotalUnit: 0,
      TotalShop: 0,
      TotalCarPark: 0,
      FirstFundAmount: 0,
      FundAmountLeft: 0,
      FinanceDateStart: null,
      FinanceDateEnd: null,
      BudgetDateStart: null,
      BudgetDateEnd: null,
      isUpdateLosa: false,
      ContactName: "",
      Telephone: "",
      Email: "",
      Address: "",
      PostAddressId: null,
      Province: "",
      District: "",
      Subarea: "",
      Postcode: "",
      FlashExpressAddress: {
        ProjectId: "",
        PostAddressId: null,
        ContactName: "",
        Telephone: "",
        Email: "",
        Address: "",
      },
      openFrom: "",
      isProjectCodeError: false,
      isProjectTypeError: false,
      isNameThError: false,
      isTaxNoError: false,
      isProjectTelError: false,
      isAddressError: false,
      isAddress2Error: false,
      isAddress3Error: false,
      isEmailError: false,
      isProjectManagerEmailError: false,
      isDebtFreeAuthEmailError: false,
      // isAverageWaterMeterError: false,
      showConfirmDialog: false,
      wordingDialog: "",
      showConfirmDialogName: "",
      titleConfirmDialog: "",
      isSuffixError: false,

      //Props Toolbar
      dataButtons: [
        {
          id: "saveCaption",
          action: "gotoSave",
          type: "save",
        },
      ],
      classTitleErrorDialog: "",
      dataButtonDialog: [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ],
    };
  },
  async mounted() {
    await this.GetProject();
    await this.checkShouldSetData();
  },
  methods: {
    async setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.showConfirmDialogName = "goBack";
      if (this.checkDatagoBack()) {
        this.openDialog({
          severity: "",
          title: this.lang.validategoBackTitle,
          content: `${this.lang.validategoBackEdit.replace(":title",this.lang.editProject)}`,
          isConfirm: true,
        });
      } else {
        this.$router.push({name: "master-project"});
      }
    },
    checkDatagoBack() {
      //ข้อมูลทั่วไป
      // console.log("this.FlashExpressAddress=",this.FlashExpressAddress);
      // console.log("this.project=",this.project.firstmileProject.postAddress.id);
      if (this.project.nameTh !== this.NameTh) return true;
      if (this.project.nameEn !== this.NameEn) return true;
      if (this.project.nickName !== this.NickName) return true;
      if (this.project.shortCode !== this.ShortCode) return true;
      if (this.project.tel !== this.Tel) return true;
      //  if(this.project.houseTel !== this.HouseTel) return true;
      if (this.project.fax !== this.Fax) return true;
      if (this.project.taxId !== this.taxNoReplace) return true;
      if (this.project.addressTh1 !== this.AddressTh1) return true;
      if (this.project.addressTh2 !== this.AddressTh2) return true;
      if (this.project.addressTh3 !== this.AddressTh3) return true;
      if (this.project.addressEn1 !== this.AddressEn1) return true;
      if (this.project.addressEn2 !== this.AddressEn2) return true;
      if (this.project.addressEn3 !== this.AddressEn3) return true;
      if (this.project.billPaymentSuffix !== this.suffix) return true;

      //ข้อมูลบุคคล
      if (this.project.projectManagerNameTh !== this.ProjectManagerNameTh)
        return true;
      if (this.project.projectManagerNameEn !== this.ProjectManagerNameEn)
        return true;
      if (this.project.projectManagerEmail !== this.ProjectManagerEmail)
        return true;
      if (this.project.debtFreeAuthEmail !== this.DebtFreeAuthEmail)
        return true;
      if (this.project.communityManagerNameTh !== this.CommunityManagerNameTh)
        return true;
      if (this.project.communityManagerNameEn !== this.CommunityManagerNameEn)
        return true;
      if (this.project.accountantNameTh !== this.AccountantNameTh) return true;
      if (this.project.accountantNameEn !== this.AccountantNameEn) return true;
      if (this.project.accManagerName !== this.AccManagerName) return true;
      if (this.project.communityManageBy !== this.CommunityManageBy)
        return true;

      //ข้อมูลการจดทะเบียน
      if (this.project.registeredNo !== this.RegisteredNo) return true;
      if (
        this.formatDateToISOString(
          new Date(this.project.juristicRegisteredDate)
        ) !== this.formatDateToISOString(this.JuristicRegisteredDate)
      )
        return true;
      if (this.project.totalOwnerRatio !== this.TotalOwnerRatio) return true;
      if (this.project.totalArea !== this.TotalArea) return true;
      if (this.project.totalUnit !== this.TotalUnit) return true;
      if (this.project.totalShop !== this.TotalShop) return true;
      if (this.project.totalCarPark !== this.TotalCarPark) return true;
      if (this.project.firstFundAmount !== this.FirstFundAmount) return true;
      if (this.project.fundAmountLeft !== this.FundAmountLeft) return true;
      if (
        this.formatDateToISOString(new Date(this.project.financeDateStart)) !==
        this.formatDateToISOString(this.FinanceDateStart)
      )
        return true;
      if (
        this.formatDateToISOString(new Date(this.project.financeDateEnd)) !==
        this.formatDateToISOString(this.FinanceDateEnd)
      )
        return true;
      if (
        this.formatDateToISOString(new Date(this.project.budgetDateStart)) !==
        this.formatDateToISOString(this.BudgetDateStart)
      )
        return true;
      if (
        this.formatDateToISOString(new Date(this.project.budgetDateEnd)) !==
        this.formatDateToISOString(this.BudgetDateEnd)
      )
        return true;

      if (this.project.firstmileProject) {
        if (this.project.firstmileProject.contactName !== this.ContactName)
          return true;
        if (this.project.firstmileProject.telephone !== this.Telephone)
          return true;
        if (this.project.firstmileProject.email !== this.Email) return true;
        if (this.project.firstmileProject.address !== this.Address) return true;
        if (
          this.project.firstmileProject.postAddress.id !==
          this.FlashExpressAddress.PostAddressId
        )
          return true;
      }

      return false;
    },
    confirm() {
      this.showConfirmDialog = false;
      if (this.showConfirmDialogName === "goBack") {
        this.$router.push({name: "master-project"});
      } else if (this.showConfirmDialogName === "confirmSave") {
        this.saveData();
      }
    },
    close() {
      this.showConfirmDialog = false;
    },
    updateCode(data) {
      // console.log("updateCode=",data);
      this.Code = data;
      this.isProjectCodeError = false;
    },
    updateNameTh(data) {
      this.NameTh = data;
      this.isNameThError = false;
    },
    updateNameEn(data) {
      this.NameEn = data;
    },
    async GetProject() {
      try {
        this.setLoading(true);
        await this.$store.dispatch(
          "masterProject/GetProjectById",
          this.selectedProject.id
        );
      } catch (error) {
        this.openDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    // ProjectTypeDataReady() {
    //   this.isProjectTypeReady = true;
    // },
    async setData() {
      // console.log("project=", this.project);
      this.Code = this.project.code;
      // this.SelectedProjectType = this.project.type;
      this.NameTh = this.project.nameTh;
      this.NameEn = this.project.nameEn;
      this.NickName = this.project.nickName;
      this.ShortCode = this.project.shortCode;
      this.Tel = this.project.tel;
      this.HouseTel = this.project.houseTel;
      this.Fax = this.project.fax;
      this.TaxNo = this.project.taxId;
      this.AddressTh1 = this.project.addressTh1;
      this.AddressTh2 = this.project.addressTh2;
      this.AddressTh3 = this.project.addressTh3;
      this.AddressEn1 = this.project.addressEn1;
      this.AddressEn2 = this.project.addressEn2;
      this.AddressEn3 = this.project.addressEn3;
      this.suffix = this.project.billPaymentSuffix;

      // //ข้อมูลบุคคล
      this.ProjectManagerNameTh = this.project.projectManagerNameTh;
      this.ProjectManagerNameEn = this.project.projectManagerNameEn;
      this.ProjectManagerEmail = this.project.projectManagerEmail;
      this.DebtFreeAuthEmail = this.project.debtFreeAuthEmail;
      this.CommunityManagerNameTh = this.project.communityManagerNameTh;
      this.CommunityManagerNameEn = this.project.communityManagerNameEn;
      this.AccountantNameTh = this.project.accountantNameTh;
      this.AccountantNameEn = this.project.accountantNameEn;
      this.AccManagerName = this.project.accManagerName;
      this.CommunityManageBy = this.project.communityManageBy;

      // //ประชาสัมพันธ์ - โฆษณา
      // this.AdvertiseInvoiceTh = this.project.advertiseInvoiceTh;
      // this.AdvertiseInvoiceEn = this.project.advertiseInvoiceEn;
      // this.AdvertiseNoticeTh = this.project.advertiseNoticeTh;
      // this.AdvertiseNoticeEn = this.project.advertiseNoticeEn;
      // this.AdvertiseReceiptTh = this.project.advertiseReceiptTh;
      // this.AdvertiseReceiptEn = this.project.advertiseReceiptEn;
      // this.InvoiceFooter = this.project.invoiceFooter;
      // this.ReceiptFooter = this.project.receiptFooter;

      //ข้อมูลการจดทะเบียน
      this.RegisteredNo = this.project.registeredNo;
      if (this.project.juristicRegisteredDate && new Date(this.project.juristicRegisteredDate)!="Invalid Date") {
        this.JuristicRegisteredDate = new Date(this.project.juristicRegisteredDate);
      }
      // this.AverageWaterMeter = this.project.averageWaterMeter;
      this.TotalOwnerRatio = this.project.totalOwnerRatio;
      this.TotalArea = this.project.totalArea;
      this.TotalUnit = this.project.totalUnit;
      this.TotalShop = this.project.totalShop;
      this.TotalCarPark = this.project.totalCarPark;
      this.FirstFundAmount = this.project.firstFundAmount;
      this.FundAmountLeft = this.project.fundAmountLeft;
      this.FinanceDateStart = new Date(this.project.financeDateStart);
      this.FinanceDateEnd = new Date(this.project.financeDateEnd);
      this.BudgetDateStart = new Date(this.project.budgetDateStart);
      this.BudgetDateEnd = new Date(this.project.budgetDateEnd);

      if (this.project.firstmileProject) {
        this.ContactName = this.project.firstmileProject.contactName;
        this.Telephone = this.project.firstmileProject.telephone;
        this.Email = this.project.firstmileProject.email;
        this.Address = this.project.firstmileProject.address;
        this.PostAddressId = this.project.firstmileProject.postAddress.id;
        this.Province = this.project.firstmileProject.postAddress.provinceTH;
        this.District = this.project.firstmileProject.postAddress.cityTH;
        this.Subarea = this.project.firstmileProject.postAddress.districtTH;
        this.Postcode = this.project.firstmileProject.postAddress.zipcode;

        this.FlashExpressAddress.ProjectId = this.selectedProject.id;
        this.FlashExpressAddress.ContactName =
          this.project.firstmileProject.contactName;
        this.FlashExpressAddress.Telephone =
          this.project.firstmileProject.telephone;
        this.FlashExpressAddress.Email = this.project.firstmileProject.email;

        this.FlashExpressAddress.Address =
          this.project.firstmileProject.address;
        this.FlashExpressAddress.PostAddressId =
          this.project.firstmileProject.postAddress.id;
      } else{
       
       this.FlashExpressAddress  =   {
           ProjectId: this.selectedProject.id ,
           PostAddressId: null,
           ContactName: "",
           Telephone: "",
           Email: "",
           Address: "",
       }
     }

      let Type = this.lang.projectTypes.find(
        (data) => data.code === this.project.type
      );

      if (Type) this.SelectedProjectType = Type.code;
      else this.SelectedProjectType = null;
      // this.selectedProjectType = this.projectTypes.find(
      //   (data) => data.id === this.project.typeId
      // );
      this.setLoading(false);
    },
    editFlashAddress() {
      const today = new Date();
      this.openFrom = today.getSeconds();
      this.showFlashAddress = true;
    },
    selectedFlashAddress(data) {
      // console.log("selectedFlashAddress=", data);
      this.FlashExpressAddress.PostAddressId = data.id;
      this.Province = data.provinceTH;
      this.District = data.cityTH;
      this.Subarea = data.districtTH;
      this.Postcode = data.zipcode;
    },
    checkUpdateFlashAddress() {
      if (this.FlashExpressAddress.ContactName !== this.ContactName)
        this.isUpdateLosa = true;
      if (this.FlashExpressAddress.Telephone !== this.Telephone)
        this.isUpdateLosa = true;
      if (this.FlashExpressAddress.Email !== this.Email)
        this.isUpdateLosa = true;
      if (this.FlashExpressAddress.Address !== this.Address)
        this.isUpdateLosa = true;
      if (this.FlashExpressAddress.PostAddressId !== this.PostAddressId)
        this.isUpdateLosa = true;
    },
    async confirmSave() {
      this.showConfirmDialogName = "confirmSave";
      this.openDialog({
        severity: "",
        title: this.lang.confirmEditSave,
        content: `${this.lang.askForEditDocument.replace(":title",this.lang.editProject)}`,
        isConfirm: true,
      });
    },
    async saveData() {
      try {
        this.setLoading(true);
        if (this.validateData()) {
          this.setLoading(false);
          return;
        }
        this.showConfirmDialogName === "save"
        this.checkUpdateFlashAddress();
        if (this.isUpdateLosa) {
          this.FlashExpressAddress.ContactName = this.ContactName;
          this.FlashExpressAddress.Telephone = this.Telephone;
          this.FlashExpressAddress.Email = this.Email;
          this.FlashExpressAddress.Address = this.Address;
        }

        let payload = {
          Id: this.selectedProject.id,
          Code: this.Code.trim(),
          Type: this.SelectedProjectType,
          NameTh: this.NameTh,
          NameEn: this.NameEn,
          NickName: this.NickName,
          ShortCode: this.ShortCode,
          Tel: this.Tel,
          HouseTel: this.HouseTel,
          Fax: this.Fax,
          TaxId: this.taxNoReplace,
          BillPaymentSuffix: this.suffix,

          AddressTh1: this.AddressTh1,
          AddressTh2: this.AddressTh2,
          AddressTh3: this.AddressTh3,
          AddressEn1: this.AddressEn1,
          AddressEn2: this.AddressEn2,
          AddressEn3: this.AddressEn3,

          ProjectManagerNameTh: this.ProjectManagerNameTh,
          ProjectManagerNameEn: this.ProjectManagerNameEn,
          ProjectManagerEmail: this.ProjectManagerEmail,
          DebtFreeAuthEmail: this.DebtFreeAuthEmail,
          CommunityManagerNameTh: this.CommunityManagerNameTh,
          CommunityManagerNameEn: this.CommunityManagerNameEn,
          AccountantNameTh: this.AccountantNameTh,
          AccountantNameEn: this.AccountantNameEn,
          AccManagerName: this.AccManagerName,
          CommunityManageBy: this.CommunityManageBy,

          // AdvertiseInvoiceTh: this.AdvertiseInvoiceTh,
          // AdvertiseInvoiceEn: this.AdvertiseInvoiceEn,
          // AdvertiseNoticeTh: this.AdvertiseNoticeTh,
          // AdvertiseNoticeEn: this.AdvertiseNoticeEn,
          // AdvertiseReceiptTh: this.AdvertiseReceiptTh,
          // AdvertiseReceiptEn: this.AdvertiseReceiptEn,
          // InvoiceFooter: this.InvoiceFooter,
          // ReceiptFooter: this.ReceiptFooter,

          RegisteredNo: this.RegisteredNo,
          JuristicRegisteredDate:
            this.JuristicRegisteredDate == null
              ? null
              : this.formatDateToISOString(this.JuristicRegisteredDate),
          // AverageWaterMeter: this.AverageWaterMeter,
          TotalOwnerRatio: this.TotalOwnerRatio,
          TotalArea: this.TotalArea,
          TotalUnit: this.TotalUnit,
          TotalShop: this.TotalShop,
          TotalCarPark: this.TotalCarPark,
          FirstFundAmount: this.FirstFundAmount,
          FundAmountLeft: this.FundAmountLeft,
          FinanceDateStart:
            this.FinanceDateStart == null
              ? null
              : this.formatDateToISOString(this.FinanceDateStart),
          FinanceDateEnd:
            this.FinanceDateEnd == null
              ? null
              : this.formatDateToISOString(this.FinanceDateEnd),
          BudgetDateStart:
            this.BudgetDateStart == null
              ? null
              : this.formatDateToISOString(this.BudgetDateStart),
          BudgetDateEnd:
            this.BudgetDateEnd == null
              ? null
              : this.formatDateToISOString(this.BudgetDateEnd),
          IsUpdateLosa: this.isUpdateLosa,
          FlashExpressAddress: this.FlashExpressAddress,
        };

        //console.log("payload=", payload);
        await this.$store.dispatch("masterProject/UpdateProjectById", payload);
        this.setLoading(false);

        this.showConfirmDialogName = "saveEditSuccess";
        this.openDialog({
          severity: "success",
          title: this.lang.saveEditSuccess,
          content: "",
          isConfirm: true,
        });
      } catch (error) {
        this.showConfirmDialogName = "";
        this.openDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.setLoading(false);
      }
    },
    validateData() {
      if (!this.isRoleUpdate) {
        this.showConfirmDialogName = "";
        this.openDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateRoleUpdate,
          isConfirm: false,
        });
        return true;
      }

      let returnStatus = false;
      if (!this.Code) {
        this.isProjectCodeError = true;
        returnStatus = true;
      }

      if (!this.SelectedProjectType) {
        this.isProjectTypeError = true;
        returnStatus = true;
      }

      if (!this.NameTh) {
        this.isNameThError = true;
        returnStatus = true;
      }

      if (!this.validNationalID(this.taxNoReplace) && this.taxNoReplace != "0000000000000") {
        this.isTaxNoError = true;
        returnStatus = true;
      }

      if (this.Tel) {
        const keysAllowed = [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "+",
          "(",
          ")",
          "-",
        ];
        for (let i = 0; i < this.Tel.length; i++) {
          if (!keysAllowed.includes(this.Tel[i])) {
            this.isProjectTelError = true;
            break;
          }
        }

        if (this.isProjectTelError) {
          returnStatus = true;
        }
      }

      if (!this.AddressTh1) {
        this.isAddressError = true;
        returnStatus = true;
      }

      if (!this.AddressTh2) {
        this.isAddress2Error = true;
        returnStatus = true;
      }

      if (!this.AddressTh3) {
        this.isAddress3Error = true;
        returnStatus = true;
      }

      if (this.Email) {
        if (this.validateEmail(this.Email)) {
          this.isEmailError = true;
          returnStatus = true;
        } else {
          this.isEmailError = false;
        }
      }

      if (this.ProjectManagerEmail) {
        if (this.validateEmail(this.ProjectManagerEmail)) {
          this.isProjectManagerEmailError = true;
          returnStatus = true;
        } else {
          this.isProjectManagerEmailError = false;
        }
      }

      if (this.DebtFreeAuthEmail) {
        if (this.validateEmail(this.DebtFreeAuthEmail)) {
          this.isDebtFreeAuthEmailError = true;
          returnStatus = true;
        } else {
          this.isDebtFreeAuthEmailError = false;
        }
      }

      // if(this.suffix == "" || this.suffix.length != 2){
      //   this.isSuffixError = true;
      //   return true;
      // }

      // if (!this.AverageWaterMeter) {
      //   this.isAverageWaterMeterError = true;
      //   return true;
      // }
      if (returnStatus) {
        this.openDialog({
          severity: "error",
          title: this.lang.validateSaveTitle,
          content: this.wordingValidate(),
          isConfirm: false,
        });
      }

      return returnStatus;
    },
    wordingValidate(){
      if (!this.Code) {
        return this.lang.validateFieldNoValue + this.lang.projectCode;
      }

      if (!this.SelectedProjectType) {
        return this.lang.validateDropDownNoSelect + this.lang.projectType;
      }

      if (!this.NameTh) {
        return this.lang.validateFieldNoValue + this.lang.projectNameTh;
      }

      if (this.isProjectTelError) {
        return this.lang.validateTel;
      }

      if (!this.TaxNo) {
        return this.lang.validateFieldNoValue + this.lang.projectTaxId;
      }

      if (!this.AddressTh1) {
        return this.lang.validateFieldNoValue + this.lang.address1;
      }

      if (!this.AddressTh2) {
        return this.lang.validateFieldNoValue + this.lang.address2;
      }

      if (!this.AddressTh3) {
        return this.lang.validateFieldNoValue + this.lang.address3;
      }

      if (this.isEmailError) {
        return [this.lang.validateEmailError, `(${this.lang.tabAddressParcel})`];
      }
      if (this.isProjectManagerEmailError) {
        return [this.lang.validateEmailError, `(${this.lang.projectManagerEmail})`];
      }
      if (this.DebtFreeAuthEmail) {
        return [this.lang.validateEmailError, `(${this.lang.debtFreeAuthEmail})`];
      }
    },
    validateEmail(data) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(data)) {
        return false;
      } else {
        return true;
      }
    },
    validNationalID(id) {
      if (id.length != 13) return false;
      let i = 0;
      let sum = 0;
      for (i = 0, sum = 0; i < 12; i++) {
        sum += parseInt(id.charAt(i)) * (13 - i);
      }
      let mod = sum % 11;
      let check = (11 - mod) % 10;
      if (check == parseInt(id.charAt(12))) {
        return true;
      }
      return false;
    },
    checkTaxNo() {
      let tax = this.taxNoReplace;
      // console.log("tax=",tax);
      if (tax) {
        if (tax.length == 13) {
          if (!this.validNationalID(tax)) {
            this.isTaxNoError = true;
          } else {
            this.isTaxNoError = false;
          }
        } else {
          this.isTaxNoError = true;
        }

        if (tax === "0000000000000") {
          this.isTaxNoError = false;
        }
      }
    },
    changeProjectType() {
      this.isProjectTypeError = false;
    },
    keyupTel() {
      const keysAllowed = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "+",
        "(",
        ")",
        "-",
      ];
      for (let i = 0; i < this.Tel.length; i++) {
        if (!keysAllowed.includes(this.Tel[i])) {
          this.isProjectTelError = true;
          break;
        } else {
          this.isProjectTelError = false;
        }
      }
    },
    keyupAddressTh() {
      this.isAddressError = false;
    },
    keyupAddress2Th() {
      this.isAddress2Error = false;
    },
    keyupAddress3Th() {
      this.isAddress3Error = false;
    },
    // keyupAverageWaterMeter(){
    //   this.isAverageWaterMeterError = false;
    // },

    blurEmail() {
      if (this.Email && this.validateEmail(this.Email)) {
        this.isEmailError = true;
      } else {
        this.isEmailError = false;
      }
    },
    blurProjectManagerEmail() {
      if (
        this.ProjectManagerEmail &&
        this.validateEmail(this.ProjectManagerEmail)
      ) {
        this.isProjectManagerEmailError = true;
      } else {
        this.isProjectManagerEmailError = false;
      }
    },
    blurDebtFreeAuthEmail() {
      if (
        this.DebtFreeAuthEmail &&
        this.validateEmail(this.DebtFreeAuthEmail)
      ) {
        this.isDebtFreeAuthEmailError = true;
      } else {
        this.isDebtFreeAuthEmailError = false;
      }
    },
    onlyNumber($event) {
      this.isSuffixError = false;
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
          $event.preventDefault();
      }
    },
    openDialog(dataConfig) {
      if (dataConfig.severity === "") {
        this.classTitleErrorDialog = "";
      } else if (dataConfig.severity === "success") {
        this.classTitleErrorDialog = "p-text-success";
      } else if (dataConfig.severity === "error") {
        this.classTitleErrorDialog = "p-error";
      }

      
      this.dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
      if (this.showConfirmDialogName == "saveEditSuccess") {
        this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close-saved",
          },
        ];
      }
      else if (dataConfig.isConfirm) {
        this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "confirm",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "confirm",
          },
        ];
      } 

      this.titleConfirmDialog = dataConfig.title;
      this.wordingDialog = dataConfig.content;
      this.showConfirmDialog = true;
    },
    closeAfterSave() {
      this.showConfirmDialog = false;
      this.$router.push({ name: "master-project" });
    },
    async checkShouldSetData() {
      if (this.isSharedRoleByMenuReady && this.project) {
        await this.setData();
      }
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    project() {
      return this.$store.getters["masterProject/project"];
    },
    // projectTypes() {
    //   return this.$store.getters["masterProject/projectTypes"];
    // },
    taxNoReplace(){
      var TaxNo = this.TaxNo.replaceAll("-", "");
      return TaxNo;
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmCOM");
    },
    isRoleUpdate() {
      if (this.roleByMenu && this.roleByMenu.update == "Y") {
        return true;
      }
      return false;
    },
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.checkShouldSetData();
    },
  },
};
</script>

<style scoped>
.mt24 {
  margin-top: 24px;
}

.bordercard {
  /* background: #FFFFFF; */
  box-shadow: 0px 4px 10px rgb(132 132 132 / 10%);
  border-radius: 12px;
}
</style>
