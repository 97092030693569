export default {
	toolbarTitle: "Request List",
	title: "File list",
	subTitle: "Requested items are no longer than 14 days from the date of request.",

	columnItemNo: "No.",
	columnRequestName: "Request name",
	columnRequestDate: "Request date",
	columnRequestUser: "Creator",
	columnRequestStatus: "Status",
	columnRequestQueue: "Your Queue",

	tagQueuing: "Queuing",
	tagProcessing: "Processing",
	tagSuccess: "Success",
	tagError: "Error",

	preview: "preview",
	download: "download",
	retryRequest: "Retry request",
	tagRequestQueue: "Your queue : :queue",
	tagWithQueue: "Wait for :queue queue",
	errorRowExceed: "Row limit exceed :row rows",
	cancelQueue: "Cancel queue",
	tagCancelQueue: "Canceling",
	dialogContentCancelQueue: "Do you want to cancel the queue for item {0}?",
	dialogContentCancelSuccessful: "You have successfully canceled the queue.",
};
