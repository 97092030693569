<template>
  <base-toolbar
    :title="lang.titleRevert"
    :dataButtons="dataButtons"
    :hideBackButton="isHideBack"
    @goBack="goBack"
    @gotoSave="gotoSave()"
  ></base-toolbar>
  <base-progress-bar
    v-model:visible="showProgressBar"
    :title="lang.titleRevert"
    :textProgressBar="lang.generating"
    :valueObj="valueObj"
    :progressBar="`Template`"
  ></base-progress-bar>
  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="lang.notificationDialogHeader"
      :classTitle="baseDialogClassTitle"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @close="showValidateDialog = false"
    ></base-dialog>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <div class="justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.titleRevert }}</label>
      </div>
      <PvDivider></PvDivider>
      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="voucherList"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          scrollable
        >
          <PvColumn
            field="itemNumber"
            :header="lang.itemNo"
            style="max-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="revertVoucherDate"
            :header="lang.columnDate"
            style="min-width: 7rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.revertVoucherDate) }}
            </template></PvColumn
          >
          <PvColumn
            field="code"
            :header="lang.columnCode"
            style="min-width: 7rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="description"
            :header="lang.description"
            style="min-width: 15rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
          </PvColumn>
          <PvColumn
            field="totalAmount"
            :header="lang.columnTotal"
            style="min-width: 7rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-right"
          >
            <template #body="slotProps">
              <span class="text-right w-full">
                {{ formatCurrency(slotProps.data.totalAmount) }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            field="importStatus"
            :header="lang.status"
            style="max-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <base-tag
                :Caption="setBaseTagStatusImport(slotProps.data.importStatus).caption"
                :Class="setBaseTagStatusImport(slotProps.data.importStatus).class"
              />
            </template>
          </PvColumn>
          <PvColumn
            field="importStatusReason"
            :header="lang.reason"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
          </PvColumn>
          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import useCalculation from "../../../hooks/calculation.js";
import { useStore } from "vuex";
import { computed, inject, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  props: ["glBook", "idBook", "id", "projectCode"],
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { sum } = useCalculation();
    const { lang } = useLang();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.accounting.edit,
          ...lang.th.gl.accounting.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.accounting.edit,
          ...lang.en.gl.accounting.listing,
        };
      }
    });
    const isHideBack = inject("isHideBack");

    const dataButtons = computed(() => {
      let result = [
        {
          id: "gotoSave",
          action: "gotoSave",
          type: "save",
        },
      ];
      return result;
    });

    const dataButtonDialog = computed(() => {
      let result = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        }
      ];
      return result;
    });

    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });

    const accountingEntry = computed(() => {
      return store.getters["glAccountingEntry/selectedAccountingEntry"];
    });

    const accountingFrequentlys = computed(() => {
      return store.getters["accountingFrequently/accountingFrequentlys"];
    });

    const accountingFrequently = computed(() => {
      return store.getters["accountingFrequently/selectedAccountingFrequently"];
    });

    let baseDialogClassTitle = ref("");
    let validateWording = ref("");
    let showValidateDialog = ref(false);

    let showProgressBar = ref(false);
    let valueObj = ref([]);

    let voucherList = ref([]);
    let isSetDataReady = ref(false);

    onMounted(async () => {
      setLoading(true);
      try {
        await getAccounting();
        await getAccountingFrequently();
        setData();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    });

    const setLoading = (bool) => {
      store.dispatch("setLoading", { value: bool });
    };

    const goBack = () => {
      router.replace({
        name: "gl-invoice-edit",
        params: {
          glBook: props.glBook,
          idBook: props.idBook,
          id: props.id,
        },
      });
    };

    const setBaseTagStatusImport = (importStatus) => {
      let result = {
        caption: "",
        class: "",
      };
      if (importStatus.toLowerCase() === "wait") {
        result.caption = mergeLang.value.wait;
        result.class = "warning";
      } else if (importStatus.toLowerCase() === "success") {
        result.caption = mergeLang.value.success;
        result.class = "success";
      } else if (importStatus.toLowerCase() === "invalid") {
        result.caption = mergeLang.value.invalid;
        result.class = "secondary";
      } else if (importStatus.toLowerCase() === "error") {
        result.caption = mergeLang.value.error;
        result.class = "error";
      }
      return result;
    };

    const initiateData = () => {
      voucherList.value = [];
      isSetDataReady.value = false;
      showProgressBar.value = false;
      valueObj.value = [];
    };

    const getAccounting = async () => {
      let where = {
        projectId: selectedProject.value.id,
        id: props.id,
      };
      await store.dispatch("glAccountingEntry/getAccountingEntry", {
        where,
      });
    };

    const getAccountingFrequently = async () => {
      let payload = {
        page: 1,
        limit: 10,
        projectId: selectedProject.value.id,
        accountBookId: props.idBook,
        accountCode: "revert",
        accountName: null,
      };
      await store.dispatch(
        "accountingFrequently/getAccountingFrequentlys",
        payload
      );
      if (accountingFrequentlys.value) {
        let accountingFrequentlyId = accountingFrequentlys.value[0].id;
        await store.dispatch(
          "accountingFrequently/getAccountingFrequentlyById",
          accountingFrequentlyId
        );
      }
    };

    // const sleep = async (ms) => {
    //   await new Promise((resolve) => setTimeout(resolve, ms));
    // };

    const setData = async () => {
      initiateData();
      if (
        accountingEntry.value.accountingEntryDetail &&
        accountingEntry.value.accountingEntryDetail.length > 0 &&
        accountingFrequently.value.accountingFrequentlyDetail &&
        accountingFrequently.value.accountingFrequentlyDetail.length > 0
      ) {
        let vd = accountingEntry.value.accountingEntryDetail;
        let af = accountingFrequently.value.accountingFrequentlyDetail;
        for (let c of vd) {
          let voucherDate = new Date(accountingEntry.value.date);
          if (!c.description) {
            console.error(`Description is null or undefined`);
            continue;
          }
          c.description = c.description.trim();
          if (!c.description.includes("/")) {
            console.error(`Invalid description format: ${c.description}`);
            continue;
          }
          let [month, year] = c.description.split("/");
          if (isNaN(month) || isNaN(year) || month.length !== 2 || isNaN(parseInt(month)) || year.length !== 4 || isNaN(parseInt(year))) {
            console.error(`Invalid month or year in description: ${c.description}`);
            continue;
          }
          month = parseInt(month);
          year = parseInt(year) - 543;
          if (month < 1 || month > 12) {
            console.error(`Invalid month in description: ${c.description}`);
            continue;
          }
          let periodToDate = new Date(year, month - 1);
          c = {
            ...c,
            revertVoucherDate: periodToDate,
            revertVoucherDescription: "กลับรายการแจ้งหนี้ " + c.description + " อ้างอิง " + accountingEntry.value.code.trim(),
            revertVoucherPeriod: c.description,
          };
          // console.log('account_frequently = ', af);
          if (
            c.description != null &&
            c.description.length == 7 &&
            c.description.indexOf("/") !== -1 &&
            c.creditAmount > 0 &&
            (
              periodToDate.getFullYear() > voucherDate.getFullYear() ||
              (periodToDate.getFullYear() === voucherDate.getFullYear() && periodToDate.getMonth() > voucherDate.getMonth())
            )
          ) {
            // console.log('voucher_detail = ', c);
            let revertVoucherIsPeriodDuplicateIndex = voucherList.value.findIndex(voucher => voucher.revertVoucherPeriod === c.revertVoucherPeriod);
            if (revertVoucherIsPeriodDuplicateIndex !== -1) {
              let details = voucherList.value[revertVoucherIsPeriodDuplicateIndex].details;
              let accountDr = af.find(dr => dr.accountId === c.accountId && dr.debitAmount > 0);
              let accountCr = null;
              if (accountDr) {
                accountCr = af.find(cr => cr.itemNumber === accountDr.itemNumber + 1 && cr.creditAmount > 0);
              }
              // console.log('update_dr = ', accountDr);
              // console.log('update_cr = ', accountCr);
              if (accountDr && accountCr) {
                let dr = {
                  accountId: accountDr.accountId,
                  description: c.revertVoucherPeriod,
                  sectionId: null,
                  jobId: null,
                  projectId: null,
                  debitAmount: c.creditAmount,
                  creditAmount: 0,
                  itemNumber: Math.max(...details.map(detail => detail.itemNumber)) + 1,
                };
                details.push(dr);
                let cr = {
                  accountId: accountCr.accountId,
                  description: c.revertVoucherPeriod,
                  sectionId: null,
                  jobId: null,
                  projectId: null,
                  debitAmount: 0,
                  creditAmount: c.creditAmount,
                  itemNumber: Math.max(...details.map(detail => detail.itemNumber)) + 1,
                };
                details.push(cr);
                voucherList.value[revertVoucherIsPeriodDuplicateIndex].totalAmount = sum([
                  voucherList.value[revertVoucherIsPeriodDuplicateIndex].totalAmount,
                  c.creditAmount
                ]);
              }
            } else {
              let details = [];
              let accountDr = af.find(dr => dr.accountId === c.accountId && dr.debitAmount > 0);
              let accountCr = null;
              if (accountDr) {
                accountCr = af.find(cr => cr.itemNumber === accountDr.itemNumber + 1 && cr.creditAmount > 0);
              }
              // console.log('insert_dr = ', accountDr);
              // console.log('insert_cr = ', accountCr);
              if (accountDr && accountCr) {
                let dr = {
                  accountId: accountDr.accountId,
                  description: c.revertVoucherPeriod,
                  sectionId: null,
                  jobId: null,
                  projectId: null,
                  debitAmount: c.creditAmount,
                  creditAmount: 0,
                  itemNumber: 1,
                };
                details.push(dr);
                let cr = {
                  accountId: accountCr.accountId,
                  description: c.revertVoucherPeriod,
                  sectionId: null,
                  jobId: null,
                  projectId: null,
                  debitAmount: 0,
                  creditAmount: c.creditAmount,
                  itemNumber: 2,
                };
                details.push(cr);
                let voucher = {
                  projectId: selectedProject.value.id,
                  generalLedgerBookId: props.idBook,
                  date: formatDateToISOString(c.revertVoucherDate),
                  code: "",
                  description: c.revertVoucherDescription,
                  type: "",
                  status: "A",
                  approveStatus: "",
                  approveName: null,
                  reasonNonApprove: "",
                  remark: null,
                  isInvoiceToGl: false,
                  isReceiptToGl: false,
                  isMeterToGl: false,
                  isPettyCashToGl: false,
                  isPurchaseInvoiceToGl: false,
                  isPurchaseReceiptToGl: false,
                  details: details,
                  refers: [],
                  typeInsert: "Add",
                  itemNumber: Math.max(...voucherList.value.map(c => c.itemNumber), 0) + 1,
                  revertVoucherDate: c.revertVoucherDate,
                  revertVoucherPeriod: c.revertVoucherPeriod,
                  totalAmount: c.creditAmount,
                  importStatus: "wait",
                  importStatusReason: "",
                  markStatus: "",
                };
                voucherList.value.push(voucher);
              }
            }
          }
        }
      }
      isSetDataReady.value = true;
    };

    const gotoSave = async () => {
      if (!isSetDataReady.value) {
        validateWording.value = mergeLang.value.validateRevertAccountingIsNotReady;
        showValidateDialog.value = true;
        return;
      }
      if (voucherList.value.length == 0) {
        validateWording.value = mergeLang.value.validateRevertAccountingNoDetail;
        showValidateDialog.value = true;
        return;
      }
      save();
    };

    const save = async () => {
      valueObj.value = [];
      valueObj.value = voucherList.value;
      showProgressBar.value = true;
      for (let c of voucherList.value) {
        await addVoucher(c);
      }
      showProgressBar.value = false;
    };

    const addVoucher = async (data) => {
      try {
        if (data.importStatus != "success") {
          await store.dispatch("glAccountingEntry/createOrUpdateAccountingEntry",data);
          if (accountingEntry.value) {
            data.code = accountingEntry.value.code;
            data.markStatus = "1";
            data.importStatus = "success";
          }
        }
        return true;
      } catch (error) {
        data.markStatus = "1";
        data.importStatus = "error";
        data.importStatusReason = error;
        return false;
      }
    };
    
    return {
      textTableClass,
      textFormClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      lang: mergeLang,
      isHideBack,

      showProgressBar,
      valueObj,

      dataButtons,
      showValidateDialog,
      baseDialogClassTitle,
      validateWording,
      dataButtonDialog,
      voucherList,

      gotoSave,
      goBack,
      setBaseTagStatusImport,
    };
  },
};
</script>