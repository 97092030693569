<template>
  <base-toolbar
    :title="lang.titlePenaltyCalculation"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoSave="confirmSave"
    :hideBackButton="isHideBack"
  >
  </base-toolbar>

  <base-dialog
    v-model:visible="showConfirmDialog"
    :titleConfirm="titleConfirmDialog"
    :wordingConfirm="wordingDialog"
    @confirm="confirm"
    @close="close"
  ></base-dialog>

  <base-container>
    <div class="surface-card shadow-2 border-round mt-5">
      <div class="font-lg pl-4 pt-4">
        <label>{{ this.lang.titlePenaltyCalculation }}</label>
      </div>

      <base-card
        :titleHeader="this.lang.titleFineRateCalculation"
        :isVisibleExpandButton="false"
        :isHideBackground="true"
        :isVisibleCard="true"
      >
        <section class="flex flex-wrap">
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label>{{ lang.penaltyRateBefore181Day }}</label>
            <PvInputNumber
              class="inputfield w-full mt-1"
              v-model="PenaltyRateBefore181Day"
              :placeholder="lang.validatePenaltyRateBefore181Day"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label>{{ lang.penaltyRateAfter180Day }}</label>
            <PvInputNumber
              class="inputfield w-full mt-1"
              v-model="PenaltyRateAfter180Day"
              :placeholder="lang.validatePenaltyRateAfter180Day"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label>{{ lang.penaltyDigit }}</label>
            <PvDropdown
              class="inputfield w-full mt-1"
              :options="PenaltyDigit"
              optionLabel="name"
              optionValue="code"
              v-model="selectedPenaltyDigit"
              :placeholder="lang.validatePenaltyDigit"
            />
            <!-- <PvInputText
              type="text"
              class="inputfield w-full mt-1"
              v-model="PenaltyDigit"
              :placeholder="lang.validatePenaltyDigit"
            /> -->
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label
              >{{ lang.roundType }} &nbsp;
              <i
                style="cursor: pointer"
                class="pi pi-exclamation-circle"
                v-tooltip.top="{
                  value: lang.tooltipRoundType,
                  class: 'custom-tooltip',
                }"
              ></i>
            </label>
            <PvDropdown
              class="inputfield w-full mt-1"
              :options="RoundType"
              optionLabel="name"
              optionValue="code"
              v-model="selectedRoundType"
              :placeholder="lang.validateRoundType"
            />
            <!-- <PvInputText
              type="text"
              class="inputfield w-full mt-1"
              v-model="RoundType"
              :placeholder="lang.validateRoundType"
            /> -->
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label
              >{{ lang.penaltyType }} &nbsp;
              <i
                style="cursor: pointer"
                class="pi pi-exclamation-circle"
                v-tooltip.top="{
                  value: lang.tooltipPenaltyType,
                  class: 'custom-tooltip',
                }"
              ></i>
            </label>
            <PvDropdown
              class="inputfield w-full mt-1"
              :options="PenaltyType"
              optionLabel="name"
              optionValue="code"
              v-model="selectedPenaltyType"
              :placeholder="lang.validatePenaltyType"
            />
            <!-- <PvInputText
              type="text"
              class="inputfield w-full mt-1"
              v-model="PenaltyType"
              :placeholder="lang.validatePenaltyType"
            /> -->
          </div>

          <div class="col-12 pl-0 pr-2 pt-3">
            <icon-warning iconColor="secondary"/>
            <label class="ml-2">{{ lang.notPenalty }}</label>
          </div>
        </section>
      </base-card>

      <base-card
        :titleHeader="this.lang.titleInvoiceDisplayOutstanding"
        :isVisibleExpandButton="false"
        :isHideBackground="true"
        :isVisibleCard="true"
      >
        <section class="flex flex-wrap">
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label>{{ lang.labelInvoiceDisplayOutstanding }}</label>
            <PvDropdown
              class="inputfield w-full mt-1"
              :options="invoiceDisplayOutstanding"
              optionLabel="name"
              optionValue="code"
              v-model="selectedInvoiceDisplayOutstanding"
            />
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
            <label>{{ lang.penaltyPrintInvoice }}</label>
            <PvDropdown
              class="inputfield w-full mt-1"
              :options="penaltyPrintInvoice"
              optionLabel="name"
              optionValue="code"
              v-model="selectedPenaltyPrintInvoice"
            />
          </div>
        </section>
      </base-card>

    </div>
  </base-container>
</template>

<script>
import { computed, inject } from "vue";
import { useStore } from "vuex";
import useLang from "../../../hooks/lang.js";
export default {
  setup() {
    const { lang } = useLang();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.product,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.product,
        };
      }
    });

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,
      isHideBack,
    };
  },
  data() {
    return {
      dataButtons: [
        {
          id: "saveCaption",
          action: "gotoSave",
          type: "save",
        },
      ],

      showConfirmDialog: false,
      wordingDialog: "",
      showConfirmDialogName: "",
      titleConfirmDialog: "",

      PenaltyRateBefore181Day: 0,
      PenaltyRateAfter180Day: 0,
      PenaltyDigit: [
        { name: "0", code: "0" },
        { name: "1", code: "1" },
        { name: "2", code: "2" },
      ],
      selectedPenaltyDigit: "",
      RoundType: [
        { name: "มาตรฐาน (มากกว่า 5 ปัดขึ้น)", code: "N" },
        { name: "ปัดขึ้น", code: "U" },
        { name: "ปัดลง", code: "L" },
      ],
      selectedRoundType: "",
      PenaltyType: [
        { name: "เลือกวันที่สิ้นสุดคำนวณค่าปรับเอง", code: "Y" },
        { name: "คำนวณถึงวันสิ้นเดือนเสมอ", code: "E" },
        { name: "ปกติ", code: "N" },
      ],
      selectedPenaltyType: "",
      selectedIsPenalty: true,
      penaltyPrintInvoice: [
        { name: "ไม่แสดง", code: "N" },
        { name: "แสดง", code: "Y" },
      ],
      selectedPenaltyPrintInvoice: "N",
      invoiceDisplayOutstanding: [
        { name: "ไม่แสดง", code: "none" },
        { name: "แสดงแบบย่อ", code: "summary" },
        { name: "แสดงแบบแจกแจง", code: "detail" },
      ],
      selectedInvoiceDisplayOutstanding: "detail",
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.GetProject();
    // console.log("products=", this.products);
    if (this.project) {
      this.setData();
    }
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    async GetProject() {
      try {
        await this.$store.dispatch(
          "masterProject/GetProjectById",
          this.selectedProject.id
        );
      } catch (error) {
        this.$toast.add({
          severity: "error", // severity:'success',severity:'info',severity:'warn',severity:'error'
          summary: this.lang.notificationDialogHeader, //TitleHeader
          detail: error,
          life: 5000, //TimeClose
        });
      }
    },
    setData() {
      this.PenaltyRateBefore181Day = parseInt(
        this.project.penaltyRateBefore181Day.trim()
      );
      this.PenaltyRateAfter180Day = parseInt(
        this.project.penaltyRateAfter180Day.trim()
      );
      this.selectedPenaltyDigit = this.project.penaltyDigit.trim();
      this.selectedRoundType = this.project.roundType.trim();
      this.selectedPenaltyType = this.project.penaltyType.trim();
      this.selectedPenaltyPrintInvoice = this.project.isVisiblePenaltyPrintInvoice ? this.project.isVisiblePenaltyPrintInvoice : "N";
      this.selectedInvoiceDisplayOutstanding = this.project.invoiceDisplayOutstanding ? this.project.invoiceDisplayOutstanding : "detail";

      this.setLoading(false);
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    confirmSave() {
      this.titleConfirmDialog = this.lang.confirmEditSave;
      this.wordingDialog = `${this.lang.askForEditDocument.replace(
        ":title",
        this.lang.titleFineRateCalculation
      )}`;
      this.showConfirmDialog = true;
    },
    confirm() {
      this.showConfirmDialog = false;    
        this.saveData();      
    },
    close() {
      this.showConfirmDialog = false;
    },
    async saveData() {
      try {
        
        this.setLoading(true);
        // if (this.validateData()) {
        //   return;
        // }

        let payload = {
          ProjectId: this.selectedProject.id,
          PenaltyRateBefore181Day: this.PenaltyRateBefore181Day.toString(),
          PenaltyRateAfter180Day: this.PenaltyRateAfter180Day.toString(),
          PenaltyDigit: this.selectedPenaltyDigit,
          RoundType: this.selectedRoundType,
          PenaltyType: this.selectedPenaltyType,
          IsVisiblePenaltyPrintInvoice: this.selectedPenaltyPrintInvoice,
          InvoiceDisplayOutstanding: this.selectedInvoiceDisplayOutstanding,
        };

        //console.log("payload=", payload);
        await this.$store.dispatch(
          "masterProject/UpdatePenaltyCalculation",
          payload
        );

        this.$toast.add({
          severity: "success", // severity:'success',severity:'info',severity:'warn',severity:'error'
          summary: this.lang.notificationDialogHeader, //TitleHeader
          detail: this.lang.saveEditSuccess,
          life: 5000, //TimeClose
        });
      } catch (error) {
        this.$toast.add({
          severity: "error", // severity:'success',severity:'info',severity:'warn',severity:'error'
          summary: this.lang.notificationDialogHeader, //TitleHeader
          detail: error,
          life: 5000, //TimeClose
        });
      }
      
      this.setLoading(false);
    },
    validateData() {
      return false;
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    project() {
      return this.$store.getters["masterProject/project"];
    },
  },
};
</script>

<style></style>
