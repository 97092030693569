<template>
  <div class="flex">
    <div class="bg-primary image-section center-screen">
      <PvSkeleton
        v-show="advertisementLoading"
        width="461px"
        height="369px"
      ></PvSkeleton>
      <PvCard v-show="!advertisementLoading">
        <template #header>
          <div style="padding: 16px">
            <img
              :src="imageUrl"
              class="image-advertisement cursor-pointer"
              @click="gotoAdvertisement()"
            />
          </div>
        </template>
        <template #content>
          <div class="text-left font-md">{{ this.description }}</div>
        </template>
        <template #footer>
          <div class="text-right">
            <span
              class="cursor-pointer text-primary font-md"
              @click="gotoAdvertisement()"
              v-if="linkUrl"
            >
              <u>{{ lang.moreDescription }}</u>
            </span>
          </div>
        </template>
      </PvCard>
    </div>
    <div class="login-section center-screen">
      <!-- <base-dialog
        v-model:visible="isError"
        :titleConfirm="lang.errorTitle"
        :wordingConfirm="errorMessage"
        :dataButtons="dataButtonDialog"
        @close="isError = false"
      ></base-dialog> -->
      <div>
        <div class="p-4 size-login">
          <div class="text-center mb-5">
            <img
              src="@/assets/images/logo.svg"
              alt="Image"
              width="182"
              class="mb-3"
            />
            <div class="p-text-primary font-bold mb-3 font-3xl">
              {{ lang.signIn }}
            </div>
          </div>

          <div class="text-left" :class="textFormClass">
            <label for="username" class="block font-normal mb-2 font-md">
              {{ lang.username }}
            </label>
            <PvInputText
              id="username"
              type="text"
              class="w-full mb-3 p-inputtext-lg"
              :class="{ 'p-invalid': isError }"
              v-model="username"
              @keyup="keyPressEvent"
            />

            <label for="password" class="block font-normal mb-2 font-md">
              {{ lang.password }}
            </label>
            <span class="p-input-icon-right w-full mb-6">
              <PvInputText
                id="password"
                :type="isShowPassword ? 'text' : 'password'"
                class="w-full p-inputtext-lg"
                :class="{ 'p-invalid': isError }"
                v-model="password"
                @keyup="keyPressEvent"
              />
              <i
                class="pi"
                :class="isShowPassword ? 'pi-eye-slash' : 'pi-eye'"
                @click="showHidePassword()"
              />
            </span>
            <div v-if="isError" id="is-login-error" class="p-error mb-5">
              {{ lang.errorLogin }}
            </div>

            <PvButton
              :label="lang.signIn"
              class="w-full p-button-lg font-normal mb-6"
              @click="login"
              :loading="isLoading"
            ></PvButton>

            <div class="w-full text-center">
              <span class="text-primary font-md">
                <div>
                  <u class="cursor-pointer" @click="gotoContact()">
                    {{ lang.labelContact1 }}
                  </u>
                </div>
                <div class="mt-2">
                  <u class="cursor-pointer" @click="gotoContact()">
                    {{ lang.labelContact2 }}
                  </u>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useTracking from "../../hooks/tracking";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  setup() {
    const store = useStore();
    const { lang } = useLang();
    const { textHeaderClass, textFormClass } = useTextStyle();

    let mergeLang = null;
    if (store.getters.selectedLanguage === "TH") {
      mergeLang = { ...lang.th.base, ...lang.th.auth.loginView };
    } else {
      mergeLang = { ...lang.en.base, ...lang.en.auth.loginView };
    }
    const userData = computed(() => {
      return {
        id: store.getters.userId,
        code: store.getters.userCode,
        name: store.getters.userName,
      };
    });
    // const mergeLang = { ...lang.value.base, ...lang.value.auth.loginView };
    const { trackingSetUser } = useTracking();
    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      trackingSetUser,
      userData,
    };
  },
  data() {
    return {
      username: "",
      password: "",
      isError: false,
      errorMessage: null,
      isLoading: false,
      redirectPath: "",
      dataButtonDialog: [
        {
          id: "close",
          caption: this.lang.close,
          class: "w-full p-button-outlined p-button-secondary",
          action: "close",
        },
      ],
      isShowPassword: false,
      description: "",
      imageUrl: "",
      linkUrl: "",
      advertisementLoading: true,
    };
  },
  created() {
    if (this.$route.query.redirect) {
      this.redirectPath = this.$route.query.redirect;
    }
  },
  mounted() {
    this.getAdvertisement();
  },
  methods: {
    async login() {
      this.isLoading = true;
      try {
        let payload = {
          username: this.username,
          password: this.password,
        };
        await this.$store.dispatch("login", payload);
        await this.$store.dispatch("loginLosa", payload);
        this.trackingSetUser(this.userData);
        if (this.redirectPath) {
          this.$router.push(this.redirectPath);
        } else {
          this.$router.push({ name: "select-project" });
        }
        await this.$store.dispatch("shared/resetProject");
      } catch (error) {
        this.errorMessage = error.message || this.lang.baseError;
        this.isError = true;
      }
      this.isLoading = false;
    },
    async keyPressEvent(event) {
      if (event.keyCode === 13) {
        await this.login();
      } else {
        this.isError = false;
      }
    },
    showHidePassword() {
      this.isShowPassword = !this.isShowPassword;
    },
    gotoContact() {
      let route = this.$router.resolve({ path: "/contact-us" });
      window.open(route.href);
    },
    gotoAdvertisement() {
      if (this.linkUrl) {
        window.open(this.linkUrl, "_blank");
      }
    },
    async getAdvertisement() {
      this.advertisementLoading = true;
      try {
        await this.$store.dispatch("advertisement/getFirstAdvertisement", true);

        this.description = this.firstAdvertisement.description;
        if (this.description.length > 150) {
          this.description = this.description.substring(1, 150);
          this.description += "...";
        }
        this.imageUrl = this.firstAdvertisement.imgUrl;
        this.linkUrl = this.firstAdvertisement.linkUrl;
        this.advertisementLoading = false;
      } catch (error) {
        this.errorMessage = error.message || this.lang.baseError;
        this.isError = true;
        this.advertisementLoading = false;
      }
    },
  },
  computed: {
    firstAdvertisement() {
      return this.$store.getters["advertisement/firstAdvertisement"];
    },
  },
};
</script>

<style scoped>
.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.image-section {
  background-image: url("@/assets/images/image-login.png");
  background-size: cover;
  width: 50%;
}

.login-section {
  width: 50%;
}

@media screen and (max-width: 1199px) {
  .image-section {
    display: none;
  }
  .login-section {
    width: 100%;
  }
  .size-login {
    max-width: 366px !important;
  }
}
@media screen and (min-width: 1200px) {
  .size-login {
    width: 366px;
  }
}
.image-advertisement {
  height: 232px;
  border-radius: 12px;
}
.p-card :deep(.p-card-body) {
  padding: 0px 24px 0px 24px;
}
.p-card :deep(.p-card-content) {
  padding: 0cm 0px 24px 0px;
}
.p-card :deep(.p-card-footer) {
  padding: 0px 0px 24px 0px;
}
.p-component {
  width: 461px;
  border-radius: 12px;
}
.p-inputtext.p-inputtext-lg {
  font-size: 16px;
}
</style>
