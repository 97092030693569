export default {
  async getInvoiceGenerateQueueStatus(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Invoice/InvoiceGenerateQueue/Status";

    if (payload) {
      url = url + "?projectId=" + payload;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get invoice generate queue status."
      );
      throw error;
    }
    // console.log("getInvoiceGenerateQueueStatus",responseData);

    context.commit("setInvoiceGenerateQueueStatus", responseData);
  },
  async getInvoiceReadyGenerateBill(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Invoice/InvoiceReadyGenerateBillStatus";

    if (payload) {
      url = url + "?where=" + JSON.stringify(payload);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get invoice generate bill status."
      );
      throw error;
    }
    // console.log("getInvoiceReadyGenerateBill",responseData);

    context.commit("setInvoiceReadyGenerateBill", responseData);
  },
  resetInvoiceGenerateQueue(context) {
    context.commit("resetInvoiceGenerateQueue", null);
  },
  resetInvoiceReadyGenerateBill(context) {
    context.commit("resetInvoiceReadyGenerateBill", null);
  },
  async addInvoiceGenerateQueue(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Invoice/InvoiceGenerateQueue/Add`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || "Failed to add invoice generate queue.");
      throw error;
    }

    // console.log("addInvoiceGenerateQueue", responseData);

    context.commit("setSelectedinvoiceGenerateQueue", responseData);
  },
  async getInvoices(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Invoice/InvoiceWaitForApprove";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get invoices wait for approve");
      throw error;
    }

    // console.log("invoices", responseData);

    context.commit("setInvoice", responseData);
  },
  async getInvoiceById(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Invoice/InvoiceWaitForApproveById";

    if (payload) {
      url = url + "?Id=" + payload;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get invoice by id."
      );
      throw error;
    }
    // console.log("getInvoiceById",responseData);

    context.commit("setSelectedInvoice", responseData.data);
  },
  resetSelectedInvoice(context) {
    context.commit("setSelectedInvoice", null);
  },
  async cancelInvoice(context,payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Invoice/CancelGeneratedInvoice";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to cancel invoice wait for approve.');
      throw error;
    }

    // console.log("cancelInvoice", responseData);
    
    context.commit("setInvoiceIsCanceled", responseData);
  },
  async addGenerateInvoice(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Invoice/AddGeneratedInvoice`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || "Failed to add generate invoice.");
      throw error;
    }

    // console.log("addGenerateInvoice", responseData);

    context.commit("setGeneratedInvoice", responseData);
  },
  async usePrepaid(context,payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Invoice/UsePrepaid";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to use prepaid.');
      throw error;
    }

    // console.log("usePrepaid", responseData);
    
    context.commit("setUsedPrepaid", responseData);
  },
  async useDeposit(context,payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Invoice/UseDeposit";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to use deposit.');
      throw error;
    }

    // console.log("UseDeposit", responseData);
    
    context.commit("setUsedDeposit", responseData);
  },
};
