export default {
  invoices(state) {
    return state.invoices;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
  selectedInvoice(state){
    return state.selectedInvoice;
  },
  dataReferenceDocument(state){
    return state.dataReferenceDocument;
  },  
  blob(state) {
    return state.blob;
  },
  invoiceDateForPrintLots(state) {
    return state.invoiceDateForPrintLots;
  },
  invoiceDateForPrintLotTotalPage(state) {
    return state.invoiceDateForPrintLotTotalPage;
  },
  invoiceDateForPrintLotCurrentPage(state) {
    return state.invoiceDateForPrintLotCurrentPage;
  },
	invoiceDateForPrintLotNextPage(state) {
	  return state.invoiceDateForPrintLotCurrentPage + 1;
	},
	invoiceDateForPrintLotIsLastPage(state, getters) {
	  return getters.invoiceDateForPrintLotCurrentPage === getters.invoiceDateForPrintLotTotalPage 
      && getters.invoiceDateForPrintLotTotalPage !== 0;
	},
  invoiceDateForCombineUnits(state) {
    return state.invoiceDateForCombineUnits;
  },
  invoiceDateForCombineUnitTotalPage(state) {
    return state.invoiceDateForCombineUnitTotalPage;
  },
  invoiceDateForCombineUnitCurrentPage(state) {
    return state.invoiceDateForCombineUnitCurrentPage;
  },
	invoiceDateForCombineUnitNextPage(state) {
	  return state.invoiceDateForCombineUnitCurrentPage + 1;
	},
	invoiceDateForCombineUnitIsLastPage(state, getters) {
	  return getters.invoiceDateForCombineUnitCurrentPage === getters.invoiceDateForCombineUnitTotalPage 
      && getters.invoiceDateForCombineUnitTotalPage !== 0;
	},
  invoiceDataByAtDates(state) {
    return state.invoiceDataByAtDates;
  },
  invoiceDataByAtDateTotalPage(state) {
    return state.invoiceDataByAtDateTotalPage;
  },
  invoiceDataByAtDateCurrentPage(state) {
    return state.invoiceDataByAtDateCurrentPage;
  },
	invoiceDataByAtDateNextPage(state) {
	  return state.invoiceDataByAtDateCurrentPage + 1;
	},
	invoiceDataByAtDateIsLastPage(state, getters) {
	  return state.holdInvoiceDate = 
      getters.invoiceDataByAtDateCurrentPage === getters.invoiceDataByAtDateTotalPage 
      && getters.invoiceDataByAtDateTotalPage !== 0;
	},
  dataInvoiceNotification(state){
    return state.dataInvoiceNotification;
  },
  invoiceNotifyNextPage(state) {
    return state.invoiceNotifyCurrentPage + 1;
  },
  invoiceNotifyIsLastPage(_, getters) {
    return getters.invoiceNotifyCurrentPage === getters.invoiceNotifyTotalPage && getters.invoiceNotifyTotalPage !== 0;
  },
  invoiceNotifyTotalPage(state) {
    return state.invoiceNotifyTotalPage;
  },
  invoiceNotifyCurrentPage(state) {
    return state.invoiceNotifyCurrentPage;
  },
  allInvoiceNotification(state){
    return state.allInvoiceNotification;
  },

  //หน้าใบเสร็จ ใช้หายอดค้างคงเหลือ
  pendingAmountByUnitAndLivingType(state){
    return state.pendingAmountByUnitAndLivingType;
  },
  pendingLists(state){
    return state.pendingLists;
  },
	pendingListTotalPage(state) {
		return state.pendingListTotalPage;
	},
	pendingListCurrentPage(state) {
		return state.pendingListCurrentPage;
	},
	pendingListNextPage(state) {
	  return state.pendingListCurrentPage + 1;
	},
	pendingListIsLastPage(_, getters) {
	  return getters.pendingListCurrentPage === getters.pendingListTotalPage && getters.pendingListTotalPage !== 0;
	},
  pendingByAmount(state){
    return state.pendingByAmount;
  },

  //  importInvoice
  importInvoiceList(state) {
    return state.importInvoiceList;
  },
  validatedImportInvoice(state){
    return state.validatedImportInvoice;
  },
};
