import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export default {
    namespaced: true,
    state(){
        return {
            requests:[],
            selectedRequests:[],
            totalPage: 0,
            currentPage: 1,
            totalItem: 0,
            rows: 100,
            blob: null,
            currentQueue: 0,
            presignedUrl: null
        }
    },
    actions,
    getters,
    mutations
}