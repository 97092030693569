export default {
	title: "Meter Usage Setting",
	createMeterUsageSetting: "Create Item",
	saveMeterStepPrice: "Save",
	meterSettingType: "Meter Setting Type",
	meterMean: "Meter Mean Deviation",
	meterSettingStepPrice: "Meter Setting Step Price",

	labelMeanMeter: "Meter Mean Deviation (Alert when registering the meter.)",

	radioFixed: "Fixed",
	radioStep: "Step",

	columnProductCode: "Product Code",
	columnProductName: "Product Name",
	columnStartNo: "Start No",
	columnEndNo: "End No",
	columnPrice: "Price",
	columnDeleteMeterStep: "Delete Item",
	askForDeleteMeterUsageSetting: "Are you sure you want to delete this item?",
	errorMeterUsage: "You can't delete this Meter because there are references.",

	waterType: "Water",
	electricityType: "Electricity",
	coolingAirType: "Cooling Air",

	labelStartNo: "Start No",
	labelEndNo: "End No",
	labelPrice: "Price",

	meterType: "Meter Type",
	validateStartNo: "Please enter Start no.",
	validateEndNo: "Please enter End no.",
	validatePrice: "Please enter Price.",
	validateNoProduct: "Please select Product.",
};
