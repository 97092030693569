export default {
	payin(state) {
		return state.payin;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	selectedPayin(state) {
		return state.selectedPayin;
	},
	blob(state) {
		return state.blob;
	},
	receiptForPayin(state) {
		return state.receiptForPayin;
	},
};
