<template>
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredBanks"
    field="codeAndName"
    @complete="searchBank"
    @dropdown-click="filteredBanks"
    forceSelection
  />
</template>

<script>
import useGetBank from "../../hooks/getBank.js";
export default {
  setup() {
    const { searchText, filteredBanks, searchBank, isSharedBankReady } =
      useGetBank();

    return {
      searchText,
      filteredBanks,
      searchBank,
      isSharedBankReady,
    };
  },
  emits: ["load-finish"],
  watch: {
    isSharedBankReady(curValue) {
      if (curValue) {
        this.$emit("load-finish");
      }
    },
  },
};
</script>
