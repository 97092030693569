export default {
	title: "Phone Book",
	titleList: "Phone Book List",
    createPhoneBook: "Create Phone Book",
	namePhoneBook: "Phone book name",
	phone: "Phone",
	contactType: "Contact Type",
	phoneType: "Phone Type",
	dropdownSearchPlaceholder: "Select data to search.",
	validateDropdownSearchText: "Please select data to search.",
};
