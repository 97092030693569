export default {
	async getPayinLists(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/BankAr";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			const error = new Error(responseData.message || "Failed to get payin");
			throw error;
		}
		context.commit("setPayin", responseData);
	},
	async getPayinById(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/BankAr/" + payload.id;
		url = encodeURI(url);

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			const error = new Error(responseData.message || "Failed to get payin");
			throw error;
		}
		context.commit("setSelectedBankPayin", responseData.data);
	},
	async resetArBank(context) {
		context.commit("resetArBank");
	},
	async printPayin(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/BankTransaction/Print";

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
			mode: "cors", // no-cors, *cors, same-origin
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			credentials: "same-origin", // include, *same-origin, omit
			redirect: "follow", // manual, *follow, error
			referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
		});

		let urlBlob = "";
		let blob = await response.blob();
		urlBlob = window.URL.createObjectURL(blob);
		context.commit("setBlob", urlBlob);
	},
	async getReceiptForPayin(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + `/BankAr/GetReceipt`;
		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			let message = null;
			if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
				message = responseData.title;
				for (const key in responseData.errors) {
					message += ", " + key.replace("$.", "");
				}
			} else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
				message = responseData.message;
			}
			const error = new Error(message || "Failed to get Receipt");
			throw error;
		}
		context.commit("setReceiptForPayin", responseData.data);
	},
	async cancelPayin(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + `/BankAr/Cancel`;
		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			let message = null;
			if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
				message = responseData.title;
				for (const key in responseData.errors) {
					message += ", " + key.replace("$.", "");
				}
			} else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
				message = responseData.message;
			}
			const error = new Error(message || "Failed to Cancel Payin");
			throw error;
		}
		context.commit("setSelectedBankPayin", responseData.data);
	},
	async createOrUpdatePayin(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + `/BankAr/${payload.type}`;
		const fallbackErrorMessage = `Failed to ${payload.type} invoice`;
		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: payload.type === "Add" ? "post" : "put",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},

			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			let message = null;
			if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
				message = responseData.title;
				for (const key in responseData.errors) {
					message += ", " + key.replace("$.", "");
				}
			} else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
				message = responseData.message;
			}
			const error = new Error(message || fallbackErrorMessage);
			throw error;
		}
		context.commit("setSelectedBankPayin", responseData.data);
	},
};
