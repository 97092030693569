<template>
    <base-toolbar
      :title="lang.projectTitleAdvertise"
      :dataButtons="dataButtons"
      @goBack="goBack"
      @gotoSave="confirmSave"
      :hideBackButton="isHideBack"
    >
    </base-toolbar>
    
    <base-dialog
        v-model:visible="showConfirmDialog"
        :titleConfirm="titleConfirmDialog"
        :wordingConfirm="wordingDialog"
        @confirm="confirm"
        @close="close"
    ></base-dialog>
    <div class="mt24"></div>
    <base-container>  
      <div class="surface-card shadow-2  border-round ">      
        <base-card
          :titleHeader="this.lang.projectTitleAdvertise"
          :isVisibleExpandButton="true"
          :isHideBackground="true"
          :isVisibleCard="true"
        >
          <PVTabView>
            <PVTabPanel>
              <template #header>
                <span
                  >{{ lang.tabAdvertiseInvoice }}
                </span>
              </template>            
              <section class="flex flex-wrap fontlabel" :class="textFormClass">
                <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                  <label>{{ lang.advertiseInvoiceTh }}</label>
                  <div class="mt-1">
                    <PvTextarea
                      v-model="AdvertiseInvoiceTh"
                      rows="4"
                      :placeholder="lang.validateAdvertiseInvoiceTh"
                      class="inputfield w-full mt-1"
                      :maxlength="750"
                    />
                  </div>
                </div>
                <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                  <label>{{ lang.advertiseInvoiceEn }}</label>
                  <div class="mt-1">
                    <PvTextarea
                      v-model="AdvertiseInvoiceEn"
                      rows="4"
                      :placeholder="lang.validateAdvertiseInvoiceEn"
                      class="inputfield w-full mt-1"
                      :maxlength="750"
                    />
                  </div>
                </div>
              </section>
            </PVTabPanel>
            <PVTabPanel>
              <template #header>
                <span
                  >{{ lang.tabAdvertiseNotice }}
                </span>
              </template>
              <section class="flex flex-wrap fontlabel" :class="textFormClass">
                <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                  <label>{{ lang.advertiseNoticeTh }}</label>
                  <div class="mt-1">
                    <PvTextarea
                      v-model="AdvertiseNoticeTh"
                      rows="4"
                      :placeholder="lang.validateAdvertiseNoticeTh"
                      class="inputfield w-full mt-1"
                      :maxlength="750"
                    />
                  </div>
                </div>
                <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                  <label>{{ lang.advertiseNoticeEn }}</label>
                  <div class="mt-1">
                    <PvTextarea
                      v-model="AdvertiseNoticeEn"
                      rows="4"
                      :placeholder="lang.validateAdvertiseNoticeEn"
                      class="inputfield w-full mt-1"
                      :maxlength="750"
                    />
                  </div>
                </div>
              </section>
            </PVTabPanel>
            <PVTabPanel>
              <template #header>
                <span
                  >{{ lang.tabAdvertiseReceipt }}
                </span>
              </template>
              <section class="flex flex-wrap fontlabel" :class="textFormClass">
                <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                  <label>{{ lang.advertiseReceiptTh }}</label>
                  <div class="mt-1">
                    <PvTextarea
                      v-model="AdvertiseReceiptTh"
                      rows="4"
                      :placeholder="lang.validateAdvertiseReceiptTh"
                      class="inputfield w-full mt-1"
                      :maxlength="750"
                    />
                  </div>
                </div>
                <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                  <label>{{ lang.advertiseReceiptEn }}</label>
                  <div class="mt-1">
                    <PvTextarea
                      v-model="AdvertiseReceiptEn"
                      rows="4"
                      :placeholder="lang.validateAdvertiseReceiptEn"
                      class="inputfield w-full mt-1"
                      :maxlength="750"
                    />
                  </div>
                </div>
              </section>
            </PVTabPanel>
            <PVTabPanel>
              <template #header>
                <span
                  >{{ lang.tabAdvertiseInvoiceReceipt }}
                </span>
              </template>
              <section class="flex flex-wrap fontlabel" :class="textFormClass">
                <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                  <label>{{ lang.invoiceFooter }}</label>
                  <div class="mt-1">
                    <PvTextarea
                      v-model="InvoiceFooter"
                      rows="4"
                      :placeholder="lang.validateInvoiceFooter"
                      class="inputfield w-full mt-1"
                      :maxlength="3000"
                    />
                  </div>
                </div>
                <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
                  <label>{{ lang.receiptFooter }}</label>
                  <div class="mt-1">
                    <PvTextarea
                      v-model="ReceiptFooter"
                      rows="4"
                      :placeholder="lang.validateReceiptFooter"
                      class="inputfield w-full mt-1"
                      :maxlength="3000"
                    />
                  </div>
                </div>
              </section>
            </PVTabPanel>
          </PVTabView>
        </base-card>   
       
      </div>
      <div class="mt24"></div>
    </base-container>
  </template>
  
  <script>
  import useLang from "../../../hooks/lang.js";
  import useTextStyle from "../../../hooks/textStyle.js";
  import useFormat from "../../../hooks/format.js";
  
  import { useStore } from "vuex";
  import { computed, inject } from "vue";
  export default {
    setup() {
      const { lang } = useLang();
      const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
      const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
      const store = useStore();
      const mergeLang = computed(function () {
        if (store.getters.selectedLanguage === "TH") {
          return {
            ...lang.th.base,
            ...lang.th.master.project.edit,
          };
        } else {
          return {
            ...lang.en.base,
            ...lang.en.master.project.edit,
          };
        }
      });

      const isHideBack = inject("isHideBack");
  
      return {
        lang: mergeLang,
        textHeaderClass,
        textFormClass,
        textTableClass,
        formatCurrency,
        formatDate,
        formatDateToISOString,
        isHideBack,
      };
    },
    data() {
      return {      
  
        //ประชาสัมพันธ์ - โฆษณา
        AdvertiseInvoiceTh: "",
        AdvertiseInvoiceEn: "",
        AdvertiseNoticeTh: "",
        AdvertiseNoticeEn: "",
        AdvertiseReceiptTh: "",
        AdvertiseReceiptEn: "",
        InvoiceFooter: "",
        ReceiptFooter: "",  
      
        showConfirmDialog: false,
        wordingDialog: "",
        showConfirmDialogName: "",
        titleConfirmDialog: "",

        dataButtons: [
        {
          id: "saveCaption",
          caption: this.lang.saveCaption,
          class: "mr-3 p-button-toolbar",
          icon: "",
          iconColor: "",
          action: "gotoSave",
          isShow: true,
        },
      ],
  
      };
    },
    async created() {
      await this.GetProject();    
      if (this.project) {
        this.setData();
      }
    },
    methods: {
      async setLoading(bool) {
        this.$store.dispatch("setLoading", { value: bool });
      },
      goBack() {      
        this.titleConfirmDialog = this.lang.validategoBackTitle;
        this.showConfirmDialogName = "goBack";
        if(this.checkDatagoBack()){    
          this.wordingDialog =`${this.lang.validategoBackEdit.replace(":title", this.lang.editAdvertisement)}`;  
          this.showConfirmDialog = true;
        }else{
          this.$router.push({name: "home"})
        }  
      },
      checkDatagoBack(){
       if(this.project.advertiseInvoiceTh !== this.AdvertiseInvoiceTh) return true;
       if(this.project.advertiseInvoiceEn !== this.AdvertiseInvoiceEn) return true;
       if(this.project.advertiseNoticeTh !== this.AdvertiseNoticeTh) return true;
       if(this.project.advertiseNoticeEn !== this.AdvertiseNoticeEn) return true;
       if(this.project.advertiseReceiptTh !== this.AdvertiseReceiptTh) return true;
       if(this.project.advertiseReceiptEn !== this.AdvertiseReceiptEn) return true;
       if(this.project.invoiceFooter !== this.InvoiceFooter) return true;
       if(this.project.receiptFooter !== this.ReceiptFooter) return true;
       
       return false;
      },
      
      confirm() {
        this.showConfirmDialog = false; 
        if(this.showConfirmDialogName === "goBack"){     
          this.$router.push({name: "home"})
        }else if(this.showConfirmDialogName === "confirmSave"){
          this.saveData();
        }
      },
      close() {
        this.showConfirmDialog = false;
      },     
      async GetProject() {
        try {
          this.setLoading(true);
          await this.$store.dispatch(
            "masterProject/GetProjectById",
            this.selectedProject.id
          );
        } catch (error) {
          this.$toast.add({
            severity: "error", // severity:'success',severity:'info',severity:'warn',severity:'error'
            summary: this.lang.notificationDialogHeader, //TitleHeader
            detail: error,
            life: 5000, //TimeClose
          });
        }
        this.setLoading(false);
      },   
      setData() {   
        //ประชาสัมพันธ์ - โฆษณา
        this.AdvertiseInvoiceTh = this.project.advertiseInvoiceTh;
        this.AdvertiseInvoiceEn = this.project.advertiseInvoiceEn;
        this.AdvertiseNoticeTh = this.project.advertiseNoticeTh;
        this.AdvertiseNoticeEn = this.project.advertiseNoticeEn;
        this.AdvertiseReceiptTh = this.project.advertiseReceiptTh;
        this.AdvertiseReceiptEn = this.project.advertiseReceiptEn;
        this.InvoiceFooter = this.project.invoiceFooter;
        this.ReceiptFooter = this.project.receiptFooter;
  
        this.setLoading(false);
      },
      
      async confirmSave() {    
        this.titleConfirmDialog = this.lang.confirmEditSave;  
        this.showConfirmDialogName = "confirmSave";
        this.wordingDialog =`${this.lang.askForEditDocument.replace(":title", this.lang.editAdvertisement)}`;
        this.showConfirmDialog = true;
      },
      async saveData() {
        try {
          if (this.validateData()) {
            return;
          }
         
          let payload = {
            Id: this.selectedProject.id,            
            AdvertiseInvoiceTh: this.AdvertiseInvoiceTh,
            AdvertiseInvoiceEn: this.AdvertiseInvoiceEn,
            AdvertiseNoticeTh: this.AdvertiseNoticeTh,
            AdvertiseNoticeEn: this.AdvertiseNoticeEn,
            AdvertiseReceiptTh: this.AdvertiseReceiptTh,
            AdvertiseReceiptEn: this.AdvertiseReceiptEn,
            InvoiceFooter: this.InvoiceFooter,
            ReceiptFooter: this.ReceiptFooter,

          };
  
          //console.log("payload=", payload); 
          await this.$store.dispatch("masterProject/UpdateAdvertisement", payload);
                 
          this.$toast.add({
            severity: "success", // severity:'success',severity:'info',severity:'warn',severity:'error'
            summary: this.lang.notificationDialogHeader, //TitleHeader
            detail: this.lang.saveEditSuccess,
            life: 5000, //TimeClose
          });  
          
        } catch (error) {
          this.$toast.add({
            severity: "error", // severity:'success',severity:'info',severity:'warn',severity:'error'
            summary: this.lang.notificationDialogHeader, //TitleHeader
            detail: error,
            life: 5000, //TimeClose
          });
         // this.setLoading(false);
        }
        // this.setLoading(false);
       
      },
      validateData() {
       
        return false;
      },     
    
  
    },
    computed: {
      selectedProject() {
        return this.$store.getters.selectedProject;
      },
      project() {
        return this.$store.getters["masterProject/project"];
      },
    },
    
  };
  </script>
  
  <style scoped>
  /* .fontlabel {
    font-size: 14px;
    color: #181819;
  } */
  .fontData {
    font-size: 16px;
    /* color: #181819; */
    letter-spacing: 0.005em;
    font-weight: 400;
  }
    
  .mt24 {
    margin-top: 24px;
  }
  
  </style>
  