export default {
  async getUnitImportBillPayment(context, payload) {
    let url =
      process.env.VUE_APP_BACKEND_URL + "/Unit/GetUnitImportBillPayment";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get units");
      throw error;
    }
    // console.log("getUnitImportBillPayment",responseData);

    context.commit("setUnits", responseData);
  },
  async getReceiptMapBillPayment(context, payload) {
    let url =
      process.env.VUE_APP_BACKEND_URL + "/Receipt/GetReceiptMapBillPayment";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get receipts");
      throw error;
    }
    // console.log("getReceiptMapBillPayment",responseData);

    context.commit("setReceipts", responseData);
  },
  async getInvoiceImportBillPayment(context, payload) {
    let url =
      process.env.VUE_APP_BACKEND_URL + "/Invoice/GetInvoiceImportBillPayment";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get invoices");
      throw error;
    }
    // console.log("getInvoiceImportBillPayment",responseData);

    context.commit("setInvoices", responseData);
  },
  async getAllReceiptMapBillPayment(context, payload) {
    while (!context.getters.receiptIsLastPage) {
      const payloadGetData= {
        page: context.getters.receiptNextPage || 1,
        limit: 100,
        documentBookId: payload.documentBookId,
        paydate: payload.paydate,
      };
      await context.dispatch("getReceiptMapBillPayment", payloadGetData);
    }
  },
  resetReceiptMapBillPayment(context) {
    context.commit("resetReceiptMapBillPayment");
  },
  setDataBillPayments(context, payload) {
    context.commit("setDataBillPayments", payload);
  },
};
