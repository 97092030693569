export default {
    title: "Calculate Notice",
    validateInsert: "You may not have permission to calculate notice.",
    titleDetail: "Calculate Notice Detail",
    titleSelectDate: "Please select date to calculate notice and payment due date.",
    calculateDate: "Calculate date",
    dueDate: "Due Date",
    calculatePenaltyDate: "Calculate Penalty Date",
    validateCalculateDate: "Calculate notice date must be before the Due date.",
    gotoCalculateNotice: "Start calculate notice",
    titleDetailCalculating: "The system is calculating the notice.",
    titleDetailCalculatingDesc: "Please wait a moment, If successful The system will go to next step.",
    noticeChecklist: "รายการตรวจสอบหนังสือทวงถาม",
    cancelNotice: "Cancel Calculate",
    approveAll: "Confirm",

    columnCode: "Notice Code",
    columnDate: "Notice Date",
    columnUnitCode: "Unit Code",
    columnAddressNo: "Address No.",
    columnCustomerName: "Customer Name",
    columnNetAmount: "Net Amount",
    unitCode: "Unit Code",

    calculateNoticeDetail: "Calculate Notice Detail",
    noticeDetail: "Notice Detail",
    noticeDetailList: "Detail List",

    columnProductCode: "Product Code",
    columnProductName: "Product Name",
    columnRemark: "Remark",
    columnPeriod: "Period",
    columnUnitPrice: "Unit Price",
    columnRatePenalty: "Penalty Rate",
    columnPenaltyDay: "Penalty Day",
    columnAmount: "Total Amount",

    wordingConfirmCancelNotice: "Are you sure you want to cancel notices?",
    wordingConfirmSaveNotice: "Are you sure you want to confirm notices?",
    wordingSaveSuccess: "Calculate notice success.",
    saveSuccess: "You have completed the transaction.",
    validateDueDate: "Please select Due date.",
    printConfirmNoticePenalty: "Print Confirm Notice Penalty Report",
}