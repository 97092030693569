export default {
	title: "ใบสั่งซื้อ",
    createOrder: "สร้างใบสั่งซื้อ",
    editOrder: "แก้ใบสั่งซื้อ",
    create: "สร้างใหม่",
    add:"เพิ่ม",
    edit: "แก้ไข",
    print: "พิมพ์",
    approve: "อนุมัติ",
    reApprove: "ถอยอนุมัติ",
    import: "นำเข้า",
	closePO: "ปิดใบสั่งซื้อ",

	code: "เลขที่เอกสาร",
	inputReferDocument: "เลขที่เอกสารอ้างอิง",
	inputDate: "วันที่เอกสาร",
	inputDueDate: "วันที่ครบกำหนด",
	inputVatSendDate: "กำหนดส่งวันที่",
	inputCreditTerm: "เครดิตเทอม (วัน)",
	inputSupplier: "ผู้จัดจำหน่าย",
	inputEmployeeName: "ผู้จัดซื้อ",
	inputDifferenceAmount: "เงินขาด / เงินเกิน",
	inputActualPayAmount: "ยอดชำระจริง",
	inputSubAmount: "หัก อื่นๆ",
	inputAddAmount: "รับ อื่นๆ",
	inputRemark1: "รายละเอียดรายงาน",
	inputRemark2: "การแบ่งจ่ายชำระ",

	inputCurrency: "สกุลเงิน",
	inputTotalAmount:"มูลค่ารวมทุกรายการ",
    inputDiscount:"ส่วนลด",
    inputDiscountAmount:"มูลค่าส่วนลด",    
    inputPayment :"เงินมัดจำ",
    inputTotalAmountBeforeTax:"มูลค่าก่อนภาษี",
    inputTax: "อัตราภาษี",
    inputTaxAmount:"มูลค่าภาษี",
    inputNetAmount: "มูลค่าสุทธิ",

	columnItemNo: "ลำดับ",
	columnBookCode: "รหัสเล่ม",
    columnDate: "วันที่",
    columnCode: "รหัสสินค้า",
    columnCodeName : "ชื่อสินค้า",
    columnUnitName: "ชื่อหน่วยนับ",
    columnUnitPrice: "ราคา",
    columnQuantity:"ปริมาณ",
    columnDiscount:"ส่วนลด",
    columnDiscountAmt: "มูลค่าที่ลด",
    columnNetAmount: "รวมเงิน",
    columnAmount: "จำนวนเงิน",

	titleImportPR: "รายการใบขอซื้อ",

    askForClosePO: "คุณยืนยันที่จะ ปิดใบสั่งซื้อนี้ ใช่หรือไม่?",
    closePOSuccess: "ปิดใบสั่งซื้อสำเร็จ",
    askForRevertApprove: "คุณยืนยันที่จะ ถอยอนุมัติเอกสารนี้ ใช่หรือไม่?",
    revertApproveSuccess: "ถอยอนุมัติสำเร็จ",

    validateTotalAmount: "มูลค่ารวมทุกสินค้า ต้องมากกว่า 0",
    validateCheckDiscount: "รูปแบบส่วนลด ระบุได้เฉพาะตัวเลข และ %",
    validateUpdatePrice: "รายละเอียดใบขอซื้อ (PR) ไม่สามารถแก้ไขราคาได้",
    validateDiscountAmount: "มูลค่าส่วนลด ต้องไม่เกินมูลค่าสุทธิ",
    calculatedFromSystem: "  *ค่าคำนวณที่ระบบแนะนำ : ",
};
