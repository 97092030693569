export default {
    setReceipts(state, payload){
        state.receipts = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },
    setSelectedReceipt(state, payload){
        state.selectedReceipt = payload
    },
    setReceiptUniqueKey(state, payload){
        state.receiptUniqueKey = payload
    },
    setBlob(state, payload){        
        state.blob = payload
    },
    setReceiptReferenceDocument(state, payload){
        state.receiptReferenceDocument = payload.data
    },
    setImportReceiptList(state, payload){
        state.importReceiptList = payload
    },
    setMappedImportReceipt(state, payload){
        state.mappedImportReceipt = payload
    },
    setValidatedImportReceipt(state, payload){
        state.validatedImportReceipt = payload
    },
    setDocumentBookId(state, payload){
        state.documentBookId = payload
    },
};