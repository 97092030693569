export default {
    title: "Overview",
    outstanding: "Outstanding",
    parcel: "Parcel",
    resident: "Resident Status",
// outstanding
    titleOutstanding: "Outstanding Overview",
    titleOutstandingType: "Payment By Outstanding",
    titleParcel: "Parcel Overview",
    titleResident: "Resident Overview",
    commonArea: "Common Area",
    waterBilling: "Water",
    titleOutstandingDetail: "Outstanding Detail : :type",
    paid: "Paid",
    overdue: "Overdue",
    thaiBath: " ฿",
    invoiceStatus: "Debt Status",
    unitCount: "Unit Count",
    totalAmount: "Total Amount",
    unit: " Unit",
    percent: "%",
    titleOutstandingPaidAmount: "Paid Total Amount :amount ฿",
    titleOutstandingNationality: "Overdue By Nationality : :type",
    thaiPerson: "Thai",
    foreignPerson: "Foreign",
    titleOutstandingPayType: "Pay Type : :type",
    cash: "Cash",
    transfer: "Transfer",
    digitalPayment: "Digital Payment",
    other: "Other",
    payType: "Pay Type",
    item: "Items",

//parcel
    titleParcelDetail: "Parcel Overview",
    titleParcelInOut: "Parcel In-Out",
    titleParcelOutstanding: "Outstanding Parcel",
    parcelByToday: "Today",
    parcelByYesterday: "Yesterday",
    parcelByWeek: "Week (Mon - Sun)",
    parcelByMonth: "Month",
    parcelByYear: "Year",
    parcelType: "Count",
    parcelIn: "Parcel In",
    parcelOut: "Parcel Out",
    parcelWaitByDate: "1 ~ 15 Days",
    parcelWaitBy15Day: "16 ~ 30 Days",
    parcelWaitByWeek: "31 ~ 60 Days",
    parcelWaitByMonth: "60 Days Or More",
    parcelOutstanding: "Outstanding",
    parcelOutstandingCount: "Outstanding Count",
    titleParcelStat: "Statistics Of Parcel In-Out Retrospect 6 Month",
    titleParcelIn: "Parcel In",
    titleParcelOut: "Parcel Out",

// resident
    titleResidentDetail: "Resident Overview",
    residenceStatus: "Resident Status",
    isLivingUnit: "Living",
    isEmptyUnit: "Empty",
    residentUtilization: "Resident Utilization",
    isOwnerLiving: "Living",
    isInvesment: "Invesment",
    titleOwnershipRate: "Common Area Ratio By Nationality",
    titleResidentOwnershipRate: "Total Unit :unit Unit",
    titleResidentRegisterUseMobile: "Registration Ratio LivingOS",
    titleResidentUseMobile: "Total Registration :user User",
    owner: "Owner",
    rental: "Rental",
    stay: "Stay",
    user: " User"
}