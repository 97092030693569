export default {
    title: "ภาพรวมในส่วนงานต่างๆ",
    outstanding: "หนี้คงค้าง",
    parcel: "พัสดุ",
    resident: "สถานะพักอาศัย",
// outstanding
    titleOutstanding: "ภาพรวมในส่วนงาน หนี้คงค้าง",
    titleOutstandingType: "อัตราการชำระ แยกตามประเภทหนี้คงค้าง",
    titleParcel: "ภาพรวมในส่วนงาน พัสดุ/บริการ",
    titleResident: "ภาพรวมในส่วนงาน สถานะพักอาศัย",
    commonArea: "ค่าส่วนกลาง",
    waterBilling: "ค่าน้ำ",
    titleOutstandingDetail: "รายละเอียดสถานะหนี้คงค้าง : :type",
    paid: "ชำระแล้ว",
    overdue: "ค้างชำระ",
    thaiBath: " ฿",
    invoiceStatus: "สถานะหนี้",
    unitCount: "จำนวนยูนิต",
    totalAmount: "จำนวนเงิน",
    unit: " ยูนิต",
    percent: "%",
    titleOutstandingPaidAmount: "ยอดชำระทั้งหมด :amount ฿",
    titleOutstandingNationality: "สัดส่วนคงค้างชำระตามสัญชาติ : :type",
    thaiPerson: "คนไทย",
    foreignPerson: "ชาวต่างชาติ",
    titleOutstandingPayType: "ประเภทการชำระ : :type",
    cash: "เงินสด (Cash)",
    transfer: "เงินโอน (Transfer)",
    digitalPayment: "Digital Payment",
    other: "อื่นๆ",
    payType: "ประเภทการชำระ",

//parcel
    titleParcelDetail: "ภาพรวมในส่วนงาน พัสดุ/บริการ",
    titleParcelInOut: "จำนวนพัสดุเข้า - ออก",
    titleParcelOutstanding: "จำนวนพัสดุคงค้าง",
    parcelByToday: "วันนี้",
    parcelByYesterday: "เมื่อวานนี้",
    parcelByWeek: "สัปดาห์นี้ (จ - อา)",
    parcelByMonth: "เดือนนี้",
    parcelByYear: "ปีนี้",
    parcelType: "จำนวนของ",
    parcelIn: "จำนวนพัสดุเข้า",
    parcelOut: "จำนวนพัสดุออก",
    parcelWaitByDate: "1 ~ 15 วัน",
    parcelWaitBy15Day: "16 ~ 30 วัน",
    parcelWaitByWeek: "31 ~ 60 วัน",
    parcelWaitByMonth: "60 วันขึ้นไป",
    parcelOutstanding: "ค้างมาแล้ว",
    parcelOutstandingCount: "จำนวนพัสดุคงค้าง",
    titleParcelStat: "สถิติพัสดุรับเข้าและจ่ายออก ย้อนหลัง 6 เดือน",
    titleParcelIn: "พัสดุเข้า",
    titleParcelOut: "พัสดุออก",
    item: "รายการ",

// resident
    titleResidentDetail: "ภาพรวมในส่วนงาน สถานะพักอาศัย",
    residenceStatus: "สถานะที่พักอาศัย",
    isLivingUnit: "มีผู้พักอาศัย",
    isEmptyUnit: "ไม่มีผู้พักอาศัย",
    residentUtilization: "การใช้ประโยชน์ที่พักอาศัย",
    isOwnerLiving: "เพื่อพักอาศัย",
    isInvesment: "เพื่อลงทุน",
    titleOwnershipRate: "อัตรากรรมสิทธิ์ในส่วนกลาง แยกตามสัญชาติ",
    titleResidentOwnershipRate: "จำนวนยูนิตทั้งหมด :unit ยูนิต",
    titleResidentRegisterUseMobile: "อัตราการลงทะเบียนและใช้งาน LivingOS",
    titleResidentUseMobile: "ยอดลงทะเบียนทั้งหมด :user User",
    owner: "เจ้าของ",
    rental: "ผู้เช่า",
    stay: "ผู้พักอาศัย",
    user: " User"
}