<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
    :draggable="false"
    :header="lang.addNewFormDetail"
  >
    <section class="flex flex-wrap" :class="textFormClass">
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="sequence">{{ lang.columnSequence }}</label>
        <PvInputNumber
          id="sequence"
          class="inputfield w-full mt-1 "
          v-model="sequence"    
          @focus="$event.target.select()"      
        />
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="category">{{ lang.columnCategory }}</label>
        <PvDropdown
          id="category"
          class="inputfield w-full mt-1"
          :options="categoryOptions"
          v-model="selectedCategory"
        />
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="category">{{ lang.columnProject }}</label>
        <PvSkeleton
          v-if="isProjectLoading"
          class="inputfield w-full mt-1"
          borderRadius="16px"
          height="3rem"
        />
        <PvAutoComplete
          id="category"
          class="inputfield w-full mt-1"
          :suggestions="filteredProjects"
          field="code"
          :dropdown="true"
          @complete="searchProject($event)"
          v-model="selectedProject"
          v-else
        />
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="name">{{ lang.columnName }}</label>
        <PvInputText
          id="name"
          type="text"
          class="inputfield w-full mt-1"
          v-model="name"
        />
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="category">{{ lang.columnPath }}</label>
        <PvInputText
          id="path"
          type="text"
          class="inputfield w-full mt-1"
          v-model="path"
        />
      </div>
      <div class="flex col-12 pl-0 align-content-center">
      <!-- <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 "> -->
          <!-- <label for="category">&nbsp;</label> -->
          <div class="field-checkbox mt-2">
            <PvCheckbox
              id="selectedIsActive"
              v-model="selectedIsActive"
              :binary="true"
            />
            <label for="selectedIsActive">{{ lang.isActive }}</label>           
          </div>
        
      </div>
    </section>
    <template #footer>
      <PvButton :label="lang.save" icon="pi pi-check" @click="save" autofocus />
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        @click="closeModal"
        class="p-button-text"
      />
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useGetProject from "../../../hooks/getProject.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  props: [
    "editingId",
    "editingSequence",
    "editingCategory",
    "editingProjectId",
    "editingName",
    "editingPath",
    "editingType",
    "editingIsActive",
    "openAddFormDetail",
  ],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass } = useTextStyle();

    const {
      isSharedProjectReady,
      getProjectById,
      searchText,
      filteredProjects,
    } = useGetProject();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.admin.form.detail };
      } else {
        return { ...lang.en.base, ...lang.en.admin.form.detail };
      }
    });
    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      isSharedProjectReady,
      getProjectById,
      searchText,
      filteredProjects,
    };
  },
  emits: ["close", "save-data"],
  data() {
    return {
      sequence: null,
      selectedCategory: null,
      selectedProject: null,
      selectedIsActive: true,
      IsActive: "Y",
      name: "",
      path: "",
      isProjectLoading: true,
      categoryOptions: ["All", "Project"],
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    save() {
      if (this.selectedIsActive) this.IsActive = "Y";
      else this.IsActive = "N";
      let payload = {
        sequence: this.sequence ? this.sequence : 0,
        category: this.selectedCategory,
        path: this.path,
        name: this.name,
        type: this.editingType,
        IsActive: this.IsActive,
      };
      // console.log(payload)
      if (this.selectedProject) {
        payload = { ...payload, projectId: this.selectedProject.id };
      }
      if (this.editingId) {
        payload = { ...payload, id: this.editingId };
      }
      // console.log(payload)
      this.$emit("save-data", payload);
      this.$emit("close");
    },
    searchProject(event) {
      this.searchText = event.query;
    },
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    setSelectedProject() {
      this.selectedProject = this.getProjectById(this.editingProjectId);
    },
  },
  watch: {
    editingSequence(curValue) {
      this.sequence = curValue;
    },
    editingName(curValue) {
      this.name = curValue;
    },
    editingPath(curValue) {
      this.path = curValue;
    },
    editingIsActive(curValue) {
      this.IsActive = curValue;
      if (curValue == "Y") this.selectedIsActive = true;
      else this.selectedIsActive = false;
    },
    editingCategory(curValue) {
      if (curValue) {
        this.selectedCategory = this.categoryOptions.find(
          (cat) => cat === curValue
        );
      }
    },
    editingProjectId(curValue) {
      if (curValue && this.isSharedProjectReady) {
        this.setSelectedProject();
      }
    },
    isSharedProjectReady(curValue) {
      if (curValue && this.editingProjectId) {
        this.setSelectedProject();
      }
      this.isProjectLoading = false;
    },
    openAddFormDetail(){
      // console.log("curValue=",curValue);
      if (this.editingType == "Add"){
        this.sequence = null;
        this.selectedCategory = null;
        this.selectedProject = null;
        this.name = "";
        this.path = ""; 
      }
    }
  },
};
</script>
