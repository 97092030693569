export default {
  async getBudgets(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Budget";

    if (payload.projectId) {
      url = url + "?projectId=" + payload.projectId;
    }
    url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to get Budget list"
      );
      throw error;
    }
    await context.commit("setBudgets", responseData.data);
  },
  async getBudgetById(context, payload) {
	let url = process.env.VUE_APP_BACKEND_URL + `/Budget/${payload.projectId}/${payload.id}`;
    url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || `Failed to get budget id : ${payload.id}`
      );
      throw error;
    }

    context.commit("setSelectedBudget", responseData.data);
  },
  resetBudget(context) {
    context.commit("setSelectedBudget", null);
  },
  async createBudget(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + "/Budget/Add";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw responseData;
    }

    context.commit("setSelectedBudget", responseData.data);
  },
  async updateBudget(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + `/Budget/${payload.id}/Update`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw responseData;
    }

    context.commit("setSelectedBudget", responseData.data);
  },
  async cancelBudget(context, payload){
	let url = process.env.VUE_APP_BACKEND_URL + `/Budget/${payload.id}/Cancel`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw responseData;
    }

    context.commit("setSelectedBudget", responseData.data);
  },
  async approveBudget(context, payload){
	let url = process.env.VUE_APP_BACKEND_URL + `/Budget/${payload.id}/Approve`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw responseData;
    }

    context.commit("setSelectedBudget", responseData.data);
  },
  async updateActiveBudget(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + `/Budget/Active/${payload.projectId}/${payload.id}`;
    const fallbackErrorMessage = `Failed to ${payload.type}`;
    const token = context.rootGetters.token;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });
    const responseData = await response.json();
    if (!response.ok) {
			let message = null;
			if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
				message = responseData.title;
				for (const key in responseData.errors) {
					message += ", " + key.replace("$.", "");
				}
			} else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
        message = responseData.message;
			}
      const knownErrorCodes = ["111", "103", "108", "107"];
      if (knownErrorCodes.includes(responseData.code)) {
        throw responseData.code;
      }
      throw new Error(message || fallbackErrorMessage);
    }
    context.commit("setSelectedBudget", responseData.data);
  },

  async getBudgetLastDate(context, projectId) {
	let url = process.env.VUE_APP_BACKEND_URL + `/Budget/LastDate/${projectId}`;
    url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || `Failed to get budget last date by project id : ${projectId}`
      );
      throw error;
    }

    context.commit("setBudgetLastDate", responseData.data);
  },  
  resetSelectedBudget(context) {
    context.commit("resetSelectedBudget");
  },
  resetBudgets(context) {
    context.commit("setBudgets", []);
  },
};
