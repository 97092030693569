export default {
    budgets(state) {
        return state.budgets;
    },
    selectedBudget(state) {
        return state.selectedBudget;
    },
    budgetLastDate(state) {
        return state.budgetLastDate;
    },
};
