export default {
	async getVoucherReferenceDocuments(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Voucher/Purchase/GetReferenceDocuments";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Voucher Reference Documents");
			throw error;
		}
		// console.log(responseData);

		// const data = responseData.data;
		context.commit("setVoucherReferenceDocuments", responseData);
	},
	async getSelectedVoucherReferenceDocument(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Voucher/Purchase/GetReferenceDocuments";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Voucher Reference Documents");
			throw error;
		}
		// console.log(responseData);

		// const data = responseData.data;
		context.commit("setSelectedVoucherReferenceDocument", responseData.data);
	},
	resetSelectedVoucherReferenceDocument(context) {
		context.commit("resetSelectedVoucherReferenceDocument");
	},
	async deleteVoucherPurchaseReferenceDocument(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Voucher/DeletePurchaseReceiptWithVoucher";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("PurchaseReceipt>>>", responseData);
			const error = new Error(responseData.message || "Failed to Cancel Purchase Receipt");
			throw error;
		}
		// console.log("setPurchaseReceiptToGl>>>", responseData.data);
		context.commit("setSelectedVoucherReferenceDocument", responseData.data);
	},
	setSelectedVoucherReferenceDocument(context, payload) {
		context.commit("setSelectedVoucherReferenceDocument", payload);
	},
};
