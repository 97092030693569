<template>
  <base-toolbar
    :title="this.title"
    :dataButtons="dataButtons"
    @goBack="goBack()"
    @gotoUpdate="gotoUpdate()"
    :hideBackButton="isHideBack"
  ></base-toolbar>
  <base-dialog
    v-model:visible="showConfirmDialog"
    :classTitle="baseDialogClassTitle"
    :titleConfirm="confirmTitle"
    :wordingConfirm="confirmWording"
    :dataButtons="dataButtonDialog"
    @confirm="deleteMeterUsageSetting()"
    @close="showConfirmDialog = false"
  ></base-dialog>
  <base-container>
    <div class="surface-card shadow-2 py-2 px-5 border-round my-5">
      <div
        class="flex justify-content-between align-items-center"
        style="padding-top: 20px"
      >
        <h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
      </div>
      <PvDivider></PvDivider>
      <div style="padding-top: 20px">
        <h2 :class="textHeaderClass" class="my-0">{{ lang.meterMean }}</h2>
        <div class="flex mt-5">
          <div class="col-6 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.labelMeanMeter }}</label>
            <PvInputNumber
              id="meanMeter"
              class="inputfield w-full mt-2"
              v-model="meanMeter"
              mode="decimal"
              :min="0"
              :minFractionDigits="2"
              :maxFractionDigits="2"
            />
          </div>
        </div>
      </div>
      <PvDivider></PvDivider>
      <div style="padding-top: 20px">
        <h2 :class="textHeaderClass" class="my-0">{{ lang.meterSettingType }}</h2>
        <div class="flex mt-5">
          <div
            v-for="(meterSettingType, index) of meterSettingTypes"
            :key="index"
            class="field-radiobutton mr-5"
          >
            <PvRadioButton
              :inputId="meterSettingType.name"
              name="meterSettingTyp"
              :value="meterSettingType.key"
              v-model="selectedMeterSettingType"
              class="mr-3"
              @change="meterTypeSelected()"
            />
            <label :for="meterSettingType.key">{{ meterSettingType.name }}</label>
          </div>
        </div>
      </div>
      <div v-if="selectedMeterSettingType === 1">
        <PvDivider></PvDivider>
        <div class="align-items-center" style="padding-top: 20px">
          <h2 :class="textHeaderClass" class="my-0">{{ lang.meterSettingStepPrice }}</h2>
          <section class="flex flex-wrap fontlabel" :class="textFormClass">
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
              <label>{{ lang.meterType }} *</label>
              <PvDropdown
                :options="meterTypes"
                optionLabel="label"
                class="inputfield w-full mt-2"
                v-model="selectedMeterType"
                @change="meterTypeSelected()"
              />
            </div>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
              <label>{{ lang.productCode }} *</label>
              <PvAutoComplete
                :dropdown="true"
                :suggestions="filteredProducts"
                field="codeAndName"
                class="inputfield w-full mt-2"
                forceSelection
                @complete="searchProduct($event)"
                v-model="selectedProduct"
                :class="{ 'p-invalid': productError }"
                @item-select="productError = false"
              />
              <small v-if="productError" id="product-help" class="p-error">
                {{ lang.validateNoProduct }}
              </small>
            </div>
            <div class="lg:col-4 md:col-4 col-6 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
              <label>{{ lang.labelStartNo }}</label>
              <PvInputNumber
                id="startNo"
                class="inputfield w-full mt-2"
                v-model="startNo"
                :min="0"
                :class="{ 'p-invalid': startNoError }"
              />
              <small v-if="startNoError" id="start-no-help" class="p-error">
                {{ lang.validateStartNo }}
              </small>
            </div>
            <div class="lg:col-4 md:col-4 col-6 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
              <label>{{ lang.labelEndNo }}</label>
              <PvInputNumber
                id="endNo"
                class="inputfield w-full mt-2"
                v-model="endNo"
                :min="0"
                :class="{ 'p-invalid': endNoError }"
                @focus="endNoError = false"
              />
              <small v-if="endNoError" id="end-no-help" class="p-error">
                {{ lang.validateEndNo }}
              </small>
            </div>
            <div class="lg:col-4 md:col-4 col-6 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
              <label>{{ lang.labelPrice }}</label>
              <PvInputNumber
                id="price"
                class="inputfield w-full mt-2"
                v-model="price"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                :min="0"
                :class="{ 'p-invalid': priceError }"
                @focus="priceError = false"
              />
              <small v-if="priceError" id="price-help" class="p-error">
                {{ lang.validatePrice }}
              </small>
            </div>
            <div class="lg:col-4 md:col-4 col-6 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
              <PvButton
                :label="this.lang.createMeterUsageSetting"
                icon="pi pi-plus"
                @click="addMeterUsageSetting()"
              />
            </div>
          </section>
        </div>
        <PvDivider></PvDivider>
        <div class="p-datatable-border my-5">
          <PvDataTable
            :value="meterUsageSettingLists"
            dataKey="id"
            selectionMode="single"
            responsiveLayout="scroll"
            :class="textTableClass"
            class="p-datatable-xs"
            :loading="isLoading"
          >
            <!-- <template #header>
						<div class="flex justify-content-between align-items-center" style="height: 72px"></div>
					</template> -->
            <PvColumn
              field="productCode"
              :header="lang.columnProductCode"
              headerClass="h-left"
              bodyClass="text-left"
              style="height: 56px;"
            />
            <PvColumn
              field="productName"
              :header="lang.columnProductName"
              headerClass="h-left"
              bodyClass="text-left"
            />
            <PvColumn
              field="startNo"
              :header="lang.columnStartNo"
              headerClass="h-right"
              bodyClass="text-right"
            >
              <template #body="slotProps">
                {{ formatNumber(slotProps.data.startNo) }}
              </template>
            </PvColumn>
            <PvColumn
              field="endNo"
              :header="lang.columnEndNo"
              headerClass="h-right"
              bodyClass="text-right"
            >
              <template #body="slotProps">
                {{ formatNumber(slotProps.data.endNo) }}
              </template>
            </PvColumn>
            <PvColumn
              field="price"
              :header="lang.columnPrice"
              headerClass="h-right"
              bodyClass="text-right"
            >
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.price) }}
              </template>
            </PvColumn>
            <PvColumn
              :header="lang.columnDeleteMeterStep"
              headerClass="h-center"
              bodyClass="text-center"
              style="height: 56px;"
            >
              <template #body="slotProps">
                <PvButton
                  type="button"
                  class="p-button-danger p-button-text py-0"
                  icon="pi pi-pencil"
                  @click="askForDeleteMeterUsageSetting(slotProps.data.id)"
                >
                  <icon-trash-delete-bin iconColor="error" />
                </PvButton>
              </template>
            </PvColumn>

            <!-- <template #footer>
              <PvPaginator
                :rows="rowsPerPage"
                :totalRecords="totalRecords"
                @page="onPageChange($event)"
                :rowsPerPageOptions="[10, 50, 100]"
                class="p-pagination-radius"
              >
                <template #start="slotProps">
                  {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{
                    totalPage
                  }}
                </template>
              </PvPaginator>
            </template> -->
            <template #empty>
              <div class="w-full p-3" style="text-align: center">
                <label>{{ this.lang.tableEmpty }}</label>
              </div>
            </template>
          </PvDataTable>
        </div>
      </div>
    </div>
  </base-container>
</template>
<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import usePageDirty from "../../../hooks/pageDirty";

export default {
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString, formatNumber } = useFormat();
    const isHideBack = inject("isHideBack");
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.master.meterUsageSetting };
      } else {
        return { ...lang.en.base, ...lang.en.master.meterUsageSetting };
      }
    });

    const { compareObject } = usePageDirty();

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      formatNumber,
      compareObject,
      isHideBack,
    };
  },
  data() {
    return {
      isLoading: false,
      showConfirmDialog: false,
      baseDialogClassTitle: "",
      confirmTitle: "",
      confirmWording: "",
      dataButtonDialog: [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ],

      selectedPage: 1,
      rowsPerPage: 10,
      selectedMeterSettingType: null,
      idMeterUsageSetting: null,
      startNo: 0,
      endNo: 0,
      price: 0,
      startNoError: false,
      endNoError: false,
      priceError: false,
      productList: [],
      selectedProduct: null,
      filteredProducts: null,
      productError: false,
      meterSettingTypes: [
        {
          key: 0,
          name: this.lang.radioFixed,
        },
        {
          key: 1,
          name: this.lang.radioStep,
        },
      ],
      selectedMeterType: { value: "W", label: this.lang.waterType },
      meterTypeError: false,
      lastMeterUsageSetting: null,
      meterTypes: [
        {
          value: "W",
          label: this.lang.waterType,
        },
        {
          value: "E",
          label: this.lang.electricityType,
        },
        {
          value: "A",
          label: this.lang.coolingAirType,
        },
      ],
      dataButtons: [
        {
          id: "editCaption",
          action: "gotoUpdate",
          type: "save"
        },
      ],
      objToCompare: null,

      meanMeter: 0,
    };
  },
  async mounted() {
    await this.getMeterUsageSettingType();
    this.setCompareData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    setCompareData() {
      const objToCompare = {
        projectId: this.selectedProject.id,
        meterUsageSettingType: this.selectedMeterSettingType,
        productId: this.selectedProduct ? this.selectedProduct.id : null,
        page: this.selectedPage,
        limit: this.rowsPerPage,
        ListPriceStep: this.meterUsageSettingLists,
        projectMeterAverage: this.meanMeter?this.meanMeter:0,
      };
      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
    },
    async getMeterUsageSettingType() {
      try {
        this.setLoading(true);
        let where = {
          projectId: this.selectedProject.id,
        };

        const payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          where,
        };

        await this.$store.dispatch("meterUsageSetting/getMeterUsageSettingType", payload);
        this.selectedMeterSettingType = this.meterUsageSettingType.projectPriceType;
        this.meanMeter = this.meterUsageSettingType.projectMeterAverage;
        if (this.selectedMeterSettingType === 1) {
          await this.getProduct();
          await this.getMeterUsageSettingList();
        }
        this.setLoading(false);
      } catch (error) {
        this.openDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.setLoading(false);
      }
    },
    async getMeterUsageSettingList() {
      try {
        this.isLoading = true;
        let where = {
          projectId: this.selectedProject.id,
          productCode: this.selectedMeterType.value,
        };

        const payload = {
          // page: this.selectedPage,
          // limit: this.rowsPerPage,
          where,
        };

        await this.$store.dispatch("meterUsageSetting/getAllMeterUsageSetting", payload);
        this.lastMeterUsageSetting = this.meterUsageSettingLists[
          this.meterUsageSettingLists.length - 1
        ];
        this.isLoading = false;
      } catch (error) {
        this.openDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.isLoading = false;
      }
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getMeterUsageSettingList();
    },
    askForDeleteMeterUsageSetting(id) {
      this.idMeterUsageSetting = id;
      this.openDialog({
        severity: "",
        title: this.lang.notificationDialogHeader,
        content: this.lang.askForDeleteMeterUsageSetting,
        isConfirm: true,
      });
    },
    deleteMeterUsageSetting() {
      let i = this.meterUsageSettingLists
        .map((item) => item.id)
        .indexOf(this.idMeterUsageSetting);
      this.meterUsageSettingLists.splice(i, 1);
      this.showConfirmDialog = false;
    },
    addMeterUsageSetting() {
      if (this.validateData()) {
        this.setLoading(false);
        return;
      }

      let data = {
        id: "dummy" + (this.meterUsageSettingLists.length + 1),
        productId: this.selectedProduct.id,
        productCode: this.selectedProduct.code,
        productName: this.selectedProduct.nameThai,
        itemNo: this.lastMeterUsageSetting ? this.lastMeterUsageSetting.itemNo + 1 : 1,
        startNo: this.startNo,
        endNo: this.endNo,
        price: this.price,
        projectId: this.selectedProject.id,
        projectName: this.selectedProject.nameTh,
      };

      this.meterUsageSettingLists.push(data);
      this.startNo = 0;
      this.endNo = 0;
      this.price = 0;
    },
    validateData() {
      let returnStatus = false;
      if (!this.selectedProduct) {
        this.productError = true;
        returnStatus = true;
      }
      // if (this.startNo < 0) {
      //   this.startNoError = true;
      //   returnStatus = true;
      // }
      // if (this.endNo < 0) {
      //   this.endNoError = true;
      //   returnStatus = true;
      // }
      // if (this.price < 0) {
      //   this.priceError = true;
      //   returnStatus = true;
      // }

      if (returnStatus) {
        this.openDialog({
          severity: "error",
          title: this.lang.validateSaveTitle,
          content: this.wordingValidate(),
          isConfirm: false,
        });
      }
      return returnStatus;
    },
    wordingValidate(){
      if (!this.selectedProduct) {
        return this.lang.validateDropDownNoSelect + this.lang.productCode;
      }
    },
    searchProduct(event) {
      let query = event.query;
      if (!query.trim().length) {
        const result = [...this.productList];
        this.filteredProducts = result;
      } else {
        this.filteredProducts = this.productList.filter((product) => {
          return product.searchText.toLowerCase().includes(query.toLowerCase());
        });
      }
    },
    async getProduct() {
      try {
        let where = {
          projectId: this.selectedProject.id,
          code: this.selectedMeterType.value,
        };

        const payload = {
          page: 1,
          limit: 100,
          where,
        };

        await this.$store.dispatch("meter/getProduct", payload);
        this.productList = this.product;
      } catch (error) {
        this.openDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
      }
    },
    async meterTypeSelected() {
      this.startNo = 0;
      this.endNo = 0;
      this.price = 0;
      this.selectedProduct = null;
      this.initiateData();
      await this.getProduct();
      await this.getMeterUsageSettingList();
    },
    initiateData() {},
    async gotoUpdate() {
      // console.log("gotoUpdate",this.selectedMeterSettingType);
      this.setLoading(true);
      try {
        // console.log(this.payload);
        if (this.selectedMeterSettingType == 1 && this.validateData()) {
          this.setLoading(false);
          return;
        }

        await this.$store.dispatch(
          "meterUsageSetting/updateMeterUsageSetting",
          this.payload
        );

        this.openDialog({
          severity: "success",
          title: this.lang.saveSuccess,
          content: "",
          isConfirm: false,
        });

        await this.getMeterUsageSettingType();
        this.setLoading(false);
      } catch (error) {
        this.openDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.setLoading(false);
      }
    },
    openDialog(dataConfig) {
      if (dataConfig.severity === "") {
        this.baseDialogClassTitle = "";
      } else if (dataConfig.severity === "success") {
        this.baseDialogClassTitle = "p-text-success";
      } else if (dataConfig.severity === "error") {
        this.baseDialogClassTitle = "p-error";
      }

      
      this.dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
      if (dataConfig.isConfirm) {
        this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "confirm",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "confirm",
          },
        ];
      } 

      this.confirmTitle = dataConfig.title;
      this.confirmWording = dataConfig.content;
      this.showConfirmDialog = true;
    },
  },
  computed: {
    title() {
      return this.lang.title;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    totalPage() {
      return this.$store.getters["meterUsageSetting/totalPage"];
    },
    currentPage() {
      return this.$store.getters["meterUsageSetting/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    meterUsageSettingType() {
      return this.$store.getters["meterUsageSetting/meterUsageSettingType"];
    },
    meterUsageSettingLists() {
      return this.$store.getters["meterUsageSetting/meterUsageSettingLists"];
    },
    product() {
      return this.$store.getters["meter/product"];
    },
    payload() {
      let payload = {
        projectId: this.selectedProject.id,
        meterUsageSettingType: this.selectedMeterSettingType,
        productId: this.selectedProduct ? this.selectedProduct.id : null,
        page: this.selectedPage,
        limit: this.rowsPerPage,
        ListPriceStep: this.meterUsageSettingLists,
        projectMeterAverage: this.meanMeter?this.meanMeter:0,
      };
      return payload;
    },
  },
  watch: {
    payload(curValue) {
      this.compareObject(this.objToCompare, curValue);
    },
  },
};
</script>
<style scoped>
.p-pagination-radius {
  border-radius: 0 0 12px 12px;
}
</style>
