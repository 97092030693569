<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
    :header="reportName"
  >
  
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ this.reportName }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="apBookselect">{{ lang.bookCode }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="apBookselect"
            optionLabel="bookName"
            optionValue="bookCode"
            v-model="selectBookCode"
            :filter="true"
            :filterPlaceholder="lang.search"
            :class="{ 'p-invalid': bookCodeError }"
          />
          <small v-if="bookCodeError" id="unit-help" class="p-error">{{
            lang.validatebookCodeError
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2"></div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateStart">{{ lang.dateStart }}</label>
          <PvCalendar
            id="dateStart"
            v-model="dateStart"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateEnd">{{ lang.dateEnd }}</label>
          <PvCalendar
            id="dateEnd"
            v-model="dateEnd"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pb-0">
          <label for="supplier">{{ lang.supplierStart }}</label>
          <base-supplier-auto-complete
            id="supplier"
            v-model="selectSupplierStart"
            class="inputfield w-full mt-1"
            @load-finish="supplierStartReady"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pb-0">
          <label for="supplier">{{ lang.supplierEnd }}</label>
          <base-supplier-auto-complete
            id="supplier"
            v-model="selectSupplierEnd"
            class="inputfield w-full mt-1"
            @load-finish="supplierEndReady"
          />
        </div>
        <!-- <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="supplierListStart">{{ lang.supplierStart }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="supplierListStart"
            optionLabel="codeAndName"
            optionValue="code"
            v-model="selectSupplierStart"
            :filter="true"
            :filterPlaceholder="lang.search"
            :class="{ 'p-invalid': supplierStartError }"
          />
          <small
            v-if="supplierStartError"
            id="supplierStart-help"
            class="p-error"
            >{{ lang.validateSupplierStartNoSelected }}</small
          >
        </div> -->
        <!-- <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="supplierListEnd">{{ lang.supplierEnd }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="supplierListEnd"
            optionLabel="codeAndName"
            optionValue="code"
            v-model="selectSupplierEnd"
            :filter="true"
            :filterPlaceholder="lang.search"
            :class="{ 'p-invalid': supplierEndError }"
          />
          <small
            v-if="supplierEndError"
            id="supplierEnd-help"
            class="p-error"
            >{{ lang.validateSupplierEndNoSelected }}</small
          >
        </div> -->
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="reportTypes">{{ lang.reportPortraitOrLandscape }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="reportTypes"
            optionLabel="name"
            optionValue="code"
            v-model="selectReportType"
            @change="setReportType"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText
            id="fileNameText"
            type="text"
            class="inputfield w-full mt-1"
            v-model="fileNameText"
          />
        </div>
      </section>
    </transition>
    
    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
        </div>
      </div>
    </template>
    <!-- <div class="pt-6 pb-1">
      <PvButton
        icon="pi pi-file-pdf"
        :label="lang.downloadPDF"
        @click="downloadPDF"
        class="mr-2 mb-2"
      />
      <PvButton
        icon="pi pi-file-excel"
        :label="lang.downloadExcel"
        @click="downloadExcel"
        class="mr-2 mb-2"
      />
      <PvButton
        icon="pi pi-file-excel"
        :label="lang.downloadExcelRaw"
        @click="downloadExcelRaw"
        class="mr-2 mb-2"
      />
      <PvButton
        icon="pi pi-times"
        :label="lang.close"
        @click="closeDialog"
        class="mr-2 mb-2"
      />
    </div> -->
  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import useGetFormPrint from "../../hooks/getFormPrint.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useTracking from "../../hooks/tracking";
import useReportRequestValidate from "../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { getPreviewBlob } = useGetFormPrint();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getPreviewBlob,
      addReportTracking,
      reportPrintOrder
    };
  },
  data() {
    return {
      selectBookCode: "",
      bookCodeError: false,

      selectSupplierStart: null,
      selectStartReady: false,

      selectSupplierEnd: null,
      selectEndReady: false,

      dateStart: null,
      dateEnd: null,

      reportTypes: [
        { name: this.lang.portrait, code: "Portrait" },
        { name: this.lang.landscape, code: "Landscape" },
      ],
      selectReportType: "Portrait",
      type: "PDF",
      fileNameText: "AccountPayableSummary",
    };
  },
  async created() {
    this.initiateData();
    let date = new Date();
    this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
    this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    await this.setBookAndSupplier();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.initiateData();
      this.$emit("close");
    },
    supplierStartReady() {
      this.selectStartReady = true;
    },
    supplierEndReady() {
      this.selectEndReady = true;
    },
    setReady() {
      if (this.selectStartReady && this.selectEndReady) {
        this.setLoading(false);
      }
    },
    initiateData() {
      let date = new Date();
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      this.selectBookCode = "";
      this.bookCodeError = false;
      this.selectSupplierStart = null;
      this.selectStartReady = false;
      this.selectSupplierEnd = null;
      this.selectEndReady = false;
      this.selectReportType = "Portrait";
      this.type = "PDF";
      this.fileNameText = "AccountPayableSummary";
    },
    async setBookAndSupplier() {
      this.setLoading(true);

      let moduleCode = "";
      if (this.reportObj.sequence == 1) {
        moduleCode = "PR";
      } else if (this.reportObj.sequence == 2) {
        moduleCode = "PO";
      } else if (this.reportObj.sequence == 3) {
        moduleCode = "PA,PI,PN,PV";
      } else {
        moduleCode = "PT,PZ";
      }

      await this.$store.dispatch("shared/resetAPBook");
      await this.$store.dispatch("shared/getAllAPBooks", moduleCode);

      if (this.apBookselect) {
        this.selectBookCode = this.apBookselect[0].bookCode;
      }

      this.setLoading(false);
    },
    
    async download(typeReport) {
      this.type = typeReport;
      if (this.validateData()) {
        return;
      }
      this.setLoading(true);
      try {
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);       
        // let request = {
        //   request: JSON.stringify(this.payload),
        // };
        // console.log(request);
        // await this.$store.dispatch("produces/addPrintOrder", request);
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }

        // console.log(this.payload);
        // await this.$store.dispatch("shared/printReport", this.payload);        
        // this.getPreviewBlob(this.fileBlob);
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
    validateData() {
      if (this.selectBookCode === "") {
        this.$swal({
          icon: "warning",
          title: this.lang.validateGlBookSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.bookCodeError = true;
          }
        });
        return true;
      }

      return false;
    },
    setReportType(){
      if (this.selectReportType == "Portrait") {
        this.fileNameText = "AccountPayableSummary";
      } else {
        this.fileNameText = "AccountPayableDetail";
      }
    },
  },
  computed: {
    payload() {
      let payload = {
        FileName: this.fileNameText + this.fileType,
        JasperPath: this.jasperPath + this.fileType,
        DocType: this.type,
        UserId: this.userId,
        PrintBy: this.userName,
          ProjectId: this.selectedProject.id,
          BookCode: this.selectBookCode,
          DateStart: this.selectedDateStart,
          DateEnd: this.selectedDateEnd,
          SupplierStart:
            this.selectSupplierStart != null ? this.selectSupplierStart.code : "",
          SupplierEnd:
            this.selectSupplierEnd != null ? this.selectSupplierEnd.code : "",
          PaperType: this.selectReportType,
        RequestId: 0,
        ReportName: "ReportAccountPayable",
      };

      // let parameter = {     
      //   ProjectId: this.selectedProject.id,
      //   BookCode: this.selectBookCode,
      //   DateStart: this.selectedDateStart,
      //   DateEnd: this.selectedDateEnd,
      //   SupplierStart:
      //     this.selectSupplierStart != null ? this.selectSupplierStart.code : "",
      //   SupplierEnd:
      //     this.selectSupplierEnd != null ? this.selectSupplierEnd.code : "",
      //   PaperType: this.selectReportType,
      // };

      // let payload = {
      //   docType: this.type,
      //   path: this.jasperPath + this.fileType,
      //   fileName: this.fileName,
      //   parameter: JSON.stringify(parameter),
      // };

      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
    reportName() {
      if (this.lang.language === "Thai") {
        return this.reportObj.nameThai;
      } else {
        return this.reportObj.nameEnglish;
      }
    },
    apBooks() {
      return this.$store.getters["shared/apBooks"];
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    apBookselect() {
      let apbookCode = [];
      if (this.apBooks) {
        this.apBooks.forEach((data) => {
          let book = {
            bookCode: data.bookCode,
            bookName: data.bookCode + " : " + data.bookNameTH,
          };
          apbookCode.push(book);
        });
      }
      return apbookCode;
    },
    // supplierListStart() {
    //     console.log("suppliers=", this.suppliers);
    //     let supStart = this.$store.getters["shared/supplierSuppliers"];
    //     return supStart.sort((a,b) => a.code > b.code);
    // },
    // supplierListEnd() {
    //     console.log("suppliers=", this.suppliers);
    //     let supEnd = this.$store.getters["shared/supplierSuppliers"];
    //     return supEnd.sort((a,b) => a.code < b.code ? 1:-1);
    // },
    fileType() {
      let fileType = this.type == "PDF" ? ".pdf" : ".xlsx";
      return fileType;
    },
    jasperPath() {
      let result = this.reportObj.path + "_refund";

      if (this.selectReportType == "Portrait") {
        result = this.reportObj.path + "_summary";
      }else{
        result = this.reportObj.path + "_detail";
      }

      if(this.type == "EXCEL" ){
        result = this.reportObj.path + "_excel";      
      }

      return result;
    },
    fileName() {      
      let result = "ReportPettyCash";
      if (this.selectReportType == "Portrait") {
        result = "AccountPayableSummary";
      } else {
        result = "AccountPayableDetail";
      }
      
      return result ;
    },
    fileBlob() {
      return this.$store.getters["shared/blob"];
    },
  },
  watch: {
    reportObj() {
      this.initiateData();
      this.setBookAndSupplier();
    },
    selectBookCode() {
      this.bookCodeError = false;
    },
  },
};
</script>
