<template>
  <base-toolbar
    :title="lang.titleToolbar"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoSave="save"
    :hideBackButton="isHideBack"
  ></base-toolbar>
  <accounting-policy-item-dialog
    v-model:visible="showAccountingPolicyItemDialog"
    @close="showAccountingPolicyItemDialog = false"
    @accounting-policy-item="addEditAccountingPolicyItem"
    :selectedAccountingPolicy="selectedAccountingPolicy"
    :openFrom="openFrom"
  />
  <accounting-policy-copy-dialog
    v-model:visible="showAccountingPolicyCopyDialog"
    @close="showAccountingPolicyCopyDialog = false"
    @accounting-policy-item-copy="accountingPolicyItemCopy"
    :details="details"
    :documentBookId="documentBookId"
    :openFrom="openFrom"
  />

  <base-dialog
    v-model:visible="showValidateDialog"
    :titleConfirm="titleConfirm"
    :classTitle="baseDialogClassTitle"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    @confirm="showValidateDialog = false"
    @close="showValidateDialog = false"
    @gotoChangeDocumentBook="changeDocumentBook"
  ></base-dialog>

  <base-container>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{ lang.title }}</label>

      <PvDivider></PvDivider>

      <div class="flex flex-wrap mb-1" :class="textFormClass">
        <!--*** ฝั่งซ้าย ***-->
        <div class="lg:col-3 md:col-12 col-12 pl-0 pr-0">
          <div
            class="lg:col-12 surface-card flex justify-content-between pr-0 pl-0"
          >
            <div class="w-full">
              <span class="p-input-icon-left w-full">
                <i class="pi pi-search" />
                <PvInputText
                  class="inputfield w-full"
                  v-model="filterSearchText['global']"
                  :placeholder="lang.searchTextPlaceholder"
                  @keyup.enter="getDocumentBooks"
                  autofocus
                />
              </span>
            </div>
            <!-- <div>
              <PvButton @click="getDocumentBooks">
                <span class="font-normal">{{ lang.search }}</span>
              </PvButton>
            </div> -->
          </div>
          <div class="w-full">
            <div class="scrollable-div mt-1">
              <PvTreeTable
                :value="setDocumentBooks"
                :filters="filterSearchText"
                selectionMode="single"
                @nodeSelect="documentBookSelected"
                filterMode="lenient"
              >
                <template #header>
                  <label class="font-md font-normal">{{
                    lang.titleDocumentBook
                  }}</label>
                </template>
                <PvColumn field="codeAndName" :expander="true"> </PvColumn>
              </PvTreeTable>
            </div>
          </div>
        </div>
        <!--*** ฝั่งซ้าย ***-->
        <!--*** ฝั่งขวา ***-->
        <div class="lg:col-9 md:col-12 col-12 pl-0 pr-0 flex flex-wrap">
          <div class="lg:col-1 md:col-0 col-0 pl-0 pr-0">
            <PvDivider layout="vertical" class="divider-layout pl-0 pr-0">
            </PvDivider>
          </div>
          <div class="lg:col-11 md:col-12 col-12 pl-0 pr-0">
            <div class="w-full pt-3">
              <label class="font-md font-normal">{{
                lang.accountingDetail
              }}</label>
            </div>
            <PvDivider></PvDivider>

            <div
              class="w-full text-center "
              style="margin-top: 10rem"
              v-if="isDataEmpty"
            >
              <label class="font-normal">{{ lang.dataEmpty }}</label>
            </div>
            <div v-else>
              <section class="flex flex-wrap mb-1" :class="textFormClass">
                <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4">
                  <label for="documentType">{{ lang.documentType }}</label>
                  <PvInputText
                    id="documentType"
                    type="text"
                    class="inputfield w-full mt-1 p-inputtext-disabled"
                    v-model="documentType"
                    disabled
                  />
                </div>

                <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4">
                  <label for="documentBook">{{ lang.documentBook }}</label>
                  <PvInputText
                    id="documentBook"
                    type="text"
                    class="inputfield w-full mt-1 p-inputtext-disabled"
                    v-model="documentBook"
                    disabled
                  />
                </div>
              </section>

              <div class="flex flex-wrap justify-content-between mt-3">
                <label class="font-lg font-normal">{{ lang.title }}</label>
                <div>
                  <PvButton
                    class="p-button-outlined ml-2"
                    @click="openCopyAccounting()"
                  >
                    <icon-copy iconColor="primary" width="16" height="16" />
                    <span class="p-button-label font-normal ml-1">{{
                      lang.copyAccountingPolicy
                    }}</span>
                  </PvButton>
                  <PvButton
                    class="p-button-outlined ml-2"
                    @click="openAddAccountingItem()"
                  >
                    <icon-plus-add iconColor="primary" width="16" height="16" />
                    <span class="p-button-label font-normal ml-1">{{
                      lang.createCaption
                    }}</span>
                  </PvButton>
                </div>
              </div>

              <div class="p-datatable-border my-2">
                <PvDataTable
                  :value="details"
                  v-model:selection="selectedAccountingPolicy"
                  selectionMode="single"
                  dataKey="id"
                  responsiveLayout="scroll"
                  class="p-datatable-xs"
                  :class="textTableClass"
                  :loading="isLoading"
                  @row-dblclick="
                    openEditAccountingItem(this.selectedAccountingPolicy)
                  "
                  scrollable
                >
                  <PvColumn
                    field="item"
                    :header="lang.columnItem"
                    style="max-width: 4rem; height: 46px"
                    headerClass="h-left"
                    bodyClass="text-center"
                  />
                  <PvColumn
                    field="nameTh"
                    :header="lang.columnLinkFunction"
                    style="min-width: 15rem"
                    headerClass="h-left"
                    bodyClass="text-left"
                  />
                  <PvColumn
                    field="debitCredit"
                    :header="lang.columnDebitCredit"
                    style="min-width: 5rem"
                    headerClass="h-left"
                    bodyClass="text-left"
                  >
                    <template #body="slotProps">
                      <span
                        class="text-center w-full"
                        v-if="slotProps.data.debitCredit == 'D'"
                      >
                        {{ this.lang.debit }}
                      </span>
                      <span class="text-center w-full" v-else>
                        {{ this.lang.credit }}
                      </span>
                    </template>
                  </PvColumn>
                  <PvColumn
                    field="accountCode"
                    :header="lang.columnAccountCode"
                    style="min-width: 8rem"
                    headerClass="h-left"
                    bodyClass="text-left"
                  />
                  <PvColumn
                    field="accountName"
                    :header="lang.columnAccountName"
                    style="min-width: 20rem"
                    headerClass="h-left"
                    bodyClass="text-left"
                  />

                  <PvColumn
                    style="min-width: 6rem"
                    headerClass="h-center"
                    bodyStyle="text-align: center; justify-content: center; align-items: center;"
                    alignFrozen="right"
                    frozen
                  >
                    <template #body="slotProps">
                      <PvButton
                        type="button"
                        class="p-button-secondary p-button-text p-0 mr-3"
                        @click="openEditAccountingItem(slotProps.data)"
                      >
                        <icon-pen-edit
                          iconColor="secondary"
                          width="24"
                          height="24"
                        />
                      </PvButton>

                      <PvButton
                        type="button"
                        class="p-button-secondary p-button-text p-0"
                        @click="deleteRow(slotProps.data.id)"
                      >
                        <icon-trash-delete-bin
                          iconColor="secondary"
                          width="24"
                          height="24"
                        />
                      </PvButton>
                    </template>
                  </PvColumn>

                  <template #empty>
                    <div class="w-full p-3" style="text-align: center">
                      <label>{{ this.lang.tableEmpty }}</label>
                    </div>
                  </template>
                </PvDataTable>
              </div>
            </div>
          </div>
          <!--*** ฝั่งขวา ***-->
        </div>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import usePageDirty from "../../../hooks/pageDirty";
import AccountingPolicyItemDialog from "../../../components/master/accountingPolicy/AccountingPolicyItemDialog.vue";
import AccountingPolicyCopyDialog from "../../../components/master/accountingPolicy/AccountingPolicyCopyDialog.vue";
export default {
  props: ["projectCode"],
  components: {
    AccountingPolicyItemDialog,
    AccountingPolicyCopyDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.accountingPolicy,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.accountingPolicy,
        };
      }
    });

    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();
    const { compareObject, isCompareObject } = usePageDirty();
    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      isHideBack,
      compareObject,
      isCompareObject,
    };
  },
  data() {
    return {
      filterSearchText: {},
      isDataEmpty: true,
      documentType: "",
      documentBookId: "",
      documentBook: "",
      selectedAccountingPolicy: null,
      isLoading: false,
      details: [],
      showAccountingPolicyItemDialog: false,
      showAccountingPolicyCopyDialog: false,
      openFrom: "",
      objToCompare: null,
      selectedDocumentBook: null,

      //dialog
      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: this.lang.notificationDialogHeader,
      validateWording: "",
      dataButtonDialog: [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ],
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    await this.roleByMenuDataReady();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    async initiateData() {
      this.isDataEmpty = true;
      this.documentType = "";
      this.documentBookId = "";
      this.documentBook = "";
      this.selectedAccountingPolicy = null;
      this.details = [];
      this.showAccountingPolicyItemDialog = false;
      this.showAccountingPolicyCopyDialog = false;
      this.openFrom = "";
      this.objToCompare = null;
      this.selectedDocumentBook = null;
      this.baseDialogClassTitle = "";
      await this.$store.dispatch("accountingPolicy/resetAccountingPolicys");
    },
    async roleByMenuDataReady() {
      if (this.isSharedRoleByMenuReady) {
        await this.getDocumentBooks();
      }
    },
    async setCompareData() {
      let details = [];
      for (const each of this.details.filter((each) => each.linkFunction)) {
        let detail = null;
        if (each.id.includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        details.push(detail);
      }

      let objToCompare = {
        projectId: this.selectedProject.id,
        documentBookId: this.documentBookId,
        details: details,
        type: "Add",
      };

      if (this.accountingPolicys.length > 0) {
        objToCompare = { ...objToCompare, type: "Update" };
      }

      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
    },
    async getDocumentBooks() {
      this.setLoading(true);
      try {
        await this.$store.dispatch(
          "accountingPolicy/getAllDocumentBooks",
          this.selectedProject.id
        );
      } catch (error) {
        this.openAlertDialogError(error);
      }
      this.setLoading(false);
    },
    async documentBookSelected(node) {
      let result = false;
      this.baseDialogClassTitle = "";
      if (this.objToCompare) {
        result = this.isCompareObject(this.objToCompare, this.payload);
      }
      if (result) {
        this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.cancel,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "gotoChangeDocumentBook",
            caption: this.lang.confirm,
            class: "w-full",
            action: "gotoChangeDocumentBook",
          },
        ];

        this.titleConfirm = this.lang.changeDocumentBook;
        this.validateWording = [
          this.lang.askForNewDocumentBook,
          this.lang.askForNewDocumentBookConfirm,
        ];
        this.selectedDocumentBook = node.data;
        this.showValidateDialog = true;
      } else {
        this.initiateData();
        this.selectedDocumentBook = node.data;
        this.changeDocumentBook();
      }
    },
    async changeDocumentBook() {
      this.showValidateDialog = false;
      this.details = [];
      this.objToCompare = null;
      if (this.selectedDocumentBook.header) {
        this.isDataEmpty = true;
      } else {
        this.isDataEmpty = false;
        await this.setSelectedAccountingPolicy();
      }
    },
    async setSelectedAccountingPolicy() {
      this.documentType =
        this.selectedDocumentBook.documentTypeCode +
        " " +
        this.selectedDocumentBook.documentTypeName;
      this.documentBook =
        this.selectedDocumentBook.code + " " + this.selectedDocumentBook.name;
      this.documentBookId = this.selectedDocumentBook.id;

      await this.getAccountingPolicys(this.selectedDocumentBook.id);
      this.accountingPolicys.forEach((element) => {
        const result = { ...element };
        this.details.push(result);
      });
      await this.setCompareData();
    },
    async getAccountingPolicys(documentBook) {
      this.isLoading = true;
      try {
        await this.$store.dispatch(
          "accountingPolicy/getAccountingPolicys",
          documentBook
        );
      } catch (error) {
        this.openAlertDialogError(error);
      }
      this.isLoading = false;
    },
    validateData() {
      this.dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.notificationDialogHeader;

      if (!this.documentBookId) {
        this.validateWording = this.lang.validateNoDocumentBook;
        this.showValidateDialog = true;
        return true;
      }

      // if (!this.details || this.details.length <= 0) {
      //   this.validateWording = this.lang.validateNoDetailSelect;
      //   this.showValidateDialog = true;
      //   return true;
      // }

      return false;
    },
    async save() {
      this.dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];

      this.titleConfirm = this.lang.notificationDialogHeader;

      if (this.accountingPolicys && this.accountingPolicys.length > 0) {
        if (!this.isRoleUpdate) {
          this.baseDialogClassTitle = "";
          this.validateWording = this.lang.validateRoleUpdate;
          this.showValidateDialog = true;
          return true;
        }
      } else {
        if (!this.isRoleCreate) {
          this.baseDialogClassTitle = "";
          this.validateWording = this.lang.validateRoleInsert;
          this.showValidateDialog = true;
          return true;
        }
      }

      if (this.validateData()) {
        return;
      }

      this.setLoading(true);

      try {
        await this.$store.dispatch(
          "accountingPolicy/createOrUpdateAccountingPolicy",
          this.payload
        );
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.saveSuccess;
        this.validateWording = this.lang.saveAccountingPolicySuccess;
        this.showValidateDialog = true;

        await this.setCompareData();
      } catch (error) {
        this.openAlertDialogError(error);
      }
      this.setLoading(false);
    },
    openAddAccountingItem() {
      // if (!this.isRoleCreate) {
      //   this.dataButtonDialog = [
      //     {
      //       id: "close",
      //       caption: this.lang.close,
      //       class: "p-button-outlined p-button-secondary w-full",
      //       action: "close",
      //     },
      //   ];

      //   this.baseDialogClassTitle = "";
      //   this.titleConfirm = this.lang.notificationDialogHeader;
      //   this.validateWording = this.lang.validateRoleInsert;
      //   this.showValidateDialog = true;
      //   return true;
      // }

      this.selectedAccountingPolicy = null;
      this.openFrom = new Date().getSeconds().toString();
      this.showAccountingPolicyItemDialog = true;
    },
    openEditAccountingItem(data) {
      // if (!this.isRoleUpdate) {
      //   this.dataButtonDialog = [
      //     {
      //       id: "close",
      //       caption: this.lang.close,
      //       class: "p-button-outlined p-button-secondary w-full",
      //       action: "close",
      //     },
      //   ];

      //   this.baseDialogClassTitle = "";
      //   this.titleConfirm = this.lang.notificationDialogHeader;
      //   this.validateWording = this.lang.validateRoleUpdate;
      //   this.showValidateDialog = true;
      //   return true;
      // }

      this.selectedAccountingPolicy = data;
      this.openFrom = new Date().getSeconds().toString();
      this.showAccountingPolicyItemDialog = true;
    },
    addEditAccountingPolicyItem(data) {
      this.updateDetails(data);
    },
    updateDetails(data) {
      if (data) {
        this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
        ];

        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = `${this.lang.askItemDuplicate.replace(
          ":code",
          data.linkFunction + ":" + data.nameTh
        )}`;

        if (data.id) {
          const checkDuplicate = this.details.find(
            (e) => e.linkFunction === data.linkFunction && e.id != data.id
          );
          if (checkDuplicate) {
            this.showValidateDialog = true;
            return true;
          }

          const detail = this.details.find((detail) => detail.id === data.id);
          if (detail) {
            detail.linkFunction = data.linkFunction;
            detail.nameTh = data.nameTh;
            detail.nameEn = data.nameEn;
            detail.description = data.description;
            detail.accountId = data.accountId;
            detail.accountCode = data.accountCode;
            detail.accountName = data.accountName;
            detail.debitCredit = data.debitCredit;
          }
        } else {
          let detail = {
            id: "dummy" + (parseInt(this.details.length) + 1),
            item: parseInt(this.details.length) + 1,
            linkFunction: data.linkFunction,
            nameTh: data.nameTh,
            nameEn: data.nameEn,
            description: data.description,
            accountId: data.accountId,
            accountCode: data.accountCode,
            accountName: data.accountName,
            debitCredit: data.debitCredit,
          };

          if (!this.details || this.details.length <= 0) {
            this.details.push(detail);
          } else {
            const checkDuplicate = this.details.find(
              (e) => e.linkFunction === data.linkFunction
            );
            if (!checkDuplicate) {
              this.details.push(detail);
            } else {
              this.showValidateDialog = true;
              return true;
            }
          }
        }
      }
      this.updateItemNo();
    },
    deleteRow(id) {
      // if (!this.isRoleDelete) {
      //   this.dataButtonDialog = [
      //     {
      //       id: "close",
      //       caption: this.lang.close,
      //       class: "p-button-outlined p-button-secondary w-full",
      //       action: "close",
      //     },
      //   ];

      //   this.baseDialogClassTitle = "";
      //   this.titleConfirm = this.lang.notificationDialogHeader;
      //   this.validateWording = this.lang.validateRoleDelete;
      //   this.showValidateDialog = true;
      //   return true;
      // }

      const index = this.details.findIndex((detail) => detail.id === id);
      this.details.splice(index, 1);

      this.updateItemNo();
    },
    updateItemNo() {
      let number = 1;
      this.details.forEach((element) => {
        if (element.linkFunction) {
          element.item = number;
          number++;
        }
      });
    },
    openCopyAccounting() {
      this.openFrom = new Date().getSeconds().toString();
      this.showAccountingPolicyCopyDialog = true;
    },
    accountingPolicyItemCopy(data){
      this.details = data;
    },
    openAlertDialogError(error) {
      this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
        ];

      this.isVisibleCloseButton = false;
      this.baseDialogClassTitle = "p-error";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.validateWording = error.message;
      this.showValidateDialog = true;
    },
  },
  computed: {
    payload() {
      let details = [];
      for (const each of this.details.filter((each) => each.linkFunction)) {
        let detail = null;
        if (each.id.includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        details.push(detail);
      }

      let payload = {
        projectId: this.selectedProject.id,
        documentBookId: this.documentBookId,
        details: details,
        type: "Add",
      };

      if (this.accountingPolicys.length > 0) {
        payload = { ...payload, type: "Update" };
      }
      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("Gll");
    },
    isRoleCreate() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRoleUpdate() {
      let result = false;
      if (this.roleByMenu && this.roleByMenu.update == "Y") {
        result = true;
      }
      return result;
    },
    isRoleDelete() {
      let result = false;
      if (this.roleByMenu && this.roleByMenu.delete == "Y") {
        result = true;
      }
      return result;
    },
    documentBooks() {
      return this.$store.getters["accountingPolicy/documentBooks"];
    },
    groupDocumentTypes() {
      let documentTypes = [];
      if (this.documentBooks) {
        let number = 1;
        this.documentBooks.forEach((item) => {
          const result = { ...item };
          if (number == 1) {
            documentTypes.push(result);
          } else {
            const documentType = documentTypes.find(
              (d) => d.documentTypeId === item.documentTypeId
            );
            if (!documentType) {
              documentTypes.push(result);
            }
          }
          number++;
        });
      }
      return documentTypes;
    },
    // setDocumentBooks() {
    //   let documentBooks = [];
    //   if (this.documentBooks && this.groupDocumentTypes) {
    //     this.groupDocumentTypes.forEach((item) => {
    //       const result = {
    //         codeAndName: item.documentTypeCode + " " + item.documentTypeName,
    //       };
    //       let addParams = {
    //         key: item.documentTypeId,
    //         data: result,
    //       };

    //       let documentBook = this.documentBooks.filter(
    //         (e) => e.documentTypeId == item.documentTypeId
    //       );
    //       if (documentBook.length > 0) {
    //         let children = [];
    //         documentBook.forEach((data) => {
    //           const datas = {
    //             ...data,
    //             codeAndName: data.code + " " + data.name,
    //           };
    //           let addParam = {
    //             key: item.id,
    //             data: datas,
    //           };
    //           children.push(addParam);
    //         });
    //         addParams = { ...addParams, children: children };
    //       }
    //       documentBooks.push(addParams);
    //     });
    //   }

    //   return documentBooks;
    // },

    setDocumentBooks() {
      let documentBooks = [];
      if (this.documentBooks && this.groupDocumentTypes) {
        this.groupDocumentTypes.forEach((item) => {
          const result = {
            ...item,
            codeAndName: item.documentTypeCode + " " + item.documentTypeName,
            header: true,
          };
          let addParams = {
            key: item.documentTypeId,
            data: result,
          };

          let children = [];
          this.documentBooks.forEach((e) => {
            const data = {
              ...e,
              codeAndName: e.code + " " + e.name,
              header: false,
            };
            if (e.documentTypeId == item.documentTypeId) {
              let addParam = {
                key: item.id,
                data: data,
              };
              children.push(addParam);
            }
          });
          addParams = { ...addParams, children: children };
          documentBooks.push(addParams);
        });
      }
      return documentBooks;
    },
    accountingPolicys() {
      return this.$store.getters["accountingPolicy/accountingPolicys"];
    },
    // saveEditSuccess() {
    //   let result = this.lang.saveSuccess;
    //   if (this.accountingPolicys.length > 0) {
    //     result = this.lang.saveEditSuccess;
    //   }
    //   return result;
    // },
    dataButtons() {
      let dataButtons = [
        {
          id: "save",
          caption: this.lang.save,
          class: "mr-2",
          icon: "icon-save",
          iconColor: "white",
          action: "gotoSave",
          isShow: this.documentBookId,
        },
      ];
      return dataButtons;
    },
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.roleByMenuDataReady();
    },
    payload(curValue) {
      if (this.objToCompare) {
        this.compareObject(this.objToCompare, curValue);
      }
    },
  },
};
</script>

<style scoped>
.scrollable-div {
  height: 450px;
  overflow: auto;
  text-align: justify;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
