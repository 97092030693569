export default {
  async getSignatureManagements(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/SignatureManagement";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.projectId) {
			url = url + "&projectId=" + payload.projectId;
		}

		url = encodeURI(url);
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
        // console.log(responseData);
        const error = new Error(responseData.message || "Failed to get signature management");
        throw error;
    }

    context.commit("setSignatureManagements", responseData);
  },
  async getSignatureManagementById(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/SignatureManagement/${payload.projectId}/${payload.id}`;
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
        // console.log(responseData);
        const error = new Error(responseData.message || `Failed to get signature management id : ${payload}`);
        throw error;
    }

    context.commit("setSelectedSignatureManagement", responseData.data);
  },
  resetSignatureManagement(context) {
    context.commit("setSelectedSignatureManagement", null);
  },
  async createSignatureManagement(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + "/SignatureManagement/Add";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw responseData;
    }

    context.commit("setSelectedSignatureManagement", responseData.data);
  },
  async updateSignatureManagement(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + `/SignatureManagement/${payload.id}/Update`;
    url = encodeURI(url);
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw responseData;
    }
    // console.log(responseData);

    context.commit("setSelectedSignatureManagement", responseData.data);
  },
};
