export default {
  purchaseRequisitions(state) {
    return state.purchaseRequisitions;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
  selectedPurchaseRequisition(state) {
    return state.selectedPurchaseRequisition;
  },
  blob(state) {
    return state.blob;
  },

};
