import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export default {
    namespaced: true,
    state(){
        return {
            phoneBooks: [],
            selectedPhoneBook: null,
            totalPage: 0,
            currentPage: 0,
            rows: 100,

            contactTypes: [],
            contactTypeTotalPage: 0,
            contactTypeCurrentPage: 0,
            
            phoneTypes: [],
            phoneTypeTotalPage: 0,
            phoneTypeCurrentPage: 0,
        };
    },
    actions,
    getters,
    mutations
}