<template>
  <base-container>
    <base-dialog
      v-model:visible="showConfirmDialog"
      :classTitle="confirmClassTitle"
      :titleConfirm="confirmTitle"
      :wordingConfirm="confirmContent"
      @confirm="closeAlertDialog"
      @close="closeAlertDialog"
    ></base-dialog>
    <base-toast-report-request
      :showToast="showToast"
      :toastMessage="toastMessage"
      :toastLink="toastLink"
      :actionToast="actionToast"
    ></base-toast-report-request>
    <div
      class="flex justify-content-between align-items-center"
      style="padding-top: 20px"
    >
      <div class="my-3">
        <PvButton
          icon="pi pi-chevron-left"
          :label="lang.back"
          @click="goBack"
          class="mr-2 p-button-secondary"
          v-if="isHideBack"
        />
      </div>
      <h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
    </div>
    <div class="surface-card shadow-2 p-3 border-round">
      <div class="flex flex-wrap justify-content-between align-items-center mb-5">
        <section class="flex flex-wrap lg:col-6 md:col-6 col-12" :class="textFormClass">
          <label for="searchText">{{ lang.searchText }}</label>
          <div class="p-inputgroup">
            <PvInputText
              id="searchText"
              type="text"
              class="inputfield w-full"
              :placeholder="lang.search"
              v-model="searchText"
              @keyup="onSearchInputKeyUp"
            />
            <PvButton icon="pi pi-search" @click="getReportLists()" />
          </div>
        </section>

        <div class="lg:col-4 md:col-4 col-12">
          <span class="p-buttonset" style="float: right">
            <PvButton
              icon="pi pi-th-large"
              class="p-button-secondary"
              @click="
                gridMenu = true;
                listMenu = false;
              "
              v-model="gridMenu"
            />
            <PvButton
              icon="pi pi-list"
              class="p-button-secondary"
              @click="
                listMenu = true;
                gridMenu = false;
              "
              v-model="listMenu"
            />
          </span>
        </div>
      </div>

      <!-- <PvDataTable
				showGridlines
				:value="reportLists"
				v-model:selection="selectedReport"
				selectionMode="single"
				dataKey="id"
				responsiveLayout="scroll"
				:stripedRows="true"
				class="p-datatable-sm"
				:class="textTableClass"
				:loading="isLoading"
				v-if="!gridMenu"
				:row="rowsPerPage"
				:paginator="true"
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				:rowsPerPageOptions="[10, 25, 50]"
				currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
			> -->
      <PvDataTable
        showGridlines
        v-if="!gridMenu"
        :value="reportLists"
        :paginator="true"
        class="p-datatable-sm"
        :class="textTableClass"
        :rows="rowsPerPage"
        dataKey="id"
        :rowHover="true"
        v-model:selection="selectedReport"
        selectionMode="single"
        :loading="isLoading"
        paginatorTemplate="CurrentPageReport    FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        :currentPageReportTemplate="lang.page + ` {currentPage} / {totalPages}`"
        :globalFilterFields="['name', 'country.name', 'representative.name', 'status']"
        responsiveLayout="scroll"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn
          field="sequence"
          :header="lang.columnSequence"
          style="width: 5%"
          headerClass="h-center"
          bodyStyle="text-align: center"
        ></PvColumn>
        <PvColumn
          field="nameThai"
          :header="lang.columnName"
          style="width: 65%"
          headerClass="h-center"
        ></PvColumn>
        <PvColumn
          style="width: 30%"
          headerClass="h-center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <PvButton
              icon="pi pi-search"
              @click="viewReport(slotProps.data)"
              :label="lang.viewhReportButton"
              class="p-button-sm"
            />
          </template>
        </PvColumn>
        <!-- <template #footer>
					<PvPaginator
						:rows="rowsPerPage"
						:totalRecords="totalRecords"
						@page="onPageChange($event)"
						:rowsPerPageOptions="[10, 50, 100]"
					>
						<template #start="slotProps">
							{{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /
							{{ totalPage }}
						</template>
					</PvPaginator>
				</template> -->
      </PvDataTable>

      <section class="flex flex-wrap" v-else>
        <div
          class="lg:col-3 md:col-6 col-12"
          v-for="reportList in reportLists"
          :key="reportList.id"
        >
          <PvCard>
            <template #content>
              <p :class="textCardTitleClass">
                {{ reportList.nameThai }}
              </p>
            </template>
            <template #footer v-if="gridMenu">
              <PvButton
                icon="pi pi-search"
                @click="viewReport(reportList)"
                :label="lang.viewhReportButton"
              />
            </template>
          </PvCard>
        </div>
      </section>
    </div>

    <!-- <base-report-petty-cash-modal
			v-model:visible="showReportPettyCash"
			@close="showReportPettyCash = false"
		></base-report-petty-cash-modal> -->
    <div v-for="(component, key) in allComponents" :key="key">
      <component
        :is="component"
        :key="component"
        :pathReport="pathReport"
        v-model:visible="showComponent"
        @close="closeComponent()"
        v-if="key === selectedComponent"
        :reportObj="reportObj"
        @toast-request="toastRequest"
      ></component>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import BaseReportPettyCashModal from "../../components/report/BaseReportPettyCash.vue";
import BaseReportAccountFrequentlyModal from "../../components/report/BaseReportAccountFrequently.vue";
import BaseReportChartOfAccountModal from "../../components/report/BaseReportChartOfAccount.vue";
import BaseReportLedgerAccountModal from "../../components/report/BaseReportLedgerAccount.vue";
import BaseReportTrialBalanceModal from "../../components/report/BaseReportTrialBalance.vue";
import BaseReportWorksheetModal from "../../components/report/BaseReportWorksheet.vue";
import BaseReportAccountingEntry from "../../components/report/BaseReportAccountingEntry.vue";
import BaseDocumentNoReference from "../../components/report/BaseDocumentNoReference.vue";
import BaseReportIncomeAndExpense from "../../components/report/BaseReportIncomeAndExpense.vue";
import BaseReportIncomeAndExpenseSummary from "../../components/report/BaseReportIncomeAndExpenseSummary.vue";
import BaseReportCompareIncomeAndExpense from "../../components/report/BaseReportCompareIncomeAndExpense.vue";
import BaseReportStatementOfFinancialPosition from "../../components/report/BaseReportStatementOfFinancialPosition.vue";
import BaseReportStatementOfFinancialPositionSummary from "../../components/report/BaseReportStatementOfFinancialPositionSummary.vue";
import BaseReportCompareStatementOfFinancialPosition from "../../components/report/BaseReportCompareStatementOfFinancialPosition.vue";
import BaseReportAssetWithDetail from "../../components/report/BaseReportAssetWithDetail.vue";
import BaseReportAssetAndDepreciationExpense from "../../components/report/BaseReportAssetAndDepreciationExpense.vue";
import BaseReportAccountPayable from "../../components/report/BaseReportAccountPayable.vue";
import BaseReportPayment from "../../components/report/BaseReportPayment.vue";
import BaseReportWithholdingTaxAttachmentPnd from "../../components/report/BaseReportWithholdingTaxAttachmentPnd.vue";
import BaseReportWithholdingTaxCoverPage from "../../components/report/BaseReportWithholdingTaxCoverPage.vue";
import BaseReportWithholdingTaxText from "../../components/report/BaseReportWithholdingTaxText.vue";
import BaseReportAccountPayableValidator from "../../components/report/BaseReportAccountPayableValidator.vue";
import BaseReportMeterUsageMonthly from "../../components/report/BaseReportMeterUsageMonthly.vue";
import ReportTax from "../../components/report/AR/ReportTax.vue";
import ReportPrepaidDetail from "../../components/report/AR/ReportPrepaidDetail.vue";
import ReportCreditNote from "../../components/report/AR/ReportCreditNote.vue";
import ReportNotice from "../../components/report/AR/ReportNotice.vue";
import ReportInvoiceDetail from "../../components/report/AR/ReportInvoiceDetail.vue";
import ReportPrepaidUsed from "../../components/report/AR/ReportPrepaidUsed.vue";
import ReportPrepaidMovement from "../../components/report/AR/ReportPrepaidMovement.vue";
import ReportPenalty from "../../components/report/AR/ReportPenalty.vue";
import ReportReceiptWithoutInvoice from "../../components/report/AR/ReportReceiptWithoutInvoice.vue";
import ReportOutStandingInvoiceMovement from "../../components/report/AR/ReportOutStandingInvoiceMovement.vue";
import ReportPayment from "../../components/report/AR/ReportPayment.vue";
import ReportReportSummaryReceiptByEmployee from "../../components/report/AR/ReportReportSummaryReceiptByEmployee.vue";
import ReportSummaryReceiveByCustomer from "../../components/report/AR/ReportSummaryReceiveByCustomer.vue";
import ReportReceiptSummaryByPayType from "../../components/report/AR/ReportReceiptSummaryByPayType.vue";
import ReportPrepaidSummary from "../../components/report/AR/ReportPrepaidSummary.vue";
import ReportRefundAdvancePayment from "../../components/report/AR/ReportRefundAdvancePayment.vue";
import ReportBillSettlementByInvoice from "../../components/report/AR/ReportBillSettlementByInvoice.vue";
import ReportReceiptSummary from "../../components/report/AR/ReportReceiptSummary.vue";
import ReportDepositSummary from "../../components/report/AR/ReportDepositSummary.vue";
import ReportDepositUsingByUnit from "../../components/report/AR/ReportDepositUsingByUnit.vue";
import ReportReceiptRegular from "../../components/report/AR/ReportReceiptRegular.vue";
import ReportOutstandingAging from "../../components/report/AR/ReportOutstandingAging.vue";
import ReportBillReceivableNotice from "../../components/report/AR/ReportBillReceivableNotice.vue";
import ReportSummaryOfOutstandingItems from "../../components/report/AR/ReportSummaryOfOutstandingItems.vue";
import ReportReceiptCashControl from "../../components/report/AR/receipt/ReportReceiptCashControl.vue";
import ReportReceiptDetailAndCancel from "../../components/report/AR/receipt/ReportReceiptDetailAndCancel.vue";
import ReportBankStatementAudit from "../../components/report/AR/ReportBankStatementAudit.vue";
import ReportDebtFreeFollowUp from "../../components/report/DebtFree/ReportDebtFreeFollowUp.vue";
import ReportForeignOwnershipRatio from "../../components/report/DebtFree/ReportForeignOwnershipRatio.vue";
import ReportGuaranteeMovement from "../../components/report/AR/ReportGuaranteeMovement.vue";
import ReportGuaranteeSummary from "../../components/report/AR/ReportGuaranteeSummary.vue";
import ReportNoticeByProduct from "../../components/report/AR/ReportNoticeByProduct.vue";
import ReportOutstandingAgingByMonth from "../../components/report/AR/ReportOutstandingAgingByMonth.vue";
import ReportStockKeycardMovement from "../../components/report/AR/stockKeycard/ReportStockKeycardMovement.vue";
import ReportStockKeycardDetail from "../../components/report/AR/stockKeycard/ReportStockKeycardDetail.vue";
import ReportStockKeycardStatusSummary from "../../components/report/AR/stockKeycard/ReportStockKeycardStatusSummary.vue";
import ReportBudgetCompareActual from "../../components/report/Budget/ReportBudgetCompareActual.vue";
import ReportPaymentRequestCoverSheet from "../../components/report/PaymentRequest/ReportPaymentRequestCoverSheet.vue";
export default {
  components: {
    BaseReportPettyCashModal,
    BaseReportAccountFrequentlyModal,
    BaseReportChartOfAccountModal,
    BaseReportLedgerAccountModal,
    BaseReportTrialBalanceModal,
    BaseReportWorksheetModal,
    BaseReportAccountingEntry,
    BaseDocumentNoReference,
    BaseReportIncomeAndExpense,
    BaseReportIncomeAndExpenseSummary,
    BaseReportCompareIncomeAndExpense,
    BaseReportStatementOfFinancialPosition,
    BaseReportStatementOfFinancialPositionSummary,
    BaseReportCompareStatementOfFinancialPosition,
    BaseReportAssetWithDetail,
    BaseReportAssetAndDepreciationExpense,
    BaseReportAccountPayable,
    BaseReportWithholdingTaxAttachmentPnd,
    BaseReportWithholdingTaxCoverPage,
    BaseReportWithholdingTaxText,
    BaseReportPayment,
    BaseReportAccountPayableValidator,
    BaseReportMeterUsageMonthly,
    ReportTax,
    ReportPrepaidDetail,
    ReportCreditNote,
    ReportNotice,
    ReportInvoiceDetail,
    ReportPrepaidUsed,
    ReportPrepaidMovement,
    ReportPenalty,
    ReportReceiptWithoutInvoice,
    ReportOutStandingInvoiceMovement,
    ReportPayment,
    ReportReportSummaryReceiptByEmployee,
    ReportSummaryReceiveByCustomer,
    ReportReceiptSummaryByPayType,
    ReportPrepaidSummary,
    ReportRefundAdvancePayment,
    ReportBillSettlementByInvoice,
    ReportReceiptSummary,
    ReportDepositSummary,
    ReportDepositUsingByUnit,
    ReportReceiptRegular,
    ReportOutstandingAging,
    ReportBillReceivableNotice,
    ReportSummaryOfOutstandingItems,
    ReportReceiptCashControl,
    ReportReceiptDetailAndCancel,
    ReportBankStatementAudit,
    ReportDebtFreeFollowUp,
    ReportForeignOwnershipRatio,
    ReportGuaranteeMovement,
    ReportGuaranteeSummary,
    ReportNoticeByProduct,
    ReportOutstandingAgingByMonth,
    ReportStockKeycardMovement,
    ReportStockKeycardDetail,
    ReportStockKeycardStatusSummary,
    ReportBudgetCompareActual,
    ReportPaymentRequestCoverSheet,
  },
  props: ["type", "projectCode"],
  setup() {
    const { lang } = useLang();
    const {
      textHeaderClass,
      textFormClass,
      textTableClass,
      textCardTitleClass,
    } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.gl.reportGl };
      } else {
        return { ...lang.en.base, ...lang.en.gl.reportGl };
      }
    });

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      textCardTitleClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      isHideBack,
    };
  },
  data() {
    return {
      searchText: null,
      selectedPage: 1,
      rowsPerPage: 10,
      gridMenu: false,
      listMenu: true,
      showReportPettyCash: false,
      showComponent: false,
      path: this.$route.path,
      reportLists: null,
      selectedComponent: null,
      pathReport: null,
      selectedReport: null,
      isLoading: true,
      reportObj: [],
      showConfirmDialog: false,
      confirmTitle: "",
      confirmContent: "",
      confirmClassTitle: "",

      showToast: 0,
      toastMessage: {},
      toastLink: "",
      actionToast: "",
    };
  },
  async created() {
    await this.getReportMenu();
    await this.getReportLists();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    async goBack() {
      await this.$store.dispatch("report/resetMenuDetail");

      if (this.$route.query && this.$route.query.back) {
        this.$router.push({ name: this.$route.query.back });
      } else {
        this.$router.push({name: "home"})
      }
    },
    onSearchInputKeyUp(event) {
      if (event.keyCode === 13) {
        this.getReportLists();
      }
    },
    viewReport(report) {
      let element = document.getElementsByTagName("body")[0];
      element.style.overflow = "hidden";
      // console.log(element);

      this.selectedComponent = report.component;
      this.pathReport = report.path;
      this.showComponent = true;
      this.reportObj = report;

      const today = new Date();
      let openFrom = today.getSeconds();
      let menuGroup = {
        menuGroupTh: this.reportMenu.nameThai,
        menuGroupEn: this.reportMenu.nameEnglish,
      }
      this.reportObj = { ...this.reportObj, openFrom, ...menuGroup };
    },
    closeComponent() {
      let element = document.getElementsByTagName("body")[0];
      element.style.overflow = "scroll";

      this.showComponent = false;
    },
    async getReportMenu() {
      try {
        await this.$store.dispatch("report/getReportMenu", {
          page: 1,
          limit: 100,
          where: this.reportMenuPayload,
        });
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.isLoading = false;
      }
    },
    async getReportLists() {
      if (!this.reportMenu) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: this.lang.reportNotfound,
          isConfirm: false,
        });
        this.isLoading = false;
        return;
      }
      try {
        let where = {
          reportMenuId: this.reportMenu.id,
          nameThai: this.searchText,
          category: "All",
        };
        const payload = {
          page: this.selectedPage,
          limit: 100,
          where,
        };
        await this.$store.dispatch("report/getAllReportMenuDetail", payload);

        this.reportLists = this.reportMenuDetails;
        this.isLoading = false;
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.isLoading = false;
      }
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getReportLists();
    },
    openAlertDialog(dataConfig) {
      if (dataConfig.severity === "") {
        this.confirmClassTitle = "";
      } else if (dataConfig.severity === "success") {
        this.confirmClassTitle = "p-text-success";
      } else if (dataConfig.severity === "error") {
        this.confirmClassTitle = "p-error";
      }

      this.isConfirm = dataConfig.isConfirm;
      this.confirmTitle = dataConfig.title;
      this.confirmContent = dataConfig.content;
      this.showConfirmDialog = true;
    },
    closeAlertDialog() {
      this.showConfirmDialog = false;
    },
    async getNewReport() {
      this.isLoading = true;
      await this.$store.dispatch("report/resetMenuDetail");
      await this.getReportMenu();
      await this.getReportLists();
    },
    toastRequest() {
      this.toastMessage = {
        severity: "info",
        summary: this.lang.toastRequestTitle,
        detail: this.lang.toastRequestContent,
        life: 5000,
      };
      this.actionToast = `/${this.selectedProject.code}/report/request?back=${this.$route.fullPath}`;
      this.toastLink = this.lang.toastRequestLink;

      const today = new Date();
			this.showToast = today.getSeconds();
    },
    // gotoRequest() {
    //   this.$router.push({name: "report-request", query: { back: this.$route.fullPath }});
    // },
  },
  computed: {
    title() {
      let result;
      if (this.reportMenu) {
        if (this.$store.getters.selectedLanguage === "TH") {
          result = this.reportMenu.nameThai;
        } else {
          result = this.reportMenu.nameEnglish;
        }
      } else {
        result = this.lang.title;
      }
      document.title = result + " | "+this.projectName+" | The LivingOS";
      return result;
    },
    reportMenuPayload() {
      let path = this.$route.name + this.type;
      let payload = {
        path: path.replaceAll("/", "").toUpperCase(),
      };
      return payload;
    },
    reportMenu() {
      return this.$store.getters["report/selectedReportMenu"];
    },
    allComponents() {
      return this.$options.components;
    },
    totalPage() {
      return this.$store.getters["report/totalPage"];
    },
    currentPage() {
      return this.$store.getters["report/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    reportMenuDetails() {
      return this.$store.getters["report/reportMenuDetail"];
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    selectedEmployee() {
      return this.$store.getters.employee;
    },
    projectName() {
      let result = "";
      if (this.selectedProject) {
        if (this.$store.getters.selectedLanguage === "TH") {
          result = this.selectedProject.nameTh.trim();
        } else {
          result = this.selectedProject.nameEn.trim();
        }
      }
      return result;
    },
  },
  watch: {
    type(curValue, oldValue) {
      // console.log(curValue, oldValue);
      if (curValue !== oldValue) {
        this.getNewReport();
        this.searchText = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-right: auto;
  }
}
</style>
