<template>
  <base-toolbar
    :title="lang.title"
    @goBack="goBack"
    :hideBackButton="isHideBack"
  ></base-toolbar>

  <base-dialog
    v-model:visible="showValidateDialog"
    :titleConfirm="titleConfirm"
    :classTitle="baseDialogClassTitle"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    @confirm="showValidateDialog = false"
    @close="showValidateDialog = false"
  ></base-dialog>

  <base-container>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.title }}</label>
      </div>

      <PvDivider></PvDivider>
      <section
        class="flex flex-wrap justify-content align-items-center"
      >
        <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            class="inputfield w-full"
            :options="selectStatus"
            optionLabel="name"
            optionValue="code"
            v-model="selectedStatus"
          />
        </div>
        <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            class="inputfield w-full"
            :options="selectType"
            optionLabel="name"
            optionValue="code"
            v-model="selectedType"
            @change="changeSelectType()"
          />
        </div>

        <div
          class="flex lg:col-6 md:col-12 col-12 pl-0 pr-0 mr-3"
          v-if="selectedType === 'date'"
        >
          <div class="col-6 pl-0 pr-2">
            <PvCalendar
              id="dateStart"
              v-model="dateStart"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
          <label for="dateEnd" class="pt-1 mt-3 mr-2">{{ "-" }}</label>
          <div class="col-6 pl-0 pr-2">
            <PvCalendar
              id="dateEnd"
              v-model="dateEnd"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
        </div>
        <div class="flex lg:col-6 md:col-12 col-12 pl-0 pr-0 mr-2" v-else>
          <div class="col-12 pl-0 pr-0">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchText"
                :placeholder="lang.searchTextPlaceholder"
                @keypress="checkInputSearch"
                autofocus
              />
            </span>
          </div>
        </div>

        <PvButton @click="getTransferSlips">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
        <!-- **************** -->
      </section>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="transferSlips"
          v-model:selection="selectedTransferSlip"
          selectionMode="single"
          dataKey="transferSlipId"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          :scrollHeight="halfViewportHeight + 'px'"
          @row-dblclick="gotoEdit(selectedTransferSlip.transferSlipId)"
          scrollable
        >
          <PvColumn
            field="status"
            :header="lang.columnStatus"
            style="min-width: 12rem; min-height: 45px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <base-tag
                :Caption="lang.receiptCreated"
                Class="success"
                v-if="slotProps.data.status == 'receiptCreated'"
              />
              <base-tag
                :Caption="lang.waiting"
                Class="warning"
                v-else-if="slotProps.data.status == 'waiting'"
              />
              <base-tag :Caption="lang.rejected" Class="error" v-else />
            </template>
          </PvColumn>
          <PvColumn
            field="losa_CreateDate"
            :header="lang.columnUploadDate"
            style="min-width: 6rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.losa_CreateDate) }}
            </template>
          </PvColumn>
          <PvColumn
            field="invoiceCode"
            :header="lang.columnInvoiceCode"
            style="min-width: 5rem"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="unitCode"
            :header="lang.columnUnitCode"
            style="min-width: 5rem"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="unitAddressNo"
            :header="lang.columnUnitAddressNo"
            style="min-width: 5rem"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="customerName"
            :header="lang.columnPayerName"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="totalAmount"
            :header="lang.columnTotalAmount"
            style="min-width: 5rem"
            headerClass="h-right"
            bodyClass="text-right"
            sortable
          >
            <template #body="slotProps">
              <span class="text-right w-full">{{
                formatCurrency(slotProps.data.totalAmount)
              }}</span>
            </template>
          </PvColumn>
          <PvColumn
            style="min-width: 5rem"
            headerClass="h-center"
            bodyStyle="text-align: center;justify-content: center;align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                @click="gotoEdit(slotProps.data.transferSlipId)"
              >
                <icon-eye iconColor="secondary" width="24" height="24" />
              </PvButton>
            </template>
          </PvColumn>

          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>

          <template #footer>
            <div>
              <PvPaginator
                :rows="rowsPerPage"
                :totalRecords="totalRecords"
                @page="onPageChange($event)"
                :rowsPerPageOptions="[10, 50, 100]"
                class="p-pagination-radius"
                :first="showCurrentPage"
              >
                <template #start="slotProps">
                  {{ lang.page }}:
                  {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{
                    totalPage
                  }}
                </template>
              </PvPaginator>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, onMounted, ref, inject } from "vue";
import { useRouter } from "vue-router";
export default {
  props: ["projectCode"],
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.transferSlip.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.transferSlip.listing,
        };
      }
    });
    const router = useRouter();
    const isHideBack = inject("isHideBack");

    //Search
    const selectStatus = [
      { name: mergeLang.value.all, code: "all" },
      { name: mergeLang.value.waiting, code: "waiting" },
      { name: mergeLang.value.receiptCreated, code: "receiptCreated" },
      { name: mergeLang.value.rejected, code: "rejected" },
    ];
    let selectedStatus = ref("all");
    const selectType = [
      { name: mergeLang.value.uploadedPeriod, code: "date" },
      { name: mergeLang.value.invoiceCode, code: "invoiceCode" },
      { name: mergeLang.value.unitCode, code: "unitCode" },
      { name: mergeLang.value.unitAddressNo, code: "unitAddressNo" },
      { name: mergeLang.value.payerName, code: "payerName" },
      { name: mergeLang.value.totalAmount, code: "totalAmount" },
    ];
    let selectedType = ref("date");
    let dateStart = ref(null);
    let dateEnd = ref(null);
    let searchText = ref("");
    let isLoading = ref(false);
    let selectedTransferSlip = ref(null);
    let selectedPage = ref(1);
    let rowsPerPage = ref(10);

    //Dialog
    let showValidateDialog = ref(false);
    let titleConfirm = ref(mergeLang.value.notificationDialogHeader);
    let validateWording = ref("");
    let baseDialogClassTitle = ref("");
    let dataButtonDialog = ref([
      {
        id: "confirm",
        caption: mergeLang.value.close,
        class: "p-button-outlined p-button-secondary w-full",
        action: "confirm",
      },
    ]);
    let storageName = ref("");

    onMounted(async () => {
      initiateData();
      setLocalSearch();
      await getTransferSlips();
    });

    //function
    const goBack = () => {
      router.push({ name: "home" });
    };

    const gotoEdit = (id) => {
      router.push({ name: "transfer-slip-edit", params: { id: id } });
    };

    const initiateData = () => {
      const date = new Date();
      dateStart.value = new Date(
        date.getFullYear(),
        date.getMonth() - 2,
        date.getDate()
      );
      dateEnd.value = date;
      storageName.value = `erp_search_TransferSlipsList_${selectedProject.value.code}_${userCode.value}`;

      selectedStatus.value = "all";
      selectedType.value = "date";
      searchText.value = "";
      isLoading.value = false;
      selectedTransferSlip.value = null;
      selectedPage.value = 1;
      rowsPerPage.value = 10;

      showValidateDialog.value = false;
      titleConfirm.value = mergeLang.value.notificationDialogHeader;
      validateWording.value = "";
      baseDialogClassTitle.value = "";
      dataButtonDialog.value = [
        {
          id: "confirm",          
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];
    };

    const changeSelectType = () => {
      const date = new Date();
      if (selectedType.value == "date" && !dateStart.value) {
        dateStart.value = new Date(
          date.getFullYear(),
          date.getMonth() - 2,
          date.getDate()
        );
      }
      if (selectedType.value == "date" && !dateEnd.value) {
        dateEnd.value = date;
      }
      searchText.value = "";
    };

    const validateSearchButton = () => {
      if (selectedType.value != "date" && searchText.value == "") {
        return true;
      }
      return false;
    };

    const getTransferSlips = async () => {
      if (validateSearchButton()) {
        validateWording.value = mergeLang.value.validateSearchText;
        baseDialogClassTitle.value = "";
        showValidateDialog.value = true;
        return;
      }
      isLoading.value = true;

      try {
        let payload = {
          page: selectedPage.value,
          limit: rowsPerPage.value,
          projectId: selectedProject.value.id,
          status: selectedStatus.value != "all" ? selectedStatus.value : null,
          dateStart: selectedDateStart.value,
          dateEnd: selectedDateEnd.value,
          invoiceCode:
            selectedType.value == "invoiceCode" ? searchText.value : null,
          unitCode: selectedType.value == "unitCode" ? searchText.value : null,
          unitAddressNo:
            selectedType.value == "unitAddressNo" ? searchText.value : null,
          customerName:
            selectedType.value == "payerName" ? searchText.value : null,
          totalAmount:
            selectedType.value == "totalAmount" ? searchText.value : null,
        };
        await store.dispatch("transferSlip/getTransferSlips", payload);

        payload = {
          ...payload,
          rowsPerPage: rowsPerPage.value,
          selectedPage: selectedPage.value,

          selectStatus: selectStatus.value,
          selectedStatus: selectedStatus.value,
          selectedType: selectedType.value,
          searchText: searchText.value,
        };
        localStorage.setItem(storageName.value, JSON.stringify(payload));
      } catch (error) {
        openAlertDialogError(error);
      }

      isLoading.value = false;
    };

    const checkInputSearch = async (event) => {
      if (
        selectedType.value == "invoiceCode" ||
        selectedType.value == "totalAmount"
      ) {
        const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        const keyPressed = event.key;
        if (selectedType.value == "totalAmount") {
          keysAllowed.push(".");
        }
        if (event.key != "Enter" && !keysAllowed.includes(keyPressed)) {
          event.preventDefault();
        }
      }

      if (event.key == "Enter") {
        await getTransferSlips();
      }
    };

    const onPageChange = async (event) => {
      if (event.rows != rowsPerPage.value) {
        selectedPage.value = 1;
      } else {
        selectedPage.value = event.page + 1;
      }
      rowsPerPage.value = event.rows;
      await getTransferSlips();
    };

    const setLocalSearch = () => {
      const localSearch = localStorage.getItem(storageName.value);
      if (localSearch) {
        let search = JSON.parse(localSearch);
        if (search.entry === "entry") {
          selectStatus.value = search.selectStatus;
          selectedStatus.value = search.selectedStatus;

          if (search.dateStart != null && search.dateStart != "") {
            dateStart.value = new Date(search.dateStart);
          } else dateStart.value = "";

          if (search.dateEnd != null && search.dateEnd != "") {
            dateEnd.value = new Date(search.dateEnd);
          } else dateEnd.value = "";

          selectedType.value = search.selectedType;
          searchText.value = search.searchText;

          rowsPerPage.value = search.rowsPerPage;
          selectedPage.value = search.selectedPage;
          search.entry = "";

          localStorage.setItem(storageName.value, JSON.stringify(search));
        }
      }
    };

    const openAlertDialogError = (error) => {
      dataButtonDialog.value = [
        {
          id: "confirm",          
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];
      baseDialogClassTitle.value = "p-error";
      titleConfirm.value = mergeLang.value.notificationDialogHeader;
      validateWording.value = error.message;
      showValidateDialog.value = true;
      isLoading.value = false;
    };

    //computed
    const halfViewportHeight = computed(() => {
      return store.getters.halfViewportHeight;
    });
    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const userCode = computed(() => {
      return store.getters.userCode;
    });
    const selectedDateStart = computed(() => {
      let date = null;
      if (dateStart.value && selectedType.value == "date") {
        date = formatDateToISOString(dateStart.value);
      }
      return date;
    });
    const selectedDateEnd = computed(() => {
      let date = null;
      if (dateEnd.value && selectedType.value == "date") {
        date = formatDateToISOString(dateEnd.value);
      }
      return date;
    });
    const transferSlips = computed(() => {
      return store.getters["transferSlip/transferSlips"];
    });
    const totalPage = computed(() => {
      return store.getters["transferSlip/totalPage"];
    });
    const currentPage = computed(() => {
      return store.getters["transferSlip/currentPage"];
    });
    const totalRecords = computed(() => {
      return totalPage.value * rowsPerPage.value;
    });
    const showCurrentPage = computed(() => {
      return rowsPerPage.value * (selectedPage.value - 1);
    });

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      isHideBack,

      selectStatus,
      selectedStatus,
      selectType,
      selectedType,
      dateStart,
      dateEnd,
      searchText,

      isLoading,
      selectedTransferSlip,
      selectedPage,
      rowsPerPage,

      showValidateDialog,
      titleConfirm,
      validateWording,
      baseDialogClassTitle,
      dataButtonDialog,
      storageName,

      //function
      goBack,
      gotoEdit,
      initiateData,
      changeSelectType,
      validateSearchButton,
      getTransferSlips,
      checkInputSearch,
      onPageChange,
      setLocalSearch,
      openAlertDialogError,

      //computed
      halfViewportHeight,
      selectedProject,
      userCode,
      selectedDateStart,
      selectedDateEnd,
      transferSlips,
      totalPage,
      currentPage,
      totalRecords,
      showCurrentPage,
    };
  },
};
</script>

<style></style>
