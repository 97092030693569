export default {
  purchaseInvoices(state) {
    return state.purchaseInvoices;
  },
  selectedPurchaseInvoice(state) {
    return state.selectedPurchaseInvoice;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
  purchaseOrders(state) {
    return state.purchaseOrders;
  },

  blob(state) {
    return state.blob;
  },
  purchaseInvoiceReference(state) {
    return state.purchaseInvoiceReference;
  },

};
