import unitGetters from "./unit/getters.js";
import productArGetters from "./productAR/getters.js";
import documentDistinctGetters from "./documentBookDistinct/getters.js";
import projectGetters from "./project/getters.js";
import formPrintGetters from "./formPrint/getters.js";
import supplierGetters from "./supplier/getters.js";

import glBookGetters from "./glBook/getters.js";
import accountingFrequentlyGetters from "./accountingFrequently/getters.js";
import accountingGetters from "./accounting/accountingAll/getters.js";
import taxBookGetters from "./taxBook/getters.js";

import customerGetters from "./customer/getters.js";
import apBookGetters from "./apBook/getters.js";
import productApGetters from "./productAP/getters.js";
import employeeGetters from "./employee/getters.js";
import purchaseGetters from "./billAcceptance/getters.js";
import taxGetters from "./tax/getters.js";
import buildingGetters from "./building/getters.js";
import jobAdminGetters from "./jobAdmin/getters.js";
import locationGetters from "./location/getters.js";
import accountingDetailGetters from "./accounting/accountingDetail/getters.js";
import accountingParentGetters from "./accounting/accountingParent/getters.js";
import productUnitGetters from "./productUnit/getters.js";
import nationalityGetters from "./nationality/getters.js";
import bankGetters from "./bank/getters.js";
import printGetters from "./print/getters.js";
import roleByMenuGetters from "./roleByMenu/getters.js"
import roleByBookGetters from "./roleByBook/getters.js"
import currencyGetters from "./currency/getters.js";
import bookBankArGetters from "./bookBankAr/getters.js";
import productGuaranteeGetters from "./productGuarantee/getters.js";
import addressGetters from "./address/getters.js";
import dbdBusinessTypeGetters from "./dbdBusinessType/getters.js";
import countryCodeGetters from "./countryCode/getters.js";

const mergeGetters = {
	...unitGetters,
	...productArGetters,
	...documentDistinctGetters,
	...projectGetters,
	...formPrintGetters,
	...glBookGetters,
	...accountingFrequentlyGetters,
	...accountingGetters,
	...supplierGetters,
	...taxBookGetters,
	...customerGetters,
	...apBookGetters,
	...productApGetters,
	...employeeGetters,
	...purchaseGetters,
	...taxGetters,
	...buildingGetters,
	...jobAdminGetters,
	...locationGetters,
	...accountingDetailGetters,
	...accountingParentGetters,
	...productUnitGetters,
	...nationalityGetters,
	...bankGetters,
	...printGetters,
	...roleByMenuGetters,
	...roleByBookGetters,
	...currencyGetters,
	...bookBankArGetters,
	...productGuaranteeGetters,
	...addressGetters,
	...dbdBusinessTypeGetters,
	...countryCodeGetters,
};

export default mergeGetters;
