export default {
  async getProductUnits(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Product/GetProductUnit";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get ProductUnit"
      );
      throw error;
    }
    // console.log("productUnit", responseData);

    // const data = responseData.data;
    const productUnit = {
      productUnits: responseData.data,
      totalPage: responseData.page.pageCount,
      currentPage: responseData.page.currentPage,
    };
    context.commit("setProductUnit", productUnit);
  },
  async getAllProductUnits(context) {
    while (!context.getters.productUnitIsLastPage) {
      let where = {
        projectId: context.rootGetters.selectedProject.id,
      };
      const payload = {
        page: context.getters.productUnitNextPage || 1,
        where,
      };
      await context.dispatch("getProductUnits", payload);
    }
  },
  resetProductUnit(context) {
    context.commit("resetProductUnit");
  },
};
