export default {
    createAccountingFrequently: "สร้างรูปแบบบัญชีใช้บ่อย",
    editAccountingFrequently: "แก้ไขรูปแบบบัญชีใช้บ่อย",
    titleEdit: "รายละเอียดรูปแบบบัญชีใช้บ่อย",
    active: "เปิดใช้งาน",
    inActive: "ไม่เปิดใช้งาน",
    format: "รูปแบบเดบิต/เครดิต",
    setproportion: "แบบกำหนดสัดส่วน",
    nonSetproportion: "แบบไม่กำหนดสัดส่วน",
    accountCode: "รหัสผังบัญชี",
    accountName: "ชื่อผังบัญชี",
    debit: "เดบิต",
    credit: "เครดิต",
    addEdit: "เพิ่ม/ แก้ไขรายการ",
    account: "บัญชี",
    createNewAccountingFrequently: "สร้างรูปแบบบัญชีใช้บ่อยใหม่",
    askForCreateNewAccountingFrequently: "หากคุณสร้างรูปแบบบัญชีใช้บ่อยใหม่ระบบจะไม่บันทึกการแก้ไข คุณยืนยันที่จะสร้างเอกสารคืนเงินค้ำประกันใหม่ใช่หรือไม่?",
    createAccountingFrequentlySuccess: "คุณสร้างรูปแบบบัญชีใช้บ่อยสำเร็จ",
    editAccountingFrequentlySuccess: "คุณแก้ไขรูปแบบบัญชีใช้บ่อยสำเร็จ",
    duplicateCode: "ไม่สามารถทำรายการได้ เนื่องจากรูปแบบบัญชีใช้บ่อยรหัส : :code ซ้ำ",
    deleteAccountingFrequentlyTitle: "ยืนยันการลบข้อมูล",
    askForAccountingFrequentlyTitle: "ต้องการยืนยันที่จะลบรายการ :code ใช่หรือไม่?",
    deleteAccountingFrequentlySuccess: "คุณลบข้อมูลสำเร็จ",
    validateDiffAmount: "ยอดรวมเดบิตและเครดิตต้องเท่ากัน และมากกว่า 0",
    validateCode: "กรุณาระบุรหัส",
    validateName: "กรุณาระบุชื่อ",
    validateDetail: "กรุณาเพิ่มรายการ",
    validateSelectedAccount: "กรุณาเลือกบัญชี",
}