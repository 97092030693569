export default {
	title: "Account Receivable Product",
	createProduct: "Create Product",

	inputSearch: "Enter a message",

	columnCode: "Code",
	columnName: "Name",
	columnPriority: "Priority",
	columnPrice: "Price",
	columnConfig: "Setting",
	columnUpdate: "Edit",

	titleConfirmDialogGotoEdit:"Confirm edit data",
	contentConfirmDialogGotoEdit: "Are you sure to edit data?",
	titleConfirmDialogGotoEDelete: "Confirm delete data",
	contentConfirmDialogGotoDelete: "Confirm to delete {code} : {name}",
	titleConfirmDialogGotoSetupPenalty: "Confirm setup product",
	contentConfirmDialogGotoSetupPenalty: "Are you sure to setup product?",

	eleteProductSuccess: "Delete successfully",
	errorArProductReference: "Can not delete. Beacuse having reference.",
};
