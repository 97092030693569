<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '70vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="titleHeaderImportData"
      :wordingConfirm="titleValidateImportData"
      :dataButtons="dataButtonDialog"
      @close="showValidateDialog = false"
    ></base-dialog>

    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">{{ lang.uploadFile }}</label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          :disabled="shouldDisableCloseButton"
          @click="closeDialog"
        />
      </div>
    </template>
    <div class="dropZone">
      <input
        type="file"
        id="dropZone-file"
        class="dropZone-file"
        ref="dropZoneFile"
        @click="resetFile"
        @change="handleFiles"
        multiple
        accept=".xlsx"
      />
      <div
        class="dropZone-wrapper"
        @dragenter.prevent=""
        @dragover.prevent=""
        @drop.prevent="handleFiles"
      >
        <label for="dropZone-file" class="cursor-pointer">
          <div class="pt-6"></div>
          <PvButton
            class="cursor-pointer p-button-rounded show-icon"
            disabled="disabled"
          >
            <icon-cloud-storage-upload iconColor="white" />
          </PvButton>
          <div class="cursor-pointer dropZone-label-text pt-4">
            <label
              for="dragFile"
              class="cursor-pointer font-md p-text-secondary"
              >{{ lang.dragFile }}</label
            >
            <label
              for="addFile"
              class="cursor-pointer font-md p-text-primary"
              @click="addFile"
              >{{ lang.addFile }}</label
            >
            <input
              id="add-file"
              type="file"
              style="display: none"
              accept=".xlsx"
              @click="resetFile"
              @change="handleFiles"
              class="cursor-pointer"
            />
          </div>
          <div class="cursor-pointer font-xs p-text-secondary pt-2 pb-8">
            <label for="importFileDesc" class="cursor-pointer">{{
              lang.importFileDesc
            }}</label>
            <label
              for="downloadfile"
              class="cursor-pointer p-text-primary"
              @click="downloadfile"
              >{{ lang.downloadfile }}</label
            >
          </div>
        </label>
      </div>
    </div>

    <div class="my-5 pt-6">
      <PvDataTable
        :value="files"
        dataKey="id"
        responsiveLayout="scroll"
        class="p-datatable-xs"
        :class="textTableClass"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn style="width: 87px; height: 56px" headerClass="h-center">
          <template #body>
            <PvButton
              v-if="isOpen"
              type="button"
              style="background: white; border: white; opacity: 1"
              disabled="disabled"
            >
              <icon-documents-file-checkmark iconColor="secondary" />
            </PvButton>
          </template>
        </PvColumn>

        <PvColumn
          field="detailName"
          :header="lang.fileName"
          style="min-width: 12rem"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-left font-normal p-text-primary"
        >
        </PvColumn>

        <PvColumn
          style="width: 2rem"
          headerClass="h-center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="">
            <PvButton
              v-if="isOpen"
              type="button"
              class="p-button-danger"
              style="background: white; border: white"
              @click="deleteFile"
            >
              <icon-trash-delete-bin iconColor="error" />
            </PvButton>
          </template>
        </PvColumn>
      </PvDataTable>
    </div>

    <div class="flex justify-content-center pt-4">
      <PvButton class="w-3" @click="uploadFile" :disabled="checkDisable">
        <span class="w-full text-center font-md font-normal p-button-label">
          <i
            v-if="isUpLoading"
            class="pi pi-spin pi-spinner font-normal mr-1"
          ></i>
          {{ lang.uploadFile }}
        </span>
      </PvButton>
    </div>
    <template #footer></template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { ref, computed } from "vue";
import * as XLSX from "xlsx";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import { useRouter } from "vue-router";
import useImportExcel from "../../../hooks/importExcel.js"
export default {
  props: [],
  emits: ["close", "data-upload"],
  setup(props, context) {
    const { lang } = useLang();
    const { textTableClass } = useTextStyle();
    const { getDownloadBlob } = useGetFormPrint();
    const { formatDate, formatDateToISOString } = useFormat();
    const { validateDate, convertDate} = useImportExcel();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.stockKeycard.import,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.stockKeycard.import,
        };
      }
    });

    const router = useRouter();

    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const fileBlob = computed(() => {
      return store.getters["shared/blob"];
    });
    const checkDisable = computed(() => {
      if (!file.value || isUpLoading.value) {
        return true;
      } else {
        return false;
      }
    });
    // const validatedImportStockKeycard = computed(() => {
    //   return store.getters["importStockKeycard/importStockKeycards"];
    // });
    const shouldDisableCloseButton = computed(() => {
      return isUpLoading.value;
    });
    const dataButtonDialog = computed(() => {
      let result = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        }
      ];
      return result;
    });
    const products = computed(() => {
      return store.getters["importStockKeycard/products"];
    });
    const units = computed(() => {
      return store.getters["importStockKeycard/units"];
    });
    const runningNumbers = computed(() => {
      return store.getters["importStockKeycard/runningNumbers"];
    });

    let files = ref([]);
    let isOpen = ref(false);
    let file = ref(null);
    let arrayBuffer = ref(null);
    let rawDatas = ref([]);
    let itemTotal = ref(0);
    let processingText = ref("");
    let isUpLoading = ref(false);
    let uploadFiles = ref([]);
    let fileName = ref("");
    let titleHeaderImportData = ref(mergeLang.value.notificationDialogHeader);
    let titleValidateImportData = ref("");
    let showValidateDialog = ref(false);
    let productImportStockKeycards = ref([]);
    let unitImportStockKeycards = ref([]);
    let runningNumberImportStockKeycards = ref([]);
    let rawDataForValidate = ref([]);

    const setLoading = (bool) => {
      isUpLoading.value = bool;
    }
    
    const closeDialog = () => {
      context.emit("close");
      initiateData();
    }

    const initiateData = () => {
      files.value = [];
      isOpen.value = false;
      file.value = null;
      rawDataForValidate.value = [];
      unitImportStockKeycards.value = [];
      productImportStockKeycards.value = [];
      runningNumberImportStockKeycards.value = [];
    }

    const downloadfile = async () => {
      setLoading(true);
      let payload = {
        docType: "excel",
        path: "stock_keycard/import_stock_keycard_form.xlsx",
        fileName: "StockKeycard",
        parameter: "{}",
      };
      try {
        await store.dispatch("shared/printReport", payload);
        const d = new Date();
        let dateText = d.toISOString();
        getDownloadBlob(
          `${payload.fileName}_${dateText}.xlsx`,
          fileBlob.value
        );
      } catch (error) {
        titleValidateImportData.value = error;
        showValidateDialog.value = true;
        setLoading(false);
      }
      setLoading(false);
    }

    const handleFiles = async (event) => {
      const inputValue = event.target.files || event.dataTransfer.files;
      uploadFiles.value = Array.from(inputValue);
      if (validateFileCount()) {
        return;
      } //เช็คจำนวนไฟล์
      file.value = inputValue[0];
      if (validateFileType()) {
        return;
      } //เช็คประเภทไฟล์
      isOpen.value = true;
      let fileName = file.value.name;
      files.value = [
        {
          detailName: fileName,
        },
      ];
    }

    const validateFileCount = () => {
      let check = uploadFiles.value.length;
      let textError = "";
      uploadFiles.value.forEach((file) => {
        textError += file.name + ", ";
      });
      let name = textError.substring(0, textError.length - 2);
      if (check > 1) {
        titleHeaderImportData.value = mergeLang.value.validateLimitFile;
        titleValidateImportData.value = mergeLang.value.validateFileType.replace(":name",name),
        showValidateDialog.value = true;
        return true;
      }
    }
    const validateFileType = () => {
      let error = "";
      if (
        file.value.type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        titleHeaderImportData.value = mergeLang.value.validateImportFile;
        titleValidateImportData.value = mergeLang.value.validateFileType.replace(":name",file.value.name);
        showValidateDialog.value = true;
        error = "error";
      }
      if (error === "error") return true;
      else return false;
    }

    const addFile = () => {
      document.getElementById("add-file").click();
    }
    const deleteFile = () => {
      initiateData();
    }    
    const resetFile = (event) => {
      if (event.target.files.length > 0) {
        event.target.value = null;
      }
    }

    const uploadFile = () => {
      if (file.value) {
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file.value);
        fileReader.onload = async () => {
          arrayBuffer.value = fileReader.result;
          let data = new Uint8Array(arrayBuffer.value);
          let arr = [];
          for (let i = 0; i != data.length; ++i)
            arr[i] = String.fromCharCode(data[i]);
          let bstr = arr.join("");
          let workbook = XLSX.read(bstr, { type: "binary" });
          let first_sheet_name = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[first_sheet_name];
          rawDatas.value = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          itemTotal.value = rawDatas.value.length;
          if (rawDatas.value.length == 0) {
            titleValidateImportData.value = mergeLang.value.validateImportNoData.replace(":file", file.value.name);
            showValidateDialog.value = true;
            return;
          } else {
            setLoading(true);
            // if (await validateData()) {
            //   showValidateDialog.value = true;
            //   setLoading(false);
            //   return;
            // }
            if (await validateImport()) {
              return;
            }
          }
          context.emit("data-upload");
          // this.$router.push({ name: "stock-keycard-import" });
        };
      } else {
        titleValidateImportData.value = mergeLang.value.validateAddfile;
        showValidateDialog.value = true;
        return true;
      }
    }
    // const validateData = async () => {
    //   for (const data in rawDatas.value) {
    //     let rowNumber = parseInt(data) + 1;
    //     let keycards = rawDatas.value[data];
    //     if (!keycards.ProductCode) {
    //       titleValidateImportData.value = mergeLang.value.validateDataProductCode.replace(":row", rowNumber);
    //       return true;
    //     }
    //     if (keycards.ProductCode) {
    //       let lengthText = keycards.ProductCode.toString();
    //       if (lengthText.length > 25) {
    //         titleValidateImportData.value = mergeLang.value.validateDataLimitTextProduct.replace(":row", rowNumber);
    //         return true;
    //       }
    //     }
    //     if (!keycards.RunningNumber) {
    //       titleValidateImportData.value = mergeLang.value.validateDataRunningNumber.replace(":row", rowNumber);
    //       return true;
    //     }
    //     if (keycards.RunningNumber) {
    //       let lengthText = keycards.RunningNumber.toString();
    //       if (lengthText.length > 25) {
    //         titleValidateImportData.value = mergeLang.value.validateDataLimitTextRunningNumber.replace(":row", rowNumber);
    //         return true;
    //       }
    //     }
    //     if (keycards.SerialNumber) {
    //       let lengthText = keycards.SerialNumber.toString();
    //       if (lengthText.length > 25) {
    //         titleValidateImportData.value = mergeLang.value.validateDataLimitTextSerialNumber.replace(":row", rowNumber);
    //         return true;
    //       }
    //     }
    //     if (!["ready", "sold", "lost", "broken", "spare", "other"].includes(keycards.Status?.toLowerCase())) {
    //       titleValidateImportData.value = mergeLang.value.validateDataLimitTextStatus.replace(":row", rowNumber);
    //       return true;
    //     }
    //     if (keycards.Remark) {
    //       let lengthText = keycards.Remark.toString();
    //       if (lengthText.length > 255) {
    //         titleValidateImportData.value = mergeLang.value.validateDataLimitTextRemark.replace(":row", rowNumber);
    //         return true;
    //       }
    //     }
    //   }
    //   return false;
    // }

    const getFields = (input, field) => {
      let output = [];
      for (let i = 0; i < input.length; ++i) output.push(input[i][field]);
      return output;
    };

    const removeDuplicates = (arr) => {
      return arr.filter((item, index) => arr.indexOf(item) === index);
    };

    const getAndValidateProducts = async () => {
      try {
        //get ข้อมูลเฉพาะ productCode
        let productList = getFields(rawDataForValidate.value, "productCode");
        //remove ข้อมูลซ้ำ
        productList = removeDuplicates(productList);

        let loopLength = 50;

        while (productList.length > 0) {
          if (productList.length < loopLength) {
            loopLength = productList.length;
          }

          let spliceData = productList.splice(0, loopLength);

          let payload = {
            projectId: selectedProject.value.id,
            productCode: spliceData,
          };

          await store.dispatch(
            "importStockKeycard/getProductImportStockKeycard",
            payload
          );

          productImportStockKeycards.value = [
            ...productImportStockKeycards.value,
            ...products.value,
          ];
        }

      } catch (error) {
        titleValidateImportData.value = error;
        showValidateDialog.value = true;
        setLoading(false);
        return true;
      }
    };

    const getAndValidateUnits = async () => {
      try {
        //get ข้อมูลเฉพาะ unitCode
        let unitList = getFields(rawDataForValidate.value, "unitCode");
        //remove ข้อมูลซ้ำ
        unitList = removeDuplicates(unitList);

        let loopLength = 50;

        while (unitList.length > 0) {
          if (unitList.length < loopLength) {
            loopLength = unitList.length;
          }

          let spliceData = unitList.splice(0, loopLength);

          let payload = {
            projectId: selectedProject.value.id,
            unitCode: spliceData,
          };

          await store.dispatch(
            "importStockKeycard/getUnitImportStockKeycard",
            payload
          );

          unitImportStockKeycards.value = [
            ...unitImportStockKeycards.value,
            ...units.value,
          ];
        }

      } catch (error) {
        titleValidateImportData.value = error;
        showValidateDialog.value = true;
        setLoading(false);
        return true;
      }
    };

    const getAndValidateRunningNumbers = async () => {
      try {
        //get ข้อมูลเฉพาะ runningNumber
        let runningNumberList = getFields(rawDataForValidate.value, "runningNumber");
        //remove ข้อมูลซ้ำ
        runningNumberList = removeDuplicates(runningNumberList);

        let loopLength = 50;

        while (runningNumberList.length > 0) {
          if (runningNumberList.length < loopLength) {
            loopLength = runningNumberList.length;
          }

          let spliceData = runningNumberList.splice(0, loopLength);

          let payload = {
            projectId: selectedProject.value.id,
            runningNumber: spliceData,
          };

          await store.dispatch(
            "importStockKeycard/getRunningNumberImportStockKeycard",
            payload
          );

          runningNumberImportStockKeycards.value = [
            ...runningNumberImportStockKeycards.value,
            ...runningNumbers.value,
          ];
        }

      } catch (error) {
        titleValidateImportData.value = error;
        showValidateDialog.value = true;
        setLoading(false);
        return true;
      }
    };

    const mapingData = async (data) => {
      //เช็คข้อมูล product
      if (data.productCode) {
        let product = productImportStockKeycards.value.filter(
          (product) => product.productCode == data.productCode
        )[0];
        let productId = product ? product.productId : "";

        if (!product) {
          data.isProductCodeError = true;
          data.importStatusCode = "invalid";
          data.importStatusReason = mergeLang.value.validateImportProductCode;
          return data;
        }
        data = {...data, productId: productId }
      }
      
      //เช็คข้อมูล unit
      if (data.unitCode) {
        let unit = unitImportStockKeycards.value.filter(
          (unit) => unit.unitCode == data.unitCode
        )[0];
        let unitId = unit ? unit.unitId : "";

        if (!unit) {
          data.isUnitCodeError = true;
          data.importStatusCode = "invalid";
          data.importStatusReason = mergeLang.value.validateImportUnitCode;
          return data;
        }
        data = {...data, unitId: unitId}
      }

      //เช็คข้อมูล running number
      if (data.runningNumber) {
        let runningNumber = runningNumberImportStockKeycards.value.filter(
          (running) => running.runningNumber == data.runningNumber
        )[0];

        if (runningNumber) {
          data.isRunningNumberError = true;
          data.importStatusCode = "invalid";
          data.importStatusReason = mergeLang.value.validateImportRunningNumberDuplicateInDatabase;
          return data;
        }
      }

      //เช็คข้อมูล unit สำหรับสถานะขาย
      if (!data.unitCode && data.status == "sold") {
        data.isUnitCodeError = true;
        data.importStatusCode = "invalid";
        data.importStatusReason = mergeLang.value.validateImportUnitCode;
        return data;
      }

      return data;
      
    };

    const prepareData = async () => {
      let dataResult = [];
      for (const data of rawDataForValidate.value) {
        let result = data;
        if(data.importStatusCode == "wait") {
          result = await mapingData(data);
        }
        dataResult.push(result);
      }
      await store.dispatch(
        "importStockKeycard/importStockKeycards",
        dataResult
      );
      router.push({ name: "preview-stock-keycard" });
    };

    const validateAndPrepareData = async () => {
      await getAndValidateProducts();
      await getAndValidateUnits();
      await getAndValidateRunningNumbers();
      if (isUpLoading.value) {
        await prepareData();
      }
      return;
    };

    const validateImport = async () => {
      try {
        let index = 0;
        let stockKeycards = [];
        const duplicateData = findDuplicateIds(rawDatas.value)
        for (const rawData of rawDatas.value) {
          let isError = false;
          let runningNumber = rawData.RunningNumber ? rawData.RunningNumber.toString().trim() : "";
          let serialNumber = rawData.SerialNumber ? rawData.SerialNumber.toString().trim(): "";
          let stockKeycardDuplicateRunningNumber = duplicateData.filter(item => item.runningNumber === runningNumber);
          let stockKeycard = {}
          if(stockKeycardDuplicateRunningNumber.length == 0) 
          {
            stockKeycard = {
              id: index,
              itemNo: index+1,
              importStatusCode: "wait",
              importStatusReason: "",
              productCode: rawData.ProductCode || "",
              isProductCodeError: false,
              unitCode: rawData.UnitCode || "",
              isUnitCodeError: false,
              date: formatDateToISOString(new Date()),
              isDateError: false,
              runningNumber: runningNumber,
              isRunningNumberError: false,
              serialNumber: serialNumber,
              isSerialNumberError: false,
              status: rawData.Status || "",
              isStatusError: false,
              remark: rawData.Remark || "",
              isRemarkError: false,
              markStatus: "",
            };
          } 
          else 
          {
            stockKeycard = {
              id: index,
              itemNo: index+1,
              importStatusCode: "invalid",
              importStatusReason: mergeLang.value.validateImportRunningNumberDuplicate + stockKeycardDuplicateRunningNumber[0].indices.toString(),
              productCode: rawData.ProductCode || "",
              isProductCodeError: false,
              unitCode: rawData.UnitCode || "",
              isUnitCodeError: false,
              date: formatDateToISOString(new Date()),
              isDateError: false,
              runningNumber: runningNumber,
              isRunningNumberError: false,
              serialNumber: serialNumber,
              isSerialNumberError: false,
              status: rawData.Status || "",
              isStatusError: false,
              remark: rawData.Remark || "",
              isRemarkError: false,
              markStatus: "",
            };
            stockKeycard.isRunningNumberError = true;
            isError = true;
          }
          index++;
          if (!rawData.ProductCode && isError == false) 
          {
            stockKeycard.importStatusCode = "invalid";
            stockKeycard.importStatusReason = mergeLang.value.validateImportProductCode;
            stockKeycard.isProductCodeError = true;
            isError = true;
          }
          if (rawData.ProductCode && isError == false) 
          {
            let lengthText = rawData.ProductCode.toString();
            if (lengthText.length > 25) {
              stockKeycard.importStatusCode = "invalid";
              stockKeycard.importStatusReason = mergeLang.value.validateImportLimitTextProduct;
              stockKeycard.isProductCodeError = true;
              isError = true;
            }
          }
          if (!rawData.RunningNumber && isError == false) 
          {
            stockKeycard.importStatusCode = "invalid";
            stockKeycard.importStatusReason = mergeLang.value.validateImportRunningNumber;
            stockKeycard.isRunningNumberError = true;
            isError = true;
          }
          if (rawData.RunningNumber && isError == false) 
          {
            let lengthText = rawData.RunningNumber.toString();
            if (lengthText.length > 25) {
              stockKeycard.importStatusCode = "invalid";
              stockKeycard.importStatusReason = mergeLang.value.validateImportLimitTextRunningNumber;
              stockKeycard.isRunningNumberError = true;
              isError = true;
            }
          }
          if (rawData.SerialNumber && isError == false) 
          {
            let lengthText = rawData.SerialNumber.toString();
            if (lengthText.length > 25) {
              stockKeycard.importStatusCode = "invalid";
              stockKeycard.importStatusReason = mergeLang.value.validateImportLimitTextSerialNumber;
              stockKeycard.isSerialNumberError = true;
              isError = true;
            }
          }
          let importStatus = rawData.Status ? rawData.Status.trim() : "";
          let importRemark = rawData.Remark ? rawData.Remark.trim() : "";
          if (!["ready", "sold", "lost", "broken", "spare", "other"].includes(importStatus?.toLowerCase()) && isError == false)
          {
            stockKeycard.importStatusCode = "invalid";
            stockKeycard.importStatusReason = mergeLang.value.validateImportStatus;
            stockKeycard.isStatusError = true;
            isError = true;
            // if (stockKeycard.importStatusReason == "") {
            //   stockKeycard.importStatusReason = mergeLang.value.validateImportStatus;
            // } else {
            //   stockKeycard.importStatusReason = stockKeycard.importStatusReason+',<br>'+mergeLang.value.validateImportStatus;
            // }
          }
          if (importRemark && isError == false) 
          {
            let lengthText = importRemark.toString();
            if (lengthText.length > 25) {
              stockKeycard.importStatusCode = "invalid";
              stockKeycard.importStatusReason = mergeLang.value.validateImportLimitTextRemark;
              stockKeycard.isRemarkError = true;
              isError = true;
            }
          }
          if (["lost", "broken", "spare", "other"].includes(importStatus?.toLowerCase()) && importRemark == "" && isError == false) 
          {
            stockKeycard.importStatusCode = "invalid";
            stockKeycard.importStatusReason = mergeLang.value.validateImportRemark;
            stockKeycard.isRemarkError = true;
            isError = true;
          }

          let result = validateDate(rawData.ReceivedDate);
          if(result){
            let receivedDate = convertDate(rawData.ReceivedDate);
            stockKeycard.date = formatDateToISOString(receivedDate);
          }
          else 
          {
            if (isError == false) 
            {
              stockKeycard.date = rawData.ReceivedDate;
              stockKeycard.importStatusCode = "invalid";
              stockKeycard.importStatusReason = mergeLang.value.validateImportDate;
              stockKeycard.isDateError = true;
              isError = true;
            }
          }
          stockKeycards.push(stockKeycard);
        }
        processingText.value = `${mergeLang.value.validatingData} ${index}/${rawDatas.value.length}`;
        rawDataForValidate.value = stockKeycards;
        await validateAndPrepareData();
        setLoading(false);
      } catch (error) {
        titleValidateImportData.value = error;
        showValidateDialog.value = true;
        setLoading(false);
        return true;
      }
      return false;
    }

    const findDuplicateIds = (arrayOfObjects) => {
      const idMap = {};
      const duplicates = [];
      arrayOfObjects.forEach((obj, index) => {
        const currentId = obj.RunningNumber;
        if (idMap[currentId] === undefined) {
          // ถ้ายังไม่เคยเจอ id นี้, เก็บ index ไว้ใน idMap
          idMap[currentId] = [index + 1];
        } else {
          // ถ้าเคยเจอ id นี้แล้ว, เพิ่ม index ลงใน array
          idMap[currentId].push(index + 1);
          if (idMap[currentId].length === 2) {
            // เพิ่ม id นี้เข้าไปใน duplicates ถ้าเป็นครั้งแรกที่เจอ
            duplicates.push(currentId);
          }
        }
      });
      // หา index ที่ซ้ำกันของแต่ละ id
      const duplicateIndices = duplicates.map(runningNumber => ({
        runningNumber: runningNumber,
        indices: idMap[runningNumber]
      }));
      return duplicateIndices;
    }
    

    return {
      // common
      lang: mergeLang,
      textTableClass,
      getDownloadBlob,
      formatDate,
      formatDateToISOString,
      validateDate,
      convertDate,

      // computed
      selectedProject,
      fileBlob,
      checkDisable,
      // validatedImportStockKeycard,
      shouldDisableCloseButton,
      dataButtonDialog,

      // data
      isUpLoading,
      files,
      isOpen,
      file,
      fileName,
      processingText,
      titleHeaderImportData,
      titleValidateImportData,
      showValidateDialog,

      // function
      setLoading,
      closeDialog,
      handleFiles,
      addFile,
      deleteFile,
      resetFile,
      downloadfile,
      uploadFile,
    };
  },
  interval: null,
};
</script>

<style scoped>
.dropZone {
  box-sizing: border-box;
  width: 100%;
  background: #fafafa;
  border: 2px dashed #ced0d6;
  border-radius: 10px;
  text-align: center;
  height: 194px;
  margin-top: 2rem;
}
.dropZone-file {
  position: absolute;
  width: 0px;
  height: 0px;
  overflow: hidden;
}
.dropZone-wrapper {
  display: grid;
  width: 100%;
  height: 100%;
}
.show-icon {
  opacity: 1;
  width: 48px;
  height: 48px;
  padding: 0.75rem;
}
</style>
