<template>
	<PvSidebar
		v-model:visible="visibleSidebar"
		:baseZIndex="10000"
		:position="position"
		@show="show"
		@hide="hide"
		class="w-full md:w-30rem"
		:showCloseIcon="false"
		>
	<!-- <PvSidebar
		v-model:visible="visibleSidebar"
		:baseZIndex="10000"
		:position="position"
		@show="show"
		@hide="hide"
		> -->
		<!-- <template #header>
			<div class="flex justify-content-between align-items-center">
				<div>
					<slot name="sidebarHeader"></slot>
				</div>
			</div>
		</template> -->
		<div class="flex justify-content-between align-items-center pt-4 pb-3">
			<div >
				<slot name="sidebarHeader"></slot>
			</div>

			<PvButton
			icon="pi pi-times"
			class="p-button-link p-icon-secondary"
			@click="visibleSidebar=false"
			/>
		</div>
		<slot></slot>
		<div class="sidebarfooter">
		<!-- <div class="w-full md:w-30rem"> -->
			<PvButton
				class="button-toolbar p-button-outlined p-button-primary w-full"
				@click="$emit('gotoEdit')">
				<icon-pen-edit />
				<span class="p-button-label font-normal ml-1">{{ this.lang.editCaption }}</span>
			</PvButton>
		</div>
	</PvSidebar>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
export default {
	//   props: ["isVisibleSidebar"],
	props: {
		isVisibleSidebar: {
			type: Boolean,
			default: false,
		},
		position: {
			type: String,
			default: "right",
		},
	},
	emits: ["visibleSidebar", "gotoEdit"],
	setup() {
		const { lang } = useLang();
		const store = useStore();
		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return {
					...lang.th.base,
				};
			} else {
				return {
					...lang.en.base,
				};
			}
		});

		return {
			lang: mergeLang,
		};
	},
	data() {
		return {
			visibleSidebar: this.isVisibleSidebar,
		};
	},
	computed: {
		// VisibleSidebar() {
		//   return this.isVisibleSidebar;
		// },
	},
	methods: {
		show() {
			this.$emit("visibleSidebar", true);
		},
		hide() {
			this.$emit("visibleSidebar", false);
		},
	},
	watch: {
		isVisibleSidebar() {
			this.visibleSidebar = this.isVisibleSidebar;
		},
	},
};
</script>

<style scoped>
.sidebarfooter {
	/* display: block;
	position: fixed;
	bottom: 0;
	right: 0;
	background-color: var(--surface-a);
	padding: 16px 24px 24px; */
	width: 100%;
}
</style>

<!-- <style>
.p-sidebar-header {
    display: none !important;
}

/* .p-sidebar-right {
	width: 528px !important;
} */

.p-sidebar-content {
	height: 85% !important;
}

.p-sidebar-header-content {
	width: 100%;
}

.p-sidebar-content {
	height: 100%;
}

@media screen and (max-width: 600px) {
	.p-sidebar-right {
		width: 100% !important;
	}
	.sidebarfooter {
		width: 100% !important;
	}
}

@media (min-width: 600px) and (max-width: 960px) {
	.p-sidebar-right {
		width: 60% !important;
	}
	.sidebarfooter {
		width: 60% !important;
	}
}

@media (min-width: 960px) and (max-width: 1264px) {
	.p-sidebar-right {
		width: 40% !important;
	}
	.sidebarfooter {
		width: 40% !important;
	}
}

@media (min-width: 1264px) {
	.p-sidebar-right {
		width: 528px !important;
	}
	.sidebarfooter {
		width: 528px !important;
	}
}
</style> -->
