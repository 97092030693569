export default {
  async getPaymentRequests(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/PaymentRequest";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.projectId) {
      url = url + "&projectId=" + payload.projectId;
    }

    if (payload.paymentRequestType) {
      url = url + "&paymentRequestType=" + payload.paymentRequestType;
    }

    if (payload.dateStart) {
      url = url + "&dateStart=" + payload.dateStart;
    }

    if (payload.dateEnd) {
      url = url + "&dateEnd=" + payload.dateEnd;
    }

    if (payload.paymentRequestCode) {
      url = url + "&paymentRequestCode=" + payload.paymentRequestCode;
    }

    if (payload.supplierName) {
      url = url + "&supplierName=" + payload.supplierName;
    }

    if (payload.totalAmountStart) {
      url = url + "&totalAmountStart=" + payload.totalAmountStart;
    }

    if (payload.totalAmountEnd) {
      url = url + "&totalAmountEnd=" + payload.totalAmountEnd;
    }

    if (payload.isAscending) {
      url = url + "&isAscending=" + payload.isAscending;
    }
    url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get payment requests"
      );
      throw error;
    }

    // console.log("getPaymentRequests",responseData);

    context.commit("setPaymentRequests", responseData);
  },
  async getPaymentRequestById(context, id) {
    let url = process.env.VUE_APP_BACKEND_URL + "/PaymentRequest/" + id;
    url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get payment requests"
      );
      throw error;
    }

    // console.log("getPaymentRequests",responseData);

    context.commit("setSelectedPaymentRequest", responseData.data);
  },
  resetPaymentRequests(context) {
    context.commit("resetPaymentRequests");
  },
  async createOrUpdatePaymentRequest(context, payload) {
    let path = "";
    if (payload.type == "Add") {
      path = `/PaymentRequest/Add`
    } else {
      path = `/PaymentRequest/${payload.id}/Update`
    }
    let url = process.env.VUE_APP_BACKEND_URL + path;
    const fallbackErrorMessage = `Failed to ${payload.type} PaymentRequest`
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: payload.type === 'Add' ? 'post' : 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      let message = null
      if (Object.prototype.hasOwnProperty.call(responseData, 'errors')) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "")
        }
      } else if (Object.prototype.hasOwnProperty.call(responseData, 'message')) {
        message = responseData.message
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }
    context.commit("setSelectedPaymentRequest", responseData.data);
  },
  async getPaymentRequestForAutoGl(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/PaymentRequest/GetPaymentRequestForAutoGl";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // let message = null;
      // if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
      //   message = responseData.title;
      //   for (const key in responseData.errors) {
      //     message += ", " + key.replace("$.", "");
      //   }
      // } else if (
      //   Object.prototype.hasOwnProperty.call(responseData, "message")
      // ) {
      //   message = responseData.message;
      // }
      // const error = new Error(message || "Failed to get products");
      throw responseData;
    }
    // console.log(responseData);

    context.commit("setPaymentRequestForAutoGl", responseData);
  },
  resetPaymentRequestForAutoGl(context) {
    context.commit("setPaymentRequestForAutoGl", []);
  },
  async getBudgetSummary(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Budget/Usage/Summary/" + payload.budgetHeaderId + "?DateAt=" + payload.dateAt;
    url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get payment requests"
      );
      throw error;
    }

    // console.log("setBudgetSummary",responseData);

    context.commit("setBudgetSummary", responseData.data);
  },
  resetBudgetSummary(context) {
    context.commit("resetBudgetSummary");
  },
  async cancelPaymentRequest(context, id) {
    if (!id) {
      throw `Id is not define`;
    }
    let url = process.env.VUE_APP_BACKEND_URL + `/PaymentRequest/${id}/Cancel`;
    const fallbackErrorMessage = `Failed to cancel PaymentRequest`
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      let message = null
      if (Object.prototype.hasOwnProperty.call(responseData, 'errors')) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "")
        }
      } else if (Object.prototype.hasOwnProperty.call(responseData, 'message')) {
        message = responseData.message
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }
    context.commit("setSelectedPaymentRequest", responseData.data);
  },
  async getPaymentRequestReferece(context, payload) {
    if (!payload.id) {
      throw `Id is not define`;
    }
    let url = process.env.VUE_APP_BACKEND_URL + `/PaymentRequest/${payload.id}/Reference?IsListPage=${payload.isListPage}`;
    const fallbackErrorMessage = `Failed to Get PaymentRequest Reference`
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      let message = null
      if (Object.prototype.hasOwnProperty.call(responseData, 'errors')) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "")
        }
      } else if (Object.prototype.hasOwnProperty.call(responseData, 'message')) {
        message = responseData.message
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }
    context.commit("setPaymentRequestReference", responseData.data);
  },
  async updateLockPaymentRequest(context, id) {
    let url = process.env.VUE_APP_BACKEND_URL + `/PaymentRequest/${id}/Lock`;
    const fallbackErrorMessage = `Failed to Lock PaymentRequest`
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      let message = null
      if (Object.prototype.hasOwnProperty.call(responseData, 'errors')) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "")
        }
      } else if (Object.prototype.hasOwnProperty.call(responseData, 'message')) {
        message = responseData.message
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }
    context.commit("setSelectedPaymentRequest", responseData.data);
  },
};
