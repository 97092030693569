<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '70vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="titleHeaderImportData"
      :wordingConfirm="titleValidateImportData"
      :dataButtons="dataButtonDialog"
      @close="showValidateDialog = false"
    ></base-dialog>

    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">{{ lang.uploadFile }}</label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          :disabled="shouldDisableCloseButton"
          @click="closeDialog"
        />
      </div>
    </template>
    <div class="dropZone">
      <input
        type="file"
        id="dropZone-file"
        class="dropZone-file"
        ref="dropZoneFile"
        @click="resetFile"
        @change="handleFiles"
        multiple
        accept=".xlsx"
      />
      <div
        class="dropZone-wrapper"
        @dragenter.prevent=""
        @dragover.prevent=""
        @drop.prevent="handleFiles"
      >
        <label for="dropZone-file" class="cursor-pointer">
          <div class="pt-6"></div>
          <PvButton class="cursor-pointer p-button-rounded show-icon" disabled="disabled">
            <icon-cloud-storage-upload iconColor="white" />
          </PvButton>
          <div class="cursor-pointer dropZone-label-text pt-4">
            <label for="dragFile" class="cursor-pointer font-md p-text-secondary">{{
              lang.dragFile
            }}</label>
            <label
              for="addFile"
              class="cursor-pointer font-md p-text-primary"
              @click="addFile"
              >{{ lang.addFile }}</label
            >
            <input
              id="add-file"
              type="file"
              style="display: none"
              accept=".xlsx"
              @click="resetFile"
              @change="handleFiles"
              class="cursor-pointer"
            />
          </div>
          <div class="cursor-pointer font-xs p-text-secondary pt-2 pb-8">
            <label for="importFileDesc" class="cursor-pointer">{{ lang.importFileDesc }}</label>
            <label
              for="downloadfile"
              class="cursor-pointer p-text-primary"
              @click="downloadfile"
              >{{ lang.downloadfile }}</label
            >
          </div>
        </label>
      </div>
    </div>

    <div class="my-5 pt-6">
      <PvDataTable
        :value="files"
        dataKey="id"
        responsiveLayout="scroll"
        class="p-datatable-xs"
        :class="textTableClass"
      >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
        <PvColumn
          style="width: 87px; height: 56px;"
          headerClass="h-center"
        >
          <template #body>
            <PvButton
              v-if="isOpen"
              type="button"
              style="background: white; border: white; opacity: 1"
              disabled="disabled"
            >
              <icon-documents-file-checkmark iconColor="secondary" />
            </PvButton>
          </template>
        </PvColumn>

        <PvColumn
          field="detailName"
          :header="lang.fileName"
          style="min-width: 12rem"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-left font-normal p-text-primary"
        >
        </PvColumn>

        <PvColumn
          style="width: 2rem; height: 56px"
          headerClass="h-center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="">
            <PvButton
              v-if="isOpen"
              type="button"
              class="p-button-danger"
              style="background: white; border: white"
              @click="deleteFile"
            >
              <icon-trash-delete-bin iconColor="error" />
            </PvButton>
          </template>
        </PvColumn>
      </PvDataTable>
    </div>

    <div class="flex justify-content-center pt-4">
      <PvButton class="w-3" @click="uploadFile" :disabled="checkDisable">
        <span class="w-full text-center font-md font-normal p-button-label">{{
          uploadButtonText
        }}</span>
      </PvButton>
    </div>
    <template #footer></template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import * as XLSX from "xlsx";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useImportExcel from "../../../hooks/importExcel.js"
export default {
  emits: ["close"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();

    const { getDownloadBlob } = useGetFormPrint();

    const { formatCurrencyTable, formatDate, formatDateToISOString } =
      useFormat();
    const { validateDate, convertDate, validatePeriod, convertPeriod } = useImportExcel();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.receipt.import,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.receipt.import,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      getDownloadBlob,
      formatCurrencyTable,
      formatDate,
      formatDateToISOString,
      validateDate,
      convertDate,
      validatePeriod,
      convertPeriod,
    };
  },
  data() {
    return {
      uploadFiles: [],
      isUpLoading: false,
      isShowDragDrop: false,
      isShowUploadLoading: false,
      fileName: "",

      files: [{}],
      file: null,
      isOpen: false,
      value1: 0,
      isUploaded: false,

      arrayBuffer: null,
      rawDatas: [],

      isShowUploading: false,
      uploadingData: [],
      itemTotal: 0,
      itemCount: 0,
      percentage: 0,

      showReceiptListDialog: false,
      openReceiptList: false,
      // receipts: [],
      validatedReceipts: [],
      mappedImportReceipts: [],
      isValidateData: true,

      titleHeaderImportData: this.lang.notificationDialogHeader,
      titleValidateImportData: "",
      dataButtonDialog: [
        {
          id: "close",
          caption: this.lang.confirm,
          class: "w-full",
          action: "close",
        },
      ],
      showValidateDialog: false,

      details: [],
      processingText: "",
    };
  },
  interval: null,
  methods: {
    setLoading(bool) {
      this.isUpLoading = bool;
      // this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    initiateData() {
      this.files = [{}];
      this.isOpen = false;
      this.file = null;
    },
    validateFileCount() {
      let check = this.uploadFiles.length;
      let textError = "";
      this.uploadFiles.forEach((file) => {
        textError += file.name + ", ";
      });
      let name = textError.substring(0, textError.length - 2);
      if (check > 1) {
        // this.$toast.add({
        //   severity: "error", //success, warn, error
        //   summary: this.lang.validateLimitFile, //TitleHeader
        //   detail: this.lang.validateLimitDocumentDetail.replace(":name", name),
        //   life: 5000, //TimeClose
        // });
        this.titleHeaderImportData = this.lang.validateLimitFile;
        (this.titleValidateImportData = this.lang.validateFileType.replace(
          ":name",
          name
        )),
          (this.showValidateDialog = true);

        return true;
      }
    },
    validateFileType() {
      let error = "";
      if (
        this.file.type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        // this.$toast.add({
        //   severity: "error", //success, warn, error
        //   summary: this.lang.validateFileType.replace(":name", this.file.name), //TitleHeader
        //   detail: this.lang.validateImportFile,
        //   life: 3000, //TimeClose
        // });
        this.titleHeaderImportData = this.lang.validateImportFile;
        this.titleValidateImportData = this.lang.validateFileType.replace(
          ":name",
          this.file.name
        );
        this.showValidateDialog = true;

        error = "error";
      }

      if (error === "error") return true;
      else return false;
    },
    async handleFiles(event) {
      const inputValue = event.target.files || event.dataTransfer.files;
      this.uploadFiles = Array.from(inputValue);

      if (this.validateFileCount()) {
        return;
      } //เช็คจำนวนไฟล์

      this.file = inputValue[0];

      if (this.validateFileType()) {
        return;
      } //เช็คประเภทไฟล์

      this.isOpen = true;
      let fileName = this.file.name;
      this.files = [
        {
          detailName: fileName,
        },
      ];
    },
    async validateData() {
      this.setLoading(true);
      for (const data in this.rawDatas) {
        let rowNumber = parseInt(data) + 1;
        let receipt = this.rawDatas[data];
        if (!receipt.UnitCode) {
          this.titleValidateImportData = this.lang.validateUnitCode.replace(
            ":row",
            rowNumber
          );
          this.showValidateDialog = true;
          this.setLoading(false);
          return true;
        }

        if (!receipt.ReceiptDate) {
          this.titleValidateImportData = this.lang.validateReceiptDate.replace(
            ":row",
            rowNumber
          );
          this.showValidateDialog = true;
          this.setLoading(false);
          return true;
        }

        if (receipt.ReceiptDate) {
          let result = this.validateDate(receipt.ReceiptDate);
          if (!result) {
            this.titleValidateImportData =
              this.lang.validateFormatReceiptDate.replace(":row", rowNumber);
            this.showValidateDialog = true;
            this.setLoading(false);
            return true;
          }
        }

        if (!receipt.ProductCode) {
          this.titleValidateImportData = this.lang.validateProductCode.replace(
            ":row",
            rowNumber
          );
          this.showValidateDialog = true;
          this.setLoading(false);
          return true;
        }

        if (!receipt.Period) {
          this.titleValidateImportData = this.lang.validatePeriod.replace(
            ":row",
            rowNumber
          );
          this.showValidateDialog = true;
          this.setLoading(false);
          return true;
        }

        if (receipt.Period) {
          let result = this.validatePeriod(receipt.Period);
          if (!result) {          
            this.titleValidateImportData =
              this.lang.validateFormatPeriod.replace(":row", rowNumber);
            this.showValidateDialog = true;
            this.setLoading(false);
            return true;
          }
        }

        if (!receipt.TotalAmount && receipt.TotalAmount != "") {
          this.titleValidateImportData = this.lang.validateTotalAmount.replace(
            ":row",
            rowNumber
          );
          this.showValidateDialog = true;
          this.setLoading(false);
          return true;
        }

        if (receipt.TotalAmount && receipt.TotalAmount < 0) {
          this.titleValidateImportData =
            this.lang.validateFormatTotalAmount.replace(":row", rowNumber);
          this.showValidateDialog = true;
          this.setLoading(false);
          return true;
        }

        if (receipt.TotalAmount) {    
          let text = /^-?\d+(\.\d+)?$/;
          let totalAmount = receipt.TotalAmount.toString().replaceAll(',', '');
          if (isNaN(parseFloat(totalAmount)) || !text.test(totalAmount)) {
            this.titleValidateImportData =
              this.lang.validateFormatTotalAmount.replace(":row", rowNumber);
            this.showValidateDialog = true;
            this.setLoading(false);
            return true;
          }
          receipt.TotalAmount = totalAmount;
        }

        if (!receipt.PayType) {
          this.titleValidateImportData = this.lang.validatePayType.replace(
            ":row",
            rowNumber
          );
          this.showValidateDialog = true;
          this.setLoading(false);
          return true;
        }

        let payType = receipt.PayType.toLowerCase();

        if (receipt.PayType && payType == "e_pay") {
          this.titleValidateImportData = this.lang.validatePayTypeEpay.replace(
            ":row",
            rowNumber
          );
          this.showValidateDialog = true;
          this.setLoading(false);
          return true;
        }

        if (
          receipt.PayType &&
          payType != "cash" &&
          payType != "transfer" &&
          payType != "cheque" &&
          payType != "creditcard" &&
          payType != "deposit" &&
          payType != "other" &&
          payType != "เงินสด" &&
          payType != "เงินโอน" &&
          payType != "เช็ค" &&
          payType != "บัตรเครดิต" &&
          payType != "หักจากเงินฝากชำระ" &&
          payType != "อื่นๆ"
        ) {
          this.titleValidateImportData = this.lang.validateFormatPayType
            .replace(":row", rowNumber)
            .replace(":payType", receipt.PayType);
          this.showValidateDialog = true;
          this.setLoading(false);
          return true;
        }

        if (
          (payType == "transfer" || payType == "cheque") &&
          !receipt.PayCode
        ) {
          this.titleValidateImportData = this.lang.validatePayCode
            .replace(":row", rowNumber)
            .replace(":type", receipt.PayType);
          this.showValidateDialog = true;
          this.setLoading(false);
          return true;
        }

        if (payType == "creditcard" && !receipt.PayCode) {
          this.titleValidateImportData = this.lang.validateCreditCardPayCode
            .replace(":row", rowNumber)
            .replace(":type", receipt.PayType);
          this.showValidateDialog = true;
          this.setLoading(false);
          return true;
        }

        if (
          (payType == "transfer" ||
            payType == "cheque" ||
            payType == "creditcard") &&
          !receipt.BookBankCode
        ) {
          this.titleValidateImportData = this.lang.validateBookBankCode
            .replace(":row", rowNumber)
            .replace(":type", receipt.PayType);
          this.showValidateDialog = true;
          this.setLoading(false);
          return true;
        }

        if (
          (payType == "cash" ||
            payType == "transfer" ||
            payType == "cheque" ||
            payType == "creditcard") &&
          !receipt.PayDate
        ) {
          this.titleValidateImportData = this.lang.validatePayDate.replace(
            ":row",
            rowNumber
          );
          this.showValidateDialog = true;
          this.setLoading(false);
          return true;
        }

        if (receipt.PayDate) {
          let result = this.validateDate(receipt.PayDate);
          if (!result) {
            this.titleValidateImportData =
              this.lang.validateFormatPayDate.replace(":row", rowNumber);
            this.showValidateDialog = true;
            this.setLoading(false);
            return true;
          }
        }
      }
      this.setLoading(false);
      return false;
    },
    // async valiDateDataDialog() {
      // let validatedImportReceipt = this.validatedImportReceipt;
      // if (validatedImportReceipt.code == "120") {
      //   this.titleValidateImportData = validatedImportReceipt.message;
      //   this.showValidateDialog = true;
      //   return;
      // }

      // if (validatedImportReceipt.code == "119") {
      //   this.titleValidateImportData =
      //     this.lang.validateUnitCodeInvalid.replace(
      //       ":unitCode",
      //       validatedImportReceipt.data.UnitCode
      //     );
      //   this.showValidateDialog = true;
      //   return;
      // }

      // if (validatedImportReceipt.code == "118") {
      //   this.titleValidateImportData =
      //     this.lang.validateProductCodeInvalid.replace(
      //       ":unitCode",
      //       validatedImportReceipt.data.UnitCode
      //     );
      //   this.showValidateDialog = true;
      //   return;
      // }

      // if (validatedImportReceipt.code == "103") {
      //   this.titleValidateImportData =
      //     this.lang.validateBookBankCodeInvalid.replace(
      //       ":unitCode",
      //       validatedImportReceipt.data.UnitCode
      //     );
      //   this.showValidateDialog = true;
      //   return;
      // }
    // },
    async validateImportReceipt() {
      this.setLoading(true);
      try {
        let rawDatas = this.rawDatas;
        let groupBy = ["UnitCode", "ReceiptDate", "PayType", "ProductCode", "Period"];
        let groupedRawDatas = Object.values(
          rawDatas.reduce((r, o) => {
            const key = groupBy.map((k) => o[k]).join("|");
            (r[key] ??= []).push(o);
            return r;
          }, {})
        );
        let tempImportDetails = [];
        for (const rawData of groupedRawDatas) {
          const totalAmount = rawData.reduce((accumulator, object) => {
            return accumulator + object.TotalAmount;
          }, 0);

          let convertPeriod = this.convertPeriod(rawData[0].Period)
          
          let tempImportDetail = {
            unitCode: rawData[0].UnitCode.toString(),
            productCode: rawData[0].ProductCode,
            period: convertPeriod,
            description: rawData[0].Description ? rawData[0].Description.toString() : "",
            receiptDate: this.convertDate(rawData[0].ReceiptDate),
            bookBankCode: rawData[0].BookBankCode ? rawData[0].BookBankCode.toString() : "",
            branch: rawData[0].Branch,
            payCode: rawData[0].PayCode ? rawData[0].PayCode.toString() : "",
            payDate: this.convertDate(rawData[0].PayDate),
            payType: rawData[0].PayType,
            paidBy: rawData[0].PaidBy ? rawData[0].PaidBy.toString() : "",
            paidAddress: rawData[0].PaidAddress ? rawData[0].PaidAddress.toString() : "",
            referenceCode: rawData[0].ReferenceCode ? rawData[0].ReferenceCode.toString() : "",
            totalAmount: totalAmount,
          };
          tempImportDetails.push(tempImportDetail);
        }

        groupBy = ["unitCode", "receiptDate", "payType"];
        let groupedHeaders = Object.values(
          tempImportDetails.reduce((r, o) => {
            const key = groupBy.map((k) => o[k]).join("|");
            (r[key] ??= []).push(o);
            return r;
          }, {})
        );
        let itemNo = 1;
        let index = 1;

        for (const groupedHeader of groupedHeaders) {
          // let type = groupedHeader[0].payType.toLowerCase();
          // let payType = "";

          // if (type == "cash" || type == "เงินสด") {
          //   payType = "Cash";
          // } else if (type == "transfer" || type == "เงินโอน") {
          //   payType = "Transfer";
          // } else if (type == "cheque" || type == "เช็ค") {
          //   payType = "Cheque";
          // } else if (type == "creditcard" || type == "บัตรเครดิต") {
          //   payType = "CreditCard";
          // } else if (type == "deposit" || type == "หักจากเงินฝากชำระ") {
          //   payType = "Deposit";
          // } else if (type == "other" || type == "อื่นๆ") {
          //   payType = "Other";
          // }

          let payload = {
            projectId: this.selectedProject.id,
            unitCode: groupedHeader[0].unitCode,
            receiptDate: this.formatDateToISOString(
              groupedHeader[0].receiptDate
            ),
            referenceCode: groupedHeader[0].referenceCode
              ? groupedHeader[0].referenceCode
              : "",
            payType: groupedHeader[0].payType,
            payCode: groupedHeader[0].payCode ? groupedHeader[0].payCode : "",
            bookBankCode: groupedHeader[0].bookBankCode
              ? groupedHeader[0].bookBankCode
              : "",
            branch: groupedHeader[0].branch,
            payDate: this.formatDateToISOString(groupedHeader[0].payDate),
            paidBy: groupedHeader[0].paidBy ? groupedHeader[0].paidBy : "",
            paidAddress: groupedHeader[0].paidAddress
              ? groupedHeader[0].paidAddress
              : "",
            details: groupedHeader,
          };

          this.processingText = `${this.lang.validatingData} ${index}/${groupedHeaders.length}`;
          index++;
          await this.$store.dispatch(
            "arReceipt/validateAndPrepareImportReceipt",
            payload
          );
          // await this.valiDateDataDialog();
          this.isValidateData = this.validatedImportReceipt.code === "000"
          if(!this.isValidateData)
          {
            if(this.validatedImportReceipt.code == "118"){
              this.titleValidateImportData = this.lang.productCodeIsNotValid.replace(":productCode", this.validatedImportReceipt.data.details[0].productCode);
            }else if(this.validatedImportReceipt.code == "119"){
              this.titleValidateImportData = this.lang.unitCodeIsNotValid.replace(":unitCode", groupedHeader[0].unitCode);
            }else if(this.validatedImportReceipt.code == "120"){
              this.titleValidateImportData = this.lang.unitCodeIsNotActive.replace(":unitCode", groupedHeader[0].unitCode);
            }else{
              this.titleValidateImportData = this.validatedImportReceipt.message;
            }
            this.showValidateDialog = true;
            this.isUpLoading = false;
            return
          } else {
            let validatedImportReceipt = {
              ...this.validatedImportReceipt.data,
              itemNo: itemNo,
              branch: payload.branch,
            };
            this.validatedReceipts.push(validatedImportReceipt);
            itemNo++;
          }
        }
      } catch (error) {
        this.titleValidateImportData = error;
        this.showValidateDialog = true;
        this.setLoading(false);
        return;
      }
    },
    // async validateImportReceipt_bck() {
    //   this.rawDatas = this.rawDatas.sort(
    //     (a, b) =>
    //       a.UnitCode.localeCompare(b.UnitCode) ||
    //       a.ReceiptDate.toString().localeCompare(b.ReceiptDate.toString()) ||
    //       a.ProductCode.localeCompare(b.ProductCode) ||
    //       a.Period.split("/").pop().localeCompare(b.Period.split("/").pop()) ||
    //       a.Period.substring(0, 2).localeCompare(b.Period.substring(0, 2))
    //   );

    //   this.setLoading(true);
    //   let itemNo = 1;
    //   for (let data = 0; data < this.rawDatas.length; data++) {
    //     try {
    //       let receipt = this.rawDatas[data];
    //       let rowNumber = parseInt(data);
    //       let firstData = this.rawDatas[rowNumber];

    //       let detail = {
    //         unitCode: firstData.UnitCode.toString(),
    //         productCode: firstData.ProductCode,
    //         period: firstData.Period,
    //         description: firstData.Description,
    //         totalAmount: firstData.TotalAmount,
    //       };
    //       this.details.push(detail);

    //       let nextItemNo = parseInt(data) + 1;

    //       for (let i = nextItemNo; i < this.rawDatas.length; i++) {
    //         let nextItem = this.rawDatas[i];

    //         if (
    //           receipt.UnitCode == nextItem.UnitCode &&
    //           receipt.ProductCode == nextItem.ProductCode &&
    //           receipt.Period == nextItem.Period &&
    //           receipt.ReceiptDate == nextItem.ReceiptDate
    //         ) {
    //           detail.totalAmount =
    //             parseInt(detail.totalAmount) + parseInt(nextItem.TotalAmount);
    //           data++;
    //         } else if (
    //           receipt.UnitCode == nextItem.UnitCode &&
    //           receipt.ReceiptDate == nextItem.ReceiptDate &&
    //           receipt.PayType == nextItem.PayType &&
    //           receipt.PayCode == nextItem.PayCode &&
    //           receipt.BookBankCode == nextItem.BookBankCode &&
    //           receipt.PayDate == nextItem.PayDate
    //         ) {
    //           let detail = {
    //             unitCode: nextItem.UnitCode.toString(),
    //             productCode: nextItem.ProductCode,
    //             period: nextItem.Period,
    //             description: nextItem.Description,
    //             totalAmount: nextItem.TotalAmount,
    //           };
    //           this.details.push(detail);
    //           data++;
    //         }
    //       }

    //       let receiptDate = this.convertDate(receipt.ReceiptDate);
    //       let payDate = this.convertDate(receipt.PayDate);
    //       // let type = receipt.PayType.toLowerCase();
    //       // let payType = "";
    //       let period = receipt.Period;
    //       let year = period.split("/").pop().toString();
    //       let month = period.split("/").shift().toString();
    //       if (month.length === 1) {
    //         period = "0" + month + "/" + year;
    //       }

    //       // if (type == "cash" || type == "เงินสด") {
    //       //   payType = "Cash";
    //       // } else if (type == "transfer" || type == "เงินโอน") {
    //       //   payType = "Transfer";
    //       // } else if (type == "cheque" || type == "เช็ค") {
    //       //   payType = "Cheque";
    //       // } else if (type == "creditcard" || type == "บัตรเครดิต") {
    //       //   payType = "CreditCard";
    //       // } else if (type == "deposit" || type == "หักจากเงินฝากชำระ") {
    //       //   payType = "Deposit";
    //       // } else if (type == "other" || type == "อื่นๆ") {
    //       //   payType = "Other";
    //       // }

    //       let payload = {
    //         projectId: this.selectedProject.id,
    //         unitCode: receipt.UnitCode.toString(),
    //         receiptDate: this.formatDateToISOString(receiptDate),
    //         referenceCode: receipt.ReferenceCode ? receipt.ReferenceCode : "",
    //         payType: receipt.PayType,
    //         payCode: receipt.PayCode ? receipt.PayCode : "",
    //         bookBankCode: receipt.BookBankCode ? receipt.BookBankCode : "",
    //         payDate: this.formatDateToISOString(payDate),
    //         paidBy: receipt.PaidBy ? receipt.PaidBy : "",
    //         paidAddress: receipt.PaidAddress ? receipt.PaidAddress : "",
    //         details: this.details,
    //       };

    //       // await this.$store.dispatch("arReceipt/validateImportReceipt",payload);
    //       await this.$store.dispatch(
    //         "arReceipt/validateAndPrepareImportReceipt",
    //         payload
    //       );
          
    //       await this.valiDateDataDialog();

    //       if (!this.validatedImportReceipt.data) {
    //         this.isValidateData = false;
    //       } else {
            
    //         let validatedImportReceipt = {
    //           ...this.validatedImportReceipt.data,
    //           itemNo: itemNo,
    //         };
    //         this.validatedReceipts.push(validatedImportReceipt);
    //         itemNo++;
    //       }

    //       this.details = [];
    //     } catch (error) {
    //       this.titleValidateImportData = error
    //       this.showValidateDialog = true;
    //       this.setLoading(false);
    //       return;
    //     }
    //   }
    //   // this.setLoading(false);
    // },
    async mappingInvoice() {
      this.setLoading(true);
      let index = 1;
      let length = 0;
      for (let validatedReceipt of this.validatedReceipts) {
        length += validatedReceipt.details.length;
      }
      for (let validatedReceipt of this.validatedReceipts) {
        let headerTotalAmount = 0;
        let isError = false;
        let mappedImportReceipt = null;
        let mappedDetails = [];
        // let tempMappedImportReceipts = [];

        for (const detail of validatedReceipt.details) {
          try {
            let usedAmount = this.getUsedAmountFromMappedReceipt(
              validatedReceipt.unitId,
              detail.productId,
              detail.period
            );
            let payload = {
              projectId: this.selectedProject.id,
              unitId: validatedReceipt.unitId,
              productId: detail.productId,
              period: detail.period,
              description: detail.description ? detail.description : "",
              amount: detail.totalAmount,
              usedAmount: usedAmount,
            };

            this.processingText = `${this.lang.mappingInvoice} ${index}/${length}`;
            index++;
            await this.$store.dispatch(
              "arReceipt/mappingInvoiceWithImportReceipt",
              payload
            );

            mappedImportReceipt = this.mappedImportReceipt;

            // let isError = false;
            for (const data of mappedImportReceipt.data) {
              let error = "";
              if (data.referenceId && data.totalAmount > data.invoiceAmount) {
                //เช็ค pendingAmount
                error = this.lang.errorDetail;
                isError = true;
              }
              mappedDetails.push({
                ...data,
                error: error,
                itemNo: validatedReceipt.itemNo,
              });
              headerTotalAmount += data.totalAmount;
            }

            // this.selectedReceipt.data = details;
            // mappedImportReceipt = mappedDetails;
          } catch (error) {
            this.titleValidateImportData = error
            this.showValidateDialog = true;
            this.setLoading(false);
            return;
          }
        }

        if (isError) {
          validatedReceipt.status = "error";
        }
        validatedReceipt.details = mappedDetails;
        validatedReceipt.totalAmount = headerTotalAmount;
        validatedReceipt = { ...validatedReceipt, error: isError };
        this.mappedImportReceipts.push(validatedReceipt);
      }

      this.setLoading(false);
    },
    getPayType(type) {
      let payType = "";
      if (type == "cash" || type == "เงินสด") {
        payType = "Cash";
      } else if (type == "transfer" || type == "เงินโอน") {
        payType = "Transfer";
      } else if (type == "cheque" || type == "เช็ค") {
        payType = "Cheque";
      } else if (type == "creditcard" || type == "บัตรเครดิต") {
        payType = "CreditCard";
      } else if (type == "deposit" || type == "หักจากเงินฝากชำระ") {
        payType = "Deposit";
      } else if (type == "other" || type == "อื่นๆ") {
        payType = "Other";
      } else {
        payType = type;
      }
      return payType;
    },
    getUsedAmountFromMappedReceipt(unitId, productId, period) {
      let mappedImportReceipts = this.mappedImportReceipts.filter(
        (c) => c.unitId == unitId
      );
      if (mappedImportReceipts.length > 0) {
        let usedAmount = 0;
        for (const receipt of mappedImportReceipts) {
          let detail = receipt.details.filter(
            (c) => c.productId == productId && c.period == period
          );
          if (detail) {
            usedAmount += this.calculateSum(detail, "totalAmount");
          }
        }
        return usedAmount;
      }
      return 0;
    },
    calculateSum(array, property) {
      const total = array.reduce((accumulator, object) => {
        return accumulator + object[property];
      }, 0);

      return total;
    },
    uploadFile() {
      if (this.file) {
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(this.file);
        fileReader.onload = async () => {
          this.arrayBuffer = fileReader.result;
          let data = new Uint8Array(this.arrayBuffer);
          let arr = [];
          for (let i = 0; i != data.length; ++i)
            arr[i] = String.fromCharCode(data[i]);
          let bstr = arr.join("");
          let workbook = XLSX.read(bstr, { type: "binary" });
          let first_sheet_name = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[first_sheet_name];
          this.rawDatas = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          this.itemTotal = this.rawDatas.length;

          if (this.rawDatas.length == 0) {
            this.titleValidateImportData =
              this.lang.validateImportNoData.replace(":file", this.file.name);
            this.showValidateDialog = true;
            return;
          } else {
            //แก้ปัญหา User payType มาเป็นภาษาไทย
            this.rawDatas.forEach((element) => {
              element.PayType = this.getPayType(element.PayType);
            });
            if (await this.validateData()) {
              return;
            } else {
              //แก้ปัญหา User ใส่ตัวเลขในช่อง total amount เป็น Text
              this.rawDatas.forEach((element) => {
                element.TotalAmount = parseFloat(element.TotalAmount);
              });
              await this.validateImportReceipt();
              //ถ้าตรวจแล้วข้อมูลมีปัญหาให้หยุดเลย
              if (!this.isValidateData) {
                return
              }
              await this.mappingInvoice();
            }
          }

          await this.$store.dispatch(
            "arReceipt/importReceiptList",
            this.mappedImportReceipts
          );
          this.$router.push({ name: "ar-receipt-import" })

          // if (this.isValidateData == true) {
          //   // this.openReceiptList = true;
          //   // this.showReceiptListDialog = true;
          //   this.$router.push("/ar/receipt/import");
          // }
        };
      } else {
        // this.$toast.add({
        //   severity: "warn", //success, warn, error
        //   summary: this.lang.validateAddfile,
        //   detail: "",
        //   life: 5000, //TimeClose
        // });
        this.titleValidateImportData = this.lang.validateAddfile
        this.showValidateDialog = true;
        return true;
      }
    },
    async downloadfile() {
      this.setLoading(true);
      let payload = {
        docType: "excel",
        path: "receipt/import_receipt_form.xlsx",
        fileName: "ImportReceiptForm",
        parameter: "{}",
      };
      try {
        await this.$store.dispatch("shared/printReport", payload);
        const d = new Date();
        let dateText = d.toISOString();
        this.getDownloadBlob(
          `${payload.fileName}_${dateText}.xlsx`,
          this.fileBlob
        );
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        this.setLoading(false);
      }
      this.setLoading(false);
    },
    addFile() {
      document.getElementById("add-file").click();
    },
    deleteFile() {
      this.initiateData();
    },
    resetFile(event) {
      if (event.target.files.length > 0) {
        event.target.value = null;
      }
    },
    closeReceiptListDialog() {
      this.showReceiptListDialog = false;
      this.mappedImportReceipts = [];
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    fileBlob() {
      return this.$store.getters["shared/blob"];
    },
    checkDisable() {
      if (!this.file || this.isUpLoading) {
        return true;
      } else {
        return false;
      }
    },
    mappedImportReceipt() {
      return this.$store.getters["arReceipt/mappedImportReceipt"];
    },
    validatedImportReceipt() {
      return this.$store.getters["arReceipt/validatedImportReceipt"];
    },
    uploadButtonText() {
      if (!this.isUpLoading) {
        return this.lang.uploadFile;
      }
      return this.processingText;
    },
    shouldDisableCloseButton() {
      return this.isUpLoading;
    },
  },
};
</script>

<style scoped>
.dropZone {
  box-sizing: border-box;
  width: 100%;
  background: #fafafa;
  border: 2px dashed #ced0d6;
  border-radius: 10px;
  text-align: center;
  height: 194px;
  margin-top: 2rem;
}

.dropZone-file {
  position: absolute;
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.dropZone-wrapper {
  display: grid;
  width: 100%;
  height: 100%;
}

.p-progressbar {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.p-datatable {
  text-align: center !important;
  overflow: visible !important;
}

.base-center {
  width: 75%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.show-icon {
  opacity: 1;
  width: 48px;
  height: 48px;
  padding: 0.75rem;
}

.base-margin {
  margin-top: 37px;
  margin-bottom: 37px;
}

.p-pagination-radius {
  border-radius: 0 0 12px 12px;
}
</style>
