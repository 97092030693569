export default {
	titleEdit: "Phone Book Detail",
	createPhoneBook: "Create Phone Book",
	editPhoneBook: "Edit Phone Book",

	contactInfo : "Contact Information",
	phoneInfo: "Phone Information",
	contactType: "Contact Type",
	contactTypePlaceholder: "Please select contact type.",
	nameTh: "Name (Thai)",
	nameEn: "Name (English)",
	phoneType: "Phone Type",
	phoneTypePlaceholder: "Please select phone type.",
    phoneNumber: "Phone Number",
	phoneExtension: "Phone Extension",
	activeStatus: "Active Status",
	validateSequence: "Please fill sequence.",
	validateNameTh: "Please fill name (Thai).",
	validatePhoneNumber: "Please fill phone number.",

    createNewPhoneBook: "Create new phone book",
    askForCreateNewPhoneBook: "Are you sure you want to discard change and create new ?",
    deletePhoneBookTitle: "Confirm Delete",
    askForDeletePhoneBook: "Are you sure you want to delete phone book :name ?",
	dialogContentSave: "Save successfully.",
};
