import { useStore } from "vuex";
import { ref, computed } from "vue";
export default function useGetAPBook() {
  const store = useStore();
  let searchText = ref(null);

  const apBooks = computed(() => {
    return store.getters["shared/apBooks"];
  });

  const apBookIsLastPage = computed(() => {
    return store.getters["shared/apBookIsLastPage"];
  });

  const filteredAPBooks = computed(() => {
    if (!searchText.value) {      //ช่องว่าง      
      const result = [...apBooks.value];
      return result;
    } else {
      return apBooks.value.filter((apBook) => {
        return apBook.bookCode
          .toLowerCase()
          .includes(searchText.value.toLowerCase())
          || apBook.bookCode
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const searchAPBook = function (event) {
    //  console.log("searchGlBook=",event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchText.value = "";
      searchText.value = null;
    } else {
      searchText.value = event.query;
    }
  };

  let isSharedAPBookReady = ref(true);

  const getAllAPBooks = async function () {
    if (!apBookIsLastPage.value) {
      await store.dispatch("shared/getAllAPBooks");
    }
  };

  const setBookCode = async function (payload) {
    isSharedAPBookReady.value = true;  
    await store.dispatch("shared/resetAPBook");
    await store.dispatch("shared/getAllAPBooks",payload);       
    isSharedAPBookReady.value = false;
  };

  return {
    searchAPBook,
    filteredAPBooks,
    searchText,
    isSharedAPBookReady,
    getAllAPBooks,
    setBookCode,
    apBooks,
  };
}
