import { useStore } from "vuex";
import { computed } from "vue";
export default function useGetImportExistUnitResident() {

    const store = useStore();

    const params = computed(() => {
        return store.getters["importExistUnitResident/params"];
    });

    const resetImportExistUnitResident = () => {
        store.dispatch("importExistUnitResident/resetImportExistUnitResidents");
    };
  
    const setImportExistUnitResidentParams = (data, field) => {
        const uniqueDatas = [...new Set(data.map(item => item[field]))];
        store.dispatch("importExistUnitResident/setParams",uniqueDatas);
    };

    const loadImportExistUnitResident = async (field = "unitCode",limit = 100) => {
        let payload = {
            field: field,
            limit: limit ,
        };
        if (params.value && params.value.length > 0) {
            await store.dispatch("importExistUnitResident/getImportExistUnitResidents", payload);
        }
    }    

    const getImportExistUnitResident = () => {
        return store.getters["importExistUnitResident/importExistUnitResidents"];
    }

    return {
        resetImportExistUnitResident,
        setImportExistUnitResidentParams,
        loadImportExistUnitResident,
        getImportExistUnitResident,
    };
}