export default {
	createResident:"สร้างสมาชิก/ลูกค้า",
	editResident:"แก้ไขสมาชิก/ลูกค้า",
	labelFromResident: "สมาชิก/ลูกค้า",
	generalInfo: "ข้อมูลทั่วไป",
	emergencyContact: "ข้อมูลติดต่อฉุกเฉิน",
	billingContact: "ข้อมูลที่อยู่สำหรับออกเอกสาร (ใบแจ้งหนี้, ใบเสร็จ, ฯลฯ)",
	idCardContact: "ข้อมูลตามที่อยู่บัตรประชาชน",
	contact: "ข้อมูลตามที่อยู่สำหรับติดต่อ",
	living: "ข้อมูลการอยู่อาศัย",

	status: "ประเภทการอยู่อาศัย",

	code: "รหัสสมาชิก/ลูกค้า",
	codePlaceholder: "ระบุรหัสสมาชิก/ลูกค้า",
	validateCode: "กรุณาระบุรหัสสมาชิก/ลูกค้า",

	name: "ชื่อสมาชิก/ลูกค้า",
	namePlaceholder: "ระบุชื่อสมาชิก/ลูกค้า",
	validateName: "กรุณาระบุชื่อสมาชิก/ลูกค้า",
	
	mobileNumber: "เบอร์โทรศัพท์",
	mobileNumberPlaceholder: "ระบุเบอร์โทรศัพท์",
	validateMobileNumber: "กรุณาระบุเบอร์โทรศัพท์",
	validateMobileNumberFormat:"กรุณาระบุเป็นตัวเลขเท่านั้น",

	birthDate: "วันเกิด",
	birthDatePlaceholder: "เลือกวันเกิด",

	email: "อีเมล",
	emailPlaceholder: "ระบุอีเมล",
	validateEmail: "กรุณาระบุอีเมล",
	validateEmailFormat: "กรุณาระบุรูปแบบ E-mail ให้ถูกต้อง",

	livingType: "ประเภทสมาชิก/ลูกค้า*",
	types: [
		{name: 'บุคคลธรรมดา', key: 'individual'}, 
		{name: 'นิติบุคคล', key: 'juristic'}, 
	],

	individualTaxId: "เลขประจำตัวประชาชน/ผู้เสียภาษี",
	juristicTaxId: "เลขประจำตัวผู้เสียภาษี*",
	individualTaxIdPlaceholder: "ระบุเลขประจำตัวประชาชน/ผู้เสียภาษี",
	juristicTaxIdPlaceholder: "ระบุเลขประจำตัวผู้เสียภาษี",
	validateIndividualTaxId: "กรุณาระบุเลขประจำตัวประชาชน",
	validateJuristicTaxId: "กรุณาระบุเลขประจำตัวผู้เสียภาษี",
	validateIndividualTaxIdDigit: "กรุณาระบุเลขประจำตัวประชาชน 13 หลัก ตัวอย่าง 0-0000-00000-00-0",
	validateJuristicTaxIdDigit: "กรุณาระบุเลขประจำตัวผู้เสียภาษี 13 หลัก ตัวอย่าง 0-0000-00000-00-0",

	isSupplier: "คุณต้องการเพิ่มข้อมูลสมาชิกเป็นผู้จัดจำหน่ายหรือไม่",
	yes: "ต้องการ",

	nationality: "สัญชาติ",

	gender: "ประเภทสมาชิก/ ลูกค้า",
	genders: [
		{name: 'ชาย', key: 'Male'}, 
		{name: 'หญิง', key: 'Female'}, 
		{name: 'บริษัท', key: 'company'}, 
	],

	
	contactName: "ชื่อผู้ติดต่อ",
	contactNamePlaceholder: "ระบุชื่อผู้ติดต่อ",

	
	address: "ที่อยู่",
	billingAddressPlaceholder: "ระบุที่อยู่สำหรับออกใบแจ้งหนี้/ใบเสร็จ",
	validateBillingAddress: "กรุณาระบุที่อยู่สำหรับออกใบแจ้งหนี้/ใบเสร็จ",
	postCode: "รหัสไปรษณีย์",
	postCodePlaceholder: "ระบุรหัสไปรษณีย์",
	validatePostCode: "กรุณาระบุรหัสไปรษณีย์",
	validatePostCodeFormat: "กรุณาระบุเป็นตัวเลข 5 หลัก",
	subDistrict: "แขวง/ตำบล",
	subDistrictPlaceholder: "ระบุแขวง/ตำบล",
	district: "แขวง/ตำบล, เขต/อำเภอ",
	districtPlaceholder: "ระบุแขวง/ตำบล, เขต/อำเภอ",
	validateBillingDistrict: "กรุณาระบุแขวง/ตำบล, เขต/อำเภอ",
	province: "จังหวัด",
	provincePlaceholder: "ระบุจังหวัด",
	validateProvince: "กรุณาระบุจังหวัด",
	documentLang: "ภาษาของเอกสาร",
	documentLangItem: [
        { name: "ไทย", code: "Thai" },
        { name: "อังกฤษ", code: "English" },
    ],
	deliveryChannel: "ช่องทางการส่งเอกสาร",
	isGroupDoc: "รวมใบแจ้งหนี้ (สำหรับเจ้าของที่มีหลายห้อง)",
	groupDocs: [
		{name: 'รวม', key: 'Y'}, 
		{name: 'ไม่รวม', key: 'N'}, 
	],
	idCardAddressPlaceholder: "ระบุที่อยู่ตามที่อยู่บัตรประชาชน",
	registerDate: "วันที่ออกบัตร",
	expiredDate: "วันที่หมดอายุ",
	datePlaceholder: "เลือกวันที่",
	contactAddressPlaceholder: "ระบุที่อยู่สำหรับติดต่อ",

	room: "เลขที่ยูนิต/ บ้านเลขที่",
	residentDetail: "รายละเอียดสมาชิก/ลูกค้า",
	createNewResident: "สร้างสมาชิก/ลูกค้าใหม่",
	askForCreateNewResident: "หากคุณสร้างรายการสมาชิก/ลูกค้าใหม่ระบบจะไม่บันทึกการแก้ไข",
	askForCreateNewResidentConfirm: "คุณยืนยันที่จะสร้างรายการสมาชิก/ลูกค้าใหม่ใช่หรือไม่?",
	createResidentSuccess: "คุณสร้างรายการสมาชิก/ลูกค้าสำเร็จ",
	editResidentSuccess: "คุณบันทึกการแก้ไขรายการสมาชิก/ลูกค้าสำเร็จ",
	deleteResident: "ลบข้อมูล",
	askForDeleteResident: "คุณต้องการลบรายการสมาชิก/ลูกค้า :code ใช่หรือไม่?",
	deleteResidentSuccess: "คุณลบรายการสมาชิก/ลูกค้า :code สำเร็จ", 
	groupingInvoice: "รวมใบแจ้งหนี้",

	prefix: "คำนำหน้า",
	countryCode: "รหัสประเทศ",
	formatDate: "(DD/MM/YYYY (ค.ศ.))",
	addressDistrict: "เขต/อำเภอ",
	labelDistrictRemark: "ข้อมูลนี้จะเปลี่ยนตามช่อง แขวง/ตำบล และเขต/อำเภอ",
};
