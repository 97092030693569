export default {
	async getBanks(context, payload) {
		await context.commit("setTypeInOut", payload.type);
		let url = process.env.VUE_APP_BACKEND_URL + "/BankTransaction";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get prepaid refund.");
			throw error;
		}
		// console.log("getBanks_responseData:",responseData);

		// const data = responseData.data;
		context.commit("setBanksAr", responseData);
	},
	async getBank(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/BankTransaction?Page=1&Limit=1";

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}
		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("getBank",responseData);
			const error = new Error(responseData.message || "Failed to get prepaid refund.");
			throw error;
		}
		// console.log("getBank",responseData.data[0]);
		if (responseData.data.length > 0) {
			context.commit("setSelectedBank", responseData.data[0]);
		}
	},
	async getTransactionType(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/BankTransaction/GetTransactionType";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("AddPrepaid_responseData_Error>>>",responseData);
			const error = new Error(responseData.message || "Failed to add prepaid refund");
			throw error;
		}
		//console.log("AddPrepaid_responseData>>>",responseData);
		await context.commit("setTransactionType", responseData.data);
	},
	async getBookBankList(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/BankTransaction/GetBookBankList";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get prepaid refund.");
			throw error;
		}
		// console.log("getBanks_responseData:",responseData);

		// const data = responseData.data;
		context.commit("setBookBankList", responseData);
	},
	async getReceiptNotPayIn(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/BankTransaction/GetReceiptNotPayIn";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);
		if (!payload.limit) {
			url = url + "?limit=20";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("AddPrepaid_responseData_Error>>>",responseData);
			const error = new Error(responseData.message || "Failed to add prepaid refund");
			throw error;
		}
		//console.log("AddPrepaid_responseData>>>",responseData);
		await context.commit("setReceiptNotPayIn", responseData);
	},
	async getReceiptExtra(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/BankTransaction/GetReceiptNotPayIn";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);
		if (!payload.limit) {
			url = url + "?limit=20";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("AddPrepaid_responseData_Error>>>",responseData);
			const error = new Error(responseData.message || "Failed to add prepaid refund");
			throw error;
		}
		//console.log("AddPrepaid_responseData>>>",responseData);
		await context.commit("setReceiptExtra", responseData);
	},
	async getAllBookBanks(context) {
		// console.log(context.getters.bookBankIsLastPage);
		let where = {
			projectId: context.rootGetters.selectedProject.id,
			bankBookUseType: context.getters.typeInOut === 1 ? "R" : "P",
		};
		const payload = {
			page: 1,
			limit: 100,
			where,
		};
		await context.dispatch("getBookBankList", payload);
	},
	async AddBankTransaction(context, payload) {
		// console.log('AddBankTransaction',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/BankTransaction/Add";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("AddBankTransaction>>>",responseData);
			const error = new Error(responseData.message || "Failed to add prepaid refund");
			throw error;
		}
		// console.log("AddPrepaid_responseData>>>", responseData.data);
		await context.commit("setNewBanksAr", responseData.data);
	},
	async EditBankTransaction(context, payload) {
		// console.log('AddBankTransaction',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/BankTransaction/Update";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("AddBankTransaction>>>",responseData);
			const error = new Error(responseData.message || "Failed to add prepaid refund");
			throw error;
		}
		//console.log("AddPrepaid_responseData>>>",responseData);
		await context.commit("setNewBanksAr", responseData.data);
	},
	async CancelPayIn(context, payload) {
		// console.log('AddBankTransaction',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/BankTransaction/Cancel";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("AddBankTransaction>>>",responseData);
			const error = new Error(responseData.message || "Failed to add prepaid refund");
			throw error;
		}
		//console.log("AddPrepaid_responseData>>>",responseData);
		await context.commit("setNewBanksAr", responseData.data);
	},
	async PrintCheque(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/BankTransaction/Print";

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
			mode: "cors", // no-cors, *cors, same-origin
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			credentials: "same-origin", // include, *same-origin, omit
			redirect: "follow", // manual, *follow, error
			referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
		});

		let urlBlob = "";
		let blob = await response.blob();
		urlBlob = window.URL.createObjectURL(blob);
		// console.log("BankTransaction>>>",urlBlob);
		context.commit("setBlob", urlBlob);
	},
	async getBankTransactionWithVouchers(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/BankTransaction/GetBankTransactionWithVouchers";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get prepaid refund.");
			throw error;
		}
		// console.log("getBanks_responseData:",responseData);

		// const data = responseData.data;
		context.commit("setTypeInOut", payload.type);
		context.commit("setBanksAr", responseData);
	},
	async AddBankVoucher(context, payload) {
		// console.log('AddBankTransaction',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/BankTransaction/AddBankVoucher";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("AddBankTransaction>>>",responseData);
			const error = new Error(responseData.message || "Failed to Add Bank Voucher");
			throw error;
		}
		// console.log("AddPrepaid_responseData>>>",responseData.data);
		await context.commit("setNewBanksAr", responseData.data);
	},
	async deleteBankWithVoucher(context, payload) {
		// console.log('AddBankTransaction',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/BankTransaction/DeleteBankWithVoucher";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("deleteBankWithVoucher>>>",responseData);
			const error = new Error(responseData.message || "Failed to delete Bank With Voucher");
			throw error;
		}
		// console.log("deleteBankWithVoucher_responseData>>>", responseData.data);
		await context.commit("setNewBanksAr", payload);
	},
	async resetBank(context) {
		context.commit("resetBank");
	},
	async getAllBanksForSelect(context, payload) {
		context.commit("resetBanksForSelect");
		while (!context.getters.banksForSelectIsLastPage) {
			// let where = payload.where;
			const payloadAllBanksForSelect = {
				limit: payload.limit,
				page: context.getters.banksForSelectNextPage || 1,
				where: payload.where,
			};
			await context.dispatch("getBanksForSelect", payloadAllBanksForSelect);
		}
	},
	async getBanksForSelect(context, payload) {
		await context.commit("setTypeInOut", payload.type);
		let url = process.env.VUE_APP_BACKEND_URL + "/BankTransaction/GetBankTransactionToImport";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get prepaid refund.");
			throw error;
		}
		// console.log("getBanksForSelect_responseData:", responseData);
		responseData.data = [...responseData.data, ...context.getters.banksForSelect];
		context.commit("setBanksForSelect", responseData);
	},
	async resetBanksSelected(context) {
		context.commit("resetBanksSelected");
	},
	async setBanksSelected(context, payload) {
		context.commit("setBanksSelected", payload);
	},
	
	async resetPrintLot(context) {
		context.commit("resetPrintLot");
	},
	async getBanksPrintLot(context, payload) {
		await context.commit("setTypeInOut", payload.type);
		let url = process.env.VUE_APP_BACKEND_URL + "/BankTransaction/GetChequePrintLot";

		if (!payload.limit) {
			url = url + "?limit=100";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get prepaid refund.");
			throw error;
		}
		const printLot = {
			printLots: responseData.data,
			totalPage: responseData.page.pageCount == 0 ? 1 : responseData.page.pageCount,
			currentPage: responseData.page.currentPage, 
		};
		// console.log("getBanks_responseData:",responseData);
		context.commit("setPrintLot", printLot);
	},
 
    async getAllPrintLot(context, payload) {
		while (!context.getters.printLotIsLastPage) {
			let where = payload.where
			const payloadR = {
			page: context.getters.printLotNextPage || 1,
			where,
			};
			await context.dispatch("getBanksPrintLot", payloadR);
		}		
	},
	async PrintLotCheque(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/BankTransaction/PrintLot";

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
			mode: "cors", // no-cors, *cors, same-origin
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			credentials: "same-origin", // include, *same-origin, omit
			redirect: "follow", // manual, *follow, error
			referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
		});

		let urlBlob = "";
		let blob = await response.blob();
		urlBlob = window.URL.createObjectURL(blob);
		// console.log("BankTransaction>>>",urlBlob);
		context.commit("setBlob", urlBlob);
	},

	async setTypeInOut(context, payload) {
		await context.commit("setTypeInOut", payload.type);
	},
  
};
