export default {
  async getAccountBooks(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Accounting/AccountBook";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get accountBook"
      );
      throw error;
    }
    // console.log("getAccountBooks", responseData);

    // const data = responseData.data;

    context.commit("setAccountBook", responseData);
  },
  async getAccountBook(context, id) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Accounting/AccountBook/"+ id;
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get accountBook"
      );
      throw error;
    }
    //console.log(responseData);

    // const data = responseData.data;
    if (responseData.data != null) {
      context.commit("setSelectedAccountBook", responseData.data);
    }
  },
  async createOrUpdateAccountBook(context, payload) {
    //let url = process.env.VUE_APP_BACKEND_URL + `/Accounting/AccountBook/${payload.type}`;
    let url = process.env.VUE_APP_BACKEND_URL + `/Accounting/AccountBook`;
    
    // const fallbackErrorMessage = `Failed to ${payload.id} accountBook`
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: payload.type === 'add' ? 'post' : 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // let message = null
      // if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
      //   message = responseData.title;
      //   for (const key in responseData.errors) {
      //     message += ", " + key.replace("$.","")
      //   }
      // } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
      //   message = responseData.message
      // }
      // const error = new Error(message || fallbackErrorMessage);
      // throw error;
      throw responseData;
    }

    context.commit("setSelectedAccountBook", responseData.data);

  },
  async deleteAccountBook(context, id) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Accounting/AccountBook/${id}`;
    
    //const fallbackErrorMessage = `Failed to ${id} accountBook`
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'delete',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      //body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // let message = null
      // if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
      //   message = responseData.title;
      //   for (const key in responseData.errors) {
      //     message += ", " + key.replace("$.","")
      //   }
      // } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
      //   message = responseData.message
      // }
      // const error = new Error(message || fallbackErrorMessage);
      // throw error;
      throw responseData;
    }
    context.commit("setAccountBook", responseData);
  },
	resetAccountBook(context) {
		context.commit("resetAccountBook");
	},
};
