export default {
    setInvoiceGenerateQueueStatus(state, payload){
        state.invoiceGenerateQueueStatus = payload.data;
    },
    resetInvoiceGenerateQueue(state){
        state.invoiceGenerateQueueStatus = null;
    },
    setInvoiceReadyGenerateBill(state, payload){
        state.invoiceReadyGenerateBill = payload.data;
    },
    resetInvoiceReadyGenerateBill(state){
        state.invoiceReadyGenerateBill = null;
    },
    setSelectedinvoiceGenerateQueue(state, payload){
        state.selectedinvoiceGenerateQueue = payload;
    },
    setInvoice(state, payload){
        state.invoices = payload.data;
        state.invoicesTotalPage = payload.page.pageCount;
        state.invoicesCurrentPage = payload.page.currentPage;
    },
    setSelectedInvoice(state, payload){
        state.selectedInvoice = payload
    },
    setInvoiceIsCanceled(state, payload) {
        state.invoiceIsCanceled = payload.data;
    },
    setGeneratedInvoice(state, payload) {
        state.generatedInvoice = payload;
    },
    setUsedPrepaid(state, payload) {
        state.isUsedPrepaid = payload;
    },
    setUsedDeposit(state, payload) {
        state.isUsedDeposit = payload;
    },
};