export default {
  setAccountingFrequently(state, payload) {
    let accountingFrequentlies = [
      ...state.accountingFrequently.accountingFrequentlies,
    ];
    for (const accountingFrequently of payload.accountingFrequentlies) {
      if (
        accountingFrequentlies.findIndex(
          (c) => c.id === accountingFrequently.id
        ) < 0
      ) {
        accountingFrequentlies.push(accountingFrequently);
      }
    }
    state.accountingFrequently.accountingFrequentlies = accountingFrequentlies;
    state.accountingFrequently.currentPage = payload.currentPage;
    state.accountingFrequently.totalPage = payload.totalPage;
  },
  resetAccountingFrequently(state) {
    const accountingFrequently = {
      accountingFrequentlies: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.accountingFrequently = accountingFrequently;
  },
};
