export default {
	title: "Project Document",
    uploadFile: "Drag drop or add file",
    addFile: "Add file",
    uploadFileDesc: "Please add file with extension .JPG ,.PNG , .PDF only and the file size doesn't exceed 5 MB",
    allDocument: "All Document",
    limitDocument: "Up to 5 files can be stored",
    validateLimitDocument: "Store can be up to 5 documents",
    validateLimitDocumentDetail: "Can't upload file :name",
    validateFileSize: "File :name is too large",
    validateFileSizeDetail: "Please upload file size doesn't exceed 5 MB",
    validateFileType: "Can't upload file :name",
    validateFileTypeDetail: "The allowed file extensions are .JPG, .PNG and PDF only",
    addFileSuccess: "Upload file successfully",
    titleConfirmDelete: "Confirm delete document",
    wordingConfirmDelete: "Are you sure you want to delete document?",
    deleteFileSuccess: "Delete file successfully",
    uploading: "Uploading",
    fileName: "File Name"
};
