<template>
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredProductPenalty"
    field="codeAndName"
    @complete="searchProductPenalty"
    @dropdown-click="filteredProductPenalty"
    forceSelection
    :placeholder="textPlaceholder"
  />
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default {
  props:["textPlaceholder"],
  emits: ["load-finish"],
  setup() {
    const store = useStore();
    let searchText = ref(null);

    const products = computed(() => {
      let itemList = [];
      let productArs = store.getters["shared/productArProducts"];
      if (productArs) {
        productArs.forEach((element) => {
          //   console.log("productArs", element);
          if (element.code.substr(0, 1) == "Z") {
            element = {
              ...element,
              ...{ codeAndName: element.code + " : " + element.nameThai },
            };
            itemList.push(element);
          }
        });
      }
      return itemList;
    });

    const filteredProductPenalty = computed(() => {
      if (!searchText.value) {
        //ช่องว่าง
        const result = [...products.value];
        return result;
      } else {
        return products.value.filter((product) => {
          return product.searchText
            .toLowerCase()
            .includes(searchText.value.toLowerCase());
        });
      }
    });

    const searchProductPenalty = function (event) {
      // console.log(event);
      if (!event.query.trim().length) {
        //ช่องว่าง
        // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
        searchText.value = "";
        searchText.value = null;
      } else {
        searchText.value = event.query;
      }
    };

    let isSharedProductPenaltyReady = ref(false);

    const productIsLastPage = computed(() => {
      return store.getters["shared/productArIsLastPage"];
    });

    const getProductPenalty = async function () {
      if (!productIsLastPage.value) {
        await store.dispatch("shared/getAllProductARs");
      }
      isSharedProductPenaltyReady.value = true;
    };

    const getProductPenaltyByCode = function (code) {
      return products.value.find((product) => {
        return product.code.toLowerCase() === code.trim().toLowerCase();
      });
    };

    onMounted(async () => {
      await getProductPenalty();
    });

    return {
      searchProductPenalty,
      filteredProductPenalty,
      searchText,
      getProductPenaltyByCode,
      isSharedProductPenaltyReady,
    };
  },
  watch: {
    isSharedProductPenaltyReady(curValue) {
      if (curValue) {
        this.$emit("load-finish");
      }
    },
  },
};
</script>
