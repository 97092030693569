export default {
  uploadFile: "อัปโหลดไฟล์",
  dragFile: "ลากไฟล์มาวาง หรือ ",
  addFile: "เพิ่มไฟล์ใหม่",
  importFileDesc: "กรุณาเพิ่มไฟล์ทีละ 1 ไฟล์ ที่มีนามสกุล .XLSX เท่านั้น หรือ ",
  downloadfile: "ดาวน์โหลดตัวอย่างไฟล์",
  fileName: "ชื่อเอกสาร",
  validateLimitFile: "สามารถอัปโหลดไฟล์ได้ทีละ 1 ไฟล์",
  validateFileType: "ไม่สามารถอัปโหลดไฟล์ :name ได้",
  validateImportFile: "สกุลไฟล์ที่อนุญาตมีดังนี้ .XLSX เท่านั้น",

  importInvoices: "นำเข้าข้อมูลใบแจ้งหนี้",
  importAll: "นำเข้าทั้งหมด",
  importSelected: "นำเข้ารายการที่เลือก",
  columnUnitCode: "ยูนิต",
  columnDate: "วันที่",
  columnDueDate: "วันที่ครบกำหนด",
  columnReferCode: "เลขที่อ้างอิง",
  columnProductCode: "รหัสรายรับ",
  columnPeriod: "งวด",
  columnDescription: "รายละเอียด",
  columnAmount: "จำนวนเงิน",

  validateImportNoData: "เอกสาร : :file ไม่มีข้อมูล",
  validateUnitCode: "ข้อมูลแถวที่ :row ไม่ได้ระบุรหัสห้อง/ยูนิต",
  validateInvoiceDate: "ข้อมูลแถวที่ :row ไม่ได้ระบุวันที่ใบแจ้งหนี้",
  validateInvoiceDueDate: "ข้อมูลแถวที่ :row ไม่ได้ระบุวันที่ครบกำหนดใบแจ้งหนี้",
  validateFormatInvoiceDate: "ข้อมูลแถวที่ :row ระบุรูปแบบวันที่ใบแจ้งหนี้ไม่ถูกต้อง(dd/MM/yyyy(ค.ศ.))",
  validateFormatInvoiceDueDate: "ข้อมูลแถวที่ :row ระบุรูปแบบวันที่ครบกำหนดใบแจ้งหนี้ไม่ถูกต้อง(dd/MM/yyyy(ค.ศ.))",
  validateProductCode: "ข้อมูลแถวที่ :row ไม่ได้ระบุรหัสรายรับ",
  validatePeriod: "ข้อมูลแถวที่ :row ไม่ได้ระบุงวด",
  validateFormatPeriod: "ข้อมูลแถวที่ :row ระบุรูปแบบงวดไม่ถูกต้อง(MM/YYYY(พ.ศ.))",
  validateTotalAmount: "ข้อมูลแถวที่ :row ไม่ได้ระบุจำนวนเงิน",
  validateFormatTotalAmount: "ข้อมูลแถวที่ :row ระบุจำนวนเงินไม่ถูกต้อง",  
  validateUnitCodeInvalid: "รหัสห้อง/ยูนิต : :unitCode ไม่ถูกต้อง",
  validateReferCodeLimitText: "ข้อมูลแถวที่ :row เลขที่อ้างอิง ระบุความยาวไม่เกิน 3000 ตัวอักษร",
  validateDescriptionLimitText: "ข้อมูลแถวที่ :row รายละเอียด ระบุความยาวไม่เกิน 750 ตัวอักษร",

  statusWaiting: "รอบันทึก",
  statusSuccess: "บันทึกสำเร็จ",
  importInvoiceSuccess: "นำเข้าใบแจ้งหนี้สำเร็จ",
  pleaseSelectItem: "กรุณาเลือกรายการ",
  uploadStatus: "สถานะอัปโหลด",
  uploading: "กำลังอัปโหลด...",
  import: "นำเข้า",
  invoiceDetail: "รายละเอียดใบแจ้งหนี้",
  error: "ข้อผิดพลาด",
  errorDetail: "ยอดชำระมากกว่ายอดค้าง",
  
  validatingData: "กำลังตรวจสอบข้อมูล",
  mappingInvoice: "กำลังจับคู่ใบแจ้งหนี้",
  lockImportInvoice: "ล็อกใบแจ้งหนี้ที่นำเข้า",
  itemsImportAllSuccess: "รายการทั้งหมดได้ถูกบันทึกลงระบบแล้ว",
  validateInvoiceDateAndDueDate: "ข้อมูลแถวที่ :row วันที่ใบแจ้งหนี้ มากกว่า วันที่ครบกำหนดใบแจ้งหนี้",
  unitCodeIsNotValid: "ไม่สามารถนำเข้าได้ เนื่องจากไม่พบเลขที่ห้อง/ยูนิต “:unitCode”",
  productCodeIsNotValid: "ไม่สามารถนำเข้าได้ เนื่องจากไม่พบรหัสรายรับ “:productCode”",  
  unitCodeIsNotActive: "ไม่สามารถนำเข้าได้ เนื่องจากเลขที่ห้อง/ยูนิต “:unitCode” มีสถานะปิดการใช้งานอยู่",

};
