<template>
  <PvDialog
    :breakpoints="{ '1280px': '80vw', '640px': '80vw' }"
    :style="{ width: '80vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="lang.notificationDialogHeader"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
    ></base-dialog>

    <template #header>
      <div class="flex flex-wrap justify-content-between align-items-right p-0">
        <div class="p-0">
          <label class="font-lg">{{ title }}</label>
        </div>
        <div>
          <PvButton
            icon="pi pi-times"
            class="p-button-link p-icon-secondary pb-0 pt-0"
            style="width: 15px"
            @click="closeDialog"
          />
        </div>
      </div>
      <PvDivider></PvDivider>

      <div class="flex flex-wrap justify-content-between align-items-center pt-1">
        <div class="flex lg:col-9 md:col-12 col-12 p-0">
          <div class="col-3 p-0 pr-2 pt-2">
            <PvDropdown
              id="selectedSortStatus"
              class="inputfield w-full"
              :options="sortStatus"
              optionLabel="name"
              optionValue="code"
              v-model="selectedSortStatus"
              scrollHeight="300px"
            />
          </div>
          <div class="col-3 p-0 pr-2 pt-2">
            <PvDropdown
              id="filterSearch"
              class="inputfield w-full"
              :options="filterSearch"
              optionLabel="name"
              optionValue="code"
              v-model="selectedFilterSearch"
              scrollHeight="300px"
            />
          </div>
          <div class="col-4 p-0 pr-2 pt-2">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchText"
                :placeholder="lang.searchTextPlaceholder"
                @keyup.enter="getStockKeycards"
                autofocus
              />
            </span>
          </div>
          <div class="pr-2 pt-2">
            <PvButton @click="getStockKeycards">
              <span class="font-normal">{{ lang.search }}</span>
            </PvButton>
          </div>
        </div>

        <div class="pt-2">
          <PvButton @click="selectStockKeycard">
            <icon-documents-file-checkmark iconColor="white" />
            <span class="font-normal w-full ml-1">{{ lang.selectedStockKeycard }}</span>
          </PvButton>
        </div>
      </div>
    </template>

    <div v-if="selectKeycardError" class="pb-2">
      <PvInlineMessage severity="error" class="w-full justify-content-start">{{ lang.validateNoSelectStockKeycard }}</PvInlineMessage>
    </div>

    <div class="p-datatable-border my-2">
      <PvDataTable
        :value="stockKeycardExceptReceiptDetails"
        v-model:selection="selectedStockKeycard"
        selectionMode="single"
        dataKey="id"
        responsiveLayout="scroll"
        class="p-datatable-xs"
        :class="textTableClass"
        :loading="isLoading"
        @row-dblclick="selectStockKeycard"
        @click="validateData"
        scrollable
      >
        <PvColumn 
          selectionMode="single" style="min-width: 4rem; flex: 0; height: 56px">
        </PvColumn>
        <PvColumn
          field="date"
          :header="lang.date"
          style="min-width: 8rem; height: 56px"
          headerClass="h-left"
          bodyClass="text-left"
        >
          <template #body="slotProps">
            {{ formatDate(slotProps.data.date) }}
          </template>
        </PvColumn>
        <PvColumn
          field="code"
          :header="lang.runningNo"
          style="min-width: 10rem; height: 56px"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="serial"
          :header="lang.serialNo"
          style="min-width: 10rem; height: 56px"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <template #footer>
          <PvPaginator
            :rows="rowsPerPage"
            :totalRecords="totalRecords"
            @page="onPageChange($event)"
            :rowsPerPageOptions="[10, 50, 100]"
            class="p-pagination-radius"
            :first="showCurrentPage"
          >
            <template #start="slotProps">
              {{ lang.page }}:
              {{ totalPage ? slotProps.state.page + 1 : 0 }}/{{ totalPage }}
            </template>
          </PvPaginator>
        </template>

        <template #empty>
          <div class="w-full p-3" style="text-align: center">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
      </PvDataTable>
    </div>

    <template #footer></template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, ref , onMounted} from "vue";
export default {
  props: {
    selectedKeycard: {
      type: Object,
    },
    receiptDetails: {
      type: Array,
    },
  },
  emits: ["close", "keycard-selected"],
  setup(props, { emit }) {
    const { lang } = useLang();
    const { textFormClass, textTableClass } = useTextStyle();
    const { formatDateToISOString, formatDate, formatDateTime } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.stockKeycard.edit,
          ...lang.th.ar.stockKeycard.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.stockKeycard.edit,
          ...lang.en.ar.stockKeycard.listing,
        };
      }
    });

    const filterSearch = ref([
      { name: mergeLang.value.runningNo, code: "running" },
    ]);
    let selectedFilterSearch = ref("running");
    let searchText = ref("");
    let selectedStockKeycard = ref(null);
    let isLoading = ref(false);
    let rowsPerPage = ref(10);
    let selectedPage = ref(1);

    let showValidateDialog = ref(false);
    let validateWording = ref("");
    let selectKeycardError = ref(false);
    let dataButtonDialog = ref([
      {
        id: "close",
        caption: mergeLang.value.closee,
        class: "w-full p-button-outlined p-button-secondary",
        action: "close",
      },
    ])
    let sortStatus = ref([
      { name: mergeLang.value.codeSortASC, code: "code_asc" },
      { name: mergeLang.value.codeSortDESC, code: "code_desc" },
      { name: mergeLang.value.dateSortASC, code: "date_asc" },
      { name: mergeLang.value.dateSortDESC, code: "date_desc" },
    ]);
    let selectedSortStatus = ref("code_asc");

    onMounted( async () => {
      initiateData();
      await getStockKeycards();
    });

    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const stockKeycards = computed(() => {
      return store.getters["arStockKeycard/stockKeycards"];
    });
    const totalPage = computed(() => {
      return store.getters["arStockKeycard/totalPage"];
    });
    const currentPage = computed(() => {
      return store.getters["arStockKeycard/currentPage"];
    });
    const totalRecords = computed(() => {
      return totalPage.value * rowsPerPage.value;
    });
    const showCurrentPage = computed(() => {
      let page = 0;
      page = rowsPerPage.value * (selectedPage.value - 1);
      return page;
    });
    const payload = computed(() => {
      let where = {
        projectId: selectedProject.value.id,
        productCode: props.selectedKeycard.productCode.trim(),
        status: "ready",
        code: searchText.value.trim(),
        sortBy: selectedSortStatus.value
      }
      return {
        page: selectedPage.value,
        limit: rowsPerPage.value,
        ...where,
      };
    });
    const title = computed(() => {
      return mergeLang.value.selectStockKeycardTitle.replace(":productCode", props.selectedKeycard.productCode.trim()).replace(":productName", props.selectedKeycard.productName.trim())
    });
    const stockKeycardExceptReceiptDetails = computed(() => {
      let details = stockKeycards.value;
      if(details.length > 0) {
        props.receiptDetails.forEach((element) => {
          const index = details.findIndex(
            (p) => p.id === element.stockKeycardId
          );
          if (index >= 0) {
            details.splice(index, 1);
          }
        });

        let i = 1;
        let data = [];
        details.forEach((element) => {
          element = { ...element, ...{ itemNo: i } };
          data.push(element);
          i++;
        });
        details = data;
      }
      return details;
    });

    const closeDialog = () => {
      emit("close");
    };

    const initiateData = () => {
      searchText.value = "";
      selectedStockKeycard.value = null;
      rowsPerPage.value = 10;
      selectedPage.value = 1;
      isLoading.value = false;
      selectKeycardError.value = false;
    };

    const getStockKeycards =  async () => {
      isLoading.value = true;
      try {
        await store.dispatch("arStockKeycard/getStockKeycards", payload.value);
        isLoading.value = false;
        selectKeycardError.value = false;
      } catch (error) {
        validateWording.value = error;
        showValidateDialog.value = true;
        isLoading.value = false;
      }
    };

    const onPageChange = async (event) => {
      if (event.rows != rowsPerPage.value) {
        selectedPage.value = 1;
      } else {
        selectedPage.value = event.page + 1;
      }
      rowsPerPage.value = event.rows;
      await getStockKeycards();
    }; 

    const validateData = () => {
      if(!selectedStockKeycard.value) {
        selectKeycardError.value = true;
        return true;
      }
      selectKeycardError.value = false;
      return false;
    };

    const selectStockKeycard = () => {
      if (validateData()) {
        return;
      }
      emit("keycard-selected", selectedStockKeycard.value);
      closeDialog();
    };


    return {
      lang: mergeLang,
      textFormClass,
      textTableClass,
      formatDateToISOString,
      formatDate,
      formatDateTime,

      //data
      filterSearch,
      selectedFilterSearch,
      searchText,
      selectedStockKeycard,
      isLoading,
      showValidateDialog,
      validateWording,
      dataButtonDialog,
      selectKeycardError,
      sortStatus,
      selectedSortStatus,

      //methods
      closeDialog,
      getStockKeycards,
      onPageChange,
      selectStockKeycard,
      validateData,

      //computed
      stockKeycards,
      totalPage,
      currentPage,
      totalRecords,
      showCurrentPage,
      rowsPerPage,
      title,
      stockKeycardExceptReceiptDetails,
    }
  },
};
</script>
