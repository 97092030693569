<template>
  <base-container>
    <base-ap-book-modal
      v-model:visible="showAPBookModal"
      :search="searchText"
      :isSharedLoading="isSharedAPBookReady"
      @close="showAPBookModal = false"
      @apBook-selected="apBookModalSelected"
    ></base-ap-book-modal>

    <base-reference-document-dialog  
     v-model:visible="showReferenceDocument" 
     @close="showReferenceDocument = false"
     :dataTable="purchaseInvoiceReference"
    >
    </base-reference-document-dialog>
    
    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchTextPrint"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>

    <div
      class="flex justify-content-between align-items-center"
      style="padding-top: 20px"
    >
      <div class="my-3">
        <PvButton
          icon="pi pi-chevron-left"
          :label="lang.back"
          @click="goBack"
          class="mr-2 p-button-secondary"
          v-if="isHideBack"
        />
      </div>
      <h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
    </div>
    <div class="surface-card shadow-2 p-3 border-round">
      <div class="pt-2 pb-1">
        <PvButton
          icon="pi pi-file"
          :label="lang.create"
          @click="gotoEdit('create')"
          class="mr-2 mb-2"
        />
        <PvButton
          icon="pi pi-list"
          :label="parameterButtonText"
          @click="toggleParameterVisible"
          class="p-button-help mr-2 mb-2"
        />
        <PvButton
          icon="pi pi-replay"
          :label="lang.changeAPBook"
          @click="changeAPBook"
          class="mr-2 mb-2"
        />
      </div>
      <transition>
        <div v-if="isParameterVisible">
          <section class="flex flex-wrap" :class="textFormClass">
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
              <label for="dateStart">{{ lang.dateStart }}</label>
              <PvCalendar
                id="dateStart"
                v-model="dateStart"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
              <label for="dateEnd">{{ lang.dateEnd }}</label>
              <PvCalendar
                id="dateEnd"
                v-model="dateEnd"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
              <label for="codeStart">{{ lang.codeStart }}</label>
              <PvInputText
                id="codeStart"
                type="text"
                class="inputfield w-full mt-1"
                placeholder="6501xxxxx"
                v-model="codeStart"
              />
            </div>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
              <label for="codeEnd">{{ lang.codeEnd }}</label>
              <PvInputText
                id="codeEnd"
                type="text"
                class="inputfield w-full mt-1"
                placeholder="6501xxxxx"
                v-model="codeEnd"
              />
            </div>
            <PvButton
              icon="pi pi-search"
              :label="lang.search"
              @click="getAccountPayables"
              class="mr-2 mb-2"
            />
          </section>
        </div>
      </transition>
      <PvDataTable
        showGridlines
        :value="purchaseInvoices"
        v-model:selection="selectedPurchaseInvoices"
        selectionMode="single"
        dataKey="id"
        responsiveLayout="scroll"
        :stripedRows="true"
        class="p-datatable-sm"
        :class="textTableClass"
        :loading="isLoading"
        @row-dblclick="gotoEdit(this.selectedPurchaseInvoices.id)"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn
          field="postStep"
          :header="lang.columnApprove"
          style="width:  5%"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        ></PvColumn>
        <PvColumn
          field="cancelStatus"
          :header="lang.columnIsCancel"
          style="width:  5%"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        ></PvColumn>
        <PvColumn
          field="code"
          :header="lang.columnCode"
          style="width:  10%"
          headerClass="h-center"
          sortable
        ></PvColumn>
        <PvColumn
          field="refCode"
          :header="lang.columnRefCode"
          style="width:  10%"
          headerClass="h-center"
          sortable
        ></PvColumn>
        <PvColumn
          field="date"
          :header="lang.columnDate"
          style="width:  10%"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        >
          <template #body="slotProps">
            {{ formatDate(slotProps.data.date) }}
          </template></PvColumn
        >
        <PvColumn
          field="dueDate"
          :header="lang.columnDueDate"
          style="width:  10%"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        >
          <template #body="slotProps">
            {{ formatDate(slotProps.data.dueDate) }}
          </template></PvColumn
        >
        <PvColumn
          field="supplierCode"
          :header="lang.columnSupplier"
          style="min-width:  10%"
          headerClass="h-center"
          sortable
        ></PvColumn>

        <PvColumn
          field="supplierName"
          :header="lang.columnSupplierName"
          style="width:  20%"
          headerClass="h-center"
          sortable
        >
        </PvColumn>

        <PvColumn
          field="paidStatus"
          :header="lang.columnPaidAmount"
          style="width:  5%"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        >
        </PvColumn>

        <PvColumn
          field="netAmount"
          :header="lang.columnNetAmount"
          style="width:  10%"
          headerClass="h-center"
          bodyClass="text-right"
          sortable
          ><template #body="slotProps">
            {{ formatCurrency(slotProps.data.netAmount) }}
          </template></PvColumn
        >       
        <PvColumn
          :header="lang.referenceDocument"
          style="width:  5%"
          headerClass="h-center"
          bodyClass="text-center"
        >
          <template #body="slotProps">
            <PvButton
              type="button"
              class="p-button-secondary p-button-text py-0"
              icon="pi pi-eye"
              @click="gotoReferenceDocument(slotProps.data.id)"
            ></PvButton>
          </template>
        </PvColumn>
        <PvColumn
        style="width: 10%"
          headerClass="h-center"
          bodyClass="text-center">
          <template #body="slotProps">
            <PvButton
              type="button"
              class="p-button-secondary p-button-text py-0"
              icon="pi pi-pencil"
              @click="gotoEdit(slotProps.data.id)"
            ></PvButton>
            <PvButton
              type="button"
              class="p-button-secondary p-button-text py-0"
              icon="pi pi-print"
              @click="selectPrintForm(slotProps.data)"
            ></PvButton>
          </template>
        </PvColumn>
        <template #footer>
          <PvPaginator
            :rows="rowsPerPage"
            :totalRecords="totalRecords"
            @page="onPageChange($event)"
            :rowsPerPageOptions="[10, 50, 100]"
						:first="showCurrentPage"
          >
            <template #start="slotProps">
              {{ lang.page }}:
              {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
            </template>
          </PvPaginator>
        </template>
      </PvDataTable>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import useGetAPBook from "../../../hooks/getAPBook.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
export default {
  props: ["apBook", "id", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { isSharedAPBookReady, searchText, setBookCode } = useGetAPBook();
    const {
      getFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints
    } = useGetFormPrint();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.ap.purchaseInvoice.listing };
      } else {
        return { ...lang.en.base, ...lang.en.ap.purchaseInvoice.listing };
      }
    });

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      isSharedAPBookReady,
      searchText,
      setBookCode,

      getFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints,

      isHideBack,
    };
  },
  data() {
    return {
      isParameterVisible: true,
      dateStart: null,
      dateEnd: null,
      codeStart: "",
      codeEnd: "",
      isLoading: false,
      showAPBookModal: true,
      selectedPurchaseInvoices: null,

      selectedPage: 1,
      rowsPerPage: 10,

      selectedBookId: "",
      selectedBookCode: "",
      selectedAPBook: null,
      
      showFormPrintModal: false,
      path: "",
      dataPrint: [],
      categoryPrint: "",
      showReferenceDocument: false,
      purchaseInvoiceReference: [],
    };
  },
  async created() {
    let date = new Date();
    this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
    this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.storageName = `erp_search_PurchaseInvoiceEntryList_${this.selectedProject.code}_${this.userCode}`;

    const localSearch = localStorage.getItem(this.storageName);
    // console.log("created_localSearch=",localSearch);
    if (localSearch) {
      let search = JSON.parse(localSearch);
      if (search.PurchaseInvoiceEntry === "Entry") {
        //
        if (search.dateStart != null && search.dateStart != "") {
          this.dateStart = new Date(search.dateStart);
        } else this.dateStart = "";

        if (search.dateEnd != null && search.dateEnd != "") {
          this.dateEnd = new Date(search.dateEnd);
        } else this.dateEnd = "";

        this.codeStart = search.codeStart || this.codeStart;
        this.codeEnd = search.codeEnd || this.codeEnd;

				this.rowsPerPage = search.rowsPerPage || this.rowsPerPage;
        this.selectedPage = search.selectedPage || this.selectedPage;

        search.PurchaseInvoiceEntry = "";
        localStorage.setItem(this.storageName, JSON.stringify(search));
        this.selectedBookCode = search.selectedAPBook.bookCode;
      }

      //  console.log("00this.search=",search);
      this.selectedAPBook = search.selectedAPBook;
      this.selectedBookId = search.selectedAPBook.bookId;
    }

    this.setBookCode("PA,PI,PN,PV");

    if (this.apBook) {
      this.showAPBookModal = false;
      await this.getAccountPayables();
    } else {
      this.showAPBookModal = true;
    }
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
			this.$router.push({name: "home"})
    },
    toggleParameterVisible() {
      this.isParameterVisible = !this.isParameterVisible;
    },
    gotoEdit(Id) {
      this.$router.push({ name: "ap-purchase-invoice-edit", params: { apBook: this.selectedBookId, id: Id }});
    },
    apBookModalSelected(data) {
      // console.log("apBookModalSelected=", data);
      this.$router.push({ name: "ap-purchase-invoice-apBook", params: { apBook: data.bookId }});
      this.selectedAPBook = data;
      this.selectedBookId = data.bookId;
      this.selectedBookCode = data.bookCode;

      this.getAccountPayables();
      this.showAPBookModal = false;

      // let where = {
      //   projectId: this.selectedProject.id,
      //   dateStart: this.selectedDateStart,
      //   dateEnd: this.selectedDateEnd,
      //   codeStart: this.codeStart || null,
      //   codeEnd: this.codeEnd || null,
      //   DocumentBookId: this.selectedBookId,
      //   selectedAPBook: this.selectedAPBook,
      // };

      // localStorage.setItem(
      //   "erp_search_PurchaseInvoiceEntryList",
      //   JSON.stringify(where)
      // );
    },
    changeAPBook() {
      this.selectedBookCode = "";
      this.setBookCode("PA,PI,PN,PV");
      this.showAPBookModal = true;
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getAccountPayables();
    },
    async getAccountPayables() {
      this.isLoading = true;
      // console.log("this.glBook=", this.glBook);
      let where = {
        projectId: this.selectedProject.id,
        dateStart: this.selectedDateStart,
        dateEnd: this.selectedDateEnd,
        codeStart: this.codeStart || null,
        codeEnd: this.codeEnd || null,
        DocumentBookId: this.selectedBookId,
      };

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };

      try {
        // console.log("payload", payload);
        await this.$store.dispatch(
          "apPurchaseInvoice/getPurchaseInvoices",
          payload
        );
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }

      this.isLoading = false;

      where = {
        ...where,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        selectedAPBook: this.selectedAPBook,
        rowsPerPage: this.rowsPerPage,
        selectedPage: this.selectedPage,
      };

      localStorage.setItem(this.storageName,JSON.stringify(where));
    },    
    async selectPrintForm(data) {
      this.dataPrint = data;
      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: this.selectedBookCode,
      };
      const payload = { where };
      await this.getFormPrints(payload);

      if(this.filteredFormPrints.length == 1) {
        this.path = this.filteredFormPrints[0].path;
        this.PrintPurchaseInvoice();
      }else{
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path;
      this.categoryPrint = event.category;
      this.PrintPurchaseInvoice();
      this.showFormPrintModal = false;
    },
    async PrintPurchaseInvoice() {
      this.setLoading(true);
      const PurchaseInvoice = {
        id: this.dataPrint.id,
        projectId: this.selectedProject.id,
        path: this.path,
        printBy: this.employee.name,
      };

      //  console.log("PrintPettyCash=", PurchaseInvoice);
      try {
        await this.$store.dispatch("apPurchaseInvoice/PrintPurchaseInvoice", PurchaseInvoice);
        this.isPrinted = true;
      } catch (error) {
        // console.log("error=", error);
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        this.isPrinted = false;
        this.setLoading(false);
      }
      let blob = this.$store.getters["apPurchaseInvoice/blob"];
      // console.log('blob',blob);
      this.getPreviewBlob(blob);

      this.setLoading(false);
    },
    async gotoReferenceDocument(id){
      this.setLoading(true);
      try {
        // console.log('id=',id);
        const payload = {
          ProjectId: this.selectedProject.id,
          PurchaseInvoiceId: id
        };
        // console.log("payload", payload);
        await this.$store.dispatch("apPurchaseInvoice/getPurchaseInvoiceReference",payload);
        let dataReference = await this.$store.getters["apPurchaseInvoice/purchaseInvoiceReference"];   
        // console.log("dataReference=",dataReference);     
        this.purchaseInvoiceReference = dataReference;   
        this.setLoading(false);     
        this.showReferenceDocument = true;
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        this.setLoading(false);
      }

    },
  },
  computed: {    
    title() {
      let titleBook = "";
      if (this.selectedBookCode) titleBook = " : " + this.selectedBookCode;
      let result = this.lang.title + titleBook;
      return result;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
		userCode() {
			return this.$store.getters.userCode;
		},
    employee() {
      return this.$store.getters.employee;
    },
    parameterButtonText() {
      return this.isParameterVisible
        ? this.lang.hideParameters
        : this.lang.showParameters;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    purchaseInvoices() {
      return this.$store.getters["apPurchaseInvoice/purchaseInvoices"];
    },
    totalPage() {
      return this.$store.getters["apPurchaseInvoice/totalPage"];
    },
    currentPage() {
      return this.$store.getters["apPurchaseInvoice/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
		showCurrentPage() {
			let page = 0;
			page = this.rowsPerPage * (this.selectedPage - 1);
			return page;
		},
  },
  watch: {
    apBook(curValue) {
      if (curValue == null) {
        this.selectedBookCode = "";
        this.showAPBookModal = true;
      }
    },
  },
};
</script>

<style></style>
