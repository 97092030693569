<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
    :header="lang.selectAPBook"
  >
    <PvDataTable
      showGridlines
      :value="filteredBookPurchases"
      v-model:selection="selectedBookPurchase"
      selectionMode="single"
      dataKey="bookId"
      responsiveLayout="scroll"
      :scrollHeight="halfViewportHeight + 'px'"
      class="p-datatable-sm"
      :class="textTableClass"
      :loading="isSharedBookPurchaseReady"
      @row-dblclick="selectBookPurchase()"
    >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
      <template #header>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <PvInputText
            v-model="searchTextBookPurchase"
            :placeholder="lang.keywordSearch"
            autofocus
          />
        </span>
      </template>
      <PvColumn selectionMode="single" bodyClass="text-center"></PvColumn>
      <PvColumn
        field="moduleCode"
        :header="lang.type"
        headerClass="h-center"
        bodyClass="text-center"
      ></PvColumn>
      <!-- <PvColumn
        field="moduleName"
        :header="lang.nameTH"
        headerClass="h-center"
      ></PvColumn> -->
      <PvColumn
        field="bookCode"
        :header="lang.code"
        headerClass="h-center"
        bodyClass="text-center"
      ></PvColumn>
      <PvColumn
        field="bookNameTH"
        :header="lang.nameTH"
        headerClass="h-center"
      ></PvColumn>
      <PvColumn
        field="bookNameEN"
        :header="lang.nameEN"
        headerClass="h-center"
      ></PvColumn>
    </PvDataTable>

    <template #footer>
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog"
      /> 
      <PvButton
        :label="lang.ok"
        icon="pi pi-check"
        autofocus
        @click="selectBookPurchase"
      />
    </template>

  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useGetBookPurchase from "../../hooks/getBookPurchase.js";
export default {
  props: {
    shouldGetData: {
      type: Boolean,
      default: true,
    },
    search: {
      type: String,
    },
    isSharedLoading: {
      type: Boolean,
    },
    documentTypes: {
      type: Array ,
      default: null
    }
  },
  emits: ["close", "bookPurchase-selected"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textTableClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ap.purchaseInvoice.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ap.purchaseInvoice.listing,
        };
      }
    });
    const { filteredBookPurchases, searchTextBookPurchase, isSharedBookPurchaseReady,searchDocumentTypes } =
      useGetBookPurchase();

    return {
      filteredBookPurchases,
      isSharedBookPurchaseReady,
      textHeaderClass,
      textTableClass,
      lang: mergeLang,
      searchTextBookPurchase,
      searchDocumentTypes,
    };
  },
  data() {
    return {
      selectedBookPurchase: null,
    };
  },
  created() {
    // console.log("created_BaseBookPurchaseModal");
    this.searchDocumentTypes = this.documentTypes;
  },
  methods: {
    selectBookPurchase() {
      if (!this.selectedBookPurchase){
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoDetailSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
         return true;
      }
      this.$emit("bookPurchase-selected", this.selectedBookPurchase);
    },
    closeDialog() {
      this.$emit("close");
    },
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
  },
  watch: {
    search(curValue) {
      // console.log("search=",curValue);
      if (curValue) {
        this.searchTextBookPurchase = curValue;
      }
    },
    // filteredBookPurchases() {
    //   // console.log("filteredBookPurchases=", this.filteredBookPurchases);
    //   this.selectedBookPurchase = this.filteredBookPurchases[0];
    // },
    isSharedLoading(curValue) {
      this.isSharedBookPurchaseReady = curValue;
    },
  },
};
</script>
