import { useStore } from "vuex";
import { ref, computed } from "vue";
export default function useGetBookPurchase() {
  const store = useStore();
  let searchTextBookPurchase = ref(null);  
  let searchDocumentTypes = ref(null);

  const bookPurchases = computed(() => {
    return store.getters["shared/bookPurchases"];
  });

  const bookPurchaseIsLastPage = computed(() => {
    return store.getters["shared/bookPurchaseIsLastPage"];
  });

  const filteredBookPurchases = computed(() => {
    let searchPurchasesDocumentTypes = [];
    if (searchDocumentTypes.value){      
      for (let index = 0; index < searchDocumentTypes.value.length; index++) {
        const documentType = searchDocumentTypes.value[index];
        bookPurchases.value.forEach(x => {
          if(x.moduleCode.toLowerCase() === documentType.trim().toLowerCase()){
            searchPurchasesDocumentTypes.push(x);
          }
        });
      }     
    }else{
      searchPurchasesDocumentTypes = bookPurchases.value;
    }

    if (!searchTextBookPurchase.value) {      //ช่องว่าง      
      //const result = [...bookPurchases.value];
      const result = searchPurchasesDocumentTypes
      return result;
    } else {
      //return bookPurchases.value.filter((bookPurchase) => {
      return searchPurchasesDocumentTypes.filter((bookPurchase) => {
        return bookPurchase.bookCode
          .toLowerCase()
          .includes(searchTextBookPurchase.value.toLowerCase())
          || bookPurchase.moduleName
          .toLowerCase()
          .includes(searchTextBookPurchase.value.toLowerCase())
          || bookPurchase.bookNameTH
          .toLowerCase()
          .includes(searchTextBookPurchase.value.toLowerCase())
          || bookPurchase.bookNameEN
          .toLowerCase()
          .includes(searchTextBookPurchase.value.toLowerCase());
      });
    }
  });

  const searchBookPurchase = function (event) {
    //  console.log("searchGlBook=",event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchTextBookPurchase.value = "";
      searchTextBookPurchase.value = null;
    } else {
      searchTextBookPurchase.value = event.query;
    }
  };

  let isSharedBookPurchaseReady = ref(true);

  const getAllBookPurchases = async function () {
    // console.log("bookPurchaseIsLastPage.value=",bookPurchaseIsLastPage.value);
    if (!bookPurchaseIsLastPage.value) {
      await store.dispatch("shared/getAllBookPurchases");
    }
  };

  const setBookPurchaseCode = async function (payload) {
    isSharedBookPurchaseReady.value = true;  
    await store.dispatch("shared/resetBookPurchase");
    await store.dispatch("shared/getAllBookPurchases",payload);       
    isSharedBookPurchaseReady.value = false;
  };  

  return {
    searchBookPurchase,
    filteredBookPurchases,
    searchTextBookPurchase,
    isSharedBookPurchaseReady,
    getAllBookPurchases,
    setBookPurchaseCode,
    bookPurchases,
    searchDocumentTypes,
  };
}
