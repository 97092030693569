<template>
  <base-toolbar
    :title="lang.title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoCreate="gotoEdit('create')"
    @gotoPrint="openPrintLotDialog"
    :hideBackButton="isHideBack"
  >
  </base-toolbar>

  <base-container>
    <import-bill-payment-dialog
      v-model:visible="showUploadBillPaymentDialog"
      @close="showUploadBillPaymentDialog = false"
      @data-upload="uploadBillPayment"
    ></import-bill-payment-dialog>

    <import-receipt-dialog
      v-model:visible="showUploadReceiptDialog"
      @close="showUploadReceiptDialog = false"
    ></import-receipt-dialog>

    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="lang.notificationDialogHeader"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
    ></base-dialog>

    <base-reference-document-dialog
      v-model:visible="showReferenceDocument"
      @close="showReferenceDocument = false"
      :dataTable="referenceDocumentData"
      :columns="columnsReferenceDocument"
    ></base-reference-document-dialog>

    <print-lot-dialog
      v-model:visible="showPrintLotDialog"
      @close="showPrintLotDialog = false"
      @print-finish="getReceipts"
      @toast-request="toastRequest"
    ></print-lot-dialog>

    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchTextPrint"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>
    
    <base-toast-report-request
      :showToast="showToast"
      :toastMessage="toastMessage"
      :toastLink="toastLink"
      :actionToast="actionToast"
    ></base-toast-report-request>

    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5" :class="textFormClass">
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.title }}</label>
        <div class="flex">
          <PvButton
            class="p-button-outlined ml-3"
            @click="gotoVisitorControl"
          >
            <icon-car iconColor="primary" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{
              lang.buttonGotoVisitorControl
            }}</span>
          </PvButton>
          <PvDivider layout="vertical"></PvDivider>
          
          <!-- สั่งเปิด 2023-09-12 -->
          <!-- ซ่อนไว้ก่อนโดยคำสั่ง PO รออนุมัติใช้อีกครั้ง 2023-04-17 -->
          <PvButton
            class="p-button-outlined"
            @click="importReceipt"
          >
            <icon-download iconColor="primary" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{ lang.import }}</span>
          </PvButton>
          <!-- ซ่อนไว้ก่อนโดยคำสั่ง PO รออนุมัติใช้อีกครั้ง 2023-04-17 -->
          <!-- สั่งเปิด 2023-09-12 -->

          <PvButton
            class="p-button-outlined ml-3"
            @click="importBillPayment"
          >
            <icon-cloud-storage-upload iconColor="primary" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{ lang.billPayment }}</span>
          </PvButton>
        </div>
      </div>

      <PvDivider></PvDivider>

      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >
        <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            id="filterSearch"
            class="inputfield w-full mr-2"
            :options="filterSearch"
            optionLabel="name"
            optionValue="code"
            v-model="selectedFilterSearch"
            scrollHeight="300px"
            @change="changeFilterSearch"
          />
        </div>
        <div class="flex lg:col-6 md:col-12 col-12 pl-0 pr-2 mr-2" v-if="isSearchDate">
          <div class="col-6 pl-0 pr-2">
            <PvCalendar
              id="dateStart"
              v-model="dateStart"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
          <label for="dateEnd" class="pt-1 mt-3 mr-2">{{ "-" }}</label>
          <div class="col-6 pl-0 pr-2">
            <PvCalendar
              id="dateEnd"
              v-model="dateEnd"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
        </div>
        <!-- <div 
          class="flex lg:col-6 md:col-12 col-12 pl-0 pr-0 mr-2" 
          v-if="isSearchNumber"
        >
          <div class="col-12 pl-0 pr-0">
            <PvInputNumber
              id="netAmount"
              class="inputfield w-full"
              v-model="netAmount"
              autofocus
            />
          </div>
        </div> -->

        <div class="flex lg:col-6 md:col-12 col-12 pl-0 pr-0 mr-2" v-if="isSearchOther">
          <div class="col-12 pl-0 pr-0">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchText"
                :placeholder="lang.searchTextPlaceholder"
                @keypress="checkInputSearch"
                autofocus
              />
            </span>
          </div>
        </div>

        <PvButton @click="getReceipts">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="receipts"
          v-model:selection="selectedReceipt"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(this.selectedReceipt.id)"
          scrollable
        >
          <PvColumn
            field="code"
            :header="lang.documentCode"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <u>{{ slotProps.data.code }}</u>
            </template>
          </PvColumn>

          <PvColumn
            field="date"
            :header="lang.date"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.date) }}
            </template>
          </PvColumn>

          <PvColumn
            field="unitCode"
            :header="lang.unit"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />

          <PvColumn
            field="unitAddressNo"
            :header="lang.address"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />

          <PvColumn
            field="customerName"
            :header="lang.customerName"
            style="min-width: 15rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <!-- <template #body="{ data, field }">
              <span
                style="width: 190px"
                class="ellipsis"
                v-tooltip.top="{
                  value: data[field],
                  class: 'custom-tooltip-secondary',
                }"
              >
                {{ data[field] }}
              </span>
            </template> -->
          </PvColumn>

          <PvColumn
            field="payType"
            :header="lang.type"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />

          <PvColumn
            field="netAmount"
            :header="lang.price"
            style="min-width: 10rem; height: 56px"
            headerClass="h-right"
            sortable
          >
            <template #body="slotProps">
              <span class="text-right w-full">{{
                formatCurrency(slotProps.data.netAmount)
              }}</span>
            </template>
          </PvColumn>

          <PvColumn
            field="paidStatus"
            :header="lang.paid"
            style="min-width: 11rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="{ data, field }">
              <base-tag
                v-if="data[field].trim() != ''"
                :Caption="paidStatus(data[field]).caption"
                :Class="paidStatus(data[field]).class"
              />
            </template>
          </PvColumn>

          <PvColumn
            field="cancelStatus"
            :header="lang.cancel"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="{ data, field }">
              <base-tag
                v-if="data[field].trim() != ''"
                :Caption="lang.cancel"
                Class="error"
              />
            </template>
          </PvColumn>

          <PvColumn
            field="postStep"
            :header="lang.printOrVoucherCreated"
            style="min-width: 11rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="{ data, field }">
              <base-tag
                v-if="data[field].trim() != ''"
                :Caption="postStep(data[field]).caption"
                Class="primary"
              />
            </template>
          </PvColumn>

          <PvColumn
            field="createDate"
            :header="lang.columnCreateDate"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.createDate) }}
            </template>
          </PvColumn>
          <PvColumn
            field="updateDate"
            :header="lang.columnUpdateDate"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <label v-if="slotProps.data.updateDate">
                {{ formatDate(slotProps.data.updateDate) }}
              </label>
            </template>
          </PvColumn>

          <PvColumn
            field="createBy"
            :header="lang.columnCreateBy"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />

          <PvColumn
            field="updateBy"
            :header="lang.columnUpdateBy"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />

          <PvColumn
            style="min-width: 12rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                @click="getPrintForm(slotProps.data)"
              >
                <icon-printer iconColor="secondary" width="24" height="24" />
              </PvButton>
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                @click="openReferenceDocument(slotProps.data.id)"
              >
                <icon-attachment-link width="24" height="24"></icon-attachment-link>
              </PvButton>
            </template>
          </PvColumn>

          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              class="p-pagination-radius"
              :first="showCurrentPage"
            >
              <template #start="slotProps">
                {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{
                  totalPage
                }}
              </template>
            </PvPaginator>
          </template>

          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ this.lang.tableEmptyList }}</label>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import PrintLotDialog from "../../../components/AR/receipt/ReceiptPrintLotDialog.vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import ImportReceiptDialog from "../../../components/AR/receipt/ImportReceiptDialog.vue";
import useGetRoleByBook from "../../../hooks/getRoleByBook.js";
import ImportBillPaymentDialog from "../../../components/AR/receipt/ImportBillPaymentDialog.vue";
export default {
  components: {
    PrintLotDialog,
    ImportReceiptDialog,
    ImportBillPaymentDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.receipt.listing,
          ...lang.th.ar.receipt.edit,
          ...lang.th.shared.referenceDocumen,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.receipt.listing,
          ...lang.en.ar.receipt.edit,
          ...lang.en.shared.referenceDocumen,
        };
      }
    });

    const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();
    const {
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
    } = useGetFormPrint();
    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,

      textTableClass,
      textFormClass,

      formatCurrency,
      formatDate,
      formatDateToISOString,
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
      isHideBack,
      getRoleByBookByCode,
      isSharedRoleByBookReady,
    };
  },
  data() {
    return {
      //Search
      filterSearch: [
        { name: this.lang.dateRange, code: "date" },
        { name: this.lang.documentCode, code: "code" },
        { name: this.lang.unit, code: "unitCode" },
        { name: this.lang.address, code: "unitAddressNo" },
        { name: this.lang.customerName, code: "customerName" },
        { name: this.lang.price, code: "netAmount" },
      ],
      selectedFilterSearch: "date",
      // dateRange: [],
      dateStart: null,
      dateEnd: null,
      searchText: "",
      netAmount: 0,

      //Table
      isLoading: false,
      selectedReceipt: null,
      selectedPage: 1,
      rowsPerPage: 10,

      //dialog
      showValidateDialog: false,
      validateWording: "",
      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "w-full p-button-outlined p-button-secondary",
          action: "confirm",
        },
      ],

      uniqueKeyId: "",

      showPrintLotDialog: false,
      showFormPrintModal: false,
      path: "",

      showReferenceDocument: false,
      referenceDocumentData: [],
      columnsReferenceDocument: [
        {
          field: "referenceDocumentBookCode",
          header: this.lang.documentBookCode,
          type: "string",
          style: "width: 10%;height: 56px;",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
        },
        // {
        //   field: "documentBookName",
        //   header: this.lang.documentBookName,
        //   type: "string",
        //   style: "width: 10%;",
        //   headerClass: "h-left font-sm font-normal",
        //   bodyClass: "text-left p-text-secondary",
        // },
        {
          field: "referenceCode",
          header: this.lang.documentCode,
          type: "string",
          style: "width: 10%",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
        },
        {
          field: "referenceDate",
          header: this.lang.date,
          type: "date",
          style: "width: 10%",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
        },
        {
          field: "referenceCancelStatus",
          header: this.lang.cancelStatus,
          type: "string",
          style: "width: 5%",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-center p-text-secondary",
        },
        {
          field: "referenceNetAmount",
          header: this.lang.amount,
          type: "currency",
          style: "width: 10%;",
          headerClass: "h-right font-sm font-normal",
          bodyClass: "text-right p-text-secondary",
        },
        {
          field: "referenceCreateDate",
          header: this.lang.createDate,
          type: "date",
          style: "width: 10%",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
        },
        {
          field: "referenceUpdateDate",
          header: this.lang.columnUpdateDate,
          type: "date",
          style: "width: 10%",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
        },
        {
          field: "referenceCreateBy",
          header: this.lang.createBy,
          type: "string",
          style: "width: 10%",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
        },
        {
          field: "referenceUpdateBy",
          header: this.lang.updateBy,
          type: "string",
          style: "width: 10%",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
        },
      ],
      showUploadReceiptDialog: false,

      storageName: "",
      
      showToast: 0,
      toastMessage: {},
      toastLink: "",
      actionToast: "",

      showUploadBillPaymentDialog: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.initiateData();
    this.roleByBookDataReady();

    // await this.getReceipts();
    // console.log("receipts", this.receipts);
    // console.log("roleByDocumentBook", this.roleByDocumentBook);
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    async gotoEdit(id) {
      if (id != "create") {
        if (this.validateRoleSelect()) {
          return;
        }
        this.$router.push({name: "ar-receipt-edit", params:{id: id}});
      }
      else {
        if (this.validateRoleCreate()) {
          return;
        }
        await this.getUniqueKey();
        this.$router.push({name: "ar-receipt-edit", params:{id: id}, query: {uniqueKeyId: this.uniqueKeyId}});
      }
    },
    async initiateData() {
      let date = new Date();
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      this.searchText = "";
      this.selectedReceipt = null;

      this.showValidateDialog = false;
      this.validateWording = "";

      this.showPrintLotDialog = false;
      this.showFormPrintModal = false;
      this.path = "";

      this.showReferenceDocument = false;
      this.referenceDocumentData = [];

      this.uniqueKeyId = "";
      this.storageName = `erp_search_ReceiptList_${this.selectedProject.code}_${this.userCode}`;
    },
    async onPageChange(event) {
      if(event.rows != this.rowsPerPage){
        this.selectedPage = 1;
      }else{
        this.selectedPage = event.page + 1;        
      }
      this.rowsPerPage = event.rows;
      await this.getReceipts();
    },
    async getReceipts() {
      if (this.validateSearchButton()) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateSearchText;
        this.showValidateDialog = true;
        return;
      }

      this.isLoading = true;
      try {
        // console.log(this.payloadGetReceipts);
        await this.$store.dispatch("arReceipt/getReceipts", this.payloadGetReceipts);

        let local = {
          ...this.payloadGetReceipts.where,
          rowsPerPage: this.rowsPerPage,
          selectedPage: this.selectedPage,
        };
        localStorage.setItem(this.storageName,JSON.stringify(local));
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
      }

      this.isLoading = false;
    },
    paidStatus(data) {
      let paidStatus = {
        caption: "",
        class: "",
      };

      if (data == "P") {
        paidStatus.caption = this.lang.payIn;
        paidStatus.class = "primary";
      }
      if (data == "F") {
        paidStatus.caption = this.lang.refunded;
        paidStatus.class = "success";
      }
      if (data == "D") {
        paidStatus.caption = this.lang.splitRefunded;
        paidStatus.class = "primary";
      }

      return paidStatus;
    },
    postStep(data) {
      let postStep = {
        caption: "",
        class: "primary",
      };

      if (data == "P") {
        postStep.caption = this.lang.printed;
      }
      if (data == "A") {
        postStep.caption = this.lang.voucherCreated;
      }

      return postStep;
    },
    changeFilterSearch() {
      this.initiateData();
    },
    async getUniqueKey() {
      try {
        // console.log(this.payloadGetReceipts);
        await this.$store.dispatch("arReceipt/getReceiptUniqueKey");

        if (this.receiptUniqueKey) {
          this.uniqueKeyId = this.receiptUniqueKey.id;
        }
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
      }
    },
    async getPrintForm(data) {
      if (this.validateRolePrint()) {
        return;
      }
      this.selectedReceipt = data;

      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: "INV",
      };
      const payload = { where };
      await this.getFormPrints(payload);

      if (this.filteredFormPrints.length == 1) {
        this.path = this.filteredFormPrints[0].path + ".pdf";
        this.printReceipt();
      } else {
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path + ".pdf";
      //this.categoryPrint = event.category;

      this.printReceipt();

      this.showFormPrintModal = false;
    },
    async printReceipt() {
      this.setLoading(true);
      try {
        let isPrinted = this.selectedReceipt.postStep.trim() == "" ? "false" : "true";
        let mobilePhone = this.selectedReceipt.customerMobileNumber.trim();
        mobilePhone = mobilePhone.substring(1, mobilePhone.length);

        let parameter = {
          ProjectId: this.selectedReceipt.projectId,
          DateStart: "",
          DateEnd: "",
          CodeStart: this.selectedReceipt.code.trim(),
          CodeEnd: this.selectedReceipt.code.trim(),
          ReceiptId: this.selectedReceipt.id,
        };

        let payload = {
          path: this.path,
          fileName: "Receipt",
          parameter: JSON.stringify(parameter),

          projectId: this.selectedReceipt.projectId,
          documentBookId: this.selectedReceipt.documentBookId,
          codeStart: this.selectedReceipt.code.trim(),
          codeEnd: this.selectedReceipt.code.trim(),
          dateStart: "",
          dateEnd: "",
          id: this.selectedReceipt.id,
          projectCode: this.selectedReceipt.projectCode.trim(),
          unitId: this.selectedReceipt.unitId,
          unitCode: this.selectedReceipt.unitCode.trim(),
          unitAddressNo: this.selectedReceipt.unitAddressNo.trim(),
          mobilePhone: mobilePhone,
          isPrinted: isPrinted,

          code: this.selectedReceipt.code.trim(),
          projectName: this.selectedProject.nameTh.trim(),
          customerId: this.selectedReceipt.customerId.trim(),
          customerCode: this.selectedReceipt.customerCode.trim(),
          customerName: this.selectedReceipt.customerName.trim(),
        };
        // console.log("print lot receipt", payload);
        await this.$store.dispatch("arReceipt/printReceipt", payload);

        await this.getPreviewBlob(this.fileBlob);
        await this.getReceipts();
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;

        this.setLoading(false);
      }

      this.setLoading(false);
    },
    async openReferenceDocument(id) {
      if (this.validateRoleSelect()) {
        return;
      }
      this.setLoading(true);
      try {
        const payload = {
          page: 1,
          projectId: this.selectedProject.id,
          id: id,
        };
        await this.$store.dispatch("arReceipt/getReceiptReferenceDocument", payload);
        // console.log(this.dataReferenceDocument);

        this.referenceDocumentData =  this.dataReferenceDocument;
        this.setLoading(false);
        this.showReferenceDocument = true;
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    importReceipt() {
      if (!this.isRoleApprove) {
        this.validateWording = this.lang.validateRoleImport;
        this.showValidateDialog = true;
        return true;
      }
      this.showUploadReceiptDialog = true;
    },
    async checkInputSearch(event) {
      // console.log(event.key);
      if (
        this.selectedFilterSearch == "code" ||
        this.selectedFilterSearch == "netAmount"
      ) {
        const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        const keyPressed = event.key;
        if (this.selectedFilterSearch == "netAmount") {
          keysAllowed.push(".");
        }
        if (event.key != "Enter" && !keysAllowed.includes(keyPressed)) {
          event.preventDefault();
        }
      }

      if (event.key == "Enter") {
        await this.getReceipts();
      }
    },
    async roleByBookDataReady() {
      if (this.isSharedRoleByBookReady) {
        await this.$store.dispatch("arReceipt/setDocumentBookId", this.roleByDocumentBook.documentBookId);
        await this.setLocalSearch();
        await this.getReceipts();
      }
    },
    validateSearchButton() {
      if (
        this.selectedFilterSearch == "date" &&
        (!this.selectedDateStart || !this.selectedDateEnd)
      ) {
        return true;
      } else if (this.selectedFilterSearch != "date" && this.searchText == "") {
        return true;
      }

      return false;
    },
    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      // console.log("created_localSearch=",localSearch);
      if (localSearch) {
        let search = JSON.parse(localSearch);

        if (search.entry === "entry") {
          if (search.dateStart != null && search.dateStart != "") {
            this.selectedFilterSearch = "date";
            this.dateStart = new Date(search.dateStart);
          } else this.dateStart = "";

          if (search.dateEnd != null && search.dateEnd != "") {
            this.selectedFilterSearch = "date";
            this.dateEnd = new Date(search.dateEnd);
          } else this.dateEnd = "";

          if (search.code != null && search.code != "") {
            this.selectedFilterSearch = "code";
            this.searchText = search.code;
          } else if (search.unitCode != null && search.unitCode != "") {
            this.selectedFilterSearch = "unitCode";
            this.searchText = search.unitCode;
          } else if (search.unitAddressNo != null && search.unitAddressNo != "") {
            this.selectedFilterSearch = "unitAddressNo";
            this.searchText = search.unitAddressNo;
          } else if (search.customerName != null && search.customerName != "") {
            this.selectedFilterSearch = "customerName";
            this.searchText = search.customerName;
          } else if (search.netAmount != null && search.netAmount != "") {
            this.selectedFilterSearch = "netAmount";
            this.searchText = search.netAmount;
          } else {
            this.searchText = "";
          }
          this.rowsPerPage = search.rowsPerPage;          
          this.selectedPage = search.selectedPage;

          search.entry = "";

          localStorage.setItem(this.storageName, JSON.stringify(search));
        }
      }
    },
    gotoVisitorControl() {
      if (this.validateRoleSelect()) {
        return;
      }
      this.$router.push({name: "visitor-control"});
    },
    openPrintLotDialog() {
      if (this.validateRolePrint()) {
        return;
      }
      this.showPrintLotDialog = true;
    },
    toastRequest() {
      this.toastMessage = {
        severity: "info",
        summary: this.lang.toastRequestTitle,
        detail: this.lang.toastRequestContent,
        life: 5000,
      };
      this.actionToast = `/${this.selectedProject.code}/report/request?back=${this.$route.fullPath}`;
      this.toastLink = this.lang.toastRequestLink;

      const today = new Date();
			this.showToast = today.getSeconds();
    },
    validateRoleSelect() {
      if(!this.isRoleSelect) {
        this.validateWording = this.lang.validateRoleSelect;
        this.showValidateDialog = true;
        return true;
      }
    },
    validateRoleCreate() {
      if(!this.isRoleCreate) {
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return true;
      }
    },
    validateRolePrint() {
      if(!this.isRolePrint) {
        this.validateWording = this.lang.validatePrintPermission;
        this.showValidateDialog = true;
        return true;
      }
    },
    importBillPayment() {
      this.showUploadBillPaymentDialog = true;
    },
    async uploadBillPayment() {
      this.showUploadBillPaymentDialog = false;
      this.$router.push({name: "ar-receipt-bill-payment"});
    },
  },
  computed: {
    payloadGetReceipts() {
      let where = {
        projectId: this.selectedProject.id,
        dateStart: this.selectedFilterSearch == "date" ? this.selectedDateStart : null,
        dateEnd: this.selectedFilterSearch == "date" ? this.selectedDateEnd : null,
        code: this.selectedFilterSearch == "code" ? this.searchText : null,
        unitCode: this.selectedFilterSearch == "unitCode" ? this.searchText : null,
        unitAddressNo:
          this.selectedFilterSearch == "unitAddressNo" ? this.searchText : null,
        customerName:
          this.selectedFilterSearch == "customerName" ? this.searchText : null,
        netAmount: this.selectedFilterSearch == "netAmount" ? this.searchText : null,
      };

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };
      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "printCaption",
          caption: this.lang.printLot,
          class: "mr-2",
          icon: "icon-printer",
          iconColor: "white",
          action: "gotoPrint",
          isShow: true,
        },
        {
          id: "createCaption",
          caption: this.lang.addReceipt,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
        },
      ];
      return dataButtons;
    },
    isRoleSelect() {
      if (this.roleByDocumentBook && this.roleByDocumentBook.select == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRoleCreate() {
      if (this.roleByDocumentBook && this.roleByDocumentBook.insert == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRolePrint() {
      if (this.roleByDocumentBook && this.roleByDocumentBook.print == "Y") {
        return true;
      } else {
        return false;
      }
    },
    receipts() {
      return this.$store.getters["arReceipt/receipts"];
    },
    totalPage() {
      return this.$store.getters["arReceipt/totalPage"];
    },
    currentPage() {
      return this.$store.getters["arReceipt/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    receiptUniqueKey() {
      return this.$store.getters["arReceipt/receiptUniqueKey"];
    },
    printForms() {
      return this.$store.getters["shared/formPrintForms"];
    },
    fileBlob() {
      return this.$store.getters["arReceipt/blob"];
    },
    dataReferenceDocument() {
      return this.$store.getters["arReceipt/receiptReferenceDocument"];
    },
    isSearchDate() {
      if (this.selectedFilterSearch == "date") {
        return true;
      } else {
        return false;
      }
    },
    isSearchNumber() {
      if (this.selectedFilterSearch == "netAmount") {
        return true;
      } else {
        return false;
      }
    },
    isSearchOther() {
      if (this.selectedFilterSearch != "date") {
        return true;
      } else {
        return false;
      }
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    roleByDocumentBook() {
      return this.getRoleByBookByCode("INV");
    },
    showCurrentPage(){
      let page = 0;
      page = this.rowsPerPage * (this.selectedPage - 1)
      return page;
    },
    isRoleApprove() {
      if (this.roleByDocumentBook && this.roleByDocumentBook.approve == "Y") {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    isSharedRoleByBookReady() {
      this.roleByBookDataReady();
    },
  },
};
</script>

<style scoped>
.ellipsis {
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}
</style>
