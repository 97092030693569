export default {
	createTitle: "Create Supplier", 
	editTitle: "Edit Supplier", 
	containerTitle: "Supplier Information",

	cardTitle1: "Data Part 1",
	cardTitle2: "Data Part 2",
	cardTitle3: "Data Part 3",

	labelCode: "Supplier code", 
	placeholderCode: "Enter supplier code.", 
	validateCodeIsNull: "Please enter supplier code.",
	validateCodeDuplicate: "The supplier code is duplicate.",  
	labelTaxId: "Tax Id", 
	placeholderTaxId: "Enter tax id.", 
	validateTaxIdIsNull: "Please enter tax id.", 
	validateTaxIdDuplicate: "The supplier tax id is duplicate.", 
	validateTaxIdIsNotValid: "The supplier tax id is not valid.", 
	labelName: "Supplier name", 
	placeholderName: "Enter supplier name.", 
	validateName: "Please enter supplier name.",
	labelGender: "Supplier type",
	itemGender1: "Company",
	itemGender2: "Male",
	itemGender3: "Female",
	labelAddress1: "Address",
	placeholderAddress1: "Enter address for to be displayed in the documents.", 
	labelAddress2: "District and sub district",
	placeholderAddress2: "Enter district and sub district for to be displayed in the documents.", 
	labelAddress3: "Province",
	placeholderAddress3: "Enter province for to be displayed in the documents.",
	labelPostCode: "Post code",
	placeholderPostCode: "Enter post code for to be displayed in the documents.",
	labelTelephoneNumber: "Telephone",
	placeholderTelephoneNumber: "Enter telephone.",
	labelMobileNumber: "Mobile",
	placeholderMobileNumber: "Enter mobile.",
	labelFaxNumber: "Fax",
	placeholderFaxNumber: "Enter fax.",
	labelEmail: "Email",
	placeholderEmail: "Enter email.",
	labelIsCustomer: "Would you like to add supplier into as a customer.",
	labelSupplierCreditTerm: "Credit terms",
	labelBankAccountNumber: "Book bank account number",
	placeholderBankAccountNumber: "Enter book bank account number",
	labelWithholdingTaxRate: "Withholding tax rate %",
	labelBank: "Bank",
	labelVatRate: "Vat rate %",
	labelContactName: "Contact name",
	validateContactName: "Enter contact name.",
	labelContactAddress1: "Address",
	validateContactAddress1: "Enter delivery, warehouse, branch address.", 
	labelContactAddress2: "District and sub district",
	validateContactAddress2: "Enter district and sub district.",
	labelContactAddress3: "Province and post code",
	validateContactAddress3: "Enter province and post code.",

	dialogTitleCreate: "Create new supplier.",
	dialogContentCreate: "Are you sure you want to discard change and create new data?",
	dialogTitleDelete: "Delete supplier.",
	dialogContentDelete: "Are you sure you want to delete supplier code {0} data?",
	dialogTitleDeleted: "Delete supplier success.",
	dialogContentDeleted: "Delete supplier {0} success.",
	dialogTitleSave: "Save success.",
	dialogContentSave: "Save supplier success.",

	dialogValidateTitle: "Something went wrong",
	dialogValidateParamsIdNotFound: "Couldn't get supplier.", // code: 102
	dialogValidateParamsProjectId: "The project id parameter is empty.", // code: 103
	dialogValidateParamsCode: "The supplier code parameter is empty.", // code: 104
	dialogValidateParamsName: "The supplier name parameter is empty.", // code: 105
	dialogValidateParamsTaxId: "The supplier tax id parameter is empty.", // code: 106
	dialogValidateParamsGendar: "The supplier type parameter is empty.", // code: 107
	dialogValidateParamsId: "The supplier id parameter is empty.", // code: 108
	dialogValidateInsertRole: "You don't have permission to create supplier.", // code: 111
	dialogValidateUpdateRole: "You don't have permission to update supplier.", // code: 112
	dialogValidateDeleteRole: "You don't have permission to delete supplier.", // code: 113
	dialogValidateInsertAndDuplicateCode: "The supplier code is duplicate.", // code: 120
	dialogValidateInsertAndDuplicateTaxId: "The supplier tax id is duplicate.", // code: 121
	dialogValidateReferencePurchaseRequisition: "Couldn't delete supplier, Because the supplier has purchase requisition.", // code: 130
	dialogValidateReferencePurchaseOrder: "Couldn't delete supplier, Because the supplier has purchase order.", // code: 131
	dialogValidateReferencePurchaseInvoice: "Couldn't delete supplier, Because the supplier has purchase invoice.", // code: 132
	dialogValidateReferencePurchaseReceipt: "Couldn't delete supplier, Because the supplier has purchase receipt.", // code: 133
	dialogValidateReferencePayment: "Couldn't delete supplier, Because the supplier has payment.", // code: 134
	dialogValidateReferenceWithholdingTax: "Couldn't delete supplier, Because the supplier has withholding tax.", // code: 135
	dialogValidateReferenceUnitRersident: "Couldn't delete supplier, Because the supplier has unit.", // code: 136
	dialogValidateReferenceUnitHistory: "Couldn't delete supplier, Because the supplier has unit history.", // code: 137

	buttonCreate: "Create",
	buttonSave: "Save",
	buttonDelete: "Delete", 
	businessType: "Business Type",
	emergencyContact: "Emergency Contact",
	accountingData: "Accounting information",
	labelAddress2Remark: "This information will change according to the subdistrict and district",
	labelEmergencyContactName: "Contact Name",
	placeholderEmergencyContactName: "Enter contact name.",
};
