export default {
	async getCurrency(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Currency";

		if (!payload.limit) {
			url = url + "?limit=100";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			const error = new Error(responseData.message || "Failed to get currency");
			throw error;
		}
		// console.log("currency",responseData);

		// const data = responseData.data;

		const currency = {
			currencies: responseData.data,
			totalPage: responseData.page.pageCount,
			currentPage: responseData.page.currentPage,
		};

		context.commit("setCurrency", currency);
	},
	async getAllCurrency(context) {
		while (!context.getters.currencyIsLastPage) {
			let where = {
				projectId: context.rootGetters.selectedProject.id,
			};
			const payload = {
				page: context.getters.currencyNextPage || 1,
				where,
			};
			await context.dispatch("getCurrency", payload);
		}
	},
	resetCurrency(context) {
		context.commit("resetCurrency");
	},
};
