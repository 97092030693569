<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
    :header="lang.btnPrintLot"
  >
    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchText"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>
    <base-list-book-bank-dialog
      v-model:visible="showFormBookBank"
      @close="showFormBookBank = false"
      @select-book-bank="selectBookBank"
      :inputSearchText="bookBank"
    >
    </base-list-book-bank-dialog>
    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="bookBank">{{ lang.bookBank }}</label>
          <div class="p-inputgroup mt-1">
            <PvInputText
              id="bookBank"
              type="text"
              class="inputfield w-full mt-1"
              :placeholder="lang.search"
              v-model="bookBank"
              @focus="$event.target.select()"
              @keydown.enter.prevent="bookBankInput($event)"
              @keydown.tab.prevent="bookBankInput($event)"
              autofocus
              :class="{ 'p-invalid': bookBankError }"
            />
            <PvButton
              icon="pi pi-search"
              class="mt-1"
              @click="getBookBankLists()"
            />
          </div>

          <small v-if="bookBankError" id="unit-help" class="p-error">{{
            lang.validatebbookBankError
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-5">
          <PvInputText
            v-model="bookBankName"
            type="text"
            class="inputfield w-full mt-1 p input-disable"
            readonly
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="codeStart">{{ lang.codeStart }}</label>
          <PvInputText
            id="codeStart"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="6501xxxxx"
            v-model="codeStart"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="codeEnd">{{ lang.codeEnd }}</label>
          <PvInputText
            id="codeEnd"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="6501xxxxx"
            v-model="codeEnd"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="cancelStatus">{{ lang.cancelStatus }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="cancelStatus"
            optionLabel="name"
            optionValue="code"
            v-model="selectedCancelStatus"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-5">
          <PvButton
            icon="pi pi-search"
            :label="lang.search"
            @click="getDataPrint"
            class="mr-2 mb-2"
          />

          <PvButton
            :label="lang.printPdf"
            icon="pi pi-file-pdf"
            class="mr-2 mb-2"
            @click="selectPrintForm"
          />
        </div>
      </section>
    </transition>

    <PvDataTable
      showGridlines
      :value="PrintLotItem"
      v-model:selection="selectedPrintLot"
      selectionMode="single"
      dataKey="id"
      responsiveLayout="scroll"
      :stripedRows="true"
      class="p-datatable-sm"
      :class="textTableClass"
      :loading="isLoading"
      :scrollHeight="'400px'"
    >
      <PvColumn
        field="itemNumber"
        :header="lang.itemNo"
        style="min-width: 4rem"
        headerClass="h-center"
        bodyClass="text-center"
      ></PvColumn>
      <PvColumn
        field="payDate"
        :header="lang.columnPayDate"
        style="min-width: 4rem"
        headerClass="h-center"
        bodyClass="text-center"
      >
        <template #body="slotProps">
          {{ formatDate(slotProps.data.payDate) }}
        </template>
      </PvColumn>
      <PvColumn
        field="toBankDate"
        :header="lang.columnToBankDate"
        style="min-width: 4rem"
        headerClass="h-center"
        bodyClass="text-center"
      >
        <template #body="slotProps">
          {{ formatDate(slotProps.data.toBankDate) }}
        </template>
      </PvColumn>
      <PvColumn
        field="code"
        :header="lang.columnCode"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>
      <PvColumn
        field="customerCode"
        :header="lang.columnCustomerCode"
        style="min-width: 4rem"
        headerClass="h-center"
        :sortable="true"
      ></PvColumn>
      <PvColumn
        field="customerName"
        :header="lang.columnCustomerName"
        style="min-width: 8rem"
        headerClass="h-center"
      ></PvColumn>
      <PvColumn
        field="payAmount"
        :header="lang.columnPrice"
        style="min-width: 2rem"
        headerClass="h-center"
        bodyClass="text-right"
      >
        <template #body="slotProps">
          {{ formatCurrency(slotProps.data.payAmount) }}
        </template>
      </PvColumn>
      <!-- <PvColumn
        field="paymentMethodCode"
        :header="lang.columnPayCode"
        style="min-width: 2rem"
        headerClass="h-center"
        bodyClass="text-center"
      ></PvColumn> -->
      <PvColumn
        field="status"
        :header="lang.columnStatus"
        style="min-width: 1rem"
        headerClass="h-center"
        bodyClass="text-center"
      ></PvColumn>
      <PvColumn
        field="cancelStatus"
        :header="lang.columnIsCancel"
        style="min-width: 1rem"
        headerClass="h-center"
      ></PvColumn>
    </PvDataTable>

    <template #footer>
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        class="p-button-text mb-2"
        @click="closeDialog"
      />
    </template>
  </PvDialog>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import useGetFormPrint from "../../hooks/getFormPrint.js";
import BaseListBookBankDialog from "../bank/BaseListBookBankDialog.vue";

export default {
  props: ["openFrom"],
  emits: ["close"],
  components: {
    BaseListBookBankDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const {
      getFormPrints,
      searchText,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints
    } = useGetFormPrint();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
          ...lang.th.ar.bank.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
          ...lang.en.ar.bank.listing,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getFormPrints,
      searchText,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints
    };
  },
  data() {
    return {
      isLoading: false,
      selectedPrintLot: [],
      showFormBookBank: false,
      selectedBookBank: null,
      bookBankError: false,
      bookBank: "",
      bookBankName: "",
      codeStart: "",
      codeEnd: "",
      cancelStatus: [
        { name: "ใช่", code: "All" },
        { name: "ไม่ใช่", code: "" },
      ],
      selectedCancelStatus: "",
      type: -1,
      showFormPrintModal: false,
      path: "",
    };
  },
  created() {
    this.initiateData();
    this.setLoading(false);
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
    },
    initiateData() {
      this.isLoading = false;
      this.selectedPrintLot = [];
      this.showFormBookBank = false;
      this.selectedBookBank = null;
      this.bookBankError = false;
      this.bookBank = "";
      this.bookBankName = "";
      this.codeStart = "";
      this.codeEnd = "";
      this.selectedCancelStatus = "";
      this.showFormPrintModal = false;
      this.path = "";
      this.$store.dispatch("bank/resetPrintLot");
    },
    bookBankInput() {
      this.showFormBookBank = true;
    },
    getBookBankLists() {
      this.showFormBookBank = true;
    },
    selectBookBank(data) {
      // console.log("selectBookBank_data=",data);
      this.selectedBookBank = data;
      this.bookBank = data.code;
      this.bookBankName =
        data.accountCode + "/" + data.branchName + "/" + data.bankName;
    },
    async getDataPrint() {
      try {
        await this.$store.dispatch("bank/resetPrintLot");
        if (!this.bookBank) {
          this.$swal({
            icon: "warning",
            title: this.lang.validatebbookBankError,
            confirmButtonText: this.lang.close,
            allowOutsideClick: false,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.bookBankError = true;
            }
          });

          return true;
        }
        this.setLoading(true);

        let where = {
          projectId: this.selectedProject.id,
          bookBankId: this.selectedBookBank.id,
          codeStart: this.codeStart,
          codeEnd: this.codeEnd,
          paymentMethodType: this.type || 0,
          //   paymentMethodId: "NV43HH)VZV",
          paymentMethodCode: "CQW",
          cancelStatus: this.selectedCancelStatus,
        };

        const payload = {
          page: 1,
          limit: 100,
          type: this.type,
          where,
        };
        // console.log("getDataPrint=", payload);
        await this.$store.dispatch("bank/getAllPrintLot", payload);
        this.setLoading(false);
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        this.setLoading(false);
      }
    },
    async selectPrintForm() {
      if (this.PrintLotItem.length <= 0) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateDetail,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        return true;
      }
      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: "PayOut",
      };
      const payload = { where };
      await this.getFormPrints(payload);
      
			if(this.filteredFormPrints.length == 1) {
				this.path = this.filteredFormPrints[0].path ;
        this.PrintCheque();
			}else{
				this.showFormPrintModal = true;
			}
    },

    formPrintModalSelected(event) {
      this.path = event.path;
      this.PrintCheque();
      this.showFormPrintModal = false;
    },
    async PrintCheque() {
      let listId = [];
      this.getPrintLot.forEach((data) => {
        listId.push(data.id);
      });
      const Param = {
        projectId: this.selectedProject.id,
        bookBankId: this.selectedBookBank.id,
        codeStart: this.codeStart,
        codeEnd: this.codeEnd,
        cancelStatus: this.selectedCancelStatus,
        listId: listId,
        path: this.path,
        id: "",
      };
      try {
        await this.$store.dispatch("bank/PrintLotCheque", Param);
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      let blob = this.$store.getters["bank/blob"];
      this.getPreviewBlob(blob);
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    getPrintLot() {
      let PrintLot = this.$store.getters["bank/getPrintLot"];
      if (PrintLot) {
        // return PrintLot.sort((a, b) => a.code - b.code);
        // return PrintLot.sort(function(a, b) { return a.code > b.code; });
        return PrintLot.slice().sort(function (a, b) {
          return a.code > b.code ? 1 : -1;
        });
      }
      return [];
    },
    PrintLotItem() {
      let itemNumber = [];
      let i = 1;
      if (this.getPrintLot) {
        this.getPrintLot.forEach((element) => {
          //    if (element.code) {
          element = { ...element, ...{ itemNumber: i } };
          itemNumber.push(element);
          i++;
          //    }
        });
      }
      return itemNumber;
    },
  },
  watch: {
    openFrom() {
      this.initiateData();
    },
    bookBank(curValue,oldValue) {
      this.bookBankError = false;
      if (!curValue) {
        this.bookBankName = "";
      }
      if (curValue !== oldValue) {
        this.$store.dispatch("bank/resetPrintLot");
      }
    },
    selectedCancelStatus(curValue,oldValue){      
      if (curValue !== oldValue) {
        this.$store.dispatch("bank/resetPrintLot");
      }
    },
  },
};
</script>
