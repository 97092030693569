export default {
	setPhoneBooks(state, payload) {
		let phoneBooks = [...state.phoneBooks];
		for (const phoneBook of payload.data) {
			if (phoneBooks.findIndex((c) => c.id === phoneBook.id) < 0) {
				phoneBooks.push(phoneBook);
			}
		}
		state.phoneBooks = phoneBooks;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
	setSelectedPhoneBook(state, payload) {
		state.selectedPhoneBook = payload;
	},
	resetPhoneBook(state) {
		state.phoneBooks = [];
		state.totalPage = 0;
		state.currentPage = 0;
		state.selectedPhoneBook = null;
	},
	
	setContactTypes(state, payload) {
		let contactTypes = [...state.contactTypes];
		for (const contactType of payload.data) {
			if (contactTypes.findIndex((c) => c.id === contactType.id) < 0) {
				contactTypes.push(contactType);
			}
		}
		state.contactTypes = contactTypes;
		state.contactTypeTotalPage = payload.page.pageCount;
		state.contactTypeCurrentPage = payload.page.currentPage;
	},
	resetContactType(state) {
		state.contactTypes = [];
		state.contactTypeTotalPage = 0;
		state.contactTypeCurrentPage = 0;
	},
	
	setPhoneTypes(state, payload) {
		let phoneTypes = [...state.phoneTypes];
		for (const phoneType of payload.data) {
			if (phoneTypes.findIndex((c) => c.id === phoneType.id) < 0) {
				phoneTypes.push(phoneType);
			}
		}
		state.phoneTypes = phoneTypes;
		state.phoneTypeTotalPage = payload.page.pageCount;
		state.phoneTypeCurrentPage = payload.page.currentPage;
	},
	resetPhoneType(state) {
		state.phoneTypes = [];
		state.phoneTypeTotalPage = 0;
		state.phoneTypeCurrentPage = 0;
	},
};