<template> 
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="pt-2 pb-1">
        <label class="font-lg font-normal">{{ lang.settingBudget }}</label>
      </div>
      <PvDivider></PvDivider>

      <div
        class="flex flex-wrap justify-content-between align-items-center mb-2"
      >
        <div>
          <span class="p-input-icon-left w-full">
            <i class="pi pi-search" />
            <PvInputText
              class="inputfield w-full"
              v-model="filterSearchText['global']"
              :placeholder="lang.keywordSearch"
              autofocus
            />
          </span>
        </div>

        <div class="flex">
          <PvButton class="p-button-link px-1" @click="expandAll">
            <icon-expand iconColor="primary" width="16" height="16" />
            <span class="p-button-label pl-2 font-normal">{{
              lang.settingBudget
            }}</span>
          </PvButton>

          <PvButton class="p-button-link pr-2" @click="collapseAll">
            <icon-collapse iconColor="primary" width="16" height="16" />
            <span class="p-button-label pl-2 font-normal">{{
              lang.settingBudget
            }}</span>
          </PvButton>
        </div>
      </div>
    </template>

    <section class="flex flex-wrap pt-0" :class="textFormClass">
      <PvTreeTable
        :value="details"
        :expandedKeys="expandedKeys"
        v-model:selectionKeys="selectedKey"
        selectionMode="checkbox"
        :filters="filterSearchText"
        filterMode="lenient"
        @node-unselect="onNodeUnselect"
        :scrollHeight="halfViewportHeight + 'px'"
      >
        <PvColumn
          field="accountCode"
          :expander="true"
          :header="lang.columnAccountCode"
          style="min-width: 5rem"
          headerClass="text-center font-normal font-xs"
        >
          <template #body="slotProps">
            <span
              class="text-left font-xs"
              :class="{ 'font-semibold': slotProps.node.data.isDetail == 'N' }"
            >
              {{ slotProps.node.data.accountCode }}
            </span>
          </template>
        </PvColumn>
        <PvColumn
          field="accountName"
          :header="lang.columnAccountName"
          style="min-width: 10rem"
          headerClass="text-center font-normal font-xs "
        >
          <template #body="slotProps">
            <span
              class="text-left font-xs"
              :class="{ 'font-semibold': slotProps.node.data.isDetail == 'N' }"
            >
              {{ slotProps.node.data.accountName }}
            </span>
          </template>
        </PvColumn>

        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ tableEmpty }}</label>
          </div>
        </template>
      </PvTreeTable>
    </section>
    <template #footer>
      <div
        class="pt-2 pb-1 pl-3 pr-3 flex flex-wrap justify-content-end align-items-center"
      >
        <div class="lg:col-3 md:col-4 col-6 pl-1 pr-1">
          <PvButton
            :label="lang.close"
            class="p-button-outlined p-button-secondary w-full"
            @click="closeDialog()"
          />
        </div>
        <div class="lg:col-3 md:col-4 col-6 pl-1 pr-1">
          <PvButton
            :label="lang.confirm"
            @click="confirmDialog()"
            class="w-full"
          />
        </div>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import useGetAccounting from "../../hooks/getAccounting.js";
import { useStore } from "vuex";
import { ref, computed, onMounted, watch } from "vue";
export default {
  emits: ["close", "confirm"],
  props: {
    isCreate: {
      type: Boolean,
    },
    selectedAccounts: {
      type: Object,
      default: null,
    }
  },
  setup(props, { emit }) {
    const { lang } = useLang();
    const store = useStore();
    const { textFormClass } = useTextStyle();
    const { isSharedAccountingReady, getAllAccountings, accountings } =
      useGetAccounting();
    const { textTableClass } = useFormat();

    const mergeLang = computed(() => {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.budget.listing,
          ...lang.th.budget.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.budget.listing,
          ...lang.en.budget.edit,
        };
      }
    });

    let expandedKeys = ref({});
    let selectedKey = ref(null);
    let filterSearchText = ref({});
    let details = ref([]);
    let selectedBudgetAccounts = ref([]);
    let unSelectAccounts = ref([]);     

    onMounted(async () => {
      await getAllAccountings();
      checkShouldPrepareData();
    });

    //function
    const closeDialog = () => {
      emit("close");
    };

    const confirmDialog = () => {
      // console.log("selectedKey=", selectedKey.value);
      // console.log("accountingMasterSubs.value=", accountingMasterSubs.value);
      let selectedAccounts = [];
      let selectedAccountIds = [];
      let unSelectAccounts = [];
      for (const key in selectedKey.value) {
        // console.log("key=", key);
        selectedAccountIds.push(key)
        let account = accountingMasterSubs.value.find((e) => e.id == key);
        if (account) {
          let data = {
            accountId: account.id,
            accountCode: account.code.trim(),
            accountName: account.name,
            accountParentId: account.parentId,
            type: account.type,
            isDetail: account.isDetail,
            searchText: account.code.trim() + account.name,
          };
          selectedAccounts.push(data);
        }
      }

      // console.log("selectedKey=", selectedKey.value);
      // console.log("selectedBudgetAccounts=", selectedBudgetAccounts.value);

      if(selectedBudgetAccounts.value.length > 0) { 
        selectedBudgetAccounts.value.forEach(e => {
          let checkUnSelected = selectedAccountIds.find(x => x == e); 
          if(!checkUnSelected) {
            let account = accountingMasterSubs.value.find((c) => c.id == e);
            if (account) {
                let data = {
                  accountId: account.id,
                  accountCode: account.code,
                  accountName: account.name,
                  accountParentId: account.parentId,
                };
                unSelectAccounts.push(data);
            }
          }          
        });
      }

      let payload = {
        selectedAccounts: selectedAccounts,
        unSelectAccounts: unSelectAccounts
      }  

      emit("confirm", payload);
     
    };

    const checkShouldPrepareData = () => {
      if (isSharedAccountingReady.value && accountingMasters.value) {
        setData();
        expandAll();
      }
    };

    const setData = async () => {
      setAccountDetails();
      // console.log("setData=", details.value);
      // console.log("setData_props.selectedAccounts=", props.selectedAccounts);
      if(props.selectedAccounts){
        selectedKey.value = props.selectedAccounts;
        for (const key in props.selectedAccounts) {
          selectedBudgetAccounts.value.push(key) ;   
        }
        selectedBudgetAccounts.value = [...new Set(selectedBudgetAccounts.value.map((item) => item))];
        // console.log("selectedBudgetAccounts.value=",selectedBudgetAccounts.value);
      }
    };

    const setAccountDetails = () => {
      let parents = [];
      accountingMasterParents.value.forEach((e) => {
        let data = {
          id: e.id,
          budgetHeaderId: "",
          accountId: e.id,
          accountCode: e.code.trim(),
          accountName: e.name,
          accountParentId: e.parentId,
          level: e.level,
          type: e.type,
          totalAmount: 0,
          isDetail: "N",
          searchText: e.code.trim() + e.name,
        };
        let parent = {
          key: e.id,
          data: data,
          children: setSubAccounts(e.id),
        };
        parents.push(parent);
      });
      details.value = parents;
      //   return parents;
    };

    const setSubAccounts = (parentId) => {
      let details = [];
      let masterDetails = accountingMasterSubs.value.filter(
        (e) => e.parentId == parentId
      );
      masterDetails.forEach((e) => {
        let data = {
          id: e.id,
          budgetHeaderId: "",
          accountId: e.id,
          accountCode: e.code.trim(),
          accountName: e.name,
          accountParentId: e.parentId,
          level: e.level,
          type: e.type,
          totalAmount: 0,
          isDetail: "Y",
          searchText: e.code.trim() + e.name,
        };

        let detail = {
          key: e.id,
          data: data,
        };
        details.push(detail);
      });
      return details;
    };

    const expandAll = () => {
      for (let detail of details.value) {
        expandNode(detail);
      }
      expandedKeys.value = { ...expandedKeys.value };
    };

    const collapseAll = () => {
      expandedKeys.value = {};
    };

    const expandNode = (node) => {
      if (node.children && node.children.length) {
        expandedKeys.value[node.key] = true;

        for (let child of node.children) {
          expandNode(child);
        }
      }
    };

    const onNodeUnselect = () => {
      // console.log("onNodeUnselect=",node);
    };
    
    //computed
    const halfViewportHeight = computed(() => {
      return store.getters.halfViewportHeight;
    });

    const tableEmpty = computed(() => {
      let result = mergeLang.value.tableEmpty;
      if (filterSearchText.value) {
        result = mergeLang.value.searchNoDetail;
      }
      return result;
    });

    const accountingMasters = computed(() => {
      let dataSort = [
        ...accountingMasterParents.value,
        ...accountingMasterSubs.value,
      ];
      return dataSort.sort((a, b) => (a.accountCode < b.accountCode ? -1 : 1));
    });

    const accountingMasterParents = computed(() => {
      let ids = [
        ...new Set(accountingMasterSubs.value.map((item) => item["parentId"])),
      ];
      let itemList = [];
      let result = accountings.value.filter((e) => ids.includes(e.id) && e.activeStatus == "Y");
      result.forEach((element) => {
        itemList.push(element);
      });
      return itemList.sort((a, b) => (a.accountCode < b.accountCode ? -1 : 1));
    });

    const accountingMasterSubs = computed(() => {
      let itemList = [];
      let result = accountings.value.filter(
        (e) => e.activeStatus == "Y" && e.isDetail == "Y" && e.type != "2" && e.type != "3"
      );
      result.forEach((element) => {
        itemList.push(element);
      });
      return itemList.sort((a, b) => (a.accountCode < b.accountCode ? -1 : 1));
    });

    const isCreateDocument = computed(() => {
      return props.isCreate;
    });

    watch(isSharedAccountingReady, function () {
      checkShouldPrepareData();
    });

    return {
      lang: mergeLang,
      textFormClass,
      textTableClass,
      isSharedAccountingReady,
      getAllAccountings,
      accountings,

      //params
      filterSearchText,
      details,
      expandedKeys,
      selectedKey,
      selectedBudgetAccounts,
      unSelectAccounts,

      //function
      closeDialog,
      confirmDialog,
      setAccountDetails,
      expandAll,
      collapseAll,
      expandNode,
      onNodeUnselect,
      
      //computed
      halfViewportHeight,
      tableEmpty,
      isCreateDocument,
    };
  },
};
</script>

<style scoped>
.p-treetable :deep(.p-treetable-thead > tr > th) {
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 0 0 0 0;
  background: #f8f9fa;
}

/* .p-treetable :deep(.p-treetable-tbody > tr) {
    background: #f8f9fa;
} */
</style>
