export default {
	setRegisterMobileDetail(state, payload) {
		state.registerMobileDetail = payload.data;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
	setRegisterMobileSummary(state, payload) {
		state.registerMobileSummary = payload;
	},
	resetRegisterMobile(state) {
		state.registerMobileSummary = null;
		state.registerMobileDetail = null;
		state.totalPage = 0;
		state.currentPage = 0;
	},
};
