<template>
  <base-toolbar
    :title="lang.titleEdit"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @goto-save="save"
  ></base-toolbar>

  <base-container>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{ title }}</label>
      <PvDivider></PvDivider>

      <div class="lg:col-6 md:col-6 col-12 px-1 pt-3 pb-0">
        <label for="signatureQuantity">{{ lang.signatureQuantity }}</label>
        <PvDropdown
          id="signatureQuantity"
          class="inputfield w-full mt-2"
          :options="signatureQuantities"
          optionLabel="name"
          optionValue="code"
          v-model="selectedSignatureQuantity"
        />
      </div>

      <div class="pt-5" v-show="selectedSignatureQuantity >= 1">
        <label class="font-lg font-normal">{{ lang.firstSignator }}</label>

        <div class="col-12 flex flex-wrap p-0">
          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pr-3">
            <base-input-text-limit
              :caption="lang.roleInDocument"
              :limitMessage="50"
              :data="roleOne"
              @data="updateRoleOne"
            />
          </div>
          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pr-3">
            <label for="positionAndName">{{ lang.positionAndName }}</label>
            <signature-dropdown
              id="selectedSignatureOne"
              v-model="selectedSignatureOne"
              class="inputfield w-full mt-1"
              @load-finish="signatureDataReady"
              :isLoadingData="true"
              @change="selectSignatureOne"
            />
          </div>
          <div class="col-12 mt-0 pl-0 pr-0 lg:pr-3">
            <base-image-preview
              v-if="signatureOneUrlBase64"
              :imageUrl="signatureOneUrlBase64"
              @delete-image="deleteImage()"
              :hideDeleteButton="true"
            />
          </div>
          <div class="field-checkbox mt-2">
            <PvCheckbox
              id="isDisplayPositionOne"
              v-model="isDisplayPositionOne"
              :binary="true"
            />
            <label for="isDisplayPositionOne">{{ lang.displayPosition }}</label>
          </div>
          <PvDivider v-if="selectedSignatureQuantity > 1"></PvDivider>
        </div>
      </div>

      <div class="pt-4" v-show="selectedSignatureQuantity >= 2">
        <label class="font-lg font-normal">{{ lang.secondSignator }}</label>

        <div class="col-12 flex flex-wrap p-0">
          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pr-3">
            <base-input-text-limit
              :caption="lang.roleInDocument"
              :limitMessage="50"
              :data="roleTwo"
              @data="updateRoleTwo"
            />
          </div>
          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pr-3">
            <label for="positionAndName">{{ lang.positionAndName }}</label>
            <signature-dropdown
              id="selectedSignatureTwo"
              v-model="selectedSignatureTwo"
              class="inputfield w-full mt-1"
              @change="selectSignatureTwo"
            />
          </div>
          <div class="col-12 mt-0 pl-0 pr-0 lg:pr-3">
            <base-image-preview
              v-if="signatureTwoUrlBase64"
              :imageUrl="signatureTwoUrlBase64"
              @delete-image="deleteImage()"
              :hideDeleteButton="true"
            />
          </div>
          <div class="field-checkbox mt-2">
            <PvCheckbox
              id="isDisplayPositionTwo"
              v-model="isDisplayPositionTwo"
              :binary="true"
            />
            <label for="isDisplayPositionTwo">{{ lang.displayPosition }}</label>
          </div>
          <PvDivider v-if="selectedSignatureQuantity > 2"></PvDivider>
        </div>
      </div>

      <div class="pt-4" v-show="selectedSignatureQuantity >= 3">
        <label class="font-lg font-normal">{{ lang.thirdSignator }}</label>

        <div class="col-12 flex flex-wrap p-0">
          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pr-3">
            <base-input-text-limit
              :caption="lang.roleInDocument"
              :limitMessage="50"
              :data="roleThree"
              @data="updateRoleThree"
            />
          </div>
          <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pr-3">
            <label for="positionAndName">{{ lang.positionAndName }}</label>
            <signature-dropdown
              id="selectedSignatureThree"
              v-model="selectedSignatureThree"
              class="inputfield w-full mt-1"
              @change="selectSignatureThree"
            />
          </div>
          <div class="col-12 mt-0 pl-0 pr-0 lg:pr-3">
            <base-image-preview
              v-if="signatureThreeUrlBase64"
              :imageUrl="signatureThreeUrlBase64"
              @delete-image="deleteImage()"
              :hideDeleteButton="true"
            />
          </div>
          <div class="field-checkbox mt-2">
            <PvCheckbox
              id="isDisplayPositionThree"
              v-model="isDisplayPositionThree"
              :binary="true"
            />
            <label for="isDisplayPositionThree">{{
              lang.displayPosition
            }}</label>
          </div>
        </div>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import { useStore } from "vuex";
import { ref, onMounted, computed } from "vue";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import BaseDialog from "../../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import useTextStyle from "../../../hooks/textStyle.js";
import SignatureDropdown from "../../../components/master/signatureManagement/SignatureDropdown.vue";
import usePageDirty from "../../../hooks/pageDirty";
export default {
  components: {
    SignatureDropdown,
  },
  props: ["projectCode", "reportMenuDetailId", "id"],
  setup(props) {
    const { lang } = useLang();
    const store = useStore();
    const router = useRouter();

    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();
    const { textFormClass } = useTextStyle();
    const { isCompareObject } = usePageDirty();

    onMounted(async () => {
      setLoading(true);
      initiateData();
      await roleByMenuDataReady();
      checkShouldSetData();
    });

    let dataConfigDialog = ref(null);
    let signatureQuantities = ref([]);
    let selectedSignatureQuantity = ref(null);
    let isSharedSignatureReady = ref(true);
    let roleOne = ref("");
    let selectedSignatureOne = ref(null);
    let signatureOneUrlBase64 = ref("");
    let isDisplayPositionOne = ref(true);
    let roleTwo = ref("");
    let selectedSignatureTwo = ref(null);
    let signatureTwoUrlBase64 = ref("");
    let isDisplayPositionTwo = ref(true);
    let roleThree = ref("");
    let selectedSignatureThree = ref(null);
    let signatureThreeUrlBase64 = ref("");
    let isDisplayPositionThree = ref(true);
    let objToCompare = ref(null);
    let isPageDirty = ref(false);

    //computeds
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.signatureManagement.signatureManagement.edit,
          ...lang.th.master.signatureManagement.signatureManagement.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.signatureManagement.signatureManagement.edit,
          ...lang.en.master.signatureManagement.signatureManagement.listing,
        };
      }
    });
    const title = computed(() => {
      let result = mergeLang.value.description;
      if (selectedSignatureManagement.value) {
        result += selectedSignatureManagement.value.reportMenuDetail;
      }
      return result;
    });
    const isCreateDocument = computed(() => {
      return props.id == "create";
    });
    const dataButtons = computed(() => {
      let result = [
        {
          id: "save",
          action: "goto-save",
          type: "save",
        },
      ];
      return result;
    });
    const roleByMenu = computed(() => {
      return getRoleByMenuByCode("pms_rpt_signature");
    });
    const isRoleCreate = computed(() => {
      if (roleByMenu.value && roleByMenu.value.insert == "Y") {
        return true;
      }
      return false;
    });
    const isRoleUpdate = computed(() => {
      if (roleByMenu.value && roleByMenu.value.update == "Y") {
        return true;
      }
      return false;
    });
    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const selectedSignatureManagement = computed(() => {
      return store.getters["signatureManagement/selectedSignatureManagement"];
    });
    const firstSignatorData = computed(() => {
      const datas = selectedSignatureManagement.value
        ? selectedSignatureManagement.value.details
        : [];
      const result = datas.find((data) => data.itemNo == 1);

      return result;
    });
    const secondSignatorData = computed(() => {
      const datas = selectedSignatureManagement.value
        ? selectedSignatureManagement.value.details
        : [];
      const result = datas.find((data) => data.itemNo == 2);

      return result;
    });
    const thirdSignatorData = computed(() => {
      const datas = selectedSignatureManagement.value
        ? selectedSignatureManagement.value.details
        : [];
      const result = datas.find((data) => data.itemNo == 3);

      return result;
    });
    const signatures = computed(() => {
      return store.getters["signature/signatureAll"];
    });
    const details = computed(() => {
      const headerId = !isCreateDocument.value?parseInt(props.id):null;
      const results = [
        {
          signatureManagementHeaderId: headerId,
          id: firstSignatorData.value ? firstSignatorData.value.id : null,
          signatureId: selectedSignatureOne.value
            ? selectedSignatureOne.value.id
            : null,
          itemNo: 1,
          role: roleOne.value,
          isDisplayPosition: isDisplayPositionOne.value ? "Y" : "N",
        },
        {
          signatureManagementHeaderId: headerId,
          id: secondSignatorData.value ? secondSignatorData.value.id : null,
          signatureId: selectedSignatureTwo.value
            ? selectedSignatureTwo.value.id
            : null,
          itemNo: 2,
          role: roleTwo.value,
          isDisplayPosition: isDisplayPositionTwo.value ? "Y" : "N",
        },
        {
          signatureManagementHeaderId: headerId,
          id: thirdSignatorData.value ? thirdSignatorData.value.id : null,
          signatureId: selectedSignatureThree.value
            ? selectedSignatureThree.value.id
            : null,
          itemNo: 3,
          role: roleThree.value,
          isDisplayPosition: isDisplayPositionThree.value ? "Y" : "N",
        },
      ];
      return results;
    });
    const payload = computed(() => {
      const filteredDetails = details.value.filter(
        (each) => each.itemNo <= selectedSignatureQuantity.value
      );

      let payload = {
        projectId: selectedProject.value.id,
        reportMenuDetailId: props.reportMenuDetailId,
        signatureQuantity: selectedSignatureQuantity.value,
        details: filteredDetails,
      };
      return payload;
    });

    //methods
    const setLoading = (bool) => {
      store.dispatch("setLoading", { value: bool });
    };
    const goBack = () => {
      setCompareObject();
      router.push({ name: "signature-management" });
    };
    const initiateData = () => {
      store.dispatch("signatureManagement/resetSignatureManagement");
      dataConfigDialog.value = null;
      signatureQuantities.value = [
        { name: mergeLang.value.defaultFormat, code: 0 },
        { name: mergeLang.value.onePerson, code: 1 },
        { name: mergeLang.value.twoPerson, code: 2 },
        { name: mergeLang.value.threePerson, code: 3 },
      ];
      selectedSignatureQuantity.value = 0;
      objToCompare.value = null;
      isPageDirty.value = false;
      initiateSignatureData();
    };
    const initiateSignatureData = () => {
      roleOne.value = "";
      selectedSignatureOne.value = null;
      signatureOneUrlBase64.value = "";
      isDisplayPositionOne.value = true;
      roleTwo.value = "";
      selectedSignatureTwo.value = null;
      signatureTwoUrlBase64.value = "";
      isDisplayPositionTwo.value = true;
      roleThree.value = "";
      selectedSignatureThree.value = null;
      signatureThreeUrlBase64.value = "";
      isDisplayPositionThree.value = true;
    };
    const setDialog = (dataConfig) => {
      const classMap = {
        success: "p-text-success",
        error: "p-error",
      };

      dataConfig.classTitle = classMap[dataConfig.classTitle] || "";

      dataConfig.button = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "cancel",
        },
      ];

      if (dataConfig.type === "confirm") {
        dataConfig.button.push({
          id: "confirm",
          caption: mergeLang.value.buttonDialogConfirm,
          class: "w-full",
          action: "confirm",
        });
      }

      dataConfigDialog.value = {
        titleConfirm: dataConfig.title,
        classTitle: dataConfig.classTitle,
        wordingConfirm: dataConfig.message,
        dataButtons: dataConfig.button,
        display: true,
      };
    };
    const roleByMenuDataReady = async () => {
      if (isSharedRoleByMenuReady.value && !isCreateDocument.value) {
        await getSignatureManagementById();
      }
    };
    const getSignatureManagementById = async () => {
      try {
        const where = {
          projectId: selectedProject.value.id,
          id: props.id,
        };
        await store.dispatch(
          "signatureManagement/getSignatureManagementById",
          where
        );
      } catch (error) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: error,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        setLoading(false);
      }
    };
    const signatureDataReady = () => {
      isSharedSignatureReady.value = true;
      checkShouldSetData();
    };
    const checkShouldSetData = () => {
      if (isSharedRoleByMenuReady.value) {
        if (!isCreateDocument.value && selectedSignatureManagement.value) {
          if (isSharedSignatureReady.value) {
            setDataSignature();
          }
          setData();
        } else if (isCreateDocument.value) {
          setLoading(false);
        }
        setCompareData();
      }
    };
    const setData = () => {
      selectedSignatureQuantity.value =
        selectedSignatureManagement.value.signatureQuantity;
      setLoading(false);
    };
    const setDataSignature = () => {
      initiateSignatureData();
      //ลงนามลำดับที่ 1
      if (firstSignatorData.value) {
        roleOne.value = firstSignatorData.value.role;
        selectedSignatureOne.value = signatures.value.find(
          (signature) => signature.id === firstSignatorData.value.signatureId
        );
        signatureOneUrlBase64.value = firstSignatorData.value.url;
        isDisplayPositionOne.value =
          firstSignatorData.value.isDisplayPosition == "Y" ? true : false;
      }
      
      //ลงนามลำดับที่ 2
      if (secondSignatorData.value) {
        roleTwo.value = secondSignatorData.value
          ? secondSignatorData.value.role
          : "";
        selectedSignatureTwo.value = signatures.value.find(
          (signature) => signature.id === secondSignatorData.value.signatureId
        );
        signatureTwoUrlBase64.value = secondSignatorData.value.url;
        isDisplayPositionTwo.value =
          secondSignatorData.value.isDisplayPosition == "Y" ? true : false;
      }

      //ลงนามลำดับที่ 3
      if (thirdSignatorData.value) {
        roleThree.value = thirdSignatorData.value
          ? thirdSignatorData.value.role
          : "";
        selectedSignatureThree.value = signatures.value.find(
          (signature) => signature.id === thirdSignatorData.value.signatureId
        );
        signatureThreeUrlBase64.value = thirdSignatorData.value.url;
        isDisplayPositionThree.value =
          thirdSignatorData.value.isDisplayPosition == "Y" ? true : false;
      }
    };
    const setCompareData = () => {
      const filteredDetails = details.value.filter(
        (each) => each.itemNo <= selectedSignatureQuantity.value
      );
      let compare = {
        projectId: selectedProject.value.id,
        reportMenuDetailId: props.reportMenuDetailId,
        signatureQuantity: selectedSignatureQuantity.value,
        details: filteredDetails,
      };
      if (!isCreateDocument.value) {
        compare = {
          ...compare,
          id: props.id,
        };
      }
      objToCompare.value = compare;
      setCompareObject();
    };
    const setCompareObject = () => {
      let result = false;
      if (isCreateDocument.value) {
        result = isCompareObject(objToCompare.value, payload.value);
      } else {
        let dataPayload = {
          ...payload.value,
          id: props.id,
        };
        result = isCompareObject(objToCompare.value, dataPayload);
      }
      isPageDirty.value = result;
    };
    const updateRoleOne = (data) => {
      roleOne.value = data;
    };
    const updateRoleTwo = (data) => {
      roleTwo.value = data;
    };
    const updateRoleThree = (data) => {
      roleThree.value = data;
    };
    const selectSignatureOne = (data) => {
      signatureOneUrlBase64.value = data.value?data.value.signatureUrl:"";
    };
    const selectSignatureTwo = (data) => {
      signatureTwoUrlBase64.value = data.value?data.value.signatureUrl:"";
    };
    const selectSignatureThree = (data) => {
      signatureThreeUrlBase64.value = data.value?data.value.signatureUrl:"";
    };
    const validateRoleSave = () => {
      if (isCreateDocument.value && !isRoleCreate.value) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.validateRoleInsert,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        return true;
      } else if (!isCreateDocument.value && !isRoleUpdate.value) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.validateRoleUpdate,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        return true;
      }
    };
    const save = async () => {
      if (validateRoleSave()) {
        return;
      }
      setLoading(true);
      try {
        if (isCreateDocument.value) {
          await addSignatureManagement();
        } else {
          await updateSignatureManagemen();
        }

        if (isCreateDocument.value) {
          await router.replace({
            name: "signature-management-edit",
            params: {
              id: selectedSignatureManagement.value.id,
            },
          });
        }
        setDialog({
          classTitle: "success",
          title: mergeLang.value.saveAllSucess,
          message: mergeLang.value.saveAllSucess,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();

        setCompareData();
        setDataSignature();
        setLoading(false);
      } catch (error) {
        let message = error.message;
        if (error.code == "101") {
          message = mergeLang.value.errorDuplicateReport;
        } else if (error.code == "102") {
          message = mergeLang.value.errorReportNotFound;
        } else if (error.code == "103") {
          message = mergeLang.value.errorQuantityNotMatchDetails;
        }
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: message,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        setLoading(false);
      }
    };
    const addSignatureManagement = async () => {
    //   console.log(payload.value);
      await store.dispatch(
        "signatureManagement/createSignatureManagement",
        payload.value
      );
    };
    const updateSignatureManagemen = async () => {
      let payloadUpdate = {
        ...payload.value,
        id: parseInt(props.id),
      };
      await store.dispatch(
        "signatureManagement/updateSignatureManagement",
        payloadUpdate
      );
    };

    onBeforeRouteLeave(async (to, from) => {
      if (isPageDirty.value) {
        var curValue = to.params.projectCode;
        var oldValue = from.params.projectCode;
        if (curValue == oldValue) {
          setDialog({
            classTitle: "",
            title: mergeLang.value.notificationDialogHeader,
            message: mergeLang.value.pageDirtyConfirmMessage,
            type: "confirm",
          });
          const dialogResponse = await createConfirmDialog(
            BaseDialog,
            dataConfigDialog.value
          ).reveal();
          return !dialogResponse.isCanceled;
        }
      }
      return true;
    });

    return {
      lang: mergeLang,
      textFormClass,

      signatureQuantities,
      selectedSignatureQuantity,
      roleOne,
      selectedSignatureOne,
      signatureOneUrlBase64,
      isDisplayPositionOne,
      roleTwo,
      selectedSignatureTwo,
      signatureTwoUrlBase64,
      isDisplayPositionTwo,
      roleThree,
      selectedSignatureThree,
      signatureThreeUrlBase64,
      isDisplayPositionThree,

      //computeds
      title,
      dataButtons,

      //methods
      goBack,
      updateRoleOne,
      updateRoleTwo,
      updateRoleThree,
      signatureDataReady,
      selectSignatureOne,
      selectSignatureTwo,
      selectSignatureThree,
      save,
    };
  },
};
</script>
