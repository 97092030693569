<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
    :header="lang.title"
  >
    <div class="flex flex-wrap justify-content-center w-full">
      <div class="w-6">
        <cropper
          ref="cropper"
          class="cropper"
          :src="image.src"
          image-restriction="stencil"
          background-class="cropper__background"
          foreground-class="cropper__foreground"
          :transitions="false"
          :default-size="defaultSize"
          :stencil-props="{
            movable: true,
            scalable: true,
            aspectRatio: raito,
            resizable: true,
          }"
          @change="onChange"
        />

        <div class="navigation">
          <div class="navigation__wrapper">
            <div class="navigation__zoom-icon--left">
              <icon-search-zoom-minus></icon-search-zoom-minus>
            </div>
            <div class="navigation__line-wrapper" ref="line" @mousedown="onStart" @touchstart="onStart">
              <div class="navigation__line">
                <div
                  class="navigation__fill"
                  :style="{
                    flexGrow: zoom,
                  }"
                ></div>
                <div
                  class="navigation__circle"
                  :class="{ 'navigation__circle--focus': focus }"
                  :style="{
                    left: `${zoom * 100}%`,
                  }"
                >
                  <div class="navigation__inner-circle" :class="{ 'navigation__inner-circle--focus': focus }"></div>
                </div>
              </div>
            </div>

            <div class="navigation__zoom-icon--right">
              <icon-search-zoom-plus></icon-search-zoom-plus>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center">
        <div class="lg:col-6 md:col-6 col-12 pl-1 pr-1">
          <PvButton :label="lang.close" class="p-button-outlined p-icon-secondary w-full" @click="closeDialog()" />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-1 pr-1">
          <PvButton :label="lang.ok" autofocus class="w-full" @click="confirmCrop()" />
        </div>
      </div>
    </template>
  </PvDialog>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
export default {
  props: {
    width: {
      default: 86,
      type: Number,
    },
    height: {
      default: 48,
      type: Number,
    },
    image: {
      type: Object,
      default() {
        return {
          src:
            "https://play-lh.googleusercontent.com/itzMNBlrjw2B7OiFSMFnfcJHH8T1lgajAB3ij6NZOHSRa7UGIbuLpy0DFqMUE3ipcA=w480-h960",
          type: null,
        };
      },
    },
  },
  components: {
    Cropper,
  },
  setup() {
    const { lang } = useLang();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.shared.cropImageModal };
      } else {
        return { ...lang.en.base, ...lang.en.shared.cropImageModal };
      }
    });

    return {
      lang: mergeLang,
    };
  },
  mounted() {
    window.addEventListener("mouseup", this.onStop, { passive: false });
    window.addEventListener("mousemove", this.onDrag, { passive: false });
    window.addEventListener("touchmove", this.onDrag, { passive: false });
    window.addEventListener("touchend", this.onStop, { passive: false });
    this.initiateData();
  },
  unmounted() {
    window.removeEventListener("mouseup", this.onStop);
    window.removeEventListener("mousemove", this.onDrag);
    window.removeEventListener("touchmove", this.onDrag);
    window.removeEventListener("touchend", this.onStop);
    this.initiateData();
  },
  data() {
    return {
      focus: false,
      zoom: 0,
      raito: 16 / 9,
      fileCrop: null,
    };
  },
  methods: {
    initiateData() {
      this.zoom = 0;
      this.focus = false;
      this.fileCrop = null;
    },
    onDrag(e) {
      if (this.focus) {
        const position = e.touches ? e.touches[0].clientX : e.clientX;
        const line = this.$refs.line;

        if (line) {
          const { left, width } = line.getBoundingClientRect();
          let change = Math.min(1, Math.max(0, position - left) / width);
          this.onZoom(change);
          this.zoom = change;
        }
        if (e.preventDefault) {
          e.preventDefault();
        }
      }
    },
    onStop() {
      this.focus = false;
    },
    onStart(e) {
      this.focus = true;
      this.onDrag(e);
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    async confirmCrop() {
      // console.log(this.cropImage);
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        try {
          this.fileCrop = await this.canvasToBlobAsync(canvas);
        } catch (error) {
          console.error(error);
        }
      }

      this.$emit("confirm-crop", canvas.toDataURL(), this.fileCrop);
      this.initiateData();
    },
    async canvasToBlobAsync(canvas) {
      const randomString = this.generateRandomString(10);
      const dataUrl = canvas.toDataURL();
      const imageType = dataUrl.split(";")[0].split(":")[1];

      return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], `${randomString}.${blob.type.replace("image/", "")}`, { type: blob.type });
            resolve(file);
          } else {
            reject(new Error("Failed to convert canvas to blob."));
          }
        }, imageType);
      });
    },
    generateRandomString(length) {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    },
    onChange() {
      const cropper = this.$refs.cropper;
      if (cropper) {
        const { coordinates, imageSize } = cropper;
        if (imageSize.width / imageSize.height > coordinates.width / coordinates.height) {
          this.zoom =
            (cropper.imageSize.height - cropper.coordinates.height) /
            (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
        } else {
          this.zoom =
            (cropper.imageSize.width - cropper.coordinates.width) /
            (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
        }
      }
      // if (canvas) {
      //   this.cropImage = result.canvas.toDataURL();
      // }
    },
    onZoom(value) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
          const minHeight = cropper.sizeRestrictions.minHeight;
          const imageHeight = cropper.imageSize.height;
          cropper.zoom(
            (imageHeight - this.zoom * (imageHeight - minHeight)) / (imageHeight - value * (imageHeight - minHeight))
          );
        } else {
          const minWidth = cropper.sizeRestrictions.minWidth;
          const imageWidth = cropper.imageSize.width;
          cropper.zoom(
            (imageWidth - this.zoom * (imageWidth - minWidth)) / (imageWidth - value * (imageWidth - minWidth))
          );
        }
      }
    },
  },
  computed: {
    defaultSize() {
      const cropper = this.$refs.cropper;
      if (cropper) {
        const { imageSize } = cropper;
        return {
          width: imageSize.width * this.raito,
          height: imageSize.height * this.raito,
        };
      } else {
        return {
          width: this.width * this.raito,
          height: this.height * this.raito,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 50px;
  &__wrapper {
    display: flex;
    align-items: center;
    max-width: 400px;
    width: 100%;
  }
  &__zoom-icon {
    height: 18.75px;
    width: 18.75px;
    fill: rgb(101, 119, 134);
    flex-shrink: 0;
    &--left {
      margin-right: 10px;
    }
    &--right {
      margin-left: 10px;
    }
  }
  &__line-wrapper {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
  }
  &__line {
    background: rgb(142, 208, 249);
    height: 5px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    position: relative;
    align-items: center;
  }
  &__fill {
    background: rgb(29, 161, 242);
    align-self: stretch;
    flex-basis: auto;
    flex-direction: column;
    flex-shrink: 0;
  }
  &__circle {
    width: 30px;
    height: 30px;
    margin-left: -15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transition-duration: 0.2s;
    transition-property: background-color, box-shadow;
    background-color: transparent;
    &:hover {
      background-color: rgba(29, 161, 242, 0.1);
    }
    &--focus {
      background-color: rgba(29, 161, 242, 0.2);
    }
  }
  &__inner-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgb(29, 161, 242);
    transform: scale(1);
    transition-duration: 0.1s;
    transition-property: transform;
    box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 7px, rgba(101, 119, 134, 0.15) 0px 1px 3px 1px;
    &--focus {
      transform: scale(1.2);
    }
  }
}
.cropper {
  height: 300px;
  &__background {
    background-color: #edf2f4;
  }
  &__foreground {
    background-color: #edf2f4;
  }
}
</style>
