export default({
    date:"Date",  
    dueDate:"DueDate",
    printAmountMoreThan: "PrintAmountMoreThan", 
    unitCodeStart:"UnitCodeStart", 
    unitCodeEnd:"UnitCodeEnd", 
    letterBy:"LetterBy", 
    printGroup:"PrintGroup", 
    suspendDate:"SuspendDate",
    overDueDay:"OverDue (Day)",  
	language: "English",  
    setupProduct: "Setup Product",

    markUpTitle: "Unable to mark up data.",
    unitCodeMarkUp: "Unit Code",
    unitAddressNoMarkUp: "Address No.",
    totalAmountMarkUp: "Total Amount",

    projectNameThMarkUp: "Project name Thai",
    projectNameEnMarkUp: "Project name English",
    customerNameThMarkUp: "Customer name Thai",
    customerNameEnMarkUp: "Customer name English",
    totalAmountTextThMarkUp: "Total Amount Thai",
    totalAmountTextEnMarkUp: "Total Amount English",
    dueDateThMarkUp: "Due Date B.E.",
    dueDateEnMarkUp: "Due Date A.D.",
    suspendDateThMarkUp: "Suspend Date Thai",
    suspendDateEnMarkUp: "Suspend Date English",

    columnValue: "markup retrieval data",
    columnName: "name markup",
})