export default {
    files(state) {
      return state.files;
    },  
    selectedFile(state) {
      return state.selectedFile;
    },      
    totalPage(state) {
      return state.totalPage;
    },
    currentPage(state) {
      return state.currentPage;
    },
    rows(state) {
      return state.rows;
    },
    blob(state) {
      return state.blob;
    },
  };