export default {
    invoiceGenerateQueueStatus(state) {
        return state.invoiceGenerateQueueStatus;
    },
    invoiceReadyGenerateBill(state) {
        return state.invoiceReadyGenerateBill;
    },
    selectedinvoiceGenerateQueue(state) {
        return state.selectedinvoiceGenerateQueue;
    },
    invoices(state) {
        return state.invoices;
    },
    selectedInvoice(state) {
        return state.selectedInvoice;
    },
    invoicesTotalPage(state) {
        return state.invoicesTotalPage;
    },
    invoicesCurrentPage(state) {
        return state.invoicesCurrentPage;
    },
    invoicesRows(state) {
        return state.invoicesRows;
    },
    invoiceIsCanceled(state) {
        return state.invoiceIsCanceled;
    },
    generatedInvoice(state) {
        return state.generatedInvoice;
    },
    isUsedPrepaid(state) {
        return state.isUsedPrepaid;
    },
    isUsedDeposit(state) {
        return state.isUsedDeposit;
    },
}