export default {    
    setPurchaseInvoices(state, payload){
        state.purchaseInvoices = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },    
    resetPurchaseInvoice(state, payload){
        state.purchaseInvoices = payload;
    },
    setPurchaseInvoiceSelected(state, payload){
        state.purchaseInvoiceSelected = payload
    }, 
    setPurchaseInvoiceToGl(state, payload){
        state.purchaseInvoiceToGl = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    }, 
    setCancelPurchaseInvoice(state, payload){
        state.cancelPurchaseInvoice = payload.success
    },   
};