<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '70vw' }"
    :modal="true"
    :closable="false"
    :header="parameterTitleText"
  >
    <base-purchase-invoice-model
      v-model:visible="showPurchaseInvoiceModal"
      @close="showPurchaseInvoiceModal = false"
      :openFrom="openImportPurchaseInvoice"
      :voucherId="voucherId"
    >
    </base-purchase-invoice-model>

    <base-book-purchase-modal
      v-model:visible="showBookPurchaseModal"
      :search="searchTextBookPurchase"
      :isSharedLoading="isSharedBookPurchaseReady"
      @close="showBookPurchaseModal = false"
      @bookPurchase-selected="bookPurchaseModalSelected"
    ></base-book-purchase-modal>

    <base-create-purchase-invoice-model
      v-model:visible="showCreatePurchaseInvoiceModal"
      @close="showCreatePurchaseInvoiceModal = false"
      @create-purchase-invoice="createPurchaseInvoice"
      :openFrom="openFromEditPurchaseInvoice"
      :purchaseBookSelected="purchaseBookSelected"
      :voucherId="voucherId"
      :purchaseInvoiceId="purchaseInvoiceId"
    >
    </base-create-purchase-invoice-model>

    <div class="pt-2 pb-1">
      <PvButton
        icon="pi pi-file"
        :label="lang.create"
        class="mr-2 mb-2"
        @click="createPurchaseInvoiceToGl"
      />
      <PvButton
        icon="pi pi-plus"
        :label="lang.importDocument"
        class="mr-2 mb-2"
        @click="importPurchaseInvoicePI"
      />
      <PvButton
        icon="pi pi-minus"
        :label="lang.removeDocument"
        class="p-button-outlined p-button-danger mr-2 mb-2"
        @click="askdeletePurchaseInvoiceWithVoucherAll"
      />
    </div>

    <PvDataTable
      showGridlines
      :value="purchaseInvoiceSelectedItem"
      v-model:selection="selectedPurchaseInvoices"
      dataKey="id"
      :loading="isLoading"
      responsiveLayout="scroll"
      :scrollHeight="halfViewportHeight + 'px'"
      :stripedRows="true"
      class="p-datatable-sm"
      :class="textTableClass"
    >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
      <PvColumn
        field="itemNumber"
        :header="lang.itemNo"
        style="min-width: 4rem"
        headerClass="h-center"
        bodyClass="text-center"
      ></PvColumn>
      <PvColumn
        field="moduleCode"
        :header="lang.type"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>

      <PvColumn
        field="documentBookCode"
        :header="lang.bookCode"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>

      <PvColumn
        field="code"
        :header="lang.columnCode"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>

      <PvColumn
        field="refCode"
        :header="lang.columnRefCode"
        style="min-width: 4rem"
        headerClass="h-center"
        sortable
      ></PvColumn>

      <PvColumn
        field="date"
        :header="lang.columnDate"
        style="min-width: 4rem"
        headerClass="h-center"
        bodyClass="text-center"
      >
        <template #body="slotProps">
          {{ formatDate(slotProps.data.date) }}
        </template>
      </PvColumn>

      <PvColumn
        field="supplierCode"
        :header="lang.columnSupplier"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>

      <PvColumn
        field="supplierName"
        :header="lang.columnSupplierName"
        style="min-width: 7rem"
        headerClass="h-center"
      >
      </PvColumn>

      <PvColumn
        field="netAmount"
        :header="lang.columnNetAmount"
        style="min-width: 5rem"
        headerClass="h-center"
        bodyClass="text-right"
        ><template #body="slotProps">
          {{ formatCurrency(slotProps.data.netAmount) }}
        </template>
      </PvColumn>
      <PvColumn
        style="min-width: 4rem"
        headerClass="h-center"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <PvButton
            type="button"
            class="p-button-secondary p-button-text py-0"
            icon="pi pi-pencil"
            @click="gotoEdit(slotProps.data.id)"
          ></PvButton>

          <PvButton
            type="button"
            class="p-button-danger p-button-text py-0"
            icon="pi pi-times"
            v-if="slotProps.data.voucherId.trim() === ''"
            @click="deleteRowSelected(slotProps.data.id)"
          ></PvButton>

          <PvButton
            type="button"
            class="p-button-danger p-button-text py-0"
            icon="pi pi-trash"
            v-else
            @click="askdeletePurchaseInvoiceWithVoucherId(slotProps.data.id)"
          ></PvButton>
        </template>
      </PvColumn>
    </PvDataTable>

    <template #footer>
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog"
      />
      <PvButton
        :label="lang.autoGl"
        icon="pi pi-check"
        @click="confirmPurchaseInvoiceToGl"
      />
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import useGetBookPurchase from "../../hooks/getBookPurchase.js";
import basePurchaseInvoiceModel from "../GL/BasePurchaseInvoiceModel.vue";
import baseCreatePurchaseInvoiceModel from "../purchaseInvoice/BaseEditPurchaseInvoiceView.vue";
export default {
  components: {
    basePurchaseInvoiceModel,
    baseCreatePurchaseInvoiceModel,
  },
  props: ["openFrom", "voucherId"],
  emits: [
    "close",
    "confirm-purchase-invoice-to-gl",
    "create-purchase-invoice",
    "edit-purchase-invoice",
  ],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass, textAlertClass } =
      useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const {
      isSharedBookPurchaseReady,
      searchTextBookPurchase,
      setBookPurchaseCode,
    } = useGetBookPurchase();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ap.purchaseInvoice.listing,
          ...lang.th.gl.accounting.edit,
          ...lang.th.gl.accounting.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ap.purchaseInvoice.listing,
          ...lang.th.gl.accounting.edit,
          ...lang.th.gl.accounting.listing,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      textAlertClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      isSharedBookPurchaseReady,
      searchTextBookPurchase,
      setBookPurchaseCode,
    };
  },
  data() {
    return {
      selectedPage: 1,
      rowsPerPage: 100,
      isLoading: true,
      selectedPurchaseInvoices: [],
      messageError: "",
      showPurchaseInvoiceModal: false,
      showBookPurchaseModal: false,
      showCreatePurchaseInvoiceModal: false,
      openImportPurchaseInvoice: "",
      openFromEditPurchaseInvoice: "",
      purchaseBookSelected: null,
      purchaseInvoiceId: "",
    };
  },
  async created() {
    this.isLoading = false;
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
    },
    createPurchaseInvoiceToGl() {
      //สร้าง PI ใหม่

      // this.setBookPurchaseCode("PJ");
      this.showBookPurchaseModal = true;

      //this.$emit("create-purchase-invoice", null);
      // this.$emit("close");
    },
    bookPurchaseModalSelected(data) {
      //book ที่เลือก
      //ปิดDialogเลือกเล่มใบวางบิล ,เปิดDialogสร้างใบวางบิล
      this.purchaseBookSelected = data; //เก็บ purchaseBook ที่เลือก
      const today = new Date();
      this.openFromEditPurchaseInvoice = this.voucherId + today.getSeconds();

      this.showBookPurchaseModal = false;
      this.showCreatePurchaseInvoiceModal = true;
    },
    gotoEdit(Id) {
      //แก้ไขข้อมูล PI
      this.purchaseInvoiceId = Id;
      this.purchaseBookSelected = null;
      this.showCreatePurchaseInvoiceModal = true;

      const today = new Date();
      this.openFromEditPurchaseInvoice = this.voucherId + today.getSeconds();
      // this.$emit("edit-purchase-invoice", Id);
      // this.$emit("close");
    },
    importPurchaseInvoicePI() {
      //import ข้อมูล PI
      //   console.log("importPurchaseInvoicePI");
      this.$store.dispatch("glPurchaseInvoice/resetPurchaseInvoice", []);
      // this.setBookPurchaseCode("PJ");
      this.showPurchaseInvoiceModal = true;
      const today = new Date();
      this.openImportPurchaseInvoice = this.voucherId + today.getSeconds();
    },
    createPurchaseInvoice() {
      //เมื่อกดมาจากหน้าedit แล้วกดสร้างเอกสารใหม่ต้องให้เลือกเล่มก่อน
      //เปิดDialogเลือกเล่มใบวางบิล
      this.isSharedBookPurchaseReady = false;
      this.showBookPurchaseModal = true;
      const today = new Date();
      this.openFromEditPurchaseInvoice = this.voucherId + today.getSeconds();
    },
    async confirmPurchaseInvoiceToGl() {
      // console.log("000confirmPurchaseInvoiceToGl=",this.purchaseInvoiceSelectedItem);
      if (this.purchaseInvoiceSelected.length <= 0) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateDetailSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return;
      }

      this.setLoading(true);
      let isPaymentRequest = this.purchaseInvoiceSelected.some(data => data.documentBookCode.trim() === "PQ");
      let details = [];
			let request = {
				projectId: this.selectedProject.id,
				isPaymentRequest: isPaymentRequest,
			};
      if (this.purchaseInvoiceSelectedItem) {
        this.purchaseInvoiceSelectedItem.forEach((data) => {
          // console.log("data=",data);
          let detail = {
            headerId: data.id,
            code: data.code,
            bookId: data.documentBookId,
          };
          details.push(detail);
        });
      }

      let purchaseInvoice = { data: details };
      request = { ...request, ...purchaseInvoice };

      // console.log("Request=",Request);
      try {
        await this.$store.dispatch(
          "glPurchaseInvoice/getPurchaseInvoiceToGl",
          request
        );
        await this.purchaseInvoiceToGlMessageError();

        if (this.messageError != "") {
          this.setLoading(false);
          this.$swal({
            icon: "error",
            html: `<p class="${this.textAlertClass}">${this.messageError}</p>`,
            confirmButtonText: this.lang.close,
            allowOutsideClick: false,
          });
        } else {
          this.AddPurchaseInvoiceSelectedToGl();
          this.setLoading(false);
          // console.log("this.purchaseInvoiceToGl=",this.purchaseInvoiceToGl);
        }
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        this.setLoading(false);
      }

      this.setLoading(false);
    },
    AddPurchaseInvoiceSelectedToGl() {
      // console.log("AddPurchaseInvoiceSelectedToGl=",this.ReceiptToGl);
      let details = [];
      let number = 1;
      this.purchaseInvoiceToGl.detail.forEach((Detail) => {
        let detail = {
          accountId: Detail.accountId,
          accountCode: Detail.accountCode,
          accountName: Detail.accountName,
          creditAmount: Detail.creditAmount,
          debitAmount: Detail.debitAmount,
          description: Detail.description,
          group: null,
          headerId: Detail.headerId,
          id: "dummy" + number,
          itemNumber: number,
          jobId: null,
          projectId: null,
          sectionId: null,
          paytype: null,
          remark1: this.purchaseInvoiceSelectedItem[0].remark1,
        };

        details.push(detail);
        number++;
      });
      this.$emit(
				"confirm-purchase-invoice-to-gl", 
				details, 
				this.purchaseInvoiceToGl.voucherDate,
				this.purchaseInvoiceToGl.voucherDescription,
			);
      this.$emit("close");
    },
    async deleteRowSelected(id) {
      const index = this.purchaseInvoiceSelected.findIndex(
        (detail) => detail.id == id
      );
      this.purchaseInvoiceSelected.splice(index, 1);
    },
    async deletePurchaseInvoiceWithVoucherId(id) {
      try {
        this.setLoading(true);
        let InvoiceIds = [];
        InvoiceIds.push(id);
        if (this.voucherId.toLowerCase() !== "create") {
          let payload = {
            ProjectId: this.selectedProject.id,
            VoucherId: this.voucherId,
            InvoiceId: InvoiceIds,
          };
          await this.$store.dispatch(
            "glPurchaseInvoice/deletePurchaseInvoiceWithVoucher",
            payload
          );
          if (this.cancelPurchaseInvoice) {
            const index = this.purchaseInvoiceSelected.findIndex(
              (detail) => detail.id == id
            );
            this.purchaseInvoiceSelected.splice(index, 1);
          }
        }
        this.setLoading(false);
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        this.setLoading(false);
      }

      this.setLoading(false);
    },
    async askdeletePurchaseInvoiceWithVoucherId(id) {
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForDeleteDocument}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        this.deletePurchaseInvoiceWithVoucherId(id)
      }
    },
    async askdeletePurchaseInvoiceWithVoucherAll() {
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForRemoveDocument}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        this.deletePurchaseInvoiceWithVoucherAll();
      }

    },
    async deletePurchaseInvoiceWithVoucherAll() {      
      if(this.purchaseInvoiceSelectedItem.length <= 0) return true;
      try 
      {             
        // console.log("this.purchaseInvoiceSelectedItem=",this.purchaseInvoiceSelectedItem);   
        this.setLoading(true);
        let InvoiceIds = [];
        if (this.voucherId.toLowerCase() !== "create") {
          this.purchaseInvoiceSelectedItem.forEach((data) => {
            if(data.voucherId.trim() !== "") InvoiceIds.push(data.id);
          });
          if(InvoiceIds.length > 0){
            let payload = {
              ProjectId: this.selectedProject.id,
              VoucherId: this.voucherId,
              InvoiceId: InvoiceIds,
            };
            await this.$store.dispatch(
              "glPurchaseInvoice/deletePurchaseInvoiceWithVoucher",
              payload
            );
            if (this.cancelPurchaseInvoice) {
                await this.$store.dispatch("glPurchaseInvoice/resetPurchaseInvoice",[]);
                await this.$store.dispatch("glPurchaseInvoice/setPurchaseInvoiceSelected",[]);
            }
          }
        }
        this.setLoading(false);
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        this.setLoading(false);
      }

      this.setLoading(false);

    },
    purchaseInvoiceToGlMessageError() {
      this.messageError = "";
      let messages = "";
      this.purchaseInvoiceToGl.detail.forEach((detail) => {
        if (detail.error != null && detail.error != "") {
          messages += detail.error + "</br>";
        }
      });
      this.messageError = messages;
    },
  },
  computed: {
    parameterTitleText() {
      return this.lang.titleImportPurchaseInvoice;
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    purchaseInvoiceSelected() {
      return this.$store.getters["glPurchaseInvoice/purchaseInvoiceSelected"];
    },
    purchaseInvoiceToGl() {
      return this.$store.getters["glPurchaseInvoice/purchaseInvoiceToGl"];
    },
    cancelPurchaseInvoice() {
      return this.$store.getters["glPurchaseInvoice/cancelPurchaseInvoice"];
    },
    purchaseInvoiceSelectedItem() {
      let itemNumber = [];
      let i = 1;
      if (this.purchaseInvoiceSelected) {
        this.purchaseInvoiceSelected.forEach((element) => {
          element = { ...element, ...{ itemNumber: i } };
          itemNumber.push(element);
          i++;
        });
      }
      return itemNumber;
    },
    isPurchaseInvoiceSelectedMessage() {
      let Error = false;
      // console.log("this.messageError.length=",this.messageError);
      if (this.messageError.length > 0) Error = true;
      return Error;
    },
  },
  watch: {
    openFrom() {
      this.setBookPurchaseCode("PJ");
    },
  },
};
</script>
<style scoped>
.p-dialog .p-dialog-header {
  padding: 0.2rem !important;
}
</style>
