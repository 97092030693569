<template>
  <PvDialog
    :breakpoints="{ '1280px': '80vw', '640px': '80vw' }"
    :style="{ width: '80vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="lang.notificationDialogHeader"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
    ></base-dialog>

    <template #header>
      <div class="flex justify-content-between align-items-right pt-2">
        <label class="font-lg">{{ lang.pendingList }}</label>
        <PvButton
          icon="pi pi-times"
          class="p-button-text p-icon-secondary pb-0 pt-0"
          style="width: 15px"
          @click="closeDialog"
        />
      </div>

      <PvDivider></PvDivider>

      <div
        class="flex flex-wrap justify-content-between align-items-center pb-3 pt-1"
      >
        <div class="flex lg:col-6 md:col-6 col-6 p-0">
          <span class="p-input-icon-left w-full mr-2">
            <i class="pi pi-search" />
            <PvInputText
              class="inputfield w-full"
              v-model="searchText"
              :placeholder="lang.searchTextPlaceholder"
              @keyup.enter="filterPending"
              autofocus
            />
          </span>

          <PvButton style="width: 6rem" @click="filterPending">
            <span class="font-normal" style="width: 6rem">{{
              lang.search
            }}</span>
          </PvButton>
        </div>

        <div>
          <!-- <PvButton class="mr-2" @click="selectPending('all')">
            <icon-documents-file-checkmark
              iconColor="white"
              width="24"
              height="24"
            />
            <span class="font-normal w-full ml-1">{{ lang.selectedAll }}</span>
          </PvButton> -->
          <PvButton @click="selectPending" :disabled="isDisableConfirm">
            <icon-documents-file-checkmark
              iconColor="white"
              width="24"
              height="24"
            />
            <span class="font-normal w-full ml-1">{{ lang.selectedList }}</span>
          </PvButton>
        </div>
      </div>
    </template>

    <div class="my-2">
      <PvDataTable
        :value="filteredPendings"
        dataKey="detailId"
        v-model:selection="selectedPending"
        responsiveLayout="scroll"
        class="p-datatable-xs"
        :class="textTableClass"
        selectionMode="multiple"
        :scrollable="true"
        :metaKeySelection="false"
        :loading="isLoading"
        :scrollHeight="halfViewportHeight + 'px'"
      >
        <PvColumn
          selectionMode="multiple"
          style="flex: 0; height: 56px"
        ></PvColumn>
        <PvColumn
          field="itemNo"
          :header="lang.itemNo"
          style="max-width: 6rem"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="code"
          :header="lang.receiptCode"
          style="min-width: 10rem;"
          headerClass="h-left"
          bodyClass="text-left"
          sortable
        />
        <PvColumn
          field="date"
          :header="lang.receiptDate"
          style="min-width: 10rem;"
          headerClass="h-left"
          bodyClass="text-left"
          sortable
        >
          <template #body="slotProps">
            {{ formatDate(slotProps.data.date) }}
          </template>
        </PvColumn>
        <PvColumn
          field="productCode"
          :header="lang.productCode"
          style="min-width: 8rem"
          headerClass="h-left"
          bodyClass="text-left"
          sortable
        />
        <PvColumn
          field="productName"
          :header="lang.productName"
          style="min-width: 16rem"
          headerClass="h-left"
          bodyClass="text-left"
          sortable
        />
        <PvColumn
          field="detailPeriod"
          :header="lang.period"
          style="min-width: 4rem"
          headerClass="h-left"
          bodyClass="text-left"
          sortable
        />
        <PvColumn
          field="detailTotalAmount"
          :header="lang.columnNetAmount"
          style="min-width: 10rem"
          headerClass="h-right"
          bodyClass="text-right"
        >
          <template #body="slotProps">
            <span class="text-right w-full">
            {{ formatCurrency(slotProps.data.detailTotalAmount) }}
            </span>
          </template>
        </PvColumn>
        <PvColumn
          field="detailPendingAmount"
          :header="lang.balance"
          style="min-width: 10rem"
          headerClass="h-right"
          bodyClass="text-right"
        >
          <template #body="slotProps">
            <span class="text-right w-full">
            {{ formatCurrency(slotProps.data.detailPendingAmount) }}
            </span>
          </template>
        </PvColumn>

        <template #empty>
          <div class="w-full p-3" style="text-align: center">
            <label>{{ this.lang.tableEmpty }}</label>
          </div>
        </template>
      </PvDataTable>
    </div>

    <template #footer></template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  props: ["unit", "isOpen","refundGuaranteeDetails", "date"],
  emits: ["close", "pending-selected"],
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.refundGuarantee.listing,
          ...lang.th.ar.refundGuarantee.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.th.ar.refundGuarantee.listing,
          ...lang.en.ar.refundGuarantee.edit,
        };
      }
    });

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatDate,
      formatDateToISOString,
      formatCurrency,
    };
  },
  data() {
    return {
      searchText: "",
      isLoading: false,
      selectedPage: 1,
      rowsPerPage: 100,
      selectedPending: [],
      filteredPending: [],
      isFilter: false,

      showValidateDialog: false,
      validateWording: "",
      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ],
    };
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    initiateData() {
      this.searchText = "";
      this.selectedPending = [];
      this.filteredPending = [];
      this.isFilter = false;
      this.$store.dispatch("arRefundGuarantee/resetPendingList");
    },
    async getPendingList() {
      this.isLoading = true;
      try {
        await this.$store.dispatch(
          "arRefundGuarantee/getAllPendingList",
          this.payload
        );
      } catch (error) {
        this.validateWording = error;
        this.showValidateDialog = true;
        this.isLoading = false;
      }

      this.isLoading = false;
    },
    selectPending(type) {
      if (type == "all") {
        this.selectedPending = this.pendingList;
      }
      this.$emit("pending-selected", this.selectedPending);
      this.closeDialog();
    },
    filterPending() {
      this.isLoading = true;
      if (!this.searchText) {
        this.filteredPending = [...this.pendingExceptRefundGuaranteeDetails];
        this.isFilter = false;
      } else {
        this.filteredPending = this.pendingExceptRefundGuaranteeDetails.filter((pending) => {
          return pending.searchText.toLowerCase().includes(this.searchText.toLowerCase());
        });
        this.isFilter = true;
      }
      this.selectedPending = [];
      this.isLoading = false;
    },
  },
  computed: {
    payload() {
      let where = {
        projectId: this.selectedProject.id,
        unitId: this.unit.id,
        date: this.formatDateToISOString(this.date),
        searchText: this.searchText,
      };
      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };

      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    pendingList() {
      let pendingList = this.$store.getters["arRefundGuarantee/pendingList"];
      if (pendingList.length > 0) {
        let i = 1;
        let data = [];
        pendingList.forEach((element) => {
          element = { ...element, ...{ itemNo: i } };
          data.push(element);
          i++;
        });
        pendingList = data;
      }
      return pendingList;
    },
    filteredPendings() {
      if (!this.isFilter) {
        return this.pendingExceptRefundGuaranteeDetails;
      } else {
        return this.filteredPending;
      }
    },
    isDisableConfirm() {
      if(this.selectedPending.length <= 0) {
        return true;
      }
      return false;
    },
    pendingExceptRefundGuaranteeDetails() {
      let details = this.pendingList;
      if (details.length > 0) {
        this.refundGuaranteeDetails.forEach((element) => {
          const index = details.findIndex(
            (p) => p.detailId === element.referenceId
          );
          if (index >= 0) {
            details.splice(index, 1);
          }
        });

        let i = 1;
        let data = [];
        details.forEach((element) => {
          element = { ...element, ...{ itemNo: i } };
          data.push(element);
          i++;
        });
        details = data;
      }

      return details;
    },
  },
  watch: {
    isOpen(curValue) {
      if (curValue) {
        this.initiateData();
        this.getPendingList();
      }
    },
  },
};
</script>
