<template>
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredPetterns"
    field="codeAndName"
    @complete="searchPettern"
    @dropdown-click="filteredPetterns"
    forceSelection
  />
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default {
    emits: ["load-finish"],
    setup() {
    const store = useStore();
    let searchText = ref(null);

    const petterns = computed(() => {
      return store.getters["accountingPolicy/petterns"]
    });

    const filteredPetterns = computed(() => {
      if (!searchText.value) {
        //ช่องว่าง
        const result = [...petterns.value];
        return result;
      } else {
        return petterns.value.filter((e) => {
          return e.codeAndName
            .toLowerCase()
            .includes(searchText.value.toLowerCase());
        });
      }
    });

    const searchPettern = function (event) {
      // console.log(event);
      if (!event.query.trim().length) {
        //ช่องว่าง
        // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
        searchText.value = "";
        searchText.value = null;
      } else {
        searchText.value = event.query;
      }
    };

    let isPetternReady = ref(false);

    const getPettern = function (code) {
      return petterns.value.find((e) => {
        return e.code.toLowerCase() === code.trim().toLowerCase();
      });
    };

    onMounted(async () => {
      const payload = {
        page: 1,
        limit: 100,
        documentBookId: "-DCB"
      };

      await store.dispatch("accountingPolicy/getPetterns",payload);
      isPetternReady.value = true;
    });

    return {
      searchText,
      filteredPetterns,
      searchPettern,
      getPettern,
      isPetternReady,
    };
  },
  watch: {
    isPetternReady(curValue) {
      if (curValue) {
        this.$emit("load-finish");
      }
    },
  },
}
</script>