import { useStore } from "vuex";
import { computed } from "vue";

export default function useGetRoleByBook() {
  const store = useStore();

  const roleByBooks = computed(() => {
    return store.getters["shared/roleByBooks"];
  });

  const isSharedRoleByBookReady = computed(() => {
    return store.getters["shared/roleByBookIsLastPage"];
  });

  const getAllRoleByBooks = async function () {
    if (!isSharedRoleByBookReady.value) {
      let storageRoleBookName = "erp_role_book_"+store.getters.selectedProject.code.trim()+"_"+store.getters.userCode.trim();
      let storageRoleBook = localStorage.getItem(storageRoleBookName);
      if (storageRoleBook) {
        let roleBook = [];
        if (storageRoleBook) {
          roleBook = JSON.parse(storageRoleBook);
        }
        await store.dispatch("shared/trySetRoleByBooks", roleBook);
      } else {
        await store.dispatch("shared/getAllRoleByBooks");
        localStorage.setItem(storageRoleBookName,JSON.stringify(roleByBooks.value));
      }
    }
  };

  const tryGetAllRoleByBooks = function () {
    let storageRoleBookName = "erp_role_book_"+store.getters.selectedProject.code.trim()+"_"+store.getters.userCode.trim();
    localStorage.removeItem(storageRoleBookName);
    getAllRoleByBooks();
  };

  const getRoleByBookByCode = function (bookCode) {
    return roleByBooks.value.find((roleByBook) => 
      roleByBook.documentBookCode.trim().toLowerCase() === bookCode.trim().toLowerCase()
    );
  };

  return {
    getRoleByBookByCode,
    isSharedRoleByBookReady,
    getAllRoleByBooks,
    tryGetAllRoleByBooks,
  }
}