import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export default {
    namespaced: true,
    state(){
        return {
            receipts: [],
            totalPage: 0,
            currentPage: 1,
            rows: 100,
            selectedReceipt: null,
            receiptUniqueKey: null,
            blob: null,
            receiptReferenceDocument: [],
            importReceiptList: [],
            mappedImportReceipt: null,
            validatedImportReceipt: null,
            documentBookId: "",
        }
    },
    actions,
    getters,
    mutations
}