export default {
	titleInvoicePrintLot: "Print invoice.",
	labelReportType: "Report type.",
	radioButtonReportType1: "Print is lot.",
	radioButtonReportType2: "Print combine unit.",
	titleBaseCardInvoicePrintLot: "Invoice description.",
	labelInvoiceDate: "Invoicec date.",
	searchInvoiceDate: "Search invoice date.",
	validateInvoiceDate: "Invalid data. May be data do not exist in the system.",
	labelCustomerType: "Customer type.",
	dropdownCustomerType: [
        { name: "Owner", code: "owner" },
        { name: "Tenant", code: "renter" },
    ],
	labelCustomerGroup: "Customer group.",
	searchCustomerGroup: "Search customer group.",
	labelSearchBy: "Search by.",
	dropdownSearchBy: [
        { name: "Document code", code: "doc_code" },
        { name: "Unit code", code: "room_code" },
    ],
	labelSortBy: "Sort by.",
	dropdownSortBy: [
        { name: "Document code", code: "doc_code" },
        { name: "Unit code", code: "room_code" },
    ],
	labelDocumentCodeStart: "From document code.",
	labelDocumentCodeEnd: "To document code.",
	labelUnitCodeStart: "From unit code.",
	labelUnitCodeEnd: "To unit code",
	labelCustomerCodeStart: "From customer code.",
	labelCustomerCodeEnd: "To customer code",
	searchInvoiceAndUnit: "Please select data.",
	checkboxIsIgnoreZero: "Print this month 0 baht",
	checkboxIsPrintCombineUnit: "Print combine unit",
	checkboxIsIgnoreBillZero: "Print 0 baht",
	notificationDialogHeader: "Notification",
};
