<template>
  <PvDialog
    :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
    :style="{ width: '40vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showConfirmDialog"
      :titleConfirm="confirmTitle"
      :wordingConfirm="confirmContent"
      :classTitle="confirmClassTitle"
      :dataButtons="dataAlertButtons"
      @close="closeAlertDialog()"
    />
    <template #header>
      <div
        class="flex justify-content-between align-items-center flex flex-wrap pt-3 pb-3"
      >
        <span class="font-lg">{{ lang.titleInvoicePrintLot }}</span>
      </div>
      <PvDivider class="mt-3 mb-3" />
    </template>

    <transition>
      <section class="flex flex-wrap mt-0 mb-0" :class="textFormClass">
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pb-0">
          <label for="reportType">{{ lang.labelReportType }}</label>
          <div class="flex">
            <div
              v-for="reportType of reportTypes"
              :key="reportType.code"
              class="field-radiobutton pt-2 mr-5"
            >
              <PvRadioButton
                class="mr-2"
                :inputId="reportType.code"
                name="reportType"
                :value="reportType.code"
                v-model="selectReportType"
                @change="changeReportType()"
                :disabled="isInvoiceDateLoading"
              />
              <label :for="reportType.code">{{ reportType.name }}</label>
            </div>
          </div>
        </div>

        <base-card
          :titleHeader="lang.titleBaseCardInvoicePrintLot"
          :isVisibleExpandButton="false"
          :isHideBackground="false"
          :isVisibleCard="true"
          :isVisibleIconButton="true"
          :screenSizeRegular="false"
          class="col-12 pb-0 mb-3"
        >
          <section class="flex flex-wrap" :class="textFormClass">
            <div :class="`${classDropdownDateAndSort}`">
              <label for="invoiceDates">{{ lang.labelInvoiceDate }}</label>
              <PvSkeleton
                v-if="isInvoiceDateLoading"
                class="inputfield w-full mt-2"
                borderRadius="5px"
                height="38px"
              />
              <PvDropdown
                class="inputfield w-full mt-2"
                :options="
                  selectReportType === '1'
                    ? invoiceDateForPrintLots
                    : invoiceDateForCombineUnits
                "
                optionLabel="name"
                optionValue="code"
                v-model="selectedInvoiceDate"
                :hide-selected="true"
                :max="3"
                @change="changeInvoiceDate()"
                :filter="true"
                :filterPlaceholder="lang.searchInvoiceDate"
                :class="{ 'p-invalid': invoiceDateError }"
                v-else
              />
              <small v-if="invoiceDateError" id="invoiceDate-help" class="p-error">{{
                lang.validateInvoiceDate
              }}</small>
            </div>

            <!-- <div :class="`${classDropdownDateAndSort}`" v-if="isParamsReportType1">
              <label>{{ lang.labelSortBy }}</label>
              <PvDropdown
                class="inputfield w-full mt-2"
                :options="lang.dropdownSortBy"
                optionLabel="name"
                optionValue="code"
                v-model="selectedSortBy"
              />
            </div> -->

            <div class="lg:col-6 md:col-6 col-12 pt-2" v-if="isParamsReportType1">
              <label>{{ lang.labelCustomerType }}</label>
              <PvDropdown
                class="inputfield w-full mt-2"
                :options="lang.dropdownCustomerType"
                optionLabel="name"
                optionValue="code"
                v-model="selectedCustomerType"
              />
            </div>

            <div class="lg:col-6 md:col-6 col-12 pt-2" v-if="isParamsReportType1">
              <label for="customerGroups">{{ lang.labelCustomerGroup }}</label>
              <PvSkeleton
                v-if="isCustomerGroupLoading"
                class="inputfield w-full mt-2"
                borderRadius="5px"
                height="38px"
              />
              <PvDropdown
                class="inputfield w-full mt-2"
                :options="customerGroups"
                optionLabel="name"
                optionValue="code"
                v-model="selectedCustomerGroup"
                :filterPlaceholder="lang.searchCustomerGroup"
                v-else
              />
            </div>
          </section>
        </base-card>

        <div class="lg:col-12 md:col-12 col-12 pt-2" v-if="isParamsReportType1">
          <label>{{ lang.labelSearchBy }}</label>
          <PvDropdown
            class="inputfield w-full mt-2"
            :options="lang.dropdownSearchBy"
            optionLabel="name"
            optionValue="code"
            v-model="selectedSearchBy"
            @change="changeSearchBy()"
          />
        </div>

        <div
          class="lg:col-6 md:col-6 col-12 pt-2"
          v-if="isSearchByDocumentCode && isParamsReportType1"
        >
          <label>{{ lang.labelDocumentCodeStart }}</label>
          <PvSkeleton
            v-if="isDocumentCodeStartLoading"
            class="inputfield w-full mt-2"
            borderRadius="5px"
            height="38px"
          />
          <PvDropdown
            class="inputfield w-full mt-2"
            :options="documentCodeStarts"
            optionLabel="name"
            optionValue="code"
            v-model="selectedDocumentCodeStart"
            :filter="true"
            :filterPlaceholder="lang.searchInvoiceAndUnit"
            v-else
          />
        </div>
        <div
          class="lg:col-6 md:col-6 col-12 pt-2"
          v-if="isSearchByDocumentCode && isParamsReportType1"
        >
          <label>{{ lang.labelDocumentCodeEnd }}</label>
          <PvSkeleton
            v-if="isDocumentCodeEndLoading"
            class="inputfield w-full mt-2"
            borderRadius="5px"
            height="38px"
          />
          <PvDropdown
            class="inputfield w-full mt-2"
            :options="documentCodeEnds"
            optionLabel="name"
            optionValue="code"
            v-model="selectedDocumentCodeEnd"
            :filter="true"
            :filterPlaceholder="lang.searchInvoiceAndUnit"
            v-else
          />
        </div>

        <div
          class="lg:col-6 md:col-6 col-12 pt-2"
          v-if="!isSearchByDocumentCode && isParamsReportType1"
        >
          <label>{{ lang.labelUnitCodeStart }}</label>
          <PvSkeleton
            v-if="isUnitCodeStartLoading"
            class="inputfield w-full mt-2"
            borderRadius="5px"
            height="38px"
          />
          <PvDropdown
            class="inputfield w-full mt-2"
            :options="unitCodeStarts"
            optionLabel="name"
            optionValue="code"
            v-model="selectedUnitCodeStart"
            :filter="true"
            :filterPlaceholder="lang.searchInvoiceAndUnit"
            v-else
          />
        </div>

        <div
          class="lg:col-6 md:col-6 col-12 pt-2"
          v-if="!isSearchByDocumentCode && isParamsReportType1"
        >
          <label>{{ lang.labelUnitCodeEnd }}</label>
          <PvSkeleton
            v-if="isUnitCodeStartLoading"
            class="inputfield w-full mt-2"
            borderRadius="5px"
            height="38px"
          />
          <PvDropdown
            class="inputfield w-full mt-2"
            :options="unitCodeEnds"
            optionLabel="name"
            optionValue="code"
            v-model="selectedUnitCodeEnd"
            :filter="true"
            :filterPlaceholder="lang.searchInvoiceAndUnit"
            v-else
          />
        </div>

        <div
          class="lg:col-6 md:col-6 col-12 pt-2"
          v-if="!isParamsReportType1 && !isParamsReportType1"
        >
          <label>{{ lang.labelCustomerCodeStart }}</label>
          <PvSkeleton
            v-if="isCustomerCodeStartLoading"
            class="inputfield w-full mt-2"
            borderRadius="5px"
            height="38px"
          />
          <PvDropdown
            class="inputfield w-full mt-2"
            :options="customerCodeStarts"
            optionLabel="name"
            optionValue="code"
            v-model="selectedCustomerCodeStart"
            :filter="true"
            :filterPlaceholder="lang.searchInvoiceAndUnit"
            v-else
          />
        </div>

        <div
          class="lg:col-6 md:col-6 col-12 pt-2"
          v-if="!isParamsReportType1 && !isParamsReportType1"
        >
          <label>{{ lang.labelCustomerCodeEnd }}</label>
          <PvSkeleton
            v-if="isCustomerCodeEndLoading"
            class="inputfield w-full mt-2"
            borderRadius="5px"
            height="38px"
          />
          <PvDropdown
            class="inputfield w-full mt-2"
            :options="customerCodeEnds"
            optionLabel="name"
            optionValue="code"
            v-model="selectedCustomerCodeEnd"
            :filter="true"
            :filterPlaceholder="lang.searchInvoiceAndUnit"
            v-else
          />
        </div>

        <div class="lg:col-4 col-12" v-if="isParamsReportType1">
          <PvCheckbox
            id="selectedIsIgnoreZero"
            v-model="selectedIsIgnoreZero"
            :binary="true"
            class="overflow-hidden mr-2"
          />
          <label for="selectedIsIgnoreZero">{{ lang.checkboxIsIgnoreZero }}</label>
        </div>
        <div class="lg:col-4 col-12" v-if="isParamsReportType1">
          <PvCheckbox
            id="selectedIsPrintCombineUnit"
            v-model="selectedIsPrintCombineUnit"
            :binary="true"
            class="overflow-hidden mr-2"
          />
          <label for="selectedIsPrintCombineUnit">{{
            lang.checkboxIsPrintCombineUnit
          }}</label>
        </div>
        <div class="lg:col-4 col-12" v-if="isParamsReportType1">
          <PvCheckbox
            id="selectedIsIgnoreBillZero"
            v-model="selectedIsIgnoreBillZero"
            :binary="true"
            class="overflow-hidden mr-2"
          />
          <label for="selectedIsIgnoreBillZero">{{
            lang.checkboxIsIgnoreBillZero
          }}</label>
        </div>
      </section>
    </transition>

    <template #footer>
      <div class="flex justify-content-between align-items-center m-3 mt-0 pt-0">
        <PvButton
          :label="lang.close"
          class="p-button-outlined p-button-secondary w-full"
          @click="closeDialog()"
        />
        <PvButton
          :label="selectReportType === '1' ? lang.request : lang.print"
          :disabled="isDisablePrintButton"
          class="w-full"
          @click="confirm()"
        />
      </div>
    </template>
  </PvDialog>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
export default {
  props: {
    openDialog: {
      type: String,
    },
    documentBookId: {
      type: String,
    },
  },
  emits: ["close", "confirm"],
  setup() {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const { formatDate } = useFormat();
    const { getPreviewBlob } = useGetFormPrint();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.invoice.printLot,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.th.ar.invoice.printLot,
        };
      }
    });

    return {
      lang: mergeLang,
      textFormClass,
      formatDate,
      getPreviewBlob,
    };
  },
  data() {
    return {
      isParamsReportType1: true,
      reportTypes: [
        { name: this.lang.radioButtonReportType1, code: "1" },
        { name: this.lang.radioButtonReportType2, code: "2" },
      ],
      selectReportType: "1",
      isInvoiceDateLoading: true,
      invoiceDateError: false,
      selectedInvoiceDate: "",
      selectedCustomerType: "owner",
      isCustomerGroupLoading: true,
      customerGroups: [],
      selectedCustomerGroup: "",
      selectedSearchBy: "doc_code",
      selectedSortBy: "doc_code",
      isDocumentCodeStartLoading: true,
      documentCodeStarts: [],
      selectedDocumentCodeStart: "",
      isDocumentCodeEndLoading: true,
      documentCodeEnds: [],
      selectedDocumentCodeEnd: "",
      isUnitCodeStartLoading: true,
      unitCodeStarts: [],
      selectedUnitCodeStart: "",
      isUnitCodeEndLoading: true,
      unitCodeEnds: [],
      selectedUnitCodeEnd: "",
      isCustomerCodeStartLoading: true,
      customerCodeStarts: [],
      selectedCustomerCodeStart: "",
      isCustomerCodeEndLoading: true,
      customerCodeEnds: [],
      selectedCustomerCodeEnd: "",
      isSearchByDocumentCode: true,
      selectedIsIgnoreZero: true,
      selectedIsPrintCombineUnit: true,
      selectedIsIgnoreBillZero: true,
      classDropdownDateAndSort: "lg:col-6 md:col-6 col-12 pt-2",
      isDisablePrintButton: true,
      roleByDocumentBook: null,
      showConfirmDialog: false,
      confirmTitle: "",
      confirmContent: "",
      confirmClassTitle: "",
    };
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    openAlertDialog(dataConfig) {
      if (dataConfig.severity === "") {
        this.confirmClassTitle = "";
      } else if (dataConfig.severity === "success") {
        this.confirmClassTitle = "p-text-success";
      } else if (dataConfig.severity === "error") {
        this.confirmClassTitle = "p-error";
      }
      this.confirmTitle = dataConfig.title;
      this.confirmContent = dataConfig.content;
      this.showConfirmDialog = true;
    },
    closeAlertDialog() {
      this.showConfirmDialog = false;
    },
    initiateData() {
      this.isParamsReportType1 = true;
      this.selectReportType = "1";
      this.selectedInvoiceDate = "";
      this.selectedCustomerType = "owner";
      this.customerGroups = [];
      this.selectedCustomerGroup = "";
      this.selectedSearchBy = "doc_code";
      this.selectedSortBy = "doc_code";
      this.documentCodeStarts = [];
      this.selectedDocumentCodeStart = "";
      this.documentCodeEnds = [];
      this.selectedDocumentCodeEnd = "";
      this.unitCodeStarts = [];
      this.selectedUnitCodeStart = "";
      this.unitCodeEnds = [];
      this.selectedUnitCodeEnd = "";
      this.isSearchByDocumentCode = true;
      this.selectedIsIgnoreZero = true;
      this.selectedIsPrintCombineUnit = true;
      this.selectedIsIgnoreBillZero = true;
      this.classDropdownDateAndSort = "lg:col-6 md:col-6 col-12 pt-2";
    },
    async setData() {
      this.initiateData();
      this.isDisablePrintButton = true;
      this.isInvoiceDateLoading = true;
      this.isCustomerGroupLoading = true;
      this.isDocumentCodeStartLoading = true;
      this.isDocumentCodeEndLoading = true;
      this.isUnitCodeStartLoading = true;
      this.isUnitCodeEndLoading = true;
      this.isCustomerCodeStartLoading = true;
      this.isCustomerCodeEndLoading = true;
      await this.getCustomerGroups();
      await this.getInvoiceDateForPrintLots();
      await this.getInvoiceDateForCombineUnits();
      await this.getInvoiceDataByAtDates();
      if (!this.$store.getters["arInvoice/invoiceDateForPrintLotIsLastPage"]) {
        this.getAllInvoiceDateForPrintLots();
      }
      if (!this.$store.getters["arInvoice/invoiceDateForCombineUnitIsLastPage"]) {
        this.getAllInvoiceDateForCombineUnits();
      }
      this.validateParamsAndDisablePrintButton();
    },
    async getCustomerGroups() {
      this.customerGroups = [{ name: "ไม่ระบุ", code: "00-ไม่ระบุ" }];
      this.selectedCustomerGroup = "00-ไม่ระบุ";
      try {
        let where = {
          projectId: this.selectedProject.id,
        };
        const payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          where,
        };
        await this.$store.dispatch("shared/getAllCustomerGroups", payload);
        let customerGroupData = this.$store.getters["shared/customerCustomerGroups"];
        if (customerGroupData != null) {
          customerGroupData.forEach((data) => {
            let customerGroup = {
              name: data.name.trim(),
              code: data.code.trim(),
            };
            this.customerGroups.push(customerGroup);
          });
        }
        this.isCustomerGroupLoading = false;
      } catch (error) {
        this.isCustomerGroupLoading = false;
      }
    },
    async getInvoiceDateForPrintLots() {
      try {
        let where = {
          projectId: this.selectedProject.id,
        };
        const payload = {
          page: 1,
          limit: 100,
          where,
        };
        await this.$store.dispatch("arInvoice/getInvoiceDateForPrintLots", payload);
        if (this.invoiceDateForPrintLots.length === 0) {
          this.isInvoiceDateLoading = true;
        } else {
          if (this.selectedInvoiceDate === "" && this.selectReportType === "1") {
            this.selectedInvoiceDate = this.invoiceDateForPrintLots[0].code;
          }
          this.isInvoiceDateLoading = false;
        }
      } catch (error) {
        this.isInvoiceDateLoading = false;
      }
    },
    getAllInvoiceDateForPrintLots() {
      let where = {
        projectId: this.selectedProject.id,
      };
      const payload = {
        page: 1,
        limit: 100,
        where,
      };
      this.$store.dispatch("arInvoice/getAllInvoiceDateForPrintLots", payload);
    },
    async getInvoiceDateForCombineUnits() {
      try {
        let where = {
          projectId: this.selectedProject.id,
        };
        const payload = {
          page: 1,
          limit: 100,
          where,
        };
        await this.$store.dispatch("arInvoice/getInvoiceDateForCombineUnits", payload);
        if (this.invoiceDateForCombineUnits.length === 0) {
          // this.isInvoiceDateLoading = true;
        } else {
          if (this.selectedInvoiceDate === "" && this.selectReportType === "2") {
            this.selectedInvoiceDate = this.invoiceDateForCombineUnits[0].code;
          }
          this.isInvoiceDateLoading = false;
        }
      } catch (error) {
        this.isInvoiceDateLoading = false;
      }
    },
    getAllInvoiceDateForCombineUnits() {
      let where = {
        projectId: this.selectedProject.id,
      };
      const payload = {
        page: 1,
        limit: 100,
        where,
      };
      this.$store.dispatch("arInvoice/getAllInvoiceDateForCombineUnits", payload);
    },
    async getInvoiceDataByAtDates() {
      this.$store.dispatch("arInvoice/resetInvoiceDataByAtDate");
      try {
        let payload = {
          projectId: this.selectedProject.id,
          date: this.selectedInvoiceDate,
        };
        if (this.selectReportType === "2") {
          payload = { ...payload, isCombineInvoice: true };
        } else {
          payload = { ...payload, isCombineInvoice: false };
        } 
        await this.$store.dispatch("arInvoice/getAllInvoiceDataByAtDates", payload);
        let invoiceData = this.$store.getters["arInvoice/invoiceDataByAtDates"];
        // console.log('getInvoiceDataByAtDates.invoiceData=',invoiceData);
        if (this.selectReportType === "1") {
          this.setDocumentCodeAndUnitCode(invoiceData);
        } else {
          this.setCustomerCode(invoiceData);
        }
      } catch (error) {
        this.isInvoiceDateLoading = true;
      }
    },
    setSkeleton() {
      if (this.selectReportType === "1") {
        this.isDocumentCodeStartLoading = true;
        this.isDocumentCodeEndLoading = true;
        this.isUnitCodeStartLoading = true;
        this.isUnitCodeEndLoading = true;
      } else {
        this.isCustomerCodeStartLoading = true;
        this.isCustomerCodeEndLoading = true;
      }
    },
    setDocumentCodeAndUnitCode(invoiceData) {
      this.setSkeleton();
      this.documentCodeStarts = [];
      this.selectedDocumentCodeStart = "";
      this.documentCodeEnds = [];
      this.selectedDocumentCodeEnd = "";
      this.unitCodeStarts = [];
      this.selectedUnitCodeStart = "";
      this.unitCodeEnds = [];
      this.selectedUnitCodeEnd = "";
      try {
        if (invoiceData != null) {
          invoiceData.forEach((d) => {
            let documentCode = {
              id: d.id,
              code: d.code.trim(),
              name: d.code.trim(),
            };
            this.documentCodeStarts.push(documentCode);
            this.documentCodeEnds.push(documentCode);
            let unitCode = {
              id: d.id,
              code: d.unitCode.trim(),
              name: d.unitCode.trim() + " : " + d.customerName.trim(),
            };
            let found = this.unitCodeStarts.find((x) => x.code === unitCode.code);
            if (!found) {
              this.unitCodeStarts.push(unitCode);
              this.unitCodeEnds.push(unitCode);
            }
          });
          this.documentCodeStarts.sort((a, b) => a.code - b.code);
          if (this.selectedDocumentCodeStart == "") {
            this.selectedDocumentCodeStart = this.documentCodeStarts[0].code;
          }
          this.documentCodeEnds.sort((a, b) => (a.code < b.code ? 1 : -1));
          if (this.selectedDocumentCodeEnd == "") {
            this.selectedDocumentCodeEnd = this.documentCodeEnds[0].code;
          }
          this.unitCodeStarts.sort(
            (a, b) => a.code.localeCompare(b.code) || a.code - b.code
          );
          if (this.selectedUnitCodeStart == "") {
            this.selectedUnitCodeStart = this.unitCodeStarts[0].code;
          }
          this.unitCodeEnds.sort(
            (a, b) => b.code.localeCompare(a.code) || b.code - a.code
          );
          if (this.selectedUnitCodeEnd == "") {
            this.selectedUnitCodeEnd = this.unitCodeEnds[0].code;
          }
          this.isDocumentCodeStartLoading = false;
          this.isDocumentCodeEndLoading = false;
          this.isUnitCodeStartLoading = false;
          this.isUnitCodeEndLoading = false;
        }
      } catch (error) {
        this.isDocumentCodeStartLoading = true;
        this.isDocumentCodeEndLoading = true;
        this.isUnitCodeStartLoading = true;
        this.isUnitCodeEndLoading = true;
      }
    },
    setCustomerCode(invoiceData) {
      this.setSkeleton();
      this.customerCodeStarts = [];
      this.selectedCustomerCodeStart = "";
      this.customerCodeEnds = [];
      this.selectedCustomerCodeEnd = "";
      try {
        if (invoiceData != null) {
          invoiceData.forEach((d) => {
            let customerCode = {
              id: d.id,
              code: d.customerCode.trim(),
              name: d.customerCode.trim() + " : " + d.customerName.trim(),
            };
            let found = this.customerCodeStarts.find((x) => x.code === customerCode.code);
            if (!found) {
              this.customerCodeStarts.push(customerCode);
              this.customerCodeEnds.push(customerCode);
            }
          });
          this.customerCodeStarts.sort(
            (a, b) => a.code.localeCompare(b.code) || a.code - b.code
          );
          if (this.selectedCustomerCodeStart == "") {
            this.selectedCustomerCodeStart = this.customerCodeStarts[0].code;
          }
          this.customerCodeEnds.sort(
            (a, b) => b.code.localeCompare(a.code) || b.code - a.code
          );
          if (this.selectedCustomerCodeEnd == "") {
            this.selectedCustomerCodeEnd = this.customerCodeEnds[0].code;
          }
          this.isCustomerCodeStartLoading = false;
          this.isCustomerCodeEndLoading = false;
        }
      } catch (error) {
        this.isCustomerCodeStartLoading = true;
        this.isCustomerCodeEndLoading = true;
      }
    },
    async changeInvoiceDate() {
      this.isDisablePrintButton = true;
      this.setSkeleton();
      await this.getInvoiceDataByAtDates();
      this.validateParamsAndDisablePrintButton();
    },
    async changeReportType() {
      if (this.invoiceDateForCombineUnits.length === 0) { 
        this.selectReportType = "1";
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: this.lang.dataNotFound,
          isConfirm: false,
        });
        return;
      }
      this.isDisablePrintButton = true;
      this.isInvoiceDateLoading = true;
      this.setSkeleton();
      if (this.selectReportType == "1") {
        this.classDropdownDateAndSort = "lg:col-6 md:col-6 col-12 pt-2";
        this.isParamsReportType1 = true;
        // if (this.selectedSortBy === "doc_code") {
        //   this.isSearchByDocumentCode = true;
        // } else {
        //   this.isSearchByDocumentCode = false;
        // }
        if (this.invoiceDateForPrintLots.length === 0) {
          this.isInvoiceDateLoading = true;
        } else {
          this.selectedInvoiceDate = this.invoiceDateForPrintLots[0].code;
          this.isInvoiceDateLoading = false;
        }
      } else {
        this.classDropdownDateAndSort = "lg:col-12 md:col-12 col-12 pt-2";
        this.isParamsReportType1 = false;
        if (this.invoiceDateForCombineUnits.length === 0) {
          this.isInvoiceDateLoading = true;
        } else {
          this.selectedInvoiceDate = this.invoiceDateForCombineUnits[0].code;
          this.isInvoiceDateLoading = false;
        }
      }
      await this.getInvoiceDataByAtDates();
      this.validateParamsAndDisablePrintButton();
    },
    changeSearchBy() {
      if (this.selectedSearchBy == "doc_code") {
        this.isSearchByDocumentCode = true;
      } else {
        this.isSearchByDocumentCode = false;
      }
    },
    validateParamsAndDisablePrintButton() {
      this.isDisablePrintButton = true; // true = disable , false = enable
      if (
        this.isInvoiceDateLoading == false ||
        this.isCustomerGroupLoading == false ||
        this.isDocumentCodeStartLoading == false ||
        this.isDocumentCodeEndLoading == false ||
        this.isUnitCodeStartLoading == false ||
        this.isUnitCodeEndLoading == false ||
        this.selectedInvoiceDate == null ||
        this.selectedInvoiceDate == "" ||
        this.selectedDocumentCodeStart == null ||
        this.selectedDocumentCodeStart == "" ||
        this.selectedDocumentCodeEnd == null ||
        this.selectedDocumentCodeEnd == "" ||
        this.selectedUnitCodeStart == null ||
        this.selectedUnitCodeStart == "" ||
        this.selectedUnitCodeEnd == null ||
        this.selectedUnitCodeEnd == ""
      ) {
        this.isDisablePrintButton = false;
      }
    },
    validateData() {
      if (this.selectedInvoiceDate == null || this.selectedInvoiceDate == "") {
        this.dateStartError = true;
        return true;
      }
    },
    closeDialog() {
      this.$emit("close");
    },
    async confirm() {
      if (this.selectedInvoiceDate === "") {
        this.openDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateInvoiceDate,
          isConfirm: false,
        });
      } else {
        if (this.selectReportType === "1") {
          this.printLot();
        } else {
          this.printGroupUnit();
        }
      }
    },
    async printLot() {
      let fileName =
        "invoice_" +
        this.selectedDocumentCodeStart +
        "_" +
        this.selectedDocumentCodeEnd +
        ".pdf";
      if (this.selectedSearchBy != "doc_code") {
        fileName =
          "invoice_" +
          this.selectedUnitCodeStart +
          "_" +
          this.selectedUnitCodeEnd +
          ".pdf";
      }
      let payload = {
        fileName: fileName,
        jasperPath: "invoice/invoice_group_by_unit.pdf",
        event: "create",
        documentType: "Bill",
        customerType: this.selectedCustomerType,
        invoiceDateStart: this.selectedInvoiceDate,
        invoiceDateEnd: this.selectedInvoiceDate,
        projectCode: this.selectedProject.code,
        projectId: this.selectedProject.id,
        userId: this.userId,
        orderBy: this.selectedSearchBy === "doc_code" ? "doc_code" : "room_code",
        // orderBy: this.selectedSortBy,
        invoiceCodeStart:
          this.selectedSearchBy === "doc_code" ? this.selectedDocumentCodeStart : "",
        invoiceCodeEnd:
          this.selectedSearchBy === "doc_code" ? this.selectedDocumentCodeEnd : "",
        unitCodeStart:
          this.selectedSearchBy === "doc_code" ? "" : this.selectedUnitCodeStart,
        unitCodeEnd: this.selectedSearchBy === "doc_code" ? "" : this.selectedUnitCodeEnd,
        customerGroup:
          this.selectedCustomerGroup === "00-ไม่ระบุ" ? "" : this.selectedCustomerGroup.substring(0,2),
        isIgnoreZero: this.selectedIsIgnoreZero ? "N" : "Y",
        invoiceId: "",
        isExcludeMultiple: this.selectedIsPrintCombineUnit ? "N" : "Y",
        customerCode: "",
        isIgnoreBillZero: this.selectedIsIgnoreBillZero ? "N" : "Y",
        isForApi: "True",
      };
      this.$emit("confirm", payload);
      // this.setLoading(true);
      // try {
      //   await this.$store.dispatch("produces/addInvoicePrintOrderChanges", payload);
      //   this.$emit("confirm");
      // } catch (error) {
      //   this.openAlertDialog({
      // 		severity: "error",
      // 		title: this.lang.notificationDialogHeader,
      // 		content: error,
      // 		isConfirm: false,
      // 	});
      //   this.setLoading(false);
      // }
      // this.setLoading(false);
    },
    async printGroupUnit() {
      this.setLoading(true);
      try {
        let parameter = {
          DocumentBookId: this.documentBookId,
          DateAt: this.selectedInvoiceDate,
          UnitStart: this.selectedCustomerCodeStart,
          UnitEnd: this.selectedCustomerCodeEnd,
          IsForApi: "True",
        };
        let payload = {
          path: "invoice/invoice_group_by_unit.pdf",
          fileName: "InvoiceGroupByUnit",
          parameter: JSON.stringify(parameter),
        };
        await this.$store.dispatch("shared/printReport", payload);
        this.getPreviewBlob(this.fileBlob);
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.setLoading(false);
      }
      this.setLoading(false);
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userId() {
      return this.$store.getters.userId;
    },
    invoiceDateForPrintLots() {
      let result = [];
      let invoiceData = this.$store.getters["arInvoice/invoiceDateForPrintLots"];
      if (invoiceData != null) {
        invoiceData.forEach((data) => {
          let invoice = {
            name: this.formatDate(data.date),
            code: data.date.substring(0, 10),
          };
          result.push(invoice);
        });
      }
      return result;
    },
    invoiceDateForCombineUnits() {
      let result = [];
      let invoiceData = this.$store.getters["arInvoice/invoiceDateForCombineUnits"];
      if (invoiceData != null) {
        invoiceData.forEach((data) => {
          let invoice = {
            name: this.formatDate(data.date),
            code: data.date.substring(0, 10),
          };
          result.push(invoice);
        });
      }
      return result;
    },
    dataAlertButtons() {
      let dataButtons = [
        {
          id: "close",
          caption: this.lang.close,
          class: "w-full",
          action: "close",
        },
      ];
      return dataButtons;
    },
    fileBlob() {
      return this.$store.getters["shared/blob"];
    },
  },
  watch: {
    openDialog() {
      this.setData();
    },
  },
};
</script>
