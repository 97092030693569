export default({
    title:"Prepaid Refund",    
    titleList:"List Prepaid Refund ",
    columnDate: "Date",
    columnCode: "Code",
    columnUnit: "Unit No.",
    columnAddress:"Addr No.",
    columnCustomerName: "Customer Name",
    columnPrice:"Price",
    columnIsPaid:"Paid",
    columnIsCancel:"Canceled",
    columnPostStep:"Printed/Debited",
    columnPostEdit:"Edit",
    
    CreateNew: "CreateNew",
    SearchBy: "SearchBy",
    UnitCode:"Unit Code",
    DateAt:"Document Date",
    CustomerName:"Customer Name",
    DocumentCode:"Document Code",
    customerSearch: "Customer Code or Name",

    columnCodeReceipt: "Code Receipt",
    titleReceiptList:"List Prepaid Received",
    columnPeriod: "Period",
    columnBalance: "Balance",
    columnProductCode: "Code",
    columnProductName: "Name",
    columnQuantity: "Quantity",
    columnTotal: "Refund",
    employeeName:"Employee Name",
    columnRemark: "Remark",
    ReceiveMan: "Receive To",
    Remark: "Reason",
    
    createPrepaid:"Create Prepaid Refund",
    editPrepaid:"Edit Prepaid Refund",
    
    Create: "Create",
    Add:"Add",
    Edit: "Edit",
    Print: "Print",
    Cancel: "Cancel",
    
    searchReceived:"Prepaid Received",
    
    askForSaveData: "Save Data Succeed",

    checkRefundAmount: "The refund amount must be less than or equal to the balance amount.",
    validateNoDetail: "Please select item",
    validateNoRemark: "Please specify the reason for prepaid refund.",
    downloadPDFSuccess: "Download Successfully",
    
})