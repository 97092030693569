<template>
  <PvDialog
    :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
    :style="{ width: '40vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-right pt-2">
        <label class="font-lg">{{ lang.addEditItem }}</label>
        <PvButton
          icon="pi pi-times"
          class="p-button-text p-icon-secondary pb-0 pt-0"
          style="width: 15px"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
          <label for="product">{{ lang.product }}</label>
          <PvSkeleton
            v-if="!isSharedProductReady"
            class="inputfield w-full mt-1"
            height="39px"
          />
          <base-product-ar-auto-complete
            id="product"
            v-model="selectedProduct"
            class="inputfield w-full mt-1 mr-2"
            inputClass="p-inputtext-disabled"
            @load-finish="productDataReady"
            v-show="isSharedProductReady"
            disabled
          />
        </div>
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
          <label for="remark">{{ lang.description }}</label>
          <PvInputText
            id="remark"
            type="text"
            class="inputfield w-full mt-1"
            v-model="remark"
          />
        </div>
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
          <label for="period">{{ lang.period }}</label>
          <PvInputText
            id="period"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="period"
            disabled
          />
        </div>
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
          <label for="unitPrice">{{ lang.columnNetAmount }}</label>
          <PvInputNumber
            id="unitPrice"
            class="inputfield w-full mt-1"
            inputClass="p-inputtext-disabled text-right w-full"
            v-model="unitPrice"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            disabled
          />
        </div>
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
          <label for="billAmount">{{ lang.balance }}</label>
          <PvInputNumber
            id="billAmount"
            class="inputfield w-full mt-1"
            inputClass="p-inputtext-disabled text-right w-full"
            v-model="billAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            disabled
          />
        </div>
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
          <label for="refund">{{ lang.refund }}</label>
          <PvInputNumber
            id="totalAmount"
            class="inputfield w-full mt-1"
            inputClass="text-right w-full"
            v-model="totalAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            :min="0"
            @input="totalAmount = $event.value"
          />
        </div>
      </section>
    </transition>
      
    <template #footer>
      <div class="flex justify-content-between align-items-center m-3 mt-0">
        <PvButton
          :label="lang.close"
          class="p-button-outlined p-button-secondary w-full"
          @click="closeDialog"
        />
        <PvButton 
          :label="lang.confirm" 
          class="w-full" 
          @click="confirm"
          :disabled="isLockConfirm"
        />
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  props: {
    data: {
      type: Object,
    },
    isOpen: {
      type: Boolean,
    },
    isDocumentLocked: {
      type: Boolean,
    }
  },
  emits: ["close", "product-selected"],
  setup() {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const { formatCurrency, formatPeriodToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.refundGuarantee.listing,
          ...lang.th.ar.refundGuarantee.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.th.ar.refundGuarantee.listing,
          ...lang.en.ar.refundGuarantee.edit,
        };
      }
    });

    return {
      lang: mergeLang,
      textFormClass,
      formatPeriodToISOString,
      formatCurrency,
    };
  },
  data() {
    return {
      selectedProduct: null,
      isSharedProductReady: false,
      remark: "",
      period: "",
      totalAmount: 0,
      billAmount: 0,
      unitPrice: 0,
    };
  },
  mounted() {
    this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
    },
    initiateData() {
      this.selectedProduct = null;
      this.remark = "";
      this.period = "";
      this.totalAmount = 0;
      this.billAmount = 0;
      this.unitPrice = 0;
    },
    async checkShouldSetData() {
      this.setLoading(true);
      if(this.data && this.isSharedProductReady) {
        await this.setData();
        await this.setDataProduct();
      } else if(this.data && !this.isSharedProductReady) {
        await this.setData();
      } else if(!this.data && this.isSharedProductReady) {
        this.setLoading(false);
      }
    },
    async setData() {
      // console.log("set data" , this.data);
      this.remark = this.data.remark;
      this.period = this.data.period;
      this.unitPrice = this.data.unitPrice;
      this.billAmount = this.data.billAmount;
      this.totalAmount = this.data.totalAmount;
      this.setLoading(false);
    },
    async setDataProduct() {
      this.selectedProduct = this.sharedProducts.find(
        (product) => product.id === this.data.productId
      );
    },
    confirm() {
      this.$emit("product-selected", this.payload);
      this.closeDialog();
    },
    productDataReady() {
      this.isSharedProductReady = true;
    },
  },
  computed: {
    payload() {
      let payload = {
        id: this.data ? this.data.id : null,
        headerId: this.data ? this.data.headerId : null,
        productId: this.selectedProduct.id,
        remark: this.remark,
        period: this.period,
        unitPrice: this.unitPrice,
        quantity: this.data.quantity,
        billAmount: this.billAmount,
        totalAmount: this.totalAmount,
        discountDescription: this.data.discountDescription,
        discountAmount: this.data.discountAmount,
        referenceId: this.data.referenceId,
      }
      return payload;
    },
    sharedProducts() {
      return this.$store.getters["shared/productArProducts"];
    },
    isLockConfirm() {
      if (this.isDocumentLocked) {
        return true;
      } else if (this.totalAmount <= 0) {
        return true;
      } else if (this.totalAmount > this.billAmount) {
        return true;
      } 
      return false;
    },
  },
  watch: {
    isSharedProductReady() {
      this.checkShouldSetData();
    },
    isOpen(curValue) {
      if(curValue) {
        this.initiateData();
        this.checkShouldSetData();
      }
    },
  },
};
</script>
