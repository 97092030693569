<template>
  <base-toolbar :title="lang.listValidation" @goBack="goBack" />
  <base-container>
    <!-- <base-dialog
      v-model:visible="showBaseDialog"
      :classTitle="classTitleBaseDialog"
      :titleConfirm="titleHeaderBaseDialog"
      :wordingConfirm="contentBaseDialog"
      :dataButtons="dataButtonDialog"
      @close="showBaseDialog = false"
      @confirm-create-receipt="createReceipt"
      @confirm-create-auto="createAuto"
      @upload="uploadBillPayment"
    ></base-dialog> -->

    <base-progress-bar
      v-model:visible="showProgressBar"
      :title="lang.creatingReceipt"
      :textProgressBar="lang.generating"
      :valueObj="valueObj"
      :progressBar="`Template`"
    ></base-progress-bar>

    <import-bill-payment-dialog
      v-model:visible="showUploadBillPaymentDialog"
      @close="showUploadBillPaymentDialog = false"
      @data-upload="uploadedBillPayment"
    ></import-bill-payment-dialog>

    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <div class="flex flex-wrap justify-content-between align-items-center">
        <div class="flex-wrap lg:col-8 md:col-12 col-12">
          <p class="font-lg font-normal mb-2 mt-0">{{ title }}</p>
          <label class="font-xs" v-if="totalBillPayments > 0">
            <span class="font-normal">{{ wordingTitleFirst }}</span>
            <span class="font-bold">{{ wordingTitleSecond }}</span>
            <span class="font-normal">{{ lang.wordingTitleThird }}</span>
          </label>
          <p
            class="font-xs mb-2 mt-1 p-text-disabled"
            v-if="totalBillPayments > 0"
          >
            {{ lang.noteUnderTitle }}
          </p>
        </div>
        <div class="lg:col-4 md:col-12 col-12 pt-0 text-right">
          <PvButton
            class="p-button-outlined ml-3 mt-1"
            @click="askForUploadBillPayment"
          >
            <icon-cloud-storage-upload
              iconColor="primary"
              width="16"
              height="16"
            />
            <span class="p-button-label font-normal ml-1">{{
              lang.title
            }}</span>
          </PvButton>
          <PvButton class="ml-3 mt-1" @click="askForCreateReceiptAuto">
            <icon-documents-file-checkmark
              iconColor="white"
              width="16"
              height="16"
            />
            <span class="p-button-label font-normal ml-1">{{
              wordingImportAll
            }}</span>
          </PvButton>
        </div>
      </div>
      <PvDivider class="mt-3"></PvDivider>

      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >
        <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            id="filterSearch"
            class="inputfield w-full mr-2"
            :options="filterSearch"
            optionLabel="name"
            optionValue="code"
            v-model="selectedFilterSearch"
            scrollHeight="300px"
          />
        </div>
        <PvButton @click="filterBillPayment">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="filteredBillPayments"
          :paginator="true"
          class="p-datatable-sm"
          :class="textTableClass"
          :rows="rowsPerPage"
          dataKey="id"
          :rowHover="true"
          v-model:selection="selectedBillPayment"
          selectionMode="single"
          :loading="isLoading"
          paginatorTemplate="CurrentPageReport    FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 50, 100]"
          :currentPageReportTemplate="
            lang.page + ` {currentPage} / {totalPages}`
          "
          :globalFilterFields="[
            'name',
            'country.name',
            'representative.name',
            'status',
          ]"
          responsiveLayout="scroll"
        >
          <PvColumn
            field="status"
            :header="lang.status"
            style="min-width: 9rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <base-tag
                :Caption="setBaseTag(slotProps.data.status).caption"
                :Class="setBaseTag(slotProps.data.status).class"
              />
            </template>
          </PvColumn>
          <PvColumn
            :header="lang.reason"
            style="min-width: 9rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              {{ setReason(slotProps.data) }}
            </template>
          </PvColumn>
          <PvColumn
            field="unitCode"
            :header="lang.unit"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="ref1"
            :header="lang.ref1"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="ref2"
            :header="lang.ref2"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="totalAmount"
            :header="lang.amount"
            style="min-width: 6rem"
            headerClass="h-right"
            bodyClass="text-right"
          >
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.totalAmount) }}
            </template>
          </PvColumn>
          <PvColumn
            field="paidBy"
            :header="lang.paidBy"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="payDateTime"
            :header="lang.paymentDate"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
          >
            <template #body="slotProps">
              <span class="text-left w-full">
                {{ slotProps.data.payDateTime === "-"? 
                  slotProps.data.payDateTime :
                  formatDateTime(slotProps.data.payDateTime) 
                }}</span>
            </template>
          </PvColumn>

          <PvColumn
            headerClass="h-left"
            bodyClass="text-left"
            style="min-width: 8rem"
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button p-button-primary py-2"
                @click="askForCreateReceipt(slotProps.data)"
                v-if="slotProps.data.status.toLowerCase() == 'wait'"
              >
                <icon-plus-add iconColor="white" width="16" height="16" />
                <span class="font-normal pl-2">{{ lang.autoCreate }}</span>
              </PvButton>

              <PvButton
                type="button"
                class="p-button-outlined py-2"
                @click="openReceipt(slotProps.data.receiptId)"
                v-else-if="slotProps.data.status.toLowerCase() == 'success'"
              >
                <span class="font-normal pl-0">{{ lang.openReceipt }}</span>
              </PvButton>

              <PvButton
                type="button"
                class="p-button-outlined py-2"
                @click="createReceiptManual(slotProps.data)"
                v-else-if="slotProps.data.status.toLowerCase() == 'invalid'"
              >
                <icon-plus-add iconColor="primary" width="16" height="16" />
                <span class="font-normal pl-2">{{ lang.manualCreate }}</span>
              </PvButton>

              <PvButton
                type="button"
                class="p-button-outlined py-2"
                @click="askForCreateReceipt(slotProps.data)"
                v-else-if="slotProps.data.status.toLowerCase() == 'error'"
              >
                <icon-refresh-loading
                  iconColor="primary"
                  width="16"
                  height="16"
                />
                <span class="font-normal pl-2">{{ lang.retry }}</span>
              </PvButton>
            </template>
          </PvColumn>

          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ lang.tableEmptyList }}</label>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, onMounted, ref, onBeforeUnmount } from "vue";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import ImportBillPaymentDialog from "../../../components/AR/receipt/ImportBillPaymentDialog.vue";
import BaseDialog from "../../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
export default {
  components: {
    ImportBillPaymentDialog,
  },
  props: ["projectCode"],
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateTime } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.receipt.listing,
          ...lang.th.ar.receipt.billPayment,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.receipt.listing,
          ...lang.en.ar.receipt.billPayment,
        };
      }
    });
    const router = useRouter();

    //ImportDialog
    let showUploadBillPaymentDialog = ref(false);
    //Dialog
    let dataConfigDialog = ref(null);
    //Search
    const filterSearch = ref([
      { name: mergeLang.value.statusAll, code: "all" },
      { name: mergeLang.value.wait, code: "wait" },
      { name: mergeLang.value.success, code: "success" },
      { name: mergeLang.value.invalid, code: "invalid" },
      { name: mergeLang.value.error, code: "error" },
    ]);
    let selectedFilterSearch = ref("all");
    //Table
    let isLoading = ref(true);
    let selectedPage = ref(1);
    let rowsPerPage = ref(50);
    let selectedBillPayment = ref(null);
    let filteredBillPayment = ref([]);
    let isFilter = ref(false);
    //ProgressBar
    let showProgressBar = ref(false);
    let valueObj = ref([]);

    onMounted(() => {
      isLoading.value = false;
      window.addEventListener("beforeunload", handleBeforeUnload);
    });

    const billPayments = computed(() => {
      return store.getters["arReceiptBillPayment/billPayments"];
    });

    const totalBillPayments = computed(() => {
      return billPayments.value ? billPayments.value.length : 0;
    });

    const totalAutoImport = computed(() => {
      return billPaymentsCreateAuto.value
        ? billPaymentsCreateAuto.value.length
        : 0;
    });

    const title = computed(() => {
      return mergeLang.value.title + " (" + totalBillPayments.value + ")";
    });

    const wordingTitleFirst = computed(() => {
      return mergeLang.value.wordingTitleFirst.replace(
        ":total",
        totalBillPayments.value
      );
    });

    const wordingTitleSecond = computed(() => {
      return mergeLang.value.wordingTitleSecond.replace(
        ":value",
        totalAutoImport.value
      );
    });

    const wordingImportAll = computed(() => {
      return mergeLang.value.importAll.replace(":value", totalAutoImport.value);
    });

    const filteredBillPayments = computed(() => {
      if (!isFilter.value) {
        return billPayments.value;
      } else {
        return filteredBillPayment.value;
      }
    });

    const receipt = computed(() => {
      return store.getters["arReceipt/selectedReceipt"];
    });

    const billPaymentsCreateAuto = computed(() => {
      if (billPayments.value) {
        return billPayments.value.filter(
          (detail) => detail.status.toLowerCase() === "wait"
        );
      }
      return [];
    });

    const goBack = () => {
      router.push({ name: "ar-receipt" });
    };

    const initiateData = async () => {
      showUploadBillPaymentDialog.value = false;
      dataConfigDialog.value = null;

      selectedFilterSearch.value = "all";

      selectedPage.value = 1;
      rowsPerPage.value = 50;
      selectedBillPayment.value = null;
      filteredBillPayment.value = [];
      isFilter.value = false;
      showProgressBar.value = false;
      valueObj.value = [];
    };

    const askForUploadBillPayment = async () => {
      if (totalBillPayments.value > 0) {
        await setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: [
            mergeLang.value.askForNewImport,
            mergeLang.value.wordingNewImport,
          ],
          type: "confirm",
        });
        const dialogResponse = await createConfirmDialog(
          BaseDialog,
          dataConfigDialog.value
        ).reveal();
        if (dialogResponse.isCanceled) {
          return;
        }
      }
      uploadBillPayment();
    };

    const uploadBillPayment = () => {
      showUploadBillPaymentDialog.value = true;
    };

    const uploadedBillPayment = () => {
      showUploadBillPaymentDialog.value = false;
    };

    const filterBillPayment = () => {
      if (selectedFilterSearch.value === "all") {
        filteredBillPayment.value = [...billPayments.value];
        isFilter.value = false;
      } else {
        filteredBillPayment.value = billPayments.value.filter((data) => {
          return data.status.toLowerCase().includes(selectedFilterSearch.value);
        });
        isFilter.value = true;
      }
      selectedBillPayment.value = null;
    };

    const setBaseTag = (status) => {
      let result = {
        caption: "",
        class: "",
      };
      if (status.toLowerCase() === "wait") {
        result.caption = mergeLang.value.wait;
        result.class = "warning";
      } else if (status.toLowerCase() === "success") {
        result.caption = mergeLang.value.success;
        result.class = "success";
      } else if (status.toLowerCase() === "invalid") {
        result.caption = mergeLang.value.invalid;
        result.class = "secondary";
      } else if (status.toLowerCase() === "error") {
        result.caption = mergeLang.value.error;
        result.class = "error";
      }

      return result;
    };

    const setReason = (data) => {
      let result = data.reason;
      if (data.reason.toLowerCase() === "unit") {
        result = mergeLang.value.invalidUnit;
      } else if (data.reason.toLowerCase() === "invoice") {
        result = mergeLang.value.invalidInvoice;
      } else if (data.reason.toLowerCase() === "amount") {
        result = mergeLang.value.invalidAmount;
      } else if (data.reason.toLowerCase() === "date") {
        result = mergeLang.value.invalidDate;
      }

      return result;
    };

    const setDialog = async (dataConfig) => {
      if (dataConfig.classTitle === "") {
        dataConfig.classTitle = "";
      } else if (dataConfig.classTitle === "success") {
        dataConfig.classTitle = "p-text-success";
      } else if (dataConfig.classTitle === "error") {
        dataConfig.classTitle = "p-error";
      }

      dataConfig.button = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "cancel",
        },
      ];
      if (dataConfig.type === "confirm") {
        dataConfig.button = [
          {
            id: "close",
            caption: mergeLang.value.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "cancel",
          },
          {
            id: "confirm",
            caption: mergeLang.value.buttonDialogConfirm,
            class: "w-full",
            action: "confirm",
          },
        ];
      }

      dataConfigDialog.value = {
        titleConfirm: dataConfig.title,
        classTitle: dataConfig.classTitle,
        wordingConfirm: dataConfig.message,
        dataButtons: dataConfig.button,
        display: true,
      };
    };

    const askForCreateReceiptAuto = async () => {
      valueObj.value = [];
      valueObj.value = billPaymentsCreateAuto.value;
      let wording = mergeLang.value.wordingCreateReceiptAuto
        .replace(":total", totalBillPayments.value)
        .replace(":value", totalAutoImport.value);
      let type = "confirm";
      let content = [wording, mergeLang.value.askForNextStep];
      if (totalAutoImport.value <= 0) {
        wording = mergeLang.value.wordingCannotCreateReceiptAuto.replace(
          ":total",
          totalBillPayments.value
        );
        type = "";
        content = wording;
      }
      await setDialog({
        classTitle: "",
        title: mergeLang.value.notificationDialogHeader,
        message: content,
        type: type,
      });
      const dialogResponse = await createConfirmDialog(
        BaseDialog,
        dataConfigDialog.value
      ).reveal();
      if (!dialogResponse.isCanceled) {
        createAuto();
      }
    };

    const createReceiptManual = (data) => {
      let billPayment = {
        uniqueKeyId: data.receiptUniqueKeyId,
        unitId: data.unitId,
        ref2: data.ref2,
        amount: data.totalAmount,
        payDate: data.payDate,
        bookBankNumber: data.bookBankNumber,
      };
      const routeData = router.resolve({
        name: "ar-receipt-edit",
        params: { id: "create" },
        query: billPayment,
      });
      window.open(routeData.href, "_blank");
    };

    const askForCreateReceipt = async (data) => {
      selectedBillPayment.value = data;
      valueObj.value = [];
      valueObj.value.push(selectedBillPayment.value);

      await setDialog({
        classTitle: "",
        title: mergeLang.value.notificationDialogHeader,
        message: mergeLang.value.askForNextStep,
        type: "confirm",
      });
      const dialogResponse = await createConfirmDialog(
        BaseDialog,
        dataConfigDialog.value
      ).reveal();
      if (!dialogResponse.isCanceled) {
        createReceipt();
      }
    };

    const createReceipt = async () => {
      showProgressBar.value = true;
      let isSuccess = await addReceipt(selectedBillPayment.value);

      showProgressBar.value = false;

      if (isSuccess) {
        await setDialog({
          classTitle: "success",
          title: mergeLang.value.saveSuccess,
          message: mergeLang.value.createReceiptSuccess,
          type: "",
        });
        await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
      }
      return;
    };

    const addReceipt = async (data) => {
      try {
        let payload = {
          ...data,
          type: "Add",
        };
        await store.dispatch("arReceipt/createOrUpdateReceipt", payload);
        if (receipt.value) {
          data.receiptId = receipt.value.id;
          data.status = "success";
        }
        return true;
      } catch (error) {
        data.status = "error";
        return false;
      }
    };

    const createAuto = async () => {
      showProgressBar.value = true;
      for (const data of billPaymentsCreateAuto.value) {
        let isSuccess = await addReceipt(data);

        if (!isSuccess) {
          return;
        }
      }
      showProgressBar.value = false;

      await setDialog({
        classTitle: "success",
        title: mergeLang.value.saveSuccess,
        message: mergeLang.value.createReceiptSuccess,
        type: "",
      });
      await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
    };

    const openReceipt = (receiptId) => {
      const routeData = router.resolve({
        name: "ar-receipt-edit",
        params: { id: receiptId },
      });
      window.open(routeData.href, "_blank");
    };

    onBeforeRouteLeave(async (to, from) => {
      if (totalBillPayments.value > 0) {
        var curValue = to.params.projectCode;
        var oldValue = from.params.projectCode;
        if (curValue == oldValue) {
          await setDialog({
            classTitle: "",
            title: mergeLang.value.notificationDialogHeader,
            message: [
              mergeLang.value.wordingDiscard,
              mergeLang.value.askForDiscard,
            ],
            type: "confirm",
          });
          const dialogResponse = await createConfirmDialog(
            BaseDialog,
            dataConfigDialog.value
          ).reveal();
          return !dialogResponse.isCanceled;
        }
      }
      return true;
    });

    onBeforeUnmount(() => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    });

    const handleBeforeUnload = async (event) => {
      if (totalBillPayments.value > 0) {
        event.preventDefault();
      }
    };

    return {
      lang: mergeLang,

      textTableClass,
      textFormClass,

      formatCurrency,
      formatDate,
      formatDateTime,

      showUploadBillPaymentDialog,
      filterSearch,
      selectedFilterSearch,
      isLoading,
      selectedPage,
      rowsPerPage,
      selectedBillPayment,
      filteredBillPayment,
      isFilter,
      showProgressBar,
      valueObj,

      billPayments,
      totalBillPayments,
      totalAutoImport,
      title,
      wordingTitleFirst,
      wordingTitleSecond,
      wordingImportAll,
      filteredBillPayments,
      receipt,
      billPaymentsCreateAuto,

      goBack,
      initiateData,
      askForUploadBillPayment,
      uploadBillPayment,
      uploadedBillPayment,
      filterBillPayment,
      setBaseTag,
      setReason,
      setDialog,
      askForCreateReceiptAuto,
      createReceiptManual,
      askForCreateReceipt,
      createReceipt,
      addReceipt,
      createAuto,
      openReceipt,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-right: auto;
  }
}
</style>
