export default {
	employeeEmployees(state) {
		return state.employee.employees;
	},
	employeeCurrentPage(state) {
		return state.employee.currentPage;
	},
	employeeNextPage(state) {
		return state.employee.currentPage + 1;
	},
	employeeTotalPage(state) {
		return state.employee.totalPage;
	},
	employeeIsLastPage(_, getters) {
		return (
			getters.employeeCurrentPage === getters.employeeTotalPage && getters.employeeTotalPage !== 0
		);
	},
	employeesByUserIds(state) {
		return state.employee.employeesByUserIds;
	},
};
