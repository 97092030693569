<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
    :header="lang.titleWithholdingTaxAttachmentPnd"
  >
  
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ this.lang.titleWithholdingTaxAttachmentPnd }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <section class="flex flex-wrap" :class="textFormClass">
      <div class="lg:col-12 md:col-6 col-12 pl-0 pr-2">
					<label for="taxTypes">{{ lang.taxType }}</label>
					<PvDropdown
            class="inputfield w-full mt-1"
            :options="taxTypes"
            optionLabel="codeAndName"
            v-model="selectedTaxType"
            :class="{ 'p-invalid': taxTypeError }"
            @change="selectTaxType()"
            />
            <small v-if="taxTypeError" id="unit-help" class="p-error">{{
              lang.validateNoTaxTypeSelect
            }}</small>
			</div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="dueDateStart">{{ lang.taxDueDateStart }}</label>
        <PvCalendar
          id="dueDateStart"
          v-model="dueDateStart"
          class="inputfield w-full mt-1"
          showIcon
          dateFormat="dd/mm/yy"
          :class="{ 'p-invalid': dueDateStartError }"
          @date-select="dueDateStartError = false"
          />
          <small v-if="dueDateStartError" id="date-help" class="p-error">{{
            lang.validateTaxDueDateSelect
          }}</small>
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="dueDateEnd">{{ lang.taxDueDateEnd }}</label>
        <PvCalendar
          id="dueDateEnd"
          v-model="dueDateEnd"
          class="inputfield w-full mt-1"
          showIcon
          dateFormat="dd/mm/yy"
          :class="{ 'p-invalid': dueDateEndError }"
          @date-select="dueDateEndError = false"
          />
          <small v-if="dueDateEndError" id="date-help" class="p-error">{{
            lang.validateTaxDueDateSelect
          }}</small>
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="position">{{ lang.position }}</label>
        <PvInputText
          id="position"
          type="text"
          class="inputfield w-full mt-1"
          v-model="position"
        />
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="submissionDate">{{ lang.submissionDate }}</label>
        <PvCalendar
          id="submissionDate"
          v-model="submissionDate"
          class="inputfield w-full mt-1"
          showIcon
          dateFormat="dd/mm/yy"
          />
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="paperType">{{ lang.reportPortraitOrLandscape }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="paperType"
            optionLabel="name"
            optionValue="code"
            v-model="selectPaperType"
            @change="setFileName()"
          />
        </div>
         
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="fileNameText">{{ lang.fileName }}</label>
        <PvInputText
          id="fileNameText"
          type="text"
          class="inputfield w-full mt-1"
          v-model="fileNameText"
        />
      </div>
    </section>
    
    
    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-6 md:col-6 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
        </div>
        <!-- <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
        </div> -->
        <div class="lg:col-6 md:col-6 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
        </div>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetFormPrint from "../../hooks/getFormPrint.js";
import useTracking from "../../hooks/tracking";
import useReportRequestValidate from "../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { getPreviewBlob } = useGetFormPrint();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.PettyCash,
          ...lang.th.gl.accounting.listing,
          ...lang.th.gl.accounting.edit,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.PettyCash,
          ...lang.en.gl.accounting.listing,
          ...lang.en.gl.accounting.edit,
          ...lang.en.gl.reportGl,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getPreviewBlob,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      selectedTaxType: null,
      taxTypeError: false,
      dueDateStart: null,
      dueDateEnd: null,
      dueDateStartError: null,
      dueDateEndError: null, 
      position: "",
      submissionDate: null,
      paperType: [
        { name: this.lang.portraitPage, code: "Portrait" },
        { name: this.lang.landscapePage, code: "Landscape" },
      ],
      selectPaperType: "Portrait",
      type: "PDF",
      fileNameText: "WithholdingTaxAttachmentPnd_Portrait",
    };
  },
  created() {
    this.setLoading(true);
    this.getTaxType();
    let date = new Date();
    this.dueDateStart = new Date(date.getFullYear(), date.getMonth(), 1);
    this.dueDateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const localSearch = localStorage.getItem("erp_search_withholdingTaxReport");
		if (localSearch) {
			const search = JSON.parse(localSearch);
				this.position = search.position || this.position;
		}

    // localStorage.setItem("erp_search_withholdingTaxReport", JSON.stringify(where));
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
      this.taxTypeError = false;
      this.dueDateStartError = false;
      this.dueDateEndError = false;
    },
    async getTaxType() {
			let where = {
				projectId: this.selectedProject.id,
				taxType: "WHT",
			};
			const payload = {
				page: 1,
				limit: 100,
				where,
			};
			await this.$store.dispatch("shared/getTaxTypes", payload);

      this.setLoading(false);
		},
    validateData() {
      if (!this.selectedTaxType) {
				this.$swal({
					icon: "warning",
					title: this.lang.validateNoTaxTypeSelect,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				}).then((result) => {
					/* Read more about isConfirmed, isDenied below */
					if (result.isConfirmed) {
						this.taxTypeError = true;
					}
				});
				return true;
			}

      if (this.selectedDueDateStart == null || this.selectedDueDateEnd == null) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateTaxDueDateSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            if (this.selectedDueDateStart == null)this.dueDateStartError = true;
            if (this.selectedDueDateEnd == null)this.dueDateEndError = true;
          }
        });
        return true;
      }
      return false;
    },
    async download(typeReport) {
      this.type = typeReport;      
      this.setLoading(true);
      if (this.validateData()) {
        this.setLoading(false);
        return;
      }
      try {    
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name); 
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
        this.setLoading(false);
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        this.setLoading(false);
      }
    },
    selectTaxType(){ 
      this.taxTypeError = false;
      this.setFileName();
    },
    setFileName(){   
      var paperType = "_Landscape";
      if(this.selectPaperType == "Portrait"){
        paperType = "_Portrait";
      }

      if(this.selectedTaxType && this.selectedTaxType.code == 'WT3'){
        this.fileNameText = "WithholdingTaxAttachmentPnd3" + paperType;
      } 
      else{
        this.fileNameText = "WithholdingTaxAttachmentPnd53" + paperType;
      }
    }
  },
  computed: {
    payload() {
      let savePosition = {
        position: this.position,
      };
      localStorage.setItem("erp_search_withholdingTaxReport", JSON.stringify(savePosition));

      let payload = {
        FileName: this.fileNameText + this.fileType,
        JasperPath: this.jasperPath + this.fileType,
        DocType: this.type,
        UserId: this.userId,
        PrintBy: this.userName,
          ProjectId: this.selectedProject.id,
          TaxTypeId: this.selectedTaxType.id,
          TaxTypeCode: this.selectedTaxType.code,
          DueDateStart: this.selectedDueDateStart,
          DueDateEnd: this.selectedDueDateEnd,
          Position: this.position,
          SubmissionDate : this.submissionDate? this.formatDate(this.submissionDate): "none",
          PaperType : this.selectPaperType,
        RequestId: 0,
        ReportName: "WithholdingTaxAttachmentPnd",
      };

      // let parameter = {
      //   ProjectId: this.selectedProject.id,
      //   TaxTypeId: this.selectedTaxType.id,
      //   TaxTypeCode: this.selectedTaxType.code,
      //   DueDateStart: this.selectedDueDateStart,
      //   DueDateEnd: this.selectedDueDateEnd,
      //   Position: this.position,
      //   SubmissionDate : this.submissionDate? this.formatDate(this.submissionDate): "none",
      //   PaperType : this.selectPaperType,
      // };      
      // let payload = {
      //   DocType: this.type,
      //   Path: this.jasperPath + this.fileType,
      //   FileName: this.fileNameText,
      //   Parameter: JSON.stringify(parameter),
      // };

      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    selectedDueDateStart() {
      let dueDateStart = null;
      if (this.dueDateStart) {
        dueDateStart = this.formatDateToISOString(this.dueDateStart);
      }
      return dueDateStart;
    },
    selectedDueDateEnd() {
      let dueDateEnd = null;
      if (this.dueDateEnd) {
        dueDateEnd = this.formatDateToISOString(this.dueDateEnd);
      }
      return dueDateEnd;
    },
    taxTypes() {
			return this.$store.getters["shared/taxTypes"];
		},
    jasperPath() {      
      var paperType = "_landscape";
      if(this.selectPaperType == "Portrait"){
        paperType = "_portrait";
      }

      let result = this.reportObj.path + "53" + paperType;
      if (this.selectedTaxType.code == "WT3") {
        result = this.reportObj.path + "3" + paperType;
      }

      if(this.type == "EXCEL" ){
         result = this.reportObj.path + "_excel";             
      }

      return result;
    },    
    fileType() {
      let fileType = this.type == "PDF" ? ".pdf" : ".xlsx";
      return fileType;
    },
    fileBlob() {
      return this.$store.getters["shared/blob"];
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
  },

};
</script>
