<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoSave="save"
    @gotoApprove="askForApprove"
    @gotoCancel="askForCancel"
  ></base-toolbar>

  <base-dialog
    v-model:visible="showValidateDialog"
    :classTitle="baseDialogClassTitle"
    :titleConfirm="titleConfirm"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    @confirm="showValidateDialog = false"
    @close="closeDialog"
    @selected-account-close="selectedAccountClose"
    @selected-account-confirm="selectedAccountConfirm"
    @delete-account-confirm="deleteAccountConfirm"
    @approve-budget="approve"
    @clear-budget="clearDataBudget"
    @cancel-budget="cancelBudget"
    @average-budget="confirmAverageBudget"
    @active-budget="updateActiveBudget"
    :isVisibleCloseButton="true"
  >
    <template #dialogBody v-if="isApproveBudget">
      <PvInlineMessage
        severity="warn"
        class="w-full mt-3 justify-content-start"
        >{{ lang.validateApproveWording }}</PvInlineMessage
      >
    </template>
  </base-dialog>

  <create-update-budget-dialog
    v-model:visible="showCreateUpdateBudgetDialog"
    @close="closeCreateUpdateBudgetDialog"
    @confirm="confirmCreateUpdateBudget"
    :budgetHeader="budgetHeader"
    :key="keyToReLoadCreateUpdate"
    :isCreate="id"
  ></create-update-budget-dialog>

  <account-chart-select-dialog
    v-model:visible="showAccountChartSelectDialog"
    @close="showAccountChartSelectDialog = false"
    @confirm="selectedAccountChart"
    :selectedAccounts="selectedAccounts"
    :key="keyToReLoadAccountChart"
    :isCreate="true"
  >
  </account-chart-select-dialog>

  <average-amount-budget-dialog
    v-model:visible="showAverageAmountBudgetDialog"
    @close="showAverageAmountBudgetDialog = false"
    @confirm="averageAmountBudget"
    :period="periodDetails.length"
    :selectedBudget="selectedDetailAverage"
    :key="keyToReLoadAverageAmount"
  >
  </average-amount-budget-dialog>
  <preview-budget-dialog
    v-model:visible="showPreviewBudgetDialog"
    @close="showPreviewBudgetDialog = false"
    :budget="budgetHeader"
  >
  </preview-budget-dialog>

  <base-container>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <div class="flex flex-wrap justify-content-between align-items-center">
        <div>
          <label class="font-lg font-normal">{{ budgetName }}</label>
          <PvButton
            type="button"
            class="p-button-secondary p-button-text p-0 ml-2"
            @click="previewBudget()"
            v-if="isApproveStatus || isCancelStatus"
          >
            <icon-eye iconColor="secondary" width="24" height="24" />
          </PvButton>
          <PvButton
            type="button"
            class="p-button-secondary p-button-text p-0 ml-2"
            @click="editBudget()"
            v-else
          >
            <icon-pen-edit iconColor="secondary" width="24" height="24" />
          </PvButton>
          <span class="ml-2">
            <!-- <base-tag
              :Caption="lang.approved"
              Class="success"
              v-if="isApproveStatus && !isCancelStatus"
            /> -->
            <!-- <base-tag
              :Caption="lang.statusWait"
              Class="secondary"
              v-if="isWaitApproveStatus"
            /> -->
            <base-tag
              :Caption="lang.cancel"
              Class="error"
              v-if="isCancelStatus"
            />
          </span>
          <br />
          <label class="font-xs font-normal">{{ budgetRange }}</label>
        </div>
        <div class="flex align-items-center ml-auto mr-2">
          <PvInputSwitch v-model="isActiveBudget" class="mr-2" @change="validateUpdateActiveBudget()"/>
          <label for="status">{{ isActiveBudget ? lang.active : lang.inActive }}</label>
        </div>
        <div class="flex" v-if="isEditBudgetDetail">
          <PvButton class="p-button-outlined" @click="askForClearBudget">
            <icon-edit-clear iconColor="primary" width="16" height="16" />
            <span class="pl-2">{{ lang.clearDataBudget }}</span>
          </PvButton>
          <PvButton class="p-button-outlined ml-2" @click="settingAccountChart">
            <icon-setting iconColor="primary" width="16" height="16" />
            <span class="pl-2">{{ lang.settingBudget }}</span>
          </PvButton>
        </div>
      </div>

      <PvDivider></PvDivider>
      <PvMessage @close="closeMessage">
        <span class="font-sm font-semibold">{{ lang.advice }}</span>
        <span class="font-sm font-normal"> {{ " " }} {{ lang.tip }}</span>
      </PvMessage>

      <div class="lg:col-5 md:col-9 col-12 py-2 pl-0">
        <div class="surface-card flex justify-content-between">
          <span class="p-input-icon-left w-full">
            <i class="pi pi-search" />
            <PvInputText
              class="inputfield w-full"
              v-model="searchText"
              :placeholder="lang.keywordSearch"
              autofocus
            />
          </span>
        </div>
      </div>

      <div class="p-datatable-border my-2 w-full">
        <PvDataTable
          ref="budgetTable"
          :value="filteredAccount"
          dataKey="accountId"
          selectionMode="single"
          responsiveLayout="scroll"
          class="editable-cells-table font-xs"
          :class="textTableClass"
          :scrollable="true"
          :scrollHeight="halfViewportHeight + 'px'"
        >
          <PvColumn
            style="min-width: 2rem; max-width: 5rem; height: 45px"
            headerClass="h-left"
            bodyClass="text-left"
            frozen
            v-if="isEditBudgetDetail"
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text mt-1 p-1"
                v-if="slotProps.data.isDetail == 'Y'"
                @click="deleteBudgetDetail(slotProps.data)"
              >
                <icon-trash-delete-bin iconColor="error" />
              </PvButton>
            </template>
          </PvColumn>

          <PvColumn
            field="accountCode"
            :header="lang.columnAccountCode"
            style="min-width: 8rem; height: 45px"
            headerClass="h-left"
            bodyClass="text-left"
            frozen
          >
            <template #body="slotProps">
              <span
                class="text-left w-full"
                :class="{ 'font-semibold': slotProps.data.isDetail == 'N' }"
              >
                {{ slotProps.data.accountCode }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            field="accountName"
            :header="lang.columnAccountName"
            style="min-width: 20rem; height: 45px"
            headerClass="h-left"
            bodyClass="text-left"
            frozen
          >
            <template #body="slotProps">
              <div
                class="flex flex-wrap justify-content-between align-items-center w-full"
              >
                <div class="lg:col-10 md:col-10 col-10">
                  <span
                    class="text-left"
                    :class="{ 'font-semibold': slotProps.data.isDetail == 'N' }"
                  >
                    {{ slotProps.data.accountName }}
                  </span>
                </div>
                <div
                  class="lg:col-2 md:col-2 col-2"
                  v-if="isEditBudgetDetail && slotProps.data.isDetail == 'Y'"
                >
                  <PvButton
                    type="button"
                    class="p-button-danger p-button-text p-0"
                    @click="averageBudgetDetail(slotProps.data)"
                    v-if="slotProps.data.isDetail == 'Y'"
                    v-tooltip.right="{
                      value: lang.averageAmount,
                      class: 'custom-tooltip',
                    }"
                  >
                    <icon-average />
                  </PvButton>
                </div>
              </div>
            </template>
          </PvColumn>

          <PvColumn
            v-for="col of columnPeriods"
            :key="col.field"
            :id="col.field"
            :field="col.field"
            :header="col.columnName"
            style="min-width: 12rem; height: 45px"
            headerClass="h-left"
            bodyClass="text-right"
            bodyStyle="text-align: right; justify-content: right;align-items: right;"
          >
            <!-- <template #body="slotProps">
          <span class="text-right w-full"> {{ formatCurrency(slotProps.data[col.field]) }} </span> 
        </template> -->

            <template #body="{data, field, index}">
              <span
                class="text-right w-full font-semibold"
                v-if="data.isDetail == 'N'"
              >
                {{ formatCurrency(data[col.field]) }}
              </span>
              <span
                class="text-right w-full"
                v-if="data.isDetail == 'Y' && isEditBudgetDetail"
              >
                <PvInputNumber
                  style="text-align: right"
                  class="inputfield"
                  inputClass="text-right font-xs w-full py-1"
                  v-model.trim="data[col.field]"
                  mode="decimal"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  :min="0"
                  :max="maxInputNumber"
                  @input="data[col.field] = validateMaxInputNumber($event.value)"
                  @blur="updateBudgetDetail(data)"
                  @focus="$event.target.select()"
                  @keydown="buttonDown($event, index + 1)"
                />
              </span>
              <span
                class="text-right w-full"
                v-if="data.isDetail == 'Y' && !isEditBudgetDetail"
              >
                {{ formatCurrency(data[field]) }}
              </span>              
            </template>
          </PvColumn>

          <PvColumn
            field="totalAmount"
            :header="lang.columnTotal + ' ' + yearOf"
            style="min-width: 12rem; height: 45px"
            headerClass="h-center"
            bodyStyle="text-align: right; justify-content: right;align-items: right;"
            class="table-td-total-amount"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <span
                class="text-right w-full font-semibold"
              >
                {{ formatCurrency(slotProps.data.totalAmount) }}
              </span>
            </template>
          </PvColumn>

          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ tableEmpty }}</label>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed, ref, onMounted, watch } from "vue";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import useGetAccounting from "../../hooks/getAccounting.js";
import usePageDirty from "../../hooks/pageDirty";
import BaseDialog from "../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import useGetRoleByMenu from "../../hooks/getRoleByMenu.js";
import CreateUpdateBudgetDialog from "../../components/Budget/CreateUpdateBudgetDialog.vue";
import AccountChartSelectDialog from "../../components/Budget/AccountChartSelectDialog.vue";
import AverageAmountBudgetDialog from "../../components/Budget/AverageAmountBudgetDialog.vue";
import PreviewBudgetDialog from "../../components/Budget/PreviewBudgetDialog.vue";
export default {
  components: {
    CreateUpdateBudgetDialog,
    AccountChartSelectDialog,
    AverageAmountBudgetDialog,
    PreviewBudgetDialog,
  },
  props: ["id", "projectCode"],
  setup(props) {
    const store = useStore();
    const { lang } = useLang();
    const { isSharedAccountingReady, getAllAccountings, accountings } =
      useGetAccounting();
    const { compareObject, isCompareObject } = usePageDirty();
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();
    const router = useRouter();
    const {
      textTableClass,
      textFormClass,
      formatDate,
      formatDateToISOString,
      formatCurrency,
      formatDateToPeriod,
      formatDateToPeriodTh,
    } = useFormat();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.budget.listing,
          ...lang.th.budget.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.budget.listing,
          ...lang.en.budget.edit,
        };
      }
    });

    //params
    let budgetName = ref("");
    let budgetRange = ref("");
    let yearOf = ref("");
    let showValidateDialog = ref(false);
    let baseDialogClassTitle = ref("");
    let titleConfirm = ref("");
    let validateWording = ref("");
    let budgetHeader = ref(null);
    let budgetDetails = ref([]);
    let details = ref([]);
    let periodDetails = ref([]);
    let nameFieldPeriodAmount = ref("periodAmount");
    let nameFieldDetailId = ref("detailId");
    let searchText = ref("");
    let showAccountChartSelectDialog = ref(false);
    let showCreateUpdateBudgetDialog = ref(false);
    let showAverageAmountBudgetDialog = ref(false);
    let showPreviewBudgetDialog = ref(false);
    let keyToReLoadCreateUpdate = ref(1);
    let keyToReLoadAccountChart = ref(1);
    let keyToReLoadAverageAmount = ref(1);
    let selectedAccounts = ref(null);
    let selectedAccountCharts = ref(null);
    let isUnSelectAccount = ref(false);
    let isDeleteAccount = ref(false);
    let deleteAccount = ref(null);
    let isPageDirty = ref(false);
    let objToCompare = ref(null);
    let isApproveBudget = ref(false);
    let isActiveBudget = ref(false); // for model
    // let isActiveBudgetConfirm = ref(false); // for confirm dialog
    let isClearBudget = ref(false);
    let isCancelBudget = ref(false);
    let selectedDetailAverage = ref(null);
    let isAverageBudget = ref(false);
    let averageAmounts = ref([]);
    let addTableHeight = ref(0);
    let maxInputNumber = ref(999999999999);

    onMounted(async () => {
      setLoading(true);
      initiateData();
      await roleByMenuDataReady();
      await getAllAccountings();
      checkShouldSetData();
      if (isCreateDocument.value) {
        setLoading(false);
        showCreateUpdateBudgetDialog.value = true;
      }
    });

    //function
    const setLoading = (bool) => {
      store.dispatch("setLoading", { value: bool });
    };

    const initiateData = () => {
      store.dispatch("budget/resetSelectedBudget");
      budgetName.value = "";
      budgetRange.value = "";
      yearOf.value = "";

      baseDialogClassTitle.value = "";
      titleConfirm.value = "";
      validateWording.value = "";
      budgetHeader.value = null;
      budgetDetails.value = [];
      details.value = [];
      periodDetails.value = [];
      searchText.value = "";
      showAccountChartSelectDialog.value = false;
      showCreateUpdateBudgetDialog.value = false;
      showAverageAmountBudgetDialog.value = false;
      showPreviewBudgetDialog.value = false;
      showValidateDialog.value = false;
      selectedAccounts.value = null;
      selectedAccountCharts.value = null;
      isUnSelectAccount.value = false;
      isDeleteAccount.value = false;
      deleteAccount.value = null;
      isPageDirty.value = false;
      isApproveBudget.value = false;
      isActiveBudget.value = false;
      // isActiveBudgetConfirm.value = false;
      isClearBudget.value = false;
      isCancelBudget.value = false;
      selectedDetailAverage.value = null;
      averageAmounts.value = [];
    };

    const goBack = () => {
      setCompareObject();
      router.push({ name: "budget" });
    };

    const roleByMenuDataReady = async () => {
      if (isSharedRoleByMenuReady.value && !isCreateDocument.value) {
        await getBudgetById();
      }
    };

    const getBudgetById = async () => {
      try {
        let payload = {
          projectId: selectedProject.value.id,
          id: props.id,
        };
        await store.dispatch("budget/getBudgetById", payload);
      } catch (error) {
        baseDialogClassTitle.value = "";
        titleConfirm.value = mergeLang.value.notificationDialogHeader;
        validateWording.value = error;
        showValidateDialog.value = true;
        setLoading(false);
      }
    };

    const askForClearBudget = () => {
      closeDialog();     
      if(details.value.length <= 0){     
        titleConfirm.value = mergeLang.value.notificationDialogHeader;
        validateWording.value = mergeLang.value.noItemToClearBudgetWording;
        showValidateDialog.value = true;
        return true;
      }

      titleConfirm.value = mergeLang.value.clearDataBudget;
      validateWording.value = mergeLang.value.validateClearBudgetWording;
      baseDialogClassTitle.value = "";
      isClearBudget.value = true;
      showValidateDialog.value = true;
    };

    const clearDataBudget = async () => {
      budgetDetails.value.forEach((e) => {
        e.amount = 0;
      });
      details.value.forEach((data) => {
        columnPeriods.value.forEach((e) => {
          data[e.field] = 0;
        });
      });
      await calculateAmount();
      showValidateDialog.value = false;
    };

    const settingAccountChart = () => {
      closeDialog();
      let accounts;
      details.value.forEach((e) => {
        let account = {
          [e.accountId]: {
            checked: true,
            partialChecked: false,
          },
        };
        if (e.isDetail == "N") {
          let accountMasterLength = accountingMasterSubs.value.filter(
            (x) => x.parentId == e.accountId && x.isDetail == "Y"
          );
          let accountDetailLength = details.value.filter(
            (x) => x.accountParentId == e.accountId && x.isDetail == "Y"
          );
          if (accountMasterLength.length != accountDetailLength.length) {
            account = {
              [e.accountId]: {
                checked: false,
                partialChecked: true,
              },
            };
          }
        }
        accounts = { ...accounts, ...account };
      });
      selectedAccounts.value = accounts;

      showAccountChartSelectDialog.value = true;
      keyToReLoadAccountChart.value++;
    };

    const selectedAccountChart = async (data) => {
      selectedAccountCharts.value = data;
      if (data.unSelectAccounts.length > 0) {
        baseDialogClassTitle.value = "";
        titleConfirm.value = mergeLang.value.confirmSelectedAccount;
        validateWording.value = [
          mergeLang.value.editSelectedAccount,
          mergeLang.value.validateChangeAccount,
        ];
        isUnSelectAccount.value = true;
        showValidateDialog.value = true;
      } else {
        selectedAccountConfirm();
      }
    };

    const selectedAccountClose = () => {
      showValidateDialog.value = false;
      isUnSelectAccount.value = false;
    };

    const selectedAccountConfirm = () => {
      if (selectedAccountCharts.value.unSelectAccounts.length > 0) {
        for (const data of selectedAccountCharts.value.unSelectAccounts) {
          let detail = details.value.find((e) => e.accountId == data.accountId);
          if (detail) {
            deleteAccount.value = detail;
            deleteAccountConfirm();
          }
        }
      }

      selectedAccountCharts.value.selectedAccounts.forEach((data) => {
        addBudgetDetail(data);
      });
      showAccountChartSelectDialog.value = false;
      showValidateDialog.value = false;
      isUnSelectAccount.value = false;
    };

    const getAccountParentsById = async (ids) => {
      return accountParents(ids);
      // let parentData = [];
      // let parents = [];
      // let datas = [];
      // let ids = [];
      // ids = id;
      // datas = accountParents(id);
      // let i = 0;
      // while (datas.length > 0 && i <= 10) {
      //   // console.log("datas=",datas);
      //   parents = accountingMasters.value.filter((obj) => ids.includes(obj.id));
      //   if (parents.length > 0) {
      //     datas = parents;
      //     parentData = [...parents, ...parentData];
      //     ids = [...new Set(datas.map((item) => item["parentId"]))];
      //   } else {
      //     datas = [];
      //   }
      //   i++;
      //   // console.log("end_datas.length=",datas.length);
      // }
      // // console.log("parentData=", parentData);
      // return parentData.filter(
      //   (item, index) => parentData.indexOf(item) === index
      // );
    };

    const calculateAmount = async () => {
      let ids = [];
      ids = groupAccountParentId.value;

      //รวมยอด ฝั่งขวา
      details.value.forEach((element) => {
        let total = 0;
        fieldPeriods.value.forEach((e) => {
          total += element[e];
        });
        element.totalAmount = total;
      });
      //รวมยอดขึ้นบน
      let i = 0;
      while (ids.length > 0 && i <= 10) {
        if (ids.length > 0) {
          let result = details.value.filter((obj) =>
            ids.includes(obj.accountId)
          );
          result.forEach((element) => {
            const filteredDetails = details.value.filter(
              (obj) => obj.accountParentId == element.accountId
            );

            let fieldSum = [...fieldPeriods.value, "totalAmount"];
            fieldSum.forEach((e) => {
              const totalPrice = filteredDetails.reduce(
                (acc, curr) => acc + curr[e],
                0
              );
              element[e] = totalPrice;
            });
          });

          ids = [...new Set(result.map((item) => item["accountParentId"]))];
        } else {
          ids = [];
        }
        i++;
      }
    };

    const editBudget = () => {
      showCreateUpdateBudgetDialog.value = true;
      keyToReLoadCreateUpdate.value++;
    };

    const confirmCreateUpdateBudget = async (data) => {
      showCreateUpdateBudgetDialog.value = false;
      if (data.copyBudget) {
        let payload = {
          projectId: selectedProject.value.id,
          id: data.copyBudget.id,
        };
        await store.dispatch("budget/getBudgetById", payload);
        await setDataCopy();
        store.dispatch("budget/resetSelectedBudget");
      }

      budgetHeader.value = data;
      await updatePeriod(data.dateStart, data.dateEnd);
      budgetName.value = data.name;
      budgetRange.value =
        mergeLang.value.periodRange +
        " " +
        formatDateToPeriod(data.dateStart) +
        " - " +
        formatDateToPeriod(data.dateEnd);
      yearOf.value = data.yearOf + 543;

      if (details.value.length <= 0) {
        showAccountChartSelectDialog.value = true;
      }
    };

    const closeCreateUpdateBudgetDialog = () => {
      showCreateUpdateBudgetDialog.value = false;
      if (!budgetHeader.value) {
        router.push({ name: "budget" });
      }
      // keyToReLoadCreateUpdate.value++;
    };

    const updateBudgetDetail = async (data) => {
      columnPeriods.value.forEach((element) => {
        let budgetDetail = budgetDetails.value.find(
          (e) => e.id == data[element.detailId]
        );
        if (budgetDetail) {
          budgetDetail.amount = data[element.field] ? data[element.field] : 0;
        }
      });
      await calculateAmount();
    };

    const deleteBudgetDetail = (data) => {
      closeDialog();
      deleteAccount.value = data;
      baseDialogClassTitle.value = "";
      titleConfirm.value = mergeLang.value.deleteAccount;
      validateWording.value = mergeLang.value.validateDeleteAccount;
      isDeleteAccount.value = true;
      showValidateDialog.value = true;
    };

    const deleteAccountConfirm = async () => {
      new Promise(() => {
        columnPeriods.value.forEach((element) => {
          let index = budgetDetails.value.findIndex(
            (e) => e.id == deleteAccount.value[element.detailId]
          );
          budgetDetails.value.splice(index, 1);
        });
        removeDetail(deleteAccount.value.accountId);
        removeAccountParent(deleteAccount.value.accountParentId);
        calculateAmount();
      });
      isDeleteAccount.value = false;
      showValidateDialog.value = false;
    };

    const removeAccountParent = async (parentId) => {
      new Promise(() => {
        let detail = details.value.find(
          (e) => e.accountParentId == parentId && e.isDetail == "Y"
        );
        if (!detail) {
          removeDetail(parentId);
        }
      });
    };

    const removeDetail = (id) => {
      //****** ลบออกจาก details ******
      const indexDetail = details.value.findIndex((e) => e.accountId == id);
      details.value.splice(indexDetail, 1);
    };

    const averageBudgetDetail = async (data) => {
      selectedDetailAverage.value = data;
      showAverageAmountBudgetDialog.value = true;
      keyToReLoadAverageAmount.value++;
    };

    const averageAmountBudget = async (data) => {
      averageAmounts.value = data;
      showAverageAmountBudgetDialog.value = false;
      if (selectedDetailAverage.value.totalAmount > 0) {
        closeDialog();
        titleConfirm.value = mergeLang.value.titleAverageAmountBudget;
        validateWording.value = mergeLang.value.averageAmountBudgetWording;
        baseDialogClassTitle.value = "";
        isAverageBudget.value = true;
        showValidateDialog.value = true;
      } else {
        confirmAverageBudget();
      }
    };

    const confirmAverageBudget = async () => {
      showValidateDialog.value = false;
      if (averageAmounts.value.length > 0) {
        let i = 0;
        columnPeriods.value.forEach((element) => {
          let budgetDetail = budgetDetails.value.find(
            (e) => e.id == selectedDetailAverage.value[element.detailId]
          );
          if (budgetDetail) {
            budgetDetail.amount = averageAmounts.value[i];
          }
          selectedDetailAverage.value[element.field] = averageAmounts.value[i];
          i++;
        });
        await calculateAmount();
      }
    };

    const setBudgetDetail = (data) => {
      let accountParentCode = "";
      let parent = accountingMasterParents.value.find(
        (x) => x.id == data.accountParentId
      );
      if (parent) {
        accountParentCode = parent.code.trim();
      }
      let budgetDetail = {
        id: randomDummyId(),
        budgetHeaderId: 0,
        itemNo: 0,
        accountId: data.accountId,
        accountCode: data.accountCode,
        accountName: data.accountName,
        accountParentId: data.accountParentId,
        accountParentCode: accountParentCode,
        date: "",
        type: data.type,
        amount: 0,
        totalAmount: 0,
        isDetail: data.isDetail,
        searchText: data.accountCode + data.accountName,
      };
      return budgetDetail;
    };

    const accountParents = (ids) => {
      const result = accountingMasters.value.filter((obj) =>
        ids.includes(obj.id)
      );
      return result;
    };

    const getParentDetails = async (ids) => {
      let parentDatas = await getAccountParentsById(ids);
      let parents = [];
      parentDatas.forEach((e) => {
        let accountParent = {
          id: e.id,
          accountId: e.id,
          accountCode: e.code.trim(),
          accountName: e.name,
          accountParentId: e.parentId,
          accountParentCode: e.code.trim(),
          type: e.type,
          totalAmount: 0,
          isDetail: "N",
          searchText: e.code.trim() + e.name,
        };

        columnPeriods.value.forEach((e) => {
          let period = {
            [e.field]: 0,
          };
          accountParent = { ...accountParent, ...period };
        });
        parents.push(accountParent);
      });

      return parents.sort((a, b) => (a.accountCode < b.accountCode ? -1 : 1));
    };

    const updatePeriod = async (periodStart, periodEnd) => {
      // let dateStart = new Date("2024-03-01");
      // let dateEnd = new Date("2024-06-01");
      let dateStart = new Date(periodStart);
      let dateEnd = new Date(periodEnd);
      let periods = [];
      let i = 0;
      for (
        let date = dateStart;
        date <= dateEnd;
        date.setMonth(date.getMonth() + 1)
      ) {
        periods.push(formatDateToISOString(date));
        if (i > 36) {
          break;
        }
        i++;
      }

      //เพิ่มข้อมูล
      let dataBudgetDetails = [...groupBudgetDetails.value];
      periods.forEach((period) => {
        let chPeriod = periodDetails.value.find(
          (date) => date.substring(0, 10) == period
        );

        if (!chPeriod) {
          dataBudgetDetails.forEach((element) => {
            let budgetDetail = setBudgetDetail(element);
            budgetDetail.date = period;
            budgetDetails.value.push(budgetDetail);
          });
        }
      });

      //ลบข้อมูล
      periodDetails.value.forEach((period) => {
        let chPeriod = periods.find((date) => date == period.substring(0, 10));
        if (!chPeriod) {
          let detaiilRemove = budgetDetails.value.filter(
            (c) => c.date == period
          );
          detaiilRemove.forEach((element) => {
            const index = budgetDetails.value.findIndex(
              (e) => e.id === element.id
            );
            budgetDetails.value.splice(index, 1);
          });
        }
      });

      periodDetails.value = [...periods];
      await setDataDetail();
      await calculateAmount();
    };

    const addBudgetDetail = async (data) => {
      let account = details.value.find(
        (e) => e.accountId == data.accountId && e.isDetail == "Y"
      );
      if (!account) {
        let detail = setBudgetDetail(data);
        columnPeriods.value.forEach((e) => {
          let budgetDetail = setBudgetDetail(data);
          budgetDetail.date = e.date;
          budgetDetails.value.push(budgetDetail);
          let period = {
            [e.field]: 0,
            [e.detailId]: budgetDetail.id,
          };
          detail = { ...detail, ...period };
        });

        //เช็ค มีบัญชีคุมของ บัญชีที่เพิ่มไหม
        let ids = [data.accountParentId];
        let uniqueParents = [];
        let parents = await getParentDetails(ids);
        parents.forEach((element) => {
          let parent = details.value.find(
            (e) => e.accountId == element.accountId
          );
          if (!parent) {
            uniqueParents.push(element);
          }
        });

        let dataSort = [...uniqueParents, ...details.value, ...[detail]];
        details.value = dataSort.sort((a, b) => {
          if (a.accountParentCode < b.accountParentCode) return -1;
          if (a.accountParentCode > b.accountParentCode) return 1;

          if (a.isDetail < b.isDetail) return -1;
          if (a.isDetail > b.isDetail) return 1;

          if (a.accountCode < b.accountCode) return -1;
          if (a.accountCode > b.accountCode) return 1;

          return 0;
        });
      }
    };

    const save = async () => {
      if (validateRole("save")) {
        return;
      }

      if (validateData("save")) {
        return;
      }

      setLoading(true);
      budgetDetails.value = budgetDetails.value.sort((a, b) =>
        a.accountCode < b.accountCode ? -1 : 1
      );
      // console.log("save=", payload.value);
      try {
        if (isCreateDocument.value) {
          await store.dispatch("budget/createBudget", payload.value);
        } else {
          await store.dispatch("budget/updateBudget", payload.value);
        }
        await store.dispatch("budget/resetBudgets");

        baseDialogClassTitle.value = "p-text-success";
        titleConfirm.value = mergeLang.value.saveSuccess;
        validateWording.value = mergeLang.value.budgetSaved;
        showValidateDialog.value = true;

        if (isCreateDocument.value) {
          await router.replace({
            name: "budget-edit",
            params: {
              id: budgetById.value.id,
            },
          });
        }

        checkShouldSetData();
        setCompareData();
      } catch (error) {
        baseDialogClassTitle.value = "";
        titleConfirm.value = mergeLang.value.notificationDialogHeader;
        validateWording.value = error.message;
        showValidateDialog.value = true;
        setLoading(false); 
      }
      setLoading(false);
    };

    const validateUpdateActiveBudget = async () => {
      if (validateRole("approve")) {
        isActiveBudget.value = !isActiveBudget.value;
        return;
      }
      titleConfirm.value = mergeLang.value.notificationDialogHeader;
      baseDialogClassTitle.value = "";
      if (isCreateDocument.value) {
        isActiveBudget.value = !isActiveBudget.value;
        validateWording.value = mergeLang.value.dialogContentSaveBeforeActive;
        showValidateDialog.value = true;
        return;
      }
      setCompareObject();
      if (!isPageDirty.value) {
        await updateActiveBudget();
      } else {
        isActiveBudget.value = !isActiveBudget.value;
        // validateWording.value = mergeLang.value.contentConfirmSaveAndActive;
        // isActiveBudgetConfirm.value = true;
        // showValidateDialog.value = true;
        const answer = await confirmDialog.reveal();
        return !answer.isCanceled;
      }
    };

    const updateActiveBudget = async () => {
      closeDialog();
      setLoading(true);
      try {
        let payload = {
          id: props.id,
          projectId: selectedProject.value.id,
        };
        // if (isPageDirty.value) {
        //   await store.dispatch("budget/updateBudget", payload.value);
        // }
        await store.dispatch("budget/updateActiveBudget", payload);
        isActiveBudget.value = budgetById.value.isActive == "Y" ? true : false;
        baseDialogClassTitle.value = "p-text-success";
        titleConfirm.value = 
          isActiveBudget.value ? 
            mergeLang.value.dialogTitleUpdateActiveSuccess : 
            mergeLang.value.dialogTitleUpdateInactiveSuccess;
        validateWording.value = 
          isActiveBudget.value ? 
            mergeLang.value.dialogContentUpdateActiveSuccess : 
            mergeLang.value.dialogContentUpdateInactiveSuccess;
        showValidateDialog.value = true;
        checkShouldSetData();
        setCompareData();
      } catch (error) {
        isActiveBudget.value = !isActiveBudget.value;
        titleConfirm.value = mergeLang.value.notificationDialogHeader;
        baseDialogClassTitle.value = "p-error";
        let massage = error;
				if (error == "111") {
					massage = mergeLang.value.validateRoleApprove;
				} else if (error == "103") {
					massage = mergeLang.value.dataNotFound;
				} else if (error == "108") {
					massage = mergeLang.value.validateCancelled;
				} else if (error == "107") {
					massage = mergeLang.value.dialogContentPeriodAlreadyActive;
				}
        validateWording.value = massage;
        showValidateDialog.value = true;
        setLoading(false);
      }
      setLoading(false);
    };

    const askForApprove = () => {
      if (validateRole("approve")) {
        return;
      }

      if (validateData("approve")) {
        return;
      }

      let message = "";
      for (let index = 0; index < details.value.length; index++) {
        const element = details.value[index];
        if (element.isDetail == "Y" && element.totalAmount <= 0) {
          message = element.accountCode + " : " + element.accountName;
          break;
        }
      }
      if (message != "") {
        baseDialogClassTitle.value = "p-error";
        titleConfirm.value = mergeLang.value.cannotApprove;
        validateWording.value =
          mergeLang.value.validateCannotApproveWording.replace(
            ":account",
            message
          );
        showValidateDialog.value = true;
        return;
      }

      setCompareObject();

      if (!isPageDirty.value) {
        titleConfirm.value = mergeLang.value.approveBudget;
        validateWording.value = mergeLang.value.validateApproveBudget;
      } else {
        titleConfirm.value = mergeLang.value.validateNotApproved;
        validateWording.value = mergeLang.value.validateNotApprovedWording;
      }

      baseDialogClassTitle.value = "";
      isApproveBudget.value = true;
      showValidateDialog.value = true;
    };

    const approve = async () => {
      closeDialog();
      setLoading(true);
      try {
        let payloadApprove = {
          id: props.id,
          projectId: selectedProject.value.id,
        };

        if (!isPageDirty.value) {
          await store.dispatch("budget/approveBudget", payloadApprove);
        } else {
          await store.dispatch("budget/updateBudget", payload.value);
          await store.dispatch("budget/approveBudget", payloadApprove);
        }

        baseDialogClassTitle.value = "p-text-success";
        titleConfirm.value = mergeLang.value.approveSuccess;
        validateWording.value = mergeLang.value.approveSuccess;
        showValidateDialog.value = true;

        checkShouldSetData();
        setCompareData();
      } catch (error) {
        baseDialogClassTitle.value = "";
        titleConfirm.value = mergeLang.value.notificationDialogHeader;
        validateWording.value = error.message;
        showValidateDialog.value = true;
        setLoading(false);
      }
      setLoading(false);
    };

    const askForCancel = () => {
      if (validateRole("cancel")) {
        return;
      }

      titleConfirm.value = mergeLang.value.cancelBudget;
      validateWording.value = mergeLang.value.validateCancelBudgetWording;
      baseDialogClassTitle.value = "";
      isCancelBudget.value = true;
      showValidateDialog.value = true;
    };

    const cancelBudget = async () => {
      closeDialog();
      setLoading(true);
      try {
        let payloadApprove = {
          id: props.id,
          projectId: selectedProject.value.id,
        };
        await store.dispatch("budget/cancelBudget", payloadApprove);

        baseDialogClassTitle.value = "p-text-success";
        titleConfirm.value = mergeLang.value.cancelSuccess;
        validateWording.value = mergeLang.value.cancelBudgetSuccess;
        showValidateDialog.value = true;

        checkShouldSetData();
        setCompareData();
      } catch (error) {
        baseDialogClassTitle.value = "";
        titleConfirm.value = mergeLang.value.notificationDialogHeader;
        validateWording.value = error.message;
        showValidateDialog.value = true;
        setLoading(false);
      }
      setLoading(false);
    };

    const checkShouldSetData = async () => {
      if (
        !isCreateDocument.value &&
        isSharedRoleByMenuReady.value &&
        isSharedAccountingReady.value &&
        budgetById.value
      ) {
        setData();
      }
    };

    const setData = async () => {
      let details = [];
      budgetById.value.details.forEach((e) => {
        const element = { ...e };
        details.push(element);
      });

      budgetHeader.value = budgetById.value;
      budgetDetails.value = details;
      periodDetails.value = [...groupDate.value];
      budgetName.value = budgetById.value.name;
      budgetRange.value =
        mergeLang.value.periodRange +
        " " +
        formatDateToPeriod(budgetById.value.dateStart) +
        " - " +
        formatDateToPeriod(budgetById.value.dateEnd);
      yearOf.value = budgetById.value.yearOf + 543;
      isActiveBudget.value = budgetById.value.isActive == "Y" ? true : false;
      await setDataDetail();
      setCompareData();
      setLoading(false); 
    };

    const setDataCopy = async () => {
      if (budgetById.value) {
        let details = [];
        budgetById.value.details.forEach((e) => {
          const element = { ...e };
          element.id = randomDummyId();
          element.amount = 0;
          details.push(element);
        });

        budgetDetails.value = details;
        periodDetails.value = [...groupDate.value];
        await setDataDetail();
      }
      setLoading(false);
    };

    const setDataDetail = async () => {
      let parentDetails = await getParentDetails(groupAccountParentId.value);
      let datas = [];
      groupBudgetDetails.value.forEach((e) => {
        let data = { ...e, ...{ accountParentCode: "" } };
        let parent = accountingMasterParents.value.find(
          (x) => x.id == e.accountParentId
        );
        if (parent) {
          data.accountParentCode = parent.code.trim();
        }
        datas.push(data);
      });
      let dataSort = [...parentDetails, ...datas];
      details.value = dataSort.sort((a, b) => {
        if (a.accountParentCode < b.accountParentCode) return -1;
        if (a.accountParentCode > b.accountParentCode) return 1;

        if (a.isDetail < b.isDetail) return -1;
        if (a.isDetail > b.isDetail) return 1;

        if (a.accountCode < b.accountCode) return -1;
        if (a.accountCode > b.accountCode) return 1;

        return 0;
      });

      await calculateAmount();
    };

    const randomDummyId = () => {
      return "dummy" + (Math.floor(Math.random() * 90000) + 10000);
    };

    const previewBudget = () => {
      showPreviewBudgetDialog.value = true;
    };

    const setCompareData = () => {
      const details = [...budgetById.value.details];
      let data = {
        projectId: selectedProject.value.id,
        name: budgetHeader.value.name,
        yearOf: budgetHeader.value.yearOf,
        dateStart: budgetHeader.value.dateStart,
        dateEnd: budgetHeader.value.dateEnd,
        remark: budgetHeader.value.remark,
        details: details,
      };
      if (!isCreateDocument.value) {
        data = {
          ...data,
          id: props.id,
        };
      }
      objToCompare.value = data;
      setCompareObject();
    };

    const setCompareObject = () => {
      let result = false;
      if (isCreateDocument.value) {
        result = isCompareObject(objToCompare.value, payload.value);
      } else {
        let data = {
          ...payload.value,
          id: props.id,
        };
        result = isCompareObject(objToCompare.value, data);
      }
      isPageDirty.value = result;
    };

    const validateRole = (type) => {
      closeDialog();
      if (type == "save" && isCreateDocument.value && !isRoleCreate.value) {
        baseDialogClassTitle.value = "";
        titleConfirm.value = mergeLang.value.notificationDialogHeader;
        validateWording.value = mergeLang.value.validateRoleInsert;
        showValidateDialog.value = true;
        return true;
      } else if (
        type == "save" &&
        !isCreateDocument.value &&
        !isRoleUpdate.value
      ) {
        baseDialogClassTitle.value = "";
        titleConfirm.value = mergeLang.value.notificationDialogHeader;
        validateWording.value = mergeLang.value.validateRoleUpdate;
        showValidateDialog.value = true;
        return true;
      } else if (
        type == "approve" &&
        !isCreateDocument.value &&
        !isRoleApprove.value
      ) {
        baseDialogClassTitle.value = "";
        titleConfirm.value = mergeLang.value.notificationDialogHeader;
        validateWording.value = mergeLang.value.validateRoleApprove;
        showValidateDialog.value = true;
        return true;
      } else if (
        type == "cancel" &&
        !isCreateDocument.value &&
        !isRoleCancel.value
      ) {
        baseDialogClassTitle.value = "";
        titleConfirm.value = mergeLang.value.notificationDialogHeader;
        validateWording.value = mergeLang.value.validateRoleCancel;
        showValidateDialog.value = true;
        return true;
      }
      return false;
    };

    const validateData = (type) => {
      closeDialog();
      
      if(details.value.length <= 0){
        if(type == "save"){
          validateWording.value = mergeLang.value.noItemToSaveBudgetWording;
        }else if (type == "approve") {          
          validateWording.value = mergeLang.value.noItemToApproveBudgetWording;
        }
        titleConfirm.value = mergeLang.value.notificationDialogHeader;
        showValidateDialog.value = true;
        return true;
      }
      
      if(store.getters["budget/budgetLastDate"]){
        let lastDate = new Date(store.getters["budget/budgetLastDate"].lastDate);
        let dateStart = new Date(budgetHeader.value.dateStart);
        if (dateStart <= lastDate) {
          // console.log("dateStart=", dateStart);
          // console.log("lastDate=", lastDate);
          titleConfirm.value = mergeLang.value.notificationDialogHeader;
          validateWording.value = mergeLang.value.validateDateStartAndLastDate;
          showValidateDialog.value = true;
          return true;
        }          
      }

      if (type == "approve") {
        let message = "";
        for (let index = 0; index < details.value.length; index++) {
          const element = details.value[index];
          if (element.isDetail == "Y" && element.totalAmount <= 0) {
            message = element.accountCode + " : " + element.accountName;
            break;
          }
        }
        if (message != "") {
          baseDialogClassTitle.value = "p-error";
          titleConfirm.value = mergeLang.value.cannotApprove;
          validateWording.value =
            mergeLang.value.validateCannotApproveWording.replace(
              ":account",
              message
            );
          showValidateDialog.value = true;
          return true;
        }
      }
    };

    const closeDialog = () => {
      showValidateDialog.value = false;
      isUnSelectAccount.value = false;
      isDeleteAccount.value = false;
      isApproveBudget.value = false;
      isClearBudget.value = false;
      isCancelBudget.value = false;
      // isActiveBudgetConfirm.value = false;
      baseDialogClassTitle.value = "";
      titleConfirm.value = "";
      validateWording.value = "";
    };

    const closeMessage = () => {
      addTableHeight.value = 80;
    };

    const validateMaxInputNumber = (value) => {  
      let amount = maxInputNumber.value;
      if (value <= maxInputNumber.value) {
        amount = value;
      }
      return amount;
    };

    //computed

    const halfViewportHeight = computed(() => {
      return store.getters.halfViewportHeight + addTableHeight.value;
    });

    const payload = computed(() => {
      let details = [];
      let itemNo = 1;
      for (const each of budgetDetails.value.filter((each) => each.accountId)) {
        let detail = null;
        if (typeof each.id === "string" && each.id.includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        detail.itemNo = itemNo;
        details.push(detail);
        itemNo++;
      }

      let payload = {
        id: props.id,
        projectId: selectedProject.value.id,
        name: budgetHeader.value ? budgetHeader.value.name : null,
        yearOf: budgetHeader.value ? budgetHeader.value.yearOf : null,
        dateStart: budgetHeader.value ? budgetHeader.value.dateStart : null,
        dateEnd: budgetHeader.value ? budgetHeader.value.dateEnd : null,
        remark: budgetHeader.value ? budgetHeader.value.remark : null,
        details: details,
      };
      return payload;
    });

    const isCreateDocument = computed(() => {
      return props.id === "create";
    });

    const title = computed(() => {
      let result = mergeLang.value.createBudget;
      if (!isCreateDocument.value) {
        result = mergeLang.value.editBudget;
      }
      return result;
    });

    const tableEmpty = computed(() => {
      let result = mergeLang.value.tableEmpty;
      if (searchText.value) {
        result = mergeLang.value.searchNoDetail;
      }
      return result;
    });

    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });

    const budgetById = computed(() => {
      return store.getters["budget/selectedBudget"];
    });

    const groupAccountParentId = computed(() => {
      let groupAccountParentId = [];
      if (budgetDetails.value) {
        groupAccountParentId = [
          ...new Set(
            budgetDetails.value.map((item) => item["accountParentId"])
          ),
        ];
      }
      return groupAccountParentId;
    });

    const groupDate = computed(() => {
      let dates = [];
      if (budgetDetails.value) {
        dates = [...new Set(budgetDetails.value.map((item) => item["date"]))];
      }
      return dates;
    });

    const columnPeriods = computed(() => {
      let columns = [];
      periodDetails.value.forEach((e) => {
        let period = {
          field: nameFieldPeriodAmount.value + e.slice(0, 7).replace("-", ""),
          columnName: formatDateToPeriodTh(e),
          detailId: nameFieldDetailId.value + e.slice(0, 7).replace("-", ""),
          date: e,
        };
        columns.push(period);
      });
      return columns;
    });

    const fieldPeriods = computed(() => {
      return [...new Set(columnPeriods.value.map((item) => item["field"]))];
    });

    const groupBudgetDetails = computed(() => {
      const groupedData = {};
      for (const detail of budgetDetails.value) {
        const key = `${detail.accountId}`;
        groupedData[key] = groupedData[key] || {
          id: detail.accountId,
          budgetHeaderId: detail.budgetHeaderId,
          accountId: detail.accountId,
          accountCode: detail.accountCode.trim(),
          accountName: detail.accountName,
          accountParentId: detail.accountParentId,
          accountParentCode: "",
          type: detail.type,
          totalAmount: 0,
          isDetail: "Y",
          searchText: detail.accountCode.trim() + detail.accountName,
        };

        groupedData[key].totalAmount += detail.amount;
        let period = detail.date.slice(0, 7).replace("-", "");
        groupedData[key][`${nameFieldPeriodAmount.value}${period}`] =
          groupedData[key][`${nameFieldPeriodAmount.value}${period}`] || 0;
        groupedData[key][`${nameFieldPeriodAmount.value}${period}`] +=
          detail.amount;
        groupedData[key][`${nameFieldDetailId.value}${period}`] = detail.id;
      }

      return Object.values(groupedData); // Convert to array
    });

    const accountingMasters = computed(() => {
      let dataSort = [
        ...accountingMasterParents.value,
        ...accountingMasterSubs.value,
      ];
      return dataSort.sort((a, b) => (a.accountCode < b.accountCode ? -1 : 1));
    });

    const accountingMasterParents = computed(() => {
      let ids = [
        ...new Set(accountingMasterSubs.value.map((item) => item["parentId"])),
      ];
      let itemList = [];
      let result = accountings.value.filter(
        (e) => ids.includes(e.id) && e.activeStatus == "Y"
      );
      result.forEach((element) => {
        element = {
          ...element,
          ...{
            level: "",
            isDisplay: false,
          },
        };
        itemList.push(element);
      });
      return itemList;
    });

    const accountingMasterSubs = computed(() => {
      let itemList = [];
      let result = accountings.value.filter(
        (e) =>
          e.activeStatus == "Y" &&
          e.isDetail == "Y" &&
          e.type != "2" &&
          e.type != "3"
      );
      result.forEach((element) => {
        element = {
          ...element,
          ...{
            level: "",
            isDisplay: true,
          },
        };
        itemList.push(element);
      });
      return itemList;
    });

    const filteredAccount = computed(() => {
      let results = [];
      if (searchText.value) {
        let datas = details.value.filter((e) =>
          e.searchText.toLowerCase().includes(searchText.value.toLowerCase())
        );
        if (datas.length > 0) {
          let parents = datas.filter((e) => e.isDetail == "N");
          if (parents.length > 0) {
            parents.forEach((e) => {
              results.push(e);
              let dataParents = details.value.filter(
                (x) => x.accountParentId == e.accountId
              );
              if (dataParents) {
                results = [...results, ...dataParents];
              }
            });
          }

          let dataDetails = datas.filter((e) => e.isDetail == "Y");
          if (dataDetails.length > 0) {
            dataDetails.forEach((e) => {
              let detail = results.find((x) => x.accountId == e.accountId);
              if (!detail) {
                let parentInDetails = details.value.find(
                  (x) => x.accountId == e.accountParentId
                );
                if (parentInDetails) {
                  let parentInResults = results.find(
                    (x) => x.accountId == e.accountParentId
                  );
                  if (!parentInResults) {
                    results = [...results, ...[parentInDetails]];
                  }
                }
                results = [...results, ...[e]];
              }
            });
          }

          results = results.sort((a, b) => {
            if (a.accountParentCode < b.accountParentCode) return -1;
            if (a.accountParentCode > b.accountParentCode) return 1;

            if (a.isDetail < b.isDetail) return -1;
            if (a.isDetail > b.isDetail) return 1;

            if (a.accountCode < b.accountCode) return -1;
            if (a.accountCode > b.accountCode) return 1;

            return 0;
          });
        }
      } else {
        results = [...details.value];
      }

      return results;
    });

    const isCancelStatus = computed(() => {
      if (budgetById.value &&
        budgetById.value.isCancel == "Y") {
        return true;
      }
      return false;
    });

    const isApproveStatus = computed(() => {
      if (budgetById.value &&
         budgetById.value.approveStatus == "P") {
        return true;
      }
      return false;
    });

    const isWaitApproveStatus = computed(() => {
      if (!isCreateDocument.value && budgetById.value && !isCancelStatus.value && !isApproveStatus.value) { 
        return true;
      }
      return false;
    });

    const isActiveStatus = computed(() => {
      if (budgetById.value &&
         budgetById.value.isActive == "Y") {
        return true;
      }
      return false;
    });

    const isEditBudgetDetail = computed(() => {
      if (!isCancelStatus.value && !isActiveStatus.value) {
        return true;
      }
      return false;
    });

    const isShowButtonCancel = computed(() => {
      if (
        !isCreateDocument.value &&
        isActiveStatus.value &&
        !isCancelStatus.value
      ) {
        return true;
      }
      return false;
    });

    const roleByMenu = computed(() => {
      return getRoleByMenuByCode("pms_budget");
    });

    const isRoleCreate = computed(() => {
      if (roleByMenu.value && roleByMenu.value.insert == "Y") {
        return true;
      }
      return false;
    });

    const isRoleUpdate = computed(() => {
      if (roleByMenu.value && roleByMenu.value.update == "Y") {
        return true;
      }
      return false;
    });

    const isRoleApprove = computed(() => {
      if (roleByMenu.value && roleByMenu.value.approve == "Y") {
        return true;
      }
      return false;
    });

    const isRoleCancel = computed(() => {
      if (roleByMenu.value && roleByMenu.value.cancel == "Y") {
        return true;
      }
      return false;
    });

    const dataButtons = computed(() => {
      let result = [
        {
          id: "cancel",
          action: "gotoCancel",
          class: "button-toolbar p-button-outlined p-button-danger mr-2",
          caption: mergeLang.value.cancel,
          icon: "icon-cancel",
          iconColor: "error",
          isShow: isShowButtonCancel.value,
        },
        // {
        //   id: "approve",
        //   action: "gotoApprove",
        //   class: "mr-2",
        //   caption: mergeLang.value.approve,
        //   icon: "icon-path",
        //   iconColor: "white",
        //   isShow: !isCreateDocument.value && !isCancelStatus.value && !isApproveStatus.value,
        // },
        {
          id: "save",
          action: "gotoSave",
          class: "p-button-label font-normal mr-2",
          caption: mergeLang.value.save,
          icon: "icon-save",
          iconColor: "white",
          isShow: isEditBudgetDetail.value && !isShowButtonCancel.value,
        },
      ];
      return result;
    });

    const dataButtonDialog = computed(() => {
      let result = [
        {
          id: "confirm",
          caption: mergeLang.value.close,
          class: "w-full p-button-outlined p-button-secondary",
          action: "confirm",
        },
      ];

      if (isUnSelectAccount.value) {
        result = [
          {
            id: "close",
            caption: mergeLang.value.close,
            class: "w-full p-button-outlined p-button-secondary",
            action: "selected-account-close",
          },
          {
            id: "confirm",
            caption: mergeLang.value.buttonDialogConfirm,
            class: "w-full",
            action: "selected-account-confirm",
          },
        ];
      }

      if (isClearBudget.value) {
        result = [
          {
            id: "close",
            caption: mergeLang.value.close,
            class: "w-full p-button-outlined p-button-secondary",
            action: "close",
          },
          {
            id: "delete",
            caption: mergeLang.value.buttonDialogConfirm,
            class: "w-full",
            action: "clear-budget",
          },
        ];
      }

      if (isDeleteAccount.value) {
        result = [
          {
            id: "close",
            caption: mergeLang.value.close,
            class: "w-full p-button-outlined p-button-secondary",
            action: "close",
          },
          {
            id: "delete",
            caption: mergeLang.value.buttonDialogConfirm,
            class: "w-full",
            action: "delete-account-confirm",
          },
        ];
      }

      if (isApproveBudget.value) {
        if (!isPageDirty.value) {
          result = [
            {
              id: "close",
              caption: mergeLang.value.close,
              class: "w-full p-button-outlined p-button-secondary",
              action: "close",
            },
            {
              id: "approve",
              caption: mergeLang.value.approve,
              class: "w-full",
              action: "approve-budget",
            },
          ];
        } else {
          result = [
            {
              id: "close",
              caption: mergeLang.value.close,
              class: "w-full p-button-outlined p-button-secondary",
              action: "close",
            },
            {
              id: "approve",
              caption: mergeLang.value.saveAndApprove,
              class: "w-full",
              action: "approve-budget",
            },
          ];
        }
      }

      // if (isActiveBudgetConfirm.value) {
      //   result = [
      //     {
      //       id: "close",
      //       caption: mergeLang.value.close,
      //       class: "w-full p-button-outlined p-button-secondary",
      //       action: "close",
      //     },
      //     {
      //       id: "cancel",
      //       caption: mergeLang.value.buttonDialogConfirm,
      //       class: "w-full",
      //       action: "active-budget",
      //     },
      //   ];
      // }

      if (isCancelBudget.value) {
        result = [
          {
            id: "close",
            caption: mergeLang.value.close,
            class: "w-full p-button-outlined p-button-secondary",
            action: "selected-account-close",
          },
          {
            id: "cancel",
            caption: mergeLang.value.buttonDialogConfirm,
            class: "w-full",
            action: "cancel-budget",
          },
        ];
      }

      if (isAverageBudget.value) {
        result = [
          {
            id: "close",
            caption: mergeLang.value.close,
            class: "w-full p-button-outlined p-button-secondary",
            action: "selected-account-close",
          },
          {
            id: "average",
            caption: mergeLang.value.buttonDialogConfirm,
            class: "w-full",
            action: "average-budget",
          },
        ];
      }

      return result;
    });

    const confirmDialog = createConfirmDialog(BaseDialog, {
      titleConfirm: mergeLang.value.notificationDialogHeader,
      wordingConfirm: mergeLang.value.pageDirtyConfirmMessage,
      dataButtons: [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "cancel",
        },
        {
          id: "confirm",
          caption: mergeLang.value.buttonDialogConfirm,
          class: "w-full",
          action: "confirm",
        },
      ],
      display: true,
    });

    onBeforeRouteLeave(async (to, from) => {
      if (isPageDirty.value) {
        let curValue = to.params.projectCode;
        let oldValue = from.params.projectCode;
        if (curValue == oldValue) {
          const answer = await confirmDialog.reveal();
          return !answer.isCanceled;
        }
      }
      return true;
    });

    watch(isSharedAccountingReady, function () {
      checkShouldSetData();
    });

    watch(isSharedRoleByMenuReady, function () {
      roleByMenuDataReady();
    });

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatDate,
      formatDateToISOString,
      formatCurrency,
      formatDateToPeriod,
      formatDateToPeriodTh,
      isSharedAccountingReady,
      getAllAccountings,
      accountings,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,

      //params
      yearOf,
      budgetName,
      budgetRange,
      budgetHeader,
      budgetDetails,
      details,
      periodDetails,
      searchText,
      showCreateUpdateBudgetDialog,
      showAccountChartSelectDialog,
      showAverageAmountBudgetDialog,
      showPreviewBudgetDialog,
      keyToReLoadCreateUpdate,
      keyToReLoadAccountChart,
      keyToReLoadAverageAmount,
      selectedAccounts,
      isUnSelectAccount,
      isDeleteAccount,
      deleteAccount,
      isApproveBudget,
      isActiveBudget,
      isClearBudget,
      isCancelBudget,
      selectedDetailAverage,
      isAverageBudget,
      averageAmounts,
      addTableHeight,
      maxInputNumber,

      //function
      setLoading,
      goBack,
      save,
      askForApprove,
      approve,
      selectedAccountChart,
      selectedAccountClose,
      selectedAccountConfirm,
      settingAccountChart,
      askForClearBudget,
      clearDataBudget,
      calculateAmount,
      accountParents,
      addBudgetDetail,
      updatePeriod,
      updateBudgetDetail,
      deleteBudgetDetail,
      removeDetail,
      removeAccountParent,
      averageBudgetDetail,
      randomDummyId,
      editBudget,
      confirmCreateUpdateBudget,
      closeCreateUpdateBudgetDialog,
      deleteAccountConfirm,
      previewBudget,
      compareObject,
      isCompareObject,
      setCompareData,
      closeDialog,
      validateRole,
      validateData,
      askForCancel,
      cancelBudget,
      averageAmountBudget,
      confirmAverageBudget,
      closeMessage,
      validateMaxInputNumber,
      validateUpdateActiveBudget,
      updateActiveBudget,

      //computed
      halfViewportHeight,
      payload,
      dataButtons,
      title,
      tableEmpty,
      budgetById,
      groupBudgetDetails,
      groupAccountParentId,
      groupDate,
      columnPeriods,
      fieldPeriods,
      accountingMasters,
      filteredAccount,
      isCancelStatus,
      isApproveStatus,
      isWaitApproveStatus,
      isActiveStatus,
      isEditBudgetDetail,
      isRoleCreate,
      isRoleUpdate,
      isRoleApprove,
      isRoleCancel,

      //dialog
      showValidateDialog,
      baseDialogClassTitle,
      titleConfirm,
      validateWording,
      dataButtonDialog,
    };
  },
  methods: {
    async buttonDown(event, index) {
      let startCellIndex = 3;
      if (event.type == "keydown") {
        let table = this.$refs.budgetTable.$el;
        let tableRows = table.getElementsByTagName("tr");

        if (this.details.length != index && event.key == "Enter"){      
          let tableRowNext = tableRows[index+1].getElementsByTagName("td"); 
          let rowNextParent = tableRowNext[startCellIndex].children[0].getElementsByTagName("span");
          if(rowNextParent.length > 0){
            tableRowNext[startCellIndex].children[0].children[0].children[0].focus();
          }else{
            tableRowNext = tableRows[index+2].getElementsByTagName("td"); 
            tableRowNext[startCellIndex].children[0].children[0].children[0].focus();
          }          
        }
      }
    },
  },
};
</script>

<style scoped>
.p-datatable :deep(.p-datatable-tbody > tr .table-td-total-amount) {
  background-color: var(--surface-b) !important;
}
</style>
