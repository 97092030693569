<template>
  <base-toolbar
    :title="this.title"
    :dataButtons="dataButtons"
    @goBack="goBack()"
    @gotoCreate="gotoEdit('create')"
    @autoCreate="autoCreate()"
    :hideBackButton="isHideBack"
  ></base-toolbar>
  <base-dialog
    v-model:visible="showConfirmDialog"
    :dataButtons="dataButtonDialog"
    :titleConfirm="confirmTitle"
    :wordingConfirm="confirmWording"
    @confirm="showConfirmDialog = false"
    @close="showConfirmDialog = false"
  ></base-dialog>

  <base-dialog
    v-model:visible="showNotiDialog"
    :titleConfirm="titleConfirmDialog"
    :wordingConfirm="contentConfirmDialog"
    :classTitle="confirmClassTitle"
    :dataButtons="dataButtonDialog"
    @close="showNotiDialog = false"
  ></base-dialog>
  <base-container>
    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5">
      <div
        class="flex justify-content-between align-items-center"
      >
        <h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
      </div>
      <PvDivider></PvDivider>
      <PVTabView :activeIndex="indexTabView" @tab-click="getMeterList($event.index)">
        <PVTabPanel>
          <template #header>
            <span>{{ lang.waterType }}</span>
          </template>
        </PVTabPanel>
        <PVTabPanel>
          <template #header>
            <span>{{ lang.electricityType }}</span>
          </template>
        </PVTabPanel>
        <PVTabPanel>
          <template #header>
            <span>{{ lang.coolingAirType }}</span>
          </template>
        </PVTabPanel>
      </PVTabView>
      <section
        class="flex flex-wrap justify-content align-items-center pt-2"
        :class="textFormClass"
      >
        <div class="lg:col-3 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            id="filterSearch"
            class="inputfield w-full mr-2"
            :options="filterSearch"
            optionLabel="name"
            optionValue="code"
            v-model="selectedFilterSearch"
            scrollHeight="300px"
            @change="changeFilterSearch()"
          />
        </div>

        <div class="flex lg:col-6 md:col-12 col-12 p-0 mr-2" v-if="!isSearchAll">
          <div class="col-12 pl-0 pr-0">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchText"
                :placeholder="lang.searchTextPlaceholder"
                @keyup.enter="getMeterList()"
                autofocus
                :disabled="selectedFilterSearch === 'all'"
                :class="{ 'p-inputtext-disabled': selectedFilterSearch === 'all' }"
              />
            </span>
          </div>
        </div>

        <PvButton @click="getMeterList()">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>
      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="meters"
          dataKey="id"
          v-model:selection="selectedMeterData"
          selectionMode="single"
          responsiveLayout="scroll"
          :class="textTableClass"
          class="p-datatable-xs"
          :loading="isLoading"
          @row-dblclick="gotoEdit(selectedMeterData.id)"
        >
          <template #empty>
            <div
              style="height: 56px"
              class="text-center flex align-items-center justify-content-center w-full"
            >
              <label class="font-normal">
                {{ lang.tableEmpty }}
              </label>
            </div>
          </template>
          <PvColumn
            field="code"
            :header="lang.columnCode"
            headerClass="h-center"
            bodyClass="text-left"
            style="height: 56px"
            sortable
          />
          <PvColumn
            field="unitCode"
            :header="lang.columnUnitCode"
            headerClass="h-center"
            bodyClass="text-center"
          />
          <PvColumn
            field="addressNo"
            :header="lang.columnAddressNo"
            headerClass="h-center"
            bodyClass="text-center"
          />
          <PvColumn
            field="isActive"
            :header="lang.columnActive"
            headerClass="h-center"
            bodyClass="text-center"
          />
          <PvColumn
            field="activeDate"
            :header="lang.columnActiveDate"
            headerClass="h-center"
            bodyClass="text-center"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.activeDate) }}
            </template>
          </PvColumn>
          <PvColumn
            field="remark"
            :header="lang.columnRemark"
            headerClass="h-center"
            bodyClass="text-center"
          />
          <PvColumn
            headerClass="h-center"
            bodyClass="text-center"
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
            </template>
          </PvColumn>
          <!-- <PvColumn
            :header="lang.columnDeleteMeter"
            headerClass="h-center"
            bodyClass="text-center"
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-danger p-button-text p-0"
                @click="askForDeleteMeter(slotProps.data.id)"
              >
                <icon-trash-delete-bin iconColor="error" />
              </PvButton>
            </template>
          </PvColumn> -->

          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              :first="showCurrentPage"
            >
              <template #start="slotProps">
                {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{
                  totalPage
                }}
              </template>
            </PvPaginator>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>
<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
export default {
  props: ["meterType", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const isHideBack = inject("isHideBack");
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.master.meter.listing };
      } else {
        return { ...lang.en.base, ...lang.en.master.meter.listing };
      }
    });

    const { getPreviewBlob, getDownloadBlob } = useGetFormPrint();

    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getPreviewBlob,
      getDownloadBlob,
      isHideBack,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
    };
  },

  data() {
    return {
      showConfirmDialog: false,
      confirmTitle: "",
      confirmWording: "",
      selectedPage: 1,
      rowsPerPage: 10,
      searchText: null,
      visiblePreview: false,
      selectedMeterData: null,
      dataMobileTable: [],
      isLoading: false,
      idMeter: null,
      isLoadingButton: false,
      //Search
      filterSearch: [
        { name: this.lang.allData, code: "all" },
        { name: this.lang.columnCode, code: "code" },
        { name: this.lang.columnUnitCode, code: "unitNo" },
        { name: this.lang.columnAddressNo, code: "addressNo" },
      ],
      selectedFilterSearch: "all",
      searchCode: null,
      searchUnit: null,
      searchAddressNo: null,
      storageName: "",
      showNotiDialog: false,
      titleConfirmDialog: "",
      contentConfirmDialog: "",
      confirmClassTitle: "",
    };
  },
  async mounted() {
    this.storageName = `erp_search_MeterList_${this.selectedProject.code}_${this.userCode}`;

    await this.$store.dispatch("meter/resetMeterForAutoCreateByProject");
    this.setLocalSearch();
    await this.getMeterList();
    await this.getForAutoCreateByProject();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    gotoEdit(id) {
      if(!this.isRoleSelect && id != "create") {
        this.confirmTitle = this.lang.notificationDialogHeader;
        this.confirmWording = this.lang.validateRoleSelect;
        this.showConfirmDialog = true;
      } else if (!this.isRoleCreate && id == "create") {
        this.confirmTitle = this.lang.notificationDialogHeader;
        this.confirmWording = this.lang.validateRoleInsert;
        this.showConfirmDialog = true;
      } else {
        this.$router.push({name: "master-meter-edit", params:{ meterType: this.meterType, id: id }});
      }
    },
    gotoCreate() {
      this.$router.push({name: "master-meter-edit", params:{ meterType: this.meterType, id: "create" }});
    },
    setvisibleSidebar(bool) {
      this.visiblePreview = bool;
    },
    async getMeterList(index) {
      this.isLoading = true;
      let type = this.meterType;
      if (index === 0) {
        this.$router.replace({name: "master-meter", params:{ meterType: "W" }});
        type = "W";
      }
      if (index === 1) {
        this.$router.replace({name: "master-meter", params:{ meterType: "E" }});
        type = "E";
      }
      if (index === 2) {
        this.$router.replace({name: "master-meter", params:{ meterType: "A" }});
        type = "A";
      }

      try {
        if (this.validateSearchButton) {
          this.openAlertDialog({
            severity: "",
            title: this.lang.notificationDialogHeader,
            content: this.lang.validateSearchText,
          });
          this.isLoading = false;
          return;
        }
        this.setDataSearch();

        let where = {
          projectId: this.selectedProject.id,
          type: type,
          code: this.searchCode || null,
          unitCode: this.searchUnit || null,
          addressNo: this.searchAddressNo || null,
        };

        const payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          where,
        };

        let local = {
          ...where,
          rowsPerPage: this.rowsPerPage,
          selectedPage: this.selectedPage,
        };
        localStorage.setItem(this.storageName, JSON.stringify(local));

        // console.log("getPurchaseReceiptList", payload);
        await this.$store.dispatch("meter/getMeterList", payload);
        this.isLoading = false;
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
        });
        this.isLoading = false;
      }
    },
    setDataSearch() {
      if (this.selectedFilterSearch === "all") {
        this.searchText = "";
      } else if (this.selectedFilterSearch === "code") {
        this.searchCode = this.searchText;
      } else if (this.selectedFilterSearch === "unitNo") {
        this.searchUnit = this.searchText;
      } else if (this.selectedFilterSearch === "addressNo") {
        this.searchAddressNo = this.searchText;
      }
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getMeterList();
    },
    askForDeleteMeter(id) {
      this.idMeter = id;
      this.showConfirmDialog = true;
      this.confirmTitle = this.lang.notificationDialogHeader;
      this.confirmWording = this.lang.askForDeleteMeter;
    },
    async deleteMeter() {
      this.setLoading(true);
      // console.log("deleteMeter", this.idMeter);
      let payload = {
        id: this.idMeter,
        projectId: this.selectedProject.id,
        meterType: this.meterType,
      };

      try {
        await this.$store.dispatch("meter/deleteMeter", payload);
        this.setLoading(false);
        await this.getMeterList();
      } catch (error) {
        let massage = error.massage;
        if (error.code == "126") {
          massage = this.lang.errorMeterUsage;
        } else if (error.code == "116") {
          massage = this.lang.validateRoleDelete;
        }
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: massage,
        });
        this.setLoading(false);
      }
      this.setLoading(false);
      this.showConfirmDialog = false;
    },
    autoCreate() {
      // console.log("autoCreate");
      if (this.isRoleCreate) {
        this.$router.replace({name: "master-meter-auto-create", params:{ meterType: this.meterType }});
      } else {
        this.confirmTitle = this.lang.notificationDialogHeader;
        this.confirmWording = this.lang.validateRoleInsert;
        this.showConfirmDialog = true;
      }
    },
    async getForAutoCreateByProject() {
      this.isLoadingButton = true;
      try {
        await this.$store.dispatch(
          "meter/getAllForAutoCreateByProject",
          this.selectedProject.id
        );
        // console.log(this.meterForAutoCreates);
        this.isLoadingButton = false;
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
        });
        this.isLoadingButton = false;
      }
    },
    changeFilterSearch() {
      this.searchText = "";
      this.searchCode = null;
      this.searchUnit = null;
      this.searchAddressNo = null;
    },
    openAlertDialog(dataConfig) {
      if (dataConfig.severity === "") {
        this.confirmClassTitle = "";
      } else if (dataConfig.severity === "success") {
        this.confirmClassTitle = "p-text-success";
      } else if (dataConfig.severity === "error") {
        this.confirmClassTitle = "p-error";
      }
      this.titleConfirmDialog = dataConfig.title;
      this.contentConfirmDialog = dataConfig.content;
      this.showNotiDialog = true;
    },
    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      if (localSearch) {
        let search = JSON.parse(localSearch);

        if (search.entry === "entry") {
          if (search.code != null && search.code != "") {
            this.selectedFilterSearch = "code";
            this.searchText = search.code;
          } else if (search.unitNo != null && search.unitNo != "") {
            this.selectedFilterSearch = "unitNo";
            this.searchText = search.unitNo;
          } else if (search.addressNo != null && search.addressNo != "") {
            this.selectedFilterSearch = "addressNo";
            this.searchText = search.addressNo;
          } else {
            this.selectedFilterSearch = "all";
            this.searchText = "";
          }

          this.rowsPerPage = search.rowsPerPage;
          this.selectedPage = search.selectedPage;

          search.entry = "";

          localStorage.setItem(this.storageName, JSON.stringify(search));
        }
      }
    },
  },
  computed: {
    title() {
      return this.lang.title;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    totalPage() {
      return this.$store.getters["meter/totalPage"];
    },
    currentPage() {
      return this.$store.getters["meter/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    meters() {
      return this.$store.getters["meter/meters"];
    },
    indexTabView() {
      if (this.meterType === "W") return 0;
      else if (this.meterType === "E") return 1;
      else if (this.meterType === "A") return 2;
      else return 0;
    },
    meterForAutoCreateByProject() {
      return this.$store.getters["meter/meterForAutoCreateByProject"].filter(
        (meterForAutoCreate) => {
          return meterForAutoCreate.productCode
            .toLowerCase()
            .includes(this.meterType.toLowerCase());
        }
      );
    },
    dataButtons() {
      let isLoading = true;
      let isDisable = true;
      if (!this.isLoadingButton) {
        isLoading = false;
      }
      if (this.meterForAutoCreateByProject.length > 0) {
        isDisable = false;
      }
      return [
        {
          id: "editCaption",
          caption: this.lang.createMeter,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
        },
        {
          id: "autoCreate",
          caption: this.lang.autoCreateMeter,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "autoCreate",
          isShow: true,
          isLoading: isLoading,
          isDisable: isDisable,
        },
      ];
    },
    dataButtonDialog() {
      return [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
    },
    validateSearchButton() {
      if (this.selectedFilterSearch !== "all" && !this.searchText) {
        return true;
      }
      return false;
    },
    showCurrentPage() {
      let page = 0;
      page = this.rowsPerPage * (this.selectedPage - 1);
      return page;
    },
    isSearchAll() {
      if (this.selectedFilterSearch == "all") {
        return true;
      }
      return false;
    },
    roleByMenu() {
      if(this.meterType == "W") {
        return this.getRoleByMenuByCode("tmMeterW");
      } else if (this.meterType == "E") {
        return this.getRoleByMenuByCode("tmMeterE");
      } else {
        return this.getRoleByMenuByCode("tmMeterC");
      }
    },
    isRoleCreate() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    isRoleSelect() {
      if (this.roleByMenu && this.roleByMenu.select == "Y") {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
.h-table {
  height: 56px;
}
.p-pagination-radius {
  border-radius: 0 0 12px 12px;
}
</style>
