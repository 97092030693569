export default {	
	titleToolbar: "ทะเบียนรายจ่าย",
	title: "รายการรายจ่าย (สินค้าและบริการ)",
	createProductAp: "สร้างสินค้าและบริการ",
	columnCode: "รหัสรายจ่าย",
	columnNameTh: "สินค้าและบริการ (ภาษาไทย)",
	columnNameEn: "สินค้าและบริการ (ภาษาอังกฤษ)",
	columnType: "สถานะใช้งาน",
	columnCreateBy: "ผู้สร้าง",
	columnUpdateBy: "ผู้แก้ไข",
	columnCreateDate:"วันที่สร้าง",
	columnUpdateDate:"วันที่แก้ไขล่าสุด", 	
    active: "ใช้งาน",
    inActive: "ไม่ใช้งาน",
	deleteProductAp: "ลบรายการรายจ่าย", 
	askForDeleteProductAp: "คุณต้องการลบรายการรายจ่าย :code ใช่หรือไม่?",
	deleteProductApSuccess: "คุณลบรายการรายจ่าย :code สำเร็จ", 	
    all: "ทั้งหมด",
    code: "รหัส",
    name: "ชื่อ",
};
