export default function useUploadImageValidate() {
  const validateImageFileType = (
    file,
    allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"]
  ) => {
    if (file && !allowedTypes.includes(file.type)) {
      return true;
    }
    return false;
  };

  const validateImageFileSize = (file, maxSize = 50 * 1024) => { // default max size = 50 KB
    if (file && file.size > maxSize) {
      return true;
    }
    return false;
  };

  return {
    validateImageFileType,
    validateImageFileSize,
  };
}
