<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '70vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showErrorDialog"
      :classTitle="classTitleErrorDialog"
      :titleConfirm="titleHeaderErrorDialog"
      :wordingConfirm="contentErrorDialog"
      :dataButtons="dataButtonDialog"
      @close="showErrorDialog = false"
    ></base-dialog>

    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">{{ lang.uploadFile }}</label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          :disabled="shouldDisableCloseButton"
          @click="closeDialog"
        />
      </div>
    </template>

    <div class="dropZone">
      <input
        type="file"
        id="dropZone-file"
        class="dropZone-file"
        ref="dropZoneFile"
        @click="resetFile"
        @change="handleFiles"
        multiple
        accept=".txt,.bpd"
      />
      <div
        class="dropZone-wrapper"
        @dragenter.prevent=""
        @dragover.prevent=""
        @drop.prevent="handleFiles"
      >
        <label for="dropZone-file" class="cursor-pointer">
          <div class="pt-6"></div>
          <PvButton
            class="cursor-pointer p-button-rounded show-icon"
            disabled="disabled"
          >
            <icon-cloud-storage-upload iconColor="white" />
          </PvButton>
          <div class="cursor-pointer dropZone-label-text pt-4">
            <label
              for="dragFile"
              class="cursor-pointer font-md p-text-secondary"
              >{{ lang.dragFile }}</label
            >
            <label
              for="addFile"
              class="cursor-pointer font-md p-text-primary"
              @click="addFile"
              >{{ lang.addFile }}</label
            >
            <input
              id="add-file"
              type="file"
              style="display: none"
              accept=".txt,.bpd"
              @click="resetFile"
              @change="handleFiles"
              class="cursor-pointer"
            />
          </div>
          <div class="cursor-pointer font-xs p-text-secondary pt-2 pb-8">
            <label for="importFileDesc" class="cursor-pointer">{{
              lang.importFileDesc
            }}</label>
          </div>
        </label>
      </div>
    </div>

    <div class="my-5 pt-6">
      <PvDataTable
        :value="files"
        dataKey="id"
        responsiveLayout="scroll"
        class="p-datatable-xs"
        :class="textTableClass"
      >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
        <PvColumn style="width: 87px; height: 56px" headerClass="h-center">
          <template #body>
            <PvButton
              v-if="haveFile"
              type="button"
              class="p-button-secondary p-button-text"
              style="opacity: 1"
              disabled="disabled"
            >
              <icon-documents-file-checkmark iconColor="secondary" />
            </PvButton>
          </template>
        </PvColumn>

        <PvColumn
          field="detailName"
          :header="lang.fileName"
          style="min-width: 12rem"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-left font-normal p-text-primary"
        >
        </PvColumn>

        <PvColumn
          style="width: 2rem; height: 56px"
          headerClass="h-center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="">
            <PvButton
              v-if="haveFile"
              type="button"
              class="p-button-danger p-button-text p-0"
              @click="deleteFile"
            >
              <icon-trash-delete-bin iconColor="error" />
            </PvButton>
          </template>
        </PvColumn>
      </PvDataTable>
    </div>

    <div class="flex justify-content-center pt-4">
      <PvButton
        class="w-3"
        @click="uploadFile"
        :disabled="checkDisable"
        :loading="isUpLoading"
      >
        <span class="w-full text-center font-md font-normal p-button-label">
          <i
            v-if="isUpLoading"
            class="pi pi-spin pi-spinner font-normal mr-1"
          ></i>
          {{ lang.uploadFile }}
        </span>
      </PvButton>
    </div>
    <template #footer></template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import { useStore } from "vuex";
import { computed } from "vue";
import { b256MultipleDays } from "@bellpetchpol/thailand-billpayment-txt-to-object";
import useGetRoleByBook from "../../../hooks/getRoleByBook.js";
import useFormat from "../../../hooks/format.js";
export default {
  emits: ["close", "data-upload"],
  setup() {
    const { lang } = useLang();
    const { textTableClass } = useTextStyle();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.receipt.billPayment,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.receipt.billPayment,
        };
      }
    });

    const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();

    const { formatDateToISOString, formatDateTime } = useFormat();

    return {
      lang: mergeLang,
      textTableClass,
      getRoleByBookByCode,
      isSharedRoleByBookReady,
      formatDateToISOString,
      formatDateTime,
    };
  },
  data() {
    return {
      uploadFiles: [],
      isUpLoading: false,

      files: [{}],
      file: null,
      haveFile: false,

      dataImport: [],
      details: [],

      classTitleErrorDialog: "",
      titleHeaderErrorDialog: this.lang.notificationDialogHeader,
      contentErrorDialog: "",
      dataButtonDialog: [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ],
      showErrorDialog: false,

      unitImportBillPayments: [],
      receiptImportBillPayments: [],
      invoiceImportBillPayments: [],
      tempDataResultForImport: [],
    };
  },
  methods: {
    setLoading(bool) {
      this.isUpLoading = bool;
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    initiateData() {
      this.files = [{}];
      this.haveFile = false;
      this.file = null;

      this.dataImport = [];
      this.details = [];

      this.classTitleErrorDialog = "";
      this.titleHeaderErrorDialog = this.lang.notificationDialogHeader;
      this.contentErrorDialog = "";
      this.dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
      this.showErrorDialog = false;
    },
    addFile() {
      document.getElementById("add-file").click();
    },
    deleteFile() {
      this.initiateData();
    },
    resetFile(event) {
      if (event.target.files.length > 0) {
        event.target.value = null;
      }
    },
    async handleFiles(event) {
      const inputValue = event.target.files || event.dataTransfer.files;
      this.uploadFiles = Array.from(inputValue);

      //เช็คจำนวนไฟล์
      if (this.validateFileCount()) {
        return;
      }

      this.file = inputValue[0];

      //เช็คประเภทไฟล์
      if (this.validateFileType()) {
        return;
      }

      this.haveFile = true;
      let fileName = this.file ? this.file.name : "";
      this.files = [
        {
          detailName: fileName,
        },
      ];
    },
    validateFileCount() {
      let check = this.uploadFiles.length;
      if (check > 1) {
        this.openDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateLimitFile,
          isConfirm: false,
        });

        return true;
      }
    },
    validateFileType() {
      if (this.file.type != "text/plain" && this.file.type != "") {
        this.openDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateImportFile,
          isConfirm: false,
        });

        return true;
      }
    },
    openDialog(dataConfig) {
      if (dataConfig.severity === "") {
        this.classTitleErrorDialog = "";
      } else if (dataConfig.severity === "success") {
        this.classTitleErrorDialog = "p-text-success";
      } else if (dataConfig.severity === "error") {
        this.classTitleErrorDialog = "p-error";
      }
      this.titleHeaderErrorDialog = dataConfig.title;
      this.contentErrorDialog = dataConfig.content;
      this.showErrorDialog = true;
    },
    async uploadFile() {
      this.setLoading(true);
      let retried = false;
      let digit = 1;
      let fileReader = new FileReader();
      fileReader.onload = async () => {
        if (fileReader.result.includes("�") && !retried) {
          retried = true;
          digit = 2;
          fileReader.readAsText(this.file, "ISO8859-11");
        } else {
          await this.convertTextToObject(fileReader.result, digit);
          this.setLoading(false);
        }
      };
      fileReader.readAsText(this.file);
    },
    async convertTextToObject(result, digit) {
      try {
        this.dataImport = b256MultipleDays(result, true, digit);
      } catch (error) {
        this.openDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: [this.lang.validateBOTFormat, this.lang.pleaseReloadAgain],
          isConfirm: false,
        });
        this.setLoading(false);
      }

      if (this.dataImport.length == 0) {
        this.openDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: this.lang.dataNotFound +" "+ this.lang.pleaseTryAgain,
          isConfirm: false,
        });
        this.setLoading(false);
        return;
      }
      
      await this.getBookBank();
      if (this.validateBookBank()) {
        return;
      }
      await this.getAllDetails();
      await this.validateAndPrepareData();
    },
    async getBookBank() {
      await this.$store.dispatch("bookBank/resetSelectedBookBank");
      const data = this.dataImport[0];
      try {
        const where = {
          projectId: this.selectedProject.id,
          bookNo: data.companyAccountNo ? data.companyAccountNo.trim() : "",
        };

        await this.$store.dispatch("bookBank/getBookBank", { where });
      } catch (error) {
        this.openDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.setLoading(false);
      }
    },
    validateBookBank() {
      if (!this.bookBank) {
        this.openDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: [this.lang.validateBookBank, this.lang.pleaseTryAgain],
          isConfirm: false,
        });
        return true;
      }
      return false;
    },
    async validateAndPrepareData() {
      await this.getUnits();
      await this.getReceipts();
      await this.getInvoices();
      if (this.isUpLoading) {
        await this.prepareData();
      } else {
        return;
      }
    },
    getFields(input, field) {
      var output = [];
      for (var i = 0; i < input.length; ++i) output.push(input[i][field]);
      return output;
    },
    removeDuplicates(arr) {
      return arr.filter((item, index) => arr.indexOf(item) === index);
    },
    async getUnits() {
      try {
        //get ข้อมูลเฉพาะ reference1
        let units = this.getFields(this.details, "reference1");
        //remove ข้อมูลซ้ำ
        units = this.removeDuplicates(units);

        let loopLength = 50;

        while (units.length > 0) {
          if (units.length < loopLength) {
            loopLength = units.length;
          }

          let spliceData = units.splice(0, loopLength);

          let payload = {
            projectId: this.selectedProject.id,
            ref1: spliceData,
          };

          await this.$store.dispatch(
            "arReceiptBillPayment/getUnitImportBillPayment",
            payload
          );

          this.unitImportBillPayments = [
            ...this.unitImportBillPayments,
            ...this.units,
          ];
        }
      } catch (error) {
        this.openDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.setLoading(false);
      }
    },
    async getReceipts() {
      try {
        await this.$store.dispatch(
          "arReceiptBillPayment/resetReceiptMapBillPayment"
        );

        let details = [];
        this.details.filter((data) => {
          if (!this.validateDateTime(data.paymentDate)) {
            details.push(data);
          }
        });
        let paymentDate = this.getFields(details, "paymentDate");
        paymentDate = this.removeDuplicates(paymentDate);

        let payload = {
          documentBookId: this.roleByDocumentBook.documentBookId,
          paydate: paymentDate,
        };

        await this.$store.dispatch(
          "arReceiptBillPayment/getAllReceiptMapBillPayment",
          payload
        );

        this.receiptImportBillPayments = this.receipts;
      } catch (error) {
        this.openDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.setLoading(false);
      }
    },
    async getInvoices() {
      try {
        //get ข้อมูลเฉพาะ reference2
        let invoices = this.getFields(this.details, "reference2");
        //remove ข้อมูลซ้ำ
        invoices = this.removeDuplicates(invoices);

        let loopLength = 50;

        while (invoices.length > 0) {
          if (invoices.length < loopLength) {
            loopLength = invoices.length;
          }

          let spliceData = invoices.splice(0, loopLength);

          let payload = {
            projectId: this.selectedProject.id,
            ref2: spliceData,
          };

          await this.$store.dispatch(
            "arReceiptBillPayment/getInvoiceImportBillPayment",
            payload
          );

          this.invoiceImportBillPayments = [
            ...this.invoiceImportBillPayments,
            ...this.invoices,
          ];
        }
      } catch (error) {
        this.openDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.setLoading(false);
      }
    },
    async getUniqueKey() {
      try {
        await this.$store.dispatch("arReceipt/getReceiptUniqueKey");
      } catch (error) {
        this.openDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.setLoading(false);
      }
    },
    async mapingData(data) {
      //เตรียมข้อมูล receipt header
      let payTime = String(data.paymentTime.match(/\d{2}/g).join(":"));
      let payDateTime = `${data.paymentDate}T${payTime}`;

      let header = {
        status: "wait",
        reason: "",
        unitCode: "",
        ref1: data.reference1,
        ref2: data.reference2,
        totalAmount: data.amount,
        // paidBy: data.customerName,
        paidBy: "",
        payDate: data.paymentDate,
        payDateTime: payDateTime,
        projectId: this.selectedProject.id,
        receiptUniqueKeyId: "",
        receiptId: "",
        refCode: "",
        date: this.formatDateToISOString(new Date()),
        customerId: "",
        employeeId: this.selectedEmployee.id,
        paidByAddress: "",
        unitId: "",
        payType: "Transfer",
        payReference: "",
        payBookBankCode: this.bookBank.code,
        payBookBankBranch:
          this.bookBank.bankName + " : " + this.bookBank.branchName,
        bookBankNumber: data.companyAccountNo.trim(),
        remark3: this.lang.paymentTime.replace(":time", payTime),
        remark4: "",
        shouldCreateCheque: false,
        shouldUseDeposit: false,
        shouldUseGuarantee: false,
        guaranteeDetailId: "",
        isLockDocument: "Y",
        markStatus: "1",
        details: [],
        receiptMapBillPayment: null,
      };

      //เช็คข้อมูล DateTime
      if (this.validateDateTime(payDateTime)) {
        header.status = "invalid";
        header.reason = "date";
        header.payDateTime = "-";
      }

      //เช็คข้อมูล unit
      let unit = this.unitImportBillPayments.filter(
        (unit) => unit.qrpayment == data.reference1
      )[0];

      if (!unit) {
        //getUniqueKey เฉพาะ row ที่ต้องสร้างใหม่เท่านั้น
        await this.getUniqueKey();
        header.receiptUniqueKeyId = this.receiptUniqueKey.id;
        header.status = "invalid";
        header.reason = "unit";
        return header;
      }

      header.unitId = unit.unitId;
      header.unitCode = unit.unitCode;
      header.customerId = unit.customerId;

      //เช็คข้อมูล receipt
      let receipt = this.receiptImportBillPayments.filter(
        (receipt) =>
          receipt.ref1 == data.reference1 &&
          receipt.ref2 == data.reference2 &&
          receipt.amount == data.amount &&
          this.formatDateToISOString(new Date(receipt.paydate)) ==
            data.paymentDate &&
          receipt.bookBankNumber == data.companyAccountNo.trim()
      )[0];

      if (receipt) {
        header.receiptId = receipt.receiptId;
        header.status = "success";
        header.reason = receipt.type;
        return header;
      }
      //getUniqueKey เฉพาะ row ที่ต้องสร้างใหม่เท่านั้น
      await this.getUniqueKey();
      header.receiptUniqueKeyId = this.receiptUniqueKey.id;
      //เช็คข้อมูล invoice
      let invoice = this.invoiceImportBillPayments.filter(
        (invoice) =>
          invoice.code == data.reference2 && 
          invoice.unitId == header.unitId
      )[0];

      if (!invoice) {
        header.status = "invalid";
        header.reason = "invoice";
        return header;
      } 

      let invoiceAmount = 0
      if (invoice.invoiceDetails.length > 0) {
        invoice.invoiceDetails.forEach((detail) => invoiceAmount = invoiceAmount + detail.pendingAmount);
      }

      if (invoiceAmount != data.amount) {
        header.status = "invalid";
        header.reason = "amount";
        return header;
      }

      header.refCode = "Bill No: BILL /" + invoice.code;

      //loop ปั้นข้อมูล detail
      invoice.invoiceDetails.forEach((detail) => {
        let result = {
          productId: detail.productId,
          productUnitId: detail.productUnitId,
          unitPrice: detail.unitPrice,
          quantity: detail.quantity,
          discountDescription: "",
          discountAmount: 0,
          totalAmount: detail.totalAmount,
          remark: detail.remark,
          period: detail.period,
          referenceId: detail.id,
          tenantId: "",
          invoiceAmount: detail.pendingAmount,
        };
        header.details.push(result);
      });

      header.receiptMapBillPayment = {
        ref1: data.reference1,
        ref2: data.reference2,
        amount: data.amount,
        type: "auto",
        payDate: data.paymentDate,
        bookBankNumber: data.companyAccountNo.trim(),
      };

      return header;
    },
    async prepareData() {
      let rawData = this.details;
      let dataResult = [];

      let i = 0;
      for (const data of rawData) {
        let result = await this.mapingData(data);
        if (result) {
          i++;
          result = { ...result, id: i };
          dataResult.push(result);
        }
      }

      await this.$store.dispatch(
        "arReceiptBillPayment/setDataBillPayments",
        dataResult
      );
      this.$emit("data-upload");
      this.initiateData();
    },
    async getAllDetails() {
      for (const data of this.dataImport) {
        this.details = [...this.details, ...data.details];
      }
    },
    validateDateTime(data) {
      let result = this.formatDateTime(data);
      if (result.includes("NaN")) {
        return true;
      }
      return false;
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    shouldDisableCloseButton() {
      return this.isUpLoading;
    },
    checkDisable() {
      if (!this.haveFile || this.isUpLoading) {
        return true;
      }
      return false;
    },
    roleByDocumentBook() {
      return this.getRoleByBookByCode("INV");
    },
    bookBank() {
      return this.$store.getters["bookBank/selectedBookBank"];
    },
    units() {
      return this.$store.getters["arReceiptBillPayment/units"];
    },
    receipts() {
      return this.$store.getters["arReceiptBillPayment/receipts"];
    },
    invoices() {
      return this.$store.getters["arReceiptBillPayment/invoices"];
    },
    selectedEmployee() {
      return this.$store.getters.employee;
    },
    receiptUniqueKey() {
      return this.$store.getters["arReceipt/receiptUniqueKey"];
    },
  },
};
</script>
<style scoped>
.show-icon {
  opacity: 1;
  width: 48px;
  height: 48px;
  padding: 0.75rem;
}
</style>
