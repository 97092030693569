export default {
	title: "ทะเบียนมิเตอร์",
	createMeter: "สร้างมิเตอร์",
	autoCreateMeter: "สร้างอัตโนมัติ",

	columnCode: "รหัสมิเตอร์",
	columnUnitCode: "รหัสห้อง/บ้าน",
	columnAddressNo: "บ้านเลขที่",
	columnActive: "สถานะ",
	columnActiveDate: "วันที่ติดตั้ง",
	columnRemark: "หมายเหตุ",
	columnProductCode: "รหัสสินค้า",
	columnProductName: "ชื่อสินค้า",
	columnEditMeter: "แก้ไขมิเตอร์",
	columnDeleteMeter: "ลบมิเตอร์",
	askForDeleteMeter: "คุณยืนยันที่จะ ลบมิเตอร์ ใช่หรือไม่?",
	errorMeterUsage: "ทำรายการไม่ได้ เนื่องจากมีข้อมูลอ้างถึง",

	waterType: "ระบบน้ำประปา",
	electricityType: "ระบบไฟฟ้า",
	coolingAirType: "ระบบทำความเย็น",
};
