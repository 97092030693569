export default {
  resetImportStockKeycards(context) {
    context.commit("resetImportStockKeycards");
  },
  importStockKeycards(context, payload) {
    context.commit("setImportStockKeycards", payload);
  },
  async getProductImportStockKeycard(context, payload) {
    let url =
      process.env.VUE_APP_BACKEND_URL + "/Product/GetProductImportStockKeycard";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get products");
      throw error;
    }
    // console.log("getProductImportStockKeycard",responseData);

    context.commit("setProducts", responseData);
  },
  async getUnitImportStockKeycard(context, payload) {
    let url =
      process.env.VUE_APP_BACKEND_URL + "/Unit/GetUnitImportStockKeycard";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get units");
      throw error;
    }
    // console.log("getUnitImportStockKeycard",responseData);

    context.commit("setUnits", responseData);
  },
  async getRunningNumberImportStockKeycard(context, payload) {
    let url =
      process.env.VUE_APP_BACKEND_URL + "/StockKeycard/GetRunningNumberImportStockKeycard";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get running number");
      throw error;
    }
    // console.log("getRunningNumberImportStockKeycard",responseData);

    context.commit("setRunningNumbers", responseData);
  },
};
