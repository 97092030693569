export default {
    title: "Form Version Setting",
    add: "Add",
    projectCode: "Project Code",
    projectName: "Project Name",
    menuCode: "Menu Code",
    menuName: "Menu Name",
    formVersion: "Form Version",
    jasperReport: "Jasper Report",
    fastReport: "Fast Report",
    createBy: "Create By",
    createDate: "Create Date",
    updateBy: "Update By",
    updateDate: "Update Date",
    searchText: "Fill up to search",
    addFormVersion: "Add Form Version",
    editFormVersion: "Edit Form Version",
    project: "Project",
    validateProject: "Select project",
    menuInvoice: "Invoice",
    menuNotice: "Notice",
    menuReceipt: "Receipt",
    menuCreditNote: "CreditNote",
    menuDeposit: "Deposit",
    menuDeptfree: "Deptfree",
    menuDeptfreeRequest: "DeptfreeRequest",
    menu: "Menu",
    validateMenu: "Select menu",
}