export default {
    setMeters(state, payload){
        state.meters = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },
    setMeterSelected(state, payload){
        state.meterSelected = payload
    },
    setMeterToGls(state, payload){
        state.meterToGl = payload.data
    },
    getMeterSelected(state, payload){
        state.meterSelected = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },
};