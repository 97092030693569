export default {
    backTitle: "แก้ไขใบตั้งเบิก",
    title: "รายละเอียดใบตั้งเบิก",
    withholdingTaxButton: "ภาษีหัก ณ ที่จ่าย",
    paymentButton: "รายการจ่าย",
    settingGL: "ตั้งค่าบันทึกบัญชี",
    autoGL: "บันทึกบัญชี",
    activeLockStatus: "เอกสารถูกล็อก",
    activeUnlockStatus: "เอกสารไม่ถูกล็อก",

    code: "เลขที่เอกสาร",
    paymentRequestType: "ประเภทตั้งเบิก",
    budgetYear: "ปีงบประมาณ",
    supplier: "ผู้จัดจำหน่าย/ผู้เบิก*",
    remark: "รายละเอียด",

    detail: "รายการตั้งเบิก",
    addDetail: "เพิ่มรายการ",

    columnItemNo: "ลำดับ",
    columnProductCode: "รหัสรายจ่าย",
    columnProductName: "ชื่อรายจ่าย",
    columnBudgetWithin: "งบ ยอดรอเบิก",
    columnRemark: "รายละเอียด",
    columnPeriod: "งวด",
    columnPrice: "ราคาต่อหน่วย",
    columnQuantity: "จำนวน",
    columnCode: "เลขที่เอกสาร",

    totalAmount: "มูลค่ารวมทุกรายการ",
    discountText: "ส่วนลดท้ายบิล",
    discountTip: "*คุณสามารถระบุเป็นเปอร์เซ็นต์ได้ เช่น 10%",
    discountAmount: "มูลค่าส่วนลดรวม",
    beforeVat: "มูลค่าก่อนภาษี",
    vatRate: "อัตราภาษี",
    vatAmount: "มูลค่าภาษี",
    vatCalculatedFromSystem: "*ค่าคำนวณที่ระบบแนะนำ : ",
    netAmount: "มูลค่าสุทธิ",
    taxAmount: "ภาษีหัก ณ ที่จ่าย",
    paymentAmount: "มูลค่าจ่ายชำระ",
    validateCheckDiscount: "รูปแบบส่วนลด ระบุได้เฉพาะตัวเลข และ %",
    validateDiscountAmount: "มูลค่าส่วนลด ต้องไม่เกินมูลค่าสุทธิ",
    validateNoSupplierSelect: "กรุณาเลือกผู้จัดจำหน่าย",
    validateDetailSelect: "กรุณาเลือกรายการ",

    titleDetailDialog: "เพิ่ม/แก้ไขรายการ",
    validateNoProductSelect: "กรุณาเลือกรายรับ",
    validateNoPrice: "กรุณาระบุราคา",
    validateNoQuantity: "กรุณาระบุจำนวน",
    product: "รายจ่าย*",
    price: "ราคา*",
    quantity: "จำนวน*",
    discountDetail: "ส่วนลด",
    discountHeader: "ส่วนลดท้ายบิล",
    totalAmountDetail: "ยอดรวม",
    titleCompareBudget: "เปรียบเทียบงบประมาณ",
    expenseAccumulated: "ตั้งเบิกสะสม",
    expenseBalance: "ตั้งเบิกคงเหลือ",
    paymentAccumulated: "เกิดจริงสะสม",
    paymentBalance: "เกิดจริงคงเหลือ",
    accountCompare: "งบประมาณที่เปรียบเทียบ",

    withinBudget: "อยู่ในงบ",
    overBudget: "เกินงบ",
    budgetNotfound: "ไม่พบงบ",
    generateProduct: "สร้างรายการเพิ่มตามจำนวนที่ระบุ",
    createItem: "สร้างรายการ",

    titleSettingDialog: "ตั้งค่าบันทึกบัญชี",
    descriptionSettingDialog: "ข้อมูลที่ตั้งค่า นี้จะถูกอ้างอิงในการบันทึกบัญชีแบบเป็นชุด และแบบรายใบ",
    confirmUseVoucher: "สมุดรายวันที่จะบันทึก",
    isUseExpenseVoucher: "PJ : สมุดรายวันซื้อ",
    isUsePaymentVoucher: "PV : ใบสำคัญจ่าย",
    documentDateSetting: "วันที่เอกสารใน PV : ใบสำคัญจ่าย",
    productVoucherSetting: "รายจ่ายที่บันทึกใน PJ : สมุดรายวันซื้อ",
    haveReferVoucerWording: "ไม่สามารถเลือกได้ เนื่องจากรายการนี้ได้ถูกนำไปบันทึกบัญชีแล้ว",
    productHasVoucher: "รายจ่ายที่บันทึกแล้วใน PJ : สมุดรายวันซื้อ"

}