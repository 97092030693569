export default{
    residentInformation:"Resident Information",
    title:"Resident List",
    add: "Add Resident",

    residentCode: "Resident Code",
    residentName: "Resident Name",
    fieldName: "Name",
    residentId: "Identification Card Number",
	residentEmail: "Email",
    
    generalInformation: "General Information",
    addressInformation: "Address Information",
    receiptAddress: "Invoice/Receipt Address",
    searchText: "Fill up to search",
    residentAll: "All",

    importResident: "Import Resident",
};