<template>
  <!-- v-model="selectedUnit" -->
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredCustomers"
    field="codeAndName"
    @complete="searchCustomer"
    @dropdown-click="filteredCustomers"
    forceSelection
  />
</template>

<script>
import useGetCustomer from "../../hooks/getCustomers.js";
export default {
  setup() {
    const {
      filteredCustomers,
      searchText,
      isSharedCustomerReady,
      searchCustomer,
      getAllCustomers,
    } = useGetCustomer();

    return {
      filteredCustomers,
      searchText,
      isSharedCustomerReady,
      searchCustomer,
      getAllCustomers,
    };
  },
  props: {
    isLoadingData: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["load-finish"],
  mounted() {
    if (this.isLoadingData) {
      this.getAllCustomers();
    }
  },
  watch: {
    isSharedCustomerReady(curValue) {
      if (curValue) {
        this.$emit("load-finish");
      }
    },
  },
};
</script>
