import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export default {
    namespaced: true,
    state(){
        return {
            refundGuaranteeList: [],
            totalPage: 0,
            currentPage: 1,
            rows: 100,
            selectedRefundGuarantee: null,
            blob: null,
            pendingList: [],
            pendingListTotalPage: 0,
            pendingListCurrentPage: 0,
        }
    },
    actions,
    getters,
    mutations
}