export default {
  async getPaymentRequestType(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/PaymentRequest/Type";

    if (payload.projectId) {
      url = url + "?projectId=" + payload.projectId;
    }
    url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get payment request type.");
      throw error;
    }    
    await context.commit("setPaymentRequestType", responseData.data);
  },
  async getPaymentRequestTypeById(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/PaymentRequest/Type/${payload.id}`;
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || `Failed to get payment request type id : ${payload.id}`);
      throw error;
    }

    context.commit("setSelectedPaymentRequestType", responseData.data);
  },
  resetPaymentRequestType(context) {
    context.commit("setSelectedPaymentRequestType", null);
  },
  async createPaymentRequestType(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + "/PaymentRequest/Type/Add";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw responseData;
    }

    context.commit("setSelectedPaymentRequestType", responseData.data);
  },
  async updatePaymentRequestType(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + `/PaymentRequest/Type/${payload.id}/Update`;
    url = encodeURI(url);
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw responseData;
    }

    context.commit("setSelectedPaymentRequestType", responseData.data);
  },
};
