export default {
    title: "Deposit Detail",
    createDeposit: "Create Deposit",
    editDeposit: "Edit Deposit",
    cancelDocument: "Cancel Document",
    depositCode: "Code",
    depositDate: "Date",
    unitCode: "Unit Code",
    depositAmount: "Total Amount",
    depositType: "Pay Type",
    cash: "Cash",
    cheque: "Cheque",
    transfer: "Transfer",
    bookBankCode: "BookBank Code",
    chequeCode: "Cheque Code",
    transferDate: "Transfer Date",
    chequeDate: "Cheque Date",
    payDate: "Pay Date",
    bank: "Bank/Branch",
    transferCode: "Transfer Code",
    payCode: "Pay Code",
    askForCreateNewDeposit: "Do you want to discard change ?",
    createNewDeposit: "Create new",
    validateDepositAmount: "Total Amount must be more than 0.",
    cancelDepositTitle: "Cancel Deposit",
    askForcancelDeposit: "Do you want to cancel deposit ?",
    cancelDepositSuccess: "Cancel deposit code : :code successfully",
    editDepositSuccess: "Edit deposit code : :code successfully",
    saveDepositSuccess: "Save deposit successfully.",

    validateSelectUnit: "Please select unit",
    validateSelectBookBank: "Please select book bank code",
}