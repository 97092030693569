export default {
	customerGroups(state) {
		return state.customerGroups;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	selectedCustomerGroup(state) {
		return state.selectedCustomerGroup;
	},
	customerGroupNextPage(state) {
		return state.currentPage + 1;
	},
	customerGroupIsLastPage(_, getters) {
		return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
	},
};
