<template>
  <PvAutoComplete
    :suggestions="filteredAddressByType"
    field="addressTh"
    @complete="searchAddress"
    forceSelection
    :autoHighlight="true"
    class="w-full"
  />
</template>

<script>
import { computed, onMounted } from "vue";
import useGetAddress from "../../hooks/getAddress";
export default {
  emits: ["load-finish"],
  props: {
    type: {
      type: String,
      default: "province",
    },
    isLoadingData: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { searchTextAddress, address, isSharedAddressReady, getAllAddress } = useGetAddress();

    const searchAddress = function (event) {
      if (!event.query.trim().length) {
        searchTextAddress.value = "";
        searchTextAddress.value = null;
      } else {
        searchTextAddress.value = event.query;
      }
    };

    const filteredAddressByType = computed(() => {
      let result = [...address.value];
      if (props.type && searchTextAddress.value) {
        result = result.filter((data) => {
          if (props.type == "subdistrict") {
            return data.subdistrictTh.includes(searchTextAddress.value);
          } else if (props.type == "district") {
            return data.districtTh.includes(searchTextAddress.value);
          } else if (props.type == "province") {
            return data.provinceTh.includes(searchTextAddress.value);
          } else if (props.type == "postCode") {
            return data.postCode.includes(searchTextAddress.value);
          }
        });
      }
      return result;
    });

    onMounted(async () => {
      if (props.isLoadingData) {
        getAllAddress();
      }
    });

    return {
      searchTextAddress,
      searchAddress,
      isSharedAddressReady,
      filteredAddressByType,
    };
  },
  watch: {
    isSharedAddressReady(curValue) {
      if (curValue) {
        this.$emit("load-finish");
      }
    },
  },
};
</script>
<style scoped>
::v-deep(.p-autocomplete-input) {
  width: 100%;
}
</style>
