import actions from "./actions.js";
import getters from "./getters.js";
import mutations from "./mutations.js";

export default {
	namespaced: true,
	state() {
		return {
			assetRegisters: [],
			selectedAssetRegister: null,
			totalPage: 0,
			currentPage: 0,
			rows: 100,
			blob: null,
			assetCodeByTypes: null,
			assetRegisterDropDowns: [],
			totalPageDropDown: 0,
			currentPageDropDown: 0,
		};
	},
	actions,
	getters,
	mutations,
};