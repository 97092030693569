<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="pb-1">
        <label class="font-lg">{{ lang.title }}</label>
      </div>
    </template>

    <PvDataTable
      showGridlines
      :value="filteredFormPrints"
      v-model:selection="selectedPath"
      selectionMode="single"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollHeight="halfViewportHeight + 'px'"
      class="p-datatable-sm"
      :class="textTableClass"
      :loading="isSharedFormPrintReady"
      @dblclick="selectPath"
    >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
      <template #header>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <PvInputText v-model="searchTextPrint" :placeholder="lang.keywordSearch" autofocus />
        </span>
      </template>
      <PvColumn selectionMode="single" bodyClass="text-center"></PvColumn>
      <PvColumn
        field="itemNo"
        :header="lang.columnSequence"
        headerClass="h-center"
        bodyClass="text-center"
      ></PvColumn>
      <PvColumn
        field="name"
        :header="lang.columnName"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>
    </PvDataTable>
    <template #footer>
      <PvButton 
        class="p-button-text"
        @click="closeDialog"
      >
        <i class="pi pi-times mr-2"></i>
        <span class="font-normal font-md">{{ lang.close }}</span>
      </PvButton>
      <PvButton 
        class="mr-3"
        @click="selectPath"
        :disabled="filteredFormPrints.length <=0"
        autofocus
      >
        <i class="pi pi-check mr-2"></i>
        <span class="font-normal font-md">{{ lang.ok }}</span>
      </PvButton>
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useGetFormPrint from "../../hooks/getFormPrint.js";
export default {
  props: {
    shouldGetData: {
      type: Boolean,
      default: true,
    },
    search: {
      type: String
    }
  },
  emits: ["close", "path-selected", "update:search"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textTableClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.shared.formPrintModal };
      } else {
        return { ...lang.en.base, ...lang.en.shared.formPrintModal };
      }
    });

    const {
      filteredFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
    } = useGetFormPrint();


    return {
      lang: mergeLang,
      textHeaderClass,
      textTableClass,
      filteredFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
    };
  },
  data() {
    return {
      selectedPath: null,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close");
      this.searchTextPrint = "";
    },
    selectPath() {
      this.$emit("path-selected", this.selectedPath);
      this.searchTextPrint = "";
    },
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },   
  },
  watch:{
    search(curValue){
      if(curValue){
        this.searchTextPrint = curValue
      }
    },
    filteredFormPrints(){
      this.selectedPath = this.filteredFormPrints[0];
    }

  }
};
</script>
