<template>
    <PvDialog
        :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
        :style="{ width: '40vw' }"
        :modal="true"
        :closable="false"
    >
        <template #header>
            <div class="flex justify-content-between align-items-right">
                <div class="pb-1">
                    <label class="font-lg">{{ lang.settingAutoGl }}</label>
                </div>
                <div>
                    <PvButton
                        icon="pi pi-times"
                        class="p-button-link p-icon-secondary pb-0 pt-0"
                        style="width: 15px"
                        @click="closeDialog()"
                    />
                </div>
            </div>
            <PvDivider></PvDivider>
        </template>

        <transition>
            <section class="flex flex-wrap" :class="textFormClass">
                <div class="col-12 pl-0 pt-0 pr-2">
                    <label for="type">{{ lang.paymentRequestTypeAutoGl }}</label>
                    <payment-request-type-dropdown
                        id="selectedPaymentRequestType"
                        v-model="selectedPaymentRequestType"
                        class="inputfield w-full mr-2 mt-2"
                        optionValue="code"
                        @load-finish="paymentRequestTypeDataReady"
                        :isAllType="false"
                        :placeholder="lang.selectPaymentRequestType"
                        @change="changePaymentRequestType"
                    />
                </div>
                <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
                    <label for="dateStart">{{ lang.paymentRequestDateStart }}</label>
                    <PvCalendar
                        id="dateStart"
                        v-model="dateStart"
                        class="inputfield w-full mt-1"
                        showIcon
                        dateFormat="dd/mm/yy"
                        :class="{ 'p-invalid': dateStartError }"
                        @date-select="dateStartError = false"
                    />
                    <small v-if="dateStartError" id="dateStart-help" class="p-error">
                    {{ lang.validateDateStartError }}
                    </small>
                </div>
                <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
                    <label for="dateEnd">{{ lang.paymentRequestDateEnd }}</label>
                    <PvCalendar
                        id="dateEnd"
                        v-model="dateEnd"
                        class="inputfield w-full mt-1"
                        showIcon
                        dateFormat="dd/mm/yy"
                        :class="{ 'p-invalid': dateEndError }"
                        @date-select="dateEndError = false"
                    />
                    <small v-if="dateEndError" id="dateStart-help" class="p-error">
                    {{ lang.validateDateEndError }}
                    </small>
                </div>
                <div class="col-12 pl-0 pt-2 pr-2">
                    <label for="ledgerBook">{{ lang.ledgerBook }}*</label>
                </div>
                <div v-if="isProductType" class="lg:col-6 md:col-6 col-12 pl-0 pr-2 flex">
                    <PvCheckbox
                        id="selectedIsUseExpense"
                        inputId="binary"
                        v-model="selectedIsUseExpense"
                        :binary="true"
                    />
                    <label for="expense" class="pl-3">{{ labelIsUseExpense }}</label>
                </div>
                <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 flex">
                    <PvCheckbox
                        id="selectedIsUsePayment"
                        v-model="selectedIsUsePayment"
                        :binary="true"
                    />
                    <label for="payment" class="pl-3">{{ labelIsUsePayment }}</label>
                </div>
                <div v-if="isNoSelectLedgerBook && isValidateLedgerBook" class="col-12 pl-0 pt-2 pr-2">
                    <small id="ledger-book-help" class="p-error">{{ lang.validateNoSelectLedgerBook }}</small>
                </div>
                <div class="col-12 pl-0 pt-3 pr-2">
                    <label for="nextStep">{{ lang.nextStepAutoGl }}</label>
                </div>
            </section>
        </transition>

        <template #footer>
            <div class="pr-2">
                <PvButton
                    :label="lang.close"
                    class="p-button-outlined p-button-secondary w-3 ml-3"
                    @click="closeDialog()"
                />
                <PvButton 
                    :label="lang.buttonDialogConfirm" 
                    class="w-full mr-3 w-3" 
                    @click="confirm()" 
                />
            </div>
        </template>
    </PvDialog>
</template>

<script>
import { useRouter } from "vue-router";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import { useStore } from "vuex";
import { computed, ref, onMounted } from "vue";
import useFormat from "../../hooks/format.js";
import BaseDialog from "../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import PaymentRequestTypeDropdown from "../../components/PaymentRequest/PaymentRequestTypeDropdown.vue";
export default {
    components: {
        PaymentRequestTypeDropdown,
    },
    props: {},
    emits: ["close", "confirm"],
    setup(props, { emit }) {
        const router = useRouter();
        const store = useStore();
        const { lang } = useLang();
        const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
        const mergeLang = computed(function () {
            if (store.getters.selectedLanguage === "TH") {
                return {
                ...lang.th.base,
                ...lang.th.ap.paymentRequest.listing,
                };
            } else {
                return {
                ...lang.en.base,
                ...lang.en.ap.paymentRequest.listing,
                };
            }
        });
        const { formatDateToISOString } = useFormat();

        let selectedPaymentRequestType = ref(null);
        let dateStart = ref(null);
        let dateEnd = ref(null);
        let selectedIsUseExpense = ref(null);
        let selectedIsUsePayment = ref(null);
        let labelIsUseExpense = ref("");
        let labelIsUsePayment = ref("");
        let isValidateLedgerBook = ref(false);
        let dateStartError = ref(false);
        let dateEndError = ref(false);
        let dataConfigDialog = ref(null);
        let isNoSetupProduct = ref(false);
        let isNoSetupType = ref(false);

        onMounted(async() => {
            initiateData();
        });

        //computed
        const selectedProject = computed(() => {
            return store.getters.selectedProject;
        });

        const isProductType = computed(() => {
            if (selectedPaymentRequestType.value == "PQ001") {
                return true;
            }
            return false;
        });

        const isNoSelectLedgerBook = computed(() => {
            if (!selectedIsUseExpense.value && !selectedIsUsePayment.value) {
                return true;
            }
            return false;
        });

        const payload = computed(() => {
            return {
                projectId: selectedProject.value.id,
                paymentRequestType: selectedPaymentRequestType.value,
                dateStart: formatDateToISOString(dateStart.value),
                dateEnd: formatDateToISOString(dateEnd.value),
                isUseExpense: selectedIsUseExpense.value,
                isUsePayment: selectedIsUsePayment.value
            }
        });

        //methods
        const closeDialog = () => {
            emit("close");
        };

        const validateData = () => {
            let returnStatus = false;
            if (!dateStart.value) {
                dateStartError.value = true;
                returnStatus = true;
            }
            if (!dateEnd.value) {
                dateEndError.value = true;
                returnStatus = true;
            }
            if (isNoSelectLedgerBook.value) {
                isValidateLedgerBook.value = true;
                returnStatus = true;
            }
            return returnStatus;
        };

        const gotoSetupProduct = () => {
            const routeData = router.resolve({
                name: "product-ap",
            });
            window.open(routeData.href, "_blank");
        };

        const gotoSetupPaymentRequestType = () => {
            const routeData = router.resolve({
                name: "payment-request-type",
            });
            window.open(routeData.href, "_blank");
        };

        const confirm = async () => {
            if (validateData()) {
                return;
            }

            try {
                await store.dispatch("paymentRequest/getPaymentRequestForAutoGl", payload.value);
                emit("confirm");
            } catch (error) {
                if (error.code == "111") {
                    isNoSetupType.value = true;
                    setDialog({
                        classTitle: "",
                        title: mergeLang.value.notificationDialogHeader,
                        message: mergeLang.value.validatePaymentRequestTypeAutoGl + error.message,
                        type: "confirm",
                    });
                    const dialogResponse = await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
                    if (!dialogResponse.isCanceled) {
                        gotoSetupPaymentRequestType();
                    }
                } else if (error.code == "112") {
                    isNoSetupProduct.value = true;
                    setDialog({
                        classTitle: "",
                        title: mergeLang.value.notificationDialogHeader,
                        message: mergeLang.value.validateProductAutoGl + error.message,
                        type: "confirm",
                    });
                    const dialogResponse = await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
                    if (!dialogResponse.isCanceled) {
                        gotoSetupProduct();
                    }
                } else {
                    setDialog({
                        classTitle: "error",
                        title: mergeLang.value.notificationDialogHeader,
                        message: error,
                    });
                    createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
                }
            }
        };

        const initiateData = () => {
            let date = new Date();
            dateStart.value = new Date(date.getFullYear(), date.getMonth(), 1);
            dateEnd.value = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            selectedIsUseExpense.value = false;
            selectedIsUsePayment.value = false;
            isValidateLedgerBook.value = false;
            dateStartError.value = false;
            dateEndError.value = false;
            dataConfigDialog.value = null;
        };

        const changePaymentRequestType = () => {
            selectedIsUseExpense.value = false;
            selectedIsUsePayment.value = false;
        };

        const paymentRequestTypeDataReady = (data) => {
            selectedPaymentRequestType.value = "PQ001";
            let result = data.find((c) => c.code.trim() == selectedPaymentRequestType.value)
            labelIsUseExpense.value = `${result.expenseLedgerBookCode} : ${result.expenseLedgerBookName}`;
            labelIsUsePayment.value = `${result.paymentLedgerBookCode} : ${result.paymentLedgerBookName}`;
        };

        const setDialog = (dataConfig) => {
            const classMap = {
                success: "p-text-success",
                error: "p-error",
            };

            dataConfig.classTitle = classMap[dataConfig.classTitle] || "";

            dataConfig.button = [
                {
                    id: "close",
                    caption: mergeLang.value.close,
                    class: "p-button-outlined p-button-secondary w-full",
                    action: "cancel",
                },
            ];

            if (dataConfig.type === "confirm") {
                dataConfig.button.push({
                    id: "confirm",
                    caption: isNoSetupType.value ? mergeLang.value.setupType : mergeLang.value.setupProduct,
                    class: "w-full",
                    action: "confirm",
                });
            }

            dataConfigDialog.value = {
                titleConfirm: dataConfig.title,
                classTitle: dataConfig.classTitle,
                wordingConfirm: dataConfig.message,
                dataButtons: dataConfig.button,
                display: true,
            };
        };

        return {
            lang: mergeLang,
            textHeaderClass,
            textFormClass,
            textTableClass,

            //data
            selectedPaymentRequestType,
            dateStart,
            dateEnd,
            selectedIsUseExpense,
            selectedIsUsePayment,
            labelIsUseExpense,
            labelIsUsePayment,
            isValidateLedgerBook,
            dateStartError,
            dateEndError,

            //methods
            closeDialog,
            confirm,
            paymentRequestTypeDataReady,
            changePaymentRequestType,

            //computed
            isProductType,
            isNoSelectLedgerBook,
        }
    }
}
</script>