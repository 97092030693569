export default {
  uploadFile: "อัปโหลดไฟล์",
  dragFile: "ลากไฟล์มาวาง หรือ ",
  addFile: "เพิ่มไฟล์ใหม่",
  importFileDesc: "กรุณาเพิ่มไฟล์ทีละ 1 ไฟล์ ที่มีนามสกุล .XLSX เท่านั้น หรือ ",
  downloadfile: "ดาวน์โหลดตัวอย่างไฟล์",
  fileName: "ชื่อเอกสาร",
  validateLimitFile: "สามารถอัปโหลดไฟล์ได้ทีละ 1 ไฟล์",
  validateFileType: "ไม่สามารถอัปโหลดไฟล์ :name ได้",
  validateImportFile: "สกุลไฟล์ที่อนุญาตมีดังนี้ .XLSX เท่านั้น",
  validateImportNoData: "เอกสาร : :file ไม่มีข้อมูล",

  validateDataProductCode: "ข้อมูลแถวที่ :row ไม่ได้ระบุรหัสรายรับ",
  validateDataLimitTextProduct: "ข้อมูลแถวที่ :row รหัสรายรับ ระบุความยาวไม่เกิน 25 ตัวอักษร",
  validateDataRunningNumber: "ข้อมูลแถวที่ :row ไม่ได้ระบุ Running No.",
  validateDataLimitTextRunningNumber: "ข้อมูลแถวที่ :row Running No. ระบุความยาวไม่เกิน 25 ตัวอักษร",
  validateDataLimitTextSerialNumber: "ข้อมูลแถวที่ :row Serial No. ระบุความยาวไม่เกิน 25 ตัวอักษร",
  validateDataLimitTextStatus: "ข้อมูลแถวที่ :row สถานะ ไม่ถูกต้อง",
  validateDataLimitTextRemark: "ข้อมูลแถวที่ :row หมายเหตุ ระบุความยาวไม่เกิน 255 ตัวอักษร",

  validateImportRunningNumberDuplicate: "ข้อมูล Running No. ซ้ำในไฟล์ลำดับที่ ",
  validateImportProductCode: "ไม่พบรหัสสินค้า",
  validateImportLimitTextProduct: "รหัสสินค้าเกิน 25 ตัวอักษร",
  validateImportRunningNumber: "ไม่พบ Running No.",
  validateImportLimitTextRunningNumber: "Running No. เกิน 25 ตัวอักษร",
  validateImportLimitTextSerialNumber: "Serial No. เกิน 25 ตัวอักษร",
  validateImportStatus: "ไม่พบสถานะ",
  validateImportLimitTextRemark: "หมายเหตุเกิน 255 ตัวอักษร",
  validateImportRemark: "ไม่พบหมายเหตุ",
  validateImportDate: "รูปแบบวันที่นำเข้าไม่ถูกต้อง",
  
  reupload: "อัปโหลดใหม่",
  importAll: "นำเข้าทั้งหมด (:value)",
  allStatus: "ทั้งหมด",
  wait: "รอบันทึก",
  success: "สร้างสำเร็จ",
  invalid: "ค่าไม่ถูกต้อง",
  error: "สร้างไม่สำเร็จ",
  statusContent: ":number รายการ",
  statusImport: "สถานะการนำเข้า",
  reason: "เหตุผล",
  askForNewImport: "ท่านต้องการอัปโหลดรายการคีย์การ์ดใหม่แทนที่รายการเดิมใช่หรือไม่",
  cannotImport: "ไม่สามารถนำเข้ารายการได้",
  cannotImportWording: "เนื่องจากมีรายการสถานะ “ค่าไม่ถูกต้อง” จำนวน :value รายการ",
  editAndReuploadWording: "กรุณาแก้ไขไฟล์ และอัปโหลดใหม่อีกครั้ง",
  noItemToImportWording: "ไม่สามารถบันทึกได้ เนื่องจากไม่มีรายการรอบันทึก",
  creatingStockKeycard: "กำลังสร้างคีย์การ์ด",
  generating: "กำลังสร้าง...",
  validateImportUnitCode: "ไม่พบยูนิต",
  validateImportRunningNumberDuplicateInDatabase: "Running No. ซ้ำในระบบ",
};
