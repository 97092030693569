export default {
	title: "TransferSlip",
	status: "Status",
	all: "All",
	waiting: "Waiting",
	receiptCreated: "Receipt Created",
	rejected: "Rejected",
	uploadedPeriod: "Uploaded Period",
	invoiceCode: "Invoice Code",
	unitCode: "Unit No.",
	unitAddressNo: "Addr No.",
	payerName: "Payer Name",
	totalAmount: "Total Amount",
	columnStatus: "Status",
	columnUploadDate: "Uploaded Date",
	columnInvoiceCode: "Invoice Code",
	columnUnitCode: "Unit No.",
	columnUnitAddressNo: "Addr No.",
	columnPayerName: "Payer Name",
	columnTotalAmount: "Total Amount",
};
