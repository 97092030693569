<template>
  <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
    <div class="text-700 text-center">
      <div class="text-900 font-bold text-5xl mb-3">404 Not Found</div>
      <div class="text-700 text-2xl mb-5">
        {{ lang.notfound }}
      </div>
      <PvButton
        :label="lang.notfoundButton"
        class="
          font-bold
          px-5
          py-3
          p-button-raised p-button-rounded
          white-space-nowrap
        "
        @click="goToHome"
      ></PvButton>
    </div>
  </div>
</template>

<script>
import useLang from '../hooks/lang.js';
import { useStore } from "vuex";
import {computed} from 'vue';
export default {
  setup() {
    const { lang } = useLang();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base };
      } else {
        return { ...lang.en.base };
      }
    });
    return { lang: mergeLang };
  },
  methods: {
    goToHome() {
      this.$router.replace({ name: "home", params: { projectCode: this.$store.getters.selectedProject.code } });
    },
  },
};
</script>