export default {
  async getTaxes(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Tax";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get tax");
      throw error;
    }
    // console.log("tax",responseData);

    // const data = responseData.data;

    const tax = {
      taxes: responseData.data,
      totalPage: responseData.page.pageCount,
      currentPage: responseData.page.currentPage,
    };
    context.commit("setTax", tax);
  },
  async getAllTaxes(context) {
    // console.log('context',context);
      while (!context.getters.taxIsLastPage) {
        let where = {
          projectId: context.rootGetters.selectedProject.id,
        };
        const payload = {
          page: context.getters.taxNextPage || 1,
          where,
        };
        await context.dispatch("getTaxes", payload);
      }
    
  },
  resetTax(context) {
    context.commit("resetTax");
  },
};
