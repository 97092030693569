export default {
  setGlBook(state, payload) {
    let glBooks = [...state.glBook.glBooks];
    for (const glBook of payload.glBooks) {
      if (glBooks.findIndex((c) => c.generalLedgerBookId === glBook.generalLedgerBookId) < 0) {
        glBooks.push(glBook);
      }
    }
    state.glBook.glBooks = glBooks;
    state.glBook.currentPage = payload.currentPage;
    state.glBook.totalPage = payload.totalPage;
  },
  resetGlBook(state) {
    const glBook = {
      glBooks: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.glBook = glBook;
  },
};
