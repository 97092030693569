export default {
    titleToolbar: "Accounting Policy",
    title: "accounting Records",
    searchTextPlaceholder: "Fill up to search.",
    accountingDetail: "Accounting Detail",
    titleDocumentBook : "DocumentBook",
    dataEmpty: "Please select document book",
    documentType: "DocumentType",
    documentBook: "DocumentBook",
    copyAccountingPolicy: "Copy Accounting Policy",
    columnItem: "No.",
	columnLinkFunction: "LinkFunction",
	columnDebitCredit: "Debit / Credit",
	columnAccountCode: "AccountCode",
	columnAccountName: "AccountName",
    columnAccountCodeAndName: "AccountCode / AccountName",
    accountingPolicyItem: "Accounting Item",
    accountingPolicyItemRemark: "Please enter data",
    accountingPolicyCopy: "Please select document book to copy",
    copyForReplace: "Copy for replace",
    validateNoSelectData: "Please select data",
    validateNoLinkFunctionSelect: "Please select linkFunction",
    validateNoDebitCreditSelect: "Please select Debit / Credit",
    validateNoAccountCodeAndNameSelect: "Please select AccountCode / AccountName",
    validateNoDocumentBook: "Please select documentBook", 
    changeDocumentBook: "Change DocumentBook",
	askForNewDocumentBook: "Are you sure you want to discard change ",
	askForNewDocumentBookConfirm: "Are you change new document?",
    saveAccountingPolicySuccess: "Save Success",
    askItemDuplicate: "Item LinkFunction :code Duplicate",
	debit: "Debit",
	credit: "Credit",
}