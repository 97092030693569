export default {
	title: "เอกสารโครงการ",
    uploadFile: "ลากไฟล์มาวาง หรือ เพิ่มไฟล์ใหม่",
    addFile: "เพิ่มไฟล์ใหม่",
    uploadFileDesc: "กรุณาเพิ่มไฟล์ที่มีนามสกุล .JPG ,.PNG , .PDF เท่านั้น และมีขนาดไฟล์ไม่เกิน 5 MB",
    allDocument: "เอกสารทั้งหมด",
    limitDocument: "สามารถเก็บเอกสารได้สูงสุด 5 ไฟล์",
    validateLimitDocument: "เก็บไฟล์ได้สูงสุดไม่เกิน 5 ไฟล์",
    validateLimitDocumentDetail: "ไม่สามารถอัปโหลดไฟล์ :name ได้",
    validateFileSize: "ไฟล์ :name มีขนาดใหญ่เกินไป",
    validateFileSizeDetail: "กรุณาอัปโหลดไฟล์ที่มีขนาดไม่เกิน 5 MB",
    validateFileType: "ไม่สามารถอัปโหลดไฟล์ :name ได้",
    validateFileTypeDetail: "สกุลไฟล์ที่อนุญาตมีดังนี้ .JPG, .PNG และ PDF เท่านั้น",
    addFileSuccess: "อัปโหลดเอกสารสำเร็จ",
    titleConfirmDelete: "ยืนยันลบเอกสาร",
    wordingConfirmDelete: "คุณยืนยันที่จะลบเอกสาร ใช่หรือไม่?",
    deleteFileSuccess: "ลบเอกสารสำเร็จ",
    uploading: "กำลังอัปโหลด",
    fileName: "ชื่อเอกสาร"
};
