import { useRoute } from 'vue-router';
import { useStore } from "vuex";
import { computed } from "vue";
import useTracking from "../hooks/tracking";

export default function useSetTracking() {
    const route = useRoute();
    const store = useStore();
    const { trackingSend } = useTracking();

    const selectedProject = computed(() => {
		return store.getters.selectedProject;
	});
    
    const routeName = computed(() => {
		return route.name;
	});

    const getTrackingProjectAndRouteName= function () { 
        let currentProject = null;
        if(selectedProject.value){
            currentProject = {
                id: selectedProject.value.id,
                code: selectedProject.value.code,
            };
        }

        let trackingData = {
            current_screen: routeName.value, 
            current_project: currentProject
        }

        return trackingData;
    }  

    const trackingView = function (payload) {
        let viewData = {
            ame: payload.name,
            event: payload.event
        }
        const trackingDefaultData = getTrackingProjectAndRouteName();
        let trackingData = {
            ...trackingDefaultData,
            view_data: viewData
        }
        // console.log("trackingView=",trackingData);
        trackingSend("track_view", trackingData);
    }
    
    const trackingButtonClick = function (payload) {
        let buttonData = {
            name: payload.name,
            event: payload.event
        }
        const trackingDefaultData = getTrackingProjectAndRouteName();
        let trackingData = {
            ...trackingDefaultData,
            button_data: buttonData
        }
        // console.log("trackingButtonClick=",trackingData);
        trackingSend("track_button_click", trackingData);
    }

    return {
        trackingView,
        trackingButtonClick,
    };
}