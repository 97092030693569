export default {
  async getDocumentBooks(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/AccountingPolicy/DocumentBookForAccountingPolicy";

    if (payload.projectId) {
      url = url + "?projectId=" + payload.projectId;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (!payload.limit) {
      url = url + "&limit=100";
    } else {
      url = url + "&limit=" + payload.limit;
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get account chart");
      throw error;
    }

    const documentBook = {
      documentBooks: responseData.data,
      totalPage: responseData.page.pageCount == 0 ? 1 : responseData.page.pageCount,
      currentPage: responseData.page.currentPage,
    };
    // console.log("documentBook=",documentBook);
    context.commit("setDocumentBooks", documentBook);
  },
  async getAllDocumentBooks(context, payload) {
    while (!context.getters.documentBookIsLastPage) {
      const payloadDocumentBooks = {
        page: context.getters.documentBookNextPage || 1,
        projectId: payload,
      };
      await context.dispatch("getDocumentBooks", payloadDocumentBooks);
    }
  },
  resetDocumentBooks(context) {
    context.commit("resetDocumentBooks");
  },
  async getAccountingPolicys(context, DocumentBookId) {
    let url = process.env.VUE_APP_BACKEND_URL + "/AccountingPolicy/DocumentBook/"+DocumentBookId;
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get account chart");
      throw error;
    }

    // console.log("documentBook=",documentBook);
    context.commit("setAccountingPolicys", responseData.data);
  },  
  resetAccountingPolicys(context) {
    context.commit("resetAccountingPolicys");
  },
  
  async getPetterns(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/AccountingPolicy/Pettern";

    if (payload.page) {
      url = url + "?page=" + payload.page;
    }

    if (!payload.limit) {
      url = url + "&limit=100";
    } else {
      url = url + "&limit=" + payload.limit;
    }
    
    if (payload.projectId) {
      url = url + "&DocumentBookId=" + payload.documentBookId;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get account chart");
      throw error;
    }

    // console.log("documentBook=",documentBook);
    context.commit("setPetterns", responseData.data);
  },
  async createOrUpdateAccountingPolicy(context, payload){
		let url = process.env.VUE_APP_BACKEND_URL + `/AccountingPolicy/${payload.type}`;
		const fallbackErrorMessage = `Failed to ${payload.type} accountingPolicy`
		const token = context.rootGetters.token;
	
		const response = await fetch(url, {
		  method: payload.type === 'Add' ? 'post' : 'put',
		  headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + token,
		  },
	
		  body: JSON.stringify(payload),
		});
	
		const responseData = await response.json();
	
		if (!response.ok) {
		  // console.log(responseData);
		  let message = null
		  if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
			message = responseData.title;
			for (const key in responseData.errors) {
			  message += ", " + key.replace("$.","")
			}
		  } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
			message = responseData.message
		  }
		  const error = new Error(message || fallbackErrorMessage);
		  throw error;
		}
		// console.log(responseData);
	
		context.commit("setAccountingPolicys", responseData.data);
	}, 
  async getCopyAccountingPolicys(context, DocumentBookId) {
    let url = process.env.VUE_APP_BACKEND_URL + "/AccountingPolicy/DocumentBook/"+DocumentBookId;
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get account chart");
      throw error;
    }

    // console.log("documentBook=",documentBook);
    context.commit("setCopyAccountingPolicys", responseData.data);
  }, 
  resetCopyAccountingPolicys(context) {
    context.commit("resetCopyAccountingPolicys");
  },
};
