<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoSave="checkBeforeSave"
    @gotoCreate="askForCreate"
    @gotoCancel="askForCancel"
    @gotoPrint="getPrintForm"
  >
  </base-toolbar>

  <base-container class="mb-5">
    <receipt-product-dialog
      v-model:visible="showProductDialog"
      :data="selectedReceiptDetail"
      :isOpen="showProductDialog"
      @close="closeProductDialog"
      @product-selected="selectProduct"
    ></receipt-product-dialog>

    <receipt-pending-dialog
      v-model:visible="showPendingDialog"
      :isOpen="showPendingDialog"
      :unit="selectedUnit"
      :date="date"
      :receiptDetails="details"
      :livingType="selectedLivingType"
      @close="showPendingDialog = false"
      @pending-selected="selectPending"
    ></receipt-pending-dialog>

    <receipt-calculate-penalty-dialog
      v-model:visible="showCalculatePenaltyDialog"
      :isOpen="showCalculatePenaltyDialog"
      @close="showCalculatePenaltyDialog = false"
      @confirm="calculatePenalty"
    ></receipt-calculate-penalty-dialog>

    <base-resident-dialog
      v-model:visible="showCustomerDialog"
      :selectedCustomerId="customerId"
      @close="showCustomerDialog = false"
      @resident-selected="selectCustomer"
    ></base-resident-dialog>

    <receipt-guarantee-dialog
      v-model:visible="showGuaranteeDialog"
      @close="showGuaranteeDialog = false"
      @guarantee-selected="selectGuarantee"
    ></receipt-guarantee-dialog>

    <receipt-other-dialog
      :isOpen="showReceiptOtherDialog"
      :code="payReference"
      v-model:visible="showReceiptOtherDialog"
      @close="showReceiptOtherDialog = false"
      @receipt-selected="selectReceiptOther"
    ></receipt-other-dialog>

    <stock-keycard-dialog
      v-model:visible="showStockKeycardDialog"
      :receiptDetails="details"
      :selectedKeycard="selectedProduct"
      @close="showStockKeycardDialog = false"
      @keycard-selected="selectKeycard"
      :key="keyToReLoad"
    ></stock-keycard-dialog>

    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchTextPrint"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>

    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="titleConfirm"
      :classTitle="baseDialogClassTitle"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @close="closeBaseDialog"
      @confirm="confirmBaseDialog"
      @confirm-cancel="cancel"
      @use-guarantee="useGuarantee"
      @not-use-guarantee="notUseGuarantee"
      @goto-create="createNewDocument"
      @goto-copy-item="copyItemSelected"
    >
      <template #dialogBody>
        <div class="lg:col-12 md:col-12 col-12 p-0 w-full" v-if="showCancelDialog">
          <label for="reasonCancel">{{ lang.cancelReason }}</label>
          <PvInputText
            id="reasonCancel"
            type="text"
            class="inputfield w-full mt-1"
            v-model="reasonCancel"
            :class="{ 'p-invalid': reasonCancelError }"
            @keypress="reasonCancelError = false"
            autofocus
          />
          <small v-if="reasonCancelError" id="reasonCancel-help" class="p-error">
            {{ lang.validateNoCancelReason }}
          </small>
        </div>
        <div class="lg:col-12 md:col-12 col-12 p-0 w-full" v-if="isCopyItem">
          <label for="copyQuantity">{{ lang.generateProduct }}</label>
          <PvInputNumber
            id="copyQuantity"
            class="mt-1 w-full"
            v-model="copyQuantity"
            mode="decimal"
            @focus="$event.target.select()"
            :min="1"
            :max="100"
            inputClass="w-full text-right"
            showButtons
          />
        </div>
      </template>
    </base-dialog>

    <div class="surface-card shadow-2 p-5 border-round mt-5" :class="textFormClass">
      <div class="flex justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.receiptDetail }}</label>
        <!-- <PvButton
          class="p-button-outlined m-0"
          @click="getPrintForm()"
          :disabled="!isRolePrint || isCreateDocument"
        >
          <icon-printer
            class="p-button-outlined p-button-primary mr-2"
            iconColor="primary"
            height="16"
            width="16"
          />
          <span class="font-sm">
            {{ lang.receiptPrintEditPage }}
          </span>
        </PvButton> -->
      </div>

      <PvDivider class="mb-3"></PvDivider>

      <section class="flex flex-wrap fontlabel" :class="textFormClass">
        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
          <label for="code">{{ lang.receiptCode }}</label>
          <PvInputText
            id="code"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="code"
            disabled
          />
        </div>

        <!-- <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="dateWithFormat">{{ lang.date }}</label>
          <PvInputText
            id="dateWithFormat"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="dateWithFormat"
            disabled
          />
        </div> -->
        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
          <label for="date">{{ lang.date }}</label>
          <PvCalendar
            id="date"
            v-model="date"
            class="inputfield w-full mt-1"
            inputClass="p-inputtext-disabled"
            showIcon
            dateFormat="dd/mm/yy"
            disabled
          />
        </div>

        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
          <label for="refCode">{{ lang.invoiceCode }}</label>
          <PvInputText
            id="refCode"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="refCode"
            disabled
          />
        </div>
        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
          <label for="employeeName">{{ lang.employeeName }}</label>
          <PvInputText
            id="employeeName"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="employeeName"
            disabled
          />
        </div>

        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
          <label>{{ lang.unitCode }}</label>
          <PvSkeleton
            v-if="!isSharedUnitReady"
            class="inputfield w-full mt-1"
            height="39px"
          />
          <base-unit-auto-complete
            id="unit"
            v-model="selectedUnit"
            class="inputfield w-full mt-1 mr-2"
            @load-finish="unitDataReady"
            @item-select="selectUnit()"
            :class="{ 'p-invalid': unitError }"
            :inputClass="classDisableUnit"
            :disabled="!isCreateDocument"
            v-show="isSharedUnitReady"
            :isValidateActiveStatus="true"
            @is-inactive="unitIsInactive"
          />
          <small v-if="unitError" id="unit-help" class="p-error">
            {{ lang.validateNoUnitSelect }}
          </small>
        </div>

        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
          <label for="customerName">{{ lang.customerName }}</label>
          <div class="flex justify-content-between align-items-center">
            <div class="w-full pr-2">
              <PvInputText
                id="customerName"
                type="text"
                class="inputfield w-full mt-1 p-inputtext-disabled"
                v-model.trim="customerName"
                disabled
              />
            </div>
            <PvButton
              icon="pi pi-users"
              class="p-button-secondary mt-1"
              @click="openCustomerDialog"
              :disabled="isDocumentDisabled"
            />
          </div>
        </div>

        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
          <label for="customerMobile">{{ lang.mobileNumber }}</label>
          <PvInputText
            id="customerMobile"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model.trim="customerMobile"
            disabled
          />
        </div>

        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0 lg:pr-4 md:pr-4">
          <label for="livingType">{{ lang.livingType }}</label>
          <div class="flex pt-1">
            <div
              v-for="livingType of livingTypes"
              :key="livingType.code"
              class="field-radiobutton pt-2 mr-3 mb-0"
            >
              <PvRadioButton
                class="mr-2"
                :inputId="livingType.code"
                name="livingType"
                :value="livingType.code"
                v-model="selectedLivingType"
              />
              <label :for="livingType.code" class="ml-0">{{ livingType.name }}</label>
            </div>
          </div>
        </div>

        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
          <label for="depositLeftAmount">{{ lang.deposit }}</label>
          <PvInputNumber
            id="depositLeftAmount"
            class="inputfield w-full mt-1"
            inputClass="p-inputtext-disabled text-right"
            v-model="depositLeftAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            disabled
          />
        </div>

        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
          <label for="pendingLeftAmount">{{ lang.pending }}</label>
          <PvInputNumber
            id="pendingLeftAmount"
            class="inputfield w-full mt-1"
            inputClass="p-inputtext-disabled text-right"
            v-model="pendingLeftAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            disabled
          />
        </div>

        <base-card
          :titleHeader="lang.titleDetail"
          :isVisibleExpandButton="true"
          :isHideBackground="true"
          :isVisibleCard="true"
          class="col-12 pr-0 pl-0 pb-0"
        >
          <div class="flex flex-wrap justify-content-between align-items-start p-0 pb-1 mt-3 pt-1">
            <div class="flex lg:col-5 md:col-12 col-12 p-0 align-content-center mb-2">
              <div class="flex flex-wrap lg:col-6 md:col-6 col-8 p-0">
                <label for="payAmount">{{ lang.amountToPay }}</label>
                <PvInputNumber
                  id="payAmount"
                  class="inputfield w-full mt-1"
                  inputClass="text-right w-full"
                  v-model="payAmount"
                  mode="decimal"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  @focus="$event.target.select()"
                />
              </div>
              <PvButton
                :label="lang.payAsDefined"
                class="p-button-primary mt-4 ml-2"
                @click="getPendingByAmount()"
                :disabled="isDocumentDisabled"
              />
            </div>
            <div class="flex flex-wrap align-items-end justify-content-end lg:col-7 md:col-12 col-12 p-0 pt-4">
              <PvButton
                icon="pi pi-search"
                :label="lang.selectPendingList"
                class="p-button-primary mb-2"
                @click="openPendingDialog()"
                :disabled="isDocumentDisabled"
              />
              <PvButton
                icon="pi pi-plus"
                :label="lang.addItem"
                class="p-button-primary mb-2 ml-2"
                @click="openProductDialog('create')"
                :disabled="isDocumentDisabled"
              />
              <PvButton
                class="p-button-primary mb-2 ml-2"
                @click="openCalculatePenaltyDialog()"
                :disabled="isDocumentDisabled"
              >
                <icon-accounting-calculator-circle
                  iconColor="white"
                  width="16"
                  height="16"
                />
                <label class="p-button-label ml-1">{{ lang.calculatePenalty }}</label>
              </PvButton>
            </div>
          </div>

          <div v-if="detailError" class="pb-2">
            <PvInlineMessage severity="error" class="w-full justify-content-start">{{ lang.validateDetail}}</PvInlineMessage>
          </div>

          <PvDataTable
            :value="details"
            dataKey="id"
            responsiveLayout="scroll"
            :class="textTableClass"
            v-model:selection="selectedReceiptDetail"
            selectionMode="single"
            @row-dblclick="openProductDialog(this.selectedReceiptDetail)"
            scrollHeight="294px"
          >
            <!-- :scrollHeight="halfViewportHeight + 'px'" -->
            <PvColumn
              field="itemNo"
              :header="lang.itemNo"
              style="min-width: 2rem"
              headerClass="h-left"
              bodyClass="text-left tableBodyTop"
            />
            <PvColumn
              field="productCode"
              :header="lang.productCode"
              style="min-width: 7rem"
              headerClass="h-left"
              bodyClass="text-left tableBodyTop"
            />
            <PvColumn
              field="productName"
              :header="lang.productName"
              style="min-width: 8rem"
              headerClass="h-left"
              bodyClass="text-left tableBodyTop"
            />
            <PvColumn
              field="remark"
              :header="lang.description"
              style="min-width: 8rem"
              headerClass="h-left"
              bodyClass="text-left tableBodyTop"
            />
            <PvColumn
              field="period"
              :header="lang.period"
              style="min-width: 2rem"
              headerClass="h-left"
              bodyClass="text-left tableBodyTop"
            />
            <PvColumn
              field="unitPrice"
              :header="lang.unitPrice"
              style="min-width: 8rem"
              headerClass="h-right"
              bodyClass="text-right tableBodyTop"
            >
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.unitPrice) }}
              </template>
            </PvColumn>
            <PvColumn
              field="quantity"
              :header="lang.quantity"
              style="min-width: 3rem"
              headerClass="h-right"
              bodyClass="text-right tableBodyTop"
            >
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.quantity) }}
              </template>
            </PvColumn>
            <PvColumn
              field="totalAmount"
              :header="lang.totalAmount"
              style="min-width: 4rem"
              headerClass="h-right"
              bodyClass="text-right tableBodyTop"
            >
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.totalAmount) }}
              </template>
            </PvColumn>
            <PvColumn
              field="discountDescription"
              :header="lang.discount"
              style="min-width: 3rem"
              headerClass="h-left"
              bodyClass="text-left tableBodyTop"
            />
            <PvColumn
              field="discountAmount"
              :header="lang.discountAmount"
              style="min-width: 8rem"
              headerClass="h-right"
              bodyClass="text-right tableBodyTop"
            >
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.discountAmount) }}
              </template>
            </PvColumn>
            <PvColumn
              field="netAmount"
              :header="lang.paidAmount"
              style="min-width: 4rem"
              headerClass="h-right"
              bodyClass="text-right tableBodyTop"
            >
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.netAmount) }}
              </template>
            </PvColumn>
            <PvColumn
              style="min-width: 9rem"
              headerClass="h-center"
              bodyStyle="text-align: center; overflow: visible;"
              bodyClass="tableBodyTop"
            >
              <template #body="slotProps">
                <PvButton
                  type="button"
                  class="p-button-secondary p-button-text p-0 mr-2"
                  @click="openProductDialog(slotProps.data)"
                >
                  <icon-pen-edit iconColor="secondary" width="24" height="24" />
                </PvButton>
                <span
                  v-tooltip.top="{
                    value: lang.copyValidate,
                    class: 'custom-tooltip',
                    disabled: (slotProps.data.referenceId==''),
                  }"
                >
                  <PvButton
                    type="button"
                    class="p-button-secondary p-button-text p-0 mr-2"
                    @click="copyItemDialog(slotProps.data)"
                    :disabled="isDocumentLocked || (slotProps.data.referenceId!='')"
                  >
                    <icon-file-blank-copy-add-plus
                      iconColor="secondary"
                      width="24"
                      height="24"
                    />
                  </PvButton>
                </span>
                
                <PvButton
                  type="button"
                  class="p-button-danger p-button-text p-0 mr-0"
                  @click="deleteRow(slotProps.data.id)"
                >
                  <icon-trash-delete-bin iconColor="secondary" width="24" height="24" />
                </PvButton>
              </template>
            </PvColumn>

            <template #empty>
              <div class="w-full" style="text-align: center">
                <label>{{ this.lang.tableEmpty }}</label>
              </div>
            </template>
          </PvDataTable>
        </base-card>
      </section>

      <base-card
        :titleHeader="lang.titlePay"
        :isVisibleExpandButton="true"
        :isHideBackground="true"
        :isVisibleCard="true"
        class="col-12 pr-0 pl-0 pb-0"
      >
        <div class="flex flex-wrap" :class="textFormClass">
          <div class="flex lg:col-6 md:col-12 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0 align-items-center">
            <div class="lg:col-8 md:col-7 col-7 p-0" :class="classPayType">
              <label for="payType">{{ lang.payType }}</label>
              <PvDropdown
                id="payType"
                class="inputfield w-full mt-1"
                :options="payTypes"
                optionValue="code"
                optionLabel="name"
                v-model="selectedPayType"
                @change="selectPayType"
                :class="{ 'p-inputtext-disabled': isDocumentLocked }"
                :disabled="isDocumentLocked"
              />
            </div>
            <div class="field-checkbox mb-0 mt-4 ml-3" v-if="selectedPayType == 'Cheque'">
              <PvCheckbox id="isReceipts" v-model="isReceipts" :binary="true" />
              <label for="isReceipts">{{ lang.receipts }}</label>
            </div>
          </div>

          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
            <label for="netTotalAmount">{{ lang.totalAmount }}</label>
            <PvInputNumber
              id="netTotalAmount"
              class="inputfield w-full mt-1"
              inputClass="p-inputtext-disabled text-right"
              v-model="netTotalAmount"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              disabled
            />
          </div>

          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
            <label>{{ bookBankLabel }}</label>
            <PvInputText
              id="payBookBankCode"
              type="text"
              class="inputfield w-full mt-1"
              v-model="payBookBankCode"
              @keypress="bookBankError = false"
              :class="{ 'p-invalid': bookBankError }"
              v-if="selectedPayType == 'Cheque'"
            />
            <base-book-bank-auto-complete
              id="selectedBookbank"
              v-model="selectedBookbank"
              class="inputfield w-full mt-1"
              @load-finish="bookBankDataReady"
              @item-select="selectBookbank()"
              :disabled="isDisableBookbank"
              :inputClass="classDisableBookbank"
              :class="{ 'p-invalid': bookBankError }"
              v-else
            />
            <small v-if="bookBankError" id="bookBank-help" class="p-error">
              {{ validateNoBank }}
            </small>
          </div>

          <div
            class="lg:col-6 md:col-12 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0"
            v-if="selectedPayType != 'CreditCard'"
          >
            <label for="payDate">{{ payDateLabel }}</label>
            <PvCalendar
              id="payDate"
              v-model="payDate"
              class="inputfield w-full mt-1"
              dateFormat="dd/mm/yy"
              showIcon
              :disabled="isDisablePayDate"
              :inputClass="classDisablePayDate"
            />
          </div>
          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0" v-else></div>

          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
            <label for="payBookBankBranch">{{ lang.branch }}</label>
            <PvInputText
              id="payBookBankBranch"
              type="text"
              class="inputfield w-full mt-1"
              v-model="payBookBankBranch"
              :disabled="isDisablePayBookBankBranch"
              :class="{
                'p-invalid': bookBankBranchError,
                'p-inputtext-disabled': isDisablePayBookBankBranch,
              }"
              @keypress="bookBankBranchError = false"
            />
            <small v-if="bookBankBranchError" id="bookBankBranch-help" class="p-error">
              {{ lang.validateNoBankBranch }}
            </small>
          </div>

          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
            <label for="payReference">{{ payRefLabel }}</label>
            <PvInputText
              id="payReference"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="payReference"
              :disabled="isDisablePayReference"
              :class="{
                'p-invalid': payReferenceError,
                'p-inputtext-disabled': isDisablePayReference,
              }"
              @keypress="payReferenceError = false"
            />
            <small v-if="payReferenceError" id="payReference-help" class="p-error">
              {{ validateNoPayReference }}
            </small>
          </div>

          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
            <label for="paidBy">{{ lang.paidBy }}</label>
            <PvInputText
              id="paidBy"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="paidBy"
            />
          </div>

          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
            <label for="paidByAddress">{{ lang.paidByAddress }}</label>
            <PvInputText
              id="paidByAddress"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="paidByAddress"
            />
          </div>

          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
            <label for="remark3">{{ lang.remark1 }}</label>
            <PvInputText
              id="remark3"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="remark3"
            />
          </div>

          <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2 lg:pr-4 md:pr-4 pt-2 pb-0">
            <label for="remark4">{{ lang.remark2 }}</label>
            <PvInputText
              id="remark4"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="remark4"
            />
          </div>
        </div>
      </base-card>
      
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import BaseBookBankAutoComplete from "../../../components/bank/BaseBookBankAutoComplete.vue";
import ReceiptProductDialog from "../../../components/AR/receipt/ReceiptProductDialog.vue";
import ReceiptPendingDialog from "../../../components/AR/receipt/ReceiptPendingDialog.vue";
import ReceiptCalculatePenaltyDialog from "../../../components/AR/receipt/ReceiptCalculatePenaltyDialog.vue";
import ReceiptGuaranteeDialog from "../../../components/AR/receipt/ReceiptGuaranteeDialog.vue";
import ReceiptOtherDialog from "../../../components/AR/receipt/ReceiptOtherDialog.vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import usePageDirty from "../../../hooks/pageDirty";
import useGetRoleByBook from "../../../hooks/getRoleByBook.js";
import StockKeycardDialog from "../../../components/AR/stockKeycard/StockKeycardDialog.vue";
// import _ from 'underscore';
export default {
  props: ["id", "projectCode"],
  components: {
    BaseBookBankAutoComplete,
    ReceiptProductDialog,
    ReceiptPendingDialog,
    ReceiptCalculatePenaltyDialog,
    ReceiptGuaranteeDialog,
    ReceiptOtherDialog,
    StockKeycardDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textFormClass, textTableClass } = useTextStyle();
    const {
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getCurrentPeriod,
    } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.receipt.listing,
          ...lang.th.ar.receipt.edit,
          ...lang.th.ar.receipt.form,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.receipt.listing,
          ...lang.en.ar.receipt.edit,
          ...lang.en.ar.receipt.form,
        };
      }
    });

    const {
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
    } = useGetFormPrint();
    const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();

    const { compareObject, isCompareObject } = usePageDirty();

    return {
      lang: mergeLang,

      textFormClass,
      textTableClass,

      formatCurrency,
      formatDate,
      formatDateToISOString,
      getCurrentPeriod,

      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,

      compareObject,
      isCompareObject,
      getRoleByBookByCode,
      isSharedRoleByBookReady,
    };
  },
  data() {
    return {
      //data
      code: "",
      date: null,
      dateWithFormat: "",
      unit: null,
      selectedUnit: null,
      unitError: false,
      isSharedUnitReady: false,
      customerId: "",
      customerName: "",
      customerMobile: "",
      refCode: "",
      livingTypes: [
        { name: this.lang.all, code: "All" },
        { name: this.lang.owner, code: "Owner" },
        { name: this.lang.rential, code: "Rential" },
      ],
      selectedLivingType: "All",
      details: [],
      selectedReceiptDetail: null,
      payAmount: 0,

      payTypes: [
        { name: this.lang.cashType, code: "Cash" },
        { name: this.lang.transferType, code: "Transfer" },
        { name: this.lang.chequeType, code: "Cheque" },
        { name: this.lang.creditCardType, code: "CreditCard" },
        { name: this.lang.depositType, code: "Deposit" },
        { name: this.lang.ePayType, code: "DigitalPayment" }, //E_Pay
        { name: this.lang.otherType, code: "Other" },
      ],
      selectedPayType: "Cash",
      paidStatus: "",
      isReceipts: true,
      selectedBookbank: null,
      payDate: null,
      payBookBankBranch: "",
      payReference: "",
      paidBy: "",
      paidByAddress: "",
      isDisableBookbank: false,
      isDisablePayDate: false,
      isDisablePayBookBankBranch: false,
      isDisablePayReference: false,
      remark3: "",
      remark4: "",
      employeeId: "",
      employeeName: "",
      shouldUseDeposit: false,
      bookBankError: false,
      bookBankBranchError: false,
      payReferenceError: false,
      validateNoPayReference: "",
      shouldUseGuarantee: false,
      guaranteeDetailId: "",
      objToCompare: null,
      payBookBankCode: "",

      //Dialog
      showProductDialog: false,
      showCustomerDialog: false,
      showPendingDialog: false,
      showCalculatePenaltyDialog: false,
      showValidateDialog: false,
      validateWording: "",
      showCancelDialog: false,
      reasonCancel: "",
      reasonCancelError: false,
      showGuaranteeDialog: false,
      showReceiptOtherDialog: false,
      showFormPrintModal: false,
      path: "",
      isCreateNewDocument: false,

      baseDialogClassTitle: "",
      titleConfirm: this.lang.notificationDialogHeader,

      isCopyItem: false,
      copyItems: null,
      copyQuantity: 1,

      detailError: false,
      receiptBillPayment: null,
      transferSlip: null,

      //stock keycard
      selectedProduct: null,
      selectedStockKeycard: null,
      showStockKeycardDialog: false,
      keyToReLoad: 0,
    };
  },
  async created() {
    await this.$store.dispatch("bank/setTypeInOut", { type: 1 }); // 1="PayIn" ,-1= "PayOut"
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    this.selectedUnit = null;
    await this.roleByBookDataReady();
 
    // console.log("this.$route.query=",this.$route.query);
    if(this.$route.query.unitId){   
      if(this.$route.query.transferSlipId){
        this.transferSlip = {    
            id: this.$route.query.id,     
            transferSlipId: this.$route.query.transferSlipId,   
            unitId: this.$route.query.unitId,
            status: "receiptCreated",
            reason: "",
        };
      } 
      else
      { 
        this.receiptBillPayment = {
            unitId: this.$route.query.unitId,
            ref1: this.$route.query.unitId,
            ref2: this.$route.query.ref2,
            amount: this.$route.query.amount,
            type: "manual",
            payDate: this.$route.query.payDate,
            bookBankNumber: this.$route.query.bookBankNumber
        };
      } 
      this.checkShouldSetData();
    }
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.setLocalSearch();
      this.$router.push({name: "ar-receipt"});
    },
    async createNewDocument() {
      this.showValidateDialog = false;
      this.setLoading(true);
      await this.initiateData();
      this.selectedUnit = null;
      this.receiptBillPayment = null;
      this.receiptTransferSlip = null;

      if (this.isCreateDocument) {
        await this.$router.push({name: "ar-receipt-edit", params:{id: "create"}, query: {uniqueKeyId: this.uniqueKeyId}});
      } else {
        await this.getUniqueKey();
        await this.$router.push({name: "ar-receipt-edit", params:{id: "create"}, query: {uniqueKeyId: this.receiptUniqueKey.id}});
      }
      if (this.isSharedUnitReady) {
        this.setLoading(false);
      }

      await this.setCompareData();
    },
    async initiateData() {
      this.code = "";
      this.date = new Date();
      this.dateWithFormat = this.formatDate(this.date);
      // this.selectedUnit = null;
      this.unitError = false;
      this.customerId = "";
      this.customerName = "";
      this.customerMobile = "";
      this.refCode = "";
      this.selectedLivingType = "All";
      this.details = [];
      this.selectedReceiptDetail = null;
      this.selectedPayType = "Cash";
      this.paidStatus = "";
      this.isReceipts = true;
      this.selectedBookbank = null;
      this.payDate = new Date();
      this.payBookBankBranch = "";
      this.payReference = "";
      this.paidBy = "";
      this.paidByAddress = "";
      this.checkDisablePayData();
      this.remark3 = "";
      this.remark4 = "";
      this.employeeId = this.selectedEmployee.id;
      this.employeeName = this.selectedEmployee.name;
      this.shouldUseDeposit = false;
      this.bookBankError = false;
      this.bookBankBranchError = false;
      this.payReferenceError = false;
      this.validateNoPayReference = "";
      this.shouldUseGuarantee = false;
      this.guaranteeDetailId = "";
      this.payAmount = 0;
      this.payBookBankCode = "";
      this.copyItems = null;

      this.showProductDialog = false;
      this.showPendingDialog = false;
      this.showCalculatePenaltyDialog = false;
      this.showGuaranteeDialog = false;
      this.showReceiptOtherDialog = false;
      this.showCancelDialog = false;
      this.showFormPrintModal = false;
      this.path = "";
      this.isCreateNewDocument = false;
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.notificationDialogHeader;

      //stock keycard
      this.selectedProduct = null,
      this.selectedStockKeycard = null,
      this.showStockKeycardDialog = false,
      this.keyToReLoad = 0,

      this.$store.dispatch("arReceipt/resetReceipt");
      this.$store.dispatch("arDeposit/resetAvailableDepositByUnit");
      this.$store.dispatch("arInvoice/resetPendingByUnitAndLivingType");
      this.$store.dispatch("arInvoice/resetPendingList");
      this.$store.dispatch("arGuarantee/resetAvailableGuaranteeByUnit");

      // console.log(_.isEqual(this.payload, this.objToCompare));
    },
    async getUniqueKey() {
      try {
        // console.log("getUniqueKey");
        await this.$store.dispatch("arReceipt/getReceiptUniqueKey");
        // console.log(this.receiptUniqueKey);
      } catch (error) {
        // this.$toast.add({
        //   severity: "error",
        //   summary: this.lang.notificationDialogHeader,
        //   detail: error,
        //   life: 5000,
        // });
        // console.log(error);
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
      }
    },
    async setData() {
      this.code = this.receipt.code;
      this.date = new Date(this.receipt.date);
      this.dateWithFormat = this.formatDate(this.date);
      this.customerId = this.receipt.customerId;
      this.customerName = this.receipt.customerName;
      this.customerMobile = this.receipt.customerMobileNumber;
      this.refCode = this.receipt.refCode;
      this.selectedLivingType = "All";
      this.details = this.receipt.receiptDetails.sort(function (a, b) {
        return a.itemNo - b.itemNo;
      });

      this.selectedPayType =
        this.receipt.payType == "E_Pay" ? "DigitalPayment" : this.receipt.payType;
      this.checkDisablePayData();
      this.paidStatus = this.receipt.paidStatus;
      if (this.selectedPayType == "Cheque" && this.paidStatus.trim() == "") {
        this.isReceipts = true;
      } else {
        this.isReceipts = false;
      }
      this.selectedBookbank = this.sharedBookBanks.find(
        (bookbank) => bookbank.code === this.receipt.payBookBankCode
      );
      this.payBookBankCode = this.receipt.payBookBankCode;
      this.payDate = new Date(this.receipt.payDate);
      this.payBookBankBranch = this.receipt.payBookBankBranch;
      this.payReference = this.receipt.payReference;

      this.paidBy = this.receipt.paidBy; //ลง remark1
      this.paidByAddress = this.receipt.paidByAddress; //ลง remark2
      this.remark3 = this.receipt.remark3; //ลง remark3
      this.remark4 = this.receipt.remark4; //ลง remark4
      this.employeeId = this.receipt.employeeId;
      this.employeeName = this.receipt.employeeName;

      this.setLoading(false);
    },
    async setDataUnit() {
      this.selectedUnit = this.sharedUnits.find(
        (unit) => unit.id === this.receipt.unitId
      );
      if (this.selectedUnit) {
        await this.getAvailableDepositByUnit();
        await this.getAvailablePendingByUnit();
      }

      this.setLoading(false);
    },
    async checkShouldSetData() {
      if (this.receipt && this.isSharedBookBankReady && this.isSharedUnitReady) {
        await this.setData();
        await this.setDataUnit();
      } else if (!this.isSharedUnitReady && this.receipt && this.isSharedBookBankReady) {
        await this.setData();        
      } else if (this.isSharedUnitReady && this.isCreateDocument && this.isSharedBookBankReady) {
        if(this.receiptBillPayment){
          await this.setDataBillPayment();   
        }
        else if(this.transferSlip){
          await this.setDataTransferSlip();  
        }
        this.setLoading(false);        
      }
      await this.setCompareData();
    },
    async getReceipt() {
      this.setLoading(true);
      try {
        let where = {
          projectId: this.selectedProject.id,
          id: this.id,
        };

        const payload = {
          where,
        };

        // console.log("getPurchaseReceiptList", payload);
        await this.$store.dispatch("arReceipt/getReceipt", payload);
      } catch (error) {
        // this.$toast.add({
        //   severity: "error",
        //   summary: this.lang.notificationDialogHeader,
        //   detail: error,
        //   life: 5000,
        // });
        // console.log(error);
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    unitDataReady() {
      this.isSharedUnitReady = true;
      this.checkShouldSetData();
    },
    bookBankDataReady() {
      this.isSharedBookBankReady = true;
      this.checkShouldSetData();
    },
    async selectUnit() {
      // console.log("this.selectedUnit=",this.selectedUnit);
      this.unitError = false;
      await this.initiateData();

      this.customerId = this.selectedUnit.customerId;
      this.customerName = this.selectedUnit.customerName;
      this.customerMobile = this.selectedUnit.customerMobilePhone;

      await this.getAvailableDepositByUnit();
      await this.getAvailablePendingByUnit();
      await this.getUnitResidents();

      if(this.receiptBillPayment && (this.selectedUnit.id != this.receiptBillPayment.unitId)){
        this.receiptBillPayment = null;
      }
      if(this.transferSlip && (this.selectedUnit.id != this.transferSlip.unitId)){
        this.transferSlip = null;
      }
    },
    async getUnitResidents() {
      this.isLoading = true;
      // console.log("payload", this.payload);

      try {
        let payload = {
          page: 1,
          where: {
            projectId: this.selectedProject.id,
            unitId: this.selectedUnit.id,
          },
        };

        await this.$store.dispatch("unitResident/resetUnitResidents");
        await this.$store.dispatch("unitResident/getUnitResidents", payload);
      } catch (error) {
        // this.$toast.add({
        //   severity: "error",
        //   summary: this.lang.notificationDialogHeader,
        //   detail: error,
        //   life: 5000,
        // });
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
      }
      // console.log(this.residents);
      this.isLoading = false;
    },
    async getAvailableDepositByUnit() {
      try {
        let payload = {
          unitId: this.selectedUnit.id,
        };

        await this.$store.dispatch("arDeposit/getAvailableDepositByUnit", payload);
      } catch (error) {
        // this.$toast.add({
        //   severity: "error",
        //   summary: this.lang.notificationDialogHeader,
        //   detail: error,
        //   life: 5000,
        // });
        // console.log(error);
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
      }
    },
    async getAvailablePendingByUnit() {
      try {
        let payload = {
          unitId: this.selectedUnit.id,
        };

        await this.$store.dispatch(
          "arInvoice/getPendingAmountByUnitAndLivingType",
          payload
        );
      } catch (error) {
        // this.$toast.add({
        //   severity: "error",
        //   summary: this.lang.notificationDialogHeader,
        //   detail: error,
        //   life: 5000,
        // });
        // console.log(error);
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
      }
    },
    selectPayType() {
      if (this.validatePayType()) {
        if(this.isCreateDocument || !this.selectedBookbank) { 
          this.selectedPayType = "Cash";
        }
        else {
          this.selectedPayType = this.receipt.payType == "E_Pay" ? "DigitalPayment" : this.receipt.payType;
        }
        return;
      }

      this.isReceipts = true;
      this.selectedBookbank = null;
      this.payDate = new Date();
      this.payBookBankBranch = "";
      this.payReference = "";
      this.bookBankError = false;
      this.bookBankBranchError = false;
      this.payReferenceError = false;
      this.payBookBankCode = "";

      this.shouldUseDeposit = false;
      this.shouldUseGuarantee = false;
      this.guaranteeDetailId = "";
      this.checkDisablePayData();
    },
    checkDisablePayData() {
      if (this.selectedPayType == "Cash") {
        this.isDisableBookbank = true;
        this.isDisablePayDate = true;
        this.isDisablePayBookBankBranch = true;
        this.isDisablePayReference = true;
      }
      if (this.selectedPayType == "Transfer") {
        this.isDisableBookbank = false;
        this.isDisablePayDate = false;
        this.isDisablePayBookBankBranch = false;
        this.isDisablePayReference = false;
      }
      if (this.selectedPayType == "Cheque") {
        this.isDisableBookbank = false;
        this.isDisablePayDate = false;
        this.isDisablePayBookBankBranch = false;
        this.isDisablePayReference = false;
      }
      if (this.selectedPayType == "CreditCard") {
        this.isDisableBookbank = false;
        this.isDisablePayDate = true;
        this.isDisablePayBookBankBranch = false;
        this.isDisablePayReference = false;
      }
      if (this.selectedPayType == "Deposit") {
        this.isDisableBookbank = true;
        this.isDisablePayDate = true;
        this.isDisablePayBookBankBranch = true;
        this.isDisablePayReference = true;
      }
      if (this.selectedPayType == "DigitalPayment") {
        this.isDisableBookbank = false;
        this.isDisablePayDate = false;
        this.isDisablePayBookBankBranch = false;
        this.isDisablePayReference = false;
      }
      if (this.selectedPayType == "Other") {
        this.isDisableBookbank = true;
        this.isDisablePayDate = true;
        this.isDisablePayBookBankBranch = true;
        this.isDisablePayReference = true;
      }
    },
    selectBookbank() {
      this.payBookBankBranch =
        this.selectedBookbank.bankName + " : " + this.selectedBookbank.branchName;
      this.bookBankError = false;
      this.bookBankBranchError = false;
      this.payBookBankCode = this.selectedBookbank.code;
    },
    deleteRow(id) {
      if (this.validateDocument(true)) {
        return;
      }
      const index = this.details.findIndex((detail) => detail.id === id);
      this.details.splice(index, 1);

      this.updateItemNumber();
    },
    updateItemNumber() {
      let number = 1;
      this.details.forEach((element) => {
        if (element.id) {
          element.itemNo = number;
        }
        number++;
      });
    },
    openProductDialog(data) {
      if (this.validateDocument(true)) {
        return;
      }
      if (this.isSharedUnitReady && this.validateUnit()) {
        return;
      }

      // console.log(data);
      if (data == "create") {
        this.selectedReceiptDetail = null;
      } else {
        this.selectedReceiptDetail = data;
      }

      this.showProductDialog = true;
    },
    closeProductDialog() {
      this.showProductDialog = false;
      this.selectedReceiptDetail = null;
    },
    async selectProduct(data) {
      if(data.productCode.substring(0,1).toLowerCase() == "k") {
        if(!this.controlStockKeycard) {
          await this.getControlStockKeycard();
        }
        if(this.controlStockKeycard && this.controlStockKeycard.isControlStockKeycard.toLowerCase() == "y") {
          this.keyToReLoad++;
          this.selectedProduct = data;
          this.showProductDialog = false;
          this.showStockKeycardDialog = true;
          return;
        }
      } 
      this.setLoading(true);
      this.addDetail(data);
      this.showProductDialog = false;
      this.detailError = false;
      this.setLoading(false);
    },
    openPendingDialog() {
      if (this.validateDocument(true)) {
        return;
      }
      if (this.validateUnit()) {
        return;
      }
      // this.setLoading(true);

      this.showPendingDialog = true;
    },
    selectPending(data) {
      this.showPendingDialog = false;
      // console.log('edit',data);
      if (data) {
        data.forEach((invoice) => {
          let number = this.countDetails;
          this.refCode = "Bill No: " + invoice.documentBookCode + " /" + invoice.code;
          let receiptDetail = null;
          receiptDetail = this.details.find(
            (detail) => detail.referenceId === invoice.detailId
          );

          if (receiptDetail == null) {
            let detail = {
              id: "dummy" + (number + 1),
              itemNo: (number + 1),
              // headerId: this.id,
              productId: invoice.productId,
              productCode: invoice.productCode,
              productName: invoice.productName,
              productUnitId: invoice.productUnitId,
              productUnitCode: invoice.productUnitCode,
              productUnitName: invoice.productUnitName,
              quantity: invoice.quantity,
              unitPrice: invoice.unitPrice,
              discountDescription: invoice.discountDescription,
              discountAmount: invoice.discountAmount,
              totalAmount: invoice.pendingAmount,
              netAmount: invoice.pendingAmount - invoice.discountAmount,
              invoiceAmount: invoice.pendingAmount,
              remark: invoice.detailRemark,
              referenceId: invoice.detailId,
              referenceType: "AMT",
              referenceTypeDescription: "Import From Receipt",
              period: invoice.period,
              tenantId: invoice.tenantId,
            };
            this.details.push(detail);
          } else {
            receiptDetail.totalAmount = invoice.pendingAmount;
            receiptDetail.discountDescription = invoice.discountDescription;
            receiptDetail.discountAmount = invoice.discountAmount;
            receiptDetail.netAmount = invoice.pendingAmount - invoice.discountAmount;
          }
        });
      }
      this.detailError = false;
    },
    openCalculatePenaltyDialog() {
      if (this.validateDocument(true)) {
        return;
      }
      if (this.validateUnit()) {
        return;
      }

      this.showCalculatePenaltyDialog = true;
    },
    async calculatePenalty(data) {
      this.setLoading(true);
      this.showCalculatePenaltyDialog = false;
      // console.log('calculatePenalty',data);
      try {
        let payload = {
          projectId: this.selectedProject.id,
          employeeId: this.selectedEmployee.id,
          period: this.getCurrentPeriod(),
          calculationDate: data,
          unitId: this.selectedUnit.id,
        };
        await this.$store.dispatch("arNotice/addNoticeByUnit", payload);
        // console.log(this.calculatedPenalty);

        if (this.calculatedPenalty.statusID == 1) {
          // this.$toast.add({
          //   severity: "success",
          //   summary: this.lang.saveSuccess,
          //   detail: "",
          //   life: 5000,
          // });
          this.baseDialogClassTitle = "p-text-success";
          this.titleConfirm = this.lang.calculateSuccess;
          this.validateWording = this.lang.calculatePenaltySuccess;
          this.showValidateDialog = true;
        } else {
          // this.$toast.add({
          //   severity: "error",
          //   summary: this.lang.notificationDialogHeader,
          //   detail: this.lang.noDetail,
          //   life: 5000,
          // });
          // console.log(this.lang.noDetail);
          this.baseDialogClassTitle = "";
          this.titleConfirm = this.lang.notificationDialogHeader;
          this.validateWording = this.lang.noDetail;
          this.showValidateDialog = true;
        }
      } catch (error) {
        // this.$toast.add({
        //   severity: "error",
        //   summary: this.lang.notificationDialogHeader,
        //   detail: error,
        //   life: 5000,
        // });
        // console.log(error);
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
      }
      this.setLoading(false);
    },
    openCustomerDialog() {
      if (this.validateDocument(true)) {
        return;
      }
      if (this.validateUnit()) {
        return;
      }

      if (this.residents.length <= 1) {
        this.isResident = false;
        this.validateWording = this.lang.validateNoResident;
        this.showValidateDialog = true;
        return;
      }

      this.showCustomerDialog = true;
    },
    selectCustomer(data) {
      this.showCustomerDialog = false;

      this.customerId = data.customerId;
      this.customerName = data.customerName;
      this.customerMobile = data.phone;
    },
    async getPendingByAmount() {
      if (this.validateDocument(true)) {
        return;
      }
      if (this.validateUnit()) {
        return;
      }

      this.setLoading(true);
      try {
        let payload = {
          projectId: this.selectedProject.id,
          unitId: this.selectedUnit.id,
          residentType:
            this.selectedLivingType == "Rential" ? "Tenant" : this.selectedLivingType,
          dateAt: this.formatDateToISOString(this.date),
          payAmount: this.payAmount,
        };

        await this.$store.dispatch("arInvoice/getPendingByAmount", payload);

        if (this.pendingByAmount) {
          this.setPendingByAmountToDetail();
        }
      } catch (error) {
        // this.$toast.add({
        //   severity: "error",
        //   summary: this.lang.notificationDialogHeader,
        //   detail: error,
        //   life: 5000,
        // });
        // console.log(error);
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;

        this.setLoading(false);
      }
      this.detailError = false;
    },
    setPendingByAmountToDetail() {
      let details = [];
      let detail = this.pendingByAmount;
      let number = 1;

      detail.forEach((invoice) => {
        if (invoice) {
          invoice.itemNo = number;
          invoice = { ...invoice, id: "dummy" + number };
          details.push(invoice);
        }
        number++;
      });
      this.details = details;
      // console.log(this.details);

      this.setLoading(false);
    },
    confirmBaseDialog() {
      this.showValidateDialog = false;
      this.clearBaseDialog();
    },
    closeBaseDialog() {
      this.showValidateDialog = false;
      this.clearBaseDialog();
    },
    async askForCancel() {
      if (this.validateDocument()) {
        return;
      }
      if(this.roleByDocumentBook && this.roleByDocumentBook.cancel != "Y") {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleCancel;
        this.showValidateDialog = true;
        return true;
      }
      this.clearBaseDialog();
      this.showCancelDialog = true;
      this.showValidateDialog = true;
    },
    async cancel() {
      if (this.reasonCancel.trim() == "") {
        this.reasonCancelError = true;
        return;
      } else {
        this.showValidateDialog = false;
      }

      this.setLoading(true);
      try {
        await this.$store.dispatch("arReceipt/cancelReceipt", {
          id: this.id,
          cancelReasons: this.reasonCancel,
        });

        // this.$toast.add({
        //   severity: "success",
        //   summary: this.lang.cancelSuccess,
        //   detail: "",
        //   life: 5000,
        // });
        this.showCancelDialog = false;
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.cancelSuccess;
        this.validateWording = `${this.lang.cancelReceiptSuccess.replace(
          ":code",
          this.code.trim()
        )}`;
        this.showValidateDialog = true;
      } catch (error) {
        // this.$toast.add({
        //   severity: "error",
        //   summary: this.lang.notificationDialogHeader,
        //   detail: error,
        //   life: 5000,
        // });
        // console.log(error);
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
      }
      this.setLoading(false);
    },
    validateUnit() {
      if (!this.selectedUnit) {
        this.validateWording = this.lang.validateNoUnitSelect;
        this.showValidateDialog = true;
        this.unitError = true;
        return true;
      }
      // console.log(this.selectedUnit);
      // if (this.selectedUnit.isActive == "N") {
      //   this.validateWording = this.lang.validateInactiveUnitSelect;
      //   this.showValidateDialog = true;
      //   if (this.isCreateDocument) {
      //     this.selectedUnit = null;
      //     this.unitError = true;
      //   }
      //   return true;
      // }

      return false;
    },
    wordingValidate() {
      if(!this.selectedUnit) {
        return this.lang.validateDropDownNoSelect + this.lang.unitCode;
      }

      if (this.countDetails <= 0) {
        return this.lang.validateDetail;
      }

      if (this.netTotalAmount < 0) {
        return this.lang.validateNetTotalAmount;
      }
    },
    validateData() {
      // if (this.validateUnit()) {
      //   return true;
      // }
      let returnStatus = false;

      if(!this.selectedUnit) {
        this.unitError = true;
        returnStatus = true;
      }

      if (this.countDetails <= 0) {
        this.detailError = true;
        returnStatus = true;
      }

      if (this.netTotalAmount < 0) {
        returnStatus = true;
      }

      if(returnStatus) {
        this.baseDialogClassTitle = "p-error";
        this.titleConfirm = this.lang.validateSaveTitle;
        this.validateWording = this.wordingValidate();
        this.showValidateDialog = true;
        return true;
      }

      if (this.validatePayType()) {
        return true;
      }

      if (this.selectedPayType == "Transfer") {
        if (!this.selectedBookbank) {
          this.validateWording = this.validateNoBank;
          this.showValidateDialog = true;
          this.bookBankError = true;
          return true;
        } else if (!this.payBookBankBranch) {
          this.validateWording = this.lang.validateNoBankBranch;
          this.showValidateDialog = true;
          this.bookBankBranchError = true;
          return true;
        }
      } else if (this.selectedPayType == "Cheque") {
        if (this.payBookBankCode == "") {
          this.validateWording = this.validateNoBank;
          this.showValidateDialog = true;
          this.bookBankError = true;
          return true;
        } else if (!this.payBookBankBranch) {
          this.validateWording = this.lang.validateNoBankBranch;
          this.showValidateDialog = true;
          this.bookBankBranchError = true;
          return true;
        } else if (!this.payReference) {
          let type = this.lang.chequeNo;
          this.validateNoPayReference = this.lang.validateNoPayReference.replace(
            ":no",
            type
          );
          this.validateWording = this.validateNoPayReference;
          this.showValidateDialog = true;
          this.payReferenceError = true;
          return true;
        }
      } else if (this.selectedPayType == "CreditCard") {
        if (!this.selectedBookbank) {
          this.validateWording = this.validateNoBank;
          this.showValidateDialog = true;
          this.bookBankError = true;
          return true;
        } else if (!this.payBookBankBranch) {
          this.validateWording = this.lang.validateNoBankBranch;
          this.showValidateDialog = true;
          this.bookBankBranchError = true;
          return true;
        } else if (!this.payReference) {
          let type = this.lang.creditCardNo;
          this.validateNoPayReference = this.lang.validateNoPayReference.replace(
            ":no",
            type
          );
          this.validateWording = this.validateNoPayReference;
          this.showValidateDialog = true;
          this.payReferenceError = true;
          return true;
        }
      } else if (this.selectedPayType == "Deposit") {
        if (this.depositLeftAmount < this.netTotalAmount) {
          this.validateWording = [
            this.lang.validateNoDeposit,
            this.lang.depositAmount.replace(":amount", this.depositLeftAmount),
          ];
          this.showValidateDialog = true;
          this.shouldUseDeposit = false;
          return true;
        } else {
          this.shouldUseDeposit = true;
        }
      }

      return false;
    },
    async checkBeforeSave() {
      this.clearBaseDialog();
      // console.log(this.details);
      // let isCheck = true;
      if (this.validateRole()) {
        return;
      }
      if (this.validateDocument(true)) {
        return;
      }
      if (this.validateData()) {
        return;
      }

      if (this.selectedPayType == "Other") {
        await this.getAvailableGuaranteeByUnit();
        await this.checkGuarantee();
      } else {
        await this.save();
      }
    },
    async save() {
      this.setLoading(true);
      try {
        // console.log("this.payload", this.payload);
        await this.$store.dispatch("arReceipt/createOrUpdateReceipt", this.payload);

        // this.$toast.add({
        //   severity: "success",
        //   summary: this.lang.saveSuccess,
        //   detail: "",
        //   life: 5000,
        // });
        this.clearBaseDialog();
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.saveSuccess;
        this.validateWording = this.saveEditSuccess;
        this.showValidateDialog = true;

        if (this.isCreateDocument) {
          this.$router.replace({name: "ar-receipt-edit", params:{id: this.receipt.id}});
          await this.setCompareData();
        }

      } catch (error) {
        // this.$toast.add({
        //   severity: "error",
        //   summary: this.lang.notificationDialogHeader,
        //   detail: error,
        //   life: 5000,
        // });
        // console.log(error);
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
      }
      this.setLoading(false);

    },
    async getAvailableGuaranteeByUnit() {
      try {
        let payload = {
          unitId: this.selectedUnit.id,
        };

        await this.$store.dispatch("arGuarantee/getAvailableGuaranteeByUnit", payload);
      } catch (error) {
        // this.$toast.add({
        //   severity: "error",
        //   summary: this.lang.notificationDialogHeader,
        //   detail: error,
        //   life: 5000,
        // });
        // console.log(error);
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
      }
    },
    async checkGuarantee() {
      this.clearBaseDialog();
      if (this.availableGuarantee && this.availableGuarantee.amountLeft > 0) {
        this.shouldUseGuarantee = true;
        this.validateWording = this.lang.askToUseGuarantee;
        this.showValidateDialog = true;
      } else {
        this.shouldUseGuarantee = false;
        this.guaranteeDetailId = "";
        this.showReceiptOtherDialog = true;
      }
    },
    useGuarantee() {
      this.showValidateDialog = false;
      this.shouldUseGuarantee = true;
      this.showGuaranteeDialog = true;
    },
    async selectGuarantee(data) {
      // console.log("selectGuarantee=",data);
      this.showGuaranteeDialog = false;
      this.shouldUseGuarantee = true;
      this.guaranteeDetailId = data.receiptDetailId;
      this.payReference = data.receiptCode;
      let remarkDeductGuarantee = this.lang.remarkDeductGuarantee + data.receiptCode;
      this.remark3 = this.remark3 ? this.remark3 + " " + remarkDeductGuarantee : remarkDeductGuarantee; 
      await this.save();
    },
    notUseGuarantee() {
      this.clearBaseDialog();
      this.showValidateDialog = false;
      this.showReceiptOtherDialog = true;
    },
    async selectReceiptOther(data) {
      // console.log("selectReceiptOther=",data);
      this.showReceiptOtherDialog = false;
      this.payReference = data.code;    
      this.remark3 = this.remark3 ? this.remark3 + " " + data.remark : data.remark;  
      await this.save();
    },
    async getPrintForm() {
      if(this.roleByDocumentBook && this.roleByDocumentBook.print != "Y") {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validatePrintPermission;
        this.showValidateDialog = true;
        return true;
      }
      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: "INV",
      };
      const payload = { where };
      await this.getFormPrints(payload);

      if (this.filteredFormPrints.length == 1) {
        this.path = this.filteredFormPrints[0].path + ".pdf";
        this.printReceipt();
      } else {
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path + ".pdf";
      //this.categoryPrint = event.category;

      this.printReceipt();

      this.showFormPrintModal = false;
    },
    async printReceipt() {
      this.setLoading(true);
      try {
        let isPrinted = this.receipt.postStep.trim() == "" ? "false" : "true";
        let mobilePhone = this.receipt.customerMobileNumber.trim();
        mobilePhone = mobilePhone.substring(1, mobilePhone.length);

        let parameter = {
          ProjectId: this.receipt.projectId,
          DateStart: "",
          DateEnd: "",
          CodeStart: this.receipt.code.trim(),
          CodeEnd: this.receipt.code.trim(),
          ReceiptId: this.receipt.id,
        };

        let payload = {
          path: this.path,
          fileName: "Receipt",
          parameter: JSON.stringify(parameter),

          projectId: this.receipt.projectId,
          documentBookId: this.receipt.documentBookId,
          codeStart: this.receipt.code.trim(),
          codeEnd: this.receipt.code.trim(),
          dateStart: "",
          dateEnd: "",
          id: this.receipt.id,
          projectCode: this.receipt.projectCode.trim(),
          unitId: this.receipt.unitId,
          unitCode: this.receipt.unitCode.trim(),
          unitAddressNo: this.receipt.unitAddressNo.trim(),
          mobilePhone: mobilePhone,
          isPrinted: isPrinted,

          code: this.receipt.code.trim(),
          projectName: this.selectedProject.nameTh.trim(),
          customerId: this.receipt.customerId.trim(),
          customerCode: this.receipt.customerCode.trim(),
          customerName: this.receipt.customerName.trim(),
        };
        // console.log("print receipt", payload);
        await this.$store.dispatch("arReceipt/printReceipt", payload);

        await this.getPreviewBlob(this.fileBlob);
        await this.getReceipt();
      } catch (error) {
        // this.$toast.add({
        //   severity: "error",
        //   summary: this.lang.notificationDialogHeader,
        //   detail: error,
        //   life: 5000,
        // });
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;

        this.setLoading(false);
      }

      this.setLoading(false);
    },
    async setCompareData() {
      let details = [];

      for (const each of this.details.filter((each) => each.productId)) {
        let detail = null;
        if (each.id.includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        details.push(detail);
      }

      let objToCompare = {
        projectId: this.selectedProject.id,
        refCode: this.refCode,
        date: this.date ? this.formatDateToISOString(this.date) : null,
        customerId: this.customerId,
        employeeId: this.employeeId,
        paidBy: this.paidBy,
        paidByAddress: this.paidByAddress,
        unitId: this.selectedUnit ? this.selectedUnit.id : "",
        payType: this.selectedPayType,
        payReference: this.payReference,
        payBookBankCode: this.payBookBankCode,
        payBookBankBranch: this.payBookBankBranch,
        payDate: this.selectedPayDate,
        remark3: this.remark3,
        remark4: this.remark4,
        shouldCreateCheque: this.shouldCreateCheque,
        shouldUseDeposit: this.shouldUseDeposit,
        shouldUseGuarantee: this.shouldUseGuarantee,
        guaranteeDetailId: this.guaranteeDetailId,
        details: details,
        receiptMapBillPayment: this.receiptBillPayment,
        transferSlip: this.transferSlip,
      };

      if (this.isCreateDocument) {
        objToCompare = {
          ...objToCompare,
          type: "Add",
          receiptUniqueKeyId: this.uniqueKeyId,
        };
      } else {
        objToCompare = { ...objToCompare, id: this.id, type: "Update" };
      }

      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
      // console.log('this.objToCompare',this.objToCompare);
      // console.log('this.payload',this.payload);
      // console.log(this.isPageDirty);
    },
    async askForCreate() {
      if(this.roleByDocumentBook && this.roleByDocumentBook.insert != "Y") {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return true;
      }
      let result = this.isCompareObject(this.objToCompare, this.payload);
      this.clearBaseDialog();
      this.isCreateNewDocument = true;
      if (result) {
        this.titleConfirm = this.lang.createNewReceipt;
        this.validateWording = this.lang.askForCreateNewReceipt;
        this.showValidateDialog = true;
      } else {
        await this.createNewDocument();
      }
    },
    clearBaseDialog() {
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.validateWording = "";
      this.shouldUseDeposit = false;
      this.shouldUseGuarantee = false;
      this.guaranteeDetailId = "";
      this.showCancelDialog = false;
      this.reasonCancel = "";
      this.reasonCancelError = false;
      this.isCopyItem = false;
      this.copyItems = null;
      this.copyQuantity = 1;
    },
    async roleByBookDataReady() {
      if (this.isSharedRoleByBookReady) {
        if (!this.isCreateDocument) {
          await this.getReceipt();
          // console.log("update", this.receipt);
        }
      }
    },
    setLocalSearch() {
      let storageName = `erp_search_ReceiptList_${this.selectedProject.code}_${this.userCode}`;
      const localSearch = localStorage.getItem(storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      // console.log("search=",search);
      localStorage.setItem(storageName, JSON.stringify(search));
    },
    copyItemDialog(data) {
      this.clearBaseDialog();
      this.isCopyItem = true;
      this.copyItems = data;
      this.titleConfirm = this.lang.addEdit;
      this.validateWording = "";
      this.showValidateDialog = true;
    },
    copyItemSelected() {
      this.showValidateDialog = false;
      if (this.copyItems) {
        let period = this.periodToDate(this.copyItems.period);
        for (let index = 1; index <= this.copyQuantity; index++) {
          let periodNext = new Date(
            period.getFullYear(),
            period.getMonth() + 1 + index,
            0
          );
          let detail = {
            id: "dummy" + (this.countDetails + 1),
            headerId: this.copyItems.headerId,
            itemNo: (this.countDetails + 1),
            productId: this.copyItems.productId,
            productCode: this.copyItems.productCode,
            productName: this.copyItems.productName,
            productUnitId: this.copyItems.productUnitId,
            productUnitCode: this.copyItems.productUnitCode,
            productUnitName: this.copyItems.productUnitName,
            quantity: this.copyItems.quantity,
            unitPrice: this.copyItems.unitPrice,
            totalAmount: this.copyItems.totalAmount,
            netAmount: this.copyItems.netAmount,
            remark: this.copyItems.remark,
            paidAmount: 0,
            period:
              (periodNext.getMonth() + 1).toString().padStart(2, "0") +
              "/" +
              periodNext.getFullYear().toString(),
            tenantId: "",
            referenceId: "",
            invoiceAmount: 0,
            discountAmount: 0,
            discountDescription: "",
          };

          this.details.push(detail);
        }
      }
      this.clearBaseDialog();
    },
    periodToDate(data) {
      let month = data.substring(0, 2);
      let year = data.slice(-4);
      const period = new Date(year + "-" + month + "-01");
      return period;
    },
    validatePayType() {
      if (this.netTotalAmount == 0 && (this.selectedPayType != "Cash" && this.selectedPayType != "Other")) {
        this.validateWording = this.lang.validatePayType;
        this.showValidateDialog = true;
        return true;
      }

      return false;
    },
    validateDocument(isCheck) {
      let returnStatus = false;
      if(this.receipt) {
        if(this.receipt.cancelStatus.trim().toLowerCase() === "c") {
          this.validateWording = this.lang.validateCancelled;
          returnStatus = true;
        } else if(this.receipt.postStep.trim().toLowerCase() === "a") {
          this.validateWording = this.lang.validateReferenceVoucher;
          returnStatus = true;
        } else if(isCheck && this.receipt.postStep.trim().toLowerCase() === "p") {
          this.validateWording = this.lang.validatePrinted;
          returnStatus = true;
        } else if(this.receipt.paidStatus.trim()) {
          this.validateWording = this.lang.validateReferenceDocument;
          returnStatus = true;
        } else if(isCheck && this.receipt.payType === "Other") {
          this.validateWording = this.lang.validatePayTypeOther;
          returnStatus = true;
        }
      }

      if(returnStatus) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.showValidateDialog = true;
      }
      return returnStatus;
    },
    validateRole() {
      if(this.isCreateDocument && this.roleByDocumentBook && this.roleByDocumentBook.insert != "Y") {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return true;
      } else if(!this.isCreateDocument && this.roleByDocumentBook && this.roleByDocumentBook.update != "Y") {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateRoleUpdate;
        this.showValidateDialog = true;
        return true;
      }
      return false;
    },
    unitIsInactive() {
      this.initiateData();
      this.selectedUnit = null;
    },
    async setDataBillPayment(){
      if(this.receiptBillPayment.unitId){
        this.selectedUnit = this.sharedUnits.find(
          (unit) => unit.id === this.receiptBillPayment.unitId
        );
        if (this.receiptBillPayment && this.selectedUnit) {
          this.receiptBillPayment.ref1 = this.selectedUnit.qrpayment ;   
          await this.selectUnit();
        }
      }   
    }, 
    async setDataTransferSlip(){
      if(this.transferSlip && this.transferSlip.unitId){
        this.selectedUnit = this.sharedUnits.find(
          (unit) => unit.id === this.transferSlip.unitId
        );
        if (this.selectedUnit) {
          await this.selectUnit();
        }
      }
    },
    addDetail(data) {
      let stockKeycardId = this.selectedStockKeycard ? this.selectedStockKeycard.id : null;
      let remark = this.selectedStockKeycard ? this.selectedStockKeycard.code : data.remark;
      if (data.id) {
        let detail = this.details.find((detail) => detail.id == data.id);
        detail.productId = data.productId;
        detail.productCode = data.productCode;
        detail.productName = data.productName;
        detail.productUnitId = data.productUnitId;
        detail.productUnitCode = data.productUnitCode;
        detail.productUnitName = data.productUnitName;
        detail.quantity = data.quantity;
        detail.unitPrice = data.unitPrice;
        detail.discountDescription = data.discountDescription;
        detail.discountAmount = data.discountAmount;
        detail.totalAmount = data.totalAmount;
        detail.netAmount = data.netAmount;
        detail.remark = remark;
        detail.period = data.period;
        detail.stockKeycardId = stockKeycardId;
      } else {
        data.itemNo = (this.countDetails + 1);
        data.id = "dummy" + (this.countDetails + 1);
        data.remark = remark;
        data.stockKeycardId = stockKeycardId;
        this.details.push(data);
      }
    },
    selectKeycard(data) {
      this.selectedStockKeycard = data;
      this.addDetail(this.selectedProduct);
      this.selectedStockKeycard = null;
    },
    async getControlStockKeycard() {
      try {
        await this.$store.dispatch(
          "controlStockKeycard/getControlStockKeycard",
          this.selectedProject.id
        );
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
      }
    },
  },
  computed: {
    payload() {
      let details = [];

      for (const each of this.details.filter((each) => each.productId)) {
        let detail = null;
        if (each.id.includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        details.push(detail);
      }
      let payload = {
        projectId: this.selectedProject.id,
        refCode: this.refCode,
        date: this.date ? this.formatDateToISOString(this.date) : null,
        customerId: this.customerId,
        employeeId: this.employeeId,
        paidBy: this.paidBy,
        paidByAddress: this.paidByAddress,
        unitId: this.selectedUnit ? this.selectedUnit.id : "",
        payType: this.selectedPayType,
        payReference: this.payReference,
        payBookBankCode: this.payBookBankCode,
        payBookBankBranch: this.payBookBankBranch,
        payDate: this.selectedPayDate,
        remark3: this.remark3,
        remark4: this.remark4,
        shouldCreateCheque: this.shouldCreateCheque,
        shouldUseDeposit: this.shouldUseDeposit,
        shouldUseGuarantee: this.shouldUseGuarantee,
        guaranteeDetailId: this.guaranteeDetailId,
        details: details,
        receiptMapBillPayment: this.receiptBillPayment,
        transferSlip: this.transferSlip,
      };

      if (this.isCreateDocument) {
        payload = {
          ...payload,
          type: "Add",
          receiptUniqueKeyId: this.uniqueKeyId,
        };
      } else {
        payload = { ...payload, id: this.id, type: "Update" };
      }

      return payload;
    },
    isCreateDocument() {
      return this.id === "create";
    },
    title() {
      let result = this.lang.createReceipt;
      if (!this.isCreateDocument) {
        result = this.lang.editReceipt;
      }
      return result;
    },
    dataButtons() {
      let result = [
        {
          id: "cancelCaption",
          action: "gotoCancel",
          // isDisable: !this.isRoleCancel || this.isCreateDocument,
          isDisable: this.isCreateDocument || !this.isSharedUnitReady,
          type: "cancel"
        },
        {
          id: "createCaption",
          action: "gotoCreate",
          type: "create"
        },
        {
          id: "printCaption",
          action: "gotoPrint",
          // isDisable: !this.isRolePrint || this.isCreateDocument,
          isDisable: this.isCreateDocument || !this.isSharedUnitReady,
          type: "print"
        },
        {
          id: "saveCaption",
          action: "gotoSave",
          // isDisable: this.isDisableSave,
          isDisable: !this.isSharedUnitReady,
          type: "save"
        },
      ];

      return result;
    },
    dataButtonDialog() {
      let result = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];

      if (this.showCancelDialog) {
        result = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "confirmCancel",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "confirm-cancel",
          },
        ];
      } else if (this.shouldUseGuarantee) {
        result = [
          {
            id: "notUseGuarantee",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "not-use-guarantee",
          },
          {
            id: "useGuarantee",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "use-guarantee",
          },
        ];
      } else if (this.isCreateNewDocument) {
        result = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "gotoCreate",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-create",
          },
        ];
      } else if (this.isCopyItem) {
        result = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "gotoCopyItem",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-copy-item",
          },
        ];
      }

      return result;
    },
    isRoleCreate() {
      let result = false;
      if (this.roleByDocumentBook && this.roleByDocumentBook.insert == "Y") {
        result = true;
      }
      return result;
    },
    isRoleUpdate() {
      let result = false;
      if (this.roleByDocumentBook && this.roleByDocumentBook.update == "Y") {
        result = true;
      }
      return result;
    },
    isRoleCancel() {
      let result = false;
      if (this.roleByDocumentBook && this.roleByDocumentBook.cancel == "Y") {
        result = true;
        if (
          this.receipt &&
          (this.receipt.cancelStatus.trim().toLowerCase() === "c" ||
            this.receipt.postStep.trim().toLowerCase() === "a" ||
            this.receipt.paidStatus.trim())
        ) {
          result = false;
        }
      }
      return result;
    },
    isRolePrint() {
      let result = false;
      if (this.roleByDocumentBook && this.roleByDocumentBook.print == "Y") {
        result = true;
      }
      return result;
    },
    isDocumentLocked() {
      if (this.receipt) {
        if (
          this.receipt.paidStatus.trim() ||
          this.receipt.postStep.trim() ||
          this.receipt.cancelStatus.trim().toLowerCase() === "c" ||
          this.receipt.payType == "Other"
        ) {
          return true;
        }
      }
      return false;
    },
    isSelectedUnit() {
      if (this.isSharedUnitReady && this.selectedUnit) {
        return true;
      }
      return false;
    },
    isDisableSave() {
      if (
        this.isDocumentLocked ||
        !this.isSelectedUnit ||
        this.countDetails <= 0
      ) {
        return true;
      }
      if (!this.isCreateDocument) {
        if (!this.isRoleUpdate) {
          return true;
        }
      }
      else {
        if (!this.isRoleCreate) {
          return true;
        }
      }
      return false;
    },
    sharedUnits() {
      return this.$store.getters["shared/unitUnits"];
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    receipt() {
      return this.$store.getters["arReceipt/selectedReceipt"];
    },
    receiptUniqueKey() {
      return this.$store.getters["arReceipt/receiptUniqueKey"];
    },
    depositByUnit() {
      return this.$store.getters["arDeposit/availableDepositByUnit"];
    },
    pendingAmountByUnitAndLivingType() {
      return this.$store.getters["arInvoice/pendingAmountByUnitAndLivingType"];
    },
    depositLeftAmount() {
      let deposit = 0;
      if (this.selectedUnit && this.depositByUnit) {
        deposit = this.depositByUnit.amountLeft;
      }
      return deposit;
    },
    pendingLeftAmount() {
      let pending = 0;
      if (this.selectedUnit && this.pendingAmountByUnitAndLivingType.length > 0) {
        let findPending = this.pendingAmountByUnitAndLivingType.find(
          (each) => each.livingType == this.selectedLivingType
        );
        if (findPending) {
          pending = findPending.pendingAmount;
        }
      }
      return pending;
    },
    classPayType() {
      let setClass = "";
      if (this.selectedPayType != "Cheque") {
        setClass = "w-full";
      }
      return setClass;
    },
    sharedBookBanks() {
      return this.$store.getters["bookBank/bookBanks"];
    },
    bookBankLabel() {
      let label = this.lang.transfer;
      if (this.selectedPayType == "Cheque") {
        label = this.lang.bank;
      }
      if (this.selectedPayType == "CreditCard") {
        label = this.lang.receive;
      }
      return label;
    },
    payDateLabel() {
      let label = this.lang.transferDate;
      if (this.selectedPayType == "Cheque") {
        label = this.lang.chequeDate;
      }
      return label;
    },
    payRefLabel() {
      let label = this.lang.transferNo;
      if (this.selectedPayType == "Cheque") {
        label = this.lang.chequeNo;
      }
      if (this.selectedPayType == "CreditCard") {
        label = this.lang.creditCardNo;
      }
      return label;
    },
    selectedPayDate() {
      let payDate = null;
      if (this.payDate) {
        payDate = this.formatDateToISOString(this.payDate);
      }
      return payDate;
    },
    sharedProductArs() {
      return this.$store.getters["shared/productArProducts"];
    },
    countDetails() {
      let length = this.details.length;
      return length;
    },
    netTotalAmount() {
      let netAmount = 0;
      if (this.details) {
        this.details.forEach((detail) => {
          netAmount += detail.netAmount;
        });
      }
      return parseFloat(netAmount.toFixed(2));
    },
    classDisableBookbank() {
      let classDisable = "";
      if (this.isDisableBookbank) {
        classDisable = "p-inputtext-disabled";
      }
      return classDisable;
    },
    classDisablePayDate() {
      let classDisable = "";
      if (this.isDisablePayDate) {
        classDisable = "p-inputtext-disabled";
      }
      return classDisable;
    },
    classDisableUnit() {
      let classDisable = "";
      if (!this.isCreateDocument) {
        classDisable = "p-inputtext-disabled";
      }
      return classDisable;
    },
    selectedEmployee() {
      return this.$store.getters.employee;
    },
    calculatedPenalty() {
      return this.$store.getters["arNotice/addNoticeByUnit"];
    },
    pendingByAmount() {
      return this.$store.getters["arInvoice/pendingByAmount"];
    },
    shouldCreateCheque() {
      if (this.selectedPayType == "Cheque" && this.isReceipts == false) {
        return true;
      }
      return false;
    },
    availableGuarantee() {
      return this.$store.getters["arGuarantee/availableGuaranteeByUnit"];
    },
    printForms() {
      return this.$store.getters["shared/formPrintForms"];
    },
    fileBlob() {
      return this.$store.getters["arReceipt/blob"];
    },
    saveEditSuccess() {
      let result = this.lang.editReceiptSuccess;
      if (this.isCreateDocument) {
        result = this.lang.createReceiptSuccess;
      }
      return result;
    },
    residents() {
      return this.$store.getters["unitResident/unitResidents"];
    },
    uniqueKeyId() {
      return this.$route.query.uniqueKeyId;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    validateNoBank() {
      let text = this.lang.validateNoBank;
      if (this.selectedPayType == "Cheque") {
        text = this.lang.validateNoEnterBank;
      }
      return text;
    },
    reasonCancelClass() {
      let classInvalid = "";
      if (this.reasonCancelError) {
        classInvalid = "p-invalid";
      }
      return classInvalid;
    },
    roleByDocumentBook() {
      return this.getRoleByBookByCode("INV");
    },
    isDocumentDisabled() {
      // return this.isDocumentLocked || !this.isSelectedUnit;
      return !this.isSharedUnitReady;
    },
    controlStockKeycard() {
      return this.$store.getters["controlStockKeycard/controlStockKeycard"];
    },
  },
  watch: {
    receipt() {
      this.checkShouldSetData();
    },
    payload(curValue) {
      if (this.objToCompare) {
        this.compareObject(this.objToCompare, curValue);
      }
    },
    isSharedRoleByBookReady() {
      this.roleByBookDataReady();
    },
  },
};
</script>
