export default {
	suppliers(state) {
		return state.suppliers;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	selectedSupplier(state) {
		return state.selectedSupplier;
	},
	supplierNextPage(state) {
		return state.currentPage + 1;
	},
	supplierIsLastPage(_, getters) {
		return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
	},
};
