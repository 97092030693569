<template>
  <base-toolbar
    :title="lang.title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoAskForCancel="askForCancel"
  >
  </base-toolbar>

  <base-dialog
    v-model:visible="showValidateDialog"
    :titleConfirm="titleConfirm"
    :classTitle="baseDialogClassTitle"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    :isCloseDialog="true"
    @confirm="showValidateDialog = false"
    @close="showValidateDialog = false"
    @gotoCancel="cancelNotice"
  ></base-dialog>

  <base-form-print-modal
    v-model:visible="showFormPrintModal"
    :search="searchTextPrint"
    @close="showFormPrintModal = false"
    @path-selected="formPrintModalSelected"
  ></base-form-print-modal>

  <base-container class="mb-5">
    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5" :class="textFormClass">
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.title }}</label>
        <div>
          <PvButton
            class="p-button-toolbar ml-2"
            @click="selectPrintForm"
            :disabled="!isRolePrint"
          >
            <icon-printer
              class="p-button-outlined p-button-primary mr-2"
              iconColor="white"
              height="16"
              width="16"
            />
            <span style="font-size: 14px">
              {{ lang.printNotice }}
            </span>
          </PvButton>
        </div>
      </div>
      <PvDivider></PvDivider>
      <section class="flex flex-wrap mb-3">
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="code">{{ lang.columnCode }}</label>
          <PvInputText
            id="code"
            type="text"
            class="inputfield w-full mt-1 input-disable"
            v-model="code"
            readonly
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="date">{{ lang.columnDate }}</label>
          <PvInputText
            id="date"
            type="text"
            class="inputfield w-full mt-1 input-disable"
            v-model="date"
            readonly
          />
          <!-- <PvCalendar
              id="date"
              v-model="date"
              class="inputfield w-full mt-1 input-disable"
              showIcon
              dateFormat="dd/mm/yy"
              disabled
            /> -->
        </div>

        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="dueDate">{{ lang.dueDate }}</label>
          <PvInputText
            id="dueDate"
            type="text"
            class="inputfield w-full mt-1 input-disable"
            v-model="dueDate"
            readonly
          />
          <!-- <PvCalendar
              id="dueDate"
              v-model="dueDate"
              class="inputfield w-full mt-1 input-disable"
              showIcon
              dateFormat="dd/mm/yy"
              disabled
            /> -->
        </div>

        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="unit">{{ lang.columnUnit }}</label>
          <PvInputText
            id="unit"
            type="text"
            class="inputfield w-full mt-1 input-disable"
            v-model="unit"
            readonly
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="customerName">{{ lang.customerName }}</label>
          <PvInputText
            id="customerName"
            type="text"
            class="inputfield w-full mt-1 input-disable"
            v-model="customerName"
            readonly
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="employeeName">{{ lang.employeeName }}</label>
          <PvInputText
            id="employeeName"
            type="text"
            class="inputfield w-full mt-1 input-disable"
            v-model="employeeName"
            readonly
          />
        </div>

        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="remark1">{{ lang.remark1 }}</label>
          <PvInputText
            id="remark1"
            type="text"
            class="inputfield w-full mt-1 input-disable"
            v-model="remark1"
            readonly
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="remark2">{{ lang.remark2 }}</label>
          <PvInputText
            id="remark2"
            type="text"
            class="inputfield w-full mt-1 input-disable"
            v-model="remark2"
            readonly
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="totalAmount">{{ lang.totalAmount }}</label>
          <PvInputNumber
            id="totalAmount"
            class="w-full mt-1 input-disable"
            inputClass="input-number-disable text-right w-full"
            v-model="totalAmount"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            readonly
          />
        </div>
      </section>
      <div class="flex flex-wrap justify-content-between align-items-center mt-5">
        <label class="font-lg font-normal">{{ lang.titleDetail }}</label>
        <div></div>
      </div>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="details"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
        >
          <PvColumn
            field="itemNo"
            :header="lang.itemNo"
            style="min-width: 5rem; height: 56px"
            headerClass="h-center"
            bodyClass="text-center"
          >
          </PvColumn>
          <PvColumn
            field="productCode"
            :header="lang.productCode"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
          </PvColumn>
          <PvColumn
            field="productName"
            :header="lang.productName"
            style="min-width: 15rem"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="remark"
            :header="lang.description"
            style="min-width: 20rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
          </PvColumn>
          <PvColumn
            field="period"
            :header="lang.period"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
          </PvColumn>

          <PvColumn
            field="unitPrice"
            :header="lang.unitPrice"
            style="min-width: 8rem"
            headerClass="h-right"
            bodyClass="text-right"
          >
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.unitPrice) }}
            </template>
          </PvColumn>

          <PvColumn
            field="quantity"
            :header="lang.quantity"
            style="min-width: 6rem"
            headerClass="h-right"
            bodyClass="text-right"
          >
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.quantity) }}
            </template>
          </PvColumn>

          <PvColumn
            field="totalAmount"
            :header="lang.columnTotalAmount"
            style="min-width: 8rem"
            headerClass="h-right"
            bodyClass="text-right"
          >
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.totalAmount) }}
            </template>
          </PvColumn>
          <PvColumn
            field="paidAmount"
            :header="lang.paidAmount"
            style="min-width: 8rem"
            headerClass="h-right"
            bodyClass="text-right"
          >
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.paidAmount) }}
            </template>
          </PvColumn>

          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import useGetRoleByBook from "../../../hooks/getRoleByBook.js";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  props: ["id", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textFormClass, textTableClass } = useTextStyle();
    const {
      formatCurrency,
      formatDateToLocaleDateString,
      formatDateToISOString,
    } = useFormat();

    const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();

    const {
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
    } = useGetFormPrint();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.notice.listing,
          ...lang.th.ar.notice.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.notice.listing,
          ...lang.en.ar.notice.edit,
        };
      }
    });
    return {
      lang: mergeLang,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDateToLocaleDateString,
      formatDateToISOString,
      getRoleByBookByCode,
      isSharedRoleByBookReady,
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
    };
  },
  data() {
    return {
      code: "",
      date: "",
      dueDate: "",
      unit: "",
      customerName: "",
      employeeName: "",
      remark1: "",
      remark2: "",
      totalAmount: 0,

      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: this.lang.notificationDialogHeader,
      validateWording: "",
      dataButtonDialog: null,
      showFormPrintModal: false,
      path: "",
      dataPrint: [],
      storageName: "",
    };
  },
  async mounted() {
    this.initiateData();
    this.storageName = `erp_search_NotictList_${this.selectedProject.code}_${this.userCode}`;
    await this.getNotice();
    this.checkShouldSetData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      const localSearch = localStorage.getItem(this.storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        NotictEntry: "Entry",
      };
      // console.log("search=",search);
      localStorage.setItem(this.storageName, JSON.stringify(search));
      this.$router.push({name: "ar-notice"});
    },
    initiateData() {
      this.date = new Date();
      this.dueDate = new Date();
      this.code = "";
      this.unit = "";
      this.customerName = "";
      this.employeeName = "";
      this.remark1 = "";
      this.remark2 = "";
      this.totalAmount = 0;
      this.baseDialogClassTitle = "";
    },
    setData() {
      this.date = this.formatDateToLocaleDateString(new Date(this.notice.date));
      this.dueDate = this.formatDateToLocaleDateString(new Date(this.notice.dueDate));
      this.code = this.notice.code;
      this.unit = this.notice.unitCode + " (" + this.notice.unitAddressNo + ")";
      this.customerName = this.notice.customerName;
      this.employeeName = this.notice.employeeName;
      this.remark1 = this.notice.remark1;
      this.remark2 = this.notice.remark2;
      this.totalAmount = this.notice.totalAmount;
    },
    async getNotice() {
      const payload = {
        id: this.id,
      };
      await this.$store.dispatch("arNotice/getNotice", payload);
    },
    checkShouldSetData() {
      if (this.notice && this.isSharedRoleByBookReady) {
        this.setData();
      }
    },
    askForCancel() {
      this.dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
        {
          id: "gotoCancel",
          caption: this.lang.buttonDialogConfirm,
          class: "w-full",
          action: "gotoCancel",
        },
      ];
      this.titleConfirm = this.lang.cancelDocument;
      this.validateWording = this.lang.askForCancelDocument;
      this.showValidateDialog = true;
    },
    async cancelNotice() {
      this.setLoading(true);
      try {
        await this.$store.dispatch("arNotice/cancelNotice", {
          id: this.id,
        });

        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.confirm,
            class: "w-full",
            action: "confirm",
          },
        ];

        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.cancelDocument;
        this.validateWording = this.lang.cancelSuccess;
        this.showValidateDialog = true;
      } catch (error) {
        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.confirm,
            class: "w-full",
            action: "confirm",
          },
        ];
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
      }
      this.setLoading(false);
    },
    async selectPrintForm() {
      if (!this.isRolePrint) {
        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.confirm,
            class: "w-full",
            action: "confirm",
          },
        ];
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validatePrintPermission;
        this.showValidateDialog = true;
        return true;
      }

      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: "NOTICE",
      };
      const payload = { where };
      await this.getFormPrints(payload);

      if (this.filteredFormPrints.length == 1) {
        this.path = this.filteredFormPrints[0].path;
        this.printNotice();
      } else {
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path;
      // this.categoryPrint = event.category;
      this.printNotice();
      this.showFormPrintModal = false;
    },
    async printNotice() {
      this.setLoading(true);

      let parameter = {
        ProjectId: this.selectedProject.id,
        NoticeDate: this.formatDateToISOString(new Date(this.notice.date)),
        NoticeCodeStart: "",
        NoticeCodeEnd: "",
        UnitCodeStart: "",
        UnitCodeEnd: "",
        NoticeId: this.id,
        IsIgnoreZero: "N",
        IsGroup: "Y",
        OrderBy: "code",
        IsForApi: "True",
      };

      let payload = {
        path: this.path + ".pdf",
        fileName: "Notice_" + this.code.trim(),
        parameter: JSON.stringify(parameter),
        ProjectId: this.selectedProject.id,
        NoticeDate: this.formatDateToISOString(new Date(this.notice.date)),
        NoticeId: this.id,
        IsIgnoreZero: "N",
      };

      // console.log("payload=",payload);
      let blob = null;
      try {
        if (this.notice && this.notice.postStep == "P") {
          await this.$store.dispatch("shared/printForm", payload);
          blob = this.$store.getters["shared/blob"];
        } else {
          await this.$store.dispatch("arNotice/printNotice", payload);
          blob = this.$store.getters["arNotice/blob"];
        }
        await this.getNotice();
      } catch (error) {
        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.confirm,
            class: "w-full",
            action: "confirm",
          },
        ];
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }

      this.getPreviewBlob(blob);
      this.setLoading(false);
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    notice() {
      return this.$store.getters["arNotice/notice"];
    },
    roleByBook() {
      return this.getRoleByBookByCode("NOTICE");
    },
    isCanceled() {
      if (this.notice) {
        if (this.notice.cancelStatus.trim().toLowerCase() === "c") {
          return true;
        }
        if (this.notice.postStep.trim().toLowerCase() === "a") {
          return true;
        }

        if (this.notice.paidStatus.trim().toLowerCase() === "d") {
          return true;
        }

        if (this.notice.paidStatus.trim().toLowerCase() === "f") {
          return true;
        }
      }

      if (!this.isRoleCancel) {
        return true;
      }

      return false;
    },
    isRoleCancel() {
      if (this.roleByBook && this.roleByBook.cancel == "Y") {
        return true;
      }
      return false;
    },
    isRolePrint() {
      if (this.roleByBook && this.roleByBook.print == "Y") {
        return true;
      }
      return false;
    },
    details() {
      let details = [];
      if (this.notice) {
        return this.notice.noticeDetails;
      }
      return details;
    },

    dataButtons() {
      return [
        {
          id: "gotoAskForCancel",
          caption: this.lang.cancelDocument,
          class: "mr-3 p-button-outlined p-button-danger",
          icon: "",
          iconColor: "",
          action: "gotoAskForCancel",
          isShow: true,
          isDisable: this.isCanceled,
        },
      ];
    },
  },
  watch: {
    notice() {
      this.checkShouldSetData();
    },
    isSharedRoleByBookReady() {
      this.checkShouldSetData();
    },
  },
};
</script>

<style></style>
