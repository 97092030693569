export default {
  setNoticeCustomReport(state, payload) {
    state.noticeCustomReports = payload.data;
  },
  setSelectedNoticeCustomReport(state, payload) {
    state.selectedNoticeCustomReport = payload;
  },
  resetNoticeCustomReport(state) {
    state.noticeCustomReports = [];
  },
  resetSelectedNoticeCustomReport(state) {
    state.selectedNoticeCustomReport = null;
  },
};
