export default {
	createTitle: "รายละเอียดปิดรอบบัญชี", 
	editTitle: "รายละเอียดปิดรอบบัญชี", 
	containerTitle: "รายละเอียดปิดรอบบัญชี",

	labelDate: "วันที่", 
	labelAccountBook: "เล่มเอกสาร", 
	validateAccountBookIsNull: "โปรดเลือกเล่มเอกสาร",
	labelAccountCode: "รหัสบัญชี", 
	validateAccountCodeIsNull: "โปรดเลือกรหัสบัญชี",
	labelCode: "เลขที่เอกสาร", 
	labelDescription: "คำอธิบาย", 
	labelSearch: "ค้นหา",
	labelGrandTotalDebit: "รวมเดบิต",
	labelGrandTotalCredit: "รวมเครดิต",
	labelGrandTotalDifferent: "ผลต่าง",

	columnItemNumber: "ลำดับ",
	columnCode:"รหัส",
	columnName: "ชื่อ",
	columnDebit: "เดบิต",
	columnCredit: "เครดิต",

	dialogTitleCancel: "ต้องการถอยข้อมูลใช่หรือไม่?",
	dialogTitleExported: "ทำรายการสำเร็จ",
	dialogContentNoneAccountBook: "โปรดตรวจสอบว่ามีสมุดบัญชีรายวันรหัส: PV ในโครงการของท่านหรือไม่?",	
	dialogContentCancel: "การถอยข้อมูล...",
	dialogContentExported: "ส่งออกข้อมูลสำเร็จ",
	dialogValidateParamsProjectId: "ไม่สามารถทำรายการได้ เนื่องจากไม่พบข้อมูลโครงการ", 
	dialogValidateParamsAccountBook: "ไม่สามารถทำรายการได้ เนื่องจากไม่พบข้อมูลเล่มเอกสาร", 
	dialogValidateParamsId: "ไม่สามารถทำรายการได้ เนื่องจากข้อมูลไอดีเอกสารไม่ถูกต้อง", 
	dialogValidateParamsDateAtId: "ไม่สามารถทำรายการได้ เนื่องจากไม่พบข้อมูลวันที่เอกสาร", 
	dialogValidateParamsAccountId: "ไม่สามารถทำรายการได้ เนื่องจากไม่พบข้อมูลผังบัญชี", 
	dialogValidateInsertVoucherService: "ไม่สามารถทำรายการได้ เนื่องจากพบข้อผิดพลาดบางอย่างในขั้นตอนสร้างเอกสาร", 
	dialogValidateCancelVoucherService: "ไม่สามารถทำรายการได้ เนื่องจากพบข้อผิดพลาดบางอย่างในขั้นตอนยกเลิกเอกสาร", 
	dialogValidateAlreadyClosingEntry: "ไม่สามารถทำรายการได้ เนื่องจากวันที่ {0} ได้ถูกปิดรอบบัญชีไปแล้ว", 
	dialogValidateCancelled: "ไม่สามารถทำรายการได้ เนื่องจากถูกยกเลิกไปแล้ว",
	dialogValidateSaveInsertOnly: "ไม่สามารถทำรายการได้ เนื่องจากระบบถูกออกแบบไว้สำหรับสร้างข้อมูลเท่านั้น", 
	dialogValidateAccountCodeInvalid: "ไม่สามารถทำรายการได้ เนื่องจากข้อมูลผังบัญชีไม่ถูกต้อง",
	dialogValidateNoDetail: "บันทึกไม่สำเร็จ เนื่องจากรหัสบัญชีที่เลือกไม่มีการบันทึกบัญชี กรุณาเลือกรหัสบัญชีใหม่อีกครั้ง",
	validateDateSelect: "กรุณาเลือกวันที่",
};
