<template>
  <base-toolbar :title="lang.title" :dataButtons="dataButtons" @gotoCreate="createBudget" @goBack="goBack"> </base-toolbar>

  <base-dialog
    v-model:visible="showValidateDialog"
    :classTitle="baseDialogClassTitle"
    :titleConfirm="titleConfirm"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    @confirm="showValidateDialog = false"
    @close="showValidateDialog = false"
  ></base-dialog>
  <base-container>
    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5" :class="textFormClass">
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.title }}</label>
      </div>

      <PvDivider></PvDivider>
      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="budgetList"
          v-model:selection="selectedBudget"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(selectedBudget.id)"
          scrollable
        >
          <PvColumn
            field="name"
            :header="lang.columnName"
            style="min-width: 30rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
          </PvColumn>
          <PvColumn
            field="yearOf"
            :header="lang.columnYear"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
          </PvColumn>
          <PvColumn
            field="period"
            :header="lang.columnPeriod"
            style="min-width: 15rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <label v-if="slotProps.data.dateStart && slotProps.data.dateEnd">
                {{ formatDateToPeriod(slotProps.data.dateStart) }} - {{ formatDateToPeriod(slotProps.data.dateEnd) }}
              </label>
            </template>
          </PvColumn>
          <PvColumn
            field="updateDate"
            :header="lang.columnUpdateDate"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <label v-if="slotProps.data.updateDate">
                {{ formatDate(slotProps.data.updateDate, false) }}
              </label>
            </template>
          </PvColumn>
          <PvColumn
            field="updateBy"
            :header="lang.columnUpdateBy"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <label>
                {{ slotProps.data.updateBy ?? slotProps.data.createBy }}
              </label>
            </template>
          </PvColumn>
          <PvColumn
            field="status"
            :header="lang.columnStatus"
            style="min-width: 9rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <base-tag 
                :Caption="lang.inActive" 
                Class="secondary" v-if="slotProps.data.isActive == 'N' && slotProps.data.isCancel == 'N'" 
              />
              <base-tag
                :Caption="lang.active"
                Class="success"
                v-else-if="slotProps.data.isActive == 'Y' && slotProps.data.isCancel == 'N'"
              />
              <base-tag 
                :Caption="lang.cancel" 
                Class="error" v-else-if="slotProps.data.isCancel == 'Y'" 
              />
            </template>
          </PvColumn>
          <PvColumn
            style="min-width: 8rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            :frozen="true"
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
            </template>
          </PvColumn>
          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref, computed, onMounted, watch } from "vue";
import useLang from "../../hooks/lang.js";
import useFormat from "../../hooks/format.js";
import useGetRoleByMenu from "../../hooks/getRoleByMenu.js";
import accountDetailWithoutParent from "../../hooks/accountDetailWithoutParent.js";
export default {
  props: ["projectCode"],
  setup() {
    const router = useRouter();
    const store = useStore();
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();
    const { checkAccountDetailNoParent} = accountDetailWithoutParent();
    const { lang } = useLang();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.budget.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.budget.listing,
        };
      }
    });
    const { formatDate, formatDateToPeriod, textTableClass, textFormClass } = useFormat();

    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const budgets = computed(() => {
      return store.getters["budget/budgets"];
    });

    let dataButtons = ref([
      {
        id: "createFormVersion",
        caption: mergeLang.value.createBudget,
        class: "mr-2",
        icon: "icon-plus-add",
        iconColor: "white",
        action: "gotoCreate",
        isShow: true,
      },
    ]);
    let selectedBudget = ref(null);
    let isLoading = ref(false);
    let budgetList = ref([]);
    let keyToReLoad = ref(1);
    
    let showValidateDialog = ref(false);
    let baseDialogClassTitle = ref("");
    let titleConfirm = ref("");
    let validateWording = ref("");

    onMounted(() => {
      roleByMenuDataReady();
    });

    const roleByMenuDataReady = async () => {
      if (isSharedRoleByMenuReady.value) {
        getBudgets();
      }
    };

    const createBudget = async () => {
      if (!isRoleCreate.value) {
        baseDialogClassTitle.value = "";
        titleConfirm.value = mergeLang.value.notificationDialogHeader;
        validateWording.value = mergeLang.value.validateRoleInsert;
        showValidateDialog.value = true;
        return true;
      }

      let result = await checkAccountDetailNoParent();
      if(!result){
        router.push({ name: "budget-edit", params: { id: "create" } });
      }
    };

    const gotoEdit = (id) => {
      router.push({ name: "budget-edit", params: { id: id } });
    };

    const getBudgets = async () => {
      isLoading.value = true;
      await store.dispatch("budget/getBudgets", { projectId: selectedProject.value.id });
      budgetList.value = budgets.value;
      isLoading.value = false;
    };
    
    const goBack = () => {
      router.push({ name: "home"});
    };

    const roleByMenu = computed(() => {
      return getRoleByMenuByCode("pms_budget");
    });
    
    const isRoleCreate = computed(() => {
      if (roleByMenu.value && roleByMenu.value.insert == "Y") {
        return true;
      }
      return false;
    });

    const dataButtonDialog = computed(() => {
      let result = [
        {
          id: "confirm",
          caption: mergeLang.value.close,
          class: "w-full p-button-outlined p-button-secondary",
          action: "confirm",
        },
      ];
      
      return result;
    });
    
    watch(isSharedRoleByMenuReady, function () {
      roleByMenuDataReady();
    });

    return {
      lang: mergeLang,
      formatDate,
      formatDateToPeriod,
      textTableClass,
      textFormClass,

      selectedProject,
      budgetList,

      dataButtons,
      selectedBudget,
      isLoading,
      keyToReLoad,
      isRoleCreate,

      createBudget,
      goBack,
      gotoEdit,
      
      //dialog
      showValidateDialog,
      baseDialogClassTitle,
      titleConfirm,
      validateWording,
      dataButtonDialog,
    };
  },
};
</script>
