<template>
    <PvDialog
      :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
      :style="{ width: '30vw' }"
      :modal="true"
      :closable="false"
    >
    <template #header>
      <div class="flex justify-content-between align-items-right">
        <div class="pb-1">
          <label class="font-lg">{{ title }}</label>
        </div>       
      </div>
      <PvDivider></PvDivider>
    </template>

    <div>
        <label class="font-sm">{{ textProgressBar }}</label>
        <PvProgressBar
        :value="percentage"
        v-if="progressBar == 'Basic'"
        ></PvProgressBar>

        <PvProgressBar
        :value="percentage"
        v-else-if="progressBar == 'Template'"
        >{{ this.getMarkStatus }}/{{ this.getValueObjTotal }} </PvProgressBar>

        <PvProgressBar mode="indeterminate" 
        style="height: 10px"
        v-else
        ></PvProgressBar>

    </div>
    <!-- {{ this.valueObj }} -->
    <template #footer>
        <slot name="progressFooter"></slot>
    </template>
    </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
export default {
  props: {
    title: {
      type: String,
      default: "Progress",
    },
    textProgressBar: {
      type: String,
      default: "SendData...",
    },
    valueObj: {
      type: Object,
      default: null     
    },
    progressBar: {
      type: String,
      default: "Basic" 
    }
  },
  emits: ["close"],
  setup() {
    const { lang } = useLang();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
        };
      } else {
        return {
          ...lang.en.base,
        };
      }
    });

    return {
      lang: mergeLang,
    };
  },
  data() {
    return {          
    }
  },
  // created() {
  //   this.startProgress();
  // },  
  methods: {    
    closeDialog() {
      this.$emit("close");
    },
    // startProgress() {
    // },
    // endProgress() {
    // }
  },  
  computed: {
	  percentage(){
      if(this.valueObj){
        // console.log("00this.getMarkStatus=",this.getMarkStatus);
        // console.log("00this.getValueObjTotal=",this.getValueObjTotal);
        if(this.getMarkStatus == 0) {
          return 1;
        }
        return Math.floor((this.getMarkStatus / this.getValueObjTotal)*100);
      }
      return 0;
    },
    getValueObjTotal(){
      if(this.valueObj){
        return this.valueObj.length;
      }
      return 0;
    },
    getMarkStatus(){
       if(this.valueObj){
        let data = this.valueObj.filter(e => e.markStatus !== "");           
        if(data){		
          return data.length;
        }
       }
       return 0;
    }
  },
   
};
</script>

<!--:progressBar= Basic,Template,Indeterminate -->
<!--
  <base-progress-bar
    v-model:visible="showProgressBar"
    :title="`ProgressBar`"
    :textProgressBar="`SendData....`"
    :valueObj="valueObj"
	  :progressBar="`Basic`" 
    @close="showProgressBar = false"
  >  
  <template #progressFooter>
      <div class="flex justify-content-between align-items-center">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pb-0">
          <PvButton
            label="หยุดชั่วคราว"
            class="p-button-primary w-full"
            @click="stopSendData()"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pb-0">
          <PvButton
            label="ยกเลิก"
            class="p-button-primary w-full"
            @click="closeProgressBar()"
          />
        </div>
      </div>
      <div class="flex justify-content-between align-items-center">       
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pb-0">
          <PvButton
            label="ส่งแจ้งแตือนต่อ"
            class="p-button-primary w-full"
            @click="startSendData()"
          />
        </div>
      </div>
    </template>
  </base-progress-bar>



  data() {
    return {
      showProgressBar: true,
      valueObj: [
        { id: 1, markStatus: "" },
        { id: 2, markStatus: "" },
        { id: 3, markStatus: "" },
        { id: 4, markStatus: "" },
        { id: 5, markStatus: "" },
        { id: 6, markStatus: "" },
        { id: 7, markStatus: "" },
        { id: 8, markStatus: "" },
        { id: 9, markStatus: "" },
        { id: 10, markStatus: "" },
        { id: 11, markStatus: "" },
        { id: 12, markStatus: "" },
        // { id: 13, markStatus: "" },
        // { id: 14, markStatus: "" },
        // { id: 15, markStatus: "" },
      ],
      interval: null,
      number: 0,
    };
  },
  created() {
    this.SendData();    
  },
  methods: {
    SendData() {
      this.interval = setInterval(() => {
        this.number = this.number + 1;
        const data = this.valueObj.find((detail) => detail.id === this.number);
        if (data) {
          data.markStatus = "1";       
        }else{
          this.stopSendData();
        }
        //  console.log("this.valueObj=",this.valueObj);
      }, 1000);
    },
    stopSendData() {
      clearInterval(this.interval);
      this.interval = null;
    },
    startSendData() {
      this.SendData();
    },
    closeProgressBar(){      
      clearInterval(this.interval);
      this.interval = null;
      this.showProgressBar = false
    }

  },


-->