import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default function useGetBuilding() {
  const store = useStore();
  let searchText = ref(null);

  const locations = computed(() => {
    return store.getters["shared/locationLocations"];
  });

  const locationIsLastPage = computed(() => {
    return store.getters["shared/locationIsLastPage"];
  });

  const filteredLocations = computed(() => {
    if (!searchText.value) {
      //ช่องว่าง
      const result = [...locations.value];
      return result;
    } else {
      return locations.value.filter((location) => {
        return location.codeAndName
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const searchLocation = function (event) {
    // console.log(event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchText.value = "";
      searchText.value = null;
    } else {
      searchText.value = event.query;
    }
  };

  let isSharedLocationReady = ref(false);

  const getAllLocations = async function () {
    if (!locationIsLastPage.value) {
      await store.dispatch("shared/getAllLocations");
    }
    isSharedLocationReady.value = true;
  };

  const getLocationByCode = function (code) {
    return locations.value.find((location) => {
      return location.code.toLowerCase() === code.trim().toLowerCase();
    });
  };

  onMounted(async () => {
    await getAllLocations();
  });

  return {
    searchLocation,
    filteredLocations,
    searchText,
    getLocationByCode,
    isSharedLocationReady
  }
}
