export default {
  meterUsageWater: "Water Meter Usage",
  meterUsageElectricity: "Electricity Meter Usage",
  meterUsageCoolingAir: "Cooling Air Meter Usage",
  meterUsageReport: "Report Meter Usage",
  meterUsageWaterEdit: "Edit Water Meter Usage",
  meterUsageElectricityEdit: "Edit Electricity Meter Usage",
  meterUsageCoolingAirEdit: "Edit Cooling Air Meter Usage",

  editMeter: "Edit",
  upload: "Import/Export",
  export: "Export",

  statusAll: "All",
  statusG: "Usually",
  statusY: "Re-checked",
  statusR: "Unusually",

  meterCode: "meter",
  unitCode: "Unit Code",
  unitAddressNo: "Unit Address No",
  productName: "Product Code",
  previousUsed: "Previous Used",
  currentUsed: "Current Used",
  quantity: "Quantity",
  amount: "Amount",
  waterAverageUsed: "Water Average Used",
  electricityAverageUsed: "Electricity Average Used",
  coolingAirAverageUsed: "Cooling Air Average Used",
  status: "Meter Status",
  reason: "Reason",
  updateDate: "Update Date/Time",

  uploadFile2: "Upload File",
  dragFile: "Drag drop file or ",
  addFile: "Add meter file",
  importFileDesc: "Please add only 1 file at a time with .XLSX or ", //
  downloadfile: "Download meter file",
  validateLimitFile: "Can be upload 1 file at a time",
  validateImportFile: "The allowed file extensions are .XLSX only",

  validateCheckNumber: "Meter Usage format can be enter only numbers.",
  updatePreviousUsed: "Update Previous Used",
  from: "From",
  to: "To",
  previousUsedReason: "Enter the reason for the previous used.",
  validatePreviousUsedReason: "Please enter the reason for the previous used.",

  setReason: "Set reason",
  reasonOverAverage: "Over Average",
  reasonNewAdded: "Recently moved in",
  reasonChangeOrBroken: "Meter change/broken",
  reasonOther: "Other",

  deleteMeter: "Delete current used",
  confirmDeleteMeter: "Delete current used",
  confirmDeleteMeterDetail: "Are you sure you want to delete current used ?",
  deleteMeterSuccess: "Delete current used success",
  validateAddfile: "Please add file",
  uploadStatus: "Upload status",

  validateReferVoucher: "Meter period :period has been auto GL or invoiced.",
  validateNoSetupProduct: "Meter :meterCode isn't setup product.",
  validateSetupCalculater: "Invalid setup meter calculate.",
  editReason: "Edit Reason: ",
  usuallyReason: "There's nothing wrong.",
  validateReason: "*Please enter reason",

  productInvalid: "*Please setup product",

  referDialogTitle: "Warning",
  referDialogWording: "In  case an invoice has been issued or bookkeeping will not be able to change the information",

  cancelMeter: "Cancel current meter used",
  cancelMeterSuccess: "Cancel current meter used success",
  validateMeterIncorrect: "Meter :meterCode current used is incorrect.",
  validateRoleInsertUpdate: "You don't have permission to add or update meter.",
};
