export default {
	titleIn: "Bank AR",
	titleOut: "Bank AP",
	codeBank: "Code",
	columnPayDate: "Date",
	columnToBankDate: "Bank Date",
	columnCode: "Code",
	columnCustomerCode: "Customer Code",
	columnCustomerName: "Customer Name",
	columnPrice: "Price",
	columnPayCode: "Pay Code",
	columnPayNameIn: "Payout Description",
	columnPayNameOut: "Pay Description",
	columnIsCancel: "Canceled",
	columnStatus: "Status",
	customerSearch: "Customer Code or Name",
	unPaidOnly: "Unpaid only",
	unPaidOptions: [
		{ name: "Yes", code: "A1" },
		{ name: "No", code: "A2" },
	],
	bookBankAccountNumber: "Book Bank Account Number",
	bookBankName: "Book Bank Name",
	paymentMethodCode: "Payment Method",
	Status: "Status",
	importDocument: "Import document",
	confirmDocument: "Confirm document",
	removeDocument: "Remove document",
	btnPrintLot: "ChequePrintLot",
	bookBank: "BookBank",
	CodeStart: "From Code",
	CodeEnd: "To Code",	
	columnAccountCode: "AccountCode",
	columnBranch: "Branch",
	columnBank: "Bank",
	validatebbookBankError: "Invalid BookBank",
	validateDetail: "Detail Print not found",
	cancelStatus: "Print Document with Cancel Status",
};
