<template>
    <base-toolbar
        :title="lang.editPaymentRequestType"
        :dataButtons="dataButtons"
        @goBack="goBack"
        @goto-save="save"
    ></base-toolbar>

    <base-container>
        <div
            class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
            :class="textFormClass"
        >
            <label class="font-lg font-normal">{{ lang.paymentRequestTypeDetail }}</label>
            <PvDivider></PvDivider>

            <section class="flex flex-wrap fontlabel" :class="textFormClass">
                <div class="lg:col-6 md:col-6 col-12 pl-3 pr-2">
                    <label for="name">{{ lang.paymentRequestTypeName }}*</label>
                    <PvInputText
                        id="name"
                        type="text"
                        class="inputfield w-full mt-1 p-inputtext-disabled"
                        v-model="name"
                        disabled
                    />
                </div>
                <div class="col-6"></div>
            </section>

            <base-card
                :titleHeader="lang.ledgerBookSetting"
                :isVisibleExpandButton="false"
                :isHideBackground="true"
                :isVisibleCard="true"
                class="p-0 pt-3"
            >
                <section class="flex flex-wrap fontlabel" :class="textFormClass">
                    <div v-if="isTypeProductAndService" class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
                        <label for="expenseLedgerBook">{{ lang.expenseLedgerBook }}*</label>
                        <PvSkeleton
                            v-if="!isSharedAccountBookReady"
                            class="inputfield w-full mt-1"
                            height="39px"
                        />
                        <base-account-book-auto-complete      
                            id="selectedExpenseLedgerBook"
                            v-model="selectedExpenseLedgerBook"
                            @load-finish="accountBookDataReady"
                            class="inputfield w-full mt-2"
                            v-show="isSharedAccountBookReady"
                            accountBook="!PC"
                            :isLoadingData="true"
                            :class="{ 'p-invalid': expenseLedgerBookError }"
                            @item-select="expenseLedgerBookError = false"
                        />
                        <small v-if="expenseLedgerBookError" id="expense-ledger-book-help" class="p-error">
                            {{ lang.validateExpenseLedgerBook }}
                        </small>
                    </div>
                    <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
                        <label for="paymentLedgerBook">{{ lang.paymentLedgerBook }}*</label>
                        <PvSkeleton
                            v-if="!isSharedAccountBookReady"
                            class="inputfield w-full mt-1"
                            height="39px"
                        />
                        <base-account-book-auto-complete      
                            id="selectedPaymentLedgerBook"
                            v-model="selectedPaymentLedgerBook"
                            @load-finish="accountBookDataReady"
                            class="inputfield w-full mt-2"
                            v-show="isSharedAccountBookReady"
                            :isLoadingData="true"
                            accountBook="!PC"
                            :class="{ 'p-invalid': paymentLedgerBookError }"
                            @item-select="paymentLedgerBookError = false"
                        />
                        <small v-if="paymentLedgerBookError" id="payment-ledger-book-help" class="p-error">
                            {{ lang.validatePaymentLedgerBook }}
                        </small>
                    </div>
                </section>
            </base-card>
        </div>
    </base-container>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref, onMounted, computed, watch } from "vue";
import useLang from "../../hooks/lang.js";
import useFormat from "../../hooks/format.js";
import BaseDialog from "../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
export default {
    props: ["projectCode", "id"],
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const { lang } = useLang();
        const mergeLang = computed(function () {
            if (store.getters.selectedLanguage === "TH") {
                return {
                ...lang.th.base,
                ...lang.th.ap.paymentRequestType.edit,
                };
            } else {
                return {
                ...lang.en.base,
                ...lang.en.ap.paymentRequestType.edit,
                };
            }
        });

        const { textFormClass } = useFormat();

        let name = ref("");
        let dataConfigDialog = ref(null);
        let selectedExpenseLedgerBook = ref(null);
        let expenseLedgerBookError = ref(false);
        let selectedPaymentLedgerBook = ref(null);
        let paymentLedgerBookError = ref(false);
        let isSharedAccountBookReady = ref(false);

        onMounted(async() => {
            setLoading(true);
            initiateData();
            await getPaymentRequestTypeById();
            checkShouldSetData();
        });

        const selectedPaymentRequestType = computed(() => {
            return store.getters["paymentRequestType/selectedPaymentRequestType"];
        });

        const isTypeProductAndService = computed(() => {
            let result = false;
            if (selectedPaymentRequestType.value && selectedPaymentRequestType.value.code == "PQ001") {
                result = true;
            }
            return result;
        });

        const sharedAccountBooks = computed(() => {
            return store.getters["shared/glBookGls"];
        });

        const dataButtons = computed(() => {
            let result = [
                {
                    id: "save",
                    action: "goto-save",
                    type: "save",
                },
            ];
            return result;
        });

        const payload = computed(() => {
            return {
                isUseExpenseLedgerBook: isTypeProductAndService.value && selectedExpenseLedgerBook.value ? "Y" : "N",
                expenseLedgerBookId: selectedExpenseLedgerBook.value ? selectedExpenseLedgerBook.value.generalLedgerBookId : "",
                isUsePaymentLedgerBook: selectedPaymentLedgerBook.value ? "Y" : "N",
                paymentLedgerBookId: selectedPaymentLedgerBook.value ? selectedPaymentLedgerBook.value.generalLedgerBookId : "",
            };
        });

        //methods
        const setLoading = (bool) => {
            store.dispatch("setLoading", { value: bool });
        };
        const goBack = () => {
            router.push({ name: "payment-request-type" });
        };

        const initiateData = () => {
            name.value = "";
            selectedExpenseLedgerBook.value = null;
            selectedPaymentLedgerBook.value = null;
            expenseLedgerBookError.value = false;
            paymentLedgerBookError.value = false;
            dataConfigDialog.value = null;
        };

        const accountBookDataReady = () => {
            isSharedAccountBookReady.value = true;
        };

        const checkShouldSetData = () => {
            if (selectedPaymentRequestType.value) {
                setData();
            }
        };

        const setData = () => {
            name.value = selectedPaymentRequestType.value.name;
            selectedExpenseLedgerBook.value = selectedPaymentRequestType.value.expenseLedgerBookId ? sharedAccountBooks.value.find((c) => c.generalLedgerBookId === selectedPaymentRequestType.value.expenseLedgerBookId.trim()) : "";
            selectedPaymentLedgerBook.value = selectedPaymentRequestType.value.paymentLedgerBookId ? sharedAccountBooks.value.find((c) => c.generalLedgerBookId === selectedPaymentRequestType.value.paymentLedgerBookId.trim()) : "";
            setLoading(false);
        };

        const getPaymentRequestTypeById = async () => {
            try {
                let payload = {
                    id: props.id,
                };
                await store.dispatch("paymentRequestType/getPaymentRequestTypeById", payload);
            } catch (error) {
                setDialog({
                    classTitle: "error",
                    title: mergeLang.value.notificationDialogHeader,
                    message: error,
                });
                createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
                setLoading(false);
            }
        };

        const wordingValidate = () => {
            if (isTypeProductAndService.value && !selectedExpenseLedgerBook.value) {
                return mergeLang.value.validateDropDownNoSelect + mergeLang.value.expenseLedgerBook;
            }
            if (!selectedPaymentLedgerBook.value) {
                return mergeLang.value.validateDropDownNoSelect + mergeLang.value.paymentLedgerBook;
            }
        };

        const validateData = () => {
            let returnStatus = false;
            if (isTypeProductAndService.value && !selectedExpenseLedgerBook.value) {
                expenseLedgerBookError.value = true;
                returnStatus = true;
            }
            if (!selectedPaymentLedgerBook.value) {
                paymentLedgerBookError.value = true;
                returnStatus = true;
            }
            if (returnStatus) {
                setDialog({
                classTitle: "error",
                title: mergeLang.value.validateSaveTitle,
                message: wordingValidate(),
                });
                createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
            }

            return returnStatus;
        };

        const updatePaymentRequestType = async () => {
            let payloadUpdate = {
                ...payload.value,
                id: props.id,
            };
            await store.dispatch("paymentRequestType/updatePaymentRequestType", payloadUpdate);
        };

        const save = async () => {
            if (validateData()) {
                return;
            }
            setLoading(true);
            try {
                await updatePaymentRequestType();
                setDialog({
                    classTitle: "success",
                    title: mergeLang.value.saveAllSucess,
                    message: mergeLang.value.saveAllSucess,
                });
                createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
                setLoading(false);
            } catch (error) {
                let message = error.message;
                setDialog({
                classTitle: "error",
                title: mergeLang.value.notificationDialogHeader,
                message: message,
                });
                createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
                setLoading(false);
            }
        };

        const setDialog = (dataConfig) => {
            const classMap = {
                success: "p-text-success",
                error: "p-error",
            };

            dataConfig.classTitle = classMap[dataConfig.classTitle] || "";

            dataConfig.button = [
                {
                id: "close",
                caption: mergeLang.value.close,
                class: "p-button-outlined p-button-secondary w-full",
                action: "cancel",
                },
            ];

            dataConfigDialog.value = {
                titleConfirm: dataConfig.title,
                classTitle: dataConfig.classTitle,
                wordingConfirm: dataConfig.message,
                dataButtons: dataConfig.button,
                display: true,
            };
        };

        watch(isSharedAccountBookReady, async (curValue) => {
            if (curValue) {
                setData();
            }
        });

        return {
            lang: mergeLang,
            textFormClass,

            //data
            name,
            selectedExpenseLedgerBook,
            selectedPaymentLedgerBook,
            isSharedAccountBookReady,
            expenseLedgerBookError,
            paymentLedgerBookError,

            //computed
            dataButtons,
            isTypeProductAndService,

            //methods
            goBack,
            save,
            accountBookDataReady,
        }
    }
}
</script>