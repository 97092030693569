export default {
	createTitle: "Create Product AP",
	editTitle: "Edit Product AP",
	productApDetail: "Product AP Detail",
	code: "Code",
	nameThai: "Name (Thai)",
	nameEnglish: "Name (English)",
	price: "Price (Baht)",
	withholding: "Withholding (%)",
	unit: "Unit",
	type: "Type",
	isActive: "Status",
	isLock: "IsCalculatePenalty",
	editTitleAccount: "Accounting",
	accountCash: "AccountCash (DR)",
	accountCredit:"AccountCredit (DR)",
	accountDebit:"AccountDebit (CR)",
	createNewProductAp: "Create Product AP",
	askForCreateNewProductAp: "Are you sure you want to discard change ",
	askForCreateNewProductApConfirm: "Are you create new document?",
	createProductApSuccess: "Create Product AP Success",
  	editProductApSuccess: "Edit Product AP Success",
  	cancelProductApSuccess: "Cancel Product AP :code Success",
	validateCode: "Please enter code",
	validateNameThai: "Please enter name (Thai)",
	validateNameEnglish: "Please enter name (English)",
	validateProductUnit: "Please select productUnit.",
	deleteProductAp: "Delete",
	settingAccountPq: "Account Payment Request (Setting expenses)",
	accountPqDebit: "Account Debit",
	accountPqCredit: "Account Credit",
	labelAcountPqDebit: "Previous, Current, Next",
	labelAcountPqCreditPrevious: "Previous",
	labelAcountPqCreditCurrent: "Current",
	labelAcountPqCreditNext: "Next",
};
