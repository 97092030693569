export default {
	title: "รูปแบบการคำนวณ",
	createMeterUsageSetting: "เพิ่มรายการ",
	saveMeterStepPrice: "บันทึก",
	meterSettingType: "วิธีการคำนวณค่าน้ำ/ไฟ",
	meterMean: "ส่วนเบี่ยงเบนค่าเฉลี่ยมิเตอร์น้ำ,ไฟ",
	meterSettingStepPrice: "ตั้งค่าคำนวณค่าน้ำ/ไฟ แบบขั้นบันได",

	labelMeanMeter: "ส่วนเบี่ยงเบนค่าเฉลี่ยมิเตอร์น้ำ,ไฟ (ใช้ในการแจ้งเตือนเวลาจดมิเตอร์)",

	radioFixed: "คงที่",
	radioStep: "ขั้นบันได",

	columnProductCode: "รหัสสินค้า",
	columnProductName: "ชื่อสินค้า",
	columnStartNo: "หน่วยเริ่มต้น",
	columnEndNo: "หน่วยสิ้นสุด",
	columnPrice: "ราคา",
	columnDeleteMeterStep: "ลบรายการ",
	askForDeleteMeterUsageSetting: "คุณยืนยันที่จะ ลบรายการนี้ ใช่หรือไม่?",
	errorMeterUsage: "ทำรายการไม่ได้ เนื่องจากมีข้อมูลอ้างถึง",

	waterType: "ระบบน้ำประปา",
	electricityType: "ระบบไฟฟ้า",
	coolingAirType: "ระบบทำความเย็น",

	labelStartNo: "หน่วยเริ่มต้น",
	labelEndNo: "หน่วยสิ้นสุด",
	labelPrice: "ราคา",

	meterType: "ประเภทมิเตอร์",
	validateStartNo: "กรุณากรอก หน่วยเริ่มต้น",
	validateEndNo: "กรุณากรอก หน่วยสิ้นสุด",
	validatePrice: "กรุณากรอก ราคา",
	validateNoProduct: "กรุณาเลือกรายรับ",
};
