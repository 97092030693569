import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default function useGetBank() {
  const store = useStore();
  let searchText = ref(null);

  const banks = computed(() => {
    return store.getters["shared/banks"];
  });

  const bankIsLastPage = computed(() => {
    return store.getters["shared/bankIsLastPage"];
  });

  const filteredBanks = computed(() => {
    if (!searchText.value) {
      //ช่องว่าง
      if (banks.value) {
        const result = [...banks.value];
        return result;
      } else {
        return null;
      }
    } else {
      return banks.value.filter((bank) => {
        return bank.name
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const searchBank = function (event) {
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchText.value = "";
      searchText.value = null;
    } else {
      searchText.value = event.query;
    }
  };

  let isSharedBankReady = ref(false);

  const getAllBanks = async function () {
    if (!bankIsLastPage.value) {
      await store.dispatch("shared/getAllBanks");
    }
    isSharedBankReady.value = true;
  };

  const getBankByCode = function (code) {
    return banks.value.find((bank) => {
      return bank.code.toLowerCase() === code.trim().toLowerCase();
    });
  };
  
  onMounted(async () => {
    await getAllBanks();
  });

  return {
    searchText,
    filteredBanks,
    searchBank,
    isSharedBankReady,
    getBankByCode,
    banks,
    getAllBanks
  }
}
