<template>  
  <import-asset-dialog
    v-model:visible="showUploadAssetDialog"
    @close="showUploadAssetDialog = false"
    @data-upload="showUploadAssetDialog = false"
  />
  <base-container>
    <div
      class="flex justify-content-between align-items-center"
      style="padding-top: 20px"
    >
      <div class="my-3">
        <PvButton
          icon="pi pi-chevron-left"
          :label="lang.back"
          class="mr-2 p-button-secondary"
          @click="goBack"
          v-if="isHideBack"
        />
      </div>
      <h2 :class="textHeaderClass" class="my-0">{{ lang.title }}</h2>
    </div>
    <div class="surface-card shadow-2 p-3 border-round">
      <div class="flex flex-wrap justify-content-between align-items-center">        
        <div class="pt-2 pb-1">
          <PvButton
            icon="pi pi-file"
            :label="lang.create"
            class="mr-2 mb-2"
            @click="gotoEdit('create')"
          />
          <PvButton
            icon="pi pi-list"
            :label="parameterButtonText"
            @click="toggleParameterVisible"
            class="p-button-help mr-2 mb-2"
          />
        </div>

        <div class="flex">
          <PvButton
            class="p-button-outlined"
            @click="importAsset"
          >
            <icon-download iconColor="primary" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{ lang.importAsset }}</span>
          </PvButton>
        </div>
      </div>
      <transition>
        <div v-if="isParameterVisible">
          <section class="flex flex-wrap" :class="textFormClass">
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
              <label for="dateStart">{{ lang.dateStart }}</label>
              <PvCalendar
                id="dateStart"
                v-model="dateStart"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
              <label for="dateEnd">{{ lang.dateEnd }}</label>
              <PvCalendar
                id="dateEnd"
                v-model="dateEnd"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
              <label for="codeStart">{{ lang.assetCodeStart }}</label>
              <PvInputText
                id="codeStart"
                type="text"
                class="inputfield w-full mt-1"
                v-model="codeStart"
              />
            </div>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
              <label for="codeEnd">{{ lang.assetCodeEnd }}</label>
              <PvInputText
                id="codeEnd"
                type="text"
                class="inputfield w-full mt-1"
                v-model="codeEnd"
              />
            </div>
            <PvButton
              icon="pi pi-search"
              :label="lang.search"
              class="mr-2 mb-2"
              @click="getAssetRegisters"
            />
          </section>
        </div>
      </transition>
      <PvDataTable
        showGridlines
        :value="assetRegisters"
        v-model:selection="selectedAssetRegister"
        selectionMode="single"
        dataKey="id"
        responsiveLayout="scroll"
        :stripedRows="true"
        class="p-datatable-sm"
        :class="textTableClass"
        :loading="isLoading"
        @row-dblclick="gotoEdit(this.selectedAssetRegister.id)"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn
          field="code"
          :header="lang.columnAssetCode"
          style="min-width: 2rem"
          headerClass="h-center"
          bodyClass="text-left"
          sortable
        ></PvColumn>
        <PvColumn
          field="name"
          :header="lang.columnAssetName"
          style="min-width: 2rem"
          headerClass="h-center"
          bodyClass="text-left"
          sortable
        ></PvColumn>
        <PvColumn
          field="assetAccountCode"
          :header="lang.columnAssetAccountCode"
          style="min-width: 2rem"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        ></PvColumn>
        <PvColumn
          field="depreCalDate"
          :header="lang.columnBeginDate"
          style="min-width: 2rem"
          headerClass="h-center"
          bodyClass="text-center"
          sortable
        >
          <template #body="slotProps">
            {{ formatDate(slotProps.data.depreCalDate) }}
          </template>
        </PvColumn>
        <PvColumn
          field="buyPrice"
          :header="lang.columnBuyPrice"
          style="min-width: 2rem"
          headerClass="h-center"
          bodyClass="text-right"
          sortable
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.buyPrice) }}
          </template>
        </PvColumn>
        <PvColumn
          field="deprePrice"
          :header="lang.columnDeprePrice"
          style="min-width: 2rem"
          headerClass="h-center"
          bodyClass="text-right"
          sortable
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.deprePrice) }}
          </template>
        </PvColumn>
        <PvColumn
            field="activeStatus"
            :header="lang.status"
            style="min-width: 8rem"
            headerClass="h-center"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <base-tag
                v-if="slotProps.data.activeStatus != ''"
                :Caption="
                  slotProps.data.activeStatus.trim() == 'Y'
                    ? lang.active
                    : lang.inActive
                "
                :Class="
                  slotProps.data.activeStatus.trim() == 'Y'
                    ? 'success'
                    : 'secondary'
                "
              />
            </template>
          </PvColumn>
        <PvColumn
          style="min-width: 4rem"
          headerClass="h-center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <PvButton
              type="button"
              class="p-button-secondary p-button-text py-0"
              icon="pi pi-pencil"
              @click="gotoEdit(slotProps.data.id)"
            ></PvButton>
            <PvButton
              type="button"
              class="p-button-secondary p-button-text py-0"
              icon="pi pi-print"
            ></PvButton>
          </template>
        </PvColumn>
        <template #footer>
          <PvPaginator
            :rows="rowsPerPage"
            :totalRecords="totalRecords"
            @page="onPageChange($event)"
            :rowsPerPageOptions="[10, 50, 100]"
          >
            <template #start="slotProps">
              {{ lang.page }}:
              {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
            </template>
          </PvPaginator>
        </template>
      </PvDataTable>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import ImportAssetDialog from "../../../components/Asset/ImportAssetDialog.vue";
import { useStore } from "vuex";
import { computed, inject } from "vue";
export default {
  components: {
    ImportAssetDialog,
  },
  props: ["id", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.asset.assetRegister.listing };
      } else {
        return { ...lang.en.base, ...lang.en.asset.assetRegister.listing };
      }
    });

    const isHideBack = inject('isHideBack');

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,

      isHideBack,
    };
  },
  data() {
    return {
      isParameterVisible: true,
      dateStart: null,
      dateEnd: null,
      codeStart: "",
      codeEnd: "",
      isLoading: false,
      selectedAssetRegister: null,
      selectedPage: 1,
      rowsPerPage: 10,
      showUploadAssetDialog: false,
    };
  },
  async created() {
    let date = new Date();
    this.dateStart = new Date("2000-01-01");
    this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const localSearch = localStorage.getItem("erp_search_assetRegisterList");
    if (localSearch) {
      let search = JSON.parse(localSearch);
      if (search.assetRegisterEntry === "Entry") {
        if (search.dateStart != null && search.dateStart != "") {
          this.dateStart = new Date(search.dateStart);
        } else this.dateStart = "";

        if (search.dateEnd != null && search.dateEnd != "") {
          this.dateEnd = new Date(search.dateEnd);
        } else this.dateEnd = "";

        this.codeStart = search.codeStart || this.codeStart;
        this.codeEnd = search.codeEnd || this.codeEnd;

        search.assetRegisterEntry = "";

        localStorage.setItem(
          "erp_search_assetRegisterList",
          JSON.stringify(search)
        );
      }
    }

    await this.getAssetRegisters();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    toggleParameterVisible() {
      this.isParameterVisible = !this.isParameterVisible;
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      this.getAssetRegisters();
    },
    async getAssetRegisters() {
      this.isLoading = true;

      let where = {
        projectId: this.selectedProject.id,
        dateStart: this.selectedDateStart,
        dateEnd: this.selectedDateEnd,
        codeStart: this.codeStart || null,
        codeEnd: this.codeEnd || null,
      };

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };

      try {
        await this.$store.dispatch(
          "assetAssetRegister/getAssetRegisters",
          payload
        );
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }

      where = {
        ...where,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      };

      localStorage.setItem(
        "erp_search_assetRegisterList",
        JSON.stringify(where)
      );

      this.isLoading = false;
    },
    gotoEdit(Id) {
      this.$router.push({name: "asset-register-edit", params:{id: Id}});
    },    
    importAsset() {
      this.showUploadAssetDialog = true;
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    parameterButtonText() {
      return this.isParameterVisible
        ? this.lang.hideParameters
        : this.lang.showParameters;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    totalPage() {
      return this.$store.getters["assetAssetRegister/totalPage"];
    },
    currentPage() {
      return this.$store.getters["assetAssetRegister/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    assetRegisters() {
      return this.$store.getters["assetAssetRegister/assetRegisters"];
    },
  },
};
</script>
