export default {
  setBookBank(state, payload) {
    // state.bookBanks = payload.data;
    let bookBanks = [...state.bookBanks];
    for (const bookBank of payload.data) {
      if(bookBanks.findIndex(c => c.id === bookBank.id) < 0){
        bookBanks.push(bookBank);
      }
    }
    state.bookBanks = bookBanks;
    state.totalPage = payload.page.pageCount;
    state.currentPage = payload.page.currentPage;
  },
  setSelectedBookBank(state, payload) {
    state.selectedBookBank = payload;
  },
  resetBookBank(state) {
    state.bookBanks = [];
    state.totalPage = 0;
    state.currentPage = 0;
  },
  setActiveStatus(state, payload) {
		state.activeStatus = payload;
	},
};
  