<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
    :draggable="false"
    :header="title"
  >
    <section class="flex flex-wrap" :class="textFormClass">
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="sequence">{{ lang.sequence }}</label>
        <PvInputText
          id="sequence"
          type="text"
          class="inputfield w-full mt-1"
          v-model="sequence"
          :class="{ 'p-invalid': sequenceError }"
          @keypress="sequenceError = false"
        />
        <small v-if="sequenceError" id="sequence-help" class="p-error">{{lang.validateSequence
        }}</small>
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="nameTh">{{ lang.nameTh }}</label>
        <PvInputText
          id="nameTh"
          type="text"
          class="inputfield w-full mt-1"
          v-model="nameThai"
          :class="{ 'p-invalid': nameThaiError }"
          @keypress="nameThaiError = false"
        />
        <small v-if="nameThaiError" id="nameThai-help" class="p-error">{{lang.validateNameThai
        }}</small>
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="nameEn">{{ lang.nameEn }}</label>
        <PvInputText
          id="nameEn"
          type="text"
          class="inputfield w-full mt-1"
          v-model="nameEng"
          :class="{ 'p-invalid': nameEngError }"
          @keypress="nameEngError = false"
        />
        <small v-if="nameEngError" id="nameEng-help" class="p-error">{{lang.validateNameEng
        }}</small>
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="path">{{ lang.path }}</label>
        <PvInputText
          id="path"
          type="text"
          class="inputfield w-full mt-1"
          v-model="path"
          :class="{ 'p-invalid': pathError }"
          @keypress="pathError = false"
        />
        <small v-if="pathError" id="path-help" class="p-error">{{lang.validatePath
        }}</small>
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="activeStatus">{{ lang.activeStatus }}</label>
        <PvDropdown
          class="inputfield w-full mt-1"
          :options="activeStatus"
          :showClear="clearActiveStatus"
          optionLabel="name"
          optionValue="code"
          v-model="selectActiveStatus"
        />
      </div>
    </section>
    <template #footer>
      <PvButton
        :label="lang.save"
        icon="pi pi-check"
        @click="save"
      />
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        @click="closeDialog"
        class="p-button-text"
      />
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  emits:["close", "saved"],
  props: {     
    reportMenu: {
      type: Object,
    },
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass } = useTextStyle();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.admin.report };
      } else {
        return { ...lang.en.base, ...lang.en.admin.report };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
    };
  },
  data() {
    return {
      sequence: "",
      nameThai: "",
      nameEng: "",
      path: "",
      activeStatus: [
        { name: this.lang.active, code: "Y" },
        { name: this.lang.inActive, code: "N" },
      ],
      selectActiveStatus: "Y",
      clearActiveStatus: false,
      sequenceError: false,
      nameThaiError: false,
      nameEngError: false,
      pathError: false,
    }
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.initiateData();
      this.$emit("close");
    },
    initiateData() {
      this.sequence = "";
      this.nameThai = "";
      this.nameEng = "";
      this.path = "";
      this.selectActiveStatus = "Y";
      this.sequenceError = false;
      this.nameThaiError = false;
      this.nameEngError = false;
      this.pathError = false;
      this.activeStatusError = false;
      this.setLoading(false);
    },
    setData() {
      this.sequence = this.reportMenu.sequence;
      this.nameThai = this.reportMenu.nameThai;
      this.nameEng = this.reportMenu.nameEnglish;
      this.path = this.reportMenu.path;
      this.selectActiveStatus = this.reportMenu.isActive;
    },
    validateData() {
      if (!this.sequence) {
        this.sequenceError = true;
        return true;
      }

      if (!this.nameThai) {
        this.nameThaiError = true;
        return true;
      }

      if (!this.nameEng) {
        this.nameEngError = true;
        return true;
      }

      // if (!this.path) {
      //   this.pathError = true;
      //   return true;
      // }

      return false;
    },
    async save() {
      if (this.validateData()) {
        this.setLoading(false);
        return;
      }
      this.setLoading(true);
      try {
        let payload = {
          sequence: this.sequence,
          nameThai: this.nameThai,
          nameEnglish: this.nameEng,
          path: this.path ? this.path : "",
          isActive: this.selectActiveStatus,
          type: "Add",
        }

        if (this.reportMenu) {
          payload = { ...payload, id: this.reportMenu.id, type: "Update" };
        }

        // console.log("save", payload);

        await this.$store.dispatch("reportMenu/createOrUpdateReportMenu", payload);

        this.$toast.add({
          severity: "success",
          summary: this.lang.saveSuccess,
          detail: "",
          life: 5000,
        });
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 5000,
        });
      }

      this.setLoading(false);
      this.$emit("saved");
      this.closeDialog();
    }
  },
  computed: {
    title() {
      let result = this.lang.addNewReportMenu;
      if(this.reportMenu) {
        result = this.lang.editReportMenu;
      }
      return result;
    }
  },
  watch: {
    reportMenu(curValue) {
      if(curValue) {
          this.setData();
      }
    }
  }
};
</script>
