export default {
    editDebtFreeFollowUpTitle: "Debt Free Follow Up Detail",
    debtFreeFollowUp: "Debt Free Follow Up",
    unitCode: "UnitCode",
    unitAddressNo: "Unit Address No",
    ownerName: "Owner Name",
    ownerPhone: "Owner Phone",
    debtFreeCode: "Debt Free Code",
    debtFreeDate: "Debt Free Date",
    newOwner: "New Owner*",
    transferDate: "Transfer Date*",
    validateNewOwner: "Please select new owner",
    validateTransferDate: "Please enter transfer date",
    validateStatus: "Please select status",
    followUpSuccess: "Follow Up Debt Free Code : :code Successfully.",
    customerReference: " (Reference from resident/customer)",
}