<template>
  <base-toolbar 
    :title="lang.title" 
    @goBack="goBack"
    :hideBackButton="isHideBack"
  ></base-toolbar>

  <base-dialog
    v-model:visible="showValidateDialog"
    :titleConfirm="titleConfirm"
    :classTitle="baseDialogClassTitle"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    :isCloseDialog="true"
    @confirm="showValidateDialog = false"
    @close="showValidateDialog = false"
  ></base-dialog>

  <base-form-print-modal
    v-model:visible="showFormPrintModal"
    :search="searchTextPrint"
    @close="showFormPrintModal = false"
    @path-selected="formPrintModalSelected"
  ></base-form-print-modal>

  <notice-print-lot-dialog
    v-model:visible="showNotivePrintLotDialog"
    :openDialog="openDialog"
    @close="showNotivePrintLotDialog = false"
    @selected-print-lot="noticePrintLot"
  >
  </notice-print-lot-dialog>

  <base-container>
    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5" :class="textFormClass">
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.title }}</label>
        <div>
          <PvButton
            class="p-button-outlined ml-2"
            @click="printNoticeLotDialog()"
            :disabled="!isRolePrint"
          >
            <icon-printer
              class="p-button-outlined p-button-primary mr-2"
              iconColor="primary"
              height="16"
              width="16"
            />
            <span style="font-size: 14px">
              {{ lang.printLot }}
            </span>
          </PvButton>
        </div>
      </div>

      <PvDivider></PvDivider>

      <div class="flex flex-wrap mb-3">
        <div class="lg:col-2 md:col-3 col-12 pl-0 pr-2 pb-0">
          <PvDropdown
            class="inputfield w-full"
            :options="selectType"
            optionLabel="name"
            optionValue="code"
            v-model="selectedType"
            @change="changeSelectedType()"
          />
        </div>
        <div class="lg:col-5 md:col-6 col-12 pl-0 pr-2 pb-0">
          <div
            class="surface-card flex justify-content-between"
            v-if="this.selectedType === 'Date'"
          >
            <PvCalendar
              id="dateStart"
              v-model="dateStart"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
            <label class="ml-2 mr-2 pt-3">{{ " - " }}</label>
            <PvCalendar
              id="dateEnd"
              v-model="dateEnd"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>

          <span class="p-input-icon-left w-full" v-else>
            <i class="pi pi-search" />
            <PvInputText
              class="inputfield w-full"
              v-model="searchText"
              :placeholder="this.selectedTypePlaceholder"
              @keypress="searchTextEnter($event)"
              :disabled="this.selectedType === 'All'"
              autofocus
            />
          </span>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pb-0">
          <PvButton class="ml-2" @click="getNotices()">
            <span class="font-normal">{{ lang.search }}</span>
          </PvButton>
        </div>
      </div>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="notices"
          v-model:selection="selectedNotice"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(this.selectedNotice.id)"
          scrollable
        >
          <!-- <PvDataTable
          :value="notices"
          v-model:selection="selectedNotice"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(this.selectedNotice.id)"
          scrollable
        > -->
          <PvColumn
            field="date"
            :header="lang.columnDate"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <u>{{ formatDate(slotProps.data.date) }}</u>
            </template>
          </PvColumn>

          <PvColumn
            field="code"
            :header="lang.columnCode"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ slotProps.data.code }}
            </template>
          </PvColumn>

          <PvColumn
            field="unitCode"
            :header="lang.columnUnit"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>

          <PvColumn
            field="unitAddressNo"
            :header="lang.columnAddress"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>

          <PvColumn
            field="customerName"
            :header="lang.columnCustomerName"
            style="min-width: 20rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
          </PvColumn>

          <PvColumn
            field="totalAmount"
            :header="lang.columnTotalAmount"
            style="min-width: 10rem"
            headerClass="h-right"
            bodyClass="text-right"
          >
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.totalAmount) }}
            </template>
          </PvColumn>

          <PvColumn
            field="postStep"
            :header="lang.columnPostStep"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <base-tag
                :Caption="lang.printed"
                Class="primary"
                v-if="slotProps.data.postStep == 'P'"
              />
            </template>
          </PvColumn>

          <PvColumn
            :header="lang.columnIsPaid"
            style="min-width: 12rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <base-tag
                :Caption="lang.fullPayment"
                Class="success"
                v-if="slotProps.data.paidStatus == 'F'"
              />
              <base-tag
                :Caption="lang.splitRefund"
                Class="primary"
                v-else-if="slotProps.data.paidStatus == 'D'"
              />
            </template>
          </PvColumn>

          <PvColumn
            :header="lang.columnIsCancel"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <base-tag
                :Caption="lang.cancel"
                Class="error"
                v-if="slotProps.data.cancelStatus == 'C'"
              />
            </template>
          </PvColumn>

          <PvColumn
            field="createBy"
            :header="lang.columnCreateBy"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>
          <PvColumn
            field="createDate"
            :header="lang.columnCreateDate"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.createDate) }}
            </template>
          </PvColumn>

          <!-- <PvColumn
            field="updateBy"
            :header="lang.columnUpdateBy"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>
          <PvColumn
            field="updateDate"
            :header="lang.columnUpdateDate"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <label v-if="slotProps.data.updateDate">
                {{ formatDate(slotProps.data.updateDate) }}
              </label>
            </template>
          </PvColumn> -->

          <PvColumn
            style="min-width: 10rem"
            headerClass="h-center"
            bodyStyle="text-align: center;justify-content: center;align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                icon="pi pi-eye"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-eye width="24" height="24" iconColor="secondary"></icon-eye>
              </PvButton>

              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                @click="selectPrintForm(slotProps.data)"
                :disabled="!isRolePrint"
              >
                <icon-printer iconColor="secondary" width="24" height="24" />
              </PvButton>
            </template>
          </PvColumn>

          <template #footer>
            <div>
              <PvPaginator
                :rows="rowsPerPage"
                :totalRecords="totalRecords"
                @page="onPageChange($event)"
                :rowsPerPageOptions="[10, 50, 100]"
                class="p-pagination-radius"
              >
                <template #start="slotProps">
                  {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{
                    totalPage
                  }}
                </template>
              </PvPaginator>
            </div>
          </template>

          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import useGetRoleByBook from "../../../hooks/getRoleByBook.js";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import NoticePrintLotDialog from "../../../components/AR/notice/NoticePrintLotDialog.vue";
export default {
  components: {
    NoticePrintLotDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const isHideBack = inject("isHideBack");

    const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();
    const {
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
    } = useGetFormPrint();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.notice.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.notice.listing,
        };
      }
    });
    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getRoleByBookByCode,
      isSharedRoleByBookReady,
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
      isHideBack,
    };
  },
  data() {
    return {
      selectType: [
        { name: this.lang.selectDate, code: "Date" },
        { name: this.lang.selectCode, code: "Code" },
        { name: this.lang.selectUnit, code: "Unit" },
        { name: this.lang.selectAddress, code: "Address" },
        { name: this.lang.selectCustomerName, code: "Name" },
        { name: this.lang.selectAll, code: "All" },
      ],
      selectedType: "Date",
      dateStart: null,
      dateEnd: null,
      code: "",
      unitCode: "",
      unitAddress: "",
      customerSearch: "",
      searchText: "",
      selectedNotice: null,
      isLoading: false,
      selectedPage: 1,
      rowsPerPage: 10,

      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: this.lang.notificationDialogHeader,
      validateWording: "",
      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.confirm,
          class: "w-full",
          action: "confirm",
        },
      ],
      storageName: "",
      showNotivePrintLotDialog: false,
      openDialog: "",
      showFormPrintModal: false,
      path: "",
      selectedPrintLot: null,
      selectedPrint: null,
    };
  },
  async mounted() {
    this.isLoading = true;
    this.initiateData();
    this.checkShouldSetData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    gotoEdit(id) {
      if (!this.isRoleSelect) {
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleSelect;
        this.showValidateDialog = true;
        return true;
      }

      this.$router.push({name: "ar-notice-edit", params: {id: id}});
    },
    initiateData() {
      let date = new Date();
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.code = "";
      this.unitCode = "";
      this.unitAddress = "";
      this.customerSearch = "";
      this.searchText = "";
    },
    async changeSelectedType() {
      this.initiateData();
      // await this.getNotices();
    },
    searchTextEnter(event) {
      if (this.selectedType === "Code") {
        const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        const keyPressed = event.key;

        if (event.key != "Enter" && !keysAllowed.includes(keyPressed)) {
          event.preventDefault();
        }
      }

      if (event.key == "Enter") {
        this.getNotices();
      }
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getNotices();
    },
    async getNotices() {
      if (
        (this.selectedType == "Code" ||
          this.selectedType == "Unit" ||
          this.selectedType == "Address" ||
          this.selectedType == "Name") &&
        this.searchText == ""
      ) {
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateSearchText;
        this.showValidateDialog = true;
        return true;
      }

      try {
        this.isLoading = true;

        if (this.selectedType === "Code") this.code = this.searchText;
        if (this.selectedType === "Unit") this.unitCode = this.searchText;
        if (this.selectedType === "Address") this.unitAddress = this.searchText;
        if (this.selectedType === "Name") this.customerSearch = this.searchText;
        let where = {
          projectId: this.selectedProject.id,
          dateStart: this.selectedType == "Date" ? this.selectedDateStart : null,
          dateEnd: this.selectedType == "Date" ? this.selectedDateEnd : null,
          code: this.code || null,
          unitCode: this.unitCode || null,
          unitAddressNo: this.unitAddress || null,
          customerSearch: this.customerSearch || null,
          selectedType: this.selectedType || null,
        };

        const payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          where,
        };
        // console.log("payload=", payload);
        await this.$store.dispatch("arNotice/getNotices", payload);
        this.isLoading = false;
        
        where = {
          ...where,
          searchText: this.searchText,
          selectedType: this.selectedType,
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
        };
        // console.log("getNotices_where=", where);
        localStorage.setItem(this.storageName, JSON.stringify(where));
      } catch (error) {
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.isLoading = false;
      }
    },
    async checkShouldSetData() {
      if (this.isSharedRoleByBookReady) {
        await this.setLocalSearch();
        await this.getNotices();
      }
    },
    printNoticeLotDialog() {
      if (!this.isRolePrint) {
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validatePrintPermission;
        this.showValidateDialog = true;
        return true;
      }

      this.openDialog = new Date().getSeconds().toString();
      this.showNotivePrintLotDialog = true;
    },
    noticePrintLot(data) {
      this.selectedPrintLot = data;
      this.showNotivePrintLotDialog = false;
      this.selectPrintForm(null);
    },
    async selectPrintForm(data) {
      this.selectedPrint = data;
      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: "NOTICE",
      };
      const payload = { where };
      await this.getFormPrints(payload);

      if (this.filteredFormPrints.length == 1) {
        this.path = this.filteredFormPrints[0].path;
        if (this.selectedPrint) {
          this.printNotice();
        } else {
          this.printNoticeLot();
        }
      } else {
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path;
      // this.categoryPrint = event.category;
      if (this.selectedPrint) {
        this.printNotice();
      } else {
        this.printNoticeLot();
      }
      this.showFormPrintModal = false;
    },
    async printNotice() {
      this.setLoading(true);

      let parameter = {
        ProjectId: this.selectedProject.id,
        NoticeDate: this.formatDateToISOString(new Date(this.selectedPrint.date)),
        NoticeCodeStart: "",
        NoticeCodeEnd: "",
        UnitCodeStart: "",
        UnitCodeEnd: "",
        NoticeId: this.selectedPrint.id,
        IsIgnoreZero: "N",
        IsGroup: "Y",
        OrderBy: "code",
        IsForApi: "True",
      };

      let payload = {
        path: this.path + ".pdf",
        fileName: "Notice_" + this.selectedPrint.code.trim(),
        parameter: JSON.stringify(parameter),
        ProjectId: this.selectedProject.id,
        NoticeDate: this.formatDateToISOString(new Date(this.selectedPrint.date)),
        NoticeId: this.selectedPrint.id,
        IsIgnoreZero: "N",
      };

      // console.log("payload=",payload);
      let blob = null;
      try {
        if (this.selectedPrint && this.selectedPrint.postStep == "P") {
          await this.$store.dispatch("shared/printForm", payload);
          blob = this.$store.getters["shared/blob"];
        } else {
          await this.$store.dispatch("arNotice/printNotice", payload);
          blob = this.$store.getters["arNotice/blob"];
        }
        await this.getNotices();
      } catch (error) {
        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.confirm,
            class: "w-full",
            action: "confirm",
          },
        ];
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }

      this.getPreviewBlob(blob);
      this.setLoading(false);
    },
    async printNoticeLot() {
      this.setLoading(true);

      let parameter = {
        ProjectId: this.selectedProject.id,
        NoticeDate: this.selectedPrintLot.date,
        NoticeCodeStart: this.selectedPrintLot.codeStart,
        NoticeCodeEnd: this.selectedPrintLot.codeEnd,
        UnitCodeStart: this.selectedPrintLot.unitStart,
        UnitCodeEnd: this.selectedPrintLot.unitEnd,
        NoticeId: "",
        IsIgnoreZero: this.selectedPrintLot.isIgnoreZero,
        IsGroup: this.selectedPrintLot.isGroup,
        OrderBy: this.selectedPrintLot.codeStart ? "code" : "room",
        IsForApi: "True",
      };

      let fileName =
        this.selectedPrintLot.codeStart + "-" + this.selectedPrintLot.codeEnd;
      if (this.selectedPrintLot.unitStart)
        fileName = this.selectedPrintLot.unitStart + "-" + this.selectedPrintLot.unitEnd;
      let payload = {
        path: this.path + ".pdf",
        fileName: "Notice_" + fileName,
        parameter: JSON.stringify(parameter),
        ProjectId: this.selectedProject.id,
        NoticeDate: this.selectedPrintLot.date,
        NoticeId: "",
        NoticeCodeStart: this.selectedPrintLot.codeStart,
        NoticeCodeEnd: this.selectedPrintLot.codeEnd,
        UnitCodeStart: this.selectedPrintLot.unitStart,
        UnitCodeEnd: this.selectedPrintLot.unitEnd,
        IsIgnoreZero: this.selectedPrintLot.isIgnoreZero,
      };
      // console.log("payload=", payload);
      let blob = null;
      try {
        await this.$store.dispatch("arNotice/printNotice", payload);
        blob = this.$store.getters["arNotice/blob"];
        this.showNotivePrintLotDialog = true;
        await this.getNotices();
      } catch (error) {
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }

      this.getPreviewBlob(blob);
      this.setLoading(false);
    },
    async setLocalSearch() {
      this.storageName = `erp_search_NotictList_${this.selectedProject.code}_${this.userCode}`;

      const localSearch = localStorage.getItem(this.storageName);
      // console.log("created_localSearch=",localSearch);
      if (localSearch) {
        let search = JSON.parse(localSearch);
        if (search.NotictEntry === "Entry") {
          this.selectedType = search.selectedType || this.selectedType;
          this.searchText = search.searchText || this.searchText;

          if (search.dateStart != null && search.dateStart != "") {
            this.dateStart = new Date(search.dateStart);
          } else this.dateStart = "";

          if (search.dateEnd != null && search.dateEnd != "") {
            this.dateEnd = new Date(search.dateEnd);
          } else this.dateEnd = "";

          search.NotictEntry = "";
          localStorage.setItem(this.storageName, JSON.stringify(search));
        }
      }
    },
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    notices() {
      return this.$store.getters["arNotice/notices"];
    },
    totalPage() {
      return this.$store.getters["arNotice/totalPage"];
    },
    currentPage() {
      return this.$store.getters["arNotice/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    roleByBook() {
      return this.getRoleByBookByCode("NOTICE");
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart && this.selectedType == "Date") {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd && this.selectedType == "Date") {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    selectedTypePlaceholder() {
      let Placeholder = this.lang.search;
      if (this.selectedType === "Code") Placeholder += this.lang.selectCode;
      if (this.selectedType === "Unit") Placeholder += this.lang.selectUnit;
      if (this.selectedType === "Address") Placeholder += this.lang.selectAddress;
      if (this.selectedType === "Name") Placeholder += this.lang.selectCustomerName;
      if (this.selectedType === "All") Placeholder += this.lang.selectAll;
      return Placeholder;
    },

    isRoleSelect() {
      if (this.roleByBook && this.roleByBook.select == "Y") {
        return true;
      }
      return false;
    },
    isRolePrint() {
      if (this.roleByBook && this.roleByBook.print == "Y") {
        return true;
      }
      return false;
    },
  },
  watch: {
    isSharedRoleByBookReady() {
      this.checkShouldSetData();
    },
  },
};
</script>

<style></style>
