<template>
  <base-toolbar
    :title="lang.backTitle"
    :dataButtons="dataButtonToolbar"
    @goBack="goBack"
    @gotoSave="save"
    @gotoCreate="create"
    @gotoPrint="printDocument"
    @gotoCancel="askForCancel"
  ></base-toolbar>
  <base-dialog
    v-model:visible="showCopyItemDialog"
    :titleConfirm="titleConfirm"
    :classTitle="classTitle"
    :dataButtons="dataButtonsCopy"
    :isCloseDialog="true"
    @close="closeBaseDialog"
    @goto-copy-item="copyItemSelected"
  >
    <template #dialogBody>
      <div class="lg:col-12 md:col-12 col-12 p-0 w-full">
        <label for="copyQuantity">{{ lang.generateProduct }}</label>
        <PvInputNumber
          id="copyQuantity"
          class="mt-1 w-full"
          v-model="copyQuantity"
          mode="decimal"
          @focus="$event.target.select()"
          :min="1"
          :max="100"
          inputClass="w-full text-right"
          showButtons
        />
      </div>
    </template>
  </base-dialog>
  <base-container>
    <payment-request-product-dialog
      v-model:visible="showProductDialog"
      :data="selectedProductDetail"
      :key="keyToReLoad"
      :budgetHeaderId="budgetHeaderId"
      :dateAt="dateAt"
      :documentDate="documentDate"
      @close="closeProductDialog"
      @confirm="confirmProductDialog"
    ></payment-request-product-dialog>
    <setting-dialog
      v-model:visible="showSettingDialog"
      :dataProducts="paymentRequest"
      :key="keyToReLoad"
      :documentDate="documentDate"
      @close="closeSettingDialog"
      @confirm="confirmSettingDialog"
    ></setting-dialog>
    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5" :class="textFormClass">
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.title }}</label>
        <div class="flex align-items-center justify-content-center">
          <PvInputSwitch v-model="activeStatus" class="mr-2" @change="updateLockStatus" v-if="!isCreateDocument" />
          <label for="status" v-if="!isCreateDocument">
            {{ activeStatus ? lang.activeLockStatus : lang.activeUnlockStatus }}
          </label>
          <PvButton
            type="button"
            icon="pi pi-file"
            class="ml-3"
            @click="getWithholdingTax"
            :label="lang.withholdingTaxButton"
          />
          <PvButton type="button" icon="pi pi-file" class="ml-3" @click="getPayment" :label="lang.paymentButton" />
          <PvButton class="p-button-outlined ml-3" @click="gotoSettingGL">
            <icon-setting iconColor="primary" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{ lang.settingGL }}</span>
          </PvButton>
          <PvButton class="p-button-outlined ml-3" @click="autoGL">
            <icon-calculator iconColor="primary" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{ lang.autoGL }}</span>
          </PvButton>
        </div>
      </div>
      <PvDivider></PvDivider>
      <section class="flex flex-wrap justify-content align-items-center mb-5" :class="textFormClass">
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="code">{{ lang.code }}</label>
          <PvInputText id="code" v-model="code" type="text" class="inputfield w-full mt-1 p input-disable" readonly />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="paymentRequestTypeList">{{ lang.paymentRequestType }}</label>
          <payment-request-type-dropdown
            id="selectedPaymentRequestType"
            v-model="selectedPaymentRequestType"
            class="inputfield w-full mr-2"
            optionValue="id"
            @load-finish="paymentRequestTypeDataReady"
            :isAllType="false"
            @change="changePaymentRequestType"
          />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2"></div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="date">{{ lang.documentDate }}</label>
          <PvCalendar id="date" v-model="documentDate" class="inputfield w-full mt-1" showIcon dateFormat="dd/mm/yy" />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="budgetYear">{{ lang.budgetYear }}</label>
          <budget-year-auto-complete
            id="budgetYear"
            v-model="selectedBudgetYear"
            class="inputfield w-full mt-1"
            @load-finish="budgetsDataReady"
            :isLoadingData="true"
            :isActive="true"
            @item-select="selectedBudgetYearError = false"
          />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2"></div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="selectedSupplier">{{ lang.supplier }}</label>
          <base-supplier-auto-complete
            id="selectedSupplier"
            v-model="selectedSupplier"
            class="inputfield w-full mt-1"
            @load-finish="supplierDataReady"
            :class="{ 'p-invalid': supplierError }"
            @item-select="changeSelectedSupplier"
          />
          <small v-if="supplierError" id="supplier-help" class="p-error">{{ lang.validateNoSupplierSelect }}</small>
        </div>
        <div class="lg:col-8 md:col-8 col-12 pl-0 pr-2">
          <base-input-text-limit :caption="lang.remark" :limitMessage="250" :data="remark" @data="updateRemark" />
        </div>
      </section>

      <div class="flex flex-wrap justify-content-between align-items-center mb-2">
        <label for="code" class="font-lg font-normal">{{ lang.detail }}</label>
        <PvButton class="p-button-outlined ml-3" @click="addDetail">
          <icon-plus-add iconColor="primary" width="16" height="16" />
          <span class="p-button-label font-normal ml-1">{{ lang.addDetail }}</span>
        </PvButton>
      </div>
      <div v-if="detailsError" class="mb-2">
        <PvInlineMessage severity="error" class="w-full justify-content-start">
          {{ lang.validateDetailSelect }}
        </PvInlineMessage>
      </div>
      <PvDataTable
        class="w-full"
        :value="details"
        dataKey="id"
        responsiveLayout="scroll"
        :class="textTableClass"
        v-model:selection="selectedDetail"
        selectionMode="single"
        scrollHeight="294px"
        :scrollable="true"
        @row-dblclick="editDetail(selectedDetail)"
      >
        <template #empty>
          <div class="w-full" style="text-align: center">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn
          field="itemNo"
          :header="lang.columnItemNo"
          style="min-width: 3rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
        />
        <PvColumn
          field="productCode"
          :header="lang.columnProductCode"
          style="min-width: 9rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
        />
        <PvColumn
          field="productName"
          :header="lang.columnProductName"
          style="min-width: 15rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
        />
        <!-- <PvColumn
          field="code"
          :header="lang.columnBudgetWithin"
          style="min-width: 10rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
        >
          <template #body="slotProps">
            <base-tag
              :Caption="calculate(slotProps.data.saveStatusCode).caption"
              :Class="setBaseTagStatus(slotProps.data.saveStatusCode).class"
            />
          </template>
        </PvColumn> -->
        <PvColumn
          field="description"
          :header="lang.columnRemark"
          style="min-width: 20rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
        />
        <PvColumn
          field="period"
          :header="lang.columnPeriod"
          style="min-width: 5rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
        />
        <PvColumn
          field="price"
          :header="lang.columnPrice"
          style="min-width: 5rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
        />
        <PvColumn
          field="quantity"
          :header="lang.columnQuantity"
          style="min-width: 5rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
        />
        <PvColumn
          style="min-width: 8rem"
          headerClass="h-center"
          bodyStyle="text-align: center; justify-content: center; align-items: center;"
          alignFrozen="right"
          frozen
        >
          <template #body="slotProps">
            <PvButton
              type="button"
              class="p-button-secondary p-button-text p-0 mr-3"
              @click="editDetail(slotProps.data)"
            >
              <icon-pen-edit iconColor="secondary" width="20" height="20" />
            </PvButton>
            <PvButton
              type="button"
              class="p-button-secondary p-button-text p-0 mr-3"
              @click="selectItemToCopy(slotProps.data)"
            >
              <icon-file-blank-copy-add-plus iconColor="secondary" width="20" height="20" />
            </PvButton>
            <PvButton
              type="button"
              class="p-button-secondary p-button-text p-0 mr-3"
              @click="deleteDetail(slotProps.data)"
            >
              <icon-trash-delete-bin iconColor="secondary" width="20" height="20" />
            </PvButton>
          </template>
        </PvColumn>
      </PvDataTable>
      <div class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 flex flex-wrap">
          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="totalAmount">{{ lang.totalAmount }}</label>
            <PvInputNumber
              id="totalAmount"
              class="inputfield w-full mt-1"
              inputClass="input-number-disable text-right"
              v-model="totalAmount"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              disabled
            />
          </div>

          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="discountText">{{ lang.discountText }}</label>
            <base-discount-input-text
              class="inputfield w-full mt-1 p text-right"
              @emit-discount-amount="setDiscountText"
              @focus="$event.target.select()"
              :defaultDiscount="discount"
              :disabled="isDocumentLocked"
            />
          </div>
          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="discountAmount">{{ lang.discountAmount }}</label>
            <PvInputNumber
              id="discountTotalAmount"
              class="inputfield w-full mt-1"
              inputClass="input-number-disable text-right"
              v-model="discountTotalAmount"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              disabled
              :class="{ 'p-invalid': discountAmountError }"
            />
            <small v-if="discountAmountError" id="supplier-help" class="p-error">
              {{ lang.validateDiscountAmount }}
            </small>
          </div>

          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="beforeVat">{{ lang.beforeVat }}</label>
            <PvInputNumber
              id="beforeVat"
              class="inputfield w-full mt-1"
              inputClass="input-number-disable text-right"
              v-model="beforeVat"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              disabled
            />
          </div>

          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="vatRate">{{ lang.vatRate }}</label>
            <base-tax-auto-complete
              id="vatRate"
              v-model="selectedVatRate"
              class="inputfield w-full mt-1"
              @load-finish="taxDataReady"
              @item-select="selectVatRate()"
            />
          </div>

          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="vatAmount">{{ lang.vatAmount }}</label>
            <label class="font-xs p-text-disabled">
              {{ lang.vatCalculatedFromSystem }} {{ formatCurrency(vatAmountFromSystem) }}
            </label>
            <div class="flex justify-content-between align-items-center">
              <div class="w-full pr-2">
                <PvInputNumber
                  id="vatAmount"
                  class="inputfield w-full mt-1"
                  v-model="vatAmount"
                  mode="decimal"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  placeholder="0.00"
                  @dblclick="editVatAmount($event)"
                  @blur="calculateNetAmount($event)"
                  :disabled="isTaxAmountDisabled"
                  :inputClass="classDisableTaxAmount"
                />
              </div>
              <PvButton
                type="button"
                class="p-button-secondary p-button-text mt-1 p-1"
                @click="editVatAmount()"
                v-if="vatAmount != 0"
              >
                <icon-pen-edit-text iconColor="secondary" width="24" height="24" />
              </PvButton>
            </div>
          </div>
          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="netAmount">{{ lang.netAmount }}</label>
            <PvInputNumber
              id="netAmount"
              class="inputfield w-full mt-1"
              inputClass="input-number-disable text-right"
              v-model="netAmount"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              disabled
            />
          </div>

          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="taxAmount">{{ lang.taxAmount }}</label>
            <PvInputNumber
              id="taxAmount"
              class="inputfield w-full mt-1"
              inputClass="input-number-disable text-right"
              v-model="taxAmount"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              disabled
            />
          </div>

          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <label for="paymentAmount">{{ lang.paymentAmount }}</label>
            <PvInputNumber
              id="paymentAmount"
              class="inputfield w-full mt-1"
              inputClass="input-number-disable text-right"
              v-model="paymentAmount"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  </base-container>
</template>
<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed, ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import useGetRoleByBook from "../../hooks/getRoleByBook.js";
import usePageDirty from "../../hooks/pageDirty.js";
import BaseDialog from "../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import useGetBudgetYear from "../../hooks/getBudgetYear.js";
import useCalculation from "../../hooks/calculation.js";
import PaymentRequestProductDialog from "../../components/PaymentRequest/PaymentRequestProductDialog.vue";
import PaymentRequestTypeDropdown from "../../components/PaymentRequest/PaymentRequestTypeDropdown.vue";
import SettingDialog from "../../components/PaymentRequest/SettingDialog.vue";

export default {
  components: { PaymentRequestProductDialog, PaymentRequestTypeDropdown, SettingDialog },
  props: ["id", "projectCode"],
  setup(props) {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatDateToISOString, formatDate, formatDateTime, formatCurrency } = useFormat();
    const { ruleOfThree, round } = useCalculation();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ap.paymentRequest.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ap.paymentRequest.edit,
        };
      }
    });
    const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();
    const { compareObject, isCompareObject } = usePageDirty();
    const router = useRouter();
    const { budgetList } = useGetBudgetYear();

    let dataConfigDialog = ref(null);
    let titleConfirm = ref(mergeLang.value.createItem);
    let classTitle = ref("");
    let dataButtonsCopy = ref([
      {
        id: "close",
        caption: mergeLang.value.close,
        class: "p-button-outlined p-button-secondary w-full",
        action: "close",
      },
      {
        id: "gotoCopyItem",
        caption: mergeLang.value.buttonDialogConfirm,
        class: "w-full",
        action: "goto-copy-item",
      },
    ]);
    let dataButtons = ref([
      {
        id: "close",
        caption: mergeLang.value.close,
        class: "p-button-outlined p-button-secondary w-full",
        action: "cancel",
      },
      {
        id: "confirm",
        caption: mergeLang.value.buttonDialogConfirm,
        class: "w-full",
        action: "confirm",
      },
    ]);

    let code = ref(null);
    let paymentRequestTypes = ref([]);
    let documentDate = ref(new Date());
    let selectedSupplier = ref(null);
    let supplierError = ref(false);
    let isSharedSupplierReady = ref(false);
    let remark = ref(null);
    let selectedBudgetYear = ref(null);
    let isSharedBudgetsReady = ref(false);

    let details = ref([]);
    let detailsError = ref(false);
    let selectedDetail = ref(null);

    let keyToReLoad = ref(0);
    let activeStatus = ref(false);
    let isSharedTaxReady = ref(false);
    let totalAmount = ref(null);
    let discount = ref(null);
    let discountAmountError = ref(false);
    let discountTotalAmount = ref(null);
    let beforeVat = ref(null);
    let selectedVatRate = ref(null);
    let vatAmountFromSystem = ref(null);
    let vatAmount = ref(null);
    let isTaxAmountDisabled = ref(true);
    let netAmount = ref(null);
    let taxAmount = ref(null);
    let paymentAmount = ref(null);
    let paymentRequestTypeList = ref([]);
    let selectedPaymentRequestType = ref(null);

    let showProductDialog = ref(false);
    let selectedProductDetail = ref(null);

    let showSettingDialog = ref(false);

    let budgetHeaderId = ref(null);
    let dateAt = ref(null);
    let showCopyItemDialog = ref(false);
    let copyQuantity = ref(1);
    let selectedItemCopy = ref(null);
    let isPaymentRequestTypeDataReady = ref(false);
    let objToCompare = ref(null);
    let isPageDirty = ref(false);
    let isUseExpenseVoucher = ref("Y");
    let isUsePaymentVoucher = ref("Y");

    let dataButtonToolbar = ref([
      {
        id: "cancelCaption",
        action: "gotoCancel",
        type: "cancel",
      },
      {
        id: "createCaption",
        type: "create",
        action: "gotoCreate",
      },
      {
        id: "printCaption",
        type: "print",
        action: "gotoPrint",
      },
      {
        id: "saveCaption",
        type: "save",
        action: "gotoSave",
      },
    ]);

    onMounted(async () => {
      setLoading(true);
      initiateData();
      if (!isCreateDocument.value) await checkShouldSetData();
    });

    const setLoading = (bool) => {
      store.dispatch("setLoading", { value: bool });
    };
    const goBack = () => {
      compareObject(objToCompare.value, payload.value);
      router.push({ name: "payment-request" });
    };
    const initiateData = () => {
      keyToReLoad.value = 0;
      code.value = null;
      paymentRequestTypes.value = null;
      documentDate.value = new Date();
      selectedSupplier.value = null;
      remark.value = null;
      selectedSupplier.value = null;
      supplierError.value = false;

      activeStatus.value = false;
      isSharedTaxReady.value = false;
      totalAmount.value = null;
      discount.value = 0.0;
      discountAmountError.value = false;
      discountTotalAmount.value = 0.0;
      beforeVat.value = 0.0;
      selectedVatRate.value = null;
      vatAmountFromSystem.value = 0.0;
      vatAmount.value = 0.0;
      isTaxAmountDisabled.value = false;
      netAmount.value = 0.0;
      taxAmount.value = 0.0;
      paymentAmount.value = 0.0;
      details.value = [];
      detailsError.value = false;
      showCopyItemDialog.value = false;
      copyQuantity.value = 1;
      selectedItemCopy.value = null;
    };
    const getDataBook = async () => {
      try {
        await store.dispatch("shared/getAllAPBooks", "PQ");
        selectedVatRate.value = sharedTaxes.value.find((tax) => tax.taxRate === selectedApBook.value.taxRate);
      } catch (error) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: error,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        setLoading(false);
      }
    };
    const getWithholdingTax = () => {
      console.log("getWithholdingTax");
    };
    const getPayment = () => {
      console.log("getPayment");
    };
    const gotoSettingGL = () => {
      keyToReLoad.value++;
      showSettingDialog.value = true;
    };
    const autoGL = () => {
      console.log("autoGL");
    };
    const updateLockStatus = async () => {
      if (!isCreateDocument.value) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.validateRoleInsert,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
      }
      compareObject(objToCompare.value, payload.value);
      if (roleByBook.value.insert != "Y") {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.validateRoleInsert,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
      }
      try {
        setLoading(true);
        await store.dispatch("paymentRequest/updateLockPaymentRequest", paymentRequest.value.id);
        setDialog({
          classTitle: "success",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.saveSuccess,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        setCompareData();
        setData();
        setLoading(false);
      } catch (error) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: error,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        setLoading(false);
      }
    };
    const save = async () => {
      if (roleByBook.value.insert != "Y" && isCreateDocument.value) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.validateRoleInsert,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
      } else if (roleByBook.value.update != "Y" && !isCreateDocument.value) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.validateRoleUpdate,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
      }
      if (!validateSave()) {
        return;
      }
      try {
        setLoading(true);
        await store.dispatch("paymentRequest/createOrUpdatePaymentRequest", payload.value);

        if (isCreateDocument.value) {
          setData();
          router.replace({ name: "payment-request-edit", params: { id: paymentRequest.value.id } });
        }
        setDialog({
          classTitle: "success",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.saveSuccess,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        setCompareData();
        setLoading(false);
      } catch (error) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: error,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        setLoading(false);
      }
    };
    const validateSave = () => {
      let isValidate = true;
      if (!selectedSupplier.value) {
        isValidate = false;
        supplierError.value = true;
      }
      if (details.value.length == 0) {
        isValidate = false;
        detailsError.value = true;
      }

      return isValidate;
    };
    const create = async () => {
      if (isCreateDocument.value) {
        router.push({
          name: "payment-request-edit",
          params: {
            id: "create",
          },
        });
      } else {
        setCompareObject();

        if (!isPageDirty.value) {
          setDialog({
            classTitle: "",
            title: mergeLang.value.notificationDialogHeader,
            message: mergeLang.value.askForCreateNewDocument,
            button: dataButtons.value,
          });
        } else {
          setDialog({
            classTitle: "",
            title: mergeLang.value.notificationDialogHeader,
            message: mergeLang.value.pageDirtyConfirmMessage,
            button: dataButtons.value,
          });
        }
        let confirm = await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        if (!confirm.isCanceled) {
          router.push({
            name: "payment-request-edit",
            params: {
              id: "create",
            },
          });
        }
      }
      initiateData();
    };
    const askForCancel = async () => {
      if (!isCreateDocument.value) {
        if (roleByBook.value.cancel != "Y" && isCreateDocument.value) {
          setDialog({
            classTitle: "error",
            title: mergeLang.value.notificationDialogHeader,
            message: mergeLang.value.validateRoleCancel,
            button: dataButtons.value,
          });
          createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        } else {
          setCompareObject();

          if (!isPageDirty.value) {
            setDialog({
              classTitle: "",
              title: mergeLang.value.notificationDialogHeader,
              message: mergeLang.value.askForCreateNewDocument,
              button: dataButtons.value,
            });
          } else {
            setDialog({
              classTitle: "",
              title: mergeLang.value.notificationDialogHeader,
              message: mergeLang.value.pageDirtyConfirmMessage,
              button: dataButtons.value,
            });
          }
          let confirm = await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
          if (!confirm.isCanceled) {
            cancel();
          }
        }
      }
    };
    const cancel = async () => {
      try {
        setLoading(true);
        await store.dispatch("paymentRequest/cancelPaymentRequest", props.id);
        setDialog({
          classTitle: "success",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.cancelSuccess,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        setLoading(false);
      } catch (error) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: error,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        setLoading(false);
      }
    };
    const printDocument = () => {
      console.log("printDocument");
    };
    const changeSelectedSupplier = () => {
      supplierError.value = false;
    };
    const supplierDataReady = () => {
      isSharedSupplierReady.value = true;
    };
    const budgetsDataReady = () => {
      isSharedBudgetsReady.value = true;
    };
    const taxDataReady = () => {
      isSharedTaxReady.value = true;
    };
    const updateRemark = (data) => {
      remark.value = data;
    };
    const addDetail = () => {
      if (!selectedSupplier.value) {
        supplierError.value = true;
        return;
      }
      selectedProductDetail.value = null;
      keyToReLoad.value++;
      showProductDialog.value = true;
    };
    const editDetail = (data) => {
      selectedProductDetail.value = data;
      keyToReLoad.value++;
      showProductDialog.value = true;
    };
    const selectItemToCopy = (data) => {
      selectedItemCopy.value = data;
      showCopyItemDialog.value = true;
    };
    const copyItemSelected = () => {
      if (selectedItemCopy.value) {
        let period = periodToDate(selectedItemCopy.value.period);
        for (let index = 1; index <= copyQuantity.value; index++) {
          const copiedData = { ...selectedItemCopy.value };
          let periodNext = new Date(period.getFullYear(), period.getMonth() + 1 + index, 0);
          copiedData.itemNo = details.value.length + 1;
          copiedData.id = "dummy" + copiedData.itemNo;
          copiedData.period =
            (periodNext.getMonth() + 1).toString().padStart(2, "0") + "/" + periodNext.getFullYear().toString();
          details.value.push(copiedData);
        }
        calculateTotalAmount();
        calculateVatFromSystem();
        calculateNetAmount();
      }

      showCopyItemDialog.value = false;
      copyQuantity.value = 1;
    };
    const periodToDate = (data) => {
      let month = data.substring(0, 2);
      let year = data.slice(-4);
      const period = new Date(year + "-" + month + "-01");
      return period;
    };
    const deleteDetail = (data) => {
      const index = details.value.findIndex((item) => item.id === data.id);
      if (index !== -1) {
        details.value.splice(index, 1);
      }
      calculateTotalAmount();
      calculateVatFromSystem();
      calculateNetAmount();
    };
    const closeProductDialog = () => {
      showProductDialog.value = false;
    };
    const confirmProductDialog = (data) => {
      showProductDialog.value = false;
      if (!data.id) {
        data.itemNo = details.value.length + 1;
        data.id = "dummy" + data.itemNo;
        details.value.push(data);
      } else {
        const index = details.value.findIndex((item) => item.id === data.id);
        if (index !== -1) {
          details.value[index] = data;
        }
      }
      calculateTotalAmount();
      calculateVatFromSystem();
      calculateNetAmount();
    };

    const closeSettingDialog = () => {
      showSettingDialog.value = false;
    };
    const confirmSettingDialog = (data, isUseExpense, isUsePayment) => {
      isUseExpenseVoucher.value = isUseExpense;
      isUsePaymentVoucher.value = isUsePayment;

      const dataIds = new Set(data.map((e) => e.id));

      details.value.forEach((detail) => {
        detail.isUseExpenseVoucher = dataIds.has(detail.id) || detail.referenceId ? "Y" : "N";
      });

      showSettingDialog.value = false;
    };
    const classDisableTaxAmount = () => {
      let setClass = "text-right ";
      if (isTaxAmountDisabled.value) {
        setClass = "text-right input-number-disable";
      }
      return setClass;
    };
    const editVatAmount = () => {
      isTaxAmountDisabled.value = false;
      calculateVatFromSystem();
      calculateNetAmount();
    };
    const checkShouldSetData = async () => {
      if (
        isSharedRoleByBookReady.value &&
        isSharedSupplierReady.value &&
        isSharedBudgetsReady.value &&
        isSharedTaxReady.value &&
        isPaymentRequestTypeDataReady.value
      ) {
        setLoading(true);
        await getDataBook();
        await getPaymentRequestType();
        if (!isCreateDocument.value) {
          await getPaymentRequest();
          setData();
        }
        const maxObject = budgetList.value.reduce(
          (max, obj) => (obj.value > max.value ? obj : max),
          budgetList.value[0]
        );
        selectedBudgetYear.value = budgetList.value.find((budget) => budget.id === maxObject.id);
        budgetHeaderId.value = maxObject.id;
        dateAt.value = new Date();
        setCompareData();
        setLoading(false);
      }
    };
    const getPaymentRequest = async () => {
      try {
        await store.dispatch("paymentRequest/getPaymentRequestById", props.id);
      } catch (error) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: error,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        setLoading(false);
      }
    };
    const setData = () => {
      if (paymentRequest.value) {
        const data = paymentRequest.value;
        // dateAt.value = new Date(data.updateDate);

        code.value = data.code;
        documentDate.value = new Date(data.date);
        remark.value = data.remark;

        details.value = data.details;
        activeStatus.value = data.isLock == "Y" ? true : false;
        // selectedDetail.value = data.selectedDetail;

        totalAmount.value = data.totalAmount;
        discount.value = data.discountDescription;
        discountTotalAmount.value = data.discountAmount;
        vatAmount.value = data.vatAmount;
        isTaxAmountDisabled.value = data.isTaxAmountDisabled;
        netAmount.value = data.netAmount;
        taxAmount.value = data.taxAmount;
        paymentAmount.value = data.paymentAmount;
        selectedPaymentRequestType.value = data.paymentRequestTypeId;

        selectedSupplier.value = sharedSuppliers.value.find((supplier) => supplier.id === data.supplierId);
        selectedVatRate.value = sharedVatRate.value.find((vat) => vat.taxRate === data.vatRate);
        if (data.budgetHeaderId) {
          selectedBudgetYear.value = budgetList.value.find((budget) => budget.id === data.budgetHeaderId);
        }
        beforeVat.value = data.totalAmount;
        vatAmountFromSystem.value = (data.vatAmount / 100) * data.totalAmount;
        vatAmount.value = data.vatAmount;
      }
    };
    const setDialog = (dataConfig) => {
      const classMap = {
        success: "p-text-success",
        error: "p-error",
      };

      dataConfig.classTitle = classMap[dataConfig.classTitle] || "";

      if (!dataConfig.button) {
        dataConfig.button = [
          {
            id: "close",
            caption: mergeLang.value.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "cancel",
          },
        ];
      }

      dataConfigDialog.value = {
        titleConfirm: dataConfig.title,
        classTitle: dataConfig.classTitle,
        wordingConfirm: dataConfig.message,
        dataButtons: dataConfig.button,
        display: true,
      };
    };
    const getPaymentRequestType = async () => {
      try {
        if (paymentRequestType.value.length == 0) {
          let payload = {
            projectId: selectedProject.value.id,
          };
          await store.dispatch("paymentRequestType/getPaymentRequestType", payload);
        }

        let data = paymentRequestType.value;
        if (data) {
          data.forEach((e) => {
            paymentRequestTypeList.value.push({ code: e.code, name: e.name, id: e.id });
          });
        }
      } catch (error) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: error,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        setLoading(false);
      }
    };
    const changePaymentRequestType = () => {};
    const selectVatRate = () => {
      calculateVatFromSystem();
      calculateNetAmount();
    };
    const setDiscountText = (data) => {
      discount.value = data;
      editDiscount();
    };
    const editDiscount = () => {
      if (discount.value) {
        if (discount.value.includes("%")) {
          discountTotalAmount.value = (parseFloat(discount.value) / 100) * totalAmount.value;
        } else {
          discountTotalAmount.value = parseFloat(discount.value);
        }
      } else {
        discountTotalAmount.value = 0;
      }
      calculateVatFromSystem();
      calculateNetAmount();
    };
    const calculateTotalAmount = () => {
      totalAmount.value = details.value.reduce((total, currentValue) => {
        return total + currentValue.totalAmount;
      }, 0);
    };
    const calculateVatFromSystem = () => {
      beforeVat.value = totalAmount.value - discountTotalAmount.value;
      vatAmountFromSystem.value = round(
        ((selectedVatRate.value ? selectedVatRate.value.taxRate : selectedApBook.value.taxRate) / 100) * beforeVat.value
      );
      vatAmount.value = vatAmountFromSystem.value;
    };
    const calculateNetAmount = () => {
      netAmount.value = totalAmount.value + vatAmount.value - discountTotalAmount.value;
      if (discount.value) {
        let sumDiscount = 0;
        let sumVat = 0;
        details.value.forEach((e, index) => {
          if (details.value.length - 1 == index && details.value.length > 1) {
            e.discountAverage = discountTotalAmount.value - sumDiscount;
            e.vatAverage = vatAmount.value - sumVat;
          } else {
            const avgDiscount = ruleOfThree(
              parseFloat(discountTotalAmount.value),
              parseFloat(e.totalAmount),
              parseFloat(totalAmount.value)
            );
            e.discountAverage = avgDiscount;

            const avgVat = ruleOfThree(
              parseFloat(vatAmount.value),
              parseFloat(e.totalAmount - avgDiscount),
              parseFloat(totalAmount.value)
            );
            e.vatAverage = avgVat;

            e.totalAmount = e.price * e.quantity - e.discountAmount;
            sumDiscount += e.discountAverage;
            sumVat += e.vatAverage;
          }
        });
      }
    };
    const closeBaseDialog = () => {
      showCopyItemDialog.value = true;
    };
    const paymentRequestTypeDataReady = () => {
      isPaymentRequestTypeDataReady.value = true;
    };
    const setCompareData = () => {
      let localDetail = [];

      for (const each of details.value.filter((each) => each.productId)) {
        let detail = null;
        if (each.id.toString().includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        localDetail.push(detail);
      }

      let dataPayload = {
        projectId: selectedProject.value.id,
        documentBookId: selectedApBook.value.bookId,
        paymentRequestTypeId: selectedPaymentRequestType.value,
        budgetHeaderId: selectedBudgetYear.value ? selectedBudgetYear.value.id : null,
        paymentVoucherDate: formatDateToISOString(documentDate.value),
        date: formatDateToISOString(documentDate.value),
        supplierId: selectedSupplier.value ? selectedSupplier.value.id : null,
        remark: remark.value,
        totalAmount: totalAmount.value,
        discountDescription: discount.value ? discount.value.toString() : null,
        discountAmount: discountTotalAmount.value,
        vatRate: selectedVatRate.value ? selectedVatRate.value.taxRate : selectedApBook.value.taxRate,
        vatAmount: vatAmount.value,
        netAmount: netAmount.value,
        details: localDetail,
        isUseExpenseVoucher: isUseExpenseVoucher.value,
        isUsePaymentVoucher: isUsePaymentVoucher.value,
        type: "Add",
      };

      if (!isCreateDocument.value) {
        dataPayload = { ...dataPayload, id: props.id, type: "Update" };
      }

      objToCompare.value = dataPayload;
      setCompareObject();
    };
    const setCompareObject = () => {
      let result = false;
      if (isCreateDocument.value) {
        result = isCompareObject(objToCompare.value, payload.value);
      } else {
        let data = {
          ...payload.value,
          id: props.id,
        };
        result = isCompareObject(objToCompare.value, data);
      }
      isPageDirty.value = result;
    };

    watch(isSharedRoleByBookReady, async () => {
      checkShouldSetData();
    });
    watch(isSharedSupplierReady, async () => {
      checkShouldSetData();
    });
    watch(isSharedBudgetsReady, async () => {
      checkShouldSetData();
    });
    watch(isSharedTaxReady, async () => {
      checkShouldSetData();
    });
    watch(isPaymentRequestTypeDataReady, async () => {
      checkShouldSetData();
    });

    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const isCreateDocument = computed(() => {
      return props.id == "create";
    });
    const isDocumentLocked = computed(() => {
      return false;
    });
    const roleByBook = computed(() => {
      return getRoleByBookByCode("PQ");
    });
    const paymentRequest = computed(() => {
      return store.getters["paymentRequest/selectedPaymentRequest"];
    });
    const paymentRequestType = computed(() => {
      return store.getters["paymentRequestType/paymentRequestType"];
    });
    const sharedSuppliers = computed(() => {
      return store.getters["shared/supplierSuppliers"];
    });
    const sharedVatRate = computed(() => {
      return store.getters["shared/taxTaxes"];
    });
    const sharedApBooks = computed(() => {
      return store.getters["shared/apBooks"];
    });
    const sharedTaxes = computed(() => {
      return store.getters["shared/taxTaxes"];
    });
    const selectedApBook = computed(() => {
      return sharedApBooks.value.length == 1 ? sharedApBooks.value[0] : sharedApBooks.value;
    });
    const payload = computed(() => {
      let localDetail = [];

      for (const each of details.value.filter((each) => each.productId)) {
        let detail = null;
        if (each.id.toString().includes("dummy")) {
          detail = { ...each, id: 0 };
        } else {
          detail = { ...each };
        }
        localDetail.push(detail);
      }
      let payload = {
        projectId: selectedProject.value.id,
        documentBookId: selectedApBook.value.bookId,
        paymentRequestTypeId: selectedPaymentRequestType.value,
        budgetHeaderId: selectedBudgetYear.value ? selectedBudgetYear.value.id : null,
        paymentVoucherDate: formatDateToISOString(documentDate.value),
        date: formatDateToISOString(documentDate.value),
        supplierId: selectedSupplier.value ? selectedSupplier.value.id : null,
        remark: remark.value,
        totalAmount: totalAmount.value,
        discountDescription: discount.value ? discount.value.toString() : null,
        discountAmount: discountTotalAmount.value,
        vatRate: selectedVatRate.value ? selectedVatRate.value.taxRate : selectedApBook.value.taxRate,
        vatAmount: vatAmount.value,
        netAmount: netAmount.value,
        details: localDetail,
        isUseExpenseVoucher: isUseExpenseVoucher.value,
        isUsePaymentVoucher: isUsePaymentVoucher.value,
        type: "Add",
      };

      if (!isCreateDocument.value) {
        payload = { ...payload, id: props.id, type: "Update" };
      }

      return payload;
    });

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatDateToISOString,
      formatDate,
      formatDateTime,
      formatCurrency,
      getRoleByBookByCode,
      isSharedRoleByBookReady,
      compareObject,
      isCompareObject,
      //method
      goBack,
      getWithholdingTax,
      getPayment,
      gotoSettingGL,
      autoGL,
      updateLockStatus,
      create,
      askForCancel,
      save,
      printDocument,
      changeSelectedSupplier,
      supplierDataReady,
      updateRemark,
      addDetail,
      editDetail,
      selectItemToCopy,
      copyItemSelected,
      deleteDetail,
      taxDataReady,
      setDiscountText,
      classDisableTaxAmount,
      editVatAmount,
      changePaymentRequestType,
      selectVatRate,
      budgetsDataReady,
      closeProductDialog,
      confirmProductDialog,
      closeBaseDialog,
      paymentRequestTypeDataReady,
      closeSettingDialog,
      confirmSettingDialog,
      //variable
      keyToReLoad,
      code,
      paymentRequestTypes,
      documentDate,
      activeStatus,
      dataButtonToolbar,
      selectedSupplier,
      supplierError,
      remark,
      details,
      detailsError,
      selectedDetail,
      totalAmount,
      discount,
      discountAmountError,
      discountTotalAmount,
      beforeVat,
      selectedVatRate,
      vatAmountFromSystem,
      vatAmount,
      isTaxAmountDisabled,
      netAmount,
      taxAmount,
      paymentAmount,
      paymentRequestTypeList,
      selectedPaymentRequestType,
      selectedBudgetYear,
      isSharedBudgetsReady,
      showProductDialog,
      selectedProductDetail,
      budgetHeaderId,
      dateAt,
      showCopyItemDialog,
      copyQuantity,
      titleConfirm,
      classTitle,
      dataButtons,
      dataButtonsCopy,
      showSettingDialog,
      //computed
      roleByBook,
      isDocumentLocked,
      paymentRequest,
    };
  },
};
</script>
