export default {
  async getNoticePrintSetting(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Notice/PrintSetting";

    if (payload.projectId) {
      url = url + "?projectId=" + payload.projectId;
    }

		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get notice print setting"
      );
      throw error;
    }

    // console.log("getNoticePrintSetting",responseData);

    context.commit("setNoticePrintSetting", responseData);
  },
  async addNoticePrintSetting(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Notice/PrintSetting/Add";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || "Failed to add notice print setting");
      throw error;
    }
    // console.log(responseData);

    context.commit("setSelectedNoticePrintSetting", responseData);
  },
  async updateNoticePrintSetting(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Notice/PrintSetting/Update";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || "Failed to update notice print setting");
      throw error;
    }
    // console.log(responseData);

    context.commit("setSelectedNoticePrintSetting", responseData);
  },
};
