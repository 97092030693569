<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.12 20.1667H5.00544C4.09728 20.1667 3.36 19.4294 3.36 18.5213V14.4067"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.84 2.88672H18.9946C19.9027 2.88672 20.64 3.624 20.64 4.53216V7.68672"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.47552 3.88032C3.30528 4.03968 2.4 5.03232 2.4 6.24672C2.4 7.57152 3.4752 8.64672 4.8 8.64672H10.079C11.1398 8.64672 11.999 7.78752 11.999 6.72672C11.999 5.66592 11.1398 4.80672 10.079 4.80672C10.079 3.216 8.78976 1.92672 7.19904 1.92672C5.93472 1.92672 4.86144 2.74464 4.47552 3.88032Z"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.1117 14.1686V19.1059C13.1117 19.6915 13.5869 20.1667 14.1725 20.1667H19.5341C20.1197 20.1667 20.5949 19.6915 20.5949 19.1059V14.1782"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.0509 14.8618L16.9229 11.6784L21.6 14.8618"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
