<template>
  <PvDialog
    :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
    :style="{ width: '40vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="lang.notificationDialogHeader"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="closeDialog()"
      @close="showValidateDialog = false"
    ></base-dialog>

    <template #header>
      <div class="flex justify-content-between align-items-right">
        <div class="pb-1">
          <label class="font-lg">{{ title }}</label>
        </div>
        <div>
          <PvButton
            icon="pi pi-times"
            class="p-button-link p-icon-secondary pb-0 pt-0"
            style="width: 15px"
            @click="closeDialog()"
          />
        </div>
      </div>

      <PvDivider></PvDivider>
    </template>

    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="col-12 pl-0 pr-2">
          <label for="product">{{ lang.product }}</label>
          <PvSkeleton
            v-if="!isSharedProduct"
            class="inputfield w-full mt-1"
            height="39px"
          />
          <product-receipt-auto-complete
            id="product"
            v-model="selectedProduct"
            class="inputfield w-full mt-1"
            @load-finish="productDataReady"
            inputClass="p-inputtext-disabled"
            v-show="isSharedProduct"
            disabled
          />
        </div>

        <div class="col-12 pl-0 pr-2">
          <label for="remark">{{ lang.description }}</label>
          <PvInputText
            id="remark"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="remark"
            :class="{ 'p-invalid': remarkError }"
            @keypress="remarkError = false"
            disabled
          />
        </div>

        <div class="col-12 pl-0 pr-2 period flex-wrap">
          <label for="period">{{ lang.period }}</label>
          <PvInputText
            id="period"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="period"
            disabled
          />
        </div>

        <div class="col-12 pl-0 pr-2">
          <label for="unitPrice">{{ lang.unitPrice }}</label>
          <PvInputNumber
            id="unitPrice"
            class="inputfield w-full mt-1"
            v-model="unitPrice"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            inputClass="p-inputtext-disabled text-right"
            disabled
          />
        </div>

        <div class="col-12 pl-0 pr-2">
          <label for="quantity">{{ lang.quantity }}</label>
          <PvInputNumber
            id="quantity"
            class="inputfield w-full mt-1"
            v-model="quantity"
            inputClass="p-inputtext-disabled text-right"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            disabled
          />
        </div>

        <div class="col-12 pl-0 pr-2">
          <label for="totalAmount">{{ lang.totalAmount }}</label>
          <PvInputNumber
            id="totalAmount"
            class="inputfield w-full mt-1"
            v-model="totalAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            @focus="$event.target.select()"
            inputClass="p-inputtext-disabled text-right"
            disabled
          />
        </div>

        <div class="col-12 pl-0 pr-2">
          <label for="balanceAmount">{{ lang.columnBalance }}</label>
          <PvInputNumber
            id="balanceAmount"
            class="inputfield w-full mt-1"
            inputClass="p-inputtext-disabled text-right"
            v-model="balanceAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            disabled
          />
        </div>

        <div class="col-12 pl-0 pr-2">
          <label for="creditNoteAmount">{{ lang.reduceDebt }}</label>
          <PvInputNumber
            id="creditNoteAmount"
            class="inputfield w-full mt-1"
            inputClass="text-right"
            v-model="creditNoteAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            :min="0"
            @input="creditNoteAmount = $event.value"
          />
        </div>
      </section>
    </transition>

    <template #footer>
      <div class="flex justify-content-between align-items-center">
        <PvButton
          :label="lang.close"
          class="p-button-outlined p-button-secondary w-full ml-3"
          @click="closeDialog()"
        />
        <PvButton
          :label="lang.confirm"
          class="w-full mr-3"
          @click="confirm()"
          :disabled="isLockConfirm"
        />
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import ProductReceiptAutoComplete from "../receipt/ProductReceiptAutoComplete.vue";
export default {
  props: {
    data: {
      type: Object,
    },
    isOpen: {
      type: Boolean,
    },
    // headerId: {
    //   type: String,
    // },
  },
  emits: ["close", "confirm-edit"],
  components: {
    ProductReceiptAutoComplete,
  },
  setup() {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const { formatCurrency, formatPeriodToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.creditNote.listing,
          ...lang.th.ar.creditNote.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.creditNote.listing,
          ...lang.en.ar.creditNote.edit,
        };
      }
    });

    return {
      lang: mergeLang,

      textFormClass,

      formatPeriodToISOString,
      formatCurrency,
    };
  },
  data() {
    return {
      selectedProduct: null,
      isSharedProduct: false,
      productError: false,
      remark: "",
      remarkError: false,
      period: [],
      periodError: false,
      unitPrice: 0.0,
      quantity: 1,
      totalAmount: 0,
      balanceAmount: 0,
      creditNoteAmount: 0,

      showValidateDialog: false,
      validateWording: "",
      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ],

      objToCompare: null,
    };
  },
  created() {
    this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    initiateData() {
      this.dataButtonDialog = [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];
      let date = new Date();
      this.period =
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        (date.getFullYear() + 543);
      this.selectedProduct = null;
      this.remark = "";
      this.unitPrice = 0.0;
      this.quantity = 1;
      this.totalAmount = 0;
      this.balanceAmount = 0;
      this.creditNoteAmount = 0;

      this.showValidateDialog = false;
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    confirm() {
      // console.log(this.payload);
      if (this.validateData()) {
        this.setLoading(false);
        return;
      }

      this.$emit("confirm-edit", this.payload);
      this.closeDialog();
    },
    async checkShouldSetData() {
      if (this.isSharedProduct && this.data) {
        this.setProductData();
        this.setData();
      } else if (!this.isSharedProduct && this.data) {
        this.setData();
      } else if (this.isSharedProduct && !this.data) {
        this.setLoading(false);
      }
    },
    setProductData() {
      this.selectedProduct = this.sharedProductArs.find(
        (product) => product.id === this.data.productId
      );
    },
    setData() {
      this.setLoading(true);
      // console.log("setDataDialog", this.data);

      this.remark = this.data.remark;
      this.period = this.data.period;
      this.unitPrice = this.data.unitPrice;
      this.quantity = this.data.quantity;
      this.totalAmount = this.data.totalAmount;
      this.balanceAmount = this.data.balance;
      this.creditNoteAmount = this.data.totalAmount;

      this.setLoading(false);
    },
    productDataReady() {
      this.isSharedProduct = true;
      this.checkShouldSetData();
    },
    validateData() {
      if (this.creditNoteAmount < 0) {
        this.validateWording = this.lang.validateCreditNoteAmount;
        this.showValidateDialog = true;
        return true;
      }

      return false;
    },
  },
  computed: {
    payload() {
      let payload = {
        id: this.data.id,
        totalAmount: this.creditNoteAmount,
      };

      // console.log("payload", payload);

      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    title() {
      let title = this.lang.titleDetailDialog;
      return title;
    },
    sharedProductArs() {
      return this.$store.getters["shared/productArProducts"];
    },
    isLockConfirm() {
      if (this.creditNoteAmount <= 0) {
        return true;
      } else if (this.creditNoteAmount > this.balanceAmount) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    isOpen(curValue) {
      if (curValue) {
        this.initiateData();
        this.checkShouldSetData();
      }
    },
  },
};
</script>

<style scoped>
.period {
  display: flex !important;
  flex-direction: column;
}
</style>
