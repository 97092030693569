export default {
    createPR: "Create Purchase Requisition",
    editPR: "Edit Purchase Requisition",
    saveAs: "Save As",
    approve: "Approve",
    reApprove: "Retreat Approve",
    requestDate: "Request Date",
    quotationDate: "Quotation Date",
    creditTerm: "Credit Term (Day)",
    supplier:"Supplier",
    buyer: "Buyer",
    remark: "Remark",
    deliveryDate: "Delivery Date",
    payment: "Payment",
    guarantee: "Guarantee",
    columnIsProduct: "type",
    columnProductCode: "Product Code",
    columnProductName: "Product Name",
    columnProductUnitName: "Unit Name",
    columnUnitPrice: "Unit Price",
    columnQuantity:"Quantity",
    columnTotalAmount: "Total Amount",
    currency: "Currency",
    currencyName: "THB",
    discount:"Discount",
    actualPayAmount: "Actual Pay Amount",
    totalAmount: "Total Amount",
    discountAmount: "Discount Amount",
    totalAmountBeforeTax: "Total Amount Before Tax",
    tax: "Tax Rate",
    taxAmount: "Tax Amount",
    netAmount: "Net Amount",
    askForProductChange: "Are you sure you want to update product code?",
	askForSelectProduct: "Can't find product code :code. Do you want to search?",
    validateNoSupplierSelect: "Please select supplier",
	validateNoEmployeeSelect: "Please select employee",
    validateRemark: "Please specify remark",
    validateDetailSelect: "Please select Item.",
    validateNoTaxSelect: "Please select Tax.",
    askForClosePR: "Are you sure you want to close Purchase requisition?",
    closePRSuccess: "Close Purchase requisition successfully",
    askForRevertApprove: "Are you sure you want to retreat approve Document?",
    reApproveSuccess: "Retreat approve successfully",
    validateTotalAmount: "Total amount must be greater than 0.",
    validateCheckDiscount: "Discount format can be enter only numbers and %.",
    validateDiscountAmount: "Discount amount must not be more than total amount.",
    calculatedFromSystem: "*Calculated From System : ",
};
