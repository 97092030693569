export default {
	createTitle: "สร้างรายการใบนำฝาก",
	editTitle: "แก้ไขรายการใบนำฝาก",
	detailTitle: "รายละเอียดใบนำฝาก",

	netAmount: "จำนวนเงินที่ฝาก",
	documentCode: "เลขที่เอกสาร",
	createNew: "สร้างใหม่",
	searchBy: "ค้นหาโดย",
	payType: "ประเภทใบนำฝาก",
	status: "สถานะ",
	bankPayin: "รับเข้าบัญชี",
	cross: "เช็คขีดคร่อม",
	holder: "ขีดฆ่าหรือผู้ถือ",
	unitCode: "รหัสห้อง/บ้าน",
	dateAt: "วันที่",
	customerName: "ชื่อลูกค้า",
	customerSearch: "รหัสหรือชื่อลูกค้า",
	employeeName: "ชื่อพนักงาน",
	receiveMan: "ชื่อผู้รับ",
	remark: "หมายเหตุ",
	payDate: "วันที่ใบนำฝาก",
	dueDate: "วันที่ครบกำหนด",
	toBankDatePayin: "วันที่เข้าธนาคาร",
	cancelPayinSuccess: "คุณยกเลิกเอกสารใบนำฝาก {code} สำเร็จ",

	referenceReceipt: "เอกสารประกอบ",

	validateBookbank: "กรุณาเลือกบัญชีธนาคาร",
	validateNoCustomerSelect: "กรุณาเลือกลูกค้า",
	validateReceiptExtra: "ไม่มีรายการเพิ่มเติม",
	validatePayType: "กรุณาเลือกประเภทใบนำฝาก",
	validateReference: "ไม่สามารถยกเลิกเอกสารนี้ได้ เนื่องจากมีเอกสารอ้างถึง", 

	validateInvalidSetupBookbank: "สมุดบัญชีธนาคารผูกธนาคารไม่ถูกต้อง",
	validateTotalAmount: "จำนวนเงินที่ชำระ ต้องมากกว่าหรือเท่ากับ 0",
	usedAmount: "ใช้ตัดชำระไปแล้ว",
    balance: "คงเหลือ",
	feeAmount: "ค่าธรรมเนียม",
};
