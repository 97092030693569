<template>
  <base-toolbar
    :title="lang.title"
    :dataButtons="dataButtons"
    :hideBackButton="isHideBack"
    @goBack="goBack"
    @gotoSave="save"
  ></base-toolbar>
  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :classTitle="baseDialogClassTitle"
      :titleConfirm="titleConfirm"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
    ></base-dialog>

    <notice-product-dialog
        v-model:visible="showNoticeProductDialog"
        :isOpen="showNoticeProductDialog"
        :detailProducts="noticePrintSettingDetails"
        @close="showNoticeProductDialog = false"
        @product-selected="productSelected"
    ></notice-product-dialog>

    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{ lang.title }}</label>

      <PvDivider></PvDivider>

      <section class="pb-2" :class="textFormClass">
        <div class="flex flex-wrap justify-content-between align-items-center">
          <label class="font-lg font-normal">{{ lang.titleListDetail }}</label>
          <PvButton class="p-button-outlined" @click="addNoticeProduct">
            <icon-plus-add iconColor="primary" height="16" width="16" />
            <span class="font-normal pl-1">{{ lang.addProduct }}</span>
          </PvButton>
        </div>
      </section>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="noticePrintSettingDetails"
          v-model:selection="selectedNoticePrintSettingDetail"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          scrollable
        >
          <PvColumn
            field="itemNumber"
            :header="lang.itemNo"
            style="max-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="productCode"
            :header="lang.code"
            style="max-width: 15rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="productName"
            :header="lang.productName"
            style="min-width: 20rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            style="max-width: 6rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-outlined p-button-danger p-button-text p-0 mr-3"
                @click="deleteDetail(slotProps.data.id)"
              >
                <icon-trash-delete-bin
                  iconColor="error"
                  width="24"
                  height="24"
                />
              </PvButton>
            </template>
          </PvColumn>

          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import NoticeProductDialog from "../../../components/master/notice/NoticeProductDialog.vue";
import usePageDirty from "../../../hooks/pageDirty";
export default {
  components: {
    NoticeProductDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.noticePrintSetting,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.noticePrintSetting,
        };
      }
    });

    const { compareObject, isCompareObject } = usePageDirty();

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      compareObject,
      isCompareObject,
      isHideBack,
    };
  },
  data() {
    return {
      isLoading: false,
      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: "",
      validateWording: "",
      noticePrintSettingDetails: [],
      selectedNoticePrintSettingDetail: null,
      showNoticeProductDialog: false,
      objToCompare: null,

      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ],
    };
  },
  async mounted() {
    await this.initiateData();
    await this.getNoticePrintSetting();
    await this.setCompareData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.setCompareObject();
      if(this.isFromReportNotice) {
        this.$router.push({name: "report", params: { type: "Notice" }});
      } else {
        this.$router.push({name: "home"})
      }
    },
    async initiateData() {
      this.selectedNoticePrintSettingDetail = null;
      this.clearBaseDialog();
    },
    clearBaseDialog() {
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.validateWording = "";
    },
    async setCompareData() {
        let details = [];

        for (const each of this.noticePrintSettingDetails.filter((each) => each.id)) {
          let detail = null;
          if (each.id.toString().includes("dummy")) {
            detail = { ...each, id: null };
          } else {
            detail = { ...each };
          }
          details.push(detail);
        }

        let objToCompare = {
            projectId: this.selectedProject.id,
            subjectTh: "",
            subjectEn: "",
            toTh: "",
            toEn: "",
            contentHeaderTh: "",
            contentHeaderEn: "",
            contentFooterTh: "",
            contentFooterEn: "",
            signatureTh: "",
            signatureEn: "",
            pageFooterTh: "",
            pageFooterEn: "",
            details: details
        }

        if (!this.isCreateDocument) {
            objToCompare = {
                ...objToCompare,
                id: this.noticePrintSetting.id
            };
        }

        this.objToCompare = objToCompare;
        this.setCompareObject();
    },
    async setCompareObject() {
      if (this.isCreateDocument) {
        this.compareObject(this.objToCompare, this.payload);
      } else {
        let payload = {
          ...this.payload,
          id: this.noticePrintSetting.id
        }
        this.compareObject(this.objToCompare, payload);
      }
    },
    async getNoticePrintSetting() {
      this.isLoading = true;
      try {
        let payload = {
          projectId: this.selectedProject.id,
        };

        await this.$store.dispatch(
          "noticePrintSetting/getNoticePrintSetting",
          payload
        );

        if (this.noticePrintSetting) {
          this.noticePrintSettingDetails = this.noticePrintSetting.details;
        }

        // console.log("noticePrintSettingDetails", this.noticePrintSettingDetails);
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    addNoticeProduct() {
        this.showNoticeProductDialog = true;
    },
    updateItemNumber() {
        let number = 1;
        this.noticePrintSettingDetails.forEach((element) => {
            element.itemNumber = number;
            number++;
        });
    },
    productSelected(data) {
        // console.log("productSelected", data);
        data.forEach((product) => {
            if(product.id) {
                let detail = {
                    id: "dummy" + (this.countDetails + 1),
                    headerId: this.noticePrintSetting ? this.noticePrintSetting.id : null,
                    itemNumber: 0,
                    productId: product.id,
                    productCode: product.code.trim(),
                    productName: product.nameThai.trim(),
                }
                this.noticePrintSettingDetails.push(detail);
                this.noticePrintSettingDetails = this.noticePrintSettingDetails.sort((a, b) => (a.productCode < b.productCode ? -1 : 1));
                this.updateItemNumber();
            }
        })
    },
    deleteDetail(id) {
      const index = this.noticePrintSettingDetails.findIndex((detail) => detail.id === id);
      this.noticePrintSettingDetails.splice(index, 1);
      this.updateItemNumber();
    },
    validateData() {
        this.clearBaseDialog();
        if(this.noticePrintSettingDetails.length == 0) {
            this.baseDialogClassTitle = "p-error";
            this.titleConfirm = this.lang.validateNoDetailTitle
            this.validateWording = this.lang.validateNoDetailDetail;
            this.showValidateDialog = true;
            return true;
        }
        return false;
    },
    async save() {
        if (this.validateData()) {
            return;
        }
        this.setLoading(true);
        this.clearBaseDialog();
        try {
            if(this.isCreateDocument) {
                await this.$store.dispatch("noticePrintSetting/addNoticePrintSetting", this.payload);
            } else {
                let payload = {
                    ...this.payload,
                    id: this.noticePrintSetting.id,
                };
                await this.$store.dispatch("noticePrintSetting/updateNoticePrintSetting", payload);
            }

            this.baseDialogClassTitle = "p-text-success";
            this.titleConfirm = this.lang.titleSaved;
            this.validateWording = this.lang.titleSavedDetail;
            this.showValidateDialog = true;
        } catch (error) {
            this.baseDialogClassTitle = "";
            this.titleConfirm = this.lang.notificationDialogHeader;
            this.validateWording = error.message;
            this.showValidateDialog = true;
            this.setLoading(false);
        }
        this.setLoading(false);

        await this.setCompareData();
    },
  },
  computed: {
    payload() {
      let details = [];

      for (const each of this.noticePrintSettingDetails.filter((each) => each.id)) {
        let detail = null;
        if (each.id.toString().includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        details.push(detail);
      }

        let payload = {
            projectId: this.selectedProject.id,
            subjectTh: "",
            subjectEn: "",
            toTh: "",
            toEn: "",
            contentHeaderTh: "",
            contentHeaderEn: "",
            contentFooterTh: "",
            contentFooterEn: "",
            signatureTh: "",
            signatureEn: "",
            pageFooterTh: "",
            pageFooterEn: "",
            details: details
        }
        return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    noticePrintSetting() {
      return this.$store.getters["noticePrintSetting/noticePrintSetting"];
    },
    countDetails() {
      let length = this.noticePrintSettingDetails.length;
      return length;
    },
    isCreateDocument() {
      if(!this.noticePrintSetting) {
        return true;
      }
      return false;
    },
    isFromReportNotice() {
      if(this.$route.name == "report-notice-print-setting") {
        return true;
      }
      return false;
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "save",
          action: "gotoSave",
          type: "save",
        },
      ];
      return dataButtons;
    },
  },
};
</script>
