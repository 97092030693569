export default {
    title: "รายละเอียดใบรับฝากเงิน",
    createDeposit: "สร้างใบรับฝากเงิน",
    editDeposit: "แก้ไขใบรับฝากเงิน",
    cancelDocument: "ยกเลิกเอกสาร",
    depositCode: "เลขที่เอกสาร",
    depositDate: "วันที่เอกสาร",
    unitCode: "รหัสห้อง/บ้าน",
    depositAmount: "จำนวนเงิน",
    depositType: "ประเภทการชำระ",
    cash: "เงินสด",
    cheque: "เช็ค",
    transfer: "เงินโอน",
    bookBankCode: "บัญชีเงินเข้าของนิติฯ",
    chequeCode: "เลขที่เช็ค",
    transferDate: "วันที่โอน",
    chequeDate: "วันที่เช็ค",
    payDate: "วันที่รับฝาก",
    bank: "ธนาคาร/สาขา",
    transferCode: "เลขที่ใบโอน",
    payCode: "เลขที่ใบรับฝาก",
    askForCreateNewDeposit: "คุณต้องการละทิ้งการแก้ไขใช่หรือไม่?",
    createNewDeposit: "สร้างใบรับฝากเงินใหม่",
    validateDepositAmount: "จำนวนเงินต้องมีมูลค่ามากกว่า 0",
    cancelDepositTitle: "ยกเลิกใบรับฝากเงิน",
    askForcancelDeposit: "คุณต้องการยกเลิกใบรับฝากเงินใช่หรือไม่?",
    cancelDepositSuccess: "คุณยกเลิกใบรับฝากเงิน เลขที่ : :code สำเร็จ",
    editDepositSuccess: "คุณแก้ไขใบรับฝากเงิน เลขที่ : :code สำเร็จ",
    saveDepositSuccess: "คุณสร้างใบรับฝากเงินสำเร็จ",

    validateSelectUnit: "กรุณาเลือกรหัสห้อง/บ้าน",
    validateSelectBookBank: "กรุณาเลือกบัญชีเงินเข้าของนิติฯ",
}