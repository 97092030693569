export default {
  supplierSuppliers(state) {
    return state.supplier.suppliers;
  },
  supplierCurrentPage(state) {
    return state.supplier.currentPage;
  },
  supplierNextPage(state) {
    return state.supplier.currentPage + 1;
  },
  supplierTotalPage(state) {
    return state.supplier.totalPage;
  },
  supplierIsLastPage(_, getters) {
    return getters.supplierCurrentPage === getters.supplierTotalPage && getters.supplierTotalPage !== 0;
  }
};
