export default {
  setUnit(state, payload) {
    // state.project.length = 0;
    let units = [...state.unit.units];
    for (const unit of payload.units) {
      if(units.findIndex(c => c.id === unit.id) < 0){
        units.push(unit);
      }
    }
    state.unit.units = units;
    state.unit.currentPage = payload.currentPage;
    state.unit.totalPage = payload.totalPage;
  },
  resetUnit(state) {
    const unit = {
      units: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.unit = unit;
  },
};
  