export default {
    setAccountCharts(state, payload){
        let accountCharts = [...state.accountCharts];
        for (const accountChart of payload.data) {
            if(accountCharts.findIndex(c => c.id === accountChart.id) < 0){
                accountCharts.push(accountChart);
            }
        }
        state.accountCharts = accountCharts;
        state.currentPage = payload.page.currentPage;
        state.totalPage = payload.page.pageCount;
    },
    setSelectedAccountChart(state, payload){
        state.selectedAccountChart = payload
    },
    resetAccountCharts(state){
        state.accountCharts = [];
        state.currentPage = 0;
        state.totalPage = 0;
    },
    setAccountCategory(state, payload) {
        let accountCategory = [...state.accountCategory];
        for (const category of payload.data) {
            if(accountCategory.findIndex(c => c.id === category.id) < 0){
                accountCategory.push(category);
            }
        }
        state.accountCategory = payload.data;
        state.currentPageAccountCategory = payload.currentPage;
        state.totalPageAccountCategory = payload.pageCount;
    },
};