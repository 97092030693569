export default {
  uploadFile: "Upload File",
  dragFile: "Drag drop file or ",
  addFile: "Add File",
  importFileDesc: "Please add only 1 file at a time with .XLSX or ",
  fileName: "File Name",
  validateLimitFile: "Can be upload 1 file at a time",
  validateFileType: "Can't upload file :name",
  validateImportFile: "The allowed file extensions are .XLSX only",
  validateImportNoData: "File : :file no data.",
  validateImportDate: "Invalid date format",
  
  creatingDeposit: "Creating Deposit",
  generating: "Generating...",
  wordingConfirmImportDeposit: "From :total items, you can create only :value items because there have “Invalid status” :invalidValue items.",
  deposit: "Deposit",
  importDeposit: "Import Deposit",
  detailDeposit: "Deposit Detail",
};
