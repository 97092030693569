export default{    
	title: "Account Chart",
	titleListing: "Account Chart List",
	createAccountChart: "Create Account Chart",
	all: "All",
	accountCode: "Account Code",
	accountName: "Account Name",
	searchTextPlaceholder: "Fill up to search.",
	accountNameTh: "Account Name (Thai)",
	accountNameEn: "Account Name (English)",
	parentCode: "Parent Code",
	active: "Active",
    inActive: "InActive",
};