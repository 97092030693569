export default {
    resetImportStockKeycards(state) {
        state.importStockKeycards = [];
    },
    setImportStockKeycards(state, payload) {
        state.importStockKeycards = payload;
    },
    setProducts(state, payload) {
        state.products = payload.data;
    },
    setUnits(state, payload) {
        state.units = payload.data;
    },
    setRunningNumbers(state, payload) {
        state.runningNumbers = payload.data;
    },
}