<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ this.title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog()"
        />
      </div>
      <PvDivider></PvDivider>
    </template>
    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateStart">{{ lang.receiptDateStart }}</label>
          <PvCalendar
            id="dateStart"
            v-model="dateStart"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateStartError }"
            @date-select="dateStartError = false"
          />
          <small v-if="dateStartError" id="date-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="dateEnd">{{ lang.to }}</label>
          <PvCalendar
            id="dateEnd"
            v-model="dateEnd"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateEndError }"
            @date-select="dateEndError = false"
          />
          <small v-if="dateEndError" id="date-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="unitStart">{{ lang.unitStart }}</label>
          <base-unit-auto-complete
            id="unitStart"
            v-model="selectedUnitStart"
            class="inputfield w-full mt-1"
            @load-finish="unitDataReady"
            :class="{ 'p-invalid': unitStartError }"
            @item-select="unitStartError = false"
          />
          <small v-if="unitStartError" id="unit-help" class="p-error">
            {{ lang.validateNoUnitSelect }}
          </small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="unitEnd">{{ lang.to }}</label>
          <base-unit-auto-complete
            id="unitEnd"
            v-model="selectedUnitEnd"
            class="inputfield w-full mt-1"
            @load-finish="unitDataReady"
            :class="{ 'p-invalid': unitEndError }"
            @item-select="unitEndError = false"
          />
          <small v-if="unitEndError" id="unit-help" class="p-error">
            {{ lang.validateNoUnitSelect }}
          </small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText
            id="fileNameText"
            type="text"
            class="inputfield w-full mt-1"
            v-model="fileNameText"
          />
        </div>
        <div class="col-12 pl-0 pr-2">
          <PvCheckbox
            id="typeRefDoc"
            inputId="binary"
            v-model="typeRefDoc"
            :binary="true"
          />
          <label for="typeRefDoc" class="pl-3">{{ lang.typeRefDoc }}</label>
        </div>
      </section>
    </transition>

    <template #footer>
      <div
				class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
			>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
				</div>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
				</div>
				<div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
				</div>
			</div>
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useTextStyle from "../../../hooks/textStyle.js";
import useLang from "../../../hooks/lang.js";
import useFormat from "../../../hooks/format.js";
import useTracking from "../../../hooks/tracking";
import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "toast-request"],
  setup() {
    const { lang } = useLang();
    const store = useStore();
    const { textFormClass } = useTextStyle();
    const { formatDateToISOString } = useFormat();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
        };
      }
    });
    return {
      lang: mergeLang,
      textFormClass,
      formatDateToISOString,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      dateStart: "",
      dateEnd: "",
      dateStartError: false,
      dateEndError: false,
      selectedUnitStart: "",
      selectedUnitEnd: "",
      isSharedUnitReady: false,
      unitEndError: false,
      unitStartError: false,
      typeRefDoc: false,
      type: "PDF",
      fileNameText: "receipt_summary",
    };
  },
  mounted() {
    this.setLoading(true);
    this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    initiateData() {
      let date = new Date();
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.dateStartError = false;
      this.dateEndError = false;
      this.selectedUnitStart = null;
      this.selectedUnitEnd = null;
      this.isSharedUnitReady = false;
      this.unitStartError = false;
      this.unitEndError = false;
      this.typeRefDoc = false;
      this.type = "PDF";
      this.fileNameText = "receipt_summary";
    },
    unitDataReady() {
      this.isSharedUnitReady = true;
      this.checkShouldSetData();
    },
    checkShouldSetData() {
      if (this.isSharedUnitReady) {
        this.setData();
      }
    },
    setData() {
      this.selectedUnitStart = this.units[0];
      this.selectedUnitEnd = this.units[this.units.length - 1];
      this.setLoading(false);
    },
    validateData() {
      let validateError = false;
      if (this.selectedDateStart == null) {
        this.dateStartError = true;
        validateError = true;
      }
      if (this.selectedDateEnd == null) {
        this.dateEndError = true;
        validateError = true;
      }
      // if (this.selectedUnitStart == null) {
      //   this.unitStartError = true;
      //   validateError = true;
      // }
      // if (this.selectedUnitEnd == null) {
      //   this.unitEndError = true;
      //   validateError = true;
      // }
      return validateError;
    },
    async download(typeReport) {
      this.type = typeReport;
      if (this.validateData()) {
        return;
      }
      this.setLoading(true);
      try {
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    title() {
      if (this.$store.getters.selectedLanguage === "TH")
        return this.reportObj.nameThai;
      else return this.reportObj.nameEnglish;
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    units() {
      return this.$store.getters["shared/unitUnits"];
    },
    fileName() {
      let fileName = this.type == "PDF" ? ".pdf" : ".xlsx";
      return this.fileNameText + fileName;
    },
    fileType() {
      let fileType =
        this.type == "EXCEL"
          ? "_excel.xlsx"
          : this.type == "JASPEREXCEL"
          ? ".xlsx"
          : ".pdf";
      return fileType;
    },
    payload() {
      let payload = {
        ProjectId: this.selectedProject.id,
        DateStart: this.selectedDateStart,
        DateEnd: this.selectedDateEnd,
        UnitCodeStart: this.selectedUnitStart ? this.selectedUnitStart.code : "",
        UnitCodeEnd: this.selectedUnitEnd ? this.selectedUnitEnd.code : "",
        IgnoreInvoice: this.typeRefDoc ? true : false,
        FileName: this.fileName,
        JasperPath: this.reportObj.path + this.fileType,
        DocType: this.type,
        UserId: this.userId,
        PrintBy: this.userName,
        RequestId: 0,
        ReportName: "ReceiptSummary",
      };
      return payload;
    },
  },
  watch: {
    reportObj() {
      this.initiateData();
    },
  },
};
</script>
