<template>
  <div
    :class="{
      backgroundBasecard: !isHideBackground,
      p24: screenSizeRegular,
      borderBasecard: isVisibleBorder,
      'mt-5 py-0': isVisibleBorder,
    }"
  >
    <div
      class="flex justify-content-between align-items-center"
      :class="{
        'font-md headerbarRegular': screenSizeRegular,
        headerbarmobile: !screenSizeRegular,
      }"
    >
      <div>
        <label>{{ this.titleHeader }}</label>
        <!-- <h3 class="ml-2">{{ this.titleHeader }}</h3> -->
      </div>
      <div>
        <!-- <PvButton
          :label="lang.showhideParameters"
          class="p-button-link colorblue"
          @click="showhideParameterVisible"
          v-if="isVisibleExpandButton"
        /> -->
        <PvButton
          class="p-button-link"
          @click="showhideParameterVisible"
          v-if="isVisibleExpandButton"
        >
          <span class="colorblue font-md"> {{ lang.showhideParameters }} </span>
        </PvButton>

        <PvButton
          class="p-button-link p-button-icon"
          @click="showhideParameterVisible"
          v-if="isVisibleIconButton"
        >
          <i v-if="isParameterVisible" class="pi pi-angle-up" />
          <i v-else class="pi pi-angle-down" />
        </PvButton>
      </div>
    </div>
    <div v-if="isParameterVisible" class="bodydetail">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  // props: [
  //   "titleHeader",
  //   "isVisibleExpandButton",
  //   "isHideBackground",
  //   "isVisibleCard",
  //   "isVisibleIconButton",
  // ],
  props: {
    titleHeader: {
      type: String,
    },
    isVisibleExpandButton: {
      type: Boolean,
      default: false,
    },
    isHideBackground: {
      type: Boolean,
      default: false,
    },
    isVisibleBorder: {
      type: Boolean,
      default: false,
    },
    isVisibleCard: {
      type: Boolean,
      default: false,
    },
    isVisibleIconButton: {
      type: Boolean,
      default: false,
    },
    screenSizeRegular: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { lang } = useLang();
    const {
      textHeaderClass,
      textFormClass,
      textTableClass,
      textAlertClass,
    } = useTextStyle();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
        };
      } else {
        return {
          ...lang.en.base,
        };
      }
    });
    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      textAlertClass,
    };
  },
  data() {
    return {
      isParameterVisible: true,
    };
  },
  created() {
    this.isParameterVisible = this.isVisibleCard;
  },
  methods: {
    showhideParameterVisible() {
      this.isParameterVisible = !this.isParameterVisible;
    },
  },
  watch: {
    isVisibleCard() {
      // console.log("curValue=", curValue);
      this.isParameterVisible = this.isVisibleCard;
    },
  },
};
</script>

<style scoped>
.p24 {
  padding: 24px;
}
.colorblue {
  color: #1c70f7;
  font-weight: 400;
}

.fontsizeheaderbar {
  color: #181819;
  font-weight: 400;
}

.headerbarRegular {
  height: 48px;
  background-color: #e4e7ed;
  border-radius: 8px;
  padding: 8px 10px;
  font-weight: 400;
}

.headerbarmobile {
  height: 40px;
  background-color: #fafafa;
  border-radius: 8px;
  padding: 8px 10px;
  font-weight: 400;
}

.bodydetail {
  margin: 10px 16px;
}

.p-button-icon {
  color: #787a7d;
}

.backgroundBasecard {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgb(132 132 132 / 10%);
  border-radius: 12px;
}
.borderBasecard {
  border-radius: 12px;
  border-color: #dee2e6;
  border-width: 1px;
  border-style: solid;
}
</style>
