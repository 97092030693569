export default {
    setReportMenuDetail(state, payload) {
      state.reportMenuDetails = payload.data;
      state.totalPage = payload.page.pageCount;
      state.currentPage = payload.page.currentPage;
    },
    setSelectedReportMenuDetail(state, payload) {
      state.selectedReportMenuDeatil = payload;
    },
    resetReportMenuDetail(state) {
      state.reportMenuDetails = [];
      state.totalPage = 0;
      state.currentPage = 0;
    },
    setReportMenuDetailDropDown(state, payload) {
      let reports = [...state.reportMenuDetailDropDown];
      for (const report of payload.data) {
        if (reports.findIndex((c) => c.id === report.id) < 0) {
          reports.push(report);
        }
      }
      state.reportMenuDetailDropDown = reports;
      state.totalPageDropDown = payload.page.pageCount;
      state.currentPageDropDown = payload.page.currentPage;
    },
};