<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '70vw' }"
    :modal="true"
    :closable="false"
  >
    <!-- <template #header> -->
    <div class="flex justify-content-between align-items-center">
      <div class="my-3">
        <PvButton
          icon="pi pi-chevron-left"
          :label="lang.back"
          @click="goBack"
          class="mr-2 p-button-secondary"
        />
      </div>

      <h2 class="my-0">{{ lang.titleImportPurchaseInvoice }}</h2>
    </div>
    <!-- </template> -->

    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
          <label for="bookCode">{{ lang.documentBookCode }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="bookCode"
            :showClear="clearBookCode"
            optionLabel="bookCode"
            optionValue="bookId"
            v-model="selectedBookId"
            @change="SetBookName"
            scrollHeight="300px"
          />
        </div>
        <!-- <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="selectedBookName">{{ lang.documentBookName }}</label>
          <PvInputText
            id="selectedBookName"
            type="text"
            class="inputfield w-full mt-1 input-disable"
            v-model="selectedBookName"
            readonly
          />
        </div> -->
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
          <label for="dateStart">{{ lang.dateStart }}</label>
          <PvCalendar
            id="dateStart"
            v-model="dateStart"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
          <label for="dateEnd">{{ lang.dateEnd }}</label>
          <PvCalendar
            id="dateEnd"
            v-model="dateEnd"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
          <label for="codeStart">{{ lang.codeStart }}</label>
          <PvInputText
            id="codeStart"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="6501xxxxx"
            v-model="codeStart"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
          <label for="codeEnd">{{ lang.codeEnd }}</label>
          <PvInputText
            id="codeEnd"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="6501xxxxx"
            v-model="codeEnd"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
          <PvButton
            icon="pi pi-search"
            :label="lang.search"
            @click="getPurchaseInvoice"
            class="mr-2 mb-2 mt-4"
          />
        </div>
      </section>
    </transition>
    <!-- <div class="pt-2 pb-1">
      <PvButton
        icon="pi pi-search"
        :label="lang.search"
        @click="getPurchaseInvoice"
        class="mr-2 mb-2"
      />
    </div> -->
    <!-- ----------------------------list------------------------------------- -->

    <PvDataTable
      showGridlines
      :value="filteredPurchaseInvoices"
      v-model:selection="selectedPurchaseInvoices"
      dataKey="id"
      :loading="isLoading"
      responsiveLayout="scroll"
      :scrollHeight="'300px'"
      class="p-datatable-sm"
      :class="textTableClass"
    >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
      <!-- @row-unselect="onRowUnselect" -->
      <template #header>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <PvInputText v-model="searchText" :placeholder="lang.keywordSearch" />
        </span>
      </template>
      <PvColumn selectionMode="multiple"></PvColumn>

      <PvColumn
        field="moduleCode"
        :header="lang.type"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>

      <PvColumn
        field="documentBookCode"
        :header="lang.bookCode"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>

      <PvColumn
        field="code"
        :header="lang.columnCode"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>
      <PvColumn
        field="refCode"
        :header="lang.columnRefCode"
        style="min-width: 4rem"
        headerClass="h-center"
        sortable
      ></PvColumn>
      <PvColumn
        field="date"
        :header="lang.columnDate"
        style="min-width: 4rem"
        headerClass="h-center"
        bodyClass="text-center"
      >
        <template #body="slotProps">
          {{ formatDate(slotProps.data.date) }}
        </template>
      </PvColumn>

      <PvColumn
        field="supplierCode"
        :header="lang.columnSupplier"
        style="min-width: 4rem"
        headerClass="h-center"
      ></PvColumn>

      <PvColumn
        field="supplierName"
        :header="lang.columnSupplierName"
        style="min-width: 7rem"
        headerClass="h-center"
      >
      </PvColumn>

      <PvColumn
        field="netAmount"
        :header="lang.columnNetAmount"
        style="min-width: 5rem"
        headerClass="h-center"
        bodyClass="text-right"
        ><template #body="slotProps">
          {{ formatCurrency(slotProps.data.netAmount) }}
        </template></PvColumn
      >
    </PvDataTable>

    <!-- ----------------------------list------------------------------------- -->

    <template #footer>
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog"
      />
      <PvButton
        :label="lang.btnConfirm"
        icon="pi pi-check"
        @click="ConfirmPurchaseInvoice"
      />
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import useGetBookPurchase from "../../hooks/getBookPurchase.js";
export default {
  props: ["openFrom","voucherId"],
  emits: ["close"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass, textAlertClass } =
      useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const { filteredBookPurchases } = useGetBookPurchase();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ap.purchaseInvoice.listing,
          ...lang.th.gl.accounting.edit,
          ...lang.th.gl.accounting.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ap.purchaseInvoice.listing,
          ...lang.th.gl.accounting.edit,
          ...lang.th.gl.accounting.listing,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      textAlertClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      filteredBookPurchases,
    };
  },
  data() {
    return {
      isPurchaseInvoice: false,
      selectedPage: 1,
      rowsPerPage: 100,
      selectedListPage: 1,
      rowsPerListPage: 100,
      isParameterVisible: false,
      isLoading: true,
      selectedReceipts: null,
      clearBookCode: false,
      selectedBookId: null,
      selectedBookName: "",
      dateStart: null,
      dateEnd: null,
      codeStart: "",
      codeEnd: "",
      customerSearch: "",
      selectedUnit: "",
      messageError: [],

      selectedPurchaseInvoices: [],
      searchText: null,
    };
  },
  async created() {
    let date = new Date();
    this.dateStart = new Date();
    this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    // console.log("this.$route.params.id=",this.$route.params.id);
    this.isLoading = false;
  },
  methods: {
    goBack() {
      this.$emit("close");
    },
    closeDialog() {
      this.$emit("close");
    },
    SetBookName() {
      this.$store.dispatch("glPurchaseInvoice/resetPurchaseInvoice",[]);
      if (this.filteredBookPurchases) {
        let bookCode = this.filteredBookPurchases.find(
          (data) => data.bookId === this.selectedBookId
        );
        this.selectedBookName = bookCode.bookNameTH + "/" + bookCode.bookNameEN;
      }
    },
    async getPurchaseInvoice() {
      // console.log("this.voucherId=",this.voucherId);
      
      let voucherId = null;
      if (this.voucherId != "create") voucherId = this.voucherId;

      this.searchText = "";
      this.selectedPurchaseInvoices = [];
      this.isLoading = true;
      let where = {
        projectId: this.selectedProject.id,
        dateStart: this.selectedDateStart,
        dateEnd: this.selectedDateEnd,
        codeStart: this.codeStart || null,
        codeEnd: this.codeEnd || null,
        DocumentBookId: this.selectedBookId,
        PostType: "add",
        VoucherId: voucherId,

      };

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };

      try {
        // console.log("payload=", payload);
        await this.$store.dispatch(
          "glPurchaseInvoice/getPurchaseInvoices",
          payload
        );
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.isLoading = false;
    },
    ConfirmPurchaseInvoice() {
      //   console.log("ConfirmPurchaseInvoice");
      if (this.selectedPurchaseInvoices.length > 0) {
        let purchaseInvoiceSelected = this.purchaseInvoiceSelected;
        this.selectedPurchaseInvoices.forEach((Detail) => {
          //  console.log("Receipts_Detail",Detail);
          const data = purchaseInvoiceSelected.find((d) => d.id === Detail.id);
          if (!data) {
            purchaseInvoiceSelected.push(Detail);
          }
        });
      }
      else
      {
        this.$swal({
          icon: "warning",
          title: this.lang.validateDetailSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return ;
      }

      // this.$emit("confirm-purchase-invoice",null);
      this.$emit("close");
    },
    setBookSelected(){      
      // console.log("this.bookCode=",this.bookCode);
      // console.log("this.filteredBookPurchases=",this.filteredBookPurchases);
      if(this.bookCode && this.filteredBookPurchases){
        if (this.filteredBookPurchases.length > 0) {
          this.selectedBookId = this.filteredBookPurchases[0].bookId;
        }
      }
    },
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    bookCode() {
      let Response = [];
      let books = this.filteredBookPurchases;
      if (books) {
        books.forEach((data) => {
          let book = {
            bookId: data.bookId,
            bookCode:
              data.bookCode + " :  " + data.bookNameTH + "/" + data.bookNameEN,
          };
          Response.push(book);
        });
      }
      return Response;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    purchaseInvoices() {
      return this.$store.getters["glPurchaseInvoice/purchaseInvoices"];
    },
    purchaseInvoiceSelected() {
      return this.$store.getters["glPurchaseInvoice/purchaseInvoiceSelected"];
    },
    filteredPurchaseInvoices() {
    //   console.log('this.selectedBookId=', this.selectedBookId);
      // let PurchaseInvoices = [];
      // if (this.purchaseInvoices) {
      //   const book = this.filteredBookPurchases.find((detail) => detail.bookId == this.selectedBookId);
      //   this.purchaseInvoices.forEach((element) => {
      //     element = { 
      //       ...element,
      //       ...{ moduleCode: book.moduleCode },
      //       ...{ bookId: book.bookId },
      //       ...{ bookCode: book.bookCode } };
      //     PurchaseInvoices.push(element);
      //   });
      // }

      if (!this.searchText) {
        //ช่องว่าง
        const result = [...this.purchaseInvoices];
        return result;
      } else {
        return this.purchaseInvoices.filter((data) => {
          return (
            data.code.toLowerCase().includes(this.searchText.toLowerCase()) ||
            data.supplierCode
              .toLowerCase()
              .includes(this.searchText.toLowerCase()) ||
            data.supplierName
              .toLowerCase()
              .includes(this.searchText.toLowerCase())
          );
        });
      }
    },

  },
  watch: {
    filteredBookPurchases() {
      this.setBookSelected();
    },
    openFrom(){
      this.setBookSelected();
    },
    bookCode(){
      this.setBookSelected();
    }
  },
};
</script>
<style scoped>
.p-dialog .p-dialog-header {
  padding: 0.2rem !important;
}
</style>
