<template>
  <base-toolbar
    :title="lang.title"
    :dataButtons="dataButtonToolbars"
    @goBack="goBack"
    @gotoCreate="gotoEdit('create')"
    :hideBackButton="isHideBack"
  />

  <base-dialog
    v-model:visible="showValidateDialog"
    :titleConfirm="lang.notificationDialogHeader"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    @confirm="showValidateDialog = false"
    @close="showValidateDialog = false"
  />

  <base-container>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{ lang.titleList }}</label>

      <PvDivider></PvDivider>

      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >
        <div class="lg:col-2 md:col-3 col-12 pl-0 pr-2">
          <PvDropdown
            id="filterSearch"
            class="inputfield w-full mr-2"
            :options="filterSearch"
            optionLabel="name"
            optionValue="code"
            v-model="selectedFilterSearch"
            scrollHeight="300px"
            @change="changeFilterSearch"
          />
        </div>
        <div class="flex lg:col-4 md:col-4 col-12 pl-0 pr-0 mr-2" v-if="selectedFilterSearch != 'all'">
          <PvDropdown
            id="id"
            class="inputfield w-full mr-2"
            :options="contactTypes"
            optionLabel="nameThai"
            optionValue="nameThai"
            v-model="selectedContactType"
            scrollHeight="300px"
            :placeholder="lang.dropdownSearchPlaceholder"
            @change="filterPhoneBook"
            v-if="isSearchContactType"
          />
          <PvDropdown
            id="id"
            class="inputfield w-full mr-2"
            :options="phoneTypes"
            optionLabel="nameThai"
            optionValue="nameThai"
            v-model="selectedPhoneType"
            scrollHeight="300px"
            :placeholder="lang.dropdownSearchPlaceholder"
            @change="filterPhoneBook"
            v-else-if="isSearchPhoneType"
          />
          <span class="p-input-icon-left w-full" v-else>
            <i class="pi pi-search" />
            <PvInputText
              class="inputfield w-full"
              v-model="searchText"
              :placeholder="lang.searchTextPlaceholder"
              @keypress="checkInputSearch"
              :disabled="isSearchAll"
              :class="{ 'p-inputtext-disabled': isSearchAll }"
              autofocus
            />
          </span>
        </div>
        <PvButton @click="filterPhoneBook">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="tempPhoneBooks"
          v-model:selection="selectedPhoneBook"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(this.selectedPhoneBook.id)"
          scrollable
        >
          <PvColumn
            field="contactTypeThai"
            :header="lang.contactType"
            style="min-width: 15rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="sequence"
            :header="lang.itemNo"
            style="min-width: 7rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="nameThai"
            :header="lang.namePhoneBook + ' TH'"
            style="min-width: 15rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="nameEnglish"
            :header="lang.namePhoneBook + ' EN'"
            style="min-width: 15rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="phoneTypeThai"
            :header="lang.phoneType"
            style="min-width: 20rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="phone"
            :header="lang.phone"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            :header="lang.status"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left font-normal"
            sortable
          >
            <template #body="slotProps">
              <base-tag
                :Caption="lang.active"
                Class="success"
                v-if="slotProps.data.activeStatus == 1"
              />
              <base-tag
                :Caption="lang.inActive"
                Class="secondary"
                v-else-if="slotProps.data.activeStatus != 1"
              />
            </template>
          </PvColumn>

          <PvColumn
            style="min-width: 2rem"
            headerClass="h-center"
            bodyStyle="justify-content: right; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 m-0"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
            </template>
          </PvColumn>

          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
export default {
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.phoneBook.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.phoneBook.listing,
        };
      }
    });

    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,

      getRoleByMenuByCode,
      isSharedRoleByMenuReady,

      isHideBack,
    };
  },
  data() {
    return {
      isLoading: false,
      showValidateDialog: false,
      validateWording: "",
      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ],

      selectedPhoneBook: null,

      searchText: "",
      filterSearch: [
        { name: this.lang.allData, code: "all" },
        { name: this.lang.namePhoneBook, code: "name" },
        { name: this.lang.phone, code: "phoneNumber" },
        { name: this.lang.contactType, code: "contactType" },
        { name: this.lang.phoneType, code: "phoneType" },
      ],
      selectedFilterSearch: "all",
      selectedContactType: "",
      selectedPhoneType: "",

      isSharedContactTypeReady: false,

      tempPhoneBooks: [],
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.initiateData();

    await this.getContactTypes();
    await this.getPhoneTypes();
    await this.roleByMenuDataReady();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    async gotoEdit(id) {
      if (id != "create") {
        if(!this.isRoleSelect) {
          this.validateWording = this.lang.validateRoleSelect;
          this.showValidateDialog = true;
          return;
        }
      }
      else {
        if(!this.isRoleCreate) {
          this.validateWording = this.lang.validateRoleInsert;
          this.showValidateDialog = true;
          return;
        }
      }
      this.$router.push({name: "phone-book-edit", params: { id: id }});
    },
    async initiateData() {
      this.searchText = "";
      this.selectedPhoneBook = null;
      this.isSharedContactTypeReady = false;
      this.selectedFilterSearch = "all";
      this.selectedContactType = "";
      this.selectedPhoneType = "";

      this.tempPhoneBooks = [];

      await this.$store.dispatch("phoneBook/resetPhoneBook");
    },
    async roleByMenuDataReady() {
      if (this.isSharedRoleByMenuReady) {
        await this.setLocalSearch();
        await this.getPhoneBooks();
      }
    },
    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      if (localSearch) {
        let search = JSON.parse(localSearch);
        if (search.entry === "entry") {
          if (search.name != null && search.name != "") {
            this.selectedFilterSearch = "name";
            this.searchText = search.name;
          } else if (search.phoneNumber != null && search.phoneNumber != "") {
            this.selectedFilterSearch = "phoneNumber";
            this.searchText = search.phoneNumber;
          } else if (search.contactType != null && search.contactType != "") {
            this.selectedFilterSearch = "contactType";
            this.selectedContactType = search.contactType;
          } else if (search.phoneType != null && search.phoneType != "") {
            this.selectedFilterSearch = "phoneType";
            this.selectedPhoneType = search.phoneType;
          } else {
            this.searchText = "";
          }

          search.entry = "";

          localStorage.setItem(this.storageName, JSON.stringify(search));
        }
      }
    },
    async getPhoneBooks() {
      this.isLoading = true;
      try {
        const payload = {
          projectId: this.selectedProject.id,
        };

        await this.$store.dispatch("phoneBook/getAllPhoneBook", payload);
        this.tempPhoneBooks = this.phoneBooks;
        if (this.selectedFilterSearch != "all") {
          await this.filterPhoneBook();
        } else {
          localStorage.setItem(this.storageName, JSON.stringify(this.payload));
        }
      } catch (error) {
        this.validateWording = error;
        this.showValidateDialog = true;
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    validateSearchButton() {
      if (
        !this.isSearchAll &&
        !this.isSearchContactType &&
        !this.isSearchPhoneType &&
        this.searchText == ""
      ) {
        this.validateWording = this.lang.validateSearchText;
        return true;
      }

      if (this.isSearchContactType && !this.selectedContactType) {
        this.validateWording = this.lang.validateDropdownSearchText;
        return true;
      }
      if (this.isSearchPhoneType && !this.selectedPhoneType) {
        this.validateWording = this.lang.validateDropdownSearchText;
        return true;
      }

      return false;
    },
    changeFilterSearch() {
      this.searchText = "";
      this.selectedContactType = "";
      this.selectedPhoneType = "";
    },
    async getContactTypes() {
      try {
        await this.$store.dispatch("phoneBook/getAllContactType");

        if (this.contactTypes) {
          this.isSharedContactTypeReady = true;
        }
      } catch (error) {
        this.validateWording = error;
        this.showValidateDialog = true;
      }
    },
    async getPhoneTypes() {
      try {
        await this.$store.dispatch("phoneBook/getAllPhoneType");
      } catch (error) {
        this.validateWording = error;
        this.showValidateDialog = true;
      }
    },
    async checkInputSearch(event) {
      // console.log(event.key);
      if (this.selectedFilterSearch == "phoneNumber") {
        const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        const keyPressed = event.key;
        if (event.key != "Enter" && !keysAllowed.includes(keyPressed)) {
          event.preventDefault();
        }
      }

      if (event.key == "Enter") {
        await this.filterPhoneBook();
      }
    },
    async filterPhoneBook() {
      if (this.validateSearchButton()) {
        this.showValidateDialog = true;
        return;
      }

      if (this.selectedFilterSearch == "all") {
        await this.getPhoneBooks();
      } else {
        if (this.selectedFilterSearch == "name") {
          this.tempPhoneBooks = this.phoneBooks.filter((data) =>
            data.nameThaiAndEnglish
              .toLowerCase()
              .includes(this.searchText.trim().toLowerCase())
          );
        } else if (this.selectedFilterSearch == "phoneNumber") {
          this.tempPhoneBooks = this.phoneBooks.filter((data) =>
            data.phone
              .toLowerCase()
              .includes(this.searchText.trim().toLowerCase())
          );
        } else if (this.selectedFilterSearch == "contactType") {
          this.tempPhoneBooks = this.phoneBooks.filter((data) =>
            data.contactTypeThai
              .toLowerCase()
              .includes(this.selectedContactType.toLowerCase())
          );
        } else if (this.selectedFilterSearch == "phoneType") {
          this.tempPhoneBooks = this.phoneBooks.filter((data) =>
            data.phoneTypeThai
              .toLowerCase()
              .includes(this.selectedPhoneType.toLowerCase())
          );
        }

        localStorage.setItem(this.storageName, JSON.stringify(this.payload));
      }
    },
  },
  computed: {
    payload() {
      const payload = {
        projectId: this.selectedProject.id,
        name: this.selectedFilterSearch == "name" ? this.searchText : null,
        phoneNumber:
          this.selectedFilterSearch == "phoneNumber" ? this.searchText : null,
        contactType:
          this.selectedFilterSearch == "contactType"
            ? this.selectedContactType
            : null,
        phoneType:
          this.selectedFilterSearch == "phoneType"
            ? this.selectedPhoneType
            : null,
      };

      return payload;
    },
    storageName() {
      return `erp_search_PhoneBookList_${this.selectedProject.code}_${this.userCode}`;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("erp_phonebook");
    },
    isRoleSelect() {
      if (this.roleByMenu && this.roleByMenu.select == "Y") {
        return true;
      }
      return false;
    },
    isRoleCreate() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    isSearchAll() {
      return this.selectedFilterSearch == "all";
    },
    isSearchContactType() {
      return this.selectedFilterSearch == "contactType";
    },
    isSearchPhoneType() {
      return this.selectedFilterSearch == "phoneType";
    },
    phoneBooks() {
      return this.$store.getters["phoneBook/phoneBooks"];
    },
    totalPage() {
      return this.$store.getters["accountingFrequently/totalPage"];
    },
    currentPage() {
      return this.$store.getters["accountingFrequently/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    showCurrentPage() {
      let page = 0;
      page = this.rowsPerPage * (this.selectedPage - 1);
      return page;
    },
    dataButtonToolbars() {
      let dataButtons = [
        {
          id: "create",
          caption: this.lang.createPhoneBook,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
        },
      ];
      return dataButtons;
    },
    contactTypes() {
      return this.$store.getters["phoneBook/contactTypes"];
    },
    phoneTypes() {
      return this.$store.getters["phoneBook/phoneTypes"];
    },
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.roleByMenuDataReady();
    },
  },
};
</script>
