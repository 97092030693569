<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
    :header="lang.selectGlBook"
  >
    <PvDataTable
      showGridlines
      :value="filteredGlBooks"
      v-model:selection="selectedGlBook"
      selectionMode="single"
      dataKey="generalLedgerBookId"
      responsiveLayout="scroll"
      :scrollHeight="halfViewportHeight + 'px'"
      class="p-datatable-sm"
      :class="textTableClass"
      :loading="isSharedGlBookReady"
      @row-dblclick="selectGlBook()"
    >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
      <template #header>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <PvInputText
            v-model="searchText"
            :placeholder="lang.keywordSearch"
            autofocus
          />
        </span>
      </template>
      <PvColumn selectionMode="single" bodyClass="text-center"></PvColumn>
      <PvColumn
        field="generalLedgerBookCode"
        :header="lang.code"
        headerClass="h-center"
        bodyClass="text-center"
      ></PvColumn>
      <PvColumn
        field="generalLedgerBookName"
        :header="lang.name"
        headerClass="h-center"
      ></PvColumn>
    </PvDataTable>
    <template #footer>
      <!-- <PvButton
        :label="lang.close"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog"
      /> -->
      <PvButton
        :label="lang.ok"
        icon="pi pi-check"
        autofocus
        @click="selectGlBook"
      />
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useGetGlBook from "../../hooks/getGlBook.js";
export default {
  props: {
    shouldGetData: {
      type: Boolean,
      default: true,
    },
    search: {
      type: String,
    },
    isSharedLoading: {
      type: Boolean
    }
  },
  emits: ["close", "glBook-selected"],
  setup() {    
    const { lang } = useLang();
    const { textHeaderClass, textTableClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.gl.accounting.listing };
      } else {
        return { ...lang.en.base, ...lang.en.gl.accounting.listing };
      }
    });
    const { filteredGlBooks, searchText, isSharedGlBookReady } = useGetGlBook();

    return {
      filteredGlBooks,
      isSharedGlBookReady,
      textHeaderClass,
      textTableClass,
      lang: mergeLang,
      searchText 
    };
  },
  data() {
    return {
      selectedGlBook: null,
    };
  },
  methods: {
    selectGlBook() {
      if (this.$route.name === "gl-accounting-book" || this.$route.name === "gl-accounting") {
        if (this.selectedGlBook.isInvoice === "N" && this.selectedGlBook.isReceipt === "N") {
          this.$emit("glBook-selected", this.selectedGlBook);
        } else if (this.selectedGlBook.isInvoice === "Y") {
          this.$swal({
            icon: "error",
            html: this.lang.invoiceSelect,
            confirmButtonText: this.lang.close,
            allowOutsideClick: false,
          });
        } else if (this.selectedGlBook.isReceipt === "Y") {
          this.$swal({
            icon: "error",
            html: this.lang.receiptSelect,
            confirmButtonText: this.lang.close,
            allowOutsideClick: false,
          });
        }
      } else {
        this.$emit("glBook-selected", this.selectedGlBook);
      }
      
    },
    closeDialog() {
      this.$emit("close");
    },
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
  },
  watch: {
    search(curValue) {    
      if (curValue) {
        this.searchText = curValue;
      }
    },
    filteredGlBooks(){
      this.selectedGlBook = this.filteredGlBooks[0];
    },
    isSharedLoading(curValue){
      this.isSharedGlBookReady = curValue;
    }
  },
};
</script>
