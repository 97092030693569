export default {
	async getPeriods(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/PettyCash/GetPettyCashPeriod";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get meter");
			throw error;
		}
		// console.log("getPeriods responseData", responseData);
		context.commit("setPeriod", responseData);
	},

	async exportPDF(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + payload.path;

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload.where),
			mode: "cors", // no-cors, *cors, same-origin
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			credentials: "same-origin", // include, *same-origin, omit
			redirect: "follow", // manual, *follow, error
			referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
		});

		let urlBlob = "";
		let blob = await response.blob();
		urlBlob = window.URL.createObjectURL(blob);
		context.commit("setBlob", urlBlob);
	},

	async exportExcel(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + payload.path;

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload.where),
			mode: "cors", // no-cors, *cors, same-origin
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			credentials: "same-origin", // include, *same-origin, omit
			redirect: "follow", // manual, *follow, error
			referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
		});

		// console.log("exportExcel_response>>>", response);
		response.blob().then(function (blob) {
			var url = window.URL.createObjectURL(blob);
			var a = document.createElement("a");
			a.href = url;
			const d = new Date();
			let dateText = d.toISOString();
			a.download = `${payload.ExcelName}_${dateText}.xlsx`;
			document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
			a.click();
			a.remove(); //afterwards we remove the element again
		});
		// let urlBlob = "";
		// let blob = await response.blob();
		// urlBlob = window.URL.createObjectURL(blob);
		// context.commit("setBlob", urlBlob);
	},

	async getReportMenu(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/ReportMenu/ReportMenu";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get meter");
			throw error;
		}
		// console.log("setReportMenu responseData", responseData);
		await context.commit("setSelectedReportMenu", responseData.data[0]);
	},

	async getReportMenuDetail(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/ReportMenu/ReportMenuDetail";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get meter");
			throw error;
		}
		// console.log("getReportMenuDetail responseData", JSON.stringify(responseData.data));

		await context.commit("setReportMenuDetail", responseData);
	},
	async getAllReportMenuDetail(context, payload) { 
		await context.dispatch("resetMenuDetail");
		while (!context.getters.reportMenuDetailIsLastPage) {
			let where = payload.where;
			const payloadP = {
				page: context.getters.reportMenuDetailNextPage || 1,
				where,
			};
			await context.dispatch("getReportMenuDetail", payloadP);
		}
	},
	resetMenuDetail(context) {
		context.commit("setSelectedMenuDetail", null);
	},
	async exportText(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + payload.path;

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload.where),
			mode: "cors", // no-cors, *cors, same-origin
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			credentials: "same-origin", // include, *same-origin, omit
			redirect: "follow", // manual, *follow, error
			referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
		});

		response.blob().then(function (blob) {
			let url = window.URL.createObjectURL(blob);
			let a = document.createElement("a");
			a.href = url;
			const d = new Date();
			let dateText = d.toISOString();
			a.download = `${payload.TextName}_${dateText}.txt`;
			document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
			a.click();
			a.remove(); //afterwards we remove the element again
		});
	},
};
