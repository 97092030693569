<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div
        class="flex justify-content-between align-items-center flex flex-wrap pt-3 pb-3"
      >
        <span class="font-lg">{{ lang.printLot }}</span>
      </div>
      <PvDivider class="mt-3 mb-3" />
    </template>

    <section class="flex flex-wrap mb-3">
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
        <label for="dateAt">{{ lang.columnDate }}</label>
        <PvAutoComplete
          v-model="selectedDateAt"
          :suggestions="filteredDateAt"
          @complete="searchDateAt"
          field="dateString"
          :dropdown="true"
          forceSelection
          class="w-full mt-1"    
          @item-select="changeDate()"   
        ></PvAutoComplete>
        <!-- <PvCalendar
          id="dateAt"
          v-model="dateAt"
          class="inputfield w-full mt-1 input-disable"
          showIcon
          dateFormat="dd/mm/yy"
          @date-select="changeDate()"
        /> -->
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
        <label for="selectPrintType">{{ lang.labelSearchBy }}</label>
        <PvDropdown
          class="inputfield w-full mt-1"
          :options="selectPrintType"
          optionLabel="name"
          optionValue="code"
          v-model="selectedPrintType"
          @change="changeSelectedPrintType()"
        />
      </div>

      <div class="flex flex-wrap w-full" v-if="this.isSelectedPrintType">
        <!--v-if="this.isSelectedPrintType"-->
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="selectedCodeStart">{{ lang.codeStart }}</label>
          <PvSkeleton
            v-if="isNoticesByAtDateLoading"
            class="inputfield w-full mt-2"
            borderRadius="5px"
            height="38px"
          />
          <PvAutoComplete
            v-model="selectedCodeStart"
            :suggestions="filteredCodeStart"
            @complete="searchCodeStart"
            field="code"
            :dropdown="true"
            forceSelection
            class="w-full mt-1"
            v-else
          ></PvAutoComplete>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="selectedCodeEnd">{{ lang.codeEnd }}</label>
          <PvSkeleton
            v-if="isNoticesByAtDateLoading"
            class="inputfield w-full mt-2"
            borderRadius="5px"
            height="38px"
          />
          <PvAutoComplete
            v-model="selectedCodeEnd"
            :suggestions="filteredCodeEnd"
            @complete="searchCodeEnd"
            field="code"
            :dropdown="true"
            forceSelection
            class="w-full mt-1"
            v-else
          ></PvAutoComplete>
          <!-- <PvInputText
            id="selectedCodeEnd"
            type="text"
            class="inputfield w-full mt-1"
            v-model="selectedCodeEnd"
          /> -->
        </div>
      </div>
      <div class="flex flex-wrap w-full" v-else>
        <!--v-else-->
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="selectedUnitStart">{{ lang.unitStart }}</label>
          <PvSkeleton
            v-if="isNoticesByAtDateLoading"
            class="inputfield w-full mt-2"
            borderRadius="5px"
            height="38px"
          />
          <PvAutoComplete
            v-model="selectedUnitStart"
            :suggestions="filteredUnitStart"
            @complete="searchUnitStart"
            field="unitAndAddress"
            :dropdown="true"
            forceSelection
            class="w-full mt-1"
            v-else
          ></PvAutoComplete>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="selectedUnitEnd">{{ lang.unitEnd }}</label>
          <PvSkeleton
            v-if="isNoticesByAtDateLoading"
            class="inputfield w-full mt-2"
            borderRadius="5px"
            height="38px"
          />
          <PvAutoComplete
            v-model="selectedUnitEnd"
            :suggestions="filteredUnitEnd"
            @complete="searchUnitEnd"
            field="unitAndAddress"
            :dropdown="true"
            forceSelection
            class="w-full mt-1"
            v-else
          ></PvAutoComplete>
        </div>
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
        <label for="selectedIgnoreZero">{{ lang.isIgnoreZero }}</label>
        <PvDropdown
          class="inputfield w-full mt-1"
          :options="selectType"
          optionLabel="name"
          optionValue="code"
          v-model="selectedIgnoreZero"
        />
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
        <label for="selectedGroup">{{ lang.isGroup }}</label>
        <PvDropdown
          class="inputfield w-full mt-1"
          :options="selectType"
          optionLabel="name"
          optionValue="code"
          v-model="selectedGroup"
        />
      </div>
    </section>

    <template #footer>      
      <div class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center">
        <div class="lg:col-6 md:col-6 col-12 pl-1 pr-1">
          <PvButton
            :label="lang.close"
            class="p-button-outlined p-button-secondary w-full"
            @click="closeDialog()"
          />
        </div>
				<div class="lg:col-6 md:col-6 col-12 pl-1 pr-1">
          <PvButton
            :label="lang.confirm"
            class="w-full"
            @click="confirm()"
            :disabled="isLockConfirm"
          />
        </div>        
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  props: {
    openDialog: {
      type: String,
    },
  },
  emits: ["close","selected-print-lot"],
  setup() {
    const { lang } = useLang();
    const { formatDate,formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.notice.listing,
          ...lang.th.ar.notice.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.notice.listing,
          ...lang.en.ar.notice.edit,
        };
      }
    });
    return {
      lang: mergeLang,
      formatDate,
      formatDateToISOString,
    };
  },
  data() {
    return {
      selectPrintType: [
        { name: this.lang.selectCode, code: "Code" },
        { name: this.lang.selectUnit, code: "Unit" },
      ],
      selectedPrintType: "Code",
      isSelectedPrintType: true,
      isNoticesByAtDateLoading: true,
      filteredDateAt: null,
      selectedDateAt: null,
      filteredCodeStart: null,
      selectedCodeStart: null,
      filteredCodeEnd: null,
      selectedCodeEnd: null,
      filteredUnitStart: null,
      selectedUnitStart: null,
      filteredUnitEnd: null,
      selectedUnitEnd: null,
      selectedIgnoreZero: "Y",
      selectedGroup: "Y",

      selectType: [
        { name: this.lang.yes, code: "Y" },
        { name: this.lang.no, code: "N" },
      ],
      itemsNoticeCode: [],
    };
  },
  async created() {
    this.initiateData();
    await this.getNoticesGroupDate();
    //await this.getNoticesByAtDate();
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    initiateData() {
      this.selectedDateAt = null;
      this.selectedPrintType = "Code";
      this.selectedCodeStart = null;
      this.selectedCodeEnd = null;
      this.selectedUnitStart = null;
      this.selectedUnitEnd = null;
      this.selectedIgnoreZero = "Y";
      this.selectedGroup = "Y";
      this.isSelectedPrintType = true;
    },    
    async setDateAt() {
      if (this.noticesDate) {
        // if (!this.selectedDateAt) {
          this.filteredDateAt = [...this.noticesDate];
          this.selectedDateAt = this.filteredDateAt[0];
        // }
        await this.getNoticesByAtDate();        
        this.setData();
      }
    },
    async setData() {
      if (this.noticesByAtDate) {
        // if (!this.selectedCodeStart) {
          this.filteredCodeStart = [...this.noticesByAtDate];
          this.filteredCodeStart.sort((a, b) => (a.code < b.code ? -1 : 1));
          this.selectedCodeStart = this.filteredCodeStart[0];
        // }

        // if (!this.selectedCodeEnd) {
          this.filteredCodeEnd = [...this.noticesByAtDate];
          this.selectedCodeEnd = this.filteredCodeEnd[0];
        // }

        // if (!this.selectedUnitStart) {
          this.filteredUnitStart = [...this.noticesUnit];
          this.filteredUnitStart.sort((a, b) => (a.unit < b.unit ? -1 : 1));
          this.selectedUnitStart = this.filteredUnitStart[0];
        // }

        // if (!this.selectedUnitEnd) {
          this.filteredUnitEnd = [...this.noticesUnit];
          this.selectedUnitEnd = this.filteredUnitEnd[0];
        // }
      }
    },
    async getNoticesGroupDate() {
      try {
        const payload = {
          ProjectId: this.selectedProject.id,
        };
        // console.log("payload=", payload);
        await this.$store.dispatch("arNotice/getNoticesGroupDate", payload);
      } catch (error) {
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.isLoading = false;
      }
    },
    changeSelectedPrintType() {
      if (this.selectedPrintType == "Code") {
        this.isSelectedPrintType = true;
        this.selectedUnitStart = null;
        this.selectedUnitEnd = null;
        this.selectedCodeStart = this.filteredCodeStart[0];
        this.selectedCodeEnd = this.filteredCodeEnd[0];
      } else {
        this.isSelectedPrintType = false;
        this.selectedCodeStart = null;
        this.selectedCodeEnd = null;
        this.selectedUnitStart = this.filteredUnitStart[0];
        this.selectedUnitEnd = this.filteredUnitEnd[0];
      }
    },
    async changeDate() {
      await this.getNoticesByAtDate();
      this.setData();
    },
    async getNoticesByAtDate() {
      try {
        this.isNoticesByAtDateLoading = true;
        const payload = {
          ProjectId: this.selectedProject.id,
          DateAt: this.selectedDateAt.date,
        };
        // console.log("payload=", payload);
        await this.$store.dispatch("arNotice/getNoticesByAtDate", payload);
        this.isNoticesByAtDateLoading = false;
      } catch (error) {
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.isLoading = false;
      }
    },
    searchDateAt(event) {
      if (this.noticesDate) {
        if (!event.query.trim().length) {
          this.filteredDateAt = [...this.noticesDate];
        } else {
          this.filteredDateAt = this.noticesDate.filter((e) => {
            return e.dateString
              .toLowerCase()
              .includes(event.query.toLowerCase());
          });
        }
      }
    },
    searchCodeStart(event) {
      if (this.noticesByAtDate) {
        if (!event.query.trim().length) {
          this.filteredCodeStart = [...this.noticesByAtDate];
        } else {
          this.filteredCodeStart = this.noticesByAtDate.filter((e) => {
            return e.code.toLowerCase().includes(event.query.toLowerCase());
          });
        }
        this.filteredCodeStart.sort((a, b) => (a.code < b.code ? -1 : 1));
      }
    },
    searchCodeEnd(event) {
      if (this.noticesByAtDate) {
        if (!event.query.trim().length) {
          this.filteredCodeEnd = [...this.noticesByAtDate];
        } else {
          this.filteredCodeEnd = this.noticesByAtDate.filter((e) => {
            return e.code.toLowerCase().includes(event.query.toLowerCase());
          });
        }
        this.filteredCodeEnd.sort((a, b) => (a.code < b.code ? 1 : -1));
      }
    },
    searchUnitStart(event) {
      if (this.noticesUnit) {
        if (!event.query.trim().length) {
          this.filteredUnitStart = [...this.noticesUnit];
        } else {
          this.filteredUnitStart = this.noticesUnit.filter((e) => {
            return e.unitAndAddress
              .toLowerCase()
              .includes(event.query.toLowerCase());
          });
        }
        this.filteredUnitStart.sort((a, b) => (a.unit < b.unit ? -1 : 1));
      }
    },
    searchUnitEnd(event) {
      if (this.noticesUnit) {
        if (!event.query.trim().length) {
          this.filteredUnitEnd = [...this.noticesUnit];
        } else {
          this.filteredUnitEnd = this.noticesUnit.filter((e) => {
            return e.unitAndAddress
              .toLowerCase()
              .includes(event.query.toLowerCase());
          });
        }
        this.filteredUnitEnd.sort((a, b) => (a.unit < b.unit ? 1 : -1));
      }
    },
    confirm() {
      // console.log("this.selectedDateAt=", this.selectedDateAt.date);
      // console.log("selectedCodeStart=", this.selectedPrintType == "Code" ? this.selectedCodeStart.code : "");
      // console.log("selectedCodeEnd=", this.selectedPrintType == "Code" ? this.selectedCodeEnd.code : "");
      // console.log("selectedUnitStart=", this.selectedPrintType == "Unit" ? this.selectedUnitStart.unit : "");
      // console.log("selectedUnitEnd=",  this.selectedPrintType == "Unit" ? this.selectedUnitEnd.unit : "");
      // console.log("selectedIgnoreZero=", this.selectedIgnoreZero);
      // console.log("selectedGroup=", this.selectedGroup);
      let payload = {
        date: this.formatDateToISOString(new Date(this.selectedDateAt.date)) ,
        codeStart: this.selectedPrintType == "Code" ? this.selectedCodeStart.code : "",
        codeEnd: this.selectedPrintType == "Code" ? this.selectedCodeEnd.code : "",
        unitStart: this.selectedPrintType == "Unit" ? this.selectedUnitStart.unit : "",
        unitEnd: this.selectedPrintType == "Unit" ? this.selectedUnitEnd.unit : "",
        isIgnoreZero: this.selectedIgnoreZero,
        isGroup: this.selectedGroup,
      }
      this.$emit("selected-print-lot",payload);
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    sharedProductArs() {
      return this.$store.getters["shared/productArProducts"];
    },
    noticesGroupDate() {
      return this.$store.getters["arNotice/noticesGroupDate"];
    },
    noticesByAtDate() {
      return this.$store.getters["arNotice/noticesByAtDate"];
    },
    noticesDate() {
      let dates = [];
      if (this.noticesGroupDate) {
        this.noticesGroupDate.forEach((element) => {
          element = { ...element, dateString: this.formatDate(element.date) };
          dates.push(element);
        });
      }
      return dates;
    },
    noticesUnit() {
      let units = [];
      if (this.noticesByAtDate) {
        this.noticesByAtDate.forEach((d) => {
          let unit = {
            id: d.id,
            unit: d.unitCode.trim().toUpperCase(),
            address: d.unitAddressNo.trim(),
            unitAndAddress: d.unitCode.trim().toUpperCase() + " : " + d.unitAddressNo.trim().toUpperCase(),
          };

          let found = units.find((x) => x.unit === unit.unit);
          if (!found) {
            units.push(unit);
          }
        });
      }
      return units;
    },
    isLockConfirm() {
      if (!this.selectedDateAt) {
        return true;
      }
      if (this.selectedPrintType == "Code" && !this.selectedCodeStart) {
        return true;
      }
      if (this.selectedPrintType == "Code" && !this.selectedCodeEnd) {
        return true;
      }
      if (this.selectedPrintType == "Unit" && !this.selectedUnitStart) {
        return true;
      }
      if (this.selectedPrintType == "Unit" && !this.selectedUnitEnd) {
        return true;
      }

      return false;
    },
  },
  watch: {
    openDialog() {
      this.initiateData();      
      this.setDateAt();
    },
    noticesDate(){     
      this.setDateAt();
    }
  },
};
</script>

<style></style>
