export default {
  async getAssetRegisters(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Asset";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get asset register"
      );
      throw error;
    }
    // console.log(responseData);

    // const data = responseData.data;

    context.commit("setAssetRegister", responseData);

  },
  async getAssetRegister(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Asset?Page=1&Limit=10";

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get asset register"
      );
      throw error;
    }
    // console.log(responseData);

    // const data = responseData.data;
    if (responseData.data.length > 0) {
      context.commit("setSelectedAssetRegister", responseData.data[0]);
    }
  },
  async createOrUpdateAssetRegister(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Asset/${payload.type}`;

    const fallbackErrorMessage = `Failed to ${payload.type} purchase requisition`
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: payload.type === 'Add' ? 'post' : 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }

    context.commit("setSelectedAssetRegister", responseData.data);

  },
  async getAssetRegisterDropDown(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Asset";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get asset register"
      );
      throw error;
    }
    // console.log("asset", responseData);

    // const data = responseData.data;

    // responseData.data = [
    //   ...context.getters.assetRegisters,
    //   ...responseData.data
    // ];

    await context.commit("setAssetRegisterDropDown", responseData);
  },
  async getAllAssetRegisters(context) {
    // await context.commit("resetAssetRegister");
    while (!context.getters.assetRegisterIsLastPage) {
      let where = {
        projectId: context.rootGetters.selectedProject.id,
      };
      const payload = {
        page: context.getters.assetRegisterNextPage || 1,
        where,
      };
      // console.log(payload);
      await context.dispatch("getAssetRegisterDropDown", payload);
    }
  },
  resetSelectedAssetRegister(context) {
    context.commit("setSelectedAssetRegister", null);
  },
  async getAssetCodeByTypes(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Asset/GetCodeByType";

    const token = context.rootGetters.token;      
      //  console.log("payload=",payload);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(payload)
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log("AddPrepaid_responseData_Error>>>",responseData);
      const error = new Error(responseData.message || "Failed to get asset type number");
      throw error;
    }
    //console.log("setAssetCodeByType>>>",responseData);            
    await context.commit('setAssetCodeByType', responseData.data);     

  },
  async copyAssetRegister(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Asset/Copy";

    const token = context.rootGetters.token;      
      //  console.log("payload=",payload);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(payload)
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log("AddPrepaid_responseData_Error>>>",responseData);
      const error = new Error(responseData.message || "Failed to copy asset");
      throw error;
    }
    // context.commit("setSelectedAssetRegister", responseData.data);

  },
};
