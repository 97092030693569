<template>
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredReportMenuDetails"
    field="nameThai"
    @complete="searchReportMenuDetail"
    @dropdown-click="filteredReportMenuDetails"
    forceSelection
  />
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default {
  props: ["reportMenuId"],
  emits: ["load-finish"],
  setup() {
    const store = useStore();
    let searchText = ref(null);
    let menuId = ref(null);

    const reportMenuDetails = computed(() => {
      let data = store.getters["reportMenuDetail/reportMenuDetailDropDown"];
      if(menuId.value) {
        return data.filter((detail) => {
          return detail.reportMenuId == menuId.value;
        });
      } else {
        return data;
      }
    });

    // console.log("reportMenuDetails", reportMenuDetails);

    const filteredReportMenuDetails = computed(() => {
      if (!searchText.value) {
        //ช่องว่าง
        const result = [...reportMenuDetails.value];
        return result;
      } else {
        return reportMenuDetails.value.filter((detail) => {
          return detail.nameThai
            .toLowerCase()
            .includes(searchText.value.toLowerCase());
        });
      }
    });

    const searchReportMenuDetail = function (event) {
      if (!event.query.trim().length) {
        //ช่องว่าง
        // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
        searchText.value = "";
        searchText.value = null;
      } else {
        searchText.value = event.query;
      }
    };

    let isReportMenuDetailReady = ref(false);

    const getReportMenuDetailByName = function (nameThai) {
      return reportMenuDetails.value.find((detail) => {
        return detail.nameThai.toLowerCase() === nameThai.trim().toLowerCase();
      });
    };

    onMounted(async () => {
      await store.dispatch("reportMenuDetail/getAllReportMenuDetailDropDown");
      isReportMenuDetailReady.value = true;
    });

    return {
      searchText,
      filteredReportMenuDetails,
      searchReportMenuDetail,
      getReportMenuDetailByName,
      isReportMenuDetailReady,
      menuId
    };
  },
  mounted() {
    this.menuId = this.reportMenuId;
  },
  watch: {
    isReportMenuDetailReady(curValue) {
      if (curValue) { 
        this.menuId = this.reportMenuId; 
        this.$emit("load-finish");
      }
    },
    reportMenuId() {
      if(this.isReportMenuDetailReady) {
        this.menuId = this.reportMenuId;
      }
    }
  },
};
</script>
