<template>
  <PvDropdown
    id="paymentRequestType"
    type="text"
    class="inputfield w-full"
    :options="paymentRequestTypeList"
    optionLabel="name"
  />
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, onMounted, watch } from "vue";
export default {
  props: {
    isAllType: {
        type: Boolean,
        default: false,
    },
  },
  emits: ["load-finish"],
  setup(props, {emit}) {
    const store = useStore();

    const selectedProject = computed(() => {
        return store.getters.selectedProject;
    });

    let paymentRequestTypeList = ref([]);

    const paymentRequestType = computed(() => {
        return store.getters["paymentRequestType/paymentRequestType"];
    });

    let isSharedPaymentRequestTypeReady = ref(false);

    const getPaymentRequestType = async function () {
        let payload = {
            projectId: selectedProject.value.id
        };
        await store.dispatch("paymentRequestType/getPaymentRequestType", payload);

        let data = paymentRequestType.value;
        paymentRequestTypeList.value = data;
        if (props.isAllType) {
            paymentRequestTypeList.value = [{code: "", name: "ทั้งหมด"}]
            data.forEach((e) => {
                paymentRequestTypeList.value.push(e);
            });
        }

        isSharedPaymentRequestTypeReady.value = true;
    };

    onMounted(async () => {
        await getPaymentRequestType();
    });

    watch(isSharedPaymentRequestTypeReady, async (curValue) => {
      if (curValue) {
        emit("load-finish", paymentRequestTypeList.value);
      }
    });

    return {
        isSharedPaymentRequestTypeReady,
        paymentRequestTypeList,
    }
  },
};
</script>
