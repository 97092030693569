let timer;
export default {
  async login(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Auth/Login";

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        username: payload.username,
        password: payload.password,
      }),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || "Failed to authenticate");
      throw error;
    }

    const data = responseData.data;
    const expiredAt = data.expiredAt
    let expiresIn = expiredAt - data.issuedAt;
    // console.log(expiredAt);
    // console.log(expiresIn); 
    

    localStorage.setItem("erp_token", data.token);
    localStorage.setItem('erp_userId', data.userId);
    localStorage.setItem('erp_userCode', data.userCode);
    localStorage.setItem('erp_userName', data.userName);
    localStorage.setItem("erp_tokenExpiration", expiredAt);

    if (expiresIn >= 2147483647)
    {
      expiresIn = 2147483647;
    } // hot fix timeout exceed 2147483647;
    //https://stackoverflow.com/questions/3468607/why-does-settimeout-break-for-large-millisecond-delay-values
    timer = setTimeout(function () {
      context.dispatch("autoLogout");
    }, expiresIn);

    context.commit("setUser", {
      token: data.token,
      userId: data.userId,
      userCode: data.userCode,
      userName: data.userName
    });
  },
  async loginLosa(context, payload) {
    let url = process.env.VUE_APP_LOSA_PM_URL + "/api/services/erp/authlivingos/login";

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: payload.username,
        password: payload.password,
      }),
    });
    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to authenticate");
      throw error;
    }

    const token = responseData.result;

    localStorage.setItem("erp_token_losa_pm", token);

    context.commit("setTokenLosaPM", token);
  },
  logout(context) {
    const user = {
      token: null,
      userId: null,
      userCode: null,
      userName: null,
      didAutoLogout: false,
    };
    //remove all relate localStorage
    const storages = Object.entries(localStorage);
    for (const storage of storages) {
      if(/erp_*/.test(storage[0]) && storage[0] !== 'erp_selectedLanguage'){
        localStorage.removeItem(storage[0])
      }
    }
    clearTimeout(timer);
    context.commit("setUser", user);
    context.commit("setProject", null);
  },
  async autoLogout(context) {
    await context.dispatch("removeAllCache");
    context.dispatch("logout");
    context.commit("setAutoLogout");
    window.document.body.style.paddingLeft = 0;
  },
  async removeAllCache(context) {
    const userId = context.rootGetters.userId;
    const payload = { UserId: userId };
    if (userId) {
      let url = process.env.VUE_APP_BACKEND_URL + "/V2/Role/RemoveAllCache";

      const token = context.rootGetters.token;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();

      if (!response.ok) {
        // console.log("setEmployeesByUserIds", responseData);
        const error = new Error(responseData.message || "Failed to Remove All Cache By UserIds");
        throw error;
      }
    }
  },
  tryLogin(context) {

    const token = localStorage.getItem("erp_token");
    const tokenExpiration = localStorage.getItem("erp_tokenExpiration");
    const userId = localStorage.getItem('erp_userId');
    const userCode = localStorage.getItem('erp_userCode');
    const userName = localStorage.getItem('erp_userName');
    const tokenLosa = localStorage.getItem('erp_token_losa_pm');

    let expiresIn = +tokenExpiration - new Date().getTime();

    if (expiresIn >= 2147483647)
    {
      expiresIn = 2147483647;
    }
    
    if (expiresIn < 28800000) {
      //8 hours
      context.dispatch("autoLogout");
      return;
    }

    timer = setTimeout(function () {
      context.dispatch("autoLogout");
    }, expiresIn);
    

    if (token) {
      context.commit("setUser", {
        token,
        userId,
        userCode,
        userName
      });
    }
    if (tokenLosa) {
      context.commit("setTokenLosaPM", tokenLosa);
    }
    
  },
  
  async ChangePassword(context, payload) {

    let url = process.env.VUE_APP_BACKEND_URL + `/Auth/ChangePassword`;
		// const fallbackErrorMessage = `Failed to ${payload.typeInsert} ChangePassword`;
		const token = context.rootGetters.token;     
    //  console.log("payload=",payload);
    
		const response = await fetch(url, {
			method: "put",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},

			body: JSON.stringify(payload),
		});

    //  console.log("payload=",JSON.stringify(payload));
    const responseData = await response.json();
    
		if (!response.ok) {
			// // console.log(responseData);
			// let message = null;
			// if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
			// 	message = responseData.title;
			// 	for (const key in responseData.errors) {
			// 		message += ", " + key.replace("$.", "");
			// 	}
			// } else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
			// 	message = responseData.message;
			// }
			// const error = new Error(message || fallbackErrorMessage);
			// throw error;
      throw responseData;
		}
    // console.log("AddPrepaid_responseData>>>",responseData);            
    // await context.commit('setSelectedPurchaseInvoice', responseData.data);      
        
  },

};
