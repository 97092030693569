<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
    :draggable="false"
    :header="title"
  >
    <section class="flex flex-wrap" :class="textFormClass">
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="sequence">{{ lang.sequence }}</label>
        <PvInputText
          id="sequence"
          type="text"
          class="inputfield w-full mt-1"
          v-model="sequence"
          :class="{ 'p-invalid': sequenceError }"
          @keypress="sequenceError = false"
          :disabled="isNoSelectedProject"
        />
        <small v-if="sequenceError" id="sequence-help" class="p-error">{{lang.validateSequence
        }}</small>
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="sequence">{{ lang.category }}</label>
        <PvDropdown
          class="inputfield w-full mt-1"
          :options="categories"
          :showClear="clearCategory"
          optionLabel="name"
          optionValue="name"
          v-model="selectCategory"
          @change="selectedCategory"
          :disabled="isNoSelectedProject"
        />
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2" v-if="isShowProjectType && !isNoSelectedProject">
        <label for="project">{{ lang.project }}</label>
        <PvSkeleton
          v-if="isProjectLoading"
          class="inputfield w-full mt-1"
          height="39px"
        />
        <PvAutoComplete
          id="category"
          class="inputfield w-full mt-1"
          :suggestions="filteredProjects"
          :field="fieldCodeAndName"
          :dropdown="true"
          @complete="searchProject($event)"
          v-model="selectedProject"
          :class="{ 'p-invalid': projectError }"
          @item-select="projectError = false"
          v-else
        />
        <small v-if="projectError" id="project-help" class="p-error">{{
          lang.validateProject
        }}</small>
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2" v-if="isShowProjectType && isNoSelectedProject">
        <label for="projectName">{{ lang.project }}</label>
        <PvInputText
          id="projectName"
          type="text"
          class="inputfield w-full mt-1"
          v-model="projectName"
          :disabled="isNoSelectedProject"
        />
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2" v-if="isShowProjectType">
        <label for="reportDetail">{{ lang.reportCategory }}</label>
        <report-menu-detail-auto-complete
          id="reportDetail"
          class="inputfield w-full mt-1"
          v-model="selectedReportMenuDetail"
          @load-finish="reportMenuDetailReady"
          :reportMenuId="this.reportMenuId"
          :disabled="isNoSelectedProject"
        />
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="nameTh">{{ lang.nameTh }}</label>
        <PvInputText
          id="nameTh"
          type="text"
          class="inputfield w-full mt-1"
          v-model="nameThai"
          :class="{ 'p-invalid': nameThaiError }"
          @keypress="nameThaiError = false"
          :disabled="isNoSelectedProject"
        />
        <small v-if="nameThaiError" id="nameThai-help" class="p-error">{{lang.validateNameThai
        }}</small>
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="nameEn">{{ lang.nameEn }}</label>
        <PvInputText
          id="nameEn"
          type="text"
          class="inputfield w-full mt-1"
          v-model="nameEng"
          :class="{ 'p-invalid': nameEngError }"
          @keypress="nameEngError = false"
          :disabled="isNoSelectedProject"
        />
        <small v-if="nameEngError" id="nameEng-help" class="p-error">{{lang.validateNameEng
        }}</small>
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="path">{{ lang.component }}</label>
        <PvInputText
          id="component"
          type="text"
          class="inputfield w-full mt-1"
          v-model="component"
          :class="{ 'p-invalid': componentError }"
          @keypress="componentError = false"
          :disabled="isNoSelectedProject"
        />
        <small v-if="componentError" id="component-help" class="p-error">{{lang.validateComponent
        }}</small>
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="path">{{ lang.path }}</label>
        <PvInputText
          id="path"
          type="text"
          class="inputfield w-full mt-1"
          v-model="path"
          :class="{ 'p-invalid': pathError }"
          @keypress="pathError = false"
          :disabled="isNoSelectedProject"
        />
        <small v-if="pathError" id="path-help" class="p-error">{{lang.validatePath
        }}</small>
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="activeStatus">{{ lang.activeStatus }}</label>
        <PvDropdown
          class="inputfield w-full mt-1"
          :options="activeStatus"
          :showClear="clearActiveStatus"
          optionLabel="name"
          optionValue="code"
          v-model="selectActiveStatus"
          :disabled="isNoSelectedProject"
        />
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="settingSignature">{{ lang.settingSignature }}</label>
        <PvDropdown
          class="inputfield w-full mt-1"
          :options="signatureSetting"
          optionLabel="name"
          optionValue="code"
          v-model="selectedSignatureSetting"
        />
      </div>
    </section>
    <template #footer>
      <PvButton :label="lang.save" icon="pi pi-check" @click="save" :disabled="isDisableSave"/>
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        @click="closeDialog"
        class="p-button-text"
      />
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetProject from "../../../hooks/getProject.js";
import ReportMenuDetailAutoComplete from "./ReportMenuDetailAutoComplete.vue";
export default {
  emits:["close", "saved"],
  components: {
    ReportMenuDetailAutoComplete
  },
  props: {     
    reportMenuDetail: {
      type: Object,
    },
    reportMenuId: String,
    openDialog: {
      type: String,
    },
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass } = useTextStyle();

    const {
      isSharedProjectReady,
      getProjectById,
      searchText,
      filteredProjects,
    } = useGetProject();

    const store = useStore();

    const fieldCodeAndName = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return "codeAndNameTh";
      } else {
        return "codeAndNameEn";
      }
    });

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.admin.report };
      } else {
        return { ...lang.en.base, ...lang.en.admin.report };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      isSharedProjectReady,
      getProjectById,
      searchText,
      filteredProjects,
      fieldCodeAndName,
    };
  },
  data() {
    return {
      sequence: "",
      categories: [
        { name: "All"},
        { name: "Project"},
      ],
      selectCategory: "All",
      clearCategory: false,
      selectedProject: null,
      nameThai: "",
      nameEng: "",
      component: "",
      path: "",
      activeStatus: [
        { name: this.lang.active, code: "Y" },
        { name: this.lang.inActive, code: "N" },
      ],
      selectActiveStatus: "Y",
      clearActiveStatus: false,
      isProjectLoading: true,
      sequenceError: false,
      nameThaiError: false,
      nameEngError: false,
      componentError: false,
      pathError: false,
      projectError: false,
      selectedReportMenuDetail: null,
      isReportMenuDetailReady: false,
      isShowProjectType: false,
      projectName: "",
      isNoSelectedProject: false,
      signatureSetting: [
        { name: this.lang.yes, code: "Y" },
        { name: this.lang.no, code: "N" },
      ],
      selectedSignatureSetting: "N", 
    };
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.initiateData();
      this.$emit("close");
    },
    initiateData() {
      this.sequence = "";
      this.selectCategory = "All";
      this.selectedProject = null;
      this.nameThai = "";
      this.nameEng = "";
      this.component = "";
      this.path = "";
      this.selectActiveStatus = "Y";
      this.sequenceError = false;
      this.nameThaiError = false;
      this.nameEngError = false;
      this.componentError = false;
      this.pathError = false;
      this.projectError = false;
      this.selectedReportMenuDetail = null;
      this.isReportMenuDetailReady = false;
      this.isShowProjectType = false;
      this.projectName = "";
      this.isNoSelectedProject = false;
      this.selectedSignatureSetting = "N"
      this.setLoading(false);
    },
    setData() {
      // console.log(this.reportMenuDetails);
      if(this.reportMenuDetail) {
        if(this.reportMenuDetail.category == "Project") {
          this.isShowProjectType = true;
        }
        this.sequence = this.reportMenuDetail.sequence;
        this.selectCategory = this.reportMenuDetail.category;
        this.nameThai = this.reportMenuDetail.nameThai;
        this.nameEng = this.reportMenuDetail.nameEnglish;
        this.component = this.reportMenuDetail.component;
        this.path = this.reportMenuDetail.path;
        this.selectActiveStatus = this.reportMenuDetail.isActive;
        this.selectedReportMenuDetail = this.reportMenuDetailAll.find((report) => report.reportMenuDetailId === this.reportMenuDetail.reportMenuDetailId);
        this.selectedSignatureSetting = this.reportMenuDetail.isSettingSignature;
        this.setDataProject();
      }
    },
    setDataProject() {
      if(this.isSharedProjectReady && this.openDialog) {
        this.selectedProject = this.filteredProjects.find((project) => project.id === this.reportMenuDetail.projectId);
        if(this.selectCategory != "All" && !this.selectedProject) {
          this.isNoSelectedProject = true;
          this.projectName = this.reportMenuDetail.projectCode + " : " + this.reportMenuDetail.projectNameTh;
        }
      }
    },
    validateData() {
      if (!this.sequence) {
        this.sequenceError = true;
        return true;
      }

      if(this.selectCategory == "Project" && !this.selectedProject){
        this.projectError = true;
        return true;
      }

      if (!this.nameThai) {
        this.nameThaiError = true;
        return true;
      }

      if (!this.nameEng) {
        this.nameEngError = true;
        return true;
      }

      // if (!this.component) {
      //   this.componentError = true;
      //   return true;
      // }

      // if (!this.path) {
      //   this.pathError = true;
      //   return true;
      // }

      return false;
    },
    async save() {
      if (this.validateData()) {
        this.setLoading(false);
        return;
      }
      this.setLoading(true);
      try {
        let payload = {
          reportMenuId: this.reportMenuId,
          sequence: this.sequence,
          category: this.selectCategory,
          projectId: this.selectedProject ? this.selectedProject.id : "",
          nameThai: this.nameThai,
          nameEnglish: this.nameEng,
          component: this.component ? this.component : "",
          path: this.path ? this.path : "",
          isActive: this.selectActiveStatus,
          reportMenuDetailId: this.selectedReportMenuDetail ? this.selectedReportMenuDetail.id : null,
          isSettingSignature: this.selectedSignatureSetting,
          type: "Add",
        }

        if (this.reportMenuDetail) {
          payload = { ...payload, id: this.reportMenuDetail.id, type: "Update" };
        }

        // console.log("save", payload);

        await this.$store.dispatch("reportMenuDetail/createOrUpdateReportMenuDetail", payload);

        this.$toast.add({
          severity: "success",
          summary: this.lang.saveSuccess,
          detail: "",
          life: 5000,
        });

      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 5000,
        });
      }

      this.setLoading(false);
      this.$emit("saved");
      this.closeDialog();
      
    },
    searchProject(event) {
      this.searchText = event.query;
    },
    reportMenuDetailReady() {
      this.isReportMenuDetailReady = true;
    },
    selectedCategory() {
      if(this.selectCategory == "Project") {
        this.isShowProjectType = true;
        this.isNoSelectedProject = false;
        this.projectName = "";
      } else {
        this.isShowProjectType = false;
      }
    },
    checkShouldSetData() {
      if(this.isReportMenuDetailReady) {
        this.setData();
      }
    },
  },
  computed: {
    title() {
      let result = this.lang.addNewReportMenuDetail;
      if(this.reportMenuDetail) {
        result = this.lang.editReportMenuDetail;
      }
      return result;
    },
    reportMenuDetails() {
      return this.$store.getters["reportMenuDetail/reportMenuDetails"];
    },
    isDisableSave() {
      if(this.selectCategory == "Project" && !this.isSharedProjectReady || this.isNoSelectedProject) {
        return true;
      }
      return false;
    },
    reportMenuDetailAll() {
      let result = this.$store.getters["reportMenuDetail/reportMenuDetailDropDown"];
      result = result.filter((detail) => detail.reportMenuId.toString() == this.reportMenuId );
      return result;
    },
  },
  watch: {
    // reportMenuDetail(curValue) {
    //   if (curValue) {
    //     this.setData();
    //   }
    // },
    isSharedProjectReady() {
      this.isProjectLoading = false;
      if(this.openDialog) {
        this.setDataProject();
      }
    },
    selectCategory(curValue) {
      if(curValue == "All") {
        this.selectedProject = null;
      }
    },
    openDialog() {
      this.setData();
    },
    isReportMenuDetailReady() {
      this.checkShouldSetData();
    },
  },
};
</script>
