export default {
  uploadFile: "อัปโหลดไฟล์",
  dragFile: "ลากไฟล์มาวาง หรือ ",
  addFile: "เพิ่มไฟล์ใหม่",
  importFileDesc: "กรุณาเพิ่มไฟล์ทีละ 1 ไฟล์ ที่มีนามสกุล .XLSX เท่านั้น หรือ ",
  downloadfile: "ดาวน์โหลดตัวอย่างไฟล์",
  fileName: "ชื่อเอกสาร",
  validateLimitFile: "สามารถอัปโหลดไฟล์ได้ทีละ 1 ไฟล์",
  validateFileType: "ไม่สามารถอัปโหลดไฟล์ :name ได้",
  validateImportFile: "สกุลไฟล์ที่อนุญาตมีดังนี้ .XLSX เท่านั้น",
  validateImportNoData: "เอกสาร : :file ไม่มีข้อมูล",

  validateImportMaxData:"ไม่สามารถนำเข้าข้อมูลได้เกิน :maxData รายการ",
  validateImportMaxColumn: "ไม่สามารถนำเข้าข้อมูลได้ เนื่องจำนวนไม่เท่ากับ :maxColumn แถว",
  validateImportColumnNotMatch: "ลำดับและชื่อคอลัมน์ไม่ตรงกับระบบ",
  
  unitCode: "เลขที่ยูนิต/ห้อง*",
  validateCannotBeBlank: "ข้อมูลแถวที่ :row :name ห้ามเป็นค่าว่าง",
  validateLimitText: "ข้อมูลแถวที่ :row :name ระบุความยาวไม่เกิน :limit ตัวอักษร",

  toolbarTitle: "นำเข้าข้อมูลสมาชิก",
  title: "นำเข้าข้อมูลสมาชิก",
  statusImport: "สถานะการนำเข้า",
  reason: "เหตุผล",
  customerCode: "รหัสสมาชิก/ลูกค้า",
  cannotImport: "ไม่สามารถนำเข้ารายการได้",
  noItemToImportWording: "ไม่สามารถบันทึกได้ เนื่องจากไม่มีรายการรอบันทึก",
  editAndReuploadWording: "กรุณาแก้ไขไฟล์ และอัปโหลดใหม่อีกครั้ง",
  wordingConfirmImportUnit: "จาก :total รายการ ท่านสามารถสร้างรายการได้เพียง :value รายการ เนื่องจากมีรายการค่าไม่ถูกต้องจำนวน :invalidValue รายการ",
  creatingUnit: "กำลังสร้างยูนิต/ห้อง",
  generating: "กำลังสร้าง...",
  residentType: "ประเภทการอยู่อาศัย",
  stayDate: "วันที่เข้าอยู่",
  moveDate: "วันที่ย้ายออก",  
  residentInformation: "ข้อมูลผู้เช่า/ผู้อยู่อาศัย",
  unitResidentDuplicate: "รหัสสมาชิก/ลูกค้า และประเภทการอยู่อาศัย และวันที่เข้าอยู่",
  validateDuplicateInDatabase: "':field' ซ้ำ ':table'", 
  validateStayDateAndMoveDate: "วันที่เข้าอยู่ มากกว่า วันที่ย้ายออก",
};
