import { useStore } from "vuex";
import { ref, computed } from "vue";
export default function useGetAddress() {
    const store = useStore();
    let searchTextAddress = ref(null);

    const address = computed(() => {
        return store.getters["shared/address"];
    });

    const addressIsLastPage = computed(() => {
        return store.getters["shared/addressIsLastPage"];
    });

    let isSharedAddressReady = ref(false);

    const getAllAddress = async function () {
        if (!addressIsLastPage.value) {
          await store.dispatch("shared/getAllAddress");
        }
        isSharedAddressReady.value = true;
    };

    return {
        searchTextAddress,
        address,
        isSharedAddressReady,
        getAllAddress,
    }
}