<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
    :header="reportName"
  >  
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ this.reportName }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>
    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="status">{{ lang.status }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="status"
            optionLabel="name"
            optionValue="code"
            v-model="selectedStatus"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="selectedPayType">{{ lang.type }}</label>
          <base-payment-method-auto-complete
            id="selectedPayType"
            v-model="selectedPayType"
            class="inputfield w-full mt-1"
            @load-finish="paymentTypeDataReady"
            :typeInOut="payType"
            :class="{ 'p-invalid': payTypeError }"
						@item-select="payTypeError = false"

          />
          <small v-if="payTypeError" id="unit-help" class="p-error">{{
            lang.validateType
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="selectedBookbank">{{ lang.bankPayoutStart }}</label>
          <base-book-bank-auto-complete
            id="selectedBookbank"
            v-model="selectedBookBankStart"
            class="inputfield w-full mt-1"
            @load-finish="selectBookBankStartReady"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="selectedBookbank">{{ lang.bankPayoutEnd }}</label>
          <base-book-bank-auto-complete
            id="selectedBookbank"
            v-model="selectedBookBankEnd"
            class="inputfield w-full mt-1"
            @load-finish="selectBookBankEndReady"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateType">{{ lang.dateType }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="dateType"
            optionLabel="name"
            optionValue="code"
            v-model="selectedDateType"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="sortBy">{{ lang.sortBy }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="sortBy"
            optionLabel="name"
            optionValue="code"
            v-model="selectedSortBy"
          />
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateStart">{{ lang.dateStart }}</label>
          <PvCalendar
            id="dateStart"
            v-model="dateStart"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateEnd">{{ lang.dateEnd }}</label>
          <PvCalendar
            id="dateEnd"
            v-model="dateEnd"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="codeStart">{{ lang.codeStart }}</label>
          <PvInputText
            id="codeStart"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="6501xxxxx"
            v-model="codeStart"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="codeEnd">{{ lang.codeEnd }}</label>
          <PvInputText
            id="codeEnd"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="6501xxxxx"
            v-model="codeEnd"
          />
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pb-0">
          <label for="supplier">{{ lang.supplierStart }}</label>
          <base-supplier-auto-complete
            id="supplier"
            v-model="selectSupplierStart"
            class="inputfield w-full mt-1"
            @load-finish="selectSupplierStartReady"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pb-0">
          <label for="supplier">{{ lang.supplierEnd }}</label>
          <base-supplier-auto-complete
            id="supplier"
            v-model="selectSupplierEnd"
            class="inputfield w-full mt-1"
            @load-finish="selectSupplierEndReady"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText
            id="fileNameText"
            type="text"
            class="inputfield w-full mt-1"
            v-model="fileNameText"
          />
        </div>
      </section>
    </transition>
    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
        </div>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import useGetFormPrint from "../../hooks/getFormPrint.js";
import BasePaymentMethodAutoComplete from "../UI/BasePaymentMethodAutoComplete.vue";
import BaseBookBankAutoComplete from "../bank/BaseBookBankAutoComplete.vue";
import { useStore } from "vuex";
import { computed } from "vue";
import useTracking from "../../hooks/tracking";
import useReportRequestValidate from "../../hooks/reportRequestValidate.js";
export default {
  components: {
    BasePaymentMethodAutoComplete,
    BaseBookBankAutoComplete,
  },
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { getPreviewBlob } = useGetFormPrint();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getPreviewBlob,
			addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      status: [
        { name: "All", code: "All" },
        { name: "N : ในมือ", code: "N" },
        // { name: "B : ฝากธนาคาร", code: "B" },
        { name: "P : ผ่าน", code: "P" },
        { name: "R : คืน", code: "R" },
      ],
      selectedStatus: "All",

      payType: -1, // 1="PayIn" ,-1= "PayOut"
      isSharedPayTypeReady: false,
      selectedPayType: "",
      payTypeError: false,

      selectedBookBankStart: null,
      isBookBankStartReady: false,
      selectedBookBankEnd: null,
      isBookBankEndReady: false,

      dateType: [
        { name: "วันที่เอกสาร", code: "Date" },
        { name: "วันที่ครบกำหนด", code: "DueDate" },
        { name: "วันที่นำฝาก", code: "DepositDate" },
        { name: "วันที่ผ่าน/คืน", code: "PassDate" },
      ],
      selectedDateType: "Date",

      sortBy: [
        { name: "วันที่เอกสาร", code: "Date" },
        { name: "เลขที่เอกสาร", code: "Code" },
      ],
      selectedSortBy: "Date",

      dateStart: null,
      dateEnd: null,

      codeStart: "",
      codeEnd: "",

      selectSupplierStart: null,
      isSupplierStartReady: false,
      selectSupplierEnd: null,
      isSupplierEndReady: false,
      type: "PDF",
      fileNameText: "Payment",
    };
  },
  async created() {
    this.initiateData();

    this.setLoading(false);
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
    },
    paymentTypeDataReady() {
      this.isSharedPayTypeReady = true;
    },
    selectBookBankStartReady() {
      this.isBookBankStartReady = true;
    },
    selectBookBankEndReady() {
      this.isBookBankEndReady = true;
    },
    selectSupplierStartReady() {
      this.isSupplierStartReady = true;
    },
    selectSupplierEndReady() {
      this.isSupplierEndReady = true;
    },
    initiateData() {      
      this.$store.dispatch("bank/setTypeInOut", {type:this.payType});
      this.selectedStatus = "All";

      this.isSharedPayTypeReady = false;
      this.selectedPayType = "";
      this.payTypeError = false;

      this.selectedBookBankStart = null;
      this.isBookBankStartReady = false;
      this.selectedBookBankEnd = null;
      this.isBookBankEndReady = false;

      this.selectedDateType = "Date";
      this.selectedSortBy = "Date";

      let date = new Date();
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      this.selectSupplierStart = null;
      this.isSupplierStartReady = false;
      this.selectSupplierEnd = null;
      this.isSupplierEndReady = false;
      this.codeStart = "";
      this.codeEnd = "";
    },
    loadDataReady() {
      //   console.log("loadDataReady_isSharedPayTypeReady=",this.isSharedPayTypeReady);
      //   console.log("loadDataReady_isBookBankStartReady=",this.isBookBankStartReady);
      //   console.log("loadDataReady_isBookBankEndReady=",this.isBookBankEndReady);
      //   console.log("loadDataReady_isSupplierStartReady=",this.isSupplierStartReady);
      //   console.log("loadDataReady_isSupplierEndReady=",this.isSupplierEndReady);
      this.setLoading(true);
      if (
        this.isSharedPayTypeReady &&
        this.isBookBankStartReady &&
        this.isBookBankEndReady &&
        this.isSupplierStartReady &&
        this.isSupplierEndReady
      ) {
        this.setLoading(false);
      }
    },
    async download(typeReport) {
      this.type = typeReport;
      if (this.validateData()) {
        return;
      }
      this.setLoading(true);
      try {     
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
    validateData() {
      if (!this.selectedPayType) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateType,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.payTypeError = true;
          }
        });
        return true;
      }

      return false;
    },
  },
  computed: {
    payload() {
      let payload = {
        FileName: this.fileNameText + this.fileType,
        JasperPath: this.jasperPath + this.fileType,
        DocType: this.type,
        UserId: this.userId,
        PrintBy: this.userName,
          ProjectId: this.selectedProject.id,
          Status: this.selectedStatus,
          PayType: this.selectedPayType != null ? this.selectedPayType.code : "All",
          BookBankStart: this.selectedBookBankStart != null ? this.selectedBookBankStart.code : "All",
          BookBankEnd: this.selectedBookBankEnd != null ? this.selectedBookBankEnd.code : "All",
          DateType: this.selectedDateType,
          SortBy: this.selectedSortBy,
          DateStart: this.selectedDateStart,
          DateEnd: this.selectedDateEnd,
          SupplierStart: this.selectSupplierStart != null ? this.selectSupplierStart.code : "All",
          SupplierEnd: this.selectSupplierEnd != null ? this.selectSupplierEnd.code : "All",
          CodeStart: this.codeStart,
          CodeEnd: this.codeEnd,
        RequestId: 0,
        ReportName: "ReportPayment",
      };

      //  let parameter = {     
      //   ProjectId: this.selectedProject.id,
      //   Status: this.selectedStatus,
      //   PayType: this.selectedPayType != null ? this.selectedPayType.code : "All",
      //   BookBankStart: this.selectedBookBankStart != null ? this.selectedBookBankStart.code : "All",
      //   BookBankEnd: this.selectedBookBankEnd != null ? this.selectedBookBankEnd.code : "All",
      //   DateType: this.selectedDateType,
      //   SortBy: this.selectedSortBy,
      //   DateStart: this.selectedDateStart,
      //   DateEnd: this.selectedDateEnd,
      //   SupplierStart: this.selectSupplierStart != null ? this.selectSupplierStart.code : "All",
      //   SupplierEnd: this.selectSupplierEnd != null ? this.selectSupplierEnd.code : "All",
      // };
      // let payload = {
      //   docType: this.type,
      //   path: this.jasperPath + this.fileType,
      //   fileName: this.fileNameText,
      //   parameter: JSON.stringify(parameter),
      // };

      return payload;
    },
    reportName() {
      if (this.lang.language === "Thai") {
        return this.reportObj.nameThai;
      } else {
        return this.reportObj.nameEnglish;
      }
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    fileType() {
      let fileType = this.type == "PDF" ? ".pdf" : ".xlsx";
      return fileType;
    },
    jasperPath() {
      let result = this.reportObj.path ;
      if(this.type == "EXCEL" ){
        result = this.reportObj.path + "_excel";      
      }

      return result;
    },
    fileBlob() {
      return this.$store.getters["shared/blob"];
    },
  },
  watch: {
    reportObj() {
      this.initiateData();
      this.loadDataReady();
    },
    isSharedPayTypeReady() {
      this.loadDataReady();
    },
    isBookBankStartReady() {
      this.loadDataReady();
    },
    isBookBankEndReady() {
      this.loadDataReady();
    },
    isSupplierStartReady() {
      this.loadDataReady();
    },
    isSupplierEndReady() {
      this.loadDataReady();
    },
    // selectedPayType() {
    //   this.payTypeError = false;
    // },
  },
};
</script>
