export default {
  async getRefundGuaranteeList(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/RefundGuarantee";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + encodeURIComponent(JSON.stringify(payload.where));
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
        // console.log(responseData);
        const error = new Error(responseData.message || "Failed to get refund guarantee");
        throw error;
    }

    // console.log("getRefundGuaranteeList",responseData);

    context.commit("setRefundGuaranteeList", responseData);
  },
  async getRefundGuaranteeById(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + '/RefundGuarantee/' + payload;
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
        // console.log(responseData);
        const error = new Error(responseData.message || `Failed to get refund guarantee id : ${payload}`);
        throw error;
    }

    // console.log("getRefundGuaranteeById", responseData);

    context.commit("setSelectedRefundGuarantee", responseData.data);
  },
  resetRefundGuarantee(context) {
    context.commit("setSelectedRefundGuarantee", null);
  },
  async print(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL +"/RefundGuarantee/Print";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(payload),
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });  
    
    let urlBlob = ''
    let blob = await response.blob();
    urlBlob = window.URL.createObjectURL(blob);
    context.commit('setBlob', urlBlob);
  },
  async cancelRefundGuarantee(context,payload){
    let url = process.env.VUE_APP_BACKEND_URL + `/RefundGuarantee/Cancel`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to cancel refund guarantee');
      throw error;
    }
    
    context.commit("setSelectedRefundGuarantee", responseData.data);
  },
  async getPendingList(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/RefundGuarantee/GetPendingByUnit";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
        // console.log(responseData);
        const error = new Error(responseData.message || "Failed to get pending");
        throw error;
    }

    // console.log("getPendingList",responseData);

    context.commit("setPendingList", responseData);
  },
  async getAllPendingList(context, payload) {
		while (!context.getters.pendingListIsLastPage) {
		  let where = payload.where;
		  const setPayload = {
			  page: context.getters.pendingListNextPage || 1,
			  where,
		  };
		  await context.dispatch("getPendingList", setPayload);
		}
	},
  resetPendingList(context) {
    context.commit("resetPendingList");
  },
  async createOrUpdateRefundGuarantee(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + `/RefundGuarantee/${payload.type}`;
    const fallbackErrorMessage = `Failed to ${payload.type} refund guarantee`
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: payload.type === 'Add' ? 'post' : 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }
    // console.log(responseData);

    context.commit("setSelectedRefundGuarantee", responseData.data);
  },
};
