export default {
    resetImportUnitResidents(state) {
      state.importUnitResidents = [];
    },
    setImportUnitResidents(state, payload) {
      state.importUnitResidents = payload;
    },    
    setUnits(state, payload) {
      state.units = payload.data;
    },
    setCustomers(state, payload) {
      state.customers = payload.data;
    },
  };
  