<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateStart">{{ lang.dateStart }}</label>
          <PvCalendar
            id="dateStart"
            v-model="dateStart"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateStartError }"
            @date-select="dateSelect"
          />
          <small v-if="dateStartError" id="date-start-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateEnd">{{ lang.dateEnd }}</label>
          <PvCalendar
            id="dateEnd"
            v-model="dateEnd"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateEndError }"
            @date-select="dateSelect"
          />
          <small v-if="dateEndError" id="date-end-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="codeStart">{{ lang.codeStart }}</label>
          <PvInputText
            id="codeStart"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="6501xxxxx"
            v-model="codeStart"
            :class="{ 'p-invalid': codeStartError }"
            @keypress="codeStartError = false"
          />
          <small v-if="codeStartError" id="code-start-help" class="p-error">{{
            lang.validateCodeStart
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="codeEnd">{{ lang.codeEnd }}</label>
          <PvInputText
            id="codeEnd"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="6501xxxxx"
            v-model="codeEnd"
            :class="{ 'p-invalid': codeEndError }"
            @keypress="codeEndError = false"
          />
          <small v-if="codeEndError" id="code-end-help" class="p-error">{{
            lang.validateCodeEnd
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="supplierStart">{{ lang.supplierStart }}</label>
          <base-supplier-auto-complete
            id="supplierStart"
            v-model="selectedSupplierStart"
            class="inputfield w-full mt-1"
            @load-finish="supplierDataReady"
            :isLoadingData="true"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="supplierEnd">{{ lang.supplierEnd }}</label>
          <base-supplier-auto-complete
            id="supplierEnd"
            v-model="selectedSupplierEnd"
            class="inputfield w-full mt-1"
            :isLoadingData="false"
          />
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText
            id="fileNameText"
            type="text"
            class="inputfield w-full mt-1"
            v-model="fileNameText"
          />
        </div>
      </section>
    </transition>
    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-6 md:col-6 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
        </div>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useFormat from "../../../hooks/format.js";
import useTextStyle from "../../../hooks/textStyle.js";
import { useStore } from "vuex";
import { ref, computed, onMounted, watch } from "vue";
import useTracking from "../../../hooks/tracking";
import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "toast-request"],
  setup(props, { emit }) {
    const { lang } = useLang();
    const { formatDateToISOString } = useFormat();
    const { textFormClass } = useTextStyle();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
        };
      }
    });

    let type = ref("PDF");
    let fileNameText = ref("payment_request_cover_sheet");
    let dateStart = ref(null);
    let dateEnd = ref(null);
    let dateStartError = ref(false);
    let dateEndError = ref(false);

    let codeStart = ref("");
    let codeEnd = ref("");
    let codeStartError = ref(false);
    let codeEndError = ref(false);

    let selectedSupplierStart = ref(null);
    let selectedSupplierEnd = ref(null);

    onMounted(() => {
      setLoading(true);
      initiateData();
    });

    const title = computed(() => {
      if (store.getters.selectedLanguage === "TH") {
        return props.reportObj.nameThai;
      } else {
        return props.reportObj.nameEnglish;
      }
    });
    const selectedDateStart = computed(() => {
      let data = null;
      if (dateStart.value) {
        data = formatDateToISOString(dateStart.value);
      }
      return data;
    });
    const selectedDateEnd = computed(() => {
      let data = null;
      if (dateEnd.value) {
        data = formatDateToISOString(dateEnd.value);
      }
      return data;
    });
    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const userId = computed(() => {
      return store.getters.userId;
    });
    const userName = computed(() => {
      return store.getters.userName;
    });
    const fileType = computed(() => {
      return type.value == "PDF" ? ".pdf" : ".xlsx";
    });
    const fileName = computed(() => {
      return fileNameText.value + fileType.value;
    });
    const payload = computed(() => {
      let payload = {
        ProjectId: selectedProject.value.id,
        FileName: fileName.value,
        JasperPath: props.reportObj.path + fileType.value,
        DocType: type.value,
        UserId: userId.value,
        PrintBy: userName.value,
        RequestId: 0,
        ReportName: "PaymentRequestCoverSheet",
        DateStart: selectedDateStart.value ?? "",
        DateEnd: selectedDateEnd.value ?? "",
        CodeStart: codeStart.value,
        CodeEnd: codeEnd.value,
        SupplierCodeStart: selectedSupplierStart.value
          ? selectedSupplierStart.value.code
          : "",
        SupplierCodeEnd: selectedSupplierEnd.value
          ? selectedSupplierEnd.value.code
          : "",
        ReportMenuDetailId: props.reportObj.id,
      };
      return payload;
    });

    const setLoading = (bool) => {
      store.dispatch("setLoading", { value: bool });
    };
    const initiateData = () => {
      type.value = "PDF";
      fileNameText.value = "payment_request_cover_sheet";

      let date = new Date();
      dateStart.value = new Date(date.getFullYear(), date.getMonth(), 1);
      dateEnd.value = new Date();
      dateStartError.value = false;
      dateEndError.value = false;

      codeStart.value = "";
      codeEnd.value = "";
      codeStartError.value = false;
      codeEndError.value = false;

      selectedSupplierStart.value = null;
      selectedSupplierEnd.value = null;
    };
    const closeDialog = () => {
      emit("close");
      initiateData();
    };
    const validateData = () => {
      let returnStatus = false;
      if (
        selectedDateStart.value == null &&
        selectedDateEnd.value == null &&
        codeStart.value == "" &&
        codeEnd.value == ""
      ) {
        dateStartError.value = true;
        dateEndError.value = true;
        codeStartError.value = true;
        codeEndError.value = true;
        returnStatus = true;
      }
      else if ( codeStart.value != "" && codeEnd.value == "" ) {
        codeEndError.value = true;
        returnStatus = true;
      }
      else if ( codeStart.value == "" && codeEnd.value != "" ) {
        codeStartError.value = true;
        returnStatus = true;
      }

      return returnStatus;
    };
    const supplierDataReady = () => {
      setLoading(false);
    };
    const dateSelect = () => {
      dateStartError.value = false;
      dateEndError.value = false;
      codeStartError.value = false;
      codeEndError.value = false;
    };

    watch(props.reportObj, function () {
      initiateData();
    });

    return {
      lang: mergeLang,
      textFormClass,
      addReportTracking,
      reportPrintOrder,

      selectedProject,
      title,
      type,
      payload,

      fileNameText,
      dateStart,
      dateStartError,
      dateEnd,
      dateEndError,

      codeStart,
      codeStartError,
      codeEnd,
      codeEndError,

      selectedSupplierStart,
      selectedSupplierEnd,

      setLoading,
      closeDialog,
      validateData,
      supplierDataReady,
      dateSelect,
    };
  },
  methods: {
    async download(typeReport) {
      this.type = typeReport;
      if (this.validateData()) {
        return;
      }

      this.setLoading(true);
      try {
        // console.log(this.payload);
        this.addReportTracking(
          this.payload,
          this.reportObj,
          this.selectedProject,
          this.$route.name
        );
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
  },
};
</script>
