<template>
  <base-toolbar
    :title="lang.title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoSave="askForSave"
    @gotoCancel="askForCancel"
    :hideBackButton="isHideBack"
  ></base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :classTitle="baseDialogClassTitle"
      :titleConfirm="titleConfirmDialog"
      :wordingConfirm="wordingConfirmDialog"
      :dataButtons="dataButtonDialog"
      @close="closeValidateDialog"
      @confirm-cancel="cancel"
      @confirm-save="save"
    ></base-dialog>

    <base-toast-report-request
      :showToast="showToast"
      :toastMessage="toastMessage"
      :toastLink="toastLink"
      :actionToast="actionToast"
      :isNewTab="true"
    ></base-toast-report-request>

    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5">
      <div class="flex justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.noticeChecklist }}</label>
        <PvButton class="p-button-outlined m-0" @click="printConfirmNoticePenalty" :disabled="!this.isRolePrint">
          <icon-printer iconColor="primary" width="16" height="16" />
          <span class="p-button-label font-normal pl-2">{{
            lang.printConfirmNoticePenalty
          }}</span>
        </PvButton>
      </div>

      <PvDivider></PvDivider>

      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >
        <div class="col-4 pr-2 pl-0">
          <span class="p-input-icon-left w-full">
            <i class="pi pi-search" />
            <PvInputText
              class="inputfield w-full"
              v-model="searchText"
              :placeholder="this.lang.search"
              @keyup.enter="getNotices"
            />
          </span>
        </div>
        <PvButton @click="getNotices">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>

      <div class="my-4">
        <PvDataTable
          :value="notices"
          v-model:selection="selectedNotice"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoPreview(this.selectedNotice.id)"
          scrollable
        >
          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
          <PvColumn
            field="code"
            :header="lang.columnCode"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left font-normal"
            sortable
          >
            <template #body="slotProps">
              <u>{{ slotProps.data.code }}</u>
            </template>
          </PvColumn>
          <PvColumn
            field="date"
            :header="lang.columnDate"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left font-normal"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.date) }}
            </template>
          </PvColumn>
          <PvColumn
            field="unitCode"
            :header="lang.columnUnitCode"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left font-normal"
          ></PvColumn>
          <PvColumn
            field="unitAddressNo"
            :header="lang.columnAddressNo"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left font-normal"
          ></PvColumn>
          <PvColumn
            field="customerName"
            :header="lang.columnCustomerName"
            style="min-width: 20rem"
            headerClass="h-left"
            bodyClass="text-left font-normal"
          ></PvColumn>
          <PvColumn
            field="totalAmount"
            :header="lang.columnNetAmount"
            style="min-width: 10rem"
            headerClass="h-right"
            bodyClass="text-right font-normal"
            sortable
          >
            <template #body="slotProps">
              <span class="text-right w-full">{{
                formatCurrency(slotProps.data.totalAmount)
              }}</span>
            </template>
          </PvColumn>
          <PvColumn
            style="min-width: 4rem"
            headerClass="h-right"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            :frozen="true"
          >
            <template #body="slotProps">
              <PvButton
                class="p-button-secondary p-button-text py-0 px-0"
                @click="gotoPreview(slotProps.data.id)"
              >
                <icon-eye iconColor="secondary" width="20" height="20" />
              </PvButton>
            </template>
          </PvColumn>
          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              class="p-pagination-radius"
            >
              <template #start="slotProps">
                {{ lang.page }}:
                {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
              </template>
            </PvPaginator>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetRoleByBook from "../../../hooks/getRoleByBook.js";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
export default {
  emits: ["toast-request"],
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const {
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getCurrentPeriod,
    } = useFormat();
    const store = useStore();

    const { getPreviewBlob } = useGetFormPrint();

    const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();

    const { reportPrintOrder } = useReportRequestValidate();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.calculateNotice.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.calculateNotice.listing,
        };
      }
    });

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getCurrentPeriod,
      isHideBack,
      getRoleByBookByCode,
      isSharedRoleByBookReady,
      getPreviewBlob,
      reportPrintOrder,
    };
  },
  data() {
    return {
      isLoading: false,
      searchText: "",
      selectedNotice: null,
      selectedPage: 1,
      rowsPerPage: 10,

      showValidateDialog: false,
      titleConfirmDialog: "",
      wordingConfirmDialog: "",
      baseDialogClassTitle: "",
      confirmDialog: false,
      isSaved: false,
      isConfirmSave: false,
      isCanceled: false,
      isConfirmCancel: false,

      showToast: 0,
      toastMessage: {},
      toastLink: "",
      actionToast: "",
      isprinted: false,
      calculateDate: "",
    };
  },
  async mounted() {
    await this.setDataQueryParam();
    await this.roleByBookDataReady();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    async getNotices() {
      this.isLoading = true;
      try {
        let where = {
          projectId: this.selectedProject.id,
          documentBookId: this.roleByBook.documentBookId,
          searchText: this.searchText,
        };

        const payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          where,
        };

        // console.log(where);

        await this.$store.dispatch("arCalculateNotice/getNotices", payload);

        // console.log("notices", this.notices);
      } catch (error) {
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = error;
        this.confirmDialog = true;
        this.showValidateDialog = true;
      }

      if(!this.notices || this.notices.length == 0) {
        this.$router.push({name: "ar-calculate-notice"});
      }

      this.isLoading = false;
    },
    gotoPreview(id) {
      //   console.log(id);
      this.$router.push({ name: "ar-edit-notice-wait-for-approve", params: {id: id}, query: {calculateDate: this.calculateDate, printed: this.isprinted} });
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getNotices();
    },
    askForSave() {
      this.titleConfirmDialog = this.lang.approveAll;
      this.wordingConfirmDialog = this.lang.wordingConfirmSaveNotice;
      this.isConfirmSave = true;
      this.showValidateDialog = true;
    },
    async save() {
      this.setLoading(true);
      this.showValidateDialog = false;
      this.confirmDialog = true;
      try {
        const payload = {
          projectId: this.selectedProject.id,
          documentBookId: this.roleByBook.documentBookId,
        };

        // console.log("save payload", payload);

        await this.$store.dispatch(
          "arCalculateNotice/addGenerateNotice",
          payload
        );

        if (this.generatedNotice) {
          this.isSaved = true;
          this.baseDialogClassTitle = "p-text-success";
          this.titleConfirmDialog = this.lang.saveSuccess;
          this.wordingConfirmDialog = this.lang.wordingSaveSuccess;
          this.showValidateDialog = true;
        }
      } catch (error) {
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = error;
        this.confirmDialog = true;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    askForCancel() {
      this.titleConfirmDialog = this.lang.cancelNotice;
      this.wordingConfirmDialog = this.lang.wordingConfirmCancelNotice;
      this.isConfirmCancel = true;
      this.showValidateDialog = true;
    },
    async cancel() {
      this.setLoading(true);
      this.showValidateDialog = false;
      this.confirmDialog = true;
      try {
        const payload = {
          projectId: this.selectedProject.id,
          documentBookId: this.roleByBook.documentBookId,
        };

        await this.$store.dispatch(
          "arCalculateNotice/cancelGeneratedNotice",
          payload
        );

        if (this.noticeIsCanceled) {
          this.isCanceled = true;
          this.baseDialogClassTitle = "p-text-success";
          this.titleConfirmDialog = this.lang.cancelSuccess;
          this.wordingConfirmDialog = this.lang.saveSuccess;
          this.showValidateDialog = true;
        }
      } catch (error) {
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = error;
        this.confirmDialog = true;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    closeValidateDialog() {
      if (this.isCanceled || this.isSaved) {
        this.$router.push({name: "ar-calculate-notice"});
      }
      this.baseDialogClassTitle = "";
      this.confirmDialog = false;
      this.isCanceled = false;
      this.isConfirmCancel = false;
      this.isConfirmSave = false;
      this.isSaved = false;
      this.showValidateDialog = false;
    },
    async roleByBookDataReady() {
      if(this.isSharedRoleByBookReady) {
        await this.getNotices();
      }
    },
    async printConfirmNoticePenalty() {
      try {
        const response = await this.reportPrintOrder(this.payloadConfirmNoticePenaltyReport);

        if (response) {
          this.toastMessage = {
            severity: "info",
            summary: this.lang.toastRequestTitle,
            detail: this.lang.toastRequestContent,
            life: 5000,
          };
          this.toastLink = this.lang.toastRequestLink;
          const today = new Date();
          this.showToast = today.getSeconds();
          this.isprinted = true;
          await this.$router.push({ name: "ar-notice-wait-for-approve", query: {calculateDate: this.calculateDate, printed: this.isprinted} });
        }

      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
    async setDataQueryParam() {
      this.isprinted = this.$route.query.printed ? this.$route.query.printed : this.queryParamPrinted;
      this.calculateDate = this.$route.query.calculateDate;
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    roleByBook() {
      return this.getRoleByBookByCode("NOTICE");
    },
    isRoleCancel() {
      if (this.roleByBook && this.roleByBook.cancel == "Y") {
        return true;
      }
      return false;
    },
    isRoleApprove() {
      if (this.roleByBook && this.roleByBook.approve == "Y") {
        return true;
      }
      return false;
    },
    isRoleInsert() {
      if ((this.roleByBook) && this.roleByBook.insert == "Y") {
        return true;
      }
      return false;
    },
    isRolePrint() {
      if ((this.roleByBook) && this.roleByBook.print == "Y") {
        return true;
      }
      return false;
    },
    notices() {
      return this.$store.getters["arCalculateNotice/notices"];
    },
    totalPage() {
      return this.$store.getters["arCalculateNotice/noticeTotalPage"];
    },
    currentPage() {
      return this.$store.getters["arCalculateNotice/noticeCurrentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    generatedNotice() {
      return this.$store.getters["arCalculateNotice/generatedNotice"];
    },
    noticeIsCanceled() {
      return this.$store.getters["arCalculateNotice/noticeIsCanceled"];
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "cancel",
          caption: this.lang.cancelNotice,
          class: "p-button-outlined p-button-danger mr-2",
          action: "gotoCancel",
          isShow: true,
          isDisable: !this.isRoleInsert,
        },
        {
          id: "save",
          caption: this.lang.approveAll,
          class: "mr-2",
          action: "gotoSave",
          isShow: true,
          isDisable: !this.isRoleInsert || !this.queryParamPrinted,
        },
      ];
      return dataButtons;
    },
    dataButtonDialog() {
      let dataButtonDialog = [];

      if (this.isConfirmCancel) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "w-full p-button-outlined p-button-secondary",
            action: "close",
          },
          {
            id: "cancel",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "confirm-cancel",
          },
        ];
      }

      if (this.isConfirmSave) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "w-full p-button-outlined p-button-secondary",
            action: "close",
          },
          {
            id: "cancel",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "confirm-save",
          },
        ];
      }

      if (this.confirmDialog) {
        dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.close,
            class: "w-full p-button-outlined p-button-secondary",
            action: "close",
          },
        ];
      }
      return dataButtonDialog;
    },
    queryParamPrinted() {
      return this.$route.query.printed == "true";
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
    payloadConfirmNoticePenaltyReport() {
      let payload = {
        ProjectId: this.selectedProject.id,
        DateStart: this.calculateDate,
        DateEnd: this.calculateDate,
        UnitCodeStart: "",
        UnitCodeEnd: "",
        ProductCodeStart: "",
        ProductCodeEnd: "",
        PenaltyCodeStart: "",
        PenaltyCodeEnd: "",
        PeriodStart: "",
        PeriodEnd: "",
        IsExcel: "N",
        IsForApi: "true",
        FileName: "ConfirmNoticePenalty.pdf",
        JasperPath: "postgres/penalty/confirm_notice_penalty.pdf",
        DocType: "PDF",
        UserId: this.userId,
        PrintBy: this.userName,
        RequestId: 0,
        ReportName: "ConfirmNoticePenalty",
      }
      return payload;
    },
  },
  watch: {
    isSharedRoleByBookReady() {
      this.roleByBookDataReady();
    },
  },
};
</script>
