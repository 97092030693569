export default {
    setDocumentBooks(state, payload){
        let documentBooks = [...state.documentBooks];
        for (const documentBook of payload.documentBooks) {
            if(documentBooks.findIndex(c => c.id === documentBook.id) < 0){
                documentBooks.push(documentBook);
            }
        }
        state.documentBooks = documentBooks;
        state.currentPage = payload.currentPage;
        state.totalPage = payload.totalPage;
    },
    setAccountingPolicys(state, payload){
        state.accountingPolicys = payload
    },
    setPetterns(state, payload){
        state.petterns = payload
    },    
    resetDocumentBooks(state){
        state.documentBooks = [];
        state.currentPage = 0;
        state.totalPage = 0;
    },
    resetAccountingPolicys(state){
        state.accountingPolicys = [];
    },    
    setCopyAccountingPolicys(state, payload){
        state.copyAccountingPolicys = payload
    },
    resetCopyAccountingPolicys(state){
        state.copyAccountingPolicys = [];
    },
};