export default {
  documentBooks(state) {
    return state.documentBooks;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
  documentBookNextPage(state) {
    return state.currentPage + 1;
  },
  documentBookIsLastPage(_, getters) {
    return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
  },
  accountingPolicys(state) {
    return state.accountingPolicys;
  },
  copyAccountingPolicys(state) {
    return state.copyAccountingPolicys;
  },
  petterns(state) {
    return state.petterns;
  },
};
