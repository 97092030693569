export default {
	createBudget: "Create Budget",
    editBudget: "Edit Budget",
	settingBudget: "Setting Budget",

	columnAccountCode: "AccountCode",
	columnAccountName: "AccountName",
	columnTotal: "Total",
	searchNoDetail: "No results found. Please try different keywords.",
	periodRange: "Budget range",
	clearDataBudget :"Clear data",
	expandAll: "Expand All",
	collapseAll: "Collapse All",
	averageAmount: "Average Amount",
	confirmSelectedAccount: "confirm Selected Account",
	editSelectedAccount: "In your latest edit, there's a deletion of the previously selected account code. ",
	validateChangeAccount: "sure you want to save these changes?",
	deleteAccount: "Delete Account",
	validateDeleteAccount: "sure you want to delete account ?",
	approved: "Approved",
	advice: "Tip",
	tip: "You can scroll horizontally to see more table contents by holding down the Shift key and scrolling the mouse.",
	approve: "Approve",
    budgetSaved: "Save successfully",
	saveAndApprove: "Save And Approve",
	approveBudget: "Approve Budget",
	validateApproveBudget: "Are you sure you want to approve budget?",
	validateApproveWording: "After approve will not be able to edit the information",
	validateNotApproved: "Please save data before approve",
	validateNotApprovedWording: "Are you sure you want to save and approve budget?",
	approveSuccess: "Approve Success",
	validateClearBudgetWording: "Are you sure you want to clear budget?",
	cancelBudget: "Cancel Budget",
	validateCancelBudgetWording: "Are you sure you want to cancel budget?",
    cancelBudgetSuccess: "Cancel Success",
	budgetDetail: "Budget Detail",	
    periodBudgetFrom: "Period Budget From",
    periodBudgetTo: "To",
	createDate:"Create Date",
	updateDate:"Update Date", 
	cannotApprove: "Cannot approve",
	validateCannotApproveWording: "Since there are zero total in the :account which you have not entered value yet.",
	titleAverageAmount: "Insert average per month over :month months",
	labelAverageAmount: "Total amount for averaging",
	labelExplainAverageAmount: "Average per month",
	titleAverageAmountBudget: "Replace data with average amount",
	averageAmountBudgetWording: "Are you sure you want to average amount?",
	validateAverageAmount: "Please enter average amount",
	validateDateStartAndLastDate: "Date start must not be less than last approved date.",
	noItemToSaveBudgetWording: "The data cannot be save as the account code has not been added yet.",
	noItemToApproveBudgetWording: "The data cannot be approve as the account code has not been added yet.",
	noItemToClearBudgetWording: "The data cannot be cleared as the account code has not been added yet.",
	contentConfirmSaveAndActive: "Do you confirm to save the budget and update the active status?",
	dialogTitleUpdateActiveSuccess: "Activation successful",
	dialogContentUpdateActiveSuccess: "You have successfully activated",
	dialogTitleUpdateInactiveSuccess: "Deactivation successful",
	dialogContentUpdateInactiveSuccess: "You have successfully deactivated",
	dialogContentPeriodAlreadyActive: "Cannot update active status because the budget period overlaps with an already active budget.",
	dialogContentSaveBeforeActive: "Please save the budget before update the active status",
};
