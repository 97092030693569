<template>
  <base-container>
    <base-project-modal
      v-model:visible="showProjectModal"
      @close="closeDialog()"
      @confirm="showProjectModal = false"
    ></base-project-modal>
  </base-container>
</template>

<script>
export default {
  data() {
    return {
      showProjectModal: true,
    };
  },
  methods: {
    closeDialog() {
      this.showProjectModal = false;
      this.$store.dispatch("autoLogout");
    }
  }
};
</script>
