export default {
  setAssetRegister(state, payload) {
    // state.project.length = 0;
    state.assetRegisters = payload.data;
    state.totalPage = payload.page.pageCount;
    state.currentPage = payload.page.currentPage;
  },
  setSelectedAssetRegister(state, payload) {
    state.selectedAssetRegister = payload;
  },
  setBlob(state, payload) {
    state.blob = payload;
  },
  resetAssetRegister(state) {
    state.assetRegisters = [];
    state.totalPage = 0;
    state.currentPage = 0;
  },
  setAssetCodeByType(state, payload) {
    state.assetCodeByTypes = payload;
  },
  setAssetRegisterDropDown(state, payload) {
    // console.log(payload);
    let assetRegisters = [...state.assetRegisterDropDowns];
    for (const assetRegister of payload.data) {
      if(assetRegisters.findIndex(c => c.id === assetRegister.id) < 0){
        assetRegisters.push(assetRegister);
      }
    }
    state.assetRegisterDropDowns = assetRegisters;
    state.totalPageDropDown = payload.page.pageCount;
    state.currentPageDropDown = payload.page.currentPage;
  },
};
