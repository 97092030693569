<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 14H20.5C21.328 14 22 13.328 22 12.5V12.5C22 11.672 21.328 11 20.5 11H12"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.5 14C19.328 14 20 14.672 20 15.5V15.5C20 16.328 19.328 17 18.5 17H12"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.5 10.9995C19.328 10.9995 20 10.3275 20 9.4995V9.4995C20 8.6715 19.328 7.9995 18.5 7.9995H13H10L11.901 6.0985C12.508 5.4915 12.508 4.5075 11.901 3.9005V3.9005C11.377 3.3765 10.556 3.2955 9.94 3.7065L4.672 7.2185C3.003 8.3315 2 10.2045 2 12.2105V13.9995C2 17.3135 4.686 19.9995 8 19.9995H13H16.5C17.328 19.9995 18 19.3275 18 18.4995V18.4995C18 17.6715 17.328 16.9995 16.5 16.9995"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
