export default {
    createKeycard: "สร้างคีย์การ์ด",
    editKeycard: "แก้ไขคีย์การ์ด",
    title: "รายละเอียดคีย์การ์ด",
    details: "รายละเอียดทั่วไป",
    date: "วันที่นำเข้า",
    validateDateError: "กรุณาเลือกวันที่นำเข้า",
    createBy: "สร้างโดย",
    createDate: "สร้างวันที่",
    productCode: "รหัสสินค้า",
    validateProduct: "กรุณาเลือกรหัสสินค้า",
    runningNo: "Running No.",
    validateRunningNo: "กรุณาระบุ Running No.",
    serialNo: "Serial No.",
    status: "สถานะ",
    remark: "หมายเหตุ",
    validateRemark: "กรุณาระบุหมายเหตุ",
    statusReady: "พร้อมขาย",
    statusSold: "ขายแล้ว",
    statusLost: "หาย",
    statusBroken: "ชำรุด",
    statusSpare: "สำรอง",
    statusOther: "อื่นๆ",

    ownerDetails: "รายละเอียดผู้ถือคีย์การ์ด",
    unit: "เลขที่ยูนิต/ บ้านเลขที่",
    validateUnit: "กรุณาเลือกเลขที่ยูนิต/ บ้านเลขที่",

    //history
    editHistory: "ประวัติการแก้ไข",
    updateDate: "วันที่แก้ไข",
    updateBy: "ผู้แก้ไข",
    receiptCode: "เลขที่ใบเสร็จ",
    unitCode: "ยูนิต",
    unitAddressNo: "บ้านเลขที่",
    historyDetails: "รายละเอียดประวัติการแก้ไข",

    stockKeycardSaved: "บันทึกรายการสำเร็จ",
    validateDuplicateCode: "ไม่สามารถทำรายการได้ เนื่องจาก Running No. : :code ซ้ำ",
    validateChangeStatusLine1: "การเปลี่ยนแปลงข้อมูลรายการที่ขายแล้ว อาจทำให้ข้อมูลคลาดเคลื่อน จากความเป็นจริง",
    validateChangeStatusLine2: "หรือการเปลี่ยนสถานะจาก ขายแล้ว เป็นสถานะอื่น จะทำให้ข้อมูลเลขที่ยูนิต/บ้านเลขที่ และใบเสร็จ ที่อ้างอิง ในรายการนี้ถูกลบออกไป",
    validateChangeStatusLine3: "คุณยืนยันที่จะบันทึกรายการนี้หรือไม่",

    //stock keycard dialog
    selectStockKeycardTitle: "เลือกคีย์การ์ดที่ขาย (:productCode : :productName)",
    selectedStockKeycard: "จ่ายคีย์การ์ดที่เลือก",
    validateNoSelectStockKeycard: "กรุณาเลือกรายการ",
}