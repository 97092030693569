<template>
  <base-container>
    <add-form-type
      v-model:visible="showAddFormTypeModal"
      @close="showAddFormTypeModal = false"
      @save-data="saveFormType"
      :editingId="editingId"
      :editingCode="editingCode"
      :editingName="editingName"
      :editingBookCode="editingBookCode"
      :editingType="editingType"
    ></add-form-type>
    <!-- <PvButton icon="pi pi-search" class="p-button-text" :class="textHeaderClass" :label="lang.title"/> -->
    <div class="flex justify-content-between align-items-center">
      <div class="my-3">
        <PvButton
          icon="pi pi-chevron-left"
          :label="lang.back"
          @click="goBack"
          class="mr-2 p-button-secondary"
        />
      </div>
      <h2 :class="textHeaderClass" class="my-0">{{ lang.title }}</h2>
    </div>
    <div class="surface-card shadow-2 p-3 border-round">
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <PvButton
            icon="pi pi-plus"
            :label="lang.addNewFormType"
            class="mr-2 mb-2"
            @click="addFormType"
          />
        </div>
        <section class="flex flex-wrap" :class="textFormClass">
          <label for="searchText">{{ lang.searchText }}</label>
          <div class="p-inputgroup">
            <PvInputText
              id="searchText"
              type="text"
              class="inputfield w-full"
              :placeholder="lang.search"
              v-model="searchText"
              @keyup="onSearchInputKeyUp"
            />
            <PvButton icon="pi pi-search" @click="getFormTypes" />
          </div>
          <!-- <div class="flex col-12 pl-0 align-content-center">
              <PvButton
                icon="pi pi-search"
                :label="lang.search"
                @click="getFormTypes"
                class="mr-2"
              />
            </div> -->
        </section>
      </div>
      <PvDataTable
        showGridlines
        :value="formTypes"
        v-model:selection="selectedFormType"
        selectionMode="single"
        dataKey="id"
        responsiveLayout="scroll"
        :stripedRows="true"
        class="p-datatable-sm"
        :class="textTableClass"
        :loading="isLoading"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn
          field="code"
          :header="lang.columnCode"
          style="min-width: 4rem"
          headerClass="h-center"
        ></PvColumn>
        <PvColumn
          field="name"
          :header="lang.columnName"
          style="min-width: 4rem"
          headerClass="h-center"
        ></PvColumn>
        <PvColumn
          field="documentBookCode"
          :header="lang.columnBookCode"
          style="min-width: 4rem"
          headerClass="h-center"
          bodyStyle="text-align: center;"
        ></PvColumn>
        <PvColumn
          style="max-width: 3rem"
          headerClass="h-center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <PvButton
              type="button"
              class="p-button-secondary p-button-text py-0"
              icon="pi pi-pencil"
              @click="editFormType(slotProps.data)"
            ></PvButton>
            <PvButton
              type="button"
              class="p-button-secondary p-button-text py-0"
              icon="pi pi-cog"
              @click="gotoDetail(slotProps.data)"
            ></PvButton>
          </template>
        </PvColumn>
        <template #footer>
          <PvPaginator
            :rows="rowsPerPage"
            :totalRecords="totalRecords"
            @page="onPageChange($event)"
            :rowsPerPageOptions="[10, 50, 100]"
          >
            <template #start="slotProps">
              {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /
              {{ totalPage }}
            </template>
            <!-- <template #end>
              <PvButton type="button" icon="pi pi-search" />
            </template> -->
          </PvPaginator>
        </template>
      </PvDataTable>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";

import AddFormType from "../../../components/admin/formType/AddFormType.vue";
export default {
  components: {
    AddFormType,
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.admin.form.listing };
      } else {
        return { ...lang.en.base, ...lang.en.admin.form.listing };
      }
    });
    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString
    };
  },
  data() {
    return {
      selectedFormType: null,
      selectedPage: 1,
      rowsPerPage: 10,
      isLoading: false,
      searchText: "",
      showAddFormTypeModal: false,
      editingId:null,
      editingCode:"",
      editingName:"",
      editingBookCode: null,
      editingType:"Add"
    };
  },
  async created() {
  
    await this.getFormTypes();
  },
  methods: {
    goBack() {
			this.$router.push({name: "home"})
    },
    gotoDetail(formDetail){
      this.$store.dispatch('adminFormType/setSelectedFormType',formDetail)
      this.$router.push({ name: "admin-form-detail", params: { id: formDetail.id} });
    },
    toggleParameterVisible() {
      this.isParameterVisible = !this.isParameterVisible;
    },
    onSearchInputKeyUp(event) {
      if (event.keyCode === 13) {
        this.getFormTypes();
      }
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getFormTypes();
    },
    async getFormTypes() {
      this.isLoading = true;

      // let isUnPaid = false;
      // if (this.selectedIsUnPaid) {
      //   if (this.selectedIsUnPaid.code === "A1") {
      //     isUnPaid = true;
      //   }
      // }

      let where = {
        searchText: this.searchText,
      };

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };
      await this.$store.dispatch("adminFormType/getFormTypes", payload);
      this.isLoading = false;

      // where = {
      //   ...where,
      //   selectedUnit: this.selectedUnit,
      //   dateStart: this.dateStart,
      //   dateEnd: this.dateEnd,
      // };

      // localStorage.setItem("erp_search_invoiceList", JSON.stringify(where));
    },
    addFormType() {
      this.editingType = 'Add';
      this.editingId = null;
      this.editingCode = '';
      this.editingName = '';
      this.editingBookCode = null;
      this.showAddFormTypeModal = true;
    },
    editFormType(selectedFormType){
      // console.log(selectedFormType)
      this.editingType = 'Update';
      this.editingId = selectedFormType.id;
      this.editingCode = selectedFormType.code;
      this.editingName = selectedFormType.name;
      this.editingBookCode = selectedFormType.documentBookCode;
      this.showAddFormTypeModal = true;
    },
    async saveFormType(payload){
      this.showAddFormTypeModal = false;
      this.setLoading(true);
      try {
        await this.$store.dispatch(
          "adminFormType/createOrUpdateFormType",
          payload
        );
        this.$swal({
          icon: "success",
          title: this.lang.saveSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        
        this.getFormTypes();
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
      this.getFormTypes();
    },
    setLoading(bool){
      this.$store.dispatch("setLoading", { value: bool });
    }
  },
  computed: {
    parameterButtonText() {
      return this.isParameterVisible
        ? this.lang.hideParameters
        : this.lang.showParameters;
    },
    formTypes() {
      return this.$store.getters["adminFormType/formTypes"];
    },
    totalPage() {
      return this.$store.getters["adminFormType/totalPage"];
    },
    currentPage() {
      return this.$store.getters["adminFormType/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
  }
};
</script>