<template>
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredTaxBooks"
    field="codeAndName"
    @complete="searchTaxBook"
    @dropdown-click="filteredTaxBooks"
    forceSelection
  />
</template>

<script>
import useGetTaxBook from "../../hooks/getTaxBook.js";
export default {
  setup() {
    const {
      filteredTaxBooks,
      searchText,
      isSharedTaxBookReady,
      searchTaxBook,
    } = useGetTaxBook();

    return {
      filteredTaxBooks,
      searchText,
      isSharedTaxBookReady,
      searchTaxBook,
    };
  },
  emits: ["load-finish"],
  watch: {
    isSharedTaxBookReady(curValue) {
      if (curValue) {
        this.$emit("load-finish");
      }
    },
  },
};
</script>