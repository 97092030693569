<template>
  <base-toolbar
    :title="lang.title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoCreate="gotoEdit('create')"
    :hideBackButton="isHideBack"
  >
  </base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="titleConfirmDialog"
      :wordingConfirm="validateWordingDialog"
      :dataButtons="dataButtonDialog"
      @close="showValidateDialog = false"
    />

    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5">
      <div class="flex justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.title }}</label>
      </div>

      <PvDivider></PvDivider>

      <div class="p-datatable-border my-5">
        <PvDataTable
          :value="bookBanks"
          v-model:selection="selectedBookBank"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-radius p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(this.selectedBookBank.id)"
        >
          <template #header>
            <div
              class="flex justify-content align-items-center"
              style="height: 72px"
            >
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <PvInputText
                  v-model="searchText"
                  :placeholder="lang.searchText"
                  autofocus
                />
              </span>
              <PvButton class="ml-2" @click="getBookBanks">
                <span class="font-normal">{{ lang.search }}</span>
              </PvButton>
            </div>
          </template>
          <PvColumn
            field="code"
            :header="lang.bookBankCode"
            style="width: 25%; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal"
          ></PvColumn>
          <PvColumn
            field="name"
            :header="lang.bookBankName"
            style="width: 30%"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal p-text-secondary"
          ></PvColumn>
          <PvColumn
            field="bankBookTypeDesc"
            :header="lang.bankBookType"
            style="width: 20%"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal p-text-secondary"
          ></PvColumn>
          <PvColumn
            field="bookNo"
            :header="lang.bookNo"
            style="width: 25%"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal p-text-secondary"
          ></PvColumn>
          <PvColumn
            style="max-width: 5rem"
            headerClass="h-center"
            bodyStyle="text-align: center;justify-content: center;align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                :disabled="!this.isRoleSelect"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
            </template>
          </PvColumn>

          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>
          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              class="p-pagination-radius"
            >
              <template #start="slotProps">
                {{ lang.page }}:
                {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
              </template>
            </PvPaginator>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
export default {
  setup() {
    const isHideBack = inject("isHideBack");
    const { lang } = useLang();
    const { textTableClass, } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToLocaleDateString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.bookBank.listing,
          ...lang.th.master.bookBank.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.bookBank.listing,
          ...lang.en.master.bookBank.edit,
        };
      }
    });
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    return {
      lang: mergeLang,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToLocaleDateString,
      isHideBack,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
    };
  },
  data() {
    return {
      isLoading: false,
      visibleRight: false,
      selectedBookBank: null,
      searchText: "",
      selectedPage: 1,
      rowsPerPage: 10,

      activeStatus: "",
      activeStatusClass: "",

      bookBankInformationTable: [],
      bookBankDetailTable: [],

      dataButtons: [
        {
          id: "createBookBank",
          caption: this.lang.addBookBank,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
        },
      ],

      titleConfirmDialog: this.lang.notificationDialogHeader,
      showValidateDialog: false,
      validateWordingDialog: "",
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.roleByMenuDataReady();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    gotoEdit(id) {
      if (id != "create") {
        if(!this.isRoleSelect) {
          this.validateWordingDialog = this.lang.validateRoleSelect;
          this.showValidateDialog = true;
          return;
        }
      }
      else {
        if(!this.isRoleCreate) {
          this.validateWordingDialog = this.lang.validateRoleInsert;
          this.showValidateDialog = true;
          return;
        }
      }
      this.$router.push({name: "book-bank-edit", params:{ id: id}});
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getBookBanks();
    },
    async getBookBanks() {
      this.isLoading = true;

      let where = {
        projectId: this.selectedProject.id,
        searchText: this.searchText,
      };

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };

      try {
        await this.$store.dispatch("bookBank/getBookBanks", payload);
      } catch (error) {
        this.validateWordingDialog = error;
        this.showValidateDialog = true;
        this.isLoading = false;
      }

      this.isLoading = false;
    },
    async roleByMenuDataReady() {
      if (this.isSharedRoleByMenuReady) {
        await this.getBookBanks();
      }
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    bookBanks() {
      return this.$store.getters["bookBank/bookBanks"];
    },
    totalPage() {
      return this.$store.getters["bookBank/totalPage"];
    },
    currentPage() {
      return this.$store.getters["bookBank/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmBKB");
    },
    isRoleSelect() {
      if (this.roleByMenu && this.roleByMenu.select == "Y") {
        return true;
      }
      return false;
    },
    isRoleCreate() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    dataButtonDialog() {
      return [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
    },
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.roleByMenuDataReady();
    },
  },
};
</script>