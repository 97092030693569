export default {
    setPurchaseOrders(state, payload){
        state.purchaseOrders = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },    
    setSelectedPurchaseOrder(state, payload){
        state.selectedPurchaseOrder = payload
    },
    setBlob(state, payload){        
        state.blob = payload
    },
    setPurchaseRequisitions(state, payload){
        state.purchaseRequisitions = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    }, 
};