<template>
  <base-toolbar
    :title="lang.toolbarUnit"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoCreate="gotoEdit('create')"
    @gotoPrintUnitReport="gotoPrintUnitReport()"
    :hideBackButton="isHideBack"
  >
  </base-toolbar>  
  
  <import-unit-resident-dialog
    v-model:visible="showUploadUnitResidentDialog"
    @close="showUploadUnitResidentDialog = false"
  ></import-unit-resident-dialog>

  <base-container>
    <base-dialog
      v-model:visible="showConfirmDialog"
      :titleConfirm="confirmTitle"
      :classTitle="confirmClassTitle"
      :wordingConfirm="confirmWording"
      :dataButtons="dataButtonDialog"
      @close="showConfirmDialog = false"
      @confirm="showConfirmDialog = false"
    ></base-dialog>
    <base-toast-report-request
      :showToast="showToast"
      :toastMessage="toastMessage"
      :toastLink="toastLink"
      :actionToast="actionToast"
    ></base-toast-report-request>
    <report-unit
      :title="lang.reportTitle"
      :openDialog="openDialog"
      v-model:visible="showFormPrintModal"
      @close="closePrintUnitReport"
      @confirm="confirmPrintUnitReport"
    ></report-unit>
    <import-unit-dialog 
      v-model:visible="showUploadUnitDialog"
      @close="showUploadUnitDialog = false"
      @data-upload="uploadUnit"
    /> 
    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5">
      <div class="flex justify-content-between align-items-center">
        <!-- <h2 class="mt-2 mb-0 text">{{ lang.title }}</h2> -->
        <label class="font-lg font-normal">{{ lang.title }}</label>
        <div class="flex justify-content-between">
          <PvButton class="p-button-outlined mr-1" @click="importUnitResident" v-if="isShowImportDataButton">
            <icon-synchronize iconColor="primary" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{ lang.importUnitResident }}</span>
          </PvButton>
          <PvButton class="p-button-outlined ml-1" @click="importUnit" v-if="isShowImportDataButton">
              <icon-download iconColor="primary" width="16" height="16" />
              <span class="p-button-label font-normal ml-1">{{ lang.buttonImportUnit }}</span>
          </PvButton>
        </div>
      </div>
      <PvDivider></PvDivider>

      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >
        <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            class="inputfield w-full"
            :options="selectSearchType"
            optionLabel="name"
            optionValue="code"
            v-model="selectedSearchType"
            @change="changeSelectedSearchType()"
          />
        </div>
        <div class="flex lg:col-6 md:col-12 col-12 p-0 mr-2" v-if="selectedSearchType != 'All'">
          <div class="col-12 pl-0 pr-0">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchText"
                :placeholder="this.selectedSearchTypePlaceholder"
                @keypress="searchUnit($event)"
                autofocus
                :disabled="selectedSearchType === 'All'"
                :class="{ 'p-inputtext-disabled': selectedSearchType === 'All' }"
              />
            </span>
          </div>
        </div>
        <PvButton @click="getUnits()">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="units"
          v-model:selection="selectedUnit"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(selectedUnit.id)"
        >
          <template #empty>
            <div
              style="height: 56px"
              class="text-center flex align-items-center justify-content-center w-full"
            >
              <label class="font-normal">
                {{ lang.tableEmpty }}
              </label>
            </div>
          </template>
          <PvColumn
            field="code"
            :header="lang.unitCode"
            style="mix-width: 4rem; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal"
            sortable
          >
          </PvColumn>
          <PvColumn
            field="addressNo"
            :header="lang.addressNo"
            style="min-width: 4rem"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal p-text-secondary"
            sortable
          >
          </PvColumn>
          <PvColumn
            field="customerName"
            :header="lang.ownerName"
            style="min-width: 10rem"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal p-text-secondary"
            sortable
          ></PvColumn>
          <PvColumn
            field="ownerRatio"
            :header="lang.columnRatio"
            style="max-width: 6rem"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal p-text-secondary"
          ></PvColumn>
          <PvColumn
            field="area"
            :header="lang.area"
            style="max-width: 6rem"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal p-text-secondary"
          ></PvColumn>
          <PvColumn
            field="name"
            :header="lang.type"
            style="max-width: 8rem"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal p-text-secondary"
          ></PvColumn>
          <PvColumn
            field="isActive"
            :header="lang.activeStatus"
            style="min-width: 11rem; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="text-left"
            sortable
          >
            <template #body="{ data, field }">
              <base-tag
                v-if="data[field].trim() != ''"
                :Caption="activeStatus(data[field]).caption"
                :Class="activeStatus(data[field]).class"
              />
            </template>
          </PvColumn>

          <PvColumn
            style="max-width: 10rem"
            headerClass="h-center font-normal"
            bodyStyle="text-align: center;"
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
            </template>
          </PvColumn>
          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              class="p-pagination-radius"
              :first="showCurrentPage"
            >
              <template #start="slotProps">
                {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{
                  totalPage
                }}
              </template>
            </PvPaginator>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import ReportUnit from "../../../components/report/Master/ReportUnit.vue";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import ImportUnitResidentDialog from "../../../components/master/unit/ImportUnitResidentDialog.vue";
import ImportUnitDialog from "../../../components/master/unit/ImportUnitDialog.vue";
import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
export default {
  components: {
    ReportUnit,
    ImportUnitResidentDialog,
    ImportUnitDialog,
  },
  setup() {
    const { lang } = useLang();
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();
    const {
      textHeaderClass,
      textFormClass,
      textTableClass,
      textCardTitleClass,
    } = useTextStyle();
    const {
      formatCurrency,
      formatDate,
      formatDateToISOString,
      formatDateToLocaleDateString,
    } = useFormat();
    const { reportPrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.unit.listing,
          ...lang.th.master.unit.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.unit.listing,
          ...lang.en.master.unit.edit,
        };
      }
    });

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,

      textHeaderClass,
      textFormClass,
      textTableClass,
      textCardTitleClass,

      formatCurrency,
      formatDate,
      formatDateToISOString,

      formatDateToLocaleDateString,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      isHideBack,
      reportPrintOrder,
    };
  },
  data() {
    return {
      isLoading: false,
      visibleRight: false,
      searchText: "",
      selectedUnit: null,
      selectedPage: 1,
      rowsPerPage: 10,

      generalInformationTable: [],
      memberInformationTable: [],
      residentInformationTable: [],

      resident: [],
      rental: [],

      showFormPrintModal: false,
      openDialog: 0,
      storageName: "",
      searchUnitCode: null,
      searchAddressNo: null,
      searchOwnerName: null,
      selectSearchType: [
        { name: this.lang.allData, code: "All" },
        { name: this.lang.unitCode, code: "UnitCode" },
        { name: this.lang.addressNo, code: "AddressNo" },
        { name: this.lang.ownerName, code: "OwnerName" },
      ],
      selectedSearchType: "All",
      confirmWording: "",
      confirmTitle: "",
      confirmClassTitle: "",
      showConfirmDialog: false,
      showToast: 0,
      toastMessage: {},
      toastLink: "",
      actionToast: "",
      showUploadUnitResidentDialog: false,
      showUploadUnitDialog: false,
    };
  },
  async mounted() {
    // console.log(this.isShowImportDataButton);
    this.storageName = `erp_search_UnitList_${this.selectedProject.code}_${this.userCode}`;
    this.checkShouldSetData();
  },
  methods: {
    goBack() {
      this.$router.push({name: "home"})
    },
    async onPageChange(event) {
      if(event.rows != this.rowsPerPage){
        this.selectedPage = 1;
      }else{
        this.selectedPage = event.page + 1;        
      }
      this.rowsPerPage = event.rows;
      this.getUnits();
    },
    async getUnits() {
      this.isLoading = true;
      if (this.validateSearchButton) {
        this.confirmClassTitle = "";
        this.confirmTitle = this.lang.notificationDialogHeader;
        this.confirmWording = this.lang.validateSearchText;
        this.showConfirmDialog = true;
        this.isLoading = false;
        return;
      }
      try {
        if (this.selectedSearchType === "All") {
          this.searchText = "";
        } else if (this.selectedSearchType === "UnitCode") {
          this.searchUnitCode = this.searchText;
        } else if (this.selectedSearchType === "AddressNo") {
          this.searchAddressNo = this.searchText;
        } else if (this.selectedSearchType === "OwnerName") {
          this.searchOwnerName = this.searchText;
        }

        let where = {
          projectId: this.selectedProject.id,
          code: this.searchUnitCode ? this.searchUnitCode : null,
          addressNo: this.searchAddressNo ? this.searchAddressNo : null,
          customerSearch: this.searchOwnerName || null,
        };

        const payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          where,
        };
        await this.$store.dispatch("unit/getUnits", payload);

        let local = {
          ...where,
          rowsPerPage: this.rowsPerPage,
          selectedPage: this.selectedPage,
        };
        localStorage.setItem(this.storageName, JSON.stringify(local));

        this.isLoading = false;
      } catch (error) {
        this.openErrorDialog(error);
      }

      this.isLoading = false;
    },
    gotoEdit(id) {
      // console.log("gotoEdit", id);
      if (!this.isRoleSelect && id != "create") {
        this.confirmTitle = this.lang.notificationDialogHeader;
        this.confirmWording = this.lang.validateRoleSelect;
        this.showConfirmDialog = true;
      } else if (!this.isRoleInsert && id == "create") {
        this.confirmTitle = this.lang.notificationDialogHeader;
        this.confirmWording = this.lang.validateRoleInsert;
        this.showConfirmDialog = true;
      } else {
        this.$router.push({name: "unit-edit", params: { id: id}});
      }
    },
    gotoReport() {
      // console.log("gotoReport");
    },
    gotoPrintUnitReport() {
      this.openDialog = new Date().getSeconds();
      let element = document.getElementsByTagName("body")[0];
      element.style.overflow = "hidden";
      this.showFormPrintModal = true;
    },
    EditUnitProduct(id) {
      // console.log("id=",id);
      this.$router.push({name: "unit-product-setup-invoice", params: { unitId: id}});
    },
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    async checkShouldSetData() {
      if (this.isSharedRoleByMenuReady) {
        // await this.setLocalSearch();
        await this.setLocalSearch();
        await this.getUnits();
      }
    },
    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      if (localSearch) {
        let search = JSON.parse(localSearch);

        if (search.entry === "entry") {
          if (search.code != null && search.code != "") {
            this.selectedSearchType = "UnitCode";
            this.searchText = search.code;
          } else if (search.addressNo != null && search.addressNo != "") {
            this.selectedSearchType = "AddressNo";
            this.searchText = search.addressNo;
          } else if (search.customerSearch != null && search.customerSearch != "") {
            this.selectedSearchType = "OwnerName";
            this.searchText = search.customerSearch;
          } else {
            this.selectedSearchType = "All";
            this.searchText = "";
          }

          this.rowsPerPage = search.rowsPerPage;
          this.selectedPage = search.selectedPage;

          search.entry = "";

          localStorage.setItem(this.storageName, JSON.stringify(search));
        }
      }
    },
    searchUnit(event) {
      if (event.key == "Enter") {
        this.getUnits();
      }
    },
    changeSelectedSearchType() {
      this.searchText = "";
      this.searchUnitCode = null;
      this.searchAddressNo = null;
      this.searchOwnerName = null;
    },
    openErrorDialog(error) {
      this.confirmClassTitle = "p-error";
      this.confirmTitle = this.lang.notificationDialogHeader;
      this.confirmWording = error;
      this.showConfirmDialog = true;
      this.typeButtonDialog = "error";
    },
    activeStatus(data) {
      let activeStatus = {
        caption: "",
        class: "",
      };
      if (data == "Y") {
        activeStatus.caption = this.lang.statusY;
        activeStatus.class = "success";
      }
      if (data == "N") {
        activeStatus.caption = this.lang.statusN;
        activeStatus.class = "error";
      }

      return activeStatus;
    },
    closePrintUnitReport() {
      let element = document.getElementsByTagName("body")[0];
      element.style.overflow = "scroll";
      this.showFormPrintModal = false
    },
    async confirmPrintUnitReport(data) {
      let element = document.getElementsByTagName("body")[0];
      element.style.overflow = "scroll";
      this.setLoading(true);
      try {
        const response = await this.reportPrintOrder(data);
        if (response) {
          this.showFormPrintModal = false;
          this.toastMessage = {
            severity: "info",
            summary: this.lang.toastRequestTitle,
            detail: this.lang.toastRequestContent,
            life: 5000,
          };
          this.actionToast = `/${this.selectedProject.code}/report/request?back=${this.$route.fullPath}`;
          this.toastLink = this.lang.toastRequestLink;
          const today = new Date();
          this.showToast = today.getSeconds();
        }
      } catch (error) {

        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    // gotoRequest() {
    //   this.$router.push({ name: "report-request", query: { back: this.$route.fullPath } })
    // },    
    importUnitResident(){
      this.showUploadUnitResidentDialog = true;
    },
    importUnit() {
      this.showUploadUnitDialog = true;
    },
    uploadUnit() {
      this.showUploadUnitDialog = false;
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    units() {
      return this.$store.getters["unit/units"];
    },
    totalPage() {
      return this.$store.getters["unit/totalPage"];
    },
    currentPage() {
      return this.$store.getters["unit/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmCOR");
    },
    isRoleSelect() {
      if (this.roleByMenu && this.roleByMenu.select == "Y") {
        return true;
      }
      return false;
    },
    isRoleInsert() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    isRolePrint() {
      if (this.roleByMenu && this.roleByMenu.print == "Y") {
        return true;
      }
      return false;
    },
    dataButtons() {
      return [
        {
          id: "printReport",
          caption: this.lang.reportPrintButton,
          class: "mr-2",
          icon: "icon-printer",
          iconColor: "white",
          action: "gotoPrintUnitReport",
          isShow: true,
          // isDisable: !this.isRolePrint,
        },
        {
          id: "createUnit",
          caption: this.lang.addRoom,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
          // isDisable: !this.isRoleInsert,
        },
      ];
    },
    dataButtonDialog() {
      return [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
    },
    validateSearchButton() {
      if (this.selectedSearchType !== "All" && !this.searchText) {
        return true;
      }
      return false;
    },
    selectedSearchTypePlaceholder() {
      let placeholder = this.lang.search;

      return placeholder;
    },
    showCurrentPage(){
      let page = 0;
      page = this.rowsPerPage * (this.selectedPage - 1)
      return page;
    },
    isShowImportDataButton() {
      let userGroupId = process.env.VUE_APP_USER_GROUP_ID.split("|")
      return userGroupId.includes(this.roleByMenu.userGroupId);
    }
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.checkShouldSetData();
    },
  },
};
</script>

<style scoped>
/* .base-center {
  width: 75%;
  height: 714px;
  margin-left: auto !important;
  margin-right: auto !important;
} */
.p-pagination-radius {
  border-radius: 0 0 12px 12px;
}
</style>
