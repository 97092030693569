export default {
	title: "TransferSlip Detail",
	transferSlipList: "transferSlip List",
	transferSlipImage: "TransferSlip Image",
	uploadDate: "Upload Date : ",
	receiptCode: "Receipt Code",
	reasonsRejected: "Reasons Rejected",
	unitAddressNo: "Address No.",
	pendingList: "Pending List",
	totalAmount: "Total Amount",
	paidAmount: "Total Paid Amount",
	pendingAmount: "Total Outstanding Balance",
	columnItemNo: "Item No.",
	columnProductCode: "ProductCode",
	columnProductName: "ProductName",
	columnPeriod: "Period",
	columnTotalAmount: "Total Amount",
	columnPaidAmount: "Paid Amount",
	createReceipt: "Create Receipt",
	reject: "Reject",
	revertToWaiting: "Revert To Waiting",
	selectRejectionReason: "Please Select Rejection Reason",
	reason: "Reason*",
	askForRevertToWaiting: "Do you want to revert this item status to “waiting” ?", 
	validateRoleApprove: "You don’t have permission to use this menu.",
	reasonSelect: "Please Select Reason",
	reasonDuplicated: "Attached transfer slip is duplicated.",
	reasonFake: "Attached transfer slip is fake.",
	reasonIncorrect: "Attached transfer slip is incorrect.",
	validateRoleCancel: "You don’t have permission to use this menu.",
	validateSelectReason: "Please Select Reason",
	titleChangedStatusSuccess: "Changed Status Successfully",
	changedStatusSuccess: " This item status has changed to “Rejected”.",
	titleRevertedToWaitingSuccess: "Reverted To Waiting Successfully",
	revertedToWaitingSuccess: "This item status has reverted to “Waiting”.",

};
