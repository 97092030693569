export default {
    title: "รายละเอียดหนังสือรับรองหนี้",
    createDebtFreeRequest: "สร้างหนังสือรับรองหนี้",
    editDebtFreeRequest: "แก้ไขหนังสือรับรองหนี้",
    submitApprove: "ส่งเรื่องให้อนุมัติ",
    debtFreeAttachment: "เอกสารประกอบ",
    //ส่วนที่ 1
    titleSectionFirst: "ส่วนที่ 1 : ข้อมูลทะเบียนห้อง",
    unitCode: "ยูนิต",
    unitArea: "พื้นที่",
    unitAddressNo: "บ้านเลขที่",
    pendingCurrent: "ค้างชำระ ณ ปัจจุบัน",
    pendingCalculateDate: "ค้างชำระ ณ วันที่",
    ownerIdCardAddress1: "ที่อยู่ตามบัตรประชาชน (ส่วนที่ 1)",
    pendingAmount: "มูลค่าค้าง ณ วันที่ขอ",
    ownerIdCardAddress2: "ที่อยู่ตามบัตรประชาชน (ส่วนที่ 2)",
    stringBahtPendingAmount: "จำนวนเงิน (ตัวอักษร)",
    ownerIdCardAddress3: "ที่อยู่ตามบัตรประชาชน (ส่วนที่ 3)",
    unitCount: "จำนวนห้องชุด",
    //ส่วนที่ 2
    titleSectionSecond: "ส่วนที่ 2 : ข้อมูลเอกสาร",
    requestDate: "วันที่ขอหนังสือรับรองหนี้",
    requestBy: "ชื่อผู้รับกรรมสิทธิ์ห้องชุด",
    documentDate: "วันที่เอกสาร",
    requestPhone: "เบอร์โทรศัพท์ผู้รับกรรมสิทธิ์ห้องชุด",
    isForeigner: "ผู้รับกรรมสิทธิ์เป็นชาวต่างชาติ",
    requestDocument: "รายการเอกสารการขอหนังสือรับรองหนี้",
    isGetCopyOfOwnerIdCard: "สำเนา บัตรประชาชนเจ้าของห้องชุด / ผู้รับโอนกรรมสิทธิ์",
    isGetCopyOfOwnerHouseRegistration: "สำเนาทะเบียนบ้านเจ้าของห้องชุด / ผู้โอนกรรมสิทธิ์",
    isGetCopyOfJointOwnerShip: "สำเนาหนังสือกรรมสิทธิ์ห้องชุด (อช.2)",
    isGetPowerOfAttorney: "หนังสือมอบอำนาจ (กรณีดำเนินการแทน)",
    isGetCopyOfProxyIdCard: "สำเนาบัตรประชาชนผู้รับมอบอำนาจ (กรณีดำเนินการแทน)",
    isGetCopyOfNameChangedCertificate: "สำเนาเอกสารการเปลี่ยนชื่อ- สกุล (ถ้ามี)",
    isOtherDocument: "เอกสารอื่นๆ",
    otherDocumentDescription: "กรุณาระบุข้อมูลเพิ่มเติม",
    requestDetail: "รายการค่าชำระหนี้",
    isRequestCutOfPublicUtilities: "แจ้งความประสงค์ให้ระงับน้ำประปา / ไฟฟ้า",
    selectDate: "เลือกวันที่",
    isPaidFee: "ชำระค่าธรรมเนียมขอหนังสือรับรองปลอดหนี้",
    isPaidExpenses: "ชำระค่าใช้จ่ายตามใบเสร็จรับ",
    receiptCode: "เลขที่ใบเสร็จ",
    receiptAmount: "จำนวนเงิน",
    pleaseInputValue: "กรุณาระบุ",
    //ส่วนที่ 3
    titleSectionThird: "ส่วนที่ 3 : การตรวจสอบ",
    isValidateExpenses: "ตรวจสอบแล้วไม่มียอดค้างชำระ",
    approve: "อนุมัติ",
    disApprove: "ไม่อนุมัติ",
    approvedDate: "วันที่อนุมัติปลอดหนี้",
    debtFreeCode: "เลขที่ปลอดหนี้",
    approvedBy: "ผู้อนุมัติปลอดหนี้",

    cancelReason: "กรุณาเลือกสาเหตุที่ยกเลิก",
    cancelDebtFreeRequestTitle: "ยกเลิกหนังสือรับรองหนี้",
    cancelDebtFreeRequestTitleDetail: "คุณต้องการยกเลิกหนังสือรับรองหนี้ใช่หรือไม่?",
    cancelReason1: "หลักฐานไม่ครบถ้วนไม่สมบูรณ์",
    cancelReason2: "ยังมีหนี้ค้างชำระ/ตรวจสอบแล้วยังไม่ปลอดหนี้",
    cancelReason3: "ระงับ/แจ้งขอยกเลิกการขอใบปลอดหนี้",
    cancelDebtFreeRequestSuccess: "ยกเลิกหนังสือรับรองหนี้ ห้อง : :unitCode สำเร็จ",
    submitApproveTitle: "ต้องการขอใบปลอดหนี้ใช่หรือไม่ ?",
    submitApproveTitleDetail: "ท่านกำลังเข้าสู่ขั้นตอนรออนุมัติปลอดหนี้ จำเป็นต้องอาศัยผู้มีสิทธิ์อนุมัติปลอดหนี้ดำเนินการ",
    submitApproveSuccess: "คุณทำรายการขออนุมัติสำเร็จ",
    isPendingAmountTitle: "ทำรายการไม่สำเร็จ",
    isPendingAmountTitleDetail: "ระบบตรวจพบว่ายังมีหนี้ค้างชำระในระบบ",

    createDebtFreeRequestSuccess: "คุณสร้างหนังสือรับรองหนี้สำเร็จ",
    editDebtFreeRequestSuccess: "คุณแก้ไขหนังสือรับรองหนี้สำเร็จ",

    validateRequestBy: "กรุณาระบุชื่อผู้ขอ",
    validateRequestDate: "กรุณาระบุวันที่ขอหนังสือรับรองหนี้",
    validateRequestPhone: "กรุณาระบุเบอร์โทรศัพท์ผู้ขอ",

    createNewDebtFreeRequestTitle: "สร้างหนังสือรับรองหนี้ใหม่",
    askForCreateNewDebtFreeRequest: "หากคุณสร้างหนังสือรับรองหนี้ใหม่ระบบจะไม่บันทึกการแก้ไข",
    createNewDebtFreeRequestTitleDetail: "คุณยืนยันที่จะสร้างหนังสือรับรองหนี้ใหม่ใช่หรือไม่ ?",

    stringBahtPendingAmountIsZero: "ศูนย์บาทถ้วน",
    validateNoSelectDate: "กรุณาเลือกวันที่",

    validateDebtFreeApproval: "เอกสารนี้ถูกอนุมัติแล้ว",
    validateDebtFreeSubmittedApprove: "เอกสารนี้ถูกส่งเรื่องอนุมัติแล้ว",
}