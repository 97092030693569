export default {
    setNoticeGenerateQueueStatus(state, payload){
        state.noticeGenerateQueueStatus = payload.data;
    },
    setSelectedNoticeGenerateQueue(state, payload){
        state.selectedNoticeGenerateQueue = payload;
    },
    setNotice(state, payload){
        state.notices = payload.data;
        state.noticeTotalPage = payload.page.pageCount;
        state.noticeCurrentPage = payload.page.currentPage;
    },
    setSelectedNotice(state, payload){
        state.selectedNotice = payload
    },
    setNoticeIsCanceled(state, payload) {
        state.noticeIsCanceled = payload.data;
    },
    setGeneratedNotice(state, payload) {
        state.generatedNotice = payload.data;
    },
    resetNoticeGenerateQueue(state){
        state.noticeGenerateQueueStatus = null;
    },
}