export default {
	title: "ทะเบียนรายรับ",
	createProduct: "สร้างทะเบียนรายรับ",

	inputSearch: "กรอกข้อความเพื่อค้นหา",

	columnCode: "รหัส",
	columnName: "ชื่อรายรับ",
	columnPriority: "ลำดับการตัดจ่าย",
	columnPrice: "ราคา",
	columnConfig: "ตั้งค่ารายรับ",
	columnUpdate: "แก้ไข",

	titleConfirmDialogGotoEdit:"ยืนยันการแก้ไข",
	contentConfirmDialogGotoEdit: "คุณต้องการยืนยันการแก้ไขข้อมูล ใช่หรือไม่?",
	titleConfirmDialogGotoEDelete: "ยืนยันการลบ",
	contentConfirmDialogGotoDelete: "ยืนยันจะลบ {code} : {name}",
	titleConfirmDialogGotoSetupPenalty: "ยืนยันการตั้งค่ารายรับ",
	contentConfirmDialogGotoSetupPenalty: "คุณต้องการยืนยันการตั้งค่ารายรับ ใช่หรือไม่?",

	deleteProductSuccess: "ลบข้อมูลสำเร็จ",
	
	errorArProductReference: "ลบข้อมูลไม่ได้ เนื่องจากมีข้อมูลอ้างถึง",
};
