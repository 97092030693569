<template>
  <base-container>
    <add-form-detail
      v-model:visible="showAddFormDetailModal"
      @close="showAddFormDetailModal = false"
      @save-data="saveFormDetail"
      :editingId="editingId"
      :editingSequence="editingSequence"
      :editingCategory="editingCategory"
      :editingProjectId="editingProjectId"
      :editingName="editingName"
      :editingPath="editingPath"
      :editingType="editingType"
      :editingIsActive="editingIsActive"
      :openAddFormDetail="openAddFormDetail"
    ></add-form-detail>
    <base-dialog
		v-model:visible="showConfirmDialog"
		:titleConfirm="confirmTitle"
		:wordingConfirm="confirmWording"
		@confirm="deleteData()"
		@close="showConfirmDialog = false"></base-dialog>
    <!-- <PvButton icon="pi pi-search" class="p-button-text" :class="textHeaderClass" :label="lang.title"/> -->
    <div class="flex justify-content-between align-items-center">
      <div class="my-3">
        <PvButton
          icon="pi pi-chevron-left"
          :label="lang.back"
          @click="goBack"
          class="mr-2 p-button-secondary"
        />
      </div>
      <h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
    </div>
    <div class="surface-card shadow-2 p-3 border-round">
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <PvButton
            icon="pi pi-plus"
            :label="lang.addNewFormDetail"
            class="mr-2 mb-2"
            @click="addFormDetail"
          />
        </div>
        <section class="flex flex-wrap" :class="textFormClass">
          <label for="searchText">{{ lang.searchText }}</label>
          <div class="p-inputgroup">
            <PvInputText
              id="searchText"
              type="text"
              class="inputfield w-full"
              :placeholder="lang.search"
              v-model="searchText"
              @keyup="onSearchInputKeyUp"
            />
            <PvButton icon="pi pi-search" @click="getFormDetails" />
          </div>
          <!-- <div class="flex col-12 pl-0 align-content-center">
              <PvButton
                icon="pi pi-search"
                :label="lang.search"
                @click="getFormDetails"
                class="mr-2"
              />
            </div> -->
        </section>
      </div>
      <PvDataTable
        showGridlines
        :value="formDetails"
        v-model:selection="selectedFormDetail"
        selectionMode="single"
        dataKey="id"
        responsiveLayout="scroll"
        :stripedRows="true"
        class="p-datatable-sm"
        :class="textTableClass"
        :loading="isLoading"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn
          field="sequence"
          :header="lang.columnSequence"
          style="min-width: 2rem"
          headerClass="h-center"
          bodyClass="text-center"
        ></PvColumn>
        <PvColumn
          field="category"
          :header="lang.columnCategory"
          style="min-width: 4rem"
          headerClass="h-center"
          bodyClass="text-center"
        ></PvColumn>
        <PvColumn
          field="projectCode"
          :header="lang.columnProjectCode"
          style="min-width: 4rem"
          headerClass="h-center"
        ></PvColumn>
        <PvColumn
          field="name"
          :header="lang.columnName"
          style="min-width: 4rem"
          headerClass="h-center"
        ></PvColumn>
        <PvColumn
          field="path"
          :header="lang.columnPath"
          style="min-width: 4rem"
          headerClass="h-center"
        ></PvColumn>        
        <PvColumn
          field="isActive"
          :header="lang.status"
          style="min-width: 2rem"
          headerClass="h-center"
          bodyClass="text-center"
        ></PvColumn>

        <PvColumn
          style="max-width: 4rem"
          headerClass="h-center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <PvButton
              type="button"
              class="p-button-secondary p-button-text py-0"
              icon="pi pi-pencil"
              @click="editFormDetail(slotProps.data)"
            ></PvButton>
            <!-- <PvButton
              type="button"
              class="p-button-danger p-button-text py-0"
              icon="pi pi-trash"
              @click="gotoEdit(slotProps.data.id)"
            ></PvButton> -->
            <PvButton
              type="button"
              class="p-button-secondary p-button-text py-0"
              icon="pi pi-trash"
              @click="askForDeleteData(slotProps.data)"
            ></PvButton>
          </template>
        </PvColumn>
        <template #footer>
          <PvPaginator
            :rows="rowsPerPage"
            :totalRecords="totalRecords"
            @page="onPageChange($event)"
            :rowsPerPageOptions="[10, 50, 100]"
          >
            <template #start="slotProps">
              {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /
              {{ totalPage }}
            </template>
            <!-- <template #end>
              <PvButton type="button" icon="pi pi-search" />
            </template> -->
          </PvPaginator>
        </template>
      </PvDataTable>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";

import AddFormDetail from "../../../components/admin/formDetail/AddFormDetail.vue";
export default {
  props: ["id", "projectCode"],
  components: {
    AddFormDetail,
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.admin.form.detail };
      } else {
        return { ...lang.en.base, ...lang.en.admin.form.detail };
      }
    });
    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
    };
  },
  data() {
    return {
      selectedFormDetail: null,
      selectedPage: 1,
      rowsPerPage: 10,
      isLoading: false,
      searchText: "",
      showAddFormDetailModal: false,
      editingId: null,
      editingSequence: null,
      editingCategory: "",
      editingProjectId: "",
      editingName: "",
      editingPath: "",
      editingType: "Add",
      editingIsActive: "Y",
      openAddFormDetail:"",
			showConfirmDialog: false,
			confirmTitle: "",
			confirmWording: "",
			formDetailId: "",
    };
  },
  async created() {
    if (!this.selectedFormType) {
      await this.$store.dispatch("adminFormType/getSelectedFormType", {
        id: this.id,
      });
    }
    await this.getFormDetails();
  },
  methods: {
    goBack() {
      this.$router.push({name: "admin-form"});
    },
    toggleParameterVisible() {
      this.isParameterVisible = !this.isParameterVisible;
    },
    onSearchInputKeyUp(event) {
      if (event.keyCode === 13) {
        this.getFormDetails();
      }
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getFormDetails();
    },
    async getFormDetails() {
      this.isLoading = true;

      let where = {
        formTypeId: this.id,
        SearchText: this.searchText,
      };

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };
      await this.$store.dispatch("adminFormDetail/getFormDetails", payload);
      this.isLoading = false;
    },
    addFormDetail() {
      this.openAddFormDetail = new Date().getSeconds();
      this.editingType = "Add";
      this.editingId = null;
      this.editingSequence = null;
      this.editingName = "";
      this.editingCategory = '';
      this.editingProjectId = '';
      this.editingPath = '';
      this.showAddFormDetailModal = true;
      this.editingIsActive = 'Y'    
    },
    editFormDetail(selectedFormDetail) {
      //  console.log("selectedFormDetail=",selectedFormDetail);
      this.editingType = "Update";
      this.editingId = selectedFormDetail.id;
      this.editingSequence = selectedFormDetail.sequence;
      this.editingName = selectedFormDetail.name;
      this.editingCategory = selectedFormDetail.category;
      this.editingProjectId = selectedFormDetail.projectId;
      this.editingPath = selectedFormDetail.path;
      this.showAddFormDetailModal = true;
      this.editingIsActive = selectedFormDetail.isActive;
    },
    async saveFormDetail(payload) {
      this.showAddFormDetailModal = false;
      let data = {...payload,formTypeId:this.id}
      this.setLoading(true);
      try {
        await this.$store.dispatch(
          "adminFormDetail/createOrUpdateFormDetail",
          data
        );
        this.$swal({
          icon: "success",
          title: this.lang.saveSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        this.getFormDetails();
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
      this.getFormDetails();
    },
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    askForDeleteData(data) {
      this.formDetailId = data.id
			this.showConfirmDialog = true;
			this.confirmTitle = this.lang.titleConfirmDelete;
			this.confirmWording = `${this.lang.askForDeleteDataDetail.replace(
				":title",
				" "+this.lang.columnSequence+" "+data.sequence+ ", "+
        this.lang.columnProjectCode+" "+data.projectCode+", "+
        this.lang.columnName+" "+data.name + " "
			)}`;
		},
    async deleteData(){  
      try {
        // console.log("this.formDetailId=",this.formDetailId);
        this.showConfirmDialog = false;
        this.setLoading(true);   
        let payload = {id:this.formDetailId,formTypeId:2}
        await this.$store.dispatch("adminFormDetail/deleteFormDetail",payload);
        this.$swal({
          icon: "success",
          title: this.lang.deleteSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        this.setLoading(false);   
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        this.setLoading(false); 
      }
    }
  },
  computed: {
    parameterButtonText() {
      return this.isParameterVisible
        ? this.lang.hideParameters
        : this.lang.showParameters;
    },
    selectedFormType() {
      return this.$store.getters["adminFormType/selectedFormType"];
    },
    formDetails() {
      return this.$store.getters["adminFormDetail/formDetails"];
    },
    totalPage() {
      return this.$store.getters["adminFormDetail/totalPage"];
    },
    currentPage() {
      return this.$store.getters["adminFormDetail/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    title() {
      if (this.selectedFormType) {
        return this.lang.title + this.selectedFormType.documentBookCode;
      }
      return "";
    },
  },
};
</script>