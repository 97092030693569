<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.66466 18.1904V18.6706C9.66466 19.2895 9.9105 19.883 10.3481 20.3206C10.7857 20.7583 11.3792 21.0041 11.9981 21.0041H12C12.6191 21.0044 13.213 20.7586 13.651 20.321C14.0889 19.8834 14.3351 19.2898 14.3353 18.6706V18.1904"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.5776 18.1904C18.641 18.1904 19.5031 17.3284 19.5031 16.2649V16.2649C19.5027 15.7247 19.2882 15.2067 18.9066 14.8243L17.6273 13.546V9.74941C17.6273 6.64152 15.1079 4.12207 12 4.12207V4.12207C8.8921 4.12207 6.37265 6.64152 6.37265 9.74941V13.546L5.09337 14.8243C4.71179 15.2067 4.4973 15.7247 4.49687 16.2649V16.2649C4.49687 17.3284 5.35894 18.1904 6.42236 18.1904H17.5776V18.1904Z"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.0255 2.99609L20.0246 4.32996"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.0046 8.99923H21.0251"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.97452 2.99609L3.97535 4.32996"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.995407 8.99923H2.97493"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "white",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
