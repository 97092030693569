<template>
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredBuildings"
    field="codeAndName"
    @complete="searchBuilding"
    @dropdown-click="filteredBuildings"
    forceSelection
  />
</template>

<script>
import useGetBuilding from "../../hooks/getBuilding.js";
export default {
  setup() {
    const {
      filteredBuildings,
      searchText,
      isSharedBuildingReady,
      searchBuilding,
    } = useGetBuilding();

    return {
      filteredBuildings,
      searchText,
      isSharedBuildingReady,
      searchBuilding,
    };
  },
  emits: ["load-finish"],
  watch: {
    isSharedBuildingReady(curValue) {
      if (curValue) {
        this.$emit("load-finish");
      }
    },
  },
};
</script>
