export default {
	productAps(state) {
		return state.productAps;
	},
	productAp(state) {
		return state.productAp;
	},
	productApCurrentPage(state) {
		return state.currentPage;
	},
	productApNextPage(state) {
		return state.currentPage + 1;
	},
	productApTotalPage(state) {
		return state.totalPage;
	},
	productApIsLastPage(_, getters) {
		return (
			getters.productApCurrentPage === getters.productApTotalPage &&
			getters.productApTotalPage !== 0
		);
	},
};
