export default {
    resetUsePrepaidAndDeposits(state) {
        state.usePrepaidAndDeposits = [];
    },
    setUsePrepaidAndDeposits(state, payload) {
        state.usePrepaidAndDeposits = payload;
    },
    resetUnitExistPrepaids(state) {
        state.unitExistPrepaids = [];
    },
    setUnitExistPrepaids(state, payload) {
        state.unitExistPrepaids = payload;
    },
    setSelectedUsePrepaid(state, payload){
        state.selectedUsePrepaid = payload;
    },
}