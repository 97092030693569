export default {
  setJobAdmin(state, payload) {
    let jobAdmins = [...state.jobAdmin.jobAdmins];
    for (const jobAdmin of payload.jobAdmins) {
      if(jobAdmins.findIndex(c => c.id === jobAdmin.id) < 0){
        jobAdmins.push(jobAdmin);
      }
    }
    state.jobAdmin.jobAdmins = jobAdmins;
    state.jobAdmin.currentPage = payload.currentPage;
    state.jobAdmin.totalPage = payload.totalPage;
  },
  resetJobAdmin(state) {
    const jobAdmin = {
      jobAdmins: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.jobAdmin = jobAdmin;
  },
};
