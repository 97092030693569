export default {
  title: "รายการใบลดหนี้",
  dateRange: "ช่วงวันที่",
  documentCode: "เลขที่เอกสาร",
  date: "วันที่เอกสาร",
  unit: "ยูนิต",
  address: "บ้านเลขที่",
  customerName: "ชื่อลูกค้า",
  validateSearchText:"กรุณาระบุคำที่ต้องการค้นหา",
	searchTextPlaceholder: "กรอกข้อมูลเพื่อค้นหา",

  print: "พิมพ์",
  printed: "พิมพ์แล้ว",
  columnCreateBy: "ผู้สร้าง",
  columnUpdateBy: "ผู้แก้ไข",
  columnCreateDate:"วันที่สร้าง",
  columnUpdateDate:"วันที่แก้ไขล่าสุด", 
  columnPrice:"จำนวนเงิน",  
};
