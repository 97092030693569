export default {
  signatures(state) {
    return state.signatures;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
  selectedSignature(state) {
    return state.selectedSignature;
  },
  signatureReference(state){
    return state.signatureReference;
  },
  signatureAll(state){
    return state.signatureAll;
  },  
  signatureTotalPage(state){
    return state.signatureTotalPage;
  },  
  signatureCurrentPage(state) {
    return state.signatureCurrentPage;
  },
  signatureNextPage(state) {
    return state.signatureCurrentPage + 1;
  },
  signatureIsLastPage(_, getters) {
    return getters.signatureCurrentPage === getters.signatureTotalPage && getters.signatureTotalPage !== 0;
  } 
};
