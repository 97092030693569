export default {
    title: "Bill Acceptance",
    changeAPBook: "Change Book",
    selectAPBook: "Select Book",
    code: "Code",
    nameTH: "Name(TH)",
    nameEN: "Name(EN)",

    columnApprove: "Approve",
    columnIsCancel: "Canceled",
    columnCode: "DocumentCode",
    columnRefCode : "ReferenceCode",
    columnDate: "DocumentDate",
    columnDueDate: "Document DueDate",
    columnSupplier:"SupplierCode",
    columnSupplierName:"SupplierName",
    columnPaidAmount: "PaidAmount",
    columnNetAmount: "TotalAmount",
    type: "Type",

};
