export default {
  async getImportExistUnitResident(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL +"/Unit/GetImportExistUnitResident";
    const token = context.rootGetters.token;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });
    const responseData = await response.json();
    if (!response.ok) {
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get running number");
      throw error;
    }
    context.commit("setImportExistUnitResidents", responseData.data);
  },
  async getImportExistUnitResidents(context, payload) {
    context.dispatch("resetImportExistUnitResidents");
    let jsonArray = context.getters.params;
    while (jsonArray.length > 0) {
      let currentBatch  = jsonArray.splice(0, payload.limit);
      let params = {
          projectId: context.rootGetters.selectedProject.id,
      };
      if (payload.field == "unitCode") {
        params = { ...params, unitCode: currentBatch };
        await context.dispatch("getImportExistUnitResident", params);
      }
    }
  }, 
  resetImportExistUnitResidents(context) {
    context.commit("resetImportExistUnitResidents");
  },
  setParams(context, payload) {
    context.commit("setParams",payload);
  },
};
