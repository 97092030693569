export default {
	title: "รายละเอียดการแจ้งโอน",
	transferSlipList: "รายการแจ้งโอน",
	transferSlipImage: "หลักฐานการชำระเงิน",
	uploadDate: "วันที่อัปโหลด : ",		
	receiptCode: "เลขที่ใบเสร็จ",
	reasonsRejected: "เหตุผลที่ไม่ผ่าน",
	unitAddressNo: "บ้านเลขที่",
	pendingList: "รายการค้างชำระ",
	totalAmount: "ยอดรวมทั้งหมด",
	paidAmount: "ยอดชำระ",
	pendingAmount: "คงเหลือที่ต้องชำระ",
	columnItemNo: "ลำดับ",
	columnProductCode: "รหัสรายรับ",
	columnProductName: "ชื่อรายรับ",
	columnPeriod: "งวด",
	columnTotalAmount: "สถานะ",
	columnPaidAmount: "ยอดชำระ",
	createReceipt: "สร้างใบเสร็จ",
	reject: "ไม่ผ่าน",
	revertToWaiting: "คืนสถานะรอตรวจสอบ",
	selectRejectionReason: "ระบุเหตุผลที่ไม่ผ่าน",
	reason: "เหตุผล*",
	askForRevertToWaiting: "คุณต้องการจะคืนสถานะรายการนี้ เป็นรอตรวจสอบหรือไม่?", 
	validateRoleApprove: "คุณไม่มีสิทธิ์ใช้งานเมนูนี้",
	reasonSelect: "กรุณาเลือกเหตุผล",
	reasonDuplicated: "หลักฐานการชำระเงินซ้ำ",
	reasonFake: "หลักฐานการชำระเงินปลอม",
	reasonIncorrect: "หลักฐานการชำระเงินไม่ถูกต้อง",
	validateRoleCancel: "คุณไม่มีสิทธิ์ใช้งานเมนูนี้",
	validateSelectReason: "กรุณาระบุเหตุผล",
	titleChangedStatusSuccess: "เปลี่ยนสถานะสำเร็จ",
	changedStatusSuccess: "คุณเปลี่ยนสถานะรายการนี้เป็น “ไม่ผ่าน” สำเร็จ",
	titleRevertedToWaitingSuccess: "คืนสถานะรอตรวจสอบสำเร็จ",
	revertedToWaitingSuccess: "คุณคืนสถานะรายการนี้เป็น “รอตรวจสอบ” สำเร็จ",

};
