<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '70vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="titleHeaderImportData"
      :wordingConfirm="titleValidateImportData"
      :dataButtons="dataButtonDialog"
      @close="showValidateDialog = false"
    ></base-dialog>

    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">{{ lang.uploadFile }}</label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          :disabled="shouldDisableCloseButton"
          @click="closeDialog"
        />
      </div>
    </template>

    <div class="dropZone">
      <input
        type="file"
        id="dropZone-file"
        class="dropZone-file"
        ref="dropZoneFile"
        @click="resetFile"
        @change="handleFiles"
        multiple
        accept=".xlsx"
      />
      <div
        class="dropZone-wrapper"
        @dragenter.prevent=""
        @dragover.prevent=""
        @drop.prevent="handleFiles"
      >
        <label for="dropZone-file" class="cursor-pointer">
          <div class="pt-6"></div>
          <PvButton
            class="cursor-pointer p-button-rounded show-icon"
            disabled="disabled"
          >
            <icon-cloud-storage-upload iconColor="white" />
          </PvButton>
          <div class="cursor-pointer dropZone-label-text pt-4">
            <label
              for="dragFile"
              class="cursor-pointer font-md p-text-secondary"
              >{{ lang.dragFile }}</label
            >
            <label
              for="addFile"
              class="cursor-pointer font-md p-text-primary"
              @click="addFile"
              >{{ lang.addFile }}</label
            >
            <input
              id="add-file"
              type="file"
              style="display: none"
              accept=".xlsx"
              @click="resetFile"
              @change="handleFiles"
              class="cursor-pointer"
            />
          </div>
          <div class="cursor-pointer font-xs p-text-secondary pt-2 pb-8">
            <label for="importFileDesc" class="cursor-pointer">{{
              lang.importFileDesc
            }}</label>
            <label
              for="downloadfile"
              class="cursor-pointer p-text-primary"
              @click="downloadfile"
              >{{ lang.downloadfile }}</label
            >
          </div>
        </label>
      </div>
    </div>

    <div class="my-5 pt-6">
      <PvDataTable
        :value="files"
        dataKey="id"
        responsiveLayout="scroll"
        class="p-datatable-xs"
        :class="textTableClass"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn style="width: 87px; height: 56px" headerClass="h-center">
          <template #body>
            <PvButton
              v-if="isOpen"
              type="button"
              style="background: white; border: white; opacity: 1"
              disabled="disabled"
            >
              <icon-documents-file-checkmark iconColor="secondary" />
            </PvButton>
          </template>
        </PvColumn>

        <PvColumn
          field="detailName"
          :header="lang.fileName"
          style="min-width: 12rem"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-left font-normal p-text-primary"
        >
        </PvColumn>

        <PvColumn
          style="width: 2rem"
          headerClass="h-center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="">
            <PvButton
              v-if="isOpen"
              type="button"
              class="p-button-danger"
              style="background: white; border: white"
              @click="deleteFile"
            >
              <icon-trash-delete-bin iconColor="error" />
            </PvButton>
          </template>
        </PvColumn>
      </PvDataTable>
    </div>

    <div class="flex justify-content-center pt-4">
      <PvButton class="w-3" @click="uploadFile" :disabled="checkDisable">
        <span class="w-full text-center font-md font-normal p-button-label">
          <i
            v-if="isUpLoading"
            class="pi pi-spin pi-spinner font-normal mr-1"
          ></i>
          {{ lang.uploadFile }}
        </span>
      </PvButton>
    </div>
    <template #footer></template>

  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, ref, onMounted, watch } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useImportExcel from "../../../hooks/importExcel.js";
import { useRouter } from "vue-router";
import useGetImportExistResident from '../../../hooks/getImportExistResident.js';
import useGetCustomerGroup from '../../../hooks/getCustomerGroup.js';
import useGetNationality from '../../../hooks/getNationality.js';
import useGetAddress from "../../../hooks/getAddress";
import useGetCountryCode from "../../../hooks/getCountryCode";
export default {
  emits: ["close", "data-upload"],
  setup(props, context) {
    const { lang } = useLang();
    const { textTableClass } = useTextStyle();
    const { formatDate, formatDateToISOString, formatSubdistrict } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.resident.edit,
          ...lang.th.master.resident.import,
          ...lang.th.validateImport,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.resident.edit,
          ...lang.en.master.resident.import,
          ...lang.en.validateImport,
        };
      }
    });

    const router = useRouter();

    const { getDownloadBlob } = useGetFormPrint();
    const { getFields, removeDuplicates, validateFileCount, validateFileType, readFileContent, validateCountryCode, validatePrefix, validateAddress, validateAddressInDatabase, validateAddressRelationship, validateGender } = useImportExcel();
    const { setImportExistResidentParams, loadImportExistResident, getImportExistResident } = useGetImportExistResident();
    const { getAllCustomerGroups, isSharedCustomerGroupReady } = useGetCustomerGroup();
    const { getAllNationalities, isSharedNationalityReady } = useGetNationality();
    const { getAllAddress, isSharedAddressReady } = useGetAddress();
    const { getAllCountryCode, isSharedCountryCodeReady } = useGetCountryCode();

    onMounted(async () => {
      await getAllAddress();
    });

    let files = ref([]);
    let isOpen = ref(false);
    let file = ref(null);
    let rawDatas = ref([]);
    let isUpLoading = ref(false);
    let uploadFiles = ref([]);
    let titleHeaderImportData = ref("");
    let titleValidateImportData = ref("");
    let showValidateDialog = ref(false);
    let residentCodeImportResidents = ref([]);
    let customerGroupImportResidents = ref([]);
    let nationalImportResidents = ref([]);

    let billingLanguages = ref(["ไทย", "อังกฤษ"]);

    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const customerGroups = computed(() => {
      return store.getters["shared/customerCustomerGroups"];
    });
    const nationalities = computed(() => {
      return store.getters["shared/nationalityNationalities"];
    });
    const shouldDisableCloseButton = computed(() => {
      return isUpLoading.value;
    });

    const checkDisable = computed(() => {
      if (!file.value || isUpLoading.value) {
        return true;
      } else {
        return false;
      }
    });

    const dataButtonDialog = computed(() => {
      let result = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        }
      ];
      return result;
    });

    const setLoading = (bool) => {
      isUpLoading.value = bool;
    };

    const initiateData = () => {
      files.value = [];
      isOpen.value = false;
      file.value = null;
      titleHeaderImportData.value = mergeLang.value.notificationDialogHeader;
      titleValidateImportData.value = "";
      residentCodeImportResidents.value = [];
      rawDatas.value = [];
      uploadFiles.value = [];
    };

    const closeDialog = () => {
      context.emit("close");
      initiateData();
    };

    const resetFile = (event) => {
      if (event.target.files.length > 0) {
        event.target.value = null;
      }
    };

    const addFile = () => {
      document.getElementById("add-file").click();
    };

    const deleteFile = () => {
      initiateData();
    };

    const handleFiles = async (event) => {
      const inputValue = event.target.files || event.dataTransfer.files;
      uploadFiles.value = Array.from(inputValue);

      const checkFileCount = validateFileCount(uploadFiles.value, 1);
      if (checkFileCount.isError) {
        titleHeaderImportData.value = mergeLang.value.validateLimitFile;
        titleValidateImportData.value = mergeLang.value.validateFileType.replace(":name", checkFileCount.message),
        showValidateDialog.value = true;
        return;
      }

      file.value = inputValue[0];
      const checkFileType = validateFileType(file.value);
      if (checkFileType.isError) {
        titleHeaderImportData.value = mergeLang.value.validateImportFile;
        titleValidateImportData.value = mergeLang.value.validateFileType.replace(":name", checkFileType.message);
        showValidateDialog.value = true;
        file.value = null;
        return;
      }

      isOpen.value = true;
      const fileName = file.value.name;
      files.value = [
        {
          detailName: fileName,
        },
      ];
    };

    const getImportResidentForm = async () => {
      try {
        let payload = {
          key: process.env.VUE_APP_IMPORT_RESIDENT_TEMPLATE_S3_KEY,
          name: "template_import_resident.xlsx",
        }
        await store.dispatch("upload/downloadFile", payload);
      } catch (error) {
        titleValidateImportData.value = error;
        showValidateDialog.value = true;
        setLoading(false);
      }
    };

    const downloadfile = async () => {
      setLoading(true);
      await getImportResidentForm();
      setLoading(false);
    };

    const getResidentCodes = async () => {
      setImportExistResidentParams(rawDatas.value,"code");
      await loadImportExistResident();
      residentCodeImportResidents.value = getImportExistResident();

      // console.log("residentCodeImportResidents.value", residentCodeImportResidents.value);
    };

    const getCustomerGroups = () => {
      if (!isSharedCustomerGroupReady.value) {
        getAllCustomerGroups();
      }

      customerGroupImportResidents.value = [
        ...customerGroupImportResidents.value,
        ...customerGroups.value,
      ];

      // console.log("customerGroupImportResidents", customerGroupImportResidents.value);
    };

    const getNationalities = () => {
      if (!isSharedNationalityReady.value) {
        getAllNationalities();
      }

      nationalImportResidents.value = [
        ...nationalImportResidents.value,
        ...nationalities.value,
      ];

      // console.log("nationalImportResidents", nationalImportResidents.value);
    };

    const validateAndPrepareData = async () => {
      await getResidentCodes();
      getCustomerGroups();
      getNationalities();
      await getCountryCodes();
      await checkShouldPrepareData();
    };

    const mappingData = (data) => {
      // console.log("mapingData", data);
      data.status = "wait";
      data.reason = "";
      data.statusDescription = "";
      //เช็คข้อมูล resident code
      if (data.code) {
        let residentCode = residentCodeImportResidents.value.filter(
          (resident) => resident.code.trim() == data.code.trim()
        )[0];

        if (residentCode) {
          let error = {
            field: "code", 
            type: "duplicateInDatabase",
            data: data.code,
          };
          data.error.push(error)
        }
      }

      if (data.prefix) {
        let prefix = validatePrefix(data.prefix);
        if (!prefix) {
          let error = {
            field: "prefix",
            type: "doNotExist",
            data: data.prefix,
          };
          data.error.push(error);
        }
      }

      if (data.mobileCountryCode) {
        if (!validateCountryCode(data.mobileCountryCode)) {
          let error = {
            field: "mobileCountryCode", 
            type: "doNotExist",
            data: data.mobileCountryCode,
          };
          data.error.push(error);
        }
      }

      //เช็คข้อมูล customer group
      let customerGroupId = "";
      if (data.customerGroup) {
        let customerGroup = customerGroupImportResidents.value.filter(
          (customerGroup) => customerGroup.name.trim() == data.customerGroup.trim()
        )[0];
        customerGroupId = customerGroup ? customerGroup.id : "";

        if (!customerGroup) {
          let error = {
            field: "customerGroup", 
            type: "doNotExist",
            data: data.customerGroup,
          };
          data.error.push(error)
        }
      }

      //เช็คข้อมูล nationality
      let nationalityId = "";
      if (data.nationality) {
        let nationality = nationalImportResidents.value.filter(
          (nationality) => nationality.country.trim() == data.nationality.trim()
        )[0];
        nationalityId = nationality ? nationality.id : "";

        if (!nationality) {
          let error = {
            field: "nationality", 
            type: "doNotExist",
            data: data.nationality,
          };
          data.error.push(error)
        }
      }

      if (data.gender) {
        let gender = validateGender(data.gender);
        if (!gender) {
          let error = {
            field: "gender", 
            type: "doNotExist",
            data: data.gender,
          };
          data.error.push(error)
        }
      }

      if (data.emergencyContactPrefix) {
        let ecPrefix = validatePrefix(data.emergencyContactPrefix);
        if (!ecPrefix) {
          let error = {
            field: "emergencyContactPrefix",
            type: "doNotExist",
            data: data.emergencyContactPrefix,
          };
          data.error.push(error);
        }
      }

      if (data.emergencyCountryCode) {
        if (!validateCountryCode(data.emergencyCountryCode)) {
          let error = {
            field: "emergencyCountryCode", 
            type: "doNotExist",
            data: data.emergencyCountryCode,
          };
          data.error.push(error);
        }
      }

      let addressForCheck = [
        {
          subdistrict: data.billingAddressSubDistrict,
          district: data.billingAddressDistrict,
          province: data.billingAddress3,
          postCode: data.billingPostCode,
          field: "billingAddress",
        },
        {
          subdistrict: data.idCardAddressSubDistrict,
          district: data.idCardAddressDistrict,
          province: data.idCardAddress3,
          postCode: data.idCardPostCode,
          field: "idCardAddress",
        },
        {
          subdistrict: data.contactAddressSubDistrict,
          district: data.contactAddressDistrict,
          province: data.contactAddress3,
          postCode: data.contactPostCode,
          field: "contactAddress",
        },
      ];
      let addressIncomplete = validateAddress(addressForCheck);
      addressIncomplete.forEach((item) => {
        if (item.isError) {
          let errorObj = {
            field: item.field,
            type: "incompleteData",
            data: "",
          };
          data.error.push(errorObj);
        } else if (
          !item.isError &&
          item.subdistrict != "" &&
          item.district != "" &&
          item.province != "" &&
          item.postCode != ""
        ) {
          let addressDatabase = validateAddressInDatabase(item);
          if (addressDatabase.isError) {
            let field = item.field == "billingAddress"?"billing":item.field == "idCardAddress"?"idCard":"contact";
            if (!addressDatabase.subdistrict) {
              let fieldError = item.field + "SubDistrict";
              let errorObj = {
                field: fieldError,
                type: "doNotExist",
                data: item.subdistrict,
              };
              data.error.push(errorObj);
            }
            if (!addressDatabase.district) {
              let fieldError = item.field + "District";
              let errorObj = {
                field: fieldError,
                type: "doNotExist",
                data: item.district,
              };
              data.error.push(errorObj);
            }
            if (!addressDatabase.province) {
              let fieldError = item.field + "3";
              let errorObj = {
                field: fieldError,
                type: "doNotExist",
                data: item.province,
              };
              data.error.push(errorObj);
            }
            if (!addressDatabase.postCode) {
              let fieldError = field + "PostCode";
              let errorObj = {
                field: fieldError,
                type: "doNotExist",
                data: item.postCode,
              };
              data.error.push(errorObj);
            }
          } else {
            let addressRelationship = validateAddressRelationship(item);
            if (!addressRelationship) {
              let errorObj = {
                field: item.field,
                type: "invalidAddress",
                data: "",
              };
              data.error.push(errorObj);
            } else {
              if (item.field == "billingAddress") {
                data.billingAddress2 = formatSubdistrict(item);
              } else if (item.field == "idCardAddress") {
                data.idCardAddress2 = formatSubdistrict(item);
              } else {
                data.contactAddress2 = formatSubdistrict(item);
              }
            }
          }
        }
      });

      if (data.billingLanguage && !billingLanguages.value.includes(data.billingLanguage)) {
        let error = {
          field: "billingLanguage", 
          type: "doNotExist",
          data: data.billingLanguage,
        };
        data.error.push(error)
      }

      if (data.error.length > 0) {
        data.status = "invalid";
        data.statusDescription = data.error[0].field;
        let errorType = data.error[0].type;

        switch (data.error[0].type) {
          case "blank":
            errorType = mergeLang.value.validateBlank.replace(":field", setFieldLangError(data.error[0].field));
            break;
          case "duplicate":
            errorType = mergeLang.value.validateDuplicateInFile.replace(":field",setFieldLangError(data.error[0].field));
            break;
          case "duplicateInDatabase":
            errorType = mergeLang.value.validateDuplicateInDatabase.replace(":field",setFieldLangError(data.error[0].field)).replace(":table", mergeLang.value.labelFromResident);
            break;
          case "overflow":
            errorType = mergeLang.value.validateLength.replace(":field", setFieldLangError(data.error[0].field)).replace(":length", data.error[0].wordLength);
            break;
          case "doNotExist":
            errorType = mergeLang.value.validateDataNotFound.replace(":field", setFieldLangError(data.error[0].field));
            break;
          case "invalid":
            errorType = mergeLang.value.validateType.replace(":field", data.error[0].field);
            break;
          case "invalidDecimal":
            errorType = mergeLang.value.validateDecimalFormat.replace(":field", data.error[0].field);
            break;
          case "format":
            errorType = mergeLang.value.validateDateFormat.replace(":field", setFieldLangError(data.error[0].field));
            break;
        }
        data.reason = errorType;
      }
      
      data = {
        ...data,
        projectId: selectedProject.value.id,
        customerGroupId: customerGroupId,
        nationalityId: nationalityId,
        markStatus: "",
      }

      return data;
    };

    const prepareData = async () => {
      let dataResult = [];
      let i = 0;
      for (const data of rawDatas.value) {
        let result = mappingData(data);
        if (result) {
          i++;
          result = { ...result, id: i, itemNo: i };
          dataResult.push(result);
        }
      }

      // console.log("dataResult", dataResult);

      await store.dispatch(
        "importResident/importResidents",
        dataResult
      );
      context.emit("data-upload");
      router.push({ name: "preview-resident" });
      setLoading(false);
    };

    const uploadFile = async () => {
      setLoading(true);
      if (file.value) {
        let condition = [
          {
            name: "code",
            type: "string",
            wordLength: 25,
            isRequire: true,
            isUnique: true,
          },
          {
            name: "prefix",
            type: "prefix",
            wordLength: 25,
          },
          {
            name: "name",
            type: "string",
            wordLength: 100,
            isRequire: true,
          },
          {
            name: "identificationCardNumber",
            type: "string",
            wordLength: 20,
          },
          {
            name: "mobileCountryCode",
            type: "string",
            wordLength: 100,
          },
          {
            name: "mobileNumber",
            type: "string",
            wordLength: 100,
          },
          {
            name: "birthDate",
            type: "date",
            wordLength: 100,
          },
          {
            name: "email",
            type: "string",
            wordLength: 100,
          },
          {
            name: "nationality",
            type: "string",
            wordLength: 100,
          },
          {
            name: "gender",
            type: "string",
            wordLength: 100,
          },
          {
            name: "emergencyContactPrefix",
            type: "prefix",
            wordLength: 25,
          },
          {
            name: "emergencyContactName",
            type: "string",
            wordLength: 100,
          },
          {
            name: "emergencyCountryCode",
            type: "string",
            wordLength: 100,
          },
          {
            name: "emergencyContactMobileNumber",
            type: "string",
            wordLength: 100,
          },
          {
            name: "billingAddress1",
            type: "string",
            wordLength: 100,
          },
          {
            name: "billingAddressSubDistrict",
            type: "string",
            wordLength: 100,
          },
          {
            name: "billingAddressDistrict",
            type: "string",
            wordLength: 100,
          },
          {
            name: "billingPostCode",
            type: "string",
            wordLength: 100,
          },
          {
            name: "billingAddress3",
            type: "string",
            wordLength: 100,
          },
          {
            name: "billingLanguage",
            type: "string",
            wordLength: 100,
          },
          {
            name: "customerGroup",
            type: "string",
            wordLength: 100,
          },
          {
            name: "isGroupingInvoice",
            type: "string",
            wordLength: 100,
          },
          {
            name: "idCardAddress1",
            type: "string",
            wordLength: 100,
          },
          {
            name: "idCardAddressSubDistrict",
            type: "string",
            wordLength: 100,
          },
          {
            name: "idCardAddressDistrict",
            type: "string",
            wordLength: 100,
          },
          {
            name: "idCardPostCode",
            type: "string",
            wordLength: 100,
          },
          {
            name: "idCardAddress3",
            type: "string",
            wordLength: 100,
          },
          {
            name: "registerDate",
            type: "date",
            wordLength: 10,
          },
          {
            name: "expiredDate",
            type: "date",
            wordLength: 10,
          },
          {
            name: "contactAddress1",
            type: "string",
            wordLength: 100,
          },
          {
            name: "contactAddressSubDistrict",
            type: "string",
            wordLength: 100,
          },
          {
            name: "contactAddressDistrict",
            type: "string",
            wordLength: 100,
          },
          {
            name: "contactPostCode",
            type: "string",
            wordLength: 100,
          },
          {
            name: "contactAddress3",
            type: "string",
            wordLength: 100,
          },
        ];

        const result = await readFileContent(file.value, 3, 20000, condition);
        if (!result.success) {
          if (result.code == "001") {
            titleHeaderImportData.value =
              mergeLang.value.notificationDialogHeader;
            titleValidateImportData.value =
              mergeLang.value.validateImportNoData.replace(
                ":file",
                file.value.name
              );
          } else if (result.code == "002") {
            titleHeaderImportData.value =
              mergeLang.value.notificationDialogHeader;
            titleValidateImportData.value =
              mergeLang.value.validateImportMaxColumn.replace(
                ":maxColumn",
                "20,000"
              );
          } else {
            titleHeaderImportData.value =
              mergeLang.value.notificationDialogHeader;
            titleValidateImportData.value = result.message;
          }
          setLoading(false);
          showValidateDialog.value = true;
          return true;
        }
        rawDatas.value = result.data;
        validateAndPrepareData(); //validate database
        
      } else {
        titleValidateImportData.value = mergeLang.value.validateAddfile;
        showValidateDialog.value = true;
        return true;
      }
    };

    const setFieldLangError = (field) => {
      switch (field) {
        case "code":
          return mergeLang.value.code;
        case "name":
          return mergeLang.value.name;
        case "identificationCardNumber":
          return mergeLang.value.individualTaxId;
        case "mobileNumber":
          return mergeLang.value.mobileNumber;
        case "birthDate":
          return mergeLang.value.birthDate;
        case "email":
          return mergeLang.value.email;
        case "nationality":
          return mergeLang.value.nationality;
        case "gender":
          return mergeLang.value.gender;
        case "emergencyContactName":
          return mergeLang.value.contactName;
        case "emergencyContactMobileNumber":
          return mergeLang.value.mobileNumber;
        case "billingAddress1":
          return mergeLang.value.address;
        case "billingAddress2":
          return mergeLang.value.district;
        case "billingAddress3":
          return mergeLang.value.province;
        case "billingPostCode":
          return mergeLang.value.postCode;
        case "billingLanguage":
          return mergeLang.value.documentLang;
        case "customerGroup":
          return mergeLang.value.deliveryChannel;
        case "isGroupingInvoice":
          return mergeLang.value.groupingInvoice;
        case "idCardAddress1":
          return mergeLang.value.address;
        case "idCardAddress2":
          return mergeLang.value.district;
        case "idCardAddress3":
          return mergeLang.value.province;
        case "idCardPostCode":
          return mergeLang.value.postCode;
        case "registerDate":
          return mergeLang.value.registerDate;
        case "expiredDate":
          return mergeLang.value.expiredDate;
        case "contactAddress1":
          return mergeLang.value.address;
        case "contactAddress2":
          return mergeLang.value.district;
        case "contactAddress3":
          return mergeLang.value.province;
        case "contactPostCode":
          return mergeLang.value.postCode;
        case "prefix":
          return mergeLang.value.prefix;
        case "mobileCountryCode":
          return mergeLang.value.countryCode;
        case "emergencyContactPrefix":
          return mergeLang.value.prefix;
        case "emergencyCountryCode":
          return mergeLang.value.countryCode;
        case "billingAddress":
          return mergeLang.value.address;
        case "billingAddressSubDistrict":
          return mergeLang.value.subdistrict;
        case "billingAddressDistrict":
          return mergeLang.value.district;
        case "idCardAddress":
          return mergeLang.value.address;
        case "idCardAddressSubDistrict":
          return mergeLang.value.subdistrict;
        case "idCardAddressDistrict":
          return mergeLang.value.district;
        case "contactAddress":
          return mergeLang.value.address;
        case "contactAddressSubDistrict":
          return mergeLang.value.subdistrict;
        case "contactAddressDistrict":
          return mergeLang.value.district;
        default:
          return field;
      }
    };

    const checkShouldPrepareData = async () => {
      if (
        isSharedAddressReady.value &&
        isSharedCustomerGroupReady.value &&
        isSharedNationalityReady.value &&
        isSharedCountryCodeReady.value &&
        rawDatas.value.length > 0
      ) {
        await prepareData();
      }
    };

    const getCountryCodes = async () => {
      if (!isSharedCountryCodeReady.value) {
        await getAllCountryCode();
      }
    };

    watch(isSharedAddressReady, function () {
      checkShouldPrepareData();
    });

    return {
        lang: mergeLang,
        textTableClass,
        formatDate,
        formatDateToISOString,
        getDownloadBlob,
        getFields,
        removeDuplicates,

        //data
        files,
        isOpen,
        file,
        isUpLoading,
        showValidateDialog,
        titleHeaderImportData,
        titleValidateImportData,

        //methods
        closeDialog,
        uploadFile,
        resetFile,
        handleFiles,
        addFile,
        deleteFile,
        downloadfile,

        //computed
        shouldDisableCloseButton,
        checkDisable,
        dataButtonDialog,
    }
  },
};
</script>

<style scoped>
.show-icon {
  opacity: 1;
  width: 48px;
  height: 48px;
  padding: 0.75rem;
}
</style>