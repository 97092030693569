export default {
  setAPBook(state, payload) {
    let apBooks = [...state.apBook.apBooks];
    for (const apBook of payload.apBooks) {
      if (apBooks.findIndex((c) => c.bookId === apBook.bookId) < 0) {
        apBooks.push(apBook);
      }
    }
    state.apBook.apBooks = apBooks;
    state.apBook.currentPage = payload.currentPage;
    state.apBook.totalPage = payload.totalPage;
  },
  resetAPBook(state) {
    const apBook = {
      apBooks: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.apBook = apBook;
  },
};
