<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @goto-delete="askBeforeDelete"
    @goto-save="save"
  ></base-toolbar>

  <base-container>
    <base-crop-image-dialog
      v-model:visible="showCropImageDialog"
      :width="imageWidth"
      :height="imageHeight"
      :image="image"
      @close="showCropImageDialog = false"
      @confirm-crop="cropImageDialog"
    ></base-crop-image-dialog>

    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{ lang.titleEdit }}</label>
      <PvDivider></PvDivider>

      <section class="flex flex-wrap fontlabel pt-3" :class="textFormClass">
        <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pr-3">
          <base-input-text-limit
            :caption="lang.position + '*'"
            :limitMessage="50"
            :isError="positionError"
            :errorMessage="lang.validatePosition"
            :data="position"
            @data="updatePosition"
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 mt-0 pl-0 pr-0 lg:pr-3">
          <base-input-text-limit
            :caption="lang.signatorName + '*'"
            :limitMessage="50"
            :isError="nameError"
            :errorMessage="lang.validateName"
            :data="name"
            @data="updateName"
          />
        </div>

        <div class="col-12 mt-0">
          <div class="lg:col-12 md:col-12 col-12 pl-0 mt-1">
            <label class="font-normal">{{ lang.signature }}</label>
            <div class="mt-1">
              <small class="font-normal p-text-secondary">{{
                lang.signatureUrlInfo
              }}</small>
            </div>
          </div>

          <PvButton class="p-button-primary" @click="openFileDialog()" v-if="!signatureUrlBase64">
            <icon-cloud-storage-upload
              iconColor="white"
              width="16"
              height="16"
            />
            <label class="p-button-label ml-1">{{ lang.uploadFile }}</label>
          </PvButton>
          <input
            type="file"
            id="add-file"
            style="display: none"
            ref="fileInput"
            :accept="imageAllowedTypes"
            @change="handleFileChange"
            @click="resetFile"
          />
          <base-image-preview
            v-if="signatureUrlBase64"
            :imageUrl="signatureUrlBase64"
            @delete-image="deleteImage()"
          />
        </div>
        <div>
          <small v-if="signatureUrlError" id="image-url-help" class="p-error">
            {{ lang.validateImage }}
          </small>
        </div>
      </section>
    </div>
  </base-container>
</template>

<script>
import useTextStyle from "../../../hooks/textStyle.js";
import useLang from "../../../hooks/lang.js";
import { useStore } from "vuex";
import { computed, ref, onMounted } from "vue";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import BaseDialog from "../../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import usePageDirty from "../../../hooks/pageDirty";
import BaseCropImageDialog from "../../../components/UI/BaseCropImageDialog.vue";
import useUploadImageValidate from "../../../hooks/uploadImageValidate.js";
export default {
  components: {
    BaseCropImageDialog,
  },
  props: ["projectCode", "id"],
  setup(props) {
    const { lang } = useLang();
    const store = useStore();
    const router = useRouter();
    const { textFormClass } = useTextStyle();
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();
    const { isCompareObject } = usePageDirty();
    const { validateImageFileType, validateImageFileSize } = useUploadImageValidate();

    onMounted(async () => {
      setLoading(true);
      initiateData();
      await roleByMenuDataReady();
      checkShouldSetData();
    });

    let dataConfigDialog = ref(null);
    let position = ref("");
    let positionError = ref(false);
    let name = ref("");
    let nameError = ref("");
    let objToCompare = ref(null);
    let isPageDirty = ref(false);
    let showCropImageDialog = ref(false);
    let imageWidth = ref(62);
    let imageHeight = ref(35);
    let image = ref(null);
    let signatureUrl = ref("");
    let signatureUrlError = ref(false);
    let signatureUrlBase64 = ref("");
    let imageAllowedTypes = ref(["image/jpeg", "image/jpg", "image/png"]);

    //computeds
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.signatureManagement.signature.edit,
          ...lang.th.master.signatureManagement.signature.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.signatureManagement.signature.edit,
          ...lang.en.master.signatureManagement.signature.listing,
        };
      }
    });
    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const roleByMenu = computed(() => {
      return getRoleByMenuByCode("pms_rpt_signature");
    });
    const isRoleCreate = computed(() => {
      if (roleByMenu.value && roleByMenu.value.insert == "Y") {
        return true;
      }
      return false;
    });
    const isRoleUpdate = computed(() => {
      if (roleByMenu.value && roleByMenu.value.update == "Y") {
        return true;
      }
      return false;
    });
    const isRoleDelete = computed(() => {
      if (roleByMenu.value && roleByMenu.value.delete == "Y") {
        return true;
      }
      return false;
    });
    const isCreateDocument = computed(() => {
      return props.id === "create";
    });
    const title = computed(() => {
      let result = mergeLang.value.createSignator;
      if (!isCreateDocument.value) {
        result = mergeLang.value.editSignator;
      }
      return result;
    });
    const dataButtons = computed(() => {
      let result = [
        {
          id: "delete",
          action: "goto-delete",
          type: "delete",
        },
        {
          id: "save",
          action: "goto-save",
          type: "save",
        },
      ];
      return result;
    });
    const selectedSignature = computed(() => {
      return store.getters["signature/selectedSignature"];
    });
    const selectedFile = computed(() => {
      return store.getters["upload/selectedFile"];
    });
    const payload = computed(() => {
      return {
        projectId: selectedProject.value.id,
        position: position.value ? position.value.trim() : "",
        name: name.value ? name.value.trim() : "",
        signatureUrl: signatureUrl.value,
      };
    });

    //methods
    const setLoading = (bool) => {
      store.dispatch("setLoading", { value: bool });
    };
    const goBack = () => {
      setCompareObject();
      router.push({ name: "signature" });
    };
    const initiateData = () => {
      dataConfigDialog.value = null;
      position.value = "";
      positionError.value = false;
      name.value = "";
      nameError.value = false;
      objToCompare.value = null;
      isPageDirty.value = false;
      showCropImageDialog.value = false;
      imageWidth.value = 62;
      imageHeight.value = 35;
      image.value = null;
      signatureUrl.value = "";
      signatureUrlError.value = false;
      signatureUrlBase64.value = "";
    };
    const setDialog = (dataConfig) => {
      const classMap = {
        success: "p-text-success",
        error: "p-error",
      };

      dataConfig.classTitle = classMap[dataConfig.classTitle] || "";

      dataConfig.button = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "cancel",
        },
      ];

      if (dataConfig.type === "confirm") {
        dataConfig.button.push({
          id: "confirm",
          caption: mergeLang.value.buttonDialogConfirm,
          class: "w-full",
          action: "confirm",
        });
      }

      dataConfigDialog.value = {
        titleConfirm: dataConfig.title,
        classTitle: dataConfig.classTitle,
        wordingConfirm: dataConfig.message,
        dataButtons: dataConfig.button,
        display: true,
      };
    };
    const roleByMenuDataReady = async () => {
      if (isSharedRoleByMenuReady.value && !isCreateDocument.value) {
        await getSignatureById();
      }
    };
    const getSignatureById = async () => {
      try {
        const where = {
          projectId: selectedProject.value.id,
          id: props.id,
        };
        await store.dispatch("signature/getSignatureById", where);
      } catch (error) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: error,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        setLoading(false);
      }
    };
    const checkShouldSetData = () => {
      if (isSharedRoleByMenuReady.value) {
        if (!isCreateDocument.value && selectedSignature.value) {
          setData();
        } else if (isCreateDocument.value) {
          setLoading(false);
        }
        setCompareData();
      }
    };
    const setData = () => {
      position.value = selectedSignature.value.position;
      name.value = selectedSignature.value.name;
      signatureUrl.value = selectedSignature.value.signatureUrl
        ? selectedSignature.value.signatureUrl
        : "";
      signatureUrlBase64.value = selectedSignature.value.signatureUrl
        ? selectedSignature.value.signatureUrl
        : "";
      setLoading(false);
    };
    const setCompareData = () => {
      let data = {
        projectId: selectedProject.value.id,
        position: position.value ? position.value.trim() : "",
        name: name.value ? name.value.trim() : "",
        signatureUrl: signatureUrl.value,
      };
      if (!isCreateDocument.value) {
        data = {
          ...data,
          id: props.id,
        };
      }
      objToCompare.value = data;
      setCompareObject();
    };
    const setCompareObject = () => {
      let result = false;
      if (isCreateDocument.value) {
        result = isCompareObject(objToCompare.value, payload.value);
      } else {
        let data = {
          ...payload.value,
          id: props.id,
        };
        result = isCompareObject(objToCompare.value, data);
      }
      isPageDirty.value = result;
    };
    const save = async () => {
      if (validateRoleSave()) {
        return;
      }
      if (validateData()) {
        return;
      }
      setLoading(true);
      try {
        if (isCreateDocument.value) {
          await addSignature();
        } else {
          await updateSignature();
        }

        if (isCreateDocument.value) {
          await router.replace({
            name: "signature-edit",
            params: {
              id: selectedSignature.value.id,
            },
          });
        }
        setDialog({
          classTitle: "success",
          title: mergeLang.value.saveAllSucess,
          message: mergeLang.value.saveAllSucess,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();

        setCompareData();
        setLoading(false);
      } catch (error) {
        let message = error.message;
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: message,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        setLoading(false);
      }
      setLoading(false);
    };
    const addSignature = async () => {
      await store.dispatch("signature/createSignature", payload.value);
    };
    const updateSignature = async () => {
      let payloadUpdate = {
        ...payload.value,
        id: props.id,
      };
      await store.dispatch("signature/updateSignature", payloadUpdate);
    };
    const askBeforeDelete = async () => {
      if (isCreateDocument.value) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.validateDeleteTitle,
          message: mergeLang.value.validateDeleteContent,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        return true;
      } else if (!isCreateDocument.value && !isRoleDelete.value) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.validateRoleDelete,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        return true;
      }

      setDialog({
        classTitle: "",
        title: mergeLang.value.titleSignatorDeleteConfirm,
        message: mergeLang.value.contentSignatorDeleteConfirm
          .replace(":position", position.value)
          .replace(":name", name.value),
        type: "confirm",
      });
      const dialogResponse = await createConfirmDialog(
        BaseDialog,
        dataConfigDialog.value
      ).reveal();
      if (!dialogResponse.isCanceled) {
        await deleteData();
      }
    };
    const deleteData = async () => {
      setLoading(true);
      try {
        let payloadDelete = {
          ...payload.value,
          id: props.id,
        };
        await store.dispatch("signature/deleteSignature", payloadDelete);
        setLoading(false);

        setDialog({
          classTitle: "success",
          title: mergeLang.value.deleteSuccess,
          message: mergeLang.value.signatorDeleteSuccess,
        });
        const dialogResponse = await createConfirmDialog(
          BaseDialog,
          dataConfigDialog.value
        ).reveal();
        if (dialogResponse.isCanceled) {
          goBack();
        }
      } catch (error) {
        let message = error.message;
        if (error.code == "126") {
          message = mergeLang.value.errorSignatorDeleteHaveRefer;
        }
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: message,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        setLoading(false);
      }
    };
    const validateRoleSave = () => {
      if (isCreateDocument.value && !isRoleCreate.value) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.validateRoleInsert,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        return true;
      } else if (!isCreateDocument.value && !isRoleUpdate.value) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.validateRoleUpdate,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        return true;
      }
    };
    const validateData = () => {
      let returnStatus = false;
      if (!position.value) {
        positionError.value = true;
        returnStatus = true;
      }
      if (!name.value) {
        nameError.value = true;
        returnStatus = true;
      }
      // if (!signatureUrl.value) {
      //   signatureUrlError.value = true;
      //   returnStatus = true;
      // }
      if (returnStatus) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.validateSaveTitle,
          message: wordingValidate(),
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
      }

      return returnStatus;
    };
    const wordingValidate = () => {
      if (!position.value) {
        return mergeLang.value.validateFieldNoValue + mergeLang.value.position;
      }
      if (!name.value) {
        return (
          mergeLang.value.validateFieldNoValue + mergeLang.value.signatorName
        );
      }
      if (!signatureUrl.value) {
        return mergeLang.value.validateImage;
      }
    };
    const updatePosition = (data) => {
      position.value = data;
      positionError.value = false;
    };
    const updateName = (data) => {
      name.value = data;
      nameError.value = false;
    };
    const handleFileChange = (event) => {
      if (event) {
        const selectedFile = event.target.files[0];
        if (validateFileImage(selectedFile)) {
          return;
        }
        const { files } = event.target;
        if (files && files[0]) {
          const blob = URL.createObjectURL(files[0]);
          image.value = {
            src: blob,
            type: files[0].type,
          };
        }
        imageWidth.value = 86;
        imageHeight.value = 48;
        showCropImageDialog.value = true;
      }
    };
    const resetFile = (event) => {
      if (event.target.files.length > 0) {
        event.target.value = null;
      }
    };
    const validateFileImage = (file) => {
      let result = false;
      let message = "";
      if (validateImageFileType(file, imageAllowedTypes.value)) {
        message = mergeLang.value.validateTypeSignatureUrlContent;
        result = true;
      } else if (validateImageFileSize(file)) {
        message = mergeLang.value.validateSizeSignatureUrlContent;
        result = true;
      }
      if (result) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.validateSignatureUrlTitle.replace(
            ":name",
            file.name
          ),
          message: message,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
      }
      return result;
    };
    const deleteImage = () => {
      signatureUrl.value = "";
      signatureUrlBase64.value = "";
    };
    const uploadFileToApi = async (file) => {
      store.getters["upload/resetSelectedFile"];
      try {
        await store.dispatch("upload/UploadFileToS3AsPublic", { file: file });
      } catch (error) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: error,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
      }
    };
    const cropImageDialog = async (imgBase64, file) => {
      showCropImageDialog.value = false;
      await uploadFileToApi(file);
      if (file && selectedFile.value) {
        const key = selectedFile.value.key;
        signatureUrl.value = `${process.env.VUE_APP_BACKEND_URL}/Aws/inline/public/${key}`;
        signatureUrlBase64.value = imgBase64;
      } else {
        console.error("Both rawTextData and htmlTextData must have values.");
      }
    };

    onBeforeRouteLeave(async (to, from) => {
      if (isPageDirty.value) {
        var curValue = to.params.projectCode;
        var oldValue = from.params.projectCode;
        if (curValue == oldValue) {
          setDialog({
            classTitle: "",
            title: mergeLang.value.notificationDialogHeader,
            message: mergeLang.value.pageDirtyConfirmMessage,
            type: "confirm",
          });
          const dialogResponse = await createConfirmDialog(
            BaseDialog,
            dataConfigDialog.value
          ).reveal();
          return !dialogResponse.isCanceled;
        }
      }
      return true;
    });

    return {
      lang: mergeLang,
      textFormClass,

      position,
      positionError,
      name,
      nameError,
      signatureUrlError,
      signatureUrlBase64,
      showCropImageDialog,
      imageWidth,
      imageHeight,
      image,
      imageAllowedTypes,

      //computeds
      title,
      dataButtons,

      //methods
      goBack,
      save,
      askBeforeDelete,
      deleteData,
      updatePosition,
      updateName,
      handleFileChange,
      resetFile,
      cropImageDialog,
      deleteImage,
    };
  },
  methods: {
    openFileDialog() {
      this.signatureUrlError = false;
      this.$refs.fileInput.click();
    },
  },
};
</script>
