export default {
	async getProductARs(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Product/AR";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}
    	url = encodeURI(url);

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get productAR");
			throw error;
		}
		// console.log('productAR',responseData);

		// const data = responseData.data;

		const productAR = {
			products: responseData.data,
			// totalPage: responseData.page.pageCount,
			totalPage: responseData.page.pageCount == 0 ? 1 : responseData.page.pageCount,
			currentPage: responseData.page.currentPage,
		};

		// console.log('productAR=',productAR);
		context.commit("setProductAR", productAR);
	},
	async getAllProductARs(context, payload) {
		while (!context.getters.productArIsLastPage) {
			let where = payload.where;
			const payloadP = {
				page: context.getters.productArNextPage || 1,
				where,
			};
			await context.dispatch("getProductARs", payloadP);
		}
	},
	resetProductAR(context) {
		context.commit("resetProductAR");
	},

	async getPenaltyRateStep(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Product/GetPenaltyRateStep";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}
    	url = encodeURI(url);

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get productAR");
			throw error;
		}

		// console.log('setPenaltyRateStep=',responseData);
		context.commit("setPenaltyRateStep", responseData);
	},
	async AddEditPenaltyRateStep(context, payload) {
		// console.log('AddBankTransaction',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/Product/UpdatePenaltyRateStep";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("AddBankTransaction>>>",responseData);
			const error = new Error(responseData.message || "Failed to add PenaltyRateStep");
			throw error;
		}
		//console.log("AddPrepaid_responseData>>>",responseData);
		await context.commit("setPenaltyRateStep", responseData);
	},
	async DeletePenaltyRateStep(context, payload) {
		// console.log('AddBankTransaction',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/Product/DeletePenaltyRateStep";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "Delete",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("AddBankTransaction>>>",responseData);
			const error = new Error(responseData.message || "Failed to add PenaltyRateStep");
			throw error;
		}
		//console.log("AddPrepaid_responseData>>>",responseData);
		await context.commit("setPenaltyRateStep", responseData);
	},

	async getArProduct(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Product/AR";
		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}
		if (payload.page) {
			url = url + "&page=" + payload.page;
		}
		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}
    	url = encodeURI(url);
		// console.log('url=',url);
		const token = context.rootGetters.token;
		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});
		const responseData = await response.json();
		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Purchase Receipt");
			throw error;
		}
		// console.log(responseData);
		context.commit("setArProducts", responseData);
	},

	async getArProductById(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Product/GetArProduct";
		if (payload) {
			url = url + "/" + payload;
		}
    	url = encodeURI(url);
		// console.log('url=',url);
		const token = context.rootGetters.token;
		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});
		const responseData = await response.json();
		if (!response.ok) {
			//console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Purchase Receipt");
			throw error;
		}
		// console.log(responseData);
		context.commit("setSelectedArProduct", responseData.data);
	},

	async deleteArProduct(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Product/DeleteArProduct/";
		if (payload.productId) {
			url = url + payload.productId;
		}
		const token = context.rootGetters.token;
		const response = await fetch(url, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});
		const responseData = await response.json();
		if (!response.ok) {
			// console.log(responseData);
			throw responseData;
		}
		// console.log(responseData);
		// context.commit("setArProducts", responseData);
	},
	resetArProduct(context) {
		context.commit("resetArProduct", null);
	},

	async addUpdateArProduct(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + `/Product/${payload.actionType}`;
		// const fallbackErrorMessage = `Failed to ${payload.actionType}`;
		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},

			body: JSON.stringify(payload),
		});

		//  console.log("payload=",JSON.stringify(payload));
		const responseData = await response.json();

		// if (!response.ok) {
		//   let message = null;
		//   if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
		//     message = responseData.title;
		//     for (const key in responseData.errors) {
		//       message += ", " + key.replace("$.", "");
		//     }
		//   } else if (
		//     Object.prototype.hasOwnProperty.call(responseData, "message")
		//   ) {
		//     message = responseData.message;
		//   }
		//   let error = new Error(message || fallbackErrorMessage);
		//   if (responseData.code == "101") error = responseData.code;
		//   throw error;
		// }
		if (!response.ok) {
			throw responseData;
		}
		// console.log('action addUpdateAccountChart',responseData.data);
		await context.commit("setSelectedArProduct", responseData.data);
	},
	async getUnitProduct(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Unit/GetAllUnitProductSetupForInvoice";
		if (!payload.limit) {
			url = url + "?limit=100";
		} else {
			url = url + "?limit=" + payload.limit;
		}
		if (payload.page) {
			url = url + "&page=" + payload.page;
		}
		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}
    	url = encodeURI(url);
		// console.log('url=',url);
		const token = context.rootGetters.token;
		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});
		const responseData = await response.json();
		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Purchase Receipt");
			throw error;
		}
		// console.log(responseData);
		context.commit("setUnitProduct", responseData);
	},
	async getAllUnitProduct(context, payload) {
		await context.commit("resetAllUnitProduct");
		while (!context.getters.allUnitProductsIsLastPage) {
			// let where = payload.where;
			const payloadAllAllUnitProduct = {
				limit: payload.limit,
				page: context.getters.unitProductNextPage || 1,
				where: payload.where,
			};
			await context.dispatch("getMergeUnitProduct", payloadAllAllUnitProduct);
		}
	},
	async getMergeUnitProduct(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Unit/GetAllUnitProductSetupForInvoice";

		if (!payload.limit) {
			url = url + "?limit=100";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}
    	url = encodeURI(url);

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get invoice");
			throw error;
		}
		// console.log("getAccountingEntries", responseData);

		responseData.data = [...responseData.data, ...context.getters.allUnitProducts];
		await context.commit("setAllUnitProduct", responseData);
	},
	resetAllUnitProduct(context){
		context.commit("resetAllUnitProduct");
	}
};
