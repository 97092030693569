export default {
  async getInvoices(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Invoice";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + encodeURIComponent(JSON.stringify(payload.where));
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get invoice");
      throw error;
    }
    // console.log(responseData);

    // const data = responseData.data;
    context.commit("setInvoice", responseData);
  },
  async getInvoice(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Invoice?limit=1";

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get invoice");
      throw error;
    }
    // console.log(responseData);

    // const data = responseData.data;
    if (responseData.data.length > 0) {
      context.commit("setSelectedInvoice", responseData.data[0]);
    }
  },
  async createOrUpdateInvoice(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Invoice/${payload.type}`;
    const fallbackErrorMessage = `Failed to ${payload.type} invoice`
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: payload.type === 'Add' ? 'post' : 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if (Object.prototype.hasOwnProperty.call(responseData, 'errors')) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "")
        }
      } else if (Object.prototype.hasOwnProperty.call(responseData, 'message')) {
        message = responseData.message
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }
    // console.log(responseData);

    // const data = responseData.data;
    // if (responseData.data.length > 0) {
    //   context.commit("setSelectedInvoice", responseData.data);
    // }
    context.commit("setSelectedInvoice", responseData.data);
  },
  async cancelInvoice(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Invoice/Cancel`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if (Object.prototype.hasOwnProperty.call(responseData, 'errors')) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "")
        }
      } else if (Object.prototype.hasOwnProperty.call(responseData, 'message')) {
        message = responseData.message
      }
      const error = new Error(message || 'Failed to cancel invoice');
      throw error;
    }

    context.commit("setSelectedInvoice", responseData.data);
  },
  resetInvoice(context) {
    context.commit("setSelectedInvoice", null);
  },
  async getReferenceDocument(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Invoice/GetReferenceDocument";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get Reference Document");
      throw error;
    }
    // console.log(responseData);

    // const data = responseData.data;
    context.commit("setDataReferenceDocument", responseData);
  },
  async PrintInvoice(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Invoice/Print";

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(payload),
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url

    });

    let urlBlob = ''
    let blob = await response.blob();
    urlBlob = window.URL.createObjectURL(blob);
    context.commit('setBlob', urlBlob);

  },
  async unlockInvoice(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Invoice/Unlock`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if (Object.prototype.hasOwnProperty.call(responseData, 'errors')) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "")
        }
      } else if (Object.prototype.hasOwnProperty.call(responseData, 'message')) {
        message = responseData.message
      }
      const error = new Error(message || 'Failed to unlock invoice');
      throw error;
    }
    // context.commit("setInvoice", responseData);    
  },
  resetInvoiceDateForPrintLot(context) {
    context.commit("resetInvoiceDateForPrintLot");
  },
  async getInvoiceDateForPrintLots(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Invoice/InvoiceGroupByDate";
    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }
    if (payload.page) {
      url = url + "&page=" + payload.page;
    }
    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }
    const token = context.rootGetters.token;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get invoice");
      throw error;
    }
    const invoiceDateForPrintLot = {
      invoiceDateForPrintLots: responseData.data,
      invoiceDateForPrintLotCurrentPage: responseData.page.currentPage,
      invoiceDateForPrintLotTotalPage: responseData.page.pageCount,
    };
    context.commit("setInvoiceDateForPrintLot", invoiceDateForPrintLot);
  },
  async getAllInvoiceDateForPrintLots(context, payload) {
    while (!context.getters.invoiceDateForPrintLotIsLastPage) {
      let where = payload.where;
      const payloadPrepare = {
        page: context.getters.invoiceDateForPrintLotNextPage || 1,
        where,
      };
      await context.dispatch("getInvoiceDateForPrintLots", payloadPrepare);
    }
  },
  resetInvoiceDateForCombineUnit(context) {
    context.commit("resetInvoiceDateForCombineUnit");
  },
  async getInvoiceDateForCombineUnits(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Invoice/InvoiceGroupByDate";
    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }
    if (payload.page) {
      url = url + "&page=" + payload.page;
    }
    payload.where = { ...payload.where, ...{ isCombineInvoice: true } };
    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }
    const token = context.rootGetters.token;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get invoice");
      throw error;
    }
    const invoiceDateForCombineUnit = {
      invoiceDateForCombineUnits: responseData.data,
      invoiceDateForCombineUnitTotalPage: responseData.page.pageCount,
      invoiceDateForCombineUnitCurrentPage: responseData.page.currentPage,
    };
    context.commit("setInvoiceDateForCombineUnit", invoiceDateForCombineUnit);
  },
  async getAllInvoiceDateForCombineUnits(context, payload) {
    while (!context.getters.invoiceDateForCombineUnitIsLastPage) {
      let where = payload.where;
      const payloadPrepare = {
        page: context.getters.invoiceDateForCombineUnitNextPage || 1,
        where,
      };
      await context.dispatch("getInvoiceDateForCombineUnits", payloadPrepare);
    }
  },
  resetInvoiceDataByAtDate(context) {
    context.commit("resetInvoiceDataByAtDate");
  },
  async getAllInvoiceDataByAtDates(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Invoice/GetInvoiceAtDate";
    url = url + "?projectId=" + payload.projectId;    
    url = url + "&date=" + payload.date;
    url = url + "&isCombineInvoice=" + payload.isCombineInvoice;
		url = encodeURI(url);
    const token = context.rootGetters.token;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get invoice");
      throw error;
    }
    const invoiceDataByAtDate = {
      invoiceDataByAtDates: responseData.data,
      invoiceDataByAtDateTotalPage: responseData.page.pageCount,
      invoiceDataByAtDateCurrentPage: responseData.page.currentPage,
    };
    context.commit("setInvoiceDataByAtDate", invoiceDataByAtDate);
  },
  async getInvoiceNotification(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Invoice/GetInvoiceNotification";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get Invoice Notification");
      throw error;
    }
    // console.log(responseData);

    // const data = responseData.data;
    if (payload.isAllInvoice) {
      context.commit("setAllInvoiceNotification", responseData);
    } else {
      context.commit("setInvoiceNotification", responseData);
    }
  },

  resetAllInvoiceNotification(context){
    context.commit("resetAllInvoiceNotification");
  },

  async pushNotification(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Invoice/PushNotification`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'post',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!responseData.success) {
      // console.log(responseData);
      let message = null
      if (Object.prototype.hasOwnProperty.call(responseData, 'errors')) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "")
        }
      } else if (Object.prototype.hasOwnProperty.call(responseData, 'message')) {
        message = responseData.message
      }
      const error = new Error(message || 'Failed to push notification');
      throw error;
    }
    // console.log(responseData);
    // context.commit("setSelectedInvoice", responseData.data);
  },
  async getAllInvoiceNotification(context, where) {
    while (!context.getters.invoiceNotifyIsLastPage) {

      const payload = {
        page: context.getters.invoiceNotifyNextPage || 1,
        limit: 100,
        isAllInvoice: true,
        where,
      };
      await context.dispatch("getInvoiceNotification", payload);
    }

  },

  //หน้าใบเสร็จ ใช้หายอดค้างคงเหลือ
  async getPendingAmountByUnitAndLivingType(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL +"/Invoice/PendingAmountByUnitAndLivingType";

    if (payload.unitId) {
      url = url + "?" + "unitId=" + payload.unitId;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get pending amount by unit and living type");
      throw error;
    }

    await context.commit("setPendingAmountByUnitAndLivingType", responseData.data);
  },
  resetPendingByUnitAndLivingType(context) {
    context.commit("setPendingAmountByUnitAndLivingType", []);
  },
  async getPendingList(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL +"/Invoice/PendingList";

    const token = context.rootGetters.token;

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }
		url = encodeURI(url);

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get pending list");
      throw error;
    }
    await context.commit("setPendingList", responseData);
  },
  async getAllPendingList(context, payload) {
		while (!context.getters.pendingListIsLastPage) {
		  let where = payload.where;
		  const setPayload = {
			page: context.getters.pendingListNextPage || 1,
			where,
		  };
		  await context.dispatch("getPendingList", setPayload);
		}
	},
  resetPendingList(context) {
    context.commit("resetPendingList");
  },
  async getPendingByAmount(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL +"/Invoice/PendingByAmount";

    if (payload.projectId) {
      url = url + "?projectId=" + payload.projectId;
    }
    if (payload.unitId) {
      url = url + "&unitId=" + payload.unitId;
    }
    if (payload.residentType) {
      url = url + "&residentType=" + payload.residentType;
    }
    if (payload.dateAt) {
      url = url + "&dateAt=" + payload.dateAt;
    }
    if (payload.payAmount != null) {
      url = url + "&payAmount=" + payload.payAmount;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get pending by amount");
      throw error;
    }
    // console.log(responseData.data);

    await context.commit("setPendingByAmount", responseData.data);
  },

  
  // importInvoice
  resetImportInvoiceList(context) {
    context.commit("resetImportInvoiceList");
  },
  importInvoiceList(context, payload) {
    context.commit("setImportInvoiceList", payload);
  },  
  async validateImportInvoice(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Invoice/ValidateImportInvoice`;
    const token = context.rootGetters.token;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();
    if (!response.ok) {
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to validate and prepare import invoive.");
      throw error;
    }
    // console.log("setValidatedImportInvoice=",responseData);
    await context.commit('setValidatedImportInvoice', responseData); 
  },
  
  async getInvoiceById(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Invoice";
    
    if (payload) {
      url = url + "/" + payload;
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get invoice");
      throw error;
    }
    // console.log(responseData);
    context.commit("setSelectedInvoice", responseData.data);
    
  },
  
};
