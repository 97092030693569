<template>
  <base-container>
    <edit-report-menu-detail-dialog
        v-model:visible="showEditReportMenuDetailModal"
        @close="closeReportMenuDetailDialog"
        @saved="saved"
        :reportMenuDetail="selectedReportMenuDetail"
        :reportMenuId="id"
        :openDialog="openDialog"
    ></edit-report-menu-detail-dialog>
    <base-dialog
      v-model:visible="showConfirmDeleteDialog"
      :titleConfirm="lang.confirmDeleteTitle"
		  :wordingConfirm="confirmDeleteDescription"
      @confirm="deleteData()"
      @close="showConfirmDeleteDialog = false"
    ></base-dialog>
    <div class="flex justify-content-between align-items-center">
      <div class="my-3">
        <PvButton
          icon="pi pi-chevron-left"
          :label="lang.back"
          @click="goBack"
          class="mr-2 p-button-secondary"
        />
      </div>
      <h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
    </div>
    <div class="surface-card shadow-2 p-3 border-round mt-5 mb-5">
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <PvButton
            icon="pi pi-plus"
            :label="lang.addNewReportMenuDetail"
            class="mr-2 mb-2"
            @click="addReportMenuDetail"
          />
        </div>
        <section class="flex flex-wrap" :class="textFormClass">
          <label for="searchText">{{ lang.searchText }}</label>
          <div class="p-inputgroup">
            <PvInputText
              id="searchText"
              type="text"
              class="inputfield w-full"
              :placeholder="lang.search"
              v-model="searchText"
              @keyup.enter="onSearchInputKeyUp"
            />
            <PvButton icon="pi pi-search" @click="getReportMenuDetails" />
          </div>
        </section>
      </div>

      <PvDataTable
        showGridlines
        :value="reportMenuDetails"
        v-model:selection="selectedReportMenuDetail"
        selectionMode="single"
        dataKey="id"
        responsiveLayout="scroll"
        :stripedRows="true"
        class="p-datatable-sm"
        :class="textTableClass"
        :loading="isLoading"
        @row-dblclick="editReportMenuDetail(selectedReportMenuDetail.data)"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn
          field="sequence"
          :header="lang.sequence"
          style="min-width: 4rem"
          headerClass="h-center"
          bodyClass="text-center"
        ></PvColumn>
        <PvColumn
          field="category"
          :header="lang.category"
          style="min-width: 4rem"
          headerClass="h-center"
          bodyClass="text-center"
        ></PvColumn>
        <PvColumn
          field="projectCode"
          :header="lang.project"
          style="min-width: 4rem"
          headerClass="h-center"
        ></PvColumn>
        <PvColumn
          field="nameThai"
          :header="lang.nameTh"
          style="min-width: 4rem"
          headerClass="h-center"
        ></PvColumn>
        <PvColumn
          field="nameEnglish"
          :header="lang.nameEn"
          style="min-width: 4rem"
          headerClass="h-center"
        ></PvColumn>
        <PvColumn
          field="component"
          :header="lang.component"
          style="min-width: 4rem"
          headerClass="h-center"
        ></PvColumn>
        <PvColumn
          field="path"
          :header="lang.path"
          style="min-width: 4rem"
          headerClass="h-center"
        ></PvColumn>
        <PvColumn
          field="isActive"
          :header="lang.activeStatus"
          style="min-width: 4rem"
          headerClass="h-center"
          bodyClass="text-center"
        ></PvColumn>
        <PvColumn
          style="max-width: 4rem"
          headerClass="h-center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <PvButton
              type="button"
              class="p-button-secondary p-button-text py-0"
              icon="pi pi-pencil"
              @click="editReportMenuDetail(slotProps.data)"
            ></PvButton>
            <PvButton
              type="button"
              class="p-button-secondary p-button-text py-0"
              icon="pi pi-trash"
              @click="askForDelete(slotProps.data)"
            ></PvButton>
          </template>
        </PvColumn>
        <template #footer>
          <PvPaginator
            :rows="rowsPerPage"
            :totalRecords="totalRecords"
            @page="onPageChange($event)"
            :rowsPerPageOptions="[10, 50, 100]"
          >
            <template #start="slotProps">
              {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /
              {{ totalPage }}
            </template>
          </PvPaginator>
        </template>
      </PvDataTable>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import EditReportMenuDetailDialog from "../../../components/admin/report/EditReportMenuDetailDialog.vue";
import BaseDialog from '@/components/UI/BaseDialog.vue';
export default {
    props: ["id", "projectCode"],
    components: {
      EditReportMenuDetailDialog,
      BaseDialog,
    },
    setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.admin.report };
      } else {
        return { ...lang.en.base, ...lang.en.admin.report };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
    };
  },
  data() {
    return {
      isLoading: false,
      searchText: "",
      selectedReportMenuDetail: null,
      selectedPage: 1,
      rowsPerPage: 10,
      showEditReportMenuDetailModal: false,
      showConfirmDeleteDialog: false,
      openDialog: "",
    }
  },
  async mounted() {
    if(!this.selectedReportMenu) {
      await this.$store.dispatch("reportMenu/getReportMenu", {
        id: this.id,
      });
    }
    await this.getReportMenuDetails();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "admin-report-menu"});
    },
    addReportMenuDetail() {
      this.openDialog = new Date().getSeconds().toString();
      this.showEditReportMenuDetailModal = true;
    },
    onSearchInputKeyUp(event) {
      if (event.key == "Enter") {
        this.getReportMenuDetails();
      }
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getReportMenuDetails();
    },
    async getReportMenuDetails() {
        this.isLoading = true;

        let where = {
          ReportMenuId: this.id,
          SearchText: this.searchText,
        };

        const payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          where,
        };

        await this.$store.dispatch("reportMenuDetail/getReportMenuDetail", payload);

        this.isLoading = false;
    },
    editReportMenuDetail(data) {
      this.openDialog = new Date().getSeconds().toString();
      this.selectedReportMenuDetail = data;
      this.showEditReportMenuDetailModal = true;
    },
    askForDelete(data) {
      this.selectedReportMenuDetail = data
      this.showConfirmDeleteDialog = true;
    },
    async deleteData() {
      try { 
        this.showConfirmDeleteDialog = false;
        this.setLoading(true); 

        let payload = {
          id: this.selectedReportMenuDetail.id,
          reportMenuId : this.id
        }

        // console.log("deleteData", payload);

        await this.$store.dispatch("reportMenuDetail/deleteReportMenuDetail", payload);

        await this.getReportMenuDetails();

        this.selectedReportMenuDetail = null;
        this.$toast.add({
          severity: "success",
          summary: this.lang.deleteSuccess,
          detail: "",
          life: 5000,
        });

        this.setLoading(false); 
        
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 5000,
        });
        this.setLoading(false); 
      }
    },
    saved() {
      this.getReportMenuDetails();
    },
    closeReportMenuDetailDialog() {
      this.showEditReportMenuDetailModal = false;
      this.selectedReportMenuDetail = null;
    },
  },
  computed: {
    reportMenuDetails() {
      return this.$store.getters["reportMenuDetail/reportMenuDetails"];
    },
    totalPage() {
      return this.$store.getters["reportMenuDetail/totalPage"];
    },
    currentPage() {
      return this.$store.getters["reportMenuDetail/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    selectedReportMenu() {
      return this.$store.getters["reportMenu/selectedReportMenu"];
    },
    title() {
      let result = "";
      if(this.selectedReportMenu) {
        result = this.lang.titleDetail.replace(":reportMenu", this.selectedReportMenu.nameThai);
      }
      return result;
    },
    confirmDeleteDescription() {
      let result = "";
      if(this.selectedReportMenuDetail) {
        result = this.lang.confirmDeleteDesc.replace(":name", this.selectedReportMenuDetail.nameThai);
      }
      return result;
    }
  }
};
</script>
