export default {
	createAsset: "สร้างทะเบียนทรัพย์สิน",
    editAsset: "แก้ไขทะเบียนทรัพย์สิน",

    createAssetRegister: "สร้างใหม่",
    saveLot: "บันทึก Lot",
    code: "หมายเลขสินทรัพย์*",
    type: "หมวดการ Running",
    type1: "1. ทรัพย์สินที่นิติฯ ซื้อเอง",
    type2: "2. ทรัพย์สินที่เจ้าของโครงการมอบให้",
    type3: "3. ทรัพย์สินที่ได้มาฟรี (บริจาค ของแถม ฯลฯ)",
    typePlaceholder: "เลือกประเภททรัพย์สิน",
    ownership: "กรรมสิทธิ์",
    ownership1: "ทรัพย์สินนิติ",
    ownership2: "ทรัพย์สินร่วม",
    nameTh: "ชื่อสินทรัพย์(ไทย)*",
    nameEn: "ชื่อสินทรัพย์(อังกฤษ)",
    assetAccount: "รหัสบัญชี*",
    depreciationAccount: "บัญชีค่าเสื่อมราคา*",
    accumAccount: "บัญชีค่าเสื่อมราคาสะสม*",
    validateNoAssetAccountSelect: "กรุณาเลือกรหัสบัญชี",
    validateNoDepreAccountSelect: "กรุณาเลือกบัญชีค่าเสื่อมราคา",
    validateNoAccumAccountSelect: "กรุณาเลือกบัญชีค่าเสื่อมราคาสะสม",
    validateNoNameTh: "กรุณาระบุชื่อสินทรัพย์(ไทย)",
    validateNoCode: "กรุณาระบุหมายเลขสินทรัพย์",
    purchaseInformation: "ข้อมูลการซื้อ",
    voucherCode: "เลขที่ใบสำคัญ/เลขที่ใบกำกับภาษี*",
    validateNoVoucherCode: "กรุณาระบุเลขที่ใบสำคัญ/เลขที่ใบกำกับภาษี",
    buyDate: "วันที่ซื้อ/วันที่ได้มา",
    buyPrice: "ราคาที่ซื้อ/มูลค่าที่ได้มา*",
    validateNoBuyPrice: "กรุณาระบุราคาที่ซื้อ/มูลค่าที่ได้มา",
    depreciationSchedule: "กำหนดการคิดค่าเสื่อมราคา",
    calculateValue: "มูลค่าที่นำไปคำนวณ*",
    validateNodepreciationPrice: "กรุณาระบุมูลค่าที่นำไปคำนวณ",
    validateDepreciationPrice: "มูลค่าที่นำไปคำนวณ ต้องไม่มากกว่าราคาที่ซื้อ/มูลค่าที่ได้มา",
    marketPrice: "ราคาตลาด",
    otherCost: "ค่าใช้จ่ายอื่น ๆ",
    depreciationDate: "วันที่เริ่มคิดค่าเสื่อม*",
    validateNoDepreciationDate: "กรุณาระบุวันที่เริ่มคิดค่าเสื่อม",
    depreciationRate: "อัตราค่าเสื่อม ร้อยละ*",
    validateNoDepreciationRate: "กรุณาระบุอัตราค่าเสื่อม ร้อยละ",
    accumDepreciationBeforeCal: "ค่าเสื่อมสะสมก่อนวันเริ่มคำนวณ",
    depreciationFormula: "วิธีการคำนวณค่าเสื่อม",
    depreciationFormula1: "แบบเส้นตรง",
    depreciationFormula2: "แบบลดน้อยถอยลง",
    depreciationPeriod: "ลงบัญชีค่าเสื่อมทุก ๆ",
    depreciationPeriod1: "เดือน",
    depreciationPeriod2: "ปี",
    status: "สถานะการใช้งาน",
    status1: "ใช้งาน",
    status2: "ชำรุด",
    cutOffAsset: "การตัดสินทรัพย์",
    depreciationRemain: "ราคาซาก",
    validateDepreciationRemain: "ราคาซาก ต้องไม่มากกว่าราคาที่ซื้อ/มูลค่าที่ได้มา",
    saleReference: "เลขที่เอกสารประกอบการขาย",
    cutOffAssetDate: "วันที่ตัดสินทรัพย์",
    salePrice: "ราคาขาย",
    categoryLocation: "หมวด - สถานที่ตั้ง",
    accompanyAsset: "เพื่อประกอบกับสินทรัพย์",
    assetGroup: "กลุ่มสินทรัพย์",
    building: "อาคาร",
    location: "สถานที่ตั้ง",
    admin: "ผู้ดูแล",

    addLot: "ต้องการเพิ่มรายการเป็น Lot",
    addValueToCopy: "กรุณาใส่จำนวนที่ต้องการคัดลอก",
    validateValueToCopy: "จำนวนที่ต้องการคัดลอก \n ต้องมากกว่า 0.",
    askForSaveLot: "คุณยืนยันที่จะ คัดลอกรายการทรัพย์สิน <br> รหัส : :code <br> ชื่อ : :name <br> จำนวน : :value รายการ ใช่หรือไม่?",
    detailTitle : "รายละเอียดทรัพย์สิน",

};
