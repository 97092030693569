import * as XLSX from "xlsx";
import useLang from "../hooks/lang.js";
import { computed } from "vue";
import { useStore } from "vuex";
export default function useExportExcel() {
  const store = useStore();
  const { lang } = useLang();

  const mergeLang = computed(function () {
    if (store.getters.selectedLanguage === "TH") {
      return {
        ...lang.th.validateImport,
      };
    } else {
      return {
        ...lang.en.validateImport,
      };
    }
  });

  const generateFile = async (raw_data, columnName, fileName, tableName, startRow) => {
    try {
      let arrayObjectData = [];

      raw_data.forEach(data => {
        let row = {};
        columnName.forEach(colName => {
          if (colName.field !== "error") {
            row[colName.name] = data[colName.field];
          } else if (colName.field === "error" && data[colName.field].length > 0) {
            let errorArray = data[colName.field];
            row[colName.name] = getErrorLang(errorArray, tableName, columnName);
          }
        })
        // console.log(row);
        arrayObjectData.push(row);
      })
      // console.log(arrayObjectData);
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(arrayObjectData, { origin: startRow - 2 });

      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      /* calculate column widths */
      const columnWidths = arrayObjectData.reduce((acc, row) => {
        Object.keys(row).forEach(key => {
          const cellLength = String(row[key]).length;
          acc[key] = Math.max(acc[key] || 0, cellLength);
        });
        return acc;
      }, {});

      /* set column widths in the worksheet */
      worksheet['!cols'] = [];
      Object.keys(columnWidths).forEach(column => {
        const width = columnWidths[column];
        worksheet['!cols'].push({ wch: width });
      });

      XLSX.writeFile(workbook, `${fileName}.xlsx`, { compression: true, bookSST: true });
    } catch (error) {
      console.error("Error generating file:", error);
    }
  };

  const getErrorLang = (value, tableName, columnName) => {
    let errorType = ""
    value.forEach((item, index) => {
      let field = columnName.find(c => c.field === item.field);
      switch (item.type) {
        case "blank":
          errorType += mergeLang.value.validateBlank.replace(":field", field.name);
          break;
        case "duplicate":
          errorType += mergeLang.value.validateDuplicateInFile.replace(":field", field.name);
          break;
        case "duplicateInDatabase":
          errorType += mergeLang.value.validateDuplicateInDatabase.replace(":field", field.name).replace(":table", tableName);
          break;
        case "overflow":
          errorType += mergeLang.value.validateLength.replace(":field", field.name).replace(":length", item.wordLength);
          break;
        case "doNotExist":
          errorType += mergeLang.value.validateDataNotFound.replace(":field", field.name);
          break;
        case "invalid":
          errorType += mergeLang.value.validateType.replace(":field", field.name);
          break;
        case "invalidDecimal":
          errorType += mergeLang.value.validateDecimalFormat.replace(":field", field.name);
          break;
        case "format":
          errorType += mergeLang.value.validateDateFormat.replace(":field", field.name);
          break;
      }
      if (index < value.length - 1) {
        errorType += "\r\n";
      }
    })
    return errorType;
  }

  return {
    generateFile,
    getErrorLang
  };
}