import { useStore } from "vuex";
import { ref, computed } from "vue";
export default function useGetCountryCode() {
  const store = useStore();
  let searchTextCountryCode = ref(null);

  const countryCodes = computed(() => {
    return store.getters["shared/countryCodes"];
  });

  const countryCodeIsLastPage = computed(() => {
    return store.getters["shared/countryCodeIsLastPage"];
  });

  const filteredCountryCode = computed(() => {
    if (!searchTextCountryCode.value) {
      //ช่องว่าง
      if (countryCodes.value) {
        const result = [...countryCodes.value];
        return result;
      } else {
        return null;
      }
    } else {
      return countryCodes.value.filter((data) => {
        return data.codeAndNameTh
          .toLowerCase()
          .includes(searchTextCountryCode.value.toLowerCase());
      });
    }
  });

  const searchCountryCode = function (event) {
    if (!event.query.trim().length) {
      searchTextCountryCode.value = "";
      searchTextCountryCode.value = null;
    } else {
      searchTextCountryCode.value = event.query;
    }
  };

  let isSharedCountryCodeReady = ref(false);

  const getAllCountryCode = async function () {
    if (!countryCodeIsLastPage.value) {
      await store.dispatch("shared/getAllCountryCode");
    }
    isSharedCountryCodeReady.value = true;
  };

  // onMounted(async () => {
  //   await getAllCountryCode();
  // });

  return {
    searchTextCountryCode,
    filteredCountryCode,
    searchCountryCode,
    isSharedCountryCodeReady,
    getAllCountryCode,
    countryCodes,
  };
}
