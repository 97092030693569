<template>
  <PvDialog
    :breakpoints="{ '1280px': '80vw', '640px': '80vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex flex-wrap justify-content-between align-items-right p-0">
        <div class="p-0">
          <label class="font-lg">{{ lang.historyDetails }}</label>
        </div>
        <div>
          <PvButton
            icon="pi pi-times"
            class="p-button-link p-icon-secondary pb-0 pt-0"
            style="width: 15px"
            @click="closeDialog"
          />
        </div>
      </div>
      <PvDivider></PvDivider>

      <section class="flex flex-wrap pt-3" :class="textFormClass">
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2">
          <label class="p-text-secondary">{{ lang.updateDate }}</label>
          <div class="mt-2">
            <label for="updateDate">{{ formatDateTime(history.updateDate) }}</label>
          </div>
        </div>
        <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2">
          <label class="p-text-secondary">{{ lang.updateBy }}</label>
          <div class="mt-2">
            <label for="updateBy">{{ history.updateBy }}</label>
          </div>
        </div>
        <div class="col-12 pt-3 pl-0">
          <label class="font-lg">{{ lang.details }}</label>
        </div>
        <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
          <label for="date">{{ lang.date }}</label>
          <PvCalendar
            id="date"
            v-model="date"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            inputClass="p-inputtext-disabled"
            disabled
          />
        </div>
        <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
          <label for="createBy">{{ lang.createBy }}</label>
          <PvInputText
            id="createBy"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="createBy"
            disabled
          />
        </div>
        <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
          <label for="createDate">{{ lang.createDate }}</label>
          <PvCalendar
            id="createDate"
            v-model="createDate"
            class="inputfield w-full mt-1"
            inputClass="p-inputtext-disabled"
            showIcon
            dateFormat="dd/mm/yy"
            disabled
          />
        </div>
        <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
          <label for="productCode">{{ lang.productCode }}</label>
          <PvSkeleton
            v-if="!isSharedProductReady"
            class="inputfield w-full mt-1"
            height="39px"
          />
          <base-product-ar-auto-complete
            id="product"
            v-model="selectedProduct"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            @load-finish="productDataReady"
            v-show="isSharedProductReady"
            disabled
          />
        </div>
        <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
          <label for="runningNo">{{ lang.runningNo }}</label>
          <PvInputText
            id="runningNo"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="runningNo"
            disabled
          />
        </div>
        <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
          <label for="serialNo">{{ lang.serialNo }}</label>
          <PvInputText
            id="serialNo"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="serialNo"
            disabled
          />
        </div>
        <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
          <label for="status">{{ lang.status }}</label>
          <PvDropdown
            id="status"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            :options="status"
            optionLabel="name"
            optionValue="code"
            v-model="selectedStatus"
            scrollHeight="300px"
            disabled
          />
        </div>
        <div class="lg:col-8 md:col-12 col-12 pl-0 pr-2">
          <label for="remark">{{ lang.remark }}</label>
          <PvInputText
            id="remark"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="remark"
            disabled
          />
        </div>

        <div class="flex flex-wrap w-full pt-4">
          <div class="col-12 pl-0">
            <label class="font-lg font-normal">{{ lang.ownerDetails }}</label>
          </div>
          <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
            <label for="unit">{{ lang.unit }}</label>
            <PvSkeleton
              v-if="!isSharedUnitReady"
              class="inputfield w-full mt-1"
              height="39px"
            />
            <base-unit-auto-complete
              id="unit"
              v-model="selectedUnit"
              class="inputfield w-full mt-1 p-inputtext-disabled"
              @load-finish="unitDataReady"
              v-show="isSharedUnitReady"
              disabled
            />
          </div>
          <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
            <label for="receiptCode">{{ lang.receiptCode }}</label>
            <PvInputText
              id="receiptCode"
              type="text"
              class="inputfield w-full mt-1 p-inputtext-disabled"
              v-model="receiptCode"
              disabled
            />
          </div>
        </div>
      </section>
    </template>

    <template #footer>
        <PvButton
            :label="lang.close"
            class="p-button-outlined p-button-secondary w-2 ml-3"
            @click="closeDialog"
        />
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, ref, onMounted } from "vue";
export default {
  props: {
    history: {
      type: Object,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const { formatDateToISOString, formatDate, formatDateTime } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.stockKeycard.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.stockKeycard.edit,
        };
      }
    });

    let date = ref(null);
    let createBy = ref("");
    let createDate = ref(null);
    let selectedProduct = ref(null);
    let isSharedProductReady = ref(false);
    let runningNo = ref("");
    let serialNo = ref("");
    let remark = ref("");
    let isSharedUnitReady = ref(false);
    let selectedUnit = ref(null);
    let receiptCode = ref("");

    const status = ref([
      { name: mergeLang.value.statusReady, code: "ready" },
      { name: mergeLang.value.statusSold, code: "sold" },
      { name: mergeLang.value.statusLost, code: "lost" },
      { name: mergeLang.value.statusBroken, code: "broken" },
      { name: mergeLang.value.statusSpare, code: "spare" },
      { name: mergeLang.value.statusOther, code: "other" },
    ]);
    let selectedStatus = ref(null);

    onMounted(() => {
        initiateData();
        if(props.history) {
          checkShouldSetData();
        }
    });

    const sharedProducts = computed(() => {
      return store.getters["shared/productArProducts"];
    });
    const sharedUnits = computed(() => {
      return store.getters["shared/unitUnits"];
    });

    const productDataReady = () => {
      isSharedProductReady.value = true;
      checkShouldSetData();
    };

    const unitDataReady = () => {
      isSharedUnitReady.value = true;
      checkShouldSetData();
    };

    const closeDialog = () => {
      emit("close");
    };

    const initiateData = () => {
      date.value = null;
      createBy.value = "";
      createDate.value = new Date();
      selectedProduct.value = null;
      isSharedProductReady.value = false;
      runningNo.value = "";
      serialNo.value = "";
      remark.value = "";
      selectedStatus.value = "ready";
      selectedUnit.value = null;
      receiptCode.value = "";
      isSharedUnitReady.value = false;
    };

    const setDataProduct = () => {
        selectedProduct.value = sharedProducts.value.find((data) => data.id == props.history.productId);
    };

    const setDataUnit = () => {
        selectedUnit.value = sharedUnits.value.find((data) => data.id == props.history.unitId);
    };

    const setData = () => {
      date.value = new Date(props.history.date);
      createBy.value = props.history.createBy;
      createDate.value = new Date(props.history.createDate);
      runningNo.value = props.history.code;
      serialNo.value = props.history.serial;
      remark.value = props.history.remark;
      receiptCode.value = props.history.receiptCode;
      selectedStatus.value = props.history.status;
    };

    const checkShouldSetData = () => {
        if (props.history && isSharedProductReady.value && isSharedUnitReady.value) {
          setData();
          setDataProduct();
          setDataUnit();
        } else if (props.history) {
          setData();
        }
    };

    return {
      lang: mergeLang,
      textFormClass,
      formatDateToISOString,
      formatDate,
      formatDateTime,

      //data
      date,
      createBy,
      createDate,
      selectedProduct,
      isSharedProductReady,
      runningNo,
      serialNo,
      remark,
      status,
      selectedStatus,
      isSharedUnitReady,
      selectedUnit,
      receiptCode,

      //methods
      productDataReady,
      unitDataReady,
      closeDialog,
    };
  },
};
</script>
