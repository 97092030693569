export default {
	async getMeterList(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Meter";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Purchase Receipt");
			throw error;
		}
		// console.log(responseData);
		context.commit("setMeters", responseData);
	},
	async getMeter(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Meter?limit=1";

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Purchase Receipt");
			throw error;
		}
		// console.log(responseData);
		context.commit("setSelectedMeter", responseData.data[0]);
	},
	resetMeter(context) {
		context.commit("resetMeter");
	},
	resetMeterForAutoCreateByProject(context) {
		context.commit("resetMeterForAutoCreateByProject");
	},
	async getProduct(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Meter/GetProduct";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Purchase Receipt");
			throw error;
		}
		// console.log(responseData);
		context.commit("setProduct", responseData.data);
	},
	async addMeter(context, payload) {
		// console.log('addMeter',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/Meter/AddMeter";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("addMeter>>>", responseData);
			// const error = new Error(responseData.message || "Failed to Add Purchase Receipt");
			throw responseData;
		}
		// console.log("addMeter>>>", responseData);
		await context.commit("setSelectedMeter", responseData.data);
	},
	async updateMeter(context, payload) {
		// console.log('updateMeter',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/Meter/UpdateMeter";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("updateMeter>>>", responseData);
			// const error = new Error(responseData.message || "Failed to Add Purchase Receipt");
			throw responseData;
		}
		// console.log("updateMeter>>>", responseData);
		await context.commit("setSelectedMeter", responseData.data);
	},
	async deleteMeter(context, payload) {
		// console.log('deleteMeter',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/Meter/DeleteMeter";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("deleteMeter>>>", responseData);
			// const error = new Error(responseData.message || "Failed to Add Purchase Receipt");
			throw responseData;
		}
		// console.log("deleteMeter>>>", responseData);
	},
	async getForAutoCreateByProject(context, payload) {
		let url =
			process.env.VUE_APP_BACKEND_URL + "/Meter/ForAutoCreateByProject/" + payload.projectId;

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("getForAutoCreateByProject>>>", responseData);
			// const error = new Error(responseData.message || "Failed to Add Purchase Receipt");
			throw responseData;
		}
		// console.log("getForAutoCreateByProject>>>", responseData.data);
		await context.commit("setForAutoCreateByProject", responseData);
	},

	async getAllForAutoCreateByProject(context, projectId) {
		while (!context.getters.meterForAutoCreateIsLastPage) {
			const payload = {
				page: context.getters.meterForAutoCreateNextPage || 1,
				limit: 100,
				projectId: projectId,
			};
			await context.dispatch("getForAutoCreateByProject", payload);
		}
	},
};
