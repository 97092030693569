export default {
	setMeterUsageSettingList(state, payload) {
		let meterUsageSettingLists = [...state.meterUsageSettingLists];
        for (const meterUsageSetting of payload.data) {
            if(meterUsageSettingLists.findIndex(c => c.id === meterUsageSetting.id) < 0){
                meterUsageSettingLists.push(meterUsageSetting);
            }
        }
		state.meterUsageSettingLists = meterUsageSettingLists;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
	setMeterUsageSettingType(state, payload) {
		state.meterUsageSettingType = payload.data;
	},
	resetMeterUsageSettingList(state) {
		state.meterUsageSettingLists = [];
		state.currentPage = 0;
        state.totalPage = 0;
	}
};
