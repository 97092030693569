export default {
	phoneBooks(state) {
		return state.phoneBooks;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	selectedPhoneBook(state) {
		return state.selectedPhoneBook;
	},
	phoneBookNextPage(state) {
		return state.currentPage + 1;
	},
	phoneBookIsLastPage(_, getters) {
		if (getters.currentPage > 0 && getters.totalPage === 0) {
			return true;
		} else {
			return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
		}
	},

	contactTypes(state) {
		return state.contactTypes;
	},
	contactTypeTotalPage(state) {
		return state.contactTypeTotalPage;
	},
	contactTypeCurrentPage(state) {
		return state.contactTypeCurrentPage;
	},
	contactTypeNextPage(state) {
		return state.contactTypeCurrentPage + 1;
	},
	contactTypeIsLastPage(_, getters) {
		if (getters.contactTypeCurrentPage > 0 && getters.contactTypeTotalPage === 0) {
			return true;
		} else {
			return getters.contactTypeCurrentPage === getters.contactTypeTotalPage && getters.contactTypeTotalPage !== 0;
		}
	},
	
	phoneTypes(state) {
		return state.phoneTypes;
	},
	phoneTypeTotalPage(state) {
		return state.phoneTypeTotalPage;
	},
	phoneTypeCurrentPage(state) {
		return state.phoneTypeCurrentPage;
	},
	phoneTypeNextPage(state) {
		return state.phoneTypeCurrentPage + 1;
	},
	phoneTypeIsLastPage(_, getters) {
		if (getters.phoneTypeCurrentPage > 0 && getters.phoneTypeTotalPage === 0) {
			return true;
		} else {
			return getters.phoneTypeCurrentPage === getters.phoneTypeTotalPage && getters.phoneTypeTotalPage !== 0;
		}
	},
};
