<template>
  <PvToast
    :style="{ width: '95%', opacity: 1, top: '5rem' }"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    position="top-center"
    group="baseToastReportRequest"
  >
    <template #message="slotProps">
      <div class="p-toast-message-content p-0 m-0" style="flex: 1">
        <span
          class="p-toast-message-icon pi"
          :class="{
            'pi-info-circle': toastMessage.severity == 'info',
            'pi-times': toastMessage.severity == 'error',
            'pi-exclamation-triangle': toastMessage.severity == 'warn',
            'pi-check': toastMessage.severity == 'success',
          }"
        />
        <div class="p-toast-message-text mt-0 mb-0">
          <span class="p-toast-summary mt-0">{{ slotProps.message.summary }}</span>
          <div class="p-toast-detail pt-1">
            <span class="mt-0">{{ slotProps.message.detail }}</span>
            <u class="p-toast-detail ml-1 mt-0 cursor-pointer" v-if="isNewTab" @click="openNewTab">{{ toastLink }}</u>
            <router-link
              v-else
              class="p-toast-detail ml-1 mt-0 cursor-pointer" 
              :to="defaultLink" >
              <u>{{ toastLink }}</u>
            </router-link>
          </div>
        </div>
      </div>
    </template>
  </PvToast>
</template>
<script>
export default {
  props: {
    toastMessage: {
      type: Object,
    },
    actionToast: {
      type: String,
      default: ""
    },
    showToast: {
      type: Number,
      default: 0,
    },
    toastLink: {
      type: String,
      default: "หน้ารายการคำขอ",
    },
    isNewTab: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    showToastMessage() {
      this.$toast.add({
        severity: this.toastMessage.severity,
        summary: this.toastMessage.summary,
        detail: this.toastMessage.detail,
        life: this.toastMessage.life,
        group: "baseToastReportRequest",
      });
    },
    openNewTab() {
      let url = this.actionToast == "" ? `/${this.selectedProject.code}/report/request?back=home` : this.actionToast;
      window.open(url, '_blank');
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    defaultLink() {
      return this.actionToast == "" ? `/${this.selectedProject.code}/report/request?back=home`: this.actionToast;
    }
  },
  watch: {
    showToast() {
      this.showToastMessage();
    },
  },
};
</script>
