export default {
    detailTitle: "รายละเอียดห้อง/บ้าน",
    detailOwnerTitle: "รายการเจ้าของ",
    detailOwnerDescription: "สามารถบันทึกข้อมูลเจ้าของสูงสุด 3 รายการ",
    toolbarCreateUnit: "สร้างห้อง/บ้าน",
    toolbarEditUnit: "แก้ไขห้อง/บ้าน",
    generalInformation: "ข้อมูลทั่วไป",
    code: "เลขที่ยูนิต/ห้อง",
    validateCode: "ระบุเลขยูนิต/ห้อง",
    addressNo: "บ้านเลขที่",
    validateAddressNo: "ระบุบ้านเลขที่",
    building: "อาคาร",
    validateBuilding: "ระบุอาคาร",
    floor: "ชั้น",
    validateFloor: "ระบุชั้น",
    roomType: "ประเภทห้องชุด",
    roomType1: "เช่าพื้นที่ส่วนกลาง/ร้านค้า",
    roomType2: "ยังไม่โอน",
    roomType3: "โอนแล้ว-ปล่อยเช่า",
    roomType4: "โอนแล้ว-ยังไม่เข้าพักอาศัย",
    roomType5: "โอนแล้ว-อยู่เอง",
    validateRoomType: "เลือกประเภทห้องชุด",
    room: "ห้อง",
    validateRoom: "ระบุห้อง",
    contractNo: "เลขที่สัญญา",
    validateContractNo: "ระบุเลขที่สัญญา",
    QRPayment: "QR Payment",
    ratio: "อัตราส่วนกรรมสิทธิ์",
    validateRatio: "ระบุอัตราส่วนกรรมสิทธิ์",
    area: "พื้นที่",
    validateArea: "ระบุพื้นที่",
    cooling: "คูลลิ้ง (ตัน)",
    validateCooling: "ระบุคูลลิ้ง (ตัน)",
    activeStatus: "การใช้งาน",
    memberInformation: "ข้อมูลสมาชิก",
    ownerInformation: "รายละเอียดของเจ้าของ",
    residentInformation: "รายการผู้เช่า/ผู้อยู่อาศัย",
    allResident: "ผู้พักอาศัยทั้งหมด",
    owner: "เจ้าของ",
    owner1: "เจ้าของที่ 1",
    owner2: "เจ้าของที่ 2",
    owner3: "เจ้าของที่ 3",
    resident : "ผู้พักอาศัย",
    residentNo : "ผู้พักอาศัยที่",
    resident1 : "ผู้พักอาศัยที่ 1",
    resident2 : "ผู้พักอาศัยที่ 2",
    resident3 : "ผู้พักอาศัยที่ 3",
    rental: "ผู้เช่า",
    rentalNo: "ผู้เช่าที่",
    rental1: "ผู้เช่า 1",
    rental2: "ผู้เช่า 2",
    rental3: "ผู้เช่า 3",
    roomOwner: "เจ้าของห้อง",
    transferDate: "วันที่โอน",
    livingDate: "วันที่เข้าอยู่",
    appointmentDate: "วันนัดรับมอบ",
    acceptDate: "วันที่รับมอบ",
    warrantyEndDate: "วันที่สิ้นสุดรับประกัน",
    validateDate: "เลือกวันที่",
    living: "การเข้าพักอาศัย",
    living1: "อยู่เอง",
    living2: "ปล่อยเช่า",
    living3: "เช่าพื้นที่ส่วนกลาง",
    receiptAddress: "ที่อยู่สำหรับออกใบแจ้งหนี้/ใบเสร็จ",
    validateReceiptAddress: "ระบุที่อยู่สำหรับออกใบแจ้งหนี้/ใบเสร็จ",
    stayDate: "วันที่เข้าอยู่",
    moveDate: "วันที่ย้ายออก",
    unitDocument: "เอกสารสำหรับห้อง :code",
    addMember: "เพิ่มสมาชิก",
    addOwner: "เพิ่มเจ้าของร่วม",
    addResident: "เพิ่มรายการผู้พักอาศัย",
    addRental: "เพิ่มผู้เช่า",
    addNewResident: "เพิ่มรายการ",
    name: "ชื่อ",
    livingType: "สถานะผู้พักอาศัย",
    settingUnitProduct: "ตั้งค่าการแจ้งหนี้",
    residentType: "ประเภทผู้พักอาศัย*",
    rentalResident : "ผู้เช่า/ผู้พักอาศัย",
    validateRentalResident: "เลือกผู้เช่า/ผู้พักอาศัย",
    createUnitSuccess: "คุณสร้างรายการห้อง/บ้านสำเร็จ",
    editUnitSuccess: "คุณแก้ไขรายการห้อง/บ้านสำเร็จ",
    unit: "ห้อง/บ้าน",
    dialogValidateParamsProjectId: "ไม่สามารถทำรายการได้ เนื่องจากข้อมูลโครงการไม่ถูกต้อง", //error_code: 103
    dialogValidateParamsUnitId: "ไม่สามารถทำรายการได้ เนื่องจากข้อมูลห้อง/บ้านไม่ถูกต้อง", //error_code: 106
    dialogValidateParamsCode: "ไม่สามารถทำรายการได้ เนื่องจากข้อมูลรหัสห้อง/บ้านไม่ถูกต้อง", //error_code: 104
    dialogValidateParamsAddressNo: "ไม่สามารถทำรายการได้ เนื่องจากข้อมูลบ้านเลขที่ไม่ถูกต้อง", //error_code: 105
    dialogValidateProjectIdNotFound: "ไม่สามารถทำรายการได้ เนื่องจากไม่พบข้อมูลโครงการ", //error_code: 101
    dialogValidateUnitIdNotFound: "ไม่สามารถทำรายการได้ เนื่องจากไม่พบข้อมูลห้อง/บ้าน", //error_code: 102
    dialogValidateInsertRole: "ไม่สามารถทำรายการได้ เนื่องจากคุณไม่มีสิทธิ์สร้างข้อมูล", // error_code: 111
    dialogValidateUpdateRole: "ไม่สามารถทำรายการได้ เนื่องจากคุณไม่มีสิทธิ์แก้ไขข้อมูล", // error_code: 112
    dialogValidateDuplicateCode: "ไม่สามารถทำรายการได้ เนื่องจากตรวจพบรหัสห้อง/บ้านซ้ำในระบบ", // error_code: 120
    dialogValidateDuplicateAddressNo: "ไม่สามารถทำรายการได้ เนื่องจากตรวจพบบ้านเลขที่ซ้ำในระบบ", // error_code: 121
    validateAddressNoLimit: "ระบุข้อความไม่เกิน 25 ตัวอักษร",
    validateAddressMoreThanLimit: "เนื่องจากคุณระบุข้อความเกิน 25 ตัวอักษร",
    status: "สถานะ",
    activeStatusResident: "ใช้งาน",
    inactiveStatusResident: "ไม่ใช้งาน",
    validateResidentDuplicate: "มีผู้เช่า/ผู้อยู่อาศัย : :name ในห้องนี้แล้ว",
}