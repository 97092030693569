export default({
	titleInvoicePrintLot: "พิมพ์ใบแจ้งหนี้",
	labelReportType: "ประเภทการพิมพ์",
	radioButtonReportType1: "พิมพ์เป็นชุด",
	radioButtonReportType2: "พิมพ์ร่วม unit",
	titleBaseCardInvoicePrintLot: "ข้อมูลใบแจ้งหนี้",
	labelInvoiceDate: "วันที่แจ้งหนี้",
	searchInvoiceDate: "ค้นหาวันที่ใบแจ้งหนี้",
	validateInvoiceDate: "พบข้อผิดพลาดในการเรียกข้อมูล มีความเป็นไปได้ว่าไม่มีข้อมูลในระบบ",
	labelCustomerType: "ประเภทลูกค้า",
	dropdownCustomerType: [
        { name: "เจ้าของ", code: "owner" },
        { name: "ผู้เช่า", code: "renter" },
    ],
	labelCustomerGroup: "กลุ่มลูกค้า",
	searchCustomerGroup: "ค้นหากลุ่มลูกค้า",
	labelSearchBy: "ค้นหารายการตาม",
	dropdownSearchBy: [
        { name: "เลขที่เอกสาร", code: "doc_code" },
        { name: "เลขที่ยูนิต", code: "room_code" },
    ],
	labelSortBy: "ข้อมูลที่พิมพ์เรียงตาม",
	dropdownSortBy: [
        { name: "เลขที่เอกสาร", code: "doc_code" },
        { name: "เลขที่ยูนิต", code: "room_code" },
    ],
	labelDocumentCodeStart: "ช่วงรายการเริ่มต้น",
	labelDocumentCodeEnd: "ช่วงรายการสิ้นสุด",
	labelUnitCodeStart: "เลขที่ยูนิตเริ่มต้น",
	labelUnitCodeEnd: "เลขที่ยูนิตสิ้นสุด",
	labelCustomerCodeStart: "รหัสลูกค้าเริ่มต้น",
	labelCustomerCodeEnd: "รหัสลูกค้าสิ้นสุด",
	searchInvoiceAndUnit: "โปรดระบุ",
	checkboxIsIgnoreZero: "พิมพ์บิลในเดือนมูลค่า 0 บาท",
	checkboxIsPrintCombineUnit: "พิมพ์ลูกค้ารวมห้อง",
	checkboxIsIgnoreBillZero: "พิมพ์บิลมูลค่ารวม 0 บาท",
	notificationDialogHeader: "ระบบแจ้งเตือน",
})