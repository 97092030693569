export default {
    title: "Report Manage",
    addNewReportMenu: "Add new report type",
    editReportMenu: "Edit report type",
    menuName: "Menu",
    sequence: "Sequence",
    nameTh: "Name(Th)",
    nameEn: "Name(En)",
    path: "Path",
    activeStatus: "Active Status",
    addNewReportMenuDetail: "Add Report",
    editReportMenuDetail: "Edit Report",
    category: "Category",
    project: "Project",
    component: "Component",
    validateSequence: "Please Enter Sequence",
    validateNameThai: "Please Enter Name(Th)",
    validateNameEng: "Please Enter Name(En)",
    validatePath: "Please Enter Path",
    validateComponent: "Please Enter Component",
    titleDetail: "Manage Report : :reportMenu",
    validateProject: "Please Select Project",
    confirmDeleteTitle: "Confirm Delete",
    confirmDeleteDesc: "Are you sure you want to delete report : :name ?",
    active: "Active",
    inActive: "InActive",
    selectReport: "Select Report",
    reportCategory: "Report Category",
    settingSignature: "Setting Signature",
	yes: "Yes",
	no: "No",
}