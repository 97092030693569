export default {
	reportMenus(state) {
		return state.reportMenus;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	selectedReportMenu(state) {
		return state.selectedReportMenu;
	},
};
