<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12 2.99625V7.99833"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M6.99792 17.0021H8.99876"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M21.0037 9.99915C21.0037 8.89413 20.1079 7.99832 19.0029 7.99832H4.99708C3.89205 7.99832 2.99625 8.89413 2.99625 9.99915"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M19.0029 21.0037H5.24719C4.00403 21.0037 2.99625 19.996 2.99625 18.7528V8.49854C2.99593 7.95823 3.10553 7.4235 3.31838 6.92688L4.41284 4.36282C4.76675 3.53379 5.58129 2.99601 6.4827 2.99625H17.5163C18.4177 2.99601 19.2322 3.53379 19.5862 4.36282L20.6866 6.92688C20.8976 7.4239 21.0055 7.95859 21.0038 8.49854V19.0029C21.0038 20.1079 20.1079 21.0037 19.0029 21.0037Z"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>
<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: 24,
		},
		height: {
			type: [Number, String],
			default: 24,
		},
		iconColor: {
			type: String,
			default: "secondary",
		},
	},
	computed: {
		strokeColor() {
			return "p-stroke-" + this.iconColor;
		},
	},
};
</script>

<style lang="scss" scoped>
svg {
	display: inline-block;
	vertical-align: baseline;
	margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
