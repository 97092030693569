import actions from "./actions.js";
import getters from "./getters.js";
import mutations from "./mutations.js";

export default {
	namespaced: true,
	state() {
		return {
			meterRegisters: [],
			selectedMeter: null,
			totalPage: 0,
			currentPage: 1,
			rows: 100,
			blob: null,
			product: [],
			meterForAutoCreateByProject: [],
			totalPageForAutoCreate: 0,
			currentPageForAutoCreate: 0,
		};
	},
	actions,
	getters,
	mutations,
};
