export default {
	blob(state) {
		return state.blob;
	},
	periods(state) {
		return state.periods;
	},
	reportMenu(state) {
		return state.reportMenu;
	},
	reportMenuDetail(state) {
		return state.reportMenuDetail;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	selectedReportMenu(state) { 
		return state.selectedReportMenu;
	},
	reportMenuDetailIsLastPage(_, getters) {
		return (
			getters.currentPage === getters.totalPage &&
			getters.totalPage !== 0
		);
	},
	reportMenuDetailNextPage(state) {
		return state.currentPage + 1;
	},
};
