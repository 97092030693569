export default {
	title: "Payin List",
	status: "Status",
	date: "Payin Date",
	customerSearch: "Customer Code or Name",
	bookBankAccountNumber: "Book Bank Account Number",
	bookBankName: "Book Bank Name",
	paymentMethodCode: "Payin Method",
	createBank: "Create Bank",

	codeBank: "Code",
	columnPayDate: "Payin Date",
	columnToBankDate: "Bank Date",
	columnCode: "Code",
	columnCustomerCode: "Customer Code",
	columnCustomerName: "Customer Name",
	columnPrice: "Price",
	columnPayCode: "Payin Code",
	columnPayName: "Payin Description",
	columnIsCancel: "Canceled",
	columnStatus: "Status",
	unPaidOnly: "Unpaid only",
	unPaidOptions: [
		{ name: "Yes", code: "A1" },
		{ name: "No", code: "A2" },
	],
	importDocument: "Import document",
	confirmDocument: "Confirm document",
	removeDocument: "Remove document",
	btnPrintLot: "ChequePrintLot",
	bookBank: "BookBank",
	CodeStart: "From Code",
	CodeEnd: "To Code",
	columnAccountCode: "AccountCode",
	columnBranch: "Branch",
	columnBank: "Bank",
	validatebbookBankError: "Invalid BookBank",
	validateDetail: "Detail Print not found",
	cancelStatus: "Print Document with Cancel Status",
	dropdownSearchByReceiptCode: "Receipt Code",
};
