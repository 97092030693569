export default {
  async getRoleByBooks(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Role/ByBook";
    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }
    if (payload.page) {
      url = url + "&page=" + payload.page;
    }
    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }
    const token = context.rootGetters.token;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get RoleByBook");
      throw error;
    }
    const roleByBook = {
      roleByBooks: responseData.data,
      totalPage: responseData.page.pageCount == 0 ? 1 : responseData.page.pageCount,
      currentPage: responseData.page.currentPage,
    };
    context.commit("setRoleByBook", roleByBook);
  },
  async getAllRoleByBooks(context) {
    context.commit("resetRoleByBook");
    while (!context.getters.roleByBookIsLastPage) {
      let where = {
        projectId: context.rootGetters.selectedProject.id,
        userId: context.rootGetters.userId,
      };
      const payload = {
        page: context.getters.roleByBookNextPage || 1,
        where,
      };
      await context.dispatch("getRoleByBooks", payload);
    }
  },
  resetRoleByBook(context) {
    context.commit("resetRoleByBook");
  },
  trySetRoleByBooks(context, payload) {
    const roleByBook = {
      roleByBooks: payload,
      totalPage: 1,
      currentPage: 1,
    };
    context.commit("setRoleByBook", roleByBook);
  },
};
