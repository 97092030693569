<template>
    <PvSkeleton
      v-if="!isSharedSignatureAllReady"
      class="inputfield w-full mt-2"
      borderRadius="8px"
      height="3rem"
    />
    <PvDropdown
      id="signature"
      type="text"
      class="inputfield w-full mt-1"
      :options="filteredSignature"
      optionLabel="positionAndName"
      :placeholder="textPlaceholder"
      :filter="true"
      :showClear="true"
      v-else
    />
  </template>
  
  <script>
  import { useStore } from "vuex";
  import { ref, computed, onMounted } from "vue";
  export default {
    props: {
      textPlaceholder: {
        type: String,
        default: "",
      },
      isLoadingData: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["load-finish"],
    setup(props) {
      const store = useStore();
      let searchText = ref(null);
  
      const signatures = computed(() => {
        return store.getters["signature/signatureAll"];
      });
  
      const filteredSignature = computed(() => {
        if (!searchText.value) {
          //ช่องว่าง
          const result = [...signatures.value];
          return result;
        } else {
          return signatures.value.filter((signature) => {
            return signature.searchText
              .toLowerCase()
              .includes(searchText.value.toLowerCase());
          });
        }
      });
  
      const searchSignature = function (event) {
        if (!event.query.trim().length) {
          //ช่องว่าง
          // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
          searchText.value = "";
          searchText.value = null;
        } else {
          searchText.value = event.query;
        }
      };
  
      let isSharedSignatureAllReady = ref(false);
  
      const signatureIsLastPage = computed(() => {
        return store.getters["signature/signatureIsLastPage"];
      });
  
      const getSignatureAll = async function () {
        if (props.isLoadingData && !signatureIsLastPage.value) {
          await store.dispatch("signature/getSignatureAll");
        }
        isSharedSignatureAllReady.value = true;
      };
  
      const getSignatureById = function (id) {
        return signatures.value.find((signature) => {
          return signature.id === id;
        });
      };
  
      onMounted(async () => {
        await getSignatureAll();
      });
  
      return {
        searchSignature,
        filteredSignature,
        searchText,
        isSharedSignatureAllReady,
        getSignatureById,
      };
    },
    watch: {
      isSharedSignatureAllReady(curValue) {
        if (curValue) {
          this.$emit("load-finish");
        }
      },
    },
  };
  </script>
  