<template>
	<PvAutoComplete
		:dropdown="true"
		:suggestions="filteredAccountingDetails"
		:field="field"
		@complete="searchAccountingDetail"
		@dropdown-click="filteredAccountingDetails"
		@item-select="selectedAccountingDetail"
		forceSelection
	/>
</template>

<script>
import useGetAccountingDetail from "../../hooks/getAccountingDetail.js";
import useLang from "../../hooks/lang.js";
import { useStore } from "vuex";
import { computed } from "vue";
import BaseDialog from "../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from 'vuejs-confirm-dialog';
export default {
	setup() {
		const { filteredAccountingDetails, searchTextAccountingDetail, isSharedAccountingDetailReady, searchAccountingDetail,getAllAccountingDetails } =
		useGetAccountingDetail();

		const { lang } = useLang();
		const store = useStore();

		const mergeLang = computed(function () {
		if (store.getters.selectedLanguage === "TH") {
			return {
				...lang.th.base,
				...lang.th.master.accountChart.edit,
			};
		} else {
			return {
				...lang.en.base,
				...lang.en.master.accountChart.edit,
			};
		}
		});

		return {
			searchAccountingDetail,
			filteredAccountingDetails,
			searchTextAccountingDetail,
			isSharedAccountingDetailReady,
			getAllAccountingDetails,
			lang: mergeLang,
		};
	},
	emits: ["load-finish", "is-inactive"],
	props: {
		isLoadingData: {
			type: Boolean,
			default: false
		},
		isValidateActiveStatus: {
			type: Boolean,
			default: true
		},
	},
	data() {
		return {
			validateWording: "",
		}
	},
	mounted() {
		if (this.isLoadingData) {
			this.getAllAccountingDetails();
		}
	},
	methods: {
		async selectedAccountingDetail(event) {
			if(this.isValidateActiveStatus && event.value.activeStatus == "N") {
				this.validateWording = [
					this.lang.validateActiveStatusWording.replace(":code", event.value.codeAndName.trim()),
					this.lang.pleaseSelectNewAccountChart
				]
				const isCanceled = await this.validateActiveStatusDialog.reveal();
				if(isCanceled){
					this.$emit("is-inactive");
				}
			}
		},
	},
	computed: {
		field() {
			let result = "codeAndNameWithActiveStatus";
			if(!this.isValidateActiveStatus) {
				result = "codeAndName"
			}
			return result;
		},
		validateActiveStatusDialog() {
			return createConfirmDialog(
				BaseDialog,
				{
					titleConfirm: this.lang.validateActiveStatusTitle,
					classTitle: "p-error",
					wordingConfirm: this.validateWording,
					dataButtons:[
						{
							id: "close",
							caption: this.lang.close,
							class: "p-button-outlined p-button-secondary w-full",
							action: "cancel",
						},
					],
					display: true
				}
			);
		}
	},
	watch: {
		isSharedAccountingDetailReady(curValue) {
			if (curValue) {
				this.$emit("load-finish");
			}
		},
	},
};
</script>
