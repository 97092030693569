<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0215 12.0188L19.7522 10.2881C20.8587 9.21463 21.3009 7.62834 20.9094 6.1373C20.518 4.64626 19.3535 3.4818 17.8625 3.09033C16.3714 2.69886 14.7851 3.14111 13.7117 4.24755L10.0102 7.94909C9.20878 8.74991 8.75851 9.83641 8.75851 10.9693C8.75851 12.1023 9.20878 13.1888 10.0102 13.9896L9.99917 14.0006C10.3439 14.3388 10.7443 14.6153 11.1827 14.8179"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.8063 9.19238C13.2448 9.3954 13.6451 9.6722 13.9898 10.0107C14.7912 10.8115 15.2415 11.898 15.2415 13.031C15.2415 14.1639 14.7912 15.2504 13.9898 16.0512L10.2883 19.7528C8.61308 21.378 5.94354 21.3578 4.29314 19.7074C2.64273 18.057 2.62253 15.3875 4.24776 13.7123L5.97848 11.9815"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "black",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
