<template>
  <base-toolbar
    :title="this.title"
    :dataButtons="dataButtons"
    @goBack="goBack()"
    @gotoCreate="gotoEdit('create')"
    :hideBackButton="isHideBack"
  >
  </base-toolbar>
  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="lang.notificationDialogHeader"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
    ></base-dialog>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.containerTitle }}</label>
      </div>

      <PvDivider></PvDivider>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="closingEntrys"
          v-model:selection="selectedClosingEntry"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(this.selectedClosingEntry.id)"
          scrollable
        >
          <PvColumn
            field="code"
            :header="lang.columnCode"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>

          <PvColumn
            field="date"
            :header="lang.columnDate"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.date) }}
            </template>
          </PvColumn>

          <PvColumn
            field="generalLedgerBookCode"
            :header="lang.colummBookCode"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>

          <PvColumn
            field="cancelStatus"
            :header="lang.cancel"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="{ data, field }">
              <base-tag
                v-if="data[field].trim() != ''"
                :Caption="lang.cancel"
                Class="error"
              />
            </template>
          </PvColumn>

          <PvColumn
            style="max-width: 5rem"
            headerClass="h-center"
            bodyStyle="text-align: center;justify-content: center;align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                :disabled="!this.isRoleSelect"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
            </template>
          </PvColumn>

          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
export default {
  setup() {
    const { lang } = useLang();
    const { textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const isHideBack = inject("isHideBack");
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.closingEntry.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.closingEntry.listing,
        };
      }
    });
    return {
      lang: mergeLang,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      isHideBack,
    };
  },
  data() {
    return {
      //table 
      isLoading: false,
      selectedClosingEntry: null,
      //dialog
      showValidateDialog: false,
      validateWording: "",
      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "w-full p-button-outlined p-button-secondary",
          action: "confirm",
        },
      ],
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.initiateData();
    this.checkShouldSetData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    async checkShouldSetData() {
      if (this.isSharedRoleByMenuReady) {
        this.getDatas();
      }
    },
    async initiateData() {
      this.selectedClosingEntry = null;
      this.showValidateDialog = false;
      this.validateWording = "";
    },
    async gotoEdit(id) {
      if (id != "create") {
        if(!this.isRoleSelect) {
          this.validateWording = this.lang.validateRoleSelect;
          this.showValidateDialog = true;
          return;
        }
      }
      else {
        if(!this.isRoleInsert) {
          this.validateWording = this.lang.validateRoleInsert;
          this.showValidateDialog = true;
          return;
        }
      }
      this.$router.push({ name: "closing-entry-edit", params: { id: encodeURI(id) }});
    },
    getDatas() {
      try {
        this.isLoading = true;
        const payload = {
          projectId: this.selectedProject.id,
        };
        this.$store.dispatch("closingEntry/getAllClosingEntrys", payload);
        this.isLoading = false;
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.isLoading = false;
      }
    },
  },
  computed: {
    title() {
      return this.lang.title;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    closingEntrys() {
      return this.$store.getters["closingEntry/closingEntrys"];
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("VC");
    },
    isRoleSelect() {
      if (this.roleByMenu && this.roleByMenu.select == "Y") {
        return true;
      }
      return false;
    },
    isRoleInsert() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    dataButtons() {
      return [
        {
          id: "createCaption",
          caption: this.lang.createCaption,
          class: "mr-2",
          icon: "icon-cancel",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
        },
      ];
    },
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.checkShouldSetData();
    },
  },
};
</script>

<style>
</style>