export default {
    setImportAssets(state, payload) {
      state.importAssets = payload;
    },
    resetImportAssets(state){
      state.importAssets = [];
    },
    setAssetCodes(state, payload) {
      state.assetCodes = payload;
    },
    resetAssetCodes(state){
      state.assetCodes = [];
    }
  };
  