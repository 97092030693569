<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.4167 6.41167L15.5875 3.58249C15.2123 3.20724 14.7035 2.99625 14.1729 2.99625H6.9979C5.89287 2.99625 4.99707 3.89205 4.99707 4.99708V19.0029C4.99707 20.1079 5.89287 21.0037 6.9979 21.0037H17.0021C18.1071 21.0037 19.0029 20.1079 19.0029 19.0029V7.82626C19.0029 7.29565 18.7919 6.78681 18.4167 6.41167Z"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.5006 15.0012L10.4994 11.4998"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.4994 15.0012L13.5006 11.4998"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "primary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
