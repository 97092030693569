export default {
  signatureManagements(state) {
    return state.signatureManagements;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
  selectedSignatureManagement(state) {
    return state.selectedSignatureManagement;
  },
};
