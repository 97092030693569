export default {
	createBank: "สร้างเอกสาร",
	editBank: "แก้ไขเอกสาร",
	createBankAp: "สร้างข้อมูลการจ่ายชำระ",
	editBankAp: "แก้ไขข้อมูลการจ่ายชำระ",
	askForProductChange: "คุณยืนยันที่จะแก้ไขรหัสรายรับใช่หรือไม่?",
	askForSelectProduct: "ไม่พบรายรับรหัส : :code ต้องการค้นหาหรือไม่?",
	askForNonExistPeriod:
		"งวดที่ระบุ :period ไม่ถูกต้อง ระบบจะใช้งวดในบรรทัดแรกแทน หากไม่มีจะใช้งวดปัจจุบัน",

	NetAmount: "จำนวนเงินที่ชำระ",
	DocumentCode: "เลขที่เอกสารชำระ/เลขที่เช็ค",
	CreateNew: "สร้างใหม่",
	SearchBy: "ค้นหาโดย",
	PayType: "ประเภทการชำระ",
	Status: "สถานะ",
	BankPayin: "รับเข้าบัญชี",
	BankPayout: "จ่ายจากบัญชี",
	Cross: "ขีดคร่อม",
	Holder: "ผู้ถือ",
	UnitCode: "รหัสห้อง/บ้าน",
	DateAt: "วันที่",
	CustomerName: "ชื่อลูกค้า",
	SupplierName: "ผู้จัดจำหน่าย",
	customerSearch: "รหัสหรือชื่อลูกค้า",
	employeeName: "ชื่อพนักงาน",
	ReceiveMan: "ชื่อผู้รับ",
	Remark: "หมายเหตุ",
	PayDate: "วันที่ชำระ",
	DueDate: "วันที่ครบกำหนด",
	SearchReceived: "แสดงเอกสาร",
	Extra: "เพิ่มเติม",
	CashType: "ประเภท",
	StartDate: "ตั้งแต่วันที่",
	EndDate: "ถึงวันที่",
	ReceiptCode: "เลขที่ใบเสร็จ",
	ToBankDatePayin: "วันที่เข้าธนาคาร",
	ToBankDatePayout: "วันที่หักจากบัญชี",

	columnCodeReceipt: "เลขที่ใบเสร็จรับเงิน",
	columnReceiptDate: "วันที่ใบเสร็จ",
	columnDate: "วันที่โอน/เช็ค",
	columnInvoice: "ใบแจ้งหนี้",
	columnUnitCode: "ยูนิต",
	columnTotal: "มูลค่า",
	columnCustomerName: "ชื่อลูกค้า",

	columnGLBookName: "ประเภทเอกสาร",
	columnGLBookCode: "ชื่อเอกสาร",
	columnCodeVoucher: "เลขที่เอกสาร",
	columnVoucherDate: "วันที่เอกสาร",
	columnVoucherDescription: "รายละเอียด",
	columnPayAmount: "มูลค่า",

	validateBookbank: "กรุณาเลือกบัญชีธนาคาร",
	validateNoCustomerSelect: "กรุณาเลือกลูกค้า",
	validateReceiptExtra: "ไม่มีรายการเพิ่มเติม",
	validatePayType: "กรุณาเลือกประเภทการชำระ",

	differenceAmount: "ยอดที่ต้องชำระ",
	cross:"เช็คขีดคร่อม",
	holder: "ขีดฆ่าหรือผู้ถือ",

	validateInvalidSetupBookbank: "สมุดบัญชีธนาคารผูกธนาคารไม่ถูกต้อง",
};
