<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '70vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center flex flex-wrap">
        <span class="font-lg" v-if="title">{{ title }}</span>
        <span class="font-lg" v-else>{{ lang.referenceDocument }}</span>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider class="mt-3 mb-3" />
    </template>

    <div class="p-datatable-border my-2">
      <PvDataTable
        :value="dataTable"
        dataKey="id"
        selectionMode="single"
        responsiveLayout="scroll"
        :class="textTableClass"
        class="p-datatable-xs"
        scrollHeight="450px"
        :loading="isLoading"
        v-if="columns == null"
      >
        <!-- <template #header>
            <div
              class="flex justify-content align-items-center"
              style="height: 56px"
            >             
            </div>
          </template> -->

        <PvColumn
          style="
            width: 10%;
            height: 56px;
            border-radius: 12px 0 0 0;
            border-bottom-left-radius: 12px;
          "
          field="documentBookCode"
          :header="lang.documentBookCode"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-left p-text-secondary"
          sortable
        >
        </PvColumn>

        <PvColumn
          field="documentBookName"
          :header="lang.documentBookName"
          style="width: 15%"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-left p-text-secondary"
          sortable
        >
        </PvColumn>
        <PvColumn
          field="code"
          :header="lang.code"
          style="width: 10%"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-left p-text-secondary"
        >
        </PvColumn>
        <PvColumn
          field="date"
          :header="lang.date"
          style="width: 5%"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-left p-text-secondary"
        >
          <template #body="slotProps">
            {{ formatDate(slotProps.data.date) }}
          </template>
        </PvColumn>
        <PvColumn
          field="cancelStatus"
          :header="lang.cancelStatus"
          style="width: 5%"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-center p-text-secondary"
        ></PvColumn>
        <PvColumn
          field="postStatus"
          :header="lang.postStatus"
          style="width: 5%"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-center p-text-secondary"
        ></PvColumn>
        <PvColumn
          field="amount"
          :header="lang.amount"
          style="width: 10%"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-right p-text-secondary"
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.amount) }}
          </template>
        </PvColumn>
        <PvColumn
          field="createBy"
          :header="lang.createBy"
          style="width: 10%"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-left p-text-secondary"
        ></PvColumn>
        <PvColumn
          field="createDate"
          :header="lang.createDate"
          style="width: 5%"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-left p-text-secondary"
        >
          <template #body="slotProps">
            {{ formatDate(slotProps.data.createDate) }}
          </template>
        </PvColumn>
        <PvColumn
          field="updateBy"
          :header="lang.updateBy"
          style="width: 10%"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-left p-text-secondary"
        ></PvColumn>
        <PvColumn
          style="width: 5%; border-radius: 0 12px 0 0; border-bottom-right-radius: 12px"
          field="updateDate"
          :header="lang.updateDate"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-left p-text-secondary"
        >
          <template #body="slotProps">
            <span v-if="slotProps.data.updateDate">
              {{ formatDate(slotProps.data.updateDate) }}
            </span>
          </template>
        </PvColumn>

        <template #empty>
          <div class="w-full h-3rem flex align-items-center justify-content-center">
            <label>{{ this.lang.tableEmpty }}</label>
          </div>
        </template>

        <!-- <template #footer>
          <label class="p-pagination-radius">&nbsp;</label>
        </template> -->
      </PvDataTable>

      <PvDataTable
        :value="dataTable"
        dataKey="id"
        selectionMode="single"
        responsiveLayout="scroll"
        :class="textTableClass"
        class="p-datatable-xs"
        scrollHeight="450px"
        :loading="isLoading"
        v-if="columns != null && columns.length > 0"
      >
        <PvColumn
          v-for="col of columns"
          :field="col.field"
          :header="col.header"
          :style="col.style"
          :headerClass="col.headerClass"
          :bodyClass="col.bodyClass"
          :key="col.field"
          :sortable="col.sortable"
        >
          <template #body="slotProps">
            <span v-if="col.type == 'date' && slotProps.data[col.field]">
              {{ formatDate(slotProps.data[col.field]) }}
            </span>
            <span v-if="col.type == 'currency'">
              {{ formatCurrency(slotProps.data[col.field]) }}
            </span>
            <span v-if="col.type == 'string'">
              {{ slotProps.data[col.field] }}
            </span>
          </template>
        </PvColumn>

        <template #empty>
          <div class="w-full h-3rem flex align-items-center justify-content-center">
            <label>{{ this.lang.tableEmpty }}</label>
          </div>
        </template>

        <!-- <template #footer>
          <label class="p-pagination-radius">&nbsp;</label>
        </template> -->
      </PvDataTable>
    </div>
    <!-- <div v-for="items in dataTable" v-bind:key="items.data"></div> -->

    <!-- <template #footer>
      <div class="md:text-center"></div>
    </template> -->
    <template #footer></template>
  </PvDialog>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
export default {
  props: {
    dataTable: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  emits: ["close"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.shared.referenceDocumen,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.shared.referenceDocumen,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
    };
  },
  data() {
    return {
      isLoading: false,
      // dataTable: [
      //   {
      //     id: "123456",
      //     documentBookCode: "PI-7",
      //     code: "6511000001",
      //     date: "2022-11-01",
      //     cancelStatus: "",
      //     postStatus: "",
      //     amount: 52000,
      //     createBy: "Admin",
      //     createDate: "2022-11-15",
      //     updateBy: "Admin",
      //     updateDate: "2022-11-20",
      //   },
      //   {
      //     id: "98765",
      //     documentBookCode: "PT-0",
      //     code: "6511000002",
      //     date: "2022-11-01",
      //     cancelStatus: "",
      //     postStatus: "",
      //     amount: 500,
      //     createBy: "Admin",
      //     createDate: "2022-11-15",
      //     updateBy: "",
      //     updateDate: "",
      //   },
      // ],
    };
  },
  created() {
    // console.log(this.dataTable);
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.p-pagination-radius {
  border-radius: 0 0 12px 12px;
}
</style>
