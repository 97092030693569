export default {
	async getPolicy(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Policy";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get policy");
			throw error;
		}
		// console.log("policy", responseData);

		context.commit("setPolicy", responseData);
	},
	async getAllPolicy(context) {
		await context.commit("resetPolicy");
		while (!context.getters.policyIsLastPage) {
			let where = {
				projectId: context.rootGetters.selectedProject.id,
			};
			const payload = {
				page: context.getters.policyNextPage || 1,
				where,
			};
			await context.dispatch("getPolicy", payload);
		}
	},
	async resetPolicy(context) {
		await context.commit("resetPolicy");
	},
	async deletePolicy(context, payload) {
		// console.log('deletePolicy',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/Policy/DeletePolicy";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("deletePolicy>>>", responseData);
			// const error = new Error(responseData.message || "Failed to Add Purchase Receipt");
			throw responseData;
		}
		// console.log("deletePolicy>>>", responseData);
	},
	async getPolicyById(context, id) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Policy/" + id;
		url = encodeURI(url);

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get policy");
			throw error;
		}
		// console.log("policy", responseData);

		context.commit("setSelectedPolicy", responseData.data);
	},
	async addPolicy(context, payload) {
		// console.log('addPolicy',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/Policy/Add";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("addPolicy>>>", responseData);
			// const error = new Error(responseData.message || "Failed to Add Purchase Receipt");
			throw responseData;
		}
		// console.log("addPolicy>>>", responseData);
		context.commit("setSelectedPolicy", responseData.data);
	},
	async updatePolicy(context, payload) {
		// console.log('updatePolicy',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/Policy/Update";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("updatePolicy>>>", responseData);
			// const error = new Error(responseData.message || "Failed to Add Purchase Receipt");
			throw responseData;
		}
		// console.log("updatePolicy>>>", responseData);
		context.commit("setSelectedPolicy", responseData.data);
	},
	async resetSelectedPolicy(context) {
		await context.commit("resetSelectedPolicy");
	},
};
