export default {
  taxTaxes(state) {
    return state.tax.taxes;
  },
  taxCurrentPage(state) {
    return state.tax.currentPage;
  },
  taxNextPage(state) {
    return state.tax.currentPage + 1;
  },
  taxTotalPage(state) {
    return state.tax.totalPage;
  },
  taxIsLastPage(_, getters) {
    return getters.taxCurrentPage === getters.taxTotalPage && getters.taxTotalPage !== 0;
  }
};
