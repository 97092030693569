<template>
	<!-- <div class="p-inputgroup"> -->
	<PvAutoComplete
		:dropdown="true"
		:suggestions="filteredSuppliers"
		field="codeAndName"
		@complete="searchSupplier"
		@dropdown-click="filteredSuppliers"
		forceSelection
	/>
	<!-- <PvButton icon="pi pi-plus" class="p-button-rounded p-button-outlined ml-2" />
	</div> -->
</template>

<script>
import useGetSupplier from "../../hooks/getSupplier.js";
export default {
	setup() {
		const { filteredSuppliers, searchText, isSharedSupplierReady, searchSupplier, getAllSuppliers } =
			useGetSupplier();

		return {
			filteredSuppliers,
			searchText,
			isSharedSupplierReady,
			searchSupplier,
			getAllSuppliers,
		};
	},
	emits: ["load-finish"],
	props: {
		isLoadingData: {
			type: Boolean,
			default: true
		},
	},
	mounted() {
		if (this.isLoadingData) {
			this.getAllSuppliers();
		}
	},
	watch: {
		isSharedSupplierReady(curValue) {
			if (curValue) {
				this.$emit("load-finish");
			}
		},
	},
};
</script>
