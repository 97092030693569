export default {
	setAdvertisement(state, payload) {
		state.advertisement = payload;
	},
	setFirstAdvertisement(state, payload) {
		state.firstAdvertisement = payload;
	},
	resetAdvertisement(state) {
		state.advertisement = [];
	},
};