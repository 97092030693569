export default {
	async addInvoicePrintOrderChanges(context, payload) {
		let url = process.env.VUE_APP_REPORT_API_URL + "/Produces/InvoicePrintOrderChanges";
		const token = context.rootGetters.token;
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});
		const responseData = await response.json();
		if (!response.ok) {
			throw responseData;
		}
		await context.commit("setIsInvoicePrintOrderChanges", responseData.data);
	},
	async retryInvoicePrintOrderChanges(context, id) {
		let url = process.env.VUE_APP_REPORT_API_URL + "/Produces/InvoicePrintOrderChanges";

		if (id) {
			url = url + "/" + id + "/Retry";
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Report Request By Id");
			throw error;
		}
		// console.log("retryInvoicePrintOrderChanges responseData", responseData);
	},
	async retryPrintOrder(context, id) {
		let url = process.env.VUE_APP_REPORT_API_URL + "/Produces/PrintOrder";

		if (id) {
			url = url + "/" + id + "/Retry";
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Report Request By Id");
			throw error;
		}
		// console.log("retryPrintOrder responseData", responseData);
	},
	async addPrintOrder(context, payload) {
		let url = process.env.VUE_APP_REPORT_API_URL + "/Produces/PrintOrder";
		const token = context.rootGetters.token;
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});
		const responseData = await response.json();
		if (!response.ok) {
			if (responseData.code == "105") {
				return responseData;
			} else {
				throw responseData;
			}
		}
		await context.commit("setIsPrintOrder", responseData.data);
		return responseData;
	},

	async addReceiptPrintOrder(context, payload) {
		let url = process.env.VUE_APP_REPORT_API_URL + "/Produces/ReceiptPrintOrder";
		const token = context.rootGetters.token;
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});
		const responseData = await response.json();
		if (!response.ok) {
			throw responseData;
		}
		await context.commit("setIsReceiptPrintOrder", responseData.data);
	},

	async addNoticePrintOrder(context, payload) {
		let url = process.env.VUE_APP_REPORT_API_URL + "/Produces/NoticeCustomReportPrintOrder";
		const token = context.rootGetters.token;
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});
		const responseData = await response.json();
		if (!response.ok) {
			throw responseData;
		}
		await context.commit("setIsNoticePrintOrder", responseData.data);
	},

	async reportWithholdingTaxPrintOrder(context, payload) {
		let url = process.env.VUE_APP_REPORT_API_URL + "/Produces/WithholdingTaxPrintOrder";
		const token = context.rootGetters.token;
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});
		const responseData = await response.json();
		if (!response.ok) {
			throw responseData;
		}
		await context.commit("setIsWithholdingTaxPrintOrder", responseData.data);
	},
	async addPrintOrderPostgres(context, payload) {
		let url = process.env.VUE_APP_REPORT_API_URL + "/V2/Produces/PrintOrder";
		const token = context.rootGetters.token;
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});
		const responseData = await response.json();
		if (!response.ok) {
			if (responseData.code == "105") {
				return responseData;
			} else {
				throw responseData;
			}
		}
		await context.commit("setIsPrintOrder", responseData.data);
		return responseData;
	},

};
