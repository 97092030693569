import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default function useGetProductUnit() {
  const store = useStore();
  let searchTextProductUnit = ref(null);

  const productUnits = computed(() => {
    return store.getters["shared/productUnits"];
  });

  const productUnitIsLastPage = computed(() => {
    return store.getters["shared/productUnitIsLastPage"];
  });

  const filteredProductUnits = computed(() => {
    if (!searchTextProductUnit.value) {
      //ช่องว่าง
      const result = [...productUnits.value];
      return result;
    } else {
      return productUnits.value.filter((productUnit) => {
        return productUnit.searchText
          .toLowerCase()
          .includes(searchTextProductUnit.value.toLowerCase());
      });
    }
  });

  const searchProductUnit = function (event) {
    // console.log(event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchTextProductUnit.value = "";
      searchTextProductUnit.value = null;
    } else {
      searchTextProductUnit.value = event.query;
    }
  };

  let isSharedProductUnitReady = ref(false);

  const getAllProductUnits = async function () {
    if (!productUnitIsLastPage.value) {
      await store.dispatch("shared/getAllProductUnits");
    }
    isSharedProductUnitReady.value = true;
  };

  const getProductUnitByCode = function (code) {
    return productUnits.value.find((productUnit) => {
      return productUnit.code.trim().toLowerCase() === code.trim().toLowerCase();
    });
  };

  onMounted(async () => {
    await getAllProductUnits();
  });

  return {
    searchProductUnit,
    filteredProductUnits,
    searchTextProductUnit,
    getProductUnitByCode,
    isSharedProductUnitReady,
    productUnits
  };
}
