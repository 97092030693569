import unitMutations from "./unit/mutations.js";
import productArMutations from "./productAR/mutations.js";
import documentBookDistinctMutations from "./documentBookDistinct/mutations.js";
import projectMutations from "./project/mutations.js";
import formPrintMutations from "./formPrint/mutations.js";
import supplierMutations from "./supplier/mutations.js";

import glBookMutations from "./glBook/mutations.js";
import accountingFrequentlyMutations from "./accountingFrequently/mutations.js";
import accountingMutations from "./accounting/accountingAll/mutations.js";
import taxBookMutations from "./taxBook/mutations.js";

import customerMutations from "./customer/mutations.js";
import apBookMutations from "./apBook/mutations.js";
import productApMutations from "./productAP/mutations.js";
import employeeMutations from "./employee/mutations.js";
import purchaseMutations from "./billAcceptance/mutations.js";
import taxMutations from "./tax/mutations.js";
import buildingMutations from "./building/mutations.js";
import jobAdminMutations from "./jobAdmin/mutations.js";
import locationMutations from "./location/mutations.js";
import accountingDetailMutations from "./accounting/accountingDetail/mutations.js";
import accountingParentMutations from "./accounting/accountingParent/mutations.js";
import productUnitMutations from "./productUnit/mutations.js";
import nationalityMutations from "./nationality/mutations.js";
import bankMutations from "./bank/mutations.js";
import printMutations from "./print/mutations.js";
import roleByMenuMutations from "./roleByMenu/mutations.js";
import roleByBookMutations from "./roleByBook/mutations.js";
import currencyMutations from "./currency/mutations.js";
import bookBankArMutations from "./bookBankAr/mutations.js";
import productGuaranteeMutations from "./productGuarantee/mutations.js";
import addressMutations from "./address/mutations.js";
import dbdBusinessTypeMutations from "./dbdBusinessType/mutations.js";
import countryCodeMutations from "./countryCode/mutations.js";


const mergeMutations = {
	...unitMutations,
	...productArMutations,
	...documentBookDistinctMutations,
	...projectMutations,
	...formPrintMutations,
	...glBookMutations,
	...accountingFrequentlyMutations,
	...accountingMutations,
	...supplierMutations,
	...taxBookMutations,
	...customerMutations,
	...apBookMutations,
	...productApMutations,
	...employeeMutations,
	...purchaseMutations,
	...taxMutations,
	...buildingMutations,
	...jobAdminMutations,
	...locationMutations,
	...accountingDetailMutations,
	...accountingParentMutations,
	...productUnitMutations,
	...nationalityMutations,
	...bankMutations,
	...printMutations,
	...roleByMenuMutations,
	...roleByBookMutations,
	...currencyMutations,
	...bookBankArMutations,
	...productGuaranteeMutations,
	...addressMutations,
	...dbdBusinessTypeMutations,
	...countryCodeMutations,
};

export default mergeMutations;
