<template>
  <base-toolbar 
    :title="lang.title" 
    @goBack="goBack"
    :hideBackButton="isHideBack"
  ></base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :classTitle="baseDialogClassTitle"
      :titleConfirm="titleConfirmDialog"
      :wordingConfirm="wordingConfirmDialog"
      :dataButtons="dataButtonDialog"
      @close="closeValidateDialog"
    ></base-dialog>

    <div v-if="isWaitForGetQueue" class="center">
      <PvProgressSpinner strokeWidth="5"/>
    </div>

    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5" v-else>
      <div>
        <label class="font-lg font-semibold">{{ titleDetail }}</label>
      </div>

      <PvDivider></PvDivider>

      <div v-if="isInQueue">
        <label class="font-md font-normal">{{ lang.titleDetailCalculatingDesc }}</label>
      </div>

      <div v-else>
        <base-card
          :titleHeader="lang.titleSelectDate"
          :isVisibleExpandButton="false"
          :isHideBackground="true"
          :isVisibleCard="true"
          class="p-0"
        >
          <section class="flex flex-wrap" :class="textFormClass">
            <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
              <label>{{ lang.invoiceDate }}</label>
              <PvCalendar
                id="invoiceDate"
                v-model="invoiceDate"
                class="inputfield w-full mt-2"
                showIcon
                dateFormat="dd/mm/yy"
                @date-select="checkPenaltyDate"
                :disabled="!isRoleInsert || isDisabledNextStep"
                :class="{ 'p-invalid': invoiceDateError }"
              />
              <small v-if="invoiceDateError" id="invoiceDate-help" class="p-error">
                {{ lang.validateInvoiceDate }}
              </small>
            </div>
            <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
              <label>{{ lang.dueDate }}</label>
              <PvCalendar
                id="dueDate"
                v-model="dueDate"
                class="inputfield w-full mt-2"
                showIcon
                dateFormat="dd/mm/yy"
                :disabled="!isRoleInsert || isDisabledNextStep"
                @date-select="validateInvoiceDate"
                :class="{ 'p-invalid': dueDateError }"
              />
              <small v-if="dueDateError" id="dueDate-help" class="p-error">
                {{ lang.validateDueDate }}
              </small>
            </div>
            <div class="pr-4 pt-6">
              <PvCheckbox
                id="isCalculatePenalty"
                inputId="binary"
                v-model="isCalculatePenalty"
                :binary="true"
                :disabled="!isRoleInsert || isDisabledNextStep"
                @change="checkCalculatePenalty"
              />
              <label for="isCalculatePenalty" class="pl-3">{{
                lang.calculatePenalty
              }}</label>
            </div>
            <div
              v-if="!isVisiblePenaltyDate"
              class="lg:col-3 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4"
            >
              <label>{{ lang.calculatePenaltyDate }}</label>
              <PvCalendar
                id="penaltyDate"
                v-model="penaltyDate"
                class="inputfield w-full mt-2"
                showIcon
                dateFormat="dd/mm/yy"
                :disabled="!isRoleInsert || isDisabledPenaltyDate"
              />
            </div>
          </section>
          <div class="pt-2 pb-1">
            <PvButton :disabled="checkDisabledNextStep" @click="nextStep">
              <span class="font-sm font-normal">{{ lang.next }}</span>
              <icon-right-arrow
                class="p-button-outlined p-button-primary ml-2"
                iconColor="white"
                height="16"
                width="16"
              />
            </PvButton>
          </div>
        </base-card>

        <base-card
          :titleHeader="lang.titleSelectPolicy"
          :isVisibleExpandButton="false"
          :isHideBackground="true"
          :isVisibleCard="true"
          class="p-0 pt-2"
          disabled="true"
          v-if="isBackStep"
        >
          <div>
            <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label class="font-sm font-normal" for="policy">{{ lang.policy }}</label>
              <!-- <base-building-auto-complete
                id="building"
                class="inputfield w-full mt-2"
                v-model="selectedBuilding"
                @load-finish="buildingDataReady"
              /> -->
              <PvSkeleton
                v-if="!isPolicyReady"
                class="inputfield w-full mt-1"
                height="39px"
              />
              <policyauto-complete
                id="building"
                class="inputfield w-full mt-2"
                v-model="selectedPolicy"
                @load-finish="policyDataReady"
                v-show="isPolicyReady"
                :class="{ 'p-invalid': selectedPolicyError }"
                @item-select="selectedPolicyError"
              >
              </policyauto-complete>
              <small
                v-if="selectedPolicyError"
                id="selected-policy-help"
                class="p-error"
              >{{ lang.validateNoSelectedPolicy }}</small>
            </div>
            <div class="pt-4 pb-2">
              <label class="font-lg font-semibold">{{ lang.getPolicyList }}</label>
              <div class="my-4">
                <PvDataTable
                  :value="policyDetail"
                  selectionMode="single"
                  dataKey="id"
                  responsiveLayout="scroll"
                  class="p-datatable-sm"
                  :class="textTableClass"
                  :loading="isLoading"
                  scrollable
                >
                  <PvColumn
                    field="productCode"
                    :header="lang.columnProductCode"
                    style="min-width: 8rem; height: 56px"
                    headerClass="h-left"
                    bodyClass="text-left font-normal"
                  ></PvColumn>
                  <PvColumn
                    field="productName"
                    :header="lang.columnProductName"
                    style="min-width: 8rem"
                    headerClass="h-left"
                    bodyClass="text-left font-normal"
                  ></PvColumn>
                  <PvColumn
                    field="remark"
                    :header="lang.columnRemark"
                    style="min-width: 8rem"
                    headerClass="h-left"
                    bodyClass="text-left font-normal"
                  ></PvColumn>
                  <PvColumn
                    field="period"
                    :header="lang.columnPeriod"
                    style="min-width: 4rem"
                    headerClass="h-left"
                    bodyClass="text-left font-normal"
                  ></PvColumn>

                  <template #empty>
                    <div class="w-full p-2" style="text-align: center">
                      <label>{{ this.lang.tableEmpty }}</label>
                    </div>
                  </template>
                </PvDataTable>
              </div>
            </div>
            <div class="flex justify-content-between">
              <div class="pt-2 pb-1">
                <PvButton @click="backStep">
                  <icon-left-arrow
                    class="p-button-outlined p-button-primary mr-2"
                    iconColor="white"
                    height="16"
                    width="16"
                  />
                  <span class="font-sm font-normal">{{ lang.goBack }}</span>
                </PvButton>
              </div>
              <div class="pt-2 pb-1">
                <PvButton @click="calculateInvoice" :disabled="!this.selectedPolicy">
                  <span class="font-sm font-normal">{{ lang.gotoCalculateInvoice }}</span>
                </PvButton>
              </div>
            </div>
          </div>
        </base-card>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetRoleByBook from "../../../hooks/getRoleByBook.js";
import PolicyautoComplete from "../../../components/master/policy/PolicyAutoComplete.vue";
export default {
  components: {
    PolicyautoComplete,
  },
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const {
      formatCurrency,
      formatDate,
      formatDateToISOString,
      formatPeriodToISOString,
    } = useFormat();
    const store = useStore();

    const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.calculateInvoice.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.calculateInvoice.listing,
        };
      }
    });

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      formatPeriodToISOString,
      isHideBack,
      getRoleByBookByCode,
      isSharedRoleByBookReady,
    };
  },
  data() {
    return {
      isLoading: false,
      showValidateDialog: false,
      dataButtonDialog: [
        {
          id: "close",
          caption: this.lang.close,
          class: "w-full p-button-outlined p-button-secondary",
          action: "close",
        },
      ],

      countDown: 10,
      invoiceDate: null,
      dueDate: null,
      isCalculatePenalty: true,
      penaltyDate: null,
      isInQueue: false,
      interval: null,
      isDisabledPenaltyDate: false,
      isVisiblePenaltyDate: false,
      titleConfirmDialog: "",
      wordingConfirmDialog: "",
      baseDialogClassTitle: "",
      isDisabledNextStep: false,
      isBackStep: false,
      selectedPolicy: null,
      isPolicyReady: false,
      isWaitForGetQueue: true,
    };
  },
  async mounted() {
    await this.initiateData();
    await this.roleByBookDataReady();
    await this.generateQueueStatus();
    // await this.getInvoiceGenerateQueueStatus();
    await this.getPenaltyType();
    await this.$store.dispatch("shared/getAllCurrency");

    // if(this.invoiceGenerateQueueStatus.isAlreadyInQueue == true) {
    //   this.isInQueue = true;
    //   this.getNewInvoiceGenerateQueueStatus();
    // } else if(this.invoiceGenerateQueueStatus.isAlreadyInQueue == false && this.invoiceGenerateQueueStatus.statusWaitForGenBill == "F") {
    //   this.$router.push("/ar/calculate-invoice/invoice-wait-for-approve");
    // }
    // await this.checkInvoiceGenerateQueueStatus();

    await this.checkPenaltyType();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      clearInterval(this.interval);
			this.$router.push({name: "home"})
    },
    closeValidateDialog() {
      if (this.isDisabledNextStep) {
        this.isDisabledNextStep = false;
      }
      this.showValidateDialog = false;
    },
    async getInvoiceGenerateQueueStatus() {
      try {
        let projectId = this.selectedProject.id;
        await this.$store.dispatch(
          "arCalculateInvoice/getInvoiceGenerateQueueStatus",
          projectId
        );
        this.isWaitForGetQueue = false;
      } catch (error) {
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = error;
        this.showValidateDialog = true;
        this.isWaitForGetQueue = false;
      }
    },
    async getPenaltyType() {
      try {
        let projectId = this.selectedProject.id;
        await this.$store.dispatch("penalty/getPenaltyType", projectId);
      } catch (error) {
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = error;
        this.showValidateDialog = true;
      }
    },
    async getInvoiceReadyGenerateBill() {
      try {
        const payload = {
          projectId: this.selectedProject.id,
          documentBookId: this.roleByBook.documentBookId,
          policyCode: this.selectedPolicy.code,
          period: this.formatPeriodToISOString(this.invoiceDate),
        };

        await this.$store.dispatch(
          "arCalculateInvoice/getInvoiceReadyGenerateBill",
          payload
        );
      } catch (error) {
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = error;
        this.showValidateDialog = true;
      }
    },
    getNewInvoiceGenerateQueueStatus() {
      this.interval = setInterval(() => {
        if (this.invoiceGenerateQueueStatus.isAlreadyInQueue == false) {
          // console.log("if get queue", this.invoiceGenerateQueueStatus.isAlreadyInQueue);
          clearInterval(this.interval);
          this.gotoInvoiceWaitForApprove();
          return;
        } else {
          // console.log("else get queue", this.invoiceGenerateQueueStatus.isAlreadyInQueue);
          this.getInvoiceGenerateQueueStatus();
        }
      }, 10000);
    },
    async checkPenaltyDate() {
      await this.validateInvoiceDate();
      if (this.penaltyType.type == "Y" || this.penaltyType.type == "N") {
        let date = this.invoiceDate.getDate();
        let invoiceDate = new Date(
          this.invoiceDate.getFullYear(),
          this.invoiceDate.getMonth(),
          date
        );
        if (date == 1) {
          this.penaltyDate = new Date(
            this.invoiceDate.getFullYear(),
            this.invoiceDate.getMonth(-1),
            0
          );
        } else {
          this.penaltyDate = invoiceDate;
        }
      } else if (this.penaltyType.type == "E") {
        this.penaltyDate = new Date(
          this.invoiceDate.getFullYear(),
          this.invoiceDate.getMonth() + 1,
          0
        );
      }
    },
    checkCalculatePenalty() {
      if (!this.isCalculatePenalty) {
        this.isVisiblePenaltyDate = true;
        this.isDisabledPenaltyDate = true;
      } else {
        this.isVisiblePenaltyDate = false;
        this.isDisabledPenaltyDate = false;
      }
      this.checkPenaltyType();
    },
    async checkPenaltyType() {
      let penaltyType = this.penaltyType.type;
      if (penaltyType == "N") {
        this.isDisabledPenaltyDate = true;
      } else if (penaltyType == "E") {
        this.isDisabledPenaltyDate = true;
        this.penaltyDate = new Date(
          this.invoiceDate.getFullYear(),
          this.invoiceDate.getMonth() + 1,
          0
        );
      }
    },
    async validateInvoiceDate() {
      if (this.invoiceDate > this.dueDate) {
        // this.titleConfirmDialog = this.lang.notificationDialogHeader;
        // this.wordingConfirmDialog = this.lang.validateInvoiceDate;
        // this.isDisabledNextStep = true;
        // this.showValidateDialog = true;
        // this.invoiceDateError = true;
      } else {
        // this.invoiceDateError = false;
        this.isDisabledNextStep = false;
      }
    },
    async nextStep() {
      await this.validateInvoiceDate();
      if (!this.showValidateDialog) {
        this.isDisabledPenaltyDate = true;
        this.isDisabledNextStep = true;
        this.isBackStep = true;
      }
    },
    backStep() {
      this.isBackStep = false;
      this.isDisabledNextStep = false;
      if (!this.isCalculatePenalty) {
        this.isDisabledPenaltyDate = false;
      } else {
        this.isDisabledPenaltyDate = true;
      }
    },
    async calculateInvoice() {
      await this.getInvoiceReadyGenerateBill();
      // console.log("invoiceReadyGenerateBill", this.invoiceReadyGenerateBill);
      if (this.invoiceReadyGenerateBill.isReady == false) {
        this.baseDialogClassTitle = "";
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = this.invoiceReadyGenerateBill.statusDesc.split("<br>");
        this.showValidateDialog = true;
      } else {
        await this.addInvoiceGenerateQueue();
        // console.log("selectedinvoiceGenerateQueue", this.selectedinvoiceGenerateQueue);
        if (this.selectedinvoiceGenerateQueue.success == true) {
          await this.generateQueueStatus();
          // await this.getInvoiceGenerateQueueStatus();
          // if (this.invoiceGenerateQueueStatus.isAlreadyInQueue == true) {
          //   this.isInQueue = true;
          //   this.getNewInvoiceGenerateQueueStatus();
          // }
          // await this.checkInvoiceGenerateQueueStatus();
        }
      }
    },
    async addInvoiceGenerateQueue() {
      try {
        let currencyId = this.currency.find((data) => data.code.trim() == "THB").id;
        const payload = {
          policyHeaderId: this.selectedPolicy.id,
          documentBookId: this.roleByBook.documentBookId,
          employeeId: this.employee.id,
          currencyId: currencyId,
          monthOf: this.formatPeriodToISOString(this.invoiceDate),
          docDate: this.formatDateToISOString(this.invoiceDate),
          dueDate: this.formatDateToISOString(this.dueDate),
          vatDate: this.formatDateToISOString(this.invoiceDate),
          calDate: this.formatDateToISOString(this.penaltyDate),
          branchId: this.selectedProject.branchId,
          projectId: this.selectedProject.id,
          isCalPenalty: this.isCalculatePenalty == true ? "Y" : "N",
        };

        // console.log("addInvoiceGenerateQueue", payload);

        await this.$store.dispatch("arCalculateInvoice/addInvoiceGenerateQueue", payload);
      } catch (error) {
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = error;
        this.showValidateDialog = true;
      }
    },
    policyDataReady() {
      this.isPolicyReady = true;
      let invoiceMonth = this.invoiceDate.getMonth() + 1;
      this.selectedPolicy = this.policy.find((data) =>
        data.code.trim().includes(invoiceMonth)
      );
    },
    gotoInvoiceWaitForApprove() {
      this.$router.push({name: "ar-invoice-wait-for-approve"});
    },
    async checkInvoiceGenerateQueueStatus() {
      if (this.invoiceGenerateQueueStatus.isAlreadyInQueue == true) {
        this.isInQueue = true;
        this.getNewInvoiceGenerateQueueStatus();
      } else if (
        this.invoiceGenerateQueueStatus.isAlreadyInQueue == false &&
        this.invoiceGenerateQueueStatus.statusWaitForGenBill == "F"
      ) {
        await this.getInvoices();
        if (
          (this.invoices && this.invoices.length > 0) &&
          this.roleByBook.documentBookId == this.invoiceGenerateQueueStatus.documentBookId
        ) {
          this.gotoInvoiceWaitForApprove();
        }
      }
    },
    async generateQueueStatus() {
      await this.getInvoiceGenerateQueueStatus();
      await this.checkInvoiceGenerateQueueStatus();
    },
    async roleByBookDataReady() {
      if (this.isSharedRoleByBookReady && !this.isRoleInsert) {
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = this.lang.validateInsert;
        this.showValidateDialog = true;
      }
    },
    async initiateData() {
      await this.$store.dispatch("arCalculateInvoice/resetInvoiceGenerateQueue");
      this.isInQueue = false;
      let date = new Date(); 
      this.invoiceDate = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dueDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.penaltyDate = new Date(date.getFullYear(), date.getMonth(-1), 0);
    },
    async getInvoices() {
      try {
        let where = {
          projectId: this.selectedProject.id,
          documentBookId: this.roleByBook.documentBookId,
          searchText: this.searchText,
        };
        const payload = {
          page: 1,
          limit: 10,
          where,
        };
        await this.$store.dispatch("arCalculateInvoice/getInvoices", payload);
      } catch (error) {
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = error;
        this.confirmDialog = true;
        this.showValidateDialog = true;
      }
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    roleByBook() {
      return this.getRoleByBookByCode("BILL");
    },
    isRoleInsert() {
      if (
        this.roleByBook &&
        this.isSharedRoleByBookReady &&
        this.roleByBook.insert == "Y"
      ) {
        return true;
      }
      return false;
    },
    employee() {
      return this.$store.getters.employee;
    },
    currency() {
      return this.$store.getters["shared/currencycurrencies"];
    },
    invoiceGenerateQueueStatus() {
      return this.$store.getters["arCalculateInvoice/invoiceGenerateQueueStatus"];
    },
    penaltyType() {
      return this.$store.getters["penalty/penaltyType"];
    },
    policy() {
      return this.$store.getters["policy/policy"];
    },
    invoiceReadyGenerateBill() {
      return this.$store.getters["arCalculateInvoice/invoiceReadyGenerateBill"];
    },
    policyDetail() {
      let result = [];
      if (this.selectedPolicy) {
        result = this.selectedPolicy.policyDetails;
        return result;
      }
      return result;
    },
    selectedInvoiceDate() {
      let invoiceDate = null;
      if (this.invoiceDate) {
        invoiceDate = this.formatDateToISOString(this.invoiceDate);
      }
      return invoiceDate;
    },
    selectedDueDate() {
      let dueDate = null;
      if (this.dueDate) {
        dueDate = this.formatDateToISOString(this.dueDate);
      }
      return dueDate;
    },
    selectedPenaltyDate() {
      let penaltyDate = null;
      if (this.penaltyDate) {
        penaltyDate = this.formatDateToISOString(this.penaltyDate);
      }
      return penaltyDate;
    },
    titleDetail() {
      let result = this.lang.titleDetail;
      if (this.isInQueue) {
        result = this.lang.titleDetailCalculating;
      }
      return result;
    },
    selectedinvoiceGenerateQueue() {
      return this.$store.getters["arCalculateInvoice/selectedinvoiceGenerateQueue"];
    },
    checkDisabledNextStep() {
      if (
        !this.isRoleInsert ||
        this.isDisabledNextStep ||
        this.invoiceDateError ||
        this.dueDateError
      ) {
        return true;
      }
      return false;
    },
    isEmptyPolicyDetail() {
      if (this.policyDetail.length == 0) {
        return true;
      }
      return false;
    },
    invoiceDateError() {
      if (this.invoiceDate == null) {
        return true;
      } else if (this.dueDate && this.invoiceDate > this.dueDate) {
        return true;
      }
      return false;
    },
    dueDateError() {
      if (this.dueDate == null) {
        return true;
      }
      return false;
    },
    invoices() {
      return this.$store.getters["arCalculateInvoice/invoices"];
    },
    selectedPolicyError() {
      if(!this.selectedPolicy) {
        return true;
      }
      return false;
    },
  },
  watch: {
    isSharedRoleByBookReady() {
      this.roleByBookDataReady();
    },
  },
};
</script>

<style scoped>
.center {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  z-index: 10000;
}
</style>