<template>
  <PvDialog
    :breakpoints="{ '1280px': '578px', '640px': '90vw' }"
    :style="{ width: '578px' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showConfirmDialog"
      :titleConfirm="titleConfirmDialog"
      :wordingConfirm="wordingDialog"
      @confirm="confirm"
      @close="close"
    ></base-dialog>

    <template #header>
      <div
        class="flex justify-content-between align-items-center flex flex-wrap"
      >
        <span class="font-lg">{{ title }}</span>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider class="mt-3 mb-3" />
    </template>

    <section class="flex flex-wrap">
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
        <label>{{ lang.productCode }}</label>
        <base-product-auto-complete
          id="ProductCode"
          v-model="ProductCode"
          class="inputfield w-full mt-1"
          :textPlaceholder="lang.validateProductCode"
          @load-finish="productReady"
          :class="{ 'p-invalid': isProductCodeError }"
          @change="changeProductCode()"
          @blur="changeProductCode()"

        />
        <small
          v-if="isProductCodeError"
          id="isProductCodeError-help"
          class="p-error"
        >
          {{ lang.validateProductCode }}
        </small>
        <!-- <PvInputText
          type="text"
          v-model="ProductCode"
          class="inputfield w-full mt-1"
          :placeholder="lang.validateProductMethodCode"
          readonly
        /> -->
      </div>
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
        <label>{{ lang.columnPrice }}</label>
        <PvInputNumber
          class="inputfield w-full mt-1"
          inputClass="text-right"
          v-model="Price"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="2"
          placeholder="0.00"
          @focus="$event.target.select()"
          :class="{ 'p-invalid': isPriceError }"
          @keydown="keyupPrice()"
        />

        <small v-if="isPriceError" id="isPriceError-help" class="p-error">
          {{ lang.validatePriceNotLessThanZero }}
        </small>
      </div>
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
        <label>{{ lang.columnOwner }}</label>
        <PvDropdown
          class="inputfield w-full mt-1"
          :options="Owner"
          optionLabel="name"
          optionValue="code"
          v-model="selectedOwner"
          :placeholder="lang.validateOwner"
          :class="{ 'p-invalid': isOwnerError }"
          @change="changeOwner()"
        />

        <small v-if="isOwnerError" id="isOwnerError-help" class="p-error">
          {{ lang.validateOwner }}
        </small>
      </div>
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
        <label>{{ lang.columnRemark }}</label>
        <PvInputText
          type="text"
          v-model="Remark"
          class="inputfield w-full mt-1"
          :placeholder="lang.validateRemark"
        />
      </div>
    </section>

    <template #footer>
      <div class="md:text-center">
        <PvButton
          @click="closeDialog"
          class="p-button-outlined p-icon-secondary"
          :style="{ width: '158px' }"
          :label="lang.cancel"
        >
          <!-- <span class="w-full">{{ this.lang.cancel }}</span> -->
        </PvButton>
        <PvButton @click="confirmSave()" :style="{ width: '158px' }" :label="lang.buttonDialogConfirm">
          <!-- <span class="w-full">{{ this.lang.saveCaption }}</span> -->
        </PvButton>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import BaseProductAutoComplete from "../../../components/master/unitProduct/BaseProductAutoComplete.vue";
export default {
  props: ["openFrom", "id", "unitId", "selectedUnitProduct"],
  // props: {
  //   selectedUnitProduct: {
  //       type: Array,
  //       default: () => []
  //   },
  //   openFrom: {
  //     type: String,
  //   },
  //   id: {
  //     type: String,
  //   },
  // },
  emits: ["close"],
  components: {
    BaseProductAutoComplete,
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.unitProduct,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.unitProduct,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
    };
  },
  data() {
    return {
      ProductCode: null,
      selectProductReady: false,
      Price: 0,
      Owner: [
        { name: "ไม่ใช่", code: "N" },
        { name: "ใช่", code: "Y" },
      ],
      selectedOwner: "N",
      Remark: "",

      showConfirmDialog: false,
      wordingDialog: "",
      showConfirmDialogName: "",
      titleConfirmDialog: "",

      isProductCodeError: false,
      isPriceError: false,
      isOwnerError: false,
    };
  },
  async created() {},
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
    },
    initiateData() {
      this.ProductCode = "";
      this.Price = 0;
      // this.selectedOwner = "";
      this.Remark = "";
    },

    setReady() {
      if (this.selectedUnitProduct && this.Products.length > 0) {
        this.setData();
      }
    },

    setData() {
      // console.log("this.selectedUnitProduct=", this.selectedUnitProduct);
      if (this.selectedUnitProduct) {
        //this.ProductCode = this.selectedUnitProduct.productId;
        this.Price = this.selectedUnitProduct.price
          ? this.selectedUnitProduct.price
          : 0;
        this.selectedOwner = this.selectedUnitProduct.isCurrentOwnerPaid
          ? this.selectedUnitProduct.isCurrentOwnerPaid
          : "";
        this.Remark = this.selectedUnitProduct.remark;

        // console.log("this.Products=", this.Products);

        this.ProductCode = this.Products.find(
          (data) => data.id === this.selectedUnitProduct.productId
        );
      }
    },
    productReady() {
      this.selectProductReady = true;
    },
    confirmSave() {
      this.titleConfirmDialog = this.lang.buttonDialogConfirm;
      this.wordingDialog = `${this.lang.askconfirmData.replace(
        ":title",
        this.title
      )}`;
      this.showConfirmDialog = true;
    },

    confirm() {
      if (this.id === "create") this.SaveData();
      else this.EditData();
      this.showConfirmDialog = false;
    },
    close() {
      this.showConfirmDialog = false;
    },
    async SaveData() {
      try {
        if (this.validateData()) {
          return;
        }

        const payload = {
          roomHID: this.unitId,
          productId: this.ProductCode.id,
          price: this.Price,
          isCurrentOwnerPaid: this.selectedOwner,
          remark: this.Remark,
        };
        //console.log("payload=", payload);
        await this.$store.dispatch("masterUnitProduct/AddUnitProduct", payload);
        this.$toast.add({
          severity: "success", // severity:'success',severity:'info',severity:'warn',severity:'error'
          //summary: this.lang.saveCaption, //TitleHeader
          detail: this.lang.saveSuccess,
          life: 5000, //TimeClose
        });
        this.$emit("close");
      } catch (error) {
        this.$toast.add({
          severity: "error", // severity:'success',severity:'info',severity:'warn',severity:'error'
          summary: this.lang.notificationDialogHeader, //TitleHeader
          detail: error,
          life: 5000, //TimeClose
        });
      }
    },
    async EditData() {
      try {
        if (this.validateData()) {
          return;
        }

        const payload = {
          id: this.selectedUnitProduct.id,
          roomHID: this.unitId,
          productId: this.ProductCode.id,
          price: this.Price,
          isCurrentOwnerPaid: this.selectedOwner,
          remark: this.Remark,
        };
        // console.log("payload=", payload);
        await this.$store.dispatch(
          "masterUnitProduct/EditUnitProduct",
          payload
        );
        this.$toast.add({
          severity: "success", // severity:'success',severity:'info',severity:'warn',severity:'error'
          // summary: this.lang.editCaption, //TitleHeader
          detail: this.lang.saveEditSuccess,
          life: 5000, //TimeClose
        });
        this.$emit("close");
      } catch (error) {
        this.$toast.add({
          severity: "error", // severity:'success',severity:'info',severity:'warn',severity:'error'
          summary: this.lang.notificationDialogHeader, //TitleHeader
          detail: error,
          life: 5000, //TimeClose
        });
      }
    },
    validateData() {
      // console.log("this.ProductCode=",this.ProductCode);
      if (!this.ProductCode) {
        this.isProductCodeError = true;
        return true;
      }

      if (this.Price < 0) {
        this.isPriceError = true;
        return true;
      }

      if (!this.selectedOwner) {
        this.isOwnerError = true;
        return true;
      }
      
      return false;
    },
    changeProductCode() {
      this.isProductCodeError = false;
    },
    keyupPrice(){
      this.isPriceError = false;
    },
    changeOwner(){
      this.isOwnerError = false;
    },
  },
  computed: {
    title() {
      let result = this.lang.editUnitProduct;
      if (this.id == "create") {
        result = this.lang.addUnitProduct;
      }
      return result;
    },
    Products() {
      let itemList = [];
      let productArs = this.$store.getters["shared/productArProducts"];
      if (productArs) {
        productArs.forEach((element) => {
          //   console.log("productArs", element);
          if (element.type === "N") {
            element = {
              ...element,
              ...{ codeAndName: element.code + " : " + element.nameThai },
            };
            itemList.push(element);
          }
        });
      }
      return itemList;
    },
  },
  watch: {
    openFrom() {
      // console.log("this.id=",this.id);
      if (this.id == "create") {
        this.initiateData();
      } else {
        this.setReady();
      }
    },
    selectProductReady() {
      this.setReady();
    },
  },
};
</script>

<style scoped>
.p-dialog .p-dialog-header {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

/* .p-dropdown .p-dropdown-trigger {
  background: #1c70f7;
  color: #eaffff;
} */
</style>
