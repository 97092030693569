<template>
    <div class="custom-image-box mt-1" :style="cssVars">
      <div class="flex justify-content-between align-items-start p-0 m-0">
        <PvImage
          :src="imageUrl"
          alt="Image"
          :width="imageObject.width"
          :height="imageObject.height"
          :style="{ maxWidth: '100%', maxHeight: '100%' }"
          preview
        />
        <PvButton
          type="button"
          class="p-button-outlined p-button-secondary p-0 ml-1"
          :class="{ 'height': '24px', 'width': '24px' }"
          @click="deleteImage"
          v-if="!hideDeleteButton"
        >
          <div>
            <icon-trash-delete-bin iconColor="secondary" width="16" height="16" />
          </div>
        </PvButton>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    emits: ["delete-image"],
    props: {
      imageUrl: {
        type: String,
        default:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzh4XErLLBeH-GP_XP7xMDaN3D0PCoZR__zQ&usqp=CAU",
      },
      cardObject: {
        type: Object,
        default() {
          return {
            width: "152",
            height: "70",
          };
        },
      },
      imageObject: {
        type: Object,
        default() {
          return {
            width: 96,
            height: 54,
          };
        },
      },
      hideDeleteButton : {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      deleteImage(){
        this.$emit("delete-image");
      }
    },
    computed: {
      cssVars() {
        return {
          "--card-width": this.cardObject.width - this.hideButtonWidth + "px",
          "--card-height": this.cardObject.height + "px",
        };
      },
      hideButtonWidth(){
        if(this.hideDeleteButton){
          return 42;
        }
        return 0;
      }
    },
  };
  </script>
  
  <style scoped>
  .custom-image-box {
    width: var(--card-width);
    height: var(--card-height);
    padding: 5px;
    border-radius: var(--border-radius);
    border: 1px solid #ced0d6;
    overflow: hidden;
  }
  </style>
  