export default {
	general: "ทั่วไป",
	adjust: "ปรับปรุง",
	closePeriod: "ปิดงวด",
	posting: "ระงับเอกสาร",
	nonPosting: "ไม่ระงับเอกสาร",
	createAccountingEntry: "สร้างใบบันทึกบัญชี",
	createReceiptToGl: "สร้างใบเสร็จมาลงบัญชี",
	createInvoiceToGl: "สร้างใบแจ้งหนี้มาลงบัญชี",
	editAccountingEntry: "แก้ไขใบบันทึกบัญชี",
	editReceiptToGl: "แก้ไขใบเสร็จมาลงบัญชี",
	editInvoiceToGl: "แก้ไขใบแจ้งหนี้มาลงบัญชี",
	accountingFrequently: "รายการบัญชีใช้บ่อย",
	total: "จำนวนเงิน",
	validateDetailSelect: "กรุณาเลือกรายการ",
	accountingItem: "รายการบัญชี",
	askForAccountingChange: "คุณยืนยันที่จะแก้ไขรหัสบัญชีใช่หรือไม่?",
	askForSelectAccounting: "ไม่พบรหัสบัญชี : :code ต้องการค้นหาหรือไม่?",
	importDocument: "นำเข้าเอกสาร",
	removeDocument: "นำเอกสารออกทั้งหมด",
	getDocument: "เอกสารประกอบ (F3)",
	invoiceItem: "รายการใบแจ้งหนี้",
	codeReference: "เลขที่อ้างอิง",
	unitCode: "ยูนิต",
	unitAddress: "บ้านเลขที่",
	customerCode: "รหัสลูกค้า",
	customerName: "ชื่อลูกค้า",
	customerSearch: "รหัสหรือชื่อลูกค้า",
	searchUnit: "ค้นหายูนิต",
	receiptTitle: "ใบเสร็จมาลงบัญชี",
	listReceiptTitle: "รายการใบเสร็จมาลงบัญชี",
	payType: "ประเภทชำระ",
	compound: "Compound Cash Epay",
	meterToGl: "นำมิเตอร์มาลงบัญชี",
	revert: "กลับรายการบัญชี",
	validateInvoiceToGl: "ไม่มีการผูกบัญชีกับการตั้งหนี้",
	meterToGlItem: "รายการมิเตอร์น้ำ/ไฟมาลงบัญชี",
	meterToGlTitle: "มิเตอร์น้ำ/ไฟมาลงบัญชี",
	meterWater: "นำเข้าน้ำประปาลงบัญชี",
	meterElectric: "นำเข้าไฟฟ้าลงบัญชี",
	voucherCode: "เลขที่บัญชี",
	waterVoucher: "ค่าน้ำประปาของงวดเดือนนี้ลงบัญชีไปแล้ว",
	electricVoucher: "ค่าไฟฟ้าของงวดเดือนนี้ลงบัญชีไปแล้ว",
	meterAlertAccountingFrequently: "ไม่พบรายการบัญชีใช้บ่อย",
	periodStart: "งวดเริ่มต้น",
	periodEnd: "งวดสิ้นสุด",
	unVoucherOnly: "เฉพาะที่ยังไม่ลงบัญชี",
	autoGl: "ลงบัญชีอัตโนมัติ",
	validateTotalAmount: "จำนวนเงินรวมเดบิต และเครดิตไม่เท่ากัน",
	notApprove: "ไม่อนุมัติ",
	validateTotalAmount2: "จำนวนเงินรวมเดบิต และเครดิตต้องมากกว่า 0",
	reasonNonApprove: "เหตุผลไม่อนุมัติ",
	createPettyCashToGl: "บันทึกรายการเงินสดย่อย",
	editPettyCashToGl: "แก้ไขรายการเงินสดย่อย",
	pettyCashPeriod: "ครั้งที่",
	validatePettyCashPeriod: "เอกสารนี้ยังไม่ได้ทำเรื่องเบิกคืน ไม่สามารถทำอนุมัติได้",
	cancelApprovePettyCash: "เลขที่นี้ทำการ อนุมัติคืนเงินสดย่อย ไปแล้ว",
	withholdingTax: "ภาษีหัก ณ ที่จ่าย",
	validateCancel: "ไม่สามารถยกเลิกเอกสารนี้ได้ เนื่องจากมีเอกสารอ้างถึง",
	validateNonApprove: "ระบุสาเหตุไม่อนุมัติ",
	validateApprovePermission: "คุณไม่มีสิทธิ์อนุมัติเอกสาร",

	payinPayoutBtn: "รายการจ่าย",
	validateTotalAmount3: "ไม่มีจำนวนเงินเดบิต หรือเครดิต",

	validateInvoiceCheckGlBook:
		"เล่มบัญชีที่เลือก :code ไม่ใช่เล่มที่ผูกไว้กับเล่มเอกสารแจ้งหนี้ (SJ) หากต้องการใช้เล่มที่ระบุ กรุณาติดต่อเจ้าหน้าที่",
	validateReceiptCheckGlBook:
		"เล่มบัญชีที่เลือก :code ไม่ใช่เล่มที่ผูกไว้กับเล่มเอกสารใบเสร็จ (RV) หากต้องการใช้เล่มที่ระบุ กรุณาติดต่อเจ้าหน้าที่",
	validateBillAcceptanceCheckGlBook:
		"เล่มบัญชีที่เลือก :code ไม่ใช่เล่มที่ผูกไว้กับเล่มเอกสารใบวางบิล (PJ) หากต้องการใช้เล่มที่ระบุ กรุณาติดต่อเจ้าหน้าที่",

	validatePurchaseReceiptCheckGlBook:
		"เล่มบัญชีที่เลือก :code ไม่ใช่เล่มที่ผูกไว้กับเล่มเอกสารใบวางบิล (PV) หากต้องการใช้เล่มที่ระบุ กรุณาติดต่อเจ้าหน้าที่",

	titleImportBillAcceptance: "นำเข้า ใบรับสินค้า/ใบรับวางบิล",
	documentBookCode: "เล่มเอกสาร",
	documentBookName: "ชื่อเอกสาร",
	bookCode: "เล่มที่",
	btnConfirm: "ยืนยันที่เลือก",

	titleImportPurchaseReceipt: "นำเข้าเอกสารประกอบ",
	deleteDocumentAll: "นำออกหรือยกเลิกความสัมพันธ์ทั้งหมด",
	receiptToGl: "Receipt To GL",
	insertRow: "แทรกแถว (F10)",
	removeRow: "ลบแถว (F11)",
	createNewDocument: "สร้างเอกสารใหม่ (F2)",
	saveDocument: "บันทึก (F8)",
	downloadPdf: "ดาวน์โหลด PDF (F6)",
	difference: "ผลต่าง (F4)",
	validateTotalAmount4: "มีรายการที่มีจำนวนเงินเดบิต หรือเครดิต แต่ไม่ได้ระบุรหัสบัญชี",
	validateDescriptionLength: "รายการลำดับที่ :number <br>รายละเอียดต้องไม่เกิน 500 ตัวอักษร",
	titleImportPurchaseInvoice: "นำเข้าเอกสารประกอบ",
	activeLockStatus: "เอกสารถูกล็อกอยู่",
	activeUnlockStatus: "เอกสารไม่ถูกล็อก",
	switchDebitCredit: "สลับ Dr/Cr",
	titleRevert: "กลับรายการบัญชี",
	validateSaveAndGotoRevert: "โปรดบันทึกเอกสาร<br>ก่อนกลับรายการบัญชี",
	validateRevertAccountingIsNotReady: "ระบบกำลังประมวลผล",
	validateRevertAccountingNoDetail: "ไม่พบรายการ",
};
