export default {
    async getPrepaids(context, payload) {

        let url = process.env.VUE_APP_BACKEND_URL + "/PrepaidRefund";

        if(!payload.limit){
            url = url + '?limit=10';
        } else {
            url = url + '?limit='+payload.limit;
        }

        if(payload.page){
            url = url + '&page='+payload.page;
        }

        if(payload.where){
            url = url + '&where=' + JSON.stringify(payload.where);
        }
        
        const token = context.rootGetters.token;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
          },
        });
    
        const responseData = await response.json();
    
        if (!response.ok) {
          // console.log(responseData);
          const error = new Error(responseData.message || "Failed to get prepaid refund.");
          throw error;
        }
        // console.log("Prepaid_responseData:",responseData);
    
        // const data = responseData.data;
        context.commit('setPrepaid', responseData);
       
    },    
    async getPrepaid(context, payload) {
      let url = process.env.VUE_APP_BACKEND_URL + "/PrepaidRefund?Page=1&Limit=10";

      if(payload.where){
          url = url + '&where=' + JSON.stringify(payload.where);
      }
      // console.log("getPrepaid_URL>>>",url);
      const token = context.rootGetters.token;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token,
        },
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        // console.log(responseData);
        const error = new Error(responseData.message || "Failed to get prepaid refund.");
        throw error;
      }
      // console.log("getPrepaid_responseData>>>",responseData);  
      // console.log("getPrepaid>>>",responseData.data.length);
      // console.log("getPrepaidresponseData.data[0]>>>",responseData.data[0]);
      if(responseData.data.length > 0){
        context.commit('setSelectedPrepaid', responseData.data[0]);
      }   
          
    },
    async getPrepaidRefundReceipts(context, payload) {
      let url = process.env.VUE_APP_BACKEND_URL + "/PrepaidRefund/Receipt";

      if(!payload.limit){
        url = url + '?limit=10';
      } else {
        url = url + '?limit='+payload.limit;
      }

      if(payload.page){
        url = url + '&page='+payload.page;
      }

      if(payload.where){
        url = url + '&where=' + JSON.stringify(payload.where);
      }

      const token = context.rootGetters.token;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
          },
        });
        const responseData = await response.json();
    
        if (!response.ok) {
          // console.log(responseData);
          const error = new Error(responseData.message || "Failed to get prepaids.");
          throw error;
        }
        // console.log("Prepaid_Receipt_responseData:",responseData);
    
        context.commit('setPrepaidReceipt', responseData);
      
    },
    async AddPrepaid(context, payload) {
      let url = process.env.VUE_APP_BACKEND_URL + "/PrepaidRefund/Add";
      
      const token = context.rootGetters.token;      
      //  console.log("payload=",payload);

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(payload)
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        // console.log("AddPrepaid_responseData_Error>>>",responseData);
        const error = new Error(responseData.message || "Failed to add prepaid refund");
        throw error;
      }
      //console.log("AddPrepaid_responseData>>>",responseData);            
      await context.commit('setSelectedPrepaid', responseData.data);       
          
    },
    async EditPrepaid(context, payload) {
      let url = process.env.VUE_APP_BACKEND_URL + "/PrepaidRefund/Update";
      
      const token = context.rootGetters.token;
      
      // console.log("payload=",payload);

      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(payload)
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        // console.log("EditPrepaid_responseData_Error>>>",responseData);
        const error = new Error(responseData.message || "Failed to update prepaid refund");
        throw error;
      }
      // console.log("EditPrepaid_responseData>>>",responseData);
      // console.log("responseData.data.length>>>",responseData.data.length);  
      
      context.commit('setSelectedPrepaid', responseData.data);         
          
    },
    async CancelPrepaid(context, payload) {
      let url = process.env.VUE_APP_BACKEND_URL + "/PrepaidRefund/Cancel";
      
      const token = context.rootGetters.token;
      
      // console.log("CancelPrepaid_payload=",payload);

      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(payload)
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        // console.log("CancelPrepaid_responseData_Error>>>",responseData);
        const error = new Error(responseData.message || "Failed to cancel prepaid refund.");
        throw error;
      }
      // console.log("CancelPrepaid_responseData>>>",responseData);
  
      context.commit('setSelectedPrepaid', responseData.data);
      
          
    },
    async PrintPrepaid(context, payload) {
      let url = process.env.VUE_APP_BACKEND_URL + "/PrepaidRefund/Print";
      
      const token = context.rootGetters.token;
      
      // console.log("PrintPrepaid_payload=",payload);
      // console.log("PrintPrepaid_payloadtoken=",token);

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(payload),
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        
      });  
       
      // var urlBlob = ''
      // await response.blob().then(function(blob) {
      //   urlBlob = window.URL.createObjectURL(blob);
      //   context.commit('setBlob', urlBlob);
      //   // window.open(url);
      //   // window.location.assign(url);
      //   // var a = document.createElement('a');
      //   // a.href = url;
      //   // const d = new Date();
      //   // let dateText = d.toISOString();
      //   // a.download = `PrepaidRefund_${dateText}.pdf`;
      //   // document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      //   // a.click();
      //   // a.remove(); //afterwards we remove the element again
      // });

      let urlBlob = ''
      let blob = await response.blob();
      urlBlob = window.URL.createObjectURL(blob);
      context.commit('setBlob', urlBlob);
      // console.log("PrintPrepaid_blob>>>",urlBlob);

      // const responseData = await response.json();      
  
      // if (!response.ok) {
      //   console.log("PrintPrepaid_responseData_Error>>>",responseData);
      //   const error = new Error(responseData.message || "Failed to Print Prepaid");
      //   throw error;
      // }
      // console.log("PrintPrepaid_responseData>>>",response.blob());
    },
   

};