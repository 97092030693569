export default {
    detailTitle: "Detail Room/House",
    detailOwnerTitle: "Detail Room/House Owner",
    detailOwnerDescription: "You can saved up to 3 owner.",
    toolbarCreateUnit: "Add Room/House",
    toolbarEditUnit: "Edit Room/House",
    generalInformation: "General Information",
    code: "Room/House Code",
    validateCode: "Enter room/house code",
    addressNo: "Address No.",
    validateAddressNo: "Enter Address No.",
    building: "Building",
    validateBuilding: "Enter building",
    floor: "Floor",
    validateFloor: "Enter floor",
    roomType: "Room type",
    roomType1: "เช่าพื้นที่ส่วนกลาง/ร้านค้า",
    roomType2: "ยังไม่โอน",
    roomType3: "โอนแล้ว-ปล่อยเช่า",
    roomType4: "โอนแล้ว-ยังไม่เข้าพักอาศัย",
    roomType5: "โอนแล้ว-อยู่เอง",
    validateRoomType: "select Room type",
    room: "Room",
    validateRoom: "Enter room",
    contractNo: "Contract No",
    validateContractNo: "Enter Contract No",
    QRPayment: "QR Payment",
    ratio: "Ratio",
    validateRatio: "Enter Ratio",
    area: "Area",
    validateArea: "Enter Area",
    cooling: "Cooling (ton)",
    validateCooling: "Enter cooling (ton)",
    activeStatus: "Active Status",
    memberInformation: "Member Information",
    ownerInformation: "Owner Information",
    residentInformation: "Rental/Resident Information",
    allResident: "All Resident",
    owner: "Owner",
    owner1: "Owner 1",
    owner2: "Owner 2",
    owner3: "Owner 3",
    resident: "Resident",
    residentNo : "Resident",
    resident1 : "Resident 1",
    resident2 : "Resident 2",
    resident3 : "Resident 3",
    rental: "Rental",
    rentalNo: "Rental",
    rental1: "Rental 1",
    rental2: "Rental 2",
    rental3: "Rental 3",
    roomOwner: "Room Owner",
    transferDate: "Transfer Date",
    livingDate: "Living Date",
    appointmentDate: "Appointment Date",
    acceptDate: "Accept Date",
    warrantyEndDate: "Warranty End Date",
    validateDate: "Select Date",
    living: "living",
    living1: "Stay",
    living2: "Rental",
    living3: "Rental Common Area",
    receiptAddress: "Receipt Address",
    validateReceiptAddress: "Enter Receipt Address",
    stayDate: "Stay Date",
    moveDate: "Move Date",
    unitDocument: "Documents for the room :code",
    addMember: "Add Member",
    addOwner: "Add Owner",
    addResident: "Add Resident",
    addRental: "Add Rental",
    addNewResident: "Add New Resident",
    name: "Name",
    livingType: "Living Type",
    settingUnitProduct: "Setting Unit Product",
    residentType: "Resident Type*",
    createUnitSuccess: "Create Unit success",
    editUnitSuccess: "Edit Unit success",
    unit: "Unit",
    dialogValidateParamsProjectId: "The project id is empty.", //error_code: 103
    dialogValidateParamsUnitId: "The unit id is empty.", //error_code: 106
    dialogValidateParamsCode: "The unit code is empty.", //error_code: 104
    dialogValidateParamsAddressNo: "The unit address number is empty.", //error_code: 105
    dialogValidateProjectIdNotFound: "The project id doesn't exist.", //error_code: 101
    dialogValidateUnitIdNotFound: "The unit id doesn't exist.", //error_code: 102
    dialogValidateInsertRole: "You don't have permission to create unit.", // error_code: 111
    dialogValidateUpdateRole: "You don't have permission to update unit.", // error_code: 112
    dialogValidateDuplicateCode: "The unit code is duplicate.", // error_code: 120
    dialogValidateDuplicateAddressNo: "The unit address number is duplicate.", // error_code: 121
    validateAddressNoLimit: "Address No. no more than 25 digits.",
    validateAddressMoreThanLimit: "you entered text more than 25 characters.",
    status: "Status",
    activeStatusResident: "Active",
    inactiveStatusResident: "Inactive",
    validateResidentDuplicate: "Rental/Resident : :name has already living in this room.",
}