export default {
  async getStockKeycards(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/StockKeycard";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.projectId) {
			url = url + "&projectId=" + payload.projectId;
		}
	
		if (payload.status) {
			url = url + "&status=" + payload.status;
		}
	
		if (payload.dateStart) {
			url = url + "&dateStart=" + payload.dateStart;
		}
	
		if (payload.dateEnd) {
			url = url + "&dateEnd=" + payload.dateEnd;
		}

		if (payload.productCode) {
			url = url + "&productCode=" + payload.productCode;
		}

		if (payload.unitCode) {
			url = url + "&unitCode=" + payload.unitCode;
		}

		if (payload.unitAddressNo) {
			url = url + "&unitAddressNo=" + payload.unitAddressNo;
		}
    
		if (payload.code) {
			url = url + "&code=" + payload.code;
		}

		if (payload.serial) {
			url = url + "&serial=" + payload.serial;
		}
    
		if (payload.receiptCode) {
			url = url + "&receiptCode=" + payload.receiptCode;
		}
    
		if (payload.sortBy) {
			url = url + "&sortBy=" + payload.sortBy;
		}

		url = encodeURI(url);
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
        // console.log(responseData);
        const error = new Error(responseData.message || "Failed to get stock keycard");
        throw error;
    }

    context.commit("setStockKeycards", responseData);
  },
  async getStockKeycardById(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + '/StockKeycard/' + payload;
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
        // console.log(responseData);
        const error = new Error(responseData.message || `Failed to get stock keycard id : ${payload}`);
        throw error;
    }

    context.commit("setSelectedStockKeycard", responseData.data);
  },
  resetStockKeycard(context) {
    context.commit("setSelectedStockKeycard", null);
  },
  async createStockKeycard(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + "/StockKeycard/Add";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw responseData;
    }
    // console.log(responseData);

    context.commit("setSelectedStockKeycard", responseData.data);
  },
  async updateStockKeycard(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + "/StockKeycard/Update";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw responseData;
    }
    // console.log(responseData);

    context.commit("setSelectedStockKeycard", responseData.data);
  },
};
