<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 16.6181C4.146 16.1091 5.454 16.1091 6.6 16.6181C7.746 17.1271 9.054 17.1271 10.2 16.6181C11.346 16.1091 12.654 16.1091 13.8 16.6181C14.946 17.1271 16.254 17.1271 17.4 16.6181C18.546 16.1091 19.854 16.1091 21 16.6181"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 8H16"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 12H16"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 14V4.5C16 3.672 16.672 3 17.5 3V3C18.328 3 19 3.672 19 4.5"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 14V4.5C8 3.672 8.672 3 9.5 3V3C10.328 3 11 3.672 11 4.5"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3 20.6181C4.146 20.1091 5.454 20.1091 6.6 20.6181C7.746 21.1271 9.054 21.1271 10.2 20.6181C11.346 20.1091 12.654 20.1091 13.8 20.6181C14.946 21.1271 16.254 21.1271 17.4 20.6181C18.546 20.1091 19.854 20.1091 21 20.6181"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 14,
    },
    height: {
      type: [Number, String],
      default: 14,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -4px !important;
  margin-right: 4px;
}
</style>
