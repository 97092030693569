<template>
  <base-container>
    <base-gl-book-modal
      v-model:visible="showGlBookModal"
      :search="searchText"
      :isSharedLoading="isSharedGlBookReady"
      @close="showGlBookModal = false"
      @glBook-selected="glBookModalSelected"
    ></base-gl-book-modal>
    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchTextPrint"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>
    <print-lot-dialog
      v-model:visible="showPrintLotDialog"
      @close="showPrintLotDialog = false"
      :glBookId="glBookId"
      :glBook="selectedGlBook"
    >
    </print-lot-dialog>
    <div
      class="flex justify-content-between align-items-center"
      style="padding-top: 20px"
    >
      <div class="my-3">
        <PvButton
          icon="pi pi-chevron-left"
          :label="lang.back"
          @click="goBack"
          class="mr-2 p-button-secondary"
          v-if="isHideBack"
        />
      </div>
      <h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
    </div>
    <div class="surface-card shadow-2 p-3 border-round">
      <div class="pt-2 pb-1">
        <PvButton
          icon="pi pi-file"
          :label="lang.create"
          @click="gotoEdit('create')"
          class="mr-2 mb-2"
        />
        <PvButton
          icon="pi pi-list"
          :label="parameterButtonText"
          @click="toggleParameterVisible"
          class="p-button-help mr-2 mb-2"
        />
        <PvButton
          icon="pi pi-replay"
          :label="lang.changeGlBook"
          @click="changeGlBook"
          class="mr-2 mb-2"
        />
        <PvButton
          icon="pi pi-print"
          :label="lang.printLot"
          @click="openPrintLotDialog"
          class="mr-2 mb-2"
        />
      </div>
      <transition>
        <div v-if="isParameterVisible">
          <section class="flex flex-wrap" :class="textFormClass">
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
              <label for="dateStart">{{ lang.dateStart }}</label>
              <PvCalendar
                id="dateStart"
                v-model="dateStart"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
              <label for="dateEnd">{{ lang.dateEnd }}</label>
              <PvCalendar
                id="dateEnd"
                v-model="dateEnd"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
              <label for="codeStart">{{ lang.codeStart }}</label>
              <PvInputText
                id="codeStart"
                type="text"
                class="inputfield w-full mt-1"
                placeholder="6501xxxxx"
                v-model="codeStart"
              />
            </div>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
              <label for="codeEnd">{{ lang.codeEnd }}</label>
              <PvInputText
                id="codeEnd"
                type="text"
                class="inputfield w-full mt-1"
                placeholder="6501xxxxx"
                v-model="codeEnd"
              />
            </div>
            <!-- <div class="pt-5 pb-1"> -->
            <PvButton
              icon="pi pi-search"
              :label="lang.search"
              @click="getAccountingEntries"
              class="mr-2 mb-2"
            />
            <!-- </div> -->
          </section>
        </div>
      </transition>
      <div class="p-datatable-border my-2">
        <PvDataTable
          showGridlines
          :value="accountingEntries"
          v-model:selection="selectedAccountingEntry"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          :stripedRows="true"
          class="p-datatable-sm"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(this.selectedAccountingEntry.id)"
          scrollable
        >
          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
          <PvColumn
            field="cancelStatus"
            :header="lang.columnIsCancel"
            style="min-width: 8rem"
            headerClass="h-center"
            bodyClass="text-center"
            sortable
          >
            <template #body="slotProps">
              <base-tag
                :Caption="lang.cancel"
                Class="error"
                v-if="slotProps.data.cancelStatus == 'C'"
              />
            </template>
          </PvColumn>
          <PvColumn
            field="code"
            :header="lang.columnCode"
            style="min-width: 15rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>
          <PvColumn
            field="date"
            :header="lang.columnDate"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.date) }}
            </template></PvColumn
          >
          <PvColumn
            field="period"
            :header="lang.columnPeriod"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>

          <PvColumn
            field="description"
            :header="lang.description"
            style="min-width: 20rem"
            bodyClass="text-left"
            headerClass="h-left"
            sortable
          >
          </PvColumn>

          <PvColumn
            field="totalAmount"
            :header="lang.columnTotal"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-right"
            sortable
          >
            <template #body="slotProps">
              <span class="text-right w-full">
                {{ formatCurrency(slotProps.data.totalAmount) }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            field="approveStatus"
            :header="lang.columnApprove"
            style="min-width: 4rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>
          <PvColumn
            field="createBy"
            :header="lang.createBy"
            style="min-width: 15rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>
          <PvColumn
            field="createDate"
            :header="lang.createDate"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.createDate) }}
            </template>
          </PvColumn>
          <PvColumn
            field="updateBy"
            :header="lang.updateBy"
            style="min-width: 15rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>
          <PvColumn
            field="updateDate"
            :header="lang.updateDate"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.updateDate) }}
            </template>
          </PvColumn>
          <PvColumn style="min-width: 10rem" alignFrozen="right" frozen>
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0"
                icon="pi pi-pencil"
                @click="gotoEdit(slotProps.data.id)"
              ></PvButton>
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0"
                icon="pi pi-print"
                @click="selectPrintForm(slotProps.data)"
              ></PvButton>
            </template>
          </PvColumn>
          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              :first="showCurrentPage"
            >
              <template #start="slotProps">
                {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{
                  totalPage
                }}
              </template>
            </PvPaginator>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import useGetGlBook from "../../../hooks/getGlBook.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import PrintLotDialog from "../../../components/GL/PrintLotDialog.vue";
import BaseDialog from "../../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import accountDetailWithoutParent from "../../../hooks/accountDetailWithoutParent.js";
export default {
  components: {
    PrintLotDialog,
  },
  props: ["glBook", "idBook", "id", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { isSharedGlBookReady, searchText, SetBookCode } = useGetGlBook();
    const {
      getFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints,
    } = useGetFormPrint();
    const { checkAccountDetailNoParent} = accountDetailWithoutParent();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.gl.accounting.listing };
      } else {
        return { ...lang.en.base, ...lang.en.gl.accounting.listing };
      }
    });

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      isSharedGlBookReady,
      searchText,
      SetBookCode,

      getFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints,

      isHideBack,
      checkAccountDetailNoParent,
    };
  },
  data() {
    return {
      isParameterVisible: true,
      isLoading: false,
      dateStart: null,
      dateEnd: null,
      codeStart: "",
      codeEnd: "",
      glBookId: null,
      selectedGlBook: null,
      selectedAccountingEntry: null,
      showGlBookModal: true,
      selectedPage: 1,
      rowsPerPage: 10,

      showFormPrintModal: false,
      path: "",
      dataPrint: [],
      categoryPrint: "",
      bookModalSelected: null,

      showPrintLotDialog: false,
      storageName: "",
      dataConfigDialog: {},
    };
  },
  async mounted() {
    let date = new Date();
    this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
    this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.storageName = `erp_search_accountingEntryList_${this.selectedProject.code}_${this.userCode}`;

    const localSearch = localStorage.getItem(this.storageName);
    if (localSearch) {
      let search = JSON.parse(localSearch);
      if (search.editAccountingEntry === "Edit") {
        if (search.dateStart != null && search.dateStart != "") {
          this.dateStart = new Date(search.dateStart);
        } else this.dateStart = "";

        if (search.dateEnd != null && search.dateEnd != "") {
          this.dateEnd = new Date(search.dateEnd);
        } else this.dateEnd = "";

        this.codeStart = search.codeStart || this.codeStart;
        this.codeEnd = search.codeEnd || this.codeEnd;

        this.rowsPerPage = search.rowsPerPage || this.rowsPerPage;
        this.selectedPage = search.selectedPage || this.selectedPage;

        search.editAccountingEntry = "";

        localStorage.setItem(this.storageName, JSON.stringify(search));
      }

      this.selectedGlBook = this.glBook;
      this.glBookId = this.idBook;
      this.bookModalSelected = search.bookModalSelected;
    }

    // console.log("this.$route.fullPath=",this.$route.fullPath)
    // console.log("selectedPath=",this.selectedPath)
    // console.log("this.glBook=",this.glBook)
    // console.log("this.selectedGlBook=",this.selectedGlBook)
    if (this.GetPathGlReceipt) {
      this.SetBookCode("!PC");
    } else if (this.GetPathGlInvoice) {
      this.SetBookCode("!PC");
    } else {
      this.SetBookCode("!PC");
    }

    if (this.glBook != null) {
      this.showGlBookModal = false;
      await this.getAccountingEntries();
    } else {
      this.showGlBookModal = true;
    }

    // await this.getAccountingEntries();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$store.dispatch("glAccountingEntry/resetAccountingEntryListing");
      this.$router.push({name: "home"})
    },
    async gotoEdit(accountingId) {
      // console.log("this.selectedGlBook",);
      let result = await this.checkAccountDetailNoParent();
      if(!result){

      if (this.GetPathGlAccounting)
        this.$router.push({
          name: "gl-accounting-edit",
          params: {
            glBook: this.selectedGlBook,
            idBook: this.glBookId,
            id: accountingId,
          }
        });
      if (this.GetPathGlReceipt)
        this.$router.push({
          name: "gl-receipt-edit",
          params: {
            glBook: this.selectedGlBook,
            idBook: this.glBookId,
            id: accountingId,
          }
        });
      if (this.GetPathGlInvoice)
        this.$router.push({
          name: "gl-invoice-edit",
          params: {
            glBook: this.selectedGlBook,
            idBook: this.glBookId,
            id: accountingId,
          }
        });
      }
      // this.$router.push(`/gl/accounting/${this.glBook}/${accountingId}`);  //Take 0//05/2022
    },
    toggleParameterVisible() {
      this.isParameterVisible = !this.isParameterVisible;
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getAccountingEntries();
    },
    async getAccountingEntries() {
      this.isLoading = true;

      // console.log("this.glBook=", this.glBook);
      // console.log("this.selectedGlBook=", this.selectedGlBook);

      let where = {
        projectId: this.selectedProject.id,
        dateStart: this.selectedDateStart,
        dateEnd: this.selectedDateEnd,
        codeStart: this.codeStart || null,
        codeEnd: this.codeEnd || null,
        generalLedgerBookCode: this.selectedGlBook,
        generalLedgerBookId: this.glBookId,
      };

      // console.log("where", where);

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };

      // console.log("payload", payload);

      await this.$store.dispatch("glAccountingEntry/getAccountingEntries", payload);

      this.isLoading = false;

      where = {
        ...where,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        generalLedgerBookCode: this.selectedGlBook,
        bookModalSelected: this.bookModalSelected,
        rowsPerPage: this.rowsPerPage,
        selectedPage: this.selectedPage,
        // generalLedgerBookCode: this.glBook,  //Take 09/05/2022
      };

      localStorage.setItem(this.storageName, JSON.stringify(where));
    },
    changeGlBook() {
      this.SetBookCode("!PC");
      this.showGlBookModal = true;
    },
    glBookModalSelected(data) {
      this.bookModalSelected = data;
      // console.log(data);
      // this.$router.push(`/gl/accounting/${data.generalLedgerBookCode}`); //Take 09/05/2022
      if (this.GetPathGlAccounting)
        this.$router.push({
          name: "gl-accounting-book",
          params: {
            glBook: data.generalLedgerBookCode,
            idBook: data.generalLedgerBookId,
          }
        });
      if (this.GetPathGlReceipt)
        this.$router.push({
          name: "gl-receipt-book",
          params: {
            glBook: data.generalLedgerBookCode,
            idBook: data.generalLedgerBookId,
          }
        });
      if (this.GetPathGlInvoice)
        this.$router.push({
          name: "gl-invoice-book",
          params: {
            glBook: data.generalLedgerBookCode,
            idBook: data.generalLedgerBookId,
          }
        });
      this.selectedGlBook = data.generalLedgerBookCode;
      this.glBookId = data.generalLedgerBookId;
      this.getAccountingEntries();
      this.showGlBookModal = false;
    },
    async selectPrintForm(data) {
      this.dataPrint = data;
      let category = this.selectedGlBook;
      // const localSearch = localStorage.getItem(this.storageName);
      // if (localSearch) {
      //   let search = JSON.parse(localSearch);
      //   category = search.generalLedgerBookCode;
      // }
      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: category,
      };
      const payload = { where };
      await this.getFormPrints(payload);

      if (this.filteredFormPrints.length == 1) {
        this.path = this.filteredFormPrints[0].path;
        this.PrintAccounting();
      } else {
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path;
      this.categoryPrint = event.category;
      this.PrintAccounting();
      this.showFormPrintModal = false;
    },
    async PrintAccounting() {
      this.setLoading(true);
      let category = this.glBookId;
      // const localSearch = localStorage.getItem(this.storageName);
      // if (localSearch) {
      //   let search = JSON.parse(localSearch);
      //   category = search.generalLedgerBookId;
      // }
      let printType = "";
      if (this.GetPathGlInvoice) {
        printType = "invoice";
      } else if (this.GetPathGlReceipt) {
        printType = "receipt";
      }
      const Prepaid = {
        id: this.dataPrint.id,
        projectId: this.selectedProject.id,
        Type: printType,
        path: this.path,
        GlbId: category,
      };

      // console.log("PrintVoucher=", Prepaid);
      try {
        await this.$store.dispatch("glAccountingEntry/PrintVoucher", Prepaid);
        this.isPrinted = true;
      } catch (error) {
        // console.log("error=", error);
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        this.isPrinted = false;
        this.setLoading(false);
      }
      let blob = this.$store.getters["glAccountingEntry/blob"];
      // console.log('blob',blob);
      this.getPreviewBlob(blob);

      this.setLoading(false);
    },
    openPrintLotDialog() {
      // console.log("openPrintLotDialog");
      this.showPrintLotDialog = true;
    },
    async askForUpdateLockStatus(data) {
      let postStep = data.postStep.trim();
      let dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "cancel",
        },
        {
          id: "updateLockStatus",
          caption: this.lang.buttonDialogConfirm,
          class: "w-full",
          action: "confirm",
        },
      ];

      let dataConfig = {
        title: postStep === "P" ? this.lang.titleUnlockDialog : this.lang.titleLockDialog,
        message:
          postStep === "P"
            ? this.lang.messageUnlockDialog.split("<br>")
            : this.lang.messageLockDialog.split("<br>"),
        classTitle: "",
        button: dataButtonDialog,
      };

      await this.setDialog(dataConfig);
      const dialogResponse = await this.dialogConfirm.reveal();
      if (!dialogResponse.isCanceled) {
        this.updateLockStatus(data);
      }
    },
    async updateLockStatus(data) {
      this.setLoading(true);
      if (this.bookModalSelected.approve.trim() !== "Y") {
        this.dialogError(this.lang.validateRoleApprove);
        this.setLoading(false);
        return;
      }
      try {
        let postStep = data.postStep.trim();
        let payload = {
          id: data.id,
          status: postStep === "P" ? "A" : "P",
        };
        let response = await this.$store.dispatch(
          "glAccountingEntry/updateLockStatus",
          payload
        );
        if (response) {
          await this.setDialog({
            title:
              postStep === "P"
                ? this.lang.unlockSuccessTitle
                : this.lang.lockSuccessTitle,
            message: postStep === "P" ? this.lang.unlockSuccess : this.lang.lockSuccess,
            classTitle: "success",
            button: null,
          });
          this.setLoading(false);
          await this.getAccountingEntries();
          await this.dialogConfirm.reveal();
        }
      } catch (error) {
        await this.setDialog({
          title: this.lang.notificationDialogHeader,
          message: error,
          classTitle: "error",
          button: null,
        });
        this.setLoading(false);
        await this.dialogConfirm.reveal();
      }
    },
    async setDialog(dataConfig) {
      if (!dataConfig.button) {
        dataConfig.button = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "cancel",
          },
        ];
      }

      if (dataConfig.classTitle === "") {
        dataConfig.classTitle = "";
      } else if (dataConfig.classTitle === "success") {
        dataConfig.classTitle = "p-text-success";
      } else if (dataConfig.classTitle === "error") {
        dataConfig.classTitle = "p-error";
      }

      this.dataConfigDialog = {
        titleConfirm: dataConfig.title,
        classTitle: dataConfig.classTitle,
        wordingConfirm: dataConfig.message,
        dataButtons: dataConfig.button,
        display: true,
      };
    },
  },
  computed: {
    title() {
      let titleBook = "";
      if (this.glBook) titleBook = " : " + this.selectedGlBook;
      let result = this.lang.accountingTitle + titleBook;
      if (this.GetPathGlAccounting) {
        result = this.lang.accountingTitle + titleBook;
      } else if (this.GetPathGlReceipt) {
        result = this.lang.receiptTitle + titleBook;
      } else if (this.GetPathGlInvoice) {
        result = this.lang.invoiceTitle + titleBook;
      }
      return result;
    },
    parameterButtonText() {
      return this.isParameterVisible
        ? this.lang.hideParameters
        : this.lang.showParameters;
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    accountingEntries() {
      return this.$store.getters["glAccountingEntry/accountingEntries"];
    },
    totalPage() {
      return this.$store.getters["glAccountingEntry/totalPage"];
    },
    currentPage() {
      return this.$store.getters["glAccountingEntry/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    GetPathGlAccounting() {
      const accountingPath = this.$route.fullPath.indexOf("gl/accounting");
      let accounting = true;
      if (accountingPath < 0) accounting = false;
      return accounting;
    },
    GetPathGlReceipt() {
      const receiptPath = this.$route.fullPath.indexOf("gl/receipt");
      let receipt = true;
      if (receiptPath < 0) receipt = false;
      return receipt;
    },
    GetPathGlInvoice() {
      const invoicePath = this.$route.fullPath.indexOf("gl/invoice");
      let invoice = true;
      if (invoicePath < 0) invoice = false;
      return invoice;
    },
    showCurrentPage() {
      let page = 0;
      page = this.rowsPerPage * (this.selectedPage - 1);
      return page;
    },
    isUnolockButton() {
      if (this.bookModalSelected) {
        if (
          this.bookModalSelected.isInvoice === "Y" ||
          this.bookModalSelected.isReceipt === "Y"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    dialogConfirm() {
      return createConfirmDialog(BaseDialog, this.dataConfigDialog);
    },
  },
  watch: {
    // GetPathGlAccounting(curValue) {
    //   // console.log("0011GetPathGlAccounting_curValue=",curValue);
    //   // console.log("0011GetPathGlAccounting_oldValue=",oldValue);
    //   if (curValue === true) {
    //     this.SetBookCode("!PC");
    //     this.showGlBookModal = true;
    //   }
    // },
    // GetPathGlReceipt(curValue) {
    //   // console.log("0022GetPathReceiptGl_curValue=",curValue);
    //   if (curValue === true) {
    //     this.SetBookCode("!PC");
    //     this.showGlBookModal = true;
    //   }
    // },
    // GetPathGlInvoice(curValue) {
    //   //  console.log("0033GetPathInvoiceGl_curValue=",curValue);
    //   if (curValue === true) {
    //     this.SetBookCode("!PC");
    //     this.showGlBookModal = true;
    //   }
    // },
    glBook(curValue) {
      if (curValue == null) {
        this.$store.dispatch("glAccountingEntry/resetAccountingEntryListing");
        this.showGlBookModal = true;
      }
    },
  },
};
</script>
