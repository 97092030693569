export default {
	glBooks(state) {
		return state.glBooks;
	},
	// totalPage(state) {
	// 	return state.totalPage;
	// },
	// currentPage(state) {
	// 	return state.currentPage;
	// },
	// rows(state) {
	// 	return state.rows;
	// },
};
