<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '70vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="titleHeaderImportData"
      :wordingConfirm="titleValidateImportData"
      :dataButtons="dataButtonDialog"
      @close="showValidateDialog = false"
    ></base-dialog>

    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">{{ lang.uploadFile }}</label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          :disabled="shouldDisableCloseButton"
          @click="closeDialog"
        />
      </div>
    </template>

    <div class="dropZone">
      <input
        type="file"
        id="dropZone-file"
        class="dropZone-file"
        ref="dropZoneFile"
        @click="resetFile"
        @change="handleFiles"
        multiple
        accept=".xlsx"
      />
      <div
        class="dropZone-wrapper"
        @dragenter.prevent=""
        @dragover.prevent=""
        @drop.prevent="handleFiles"
      >
        <label for="dropZone-file" class="cursor-pointer">
          <div class="pt-6"></div>
          <PvButton
            class="cursor-pointer p-button-rounded show-icon"
            disabled="disabled"
          >
            <icon-cloud-storage-upload iconColor="white" />
          </PvButton>
          <div class="cursor-pointer dropZone-label-text pt-4">
            <label
              for="dragFile"
              class="cursor-pointer font-md p-text-secondary"
              >{{ lang.dragFile }}</label
            >
            <label
              for="addFile"
              class="cursor-pointer font-md p-text-primary"
              @click="addFile"
              >{{ lang.addFile }}</label
            >
            <input
              id="add-file"
              type="file"
              style="display: none"
              accept=".xlsx"
              @click="resetFile"
              @change="handleFiles"
              class="cursor-pointer"
            />
          </div>
          <div class="cursor-pointer font-xs p-text-secondary pt-2 pb-8">
            <label for="importFileDesc" class="cursor-pointer">{{
              lang.importFileDesc
            }}</label>
            <label
              for="downloadfile"
              class="cursor-pointer p-text-primary"
              @click="downloadfile"
              >{{ lang.downloadfile }}</label
            >
          </div>
        </label>
      </div>
    </div>

    <div class="my-5 pt-6">
      <PvDataTable
        :value="files"
        dataKey="id"
        responsiveLayout="scroll"
        class="p-datatable-xs"
        :class="textTableClass"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn style="width: 87px; height: 56px" headerClass="h-center">
          <template #body>
            <PvButton
              v-if="isOpen"
              type="button"
              style="background: white; border: white; opacity: 1"
              disabled="disabled"
            >
              <icon-documents-file-checkmark iconColor="secondary" />
            </PvButton>
          </template>
        </PvColumn>

        <PvColumn
          field="detailName"
          :header="lang.fileName"
          style="min-width: 12rem"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-left font-normal p-text-primary"
        >
        </PvColumn>

        <PvColumn
          style="width: 2rem"
          headerClass="h-center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="">
            <PvButton
              v-if="isOpen"
              type="button"
              class="p-button-danger"
              style="background: white; border: white"
              @click="deleteFile"
            >
              <icon-trash-delete-bin iconColor="error" />
            </PvButton>
          </template>
        </PvColumn>
      </PvDataTable>
    </div>

    <div class="flex justify-content-center pt-4">
      <PvButton class="w-3" @click="uploadFile" :disabled="checkDisable">
        <span class="w-full text-center font-md font-normal p-button-label">
          <i
            v-if="isUpLoading"
            class="pi pi-spin pi-spinner font-normal mr-1"
          ></i>
          {{ lang.uploadFile }}
        </span>
      </PvButton>
    </div>
    <template #footer></template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import useImportExcel from "../../../hooks/importExcel.js";
import useGetImportExistUnit from "../../../hooks/getImportExistUnit.js";
import useGetBookBankAr from "../../../hooks/getBookBankAr.js";
export default {
  emits: ["close", "data-upload"],
  setup(props, context) {
    const { lang } = useLang();
    const { textTableClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.deposit.edit,
          ...lang.th.ar.deposit.import,
          ...lang.th.validateImport,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.deposit.edit,
          ...lang.en.ar.deposit.import,
          ...lang.en.validateImport,
        };
      }
    });

    const router = useRouter();

    const { 
      validateFileCount, 
      validateFileType, 
      readFileContent 
    } = useImportExcel();
    const {
      setImportExistUnitParams,
      loadImportExistUnit,
      getImportExistUnit,
    } = useGetImportExistUnit();
    const { 
      bookBankAr, 
      isSharedBookBankArReady,
      getAllBookBankArs,
    } = useGetBookBankAr();

    //params
    let files = ref([]);
    let isOpen = ref(false);
    let file = ref(null);
    let rawDatas = ref([]);
    let isUpLoading = ref(false);
    let uploadFiles = ref([]);
    let titleHeaderImportData = ref("");
    let titleValidateImportData = ref("");
    let showValidateDialog = ref(false);
    let importUnitByCodes = ref([]);
    let payTypes = ref(["เงินสด","เช็ค","เงินโอน"]);
    let condition = ref([
      {
        name: "date",
        type: "date",
        wordLength: 25,
        isRequire: true,
      },
      {
        name: "unitCode",
        type: "string",
        wordLength: 50,
        isRequire: true,
      },
      {
        name: "payAmount",
        type: "decimal",
        isRequire: true,
      },
      {
        name: "payType",
        type: "string",
        isRequire: true,
      },
      {
        name: "bookBankCode",
        type: "string",
        isRequire: true,
      },
      {
        name: "transferDate",
        type: "date",
        wordLength: 25,
      },
      {
        name: "docBankBranchName",
        type: "string",
        wordLength: 100,
      },
      {
        name: "depositor",
        type: "string",
        wordLength: 100,
      },
      {
        name: "remark",
        type: "string",
        wordLength: 300,
      },
    ]);

    //computeds
    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const shouldDisableCloseButton = computed(() => {
      return isUpLoading.value;
    });
    const checkDisable = computed(() => {
      if (!file.value || isUpLoading.value) {
        return true;
      }
      return false;
    });
    const dataButtonDialog = computed(() => {
      let result = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
      return result;
    });

    //methods
    const setLoading = (bool) => {
      isUpLoading.value = bool;
    };

    const initiateData = () => {
      files.value = [];
      isOpen.value = false;
      file.value = null;
      rawDatas.value = [];
      uploadFiles.value = [];
      titleHeaderImportData.value = mergeLang.value.notificationDialogHeader;
      titleValidateImportData.value = "";
      importUnitByCodes = [];
    };

    const closeDialog = () => {
      context.emit("close");
      initiateData();
    };

    const resetFile = (event) => {
      if (event.target.files.length > 0) {
        event.target.value = null;
      }
    };

    const addFile = () => {
      document.getElementById("add-file").click();
    };

    const deleteFile = () => {
      initiateData();
    };

    const handleFiles = async (event) => {
      const inputValue = event.target.files || event.dataTransfer.files;
      uploadFiles.value = Array.from(inputValue);

      const checkFileCount = validateFileCount(uploadFiles.value, 1);
      if (checkFileCount.isError) {
        titleHeaderImportData.value = mergeLang.value.validateLimitFile.replace(
          ":number",
          1
        );
        titleValidateImportData.value =
          mergeLang.value.validateFileType.replace(
            ":name",
            checkFileCount.message
          );
        showValidateDialog.value = true;
        return;
      }

      file.value = inputValue[0];
      const checkFileType = validateFileType(file.value);
      if (checkFileType.isError) {
        titleHeaderImportData.value = mergeLang.value.validateImportFile;
        titleValidateImportData.value =
          mergeLang.value.validateFileType.replace(
            ":name",
            checkFileType.message
          );
        showValidateDialog.value = true;
        file.value = null;
        return;
      }

      isOpen.value = true;
      const fileName = file.value.name;
      files.value = [
        {
          detailName: fileName,
        },
      ];
    };

    const downloadfile = async () => {
      setLoading(true);
      await getImportDepositForm();
      setLoading(false);
    };

    const getImportDepositForm = async () => {
      try {
        let payload = {
          key: process.env.VUE_APP_IMPORT_DEPOSIT_TEMPLATE_S3_KEY,
          name: "template_import_deposit.xlsx",
        };
        await store.dispatch("upload/downloadFile", payload);
      } catch (error) {
        titleHeaderImportData.value = mergeLang.value.notificationDialogHeader;
        titleValidateImportData.value = error;
        showValidateDialog.value = true;
        setLoading(false);
      }
    };

    const uploadFile = async () => {
      setLoading(true);
      if (file.value) {
        const result = await readFileContent(
          file.value,
          2,
          30000,
          condition.value
        );
        if (!result.success) {
          if (result.code == "001") {
            titleHeaderImportData.value =
              mergeLang.value.notificationDialogHeader;
            titleValidateImportData.value =
              mergeLang.value.validateImportNoData.replace(
                ":file",
                file.value.name
              );
          } else if (result.code == "002") {
            titleHeaderImportData.value =
              mergeLang.value.notificationDialogHeader;
            titleValidateImportData.value =
              mergeLang.value.validateImportMaxColumn.replace(
                ":maxColumn",
                "30,000"
              );
          } else {
            titleHeaderImportData.value =
              mergeLang.value.notificationDialogHeader;
            titleValidateImportData.value = result.message;
          }
          setLoading(false);
          showValidateDialog.value = true;
          return true;
        }
        rawDatas.value = result.data;
        validateAndPrepareData();
      } else {
        titleHeaderImportData.value = mergeLang.value.notificationDialogHeader;
        titleValidateImportData.value = mergeLang.value.validateAddfile;
        showValidateDialog.value = true;
        return true;
      }
    };

    const validateAndPrepareData = async () => {
      if (!isSharedBookBankArReady.value) {
        await getAllBookBankArs();
      }
      await getUnitImportByCode();
      await prepareData();
    };

    const prepareData = async () => {
      let dataResult = [];
      let i = 0;
      for (const data of rawDatas.value) {
        let result = await mappingData(data);
        if (result) {
          i++;
          result = { ...result, id: i, itemNo: i };
          dataResult.push(result);
        }
      }

      // console.log("dataResult", dataResult);

      setLoading(false);
      await store.dispatch("importDeposit/importDeposits", dataResult);
      context.emit("data-upload");
      router.push({ name: "preview-deposit" });
      initiateData();
    };

    const mappingData = async (data) => {
      // console.log("mapingData", data);
      data.importStatusCode = "wait";
      data.importStatusReason = "";
      data.importStatusDescription = "";
      data.markStatus = "";

      //เช็คข้อมูล unit
      if (data.unitCode) 
      {
        let unitData = importUnitByCodes.value.find(unit => unit.code == data.unitCode);
        if(!unitData){ //ไม่พบ unitCode ใน DB  
          let errorObj = {
            field: "unitCode",
            type: "doNotExist",
            data: data.unitCode,
          }; 
          data.error.push(errorObj);
        } else {
          data.unitId = unitData.id;
          data.customerId = unitData.customerId;
        }
      }
      //เช็คข้อมูล ประเภทการชำระ
      if (data.payType) 
      {
        if (!payTypes.value.includes(data.payType)) 
        {
          let errorObj = {
            field: "payType",
            type: "doNotExist",
            data: data.payType,
          }; 
          data.error.push(errorObj);
        } else {
          data.paymentMethodCode = data.payType == "เงินสด"?"CAD": data.payType == "เช็ค"?"CQD":"TRD";
        }
      }
      //เช็คข้อมูล bookBank
      if (data.bookBankCode) {
        let bookBankData = bookBankAr.value.filter(
          (bookBank) => bookBank.code.trim() == data.bookBankCode.trim()
        )[0];

        if (!bookBankData) {
          let error = {
            field: "bookBankCode",
            type: "doNotExist",
            data: data.bookBankCode,
          };
          data.error.push(error);
        } else {
          data.bookBankId = bookBankData.id;
          if (!data.docBankBranchName && data.payType == "เงินโอน") {
            data.docBankBranchName = bookBankData.branchName
          }
        }
      }

      if (data.error.length > 0) {
        data.importStatusCode = "invalid";
        data.importStatusDescription = data.error[0].field;
        let errorType = data.error[0].type;

        switch (data.error[0].type) {
          case "blank":
            errorType = mergeLang.value.validateBlank.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
          case "duplicate":
            errorType = mergeLang.value.validateDuplicateInFile.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
          case "duplicateInDatabase":
            errorType = mergeLang.value.validateDuplicateInDatabase
              .replace(":field", setFieldLangError(data.error[0].field))
              .replace(":table", mergeLang.value.deposit);
            break;
          case "overflow":
            errorType = mergeLang.value.validateLength
              .replace(":field", setFieldLangError(data.error[0].field))
              .replace(":length", data.error[0].wordLength);
            break;
          case "doNotExist":
            errorType = mergeLang.value.validateDataNotFound.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
          case "invalid":
            errorType = mergeLang.value.validateType.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
          case "invalidDecimal":
            errorType = mergeLang.value.validateDecimalFormat.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
          case "format":
            errorType = mergeLang.value.validateDateFormat.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
          case "incompleteData":
            errorType = mergeLang.value.validateIncompleteData.replace(
              ":field",
              setFieldLangError(data.error[0].field)
            );
            break;
        }
        data.importStatusReason = errorType;
      }

      data = {
        ...data,
        projectId: selectedProject.value.id,
        transferDate: data.transferDate?data.transferDate:new Date(),
        type: "Add",
      };
      // console.log(data);
      return data;
    };
    
    const getUnitImportByCode = async () => {
      setImportExistUnitParams(rawDatas.value,"unitCode");
      await loadImportExistUnit("unitCode");
      importUnitByCodes.value = getImportExistUnit();
    };

    const setFieldLangError = (field) => {
      switch (field) {
        case "date":
          return mergeLang.value.depositDate;
        case "unitCode":
          return mergeLang.value.unitCode;
        case "payAmount":
          return mergeLang.value.depositAmount;
        case "payType":
          return mergeLang.value.depositType;
        case "bookBankCode":
          return mergeLang.value.bookBankCode;
        case "transferDate":
          return mergeLang.value.transferDate;
        case "docBankBranchName":
          return mergeLang.value.bank;
        case "depositor":
          return mergeLang.value.transferCode;
        case "remark":
          return mergeLang.value.remark;
        default:
          return field;
      }
    };

    return {
      lang: mergeLang,
      textTableClass,

      files,
      isOpen,
      file,
      isUpLoading,
      showValidateDialog,
      titleHeaderImportData,
      titleValidateImportData,

      //computeds
      shouldDisableCloseButton,
      checkDisable,
      dataButtonDialog,

      //methods
      closeDialog,
      uploadFile,
      resetFile,
      handleFiles,
      addFile,
      deleteFile,
      downloadfile,
    };
  },
};
</script>

<style scoped>
.show-icon {
  opacity: 1;
  width: 48px;
  height: 48px;
  padding: 0.75rem;
}
</style>
