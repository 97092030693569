<template>
  <base-toolbar :title="lang.toolbarTitle" @goBack="goBack"></base-toolbar>
  <base-progress-bar
    v-model:visible="showProgressBar"
    :title="lang.creatingStockKeycard"
    :textProgressBar="lang.generating"
    :valueObj="valueObj"
    :progressBar="`Template`"
  ></base-progress-bar>

  <import-unit-resident-dialog
    v-model:visible="showUploadUnitResidentDialog"
    @close="showUploadUnitResidentDialog = false"
    @data-upload="uploadedUnitResident"
  ></import-unit-resident-dialog>

  <base-container>
    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5" :class="textFormClass">
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.title }}</label>

        <div class="flex">
          <PvButton class="p-button-outlined ml-3" @click="exportErrorExcel">
            <i class="pi pi-file-excel" style="width: 16px; height: 16px"></i>
            <span class="p-button-label font-normal ml-1">{{ lang.listCannotImport }}</span>
          </PvButton>

          <PvButton class="p-button-outlined ml-3" @click="askForReUpload">
            <icon-cloud-storage-upload iconColor="primary" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{ lang.reupload }}</span>
          </PvButton>

          <PvButton class="ml-3" @click="importUnitResident">
            <icon-documents-file-checkmark iconColor="white" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{ labelImportAll }}</span>
          </PvButton>
        </div>
      </div>

      <PvDivider></PvDivider>

      <div class="flex flex-wrap justify-content-left">
        <div class="lg:col-2 md:col-6 col-12 ml-1 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.allStatus"
            :content="contentAllStatus"
            :color="'primary'"
            :isClick="allStatusClick"
            @click="clickFilter('all')"
          ></base-select-card>
        </div>
        <div class="lg:col-2 md:col-6 col-12 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.wait"
            :content="contentWaitStatus"
            :color="'warning'"
            :isClick="waitStatusClick"
            @click="clickFilter('wait')"
          ></base-select-card>
        </div>
        <div class="lg:col-2 md:col-6 col-12 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.invalid"
            :content="contentInvalidStatus"
            :color="'secondary'"
            :isClick="invalidStatusClick"
            @click="clickFilter('invalid')"
          ></base-select-card>
        </div>
        <div class="lg:col-2 md:col-6 col-12 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.error"
            :content="contentErrorStatus"
            :color="'error'"
            :isClick="errorStatusClick"
            @click="clickFilter('error')"
          ></base-select-card>
        </div>
        <div class="lg:col-2 md:col-6 col-12 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.success"
            :content="contentSuccessStatus"
            :color="'success'"
            :isClick="successStatusClick"
            @click="clickFilter('success')"
          ></base-select-card>
        </div>
      </div>
      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="previewDatas"
          :paginator="true"
          class="p-datatable-sm"
          :class="textTableClass"
          :rows="rowsPerPage"
          dataKey="id"
          :rowHover="true"
          :loading="isLoading"
          paginatorTemplate="CurrentPageReport    FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 50, 100]"
          :currentPageReportTemplate="lang.page + ` {currentPage} / {totalPages}`"
          responsiveLayout="scroll"
        >
          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
          <PvColumn
            field="itemNo"
            :header="lang.itemNo"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="importStatusCode"
            :header="lang.statusImport"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <base-tag
                :Caption="setBaseTagStatusImport(slotProps.data.importStatusCode).caption"
                :Class="setBaseTagStatusImport(slotProps.data.importStatusCode).class"
              />
            </template>
          </PvColumn>
          <PvColumn
            field="importStatusReason"
            :header="lang.reason"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="unitCode"
            :header="lang.unitCode"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.importStatusDescription === 'unitCode' }">
                {{ slotProps.data.unitCode }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            field="customerCode"
            :header="lang.customerCode"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.importStatusDescription === 'customerCode' }">
                {{ slotProps.data.customerCode }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            field="residentType"
            :header="lang.livingType"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.importStatusDescription === 'residentType' }">
                {{ slotProps.data.residentType }}
              </span>
            </template>
          </PvColumn>
          <!-- <PvColumn
            field="appointmentDate"
            :header="lang.appointmentDate"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.importStatusDescription === 'appointmentDate' }">
                {{
                  slotProps.data.importStatusDescription === "appointmentDate" || slotProps.data.appointmentDate === ""
                    ? slotProps.data.appointmentDate
                    : formatDate(slotProps.data.appointmentDate)
                }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            field="transferDate"
            :header="lang.transferDate"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.importStatusDescription === 'transferDate' }">
                {{
                  slotProps.data.importStatusDescription === "transferDate" || slotProps.data.transferDate === ""
                    ? slotProps.data.transferDate
                    : formatDate(slotProps.data.transferDate)
                }}
              </span>
            </template>
          </PvColumn> -->
          <PvColumn
            field="stayDate"
            :header="lang.stayDate"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.importStatusDescription === 'stayDate' }">
                {{
                  slotProps.data.importStatusDescription === "stayDate" || slotProps.data.stayDate === ""
                    ? slotProps.data.stayDate
                    : formatDate(slotProps.data.stayDate, false)
                }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            field="moveDate"
            :header="lang.moveDate"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': isStatusError(slotProps.data,'moveDate') }">
                {{
                  isStatusError(slotProps.data,'moveDate') || slotProps.data.moveDate === ""
                    ? slotProps.data.moveDate
                    : formatDate(slotProps.data.moveDate, false)
                }}
              </span>
            </template>
          </PvColumn>
          <!-- <PvColumn
            field="living"
            :header="lang.living"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
          </PvColumn> -->
          <!-- <PvColumn
            field="warrantyEndDate"
            :header="lang.warrantyEndDate"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.importStatusDescription === 'warrantyEndDate' }">
                {{
                  slotProps.data.importStatusDescription === "warrantyEndDate" || slotProps.data.warrantyEndDate === ""
                    ? slotProps.data.warrantyEndDate
                    : formatDate(slotProps.data.warrantyEndDate)
                }}
              </span>
            </template>
          </PvColumn> -->
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>
<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import BaseDialog from "../../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import ImportUnitResidentDialog from "../../../components/master/unit/ImportUnitResidentDialog.vue";
import useExportExcel from "../../../hooks/exportExcel.js";
export default {
  components: {
    ImportUnitResidentDialog,
  },
  props: ["projectCode"],
  setup() {
    //use
    const router = useRouter();
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const { getRoleByMenuByCode } = useGetRoleByMenu();
    const { generateFile } = useExportExcel();

    //variable
    //Table
    let isLoading = ref(false);
    let selectedPage = ref(1);
    let rowsPerPage = ref(10);
    //base-select-card
    let allStatusClick = ref(true);
    let waitStatusClick = ref(false);
    let invalidStatusClick = ref(false);
    let errorStatusClick = ref(false);
    let successStatusClick = ref(false);
    //Dialog
    let dataConfigDialog = ref(null);
    let showUploadUnitResidentDialog = ref(false);
    //ProgressBar
    let showProgressBar = ref(false);
    let valueObj = ref([]);

    //computed
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.unit.import,
          ...lang.th.master.unit.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.unit.import,
          ...lang.en.master.unit.edit,
        };
      }
    });
    const roleByMenu = computed(() => {
      return getRoleByMenuByCode("tmCOR");
    });
    const isRoleUpdate = computed(() => {
      return roleByMenu.value && roleByMenu.value.update === "Y";
    });
    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const unitResidentDatas = computed(() => {
      return store.getters["importUnitResident/importUnitResidents"];
    });

    const lengthUnitResident = computed(() => {
      return unitResidentDatas.value.length;
    });

    const filterDataByStatus = (status) => {
      if (lengthUnitResident.value > 0) {
        return unitResidentDatas.value.filter((data) => data.importStatusCode === status);
      }
      return [];
    };

    const dataWaitStatus = computed(() => filterDataByStatus("wait"));
    const dataInvalidStatus = computed(() => filterDataByStatus("invalid"));
    const dataErrorStatus = computed(() => filterDataByStatus("error"));
    const dataSuccessStatus = computed(() => filterDataByStatus("success"));
    const dataExportError = computed(() => [...dataInvalidStatus.value, ...dataErrorStatus.value]);

    const labelImportAll = computed(() => {
      let countData = 0;
      if (lengthUnitResident.value > 0) {
        countData = dataWaitStatus.value.length;
      }
      return mergeLang.value.importAll.replace(":value", countData);
    });
    const contentAllStatus = computed(() => mergeLang.value.statusContent.replace(":number", lengthUnitResident.value));
    const contentWaitStatus = computed(() =>
      mergeLang.value.statusContent.replace(":number", dataWaitStatus.value.length)
    );
    const contentInvalidStatus = computed(() =>
      mergeLang.value.statusContent.replace(":number", dataInvalidStatus.value.length)
    );
    const contentErrorStatus = computed(() =>
      mergeLang.value.statusContent.replace(":number", dataErrorStatus.value.length)
    );
    const contentSuccessStatus = computed(() =>
      mergeLang.value.statusContent.replace(":number", dataSuccessStatus.value.length)
    );
    const previewDatas = computed(() => {
      switch (true) {
        case waitStatusClick.value:
          return dataWaitStatus.value;
        case invalidStatusClick.value:
          return dataInvalidStatus.value;
        case errorStatusClick.value:
          return dataErrorStatus.value;
        case successStatusClick.value:
          return dataSuccessStatus.value;
        default:
          return unitResidentDatas.value;
      }
    });

    //function
    const initiateData = async () => {
      selectedPage.value = 1;
      rowsPerPage.value = 10;
      allStatusClick.value = true;
      waitStatusClick.value = false;
      invalidStatusClick.value = false;
      errorStatusClick.value = false;
      successStatusClick.value = false;
      dataConfigDialog.value = null;
      showProgressBar.value = false;
      valueObj.value = [];
    };
    const goBack = () => {
      router.push({ name: "unit" });
    };
    const clickFilter = (status) => {
      allStatusClick.value = false;
      waitStatusClick.value = false;
      invalidStatusClick.value = false;
      errorStatusClick.value = false;
      successStatusClick.value = false;

      if (status == "all") {
        allStatusClick.value = true;
      } else if (status == "wait") {
        waitStatusClick.value = true;
      } else if (status == "invalid") {
        invalidStatusClick.value = true;
      } else if (status == "error") {
        errorStatusClick.value = true;
      } else if (status == "success") {
        successStatusClick.value = true;
      }

      selectedPage.value = 1;
      rowsPerPage.value = 10;
    };
    const setDialog = (dataConfig) => {
      const classMap = {
        success: "p-text-success",
        error: "p-error",
      };

      dataConfig.classTitle = classMap[dataConfig.classTitle] || "";

      dataConfig.button = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "cancel",
        },
      ];

      if (dataConfig.type === "confirm") {
        dataConfig.button.push({
          id: "confirm",
          caption: mergeLang.value.buttonDialogConfirm,
          class: "w-full",
          action: "confirm",
        });
      }

      dataConfigDialog.value = {
        titleConfirm: dataConfig.title,
        classTitle: dataConfig.classTitle,
        wordingConfirm: dataConfig.message,
        dataButtons: dataConfig.button,
        display: true,
      };
    };
    const askForReUpload = async () => {
      if (lengthUnitResident.value > 0) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.askForNewImport,
          type: "confirm",
        });
        const dialogResponse = await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        if (dialogResponse.isCanceled) {
          return;
        }
      }
      uploadUnitResident();
    };
    const validateData = () => {
      if (!isRoleUpdate.value) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.validateRoleUpdate,
          type: "",
        });
        return true;
      } else if (dataWaitStatus.value.length == 0) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.noItemToImportWording,
          type: "",
        });
        return true;
      }
      return false;
    };
    const importUnitResident = async () => {
      if (validateData()) {
        await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        return;
      }

      valueObj.value = [];
      valueObj.value = dataWaitStatus.value;
      showProgressBar.value = true;
      for (const data of dataWaitStatus.value) {
        await addUnitResident(data);
      }
      showProgressBar.value = false;

      setDialog({
        classTitle: "success",
        title: mergeLang.value.saveSuccess,
        message: mergeLang.value.saveSuccess,
        type: "",
      });
      await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();

      clickFilter("all");
    };
    const addUnitResident = async (data) => {
      try {
        // console.log("addUnitResident", data);
        const ownerTypes = ["เจ้าของที่ 2", "เจ้าของที่ 3"];
        const residentTypes = ["ผู้เช่า", "ผู้พักอาศัย"];

        if (ownerTypes.includes(data.residentType)) {
          let payload = {
            id: data.unitId,
            projectId: selectedProject.value.id,
            code: data.unitCode,
            ownerId2: data.residentType == "เจ้าของที่ 2" ? data.customerId : null,
            ownerId3: data.residentType == "เจ้าของที่ 3" ? data.customerId : null,
            type: "Update",
          };

          // console.log("owner" ,payload);
          await store.dispatch("unit/createOrUpdateUnit", payload);
        } else if (residentTypes.includes(data.residentType)) {
          let payload = {
            unitId: data.unitId,
            customerId: data.customerId,
            role: data.residentType == "ผู้เช่า" ? "Rential" : "Stay",
            startDate: data.stayDate,
            endDate: data.moveDate ? data.moveDate : data.stayDate,
            addBy: null,
            type: "AddResident",
          };
          // console.log("resident" ,payload);
          await store.dispatch("unitResident/createOrUpdateUnitResident", payload);
        }

        if (unitResidentDatas.value) {
          data.id = unitResidentDatas.value.id;
          data.importStatusCode = "success";
          data.markStatus = "1";
        }
        return true;
      } catch (error) {
        data.importStatusCode = "error";
        data.markStatus = "1";
        return false;
      }
    };
    const setBaseTagStatusImport = (status) => {
      switch (status.toLowerCase()) {
        case "wait":
          return {
            caption: mergeLang.value.wait,
            class: "warning",
          };
        case "success":
          return {
            caption: mergeLang.value.success,
            class: "success",
          };
        case "invalid":
          return {
            caption: mergeLang.value.invalid,
            class: "secondary",
          };
        case "error":
          return {
            caption: mergeLang.value.error,
            class: "error",
          };
        default:
          return {
            caption: "",
            class: "",
          };
      }
    };
    const handleBeforeUnload = async (event) => {
      if (dataWaitStatus.value.length > 0 || dataInvalidStatus.value.length > 0) {
        event.preventDefault();
      }
    };
    const uploadUnitResident = () => {
      showUploadUnitResidentDialog.value = true;
    };
    const uploadedUnitResident = () => {
      showUploadUnitResidentDialog.value = false;
    };

    const exportErrorExcel = async () => {
      if (dataExportError.value.length > 0) {
        const columnName = [
          {
            field: "unitCode",
            name: "เลขที่ยูนิต/ห้อง"
          },
          {
            field: "customerCode",
            name: "รหัสสมาชิก/ลูกค้า",
          },
          {
            field: "residentType",
            name: "ประเภทการอยู่อาศัย",
          },
          {
            field: "stayDate",
            name: "วันที่เข้าอยู่",
          },
          {
            field: "moveDate",
            name: "วันที่ย้ายออก",
          },
          {
            field: "error",
            name: "เหตุผล",
          },
        ];
        generateFile(dataExportError.value, columnName, "unit_resident_error", mergeLang.value.residentInformation, 2);
      } else {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.noItemToExportWording,
          type: "",
        });
        await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
      }
    };
    const isStatusError = (data, field) => {
      if(data && data.error){
        let checkError = data.error.find(e => e.field == field);
        if(checkError){
          return true;
        }
      }
      return false;
    };    

    onMounted(async () => {
      initiateData;
      window.addEventListener("beforeunload", handleBeforeUnload);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    });

    onBeforeRouteLeave(async (to, from) => {
      if (dataWaitStatus.value.length > 0 || dataInvalidStatus.value.length > 0) {
        var curValue = to.params.projectCode;
        var oldValue = from.params.projectCode;
        if (curValue == oldValue) {
          setDialog({
            classTitle: "",
            title: mergeLang.value.notificationDialogHeader,
            message: mergeLang.value.pageDirtyConfirmMessage,
            type: "confirm",
          });
          const dialogResponse = await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
          return !dialogResponse.isCanceled;
        }
      }
      return true;
    });

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatDate,
      formatDateToISOString,

      //variable
      isLoading,
      selectedPage,
      rowsPerPage,

      allStatusClick,
      waitStatusClick,
      invalidStatusClick,
      errorStatusClick,
      successStatusClick,

      showProgressBar,
      valueObj,
      showUploadUnitResidentDialog,
      isStatusError,

      //computeds
      labelImportAll,
      contentAllStatus,
      contentWaitStatus,
      contentInvalidStatus,
      contentErrorStatus,
      contentSuccessStatus,
      previewDatas,

      //method
      goBack,
      clickFilter,
      askForReUpload,
      importUnitResident,
      setBaseTagStatusImport,
      uploadedUnitResident,
      exportErrorExcel,
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-right: auto;
  }
}
</style>
