export default {
  uploadFile: "อัปโหลดไฟล์",
  dragFile: "ลากไฟล์มาวาง หรือ ",
  addFile: "เพิ่มไฟล์ใหม่",
  importFileDesc: "กรุณาเพิ่มไฟล์ทีละ 1 ไฟล์ที่มีที่มีนามสกุล .txt, .bpd และเป็นรูปแบบ Bill Payment BOT format (256 bytes)",
  fileName: "ชื่อเอกสาร",
  validateLimitFile: "สามารถอัปโหลดไฟล์ได้ทีละ 1 ไฟล์",
  validateFileType: "ไม่สามารถอัปโหลดไฟล์ :name ได้",
  validateImportFile: "สกุลไฟล์ที่อนุญาตมี .txt และ .bpd เท่านั้น",
  validateBOTFormat: "ท่านอัปโหลดไฟล์ที่ไม่ตรงรูปแบบตามมาตรฐานธนาคาร",
  pleaseReloadAgain: "กรุณาอัปโหลดใหม่อีกครั้ง",
  validateBookBank: "ไม่พบเลขที่บัญชีนี้ภายใต้โครงการที่ทำรายการ",
  pleaseTryAgain: "กรุณาตรวจสอบอีกครั้ง",

  title: "Bill Payment",
  wordingTitleFirst: "จาก :total รายการ ระบบพบข้อมูลที่ตรงกันและสามารถสร้างใบเสร็จได้ทันทีจำนวน",
  wordingTitleSecond: " :value รายการ ",
  wordingTitleThird: "ท่านสามารถกดเลือกปุ่ม สร้างใบเสร็จทั้งหมด ได้",
  noteUnderTitle: "สำหรับรายการที่สถานะ ค่าไม่ถูกต้อง จะทำได้แค่ สร้าง Manual เท่านั้น",
  listValidation: "ตรวจสอบรายการ",
  importAll: "นำเข้าทั้งหมด (:value)",

  statusAll: "สถานะทั้งหมด",
  wait: "รอสร้าง",
  success: "สร้างสำเร็จ",
  invalid: "ค่าไม่ถูกต้อง",
  error: "สร้างไม่สำเร็จ",
  
  reason: "เหตุผล",
  ref1: "บ้านเลขที่(Ref1)",
  ref2: "ใบแจ้งหนี้(Ref2)",
  amount: "จำนวนเงิน",
	paidBy: "ชื่อผู้ชำระ",
	paymentDate: "วันที่ชำระเงิน",
  openReceipt: "ดูใบเสร็จ",
  autoCreate: "สร้างทันที",
  manualCreate: "สร้างManual",
  retry: "ลองใหม่",
  invalidUnit: "บ้านเลขที่ไม่ตรง",
  invalidInvoice: "ใบแจ้งหนี้ไม่ตรง",
  invalidAmount: "ยอดเงินไม่ตรง",
  invalidDate: "รูปแบบวันที่ชำระเงินไม่ถูกต้อง",

  askForNextStep: "ท่านยืนยันที่จะดำเนินการต่อหรือไม่?",
  creatingReceipt: "กำลังสร้างใบเสร็จ",
  generating: "กำลังสร้าง...",
  createReceiptSuccess: "ท่านทำรายการสำเร็จ",
  paymentTime: "เวลาที่ชำระเงิน :time",
  wordingCreateReceiptAuto: "จาก :total รายการของท่านสามารถสร้างใบเสร็จได้เพียง :value รายการ เนื่องจากเป็นข้อมูลที่ตรงกัน",
  wordingCannotCreateReceiptAuto: "จาก :total รายการของท่านไม่สามารถสร้างใบเสร็จได้ เนื่องจากไม่มีข้อมูลที่ตรงกัน",
  askForDiscard: "ท่านยืนยันที่จะละทิ้งใช่หรือไม่",
  wordingDiscard: "หากท่านละทิ้งหน้านี้ รายการที่อัปโหลดก่อนหน้าจะถูกล้างค่าทิ้งทั้งหมด",
  askForNewImport: "ท่านต้องการอัปโหลดรายการ Bill payment ใหม่แทนที่ รายการเดิมใช่หรือไม่",
  wordingNewImport: "สำหรับรายการที่สร้างใบเสร็จสำเร็จไป จะถือว่าถูกบันทึกลงระบบแล้ว",
};
