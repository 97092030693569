export default {
    title: "จัดการงบประมาณ",
    createBudget: "สร้างงบประมาณ",
    columnName: "ชื่องบประมาณ",
    columnYear: "ปีงบประมาณ",
    columnPeriod: "ช่วงงบประมาณ",
    columnUpdateDate: "วันที่แก้ไขล่าสุด",
    columnUpdateBy: "ผู้แก้ไขล่าสุด",
    columnStatus: "สถานะ",

    statusWait: "รออนุมัติ",
    statusApprove: "อนุมัติ",
    statusCancel: "ยกเลิก",

    titleCreateDialog: "สร้างงบประมาณ",
    nameBudget: "ชื่องบประมาณ*",
    nameBudgetPlaceholder: "ระบุชื่องบประมาณ",
    validateNameBudget: "กรุณาระบุชื่องบประมาณ",

    yearBudget: "ปีงบประมาณ*",
    yearBudgetPlaceholder: "เลือกปีงบประมาณ",
    validateYearBudget: "กรุณาเลือกปีงบประมาณ",

    remarkBudget: "หมายเหตุ",
    remarkBudgetPlaceholder: "ระบุหมายเหตุ",

    periodBudgetFrom: "ช่วงงบประมาณ ตั้งแต่*",
    validatePeriodBudgetFrom: "กรุณาระบุช่วงงบประมาณ ตั้งแต่",

    periodBudgetTo: "ถึง*",
    validatePeriodBudgetTo: "กรุณาระบุช่วงงบประมาณ ถึง",

    budgetMethod: "วิธีการกรอกงบ",
    newBudgetMethod: "กำหนดเอง",
    copyBudgetMethod: "คัดลอกงบประมาณ",

    copyBudgetSelect: "ปีงบประมาณที่คัดลอก",
    validateBudgetDate: "กรุณาระบุช่วงงบประมาณที่ถูกต้อง โดยช่วงเวลาตั้งแต่ จะต้องมาก่อนหรือเท่ากับช่วงเวลาถึง",
};
