<template>
  <PvDialog
    :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
    :style="{ width: '40vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="lang.notificationDialogHeader"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="closeDialog()"
      @close="showValidateDialog = false"
    ></base-dialog>

    <template #header>
      <div class="flex justify-content-between align-items-right">
        <div class="pb-1">
          <label class="font-lg">{{ title }}</label>
        </div>
        <div>
          <PvButton
            icon="pi pi-times"
            class="p-button-link p-icon-secondary pb-0 pt-0"
            style="width: 15px"
            @click="closeDialog()"
          />
        </div>
      </div>

      <PvDivider></PvDivider>
    </template>

    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
          <label for="product">{{ lang.product }}</label>
          <product-receipt-auto-complete
            id="product"
            v-model="selectedProduct"
            class="inputfield w-full mt-1"
            @load-finish="productDataReady"
            :class="{ 'p-invalid': productError }"
            @item-select="selectProduct()"
            autofocus
            :inputClass="classHaveRefer"
            :disabled="haveRefer"
          />
          <small v-if="productError" id="product-help" class="p-error">{{
            lang.validateNoProductSelect
          }}</small>
        </div>

        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
          <label for="remark">{{ lang.description }}</label>
          <PvInputText
            id="remark"
            type="text"
            class="inputfield w-full mt-1"
            v-model="remark"
            :class="{ 'p-invalid': remarkError, 'p-inputtext-disabled': haveRefer }"
            @keypress="remarkError = false"
            :disabled="haveRefer"
          />
          <small v-if="remarkError" id="remark-help" class="p-error">
            {{ lang.validateNoRemarkKeyCard }}
          </small>
        </div>

        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 period flex-wrap">
          <label for="period">{{ lang.period }}</label>
          <base-period-auto-complete
            id="period"
            v-model="period"
            class="inputfield w-full mt-1"
            :class="{ 'p-invalid': periodError }"
            @item-select="periodError = false"
            :inputClass="classHaveRefer"
            :disabled="haveRefer"
          ></base-period-auto-complete>
          <small v-if="periodError" id="period-help" class="p-error">
            {{ lang.validateNoPeriod }}
          </small>
        </div>

        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="unitPrice">{{ lang.unitPrice }}</label>
          <PvInputNumber
            id="unitPrice"
            class="inputfield w-full mt-1"
            v-model="unitPrice"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            @focus="$event.target.select()"
            :inputClass="classHaveRefer"
            :disabled="haveRefer"
            @input="unitPrice = $event.value"
          />
          <!-- <PvInputText
            id="unitPrice"
            type="text"
            class="inputfield w-full mt-1"
            v-model="unitPrice"
            placeholder="0.00"
            @focus="$event.target.select()"
            :class="classHaveRefer"
            :disabled="haveRefer"
            @keypress="checkInputUnitPrice"
            @blur="this.unitPrice = this.formatCurrency(this.unitPrice)"
          /> -->
        </div>

        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="quantity">{{ lang.quantity }}</label>
          <PvInputNumber
            id="quantity"
            class="inputfield w-full mt-1"
            v-model="quantity"
            @focus="$event.target.select()"
            :inputClass="classQuantity"
            :disabled="haveRefer || isKeycard"
            @input="quantity = $event.value"
            mode="decimal"
            :min="0"
            :minFractionDigits="2"
            :maxFractionDigits="2"
          />
          <!-- <PvInputText
            id="quantity"
            type="text"
            class="inputfield w-full mt-1"
            v-model="quantity"
            placeholder="0"
            @focus="$event.target.select()"
            :class="classHaveRefer"
            :disabled="haveRefer"
            @keypress="checkInputQuantity"
          /> -->
        </div>

        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
          <label for="totalAmount">{{ lang.totalAmount }}</label>
          <PvInputNumber
            id="totalAmount"
            class="inputfield w-full mt-1"
            v-model="totalAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            @focus="$event.target.select()"
            :inputClass="classTotalAmount"
            :disabled="!haveRefer"
            @input="totalAmount = $event.value"
          />
        </div>

        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="discountDescription">{{ lang.discount }}</label>
          <PvInputText
            id="discountDescription"
            type="text"
            class="inputfield w-full mt-1"
            v-model="discountDescription"
            :placeholder="lang.discountPlaceholder"
            @focus="$event.target.select()"
            :class="{ 'p-invalid': discountError }"
            @keyup="checkInputDiscount()"
          />
          <small v-if="discountError" id="discount-help" class="p-error">
            {{ lang.validateInvalidDiscount }}
          </small>
        </div>

        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="discountAmount">{{ lang.discountAmount }}</label>
          <PvInputNumber
            id="discountAmount"
            class="inputfield w-full mt-1"
            inputClass="w-full p-inputtext-disabled"
            v-model="discountAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            disabled
          />
        </div>

        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
          <label for="netAmount">{{ lang.paidAmount }}</label>
          <PvInputNumber
            id="netAmount"
            class="inputfield w-full mt-1"
            inputClass="p-inputtext-disabled"
            v-model="netAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            disabled
          />
        </div>
      </section>
    </transition>

    <template #footer>
      <div class="flex justify-content-between align-items-center">
        <PvButton
          :label="lang.close"
          class="p-button-outlined p-button-secondary w-full ml-3"
          @click="closeDialog()"
        />
        <PvButton 
          :label="lang.confirm" 
          class="w-full mr-3" 
          @click="confirm()" 
          :disabled="isLockConfirm"
        />
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import ProductReceiptAutoComplete from "../../AR/receipt/ProductReceiptAutoComplete.vue";
import _ from 'underscore';
export default {
  props: {
    data: {
      type: Object,
    },
    isOpen: {
      type: Boolean,
    },
    // headerId: {
    //   type: String,
    // },
  },
  emits: ["close", "product-selected"],
  components: {
    ProductReceiptAutoComplete,
  },
  setup() {
    const { lang } = useLang();
    const { textFormClass, } = useTextStyle();
    const { formatCurrency, formatPeriodToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.receipt.listing,
          ...lang.th.ar.receipt.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.receipt.listing,
          ...lang.en.ar.receipt.edit,
        };
      }
    });


    return {
      lang: mergeLang,

      textFormClass,

      formatPeriodToISOString,
      formatCurrency,
    };
  },
  data() {
    return {
      selectedProduct: null,
      isSharedProduct: false,
      productError: false,
      remark: "",
      remarkError: false,
      period: [],
      periodError: false,
      unitPrice: 0.00,
      quantity: 1,
      discountDescription: "",
      discountError: false,
      totalAmountVariable: 0,

      showValidateDialog: false,
      validateWording: "",
      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ],

      objToCompare: null,
      isKeycard: false,
    };
  },
  created() {
    this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    initiateData() {
      this.dataButtonDialog = [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];
      let date = new Date();
      this.period =
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        (date.getFullYear() + 543);
      this.selectedProduct = null;
      this.remark = "";
      this.unitPrice = 0.00;
      this.quantity = 1;
      this.totalAmount = 0;
      this.discountDescription = "";
      this.discountError = false;
      this.productError = false;
      this.remarkError = false;
      this.periodError = false;
      this.showValidateDialog = false;
      this.isKeycard = false;
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    askForCancelEditData() {
      if (!_.isEqual(this.payload, this.objToCompare)) {
        this.setPageDirtyValidateDialog();
        this.showValidateDialog = true;
      } else {
        this.closeDialog();
      }
    },
    confirm() {
      // console.log(this.payload);
      if (this.validateData()) {
        this.setLoading(false);
        return;
      }

      this.$emit("product-selected", this.payload);
      this.initiateData();
    },
    async checkShouldSetData() {
      if (this.isSharedProduct && this.data) {
        this.setData();
      }
      await this.setCompareData();
    },
    setData() {
      this.setLoading(true);
      // console.log("setDataDialog", this.data);
      this.selectedProduct = this.sharedProductArs.find(
        (product) => product.id === this.data.productId
      );
      this.remark = this.data.remark;
      this.period = this.data.period;
      this.unitPrice = this.data.unitPrice;
      this.quantity = this.data.quantity;
      if (this.haveRefer) {
        this.totalAmount = this.data.totalAmount;
      }
      this.discountDescription = this.data.discountDescription.trim();
      this.setLoading(false);
    },
    productDataReady() {
      this.isSharedProduct = true;
    },
    checkInputDiscount() {
      // console.log("event=", event.key);
      // const keysAllowed=["0","1","2","3","4","5","6","7","8","9",".","%"];
      // const keyPressed = event.key;
      if (
        !this.discountDescription.match(/^[0-9.%]+$/i) &&
        this.discountDescription != ""
      ) {
        this.discountError = true;
      } else {
        this.discountError = false;
      }
      // console.log(this.containsOnlyNumbers(this.discountDescription));
    },
    validateData() {
      if (!this.selectedProduct) {
        // this.validateWording = this.lang.validateNoProductSelect;
        // this.showValidateDialog = true;
        this.productError = true;
        return true;
      }

      // if (
      //   this.selectedProduct.code.substring(0, 2) == "KC" &&
      //   this.remark.trim() == ""
      // ) {
      //   // this.validateWording = this.lang.validateNoRemarkKeyCard;
      //   // this.showValidateDialog = true;
      //   this.remarkError = true;
      //   return true;
      // }

      if (this.period == "") {
        // this.validateWording = this.lang.validateNoPeriod;
        // this.showValidateDialog = true;
        this.periodError = true;
        return true;
      }

      if (this.netAmount < 0) {
        this.validateWording = this.lang.validateNetAmount;
        this.showValidateDialog = true;
        return true;
      }

      return false;
    },
    selectProduct() {
      // console.log(this.selectedProduct);
      this.unitPrice = this.selectedProduct.price;
      this.productError = false;
      if(this.selectedProduct.code.substring(0,1).toLowerCase() == "k") {
        this.isKeycard = true;
      }
    },
    async setCompareData() {
      let objToCompare = {
        itemNo: 0,
        productId: this.selectedProduct ? this.selectedProduct.id : "",
        productCode: this.selectedProduct ? this.selectedProduct.code : "",
        productName: this.selectedProduct ? this.selectedProduct.nameThai : "",
        productUnitId: this.selectedProduct ? this.selectedProduct.unitId : "",
        productUnitCode: this.selectedProduct
          ? this.selectedProduct.unitCode.trim()
          : "",
        productUnitName: this.selectedProduct
          ? this.selectedProduct.unitName.trim()
          : "",
        quantity: this.quantity,
        unitPrice: this.unitPrice,
        discountDescription: this.discountDescription.trim(),
        discountAmount: this.discountAmount,
        totalAmount: this.totalAmount,
        netAmount: this.netAmount,
        remark: this.remark.trim(),
        referenceId: "",
        referenceType: "",
        referenceTypeDescription: "",
        tenantId: "",
        invoiceAmount: 0,
        period: this.period.name ? this.period.name : this.period,
        id: null,
        // headerId: !this.isCreateDocument ? this.headerId : null,
      };

      if (this.data) {
        objToCompare = {
          ...objToCompare,
          id: this.data.id,
        };
      }

      this.objToCompare = objToCompare;
      // console.log(this.objToCompare);
    },
    setPageDirtyValidateDialog() {
      this.dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.cancel,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
        {
          id: "confirm",
          caption: this.lang.buttonDialogConfirm,
          class: "w-full",
          action: "confirm",
        },
      ];
      this.validateWording = this.lang.confirmCancel + this.title;
    },
    // checkInputUnitPrice($event) {
    //   // console.log($event.key); 
    //   const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9','.',','];
    //   const keyPressed = $event.key;
    //   if (!keysAllowed.includes(keyPressed)) {
    //     $event.preventDefault()
    //   }
    // },
    // checkInputQuantity($event) {
    //   // console.log($event.key); 
    //   const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    //   const keyPressed = $event.key;
    //   if (!keysAllowed.includes(keyPressed)) {
    //     $event.preventDefault()
    //   }
    // },
  },
  computed: {
    payload() {
      let payload = {
        itemNo: 0,
        productId: this.selectedProduct ? this.selectedProduct.id : "",
        productCode: this.selectedProduct ? this.selectedProduct.code : "",
        productName: this.selectedProduct ? this.selectedProduct.nameThai : "",
        productUnitId: this.selectedProduct ? this.selectedProduct.unitId : "",
        productUnitCode: this.selectedProduct
          ? this.selectedProduct.unitCode.trim()
          : "",
        productUnitName: this.selectedProduct
          ? this.selectedProduct.unitName.trim()
          : "",
        quantity: this.quantity,
        unitPrice: this.unitPrice,
        discountDescription: this.discountDescription.trim(),
        discountAmount: this.discountAmount,
        totalAmount: this.totalAmount,
        netAmount: this.netAmount,
        remark: this.remark.trim(),
        referenceId: "",
        referenceType: "",
        referenceTypeDescription: "",
        tenantId: "",
        invoiceAmount: 0,
        period: this.period.name ? this.period.name : this.period,
        id: null,
        // headerId: !this.isCreateDocument ? this.headerId : null,
      };

      if (this.data) {
        payload = {
          ...payload,
          id: this.data.id,
          // referenceId: this.data.referenceId,
          // referenceType: this.data.referenceType,
          // referenceTypeDescription: this.data.referenceTypeDescription,
          // tenantId: this.data.tenantId,
          // invoiceAmount: this.data.invoiceAmount,
          // itemNo: this.data.itemNo,
        };
      }
      // console.log("payload", payload);

      return payload;
    },
    isCreateDocument() {
      return this.headerId === "create";
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    title() {
      let title = this.lang.addEdit //this.lang.addItem;
      // if (this.data) {
      //   title = this.lang.editItem;
      // }
      return title;
    },
    sharedProductArs() {
      return this.$store.getters["shared/productArProducts"];
    },
    selectedPeriod() {
      let period = null;
      if (this.period) {
        period = this.formatPeriodToISOString(this.period);
      }
      return period;
    },
    discountAmount() {
      let discount = 0;
      if (this.discountDescription && !this.discountError) {
        if (this.discountDescription.includes("%")) {
          discount =
            (parseFloat(this.discountDescription) / 100) * this.totalAmount;
        } else {
          discount = parseFloat(this.discountDescription);
        }
      }
      return parseFloat(discount.toFixed(2));
    },
    netAmount() {
      return this.totalAmount - this.discountAmount;
    },
    haveRefer() {
      let refer = false;
      if (this.data && this.data.referenceId.trim()) {
        refer = true;
      }
      return refer;
    },
    classHaveRefer() {
      let setClass = "w-full";
      if (this.haveRefer) {
        setClass = "w-full p-inputtext-disabled";
      }
      return setClass;
    },
    classTotalAmount() {
      let setClass = "w-full";
      if (!this.haveRefer) {
        setClass = "w-full p-inputtext-disabled";
      }
      return setClass;
    },
    totalAmount: {
      get() {
        if (this.haveRefer) {
          return parseFloat(this.totalAmountVariable.toFixed(2));
        } else {
          return parseFloat((this.unitPrice * this.quantity).toFixed(2));
        }
      },
      set(value) {
        this.totalAmountVariable = parseFloat(value.toFixed(2));
      },
    },
    isLockConfirm(){
      if (!this.selectedProduct) {
        return true;
      }
      else if (this.totalAmount <= 0) {
        return true;
      }
      else if (this.quantity < 0) {
        return true;
      }
      else if (this.netAmount < 0) {
        return true;
      }
      else if (this.period == "") {
        return true;
      }
      else {
        return false;
      }
    },
    classQuantity() {
      let setClass = "w-full";
      if (this.haveRefer || this.isKeycard) {
        setClass = "w-full p-inputtext-disabled";
      } 
      return setClass;
    },
  },
  watch: {
    isSharedProduct() {
      this.checkShouldSetData();
    },
    isOpen(curValue) {
      if(curValue) { 
        this.initiateData();
        this.checkShouldSetData();
      }
    },
  },
};
</script>

<style scoped>
.period {
  display: flex !important;
  flex-direction: column;
}
</style>
