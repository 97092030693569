<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.31175 10.5154L13.9077 13.6442C14.1566 13.7348 14.4313 13.7228 14.6714 13.6108C14.9115 13.4988 15.0973 13.296 15.1879 13.0471L16.1421 10.4253C16.297 9.99967 16.7205 9.73303 17.1712 9.77726L21.0076 10.1539C21.2781 10.1804 21.5322 10.0204 21.6251 9.76509L21.9639 8.8342C22.0763 8.5253 21.9171 8.18375 21.6082 8.07132L8.00743 3.12103C6.97057 2.74365 5.8241 3.27826 5.44671 4.31512L4.12146 7.95603C3.93952 8.45324 3.96274 9.0024 4.18601 9.48248C4.40928 9.96256 4.81428 10.3342 5.31175 10.5154Z"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 14H5C5.55228 14 6 14.4477 6 15V19C6 19.5523 5.55228 20 5 20H3V14Z"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 12.2217V15.9999C10 16.5522 9.55228 16.9999 9 16.9999H6"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 6.72823L8 6.00028"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.055 10.0605L19.2002 12.4088C19.0498 12.8221 18.7414 13.1586 18.3429 13.3444C17.9444 13.5302 17.4883 13.5501 17.0751 13.3997L15.2954 12.7519"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 14,
    },
    height: {
      type: [Number, String],
      default: 14,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -4px !important;
  margin-right: 4px;
}
</style>
