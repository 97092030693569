export default {
    async getRoleByDocumentBooks(context, payload) {
        let url = process.env.VUE_APP_BACKEND_URL + "/Role/ByBook";

        if (!payload.limit) {
            url = url + "?limit=10";
          } else {
            url = url + "?limit=" + payload.limit;
        }

        if (payload.page) {
            url = url + "&page=" + payload.page;
          }
      
        if (payload.where) {
            url = url + "&where=" + JSON.stringify(payload.where);
        }

        const token = context.rootGetters.token;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });

        const responseData = await response.json();

        if (!response.ok) {
            // console.log(responseData);
            const error = new Error(responseData.message || "Failed to get role by document book");
            throw error;
        }

        // console.log("responseData", responseData);

        // const data = responseData.data;
        context.commit("setRoleByDocumentBook", responseData);
    }
}