<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ lang.title }}
          </label>
        </div>
        <PvButton icon="pi pi-times" class="p-button-link p-icon-secondary" @click="closeDialog" />
      </div>
      <PvDivider></PvDivider>
    </template>

    <section class="flex flex-wrap" :class="textFormClass">
      <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
        <label for="unitCodeStart">{{ lang.unitCodeStart }}</label>
        <base-unit-auto-complete
          id="unitCodeStart"
          v-model="selectedUnitStart"
          class="inputfield w-full mt-1"
          :isLoadingData="true"
          @load-finish="unitDataReady"
        />
      </div>
      <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
        <label for="unitCodeEnd">{{ lang.unitCodeEnd }}</label>
        <base-unit-auto-complete
          id="unitCodeEnd"
          v-model="selectedUnitEnd"
          class="inputfield w-full mt-1"
          @load-finish="unitDataReady"
        />
      </div>
      <div class="col-4"></div>

      <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
        <label for="date">{{ lang.date }}</label>
        <PvCalendar
          id="date"
          v-model="date"
          class="inputfield w-full mt-1"
          showIcon
          dateFormat="dd/mm/yy"
          :class="{ 'p-invalid': dateError }"
          @date-select="dateError = false"
        />
        <small v-if="dateError" id="date-help" class="p-error">{{ lang.validateDateSelect }}</small>
      </div>
      <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
        <label for="dueDate">{{ lang.dueDate }}</label>
        <PvCalendar
          id="dueDate"
          v-model="dueDate"
          class="inputfield w-full mt-1"
          showIcon
          dateFormat="dd/mm/yy"
          :class="{ 'p-invalid': dueDateError }"
          @date-select="dueDateError = false"
        />
        <small v-if="dueDateError" id="dueDate-help" class="p-error">{{ lang.validateDateSelect }}</small>
      </div>
      <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0" v-if="isSuspend">
        <label for="suspendDate">{{ lang.suspendDate }}</label>
        <PvCalendar
          id="suspendDate"
          v-model="suspendDate"
          class="inputfield w-full mt-1"
          showIcon
          dateFormat="dd/mm/yy"
          :class="{ 'p-invalid': suspendDateError }"
          @date-select="suspendDateError = false"
        />
        <small v-if="suspendDateError" id="suspendDate-help" class="p-error">{{ lang.validateDateSelect }}</small>
      </div>
      <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0" v-else></div>

      <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
        <label for="printAmountMoreThan">{{ lang.printAmountMoreThan }}</label>
        <PvInputNumber
          id="printAmountMoreThan"
          class="inputfield w-full mt-1"
          inputClass="text-right"
          v-model="printAmountMoreThan"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="2"
          @focus="$event.target.select()"
        />
      </div>
      <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
        <label for="overDueDay">{{ lang.overDueDay }}</label>
        <PvInputNumber
          id="overDueDay"
          class="inputfield w-full mt-1"
          inputClass="text-right"
          v-model="overDueDay"
          @focus="$event.target.select()"
        />
      </div>
      <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
        <label for="fileNameText">{{ lang.fileName }}</label>
        <PvInputText
          id="fileNameText"
          type="text"
          class="inputfield w-full mt-1"
          v-model="fileNameText"
          placeholder="หากไม่ระบุ ระบบจะระบุให้อัตโนมัติ"
        />
      </div>
    </section>

    <template #footer>
      <div class="flex flex-wrap pt-4 pl-3 pr-2">
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
          <PvButton icon="pi pi-print" :label="lang.print" @click="download" class="w-full" />
        </div>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useTracking from "../../../hooks/tracking";
import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
export default {
  props: {
    isSuspend: {
      type: Boolean,
      default: false,
    },
    noticePrintSettingHeaderId: {
      type: Number,
    },
  },
  emits: ["close", "toast-request"],
  setup() {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { addReportTracking } = useTracking();
    const { noticePrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.notice.report,
          ...lang.th.ar.notice.customReport,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.notice.report,
          ...lang.th.ar.notice.customReport,
        };
      }
    });

    return {
      lang: mergeLang,
      textFormClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      addReportTracking,
      noticePrintOrder,
    };
  },
  data() {
    return {
      date: null,
      dateError: false,
      dueDate: null,
      dueDateError: false,
      suspendDate: null,
      suspendDateError: false,
      isSharedUnitReady: false,
      selectedUnitStart: null,
      selectedUnitEnd: null,
      printAmountMoreThan: 0,
      overDueDay: 0,
      fileNameText: "",
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    async initiateData() {
      this.date = new Date();
      this.dueDate = new Date();
      this.dateError = false;
      this.dueDateError = false;
      this.suspendDate = new Date();
      this.suspendDateError = false;
      this.selectedUnitStart = null;
      this.selectedUnitEnd = null;
      this.printAmountMoreThan = 0;
      this.overDueDay = 0;
      this.fileNameText = "";
    },
    unitDataReady() {
      this.isSharedUnitReady = true;
      this.setDataUnit();
    },
    setDataUnit() {
      this.selectedUnitStart = this.unitStart;
      this.selectedUnitEnd = this.unitEnd;

      this.setLoading(false);
    },
    validateData() {
      let returnStatus = false;
      if (this.selectedDate == null) {
        this.dateError = true;
        returnStatus = true;
      }
      if (this.selectedDueDate == null) {
        this.dueDateError = true;
        returnStatus = true;
      }
      if (this.selectedSuspendDate == null) {
        this.suspendDateError = true;
        returnStatus = true;
      }
      return returnStatus;
    },
    async download() {
      if (this.validateData()) {
        return;
      }

      this.setLoading(true);
      try {
        let tracking = { ...this.payload, ...{DocType: "PDF",} };
        let reportObj = {
          menuGroupTh: "notice_custom",
          menuGroupEn: "notice_custom",
          nameThai: this.lang.title,
          nameEnglish: "report notice custom",
        } 
        this.addReportTracking(tracking, reportObj, this.selectedProject, this.$route.name);
        const response = await this.noticePrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
    printSetting() {
      this.$router.push({ name: "report-notice-print-setting" });
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    fileBlob() {
      return this.$store.getters["shared/blob"];
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
    sharedUnits() {
      let result = this.$store.getters["shared/unitUnits"];
      return result.sort((a, b) => a.code - b.code);
    },
    unitStart() {
      if (this.sharedUnits.length > 0) {
        return this.sharedUnits[0];
      }
      return null;
    },
    unitEnd() {
      let unitCount = this.sharedUnits.length;
      if (this.sharedUnits.length > 0) {
        return this.sharedUnits[unitCount - 1];
      }
      return null;
    },
    selectedDate() {
      let date = null;
      if (this.date) {
        date = this.formatDateToISOString(this.date);
      }
      return date;
    },
    selectedDueDate() {
      let dueDate = null;
      if (this.dueDate) {
        dueDate = this.formatDateToISOString(this.dueDate);
      }
      return dueDate;
    },
    selectedSuspendDate() {
      let suspendDate = null;
      if (this.suspendDate) {
        suspendDate = this.formatDateToISOString(this.suspendDate);
      }
      return suspendDate;
    },
    selectedSuspendDateTh() {
      let suspendDate = null;
      if (this.suspendDate) {
        suspendDate = this.formatDate(this.suspendDate);
      }
      return suspendDate;
    },
    selectedSuspendDateEn() {
      let suspendDate = null;
      if (this.suspendDate) {
        suspendDate = this.formatDateToLocaleDateString(this.suspendDate);
      }
      return suspendDate;
    },
    payload() {
      let payload = {
        FileName: (this.fileNameText ? this.fileNameText : "notice_custom_report") + ".pdf",
        JasperPath: "notice/notice_custom_report.pdf",
        Topic: "notice_custom_report_print_order",
        Event: "create",
        UserId: this.userId,
        PrintBy: this.userName,
        ProjectId: this.selectedProject.id,
        NoticePrintSettingHeaderId: this.noticePrintSettingHeaderId,
        IsDummyPrintPreview: "N",
        Date: this.selectedDate,
        DueDate: this.selectedDueDate,
        SuspendDate: this.selectedSuspendDate,
        UnitCodeStart: this.selectedUnitStart ? this.selectedUnitStart.code : this.unitStart.code,
        UnitCodeEnd: this.selectedUnitEnd ? this.selectedUnitEnd.code : this.unitEnd.code,
        DebtAmountMoreThan: this.printAmountMoreThan,
        OverdueDayMoreThan: this.overDueDay,
      };
      return payload;
    },
  },
};
</script>
