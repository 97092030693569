import unitStates from "./unit/states.js";
import productArStates from "./productAR/states.js";
import documentBookDistinctStates from "./documentBookDistinct/states.js";
import projectStates from "./project/states.js";
import formPrintStates from "./formPrint/states.js";
import supplierStates from "./supplier/states.js";

import glBookStates from "./glBook/states.js";
import accountingFrequentlyStates from "./accountingFrequently/states.js";
import accountingStates from "./accounting/accountingAll/states.js";
import taxBookStates from "./taxBook/states.js";

import customerStates from "./customer/states.js";
import apBookStates from "./apBook/states.js";
import productApStates from "./productAP/states.js";
import employeeStates from "./employee/states.js";
import purchaseBookStates from "./billAcceptance/states.js";
import taxStates from "./tax/states.js";
import buildingStates from "./building/states.js";
import jobAdminStates from "./jobAdmin/states.js";
import locationStates from "./location/states.js";
import accountingDetailStates from "./accounting/accountingDetail/states.js";
import accountingParentStates from "./accounting/accountingParent/states.js";
import productUnitStates from "./productUnit/states.js";
import nationalityStates from "./nationality/states.js";
import bankStates from "./bank/states.js";
import printStates from "./print/states.js";
import roleByMenuStates from "./roleByMenu/states.js";
import roleByBookStates from "./roleByBook/states.js";
import currencyStates from "./currency/states.js";
import bookBankArStates from "./bookBankAr/states.js";
import productGuaranteeStates from "./productGuarantee/states.js";
import addressStates from "./address/states.js";
import dbdBusinessTypeStates from "./dbdBusinessType/states.js";
import countryCodeStates from "./countryCode/states.js";


const mergeStates = {
	...unitStates,
	...productArStates,
	...documentBookDistinctStates,
	...projectStates,
	...formPrintStates,
	...glBookStates,
	...accountingFrequentlyStates,
	...accountingStates,
	...supplierStates,
	...taxBookStates,
	...customerStates,
	...apBookStates,
	...productApStates,
	...employeeStates,
	...purchaseBookStates,
	...taxStates,
	...buildingStates,
	...jobAdminStates,
	...locationStates,
	...accountingDetailStates,
	...accountingParentStates,
	...productUnitStates,
	...nationalityStates,
	...bankStates,
	...printStates,
	...roleByMenuStates,
	...roleByBookStates,
	...currencyStates,
	...bookBankArStates,
	...productGuaranteeStates,
	...addressStates,
	...dbdBusinessTypeStates,
	...countryCodeStates,
};

export default mergeStates;
