import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { createConfirmDialog } from 'vuejs-confirm-dialog';
import { computed, ref ,onMounted} from 'vue';
import useLang from "../hooks/lang.js";
import useGetAccounting from "../hooks/getAccounting.js";
import BaseDialog from "../components/UI/BaseDialog.vue";
import * as XLSX from "xlsx";

export default function usePageDirty() {
    const store = useStore(); 
    const router = useRouter(); 
    const { lang } = useLang();
    const { isSharedAccountingReady, getAllAccountings, accountings } = useGetAccounting();
    
    let isAccountDetailNoParent = ref(false);
    let accountDetails = ref([]);

    onMounted(async () => {
        await getAllAccountings();
    });

    const getAccountDetailNoParent = async function () {        
        let result = await accountings.value.filter((e) =>
                e.activeStatus == "Y" &&
                e.isDetail == "Y" &&
                (e.parentId == null || e.parentId.trim() == "")
        );
        if(result.length > 0){
            accountDetails.value = [...result];
            isAccountDetailNoParent.value = true;
        }
        return  result;
    };

    const checkAccountDetailNoParent = async function () {
        await getAccountDetailNoParent();
        const confirmDialog = createConfirmDialog(BaseDialog,
        {
            titleConfirm: mergeLang.value.notificationDialogHeader,
            wordingConfirm: mergeLang.value.validateAccountDetail.replaceAll(":quantity", accountDetails.value.length),
            dataButtons:[{
                id: "close",
                caption: mergeLang.value.close,
                class: "p-button-outlined p-button-secondary w-full",
                action: "cancel",
            },
            {
                id: "confirm",
                caption: mergeLang.value.gotoAccounting,
                class: "w-full",
                action: "confirm",
            }],
            display: true
        });
         
        if(isAccountDetailNoParent.value){
            const answer = await confirmDialog.reveal();
            if(answer.isCanceled){
                return true;
            }else{
                // exportExcel();
                router.push({ name: "account-chart"});
                return true;
            }  
        }else{
            return false;
        }
    };
  
    //computed
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.gl.accounting.listing};
      } else {
        return { ...lang.en.base, ...lang.en.gl.accounting.listing};
      }
    });

    const exportExcel = function () {
        let datas = [];
        accountDetails.value.forEach(e => {
            let data = {
                id: e.id,
                code: e.code,
                name: e.name
            }
            datas.push(data);
        });

        const dataWS = XLSX.utils.json_to_sheet(datas);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataWS);
        XLSX.writeFile(wb, "AccountDetailNoParent.xlsx");
    };
    
    const isAccountDetailNoParentReady = computed(() => {
        if (isSharedAccountingReady.value) {
          return true;
        } 
        return false;
    });

    return {
        isAccountDetailNoParentReady,
        checkAccountDetailNoParent,
        isAccountDetailNoParent,
        accountDetails,
        exportExcel,
    };
}