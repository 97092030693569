export default {
    glBookGls(state) {
      return state.glBook.glBooks;
    },
    glBookCurrentPage(state) {
      return state.glBook.currentPage; 
    },
    glBookNextPage(state) {
      return state.glBook.currentPage + 1;
    },
    glBookTotalPage(state) {
      return state.glBook.totalPage;
    },
    glBookIsLastPage(_, getters) {
      return (getters.glBookCurrentPage === getters.glBookTotalPage) && (getters.glBookTotalPage !== 0);
    }
};
  