export default {
    toolbarTitle: "Register LOS APP Dashboard",
    title: "Application registration information",
    owner: "Owner",
    rential: "Rential",
    stay: "Stay",

    allStatusContent: "{number}",
    otherStatusContent: "({number})",

    allStatus: "All",
    registered: "Registered",
    waitRegister: "Wait for register",
    waitApprove: "Wait for approve",
    
    columnUnitCode: "Unit",
    columnAddressNo: "Address",
    columnCustomerName: "Customer Name",
    columnCustomerMobile: "Mobile Number",
    columnLivingType: "Living Type",
    columnRegisterStatus: "Register Status",
	dialogTitleExported: "Export success.",
	dialogContentExported: "Export to excel success.",
}