<template>
  <base-toolbar
    :title="lang.transferSlipList"
    :dataButtons="dataButtons"
    @gotoReject="askForReject"
    @gotoRevertToWaiting="askForRevertToWaiting"
    @gotoCreateReceipt="gotoCreateReceipt"
    @goBack="goBack"
  ></base-toolbar>

  <base-dialog
    v-model:visible="showValidateDialog"
    :titleConfirm="titleConfirm"
    :classTitle="baseDialogClassTitle"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    @confirm="showValidateDialog = false"
    @close="showValidateDialog = false"
    @gotoReject="gotoReject"
    @gotoRevertToWaiting="gotoRevertToWaiting"
  >
    <template #dialogBody>
      <div class="w-full pt-1 pb-4" v-if="isSelectReason">
        <PvDropdown
          class="inputfield w-full"
          :options="selectReason"
          optionLabel="code"
          optionValue="code"
          v-model="selectedReason"
          @change="changeReason"
          :class="{ 'p-invalid': isSelectedReasonError }"
        />
        <small v-if="isSelectedReasonError" id="unit-help" class="p-error">{{
          lang.validateSelectReason
        }}</small>
      </div>
    </template>
  </base-dialog>

  <base-container>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.title }}</label>
      </div>

      <PvDivider></PvDivider>
      <section
        class="flex flex-wrap justify-content align-items-start"
        :class="textFormClass"
      >
        <!--*** ฝั่งซ้าย ***-->
        <div class="lg:col-3 md:col-12 col-12 pl-0 pr-0">
          <div class="lg:col-12 md:col-12 col-12 pl-0 pr-0">
            <label class="font-lg font-normal"
              ><b>{{ lang.transferSlipImage }}</b></label
            >
          </div>
          <div class="lg:col-12 md:col-12 col-12 pl-0 pr-0">
            <label class="font-sm font-normal"
              >{{ lang.uploadDate }} {{ uploadDate }}
            </label>
          </div>
          <div class="lg:col-12 md:col-12 col-12 pl-0 pr-0">
            <div style="max-width: 250px">
              <PvImage
                :src="imageUrl"
                alt="Image"
                width="250"
                preview
                class="z-5"
              />
            </div>
          </div>
        </div>
        <!--*** ฝั่งซ้าย ***-->

        <!--*** ฝั่งขวา ***-->
        <div
          class="lg:col-9 md:col-12 col-12 pl-2 pr-0 flex align-items-start flex-wrap"
        >
          <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
            <label>{{ lang.status }}</label>
            <div class="mt-2">
              <base-tag :Caption="tagStatus.caption" :Class="tagStatus.class" />
            </div>
          </div>
          <div
            class="lg:col-9 md:col-6 col-12 pl-0 pr-2"
            v-if="tagStatus.class == 'success'"
          >
            <label>{{ lang.receiptCode }}</label>
            <div class="mt-1 font-md">
              <a
                class="font-md p-text-primary"
                href="#"
                @click="previewReceipt()"
              >
                {{ receiptCode }}
                <icon-external-link />
              </a>
            </div>
          </div>
          <div
            class="lg:col-9 md:col-6 col-12 pl-0 pr-2"
            v-else-if="tagStatus.class == 'error'"
          >
            <label>{{ lang.reasonsRejected }}</label>
            <div class="mt-1 font-md">
              <span>{{ reason }}</span>
            </div>
          </div>
          <div class="lg:col-9 md:col-6 col-12 pl-0 pr-2" v-else></div>
          <!-- ******************** -->

          <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
            <label>{{ lang.invoiceCode }}</label>
            <div class="mt-1 font-md">
              <span>{{ invoiceCode }}</span>
            </div>
          </div>
          <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
            <label>{{ lang.unitCode }}</label>
            <div class="mt-1 font-md">
              <span>{{ unitCode }}</span>
            </div>
          </div>
          <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
            <label>{{ lang.unitAddressNo }}</label>
            <div class="mt-1 font-md">
              <span>{{ unitAddressNo }}</span>
            </div>
          </div>
          <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
            <label>{{ lang.payerName }}</label>
            <div class="mt-1 font-md">
              <span>{{ customerName }}</span>
            </div>
          </div>
          <!-- ******************** -->

          <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 py-3">
            <label class="font-lg font-normal"
              ><b> {{ lang.pendingList }}</b></label
            >
          </div>
          <!-- ******************** -->

          <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
            <label>{{ lang.totalAmount }}</label>
            <div class="mt-1">
              <PvInputNumber
                id="tax"
                class="inputfield w-full mt-1"
                inputClass="input-number-disable text-right"
                v-model="totalAmount"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                disabled
              />
            </div>
          </div>
          <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
            <label>{{ lang.paidAmount }}</label>
            <div class="mt-1">
              <PvInputNumber
                id="tax"
                class="inputfield w-full mt-1"
                inputClass="input-number-disable text-right"
                v-model="paidAmount"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                disabled
              />
            </div>
          </div>
          <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
            <label>{{ lang.pendingAmount }}</label>
            <div class="mt-1">
              <PvInputNumber
                id="tax"
                class="inputfield w-full mt-1"
                inputClass="input-number-disable text-right"
                v-model="pendingAmount"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                disabled
              />
            </div>
          </div>
          <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2"></div>
          <!-- ******************** -->

          <div class="p-datatable-border my-2 w-full">
            <PvDataTable
              :value="invoiceDetails"
              selectionMode="single"
              dataKey="id"
              responsiveLayout="scroll"
              class="p-datatable-xs"
              :class="textTableClass"
              :scrollHeight="halfViewportHeight + 'px'"
              scrollable
            >
              <PvColumn
                field="itemNo"
                :header="lang.columnItemNo"
                style="min-width: 5rem; min-height: 45px"
                headerClass="h-left"
                bodyClass="text-left"
              ></PvColumn>
              <PvColumn
                field="productCode"
                :header="lang.columnProductCode"
                style="min-width: 5rem"
                headerClass="h-left"
                bodyClass="text-left"
              ></PvColumn>
              <PvColumn
                field="productName"
                :header="lang.columnProductName"
                style="min-width: 20rem"
                headerClass="h-left"
                bodyClass="text-left"
              ></PvColumn>
              <PvColumn
                field="period"
                :header="lang.columnPeriod"
                style="min-width: 5rem"
                headerClass="h-left"
                bodyClass="text-left"
              ></PvColumn>
              <PvColumn
                field="totalAmount"
                :header="lang.columnTotalAmount"
                style="min-width: 5rem"
                headerClass="h-right"
                bodyClass="text-right"
              >
                <template #body="slotProps">
                  <span class="text-right w-full">{{
                    formatCurrency(slotProps.data.totalAmount)
                  }}</span>
                </template>
              </PvColumn>
              <PvColumn
                field="paidAmount"
                :header="lang.columnPaidAmount"
                style="min-width: 5rem"
                headerClass="h-right"
                bodyClass="text-right"
              >
                <template #body="slotProps">
                  <span class="text-right w-full">{{
                    formatCurrency(slotProps.data.paidAmount)
                  }}</span>
                </template>
              </PvColumn>

              <template #empty>
                <div class="w-full p-3" Style="text-align: center;">
                  <label>{{ lang.tableEmpty }}</label>
                </div>
              </template>
            </PvDataTable>
          </div>

          <!-- ******************** -->
        </div>
        <!--*** ฝั่งขวา ***-->
      </section>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import useGetRoleByBook from "../../../hooks/getRoleByBook.js";
import useCalculation from "../../../hooks/calculation.js";
export default {
  props: ["id", "projectCode"],
  setup(props) {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const {
      formatCurrency,
      formatDateTime,
    } = useFormat();
    const store = useStore();    
    const { sum, subtract } = useCalculation();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.transferSlip.listing,
          ...lang.th.ar.transferSlip.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.transferSlip.listing,
          ...lang.en.ar.transferSlip.edit,
        };
      }
    });
    const router = useRouter();

    const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();

    let receiptId = ref("");
    let receiptCode = ref("");
    let reason = ref("");
    let invoiceCode = ref("");
    let unitCode = ref("");
    let unitAddressNo = ref("");
    let customerName = ref("");
    let totalAmount = ref(0);
    let paidAmount = ref(0);
    let pendingAmount = ref(0);
    let invoiceDetails = ref([]);

    const selectReason = [
      { code: mergeLang.value.reasonSelect },
      { code: mergeLang.value.reasonDuplicated },
      { code: mergeLang.value.reasonFake },
      { code: mergeLang.value.reasonIncorrect },
    ];
    let selectedReason = ref(mergeLang.value.reasonSelect);
    let isSelectedReasonError = ref(false);
    let isSelectReason = ref(false);

    //Dialog
    let showValidateDialog = ref(false);
    let titleConfirm = ref(mergeLang.value.notificationDialogHeader);
    let validateWording = ref("");
    let baseDialogClassTitle = ref("");
    let dataButtonDialog = ref([
      {
        id: "confirm",
        caption: mergeLang.value.close,
        class: "p-button-outlined p-button-secondary w-full",
        action: "confirm",
      },
    ]);
    let storageName = ref("");

    onMounted(async () => {
      setLoading(true);
      initiateData();
      await roleByBookDataReady();
    });

    const setLoading = (bool) => {
      store.dispatch("setLoading", { value: bool });
    };

    const goBack = () => {
      setLocalSearch();
      router.push({ name: "transfer-slip" });
    };

    const previewReceipt = () => {
      const routeData = router.resolve({
        name: "ar-receipt-edit",
        params: { id: receiptId.value },
      });
      window.open(routeData.href, "_blank");
    };

    const roleByBookDataReady = async () => {
      // console.log("roleByBookDataReady=", isSharedRoleByBookReady.value);
      if (isSharedRoleByBookReady.value) {
        await getTransferSlip();
        setData();
        calculateAmount();
      }
    };

    const setLocalSearch = () => {
      let storageName = `erp_search_TransferSlipsList_${selectedProject.value.code}_${userCode.value}`;
      const localSearch = localStorage.getItem(storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      localStorage.setItem(storageName, JSON.stringify(search));
    };

    //function
    const initiateData = () => {
      receiptId.value = "";
      receiptCode.value = "";
      reason.value = "";
      invoiceCode.value = "";
      unitCode.value = "";
      unitAddressNo.value = "";
      customerName.value = "";
      totalAmount.value = 0;
      paidAmount.value = 0;
      pendingAmount.value = 0;
      invoiceDetails.value = [];

      selectedReason.value = mergeLang.value.reasonSelect;
      isSelectReason.value = false;
      isSelectedReasonError.value = false;

      showValidateDialog.value = false;
      titleConfirm.value = mergeLang.value.notificationDialogHeader;
      validateWording.value = "";
      baseDialogClassTitle.value = "";
      dataButtonDialog.value = [
        {
          id: "confirm",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];
    };

    const getTransferSlip = async () => {
      try {
        await store.dispatch("transferSlip/getTransferSlip", props.id);
      } catch (error) {
        openAlertDialogError(error);
      }
    };

    const setData = () => {
      // console.log("transferSlip=", transferSlip.value);
      receiptId.value = transferSlip.value.receiptId;
      receiptCode.value = transferSlip.value.receiptCode;
      reason.value = transferSlip.value.reason;
      invoiceCode.value = transferSlip.value.invoiceCode;
      unitCode.value = transferSlip.value.unitCode;
      unitAddressNo.value = transferSlip.value.unitAddressNo;
      customerName.value = transferSlip.value.customerName;

      totalAmount.value = 0;
      paidAmount.value = 0;
      pendingAmount.value = 0;
      invoiceDetails.value = transferSlip.value.invoiceDetails;
      
      setLoading(false);
    };

    const calculateAmount = () => {
      if (invoiceDetails.value) {
        // console.log("invoiceDetails.value.invoiceDetails=",invoiceDetails.value);
        let total = 0;
        let paid = 0;
        invoiceDetails.value.forEach((detail) => {
          total =  sum([total, detail.totalAmount]);
          paid = sum([paid, detail.paidAmount]);
        });

        totalAmount.value = total;
        paidAmount.value = paid;
        pendingAmount.value = subtract(total, paid);
      }
    };
    const askForReject = () => {
      if (!isRoleCancel.value) {
        validateWording.value = mergeLang.value.validateRoleCancel;
        showValidateDialog.value = true;
        return;
      }

      dataButtonDialog.value = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
        {
          id: "gotoReject",
          caption: mergeLang.value.confirm,
          class: "w-full",
          action: "gotoReject",
        },
      ];
      titleConfirm.value = mergeLang.value.selectRejectionReason;
      validateWording.value = mergeLang.value.reason;
      baseDialogClassTitle.value = "";

      isSelectReason.value = true;
      isSelectedReasonError.value = false;
      showValidateDialog.value = true;
    };
    const gotoReject = () => {
      if (selectedReason.value == mergeLang.value.reasonSelect) {
        isSelectedReasonError.value = true;
        return;
      }

      showValidateDialog.value = false;
      isSelectReason.value = false;
      saveData("rejected", selectedReason.value);
    };
    const saveData = async (status, reason) => {
      try {
        let payload = {
          projectId: selectedProject.value.id,
          id: transferSlip.value.id,
          transferSlipId: props.id,
          status: status,
          reason: reason,
          typeAddEdit: transferSlip.value.id != 0 ? "Update" : "Add", 
        };
        // console.log("payload=", payload);
        await store.dispatch("transferSlip/addEditTransferSlip", payload);
        dataButtonDialog.value = [
          {
            id: "close",
            caption: mergeLang.value.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
        ];
        baseDialogClassTitle.value = "p-text-success";
        titleConfirm.value = status == "rejected" ? mergeLang.value.titleChangedStatusSuccess : mergeLang.value.titleRevertedToWaitingSuccess;
        validateWording.value = status == "rejected" ? mergeLang.value.changedStatusSuccess : mergeLang.value.revertedToWaitingSuccess;
        showValidateDialog.value = true;

        await router.replace({name: "transfer-slip-edit", params: { id: transferSlip.value.transferSlipId }})
        
      } catch (error) {
        openAlertDialogError(error);
      }
    };

    const askForRevertToWaiting = () => {
      if (!isRoleApprove.value) {
        validateWording.value = mergeLang.value.validateRoleApprove;
        baseDialogClassTitle.value = "";
        showValidateDialog.value = true;
        return;
      }

      dataButtonDialog.value = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
        {
          id: "gotoRevertToWaiting",
          caption: mergeLang.value.confirm,
          class: "w-full",
          action: "gotoRevertToWaiting",
        },
      ];
      titleConfirm.value = mergeLang.value.revertToWaiting;
      validateWording.value = mergeLang.value.askForRevertToWaiting;
      baseDialogClassTitle.value = "";
      showValidateDialog.value = true;
    };
    const gotoRevertToWaiting = () => {
      showValidateDialog.value = false;
      saveData("waiting", "");
    };
    const gotoCreateReceipt = async() => {
      if (!isRoleCreate.value) {
        validateWording.value = mergeLang.value.validateRoleInsert;
        showValidateDialog.value = true;
        return;
      }
      await getUniqueKey();
      let query = {
        uniqueKeyId: receiptUniqueKey.value.id,
        id: transferSlip.value.id,
        transferSlipId: transferSlip.value.transferSlipId,
        unitId: transferSlip.value.unitId,
      };

      const routeData = router.resolve({
        name: "ar-receipt-edit",
        params: { id: "create" },
        query: query,
      });
      window.open(routeData.href, "_blank");
    };
    const changeReason = () => {
      isSelectedReasonError.value = false;
    };
    const getUniqueKey = async() => {
      try {
        await store.dispatch("arReceipt/getReceiptUniqueKey");
      } catch (error) {
        openAlertDialogError(error);
      }
    };

    const openAlertDialogError = (error) => {
      dataButtonDialog.value = [
        {
          id: "confirm",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];
      baseDialogClassTitle.value = "p-error";
      titleConfirm.value = mergeLang.value.notificationDialogHeader;
      validateWording.value = error.message;
      showValidateDialog.value = true;      
      setLoading(false);
    };

    //computed
    const halfViewportHeight = computed(() => {
      return store.getters.halfViewportHeight;
    });
    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const userCode = computed(() => {
      return store.getters.userCode;
    });
    const transferSlip = computed(() => {
      return store.getters["transferSlip/transferSlip"];
    });
    const uploadDate = computed(() => {
      if (transferSlip.value) {
        return formatDateTime(transferSlip.value.losa_CreateDate);
      }
      return "";
    });
    const imageUrl = computed(() => {
      if (transferSlip.value) {
        return transferSlip.value.imageUrl;
      }
      return "";
    });

    const tagStatus = computed(() => {
      let status = {
        caption: mergeLang.value.rejected,
        class: "error",
      };
      if (transferSlip.value && transferSlip.value.status == "receiptCreated") {
        status.caption = mergeLang.value.receiptCreated;
        status.class = "success";
      } else if (transferSlip.value && transferSlip.value.status == "waiting") {
        status.caption = mergeLang.value.waiting;
        status.class = "warning";
      }

      return status;
    });

    const roleByDocumentBook = computed(() => {
      return getRoleByBookByCode("INV");
    });
    const isRoleCreate = computed(() => {
      let result = false;
      if (roleByDocumentBook.value && roleByDocumentBook.value.insert == "Y") {
        result = true;
      }
      return result;
    });
    const isRoleCancel = computed(() => {
      let result = false;
      if (roleByDocumentBook.value && roleByDocumentBook.value.cancel == "Y") {
        result = true;
      }
      return result;
    });
    const isRoleApprove = computed(() => {
      let result = false;
      if (roleByDocumentBook.value && roleByDocumentBook.value.approve == "Y") {
        result = true;
      }
      return result;
    });
    const dataButtons = computed(() => {
      let result = [
        {
          id: "gotoReject",
          action: "gotoReject",
          caption: mergeLang.value.reject,
          class: "button-toolbar p-button-outlined mr-2",
          icon: "icon-reject",
          iconColor: "primary",
          isShow: isWaiting.value,
        },
        {
          id: "gotoRevertToWaiting",
          action: "gotoRevertToWaiting",
          caption: mergeLang.value.revertToWaiting,
          class: "button-toolbar p-button-outlined mr-2",
          isShow: isRejected.value,
        },
        {
          id: "gotoCreateReceipt",
          action: "gotoCreateReceipt",
          caption: mergeLang.value.createReceipt,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          isShow: isWaiting.value,
        },
      ];
      return result;
    });
    const isWaiting = computed(() => {
      if (transferSlip.value && transferSlip.value.status == "waiting") {
        return true;
      }
      return false;
    });
    const isRejected = computed(() => {
      if (transferSlip.value && transferSlip.value.status == "rejected") {
        return true;
      }
      return false;
    });
    const receiptUniqueKey = computed(() => {
      return store.getters["arReceipt/receiptUniqueKey"];
    });

    // watchEffect(() => {
    //   if (isSharedRoleByBookReady.value) {
    //     roleByBookDataReady();
    //   }
    // });

    return {
      lang: mergeLang,

      textTableClass,
      textFormClass,

      formatCurrency,
      formatDateTime,
      getRoleByBookByCode,
      isSharedRoleByBookReady,
      sum,
      subtract,

      receiptId,
      receiptCode,
      reason,
      invoiceCode,
      unitCode,
      unitAddressNo,
      customerName,

      totalAmount,
      paidAmount,
      pendingAmount,
      invoiceDetails,
      selectReason,
      selectedReason,
      isSelectedReasonError,
      isSelectReason,

      showValidateDialog,
      titleConfirm,
      validateWording,
      baseDialogClassTitle,
      dataButtonDialog,
      storageName,

      //function
      setLoading,
      goBack,
      previewReceipt,
      roleByBookDataReady,
      setLocalSearch,
      initiateData,
      getTransferSlip,
      calculateAmount,
      askForReject,
      gotoReject,
      saveData,
      askForRevertToWaiting,
      gotoRevertToWaiting,
      gotoCreateReceipt,
      changeReason,
      getUniqueKey,

      openAlertDialogError,

      //computed
      halfViewportHeight,
      selectedProject,
      userCode,
      transferSlip,
      uploadDate,
      imageUrl,
      tagStatus,
      roleByDocumentBook,
      isRoleCreate,
      isRoleCancel,
      isRoleApprove,
      dataButtons,
      isWaiting,
      isRejected,
      receiptUniqueKey,
    };
  },
  watch: {
    isSharedRoleByBookReady() {
      this.roleByBookDataReady();
    },
  },
};
</script>
