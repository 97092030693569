<template>
  <PvDialog
    :breakpoints="{ '1280px': '50vw', '640px': '75vw' }"
    :style="{ width: '40vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="lang.notificationDialogHeader"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="closeDialog"
    ></base-dialog>

    <template #header>
      <div class="flex flex-wrap justify-content-between align-items-right p-0">
        <div class="p-0">
          <label class="font-lg">{{ lang.title }}</label>
        </div>
        <div>
          <PvButton
            icon="pi pi-times"
            class="p-button-link p-icon-secondary pb-0 pt-0"
            style="width: 15px"
            @click="closeDialog()"
          />
        </div>
      </div>
      <PvDivider></PvDivider>

      <section class="flex flex-wrap justify-content align-items-center">
        <div class="lg:col-6 md:col-6 col-6 p-0 mr-2">
          <span class="p-input-icon-left w-full">
            <i class="pi pi-search" />
            <PvInputText
              class="inputfield w-full"
              v-model="searchText"
              :placeholder="lang.keywordSearch"
              @keyup.enter="filterResident"
              autofocus
            />
          </span>
        </div>

        <PvButton @click="filterResident">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>
    </template>

    <div class="p-datatable-border my-2">
      <PvDataTable
        :value="filteredResidents"
        v-model:selection="selectedResident"
        selectionMode="single"
        dataKey="customerId"
        responsiveLayout="scroll"
        :scrollHeight="halfViewportHeight + 'px'"
        class="p-datatable-sm"
        :class="textTableClass"
        :loading="isLoading"
        @dblclick="selectResident"
      >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
        <PvColumn
          selectionMode="single"
          style="flex: 0; height: 56px"
        ></PvColumn>

        <PvColumn
          field="customerName"
          :header="lang.columnName"
          style="min-width: 15rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
          sortable
        ></PvColumn>

        <PvColumn
          field="erpLivingTypeTh"
          :header="lang.columnType"
          style="min-width: 2rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
          sortable
        ></PvColumn>
      </PvDataTable>
    </div>

    <template #footer>
      <PvButton 
        class="p-button-text"
        @click="closeDialog"
      >
        <i class="pi pi-times mr-2"></i>
        <span class="font-normal font-md">{{ lang.close }}</span>
      </PvButton>
      <PvButton 
        class="mr-3"
        @click="selectResident"
        :disabled="!selectedResident"
        autofocus
      >
        <i class="pi pi-check mr-2"></i>
        <span class="font-normal font-md">{{ lang.ok }}</span>
      </PvButton>
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
export default {
  props: ["selectedCustomerId"],
  emits: ["close", "resident-selected"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textTableClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.shared.resident };
      } else {
        return { ...lang.en.base, ...lang.en.shared.resident };
      }
    });

    return {
      textHeaderClass,
      textTableClass,
      lang: mergeLang,
    };
  },
  data() {
    return {
      isLoading: false,
      searchText: "",
      selectedResident: null,
      filteredResident: [],
      isFilter: false,

      showValidateDialog: false,
      validateWording: "",
    };
  },
  methods: {
    initiateData() {
      this.searchText = "";
      this.isLoading = false;
      this.selectedResident = null;
      this.filteredResident = [];
      this.isFilter = false;
      this.showValidateDialog = false;
      this.validateWording = "";
    },
    selectResident() {
      if (!this.selectedResident) {
        this.showValidateDialog = true;
        return;
      }
      this.$emit("resident-selected", this.selectedResident);
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    filterResident() {
      if (!this.searchText) {
        this.filteredResident = [...this.residents];
        this.isFilter = false;
      } else {
        this.filteredResident = this.residents.filter((resident) => {
          return (
            resident.customerName
              .toLowerCase()
              .includes(this.searchText.toLowerCase()) ||
            resident.erpLivingTypeTh
              .toLowerCase()
              .includes(this.searchText.toLowerCase())
          );
        });
        this.isFilter = true;
      }
      this.selectedResident = null;
    },
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    residentList() {
      return this.$store.getters["unitResident/unitResidents"];
    },
    residents() {
      let residents = this.residentList;
      if(this.selectedCustomerId) {
        residents = residents.filter((resident) => resident.customerId != this.selectedCustomerId);
      }
      return residents.sort((a, b) => (a.erpLivingType > b.erpLivingType ? 1 : -1));
    },
    filteredResidents() {
      if (!this.isFilter) {
        return this.residents;
      } else {
        return this.filteredResident;
      }
    },
    dataButtonDialog() {
      let dataButtonDialog= [
        {
          id: "confirm",
          caption: this.lang.buttonDialogConfirm,
          class: "w-full",
          action: "confirm",
        },
      ];

      return dataButtonDialog
    },
  },
};
</script>