<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoSave="saveOrUpdateResident"
    @gotoSettingUnitProduct="gotoSettingUnitProduct"
  >
  </base-toolbar>
  <base-container>
    <base-dialog
      v-model:visible="showConfirmDialog"
      :titleConfirm="confirmTitle"
      :wordingConfirm="confirmWording"
      :classTitle="confirmClassTitle"
      :dataButtons="dialogDataButtons"
      @close="showConfirmDialog = false"
    ></base-dialog>
    <base-dialog
      v-model:visible="showResidentDialog"
      :titleConfirm="confirmTitle"
      :wordingConfirm="confirmWording"
      :classTitle="confirmClassTitle"
      :dataButtons="dialogDataButtons"
      widthStyle="40vw"
      @close="showResidentDialog = false"
      @addRentalResident="addRentalResident()"
      @updateRentalResident="updateRentalResident()"
    >
      <template #dialogBody>
        <section class="flex flex-wrap fontlabel" :class="textFormClass">
          <div class="lg:col-12 md:col-12 col-12 pl-0 pr-0 flex flex-wrap">
            <div class="lg:col-12 md:col-12 col-12 p-0 flex flex-wrap">
              <label name="data" for="residentType" class="p-0 col-12">
                {{ lang.residentType }}
              </label>
              <div
                v-for="residentType of residentTypes"
                :key="residentType.code"
                class="field-radiobutton mt-2"
              >
                <PvRadioButton
                  class="mr-2"
                  :inputId="residentType.code"
                  name="residentType"
                  :value="residentType.code"
                  v-model="selectedResidentType"
                />
                <label :for="residentType.code" class="mr-2">
                  {{ residentType.name }}
                </label>
              </div>
            </div>
            <div class="lg:col-12 md:col-12 col-12 p-0 flex flex-wrap">
              <label name="data" for="resident">
                {{ lang.rentalResident }}
              </label>
              <base-customer-auto-complete
                id="resident"
                v-model="selectedResident"
                class="inputfield w-full mt-2"
                @load-finish="residentDataReady"
                :placeholder="lang.addResident"
                @item-select="changeResident()"
                :class="{ 'p-invalid': selectedResidentError }"
                @change="selectedResidentError = false"
                :isLoadingData="false"
                :disabled="isUpdateResident"
              />
              <small v-if="selectedResidentError" id="roomType-help" class="p-error">
                {{ lang.validateRentalResident }}
              </small>
            </div>

            <div class="lg:col-12 md:col-12 col-12 p-0 mt-3 flex flex-wrap">
              <div class="lg:col-6 md:col-6 col-12 p-0 pr-1">
                <label for="stayDate1">{{ lang.stayDate }}</label>
                <PvCalendar
                  id="stayDate1"
                  v-model="startDate"
                  class="inputfield w-full mt-2"
                  showIcon
                  dateFormat="dd/mm/yy"
                  :placeholder="lang.validateDate"
                />
              </div>
              <div class="lg:col-6 md:col-6 col-12 p-0 pl-1">
                <label for="moveDate1">{{ lang.moveDate }}</label>
                <PvCalendar
                  id="moveDate1"
                  v-model="endDate"
                  class="inputfield w-full mt-2"
                  showIcon
                  dateFormat="dd/mm/yy"
                  :placeholder="lang.validateDate"
                />
              </div>
            </div>
            <div class="lg:col-12 md:col-12 col-12 p-0 mt-3 flex flex-wrap">
              <div class="col-12 p-0 pr-1">
                <label for="activeStatusResident">{{ lang.status }}</label>
              </div>
              <div class="col-12 p-0 pr-1 mt-2 align-items-center flex flex-wrap">
                <PvInputSwitch id="activeStatusResident" v-model="activeStatusResident" class="ml-2 mr-3" />
                <label for="activeStatusResident">
                  {{ activeStatusResident ? lang.activeStatusResident : lang.inactiveStatusResident }}
                </label>
              </div>
            </div>
          </div>
        </section>
      </template>
    </base-dialog>
    <div class="surface-card shadow-2 p-5 border-round mt-5" :class="textFormClass">
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.detailTitle }}</label>
      </div>
      <PvDivider></PvDivider>
      <base-card
        :titleHeader="lang.generalInformation"
        :isVisibleExpandButton="true"
        :isHideBackground="true"
        :isVisibleCard="true"
        class="pb-0"
      >
        <section class="flex flex-wrap fontlabel" :class="textFormClass">
          <!-- <div class="lg:col-6 md:col-6 col-12 pl-0 pr-3 pb-0"> -->
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="code">{{ lang.code + "*" }}</label>
            <PvInputText
              type="text"
              class="inputfield w-full mt-2"
              v-model="code"
              :placeholder="lang.validateCode"
              :class="{ 'p-invalid': codeError }"
              @keyup="codeError = false"
            />
            <small v-if="codeError" id="code-help" class="p-error">{{
              lang.validateCode
            }}</small>
          </div>
          <!-- <div class="lg:col-6 md:col-6 col-12 pl-3 pr-0 pb-0"> -->
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="addressNo">{{ lang.addressNo + "*" }}</label>
            <PvInputText
              type="text"
              class="inputfield w-full mt-2"
              v-model="addressNo"
              :placeholder="lang.validateAddressNo"
              :class="{ 'p-invalid': addressNoError }"
              @keyup="addressNoError = false"
            />
            <small v-if="addressNoError" id="addessNo-help" class="p-error">{{
              this.messageErrorAddressNo
            }}</small>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="building">{{ lang.building }}</label>
            <PvInputText
              type="text"
              class="inputfield w-full mt-2"
              v-model="building"
              :placeholder="lang.validateBuilding"
            />
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="floor">{{ lang.floor }}</label>
            <PvInputText
              type="text"
              class="inputfield w-full mt-2"
              v-model="floor"
              :placeholder="lang.validateFloor"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="roomType">{{ lang.roomType + "*" }}</label>
            <PvDropdown
              id="roomType"
              class="inputfield w-full mt-2"
              :options="roomTypes"
              optionLabel="name"
              optionValue="name"
              v-model="selectRoomType"
              :placeholder="lang.validateRoomType"
              :class="{ 'p-invalid': roomTypeError }"
              @change="roomTypeError = false"
            />
            <small v-if="roomTypeError" id="roomType-help" class="p-error">{{
              lang.validateRoomType
            }}</small>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="room">{{ lang.room }}</label>
            <PvInputText
              type="text"
              class="inputfield w-full mt-2"
              v-model="room"
              :placeholder="lang.validateRoom"
              :class="{ 'p-invalid': roomError }"
              @keyup="roomError = false"
            />
            <small v-if="roomError" id="room-help" class="p-error">{{
              lang.validateRoom
            }}</small>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="contractNo">{{ lang.contractNo }}</label>
            <PvInputText
              type="text"
              class="inputfield w-full mt-2"
              v-model="contractNo"
              :placeholder="lang.validateContractNo"
            />
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="QRPayment">{{ lang.QRPayment }}</label>
            <PvInputText type="text" class="inputfield w-full mt-2" v-model="QRPayment" />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="ratio">{{ lang.ratio }}</label>
            <!-- <PvInputText
              type="number"
              class="inputfield w-full mt-2"
              v-model="ratio"
              placeholder="0.00"
            /> -->
            <PvInputNumber
              id="ratio"
              class="inputfield w-full mt-2"
              v-model="ratio"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              placeholder="0.00"
              inputStyle="color: #6E7073"
              @focus="$event.target.select()"
            />
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="area">{{ lang.area }}</label>
            <!-- <PvInputText
                type="text"
                class="inputfield w-full mt-2"
                v-model="area"
                placeholder="0.00"
              /> -->
            <PvInputNumber
              id="area"
              class="inputfield w-full mt-2"
              v-model="area"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              placeholder="0.00"
              inputStyle="color: #6E7073"
              @focus="$event.target.select()"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="cooling">{{ lang.cooling }}</label>
            <!-- <PvInputText
              type="text"
              class="inputfield w-full mt-2"
              v-model="cooling"
              placeholder="0.00"
            /> -->
            <PvInputNumber
              id="cooling"
              class="inputfield w-full mt-2"
              v-model="cooling"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              inputStyle="color: #6E7073"
              placeholder="0.00"
              @focus="$event.target.select()"
            />
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="activeStatus">{{ lang.activeStatus }}</label>
            <div class="mt-3">
              <PvInputSwitch v-model="activeStatus" class="ml-2" />
            </div>
          </div>
        </section>
      </base-card>

      <base-card
        :titleHeader="lang.memberInformation"
        :isVisibleExpandButton="true"
        :isHideBackground="true"
        :isVisibleCard="true"
        class="pt-3"
      >
        <!-- <div class="flex justify-content-between align-items-center pt-2 pb-1">
          <PvSplitButton :label="lang.addMember" :model="items" class="bg-white">
            <PvButton
              class="p-button-outlined opacity-100"
              style="border-bottom-right-radius: 0rem; border-top-right-radius: 0rem"
              :label="lang.addMember"
              disabled="true"
            ></PvButton>
          </PvSplitButton>
        </div> -->

        <div class="flex flex-wrap align-items-center mt-3">
          <label class="font-lg font-normal w-full">{{ lang.detailOwnerTitle }}</label>
          <label class="font-md font-normal mt-2">
            {{ lang.detailOwnerDescription }}
          </label>
        </div>
        <PvDivider></PvDivider>
        <section class="flex flex-wrap fontlabel" :class="textFormClass">
          <div class="lg:col-12 md:col-12 col-6 p-0">
            <div class="lg:col-6 md:col-6 col-6 px-1 pt-2 pb-0">
              <label for="owner1">{{ lang.owner1 + "*" }}</label>
              <base-customer-auto-complete
                id="owner1"
                v-model="selectedOwner1"
                class="inputfield w-full mt-2"
                @load-finish="ownerDataReady"
                :placeholder="lang.addOwner"
                :class="{ 'p-invalid': owner1Error }"
                @item-select="owner1Error = false"
              />
              <small v-if="owner1Error" id="owner1-help" class="p-error">{{
                lang.addOwner
              }}</small>
            </div>
          </div>

          <div class="lg:col-12 md:col-12 col-6 p-0">
            <div class="lg:col-6 md:col-6 col-6 px-1 pt-2 pb-0">
              <label for="owner2">{{ lang.owner2 }}</label>
              <base-customer-auto-complete
                id="owner2"
                v-model="selectedOwner2"
                class="inputfield w-full mt-2"
                :placeholder="lang.addOwner"
                :isLoadingData="false"
              />
            </div>
          </div>

          <div class="lg:col-12 md:col-12 col-6 p-0">
            <div class="lg:col-6 md:col-6 col-6 px-1 pt-2 pb-0">
              <label for="owner3">{{ lang.owner3 }}</label>
              <base-customer-auto-complete
                id="owner3"
                v-model="selectedOwner3"
                class="inputfield w-full mt-2"
                :placeholder="lang.addOwner"
                :isLoadingData="false"
              />
            </div>
          </div>
          <base-card
            :titleHeader="lang.memberInformation"
            :isVisibleExpandButton="false"
            :isHideBackground="false"
            :isVisibleCard="true"
            :isVisibleBorder="true"
            class="mt-2 px-0"
          >
            <div class="lg:col-12 md:col-12 col-12 px-0 flex flex-wrap">
              <div class="lg:col-3 md:col-6 col-12 px-1 pt-3 pb-0">
                <label for="appointmentDate">{{ lang.appointmentDate }}</label>
                <PvCalendar
                  id="appointmentDate"
                  v-model="appointmentDate"
                  class="inputfield w-full mt-2"
                  showIcon
                  dateFormat="dd/mm/yy"
                  :placeholder="lang.validateDate"
                />
              </div>
              <div class="lg:col-3 md:col-6 col-12 px-1 pt-3 pb-0">
                <label for="acceptDate">{{ lang.acceptDate }}</label>
                <PvCalendar
                  id="acceptDate"
                  v-model="acceptDate"
                  class="inputfield w-full mt-2"
                  showIcon
                  dateFormat="dd/mm/yy"
                  :placeholder="lang.validateDate"
                />
              </div>
              <div class="lg:col-3 md:col-6 col-12 px-1 pt-3 pb-0">
                <label for="transferDate">{{ lang.transferDate }}</label>
                <PvCalendar
                  id="transferDate"
                  v-model="transferDate"
                  class="inputfield w-full mt-2"
                  showIcon
                  dateFormat="dd/mm/yy"
                  :placeholder="lang.validateDate"
                />
              </div>
              <div class="lg:col-3 md:col-6 col-12 px-1 pt-3 pb-0">
                <label for="livingDate">{{ lang.livingDate }}</label>
                <PvCalendar
                  id="livingDate"
                  v-model="livingDate"
                  class="inputfield w-full mt-2"
                  showIcon
                  dateFormat="dd/mm/yy"
                  :placeholder="lang.validateDate"
                />
              </div>
              <div class="lg:col-6 md:col-12 col-12 px-1 pt-3">
                <label for="receiptAddress">{{ lang.receiptAddress }}</label>
                <PvInputText
                  v-model="receiptAddress"
                  :placeholder="lang.validateReceiptAddress"
                  class="inputfield w-full mt-2"
                  :maxlength="750"
                />
              </div>
              <div class="lg:col-3 md:col-6 col-12 px-1 pt-3 pb-0">
                <label for="living">{{ lang.living }}</label>
                <PvDropdown
                  id="living"
                  class="inputfield w-full mt-2"
                  :options="livingTypes"
                  optionLabel="name"
                  optionValue="code"
                  v-model="selectLivingType"
                />
              </div>
              <div class="lg:col-3 md:col-6 col-12 px-1 pt-3 pb-0">
                <label for="warrantyEndDate">{{ lang.warrantyEndDate }}</label>
                <PvCalendar
                  id="warrantyEndDate"
                  v-model="warrantyEndDate"
                  class="inputfield w-full mt-2"
                  showIcon
                  dateFormat="dd/mm/yy"
                  :placeholder="lang.validateDate"
                />
              </div>
            </div>
          </base-card>
        </section>
        <div class="flex flex-wrap justify-content-between align-items-center mt-5">
          <label class="font-lg font-normal">{{ lang.residentInformation }}</label>
          <PvButton class="ml-2" @click="openAddRentalResidentDialog()">
            <icon-plus-add iconColor="white" height="16" width="16" />
            <span style="font-size: 14px">
              {{ lang.addNewResident }}
            </span>
          </PvButton>
        </div>
        <PvDataTable
          :value="rentalResidentData"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs mt-3"
          :class="textTableClass"
          :loading="isLoading"
        >
          <template #empty>
            <div
              style="height: 56px"
              class="text-center flex align-items-center justify-content-center w-full"
            >
              <label class="font-normal">
                {{ lang.tableEmpty }}
              </label>
            </div>
          </template>
          <PvColumn
            field="isActive"
            :header="lang.status"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal"
            style="height: 56px"
          >
            <template #body="slotProps">
              <base-tag
                v-if="slotProps.data.isActive.trim() == 'Y'"
                :Caption="lang.activeStatusResident"
                Class="success"
              />
              <base-tag
                v-else
                :Caption="lang.inactiveStatusResident"
                Class="error"
              />
            </template>
          </PvColumn>
          <PvColumn
            field="customerName"
            :header="lang.name"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal"
            style="height: 56px"
          ></PvColumn>
          <PvColumn
            field="startDate"
            :header="lang.stayDate"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal"
            style="height: 56px"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.startDate, false) }}
            </template>
          </PvColumn>
          <PvColumn
            field="endDate"
            :header="lang.moveDate"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal"
            style="height: 56px"
          >
            <template #body="slotProps">
              <span v-if="slotProps.data.endDate == slotProps.data.startDate">-</span>
              <span v-else>{{ formatDate(slotProps.data.endDate, false) }}</span>
            </template>
          </PvColumn>
          <PvColumn
            field="erpLivingType"
            :header="lang.livingType"
            headerClass="h-left font-normal"
            bodyClass="text-left font-normal"
            style="height: 56px"
          >
            <template #body="slotProps">
              <base-tag
                v-if="slotProps.data.erpLivingType.trim() == 'Stay'"
                :Caption="lang.resident"
                Class="primary"
              />
              <base-tag
                v-else
                :Caption="lang.rental"
                Class="warning"
              />
            </template>
          </PvColumn>
          <PvColumn
            headerClass="h-center"
            bodyClass="text-center"
            style="min-width: 7rem"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0"
                @click="editRentalResident(slotProps.data)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0"
                @click="deleteRentalResidentData(slotProps.data)"
              >
                <icon-trash-delete-bin iconColor="error" />
              </PvButton>
            </template>
          </PvColumn>
          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
            >
              <template #start="slotProps">
                {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{
                  totalPage
                }}
              </template>
            </PvPaginator>
          </template>
        </PvDataTable>
      </base-card>
    </div>

    <div
      class="shadow-2 border-round mt-5"
      :class="{
        'p-inputtext-disabled': isCreateDocument,
        'surface-card': !isCreateDocument,
      }"
    >
      <unit-document-view :unitId="this.id"></unit-document-view>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import unitDocumentView from "../unit/UnitDocumentView.vue";
import usePageDirty from "../../../hooks/pageDirty";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import useGetCustomers from "../../../hooks/getCustomers.js";
export default {
  props: ["id", "projectCode"],
  components: {
    unitDocumentView,
  },
  setup() {
    const { lang } = useLang();
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();
    const {
      textHeaderClass,
      textFormClass,
      textTableClass,
      textCardTitleClass,
    } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.unit.edit,
          ...lang.th.master.unit.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.unit.edit,
          ...lang.en.master.unit.listing,
        };
      }
    });

    const { compareObject, isCompareObject } = usePageDirty();

    const { refreshCustomers } = useGetCustomers();

    return {
      lang: mergeLang,

      textHeaderClass,
      textFormClass,
      textTableClass,
      textCardTitleClass,

      formatCurrency,
      formatDate,
      formatDateToISOString,

      compareObject,
      isCompareObject,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      refreshCustomers,
    };
  },
  data() {
    return {
      selectedPage: 1,
      rowsPerPage: 10,
      isLoading: false,
      searchText: "",
      code: "",
      codeError: false,
      addressNo: "",
      addressNoError: false,
      isAddressNoErrorLimit: false,
      activeStatus: true,
      activeStatusResident: true,
      cooling: 0,
      area: 0,
      ratio: 0,
      QRPayment: "",
      contractNo: "",
      room: "",
      roomError: false,
      floor: "",
      building: "",
      roomTypes: [
        { name: this.lang.roomType1 },
        { name: this.lang.roomType2 },
        { name: this.lang.roomType3 },
        { name: this.lang.roomType4 },
        { name: this.lang.roomType5 },
      ],
      selectRoomType: "",
      roomTypeError: false,

      selectedOwner1: null,
      owner1Error: false,
      selectedOwner2: null,
      selectedOwner3: null,
      transferDate: "",
      livingDate: "",
      appointmentDate: "",
      acceptDate: "",
      warrantyEndDate: "",
      receiptAddress: "",
      livingTypes: [
        { name: this.lang.living1, code: "1" },
        { name: this.lang.living2, code: "2" },
        { name: this.lang.living3, code: "3" },
      ],
      selectLivingType: null,

      resident1: "",
      resident2: "",
      resident3: "",
      isShowResident2: true,
      isShowResident3: true,
      stayDate1: "",
      moveDate1: "",
      stayDate2: "",
      moveDate2: "",
      stayDate3: "",
      moveDate3: "",
      rental1: null,
      rentalStayDate1: "",

      items: [
        {
          label: this.lang.resident,
          command: () => {
            this.addResident();
          },
        },
        {
          label: this.lang.rental,
          command: () => {
            this.addRental();
          },
        },
      ],

      isSharedOwnerReady: false,
      isSharedOwner1Ready: false,
      isSharedOwner2Ready: false,
      isSharedOwner3Ready: false,
      isSharedRentalReady: false,
      isSharedResidentReady: false,

      owners: [],
      residents: [],
      rentals: [],
      newRentals: [],

      rentalStartDateDateError: false,
      activeTabIndex: 0,

      rentalCount: 0,
      residentCount: 0,

      // addMembers: [
      //   { name: "ผู้พักอาศัย", code: "1" },
      //   { name: "ผู้เช่า", code: "2" },
      // ],
      selectAddMember: "",
      clearAddMember: false,

      objToCompare: null,
      selectedRental: [],
      selectedResident: "",

      showConfirmDialog: false,
      confirmTitle: "",
      confirmWording: "",
      confirmClassTitle: "",
      typeButtonDialog: "",
      showResidentDialog: false,
      startDate: "",
      endDate: "",
      residentTypes: [
        { name: this.lang.rental, code: "Rential" },
        { name: this.lang.resident, code: "Stay" },
      ],
      selectedResidentType: "Rential",
      rentalResidentData: [],
      idToUpdate: [],
      selectedResidentError: false,
      unitResidentDataForDelete: [],
      unitResidentDataForCreateUpdate: [],
      isUpdateResident: false,
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    if (!this.isCreateDocument) {
      await this.getUnit();
      await this.getUnitResidents();
      //this.setData();
    }
    await this.refreshCustomers();
    if(this.project.length == 0) {
      await this.getProject();
    }
    await this.checkShouldSetData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      this.getUnitResidents();
    },
    goBack() {
      // this.compareObject(this.objToCompare, this.payload);
      this.setLocalSearch();
      this.$router.push({name: "unit"});
    },
    async checkShouldSetData() {
      if (
        this.isSharedOwnerReady &&
        this.isSharedRoleByMenuReady &&
        this.isCreateDocument
      ) {
        this.selectedOwner1 = this.sharedCustomers.find(
          (owner) => owner.code == this.project.taxId
        );
        this.setLoading(false);
      } else if (
        this.isSharedOwnerReady &&
        this.selectedUnit &&
        this.isSharedRoleByMenuReady &&
        !this.isCreateDocument
      ) {
        this.setData();
      }

      await this.setCompareData();
    },
    async getUnit() {
      try {        
        await this.$store.dispatch("unit/getUnitById", this.id);
      } catch (error) {
        this.openErrorDialog(error);
      }
    },
    async getUnitResidents() {
      this.isLoading = true;

      let where = {
        projectId: this.selectedProject.id,
        unitId: this.id,
        customerName: this.searchText,
      };

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };

      try {
        await this.$store.dispatch("unitResident/getUnitResidents", payload);
        this.rentalResidentData = this.unitResidents.filter(
          (data) => data.erpLivingType != "Owner"
        );
      } catch (error) {
        this.openErrorDialog(error);
      }

      // this.owners = this.unitResidents.filter((data) => data.erpLivingType == "Owner");

      //   this.residents = this.unitResidents.filter((data) => data.erpLivingType == "Stay");
      //   this.rentals = this.unitResidents.filter((data) => data.erpLivingType == "Rential");

      // let res = this.unitResidents.filter((data) => data.erpLivingType == "Stay");

      // res.forEach((data) => {
      //   let newRes = {
      //     addBy: data.addBy,
      //     addressNo: data.addressNo,
      //     area: data.area,
      //     building: data.building,
      //     countryCode: data.countryCode,
      //     customerCode: data.customerCode,
      //     customerId: data.customerId,
      //     customerName: data.customerName,
      //     email: data.email,
      //     endDate: new Date(data.endDate),
      //     erpLivingType: data.erpLivingType,
      //     floor: data.floor,
      //     id: data.id,
      //     livingType: data.livingType,
      //     phone: data.phone,
      //     projectCode: data.projectCode,
      //     projectId: data.projectId,
      //     projectName: data.projectName,
      //     startDate: new Date(data.startDate),
      //     unitCode: data.unitCode,
      //     unitId: data.unitId,
      //     unitResidentId: data.unitResidentId,
      //   };
      //   this.residents.push(newRes);
      // });

      // let rent = this.unitResidents.filter((data) => data.erpLivingType == "Rential");

      // rent.forEach((data) => {
      //   let newRent = {
      //     addBy: data.addBy,
      //     addressNo: data.addressNo,
      //     area: data.area,
      //     building: data.building,
      //     countryCode: data.countryCode,
      //     customerCode: data.customerCode,
      //     customerId: data.customerId,
      //     customerName: data.customerName,
      //     email: data.email,
      //     endDate: new Date(data.endDate),
      //     erpLivingType: data.erpLivingType,
      //     floor: data.floor,
      //     id: data.id,
      //     livingType: data.livingType,
      //     phone: data.phone,
      //     projectCode: data.projectCode,
      //     projectId: data.projectId,
      //     projectName: data.projectName,
      //     startDate: new Date(data.startDate),
      //     unitCode: data.unitCode,
      //     unitId: data.unitId,
      //     unitResidentId: data.unitResidentId,
      //   };
      //   this.rentals.push(newRent);
      // });

      // this.rentalCount = this.rentals.length;
      // this.residentCount = this.residents.length;

      // this.setDataUnitResident();
      this.isLoading = false;
    },
    setDataUnitResident() {
      this.rentals.forEach((rent) => {
        let customer = this.sharedCustomers.find((cus) => cus.id === rent.customerId);
        this.selectedRental.push(customer);
      });

      this.residents.forEach((res) => {
        let customer = this.sharedCustomers.find((cus) => cus.id === res.customerId);
        this.selectedResident.push(customer);
      });
    },
    async initiateData() {
      this.code = "";
      this.addressNo = "";
      this.activeStatus = true;
      this.activeStatusResident = true;
      this.cooling = 0;
      this.area = 0;
      this.ratio = 0;
      this.QRPayment = "";
      this.contractNo = "";
      this.room = "";
      this.floor = "";
      this.building = "";
      this.selectRoomType = "";
      this.selectedOwner1 = null;
      this.selectedOwner2 = null;
      this.selectedOwner3 = null;
      this.transferDate = "";
      this.livingDate = "";
      this.appointmentDate = "";
      this.acceptDate = "";
      this.warrantyEndDate = "";
      this.receiptAddress = "";
      this.selectLivingType = "1";

      this.codeError = false;
      this.addressNoError = false;
      this.isAddressNoErrorLimit = false;
      this.roomTypeError = false;
      this.roomError = false;
      this.rentalStartDateDateError = false;
      this.owner1Error = false;

      this.rentalCount = 0;
      this.residentCount = 0;
      this.selectedRental = [];
      this.selectedResident = [];
      this.unitResidentDataForDelete = [];
      this.unitResidentDataForCreateUpdate = [];
      this.isUpdateResident = false;

      this.$store.dispatch("unitResident/resetUnitResidents");
      this.$store.dispatch("unit/resetSelectedUnit");

      // await this.setCompareData();
    },
    async setData() {
      this.code = this.selectedUnit.code;
      this.addressNo = this.selectedUnit.addressNo;
      this.building = this.selectedUnit.building;
      this.floor = this.selectedUnit.floor;
      this.selectRoomType = this.selectedUnit.name;
      this.room = this.selectedUnit.room;
      this.contractNo = this.selectedUnit.contract;
      this.QRPayment = this.selectedUnit.qrpayment;
      this.ratio = Number(this.selectedUnit.ownerRatio);
      this.area = this.selectedUnit.area;
      this.cooling = Number(this.selectedUnit.cooling);
      this.activeStatus = this.selectedUnit.isActive == "Y" ? true : false;

      this.selectedOwner1 = this.sharedCustomers.find(
        (owner) => owner.id === this.selectedUnit.customerId
      );
      this.selectedOwner2 = this.sharedCustomers.find(
        (owner) => owner.id === this.selectedUnit.customerId2
      );
      this.selectedOwner3 = this.sharedCustomers.find(
        (owner) => owner.id === this.selectedUnit.customerId3
      );

      this.transferDate = this.selectedUnit.transferDate
        ? new Date(this.selectedUnit.transferDate)
        : null;
      this.livingDate = this.selectedUnit.livingDate
        ? new Date(this.selectedUnit.livingDate)
        : null;
      this.appointmentDate = this.selectedUnit.appointmentDate
        ? new Date(this.selectedUnit.appointmentDate)
        : null;
      this.acceptDate = this.selectedUnit.receiptDate
        ? new Date(this.selectedUnit.receiptDate)
        : null;
      this.warrantyEndDate = this.selectedUnit.expirdInsuranceDate
        ? new Date(this.selectedUnit.expirdInsuranceDate)
        : null;
      this.receiptAddress = this.selectedUnit.receiptAddress;
      this.selectLivingType = this.selectedUnit.livingType;
      // await this.setCompareData(); 
      this.setLoading(false);
      
    },
    validateRole() {
      if(this.isCreateDocument && !this.isRoleCreate) {
        this.typeButtonDialog = "error"
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateRoleInsert,
        });
        return true;
      } else if(!this.isCreateDocument && !this.isRoleUpdate) {
        this.typeButtonDialog = "error"
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateRoleUpdate,
        });
        return true;
      }
      return false;
    },
    wordingValidate() {
      if(!this.code) {
        return this.lang.validateFieldNoValue + this.lang.code;
      }

      if(!this.addressNo) {
        return this.lang.validateFieldNoValue + this.lang.addressNo;
      }

      if(this.addressNo && this.isAddressNoErrorLimit) {
        return this.lang.validateAddressMoreThanLimit;
      }

      if (!this.selectRoomType) {
        return this.lang.validateDropDownNoSelect + this.lang.roomType;
      }

      if (!this.selectedOwner1) {
        return this.lang.validateDropDownNoSelect + this.lang.owner1;
      }
    },
    validateData() {
      let returnStatus = false;

      if (!this.code) {
        this.codeError = true;
        returnStatus = true;
      }
      if (!this.addressNo) {
        this.addressNoError = true;
        this.isAddressNoErrorLimit = false;
        returnStatus = true;
      }
      if (this.addressNo && this.addressNo.length > 25) {
        this.addressNoError = true;
        this.isAddressNoErrorLimit = true;
        returnStatus = true;
      }
      if (!this.selectRoomType) {
        this.roomTypeError = true;
        returnStatus = true;
      }
      // if (!this.room) {
      //   this.roomError = true;
      //   return true;
      // }
      if (!this.selectedOwner1) {
        this.owner1Error = true;
        returnStatus = true;
      }

      if(returnStatus) {
        this.typeButtonDialog = "error"
        this.openAlertDialog({
          severity: "error",
          title: this.lang.validateSaveTitle,
          content: this.wordingValidate(),
        });
      }

      return returnStatus;
    },
    validateRentalResident(data) {
      let dataDuplicate = this.rentalResidentData.filter((resident) => resident.customerId == data.customerId);
      if (dataDuplicate.length > 1) {
        this.typeButtonDialog = "error";
        this.openAlertDialog({
          severity: "error",
          title: this.lang.validateSaveTitle,
          content: this.lang.validateResidentDuplicate.replace(":name", data.customerName.trim()),
        });
        return true;
      }
      return false;
    },
    async saveOrUpdateResident() {      
      if (this.validateRole()) {
        return;
      }
      if (this.validateData()) {
        // this.setLoading(false);
        return;
      }
      for (const data of this.rentalResidentData) {
        if (this.validateRentalResident(data)) {
          return;
        }
        let payload = {
          customerId: data.customerId,
          role: data.erpLivingType,
          startDate: data.startDate,
          endDate: data.endDate,
          addBy: null,
          isActive: data.isActive,
        };
        if (data.id.includes("dummy")) {
          payload = { ...payload, type: "AddResident" };
        } else {
          payload = { ...payload, type: "UnitUpdateResident", id: data.id };
        }
        if (data.isChange) {
          data.startDate = this.formatDateToISOString(new Date(data.startDate));
          data.endDate = this.formatDateToISOString(new Date(data.endDate));
          // console.log("saveOrUpdateResident===payload", payload);
          this.unitResidentDataForCreateUpdate.push(payload);
        }
      }
      this.save();
    },
    async save() {
      this.setLoading(true);
      try {
        await this.$store.dispatch("unit/createOrUpdateUnit", this.payload);

        this.typeButtonDialog = "success";

        if (this.isCreateDocument) {
          this.$router.replace({name: "unit-edit", params: { id: this.selectedUnit.id}});
          this.openAlertDialog({
            severity: "success",
            title: this.lang.saveSuccess,
            content: this.lang.createUnitSuccess,
          });
        } else {
          this.openAlertDialog({
            severity: "success",
            title: this.lang.saveSuccess,
            content: this.lang.editUnitSuccess,
          });
        }

        this.rentals = [];
        this.residents = [];
        this.selectedRental = [];
        this.selectedResident = [];
        this.unitResidentDataForDelete = [];
        this.unitResidentDataForCreateUpdate = [];
        this.getUnitResidents();
        await this.setCompareData();
      } catch (error) {
        let massage = error.message;
				if (error.code == "103") {
					massage = this.lang.dialogValidateParamsProjectId;
				} else if (error.code == "104") {
					massage = this.lang.dialogValidateParamsCode;
				} else if (error.code == "102") {
					massage = this.lang.dialogValidateUnitIdNotFound;
				} else if (error.code == "105") {
					massage = this.lang.dialogValidateParamsAddressNo;
				} else if (error.code == "106") {
					massage = this.lang.dialogValidateParamsUnitId;
				} else if (error.code == "111") {
					massage = this.lang.dialogValidateInsertRole;
				} else if (error.code == "112") {
					massage = this.lang.dialogValidateUpdateRole;
				} else if (error.code == "101") {
					massage = this.lang.dialogValidateProjectIdNotFound;
				} else if (error.code == "120") {
					massage = this.lang.dialogValidateDuplicateCode;
				} else if (error.code == "121") {
					massage = this.lang.dialogValidateDuplicateAddressNo;
				}
        this.openErrorDialog(massage);
      }
      this.setLoading(false);
    },
    addRental() {
      let data = {
        customerId: "",
        endDate: null,
        startDate: null,
      };
      this.rentals.push(data);
      // this.newRentals.push(data);
      this.activeTabIndex = 3;
    },
    addResident() {
      let data = {
        customerId: "",
        endDate: null,
        startDate: null,
      };
      this.residents.push(data);
      this.activeTabIndex = 2;
    },
    ownerDataReady() {
      // this.isSharedOwner1Ready = true;
      this.isSharedOwnerReady = true;
    },
    rentalDataReady() {
      this.isSharedRentalReady = true;
    },
    residentDataReady() {
      this.isSharedResidentReady = true;
    },
    editOwner(data) {
      this.$router.push({name: "unit-resident-edit", params: {unitId: this.id, id: data.id}})
    },
    editResident(data) {
      if (data.customerName.id)
        this.$router.push({name: "unit-resident-edit", params: { unitId: this.id, id: data.customerName.id}});
      else this.$router.push({name: "unit-resident-edit", params: { unitId: this.id, id: data.customerId}});
    },
    editRental(data) {
      if (data.customerName.id)
        this.$router.push({name: "unit-resident-edit", params: { unitId: this.id, id: data.customerName.id}});
      else this.$router.push({name: "unit-resident-edit", params: { unitId: this.id, id: data.customerId}});
    },
    async setCompareData() {
      let objToCompare = {
        projectId: this.selectedProject.id,
        code: this.code,
        name: this.selectRoomType,
        ownerId: this.selectedOwner1 ? this.selectedOwner1.id : "",
        ownerRatio: this.ratio ? this.ratio.toString() : "0",
        transferDate: this.transferDate
          ? this.formatDateToISOString(this.transferDate)
          : null,
        livingDate: this.livingDate ? this.formatDateToISOString(this.livingDate) : null,
        building: this.building,
        floor: this.floor,
        addressNo: this.addressNo,
        area: this.area ? this.area : 0,
        contract: this.contractNo,
        cooling: this.cooling ? this.cooling.toString() : "0",
        receiptAddress: this.receiptAddress,
        activeStatus: this.activeStatus == true ? "Y" : "N",
        ownerId2: this.selectedOwner2 ? this.selectedOwner2.id : "",
        ownerId3: this.selectedOwner3 ? this.selectedOwner3.id : "",
        qrPayment: this.QRPayment,
        livingType: this.selectLivingType,
        room: this.room,
        appointmentDate: this.appointmentDate
          ? this.formatDateToISOString(this.appointmentDate)
          : null,
        receiptDate: this.acceptDate ? this.formatDateToISOString(this.acceptDate) : null,
        expirdInsuranceDate: this.warrantyEndDate
          ? this.formatDateToISOString(this.warrantyEndDate)
          : null,
        unitResidentDataForDelete: this.unitResidentDataForDelete,
        unitResidentDataForCreateUpdate: this.unitResidentDataForCreateUpdate,
        type: "Add",
      };
      if (!this.isCreateDocument) {
        objToCompare = { ...objToCompare, id: this.id, type: "Update" };
      }
      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
    },
    tabClick(data) {
      // console.log("tabClick", data);
      if (data.index == 0) this.activeTabIndex = 0;
      else if (data.index == 1) this.activeTabIndex = 1;
      else if (data.index == 2) this.activeTabIndex = 2;
      else this.activeTabIndex = 3;
    },
    changeRental(index) {
      let oldRental = this.rentals[index].customerId;
      let curRental = this.selectedRental[index].id;

      // console.log("oldRental", oldRental);
      // console.log("curRental", curRental);

      if (oldRental && curRental && oldRental != curRental) {
        this.rentals[index] = { ...this.rentals[index], changeRental: "update" };
      } else {
        this.rentals[index] = { ...this.rentals[index], changeRental: "add" };
      }

      this.rentals[index].customerId = curRental;
    },
    changeResident() {
      this.startDate = new Date();
      this.endDate = new Date();
      this.selectedResidentError = false;
    },
    setLocalSearch() {
      let storageName = `erp_search_UnitList_${this.selectedProject.code}_${this.userCode}`;
      const localSearch = localStorage.getItem(storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      localStorage.setItem(storageName, JSON.stringify(search));
    },
    gotoSettingUnitProduct() {
      this.$router.push({name: "unit-product-setup-invoice", params: {unitId: this.id}});
    },
    openErrorDialog(error) {
      this.confirmClassTitle = "p-error";
      this.confirmTitle = this.lang.notificationDialogHeader;
      this.confirmWording = error;
      this.showConfirmDialog = true;
      this.typeButtonDialog = "error";
    },
    openAlertDialog(dataConfig) {
      if (dataConfig.severity === "") {
        this.confirmClassTitle = "";
      } else if (dataConfig.severity === "success") {
        this.confirmClassTitle = "p-text-success";
      } else if (dataConfig.severity === "error") {
        this.confirmClassTitle = "p-error";
      }
      this.confirmTitle = dataConfig.title;
      this.confirmWording = dataConfig.content;
      this.showConfirmDialog = true;
    },
    openAddRentalResidentDialog() {
      this.confirmClassTitle = "";
      this.confirmTitle = this.lang.addResident;
      this.confirmWording = "";
      this.typeButtonDialog = "addRentalResident";
      this.startDate = new Date();
      this.endDate = new Date();
      this.selectedResident = "";
      this.selectedResidentType = "Rential";
      this.selectedResidentError = false;
      this.showResidentDialog = true;
      this.activeStatusResident = true;
      this.isUpdateResident = false;
    },
    validateAddUpdateResident() {
      let returnStatus = false;
      if (!this.selectedResident) {
        this.selectedResidentError = true;
        returnStatus = true;
      }
      return returnStatus;
    },
    async addRentalResident() {
      if (this.validateAddUpdateResident()) {
        return;
      }
      let customer = this.selectedResident;
      let newRentalResident = {
        countryCode: customer.mobileCountryCode,
        customerCode: customer.code.trim(),
        customerId: customer.id.trim(),
        customerName: customer.name,
        endDate: this.selectedEndDate,
        erpLivingType: this.selectedResidentType,
        erpLivingTypeTh: this.selectedResidentType == "Stay" ? "ผู้พักอาศัย" : "ผู้เช่า",
        id: "dummy" + (parseInt(this.rentalResidentData.length) + 1),
        livingType: this.selectedResidentType == "Stay" ? "Family" : "Tenant",
        startDate: this.selectedStartDate,
        isChange: true,
        isActive: this.activeStatusResident == true ? "Y" : "N",
      };

      this.rentalResidentData.push(newRentalResident);
      this.showResidentDialog = false;
    },
    async updateRentalResident() {
      if (this.validateAddUpdateResident()) {
        return;
      }
      let customer = this.selectedResident;
      let i = 0;
      if (this.idToUpdate.includes("dummy")) {
        i = this.rentalResidentData
          .map((item) => item.id)
          .indexOf(this.idToUpdate);
      } else {
        i = this.rentalResidentData
          .map((item) => item.unitResidentId)
          .indexOf(this.idToUpdate);
      }

      this.rentalResidentData[i].countryCode = customer.mobileCountryCode;
      this.rentalResidentData[i].countryCode = customer.mobileCountryCode;
      this.rentalResidentData[i].customerCode = customer.code.trim();
      this.rentalResidentData[i].customerId = customer.id.trim();
      this.rentalResidentData[i].customerName = customer.name;
      this.rentalResidentData[i].endDate = this.selectedEndDate;
      this.rentalResidentData[i].erpLivingType = this.selectedResidentType;
      this.rentalResidentData[i].erpLivingTypeTh =
        this.selectedResidentType == "Stay" ? "ผู้พักอาศัย" : "ผู้เช่า";
      this.rentalResidentData[i].id = this.idToUpdate;
      this.rentalResidentData[i].livingType =
        this.selectedResidentType == "Stay" ? "Family" : "Tenant";
      this.rentalResidentData[i].startDate = this.selectedStartDate;
      this.rentalResidentData[i].isActive = this.activeStatusResident == true ? "Y" : "N";
      this.rentalResidentData[i].isChange = true;
      this.showResidentDialog = false;
    },
    editRentalResident(dataCustomer) {
      this.confirmClassTitle = "";
      this.confirmTitle = this.lang.addResident;
      this.confirmWording = "";
      this.typeButtonDialog = "updateRentalResident";
      this.showResidentDialog = true;

      this.selectedResident = this.sharedCustomers.find(
        (data) => data.id === dataCustomer.customerId
      );
      this.endDate = new Date(dataCustomer.endDate);
      this.startDate = new Date(dataCustomer.startDate);
      this.selectedResidentType = dataCustomer.erpLivingType;
      this.idToUpdate = dataCustomer.unitResidentId ?? dataCustomer.id;
      this.activeStatusResident = dataCustomer.isActive == "Y" ? true : false;
      this.isUpdateResident = true;
    },
    deleteRentalResidentData(data) {
      if (!data.id.includes("dummy")) {
        this.unitResidentDataForDelete.push(data);
      }
      let i = this.rentalResidentData
        .map((item) => item.unitResidentId)
        .indexOf(data.unitResidentId);
      this.rentalResidentData.splice(i, 1);
    },
    async getProject() {
      try {      
        await this.$store.dispatch(
          "masterProject/GetProjectById",
          this.selectedProject.id
        );
      } catch (error) {
        this.openErrorDialog(error);
      }
    },
  },
  computed: {
    payload() {
      let payload = {
        projectId: this.selectedProject.id,
        code: this.code,
        name: this.selectRoomType,
        ownerId: this.selectedOwner1 ? this.selectedOwner1.id : "",
        ownerRatio: this.ratio ? this.ratio.toString() : "0",
        transferDate: this.transferDate
          ? this.formatDateToISOString(this.transferDate)
          : null,
        livingDate: this.livingDate ? this.formatDateToISOString(this.livingDate) : null,
        building: this.building,
        floor: this.floor,
        addressNo: this.addressNo,
        area: this.area ? this.area : 0,
        contract: this.contractNo,
        cooling: this.cooling ? this.cooling.toString() : "0",
        receiptAddress: this.receiptAddress,
        activeStatus: this.activeStatus == true ? "Y" : "N",
        ownerId2: this.selectedOwner2 ? this.selectedOwner2.id : "",
        ownerId3: this.selectedOwner3 ? this.selectedOwner3.id : "",
        qrPayment: this.QRPayment,
        livingType: this.selectLivingType,
        room: this.room,
        appointmentDate: this.appointmentDate
          ? this.formatDateToISOString(this.appointmentDate)
          : null,
        receiptDate: this.acceptDate ? this.formatDateToISOString(this.acceptDate) : null,
        expirdInsuranceDate: this.warrantyEndDate
          ? this.formatDateToISOString(this.warrantyEndDate)
          : null,
        unitResidentDataForDelete: this.unitResidentDataForDelete,
        unitResidentDataForCreateUpdate: this.unitResidentDataForCreateUpdate,
        type: "Add",
      };

      if (!this.isCreateDocument) {
        payload = { ...payload, id: this.id, type: "Update" };
      }

      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    isCreateDocument() {
      return this.id === "create";
    },
    title() {
      let result = this.lang.toolbarEditUnit;
      if (this.isCreateDocument) {
        result = this.lang.toolbarCreateUnit;
      }
      return result;
    },
    selectedUnit() {
      return this.$store.getters["unit/selectedUnit"];
    },
    sharedCustomers() {
      return this.$store.getters["shared/customerCustomers"];
    },
    unitResidents() {
      return this.$store.getters["unitResident/unitResidents"];
    },
    totalPage() {
      return this.$store.getters["unitResident/totalPage"];
    },
    currentPage() {
      return this.$store.getters["unitResident/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    activeTab() {
      let result = this.activeTabIndex;
      return result;
    },
    ownerLength() {
      let result = this.owners.length;
      if (this.selectedOwner1) result = 1;
      if (this.selectedOwner2) result = 2;
      if (this.selectedOwner3) result = 3;
      return result;
    },
    dataButtons() {
      return [
        {
          id: "editUnitProduct",
          caption: this.lang.settingUnitProduct,
          class: "mr-2 p-button-toolbar p-button-outlined",
          icon: "icon-setting",
          iconColor: "primary",
          action: "gotoSettingUnitProduct",
          isShow: true,
          isDisable: this.isCreateDocument,
        },
        {
          id: "saveUnit",
          action: "gotoSave",
          // isDisable: !this.isRoleUpdate,
          type: "save"
        },
      ];
    },
    dialogDataButtons() {
      if (this.typeButtonDialog == "error" || this.typeButtonDialog == "success") {
        return [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
        ];
      } else if (this.typeButtonDialog == "addRentalResident") {
        return [
          {
            id: "close",
            caption: this.lang.buttonDialogCancel,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "addRentalResident",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "addRentalResident",
          },
        ];
      } else if (this.typeButtonDialog == "updateRentalResident") {
        return [
          {
            id: "close",
            caption: this.lang.buttonDialogCancel,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "updateRentalResident",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "updateRentalResident",
          },
        ];
      } else {
        return [
          {
            id: "close",
            caption: this.lang.buttonDialogCancel,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "confirm",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "confirm",
          },
        ];
      }
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmCOR");
    },
    isRoleSelect() {
      if (this.roleByMenu && this.roleByMenu.select == "Y") {
        return true;
      }
      return false;
    },
    isRolePrint() {
      if (this.roleByMenu && this.roleByMenu.print == "Y") {
        return true;
      }
      return false;
    },
    isRoleUpdate() {
      if (this.roleByMenu && this.roleByMenu.update == "Y") {
        return true;
      }
      return false;
    },
    selectedStartDate() {
      let startDate = null;
      if (this.startDate) {
        startDate = this.formatDateToISOString(this.startDate);
      } else {
        startDate = this.formatDateToISOString(new Date());
      }
      return startDate;
    },
    selectedEndDate() {
      let endDate = null;
      if (this.endDate) {
        endDate = this.formatDateToISOString(this.endDate);
      } else {
        endDate = this.formatDateToISOString(new Date());
      }
      return endDate;
    },
    isRoleCreate() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    project() {
      return this.$store.getters["masterProject/project"];
    },
    messageErrorAddressNo(){
      let message = this.lang.validateAddressNo;
      if(this.isAddressNoErrorLimit){
        message = this.lang.validateAddressNoLimit;
      }
      return message;
    }
  },
  watch: {
    isSharedOwnerReady() {
      this.checkShouldSetData();
    },
    // isSharedRentalReady() {
    //   this.checkShouldSetData();
    // },
    isSharedResidentReady() {
      this.checkShouldSetData();
    },  
    selectedUnit() {
      this.checkShouldSetData();
    },
    isSharedRoleByMenuReady() {
      this.checkShouldSetData();
    }, 
    // selectedOwner1(curValue) {
    //   if (curValue && curValue.id == "add") {
    //     this.$router.push(`/unit/resident/${this.id}/create`);
    //   }
    // },
    // selectedOwner2(curValue) {
    //   if (curValue && curValue.id == "add") {
    //     this.$router.push(`/unit/resident/${this.id}/create`);
    //   }
    // },
    // selectedOwner3(curValue) {
    //   if (curValue && curValue.id == "add") {
    //     this.$router.push(`/unit/resident/${this.id}/create`);
    //   }
    // },
    // selectAddMember(curValue) {
    //   if (curValue == 1) this.addResident();
    //   else this.addRental();
    // },
    payload(curValue) {
      if (this.objToCompare) this.compareObject(this.objToCompare, curValue);
    },
  },
};
</script>
