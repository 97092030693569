export default {
    apBooks(state) {
      return state.apBook.apBooks;
    },
    apBookCurrentPage(state) {
      return state.apBook.currentPage; 
    },
    apBookNextPage(state) {
      return state.apBook.currentPage + 1;
    },
    apBookTotalPage(state) {
      return state.apBook.totalPage;
    },
    apBookIsLastPage(_, getters) {
      return (getters.apBookCurrentPage === getters.apBookTotalPage) && (getters.apBookTotalPage !== 0);
    }
};
  