<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>
    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateStart">{{ lang.dateStart }}</label>
          <PvCalendar
            id="dateStart"
            v-model="dateStart"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateStartError }"
            @date-select="dateStartError = false"
          />
          <small v-if="dateStartError" id="unit-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="dateEnd">{{ lang.to }}</label>
          <PvCalendar
            id="dateEnd"
            v-model="dateEnd"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateEndError }"
            @date-select="dateEndError = false"
          />
          <small v-if="dateEndError" id="unit-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="unitStart">{{ lang.unitStart }}</label>
          <base-unit-auto-complete
            id="unit"
            v-model="selectedUnitStart"
            class="inputfield w-full mt-1"
            @load-finish="unitDataReady"
            :class="{ 'p-invalid': unitStartError }"
            @item-select="unitStartError = false"
          />
          <small v-if="unitStartError" id="unit-help" class="p-error">{{
            lang.validateNoUnitSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="unitEnd">{{ lang.to }}</label>
          <base-unit-auto-complete
            id="unit"
            v-model="selectedUnitEnd"
            class="inputfield w-full mt-1"
            @load-finish="unitDataReady"
            :class="{ 'p-invalid': unitEndError }"
            @item-select="unitEndError = false"
          />
          <small v-if="unitEndError" id="unit-help" class="p-error">{{
            lang.validateNoUnitSelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="productStart">{{ lang.productStart }}</label>
          <base-product-ar-auto-complete
            id="productStart"
            v-model="selectedProductStart"
            class="inputfield w-full mt-1"
            @load-finish="productDataReady"
            :class="{ 'p-invalid': productStartError }"
            @item-select="productStartError = false"
            filterType="ignorePenalty"
          />
          <small v-if="productStartError" id="unit-help" class="p-error">{{
            lang.validateNoProductSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="productEnd">{{ lang.to }}</label>
          <base-product-ar-auto-complete
            id="productEnd"
            v-model="selectedProductEnd"
            class="inputfield w-full mt-1"
            @load-finish="productDataReady"
            :class="{ 'p-invalid': productEndError }"
            @item-select="productEndError = false"
            filterType="ignorePenalty"
          />
          <small v-if="productEndError" id="unit-help" class="p-error">{{
            lang.validateNoProductSelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="penaltyStart">{{ lang.penaltyStart }}</label>
          <base-product-ar-auto-complete
            id="penaltyStart"
            v-model="selectedPenaltyStart"
            class="inputfield w-full mt-1"
            @load-finish="productDataReady"
            :class="{ 'p-invalid': penaltyStartError }"
            @item-select="penaltyStartError = false"
            filterType="penalty"
          />
          <small v-if="penaltyStartError" id="unit-help" class="p-error">{{
            lang.validateNoญenaltySelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="penaltyEnd">{{ lang.to }}</label>
          <base-product-ar-auto-complete
            id="penaltyEnd"
            v-model="selectedPenaltyEnd"
            class="inputfield w-full mt-1"
            @load-finish="productDataReady"
            :class="{ 'p-invalid': penaltyEndError }"
            @item-select="penaltyEndError = false"
            filterType="penalty"
          />
          <small v-if="penaltyEndError" id="unit-help" class="p-error">{{
            lang.validateNoPenaltySelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="periodStart">{{ lang.accountPeriodStart }}</label>
          <PvInputMask
            id="periodStart"
            mask="99/9999"
            v-model.trim="periodStart"
            class="inputfield w-full mt-1"
            placeholder="MM/YYYY(พ.ศ.)"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="periodEnd">{{ lang.to }}</label>
          <PvInputMask
            id="periodEnd"
            mask="99/9999"
            v-model.trim="periodEnd"
            class="inputfield w-full mt-1"
            placeholder="MM/YYYY(พ.ศ.)"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText
            id="fileNameText"
            type="text"
            class="inputfield w-full mt-1"
            v-model="fileNameText"
          />
        </div>
      </section>
    </transition>
    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
        </div>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useTracking from "../../../hooks/tracking";
import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "toast-request"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { getPreviewBlob, getDownloadBlob } = useGetFormPrint();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getPreviewBlob,
      getDownloadBlob,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      dateStart: null,
      dateEnd: null,
      dateStartError: false,
      dateEndError: false,
      unitStartError: false,
      unitEndError: false,
      selectedUnitStart: null,
      selectedUnitEnd: null,
      isSharedUnitReady: false,
      selectedProductStart: null,
      selectedProductEnd: null,
      productStartError: false,
      productEndError: false,
      selectedPenaltyStart: null,
      selectedPenaltyEnd: null,
      penaltyStartError: false,
      penaltyEndError: false,
      isSharedProductReady: false,
      periodStart: "",
      periodEnd: "",
      type: "PDF",
      fileNameText: "penalty",
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    async initiateData() {
      let date = new Date();
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.dateStartError = false;
      this.dateEndError = false;
      this.selectedUnitStart = null;
      this.selectedUnitEnd = null;
      this.isSharedUnitReady = false;
      this.selectedProductStart = null;
      this.selectedProductEnd = null;
      this.selectedPenaltyStart = null;
      this.selectedPenaltyEnd = null;
      this.isSharedProductReady = false;
      this.unitStartError = false;
      this.unitEndError = false;
      this.productStartError = false;
      this.productEndError = false;
      this.penaltyStartError = false;
      this.penaltyEndError = false;
      this.periodStart = "";
      this.periodEnd = "";
      this.type = "PDF";
      this.fileNameText = "penalty";
    },
    validateData() {
      let returnStatus = false;
      if (this.selectedDateStart == null) {
        this.dateStartError = true;
        returnStatus = true;
      }
      if (this.selectedDateEnd == null) {
        this.dateEndError = true;
        returnStatus = true;
      }
      // if (this.selectedUnitStart == null) {
      //   this.unitStartError = true;
      //   returnStatus = true;
      // }
      // if (this.selectedUnitEnd == null) {
      //   this.unitEndError = true;
      //   returnStatus = true;
      // }
      //   if (this.selectedProductStart == null) {
      //     this.productStartError = true;
      //     return true;
      //   }
      //   if (this.selectedProductEnd == null) {
      //     this.productEndError = true;
      //     return true;
      //   }
      return returnStatus;
    },
    unitDataReady() {
      this.isSharedUnitReady = true;
      this.checkShouldSetData();
    },
    productDataReady() {
      this.isSharedProductReady = true;
      this.checkShouldSetData();
    },
    async checkShouldSetData() {
      if (this.isSharedUnitReady && this.isSharedProductReady) {
        await this.setData();
        this.setLoading(false);
      }
    },
    async setData() {
      // this.setLoading(true);
      this.selectedUnitStart = this.units[0];
      this.selectedUnitEnd = this.units[this.units.length - 1];
      this.selectedProductStart = this.products[0];
      this.selectedProductEnd = this.products[this.products.length - 1];
      this.selectedPenaltyStart = this.penaltyProducts[0];
      this.selectedPenaltyEnd = this.penaltyProducts[this.penaltyProducts.length - 1];
    },
    async download(typeReport) {
      this.type = typeReport;
      if (this.validateData()) {
        return;
      }

      this.setLoading(true);
      try {
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    title() {
      if (this.$store.getters.selectedLanguage === "TH") return this.reportObj.nameThai;
      else return this.reportObj.nameEnglish;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    units() {
      return this.$store.getters["shared/unitUnits"];
    },
    products() {
      let itemList = this.$store.getters["shared/productArProducts"];
      if(itemList.length > 0) {
        itemList = itemList.filter((product) => product.code.substr(0, 1) != "Z")
      }
      return itemList;
    },
    penaltyProducts() {
      let itemList = this.$store.getters["shared/productArProducts"];
      if(itemList.length > 0) {
        itemList = itemList.filter((product) => product.code.substr(0, 1) == "Z")
      }
      return itemList;
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
    fileType() {
      let fileType =
        this.type == "EXCEL"
          ? "_excel.xlsx"
          : (this.type == "JASPEREXCEL")
          ? ".xlsx"
          : ".pdf";
      return fileType;
    },
    fileName() {
      let fileName = this.type == "PDF" ? ".pdf" : ".xlsx";
      return this.fileNameText + fileName;
    },
    payload() {
      let payload = {
        ProjectId: this.selectedProject.id,
        DateStart: this.selectedDateStart,
        DateEnd: this.selectedDateEnd,
        UnitCodeStart: this.selectedUnitStart ? this.selectedUnitStart.code : this.units[0].code,
        UnitCodeEnd: this.selectedUnitEnd ? this.selectedUnitEnd.code : this.units[this.units.length - 1].code,
        ProductCodeStart: this.selectedProductStart ? this.selectedProductStart.code : this.products[0].code,
        ProductCodeEnd: this.selectedProductEnd ? this.selectedProductEnd.code : this.products[this.products.length - 1].code,
        PenaltyCodeStart: this.selectedPenaltyStart ? this.selectedPenaltyStart.code : this.penaltyProducts[0].code,
        PenaltyCodeEnd: this.selectedPenaltyEnd ? this.selectedPenaltyEnd.code : this.penaltyProducts[this.penaltyProducts.length - 1].code,
        PeriodStart: this.periodStart,
        PeriodEnd: this.periodEnd,
        IsExcel: "N",
        IsForApi: "true",
        FileName: this.fileName,
        JasperPath: this.reportObj.path + this.fileType,
        DocType: this.type,
        UserId: this.userId,
        PrintBy: this.userName,
        RequestId: 0,
        ReportName: "Penalty",
      }
      return payload;
    },
  },
  watch: {
    // isSharedUnitReady() {
    //   this.checkShouldSetData();
    // },
    // isSharedProductReady() {
    //   this.checkShouldSetData();
    // },
    reportObj() {
      this.initiateData();
    },
  },
};
</script>
