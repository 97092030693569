export default {
  formDetails(state) {
    return state.formDetails;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
};
