export default {
  async getTransferSlips(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/TransferSlip";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }
    if (payload.page) {
      url = url + "&page=" + payload.page;
    }
    if (payload.projectId) {
      url = url + "&projectId=" + payload.projectId;
    }    
    if (payload.status) {
      url = url + "&status=" + payload.status;
    }
    if (payload.dateStart && payload.dateEnd) {
      url = url + "&dateStart=" + payload.dateStart + "&dateEnd=" + payload.dateEnd;
    }
    if (payload.invoiceCode) {
      url = url + "&invoiceCode=" + payload.invoiceCode;
    }
    if (payload.unitCode) {
      url = url + "&unitCode=" + encodeURIComponent(payload.unitCode);
    }
    if (payload.unitAddressNo) {
      url = url + "&unitAddressNo=" + encodeURIComponent(payload.unitAddressNo);
    }
    if (payload.customerName) {
      url = url + "&customerName=" + payload.customerName;
    }
    if (payload.totalAmount) {
      url = url + "&totalAmount=" + payload.totalAmount;
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get tax");
      throw error;
    }
    // console.log("getTransferSlips_responseData=",responseData);
    context.commit("setTransferSlips", responseData);
  },
  async getTransferSlip(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/TransferSlip";

    if (payload) {
      url = url + "/" + payload;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get credit note");
      throw error;
    }
    // console.log(responseData.data);
    context.commit("setTransferSlip", responseData.data);
  },
	async addEditTransferSlip(context, payload){
		let url = process.env.VUE_APP_BACKEND_URL + `/TransferSlip/${payload.typeAddEdit}`;
		const fallbackErrorMessage = `Failed to ${payload.typeAddEdit} productAp`
		const token = context.rootGetters.token;
	
		const response = await fetch(url, {
		  method: payload.typeAddEdit === 'Add' ? 'post' : 'put',
		  headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + token,
		  },
	
		  body: JSON.stringify(payload),
		});
	
		const responseData = await response.json();
	
		if (!response.ok) {
		  // console.log(responseData);
		  let message = null
		  if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
			message = responseData.title;
			for (const key in responseData.errors) {
			  message += ", " + key.replace("$.","")
			}
		  } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
			message = responseData.message
		  }
		  const error = new Error(message || fallbackErrorMessage);
		  throw error;
		}
		// console.log(responseData);
	
		context.commit("setTransferSlip", responseData.data);
	},







};
