import { useStore } from "vuex";
import { computed, ref } from "vue";
import useLang from "../hooks/lang.js";

const d = new Date();
let seconds = computed(() => d.getTime());

export default function useGetRouteMenu() {
    const store = useStore();

    const selectedLanguage = computed(() => store.getters.selectedLanguage);
    changeMenuHeaderLanguage(selectedLanguage.value, store);
    changeFooterMenuLanguage(selectedLanguage.value, store);

    return {
        changeMenuHeaderLanguage,
        changeFooterMenuLanguage
    };
}

function getPayload(store) {
    // const store = useStore();
    const selectedProject = computed(() => store.getters.selectedProject);
    const tokenERP = computed(() => store.getters.token);
    const tokenLosaPM = computed(() => store.getters.tokenLosaPM);
    const userId = computed(() => store.getters.userId);
    const payload = {
        selectedProject: selectedProject.value,
        tokenERP: tokenERP.value,
        tokenLosaPM: tokenLosaPM.value,
        userId: userId.value,
    }

    return payload;
}

function changeMenuHeaderLanguage(language, store) {
    const { lang } = useLang();
    const payload = getPayload(store)
    let menuItems = ref(null);
    let menu = {};
    if (language === "TH") {
        menu = { ...lang.th.menuV2 };
    } else {
        menu = { ...lang.en.menuV2 };
    }


    menuItems.value = [
        {
            id: "1",
            reference: "1",
            label: "",
            items: [
                {
                    id: "1.1",
                    reference: "1",
                    label: menu.projectOverview,
                    icon: "icon-chart-circle",
                    to: "/home",
                    type: "",
                    params: [],
                    name:"home",
                },
                {
                    id: "1.2",
                    reference: "1",
                    label: menu.registerApp,
                    icon: "icon-mobiles",
                    to: "/dashboard/mobile-register",
                    type: "",
                    params: [],
                    name: "dashboard-mobile-register"
                },
            ],
        },
        {
            id: "2",
            reference: "2",
            label: menu.manageResidents,
            items: [
                {
                    id: "2.1",
                    reference: "2",
                    label: menu.residents,
                    icon: "icon-home",
                    to: "",
                    items: [
                        {
                            id: "2.1.1",
                            reference: "2.1",
                            label: menu.unit,
                            to: "/unit",
                            type: "",
                            params: [],
                            name: "unit"
                        },
                        {
                            id: "2.1.2",
                            reference: "2.1",
                            label: menu.customer,
                            to: "/resident",
                            type: "",
                            params: [],
                            name: "resident"
                        },
                        {
                            id: "2.1.3",
                            reference: "2.1",
                            label: menu.customerGroup,
                            to: "/customer-group",
                            type: "",
                            params: [],
                            name: "customer-group"
                        },
                        // {
                        //     id: "2.1.4",
                        //     reference: "2.1",
                        //     label: menu.debtfree,
                        //     to: "/debt-free",
                        //     type: "",
                        //     params: [],
                        //     name: "debt-free"
                        // },
                        {
                            id: "2.1.4",
                            reference: "2.1",
                            label: menu.debtfree,
                            to: "",
                            items: [
                                {
                                    id: "2.1.4.1",
                                    reference: "2.1.4",
                                    label: menu.debtfreeRequest,
                                    to: "/debt-free/request",
                                    type: "",
                                    params: [],
                                    name: "debt-free-request"
                                },
                                {
                                    id: "2.1.4.2",
                                    reference: "2.1.4",
                                    label: menu.debtfreeApprove,
                                    to: "/debt-free/approve",
                                    type: "",
                                    params: [],
                                    name: "debt-free-approve"
                                },
                                {
                                    id: "2.1.4.3",
                                    reference: "2.1.4",
                                    label: menu.debtfreePrint,
                                    to: "/debt-free/print",
                                    type: "",
                                    params: [],
                                    name: "debt-free-print"
                                },
                                {
                                    id: "2.1.4.4",
                                    reference: "2.1.4",
                                    label: menu.debtfreeFollowUp,
                                    to: "/debt-free/follow-up",
                                    type: "",
                                    params: [],
                                    name: "debt-free-follow-up"
                                },
                                {
                                    id: "2.1.4.5",
                                    reference: "2.1.4",
                                    label: menu.debtfreeReport,
                                    to: "/report/DebtFree",
                                    type: "",
                                    params: [],
                                    name: "report-DebtFree"
                                },
                            ],
                        },
                        {
                            id: "2.1.5",
                            reference: "2.1",
                            label: menu.invitationCode,
                            to: `${process.env.VUE_APP_LOSWEB_URL}/los-web-admin/onboarding/invitation-onboarding?projectId=${payload.selectedProject.id}&menu=none&${seconds.value}`,
                            type: "ifarame",
                            params: [],
                            name: "invitation-code"
                        },
                    ],
                },
            ],
        },
        {
            id: "3",
            reference: "3",
            label: menu.financialAccount,
            items: [
                {
                    id: "3.1",
                    reference: "3",
                    label: menu.arSystem,
                    icon: "icon-coins",
                    to: "",
                    items: [
                        {
                            id: "3.1.1",
                            reference: "3.1",
                            label: menu.meter,
                            to: "",
                            items: [
                                {
                                    id: "3.1.1.1",
                                    reference: "3.1.1",
                                    label: menu.meterUsageWater,
                                    to: "/meter-usage/water/W",
                                    type: "",
                                    params: [],
                                    name: "meter-usage-water"
                                },
                                {
                                    id: "3.1.1.2",
                                    reference: "3.1.1",
                                    label: menu.meterUsageElectric,
                                    to: "/meter-usage/electricity/E",
                                    type: "",
                                    params: [],
                                    name: "meter-usage-electricity"
                                },
                                {
                                    id: "3.1.1.3",
                                    reference: "3.1.1",
                                    label: menu.meterUsageCoolingAir,
                                    to: "/meter-usage/cooling-air/A",
                                    type: "",
                                    params: [],
                                    name: "meter-usage-cooling-air"
                                },
                                {
                                    id: "3.1.1.4",
                                    reference: "3.1.1",
                                    label: menu.meterUsageReport,
                                    to: "/report/MeterUsage",
                                    type: "",
                                    params: [],
                                    name: "report-MeterUsage"
                                },
                            ],
                        },
                        {
                            id: "3.1.2",
                            reference: "3.1",
                            label: menu.invoiceSystem,
                            to: "",
                            items: [
                                {
                                    id: "3.1.2.1",
                                    reference: "3.1.2",
                                    label: menu.invoice,
                                    to: "/ar/invoice",
                                    type: "",
                                    params: [],
                                    name: "ar-invoice"
                                },
                                {
                                    id: "3.1.2.2",
                                    reference: "3.1.2",
                                    label: menu.calculateInvoice,
                                    to: "/ar/calculate-invoice",
                                    type: "",
                                    params: [],
                                    name: "ar-calculate-invoice"
                                },
                                {
                                    id: "3.1.2.3",
                                    reference: "3.1.2",
                                    label: menu.outstandingLetter,
                                    to: "/ar/invoice/outstanding-letter",
                                    type: "",
                                    params: [],
                                    name: "ar-outstanding-letter"
                                },
                            ],
                        },
                        {
                            id: "3.1.3",
                            reference: "3.1",
                            label: menu.noticeSystem,
                            to: "",
                            items: [
                                {
                                    id: "3.1.3.1",
                                    reference: "3.1.3",
                                    label: menu.notice,
                                    to: "/ar/notice",
                                    type: "",
                                    params: [],
                                    name: "ar-notice"
                                },
                                {
                                    id: "3.1.3.2",
                                    reference: "3.1.3",
                                    label: menu.calculateNotice,
                                    to: "/ar/calculate-notice",
                                    type: "",
                                    params: [],
                                    name: "ar-calculate-notice"
                                },
                                {
                                    id: "3.1.3.3",
                                    reference: "3.1.3",
                                    label: menu.noticeReport,
                                    to: "/report/Notice",
                                    type: "",
                                    params: [],
                                    name: "report-Notice"
                                },
                                {
                                    id: "3.1.3.4",
                                    reference: "3.1.3",
                                    label: menu.noticeCustomReport,
                                    to: "/ar/notice-custom-report",
                                    type: "",
                                    params: [],
                                    name: "notice-custom-report"
                                },
                            ],
                        },
                        {
                            id: "3.1.4",
                            reference: "3.1",
                            label: menu.receiptSystem,
                            to: "",
                            items: [
                                {
                                    id: "3.1.4.1",
                                    reference: "3.1.4",
                                    label: menu.receipt,
                                    to: "/ar/receipt",
                                    type: "",
                                    params: [],
                                    name: "ar-receipt"
                                },
                                {
                                    id: "3.1.4.2",
                                    reference: "3.1.4",
                                    label: menu.transferSlip,
                                    to: "/ar/transfer-slip",
                                    type: "",
                                    params: [],
                                    name: "transfer-slip"
                                },
                                {
                                    id: "3.1.4.3",
                                    reference: "3.1.4",
                                    label: menu.receiptReport,
                                    to: "/report/Receipt",
                                    type: "",
                                    params: [],
                                    name: "report-Receipt"
                                },
                                {
                                    id: "3.1.4.4",
                                    reference: "3.1.4",
                                    label: menu.deposit,
                                    to: "/ar/deposit",
                                    type: "",
                                    params: [],
                                    name: "ar-deposit"
                                },
                                {
                                    id: "3.1.4.5",
                                    reference: "3.1.4",
                                    label: menu.creditNote,
                                    to: "/ar/credit-note",
                                    type: "",
                                    params: [],
                                    name: "ar-credit-note"
                                },
                                {
                                    id: "3.1.4.6",
                                    reference: "3.1.4",
                                    label: menu.bankAR,
                                    to: "/ar/bank-ar",
                                    type: "",
                                    params: [],
                                    name: "ar-bank-ar"
                                },
                                {
                                    id: "3.1.4.7",
                                    reference: "3.1.4",
                                    label: menu.usePrepaidAndDeposit,
                                    to: "/ar/use-prepaid-and-deposit",
                                    type: "",
                                    params: [],
                                    name: "ar-use-prepaid-and-deposit"
                                },
                            ],
                        },
                        {
                            id: "3.1.5",
                            reference: "3.1",
                            label: menu.refundSystem,
                            to: "",
                            items: [
                                {
                                    id: "3.1.5.1",
                                    reference: "3.1.5",
                                    label: menu.prepaidRefund,
                                    to: "/ar/prepaid-refund",
                                    type: "",
                                    params: [],
                                    name: "ar-prepaid-refund"
                                },
                                {
                                    id: "3.1.5.2",
                                    reference: "3.1.5",
                                    label: menu.refundGuarantee,
                                    to: "/ar/refund-guarantee",
                                    type: "",
                                    params: [],
                                    name: "ar-refund-guarantee"
                                },
                            ]
                        },
                        {
                            id: "3.1.6",
                            reference: "3.1",
                            label: menu.stockKeycardSystem,
                            to: "",
                            items: [
                                {
                                    id: "3.1.6.1",
                                    reference: "3.1.6",
                                    label: menu.stockKeycard,
                                    to: "/ar/stock-keycard",
                                    type: "",
                                    params: [],
                                    name: "ar-stock-keycard"
                                },
                                {
                                    id: "3.1.6.2",
                                    reference: "3.1.6",
                                    label: menu.stockKeycardReport,
                                    to: "/report/StockKeycard",
                                    type: "",
                                    params: [],
                                    name: "report-StockKeycard"
                                },
                            ]
                        },
                        {
                            id: "3.1.7",
                            reference: "3.1",
                            label: menu.arReport,
                            to: "/report/AR",
                            type: "",
                            params: [],
                            name: "report-AR"
                        },
                        // {
                        //     id: "3.1.7",
                        //     reference: "3.1",
                        //     label: menu.checkTransfer,
                        //     to: "",
                        //     type: "",
                        //     params: [],
                        // }
                    ],
                },
                {
                    id: "3.2",
                    reference: "3",
                    label: menu.apSystem,
                    icon: "icon-bank",
                    to: "",
                    items: [
                        {
                            id: "3.2.1",
                            reference: "3.2",
                            label: menu.purchaseRequisition,
                            to: "/ap/purchase-requisition",
                            type: "",
                            params: [],
                            name: "ap-purchase-requisition"
                        },
                        {
                            id: "3.2.2",
                            reference: "3.2",
                            label: menu.purchaseOrder,
                            to: "/ap/purchase-order",
                            type: "",
                            params: [],
                            name: "ap-purchase-order"
                        },
                        {
                            id: "3.2.3",
                            reference: "3.2",
                            label: menu.purchaseInvoice,
                            to: "/ap/purchase-invoice",
                            type: "",
                            params: [],
                            name: "ap-purchase-invoice"
                        },
                        {
                            id: "3.2.4",
                            reference: "3.2",
                            label: menu.purchaseReceipt,
                            to: "/ap/purchase-receipt",
                            type: "",
                            params: [],
                            name: "ap-purchase-receipt"
                        },
                        // {
                        //     id: "3.2.5",
                        //     reference: "3.2",
                        //     label: menu.paymentRequest,
                        //     to: "",
                        //     items: [
                        //         {
                        //             id: "3.2.5.1",
                        //             reference: "3.2.5",
                        //             label: menu.paymentRequestManagement,
                        //             to: "/payment-request",
                        //             type: "",
                        //             params: [],
                        //             name: "payment-request"
                        //         },
                        //         {
                        //             id: "3.2.5.2",
                        //             reference: "3.2.5",
                        //             label: menu.paymentRequestReport,
                        //             to: "/report/payment-request",
                        //             type: "",
                        //             params: [],
                        //             name: "report-payment-request"
                        //         },
                        //         {
                        //             id: "3.2.5.3",
                        //             reference: "3.2.5",
                        //             label: menu.paymentRequestType,
                        //             to: "/payment-request-type",
                        //             type: "",
                        //             params: [],
                        //             name: "payment-request-type"
                        //         },
                        //     ]
                        // },
                        {
                            id: "3.2.6",
                            reference: "3.2",
                            label: menu.bankAP,
                            to: "/ap/bank-ap",
                            type: "",
                            params: [],
                            name: "ap-bank-ap"
                        },
                        {
                            id: "3.2.7",
                            reference: "3.2",
                            label: menu.withholdingTax,
                            to: "/ap/withholding-tax",
                            type: "",
                            params: [],
                            name: "ap-withholding-tax"
                        },
                        {
                            id: "3.2.8",
                            reference: "3.2",
                            label: menu.apReport,
                            to: "/report/AP",
                            type: "",
                            params: [],
                            name: "report-AP"
                        },
                        {
                            id: "3.2.9",
                            reference: "3.2",
                            label: menu.withholdingTaxReport,
                            to: "/report/WithholdingTax",
                            type: "",
                            params: [],
                            name: "report-WithholdingTax"
                        },
                    ],
                },
                {
                    id: "3.3",
                    reference: "3",
                    label: menu.glSystem,
                    icon: "icon-calculator",
                    to: "",
                    items: [
                        {
                            id: "3.3.1",
                            reference: "3.3",
                            label: menu.glPettyCash,
                            to: "/gl/pettycash",
                            type: "",
                            params: [],
                            name: "gl-pettycash"
                        },
                        {
                            id: "3.3.2",
                            reference: "3.3",
                            label: menu.pettyCashReport,
                            to: "/report/PC",
                            type: "",
                            params: [],
                            name: "report-PC"
                        },
                        {
                            id: "3.3.3",
                            reference: "3.3",
                            label: menu.glInvoice,
                            to: "/gl/invoice",
                            type: "",
                            params: [],
                            name: "gl-invoice"
                        },
                        {
                            id: "3.3.4",
                            reference: "3.3",
                            label: menu.glReceipt,
                            to: "/gl/receipt",
                            type: "",
                            params: [],
                            name: "gl-receipt"
                        },
                        {
                            id: "3.3.5",
                            reference: "3.3",
                            label: menu.glAccounting,
                            to: "/gl/accounting",
                            type: "",
                            params: [],
                            name: "gl-accounting"
                        },
                        {
                            id: "3.3.6",
                            reference: "3.3",
                            label: menu.closingEntry,
                            to: "/gl/closing-entry",
                            type: "",
                            params: [],
                            name: "closing-entry"
                        },
                        {
                            id: "3.3.7",
                            reference: "3.3",
                            label: menu.glReport,
                            to: "/report/GL",
                            type: "",
                            params: [],
                            name: "report-GL"
                        },
                    ],
                },
                {
                    id: "3.4",
                    reference: "3",
                    label: menu.assetSystem,
                    icon: "icon-chair",
                    to: "",
                    items: [
                        {
                            id: "3.4.1",
                            reference: "3.4",
                            label: menu.assetRegister,
                            to: "/asset/register",
                            type: "",
                            params: [],
                            name: "asset-register"
                        },
                        {
                            id: "3.4.2",
                            reference: "3.4",
                            label: menu.reportAsset,
                            to: "/report/Asset",
                            type: "",
                            params: [],
                            name: "report-Asset"
                        },
                    ]
                },
                // {
                //     id: "3.5",
                //     reference: "3",
                //     label: menu.budget,
                //     icon: "icon-moneybag",
                //     to: "",
                //     items: [ 
                //         {
                //             id: "3.5.1",
                //             reference: "3.5",
                //             label: menu.manageBudget,
                //             to: "/budget",
                //             type: "",
                //             params: [],
                //             name: "budget"
                //         },
                //         {
                //             id: "3.5.2",
                //             reference: "3.5",
                //             label: menu.budgetReport,
                //             to: "/report/budget",
                //             type: "",
                //             params: [],
                //             name: "report-budget"
                //         },
                //     ]
                // }
            ],
        },
        {
            id: "4",
            reference: "4",
            label: menu.setting,
            items: [
                {
                    id: "4.1",
                    reference: "4",
                    label: menu.financialAccount,
                    icon: "icon-invoice-hand-select",
                    to: "",
                    items: [
                        {
                            id: "4.1.1",
                            reference: "4.1",
                            label: menu.meter,
                            to: "/meter/W",
                            type: "",
                            params: [],
                            name: "master-meter"
                        },
                        {
                            id: "4.1.2",
                            reference: "4.1",
                            label: menu.meterUsageSetting,
                            to: "/meter-usage-seting",
                            type: "",
                            params: [],
                            name: "meter-usage-seting"
                        },
                        {
                            id: "4.1.3",
                            reference: "4.1",
                            label: menu.productAR,
                            to: "/ar-product",
                            type: "",
                            params: [],
                            name: "ar-product"
                        },
                        {
                            id: "4.1.4",
                            reference: "4.1",
                            label: menu.policy,
                            to: "/master/policy",
                            type: "",
                            params: [],
                            name: "master-policy"
                        },
                        {
                            id: "4.1.5",
                            reference: "4.1",
                            label: menu.projectPenaltyCalculation,
                            to: "/project-penaltycalculation/edit",
                            type: "",
                            params: [],
                            name: "project-penaltycalculation-edit"
                        },
                        {
                            id: "4.1.6",
                            reference: "4.1",
                            label: menu.supplier,
                            to: "/supplier",
                            type: "",
                            params: [],
                            name: "supplier"
                        },
                        {
                            id: "4.1.7",
                            reference: "4.1",
                            label: menu.productAP,
                            to: "/product-ap",
                            type: "",
                            params: [],
                            name: "product-ap"
                        },
                        {
                            id: "4.1.8",
                            reference: "4.1",
                            label: menu.controlStockKeycard,
                            to: "/control-stock-keycard",
                            type: "",
                            params: [],
                            name: "control-stock-keycard"
                        },
                        {
                            id: "4.1.9",
                            reference: "4.1",
                            label: menu.accountSetup,
                            to: "",
                            items: [
                                {
                                    id: "4.1.8.1",
                                    reference: "4.1.8",
                                    label: menu.accountChart,
                                    to: "/account-chart",
                                    type: "",
                                    params: [],
                                    name: "account-chart"
                                },
                                {
                                    id: "4.1.8.2",
                                    reference: "4.1.8",
                                    label: menu.bookbank,
                                    to: "/book-bank",
                                    type: "",
                                    params: [],
                                    name: "book-bank"
                                },
                                {
                                    id: "4.1.8.3",
                                    reference: "4.1.8",
                                    label: menu.accountBook,
                                    to: "/account-book",
                                    type: "",
                                    params: [],
                                    name: "account-book"
                                },
                                // {
                                //     id: "4.1.8.4",
                                //     reference: "4.1.8",
                                //     label: menu.accountFormat,
                                //     to: "",
                                //     type: "",
                                //     params: [],
                                // },
                                {
                                    id: "4.1.8.4",
                                    reference: "4.1.8",
                                    label: menu.accountFrequently,
                                    to: "/accounting-frequently",
                                    type: "",
                                    params: [],
                                    name: "accounting-frequently"
                                },
                                {
                                    id: "4.1.8.5",
                                    reference: "4.1.8",
                                    label: menu.accountPolicy,
                                    to: "/accounting-policy",
                                    type: "",
                                    params: [],
                                    name: "accounting-policy"
                                },
                            ],
                        },
                        {
                            id: "4.1.10",
                            reference: "4.1",
                            label: menu.signatureSetup,
                            to: "",
                            items: [
                                {
                                    id: "4.1.10.1",
                                    reference: "4.1.10",
                                    label: menu.signatureManagement,
                                    to: "/signature-management",
                                    type: "",
                                    params: [],
                                    name: "signature-management"
                                },
                                {
                                    id: "4.1.10.2",
                                    reference: "4.1.10",
                                    label: menu.reportSignator,
                                    to: "/signature",
                                    type: "",
                                    params: [],
                                    name: "signature"
                                },
                            ],
                        },
                    ],
                },
                {
                    id: "4.2",
                    reference: "4",
                    label: menu.projectSetup,
                    icon: "icon-high-rise-building",
                    to: "",
                    items: [
                        {
                            id: "4.2.1",
                            reference: "4.2",
                            label: menu.projectInformation,
                            to: "/master/project",
                            type: "",
                            params: [],
                            name: "master-project"
                        },
                        {
                            id: "4.2.2",
                            reference: "4.2",
                            label: menu.projectDocument,
                            to: "/project-document",
                            type: "",
                            params: [],
                            name: "project-document"
                        },
                        {
                            id: "4.2.3",
                            reference: "4.2",
                            label: menu.announceAdvertising,
                            to: "/master/product-advertisement/edit",
                            type: "",
                            params: [],
                            name: "product-advertisement-edit"
                        },
                        {
                            id: "4.2.4",
                            reference: "4.2",
                            label: menu.phoneBook,
                            to: "/phone-book",
                            type: "",
                            params: [],
                            name: "phone-book"
                        },
                    ],
                },
            ],
        },
        {
            id: "5",
            reference: "5",
            label: menu.other,
            items: [
                {
                    id: "5.1",
                    reference: "5",
                    label: menu.parcel,
                    icon: "icon-package-box",
                    to: `${process.env.VUE_APP_LASTMILE_URL}/auth/erp?token=${payload.tokenERP}&project_id=${payload.selectedProject.id}`,
                    type: "newTab",
                    params: [],
                    name: "parcel"
                },
                {
                    id: "5.2",
                    reference: "5",
                    label: menu.parcelReport,
                    icon: "icon-delivery-truck-fast",
                    to: `${process.env.VUE_APP_FIRSTMILE_URL}/los-web-admin/dashboard/first-mile-report-niti-dashboard?projectId=${payload.selectedProject.id}&menu=none&${seconds.value}`,
                    type: "ifarame",
                    params: [],
                    name: "parcel-report"
                },
                {
                    id: "5.3",
                    reference: "5",
                    label: menu.chat,
                    icon: "messages-chat",
                    to: `${process.env.VUE_APP_CHAT_URL}/landing_login?token=${payload.tokenERP}&user_id=${payload.userId}&project_id=${payload.selectedProject.id}`,
                    type: "newTab",
                    params: [],
                    name: "chat"
                },
                {
                    id: "5.4",
                    reference: "5",
                    label: menu.news,
                    icon: "horn-trumpet",
                    to: `${process.env.VUE_APP_ANNOUNCEMENT_URL}/landing_login?token=${payload.tokenERP}&userId=${payload.userId}&project_id=${payload.selectedProject.id}`,
                    type: "newTab",
                    params: [],
                    name: "news"
                },
                {
                    id: "5.5",
                    reference: "5",
                    label: menu.facilityBooking,
                    icon: "swimming-pool",
                    to: `${process.env.VUE_APP_FACILITYBOOKING_URL}/login?token=${payload.tokenERP}&project_id=${payload.selectedProject.id}`,
                    type: "newTab",
                    params: [],
                    name: "facility-booking"
                },
                {
                    id: "5.6",
                    reference: "5",
                    label: menu.serviceMaintenence,
                    icon: "tools-wench",
                    to: `${process.env.VUE_APP_PMS_URL}/service-maintenance/auth/erp?token=${payload.tokenERP}&project_id=${payload.selectedProject.id}`,
                    type: "newTab",
                    params: [],
                    name: "service-maintenence"
                },
                {
                    id: "5.7",
                    reference: "5",
                    label: menu.visitorControl,
                    icon: "video-camera-security",
                    to: `${process.env.VUE_APP_VISITOR_CONTROL_URL}/login?token=${payload.tokenLosaPM}&projectId=${payload.selectedProject.id}&SystemName=VisitorControl`,
                    type: "newTab",
                    params: [],
                    name: "visitor-control"
                },
                {
                    id: "5.8",
                    reference: "5",
                    label: menu.smartAudit,
                    icon: "icon-seurity",
                    to: `${process.env.VUE_APP_VISITOR_CONTROL_URL}/login?token=${payload.tokenLosaPM}&projectId=${payload.selectedProject.id}&SystemName=SmartAudit`,
                    type: "newTab",
                    params: [],
                    name: "smart-audit"
                },
            ],
        },
        {
            id: "6",
            reference: "6",
            label: menu.help,
            items: [
                {
                    id: "6.1",
                    reference: "6",
                    label: menu.manualWebService,
                    icon: "book-search",
                    to: `https://www.thelivingos.com/manual-web-version/`,
                    type: "newTab",
                    params: [],
                    name: "manual-web-service"
                },
                {
                    id: "6.2",
                    reference: "6",
                    label: menu.contactStaff,
                    icon: "headphones-microphone",
                    to: "/contact-us",
                    type: "",
                    params: [],
                    name: "contact-us"
                },
            ],
        },
    ];

    return menuItems;
}


function changeFooterMenuLanguage(language, store) {
    const { lang } = useLang();
    const payload = getPayload(store)
    let menuItems = ref(null);
    let menu = {};
    if (language === "TH") {
        menu = { ...lang.th.menuV2 };
    } else {
        menu = { ...lang.en.menuV2 };
    }

    menuItems.value = [
        {
            id: "1",
            reference: "1",
            label: menu.productAndService,
            icon: "icon-basket",
            to: `${process.env.VUE_APP_JURISTIC_MART_URL}/?token=${payload.tokenLosaPM}&${seconds.value}`,
            type: "ifarame",
            params: [],
            name: "product-service"
        },
        {
            id: "2",
            reference: "2",
            label: menu.reportRequest,
            icon: "arrow-download",
            to: "/report/request?back=home",
            type: "",
            params: [],
            name: "report-request"
        },
    ];

    return menuItems;
}