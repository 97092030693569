export default {
  async getPettyCash(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/PettyCash";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get meter");
      throw error;
    }
    // console.log("getPettyCash responseData", responseData);
    context.commit("setPettyCash", responseData);
  },

  async AddPettyCash(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + `/PettyCash/AddPettyCash`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'post',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to revert');
      throw error;
    }
    
    context.commit("setPettyCash", responseData);
  },

  async ApprovePettyCash(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + `/PettyCash/ApprovePettyCash`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'post',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to revert');
      throw error;
    }
    
    context.commit("setPettyCash", responseData);
  },
  
  async PrintPettyCash(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/PettyCash/Print";
    
    const token = context.rootGetters.token;
    
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(payload),
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      
    });  
           
    // console.log("PettyCash_response>>>",response);  
    // response.blob().then(function(blob) {
    //   // console.log("PrintPrepaid_blob>>>",blob);
    //     var url = window.URL.createObjectURL(blob);
    //     //window.location.assign(url);
    //     var a = document.createElement('a');
    //     a.href = url;
    //     const d = new Date();
    //     let dateText = d.toISOString();
    //     a.download = `PettyCash_${dateText}.pdf`;
    //     document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    //     a.click();
    //     a.remove(); //afterwards we remove the element again
    // });
    
    let urlBlob = ''
    let blob = await response.blob();
    urlBlob = window.URL.createObjectURL(blob);
    context.commit('setBlob', urlBlob);
     
  },
  resetPettyCash(context) {
		context.commit("resetPettyCash");
	},
  async getPettyCashUseOtherExpenses(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/PettyCash/UseOtherExpenses/${payload}`;

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get petty cash use other expenses");
      throw error;
    }
    context.commit("setPettyCashUseOtherExpenses", responseData);
  },
};
