export default {
    setFormPrint(state, payload) {
      let forms = [...state.formPrint.forms];
      for (const form of payload.forms) {
        if(forms.findIndex(c => c.id === form.id) < 0){
          forms.push(form);
        }
      }
      state.formPrint.forms = forms;
      state.formPrint.currentPage = payload.currentPage;
      state.formPrint.totalPage = payload.totalPage;
    },
    resetFormPrint(state) {
      const formPrint = {
        forms: [],
        totalPage: 0,
        currentPage: 0,
      };
      state.formPrint = formPrint;
    },
  };