export default {
    titleToolbar: "นโยบายการบันทึกบัญชี",
    title: "การบันทึกบัญชี",
    searchTextPlaceholder: "กรอกข้อมูลเพื่อค้นหา",
    accountingDetail: "รายละเอียดรูปแบบบัญชี",
    titleDocumentBook : "รายการเอกสาร",
    dataEmpty: "กรุณาเลือก รายการเอกสาร ที่ต้องการแก้ไข",
    documentType: "ประเภทเอกสาร",
    documentBook: "เล่มเอกสาร",
    copyAccountingPolicy: "คัดลอกจากเอกสารอื่น",
    columnItem: "ลำดับ",
	columnLinkFunction: "บัญชีเชื่อมโยง",
	columnDebitCredit: "เดบิต / เครดิต",
	columnAccountCode: "รหัสบัญชี",
	columnAccountName: "ชื่อบัญชี",
    columnAccountCodeAndName: "รหัสบัญชี / ชื่อบัญชี",
    accountingPolicyItem: "รายการบัญชี",
    accountingPolicyItemRemark: "กรุณากรอกข้อมูลให้ครบ",
    accountingPolicyCopy: "กรุณาเลือกรายการบันทึกบัญชีจากเอกสารอื่นที่ต้องการคัดลอก",
    copyForReplace: "คัดลอกเพื่อแทนที่รายการเดิม",
    validateNoSelectData: "กรุณาเลือกข้อมูล",
    validateNoLinkFunctionSelect: "กรุณาเลือกบัญชีเชื่อมโยง",
    validateNoDebitCreditSelect: "กรุณาเลือกเดบิต / เครดิต",
    validateNoAccountCodeAndNameSelect: "กรุณาเลือกรหัสบัญชี / ชื่อบัญชี",
    validateNoDocumentBook: "กรุณาเลือกเล่มเอกสาร",
    changeDocumentBook: "เปลี่ยนเล่มเอกสาร",
    askForNewDocumentBook: "หากคุณเปลี่ยนเล่มเอกสารใหม่ระบบจะไม่บันทึกการแก้ไข",
	askForNewDocumentBookConfirm: "คุณยืนยันที่จะเปลี่ยนเล่มเอกสารใหม่ใช่หรือไม่?",
    saveAccountingPolicySuccess: "บันทึกรายการสำเร็จ",
    askItemDuplicate: "มีบัญชีเชื่อมโยง :code นี้แล้ว",
	debit: "เดบิต",
	credit: "เครดิต",
}