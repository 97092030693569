export default {
    setFile(state, payload){
        state.files = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },
    setSelectedFile(state, payload){        
        state.selectedFile = payload
    }, 
    setBlob(state, payload){        
        state.blob = payload
    },
    resetFile(state) {
        state.files = [];
        state.totalPage = 0;
        state.currentPage = 0;
    },
};