<template>
  <base-toolbar :title="lang.import" @goBack="goBack"></base-toolbar>
  <import-stock-keycard-dialog
    v-model:visible="showUploadStockKeycardDialog"
    @close="showUploadStockKeycardDialog = false"
    @data-upload="uploadedStockKeycard"
  />
  <base-progress-bar
    v-model:visible="showProgressBar"
    :title="lang.creatingStockKeycard"
    :textProgressBar="lang.generating"
    :valueObj="valueObj"
    :progressBar="`Template`"
  ></base-progress-bar>
  <base-container>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.import }}</label>

        <div class="flex">
          <PvButton
            class="p-button-outlined ml-3"
            @click="askForUploadStockKeycard"
          >
            <icon-cloud-storage-upload
              iconColor="primary"
              width="16"
              height="16"
            />
            <span class="p-button-label font-normal ml-1">{{
              lang.reupload
            }}</span>
          </PvButton>

          <PvButton class="ml-3" @click="importStockKeycard">
            <icon-documents-file-checkmark
              iconColor="white"
              width="16"
              height="16"
            />
            <span class="p-button-label font-normal ml-1">{{
              labelImportAll
            }}</span>
          </PvButton>
        </div>
      </div>

      <PvDivider></PvDivider>

      <div class="flex flex-wrap justify-content-left">
        <div class="lg:col-2 md:col-6 col-12 ml-1 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.allStatus"
            :content="contentAllStatus"
            :color="'primary'"
            :isClick="allStatusClick"
            @click="clickFilter('all')"
          ></base-select-card>
        </div>
        <div class="lg:col-2 md:col-6 col-12 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.wait"
            :content="contentWaitStatus"
            :color="'warning'"
            :isClick="waitStatusClick"
            @click="clickFilter('wait')"
          ></base-select-card>
        </div>
        <div class="lg:col-2 md:col-6 col-12 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.invalid"
            :content="contentInvalidStatus"
            :color="'secondary'"
            :isClick="invalidStatusClick"
            @click="clickFilter('invalid')"
          ></base-select-card>
        </div>
        <div class="lg:col-2 md:col-6 col-12 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.error"
            :content="contentErrorStatus"
            :color="'error'"
            :isClick="errorStatusClick"
            @click="clickFilter('error')"
          ></base-select-card>
        </div>
        <div class="lg:col-2 md:col-6 col-12 mr-2">
          <base-select-card
            class="w-full"
            :title="lang.success"
            :content="contentSuccessStatus"
            :color="'success'"
            :isClick="successStatusClick"
            @click="clickFilter('success')"
          ></base-select-card>
        </div>
      </div>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="dataTables"
          :paginator="true"
          class="p-datatable-sm"
          :class="textTableClass"
          :rows="rowsPerPage"
          dataKey="id"
          :rowHover="true"
          :loading="isLoading"
          paginatorTemplate="CurrentPageReport    FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 50, 100]"
          :currentPageReportTemplate="
            lang.page + ` {currentPage} / {totalPages}`
          "
          :globalFilterFields="[
            'name',
            'country.name',
            'representative.name',
            'status',
          ]"
          responsiveLayout="scroll"
        >
          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
          <PvColumn
            field="itemNo"
            :header="lang.itemNo"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="importStatusCode"
            :header="lang.status"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <base-tag
                :Caption="
                  setBaseTagStatusImport(slotProps.data.importStatusCode)
                    .caption
                "
                :Class="
                  setBaseTagStatusImport(slotProps.data.importStatusCode).class
                "
              />
            </template>
          </PvColumn>
          <PvColumn
            field="importStatusReason"
            :header="lang.reason"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="productCode"
            :header="lang.productCode"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.isProductCodeError }">
                {{ slotProps.data.productCode }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            field="unitCode"
            :header="lang.unit"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.isUnitCodeError }">
                {{ slotProps.data.unitCode }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            field="date"
            :header="lang.date"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.isDateError }">
                {{ slotProps.data.isDateError? 
                  slotProps.data.date :
                  formatDate(slotProps.data.date) 
                }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            field="runningNumber"
            :header="lang.runningNo"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.isRunningNumberError }">
                {{ slotProps.data.runningNumber }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            field="serialNumber"
            :header="lang.serialNo"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.isSerialNumberError }">
                {{ slotProps.data.serialNumber }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            field="status"
            :header="lang.status"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="{ data, field }">
              <base-tag
                :Caption="setBaseTagStatus(data[field]).label"
                :Class="setBaseTagStatus(data[field]).class"
              />
            </template>
          </PvColumn>
          <PvColumn
            field="remark"
            :header="lang.remark"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import BaseDialog from "../../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import ImportStockKeycardDialog from "../../../components/AR/stockKeycard/ImportStockKeycardDialog.vue";
export default {
  props: ["projectCode"],
  components: {
    ImportStockKeycardDialog,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { lang } = useLang();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.stockKeycard.listing,
          ...lang.th.ar.stockKeycard.import,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.stockKeycard.listing,
          ...lang.en.ar.stockKeycard.import,
        };
      }
    });

    const { formatDate, textTableClass, textFormClass } = useFormat();

    onMounted(() => {
      initiateData;
      window.addEventListener("beforeunload", handleBeforeUnload);
    });

    let dataConfigDialog = ref(null);
    let showUploadStockKeycardDialog = ref(false);
    //Table
    let isLoading = ref(false);
    let selectedPage = ref(1);
    let rowsPerPage = ref(10);
    //base-select-card
    let allStatusClick = ref(true);
    let waitStatusClick = ref(false);
    let invalidStatusClick = ref(false);
    let errorStatusClick = ref(false);
    let successStatusClick = ref(false);
    //ProgressBar
    let showProgressBar = ref(false);
    let valueObj = ref([]);

    //computeds
    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const stockKeycards = computed(() => {
      return store.getters["importStockKeycard/importStockKeycards"];
    });
    const lengthStockKeycards = computed(() => {
      return stockKeycards.value.length;
    });
    const dataWaitStatus = computed(() => {
      if (lengthStockKeycards.value > 0) {
        return stockKeycards.value.filter(
          (data) => data.importStatusCode == "wait"
        );
      }
      return [];
    });
    const dataInvalidStatus = computed(() => {
      if (lengthStockKeycards.value > 0) {
        return stockKeycards.value.filter(
          (data) => data.importStatusCode == "invalid"
        );
      }
      return [];
    });
    const dataErrorStatus = computed(() => {
      if (lengthStockKeycards.value > 0) {
        return stockKeycards.value.filter(
          (data) => data.importStatusCode == "error"
        );
      }
      return [];
    });
    const dataSuccessStatus = computed(() => {
      if (lengthStockKeycards.value > 0) {
        return stockKeycards.value.filter(
          (data) => data.importStatusCode == "success"
        );
      }
      return [];
    });
    const labelImportAll = computed(() => {
      let countData = 0;
      if (lengthStockKeycards.value > 0) {
        countData = dataWaitStatus.value.length;
      }
      return mergeLang.value.importAll.replace(":value", countData);
    });
    const contentAllStatus = computed(() => {
      let countData = 0;
      if (lengthStockKeycards.value > 0) {
        countData = lengthStockKeycards.value;
      }
      return mergeLang.value.statusContent.replace(":number", countData);
    });
    const contentWaitStatus = computed(() => {
      let countData = 0;
      if (lengthStockKeycards.value > 0) {
        countData = dataWaitStatus.value.length;
      }
      return mergeLang.value.statusContent.replace(":number", countData);
    });
    const contentInvalidStatus = computed(() => {
      let countData = 0;
      if (lengthStockKeycards.value > 0) {
        countData = dataInvalidStatus.value.length;
      }
      return mergeLang.value.statusContent.replace(":number", countData);
    });
    const contentErrorStatus = computed(() => {
      let countData = 0;
      if (lengthStockKeycards.value > 0) {
        countData = dataErrorStatus.value.length;
      }
      return mergeLang.value.statusContent.replace(":number", countData);
    });
    const contentSuccessStatus = computed(() => {
      let countData = 0;
      if (lengthStockKeycards.value > 0) {
        countData = dataSuccessStatus.value.length;
      }
      return mergeLang.value.statusContent.replace(":number", countData);
    });
    const dataTables = computed(() => {
      if (waitStatusClick.value) {
        return dataWaitStatus.value;
      } else if (invalidStatusClick.value) {
        return dataInvalidStatus.value;
      } else if (errorStatusClick.value) {
        return dataErrorStatus.value;
      } else if (successStatusClick.value) {
        return dataSuccessStatus.value;
      }

      return stockKeycards.value;
    });
    const stockKeycard = computed(() => {
      return store.getters["arStockKeycard/selectedStockKeycard"];
    });

    //methods
    const goBack = () => {
      router.push({ name: "ar-stock-keycard" });
    };
    const setDialog = (dataConfig) => {
      if (dataConfig.classTitle === "") {
        dataConfig.classTitle = "";
      } else if (dataConfig.classTitle === "success") {
        dataConfig.classTitle = "p-text-success";
      } else if (dataConfig.classTitle === "error") {
        dataConfig.classTitle = "p-error";
      }

      dataConfig.button = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "cancel",
        },
      ];
      if (dataConfig.type === "confirm") {
        dataConfig.button = [
          {
            id: "close",
            caption: mergeLang.value.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "cancel",
          },
          {
            id: "confirm",
            caption: mergeLang.value.buttonDialogConfirm,
            class: "w-full",
            action: "confirm",
          },
        ];
      }

      dataConfigDialog.value = {
        titleConfirm: dataConfig.title,
        classTitle: dataConfig.classTitle,
        wordingConfirm: dataConfig.message,
        dataButtons: dataConfig.button,
        display: true,
      };
    };
    const initiateData = async () => {
      selectedPage.value = 1;
      rowsPerPage.value = 10;
      allStatusClick.value = true;
      waitStatusClick.value = false;
      invalidStatusClick.value = false;
      errorStatusClick.value = false;
      successStatusClick.value = false;
      dataConfigDialog.value = null;
      showProgressBar.value = false;
      valueObj.value = [];
    };
    const setBaseTagStatusImport = (status) => {
      let result = {
        caption: "",
        class: "",
      };
      if (status.toLowerCase() === "wait") {
        result.caption = mergeLang.value.wait;
        result.class = "warning";
      } else if (status.toLowerCase() === "success") {
        result.caption = mergeLang.value.success;
        result.class = "success";
      } else if (status.toLowerCase() === "invalid") {
        result.caption = mergeLang.value.invalid;
        result.class = "secondary";
      } else if (status.toLowerCase() === "error") {
        result.caption = mergeLang.value.error;
        result.class = "error";
      }

      return result;
    };
    const setBaseTagStatus = (data) => {
      let status = {
        label: mergeLang.value.ready,
        class: "success",
      };
      if (data == "sold") {
        status.label = mergeLang.value.sold;
        status.class = "secondary";
      } else if (data == "lost") {
        status.label = mergeLang.value.lost;
        status.class = "error";
      } else if (data == "broken") {
        status.label = mergeLang.value.broken;
        status.class = "warning";
      } else if (data == "spare") {
        status.label = mergeLang.value.spare;
        status.class = "primary";
      } else if (data == "other") {
        status.label = mergeLang.value.other;
        status.class = "other";
      }
      return status;
    };
    const clickFilter = (status) => {
      allStatusClick.value = false;
      waitStatusClick.value = false;
      invalidStatusClick.value = false;
      errorStatusClick.value = false;
      successStatusClick.value = false;

      if (status == "all") {
        allStatusClick.value = true;
      } else if (status == "wait") {
        waitStatusClick.value = true;
      } else if (status == "invalid") {
        invalidStatusClick.value = true;
      } else if (status == "error") {
        errorStatusClick.value = true;
      } else if (status == "success") {
        successStatusClick.value = true;
      }

      selectedPage.value = 1;
      rowsPerPage.value = 10;
    };
    const askForUploadStockKeycard = async () => {
      if (lengthStockKeycards.value > 0) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.askForNewImport,
          type: "confirm",
        });
        const dialogResponse = await createConfirmDialog(
          BaseDialog,
          dataConfigDialog.value
        ).reveal();
        if (dialogResponse.isCanceled) {
          return;
        }
      }
      uploadStockKeycard();
    };
    const uploadStockKeycard = () => {
      showUploadStockKeycardDialog.value = true;
    };
    const uploadedStockKeycard = () => {
      showUploadStockKeycardDialog.value = false;
    };
    const importStockKeycard = async () => {
      if (validateData()) {
        await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        return;
      }
      
      valueObj.value = [];
      valueObj.value = dataWaitStatus.value;
      showProgressBar.value = true;
      for (const data of dataWaitStatus.value) {
        await addStockKeycard(data);
      }
      showProgressBar.value = false;

      setDialog({
        classTitle: "success",
        title: mergeLang.value.saveSuccess,
        message: mergeLang.value.saveSuccess,
        type: "",
      });
      await createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
      clickFilter("all");
    };
    const validateData = () => {
      if (dataInvalidStatus.value.length > 0) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.cannotImport,
          message: [
            mergeLang.value.cannotImportWording.replace(
              ":value",
              dataInvalidStatus.value.length
            ),
            mergeLang.value.editAndReuploadWording,
          ],
          type: "",
        });
        return true;
      } else if (dataWaitStatus.value.length == 0) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.noItemToImportWording,
          type: "",
        });
        return true;
      }
      return false;
    };
    const addStockKeycard = async (data) => {
      try {
        let payload = {
          projectId: selectedProject.value.id,
          code: data.runningNumber,
          serial: data.serialNumber,
          date: data.date,
          productId: data.productId ? data.productId : "",
          unitId: data.unitId ? data.unitId : "",
          buyPrice: 0,
          status: data.status,
          remark: data.remark,
          receiptDetailId: "",
        };
        // console.log(payload);
        await store.dispatch("arStockKeycard/createStockKeycard", payload);
        if (stockKeycard.value) {
          data.id = stockKeycard.value.id;
          data.importStatusCode = "success";
          data.markStatus = "1";
        }
        return true;
      } catch (error) {
        data.importStatusCode = "error";
        data.markStatus = "1";
        return false;
      }
    };

    onBeforeRouteLeave(async (to, from) => {
      if (dataWaitStatus.value.length > 0 || dataInvalidStatus.value.length > 0) {
        var curValue = to.params.projectCode;
        var oldValue = from.params.projectCode;
        if (curValue == oldValue) {
          setDialog({
            classTitle: "",
            title: mergeLang.value.notificationDialogHeader,
            message: mergeLang.value.pageDirtyConfirmMessage,
            type: "confirm",
          });
          const dialogResponse = await createConfirmDialog(
            BaseDialog,
            dataConfigDialog.value
          ).reveal();
          return !dialogResponse.isCanceled;
        }
      }
      return true;
    });

    onBeforeUnmount(() => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    });

    const handleBeforeUnload = async (event) => {
      if (dataWaitStatus.value.length > 0 || dataInvalidStatus.value.length > 0) {
        event.preventDefault();
      }
    };

    return {
      lang: mergeLang,
      formatDate,
      textTableClass,
      textFormClass,

      showUploadStockKeycardDialog,
      isLoading,
      selectedPage,
      rowsPerPage,
      allStatusClick,
      waitStatusClick,
      invalidStatusClick,
      errorStatusClick,
      successStatusClick,
      showProgressBar,
      valueObj,

      //computeds
      dataTables,
      labelImportAll,
      contentAllStatus,
      contentWaitStatus,
      contentInvalidStatus,
      contentErrorStatus,
      contentSuccessStatus,

      //methods
      goBack,
      askForUploadStockKeycard,
      uploadedStockKeycard,
      importStockKeycard,
      setBaseTagStatus,
      setBaseTagStatusImport,
      clickFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-right: auto;
  }
}
</style>
