export default {
    receipts(state) {
      return state.receipts;
    },
    totalPage(state) {
      return state.totalPage;
    },
    currentPage(state) {
      return state.currentPage;
    },
    rows(state) {
      return state.rows;
    },
    selectedReceipt(state){
      return state.selectedReceipt;
    },
    receiptUniqueKey(state){
      return state.receiptUniqueKey;
    },
    blob(state) {
      return state.blob;
    },
    receiptReferenceDocument(state){
      return state.receiptReferenceDocument;
    },
    importReceiptList(state) {
      return state.importReceiptList;
    },
    mappedImportReceipt(state){
      return state.mappedImportReceipt;
    },
    validatedImportReceipt(state){
      return state.validatedImportReceipt;
    },
    documentBookId(state){
      return state.documentBookId;
    },
  };
  