import { useStore } from "vuex";
import { computed, ref } from 'vue';
import { onBeforeRouteLeave } from 'vue-router'
import { _ } from 'vue-underscore';
import BaseDialog from "../components/UI/BaseDialog.vue";
import { createConfirmDialog } from 'vuejs-confirm-dialog';
import useLang from "../hooks/lang.js";
export default function usePageDirty() {
  const store = useStore();

  const { lang } = useLang();

  //computed
  const mergeLang = computed(function () {
    if (store.getters.selectedLanguage === "TH") {
      return { ...lang.th.base};
    } else {
      return { ...lang.en.base};
    }
  });

  let pageDirtyTitle = ref(mergeLang.value.notificationDialogHeader);
  let pageDirtyMessage = ref(mergeLang.value.pageDirtyConfirmMessage);

  const compareObject = function (basePayload, currentPayload) {
    let result = !_.isEqual(basePayload, currentPayload);
    // console.log('compare object is page dirty = ', result)
    store.dispatch('setPageDirty', { value: result });
  };

  const isCompareObject = function (basePayload, currentPayload) {
    let result = !_.isEqual(basePayload, currentPayload);
    // console.log('compare object is page dirty = ', result)
    return result;
  };

  const isPageDirty = computed(() => {
    return store.getters["isPageDirty"];
  });

  const confirmDialog = createConfirmDialog(BaseDialog,
    {
      titleConfirm: pageDirtyTitle.value,
      wordingConfirm: pageDirtyMessage.value,
      dataButtons:[{
        id: "close",
        caption: mergeLang.value.close,
        class: "p-button-outlined p-button-secondary w-full",
        action: "cancel",
      },
      {
        id: "confirm",
        caption: mergeLang.value.buttonDialogConfirm,
        class: "w-full",
        action: "confirm",
      }],
      display: true
    });

    onBeforeRouteLeave(async (to, from) => {
      if (isPageDirty.value) {   
        var curValue = to.params.projectCode;
        var oldValue = from.params.projectCode;
        //เช็คในกรณีที่เปลี่ยนโครงการแล้วอยู่หน้าเดิม
        if (curValue == oldValue) {
          const answer = await confirmDialog.reveal();
          return !answer.isCanceled;
        }
        // const answer = window.confirm(
        //   'Do you really want to leave? you have unsaved changes!'
        // )
        // cancel the navigation and stay on the same page
        // if (!answer) return false
    }
    return true
  })

  return {
    isPageDirty,
    compareObject,
    pageDirtyTitle,
    pageDirtyMessage,
    isCompareObject,
  };
}
