// วิธีใช้งาน
// 1.สร้าง route ใน router/index.js
// 2.ระบุ navigation ทั้ง 2 ภาษาใน lang/navigation/th,en
//     - name  : navigation จะใช้ name ในการ map กับแต่ละ route
//               **ซึ่งต้องระบุให้ตรงกับ name ที่ระบุใน file router/index.js
//     - items : label --> ระบุคำ/ชื่อที่จะแสดง สำหรับแต่ละ item
//               url --> ระบุ path เพื่อ link ไป path ต่างๆ (ใช้ path ที่ระบุใน router/index.js)

//ตัวอย่างข้อมูลสำหรับ Set navigation
// {
//     name: 'ap-purchase-order-edit',
//     items: [
//         { label: "ใบสั่งซื้อ", url: "/ap/purchase-order/:apBookId" },
//         { label: "{text}ใบสั่งซื้อ", url: "/ap/purchase-order/:apBookId/:id", type: "edit" },
//     >> type: "edit" ระบุเพื่อบอกว่าหน้าไหนต้องมีการแก้ไข
//     ]
// },

const navigations = [
	{
		name: "project-document",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "โครงการ", url: "" },
			{ label: "เอกสารโครงการ", url: "" },
		],
	},
	{
		name: "master-project",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "โครงการ", url: "" },
			{ label: "ข้อมูลโครงการ", url: "" },
		],
	},
	{
		name: "master-project-edit",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "โครงการ", url: "" },
			{ label: "ข้อมูลโครงการ", url: "/master/project" },
			{ label: "แก้ไขข้อมูลโครงการ", url: "" },
		],
	},
	{
		name: "product-advertisement-edit",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "โครงการ", url: "" },
			{ label: "ประชาสัมพันธ์-โฆษณา", url: "" },
		],
	},
	{
		name: "master-meter",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ทะเบียนมิเตอร์", url: "" },
		],
	},
	{
		name: "master-meter-edit",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ทะเบียนมิเตอร์", url: "/meter/:meterType" },
			{ label: "{text}ทะเบียนมิเตอร์", url: "", type: "edit" },
		],
	},
	{
		name: "master-meter-auto-create",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "มิเตอร์", url: "/meter/:meterType" },
			{ label: "สร้างมิเตอร์อัตโนมัติ", url: "" },
		],
	},
	{
		name: "master-meter-usage-seting",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "มิเตอร์", url: "/meter/:meterType" },
			{ label: "รูปแบบการคำนวณมิเตอร์", url: "" },
		],
	},
	{
		name: "project-penaltycalculation-edit",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "กำหนดอัตราค่าปรับ", url: "" },
		],
	},
	{
		name: "product-penaltycalculation-edit",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "รายรับ", url: "/ar-product" },
			{ label: "อัตราค่าปรับ", url: "" },
		],
	},
	{
		name: "unit",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			{ label: "ห้อง/บ้าน", url: "" },
		],
	},
	{
		name: "unit-edit",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			{ label: "ห้อง/บ้าน", url: "/unit" },
			{ label: "{text}ห้อง/บ้าน", url: "", type: "edit" },
		],
	},
	{
		name: "unit-resident-edit",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			{ label: "ห้อง/ยูนิต", url: "/unit" },
			{ label: "{text}ห้อง/ยูนิต", url: "/unit/:unitId", type: "edit" },
			{ label: "{text}สมาชิก/ลูกค้า", url: "", type: "edit" },
		],
	},
	{
		name: "resident",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			{ label: "สมาชิก/ลูกค้า", url: "" },
		],
	},
	{
		name: "resident-edit",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			{ label: "สมาชิก/ลูกค้า", url: "/resident" },
			{ label: "{text}สมาชิก/ลูกค้า", url: "", type: "edit" },
		],
	},
	{
		name: "ar-product",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "รายรับ", url: "" },
		],
	},
	{
		name: "ar-product-edit",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "รายรับ", url: "/ar-product" },
			{ label: "{text}รายรับ", url: "", type: "edit" },
		],
	},
	{
		name: "unit-product-setup-invoice",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			{ label: "ห้อง/บ้าน", url: "/unit" },
			{ label: "รายการแจ้งหนี้", url: "" },
		],
	},
	{
		name: "book-bank",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ตั้งค่าบัญชี", url: "" },
			{ label: "บัญชีธนาคาร", url: "" },
		],
	},
	{
		name: "book-bank-edit",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ตั้งค่าบัญชี", url: "" },
			{ label: "บัญชีธนาคาร", url: "/book-bank" },
			{ label: "{text}บัญชีธนาคาร", url: "", type: "edit" },
		],
	},
	{
		name: "form-version",
		items: [
			{ label: "ช่วยเหลือ", url: "" },
			{ label: "ตั้งค่าเวอร์ชันแบบฟอร์ม", url: "" },
		],
	},
	{
		name: "meter-usage-water",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "มิเตอร์", url: "" },
			{ label: "บันทึกมิเตอร์ประปา", url: "" },
		],
	},
	{
		name: "meter-usage-electricity",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "มิเตอร์", url: "" },
			{ label: "บันทึกมิเตอร์ไฟ", url: "" },
		],
	},
	{
		name: "meter-usage-cooling-air",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "มิเตอร์", url: "" },
			{ label: "บันทึกมิเตอร์ความเย็น", url: "" },
		],
	},
	{
		name: "meter-usage-water-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "มิเตอร์", url: "" },
			{ label: "บันทึกมิเตอร์ประปา", url: "/meter-usage/water/W" },
			{ label: "แก้ไขบันทึกมิเตอร์ประปา", url: "" },
		],
	},
	{
		name: "meter-usage-electricity-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "มิเตอร์", url: "" },
			{ label: "บันทึกมิเตอร์ไฟ", url: "/meter-usage/electricity/E" },
			{ label: "แก้ไขบันทึกมิเตอร์ไฟ", url: "" },
		],
	},
	{
		name: "meter-usage-cooling-air-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "มิเตอร์", url: "" },
			{ label: "บันทึกมิเตอร์ความเย็น", url: "/meter-usage/cooling-air/A" },
			{ label: "แก้ไขบันทึกมิเตอร์ความเย็น", url: "" },
		],
	},
	{
		name: "ar-invoice",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "แจ้งหนี้", url: "" },
			{ label: "ใบแจ้งหนี้", url: "" },
		],
	},
	{
		name: "ar-invoice-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "แจ้งหนี้", url: "" },
			{ label: "ใบแจ้งหนี้", url: "/ar/invoice" },			
			{ label: "{text}ใบแจ้งหนี้", url: "", type: "edit" },
		],
	},
	{
		name: "outstanding-letter",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "แจ้งหนี้", url: "" },
			{ label: "หนังสือยืนยันยอดค้าง", url: "" },
		],
	},
	{
		name: "account-book",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ตั้งค่าบัญชี", url: "" },
			{ label: "ตั้งค่าสมุดบัญชี", url: "" },
		],
	},
	{
		name: "account-book-edit",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ตั้งค่าบัญชี", url: "" },
			{ label: "ตั้งค่าสมุดบัญชี", url: "/account-book" },
			{ label: "{text}สมุดบัญชี", url: "", type: "edit" },
		],
	},
	{
		name: "ar-receipt",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "รับชำระ", url: "" },
			{ label: "ใบเสร็จ", url: "" },
		],
	},
	{
		name: "ar-receipt-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "รับชำระ", url: "" },
			{ label: "ใบเสร็จ", url: "/ar/receipt" },
			{ label: "{text}ใบเสร็จ", url: "", type: "edit" },
		],
	},
	{
		name: "visitor-control",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "รับชำระ", url: "" },
			{ label: "ใบเสร็จ", url: "/ar/receipt" },
			{ label: "รายการใบเสร็จ Visitor control", url: "" },
		],
	},
	{
		name: "visitor-control-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "รับชำระ", url: "" },
			{ label: "ใบเสร็จ", url: "/ar/receipt" },
			{ label: "ใบเสร็จ Visitor control", url: "/ar/visitor-control" },
			{ label: "แก้ไขใบเสร็จ Visitor control", url: "", type: "edit" },
		],
	},
	{
		name: "ar-receipt-import",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "รับชำระ", url: "" },
			{ label: "ใบเสร็จ", url: "/ar/receipt" },
			{ label: "นำเข้าข้อมูลใบเสร็จ", url: "" },
		],
	},
	{
		name: "report-request",
		items: [
			{ label: "รายการคำขอ", url: "" },
		],
	},
	{
		name: "ar-notice",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "ทวงถาม", url: "" },
			{ label: "หนังสือทวงถาม", url: "" },
		],
	},
	{
		name: "ar-notice-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "ทวงถาม", url: "" },
			{ label: "หนังสือทวงถาม", url: "/ar/notice" },
			{ label: "รายละเอียดหนังสือทวงถาม", url: ""},
		],
	},
	{
		name: "ar-calculate-invoice",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "แจ้งหนี้", url: "" },
			{ label: "คำนวณใบแจ้งหนี้", url: "" },
		],
	},
	{
		name: "ar-invoice-wait-for-approve",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "แจ้งหนี้", url: "" },
			{ label: "คำนวณใบแจ้งหนี้", url: "" },
		],
	},
	{
		name: "ar-edit-invoice-wait-for-approve",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "แจ้งหนี้", url: "" },
			{ label: "คำนวณใบแจ้งหนี้", url: "" },
			{ label: "รายละเอียดการคำนวณหนี้", url: "" },
		],
	},
	{
		name: "ar-use-prepaid-and-deposit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "รับชำระ", url: "" },
			{ label: "ตัดรับล่วงหน้าและเงินฝาก", url: "" },
		],
	},
	{
		name: "ar-preview-use-prepaid-and-deposit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "รับชำระ", url: "" },
			{ label: "ตัดรับล่วงหน้าและเงินฝาก", url: "" },
			{ label: "รายการรอการตัด", url: "" },
		],
	},
	{
		name: "ar-calculate-notice",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "ทวงถาม", url: "" },
			{ label: "คำนวณหนังสือทวงถาม", url: "" },
		],
	},
	{
		name: "ar-notice-wait-for-approve",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "ทวงถาม", url: "" },
			{ label: "คำนวณหนังสือทวงถาม", url: "" },
		],
	},
	{
		name: "ar-edit-notice-wait-for-approve",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "ทวงถาม", url: "" },
			{ label: "คำนวณหนังสือทวงถาม", url: "" },
			{ label: "ตรวจสอบรายละเอียด", url: "" },
		],
	},
	{
		name: "master-policy",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ชุดเรียกเก็บ", url: "" },
		],
	},
	{
		name: "master-policy-edit",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ชุดเรียกเก็บ", url: "/master/policy" },
			{ label: "{text}ชุดเรียกเก็บ", url: "", type: "edit" },
		],
	},
	{
		name: "ar-bank-ar",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "รับชำระ", url: "" },
			{ label: "ใบนำฝาก (Bank AR)", url: "" },
		],
	},
	{
		name: "ar-bank-ar-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "รับชำระ", url: "" },
			{ label: "ใบนำฝาก (Bank AR)", url: "/ar/bank-ar" },
			{ label: "{text}ใบนำฝาก (Bank AR)", url: "", type: "edit" },
		],
	},
	{
		name: "ar-deposit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "รับชำระ", url: "" },
			{ label: "ใบรับฝากเงิน", url: "" },
		]
	},
	{
		name: "ar-deposit-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "รับชำระ", url: "" },
			{ label: "ใบรับฝากเงิน", url: "/ar/deposit" },
			{ label: "{text}ใบรับฝากเงิน", url: "", type: "edit" },
		]
	},
	{
		name: "ar-credit-note",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "รับชำระ", url: "" },
			{ label: "ใบลดหนี้", url: "" },
		]
	},
	{
		name: "ar-credit-note-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "รับชำระ", url: "" },
			{ label: "ใบลดหนี้", url: "/ar/credit-note" },
			{ label: "{text}ใบลดหนี้", url: "", type: "edit" },
		]
	},
	{
		name: "customer-group",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			{ label: "ช่องทางการส่งเอกสาร", url: "" },
		],
	},
	{
		name: "customer-group-edit",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			{ label: "ช่องทางการส่งเอกสาร", url: "/customer-group" },
			{ label: "{text}ช่องทางการส่งเอกสาร", url: "", type: "edit" },
		],
	},
	{
		name: "ar-refund-guarantee",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "คืนเงิน", url: "" },
			{ label: "คืนเงินค้ำประกัน", url: "" },
		],
	},
	{
		name: "ar-refund-guarantee-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "คืนเงิน", url: "" },
			{ label: "คืนเงินค้ำประกัน", url: "/ar/refund-guarantee" },
			{ label: "{text}คืนเงินค้ำประกัน", url: "", type: "edit" },
		]
	},
	{
		name: "product-ap",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "รายจ่าย", url: "" },
		],
	},
	{
		name: "product-ap-edit",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "รายจ่าย", url: "/product-ap" },
			{ label: "{text}รายจ่าย", url: "", type: "edit" },
		],
	},
	{
		name: "debt-free",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			{ label: "ปลอดหนี้", url: "" },
		],
	},
	{
		name: "debt-free-request",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			// { label: "ปลอดหนี้", url: "/debt-free" },
			{ label: "ขอหนังสือรับรองหนี้", url: "" },
		],
	},
	{
		name: "debt-free-request-edit",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			// { label: "ปลอดหนี้", url: "/debt-free" },
			{ label: "ขอหนังสือรับรองหนี้", url: "/debt-free/request" },
			{ label: "{text}หนังสือรับรองหนี้", url: "", type: "edit" },
		]
	},
	{
		name: "debt-free-approve",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			// { label: "ปลอดหนี้", url: "/debt-free" },
			{ label: "อนุมัติปลอดหนี้", url: "" },
		],
	},
	{
		name: "debt-free-approve-edit",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			// { label: "ปลอดหนี้", url: "/debt-free" },
			{ label: "อนุมัติปลอดหนี้", url: "/debt-free/approve" },
			{ label: "แก้ไขใบอนุมัติปลอดหนี้", url: "" },
		]
	},
	{
		name: "debt-free-print",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			// { label: "ปลอดหนี้", url: "/debt-free" },
			{ label: "พิมพ์ใบปลอดหนี้", url: "" },
		],
	},
	{
		name: "debt-free-follow-up",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			// { label: "ปลอดหนี้", url: "/debt-free" },
			{ label: "ติดตามสถานะปลอดหนี้", url: "" },
		],
	},
	{
		name: "debt-free-follow-up-edit",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			// { label: "ปลอดหนี้", url: "/debt-free" },
			{ label: "ติดตามสถานะปลอดหนี้", url: "/debt-free/follow-up" },
			{ label: "{text}ติดตามสถานะปลอดหนี้", url: "", type: "edit" },
		]
	},
	{
		name: "supplier",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ผู้จัดจำหน่าย", url: "" },
		],
	},
	{
		name: "supplier-edit",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ผู้จัดจำหน่าย", url: "/supplier" },
			{ label: "{text}ผู้จัดจำหน่าย", url: "", type: "edit" },
		],
	},
	{
		name: "closing-entry",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "บัญชี GL", url: "" },
			{ label: "ปิดรอบบัญชี", url: "" },
		],
	},
	{
		name: "closing-entry-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "บัญชี GL", url: "" },
			{ label: "ปิดรอบบัญชี", url: "/gl/closing-entry" },
			{ label: "รายละเอียดปิดรอบบัญชี", url: ""},
		],
	},
	{
		name: "accounting-frequently",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ตั้งค่าบัญชี", url: "" },
			{ label: "รูปแบบบัญชีใช้บ่อย", url: "" },
		],
	},
	{
		name: "accounting-frequently-accountBookId",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ตั้งค่าบัญชี", url: "" },
			{ label: "รูปแบบบัญชีใช้บ่อย", url: "" },
		],
	},
	{
		name: "accounting-frequently-accountBookId-edit",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ตั้งค่าบัญชี", url: "" },
			{ label: "รูปแบบบัญชีใช้บ่อย", url: "/accounting-frequently" },
			{ label: "{text}รูปแบบบัญชีใช้บ่อย", url: "", type: "edit" },
		],
	},
	{
		name: "account-chart",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ตั้งค่าบัญชี", url: "" },
			{ label: "ผังบัญชี", url: "" },
		],
	},
	{
		name: "account-chart-edit",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ตั้งค่าบัญชี", url: "" },
			{ label: "ผังบัญชี", url: "/account-chart" },
			{ label: "{text}ผังบัญชี", url: "", type: "edit" },
		]
	},
	{
		name: "dashboard-mobile-register",
		items: [
			{ label: "ลงทะเบียนแอป", url: "" },
		]
	},
	{
		name: "accounting-policy",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ตั้งค่าบัญชี", url: "" },
			{ label: "นโยบายการบันทึกบัญชี", url: "" },
		]
	},
	{
		name: "phone-book",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "โครงการ", url: "" },
			{ label: "สมุดโทรศัพท์", url: "" },
		],
	},
	{
		name: "phone-book-edit",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "โครงการ", url: "" },
			{ label: "สมุดโทรศัพท์", url: "/phone-book" },
			{ label: "{text}สมุดโทรศัพท์", url: "", type: "edit" },
		],
	},
	{
		name: "home",
		items: [
			{ label: "", url: "" },
		],
	},
	{
		name: "report-ar",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "รายงานลูกหนี้", url: "" },
		],
	},
	{
		name: "report-receipt",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "รับชำระ", url: "" },
			{ label: "รายงานใบเสร็จ", url: "" },
		],
	},
	{
		name: "report-notice",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "ทวงถาม", url: "" },
			{ label: "หนังสือทวงถามรูปแบบอื่น", url: "" },
		],
	},
	{
		name: "report-ap",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "รายงานเจ้าหนี้", url: "" },
		],
	},
	{
		name: "report-withholding-tax",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "รายงาน ภ.ง.ด.", url: "" },
		],
	},
	{
		name: "report-gl",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "บัญชี GL", url: "" },
			{ label: "รายงานบัญชี", url: "" },
		],
	},
	{
		name: "report-pc",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "บัญชี GL", url: "" },
			{ label: "รายงานเงินสดย่อย", url: "" },
		],
	},
	{
		name: "report-asset",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ทรัพย์สิน", url: "" },
			{ label: "รายงานทรัพย์สิน", url: "" },
		],
	},
	{
		name: "report-meter",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "มิเตอร์", url: "" },
			{ label: "รายงานมิเตอร์", url: "" },
		],
	},
	{
		name: "report-debt-free",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			{ label: "รายงานปลอดหนี้", url: "" },
		],
	},
	{
		name: "asset-register",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ทรัพย์สิน", url: "" },
			{ label: "ทะเบียนทรัพย์สิน", url: "" },
		],
	},
	{
		name: "asset-register-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ทรัพย์สิน", url: "" },
			{ label: "ทะเบียนทรัพย์สิน", url: "/asset/register" },
			{ label: "{text}ทะเบียนทรัพย์สิน", url: "", type: "edit" },
		]
	},
	{
		name: "ar-prepaid-refund",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "คืนเงิน", url: "" },
			{ label: "คืนเงินรับล่วงหน้า", url: "" },
		],
	},
	{
		name: "ar-prepaid-refund-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "คืนเงิน", url: "" },
			{ label: "คืนเงินรับล่วงหน้า", url: "/ar/prepaid-refund" },
			{ label: "{text}คืนเงินรับล่วงหน้า", url: "", type: "edit" },
		],
	},
	{
		name: "ap-withholding-tax",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ภาษีหัก ณ ที่จ่าย", url: "" },
		],
	},
	{
		name: "ap-withholding-tax-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ภาษีหัก ณ ที่จ่าย", url: "/ap/withholding-tax" },
			{ label: "{text}ภาษีหัก ณ ที่จ่าย", url: "", type: "edit" },
		],
	},
	{
		name: "ap-bank-ap",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "จ่ายชำระ", url: "" },
		],
	},
	{
		name: "ap-bank-ap-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "จ่ายชำระ", url: "/ap/bank-ap" },
			{ label: "{text}จ่ายชำระ", url: "", type: "edit" },
		],
	},
	{
		name: "ap-purchase-requisition",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ใบขอซื้อ PR", url: ""},
		],
	},
	{
		name: "ap-purchase-requisition-apBook",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ใบขอซื้อ PR", url: "" },
		],
	},
	{
		name: "ap-purchase-requisition-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ใบขอซื้อ PR", url: "/ap/purchase-requisition/:apBook" },
			{ label: "{text}ใบขอซื้อ PR", url: "", type: "edit" },
		],
	},
	{
		name: "ap-purchase-order",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ใบสั่งซื้อ PO", url: ""},
		],
	},
	{
		name: "ap-purchase-order-apBookId",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ใบสั่งซื้อ PO", url: "" },
		],
	},
	{
		name: "ap-purchase-order-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ใบสั่งซื้อ PO", url: "/ap/purchase-order/:apBookId" },
			{ label: "{text}ใบสั่งซื้อ PO", url: "", type: "edit" },
		],
	},
	{
		name: "ap-purchase-invoice",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ใบรับสินค้า/ใบรับวางบิล", url: ""},
		],
	},
	{
		name: "ap-purchase-invoice-apBook",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ใบรับสินค้า/ใบรับวางบิล", url: "" },
		],
	},
	{
		name: "ap-purchase-invoice-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ใบรับสินค้า/ใบรับวางบิล", url: "/ap/purchase-invoice/:apBook" },
			{ label: "{text}ใบรับสินค้า/ใบรับวางบิล", url: "", type: "edit" },
		],
	},
	{
		name: "ap-purchase-receipt",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ใบเสร็จด้านจ่าย", url: ""},
		],
	},
	{
		name: "ap-purchase-receipt-apbook",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ใบเสร็จด้านจ่าย", url: "" },
		],
	},
	{
		name: "ap-purchase-receipt-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ใบเสร็จด้านจ่าย", url: "/ap/purchase-receipt/:apBook" },
			{ label: "{text}ใบเสร็จด้านจ่าย", url: "", type: "edit" },
		],
	},
	{
		name: "gl-invoice",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "บัญชี GL", url: "" },
			{ label: "ใบแจ้งหนี้มาลงบัญชี (ลูกหนี้)", url: ""},
		],
	},
	{
		name: "gl-invoice-book",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "บัญชี GL", url: "" },
			{ label: "ใบแจ้งหนี้มาลงบัญชี (ลูกหนี้)", url: "" },
		],
	},
	{
		name: "gl-invoice-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "บัญชี GL", url: "" },
			{ label: "ใบแจ้งหนี้มาลงบัญชี (ลูกหนี้)", url: "/gl/invoice/:glBook/:idBook" },
			{ label: "{text}ใบแจ้งหนี้มาลงบัญชี (ลูกหนี้)", url: "", type: "edit" },
		],
	},
	{
		name: "revert-accounting",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "บัญชี GL", url: "" },
			{ label: "ใบแจ้งหนี้มาลงบัญชี (ลูกหนี้)", url: "/gl/invoice/:glBook/:idBook" },
			{ label: "{text}ใบแจ้งหนี้มาลงบัญชี (ลูกหนี้)", url: "/gl/invoice/:glBook/:idBook/:id", type: "edit" },
			{ label: "กลับรายการบัญชี", url: "", type: "edit" },
		],
	},
	{
		name: "gl-receipt",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "บัญชี GL", url: "" },
			{ label: "ใบเสร็จมาลงบัญชี (ลูกหนี้)", url: ""},
		],
	},
	{
		name: "gl-receipt-book",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "บัญชี GL", url: "" },
			{ label: "ใบเสร็จมาลงบัญชี (ลูกหนี้)", url: "" },
		],
	},
	{
		name: "gl-receipt-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "บัญชี GL", url: "" },
			{ label: "ใบเสร็จมาลงบัญชี (ลูกหนี้)", url: "/gl/receipt/:glBook/:idBook" },
			{ label: "{text}ใบเสร็จมาลงบัญชี (ลูกหนี้)", url: "", type: "edit" },
		],
	},
	{
		name: "gl-accounting",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "บัญชี GL", url: "" },
			{ label: "บันทึกบัญชี-ทั่วไป", url: ""},
		],
	},
	{
		name: "gl-accounting-book",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "บัญชี GL", url: "" },
			{ label: "บันทึกบัญชี-ทั่วไป", url: "" },
		],
	},
	{
		name: "gl-accounting-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "บัญชี GL", url: "" },
			{ label: "บันทึกบัญชี-ทั่วไป", url: "/gl/accounting/:glBook/:idBook" },
			{ label: "{text}บันทึกบัญชี-ทั่วไป", url: "", type: "edit" },
		],
	},
	{
		name: "gl-pettycash",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "บัญชี GL", url: "" },
			{ label: "เงินสดย่อย", url: ""},
		],
	},
	{
		name: "gl-pettycash-book",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "บัญชี GL", url: "" },
			{ label: "เงินสดย่อย", url: "" },
		],
	},
	{
		name: "gl-pettycash-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "บัญชี GL", url: "" },
			{ label: "เงินสดย่อย", url: "/gl/pettycash/:glBook/:idBook" },
			{ label: "{text}เงินสดย่อย", url: "", type: "edit" },
		],
	},
	{
		name: "notice-print-setting",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "รูปแบบหนังสือทวงถาม", url: "" },
		],
	},
	{
		name: "report-notice-print-setting",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "ทวงถาม", url: "" },
			{ label: "หนังสือทวงถามรูปแบบอื่น", url: "/report/Notice" },
			{ label: "รูปแบบหนังสือทวงถาม", url: "" },
		],
	},
	{
		name: "ar-invoice-import",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "แจ้งหนี้", url: "" },
			{ label: "ใบแจ้งหนี้", url: "/ar/invoice" },
			{ label: "นำเข้าข้อมูลใบแจ้งหนี้", url: "" },
		],
	},
	{
		name: "ar-invoice-notification",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "แจ้งหนี้", url: "" },
			{ label: "ใบแจ้งหนี้", url: "/ar/invoice" },
			{ label: "ส่งแจ้งเตือน", url: "" },
		],
	},
	{
		name: "notice-custom-report",
		items: [
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "ทวงถาม", url: "" },
			{ label: "หนังสือทวงถามกำหนดเอง", url: "" },
		],
	},
	{
		name: "notice-custom-report-edit",
		items: [
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "ทวงถาม", url: "" },
			{ label: "หนังสือทวงถามกำหนดเอง", url: "" },
			{ label: "{text}หนังสือทวงถามกำหนดเอง", url: "", type: "edit" },
		],
	},
	{
		name: "ar-receipt-bill-payment",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "รับชำระ", url: "" },
			{ label: "ใบเสร็จ", url: "/ar/receipt" },
			{ label: "ตรวจสอบรายการ", url: "" },
		],
	},
	{
		name: "transfer-slip",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "รับชำระ", url: "" },
			{ label: "ตรวจสอบเงินโอน", url: "" },
		],
	},
	{
		name: "transfer-slip-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "รับชำระ", url: "" },
			{ label: "ตรวจสอบเงินโอน", url: "/ar/transfer-slip" },
			{ label: "รายการแจ้งโอน", url: "" },
			],
	},
	{
		name: "ar-stock-keycard",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "คีย์การ์ด", url: "" },
			{ label: "ทะเบียนคีย์การ์ด", url: "" },
		],
	},
	{
		name: "ar-stock-keycard-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "คีย์การ์ด", url: "" },
			{ label: "ทะเบียนคีย์การ์ด", url: "/ar/stock-keycard" },
			{ label: "{text}คีย์การ์ด", url: "", type: "edit" },
		],
	},
	{
		name: "control-stock-keycard",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "สต๊อกคีย์การ์ด", url: "" },
		],
	},
	{
		name: "preview-stock-keycard",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "คีย์การ์ด", url: "" },
			{ label: "ทะเบียนคีย์การ์ด", url: "/ar/stock-keycard" },
			{ label: "นำเข้าคีย์การ์ด", url: "" },
		],
	},
	{
		name: "report-stock-keycard",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "คีย์การ์ด", url: "" },
			{ label: "รายงานคีย์การ์ด", url: "" },
		],
	},
	{
		name: "preview-resident",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			{ label: "สมาชิก/ลูกค้า", url: "/resident" },
			{ label: "นำเข้าสมาชิก/ลูกค้า", url: "" },
		],
	},
	{
		name: "preview-unit",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			{ label: "ห้อง/บ้าน", url: "/unit" },
			{ label: "นำเข้าห้อง/บ้าน", url: "" },
		],
	},
	{
		name: "preview-unit-resident",
		items: [
			{ label: "จัดการผู้พักอาศัย", url: "" },
			{ label: "ผู้พักอาศัย", url: "" },
			{ label: "ห้อง/บ้าน", url: "/unit" },
			{ label: "นำเข้าข้อมูลสมาชิก", url: ""},
		],
	},
	{
		name: "preview-supplier",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ผู้จัดจำหน่าย", url: "/supplier" },
			{ label: "นำเข้าข้อมูลผู้จัดจำหน่าย", url: ""},
		],
	},
	{
		name: "preview-deposit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ลูกหนี้ AR", url: "" },
			{ label: "รับชำระ", url: "" },
			{ label: "ใบรับฝากเงิน", url: "/ar/deposit" },
			{ label: "นำเข้าข้อมูลใบรับฝากเงิน", url: ""},
		],
	},
	{
		name: "preview-asset",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ทรัพย์สิน", url: "" },
			{ label: "ทะเบียนทรัพย์สิน", url: "/asset/register" },
			{ label: "นำเข้าข้อมูลทรัพย์สิน", url: "" },
		]
	},
	{
		name: "budget",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "งบประมาณ", url: "" },
			{ label: "จัดการงบประมาณ", url: "" },
		],
	},
	{
		name: "budget-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "งบประมาณ", url: "" },
			{ label: "จัดการงบประมาณ", url: "/budget" },
			{ label: "{text}งบประมาณ", url: "", type: "edit" },
		],
	},
	{
		name: "report-budget",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "งบประมาณ", url: "" },
			{ label: "รายงานงบประมาณ", url: "" },
		],
	},
	{
		name: "signature",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ตั้งค่าการลงนาม", url: "" },
			{ label: "ผู้ลงนามในรายงาน", url: "" },
		],
	},
	{
		name: "signature-edit",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ตั้งค่าการลงนาม", url: "" },
			{ label: "ผู้ลงนามในรายงาน", url: "/signature" },
			{ label: "{text}ผู้ลงนาม", url: "", type: "edit" },
		],
	},
	{
		name: "signature-management",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ตั้งค่าการลงนาม", url: "" },
			{ label: "รายงานที่สามารถตั้งค่า", url: "" },
		],
	},
	{
		name: "signature-management-edit",
		items: [
			{ label: "ตั้งค่าโครงการ", url: "" },
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "ตั้งค่าการลงนาม", url: "" },
			{ label: "รายงานที่สามารถตั้งค่า", url: "/signature-management" },
			{ label: "รายละเอียดรายงาน", url: "", type: "edit" },
		],
	},
	{
		name: "payment-request-type",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ใบตั้งเบิก", url: "" },
			{ label: "ตั้งค่าใบตั้งเบิก", url: "" },
		],
	},
	{
		name: "payment-request-type-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ใบตั้งเบิก", url: "" },
			{ label: "ตั้งค่าใบตั้งเบิก", url: "" },
			{ label: "{text}ประเภทใบตั้งเบิก", url: "", type: "edit" },
		],
	},
	{
		name: "payment-request-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ใบตั้งเบิก", url: "" },
			{ label: "{text}ใบตั้งเบิก", url: "", type: "edit" },
		],
	},
	{
		name: "payment-request",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ใบตั้งเบิก", url: "" },
			{ label: "จัดการใบตั้งเบิก", url: "" },
		],
	},
	{
		name: "payment-request-edit",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ใบตั้งเบิก", url: "" },
			{ label: "จัดการใบตั้งเบิก", url: "" },
			{ label: "{text}จัดการใบตั้งเบิก", url: "", type: "edit" },
		],
	},
	{
		name: "report-payment-request",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ใบตั้งเบิก", url: "" },
			{ label: "รายงานใบตั้งเบิก", url: "" },
		],
	},
	{
		name: "preview-payment-request-auto-gl",
		items: [
			{ label: "บัญชีการเงิน", url: "" },
			{ label: "เจ้าหนี้ AP", url: "" },
			{ label: "ใบตั้งเบิก", url: "" },
			{ label: "จัดการใบตั้งเบิก", url: "" },
			{ label: "บันทึกบัญชี", url: "" },
		],
	},
];

export default navigations;
