export default {
  setUnits(state, payload) {
    state.units = payload.data;
  },
  setInvoices(state, payload) {
    state.invoices = payload.data;
  },
  setReceipts(state, payload) {
    let receipts = [...state.receipts];
    for (const receipt of payload.data) {
      if (receipts.findIndex((c) => c.id === receipt.id) < 0) {
        receipts.push(receipt);
      }
    }
    state.receipts = receipts;
    state.receiptCurrentPage = payload.page.currentPage;
    state.receiptTotalPage = payload.page.pageCount;
  },
  resetReceiptMapBillPayment(state) {
    state.receipts = [];
    state.receiptCurrentPage = 0;
    state.receiptTotalPage = 0;
  },
  setDataBillPayments(state, payload){
    state.billPayments = payload;
  },
};
