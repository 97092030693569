export default {
    
    setReceipt(state, payload) {
        let receipts = [...state.receipts];
        for (const receipt of payload.receipts) {
            if(receipts.findIndex(c => c.id === receipt.id) < 0){
               receipts.push(receipt);
            }
        }
        state.receipts = receipts;
        state.currentPage = payload.currentPage;
        state.totalPage = payload.totalPage;
    },
    resetReceipt(state){
        state.receipts = [];
        state.currentPage = 0;
        state.totalPage = 0;
    },
    // setReceipts(state, payload){
    //     state.receipts = payload.data
    //     state.totalPage = payload.page.pageCount
    //     state.currentPage = payload.page.currentPage
    // },
    setReceiptSelected(state, payload){
        state.receiptSelected = payload
    },
    // getReceiptSelected(state, payload){
    //     state.receiptSelected = payload.data
    //     state.totalListPage = payload.page.pageCount
    //     state.currentPage = payload.page.currentPage
    // },
    getReceiptSelected(state, payload) {
        let receiptSelected = [...state.receiptSelected];
        for (const receipt of payload.receipts) {
            if(receiptSelected.findIndex(c => c.id === receipt.id) < 0){
               receiptSelected.push(receipt);
            }
        }
        state.receiptSelected = receiptSelected;
        state.currentPage = payload.currentPage;
        state.totalPage = payload.totalPage;
    },    
    resetReceiptSelected(state){
        state.receiptSelected = [];
        state.currentPage = 0;
        state.totalPage = 0;
    },
    setReceiptToGl(state, payload){
        state.receiptToGl = payload.data
    },
    clearReceiptToGl(state, payload){
        state.receiptToGl = payload
    }
};