export default {
	title: "ใบเสร็จรับเงิน / ใบกำกับภาษี",
	changeBook: "เลือกเล่มเอกสาร",

	columnPostStep: "สถานะ",
	columnCancelStatus: "ยกเลิก",
	columnCode: "เลขที่",
	columnReferenceCode: "เลขที่อ้างอิง",
	columnDate: "วันที่",
	columnSupplierCode: "รหัสผู้จัดจำหนาย",
	columnSupplierName: "ชื่อผู้จัดจำหนาย",
	columnReceiptNetAmount: "มูลค่าสุทธิ",
	columnreceiptActualPayAmount: "ยอดชำระจริง",
	columnCurrencyUnit: "หน่วย",
};
