<template>
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredPeriods"
    @complete="searchPeriod($event)"
    v-model="selectedPeriod"
    field="name"
    forceSelection
  />

  <!-- <PvDropdown
    :options="periods"
    @complete="searchPeriod"
    @dropdown-click="searchPeriod"
    optionLabel="name"
    :filter="true"
  /> -->
  <!-- field="code"  --> 
</template>

<script>
export default {
  props: {
    isOrderby: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      periods: [],
      filteredPeriods: null,
      selectedPeriod: null
    };
  },
  created() {
    let date = new Date();
    date.setMonth(date.getMonth() + 36);
    for (let index = 0; index < 250; index++) {
      date.setMonth(date.getMonth() - 1);
      this.periods.push({
        code: index + 1,
        name:
          (date.getMonth() + 1).toString().padStart(2, "0") +
          "/" +
          (date.getFullYear() + 543),
      });
    }
  },
  methods: {
    searchPeriod(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredPeriods = [...this.periodsOrder];
        } else {
          this.filteredPeriods = this.periodsOrder.filter((period) => {
            return period.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
      // if (!event.query.trim().length) {
      //   //ช่องว่าง
      //   // let date = new Date();
      //   // this.searchText = "";
      //   // this.searchText = (date.getFullYear() + 543).toString();

      //   this.searchText = "";
      //   this.searchText = null;
      // } else {
      //   this.searchText = event.query;
      // }
    },
    checkData(event) {
      // console.log(event);
      const found = this.periods.find(
        (period) =>
          period.name.toLowerCase() === event.target.value.toLowerCase()
      );
      if (!found) {
        // console.log(event.target.value)// = '05/2564'
        this.$emit("update:period", "00");
      }
    },
  },
  computed: {
    periodsOrder(){
      if(this.periods && this.isOrderby){
        let periods = this.periods;
        return periods.sort((a, b) => (a.code > b.code ? -1 : 1));
      }
      return this.periods;
    },
    
  },
  watch:{
    selectedPeriod(){
      // console.log(this.selectedPeriod)
    }
  }
  // computed: {
  //   filteredPeriod() {
  //     if (!this.searchText) {
  //       //ช่องว่าง
  //       const result = [...this.periods];
  //       return result;
  //     } else {
  //       return this.periods.filter((period) => {
  //         return period.name.toLowerCase().includes(this.searchText.toLowerCase());
  //       });
  //     }
  //   },
  // },
};
</script>