import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export default {
    namespaced: true,
    state(){
        return {
            noticeGenerateQueueStatus: null,
            noticeReadyGenerateBill: null,
            selectedNoticeGenerateQueue: null,
            notices: [],
            selectedNotice: null,
            noticeTotalPage: 0,
            noticeCurrentPage: 1,
            noticeRows: 100,
            noticeIsCanceled: false,
            generatedNotice: false,
        }
    },
    actions,
    getters,
    mutations
}