export default {
  async getGlBooks(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Voucher/CheckDocumentToGL";

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get glBook");
      throw error;
    }

    // console.log("getGlBooks", responseData);

    // const data = responseData.data;
    context.commit("setGlBook", responseData.data);
  },
};
