export default {
  projects(state) {
    return state.projects;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
  isProjectSelected(_,getters){
    return !!getters.projects
  },
  foreignOwnershipRatioMessage(state) {
    return state.foreignOwnershipRatioMessage;
  },
};
