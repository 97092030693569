<template>
  <!-- v-model="selectedUnit" -->
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredCustomerGroups"
    field="name"
    @complete="searchCustomerGroup"
    @dropdown-click="filteredCustomerGroups"
    forceSelection
  />
</template>

<script>
import useGetCustomerGroup from "../../hooks/getCustomerGroup.js";
export default {
  setup() {
    const {
      filteredCustomerGroups,
      searchText,
      isSharedCustomerGroupReady,
      searchCustomerGroup,
    } = useGetCustomerGroup();

    return {
      filteredCustomerGroups,
      searchText,
      isSharedCustomerGroupReady,
      searchCustomerGroup,
    };
  },
  emits: ["load-finish"],
  watch: {
    isSharedCustomerGroupReady(curValue) {
      if (curValue) {
        this.$emit("load-finish");
      }
    },
  },
};
</script>
