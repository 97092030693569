<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '70vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="titleHeaderImportData"
      :wordingConfirm="titleValidateImportData"
      :dataButtons="dataButtonDialog"
      @close="showValidateDialog = false"
    ></base-dialog>

    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">{{ lang.uploadFile }}</label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          :disabled="shouldDisableCloseButton"
          @click="closeDialog"
        />
      </div>
    </template>
    <div class="dropZone">
      <input
        type="file"
        id="dropZone-file"
        class="dropZone-file"
        ref="dropZoneFile"
        @click="resetFile"
        @change="handleFiles"
        multiple
        accept=".xlsx"
      />
      <div
        class="dropZone-wrapper"
        @dragenter.prevent=""
        @dragover.prevent=""
        @drop.prevent="handleFiles"
      >
        <label for="dropZone-file" class="cursor-pointer">
          <div class="pt-6"></div>
          <PvButton
            class="cursor-pointer p-button-rounded show-icon"
            disabled="disabled"
          >
            <icon-cloud-storage-upload iconColor="white" />
          </PvButton>
          <div class="cursor-pointer dropZone-label-text pt-4">
            <label
              for="dragFile"
              class="cursor-pointer font-md p-text-secondary"
              >{{ lang.dragFile }}</label
            >
            <label
              for="addFile"
              class="cursor-pointer font-md p-text-primary"
              @click="addFile"
              >{{ lang.addFile }}</label
            >
            <input
              id="add-file"
              type="file"
              style="display: none"
              accept=".xlsx"
              @click="resetFile"
              @change="handleFiles"
              class="cursor-pointer"
            />
          </div>
          <div class="cursor-pointer font-xs p-text-secondary pt-2 pb-8">
            <label for="importFileDesc" class="cursor-pointer">{{
              lang.importFileDesc
            }}</label>
            <label
              for="downloadfile"
              class="cursor-pointer p-text-primary"
              @click="downloadfile"
              >{{ lang.downloadfile }}</label
            >
          </div>
        </label>
      </div>
    </div>

    <div class="p-datatable-border my-5 pt-6">
      <PvDataTable
        :value="files"
        dataKey="id"
        responsiveLayout="scroll"
        class="p-datatable-xs"
        :class="textTableClass"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn style="width: 87px; height: 56px" headerClass="h-center">
          <template #body>
            <PvButton
              v-if="isOpen"
              type="button"
              style="background: white; border: white; opacity: 1"
              disabled="disabled"
            >
              <icon-documents-file-checkmark iconColor="secondary" />
            </PvButton>
          </template>
        </PvColumn>

        <PvColumn
          field="detailName"
          :header="lang.fileName"
          style="min-width: 12rem"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-left font-normal p-text-primary"
        >
        </PvColumn>

        <PvColumn
          style="width: 2rem"
          headerClass="h-center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="">
            <PvButton
              v-if="isOpen"
              type="button"
              class="p-button-danger"
              style="background: white; border: white"
              @click="deleteFile"
            >
              <icon-trash-delete-bin iconColor="error" />
            </PvButton>
          </template>
        </PvColumn>
        <template #footer>
          <label class="p-pagination-radius">&nbsp;</label>
        </template>
      </PvDataTable>
    </div>

    <div class="flex justify-content-center pt-4">
      <PvButton class="w-3" @click="uploadFile" :disabled="checkDisable">
        <span class="w-full text-center font-md font-normal p-button-label">{{
          uploadButtonText
        }}</span>
      </PvButton>
    </div>
    <template #footer></template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import * as XLSX from "xlsx";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useImportExcel from "../../../hooks/importExcel.js"
export default {
  emits: ["close"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();

    const { getDownloadBlob } = useGetFormPrint();

    const { formatCurrencyTable, formatDate, formatDateToISOString } =
      useFormat();
    const { validateDate, convertDate, validatePeriod, convertPeriod } = useImportExcel();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.invoice.import,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.invoice.import,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      getDownloadBlob,
      formatCurrencyTable,
      formatDate,
      formatDateToISOString,
      validateDate,
      convertDate,
      validatePeriod,
      convertPeriod,
    };
  },
  data() {
    return {
      uploadFiles: [],
      isUpLoading: false,
      isShowDragDrop: false,
      isShowUploadLoading: false,
      fileName: "",

      files: [],
      file: null,
      isOpen: false,
      value1: 0,
      isUploaded: false,

      arrayBuffer: null,
      rawDatas: [],

      isShowUploading: false,
      uploadingData: [],
      itemTotal: 0,
      itemCount: 0,
      percentage: 0,

      showInvoiceListDialog: false,
      openInvoiceList: false,
      // invoices: [],
      validatedInvoices: [],

      titleHeaderImportData: this.lang.notificationDialogHeader,
      titleValidateImportData: "",
      dataButtonDialog: [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ],
      showValidateDialog: false,

      details: [],
      processingText: "",
    };
  },
  interval: null,
  methods: {
    setLoading(bool) {
      this.isUpLoading = bool;
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    initiateData() {
      this.files = [];
      this.isOpen = false;
      this.file = null;
    },
    validateFileCount() {
      let check = this.uploadFiles.length;
      let textError = "";
      this.uploadFiles.forEach((file) => {
        textError += file.name + ", ";
      });
      let name = textError.substring(0, textError.length - 2);
      if (check > 1) {
        this.titleHeaderImportData = this.lang.validateLimitFile;
        (this.titleValidateImportData = this.lang.validateFileType.replace(
          ":name",
          name
        )),
          (this.showValidateDialog = true);

        return true;
      }
    },
    validateFileType() {
      let error = "";
      if (
        this.file.type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        this.titleHeaderImportData = this.lang.validateImportFile;
        this.titleValidateImportData = this.lang.validateFileType.replace(
          ":name",
          this.file.name
        );
        this.showValidateDialog = true;

        error = "error";
      }

      if (error === "error") return true;
      else return false;
    },
    async handleFiles(event) {
      const inputValue = event.target.files || event.dataTransfer.files;
      this.uploadFiles = Array.from(inputValue);

      if (this.validateFileCount()) {
        return;
      } //เช็คจำนวนไฟล์

      this.file = inputValue[0];

      if (this.validateFileType()) {
        return;
      } //เช็คประเภทไฟล์

      this.isOpen = true;
      let fileName = this.file.name;
      this.files = [
        {
          detailName: fileName,
        },
      ];
    },
    async validateData() {
      for (const data in this.rawDatas) {
        let rowNumber = parseInt(data) + 1;
        let invoice = this.rawDatas[data];
        if (!invoice.UnitCode) {
          this.titleValidateImportData = this.lang.validateUnitCode.replace(
            ":row",
            rowNumber
          );
          return true;
        }

        if (!invoice.InvoiceDate) {
          this.titleValidateImportData = this.lang.validateInvoiceDate.replace(
            ":row",
            rowNumber
          );
          return true;
        }

        if (!invoice.InvoiceDueDate) {
          this.titleValidateImportData =
            this.lang.validateInvoiceDueDate.replace(":row", rowNumber);
          return true;
        }

        if (!invoice.ProductCode) {
          this.titleValidateImportData = this.lang.validateProductCode.replace(
            ":row",
            rowNumber
          );
          return true;
        }

        if (!invoice.Period) {
          this.titleValidateImportData = this.lang.validatePeriod.replace(
            ":row",
            rowNumber
          );
          return true;
        }

        if (!invoice.TotalAmount || invoice.TotalAmount == "") {
          this.titleValidateImportData = this.lang.validateTotalAmount.replace(
            ":row",
            rowNumber
          );
          return true;
        }

        if (invoice.TotalAmount && invoice.TotalAmount < 0) {
          this.titleValidateImportData =
            this.lang.validateFormatTotalAmount.replace(":row", rowNumber);
          return true;
        }

        if (invoice.InvoiceDate) {
          let result = this.validateDate(invoice.InvoiceDate);
          if (!result) {
            this.titleValidateImportData =
              this.lang.validateFormatInvoiceDate.replace(":row", rowNumber);
            return true;
          }
        }

        if (!invoice.InvoiceDueDate) {
          let result = this.validateDate(invoice.InvoiceDueDate);
          if (!result) {
            this.titleValidateImportData =
              this.lang.validateFormatInvoiceDueDate.replace(":row", rowNumber);
            return true;
          }
        }

        if(this.convertDate(invoice.InvoiceDate) > this.convertDate(invoice.InvoiceDueDate)){
          this.titleValidateImportData =
              this.lang.validateInvoiceDateAndDueDate.replace(":row", rowNumber);
            return true;
        }

        if (invoice.Period) {
          let result = this.validatePeriod(invoice.Period)
          if(result){
            invoice.Period = this.convertPeriod(invoice.Period);
          }else{        
            this.titleValidateImportData =
              this.lang.validateFormatPeriod.replace(":row", rowNumber);
            return true;
          }
        }

        if (invoice.TotalAmount) {          
          let text = /^-?\d+(\.\d+)?$/;
          let totalAmount = invoice.TotalAmount.toString().replaceAll(",", ""); 
                    
          if (isNaN(parseFloat(totalAmount)) || !text.test(totalAmount) ) {
            this.titleValidateImportData =
              this.lang.validateFormatTotalAmount.replace(":row", rowNumber);
            return true;
          }          
          invoice.TotalAmount = parseFloat(totalAmount);
        }

        if (invoice.ReferenceCode) {
          let referenceCode = invoice.ReferenceCode.toString();
          if (referenceCode.length > 3000) {
            this.titleValidateImportData =
              this.lang.validateReferCodeLimitText.replace(":row", rowNumber);
            return true;
          }
        }

        if (invoice.Description) {
          let description = invoice.Description.toString();
          if (description.length > 750) {
            this.titleValidateImportData =
              this.lang.validateDescriptionLimitText.replace(":row", rowNumber);
            return true;
          }
        }
      }
      return false;
    },
    async validateImportInvoice() {
      try {
        // console.log("validateImportInvoice");
        let rawDatas = this.rawDatas;
        let groupBy = ["UnitCode", "InvoiceDate", "InvoiceDueDate"];
        let groupedRawDatas = Object.values(
          rawDatas.reduce((r, o) => {
            const key = groupBy.map((k) => o[k]).join("|");
            (r[key] ??= []).push(o);
            return r;
          }, {})
        );
        let tempImportHeaders = [];

        let itemNo = 1;
        let index = 1;
        let totalAmount = 0;
        for (const rawData of groupedRawDatas) {
          var ImportDetails = rawData.reduce(function (details, object) {
            let detail = {
              itemNo: index,
              productCode: object.ProductCode,
              period: object.Period,
              remark: object.Description ? object.Description : "",
              totalAmount: object.TotalAmount,
              unitPrice: object.TotalAmount,
              paidAmount: 0,
              productId: "",
              productName: "",
              quantity: 1,
              tenantId: "",
            };
            totalAmount += object.TotalAmount;
            details.push(detail);
            index++;
            return details;
          }, []);
          index = 1;
          // console.log("ImportDetails=",ImportDetails);
          let tempImportHeader = {
            projectId: this.selectedProject.id,
            unitCode: rawData[0].UnitCode.toString(),
            date: this.formatDateToISOString(
              this.convertDate(rawData[0].InvoiceDate)
            ),
            duedate: this.formatDateToISOString(
              this.convertDate(rawData[0].InvoiceDueDate)
            ),
            remark1: rawData[0].ReferenceCode ? rawData[0].ReferenceCode : "",
            remark2: "",
            details: ImportDetails,
            unitId: "",
            customerId: "",
            type: "Add",
            itemNo: itemNo,
            totalAmount: totalAmount,
            markStatus: "",
            error: "",
            status: "waiting",
          };

          this.processingText = `${this.lang.validatingData} ${itemNo}/${groupedRawDatas.length}`;
          // console.log("tempImportHeader=",tempImportHeader);
          await this.$store.dispatch(
            "arInvoice/validateImportInvoice",
            tempImportHeader
          );

          if (
            this.validatedImportInvoice &&
            (!this.validatedImportInvoice.success ||
              this.validatedImportInvoice.code !== "000")
          ) {
            if(this.validatedImportInvoice.code == "118"){
              this.titleValidateImportData = this.lang.productCodeIsNotValid.replace(":productCode", this.validatedImportInvoice.data.details[0].productCode);
            }else if(this.validatedImportInvoice.code == "119"){
              this.titleValidateImportData = this.lang.unitCodeIsNotValid.replace(":unitCode", this.validatedImportInvoice.data.unitCode);
            }else if(this.validatedImportInvoice.code == "120"){
              this.titleValidateImportData = this.lang.unitCodeIsNotActive.replace(":unitCode", this.validatedImportInvoice.data.unitCode);
            }else{
              this.titleValidateImportData = this.validatedImportInvoice.message;
            }
              this.showValidateDialog = true;
              this.isUpLoading = false;
            return true;
          }
          // console.log("this.validatedImportInvoice=",this.validatedImportInvoice);
          if (this.validatedImportInvoice && this.validatedImportInvoice.data) {
            this.validatedImportInvoice.data.details.forEach((e) => {
              var detail = ImportDetails.filter(
                (c) => c.productCode === e.productCode
              );
              if (detail) {
                detail.forEach((d) => {
                  d.productId = e.productId;
                  d.productName = e.productName;
                });
              }
            });
            tempImportHeader.details = ImportDetails;
            tempImportHeader.unitId = this.validatedImportInvoice.data.unitId;
            tempImportHeader.customerId = this.validatedImportInvoice.data.customerId;
          }

          itemNo++;
          totalAmount = 0;
          tempImportHeaders.push(tempImportHeader);
        }
        await this.$store.dispatch(
          "arInvoice/importInvoiceList",
          tempImportHeaders
        );
        // console.log("tempImportHeaders=", tempImportHeaders);
        this.setLoading(false);
      } catch (error) {
        this.titleValidateImportData = error;
        this.showValidateDialog = true;
        this.setLoading(false);
        return true;
      }
      return false;
    },
    async uploadFile() {
      if (this.file) {
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(this.file);
        fileReader.onload = async () => {
          this.arrayBuffer = fileReader.result;
          let data = new Uint8Array(this.arrayBuffer);
          let arr = [];
          for (let i = 0; i != data.length; ++i)
            arr[i] = String.fromCharCode(data[i]);
          let bstr = arr.join("");
          let workbook = XLSX.read(bstr, { type: "binary" });
          let first_sheet_name = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[first_sheet_name];
          this.rawDatas = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          this.itemTotal = this.rawDatas.length;

          if (this.rawDatas.length == 0) {
            this.titleValidateImportData =
              this.lang.validateImportNoData.replace(":file", this.file.name);
            this.showValidateDialog = true;
            return;
          } else {
            this.setLoading(true);
            // console.log("this.rawDatas=", this.rawDatas);
            if (await this.validateData()) {
              this.showValidateDialog = true;
              this.setLoading(false);
              return;
            }

            if (await this.validateImportInvoice()) {
              return;
            }
          }

          this.$router.push({ name: "ar-invoice-import" });
        };
      } else {
        this.titleValidateImportData = this.lang.validateAddfile;
        this.showValidateDialog = true;
        return true;
      }
    },
    async downloadfile() {
      this.setLoading(true);
      let payload = {
        docType: "excel",
        path: "invoice/import_invoice_form.xlsx",
        fileName: "ImportInvoiceForm",
        parameter: "{}",
      };
      try {
        await this.$store.dispatch("shared/printReport", payload);
        const d = new Date();
        let dateText = d.toISOString();
        this.getDownloadBlob(
          `${payload.fileName}_${dateText}.xlsx`,
          this.fileBlob
        );
      } catch (error) {
        this.titleValidateImportData = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    addFile() {
      document.getElementById("add-file").click();
    },
    deleteFile() {
      this.initiateData();
    },
    resetFile(event) {
      if (event.target.files.length > 0) {
        event.target.value = null;
      }
    },
    closeInvoiceListDialog() {
      this.showInvoiceListDialog = false;
    },
    isNumeric(value) {
      return Number.isInteger(value);
    }, 
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    fileBlob() {
      return this.$store.getters["shared/blob"];
    },
    checkDisable() {
      if (!this.file || this.isUpLoading) {
        return true;
      } else {
        return false;
      }
    },
    validatedImportInvoice() {
      return this.$store.getters["arInvoice/validatedImportInvoice"];
    },
    uploadButtonText() {
      if (!this.isUpLoading) {
        return this.lang.uploadFile;
      }
      return this.processingText;
    },
    shouldDisableCloseButton() {
      return this.isUpLoading;
    },
  },
};
</script>

<style scoped>
.dropZone {
  box-sizing: border-box;
  width: 100%;
  background: #fafafa;
  border: 2px dashed #ced0d6;
  border-radius: 10px;
  text-align: center;
  height: 194px;
  margin-top: 2rem;
}

.dropZone-file {
  position: absolute;
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.dropZone-wrapper {
  display: grid;
  width: 100%;
  height: 100%;
}

.p-progressbar {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.p-datatable {
  text-align: center !important;
  overflow: visible !important;
}

.base-center {
  width: 75%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.show-icon {
  opacity: 1;
  width: 48px;
  height: 48px;
  padding: 0.75rem;
}

.base-margin {
  margin-top: 37px;
  margin-bottom: 37px;
}

.p-pagination-radius {
  border-radius: 0 0 12px 12px;
}
</style>
