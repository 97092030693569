export default {
	accountingTitle: "บันทึกบัญชี",
	receiptTitle: "ใบเสร็จมาลงบัญชี",
	invoiceTitle: "ใบแจ้งหนี้มาลงบัญชี",
	columnStatus: "สถานะ",
	columnIsCancel: "ยกเลิก",
	columnCode: "เลขที่",
	columnDate: "วันที่",
	columnPeriod: "งวด",
	columnDesc: "คำอธิบาย",
	columnTotal: "จำนวนเงิน",
	columnApprove: "อนุมัติ",
	columnType: "ประเภท",
	changeGlBook: "เปลี่ยนเล่มเอกสาร",
	selectGlBook: "เลือกเล่มเอกสาร",
	code: "รหัส",
	name: "ชื่อ",
	approveName: "ผู้อนุมัติ",
	accountingFrequently: "รายการบัญชีใช้บ่อย",
	columnManageRow: "จัดการแถว",
	debit: "เดบิต",
	credit: "เครดิต",
	totalDebit: "รวมเดบิต",
	totalCredit: "รวมเครดิต",
	diff: "ผลต่าง",
	createBy: "ผู้สร้าง",
	createDate: "วันที่สร้าง",
	updateBy: "ผู้แก้ไข",
	updateDate: "วันที่แก้ไข",
	printLot: "พิมพ์เป็นชุด",
	downloadPDF: "Download PDF",
	validateCodeStart: "กรุณาระบุเลขที่เริ่มต้น",
	validateCodeEnd: "กรุณาระบุเลขที่สิ้นสุด",
	voucher: "Voucher",
	invoiceSelect: "กรุณาเลือกเล่มที่ไม่เกี่ยวข้องกับใบแจ้งหนี้",
	receiptSelect: "กรุณาเลือกเล่มที่ไม่เกี่ยวข้องกับใบเสร็จ",
	documentBookCode: "เล่มเอกสาร",
	lockStatus: "ล็อก",
	unlockStatus: "ไม่ล็อก",
	unlockSuccess: "คุณปลดล็อกเอกสารสำเร็จ",
	lockSuccess: "คุณล็อกเอกสารสำเร็จ",
	unlockSuccessTitle: "ปลดล็อกเอกสารสำเร็จ",
	lockSuccessTitle: "ล็อกเอกสารสำเร็จ",
	titleLockDialog: "ล็อกเอกสาร",
	messageLockDialog: "คุณต้องการล็อกเอกสาร<br>เพื่อห้ามการแก้ไขรายละเอียดเอกสารนี้หรือไม่?",
	titleUnlockDialog: "ปลดล็อกเอกสาร",
	messageUnlockDialog: "คุณต้องการปลดล็อกเอกสาร<br>เพื่อแก้ไขรายละเอียดเอกสารนี้หรือไม่?",
	validateAccountDetail : "เนื่องจากคุณมีรายการผังบัญชีย่อยที่ไม่ได้ระบุบัญชีคุม จำนวน :quantity รายการ กรุณาแก้ไขผังบัญชี",
	gotoAccounting: "ไปเมนู ผังบัญชี"
};
