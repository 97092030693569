export default {
  meterUsageWater: "บันทึกมิเตอร์ประปา",
  meterUsageElectricity: "บันทึกมิเตอร์ไฟ",
  meterUsageCoolingAir: "บันทึกมิเตอร์ความเย็น",
  meterUsageReport: "รายงานบันทึกมิเตอร์",
  meterUsageWaterEdit: "แก้ไขบันทึกมิเตอร์ประปา",
  meterUsageElectricityEdit: "แก้ไขบันทึกมิเตอร์ไฟ",
  meterUsageCoolingAirEdit: "แก้ไขบันทึกมิเตอร์ความเย็น",

  editMeter: "แก้ไข",
  upload: "นำเข้า/ส่งออก",
  export: "ส่งออก",

  statusAll: "สถานะทั้งหมด",
  statusG: "ปกติ",
  statusY: "ผ่านการตรวจสอบ",
  statusR: "ผิดปกติ",

  meterCode: "มิเตอร์",
  unitCode: "รหัสห้อง/ บ้าน",
  unitAddressNo: "บ้านเลขที่",
  productName: "บัญชีรายรับ",
  previousUsed: "จดครั้งก่อน",
  currentUsed: "จดครั้งนี้",
  quantity: "จำนวน",
  amount: "รวมเงิน",
  waterAverageUsed: "หน่วยน้ำเฉลี่ย",
  electricityAverageUsed: "หน่วยไฟเฉลี่ย",
  coolingAirAverageUsed: "หน่วยความเย็นเฉลี่ย",
  status: "สถานะมิเตอร์",
  reason: "ระบุสาเหตุ",
  updateDate: "วันเวลาที่อัปเดต",

  uploadFile2: "อัปโหลดไฟล์",
  dragFile: "ลากไฟล์มาวาง หรือ ",
  addFile: "เพิ่มไฟล์มิเตอร์",
  importFileDesc: "กรุณาเพิ่มไฟล์ทีละ 1 ไฟล์ที่มีนามสกุล .XLSX เท่านั้น หรือ ",
  downloadfile: "ดาวน์โหลดไฟล์มิเตอร์",
  validateLimitFile: "สามารถอัปโหลดไฟล์ได้ทีละ 1 ไฟล์",
  validateImportFile: "สกุลไฟล์ที่อนุญาตมีดังนี้ .XLSX เท่านั้น",

  validateCheckNumber: "รูปแบบการจดมิเตอร์ ระบุได้เฉพาะตัวเลข",
  updatePreviousUsed: "แก้ไขเลขจดครั้งก่อน",
  from: "จาก",
  to: "เป็น",
  previousUsedReason: "ระบุเหตุผลที่แก้ไข",
  validatePreviousUsedReason: "โปรดระบุเหตุผลที่แก้ไขเลขจดครั้งก่อน",

  setReason: "กำหนดสาเหตุ",
  reasonOverAverage: "ใช้เกินค่าเฉลี่ย",
  reasonNewAdded: "เพิ่งเข้าอยู่",
  reasonChangeOrBroken: "มิเตอร์เปลี่ยน/เสีย",
  reasonOther: "อื่นๆ",

  deleteMeter: "ลบเลขจดครั้งนี้",
  confirmDeleteMeter: "ละทิ้งการบันทึกเลขจดครั้งนี้",
  confirmDeleteMeterDetail: "คุณต้องการละทิ้งการบันทึกเลขจดครั้งนี้ทั้งหมดหรือไม่",
  deleteMeterSuccess: "ลบเลขจดครั้งนี้สำเร็จ",
  validateAddfile: "กรุณาเพิ่มไฟล์",
  uploadStatus: "สถานะอัปโหลด",

  validateReferVoucher: "มีรายการมิเตอร์ในงวด :period ถูกนำไปลงบัญชีหรือแจ้งหนี้แล้ว",
  validateNoSetupProduct: "มิเตอร์ :meterCode ยังไม่ได้ตั้งค่ารหัสรายรับ",
  validateSetupCalculater: "ตั้งค่าการคำนวณมิเตอร์แบบขั้นบันไดไม่ถูกต้อง",
  editReason: "เหตุผลที่แก้ไข: ",
  usuallyReason: "ไม่พบความผิดปกติ",
  validateReason: "*โปรดระบุ",

  productInvalid: "*โปรดตั้งค่าทะเบียนรายรับ",

  referDialogTitle: "แจ้งเตือน",
  referDialogWording: "กรณีที่มีการออกใบแจ้งหนี้ หรือ ลงบันทึกบัญชีแล้ว จะไม่สามารถแก้ไขข้อมูลการจดมิเตอร์ได้ ",

  cancelMeter: "ยกเลิกการแก้ไขเลขจดครั้งนี้",
  cancelMeterSuccess: "ยกเลิกการแก้ไขเลขจดครั้งนี้สำเร็จ",
  validateMeterIncorrect: "มิเตอร์ :meterCode เลขจดมิเตอร์ไม่ถูกต้อง",
  validateRoleInsertUpdate: "คุณไม่มีสิทธิ์ สร้าง หรือ แก้ไขมิเตอร์",
};
