import { useStore } from "vuex";
import { ref, computed } from "vue";
export default function useGetAccountingDetail() {
  const store = useStore();
  let searchTextAccountingDetail = ref(null);

  const accountingDetails = computed(() => {
    return store.getters["shared/accountingDetailAccounts"];
  });

  const accountingDetailIsLastPage = computed(() => {
    return store.getters["shared/accountingDetailIsLastPage"];
  });

  const filteredAccountingDetails = computed(() => {
    if (!searchTextAccountingDetail.value) {
      //ช่องว่าง
      const result = [...accountingDetails.value];
      return result;
    } else {
      return accountingDetails.value.filter((accounting) => {
        return accounting.searchText
          .toLowerCase()
          .includes(searchTextAccountingDetail.value.toLowerCase());
      });
    }
  });

  const searchAccountingDetail = function (event) {
    // console.log(event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchTextAccountingDetail.value = "";
      searchTextAccountingDetail.value = null;
    } else {
      searchTextAccountingDetail.value = event.query;
    }
  };

  let isSharedAccountingDetailReady = ref(false);

  const getAllAccountingDetails = async function () {
    if (!accountingDetailIsLastPage.value) {
      await store.dispatch("shared/getAllAccountingDetails");
    }
    isSharedAccountingDetailReady.value = true;
  };

  const getAccountingDetailByCode = function (code) {
    return accountingDetails.value.find((accounting) => {
      return accounting.code.trim().toLowerCase() === code.trim().toLowerCase();
    });
  };

  const refreshAccountingDetails = async function () {
    isSharedAccountingDetailReady.value = false;
    await store.dispatch("shared/resetAccountingDetail");
    await getAllAccountingDetails();
  };


  // onMounted(async () => {
  //   await getAllAccountingDetails();
  // });

  return {
    searchAccountingDetail,
    filteredAccountingDetails,
    searchTextAccountingDetail,
    getAccountingDetailByCode,
    isSharedAccountingDetailReady,
    accountingDetails,
    getAllAccountingDetails,
    refreshAccountingDetails
  };
}
