export default {
    setFormVersion(state, payload) {
      state.formVersions = payload.data;
      state.totalPage = payload.page.pageCount;
      state.currentPage = payload.page.currentPage;
    },
    setSelectedFormVersion(state, payload) {
      state.selectedFormVersion = payload;
    },
    resetFormVersion(state) {
      state.formVersions = [];
      state.totalPage = 0;
      state.currentPage = 0;
    },
};