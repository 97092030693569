<template>
  <base-toolbar
    :title="this.title"
    :dataButtons="dataButtons"
    @goBack="goBack()"
    @save="save()"
    @createNewDocument="arkForCreateNewDocument()"
    @gotoDelete="askForDelete"
  ></base-toolbar>
  <base-dialog
    v-model:visible="showConfirmDialog"
    :classTitle="baseDialogClassTitle"
    :dataButtons="dataButtonDialog"
    :titleConfirm="confirmTitle"
    :wordingConfirm="confirmWording"
    @confirm="showConfirmDialog = false"
    @close="showConfirmDialog = false"
    @goto-create="createNewDocument"
    @goto-delete="deleteMeter"
    @back="gotoListing"
  ></base-dialog>
  <base-container>
    <div class="surface-card shadow-1 border-round my-5">
      <base-card
        :titleHeader="lang.updateMeterData"
        :isVisibleExpandButton="true"
        :isHideBackground="true"
        :isVisibleCard="true"
      >
        <section class="flex flex-wrap fontlabel" :class="textFormClass">
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.code }} *</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="code"
                :placeholder="lang.code"
                :class="{ 'p-invalid': codeError }"
              />
              <small v-if="codeError" id="unit-help" class="p-error">
                {{ lang.validateNoCode }}
              </small>
            </div>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.unitCode }} *</label>
            <div class="mt-1">
              <PvSkeleton
                v-if="!isSharedUnitReady"
                class="inputfield w-full mt-2"
                height="39px"
              />
              <base-unit-auto-complete
                v-show="isSharedUnitReady"
                id="unit"
                v-model="selectedUnit"
                class="inputfield w-full mt-1"
                @load-finish="unitDataReady"
                @item-select="unitSelected()"
                :class="{ 'p-invalid': unitError }"
                :disabled="!isCreateDocument"
                :isValidateActiveStatus="true"
                @is-inactive="initiateData"
              />
              <small v-if="unitError" id="unit-help" class="p-error">
                {{ lang.validateNoUnitSelect }}
              </small>
            </div>
          </div>
          <!-- <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
						<label>{{ lang.productCode }} *</label>
						<div class="mt-1">
							<PvAutoComplete
								:dropdown="true"
								:suggestions="filteredProducts"
								field="codeAndName"
								class="inputfield w-full mt-1"
								forceSelection
								@complete="searchItems($event)"
								v-model="selectedProduct"
								:class="{ 'p-invalid': productError }"
								@item-select="productSelected()"
								:disabled="!isCreateDocument" />
							<small v-if="productError" id="unit-help" class="p-error">
								{{ lang.validateNoProduct }}
							</small>
						</div>
					</div> -->
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.activeDate }} *</label>
            <div class="mt-1">
              <PvCalendar
                id="receiptDate"
                v-model="activeDate"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
                :class="{ 'p-invalid': activeDateError }"
                @date-select="activeDateError = false"
              />
            </div>
            <small v-if="activeDateError" id="active-date-help" class="p-error">{{
              lang.validateActiveDate
            }}</small>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.remark }}</label>
            <div class="mt-1">
              <PvInputText
                type="text"
                class="inputfield w-full mt-1"
                v-model="remark"
                :placeholder="lang.remark"
              />
            </div>
          </div>
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.active }}</label>
            <div class="mt-3">
              <PvInputSwitch
                v-model="active"
                trueValue="A"
                falseValue="N"
              ></PvInputSwitch>
            </div>
          </div>
        </section>
      </base-card>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import usePageDirty from "../../../hooks/pageDirty";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
export default {
  props: ["id", "meterType", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.master.meter.edit, ...lang.th.master.meter.listing };
      } else {
        return { ...lang.en.base, ...lang.en.master.meter.edit, ...lang.en.master.meter.listing };
      }
    });

    const { compareObject, isCompareObject } = usePageDirty();

    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      compareObject,
      isCompareObject,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
    };
  },

  data() {
    return {
      code: "",
      codeError: false,
      selectedUnit: null,
      unitError: "",
      isSharedUnitReady: false,
      productCode: "",
      activeDate: "",
      remark: "",
      active: "A",
      // dataButtons: [
      //   {
      //     id: "newCaption",
      //     caption: this.lang.newMeter,
      //     iconColor: "white",
      //     action: "createNewDocument",
      //     isShow: true,
      //     icon: "icon-plus-add",
      //     class: "p-button-secondary",
      //   },
      //   {
      //     id: "saveCaption",
      //     caption: this.lang.saveMeter,
      //     iconColor: "white",
      //     action: "save",
      //     isShow: true,
      //     class: "ml-2",
      //   },
      // ],
      productList: [],
      selectedProduct: null,
      filteredProducts: null,
      productError: false,
      showConfirmDialog: false,
      confirmTitle: "",
      confirmWording: "",
      objToCompare: null,
      baseDialogClassTitle: "",
      isDelete: false,
      isDeleteSuccess: false,
      isCreateNew: false,
      activeDateError: false,
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    await this.getProduct();
    if (!this.isCreateDocument) {
      await this.getMeter();
    }
    // else {
    //   await this.initiateData();
    // }
    // this.setLoading(false);
    await this.checkShouldSetData();
  },
  methods: {
    async setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    unitDataReady() {
      this.isSharedUnitReady = true;
    },
    goBack() {
      this.setLocalSearch();
      if (this.objToCompare) this.compareObject(this.objToCompare, this.payload);
      this.$router.push({name: "master-meter", params:{ meterType: this.meterType }});
    },
    async setCompareData() {
      let type = "save";
      if (!this.isCreateDocument) {
        type = "update";
      }
      const objToCompare = {
        type: type,
        activeDate: this.selectedActiveDate,
        remark: this.remark,
        isActinve: this.active,
        meterCode: this.code,
        roomId: this.selectedUnit ? this.selectedUnit.id : "",
        projectId: this.selectedProject.id,
        meterType: this.meterType,
        id: this.id,
      };
      this.objToCompare = objToCompare;

      this.compareObject(objToCompare, this.payload);
    },
    async initiateData() {
      await this.$store.dispatch("meter/resetMeter");
      this.code = "";
      this.selectedUnit = null;
      this.activeDate = new Date();
      this.remark = "";
      this.active = "A";
      this.selectedProduct = this.product.find((pro) =>
        pro.code.trim().includes(this.meterType + "0")
      );
      this.activeDateError = false;
      this.codeError = false;
      this.unitError = false;
      // this.setLoading(false);
    },
    async getMeter() {
      // this.setLoading(true);
      try {
        let where = {
          projectId: this.selectedProject.id,
          id: this.id,
        };

        const payload = {
          page: 1,
          limit: 10,
          where,
        };

        // console.log("getPurchaseReceiptList", payload);
        await this.$store.dispatch("meter/getMeter", payload);
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.confirmTitle = this.lang.deleteMeterSuccessTitle;
        this.confirmWording = error;
        this.showConfirmDialog = true;
      }
    },
    async getProduct() {
      try {
        let where = {
          projectId: this.selectedProject.id,
          code: this.meterType,
        };

        const payload = {
          page: 1,
          limit: 100,
          where,
        };

        await this.$store.dispatch("meter/getProduct", payload);
        this.productList = this.product;
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.confirmTitle = this.lang.deleteMeterSuccessTitle;
        this.confirmWording = error;
        this.showConfirmDialog = true;
      }
    },
    async setDataUnit() {
      this.selectedUnit = this.sharedUnits.find((unit) => unit.id === this.selectedMeter.unitId);
    },
    async setData() {
      if (this.selectedMeter && this.product) {
        const meter = this.selectedMeter;
        // const product = this.product;
        // this.productList = product;

        this.selectedProduct = this.product.find((pro) => pro.code.trim().includes("W0"));
        // console.log(this.product.find((pro) => pro.code.trim().includes("W0")));

        this.code = meter.code.trim();
        // this.selectedUnit = this.sharedUnits.find((unit) => unit.id === meter.unitId);
        this.activeDate = new Date(meter.activeDate);
        this.remark = meter.remark;
        this.active = meter.isActive;
        // await this.setCompareData();
        // await this.setLoading(false);
        this.setLoading(false);
      }
    },
    // setProduct() {
    // 	if (this.product && this.selectedUnit) {
    // 		let unitProduct = this.selectedUnit.unitDetails.find(
    // 			(unitPro) => unitPro.productCode.charAt(0) === this.meterType.trim()
    // 		);
    // 		if (unitProduct) {
    // 			let searchProduct = this.product.find((pro) => pro.id === unitProduct.productId.trim());
    // 			if (searchProduct) {
    // 				this.selectedProduct = searchProduct;
    // 			} else {
    // 				this.$toast.add({
    // 					severity: "warn",
    // 					summary: this.lang.notificationDialogHeader,
    // 					detail: this.lang.warrningUnit,
    // 					life: 3000,
    // 				});
    // 			}
    // 		} else {
    // 			this.$toast.add({
    // 				severity: "warn",
    // 				summary: this.lang.notificationDialogHeader,
    // 				detail: this.lang.warrningUnit,
    // 				life: 3000,
    // 			});
    // 		}
    // 	}
    // },
    async checkShouldSetData() {
      // if (this.isSharedUnitReady && this.selectedMeter && this.product) {
      //   await this.setData();
      // }
      if (this.isSharedUnitReady && this.selectedMeter && this.product) {
        await this.setData();
        await this.setDataUnit();
      } else if (!this.isSharedUnitReady && this.selectedMeter && this.product) {
        await this.setData();
      } else if (this.isSharedUnitReady && this.isCreateDocument) {
        this.setLoading(false);
      }
      await this.setCompareData();
    },
    validateRole() {
      if(this.isCreateDocument && !this.isRoleCreate) {
				this.baseDialogClassTitle = "";
				this.confirmTitle = this.lang.notificationDialogHeader
				this.confirmWording = this.lang.validateRoleInsert;
				this.showConfirmDialog = true;
				return true;
			} else if(!this.isCreateDocument && !this.isRoleUpdate) {
				this.baseDialogClassTitle = "";
				this.confirmTitle = this.lang.notificationDialogHeader
				this.confirmWording = this.lang.validateRoleUpdate;
				this.showConfirmDialog = true;
				return true;
			}
			return false;
    },
    async save() {
      if (this.validateRole()) {
				return;
			}
      if (this.validateData()) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.payload.type === "save") {
          // console.log("save", this.payload);
          await this.$store.dispatch("meter/addMeter", this.payload);
        } else if (this.payload.type === "update") {
          // console.log("update", this.payload);
          await this.$store.dispatch("meter/updateMeter", this.payload);
        }
        this.clearBaseDialog();
        this.baseDialogClassTitle = "p-text-success";
        this.confirmTitle = this.lang.saveSuccess;
        this.confirmWording = this.lang.code + " : " + this.code;
        this.showConfirmDialog = true;

        if (this.isCreateDocument) {
          this.$router.replace({name: "master-meter-edit", params:{ meterType: this.meterType, id: this.selectedMeter.id }});
          const objToCompare = {
            type: "update",
            activeDate: this.selectedActiveDate,
            remark: this.remark,
            isActinve: this.active,
            meterCode: this.code,
            roomId: this.selectedUnit ? this.selectedUnit.id : "",
            projectId: this.selectedProject.id,
            meterType: this.meterType,
            id: this.selectedMeter.id,
          };
          this.objToCompare = objToCompare;
        }

        this.setLoading(false);
      } catch (error) {
        let massage = error.message;
        if (error.code == "101") {
          massage = this.lang.errorMeterDuplicate;
        } else if (error.code == "111") {
          massage = this.lang.validateRoleInsert;
        } else if (error.code == "112") {
          massage = this.lang.validateRoleUpdate;
        } else if (error.code == "127") {
          massage = this.lang.errorMeterActiveDate;
        }
        this.baseDialogClassTitle = "";
        this.confirmTitle = this.lang.notificationDialogHeader;
        this.confirmWording = massage;
        this.showConfirmDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    // searchItems(event) {
    // 	let query = event.query;
    // 	if (!query.trim().length) {
    // 		const result = [...this.productList];
    // 		this.filteredProducts = result;
    // 	} else {
    // 		this.filteredProducts = this.productList.filter((product) => {
    // 			return product.searchText.toLowerCase().includes(query.toLowerCase());
    // 		});
    // 	}
    // },
    arkForCreateNewDocument() {
      this.clearBaseDialog();
      if(!this.isRoleCreate) {
        this.baseDialogClassTitle = "";
        this.confirmTitle = this.lang.notificationDialogHeader
        this.confirmWording = this.lang.validateRoleInsert;
        this.showConfirmDialog = true;
        return;
      }
      let result = this.isCompareObject(this.objToCompare, this.payload);
      this.isCreateNew = true;
      if (result) {
        this.confirmTitle = this.lang.notificationDialogHeader;
        this.confirmWording = this.lang.askForCreateNewDocument;
        this.showConfirmDialog = true;
      } else {
        this.createNewDocument();
      }
    },
    createNewDocument() {
      if (!this.isCreateDocument) {
        this.$router.replace({name: "master-meter-edit", params:{ meterType: this.meterType, id: "create" }});
      }
      this.initiateData();
      this.showConfirmDialog = false;
    },
    wordingValidate() {
      if (!this.code) {
        return this.lang.validateFieldNoValue + this.lang.code;
      }
      if (!this.selectedUnit) {
        return this.lang.validateDropDownNoSelect + this.lang.unitCode;
      }
      if (!this.activeDate) {
        return this.lang.validateDropDownNoSelect + this.lang.activeDate;
      }
    },
    validateData() {
      let returnStatus = false;
      if (!this.code) {
        this.codeError = true;
        returnStatus = true;
      }
      if (!this.selectedUnit) {
        this.unitError = true;
        returnStatus = true;
      }
      if (!this.activeDate) {
        this.activeDateError = true;
        returnStatus = true;
      }
      // if (!this.selectedProduct) {
      // 	this.productError = true;
      // 	return true;
      // }
      if(returnStatus) {
        this.baseDialogClassTitle = "p-error";
        this.confirmTitle = this.lang.validateSaveTitle;
        this.confirmWording = this.wordingValidate();
        this.showConfirmDialog = true;
			}
      return returnStatus;
    },
    unitSelected() {
      this.unitError = false;
      // this.selectedProduct = null;
      // this.setProduct();
    },
    // productSelected() {
    // 	this.productError = false;
    // },
    setLocalSearch() {
      let storageName = `erp_search_MeterList_${this.selectedProject.code}_${this.userCode}`;
      const localSearch = localStorage.getItem(storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      localStorage.setItem(storageName, JSON.stringify(search));
    },
    askForDelete() {
      this.clearBaseDialog();
      if(!this.isCreateDocument && !this.isRoleDelete) {
        this.baseDialogClassTitle = "";
        this.confirmTitle = this.lang.notificationDialogHeader
        this.confirmWording = this.lang.validateRoleDelete;
        this.showConfirmDialog = true;
        return;
      }
      this.isDelete = true;
      this.confirmTitle = this.lang.deleteMeterTitle;
      this.confirmWording = this.lang.askForDeleteMeter;
      this.showConfirmDialog = true;
    },
    async deleteMeter() {
      this.setLoading(true);
      try {
        let payload = {
          id: this.id,
          projectId: this.selectedProject.id,
          meterType: this.meterType,
        };
        await this.$store.dispatch("meter/deleteMeter", payload);

        this.isDelete = false;
      } catch (error) {
        let massage = error.message
        if (error.code == "126") {
          massage = this.lang.errorMeterUsage;
        } else if (error.code == "116") {
          massage = this.lang.validateRoleDelete;
        }
        this.isDelete = false;
        this.baseDialogClassTitle = "";
        this.confirmTitle = this.lang.notificationDialogHeader;
        this.confirmWording = massage;
        this.showConfirmDialog = true;
        this.setLoading(false);
        return;
      }
      this.isDeleteSuccess = true;
      this.baseDialogClassTitle = "p-text-success";
      this.confirmTitle = this.lang.deleteMeterSuccessTitle;
      this.confirmWording = this.lang.deleteMeterSuccess;
      this.showConfirmDialog = true;
      this.setLoading(false);
    },
    clearBaseDialog() {
      this.baseDialogClassTitle = "";
      this.confirmTitle = this.lang.notificationDialogHeader;
      this.confirmWording = "";
      this.isDelete = false;
      this.isDeleteSuccess = false;
      this.isCreateNew = false;
    },
    gotoListing() {
      this.$router.push({name: "master-meter"});
    },
    
  },
  computed: {
    title() {
      return this.isCreateDocument ? this.lang.addTitle : this.lang.editTitle;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    isCreateDocument() {
      return this.id === "create";
    },
    selectedMeter() {
      return this.$store.getters["meter/selectedMeter"];
    },
    product() {
      return this.$store.getters["meter/product"];
    },
    sharedUnits() {
      return this.$store.getters["shared/unitUnits"];
    },
    selectedActiveDate() {
      let activeDate = null;
      if (this.activeDate) {
        activeDate = this.formatDateToISOString(this.activeDate);
      } else {
        activeDate = this.formatDateToISOString(new Date());
      }
      return activeDate;
    },
    payload() {
      let type = "save";
      let payload = {};
      if (!this.isCreateDocument) {
        type = "update";
      }
      payload = {
        type: type,
        activeDate: this.selectedActiveDate,
        remark: this.remark,
        isActinve: this.active,
        meterCode: this.code,
        roomId: this.selectedUnit ? this.selectedUnit.id : "",
        projectId: this.selectedProject.id,
        meterType: this.meterType,
        id: this.id,
      };
      return payload;
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "delete",
          action: "gotoDelete",
          type: "delete",
          isDisable: !this.isSharedUnitReady || this.isCreateDocument,
        },
        {
          id: "create",
          action: "createNewDocument",
          type: "create"
        },
        {
          id: "save",
          action: "save",
          type: "save",
          isDisable: !this.isSharedUnitReady,
        },
      ]
      return dataButtons;
    },
    dataButtonDialog() {
      let dataButtonDialog = [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];
      
      if(this.isCreateNew) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "gotoCreate",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-create",
          },
        ]
      } else if(this.isDelete) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "goto-delete",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-delete",
          },
        ]
      } else if(this.isDeleteSuccess) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "back",
          },
        ]
      }

      return dataButtonDialog;
    },
    roleByMenu() {
      if(this.meterType == "W") {
        return this.getRoleByMenuByCode("tmMeterW");
      } else if (this.meterType == "E") {
        return this.getRoleByMenuByCode("tmMeterE");
      } else {
        return this.getRoleByMenuByCode("tmMeterC");
      }
    },
    isRoleCreate() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    isRoleUpdate() {
      if (this.roleByMenu && this.roleByMenu.update == "Y") {
        return true;
      }
      return false;
    },
    isRoleDelete() {
      if (this.roleByMenu && this.roleByMenu.delete == "Y") {
        return true;
      }
      return false;
    },
  },
  watch: {
    isSharedUnitReady() {
      this.checkShouldSetData();
    },
    selectedMeter() {
      this.checkShouldSetData();
    },
    product() {
      this.checkShouldSetData();
    },
    code() {
      this.codeError = false;
    },
    // payload(curValue) {
    // 	if (this.objToCompare) this.compareObject(this.objToCompare, curValue);
    // },
  },
};
</script>
