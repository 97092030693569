export default {
  createReceipt: "Create Receipt",
  editReceipt: "Edit Receipt",
  receiptDetail: "Receipt Detail",
  unitCode: "Unit Code",
  mobileNumber: "Telephone",
  invoiceCode: "Invoice Code",
	invoiceDate: "Invoice Date",
  residentType: "Resident Type",
  owner: "Owner",
  rential: "Rential",
  all: "All",
  deposit: "Deposit",
  pending: "Pending",
  discount:"Discount",
  discountAmount: "Discount Amount",
  
  payAsDefined: "Add items from amount",
  amountToPay: "Amount to pay",

  pendingList: "Pending List",
  calculatePenalty: "Calculate Penalty",
  calculatePenaltyDate: "Do you want calculate penalty until?",

  payType: "Payment Type",
	cashType: "Cash",
	transferType: "Transfer",
	chequeType: "Cheque",
	creditCardType: "Credit Card",
	depositType: "Deposit",
	ePayType: "E_Pay",
	otherType: "Other",
  receipts: "Receipts",
	transfer: "Transfer",
	receive: "Receive",
	bank: "Bank",
  branch: "Branch",
  transferDate: "Transfer Date",
  chequeDate: "Cheque Date",
	transferNo: "Transfer Number",
	chequeNo: "Cheque Number",
	creditCardNo: "Credit Card Number",
	paidBy: "Paid by",
  paidByAddress: "Paid by address",
  
  discountPlaceholder: "ex. 10% or 100",
  validateInvalidDiscount: "Discount format invalid. Discount can be enter only numbers and %.",
  validateNoRemarkKeyCard: "Please enter Serial No. in description.",
  validateNetAmount: "Discount amount more than paid amount.",
	product: "Product",
  productCode: "Product Code",
  productName: "Product Name",
  paySelected: "Pay Selected",
  cancelReason: "Cancel reason",
  validateNoCancelReason: "Please enter cancel reason.",
  
  validateNoDeposit : "You don't have enough deposit left amount.",
  depositAmount : "There is a deposit of :amount baht.",
  askToUseDeposit : "Do you want to use deposit or not?",
	validateNetTotalAmount: "Net Total Amount not less than 0.",
  validateNoBank: "Please select bank",
  validateNoBankBranch: "Please enter bank branch.",
  validateNoPayReference: "Please enter:no.",
  askToUseGuarantee: "Do you want to use guarantee or not?",
  itemsGuarantee: "Please select guarantee.",
  balanceAmount: "Balance Amount",
  selectedConfirm: "Selected Confirm",
  selectOtherReceipt: "Please select other receipt",
  statusReceipt: "Receipt status",
  onlyCanceled: "Canceled only",
  allReceipt: "All Receipt",
	referCode: "Refer Code",
	validateReferCode: "enter refer code",
  please: "Please",
  createReceiptSuccess: "Create receipt success",
  editReceiptSuccess: "Edit receipt success",
  askForCreateNewReceipt: "Are you sure you want to discard change and create new document?",
  calculateSuccess: "Calculated Success",
  calculatePenaltySuccess: "Calculated Penalty Success",
  cancelReceiptSuccess: "Cancel Receipt :code Success",
	validateNoResident: "This room doesn't have resident.",
  addEdit: "Add/ Edit item",
  createNewReceipt: "Create New Receipt",
  validateNoEnterBank: "Please ente bank.",
  generateProduct:"Generate Product By Input Number",
  copyValidate:"Can't copy pending item.",
  titleDetail: "Receipt Detail",
  titlePay: "Pay Detail",
  remark1: "Remark (1)",
  remark2: "Remark (2)",
  selectPendingList: "Select Pending",
  validatePayType : "If you want to create a receipt with a value of 0.00 baht, please select the payment method as either Cash or Deduction from other sources only.",
  validatePayTypeOther: "This document is paid by other type",
  remarkDeductGuarantee: "Deduct Guarantee No.",
  remarkDeductReceipt: "Deduct Receipt No.",
  remarkDeductOther: "Deduct Other",
};
