export default{
	residentInformation: "ข้อมูลสมาชิก/ลูกค้า",
    title: "รายการสมาชิก/ลูกค้า",
    add: "สร้างสมาชิก/ลูกค้า",
    
    residentCode: "รหัสสมาชิก/ลูกค้า",
    residentName: "ชื่อสมาชิก/ลูกค้า",
    fieldName: "ชื่อ",
    residentId: "รหัสบัตรประชาชน",
	residentEmail: "อีเมล",
    
    generalInformation: "ข้อมูลทั่วไป",
    addressInformation: "ข้อมูลที่อยู่",
    receiptAddress: "ที่อยู่สำหรับออกใบแจ้งหนี้/ใบเสร็จ",
    searchText: "กรอกข้อมูลเพื่อค้นหา",
    residentAll: "ทั้งหมด",

    importResident: "นำเข้าสมาชิก/ลูกค้า",
};