<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12 14.975V13.975C11.9853 13.1749 12.2967 12.4033 12.8625 11.8375C13.4283 11.2717 14.1999 10.9603 15 10.975H18C18.8001 10.9603 19.5717 11.2717 20.1375 11.8375C20.7033 12.4033 21.0147 13.1749 21 13.975V14.975"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M11 5H5C3.89543 5 3 5.89543 3 7V12C3 13.1046 3.89543 14 5 14H9"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<circle
			r="2.5"
			transform="matrix(-1 0 0 1 16.542 5.5)"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M7 8H9"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M15.4081 17H18"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M18 17V19.5561"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M17.6189 17C17.0134 18.1302 16.0945 19.0615 14.9727 19.6823C13.4301 20.5311 11.6114 20.725 9.92445 20.2208C8.23755 19.7165 6.82368 18.5561 6 17"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: 24,
		},
		height: {
			type: [Number, String],
			default: 24,
		},
		iconColor: {
			type: String,
			default: "secondary",
		},
	},
	computed: {
		strokeColor() {
			return "p-stroke-" + this.iconColor;
		},
	},
};
</script>

<style lang="scss" scoped>
svg {
	display: inline-block;
	vertical-align: baseline;
	margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
