<template>
  <PvBlockUI fullScreen :blocked="isLoading">
    <PvProgressSpinner strokeWidth="5" class="center" v-if="isLoading"/>
  </PvBlockUI>
</template>

<script>
export default {
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
};
</script>


<style scoped>
.center {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  z-index: 10000;
}
</style>