<template>
  <base-toolbar :title="title" @goBack="goBack()"></base-toolbar>
  <base-dialog
    v-model:visible="showValidateDialog"
    :titleConfirm="lang.notificationDialogHeader"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    @confirm="showValidateDialog = false"
    @close="showValidateDialog = false"
  ></base-dialog>

  <base-container>
    <div class="surface-card shadow-2 p-5 border-round mt-5" :class="textFormClass">
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ title }}</label>
      </div>

      <PvDivider></PvDivider>

      <section
        class="flex flex-wrap justify-content align-items-center mt-0 pt-0"
        :class="textFormClass"
      >
        <div class="flex lg:col-6 md:col-12 col-12 mt-0 p-0 mr-2">
          <div class="col-12 p-0">
            <!-- <label for="searchText">{{ lang.labelSearchText }}</label> -->
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchText"
                :placeholder="lang.searchTextPlaceholder"
                @keypress="checkInputSearch"
                autofocus
              />
            </span>
          </div>
        </div>

        <div class="lg:col-2 md:col-12 col-12 mt-0 mr-2 p-0">
          <!-- <label for="searchText">{{ lang.type }}</label> -->
          <PvDropdown
            id="filterSearch"
            class="inputfield w-full mr-2"
            :options="filterSearch"
            optionLabel="name"
            optionValue="code"
            v-model="selectedFilterSearch"
            scrollHeight="300px"
            @change="changeFilterSearch"
          />
        </div>

        <!-- <div class="mt-3"> -->
        <div>
          <PvButton @click="getReceipts()">
            <span class="font-normal">{{ lang.search }}</span>
          </PvButton>
        </div>
      </section>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="receipts"
          v-model:selection="selectedReceipt"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(this.selectedReceipt.id)"
          scrollable
        >
          <PvColumn
            field="code"
            :header="lang.documentCode"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <u>{{ slotProps.data.code }}</u>
            </template>
          </PvColumn>

          <PvColumn
            field="date"
            :header="lang.date"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.date) }}
            </template>
          </PvColumn>

          <PvColumn
            field="unitCode"
            :header="lang.unit"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />

          <PvColumn
            field="unitAddressNo"
            :header="lang.address"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />

          <PvColumn
            field="customerName"
            :header="lang.customerName"
            style="min-width: 15rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
          </PvColumn>

          <PvColumn
            field="payType"
            :header="lang.type"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />

          <PvColumn
            field="netAmount"
            :header="lang.price"
            style="min-width: 10rem; height: 56px"
            headerClass="h-right"
            bodyClass="text-right"
            sortable
          >
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.netAmount) }}
            </template>
          </PvColumn>

          <PvColumn
            style="min-width: 4rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            :frozen="true"
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0"
                icon="pi pi-pencil"
                @click="gotoEdit(slotProps.data.id)"
                :disabled="!isRoleSelect"
              ></PvButton>
            </template>
          </PvColumn>
          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>

          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
            >
              <template #start="slotProps">
                {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{
                  totalPage
                }}
              </template>
            </PvPaginator>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import useGetRoleByBook from "../../../hooks/getRoleByBook.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.visitorControl.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.visitorControl.listing,
        };
      }
    });
    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getRoleByBookByCode,
      isSharedRoleByBookReady,
    };
  },
  data() {
    return {
      isLoading: false,
      filterSearch: [
        { name: this.lang.dropdownCash, code: "cash" },
        { name: this.lang.dropdownTransfer, code: "transfer" },
        { name: this.lang.dropdownCheque, code: "cheque" },
        { name: this.lang.dropdownCreditCard, code: "creditCard" },
      ],
      selectedFilterSearch: "cash",
      searchText: "",
      selectedReceipt: null,
      selectedPage: 1,
      rowsPerPage: 10,
      dateStart: null,
      dateEnd: null,
      storageName: "",
      showValidateDialog: false,
      validateWording: "",
      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ],
    };
  },
  async mounted() {
    let date = new Date();
    this.dateStart = new Date(date.setDate(date.getDate() - 15));
    this.dateEnd = new Date();
    this.roleByBookDataReady();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.setLocalSearchReceiptList();
      this.$router.push({name: "ar-receipt"});
    },
    setLocalSearchReceiptList() {
      let storageName = `erp_search_ReceiptList_${this.selectedProject.code}_${this.userCode}`;
      const localSearch = localStorage.getItem(storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      localStorage.setItem(storageName, JSON.stringify(search));
    },
    async roleByBookDataReady() {
      if (this.isSharedRoleByBookReady) {
        this.initiateData();
        await this.setLocalSearch();
        await this.getReceipts();
      }
    },
    initiateData() {
      let date = new Date();
      this.dateStart = new Date(date.setDate(date.getDate() - 15));
      this.dateEnd = new Date();
      this.searchText = "";
      this.selectedReceipt = null;
      this.storageName = `erp_search_visitor_control_list_${this.selectedProject.code}_${this.userCode}`;
    },
    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      if (localSearch) {
        let search = JSON.parse(localSearch);
        if (search.entry === "entry") {
          if (search.dateStart != null && search.dateStart != "") {
            this.dateStart = new Date(search.dateStart);
          } else this.dateStart = "";
          if (search.dateEnd != null && search.dateEnd != "") {
            this.dateEnd = new Date(search.dateEnd);
          } else this.dateEnd = "";
          if (search.payType != null && search.payType != "") {
            this.selectedFilterSearch = search.payType.toLowerCase();
          } else this.selectedFilterSearch = "cash";
          if (search.code != null && search.code != "") {
            this.searchText = search.code;
          } else this.searchText = "";
          search.entry = "";
          localStorage.setItem(this.storageName, JSON.stringify(search));
        }
      }
    },
    async checkInputSearch(event) {
      const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const keyPressed = event.key;
      if (this.selectedType == "netAmount") {
        keysAllowed.push(".");
      }
      if (event.key != "Enter" && !keysAllowed.includes(keyPressed)) {
        event.preventDefault();
      }
      if (event.key == "Enter") {
        await this.getReceipts();
      }
    },
    changeFilterSearch() {
      this.initiateData();
    },
    async getReceipts() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("arReceipt/getReceipts", this.payload);
        localStorage.setItem(this.storageName, JSON.stringify(this.payload.where));
      } catch (error) {
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
      }
      this.isLoading = false;
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getReceipts();
    },
    async gotoEdit(id) {
      if (this.isRoleSelect) {
        this.$router.push({name: "visitor-control-edit", params:{id: id}});
      } else {
        this.validateWording = this.lang.validateRoleSelect;
        this.showValidateDialog = true;
      }
    },
  },
  computed: {
    title() {
      return this.lang.title;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    roleByBook() {
      return this.getRoleByBookByCode("INV");
    },
    isRoleSelect() {
      if (this.roleByBook && this.roleByBook.select == "Y") {
        return true;
      }
      return false;
    },
    isRoleUpdate() {
      if (this.roleByBook && this.roleByBook.update == "Y") {
        return true;
      }
      return false;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    payload() {
      let where = {
        projectId: this.selectedProject.id,
        isVisitorControl: true,
        unitCode: "Z_Other",
        dateStart: this.selectedDateStart,
        dateEnd: this.selectedDateEnd,
      };
      if (this.selectedFilterSearch == "cash") {
        where = {
          ...where,
          payType: "Cash",
        };
      } else if (this.selectedFilterSearch == "transfer") {
        where = {
          ...where,
          payType: "Transfer",
        };
      } else if (this.selectedFilterSearch == "cheque") {
        where = {
          ...where,
          payType: "Cheque",
        };
      } else if (this.selectedFilterSearch == "creditCard") {
        where = {
          ...where,
          payType: "CreditCard",
        };
      }
      if (this.searchText != "") {
        where = {
          ...where,
          code: this.searchText,
        };
      }
      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };
      return payload;
    },
    receipts() {
      return this.$store.getters["arReceipt/receipts"];
    },
    totalPage() {
      return this.$store.getters["arReceipt/totalPage"];
    },
    currentPage() {
      return this.$store.getters["arReceipt/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
  },
  watch: {
    isSharedRoleByBookReady() {
      this.roleByBookDataReady();
    },
  },
};
</script>
