<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <section class="flex flex-wrap" :class="textFormClass">
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="dateStart">{{ lang.dateStart }}</label>
        <PvCalendar
          id="dateStart"
          v-model="dateStart"
          class="inputfield w-full mt-1"
          showIcon
          dateFormat="dd/mm/yy"
          :class="{ 'p-invalid': dateStartError }"
          @date-select="dateStartError = false"
        />
        <small v-if="dateStartError" id="unit-help" class="p-error">{{
          lang.validateDateSelect
        }}</small>
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="dateEnd">{{ lang.dateEnd }}</label>
        <PvCalendar
          id="dateEnd"
          v-model="dateEnd"
          class="inputfield w-full mt-1"
          showIcon
          dateFormat="dd/mm/yy"
          :class="{ 'p-invalid': dateEndError }"
          @date-select="dateEndError = false"
        />
        <small v-if="dateEndError" id="unit-help" class="p-error">{{
          lang.validateDateSelect
        }}</small>
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="accountListStart">{{ lang.accountCodeFrom }}</label>
        <base-accounting-detail-auto-complete
          id="accountingFrom"
          v-model="selectAccountCodeStart"
          class="inputfield w-full mt-2"
          @load-finish="accountingDataReady"
          :isLoadingData="true"
          :isValidateActiveStatus="false"
        />
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="accountListEnd">{{ lang.to }}</label>
        <base-accounting-detail-auto-complete
          id="accountingTo"
          v-model="selectAccountCodeEnd"
          class="inputfield w-full mt-2"
          @load-finish="accountingDataReady"
          :isValidateActiveStatus="false"
        />
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="descriptionPrint">{{ lang.descriptionPrint }}</label>
        <PvDropdown
          class="inputfield w-full mt-1"
          :options="descriptionPrint"
          :showClear="clearDescriptionPrint"
          optionLabel="name"
          optionValue="code"
          v-model="selectDescriptionPrint"
        />
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="descriptionLinePrint">{{
          lang.descriptionLinePrint
        }}</label>
        <PvDropdown
          class="inputfield w-full mt-1"
          :options="descriptionLinePrint"
          :showClear="clearDescriptionLinePrint"
          optionLabel="name"
          optionValue="code"
          v-model="selectDescriptionLinePrint"
        />
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="newPagePrint">{{ lang.newAccountNewPage }}</label>
        <PvDropdown
          class="inputfield w-full mt-1"
          :options="newPagePrint"
          :showClear="clearNewPagePrint"
          optionLabel="name"
          optionValue="code"
          v-model="selectNewPagePrint"
        />
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="accountMovementPrint">{{
          lang.accountMovementPrint
        }}</label>
        <PvDropdown
          class="inputfield w-full mt-1"
          :options="accountMovementPrint"
          :showClear="clearAccountMovementPrint"
          optionLabel="name"
          optionValue="code"
          v-model="selectAccountMovementPrint"
        />
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="statusPrint">{{ lang.statusPrint }}</label>
        <PvDropdown
          class="inputfield w-full mt-1"
          :options="statusPrint"
          :showClear="clearStatusPrint"
          optionLabel="name"
          optionValue="code"
          v-model="selectStatusPrint"
        />
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label for="fileNameText">{{ lang.fileName }}</label>
        <PvInputText
          id="fileNameText"
          type="text"
          class="inputfield w-full mt-1"
          v-model="fileNameText"
        />
      </div>
    </section>

    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
        </div>
      </div>
    </template>
    
  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetFormPrint from "../../hooks/getFormPrint.js";
import useTracking from "../../hooks/tracking";
import useReportRequestValidate from "../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "toast-request"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { getPreviewBlob } = useGetFormPrint();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.PettyCash,
          ...lang.th.gl.accounting.listing,
          ...lang.th.gl.accounting.edit,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.PettyCash,
          ...lang.en.gl.accounting.listing,
          ...lang.en.gl.accounting.edit,
          ...lang.en.gl.reportGl,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getPreviewBlob,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      dateStart: null,
      dateEnd: null,
      selectAccountCodeStart: null,
      selectAccountCodeEnd: null,
      clearBookCodeStart: false,
      clearBookCodeEnd: false,

      descriptionPrint: [
        { name: this.lang.descriptionPrint1, code: "1" },
        { name: this.lang.descriptionPrint2, code: "2" },
        { name: this.lang.descriptionPrint3, code: "3" },
      ],
      selectDescriptionPrint: "3",
      clearDescriptionPrint: false,

      descriptionLinePrint: [
        { name: this.lang.yes, code: "Y" },
        { name: this.lang.no, code: "N" },
      ],
      selectDescriptionLinePrint: "Y",
      clearDescriptionLinePrint: false,

      newPagePrint: [
        { name: this.lang.yes, code: "Y" },
        { name: this.lang.no, code: "N" },
      ],
      selectNewPagePrint: "Y",
      clearNewPagePrint: false,

      accountMovementPrint: [
        { name: this.lang.accountMovementPrint1, code: "1" },
        { name: this.lang.accountMovementPrint2, code: "2" },
      ],
      selectAccountMovementPrint: "1",
      clearAccountMovementPrint: false,

      statusPrint: [
        { name: this.lang.statusPrint1, code: "" },
        { name: this.lang.statusPrint2, code: "1" },
        { name: this.lang.statusPrint3, code: "2" },
      ],
      selectStatusPrint: "",
      clearStatusPrint: false,
      accountCodeStartError: false,
      accountCodeEndError: false,

      isSharedAccountingReady: false,
      dateStartError: false,
      dateEndError: false,
      type: "PDF",
      fileNameText: "ledger_account",
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    validateData() {
      let returnStatus = false;
      if (this.selectedDateStart == null) {
        this.dateStartError = true;
        returnStatus = true;
      }
      if (this.selectedDateEnd == null) {
        this.dateEndError = true;
        returnStatus = true;
      }
      // if (this.selectAccountCodeStart == null) {
      //   this.accountCodeStartError = true;
      //   returnStatus = true;
      // }
      // if (this.selectAccountCodeEnd == null) {
      //   this.accountCodeEndError = true;
      //   returnStatus = true;
      // }
      return returnStatus;
    },
    accountingDataReady() {
      this.isSharedAccountingReady = true;
      this.setLoading(false);
    },
    async initiateData() {
      let date = new Date();
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.dateStartError = false;
      this.dateEndError = false;
      this.selectAccountCodeStart = null,
      this.selectAccountCodeEnd = null,
      this.accountCodeStartError = false,
      this.accountCodeEndError = false,
      this.selectDescriptionPrint = "3",
      this.selectDescriptionLinePrint = "Y",
      this.selectNewPagePrint = "Y",
      this.selectAccountMovementPrint = "1",
      this.selectStatusPrint = "",
      this.type = "PDF";
      this.fileNameText = "ledger_account";
    },
    async download(typeReport) {
      this.type = typeReport;
      if (this.validateData()) {
        return;
      }

      this.setLoading(true);
      try {
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    fileBlob() {
      return this.$store.getters["shared/blob"];
    },
    title() {
      if (this.$store.getters.selectedLanguage === "TH")
        return this.reportObj.nameThai;
      else return this.reportObj.nameEnglish;
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
    fileType() {
      let fileType =
        this.type == "EXCEL"
          ? "_excel.xlsx"
          : (this.type == "JASPEREXCEL")
          ? ".xlsx"
          : ".pdf";
      return fileType;
    },
    fileName() {
      let fileName = this.type == "PDF" ? ".pdf" : ".xlsx";
      return this.fileNameText + fileName;
    },
    jasperPath() {
      let result = this.reportObj.path;
      if(this.type == "PDF" && this.selectNewPagePrint == "Y") {
        result = this.reportObj.path + "_new_page";
      }
      return result;
    },
    payload() {
      let accountList = this.sharedAccounts.length;
      let accountStart = this.sharedAccounts[0];
      let accountEnd= this.sharedAccounts[accountList - 1];

      let payload = {
        ProjectId: this.selectedProject.id,
        DateStart: this.selectedDateStart,
        DateEnd: this.selectedDateEnd,
        AccountStart: this.selectAccountCodeStart ? this.selectAccountCodeStart.code.trim() : accountStart.code.trim(),
        AccountEnd: this.selectAccountCodeEnd ? this.selectAccountCodeEnd.code.trim() : accountEnd.code.trim(),
        Detail: this.selectDescriptionPrint,
        DetailLine: this.selectDescriptionLinePrint,
        NewPage: this.selectNewPagePrint,
        AccountMovement: this.selectAccountMovementPrint,
        Status: this.selectStatusPrint,
        FileName: this.fileName,
        JasperPath: this.jasperPath + this.fileType,
        DocType: this.type,
        UserId: this.userId,
        PrintBy: this.userName,
        RequestId: 0,
        ReportName: "LedgerAccount",
      };
      return payload;
    },
    sharedAccounts() {
      let result = this.$store.getters["shared/accountingDetailAccounts"];
      return result.sort((a, b) => a.code - b.code );
    },
  },
  watch: {
    reportObj() {
      this.initiateData();
    },
  },
};
</script>
