export default {
	setRequests(state, payload){
		state.requests = payload.data;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
		state.totalItem = payload.page.totalItem;
	},
	setSelectedRequests(state, payload) {
		state.selectedRequests = payload;
	},
	resetRequests(state){
		state.requests = [];
		state.totalPage = 0;
		state.currentPage = 1;
		state.totalItem = 0;
	},
	setBlob(state, payload){        
        state.blob = payload
    },
	setCurrentQueue(state, payload){        
        state.currentQueue = payload;
    },
	setPresignedUrl(state, payload){
		state.presignedUrl = payload.data;
	},
	resetPresignedUrl(state){
		state.presignedUrl = null;
	}
};
