<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
  > 
    <!-- <report-print-modal 
      v-model:visible="showReportPrintModal"
      @close="showReportPrintModal = false"
      :projectId="this.selectedProject.id"
      :menuId="this.reportObj.reportMenuId"
      :menuDetailId="this.reportObj.reportMenuDetailId"
      :openReport="this.openReport"
      @path-selected="printReport"
    ></report-print-modal> -->
    <base-report-print-modal
      v-model:visible="showReportPrintModal"
      :search="searchTextReportPrint"
      @close="showReportPrintModal = false"
      @path-selected="printReport"
    ></base-report-print-modal>

    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ this.reportName }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
          <label for="date">{{ lang.date }}</label>
          <PvCalendar
            id="date"
            v-model="date"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateError }"
            @date-select="selectDate"
          />
          <small v-if="dateError" id="date-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
          <label for="dueDate">{{ lang.dueDate }}</label>
          <PvCalendar
            id="dueDate"
            v-model="dueDate"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dueDateError }"
            @date-select="selectDate"
          />
          <small v-if="dueDateError" id="dueDate-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
          <label for="printAmountMoreThan">{{
            lang.printAmountMoreThan
          }}</label>
          <PvInputNumber
            id="printAmountMoreThan"
            class="inputfield w-full mt-1"
            inputClass="text-right"
            v-model="printAmountMoreThan"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            @focus="$event.target.select()"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
          <label for="unitCodeStart">{{ lang.unitCodeStart }}</label>
          <base-unit-auto-complete
            id="unitCodeStart"
            v-model="selectedUnitStart"
            class="inputfield w-full mt-1"
            @load-finish="unitCodeStartReady"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
          <label for="unitCodeEnd">{{ lang.unitCodeEnd }}</label>
          <base-unit-auto-complete
            id="unitCodeEnd"
            v-model="selectedUnitEnd"
            class="inputfield w-full mt-1"
            @load-finish="unitCodeEndReady"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
          <label for="letterBy">{{ lang.letterBy }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="letterBy"
            optionLabel="name"
            optionValue="code"
            v-model="selectedLetterBy"
            :placeholder="lang.letterBy"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
          <label for="printGroup">{{ lang.printGroup }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="printGroup"
            optionLabel="name"
            optionValue="code"
            v-model="selectedPrintGroup"
            :placeholder="lang.printGroup"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0" v-if="isShowOverDue">
          <label for="suspendDate">{{ lang.suspendDate }}</label>
          <PvCalendar
            id="suspendDate"
            v-model="suspendDate"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': suspendDateError }"
            @date-select="selectDate"
          />
          <small
            v-if="suspendDateError"
            id="suspendDate-help"
            class="p-error"
            >{{ lang.validateDateSelect }}</small
          >
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0" v-if="isShowOverDue">
          <label for="overDueDay">{{ lang.overDueDay }}</label>
          <PvInputNumber
            id="overDueDay"
            class="inputfield w-full mt-1"
            inputClass="text-right"
            v-model="overDueDay"
            @focus="$event.target.select()"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText
            id="fileNameText"
            type="text"
            class="inputfield w-full mt-1"
            v-model="fileNameText"
          />
        </div>
      </section>
    </transition>

    <template #footer>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-4 md:col-4 col-12 pl-3 pr-3">
          <PvButton
            icon="pi pi-print"
            :label="lang.print"
            @click="downloadPDF()"
            class="mr-2 mb-2 w-full"
          />
        </div>
      </section>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetReportPrint from "../../../hooks/getReportPrint.js";
import useTracking from "../../../hooks/tracking";
import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: Object,
  },
  emits: ["close", "toast-request"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const {
      formatCurrency,
      formatDate,
      formatDateToISOString,
      formatDateToLocaleDateString,
      formatISODateToLocaleDateString,
    } = useFormat();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
          ...lang.th.ar.notice.report,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.th.gl.reportGl,
          ...lang.en.ar.notice.report,
        };
      }
    });

    const { 
      searchTextReportPrint,
      getPreviewBlob,
      getReportPrints,
      filteredReportPrints
    } = useGetReportPrint();

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      formatDateToLocaleDateString,
      formatISODateToLocaleDateString,
      getPreviewBlob,
      searchTextReportPrint,
      getReportPrints,
      filteredReportPrints,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      date: null,
      dateError: false,
      dueDate: null,
      dueDateError: false,
      selectedUnitStart: null,
      isUnitStartReady: false,
      selectedUnitEnd: null,
      isUnitEndReady: false,
      printAmountMoreThan: 0,
      printGroup: [
        { name: "ใช่", code: "Y" },
        { name: "ไม่ใช่", code: "N" },
      ],
      selectedPrintGroup: "Y",
      letterBy: [
        { name: "ผู้จัดการอาคาร", code: "proj" },
        { name: "ผู้จัดการนิติ", code: "com" },
        { name: "ผู้จัดการฝ่ายบัญชี", code: "acc" },
      ],
      selectedLetterBy: "proj",
      suspendDate: null,
      suspendDateError: false,
      overDueDay: 0,
      showReportPrintModal: false,
      path: "",

      fileNameText: "notice_by_product",
    };
  },
  async created() {
    // this.setLoading(true);
    this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.path = "";
      this.$emit("close");
    },
    unitCodeStartReady() {
      this.isUnitStartReady = true;
    },
    unitCodeEndReady() {
      this.isUnitEndReady = true;
    },
    setReady() {
      if (this.isUnitStartReady && this.isUnitEndReady) {
        this.setLoading(false);
      }
    },
    initiateData() {
      this.date = new Date();
      this.dueDate = new Date();
      this.suspendDate = new Date();

      this.selectedUnitStart = null;
      this.isUnitStartReady = false;
      this.selectedUnitEnd = null;
      this.isUnitEndReady = false;
      this.printAmountMoreThan = 0;
      this.selectedPrintGroup = "Y";
      this.selectedLetterBy = "proj";
      this.overDueDay = 0;

      this.dateError = false;
      this.dueDateError = false;
      this.suspendDateError = false;
      this.path = "";
      this.openReport = false;
      this.fileNameText = this.fileNameNotice
    },

    async downloadPDF() {
      if (this.validateData()) {
        return;
      }

      await this.$store.dispatch("reportMenuDetail/resetReportMenuDetail");

      let where = {
        projectId: this.selectedProject.id,
        reportMenuId: this.reportObj.reportMenuId,
        reportMenuDetailId: this.reportObj.reportMenuDetailId,
      };
      const payload = { 
        page: 1,
        limit: 100,
        where 
      };
      await this.getReportPrints(payload);
      if(this.filteredReportPrints.length == 1) {
        this.printReport(this.filteredReportPrints[0].path);
      } else {
        this.showReportPrintModal = true;
      }
    },
    validateData() {
      if (this.selectedDate == null) {
        this.dateError = true;
        return true;
      }
      if (this.selectedDueDate == null) {
        this.dueDateError = true;
        return true;
      }
      if (this.selectedSuspendDateTh == null) {
        this.suspendDateError = true;
        return true;
      }
      return false;
    },
    selectDate() {
      if (this.selectedDate) this.dateError = false;
      if (this.selectedDueDate) this.dueDateError = false;
      if (this.selectedSuspendDateTh) this.suspendDateError = false;
    },
    async printReport(path) {
      // console.log("path", path);
      if(path) {
        this.path = path;
      }
      this.setLoading(true);
      try {      
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });

        this.setLoading(false);
      }

      this.showReportPrintModal = false;
    }
  },
  computed: {
    payload() {

      let payload = {
        FileName: this.fileNameText + ".pdf",
        JasperPath: this.selectedReport.pathFile,
        DocType: "PDF",
        UserId: this.userId,
        PrintBy: this.userName,
        RequestId: 0,

        ProjectId: this.selectedProject.id,
        NoticeDate: this.selectedDate,
        NoticeDueDate: this.selectedDueDate,
        UnitCodeStart: this.selectedUnitStart
          ? this.selectedUnitStart.code
          : "",
        UnitCodeEnd: this.selectedUnitEnd ? this.selectedUnitEnd.code : "",
        AmountMoreThan: this.printAmountMoreThan,
        IsGroup: this.selectedPrintGroup,
        Condition: this.selectedReport.condition,
        OverDueDay: this.overDueDay,
        IsForApi: "true",
        LetterBy: this.selectedLetterBy,
        SuspendDateTh: this.selectedSuspendDateTh,
        SuspendDateEn: this.selectedSuspendDateEn,
      };
      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    fileBlob() {
      return this.$store.getters["shared/blob"];
    },
    reportName() {
      if (this.lang.language === "Thai") {
        return this.reportObj.nameThai;
      } else {
        return this.reportObj.nameEnglish;
      }
    },
    selectedDate() {
      let date = null;
      if (this.date) {
        date = this.formatDateToISOString(this.date);
      }
      return date;
    },
    selectedDueDate() {
      let dueDate = null;
      if (this.dueDate) {
        dueDate = this.formatDateToISOString(this.dueDate);
      }
      return dueDate;
    },
    selectedSuspendDateTh() {
      let suspendDate = null;
      if (this.suspendDate) {
        suspendDate = this.formatDate(this.suspendDate);
      }
      return suspendDate;
    },
    selectedSuspendDateEn() {
      let suspendDate = null;
      if (this.suspendDate) {
        suspendDate = this.formatDateToLocaleDateString(this.suspendDate);
      }
      return suspendDate;
    },
    selectedReport() {
      if (this.path.includes("/notice_exclude_penalty")) {
        return {
          pathFile: this.path +".pdf",
          fileName: "NoticeExcludePenalty",
          condition: "",
        };
      } else if (this.path.includes("/notice_water_warning")) {
        return {
          pathFile:  this.path +".pdf",
          fileName: "NoticeWaterWarning",
          condition: "water_warning",
        };
      } else if (this.path.includes("/notice_water_suspend")) {
        return {
          pathFile:  this.path +".pdf",
          fileName: "NoticeWaterSuspend",
          condition: "water_suspend",
        };
      } else if (this.path.includes("/notice_common_before_due")) {
        return {
          pathFile: this.path +".pdf",
          fileName: "NoticeCommonFeeBeforeDue",
          condition: "common_warning",
        };
      } else if (this.path.includes("/notice_common_less_than180")) {
        return {
          pathFile: this.path +".pdf",
          fileName: "NoticeCommonFeeLessThan180",
          condition: "common_less6",
        };
      } else if (this.path.includes("/notice_common_more_than180")) {
        return {
          pathFile: this.path +".pdf",
          fileName: "NoticeCommonFeeMoreThan180",
          condition: "common_more6",
        };
      } else if (this.path.includes("/notice_electricity_warning")) {
        return {
          pathFile: this.path +".pdf",
          fileName: "NoticeElectricityWarning",
          condition: "elec_warning",
        };
      } else if (this.path.includes("/notice_electricity_suspend")) {
        return {
          pathFile: this.path +".pdf",
          fileName: "NoticeElectricitySuspend",
          condition: "elec_suspend",
        };
      } else if (this.path.includes("/notice_water_electricity_warning")) {
        return {
          pathFile: this.path +".pdf",
          fileName: "NoticeWaterAndElectricityWarning",
          condition: "water_elec_warning",
        };
      }
      return {
        pathFile: this.path +".pdf",
        fileName: "NoticeExcludePenalty",
        condition: "",
      };
    },
    isShowOverDue(){
        if (this.reportObj.path.includes("notice_water_suspend") || this.reportObj.path.includes("notice_electricity_suspend")) {
            return true;
        }
        return false;
    },
    reportPrints() {
      return this.$store.getters["reportMenuDetail/reportMenuDetails"];
    },
    fileNameNotice() {
      let path = this.reportObj.path
      if (path.includes("/notice_exclude_penalty")) {
        return "notice_exclude_penalty"
      } else if (path.includes("/notice_water_warning")) {
        return "notice_water_warning"
      } else if (path.includes("/notice_water_suspend")) {
        return "notice_water_suspend"
      } else if (path.includes("/notice_common_before_due")) {
        return "notice_common_before_due"
      } else if (path.includes("/notice_common_less_than180")) {
        return "notice_common_less_than180"
      } else if (path.includes("/notice_common_more_than180")) {
        return "notice_common_more_than180"
      } else if (path.includes("/notice_electricity_warning")) {
        return "notice_electricity_warning"
      } else if (path.includes("/notice_electricity_suspend")) {
        return "notice_electricity_suspend"
      } else if (path.includes("/notice_water_electricity_warning")) {
        return "notice_water_electricity_warning"
      }
      return "notice_exclude_penalty"
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
  },
  watch: {
    reportObj() {
      this.initiateData();
    //   console.log("this.reportObj=", this.reportObj);
    },
  },
};
</script>
