export default {
	unitResidents(state) {
		return state.unitResidents;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	selectedUnitResident(state) {
		return state.selectedUnitResident;
	},
	unitResidentNextPage(state) {
		return state.currentPage + 1;
	},
	unitResidentIsLastPage(_, getters) {
		return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
	},
};
