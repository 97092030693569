export default {
  createReceipt: "สร้างใบเสร็จรับเงิน",
  editReceipt: "แก้ไขใบเสร็จรับเงิน",
  receiptDetail: "รายละเอียดใบเสร็จ",
  unitCode: "เลขที่ยูนิต/ บ้านเลขที่",
  mobileNumber: "เบอร์โทรศัพท์",
  invoiceCode: "เลขที่ใบแจ้งหนี้",
	invoiceDate: "วันที่ใบแจ้งหนี้",
  livingType: "ประเภทผู้พักอาศัย",
  owner: "เจ้าของ",
  rential: "ผู้เช่า",
  all: "ทั้งหมด",
  deposit: "เงินฝาก",
  pending: "ยอดค้าง",
  discount:"ส่วนลด",
  discountAmount: "มูลค่าส่วนลด",

  payAsDefined: "จ่ายตามที่ระบุ",
  amountToPay: "ระบุจำนวนเงินที่ต้องการชำระ",

  pendingList: "รายการค้างชำระ",
  calculatePenalty: "คำนวณค่าปรับ/ เงินเพิ่ม",
  calculatePenaltyDate: "คุณต้องการคำนวณค่าปรับจนถึงวันที่?",

  payType: "ประเภทการชำระ",
	cashType: "เงินสด",
	transferType: "เงินโอน",
	chequeType: "เช็ค",
	creditCardType: "บัตรเครดิต",
	depositType: "หักจากเงินฝากชำระ",
	ePayType: "E_Pay",
	otherType: "หักจาก (อื่นๆ)",
  receipts: "หลายใบเสร็จ",
	transfer: "โอนเข้าบัญชี",
	receive: "รับเข้าบัญชี",
	bank: "ธนาคาร",
  branch: "สาขา",
  transferDate: "วันที่โอน",
  chequeDate: "วันที่เช็ค",
	transferNo: "เลขที่ใบโอน",
	chequeNo: "เลขที่เช็ค",
	creditCardNo: "เลขที่บัตรเครดิต",
	paidBy: "ชื่อผู้ชำระ",
  paidByAddress: "ที่อยู่ผู้ชำระ",

  discountPlaceholder: "เช่น 10% หรือ 100",
  validateInvalidDiscount: "รูปแบบส่วนลดไม่ถูกต้อง ระบุได้เฉพาะตัวเลข และ %",
  validateNoRemarkKeyCard: "กรุณาระบุเลขคีย์การ์ด Serial No. ในช่องรายละเอียด",
  validateNetAmount: "ส่วนลดที่ระบุมากกว่าจำนวนเงินที่ต้องชำระ",
	product: "รายรับ",
  productCode: "รหัสรายรับ",
  productName: "ชื่อรายรับ",
  paySelected: "จ่ายรายการที่เลือก",
  cancelReason: "สาเหตุการยกเลิก",
  validateNoCancelReason: "กรุณาระบุสาเหตุการยกเลิก",

  validateNoDeposit : "คุณมีเงินฝากไม่พอให้ชำระ",
  depositAmount : "มีเงินฝากอยู่จำนวน :amount บาท",
  askToUseDeposit : "ต้องการหักจากเงินฝากหรือไม่",
	validateNetTotalAmount: "มูลค่าสุทธิ ต้องไม่น้อยกว่า 0",
  validateNoBank: "กรุณาเลือกธนาคาร",
  validateNoBankBranch: "กรุณาระบุสาขาธนาคาร",
  validateNoPayReference: "กรุณาระบุ:no",
  askToUseGuarantee: "ต้องการหักเงินค้ำประกันหรือไม่",
  itemsGuarantee: "กรุณาเลือกรายการเงินค้ำ",
  balanceAmount: "มูลค่าคงเหลือ",
  selectedConfirm: "ยืนยันรายการที่เลือก",
  selectOtherReceipt: "กรุณาเลือกใบเสร็จที่ต้องการหักจาก",
  statusReceipt: "สถานะใบเสร็จ",
  onlyCanceled: "เฉพาะที่ยกเลิก",
  allReceipt: "ใบเสร็จทั้งหมด",
	referCode: "เลขที่อ้างอิง",
	validateReferCode: "ระบุเลขที่อ้างอิง",
  please: "กรุณา",
  createReceiptSuccess: "คุณสร้างรายการใบเสร็จรับเงินสำเร็จ",
  editReceiptSuccess: "คุณแก้ไขรายการใบเสร็จรับเงินสำเร็จ",
  askForCreateNewReceipt: "หากคุณสร้างรายการใบเสร็จรับเงินใหม่ระบบจะไม่บันทึกการแก้ไข คุณยืนยันที่จะสร้างรายการใบเสร็จรับเงินใหม่ใช่หรือไม่?",
  calculateSuccess: "คำนวณสำเร็จ",
  calculatePenaltySuccess: "คำนวณค่าปรับ/ เงินเพิ่มสำเร็จ",
  cancelReceiptSuccess: "คุณยกเลิกเอกสารใบเสร็จรับเงิน :code สำเร็จ",
	validateNoResident: "ห้อง/บ้านที่ท่านเลือกไม่มีผู้เช่า",
  addEdit: "เพิ่ม/ แก้ไขรายการ",
  createNewReceipt: "สร้างใบเสร็จรับเงินใหม่",
  validateNoEnterBank: "กรุณาระบุธนาคาร",
  generateProduct:"สร้างรายการเพิ่มตามจำนวนที่ระบุ",
  copyValidate:"รายการค้างชำระไม่สามารถคัดลอกได้",
  titleDetail: "รายการใช้จ่าย",
  titlePay: "ข้อมูลการชำระ",
  remark1: "หมายเหตุ (1)",
  remark2: "หมายเหตุ (2)",
  selectPendingList: "เลือกรายการค้างชำระ",
  validatePayType : "หากต้องการสร้างใบเสร็จมูลค่า 0.00 บาท กรุณาเลือกประเภทการชำระเงินเป็น เงินสด หรือ หักจาก (อื่นๆ) เท่านั้น",
  validatePayTypeOther: "เอกสารนี้ชำระโดยประเภทหักจาก (อื่นๆ)",
  remarkDeductGuarantee: "หักจากใบเสร็จเงินค้ำประกัน No.",
  remarkDeductReceipt: "หักจากใบเสร็จ No.",
  remarkDeductOther: "หักจากอื่น ๆ",
};
