export default {
	titleEdit: "รายละเอียดสมุดโทรศัพท์",
	createPhoneBook: "สร้างสมุดโทรศัพท์",
	editPhoneBook: "แก้ไขสมุดโทรศัพท์",

	contactInfo: "ข้อมูลผู้ติดต่อ",
	phoneInfo: "ข้อมูลเบอร์โทรศัพท์",
	contactType: "ประเภทผู้ติดต่อ",
	contactTypePlaceholder: "กรุณาเลือกประเภทผู้ติดต่อ",
	nameTh: "ชื่อสมุดโทรศัพท์ (ภาษาไทย)",
	nameEn: "ชื่อสมุดโทรศัพท์ (ภาษาอังกฤษ)",
	phoneType: "ประเภทเบอร์โทร",
	phoneTypePlaceholder: "กรุณาเลือกประเภทเบอร์โทร",
    phoneNumber: "เบอร์โทรศัพท์",
	phoneExtension: "เบอร์ต่อ",
	activeStatus: "สถานะการใช้งาน",
	validateSequence: "กรุณาระบุลำดับ",
	validateNameTh: "กรุณาระบุชื่อสมุดโทรศัพท์ (ภาษาไทย)",
	validatePhoneNumber: "กรุณาระบุเบอร์โทรศัพท์",

    createNewPhoneBook: "สร้างสมุดโทรศัพท์ใหม่",
    askForCreateNewPhoneBook: "หากคุณสร้างสมุดโทรศัพท์ใหม่ระบบจะไม่บันทึกการแก้ไข คุณยืนยันที่จะสร้างเอกสารสมุดโทรศัพท์ใหม่ใช่หรือไม่?",
    deletePhoneBookTitle: "ยืนยันการลบข้อมูล",
    askForDeletePhoneBook: "ต้องการยืนยันที่จะลบรายการ :name ใช่หรือไม่?",
	dialogContentSave: "บันทึกรายการสำเร็จ",
};
