export default {
    toolbarUnit: "Room/House List",
    title: "Room Registration/House",
    createReport: "Create Report",
    addRoom: "Create Room/House",
    searchText: "Fill up to search",
    unitCode: "Unit Code",
    addressNo: "Address No",
    ownerName: "Owner Name",
    columnRatio: "Ratio",
    area: "Area",
    type: "Type",
    unitProductSetupForInvoice: "UnitProductSetupForInvoice",
    reportPrintButton: "Print Report",
    reportTitle: "Unit Report",
    unitList: "Units",
    activeStatus: "Active Status",
    statusY: "Active",
    statusN: "Unactive",
    importUnitResident: "Import Unit Resident",
    buttonImportUnit: "Import Unit",
}