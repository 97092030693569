export default {
	editTitle: "Edit Account Book", 

	dialogTitleNotification: "Notification", 
	dialogTitleAddSuccess: "Insert success.", 
	dialogContentAddSuccess: "Insert account book successful.",
	dialogTitleEditSuccess: "Edit success.",
	dialogContentEditSuccess: "Edit account book successful.",
	dialogTitleDeleteSuccess: "Delete success", 
	dialogContentDeleteSuccess: "Delete account book successful.",	
	dialogTitleDeleteConfirm: "Delete account book confirmation.",
	dialogContentDeleteConfirm: "Do you want delete account book {0} or not?", 

	validateParams: "Please enter require field.",  
	validateRole: "You don't have permission.", 
	validateDuplicate: "Please check code in the system.", 
	validateNotFound: "You may not have data or It's doesn't exist.", 
	validateReference: "The account book has reference in the system.", 
	validateNoneId: "The account book id doesn't exist.",

	labelCode: "Account book code*", 
	placeholderCode: "Please enter account book code.", 
	validateCode: "Please enter account book code.", 
	validateNoneCode: "Please enter account book code.", 
	validateDuplicateCode: "The account book code is duplicate in the system.", 
	labelNameThai: "Account book name* (English)", 
	placeholderNameThai: "Please enter account book name thai.", 
	validateNameThai: "Please enter account book name thai.", 
	labelNameEnglish: "Account book name (English)", 
	placeholderNameEnglish: "Please enter account book name english.", 
	validateNameEnglish: "Please enter account book name english.", 
	labelLockDate: "Data lock end date.", 
	validateLimitText: "Please specify a message with a maximum length of 50 characters.",
	validateChangeCode: "The book code {0} cannot be changed. (Support characters and numbers only)",
	validateChangeUniqueCode: "The book code {0} cannot be changed. (The book code is master code)",

	buttonSave: "Save",
	buttonDelete: "Delete account book", 

	buttonDialogSuccess: "Agree",
	buttonDialogConfirm: "Confirm",
	buttonDialogCancel: "Cancel",

	titleCretae: "Create Account Book", 
	titleEdit: "Edit Account Book",
	settingPettyCashAccount: "Setting petty cash account",
	pettyCashAccount: "Petty cash account",
	enterPettyCashAccount: "Enter petty cash account",
	showPettyCashUseOtherExpenses: "Show petty cash difference for report",
	validateSelectedPettyCashAccount: "Please select petty cash account",
	pettyCashUseOtherExpensesAcoount: "Account for for use other expenses*",
	enterPettyCashUseOtherExpensesAcoount: "Enter account for use other expenses*",
	validateSelectedPettyCashUseOtherExpensesAcoount: "Please select account for for use other expenses",
	checkboxIsDefaultVoucherDate: "Reference date documents.",
};
