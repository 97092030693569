export default {
  setIsInvoicePrintOrderChanges(state, payload) {
    state.isInvoicePrintOrderChanges = payload.data;
  },
  setIsPrintOrder(state, payload) {
    state.isPrintOrder = payload.data;
  },
  setIsReceiptPrintOrder(state, payload) {
    state.isReceiptPrintOrder = payload.data;
  },
  setIsNoticePrintOrder(state, payload) {
    state.isNoticePrintOrder = payload.data;
  },
  setIsWithholdingTaxPrintOrder(state, payload) {
    state.isWithholdingTaxPrintOrder = payload.data;
  },

  };
  