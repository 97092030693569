<template>
  <base-container>
    <base-product-ap-modal
      v-model:visible="showProductModal"
      :search="searchText"
      @close="showProductModal = false"
      @product-selected="productModalSelected"
    ></base-product-ap-modal>

    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchTextPrint"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>

    <div class="flex justify-content-between align-items-center">
      <div class="my-3">
        <PvButton
          icon="pi pi-chevron-left"
          :label="lang.back"
          @click="goBack"
          class="mr-2 p-button-secondary"
        />
      </div>
      <h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
    </div>
    <transition name="fade" mode="out-in">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="pt-2 pb-1">
          <PvButton
            icon="pi pi-file"
            :label="lang.create"
            class="p-button-secondary mr-2 mb-2"
            @click="createDocument"
          />
          <PvButton 
            icon="pi pi-save" 
            :label="lang.save" 
            class="mr-2 mb-2" 
            @click="save"
            :disabled="isCheckDisabled"
          />
          <PvButton 
            icon="pi pi-save" 
            :label="lang.saveAs" 
            class="mr-2 mb-2" 
            @click="askForSaveAs"
            :disabled="isCheckSaveAs"
          />
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.printPdf"
            class="mr-2 mb-2"
            :disabled="isCreateDocument"
            @click="selectPrintForm"
          />
          <PvButton
            icon="pi pi-check-circle"
            :label="lang.approve"
            class="mr-2 mb-2"
            @click="askForApprove"
            :disabled="isCreateDocument || isCheckDisabled"
          />
          <PvButton
            v-if="isCheckRoleApprove"
            icon="pi pi-replay"
            :label="lang.reApprove"
            class="mr-2 mb-2"
            @click="askForRevertApprove"
            :disabled="isCreateDocument || isCheckApprovedDisabled"
          />
          <PvButton
            icon="pi pi-times"
            :label="lang.closePR"
            class="mr-2 mb-2"
            @click="askForClosePR"
            :disabled="isCreateDocument || isCheckCloseDisabled"
          />
          <PvButton
            icon="pi pi-times"
            :label="lang.cancel"
            class="p-button-outlined p-button-danger mr-2 mb-2"
            @click="askForCancel"
            :disabled="isCreateDocument || isCheckDisabled"
          />
        </div>
        <div class="flex flex-wrap" :class="textFormClass">
          <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 flex flex-wrap">
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="code">{{ lang.columnCode }}</label>
              <PvInputText
                id="code"
                v-model="code"
                type="text"
                class="inputfield w-full mt-1 p input-number-disable"
                disabled
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="refCode">{{ lang.columnReferDocument }}</label>
              <PvInputText
                id="referDocument"
                v-model="referDocument"
                type="text"
                class="inputfield w-full mt-1 p"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="creditTerm">{{ lang.creditTerm }}</label>
              <PvInputNumber
                id="creditTerm"
                class="inputfield w-full mt-1"
                inputClass="text-right"
                v-model="creditTerm"
                @focus="$event.target.select()"
                @blur="editCreditTerm($event)"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="date">{{ lang.columnDate }}</label>
              <PvCalendar
                id="date"
                v-model="date"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
                @date-select="dateSelected"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="vatSendDate">{{ lang.requestDate }}</label>
              <PvCalendar
                id="vatSendDate"
                v-model="vatSendDate"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="dueDate">{{ lang.quotationDate }}</label>
              <PvCalendar
                id="dueDate"
                v-model="dueDate"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
                @date-select="setCreditTerm"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="supplier">{{ lang.supplier }}</label>
              <base-supplier-auto-complete
                id="supplier"
                v-model="selectedSupplier"
                class="inputfield w-full mt-1"
                @load-finish="supplierDataReady"
                :class="{ 'p-invalid': supplierError }"
                @item-select="selectSupplier()"
              />
              <small v-if="supplierError" id="supplier-help" class="p-error">{{
                lang.validateNoSupplierSelect
              }}</small>
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="receiptEmployee">{{ lang.buyer }}</label>
              <base-employee-auto-complete
                id="receiptEmployee"
                v-model="selectedBuyer"
                class="inputfield w-full mt-1"
                @load-finish="employeeDataReady"
                @item-select="buyerError = false"
                :class="{ 'p-invalid': buyerError }"
              />
              <small v-if="buyerError" id="employee-help" class="p-error">{{
                lang.validateNoEmployeeSelect
              }}</small>
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="remark" style="color:#e24c4c;">{{ lang.remark }}</label>
              <PvInputText
                id="remark"
                v-model="remark"
                type="text"
                class="inputfield w-full mt-1 p"
                :class="{ 'p-invalid': remarkError }"
                @input="this.remarkError = false"
              />
              <small v-if="remarkError" id="remark-help" class="p-error">{{ lang.validateRemark }}
              </small>
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="deliveryDate">{{ lang.deliveryDate }}</label>
              <PvInputText
                id="deliveryDate"
                v-model="deliveryDate"
                type="text"
                class="inputfield w-full mt-1 p"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="payment">{{ lang.payment }}</label>
              <PvInputText
                id="payment"
                v-model="payment"
                type="text"
                class="inputfield w-full mt-1 p"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="guarantee">{{ lang.guarantee }}</label>
              <PvInputText
                id="guarantee"
                v-model="guarantee"
                type="text"
                class="inputfield w-full mt-1 p"
              />
            </div>
          </div>
        </div>
        <PvDataTable
          showGridlines
          :value="details"
          dataKey="id"
          responsiveLayout="scroll"
          :class="textTableClass"
          editMode="cell"
          class="editable-cells-table"
          @cell-edit-complete="onCellEditComplete"
        >
          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
          <PvColumn
            field="itemNo"
            :header="lang.itemNo"
            style="min-width: 4rem"
            headerClass="h-center"
            bodyClass="text-center"
          ></PvColumn>
          <!-- <PvColumn
            field="isProduct"
            :header="lang.columnIsProduct"
            style="min-width: 4rem"
            headerClass="h-center"
            bodyClass="text-center"
          ></PvColumn> -->
          <PvColumn
            field="productCode"
            :header="lang.columnProductCode"
            style="min-width: 4rem"
            headerClass="h-center"
            bodyClass="text-left"
          >
            <template #editor="{ data, field }">
              <PvInputText type="text" v-model.trim="data[field]" />
            </template>
          </PvColumn>
          <PvColumn
            field="productName"
            :header="lang.columnProductName"
            style="min-width: 4rem"
            headerClass="h-center"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="remark"
            :header="lang.description"
            style="min-width: 4rem"
            headerClass="h-center"
            bodyClass="text-left"
          >
            <template #body="slotProps">
             <pre class="p-0 m-0">{{ slotProps.data.remark }}</pre>
            </template>
            <template #editor="{ data, field }">
              <PvTextarea
                v-model="data[field]"
                :autoResize="true"
                rows="1"
                class="inputfield w-full mt-1"
                :maxlength="750"
                cols="20"
                @focus="$event.target.select()"
              />
            </template>
          </PvColumn>
          <PvColumn
            field="productUnitName"
            :header="lang.columnProductUnitName"
            style="min-width: 6rem"
            headerClass="h-center"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="unitPrice"
            :header="lang.columnUnitPrice"
            style="min-width: 6rem"
            headerClass="h-center"
            bodyClass="text-right"
          >
            <template #editor="{ data, field }">
              <PvInputNumber
                id="unitPrice"
                class="inputfield w-full"
                inputClass="text-right"
                v-model="data[field]"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                :min="0"
                @focus="$event.target.select()"
              />
            </template>
            <template #body="slotProps">
              {{ formatCurrencyTable(slotProps.data, "unitPrice") }}
            </template>
          </PvColumn>
          <PvColumn
            field="quantity"
            :header="lang.columnQuantity"
            style="min-width: 6rem"
            headerClass="h-center"
            bodyClass="text-right"
          >
            <template #editor="{ data, field }">
              <PvInputNumber
                id="quantity"
                class="inputfield w-full"
                inputClass="text-right"
                v-model="data[field]"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                :min="0"
                @focus="$event.target.select()"
              />
            </template>
            <!-- <template #body="slotProps">
              {{ formatCurrency(slotProps.data.quantity) }}
            </template> -->
          </PvColumn>
          <PvColumn
            field="totalAmount"
            :header="lang.columnTotalAmount"
            style="min-width: 6rem"
            headerClass="h-center"
            bodyClass="text-right"
          >
            <template #body="slotProps">
              {{ formatCurrencyTable(slotProps.data, "totalAmount") }}
            </template>
          </PvColumn>
          <PvColumn
            style="min-width: 4rem"
            headerClass="h-center"
            bodyStyle="text-align: center; overflow: visible"
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-danger p-button-text py-0"
                icon="pi pi-times"
                @click="deleteRow(slotProps.data.id)"
                :disabled="!slotProps.data.productCode"
              ></PvButton>
            </template>
          </PvColumn>
        </PvDataTable>
        <div class="flex flex-wrap" :class="textFormClass">
          <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 flex flex-wrap">
            <!-- <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="currency">{{ lang.currency }}</label>
              <PvInputText
                id="currency"
                v-model="currency"
                type="text"
                class="inputfield w-full mt-1 p input-disable text-right"
                :placeholder=lang.currencyName
                readonly
              />
            </div> -->
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="totalAmount">{{ lang.totalAmount }}</label>
              <PvInputNumber
                id="totalAmount"
                class="inputfield w-full mt-1"
                inputClass="input-number-disable text-right"
                v-model="totalAmount"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                placeholder="0.00"
                disabled
              />
            </div>
            <!-- <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="actualPayAmount">{{ lang.actualPayAmount }}</label>
              <PvInputNumber
                id="actualPayAmount"
                class="inputfield w-full mt-1"
                inputClass="input-disable text-right"
                v-model="actualPayAmount"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                placeholder="0.00"
                readonly
              />
            </div> -->
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="discount">{{ lang.discount }}</label>
              <!-- <PvInputText
                id="discount"
                v-model="discount"
                type="text"
                class="inputfield w-full mt-1 p text-right"
                @focus="$event.target.select()"
                @keypress="checkImputDiscount($event)"                
                @input="editDiscount($event)"
              /> -->
    					<base-discount-input-text 
                class="inputfield w-full mt-1 p text-right"
                @focus="$event.target.select()"
                @emit-discount-amount="setDiscountText"
                :defaultDiscount="discount"/>
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="discountAmount">{{ lang.discountAmount }}</label>
              <PvInputNumber
                id="discountAmount"
                class="inputfield w-full mt-1"
                inputClass="input-number-disable text-right"
                v-model="discountAmount"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                placeholder="0.00"
                disabled
                :class="{ 'p-invalid': discountAmountError }"
              />
              <small v-if="discountAmountError" id="supplier-help" class="p-error">{{
                lang.validateDiscountAmount
              }}</small>
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="totalAmountBeforeTax">{{
                lang.totalAmountBeforeTax
              }}</label>
              <PvInputNumber
                id="totalAmountBeforeTax"
                class="inputfield w-full mt-1"
                inputClass="input-number-disable text-right"
                v-model="totalAmountBeforeTax"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                placeholder="0.00"
                disabled
              />
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="tax">{{ lang.tax }}</label>
              <base-tax-auto-complete
                id="tax"
                v-model="selectedTax"
                class="inputfield w-full mt-1"
                @load-finish="taxDataReady"
                @item-select="selectTax()"
                :class="{ 'p-invalid': taxError }"
              />
              <small v-if="taxError" id="tax-help" class="p-error">{{
                lang.validateNoTaxSelect
              }}</small>
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="taxAmount">{{ lang.taxAmount }}</label>
              <label class="font-xs p-text-disabled">{{lang.calculatedFromSystem}}{{ this.formatCurrency(this.calculateTaxFromSystem) }}</label>              
              <div class="flex justify-content-between align-items-center">
                <div class="w-full pr-2">
                  <PvInputNumber
                    id="taxAmount"
                    class="inputfield w-full mt-1"
                    v-model="taxAmount"
                    mode="decimal"
                    :minFractionDigits="2"
                    :maxFractionDigits="2"
                    placeholder="0.00"
                    @dblclick="doubleClickTaxAmount($event)"
                    @blur="setNetAmount($event)"
                    :disabled="isTaxAmountDisabled" 
                    :inputClass="classDisableTaxAmount"
                  />                 
                </div>
                <PvButton
                  type="button"
                  class="p-button-secondary p-button-text mt-1 p-1"
                  @click="doubleClickTaxAmount()"
                  v-if="!this.tax == 0"             
                >
                  <icon-pen-edit-text iconColor="secondary" width="24" height="24" />
                </PvButton>
              </div>
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="netAmount">{{ lang.netAmount }}</label>
              <PvInputNumber
                id="netAmount"
                class="inputfield w-full mt-1"
                inputClass="input-number-disable text-right"
                v-model="netAmount"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                placeholder="0.00"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useGetProductAP from "../../../hooks/getProductAP.js";
export default {
  props: ["apBook", "id", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass, textAlertClass } =
      useTextStyle();
    const { formatCurrencyTable, formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { isSharedProductReady, getProductByCode, searchText } = useGetProductAP();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ap.purchaseRequisition.listing,
          ...lang.th.ap.purchaseRequisition.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ap.purchaseRequisition.listing,
          ...lang.en.ap.purchaseRequisition.edit,
        };
      }
    });

    const {
      getFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints
    } = useGetFormPrint();

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrencyTable,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      textAlertClass,
      getFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints,
      isSharedProductReady,
      getProductByCode,
      searchText,
    };
  },
  data() {
    return {
      code: "",
      referDocument: "",
      date: null,
      vatSendDate: null,
      dueDate: null,
      creditTerm: 0,
      remark: "",
      deliveryDate: "",
      payment: "",
      guarantee: "",
      details: [],
      currency: "",
      discount: "",
      discountAmount: 0,
      actualPayAmount: 0,
      totalAmount: 0,
      totalAmountBeforeTax: 0,
      tax: 0,
      taxAmount: 0,
      netAmount: 0,
      vatInclude: "",
      selectedSupplier: null,
      supplierError: false,
      selectedBuyer: null,
      buyerError: false,
      selectedTax: null,
      taxError: false,
      isSharedSupplierReady: false,
      isSharedEmployeeReady: false,
      isSharedTaxReady: false,
      showProductModal: false,
      editingDetail: null,
      remarkError: false,

      selectedAPBook: null,
      selectedBookId: "",
      selectedBookCode: "",

      showFormPrintModal: false,
      path: "",
      isPrinted: false,

      discountAmountError : false,
      isSetData: true,
      storageName: "",

      calculateTaxFromSystem: 0.00,
      isTaxAmountDisabled: true,

    };
  },
  async mounted() {
    
    this.storageName = `erp_search_purchaseRequisitionList_${this.selectedProject.code}_${this.userCode}`;
    const localSearch = localStorage.getItem(this.storageName);
    if (localSearch) {
      let search = JSON.parse(localSearch);
      this.selectedAPBook = search.selectedAPBook;
      this.selectedBookId = search.selectedAPBook.bookId;
      this.selectedBookCode = search.selectedAPBook.bookCode;
      // this.tax = search.selectedAPBook.taxRate;
      this.vatInclude = search.selectedAPBook.vatInclude;
    }
    // console.log("this.apBook=", this.apBook);
    // console.log("this.id=", this.id);
    // console.log("this.selectedAPBook=", this.selectedAPBook);

    if (!this.isCreateDocument) {
      await this.$store.dispatch("apPurchaseRequisition/resetSelectedPurchaseRequisition");
      this.setLoading(true);
      const where = {
        id: this.id,
      };
      await this.$store.dispatch("apPurchaseRequisition/getPurchaseRequisition",{ where });
    } else {
      this.initiateData();
    }

    await this.getRoleByDocumentBook();    

  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      const localSearch = localStorage.getItem(this.storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        purchaseRequisitionEntry: "Entry",
      };
      localStorage.setItem(this.storageName, JSON.stringify(search));
      this.$router.push({ name: "ap-purchase-requisition-apBook", params: {apBook: this.apBook} });
    },
    supplierDataReady() {
      this.isSharedSupplierReady = true;
    },
    taxDataReady() {
      this.isSharedTaxReady = true;
      if(this.isCreateDocument ){
        this.selectedTax = this.sharedTaxes.find(
        (tax) => tax.taxRate === this.selectedAPBook.taxRate
        );
        this.tax = this.selectedTax.taxRate;
      }
    },
    employeeDataReady() {
      this.isSharedEmployeeReady = true;
      if(this.isCreateDocument){
        this.selectedBuyer = this.sharedEmployees.find(
        (buyer) => buyer.code === this.employee.code
        );
      }
    },
    checkShouldSetData() {
      if (this.isSharedSupplierReady && 
          this.isSharedEmployeeReady && 
          this.isSharedTaxReady && 
          this.purchaseRequisition) {
            this.setData();
          }
    },
    setData() {
      this.isSetData = false;
      // console.log("purchaseRequisition", this.purchaseRequisition);
      this.setLoading(true);
      // if (!this.isCreateDocument) {
        this.selectedSupplier = this.sharedSuppliers.find(
          (supplier) => supplier.id === this.purchaseRequisition.supplierId
        );
        this.selectedBuyer = this.sharedEmployees.find(
        (employee) => employee.id === this.purchaseRequisition.employeeId
        );
        this.selectedTax = this.sharedTaxes.find((tax) => tax.taxRate === this.purchaseRequisition.vatRate);
        this.code = this.purchaseRequisition.code;
        this.referDocument = this.purchaseRequisition.referDocument;
        this.date = new Date(this.purchaseRequisition.date);
        this.vatSendDate = new Date(this.purchaseRequisition.vatSendDate);
        this.dueDate = new Date(this.purchaseRequisition.dueDate);
        this.remark = this.purchaseRequisition.remark1;
        this.deliveryDate = this.purchaseRequisition.remark4;
        this.payment = this.purchaseRequisition.remark2;
        this.guarantee = this.purchaseRequisition.remark3;
        this.currency = this.purchaseRequisition.currencyName; 
        this.discount = this.purchaseRequisition.discount;
        this.discountAmount = this.purchaseRequisition.discountAmount;
        // this.actualPayAmount = this.purchaseRequisition.actualPayAmount;
        this.totalAmount = this.purchaseRequisition.totalAmount;
        this.totalAmountBeforeTax = this.purchaseRequisition.totalAmount - this.purchaseRequisition.discountAmount;
        // this.tax = this.purchaseRequisition.vatRate;
        this.taxAmount = this.purchaseRequisition.vatAmount;
        this.netAmount = this.purchaseRequisition.netAmount;

        this.details = this.purchaseRequisition.purchaseRequisitionDetails;
      // }
        this.tax = this.selectedTax.taxRate;
      this.setCreditTerm();
      this.addEmptyRow();
      this.updateItemNumber();

      this.calculateNetAmount();
      this.setNetAmount(null);
      this.setLoading(false);
    },
    async initiateData() {
      this.selectedBuyer = this.sharedEmployees.find(
        (buyer) => buyer.code === this.employee.code
      );
      this.selectedTax == this.sharedTaxes.find((tax) => tax.taxRate == this.selectedAPBook.taxRate);
      this.selectedSupplier = null;
      this.code = "";
      this.referDocument = "";
      this.date = new Date();
      this.vatSendDate = new Date();
      this.dueDate = new Date();
      this.creditTerm = 0;
      this.remark = "";
      this.deliveryDate = "";
      this.payment = "";
      this.guarantee = "";
      this.details = [];
      this.currency = "", 
      this.discount = "", 
      this.discountAmount = 0;
      // this.actualPayAmount = 0;
      this.totalAmount = 0;
      this.totalAmountBeforeTax = 0;
      // this.tax = 0;
      this.calculateTaxFromSystem = 0;
      this.taxAmount = 0;
      this.netAmount = 0;

      this.buyerError = false;
      this.supplierError = false;
      this.taxError = false;
      this.remarkError = false;
      this.discountAmountError = false;
      this.isSetData = true;
      
      this.calculateTaxFromSystem = 0.00;
      this.isTaxAmountDisabled = true;

      await this.$store.dispatch("apPurchaseRequisition/resetSelectedPurchaseRequisition");
      this.addEmptyRow();
    },
    addEmptyRow() {
      this.details.push({
        id: "dummy" + (parseInt(this.details.length) + 1),
        headerId: null,
        itemNo: null,
        isProduct: null,
        productId: null,
        productCode: null,
        productName: null,
        quantity: null,
        productUnitId: null,
        productUnitCode: null,
        productUnitName: null,
        unitRatio: null,
        unitPrice: null,
        discount: null,
        discountAmount: null,
        totalAmount: null,
        remark: null,
        dlvrQuantity: null
      });
    },
    createDocument() {
      this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForCreateNewDocument}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.cancel,
        allowOutsideClick: false,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (!this.isCreateDocument) {
            this.$router.replace({ name: "ap-purchase-requisition-edit", params: {apBook: this.apBook, id: "create"} });
          } else {
            //
          }
          this.initiateData();
        }
      });
    },
    setCreditTerm() {
      if (this.date === null) this.date = new Date();
      if (this.dueDate === null) this.dueDate = new Date();
      const utc1 = Date.UTC(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate()
      );
      const utc2 = Date.UTC(
        this.dueDate.getFullYear(),
        this.dueDate.getMonth(),
        this.dueDate.getDate()
      );
      let creditTerm = Math.floor((utc2 - utc1) / (1000 * 3600 * 24));
      this.creditTerm = creditTerm;
    },
    calculateNetAmount() {    
      // console.log("this.discount=",this.discount);
      if (this.discount) {
        if (this.discount.includes("%")) {
          this.discountAmount =
            (parseFloat(this.discount) / 100) * this.totalAmount;
        } else {
          this.discountAmount = parseFloat(this.discount);
        }

        if (this.vatInclude === "Y") { //this.selectedTax.taxRate 
          this.netAmount = this.totalAmount - this.discountAmount;
          this.calculateTaxFromSystem = (this.netAmount * this.tax) / 107;
          this.totalAmountBeforeTax = this.netAmount - this.calculateTaxFromSystem;
        } else {
          this.totalAmountBeforeTax = this.totalAmount - this.discountAmount;
          this.calculateTaxFromSystem = (this.tax / 100) * this.totalAmountBeforeTax;
          this.netAmount = this.totalAmount - this.discountAmount + this.calculateTaxFromSystem;
        }
      } else {
        // console.log("this.vatInclude=",this.vatInclude);
        this.discountAmount = 0;    
        if (this.vatInclude === "Y") {
          this.netAmount = this.totalAmount - this.discountAmount;
          this.calculateTaxFromSystem = (this.netAmount * this.tax) / 107;
          this.totalAmountBeforeTax = this.netAmount - this.calculateTaxFromSystem;
        } else {    
          this.totalAmountBeforeTax = this.totalAmount - this.discountAmount; 
          this.calculateTaxFromSystem = (this.tax / 100) * this.totalAmountBeforeTax;
          this.netAmount = this.totalAmount - this.discountAmount + this.calculateTaxFromSystem;
        }
      }
      
    },
    onCellEditComplete(event) {
      if (!this.isCheckDisabled) {
        let value = event.value;
        let newValue = event.newValue;
        // console.log("event=",event);
        // console.log("event.value=",event.value);
        // console.log("event.newValue=",event.newValue);
        
        if (typeof value === "string") {
          value = value.trim();
        }
        if (typeof newValue === "string") {
          newValue = newValue.trim();
        }
        if (value === newValue && event.field !== "remark") {
          return;
        }

        if(event.field === "unitPrice") {
          if (newValue < 0 || !newValue) {
            newValue = 0;
          } 
          //else {
            this.calculateDetail(event.data,newValue,
              event.data.quantity)
          //}
        } else if (event.field === "quantity") {
          if (newValue < 1 || !newValue) {
            event.data.quantity = 1;
            this.calculateDetail(event.data,
              event.data.unitPrice,1)
          } 
          else {
            this.calculateDetail(event.data,
              event.data.unitPrice,newValue)
          }
        } else if (event.field === "productCode") {
          //หาว่า Code ที่ระบุมีอยู่จริงหรือไม่
          const product = this.getProductByCode(newValue);
          //หากมี ก็ update ข้อมูลใน record นั้น
          if (product) {
            if (newValue === "") {
              return;
            }
            if (!value) {
              this.productSelect(event.data, product);
            } else if (value !== newValue) {
              this.$confirm.require({
                message: this.lang.askForProductChange,
                header: this.lang.confirmDialogHeader,
                icon: "pi pi-exclamation-triangle",
                accept: () => {
                  //callback to execute when user confirms the action
                  this.productSelect(event.data, product);
                },
              });
            }
          } else {
            this.$confirm.require({
              message: this.lang.askForSelectProduct.replace(":code", newValue),
              header: this.lang.confirmDialogHeader,
              icon: "pi pi-exclamation-triangle",
              accept: () => {
                //callback to execute when user confirms the action
                this.editingDetail = event.data;
                this.searchText = newValue;
                this.showProductModal = true;
              },
            });
          }
        } else if (event.field === "remark") {
          if(event.type == "enter" && event.originalEvent.shiftKey) {
            event.preventDefault();
          }
          event.data.remark = newValue;
        }
      }
    },
    addRow(detail) {
      this.details.forEach((element) => {
        if (element.id === detail.id) {
          element = { ...element, ...detail };
        }
      });
    },
    productSelect(detail, product) {
      //console.log("productSelect_product", product);
      detail.headerId = null;
      detail.itemNo = detail.itemNo || this.lastItemNo + 1;
      // detail.isProduct = "P"
      detail.productId = product.id;
      detail.productCode = product.code;
      detail.productName = product.nameThai;
      detail.quantity = 1;
      detail.productUnitId = product.unitId;
      detail.productUnitCode = product.unitCode;
      detail.productUnitName = product.unitName;
      detail.unitPrice = product.price;
      detail.discount = "";
      detail.discountAmount = 0;
      detail.totalAmount = detail.unitPrice * detail.quantity;
      detail.remark = "";

      this.addRow(detail);
      //หาว่ามีบรรทัดที่ รหัสเป็น null หรือยัง (บรรทัดว่าง)
      const emptyRow = this.details.find((detail) => !detail.productCode);
      if (!emptyRow) {
        //ถ้ายังไม่มีให้เพิ่มไว้
        this.addEmptyRow();
      }

      this.updateTotalAmount();
      this.updateItemNumber();
      
      this.calculateNetAmount();
      this.setTaxAmountFromSystem();
    },
    productModalSelected(product) {
      this.productSelect(this.editingDetail, product);
      this.showProductModal = false;
    },
    updateTotalAmount() {
      let totalAmount = 0;
      this.details.forEach((detail) => {
        totalAmount += detail.totalAmount;
      });
      this.totalAmount = totalAmount;
    },
    updateItemNumber() {
      let number = 1;
      this.details.forEach((element) => {
        if (element.productCode) {
          element.itemNo = number;
        }
        number++;
      });
    },
    deleteRow(id) {
      const index = this.details.findIndex((detail) => detail.id === id);
      this.details.splice(index, 1);

      this.updateTotalAmount();
      this.updateItemNumber();

      this.calculateNetAmount();
      this.setTaxAmountFromSystem();
    },
    calculateDetail(detail, unitPrice, quantity) {
      detail.unitPrice = unitPrice;
      detail.quantity = quantity;
      detail.totalAmount = unitPrice*quantity
      this.updateTotalAmount(); 

      // console.log("calculateDetail");
      this.calculateNetAmount();
      this.setTaxAmountFromSystem();
    },
    validateData() {
      if (!this.selectedSupplier) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoSupplierSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.supplierError = true;
          }
        });
        return true;
      }

      if (!this.selectedBuyer) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoEmployeeSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.buyerError = true;
          }
        });
        return true;
      }

      if (!this.selectedTax) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoTaxSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.taxError = true;
          }
        });
        return true;
      }

      if (!this.remark) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateRemark,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.remarkError = true;
          }
        });
        return true;
      }

      if (this.details.length <= 1) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateDetailSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      if (this.totalAmount <= 0) {
				this.$swal({
					icon: "warning",
					title: this.lang.validateTotalAmount,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				return true;
			}

      if(this.discount){
        let isText = false;
        const keysAllowed = ["0","1","2","3","4","5","6","7","8","9",".","%"];
        for (let i = 0; i < this.discount.length; i++) {
          if (!keysAllowed.includes(this.discount[i])) {
            isText = true;
            break;
          }
        }
        if (isText) {
          this.$swal({
            icon: "warning",
            title: this.lang.validateCheckDiscount,
            confirmButtonText: this.lang.close,
            allowOutsideClick: false,
          });
          return true;
        }
      }

      if(this.netAmount < 0){
        this.$swal({
          icon: "warning",
          title: this.lang.validateDiscountAmount,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.discountAmountError = true;
          }
        });
        return true;
      }
      return false;
    },
    async save() {
      if (this.validateData()) {
        return;
      }
      if (this.validateRoles()) {
        return;
      }
      this.setLoading(true);
      try {
        await this.$store.dispatch("apPurchaseRequisition/createOrUpdatePurchaseRequisition",this.payload);
        this.$swal({
          icon: "success",
          title: this.lang.saveSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        if (this.isCreateDocument) {
          await this.$router.replace({ name: "ap-purchase-requisition-edit", params: {apBook: this.apBook, id: this.purchaseRequisition.id} });
        }
        this.isTaxAmountDisabled = true;
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
    },
    async askForSaveAs() {      
      if (
        this.roleByDocumentBook.length > 0 &&
        this.roleByDocumentBook[0].insert === "N" &&
        !this.isCreateDocument
      ) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateRoleInsert,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return;
      }
      // if (this.validateRoles()) {
      //   return;
      // }
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForSaveAs}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        await this.saveAs();
      } else if (result.isDenied) {
        //
      }
    },
    async saveAs() {
      this.setLoading(true);

      let details = [];

      for (const each of this.details.filter((each) => each.productId)) {
        let detail = null;
        if (each.id.includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        details.push(detail);
      }

      let payload = {
        projectId: this.selectedProject.id,
        documentBookId: this.selectedBookId,
        referDocument: this.referDocument,
        date: this.formatDateToISOString(this.date),
        dueDate: this.formatDateToISOString(this.dueDate),
        vatSendDate: this.formatDateToISOString(this.vatSendDate),
        totalAmount: this.totalAmount,
        discount: this.discount,
        discountAmount: this.discountAmount,
        vatAmount: this.taxAmount,
        netAmount: this.netAmount,
        vatRate: this.tax,
        remark1: this.remark,
        remark2: this.deliveryDate,
        remark3: this.payment,
        remark4: this.guarantee,
        supplierId: this.selectedSupplier.id,
        employeeId: this.selectedBuyer.id,
        details: details,
        type: "Add",
      }

      try {
        // console.log("payload", payload);
        await this.$store.dispatch("apPurchaseRequisition/createOrUpdatePurchaseRequisition", payload);
        this.$swal({
          icon: "success",
          title: this.lang.saveAsSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        this.$router.replace({ name: "ap-purchase-requisition-edit", params: {apBook: this.apBook, id: this.purchaseRequisition.id} });
        

      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
    },
    async askForApprove() {      
      if (
        this.roleByDocumentBook.length > 0 && 
        this.roleByDocumentBook[0].approve === "N" &&
        !this.isCreateDocument ) 
      {
        this.$swal({
          icon: "warning",
          title: this.lang.validateRoleApprove,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }
      // if (this.validateRoles()) {
      //   return;
      // }
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForApproveDocument}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        await this.approve();
      } else if (result.isDenied) {
        //
      }
    },
    async approve() {
      this.setLoading(true);
      let payload = {
        id: this.id,
        projectId: this.selectedProject.id,
      }
      try {
        await this.$store.dispatch("apPurchaseRequisition/approvePurchaseRequisition", payload);
    
        this.$swal({
          icon: "success",
          title: this.lang.approveSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
    },
    async askForRevertApprove() {
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForRevertApprove}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        await this.revertApprove();
      } else if (result.isDenied) {
        //
      }
    },
    async revertApprove() {
      this.setLoading(true);
      let payload = {
        id: this.id,
        projectId: this.selectedProject.id,
      }
      try {
        await this.$store.dispatch("apPurchaseRequisition/revertApprovePurchaseRequisition", payload);
    
        this.$swal({
          icon: "success",
          title: this.lang.reApproveSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
    },
    async askForClosePR() {
      if (
        this.roleByDocumentBook.length > 0 && 
        (this.roleByDocumentBook[0].insert === "N" && 
         this.roleByDocumentBook[0].update === "N" && 
         this.roleByDocumentBook[0].approve === "N") &&
        !this.isCreateDocument ) 
      {
        this.$swal({
          icon: "warning",
          title: this.lang.validateRoleClose,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return;
      }

      // if (this.validateRoles()) {
      //   return;
      // }
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForClosePR}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        await this.closePR();
      } else if (result.isDenied) {
        //
      }
    },
    async closePR() {
      this.setLoading(true);
      let payload = {
        id: this.id,
        projectId: this.selectedProject.id,
      }

      try {
        await this.$store.dispatch("apPurchaseRequisition/closePurchaseRequisition", payload);
    
        this.$swal({
          icon: "success",
          title: this.lang.closePRSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
    },
    async askForCancel() {      
      if (
        this.roleByDocumentBook.length > 0 && 
        this.roleByDocumentBook[0].cancel === "N" &&
        !this.isCreateDocument ) 
      {
        this.$swal({
          icon: "warning",
          title: this.lang.validateRoleCancel,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return ;
      }
      // if (this.validateRoles()) {
      //   return;
      // }
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForCancelDocument}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        await this.cancel();
      } else if (result.isDenied) {
        //
      }
    },
    async cancel() {
      this.setLoading(true);
      let payload = {
        id: this.id,
        projectId: this.selectedProject.id,
      }
      try {
        await this.$store.dispatch("apPurchaseRequisition/cancelPurchaseRequisition", payload);
    
        this.$swal({
          icon: "success",
          title: this.lang.cancelSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
    },
    async selectPrintForm() {
      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: this.selectedBookCode,
      };
      const payload = { where };
      await this.getFormPrints(payload);
      
      if(this.filteredFormPrints.length == 1) {
        this.path = this.filteredFormPrints[0].path;
        this.printPurchaseRequisition();
      }else{
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path;
      this.printPurchaseRequisition();
      this.showFormPrintModal = false;
    },
    async printPurchaseRequisition() {
      this.setLoading(true);
      const purchaseRequisition = {
        id: this.id,
        path: this.path,
      };

      try {
        await this.$store.dispatch("apPurchaseRequisition/printPurchaseRequisition", purchaseRequisition);
        this.isPrinted = true;
      } catch (error) {
        // console.log("error=", error);
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        this.isPrinted = false;
        this.setLoading(false);
      }
      let blob = this.$store.getters["apPurchaseRequisition/blob"];
      this.getPreviewBlob(blob);
      this.setLoading(false);
    },
    async getRoleByDocumentBook() {
      let where = {
				projectId: this.selectedProject.id,
				documentBookId: this.apBook,
			};

      const payload = {
				page: 1,
				limit: 100,
				where,
			};

      await this.$store.dispatch("roleRoleByDocumentBook/getRoleByDocumentBooks", payload);
    },
    validateRoles() {
      if (
        this.roleByDocumentBook.length > 0 && 
        this.roleByDocumentBook[0].insert === "N" &&
        this.isCreateDocument ) 
      {
        this.$swal({
          icon: "warning",
          title: this.lang.validateRoleInsert,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      if (
        this.roleByDocumentBook.length > 0 && 
        this.roleByDocumentBook[0].update === "N" &&
        !this.isCreateDocument ) 
      {
        this.$swal({
          icon: "warning",
          title: this.lang.validateRoleUpdate,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      // if (
      //   this.roleByDocumentBook.length > 0 && 
      //   this.roleByDocumentBook[0].cancel === "N" &&
      //   !this.isCreateDocument ) 
      // {
      //   this.$swal({
      //     icon: "warning",
      //     title: this.lang.validateRoleCancel,
      //     confirmButtonText: this.lang.close,
      //     allowOutsideClick: false,
      //   });
      //   return true;
      // }

      // if (
      //   this.roleByDocumentBook.length > 0 && 
      //   this.roleByDocumentBook[0].approve === "N" &&
      //   !this.isCreateDocument ) 
      // {
      //   this.$swal({
      //     icon: "warning",
      //     title: this.lang.validateRoleApprove,
      //     confirmButtonText: this.lang.close,
      //     allowOutsideClick: false,
      //   });
      //   return true;
      // }
    },
    // checkImputDiscount(event) {
    //   // console.log("event=",event);
    //   const keysAllowed = ["0","1","2","3","4","5","6","7","8","9",".","%"];
    //   const keyPressed = event.key;
    //   if (!keysAllowed.includes(keyPressed)) {
    //     this.$swal({
    //       icon: "warning",
    //       title: this.lang.validateCheckDiscount,
    //       confirmButtonText: this.lang.close,
    //       allowOutsideClick: false,
    //     });
    //   }
    // }, 
    setDiscountText(data) { 
      this.discount = data;
      this.editDiscount();
    }, 
    doubleClickTaxAmount(){
      // console.log("doubleClickTaxAmount=",event);
      if(!this.isCheckDisabled && this.isTaxAmountDisabled && this.tax != 0){
        document.getElementById('taxAmount').value = "";
        this.isTaxAmountDisabled = false;
        // this.taxAmount = 0;
      }
    },
    editDiscount(){
      // console.log("event.target.value=",event);
      // console.log("this.discount=",this.discount);      
      this.calculateNetAmount();
      this.setTaxAmountFromSystem();
      
    },
    selectTax(){
      this.tax = this.selectedTax.taxRate;
      this.calculateNetAmount();
      this.taxError = false;
      this.setTaxAmountFromSystem();
      if(this.tax == 0){
        this.isTaxAmountDisabled = true;
      }
    },
    setTaxAmountFromSystem(){
      this.taxAmount = parseFloat(this.calculateTaxFromSystem.toFixed(2));
      this.setNetAmount(null);
    },
    setNetAmount(event){
      // console.log("setNetAmount_event=",event);
      let taxAmount = this.taxAmount;      
      if(event !== null){
        taxAmount = event.target.value.replaceAll(",","");
      }         
      
      if(this.calculateTaxFromSystem != taxAmount){        
        if (this.vatInclude === "Y") {
          this.netAmount = this.totalAmount - this.discountAmount;
        } else {
          this.netAmount = this.totalAmount - this.discountAmount + parseFloat(taxAmount);
        }
      }    

      if(this.netAmount >= 0) this.discountAmountError = false;  
    },
    dateSelected(){
      let day = this.creditTerm;
      this.dueDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate() + day,
        0
      );
      this.setCreditTerm();
    },
    editCreditTerm(event) {
      let creditTerm = parseFloat(event.target.value);
      let newValue = 0;
      if(creditTerm > 0)
      { 
        newValue = creditTerm;
      }
      this.dueDate = new Date(this.date.getFullYear(), this.date.getMonth(),this.date.getDate() + newValue,0);
    },
    selectSupplier() {      
      if(this.selectedSupplier){ 
        this.creditTerm = this.selectedSupplier.supplierCreditTerm;
      }
      this.supplierError = false;   
    },
  },
  computed: {
    payload() {
      let details = [];

      for (const each of this.details.filter((each) => each.productId)) {
        let detail = null;
        if (each.id.includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        details.push(detail);
      }

      let payload = {
        projectId: this.selectedProject.id,
        documentBookId: this.selectedBookId,
        referDocument: this.referDocument,
        date: this.formatDateToISOString(this.date),
        dueDate: this.formatDateToISOString(this.dueDate),
        vatSendDate: this.formatDateToISOString(this.vatSendDate),
        totalAmount: this.totalAmount,
        discount: this.discount,
        discountAmount: this.discountAmount,
        vatAmount: this.taxAmount,
        netAmount: this.netAmount,
        vatRate: this.tax,
        remark1: this.remark,
        remark2: this.payment,
        remark3: this.guarantee,
        remark4: this.deliveryDate,
        supplierId: this.selectedSupplier.id,
        employeeId: this.selectedBuyer.id,
        details: details,
        type: "Add",
      }

      if (!this.isCreateDocument) {
        payload = { ...payload, id: this.id, type: "Update" };
      }

      return payload;
    },
    isCreateDocument() {
      return this.id === "create";
    },
    title() {
      let result = this.lang.editPR + " : " + this.selectedBookCode;
      if (this.isCreateDocument) {
        result = this.lang.createPR + " : " + this.selectedBookCode;
      }
      return result;
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
		userCode() {
			return this.$store.getters.userCode;
		},
    employee() {
      return this.$store.getters.employee;
    },
    sharedSuppliers() {
      return this.$store.getters["shared/supplierSuppliers"];
    },
    sharedEmployees() {
      return this.$store.getters["shared/employeeEmployees"];
    },
    sharedTaxes() {
      return this.$store.getters["shared/taxTaxes"];
    },
    purchaseRequisition() {
      return this.$store.getters[
        "apPurchaseRequisition/selectedPurchaseRequisition"
      ];
    },
    isCanceled() {
      if (this.purchaseRequisition) {
        if (this.purchaseRequisition.cancelStatus === "C") {
          return true;
        }
      }
      return false;
    },
    isApproved() {
      if (this.purchaseRequisition) {
        if (this.purchaseRequisition.approveStatus === "P") {
          return true;
        }
      }
      return false;
    },
    isPaid() {
      if (this.purchaseRequisition) {
        if (this.purchaseRequisition.paidStatus === "F" || this.purchaseRequisition.paidStatus === "D" ) {
          return true;
        }
      }
      return false;
    },
    isClosed() {
      if (this.purchaseRequisition) {
        if (this.purchaseRequisition.paidStatus === "C") {
          return true;
        }
      }
      return false;
    },
    isCheckDisabled() {
      let isCheck = false;
      if(this.purchaseRequisition && this.purchaseRequisition.cancelStatus === "C") 
        isCheck = true
      if(this.purchaseRequisition && this.purchaseRequisition.approveStatus === "P") 
        isCheck = true
      if(this.purchaseRequisition && (this.purchaseRequisition.paidStatus === "F" || this.purchaseRequisition.paidStatus === "D" || this.purchaseRequisition.paidStatus === "C")) 
        isCheck = true
      return isCheck
    },
    isCheckCloseDisabled() {
      let isCheck = false;
      if(this.purchaseRequisition && this.purchaseRequisition.cancelStatus === "C") 
        isCheck = true
      if(this.purchaseRequisition && (this.purchaseRequisition.paidStatus === "F" || this.purchaseRequisition.paidStatus === "D" || this.purchaseRequisition.paidStatus === "C")) 
        isCheck = true
      return isCheck
    },
    isCheckApprovedDisabled() {
      let isCheck = false;
      if(this.purchaseRequisition && this.purchaseRequisition.approveStatus != "P") 
        isCheck = true
      if(this.purchaseRequisition && this.purchaseRequisition.cancelStatus === "C") 
        isCheck = true
      if(this.purchaseRequisition && (this.purchaseRequisition.paidStatus === "F" || this.purchaseRequisition.paidStatus === "D" || this.purchaseRequisition.paidStatus === "C")) 
        isCheck = true
      return isCheck
    },
    lastItemNo() {
      const itemNo = Math.max.apply(
        Math,
        this.details.map(function (o) {
          return o.itemNo;
        })
      );
      return itemNo;
    },
    roleByDocumentBook() {
			return this.$store.getters["roleRoleByDocumentBook/roles"];
		},
    isCheckRoleApprove() {
      let isCheck = false;

      if(this.roleByDocumentBook.length > 0) {
        if (this.roleByDocumentBook[0].approve === "Y") 
          isCheck = true
      } 

      return isCheck
    },
    isCheckSaveAs() {
      let isCheck = false;
      if (!this.code) isCheck = true;
      return isCheck;
    },
    classDisableTaxAmount() {
      let setClass = "text-right ";
      if (this.isTaxAmountDisabled) {
        setClass = "text-right input-number-disable";
      }
      return setClass;
    },
  },
  watch: {
    isSharedSupplierReady() {
      this.checkShouldSetData();
    },
    isSharedEmployeeReady() {
      this.checkShouldSetData();
    },
    isSharedTaxReady() {
      this.checkShouldSetData();
    },
    purchaseRequisition() {
      this.checkShouldSetData();
    },
  },
};
</script>

