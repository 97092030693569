export default {
    title: "ตั้งค่าเวอร์ชันแบบฟอร์ม",
    add: "เพิ่ม",
    projectCode: "รหัสโครงการ",
    projectName: "ชื่อโครงการ",
    menuCode: "รหัสเมนู",
    menuName: "ชื่อเมนู",
    formVersion: "เวอร์ชันแบบฟอร์ม",
    jasperReport: "Jasper Report",
    fastReport: "Fast Report",
    createBy: "ผู้สร้าง",
    createDate: "วันที่สร้าง",
    updateBy: "ผู้แก้ไข",
    updateDate: "วันที่แก้ไข",
    searchText: "กรอกข้อมูลเพื่อค้นหา",
    addFormVersion: "เพิ่มเวอร์ชันแบบฟอร์ม",
    editFormVersion: "แก้ไขเวอร์ชันแบบฟอร์ม",
    project: "โครงการ",
    validateProject: "เลือกโครงการ",
    menuInvoice: "ใบแจ้งหนี้",
    menuNotice: "หนังสือทวงถาม",
    menuReceipt: "ใบเสร็จ",
    menuCreditNote: "ใบลดหนี้",
    menuDeposit: "ใบรับฝากเงิน",
    menuDeptfree: "ใบปลอดหนี้",
    menuDeptfreeRequest: "หนังสือรับรองหนี้",
    menu: "เมนู",
    validateMenu: "เลือกเมนู",
}