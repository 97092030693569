export default {
    setUnit(state, payload) {
      state.units = payload.data;
      state.totalPage = payload.page.pageCount;
      state.currentPage = payload.page.currentPage;
    },
    setSelectedUnit(state, payload) {
      state.selectedUnit = payload;
    },
    resetUnit(state) {
      state.units = [];
      state.totalPage = 0;
      state.currentPage = 0;
    },
  };
  