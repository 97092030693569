<template>
  <base-toolbar
    :title="lang.title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoCreate="gotoEdit('create')"
    :hideBackButton="isHideBack"
  >
  </base-toolbar>
  <base-container>
    <base-dialog
      v-model:visible="showConfirmDialog"
      :titleConfirm="confirmTitle"
      :classTitle="confirmClassTitle"
      :wordingConfirm="confirmWording"
      :dataButtons="dataButtonDialog"
      @close="showConfirmDialog = false"
      @confirm="showConfirmDialog = false"
    ></base-dialog>

    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchText"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>

    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5" :class="textFormClass">
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.title }}</label>
        <PvDivider></PvDivider>
      </div>

      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >
        <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            class="inputfield w-full"
            :options="selectSearchType"
            optionLabel="name"
            optionValue="code"
            v-model="selectedSearchType"
            @change="changeSelectedSearchType()"
          />
        </div>
        <div
          class="flex lg:col-6 md:col-12 col-12 pl-0 pr-2 mr-2"
          v-if="this.selectedSearchType === 'Date'"
        >
          <div class="col-6 pl-0 pr-2">
            <PvCalendar
              id="dateStart"
              v-model="dateStart"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
          <label for="dateEnd" class="pt-1 mt-3 mr-2">{{ "-" }}</label>
          <div class="col-6 pl-0 pr-2">
            <PvCalendar
              id="dateEnd"
              v-model="dateEnd"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
        </div>
        <div
          class="flex lg:col-6 md:col-12 col-12 pl-0 pr-0 mr-2"
          v-if="isShowSearchText"
        >
          <div class="col-12 pl-0 pr-0">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchTextBankAr"
                :placeholder="this.selectedSearchTypePlaceholder"
                @keypress="searchBankAr($event)"
                autofocus
              />
            </span>
          </div>
        </div>
        <div
          v-if="this.selectedSearchType === 'PaymentMethodCode'"
          class="flex lg:col-6 md:col-12 col-12 pl-0 pr-0 mr-2"
        >
          <base-payment-method-auto-complete
            id="paymentMethodCode"
            v-model="selectedPaymentMethodCode"
            @load-finish="paymentTypeDataReady"
            :typeInOut="payType"
            class="inputfield w-full"
            v-show="!isPaymentMethodLoading"
          />
          <PvSkeleton
            v-show="isPaymentMethodLoading"
            class="inputfield w-full"
            height="3rem"
          />
        </div>
        <div
          v-if="this.selectedSearchType === 'Status'"
          class="flex lg:col-6 md:col-12 col-12 pl-0 pr-0 mr-2"
        >
          <PvDropdown
            id="selectedStatus"
            class="inputfield w-full"
            :options="statusOptions"
            optionLabel="name"
            optionValue="code"
            v-model="selectedStatus"
          />
        </div>
        <PvButton @click="getPayinLists()">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>
      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="payinData"
          v-model:selection="selectedPayin"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(selectedPayin.id)"
          scrollable
        >
          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
          <PvColumn
            field="payDate"
            :header="lang.columnPayDate"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            :sortable="true"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.payDate) }}
            </template>
          </PvColumn>
          <PvColumn
            field="toBankDate"
            :header="lang.columnToBankDate"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            :sortable="true"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.toBankDate) }}
            </template>
          </PvColumn>
          <PvColumn
            field="code"
            :header="lang.columnCode"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            :sortable="true"
          ></PvColumn>
          <PvColumn
            field="customerCode"
            :header="lang.columnCustomerCode"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
          ></PvColumn>
          <PvColumn
            field="customerName"
            :header="lang.columnCustomerName"
            style="min-width: 15rem; height: 56px"
            headerClass="h-left"
          ></PvColumn>
          <PvColumn
            field="payAmount"
            :header="lang.columnPrice"
            style="min-width: 8rem; height: 56px"
            headerClass="h-right"
          >
            <template #body="slotProps">
              <span class="text-right w-full">
                {{ formatCurrency(slotProps.data.payAmount) }}
              </span>
            </template>
          </PvColumn>
          <PvColumn
            field="paymentMethodName"
            :header="lang.columnPayName"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
          ></PvColumn>
          <PvColumn
            field="status"
            :header="lang.columnStatus"
            style="min-width: 4rem; height: 56px"
            headerClass="h-left"
          ></PvColumn>
          <PvColumn
            field="cancelStatus"
            :header="lang.columnIsCancel"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
          >
            <template #body="{ data, field }">
              <base-tag
                v-if="data[field].trim() != ''"
                :Caption="lang.cancel"
                Class="error"
              />
            </template>
          </PvColumn>
          <PvColumn
            style="min-width: 10rem"
            headerClass="h-center;height: 56px"
            bodyStyle="text-align: center; overflow: visible"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                @click="selectPrintForm(slotProps.data)"
              >
                <icon-printer iconColor="secondary" width="24" height="24" />
              </PvButton>
            </template>
          </PvColumn>
          <template #footer>
            <div>
              <PvPaginator
                :rows="rowsPerPage"
                :totalRecords="totalRecords"
                @page="onPageChange($event)"
                :rowsPerPageOptions="[10, 50, 100]"
                class="p-pagination-radius"
              >
                <template #start="slotProps">
                  {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{
                    totalPage
                  }}
                </template>
              </PvPaginator>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import basePaymentMethodAutoComplete from "../../../components/bank/BasePaymentMethodAutoComplete.vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";

export default {
  components: {
    basePaymentMethodAutoComplete,
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.ar.bank.listing };
      } else {
        return { ...lang.en.base, ...lang.en.ar.bank.listing };
      }
    });

    const isHideBack = inject("isHideBack");

    const {
      getFormPrints,
      searchText,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints,
    } = useGetFormPrint();

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      isHideBack,
      getFormPrints,
      searchText,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
    };
  },
  data() {
    return {
      isLoading: false,
      selectSearchType: [
        { name: this.lang.date, code: "Date" },
        { name: this.lang.codeBank, code: "Code" },
        { name: this.lang.customerSearch, code: "CustomerSearch" },
        { name: this.lang.bookBankName, code: "BookBankName" },
        { name: this.lang.paymentMethodCode, code: "PaymentMethodCode" },
        { name: this.lang.status, code: "Status" },
        { name: this.lang.dropdownSearchByReceiptCode, code: "ReceiptCode" },
      ],
      statusOptions: [
        { code: "N", name: "N : ในมือ" },
        { code: "B", name: "B : เข้าธนาคาร" },
        { code: "R", name: "R : คืน" },
      ],
      selectedStatus: "",
      selectedSearchType: "Date",
      selectedPaymentMethodCode: null,
      dateStart: null,
      dateEnd: null,
      searchTextBankAr: "",
      payinData: [],
      selectedPayin: null,
      confirmWording: "",
      confirmTitle: "",
      confirmClassTitle: "",
      showConfirmDialog: false,
      selectedPage: 1,
      rowsPerPage: 10,
      isSharedPayTypeReady: false,
      isPaymentMethodLoading: true,
      payType: 1,
      dataPrint: null,
      path: null,
      showFormPrintModal: false,
      start: null,
      end: null,
      code: null,
      customerSearch: null,
      bookBankName: null,
      paymentMethodId: null,
      status: null,
      validate: false,
      storageName: "",
      receiptCode: null,
    };
  },
  async created() {
    await this.$store.dispatch("bank/setTypeInOut", { type: this.payType });
  },
  async mounted() {
    this.isLoading = true;
    await this.initiateData();
    this.checkShouldSetData();
  },
  methods: {
    async initiateData() {
      let date = new Date();
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.searchTextBankAr = "";

      this.start = null;
      this.end = null;
      this.code = null;
      this.customerSearch = null;
      this.bookBankName = null;
      this.paymentMethodId = null;
      this.status = null;
      this.receiptCode = null;

      this.storageName = `erp_search_BankArList_${this.selectedProject.code}_${this.userCode}`;
    },
    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      if (localSearch) {
        let search = JSON.parse(localSearch);

        if (search.entry === "entry") {
          if (search.startDate != null && search.startDate != "") {
            this.selectedFilterSearch = "Date";
            this.dateStart = new Date(search.startDate);
          } else this.dateStart = "";

          if (search.endDate != null && search.endDate != "") {
            this.selectedSearchType = "Date";
            this.dateEnd = new Date(search.endDate);
          } else this.dateEnd = "";

          if (search.code != null && search.code != "") {
            this.selectedSearchType = "Code";
            this.searchTextBankAr = search.code;
          } else if (search.customerCode != null && search.customerCode != "") {
            this.selectedSearchType = "CustomerSearch";
            this.searchTextBankAr = search.customerCode;
          } else if (search.bookBankName != null && search.bookBankName != "") {
            this.selectedSearchType = "BookBankName";
            this.searchTextBankAr = search.bookBankName;
          } else if (search.paymentMethodId != null && search.paymentMethodId != "") {
            this.selectedSearchType = "PaymentMethodCode";
            this.selectedPaymentMethodCode = this.sharedPaymentType.find(
              (paymentType) => paymentType.id === search.paymentMethodId
            );
          } else if (search.status != null && search.status != "") {
            this.selectedSearchType = "Status";
            this.selectedStatus = search.status;
          } else if (search.receiptCode != null && search.receiptCode != "") {
            this.selectedSearchType = "ReceiptCode";
            this.searchTextBankAr = search.receiptCode;
          }

          this.rowsPerPage = search.rowsPerPage;
          this.selectedPage = search.selectedPage;

          search.entry = "";

          localStorage.setItem(this.storageName, JSON.stringify(search));
        }
      }
    },
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
			this.$router.push({name: "home"})
    },
    gotoEdit(id) {
      if (id != "create" && !this.isRoleSelect) {
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateRoleSelect,
          isConfirm: false,
        });
        return;
      }
      else if (id == "create" && !this.isRoleInsert) {
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateRoleInsert,
          isConfirm: false,
        });
        return;
      }

      this.$router.push({ name: "ar-bank-ar-edit", params: {id: id} });
    },
    async selectPrintForm(data) {
      if (!this.isRolePrint) {
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validatePrintPermission,
          isConfirm: false,
        });
        return;
      }

      try {
        await this.$store.dispatch("shared/resetFormPrint");
        this.dataPrint = data;
        let where = {
          projectId: this.selectedProject.id,
          documentBookCode: "PayIn",
        };
        const payload = { where };
        await this.getFormPrints(payload);

        if (this.filteredFormPrints.length == 1) {
          this.path = this.filteredFormPrints[0].path;
          this.printPayin(data.id);
        } else {
          this.showFormPrintModal = true;
        }
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path;
      this.printPayin(this.dataPrint.id);
      this.showFormPrintModal = false;
    },
    changeSelectedSearchType() {
      this.initiateData();
    },
    async printPayin(id) {
      try {
        const payin = {
          id: id,
          path: this.path,
        };
        await this.$store.dispatch("arBank/printPayin", payin);
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
      }
      let blob = this.$store.getters["arBank/blob"];
      this.getPreviewBlob(blob);
    },
    searchBankAr(event) {
      if (event.key == "Enter") {
        this.getPayinLists();
      }
    },
    async setDataSearch() {
      if (this.selectedSearchType === "Date") {
        this.start = this.selectedDateStart;
        this.end = this.selectedDateEnd;
      } else if (this.selectedSearchType === "Code") {
        this.code = this.searchTextBankAr;
      } else if (this.selectedSearchType === "CustomerSearch") {
        this.customerSearch = this.searchTextBankAr;
      } else if (this.selectedSearchType === "BookBankName") {
        this.bookBankName = this.searchTextBankAr;
      } else if (this.selectedSearchType === "PaymentMethodCode") {
        this.paymentMethodId = this.selectedPaymentMethodCode.id;
      } else if (this.selectedSearchType === "Status") {
        this.status = this.selectedStatus;
      } else if (this.selectedSearchType === "ReceiptCode") {
        this.receiptCode = this.searchTextBankAr;
      }
    },
    async getPayinLists() {
      this.isLoading = true;
      if (this.validateSearchButton) {
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateSearchText,
          isConfirm: false,
        });
        this.isLoading = false;
        return;
      }
      await this.setDataSearch();
      try {
        let where = {
          projectId: this.selectedProject.id,
          startDate: this.start || null,
          endDate: this.end || null,
          code: this.code || null,
          customerCode: this.customerSearch || null,
          customerName: this.customerSearch || null,
          bookBankAccountNumber: this.bookBankName || null,
          bookBankName: this.bookBankName || null,
          paymentMethodId: this.paymentMethodId || null,
          status: this.status || null,
          receiptCode: this.receiptCode || null,
        };

        const payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          where,
        };

        await this.$store.dispatch("arBank/getPayinLists", payload);
        this.payinData = this.arBanks;

        let local = {
          ...where,
          rowsPerPage: this.rowsPerPage,
          selectedPage: this.selectedPage,
        };
        localStorage.setItem(this.storageName, JSON.stringify(local));

        this.isLoading = false;
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.isLoading = false;
      }
    },
    openAlertDialog(dataConfig) {
      if (dataConfig.severity === "") {
        this.confirmClassTitle = "";
      } else if (dataConfig.severity === "success") {
        this.confirmClassTitle = "p-text-success";
      } else if (dataConfig.severity === "error") {
        this.confirmClassTitle = "p-error";
      }
      this.confirmTitle = dataConfig.title;
      this.confirmWording = dataConfig.content;
      this.showConfirmDialog = true;
    },
    paymentTypeDataReady() {
      this.isSharedPayTypeReady = true;
      this.isPaymentMethodLoading = false;
    },
    async checkShouldSetData() {
      if (this.isSharedRoleByMenuReady) {
        await this.setLocalSearch();
        await this.getPayinLists();
      }
    },
    async onPageChange(event) {
      this.isLoading = true;
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getPayinLists();
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart && this.selectedSearchType == "Date") {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd && this.selectedSearchType == "Date") {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    selectedSearchTypePlaceholder() {
      let placeholder = this.lang.search;
      if (this.selectedSearchType === "Code") placeholder += this.lang.codeBank;
      if (this.selectedSearchType === "CustomerSearch") placeholder += this.lang.customerSearch;
      if (this.selectedSearchType === "BookBankName") placeholder += this.lang.bookBankName;
      if (this.selectedSearchType === "PaymentMethodCode") placeholder += this.lang.paymentMethodCode;
      if (this.selectedSearchType === "Status") placeholder += this.lang.status;
      if (this.selectedSearchType === "ReceiptCode") placeholder += this.lang.dropdownSearchByReceiptCode;
      return placeholder;
    },
    isShowSearchText() {
      return (
        this.selectedSearchType === "Code" ||
        this.selectedSearchType === "CustomerSearch" ||
        this.selectedSearchType === "BookBankName" ||
        this.selectedSearchType === "ReceiptCode" 
      );
    },
    arBanks() {
      return this.$store.getters["arBank/payin"];
    },
    totalPage() {
      return this.$store.getters["arBank/totalPage"];
    },
    currentPage() {
      return this.$store.getters["arBank/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    dataButtons() {
      return [
        {
          id: "createArBank",
          caption: this.lang.createBank,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
        },
      ];
    },
    dataButtonDialog() {
      return [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("PayIn");
    },
    isRoleSelect() {
      if (this.roleByMenu && this.roleByMenu.select == "Y") {
        return true;
      }
      return false;
    },
    isRoleInsert() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    isRolePrint() {
      if (this.roleByMenu && this.roleByMenu.print == "Y") {
        return true;
      }
      return false;
    },
    validateSearchButton() {
      if (
        this.selectedSearchType === "Date" &&
        (!this.selectedDateStart || !this.selectedDateEnd)
      ) {
        return true;
      } else if (
        (this.selectedSearchType === "Code" ||
          this.selectedSearchType === "CustomerSearch" ||
          this.selectedSearchType === "BookBankName" || 
          this.selectedSearchType === "ReceiptCode") &&
        !this.searchTextBankAr
      ) {
        return true;
      } else if (
        this.selectedSearchType === "PaymentMethodCode" &&
        !this.selectedPaymentMethodCode
      ) {
        return true;
      } else if (this.selectedSearchType === "Status" && !this.selectedStatus) {
        return true;
      }
      return false;
    },
    sharedPaymentType() {
      return this.$store.getters["bank/transactionType"];
    },
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.checkShouldSetData();
    },
  },
};
</script>
