export default {
  accountingAccounts(state) {
    return state.accounting.accountings;
  },
  accountingCurrentPage(state) {
    return state.accounting.currentPage;
  },
  accountingNextPage(state) {
    return state.accounting.currentPage + 1;
  },
  accountingTotalPage(state) {
    return state.accounting.totalPage;
  },
  accountingIsLastPage(_, getters) {
    return (
      getters.accountingCurrentPage === getters.accountingTotalPage &&
      getters.accountingTotalPage !== 0
    );
  },
};
