<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <!-- <PvMenubar :model="menuItems" /> -->
  <!-- v-if="isAuthenticated && isMenuVisible && isProjectSelected" -->
  <div>
    <!-- <the-header v-if="isAuthenticated && isMenuVisible && isProjectSelected"></the-header> -->
    <the-sidebar
      v-if="isAuthenticated && isMenuVisible && isProjectSelected"
    ></the-sidebar>
    <the-navigation
      v-if="isAuthenticated && isMenuVisible && isProjectSelected"
    ></the-navigation>
    <PvConfirmDialog
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }"
      :closable="false"
    >
    </PvConfirmDialog>
    <the-loader></the-loader>
    <PvToast
      Toast
      :style="{ width: '95%', opacity: 1, top: '5rem' }"
      position="top-center"
    ></PvToast>
    <div>
      <!-- <router-view /> -->

      <!-- <transition name="fade">
      <router-view />
      </transition> -->
      <!-- <router-view v-slot="{Component}">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view> -->
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <div><component :is="Component"></component></div>
          <!-- <div style="background-color: #E5E5E5;"><component :is="Component"></component></div> -->
        </transition>
      </router-view>
    </div>
  </div>
  <DialogsWrapper />
</template>

<script>
import { ref, provide, computed } from "@vue/runtime-core";
import TheHeader from "./components/layout/TheHeader.vue";
import TheLoader from "./components/layout/TheLoader.vue";
import TheNavigation from "./components/layout/TheNavigation.vue";
// import TheSidebar from "./components/layout/TheSidebar.vue" ;
import TheSidebar from "./components/v2/layout/TheSidebar.vue";
import useGetRoleByBook from "./hooks/getRoleByBook.js";
import useGetRoleByMenu from "./hooks/getRoleByMenu.js";
import { jwtDecode } from "jwt-decode";
import useTracking from "./hooks/tracking";

export default {
  components: {
    TheHeader,
    TheLoader,
    TheNavigation,
    TheSidebar,
  },
  setup() {
    const isHideBack = ref(true);
    const isMenuVisible = ref(true);

    const hideBack = function () {
      isHideBack.value = false;
    };

    const hideMenu = function () {
      isMenuVisible.value = false;
      hideBack();
    };

    const showBack = function () {
      isHideBack.value = true;
    };

    const counter = computed(function () {
      return isHideBack.value;
    });

    provide("isHideBack", counter);
    provide("isMenuVisible", isMenuVisible);

    const { tryGetAllRoleByBooks, getAllRoleByBooks } = useGetRoleByBook();
    const { tryGetAllRoleByMenus, getAllRoleByMenus } = useGetRoleByMenu();

    const { trackingSetDefault } = useTracking();

    //v0.1.70
    return {
      hideBack,
      showBack,
      tryGetAllRoleByBooks,
      tryGetAllRoleByMenus,
      hideMenu,
      isMenuVisible,
      getAllRoleByBooks,
      getAllRoleByMenus,
      trackingSetDefault
    };
  },
  // data() {
  //   return {
  //     isMenuVisible: true,
  //   };
  // },
  async created() {
    this.setViewportHeight();
    window.addEventListener("resize", this.setViewportHeight);
    await this.checkTokenVersion();

    // this.getDataFromLocalStorage();

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    if (params.isHideMenu === "Y")
    {
      this.hideMenu();
    }

    if (
      params.appToken 
      && params.appUserId 
      && params.appUserCode
      && params.appUserName
      && params.appExpiration
    ) 
    {
      localStorage.setItem("erp_token", params.appToken);
      localStorage.setItem("erp_userId", params.appUserId);
      localStorage.setItem("erp_userCode", params.appUserCode);
      localStorage.setItem("erp_userName", params.appUserName);
      localStorage.setItem("erp_tokenExpiration", params.appExpiration);
    }

    if (
      params.projectId
      && params.projectCode
      && params.projectNameTh
      && params.projectNameEn
    )
    {
      const project = {
        id: params.projectId,
        code: params.projectCode,
        nameTh: params.projectNameTh,
        nameEn: params.projectNameEn,
        branchId: params.branchId ?? null,
        taxId: params.taxId ?? null,
        facilityBookingURL: params.facilityBookingURL ?? null,
        announcementURL: params.announcementURL ?? null,
      };
      localStorage.setItem("erp_selectedProject", JSON.stringify(project));
    }

    if (
      params.projectId
      && params.appUserCode
    )
    {
      this.$store.dispatch("getEmployeeByCodeAndProject", {
        projectId: params.projectId,
        employeeCode: params.appUserCode,
      });
    }

    this.clearLocalStorageExcludeSelectedProject(params.projectCode, params.appUserCode);

    this.getDataFromLocalStorage();

  },
  mounted() {
    // Add event listener on keydown
    document.addEventListener("keydown", this.keyDown, false);
    this.trackingSetDefault();
  },
  methods: {
    getDataFromLocalStorage() {
      this.$store.dispatch("tryLogin");
      // this.$store.dispatch("trySetProject");
      this.$store.dispatch("trySetLanguage");
      // this.$store.dispatch("trySetEmployee");

      // if (this.isProjectSelected) 
      // {
      //   console.log("isProjectSelected", this.$route);
      //   this.tryGetAllRoleByBooks();
      //   this.tryGetAllRoleByMenus();
      // }
    },
    setViewportHeight() {
      this.$store.dispatch("setViewportHeight");
    },
    keyDown(event) {
      var name = event.key;
      // var code = event.code;
      var preventActionKeys = [
        "F2",
        "F3",
        "F4",
        "F5",
        "F6",
        "F8",
        "F10",
        "F11",
        "Escape",
      ];
      if (name === "Control" || name === "Alt") {
        // Do nothing.
        return;
      }
      if (preventActionKeys.includes(name)) {
        event.preventDefault();
        event.stopPropagation();
        // console.log(
        //   `EditInvoiceView \n Key down ${name} \n Key code Value: ${code} has prevented`
        // );
      }
      // if (event.ctrlKey) {
      //   console.log(`Combination of ctrlKey + ${name} \n Key code Value: ${code}`);
      // }
    },
    clearLocalStorageExcludeSelectedProject(projectCode = "", userCode = "") {
      if (!projectCode && this.isProjectSelected) {
        projectCode = this.selectedProject.code;
      }
      if (!userCode) {
        userCode = this.userCode;
      }
      if (projectCode && userCode) {
        let keyCheck = "_" + projectCode.trim() + "_" + userCode.trim();
        // const regexRole = new RegExp("erp_role_*");
        const regexSearch = new RegExp("erp_search_*");

        for (let i = 0; i < localStorage.length; i++) {
          let keyLocal = localStorage.key(i);
          if (regexSearch.test(keyLocal)) {
            if (!keyLocal.includes(keyCheck)) {
              // console.log(keyLocal);
              localStorage.removeItem(keyLocal);
            }
          }
        }
      }
      
    },
    async checkTokenVersion() {
      const token = localStorage.getItem("erp_token");
      if (token) {
        // let tokenDecode = VueJwtDecode.decode(token);
        const tokenDecode = jwtDecode(token);
        if (!tokenDecode.token_version || tokenDecode.token_version != process.env.VUE_APP_TOKEN_VERSION) {
          this.$store.dispatch("autoLogout");
        }
      }
    }
  },
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isProjectSelected() {
      return this.$store.getters.isProjectSelected;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
		userCode() {
			return this.$store.getters.userCode;
		}
  },
  watch: {
    didAutoLogout(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace("/login");
      }
    },
    $route(curValue) {
      if (curValue.query.back && !this.isMenuVisible) {
        if (curValue.query.back !== "home") {
          this.showBack();
        } else {
          this.hideBack();
        }
      } else if (!this.isMenuVisible) {
        this.hideBack();
      }
    },
    isProjectSelected(curValue) {
      if (curValue) {
        this.clearLocalStorageExcludeSelectedProject()
      }
    }
  },
};
</script>

<style scoped>
/* .fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
} */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}
</style>

<style lang="scss">
html {
  font-size: 14px;
  color: var(--text-color);
}
// @font-face {
//   font-family: "SFThonburi";
//   src: url(./fonts/SFThonburi-Regular.woff) format("woff");
//   font-weight: 400;
//   font-display: auto;
// }

// @font-face {
//   font-family: "SFThonburi";
//   src: url(./fonts/SFThonburi-Bold.woff) format("woff");
//   font-weight: 700;
//   font-display: auto;
// }

// @font-face {
//   font-family: "SFThonburi";
//   src: url(./fonts/SFThonburi-Semibold.woff) format("woff");
//   font-weight: 600;
//   font-display: auto;
// }
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;700&display=swap");
$color: red;
* {
  box-sizing: border-box;
}

.h-center .p-column-header-content {
  justify-content: center !important;
}

.h-left .p-column-header-content {
  justify-content: left !important;
}

.h-right .p-column-header-content {
  justify-content: right !important;
}

.p-button-label,
.p-dialog,
.p-menuitem,
.p-datatable,
.p-inputtext,
.p-tabview,
.custom-tooltip,
.p-sidebar,
.p-dropdown-items,
.p-datepicker,
.p-datepicker-month,
.p-datepicker-year,
.p-autocomplete-panel,
.p-component,
.p-link,
pre,
[class*="swal2"],
p {
  font-family: "Sarabun", sans-serif;
}

#app {
  font-family: "Sarabun", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
}
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }
// body {
//   margin: 0px;
// }

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
.description-column-style {
  min-width: 5rem;
  max-width: 15rem;
  white-space: normal;
  word-wrap: break-word;
}

.font-xs {
  font-size: 12px;
}

.font-sm {
  font-size: 14px;
}

.font-md {
  font-size: 16px;
}

.font-lg {
  font-size: 18px;
}

.font-xl {
  font-size: 20px;
}

.font-2xl {
  font-size: 22px;
}

.font-3xl {
  font-size: 24px;
}

.font-4xl {
  font-size: 26px;
}

.font-5xl {
  font-size: 28px;
}

.font-6xl {
  font-size: 30px;
}

.font-7xl {
  font-size: 32px;
}
</style>
