<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.083 9.99999V7.49999C13.083 5.10699 15.023 3.16699 17.416 3.16699V3.16699C19.809 3.16699 21.749 5.10699 21.749 7.49999V9.66699"    
      :class="strokeColor"
      stroke="#323232"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.292 21H4.292C3.187 21 2.292 20.105 2.292 19V12C2.292 10.895 3.187 10 4.292 10H14.292C15.397 10 16.292 10.895 16.292 12V19C16.292 20.105 15.397 21 14.292 21V21Z"    
      :class="strokeColor"
      stroke="#323232"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.29 14V17"    
      :class="strokeColor"
      stroke="#323232"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "primary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
