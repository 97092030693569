<template>
  <base-container>
    <prepaid-receipt-dialog
      v-model:visible="showPrepaidReceipt"
      @close="showPrepaidReceipt = false"
      @select-prepaid-receipt="selectPrepaidReceipt"
    ></prepaid-receipt-dialog>

    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchTextPrint"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>
    <div class="flex justify-content-between align-items-center">
      <div class="my-3">
        <PvButton
          icon="pi pi-chevron-left"
          :label="lang.back"
          @click="goBack"
          class="mr-2 p-button-secondary"
        />
      </div>
      <h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
    </div>
    <transition name="fade" mode="out-in">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="pt-2 pb-1">
          <PvButton
            icon="pi pi-file"
            :label="lang.create"
            class="p-button-secondary mr-2 mb-2"
            @click="CreatePrepaid"
          />
          <PvButton
            icon="pi pi-save"
            :label="lang.save"
            class="mr-2 mb-2"
            @click="SavePrepaid"
            :disabled="isDocumentLocked || isPrinted"
          />
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.printPdf"
            class="mr-2 mb-2"
            @click="selectPrintForm"
            :disabled="isCreateDocument"
          />
          <PvButton
            icon="pi pi-times"
            :label="lang.cancel"
            class="p-button-outlined p-button-danger mr-2 mb-2"
            @click="askForCancel"
            :disabled="isCreateDocument || isCanceled"
          />
        </div>
        <section class="flex flex-wrap" :class="textFormClass">
          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
            <label for="code">{{ lang.DocumentCode }}</label>
            <PvInputText
              id="code"
              type="text"
              class="inputfield w-full mt-1 input-disable"
              v-model="code"
              readonly
            />
          </div>
          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
            <label for="dateAt">{{ lang.DateAt }}</label>
            <PvCalendar
              id="dateAt"
              v-model="dateAt"
              class="inputfield w-full mt-1"
              showIcon
              dateFormat="dd/mm/yy"
              disabled
            />
          </div>

          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
            <label for="employeeName">{{ lang.employeeName }}</label>
            <PvInputText
              id="employeeName"
              type="text"
              class="inputfield w-full mt-1"
              readonly
              v-model.trim="employeeName"
            />
          </div>

          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
            <label for="selectedUnit">{{ lang.UnitCode }}</label>
            <base-unit-auto-complete
              id="selectedUnit"
              v-model="selectedUnit"
              class="inputfield w-full mt-1"
              @load-finish="unitDataReady"
              :readonly="!isCreateDocument"
              :class="{ 'p-invalid': unitError }"
              @item-select="selectUnit"
              :isValidateActiveStatus="true"
              @is-inactive="clearPrepaid"
            />
            <small v-if="unitError" id="unit-help" class="p-error">{{
              lang.validateNoUnitSelect
            }}</small>
          </div>

          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
            <label for="customerName">{{ lang.CustomerName }}</label>
            <PvInputText
              id="customerName"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="customerName"
              readonly
            />
          </div>

          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
            <label for="ReceiveMan">{{ lang.ReceiveMan }}</label>
            <PvInputText
              id="ReceiveMan"
              type="text"
              class="inputfield w-full mt-1"
              v-model="ReceiveMan"
            />
          </div>

          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
            <label for="Remark">{{ lang.Remark }}</label>
            <PvInputText
              id="Remark1"
              type="text"
              class="inputfield w-full mt-1"
              v-model="Remark"
              :class="{ 'p-invalid': remarkError }"
            />
            <small v-if="remarkError" id="remark-help" class="p-error">{{
              lang.validateNoRemark
            }}</small>
          </div>

          <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
            <label for="totalAmount">{{ lang.totalAmount }}</label>
            <PvInputNumber
              id="totalAmount"
              class="inputfield w-full mt-1"
              v-model="totalAmount"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              readonly
            />
          </div>
        </section>

        <div class="pt-2 pb-1">
          <PvButton
            icon="pi pi-search"
            :label="lang.searchReceived"
            class="mr-2 mb-2"
            @click="getPrepaidRefundReceipts"
            :disabled="isDocumentLocked"
          />
        </div>

        <PvDataTable
          :value="PrepaidEntryDetail"
          editMode="row"
          dataKey="referenceId"
          v-model:editingRows="editingRows"
          @row-edit-save="onRowEditSave"
          responsiveLayout="scroll"
          :scrollHeight="halfViewportHeight + 'px'"
          class="p-datatable-sm"
          :class="textTableClass"
          :loading="isLoading"
        >
          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
          <PvColumn
            field="itemNo"
            :header="lang.itemNo"
            style="min-width: 2rem"
            headerClass="h-center"
            bodyClass="text-center"
          >
          </PvColumn>
          <PvColumn
            field="code"
            :header="lang.columnCodeReceipt"
            style="min-width: 3rem"
            headerClass="h-center"
          ></PvColumn>

          <PvColumn
            field="productCode"
            :header="lang.columnProductCode"
            style="min-width: 4rem"
            headerClass="h-center"
          ></PvColumn>

          <PvColumn
            field="productName"
            :header="lang.columnProductName"
            style="min-width: 4rem"
            headerClass="h-center"
          ></PvColumn>

          <PvColumn
            field="remark"
            :header="lang.columnRemark"
            style="min-width: 4rem"
            headerClass="h-center"
            bodyClass="text-left"
          >
            <template #editor="{ data, field }">
              <PvInputText v-model="data[field]" />
            </template>
          </PvColumn>

          <PvColumn
            field="period"
            :header="lang.columnPeriod"
            style="min-width: 4rem"
            headerClass="h-center"
          ></PvColumn>

          <PvColumn
            field="amount"
            :header="lang.columnPrice"
            style="min-width: 3rem"
            headerClass="h-center"
            bodyClass="text-right"
          >
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.amount) }}
            </template>
          </PvColumn>

          <!-- <PvColumn
            field="quantity"
            :header="lang.columnQuantity"
            style="min-width: 3rem"
            headerClass="h-center"
            bodyClass="text-right"
          ></PvColumn>

          <PvColumn
            field="balanceAmount"
            :header="lang.columnBalance"
            style="min-width: 3rem"
            headerClass="h-center"
            bodyClass="text-right"
          >
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.balanceAmount) }}
            </template>
          </PvColumn> -->

          <PvColumn
            field="totalAmount"
            :header="lang.columnTotal"
            style="min-width: 3rem"
            headerClass="h-center"
            bodyClass="text-right"
          >
            <template #editor="{ data, field }">
              <PvInputText
                type="number"
                v-model.number="data[field]"
                style="width: 6rem"
              />
            </template>

            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.totalAmount) }}
            </template>
          </PvColumn>

          <PvColumn
            :rowEditor="true"
            style="min-width: 2rem; text-align: center"
          ></PvColumn>
          <PvColumn
            style="min-width: 1rem"
            headerClass="h-center"
            bodyStyle="text-align: center; overflow: visible"
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0"
                icon="pi pi-trash"
                @click="DeletePrepaidDetail(slotProps.data.referenceId)"
              ></PvButton>
            </template>
          </PvColumn>
        </PvDataTable>
      </div>
    </transition>
    <br />
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import prepaidReceiptDialog from "../../../components/prepaid/BasePrepaidReceiptDialog.vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
export default {
  props: ["id", "projectCode"],
  components: {
    prepaidReceiptDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass, textAlertClass } =
      useTextStyle();
    const {
      formatCurrency,
      formatDate,
      formatDateToISOString,
      // formatPrice,
    } = useFormat();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.ar.prepaidRefund };
      } else {
        return { ...lang.en.base, ...lang.en.ar.prepaidRefund };
      }
    });

    const { getFormPrints, searchTextPrint, isSharedFormPrintReady, getPreviewBlob, filteredFormPrints } =
      useGetFormPrint();

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      textAlertClass,
      getFormPrints,
      searchTextPrint,
      isSharedFormPrintReady,
      getPreviewBlob,
      filteredFormPrints
    };
  },
  data() {
    return {
      isLoading: false,
      selectedUnit: null,
      dateAt: null,
      PrepaidDetail: [],
      editingRows: [],
      PrepaidEntryDetail: [],
      code: "",
      customerName: "",
      employeeName: "",
      ReceiveMan: "",
      Remark: "",
      isSharedUnitReady: false,
      totalAmount: null,
      unitError: false,
      remarkError: false,
      unitCode: "",
      isPrinted: false,

      //PrepaidReceipt
      showPrepaidReceipt: false,
      selectedPrepaidReceipt: null,
      messages: [{ ismessage: false, severity: "success", content: "123" }],

      showFormPrintModal: false,
      path: "",
      categoryPrint: "",
    };
  },
  async created() {
    if (this.isCreateDocument) {
      this.clearPrepaid();
    } else {
      const where = {
        id: this.id,
      };
      this.setLoading(true);
      await this.$store.dispatch("arPrepaidRefund/getPrepaid", { where });
    }
  },
  methods: {
    // formatPrice(value) {
    //     let val = (value/1).toFixed(2)
    //     return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    // },
    goBack() {
      const localSearch = localStorage.getItem(this.storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        PrepaidEntry: "Entry",
      };
      // console.log("search=",search);
      localStorage.setItem(this.storageName,JSON.stringify(search));
      this.$router.push({name: "ar-prepaid-refund"});
    },
    unitDataReady() {
      this.isSharedUnitReady = true;
    },
    setData() {
      if (!this.isCreateDocument) {
        this.selectedUnit = this.sharedUnits.find(
          (unit) => unit.id === this.PrepaidRefund.unitId
        );

        // console.log("00setData=",this.PrepaidRefund.details);
        this.code = this.PrepaidRefund.code;
        this.dateAt = new Date(this.PrepaidRefund.date);
        this.customerName = this.PrepaidRefund.customerName;
        this.employeeName = this.PrepaidRefund.employeeName;
        this.PrepaidEntryDetail = this.PrepaidRefund.details;
        this.ReceiveMan = this.PrepaidRefund.receiveMan;
        this.Remark = this.PrepaidRefund.remark1;
        this.totalAmount = this.PrepaidRefund.netAmount;
      }
      this.updateItemNo();
      // console.log("11setData=",this.PrepaidEntryDetail);
      this.setLoading(false);
    },
    async getPrepaidRefundReceipts() {
      if (this.selectedUnit === "" || this.selectedUnit == null) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoUnitSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.unitError = true;
          }
        });
      } else {
        this.isLoading = true;
        this.showPrepaidReceipt = true;
        let where = {
          projectId: this.selectedProject.id,
          unitCode: this.selectedUnit.code
        };
        // console.log('where',JSON.stringify(where));
        const payload = { 
          where,
          limit: 100 
        };
        await this.$store.dispatch(
          "arPrepaidRefund/getPrepaidRefundReceipts",
          payload
        );
        this.isLoading = false;
      }
    },
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    checkShouldSetData() {
      if (this.isSharedUnitReady && this.PrepaidRefund) {
        this.setData();
      }
    },
    selectPrepaidReceipt(data) {
      this.showPrepaidReceipt = false;
      // console.log("selectPrepaidReceipt=", data);
      // console.log("000this.PrepaidEntryDetail:", this.PrepaidEntryDetail);
      let number = this.PrepaidEntryDetail.length;
      if (data) {
        data.forEach((Receipt) => {
          // console.log("Receipt:",Receipt);
          let PrepaidDetail = null;
          PrepaidDetail = this.PrepaidEntryDetail.find(
            (Prepaid) => Prepaid.referenceId === Receipt.detailId
          );

          // console.log("PrepaidDetail:",PrepaidDetail);
          if (PrepaidDetail == null) {
            let detail = {
              itemNo: number + 1,
              headerId: Receipt.id,
              productId: Receipt.productId,
              productCode: Receipt.productCode,
              productName: Receipt.productName,
              quantity: Receipt.quantity,
              unitPrice: Receipt.unitPrice,
              balanceAmount: Receipt.balanceAmount,
              totalAmount: Receipt.balanceAmount,
              remark: Receipt.remark,
              referenceId: Receipt.detailId,
              period: Receipt.period,
              code: Receipt.code,
              amount: Receipt.totalAmount,
            };
            // console.log("detail=",detail);
            this.PrepaidEntryDetail.push(detail);
            // console.log("PrepaidEntryDetail=",this.PrepaidEntryDetail);
            //test
          } else {
            const totalAmount =
              PrepaidDetail.totalAmount + Receipt.balanceAmount;
            const balanceAmount = PrepaidDetail.balanceAmount;

            // console.log("Receipt.totalAmount=",totalAmount);
            // console.log("Receipt.balanceAmount=",balanceAmount);

            if (parseFloat(balanceAmount) > parseFloat(totalAmount)) {
              PrepaidDetail.totalAmount =
                PrepaidDetail.totalAmount + Receipt.balanceAmount;
            } else {
              PrepaidDetail.totalAmount = balanceAmount;
            }
          }

          // console.log("Detail select",this.PrepaidEntryDetail);
          number++;
        });
      }
      this.updateTotalAmount();
    },
    onRowEditSave(event) {
      let { newData, index } = event;
      //console.log("onRowEditSave_event:", event);
      //  console.log("onRowEditSave_newData:", newData);
      //  console.log("onRowEditSave_totalAmount:", newData.totalAmount);
      //  console.log("onRowEditSave_balanceAmount:", newData.balanceAmount);
      if (newData.totalAmount > newData.balanceAmount) {
        // console.log("ยอดคืนต้องน้อยกว่าหรือเท่ากับค่าคงเหลือ");
        this.$swal({
          icon: "warning",
          title: this.lang.checkRefundAmount,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      } else {
        this.PrepaidEntryDetail[index] = newData;
      }
      this.updateTotalAmount();
    },
    DeletePrepaidDetail(id) {
      // console.log("DeletePrepaidDetail:", id);
      // console.log("this.PrepaidEntryDetail:", this.PrepaidEntryDetail);
      const Prepaid = this.PrepaidEntryDetail.find(
        (Prepaid) => Prepaid.referenceId === id
      );
      // console.log("Prepaid:",Prepaid);
      let userIndex = this.PrepaidEntryDetail.indexOf(Prepaid);
      this.PrepaidEntryDetail.splice(userIndex, 1);
      this.updateTotalAmount();
      this.updateItemNo();
    },
    CreatePrepaid() {
      this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForCreateNewDocument}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.cancel,
        allowOutsideClick: false,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (!this.isCreateDocument) {
            this.$router.replace({name: "ar-prepaid-refund-edit", params: {id: "create"}});
          }
          this.clearPrepaid();
        } else if (result.isDenied) {
          //
        }
      });
    },
    async updateTotalAmount() {
      // console.log("this.PrepaidEntryDetail=",this.PrepaidEntryDetail);
      let total = 0;
      await this.PrepaidEntryDetail.forEach((element) => {
        total = total + parseFloat(element.totalAmount);
      });

      this.totalAmount = total;
    },
    updateItemNo() {
      let number = 1;
      this.PrepaidEntryDetail.forEach((element) => {
        element.itemNo = number;
        number++;
      });
    },
    clearPrepaid() {
      this.code = "";
      this.dateAt = new Date();
      this.selectedUnit = null;
      this.customerName = "";
      this.employeeName = this.selectedUserName;
      this.PrepaidEntryDetail = [];
      this.ReceiveMan = "";
      this.Remark = "";
      this.totalAmount = null;
      this.isPrinted = false;
    },
    async SavePrepaid() {
      if (this.validateData()) {
        return;
      }

      this.setLoading(true);

      try {
        if (this.isCreateDocument) {
          let Prepaid = {
            projectId: this.selectedProject.id,
            refCode: "INV_" + this.PrepaidEntryDetail[0].code,
            date: this.selectedDateAt,
            customerId: this.selectedUnit.customerId,
            employeeId: this.employee.id,
            unitId: this.selectedUnit.id,
            receiveMan: this.ReceiveMan,
            remark1: this.Remark,
            details: this.PrepaidEntryDetail,
          };
          // Prepaid.details = this.PrepaidEntryDetail;
          // console.log("AddPrepaid_Prepaid =", Prepaid);
          await this.$store.dispatch("arPrepaidRefund/AddPrepaid", Prepaid);

          const PrepaidRefund = this.PrepaidRefund;
          // console.log("AddPrepaidRefund=", PrepaidRefund);
          this.$router.replace({name: "ar-prepaid-refund-edit", params: {id: PrepaidRefund.id}});
          this.code = PrepaidRefund.code;

          this.$swal({
            icon: "success",
            title: this.lang.saveSuccess,
            confirmButtonText: this.lang.close,
            allowOutsideClick: false,
          });
        } else {
          const Prepaid = this.PrepaidRefund;
          Prepaid.date = this.selectedDateAt;
          Prepaid.ReceiveMan = this.ReceiveMan;
          Prepaid.remark1 = this.Remark;
          Prepaid.details = this.PrepaidEntryDetail;

          // console.log("EditPrepaid_Prepaid =", Prepaid);
          await this.$store.dispatch("arPrepaidRefund/EditPrepaid", Prepaid);

          this.$swal({
            icon: "success",
            title: this.lang.saveSuccess,
            confirmButtonText: this.lang.close,
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.setLoading(false);
    },
    async askForCancel() {
      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForCancelDocument}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        await this.CancelPrepaid();
      } else if (result.isDenied) {
        //
      }
    },
    async CancelPrepaid() {
      this.setLoading(true);

      const Prepaid = {
        id: this.id,
      };

      try {
        await this.$store.dispatch("arPrepaidRefund/CancelPrepaid", Prepaid);
        this.$swal({
          icon: "success",
          title: this.lang.cancelSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    async PrintPrepaid() {
      this.setLoading(true);
      
      const Prepaid = {
        id: this.id,
        projectId: this.selectedProject.id,
        unitCode: this.selectedUnit.code,
        path: this.path,
        category: this.categoryPrint,
      };
      try {
        await this.$store.dispatch("arPrepaidRefund/PrintPrepaid", Prepaid)
        // this.$swal({
        //   icon: "success",
        //   title: this.lang.downloadPDFSuccess,
        //   confirmButtonText: this.lang.close,
        //   allowOutsideClick: false,
        // });
        this.isPrinted = true;
      } catch (error) {
        // console.log("error=", error);
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        this.isPrinted = false;
        this.setLoading(false);
      }
      let blob = this.$store.getters["arPrepaidRefund/blob"];
      // console.log('blob',blob);
      this.getPreviewBlob(blob)

      this.setLoading(false);
    },
    validateData() {
      if (!this.selectedUnit) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoUnitSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.unitError = true;
          }
        });
        return true;
      } else if (this.PrepaidEntryDetail.length <= 0) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoDetail,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      } else if (this.Remark.length <= 0) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoRemark,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.remarkError = true;
          }
        });
        return true;
      }
      return false;
    },
    async selectPrintForm(){
      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
          projectId: this.selectedProject.id,
          documentBookCode: "RETURN",
        };
      const payload = { where };
      await this.getFormPrints(payload);
      
      if(this.filteredFormPrints.length == 1) {
        this.path = this.filteredFormPrints[0].path;
        this.categoryPrint = this.filteredFormPrints[0].category;
        this.PrintPrepaid();
      }else{
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path;
      this.categoryPrint = event.category
      this.PrintPrepaid();
      this.showFormPrintModal = false;
    },
    selectUnit() {
      this.unitError = false;
      this.customerName = this.selectedUnit.customerName;
      this.unitCode = this.selectedUnit.code;
    },
  },
  computed: {
    isCreateDocument() {
      return this.id === "create";
    },
    title() {
      let result = this.lang.editPrepaid;
      if (this.isCreateDocument) {
        result = this.lang.createPrepaid;
      }
      return result;
    },
    PrepaidRefund() {
      return this.$store.getters["arPrepaidRefund/selectedPrepaid"];
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    selectedUserName() {
      return this.$store.getters.userName;
    },
    comboBoxUnits() {
      return this.$store.getters["comboBox/unitUnits"];
    },
    sharedUnits() {
      return this.$store.getters["shared/unitUnits"];
    },
    isDocumentLocked() {
      if (this.PrepaidRefund && !this.isCreateDocument) {
        if (
          this.PrepaidRefund.postStep.trim() != "" ||
          this.PrepaidRefund.cancelStatus.trim() != ""
        ) {
          return true;
        }
      }
      return false;
    },
    isCanceled() {
      if (this.PrepaidRefund) {
        // console.log("this.PrepaidRefund",this.PrepaidRefund);
        if (this.PrepaidRefund.cancelStatus.trim().toLowerCase() === "c") {
          return true;
        }
      }
      return false;
    },
    employee() {
      return this.$store.getters.employee;
    },
    selectedDateAt() {
      let dateAt = null;
      if (this.dateAt) {
        dateAt = this.formatDateToISOString(this.dateAt);
      } else {
        dateAt = this.formatDateToISOString(new Date());
      }
      return dateAt;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    storageName() {
      return `erp_search_PrepaidRefundList_${this.selectedProject.code}_${this.userCode}`;
    },
  },
  watch: {
    isSharedUnitReady() {
      this.checkShouldSetData();
    },
    PrepaidRefund() {
      this.checkShouldSetData();
    },
    // selectedUnit(curValue, oldValue) {
    //   if (oldValue === null && curValue) {
    //     this.unitError = false;
    //     this.customerName = curValue.customerName;

    //     this.unitCode = curValue.code;
    //     // this.PrepaidEntryDetail = [];
    //     // this.totalAmount = 0;
    //   }
    // },
    unitCode(curValue, oldValue) {
      // console.log("UnitCode_curValue=", curValue);
      // console.log("UnitCode_oldValue=", oldValue);
      if (oldValue != "" && curValue != oldValue) {
        this.PrepaidEntryDetail = [];
        this.totalAmount = 0;
        this.code = "";
        this.ReceiveMan = "";
        this.Remark = "";
        this.isPrinted = false;
      }
    },
    Remark(curValue) {
      if (curValue) {
        this.remarkError = false;
      }
    },
  },
};
</script>

<style scoped>
::v-deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

.p-button-text {
  width: 2rem;
}
</style>
