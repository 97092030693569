export default {
      async getReceipts(context, payload) {

        let url = process.env.VUE_APP_BACKEND_URL + "/Voucher/Receipt";

        if(!payload.limit){
            url = url + '?limit=100';
        } else {
            url = url + '?limit='+payload.limit;
        }

        if(payload.page){
            url = url + '&page='+payload.page;
        }

        if(payload.where){
            url = url + '&where=' + JSON.stringify(payload.where);
        }
        // console.log("setReceipts_payload=",payload);

        const token = context.rootGetters.token;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
          },
        });
    
        const responseData = await response.json();
    
        if (!response.ok) {
          // console.log(responseData);
          let message = null
          if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
            message = responseData.title;
            for (const key in responseData.errors) {
              message += ", " + key.replace("$.","")
            }
          } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
            message = responseData.message
          }
          const error = new Error(message || "Failed to get project");
          throw error;
        }
        const receipt = {
          receipts: responseData.data,
          totalPage: responseData.page.pageCount, 
          currentPage: responseData.page.currentPage, 
        };
    
        //  console.log("setReceipts=",receipt);
        context.commit('setReceipt', receipt);
      },
      
    async getAllReceipts(context, payload) {
      // console.log("getAllReceiptSelected=",context.getters.receiptIsLastPage);
        while (!context.getters.receiptIsLastPage) {
          let where = payload.where
          const payloadR = {
            page: context.getters.receiptNextPage || 1,
            where,
          };
          await context.dispatch("getReceipts", payloadR);
        }
      
    },

    async resetReceipt(context, payload) {
      // console.log("setReceiptSelected=",payload);
      context.commit('resetReceipt', payload);
    },
    
    async setReceiptSelected(context, payload) {
      // console.log("setReceiptSelected=",payload);
      context.commit('setReceiptSelected', payload);
    },

    async getAllReceiptSelected(context, payload) {
      // console.log("getAllReceiptSelected=",context.getters.receiptIsLastPage);
      while (!context.getters.receiptIsLastPage) {
        let where = payload.where
        const payloadR = {
          page: context.getters.receiptNextPage || 1,
          where,
        };
        await context.dispatch("getReceiptSelected", payloadR);
      }    
    },

    async resetReceiptSelected(context, payload) {
      // console.log("setReceiptSelected=",payload);
      context.commit('resetReceiptSelected', payload);
    },


    async getReceiptSelected(context, payload) {

      let url = process.env.VUE_APP_BACKEND_URL + "/Voucher/Receipt";

      if(!payload.limit){
          url = url + '?limit=100';
      } else {
          url = url + '?limit='+payload.limit;
      }

      if(payload.page){
          url = url + '&page='+payload.page;
      }

      if(payload.where){
          url = url + '&where=' + JSON.stringify(payload.where);
      }
      
      // console.log("getListReceiptToGl_payload=",payload);
      const token = context.rootGetters.token;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token,
        },
      });
      const responseData = await response.json();
  
      if (!response.ok) {
        // console.log(responseData);
        let message = null
        if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
          message = responseData.title;
          for (const key in responseData.errors) {
            message += ", " + key.replace("$.","")
          }
        } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
          message = responseData.message
        }
        const error = new Error(message || "Failed to get receipt");
        throw error;
      }

      const receipt = {
        receipts: responseData.data,
        totalPage: responseData.page.pageCount, 
        currentPage: responseData.page.currentPage, 
      };  

      //  console.log("getReceiptSelected=",responseData);    
      context.commit('getReceiptSelected', receipt);
    },      

    async getReceiptToGl(context, payload) {        
      let url = process.env.VUE_APP_BACKEND_URL + "/Voucher/ReceiptToGl";
    
      const token = context.rootGetters.token;      
      // console.log("getReceiptToGl_payload=",payload);

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(payload),
      });
  
      const responseData = await response.json();       
      // console.log("00getReceiptToGl>>>",responseData);
      if (!response.ok) {
        // console.log(responseData);
        let message = null
        if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
          message = responseData.title;
          for (const key in responseData.errors) {
            message += ", " + key.replace("$.","")
          }
        } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
          message = responseData.message
        }
        const error = new Error(message || "Failed to get project");
        throw error;
      }

      // console.log("getReceiptToGl>>>",responseData);            
      await context.commit('setReceiptToGl', responseData);       
          
    },
    async clearReceiptToGl(context, payload) {
      // console.log("setReceiptSelected=",payload);
      context.commit('clearReceiptToGl', payload);
    },

};