<template>
  <PvDialog
    :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
    :style="{ width: '40vw' }"
    :modal="true"
    :closable="false"
  >
    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchTextPrint"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>

    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="lang.notificationDialogHeader"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
    ></base-dialog>

    <template #header>
      <div
        class="flex justify-content-between align-items-center flex flex-wrap pt-3 pb-3"
      >
        <span class="font-lg">{{ lang.withholdingTaxPrintLot }}</span>
      </div>
      <PvDivider class="mt-3 mb-3" />
    </template>

    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="taxBook">{{ lang.columnBook }}</label>
          <PvSkeleton
            v-if="!isSharedTaxBookReady"
            class="inputfield w-full mt-1"
            height="39px"
          />
          <base-tax-book-auto-complete
            v-show="isSharedTaxBookReady"
            id="taxBook"
            v-model="selectedTaxBook"
            class="inputfield w-full mt-1"
            @load-finish="taxBookDataReady"
            :class="{ 'p-invalid': taxBookError }"
            @item-select="taxBookError = false"
            @blur="taxBookError = false"
          />
          <small v-if="taxBookError" id="tax-book-help" class="p-error">{{
            lang.validateNoTaxBookSelect
          }}</small>
        </div>
        <div class="col-6"></div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateStart">{{ lang.dateWhtStart }}</label>
          <PvCalendar
            id="dateStart"
            v-model="dateStart"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateStartError }"
            @date-select="dateStartSelected()"
            @blur="dateStartSelected()"
          />
          <small v-if="dateStartError" id="unit-help" class="p-error">{{
            lang.validateDateStart
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateEnd">{{ lang.dateWhtEnd }}</label>
          <PvCalendar
            id="dateEnd"
            v-model="dateEnd"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateEndError }"
            @date-select="dateEndError.value = false"
            :minDate="minDateValue"
            :maxDate="maxDateValue"
          />
          <small v-if="dateEndError" id="unit-help" class="p-error">{{
            lang.validateDateEnd
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="codeStart">{{ lang.codeStart }}</label>
          <PvInputText
            id="codeStart"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="6701xxxxx"
            v-model="codeStart"
            :class="{ 'p-invalid': codeStartError }"
            @keypress="codeStartError = false"
          />
          <small v-if="codeStartError" id="codeStart-help" class="p-error">{{
            lang.validateCodeStart
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="codeEnd">{{ lang.codeEnd }}</label>
          <PvInputText
            id="codeEnd"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="6701xxxxx"
            v-model="codeEnd"
            :class="{ 'p-invalid': codeEndError }"
            @keypress="codeEndError = false"
          />
          <small v-if="codeEndError" id="codeEnd-help" class="p-error">{{
            lang.validateCodeEnd
          }}</small>
        </div> 
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText
            id="fileNameText"
            type="text"
            class="inputfield w-full mt-1"
            v-model="fileNameText"
          />
        </div>
      </section>
    </transition>

    <template #footer>
      <div
        class="flex justify-content-between align-items-center m-3 mt-0 pt-3"
      >
        <PvButton
          :label="lang.close"
          class="p-button-outlined p-button-secondary w-full"
          @click="closeDialog"
        />
        <PvButton 
            :label="lang.confirm" 
            class="w-full" 
            @click="getPrintForm"
        />
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import { useStore } from "vuex";
import { computed, ref, onMounted } from "vue";
import useGetFormPrint from "../../hooks/getFormPrint.js";
import useFormat from "../../hooks/format.js";
import useReportRequestValidate from "../../hooks/reportRequestValidate.js";
export default {
  emits: ["close", "toast-request"],
  setup(props, { emit }) {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const store = useStore();
    const { getFormPrints, searchTextPrint, getPreviewBlob, filteredFormPrints  } = useGetFormPrint();
    const { formatDateToISOString } = useFormat();
    const { withholdingTaxPrintOrder } = useReportRequestValidate();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ap.withholdingTax.listing,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ap.withholdingTax.listing,
          ...lang.en.gl.reportGl,
        };
      }
    });

    let selectedTaxBook = ref(null);
    let taxBookError = ref(false);
    let isSharedTaxBookReady = ref(false);
    let dateStart = ref(null);
    let dateStartError = ref(false);
    let dateEnd = ref(null);
    let dateEndError = ref(false);
    let codeStart = ref("");
    let codeStartError = ref(false);
    let codeEnd = ref("");
    let codeEndError = ref(false);
    let fileNameText = ref("");
    let path = ref("");
    let showFormPrintModal = ref(false);
    let showValidateDialog = ref(false);
    let validateWording = ref("");
    let dataButtonDialog = ref([
      {
        id: "close",
        caption: mergeLang.value.close,
        class: "p-button-outlined p-button-secondary w-full",
        action: "confirm",
      },
    ])

    onMounted(() => {
      initiateData();
    });

    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const userId = computed(() => {
      return store.getters.userId;
    });
    const userName = computed(() => {
      return store.getters.userName;
    });
    const selectedDateStart = computed(() => {
      let date = null;
      if (dateStart.value) {
        date = formatDateToISOString(dateStart.value);
      }
      return date;
    });
    const selectedDateEnd = computed(() => {
      let date = null;
      if (dateEnd.value) {
        date = formatDateToISOString(dateEnd.value);
      }
      return date;
    });
    
    const minDateValue = computed(() => {
      let date = new Date();
      if (dateStart.value) {
        return new Date(dateStart.value.getFullYear(), dateStart.value.getMonth());
      } else {
        return new Date(date.getFullYear(), date.getMonth(), 1);
      }
    });

    const maxDateValue = computed(() => {
      let date = new Date();
      if (dateStart.value) {
        return new Date(dateStart.value.getFullYear(), dateStart.value.getMonth() + 2, 0);
      } else {
        return new Date(date.getFullYear(), date.getMonth() + 2, 0);
      }
    });

    const payload = computed(() => {
      let payload = {
        Topic: "report_withholding_tax_print_order",
        ProjectId: selectedProject.value.id,
        DocumentBookId: selectedTaxBook.value.id,
        DateStart: selectedDateStart.value,
        DateEnd: selectedDateEnd.value,
        CodeStart: codeStart.value,
        CodeEnd: codeEnd.value,
        FileName: fileNameText.value + ".pdf",
        JasperPath: path.value,
        DocType: "PDF",
        UserId: userId.value,
        PrintBy: userName.value,
        RequestId: 0,
        Event: "create",
        // Path: path.value,
      };
      return payload;
    });

    const closeDialog = () => {
      emit("close");
    };

    const taxBookDataReady = () => {
      isSharedTaxBookReady.value = true;
    };

    const initiateData = () => {
      let date = new Date();
      selectedTaxBook.value = null;
      taxBookError.value = false;
      isSharedTaxBookReady.value = false;
      dateStart.value = new Date(date.getFullYear(), date.getMonth(), 1);
      dateStartError.value = false;
      dateEnd.value = date;
      dateEndError.value = false;
      codeStart.value = "";
      codeStartError.value = false;
      codeEnd.value = "";
      codeEndError.value = false;
      fileNameText.value = "WithholdingTaxPrintLot"
      path.value = "";
      showFormPrintModal.value = false;
      showValidateDialog.value = false;
      validateWording.value = "";
    };

    const validateData = () => {
      let returnStatus = false;
      if(!selectedTaxBook.value) {
        taxBookError.value = true;
        returnStatus = true;
      }
      if(!dateStart.value) {
        dateStartError.value = true;
        returnStatus = true;
      }
      if(!dateEnd.value) {
        dateEndError.value = true;
        returnStatus = true;
      }
      // if(!codeStart.value) {
      //   codeStartError.value = true;
      //   returnStatus = true;
      // }
      // if(!codeEnd.value) {
      //   codeEndError.value = true;
      //   returnStatus = true;
      // }
      return returnStatus;
    };

    const dateStartSelected = () => {
      dateStartError.value = false
      if (dateEnd.value < minDateValue.value) {
        dateEnd.value = minDateValue.value
      }
      if (dateEnd.value > maxDateValue.value) {
        dateEnd.value = maxDateValue.value
      }
    };

    return {
      lang: mergeLang,
      textFormClass,
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,

      //data
      selectedTaxBook,
      taxBookError,
      isSharedTaxBookReady,
      dateStart,
      dateStartError,
      dateEnd,
      dateEndError,
      codeStart,
      codeStartError,
      codeEnd,
      codeEndError,
      fileNameText,
      path,
      showFormPrintModal,
      showValidateDialog,
      validateWording,
      dataButtonDialog,

      //methods
      closeDialog,
      taxBookDataReady,
      validateData,
      dateStartSelected,

      // computed
      selectedProject,
      payload,
      selectedDateStart,
      selectedDateEnd,
      minDateValue,
      maxDateValue,
      withholdingTaxPrintOrder,
    };
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    async getPrintForm() {
      if (this.validateData()) {
        return;
      }

      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: this.selectedTaxBook.code.trim(),
      };
      const payload = { where };
      await this.getFormPrints(payload);
      
      if (this.filteredFormPrints.length == 1) {
        this.path = this.filteredFormPrints[0].path + ".pdf";
        await this.printLot();
      } else {
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path + ".pdf";
      this.printLot();
      this.showFormPrintModal = false;
      this.closeDialog();
    },
    async printLot() {
      this.setLoading(true);
      try {
        const response = await this.withholdingTaxPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
        // await this.$store.dispatch("produces/reportWithholdingTaxPrintOrder", this.payload);
        // this.$emit("toast-request");
        // let payload = {
        //   path: this.path,
        //   fileName: "WithholdingTax_Lot",
        //   parameter: JSON.stringify(this.payload),
        // };
        // await this.$store.dispatch("shared/printForm", payload);
			  // let blob = this.$store.getters["shared/blob"];
        // this.getPreviewBlob(blob);
        this.setLoading(false);
      } catch (error) {
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
  }
};
</script>
