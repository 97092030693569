export default({
	referenceDocument: "Reference Document",
    documentBookCode: "DocumentBookCode",
    documentBookName: "DocumentBookName",
    code: "Code",
    date: "Date",
    cancelStatus: "CancelStatus",
    postStatus: "PostStatus",
    amount: "Amount",
    createBy: "CreateBy",
    createDate: 'CreateDate',
    updateBy: "UpdateBy",
    updateDate: "UpdateDate",
    tableEmpty: "Data not found.",
    columnIsPaid: "Paid",
})