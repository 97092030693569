export default {
  receiptCodeStart: "Receipt Code Start",
  receiptPrintListPage: "Receipt Print ",
  receiptPrintEditPage: "Receipt Print",
  lot: "Lot",
	validateNoCode: "Please enter code.",
	validateDateSelect: "Please select date.",
  receiptDateStart: "Receipt date start",
	to: "To",
	fileName: "File Name",
};
