export default {
    toolbarTitle: "Debt Free Approve",
    title: "Debt Free Approve",
    approve: "Approve",
    dateRange: "Doucument Date",
    unitAddress: "Unit/Address no.",
    ownerName: "Owner Name",
    searchTextPlaceholder: "Fill up to search.",
    unitCode: "Unit Code",
    unitAddressNo: "Address No.",
    requestDateList: "Request Date",
    docBy: "documentationBy",
    pendingCalculateDate: "Pending Calculate Date",
    tableEmptyList: "Data not found.",
}