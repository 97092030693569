<!-- <template>
  <PvBreadcrumb
    class="p-button-label"
    :home="home"
    :model="items"
    v-if="isNavigation"
  >
		<template #item="{ item }">
			<router-link :to="item.url" custom v-slot="{ href, navigate }">
				<a :href="href" @click="navigate" class="p-menuitem-link">
					<span class="p-menuitem-text">{{ item.label }}</span>
				</a>
			</router-link>
		</template>
  </PvBreadcrumb>
</template> -->
<template>
  <base-dialog
    v-model:visible="showConfirmDialog"
    :titleConfirm="titleDialog"
    :wordingConfirm="wordingDialog"
		:dataButtons="dataButtonDialog"
    @confirm="confirmChange"
    @close="cancelChange"
    @changeProject="changeProject"
  ></base-dialog>
  <base-project-modal
    v-model:visible="showProjectModal"
    @close="showProjectModal = false"
    @confirm="showProjectModal = false"
  >
  </base-project-modal>
  <div
    class="flex flex-wrap p-0 p-navigation-border sticky top-0 bg-white z-5"
    style="min-height: 56px"
    v-if="isNavigation"
  >
    <div class="p-0" style="width: 50%">
      <PvBreadcrumb
        class="p-button-label"
        :home="home"
        :model="items"
        style="border: 0px"
      >
        <template #item="{ item }">
          <router-link :to="`/${project.code}${item.url}`" custom v-slot="{ href, navigate }">
            <a
              :href="href"
              @click="navigate"
              class="p-menuitem-link"
              v-if="item.url != ''"
            >
              <span class="p-menuitem-text">{{ item.label }}</span>
            </a>
            <span class="p-menuitem-text font-normal" v-else>{{ item.label }}</span>
          </router-link>
        </template>
      </PvBreadcrumb>
    </div>
    <div style="width: 50%" class="text-right pr-3">
      <PvButton
        class="p-button-secondary p-button-outlined max-w-full"
        style="padding: 8px 16px 8px 16px"
        @click="checkPageDirty()"
      >
        <span
          class="font-bold font-sm white-space-nowrap overflow-hidden text-overflow-ellipsis max-w-full text-900"
        >
          {{ $store.getters.selectedLanguage === "TH" ? project.nameTh : project.nameEn }}
        </span>
        <icon-arrange-filter-sort
          width="24"
          height="24"
          iconColor="secondary"
          class="ml-3"
        ></icon-arrange-filter-sort>
      </PvButton>
    </div>
  </div>
</template>

<script>
import useGetProject from "../../hooks/getProject.js";
import useLang from "../../hooks/lang.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetRoleByBook from "../../hooks/getRoleByBook.js";
import useGetRoleByMenu from "../../hooks/getRoleByMenu.js";
export default {
  setup() {
    const store = useStore();
    const { lang } = useLang();
    const navigations = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return lang.th.navigation;
      } else {
        return lang.en.navigation;
      }
    });

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
        };
      } else {
        return {
          ...lang.en.base,
        };
      }
    });

    const {
      isSharedProjectReady,
      getProjectById,
      searchText,
      filteredProjects,
    } = useGetProject();

    const { getAllRoleByBooks } = useGetRoleByBook();
    const { getAllRoleByMenus } = useGetRoleByMenu();

    return {
      navigations,

      isSharedProjectReady,
      getProjectById,
      searchText,
      filteredProjects,

      lang: mergeLang,

      getAllRoleByBooks,
      getAllRoleByMenus,
    };
  },
  data() {
    return {
      home: {},
      items: [],
      isNavigation: false,

      selectedProject: null,
      isProjectLoading: true,
      objToCompare: null,
      showConfirmDialog: false,
      titleDialog: this.lang.confirmChangeProjectMessage,
      wordingDialog: this.lang.changeProjectMessage,
      showProjectModal: false,
      dataButtonDialog: [],
    };
  },
  methods: {
    setNavigation(data) {
      this.home = {};
      this.items = [];
      this.isNavigation = false;
      const selectedLang = this.$store.getters.selectedLanguage;

      const navigation = this.navigations.find((nav) => nav.name === data);

      if (navigation) {
        let setNavigations = [];
        let setNavigation = [];
        this.isNavigation = true;

        navigation.items.forEach((element) => {
          setNavigation = { ...element };

          for (const [id, value] of Object.entries(this.$route.params)) {
            if (id) {
              setNavigation.url = setNavigation.url.replace(`:${id}`, `${value}`);
            }
            if (setNavigation.type === "edit") {
              // setNavigation.url = this.$route.path;
              // console.log(id, value, element);

              if (selectedLang === "TH") {
                if (value === "create") {
                  setNavigation.label = element.label.replace("{text}", "สร้าง");
                } else {
                  setNavigation.label = element.label.replace("{text}", "แก้ไข");
                }
              } else {
                if (value === "create") {
                  setNavigation.label = element.label.replace("{text}", "Add");
                } else {
                  setNavigation.label = element.label.replace("{text}", "Edit");
                }
              }
            }
          }
          setNavigations.push(setNavigation);
        });

        if (setNavigations.length == 1) {
          this.home = setNavigations[0];
        } else if (setNavigations.length > 1) {
          this.home = setNavigations[0];
          this.items = setNavigations.filter((_, index) => index != 0);
          //.splice(1, navigation.items.length);
        }
      } else {
        this.isNavigation = false;
      }
    },
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    checkShouldSetData() {
      if (this.isSharedProjectReady) {
        // this.setLoading(true);
        this.setData();
      }
    },
    setData() {
      this.selectedProject = this.filteredProjects.find(
        (data) => data.id === this.project.id
      );
      this.objToCompare = this.selectedProject;
      // this.setLoading(false);
    },
    async checkPageDirty(){
      this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.cancel,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "changeProject",
            caption: this.lang.confirm,
            class: "w-full",
            action: "changeProject",
          },
        ];
      this.titleDialog = this.lang.notificationDialogHeader;
      this.wordingDialog = this.lang.pageDirtyConfirmMessage;   

      // console.log("changeProject_isPageDirty=",this.isPageDirty);
      if(!this.isPageDirty){
        this.changeProject();
      }else{   
        this.showConfirmDialog = true;
      }
    },
    async changeProject() {    
        this.showConfirmDialog = false;
        this.showProjectModal = true;
    },
    async confirmChange() {      
      this.dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.cancel,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
        {
          id: "confirm",
          caption: this.lang.confirm,
          class: "w-full",
          action: "confirm",
        },
      ];        
      this.titleDialog = this.lang.confirmChangeProjectMessage;
      this.wordingDialog = this.lang.changeProjectMessage;

      this.setLoading(true);
      if (this.selectedProject !== this.objToCompare && this.showConfirmDialog) {
        this.$store.dispatch("setProject", this.selectedProject);

        try {
          await this.$store.dispatch("getEmployeeByCodeAndProject", {
            projectId: this.selectedProject.id,
            employeeCode: this.userCode,
          });
          await this.getAllRoleByBooks();
          await this.getAllRoleByMenus();
        } catch (error) {
          this.$swal({
            icon: "error",
            html: `<p class="${this.textAlertClass}">${error}</p>`,
            confirmButtonText: this.lang.close,
            allowOutsideClick: false,
          });
        }
        this.setLoading(false);
        this.$router.replace({ name: "home" });
        this.objToCompare = this.selectedProject;
      }
      this.showConfirmDialog = false;
    },
    cancelChange() {
      this.selectedProject = this.objToCompare;
      this.showConfirmDialog = false;
    },
    checkRouteReportType(type) {
      let name = "";
      if (type == "ar") {
        name = "-ar";
      } else if (type == "ap") {
        name = "-ap";
      } else if (type == "gl") {
        name = "-gl";
      } else if (type == "pc") {
        name = "-pc";
      } else if (type == "asset") {
        name = "-asset";
      } else if (type == "withholdingtax") {
        name = "-withholding-tax";
      } else if (type == "meterusage") {
        name = "-meter";
      } else if (type == "notice") {
        name = "-notice";
      } else if (type == "receipt") {
        name = "-receipt";
      } else if (type == "outstandingletter") {
        name = "-outstanding-letter";
      } else if (type == "debtfree") {
        name = "-debt-free";
      } else if (type == "stockkeycard") {
        name = "-stock-keycard";
      } else if (type == "budget") {
        name = "-budget";
      } else if (type == "payment-request") {
        name = "-payment-request";
      }
      return name;
    },
  },
  computed: {
    route() {
      return this.$route;
    },
    routeName() {
      let route = this.route;
      let name = this.route.name ? this.route.name : "";
      if (name == "report" && route.params && route.params.type) {
        const type = route.params.type.toLowerCase();
        name += this.checkRouteReportType(type);
      }
      return name;
    },
    project() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },    
    isPageDirty() {
      return this.$store.getters["isPageDirty"];
    }, 
  },
  watch: {
    route() {
      this.setNavigation(this.routeName);
    },
    isSharedProjectReady() {
      this.checkShouldSetData();
      this.isProjectLoading = false;
    },
    navigations() {
      this.setNavigation(this.routeName);
    },
  },
};
</script>
