<template>
  <PvCard class="p-0 personal-ratio-card">
    <template #title>
      <slot name="titleLeft"></slot>
      <span class="font-lg p-button-label">{{ title }}</span>
      <slot name="titleRight"></slot>
    </template>
    <template #content>
      <span class="font-3xl font-bold select-card-text-primary">
        {{ content }}
      </span>
      <div class="my-2">
        <span class="font-sm font-normal">
          {{ subContent1 }}
        </span>
      </div>
      <div class="my-2">
        <span class="font-sm font-normal"> 
            {{ subContent2 }}
        </span>
      </div>
    </template>
  </PvCard>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    subContent1: {
      type: String,
      default: "",
    },
    subContent2: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.p-card :deep(.p-card-content) {
  padding: 0;
}
.p-card :deep(.p-card-body) {
  padding: 10px 24px 10px 24px;
}
</style>


<!-- 

  <div class="flex flex-wrap">
    <div class="lg:col-6 md:col-6 col-12 lg:w-2 md:w-3 w-4 mx-3">
      <personal-ratio-card
        title="ค่าส่วนกลาง"
        content="70%"
        subContent1="700 Unit"
        subContent2="70,000 ฿"
      >
        <template #titleRight
          ><icon-path
            iconColor="success"
            width="24"
            height="24"
            style="margin-left: 10px; margin-bottom: -7px"
        /></template>
      </personal-ratio-card>
    </div>

    <div class="lg:col-6 md:col-6 col-12 lg:w-2 md:w-3 w-4 mx-3">
      <personal-ratio-card
        title="ค่าน้ำ"
        content="100%"
        subContent1="700 Unit"
        subContent2="&nbsp"
      >
        <template #titleRight
          ><icon-path
            iconColor="success"
            width="24"
            height="24"
            style="margin-left: 10px; margin-bottom: -7px"
        /></template>
      </personal-ratio-card>
    </div>
  </div>

 -->