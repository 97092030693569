<template>
  <base-dialog
    v-model:visible="showValidateDialog"
    :titleConfirm="lang.notificationDialogHeader"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    @confirm="showValidateDialog = false"
    @close="showValidateDialog = false"
  ></base-dialog>

  <base-container class="mt-5 mb-5" :isBackground="false">
    <splash-dialog 
        :splashData="splashData"
        v-model:visible="showSplashDialog" 
        @close="showSplashDialog = false"
    ></splash-dialog>

    <div class="pb-3">
        <advertisement-carousel></advertisement-carousel>
    </div>
<!-- ภาพรวมในส่วนงานต่างๆ -->
    <label class="font-2xl font-bold">{{ lang.title }}</label>
    <div class="flex flex-wrap justify-content align-items-center pt-3 pb-3">
        <!-- หนี้คงค้าง -->
        <div class="pt-3 pr-3 mx-3">
            <base-select-card
                :title="lang.outstanding"
                :color="'primary'"
                :isClick="isOutstanding"
                @click="clickType('outstanding')"
                style="width: 210px;"
            >
                <template #titleLeft>
                    <icon-payment
                        :iconColor="iconColorIsClickOutstanding"
                        width="24"
                        height="24"
                        style="margin-right: 12px; margin-bottom: -7px"
                    />
                </template>
            </base-select-card>
        </div>
        <!-- พัสดุ -->
        <div class="pt-3 pr-3 mx-3">
            <base-select-card
                :title="lang.parcel"
                :color="'primary'"
                :isClick="isParcel"
                @click="clickType('parcel')"
                style="width: 210px;"
            >
                <template #titleLeft>
                    <icon-parcel
                        :iconColor="iconColorIsClickParcel"
                        width="24"
                        height="24"
                        style="margin-right: 12px; margin-bottom: -7px"
                    />
                </template>
            </base-select-card>
        </div>
        <!-- สถานะพักอาศัย -->
        <div class="pt-3 pr-3 mx-3">
            <base-select-card
                :title="lang.resident"
                :color="'primary'"
                :isClick="isResident"
                @click="clickType('resident')"
                style="width: 210px;"
            >
                <template #titleLeft>
                    <icon-resident
                        :iconColor="iconColorIsClickResident"
                        width="24"
                        height="24"
                        style="margin-right: 12px; margin-bottom: -7px"
                    />
                </template>
            </base-select-card>
        </div>
    </div>
<!-- ภาพรวมในส่วนงานต่างๆ -->

<!-- ภาพรวมในส่วนงาน หนี้คงค้าง -->
    <div v-if="isOutstanding" class="content-border pt-2">
        <div class="flex flex-wrap justify-content-between align-items-center pt-3">
            <label class="font-2xl font-bold">{{ titleDetail }}</label>
            <div class="pl-0" style="width: 210px;">
                <PvSkeleton
                    v-show="!filteredOutstandingPeriod"
                    class="inputfield w-full mt-1"
                    height="39px"
                />
                <PvAutoComplete
                    v-show="filteredOutstandingPeriod"
                    :dropdown="true"
                    :suggestions="filteredOutstandingPeriod"
                    field="name"
                    class="inputfield w-full mt-2"
                    forceSelection
                    @complete="searchPeriod($event)"
                    v-model="selectedOutstandingPeriod"
                    @blur="setDefaultOutstandingPeriod"
                    @item-select="changeOutstandingPeriod"
                />
            </div>
        </div>
        <div class="flex flex-wrap align-items-center pt-3 pb-3">
            <label class="col-12 font-lg font-bold pl-0 pb-2">{{ lang.titleOutstandingType }}</label>
            <!-- ค่าส่วนกลาง -->
            <div class="pt-3 pr-3 mx-3">
                <base-select-card
                    :title="lang.commonArea"
                    :color="'primary'"
                    :isClick="isCommonArea"
                    @click="clickFilterOutstanding('commonArea')"
                    style="width: 210px;"
                >
                <template #contentRight>
                    <span class="p-text-secondary font-bold font-3xl" :class="{'p-text-primary font-bold font-3xl' : isCommonArea}">{{ outstandingCommonAreaPercent + "%" }}</span>
                    <icon-path
                        v-if="outstandingCommonAreaIsHigh"
                        iconColor="success"
                        width="24"
                        height="24"
                        style="margin-left: 6px; margin-bottom: -5px"
                    />
                    <icon-warning
                        v-if="outstandingCommonAreaIsMedium"
                        iconColor="warning"
                        width="24"
                        height="24"
                        style="margin-left: 6px; margin-bottom: -4px"
                    />
                    <icon-error
                        v-if="outstandingCommonAreaIsLow"
                        iconColor="error"
                        width="24"
                        height="24"
                        style="margin-left: 6px; margin-bottom: -4px"
                    />
                </template>
                </base-select-card>
            </div>
            <!-- ค่าน้ำ -->
            <div class="pt-3 pr-3 mx-3">
                <base-select-card
                    :title="lang.waterBilling"
                    :color="'primary'"
                    :isClick="isWaterBilling"
                    @click="clickFilterOutstanding('waterBilling')"
                    style="width: 210px;"
                >
                <template #contentRight>
                    <span class="p-text-secondary font-bold font-3xl" :class="{'p-text-primary font-bold font-3xl' : isWaterBilling}">{{ outstandingWaterBillingPercent + "%" }}</span>
                    <icon-path
                        v-if="outstandingWaterBillingIsHigh"
                        iconColor="success"
                        width="24"
                        height="24"
                        style="margin-left: 6px; margin-bottom: -5px"
                    />
                    <icon-warning
                        v-if="outstandingWaterBillingIsMedium"
                        iconColor="warning"
                        width="24"
                        height="24"
                        style="margin-left: 6px; margin-bottom: -4px"
                    />
                    <icon-error
                        v-if="outstandingWaterBillingIsLow"
                        iconColor="error"
                        width="24"
                        height="24"
                        style="margin-left: 6px; margin-bottom: -4px"
                    />
                </template>
                </base-select-card>
            </div>
        </div>
    <!-- รายละเอียดสถานะหนี้คงค้าง -->
        <div class="content-border mr-2">
            <label class="col-12 font-lg font-bold pl-0">{{ titleOutstandingDetail }}</label>
            <div class="flex flex-wrap justify-content-center pt-3">
                <div class="flex align-items-center justify-content-center col-3 pr-3">
                    <div class="w-full">
                        <PvChart
                            :type="typeChartOutstandingStatus"
                            :data="chartDataOutstandingStatus"
                            :options="chartOptionsOutstandingStatus"
                            :plugins="outstandingStatusPlugins"
                        />
                    </div>
                </div>
                <div class="flex align-items-center justify-content-center col-8 pl-3">
                    <div class="w-full">
                        <base-list-view 
                            :dataTable="dataTableOutstandingStatus" 
                            :columns="outstandingColumnStatus"
                        ></base-list-view>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-wrap justify-content-center">
    <!-- สัดส่วนคงค้างชำระตามสัญชาติ -->
            <div class="lg:col-6 md:col-12 col-12 pl-0 pt-4 pr-2">
                <div class="content-border h-full">
                    <div>
                        <div>
                            <label class="font-lg font-bold pl-0">{{ titleOutstandingNationality }}</label>
                        </div>
                        <div class="pt-4">
                            <label class="font-sm font-normal pl-0">{{ titleOutstandingPaidAmount }}</label>
                        </div>
                    </div>
                    <!-- <div class="flex flex-wrap pt-3">
                        <div class="lg:col-6 md:col-6 col-12 pl-0 w-5 mx-3">
                            <personal-ratio-card
                                :title="lang.thaiPerson"
                                :content="outstandingNationality.overdueThaiPercent"
                                :subContent1="outstandingNationality.overdueThaiUnit"
                                :subContent2="outstandingNationality.overdueThaiAmount"
                            >
                                <template #titleRight>
                                    <icon-thai-flag
                                        style="margin-left: 10px; margin-bottom: -7px"
                                    />
                                </template>
                            </personal-ratio-card>
                        </div>
                        <div class="lg:col-6 md:col-6 col-12 w-5 mx-3">
                            <personal-ratio-card
                                :title="lang.foreignPerson"
                                :content="outstandingNationality.overdueEngPercent"
                                :subContent1="outstandingNationality.overdueEngUnit"
                                :subContent2="outstandingNationality.overdueEngAmount"
                            >
                                <template #titleRight>
                                    <icon-earth
                                        style="margin-left: 10px; 
                                        margin-bottom: -7px"
                                    />
                                </template>
                            </personal-ratio-card>
                        </div>
                    </div> -->
                    <div class="flex flex-wrap pt-3" style="height: 75%;">
                        <div class="col-6 pl-0">
                            <personal-ratio-card
                                :title="lang.thaiPerson"
                                :content="outstandingNationality.overdueThaiPercent"
                                :subContent1="outstandingNationality.overdueThaiUnit"
                                :subContent2="outstandingNationality.overdueThaiAmount"
                            >
                                <template #titleRight>
                                    <icon-thai-flag
                                        style="margin-left: 10px; margin-bottom: -7px"
                                    />
                                </template>
                            </personal-ratio-card>
                        </div>
                        <div class="col-6 pl-2 pr-0">
                            <personal-ratio-card
                                :title="lang.foreignPerson"
                                :content="outstandingNationality.overdueEngPercent"
                                :subContent1="outstandingNationality.overdueEngUnit"
                                :subContent2="outstandingNationality.overdueEngAmount"
                            >
                                <template #titleRight>
                                    <icon-earth
                                        style="margin-left: 10px; 
                                        margin-bottom: -7px"
                                    />
                                </template>
                            </personal-ratio-card>
                        </div>
                    </div>
                </div>
            </div>

        <!-- ประเภทการชำระ -->
            <div class="lg:col-6 md:col-12 col-12 pl-0 pt-4 pr-2">
                <div class="content-border h-full">
                    <label class="font-lg font-bold pl-0">{{  titleOutstandingPayType }}</label>
                    <div class="flex flex-wrap justify-content-center">
                        <div class="lg:col-4 md:col-12 col-12 pr-2">
                            <PvChart
                                :type="typeChartOutstandingPayType"
                                :data="chartDataOutstandingPayType"
                                :options="chartOptionsStackedBar"
                                class="pt-6 h-full"
                            />
                        </div>
                        <div class="lg:col-8 md:col-12 col-12">
                            <base-list-view 
                                :dataTable="dataTableOutstandingPayType" 
                                :columns="outstandingColumnPayType"> 
                            </base-list-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
<!-- ภาพรวมในส่วนงาน หนี้คงค้าง -->

<!-- ภาพรวมในส่วนงาน พัสดุ -->
    <div v-if="isParcel" class="content-border pt-2">
        <div class="pt-5 flex justify-content-between flex-wrap">
            <label class="font-2xl font-bold">{{ lang.titleParcelDetail }}</label>
            <PvButton icon="pi pi-refresh" class="p-button p-button-outlined mr-2" @click="reloadParcelData()" />
        </div>
        <div class="flex flex-wrap">
            <!-- จำนวนพัสดุเข้า - ออก -->
            <div class="lg:col-5 md:col-12 col-12 pl-0 pt-4 pr-2">
                <div class="content-border h-full">
                    <label class="font-lg font-bold pl-0">{{ lang.titleParcelInOut }}</label>
                    <div class="pt-2">
                        <base-list-view 
                            :dataTable="dataTableParcelInOut" 
                            :columns="parcelInOutColumn"> 
                        </base-list-view>
                    </div>
                </div>
            </div>

            <!-- จำนวนพัสดุคงค้าง -->
            <div class="lg:col-7 md:col-12 col-12 pl-0 pt-4 pr-2">
                <div class="content-border h-full">
                    <label class="font-lg font-bold pl-0">{{ lang.titleParcelOutstanding }}</label>
                    <div class="flex flex-wrap justify-content-center">
                        <div class="flex align-items-center justify-content-center col-4 pr-3">
                            <div class="w-full">
                                <PvChart
                                    :type="typeChartParcelOutstanding"
                                    :data="chartDataParcelOutstanding"
                                    :options="chartOptionsParcelOutstanding"
                                    :plugins="parcelOutstandingPlugins"
                                />
                            </div>
                        </div>
                        <div class="flex align-items-center justify-content-center col-8 pl-3">
                            <div class="w-full">
                                <base-list-view 
                                    :dataTable="dataTableParcelOutstanding" 
                                    :columns="parcelOutstandingColumn"
                                ></base-list-view>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- สถิติพัสดุรับเข้าและจ่ายออก ย้อนหลัง 6 เดือน -->
        <div class="content-border mt-3 mr-2 mb-2">
            <label class="font-lg font-bold pl-0">{{ lang.titleParcelStat }}</label>
           <div class="p-4">
            <div class="flex pt-5">
                <div>
                    <i class="pi pi-circle-fill p-text-primary pr-2"/>
                    <label>{{ lang.titleParcelIn }}</label>
                </div>
                <div class="pl-6">
                    <i class="pi pi-circle-fill p-text-success pr-2"/>
                    <label>{{ lang.titleParcelOut }}</label>
                </div>
            </div>
            <div>
                <PvChart
                    :type="typeChartParcelStat"
                    :data="chartDataParcelStat"
                    :options="chartOptionsParcelStat"
                    :plugins=plugins
                    class="h-25rem pt-4"
                />
            </div>
           </div>
        </div>
    </div>
<!-- ภาพรวมในส่วนงาน พัสดุ -->

<!-- ภาพรวมในส่วนงาน สถานะพักอาศัย -->
    <div v-if="isResident" class="content-border pt-2">
        <div class="pt-5">
            <label class="font-2xl font-bold">{{ lang.titleResidentDetail }}</label>
        </div>
        <div class="flex flex-wrap">
        <!-- สถานะที่พักอาศัย -->
            <div class="lg:col-6 md:col-12 col-12 pl-0 pt-4 pr-2">
                <div class="content-border h-full">
                    <label class="font-lg font-bold pl-0">{{ lang.residenceStatus }}</label>
                    <div class="flex flex-wrap justify-content-center">
                        <div class="col-4">
                            <PvChart
                                :type="typeChartResidentStatus"
                                :data="chartDataResidentStatus"
                                :options="chartOptionsStackedBar"
                                class="pt-4 h-full"
                            />
                        </div>
                        <div class="lg:col-8 md:col-12 col-12">
                            <base-list-view 
                                :dataTable="dataTableResidentStatus" 
                                :columns="residentColumnStatus"> 
                            </base-list-view>
                        </div>
                    </div>
                </div>
            </div>
        <!-- การใช้ประโยชน์ที่พักอาศัย -->
            <div class="lg:col-6 md:col-12 col-12 pl-0 pt-4 pr-2">
                <div class="content-border h-full">
                    <label class="font-lg font-bold pl-0">{{ lang.residentUtilization }}</label>
                    <div class="flex flex-wrap justify-content-center">
                        <div class="col-4">
                            <PvChart
                                :type="typeChartResidentUtilization"
                                :data="chartDataResidentUtilization"
                                :options="chartOptionsStackedBar"
                                class="pt-4 h-full"
                            />
                        </div>
                        <div class="lg:col-8 md:col-12 col-12">
                            <base-list-view
                                :dataTable="dataTableResidentUtilization" 
                                :columns="residentColumnUtilization" 
                            ></base-list-view>
                        </div>
                    </div>
                </div>
            </div>
        <!-- อัตรากรรมสิทธิ์ในส่วนกลาง แยกตามสัญชาติ -->
            <div class="lg:col-6 md:col-12 col-12 pl-0 pt-3 pr-2">
                <div class="content-border h-full">
                    <div>
                        <label class="font-lg font-bold pl-0">{{ lang.titleOwnershipRate }}</label>
                        <div class="pt-4">
                            <label class="font-sm font-normal pl-0">{{ titleResidentOwnershipRate }}</label>
                        </div>
                    </div>
                    <!-- <div class="flex flex-wrap pt-3">
                        <div class="lg:col-6 md:col-6 col-12 pl-0 w-5 mx-3">
                            <personal-ratio-card
                                :title="lang.thaiPerson"
                                :content="residentList.ownerThaiPercent.toFixed(2) + this.lang.percent"
                                :subContent1="this.formatNumber(residentList.ownerThaiCount).toString() + this.lang.unit"
                                subContent2="&nbsp"
                            >
                                <template #titleRight>
                                    <icon-thai-flag
                                        style="margin-left: 10px; margin-bottom: -7px"
                                    />
                                </template>
                            </personal-ratio-card>
                        </div>
                        <div class="lg:col-6 md:col-6 col-12 w-5 mx-3">
                            <personal-ratio-card
                                :title="lang.foreignPerson"
                                :content="residentList.ownerForeignPercent.toFixed(2) + this.lang.percent"
                                :subContent1="this.formatNumber(residentList.ownerForeignCount).toString() + this.lang.unit"
                                subContent2="&nbsp"
                            >
                                <template #titleRight>
                                    <icon-earth
                                        style="margin-left: 10px; 
                                        margin-bottom: -7px"
                                    />
                                </template>
                            </personal-ratio-card>
                        </div>
                    </div> -->
                    <div class="flex flex-wrap pt-3" style="height: 75%;">
                        <div class="col-6 pl-0">
                            <personal-ratio-card
                                :title="lang.thaiPerson"
                                :content="residentList.ownerThaiPercent.toFixed(2) + this.lang.percent"
                                :subContent1="this.formatNumber(residentList.ownerThaiCount).toString() + this.lang.unit"
                                subContent2="&nbsp"
                            >
                                <template #titleRight>
                                    <icon-thai-flag
                                        style="margin-left: 10px; margin-bottom: -7px"
                                    />
                                </template>
                            </personal-ratio-card>
                        </div>
                        <div class="col-6 pl-2 pr-0">
                            <personal-ratio-card
                                :title="lang.foreignPerson"
                                :content="residentList.ownerForeignPercent.toFixed(2) + this.lang.percent"
                                :subContent1="this.formatNumber(residentList.ownerForeignCount).toString() + this.lang.unit"
                                subContent2="&nbsp"
                            >
                                <template #titleRight>
                                    <icon-earth
                                        style="margin-left: 10px; 
                                        margin-bottom: -7px"
                                    />
                                </template>
                            </personal-ratio-card>
                        </div>
                    </div>
                </div>
            </div>
            <!-- อัตราการลงทะเบียนและใช้งาน LivingOS -->
            <div class="lg:col-6 md:col-12 col-12 pl-0 pt-3 pr-2">
                <div class="content-border h-full">
                    <div>
                        <label class="font-lg font-bold pl-0">{{ lang.titleResidentRegisterUseMobile }}</label>
                        <div class="pt-4">
                            <label class="font-sm font-normal pl-0">{{ titleResidentUseMobile }}</label>
                        </div>
                    </div>
                    <!-- <div class="flex flex-wrap pt-3">
                        <div class="lg:col-4 md:col-6 col-12 pl-0 w-3 mx-3">
                            <personal-ratio-card
                                :title="lang.owner"
                                :content="residentOwnerUseMobilePercent"
                                :subContent1="this.formatNumber(residentList.useMobileOwnerlivingCount).toString() + this.lang.user"
                                :subContent2="this.formatNumber(residentList.useMobileOwnerUnitCount).toString() + this.lang.unit"
                            ></personal-ratio-card>
                        </div>
                        <div class="lg:col-4 md:col-6 col-12 w-3 mx-3">
                            <personal-ratio-card
                                :title="lang.rental"
                                :content="residentRentalUseMobilePercent"
                                :subContent1="this.formatNumber(residentList.useMobileRentalLivingCount).toString() + this.lang.user"
                                :subContent2="this.formatNumber(residentList.useMobileRentalUnitCount).toString() + this.lang.unit"
                            ></personal-ratio-card>
                        </div>
                        <div class="lg:col-4 md:col-6 col-12 w-3 mx-3">
                            <personal-ratio-card
                                :title="lang.stay"
                                :content="residentStayUseMobilePercent"
                                :subContent1="this.formatNumber(residentList.useMobileStayLivingCount).toString() + this.lang.user"
                                :subContent2="this.formatNumber(residentList.useMobileStayUnitCount).toString() + this.lang.unit"
                            ></personal-ratio-card>
                        </div>
                    </div> -->
                    <div class="flex flex-wrap pt-3" style="height: 75%;">
                        <div class="col-4 pl-0">
                            <personal-ratio-card
                                :title="lang.owner"
                                :content="residentOwnerUseMobilePercent"
                                :subContent1="this.formatNumber(residentList.useMobileOwnerlivingCount).toString() + this.lang.user"
                                :subContent2="this.formatNumber(residentList.useMobileOwnerUnitCount).toString() + this.lang.unit"
                            ></personal-ratio-card>
                        </div>
                        <div class="col-4 pl-2">
                            <personal-ratio-card
                                :title="lang.rental"
                                :content="residentRentalUseMobilePercent"
                                :subContent1="this.formatNumber(residentList.useMobileRentalLivingCount).toString() + this.lang.user"
                                :subContent2="this.formatNumber(residentList.useMobileRentalUnitCount).toString() + this.lang.unit"
                            ></personal-ratio-card>
                        </div>
                        <div class="col-4 pl-2">
                            <personal-ratio-card
                                :title="lang.stay"
                                :content="residentStayUseMobilePercent"
                                :subContent1="this.formatNumber(residentList.useMobileStayLivingCount).toString() + this.lang.user"
                                :subContent2="this.formatNumber(residentList.useMobileStayUnitCount).toString() + this.lang.unit"
                            ></personal-ratio-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- ภาพรวมในส่วนงาน สถานะพักอาศัย -->

  </base-container>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useFormat from "../../hooks/format.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import PersonalRatioCard from   "../../components/dashboard/PersonalRatioCard.vue";
import AdvertisementCarousel from   "../../components/advertisement/AdvertisementCarousel.vue";
import SplashDialog from   "../../components/dashboard/SplashDialog.vue";
import useSetTracking from "../../hooks/setTracking";
export default {
  components: {
    PersonalRatioCard,
    AdvertisementCarousel,
    SplashDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.dashboard.dashboard,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.dashboard.dashboard,
        };
      }
    });

    const { formatCurrency, formatNumber, formatDateToISOString } = useFormat();
    const { trackingView } = useSetTracking();

    

    const plugins = [ ChartDataLabels ];

    return {
        lang: mergeLang,
        textTableClass,
        textFormClass,
        formatCurrency,
        formatNumber,
        formatDateToISOString,
        trackingView,
        plugins
    }
  },
  data() {
    return {
      showValidateDialog: false,
      validateWording: "",

      isOutstanding: true,
      isParcel: false,
      isResident: false,

    // Outstanding
      isCommonArea: true,
      isWaterBilling: false,
      tempOutstanding: [],
      selectedOutstanding: null,
      outstandingPeriod: [],
      filteredOutstandingPeriod: [],
      selectedOutstandingPeriod: null,
      typeChartOutstandingStatus: "doughnut",
      chartDataOutstandingStatus: {},
      chartOptionsOutstandingStatus: {},
      dataTableOutstandingStatus: [],
      outstandingColumnStatus: [],
      dataTableOutstandingPayType: [],
      outstandingColumnPayType: [],
      typeChartOutstandingPayType: "bar",
      chartDataOutstandingPayType: {},
    
    // Parcel
      dataTableParcelInOut: [],
      parcelInOutColumn: [],
      dataTableParcelOutstanding: [],
      parcelOutstandingColumn: [],
      typeChartParcelOutstanding: "doughnut",
      chartDataParcelOutstanding: {},
      chartOptionsParcelOutstanding: {},
      typeChartParcelStat: "bar",
      chartDataParcelStat: {},
      chartOptionsParcelStat: {},

    // Resident
      typeChartResidentStatus: "bar",
      chartDataResidentStatus: {},
      dataTableResidentStatus: [],
      residentColumnStatus: [],
      dataTableResidentUtilization: [],
      residentColumnUtilization: [],
      typeChartResidentUtilization: "bar",
      chartDataResidentUtilization: {},

      chartOptionsStackedBar: {
        maintainAspectRatio: false,
        tickColor: "#9BD0F5",
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (data) {
                        return data.dataset.label + " " + data.raw + "%";
                    },
                },
            },
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    display: false
                },
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                },
            }
        }
      },

      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ],
      cacheEnable: true,

      //SplashDialog
      showSplashDialog: false,
      splashData: [
        {
           imageUrl: "https://los-api-erp-prod.thelivingos.com/Aws/inline/public/7fd4fc7d-8f3c-4d3e-8f42-43451340b480.png",
           url: "https://docs.google.com/forms/d/e/1FAIpQLSfy9Wl055o2qkyobxIvcWgtXo-3XR5Lx4FzIuwUDhu1ppBLRw/viewform",
           description: "แบบสอบถามการอบรม",
        },
        {
           imageUrl: "https://los-api-erp-prod.thelivingos.com/Aws/inline/public/70d61638-7cfc-42f5-882c-c8d425a965bf.png",
           url: "https://docs.google.com/document/d/1xtrl0KTSb4GTBwfUmmipKEHRCmrTnjlPLUQNQz6FHwA/edit",
           description: "แนะนำการใช้งานเมนูแนบ Slip",
        },
      ],
    };
  },
  async mounted() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    this.setLoading(true);
    await this.getOutstandingList();
    await this.getParcelList();
    await this.getResidentList();
    await this.checkShouldSetData();
    this.openSplashDialog();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    searchPeriod(event) {
        let query = event.query;
        if (!query.trim().length) {
            const result = [...this.outstandingPeriod];
            this.filteredOutstandingPeriod = result;
        } else {
            this.filteredOutstandingPeriod = this.outstandingPeriod.filter((period) => {
            return period.name.includes(query);
            });
        }
    },
    clickType(type) {
        this.isOutstanding = false;
        this.isParcel = false;
        this.isResident = false;

        if (type == "outstanding") {
            this.isOutstanding = true;
        } else if (type == "parcel") {
            this.isParcel = true;
        } else if (type == "resident") {
            this.isResident = true;
        }
    },
    async checkShouldSetData() {
        if(this.outstandingList) {
            await this.setDataOutstanding();
            await this.setDataOutstandingCreate();
            await this.setDataChartOutstanding();
        }

        if(this.residentList) {
            await this.setDataResident();
        }

        if(this.parcelList) {
            await this.setDataParcel();
        }

        this.setLoading(false);
    },
// Outstanding
    async getOutstandingList() {
      try {
        await this.$store.dispatch("dashboard/getOutstandingList", this.payload);
      } catch (error) {
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    //   console.log("outstandingList", this.outstandingList);
    },
    async setDataOutstanding() {
        if(this.isCommonArea) {
            this.tempOutstanding = this.outstandingList.commonAreas;
        } else if(this.isWaterBilling) {
            this.tempOutstanding = this.outstandingList.waterBillings;
        }
    },
    async setDataOutstandingCreate() {
        let periods = this.outstandingList.periods;
        for (let index = 0; index < periods.length ; index++) {
            this.outstandingPeriod.push({
                code: index + 1,
                name: periods[index]
            });
        }
        this.filteredOutstandingPeriod = this.outstandingPeriod;
        this.setDefaultOutstandingPeriod();
        this.selectedOutstanding = this.tempOutstanding.filter((data) => data.periodTH == this.selectedOutstandingPeriod.name)[0];
        // console.log("setDataOutstandingCreate", this.selectedOutstanding);
    },
    async changeOutstandingPeriod() {
        await this.setDataOutstanding();
        this.selectedOutstanding = this.tempOutstanding.filter((data) => data.periodTH == this.selectedOutstandingPeriod.name)[0];
        await this.setDataChartOutstanding();
    },
    setDefaultOutstandingPeriod() {
        if(!this.selectedOutstandingPeriod) {
            this.selectedOutstandingPeriod = this.outstandingPeriod[0];
            this.changeOutstandingPeriod();
        }
    },
    async setDataChartOutstanding() {
        await this.setDataChartOutstandingStatus(); //รายละเอียดสถานะหนี้คงค้าง
        await this.setDataChartOutstandingPayType(); //ประเภทการชำระ 
    },
    async setDataChartOutstandingStatus() {
        this.chartDataOutstandingStatus = {
            labels: [this.lang.paid, this.lang.overdue],
            datasets: [
                {
                    data: [
                        this.selectedOutstanding ? this.selectedOutstanding.paidPercent == null ? 0 : parseFloat(this.selectedOutstanding.paidPercent.toFixed(2)) : 0,

                        this.selectedOutstanding ? this.selectedOutstanding.overduePercent == null ? 0 : parseFloat(this.selectedOutstanding.overduePercent.toFixed(2)) : 0
                    ],
                    backgroundColor: ['#13d380', '#f03737'],
                },
            ],
        };
        
        this.chartOptionsOutstandingStatus = {
            cutout: '40%',
            plugins: {
                legend: {
                    display: false,
                },
                datalabels: {
                    color: 'white',
                    font: {
                        weight: 'bold',
                        size: 16,
                    },
                    formatter: (value) => {
                        let label = value + "%";
                        return label;
                    },
                },
                tooltip: {
                    callbacks: {
                        label: function (data) {
                            return data.raw + "%";
                        },
                    },
                },
            }
        };
        

        let paidPercent = "0.00%";
        let overduePercent = "0.00%";
        if(this.selectedOutstanding) {
            paidPercent = (this.selectedOutstanding.paidPercent ? this.formatCurrency(this.selectedOutstanding.paidPercent).toString() : "0.00") + this.lang.percent;
            overduePercent = (this.selectedOutstanding.overduePercent ? this.formatCurrency(this.selectedOutstanding.overduePercent).toString() : "0.00") + this.lang.percent;
        }

        this.dataTableOutstandingStatus = [
            {
                status: this.lang.paid,
                unitCount: (this.selectedOutstanding ? (this.selectedOutstanding.paidUnit ? this.formatNumber(this.selectedOutstanding.paidUnit) : 0).toString() : "0") + ` (${paidPercent})`,
                totalAmount: (this.selectedOutstanding ? (this.selectedOutstanding.paidAmount ? this.formatCurrency(this.selectedOutstanding.paidAmount).toString() : "0.00") : "0.00") + this.lang.thaiBath+ ` (${paidPercent})`,
                iconColor: "success",
                textColor: "p-text-success",
            },
            {
                status: this.lang.overdue,
                unitCount: (this.selectedOutstanding ? (this.selectedOutstanding.overdueUnit ? this.formatNumber(this.selectedOutstanding.overdueUnit) : 0).toString() : "0") + ` (${overduePercent})`,
                totalAmount: (this.selectedOutstanding ? (this.selectedOutstanding.overdueAmount ? this.formatCurrency(this.selectedOutstanding.overdueAmount).toString() : "0.00") : "0.00") + this.lang.thaiBath + ` (${overduePercent})`,
                iconColor: "error",
                textColor: "p-error",
            },
        ];

        this.outstandingColumnStatus = [
            {
                field: "status",
                header: this.lang.invoiceStatus,
                type: "string",
                style: "width: 10%;height: 46px;",
                headerClass: "h-left font-sm ",
                bodyClass: "text-left",
                iconName: "icon-spot",
            },
            {
                field: "unitCount",
                header: this.lang.unitCount,
                type: "string",
                style: "width: 10%;",
                headerClass: "h-right font-sm ",
                bodyClass: "text-right",
            },
            {
                field: "totalAmount",
                header: this.lang.totalAmount,
                type: "string",
                style: "width: 10%;",
                headerClass: "h-right font-sm ",
                bodyClass: "text-right",
            },
        ];
    },
    async setDataChartOutstandingPayType() {
        let payTotalAmount = 0;
        if(this.selectedOutstanding) {
            payTotalAmount = (this.selectedOutstanding.cashAmount ? this.selectedOutstanding.cashAmount : 0) + (this.selectedOutstanding.transferAmount ? this.selectedOutstanding.transferAmount : 0) + (this.selectedOutstanding.epayAmount ? this.selectedOutstanding.epayAmount : 0) + (this.selectedOutstanding.otherAmount ? this.selectedOutstanding.otherAmount : 0)
        }

        this.chartDataOutstandingPayType = {
            labels: [''],
            datasets: [
                {
                    type: 'bar',
                    label: this.lang.cash,
                    data: [this.selectedOutstanding ? (this.selectedOutstanding.cashAmount ? ((this.selectedOutstanding.cashAmount/payTotalAmount)*100).toFixed(2) : 0) : 0],
                    backgroundColor: ["#1c70f7"],
                },
                {
                    type: 'bar',
                    label: this.lang.transfer,
                    data: [this.selectedOutstanding ? (this.selectedOutstanding.transferAmount ? ((this.selectedOutstanding.transferAmount/payTotalAmount)*100).toFixed(2) : 0) : 0],
                    backgroundColor: ["#13d380"],
                },
                {
                    type: 'bar',
                    label: this.lang.digitalPayment,
                    data: [this.selectedOutstanding ? (this.selectedOutstanding.epayAmount ? ((this.selectedOutstanding.epayAmount/payTotalAmount)*100).toFixed(2) : 0) : 0],
                    backgroundColor: ["#FFAA00"],
                },
                {
                    type: 'bar',
                    label: this.lang.other,
                    data: [this.selectedOutstanding ? (this.selectedOutstanding.otherAmount ? ((this.selectedOutstanding.otherAmount/payTotalAmount)*100).toFixed(2) : 0) : 0],
                    backgroundColor: ["#7B61FF"],
                },
            ]
        };

        this.dataTableOutstandingPayType = [
            {
                type: this.lang.cash,
                totalAmount: this.selectedOutstanding ? (this.selectedOutstanding.cashAmount ? this.formatCurrency(this.selectedOutstanding.cashAmount).toString() : "0.00") : "0.00" + this.lang.thaiBath,
                iconColor: "primary",
            },
            {
                type: this.lang.transfer,
                totalAmount: this.selectedOutstanding ? (this.selectedOutstanding.transferAmount ? this.formatCurrency(this.selectedOutstanding.transferAmount).toString() : "0.00") : "0.00" + this.lang.thaiBath,
                iconColor: "success",
            },
            {
                type: this.lang.digitalPayment,
                totalAmount: this.selectedOutstanding ? (this.selectedOutstanding.epayAmount ? this.formatCurrency(this.selectedOutstanding.epayAmount).toString() : "0.00") : "0.00" + this.lang.thaiBath,
                iconColor: "warning",
            },
            {
                type: this.lang.other,
                totalAmount: this.selectedOutstanding ? (this.selectedOutstanding.otherAmount ? this.formatCurrency(this.selectedOutstanding.otherAmount).toString() : "0.00") : "0.00" + this.lang.thaiBath,
                iconColor: "purple",
            },
        ];

        this.outstandingColumnPayType = [
            {
                field: "type",
                header: this.lang.payType,
                type: "string",
                style: "width: 10%;height: 46px;",
                headerClass: "h-left font-sm ",
                bodyClass: "text-left",
                iconName: "icon-spot",
            },
            {
                field: "totalAmount",
                header: this.lang.totalAmount,
                type: "string",
                style: "width: 10%;",
                headerClass: "h-right font-sm ",
                bodyClass: "text-right",
            },
        ];
    },
    async clickFilterOutstanding(type) {
        this.isCommonArea = false;
        this.isWaterBilling = false;
        if (this.isOutstanding && type == "commonArea") {
            this.isCommonArea = true;
        } else if (this.isOutstanding && type == "waterBilling") {
            this.isWaterBilling = true;
        }
        // this.checkShouldSetData();
        await this.setDataOutstanding();
        this.selectedOutstanding = this.tempOutstanding.filter((data) => data.periodTH == this.selectedOutstandingPeriod.name)[0];
        await this.setDataChartOutstanding();
        // console.log("clickFilterOutstanding", this.selectedOutstanding);
    },
// Parcel
    async getParcelList() {
      try {
        await this.$store.dispatch("dashboard/getParcelList", this.payload);
      } catch (error) {
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    //   console.log("parcelList", this.parcelList);
    },
    async setDataParcel() {
        await this.setDataTableParcelInOut(); //จำนวนพัสดุเข้า - ออก
        await this.setDataChartParcelOutstanding(); //จำนวนพัสดุคงค้าง
        await this.setDataChartParcelStat(); //สถิติพัสดุรับเข้าและจ่ายออก ย้อนหลัง 6 เดือน
    },
    async setDataTableParcelInOut() {
        this.dataTableParcelInOut = [
            {
                type: this.lang.parcelByToday,
                parcelIn: this.parcelList.parcelNewByDate ? this.formatNumber(this.parcelList.parcelNewByDate).toString() : "0",
                parcelOut: this.parcelList.parcelReceivedByDate ? this.formatNumber(this.parcelList.parcelReceivedByDate).toString() : "0",
            },
            {
                type: this.lang.parcelByYesterday,
                parcelIn: this.parcelList.parcelNewBy15Day ? this.formatNumber(this.parcelList.parcelNewBy15Day).toString() : "0",
                parcelOut: this.parcelList.parcelReceivedBy15Day ? this.formatNumber(this.parcelList.parcelReceivedBy15Day).toString() : "0",
            },
            {
                type: this.lang.parcelByWeek,
                parcelIn: this.parcelList.parcelNewByWeek ? this.formatNumber(this.parcelList.parcelNewByWeek).toString() : "0",
                parcelOut: this.parcelList.parcelReceivedByWeek ? this.formatNumber(this.parcelList.parcelReceivedByWeek).toString() : "0",
            },
            {
                type: this.lang.parcelByMonth,
                parcelIn: this.parcelList.parcelNewByMonth ? this.formatNumber(this.parcelList.parcelNewByMonth).toString() : "0",
                parcelOut: this.parcelList.parcelReceivedByMonth ? this.formatNumber(this.parcelList.parcelReceivedByMonth).toString() : "0",
            },
            {
                type: this.lang.parcelByYear,
                parcelIn: this.parcelList.parcelNewTotal ? this.formatNumber(this.parcelList.parcelNewTotal).toString() : "0",
                parcelOut: this.parcelList.parcelReceivedTotal ? this.formatNumber(this.parcelList.parcelReceivedTotal).toString() : "0",
            },
        ];

        this.parcelInOutColumn = [
            {
                field: "type",
                header: this.lang.parcelType,
                type: "string",
                style: "width: 10%;height: 46px;",
                headerClass: "h-left font-sm ",
                bodyClass: "text-left",
            },
            {
                field: "parcelIn",
                header: this.lang.parcelIn,
                type: "string",
                style: "width: 10%;",
                headerClass: "h-right font-sm p-text-primary",
                bodyClass: "text-right p-text-primary",
            },
            {
                field: "parcelOut",
                header: this.lang.parcelOut,
                type: "string",
                style: "width: 10%;",
                headerClass: "h-right font-sm p-text-success",
                bodyClass: "text-right p-text-success",
            },
        ];
    },
    async setDataChartParcelOutstanding() {
        let parcelOutstandingTotal = (this.parcelList.parcelWaitByDate ? this.parcelList.parcelWaitByDate : 0 )
            + (this.parcelList.parcelWaitBy15Day ? this.parcelList.parcelWaitBy15Day : 0) 
            + (this.parcelList.parcelWaitByWeek ? this.parcelList.parcelWaitByWeek : 0)
            + (this.parcelList.parcelWaitByMonth ? this.parcelList.parcelWaitByMonth : 0);
        
        let parcelWaitByDate = this.parcelList.parcelWaitByDate ? ((this.parcelList.parcelWaitByDate/parcelOutstandingTotal)*100).toFixed(2) : "0";
        let parcelWaitBy15Day = this.parcelList.parcelWaitBy15Day ? ((this.parcelList.parcelWaitBy15Day/parcelOutstandingTotal)*100).toFixed(2) : "0";
        let parcelWaitByWeek = this.parcelList.parcelWaitByWeek ? ((this.parcelList.parcelWaitByWeek/parcelOutstandingTotal)*100).toFixed(2) : "0";
        let parcelWaitByMonth = this.parcelList.parcelWaitByMonth ? ((this.parcelList.parcelWaitByMonth/parcelOutstandingTotal)*100).toFixed(2) : "0";

        this.chartDataParcelOutstanding = {
            labels: [   
                this.lang.parcelWaitByDate, 
                this.lang.parcelWaitBy15Day,
                this.lang.parcelWaitByWeek, 
                this.lang.parcelWaitByMonth, 
            ],
            datasets: [
                {
                    data: [
                        parcelWaitByDate,
                        parcelWaitBy15Day,
                        parcelWaitByWeek,
                        parcelWaitByMonth
                    ],
                    backgroundColor: ['#13d380', '#7B61FF', '#FFAA00', '#f03737'],
                },
            ],
        };

        this.chartOptionsParcelOutstanding = {
            cutout: '40%',
            plugins: {
                legend: {
                    display: false,
                },
                datalabels: {
                    color: 'white',
                    font: {
                        weight: 'bold',
                        size: 16,
                    },
                    formatter: (value) => {
                        let label = value + "%";
                        return label;
                    },
                },
                tooltip: {
                    callbacks: {
                        label: function (data) {
                            return data.raw + "%";
                        },
                    },
                },
            }
        };

        this.dataTableParcelOutstanding = [
            {
                type: this.lang.parcelWaitByDate,
                parcelOutstanding: this.parcelList.parcelWaitByDate ? this.formatNumber(this.parcelList.parcelWaitByDate).toString() : "0",
                iconColor: "success",
            },
            {
                type: this.lang.parcelWaitBy15Day,
                parcelOutstanding: this.parcelList.parcelWaitBy15Day ? this.formatNumber(this.parcelList.parcelWaitBy15Day).toString() : "0",
                iconColor: "purple",
            },
            {
                type: this.lang.parcelWaitByWeek, 
                parcelOutstanding: this.parcelList.parcelWaitByWeek ? this.formatNumber(this.parcelList.parcelWaitByWeek).toString() : "0",
                iconColor: "warning",
            },
            {
                type: this.lang.parcelWaitByMonth,
                parcelOutstanding: this.parcelList.parcelWaitByMonth ? this.formatNumber(this.parcelList.parcelWaitByMonth).toString() : "0",
                iconColor: "error",
            },
        ];

        this.parcelOutstandingColumn = [
            {
                field: "type",
                header: this.lang.parcelOutstanding,
                type: "string",
                style: "width: 10%;height: 46px;",
                headerClass: "h-left font-sm ",
                bodyClass: "text-left",
                iconName: "icon-spot",
            },
            {
                field: "parcelOutstanding",
                header: this.lang.parcelOutstandingCount,
                type: "string",
                style: "width: 10%;",
                headerClass: "h-right font-sm p-text-error",
                bodyClass: "text-right p-error",
            },
        ];
    },
    async setDataChartParcelStat() {
        // console.log("parcelList", this.parcelList);
        let parcelStatList = this.parcelList.martParcelHistoryModel;
        let month1 = parcelStatList[0];
        let month2 = parcelStatList[1];
        let month3 = parcelStatList[2];
        let month4 = parcelStatList[3];
        let month5 = parcelStatList[4];
        let month6 = parcelStatList[5];

        this.chartDataParcelStat = {
            labels: [
                new Date(month1.date).toLocaleString('th-TH',{month:'short', year:'numeric'}),
                new Date(month2.date).toLocaleString('th-TH',{month:'short', year:'numeric'}),
                new Date(month3.date).toLocaleString('th-TH',{month:'short', year:'numeric'}),
                new Date(month4.date).toLocaleString('th-TH',{month:'short', year:'numeric'}),
                new Date(month5.date).toLocaleString('th-TH',{month:'short', year:'numeric'}),
                new Date(month6.date).toLocaleString('th-TH',{month:'short', year:'numeric'})
            ],
            datasets: [
                {
                    label: this.lang.titleParcelIn,
                    data: [ 
                        month1.countWait,
                        month2.countWait,
                        month3.countWait,
                        month4.countWait,
                        month5.countWait,
                        month6.countWait,
                    ],
                    backgroundColor: ['#1c70f7']
                },
                {
                    label: this.lang.titleParcelOut,
                    data: [ 
                        month1.countReceived,
                        month2.countReceived,
                        month3.countReceived,
                        month4.countReceived,
                        month5.countReceived,
                        month6.countReceived,
                    ],
                    backgroundColor: ['#13d380']
                }
            ]
        };

        this.chartOptionsParcelStat = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    display: false,
                },
                datalabels: {
                    color: 'white',
                    font: {
                        weight: 'bold',
                        size: 16,
                    },
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    grid: {
                        drawBorder: false,
                    }
                }
            }
        }
    },
// Resident
    async getResidentList() {
      try {
        await this.$store.dispatch("dashboard/getResidentList", this.payload);
      } catch (error) {
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    //   console.log("residentList", this.residentList);
    },
    async setDataResident() {
        await this.setDataChartResidentStatus(); //สถานะที่พักอาศัย
        await this.setDataChartResidentUtilization(); //การใช้ประโยชน์ที่พักอาศัย
    },
    async setDataChartResidentStatus() {
        this.chartDataResidentStatus = {
            labels: [''],
            datasets: [
                {
                    type: 'bar',
                    label: this.lang.isLivingUnit,
                    data: [this.residentList.livingUnitPercent ? this.residentList.livingUnitPercent.toFixed(2) : "0.00"],
                    backgroundColor: ["#1c70f7"],
                },
                {
                    type: 'bar',
                    label: this.lang.isEmptyUnit,
                    data: [this.residentList.emptyUnitPercent ? this.residentList.emptyUnitPercent.toFixed(2) : "0.00"],
                    backgroundColor: ["#787a7d"],
                },
            ]
        };
        
        this.dataTableResidentStatus = [
            {
                status: this.lang.isLivingUnit,
                unitCount: `${this.formatNumber(this.residentList.livingUnitCount).toString()} (${this.residentList.livingUnitPercent.toFixed(2) }%)`,
                iconColor: "primary",
            },
            {
                status: this.lang.isEmptyUnit,
                unitCount: `${this.formatNumber(this.residentList.emptyUnitCount).toString()} (${this.residentList.emptyUnitPercent.toFixed(2) }%)`,
                iconColor: "secondary",
            },
        ];

        this.residentColumnStatus = [
            {
                field: "status",
                header: this.lang.status,
                type: "string",
                style: "width: 10%;height: 46px;",
                headerClass: "h-left font-sm ",
                bodyClass: "text-left",
                iconName: "icon-spot",
            },
            {
                field: "unitCount",
                header: this.lang.unit,
                type: "string",
                style: "width: 10%;",
                headerClass: "h-right font-sm ",
                bodyClass: "text-right",
            },
        ];
    },
    async setDataChartResidentUtilization() {
        this.chartDataResidentUtilization = {
            labels: [''],
            datasets: [
                {
                    type: 'bar',
                    label: this.lang.isOwnerLiving,
                    data: [this.residentList.ownerLivingUnitPercent ? this.residentList.ownerLivingUnitPercent.toFixed(2) : "0.00"],
                    backgroundColor: ["#1c70f7"],
                },
                {
                    type: 'bar',
                    label: this.lang.isInvesment,
                    data: [this.residentList.rentalLivingUnitPercent ? this.residentList.rentalLivingUnitPercent.toFixed(2) : "0.00"],
                    backgroundColor: ["#13d380"],
                },
            ]
        };        


        this.dataTableResidentUtilization = [
            {
                type: this.lang.isOwnerLiving,
                unitCount: `${this.formatNumber(this.residentList.ownerLivingUnitCount).toString()} (${this.residentList.ownerLivingUnitPercent.toFixed(2) }%)`,
                iconColor: "primary",
            },
            {
                type: this.lang.isInvesment,
                unitCount: `${this.formatNumber(this.residentList.rentalLivingUnitCount).toString()} (${this.residentList.rentalLivingUnitPercent.toFixed(2) }%)`,
                iconColor: "success",
            },
        ];

        this.residentColumnUtilization = [
            {
                field: "type",
                header: this.lang.type,
                type: "string",
                style: "width: 10%;height: 46px;",
                headerClass: "h-left font-sm ",
                bodyClass: "text-left",
                iconName: "icon-spot",
            },
            {
                field: "unitCount",
                header: this.lang.unit,
                type: "string",
                style: "width: 10%;",
                headerClass: "h-right font-sm ",
                bodyClass: "text-right",
            },
        ];
    },
    async reloadParcelData() {
      this.cacheEnable = false;
      this.setLoading(true);
      await this.getParcelList();
      await this.setDataParcel();
      this.setLoading(false);
    },
    openSplashDialog() {
      const haveSplash = this.splashData.find(item => item.imageUrl != "");
      if ( haveSplash ) {
        const dateNow = new Date();
        const storageName = `splash_view_${this.userId}`;
        const local = localStorage.getItem(storageName);
        if (!local || (local && this.formatDateToISOString(new Date(local)) != this.formatDateToISOString(dateNow))) {
            this.showSplashDialog = true;
            let element = document.getElementsByTagName("body")[0];
            element.style.overflow = "hidden";

            let payload = {
                name: "splash_dialog",
                event: {}
            }
            this.trackingView(payload);
        }
      }
    },
  },
  computed: {
    payload() {
      const payload = {
        projectId: this.selectedProject.id,
        cacheEnable: this.cacheEnable,
      };

      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    outstandingList() {
      return this.$store.getters["dashboard/outstandingList"];
    },
    residentList() {
      return this.$store.getters["dashboard/residentList"];
    },
    parcelList() {
      return this.$store.getters["dashboard/parcelList"];
    },
    outstandingCommonAreaPercent() {
        let result = "0.00";
        if(Object.keys(this.outstandingList).length !== 0 && this.selectedOutstandingPeriod) {
            let tempData = this.outstandingList.commonAreas.filter((data) => data.periodTH == this.selectedOutstandingPeriod.name)[0];
            if(tempData) {
                result = tempData.paidPercent ? tempData.paidPercent.toFixed(2) : "0.00";
            }
        }
        return result;
    },
    outstandingWaterBillingPercent() {
        let result = "0.00";
        if(Object.keys(this.outstandingList).length !== 0 && this.selectedOutstandingPeriod) {
            let tempData = this.outstandingList.waterBillings.filter((data) => data.periodTH == this.selectedOutstandingPeriod.name)[0];
            if(tempData) {
                result = tempData.paidPercent ? tempData.paidPercent.toFixed(2) : "0.00";
            }
        }
        return result;
    },
    outstandingCommonAreaIsHigh() {
        let result = false;
        if(parseFloat(this.outstandingCommonAreaPercent) == 100) {
            result = true;
        }
        return result;
    },
    outstandingCommonAreaIsMedium() {
        let result = false;
        if(parseFloat(this.outstandingCommonAreaPercent) >= 71 && parseFloat(this.outstandingCommonAreaPercent) <= 99) {
            result = true;
        }
        return result;
    },
    outstandingCommonAreaIsLow() {
        let result = false;
        if(parseFloat(this.outstandingCommonAreaPercent) >= 0 && parseFloat(this.outstandingCommonAreaPercent) <= 70) {
            result = true;
        }
        return result;
    },
    outstandingWaterBillingIsHigh() {
        let result = false;
        if(parseFloat(this.outstandingWaterBillingPercent) == 100) {
            result = true;
        }
        return result;
    },
    outstandingWaterBillingIsMedium() {
        let result = false;
        if(parseFloat(this.outstandingWaterBillingPercent) >= 71 && parseFloat(this.outstandingWaterBillingPercent) <= 99) {
            result = true;
        }
        return result;
    },
    outstandingWaterBillingIsLow() {
        let result = false;
        if(parseFloat(this.outstandingWaterBillingPercent) >= 0 && parseFloat(this.outstandingWaterBillingPercent) <= 70) {
            result = true;
        }
        return result;
    },
    titleDetail() {
        let result = "";
        if(this.isOutstanding) {
            result = this.lang.titleOutstanding;
        } else if(this.isParcel) {
            result = this.lang.titleParcel;
        } else if(this.isResident) {
            result = this.lang.titleResident;
        }
        return result;
    },
    titleOutstandingDetail() {
        let result = "";
        if(this.isCommonArea) {
            result = this.lang.titleOutstandingDetail.replace(":type", this.lang.commonArea);
        } else if(this.isWaterBilling) {
            result = this.lang.titleOutstandingDetail.replace(":type", this.lang.waterBilling);
        }
        return result;
    },
    titleOutstandingNationality() {
        let result = "";
        if(this.isCommonArea) {
            result = this.lang.titleOutstandingNationality.replace(":type", this.lang.commonArea);
        } else if(this.isWaterBilling) {
            result = this.lang.titleOutstandingNationality.replace(":type", this.lang.waterBilling);
        }
        return result;
    },
// สัดส่วนคงค้างชำระตามสัญชาติ
    outstandingNationality() {
        let result = {};
        if(this.selectedOutstanding) {
            let data = this.selectedOutstanding;
            result = {
                overdueThaiAmount: this.formatCurrency(data.overdueThaiAmount).toString() + this.lang.thaiBath,
                overdueThaiPercent: (data.overdueThaiPercent ? data.overdueThaiPercent.toFixed(2) : "0.00") + this.lang.percent,
                overdueThaiUnit: (data.overdueThaiUnit ? this.formatNumber(data.overdueThaiUnit) : "0" )+ this.lang.unit,
                overdueEngAmount: this.formatCurrency(data.overdueEngAmount).toString() + this.lang.thaiBath,
                overdueEngPercent: (data.overdueEngPercent ? data.overdueEngPercent.toFixed(2) : "0.00") + this.lang.percent,
                overdueEngUnit: (data.overdueEngUnit ? this.formatNumber(data.overdueEngUnit) : "0") + this.lang.unit, 
            }
        } else {
            result = {
                overdueThaiAmount: "0.00" + this.lang.thaiBath,
                overdueThaiPercent: "0.00" + this.lang.percent,
                overdueThaiUnit: "0" + this.lang.unit,
                overdueEngAmount: "0.00" + this.lang.thaiBath,
                overdueEngPercent: "0.00" + this.lang.percent,
                overdueEngUnit: "0" + this.lang.unit, 
            }
        }
        return result;
    },
    titleOutstandingPaidAmount() {
        let result = this.lang.titleOutstandingPaidAmount.replace(":amount", "0.00");
        if(this.selectedOutstanding) {
            result = this.lang.titleOutstandingPaidAmount.replace(":amount", this.selectedOutstanding.billAmount ? this.formatCurrency(this.selectedOutstanding.billAmount) : "0.00");
        }
        return result;
    },
    titleOutstandingPayType() {
        let result = "";
        if(this.isCommonArea) {
            result = this.lang.titleOutstandingPayType.replace(":type", this.lang.commonArea);
        } else if(this.isWaterBilling) {
            result = this.lang.titleOutstandingPayType.replace(":type", this.lang.waterBilling);
        }
        return result;
    },
    titleResidentOwnershipRate() {
        let result = "";
        if(this.residentList) {
            result = this.lang.titleResidentOwnershipRate.replace(":unit", this.residentList.allUnitCount);
        }
        return result;
    },
    titleResidentUseMobile() {
        let result = "";
        if(this.residentList) {
            result = this.lang.titleResidentUseMobile.replace(":user", this.residentList.useMobileTotalLivingCount);
        }
        return result;
    },
    residentOwnerUseMobilePercent() {
        let result = "0.00%";
        if(this.residentList && this.residentList.useMobileOwnerlivingCount > 0) {
            result = ((this.residentList.useMobileOwnerlivingCount/this.residentList.useMobileTotalLivingCount)*100).toFixed(2) + "%"
        }
        return result;
    },
    residentRentalUseMobilePercent() {
        let result = "0.00%";
        if(this.residentList && this.residentList.useMobileRentalLivingCount > 0) {
            result = ((this.residentList.useMobileRentalLivingCount/this.residentList.useMobileTotalLivingCount)*100).toFixed(2) + "%"
        }
        return result;
    },
    residentStayUseMobilePercent() {
        let result = "0.00%";
        if(this.residentList && this.residentList.useMobileStayLivingCount > 0) {
            result = ((this.residentList.useMobileStayLivingCount/this.residentList.useMobileTotalLivingCount)*100).toFixed(2) + "%"
        }
        return result;
    },
    iconColorIsClickOutstanding() {
        let result = "secondary";
        if(this.isOutstanding) {
            result = "primary";
        } 
        return result;
    },
    iconColorIsClickParcel() {
        let result = "secondary";
        if(this.isParcel) {
            result = "primary";
        } 
        return result;
    },
    iconColorIsClickResident() {
        let result = "secondary";
        if(this.isResident) {
            result = "primary";
        } 
        return result;
    },
    outstandingStatusPlugins(){
        let unitCount = "0";
        let unit = this.lang.unit;
        if(this.selectedOutstanding) {
            unitCount = this.formatNumber(this.selectedOutstanding.billUnitCount).toString();
        }
        let centerText = {
            id: 'centerText',
            beforeDraw( chart) {
                const { ctx, chartArea: {top, width, height}} = chart
                ctx.save();
                ctx.fillStyle = 'black';
                ctx.textAlign = 'center';
                ctx.font = '16px Sarabun';
                ctx.fillText(unitCount, width / 2, top + (height / 2.1));
                ctx.fillText(unit, width / 2, top + (height / 1.7));
            }
        }
        return [ ChartDataLabels, centerText ];
    },
    parcelOutstandingPlugins() {
        let parcelWaitTotal = "0";
        let item = this.lang.item;
        if(this.parcelList) {
            let parcelWaitByDate = this.parcelList.parcelWaitByDate ? this.parcelList.parcelWaitByDate : 0;
            let parcelWaitBy15Day = this.parcelList.parcelWaitBy15Day ? this.parcelList.parcelWaitBy15Day : 0;
            let parcelWaitByWeek = this.parcelList.parcelWaitByWeek ? this.parcelList.parcelWaitByWeek : 0;
            let parcelWaitByMonth = this.parcelList.parcelWaitByMonth ? this.parcelList.parcelWaitByMonth : 0;
            parcelWaitTotal = this.formatNumber(parcelWaitByDate + parcelWaitBy15Day + parcelWaitByWeek + parcelWaitByMonth).toString();
        }
        const centerText = {
            id: 'centerText',
            beforeDraw( chart) {
                const { ctx, chartArea: {top, width, height}} = chart
                ctx.save();
                ctx.fillStyle = 'black';
                ctx.textAlign = 'center';
                ctx.font = '16px Sarabun';
                ctx.fillText(parcelWaitTotal, width / 2, top + (height / 2.1));
                ctx.fillText(item, width / 2, top + (height / 1.7));
            }
        }
        return [ ChartDataLabels, centerText ];
    },
    userId() {
      return this.$store.getters.userId;
    },
  },
};
</script>
