export default {
  createWht:"สร้างใบภาษีหัก ณ ที่จ่าย",
  editWht:"แก้ไขใบภาษีหัก ณ ที่จ่าย",
  taxbook:"เล่มที่",
  taxType: "แบบ ภ.ง.ด.",
  taxDate:"วันที่จ่าย",
  taxDueDate:"วันที่นำส่งภาษี",
  supplier:"ผู้จัดจำหน่าย",
  address1:"ที่อยู่",
  address2:"แขวง/เขต",
  address3:"จังหวัด",
  taxId:"เลขประจำตัวผู้เสียภาษี",
  typOfIncome:"ประเภทเงินได้ที่จ่าย",
  rate:"อัตรา %",
  paidAmount:"จำนวนเงินที่จ่าย",
  taxAmount:"ภาษีที่หักไว้",
  remark:"หมายเหตุ",
  status:"สถานะ",
  status0:"หักภาษี ณ ที่จ่าย",
  status1:"ออกให้ตลอดไป",
  status2:"ออกให้ครั้งเดียว",
  status3:"อื่นๆ",
  totalAmount:"รวมทั้งหมด",
  validateTotalAmount:"จำนวนเงินต้องมากกว่า 0",
  line01Description:"1. เงินเดือน ค่าจ้าง เบี้ยเลี้ยง โบนัส ฯลฯ ตามมาตรา 40 (1)",
  line02Description:"2. ค่าธรรมเนียม ค่านายหน้า ฯลฯ ตามมาตรา 40 (2)",
  line03Description:"3. ค่าแห่งลิขสิทธิ์ ฯลฯ  ตามมาตรา 40 (3)",
  line04Description:"4. (ก) ค่าดอกเบี้ย ฯลฯ  ตามมาตรา 40 (4) (ก)",
  line05Description:"    (ข) เงินปันผล เงินส่วนแบ่งกำไร ฯลฯ  ตามมาตรา 40 (4) (ข)",
  line06Description:"5. การจ่ายเงินที่ต้องหักภาษี ณ ที่จ่ายตามคำสั่งกรมสรรพากรที่ออกตามมาตรา 3 เตรส เช่น ค่าโฆษณา",
  validateCancel: "ไม่สามารถยกเลิกเอกสารนี้ได้ เนื่องจากมีเอกสารอ้างถึง",
};
