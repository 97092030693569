import Vuex from "vuex";
import { createStore } from "vuex-extensions";
import authModule from "./auth/index.js";
import sharedModule from "./shared/index.js";
import projectModule from "./project/index.js";

//ar
import arInvoiceModule from "./ar/invoice/index.js";
import arPrepaidRefundModule from "./ar/prepaidRefund/index.js";
import arReceiptModule from './ar/receipt/index.js';
import arDepositModule from './ar/deposit/index.js';
import arNoticeModule from "./ar/notice/index.js";
import arGuaranteeModule from "./ar/guarantee/index.js";
import arCalculateInvoiceModule from "./ar/calculateInvoice/index.js";
import arCalculateNoticeModule from "./ar/calculateNotice/index.js";
import arBankModule from "./ar/bank/index.js";
import arCreditNoteModule from "./ar/creditNote/index.js";
import arRefundGuaranteeModule from "./ar/refundGuarantee/index.js";
import noticeCustomReportModule from "./ar/noticeCustomReport/index.js";
import arReceiptBillPaymentModule from './ar/receipt/billPayment/index.js';
import transferSlipModule from './ar/transferSlip/index.js';
import arStockKeycardModule from './ar/stockKeycard/index.js';
import importStockKeycardModule from './ar/stockKeycard/importStockKeycard/index.js';
import arImportDepositModule from "./ar/deposit/importDeposit/index.js";
import usePrepaidAndDepositModule from "./ar/prepaid/index.js";

//bank
import bankModule from "./bank/index.js";

//admin
import adminFormTypeModule from "./admin/formType/index.js";
import adminFormDetailModule from "./admin/formDetail/index.js";
import reportMenuModule from "./admin/reportMenu/index.js";
import reportMenuDetailModule from "./admin/reportMenuDetail/index.js";

//gl
import glAccountingEntryModule from "./gl/accountingEntry/index.js";
import glAccountingReceiptModule from "./gl/accountingReceipt/index.js";
import glAccountingInvoiceModule from "./gl/accountingInvoice/index.js";
import glMeterToGlModule from "./gl/meterToGl/index.js";
import glPettyCashModule from "./gl/pettyCash/index.js";
import glCheckDocumentToGLModule from "./gl/checkBook/index.js";
import glPurchaseInvoiceModule from "./gl/purchaseInvoice/index.js";
import glClosingEntryModule from "./gl/closingEntry/index.js";
import glPurchaseReceiptModule from "./gl/purchaseReceipt/index.js";

//ap
import apWithholdingTaxModule from "./ap/withholdingTax/index.js";
import apPurchaseInvoiceModule from "./ap/purchaseInvoice/index.js";
import apPurchaseReceiptModule from "./ap/purchaseReceipt/index.js";
import apPurchaseRequisitionModule from "./ap/purchaseRequisition/index.js";
import apPurchaseOrderModule from "./ap/purchaseOrder/index.js";
//report
import reportModule from "./report/index.js";
import reportRequestsModule from "./report/requests/index.js";
import producesModule from "./report/produces/index.js";

//role
import roleRoleByDocumentBookModule from "./role/roleByDocumentBook/index.js";

//master
import masterProjectModule from "./master/project/index.js";
import masterAccountChartModule from "./master/accountChart/index.js";
import unitModule from "./master/unit/index.js";
import unitResidentModule from "./master/unitResident/index.js";
import masterProductModule from "./master/product/index.js";
import meterModule from "./master/meter/index.js";
import meterUsageSettingModule from "./master/meterUsageSetting/index.js";
import masterResidentModule from "./master/resident/index.js";
import masterUnitProductModule from "./master/unitProduct/index.js";
import arProductModule from "./master/product/index.js";
import bookBankModule from "./master/bookBank/index.js";
import masterBankModule from "./shared/bank/states.js";
import masterAccountBookModule from "./master/accountBook/index.js";
import policyModule from "./master/policy/index.js";
import customerGroupModule from "./master/customerGroup/index.js";
import masterProductApModule from "./master/productAP/index.js";
import supplierModule from "./master/supplier/index.js";
import accountingFrequentlyModule from "./master/accountingFrequently/index.js";
import accountingPolicyModule from "./master/accountingPolicy/index.js";
import phoneBookModule from "./master/phoneBook/index.js";
import noticePrintSettingModule from "./master/noticePrintSetting/index.js";
import controlStockKeycardModule from "./ar/stockKeycard/controlStockKeycard/index.js";
import importUnitResidentModule from "./master/unitResident/import/index.js";
import importUnitModule from "./master/unit/importUnit/index.js";
import masterImportResidentModule from "./master/resident/importResident/index.js";
import masterImportSupplierModule from "./master/supplier/importSupplier/index.js";
import masterSignatureModule from "./master/signatureManagement/signature/index.js";
import masterSignatureManagementModule from "./master/signatureManagement/signatureManagement/index.js";


//importExist
import importExistResidentModule from "./import/importExistResident/index.js";
import importExistUnitModule from "./import/importExistUnit/index.js";
import importExistUnitResidentModule from "./import/importExistUnitResident/index.js";
import importExistSupplierModule from "./import/importExistSupplier/index.js";

//asset
import assetAssetRegisterModule from "./asset/assetRegister/index.js";
import assetAssetGroupModule from "./asset/assetGroup/index.js";
import importAssetModule from "./asset/import/index.js";

//losa
import flashAddressModule from "./losa/flashAddress/index.js";

import uploadModule from "./upload/index.js";

import formVersionModule from "./admin/formVersion/index.js";

//meterUsage
import meterUsageModule from "./meter/meterUsage/index.js";

//documentBook
import documentBookModule from "./documentBook/index.js";

//penalty
import penaltyModule from "./penalty/index.js";

//debtFree
import debtFreeRequestModule from "./debtFree/request/index.js";
import debtFreeApproveModule from "./debtFree/approve/index.js";
import debtFreePrintModule from "./debtFree/print/index.js";
import debtFreeFollowUpModule from "./debtFree/followUp/index.js";

//dashboard
import dashboardMobileRegisterModule from "./dashboard/mobileRegister/index.js";
import dashboardModule from "./dashboard/dashboard/index.js";

//advertisement
import advertisementModule from "./advertisement/index.js";

//budget
import budgetModule from "./budget/index.js";

//paymentRequest
import paymentRequestTypeModule from "./paymentRequest/paymentRequestType/index.js";
import paymentRequestModule from "./paymentRequest/paymentRequest/index.js";

import router from "@/router/index.js";

export default createStore(Vuex.Store, {
	modules: initialStateModules(),
	state: initialStateRoot(),
	getters: {
		selectedLanguage(state) {
			return state.language;
		},
		selectedProject(state) {
			return state.selectedProject;
		},
		isProjectSelected(_, getters) {
			return !!getters.selectedProject;
		},
		viewportHeight(state) {
			return state.viewportHeight;
		},
		halfViewportHeight(_, getters) {
			return parseInt(getters.viewportHeight / 2);
		},
		threeQuarterViewportHeight(_, getters) {
			return parseInt(getters.viewportHeight * 0.75);
		},
		isLoading(state) {
			return state.isLoading;
		},
		employee(state) {
			return state.employee;
		},
		isEmployee(_, getters) {
			return !!getters.employee;
		},
		isPageDirty(state) {
			return state.isPageDirty;
		},
	},
	mutations: {
		setLanguage(state, payload) {
			state.language = payload.value;
		},
		setProject(state, payload) {
			state.selectedProject = payload;
		},
		setViewportHeight(state, payload) {
			state.viewportHeight = payload.value;
		},
		setLoading(state, payload) {
			state.isLoading = payload.value;
		},
		setEmployee(state, payload) {
			state.employee = payload;
		},
		setPageDirty(state, payload) {
			state.isPageDirty = payload.value;
		},
		resetStatesModules(state) {
			let modulesReset = {}
			for (const [key] of Object.entries(state)) {
				if (key != 'auth' && key != 'project') {
					modulesReset[key] = { self: true }
				}
			}

			this.reset(
				{
					self: false,
					nested: false,
					modules: modulesReset
				}
			);
			router.push('/select-project')
			// location.reload();
		}
	},
	actions: {
		setLanguage(context, payload) {
			localStorage.setItem("erp_selectedLanguage", payload.value);
			context.commit("setLanguage", { value: payload.value });
		},
		trySetLanguage(context) {
			const selectedLanguage = localStorage.getItem("erp_selectedLanguage");
			if (selectedLanguage) {
				context.commit("setLanguage", { value: selectedLanguage });
			}
		},
		resetStates(context) {
			//hooks
			context.dispatch("shared/resetAccounting");
			context.dispatch("shared/resetAccountingDetail");
			context.dispatch("shared/resetAccountingParent");
			context.dispatch("shared/resetAccountingFrequently");
			context.dispatch("shared/resetAPBook");
			context.dispatch("shared/resetBank");
			context.dispatch("shared/resetBookPurchase");
			context.dispatch("shared/resetBookBankAr");
			context.dispatch("shared/resetBuilding");
			context.dispatch("shared/resetCurrency");
			context.dispatch("shared/resetCustomer");
			context.dispatch("shared/resetCustomerGroup");
			context.dispatch("shared/resetDocumentBookDistinct");
			context.dispatch("shared/resetEmployee");
			context.dispatch("shared/resetFormPrint");
			context.dispatch("shared/resetGlBook");
			context.dispatch("shared/resetJobAdmin");
			context.dispatch("shared/resetLocation");
			context.dispatch("shared/resetNationality");
			context.dispatch("shared/resetProductAR");
			context.dispatch("shared/resetProductAP");
			context.dispatch("shared/resetProductGuarantee");
			context.dispatch("shared/resetProductUnit");
			context.dispatch("shared/resetRoleByBook");
			context.dispatch("shared/resetRoleByMenu");
			context.dispatch("shared/resetSupplier");
			context.dispatch("shared/resetTax");
			context.dispatch("shared/resetTaxBook");
			context.dispatch("shared/resetUnit");
			//other
			context.dispatch("bookBank/reseBookBank");
			context.dispatch("closingEntry/resetClosingEntry");
		},
		setProject(context, payload) {
			if (payload) {
				const project = {
					id: payload.id,
					code: payload.code,
					nameTh: payload.nameTh,
					nameEn: payload.nameEn,
					taxId: payload.taxId,
					branchId: payload.branchId,
					facilityBookingURL: payload.facilityBookingURL,
					announcementURL: payload.announcementURL,
				};
				localStorage.setItem("erp_selectedProject", JSON.stringify(project));
				//remove all relate localStorage
				const storages = Object.entries(localStorage);
				for (const storage of storages) {
					if (/erp_search*/.test(storage[0]) && storage[0] !== "erp_selectedLanguage") {
						localStorage.removeItem(storage[0]);
					}
				}
				context.commit("setProject", project);
				// reset state (hooks)
				// context.dispatch("resetStates"); 
				context.commit("resetStatesModules");
			} else {
				context.commit("setProject", null);
			}
		},
		setProjectList(context, payload) {
			const projectTextList = localStorage.getItem("erp_selectedProjectList");
			let projectList = []
			if (projectTextList) {
				projectList = JSON.parse(projectTextList);
			}
			const project = {
				id: payload.id,
				code: payload.code,
				nameTh: payload.nameTh,
				nameEn: payload.nameEn,
				taxId: payload.taxId,
				branchId: payload.branchId,
				facilityBookingURL: payload.facilityBookingURL,
				announcementURL: payload.announcementURL,
			};
			if (!(projectList.find(c => c.code === payload.code))) {
				projectList.push(project);
				localStorage.setItem("erp_selectedProjectList", JSON.stringify(projectList));
			}
			context.commit("setProject", project);
			context.commit("resetStatesModules");
		},
		async trySetProject(context, projectCode = null) {
			if (projectCode) {
				const projectTextList = localStorage.getItem("erp_selectedProjectList");
				if (projectTextList) {
					const projectList = JSON.parse(projectTextList);
					let project = projectList.find(c => c.code === projectCode)
					if (project) {
						context.commit("setProject", project);
					} else {
						if (projectCode == "home") {
							router.push('/select-project');
						} else {
							await context.dispatch("getProjectByCode", projectCode)
						}
					}
				}
			}
			// else {
			// 	const projectText = localStorage.getItem("erp_selectedProject");
			// 	if (projectText) {
			// 		const project = JSON.parse(projectText);
			// 		context.commit("setProject", project);
			// 	}
			// }
			context.dispatch("trySetEmployee");
		},
		async getProjectByCode(context, projectCode) {
			let url = process.env.VUE_APP_BACKEND_URL + `/Project/GetProjectByCode/${projectCode}`;

			url = encodeURI(url);

			const token = context.rootGetters.token;

			const response = await fetch(url, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token,
				},
			});

			const responseData = await response.json();

			if (!response.ok) {// console.log(responseData);
				let message = null;
				if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
					message = responseData.title;
					for (const key in responseData.errors) {
						message += ", " + key.replace("$.", "");
					}
				} else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
					message = responseData.message;
				}
				const error = new Error(message || "Failed to get employee");
				console.error(error);

				const isAuthenticated = context.rootGetters.isAuthenticated;
				if (isAuthenticated) {
					router.push('/select-project');
				} else {
					router.push('/login');
				}
			} else {
				const data = responseData.data;
				const projectTextList = localStorage.getItem("erp_selectedProjectList");
				const projectList = JSON.parse(projectTextList);
				projectList.push(data);
				localStorage.setItem("erp_selectedProjectList", JSON.stringify(projectList));
				await context.dispatch("trySetProject", data.code)
			}
		},
		setViewportHeight(context) {
			const viewportHeight = window.innerHeight;
			context.commit("setViewportHeight", { value: viewportHeight });
		},
		setLoading(context, payload) {
			context.commit("setLoading", payload);
		},
		async getEmployeeByCodeAndProject(context, payload) {
			let url = process.env.VUE_APP_BACKEND_URL + "/Employee/ByCodeAndProject";

			url = url + "?ProjectId=" + payload.projectId;
			url = url + "&EmployeeCode=" + payload.employeeCode;

			url = encodeURI(url);

			const token = context.rootGetters.token;

			const response = await fetch(url, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token,
				},
			});

			const responseData = await response.json();

			if (!response.ok) {
				// console.log(responseData);
				let message = null;
				if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
					message = responseData.title;
					for (const key in responseData.errors) {
						message += ", " + key.replace("$.", "");
					}
				} else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
					message = responseData.message;
				}
				const error = new Error(message || "Failed to get employee");
				throw error;
			}
			// console.log(responseData);
			const data = responseData.data;
			// localStorage.setItem("erp_employee", JSON.stringify(data));
			await context.dispatch("setEmployeeList", data)
		},
		setEmployeeList(context, payload) {
			const employeeTextList = localStorage.getItem("erp_employeeList");
			let employeeList = []
			if (employeeTextList) {
				employeeList = JSON.parse(employeeTextList);
			}
			if (!(employeeList.find(c => c.id === payload.id))) {
				employeeList.push(payload);
				localStorage.setItem("erp_employeeList", JSON.stringify(employeeList));
			}
			context.commit("setEmployee", payload);
		},
		async trySetEmployee(context) {
			const employeeTextList = localStorage.getItem("erp_employeeList");
			if (employeeTextList) {
				if (context.rootGetters.selectedProject) {
					const projectId = context.rootGetters.selectedProject.id;
					const employeeList = JSON.parse(employeeTextList);

					let employee = employeeList.find(c => c.projectId === projectId)
					if (employee) {
						context.commit("setEmployee", employee);
					} else {
						let payload = { projectId: projectId, employeeCode: context.rootGetters.userCode }
						await context.dispatch("getEmployeeByCodeAndProject", payload)
					}
				}
			}
			// else {
			// 	const empText = localStorage.getItem("erp_employee");
			// 	if (empText) {
			// 		const employee = JSON.parse(empText);
			// 		context.commit("setEmployee", employee);
			// 	}
			// }
		},
		setPageDirty(context, payload) {
			context.commit("setPageDirty", payload);
		},
	},
});


function initialStateRoot() {
	return {
		language: "TH",
		selectedProject: null,
		employee: null,
		viewportHeight: 0,
		isLoading: false,
		isPageDirty: false,
	}
}

function initialStateModules() {
	return {
		auth: authModule,
		shared: sharedModule,
		project: projectModule,
		arInvoice: arInvoiceModule,
		arPrepaidRefund: arPrepaidRefundModule,
		bank: bankModule,
		adminFormType: adminFormTypeModule,
		adminFormDetail: adminFormDetailModule,
		glAccountingEntry: glAccountingEntryModule,
		glAccountingReceipt: glAccountingReceiptModule,
		glAccountingInvoice: glAccountingInvoiceModule,
		glMeterToGl: glMeterToGlModule,
		glPettyCash: glPettyCashModule,
		apWithholdingTax: apWithholdingTaxModule,
		report: reportModule,
		reportRequests: reportRequestsModule,
		produces: producesModule,
		apPurchaseInvoice: apPurchaseInvoiceModule,
		apPurchaseReceipt: apPurchaseReceiptModule,
		apPurchaseRequisition: apPurchaseRequisitionModule,
		apPurchaseOrder: apPurchaseOrderModule,
		glCheckDocumentToGL: glCheckDocumentToGLModule,
		roleRoleByDocumentBook: roleRoleByDocumentBookModule,
		glPurchaseInvoice: glPurchaseInvoiceModule,
		masterProject: masterProjectModule,
		assetAssetRegister: assetAssetRegisterModule,
		assetAssetGroup: assetAssetGroupModule,
		flashAddress: flashAddressModule,
		upload: uploadModule,
		masterAccountChart: masterAccountChartModule,
		masterProduct: masterProductModule,
		meter: meterModule,
		meterUsageSetting: meterUsageSettingModule,
		unit: unitModule,
		unitResident: unitResidentModule,
		masterResident: masterResidentModule,
		masterUnitProduct: masterUnitProductModule,
		arProduct: arProductModule,
		bookBank: bookBankModule,
		masterBank: masterBankModule,
		formVersion: formVersionModule,
		meterUsage: meterUsageModule,
		reportMenu: reportMenuModule,
		reportMenuDetail: reportMenuDetailModule,
		masterAccountBook: masterAccountBookModule,
		arReceipt: arReceiptModule,
		arDeposit: arDepositModule,
		arNotice: arNoticeModule,
		arGuarantee: arGuaranteeModule,
		documentBook: documentBookModule,
		arCalculateInvoice: arCalculateInvoiceModule,
		penalty: penaltyModule,
		policy: policyModule,
		arCalculateNotice: arCalculateNoticeModule,
		arBank: arBankModule,
		arCreditNote: arCreditNoteModule,
		customerGroup: customerGroupModule,
		arRefundGuarantee: arRefundGuaranteeModule,
		productAp: masterProductApModule,
		debtFreeRequest: debtFreeRequestModule,
		debtFreeApprove: debtFreeApproveModule,
		debtFreePrint: debtFreePrintModule,
		debtFreeFollowUp: debtFreeFollowUpModule,
		supplier: supplierModule,
		closingEntry: glClosingEntryModule,
		accountingFrequently: accountingFrequentlyModule,
		dashboardMobileRegister: dashboardMobileRegisterModule,
		accountingPolicy: accountingPolicyModule,
		phoneBook: phoneBookModule,
		advertisement: advertisementModule,
		dashboard: dashboardModule,
		glPurchaseReceipt: glPurchaseReceiptModule,
		noticePrintSetting: noticePrintSettingModule,
		noticeCustomReport: noticeCustomReportModule,
		arReceiptBillPayment: arReceiptBillPaymentModule,
		transferSlip: transferSlipModule,
		arStockKeycard: arStockKeycardModule,
		controlStockKeycard: controlStockKeycardModule,
		importStockKeycard: importStockKeycardModule,
		importUnitResident: importUnitResidentModule,
		importUnit: importUnitModule,
		importExistResident: importExistResidentModule,
		importExistUnit: importExistUnitModule,
		importResident: masterImportResidentModule,
		importExistUnitResident: importExistUnitResidentModule,
		importExistSupplier: importExistSupplierModule,
		importSupplier: masterImportSupplierModule,
		importDeposit: arImportDepositModule,
		importAsset: importAssetModule,
		budget: budgetModule,
		usePrepaidAndDeposit: usePrepaidAndDepositModule,
		signature: masterSignatureModule,
		signatureManagement: masterSignatureManagementModule,
		paymentRequestType: paymentRequestTypeModule,
		paymentRequest: paymentRequestModule,
	}
}