<template>
	<PvAutoComplete
		:dropdown="true"
		:suggestions="filteredProductUnits"
		field="codeAndName"
		@complete="searchProductUnit"
		@dropdown-click="filteredProductUnits"
		forceSelection
	/>
</template>

<script>
import useGetProductUnit from "../../../hooks/getProductUnit.js";
export default {
	setup() {
		const { filteredProductUnits, searchText, isSharedProductUnitReady, searchProductUnit } =
			useGetProductUnit();

		return {
			filteredProductUnits,
			searchText,
			isSharedProductUnitReady,
			searchProductUnit,
		};
	},
	emits: ["load-finish"],
	watch: {
		isSharedProductUnitReady(curValue) {
			if (curValue) {
				this.$emit("load-finish");
			}
		},
	},
};
</script>
