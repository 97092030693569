export default {
	accountingTitle: "Accounting to GL",
	receiptTitle: "Receipt To GL",
	invoiceTitle: "Invoice To GL",
	columnStatus: "Status",
	columnIsCancel: "Canceled",
	columnCode: "Code",
	columnDate: "Date",
	columnPeriod: "Period",
	columnDescription: "Description",
	columnTotal: "Total",
	columnApprove: "Approve",
	columnType: "Type",
	changeGlBook: "Change Book",
	selectGlBook: "Select Book",
	code: "Code",
	name: "Name",
	approveName: "Approve Name",
	accountingFrequently: "Accounting Frequently",
	columnManageRow: "Manage Row",
	debit: "Debit",
	credit: "Credit",
	totalDebit: "Total Debit",
	totalCredit: "Total Credit",
	diff: "Difference",
	createBy: "Create By",
	createDate: "Create Date",
	updateBy: "Update By",
	updateDate: "Update Date",
	printLot: "Print Lot",
	downloadPDF: "Download PDF",
	validateCodeStart: "Please enter code start.",
	validateCodeEnd: "Please enter code end.",
	voucher: "Voucher",
	invoiceSelect: "Choose a book that is not related to Invoice.",
	receiptSelect: "Choose a book that is not related to Receipt.",
	documentBookCode: "Document Book",
	lockStatus: "Lock",
	unlockStatus: "Unlock",
	unlockSuccessTitle: "Unlock Success",
	lockSuccessTitle: "Lock Success",
	titleLockDialog: "Lock Document",
	messageLockDialog: "You want to lock the document.<br>To prohibit editing of the details of this document?",
	titleUnlockDialog: "Unlock Document",
	messageUnlockDialog: "You want to Unlock the document.<br>To prohibit editing of the details of this document?",
	validateAccountDetail : "There are non-parent accounts missing a parent account. Please update the chart of accounts before proceeding.",
	gotoAccounting: "Go to Accounting"
};
