<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.5 7H7.5C6.948 7 6.5 7.448 6.5 8V9C6.5 9.552 6.948 10 7.5 10H16.5C17.052 10 17.5 9.552 17.5 9V8C17.5 7.448 17.052 7 16.5 7Z"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.591 7L16.587 5.008C17.221 3.74 15.904 2.381 14.617 2.975L12 4.183L9.383 2.975C8.096 2.381 6.779 3.739 7.413 5.008L8.409 7"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.75001 10L6.03301 12.747C4.81201 14.701 4.93701 17.209 6.34701 19.032V19.032C7.38901 20.38 8.99701 21.168 10.701 21.168H13.3C15.003 21.168 16.611 20.379 17.653 19.032V19.032C19.064 17.209 19.189 14.701 17.968 12.746C17.423 11.873 16.825 10.917 16.251 9.998"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
