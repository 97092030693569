export default {
	formVersions(state) {
		return state.formVersions;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	selectedFormVersion(state) {
		return state.selectedFormVersion;
	},
	formVersionNextPage(state) {
		return state.currentPage + 1;
	},
	formVersionIsLastPage(_, getters) {
		return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
	},
};
