export default {
	setCurrency(state, payload) {
		let currencies = [...state.currency.currencies];
		for (const currency of payload.currencies) {
			if (currencies.findIndex((c) => c.id === currency.id) < 0) {
				currencies.push(currency);
			}
		}
		state.currency.currencies = currencies;
		state.currency.currentPage = payload.currentPage;
		state.currency.totalPage = payload.totalPage;
	},
	resetCurrency(state) {
		const currency = {
			currencies: [],
			totalPage: 0,
			currentPage: 0,
		};
		state.currency = currency;
	},
};
