<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
    :header="title"
  >
    <PvDivider></PvDivider>
    <transition>
      <section class="flex flex-wrap fontlabel" :class="textFormClass">
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="project">{{ lang.project }}</label>
          <PvSkeleton
            v-if="isProjectLoading"
            class="inputfield w-full mt-2"
            borderRadius="8px"
            height="3rem"
          />
          <PvAutoComplete
            id="category"
            class="inputfield w-full mt-2"
            :suggestions="filteredProjects"
            :field="fieldCodeAndName"
            :dropdown="true"
            @complete="searchProject($event)"
            @dropdown-click="searchProject($event)"
            v-model="selectedProject"
            forceSelection
            :placeholder="lang.validateProject"
            :class="{ 'p-invalid': projectError }"
            @item-select="projectError = false"
            v-else
          />
          <small v-if="projectError" id="project-help" class="p-error">{{
            lang.validateProject
          }}</small>
        </div>

        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="menu">{{ lang.menu }}</label>
          <PvDropdown
            id="menu"
            class="inputfield w-full mt-2"
            :options="menus"
            optionLabel="codeAndName"
            
            v-model="selectedMenu"
            :showClear="clearMenu"
            :placeholder="lang.validateMenu"
            :class="{ 'p-invalid': menuError }"
            @change="menuError = false"
          />
          <small v-if="menuError" id="menu-help" class="p-error">{{
            lang.validateMenu
          }}</small>
        </div>

        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="formVersion">{{ lang.formVersion }}</label>
          <div class="flex">
            <div
              v-for="formVersion of formVersions"
              :key="formVersion.code"
              class="field-radiobutton pt-3 mr-5"
            >
              <PvRadioButton
                class="mr-2"
                :inputId="formVersion.code"
                name="formVersion"
                :value="formVersion.code"
                v-model="selectedFormVersion"
              />
              <label :for="formVersion.code">{{ formVersion.name }}</label>
            </div>
          </div>
        </div>
      </section>
    </transition>

    <template #footer>
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog"
      />
      <PvButton :label="lang.save" icon="pi pi-check" @click="save" />
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetProject from "../../../hooks/getProject.js";
export default {
  // props: ["id", "projectCode"],
  props: {
    data: {
      type: Object,
    },
    openFrom : {
      type: String,
    }
  },
  emits: ["close"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();

    const { formatDate, formatDateToISOString } = useFormat();

    const {
      isSharedProjectReady,
      getProjectById,
      searchText,
      filteredProjects,
    } = useGetProject();

    const store = useStore();

    const fieldCodeAndName = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return "codeAndNameTh";
      } else {
        return "codeAndNameEn";
      }
    });

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.admin.formVersion,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.admin.formVersion,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatDate,
      formatDateToISOString,
      isSharedProjectReady,
      getProjectById,
      searchText,
      filteredProjects,
      fieldCodeAndName,
    };
  },
  data() {
    return {
      isProjectLoading: true,
      selectedProject: null,

      menus: [
        {
          name: this.lang.menuInvoice,
          code: "Invoice",
          codeAndName: "Invoice" + " : " + this.lang.menuInvoice,
        },
        {
          name: this.lang.menuNotice,
          code: "Notice",
          codeAndName: "Notice" + " : " + this.lang.menuNotice,
        },
        {
          name: this.lang.menuReceipt,
          code: "Receipt",
          codeAndName: "Receipt" + " : " + this.lang.menuReceipt,
        },
        {
          name: this.lang.menuCreditNote,
          code: "CreditNote",
          codeAndName: "CreditNote" + " : " + this.lang.menuCreditNote,
        },
        {
          name: this.lang.menuDeposit,
          code: "Deposit",
          codeAndName: "Deposit" + " : " + this.lang.menuDeposit,
        },
        {
          name: this.lang.menuDeptfree,
          code: "Deptfree",
          codeAndName: "Deptfree" + " : " + this.lang.menuDeptfree,
        },
        {
          name: this.lang.menuDeptfreeRequest,
          code: "DeptfreeRequest",
          codeAndName:
            "DeptfreeRequest" + " : " + this.lang.menuDeptfreeRequest,
        },
      ],
      selectedMenu: null,
      clearMenu: false,

      formVersions: [
        { name: this.lang.jasperReport, code: "Y" },
        { name: this.lang.fastReport, code: "N" },
      ],
      selectedFormVersion: null,

      projectError: false,
      menuError: false,

      dataFormVersion: {},
    };
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.initiateData();
      this.$emit("close");
    },
    searchProject(event) {
      this.searchText = event.query;
    },
    checkShouldSetData() {
      if(this.data && !this.isProjectLoading) {
        this.setData();
      }

      if(this.data == null && !this.isProjectLoading){
        this.initiateData();
      }
    },
    initiateData() {
      this.selectedProject = null;
      this.selectedMenu = null;
      this.selectedFormVersion = "Y";
      this.projectError = false;
      this.menuError = false;
      this.$store.dispatch("formVersion/resetSelectedFormVersion");
      this.setLoading(false);
    },
    setData() {
      if(this.data) {
        this.selectedProject = this.filteredProjects.find((project) => project.id === this.data.projectId);
        this.selectedMenu = this.menus.find((menu) => menu.code === this.data.menuCode);
        this.selectedFormVersion = this.data.isJasperReport;
      }

      this.setLoading(false);
    },
    // async getFormVersion() {
    //   this.setLoading(true);
    //   const where = {
    //     id: this.id,
    //   };

    //   await this.$store.dispatch("formVersion/getFormVersion", {
    //     where,
    //   });

    //   this.dataFormVersion =
    //     this.$store.getters["formVersion/selectedFormVersion"];

    // //   console.log("dataFormVersion", this.dataFormVersion);

    //   this.setData(this.dataFormVersion);

    //   this.setLoading(false);
    // },
    validateData() {
      if (!this.selectedProject) {
        this.projectError = true;
        return true;
      }

      if (!this.selectedMenu) {
        this.menuError = true;
        return true;
      }

      return false;
    },
    async save() {
      if (this.validateData()) {
        this.setLoading(false);
        return;
      }

      this.setLoading(true);

      try {
        // console.log("save", this.payload);
        await this.$store.dispatch("formVersion/createOrUpdateFormVersion", this.payload);

        this.$toast.add({
          severity: "success",
          summary: this.lang.saveSuccess,
          detail: "",
          life: 5000,
        });
        
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 5000,
        });
      }

      this.setLoading(false);
      this.closeDialog();
    },
  },
  computed: {
    payload() {
        let payload = {
            projectId: this.selectedProject.id,
            menuCode: this.selectedMenu.code,
            menuName: this.selectedMenu.name,
            isJasperReport: this.selectedFormVersion,
            type: "Add",
        }

        if (!this.isCreateDocument) {
            payload = { ...payload, id: this.data.id, type: "Update" };
        }

        return payload;
    },
    isCreateDocument() {
      return this.data === null;
    },
    title() {
      let result = this.lang.editFormVersion;
      if (this.isCreateDocument) {
        result = this.lang.addFormVersion;
      }
      return result;
    },
  },
  watch: {
    isSharedProjectReady() {
      this.isProjectLoading = false;
      this.checkShouldSetData();
    },
    // id(curValue) {
    // //   console.log("id", curValue);
    //   if (curValue === "create") this.initiateData();
    //   else this.getFormVersion();
    // },
    openFrom() {
      this.setLoading(true);
      // const Check = curValue.indexOf("create");
      this.checkShouldSetData();
      // if (Check < 0) {
      //   this.checkShouldSetData();
      // } else if (Check >= 0) {
      //   this.checkShouldSetData();
      // }
    },
  },
};
</script>
