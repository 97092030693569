export default {
	setReportMenu(state, payload) {
		state.reportMenu = payload.data;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
	setSelectedReportMenu(state, payload) { 
		state.selectedReportMenu = payload;
	},
	setReportMenuDetail(state, payload) {
		let reportMenuDetails = [...state.reportMenuDetail];
		for (const reportMenuDetail of payload.data) {
			if (reportMenuDetails.findIndex((c) => c.id === reportMenuDetail.id) < 0) {
				reportMenuDetails.push(reportMenuDetail);
			}
		}
		state.reportMenuDetail = reportMenuDetails;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
	setPeriod(state, payload) {
		state.periods = payload.data;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
	setBlob(state, payload) {
		state.blob = payload;
	},
	setSelectedMenuDetail(state) {
		state.reportMenuDetail = [];
		state.totalPage = 0;
		state.currentPage = 0;
	},
};
