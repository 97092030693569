<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '70vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="titleHeaderImportData"
      :wordingConfirm="titleValidateImportData"
      :dataButtons="dataButtonDialog"
      @close="showValidateDialog = false"
    ></base-dialog>

    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">{{ lang.uploadFile }}</label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          :disabled="shouldDisableCloseButton"
          @click="closeDialog"
        />
      </div>
    </template>

    <div class="dropZone">
      <input
        type="file"
        id="dropZone-file"
        class="dropZone-file"
        ref="dropZoneFile"
        @click="resetFile"
        @change="handleFiles"
        multiple
        accept=".xlsx"
      />
      <div
        class="dropZone-wrapper"
        @dragenter.prevent=""
        @dragover.prevent=""
        @drop.prevent="handleFiles"
      >
        <label for="dropZone-file" class="cursor-pointer">
          <div class="pt-6"></div>
          <PvButton
            class="cursor-pointer p-button-rounded show-icon"
            disabled="disabled"
          >
            <icon-cloud-storage-upload iconColor="white" />
          </PvButton>
          <div class="cursor-pointer dropZone-label-text pt-4">
            <label
              for="dragFile"
              class="cursor-pointer font-md p-text-secondary"
              >{{ lang.dragFile }}</label
            >
            <label
              for="addFile"
              class="cursor-pointer font-md p-text-primary"
              @click="addFile"
              >{{ lang.addFile }}</label
            >
            <input
              id="add-file"
              type="file"
              style="display: none"
              accept=".xlsx"
              @click="resetFile"
              @change="handleFiles"
              class="cursor-pointer"
            />
          </div>
          <div class="cursor-pointer font-xs p-text-secondary pt-2 pb-8">
            <label for="importFileDesc" class="cursor-pointer">{{
              lang.importFileDesc
            }}</label>
            <label
              for="downloadfile"
              class="cursor-pointer p-text-primary"
              @click="downloadfile"
              >{{ lang.downloadfile }}</label
            >
          </div>
        </label>
      </div>
    </div>

    <div class="my-5 pt-6">
      <PvDataTable
        :value="files"
        dataKey="id"
        responsiveLayout="scroll"
        class="p-datatable-xs"
        :class="textTableClass"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn style="width: 87px; height: 56px" headerClass="h-center">
          <template #body>
            <PvButton
              v-if="isOpen"
              type="button"
              style="background: white; border: white; opacity: 1"
              disabled="disabled"
            >
              <icon-documents-file-checkmark iconColor="secondary" />
            </PvButton>
          </template>
        </PvColumn>

        <PvColumn
          field="detailName"
          :header="lang.fileName"
          style="min-width: 12rem"
          headerClass="h-left font-sm font-normal"
          bodyClass="text-left font-normal p-text-primary"
        >
        </PvColumn>

        <PvColumn
          style="width: 2rem"
          headerClass="h-center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="">
            <PvButton
              v-if="isOpen"
              type="button"
              class="p-button-danger"
              style="background: white; border: white"
              @click="deleteFile"
            >
              <icon-trash-delete-bin iconColor="error" />
            </PvButton>
          </template>
        </PvColumn>
      </PvDataTable>
    </div>

    <div class="flex justify-content-center pt-4">
      <PvButton class="w-3" @click="uploadFile" :disabled="checkDisable">
        <span class="w-full text-center font-md font-normal p-button-label">
          <i
            v-if="isUpLoading"
            class="pi pi-spin pi-spinner font-normal mr-1"
          ></i>
          {{ lang.uploadFile }}
        </span>
      </PvButton>
    </div>
    <template #footer></template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import useImportExcel from "../../../hooks/importExcel.js"
import useGetImportExistUnit from '../../../hooks/getImportExistUnit.js';
import useGetImportExistResident from '../../../hooks/getImportExistResident.js';
import useGetImportExistUnitResident from '../../../hooks/getImportExistUnitResident.js';
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  emits: ["close", "data-upload"],
  setup(props, context) {
    const { lang } = useLang();
    const { textTableClass } = useTextStyle();
    const { formatDate, formatDateToISOString } = useFormat();
    const { validateFileCount, validateFileType, readFileContent} = useImportExcel();
    const { setImportExistUnitParams, loadImportExistUnit, getImportExistUnit } = useGetImportExistUnit();
    const { setImportExistResidentParams, loadImportExistResident, getImportExistResident } = useGetImportExistResident();
    const { setImportExistUnitResidentParams, loadImportExistUnitResident, getImportExistUnitResident } = useGetImportExistUnitResident();
    const store = useStore();
    const router = useRouter();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.validateImport,
          ...lang.th.master.unit.import,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.validateImport,
          ...lang.en.master.unit.import,
        };
      }
    });

    let files = ref([]);
    let isOpen = ref(false);
    let file = ref(null);
    let isUpLoading = ref(false);
    let uploadFiles = ref([]);
    let titleHeaderImportData = ref("");
    let titleValidateImportData = ref("");
    let showValidateDialog = ref(false);
    let columnsArray = ref([]);

    let rawDataForValidate = ref([]);
    let importUnitByCodes = ref([]);
    let importCustomerByCodes = ref([]);
    let importUnitResidentByCodes = ref([]);


    // function
    const setLoading = (bool) => {
      isUpLoading.value = bool;
    };

    const initiateData = () => {
      files.value = [];
      isOpen.value = false;
      file.value = null;
      titleHeaderImportData.value = mergeLang.value.notificationDialogHeader;
      importUnitByCodes.value = [];
      importCustomerByCodes.value = [];
      importUnitResidentByCodes.value = [];
      rawDataForValidate.value = [];
    };

    const closeDialog = () => {
      context.emit("close");
      initiateData();
    };

    const resetFile = (event) => {
      if (event.target.files.length > 0) {
        event.target.value = null;
      }
    };
    
    const getImportUnitResidentForm = async () => {
      try {
        const d = new Date();
        let dateText = d.toISOString();
        let payload = {
          key: `${process.env.VUE_APP_IMPORT_UNIT_RESIDENT_TEMPLATE_S3_KEY}`,
          name: `import_unit_resident_form_${dateText}.xlsx`,
        };
        await store.dispatch("upload/downloadFile", payload);
      } catch (error) {
        titleValidateImportData.value = error;
        showValidateDialog.value = true;
        setLoading(false);
      }
    };

    const downloadfile = async () => {
      setLoading(true);
      await getImportUnitResidentForm();
      setLoading(false);
    };

    const addFile = () => {
      document.getElementById("add-file").click();
    };

    const deleteFile = () => {
      initiateData();
    };    

    const handleFiles = async (event) => {
      const inputValue = event.target.files || event.dataTransfer.files;
      uploadFiles.value = Array.from(inputValue);

      //เช็คจำนวนไฟล์
      const checkFileCount = validateFileCount(uploadFiles.value, 1);
      if (checkFileCount.isError) {
        titleHeaderImportData.value = mergeLang.value.validateLimitFile;
        titleValidateImportData.value = mergeLang.value.validateFileType.replace(":name", checkFileCount.message),
        showValidateDialog.value = true;
        return;
      }

      //เช็คประเภทไฟล์
      file.value = inputValue[0];
      const checkFileType = validateFileType(file.value);
      if (checkFileType.isError) {
        titleHeaderImportData.value = mergeLang.value.validateImportFile;
        titleValidateImportData.value = mergeLang.value.validateFileType.replace(":name", checkFileType.message);
        showValidateDialog.value = true;
        file.value = null;
        return;
      }
       
      isOpen.value = true;
      const fileName = file.value.name;
      files.value = [
        {
          detailName: fileName,
        },
      ];
    };

    const uploadFile = async () => {
      setLoading(true);
      if (file.value) {
        let condition = [
          {
            //เลขที่ยูนิต/ห้อง*
            name: "unitCode",
            type: "string",
            wordLength: 25,
            isRequire: true,
          },
          {
            //รหัสสมาชิก/ลูกค้า*
            name: "customerCode",
            type: "string",
            wordLength: 100,
            isRequire: true,
          },
          {
            //ประเภทการอยู่อาศัย
            name: "residentType",
            type: "string",
            wordLength: 100,
            isRequire: true,
          },
          // {
          //   //วันนัดรับมอบ
          //   name: "appointmentDate",
          //   type: "date",
          //   isRequire: false,
          // },
          // {
          //   //วันที่โอน
          //   name: "transferDate",
          //   type: "date",
          //   isRequire: false,
          // },
          // {
          //   //วันที่สิ้นสุดรับประกัน
          //   name: "warrantyEndDate",
          //   type: "date",
          //   isRequire: false,
          // },
          {
            //วันที่เข้าอยู่
            name: "stayDate",
            type: "date",
            isRequire: false,
          },
          {
            //วันที่ย้ายออก
            name: "moveDate",
            type: "date",
            isRequire: false,
          },
          // {
          //   //การเข้าพักอาศัย
          //   name: "living",
          //   type: "string",
          //   wordLength: 100,
          //   isRequire: false,
          // }
        ];
        
        // console.log("file.value=",file.value);
        // console.log("condition=",condition);
        try {
          const maxRow = 20000;
          let result = await readFileContent(file.value, 2, maxRow, condition);  
          if(!result.success){
            if(result.code == "001"){
              titleHeaderImportData.value = mergeLang.value.notificationDialogHeader;
              titleValidateImportData.value = mergeLang.value.validateImportNoData.replace(":file", file.value.name);              
            }else if(result.code == "002"){
              titleHeaderImportData.value = mergeLang.value.notificationDialogHeader;
              titleValidateImportData.value = mergeLang.value.validateImportMaxData.replace(":maxData", maxRow);
            }
            showValidateDialog.value = true;
            setLoading(false);
            return true;
          }          
          rawDataForValidate.value = result.data;
          await validateAndPrepareData();
          setLoading(false);
        } catch (error) {
          titleHeaderImportData.value = mergeLang.value.notificationDialogHeader;
          titleValidateImportData.value = error;
          showValidateDialog.value = true;
          setLoading(false);
          return true;
        }

      }

    };

    const validateAndPrepareData = async () => {
      await getUnitImportByCode();
      await getCustomerImportByCode();
      await getUnitResidentImportByCode();
      if (isUpLoading.value) {
        // console.log("importUnitByCodes.value=",importUnitByCodes.value);
        // console.log("importCustomerByCodes.value=",importCustomerByCodes.value);
        // console.log("importUnitResidentByCodes.value=",importUnitResidentByCodes.value);
        await prepareData();
      }
      return;
    };

    const getUnitImportByCode = async () => {
        setImportExistUnitParams(rawDataForValidate.value,"unitCode");
        await loadImportExistUnit("unitCode");
        importUnitByCodes.value = getImportExistUnit();
    };
    
    const getCustomerImportByCode = async () => {      
      setImportExistResidentParams(rawDataForValidate.value,"customerCode");
      await loadImportExistResident();
      importCustomerByCodes.value = getImportExistResident();
    };
    
    const getUnitResidentImportByCode = async () => {      
      setImportExistUnitResidentParams(rawDataForValidate.value,"unitCode");
      await loadImportExistUnitResident();
      importUnitResidentByCodes.value = getImportExistUnitResident();
    };

    const prepareData = async () => {
      let dataResult = [];
      let i = 1;
      for (const data of rawDataForValidate.value) {
        let unitResident = data;
        unitResident.importStatusCode = "wait";
        unitResident.importStatusReason = "";
        unitResident.importStatusDescription = "";
        unitResident.itemNo = i;
        unitResident.markStatus = "";
        
        let unit = importUnitByCodes.value.find(e => e.code == unitResident.unitCode);
        if(!unit){ //ไม่พบ unitCode ใน DB  
          let messageError = {field: 'unitCode', type: 'doNotExist'};
          unitResident.error.push(messageError);
        } else {
          unitResident.unitId = unit.id
        }

        let customer = importCustomerByCodes.value.find(e => e.code == unitResident.customerCode);
        if(!customer){ //ไม่พบ unitCode ใน DB  
          let messageError = {field: 'customerCode', type: 'doNotExist'};
          unitResident.error.push(messageError);
        } else {
          unitResident.customerId = customer.id;
        }       

        if(unitResident.residentType){
          let unitResidentTypes = ["ผู้เช่า","ผู้พักอาศัย"];
          let residentTypes = [...["เจ้าของที่ 2","เจ้าของที่ 3"],...unitResidentTypes];
          if(!residentTypes.includes(unitResident.residentType)){
            let messageError = {field: 'residentType', type: 'doNotExist'};
            unitResident.error.push(messageError);
          }

          if(!unitResident.stayDate && (unitResident.residentType == unitResidentTypes[0] || unitResident.residentType == unitResidentTypes[1])){
            let messageError = {field: 'stayDate', type: 'format'};
            unitResident.error.push(messageError);
          }
          
          if(unitResidentTypes.includes(unitResident.residentType)) {
            let livingType = "" ;
            if(unitResident.residentType == unitResidentTypes[0]){
              livingType = "Rential" ;
            } 
            else if( unitResident.residentType == unitResidentTypes[1]){
              livingType = "Stay" ;
            }

            if(!unitResident.stayDate){
              let messageError = {field: 'stayDate', type: 'format'};
              unitResident.error.push(messageError);
            }

            if(!unitResident.moveDate && unitResident.stayDate){
              let checkStayDateError = unitResident.error.find(e => e.field == "stayDate");
              if(!checkStayDateError){
                unitResident.moveDate  = unitResident.stayDate;
              }
            }
            let importUnitResident = importUnitResidentByCodes.value.find(e => e.unitCode == unitResident.unitCode 
                && e.customerCode == unitResident.customerCode
                && e.livingType == livingType
                && formatDate(e.livingStartDate) == formatDate(unitResident.stayDate)
                );
            if(importUnitResident){
              let messageError = {field: 'unitResidentDuplicate', type: 'duplicateInDatabase'};
              unitResident.error.push(messageError);
            }

            // if(formatDate(unitResident.stayDate) > formatDate(unitResident.moveDate)){
            //   let messageError = {field: 'stayDateMorethanMoveDate', type: 'stayDateMorethanMoveDate'};
            //   unitResident.error.push(messageError);
            // }

          }
        }

        
        if (data.error.length > 0) {
          unitResident.importStatusCode = "invalid";
          unitResident.importStatusDescription = data.error[0].field;
          let errorType = data.error[0].type

          switch (data.error[0].type) {
            case "blank":
              errorType = mergeLang.value.validateBlank.replace(":field", setFieldLangError(data.error[0].field));
              break;
            case "duplicate":
              errorType = mergeLang.value.validateDuplicateInFile.replace(":field", setFieldLangError(data.error[0].field));
              break;              
            case "duplicateInDatabase":
              errorType = mergeLang.value.validateDuplicateInDatabase.replace(":field",setFieldLangError(data.error[0].field)).replace(":table", mergeLang.value.residentInformation);
              break;
            case "overflow":
              errorType = mergeLang.value.validateLength.replace(":field", setFieldLangError(data.error[0].field)).replace(":length", data.error[0].wordLength);
              break;              
            case "doNotExist":
              errorType = mergeLang.value.validateDataNotFound.replace(":field", setFieldLangError(data.error[0].field));
              break;
            case "invalid":
              errorType = mergeLang.value.validateType.replace(":field", setFieldLangError(data.error[0].field));
              break;
            case "invalidDecimal":
              errorType = mergeLang.value.validateDecimalFormat.replace(":field", setFieldLangError(data.error[0].field));
              break;
            case "format":
              errorType = mergeLang.value.validateDateFormat.replace(":field", setFieldLangError(data.error[0].field));
              break;              
            case "stayDateMorethanMoveDate":
              errorType = mergeLang.value.validateStayDateAndMoveDate;
              break;
          }
          unitResident.importStatusReason = errorType;
        }        
        
        dataResult.push(unitResident);
        i++;
      }
      // console.log("dataResult.value=",dataResult);
      await store.dispatch(
        "importUnitResident/importUnitResidents",
        dataResult
      );
      context.emit("data-upload");
      router.push({ name: "preview-unit-resident" });
    }
    
    const setFieldLangError = (field) => {
      switch (field) {
        case "unitCode":
          return mergeLang.value.unitCode;
        case "customerCode":
          return mergeLang.value.customerCode;
        case "residentType":
          return mergeLang.value.residentType;
        case "stayDate":
          return mergeLang.value.stayDate;
        case "moveDate":
          return mergeLang.value.moveDate;
        case "unitResidentDuplicate":
          return mergeLang.value.unitResidentDuplicate;
        default:
          return field;
      }
    };

    //***computed***
    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });

    const shouldDisableCloseButton = computed(() => {
      return isUpLoading.value;
    });

    const checkDisable = computed(() => {
      if (!file.value || isUpLoading.value) {
        return true;
      } else {
        return false;
      }
    });

    const dataButtonDialog = computed(() => {
      let result = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
      return result;
    });
    
    // const importUnitResidents = computed(() => {
    //   return store.getters["importUnitResident/importUnitResidents"];
    // });


    return {
      lang: mergeLang,
      textTableClass,
      formatDate,
      formatDateToISOString,

      // data
      files,
      isOpen,
      file,
      isUpLoading,
      showValidateDialog,
      titleHeaderImportData,
      titleValidateImportData,
      columnsArray,

      //function
      setLoading,
      closeDialog,
      uploadFile,
      resetFile,
      handleFiles,
      downloadfile,
      addFile,
      deleteFile,

      //computed
      selectedProject,
      shouldDisableCloseButton,
      checkDisable,
      dataButtonDialog,
    };
  },
};
</script>

<style scoped>
.show-icon {
  opacity: 1;
  width: 48px;
  height: 48px;
  padding: 0.75rem;
}
</style>
