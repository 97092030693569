<template>
  <div
    class="sticky bg-white z-5"
    :style="isNavigation && isMenuVisible ? { top: '56px' } : { top: '0px' }"
  >
    <PvToolbar>
      <template #start>
        <PvButton
          icon="pi pi-chevron-left"
          class="p-button-link p-icon-secondary"
          @click="goBack"
          v-if="hideBackButton"
        ></PvButton>
        <label class="p-button-label font-md">{{ title }}</label>
      </template>

      <template #end>
        <div v-for="dataButton in dataButtons" :key="dataButton.id">
          <PvButton
            v-if="dataButton.type == 'create'"
            class="button-toolbar p-button-outlined mr-2"
            @click="$emit(dataButton.action)"
            :disabled="dataButton.isDisable"
          >
            <icon-create iconColor="primary" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{ lang.createNew }}</span>
          </PvButton>

          <PvButton
            v-else-if="dataButton.type == 'save'"
            class="button-toolbar mr-2"
            @click="$emit(dataButton.action)"
            :disabled="dataButton.isDisable"
          >
            <icon-save iconColor="white" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{ lang.save }}</span>
          </PvButton>

          <PvButton
            v-else-if="dataButton.type == 'print'"
            class="button-toolbar mr-2"
            @click="$emit(dataButton.action)"
            :disabled="dataButton.isDisable"
          >
            <icon-printer iconColor="white" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{ lang.print }}</span>
          </PvButton>

          <PvButton
            v-else-if="dataButton.type == 'cancel'"
            class="button-toolbar p-button-outlined p-button-danger mr-2"
            @click="$emit(dataButton.action)"
            :disabled="dataButton.isDisable"
          >
            <icon-cancel iconColor="error" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{ lang.cancel }}</span>
          </PvButton>

          <PvButton
            v-else-if="dataButton.type == 'delete'"
            class="button-toolbar p-button-outlined p-button-danger mr-2"
            @click="$emit(dataButton.action)"
            :disabled="dataButton.isDisable"
          >
            <icon-trash-delete-bin iconColor="error" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{ lang.delete }}</span>
          </PvButton>

          <PvButton
            :class="dataButton.class"
            class="button-toolbar"
            v-else-if="dataButton.isShow"
            @click="$emit(dataButton.action)"
            :disabled="dataButton.isDisable"
            :loading="dataButton.isLoading"
          >
            <!-- <img
              :src="dataButton.icon"
              style="width: 16px"
              v-if="dataButton.icon == '' ? false : true"
            /> -->
            <div>
              <i
                v-if="dataButton.isLoading"
                class="pi pi-spin pi-spinner font-normal mr-1"
              />
              <icon-file-blank-copy-add-plus
                v-else-if="dataButton.icon == 'icon-file-blank-copy-add-plus'"
                :iconColor="dataButton.iconColor"
              />
              <icon-pen-edit
                v-else-if="dataButton.icon == 'icon-pen-edit'"
                :iconColor="dataButton.iconColor"
              />
              <icon-plus-add
                v-else-if="dataButton.icon == 'icon-plus-add'"
                :iconColor="dataButton.iconColor"
                width="16"
                height="16"
              />
              <icon-trash-delete-bin
                v-else-if="dataButton.icon == 'icon-trash-delete-bin'"
                :iconColor="dataButton.iconColor"
                width="16"
                height="16"
              />
              <icon-bell-notification-alarm
                v-else-if="dataButton.icon == 'icon-bell-notification-alarm'"
                :iconColor="dataButton.iconColor"
                width="16"
                height="16"
              />
              <icon-printer
                v-else-if="dataButton.icon == 'icon-printer'"
                :iconColor="dataButton.iconColor"
                width="16"
                height="16"
              />
              <i
                class="pi pi-check"
                v-else-if="dataButton.icon == 'pi-check'"
                :color="dataButton.iconColor"
              ></i>
              <icon-save
                v-else-if="dataButton.icon == 'icon-save'"
                :iconColor="dataButton.iconColor"
                width="16"
                height="16"
              />
              <icon-create
                v-else-if="dataButton.icon == 'icon-create'"
                :iconColor="dataButton.iconColor"
                width="16"
                height="16"
              />
              <icon-cancel
                v-else-if="dataButton.icon == 'icon-cancel'"
                :iconColor="dataButton.iconColor"
                width="16"
                height="16"
              />
              <icon-document-checkmark
                v-else-if="dataButton.icon == 'icon-document-checkmark'"
                :iconColor="dataButton.iconColor"
                width="16"
                height="16"
              />
              <icon-path
                v-else-if="dataButton.icon == 'icon-path'"
                :iconColor="dataButton.iconColor"
                width="16"
                height="16"
              />
              <icon-download-arrow
                v-else-if="dataButton.icon == 'icon-download-arrow'"
                :iconColor="dataButton.iconColor"
                width="16"
                height="16"
              />

              <icon-arrow-rotate
                v-else-if="dataButton.icon == 'icon-arrow-rotate'"
                :iconColor="dataButton.iconColor"
                width="16"
                height="16"
              />

              <icon-setting
                v-else-if="dataButton.icon == 'icon-setting'"
                :iconColor="dataButton.iconColor"
                width="16"
                height="16"
              />
              <icon-eye
                v-else-if="dataButton.icon == 'icon-eye'"
                :iconColor="dataButton.iconColor"
                width="16"
                height="16"
              />
              <icon-reject
                v-else-if="dataButton.icon == 'icon-reject'"
                :iconColor="dataButton.iconColor"
                width="16"
                height="16"
              />
              <span class="p-button-label font-normal ml-1">{{
                dataButton.caption
              }}</span>
            </div>
          </PvButton>
        </div>
      </template>
    </PvToolbar>
  </div>
</template>

<script>
import { computed, inject } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
export default {
  // props: ["title", "isShowGoToEdit", "isShowConfirm"],
  props: {
    title: {
      type: String,
    },
    dataButtons: {
      type: Array,
    },
    hideBackButton: {
      type: Boolean,
      default: true,
    },
  },
  // emits: [""],
  setup() {
    const { lang } = useLang();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
        };
      } else {
        return {
          ...lang.en.base,
        };
      }
    });
    const navigations = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return lang.th.navigation;
      } else {
        return lang.en.navigation;
      }
    });

    const isMenuVisible = inject("isMenuVisible");

    return {
      lang: mergeLang,
      navigations,
      isMenuVisible,
    };
  },
  data() {
    return {
      isNavigation: false,
    };
  },
  async created() {
    this.checkNavigation();
  },
  methods: {
    goBack() {
      this.$emit("goBack");
    },
    checkNavigation() {
      const navigation = this.navigations.find((nav) => nav.name === this.$route.name);
      if (navigation) this.isNavigation = true;
    },
    // gotoEdit() {
    //   console.log("gotoEdit");
    //   this.$emit("gotoEdit");
    // },
    // gotoSave() {
    //   console.log("save");
    //   this.$emit("gotoSave");
    // },
    // create() {
    //   console.log("create");
    //   this.$emit("gotoCreate");
    // },
  },
};
</script>

<style>
.p-toolbar-group-left,
.p-toolbar-group-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  width: 50%;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.button-toolbar {
  /* width: 129px; */
  height: 40px;
  margin-top: 2px;
  margin-bottom: 2px;
  display: flow-root;
}
.p-toolbar {
  height: 60px;
}
</style>

<!--
**ตัวอย่างข้อมูล**
dataButton: [
  //ไปหน้าแก้ไขข้อมูล
    {
      id: "editCaption",
      caption: this.lang.editCaption,
      class: "p-button-outlined p-button-primary mr-2",
      icon: "icon-pen-edit",
      iconColor: "primary",
      action: "gotoEdit",
      isShow: true,
    },
  //ยืนยันการแก้ไขข้อมูล (บันทึก/ยืนยัน)
    {
      id: "saveCaption",
      caption: this.lang.saveCaption,
      class: "mr-2",
      icon: "",
      iconColor: "",
      action: "gotoSave",
      isShow: true,
    },
  //เพิ่มข้อมูล (เพิ่ม/สร้าง)
    {
      id: "createCaption",
      caption: this.lang.createCaption,
      class: "mr-2",
      icon: "icon-plus-add",
      iconColor: "white",
      action: "gotoCreate",
      isShow: true,
    },
  //รายงาน
    {
      id: "reportCaption",
      caption: this.lang.reportCaption,
      class: "mr-2",
      icon: "icon-file-blank-copy-add-plus",
      iconColor: "white",
      action: "gotoReport",
      isShow: true,
    },
  //พิมพ์
  {
    id: "printCaption",
    caption: this.lang.printCaption,
    class: "mr-2",
    icon: "icon-file-blank-copy-add-plus",
    iconColor: "white",
    action: "gotoPrint",
    isShow: true,
  },
]-->
