<template>
  <base-toolbar :title="lang.title" @goBack="goBack" :hideBackButton="isHideBack"> </base-toolbar>
  <div></div>
  <div>
    <div class="pt-8 flex justify-content-center">
      <label class="font-bold font-7xl p-text-primary">
        {{ lang.reportProblem }}
      </label>
    </div>

    <div class="pt-6 flex justify-content-center">
      <label class="font-md font-normal">
        {{ lang.reportProblemDescription }}
      </label>
    </div>
  </div>

  <div class="flex">
    <div class="flex align-items-center justify-content-center col-4 pt-6 pl-8">
      <div class="responsive">
        <img class="responsive" :src="contactUS" />
      </div>
    </div>

    <div class="col-8 pl-8">
      <div class="pt-8">
        <div class="pt-8 pl-6">
          <label class="font-3xl font-bold">{{ lang.serviceTime }}</label>
        </div>
      </div>

      <div class="pt-6 pl-6">
        <label class="font-lg font-bold">{{ lang.setviceTimeDescription }}</label>
      </div>

      <div class="flex align-items-center pt-8 pl-6">
        <div class="flex col-5">
          <img class="pr-4" :src="lineLogo" />
          <label class="font-3xl font-bold">{{ lang.line }}</label>
        </div>
        <div class="col-7">
          <a class="font-3xl font-bold p-text-primary" href="#" @click="goToAddLine">
            {{ lang.lineAdd }}
          </a>
        </div>
      </div>

      <div class="flex align-items-center pt-6 pl-6">
        <div class="flex col-5">
          <img class="pr-4" :src="phoneLogo" />
          <label class="font-3xl font-bold">{{ lang.contactNumber }}</label>
        </div>
        <div class="col-7">
          <label class="font-3xl font-bold">{{ lang.tel }}</label>
        </div>
      </div>

      <div class="flex align-items-center pt-6 pl-6">
        <div class="flex col-5">
          <img class="pr-4" :src="emailLogo" />
          <label class="font-3xl font-bold">{{ lang.email }}</label>
        </div>
        <div class="col-7">
          <a :href="mailTo" class="font-3xl font-bold p-text">
            {{ lang.emailOfficial }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useLang from "../hooks/lang.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
export default {
  setup() {
    const { lang } = useLang();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.contactUs,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.contactUs,
        };
      }
    });

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,
      isHideBack,
    };
  },
  data() {
    return {
      contactUS: require("@/assets/images/contact-us.png"),
      lineLogo: require("@/assets/images/line.svg"),
      lineAdd: "https://page.line.me/lgx2880j?openQrModal=true",
      phoneLogo: require("@/assets/images/phone-call.svg"),
      emailLogo: require("@/assets/images/email.svg"),
      mailTo: "mailto:support@thelivingos.com",
    };
  },
  methods: {
    goToAddLine() {
      window.open(this.lineAdd);
    },
    goBack() {
      this.$router.push({name: "login"});
    },
  },
};
</script>

<style scoped>
.responsive {
  width: 100%;
  height: auto;
}
a {
  text-decoration: none;
}
</style>
