<template>
  <PvDialog
    :breakpoints="{ '1280px': '70vw', '640px': '50vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex flex-wrap justify-content-between align-items-right p-0">
        <div>
          <label class="font-lg">{{ lang.unitList }}</label>
        </div>
        <!-- <div>
          <PvButton
            icon="pi pi-times"
            class="p-button-link p-icon-secondary pb-0 pt-0"
            style="width: 15px"
            @click="closeDialog()"
          />
        </div> -->
      </div>
      <PvDivider></PvDivider>
      <section class="flex flex-wrap justify-content align-items-center">
        <div class="lg:col-6 md:col-6 col-6 p-0 mr-2">
          <span class="p-input-icon-left w-full">
            <i class="pi pi-search" />
            <PvInputText
              class="inputfield w-full"
              v-model="searchText"
              :placeholder="lang.keywordSearch"
              @keyup.enter="getUnits"
              autofocus
            />
          </span>
        </div>
        <PvButton @click="getUnits">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>
    </template>

    <div class="p-datatable-border my-2">
      <PvDataTable
        :value="unitList"
        v-model:selection="selectedUnit"
        selectionMode="single"
        dataKey="id"
        responsiveLayout="scroll"
        :scrollHeight="halfViewportHeight + 'px'"
        class="p-datatable-sm"
        :class="textTableClass"
        :loading="isLoading"
        @row-dblclick="selectUnit"
      >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
        <PvColumn
          selectionMode="single"
          bodyClass="text-center"
          style="min-width: 4rem; height: 56px"
        >
        </PvColumn>
        <PvColumn
          field="code"
          :header="lang.unitCode"
          style="min-width: 10rem; height: 56px"
          headerClass="h-left"
          bodyClass="text-left"
          sortable
        />
        <PvColumn
          field="addressNo"
          :header="lang.addressNo"
          style="min-width: 10rem; height: 56px"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="customerName"
          :header="lang.ownerName"
          style="min-width: 15rem; height: 56px"
          headerClass="h-left"
          bodyClass="text-left"
        />
      </PvDataTable>
    </div>

    <template #footer>
      <PvButton
        :label="lang.close"
        class="p-button-outlined p-button-secondary w-2 ml-3"
        @click="closeDialog"
      />
      <PvButton 
        :label="lang.confirm" 
        class="w-full mr-3 w-2" 
        @click="selectUnit" 
      />
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useGetUnit from "../../hooks/getUnit.js";
export default {
  props: {
    isSharedLoading: {
      type: Boolean,
    },
  },
  emits: ["close", "unit-selected"],
  setup() {
    const { lang } = useLang();
    const { textTableClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.master.unit.listing };
      } else {
        return { ...lang.en.base, ...lang.en.master.unit.listing };
      }
    });

    const { filteredUnits, isSharedUnitReady } = useGetUnit();

    return {
      lang: mergeLang,
      textTableClass,
      filteredUnits,
      isSharedUnitReady,
    };
  },
  mounted() {
    this.isLoading = true;
  },
  data() {
    return {
      selectedUnit: null,
      searchText: "",
      isLoading: false,
      filteredUnit: [],
      isSearch: false,
    };
  },
  methods: {
    initiateData() {
        this.selectedUnit = null;
        this.searchText = "";
        this.filteredUnit = [];
        this.isSearch = false;
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    getUnits() {
      if (this.searchText) {
        this.isLoading = true;
        this.filteredUnit = this.units.filter((unit) => {
          return (
            unit.code.toLowerCase().includes(this.searchText.toLowerCase()) || 
            unit.addressNo.toLowerCase().includes(this.searchText.toLowerCase()) ||
            unit.customerName.toLowerCase().includes(this.searchText.toLowerCase())
          );
        });
        this.isSearch = true;
        this.isLoading = false;
      } else {
        this.isSearch = false;
      }
      this.selectedUnit = null;
    },
    selectUnit() {
      this.$emit("unit-selected", this.selectedUnit);
    },
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    units() {
        return this.$store.getters["shared/unitUnits"];
    },
    unitList() {
        if(this.isSearch) {
            return this.filteredUnit;
        } else {
            return this.filteredUnits;
        }
    },
  },
  watch: {
    isSharedUnitReady(curValue) {
        if(curValue) {
            this.isLoading = false;
        }
    }
  }
};
</script>
