<template>
	<PvAutoComplete
		:dropdown="true"
		:suggestions="filteredPayTypes"
		field="name"
		@complete="searchPayType"
		@dropdown-click="filteredPayTypes"
		forceSelection
	/>
</template>

<script>
import useGetPayType from "../../hooks/getPaymentMethod.js";
export default {
	setup() {
		const {
			filteredPayTypes,
			searchText,
			type,
			isSharedPayTypeReady,
			searchPayType,
		} = useGetPayType();

		return {
			filteredPayTypes,
			searchText,
			type,
			isSharedPayTypeReady,
			searchPayType,
		};
	},
	emits: ["load-finish"],
	watch: {
		isSharedPayTypeReady(curValue) {
			// console.log("isSharedPayTypeReady", curValue);
			if (curValue) {
				this.$emit("load-finish");
			}
		},
	},
};
</script>
