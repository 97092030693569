<template>
  <base-container>
    <PvDialog
      :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      :modal="true"
      :closable="false"
      :header="lang.addLot"
      v-model:visible="showAddItemLotDialog"
    > 
      <transition>
        <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-10 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="addValueToCopy">{{ lang.addValueToCopy }}</label>
              <PvInputNumber
                id="valueToCopy"
                class="inputfield w-full mt-1"
                inputClass="text-right"
                v-model="valueToCopy"
                placeholder="0"
                @focus="$event.target.select()"
              />
            </div>
        </section>
      </transition>
      <template #footer>
        <PvButton
          :label="lang.close"
          icon="pi pi-times"
          class="p-button-text"
          @click="closeAddItemLotDialog"
        />
        <PvButton :label="lang.ok" icon="pi pi-check" @click="addItemLot" />
      </template>
    </PvDialog>

    <div class="flex justify-content-between align-items-center">
      <div class="my-3">
        <PvButton
          icon="pi pi-chevron-left"
          :label="lang.back"
          @click="goBack"
          class="mr-2 p-button-secondary"
        />
      </div>
      <h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
    </div>
    <transition name="fade" mode="out-in">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="pt-2 pb-1">
          <PvButton
            icon="pi pi-file"
            :label="lang.create"
            class="p-button-secondary mr-2 mb-2"
            @click="createDocument"
          />
          <PvButton
            icon="pi pi-save"
            :label="lang.save"
            class="mr-2 mb-2"
            @click="save"
          />
          <PvButton
            icon="pi pi-save"
            :label="lang.saveLot"
            class="mr-2 mb-2"
            :disabled="isCreateDocument"
            @click="saveLot"
          />
        </div>

        <div class="flex flex-wrap" :class="textFormClass">
          <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 flex flex-wrap">
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="code">{{ lang.code }}</label>
              <PvInputText
                id="code"
                v-model="code"
                type="text"
                class="inputfield w-full mt-1 p"
                :class="{ 'p-invalid': codeError }"
                @keypress="codeError = false"
              />
              <small v-if="codeError" id="code-help" class="p-error">{{
                lang.validateNoCode
              }}</small>
            </div>

            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
              <label for="type">{{ lang.type }}</label>
              <PvDropdown
                id="AccountType"
                class="inputfield w-full mt-1"
                :options="types"
                optionLabel="name"
                optionValue="code"
                v-model="selectType"
                :showClear="clearType"
                :placeholder="lang.typePlaceholder"
                @change="selectAssetType"
              />
            </div>

            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
              <label for="ownership">{{ lang.ownership }}</label>
              <PvDropdown
                id="ownership"
                class="inputfield w-full mt-1"
                :options="ownerships"
                optionLabel="name"
                optionValue="name"
                v-model="selectOwnership"
              />
            </div>

            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
              <label for="nameTh">{{
                lang.nameTh
              }}</label>
              <PvInputText
                id="nameTh"
                v-model="nameTh"
                type="text"
                class="inputfield w-full mt-1"
                :class="{ 'p-invalid': nameThError }"
                @keypress="nameThError = false"
              />
              <small v-if="nameThError" id="nameTh-help" class="p-error">{{
                lang.validateNoNameTh
              }}</small>
            </div>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
              <label for="nameEn">{{ lang.nameEn }}</label>
              <PvInputText
                id="nameEn"
                v-model="nameEn"
                type="text"
                class="inputfield w-full mt-1"
              />
            </div>

            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="assetAccount">{{
                lang.assetAccount
              }}</label>
              <base-accounting-detail-auto-complete
                id="assetAccount"
                v-model="selectedAssetAccount"
                class="inputfield w-full mt-1"
                @load-finish="assetAccountDataReady"
                :class="{ 'p-invalid': assetAccountError }"
                @item-select="assetAccountError = false"
                :isLoadingData="true"
                @is-inactive="selectedAssetAccount = null"
              />
              <small
                v-if="assetAccountError"
                id="assetAccount-help"
                class="p-error"
                >{{ lang.validateNoAssetAccountSelect }}</small
              >
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="depreAccount">{{
                lang.depreciationAccount
              }}</label>
              <base-accounting-detail-auto-complete
                id="depreAccount"
                v-model="selectedDepreAccount"
                class="inputfield w-full mt-1"
                :class="{ 'p-invalid': depreAccountError }"
                @item-select="depreAccountError = false"
                @is-inactive="selectedDepreAccount = null"
              />
              <small
                v-if="depreAccountError"
                id="depreAccount-help"
                class="p-error"
                >{{ lang.validateNoDepreAccountSelect }}</small
              >
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="accumAccount">{{
                lang.accumAccount
              }}</label>
              <base-accounting-detail-auto-complete
                id="accumAccount"
                v-model="selectedAccumAccount"
                class="inputfield w-full mt-1"
                :class="{ 'p-invalid': accumAccountError }"
                @item-select="accumAccountError = false"
                @is-inactive="selectedAccumAccount = null"
              />
              <small
                v-if="accumAccountError"
                id="accumAccount-help"
                class="p-error"
                >{{ lang.validateNoAccumAccountSelect }}</small
              >
            </div>
            <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
              <label for="activeStatus">{{ lang.status }}</label>
              <div class="mt-3 flex align-items-center ml-auto">
                <PvInputSwitch v-model="activeStatus" class="ml-2 mr-2" />
                <label for="activeStatus">{{ activeStatus ? lang.active : lang.inActive }}</label>
              </div>
            </div>
          </div>
          <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 flex flex-wrap">
            <PvDivider>
              <div class="flex align-items-center">
                <label style="font-size: 20px" class="p-button-label">{{
                  lang.purchaseInformation
                }}</label>
              </div>
            </PvDivider>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="taxInvoiceCode">{{
                lang.voucherCode
              }}</label>
              <PvInputText
                id="taxInvoiceCode"
                v-model="taxInvoiceCode"
                type="text"
                class="inputfield w-full mt-1 p"
                :class="{ 'p-invalid': taxInvoiceCodeError }"
                @keypress="taxInvoiceCodeError = false"
              />
              <small
                v-if="taxInvoiceCodeError"
                id="taxInvoiceCode-help"
                class="p-error"
                >{{ lang.validateNoVoucherCode }}</small
              >
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="buyDate">{{ lang.buyDate }}</label>
              <PvCalendar
                id="buyDate"
                v-model="buyDate"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="buyPrice">{{
                lang.buyPrice
              }}</label>
              <PvInputNumber
                id="buyPrice"
                class="inputfield w-full mt-1"
                inputClass="text-right"
                v-model="buyPrice"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                placeholder="0.00"
                @focus="$event.target.select()"
                :class="{ 'p-invalid': buyPriceError }"
                :min="0"
                @focusout="validateBuyPrice"
                @keydown="buyPriceError = false"
              />
              <small v-if="buyPriceError" id="buyPrice-help" class="p-error">{{
                lang.validateNoBuyPrice
              }}</small>
            </div>
          </div>

          <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 flex flex-wrap">
            <PvDivider>
              <div class="flex align-items-center">
                <label style="font-size: 20px" class="p-button-label">{{
                  lang.depreciationSchedule
                }}</label>
              </div>
            </PvDivider>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="depreciationPrice">{{
                lang.calculateValue
              }}</label>
              <PvInputNumber
                id="depreciationPrice"
                class="inputfield w-full mt-1"
                inputClass="text-right"
                v-model="depreciationPrice"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                placeholder="0.00"
                @focus="$event.target.select()"
                :class="{ 'p-invalid': depreciationPriceError }"
                :min="0"
                @focusout="validateDepreciationPrice"
                @keydown="depreciationPriceError = false"
              />
              <small
                v-if="depreciationPriceError"
                id="buyPrice-help"
                class="p-error"
                >{{ lang.validateNodepreciationPrice }}
              </small>
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="marketPrice">{{ lang.marketPrice }}</label>
              <PvInputNumber
                id="marketPrice"
                class="inputfield w-full mt-1"
                inputClass="text-right"
                v-model="marketPrice"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                placeholder="0.00"
                @focus="$event.target.select()"
                :min="0"
              />
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="otherCost">{{ lang.otherCost }}</label>
              <PvInputNumber
                id="otherCost"
                class="inputfield w-full mt-1"
                inputClass="text-right"
                v-model="otherCost"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                placeholder="0.00"
                @focus="$event.target.select()"
                :min="0"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="depreciationDate">{{
                lang.depreciationDate
              }}</label>
              <PvCalendar
                id="depreCalDate"
                v-model="depreciationDate"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
                :class="{ 'p-invalid': depreciationDateError }"
                @date-select="depreciationDateError = false"
              />
              <small
                v-if="depreciationDateError"
                id="buyPrice-help"
                class="p-error"
                >{{ lang.validateNoDepreciationDate }}
              </small>
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="depreciationRate">{{
                lang.depreciationRate
              }}</label>
              <PvInputNumber
                id="depreciationRate"
                class="inputfield w-full mt-1"
                inputClass="text-right"
                v-model="depreciationRate"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                placeholder="0.00"
                @focus="$event.target.select()"
                :class="{ 'p-invalid': depreciationRateError }"
                :min="0"
                @focusout="validateDepreciationRate"
                @keydown="depreciationRateError = false"
              />
              <small
                v-if="depreciationRateError"
                id="buyPrice-help"
                class="p-error"
                >{{ lang.validateNoDepreciationRate }}
              </small>
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="accumDepreciationBeforeCal">{{
                lang.accumDepreciationBeforeCal
              }}</label>
              <PvInputNumber
                id="beforeDepre"
                class="inputfield w-full mt-1"
                inputClass="text-right"
                v-model="accumDepreciationBeforeCal"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                placeholder="0.00"
                @focus="$event.target.select()"
                :min="0"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
              <label for="depreciationFormula">{{
                lang.depreciationFormula
              }}</label>
              <PvDropdown
                id="depreFormula"
                class="inputfield w-full mt-1"
                :options="depreciationFormulas"
                optionLabel="name"
                optionValue="code"
                v-model="selectDepreciationFormula"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
              <label for="depreciationPeriod">{{
                lang.depreciationPeriod
              }}</label>
              <PvDropdown
                id="deprePreriod"
                class="inputfield w-full mt-1"
                :options="depreciationPeriods"
                optionLabel="name"
                optionValue="code"
                v-model="selectDepreciationPeriod"
              />
            </div>
            <!-- <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
              <label for="status">{{ lang.status }}</label>
              <PvDropdown
                id="activeStatus"
                class="inputfield w-full mt-1"
                :options="status"
                optionLabel="name"
                optionValue="code"
                v-model="selectStatus"
              />
            </div> -->
          </div>

          <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 flex flex-wrap">
            <PvDivider>
              <div class="flex align-items-center">
                <label style="font-size: 20px" class="p-button-label">{{
                  lang.cutOffAsset
                }}</label>
              </div>
            </PvDivider>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="depreciationRemain">{{
                lang.depreciationRemain
              }}</label>
              <PvInputNumber
                id="depreRemain"
                class="inputfield w-full mt-1"
                inputClass="text-right"
                v-model="depreciationRemain"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                placeholder="0.00"
                @focus="$event.target.select()"
                :min="0"
                @focusout="validateDepreciationRemain"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
              <label for="saleReference">{{ lang.saleReference }}</label>
              <PvInputText
                id="saleRefer"
                v-model="saleReference"
                type="text"
                class="inputfield w-full mt-1"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="saleDate">{{ lang.cutOffAssetDate }}</label>
              <PvCalendar
                id="saleDate"
                v-model="cutOffAssetDate"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
              <label for="salePrice">{{ lang.salePrice }}</label>
              <PvInputNumber
                id="salePrice"
                class="inputfield w-full mt-1"
                inputClass="text-right"
                v-model="salePrice"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                placeholder="0.00"
                @focus="$event.target.select()"
                :min="0"
              />
            </div>
          </div>
<!-- 
          <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 flex flex-wrap">
            <PvDivider>
              <div class="flex align-items-center">
                <label style="font-size: 20px" class="p-button-label">{{
                  lang.categoryLocation
                }}</label>
              </div>
            </PvDivider>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="assetRegister">{{ lang.accompanyAsset }}</label>
              <base-asset-register-auto-complete
                id="assetRegister"
                class="inputfield w-full mt-1"
                v-model="selectedAccompanyAsset"
                @load-finish="assetRegisterDataReady"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="assetGroup">{{ lang.assetGroup }}</label>
              <base-asset-group-auto-complete
                id="assetGroup"
                class="inputfield w-full mt-1"
                v-model="selectedAssetGroup"
                @load-finish="assetGroupDataReady"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="building">{{ lang.building }}</label>
              <base-building-auto-complete
                id="building"
                class="inputfield w-full mt-1"
                v-model="selectedBuilding"
                @load-finish="buildingDataReady"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="location">{{ lang.location }}</label>
              <base-location-auto-complete
                id="location"
                class="inputfield w-full mt-1"
                v-model="selectedLocation"
                @load-finish="locationDataReady"
              />
            </div>
            <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2 pb-0">
              <label for="admin">{{ lang.admin }}</label>
              <base-job-admin-auto-complete
                id="admin"
                class="inputfield w-full mt-1"
                v-model="selectedAdmin"
                @load-finish="adminDataReady"
              />
            </div>
          </div> -->
        </div>
      </div>
    </transition>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
// import BaseAssetRegisterAutoComplete from "../../../components/Asset/BaseAssetRegisterAutoComplete.vue";
// import BaseAssetGroupAutoComplete from "../../../components/Asset/BaseAssetGroupAutoComplete.vue";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  props: ["id", "projectCode"],
  components: {
    // BaseAssetRegisterAutoComplete,
    // BaseAssetGroupAutoComplete,
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass, textAlertClass } =
      useTextStyle();
    const {
      formatCurrencyTable,
      formatCurrency,
      formatDate,
      formatDateToISOString,
    } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.asset.assetRegister.listing,
          ...lang.th.asset.assetRegister.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.asset.assetRegister.listing,
          ...lang.en.asset.assetRegister.edit,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrencyTable,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      textAlertClass,
    };
  },
  data() {
    return {
      code: "",
      codeError: false,
      types: [
        { name: this.lang.type1, code: "1" },
        { name: this.lang.type2, code: "2" },
        { name: this.lang.type3, code: "3" },
      ],
      selectType: "",
      clearType: false,
      ownerships: [
        { name: this.lang.ownership1 },
        { name: this.lang.ownership2 },
      ],
      selectOwnership: "",

      nameTh: "",
      nameThError: false,
      nameEn: "",

      selectedAssetAccount: null,
      isSharedAssetAccountReady: false,
      assetAccountError: false,
      selectedDepreAccount: null,
      depreAccountError: false,
      selectedAccumAccount: null,
      accumAccountError: false,

      taxInvoiceCode: "",
      taxInvoiceCodeError: false,
      buyDate: null,
      buyPrice: 0,
      buyPriceError: false,
      depreciationPrice: 0,
      depreciationPriceError: false,
      marketPrice: 0,
      otherCost: 0,
      depreciationDate: null,
      depreciationDateError: false,
      depreciationRate: 0,
      depreciationRateError: false,
      accumDepreciationBeforeCal: 0,
      depreciationFormulas: [
        { name: this.lang.depreciationFormula1, code: "A" },
        { name: this.lang.depreciationFormula2, code: "B" },
      ],
      selectDepreciationFormula: "",
      depreciationPeriods: [
        { name: this.lang.depreciationPeriod1, code: "M" },
        { name: this.lang.depreciationPeriod2, code: "Y" },
      ],
      selectDepreciationPeriod: "",
      status: [
        { name: this.lang.status1, code: "Y" },
        { name: this.lang.status2, code: "N" },
      ],
      selectStatus: "",

      depreciationRemain: 0,
      saleReference: "",
      cutOffAssetDate: null,
      salePrice: 0,

      isAssetRegisterReady: false,
      selectedAccompanyAsset: null,
      isAssetGroupReady: false,
      selectedAssetGroup: null,
      isSharedBuildingReady: false,
      selectedBuilding: null,
      isSharedLocationReady: false,
      selectedLocation: null,
      isSharedAdminReady: false,
      selectedAdmin: null,

      showAddItemLotDialog: false,
      valueToCopy: 0,
      activeStatus: true,
    };
  },
  async created() {
    await this.initiateData();
    if (!this.isCreateDocument) {
      const where = {
        projectId: this.selectedProject.id,
        id: this.id,
      };
      this.setLoading(true);
      await this.$store.dispatch("assetAssetRegister/getAssetRegister", {
        where,
      });
      this.getAssetCodeByTypes();
    }
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      const localSearch = localStorage.getItem("erp_search_assetRegisterList");
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        assetRegisterEntry: "Entry",
      };
      localStorage.setItem(
        "erp_search_assetRegisterList",
        JSON.stringify(search)
      );
      this.$router.push({name: "asset-register"});
    },
    checkShouldSetData() {
      if (
        this.isSharedAssetAccountReady &&
        // this.isAssetRegisterReady &&
        // this.isAssetGroupReady &&
        // this.isSharedBuildingReady &&
        // this.isSharedLocationReady &&
        // this.isSharedAdminReady &&
        this.selectedAssetRegister
      ) {
        // console.log('checkShouldSetData');
        this.setData();
      }
    },
    setData() {
      this.setLoading(true);
      // console.log(this.selectedAssetRegister);
      this.code = this.selectedAssetRegister.code;
      this.selectType = "";
      this.selectOwnership = this.selectedAssetRegister.model;
      this.nameTh = this.selectedAssetRegister.name;
      this.nameEn = this.selectedAssetRegister.nameEn;
      this.selectedAssetAccount = this.sharedAccounts.find(
        (account) => account.id === this.selectedAssetRegister.assetAccountId
      );
      this.selectedDepreAccount = this.sharedAccounts.find(
        (account) => account.id === this.selectedAssetRegister.depreAccountId
      );
      this.selectedAccumAccount = this.sharedAccounts.find(
        (account) => account.id === this.selectedAssetRegister.accumAccountId
      );
      this.taxInvoiceCode = this.selectedAssetRegister.taxInvoiceCode;
      this.buyDate = this.selectedAssetRegister.buyDate ? new Date(this.selectedAssetRegister.buyDate) : null;
      this.buyPrice = this.selectedAssetRegister.buyPrice;
      this.depreciationPrice = this.selectedAssetRegister.deprePrice;
      this.marketPrice = this.selectedAssetRegister.marketPrice;
      this.otherCost = this.selectedAssetRegister.otherCost;
      this.depreciationDate = this.selectedAssetRegister.depreCalDate ? new Date(this.selectedAssetRegister.depreCalDate) : null;
      this.depreciationRate = this.selectedAssetRegister.depreRate;
      this.accumDepreciationBeforeCal = this.selectedAssetRegister.beforeDepre;
      this.selectDepreciationFormula =
        this.selectedAssetRegister.depreFormula;
      this.selectDepreciationPeriod =
        this.selectedAssetRegister.deprePreriod;
      this.selectStatus = this.selectedAssetRegister.activeStatus.trim();
      this.depreciationRemain = this.selectedAssetRegister.depreRemain;
      this.saleReference = this.selectedAssetRegister.saleRefer;
      this.cutOffAssetDate = this.selectedAssetRegister.saleDate ? new Date(this.selectedAssetRegister.saleDate) : null;
      this.salePrice = this.selectedAssetRegister.salePrice;
      this.selectedAccompanyAsset = this.assetRegister.find(
        (asset) => asset.id === this.selectedAssetRegister.parentAssetId
      );
      this.selectedAssetGroup = this.assetGroup.find(
        (asset) => asset.id === this.selectedAssetRegister.assetGroupId
      );
      this.selectedBuilding = this.sharedBuilding.find(
        (building) => building.id === this.selectedAssetRegister.buildingId
      );
      this.selectedLocation = this.sharedLocation.find(
        (location) => location.id === this.selectedAssetRegister.locationId
      );
      this.selectedAdmin = this.sharedJobAdmin.find(
        (admin) => admin.id === this.selectedAssetRegister.adminId
      );
      this.activeStatus = this.selectedAssetRegister.activeStatus.trim() == "Y" ? true : false;

      this.setLoading(false);
    },
    async initiateData() {
      this.code = "";
      this.selectType = "";
      this.selectOwnership = this.lang.ownership1;
      this.nameTh = "";
      this.nameEn = "";
      this.selectedAssetAccount = null;
      this.selectedDepreAccount = null;
      this.selectedAccumAccount = null;
      this.taxInvoiceCode = "";
      this.buyDate = new Date();
      this.buyPrice = 0;
      this.depreciationPrice = 0;
      this.marketPrice = 0;
      this.otherCost = 0;
      this.depreciationDate = new Date();
      this.depreciationRate = 0;
      this.accumDepreciationBeforeCal = 0;
      this.selectDepreciationFormula = "A";
      this.selectDepreciationPeriod = "M";
      this.selectStatus = "Y";
      this.depreciationRemain = 0;
      this.saleReference = "";
      // this.cutOffAssetDate = new Date();
      this.cutOffAssetDate = null;
      this.salePrice = 0;
      this.selectedAccompanyAsset = null;
      this.selectedAssetGroup = null;
      this.selectedBuilding = null;
      this.selectedLocation = null;
      this.selectedAdmin = null;

      this.codeError = false;
      this.nameThError = false;
      this.assetAccountError = false;
      this.depreAccountError = false;
      this.accumAccountError = false;
      this.taxInvoiceCodeError = false;
      this.buyPriceError = false;
      this.depreciationPriceError = false;
      this.depreciationDateError = false;
      this.depreciationRateError = false;
      this.activeStatus = true;
      await this.$store.dispatch("assetAssetRegister/resetSelectedAssetRegister");
      this.getAssetCodeByTypes();
    },
    createDocument() {
      this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForCreateNewDocument}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.cancel,
        allowOutsideClick: false,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (!this.isCreateDocument) {
            this.$router.replace({name: "asset-register-edit", params: {id: "create"}});
          } else {
            //
          }
          this.initiateData();
        }
      });
    },
    assetAccountDataReady() {
      this.isSharedAssetAccountReady = true;
    },
    assetRegisterDataReady() {
      this.isAssetRegisterReady = true;
    },
    assetGroupDataReady() {
      this.isAssetGroupReady = true;
    },
    buildingDataReady() {
      this.isSharedBuildingReady = true;
    },
    locationDataReady() {
      this.isSharedLocationReady = true;
    },
    adminDataReady() {
      this.isSharedAdminReady = true;
    },
    validateData() {
      if (!this.code) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoCode,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.codeError = true;
          }
        });
        return true;
      }

      if (!this.nameTh) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoNameTh,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.nameThError = true;
          }
        });
        return true;
      }

      if (!this.selectedAssetAccount) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoAssetAccountSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.assetAccountError = true;
          }
        });
        return true;
      }

      if (!this.selectedDepreAccount) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoDepreAccountSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.depreAccountError = true;
          }
        });
        return true;
      }

      if (!this.selectedAccumAccount) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoAccumAccountSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.accumAccountError = true;
          }
        });
        return true;
      }

      if (!this.taxInvoiceCode) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoVoucherCode,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.taxInvoiceCodeError = true;
          }
        });
        return true;
      }

      if (this.buyPrice == 0 || this.buyPrice == null) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoBuyPrice,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.buyPriceError = true;
          }
        });
        return true;
      }

      if (this.depreciationPrice == 0) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNodepreciationPrice,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.depreciationPriceError = true;
          }
        });
        return true;
      }

      if (this.depreciationDate == null) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoDepreciationDate,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.depreciationDateError = true;
          }
        });
        return true;
      }

      if (this.depreciationRate == 0) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoDepreciationRate,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.depreciationRateError = true;
          }
        });
        return true;
      }

      return false;
    },
    async save() {
      if (this.validateData()) {
        this.setLoading(false);
        return;
      }

      this.setLoading(true);

      try {
        // console.log("save", this.payload);
        await this.$store.dispatch(
          "assetAssetRegister/createOrUpdateAssetRegister",
          this.payload
        );
        this.$swal({
          icon: "success",
          title: this.lang.saveSuccess,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        if (this.isCreateDocument) {
          this.$router.replace({name: "asset-register-edit", params: {id: this.selectedAssetRegister.id}});
        }
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }

      this.setLoading(false);
    },
    saveLot() {
      this.showAddItemLotDialog = true;
    },
    closeAddItemLotDialog() {
      this.showAddItemLotDialog = false;
      this.valueToCopy = 0;
    },
    async addItemLot() {
      // console.log(this.valueToCopy);
      // console.log(this.selectedAssetRegister);

      if(this.valueToCopy <= 0){
        this.$swal({
          icon: "warning",
          title: this.lang.validateValueToCopy,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }

      this.showAddItemLotDialog = false;

      const result = await this.$swal({
        icon: "info",
        html: `<p class="${this.textAlertClass}">${this.lang.askForSaveLot.replace(":code", this.code).replace(":name", this.selectedAssetRegister.name).replace(":value", this.valueToCopy)}</p>`,
        confirmButtonText: this.lang.confirm,
        showCancelButton: true,
        cancelButtonText: this.lang.close,
        allowOutsideClick: false,
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748B",
      });

      if (result.isConfirmed) {
        let payload = {
          copyNumber: this.valueToCopy,
          projectCode: this.selectedProject.code.trim(),
        };
        payload = {...this.payload, ...payload};
        try {
          // console.log("addItemLot",payload);
          await this.$store.dispatch("assetAssetRegister/copyAssetRegister",payload);
          this.$swal({
            icon: "success",
            title: this.lang.saveSuccess,
            confirmButtonText: this.lang.close,
            allowOutsideClick: false,
          });
        } catch (error) {
          this.$swal({
            icon: "error",
            html: `<p class="${this.textAlertClass}">${error}</p>`,
            confirmButtonText: this.lang.close,
            allowOutsideClick: false,
          });
        }
      
      } else if (result.isDenied) {
        //
      }

      this.valueToCopy = 0;
    },
    async getAssetCodeByTypes() {
      try {
        let where = {
          projectId: this.selectedProject.id,
          projectCode: this.selectedProject.code.trim(),
        };
        await this.$store.dispatch(
          "assetAssetRegister/getAssetCodeByTypes",
          where
        );
        // console.log(this.assetCodeByTypes);
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
    },
    selectAssetType() {
        if (this.selectType == 1) {
          this.code = this.assetCodeByTypes.assetType1;
        } else if (this.selectType == 2) {
          this.code = this.assetCodeByTypes.assetType2;
        } else if (this.selectType == 3) {
          this.code = this.assetCodeByTypes.assetType3;
        } else {
          this.code = "";
        }
        this.clearType = true;
      },
      validateBuyPrice() {
        if (this.buyPrice == null) {
          this.buyPrice = 0
          this.depreciationPrice = 0;
        } else {
          this.buyPriceError = false;
        }
      },
      validateDepreciationRemain() {
        if (this.depreciationRemain > this.buyPrice) {
          this.$swal({
                      icon: "warning",
                      title: this.lang.validateDepreciationRemain,
                      confirmButtonText: this.lang.close,
                      allowOutsideClick: false,
                  });
          this.depreciationRemain = 0;
        }
      },
      validateDepreciationRate() {
        if (this.depreciationRate < 0 || this.depreciationRate > 100) {
          this.depreciationRate = 0
        } else this.depreciationRateError = false;
      },
      validateDepreciationPrice() {
        if (this.depreciationPrice < 0 || this.depreciationPrice == null) {
          this.depreciationPrice = 0
        } else if (this.depreciationPrice > this.buyPrice) {
          this.$swal({
                      icon: "warning",
                      title: this.lang.validateDepreciationPrice,
                      confirmButtonText: this.lang.close,
                      allowOutsideClick: false,
                  });
          this.depreciationPrice = 0
        } else this.depreciationPriceError = false;
      }
  },
  computed: {
    payload() {
      let payload = {
        projectId: this.selectedProject.id,
        code: this.code,
        name: this.nameTh,
        nameEn: this.nameEn,
        model: this.selectOwnership,
        assetAccountId: this.selectedAssetAccount.id,
        depreAccountId: this.selectedDepreAccount.id,
        accumAccountId: this.selectedAccumAccount.id,
        taxInvoiceCode: this.taxInvoiceCode,
        buyDate: this.buyDate ? this.formatDateToISOString(this.buyDate) : null,
        buyPrice: this.buyPrice,
        deprePrice: this.depreciationPrice,
        marketPrice: this.marketPrice,
        otherCost: this.otherCost,
        depreCalDate: this.formatDateToISOString(this.depreciationDate),
        depreRate: this.depreciationRate,
        beforeDepre: this.accumDepreciationBeforeCal,
        depreFormula: this.selectDepreciationFormula,
        deprePreriod: this.selectDepreciationPeriod,
        activeStatus: this.activeStatus == true ? "Y" : "N",
        depreRemain: this.depreciationRemain,
        saleRefer: this.saleReference,
        saleDate: this.cutOffAssetDate ? this.formatDateToISOString(this.cutOffAssetDate) : null,
        salePrice: this.salePrice,
        parentAssetId: this.selectedAccompanyAsset ? this.selectedAccompanyAsset.id : "",
        assetGroupId: this.selectedAssetGroup ? this.selectedAssetGroup.id : "",
        buildingId: this.selectedBuilding ? this.selectedBuilding.id : "",
        locationId: this.selectedLocation ? this.selectedLocation.id : "",
        adminId: this.selectedAdmin ? this.selectedAdmin.id : "",
        type: "Add",
      };

      if (!this.isCreateDocument) {
        payload = { ...payload, id: this.id, type: "Update" };
      }

      return payload;
    },
    isCreateDocument() {
      return this.id === "create";
    },
    title() {
      let result = this.lang.editAsset;
      if (this.isCreateDocument) {
        result = this.lang.createAsset;
      }
      return result;
    },
    sharedAccounts() {
      return this.$store.getters["shared/accountingDetailAccounts"];
    },
    assetRegister() {
      return this.$store.getters["assetAssetRegister/assetRegisters"];
    },
    assetGroup() {
      return this.$store.getters["assetAssetGroup/assetGroups"];
    },
    sharedBuilding() {
      return this.$store.getters["shared/buildingBuildings"];
    },
    sharedJobAdmin() {
      return this.$store.getters["shared/jobAdminJobAdmins"];
    },
    sharedLocation() {
      return this.$store.getters["shared/locationLocations"];
    },
    selectedAssetRegister() {
      return this.$store.getters["assetAssetRegister/selectedAssetRegister"];
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    assetCodeByTypes() {
      return this.$store.getters["assetAssetRegister/assetCodeByType"];
    },
  },
  watch: {
    isSharedAssetAccountReady() {
      this.checkShouldSetData();
    },
    // isAssetRegisterReady() {
    //   this.checkShouldSetData();
    // },
    // isAssetGroupReady() {
    //   this.checkShouldSetData();
    // },
    // isSharedBuildingReady() {
    //   this.checkShouldSetData();
    // },
    // isSharedLocationReady() {
    //   this.checkShouldSetData();
    // },
    // isSharedAdminReady() {
    //   this.checkShouldSetData();
    // },
    // selectedAssetAccount(curValue, oldValue) {
    //   if (oldValue === null && curValue) {
    //     this.assetAccountError = false;
    //   }
    // },
    // selectedDepreAccount(curValue, oldValue) {
    //   if (oldValue === null && curValue) {
    //     this.depreAccountError = false;
    //   }
    // },
    // selectedAccumAccount(curValue, oldValue) {
    //   if (oldValue === null && curValue) {
    //     this.accumAccountError = false;
    //   }
    // },
    // nameTh(curValue, oldValue) {
    //   if (oldValue === "" && curValue) {
    //     this.nameThError = false;
    //   }
    // },
    // code(curValue, oldValue) {
    //   if (oldValue === "" && curValue) {
    //     this.codeError = false;
    //   }
    // },
    // taxInvoiceCode(curValue) {
    //   if (curValue) {
    //     this.taxInvoiceCodeError = false;
    //   }
    // },
    // buyPrice(curValue) {
    //   // if (curValue) {
    //   //   this.buyPriceError = false;
    //   // }
    //   if (curValue < 0 || curValue == null) {
    //     this.buyPrice = 0
    //   }
    //   if (curValue == null) {
    //     this.depreciationPrice = 0;
    //   }
    //   else this.buyPriceError = false;
    // },
    // depreciationPrice(curValue) {
    //   // if (curValue) {
    //   //   this.depreciationPriceError = false;
    //   // }
    //   if (curValue < 0 || curValue == null) {
    //     this.depreciationPrice = 0
    //   } else if (curValue > this.buyPrice) {
    //     this.$swal({
		// 			icon: "warning",
		// 			title: this.lang.validateDepreciationPrice,
		// 			confirmButtonText: this.lang.close,
		// 			allowOutsideClick: false,
		// 		});
    //     this.depreciationPrice = 0
		// 		return true;
    //   } else this.depreciationPriceError = false;
    // },
    // marketPrice(curValue) {
    //   if (curValue < 0) this.marketPrice = 0
    // },
    // otherCost(curValue) {
    //   if (curValue < 0) this.otherCost = 0
    // },
    // accumDepreciationBeforeCal(curValue) {
    //   if (curValue < 0) this.accumDepreciationBeforeCal = 0
    // },
    // depreciationDate(curValue) {
    //   if (curValue) {
    //     this.depreciationDateError = false;
    //   }
    // },
    // depreciationRate(curValue) {
    //   // if (curValue) {
    //   //   this.depreciationRateError = false;
    //   // } 
    //   if (curValue < 0 || curValue > 100) {
    //     this.depreciationRate = 0
    //   } else this.depreciationRateError = false;
    // },
    // depreciationRemain(curValue) {
    //   if (curValue < 0) this.depreciationRemain = 0
    //   else if (curValue > this.buyPrice) {
    //     this.$swal({
		// 			icon: "warning",
		// 			title: this.lang.validateDepreciationRemain,
		// 			confirmButtonText: this.lang.close,
		// 			allowOutsideClick: false,
		// 		});
    //     this.depreciationRemain = 0
		// 		return true;
    //   }
    // },
    // salePrice(curValue) {
    //   if (curValue < 0) this.salePrice = 0
    // },
    // selectType(curValue) {
    //   if (curValue == 1) {
    //     this.code = this.assetCodeByTypes.assetType1;
    //   } else if (curValue == 2) {
    //     this.code = this.assetCodeByTypes.assetType2;
    //   } else if (curValue == 3) {
    //     this.code = this.assetCodeByTypes.assetType3;
    //   } else {
    //     this.code = "";
    //   }
    //   this.clearType = true;
    // },
    selectedAssetRegister() {
      this.checkShouldSetData();
    },
  },
};
</script>
