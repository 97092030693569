export default {
    paymentRequestManagement: "Payment Request Management",
    createPaymentRequest: "Create Payment Request",
    paymentRequest: "Payment Request",
    filterSearchDate: "Date",
    supplier: "Supplier",
    filterSearchAmount: "Total Amount",
    columnTotalAmount: "Total Amount",
    columnExpense: "Expense",
    columnPayment: "Payment",
    totalAmountAscending: "Ascending",
    totalAmountDescending: "Descending",
    postedStatus: "Posted",
    partialStatus: "Partial",
    autoGl: "Auto GL",
    settingAutoGl: "Setting Auto GL",
    paymentRequestTypeAutoGl: "Payment Request Type For Auto GL",
    selectPaymentRequestType: "Select Payment Request Type",
    paymentRequestDateStart: "Date Start",
    paymentRequestDateEnd: "Date End",
    ledgerBook: "Ledger Book",
    nextStepAutoGl: "In the next step, The system will display a list of approved payment request.",
    ledgerBookCode: "Ledger Book",
    voucherDate: "Voucher Date",
    paymentRequestCode: "Payment Request Code",
    paymentRequestDate: "Payment Request Date",
    paymentRequestType: "Payment Request Type",
    validateNoSelectLedgerBook: "Please Select Ledger Book",
    validateDateStartError: "Please Select Date Start",
    validateDateEndError: "Please Select Date End",
    titlePreviewVoucher: "Auto GL Example",
    accountCode: "Account Code",
    accountName: "Account Name",
    debitAmount: "Debit",
    creditAmount: "Credit",
    totalDebitAmount: "Total Debit",
    totalCreditAmount: "Total Credit",
    totalDiffAmount: "Diff",
    validatePaymentRequestTypeAutoGl: "Can't auto gl, because you have a type that does't specify a ledger book : ",
    validateProductAutoGl: "Can't auto gl, because you have pruduct that does't specify debit, credit",
    setupType: "setup payment request type",
    setupProduct: "setup product",
    autoGlProcessing: "Processing",
    validateNoDataForAutoGl: "Can't auto gl, because there is no waiting list to save",
    columnItemNo: "ItemNo",
    columnBookCode: "Book Code",
    columnBookName: "Book Name",
    columnReferenceCode: "Reference Code",
    columnReferenceDate: "Reference Date",
}