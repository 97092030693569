export default {
    resetImportResidents(state) {
        state.importResidents = [];
    },
    setImportResidents(state, payload) {
        state.importResidents = payload;
    },
    setResidentCodes(state, payload) {
        state.residentCodes = payload.data;
    },
}