export default {
	async getEmployees(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Employee";

		if (!payload.limit) {
			url = url + "?limit=100";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get unit");
			throw error;
		}
		// console.log("employee",responseData);

		// const data = responseData.data;

		const employee = {
			employees: responseData.data,
			totalPage: responseData.page.pageCount,
			currentPage: responseData.page.currentPage,
		};
		context.commit("setEmployee", employee);
	},
	async getAllEmployees(context) {
		// console.log('context',context);
		while (!context.getters.employeeIsLastPage) {
			let where = {
				projectId: context.rootGetters.selectedProject.id,
				isEmployee: "Y",
			};
			const payload = {
				page: context.getters.employeeNextPage || 1,
				where,
			};
			await context.dispatch("getEmployees", payload);
		}
	},
	resetEmployee(context) {
		context.commit("resetEmployee");
	},
	async getEmployeesByUserIds(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Employee/GetEmployeeLisBytUserIds";

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("setEmployeesByUserIds", responseData);
			const error = new Error(responseData.message || "Failed to get Employees By UserIds");
			throw error;
		}
		// console.log("setEmployeesByUserIds responseData", responseData.data);
		await context.commit("setEmployeesByUserIds", responseData.data);
	},
};
