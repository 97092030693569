export default {
  setClosingEntry(state, payload) {
		let closingEntrys = [...state.closingEntrys];
		for (const closingEntry of payload.data) {
			if (closingEntrys.findIndex((c) => c.id === closingEntry.id) < 0) {
				closingEntrys.push(closingEntry);
			}
		}
    state.closingEntrys = closingEntrys;
    state.totalPage = payload.page.pageCount;
    state.currentPage = payload.page.currentPage;
  },
  setSelectedClosingEntry(state, payload) {
    state.selectedClosingEntry = payload;
  },
  resetClosingEntry(state) {
    state.closingEntrys = [];
    state.totalPage = 0;
    state.currentPage = 0;
  },
  setClosingVoucher(state, payload) {
    state.closingVouchers = payload.data;
  },
  resetClosingVoucher(state) {
    state.closingVouchers = [];
  },
  resetSelectedClosingEntry(state) {
    state.selectedClosingEntry = [];
  },
};