export default {
	titleBack: "ชุดเรียกเก็บ",
	title: "ชุดเรียกเก็บประจำเดือน",
	createPolicy: "สร้างชุดเรียกเก็บ",
	emptyPolicy: "ไม่มีรายการชุดเรียกเก็บ",

	columnItemNo: "ลำดับ",
	columnCode: "รหัส",
	columnName: "ชื่อ",
	columnCreateBy: "ผู้สร้าง",
	columnCreateDate: "วันที่สร้าง",
	columnUpdateBy: "ผู้แก้ไข",
	columnUpdateDate: "วันที่แก้ไขล่าสุด",
	columnAction: "",

	askForDeletePolicy: "คุณยืนยันที่จะ ลบรายรับ {policyCodeAndName} ใช่หรือไม่?",
};
