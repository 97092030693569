<template>
  <svg
  	:width="width"
	:height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00391 15.5H16.0039"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.0039 8.5H8.00391"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.00391 12H16.0039"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.449 20.396L18.459 20.8935C18.1785 21.0345 17.8481 21.0355 17.5667 20.8964L15.9942 20.1188L14.4533 20.8929C14.172 21.0342 13.8406 21.0348 13.5588 20.8945L12 20.1185L10.4412 20.8945C10.1594 21.0348 9.82799 21.0342 9.54668 20.8929L8.00578 20.1188L6.4333 20.8964C6.15191 21.0355 5.82151 21.0345 5.54102 20.8935L4.55102 20.396C4.21321 20.2263 4.00001 19.8805 4 19.5025V4.49752C4 4.11947 4.2132 3.77374 4.551 3.604L5.541 3.10648C5.82149 2.96552 6.15189 2.96446 6.43328 3.10361L8.00576 3.8812L9.54666 3.10713C9.82797 2.96581 10.1594 2.96521 10.4412 3.10551L12 3.88151L13.5588 3.10551C13.8406 2.96521 14.172 2.96581 14.4533 3.10713L15.9942 3.8812L17.5667 3.10361C17.8481 2.96446 18.1785 2.96552 18.459 3.10648L19.449 3.604C19.7868 3.77374 20 4.11947 20 4.49752V19.5025C20 19.8806 19.7868 20.2263 19.449 20.396Z"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
