export default({
    createTitle: "สร้างหนังสือทวงถามกำหนดเอง",
    editTitle: "แก้ไขหนังสือทวงถามกำหนดเอง",
    containerTitle: "รายละเอียดหนังสือทวงถาม",
    labelName: "ชื่อหนังสือทวงถาม",
    placeholderName: "กรุณาระบุชื่อหนังสือทวงถาม",
    validateName: "กรุณาระบุชื่อหนังสือทวงถาม",
    tabManageProduct: "รายรับที่แสดง",
    tabManageReport: "ปรับแต่งแบบฟอร์ม",
    labelIsGroupProduct: "พิมพ์แบบรวมรายการ",
    isGroupProduct1: "ใช่",
    isGroupProduct2: "ไม่ใช่",
    columnIsGroupProduct: "รวมรายการ",
    tooltipPenaltyProduct: "หมวดค่าปรับ ไม่สามารถแสดง<br>แบบไม่รวมรายการได้",
    tagGroup: "รวม",
    tagUngroup: "ไม่รวม",
    labelManageProduct: "รายรับที่แสดงในเอกสาร",
    addProduct: "เพิ่มรายรับ",

    validateDetailTitle: "ไม่สามารถปรับแต่งแบบฟอร์มได้",
    validateDetailContent: "คุณไม่ได้เลือกรายรับที่จะแสดง กรุณาเลือกรายรับอย่างน้อย 1 รายการ",

    validateImageLogoTitle: "ไม่สามารถอัปโหลดไฟล์ {0} ได้",
    validateSizeImageLogoContent: "ขนาดไฟล์ที่อนุญาติต้องไม่เกิน 50 Kb เท่านั้น",
    validateTypeImageLogoContent: "สกุลไฟล์ที่อนุญาติมีดังนี้ .JPEG, .JPG และ .PNG เท่านั้น",

    labelIsSuspend: "ระบุวันที่ระงับในหนังสือทวงถาม",

    tooltipExampleFooter: "ตัวอย่างตำแหน่งของข้อมูล",
    tooltipDisplayLogoHeader: "ตัวอย่างหัวกระดาษในแต่ละกรณี",
    tooltipSubject: "ข้อมูลที่เพิ่มนี้จะถูกเพิ่มลงส่วน หัวเรื่อง",
    tooltipTo: "ข้อมูลที่เพิ่มนี้จะถูกเพิ่มลงส่วน ชื่อผู้รับ",
    tooltipAddress: "ข้อมูลที่เพิ่มนี้จะถูกเพิ่มลงส่วน ที่อยู่",
    tooltipContentHeader: "ข้อมูลที่เพิ่มนี้จะถูกเพิ่มลงส่วน ย่อหน้าที่ 1",
    tooltipContentFootert: "ข้อมูลที่เพิ่มนี้จะถูกเพิ่มลงส่วน ย่อหน้าที่ 2",
    tooltipSignature: "ข้อมูลที่เพิ่มนี้จะถูกเพิ่มลงส่วน ลายเซ็นผู้ออกเอกสาร",
    tooltipSignatureName: "ข้อมูลที่เพิ่มนี้จะถูกเพิ่มลงส่วน ชื่อจริงผู้ออกเอกสาร",
    tooltipSignaturePosition: "ข้อมูลที่เพิ่มนี้จะถูกเพิ่มลงส่วน ชื่อตำแหน่งผู้ออกเอกสาร",
    tooltipPageFooter: "ข้อมูลที่เพิ่มนี้จะถูกเพิ่มลงส่วน ท้ายกระดาษ",

    labelIsDisplayLogo: "เพิ่มโลโก้บนหัวกระดาษ",
    labelDisplayLogo: "โลโก้",
    smallDisplayLogoInfo1: "หากเพิ่มโลโก้ ระบบจะจัดตัวอักษรในหัวกระดาษให้ชิดซ้าย",
    smallDisplayLogoInfo2: "อัตราส่วน 16:9 (เช่น 86x48 เป็นต้น) รองรับไฟล์ .jpeg, .jpg, .png ไม่เกิน 50kb",
    labelMarkup: "เพิ่มมาร์คอัพเรียกข้อมูล",

    labelSubject: "หัวเรื่อง",
    labelEditorSubjectTh: "เรื่อง (ภาษาไทย)",
    labelEditorSubjectEn: "เรื่อง (ภาษาอังกฤษ)",

    labelTo: "ชื่อผู้รับ",
    labelToTh: "ชื่อผู้รับ (ภาษาไทย)",
    labelToEn: "ชื่อผู้รับ (ภาษาอังกฤษ)",

    labelAddress: "ที่อยู่",
    labelAddressTh: "ที่อยู่ (ภาษาไทย)",
    labelAddressEn: "ที่อยู่ (ภาษาอังกฤษ)",

    labelContentHeader: "ย่อหน้าที่ 1",
    labelContentHeaderTh: "ย่อหน้าที่ 1 (ภาษาไทย)",
    labelContentHeaderEn: "ย่อหน้าที่ 1 (ภาษาอังกฤษ)",

    labelContentFooter: "เพิ่มย่อหน้าที่ 2",
    labelContentFooterTh: "ย่อหน้าที่ 2 (ภาษาไทย)",
    validateContentFooterTh: "กรุณาระบุย่อหน้าที่ 2 (ภาษาไทย)",
    labelContentFooterEn: "ย่อหน้าที่ 2 (ภาษาอังกฤษ)",
    validateContentFooterEn: "กรุณาระบุย่อหน้าที่ 2 (ภาษาอังกฤษ)",

    labelSignatureUrl: "เพิ่มลายเซ็นผู้ออกเอกสาร",
    labelIsSameSignatureOnBothLang: "ใช้ลายเซ็นเดียวกัน ทั้งสองภาษา",
    isSameSignatureOnBothLang1: "ใช่",
    isSameSignatureOnBothLang2: "ไม่ใช่",
    labelSignatureUrlTh: "ลายเซ็นผู้ออกเอกสาร (ภาษาไทย)",
    labelSignatureUrlEn: "ลายเซ็นผู้ออกเอกสาร (ภาษาอังกฤษ)",
    smallSignatureUrl: "อัตราส่วน 16:9 (เช่น 75x42 เป็นต้น) รองรับไฟล์ .jpeg, .jpg, .png ไม่เกิน 50kb",

    labelSignatureFullName: "เพิ่มชื่อจริงผู้ออกเอกสาร",
    labelSignatureFullNameTh: "ชื่อจริงผู้ออกเอกสาร (ภาษาไทย)",
    validateSignatureFullNameTh: "กรุณาระบุชื่อจริงผู้ออกเอกสาร (ภาษาไทย)",
    labelSignatureFullNameEn: "ชื่อจริงผู้ออกเอกสาร (ภาษาอังกฤษ)",
    validateSignatureFullNameEn: "กรุณาระบุชื่อจริงผู้ออกเอกสาร (ภาษาอังกฤษ)",

    labelSignaturePosition: "เพิ่มชื่อตำแหน่งผู้ออกเอกสาร",
    labelSignaturePositionTh: "ชื่อตำแหน่งผู้ออกเอกสาร (ภาษาไทย)",
    validateSignaturePositionTh: "กรุณาระบุชื่อตำแหน่งผู้ออกเอกสาร (ภาษาไทย)",
    labelSignaturePositionEn: "ชื่อตำแหน่งผู้ออกเอกสาร (ภาษาอังกฤษ)",
    validateSignaturePositionEn: "กรุณาระบุชื่อตำแหน่งผู้ออกเอกสาร (ภาษาอังกฤษ)",

    labelPageFooter: "ท้ายกระดาษ",
    labelPageFooterTh: "ท้ายกระดาษ (ภาษาไทย)",
    labelPageFooterEn: "ท้ายกระดาษ (ภาษาอังกฤษ)",

    validateImage: "โปรดอัปโหลดไฟล์",
    validateField: "เนื่องจากคุณยังไม่ระบุข้อมูลในช่อง {0}",
    
    contentSaved: "บันทึกรายการสำเร็จ",
    titleNoticeCustomReportDeleteConfirm: "ลบหนังสือทวงถาม",
    contentNoticeCustomReportDeleteConfirm: "คุณต้องการที่จะลบหนังสือทวงถามนี้หรือไม่?",
    titleNoticeCustomReportDeleted: "ลบหนังสือทวงถามสำเร็จ",
    contentNoticeCustomReportDeleted: "คุณลบหนังสือทวงถามสำเร็จ",

    validateSaveAndPreviewTitle: "โปรดบันทึกเอกสาร ก่อนดูตัวอย่าง",
    validateSaveAndPreviewContent: "เนื่องจากระบบไม่สามารถแสดงตัวอย่าง หากเอกสารยังไม่ถูกบันทึก",
    buttonDialogSaveAndPreview: "บันทึกและดูตัวอย่าง",
    validateDeleteContent: "เนื่องจากคุณยังไม่บันทึกรายการ",
})