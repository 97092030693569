import actions from "./actions.js";
import getters from "./getters.js";
import mutations from "./mutations.js";

export default {
	namespaced: true,
	state() {
		return {
			meterUsageSettingLists: [],
			meterUsageSettingType: null,
			totalPage: 0,
			currentPage: 1,
			rows: 100,
		};
	},
	actions,
	getters,
	mutations,
};
