<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99992 2.66455C5.30963 2.66496 3.04046 4.66821 2.70646 7.3377C2.37246 10.0072 4.07811 12.5078 6.68538 13.171C9.29264 13.8342 11.9858 12.4526 12.968 9.94802C13.9502 7.44342 12.9141 4.59937 10.551 3.31349"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.551 5.36417V3.00586H12.9093"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
