<template>
  <base-toolbar
    :title="lang.titleToolbaar"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @sendAllNotification="confirmToSendAllNotification()"
  >
  </base-toolbar>
  <base-dialog
    v-model:visible="showConfirmDialog"
    :classTitle="confirmClassTitle"
    :titleConfirm="confirmTitle"
    :wordingConfirm="confirmContent"
    @confirm="confirmDialog"
    @close="closeAlertDialog"
  ></base-dialog>

  <base-progress-bar
    v-model:visible="showProgressBar"
    :title="lang.notifyStatus"
    :textProgressBar="lang.sending"
    :valueObj="valueObj"
    :progressBar="`Template`"
  >
    <template #progressFooter>
      <div class="flex justify-content-between align-items-center">
        <div class="col-12 pl-0 pr-2 pb-0">
          <PvButton
            :label="lang.cancel"
            class="p-button-outlined p-button-primary w-full"
            @click="closeProgressBar()"
          />
        </div>
      </div>
    </template>
  </base-progress-bar>

  <base-container>
    <div class="surface-card shadow-2 p-5 border-round mt-5">
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.title }}</label>
        <PvDivider></PvDivider>
        <div class="flex flex-wrap mb-3 w-full">
          <div class="lg:col-2 md:col-2 col-12 pl-0 pr-2 pb-0">
            <PvDropdown
              class="inputfield w-full"
              :options="selectType"
              optionLabel="name"
              optionValue="code"
              v-model="selectedType"
              @change="changeSelectedType()"
            />
          </div>
          <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchText"
                :placeholder="this.selectedTypePlaceholder"
                autofocus
              />
            </span>
          </div>
          <div class="lg:col-3 md:col-3 col-12 pl-0 pr-2 pb-0 flex flex-wrap">
            <PvButton class="ml-2" @click="getInvoiceNotification()">
              <span class="font-normal">{{ lang.search }}</span>
            </PvButton>
          </div>
        </div>
      </div>

      <div class="flex mb-3 w-full">
        <div class="lg:col-3 md:col-3 col-6 pr-0">
          <div class="flex">
            <div class="pt-5">
              <icon-filter iconColor="secondary" />
            </div>
            <div class="pl-2">
              <label for="dateDocument">{{ lang.date }}</label>
              <PvCalendar
                id="dateDocument"
                v-model="dateDocument"
                class="inputfield w-full mt-1"
                showIcon
                dateFormat="dd/mm/yy"
                :placeholder="lang.date"
                @date-select="getInvoiceNotification()"
              />
            </div>
          </div>
        </div>
        <div class="lg:col-2 md:col-2 col-6 pl-2 pr-0">
          <label for="installAppStatus">{{ lang.installAppStatus }}</label>
          <PvDropdown
            id="installAppStatus"
            class="inputfield w-full mt-1"
            :options="selectInstallAppStatus"
            optionLabel="name"
            optionValue="code"
            v-model="selectedInstallAppStatus"
            :placeholder="lang.installAppStatus"
            @change="getInvoiceNotification()"
          />
        </div>
        <div class="lg:col-2 md:col-2 col-6 pl-2 pr-0">
          <label for="sendNotifyStatus">{{ lang.sendNotifyStatus }}</label>
          <PvDropdown
            id="sendNotifyStatus"
            class="inputfield w-full mt-1"
            :options="selectSendNotifyStatus"
            optionLabel="name"
            optionValue="code"
            v-model="selectedSendNotifyStatus"
            :placeholder="lang.sendNotifyStatus"
            @change="getInvoiceNotification()"
          />
        </div>
        <div class="lg:col-2 md:col-2 col-6 pl-2 pr-0">
          <label for="readNotifyStatus">{{ lang.readNotifyStatus }}</label>
          <PvDropdown
            id="readNotifyStatus"
            class="inputfield w-full mt-1"
            :options="selectReadNotifyStatus"
            optionLabel="name"
            optionValue="code"
            v-model="selectedReadNotifyStatus"
            :placeholder="lang.readNotifyStatus"
            @change="getInvoiceNotification()"
          />
        </div>
        <div class="flex lg:col-3 md:col-3 col-6 pl-2 pr-0">
          <div class="field-checkbox m-0 mr-2 ml-2">
            <PvCheckbox
              id="selectedIsUnPaid"
              v-model="selectedIsUnPaid"
              :binary="true"
              @change="getInvoiceNotification()"
            />
            <label for="selectedIsUnPaid">{{ lang.unPaidOnly }}</label>
          </div>

          <PvButton class="ml-2 p-button-text" @click="clearFilter()">
            <span class="font-normal">{{ lang.clearFilter }}</span>
          </PvButton>
        </div>
      </div>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="invoiceNotificationsData"
          v-model:selection="selectedInvoiceNotification"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
        >
          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
          <PvColumn
            field="code"
            :header="lang.columnCode"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
          </PvColumn>
          <PvColumn
            field="date"
            :header="lang.columnDate"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.date) }}
            </template>
          </PvColumn>
          <PvColumn
            field="customerName"
            :header="lang.columnCustomerName"
            style="min-width: 20rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>
          <PvColumn
            field="unitCode"
            :header="lang.columnUnit"
            style="min-width: 7rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>
          <PvColumn
            field="unitAddressNo"
            :header="lang.columnAddress"
            style="min-width: 7rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          ></PvColumn>
          <PvColumn
            field="totalAmount"
            :header="lang.columnPrice"
            style="min-width: 5rem"
            headerClass="h-right"
            bodyClass="text-right"
            sortable
          >
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.totalAmount) }}
            </template>
          </PvColumn>
          <PvColumn
            field="postStep"
            :header="lang.columnPostStep"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <base-tag
                :Caption="lang.printed"
                Class="primary"
                v-if="slotProps.data.postStep == 'P'"
              />
            </template>
          </PvColumn>

          <PvColumn
            field="isInstallApp"
            :header="lang.columnInstallApp"
            style="min-width: 5rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <div v-if="slotProps.data.isInstallApp == 'Y'">
                <icon-path />
              </div>
            </template>
          </PvColumn>
          <PvColumn
            field="sendStatus"
            :header="lang.columnSendNotify"
            style="min-width: 5rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <div v-if="slotProps.data.sendStatus == 'Y'">
                <icon-path />
              </div>
            </template>
          </PvColumn>
          <PvColumn
            field="isReadNotify"
            :header="lang.columnReadNotify"
            style="min-width: 5rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <div v-if="slotProps.data.isReadNotify == 'Y'">
                <icon-path />
              </div>
            </template>
          </PvColumn>
          <PvColumn
            style="min-width: 5rem"
            headerClass="h-center"
            bodyStyle="text-align: center;justify-content: center;align-items: center;"
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0"
                @click="sendNotification(slotProps.data, 'once')"
              >
                <icon-bell-circle width="24" height="24"></icon-bell-circle>
              </PvButton>
            </template>
          </PvColumn>
          <template #footer>
            <div>
              <PvPaginator
                :rows="rowsPerPage"
                :totalRecords="totalRecords"
                @page="onPageChange($event)"
                :rowsPerPageOptions="[10, 50, 100]"
                class="p-pagination-radius"
              >
                <template #start="slotProps">
                  {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{
                    totalPage
                  }}
                </template>
              </PvPaginator>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  props: ["projectCode"],
  setup() {
    const { lang } = useLang();
    const { textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.invoice.notification,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.invoice.notification,
        };
      }
    });
    return {
      lang: mergeLang,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
    };
  },
  data() {
    return {
      invoiceNotificationsData: [],
      invoiceTosendNotification: [],
      selectedInvoiceNotification: null,
      isLoading: false,
      selectedPage: 1,
      rowsPerPage: 10,
      selectType: [
        { name: this.lang.code, code: "Code" },
        { name: this.lang.unit, code: "Unit" },
        { name: this.lang.address, code: "Address" },
        { name: this.lang.customerName, code: "CustomerName" },
      ],
      selectedType: "Code",
      selectedIsUnPaid: false,
      selectInstallAppStatus: [
        { name: this.lang.allStatus, code: "All" },
        { name: this.lang.isInstall, code: "Y" },
        { name: this.lang.isNotInstall, code: "N" },
      ],
      selectedInstallAppStatus: "All",
      selectSendNotifyStatus: [
        { name: this.lang.allStatus, code: "All" },
        { name: this.lang.sendNotify, code: "Y" },
        { name: this.lang.notSendNotify, code: "N" },
      ],
      selectedSendNotifyStatus: "All",
      selectReadNotifyStatus: [
        { name: this.lang.allStatus, code: "All" },
        { name: this.lang.readNotify, code: "Y" },
        { name: this.lang.notReadNotify, code: "N" },
      ],
      selectedReadNotifyStatus: "All",
      dateDocument: null,
      searchText: "",
      code: "",
      unitCode: "",
      unitAddress: "",
      customerName: "",
      showConfirmDialog: false,
      confirmTitle: "",
      confirmContent: "",
      confirmClassTitle: "",
      showProgressBar: false,
      isStopProgress: false,
    };
  },
  async created() {
    this.initiateData();
    await this.getInvoiceNotification();
  },
  methods: {
    initiateData() {
      this.dateDocument = new Date();
      this.code = "";
      this.unitCode = "";
      this.unitAddress = "";
      this.customerName = "";
      this.searchText = "";
      this.selectedInstallAppStatus = "All";
      this.selectedSendNotifyStatus = "All";
      this.selectedReadNotifyStatus = "All";
    },
    goBack() {
      this.$router.push({ name: "ar-invoice"})
    },
    async sendNotification(data, type) {
      // console.log("sendNotification", data);
      try {
        const payload = {
          Id: data.id,
          Code: data.code.trim(),
          Badge: 0,
          CustomerId: data.customerId.trim(),
          CustomerCode: data.customerCode.trim(),
          CustomerName: data.customerName.trim(),
          ProjectId: this.selectedProject.id.trim(),
          ProjectCode: this.selectedProject.code.trim(),
          ProjectName: this.selectedProject.nameTh.trim(),
          UnitId: data.unitId.trim(),
          UnitCode: data.unitCode.trim(),
          UnitAddressNo: data.unitAddressNo.trim(),
          InvoiceUrl: `http://13.251.91.42:222/${this.selectedProject.code.trim()}${data.code.trim()}.jpg`,
          UserType: "owner"
        }
        // console.log("sendNotification:payload", payload);
        await this.$store.dispatch("arInvoice/pushNotification", payload);
        if (type === "once") {
          this.$toast.add({
            severity: "success",
            summary: this.lang.sendNotifySuccess,
            life: 3000,
          });
          await this.getInvoiceNotification();
        }
      } catch (error) {
        this.showProgressBar = false;
        // this.openAlertDialog({
        //   severity: "error",
        //   title: this.lang.notificationDialogHeader,
        //   content: error,
        //   isConfirm: false,
        // });
        if (type === "once") {
          this.openAlertDialog({
            severity: "error",
            title: this.lang.notificationDialogHeader,
            content: error,
            isConfirm: false,
          });
        }
        else {
          this.$toast.add({
            severity: "error",
            summary: this.lang.notificationDialogHeader,
            detail: error,
            life: 3000,
          });
        }
      }
    },
    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async sendAllNotification() {
      this.showProgressBar = true;
      this.isStopProgress = false;
      await this.getDataToSendNotify();
      // console.log(this.invoiceTosendNotification);
      this.showConfirmDialog = false;
      let countNoti = 1;
      for (let data of this.invoiceTosendNotification) {
        if (!this.isStopProgress) {
          await this.sendNotification(data, "all");
          data.markStatus = "1";
          // await this.sleep(100);
          // console.log(data);
        } else {
          break;
        }
        if (countNoti === this.allInvoiceNotification.length) {
          this.showProgressBar = false;
          this.isStopProgress = false;
          this.$toast.add({
            severity: "success",
            summary: this.lang.sendNotifySuccess,
            life: 3000,
          });
          this.invoiceTosendNotification = [];
          await this.$store.dispatch("arInvoice/resetAllInvoiceNotification");
          await this.getInvoiceNotification();
        }
        countNoti++;
      }
    },
    changeSelectedType() {
      this.initiateData();
    },
    async getInvoiceNotification() {
      try {
        this.isLoading = true;

        let where = this.getPayload();

        const payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          where,
        };
        // console.log("payload=", payload);
        await this.$store.dispatch("arInvoice/getInvoiceNotification", payload);
        this.invoiceNotificationsData = this.invoiceNotifications;
        this.isLoading = false;
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.isLoading = false;
      }
    },
    async getDataToSendNotify() {
      try {
        let payload = this.getPayload();
        // console.log("payload=", payload);
        await this.$store.dispatch("arInvoice/getAllInvoiceNotification", payload);
        this.invoiceTosendNotification = this.allInvoiceNotification;
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
      }
    },
    async clearFilter() {
      this.initiateData();
      await this.getInvoiceNotification();
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getInvoiceNotification();
    },
    openAlertDialog(dataConfig) {
      if (dataConfig.severity === "") {
        this.confirmClassTitle = "";
      } else if (dataConfig.severity === "success") {
        this.confirmClassTitle = "p-text-success";
      } else if (dataConfig.severity === "error") {
        this.confirmClassTitle = "p-error";
      }

      this.isConfirm = dataConfig.isConfirm;
      this.confirmTitle = dataConfig.title;
      this.confirmContent = dataConfig.content;
      this.showConfirmDialog = true;
    },
    closeAlertDialog() {
      this.showConfirmDialog = false;
    },
    async confirmDialog() {
      if (this.isConfirm === "sendAllNotification") {
        await this.sendAllNotification();
        this.showConfirmDialog = false;
      } else {
        this.closeAlertDialog();
      }
    },
    getPayload() {
      if (this.selectedType === "Code") this.code = this.searchText;
      if (this.selectedType === "Unit") this.unitCode = this.searchText;
      if (this.selectedType === "Address") this.unitAddress = this.searchText;
      if (this.selectedType === "CustomerName") this.customerName = this.searchText;

      let where = {
        projectId: this.selectedProject.id,
        date: this.selectedDateDocument,
        code: this.code || null,
        unitCode: this.unitCode || null,
        unitAddressNo: this.unitAddress || null,
        customerName: this.customerName || null,
        IsUnPaidOnly: this.selectedIsUnPaid,
        IsInstallApp: this.selectedInstallAppStatus,
        SendStatus: this.selectedSendNotifyStatus,
        IsReadNotify: this.selectedReadNotifyStatus,
      };

      return where;
    },
    async closeProgressBar() {
      this.isStopProgress = true;
      this.showProgressBar = false;
      this.invoiceTosendNotification = [];
      await this.$store.dispatch("arInvoice/resetAllInvoiceNotification");
      await this.getInvoiceNotification();
    },
    confirmToSendAllNotification() {
      let statusWording = this.selectInstallAppStatus.find(
        (menu) => menu.code === this.selectedInstallAppStatus
      ).name;

      this.openAlertDialog({
        severity: "",
        title: this.lang.gotoSendNotify,
        content: this.lang.confirmSendAllWording.replace("{status}", statusWording),
        isConfirm: "sendAllNotification",
      });
    },
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    totalPage() {
      return this.$store.getters["arInvoice/totalPage"];
    },
    currentPage() {
      return this.$store.getters["arInvoice/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    invoiceNotifications() {
      return this.$store.getters["arInvoice/dataInvoiceNotification"];
    },
    allInvoiceNotification() {
      return this.$store.getters["arInvoice/allInvoiceNotification"];
    },
    selectedDateDocument() {
      let dateDocument = null;
      if (this.dateDocument) {
        dateDocument = this.formatDateToISOString(this.dateDocument);
      }
      return dateDocument;
    },
    selectedTypePlaceholder() {
      let placeholder = this.lang.search;
      if (this.selectedType === "Code") placeholder += this.lang.code;
      if (this.selectedType === "Unit") placeholder += this.lang.unit;
      if (this.selectedType === "Address") placeholder += this.lang.address;
      if (this.selectedType === "CustomerName") placeholder += this.lang.customerName;
      return placeholder;
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "sendAllNotification",
          caption: this.lang.gotoSendNotify,
          class: "mr-2",
          icon: "icon-bell-notification-alarm",
          iconColor: "white",
          action: "sendAllNotification",
          isShow: true,
        },
      ];
      return dataButtons;
    },
    // valueObj() {
    //   let allInvoiceNotification = [...this.allInvoiceNotification];
    //   if (allInvoiceNotification.length > 0) {
    //     allInvoiceNotification.forEach((element) => {
    //       element.markStatus = "";
    //     });
    //     return allInvoiceNotification;
    //   }
    //   return null;
    // },
    valueObj() {
      if (this.invoiceTosendNotification) {
        return this.invoiceTosendNotification;
      }
      return null;
    },
  },
};
</script>

<style></style>
