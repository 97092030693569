import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export default {
    namespaced: true,
    state(){
        return {
            invoices: [],
            selectedInvoice: null,
            totalPage: 0,
            currentPage: 1,
            rows: 100,
            dataReferenceDocument: [],
            invoiceDateForPrintLots: [],
            invoiceDateForPrintLotTotalPage: 0,
            invoiceDateForPrintLotCurrentPage: 0,      
            invoiceDateForCombineUnits: [],
            invoiceDateForCombineUnitTotalPage: 0,
            invoiceDateForCombineUnitCurrentPage: 0, 
            invoiceDataByAtDates: [],
            invoiceDataByAtDateTotalPage: 0,
            invoiceDataByAtDateCurrentPage: 0,
            dataInvoiceNotification: [],
            allInvoiceNotification: [],
            invoiceNotifyTotalPage: 0,
            invoiceNotifyCurrentPage: 0,

            //หน้าใบเสร็จ ใช้หายอดค้างคงเหลือ
            pendingAmountByUnitAndLivingType: [],
            pendingLists: [],
            pendingListTotalPage: 0,
            pendingListCurrentPage: 0,
            pendingByAmount: [],

            //importInvoice
            importInvoiceList: [],
            validatedImportInvoice: null,

        }
    },
    actions,
    getters,
    mutations
}