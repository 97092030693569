<template>
	<base-toolbar 
		:title="title" 
		:dataButtons="dataButtons" 
		@goBack="goBack" 
		@gotoSave="saveData" 
		@askForCreate="askForCreate"
		@askForDelete="askForDelete">
	</base-toolbar>
	<base-dialog
		v-model:visible="showConfirmDialog"
		:titleConfirm="titleConfirmDialog"
		:wordingConfirm="wordingDialog"
		:dataButtons="dataButtonDialog"
		@confirm="confirmChange()"
		@close="cancelChange()"
		@goto-delete="deleteArProduct"></base-dialog>

	<base-dialog
		v-model:visible="showConfirmDeleteDialog"
		:titleConfirm="titleConfirmDialog"
		:wordingConfirm="wordingDialog"
		@confirm="confirm()"
		@close="close()"></base-dialog>
	
	<base-dialog
		v-model:visible="showDialog"
		:classTitle="baseDialogClassTitle"
		:titleConfirm="titleConfirm"
		:wordingConfirm="validateWording"
		:dataButtons="dataButtonDialog"
		@confirm="showDialog = false"
		@close="showDialog = false"
		@back="goBack"
	></base-dialog>

	<base-penalty-rate-step-dialog
		v-model:visible="showPenaltyCalculation"
		@close="closePenaltyRateStep"
		@penalty-rate-step="addPenaltyRateStep"
		:openFrom="openFrom"
		:productCode="productName"
		:penaltyCode="penaltyName"
		:dateStartLast="dateStartLast"
		:dataUpdatePenalty="dataUpdatePenalty">
	</base-penalty-rate-step-dialog>

	<base-container>
		<div class="surface-card shadow-1 border-round my-5">
			<div
				style="
					padding-left: 24px;
					padding-right: 24px;
					padding-top: 24px;
					padding-bottom: 0px;
				">
				<div class="flex justify-content-between align-items-center">
					<!-- <h2 :class="textHeaderClass">{{ subTitle }}</h2> -->
					<label class="font-lg font-normal">{{ subTitle }}</label>
					<!-- <PvButton
            class="p-button-outlined p-button-primary mr-2"
            style="margin-bottom: 25px;"
            @click="gotoSetupPenalty()"
          >
            <icon-setting
              class="p-button-outlined p-button-primary mr-2"
              iconColor="primary"
              height="16"
              width="16"
            />
            <span class="p-button-label font-normal ml-1">{{lang.setupButtonCaption}}</span>
          </PvButton> -->
				</div>
				<!-- <PvDivider style="margin-top: 0px"></PvDivider> -->
				<PvDivider></PvDivider>
			</div>
			<base-card
				:titleHeader="lang.titleGeneral"
				:isVisibleExpandButton="true"
				:isHideBackground="true"
				:isVisibleCard="true"
				class="pt-2">
				<!-- <base-card
        :titleHeader="lang.titleGeneral"
        :isVisibleExpandButton="true"
        :isHideBackground="true"
        :isVisibleCard="true"
      > -->

				<section class="flex flex-wrap fontlabel" :class="textFormClass">
					<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
						<label>{{ lang.labelCode }}*</label>
						<div class="mt-1">
							<PvInputText
								type="text"
								class="inputfield w-full mt-1"
								v-model="code"
								:placeholder="lang.placeholderCode"
								:class="{ 'p-invalid': codeError }"
								@keyup="codeError = false" />
							<small v-if="codeError" id="code-help" class="p-error">
								{{ lang.validateCode }}
							</small>
						</div>
					</div>

					<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
						<label>{{ lang.labelPaidPriority }}</label>
						<div class="mt-1">
							<PvInputNumber
								id="paidPriority"
								class="inputfield w-full mt-1"
								v-model="paidPriority"
								mode="decimal"
								:placeholder="lang.placeholderPaidPriority" />
						</div>
					</div>

					<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
						<label>{{ lang.labelNameThai }}*</label>
						<div class="mt-1">
							<PvInputText
								type="text"
								class="inputfield w-full mt-1"
								v-model="nameThai"
								:placeholder="lang.placeholderNameThai"
								:class="{ 'p-invalid': nameThaiError }"
								@keyup="nameThaiError = false" />
							<small v-if="nameThaiError" id="name-thai-help" class="p-error">
								{{ lang.validateNameThai }}
							</small>
						</div>
					</div>

					<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
						<label>{{ lang.labelNameEnglish }}</label>
						<div class="mt-1">
							<PvInputText
								type="text"
								class="inputfield w-full mt-1"
								v-model="nameEnglish"
								:placeholder="lang.placeholderNameEnglish"/>
						</div>
					</div>

					<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
						<label>{{ lang.labelPrice }}</label>
						<div class="mt-1">
							<PvInputNumber
								id="price"
								class="inputfield w-full mt-1"
								inputClass="text-left"
								v-model="price"
								mode="decimal"
								:minFractionDigits="2"
								:maxFractionDigits="2"
								:placeholder="lang.placeholderPrice"
								@focus="$event.target.select()" />
						</div>
					</div>

					<!-- <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <div>
              <label or="productUnit">{{ lang.labelProductUnit }}</label>
              <div class="mt-1">
                <base-product-unit-auto-complete
                  id="productUnit"
                  v-model="selectedProductUnit"
                  class="inputfield w-full mt-1"
                  @load-finish="productUnitDataReady"
                  :placeholder="lang.placeholderProductUnit"
                />
            </div>
            </div>
          </div> -->

					<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
						<label>
							{{ lang.labelProductType }}* &nbsp;
							<i
								style="cursor: pointer"
								class="pi pi-exclamation-circle"
								v-tooltip.top="{
									value: lang.tooltipProductType,
									class: 'custom-tooltip',
								}"></i>
						</label>
						<div class="mt-1">
							<PvDropdown
								id="depreFormula"
								class="inputfield w-full mt-1"
								:options="itemProductTypes"
								optionLabel="name"
								optionValue="code"
								v-model="selectedProductType"
								:placeholder="lang.placeholderProductType"
								:class="{ 'p-invalid': productTypeError }"
								@change="this.productTypeChange()" />
							<small v-if="productTypeError" id="product-type-help" class="p-error">
								{{ lang.validateProductType }}
							</small>
						</div>
					</div>

					<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
						<div>
							<label or="productPrepaid">{{ lang.labelProductPrepaid }}</label>
							<div class="mt-1">
								<base-product-prepaid-auto-complete
									id="productPrepaid"
									v-model="selectedProductPrepaid"
									class="inputfield w-full mt-1"
									@load-finish="productPrepaidDataReady"
									:placeholder="lang.placeholderProductPrepaid" />
							</div>
						</div>
					</div>

					<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
						<label>
							{{ lang.labelProductCalculation }}* &nbsp;
							<i
								style="cursor: pointer"
								class="pi pi-exclamation-circle"
								v-tooltip.top="{
									value: lang.tooltipProductCalculation,
									class: 'custom-tooltip',
								}"></i>
						</label>
						<div class="mt-1">
							<PvDropdown
								id="depreFormula"
								class="inputfield w-full mt-1"
								:options="itemProductCalculations"
								optionLabel="name"
								optionValue="code"
								v-model="selectedProductCalculation"
								:placeholder="lang.placeholderProductCalculation"
								:class="{ 'p-invalid': productCalculationError }"
								@change="productCalculationError = false"/>
							<small
								v-if="productCalculationError"
								id="product-calculation-help"
								class="p-error">
								{{ lang.validateProductCalculation }}
							</small>
						</div>
					</div>

					<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
						<label for="isPenalty">{{ lang.labelIsCalculatePenalty }}*</label>
						<div class="mt-1">
							<PvDropdown
								id="isPenalty"
								class="inputfield w-full mt-1"
								:options="itemIsCalculatePenaltys"
								optionLabel="name"
								optionValue="code"
								v-model="selectedIsCalculatePenalty"
								:placeholder="lang.placeholderIsCalculatePenalty"
								:class="{ 'p-invalid': isCalculatePenaltyError }"
								@change="this.validateIsPenalty()" />
							<small
								v-if="isCalculatePenaltyError"
								id="is-calculate-penalty-help"
								class="p-error">
								{{ lang.validateIsCalculatePenalty }}
							</small>
						</div>
					</div>

					<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
						<label for="productPenalty">{{ lang.labelProductPenalty }}</label>
						<div class="mt-1">
							<base-product-penalty-auto-complete
								id="productPenalty"
								v-model="selectedProductPenalty"
								class="inputfield w-full mt-1"
								@load-finish="productPenaltyDataReady"
								:placeholder="lang.placeholderProductPenalty"
								:class="{ 'p-invalid': productPenaltyError }"
								@item-select="this.validateProductPenalty()"
								:disabled="this.selectedIsCalculatePenalty == 'N' || !this.selectedIsCalculatePenalty" />
							<small
								v-if="productPenaltyError"
								id="product-penalty-help"
								class="p-error">
								{{ lang.validateProductPenalty }}
							</small>
						</div>
					</div>

					<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4 mt-4">
						<div class="row flex align-items-center pt-2">
							<PvCheckbox
								id="isIgnoreDuplicatePeriod"
								v-model="selectedIsIgnoreDuplicatePeriod"
								:binary="true" />
							<label class="pl-3">{{ lang.labelIsIgnoreDuplicatePeriod }}</label>
						</div>
					</div>
				</section>
			</base-card>

			<base-card
				:titleHeader="lang.titleAccountChart"
				:isVisibleExpandButton="true"
				:isHideBackground="true"
				:isVisibleCard="true">
				<PVTabView>
					<PVTabPanel>
						<template #header>
							<span>{{ lang.tabInvoice }} </span>
						</template>

						<section class="flex flex-wrap fontlabel" :class="textFormClass">
							<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
								<div class="pt-5" style="margin-bottom: 20px">
									<label style="font-size: 18px">{{ lang.labelAccountDr }}</label>
								</div>

								<div style="margin-bottom: 20px">
									<label for="acountDrInvoicePrevious">
										{{ lang.labelAcountDrInvoicePrevious }}
									</label>
									<base-accounting-detail-auto-complete
										id="acountDrInvoicePrevious"
										v-model="selectedAcountDrInvoicePrevious"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										:isLoadingData="true" 
										@is-inactive="selectedAcountDrInvoicePrevious = null"/>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountDrInvoiceCurrent">
										{{ lang.labelAccountDrInvoiceCurrent }}
									</label>
									<base-accounting-detail-auto-complete
										id="accountDrInvoiceCurrent"
										v-model="selectedAccountDrInvoiceCurrent"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountDrInvoiceCurrent = null"/>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountDrInvoiceForward">
										{{ lang.labelAccountDrInvoiceForward }}
									</label>
									<base-accounting-detail-auto-complete
										id="accountDrInvoiceForward"
										v-model="selectedAccountDrInvoiceForward"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountDrInvoiceForward = null"/>
								</div>
							</div>

							<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
								<div class="pt-5" style="margin-bottom: 20px">
									<label style="font-size: 18px">
										{{ lang.labelAccountCr }}
									</label>
								</div>

								<div style="margin-bottom: 20px">
									<label for="acountCrInvoicePrevious">
										{{ lang.labelAcountCrInvoicePrevious }}
									</label>
									<base-accounting-detail-auto-complete
										id="acountCrInvoicePrevious"
										v-model="selectedAcountCrInvoicePrevious"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAcountCrInvoicePrevious = null"/>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountCrInvoiceCurrent">
										{{ lang.labelAccountCrInvoiceCurrent }}
									</label>
									<base-accounting-detail-auto-complete
										id="accountCrInvoiceCurrent"
										v-model="selectedAccountCrInvoiceCurrent"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountCrInvoiceCurrent = null"/>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountCrInvoiceForward">
										{{ lang.labelAccountCrInvoiceForward }}
									</label>
									<base-accounting-detail-auto-complete
										id="accountCrInvoiceForward"
										v-model="selectedAccountCrInvoiceForward"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountCrInvoiceForward = null"/>
								</div>
							</div>
						</section>
					</PVTabPanel>

					<PVTabPanel>
						<template #header>
							<span>{{ lang.tabReceipt }} </span>
						</template>

						<section class="flex flex-wrap fontlabel" :class="textFormClass">
							<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
								<div class="pt-5" style="margin-bottom: 20px">
									<label style="font-size: 18px">
										{{ lang.labelAccountDr }}
									</label>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountDrReceiptCash">
										{{ lang.labelAccountDrReceiptCash }}
									</label>
									<base-accounting-detail-auto-complete
										id="accountDrReceiptCash"
										v-model="selectedAccountDrReceiptCash"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountDrReceiptCash = null"/>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountDrReceiptCheque">
										{{ lang.labelAccountDrReceiptCheque }}
									</label>
									<base-accounting-detail-auto-complete
										id="accountDrReceiptCheque"
										v-model="selectedAccountDrReceiptCheque"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountDrReceiptCheque = null"/>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountDrReceiptTransfer">
										{{ lang.labelAccountDrReceiptTransfer }}
									</label>
									<base-accounting-detail-auto-complete
										id="accountDrReceiptTransfer"
										v-model="selectedAccountDrReceiptTransfer"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountDrReceiptTransfer = null"/>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountDrReceiptTransferPrevious">
										{{ lang.labelAccountDrReceiptTransferPrevious }}
									</label>
									<base-accounting-detail-auto-complete
										id="accountDrReceiptTransferPrevious"
										v-model="selectedAccountDrReceiptTransferPrevious"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountDrReceiptTransferPrevious = null"/>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountDrReceiptDigitalPayment">{{
										lang.labelAccountDrReceiptDigitalPayment
									}}</label>
									<base-accounting-detail-auto-complete
										id="accountDrReceiptDigitalPayment"
										v-model="selectedAccountDrReceiptDigitalPayment"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountDrReceiptDigitalPayment = null"/>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountDrReceiptCreditCard">{{
										lang.labelAccountDrReceiptCreditCard
									}}</label>
									<base-accounting-detail-auto-complete
										id="accountDrReceiptCreditCard"
										v-model="selectedAccountDrReceiptCreditCard"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountDrReceiptCreditCard = null"/>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountDrReceiptDeposit">{{
										lang.labelAccountDrReceiptDeposit
									}}</label>
									<base-accounting-detail-auto-complete
										id="accountDrReceiptDeposit"
										v-model="selectedAccountDrReceiptDeposit"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountDrReceiptDeposit = null"/>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountDrReceiptOther">{{
										lang.labelAccountDrReceiptOther
									}}</label>
									<base-accounting-detail-auto-complete
										id="accountDrReceiptOther"
										v-model="selectedAccountDrReceiptOther"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountDrReceiptOther = null"/>
								</div>
							</div>

							<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
								<div class="pt-5" style="margin-bottom: 20px">
									<label style="font-size: 18px">{{ lang.labelAccountCr }}</label>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountCrReceiptCash">{{
										lang.labelAccountCrReceiptCash
									}}</label>
									<base-accounting-detail-auto-complete
										id="accountCrReceiptCash"
										v-model="selectedAccountCrReceiptCash"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountCrReceiptCash = null"/>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountCrReceiptCheque">{{
										lang.labelAccountCrReceiptCheque
									}}</label>
									<base-accounting-detail-auto-complete
										id="accountCrReceiptCheque"
										v-model="selectedAccountCrReceiptCheque"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountCrReceiptCheque = null"/>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountCrReceiptTransfer">{{
										lang.labelAccountCrReceiptTransfer
									}}</label>
									<base-accounting-detail-auto-complete
										id="accountCrReceiptTransfer"
										v-model="selectedAccountCrReceiptTransfer"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountCrReceiptTransfer = null"/>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountCrReceiptTransferPrevious">{{
										lang.labelAccountCrReceiptTransferPrevious
									}}</label>
									<base-accounting-detail-auto-complete
										id="accountCrReceiptTransferPrevious"
										v-model="selectedAccountCrReceiptTransferPrevious"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountCrReceiptTransferPrevious = null"/>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountCrReceiptDigitalPayment">{{
										lang.labelAccountCrReceiptDigitalPayment
									}}</label>
									<base-accounting-detail-auto-complete
										id="accountCrReceiptDigitalPayment"
										v-model="selectedAccountCrReceiptDigitalPayment"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountCrReceiptDigitalPayment = null"/>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountCrReceiptCreditCard">{{
										lang.labelAccountCrReceiptCreditCard
									}}</label>
									<base-accounting-detail-auto-complete
										id="accountCrReceiptCreditCard"
										v-model="selectedAccountCrReceiptCreditCard"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountCrReceiptCreditCard = null"/>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountCrReceiptDeposit">{{
										lang.labelAccountCrReceiptDeposit
									}}</label>
									<base-accounting-detail-auto-complete
										id="accountCrReceiptDeposit"
										v-model="selectedAccountCrReceiptDeposit"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountCrReceiptDeposit = null"/>
								</div>

								<div style="margin-bottom: 20px">
									<label for="accountCrReceiptOther">{{
										lang.labelAccountCrReceiptOther
									}}</label>
									<base-accounting-detail-auto-complete
										id="accountCrReceiptOther"
										v-model="selectedAccountCrReceiptOther"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountCrReceiptOther = null"/>
								</div>
							</div>
						</section>
					</PVTabPanel>

					<PVTabPanel>
						<template #header>
							<span>{{ lang.tabDiscount }} </span>
						</template>

						<section class="flex flex-wrap fontlabel" :class="textFormClass">
							<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
								<div class="pt-5" style="margin-bottom: 20px">
									<label style="font-size: 18px">{{ lang.labelAccountDr }}</label>
								</div>

								<div style="margin-bottom: 20px">
									<!-- <label for="accountDrReceiptDiscount">{{lang.labelAccountDrReceiptDiscount}}</label> -->
									<base-accounting-detail-auto-complete
										id="accountDrReceiptDiscount"
										v-model="selectedAccountDrReceiptDiscount"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountDrReceiptDiscount = null"/>
								</div>
							</div>

							<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
								<div class="pt-5" style="margin-bottom: 20px">
									<label style="font-size: 18px">{{ lang.labelAccountCr }}</label>
								</div>

								<div style="margin-bottom: 20px">
									<!-- <label for="accountCrReceiptDiscount">{{lang.labelAccountCrReceiptDiscount}}</label> -->
									<base-accounting-detail-auto-complete
										id="accountCrReceiptDiscount"
										v-model="selectedAccountCrReceiptDiscount"
										class="inputfield w-full mt-2"
										@load-finish="accountChartDataReady"
										:placeholder="lang.placeholderAccount"
										@is-inactive="selectedAccountCrReceiptDiscount = null"/>
								</div>
							</div>
						</section>
					</PVTabPanel>
				</PVTabView>
			</base-card>

			<base-card
				:titleHeader="lang.titlePenaltyProduct"
				:isVisibleExpandButton="true"
				:isHideBackground="true"
				:isVisibleCard="true"
				class="pt-2">
				<PVTabView v-model:activeIndex="activeIndexTabPenalty">
					<PVTabPanel>
						<template #header>
							<span>{{ lang.tabAddProductToRoom }} </span>
						</template>
						<div
							class="justify-content-between align-items-center flex flex-wrap mt-5"
							v-if="selectedProductType === 'N'">
							<div class="flex flex-wrap">
								<div class="col-5">
									<label>{{ lang.labelSearchStart }}</label>
									<base-unit-auto-complete
										id="unit"
										v-model="searchStart"
										class="inputfield w-full mt-2"
										@load-finish="unitDataReady"
									/>
								</div>
								<div class="col-5">
									<label>{{ lang.labelSearchEnd }}</label>
									<base-unit-auto-complete
										id="unit"
										v-model="searchEnd"
										class="inputfield w-full mt-2"
										@load-finish="unitDataReady"
									/>
								</div>
								<div class="col-1" style="margin-top: 1.8rem">
									<PvButton class="p-button-primary" @click="searchTabRoom()">
										<i class="pi pi-search" />
									</PvButton>
								</div>
							</div>

							<div class="flex flex-wrap mt-3">
								<PvButton
									class="p-button-primary mr-2"
									@click="addProductToAllRoom()">
									<icon-download iconColor="white" />
									<span class="ml-1">{{ this.lang.labelAddAllRoom }}</span>
								</PvButton>
								<PvButton
									class="p-button-danger"
									@click="deleteProductFromAllRoom()">
									<icon-trash-delete-bin iconColor="white" />
									<span class="ml-1"> {{ this.lang.labelDeleteAllRoom }} </span>
								</PvButton>
							</div>
						</div>

						<div class="my-5">
							<PvDataTable
								:value="unitProductList"
								responsiveLayout="scroll"
								dataKey="id"
								:loading="isLoadingUnitProduct"
								class="editable-cells-table font-weight-400">
								<template #empty>
									<div class="w-full p-3" style="text-align: center">
										<label>{{ this.lang.tableEmpty }}</label>
									</div>
								</template>

								<PvColumn
									field="code"
									:header="lang.columnUnitCode"
									style="width: 10%"></PvColumn>

								<PvColumn
									field="addressNo"
									:header="lang.columnUnitAddress"
									style="width: 10%"></PvColumn>

								<PvColumn
									field="productCode"
									:header="lang.columnProductCode"
									style="width: 10%"></PvColumn>

								<PvColumn
									field="productNameThai"
									:header="lang.columnProductName"
									style="width: 40%"></PvColumn>

								<PvColumn :header="lang.columnStatus" style="width: 15%">
									<template #body="slotProps">
										<!-- {{ unitProductDetails[slotProps.index].status }} -->
										<base-tag
											v-if="slotProps.data.status === 'added'"
											:Caption="lang.labelTagAdded"
											Class="success" />
										<base-tag
											v-else-if="slotProps.data.status === 'waiting'"
											:Caption="lang.labelTagWaiting"
											Class="secondary" />
										<base-tag
											v-else-if="slotProps.data.status.includes('pending')"
											:Caption="lang.labelTagPending"
											Class="warning" />
									</template>
								</PvColumn>

								<PvColumn
									field="itemNumber"
									:header="lang.columnAction"
									style="width: 15%"
									headerClass="h-center "
									bodyClass="text-center">
									<template #body="slotProps">
										<PvButton
											type="button"
											class="p-button-outlined"
											v-if="
												slotProps.data.status === 'waiting' ||
												slotProps.data.status === 'pendingDelete'
											"
											@click="addProductToRoom(slotProps.data)">
											<icon-download
												class="mx-auto"
												iconColor="primary"
												height="14"
												width="14" />
											{{ lang.labelAddToRoom }}
										</PvButton>
										<PvButton
											type="button"
											class="p-button-outlined p-button-danger"
											v-else-if="
												slotProps.data.status === 'added' ||
												slotProps.data.status === 'pendingAdded'
											"
											@click="deleteProductFromRoom(slotProps.data)">
											<icon-trash-delete-bin
												class="mx-auto"
												iconColor="error"
												height="14"
												width="14" />
											{{ lang.labelDeleteFromRoom }}
										</PvButton>
									</template>
								</PvColumn>

								<template #footer>
									<PvPaginator
										:rows="rowsPerPage"
										:totalRecords="totalRecords"
										@page="onPageChange($event)"
										:rowsPerPageOptions="[10, 50, 100]">
										<template #start="slotProps">
											{{ lang.page }}:
											{{ totalPage ? slotProps.state.page + 1 : 0 }} /{{
												totalPage
											}}
										</template>
									</PvPaginator>
								</template>
							</PvDataTable>
						</div>
					</PVTabPanel>
					<PVTabPanel :disabled="this.selectedIsCalculatePenalty === 'N' || !this.selectedIsCalculatePenalty">
						<template #header>
							<span>{{ lang.tabPenaltyProduct }} </span>
						</template>
						<div class="flex justify-content-end flex-wrap mt-3">
							<PvButton
								class="p-button-outlined p-button-primary mr-2"
								@click="openPenaltyRateStep"
								v-if="this.selectedIsCalculatePenalty === 'Y'">
								<span class="">{{ this.lang.addPenaltyRateStep }}</span>
							</PvButton>
						</div>
						<PvDivider></PvDivider>
						<div class="my-5">
							<PvDataTable
								:value="penaltyDetails"
								responsiveLayout="scroll"
								dataKey="id"
								:loading="isLoading"
								editMode="cell"
								class="editable-cells-table font-weight-400"
								selectionMode="single">
								<template #empty>
									<div class="w-full p-3" style="text-align: center">
										<label>{{ this.lang.tableEmpty }}</label>
									</div>
								</template>
								<PvColumn
									field="itemNumber"
									:header="lang.itemNo"
									style="width: 15%"
									headerClass="h-center "
									bodyClass="text-center"></PvColumn>
								<PvColumn
									field="dateStart"
									:header="lang.columndateStart"
									style="width: 35%"
									headerClass="h-center "
									bodyClass="text-center">
								</PvColumn>
								<PvColumn
									field="penaltyRate"
									:header="lang.columnpenaltyRate"
									style="width: 35%"
									headerClass="h-center "
									bodyClass="text-center">
									<template #body="slotProps">
										{{ formatCurrency(slotProps.data.penaltyRate) }}
									</template>
								</PvColumn>
								<PvColumn
									style="width: 15%"
									headerClass="h-center"
									bodyStyle="text-align: center; overflow: visible"
									v-if="this.selectedIsCalculatePenalty === 'Y'">
									<template #body="slotProps">
										<PvButton
											type="button"
											class="p-button-secondary p-button-text py-0"
											@click="editPenalty(slotProps.data, slotProps.index)"
										>
											<icon-pen-edit iconColor="secondary" width="24" height="24" />
										</PvButton>
										<PvButton
											type="button"
											class="p-button-danger p-button-text p-0"
											@click="deleteDataDummy(slotProps.data.id)">
											<icon-trash-delete-bin iconColor="error" />
										</PvButton>
									</template>
								</PvColumn>
							</PvDataTable>
						</div>
					</PVTabPanel>
				</PVTabView>
			</base-card>
		</div>
	</base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import usePageDirty from "../../../hooks/pageDirty";
import BasePenaltyRateStepDialog from "../../../components/master/product/BasePenaltyRateStepDialog.vue";
// import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import useGetProduct from "../../../hooks/getProductAR";
export default {
	props: ["id", "projectCode"],
	components: { BasePenaltyRateStepDialog },
	setup() {
		const { lang } = useLang();
		const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
		const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
		const { compareObject, isCompareObject } = usePageDirty();
		const store = useStore();
		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return {
					...lang.th.base,
					...lang.th.master.accountReceivableProduct.edit,
					...lang.th.master.product,
				};
			} else {
				return {
					...lang.en.base,
					...lang.en.master.accountReceivableProduct.edit,
					...lang.th.master.product,
				};
			}
		});

		// const { getPreviewBlob, getDownloadBlob } = useGetFormPrint();
		const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();
		const { refreshProduct } = useGetProduct();

		return {
			lang: mergeLang,
			textHeaderClass,
			textFormClass,
			textTableClass,
			formatCurrency,
			formatDate,
			formatDateToISOString,
			compareObject,
			isCompareObject,
			//   getPreviewBlob,
			//   getDownloadBlob,
			getRoleByMenuByCode,
      		isSharedRoleByMenuReady,
			refreshProduct,
		};
	},
	data() {
		return {
			// dataButtons: [
			// 	{
			// 		id: "editCaption",
			// 		action: "askForCreate",
			// 		type: "create"
			// 	},
			// 	{
			// 		id: "saveCaption",
			// 		action: "gotoSave",
			// 		type: "save"
			// 	},
			// ],

			code: "",
			codeError: false,
			nameThai: "",
			nameThaiError: false,
			nameEnglish: "",
			nameEnglishError: false,

			paidPriority: 99,
			price: 0,

			// isSharedProductUnitReady: false,
			// selectedProductUnit: null,

			isSharedProductPenaltReady: false,
			selectedProductPenalty: null,
			productPenaltyError: false,

			isSharedProductPrepaidReady: false,
			selectedProductPrepaid: null,

			isSharedAccountChartReady: false,
			selectedAcountDrInvoicePrevious: null,
			selectedAcountCrInvoicePrevious: null,
			selectedAccountDrInvoiceCurrent: null,
			selectedAccountCrInvoiceCurrent: null,
			selectedAccountDrInvoiceForward: null,
			selectedAccountCrInvoiceForward: null,
			selectedAccountDrReceiptCash: null,
			selectedAccountCrReceiptCash: null,
			selectedAccountDrReceiptCheque: null,
			selectedAccountCrReceiptCheque: null,
			selectedAccountDrReceiptTransfer: null,
			selectedAccountCrReceiptTransfer: null,
			selectedAccountDrReceiptTransferPrevious: null,
			selectedAccountCrReceiptTransferPrevious: null,
			selectedAccountDrReceiptDigitalPayment: null,
			selectedAccountCrReceiptDigitalPayment: null,
			selectedAccountDrReceiptCreditCard: null,
			selectedAccountCrReceiptCreditCard: null,
			selectedAccountDrReceiptDeposit: null,
			selectedAccountCrReceiptDeposit: null,
			selectedAccountDrReceiptOther: null,
			selectedAccountCrReceiptOther: null,
			selectedAccountDrReceiptDiscount: null,
			selectedAccountCrReceiptDiscount: null,

			selectedProductType: "",
			productTypeError: false,
			itemProductTypes: [
				{ name: this.lang.itemProductType1, code: "Y" },
				{ name: this.lang.itemProductType2, code: "N" },
				{ name: this.lang.itemProductType3, code: "P" },
			],
			selectedProductCalculation: "",
			productCalculationError: false,
			itemProductCalculations: [
				{ name: this.lang.itemProductCalculation1, code: "P" },
				{ name: this.lang.itemProductCalculation2, code: "G" },
				{ name: this.lang.itemProductCalculation3, code: "S" },
			],
			selectedIsCalculatePenalty: "",
			isCalculatePenaltyError: false,
			itemIsCalculatePenaltys: [
				{ name: this.lang.itemIsCalculatePenalty1, code: "Y" },
				{ name: this.lang.itemIsCalculatePenalty2, code: "N" },
			],

			selectedIsVat: "N",
			selectedIsIgnoreDuplicatePeriod: false,

			//penalty card #akkaraphon
			showConfirmDialog: false,
			wordingDialog: "",
			showConfirmDialogName: "",
			titleConfirmDialog: "",

			productMethodCode: null,
			penaltyMethodCode: null,
			penaltyDetails: [],
			penaltyId: "",

			isLoading: true,
			selectedProduct: null,
			openFrom: "",
			showPenaltyCalculation: false,
			indexUpdatePenalty: null,
			dataUpdatePenalty: null,
			activeIndexTabPenalty: 0,
			searchStart: "",
			searchEnd: "",
			itemSerachBy: [
				{ name: this.lang.itemSearchByAll, code: "All" },
				{ name: this.lang.itemSearchByUnitCode, code: "UnitCode" },
				{ name: this.lang.itemSearchByUnitAddress, code: "AddressNo" },
			],
			selectedSerachBy: "All",
			selectedPage: 1,
			rowsPerPage: 10,
			roomToDeleteProduct: [],
			roomToAddProduct: [],
			selectedUnitProduct: [],
			unitProductList: [],
			waitProduct: [],
			isLoadingUnitProduct: false,
			confirmAddOrDelete: "",
			showConfirmDeleteDialog: false,
			isSharedUnitReady: false,

			closeDialog: false,
			isDelete: false,
			isDeleteSuccess: false,
			showDialog: false,
			baseDialogClassTitle: "",
			titleConfirm: "",
			validateWording: "",
		};
	},
	async created() {
		// await this.initiateData();
		await this.$store.dispatch("shared/getAllProductARs");
		await this.getPenaltyRateStep();
		if (!this.isCreateDocument) {
			await this.getProduct();
		} else {
			await this.initiateData();
		}
	},
	// mounted() {
	// 	const objToCompare = {
	//     code: this.code,
	//     projectId: this.selectedProject.id,
	//     nameThai: this.nameThai,
	//     nameEnglish: this.nameEnglish,
	//     paidPriority: this.paidPriority,
	//     price: this.price,
	//     // unitId: this.selectedProductUnit,
	//     type: this.selectedProductType,
	//     calculation: this.selectedProductCalculation,
	//     isCalculatePenalty: this.selectedIsCalculatePenalty,
	//     penaltyId: this.selectedProductPenalty ? this.selectedProductPenalty.id : "",
	//     prepaidId: this.selectedProductPrepaid ? this.selectedProductPrepaid.id : "",
	//     isVat: this.selectedIsVat,
	//     isIgnoreDuplicatePeriod: this.selectedIsIgnoreDuplicatePeriod?"R":"",
	//     accountIdDrInvoicePrevious: this.selectedAcountDrInvoicePrevious ? this.selectedAcountDrInvoicePrevious.id : "",
	//     accountIdCrInvoicePrevious: this.selectedAcountCrInvoicePrevious ? this.selectedAcountCrInvoicePrevious.id : "",
	//     accountIdDrInvoiceCurrent: this.selectedAccountDrInvoiceCurrent ? this.selectedAccountDrInvoiceCurrent.id : "",
	//     accountIdCrInvoiceCurrent: this.selectedAccountCrInvoiceCurrent ? this.selectedAccountCrInvoiceCurrent.id : "",
	//     accountIdDrInvoiceForward: this.selectedAccountDrInvoiceForward ? this.selectedAccountDrInvoiceForward.id : "",
	//     accountIdCrInvoiceForward: this.selectedAccountCrInvoiceForward ? this.selectedAccountCrInvoiceForward.id : "",
	//     accountIdDrReceiptCash: this.selectedAccountDrReceiptCash ? this.selectedAccountDrReceiptCash.id : "",
	//     accountIdCrReceiptCash: this.selectedAccountCrReceiptCash ? this.selectedAccountCrReceiptCash.id : "",
	//     accountIdDrReceiptCheque: this.selectedAccountDrReceiptCheque ? this.selectedAccountDrReceiptCheque.id : "",
	//     accountIdCrReceiptCheque: this.selectedAccountCrReceiptCheque ? this.selectedAccountCrReceiptCheque.id : "",
	//     accountIdDrReceiptTransfer: this.selectedAccountDrReceiptTransfer ? this.selectedAccountDrReceiptTransfer.id : "",
	//     accountIdCrReceiptTransfer: this.selectedAccountCrReceiptTransfer ? this.selectedAccountCrReceiptTransfer.id : "",
	//     accountIdDrReceiptTransferPrevious: this.selectedAccountDrReceiptTransferPrevious ? this.selectedAccountDrReceiptTransferPrevious.id : "",
	//     accountIdCrReceiptTransferPrevious: this.selectedAccountCrReceiptTransferPrevious ? this.selectedAccountCrReceiptTransferPrevious.id : "",
	//     accountIdDrReceiptDigitalPayment: this.selectedAccountDrReceiptDigitalPayment ? this.selectedAccountDrReceiptDigitalPayment.id : "",
	//     accountIdCrReceiptDigitalPayment: this.selectedAccountCrReceiptDigitalPayment ? this.selectedAccountCrReceiptDigitalPayment.id : "",
	//     accountIdDrReceiptCreditCard: this.selectedAccountDrReceiptCreditCard ? this.selectedAccountDrReceiptCreditCard.id : "",
	//     accountIdCrReceiptCreditCard: this.selectedAccountCrReceiptCreditCard ? this.selectedAccountCrReceiptCreditCard.id : "",
	//     accountIdDrReceiptDeposit: this.selectedAccountDrReceiptDeposit ? this.selectedAccountDrReceiptDeposit.id : "",
	//     accountIdCrReceiptDeposit: this.selectedAccountCrReceiptDeposit ? this.selectedAccountCrReceiptDeposit.id : "",
	//     accountIdDrReceiptOther: this.selectedAccountDrReceiptOther ? this.selectedAccountDrReceiptOther.id : "",
	//     accountIdCrReceiptOther: this.selectedAccountCrReceiptOther ? this.selectedAccountCrReceiptOther.id : "",
	//     accountIdDrReceiptDiscount: this.selectedAccountDrReceiptDiscount ? this.selectedAccountDrReceiptDiscount.id : "",
	//     accountIdCrReceiptDiscount: this.selectedAccountCrReceiptDiscount ? this.selectedAccountCrReceiptDiscount.id : "",
	//     actionType: "AddArProduct",
	// 	}
	// 	this.objToCompare = objToCompare
	//   if (!this.isCreateDocument) {
	//     this.objToCompare = { ...this.objToCompare, id: this.id, actionType: "UpdateArProduct" };
	//   }
	// 	this.compareObject(this.objToCompare, this.payload)
	// },
	methods: {
		setLoading(bool) {
			this.$store.dispatch("setLoading", { value: bool });
		},
		goBack() {
			this.setLocalSearch();
			this.compareObject(this.objToCompare, this.payload);
			this.$router.push({name: "ar-product"});
		},
		async setObjectToCompare() {
			const objToCompare = {
				code: this.code,
				projectId: this.selectedProject.id,
				nameThai: this.nameThai,
				nameEnglish: this.nameEnglish,
				paidPriority: this.paidPriority,
				price: this.price,
				// unitId: this.selectedProductUnit,
				type: this.selectedProductType,
				calculation: this.selectedProductCalculation,
				isCalculatePenalty: this.selectedIsCalculatePenalty,
				penaltyId: this.selectedProductPenalty ? this.selectedProductPenalty.id : "",
				prepaidId: this.selectedProductPrepaid ? this.selectedProductPrepaid.id : "",
				isVat: this.selectedIsVat,
				isIgnoreDuplicatePeriod: this.selectedIsIgnoreDuplicatePeriod ? "R" : "C",
				accountIdDrInvoicePrevious: this.selectedAcountDrInvoicePrevious
					? this.selectedAcountDrInvoicePrevious.id
					: "",
				accountIdCrInvoicePrevious: this.selectedAcountCrInvoicePrevious
					? this.selectedAcountCrInvoicePrevious.id
					: "",
				accountIdDrInvoiceCurrent: this.selectedAccountDrInvoiceCurrent
					? this.selectedAccountDrInvoiceCurrent.id
					: "",
				accountIdCrInvoiceCurrent: this.selectedAccountCrInvoiceCurrent
					? this.selectedAccountCrInvoiceCurrent.id
					: "",
				accountIdDrInvoiceForward: this.selectedAccountDrInvoiceForward
					? this.selectedAccountDrInvoiceForward.id
					: "",
				accountIdCrInvoiceForward: this.selectedAccountCrInvoiceForward
					? this.selectedAccountCrInvoiceForward.id
					: "",
				accountIdDrReceiptCash: this.selectedAccountDrReceiptCash
					? this.selectedAccountDrReceiptCash.id
					: "",
				accountIdCrReceiptCash: this.selectedAccountCrReceiptCash
					? this.selectedAccountCrReceiptCash.id
					: "",
				accountIdDrReceiptCheque: this.selectedAccountDrReceiptCheque
					? this.selectedAccountDrReceiptCheque.id
					: "",
				accountIdCrReceiptCheque: this.selectedAccountCrReceiptCheque
					? this.selectedAccountCrReceiptCheque.id
					: "",
				accountIdDrReceiptTransfer: this.selectedAccountDrReceiptTransfer
					? this.selectedAccountDrReceiptTransfer.id
					: "",
				accountIdCrReceiptTransfer: this.selectedAccountCrReceiptTransfer
					? this.selectedAccountCrReceiptTransfer.id
					: "",
				accountIdDrReceiptTransferPrevious: this.selectedAccountDrReceiptTransferPrevious
					? this.selectedAccountDrReceiptTransferPrevious.id
					: "",
				accountIdCrReceiptTransferPrevious: this.selectedAccountCrReceiptTransferPrevious
					? this.selectedAccountCrReceiptTransferPrevious.id
					: "",
				accountIdDrReceiptDigitalPayment: this.selectedAccountDrReceiptDigitalPayment
					? this.selectedAccountDrReceiptDigitalPayment.id
					: "",
				accountIdCrReceiptDigitalPayment: this.selectedAccountCrReceiptDigitalPayment
					? this.selectedAccountCrReceiptDigitalPayment.id
					: "",
				accountIdDrReceiptCreditCard: this.selectedAccountCrReceiptCreditCard
					? this.selectedAccountCrReceiptCreditCard.id
					: "",
				accountIdCrReceiptCreditCard: this.selectedAccountDrReceiptCreditCard
					? this.selectedAccountDrReceiptCreditCard.id
					: "",
				accountIdDrReceiptDeposit: this.selectedAccountDrReceiptDeposit
					? this.selectedAccountDrReceiptDeposit.id
					: "",
				accountIdCrReceiptDeposit: this.selectedAccountCrReceiptDeposit
					? this.selectedAccountCrReceiptDeposit.id
					: "",
				accountIdDrReceiptOther: this.selectedAccountDrReceiptOther
					? this.selectedAccountDrReceiptOther.id
					: "",
				accountIdCrReceiptOther: this.selectedAccountCrReceiptOther
					? this.selectedAccountCrReceiptOther.id
					: "",
				accountIdDrReceiptDiscount: this.selectedAccountDrReceiptDiscount
					? this.selectedAccountDrReceiptDiscount.id
					: "",
				accountIdCrReceiptDiscount: this.selectedAccountCrReceiptDiscount
					? this.selectedAccountCrReceiptDiscount.id
					: "",
				penaltyRateStep: this.penaltyDetails,
				roomForAddProduct: this.roomToAddProduct,
				roomForDeleteProduct: this.roomToDeleteProduct,
				actionType: "AddArProduct",
			};
			this.objToCompare = objToCompare;
			if (!this.isCreateDocument) {
				this.objToCompare = {
					...this.objToCompare,
					id: this.id,
					actionType: "UpdateArProduct",
				};
			}
			this.compareObject(this.objToCompare, this.payload);
		},
		gotoSetupPenalty() {
			// console.log("gotoSetupPenalty.payload=", this.payload);
			// console.log("gotoSetupPenalty.selectedArProduct=", this.selectedArProduct);
		},
		async getProduct() {
			try {
				this.setLoading(true);
				await this.$store.dispatch("arProduct/getArProductById", this.id);
			} catch (error) {
				this.baseDialogClassTitle = "";
				this.titleConfirm = this.lang.toastHeaderMsgDefault;
        		this.validateWording = error;
        		this.showDialog = true;
				this.closeDialog = true;
			}
			this.setLoading(false);
		},
		checkShouldSetData() {
			if (
				this.selectedArProduct &&
				// this.isSharedAccountChartReady &&
				// this.isSharedProductUnitReady &&
				this.isSharedProductPenaltyReady &&
				this.isSharedProductPrepaidReady
			) {
				this.setData();
			}
		},
		accountChartDataReady() {
			this.isSharedAccountChartReady = true;
			this.checkShouldSetData();
		},
		// productUnitDataReady() {
		//   this.isSharedProductUnitReady = true;
		// },
		productPenaltyDataReady() {
			this.isSharedProductPenaltyReady = true;
			this.checkShouldSetData();
		},
		productPrepaidDataReady() {
			this.isSharedProductPrepaidReady = true;
			this.checkShouldSetData();
		},
		async initiateData() {
			await this.$store.dispatch("arProduct/resetArProduct");
			this.code = "";
			this.paidPriority = 99;
			this.nameThai = "";
			this.nameEnglish = "";
			this.price = 0;
			// this.selectedProductUnit = null;
			this.selectedProductType = "";
			this.selectedProductCalculation = "";
			this.selectedIsCalculatePenalty = "";
			this.selectedProductPenalty = null;
			this.selectedProductPrepaid = null;
			this.selectedIsVat = "N";
			this.selectedIsIgnoreDuplicatePeriod = false;
			this.selectedAcountDrInvoicePrevious = null;
			this.selectedAcountCrInvoicePrevious = null;
			this.selectedAccountDrInvoiceCurrent = null;
			this.selectedAccountCrInvoiceCurrent = null;
			this.selectedAccountDrInvoiceForward = null;
			this.selectedAccountCrInvoiceForward = null;
			this.selectedAccountDrReceiptCash = null;
			this.selectedAccountCrReceiptCash = null;
			this.selectedAccountDrReceiptCheque = null;
			this.selectedAccountCrReceiptCheque = null;
			this.selectedAccountDrReceiptTransfer = null;
			this.selectedAccountCrReceiptTransfer = null;
			this.selectedAccountDrReceiptTransferPrevious = null;
			this.selectedAccountCrReceiptTransferPrevious = null;
			this.selectedAccountDrReceiptDigitalPayment = null;
			this.selectedAccountCrReceiptDigitalPayment = null;
			this.selectedAccountDrReceiptCreditCard = null;
			this.selectedAccountCrReceiptCreditCard = null;
			this.selectedAccountDrReceiptDeposit = null;
			this.selectedAccountCrReceiptDeposit = null;
			this.selectedAccountDrReceiptOther = null;
			this.selectedAccountCrReceiptOther = null;
			this.selectedAccountDrReceiptDiscount = null;
			this.selectedAccountCrReceiptDiscount = null;
			this.penaltyId = "";
			this.productMethodCode = null;
			this.penaltyMethodCode = null;
			this.penaltyDetails = [];

			this.codeError = false;
			this.nameThaiError = false;
			this.productTypeError = false;
			this.productCalculationError = false;
			this.productPenaltyError = false;
			this.isCalculatePenaltyError = false;

			await this.setObjectToCompare();
		},
		async setData() {
			if (this.selectedArProduct) {
				const product = this.selectedArProduct;
				this.code = product.code.trim();
				this.paidPriority = product.paidPriority;
				this.nameThai = product.nameThai.trim();
				this.nameEnglish = product.nameEnglish.trim();
				this.price = product.price;
				// this.selectedProductUnit = this.sharedProductUnits.find((productUnit) => productUnit.id === product.unitId);
				this.selectedProductType = product.type.trim();
				this.selectedProductCalculation = product.calculation.trim();
				this.selectedIsCalculatePenalty = product.isCalculatePenalty.trim();
				let penalty = this.findProduct(product.penaltyId);
				this.selectedProductPenalty = penalty.find(
					(penalty) => penalty.id === product.penaltyId
				);
				let prepaid = this.findProduct(product.prepaidId);
				this.selectedProductPrepaid = prepaid.find(
					(prepaid) => prepaid.id === product.prepaidId
				);
				this.selectedIsVat = "N";
				this.selectedIsIgnoreDuplicatePeriod =
					product.isIgnoreDuplicatePeriod == "Y" ? true : false;
				this.selectedAcountDrInvoicePrevious = this.sharedAccounts.find(
					(account) => account.id === product.accountIdDrInvoicePrevious
				);
				this.selectedAcountCrInvoicePrevious = this.sharedAccounts.find(
					(account) => account.id === product.accountIdCrInvoicePrevious
				);
				this.selectedAccountDrInvoiceCurrent = this.sharedAccounts.find(
					(account) => account.id === product.accountIdDrInvoiceCurrent
				);
				this.selectedAccountCrInvoiceCurrent = this.sharedAccounts.find(
					(account) => account.id === product.accountIdCrInvoiceCurrent
				);
				this.selectedAccountDrInvoiceForward = this.sharedAccounts.find(
					(account) => account.id === product.accountIdDrInvoiceForward
				);
				this.selectedAccountCrInvoiceForward = this.sharedAccounts.find(
					(account) => account.id === product.accountIdCrInvoiceForward
				);
				this.selectedAccountDrReceiptCash = this.sharedAccounts.find(
					(account) => account.id === product.accountIdDrReceiptCash
				);
				this.selectedAccountCrReceiptCash = this.sharedAccounts.find(
					(account) => account.id === product.accountIdCrReceiptCash
				);
				this.selectedAccountDrReceiptCheque = this.sharedAccounts.find(
					(account) => account.id === product.accountIdDrReceiptCheque
				);
				this.selectedAccountCrReceiptCheque = this.sharedAccounts.find(
					(account) => account.id === product.accountIdCrReceiptCheque
				);
				this.selectedAccountDrReceiptTransfer = this.sharedAccounts.find(
					(account) => account.id === product.accountIdDrReceiptTransfer
				);
				this.selectedAccountCrReceiptTransfer = this.sharedAccounts.find(
					(account) => account.id === product.accountIdCrReceiptTransfer
				);
				this.selectedAccountDrReceiptTransferPrevious = this.sharedAccounts.find(
					(account) => account.id === product.accountIdDrReceiptTransferPrevious
				);
				this.selectedAccountCrReceiptTransferPrevious = this.sharedAccounts.find(
					(account) => account.id === product.accountIdCrReceiptTransferPrevious
				);
				this.selectedAccountDrReceiptDigitalPayment = this.sharedAccounts.find(
					(account) => account.id === product.accountIdDrReceiptDigitalPayment
				);
				this.selectedAccountCrReceiptDigitalPayment = this.sharedAccounts.find(
					(account) => account.id === product.accountIdCrReceiptDigitalPayment
				);
				this.selectedAccountDrReceiptCreditCard = this.sharedAccounts.find(
					(account) => account.id === product.accountIdCrReceiptCreditCard
				);
				this.selectedAccountCrReceiptCreditCard = this.sharedAccounts.find(
					(account) => account.id === product.accountIdDrReceiptCreditCard
				);
				this.selectedAccountDrReceiptDeposit = this.sharedAccounts.find(
					(account) => account.id === product.accountIdDrReceiptDeposit
				);
				this.selectedAccountCrReceiptDeposit = this.sharedAccounts.find(
					(account) => account.id === product.accountIdCrReceiptDeposit
				);
				this.selectedAccountDrReceiptOther = this.sharedAccounts.find(
					(account) => account.id === product.accountIdDrReceiptOther
				);
				this.selectedAccountCrReceiptOther = this.sharedAccounts.find(
					(account) => account.id === product.accountIdCrReceiptOther
				);
				this.selectedAccountDrReceiptDiscount = this.sharedAccounts.find(
					(account) => account.id === product.accountIdDrReceiptDiscount
				);
				this.selectedAccountCrReceiptDiscount = this.sharedAccounts.find(
					(account) => account.id === product.accountIdCrReceiptDiscount
				);

				this.penaltyId = product.penaltyId;
				this.productMethodCode = this.productName;
				this.penaltyMethodCode = this.penaltyName;
				this.penaltyDetails = this.penaltyRateStep;

				if (this.selectedProductType === "N") {
					await this.searchTabRoom();
				}

				//this.setLoading(false);
				await this.setObjectToCompare();
			}
		},
		findProduct(id) {
			let itemList = [];
			let productArs = this.$store.getters["shared/productArProducts"];
			if (productArs) {
				productArs.forEach((element) => {
					if (element.id == id) {
						element = {
							...element,
							...{ codeAndName: element.code + " : " + element.nameThai },
						};
						itemList.push(element);
					}
				});
			}
			return itemList;
		},
		validateIsPenalty() {
			this.isCalculatePenaltyError = false;
			this.productPenaltyError = false;
			if (this.selectedIsCalculatePenalty == "N") {
				this.selectedProductPenalty = null;
				this.activeIndexTabPenalty = 0;
			}
		},
		validateProductPenalty() {
			this.productPenaltyError = false;
			if (this.selectedProductPenalty) {
				this.selectedIsCalculatePenalty = "Y";
				this.penaltyId = this.selectedProductPenalty.id;
				this.penaltyName = this.penaltyId;
			} else {
				this.selectedIsCalculatePenalty = "N";
			}
		},
		validateData() {
			let returnStatus = false
			this.clearBaseDialog();
			this.closeDialog = true;
			if(this.isCreateDocument && !this.isRoleCreate) {
				this.showDialog = true;
				this.titleConfirm = this.lang.notificationDialogHeader
				this.validateWording = this.lang.validateRoleInsert;
				returnStatus = true;
			} else if(!this.isCreateDocument && !this.isRoleUpdate) {
				this.showDialog = true;
				this.titleConfirm = this.lang.notificationDialogHeader
				this.validateWording = this.lang.validateRoleUpdate;
				returnStatus = true;
			}

			if (!this.code.trim()) {
				this.codeError = true;
				returnStatus = true;
			}
			if (!this.nameThai.trim()) {
				this.nameThaiError = true;
				returnStatus = true;
			}
			if (!this.selectedProductType) {
				this.productTypeError = true;
				returnStatus = true;
			}
			if (!this.selectedProductCalculation) {
				this.productCalculationError = true;
				returnStatus = true;
			}
			if (this.selectedIsCalculatePenalty == "Y" && !this.selectedProductPenalty) {
				this.productPenaltyError = true;
				returnStatus = true;
			}
			if (!this.selectedIsCalculatePenalty) {
				this.isCalculatePenaltyError = true;
				returnStatus = true;
			}
			if (this.code.charAt(0).toLowerCase() == "z" && this.selectedProductPenalty) {
				this.isCalculatePenaltyError = true;
				returnStatus = true;
			}

			if (returnStatus) {
				this.baseDialogClassTitle = "p-text-error";
				this.titleConfirm = this.lang.validateSaveTitle;
        		this.validateWording = this.wordingValidate();
        		this.showDialog = true;
			}
			return returnStatus;
		},
		wordingValidate(){
			if (!this.code.trim()) {
				return this.lang.validateFieldNoValue + this.lang.labelCode;
			}

			if (!this.nameThai.trim()) {
				return this.lang.validateFieldNoValue + this.lang.labelNameThai;
			}
			if (!this.selectedProductType) {
				this.productTypeError = true;
				return this.lang.validateDropDownNoSelect + this.lang.labelProductType;
			}
			if (!this.selectedProductCalculation) {
				return this.lang.validateDropDownNoSelect + this.lang.labelProductCalculation;
			}
			if (this.selectedIsCalculatePenalty == "Y" && !this.selectedProductPenalty) {
				return this.lang.validateDropDownNoSelect + this.lang.labelProductPenalty;
			}
			if (!this.selectedIsCalculatePenalty) {
				return this.lang.validateDropDownNoSelect + this.lang.labelIsCalculatePenalty;
			}
			if (this.code.charAt(0).toLowerCase() == "z" && this.selectedProductPenalty) {
				return this.lang.labelIsProductPenalty;
			}
		},
		async createProduct() {
			this.setLoading(true);
			this.showConfirmDialog = false;
			await this.initiateData();
			await this.$router.replace({name: "ar-product-edit", params: { id: "create" }});
			await this.setObjectToCompare();
			this.setLoading(false);
		},
		async askForCreate() {
			this.clearBaseDialog();
			if(!this.isRoleCreate) {
				this.showDialog = true;
				this.titleConfirm = this.lang.notificationDialogHeader
				this.validateWording = this.lang.validateRoleInsert;
				this.closeDialog = true;
				return;
			}
			let result = this.isCompareObject(this.objToCompare, this.payload);
			if (result) {
				this.showConfirmDialog = true;
				this.titleConfirmDialog = this.lang.notificationDialogHeader;
				this.wordingDialog = this.lang.askForCreateNewDocument;
				this.confirmAddOrDelete = "new";
			} else {
				await this.createProduct();
			}
		},
		async saveData() {
			if (this.validateData()) {
				this.setLoading(false);
				return;
			}

			try {
				this.setLoading(true);
				await this.$store.dispatch("arProduct/addUpdateArProduct", this.payload);

				if (this.isCreateDocument) {
					await this.$router.replace({name: "ar-product-edit", params: { id: this.selectedArProduct.id }});
					await this.setObjectToCompare();
				}
				this.roomToAddProduct = [];
				this.roomToDeleteProduct = [];
				if (this.selectedProductType === "N") {
					await this.searchTabRoom();
				}
				let titleMsgIssuccessfully = this.lang.toastHeaderMsgAdd;
				let msgIssuccessfully = this.lang.toastMsgAdd.replace(
					":productName",
					this.nameThai
				);
				if (this.payload.actionType == "UpdateArProduct") {
					titleMsgIssuccessfully = this.lang.toastHeaderMsgEdit;
					msgIssuccessfully = this.lang.toastMsgEdit.replace(
						":productName",
						this.nameThai
					);
				}
				this.baseDialogClassTitle = "p-text-success";
				this.titleConfirm = titleMsgIssuccessfully;
        		this.validateWording = msgIssuccessfully;
        		this.showDialog = true;
				this.closeDialog = true;
				this.refreshProduct();
				this.setLoading(false);
			} catch (error) {
				let titleMsg = this.lang.toastHeaderMsgError;
				let massage = error.massage;
				if (error.code == "101") {
					titleMsg = this.lang.toastHeaderMsgDuplicate;
					massage = this.lang.toastMsgDuplicate;
				} else if (error.code == "111") {
					massage = this.lang.toastMsgRoleInsert;
				} else if (error.code == "112") {
					massage = this.lang.toastMsgRoleUpdate;
				}
				this.baseDialogClassTitle = "";
				this.titleConfirm = titleMsg;
        		this.validateWording = massage;
        		this.showDialog = true;
				this.closeDialog = true;
				this.setLoading(false);
			}
			this.setLoading(false);
		},

		//penalty card #akkaraphon
		async getPenaltyRateStep() {
			try {
				let where = {
					projectId: this.selectedProject.id,
					productId: this.id,
				};
				const payload = {
					page: 1,
					limit: 100,
					where,
				};
				await this.$store.dispatch("masterProduct/getPenaltyRateStep", payload);
				this.isLoading = false;
			} catch (error) {
				this.baseDialogClassTitle = "";
				this.titleConfirm = this.lang.notificationDialogHeader;
        		this.validateWording = error;
        		this.showDialog = true;
				this.closeDialog = true;
				this.isLoading = false;
			}
		},
		openPenaltyRateStep() {
			const today = new Date();
			this.openFrom = today.getSeconds();
			this.showPenaltyCalculation = true;
		},
		addPenaltyRateStep(data) {
			if (this.indexUpdatePenalty != null) {
				this.penaltyDetails[this.indexUpdatePenalty].dateStart = data.dateStart;
				this.penaltyDetails[this.indexUpdatePenalty].penaltyRate = data.penaltyRate;
				this.indexUpdatePenalty = null;
			} else {
				let detail = {
					id: "dummy" + (parseInt(this.penaltyDetails.length) + 1),
					itemNumber: parseInt(this.penaltyDetails.length) + 1,
					dateStart: data.dateStart,
					penaltyRate: data.penaltyRate,
				};
				this.penaltyDetails.push(detail);
			}
		},
		closePenaltyRateStep() { 
			this.indexUpdatePenalty = null;
			this.dataUpdatePenalty = null;
			this.showPenaltyCalculation = false;
		},
		async searchTabRoom() {
			try {
				this.isLoadingUnitProduct = true;
				let where = {
					projectId: this.selectedProject.id,
					productId: this.id,
				};

				if (this.searchStart) {
					where = {
						...where,
						...{ CodeStart: this.searchStart.code, AddressNoStart: this.searchStart.addressNo },
					};
				}
				if (this.searchEnd) {
					where = {
						...where,
						...{ CodeEnd: this.searchEnd.code, AddressNoEnd: this.searchEnd.addressNo },
					};
				}
				const payload = {
					page: this.selectedPage,
					limit: this.rowsPerPage,
					where,
				};
				// console.log(payload);
				await this.$store.dispatch("arProduct/getUnitProduct", payload);
				this.unitProductList = this.unitProductDetails;
				
				//check update status
				this.unitProductList.forEach((element) => {
					if (this.roomToAddProduct.find(c => element.id == c.id)) {
						element.status = "pendingAdded"
					}
				});

				this.unitProductList.forEach((element) => {
					if (this.roomToDeleteProduct.find(c => element.id == c.id)) {
						element.status = "pendingDelete"
					}
				});

				this.isLoadingUnitProduct = false;

			} catch (error) {
				this.baseDialogClassTitle = "";
				this.titleConfirm = this.lang.notificationDialogHeader;
        		this.validateWording = error;
        		this.showDialog = true;
				this.closeDialog = true;
				this.isLoadingUnitProduct = false;
			}
		},
		async addProductToAllRoom() {
			this.clearBaseDialog();
			await this.getAllUnitProduct();
			let productToAdd = [];
			let allUnitProduct = [];
			if (this.roomToDeleteProduct.length > 0) {
				allUnitProduct = this.roomToAddProduct;
			} else {
				allUnitProduct = this.allUnitProducts;
			}
			allUnitProduct.filter((item) => {
				if (item.productId === null) {
					productToAdd.push({ id: item.id });
				}
			});
			// console.log("addProductToAllRoom", productToAdd);

			this.showConfirmDialog = true;
			this.wordingDialog = this.lang.wordingAddedDialog
				.replace("{product}", this.code)
				.replace("{num}", productToAdd.length)
				.replace("{total}", this.allUnitProducts.length);
			this.titleConfirmDialog = this.lang.titleAddedConfirmDialog;
			this.waitProduct = productToAdd;
			this.confirmAddOrDelete = "add";
		},
		async deleteProductFromAllRoom() {
			this.clearBaseDialog();
			await this.getAllUnitProduct();
			let productToDelete = [];
			let allUnitProduct = [];
			if (this.roomToAddProduct.length > 0) {
				allUnitProduct = this.roomToAddProduct;
			} else {
				allUnitProduct = this.allUnitProducts;
			}
			allUnitProduct.filter((item) => {
				if (item.productId !== null) {
					productToDelete.push({ id: item.id });
				}
			});
			// console.log("deleteProductFromAllRoom", productToDelete);

			this.showConfirmDialog = true;
			this.wordingDialog = this.lang.wordingDeleteDialog
				.replace("{product}", this.code)
				.replace("{num}", productToDelete.length)
				.replace("{total}", this.allUnitProducts.length);
			this.titleConfirmDialog = this.lang.titleDeleteConfirmDialog;
			this.waitProduct = productToDelete;
			this.confirmAddOrDelete = "delete";
		},
		async addProductToRoom(data) {
			let checkIsId = this.checkIsId(this.roomToAddProduct, data.id);
			// console.log("addProductToRoom", checkIsId);
			if (!checkIsId) {
				this.selectedUnitProduct = data;

				let i = this.roomToDeleteProduct.map((item) => item.id).indexOf(data.id);
				this.roomToDeleteProduct.splice(i, 1);

				this.roomToAddProduct.push({ id: data.id });
				this.selectedUnitProduct.status = "pendingAdded";
				this.unitProductList[this.findIndexById(data.id)] = this.selectedUnitProduct;
				// console.log("addProductToRoom_add", this.roomToAddProduct);
				// console.log("addProductToRoom_del", this.roomToDeleteProduct);
			}
		},
		async deleteProductFromRoom(data) {
			let checkIsId = this.checkIsId(this.roomToDeleteProduct, data.id);
			// console.log("deleteProductFromRoom", checkIsId);
			if (!checkIsId) {
				this.selectedUnitProduct = data;

				let i = this.roomToAddProduct.map((item) => item.id).indexOf(data.id);
				this.roomToAddProduct.splice(i, 1);

				this.roomToDeleteProduct.push({ id: data.id });
				this.selectedUnitProduct.status = "pendingDelete";
				this.unitProductList[this.findIndexById(data.id)] = this.selectedUnitProduct;
				// console.log("deleteProductFromRoom_add", this.roomToAddProduct);
				// console.log("deleteProductFromRoom_del", this.roomToDeleteProduct);
			}
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.unitProductList.length; i++) {
				if (this.unitProductList[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		checkIsId(data, id) {
			let check = false;
			data.filter((item) => {
				if (item.id === id) {
					check = true;
				}
			});
			return check;
		},
		async getUnitProduct() {
			try {
				this.isLoadingUnitProduct = true;
				let where = {
					projectId: this.selectedProject.id,
					productId: this.id,
				};
				const payload = {
					page: this.selectedPage,
					limit: this.rowsPerPage,
					where,
				};
				await this.$store.dispatch("arProduct/getUnitProduct", payload);
				this.unitProductList = this.unitProductDetails;
				this.isLoadingUnitProduct = false;
			} catch (error) {
				this.isLoadingUnitProduct = false;
				this.baseDialogClassTitle = "";
				this.titleConfirm = this.lang.notificationDialogHeader;
        		this.validateWording = error;
        		this.showDialog = true;
				this.closeDialog = true;
			}
		},
		async getAllUnitProduct() {
			try {
				this.setLoading(true);
				this.isLoadingUnitProduct = true;
				let where = {
					projectId: this.selectedProject.id,
					productId: this.id,
				};

				if (this.searchStart) {
					where = {
						...where,
						...{ CodeStart: this.searchStart.code, AddressNoStart: this.searchStart.addressNo },
					};
				}
				if (this.searchEnd) {
					where = {
						...where,
						...{ CodeEnd: this.searchEnd.code, AddressNoEnd: this.searchEnd.addressNo },
					};
				}

				const payload = {
					page: 1,
					limit: 100,
					where,
				};
				await this.$store.dispatch("arProduct/getAllUnitProduct", payload);
				this.isLoadingUnitProduct = false;
				this.setLoading(false);
				// console.log("getAllUnitProduct", this.allUnitProducts);
			} catch (error) {
				this.isLoadingUnitProduct = false;
				this.baseDialogClassTitle = "";
				this.titleConfirm = this.lang.notificationDialogHeader;
        		this.validateWording = error;
        		this.showDialog = true;
				this.closeDialog = true;
			}
		},
		async onPageChange(event) {
			this.selectedPage = event.page + 1;
			this.rowsPerPage = event.rows;
			await this.searchTabRoom();
		},
		changeStatus() {
			this.unitProductList.forEach((data) => {
				if (this.roomToAddProduct.find((add) => add.id === data.id)) {
					// console.log("roomToAddProduct");
					data.status = "pendingAdded";
				} else if (this.roomToDeleteProduct.find((del) => del.id === data.id)) {
					// console.log("roomToDeleteProduct");
					data.status = "pendingDelete";
				}
				// this.selectedUnitProduct.push(data);
			});
			// console.log("unitProductDetails_newValue", this.selectedUnitProduct);
			// this.unitProductList = this.selectedUnitProduct;
		},
		async productTypeChange() {
			this.productTypeError = false;
			if (this.selectedProductType !== "N") {
				this.unitProductList = [];
				this.selectedProductPrepaid = null;
				this.selectedIsCalculatePenalty = null;
				this.selectedProductPenalty = null;
			} else {
				await this.searchTabRoom();
			}
		},
		confirmChange() {
			if (this.confirmAddOrDelete === "add") {
				this.roomToDeleteProduct = [];
				this.roomToAddProduct = this.waitProduct;
			} else if (this.confirmAddOrDelete === "delete") {
				this.roomToAddProduct = [];
				this.roomToDeleteProduct = this.waitProduct;
			} else if (this.confirmAddOrDelete === "new") {
				this.createProduct();
			}
			this.changeStatus();
			this.showConfirmDialog = false;
		},
		cancelChange() {
			this.showConfirmDialog = false;
			this.waitProduct = [];
		},
		deleteDataDummy(id) {
			const index = this.penaltyDetails.findIndex((detail) => detail.id == id);
			this.penaltyDetails.splice(index, 1);
			this.updateItemNumber();
		},
		editPenalty(data, index) { 
			const today = new Date();
			this.openFrom = today.getSeconds();
			this.showPenaltyCalculation = true;
			this.indexUpdatePenalty = index;
			this.dataUpdatePenalty = data
		},
		updateItemNumber() {
			let number = 1;
			this.penaltyDetails.forEach((element) => {
				// console.log("element", element.accountId);
				element.itemNumber = number;
				number++;
			});
		},
		confirmDelete(id) {
			this.penaltyId = id;
			this.showConfirmDialogName = "confirmDelete";
			this.titleConfirmDialog = this.lang.titleConfirmDelete;
			this.wordingDialog = `${this.lang.askForDeleteDataDetail.replace(
				":title",
				this.lang.titleFineRate
			)}`;
			this.showConfirmDeleteDialog = true;
		},
		close() {
			this.showConfirmDeleteDialog = false;
		},
		confirm() {
			this.showConfirmDeleteDialog = false;
			if (this.showConfirmDialogName === "confirmDelete") {
				this.deleteData();
			}
		},
		async deleteData() {
			this.setLoading(true);
			try {
				if (this.penaltyId.includes("dummy")) {
					return true;
				}

				let payload = {
					ProjectId: this.selectedProject.id,
					ProductId: this.id,
					PenaltyId: this.penaltyId,
				};

				// console.log("payload=", payload);
				await this.$store.dispatch("masterProduct/DeletePenaltyRateStep", payload);
				this.$toast.add({
					severity: "success", // severity:'success',severity:'info',severity:'warn',severity:'error'
					//summary: this.lang.notificationDialogHeader, //TitleHeader
					detail: this.lang.deleteSuccess,
					life: 5000, //TimeClose
				});
				this.deleteDataDummy(this.penaltyId);
			} catch (error) {
				this.baseDialogClassTitle = "";
				this.titleConfirm = this.lang.notificationDialogHeader;
        		this.validateWording = error;
        		this.showDialog = true;
				this.closeDialog = true;
				this.setLoading(false);
			}
			this.setLoading(false);
		},
		validateSearchTabRoom() {
			this.searchEnd = "";
			this.searchStart = "";
			this.searchStartError = false;
			this.searchEndError = false;
			if (this.selectedSerachBy === "All") {
				this.searchTabRoom();
			}
		},
		unitDataReady() {
			this.isSharedUnitReady = true;
		},
		setLocalSearch() {
			let storageName = `erp_search_ProductArList_${this.selectedProject.code}_${this.userCode}`;
			const localSearch = localStorage.getItem(storageName);
			let search = JSON.parse(localSearch);
			search = {
				...search,
				entry: "entry",
			};
			localStorage.setItem(storageName, JSON.stringify(search));
		},
		askForDelete() {
			this.clearBaseDialog();
			if(!this.isCreateDocument && !this.isRoleDelete) {
				this.showConfirmDialog = true;
				this.titleConfirmDialog = this.lang.notificationDialogHeader
				this.wordingDialog = this.lang.validateRoleDelete;
				this.closeDialog = true;
				return;
			}

			this.isDelete = true;
			this.showConfirmDialog = true;
			this.titleConfirmDialog = this.lang.deleteArProductTitle
			this.wordingDialog = this.lang.askForDeleteArProductTitle.replace(":code", this.code);
		},
		async deleteArProduct() {
			this.showConfirmDialog = false;
			this.setLoading(true);
			try {
				const payload = {
					productId: this.id,
				};

				await this.$store.dispatch("arProduct/deleteArProduct", payload);
				
				this.isDelete = false;
				this.isDeleteSuccess = true;
				this.showDialog = true;
				this.baseDialogClassTitle = "p-text-success";
				this.titleConfirm = this.lang.successfully;
				this.validateWording = this.lang.deleteArProductSuccess;

			} catch (error) {
				let massage = error.message;
				if (error.code == "126") {
					massage = this.lang.errorArProductReference;
				}
				this.baseDialogClassTitle = "";
				this.titleConfirm = this.lang.notificationDialogHeader;
        		this.validateWording = massage;
        		this.showDialog = true;
				this.closeDialog = true;
				
				this.setLoading(false);
			}

			this.setLoading(false);
		},
		clearBaseDialog() {
			this.baseDialogClassTitle = "";
			this.titleConfirm = this.lang.notificationDialogHeader;
			this.validateWording = "";
			this.isDelete = false;
			this.isDeleteSuccess = false;
			this.closeDialog = false;
		},
	},
	computed: {
		title() {
			return this.isCreateDocument ? this.lang.addTitle : this.lang.editTitle;
		},
		subTitle() {
			return this.isCreateDocument ? this.lang.addSubTitle : this.lang.editSubTitle;
		},
		toastMessageSave() {
			return this.isCreateDocument ? this.lang.addSubTitle : this.lang.editSubTitle;
		},
		isCreateDocument() {
			return this.id === "create";
		},
		selectedProject() {
			return this.$store.getters.selectedProject;
		},
		userCode() {
			return this.$store.getters.userCode;
		},
		selectedArProduct() {
			return this.$store.getters["arProduct/selectedArProduct"];
		},
		sharedAccounts() {
			return this.$store.getters["shared/accountingDetailAccounts"];
		},
		sharedProductUnits() {
			return this.$store.getters["shared/productUnits"];
		},
		payload() {
			let penaltyDetails = [];
			this.penaltyDetails.forEach((data) => {
				let detail = null;
				if (data.id.includes("dummy")) {
					detail = { ...data, id: null };
				} else {
					detail = { ...data };
				}
				penaltyDetails.push(detail);
			});

			let payload = {
				code: this.code,
				projectId: this.selectedProject.id,
				nameThai: this.nameThai,
				nameEnglish: this.nameEnglish,
				paidPriority: this.paidPriority,
				price: this.price,
				// unitId: this.selectedProductUnit,
				type: this.selectedProductType,
				calculation: this.selectedProductCalculation,
				isCalculatePenalty: this.selectedIsCalculatePenalty,
				penaltyId: this.selectedProductPenalty ? this.selectedProductPenalty.id : "",
				prepaidId: this.selectedProductPrepaid ? this.selectedProductPrepaid.id : "",
				isVat: this.selectedIsVat,
				isIgnoreDuplicatePeriod: this.selectedIsIgnoreDuplicatePeriod ? "R" : "C",
				accountIdDrInvoicePrevious: this.selectedAcountDrInvoicePrevious
					? this.selectedAcountDrInvoicePrevious.id
					: "",
				accountIdCrInvoicePrevious: this.selectedAcountCrInvoicePrevious
					? this.selectedAcountCrInvoicePrevious.id
					: "",
				accountIdDrInvoiceCurrent: this.selectedAccountDrInvoiceCurrent
					? this.selectedAccountDrInvoiceCurrent.id
					: "",
				accountIdCrInvoiceCurrent: this.selectedAccountCrInvoiceCurrent
					? this.selectedAccountCrInvoiceCurrent.id
					: "",
				accountIdDrInvoiceForward: this.selectedAccountDrInvoiceForward
					? this.selectedAccountDrInvoiceForward.id
					: "",
				accountIdCrInvoiceForward: this.selectedAccountCrInvoiceForward
					? this.selectedAccountCrInvoiceForward.id
					: "",
				accountIdDrReceiptCash: this.selectedAccountDrReceiptCash
					? this.selectedAccountDrReceiptCash.id
					: "",
				accountIdCrReceiptCash: this.selectedAccountCrReceiptCash
					? this.selectedAccountCrReceiptCash.id
					: "",
				accountIdDrReceiptCheque: this.selectedAccountDrReceiptCheque
					? this.selectedAccountDrReceiptCheque.id
					: "",
				accountIdCrReceiptCheque: this.selectedAccountCrReceiptCheque
					? this.selectedAccountCrReceiptCheque.id
					: "",
				accountIdDrReceiptTransfer: this.selectedAccountDrReceiptTransfer
					? this.selectedAccountDrReceiptTransfer.id
					: "",
				accountIdCrReceiptTransfer: this.selectedAccountCrReceiptTransfer
					? this.selectedAccountCrReceiptTransfer.id
					: "",
				accountIdDrReceiptTransferPrevious: this.selectedAccountDrReceiptTransferPrevious
					? this.selectedAccountDrReceiptTransferPrevious.id
					: "",
				accountIdCrReceiptTransferPrevious: this.selectedAccountCrReceiptTransferPrevious
					? this.selectedAccountCrReceiptTransferPrevious.id
					: "",
				accountIdDrReceiptDigitalPayment: this.selectedAccountDrReceiptDigitalPayment
					? this.selectedAccountDrReceiptDigitalPayment.id
					: "",
				accountIdCrReceiptDigitalPayment: this.selectedAccountCrReceiptDigitalPayment
					? this.selectedAccountCrReceiptDigitalPayment.id
					: "",
				accountIdDrReceiptCreditCard: this.selectedAccountCrReceiptCreditCard
					? this.selectedAccountCrReceiptCreditCard.id
					: "",
				accountIdCrReceiptCreditCard: this.selectedAccountDrReceiptCreditCard
					? this.selectedAccountDrReceiptCreditCard.id
					: "",
				accountIdDrReceiptDeposit: this.selectedAccountDrReceiptDeposit
					? this.selectedAccountDrReceiptDeposit.id
					: "",
				accountIdCrReceiptDeposit: this.selectedAccountCrReceiptDeposit
					? this.selectedAccountCrReceiptDeposit.id
					: "",
				accountIdDrReceiptOther: this.selectedAccountDrReceiptOther
					? this.selectedAccountDrReceiptOther.id
					: "",
				accountIdCrReceiptOther: this.selectedAccountCrReceiptOther
					? this.selectedAccountCrReceiptOther.id
					: "",
				accountIdDrReceiptDiscount: this.selectedAccountDrReceiptDiscount
					? this.selectedAccountDrReceiptDiscount.id
					: "",
				accountIdCrReceiptDiscount: this.selectedAccountCrReceiptDiscount
					? this.selectedAccountCrReceiptDiscount.id
					: "",
				penaltyRateStep: [],
				roomForAddProduct: this.roomToAddProduct,
				roomForDeleteProduct: this.roomToDeleteProduct,
				actionType: "AddArProduct",
			};
			if (!this.isCreateDocument) {
				payload = { ...payload, id: this.id, actionType: "UpdateArProduct" };
			}
			if (this.selectedIsCalculatePenalty === "Y") {
				payload = { ...payload, penaltyRateStep: penaltyDetails };
			}
			return payload;
		},

		//penalty card #akkaraphon
		penaltyRateStep() {
			let itemNumber = [];
			let i = 1;
			const penaltyRateStep = this.$store.getters["masterProduct/penaltyRateStep"];
			if (penaltyRateStep) {
				penaltyRateStep.forEach((element) => {
					// element = { ...element, ...{ itemNumber: i } };
					let data = {
						id: element.id,
						itemNumber: i,
						dateStart: element.dateStart,
						penaltyRate: element.penaltyRate,
					};
					itemNumber.push(data);
					i++;
				});
			}
			return itemNumber;
		},
		productAr() {
			return this.$store.getters["shared/productArProducts"];
		},
		productName() {
			if (this.productAr) {
				let productAr = this.productAr.find((data) => data.id === this.id);
				if (productAr) {
					return productAr.code + ":" + productAr.nameThai;
				}
			}
			return "";
		},
		penaltyName: {
			get() {
				if (this.productAr) {
					let productAr = this.productAr.find((data) => data.id === this.penaltyId);
					if (productAr) {
						return productAr.code + ":" + productAr.nameThai;
					}
				}
				return "";
			},
			set(newValue) {
				let productAr = this.productAr.find((data) => data.id === newValue);
				if (productAr) {
					this.penaltyMethodCode = productAr.code + ":" + productAr.nameThai;
				}
			},
		},
		dateStartLast() {
			if (this.penaltyDetails) {
				let detailsTotal = this.penaltyDetails.length;
				if (detailsTotal == 0) {
					return 0;
				} else {
					let dateLast = this.penaltyDetails[detailsTotal - 1].dateStart;
					return dateLast;
				}
			} else return 0;
		},
		totalPage() {
			return this.$store.getters["arProduct/unitProductTotalPage"];
		},
		currentPage() {
			return this.$store.getters["arProduct/unitProductCurrentPage"];
		},
		totalRecords() {
			return this.totalPage * this.rowsPerPage;
		},
		unitProductDetails() {
			let unitProduct = this.$store.getters["arProduct/unitProducts"];
			let unitProductDetails = [];
			unitProduct.forEach((data) => {
				let detail = null;
				if (data.productId === null) {
					detail = { ...data, ...{ status: "waiting" } };
				} else {
					detail = { ...data, ...{ status: "added" } };
				}
				unitProductDetails.push(detail);
			});
			return unitProductDetails;
		},
		allUnitProducts() {
			return this.$store.getters["arProduct/allUnitProducts"];
		},
		roleByMenu() {
			return this.getRoleByMenuByCode("tmPro");
		},
		isRoleCreate() {
			if (this.roleByMenu && this.roleByMenu.insert == "Y") {
				return true;
			}
			return false;
		},
		isRoleUpdate() {
			if (this.roleByMenu && this.roleByMenu.update == "Y") {
				return true;
			} else {
				return false;
			}
		},
		isRoleDelete() {
			if (this.roleByMenu && this.roleByMenu.delete == "Y") {
				return true;
			} else {
				return false;
			}
		},
		dataButtons() {
			let dataButtons = [
				{
					id: "delete",
					action: "askForDelete",
					type: "delete",
					isDisable: this.isCreateDocument,
				},
				{
					id: "editCaption",
					action: "askForCreate",
					type: "create"
				},
				{
					id: "saveCaption",
					action: "gotoSave",
					type: "save"
				},
			];
			return dataButtons;
		},
		dataButtonDialog() {
			let dataButtonDialog = [
			{
				id: "cancel",
				caption: this.lang.cancel,
				class: "p-button-outlined p-button-secondary w-full",
				action: "close",
				},
				{
				id: "confirm",
				caption: this.lang.buttonDialogConfirm,
				class: "w-full",
				action: "confirm",
				},
			];
			if(this.closeDialog) {
				dataButtonDialog = [
					{
					id: "confirm",
					caption: this.lang.close,
					class: "p-button-outlined p-button-secondary w-full",
					action: "close",
					},
				];
			} else if(this.isDelete) {
				dataButtonDialog = [
					{
						id: "close",
						caption: this.lang.close,
						class: "p-button-outlined p-button-secondary w-full",
						action: "close",
					},
					{
						id: "gotoDelete",
						caption: this.lang.buttonDialogConfirm,
						class: "w-full",
						action: "goto-delete",
					},
				]
			} else if(this.isDeleteSuccess) {
				dataButtonDialog = [
				{
					id: "close",
					caption: this.lang.close,
					class: "p-button-outlined p-button-secondary w-full",
					action: "back",
				},
				]
			}

			return dataButtonDialog;
		}
	},
	watch: {
		selectedArProduct() {
			this.checkShouldSetData();
		},
		payload(curValue) {
			this.compareObject(this.objToCompare, curValue);
		},
	},
};
</script>

<style scoped>
.fontlabel {
	font-size: 14px;
	color: #181819;
}
.fontData {
	font-size: 16px;
	color: #181819;
	letter-spacing: 0.005em;
	font-weight: bold;
}

.disableinputtext {
	background-color: #f0f3fa;
}

.mt24 {
	margin-top: 24px;
}

.bordercard {
	background: #ffffff;
	box-shadow: 0px 4px 10px rgb(132 132 132 / 10%);
	border-radius: 12px;
}
</style>
