<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @gotoEdit="gotoEdit()"
    @goBack="goBack()"
    :hideBackButton="isHideBack"
  >
    ></base-toolbar
  >
  <base-container>
    <PvDialog
      :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
      :style="{ width: '70vw' }"
      :modal="true"
      :closable="false"
      v-model:visible="showUploadFileDialog"
    >
      <template #header>
        <div class="flex justify-content-between align-items-center">
          <div class="pt-2 pb-1">
            <label class="font-lg font-normal">{{ lang.uploadFile2 }}</label>
          </div>
          <PvButton
            icon="pi pi-times"
            class="p-button-link p-icon-secondary"
            @click="showUploadFileDialog = false"
          />
        </div>
      </template>
      <import-meter-usage-dialog
        @close="showUploadFileDialog = false"
        @finish="setDataAfterUploaded"
        :parameter="payloadGetData"
      >
      </import-meter-usage-dialog>
      <template #footer> </template>
    </PvDialog>

    <base-dialog
      v-model:visible="showConfirmDeleteDialog"
      :titleConfirm="lang.confirmDeleteMeter"
      :wordingConfirm="lang.confirmDeleteMeterDetail"
      @confirm="confirmDelete"
      @close="closeConfirmDelete"
    ></base-dialog>

    <update-previous-meter-used-dialog
      v-model:visible="showUpdatePreviousUsedDialog"
      @close="closeUpdatePreviousUsedDialog"
      @save="save"
      :dataMeterUsage="dataMeterUsage"
    ></update-previous-meter-used-dialog>

    <PvDialog
      v-model:visible="showReferDialog"
      :breakpoints="{ '1280px': '30vw', '640px': '70vw' }"
      :style="{ width: '30vw' }"
      :modal="true"
      :closable="false"
    >
      <template #header>
        <div class="flex justify-content-between align-items-center">
          <div class="pt-2 pb-1">
            <label class="font-lg font-normal">{{
              lang.referDialogTitle
            }}</label>
          </div>
        </div>
      </template>
      <div class="pt-4">
        <label>{{ lang.referDialogWording }}</label>
      </div>
      <template #footer>
        <PvButton @click="showReferDialog = false" class="w-full">
          <span class="p-button-label font-md font-normal">{{ lang.ok }}</span>
        </PvButton>
      </template>
    </PvDialog>

    <!-- Table -->
    <div class="surface-card shadow-2 py-2 px-5 border-round my-5">
      <div class="flex flex-wrap justify-content-between align-items-center">
        <div class="lg:col-7 md:col-7 col-12 pl-0 pr-2 flex flex-wrap">
          <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
            <PvDropdown
              id="status"
              class="inputfield w-full mr-2"
              :options="status"
              optionLabel="name"
              optionValue="code"
              v-model="selectedStatus"
              scrollHeight="300px"
              @change="getMeterUsagesByStatus()"
            />
          </div>
          <div class="lg:col-5 md:col-12 col-12 pl-0 pr-2">
            <PvCalendar
              id="period"
              v-model="period"
              class="inputfield w-full"
              showIcon
              dateFormat="MM yy"
              view="month"
              @date-select="getMeterUsagesByPeriod()"
            />
          </div>
        </div>
        <!-- <div class="pt-2">
          <PvButton
            :label="lang.export"
            @click="exportData"
            class="p-button-outlined"
          >
            <icon-documents-file-checkmark iconColor="primary" width="16" height="16" />
            <span class="p-button-label font-normal ml-1">{{ lang.export }}</span>
          </PvButton>
        </div> -->
        <div class="flex flex-wrap">
          <div class="pt-2 pr-2">
            <PvButton
              v-if="isEdit"
              :label="lang.cancelMeter"
              @click="askForConfirmDeleteMeter"
              class="p-button-outlined"
            >
              <icon-refresh-loading
                iconColor="primary"
                width="16"
                height="16"
              />
              <span class="p-button-label font-normal ml-1">{{
                lang.cancelMeter
              }}</span>
            </PvButton>
          </div>
          <div class="pt-2">
            <PvButton
              v-if="isEdit"
              :label="lang.upload"
              @click="upload"
              class="p-button-outlined"
            >
              <icon-download iconColor="primary" width="16" height="16" />
              <span class="p-button-label font-normal ml-1">{{
                lang.upload
              }}</span>
            </PvButton>
          </div>
        </div>
      </div>

      <div class="mb-5 mt-0">
        <PvDataTable
          ref="meterUsagesTable"
          :value="meterUsages"
          dataKey="meterId"
          selectionMode="single"
          responsiveLayout="scroll"
          :class="textTableClass"
          :loading="isLoading"
          editMode="cell"
          class="editable-cells-table p-datatable-xs"
        >
          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
          <PvColumn
            field="meterCode"
            headerClass="h-left font-normal"
            :bodyClass="bodyClassEdit"
            style="height: 56px"
            sortable
          >
            <template #header>
              <span class="p-column-title" style="min-width: 47px">{{
                lang.meterCode
              }}</span>
            </template>
          </PvColumn>

          <PvColumn
            field="unitCode"
            headerClass="h-left font-normal"
            :bodyClass="bodyClassEdit"
            sortable
          >
            <template #header>
              <span class="p-column-title" style="min-width: 87px">{{
                lang.unitCode
              }}</span>
            </template>
          </PvColumn>

          <PvColumn
            field="unitAddressNo"
            headerClass="h-left font-normal"
            :bodyClass="bodyClassEdit"
            sortable
          >
            <template #header>
              <span class="p-column-title" style="min-width: 57px">{{
                lang.unitAddressNo
              }}</span>
            </template>
          </PvColumn>

          <PvColumn
            field="productName"
            headerClass="h-left font-normal"
            :bodyClass="bodyClassEdit"
          >
            <template #header>
              <span class="p-column-title" style="width: 118px"
                >{{ lang.productName }}
              </span>
            </template>
            <template #body="{ data, field }">
              <span
                style="width: 118px"
                class="ellipsis"
                v-tooltip.top="{
                  value:
                    data[field] == 'invalid'
                      ? lang.productInvalid
                      : data[field],
                  class: 'custom-tooltip-secondary',
                }"
              >
                {{
                  data[field] == "invalid" ? lang.productInvalid : data[field]
                }}
              </span>
            </template>
          </PvColumn>

          <PvColumn
            field="previousUsed"
            headerClass="h-right font-normal"
            bodyClass="text-right"
          >
            <template #header>
              <span style="width: 95px; text-align: right">{{
                lang.previousUsed
              }}</span>
            </template>
            <template #body="{ data, field }" v-if="isEdit">
              <span>
                <span
                  class="p-input-icon-left"
                  v-if="data.updatePreviousUsedReason != ''"
                >
                  <i
                    class="pi pi-exclamation-circle pr-0 p-icon-warning"
                    :class="{
                      'p-icon-disabled ': disabled(
                        data.isRefer,
                        data.productName,
                        data.state
                      ),
                    }"
                    style="font-size: 12px"
                    v-tooltip.top="{
                      value: lang.editReason + data.updatePreviousUsedReason,
                      class: disabled(
                        data.isRefer,
                        data.productName,
                        data.state
                      )
                        ? 'custom-tooltip-secondary'
                        : 'custom-tooltip-warning',
                    }"
                  />
                  <PvInputText
                    style="width: 95px; text-align: right"
                    class="p-inputtext-warning"
                    :class="{
                      'p-inputtext-disabled': disabled(
                        data.isRefer,
                        data.productName,
                        data.state
                      ),
                    }"
                    type="text"
                    :maxlength="5"
                    v-model.trim="data[field]"
                    @dblclick="gotoUpdatePreviousUsed(data)"
                    readonly
                    :disabled="
                      disabled(data.isRefer, data.productName, data.state)
                    "
                  />
                </span>
                <PvInputText
                  :class="{
                    'p-inputtext-disabled': disabled(
                      data.isRefer,
                      data.productName,
                      data.state
                    ),
                  }"
                  style="width: 95px; text-align: right"
                  type="text"
                  :maxlength="5"
                  v-model.trim="data[field]"
                  @dblclick="gotoUpdatePreviousUsed(data)"
                  readonly
                  v-else
                  :disabled="
                    disabled(data.isRefer, data.productName, data.state)
                  "
                />
              </span>
            </template>
            <template #body="{ data, field }" v-else>
              <span class="flex justify-content-between align-items-right">
                <i
                  v-if="data.updatePreviousUsedReason != ''"
                  class="pi pi-exclamation-circle pr-1 pl-5 p-icon-warning"
                  style="font-size: 12px"
                  v-tooltip.top="{
                    value: lang.editReason + data.updatePreviousUsedReason,
                    class: 'custom-tooltip-warning',
                  }"
                />
                <div v-else></div>
                {{ data[field] }}
              </span>
            </template>
          </PvColumn>

          <PvColumn
            field="currentUsed"
            headerClass="h-right font-normal"
            bodyClass="text-right"
          >
            <template #header>
              <span style="width: 95px; text-align: right">{{
                lang.currentUsed
              }}</span>
            </template>
            <template #body="{ data, field, index }" v-if="isEdit">
              <PvInputText
                :class="{
                  'p-inputtext-disabled': disabled(
                    data.isRefer,
                    data.productName,
                    data.state
                  ),
                }"
                style="width: 95px; text-align: right"
                type="text"
                :maxlength="5"
                v-model="data[field]"
                :value="data.currentUsed"
                @focus="
                  $event.target.select();
                  oldValue = data[field];
                "
                :disabled="disabled(data.isRefer, data.productName, data.state)"
                @focusout="
                  buttonDown($event, index + 1, data, data.currentUsed)
                "
                @keydown="buttonDown($event, index + 1, data, data.currentUsed)"
              />
            </template>
            <template #body="{ data, field }" v-else>
              {{ data[field] }}
            </template>
          </PvColumn>

          <PvColumn
            field="quantity"
            class="text-right font-normal"
            :bodyClass="bodyClassEdit"
          >
            <template #header>
              <span style="width: 72px; text-align: right">{{
                lang.quantity
              }}</span>
            </template>
          </PvColumn>

          <PvColumn
            field="amount"
            class="text-right font-normal"
            :bodyClass="bodyClassEdit"
          >
            <template #header>
              <span style="width: 72px; text-align: right">{{
                lang.amount
              }}</span>
            </template>
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.amount) }}
            </template>
          </PvColumn>

          <PvColumn
            field="averageUsed"
            class="text-right font-normal"
            :bodyClass="bodyClassEdit"
          >
            <template #header>
              <span style="width: 85px; text-align: right">{{
                averageUsed
              }}</span>
            </template>
          </PvColumn>

          <PvColumn
            field="status"
            headerClass="h-left font-normal"
            bodyClass="text-left"
          >
            <template #header>
              <span style="width: 95px; text-align: left">{{
                lang.status
              }}</span>
            </template>
            <template #body="slotProps">
              <base-tag
                v-if="slotProps.data.status != ''"
                :Caption="slotProps.data.statusDescription"
                :Class="
                  slotProps.data.status == 'G'
                    ? 'success'
                    : slotProps.data.status == 'R'
                    ? 'error'
                    : 'warning'
                "
              />
            </template>
          </PvColumn>

          <PvColumn
            field="reason"
            headerClass="h-left font-normal"
            bodyClass="text-left"
            style="width: 141px"
          >
            <template #header>
              <span style="width: 141px; text-align: left">{{
                lang.reason
              }}</span>
            </template>
            <template #body="slotProps" v-if="isEdit">
              <span style="width: 141px; text-align: left">
                <PvDropdown
                  class="inputfield w-full mt-1"
                  :class="{
                    'p-inputtext-disabled': disabled(
                      slotProps.data.isRefer,
                      slotProps.data.productName,
                      slotProps.data.state
                    ),
                  }"
                  style="text-overflow: ellipsis"
                  :options="reasonStatusOther"
                  optionLabel="name"
                  optionValue="name"
                  v-model="slotProps.data.reason"
                  :placeholder="lang.setReason"
                  @change="save(slotProps.data)"
                  v-if="slotProps.data.id && slotProps.data.status != 'G'"
                  :disabled="
                    disabled(
                      slotProps.data.isRefer,
                      slotProps.data.productName,
                      slotProps.data.state
                    )
                  "
                />
              </span>
            </template>
            <template #body="{ data, field }" v-else>
              {{ data.status != "G" ? data[field] : "" }}
            </template>
          </PvColumn>

          <PvColumn field="updateDate">
            <template #body="slotProps">
              <icon-clock-time-arrow
                iconColor="secondary"
                v-if="slotProps.data.state == 'F' && isEdit"
              />
              <PvProgressSpinner
                style="width: 24px; height: 24px; stroke: blue !important"
                strokeWidth="3"
                v-if="slotProps.data.state == 'W' && isEdit"
              />
            </template>
          </PvColumn>

          <PvColumn
            field="updateDate"
            headerClass="h-left font-normal"
            :bodyClass="bodyClassEdit"
          >
            <template #header>
              <span style="width: 143px; text-align: left">{{
                lang.updateDate
              }}</span>
            </template>
            <template #body="slotProps">
              <span v-if="slotProps.data.createDate">
                {{
                  formatDateTime(
                    slotProps.data.updateDate
                      ? slotProps.data.updateDate
                      : slotProps.data.createDate
                  )
                }}
              </span>
            </template>
          </PvColumn>

          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              class="p-pagination-radius"
              :first="showCurrentPage"
            >
              <template #start="slotProps">
                {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{
                  totalPage
                }}
              </template>
            </PvPaginator>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import ImportMeterUsageDialog from "../../../components/meter/meterUsage/ImportMeterUsageDialog.vue";
import UpdatePreviousMeterUsedDialog from "../../../components/meter/meterUsage/UpdatePreviousMeterUsedDialog";
export default {
  props: ["productType", "page", "projectCode"],
  components: {
    ImportMeterUsageDialog,
    UpdatePreviousMeterUsedDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const {
      formatPeriodToISOString,
      formatDate,
      formatDateToISOString,
      formatDateTime,
      formatCurrency,
    } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.meter.meterUsage.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.meter.meterUsage.listing,
        };
      }
    });

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,

      textHeaderClass,
      textFormClass,
      textTableClass,

      formatPeriodToISOString,
      formatDate,
      formatDateToISOString,
      formatDateTime,
      formatCurrency,
      isHideBack,
    };
  },
  data() {
    return {
      isLoading: false,

      status: [
        { name: this.lang.statusAll, code: "" },
        { name: this.lang.statusG, code: "G" },
        { name: this.lang.statusY, code: "Y" },
        { name: this.lang.statusR, code: "R" },
      ],
      selectedStatus: "",
      period: null,
      showUploadFileDialog: false,
      showUpdatePreviousUsedDialog: false,
      dataMeterUsage: null,

      reasonStatusG: [
        { name: this.lang.reasonNewAdded },
        { name: this.lang.reasonChangeOrBroken },
        { name: this.lang.reasonOther },
      ],
      reasonStatusOther: [
        { name: this.lang.reasonOverAverage },
        { name: this.lang.reasonNewAdded },
        { name: this.lang.reasonChangeOrBroken },
        { name: this.lang.reasonOther },
      ],

      showConfirmDeleteDialog: false,
      oldValue: "",
      countReferDoc: 0,
      showReferDialog: false,

      meterUsages: null,

      selectedPage: 1,
      rowsPerPage: 100,
    };
  },
  async created() {
    this.setLoading(true);
    let date = new Date();
    this.period = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    await this.getMeterUsages();
    // console.log(this.isEdit);

    // this.setLoading(false);
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      if (this.isEdit) this.$router.push(this.url);
      else this.$router.push({name: "home"})
    },
    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async gotoEdit() {
      // this.setLoading(true);
      await this.$router.push(`${this.url}/edit`);

      this.validateReferDoc();

      // let tempMeterUsage = [...this.meterUsages];
      // this.meterUsages = [];
      // let loopLength = 50;

      // while (tempMeterUsage.length > 0) {
      //   if (tempMeterUsage.length < loopLength) {
      //     loopLength = tempMeterUsage.length;
      //   }

      //   let spliceData = tempMeterUsage.splice(0, loopLength);

      //   this.meterUsages = [...this.meterUsages, ...spliceData];

      //   await this.sleep(500);
      // }

      // this.setLoading(false);
    },
    initiateData() {
      this.oldValue = "";
      this.countReferDoc = 0;
      this.showReferDialog = false;
    },
    upload() {
      this.showUploadFileDialog = true;
    },
    async getMeterUsages() {
      this.initiateData();
      this.setLoading(true);
      // this.isLoading = true;
      // this.$store.dispatch("meterUsage/resetMeterUsages");
      try {
        let where = this.payloadGetData;

        const payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          where,
        };
        // console.log("getMeterUsages", payload);

        // await this.$store.dispatch("meterUsage/getAllMeterUsages", payload);
        await this.$store.dispatch("meterUsage/getMeterUsageList", payload);


        this.setLoading(false);
        // this.isLoading = false;
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
        // this.isLoading = false;
      }

      if (this.meterUsagesList) {
        this.meterUsages = this.meterUsagesList;
      }
      this.setLoading(false);
      // console.log(this.meterUsages);
    },
    async exportData() {
      this.setLoading(true);
      let parameter = {
        ProjectId: this.payloadGetData.projectId,
        ProductType: this.payloadGetData.productType,
        ActiveStatus: this.payloadGetData.activeStatus,
        StatusUsage: this.payloadGetData.statusUsage,
        ActiveDate: this.payloadGetData.activeDate,
        Period: this.payloadGetData.period,
      };

      let payload = {
        docType: "excel",
        path: "meter/meter_usage_excel.xlsx",
        fileName: "MeterUsage",
        parameter: JSON.stringify(parameter),
      };
      try {
        await this.$store.dispatch("shared/printReport", payload);
        const d = new Date();
        let dateText = d.toISOString();
        this.getDownloadBlob(
          `${payload.fileName}_${dateText}.xlsx`,
          this.fileBlob
        );
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });

        this.setLoading(false);
      }
      this.setLoading(false);
    },
    preventCharacter(event) {
      // console.log("preventCharacter", event);
      // // let text = ""
      // if (
      //   isNaN(data.currentUsed) ||
      //   data.currentUsed < 0 ||
      //   data.currentUsed > 99999
      // ) {
      //   // console.log("Input not valid");
      //   data.currentUsed = this.oldValue;
      // } else {
      //   // console.log("Input OK");
      //   if (data.currentUsed != "" && data.currentUsed != this.oldValue){
      //     if(data.id) data.reason = "";
      //     this.save(data);
      //   }
      // }
      // console.log(event);
      const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "ArrowLeft", "ArrowRight"];
      const keyPressed = event.key;
      if (!keysAllowed.includes(keyPressed)) {
        event.preventDefault();
      }

      // if(event.key == "Tab") {
      //   console.log("if", event.key);
      //   event.preventDefault();
      // }
    },
    gotoUpdatePreviousUsed(data) {
      this.dataMeterUsage = data;
      this.showUpdatePreviousUsedDialog = true;
    },
    closeUpdatePreviousUsedDialog() {
      this.dataMeterUsage = null;
      this.showUpdatePreviousUsedDialog = false;
    },
    async buttonDown(event, index, data, oldValue) {
      if (oldValue != data.currentUsed) this.oldValue = oldValue;

      if (event.type == "focusout") {
        if (
          data.currentUsed != "" &&
          data.currentUsed != this.oldValue &&
          data.state != "W"
        ) {
          if (data.id) {
            data.reason = "";
          }
          this.save(data, false, this.oldValue);
          // alert("focus out saved!");
        }
      }

      if (event.type == "keydown") {
        this.preventCharacter(event);

        if (
          event.key == "Enter" ||
          event.key == "ArrowDown" ||
          event.key == "ArrowUp" ||
          event.key == "Tab"
        ) {
          if (this.meterUsages.length >= index) {
            if (
              data.currentUsed != "" &&
              data.currentUsed != this.oldValue &&
              data.state != "W"
            ) {
              if (data.id) data.reason = "";
              this.save(data, false, this.oldValue);
            }

            let table = this.$refs.meterUsagesTable.$el;
            let tableRow = table.getElementsByTagName("tr");
            let newNextIndex = index + 1;
            let newPreviousIndex = index - 1;

            // let nextElement = tableRow[newIndex].children[5].children[0];
            for (let i = newNextIndex; i < this.meterUsages.length; i++) {
              if (tableRow[i].children[5].children[0].disabled) {
                newNextIndex++;
              } else {
                break;
              }
            }
            for (let i = newPreviousIndex; i != 1; i--) {
              if (tableRow[i].children[5].children[0].disabled) {
                newPreviousIndex--;
              } else {
                break;
              }
            }
            if (
              event.key == "ArrowDown" ||
              event.key == "Enter" ||
              event.key == "Tab"
            ) {
              if (this.meterUsages.length > index) {
                tableRow[newNextIndex].children[5].children[0].focus();
              }
            } else if (event.key == "ArrowUp") {
              if (index != 1) {
                tableRow[newPreviousIndex].children[5].children[0].focus();
              }
            }
          }
        } else if (event.key == "Backspace") {
          let newCurrentUsed = data.currentUsed.toString().slice(0, -1);
          newCurrentUsed = parseInt(newCurrentUsed);
          if (isNaN(newCurrentUsed)) {
            data.currentUsed = "";
          } else {
            data.currentUsed = newCurrentUsed;
          }
        }
      }
    },
    getNewNextIndex() {},
    getNewPreviousIndex() {},
    async save(data, dialog, oldValueCurrentUsed) {
      data.state = "W";
      this.setNewData(data);
      // console.log(this.meterUsages);

      let payload = {
        previousUsed: data.previousUsed == "" ? 0 : parseInt(data.previousUsed),
        currentUsed: data.currentUsed == "" ? 0 : parseInt(data.currentUsed),
        updatePreviousUsedReason: data.updatePreviousUsedReason,
      };

      if (!data.id) {
        payload = {
          ...payload,
          meterId: data.meterId,
          period: data.period,
          type: "AddMeterUsage",
        };
      } else {
        payload = {
          ...payload,
          id: data.id,
          reason: data.reason,
          type: "UpdateMeterUsage",
        };
      }

      try {
        // console.log("save", payload);
        await this.$store.dispatch("meterUsage/addUpdateMeterUsage", payload);

        this.validateSaveMeterUsage(data);

        if (this.selectedMeterUsage.data) {
          this.selectedMeterUsage.data.state = "F";
          this.setNewData(this.selectedMeterUsage.data);
        } else {
          data.state = "";
          if (data.currentUsed != oldValueCurrentUsed)
            data.currentUsed = oldValueCurrentUsed;
          this.setNewData(data);
        }
        // console.log('this.selectedMeterUsage.data',this.selectedMeterUsage.data);
        if (dialog) {
          // this.closeUpdatePreviousUsedDialog();
          this.$toast.add({
            severity: "success", // severity:'success',severity:'info',severity:'warn',severity:'error'
            summary: this.lang.updatePreviousUsed, //TitleHeader
            detail: this.lang.meterCode + " : " + data.meterCode,
            life: 5000, //TimeClose
          });
        }
      } catch (error) {
        // console.log(payload.type, error);
        data.state = "";
        if (data.currentUsed != oldValueCurrentUsed) {
          data.currentUsed = oldValueCurrentUsed;
        }
        if (dialog) data.updatePreviousUsedReason = "";
        this.setNewData(data);
        
        let errorMessage = error.message;
        if (error.code == "120") {
          errorMessage = this.lang.validateRoleInsertUpdate;
        }

        this.$toast.add({
          severity: "error", // severity:'success',severity:'info',severity:'warn',severity:'error'
          summary: this.lang.notificationDialogHeader, //TitleHeader
          detail: errorMessage,
          life: 5000, //TimeClose
        });
      }
    },
    // changeFormat(val,data){
    //   // console.log(val,data);
    //   data.currentUsed = val.padStart(5, '0');
    // }
    setNewData(data) {
      this.meterUsages[this.findIndexById(data.meterId)] = data;
      // console.log('setNewData',data);
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.meterUsages.length; i++) {
        if (this.meterUsages[i].meterId === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    async validateReferDoc() {
      // console.log('validateReferDoc',this.isEdit);
      if (this.meterUsagesList && this.isEdit) {
        this.countReferDoc = this.meterUsages[0].referCode != "";
        if (this.countReferDoc) this.showReferDialog = true;
      }
    },
    async getMeterUsagesByStatus() {
      await this.getMeterUsages();
    },
    async getMeterUsagesByPeriod() {
      await this.getMeterUsages();
      this.validateReferDoc();
    },
    async deleteMeters() {
      let payload = {
        projectId: this.selectedProject.id,
        period: this.selectedPeriod,
        productType: this.productType,
      };
      try {
        // console.log("deleteMeters", payload);
        this.setLoading(true);
        await this.$store.dispatch("meterUsage/deleteMeterUsage", payload);
        this.setLoading(false);
        this.$toast.add({
          severity: "success",
          summary: this.lang.cancelMeterSuccess,
          detail: this.selectedPeriod,
          life: 5000,
        });

        await this.getMeterUsages();
      } catch (error) {
        this.setLoading(false);
        if (error == "128") {
          this.$toast.add({
            severity: "error",
            summary: this.lang.notificationDialogHeader,
            detail: this.lang.validateReferVoucher.replace(
              ":period",
              this.selectedPeriod
            ),
            life: 5000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.lang.notificationDialogHeader,
            detail: error,
            life: 5000,
          });
        }
      }
    },
    askForConfirmDeleteMeter() {
      this.showConfirmDeleteDialog = true;
    },
    confirmDelete() {
      this.deleteMeters();
      this.showConfirmDeleteDialog = false;
    },
    closeConfirmDelete() {
      this.showConfirmDeleteDialog = false;
    },
    setDataAfterUploaded() {
      this.showUploadFileDialog = false;
      this.$router.replace(`${this.url}`);
      this.getMeterUsages();
    },
    validateSaveMeterUsage(data) {
      if (this.selectedMeterUsage.code == "128") {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: this.lang.validateReferVoucher.replace(
            ":period",
            this.selectedPeriod
          ),
          life: 5000,
        });
        // return;
      }

      if (this.selectedMeterUsage.code == "129") {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail:
            this.lang.meterCode +
            " : " +
            data.meterCode +
            " " +
            this.lang.validateSetupCalculater,
          life: 5000,
        });
        // return;
      }

      if (this.selectedMeterUsage.code == "130") {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: this.lang.validateNoSetupProduct.replace(
            ":meterCode",
            data.meterCode
          ),
          life: 5000,
        });
        // return;
      }
    },
    disabled(isRefer, productName, state) {
      let isDisabled = false;
      if (isRefer == "Y" || productName == "invalid" || state == "W")
        isDisabled = true;
      return isDisabled;
    },
    // onFocusOut(event, data, oldValue) {
    //   if(oldValue != data.currentUsed) {
    //     this.oldValue = oldValue;
    //   }

    //   if (event.target.tagName == "INPUT") {
    //     if (event.key != "Enter") {
    //       if (data.currentUsed != "" && data.currentUsed != this.oldValue){
    //         if(data.id) {
    //           data.reason = "";
    //         }
    //         this.save(data, false, this.oldValue);
    //       }
    //     }
    //   }
    //   else {
    //     if (data.currentUsed != "" && data.currentUsed != this.oldValue){
    //       if(data.id) {
    //         data.reason = "";
    //       }
    //       this.save(data, false, this.oldValue);
    //     }
    //   }
    // }
    async onPageChange(event) {
      if(event.rows != this.rowsPerPage){
        this.selectedPage = 1;
      }else{
        this.selectedPage = event.page + 1;        
      }
      this.rowsPerPage = event.rows;
      await this.getMeterUsages();
    },
  },
  computed: {
    payloadGetData() {
      let activeDate = new Date(
        this.period.getFullYear(),
        this.period.getMonth() + 1,
        0
      );
      let where = {
        projectId: this.selectedProject.id,
        productType: this.productType,
        activeStatus: "A",
        activeDate: this.formatDateToISOString(activeDate),
        period: this.selectedPeriod,
        statusUsage: this.selectedStatus,
      };

      return where;
    },
    title() {
      let result = this.lang.meterUsageWater;
      if (this.productType == "W") {
        result = this.isEdit
          ? this.lang.meterUsageWaterEdit
          : this.lang.meterUsageWater;
      } else if (this.productType == "E") {
        result = this.isEdit
          ? this.lang.meterUsageElectricityEdit
          : this.lang.meterUsageElectricity;
      } else if (this.productType == "A") {
        result = this.isEdit
          ? this.lang.meterUsageCoolingAirEdit
          : this.lang.meterUsageCoolingAir;
      }
      return result;
    },
    averageUsed() {
      let result = this.lang.waterAverageUsed;
      if (this.productType == "W") {
        result = this.lang.waterAverageUsed;
      } else if (this.productType == "E") {
        result = this.lang.electricityAverageUsed;
      } else if (this.productType == "A") {
        result = this.lang.coolingAirAverageUsed;
      }
      return result;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    selectedPeriod() {
      let period = null;
      if (this.period) {
        period = this.formatPeriodToISOString(this.period);
      }
      return period;
    },
    meterUsagesList() {
      // let data = this.$store.getters["meterUsage/meterUsages"];
      // data.forEach((val) => {
      //   val.previousUsed = val.previousUsed == '0' ? "" : val.previousUsed
      // })
      return this.$store.getters["meterUsage/meterUsages"];
    },
    fileBlob() {
      return this.$store.getters["shared/blob"];
    },
    url() {
      let result = "/meter-usage/water/W";
      if (this.productType == "W") {
        result = "/meter-usage/water/W";
      } else if (this.productType == "E") {
        result = "/meter-usage/electricity/E";
      } else if (this.productType == "A") {
        result = "/meter-usage/cooling-air/A";
      }
      return `/${this.selectedProject.code}${result}`;
    },
    isEdit() {
      let isEdit = false;
      if (this.page == "edit") {
        isEdit = true;
      }
      return isEdit;
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "editCaption",
          caption: this.lang.editMeter,
          class: "p-button-primary m-0",
          icon: "icon-pen-edit",
          iconColor: "white",
          action: "gotoEdit",
          isShow: !this.isEdit,
        },
      ];

      return dataButtons;
    },
    bodyClassEdit() {
      if (this.isEdit) return "p-text-secondary";
      else return "";
    },
    selectedMeterUsage() {
      return this.$store.getters["meterUsage/selectedMeterUsage"];
    },
    totalPage() {
      return this.$store.getters["meterUsage/totalPage"];
    },
    currentPage() {
      return this.$store.getters["meterUsage/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    showCurrentPage(){
      let page = 0;
      page = this.rowsPerPage * (this.selectedPage - 1)
      return page;
    },
  },
  watch: {
    async productType() {
      await this.getMeterUsages();
    },
    page(curValue) {
      if (curValue != "edit") {
        this.getMeterUsages();
      }
    },
  },
};
</script>

<style scoped>
.ellipsis {
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

/* .p-progress-spinner-circle {
  stroke: yellowgreen !important;
} */
</style>
