export default {
	title: "รายการมิเตอร์ที่จะถูกสร้างอัตโนมัติ",
	confirmAutoCreate: "ยืนยันการสร้างอัตโนมัติ",
	autoCreateMeter: "สร้างอัตโนมัติ",

	columnUnitCode: "รหัสห้อง/บ้าน",
	columnAddressNo: "บ้านเลขที่",
	columnStatus: "สถานะ",
	columnProductCode: "รหัสสินค้า",
	columnProductName: "ชื่อสินค้า",

	activeDate: "เลือกวันที่ติดตั้ง",

	waiting: "กำลังดำเนินการ",
	created: "สร้างสำเสร็จ",
	error: "ผิดพลาด",
	errorProductType: "กรุณาตรวจสอบประเภทของรหัสรายรับ",
};
