export default {
  setNationality(state, payload) {
    let nationalities = [...state.nationality.nationalities];
    for (const nationality of payload.nationalities) {
      if(nationalities.findIndex(c => c.id === nationality.id) < 0){
        nationalities.push(nationality);
      }
    }
    state.nationality.nationalities = nationalities;
    state.nationality.currentPage = payload.currentPage;
    state.nationality.totalPage = payload.totalPage;
  },
  resetNationality(state) {
    const nationality = {
      nationalities: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.nationality = nationality;
  },
};
