export default {
  uploadFile: "Upload File",
  dragFile: "Drag drop file or ",
  addFile: "Add File",
  importFileDesc: "Please add only 1 file at a time with .XLSX or ",
  downloadfile: "Download example file",
  fileName: "File Name",
  validateLimitFile: "Can be upload 1 file at a time",
  validateFileType: "Can't upload file :name",
  validateImportFile: "The allowed file extensions are .XLSX only",

  importInvoices: "Import Invoice",
  importAll: "Import All",
  importSelected: "Import Selected",
  columnUnitCode: "Unit",
  columnDate: "Date",
  columnDueDate: "DueDate",
  columnReferCode: "Refer Code",
  columnProductCode: "Product Code",
  columnPeriod: "Period",
  columnDescription: "Description",
  columnAmount: "Total Amount",

  validateImportNoData: "File : :file no data.",
  validateUnitCode: "Row :row no enter unit code.",
  validateInvoiceDate: "Row :row no enter invoice date.",
  validateInvoiceDueDate: "Row :row no enter invoice duedate.",
  validateFormatInvoiceDate: "Row :row invalid format invoice date(dd/MM/yyyy).",
  validateFormatInvoiceDueDate: "Row :row invalid format invoice duedate(dd/MM/yyyy).",
  validateProductCode: "Row :row no enter product date.",
  validatePeriod: "Row :row no enter period.",
  validateFormatPeriod: "Row :row invalid format period(MM/YYYY(Thai)).",
  validateTotalAmount: "Row :row no enter total amount.",
  validateFormatTotalAmount: "Row :row invalid format total amount.", 
  validateUnitCodeInvalid: "Unit code : :unitCode is invalid.",
  validateReferCodeLimitText: "Row :row ReferCode specify a message with a maximum length of 3000 characters.",
  validateDescriptionLimitText: "Row :row Description specify a message with a maximum length of 750 characters.",

  statusWaiting: "Waiting",
  statusSuccess: "Success",
  importInvoiceSuccess: "import Invoice Success.",
  pleaseSelectItem: "Please select item.",
  uploadStatus: "Upload status",
  uploading: "Uploading...",
  import: "Import",
  invoiceDetail: "Invoice Detail",
  error: "Error",
  errorDetail: "Invoice amount more than pending amount.",
  
  validatingData: "Validating",
  mappingInvoice: "Mapping invoice",
  lockImportInvoice: "Lock Import Invoice",
  itemsImportAllSuccess: "All items were already imported.",
  validateInvoiceDateAndDueDate: "Row :row InvoiceDate more than InvoiceDueDate",
  unitCodeIsNotValid: "UnitCode is not valid “:unitCode”",
  productCodeIsNotValid: "ProductCode is not valid “:productCode”",
  unitCodeIsNotActive: "UnitCode is not active “:unitCode”",

};
