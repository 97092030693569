<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18.5027 12C18.5027 12.2763 18.2788 12.5002 18.0025 12.5002C17.7262 12.5002 17.5023 12.2763 17.5023 12C17.5023 11.7237 17.7262 11.4998 18.0025 11.4998C18.2788 11.4998 18.5027 11.7237 18.5027 12"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M12.5002 12C12.5002 12.2763 12.2763 12.5002 12 12.5002C11.7237 12.5002 11.4998 12.2763 11.4998 12C11.4998 11.7237 11.7237 11.4998 12 11.4998C12.2763 11.4998 12.5002 11.7237 12.5002 12"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M6.4977 12C6.4977 12.2763 6.27375 12.5002 5.99749 12.5002C5.72123 12.5002 5.49728 12.2763 5.49728 12C5.49728 11.7237 5.72123 11.4998 5.99749 11.4998C6.27375 11.4998 6.4977 11.7237 6.4977 12"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: 24,
		},
		height: {
			type: [Number, String],
			default: 24,
		},
		iconColor: {
			type: String,
			default: "secondary",
		},
	},
	computed: {
		strokeColor() {
			return "p-stroke-" + this.iconColor;
		},
	},
};
</script>

<style lang="scss" scoped>
svg {
	display: inline-block;
	vertical-align: baseline;
	margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
