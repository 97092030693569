export default {
	title: "Supplier",
	createCaption: "Create Supplier",

	containerTitle: "Supplier Listing",
	dropdownAll: "All",
	dropdownCode: "Code",
	dropdownName: "Name",
	dropdownTelephoneNumber: "Telephone",

	columnCode: "Code",
	columnName: "Name",
	colummTaxId: "Tax Id",
	colummTelephoneNumber: "Telephone",
	colummEmail: "Email",

	importSupplier: "Import Supplier",
};
