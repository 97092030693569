<template>
  <PvDialog
    :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
    :style="{ width: '40vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div
        class="flex justify-content-between align-items-center flex flex-wrap"
      >
        <span class="font-lg">{{ lang.addEdit }}</span>
        <!-- <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        /> -->
      </div>
      <PvDivider></PvDivider>
    </template>

    <section class="flex flex-wrap" :class="textFormClass">
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
        <label for="account">{{ lang.account }}</label>
        <PvSkeleton
          v-if="!isSharedAccountReady"
          class="inputfield w-full mt-1"
          height="39px"
        />
        <base-accounting-detail-auto-complete
          id="account"
          v-model="selectedAccount"
          class="inputfield w-full mt-1"
          @load-finish="accountDataReady"
          :isLoadingData="true"
          v-show="isSharedAccountReady"
          :class="{ 'p-invalid': selectedAccountError }"
          @item-select="selectedAccountError = false"
          @is-inactive="selectedAccount = null"
        />
        <small v-if="selectedAccountError" id="selectedAccount-help" class="p-error">
          {{ lang.validateSelectedAccount }}
        </small>
      </div>
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
        <label for="description">{{ lang.description }}</label>
        <PvInputText
          id="description"
          type="text"
          class="inputfield w-full mt-1"
          v-model="description"
        />
      </div>
      <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
        <label for="debit">{{ lang.debit }}</label>
        <PvInputNumber
          id="debitAmount"
          class="mt-1 w-full"
          inputClass="inputfield w-full text-right"
          v-model="debitAmount"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="2"
          :min="0"
          @focus="$event.target.select()"
          placeholder="0.00"
        />
      </div>
      <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
        <label for="credit">{{ lang.credit }}</label>
        <PvInputNumber
          id="creditAmount"
          class="mt-1 w-full"
          inputClass="inputfield w-full text-right"
          v-model="creditAmount"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="2"
          :min="0"
          @focus="$event.target.select()"
          placeholder="0.00"
        />
      </div>
    </section>

    <template #footer>
      <div class="flex justify-content-between align-items-center m-3">
        <PvButton
          :label="lang.close"
          class="p-button-outlined p-button-secondary w-full"
          @click="closeDialog"
        />
        <PvButton :label="lang.confirm" class="w-full" @click="confirm" />
      </div>
    </template>
  </PvDialog>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
export default {
  props: {
    data: {
      type: Object,
    },
    isOpen: {
      type: Boolean,
    },
  },
  emits: ["close", "item-selected"],
  setup() {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.accountingFrequently.edit,
          ...lang.th.master.accountingFrequently.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.accountingFrequently.edit,
          ...lang.en.master.accountingFrequently.listing,
        };
      }
    });

    return {
      lang: mergeLang,
      textFormClass,
    };
  },
  data() {
    return {
      isSharedAccountReady: false,
      selectedAccount: null,
      description: "",
      debitAmount: 0,
      creditAmount: 0,
      selectedAccountError: false,
    };
  },
  mounted() {
    this.initiateData();
  },
  methods: {
    closeDialog() {
      this.initiateData();
      this.$emit("close");
    },
    accountDataReady() {
      this.isSharedAccountReady = true;
    },
    initiateData() {
      this.selectedAccount = null;
      this.description = "";
      this.debitAmount = 0;
      this.creditAmount = 0;
      this.selectedAccountError = false;
    },
    setData() {
      this.initiateData();
      if (this.data) {
        this.selectedAccount = this.sharedAccounts.find(
          (account) => account.id == this.data.accountId
        );
        this.description = this.data.description;
        this.debitAmount = this.data.debitAmount;
        this.creditAmount = this.data.creditAmount;
      }
    },
    confirm() {
      if(!this.selectedAccount) {
        this.selectedAccountError = true;
        return;
      }
      this.$emit("item-selected", this.payload);
      this.closeDialog();
    },
  },
  computed: {
    payload() {
      let payload = {
        accountId: this.selectedAccount ? this.selectedAccount.id : null,
        accountCode: this.selectedAccount
          ? this.selectedAccount.code.trim()
          : null,
        accountName: this.selectedAccount
          ? this.selectedAccount.name.trim()
          : null,
        description: this.description,
        debitAmount: this.debitAmount,
        creditAmount: this.creditAmount,
      };

      if (this.data) {
        payload = {
          ...payload,
          id: this.data.id,
        };
      }

      return payload;
    },
    sharedAccounts() {
      return this.$store.getters["shared/accountingDetailAccounts"];
    },
  },
  watch: {
    isOpen() {
      this.setData();
    },
    isSharedAccountReady(curValue) {
      if (curValue) {
        this.setData();
      }
    },
  },
};
</script>
