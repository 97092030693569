export default {
  title: "Credit Note",
  dateRange: "Date Range",
  documentCode: "Document Code",
  date: "Document Date",
  unit: "Unit",
  address:"Address no.",
  customerName: "Customer Name",
  validateSearchText:"Please enter searchText.",
	searchTextPlaceholder: "Fill up to search.",
  
  print: "Print",
  printed: "Printed",
  columnCreateBy: "Create By",
  columnUpdateBy: "Update By",
  columnCreateDate:"Create Date",
  columnUpdateDate:"Update Date", 
  columnPrice:"Net Amount",
};
