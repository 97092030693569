export default({
    createTitle: "Create Notice",
    editTitle: "Edit Notice",
    containerTitle: "Notice Detail",
	labelName: "Notice Name",
	placeholderName: "Enter Notice Name",
	validateName: "Enter Notice Name",
    tabManageProduct: "AR Product List",
    tabManageReport: "Edit Form",
	labelIsGroupProduct: "Print Group",
    isGroupProduct1: "Yes",
    isGroupProduct2: "No",
    columnIsGroupProduct: "Group Product",
    tooltipPenaltyProduct: "Cannot be displayed without including items.",
    tagGroup: "Grouped",
    tagUngroup: "Not grouped",
    labelManageProduct: "Account Receivable Product List",
    addProduct: "Add AR Product",

    validateDetailTitle: "Unable To Customize Report",
    validateDetailContent: "Please enter at least 1 item.",

    validateImageLogoTitle: "Can not upload file {0}",
    validateSizeImageLogoContent: "Allowed File Size Cannot Exceed 50 Kb Only",
    validateTypeImageLogoContent: "The allowed file extensions are .JPEG, .JPG and .PNG only",

    labelIsSuspend: "Add suspend date in notice",

    tooltipExampleFooter: "(Example) Position of added data",
    tooltipDisplayLogoHeader: "(Example) Header without logo &  with logo",
    tooltipSubject: "Input data will be added to title section. ",
    tooltipTo: "Input data will be added to recipient name section.",
    tooltipAddress: "Input data will be added to address section. ",
    tooltipContentHeader: "Input data will be added to 1st paragraph section.",
    tooltipContentFootert: "Input data will be added to 2nd paragraph section.",
    tooltipSignature: "Input data will be added to sender signature section.",
    tooltipSignatureName: "Input data will be added to sender name section.",
    tooltipSignaturePosition: "Input data will be added to sender position section.",
    tooltipPageFooter: "Input data will be added to footer section.",
    
    labelIsDisplayLogo: "Add logo on header",
    labelDisplayLogo: "Logo",
    smallDisplayLogoInfo1: "When logo are added,  text on header will be left aligned.",
    smallDisplayLogoInfo2: "A 16:9 ratio image (e.g., 86x48 px) is preferred. Only JPEG, JPG, and PNG files with max size of 50 KB.",
    labelMarkup: "Insert markup", 

    labelSubject: "Title",
    labelEditorSubjectTh: "Title (TH)",
    labelEditorSubjectEn: "Title (EN)",

    labelTo: "Recipient Name ",
    labelToTh: "Recipient Name (TH)",
    labelToEn: "Recipient Name (EN)",

    labelAddress: "Address",
    labelAddressTh: "Address (TH)",
    labelAddressEn: "Address (EN)",

    labelContentHeader: "1st Paragraph",
    labelContentHeaderTh: "1st Paragraph (TH)",
    labelContentHeaderEn: "1st Paragraph (EN)",

    labelContentFooter: "Add 2nd Paragraph",
    labelContentFooterTh: "2nd Paragraph (TH)",
    validateContentFooterTh: "Enter 2nd Paragraph (TH)",
    labelContentFooterEn: "2nd Paragraph (EN)",
    validateContentFooterEn: "Enter 2nd Paragraph (EN)",

    labelSignatureUrl: "Add sender signature",
    labelIsSameSignatureOnBothLang: "Use same signature for both TH and EN version",
    isSameSignatureOnBothLang1: "Yes",
    isSameSignatureOnBothLang2: "No",
    labelSignatureUrlTh: "Sender Signature (TH)",
    labelSignatureUrlEn: "Sender Signature (EN)",
    smallSignatureUrl: "A 16:9 ratio image (e.g., 75x42 px) is preferred. Only JPEG, JPG, and PNG files with max size of 50 KB.",

    labelSignatureFullName: "Add sender name",
    labelSignatureFullNameTh: "Sender Name (TH)",
    validateSignatureFullNameTh: "Enter sender name (TH)",
    labelSignatureFullNameEn: "Sender Name (EN)",
    validateSignatureFullNameEn: "Enter sender name (EN)",

    labelSignaturePosition: "Add sender position",
    labelSignaturePositionTh: "Sender Position (TH)*",
    validateSignaturePositionTh: "Enter sender position (TH)",
    labelSignaturePositionEn: "Sender Position (EN)*",
    validateSignaturePositionEn: "Enter sender position (EN)",

    labelPageFooter: "Footer",
    labelPageFooterTh: "Footer (TH)",
    labelPageFooterEn: "Footer (EN)",

    validateImage: "Please upload file",
    validateField: "You Have Not Entered In The {0} Field",

    contentSaved: "The Information Has Been Saved Successfully",
    titleNoticeCustomReportDeleteConfirm: "Delete Notice",
    contentNoticeCustomReportDeleteConfirm: "Do you want to delete this notice?",
    titleNoticeCustomReportDeleted: "Deleted successfully",
    contentNoticeCustomReportDeleted: "You deleted this notice successfully.",

    validateSaveAndPreviewTitle: "Please save before previewing",
    validateSaveAndPreviewContent: "System can’t proceed previewing if data is not saved.",
    buttonDialogSaveAndPreview: "Save and preview",
    validateDeleteContent: "You have not save data yet.",
})