export default {
  accountingParentAccounts(state) {
    return state.accountingParent.accountingParents;
  },
  accountingParentCurrentPage(state) {
    return state.accountingParent.currentPage;
  },
  accountingParentNextPage(state) {
    return state.accountingParent.currentPage + 1;
  },
  accountingParentTotalPage(state) {
    return state.accountingParent.totalPage;
  },
  accountingParentIsLastPage(_, getters) {
    // return (
    //   getters.accountingParentCurrentPage === getters.accountingParentTotalPage &&
    //   getters.accountingParentTotalPage !== 0
    // );
    if (getters.accountingParentCurrentPage > 0 && getters.accountingParentTotalPage === 0) {
			return true;
		} else {
			return getters.accountingParentCurrentPage === getters.accountingParentTotalPage && getters.accountingParentTotalPage !== 0;
		}
  },
  accountingParentReload(state) {
    return state.accountingParent.isReload;
  },
};
