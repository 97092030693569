<template>
  <svg
  :width="width"
		:height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.62827 5.03613L7.99993 2.66448L10.3716 5.03613"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.00002 9.77856L8.00002 2.66427"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3357 10.9636C13.3357 12.2735 12.2738 13.3353 10.964 13.3353H5.03621C3.72638 13.3353 2.66455 12.2735 2.66455 10.9636"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "primary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
