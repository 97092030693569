<template>
  <base-toolbar 
    :title="lang.title" 
    @goBack="goBack"
    :hideBackButton="isHideBack"
  ></base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :classTitle="baseDialogClassTitle"
      :titleConfirm="titleConfirmDialog"
      :wordingConfirm="wordingConfirmDialog"
      :dataButtons="dataButtonDialog"
      @close="closeValidateDialog"
    ></base-dialog>

    <div v-if="isWaitForGetQueue" class="center">
      <PvProgressSpinner strokeWidth="5"/>
    </div>

    <div class="surface-card shadow-2 p-5 border-round mt-5" v-else>
      <div>
        <label class="font-lg font-semibold">{{ titleDetail }}</label>
      </div>

      <PvDivider></PvDivider>

      <div v-if="isInQueue">
        <label class="font-md font-normal">{{
          lang.titleDetailCalculatingDesc
        }}</label>
      </div>

      <div v-else>
        <base-card
          :titleHeader="lang.titleSelectDate"
          :isVisibleExpandButton="false"
          :isHideBackground="true"
          :isVisibleCard="true"
          class="p-0"
        >
          <section class="flex flex-wrap" :class="textFormClass">
            <div
              class="lg:col-3 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4"
            >
              <label>{{ lang.calculateDate }}</label>
              <PvCalendar
                id="calculateDate"
                v-model="calculateDate"
                class="inputfield w-full mt-2"
                showIcon
                dateFormat="dd/mm/yy"
                @date-select="checkPenaltyDate"
                :disabled="!isRoleInsert || isDisabledNextStep"
                :class="{ 'p-invalid': calculateDateError }"
              />
              <small
                v-if="calculateDateError"
                id="invoiceDate-help"
                class="p-error"
              >
                {{ lang.validateCalculateDate }}
              </small>
            </div>
            <div
              class="lg:col-3 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4"
            >
              <label>{{ lang.dueDate }}</label>
              <PvCalendar
                id="dueDate"
                v-model="dueDate"
                class="inputfield w-full mt-2"
                showIcon
                dateFormat="dd/mm/yy"
                :disabled="!isRoleInsert || isDisabledNextStep"
                :class="{ 'p-invalid': dueDateError }"
              />
              <small v-if="dueDateError" id="dueDate-help" class="p-error">
                {{ lang.validateDueDate }}
              </small>
            </div>
            <div
              class="lg:col-3 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4"
            >
              <label>{{ lang.calculatePenaltyDate }}</label>
              <PvCalendar
                id="penaltyDate"
                v-model="penaltyDate"
                class="inputfield w-full mt-2"
                showIcon
                dateFormat="dd/mm/yy"
                :disabled="!isRoleInsert || isDisabledPenaltyDate"
              />
            </div>
          </section>
          <div class="pt-4 pb-1">
            <PvButton :disabled="checkDisabledNextStep" @click="calculateNotice">
              <span class="font-sm font-normal">{{
                lang.gotoCalculateNotice
              }}</span>
            </PvButton>
          </div>
        </base-card>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetRoleByBook from "../../../hooks/getRoleByBook.js";
export default {
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const {
      formatCurrency,
      formatDate,
      formatDateToISOString,
      formatPeriodToISOString,
    } = useFormat();
    const store = useStore();

    const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.calculateNotice.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.calculateNotice.listing,
        };
      }
    });

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      formatPeriodToISOString,
      isHideBack,
      getRoleByBookByCode,
      isSharedRoleByBookReady,
    };
  },
  data() {
    return {
      isLoading: false,
      showValidateDialog: false,
      dataButtonDialog: [
        {
          id: "close",
          caption: this.lang.close,
          class: "w-full p-button-outlined p-button-secondary",
          action: "close",
        },
      ],
      titleConfirmDialog: "",
      wordingConfirmDialog: "",
      baseDialogClassTitle: "",
      interval: null,
      isInQueue: false,
      calculateDate: null,
      dueDate: null,
      penaltyDate: null,
      // calculateDateError: false,
      isDisabledNextStep: false,
      isDisabledPenaltyDate: false,

      isWaitForGetQueue: true,
    };
  },
  async mounted() {
    await this.initiateData();
    await this.roleByBookDataReady();
    await this.generateQueueStatus();
    await this.getPenaltyType();
    await this.$store.dispatch("shared/getAllCurrency");

    // console.log(this.noticeGenerateQueueStatus);

    await this.checkPenaltyType();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      clearInterval(this.interval);
      this.isInQueue = false;
      this.$router.push({name: "home"})
    },
    closeValidateDialog() {
      if (this.isDisabledNextStep) {
        this.isDisabledNextStep = false;
      }
      this.showValidateDialog = false;
    },
    async getNoticeGenerateQueueStatus() {
      try {
        let projectId = this.selectedProject.id;
        await this.$store.dispatch(
          "arCalculateNotice/getNoticeGenerateQueueStatus",
          projectId
        );
        this.isWaitForGetQueue = false;
      } catch (error) {
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = error;
        this.showValidateDialog = true;
        this.isWaitForGetQueue = false;
      }
    },
    getNewNoticeGenerateQueueStatus() {
      this.interval = setInterval(() => {
        if (this.noticeGenerateQueueStatus.isAlreadyInQueue == false) {
          clearInterval(this.interval);
          this.gotoNoticeWaitForApprove();
          return;
        } else {
          this.getNoticeGenerateQueueStatus();
        }
      }, 10000);
    },
    async checkPenaltyType() {
      let penaltyType = this.penaltyType.type;
    //   console.log("penaltyType", penaltyType);
      if (penaltyType == "N") {
        this.isDisabledPenaltyDate = true;
      } else if (penaltyType == "E") {
        this.isDisabledPenaltyDate = true;
        this.penaltyDate = new Date(
          this.calculateDate.getFullYear(),
          this.calculateDate.getMonth() + 1,
          0
        );
      }
    },
    async validateCalculateDate() {
      if (this.calculateDate > this.dueDate) {
        // this.calculateDateError = true;
      } else {
        // this.calculateDateError = false;
        this.isDisabledNextStep = false;
      }
    },
    async checkPenaltyDate() {
      await this.validateCalculateDate();
      if (this.penaltyType.type == "Y" || this.penaltyType.type == "N") {
        let date = this.calculateDate.getDate();
        let calculateDate = new Date(
          this.calculateDate.getFullYear(),
          this.calculateDate.getMonth(),
          date
        );
        if (date == 1) {
          this.penaltyDate = new Date(
            this.calculateDate.getFullYear(),
            this.calculateDate.getMonth(-1),
            0
          );
        } else {
          this.penaltyDate = calculateDate;
        }
      } else if (this.penaltyType.type == "E") {
        this.penaltyDate = new Date(
          this.calculateDate.getFullYear(),
          this.calculateDate.getMonth() + 1,
          0
        );
      }
    },
    async getPenaltyType() {
      try {
        let projectId = this.selectedProject.id;
        await this.$store.dispatch("penalty/getPenaltyType", projectId);
      } catch (error) {
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = error;
        this.showValidateDialog = true;
      }
    },
    async calculateNotice() {
      await this.addNoticeGenerateQueue();
      // console.log(this.selectedNoticeGenerateQueue);
      if(this.selectedNoticeGenerateQueue.success == true) {
        // await this.getNoticeGenerateQueueStatus();
        // await this.checkNoticeGenerateQueueStatus();
        await this.generateQueueStatus();
      }
    },
    async addNoticeGenerateQueue() {
      try {
        let currencyId = this.currency.find((data) => data.code.trim() == "THB").id;
        const payload = {
          policyHeaderId: "",
          documentBookId: this.roleByBook.documentBookId,
          employeeId: this.employee.id,
          currencyId: currencyId,
          monthOf: this.formatPeriodToISOString(this.calculateDate),
          docDate: this.formatDateToISOString(this.calculateDate),
          dueDate: this.formatDateToISOString(this.dueDate),
          vatDate: this.formatDateToISOString(this.calculateDate),
          calDate: this.formatDateToISOString(this.penaltyDate),
          branchId: this.selectedProject.branchId,
          projectId: this.selectedProject.id,
          isCalPenalty: "Y" ,
        };

        // console.log("addNoticeGenerateQueue", payload);

        await this.$store.dispatch("arCalculateNotice/addNoticeGenerateQueue", payload);
      } catch (error) {
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = error;
        this.showValidateDialog = true;
      }
    },
    gotoNoticeWaitForApprove() {
      let calculateDate = this.formatDateToISOString(this.calculateDate)
      this.$router.push({ name: "ar-notice-wait-for-approve", query: {calculateDate: calculateDate} });
    },
    async checkNoticeGenerateQueueStatus() {
      if (this.noticeGenerateQueueStatus.isAlreadyInQueue == true) {
        this.isInQueue = true;
        this.getNewNoticeGenerateQueueStatus();
      } else if (
        this.noticeGenerateQueueStatus.isAlreadyInQueue == false &&
        this.noticeGenerateQueueStatus.statusWaitForGenBill == "F"
      ) {
        // this.gotoNoticeWaitForApprove();
        await this.getNotices();
        if (
          (this.notices && this.notices.length > 0) && 
          this.roleByBook.documentBookId == this.noticeGenerateQueueStatus.documentBookId
        ) {
          this.gotoNoticeWaitForApprove();
        }
      }
    },
    async generateQueueStatus() {
      await this.getNoticeGenerateQueueStatus();
      await this.checkNoticeGenerateQueueStatus();
    },
    async roleByBookDataReady() {
      if (this.isSharedRoleByBookReady && !this.isRoleInsert) {
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = this.lang.validateInsert;
        this.showValidateDialog = true;
      }
    },
    async initiateData() {
      await this.$store.dispatch("arCalculateNotice/resetNoticeGenerateQueue");
      let date = new Date(); 
      this.calculateDate = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dueDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.penaltyDate = new Date(date.getFullYear(), date.getMonth(-1), 0);
    },
    async getNotices() {
      try {
        let where = {
          projectId: this.selectedProject.id,
          documentBookId: this.roleByBook.documentBookId,
        };
        const payload = {
          page: 1,
          limit: 10,
          where,
        };
        await this.$store.dispatch("arCalculateNotice/getNotices", payload);
        // console.log("notices", this.notices);
      } catch (error) {
        this.titleConfirmDialog = this.lang.notificationDialogHeader;
        this.wordingConfirmDialog = error;
        this.confirmDialog = true;
        this.showValidateDialog = true;
      }
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    roleByBook() {
      return this.getRoleByBookByCode("NOTICE");
    },
    isRoleInsert() {
      if (
        this.roleByBook &&
        this.isSharedRoleByBookReady &&
        this.roleByBook.insert == "Y"
      ) {
        return true;
      }
      return false;
    },
    titleDetail() {
      let result = this.lang.titleDetail;
      if (this.isInQueue) {
        result = this.lang.titleDetailCalculating;
      }
      return result;
    },
    employee() {
      return this.$store.getters.employee;
    },
    currency() {
      return this.$store.getters["shared/currencycurrencies"];
    },
    noticeGenerateQueueStatus() {
      return this.$store.getters["arCalculateNotice/noticeGenerateQueueStatus"];
    },
    penaltyType() {
      return this.$store.getters["penalty/penaltyType"];
    },
    selectedNoticeGenerateQueue() {
      return this.$store.getters["arCalculateNotice/selectedNoticeGenerateQueue"];
    },
    calculateDateError() {
      if (this.calculateDate == null) {
        return true;
      } else if (this.dueDate && this.calculateDate > this.dueDate) {
        return true;
      }
      return false;
    },
    dueDateError() {
      if (this.dueDate == null) {
        return true;
      }
      return false;
    },
    checkDisabledNextStep() {
      if (
        !this.isRoleInsert ||
        this.isDisabledNextStep ||
        this.calculateDateError ||
        this.dueDateError
      ) {
        return true;
      }
      return false;
    },
    notices() {
      return this.$store.getters["arCalculateNotice/notices"];
    },
  },
  watch: {
    isSharedRoleByBookReady() {
      this.roleByBookDataReady();
    },
  },
};
</script>

<style scoped>
.center {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  z-index: 10000;
}
</style>
