export default {
	isInvoicePrintOrderChanges(state) {
		return state.isInvoicePrintOrderChanges;
	},
	isPrintOrder(state) {
		return state.isPrintOrder;
	},
	isReceiptPrintOrder(state) {
		return state.isReceiptPrintOrder;
	},
	isNoticePrintOrder(state) {
		return state.isNoticePrintOrder;
	},
	isWithholdingTaxPrintOrder(state) {
		return state.isWithholdingTaxPrintOrder;
	},
};
