import { useStore } from "vuex";
export default function useFormat() {
  const store = useStore();

  // const formatCurrency = (value) => value.toLocaleString("th-TH");

  const formatCurrency = (value) => {
    let val = (value / 1).toFixed(2)
    return val.toLocaleString("th-TH").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const formatNumber = (value) => {
    let val = (value / 1)
    return val.toLocaleString("th-TH").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const formatCurrencyTable = (obj, field) => {
    if (obj.id.startsWith('dummy') && !obj.productCode) {
      return ''
    } else {
      // return obj[field].toLocaleString("th-TH");
      let number = (obj[field] / 1).toFixed(2)
      return number.toLocaleString("th-TH").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // return obj[field].toLocaleString("th-TH").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }


  const formatDate = (value, isCheckLanguage = true) => {
    const d = isoStringToDate(value);
    const day = d.getDate();
    const month = d.getMonth() + 1;
    let year = d.getFullYear();
    if (store.getters.selectedLanguage === "TH" && isCheckLanguage) {
      year += 543;
    }
    return day + "/" + month + "/" + year;
  };

  const formatDateToISOString = (value) => {
    const day = value.getDate();
    const month = value.getMonth() + 1;
    let year = value.getFullYear();

    return year.toString() + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
  }

  function isoStringToDate(value) {
    return new Date(value);
  }

  const getCurrentPeriod = () => {
    let date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear() + 543;
    return month.toString().padStart(2, '0') + '/' + year.toString();
  }

  const formatPeriodToISOString = (value) => {
    const month = value.getMonth() + 1;
    let year = value.getFullYear() + 543;
    return month.toString().padStart(2, '0') + '/' + year.toString();
  }

  const formatISODateToLocaleDateString = (value) => {
    const event = new Date(value);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    let date = "";

    if (store.getters.selectedLanguage === "TH") {
      date = event.toLocaleDateString('th-TH', options)
    }
    else {
      date = event.toLocaleDateString('en-GB', options)
    }
    return date;
  }

  const formatDateToLocaleDateString = (value) => {
    const day = value.getDate();
    const month = value.getMonth() + 1;
    let year = value.getFullYear();

    return (day.toString().padStart(2, "0") + "/" + month.toString().padStart(2, "0") + "/" + year.toString());
  };

  const formatDateTime = (value) => {
    const d = isoStringToDate(value);
    const day = d.getDate();
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    const hours = d.getHours();
    const minutes = d.getMinutes();

    return day + "/" + month + "/" + year + " " + hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0");
  };

  const formatSubdistrict = (value) => {
    let result = "";
    if (value.province && (value.province == "กรุงเทพมหานคร")) {
      result = `แขวง${value.subdistrict} เขต${value.district}`
    }
    else {
      result = `ตำบล${value.subdistrict} อำเภอ${value.district}`
    }

    return result;
  };

  /**
   * Remove special character from string.
   *
   * @param str String to remove.
   */

  const removeSpecialCharacter = (str) => {
    // This regex matches any character that is not a letter, number, or Thai character
    return str.replace(/[^\w\u0E00-\u0E7F.]/g, '');
  }

  const formatDateToPeriod = (value) => {
    const d = isoStringToDate(value);
    const month = d.getMonth() + 1;
    let year = d.getFullYear();
    return month.toString().padStart(2, '0') + '/' + year.toString();
  }

  const formatDateToPeriodTh = (value) => {
    const event = new Date(value);
    const options = { month: 'long', year: 'numeric' };
    let date = event.toLocaleDateString('th-TH', options)
    return date;
  }

  const formatDateTimeToISOString = (value) => {
    const day = value.getDate();
    const month = value.getMonth() + 1;
    let year = value.getFullYear();
    const hours = value.getHours();
    const minutes = value.getMinutes();
    const secounds = value.getSeconds();

    return year.toString() + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0')
      + "T" + hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + secounds.toString().padStart(2, '0');
  }

  return {
    formatCurrency,
    formatDate,
    formatDateToISOString,
    formatCurrencyTable,
    getCurrentPeriod,
    formatPeriodToISOString,
    formatISODateToLocaleDateString,
    formatDateToLocaleDateString,
    formatDateTime,
    formatNumber,
    formatSubdistrict,
    removeSpecialCharacter,
    formatDateToPeriod,
    formatDateToPeriodTh,
    formatDateTimeToISOString,
  };
}
