export default {
  async getAccountingDetails(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Accounting";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get Accounting Detail"
      );
      throw error;
    }
    // console.log("accounting", responseData);

    // const data = responseData.data;
    const accountingDetail = {
      accountingDetails: responseData.data,
      totalPage: responseData.page.pageCount,
      currentPage: responseData.page.currentPage,
    };
    context.commit("setAccountingDetail", accountingDetail);
  },
  async getAllAccountingDetails(context) {
    while (!context.getters.accountingDetailIsLastPage) {
      let where = {
        projectId: context.rootGetters.selectedProject.id,
        isDetail: "Y"
      };
      const payload = {
        page: context.getters.accountingDetailNextPage || 1,
        where,
      };
      await context.dispatch("getAccountingDetails", payload);
    }
  },
  resetAccountingDetail(context) {
    context.commit("resetAccountingDetail");
  },
};
