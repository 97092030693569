export default {
    setSignatures(state, payload){
        state.signatures = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },
    setSelectedSignature(state, payload){
        state.selectedSignature = payload
    },
    setSignatureReference(state, payload){
        state.signatureReference = payload
    },
    setSignatureAll(state, payload){        
        let signatures = [...state.signatures];
        for (const signature of payload.data) {
            if(signatures.findIndex(c => c.id === signature.id) < 0){
                signatures.push(signature);
            }
        }
        state.signatureAll = signatures;
        state.signatureTotalPage = payload.page.pageCount
        state.signatureCurrentPage = payload.page.currentPage
    },
}