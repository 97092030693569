export default {
  locationLocations(state) {
    return state.location.locations;
  },
  locationCurrentPage(state) {
    return state.location.currentPage;
  },
  locationNextPage(state) {
    return state.location.currentPage + 1;
  },
  locationTotalPage(state) {
    return state.location.totalPage;
  },
  locationIsLastPage(_, getters) {
    return getters.locationCurrentPage === getters.locationTotalPage && getters.locationTotalPage !== 0;
  }
};
