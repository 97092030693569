export default {
    title: "คืนเงินค้ำประกัน",
    createRefundGuarantee: "สร้างคืนเงินค้ำประกัน",
    titleListing: "รายการคืนเงินค้ำประกัน",
    dateRange: "ช่วงวันที่",
    documentCode: "เลขที่เอกสาร",
    unit: "ยูนิต",
    address:"บ้านเลขที่",
    customerName: "ชื่อลูกค้า",
    searchTextPlaceholder: "กรอกข้อมูลเพื่อค้นหา",
    columnNetAmount: "จำนวนเงิน",
    refunded: "คืนแล้ว",
    printed: "พิมพ์แล้ว",
    columnCreateDate:"วันที่สร้าง",
    columnUpdateDate:"วันที่แก้ไขล่าสุด", 
    columnCreateBy: "ผู้สร้าง",
    columnUpdateBy: "ผู้แก้ไข",
    validateRoleSelect: "คุณไม่มีสิทธิ์เข้าถึงเอกสาร",
}