<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.0029 21.0036H5.10117C4.00187 21.0039 3.10832 20.117 3.10034 19.0178L2.99624 5.01193C2.99234 4.4787 3.20139 3.96594 3.57704 3.58746C3.9527 3.20899 4.46388 2.99609 4.99713 2.99609H16.1737C16.7044 2.99609 17.2133 3.20698 17.5883 3.58234L20.4175 6.41152C20.7927 6.7867 21.0036 7.29551 21.0037 7.82611V19.0028C21.0037 19.5335 20.7931 20.0425 20.4179 20.4177C20.0426 20.793 19.5336 21.0036 19.0029 21.0036Z"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.9947 2.99609V6.99776C15.9945 7.55019 15.5467 7.99798 14.9942 7.99818H8.99175C8.43931 7.99798 7.99153 7.55019 7.99133 6.99776V2.99609"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="12"
      cy="14.501"
      r="2.50104"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
