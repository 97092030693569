export default{
	createResident:"Add Resident",
	editResident:"Edit Resident",
	labelFromResident: "Customer",
	generalInfo : "General Information",
	emergencyContact: "Emergency Contact",
	billingContact: "Billing Contact (Invoice, Receipt, etc.)",
	idCardContact: "Id Card Contact",
	contact: "Contact",
	living: "Living Detail",

	status: "Resident Status",

	code: "Code",
	codePlaceholder: "Type resident code",
	validateCode: "Please type resident code",
	
	name: "Name",
	namePlaceholder: "Type resident name",
	validateName: "Please type resident name",
	
	mobileNumber: "Telephone",
	mobileNumberPlaceholder: "Type telephone",
	validateMobileNumber: "Please type telephone",
	validateMobileNumberFormat:"Please type numbers only.",
	
	birthDate: "Birth Date",
	birthDatePlaceholder: "Type birth date",

	email: "Email",
	emailPlaceholder: "Type email",
	validateEmail: "Please type email",
	validateEmailFormat: "Please type valid format Email",

	residentType: "Resident Type*",
	types: [
		{name: 'Individual', key: 'individual'}, 
		{name: 'Juristic', key: 'juristic'}, 
	],

	individualTaxId: "ID card no./Tax Id",
	juristicTaxId: "Tax Id*",
	individualTaxIdPlaceholder : "Type ID card no./Tax Id",
	juristicTaxIdPlaceholder : "Type tax Id.",
	validateIndividualTaxId : "Please type ID card no.",
	validateJuristicTaxId : "Please type tax Id.",
	validateIndividualTaxIdDigit: "Please type TaxNo example 0-0000-00000-00-0",
	validateJuristicTaxIdDigit: "Please type TaxNo example 0-0000-00000-00-0",
	
	isSupplier: "Do you want to add customer to be supplier?",
	yes: "Yes",
	
	nationality: "Nationality",
	
	gender: "Resident Type",
	genders: [
		{name: 'Male', key: 'Male'}, 
		{name: 'Female', key: 'Female'}, 
		{name: 'Company', key: 'Company'}, 
	],

	
	contactName: "Contact Name",
	contactNamePlaceholder: "Type Contact Name",


	address: "Address",
	billingAddressPlaceholder: "Type the address for Invoice/Receipt",
	validateBillingAddress: "Please type the address for Invoice/Receipt",
	postCode: "Postcode",
	postCodePlaceholder: "Type postcode",
	validatePostCode: "Please type postcod",
	validatePostCodeFormat: "Please type 5 digit for numbers only.",
	subDistrict: "Sub-area/Sub-district",
	subDistrictPlaceholder: "Type Sub-area/Sub-district",
	district: "Sub-area/Sub-district, Area/District",
	districtPlaceholder: "Type Sub-area/Sub-district, Area/District",
	validateBillingDistrict: "Please type Sub-area/Sub-district, Area/District",
	province: "Province",
	provincePlaceholder: "Type province",
	validateProvince: "Please type province",
	documentLang: "Document Language",
	documentLangItem: [
        { name: "Thai", code: "Thai" },
        { name: "English", code: "English" },
    ],
	deliveryChannel: "Document delivery channel",
	isGroupDoc: "Group Invoice (For Owner with multiple rooms)",
	groupDocs: [
		{name: 'Y', key: 'Y'}, 
		{name: 'N', key: 'N'}, 
	],
	idCardAddressPlaceholder: "Type the address according to the address on the ID card.",
	registerDate: "Register Date",
	expiredDate: "Expired Date",
	datePlaceholder: "Select Date",
	contactAddressPlaceholder: "Type contact address.",
	
	room: "Unit/ Address",
	residentDetail: "Resident Detail",
	createNewResident: "Create Resident",
	askForCreateNewResident: "Are you sure you want to discard change ",
	askForCreateNewResidentConfirm: "Are you create new document?",
	createResidentSuccess: "Create Resident Success",
	editResidentSuccess: "Edit Resident Success",
	deleteResident: "Delete Resident",
	askForDeleteResident: "Are you sure to delete Resident :code ? ",
	deleteResidentSuccess: "Delete Resident :code success", 
	groupingInvoice: "Group Invoice",

	prefix: "Prefix",
	countryCode: "Country code",
	formatDate: "(DD/MM/YYYY (A.D.))",
	addressDistrict: "Area/District",
	labelDistrictRemark: "This information will change according to the subdistrict and district.",
};