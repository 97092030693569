import { useStore } from "vuex";
import { computed } from "vue";

export default function useGetRoleByMenu() {
	const store = useStore();

	const roleByMenus = computed(() => {
		return store.getters["shared/roleByMenus"];
	});

	const isSharedRoleByMenuReady = computed(() => {
		return store.getters["shared/roleByMenuIsLastPage"];
	});

	const getAllRoleByMenus = async function () {
		if (!isSharedRoleByMenuReady.value) {
			let storageRoleMenuName =
				"erp_role_menu_" +
				store.getters.selectedProject.code.trim() +
				"_" +
				store.getters.userCode.trim();
			let storageRoleMenu = localStorage.getItem(storageRoleMenuName);
			if (storageRoleMenu) {
				let roleMenu = [];
				if (storageRoleMenu) {
					roleMenu = JSON.parse(storageRoleMenu);
				}
				await store.dispatch("shared/trySetRoleByMenus", roleMenu);
			} else {
				await store.dispatch("shared/getAllRoleByMenus");
				localStorage.setItem(storageRoleMenuName, JSON.stringify(roleByMenus.value));
			}
		}
	};

	const tryGetAllRoleByMenus = function () {
		let storageRoleMenuName =
			"erp_role_menu_" +
			store.getters.selectedProject.code.trim() +
			"_" +
			store.getters.userCode.trim();
		localStorage.removeItem(storageRoleMenuName);
		getAllRoleByMenus();
	};

	const getRoleByMenuByCode = function (menuCode) {
		return roleByMenus.value.find(
			(roleByMenu) => roleByMenu.menuCode.trim().toLowerCase() === menuCode.trim().toLowerCase()
		);
	};

	return {
		getRoleByMenuByCode,
		isSharedRoleByMenuReady,
		getAllRoleByMenus,
		tryGetAllRoleByMenus,
	};
}