import { useStore } from "vuex";
import { ref, computed } from "vue";
export default function useGetDbdBusinessType() {
  const store = useStore();
  let searchTextDbdBusinessType = ref(null);

  const dbdBusinessType = computed(() => {
    return store.getters["shared/dbdBusinessType"];
  });

  const dbdBusinessTypeIsLastPage = computed(() => {
    return store.getters["shared/dbdBusinessTypeIsLastPage"];
  });

  const filteredDbdBusinessType = computed(() => {
    if (!searchTextDbdBusinessType.value) {
      //ช่องว่าง
      if (dbdBusinessType.value) {
        const result = [...dbdBusinessType.value];
        return result;
      } else {
        return null;
      }
    } else {
      return dbdBusinessType.value.filter((data) => {
        return data.codeAndNameDbd
          .toLowerCase()
          .includes(searchTextDbdBusinessType.value.toLowerCase());
      });
    }
  });

  const searchDbdBusinessType = function (event) {
    if (!event.query.trim().length) {
      searchTextDbdBusinessType.value = "";
      searchTextDbdBusinessType.value = null;
    } else {
      searchTextDbdBusinessType.value = event.query;
    }
  };

  let isSharedDbdBusinessTypeReady = ref(false);

  const getAllDbdBusinessType = async function () {
    if (!dbdBusinessTypeIsLastPage.value) {
      await store.dispatch("shared/getAllDbdBusinessType");
    }
    isSharedDbdBusinessTypeReady.value = true;
  };

  return {
    searchTextDbdBusinessType,
    filteredDbdBusinessType,
    searchDbdBusinessType,
    isSharedDbdBusinessTypeReady,
    getAllDbdBusinessType,
    dbdBusinessType,
  };
}
