import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default function useGetBuilding() {
  const store = useStore();
  let searchText = ref(null);

  const jobAdmins = computed(() => {
    return store.getters["shared/jobAdminJobAdmins"];
  });

  const jobAdminIsLastPage = computed(() => {
    return store.getters["shared/jobAdminIsLastPage"];
  });

  const filteredJobAdmins = computed(() => {
    if (!searchText.value) {
      //ช่องว่าง
      const result = [...jobAdmins.value];
      return result;
    } else {
      return jobAdmins.value.filter((jobAdmin) => {
        return jobAdmin.codeAndName
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const searchJobAdmin = function (event) {
    // console.log(event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchText.value = "";
      searchText.value = null;
    } else {
      searchText.value = event.query;
    }
  };

  let isSharedJobAdminReady = ref(false);

  const getAllJobAdmins = async function () {
    if (!jobAdminIsLastPage.value) {
      await store.dispatch("shared/getAllJobAdmins");
    }
    isSharedJobAdminReady.value = true;
  };

  const getJobAdminByCode = function (code) {
    return jobAdmins.value.find((jobAdmin) => {
      return jobAdmin.code.toLowerCase() === code.trim().toLowerCase();
    });
  };

  onMounted(async () => {
    await getAllJobAdmins();
  });

  return {
    searchJobAdmin,
    filteredJobAdmins,
    searchText,
    getJobAdminByCode,
    isSharedJobAdminReady
  }
}
