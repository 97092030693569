export default({
    validateBlank: "':field' ห้ามเป็นค่าว่าง",
    validateDuplicateInFile: "':field' ห้ามซ้ำใน file", 
    validateDuplicateInDatabase: "':field' ห้ามซ้ำในข้อมูลหลัก ':table'", 
    validateLength : "':field' ระบุความยาวไม่เกิน :length ตัวอักษร", 
    validateDataNotFound: "':field' ไม่พบข้อมูล",
    validateDateFormat: "':field' รูปแบบวันที่นำเข้าไม่ถูกต้อง 'DD/MM/YYYY (ค.ศ.)'",
    validateDecimalFormat: "':field' รูปแบบตัวเลขทศนิยมนำเข้าไม่ถูกต้อง '###.##'",
    validateType: "':field' รูปแบบข้อมูลไม่ถูกต้อง",
    validateAddfile: "กรุณาเพิ่มไฟล์",
    validateIncompleteData: "':field' ระบุข้อมูลไม่ครบ",
    validateAddress: "ข้อมูลที่อยู่ไม่สอดคล้องกัน",
    validateImportMaxColumn: "ไม่สามารถนำเข้าข้อมูลได้ เนื่องจำนวนไม่เท่ากับ :maxColumn แถว",
    validateInactive: "':field' ถูกปิดการใช้งาน",
})