<template>
  <svg
    :width="width"
	:height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.319 10.3418L11.171 14.4898L8.68201 12.0008"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.1 5.92148C16.641 5.72748 14.403 4.74848 12.637 3.23348C12.275 2.92348 11.724 2.92348 11.363 3.23348C9.597 4.74748 7.359 5.72748 4.9 5.92148C4.388 5.96148 4 6.39948 4 6.91248V11.2425C4 15.6095 7.156 19.7045 11.478 20.9275C11.817 21.0235 12.184 21.0235 12.523 20.9275C16.844 19.7035 20 15.6105 20 11.2435V6.91348C20 6.39948 19.612 5.96148 19.1 5.92148Z"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
