<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>
    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="col-12 pl-0 pr-2">
          <label for="getGlBook">{{ lang.glBookCode }}</label>
          <!-- <PvSkeleton
            v-if="!isSharedAccountBookReady"
            class="inputfield w-full mt-1"
            borderRadius="5px"
            height="38px"
          /> -->
          <base-account-book-auto-complete
            id="accountBook"
            v-model="selectedAccountBook"
            @load-finish="accountBookDataReady"
            class="inputfield w-full mt-1"
            :isLoadingData="true"
            :placeholder="lang.validateAccountBookIsNull"
            :class="{ 'p-invalid': selectedAccountBookError }"
            @item-select="selectAccountBook"
            :key="'BaseReportAccountingEntry'"
          />
          <small v-if="selectedAccountBookError" id="unit-help" class="p-error">{{
            lang.validateGlBookSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="codeLists">{{ lang.codeFrom }}</label>
          <!-- <PvSkeleton
            v-if="isCodeListsLoading"
            class="inputfield w-full mt-1"
            borderRadius="5px"
            height="38px"
          /> -->
          <PvAutoComplete
            v-model="selectedCodeStart"
            :suggestions="filteredCodeStart"
            @dropdown-click="filteredCodeStart"
            @complete="searchCodeStart"
            field="code"
            :dropdown="true"
            forceSelection
            class="w-full mt-1"
          />
          <small v-if="selectedCodeStartError" id="unit-help" class="p-error">{{
            lang.validateCodeSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="codeLists">{{ lang.codeTo }}</label>
          <!-- <PvSkeleton
            v-if="isCodeListsLoading"
            class="inputfield w-full mt-1"
            borderRadius="5px"
            height="38px"
          /> -->
          <PvAutoComplete
            v-model="selectedCodeEnd"
            :suggestions="filteredCodeEnd"
            @dropdown-click="filteredCodeEnd"
            @complete="searchCodeEnd"
            field="code"
            :dropdown="true"
            forceSelection
            class="w-full mt-1"
          />
          <small v-if="selectedCodeEndError" id="unit-help" class="p-error">{{
            lang.validateCodeSelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="typeLists">{{ lang.typeReport }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="typeLists"
            optionLabel="label"
            optionValue="value"
            v-model="selectedTypeReport"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="orderLists">{{ lang.orderReport }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="orderLists"
            optionLabel="label"
            optionValue="value"
            v-model="selectedOrderReport"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText
            id="fileNameText"
            type="text"
            class="inputfield w-full mt-1"
            v-model="fileNameText"
          />
        </div>
      </section>
    </transition>
    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
        </div>
      </div>
    </template>
  </PvDialog>
</template>
<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useTracking from "../../hooks/tracking";
import useReportRequestValidate from "../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "toast-request"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      isSharedAccountBookReady: false,
      selectedAccountBookError: false,
      selectedAccountBook: null,

      isCodeListsLoading: false,
      searchTextCodeStart: null,
      selectedCodeStart: null,
      selectedCodeStartError: false,

      searchTextCodeEnd: null,
      selectedCodeEnd: null,
      selectedCodeEndError: false,

      typeLists: [
        { value: "summary", label: this.lang.typeSummary },
        { value: "detail", label: this.lang.typeDetail },
      ],
      orderLists: [
        { value: "code", label: this.lang.orderCode },
        { value: "date", label: this.lang.orderDate },
      ],
      selectedTypeReport: "summary",
      selectedOrderReport: "code",

      docType: "PDF",
      fileNameText: "account_entry",
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
    },
    async initiateData() {
      this.selectedAccountBook = null;
      this.selectedAccountBookError = false;
      this.isCodeListsLoading = false;
      this.searchTextCodeStart = null;
      this.selectedCodeStart = null;
      this.selectedCodeStartError = false;
      this.searchTextCodeEnd = null;
      this.selectedCodeEnd = null;
      this.selectedCodeEndError = false;
      this.selectedTypeReport = "summary";
      this.selectedOrderReport = "code";
      
      this.docType = "PDF";
      this.fileNameText = "account_entry";
    },
    async download(typeReport) {
      this.docType = typeReport;
      if (this.validateData()) {
        return;
      }

      this.setLoading(true);
      try {
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
    validateData() {
      let returnStatus = false;
      if ( !this.selectedAccountBook ) {
        this.selectedAccountBookError = true;
        returnStatus = true;
      }
      if (this.codeLists.length == 0) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.askForSelectBook,
          detail: "",
          life: 3000,
        });
        returnStatus = true;
      }
      return returnStatus;
    },
    async getCodeLists() {
      // this.setLoading(true);
      this.isCodeListsLoading = true;
      try {
        let where = {
          projectId: this.selectedProject.id,
          generalLedgerBookId: this.selectedAccountBook.generalLedgerBookId,
        };
        const payload = {
          page: 1,
          limit: 100,
          where,
        };

        await this.$store.dispatch(
          "glAccountingEntry/getAllAccountingEntries",
          payload
        );
        // console.log("getCodeLists", this.codeLists);

        if (this.codeLists.length == 0) {
          this.$toast.add({
            severity: "error",
            summary: this.lang.askForSelectBook,
            detail: "",
            life: 3000,
          });
        }
        this.isCodeListsLoading = false;
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.isCodeListsLoading = false;
        this.setLoading(false);
      }

      // this.selectedCodeStart = this.codeLists[0].code;
      // this.selectedCodeEnd = this.codeLists[this.codeLists.length - 1].code;

      this.setLoading(false);
    },
    accountBookDataReady() {
      this.isSharedAccountBookReady = true;
      this.setLoading(false);
    },
    async selectAccountBook() {
      this.setLoading(true);
      this.selectedAccountBookError = false;

      await this.getCodeLists();
      this.selectedCodeStart = "";
      this.selectedCodeEnd = "";
    },
    searchCodeStart(event) {
      if (!event.query.trim().length) {
        //ช่องว่าง
        // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
        this.searchTextCodeStart = "";
        this.searchTextCodeStart = null;
      } else {
        this.searchTextCodeStart = event.query.toLowerCase();
      }
    },
    searchCodeEnd(event) {
      if (!event.query.trim().length) {
        //ช่องว่าง
        // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
        this.searchTextCodeEnd = "";
        this.searchTextCodeEnd = null;
      } else {
        this.searchTextCodeEnd = event.query.toLowerCase();
      }
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
    title() {
      if (this.$store.getters.selectedLanguage === "TH")
        return this.reportObj.nameThai;
      else return this.reportObj.nameEnglish;
    },
    codeLists() {
      return this.$store.getters["glAccountingEntry/accountingEntries"]
        .slice()
        .sort(function (a, b) {
          return a.code - b.code
        });
    },
    filteredCodeStart() {
      let result = [];
      if (!this.searchTextCodeStart) {
        //ช่องว่าง
        result = [...this.codeLists];
      } else {
        result = this.codeLists.filter((data) => {
          return data.code.toLowerCase().includes(this.searchTextCodeStart);
        });
      }
      return result;
    },
    filteredCodeEnd() {
      let result = [];
      if (!this.searchTextCodeEnd) {
        //ช่องว่าง
        result = [...this.codeLists];
      } else {
        result = this.codeLists.filter((data) => {
          return data.code.toLowerCase().includes(this.searchTextCodeEnd);
        });
      }
      return result;
    },
    codeStart() {
      let codeStart = "";
      if( this.codeLists.length > 0 )
      {
        codeStart = this.selectedCodeStart ? this.selectedCodeStart.code : this.codeLists[0].code;
      }
      return codeStart;
    },
    codeEnd() {
      let len = this.codeLists.length;
      let codeEnd = "";
      if( len > 0 )
      {
        codeEnd = this.selectedCodeEnd ? this.selectedCodeEnd.code : this.codeLists[len-1].code;
      }
      return codeEnd;
    },
    payload() {
      let payload = {
        RequestId: 0,
        ReportName: "AccountingEntry",
        FileName: this.fileNameText + this.fileType,
        JasperPath: this.path + this.fileType,
        DocType: this.docType,
        UserId: this.userId,
        PrintBy: this.userName,
        ProjectId: this.selectedProject.id,
        GeneralLedgerBookId: this.selectedAccountBook ? this.selectedAccountBook.generalLedgerBookId : null,
        CodeStart: this.codeStart.trim(),
        CodeEnd: this.codeEnd.trim(),
        OrderType: this.selectedOrderReport,
      };
      return payload;
    },
    path() {
      let path = this.reportObj.path + "_"+this.selectedTypeReport;
      if( this.docType == "EXCEL" )
      {
        path = path + "_excel";
      }

      return path;
    },
    fileType() {
      let fileType = this.docType == "PDF" ? ".pdf" : ".xlsx";
      return fileType;
    },
  },
  watch: {
    reportObj() {
      this.initiateData();
    },
  },
};
</script>
