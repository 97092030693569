<template>
    <PvAutoComplete
      :dropdown="true"
      :suggestions="filteredPrefix"
      @complete="searchPrefix"
      @dropdown-click="filteredPrefix"
      forceSelection
    />
  </template>
  
  <script>
  import { ref } from "vue";
  import useCommon from "../../constant/common.js"
  export default {
    setup() {
      const prefixes = ref(useCommon.CUSTOMER_PREFIX);

      let filteredPrefix = ref([]);

      const searchPrefix = function (event) {
      if (!event.query.trim().length) {
        filteredPrefix.value = [...prefixes.value];
      } else {
        filteredPrefix.value = prefixes.value.filter((country) => {
            return country.toLowerCase().includes(event.query.toLowerCase());
        });
      }
    };

      return {
        prefixs: prefixes,
        filteredPrefix,
        searchPrefix,
      };
    },
  };
  </script>  