export default {
    reportProblem: "ติดต่อเจ้าหน้าที่",
    reportProblemDescription: "ท่านสามารถติดต่อ สอบถาม หรือแจ้งปัญหาการใช้บริการกับ ทีมงาน The LivingOS ผ่านช่องทางที่สะดวกด้านล่าง",
    scanMe: "สแกนเลย!",
    serviceTime: "วันเวลาให้บริการ",
    setviceTimeDescription: "วันจันทร์ - วันเสาร์ ตั้งแต่เวลา 9.00 - 18.00 น.",
    line: "LINE Official Account",
    lineAdd: "@thelivingos",
    contactNumber: "เบอร์ติดต่อ",
    tel: "02-481-5234",
    email: "อีเมล",
    emailOfficial: "support@thelivingos.com",
    title: "ย้อนกลับ",
}