export default {
	title: "ช่องทางการส่งเอกสาร",
	createCustomerGroup: "สร้างช่องทางการส่งเอกสาร",
	columnCode: "รหัสช่องทางการส่งเอกสาร",
	columnName: "ชื่อช่องทางการส่งเอกสาร",
	editButton:"ตั้งค่าช่องทางการส่งเอกสาร",
	columnActiveStatus: "สถานะใช้งาน",
	tagActive: "ใช้งาน",
	tagInActive: "ไม่ใช้งาน",
};
