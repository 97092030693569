<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 11.9993V9.66602C12 9.11402 12.448 8.66602 13 8.66602V8.66602C13.552 8.66602 14 9.11402 14 9.66602V12.666C14 13.7707 13.1047 14.666 12 14.666V14.666C10.8953 14.666 10 13.7707 10 12.666V10.666"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.33366 10.6673H4.66699"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.33366 7.99935H4.66699"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.33366 5.33333H4.66699"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 6V3.33333C12 2.59667 11.4033 2 10.6667 2H3.33333C2.59667 2 2 2.59667 2 3.33333V12.6667C2 13.4033 2.59667 14 3.33333 14H7.33333"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
