<template>  

  <PvDialog
    :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
    :style="{ width: '40vw' }"
    :modal="true"
    :closable="false"
  > 
  
  <base-dialog
    v-model:visible="showValidateDialog"
    :titleConfirm="titleConfirm"
    :classTitle="baseDialogClassTitle"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    @confirm="showValidateDialog = false"
    @close="showValidateDialog = false"
  ></base-dialog>

    <template #header>
      <div
        class="flex justify-content-between align-items-center flex flex-wrap"
      >
        <span class="font-lg">{{ lang.accountingPolicyCopy }}</span>
      </div>
      <PvDivider></PvDivider>
    </template>
    <section class="flex flex-wrap" :class="textFormClass">
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
        <label for="documentBook">{{ lang.documentBook }}</label>
        <PvAutoComplete
          v-model="selectedDocumentBook"
          :suggestions="filteredDocumentBook"
          @complete="searchDocumentBook"
          @dropdown-click="filteredDocumentBook"
          field="codeAndName"
          :dropdown="true"
          forceSelection
          class="w-full mt-1"
          @item-select="changeDocumentBook()"
          :placeholder="lang.validateNoSelectData"
          :class="{ 'p-invalid': isSelectedDocumentBookError }"
        />
        <small v-if="isSelectedDocumentBookError" id="supplier-help" class="p-error">{{
          lang.validateNoDocumentBook
        }}</small>
      </div>
    </section>

    <template #footer>
      <PvButton
        :label="lang.close"
        class="lg:w-2 md:w-3 w-4 p-button-outlined p-button-secondary ml-3 mr-3"
        @click="closeDialog"
      />
      <PvButton
        :label="lang.copy"
        class="lg:w-3 md:w-4 w-5 mt-1 mr-3"
        v-if="!isShowButtonCopy"
        @click="selectedDocumentBookCopy"
      />
      <PvButton
        :label="lang.copyForReplace"
        class="lg:w-5 md:w-8 w-9 mt-1 mr-3"
        v-else
        @click="selectedDocumentBookCopy"
      />
    </template>
  </PvDialog>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
export default {
  props: {
    details: {
      type: Object,
    },
    documentBookId: {
      type: String,
    },
    openFrom: {
      type: String,
    },
  },
  emits: ["close","accounting-policy-item-copy"],
  setup() {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.accountingPolicy,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.th.master.accountingPolicy,
        };
      }
    });

    return {
      lang: mergeLang,
      textFormClass,
    };
  },
  data() {
    return {
      selectedDocumentBook: null,
      filteredDocumentBook: null,
      isSelectedDocumentBookError: false,

      //dialog
      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: this.lang.notificationDialogHeader,
      validateWording: "",
      dataButtonDialog: [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ],
    };
  },  
  mounted() {
    this.initiateData();
  },
  methods: {    
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
    },
    async initiateData(){
      this.selectedDocumentBook = null;
      this.isSelectedDocumentBookError = false;
      await this.$store.dispatch("accountingPolicy/resetCopyAccountingPolicys");
    },
    async selectedDocumentBookCopy() {      
      this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
        ];

        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;

      if (!this.selectedDocumentBook) {       
        this.isSelectedDocumentBookError = true;
      }else{
        await this.getCopyAccountingPolicys(this.selectedDocumentBook.id)
        if(!this.copyAccountingPolicys || this.copyAccountingPolicys.length <= 0){ 
          this.validateWording = this.lang.dataNotFound;
          this.showValidateDialog = true;
        }
        else {          
          this.$emit("accounting-policy-item-copy",this.copyAccountingPolicys);
          this.closeDialog();
        }
      }
    },
    setData() {
      this.initiateData();
      this.filteredDocumentBook = [...this.getDocumentBooks];
    },
    async changeDocumentBook() {
      this.isSelectedDocumentBookError = false;
    },
    searchDocumentBook(event) {
      if (this.getDocumentBooks) {
        if (!event.query.trim().length) {
          this.filteredDocumentBook = [...this.getDocumentBooks];
        } else {
          this.filteredDocumentBook = this.getDocumentBooks.filter((e) => {
            return e.codeAndName
              .toLowerCase()
              .includes(event.query.toLowerCase());
          });
        }
      }
    },    
    async getCopyAccountingPolicys(documentBookId) {
      this.setLoading(true);
      try {
        await this.$store.dispatch(
          "accountingPolicy/getCopyAccountingPolicys",
          documentBookId
        );
      } catch (error) {
        this.openAlertDialogError(error);
      }
      this.setLoading(false);
    },
  },
  computed: {
    isShowButtonCopy() {
      if (this.details && this.details.length > 0) {
        return true;
      }
      return false;
    },
    documentBooks() {
      return this.$store.getters["accountingPolicy/documentBooks"];
    },    
    copyAccountingPolicys() {
      return this.$store.getters["accountingPolicy/copyAccountingPolicys"];
    },
    getDocumentBooks() {
      let documents = [];
      if (this.documentBooks) {
        let number = 1;
        this.documentBooks.forEach((item) => {
          const result = {
            ...item,
            codeAndName:
              item.documentTypeCode +
              " " +
              item.documentTypeName +
              " / " +
              item.code,
          };

          if (result.id !== this.documentBookId) {
            if (number == 1) {
              documents.push(result);
            } else {
              const document = documents.find((d) => d.id === item.id);
              if (!document) {
                documents.push(result);
              }
            }
          }
          number++;
        });
      }
      return documents;
    },
  },
  watch: {
    openFrom() {
      this.setData();
    },
  },
};
</script>

<style></style>
