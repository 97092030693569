<template>
  <PvDialog
    :breakpoints="{ '1280px': '80vw', '640px': '80vw' }"
    :style="{ width: '80vw' }"
    :modal="true"
    :closable="false"
  >
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="lang.notificationDialogHeader"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
    ></base-dialog>

    <template #header>
      <div class="flex flex-wrap justify-content-between align-items-right p-0">
        <div class="p-0">
          <label class="font-lg">{{ lang.selectOtherReceipt }}</label>
        </div>
        <div>
          <PvButton
            icon="pi pi-times"
            class="p-button-link p-icon-secondary pb-0 pt-0"
            style="width: 15px"
            @click="closeDialog()"
          />
        </div>
      </div>
      <PvDivider></PvDivider>

      <div class="col-12 p-0 lg:pr-4 md:pr-4">
        <label for="statusList">{{ lang.statusReceipt }}</label>
        <div class="flex">
          <div
            v-for="status of statusList"
            :key="status.code"
            class="field-radiobutton pt-3 mr-5"
          >
            <PvRadioButton
              class="mr-2"
              :inputId="status.code"
              name="status"
              :value="status.code"
              v-model="selectedStatus"
              @change="changeStatus()"
            />
            <label :for="status.code">{{ status.name }}</label>
          </div>
        </div>
      </div>
      <div
        class="flex flex-wrap justify-content-between align-items-center p-0"
      >
        <div class="flex lg:col-6 md:col-6 col-6 p-0" v-if="!isStatusOther">
          <span class="p-input-icon-left w-full pr-2">
            <i class="pi pi-search" />
            <PvInputText
              class="inputfield w-full"
              v-model="searchText"
              :placeholder="lang.searchTextPlaceholder"
              @keyup.enter="getReceipts"
              autofocus
            />
          </span>

          <PvButton @click="getReceipts" style="width: 6rem;">
            <span class="font-normal" style="width: 6rem;">{{ lang.search }}</span>
          </PvButton>
        </div>

        <!-- <PvButton class="ml-2" @click="selectReceipt">
          <icon-documents-file-checkmark iconColor="white" />
          <span class="font-normal w-full ml-1">{{
            lang.selectedConfirm
          }}</span>
        </PvButton> -->
      </div>
    </template>

    <div class="p-datatable-border my-2" v-if="!isStatusOther">
      <PvDataTable
        :value="receipts"
        v-model:selection="selectedReceipt"
        dataKey="id"
        :class="textTableClass"
        :loading="isLoading"
        :scrollable="true"
        :scrollHeight="halfViewportHeight + 'px'"
        selectionMode="single"
        @row-dblclick="selectReceipt"
      >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
        <PvColumn
          selectionMode="single"
          style="min-width: 4rem; flex: 0; height: 56px"
        ></PvColumn>

        <PvColumn
          field="code"
          :header="lang.code"
          style="min-width: 9rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
        />

        <PvColumn
          field="date"
          :header="lang.date"
          style="min-width: 10rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left font-normal"
          sortable
        >
          <template #body="slotProps">
            {{ formatDate(slotProps.data.date) }}
          </template>
        </PvColumn>

        <PvColumn
          field="cancelStatus"
          :header="lang.cancel"
          style="min-width: 10rem; height: 56px"
          headerClass="h-left font-normal"
          bodyClass="text-left"
          sortable
        >
          <template #body="{ data, field }">
            <base-tag
              v-if="data[field].trim() != ''"
              :Caption="lang.cancel"
              Class="error"
            />
          </template>
        </PvColumn>

        <template #footer>
          <PvPaginator
            :rows="rowsPerPage"
            :totalRecords="totalRecords"
            @page="onPageChange($event)"
            :rowsPerPageOptions="[10, 50, 100]"
          >
            <template #start="slotProps">
              {{ lang.page }}:
              {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
            </template>
          </PvPaginator>
        </template>
      </PvDataTable>
    </div>

    <div class="lg:col-12 md:col-12 col-12 p-0 w-full" v-else>
      <label for="referCode">{{ lang.referCode }}</label>
      <PvInputText
        id="referCode"
        type="text"
        class="inputfield w-full mt-1"
        v-model="referCode"
        :class="{ 'p-invalid': referCodeError }"
        @keypress="referCodeError = false"
        :placeholder="lang.validateReferCode"
      />
      <small v-if="referCodeError" id="referCode-help" class="p-error">
        {{ lang.please + lang.validateReferCode }}
      </small>
    </div>

    <template #footer>
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog"
      />
      <PvButton
        :label="lang.ok"
        icon="pi pi-check"
        autofocus
        @click="selectReceipt"
      />
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  props: ["isOpen","code"],
  emits: ["close", "receipt-selected"],
  setup() {
    const { lang } = useLang();
    const { textTableClass, } = useTextStyle();
    const { formatDate } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.receipt.listing,
          ...lang.th.ar.receipt.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.receipt.listing,
          ...lang.en.ar.receipt.edit,
        };
      }
    });

    return {
      lang: mergeLang,

      textTableClass,

      formatDate,
    };
  },
  data() {
    return {
      isLoading: false,
      statusList: [
        { name: this.lang.onlyCanceled, code: "canceled" },
        { name: this.lang.allReceipt, code: "all" },
        { name: this.lang.other, code: "other" },
      ],
      selectedStatus: "canceled",
      selectedPage: 1,
      rowsPerPage: 10,

      searchText: "",
      selectedReceipt: null,
      isFilter: false,
      referCode: "",
      referCodeError: false,

      showValidateDialog: false,
      validateWording: "",
      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "w-full p-button-outlined p-button-secondary",
          action: "confirm",
        },
      ],
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    initiateData() {
      this.isLoading = false;
      this.selectedStatus = "canceled";
      this.selectedPage = 1;
      this.rowsPerPage = 10;
      this.searchText = "";
      this.selectedReceipt = null;
      this.referCode = "";
      this.referCodeError = false;
    },
    async getReceipts() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("arReceipt/getReceipts", this.payload);
      } catch (error) {
        // this.$toast.add({
        //   severity: "error",
        //   summary: this.lang.notificationDialogHeader,
        //   detail: error,
        //   life: 5000,
        // });
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;  
      }
      this.isLoading = false;
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getReceipts();
    },
    async changeStatus() {
      if (this.selectedStatus != "other") {
        await this.getReceipts();
      } else {
        this.referCode = "";
        this.selectedReceipt = null;
      }
    },
    selectReceipt() {
      let refer = "";
      let remark ="";
      if (this.selectedStatus != "other") {
        if (!this.selectedReceipt) {
          this.validateWording = this.lang.validateNoDetailSelect;
          this.showValidateDialog = true;
          return;
        }

        refer = this.selectedReceipt.code.trim();
        remark = this.lang.remarkDeductReceipt + refer;
      } else {
        if (!this.referCode) {
          this.validateWording = this.lang.please + this.lang.validateReferCode;
          this.showValidateDialog = true;
          this.referCodeError = true;
          return;
        }

        refer = this.referCode.trim();
        remark = this.lang.remarkDeductOther;
      }
      //   console.log(refer);
      let data = {
        code : refer,
        remark : remark,
      }
      this.$emit("receipt-selected", data);
      this.closeDialog();
    },
    setData() {
      if(this.code) {
        this.referCode = this.code;
        this.selectedStatus = "other";
      }
    },
  },
  computed: {
    payload() {
      let where = {
        projectId: this.selectedProject.id,
      };

      if (this.selectedStatus == "canceled") {
        where = {
          ...where,
          cancelStatus: "C",
        };
      }

      if (this.searchText) {
        where = {
          ...where,
          code: this.searchText,
        };
      }

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };
      return payload;
    },
    isStatusOther() {
      let result = false;
      if (this.selectedStatus == "other") {
        result = true;
      }
      return result;
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight - 100;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    receipts() {
      return this.$store.getters["arReceipt/receipts"];
    },
    totalPage() {
      return this.$store.getters["arReceipt/totalPage"];
    },
    currentPage() {
      return this.$store.getters["arReceipt/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
  },
  watch: {
    isOpen(curValue) {
      if (curValue) {
        this.setData();
        this.getReceipts();
      }
    },
  },
};
</script>
