<template>
	<base-form-print-modal
		v-model:visible="showFormPrintModal"
		:search="searchText"
		@close="showFormPrintModal = false"
		@path-selected="formPrintModalSelected"></base-form-print-modal>
	<div class="flex justify-content-between align-items-center" v-if="isOwnPage">
		<div class="my-3">
			<PvButton
				icon="pi pi-chevron-left"
				:label="lang.back"
				@click="goBack"
				class="mr-2 p-button-secondary" />
		</div>
		<h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
	</div>
	<transition name="fade" mode="out-in">
		<div class="surface-card shadow-2 p-3 border-round">
			<div class="pt-2 pb-1">
				<PvButton
					icon="pi pi-file"
					:label="lang.create"
					class="p-button-secondary mr-2 mb-2"
					@click="CreateNewBank()" />
				<PvButton
					icon="pi pi-save"
					:label="lang.save"
					class="mr-2 mb-2"
					@click="SavePayIn"
					:disabled="isCanceled" />
				<PvButton
					icon="pi pi-file-pdf"
					:label="lang.printPdf"
					class="mr-2 mb-2"
					@click="selectPrintForm"
					:disabled="isCreateDocument && isCreateVoucher" />
				<PvButton
					icon="pi pi-times"
					:label="lang.cancel"
					class="p-button-outlined p-button-danger mr-2 mb-2"
					@click="askForCancel"
					:disabled="(isCreateDocument && isCreateVoucher) || isCanceled" />
			</div>
			<section class="flex flex-wrap" :class="textFormClass">
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="code">{{ lang.DocumentCode }}</label>
					<PvInputText
						id="code"
						type="text"
						class="inputfield w-full mt-1"
						v-model="code" />
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="payDate">{{ lang.PayDate }}</label>
					<PvCalendar
						id="payDate"
						v-model="payDate"
						class="inputfield w-full mt-1"
						showIcon
						dateFormat="dd/mm/yy" />
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="dueDate">{{ lang.DueDate }}</label>
					<PvCalendar
						id="dueDate"
						v-model="dueDate"
						class="inputfield w-full mt-1"
						showIcon
						dateFormat="dd/mm/yy" />
				</div>

				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="selectedCustomer" v-if="showByPayType">{{
						lang.CustomerName
					}}</label>
					<label for="selectedCustomer" v-if="!showByPayType">{{
						lang.SupplierName
					}}</label>
					<base-customer-auto-complete
						id="selectedCustomer"
						v-model="selectedCustomer"
						class="inputfield w-full mt-1"
						@load-finish="customerDataReady"
						:class="{ 'p-invalid': customerError }"
						v-if="showByPayType"
						@item-select="changeSelectedCustomer()" />
					<base-supplier-auto-complete
						id="selectedCustomer"
						v-model="selectedCustomer"
						class="inputfield w-full mt-1"
						@load-finish="customerDataReady"
						:class="{ 'p-invalid': customerError }"
						v-if="!showByPayType"
						@item-select="changeSelectedCustomer()" />
					<small v-if="customerError" id="customer-help" class="p-error">{{
						lang.validateNoCustomerSelect
					}}</small>
				</div>

				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="selectedPayType">{{ lang.PayType }}</label>
					<base-payment-method-auto-complete
						id="selectedPayType"
						v-model="selectedPayType"
						class="inputfield w-full mt-1"
						@load-finish="paymentTypeDataReady"
						:typeInOut="payType" 
						:class="{ 'p-invalid': payTypeError }"
						@item-select="payTypeError = false"
					/>
					<small v-if="payTypeError" id="payType-help" class="p-error">{{
						lang.validatePayType
					}}</small>
				</div>

				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="employeeName">{{ lang.employeeName }}</label>
					<PvInputText
						id="employeeName"
						type="text"
						class="inputfield w-full mt-1"
						readonly
						v-model.trim="employeeName" />
				</div>

				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="selectedBookbank" v-if="showByPayType">{{ lang.BankPayin }}</label>
					<label for="selectedBookbank" v-if="!showByPayType">{{
						lang.BankPayout
					}}</label>
					<base-book-bank-auto-complete
						id="selectedBookbank"
						v-model="selectedBookbank"
						class="inputfield w-full mt-1"
						@load-finish="bookBankDataReady"
						:class="{ 'p-invalid': bookbankError }"
						@item-select="changeSelectedBookbank()"
					/>
					<small v-if="bookbankError" id="bookbank-help" class="p-error">{{
						lang.validateBookbank
					}}</small>
				</div>

				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2" v-if="showByPayType">
					<label for="selectedStatus">{{ lang.Status }}</label>
					<PvDropdown
						id="selectedStatus"
						class="inputfield w-full mt-1"
						:options="statusOptionsPayin"
						optionLabel="label"
						v-model="selectedStatus" />
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2" v-if="!showByPayType">
					<label for="selectedStatus">{{ lang.Status }}</label>
					<PvDropdown
						id="selectedStatus"
						class="inputfield w-full mt-1"
						:options="statusOptionsPayout"
						optionLabel="label"
						v-model="selectedStatus" />
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="toBankDate" v-if="showByPayType">{{ lang.ToBankDatePayin }}</label>
					<label for="toBankDate" v-if="!showByPayType">{{
						lang.ToBankDatePayout
					}}</label>
					<PvCalendar
						id="toBankDate"
						v-model="toBankDate"
						class="inputfield w-full mt-1"
						showIcon
						dateFormat="dd/mm/yy"
						:disabled="selectedStatus.value === 'N'" />
				</div>

				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2" v-if="!showByPayType">
					<label for="ReceiveMan">{{ lang.ReceiveMan }}</label>
					<PvInputText
						id="ReceiveMan"
						type="text"
						class="inputfield w-full mt-1"
						v-model="receiveMan" />
				</div>

				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="Remark">{{ lang.Remark }}</label>
					<PvInputText
						id="Remark"
						type="text"
						class="inputfield w-full mt-1"
						v-model="Remark" />
				</div>

				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="netAmount">{{ lang.NetAmount }}</label>
					<PvInputNumber
						id="netAmount"
						class="inputfield w-full mt-1"
						v-model="totalAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2" />
				</div>

				<!-- PT Zone -->
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2" v-if="this.rvhid">
					<label for="netAmount">{{ lang.differenceAmount }}</label>
					<PvInputNumber
						id="netAmount"
						class="inputfield w-full mt-1"
						inputClass="input-disable"
						v-model="differenceAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						disabled />
				</div>
				<!-- PT Zone -->

				<!-- ******************** -->
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2" v-if="checkPayType">
					<label for="selectedCross">{{ lang.cross }}</label>
					<PvDropdown
						id="selectedCross"
						class="inputfield w-full mt-1"
						:options="crossOptions"
						optionLabel="label"
						optionValue="value"
						v-model="selectedCross" />
				</div>

				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2" v-if="checkPayType">
					<label for="selectedHolder">{{ lang.holder }}</label>
					<PvDropdown
						id="selectedHolder"
						class="inputfield w-full mt-1"
						:options="holderOptions"
						optionLabel="label"
						optionValue="value"
						v-model="selectedHolder" />
					<!-- <label for="selectedHolder">{{ lang.cross }}</label>
					<div class="field-checkbox m-0 mr-2 mt-4">
						<PvCheckbox
						id="selectedHolder"
						v-model="selectedHolder"
						:binary="true"
						/>
						<label for="selectedHolder">{{ lang.crossOrBearer }}</label>
					</div> -->
				</div>

				<!-- ******************** -->
			</section>

			<section class="flex flex-wrap mt-5" :class="textFormClass" v-if="showGetReceipt">
				<div class="pl-0 pr-2">
					<PvButton
						icon="pi pi-search"
						:label="lang.SearchReceived"
						class="mr-2 mb-2"
						@click="getReceipts"
						:disabled="isDocumentLocked" />
				</div>
				<div class="pl-0 pr-2">
					<PvButton
						icon="pi pi-plus"
						:label="lang.Extra"
						class="mr-2 mb-2 p-button-secondary"
						@click="getExtra"
						:disabled="isDocumentLocked && isExtraButton" />
				</div>
				<div class="col-12"></div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="startDate">{{ lang.StartDate }}</label>
					<PvCalendar
						id="startDate"
						v-model="startDate"
						class="inputfield w-full mt-1"
						showIcon
						dateFormat="dd/mm/yy" />
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="endDate">{{ lang.EndDate }}</label>
					<PvCalendar
						id="endDate"
						v-model="endDate"
						class="inputfield w-full mt-1"
						showIcon
						dateFormat="dd/mm/yy" />
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="selectedCashType">{{ lang.CashType }}</label>
					<PvDropdown
						id="selectedCashType"
						class="inputfield w-full mt-1"
						:options="cashTypeOptions"
						optionLabel="label"
						v-model="selectedCashType" />
				</div>
			</section>

			<PvDataTable
				:value="receiptList"
				editMode="row"
				dataKey="referenceId"
				responsiveLayout="scroll"
				:scrollHeight="viewportHeight + 'px'"
				class="p-datatable-sm"
				:class="textTableClass"
				:loading="isLoading"
				v-show="showReceipt">
				
				<template #empty>
					<div class="w-full p-3" Style="text-align: center;">
					<label>{{ lang.tableEmpty }}</label>
					</div>
				</template>
				<PvColumn
					field="itemNo"
					:header="lang.itemNo"
					style="min-width: 2rem"
					headerClass="h-center"
					bodyClass="text-center">
				</PvColumn>
				<PvColumn
					field="code"
					:header="lang.columnCodeReceipt"
					style="min-width: 3rem"
					headerClass="h-center"
					bodyClass="text-center"></PvColumn>

				<PvColumn
					field="date"
					:header="lang.columnReceiptDate"
					style="min-width: 4rem"
					headerClass="h-center"
					bodyClass="text-center"
					:sortable="true">
					<template #body="slotProps">
						{{ formatDate(slotProps.data.date) }}
					</template>
				</PvColumn>

				<PvColumn
					field="payDate"
					:header="lang.columnDate"
					style="min-width: 4rem"
					headerClass="h-center"
					bodyClass="text-center"
					:sortable="true">
					<template #body="slotProps">
						{{ formatDate(slotProps.data.payDate) }}
					</template>
				</PvColumn>

				<PvColumn
					field="unitCode"
					:header="lang.columnUnitCode"
					style="min-width: 4rem"
					headerClass="h-center"
					bodyClass="text-center"></PvColumn>

				<PvColumn
					field="refCode"
					:header="lang.columnInvoice"
					style="min-width: 4rem"
					headerClass="h-center"></PvColumn>

				<PvColumn
					field="customerName"
					:header="lang.columnCustomerName"
					style="min-width: 4rem"
					headerClass="h-center"></PvColumn>

				<PvColumn
					field="totalAmount"
					:header="lang.columnTotal"
					style="min-width: 3rem"
					headerClass="h-center"
					bodyClass="text-right">
					<template #editor="{ data, field }">
						<PvInputText
							type="number"
							v-model.number="data[field]"
							style="width: 6rem" />
					</template>

					<template #body="slotProps">
						{{ formatCurrency(slotProps.data.totalAmount) }}
					</template>
				</PvColumn>

				<PvColumn
					style="min-width: 1rem"
					headerClass="h-center"
					bodyStyle="text-align: center; overflow: visible">
					<template #body="slotProps">
						<PvButton
							type="button"
							class="p-button-secondary p-button-text py-0"
							icon="pi pi-trash"
							@click="DeleteReceiptDetail(slotProps.data.id)"></PvButton>
					</template>
				</PvColumn>

				<template #footer>
					<PvPaginator
						:rows="rowsPerPage"
						:totalRecords="totalRecords"
						@page="onPageChange($event)"
						:rowsPerPageOptions="[10, 50, 100]">
						<template #start="slotProps">
							{{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /
							{{ totalPage }}
						</template>
					</PvPaginator>
				</template>
			</PvDataTable>

			<PvDataTable
				:value="voucherList"
				editMode="row"
				dataKey="voucherId"
				responsiveLayout="scroll"
				:scrollHeight="viewportHeight + 'px'"
				class="p-datatable-sm"
				:class="textTableClass"
				:loading="isLoading"
				v-show="showVoucher">
				
				<template #empty>
					<div class="w-full p-3" Style="text-align: center;">
					<label>{{ lang.tableEmpty }}</label>
					</div>
				</template>
				<PvColumn
					field="itemNo"
					:header="lang.itemNo"
					style="min-width: 2rem"
					headerClass="h-center"
					bodyClass="text-center">
				</PvColumn>

				<PvColumn
					field="generalLedgerBookCode"
					:header="lang.columnGLBookCode"
					style="min-width: 3rem"
					headerClass="h-center"
					bodyClass="text-center"></PvColumn>

				<PvColumn
					field="generalLedgerBookName"
					:header="lang.columnGLBookName"
					style="min-width: 3rem"
					headerClass="h-center"
					bodyClass="text-center"></PvColumn>

				<PvColumn
					field="voucherCode"
					:header="lang.columnCodeVoucher"
					style="min-width: 3rem"
					headerClass="h-center"
					bodyClass="text-center"></PvColumn>

				<PvColumn
					field="voucherDate"
					:header="lang.columnVoucherDate"
					style="min-width: 4rem"
					headerClass="h-center"
					bodyClass="text-center"
					:sortable="true">
					<template #body="slotProps">
						{{ formatDate(slotProps.data.voucherDate) }}
					</template>
				</PvColumn>

				<PvColumn
					field="voucherDescription"
					:header="lang.columnVoucherDescription"
					style="min-width: 3rem"
					headerClass="h-center"
					bodyClass="text-center"></PvColumn>

				<PvColumn
					field="payAmount"
					:header="lang.columnPayAmount"
					style="min-width: 3rem"
					headerClass="h-center"
					bodyClass="text-right">
					<template #editor="{ data, field }">
						<PvInputText
							type="number"
							v-model.number="data[field]"
							style="width: 6rem" />
					</template>

					<template #body="slotProps">
						{{ formatCurrency(slotProps.data.payAmount) }}
					</template>
				</PvColumn>

				<PvColumn
					style="min-width: 1rem"
					headerClass="h-center"
					bodyStyle="text-align: center; overflow: visible">
					<template #body="slotProps">
						<PvButton
							type="button"
							class="p-button-secondary p-button-text py-0"
							icon="pi pi-trash"
							@click="removeVoucher(slotProps.data)"></PvButton>
					</template>
				</PvColumn>

				<template #footer>
					<PvPaginator
						:rows="rowsPerPage"
						:totalRecords="totalRecords"
						@page="onPageChange($event)"
						:rowsPerPageOptions="[10, 50, 100]">
						<template #start="slotProps">
							{{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /
							{{ totalPage }}
						</template>
					</PvPaginator>
				</template>
			</PvDataTable>
		</div>
	</transition>
	<br />
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import BaseBookBankAutoComplete from "../../components/bank/BaseBookBankAutoComplete.vue";
import BasePaymentMethodAutoComplete from "../../components/bank/BasePaymentMethodAutoComplete.vue";
import useGetFormPrint from "../../hooks/getFormPrint.js";
export default {
	props: ["id", "voucherId", "type", "rvhid", "supplierCode", "receiptDifferenceAmount"],
	emits: ["close"],
	components: {
		BaseBookBankAutoComplete,
		BasePaymentMethodAutoComplete,
	},
	setup() {
		const { lang } = useLang();
		const { textHeaderClass, textFormClass, textTableClass, textAlertClass } = useTextStyle();
		const {
			formatCurrency,
			formatDate,
			formatDateToISOString,
			// formatPrice,
		} = useFormat();

		const store = useStore();
		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return { ...lang.th.base, ...lang.th.ap.bank.edit };
			} else {
				return { ...lang.en.base, ...lang.en.ap.bank.edit };
			}
		});

		const { getFormPrints, searchText, isSharedFormPrintReady, getPreviewBlob, filteredFormPrints } =
			useGetFormPrint();

		return {
			lang: mergeLang,
			textHeaderClass,
			textFormClass,
			textTableClass,
			formatCurrency,
			formatDate,
			formatDateToISOString,
			textAlertClass,
			getFormPrints,
			searchText,
			isSharedFormPrintReady,
			getPreviewBlob,
			filteredFormPrints
		};
	},
	data() {
		return {
			selectedPage: 1,
			rowsPerPage: 10,
			isLoading: false,
			selectedCustomer: null,
			selectedCashType: { value: "Cash", label: "เงินสด" },
			selectedPayType: null,
			selectedStatus: { value: "N", label: "N : ในมือ" },
			selectedBookbank: null,
			dateAt: null,
			startDate: null,
			payDate: new Date(),
			dueDate: new Date(),
			endDate: null,
			toBankDate: new Date(),
			editingRows: [],
			code: "",
			customerName: "",
			employeeName: "",
			receiveMan: "",
			Remark: "",
			isSharedCustomerReady: false,
			isSharedBookBankReady: false,
			isSharedPayTypeReady: false,
			totalAmount: null,
			customerError: false,
			remarkError: false,
			customerCode: "",
			receiptList: null,
			voucherList: null,
			extraList: null,
			newID: null,

			cashTypeOptions: [
				{ value: "Cash", label: "เงินสด" },
				{ value: "CreditCard", label: "บัตรเครดิต" },
				{ value: "Transfer", label: "เงินโอน" },
				{ value: "Cheque", label: "เช็ค" },
				{ value: "E_Pay", label: "E_Pay" },
			],
			statusOptionsPayout: [
				{ value: "N", label: "N : ในมือ" },
				{ value: "B", label: "B : เข้าธนาคาร" },
				{ value: "P", label: "P : ผ่าน" },
				{ value: "R", label: "R : คืน" },
			],
			statusOptionsPayin: [
				{ value: "N", label: "N : ในมือ" },
				{ value: "B", label: "B : เข้าธนาคาร" },
			],

			//Receipt
			receiptCode: null,
			selectedReceipt: null,
			messages: [{ ismessage: false, severity: "success", content: "123" }],

			showFormPrintModal: false,
			path: "",
			categoryPrint: "",

			isCreateVoucher: true,
			isExtraButton: true,

			differenceAmount: 0,

			crossOptions: [
				{ value: "A", label: "ACC PAYEE" },
				{ value: "N", label: "ไม่ขีดคร่อม" },
			],
			selectedCross: "A",

			holderOptions: [
				{ value: "Y", label: "ใช่" },
				{ value: "N", label: "ไม่ใช่" },
			],
			selectedHolder: "Y",

			payTypeError: false,
			bookbankError: false,
		};
	},
	async created() {
		// console.log("created", this.payType);
		if (this.isCreateDocument) {
			this.clearBank();
		} else {
			this.isCreateVoucher = false;
			// console.log("created else >>", this.isCreateVoucher);
			const where = {
				id: this.id,
				type: this.payType,
			};
			this.newID = this.id;
			this.setLoading(true);
			await this.$store.dispatch("bank/getBank", { where });
		}
	},
	methods: {
		goBack() {
			let storageName = `erp_search_BankList_${this.selectedProject.code}_${this.userCode}`
			const localSearch = localStorage.getItem(storageName);
			let search = JSON.parse(localSearch);
			search = {
				...search,
				BankEntry: "Entry",
			};
			// console.log("search=", search);
			localStorage.setItem(storageName, JSON.stringify(search));

			// await this.$store.dispatch("bank/resetBank");
			if (this.payType == 1) {
				this.$router.push({ name: "ar-bank-ar" })
			} else {
				this.$router.push({ name: "ap-bank-ap" })
			}
		},
		customerDataReady() {
			this.isSharedCustomerReady = true;
		},
		bookBankDataReady() {
			this.isSharedBookBankReady = true;
		},
		paymentTypeDataReady() {
			this.isSharedPayTypeReady = true;
		},
		setData() {
			if (!this.isCreateDocument || !this.isCreateVoucher) {
				if (this.payType === 1) {
					this.selectedCustomer = this.sharedCustomers.find(
						(customer) => customer.id === this.Bank.customerId
					);
				} else {
					this.selectedCustomer = this.sharedSupplier.find(
						(customer) => customer.id === this.Bank.customerId
					);
				}
				if (this.payType === 1) {
					this.selectedStatus = this.statusOptionsPayin.find(
						(status) => status.value === this.Bank.status
					);
				} else {
					this.selectedStatus = this.statusOptionsPayout.find(
						(status) => status.value === this.Bank.status
					);
				}
				this.selectedBookbank = this.sharedBookBanks.find(
					(bookbank) => bookbank.id === this.Bank.bookBankId
				);
				this.selectedPayType = this.sharedPaymentType.find(
					(paymentType) => paymentType.id === this.Bank.paymentMethodId
				);

				//console.log("setData", this.Bank);
				this.code = this.Bank.code;
				this.startDate = new Date();
				this.endDate = new Date();
				this.payDate = new Date(this.Bank.payDate);
				this.dueDate = new Date(this.Bank.payDueDate);
				this.toBankDate = new Date(this.Bank.toBankDate);
				this.customerName = this.Bank.customerName;
				this.employeeName = this.Bank.createBy;
				this.Remark = this.Bank.remark;
				this.totalAmount = this.Bank.payAmount;
				this.receiptList = this.Bank.referenceReceiptDetails;
				this.voucherList = this.Bank.referenceVoucherDetails;
				this.receiveMan = this.Bank.depositor;
				// console.log("voucherList", this.voucherList);
				if (this.receiptList || this.voucherList) {
					this.updateItemNo();
				}
				this.selectedCross = this.Bank.isCross;
				this.selectedHolder = this.Bank.isHolder;
			}
			// console.log("11setData=",this.receiptList);
			this.setLoading(false);
		},
		setDataPT() {
			this.setLoading(true);
			if (this.rvhid && this.purchaseReceipt && this.isCreateDocument) {
				// console.log("setdataPT()", this.purchaseReceipt.receiptSupplierId);
				this.selectedCustomer = this.sharedSupplier.find(
					(customer) => customer.id === this.purchaseReceipt.receiptSupplierId
				);
				this.differenceAmount = this.receiptDifferenceAmount;
			}
			this.setLoading(false);
		},
		async getReceipts() {
			this.isLoading = true;
			let where = {
				projectId: this.selectedProject.id,
				payType: this.selectedCashType.value,
				dateStart: this.startDate,
				dateEnd: this.endDate,
			};
			// console.log("where", JSON.stringify(where));
			const payload = {
				page: this.selectedPage,
				limit: this.rowsPerPage,
				where,
			};
			await this.$store.dispatch("bank/getReceiptNotPayIn", payload);
			this.isLoading = false;
			this.receiptList = this.$store.getters["bank/receiptNotPayIn"];
			// console.log("receiptList", this.receiptList);
			// this.updateTotalAmount();
			this.updateItemNo();
		},
		async getExtra() {
			this.isExtraButton = false;
			this.isLoading = true;
			let where = {
				projectId: this.selectedProject.id,
				payType: "Other",
			};
			const payload = {
				page: this.selectedPage,
				limit: this.rowsPerPage,
				where,
			};
			await this.$store.dispatch("bank/getReceiptExtra", payload);
			this.isLoading = false;
			let extraList = this.$store.getters["bank/receiptExtra"];
			if (extraList.length > 0) {
				// console.log("receiptExtra", extraList)
				await extraList.forEach((element) => {
					// console.log("extraList", element)
					this.receiptList.push(element);
				});

				// this.updateTotalAmount();
				this.updateItemNo();
			} else {
				this.$swal({
					icon: "warning",
					title: this.lang.validateReceiptExtra,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				}).then((result) => {
					/* Read more about isConfirmed, isDenied below */
					if (result.isConfirmed) {
						this.customerError = true;
					}
				});
			}
		},
		async onPageChange(event) {
			this.selectedPage = event.page + 1;
			this.rowsPerPage = event.rows;
			await this.getReceipts();
		},
		setLoading(bool) {
			this.$store.dispatch("setLoading", { value: bool });
		},
		checkShouldSetData() {
			if (
				this.isSharedCustomerReady &&
				this.isSharedBookBankReady &&
				this.isSharedPayTypeReady &&
				this.Bank
			) {
				this.setData();
			}
			if (this.rvhid) {
				this.setDataPT();
			}
		},
		DeleteReceiptDetail(id) {
			// console.log("DeleteReceiptDetail:", id);
			// console.log("this.receiptList:", this.receiptList);
			const Receipt = this.receiptList.find((Receipt) => Receipt.id === id);
			// console.log("Prepaid:",Prepaid);
			let userIndex = this.receiptList.indexOf(Receipt);
			this.receiptList.splice(userIndex, 1);
			// this.updateTotalAmount();
			this.updateItemNo();
		},
		CreateNewBank() {
			this.$swal({
				icon: "info",
				html: `<p class="${this.textAlertClass}">${this.lang.askForCreateNewDocument}</p>`,
				confirmButtonText: this.lang.confirm,
				showCancelButton: true,
				cancelButtonText: this.lang.cancel,
				allowOutsideClick: false,
			}).then((result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					if (!this.isCreateDocument && !this.voucherId && !this.rvhid) {
						if (this.payType == 1) {
							this.$router.push({ name: "ar-bank-ar-edit", params: { id: "create" } })
						} else {
							this.$router.push({ name: "ap-bank-ap-edit", params: { id: "create" } })
						}
					}
					this.clearBank();
				} else if (result.isDenied) {
					//
				}
			});
		},
		async updateTotalAmount() {
			// console.log("this.receiptList=",this.receiptList);
			let total = 0;
			await this.receiptList.forEach((element) => {
				total = total + parseFloat(element.netAmount);
			});

			this.totalAmount = total;
		},
		updateItemNo() {
			let number = 1;
			if (this.receiptList) {
				this.receiptList.forEach((element) => {
					element.itemNo = number;
					number++;
				});
			} else {
				this.voucherList.forEach((element) => {
					element.itemNo = number;
					number++;
				});
			}
		},
		async clearBank() {
			// let date = new Date();
			// console.log(this.Bank);
			await this.$store.dispatch("bank/resetBank");
			this.code = "";
			this.payDate = new Date();
			this.dueDate = new Date();
			this.toBankDate = new Date();
			this.selectedCustomer = null;
			this.customerName = "";
			this.selectedPayType = "";
			this.employeeName = this.selectedUserName;
			this.receiveMan = "";
			this.Remark = "";
			this.totalAmount = null;
			this.selectedStatus = { value: "N", label: "N : ในมือ" };
			this.selectedBookbank = "";
			this.receiptList = [];
			this.voucherList = null;
			this.newID = null;
			if (this.voucherId) {
				this.isCreateVoucher = true;
				if (this.dataVoucher) {
					// console.log("clearBank", this.dataVoucher);

					this.payDate = new Date(this.dataVoucher.date);
					this.dueDate = new Date(this.dataVoucher.date);
					this.toBankDate = new Date(this.dataVoucher.date);
					this.Remark = this.dataVoucher.description;
				}
			}

			if (this.supplierCode) {
				this.selectedCustomer = this.sharedSupplier.find(
					(customer) => customer.code === this.supplierCode
				);
				// this.receiveMan = this.selectedCustomer.name; // erp-1561
				this.receiveMan = this.selectedCustomer.prefix ? this.selectedCustomer.prefix + " " + this.selectedCustomer.name : this.selectedCustomer.name; //ERP-6034
			}
			this.differenceAmount = this.receiptDifferenceAmount;
		},
		async SavePayIn() {
			if (this.validateData()) {
				return;
			}
			// console.log("SavePayIn Add", this.isCreateDocument, this.isCreateVoucher);
			this.setLoading(true);

			try {
				if ((this.isCreateDocument || this.isCreateVoucher) && this.newID === null) {
					this.isCreateVoucher = false;
					let payload = {
						projectId: this.selectedProject.id,
						PaymentMethodCode: this.selectedPayType.code,
						PaymentMethodId: this.selectedPayType.id,
						BookBankId: this.selectedBookbank.id,
						BankId: this.selectedBookbank.bankId,
						DocBankBranchName: this.selectedBookbank.branchName,
						CustomerId: this.selectedCustomer.id,
						PayAmount: this.totalAmount,
						PayDate: this.selectedPayDate,
						PayDueDate: this.selectedDueDate,
						ToBankDate: this.selectedToBankDate,
						PassDate: this.selectedToBankDate,
						ReturnDate: this.selectedToBankDate,
						Depositor: this.receiveMan,
						Remark: this.Remark,
						Status: this.selectedStatus.value,
						FreeAmount: this.totalAmount,
						// IsCross: "N",
						// IsHolder: "N",
						IsCross: this.selectedCross,
						IsHolder: this.selectedHolder,
						ChequeReturnNote: "",
						ReferenceReceiptDetails: this.receiptList,
						Code: this.code,
					};
					// Prepaid.details = this.receiptList;
					// console.log("SavePayIn Add", payload);
					if (this.rvhid) {
						payload = { ...payload, ...{ RVHId: this.rvhid } };
					}

					await this.$store.dispatch("bank/AddBankTransaction", payload);
					const newBanksAr = this.$store.getters["bank/newBanksAr"];

					if (this.rvhid === "create") {
						let bankCreateImport = [];
						bankCreateImport = [...this.banksSelected, ...[newBanksAr]];
						await this.$store.dispatch("bank/setBanksSelected", bankCreateImport);
					}

					this.newID = newBanksAr.id;
					// console.log("AddBank=", newBanksAr);
					if (this.voucherId) {
						let bankVoucher = {
							PayId: newBanksAr.id,
							VoucherId: this.voucherId,
							RefType: "VCH",
							PayAmount: newBanksAr.payAmount,
							ProjectId: this.selectedProject.id,
						};
						await this.$store.dispatch("bank/AddBankVoucher", bankVoucher);

						// console.log("bankVoucher", bankVoucher);
						// console.log("bankVoucher", this.isCreateVoucher);

						this.$swal({
							icon: "success",
							title: this.lang.saveSuccess,
							confirmButtonText: this.lang.close,
							allowOutsideClick: false,
						});
						const where = {
							id: newBanksAr.id,
						};
						this.setLoading(true);

						await this.$store.dispatch("bank/getBank", { where });
						this.setData();
					} else if (this.rvhid) {
						this.$swal({
							icon: "success",
							title: this.lang.saveSuccess,
							confirmButtonText: this.lang.close,
							allowOutsideClick: false,
						});
						const where = {
							id: newBanksAr.id,
						};
						this.setLoading(true);

						await this.$store.dispatch("bank/getBank", { where });
						this.setData();
					} else {
						if (this.payType == 1) {
							this.$router.push({ name: "ar-bank-ar-edit", params: { id: newBanksAr.id } })
						} else {
							this.$router.push({ name: "ap-bank-ap-edit", params: { id: newBanksAr.id } })
						}

						this.$swal({
							icon: "success",
							title: this.lang.saveSuccess,
							confirmButtonText: this.lang.close,
							allowOutsideClick: false,
						});
						// console.log(newBanksAr.id);
						const where = {
							id: newBanksAr.id,
						};
						this.setLoading(true);

						await this.$store.dispatch("bank/getBank", { where });
						this.setData();
					}
				} else {
					const payload = this.Bank;
					payload.toBankDate = this.selectedToBankDate;
					payload.passDate = this.selectedToBankDate;
					payload.returnDate = this.selectedToBankDate;
					payload.remark = this.Remark;
					payload.payAmount = this.totalAmount;
					payload.referenceReceiptDetails = this.receiptList;
					payload.paymentMethodId = this.selectedPayType.id;
					payload.paymentMethodCode = this.selectedPayType.code;
					payload.bookBankId = this.selectedBookbank.id;
					payload.bankId = this.selectedBookbank.bankId;
					payload.docBankBranchName = this.selectedBookbank.branchName;
					payload.customerId = this.selectedCustomer.id;
					payload.payDueDate = this.selectedDueDate;
					payload.payDate = this.selectedPayDate;
					payload.depositor = this.receiveMan;
					payload.status = this.selectedStatus.value;
					payload.freeAmount = this.totalAmount;
					payload.code = this.code;
					if (this.rvhid) {
						payload.RVHId = this.rvhid;
					}
					payload.IsCross = this.selectedCross;
					payload.IsHolder = this.selectedHolder;

					// console.log("SavePayIn Edit", payload);
					this.updateDataImport();
					await this.$store.dispatch("bank/EditBankTransaction", payload);

					this.$swal({
						icon: "success",
						title: this.lang.saveSuccess,
						confirmButtonText: this.lang.close,
						allowOutsideClick: false,
					});
					const where = {
						id: payload.id,
					};
					this.newID = payload.id;
					await this.$store.dispatch("bank/getBank", { where });
					this.setData();
				}
			} catch (error) {
				this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
			}
			this.setLoading(false);
		},
		updateDataImport() {
			if (this.Bank.id !== "create") {
				let data = this.banksSelected;
				let i = data.map((item) => item.id).indexOf(this.Bank.id);
				data[i] = this.Bank;
			}
		},
		async askForCancel() {
			const result = await this.$swal({
				icon: "info",
				html: `<p class="${this.textAlertClass}">${this.lang.askForCancelDocument}</p>`,
				confirmButtonText: this.lang.confirm,
				showCancelButton: true,
				cancelButtonText: this.lang.close,
				allowOutsideClick: false,
				confirmButtonColor: "#EF4444",
				cancelButtonColor: "#64748B",
			});

			if (result.isConfirmed) {
				await this.CancelPayIn();
			} else if (result.isDenied) {
				//
			}
		},
		async CancelPayIn() {
			this.setLoading(true);
			const newBanksAr = this.$store.getters["bank/newBanksAr"];
			this.newID = this.newID ? this.newID : newBanksAr.id;

			const payload = {
				id: this.newID,
			};

			try {
				await this.$store.dispatch("bank/CancelPayIn", payload);
				const where = {
					id: this.newID,
				};
				this.setLoading(true);

				await this.$store.dispatch("bank/getBank", { where });
				this.setData();
				this.$swal({
					icon: "success",
					title: this.lang.cancelSuccess,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
			} catch (error) {
				this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				this.setLoading(false);
			}
			this.setLoading(false);
		},
		validateData() {
			if (!this.selectedCustomer) {
				this.$swal({
					icon: "warning",
					title: this.lang.validateNoCustomerSelect,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				}).then((result) => {
					if (result.isConfirmed) {
						this.customerError = true;
					}
				});
				return true;
			} else if (!this.selectedBookbank) {
				this.$swal({
					icon: "warning",
					title: this.lang.validateBookbank,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				}).then((result) => {
					if (result.isConfirmed) {
						this.bookbankError = true;
					}
				});
				return true;
			} else if (!this.selectedPayType) {
				this.$swal({
					icon: "warning",
					title: this.lang.validatePayType,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				}).then((result) => {
					if (result.isConfirmed) {
						this.payTypeError = true;
					}
				});
				return true;
			}
			return false;
		},
		async PrintCheque(data) {
			let listId = [];
			listId.push(data.id);
			const payload = {
				id: data.id,
				path: this.path,
				listId: listId,
			};
			try {
				// await this.$store.dispatch("bank/PrintCheque", payload);
				await this.$store.dispatch("bank/PrintLotCheque", payload);
			} catch (error) {
				this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
			}
			let blob = this.$store.getters["bank/blob"];
			this.getPreviewBlob(blob);
		},
		async selectPrintForm() {
			await this.$store.dispatch("shared/resetFormPrint");
			// console.log('selectPrintForm',this.Bank);
			this.dataPrint = this.Bank;
			let where = {
				projectId: this.selectedProject.id,
				// documentBookCode: this.payType == 1 ? "PayIn" : "PayOut",
				documentBookCode: "PayOut",
			};
			const payload = { where };
			await this.getFormPrints(payload);			
			
			if(this.filteredFormPrints.length == 1) {
				this.path = this.filteredFormPrints[0].path ;
				this.PrintCheque(this.dataPrint);
			}else{
				this.showFormPrintModal = true;
			}
		},
		formPrintModalSelected(event) {
			this.path = event.path;
			this.PrintCheque(this.dataPrint);
			this.showFormPrintModal = false;
		},
		async removeVoucher(data) {
			// console.log("removeVoucher", data);
			this.setLoading(true);
			const result = await this.$swal({
				icon: "info",
				html: `<p class="${this.textAlertClass}">${this.lang.askForDeleteDocument}</p>`,
				confirmButtonText: this.lang.confirm,
				showCancelButton: true,
				cancelButtonText: this.lang.close,
				allowOutsideClick: false,
				confirmButtonColor: "#EF4444",
				cancelButtonColor: "#64748B",
			});

			if (result.isConfirmed) {
				let payload = {
					PayId: data.payID,
					VoucherId: data.voucherId,
					RefType: "VCH",
					ProjectId: this.selectedProject.id,
				};

				if (this.rvhid) {
					payload = { ...payload, ...{ RVHId: this.rvhid } };
					payload.RefType = "RVH";
				}
				this.newID = data.payID;
				// console.log("isConfirmed", payload);
				try {
					await this.$store.dispatch("bank/deleteBankWithVoucher", payload);
					await this.$swal({
						icon: "success",
						title: this.lang.successfully,
						confirmButtonText: this.lang.close,
						allowOutsideClick: false,
					});
					this.setLoading(true);
					let where = {
						type: this.payType,
						id: this.newID,
					};

					this.setLoading(true);
					await this.$store.dispatch("bank/getBank", { where });
					this.setData();

					this.setLoading(false);
				} catch (error) {
					this.$swal({
						icon: "error",
						html: `<p class="${this.textAlertClass}">${error}</p>`,
						confirmButtonText: this.lang.close,
						allowOutsideClick: false,
					});
				}
			} else if (result.isDenied) {
				//
			}
			this.setLoading(false);
		},
		changeSelectedCustomer() {
			this.customerError = false;
			this.customerName = this.selectedCustomer.name;
			this.customerCode = this.selectedCustomer.code;
			if (this.isCreateDocument) { // erp-1561
				// this.receiveMan = this.selectedCustomer.name;
				this.receiveMan = this.selectedCustomer.prefix ? this.selectedCustomer.prefix + " " + this.selectedCustomer.name : this.selectedCustomer.name; //ERP-6034
			}
		},
		changeSelectedBookbank() {
			// console.log('changeSelectedBookbank',this.selectedBookbank);
			this.bookbankError = false;
			if (this.validateBookbank()) {
				return;
			}
		},
		validateBookbank() {
			// console.log('this.payType',this.payType);
			if (this.payType == -1 && (this.selectedBookbank.bankCode == 'DEPOSIT' || this.selectedBookbank.bankCode =='BANK')) {
				this.$swal({
					icon: "warning",
					title: this.lang.validateInvalidSetupBookbank,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				}).then((result) => {
					if (result.isConfirmed) {
						this.selectedBookbank = null;
						this.bookbankError = true;
					}
				});
				return true;
			}
			return false;
		},
	},
	computed: {
		isCreateDocument() {
			return this.id === "create" && this.newID === null;
		},
		title() {
			// console.log("title", this.lang);
			let result = this.payType == 1 ? this.lang.editBank : this.lang.editBankAp;
			if (this.isCreateDocument) {
				result = this.payType == 1 ? this.lang.createBank : this.lang.createBankAp;
			}
			return result;
		},
		Bank() {
			// console.log("Bank", this.$store.getters["bank/selectedBank"]);
			return this.$store.getters["bank/selectedBank"];
		},
		viewportHeight() {
			return this.$store.getters.viewportHeight;
		},
		selectedProject() {
			return this.$store.getters.selectedProject;
		},
		userCode() {
			return this.$store.getters.userCode;
		},
		selectedUserName() {
			return this.$store.getters.userName;
		},
		totalPage() {
			return this.$store.getters["bank/totalPage"];
		},
		currentPage() {
			return this.$store.getters["bank/currentPage"];
		},
		totalRecords() {
			return this.totalPage * this.rowsPerPage;
		},
		// comboBoxUnits() {
		//   return this.$store.getters["comboBox/customerUnits"];
		// },
		sharedCustomers() {
			return this.$store.getters["shared/customerCustomers"];
		},
		sharedSupplier() {
			return this.$store.getters["shared/supplierSuppliers"];
		},
		sharedBookBanks() {
			return this.$store.getters["bookBank/bookBanks"];
		},
		sharedPaymentType() {
			return this.$store.getters["bank/transactionType"];
		},
		dataVoucher() {
			if (this.voucherId || this.rvhid) {
				return this.$store.getters["glAccountingEntry/selectedAccountingEntry"];
			}
			return null;
		},
		isDocumentLocked() {
			// console.log("isDocumentLocked", this.Bank);
			if (this.Bank && !this.isCreateDocument) {
				if (this.Bank.cancelStatus.trim() != "") {
					return true;
				}
			}
			return false;
		},
		isCanceled() {
			if (this.Bank) {
				// console.log("this.Bank",this.Bank);
				if (this.Bank.cancelStatus.trim().toLowerCase() === "c") {
					return true;
				}
			}
			return false;
		},
		employee() {
			return this.$store.getters.employee;
		},
		selectedPayDate() {
			let payDate = null;
			if (this.payDate) {
				payDate = this.formatDateToISOString(this.payDate);
			} else {
				payDate = this.formatDateToISOString(new Date());
			}
			return payDate;
		},
		selectedDueDate() {
			let dueDate = null;
			if (this.dueDate) {
				dueDate = this.formatDateToISOString(this.dueDate);
			} else {
				dueDate = this.formatDateToISOString(new Date());
			}
			return dueDate;
		},
		selectedToBankDate() {
			let toBankDate = null;
			if (this.toBankDate) {
				toBankDate = this.formatDateToISOString(this.toBankDate);
			} else {
				toBankDate = this.formatDateToISOString(new Date());
			}
			return toBankDate;
		},
		selectedStartDate() {
			let startDate = null;
			if (this.startDate) {
				startDate = this.formatDateToISOString(this.startDate);
			} else {
				startDate = this.formatDateToISOString(new Date());
			}
			return startDate;
		},
		selectedEndDate() {
			let endDate = null;
			if (this.endDate) {
				endDate = this.formatDateToISOString(this.endDate);
			} else {
				endDate = this.formatDateToISOString(new Date());
			}
			return endDate;
		},
		showReceipt() {
			if (this.receiptList || this.extraList) {
				return true;
			}
			return false;
		},
		showGetReceipt() {
			// console.log(this.payType);
			if (this.payType == 1) {
				return true;
			}
			return false;
		},
		showVoucher() {
			if (this.voucherList) {
				return true;
			}
			return false;
		},
		payType() {
			return this.type ? this.type : this.$store.getters["bank/typeInOut"];
		},
		showByPayType() {
			let payType = this.type ? this.type : this.$store.getters["bank/typeInOut"];
			if (payType == 1) {
				return true;
			} else {
				return false;
			}
		},
		isOwnPage() {
			return !this.voucherId && !this.rvhid;
		},
		purchaseReceipt() {
			return this.$store.getters["apPurchaseReceipt/selectedPurchaseReceipt"];
		},
		banksSelected() {
			return this.$store.getters["bank/banksSelected"];
		},
		checkPayType() {
			if (this.selectedPayType) {
				if (this.selectedPayType.code == "CQW") return true;
			}
			return false;
		},
	},
	watch: {
		isSharedCustomerReady() {
			this.checkShouldSetData();
		},
		isSharedBookBankReady() {
			this.checkShouldSetData();
		},
		Bank() {
			this.checkShouldSetData();
		},
		isSharedPayTypeReady() {
			this.checkShouldSetData();
		},
		totalAmount() {
			// console.log(this.receiptDifferenceAmount);
			if (this.purchaseReceipt && this.isCreateDocument) {
				this.differenceAmount -= this.receiptDifferenceAmount;
			} else if (this.purchaseReceipt && !this.isCreateDocument) {
				this.differenceAmount = this.receiptDifferenceAmount;
			}
		},
	},
};
</script>

<style scoped>
::v-deep(.editable-cells-table td.p-cell-editing) {
	padding-top: 0;
	padding-bottom: 0;
}

.p-button-text {
	width: 2rem;
}
</style>
