export default {
    setProject(state, payload){
        state.project = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },
    setProjectType(state, payload){
        state.projectTypes = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },
};