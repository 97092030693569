export default {
    title: "Debt Free Request",
    addDebtFree: "Add Debt Free",
    debtFreeList: "Debt Free",
    dateRange: "Doucument Date",
    unitAddress: "Unit/Address no.",
    ownerName: "Owner Name",
    searchTextPlaceholder: "Fill up to search.",
    unitCode: "Unit Code",
    unitAddressNo: "Address .No",
    requestDate: "Request Date",
    requestBy: "Request By",
    requestPhone: "Request Phone",
    approve: "Approve",
    createDate: "Create Date",
    createBy: "Create By",
    disApprove: "DisApprove",
}