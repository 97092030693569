<template>
  <base-toolbar
    :title="lang.toolbarTitle"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoExport="exportToExcel"
  >
  </base-toolbar>
  <base-container>
    <base-toast-report-request
      :showToast="showToast"
      :toastMessage="toastMessage"
      :toastLink="toastLink"
      :actionToast="actionToast"
    ></base-toast-report-request>

    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="titleDialog"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
    ></base-dialog>
    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5" :class="textFormClass">
      <div class="flex justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.title }}</label>
      </div>

      <PvDivider></PvDivider>
      <!-- dashboard -->
      <div class="flex flex-wrap justify-content-between pl-3 pr-3">
        <base-select-card
          class="lg:col-3 md:col-6 col-12 my-2"
          :title="this.lang.allStatus"
          :content="this.contentAllStatus"
          :color="'primary'"
          :isClick="allStatusClick"
          @click="clickFilter('allStatus')"
        ></base-select-card>
        <base-select-card
          class="lg:col-3 md:col-6 col-12 my-2"
          :title="this.lang.registered"
          :content="this.percentRegistered"
          :subContent="this.contentRegistered"
          :color="'success'"
          :isClick="registeredClick"
          @click="clickFilter('registered')"
        ></base-select-card>
        <base-select-card
          class="lg:col-3 md:col-6 col-12 my-2"
          :title="this.lang.waitRegister"
          :content="this.percentWaitRegister"
          :subContent="this.contentWaitRegister"
          :color="'error'"
          :isClick="waitRegisterClick"
          @click="clickFilter('waitRegister')"
        ></base-select-card>
        <base-select-card
          class="lg:col-3 md:col-6 col-12 my-2"
          :title="this.lang.waitApprove"
          :content="this.percentPenndingApprove"
          :subContent="this.contentPendingApprove"
          :color="'warning'"
          :isClick="penndingApproveClick"
          @click="clickFilter('penndingApprove')"
        ></base-select-card>
      </div>
      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >
        <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            id="filterSearch"
            class="inputfield w-full mr-2"
            :options="filterSearch"
            optionLabel="name"
            optionValue="code"
            v-model="selectedFilterSearch"
            scrollHeight="300px"
            @change="changeFilterSearch"
          />
        </div>
        <div class="flex lg:col-6 md:col-12 col-12 p-0 mr-2" v-if="isSearchText && !isDisableSearchText">
          <div class="col-12 pl-0 pr-0">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchText"
                :placeholder="lang.searchTextPlaceholder"
                @keypress="checkInputSearch"
                autofocus
                :disabled="isDisableSearchText"
                :class="{ 'p-inputtext-disabled': isDisableSearchText }"
              />
            </span>
          </div>
        </div>
        <div v-if="!isSearchText && !isDisableSearchText" class="flex lg:col-6 md:col-12 col-12 p-0 mr-2">
          <div class="col-12 pl-0 pr-0">
            <PvDropdown
              id="selectedStatus"
              class="inputfield w-full"
              :options="statusOptions"
              optionLabel="name"
              optionValue="code"
              v-model="selectedStatus"
            />
          </div>
        </div>
        <PvButton @click="getMobileRegisterDetail">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>
      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="dataMobileRegisterDetail"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          scrollable
        >
          <PvColumn
            field="unitCode"
            :header="lang.columnUnitCode"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="addressNo"
            :header="lang.columnAddressNo"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="customerName"
            :header="lang.columnCustomerName"
            style="min-width: 25rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="registerMobile"
            :header="lang.columnCustomerMobile"
            style="max-width: 15rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="livingType"
            :header="lang.columnLivingType"
            style="max-width: 15rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ livingTypeText(slotProps.data.livingType) }}
            </template>
          </PvColumn>
          <PvColumn
            field="registerStatus"
            :header="lang.columnRegisterStatus"
            style="min-width: 12rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <base-tag
                v-if="slotProps.data.registerStatus.trim() != ''"
                :Caption="registerStatus(slotProps.data.registerStatus).caption"
                :Class="registerStatus(slotProps.data.registerStatus).class"
              />
            </template>
          </PvColumn>
          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              class="p-pagination-radius"
              :first="showCurrentPage"
            >
              <template #start="slotProps">
                {{ lang.page }}: {{ totalPage ? slotProps.state.page + 1 : 0 }} /
                {{ totalPage }}
              </template>
            </PvPaginator>
          </template>

          <template #empty>
            <div
              style="height: 56px"
              class="text-center flex align-items-center justify-content-center w-full"
            >
              <label class="font-normal">
                {{ lang.tableEmpty }}
              </label>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>
<script>
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import useReportRequestValidate from "../../hooks/reportRequestValidate.js";
// import * as XLSX from "xlsx";
export default {
  setup() {
    const { lang } = useLang();
    const store = useStore();

    const { textFormClass, textTableClass } = useTextStyle();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.dashboard.mobileRegister.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.dashboard.mobileRegister.listing,
        };
      }
    });

    const isHideBack = inject("isHideBack");

    const { formatNumber, formatDateTime } = useFormat();

    const { reportPrintOrder } = useReportRequestValidate();

    return {
      lang: mergeLang,
      isHideBack,
      textFormClass,
      textTableClass,
      formatNumber,
      formatDateTime,
      reportPrintOrder,
    };
  },
  data() {
    return {
      dataMobileRegisterDetail: [],
      dataMobileRegisterSummary: null,
      filterSearch: [
        { name: this.lang.allFilter, code: "all" },
        { name: this.lang.unit, code: "unitCode" },
        { name: this.lang.columnAddressNo, code: "addressNo" },
        { name: this.lang.customerName, code: "customerName" },
        { name: this.lang.customerMobile, code: "customerMobile" },
        { name: this.lang.livingType, code: "livingType" },
      ],
      selectedFilterSearch: "all",
      searchText: "",
      statusOptions: [
        { name: this.lang.owner, code: "Owner" },
        { name: this.lang.rential, code: "Rential" },
        { name: this.lang.stay, code: "Stay" },
      ],
      selectedStatus: "",
      unitCode: null,
      addressNo: null,
      customerName: null,
      customerMobile: null,
      livingType: null,
      selectedPage: 1,
      rowsPerPage: 100,
      isLoading: false,

      showValidateDialog: false,
      validateWording: "",
      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "w-full p-button-outlined p-button-secondary",
          action: "confirm",
        },
      ],
      titleDialog: "",
      percentRegistered: "",
      percentWaitRegister: "",
      percentPenndingApprove: "",

      allStatusClick: true,
      registeredClick: false,
      waitRegisterClick: false,
      penndingApproveClick: false,

      registerStatusFilter: null,
      
      showToast: 0,
      toastMessage: {},
      toastLink: "",
      actionToast: "",
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    await this.getMobileRegisterSummary();
    await this.getMobileRegisterDetail();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    async initiateData() {
      this.dataMobileRegisterDetail = [];
      this.dataMobileRegisterSummary = null;

      this.selectedStatus = "";
      this.selectedFilterSearch = "all";
      this.searchText = "";

      this.unitCode = null;
      this.addressNo = null;
      this.customerName = null;
      this.customerMobile = null;
      this.livingType = null;

      this.allStatusClick = true;
      this.registeredClick = false;
      this.waitRegisterClick = false;
      this.penndingApproveClick = false;
    },
    async getMobileRegisterSummary() {
      // this.setLoading(true);
      try {
        await this.$store.dispatch("dashboardMobileRegister/getMobileRegisterSummary", {
          projectId: this.selectedProject.id,
        });
        this.dataMobileRegisterSummary = this.registerMobileSummary;
        this.calculatePercent();
        // this.setLoading(false);
      } catch (error) {
        this.baseDialogClassTitle = "p-error";
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    calculatePercent() {
      let all = this.dataMobileRegisterSummary.allMobileRegister;
      let registered = this.dataMobileRegisterSummary.sumRegister;
      let waitRegister = this.dataMobileRegisterSummary.sumWaitRegister;
      let pendingApprove = this.dataMobileRegisterSummary.sumPendingApprove;

      this.percentRegistered = ((registered / all) * 100).toFixed(2) + "% ";
      this.percentWaitRegister = ((waitRegister / all) * 100).toFixed(2) + "% ";
      this.percentPenndingApprove = ((pendingApprove / all) * 100).toFixed(2) + "% ";
    },
    async getMobileRegisterDetail() {
      if (this.validateSearchButton) {
        this.baseDialogClassTitle = "";
        this.titleDialog = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateSearchText;
        this.showValidateDialog = true;
        return;
      }
      this.setLoading(true);
      this.setDataSearch();
      try {        
        await this.$store.dispatch(
          "dashboardMobileRegister/getMobileRegisterDetail",
          this.payload
        );
        this.dataMobileRegisterDetail = this.registerMobileDetail;
        this.setLoading(false);
      } catch (error) {
        this.baseDialogClassTitle = "p-error";
        this.titleDialog = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    async checkInputSearch(event) {
      if (event.key == "Enter") {
        await this.getMobileRegisterDetail();
      }
    },
    changeFilterSearch() {
      this.searchText = "";
      this.selectedStatus = "";
      this.unitCode = "";
      this.addressNo = "";
      this.customerName = "";
      this.customerMobile = "";
      this.livingType = "";
    },
    async setDataSearch() {
      if (this.selectedFilterSearch === "unitCode") {
        this.unitCode = this.searchText;
      } else if (this.selectedFilterSearch === "addressNo") {
        this.addressNo = this.searchText;
      } else if (this.selectedFilterSearch === "customerName") {
        this.customerName = this.searchText;
      } else if (this.selectedFilterSearch === "customerMobile") {
        this.customerMobile = this.searchText;
      } else if (this.selectedFilterSearch === "livingType") {
        this.livingType = this.selectedStatus;
      }
    },
    async exportToExcel() {
      this.setLoading(true);
      // let data = [];
      try {
        // this.dataMobileRegisterDetail.forEach((e) => {
        //   e = {
        //     projectCode: e.projectCode,
        //     projectName: e.projectName,
        //     unitCode: e.unitCode,
        //     addressNo: e.addressNo,
        //     customerCode: e.customerCode,
        //     customerName: e.customerName,
        //     livingType: this.livingTypeText(e.livingType),
        //     customerMobile: e.customerMobile,
        //     registerEmail: e.registerEmail,
        //     registerMobile: e.registerMobile,
        //     registerStatus: this.registerStatus(e.registerStatus).caption,
        //   };
        //   data.push(e);
        // });
        // const dataWS = XLSX.utils.json_to_sheet(data);
        // const wb = XLSX.utils.book_new();
        // const dataNow = this.formatDateTime(new Date());
        // XLSX.utils.book_append_sheet(wb, dataWS);
        // XLSX.writeFile(wb, "MobileRegister_" + dataNow + ".xlsx");

        // this.baseDialogClassTitle = "";
        // this.titleDialog = this.lang.dialogTitleExported;
        // this.contentDialog = this.lang.dialogContentExported;
        // this.isVisibleDialog = true;

        const dateNow = this.formatDateTime(new Date());
        const payload = {
          RequestId: 0,
          FileName: "mobile_register_"+ dateNow + ".xlsx",
          JasperPath: "dashboard/mobile_register.xlsx",
          DocType: "EXCEL",
          UserId: this.userId,
          ProjectId: this.selectedProject.id,
          RegisterStatus: this.registerStatusFilter? this.registerStatusFilter: "",
          UnitCode: this.unitCode,
          AddressNo: this.addressNo,
          CustomerName: this.customerName,
          CustomerMobile: this.customerMobile,
          LivingType: this.livingType
        };

        const response = await this.reportPrintOrder(payload);
        if (response) {
          this.toastRequest();
          this.setLoading(false);
        }
      } catch (error) {
        this.baseDialogClassTitle = "p-error";
        this.titleDialog = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    registerStatus(data) {
      let paidStatus = {
        caption: "",
        class: "",
      };

      if (data == "Registered") {
        paidStatus.caption = this.lang.registered;
        paidStatus.class = "success";
      }
      if (data == "Wait-register") {
        paidStatus.caption = this.lang.waitRegister;
        paidStatus.class = "error";
      }
      if (data == "Pending-approve") {
        paidStatus.caption = this.lang.waitApprove;
        paidStatus.class = "warning";
      }

      return paidStatus;
    },
    livingTypeText(text) {
      if (text.trim() === "Owner") {
        return this.lang.owner;
      } else if (text.trim() === "Rential") {
        return this.lang.rential;
      } else if (text.trim() === "Stay") {
        return this.lang.stay;
      } else {
        return "";
      }
    },
    async onPageChange(event) {
      if (event.rows != this.rowsPerPage) {
        this.selectedPage = 1;
      } else {
        this.selectedPage = event.page + 1;
      }
      this.rowsPerPage = event.rows;
      await this.getMobileRegisterDetail();
    },
    async clickFilter(status) {
      this.allStatusClick = false;
      this.registeredClick = false;
      this.waitRegisterClick = false;
      this.penndingApproveClick = false;

      if (status == "allStatus") {
        this.allStatusClick = true;
        this.registerStatusFilter = null;
      } else if (status == "registered") {
        this.registeredClick = true;
        this.registerStatusFilter = "Registered";
      } else if (status == "waitRegister") {
        this.waitRegisterClick = true;
        this.registerStatusFilter = "Wait-register";
      } else if (status == "penndingApprove") {
        this.penndingApproveClick = true;
        this.registerStatusFilter = "Pending-approve";
      }

      this.selectedFilterSearch = "all";
      this.selectedPage = 1,
      this.rowsPerPage = 100,
      this.changeFilterSearch();
      await this.getMobileRegisterDetail();
    },
    toastRequest() {
      this.toastMessage = {
        severity: "info",
        summary: this.lang.toastRequestTitle,
        detail: this.lang.toastRequestContent,
        life: 5000,
      };
      this.actionToast = `/${this.selectedProject.code}/report/request?back=${this.$route.fullPath}`;
      this.toastLink = this.lang.toastRequestLink;

      const today = new Date();
			this.showToast = today.getSeconds();
    },
    // gotoRequest() {
    //   this.$router.push({name: "report-request", query: {back: this.$route.fullPath}});
    // },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    validateSearchButton() {
      if (
        this.selectedFilterSearch !== "all" &&
        this.selectedFilterSearch === "livingType" &&
        this.selectedStatus == ""
      ) {
        return true;
      } else if (
        this.selectedFilterSearch !== "all" &&
        this.selectedFilterSearch !== "livingType" &&
        this.searchText == ""
      ) {
        return true;
      }
      return false;
    },
    payload() {
      let payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        projectId: this.selectedProject.id,
        unitCode: this.unitCode || null,
        addressNo: this.addressNo || null,
        customerName: this.customerName || null,
        customerMobile: this.customerMobile || null,
        livingType: this.livingType || null,
        registerStatus: this.registerStatusFilter || null,
      };
      return payload;
    },
    dataButtons() {
      return [
        {
          id: "exportCaption",
          caption: this.lang.export,
          class: "p-button-outlined mr-2",
          icon: "icon-download-arrow",
          iconColor: "primary",
          action: "gotoExport",
          isShow: true,
        },
      ];
    },
    isSearchText() {
      if (this.selectedFilterSearch !== "livingType") {
        return true;
      } else {
        return false;
      }
    },
    isDisableSearchText() {
      if (this.selectedFilterSearch === "all") {
        return true;
      } else {
        return false;
      }
    },
    registerMobileSummary() {
      return this.$store.getters["dashboardMobileRegister/registerMobileSummary"];
    },
    registerMobileDetail() {
      return this.$store.getters["dashboardMobileRegister/registerMobileDetail"];
    },
    totalPage() {
      return this.$store.getters["dashboardMobileRegister/totalPage"];
    },
    currentPage() {
      return this.$store.getters["dashboardMobileRegister/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    contentAllStatus() {
      if (this.dataMobileRegisterSummary) {
        return this.lang.allStatusContent.replace(
          "{number}",
          this.formatNumber(this.dataMobileRegisterSummary.allMobileRegister)
        );
      }
      return "";
    },
    contentRegistered() {
      if (this.dataMobileRegisterSummary) {
        return this.lang.otherStatusContent.replace(
          "{number}",
          this.formatNumber(this.dataMobileRegisterSummary.sumRegister)
        );
      }
      return "";
    },
    contentPendingApprove() {
      if (this.dataMobileRegisterSummary) {
        return this.lang.otherStatusContent.replace(
          "{number}",
          this.formatNumber(this.dataMobileRegisterSummary.sumPendingApprove)
        );
      }
      return "";
    },
    contentWaitRegister() {
      if (this.dataMobileRegisterSummary) {
        return this.lang.otherStatusContent.replace(
          "{number}",
          this.formatNumber(this.dataMobileRegisterSummary.sumWaitRegister)
        );
      }
      return "";
    },
    showCurrentPage(){
      let page = 0;
      page = this.rowsPerPage * (this.selectedPage - 1)
      return page;
    },
    userId() {
      return this.$store.getters.userId;
    },
  },
};
</script>
