<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '640px': '80vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content align-items-center">
        <div class="col-2">
          <label class="font-lg font-bold">{{ lang.title }}</label>
        </div>

        <div
          class="col-10 inline-message cursor-pointer"
          v-tooltip.bottom="{
            value: lang.copyProject,
            class: 'custom-tooltip-secondary',
          }"
          @click="copyProject"
          v-if="this.currentSelectedProject"
        >
          <div class="flex justify-content-between w-auto">
            <div class="inlineMessage-ellipsis">
              <span class="font-bold">{{ lang.currentProject }}</span>
              <span class="font-normal">{{ titleCurrentProject }}</span>
            </div>
            <PvButton
              type="button"
              class="p-button-text p-0 ml-2"
              @click="copyProject"
            >
              <icon-copy iconColor="primary" width="24" height="24"></icon-copy>
            </PvButton>
          </div>
        </div>
      </div>

      <PvDivider></PvDivider>

      <section class="flex flex-wrap justify-content align-items-center">
        <div class="lg:col-6 md:col-8 col-8 p-0 mr-2">
          <span class="p-input-icon-left w-full">
            <i class="pi pi-search" />
            <PvInputText
              class="inputfield w-full"
              v-model="searchText"
              :placeholder="lang.keywordSearch"
              @keyup.enter="getProjects"
              autofocus
            />
          </span>
        </div>
        <PvButton @click="getProjects">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>
    </template>

    <div class="p-datatable-border">
      <PvDataTable
        :value="projects"
        v-model:selection="selectedProject"
        selectionMode="single"
        dataKey="id"
        responsiveLayout="scroll"
        :scrollHeight="halfViewportHeight + 'px'"
        class="p-datatable-xs"
        :class="textTableClass"
        :loading="isLoading"
        @dblclick="selectProject"
      >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
        <PvColumn
          selectionMode="single"
          bodyClass="text-center"
          style="min-width: 2rem; height: 45px"
        ></PvColumn>
        <PvColumn
          field="code"
          :header="lang.columnCode"
          style="min-width: 6rem; height: 45px"
          headerClass="h-left font-normal"
          bodyClass="text-left"
        ></PvColumn>
        <PvColumn
          :field="columnProjectName"
          :header="titleColumnProjectName"
          style="min-width: 10rem; height: 45px"
          headerClass="h-left font-normal"
          bodyClass="text-left"
        ></PvColumn>

        <template #footer>
          <PvPaginator
            :rows="rowsPerPage"
            :totalRecords="totalRecords"
            @page="onPageChange($event)"
            :rowsPerPageOptions="[10, 50, 100]"
            class="p-pagination-radius p-1"
          >
            <template #start="slotProps">
              {{ lang.page }}:
              {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
            </template>
          </PvPaginator>
        </template>
      </PvDataTable>
    </div>

    <template #footer>
      <PvButton
        :label="lang.close"
        class="p-button-outlined p-button-secondary w-2 ml-3"
        @click="closeDialog"
      />
      <PvButton
        :label="lang.confirm"
        class="w-full mr-3 w-2"
        @click="selectProject"
        :disabled="!selectedProject"
      />
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetRoleByBook from "../../hooks/getRoleByBook.js";
import useGetRoleByMenu from "../../hooks/getRoleByMenu.js";
export default {
  emits: ["close", "confirm"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textTableClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.selectProject,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.selectProject,
        };
      }
    });

    const { getAllRoleByBooks } = useGetRoleByBook();
    const { getAllRoleByMenus } = useGetRoleByMenu();

    return {
      lang: mergeLang,
      textHeaderClass,
      textTableClass,
      getAllRoleByBooks,
      getAllRoleByMenus,
    };
  },
  data() {
    return {
      selectedProject: null,
      searchText: "",
      isLoading: false,
      redirectPath: "",
      filteredProject: [],
      selectedPage: 1,
      rowsPerPage: 10,
    };
  },
  async mounted() {
    await this.getProjects();
  },
  methods: {
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    initiateData() {
      this.selectedProject = null;
      this.searchText = "";
    },
    async getProjects() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("project/getProjects", this.payload);
        if (this.isSingleProject) {
          this.selectedProject = this.projects[0];
          this.selectProject();
        }
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async selectProject() {
      // console.log("selectProject", this.selectedProject);
      // this.$store.dispatch("setProject", this.selectedProject);
      this.$store.dispatch("setProjectList", this.selectedProject);
      this.$emit("confirm");
      try {
        await this.$store.dispatch("getEmployeeByCodeAndProject", {
          projectId: this.selectedProject.id,
          employeeCode: this.userCode,
        });
        await this.getAllRoleByBooks();
        await this.getAllRoleByMenus();
      } catch (error) {
        this.$swal({
          icon: "error",
          html: `<p class="${this.textAlertClass}">${error}</p>`,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
      }
      this.$router.replace({ name: "home", params: { projectCode: this.selectedProject.code } });
      await this.$store.dispatch("setPageDirty", { value: false }); //ตอนเปลี่ยนโครงการ setPageDirty=false
      this.initiateData();
    },
    getProjectBySearchText() {
      if (this.searchText) {
        this.isLoading = true;
        this.filteredProject = this.projects.filter((project) => {
          return project.search
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        });
        this.isLoading = false;
      } else {
        this.filteredProject = this.projects;
      }
      this.selectedUnit = null;
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      await this.getProjects();
    },
    copyProject() {
      navigator.clipboard.writeText(this.titleCurrentProject);
    },
  },
  computed: {
    projects() {
      return this.$store.getters["project/projects"];
    },
    isSingleProject() { 
      return Array.isArray(this.projects) && this.projects && this.projects.length == 1 && this.$route.name == "select-project"
    },
    totalPage() {
      return this.$store.getters["project/totalPage"];
    },
    currentPage() {
      return this.$store.getters["project/currentPage"];
    },
    rows() {
      return this.$store.getters["project/rows"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    currentSelectedProject() {
      return this.$store.getters.selectedProject;
    },
    payload() {
      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where: this.searchText || "",
      };

      return payload;
    },
    titleCurrentProject() {
      let result = "";
      if(this.currentSelectedProject){
        if (this.$store.getters.selectedLanguage === "TH") {
          result =
            this.currentSelectedProject.code.trim() +
            " : " +
            this.currentSelectedProject.nameTh.trim();
        } else {
          result =
            this.currentSelectedProject.code.trim() +
            " : " +
            this.currentSelectedProject.nameEn.trim();
        }
      }
      return result;
    },
    columnProjectName() {
      let result = "";
      if (this.$store.getters.selectedLanguage === "TH") {
        result = "nameTh";
      } else {
        result = "nameEn";
      }
      return result;
    },
    titleColumnProjectName() {
      let result = "";
      if (this.$store.getters.selectedLanguage === "TH") {
        result = this.lang.columnNameTh;
      } else {
        result = this.lang.columnNameEn;
      }
      return result;
    },
  },
};
</script>

<style scoped>
.inlineMessage-ellipsis {
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  max-width: 95%;
}
.inline-message {
  border-radius: 6px;
  background: #f5f9ff;
  color: #1c70f7;
}
</style>
