export default {
	async getMobileRegisterSummary(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Dashboard/MobileRegisterSummary";

		if (payload.projectId) {
			url = url + "/" + payload.projectId;
		}
		url = encodeURI(url);

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			const error = new Error(responseData.message || "Failed to get MobileRegisterSummary");
			throw error;
		}
		context.commit("setRegisterMobileSummary", responseData.data);
	},
	async getMobileRegisterDetail(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Dashboard/MobileRegisterDetail";

		if (!payload.limit) {
			url = url + "?limit=100";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.projectId) {
			url = url + "&projectId=" + payload.projectId;
		}
		if (payload.unitCode) {
			url = url + "&unitCode=" + payload.unitCode;
		}
		if (payload.addressNo) {
			url = url + "&addressNo=" + payload.addressNo;
		}
		if (payload.customerName) {
			url = url + "&customerName=" + payload.customerName;
		}
		if (payload.customerMobile) {
			url = url + "&customerMobile=" + payload.customerMobile;
		}
		if (payload.livingType) {
			url = url + "&livingType=" + payload.livingType;
		}
		if (payload.registerStatus) {
			url = url + "&registerStatus=" + payload.registerStatus;
		}
		url = encodeURI(url);

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			const error = new Error(responseData.message || "Failed to get MobileRegisterDetail");
			throw error;
		}
		context.commit("setRegisterMobileDetail", responseData);
	},
	async resetRegisterMobile(context) {
		context.commit("resetRegisterMobile");
	},
};
