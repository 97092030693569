export default {
	title: "ใบขอซื้อ",
	changeAPBook: "เปลี่ยนเล่มเอกสาร",

	columnApproveStatus: "อนุมัติ",
    columnCancelStatus:"ยกเลิก",
    columnCode: "เลขที่เอกสาร",
    columnReferDocument : "เอกสารอ้างอิง",
    columnDate: "วันที่เอกสาร",
    columnDueDate: "วันที่ครบกำหนด",
    columnSupplierCode:"รหัสผู้จัดจำหน่าย",
    columnSupplierName:"ชื่อผู้จัดจำหน่าย",
    columnNetAmount: "จำนวนเงิน",
	columnPaidStatus: "สถานะ",
    closePR: "ปิดใบขอซื้อ"
};
