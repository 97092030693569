export default {
  setProductAR(state, payload) {
    // state.project.length = 0;
    let products = [...state.productAR.products];
    for (const product of payload.products) {
      if(products.findIndex(c => c.id === product.id) < 0){
        products.push(product);
      }
    }
    state.productAR.products = products;
    state.productAR.currentPage = payload.currentPage;
    state.productAR.totalPage = payload.totalPage;
  },
  resetProductAR(state) {
    const productAR = {
      products: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.productAR = productAR;
  },
};
