import { useStore } from "vuex";
import { ref, computed} from "vue";
export default function useGetProductGuarantee() {
  const store = useStore();
  let searchText = ref(null);

  const productGuarantees = computed(() => {
    return store.getters["shared/productGuarantees"];  
  });

  const productIsLastPage = computed(() => {
    return store.getters["shared/productGuaranteeIsLastPage"];
  });

  const filteredProductGuarantees = computed(() => {
    // console.log("productGuarantees.value=",productGuarantees.value);
    let products = productGuarantees.value.filter((e) => {return (e.code.substring(0, 1).toLowerCase() == "g")});
    if (!searchText.value) {
      //ช่องว่าง
      const result = [...products];
      return result;
    } else {
      return products.filter((e) => {
        return e.searchText
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const searchProductGuarantee = function (event) {
    // console.log(event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchText.value = "";
      searchText.value = null;
    } else {
      searchText.value = event.query;
    }
  };

  let isSharedProductGuaranteeReady = ref(false);

  const getAllProductGuarantees = async function () {
    if (!productIsLastPage.value) {
      await store.dispatch("shared/getAllProductGuarantees");
    }
    isSharedProductGuaranteeReady.value = true;
  };

//   onMounted(async () => {
//     await getAllProductGuarantees();
//   });

  return {
    searchProductGuarantee,
    filteredProductGuarantees,
    searchText,
    isSharedProductGuaranteeReady,
    getAllProductGuarantees,
  };
}
