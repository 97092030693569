<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ this.title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog()"
        />
      </div>
      <PvDivider></PvDivider>
    </template>
    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="bookBankStart">{{ lang.bookBankNumberStart }}</label>
          <base-book-bank-ar-auto-complete
            id="bookBankStart"
            v-model="selectedBookbankStart"
            class="inputfield w-full mt-2"
            @load-finish="bookBankDataReady"
            :class="{ 'p-invalid': bookBankStartError }"
            @item-select="bookBankStartError = false"
          />
          <small v-if="bookBankStartError" id="bank-help" class="p-error">
            {{ lang.validateBank }}
          </small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="bookBankEnd">{{ lang.to }}</label>
          <base-book-bank-ar-auto-complete
            id="bookBankEnd"
            v-model="selectedBookbankEnd"
            class="inputfield w-full mt-2"
            @load-finish="bookBankDataReady"
            :class="{ 'p-invalid': bookBankEndError }"
            @item-select="bookBankEndError = false"
          />
          <small v-if="bookBankEndError" id="bank-help" class="p-error">
            {{ lang.validateBank }}
          </small>
        </div>

        <div class="col-12 pl-0 pr-2">
          <PvCheckbox
            id="checkedAllCode"
            inputId="binary"
            v-model="checkedAllCode"
            :binary="true"
            @change="changeCheckAllCode()"
          />
          <label for="checkedAllCode" class="pl-3">{{ lang.checkedAllCode }}</label>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="invoiceCodeStart">{{ lang.invoiceCodeStart }}</label>
          <PvInputText
            id="invoiceCodeStart"
            type="text"
            class="inputfield w-full mt-2"
            :placeholder="lang.invoiceCodeStart"
            v-model="invoiceCodeStart"
            :disabled="checkedAllCode"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="invoiceCodeEnd">{{ lang.to }}</label>
          <PvInputText
            id="invoiceCodeEnd"
            type="text"
            class="inputfield w-full mt-2"
            :placeholder="lang.invoiceCodeEnd"
            v-model="invoiceCodeEnd"
            :disabled="checkedAllCode"
          />
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="selectedStatus">{{ lang.status }}</label>
          <PvDropdown
            id="selectedStatus"
            class="inputfield w-full mt-2"
            :options="statusOptions"
            optionLabel="name"
            optionValue="code"
            v-model="selectedStatus"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="selectedTransactionType">{{ lang.cashType }}</label>
          <PvDropdown
            id="selectedTransactionType"
            class="inputfield w-full mt-2"
            :options="transactionTypeOptions"
            optionLabel="name"
            optionValue="id"
            v-model="selectedTransactionType"
          />
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="customerStart">{{ lang.customerStart }}</label>
          <base-customer-auto-complete
            id="customerStart"
            v-model="selectedCustomerStart"
            class="inputfield w-full mt-2"
            @load-finish="customerDataReady"
            :class="{ 'p-invalid': customerStartError }"
            @item-select="customerStartError = false"
          />
          <small v-if="customerStartError" id="bank-help" class="p-error">
            {{ lang.validateCustomer }}
          </small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="customerEnd">{{ lang.to }}</label>
          <base-customer-auto-complete
            id="customerEnd"
            v-model="selectedCustomerEnd"
            class="inputfield w-full mt-2"
            @load-finish="customerDataReady"
            :class="{ 'p-invalid': customerEndError }"
            @item-select="customerEndError = false"
          />
          <small v-if="customerEndError" id="bank-help" class="p-error">
            {{ lang.validateCustomer }}
          </small>
        </div>

        <div class="col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
          <label for="selectDateType">{{ lang.dateType }}</label>
          <!-- <PvDropdown
						id="selectDateType"
						class="inputfield w-full mt-1"
						:options="dateTypes"
						optionLabel="name"
						optionValue="code"
						v-model="selectDateType" /> -->
          <div class="flex flex-wrap">
            <div
              v-for="dateType of dateTypes"
              :key="dateType.code"
              class="field-radiobutton pt-3 mr-5"
            >
              <PvRadioButton
                class="mr-2"
                :inputId="dateType.code"
                name="dateType"
                :value="dateType.code"
                v-model="selectDateType"
                @change="changeDateType()"
              />
              <label :for="dateType.code">{{ dateType.name }}</label>
            </div>
          </div>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateStart">{{ lang.receiptDateStart }}</label>
          <PvCalendar
            id="dateStart"
            v-model="dateStart"
            class="inputfield w-full mt-2"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateStartError }"
            @date-select="dateStartError = false"
          />
          <small v-if="dateStartError" id="date-start-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="dateEnd">{{ lang.to }}</label>
          <PvCalendar
            id="dateEnd"
            v-model="dateEnd"
            class="inputfield w-full mt-2"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateEndError }"
            @date-select="dateEndError = false"
          />
          <small v-if="dateEndError" id="date-end-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText
            id="fileNameText"
            type="text"
            class="inputfield w-full mt-1"
            v-model="fileNameText"
          />
        </div>
      </section>
    </transition>

    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
        </div>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useTextStyle from "../../../hooks/textStyle.js";
import useLang from "../../../hooks/lang.js";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useFormat from "../../../hooks/format.js";
import useTracking from "../../../hooks/tracking";
import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
// import BaseBookBankAutoComplete from "../../../components/bank/BaseBookBankAutoComplete.vue";
export default {
  // components: {
  //   BaseBookBankAutoComplete,
  // },
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { lang } = useLang();
    const store = useStore();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { getPreviewBlob, getDownloadBlob } = useGetFormPrint();
    const { formatDateToISOString, formatPeriodToISOString } = useFormat();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      getPreviewBlob,
      getDownloadBlob,
      formatDateToISOString,
      formatPeriodToISOString,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      selectedBookbankStart: null,
      bookBankStartError: false,
      selectedBookbankEnd: null,
      bookBankEndError: false,

      selectedCustomerStart: null,
      customerStartError: false,
      selectedCustomerEnd: null,
      customerEndError: false,

      isSharedBookBankReady: false,
      isSharedCustomerReady: false,

      checkedAllCode: false,
      invoiceCodeStart: "",
      invoiceCodeEnd: "",

      selectedStatus: "All",
      statusOptions: [
        { code: "All", name: "All : ทั้งหมด" },
        { code: "N", name: "N : ในมือ" },
        { code: "B", name: "B : เข้าธนาคาร" },
        { code: "P", name: "P : ผ่าน" },
        { code: "R", name: "R : คืน" },
      ],
      selectedTransactionType: "All",
      transactionTypeOptions: [],
      dateTypes: [
        { code: "date", name: this.lang.date },
        { code: "dueDate", name: this.lang.dueDate },
        { code: "toBankDate", name: this.lang.toBankDate },
        { code: "passDate", name: this.lang.passDate },
        { code: "returnDate", name: this.lang.returnDate },
      ],
      selectDateType: "date",
      dateStart: "",
      dateEnd: "",
      docType: "",
      dateStartError: false,
      dateEndError: false,
      type: "PDF",
      fileNameText: "payment",
    };
  },

  async mounted() {
    this.setLoading(true);
    this.initiateData();
    this.getTransactionType();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },

    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },

    async initiateData() {
      // await this.$store.dispatch("bank/setTypeInOut", { type: 1 }); // 1="PayIn" ,-1= "PayOut"
      // console.log(this.reportObj.path);
      this.selectedStatus = "All";
      this.selectedTransactionType = "All";
      this.invoiceCodeStart = "";
      this.invoiceCodeEnd = "";
      this.checkedAllCode = false;
      this.selectedBookbankStart = null;
      this.selectedBookbankEnd = null;
      this.selectedCustomerStart = null;
      this.selectedCustomerEnd = null;
      this.selectedPayType = null;

      let date = new Date();
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.dateStartError = false;
      this.dateEndError = false;
      this.type = "PDF";
      this.fileNameText = "payment";
    },

    setData() {
      // console.log(this.sharedBookbanks);

      this.selectedBookbankStart = this.sharedBookbanks[0];
      this.selectedBookbankEnd = this.sharedBookbanks[this.sharedBookbanks.length - 1];
      this.selectedCustomerStart = this.sharedCustomers[0];
      this.selectedCustomerEnd = this.sharedCustomers[this.sharedCustomers.length - 1];
      this.setLoading(false);
    },

    changeCheckAllCode() {
      this.invoiceCodeStart = "";
      this.invoiceCodeEnd = "";
    },

    changeDateType() {
      let date = new Date();
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    },

    async getTransactionType() {
      try {
        await this.$store.dispatch("bank/getTransactionType", {});
        this.transactionTypeOptions = this.sharedTransactionType;
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
      }
    },

    bookBankDataReady() {
      this.isSharedBookBankReady = true;
      this.checkShouldSetData();
    },

    customerDataReady() {
      this.isSharedCustomerReady = true;
      this.checkShouldSetData();
    },

    checkShouldSetData() {
      if (this.isSharedCustomerReady && this.isSharedBookBankReady) {
        this.setData();
      }
    },

    validateData() {
      let returnStatus = false;
      // if (this.selectedBookbankStart == null) {
      //   this.bookBankStartError = true;
      //   returnStatus = true;
      // }
      // if (this.selectedBookbankEnd == null) {
      //   this.bookBankEndError = true;
      //   returnStatus = true;
      // }
      // if (this.selectedCustomerStart == null) {
      //   this.customerStartError = true;
      //   returnStatus = true;
      // }
      // if (this.selectedCustomerEnd == null) {
      //   this.customerEndError = true;
      //   returnStatus = true;
      // }
      if (this.selectedDateStart == null) {
        this.dateStartError = true;
        returnStatus = true;
      }
      if (this.selectedDateEnd == null) {
        this.dateEndError = true;
        returnStatus = true;
      }
      return returnStatus;
    },
    async download(typeReport) {
      this.type = typeReport;
      if (this.validateData()) {
        return;
      }

      this.setLoading(true);
      try {
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    title() {
      if (this.$store.getters.selectedLanguage === "TH") return this.reportObj.nameThai;
      else return this.reportObj.nameEnglish;
    },
    sharedCustomers() {
      return this.$store.getters["shared/customerCustomers"];
    },
    sharedBookbanks() {
      return this.$store.getters["shared/bookBankArs"];
    },
    sharedTransactionType() {
      let transactionType = this.$store.getters["bank/transactionType"];
      let jsonAdd = {
        id: "All",
        code: "All",
        name: "All - ทั้งหมด",
        name2: "All",
      };
      transactionType.push(jsonAdd);
      return transactionType;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
    fileType() {
      let fileType =
        this.type == "EXCEL"
          ? "_excel.xlsx"
          : (this.type == "JASPEREXCEL")
          ? ".xlsx"
          : ".pdf";
      return fileType;
    },
    fileName() {
      let fileName = this.type == "PDF" ? ".pdf" : ".xlsx";
      return this.fileNameText + fileName;
    },
    payload() {
      let payload = {
        ProjectId: this.selectedProject.id,
        BookbankStart: this.selectedBookbankStart ? this.selectedBookbankStart.code : this.sharedBookbanks[0].code,
        BookbankEnd: this.selectedBookbankEnd ? this.selectedBookbankEnd.code : this.sharedBookbanks[this.sharedBookbanks.length - 1].code,
        CustomerStart: this.selectedCustomerStart ? this.selectedCustomerStart.code : this.sharedCustomers[0].code,
        CustomerEnd: this.selectedCustomerEnd ? this.selectedCustomerEnd.code : this.sharedCustomers[this.sharedCustomers.length - 1].code,
        Status: this.selectedStatus,
        PaymentMethod: this.selectedTransactionType,
        FilterDate: this.selectDateType,
        DateStart: this.selectedDateStart,
        DateEnd: this.selectedDateEnd,
        CodeStart: this.invoiceCodeStart,
        CodeEnd: this.invoiceCodeEnd,
        OrderBy: "Y",
        FileName: this.fileName,
        JasperPath: this.reportObj.path + this.fileType,
        DocType: this.type,
        UserId: this.userId,
        PrintBy: this.userName,
        RequestId: 0,
        ReportName: "Payment",
      }
      return payload;
    }
  },
  watch: {
    reportObj() {
      this.initiateData();
    },
    // isSharedCustomerReady() {
    //   this.checkShouldSetData();
    // },
    // isSharedBookBankReady() {
    //   this.checkShouldSetData();
    // },
    // isSharedPayTypeReady() {
    //   this.checkShouldSetData();
    // },
  },
};
</script>
