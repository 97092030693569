<!-- example code (how to use) -->
<!-- 
  <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3">
    <base-input-mobile-with-country-code
      :caption="lang.projectHouseTel"
      :textHolder="lang.validateProjectTel"
      :toastTotle="lang.notificationDialogHeader"
      :toastContent="lang.notificationCountryCode"
      :inputCountryCode="`758`"
      @inputCountryCode="updateCountryCode"
      :inputPhone="`0982897841`"
      @inputPhone="updatePhone"
    />
  </div>  
-->
<template>
  <div class="w-full">
    <label>{{caption}}</label>
    <div class="p-inputgroup w-full mt-2" id="baseDropdownWithCountryCode">
      <PvDropdown
        class="base-dropdown-width"
        v-model="selectedCountryCode"
        :options="countryCodes"
        optionLabel="name"
        optionValue="code"
        @change="pushData()"
        v-if="!isdisable"
      />
      <PvInputText
        class="base-dropdown-width"
        type="text"
        value="+66"
        readonly
        v-if="isdisable"
      />
      <PvInputText
        class="w-full"
        type="text"
        v-model="inputText"
        :placeholder="textHolder"
        @keyup="pushData()"
        :class="{ 'p-invalid': isError }"
      />
      <!-- <PvInputText
        class="w-full hidden-sm hidden-xs"
        type="text"
        v-model="Phone"
        placeholder="ระะบุเบอร์โทรศัพท์"
      /> -->
      <!-- <small v-if="isError" id="error-help" class="p-error">{{errorMessage}}</small>   -->
    </div>
    <small v-if="isError" id="error-help" class="p-error">{{errorMessage}}</small>  
  </div>
</template>

<script>
export default {

  props: {
    caption: String,
    textHolder: String,
    isError: Boolean,
    errorMessage: String,
    toastTotle: String,
    toastContent: String,
    inputCountryCode: String,
    inputPhone: String,
    isdisable: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["inputCountryCode","inputPhone"],

  data() {
    return {
      // inputText: this.inputPhone,
      phone: "",
      countryCodes: [
        { name: "+66", code: "66" },
        { name: "+68", code: "68" },
        { name: "+65", code: "65" },
        // { name: "อื่นๆ", code: "0" },
      ],
      selectedCountryCode: this.setCountryCode(),
    };
  },
  
  methods: {
    pushData() {
      this.$emit("inputCountryCode", this.selectedCountryCode);
      this.$emit("inputPhone", this.phone);
    },
    pushCountryCode() {
      if (this.selectedCountryCode == "0") {
        this.alertWarning();
      }
      this.$emit("inputCountryCode", this.selectedCountryCode);
    },
    pushPhone() {
      this.$emit("inputPhone", this.inputText);
    },
    setCountryCode() {
      // let code = ['66','68','65','0']
      let code = ['66','68','65']
      let checkCode = code.find(
        (data) => data === this.inputCountryCode
      );
      if(checkCode){
        return this.inputCountryCode
      } else {
        // return '0'
        return '66'
      }
    },
    alertWarning() {
      this.$toast.add({
          severity: "warn", // severity:'success',severity:'info',severity:'warn',severity:'error'
          summary: this.toastTotle, // "ข้อความแจ้งเตือน"
          detail: this.toastContent, // "ตัวเลือกอื่นๆ มีเพื่อรองรับข้อมูลที่เกิดความผิดพลาดเท่านั้น เมื่อกดบันทึก ข้อมูลจะถูกเปลี่ยนเป็น ประเทศไทย(+66)  ให้โดยอัตโนมัติ"
          life: 5000, //TimeClose
        });
    },
  },
  computed: {
    inputText: {
      // this.phone = this.inputPhone;
      get() {
        return this.inputPhone;
      },
      // setter
      set(newValue) {
        // Note: we are using destructuring assignment syntax here.
        this.phone = newValue;
      }
      //https://vuejs.org/guide/essentials/computed.html#writable-computed
    }
  },

};
</script>

<style>
@media (max-width: 600px) {
  .hidden-xs {
    display: none !important;
  }
  .base-dropdown-width {
    /* max-width: 92px;  */
    width: 92px !important;
  }
  #baseDropdownWithCountryCode .p-dropdown-trigger {
    /* display: none !important; */
    display: flex;
  }
}
@media (min-width: 601px) and (max-width: 959px) {
  .hidden-sm {
    display: none !important;
  }
  .base-dropdown-width {
    /* max-width: 92px;  */
    width: 92px !important;
  }
  #baseDropdownWithCountryCode .p-dropdown-trigger {
    display: flex;
  }
}
@media (min-width: 960px) and (max-width: 1263px) {
  .hidden-md {
    display: none !important;
  }
  .base-dropdown-width {
    /* max-width: 92px;  */
    width: 92px !important;
  }
  #baseDropdownWithCountryCode .p-dropdown-trigger {
    display: flex;
  }
}
@media (min-width: 1264px) and (max-width: 1903px) {
  .hidden-lg {
    display: none !important;
  }
  .base-dropdown-width {
    /* max-width: 92px;  */
    width: 92px !important;
  }
  #baseDropdownWithCountryCode .p-dropdown-trigger {
    display: flex;
  }
}
@media (min-width: 1904px) {
  .hidden-xl {
    display: none !important;
  }
  .base-dropdown-width {
    /* max-width: 92px;  */
    width: 92px !important;
  }
  #baseDropdownWithCountryCode .p-dropdown-trigger {
    display: flex;
  }
}
</style>