export default {
    paymentRequestManagement: "จัดการใบตั้งเบิก",
    createPaymentRequest: "สร้างใบตั้งเบิก",
    paymentRequest: "ใบตั้งเบิก",
    filterSearchDate: "ช่วงวันที่เอกสาร",
    supplier: "ผู้จัดจำหน่าย/ผู้เบิก",
    filterSearchAmount: "ช่วงจำนวนเงิน",
    columnTotalAmount: "จำนวนเงิน",
    columnExpense: "ตั้งค่าใช้จ่าย",
    columnPayment: "ทำจ่าย",
    totalAmountAscending: "จำนวนเงิน น้อยไปมาก",
    totalAmountDescending: "จำนวนเงิน มากไปน้อย",
    postedStatus: "ลงบัญชีแล้ว",
    partialStatus: "ลงบัญชีบางส่วน",
    autoGl: "บันทึกบัญชี",
    settingAutoGl: "กรุณาตั้งค่าการบันทึกบัญชี",
    paymentRequestTypeAutoGl: "ประเภทใบตั้งเบิกที่จะบันทึกบัญชี",
    selectPaymentRequestType: "เลือกประเภทใบตั้งเบิก",
    paymentRequestDateStart: "วันที่เอกสารเริ่มต้น",
    paymentRequestDateEnd: "วันที่เอกสารสิ้นสุด",
    ledgerBook: "สมุดรายวันที่จะบันทึก",
    nextStepAutoGl: "ในขั้นตอนถัดไป ระบบจะแสดงรายการใบตั้งเบิกที่อนุมัติแล้ว ตามการตั้งค่าที่ระบุ",
    ledgerBookCode: "สมุดบัญชี",
    voucherDate: "วันที่บันทึกบัญชี",
    paymentRequestCode: "เลขที่ใบตั้งเบิก",
    paymentRequestDate: "วันที่ใบตั้งเบิก",
    paymentRequestType: "ประเภท",
    validateNoSelectLedgerBook: "กรุณาเลือกสมุดรายวันที่จะบันทึก",
    validateDateStartError: "กรุณาเลือกวันที่เอกสารเริ่มต้น",
    validateDateEndError: "กรุณาเลือกวันที่เอกสารสิ้นสุด",
    titlePreviewVoucher: "ตัวอย่างการบันทึกบัญชี",
    accountCode: "รหัสผังบัญชี",
    accountName: "ชื่อผังบัญชี",
    debitAmount: "เดบิต",
    creditAmount: "เครดิต",
    totalDebitAmount: "รวมเดบิต",
    totalCreditAmount: "รวมเครดิต",
    totalDiffAmount: "ผลต่าง",
    validatePaymentRequestTypeAutoGl: "ไม่สามารถบันทึกบัญชี เนื่องจากคุณมีประเภทที่ไม่ระบุสมุดบัญชี ดังนี้ : ",
    validateProductAutoGl: "ไม่สามารถบันทึกบัญชี เนื่องจากคุณมีรายจ่ายที่ไม่ระบุเดบิต, เครดิต ดังนี้ : ",
    setupType: "ไปหน้า ตั้งค่าใบตั้งเบิก",
    setupProduct: "ไปหน้า รายจ่าย",
    autoGlProcessing: "กำลังทำงาน",
    validateNoDataForAutoGl: "ไม่สามารถบันทึกได้ เนื่องจากไม่มีรายการรอบันทึก",
    columnItemNo: "ลำดับ",
    columnBookCode: "ประเภทเล่มเอกสาร",
    columnBookName: "ชื่อเล่มเอกสาร",
    columnReferenceCode: "เลขที่",
    columnReferenceDate: "วันที่",
}