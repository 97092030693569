export default {
	createCustomerGroup: "Create Customer Group", 
	editCustomerGroup: "Edit Customer Group", 
	editTitle: "Customer Group Details", 

	dialogTitleNotification: "Notification", 
	dialogTitleAddSuccess: "Insert success.", 
	dialogContentAddSuccess: "Insert customer group successful.",
	dialogTitleEditSuccess: "Edit success.",
	dialogContentEditSuccess: "Edit customer group successful.",
	dialogTitleDeleteSuccess: "Delete success", 
	dialogContentDeleteSuccess: "Delete customer group successful.",	
	dialogTitleDeleteConfirm: "Delete customer group confirmation.",
	dialogContentDeleteConfirm: "Do you want delete customer group {0} or not?", 

	validateParams: "Please enter require field.",  
	validateRole: "You don't have permission.", 
	validateDuplicate: "Please check code in the system.", 
	validateNotFound: "You may not have data or It's doesn't exist.", 
	validateReference: "The customer group has reference in the system.", 
	validateNoneId: "The customer group id doesn't exist.",

	labelCode: "Customer group code*", 
	placeholderCode: "Please enter customer group code.", 
	validateCode: "Please enter customer group code.", 
	validateNoneCode: "Please enter customer group code.", 
	validateDuplicateCode: "The customer group code is duplicate in the system.", 
	labelNameThai: "Customer groupname* (English)", 
	placeholderNameThai: "Please enter customer group name thai.", 
	validateNameThai: "Please enter customer group name thai.", 
	validateLimitText: "Please specify a message with a maximum length of 50 characters.",
	validateChangeCode: "The book code {0} cannot be changed. (Support characters and numbers only)",
	validateChangeUniqueCode: "The book code {0} cannot be changed. (The book code is master code)",
	labelActiveStatus: "Active status",
};
