export default {
  async getBookBankArs(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/BookBank";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get book bank"
      );
      throw error;
    }
    // console.log("bookbank", responseData);
    const bookBankAr = {
      bookBankArs : responseData.data,
      totalPage: responseData.page.pageCount == 0 ? 1 : responseData.page.pageCount,
      currentPage: responseData.page.currentPage, 
    };
    // console.log("setBookBankAr=",bookBankAr);
    context.commit("setBookBankAr", bookBankAr);
  },  
  
  resetBookBankAr(context) {
    context.commit("resetBookBankAr",null);
  },
  async getAllBookBankArs(context) {
    while (!context.getters.bookBankArIsLastPage) {
      let where = {
        projectId: context.rootGetters.selectedProject.id,
        bankBookUseType: "R",
        activeStatus: "Y",
      };

      const payloadNew = {
        page: context.getters.bookBankArNextPage || 1,
        where,
      };
      await context.dispatch("getBookBankArs", payloadNew);
    }
    
  },

};
