export default {
	importReceiptTitle: "เลือกใบเสร็จ",
	extra: "เพิ่มหักจาก",
	cashType: "ประเภท",
	cash: "เงินสด",
	creditCard: "บัตรเครดิต",
	transfer: "เงินโอน",
	cheque: "เช็ค",
	epay: "E-Pay",
	startDate: "ตั้งแต่วันที่",
	endDate: "ถึงวันที่",
	receiptCode: "เลขที่ใบเสร็จ",
	toBankDatePayin: "วันที่เข้าธนาคาร",
	searchReceiptLabel: "ค้นหาใบเสร็จ",
	confirmImport: "ยืนยันเลือกใบเสร็จ",
	deleteReceiptAll: "ลบใบเสร็จทั้งหมด",

	columnCodeReceipt: "เลขที่ใบเสร็จรับเงิน",
	columnReceiptDate: "วันที่ใบเสร็จ",
	columnDate: "วันที่โอน/เช็ค",
	columnInvoice: "ใบแจ้งหนี้",
	columnUnitCode: "ยูนิต",
	columnTotal: "มูลค่า",
	columnCustomerName: "ชื่อลูกค้า",
};
