export default {
  async getRoleByMenus(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Role/ByMenu";
    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }
    if (payload.page) {
      url = url + "&page=" + payload.page;
    }
    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }
    const token = context.rootGetters.token;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get RoleByMenu");
      throw error;
    }
    const roleByMenu = {
      roleByMenus: responseData.data,
      totalPage: responseData.page.pageCount == 0 ? 1 : responseData.page.pageCount,
      currentPage: responseData.page.currentPage,
    };
    context.commit("setRoleByMenu", roleByMenu);
  },
  async getAllRoleByMenus(context) {
    context.commit("resetRoleByMenu");
    while (!context.getters.roleByMenuIsLastPage) {
      let where = {
        projectId: context.rootGetters.selectedProject.id,
        userId: context.rootGetters.userId,
      };
      const payload = {
        page: context.getters.roleByMenuNextPage || 1,
        where,
      };
      await context.dispatch("getRoleByMenus", payload);
    }
  },
  resetRoleByMenu(context) {
    context.commit("resetRoleByMenu");
  },
  trySetRoleByMenus(context, payload) {
    const roleByMenu = {
      roleByMenus: payload,
      totalPage: 1,
      currentPage: 1,
    };
    context.commit("setRoleByMenu", roleByMenu);
  },
};
