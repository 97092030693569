export default {
    outstandingList(state) {
        return state.outstandingList;
    },
    residentList(state) {
        return state.residentList;
    },
    parcelList(state) {
        return state.parcelList;
    },
}