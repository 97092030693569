export default {
	productArProducts(state) {
		return state.products;
	},
	productArCurrentPage(state) {
		return state.currentPage;
	},
	productArNextPage(state) {
		return state.currentPage + 1;
	},
	productArTotalPage(state) {
		return state.totalPage;
	},
	productArIsLastPage(_, getters) {
		return (
			getters.productArCurrentPage === getters.productArTotalPage &&
			getters.productArTotalPage !== 0
		);
	},
	penaltyRateStep(state) {
		return state.penaltyRateStep;
	},
	arProducts(state) {
		return state.arProducts;
	},
	selectedArProduct(state) {
		return state.selectedArProduct;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	unitProducts(state) {
		return state.unitProducts;
	},
	unitProductNextPage(state) {
		return state.unitProductCurrentPage + 1;
	},
	unitProductTotalPage(state) {
		return state.unitProductTotalPage;
	},
	unitProductCurrentPage(state) {
		return state.unitProductCurrentPage;
	},
	allUnitProducts(state) {
		return state.allUnitProducts;
	},
	allUnitProductsIsLastPage(_, getters) {
		if (getters.unitProductCurrentPage > 0 && getters.unitProductTotalPage === 0) {
			return true;
		} else {
			return getters.unitProductCurrentPage === getters.unitProductTotalPage && getters.unitProductTotalPage !== 0;
		}
	},
};
