<template>
  <!-- v-model="selectedUnit" -->
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredUnits"
    :field="field"
    @complete="searchUnit"
		@dropdown-click="filteredUnits"
		@item-select="selectedUnit"
    forceSelection
  />
</template>

<script>
import useGetUnit from "../../hooks/getUnit.js";
import useLang from "../../hooks/lang.js";
import { useStore } from "vuex";
import { computed } from "vue";
import BaseDialog from "../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from 'vuejs-confirm-dialog';
export default {
  setup() {
 
    const {
      filteredUnits,
      searchText,
      isSharedUnitReady,
      searchUnit
    } = useGetUnit();

    const { lang } = useLang();
		const store = useStore();

		const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.shared.unit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.shared.unit,
        };
      }
		});

    return {
      filteredUnits,
      searchText,
      isSharedUnitReady,
      searchUnit,
			lang: mergeLang,
    };
  },
	props: {
		isValidateActiveStatus: {
			type: Boolean,
			default: false
		},
	},
  emits: ["load-finish", "is-inactive"],
  data() {
		return {
			validateWording: "",
		}
	},
	methods: {
		async selectedUnit(event) {
			if(this.isValidateActiveStatus && event.value.isActive == "N") {
				this.validateWording = [
					this.lang.validateActiveStatusWording.replace(":code", event.value.codeAndAddressNo.trim()),
					this.lang.pleaseSelectNewUnit
				]
				const isCanceled = await this.validateActiveStatusDialog.reveal();
				if(isCanceled){
					this.$emit("is-inactive");
				}
			}
		},
	},
  computed: {
		field() {
			let result = "codeAndAddressNo";
			if(this.isValidateActiveStatus) {
				result = "codeAndAddressNoWithActiveStatus"
			}
			return result;
		},
		validateActiveStatusDialog() {
			return createConfirmDialog(
				BaseDialog,
				{
					titleConfirm: this.lang.validateActiveStatusTitle,
					classTitle: "p-error",
					wordingConfirm: this.validateWording,
					dataButtons:[
						{
							id: "close",
							caption: this.lang.close,
							class: "p-button-outlined p-button-secondary w-full",
							action: "cancel",
						},
					],
					display: true
				}
			);
		}
	},
  watch:{
    isSharedUnitReady(curValue){
      if(curValue){
        this.$emit('load-finish');
      }
    }
  }
  // props:{
  //   shouldGetData: {
  //     type: Boolean,
  //     default: true
  //   },
  // },
  
  // data() {
  //   return {
  //     searchText: null
  //   };
  // },
  // async created() {
  //   // console.log(this.unitNextPage)
  //   if (this.shouldGetData){
  //     await this.getAllUnits(); 
  //   } 
    
  // },
  // methods: {
  //   searchUnit(event) {
  //     console.log(event)
  //     if (!event.query.trim().length) {
  //       //ช่องว่าง
  //       // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
  //       this.searchText = '';
  //       this.searchText = null;
  //     } else {
  //       this.searchText = event.query;
  //     }
  //   },
  //   async getAllUnits() {
  //     if(!this.unitIsLastPage){
  //       await this.$store.dispatch("shared/getAllUnits");
  //     } 
  //     this.$emit('load-finish');
      
  //   },
  // },
  // computed: {
  //   selectedProject() {
  //     return this.$store.getters.selectedProject;
  //   },
  //   units() {
  //     return this.$store.getters["shared/unitUnits"];
  //   },
  //   unitIsLastPage() {
  //     return this.$store.getters["shared/unitIsLastPage"]
  //   },
  //   filteredUnits(){
  //     if (!this.searchText) {
  //       //ช่องว่าง
  //       // console.log('search',this.units)
  //       const result = [...this.units];
  //       return result;
  //     } else {
  //       // this.resetData();
  //       // console.log('here')
  //       // await this.getUnits(event.query);
  //       return this.units.filter((unit) => {
  //         return unit.codeAndAddressNo
  //           .toLowerCase()
  //           .startsWith(this.searchText.toLowerCase());
  //       });
  //     }
  //   }
  // },
};
</script>