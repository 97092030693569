export default {
  setSupplier(state, payload) {
    state.suppliers = payload.data;
    state.totalPage = payload.page.pageCount;
    state.currentPage = payload.page.currentPage;
  },
  setSelectedSupplier(state, payload) {
    state.selectedSupplier = payload;
  },
  resetSupplier(state) {
    state.suppliers = [];
    state.totalPage = 0;
    state.currentPage = 0;
  },
};