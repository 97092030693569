<template>
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredProducts"
    field="codeAndName"
    @complete="searchProduct"
    @dropdown-click="filteredProducts"
    forceSelection
  />
</template>

<script>
import { watch } from "vue";
import useGetProductAP from "../../hooks/getProductAP.js";

export default {
  emits: ["load-finish"],
  setup(props, { emit }) {
    const { searchProduct, filteredProducts, isSharedProductReady } = useGetProductAP();

    watch(isSharedProductReady, (newValue) => {
      if (newValue) {
        emit("load-finish");
      }
    });

    return {
      searchProduct,
      filteredProducts,
    };
  },
};
</script>