export default {
	createBank: "Create Payin",
	editBank: "Edit Payin",
	createBankAp: "Create Payout",
	editBankAp: "Edit Payout",
	askForProductChange: "Are you sure you want to update product code?",
	askForSelectProduct: "Can't find product code : :code. Do you want to search?",
	askForCreateNewDocument: "Are you sure you want to discard change and create new document?",
	askForNonExistPeriod:
		"Can not find period :period. First row or default period will be use depends on existing data.",

	NetAmount: "Net Amount",
	DocumentCode: "Document Code",
	CreateNew: "CreateNew",
	SearchBy: "SearchBy",
	PayType: "Payment Type",
	Status: "Status",
	BankPayin: "Bank Payin",
	BankPayout: "Bank Payout",
	Cross: "Cross",
	Holder: "Holder",
	UnitCode: "Unit Code",
	DateAt: "Document Date",
	CustomerName: "Customer Name",
	customerSearch: "Customer Code or Name",
	employeeName: "Employee Name",
	ReceiveMan: "Receive To",
	Remark: "Remark",
	PayDate: "Payment Date",
	DueDate: "Due Date",
	SearchReceived: "Search Received",
	Extra: "Extra",
	CashType: "Cash Type",
	StartDate: "Start Date",
	EndDate: "End Date",
	ReceiptCode: "Receipt Code",
	ToBankDatePayin: "To Bank Date",
	ToBankDatePayout: "Out Bank Date",

	columnCodeReceipt: "Code Receipt",
	columnReceiptDate: "Receipt Date",
	columnDate: "Transfer/Cheque Date",
	columnInvoice: "Invoice",
	columnUnitCode: "Unit Code",
	columnTotal: "Total",
	columnCustomerName: "Customer Name",

	columnGLBookName: "GL Book Name",
	columnGLBookCode: "GL Book Code",
	columnCodeVoucher: "Voucher Code",
	columnVoucherDate: "Voucher Date",
	columnVoucherDescription: "Description",
	columnPayAmount: "Pay Amount",

	validateBookbank: "Please select Bookbank",
	validateNoCustomerSelect: "Please select Customer",
	validateReceiptExtra: "No more Receipt",
	validatePayType: "Please select Payment Type",

	differenceAmount: "Amount to be paid",
	cross:"crossed cheque",
	holder: "cross out \"Or holder\"",

	validateInvalidSetupBookbank: "Bookbank is invalid setup bank.",
};
