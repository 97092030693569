export default {  

      async GetFlashAddress(context, payload) {

        let url = process.env.VUE_APP_BACKEND_URL + "/Losa/FirstmileAddress";
        
        if(payload.province){
            url = url + '?province='+ payload.province ;
        }
        if(payload.limit){
            url = url + '&limit=1000';
        }
        if(payload.search){
            url = url + '&search='+ payload.search ;
        }
        
        const token = context.rootGetters.token;
       
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,
          },
        });
    
        const responseData = await response.json();
    
        if (!response.ok) {
          // console.log(responseData);
          let message = null
          if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
            message = responseData.title;
            for (const key in responseData.errors) {
              message += ", " + key.replace("$.","")
            }
          } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
            message = responseData.message
          }
          const error = new Error(message || "Failed to get project");
          throw error;
        }
        // console.log("setFlashAddress=",responseData);
    
        context.commit('setFlashAddress', responseData);
      },

      async resetFlashAddress(context) {
        context.commit('resetFlashAddress');
      },
};