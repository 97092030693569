export default {
    setPurchaseRequisition(state, payload){
        state.purchaseRequisitions = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },
    setSelectedPurchaseRequisition(state, payload){
        state.selectedPurchaseRequisition = payload
    },
    setBlob(state, payload){        
        state.blob = payload
    },
};