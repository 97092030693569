<template>
    <svg 
        :width="width"
		:height="height"
        viewBox="0 0 24 24" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
    <path 
        fill-rule="evenodd" 
        clip-rule="evenodd"
        d="M15.5503 3.11878H15.5503C16.3756 3.39385 16.9321 4.16624 16.9319 5.03612V5.09085C16.9316 6.18993 17.8099 7.08767 18.9087 7.11142L19.0269 7.11398C19.7763 7.13018 20.4552 7.55997 20.7904 8.23044V8.23044C21.1795 9.0085 21.0268 9.94822 20.4116 10.5632L20.3729 10.6018C19.5955 11.3788 19.5817 12.6346 20.3419 13.4284L20.4237 13.5138C20.9421 14.0551 21.1183 14.8391 20.8812 15.5502V15.5502C20.6062 16.3754 19.8338 16.932 18.9639 16.9318H18.9092C17.8101 16.9315 16.9124 17.8098 16.8886 18.9086L16.8861 19.0268C16.8698 19.7762 16.4401 20.4551 15.7696 20.7903V20.7903C14.9915 21.1793 14.0518 21.0267 13.4369 20.4114L13.3982 20.3727C12.6213 19.5953 11.3654 19.5816 10.5716 20.3418L10.4863 20.4235C9.94489 20.942 9.16097 21.1182 8.44984 20.8811V20.8811C7.62458 20.606 7.06803 19.8336 7.06826 18.9637V18.9091C7.06856 17.81 6.19027 16.9122 5.09144 16.8885L4.97325 16.8859C4.22381 16.8697 3.54495 16.4399 3.20973 15.7695V15.7695C2.82069 14.9914 2.97327 14.0518 3.58849 13.4368L3.6272 13.3981C4.40458 12.6212 4.41834 11.3653 3.65816 10.5715L3.57637 10.4861C3.05791 9.94474 2.88178 9.16083 3.11882 8.44971V8.44971C3.3939 7.62445 4.16629 7.06789 5.03619 7.06813H5.09088C6.18996 7.06843 7.0877 6.19014 7.11145 5.09131L7.11401 4.97312C7.13021 4.2237 7.55997 3.54484 8.23043 3.20961V3.20961C9.00848 2.82058 9.94819 2.97321 10.5631 3.58849L10.6018 3.62715C11.3787 4.40456 12.6346 4.4183 13.4284 3.65807L13.5137 3.57637C14.0551 3.05785 14.8391 2.88169 15.5503 3.11878Z" 
        :class="strokeColor"
        stroke-width="1.5" 
        stroke-linecap="round" 
        stroke-linejoin="round"/>
    <circle 
        cx="12" 
        cy="12" 
        r="2.50104" 
        :class="strokeColor"
        stroke-width="1.5" 
        stroke-linecap="round" 
        stroke-linejoin="round"/>
    </svg>
</template>


<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: 24,
		},
		height: {
			type: [Number, String],
			default: 24,
		},
		iconColor: {
			type: String,
			default: "secondary",
		},
	},
	computed: {
		strokeColor() {
			return "p-stroke-" + this.iconColor;
		},
	},
};
</script>

<style lang="scss" scoped>
svg {
	display: inline-block;
	vertical-align: baseline;
	margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>