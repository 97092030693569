<template>
  <base-toolbar :title="lang.importReceipts" @goBack="goBack"></base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="titleHeaderImportData"
      :wordingConfirm="titleValidateImportData"
      :dataButtons="dataButtonDialog"
      :classTitle="baseDialogClassTitle"
      @close="closeDialog"
    ></base-dialog>

    <base-progress-bar
      v-model:visible="showProgressBar"
      :title="lang.uploadStatus"
      :textProgressBar="lang.uploading"
      :valueObj="valueObj"
      :progressBar="`Template`"
    >
    </base-progress-bar>

    <import-receipt-detail-dialog
      v-model:visible="showReceiptDetailDialog"
      :details="receiptDetails"
      @close="showReceiptDetailDialog = false"
    >
    </import-receipt-detail-dialog>

    <div class="surface-card shadow-2 p-5 border-round mt-5">
      <div class="flex align-items-center pt-2">
        <div>
          <PvButton @click="importAll()">
            <icon-documents-file-checkmark
              iconColor="white"
              width="24"
              height="24"
            />
            <span
              class="w-full text-center font-md font-normal p-button-label pl-2"
              >{{ lang.importAll }}</span
            >
          </PvButton>
          <!-- <PvButton class="ml-2" @click="importAll(false)">
            <icon-documents-file-checkmark
              iconColor="white"
              width="24"
              height="24"
            />
            <span
              class="w-full text-center font-md font-normal p-button-label"
              >{{ lang.importSelected }}</span
            >
          </PvButton> -->
        </div>
        <div class="pl-4 pt-0">
          <PvCheckbox
            id="isLock"
            inputId="binary"
            v-model="isLock"
            :binary="true"
          />
          <label for="isLock" class="pl-3">Lock ใบเสร็จที่ Import</label>
        </div>
      </div>

      <div class="p-datatable-border my-2 pt-3">
        <PvDataTable
          :value="receipts"
          v-model:selection="selectedReceipt"
          dataKey="itemNo"
          :class="textTableClass"
          :loading="isLoading"
          :scrollable="true"
          :scrollHeight="halfViewportHeight + 'px'"
        >
          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
          <!-- <PvColumn selectionMode="multiple" :frozen="true"></PvColumn> -->
          <PvColumn
            field="itemNo"
            :header="lang.itemNo"
            style="min-width: 5rem; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="text-center font-normal"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.error }">{{
                slotProps.data.itemNo
              }}</span>
            </template>
          </PvColumn>
          <PvColumn
            field="status"
            headerClass="h-left font-normal"
            bodyClass="font-normal"
            style="min-width: 10rem; height: 56px"
          >
            <template #header>
              <span style="text-align: left">{{ lang.status }}</span>
            </template>
            <template #body="slotProps">
              <base-tag
                v-if="slotProps.data.status != ''"
                :Caption="
                  slotProps.data.status == 'waiting'
                    ? lang.statusWaiting
                    : slotProps.data.status == 'success'
                    ? lang.statusSuccess
                    : lang.error
                "
                :Class="
                  slotProps.data.status == 'waiting'
                    ? 'warning'
                    : slotProps.data.status == 'success'
                    ? 'success'
                    : 'error'
                "
              />
            </template>
          </PvColumn>
          <PvColumn
            field="unitCode"
            :header="lang.columnUnitCode"
            style="min-width: 6rem; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="font-normal"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.error }">{{
                slotProps.data.unitCode
              }}</span>
            </template>
          </PvColumn>
          <!-- <PvColumn
            field="unitAddressNo"
            :header="lang.columnUnitAddressNo"
            style="min-width: 6rem; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="font-normal"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.error}">{{
                slotProps.data.unitAddressNo
              }}</span>
            </template>
          </PvColumn> -->
          <PvColumn
            field="receiptDate"
            :header="lang.columnDate"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="text-center font-normal"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.error }">{{
                formatDate(slotProps.data.receiptDate)
              }}</span>
            </template>
          </PvColumn>
          <PvColumn
            field="referenceCode"
            :header="lang.columnReferCode"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="font-normal"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.error }">{{
                slotProps.data.referenceCode
              }}</span>
            </template>
          </PvColumn>
          <!-- <PvColumn
            field="customerName"
            :header="lang.columnUnitCustomerName"
            style="min-width: 12rem; height: 56px"
            headerClass="h-center font-normal"
            bodyClass="font-normal"
          /> -->
          <!-- <PvColumn
            field="productCode"
            :header="lang.columnProductCode"
            style="min-width: 10rem; height: 56px"
            headerClass="h-center font-normal"
            bodyClass="font-normal"
          >
            <template #body="slotProps">
              {{ slotProps.data.productCode }}
            </template>
          </PvColumn>
          <PvColumn
            field="period"
            :header="lang.columnPeriod"
            style="min-width: 6rem; height: 56px"
            headerClass="h-center font-normal"
            bodyClass="text-center font-normal"
          >
            <template #body="slotProps">
              {{ slotProps.data.period }}
            </template>
          </PvColumn>
          <PvColumn
            field="remark"
            :header="lang.columnDescription"
            style="min-width: 10rem; height: 56px"
            headerClass="h-center font-normal"
            bodyClass="font-normal"
          >
            <template #body="slotProps">
              {{ slotProps.data.remark }}
            </template>
          </PvColumn> -->
          <PvColumn
            field="totalAmount"
            :header="lang.columnAmount"
            style="min-width: 8rem; height: 56px"
            headerClass="h-right font-normal"
            bodyClass="text-right font-normal"
          >
            <template #body="slotProps">
              <span
                class="text-right w-full"
                :class="{ 'p-error': slotProps.data.error }"
                >{{ formatCurrency(slotProps.data.totalAmount) }}</span
              >
            </template>
          </PvColumn>
          <PvColumn
            field="payType"
            :header="lang.columnPayType"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="text-center font-normal"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.error }">{{
                slotProps.data.payType
              }}</span>
            </template>
          </PvColumn>
          <PvColumn
            field="payCode"
            :header="lang.columnPayCode"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="text-center font-normal"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.error }">{{
                slotProps.data.payCode
              }}</span>
            </template>
          </PvColumn>
          <PvColumn
            field="bookBankCode"
            :header="lang.columnBookBankCode"
            style="min-width: 15rem; height: 56px"
            headerClass="h-left font-normal"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.error }">{{
                slotProps.data.bookBankCode
              }}</span>
            </template>
          </PvColumn>
          <PvColumn
            field="branch"
            :header="lang.columnBranch"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left font-normal"
          />
          <PvColumn
            field="payDate"
            :header="lang.columnPayDate"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="text-center font-normal"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.error }">{{
                formatDate(slotProps.data.payDate)
              }}</span>
            </template>
          </PvColumn>
          <PvColumn
            field="paidBy"
            :header="lang.columnCustomerName"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="font-normal"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.error }">{{
                slotProps.data.paidBy
              }}</span>
            </template>
          </PvColumn>
          <PvColumn
            field="paidAddress"
            :header="lang.columnCustomerAddressNo"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left font-normal"
            bodyClass="font-normal"
          >
            <template #body="slotProps">
              <span :class="{ 'p-error': slotProps.data.error }">{{
                slotProps.data.paidByAddress
              }}</span>
            </template>
          </PvColumn>
          <PvColumn
            style="min-width: 6rem; height: 56px"
            headerClass="h-left"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-2 px-2 mr-2"
                @click="gotoDetails(slotProps.data.details)"
              >
                <icon-eye
                  iconColor="secondary"
                  width="24"
                  height="24"
                ></icon-eye>
              </PvButton>
              <!-- <PvButton
                type="button"
                class="p-button-primary"
                :label="lang.import"
                @click="importSelected(slotProps.data)"
              ></PvButton> -->
            </template>
          </PvColumn>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import ImportReceiptDetailDialog from "../../../components/AR/receipt/ImportReceiptDetailDialog.vue";
export default {
  //   props: ["receipts", "open"],
  emits: ["close"],
  components: {
    ImportReceiptDetailDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.receipt.import,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.receipt.import,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
    };
  },
  data() {
    return {
      isLoading: false,
      selectedReceipt: null,
      titleValidateImportData: "",
      titleHeaderImportData: "",
      dataButtonDialog: [
        {
          id: "close",
          caption: this.lang.confirm,
          class: "w-full",
          action: "close",
        },
      ],
      showValidateDialog: false,

      isShowUploading: false,
      uploadingData: [],
      itemTotal: 0,
      itemCount: 0,
      percentage: 0,

      uniqueKeyId: "",
      isLock: true,
      baseDialogClassTitle: "",
      showProgressBar: false,

      details: [],

      showReceiptDetailDialog: false,
      receiptDetails: [],
    };
  },
  async created() {
    await this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "ar-receipt"});
    },
    async initiateData() {
      (this.selectedReceipt = null), (this.uniqueKeyId = "");
      this.baseDialogClassTitle = "";
      this.isLock = true;
      this.titleValidateImportData = "";
      this.titleHeaderImportData = this.lang.notificationDialogHeader;
      this.showValidateDialog = false;
    },
    async getUniqueKey() {
      try {
        await this.$store.dispatch("arReceipt/getReceiptUniqueKey");

        if (this.receiptUniqueKey) {
          this.uniqueKeyId = this.receiptUniqueKey.id;
        }
      } catch (error) {
        this.titleHeaderImportData = this.lang.notificationDialogHeader;
        this.titleValidateImportData = error;
        this.showValidateDialog = true;
      }
    },
    validateData() {
      if (!this.selectedReceipt) {
        this.titleHeaderImportData = this.lang.notificationDialogHeader;
        this.titleValidateImportData = this.lang.pleaseSelectItem;
        this.showValidateDialog = true;
        return true;
      }
      return false;
    },
    // async mappingInvoice() {
    //   for (let data = 0; data < this.selectedReceipt.length; data++) {
    //     try {
    //       let receipt = this.selectedReceipt[data];
    //       let rowNumber = parseInt(data);
    //       let firstData = this.selectedReceipt[rowNumber];

    //       let detail = {
    //         unitCode: firstData.unitCode,
    //         productCode: firstData.productCode,
    //         period: firstData.period,
    //         description: firstData.description,
    //         totalAmount: firstData.totalAmount,
    //       };
    //       this.details.push(detail);

    //       let nextItemNo = parseInt(data) + 1;

    //       for (let i = nextItemNo; i < this.selectedReceipt.length; i++) {
    //         let nextItem = this.selectedReceipt[i];

    //         if (
    //           receipt.unitCode == nextItem.unitCode &&
    //           receipt.receiptDate == nextItem.receiptDate &&
    //           receipt.payType == nextItem.payType &&
    //           receipt.payCode == nextItem.payCode &&
    //           receipt.bookBankCode == nextItem.bookBankCode &&
    //           receipt.payDate == nextItem.payDate
    //         ) {
    //           let detail = {
    //             unitCode: nextItem.unitCode.toString(),
    //             productCode: nextItem.productCode,
    //             period: nextItem.period,
    //             description: nextItem.description,
    //             totalAmount: nextItem.totalAmount,
    //           };
    //           this.details.push(detail);
    //           data++;
    //         }
    //       }

    //       // console.log("this.details", this.details);

    //       let payload = {
    //         projectId: this.selectedProject.id,
    //         unitId: receipt.unitId,
    //         unitCode: receipt.unitCode,
    //         unitAddressNo: receipt.unitAddressNo,
    //         receiptDate: this.formatDateToISOString(receipt.date),
    //         referenceCode: receipt.refCode ? receipt.refCode : "",
    //         customerId: receipt.customerId,
    //         customerName: receipt.customerName,
    //         payType: receipt.payType,
    //         payCode: receipt.payCode ? receipt.payCode : "",
    //         bookBankCode: receipt.payBookBankCode
    //           ? receipt.payBookBankCode
    //           : "",
    //         bankName: receipt.payBookBankBranch
    //           ? receipt.payBookBankBranch
    //           : "",
    //         payDate: this.formatDateToISOString(receipt.payDate),
    //         paidBy: receipt.PaidBy ? receipt.PaidBy : "",
    //         paidAddress: receipt.PaidAddress ? receipt.PaidAddress : "",
    //         details: this.details,
    //       };

    //       // console.log("payload", payload);

    //       await this.$store.dispatch(
    //         "arReceipt/ImportReceiptMappingInvoice",
    //         payload
    //       );

    //       this.details = [];
    //     } catch (error) {
    //       this.titleHeaderImportData = this.lang.notificationDialogHeader;
    //       this.titleValidateImportData = error;
    //       this.showValidateDialog = true;
    //       return;
    //     }
    //   }
    // },
    closeDialog() {
      this.showValidateDialog = false;
      this.showProgressBar = false;
    },
    importSelected(data) {
      // console.log("importSelected", data);
      this.selectedReceipt = [];
      this.selectedReceipt.push(data);
      // this.importAll(false);
    },
    async importAll() {

      let isSuccess = false;

      this.showProgressBar = true;
      for (const data of this.receipts) {
        // console.log("data", data);
        isSuccess = await this.addReceipt(data);
        //หากมีรายดารใด addReceipt ไม่สำเร็จก็จะหยุดทันที
        if(!isSuccess)
        {
          return
        }
      }

      if (isSuccess) {
        this.showProgressBar = false;
        this.baseDialogClassTitle = "p-text-success";
        this.titleHeaderImportData = this.lang.importReceiptSuccess;
        this.titleValidateImportData = "";
        this.showValidateDialog = true;
      }
    },
    async addReceipt(data) {
      try {
        await this.getUniqueKey();
        data.status = "waiting";
        let details = [];

        for (const receiptDetail of data.details) {
          let detail = {
            productId: receiptDetail.productId,
            productUnitId: receiptDetail.productUnitId,
            unitPrice: receiptDetail.unitPrice,
            quantity: receiptDetail.quantity,
            discountDescription: receiptDetail.discountDescription,
            discountAmount: receiptDetail.discountAmount,
            totalAmount: receiptDetail.totalAmount,
            remark: receiptDetail.remark,
            period: receiptDetail.period,
            referenceId: receiptDetail.referenceId,
            tenantId: receiptDetail.tenantId,
            invoiceAmount: receiptDetail.invoiceAmount,
          };
          details.push(detail);
        }

        let refCode = this.isLock == true ? "Y" : "N";
        let payBookBankBranch = "";
        if (data.branch) {
          payBookBankBranch = data.branch;
        } else if (data.bankName || data.bookBankBranch) {
          let bankName = data.bankName ? data.bankName : "";
          let bookBankBranch = data.bookBankBranch ? data.bookBankBranch : "";
          payBookBankBranch = bankName + " : " + bookBankBranch;
        }

        let payload = {
          projectId: this.selectedProject.id,
          refCode: refCode,
          date: this.formatDateToISOString(new Date(data.receiptDate)),
          customerId: data.customerId,
          employeeId: this.employee.id,
          paidBy: data.paidBy,
          paidByAddress: data.paidAddress,
          unitId: data.unitId,
          payType: data.payType,
          payReference: data.payCode,
          payBookBankCode: data.bookBankCode,
          payBookBankBranch: payBookBankBranch,
          payDate: this.formatDateToISOString(new Date(data.payDate)),
          remark3: "",
          remark4: "",
          shouldCreateCheque: false,
          shouldUseDeposit: data.payType == "Deposit" ? true : false,
          shouldUseGuarantee: false,
          guaranteeDetailId: "",
          receiptUniqueKeyId: this.uniqueKeyId,
          details: details,
          type: "Add",
        };

        // console.log("payload", payload);

        await this.$store.dispatch("arReceipt/createOrUpdateReceipt", payload);

        data.markStatus = "1";
        data.status = "success";
        return true;
      } catch (error) {
        
        this.titleHeaderImportData = this.lang.notificationDialogHeader;
        this.titleValidateImportData = error;
        this.showValidateDialog = true;
        data.status = this.lang.error;
        return false;
      }
    },
    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    gotoDetails(details) {
      // console.log("receipt details", details);
      this.receiptDetails = details;
      this.showReceiptDetailDialog = true;
    },
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    employee() {
      return this.$store.getters.employee;
    },
    receipts() {
      return this.$store.getters["arReceipt/importReceiptList"];
    },
    receiptUniqueKey() {
      return this.$store.getters["arReceipt/receiptUniqueKey"];
    },
    valueObj() {
      if (this.receipts) {
        return this.receipts;
      }
      return null;
    },
  },
};
</script>
