export default {
	title: "Customer Group",
	createCustomerGroup: "Create Customer Group",
	columnCode: "Code",
	columnName: "Name",
	editButton:"Setting",
	columnActiveStatus: "Type",
	tagActive: "Active",
	tagInActive: "InActive",
};
