export default {
  async getUnitResidents(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Unit/ResidentLeftInclude";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }
    url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get all unit resident"
      );
      throw error;
    }
    // console.log("getUnitResidents", responseData);

    // const data = responseData.data;

    context.commit("setUnitResident", responseData);
  },
  async createOrUpdateUnitResident(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Unit/${payload.type}`;
    // let url = process.env.VUE_APP_BACKEND_URL + `/Unit/AddResident`;

    const fallbackErrorMessage = `Failed to ${payload.type} unit resident`
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: payload.type === 'AddResident' ? 'post' : 'put',
      // method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }

    context.commit("setSelectedUnitResident", responseData.data);

  },
  resetUnitResidents(context) {
    context.commit("resetUnitResident", null);
  },
  async getAllUnitResidentsByCustomerId(context, payload) {
    while (!context.getters.unitResidentIsLastPage) {
      let where = {
        projectId: payload.projectId,
        customerId: payload.customerId,
      };
      const setPayload = {
        page: context.getters.unitResidentNextPage || 1,
        where,
      };
      await context.dispatch("getUnitResidentsByCustomerId", setPayload);
    }
  },
  async getUnitResidentsByCustomerId(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Unit/AllResident";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }
    url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get all unit resident"
      );
      throw error;
    }
    // console.log("getUnitResidents", responseData);

    // const data = responseData.data;

    context.commit("setUnitResidents", responseData);
  },
};
