export default {  
  transferSlips(state) {
    return state.transferSlips;
  },
  transferSlip(state) {
    return state.transferSlip;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
};
