export default {
  refundGuaranteeList(state) {
    return state.refundGuaranteeList;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
  selectedRefundGuarantee(state) {
    return state.selectedRefundGuarantee;
  },
  blob(state) {
    return state.blob;
  },
  pendingList(state) {
    return state.pendingList;
  },
  pendingListTotalPage(state) {
		return state.pendingListTotalPage;
	},
	pendingListCurrentPage(state) {
		return state.pendingListCurrentPage;
	},
	pendingListNextPage(state) {
	  return state.pendingListCurrentPage + 1;
	},
	pendingListIsLastPage(_, getters) {
	  return getters.pendingListCurrentPage === getters.pendingListTotalPage && getters.pendingListTotalPage !== 0;
	},
};
