export default {
	accountBooks(state) {
		return state.accountBooks;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	selectedAccountBook(state) {
		return state.selectedAccountBook;
	},
	accountBookNextPage(state) {
		return state.currentPage + 1;
	},
	accountBookIsLastPage(_, getters) {
		return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
	},
};
