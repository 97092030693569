<template>
  <base-toolbar :title="lang.toolbarTitle" :hideBackButton="isHideBack" @goBack="goBack()"></base-toolbar>
  <base-dialog 
    v-model:visible="showDialog" 
    :titleConfirm="dialogTitle" 
    :wordingConfirm="dialogContent"
    :classTitle="dialogClassTitle"
    :dataButtons="dialogDataButtons"
    @confirm-cancel-queue="cancelQueue" 
    @close="closeDialog"
  ></base-dialog>
  <base-container>
    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5">
      <div class="justify-content-between align-items-center">
        <h2 :class="textHeaderClass" class="mt-0 mb-2">
          {{ lang.title }}
        </h2>
        <label class="font-md font-normal p-text-secondary">{{
          lang.subTitle
        }}</label>
      </div>
      <PvDivider></PvDivider>
      <PvDataTable :value="requestsList" dataKey="id" v-model:selection="selectedRequestData" selectionMode="single"
        responsiveLayout="scroll" :class="textTableClass" class="p-datatable-xs" :loading="isLoading">
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn field="itemNo" :header="lang.columnItemNo" headerClass="h-left" bodyClass="text-left"
          style="height: 56px; min-width: 3rem" />

        <PvColumn field="name" :header="lang.columnRequestName" headerClass="h-left" bodyClass="text-left"
          style="height: 56px; min-width: 15rem" />

        <PvColumn field="createDate" :header="lang.columnRequestDate" headerClass="h-left" bodyClass="text-left"
          style="height: 56px; min-width: 10rem">
          <template #body="slotProps">
            {{ formatDateTime(slotProps.data.createDate) }}
          </template>
        </PvColumn>

        <PvColumn field="userName" :header="lang.columnRequestUser" headerClass="h-left" bodyClass="text-left"
          style="height: 56px; min-width: 8rem" />

        <PvColumn field="status" :header="lang.columnRequestStatus" headerClass="h-left" bodyClass="text-left"
          style="height: 56px; min-width: 4rem">
          <template #body="slotProps">
            <base-tag 
              :Caption="lang.tagQueuing" 
              Class="primary" 
              v-if="slotProps.data.status == 'Queuing' 
                && slotProps.data.cancelStatus != 'Waiting'" 
            />
            <base-tag 
              :Caption="lang.tagProcessing" 
              Class="warning" 
              v-else-if="slotProps.data.status == 'Processing' 
                && slotProps.data.cancelStatus != 'Waiting'" 
            />
            <base-tag 
              :Caption="lang.tagSuccess" 
              Class="success" 
              v-else-if="slotProps.data.status == 'Success' 
                && slotProps.data.cancelStatus != 'Waiting'" 
            />
            <base-tag 
              :Caption="lang.tagError" 
              Class="error" 
              v-else-if="slotProps.data.status == 'Error' 
              && slotProps.data.cancelStatus != 'Waiting'" 
            />
            <base-tag 
              :Caption="lang.tagCancelQueue" 
              Class="secondary" 
              v-else-if="slotProps.data.cancelStatus == 'Waiting'" 
            />
          </template>
        </PvColumn>

        <PvColumn field="queue" :header="lang.columnRequestQueue" headerClass="h-left" bodyClass="text-left"
          style="height: 56px; min-width: 4rem">
          <template #body="slotProps">
            <label>{{
              slotProps.data.queueType +
              slotProps.data.queue.toString().padStart(3, "0")
            }}</label>
          </template>
        </PvColumn>

        <PvColumn headerClass="h-left" bodyClass="text-left" style="min-width: 10rem">
          <template #body="slotProps">
            <PvButton type="button" class="p-button p-button-primary py-2" :loading="slotProps.data.isFileLoading"
              @click="previewFile(slotProps.data)" v-if="
                slotProps.data.status == 'Success' &&
                slotProps.data.fileKey.toLowerCase().includes('.pdf')
              ">
              <icon-document-search v-if="!slotProps.data.isFileLoading" iconColor="white" width="16" height="16" />
              <i v-else class="pi pi-spin pi-spinner font-normal"></i>
              <span class="font-normal pl-2">{{ lang.preview }}</span>
            </PvButton>
            <PvButton type="button" class="p-button p-button-primary py-2" :loading="slotProps.data.isFileLoading"
              @click="downloadFile(slotProps.data)" v-else-if="slotProps.data.status == 'Success'">
              <icon-download v-if="!slotProps.data.isFileLoading" iconColor="white" width="16" height="16" />
              <i v-else class="pi pi-spin pi-spinner font-normal"></i>
              <span class="font-normal pl-2">{{ lang.download }}</span>
            </PvButton>
            <span v-else-if="
              slotProps.data.status == 'Error' &&
              slotProps.data.errorCode == '103'
            ">
              {{
                lang.errorRowExceed.replace(
                  ":row",
                  splitRowExceedMessage(slotProps.data.errorMessage)
                )
              }}
            </span>
            <PvButton type="button" class="p-button p-button-outlined p-button-primary py-2"
              @click="retryRequest(slotProps.data.id)" v-else-if="slotProps.data.status == 'Error'">
              <icon-refresh-loading iconColor="primary" width="16" height="16" />
              <span class="font-normal pl-2">{{ lang.retryRequest }}</span>
            </PvButton>

            <span v-else class="font-normal">
              {{
                lang.tagWithQueue.replace(
                  ":queue",
                  calculateQueue(slotProps.data)
                )
              }}
            </span>
          </template>
        </PvColumn>
        <PvColumn
          style="min-width: 2rem"
          headerClass="h-center"
          bodyStyle="justify-content: end; align-items: center;"
          alignFrozen="right"
          frozen
        >
          <template #body="slotProps">
            <PvButton
              type="button"
              class="p-button-danger p-button-text py-0"
              icon="pi pi-times"
              @click="askCancelQueue(slotProps.data.id)"
              v-tooltip.top="{
                value: lang.cancelQueue,
                class: 'custom-tooltip',
              }"
              v-if="slotProps.data.status == 'Queuing' && slotProps.data.cancelStatus != 'Waiting'"
            ></PvButton>
          </template>
        </PvColumn>
        <template #footer>
          <PvPaginator :rows="rowsPerPage" :totalRecords="totalRecords" @page="onPageChange($event)"
            :rowsPerPageOptions="[10, 50, 100]">
            <template #start="slotProps">
              {{ lang.page }}:
              {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
            </template>
          </PvPaginator>
        </template>
      </PvDataTable>
    </div>
  </base-container>
</template>
<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetFormPrint from "../../hooks/getFormPrint.js";

export default {
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textTableClass } = useTextStyle();
    const { formatDateTime, formatNumber } = useFormat();
    const isHideBack = inject("isHideBack");
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.reportRequest };
      } else {
        return { ...lang.en.base, ...lang.en.reportRequest };
      }
    });

    const { getPreviewBlob, getDownloadBlob } = useGetFormPrint();

    return {
      lang: mergeLang,
      textHeaderClass,
      textTableClass,
      formatDateTime,
      getPreviewBlob,
      getDownloadBlob,
      isHideBack,
      formatNumber
    };
  },
  data() {
    return {
      isLoading: true,
      selectedRequestData: null,
      requestsList: [],
      selectedPage: 1,
      rowsPerPage: 10,
      showDialog: false,
      dialogTitle: "",
      dialogContent: "",
      dialogClassTitle: "",
      actionDataButton: "",
      dataQueuing: [],
      prevRoute: null,
      intervalID: null,
      cancelRequestId: 0,
    };
  },
  async created() {
    // if (this.$route.query.back == "home" && this.isHideBack == false) {
    //   this.isHideBack == false;
    // }
    this.getCurrentQueue();
    await this.getRequestsList();
    if (this.dataQueuing.length > 0) {
      this.loopGetRequestsList();
    }
    let element = document.getElementsByTagName("body")[0];
    element.style.overflow = "scroll";
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      // console.log(this.$route.name);
      // console.log(this.$route.query);
      clearInterval(this.intervalID);
      if (this.$route.query.back == "home") {
        this.$router.push({ name: this.$route.query.back });
      } else {
        this.$router.push({ path: this.$route.query.back });
      }
    },
    loopGetRequestsList() {
      this.executeLoop();
      this.intervalID = setInterval(() => {
        this.executeLoop();
      }, 5000);
    },
    executeLoop() {
      if (this.dataQueuing.length == 0) {
          clearInterval(this.intervalID);
        } else {
          this.getCurrentQueue();
          this.dataQueuing.forEach((element) => {
            this.getRequestById(element.id);
          });
        }
    },
    async getRequestsList() {
      try {
        this.isLoading = true;
        const payload = {
          projectId: this.selectedProject.id,
          userId: this.userId,
          page: this.selectedPage,
          limit: this.rowsPerPage,
        };

        await this.$store.dispatch("reportRequests/getReportRequests", payload);
        await this.updateData();
        this.requestsList = this.requests;
        this.checkStatus();
        this.isLoading = false;
      } catch (error) {
        this.showErrorDialog(error);
        this.isLoading = false;
      }
    },
    async getRequestById(id) {
      try {
        const payload = {
          id: id,
        };
        await this.$store.dispatch(
          "reportRequests/getReportRequestById",
          payload
        );
        if (this.selectedRequests.cancelStatus === "Canceled") {
          clearInterval(this.intervalID);
          await this.getRequestsList();
          if (this.dataQueuing.length > 0) {
            this.loopGetRequestsList();
          }
        } else {
          this.requestsList.forEach((req) => {
            if (req.id === this.selectedRequests.id) {
              req.status = this.selectedRequests.status;
              req.fileBucket = this.selectedRequests.fileBucket;
              req.fileKey = this.selectedRequests.fileKey;
              req.queue = this.selectedRequests.queue;
              req.errorCode = this.selectedRequests.errorCode;
              req.errorMessage = this.selectedRequests.errorMessage;
              req.name = this.selectedRequests.name;
              req.cancelStatus = this.selectedRequests.cancelStatus;
            }
          });
          this.checkStatus();
        }
        this.isLoading = false;
      } catch (error) {
        this.showErrorDialog(error);
      }
      this.isLoading = false;
    },
    async getCurrentQueue() {
      try {
        await this.$store.dispatch("reportRequests/getCurrentQueue");
      } catch (error) {
        this.showErrorDialog(error);
      }
    },
    async updateData() {
      let userIds = [];
      if (this.requests) {
        this.requests.forEach((element) => {
          userIds.push(element.userId);
        });
      }
      await this.getEmployeesByUserIds(userIds);

      if (this.requests) {
        let i = 0;
        this.requests.forEach((element) => {
          element.userName = this.employeeNames[i];
          i++;
        });

        let number =
          this.totalRecords - this.rowsPerPage * (this.currentPage - 1);
        this.requests.forEach((element) => {
          element.itemNo = number;
          number--;
        });
      }
    },
    async getEmployeesByUserIds(userIds) {
      try {
        await this.$store.dispatch("shared/getEmployeesByUserIds", {
          ids: userIds,
        });
      } catch (error) {
        this.showErrorDialog(error);
      }
    },
    checkStatus() {
      this.dataQueuing = this.requestsList.filter(
        (request) =>
          request.status === "Queuing" || request.status === "Processing"
      );
      // console.log(this.dataQueuing.length);
    },
    async previewFile(data) {
      // console.log("previewFile", data);
      let payload = {
        url: data.fileBucket + "/" + data.fileKey,
      };
      let dataIndex = this.requestsList.findIndex((c) => c.id === data.id);
      this.requestsList[dataIndex].isFileLoading = true;
      await this.$store.dispatch("reportRequests/getFile", payload);
      this.requestsList[dataIndex].isFileLoading = false;
      this.getPreviewBlob(this.fileBlob);
    },
    async downloadFile(data) {
      // console.log("downloadFile", data);
      const d = new Date();
      let dateText = d.toISOString();

      let filename =
        data.name != "" && data.name != ".xlsx" && data.name != ".pdf"
          ? data.name
          : `${dateText}_${data.fileKey}`;

      let payload = {
        url: data.fileBucket + "/" + data.fileKey,
        fileName: filename
      };
      let dataIndex = this.requestsList.findIndex((c) => c.id === data.id);
      this.requestsList[dataIndex].isFileLoading = true;
      
      // if(this.requestsList[dataIndex].fileKey.toLowerCase().includes('.zip'))
      // {
      //   try {
      //     await this.$store.dispatch("reportRequests/getPresignedUrl", payload);        
      //     if(this.presignedUrl){
      //       this.getDownloadBlob(filename, this.presignedUrl);
      //     } else {
      //       this.showErrorDialog = true;
      //       this.confirmErrorTitle = this.lang.notificationDialogHeader;
      //       this.confirmErrorWording = this.lang.baseError;
      //     }
      //     await this.$store.dispatch("reportRequests/resetPresignedUrl");
      //   } catch (error) {
      //     this.showErrorDialog = true;
      //     this.confirmErrorTitle = this.lang.notificationDialogHeader;
      //     this.confirmErrorWording = error;
      //   }
      //   this.requestsList[dataIndex].isFileLoading = false;
        
      // } else {
      //   await this.$store.dispatch("reportRequests/getFile", payload);
      //   this.requestsList[dataIndex].isFileLoading = false;
      //   await this.getDownloadBlob(filename, this.fileBlob);
      // }
      try {
        await this.$store.dispatch("reportRequests/getPresignedUrl", payload);        
        if(this.presignedUrl){
          // console.log(filename);
          this.getDownloadBlob("", this.presignedUrl);
        } else {
          this.showErrorDialog(this.lang.baseError);
        }
      } catch (error) {
        this.showErrorDialog(error);
      }
      await this.$store.dispatch("reportRequests/resetPresignedUrl");
      this.requestsList[dataIndex].isFileLoading = false;
    },
    async retryRequest(id) {
      // console.log("retryRequest", id);
      try {
        // await this.$store.dispatch("produces/retryInvoicePrintOrderChanges", id);
        await this.$store.dispatch("produces/retryPrintOrder", id);
        await this.getRequestById(id);
        if (this.dataQueuing.length > 0 && this.intervalID == null) {
          this.loopGetRequestsList();
        }
      } catch (error) {
        this.showErrorDialog(error);
      }
    },
    async onPageChange(event) {
      this.selectedPage = event.page + 1;
      this.rowsPerPage = event.rows;
      this.getCurrentQueue();
      await this.getRequestsList();
      if (this.dataQueuing.length > 0) {
        this.loopGetRequestsList();
      }
    },
    calculateQueue(data) {
      if(this.currentQueue.length > 0) {
        let findQueue = this.currentQueue.find((queue) => {
          return queue.queueType === data.queueType && data.queue >= queue.currentQueue;
        });
        if (findQueue) {
          return Math.max(0, data.queue - findQueue.currentQueue);
        }
      }
      return 0
    },
    splitRowExceedMessage(text) {
      let strings = text.split(":");
      if (strings.length === 3) {
        return this.formatNumber(strings[1]);
      }
      return 0;
    },
    showErrorDialog(error) {
      this.showDialog = true;
      this.dialogTitle = this.lang.notificationDialogHeader;
      this.dialogContent = error;
      this.dialogClassTitle = "error";
      this.actionDataButton = "";
      this.isLoading = false;
    },
    showSuccessDialog(title, content) {
      this.showDialog = true;
      this.dialogTitle = title;
      this.dialogContent = content;
      this.dialogClassTitle = "p-text-success";
      this.actionDataButton = "";
    },
    closeDialog() {
      this.showDialog = false;
      this.dialogTitle = this.lang.notificationDialogHeader;
      this.dialogContent = "";
      this.dialogClassTitle = "";
      this.actionDataButton = "";
    },
    askCancelQueue(id) {
      this.cancelRequestId = id;
      this.actionDataButton = "confirm-cancel-queue";
      this.dialogTitle = this.lang.cancelQueue;
      this.dialogContent = this.lang.dialogContentCancelQueue.replace("{0}",this.selectedRequests.name);
      this.dialogClassTitle = "";
      this.showDialog = true;
    },
    async cancelQueue() {
      try {
        this.isLoading = true;
        let payload = {
          id: this.cancelRequestId,
          cancelStatus: "Waiting",
        };
        await this.$store.dispatch("reportRequests/cancelReportRequest", payload);
        this.requestsList.forEach((req) => {
          if (req.id === this.selectedRequests.id) {
            req.cancelStatus = this.selectedRequests.cancelStatus;
          }
        });
        this.showSuccessDialog(this.lang.cancelSuccess, this.lang.dialogContentCancelSuccessful);
        this.isLoading = false;
      } catch (error) {
        this.showErrorDialog(error);
      }
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    employeeNames() {
      return this.$store.getters["shared/employeesByUserIds"];
    },
    totalPage() {
      return this.$store.getters["reportRequests/totalPage"];
    },
    currentPage() {
      return this.$store.getters["reportRequests/currentPage"];
    },
    totalRecords() {
      return this.$store.getters["reportRequests/totalItem"];
    },
    requests() {
      return this.$store.getters["reportRequests/requests"];
    },
    selectedRequests() {
      return this.$store.getters["reportRequests/selectedRequests"];
    },
    userId() {
      return this.$store.getters.userId;
    },
    fileBlob() {
      return this.$store.getters["reportRequests/blob"];
    },
    currentQueue() {
      return this.$store.getters["reportRequests/currentQueue"];
    },
    presignedUrl() {
      return this.$store.getters["reportRequests/presignedUrl"];
    },
    dialogDataButtons() {
      let data = [
        {
          id: "close",
          caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
      if (this.actionDataButton == "confirm-cancel-queue") {
        data = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "confirm",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "confirm-cancel-queue",
          },
        ];
      }
      return data;
    },
  },
};
</script>
