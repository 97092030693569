export default {
    availableDepositByUnit(state){
      return state.availableDepositByUnit;
    },
    deposits(state) {
      return state.deposits;
    },
    totalPage(state) {
      return state.totalPage;
    },
    currentPage(state) {
      return state.currentPage;
    },
    rows(state) {
      return state.rows;
    },
    selectedDeposit(state){
      return state.selectedDeposit;
    },
    depositReferenceDocument(state) {
      return state.depositReferenceDocument;
    },
    unitExistDeposits(state) {
      return state.unitExistDeposits;
    },
  };
  