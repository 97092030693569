export default {
  setRoleByMenu(state, payload) {
    let roleByMenus = [...state.roleByMenu.roleByMenus];
    for (const roleByMenu of payload.roleByMenus) {
      if (roleByMenus.findIndex((c) => c.menuId === roleByMenu.menuId) < 0) {
        roleByMenus.push(roleByMenu);
      }
    }
    state.roleByMenu.roleByMenus = roleByMenus;
    state.roleByMenu.currentPage = payload.currentPage;
    state.roleByMenu.totalPage = payload.totalPage;
  },
  resetRoleByMenu(state) {
    const roleByMenu = {
      roleByMenus: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.roleByMenu = roleByMenu;
  },
};