export default {
  stockKeycards(state) {
    return state.stockKeycards;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
  selectedStockKeycard(state) {
    return state.selectedStockKeycard;
  },
};
