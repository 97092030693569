export default {
    setUnitResident(state, payload) {
      state.unitResidents = payload.data;
      state.totalPage = payload.page.pageCount;
      state.currentPage = payload.page.currentPage;
    },
    setSelectedUnitResident(state, payload) {
      state.selectedUnitResident = payload;
    },
    resetUnitResident(state) {
      state.unitResidents = [];
      state.totalPage = 0;
      state.currentPage = 0;
    },
    setUnitResidents(state, payload) {
      // state.project.length = 0;
      let unitResidents = [...state.unitResidents];
      for (const unitResident of payload.data) {
        if(unitResidents.findIndex(c => c.id === unitResident.id) < 0){
          unitResidents.push(unitResident);
        }
      }

      state.unitResidents = unitResidents;
      state.totalPage = payload.page.pageCount;
      state.currentPage = payload.page.currentPage;
    },
  };
  