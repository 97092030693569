export default {
  async getDebtFreePrints(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Debtfree/DebtfreePrint";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }
    if (payload.page) {
      url = url + "&page=" + payload.page;
    }
    if (payload.projectId) {
      url = url + "&projectId=" + payload.projectId;
    }
    if (payload.dateStart) {
        url = url + "&dateStart=" + payload.dateStart;
    }
    if (payload.dateEnd) {
        url = url + "&dateEnd=" + payload.dateEnd;
    }
    if (payload.unitCodeAndAddressNo) {
        url = url + "&unitCodeAndAddressNo=" + payload.unitCodeAndAddressNo;
    }
    if (payload.ownerName) {
        url = url + "&ownerName=" + payload.ownerName;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get debt free print.");
      throw error;
    }

    // console.log("debtFreePrints",responseData);

    context.commit("setDebtFreePrints", responseData);
  },
  async printDebtFree(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL +"/Debtfree/Print";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(payload),
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      
    });  
    
    let urlBlob = ''
    let blob = await response.blob();
    urlBlob = window.URL.createObjectURL(blob);
    context.commit('setBlob', urlBlob);
  },
};
