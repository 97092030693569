export default {
	titlePenaltyCalculation: "การคำนวณค่าปรับ",
	titleFineRateCalculation: "อัตราค่าปรับและการคำนวณ",	
	titleFineRate: "อัตราค่าปรับ",
	penaltyRateBefore181Day: "อัตราค่าปรับก่อน 6 เดือน",
	penaltyRateAfter180Day: "อัตราค่าปรับหลัง 6 เดือน",
	penaltyDigit: "กำหนดจำนวนหลักของทศนิยม",
	roundType: "รูปแบบการปัดเศษ",
	penaltyType: "รูปแบบวันสิ้นสุดการคำนวณค่าปรับ",
	productMethodCode: "รายรับ",
	penaltyMethodCode: "รายการค่าปรับ",

	tooltipRoundType:"แบบมาตรฐาน:(มากกว่าหรือเท่ากับ 5 ปัดขึ้น)",
	tooltipPenaltyType:"แบบปกติ:วันที่สิ้นสุดจะคำนวณเป็นวันเดียวกับวันแจ้งหนี้ *ยกเว้นวันที่ 1 ระบบจะคำนวณถึงวันที่เดือนที่ผ่านมา",
	notPenalty: "กรณีไม่ระบุอัตราคาปรับ จะใช้การคำนวณตามหลักคำนวณค่าปรับ",

    validatePenaltyRateBefore181Day: "ระบุอัตราค่าปรับ(%)",
    validatePenaltyRateAfter180Day: "ระบุอัตราค่าปรับ(%)",
    validatePenaltyDigit: "แบบมาตรฐาน",
    validateRoundType: "2 ตำแหน่ง",
    validatePenaltyType: "เลือกวันที่สิ้นสุดค่าปรับเอง",
    validateProductMethodCode: "ค่าใช้จ่ายส่วนกลาง",
    validatePenaltyMethodCode: "ค่าปรับเงินเพิ่ม - ล่าช้า",

	columnCode: "รหัส",
	columnName: "ชื่อรายรับ",
	columnPaidPriority: "ลำดับการตัดจ่าย",
	columnPrice: "ราคา",
	columnPenalty: "ค่าปรับ",
	columnEdit: "แก้ไข",
	columndateStart: "วันที่เริ่มต้น",
	columnpenaltyRate: "อัตราค่าปรับ %",
	addProduct: "เพิ่มรายรับ",

	titlePenaltyProduct: "ตั้งค่าทะเบียนรายรับ",
	productAr: "ทะเบียนรายรับ",
	addPenaltyRateStep: "เพิ่มอัตราค่าปรับ",
	productCode: "รหัสค่าปรับ",
	penaltyCode: "รายรับ",
    validateDataStartFirst: "วันที่เริ่มต้นแรก ต้องเป็น 1 เสมอ",
    validateDataStartOver: "วันที่เริ่มต้นที่ระบุ ต้องมากกว่าวันที่เริ่มต้นก่อนหน้า",
    validateDataStartLow: "วันที่เริ่มต้นที่ระบุ ต้องน้อยกว่าวันที่เริ่มต้นทัดไป",
    validateValueDataStart: "วันที่เริ่มต้น ต้องมากกว่า ",
    validateValuePenaltyRate: "อัตราค่าปรับ ต้องมากกว่า 0",
	penaltyPrintInvoice: "แสดงค่าปรับเป็นรายการในใบแจ้งหนี้",

	titleInvoiceDisplayOutstanding: "แสดงข้อมูลในใบแจ้งหนี้",
	labelInvoiceDisplayOutstanding: "แสดงรายการหนี้ค้างชำระ",
};
