export default {
	title: "รายงานสรุปการใช้น้ำ/ไฟ (ประจำเดือน)",
	downloadPDF: "Download PDF",
	downloadExcel: "Download Excel",
	reportType: "ประเภทรายงาน",
	summaryReportType: "สรุปรายเดือน",
	historyReportType: "ประวัติย้อนหลัง 4 เดือน",
	statusMeterUsage: "สถานะ",
	validateStatusMeterUsage: "กรุณาเลือกห้อง",
	statusAll: "ทั้งหมด",
	statusGreen: "ปกติ",
	statusYellow: "ผ่านการตรวจสอบ",
	statusRed: "ผิดปกติ",

	waterType: "ระบบน้ำประปา",
	electricityType: "ระบบไฟฟ้า",
	coolingAirType: "ระบบทำความเย็น",
};
