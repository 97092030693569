<template>
	<PvDialog
	:breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
	:style="{ width: '60vw' }"
	:modal="true"
	:closable="false"
	:header="reportName">
	
		<template #header>
		<div class="flex justify-content-between align-items-center">
			<div class="pt-2 pb-1">
			<label class="font-lg font-normal">
				{{ this.reportName }}
			</label>
			</div>
			<PvButton
			icon="pi pi-times"
			class="p-button-link p-icon-secondary"
			@click="closeDialog"
			/>
		</div>
		<PvDivider></PvDivider>
		</template>
		
		<transition>
			<section class="flex flex-wrap" :class="textFormClass">
				<div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
					<label for="atDate">{{ lang.atDate }}</label>
					<PvCalendar
						id="atDate"
						v-model="atDate"
						class="inputfield w-full mt-1"
						showIcon
						dateFormat="dd/mm/yy" 
						:class="{ 'p-invalid': isAtDateError }"
						@date-select="isAtDateError = false" />
						
						<small v-if="isAtDateError" id="dateAt-help" class="p-error">{{
							lang.validateDateSelect
						}}</small>
				</div>
				<div class="lg:col-0 md:col-0 col-12">
				</div>
				<div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
				<label for="fileNameText">{{ lang.fileName }}</label>
				<PvInputText
					id="fileNameText"
					type="text"
					class="inputfield w-full mt-1"
					v-model="fileNameText"
				/>
				</div>
			</section>
		</transition>
    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
        </div>
      </div>
    </template>
	
	</PvDialog>
</template>
<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import useGetFormPrint from "../../hooks/getFormPrint.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useTracking from "../../hooks/tracking";
import useReportRequestValidate from "../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
	setup() {
		const { lang } = useLang();
		const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
		const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
		const { getPreviewBlob } = useGetFormPrint();
    	const { addReportTracking } = useTracking();
		const { reportPrintOrder } = useReportRequestValidate();
		const store = useStore();
		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return {
					...lang.th.base,
					...lang.th.gl.reportGl,
				};
			} else {
				return {
					...lang.en.base,
					...lang.en.gl.reportGl,
				};
			}
		});

		return {
			lang: mergeLang,
			textHeaderClass,
			textFormClass,
			textTableClass,
			formatCurrency,
			formatDate,
			formatDateToISOString,
			getPreviewBlob,
      		addReportTracking,
			reportPrintOrder,
		};
	},
	data() {
		return {
			atDate: null,
			isAtDateError: false,
			type: "PDF",
			fileNameText: "AccountPayableValidator",
		};
	},
	async created() {
		let date = new Date();
		this.atDate = new Date(date.getFullYear(), date.getMonth(), 1);
	},
	methods: {
		setLoading(bool) {
			this.$store.dispatch("setLoading", { value: bool });
		},
		closeDialog() {
			this.$emit("close");
		},
		async download(typeReport) {
			this.type = typeReport;
			if (this.validateData()) {
				return;
			}
			this.setLoading(true);
			try {
                this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
				const response = await this.reportPrintOrder(this.payload);
				if (response) {
					this.$emit("toast-request");
				}
				this.setLoading(false);
			} catch (error) {
				this.$toast.add({
				severity: "error",
				summary: this.lang.notificationDialogHeader,
				detail: error,
				life: 3000,
				});
				this.setLoading(false);
			}
		},
		validateData() {
		if (!this.atDate) {
			this.$swal({
			icon: "warning",
			title: this.lang.validateDateSelect,
			confirmButtonText: this.lang.close,
			allowOutsideClick: false,
			}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				this.isAtDateError = true;
			}
			});
			return true;
		}

		return false;
		},
	},
	computed: {
		payload() {
			let payload = {
				FileName: this.fileNameText + this.fileType,
				JasperPath: this.jasperPath + this.fileType,
				DocType: this.type,
				UserId: this.userId,
				PrintBy: this.userName,
					ProjectId: this.selectedProject.id,
					Date: this.selectedDate,
				RequestId: 0,
				ReportName: "ReportAccountPayableValidator",
			};

			// let parameter = {     
			// 	ProjectId: this.selectedProject.id,
			// 	Date: this.selectedDate,
			// };
			// let payload = {
			// 	docType: this.type,
			// 	path: this.jasperPath + this.fileType,
			// 	fileName: this.fileNameText,
			// 	parameter: JSON.stringify(parameter),
			// };
			return payload;
		},
		selectedProject() {
			return this.$store.getters.selectedProject;
		},
		userId() {
			return this.$store.getters.userId;
		},
		userName() {
			return this.$store.getters.userName;
		},
		reportName() {
			if (this.lang.language === "Thai") {
				return this.reportObj.nameThai;
			} else {
				return this.reportObj.nameEnglish;
			}
		},
		selectedDate() {
			let atDate = null;
			if (this.atDate) {
				atDate = this.formatDateToISOString(this.atDate);
			}
			return atDate;
		},
		fileType() {
			let fileType = this.type == "PDF" ? ".pdf" : ".xlsx";
			return fileType;
		},
		jasperPath() {
			let result = this.reportObj.path ;
			if(this.type == "EXCEL" ){
				result = this.reportObj.path + "_excel";      
			}
			return result;
		},
		fileBlob() {
			return this.$store.getters["shared/blob"];
		},
	},
};
</script>
