<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5576 11.1104L12.0001 14.6678L8.44263 11.1104"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.0002 3.99707V14.6685"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.0035 16.4463C20.0035 18.411 18.4108 20.0038 16.446 20.0038H7.55431C5.58957 20.0038 3.99683 18.411 3.99683 16.4463"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 14,
    },
    height: {
      type: [Number, String],
      default: 14,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -4px !important;
  margin-right: 4px;
}
</style>
