export default {
    setParams(state, payload) {
        state.params = payload;
    },
    setImportExistSuppliers(state, payload) {
        state.importExistSuppliers = [
            ...state.importExistSuppliers, 
            ...payload,
        ];
    },
    resetImportExistSuppliers(state) {
        state.importExistSuppliers = [];
    },
}