export default {
    bookBankArs(state) {
      return state.bookBankAr.bookBankArs;
    },
    bookBankArCurrentPage(state) {
      return state.bookBankAr.currentPage; 
    },
    bookBankArNextPage(state) {
      return state.bookBankAr.currentPage + 1;
    },
    bookBankArTotalPage(state) {
      return state.bookBankAr.totalPage;
    },
    bookBankArIsLastPage(_, getters) {
      return (getters.bookBankArCurrentPage === getters.bookBankArTotalPage) && (getters.bookBankArTotalPage !== 0);
    }
};
  