export default {
    async getOutstandingList(context, payload) {
        let url = process.env.VUE_APP_BACKEND_URL + "/Dashboard/Outstanding";

        if (payload.projectId) {
            url = url + "?projectId=" + payload.projectId;
        }
        url = encodeURI(url);
        
        const token = context.rootGetters.token;

        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        });

        const responseData = await response.json();

        if (!response.ok) {
            // console.log(responseData);
            const error = new Error(
                responseData.message || "Failed to get dashboard outstanding list"
            );
            throw error;
        }
        // console.log("getOutstandingList",responseData);
        context.commit("setOutstandingList", responseData);
    },
    async getResidentList(context, payload) {
        let url = process.env.VUE_APP_BACKEND_URL + "/Dashboard/Resident";

        if (payload.projectId) {
            url = url + "?projectId=" + payload.projectId;
        }
		url = encodeURI(url);

        const token = context.rootGetters.token;

        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        });

        const responseData = await response.json();

        if (!response.ok) {
            // console.log(responseData);
            const error = new Error(
                responseData.message || "Failed to get dashboard resident list"
            );
            throw error;
        }
        // console.log("getResidentList",responseData);
        context.commit("setResidentList", responseData);
    },
    async getParcelList(context, payload) {
        let url = process.env.VUE_APP_BACKEND_URL + "/Dashboard/Parcel";

        if (payload.projectId) {
            url = url + "?projectId=" + payload.projectId;
        }
        if (payload.cacheEnable) {
            url = url + "&cacheEnable=" + payload.cacheEnable;
        }
		url = encodeURI(url);

        const token = context.rootGetters.token;

        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        });

        const responseData = await response.json();

        if (!response.ok) {
            // console.log(responseData);
            const error = new Error(
                responseData.message || "Failed to get dashboard parcel list"
            );
            throw error;
        }
        // console.log("getParcelList",responseData);
        context.commit("setParcelList", responseData);
    },
}