export default {
  uploadFile: "Upload File",
  dragFile: "Drag drop file or ",
  addFile: "Add File",
  importFileDesc: "Please add only 1 file at a time with .txt, .bpd and with Bill Payment BOT format (256 bytes)",
  fileName: "File Name",
  validateLimitFile: "Can be upload 1 file at a time",
  validateFileType: "Can't upload file :name",
  validateImportFile: "The allowed file extensions are .txt and .bpd only",
  validateBOTFormat: "You have uploaded a file that does not conform to the banking standard.",
  pleaseReloadAgain: "Please try again",
  validateBookBank: "No account number associated with this project.",
  pleaseTryAgain: "Please try again.",
  
  title: "Bill Payment",
  wordingTitleFirst: "From :total entires, the system identifies matching data and generate receipts instantly for",
  wordingTitleSecond: " :value entires, ",
  wordingTitleThird: "you can press Import All button",
  noteUnderTitle: "For entries with Invalid status, the only option available to create by manual entry.",
  listValidation: "Validation",
  importAll: "Import All (:value)",

  statusAll: "All status",
  wait: "Wait",
  success: "Success",
  invalid: "Invalid",
  error: "Error",
  
  reason:"Reason",
  ref1: "Address No.(Ref1)",
  ref2: "Invoice Code(Ref2)",
  amount: "Amount",
	paidBy: "Paid By",
	paymentDate: "Payment Date",
  openReceipt: "Open Receipt",
  autoCreate: "Auto Create",
  manualCreate: "Manual Create",
  retry: "Retry",
  invalidUnit: "Unit Invalid",
  invalidInvoice: "Invoice Invalid",
  invalidAmount: "Invoice Amount",
  invalidDate: "Date Invalid",

  askForNextStep: "Are you sure you would like to continue or not?",
  creatingReceipt: "Generating",
  generating: "Generating...",
  createReceiptSuccess: "You have completed the task",
  paymentTime: "Payment time :time",
  wordingCreateReceiptAuto: "Out of your :total items, only :value receipts can be generated due to matching data.",
  wordingCannotCreateReceiptAuto: "Out of your :total items, a receipt could not be created because there don't have matching data.",
  askForDiscard: "Are you sure you would like to discard or not?",
  wordingDiscard: "If you discard this page, the previously uploaded items will be completely cleared.",
  askForNewImport: "Would you like to upload a new Bill payment list to replace the old one?",
  wordingNewImport: "For the receipts that have been successfully created, they will be considered as saved in the system.",
};
