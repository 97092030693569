export default { 
  setImportAssets(context, payload) {
    context.commit("setImportAssets", payload);
  },
  resetImportAssets(context) {
    context.commit("resetImportAssets");
  },
  async getAssetCodes(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Asset/GetImportExistAsset";
    const token = context.rootGetters.token;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });
    const responseData = await response.json();
    if (!response.ok) {
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || "Failed to get ImportExistAsset");
      throw error;
    }
    context.commit("setAssetCodes", responseData.data);
  },
  resetAssetCodes(context) {
    context.commit("resetAssetCodes");
  }, 
};
