<template>
  <base-dialog
    v-model:visible="showConfirmDialog"
    :titleConfirm="lang.titleConfirmDelete"
    :wordingConfirm="lang.wordingConfirmDelete"
    @confirm="confirmDelete"
    @close="close"
  ></base-dialog>

  <base-dialog
    v-model:visible="showAlertDialog"
    :titleConfirm="confirmTitle"
    :wordingConfirm="confirmWording"
    :classTitle="confirmClassTitle"
    :dataButtons="dialogDataButtons"
    @close="showAlertDialog = false"
  ></base-dialog>
  <div class="surface-card shadow-2 border-round mb-5">
    <base-card
      :titleHeader="title"
      :isVisibleExpandButton="false"
      :isHideBackground="true"
      :isVisibleCard="true"
    >
      <div
        class="dropZone"
        v-if="isShowDragDrop"
        :class="{ 'p-inputtext-disabled': isCreateDocument }"
      >
        <input
          type="file"
          id="dropZone-file"
          class="dropZone-file"
          ref="dropZoneFile"
          @change="handleFiles"
          multiple
          accept=".jpg,.png,.pdf"
          :disabled="isCreateDocument"
        />
        <div
          class="dropZone-wrapper"
          @dragenter.prevent=""
          @dragover.prevent=""
          @drop.prevent="handleFiles"
        >
          <label for="dropZone-file" :class="{ 'cursor-pointer': !isCreateDocument }">
            <div class="pt-6"></div>
            <PvButton class="p-button-rounded show-icon" disabled="disabled">
              <icon-cloud-storage-upload iconColor="white" />
            </PvButton>
            <div
              class="dropZone-label-text pt-4"
              :class="{ 'cursor-pointer': !isCreateDocument }"
            >
              <label
                for="uploadFile"
                class="font-md p-text-secondary"
                :class="{ 'cursor-pointer': !isCreateDocument }"
                >{{ lang.uploadFile }}</label
              >
            </div>
            <div
              class="font-xs p-text-secondary pt-2 pb-8"
              :class="{ 'cursor-pointer': !isCreateDocument }"
            >
              <label
                for="uploadFileDesc"
                :class="{ 'cursor-pointer': !isCreateDocument }"
              >
                {{ lang.uploadFileDesc }}
              </label>
            </div>
          </label>
        </div>
      </div>

      <div class="dropZone" v-if="isShowUploadLoading">
        <div style="width: 100%">
          <!-- <div class="pt-4"></div> -->
          <div class="pt-7">
            <label for="uploadFileDesc" class="font-md text-color-secondary">{{
              lang.uploading
            }}</label>
          </div>
          <div class="pt-4">
            <label class="font-xs text-color-secondary" for="uploadFileDesc">{{
              this.fileName
            }}</label>
          </div>
          <div class="pt-4">
            <PvProgressBar
              :value="value1"
              :showValue="false"
              style="width: 360px; height: 8px; background-color: #e9f1ff"
            ></PvProgressBar>
          </div>
        </div>
      </div>

      <div class="flex justify-content-between align-items-center pt-6">
        <label for="allDocument" class="font-md font-normal">{{
          lang.allDocument
        }}</label>
      </div>
      <div style="padding-top: 10px">
        <label for="limitDocument" class="font-xs p-text-secondary">{{
          lang.limitDocument
        }}</label>
      </div>

      <div class="p-datatable-border my-5">
        <PvDataTable
          :value="files"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
        >
          <template #empty>
            <div
              style="height: 56px"
              class="text-center flex align-items-center justify-content-center w-full"
            >
              <label class="font-normal">
                {{ lang.tableEmpty }}
              </label>
            </div>
          </template>
          <!-- <template #header>
            <label class="font-xs text-color-secondary" >&nbsp;</label>
          </template> -->
          <PvColumn style="width: 68px; height: 56px" headerClass="h-center">
            <template #body>
              <!-- <icon-documents-file-checkmark iconColor="secondary"/> -->
              <PvButton
                type="button"
                style="background: white; border: white; opacity: 1"
                disabled="disabled"
              >
                <icon-documents-file-checkmark iconColor="secondary" />
              </PvButton>
            </template>
          </PvColumn>

          <PvColumn
            field="detailName"
            :header="lang.fileName"
            style="min-width: 12rem"
            headerClass="h-left font-sm font-normal"
            bodyClass="text-left font-normal"
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                @click="openfile(slotProps.data)"
                class="p-button-link pl-0"
              >
                {{ slotProps.data.detailName }}
              </PvButton>
            </template>
          </PvColumn>

          <PvColumn
            style="width: 2rem"
            headerClass="h-center"
            bodyStyle="text-align: center; overflow: visible"
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-danger"
                style="background: white; border: white"
                @click="askForConfirmDelete(slotProps.data)"
              >
                <icon-trash-delete-bin iconColor="error" />
              </PvButton>
            </template>
          </PvColumn>
        </PvDataTable>
      </div>
    </base-card>
  </div>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
export default {
  props: ["unitId", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.projectDocument,
          ...lang.th.master.unit.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.projectDocument,
          ...lang.en.master.unit.edit,
        };
      }
    });

    const { getPreviewBlob, getDownloadBlob } = useGetFormPrint();

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getPreviewBlob,
      getDownloadBlob,
    };
  },
  data() {
    return {
      value1: 0,
      uploadFiles: [],
      isLoading: false,
      showConfirmDialog: false,
      isShowDragDrop: false,
      isShowUploadLoading: false,
      deleteId: "",
      deleteUrl: "",
      deleteName: "",
      fileName: "",
      selectedPage: 1,
      rowsPerPage: 10,
      showAlertDialog: false,
      confirmTitle: "",
      confirmWording: "",
      confirmClassTitle: "",
    };
  },
  interval: null,
  async mounted() {
    // console.log("unitId", this.unitId);

    this.isShowDragDrop = true;

    if (!this.isCreateDocument) {
      await this.getFiles();
    } else {
      await this.$store.dispatch("upload/resetFile");
    }

    // if(!this.isCreateDocument) {
    //   const where = {
    //     id: this.unitId,
    //   };
    //   this.setLoading(true);
    //   await this.$store.dispatch("unit/getUnit", {
    //     where,
    //   });
    //   this.setLoading(false);
    //   await this.getFiles();
    // }
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    close() {
      this.showConfirmDialog = false;
    },
    validateFileSize() {
      let error = "";
      for (const file of this.uploadFiles) {
        if (file.size > 5 * 1024 * 1024) {
          //ขนาดไฟล์ต้องไม่เกิน 5 MB
          // this.uploadFiles.splice(file,1);

          this.openAlertDialog({
            severity: "error",
            title: this.lang.validateFileSize.replace(":name", file.name),
            content: this.lang.validateFileSizeDetail,
          });

          error = "error";
        }
      }

      if (error === "error") return true;
      else return false;
    },
    validateFileCount() {
      let check = this.files.length + this.uploadFiles.length;
      let textError = "";
      this.uploadFiles.forEach((file) => {
        textError += file.name + ", ";
      });
      let name = textError.substring(0, textError.length - 2);
      if (check > 5) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.validateLimitDocument,
          content: this.lang.validateLimitDocumentDetail.replace(":name", name),
        });

        return true;
      }
    },
    validateFileType() {
      let error = "";
      this.uploadFiles.forEach((file) => {
        if (
          file.type != "application/pdf" &&
          file.type != "image/png" &&
          file.type != "image/jpeg"
        ) {
          this.openAlertDialog({
            severity: "error",
            title: this.lang.validateFileType.replace(":name", file.name),
            content: this.lang.validateFileTypeDetail,
          });
          error = "error";
          // return true;
        }
      });

      if (error === "error") return true;
      else return false;
    },
    async handleFiles(event) {
      if (!this.isCreateDocument) {
        const inputValue = event.target.files || event.dataTransfer.files;

        this.uploadFiles = Array.from(inputValue);

        if (this.validateFileCount()) {
          return;
        } //เช็คจำนวนไฟล์

        if (this.validateFileSize()) {
          return;
        } //เช็คขนาดไฟล์

        if (this.validateFileType()) {
          return;
        } //เช็คประเภทไฟล์

        this.$store.getters["upload/resetSelectedFile"];

        let text = "";
        this.uploadFiles.forEach((file) => {
          text += file.name + ", ";
        });
        this.fileName = text.substring(0, text.length - 2);

        this.isShowDragDrop = false;
        this.isShowUploadLoading = true;
        this.startProgress();

        let length = this.uploadFiles.length;
        let number = 0;

        try {
          for (const file of this.uploadFiles) {
            await this.$store.dispatch("upload/uploadFile", { file: file });
            let key = this.selectedFile.key;
            let fileName = this.selectedFile.fileName;
            this.value1 = 0;
            await this.addFile(file, key, fileName);

            number++;
          }
        } catch (error) {
          this.openAlertDialog({
            severity: "error",
            title: this.lang.notificationDialogHeader,
            content: error,
          });

          this.endProgress();
          this.isShowUploadLoading = false;
          this.isShowDragDrop = true;
        }

        if (number == length) {
          this.endProgress();
        }
      }
    },
    async upload() {
      for (const file of this.uploadFiles) {
        try {
          await this.$store.dispatch("upload/uploadFile", { file: file });
          let key = this.selectedFile.key;
          let fileName = this.selectedFile.fileName;
          this.value1 = 0;
          await this.addFile(key, fileName);
        } catch (error) {
          this.openAlertDialog({
            severity: "error",
            title: this.lang.notificationDialogHeader,
            content: error,
          });
        }
      }
    },
    async getFiles() {
      this.isLoading = true;

      let where = {
        projectId: this.unitId,
        type: "unit",
      };

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };

      try {
        await this.$store.dispatch("upload/getFiles", payload);
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
        });
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async addFile(file, key, fileName) {
      this.setLoading(true);

      let url = process.env.VUE_APP_BACKEND_URL + "/Aws/" + key;

      let payload = {
        projectId: this.unitId,
        type: "unit",
        url: url,
        detailName: fileName,
      };

      try {
        await this.$store.dispatch("upload/addFile", payload);
        await this.getFiles();

        this.openAlertDialog({
          severity: "success",
          title: this.lang.addFileSuccess,
          content: file.name,
        });

        this.isShowUploadLoading = false;
        this.isShowDragDrop = true;
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
        });
      }
      this.setLoading(false);
    },
    askForConfirmDelete(data) {
      this.deleteId = data.detailId;
      this.deleteUrl = data.url.substring(data.url.indexOf("Aws/") + 4);
      // console.log(this.deleteUrl);
      if (this.deleteUrl.includes("://")) {
        this.deleteUrl = null;
      }
      this.deleteName = data.detailName;
      this.showConfirmDialog = true;
    },
    confirmDelete() {
      let payload = {
        projectId: this.selectedProject.id,
        id: this.deleteId,
        key: this.deleteUrl,
      };

      this.deleteFile(payload);
      this.showConfirmDialog = false;
    },
    async deleteFile(payload) {
      this.setLoading(true);

      try {
        await this.$store.dispatch("upload/deleteFile", payload);
        await this.getFiles();

        this.openAlertDialog({
          severity: "error",
          title: this.lang.deleteFileSuccess,
          content: this.deleteName,
        });
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
        });
      }
      this.setLoading(false);
    },
    async openfile(data) {
      this.setLoading(true);

      let payload = {
        key: data.url.substring(data.url.indexOf("Aws/") + 4),
        name: data.detailName,
      };

      try {
        if (payload.key.includes("://")) {
          payload.key = null;
          await this.$store.dispatch("upload/downloadFile2", data.url);
          let blob = this.$store.getters["upload/blob"];
          const d = new Date();
          let dateText = d.toISOString();
          this.getDownloadBlob(`${data.detailName}_${dateText}.pdf`, blob);
        } else await this.$store.dispatch("upload/downloadFile", payload);
      } catch (error) {
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
        });
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    startProgress() {
      this.interval = setInterval(() => {
        let newValue = this.value1 + Math.floor(Math.random() * 50) + 1;
        if (newValue >= 100) {
          newValue = 100;
        }
        this.value1 = newValue;
      }, 300);
    },
    endProgress() {
      clearInterval(this.interval);
      this.interval = null;
    },
    openAlertDialog(dataConfig) {
      if (dataConfig.severity === "") {
        this.confirmClassTitle = "";
      } else if (dataConfig.severity === "success") {
        this.confirmClassTitle = "p-text-success";
      } else if (dataConfig.severity === "error") {
        this.confirmClassTitle = "p-error";
      }
      this.confirmTitle = dataConfig.title;
      this.confirmWording = dataConfig.content;
      this.showAlertDialog = true;
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    files() {
      return this.$store.getters["upload/files"];
    },
    selectedFile() {
      return this.$store.getters["upload/selectedFile"];
    },
    totalPage() {
      return this.$store.getters["upload/totalPage"];
    },
    currentPage() {
      return this.$store.getters["upload/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    title() {
      let code = this.selectedUnit ? this.selectedUnit.code : "";
      let result = this.lang.unitDocument.replace(":code", code);
      return result;
    },
    selectedUnit() {
      return this.$store.getters["unit/selectedUnit"];
    },
    isCreateDocument() {
      return this.unitId === "create";
    },
    dialogDataButtons() {
      return [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];
    },
  },
};
</script>

<style scoped>
.dropZone {
  box-sizing: border-box;
  width: 100%;
  background: #fafafa;
  border: 2px dashed #ced0d6;
  border-radius: 10px;
  text-align: center;
  height: 194px;
  margin-top: 2rem;
}

.dropZone-file {
  position: absolute;
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.dropZone-wrapper {
  display: grid;
  width: 100%;
  height: 100%;
}

.p-progressbar {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.p-datatable {
  text-align: center !important;
  overflow: visible !important;
  /* padding-top: 3% !important;
  padding-bottom: 3% !important; */
}

.base-center {
  width: 75%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.show-icon {
  opacity: 1;
  width: 48px;
  height: 48px;
  padding: 0.75rem;
}

.base-margin {
  margin-top: 37px;
  margin-bottom: 37px;
}

.p-pagination-radius {
  border-radius: 0 0 12px 12px;
}
</style>
