<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoSave="save"
    @gotoCreate="askForCreate"
    @gotoDelete="askForDelete"
  ></base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="titleConfirm"
      :classTitle="baseDialogClassTitle"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      :isVisibleCloseButton="isVisibleCloseButton"
      @confirm="confirmDialog"
      @close="closeBaseDialog"
      @gotoDelete="deleteResident"
      @gotoCreate="createResident"
      @gotoBack="goBack"
    ></base-dialog>

    <div class="surface-card shadow-2 p-5 border-round my-5">
      <div class="flex justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.residentDetail }}</label>
      </div>
      <PvDivider></PvDivider>
      <!-- ข้อมูลทั่วไป -->
      <base-card
        :titleHeader="lang.generalInfo"
        :isVisibleExpandButton="true"
        :isHideBackground="true"
        :isVisibleCard="true"
        class="pl-0 pr-0 pt-2"
      >
        <section
          class="flex flex-wrap justify-content-between"
          :class="textFormClass"
        >
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.code }}*</label>
            <PvInputText
              id="code"
              type="text"
              class="inputfield w-full mt-2"
              v-model="code"
              :placeholder="lang.codePlaceholder"
              :class="{ 'p-invalid': isCodeError }"
              @keyup="isCodeError = false"
              @blur="isCodeError = false"
            />
            <small v-if="isCodeError" id="isCodeError-help" class="p-error">
              {{ lang.validateCode }}
            </small>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ taxIdTitle }}</label>
            <PvInputText
              id="taxId"
              type="text"
              class="inputfield w-full mt-2"
              :placeholder="taxIdPlaceholder"
              v-model.trim="taxId"
              maxlength="13"
              :class="{ 'p-invalid': isTaxIdError }"
              
            />
            <!-- เอาออกเนื่องจากลูกค้าไม่ต้องการให้ validate 
              @keyup="checkTaxId()"
              @blur="checkTaxId()" -->
            <small v-if="isTaxIdError" id="isTaxIdError-help" class="p-error">
              {{ validateTaxId }}
            </small>
          </div>

          <div class="lg:col-2 md:col-2 col-6 pl-0 pr-2 pt-3">
            <label>{{ lang.prefix }}</label>
            <base-prefix-auto-complete
              id="prefix"
              v-model="prefix"
              class="inputfield w-full mt-1"
            />
          </div>
          <div class="lg:col-4 md:col-4 col-6 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.name }}*</label>
            <PvInputText
              id="name"
              type="text"
              class="inputfield w-full mt-2"
              v-model="name"
              :placeholder="lang.namePlaceholder"
              :class="{ 'p-invalid': isNameError }"
              @keyup="isNameError = false"
              @blur="isNameError = false"
            />
            <small v-if="isNameError" id="isNameError-help" class="p-error">
              {{ lang.validateName }}
            </small>
          </div>

          <div class="lg:col-2 md:col-2 col-6 pl-0 pr-2 pt-3">
            <label>{{ lang.countryCode }}</label>
            <base-country-code-auto-complete
              id="mobileNumberCountryCode"
              v-model="selectedMobileNumberCountryCode"
              class="inputfield w-full mt-1"
              @load-finish="countryCodeDataReady"
							:isLoadingData="true" 
              @item-select="selectMobileNumberCountryCode"
            />
          </div>
          <div class="lg:col-4 md:col-4 col-6 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="mobileNumber">{{ lang.mobileNumber }}</label>
            <PvInputText
              id="mobileNumber"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="mobileNumber"
              :placeholder="lang.mobileNumberPlaceholder"
              @keypress="validMobile"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="birthDate">{{ lang.birthDate }}</label>
            <PvCalendar
              id="birthDate"
              v-model="birthDate"
              class="inputfield w-full mt-2"
              showIcon
              dateFormat="dd/mm/yy"
              :placeholder="lang.birthDatePlaceholder"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.email }}</label>
            <PvInputText
              id="email"
              type="text"
              class="inputfield w-full mt-2"
              v-model="email"
              :placeholder="lang.emailPlaceholder"
              :class="{ 'p-invalid': isEmailError }"
              @blur="checkEmail()"
              @keyup="isEmailError = false"
            />
            <small v-if="isEmailError" id="isEmailError-help" class="p-error"
              >{{ lang.validateEmailFormat }}
            </small>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.nationality }}</label>
            <base-nationality-auto-complete
              id="nationality"
              v-model="selectedNationality"
              class="inputfield w-full mt-2"
              @load-finish="nationalityDataReady"
            ></base-nationality-auto-complete>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.gender }}</label>
            <div class="flex flex-wrap w-full">
              <div
                v-for="gender of lang.genders"
                :key="gender.key"
                class="pt-3 pr-5"
              >
                <PvRadioButton
                  :inputId="gender.key"
                  name="gender"
                  :value="gender.key"
                  v-model="selectedGender"
                />
                <label :for="gender.key" class="pl-3">{{ gender.name }}</label>
              </div>
            </div>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="selectedIsSupplier">{{ lang.isSupplier }}</label>
            <div class="row flex align-items-center pt-2">
              <PvCheckbox
                id="selectedIsSupplier"
                v-model="selectedIsSupplier"
                :binary="true"
              />
              <label class="pl-3">{{ lang.yes }}</label>
            </div>
          </div>
        </section>
      </base-card>

      <!-- ข้อมูลติดต่อฉุกเฉิน -->
      <base-card
        :titleHeader="lang.emergencyContact"
        :isVisibleExpandButton="true"
        :isHideBackground="true"
        :isVisibleCard="true"
        class="pl-0 pr-0 pt-2"
      >
        <section
          class="flex flex-wrap justify-content-between"
          :class="textFormClass"
        >
          <div class="lg:col-2 md:col-2 col-6 pl-0 pr-2 pt-3">
            <label>{{ lang.prefix }}</label>
            <base-prefix-auto-complete
              id="emergencyContactPrefix"
              v-model="emergencyContactPrefix"
              class="inputfield w-full mt-1"
            />
          </div>
          <div class="lg:col-4 md:col-4 col-6 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.contactName }}</label>
            <PvInputText
              id="emergencyContactName"
              type="text"
              class="inputfield w-full mt-2"
              v-model="emergencyContactName"
              :placeholder="lang.contactNamePlaceholder"
            />
          </div>

          <div class="lg:col-2 md:col-2 col-6 pl-0 pr-2 pt-3">
            <label>{{ lang.countryCode }}</label>
            <base-country-code-auto-complete
              id="emergencyContactMobileCountryCode"
              v-model="selectedEmergencyContactMobileCountryCode"
              class="inputfield w-full mt-1"
              @item-select="selectEmergencyContactMobileCountryCode"
            />
          </div>
          <div class="lg:col-4 md:col-4 col-6 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="emergencyContactMobileNumber">{{ lang.mobileNumber }}</label>
            <PvInputText
              id="emergencyContactMobileNumber"
              type="text"
              class="inputfield w-full mt-1"
              v-model.trim="emergencyContactMobileNumber"
              :placeholder="lang.mobileNumberPlaceholder"
            />
          </div>
        </section>
      </base-card>

      <!-- ข้อมูลที่อยู่สำหรับออกเอกสาร (ใบแจ้งหนี้, ใบเสร็จ, ฯลฯ) -->
      <base-card
        :titleHeader="lang.billingContact"
        :isVisibleExpandButton="true"
        :isHideBackground="true"
        :isVisibleCard="true"
        class="pl-0 pr-0 pt-2"
      >
        <section
          class="flex flex-wrap justify-content-between"
          :class="textFormClass"
        >
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.address }}</label>
            <PvInputText
              id="billingAddress"
              type="text"
              class="inputfield w-full mt-2"
              v-model.trim="billingAddress"
              :placeholder="lang.billingAddressPlaceholder"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="billingDistrict">{{ lang.district }}</label>
            <label for="billingDistrict" class="p-text-secondary"> *{{ lang.labelDistrictRemark }}</label>
            <PvInputText
              id="billingDistrict"
              type="text"
              class="inputfield w-full mt-1 p-inputtext-disabled"
              v-model.trim="billingDistrict"
              :placeholder="lang.districtPlaceholder"
              :disabled="true"
            />
          </div>

          <div class="flex flex-wrap col-12 p-0">
            <base-address-selector 
              :key="keyReload" 
              :defaultValue="billingAddressDefault"
              :isAddressError="isBillingAddressError"
							:isLoadingData="true"
              @addressDataReady="isSharedAddressReady = true"
              @itemSelected="setDataBillingAddress">
            </base-address-selector>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.documentLang }}</label>
            <PvDropdown
              class="inputfield w-full mt-2"
              :options="lang.documentLangItem"
              optionLabel="name"
              optionValue="code"
              v-model="selectedDocumentLang"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.deliveryChannel }}</label>
            <base-customer-group-auto-complete
              id="customerGroup"
              v-model="selectedCustomerGroup"
              class="inputfield w-full mt-2"
              @load-finish="customerGroupDataReady"
            ></base-customer-group-auto-complete>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.isGroupDoc }}</label>
            <div class="flex flex-wrap w-full">
              <div
                v-for="groupDoc of lang.groupDocs"
                :key="groupDoc.key"
                class="pt-2 pr-5"
              >
                <PvRadioButton
                  :inputId="groupDoc.key"
                  name="groupDoc"
                  :value="groupDoc.key"
                  v-model="selectedGroupDoc"
                />
                <label :for="groupDoc.key" class="pl-3">{{
                  groupDoc.name
                }}</label>
              </div>
            </div>
          </div>
        </section>
      </base-card>

      <!-- ข้อมูลตามที่อยู่บัตรประชาชน -->
      <base-card
        :titleHeader="lang.idCardContact"
        :isVisibleExpandButton="true"
        :isHideBackground="true"
        :isVisibleCard="true"
        class="pl-0 pr-0 pt-2"
      >
        <section
          class="flex flex-wrap justify-content-between"
          :class="textFormClass"
        >
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.address }}</label>
            <PvInputText
              id="idCardAddress"
              type="text"
              class="inputfield w-full mt-2"
              v-model="idCardAddress"
              :placeholder="lang.idCardAddressPlaceholder"
            />
          </div>
          
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="idCardDistrict">{{ lang.district }}</label>
            <label for="idCardDistrict" class="p-text-secondary"> *{{ lang.labelDistrictRemark }}</label>
            <PvInputText
              id="idCardDistrict"
              type="text"
              class="inputfield w-full mt-1 p-inputtext-disabled"
              v-model.trim="idCardDistrict"
              :placeholder="lang.districtPlaceholder"
              :disabled="true"
            />
          </div>

          <div class="flex flex-wrap col-12 p-0">
            <base-address-selector 
              :key="keyReload" 
              :defaultValue="idCardAddressDefault"
              :isAddressError="isIdCardAddressError"
              @itemSelected="setDataIdCardAddress">
            </base-address-selector>
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="registerDate">{{ lang.registerDate }}</label>
            <PvCalendar
              id="registerDate"
              v-model="registerDate"
              class="inputfield w-full mt-2"
              showIcon
              dateFormat="dd/mm/yy"
              :placeholder="lang.datePlaceholder"
            />
          </div>

          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="registerDate">{{ lang.expiredDate }}</label>
            <PvCalendar
              id="expiredDate"
              v-model="expiredDate"
              class="inputfield w-full mt-2"
              showIcon
              dateFormat="dd/mm/yy"
              :placeholder="lang.datePlaceholder"
            />
          </div>
        </section>
      </base-card>

      <!-- ข้อมูลตามที่อยู่สำหรับติดต่อ -->
      <base-card
        :titleHeader="lang.contact"
        :isVisibleExpandButton="true"
        :isHideBackground="true"
        :isVisibleCard="true"
        class="pl-0 pr-0 pt-2"
      >
        <section
          class="flex flex-wrap justify-content-between"
          :class="textFormClass"
        >
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label>{{ lang.address }}</label>
            <PvInputText
              id="contactAddress"
              type="text"
              class="inputfield w-full mt-2"
              v-model="contactAddress"
              :placeholder="lang.contactAddressPlaceholder"
            />
          </div>

          
          <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
            <label for="contactDistrict">{{ lang.district }}</label>
            <label for="contactDistrict" class="p-text-secondary"> *{{ lang.labelDistrictRemark }}</label>
            <PvInputText
              id="contactDistrict"
              type="text"
              class="inputfield w-full mt-1 p-inputtext-disabled"
              v-model.trim="contactDistrict"
              :placeholder="lang.districtPlaceholder"
              :disabled="true"
            />
          </div>

          <div class="flex flex-wrap col-12 p-0">
            <base-address-selector 
              :key="keyReload" 
              :defaultValue="contactAddressDefault"
              :isAddressError="isContactAddressError"
              @itemSelected="setDataContactAddress">
            </base-address-selector>
          </div>
        </section>
      </base-card>

      <!-- ข้อมูลการอยู่อาศัย -->
      <base-card
        :titleHeader="lang.living"
        :isVisibleExpandButton="true"
        :isHideBackground="true"
        :isVisibleCard="true"
        class="pl-0 pr-0 pt-2"
      >
        <div class="p-datatable-border my-2">
          <PvDataTable
            :value="unitResidents"
            dataKey="id"
            selectionMode="single"
            responsiveLayout="scroll"
            :class="textTableClass"
            class="p-datatable-xs"
            :loading="isLoading"
          >
            <PvColumn
              field="unitCode"
              :header="lang.room"
              headerClass="h-left"
              bodyClass="text-left"
              style="min-width: 10rem; height: 56px"
            >
              <template #body="slotProps">
                {{ slotProps.data.unitCode }} : {{ slotProps.data.addressNo }}
              </template>
            </PvColumn>

            <PvColumn
              field="erpLivingTypeTh"
              :header="lang.status"
              headerClass="h-left "
              bodyClass="text-left"
              style="min-width: 30rem"
            />

            <template #empty>
              <div class="w-full p-3" style="text-align: center">
                <label>{{ this.lang.tableEmpty }}</label>
              </div>
            </template>
            
          </PvDataTable>
        </div>
      </base-card>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import usePageDirty from "../../../hooks/pageDirty";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import useCommon from "../../../constant/common.js"
export default {
  props: ["id", "unitId", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textFormClass, textTableClass } = useTextStyle();
    const { formatDate, formatDateToISOString, formatSubdistrict } = useFormat();
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.resident.listing,
          ...lang.th.master.resident.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.resident.listing,
          ...lang.en.master.resident.edit,
        };
      }
    });

    const { compareObject, isCompareObject } = usePageDirty();
    const prefixes = ref(useCommon.CUSTOMER_PREFIX);

    return {
      lang: mergeLang,

      textFormClass,
      textTableClass,

      formatDate,
      formatDateToISOString,
      formatSubdistrict,

      compareObject,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      isCompareObject,
      prefixes,
    };
  },
  data() {
    return {
      taxId: "",
      code: "",
      name: "",
      birthDate: null,
      email: "",
      selectedIsSupplier: false,
      mobileCountryCode: "",
      mobileNumber: "",
      emergencyContactName: "",
      emergencyContactMobileNumber: "",
      billingAddress: "",
      billingPostCode: "",
      billingDistrict: "", //แขวง/ตำบล, เขต/อำเภอ billingAddress2
      billingProvince: "",
      idCardAddress: "",
      idCardPostCode: "",
      idCardDistrict: "", //แขวง/ตำบล, เขต/อำเภอ
      idCardProvince: "",
      registerDate: null,
      expiredDate: null,
      contactAddress: "",
      contactPostCode: "",
      contactDistrict: "", //แขวง/ตำบล, เขต/อำเภอ
      contactProvince: "",

      isSharedNationalityReady: false,
      isSharedCustomerGroupReady: false,
      isCodeError: false,
      isTaxIdError: false,
      isNameError: false,
      isMobileError: false,
      isEmailError: false,
      // isBillingAddressError: false,
      // isBillingDistrictError: false,
      isBillingPostCodeError: false,
      // isBillingProvinceError: false,

      validateTaxId: "",
      validateMobileNumber: "",
      validateEmail: "",
      validateBillingPostCode: "",

      // selectedResidentType: null,
      selectedGender: this.lang.genders[0].key,
      selectedNationality: null,
      selectedDocumentLang: "Thai",
      selectedGroupDoc: this.lang.groupDocs[0].key,
      selectedCustomerGroup: null,

      objToCompare: null,
      isLoading: false,
      dataButtonDialog: [],
      isVisibleCloseButton: false,
      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: this.lang.notificationDialogHeader,
      validateWording: "",

      prefix: "",
      isSharedCountryCodeReady: false,
      selectedMobileNumberCountryCode: "",
      emergencyContactPrefix: "",
      selectedEmergencyContactMobileCountryCode: "",
      isSharedAddressReady: false,
      billingAddressDefault: {
        province: "",
        district: "",
        subdistrict: "",
        postCode: "",
      },
      isBillingAddressError: {
        province: false,
        district: false,
        subdistrict: false,
        postCode: false,
      },
      billingAddressDistrict: "",
      billingAddressSubDistrict: "",

      idCardAddressDefault: {
        province: "",
        district: "",
        subdistrict: "",
        postCode: "",
      },
      isIdCardAddressError: {
        province: false,
        district: false,
        subdistrict: false,
        postCode: false,
      },
      idCardAddressDistrict: "",
      idCardAddressSubDistrict: "",
      
      contactAddressDefault: {
        province: "",
        district: "",
        subdistrict: "",
        postCode: "",
      },
      isContactAddressError: {
        province: false,
        district: false,
        subdistrict: false,
        postCode: false,
      },
      contactAddressDistrict: "",
      contactAddressSubDistrict: "",
      keyReload: 0,
    };
  },
  // async created() {
  //   if (!this.isCreateDocument) {
  //     const where = {
  //       id: this.id,
  //     };
  //     this.setLoading(true);
  //     await this.$store.dispatch("masterResident/getResident", { where });
  //     if (!this.resident) this.setLoading(false);
  //     this.getUnitResidents();
  //   } else {
  //     this.initiateData();
  //   }
  // },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    await this.roleByMenuDataReady();
  },
  methods: {
    goBack() {
      if (this.unitId) this.$router.push({name: "unit-edit", params: {id: this.unitId}});
      else {
        this.setLocalSearch();
        this.$router.push({name: "resident"});
        // this.$store.dispatch("masterResident/resetResidentSelected");
      }
    },
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    async roleByMenuDataReady() {
      if (this.isSharedRoleByMenuReady) {
        if (!this.isCreateDocument) {
          await this.getResident();
          await this.getUnitResidents();
        }
      }
    },
    async initiateData() {
      await this.$store.dispatch("masterResident/resetResident");
      await this.$store.dispatch("masterResident/resetResidentSelected");
      await this.$store.dispatch("unitResident/resetUnitResidents");
      this.code = "";
      this.name = "";
      this.taxId = "";
      this.birthDate = null;
      this.email = "";
      this.selectedIsSupplier = false;
      this.mobileCountryCode = "";
      this.mobileNumber = "";
      this.emergencyContactName = "";
      this.emergencyContactMobileNumber = "";
      this.billingAddress = "";
      this.billingPostCode = "";
      this.billingDistrict = "";
      this.billingProvince = "";
      this.idCardAddress = "";
      this.idCardPostCode = "";
      this.idCardDistrict = "";
      this.idCardProvince = "";
      this.registerDate = null;
      this.expiredDate = null;
      this.contactAddress = "";
      this.contactPostCode = "";
      this.contactDistrict = "";
      this.contactProvince = "";

      this.isCodeError = false;
      this.isTaxIdError = false;
      this.isNameError = false;
      this.isMobileError = false;
      this.isEmailError = false;
      // this.isBillingAddressError = false;
      // this.isBillingDistrictError = false;
      this.isBillingPostCodeError = false;

      // this.selectedResidentType = null;
      this.selectedDocumentLang = "Thai";
      this.selectedCustomerGroup = null;
      this.selectedNationality = null;

      // this.selectedResidentType = this.lang.types[0].key;
      this.selectedGender = this.lang.genders[0].key;
      this.selectedGroupDoc = this.lang.groupDocs[0].key;
      this.objToCompare = null;
      this.baseDialogClassTitle = "";
      if (this.isCreateDocument && this.isSharedNationalityReady) {
        this.selectedNationality = await this.sharedNationalities.find(
          (data) => data.nationality === "Thai"
        );
      }
      if (this.isCreateDocument && this.isSharedCustomerGroupReady) {
        this.selectedCustomerGroup = await this.sharedCustomerGroups[0];
      }

      this.prefix = "";
      this.selectedMobileNumberCountryCode = "";
      this.emergencyContactPrefix = "";
      this.selectedEmergencyContactMobileCountryCode = "";
      this.billingAddressDefault = {
        province: "",
        district: "",
        subdistrict: "",
        postCode: "",
      };
      this.isBillingAddressError = {
        province: false,
        district: false,
        subdistrict: false,
        postCode: false,
      };
      this.billingAddressDistrict = "";
      this.billingAddressSubDistrict = "";
      this.idCardAddressDefault = {
        province: "",
        district: "",
        subdistrict: "",
        postCode: "",
      };
      this.isIdCardAddressError = {
        province: false,
        district: false,
        subdistrict: false,
        postCode: false,
      };
      this.idCardAddressDistrict = "";
      this.idCardAddressSubDistrict = "";
      this.contactAddressDefault = {
        province: "",
        district: "",
        subdistrict: "",
        postCode: "",
      };
      this.isContactAddressError = {
        province: false,
        district: false,
        subdistrict: false,
        postCode: false,
      };
      this.contactAddressDistrict = "";
      this.contactAddressSubDistrict = "";
      this.keyReload++;
      
      if (this.isCreateDocument && this.isSharedCountryCodeReady) {
        //set default country code
        let defaultCountryCode = this.findCountryCode("66");
        this.selectedMobileNumberCountryCode = defaultCountryCode;
        this.selectedEmergencyContactMobileCountryCode = defaultCountryCode;
      }
    },
    async checkShouldSetData() {
      // if (this.isCreateDocument && this.isSharedNationalityReady) {
      //   this.selectedNationality = await this.sharedNationalities.find(
      //     (data) => data.nationality === "Thai"
      //   );
      // }
      // if (this.isCreateDocument && this.isSharedCustomerGroupReady) {
      //   this.selectedCustomerGroup = await this.sharedCustomerGroups[0];
      // }
      if (
        !this.isCreateDocument &&
        this.resident &&
        this.isSharedNationalityReady &&
        this.isSharedCustomerGroupReady && 
        this.isSharedCountryCodeReady && 
        this.isSharedAddressReady
      ) {
        this.setData();
      } else if (
        this.isCreateDocument &&
        this.isSharedNationalityReady &&
        this.isSharedCustomerGroupReady && 
        this.isSharedCountryCodeReady && 
        this.isSharedAddressReady
      ) {
        this.selectedNationality = await this.sharedNationalities.find(
          (data) => data.nationality === "Thai"
        );
        this.selectedCustomerGroup = await this.sharedCustomerGroups[0];
        //set default country code
        let defaultCountryCode = this.findCountryCode("66");
        this.selectedMobileNumberCountryCode = defaultCountryCode;
        this.selectedEmergencyContactMobileCountryCode = defaultCountryCode;
        this.setLoading(false);
      }

      await this.setCompareData();
    },
    setData() {
      // this.selectedResidentType = this.resident.gender.toLowerCase() == "company" ? this.lang.types[1].key:this.lang.types[0].key;
      // this.selectedGender = this.resident.gender.toLowerCase() == "female"? this.lang.genders[1].key:this.lang.genders[0].key;
      if (this.resident.gender.toLowerCase() == "company") {
        this.selectedGender = this.lang.genders[2].key;
      } else if (this.resident.gender.toLowerCase() == "female") {
        this.selectedGender = this.lang.genders[1].key;
      } else {
        this.selectedGender = this.lang.genders[0].key;
      }

      this.code = this.resident.code;
      this.name = this.resident.name;
      this.mobileCountryCode = this.resident.mobileCountryCode;
      this.mobileNumber = this.resident.mobileNumber;
      this.taxId = this.resident.identificationCardNumber;
      this.birthDate = this.resident.birthDate ? new Date(this.resident.birthDate): null;
      this.email = this.resident.email;
      this.selectedNationality = this.sharedNationalities.find(
        (data) => data.id == this.resident.nationalityId
      );
      this.selectedIsSupplier = this.resident.isSupplier == "Y" ? true : false;
      this.emergencyContactName = this.resident.emergencyContactName;
      this.emergencyContactMobileNumber =
        this.resident.emergencyContactMobileNumber;
      this.billingAddress = this.resident.billingAddress1;
      this.billingPostCode = this.resident.billingPostCode;
      this.billingDistrict = this.resident.billingAddress2;
      this.billingProvince = this.resident.billingAddress3;
      this.selectedDocumentLang = this.resident.billingLanguage;
      this.selectedCustomerGroup = this.sharedCustomerGroups.find(
        (data) => data.id == this.resident.customerGroupId
      );
      this.selectedGroupDoc = this.resident.isGroupingInvoice;
      this.idCardAddress = this.resident.idCardAddress1;
      this.idCardPostCode = this.resident.idCardPostCode;
      this.idCardDistrict = this.resident.idCardAddress2;
      this.idCardProvince = this.resident.idCardAddress3;
      this.registerDate = this.resident.registerDate ? new Date(this.resident.registerDate) : null;
      this.expiredDate = this.resident.expiredDate ? new Date(this.resident.expiredDate) : null;
      this.contactAddress = this.resident.contactAddress1;
      this.contactPostCode = this.resident.contactPostCode;
      this.contactDistrict = this.resident.contactAddress2;
      this.contactProvince = this.resident.contactAddress3;

      this.prefix = this.prefixes.find(
        (data) => data == this.resident.prefix
      );
      this.selectedMobileNumberCountryCode = this.findCountryCode(this.resident.mobileCountryCode);
      this.emergencyContactPrefix = this.prefixes.find(
        (data) => data == this.resident.emergencyContactPrefix
      );
      this.selectedEmergencyContactMobileCountryCode = this.findCountryCode(this.resident.emergencyContactMobileCountryCode);
      this.billingAddressDistrict = this.resident.billingAddressDistrict;
      this.billingAddressSubDistrict = this.resident.billingAddressSubDistrict;
      this.billingAddressDefault = {
        province: this.billingProvince,
        district: this.billingAddressDistrict,
        subdistrict: this.billingAddressSubDistrict,
        postCode: this.billingPostCode,
      }
      this.idCardAddressDistrict = this.resident.idCardAddressDistrict;
      this.idCardAddressSubDistrict = this.resident.idCardAddressSubDistrict;
      this.idCardAddressDefault = {
        province: this.idCardProvince,
        district: this.idCardAddressDistrict,
        subdistrict: this.idCardAddressSubDistrict,
        postCode: this.idCardPostCode,
      }
      this.contactAddressDistrict = this.resident.contactAddressDistrict;
      this.contactAddressSubDistrict = this.resident.contactAddressSubDistrict;
      this.contactAddressDefault = {
        province: this.contactProvince,
        district: this.contactAddressDistrict,
        subdistrict: this.contactAddressSubDistrict,
        postCode: this.contactPostCode,
      }
      this.keyReload++;
      this.setLoading(false);
    },
    selectedBirthDate() {
      let birthDate = null;
      if (this.birthDate) {
        birthDate = this.formatDateToISOString(this.birthDate);
      }
      return birthDate;
    },
    async nationalityDataReady() {
      this.isSharedNationalityReady = true;
      this.checkShouldSetData();
    },
    updateCountryCode(code) {
      this.mobileCountryCode = code;
    },
    updateEmergencyPhone(mobileNumber) {
      this.emergencyContactMobileNumber = mobileNumber;
    },
    async customerGroupDataReady() {
      this.isSharedCustomerGroupReady = true;
      this.checkShouldSetData();
    },
    validTaxId(id) {
      if (id.length != 13) return false;
      let i = 0;
      let sum = 0;
      for (i = 0, sum = 0; i < 12; i++) {
        sum += parseInt(id.charAt(i)) * (13 - i);
      }
      let mod = sum % 11;
      let check = (11 - mod) % 10;
      if (check == parseInt(id.charAt(12))) {
        return true;
      }
      return false;
    },
    checkTaxId() {
      this.isTaxIdError = false;
      let tax = this.taxId;
      if (tax) {
        if (this.selectedGender == this.lang.genders[2].key)
          this.validateTaxId = this.lang.validateJuristicTaxIdDigit;
        else this.validateTaxId = this.lang.validateIndividualTaxIdDigit;

        if (tax.length == 13) {
          if (!this.validTaxId(tax)) {
            this.isTaxIdError = true;
          } else {
            this.isTaxIdError = false;
          }
        } else {
          this.isTaxIdError = true;
        }

        if (tax === "0000000000000") {
          this.isTaxIdError = false;
        }
      }
    },
    validMobile(event) {
      const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      if (!keysAllowed.includes(event.key)) {
        event.preventDefault()
      }
    },
    validEmail(data) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(data)) {
        return false;
      } else {
        return true;
      }
    },
    checkEmail() {
      if (this.email && this.validEmail(this.email)) {
        this.validateEmail = this.lang.validateEmailFormat;
        this.isEmailError = true;
      } else {
        this.isEmailError = false;
      }
    },
    validPostCode() {
      const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      this.isBillingPostCodeError = false;
      for (let i = 0; i < this.billingPostCode.length; i++) {
        if (!keysAllowed.includes(this.billingPostCode[i])) {
          this.validateBillingPostCode = this.lang.validateMobileNumberFormat;
          this.isBillingPostCodeError = true;
          break;
        } else {
          this.isBillingPostCodeError = false;
        }
      }
    },
    checkPostCode() {
      this.isBillingPostCodeError = false;
      if (this.billingPostCode) {
        this.validateBillingPostCode = this.lang.validatePostCodeFormat;
        if (this.billingPostCode.length == 5) {
          this.validPostCode();
        } else {
          this.isBillingPostCodeError = true;
        }
      } else {
        this.isBillingPostCodeError = false;
      }
    },
    validateRole() {
      if(this.isCreateDocument && !this.isRoleCreate) {
        this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
        ];
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return true;
      } else if(!this.isCreateDocument && !this.isRoleUpdate) {
        this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
        ];
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateRoleUpdate;
        this.showValidateDialog = true;
        return true;
      }
      return false;
    },
    wordingValidate() {
      if(!this.code) {
        return this.lang.validateFieldNoValue + this.lang.code;
      }

      if (!this.name) {
        return this.lang.validateFieldNoValue + this.lang.name;
      }

      if (this.mobileNumber && this.isMobileError) {
        return this.lang.validateFomatInvalid.replace(":field", this.lang.mobileNumber);
      }

      if (this.email) {
        this.checkEmail();
        if (this.isEmailError) {
          return this.lang.validateFomatInvalid.replace(":field", this.lang.email);
        }
      }

      if (this.billingPostCode) {
        this.checkPostCode();
        if (this.isBillingPostCodeError) {
          return this.lang.validateFomatInvalid.replace(":field", this.lang.billingPostCode);
        }
      }

      //address
      if (this.billingProvince ||
          this.billingAddressDistrict ||
          this.billingAddressSubDistrict ||
          this.billingPostCode
      ) {
        if (!this.billingProvince) {
          return [this.lang.validateFieldNoValue + this.lang.province, `(${this.lang.billingContact})`];
        }
        if (!this.billingAddressDistrict) {
          return [this.lang.validateFieldNoValue + this.lang.addressDistrict, `(${this.lang.billingContact})`];
        }
        if (!this.billingAddressSubDistrict) {
          return [this.lang.validateFieldNoValue + this.lang.subDistrict, `(${this.lang.billingContact})`];
        }
        if (!this.billingPostCode) {
          return [this.lang.validateFieldNoValue + this.lang.postCode, `(${this.lang.billingContact})`];
        }
      }
      if (this.idCardProvince ||
          this.idCardAddressDistrict ||
          this.idCardAddressSubDistrict ||
          this.idCardPostCode
      ) {
        if (!this.idCardProvince) {
          return [this.lang.validateFieldNoValue + this.lang.province, `(${this.lang.idCardContact})`];
        }
        if (!this.idCardAddressDistrict) {
          return [this.lang.validateFieldNoValue + this.lang.addressDistrict, `(${this.lang.idCardContact})`];
        }
        if (!this.idCardAddressSubDistrict) {
          return [this.lang.validateFieldNoValue + this.lang.subDistrict, `(${this.lang.idCardContact})`];
        }
        if (!this.idCardPostCode) {
          return [this.lang.validateFieldNoValue + this.lang.postCode, `(${this.lang.idCardContact})`];
        }
      }
      if (this.contactProvince ||
          this.contactAddressDistrict ||
          this.contactAddressSubDistrict ||
          this.contactPostCode
      ) {
        if (!this.contactProvince) {
          return [this.lang.validateFieldNoValue + this.lang.province, `(${this.lang.contact})`];
        }
        if (!this.contactAddressDistrict) {
          return [this.lang.validateFieldNoValue + this.lang.addressDistrict, `(${this.lang.contact})`];
        }
        if (!this.contactAddressSubDistrict) {
          return [this.lang.validateFieldNoValue + this.lang.subDistrict, `(${this.lang.contact})`];
        }
        if (!this.contactPostCode) {
          return [this.lang.validateFieldNoValue + this.lang.postCode, `(${this.lang.contact})`];
        }
      }

    },
    validateData() {
      let isError = false;

      if (!this.code) {
        this.isCodeError = true;
        isError = true;
      }

      if (!this.name) {
        this.isNameError = true;
        isError = true;
      }

      if (this.mobileNumber) {
        if (this.isMobileError) {
          isError = true;
        }
      }

      if (this.email) {
        if (this.isEmailError) {
          isError = true;
        }
      }

      if (this.billingPostCode) {
        if (this.isBillingPostCodeError) {
          isError = true;
        }
      }

      //address
      if (this.billingProvince ||
          this.billingAddressDistrict ||
          this.billingAddressSubDistrict ||
          this.billingPostCode
      ) {
        if (!this.billingProvince) {
          this.isBillingAddressError.province = true;
          isError = true;
        }
        if (!this.billingAddressDistrict) {
          this.isBillingAddressError.district = true;
          isError = true;
        }
        if (!this.billingAddressSubDistrict) {
          this.isBillingAddressError.subdistrict = true;
          isError = true;
        }
        if (!this.billingPostCode) {
          this.isBillingAddressError.postCode = true;
          isError = true;
        }
      }
      if (this.idCardProvince ||
          this.idCardAddressDistrict ||
          this.idCardAddressSubDistrict ||
          this.idCardPostCode
      ) {
        if (!this.idCardProvince) {
          this.isIdCardAddressError.province = true;
          isError = true;
        }
        if (!this.idCardAddressDistrict) {
          this.isIdCardAddressError.district = true;
          isError = true;
        }
        if (!this.idCardAddressSubDistrict) {
          this.isIdCardAddressError.subdistrict = true;
          isError = true;
        }
        if (!this.idCardPostCode) {
          this.isIdCardAddressError.postCode = true;
          isError = true;
        }
      }
      if (this.contactProvince ||
          this.contactAddressDistrict ||
          this.contactAddressSubDistrict ||
          this.contactPostCode
      ) {
        if (!this.contactProvince) {
          this.isContactAddressError.province = true;
          isError = true;
        }
        if (!this.contactAddressDistrict) {
          this.isContactAddressError.district = true;
          isError = true;
        }
        if (!this.contactAddressSubDistrict) {
          this.isContactAddressError.subdistrict = true;
          isError = true;
        }
        if (!this.contactPostCode) {
          this.isContactAddressError.postCode = true;
          isError = true;
        }
      }

      if(isError) {
        this.keyReload++;
        this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
        ];
        this.baseDialogClassTitle = "p-error";
        this.titleConfirm = this.lang.validateSaveTitle;
        this.validateWording = this.wordingValidate();
        this.showValidateDialog = true;
      }

      return isError;
    },
    async save() {
      if (this.validateRole()) {
        return;
      }
      if (this.validateData()) {
        return;
      }
      this.setLoading(true);

      try {
        await this.$store.dispatch(
          "masterResident/addUpdateResident",
          this.payload
        );

        this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
        ];
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.saveSuccess;
        this.validateWording = this.saveEditSuccess;
        this.showValidateDialog = true;

        if (this.isCreateDocument) {
          if (this.unitId) {
            await this.$router.replace({
              name: "unit-resident-edit",
              params: {
                unitId: this.unitId,
                id: this.resident.id
              }
            });
          } else {
            await this.$router.replace({
              name: "resident-edit",
              params: {
                id: this.resident.id
              }
            });
          }

          this.setData();
          await this.setCompareData();
        }
      } catch (error) {
        this.openAlertDialogError(error);
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    async getResident() {
      try {
        await this.$store.dispatch("masterResident/getResident", this.id);
      } catch (error) {
        this.openAlertDialogError(error);
        this.setLoading(false);
      }
    },
    async getUnitResidents() {
      this.isLoading = true;
      let where = {
        projectId: this.selectedProject.id,
        customerId: this.id,
      };

      try {
        await this.$store.dispatch(
          "unitResident/getAllUnitResidentsByCustomerId",
          where
        );
      } catch (error) {
        this.openAlertDialogError(error);
        this.isLoading = false;
      }

      this.isLoading = false;
    },
    async setCompareData() {
      let objToCompare = {
        projectId: this.selectedProject.id,
        code: this.code,
        customerGroupId: this.selectedCustomerGroup
          ? this.selectedCustomerGroup.id
          : null,
        name: this.name,
        gender: this.selectedGender,
        idCardAddress1: this.idCardAddress,
        idCardAddress2: this.idCardDistrict,
        idCardAddress3: this.idCardProvince,
        idCardPostCode: this.idCardPostCode.trim(),
        isGroupingInvoice: this.selectedGroupDoc,
        contactAddress1: this.contactAddress,
        contactAddress2: this.contactDistrict,
        contactAddress3: this.contactProvince,
        contactPostCode: this.contactPostCode.trim(),
        email: this.email,
        mobileCountryCode: this.selectedMobileNumberCountryCode,
        mobileNumber: this.mobileNumber,
        billingLanguage: this.selectedDocumentLang,
        billingAddress1: this.billingAddress,
        billingAddress2: this.billingDistrict,
        billingAddress3: this.billingProvince,
        billingPostCode: this.billingPostCode.trim(),
        emergencyContactName: this.emergencyContactName,
        emergencyContactMobileCountryCode: this.selectedEmergencyContactMobileCountryCode,
        emergencyContactMobileNumber: this.emergencyContactMobileNumber,
        identificationCardNumber: this.taxId,
        // livingUnitId: this.unit?this.unit.id:"",
        // livingRole: this.unit?this.unit.status:"Owner",
        // livingStartDate: this.unit?this.formatDateToISOString(this.unit.startDate):this.formatDateToISOString(new Date()),
        // livingEndDate: this.unit?this.formatDateToISOString(this.unit.endDate):this.formatDateToISOString(new Date()),
        // livingAddBy: this.unit?userId:"",
        // isFromUnitResidentRequested: true,
        // unitResidentRequestedId: 0,
        // approveBy: "string",
        birthDate: this.birthDate
          ? this.formatDateToISOString(this.birthDate)
          : null,
        registerDate: this.registerDate
          ? this.formatDateToISOString(this.registerDate)
          : null,
        expiredDate: this.expiredDate
          ? this.formatDateToISOString(this.expiredDate)
          : null,
        isSupplier: this.selectedIsSupplier ? "Y" : "N",
        nationalityId: this.selectedNationality
          ? this.selectedNationality.id
          : null,
        type: "Add",
        isIgnoreValidateAddress: false,
        prefix: this.prefix,
        emergencyContactPrefix: this.emergencyContactPrefix,
        billingAddressDistrict: this.billingAddressDistrict,
        billingAddressSubDistrict: this.billingAddressSubDistrict,
        idCardAddressDistrict: this.idCardAddressDistrict,
        idCardAddressSubDistrict: this.idCardAddressSubDistrict,
        contactAddressDistrict: this.contactAddressDistrict,
        contactAddressSubDistrict: this.contactAddressSubDistrict,
      };

      if (!this.isCreateDocument) {
        objToCompare = { ...objToCompare, id: this.id, type: "Update" };
      }
      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
    },
    setLocalSearch() {
      let storageName = `erp_search_ResidentList_${this.selectedProject.code}_${this.userCode}`;
      const localSearch = localStorage.getItem(storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      localStorage.setItem(storageName, JSON.stringify(search));
    },
    async askForCreate() {
      if(!this.isRoleCreate) {
        this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
        ];
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return;
      }

      let result = this.isCompareObject(this.objToCompare, this.payload);
      if (result) {
        this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.cancel,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "gotoCreate",
            caption: this.lang.confirm,
            class: "w-full",
            action: "gotoCreate",
          },
        ];

        this.titleConfirm = this.lang.createNewResident;
        this.validateWording = [
          this.lang.askForCreateNewResident,
          this.lang.askForCreateNewResidentConfirm,
        ];
        this.showValidateDialog = true;
      } else {
        await this.createResident();
      }
    },
    async createResident() {
      this.setLoading(true);
      await this.initiateData();
      await this.$router.push({ name: "resident-edit", params: { id: "create" } });

      this.setCompareData();
      this.showValidateDialog = false;
      this.setLoading(false);
    },
    askForDelete() {
      if(!this.isCreateDocument && !this.isRoleDelete) {
        this.dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
        ];
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateRoleDelete;
        this.showValidateDialog = true;
        return;
      }

      this.dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
        {
          id: "gotoDelete",
          caption: this.lang.buttonDialogConfirm,
          class: "w-full",
          action: "gotoDelete",
        },
      ];
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.deleteResident;
      this.validateWording = `${this.lang.askForDeleteResident.replace(
        ":code",
        this.code
      )}`;
      this.showValidateDialog = true;
    },
    async deleteResident() {
      this.setLoading(true);
      this.showValidateDialog = false;
      try {
        let payload = {
          projectId: this.selectedProject.id,
          id: this.id,
        };
        await this.$store.dispatch("masterResident/deleteResident", payload);
        this.dataButtonDialog = [
          {
            id: "gotoBack",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "gotoBack",
          },
        ];
        this.isVisibleCloseButton = true;
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.deleteSuccess;
        this.validateWording = `${this.lang.deleteResidentSuccess.replace(
          ":code",
          this.code
        )}`;
        this.showValidateDialog = true;
      } catch (error) {
        this.openAlertDialogError(error);
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    confirmDialog() {
      this.showValidateDialog = false;
      this.baseDialogClassTitle = "";
    },
    closeBaseDialog() {
      this.showValidateDialog = false;
    },
    openAlertDialogError(error) {
      this.dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
      ];

      this.isVisibleCloseButton = false;
      this.baseDialogClassTitle = "p-error";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.validateWording = error.message;
      this.showValidateDialog = true;
    },
    countryCodeDataReady() {
      this.isSharedCountryCodeReady = true;
      this.checkShouldSetData();
    },
    selectMobileNumberCountryCode(data) {
      this.selectedMobileNumberCountryCode = data.value.callingCode;
    },
    selectEmergencyContactMobileCountryCode(data) {
      this.selectedEmergencyContactMobileCountryCode = data.value.callingCode;
    },
    setDataBillingAddress(data) {
      this.billingDistrict = (data.district && data.subdistrict)?this.formatSubdistrict(data):"";
      this.billingProvince = data.province;
      this.billingAddressDistrict = data.district;
      this.billingAddressSubDistrict = data.subdistrict;
      this.billingPostCode = data.postCode;
      this.billingAddressDefault = {
        province: data.province,
        district: data.district,
        subdistrict: data.subdistrict,
        postCode: data.postCode,
      }
      this.isBillingAddressError = {
        province: false,
        district: false,
        subdistrict: false,
        postCode: false,
      }
    },
    setDataIdCardAddress(data) {
      this.idCardDistrict = (data.district && data.subdistrict)?this.formatSubdistrict(data):"";
      this.idCardProvince = data.province;
      this.idCardAddressDistrict = data.district;
      this.idCardAddressSubDistrict = data.subdistrict;
      this.idCardPostCode = data.postCode;
      this.idCardAddressDefault = {
        province: data.province,
        district: data.district,
        subdistrict: data.subdistrict,
        postCode: data.postCode,
      }
      this.isIdCardAddressError = {
        province: false,
        district: false,
        subdistrict: false,
        postCode: false,
      }
    },
    setDataContactAddress(data) {
      this.contactDistrict = (data.district && data.subdistrict)?this.formatSubdistrict(data):"";
      this.contactProvince = data.province;
      this.contactAddressDistrict = data.district;
      this.contactAddressSubDistrict = data.subdistrict;
      this.contactPostCode = data.postCode;
      this.contactAddressDefault = {
        province: data.province,
        district: data.district,
        subdistrict: data.subdistrict,
        postCode: data.postCode,
      }
      this.isContactAddressError = {
        province: false,
        district: false,
        subdistrict: false,
        postCode: false,
      }
    },
    findCountryCode(data) {
      const countryCode = this.sharedCountryCodes.find((countryCode) => countryCode.callingCode == data);
      let result = countryCode? countryCode.callingCode:"";
      return result;
    },
  },
  computed: {
    payload() {
      let payload = {
        projectId: this.selectedProject.id,
        code: this.code,
        customerGroupId: this.selectedCustomerGroup
          ? this.selectedCustomerGroup.id
          : null,
        name: this.name,
        gender: this.selectedGender,
        idCardAddress1: this.idCardAddress,
        idCardAddress2: this.idCardDistrict,
        idCardAddress3: this.idCardProvince,
        idCardPostCode: this.idCardPostCode.trim(),
        isGroupingInvoice: this.selectedGroupDoc,
        contactAddress1: this.contactAddress,
        contactAddress2: this.contactDistrict,
        contactAddress3: this.contactProvince,
        contactPostCode: this.contactPostCode.trim(),
        email: this.email,
        mobileCountryCode: this.selectedMobileNumberCountryCode,
        mobileNumber: this.mobileNumber,
        billingLanguage: this.selectedDocumentLang,
        billingAddress1: this.billingAddress,
        billingAddress2: this.billingDistrict,
        billingAddress3: this.billingProvince,
        billingPostCode: this.billingPostCode.trim(),
        emergencyContactName: this.emergencyContactName,
        emergencyContactMobileCountryCode: this.selectedEmergencyContactMobileCountryCode,
        emergencyContactMobileNumber: this.emergencyContactMobileNumber,
        identificationCardNumber: this.taxId,
        // livingUnitId: this.unit?this.unit.id:"",
        // livingRole: this.unit?this.unit.status:"Owner",
        // livingStartDate: this.unit?this.formatDateToISOString(this.unit.startDate):this.formatDateToISOString(new Date()),
        // livingEndDate: this.unit?this.formatDateToISOString(this.unit.endDate):this.formatDateToISOString(new Date()),
        // livingAddBy: this.unit?userId:"",
        // isFromUnitResidentRequested: true,
        // unitResidentRequestedId: 0,
        // approveBy: "string",
        birthDate: this.birthDate
          ? this.formatDateToISOString(this.birthDate)
          : null,
        registerDate: this.registerDate
          ? this.formatDateToISOString(this.registerDate)
          : null,
        expiredDate: this.expiredDate
          ? this.formatDateToISOString(this.expiredDate)
          : null,
        isSupplier: this.selectedIsSupplier ? "Y" : "N",
        nationalityId: this.selectedNationality
          ? this.selectedNationality.id
          : null,
        type: "Add",
        isIgnoreValidateAddress: false,
        prefix: this.prefix,
        emergencyContactPrefix: this.emergencyContactPrefix,
        billingAddressDistrict: this.billingAddressDistrict,
        billingAddressSubDistrict: this.billingAddressSubDistrict,
        idCardAddressDistrict: this.idCardAddressDistrict,
        idCardAddressSubDistrict: this.idCardAddressSubDistrict,
        contactAddressDistrict: this.contactAddressDistrict,
        contactAddressSubDistrict: this.contactAddressSubDistrict,
      };
      if (!this.isCreateDocument) {
        payload = { ...payload, id: this.id, type: "Update" };
      }
      return payload;
    },
    isCreateDocument() {
      return this.id === "create";
    },
    title() {
      let result = this.lang.createResident;
      if (!this.isCreateDocument) {
        result = this.lang.editResident;
      }
      return result;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    sharedNationalities() {
      return this.$store.getters["shared/nationalityNationalities"];
    },
    taxIdTitle() {
      let result = this.lang.individualTaxId;
      return result;
    },
    taxIdPlaceholder() {
      let result = this.lang.individualTaxIdPlaceholder;
      return result;
    },
    sharedCustomerGroups() {
      return this.$store.getters["shared/customerCustomerGroups"];
    },
    resident() {
      return this.$store.getters["masterResident/selectedResident"];
    },
    unitResidents() {
      return this.$store.getters["unitResident/unitResidents"];
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmCOR");
    },
    isRoleCreate() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRoleUpdate() {
      let result = false;
      if (this.roleByMenu && this.roleByMenu.update == "Y") {
        result = true;
      }
      return result;
    },
    isRoleDelete() {
      let result = false;
      if (this.roleByMenu && this.roleByMenu.delete == "Y") {
        result = true;
      }
      return result;
    },
    isDisableSave() {
      if (!this.isCreateDocument) {
        if (!this.isRoleUpdate) {
          return true;
        }
      } else {
        if (!this.isRoleCreate) {
          return true;
        }
      }
      return false;
    },
    isDisableDelete() {
      if (this.isCreateDocument) {
        return true;
      }

      if (!this.isRoleDelete) {
        return true;
      }
      return false;
    },
    saveEditSuccess() {
      let result = this.lang.editResidentSuccess;
      if (this.isCreateDocument) {
        result = this.lang.createResidentSuccess;
      }
      return result;
    },
    dataButtons() {
      let result = [
        {
          id: "gotoDelete",
          action: "gotoDelete",
          // isDisable: this.isDisableDelete,
          isDisable: this.isCreateDocument,
          type: "delete"
        },
        {
          id: "gotoCreate",
          action: "gotoCreate",
          // isDisable: !this.isRoleCreate,
          type: "create"
        },
        {
          id: "gotoSave",
          action: "gotoSave",
          // isDisable: this.isDisableSave,
          type: "save"
        },
      ];

      return result;
    },
    sharedCountryCodes() {
      return this.$store.getters["shared/countryCodes"];
    },
  },
  watch: {
    resident() {
      this.checkShouldSetData();
    },
    isSharedRoleByMenuReady() {
      this.roleByMenuDataReady();
    },
    payload(curValue) {
      if (this.objToCompare) {
        this.compareObject(this.objToCompare, curValue);
      }
    },
    isSharedAddressReady() {
      this.checkShouldSetData();
    },
  },
};
</script>
