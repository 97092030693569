export default {
  async getDebtFreeFollowUpList(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Debtfree/DebtfreeFollowUp";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }
    if (payload.page) {
      url = url + "&page=" + payload.page;
    }
    if (payload.projectId) {
      url = url + "&projectId=" + payload.projectId;
    }
    if (payload.dateStart) {
      url = url + "&dateStart=" + payload.dateStart;
    }
    if (payload.dateEnd) {
      url = url + "&dateEnd=" + payload.dateEnd;
    }
    if (payload.unitCodeAndAddressNo) {
      url = url + "&unitCodeAndAddressNo=" + payload.unitCodeAndAddressNo;
    }
    if (payload.ownerName) {
      url = url + "&ownerName=" + payload.ownerName;
    }
    if (payload.status) {
      url = url + "&status=" + payload.status;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get debt free follow up.");
      throw error;
    }

    // console.log("getDebtFreeFollowUpList",responseData);

    context.commit("setDebtFreeFollowUpList", responseData);
  },
  async getDebtFreeFollowUpById(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + '/Debtfree/DebtfreeFollowUp/' + payload;
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || `Failed to get debt free follow up id : ${payload}`);
      throw error;
    }

    // console.log("getDebtFreeFollowUpById", responseData);

    context.commit("setSelectedDebtFreeApprove", responseData.data);
  },
  resetDebtFreeFollowUp(context) {
    context.commit("setSelectedDebtFreeApprove", null);
  },
  async updateDebtfreeFollowUp(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Debtfree/DebtfreeFollowUp/Update";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to follow up debtfree');
      throw error;
    }
    
    context.commit("setSelectedDebtFreeApprove", responseData.data);       
  },
};
