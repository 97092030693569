export default {
  setBookBankAr(state, payload) {
    let bookBankArs = [...state.bookBankAr.bookBankArs];
    for (const bookBankAr of payload.bookBankArs) {
      if (bookBankArs.findIndex((c) => c.id === bookBankAr.id) < 0) {
        bookBankArs.push(bookBankAr);
      }
    }
    state.bookBankAr.bookBankArs = bookBankArs;
    state.bookBankAr.currentPage = payload.currentPage;
    state.bookBankAr.totalPage = payload.totalPage;
  },
  resetBookBankAr(state) {
    const bookBankAr = {
      bookBankArs: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.bookBankAr = bookBankAr;
  },
};
  