export default {
	editTitle: "แก้ไขทะเบียนรายรับ",
	addTitle: "สร้างทะเบียนรายรับ",
	editSubTitle: "แก้ไขทะเบียนรายรับ",
	addSubTitle: "สร้างทะเบียนรายรับ",
	createProduct: "เพิ่มรายรับ",

	toastHeaderMsgDefault: "ข้อความแจ้งเตือน",
	toastMsgRoleInsert: "คุณไม่มีสิทธิ์ สร้างเอกสาร",
	toastMsgRoleUpdate: "คุณไม่มีสิทธิ์ แก้ไขเอกสาร",
	toastMsgRoleCancel: "คุณไม่มีสิทธิ์ ยกเลิกเอกสาร",
	toastMsgRoleApprove: "คุณไม่มีสิทธิ์ อนุมัติเอกสาร",
	toastMsgRoleDelete: "คุณไม่มีสิทธิ์ ลบข้อมูล",
	toastHeaderMsgAdd: "เพิ่มทะเบียนรายรับสำเร็จ",
	toastMsgAdd: "ทะเบียนรายรับ:productName",
	toastHeaderMsgEdit: "แก้ไขข้อมูลทะเบียนรายรับสำเร็จ",
	toastMsgEdit: "แก้ไขรายรับ:productName",
	toastHeaderMsgDuplicate: "ไม่สามารถเพิ่มทะเบียนรายรับได้",
	toastMsgDuplicate: "มีทะเบียนรายรับนี้อยู่แล้วในระบบ",
	toastHeaderMsgError: "บันทึกข้อมูลไม่สำเร็จ",
	toastMsgError: "ไม่สามารถแก้ไขทะเบียนรายรับ:productNameได้",

	saveButtonCaption: "ยืนยัน",
	setupButtonCaption: "ตั้งค่ารายรับ",

	titleGeneral: "รายละเอียดรายรับ",
	titleAccountChart: "ผังบัญชี",

	tabInvoice: "การแจ้งหนี้",
	tabReceipt: "การชำระเงิน",
	tabDiscount: "ส่วนลด",

	labelCode: "รหัสรายรับ",
	placeholderCode: "กรุณาระบุรหัสรายรับ",
	validateCode: "กรุณาระบุรหัสรายรับ",
	labelPaidPriority: "ลำดับการตัดจ่าย",
	placeholderPaidPriority: "กรุณาระบุลำดับการตัดจ่ายเป็นตัวเลข",
	labelNameThai: "ชื่อรายรับภาษาไทย",
	placeholderNameThai: "กรุณาระบุชื่อรายรับภาษาไทย",
	validateNameThai: "กรุณาระบุชื่อรายรับภาษาไทย",
	labelNameEnglish: "ชื่อรายรับภาษาอังกฤษ",
	placeholderNameEnglish: "กรุณาระบุชื่อรายรับภาษาอังกฤษ",
	validateNameEnglish: "กรุณาระบุชื่อรายรับภาษาอังกฤษ",
	labelPrice: "ราคา",
	placeholderPrice: "กรุณาระบุราคา(บาท)",
	labelProductUnit: "หน่วยนับ",
	placeholderProductUnit: "กรุณาเลือกหน่วยนับ",

	labelProductType: "ประเภทการแจ้งหนี้",
	placeholderProductType: "กรุณาเลือกรูปแบบการแจ้งหนี้",
	validateProductType: "กรุณาเลือกรูปแบบการแจ้งหนี้",
	tooltipProductType: "ใช้สำหรับการรับการบันทึกชำระหนี้",
	itemProductType1: "รับเงินสด(ไม่ต้องแจ้งหนี้)",
	itemProductType2: "รายการแจ้งหนี้",
	itemProductType3: "รับล่วงหน้า",

	labelProductCalculation: "รูปแบบการคำนวณหนี้",
	placeholderProductCalculation: "กรุณาเลือกรูปแบบการคำนวณหนี้",
	validateProductCalculation: "กรุณาเลือกรูปแบบการคำนวณหนี้",
	tooltipProductCalculation: "วิธีการคำนวณ : ราคา x รูปแบบการคำนวณหนี้<br>(ยกเว้นรูปแบบ \"แบบคงที่เท่ากันทุกยูนิต\")",
	itemProductCalculation1: "แบบคงที่เท่ากันทุกยูนิต",
	itemProductCalculation2: "ราคา x พื้นที่",
	itemProductCalculation3: "ราคา x อัตราส่วนกรรมสิทธ์",

	labelIsCalculatePenalty: "การคิดค่าปรับ",
	placeholderIsCalculatePenalty: "กรุณาเลือกรูปแบบการคิดค่าปรับ",
	validateIsCalculatePenalty: "กรุณาเลือกรูปแบบการคิดค่าปรับ",
	itemIsCalculatePenalty1: "คำนวณค่าปรับ/เงินเพิ่มจากระบบ",
	itemIsCalculatePenalty2: "ไม่ต้องคำนวณค่าปรับ/เงินเพิ่ม",

	labelProductPenalty: "รายรับค่าปรับ",
	placeholderProductPenalty: "กรุณาเลือกรายรับค่าปรับ",
	validateProductPenalty: "กรุณาเลือกรายรับค่าปรับ",

	labelProductPrepaid: "รับล่วงหน้า",
	placeholderProductPrepaid: "กรุณาเลือกรายรับล่วงหน้า",

	labelIsVat: "คิดภาษี",
	itemIsVats1: "คิดภาษี",
	itemIsVats2: "ไม่คิดภาษี",

	labelIsIgnoreDuplicatePeriod: "ยกเลิกการตรวจสอบงวดเดือนซ้ำในระบบ",

	placeholderAccount: "กรุณาเลือกผังบัญชี",
	labelAccountDr: "ผังบัญชี Dedit",
	labelAcountDrInvoicePrevious: "ย้อนหลัง/ก่อนหน้า",
	labelAccountDrInvoiceCurrent: "ปัจจุบัน",
	labelAccountDrInvoiceForward: "ถัดไป/ล่วงหน้า",
	labelAccountDrReceiptCash: "ประเภทเงินสด",
	labelAccountDrReceiptCheque: "ประเภทเช็ค",
	labelAccountDrReceiptTransfer: "ประเภทเงินโอน (ปัจจุบัน)",
	labelAccountDrReceiptTransferPrevious: "ประเภทเงินโอน (ย้อนหลัง/ก่อนหน้า)",
	labelAccountDrReceiptDigitalPayment: "ประเภทเงินโอน (Digital payment)",
	labelAccountDrReceiptCreditCard: "ประเภทบัตรเครดิต",
	labelAccountDrReceiptDeposit: "ประเภทหักจากเงินฝาก",
	labelAccountDrReceiptOther: "ประเภทหักจากอื่นๆ",
	labelAccountDrReceiptDiscount: "ประเภทส่วนลด",
	labelAccountCr: "ผังบัญชี Credit",
	labelAcountCrInvoicePrevious: "ย้อนหลัง/ก่อนหน้า",
	labelAccountCrInvoiceCurrent: "ปัจจุบัน",
	labelAccountCrInvoiceForward: "ถัดไป/ล่วงหน้า",
	labelAccountCrReceiptCash: "ประเภทเงินสด",
	labelAccountCrReceiptCheque: "ประเภทเช็ค",
	labelAccountCrReceiptTransfer: "ประเภทเงินโอน (ปัจจุบัน)",
	labelAccountCrReceiptTransferPrevious: "ประเภทเงินโอน (ย้อนหลัง/ก่อนหน้า)",
	labelAccountCrReceiptDigitalPayment: "ประเภทเงินโอน (Digital payment)",
	labelAccountCrReceiptCreditCard: "ประเภทบัตรเครดิต",
	labelAccountCrReceiptDeposit: "ประเภทหักจากเงินฝาก",
	labelAccountCrReceiptOther: "ประเภทหักจากอื่นๆ",
	labelAccountCrReceiptDiscount: "ประเภทส่วนลด",

	tabPenaltyProduct: "แก้ไขอัตราค่าปรับ",
	tabAddProductToRoom: "เพิ่มรายการเข้าห้อง",
	noRecordsFound: "ไม่มีข้อมูล",

	labelSearchBy: "ค้นหาโดย",
	itemSearchByAll: "ทั้งหมด",
	itemSearchByUnitCode: "รหัสห้อง",
	itemSearchByUnitAddress: "บ้านเลขที่",
	labelSearchStart: "เริ่มต้น",
	labelSearchEnd: "สิ้นสุด",
	validateSearch: "กรุณากรอกข้อความค้นหา",
	labelAddAllRoom: "เพิ่มเข้าทั้งหมด",
	labelDeleteAllRoom: "ลบออกทั้งหมด",

	columnUnitCode: "รหัสห้อง",
	columnUnitAddress: "บ้านเลขที่",
	columnProductCode: "รหัสรายรับ",
	columnProductName: "ชื่อรายรับ",
	columnStatus: "สถานะเพิ่มรายรับ",
	columnAction: "ทำรายการ",

	labelAddToRoom: "เพิ่มเข้า",
	labelDeleteFromRoom: "ลบออก",

	labelTagAdded: "เพิ่มแล้ว",
	labelTagPending: "รอยืนยัน",
	labelTagWaiting: "รอเพิ่มเข้า",

	titleAddedConfirmDialog: "ยืนยันการเพิ่มทะเบียนรายรับ",
	wordingAddedDialog: "เพิ่มทะเบียนรายรับ {product} เข้า {num} ห้อง \nรวมเป็นทั้งหมด {total} ห้อง",
	titleDeleteConfirmDialog: "ยืนยันการลบทะเบียนรายรับ",
	wordingDeleteDialog: "ลบทะเบียนรายรับ {product} ออก {num} ห้อง \nรวมเป็นทั้งหมด {total} ห้อง",
	arProductDetail: "รายละเอียดทะเบียนรายรับ",
	deleteArProductTitle: "ยืนยันการลบข้อมูล",
	askForDeleteArProductTitle: "ต้องการยืนยันที่จะลบรายการ :code ใช่หรือไม่?",
	deleteArProductSuccess: "คุณลบข้อมูลสำเร็จ",
	errorArProductReference: "ลบข้อมูลไม่ได้ เนื่องจากมีข้อมูลอ้างถึง",

	labelIsProductPenalty: "เนื่องจากรายรับค่าปรับ/เงินเพิ่ม ไม่อนุญาตให้คิดค่าปรับ/เงินเพิ่มอีกได้",
};
