export default {
	async getAccountingEntries(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Voucher";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get invoice");
			throw error;
		}
		// console.log("getAccountingEntries", responseData);

		// const data = responseData.data;
		context.commit("setAccountingEntry", responseData);
	},
	async getAccountingEntry(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Voucher?limit=1";

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get invoice");
			throw error;
		}

		// const data = responseData.data;
		if (responseData.data.length > 0) {
			context.commit("setSelectedAccountingEntry", responseData.data[0]);
		}
	},
	async createOrUpdateAccountingEntry(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + `/Voucher/${payload.typeInsert}`;
		const fallbackErrorMessage = `Failed to ${payload.typeInsert} voucher`;
		const token = context.rootGetters.token;

		// console.log("url", url);

		const response = await fetch(url, {
			method: payload.typeInsert === "Add" ? "post" : "put",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},

			body: JSON.stringify(payload),
		});

		// console.log("response", response)

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			if (responseData.code) {
				throw responseData;
			} else {
				let message = null;
				if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
					message = responseData.title;
					for (const key in responseData.errors) {
						message += ", " + key.replace("$.", "");
					}
				} else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
					message = responseData.message;
				}
				const error = new Error(message || fallbackErrorMessage);
				throw error;
			}
		}
		// console.log(responseData);
		context.commit("setSelectedAccountingEntry", responseData.data);
	},
	async RevertGeneralLedger(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + `/Voucher/Revert`;
		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "post",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},

			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			let message = null;
			if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
				message = responseData.title;
				for (const key in responseData.errors) {
					message += ", " + key.replace("$.", "");
				}
			} else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
				message = responseData.message;
			}
			const error = new Error(message || "Failed to revert");
			throw error;
		}

		context.commit("setSelectedAccountingEntry", responseData.data);
	},
	async CancelAccounting(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + `/Voucher/Cancel`;
		const token = context.rootGetters.token;
		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},

			body: JSON.stringify(payload),
		});
		const responseData = await response.json();
		if (!response.ok) {
			if (responseData.code) {
				throw responseData;
			} else {
				let message = null;
				if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
					message = responseData.title;
					for (const key in responseData.errors) {
						message += ", " + key.replace("$.", "");
					}
				} else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
					message = responseData.message;
				}
				const error = new Error(message || "Failed to cancel");
				throw error;
			}
		}
		context.commit("setSelectedAccountingEntry", responseData.data);
	},

	resetAccountingEntry(context) {
		context.commit("setSelectedAccountingEntry", null);
	},

	async PrintVoucher(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Voucher/Print";

		const token = context.rootGetters.token;

		// console.log("PrintPrepaid_payload=",payload);
		// console.log("PrintPrepaid_payloadtoken=",token);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
			mode: "cors", // no-cors, *cors, same-origin
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			credentials: "same-origin", // include, *same-origin, omit
			redirect: "follow", // manual, *follow, error
			referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
		});

		// console.log("PrintPrepaid_response>>>",response);

		// response.blob().then(function(blob) {
		//   // console.log("PrintPrepaid_blob>>>",blob);
		//     var url = window.URL.createObjectURL(blob);
		//     //window.location.assign(url);
		//     var a = document.createElement('a');
		//     a.href = url;
		//     const d = new Date();
		//     let dateText = d.toISOString();
		//     a.download = `PrepaidRefund_${dateText}.pdf`;
		//     document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
		//     a.click();
		//     a.remove(); //afterwards we remove the element again
		// });

		let urlBlob = "";
		let blob = await response.blob();
		urlBlob = window.URL.createObjectURL(blob);
		context.commit("setBlob", urlBlob);
	},
	async getAllAccountingEntries(context, payload) {
		await context.commit("resetAccountingEntryListing");
		while (!context.getters.accountingEntriesIsLastPage) {
			// let where = payload.where;
			const payloadAllAccountingEntries = {
				limit: payload.limit,
				page: context.getters.accountingEntriesNextPage || 1,
				where: payload.where,
			};
			await context.dispatch("getMergeAccountingEntries", payloadAllAccountingEntries);
		}
	},
	async getMergeAccountingEntries(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Voucher";

		if (!payload.limit) {
			url = url + "?limit=100";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get invoice");
			throw error;
		}
		// console.log("getAccountingEntries", responseData);

		responseData.data = [...responseData.data, ...context.getters.accountingEntries];
		await context.commit("setAccountingEntry", responseData);
	},
	resetAccountingEntryListing(context) {
		context.commit("resetAccountingEntryListing");
	},
	async updateLockStatus(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + `/Voucher/UpdateLockStatus`;
		const token = context.rootGetters.token;
		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},

			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			let message = null;
			if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
				message = responseData.title;
				for (const key in responseData.errors) {
					message += ", " + key.replace("$.", "");
				}
			} else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
				message = responseData.message;
			}
			const error = new Error(message || "Failed to Update lock status");
			throw error;
		}
		return responseData.data;
	},
};
