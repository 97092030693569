<template>
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredNationalities"
    field="countryAndNationality"
    @complete="searchNationality"
    @dropdown-click="filteredNationalities"
    forceSelection
  />
</template>

<script>
import useGetNationality from "../../hooks/getNationality.js";
export default {
  setup() {
    const {
      filteredNationalities,
      searchText,
      isSharedNationalityReady,
      searchNationality,
    } = useGetNationality();

    return {
      filteredNationalities,
      searchText,
      isSharedNationalityReady,
      searchNationality,
    };
  },
  emits: ["load-finish"],
  watch: {
    isSharedNationalityReady(curValue) {
      if (curValue) {
        this.$emit("load-finish");
      }
    },
  },
};
</script>
