export default {
	requests(state) {
		return state.requests;
	},
	selectedRequests(state) {
		return state.selectedRequests;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	totalItem(state) {
		return state.totalItem;
	},
	blob(state) {
		return state.blob;
	},
	currentQueue(state) {
		return state.currentQueue;
	},
	presignedUrl(state) {
		return state.presignedUrl;
	}
};
