export default {
    title: "Refund Guarantee",
    createRefundGuarantee: "Create Refund Guarantee",
    titleListing: "Refund Guarantee List",
    dateRange: "Date Range",
    documentCode: "Document Code",
    unit: "Unit",
    address:"Address no.",
    customerName: "Customer Name",
    searchTextPlaceholder: "Fill up to search.",
    columnNetAmount: "Net Amount",
    refunded: "Refunded",
    printed: "Printed",
    columnCreateDate:"Create Date",
    columnUpdateDate:"Update Date", 
    columnCreateBy: "Create By",
    columnUpdateBy: "Update By",
    validateRoleSelect: "You don't have permission to select document.",
}