<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '70vw' }"
    :modal="true"
    :closable="false"
    :header="parameterTitleText"
  >
    <transition>
      <section
        class="flex flex-wrap"
        :class="textFormClass"
        v-if="isParameterVisible"
      >
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="type">{{ lang.type }}</label>
          <PvDropdown
            id="type"
            class="inputfield w-full mt-1"
            :options="types"
            optionValue="code"
            optionLabel="name"
            v-model="selectType"
          />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="periodStart">{{ lang.periodStart }}</label>
          <PvCalendar
            id="periodStart"
            v-model="periodStart"
            class="inputfield w-full mt-1"
            view="month"
            dateFormat="mm/yy"
            showIcon
          />
        </div>
        <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
          <label for="periodEnd">{{ lang.periodEnd }}</label>
          <PvCalendar
            id="periodEnd"
            v-model="periodEnd"
            class="inputfield w-full mt-1"
            view="month"
            dateFormat="mm/yy"
            showIcon
          />
        </div>
      </section>
    </transition>
    <div class="pt-2 pb-1">
      <PvButton
        icon="pi pi-plus"
        :label="lang.importDocument"
        class="mr-2 mb-2"
        @click="AddMeterToGl"
        v-if="!isAddMeter"
      />
      <PvButton
        icon="pi pi-chevron-left"
        :label="lang.back"
        @click="goBack"
        class="p-button-secondary mr-2 mb-2"
        v-if="isHideBack"
      />
      <PvButton
        icon="pi pi-check"
        :label="lang.confirm"
        @click="getMeterToGls"
        class="mr-2 mb-2"
        v-if="isAddMeter"
      />
    </div>

    <!-- ----------------------------list------------------------------------- -->

    <PvDataTable
      showGridlines
      :value="meterSelected"
      dataKey="period"
      :loading="isLoading"
      responsiveLayout="scroll"
      :scrollHeight="halfViewportHeight + 'px'"
      class="p-datatable-sm"
      :stripedRows="true"
      :class="textTableClass"
      v-if="!isAddMeter"
    >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
      <PvColumn
        field="productCode"
        :header="lang.code"
        style="min-width: 6rem"
        headerClass="h-center"
        bodyClass="text-center"
        sortable
      ></PvColumn>
      <PvColumn
        field="productName"
        :header="lang.name"
        style="min-width: 6rem"
        headerClass="h-center"
        bodyClass="text-left"
        sortable
      ></PvColumn>
      <PvColumn
        field="period"
        :header="lang.columnPeriod"
        style="min-width: 4rem"
        headerClass="h-center"
        bodyClass="text-center"
        sortable
      ></PvColumn>
      <PvColumn
        field="totalAmount"
        :header="lang.columnTotal"
        style="min-width: 3rem"
        headerClass="h-center"
        bodyClass="text-right"
        sortable
      >
        <template #body="slotProps">
          {{ formatCurrency(slotProps.data.totalAmount) }}
        </template>
      </PvColumn>
      <PvColumn
        field="voucherCode"
        :header="lang.voucherCode"
        style="min-width: 6rem"
        headerClass="h-center"
        bodyClass="text-center"
        sortable
      ></PvColumn>
      <PvColumn
        style="min-width: 4rem"
        headerClass="h-center"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <PvButton
            type="button"
            class="p-button-danger p-button-text py-0"
            icon="pi pi-times"
            @click="deleteRowMeterSelected(slotProps.data)"
          ></PvButton>
        </template>
      </PvColumn>
    </PvDataTable>

    <template #footer>
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog"
      />
      <PvButton
        :label="lang.autoGl"
        icon="pi pi-check"
        autofocus
        @click="selectMeterToGl"
        v-if="!isAddMeter"
      />
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
export default {
  props: ["openFrom"],
  emits: ["close", "meter-to-gl-selected"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const store = useStore();
    const {
      formatCurrency,
      formatDate,
      formatDateToISOString,
      formatPeriodToISOString,
    } = useFormat();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.accounting.edit,
          ...lang.th.gl.accounting.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.accounting.edit,
          ...lang.en.gl.accounting.listing,
        };
      }
    });

    return {
      textHeaderClass,
      textFormClass,
      textTableClass,
      lang: mergeLang,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      formatPeriodToISOString,
    };
  },
  data() {
    return {
      isParameterVisible: false,
      selectType: "W",
      types: [
        { name: this.lang.meterWater, code: "W" },
        { name: this.lang.meterElectric, code: "E" },
      ],
      periodStart: null,
      periodEnd: null,
      selectedMeterToGl: null,
      isLoading: true,
      selectedIsUnVoucher: true,
      getSelectedMeter: null,
      isAddMeter: false,
      selectedPage: 1,
      rowsPerPage: 100,
      isDuplicateMeter: false,
      messageError: null,
    };
  },
  async created() {
    this.setLoading(true);
    let date = new Date();
    // let newDate = new Date(date.setMonth(date.getMonth()-6));
    this.periodStart = new Date(date.getFullYear(), date.getMonth(), 1);
    this.periodEnd = new Date(date.getFullYear(), date.getMonth(), 1);

    // await this.getMeterToGls();
    if (this.meterSelected == null) {
      this.isAddMeter = true;
      this.isParameterVisible = true;
    }
    this.isLoading = false;
    this.setLoading(false);
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.isAddMeter = false;
      this.isParameterVisible = false;
      this.$emit("close");
    },
    deleteRowMeterSelected(data) {
      const index = this.meterSelected.findIndex((detail) => detail.period == data.period && detail.productCode == data.productCode);
      this.meterSelected.splice(index, 1);
    },
    async selectMeterToGl() {
      this.isLoading = true;

      // const localSearch = localStorage.getItem(
      //   "erp_search_accountingEntryList"
      // );
      // let search = JSON.parse(localSearch);
      let details = [];
      let requestMeter = {
        projectId: this.selectedProject.id,
        voucherBookId: this.$route.params.idBook,
      };

      this.meterSelected.forEach((element) => {
        // let alert = "";
        let product = "";
        if (element.productCode.substring(0, 1) === "W") {
          product = "W0";
        } else if (element.productCode.substring(0, 1) === "E") {
          product = "E0";
        }
        let meterData = this.accountingFrequentlies.find(
          (detail) => detail.code === product
        );
        if (!meterData) {
          this.$swal({
            icon: "warning",
            title: this.lang.meterAlertAccountingFrequently,
            confirmButtonText: this.lang.close,
            allowOutsideClick: false,
          });
          return;
        }
        let detail = {
          ProductCode: product,
          Period: element.period,
          TotalAmount: element.totalAmount,
        };
        details.push(detail);
      });
      let meters = { Meters: details };
      requestMeter = { ...requestMeter, ...meters };
      try {
        await this.$store.dispatch("glMeterToGl/getMeterToGl",requestMeter);
      } catch (error) {
        this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				this.setLoading(false);
      }

      this.AddMeterSelectedToGl();
      this.closeDialog();
      this.isLoading = false;
      // this.$emit("meter-to-gl-selected", this.selectedMeterToGl);
      this.onRowUnselect();
    },
    onRowUnselect() {
      this.selectedMeterToGl = null;
    },
    async getMeterToGls() {
      // console.log('getMeterToGls');
      this.setLoading(true);

      let where = {
        projectId: this.selectedProject.id,
        productCode: this.selectType,
        periodStart: this.selectedPeriodStart,
        periodEnd: this.selectedPeriodEnd,
        isUnVoucherOnly: this.selectedIsUnVoucher,
        postType: "add",
      };

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };

      try {
        await this.$store.dispatch("glMeterToGl/getMeters", payload);
      } catch (error) {
        this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				this.setLoading(false);
      }

      this.SetMeterSelected();

      this.isAddMeter = false;
      this.isParameterVisible = false;

      // console.log(this.meterToGls);

      this.setLoading(false);
    },
    AddMeterToGl() {
      this.isAddMeter = true;
      this.isParameterVisible = true;
    },
    goBack() {
      this.isAddMeter = false;
      this.isParameterVisible = false;
    },
    toggleParameterVisible() {
      this.isParameterVisible = !this.isParameterVisible;
    },
    async getMeterSelected() {
      this.isLoading = true;

      // if (this.meterSelected.length > 0) {
      //   this.isAddMeter = false;
      //   this.isParameterVisible = false;
      // }
      if (this.meterSelected.length <= 0 ) {
        this.isAddMeter = true;
        this.isParameterVisible = true;
      }
      this.isLoading = false;
    },
    AddMeterSelectedToGl() {
      let details = [];
      let number = 1;

      this.meterToGl.forEach((Detail) => {
        let meter = [{ProductType: Detail.period.substring(0,1), Period: Detail.period.substring(2)}];

        let detail = {
          accountId: Detail.accountId,
          accountCode: Detail.accountCode,
          accountName: Detail.accountName,
          creditAmount: Detail.creditAmount,
          debitAmount: Detail.debitAmount,
          description: Detail.description,
          group: null,
          headerId: Detail.headerId,
          id: Detail.id,
          itemNumber: number,
          jobId: null,
          projectId: this.selectedProject.id,
          sectionId: null,
          meters: meter,
        };
        details.push(detail);
        number++;
      });

      this.$emit("meter-to-gl-selected", details);
      this.isDuplicateMeter = false;
    },
    async SetMeterSelected(){
      await this.$store.dispatch("glMeterToGl/setMeterSelected", this.meters);
      // // let newData = [];
      // let meterSelected = this.meterSelected;
      // const meters = this.meters;
      // // console.log('meterSelected',meterSelected);
      // // console.log('meters',meters);
      // meters.forEach((Detail) => {
      //   const data = meterSelected.find((d) => d.period == Detail.period && d.productCode == Detail.productCode);
      //   if (!data) {
      //      meterSelected.push(Detail);
      //   }
      // });

      // // console.log('this.meterSelected',this.meterSelected);
    }
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
    parameterButtonText() {
      return this.isParameterVisible
        ? this.lang.hideParameters
        : this.lang.showParameters;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    meters() {
      return this.$store.getters["glMeterToGl/meters"];
    },
    selectedPeriodStart() {
      // console.log('selectedPeriodStart');
      let periodStart = null;
      if (this.periodStart) {
        periodStart = this.formatPeriodToISOString(this.periodStart);
      }
      return periodStart;
    },
    selectedPeriodEnd() {
      let periodEnd = null;
      if (this.periodEnd) {
        periodEnd = this.formatPeriodToISOString(this.periodEnd);
      }
      return periodEnd;
    },
    parameterTitleText() {
      return this.isAddMeter
        ? this.lang.meterToGlTitle
        : this.lang.meterToGlItem;
    },
    meterSelected() {
      return this.$store.getters["glMeterToGl/meterSelected"];
    },
    meterToGl() {
      return this.$store.getters["glMeterToGl/meterToGl"];
    },
    accountingFrequentlies() {
      return this.$store.getters["shared/accountingFrequentlyAccountings"];
    },
    isHideBack() {
      if(this.meterSelected.length > 0 && this.isAddMeter) 
        return true
      else 
        return false
    }
  },
  watch: {
    openFrom(curValue) {
      // console.log("openFrom1=",curValue);
      const Check = curValue.indexOf("create");
      //  console.log("openFrom2=",Check);
      if (Check < 0) {
        this.getMeterSelected();
      } else if (Check >= 0) {
        // console.log("000meterSelected=",this.meterSelected)
        if (this.meterSelected.length <= 0) {
          this.isAddMeter = true;
          this.isParameterVisible = true;
        }
      }
    },
  },
};
</script>
