export default {
    titleEdit: "Edit Debt Free Approve.",

    pendingDate: "Pending Calculate Date",
    pendingAmount: "Pending Amount",
    phoneNumber: "Phone Number",

    cancelReason1: "Incomplete evidence.",
    cancelReason2: "Still pending/Not yet debt free.",
    cancelReason3: "Suspended/Request for cancel.",
    reasonPlaceholder: "Select the reason.",
    validateReason: "Please select the reason.",

    code: "Document Code : :code",
    debtFreeApproveSuccess: "You approve debt free success",
    debtFreeDisapproveSuccess: "You disapprove debt free success",
}