export default {
  productApProducts(state) {
    return state.productAP.products;
  },
  productApCurrentPage(state) {
    return state.productAP.currentPage;
  },
  productApNextPage(state) {
    return state.productAP.currentPage + 1;
  },
  productApTotalPage(state) {
    return state.productAP.totalPage;
  },
  productApIsLastPage(_, getters) {
    return getters.productApCurrentPage === getters.productApTotalPage && getters.productApTotalPage !== 0;
  }
};
