<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M16.762 15.4724H14.6431"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M20.0033 19.5862V15.2113C20.0033 13.6461 18.7344 12.3772 17.1692 12.3772H5.83043C4.26515 12.3772 2.99625 13.6461 2.99625 15.2113V19.5862C2.99598 19.9622 3.14525 20.3229 3.41116 20.5888C3.67706 20.8547 4.03779 21.004 4.41384 21.0038H5.81442C6.1903 21.0038 6.55076 20.8544 6.81646 20.5885C7.08215 20.3226 7.23128 19.962 7.23101 19.5862V18.6498H15.7646V19.5862C15.7643 19.9624 15.9137 20.3233 16.1798 20.5892C16.446 20.8551 16.8069 21.0043 17.1832 21.0038H18.5837C18.9601 21.0045 19.3214 20.8555 19.5877 20.5896C19.8541 20.3236 20.0036 19.9626 20.0033 19.5862Z"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M17.9074 12.4762L17.0951 8.99875"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M14.0008 6.99792H8.05335C7.0688 6.99802 6.2139 7.67597 5.98949 8.6346L5.08911 12.4762"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<rect
			x="14.0008"
			y="1.99583"
			width="7.00292"
			height="7.00292"
			rx="2"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M18.6377 4.68495L17.1631 6.16357L16.2768 5.2742"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M8.29244 15.4724H6.17355"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M16.826 15.4724H14.7071"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M17.8845 12.2941L20.0033 11.2347"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M5.11513 12.2941L2.99625 11.2347"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: 24,
		},
		height: {
			type: [Number, String],
			default: 24,
		},
		iconColor: {
			type: String,
			default: "secondary",
		},
	},
	computed: {
		strokeColor() {
			return "p-stroke-" + this.iconColor;
		},
	},
};
</script>

<style lang="scss" scoped>
svg {
	display: inline-block;
	vertical-align: baseline;
	margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
