export default {
	titleToolbar: "Product AP",
	title: "Product AP",
	createProductAp: "Create Product AP",
	columnCode: "Code",
	columnNameTh: "Product and Service (Thai)",
	columnNameEn: "Product and Service (English)",
	columnType: "Type",
	columnCreateBy: "Create By",
	columnUpdateBy: "Update By",
	columnCreateDate:"Create Date",
	columnUpdateDate:"Update Date", 
    active: "Active",
    inActive: "InActive",
	deleteProductAp: "Delete Product AP", 
	askForDeleteProductAp: "Are you sure to delete Product AP :code ? ",
	deleteProductApSuccess: "Delete Product AP :code success",
    all: "All",
    code: "Code",
    name: "Name",

};
