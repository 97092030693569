<template>
	<base-toolbar
		:title="this.title"
		:dataButtons="dataButtons"
		@goBack="goBack()"
		@gotoCreate="download('PDF')"
        :hideBackButton="isHideBack"
    >
	</base-toolbar>
    <base-container>
        <div class="surface-card shadow-2 p-5 border-round my-5">
			<div class="flex justify-content-between align-items-center">
				<label class="font-lg font-normal">{{ title }}</label>
                <PvButton
                    icon="pi pi-file-excel"
                    :label="lang.buttonDownloadExcel"
                    @click="download('EXCEL')"
                    class="mr-2 mb-2"
                />
			</div>
            <PvDivider></PvDivider>
            <section class="flex flex-wrap fontlabel" :class="textFormClass">
                <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
                    <label for="letterEndDate">{{ lang.labelLetterEndDate }}</label>
                    <PvCalendar
                        id="letterEndDate"
                        v-model="letterEndDate"
                        class="inputfield w-full mt-1"
                        showIcon
                        dateFormat="dd/mm/yy"
                        :class="{ 'p-invalid': letterEndDateError }"
                        @date-select="resetLetterEndDateError()"
                        @keypress="resetLetterEndDateError()"
                        @blur="validateData()"
                    />
                    <small v-if="letterEndDateError" id="letter-end-date-help" class="p-error">
                        {{ lang.validateDateSelect}}
                    </small>
                </div>

                <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
                    <label for="letterDocumentDate">{{ lang.labelLetterDocumentDate }}</label>
                    <PvCalendar
                        id="letterDocumentDate"
                        v-model="letterDocumentDate"
                        class="inputfield w-full mt-1"
                        showIcon
                        dateFormat="dd/mm/yy"
                        :class="{ 'p-invalid': letterDocumentDateError }"
                        @date-select="resetLetterDocumentDateError()"
                        @keypress="resetLetterDocumentDateError()"
                        @blur="validateData()"
                    />
                    <small v-if="letterDocumentDateError" id="letter-document-date-help" class="p-error">
                        {{ lang.validateDateSelect }}
                    </small>
                </div>

                <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
                    <label for="letterConfirmDate">{{ lang.labelLetterConfirmDate }}</label>
                    <PvCalendar
                        id="letterConfirmDate"
                        v-model="letterConfirmDate"
                        class="inputfield w-full mt-1"
                        showIcon
                        dateFormat="dd/mm/yy"
                        :class="{ 'p-invalid': letterConfirmDateError }"
                        @date-select="resetLetterConfirmDateError()"
                        @keypress="resetLetterConfirmDateError()"
                        @blur="validateData()"
                    />
                    <small v-if="letterConfirmDateError" id="letter-confirm-date-help" class="p-error">
                        {{ lang.validateDateSelect }}
                    </small>
                </div>

                <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
                    <label for="letterDocumentBy">{{ lang.labelLetterDocumentBy }}</label>
                    <PvDropdown
                        class="inputfield w-full mt-1"
                        :options="lang.letterDocumentBy"
                        optionLabel="name"
                        optionValue="code"
                        v-model="selectedLetterDocumentBy"
                    />
                </div>

            </section>
        </div>
    </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
export default {
    setup() {
		const { lang } = useLang();
		const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
		const { formatDate, formatDateToISOString } = useFormat();
        const { getPreviewBlob, getDownloadBlob } = useGetFormPrint();
        const isHideBack = inject("isHideBack");
		const store = useStore();
		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return {
					...lang.th.base,
					...lang.th.ar.invoice.outstandingLetter,
				};
			} else {
				return {
					...lang.en.base,
					...lang.en.ar.invoice.outstandingLetter,
				};
			}
		});

		return {
			lang: mergeLang,
			textHeaderClass,
			textFormClass,
			textTableClass,
			formatDate,
			formatDateToISOString,
            getPreviewBlob,
            getDownloadBlob,
            isHideBack,
		};
	},
	data() {
		return {
			isLoading: false,
            letterEndDate: null,
            letterEndDateError: false,
            letterDocumentDate: null,
            letterDocumentDateError: false,
            letterConfirmDate: null,
            letterConfirmDateError: false,
            selectedLetterDocumentBy: "Building",
            // showReportPrintModal: false,
            // path: "",
            // reportMenu: null,
            // reportMenuDetail: null,
            // reportMenuId: "",
            // reportMenuDetailId: "",
		};
	},
	created() {
        // this.isLoading = true;
        this.initiateData();
        // await this.getReportMenu();
        // this.isLoading = false;
	},
	methods: {
		setLoading(bool) {
			this.$store.dispatch("setLoading", { value: bool });
		},
		goBack() {
			this.$router.push({name: "home"})
		},
        initiateData() {
            this.letterEndDate = new Date();
            this.letterEndDateError = false;
            this.letterDocumentDate = new Date();
            this.letterDocumentDateError = false;
            this.letterConfirmDate = new Date();
            this.letterConfirmDateError = false;
            this.selectedLetterDocumentBy = "Building";
        },
        // async getReportMenu() {
        //     try {
        //         let where = {
        //             NameThai: "หนังสือยืนยันยอดค้าง",
        //         };
        //         const payload = {
        //             page: 1,
        //             limit: 100,
        //             where,
        //         };
        //         await this.$store.dispatch("reportMenu/getReportMenu", payload );
        //         this.reportMenu = this.$store.getters["reportMenu/selectedReportMenu"];
        //         await this.getReportMenuDetails(this.reportMenu.id);
        //     } catch (error) {
        //         this.$swal({
        //             icon: "error",
        //             html: `<p class="${this.textAlertClass}">${error}</p>`,
        //             confirmButtonText: this.lang.close,
        //             allowOutsideClick: false,
        //         });
        //         this.isLoading = false;
        //     }            
        // },
        // async getReportMenuDetails(reportMenuId) {
        //     try {
        //         let where = {
        //             reportMenuId: reportMenuId,
        //         };
        //         const payload = {
        //             page: 1,
        //             limit: 100,
        //             where,
        //         };
        //         await this.$store.dispatch("reportMenuDetail/getReportMenuDetail", payload); 
        //         this.reportMenuDetail = this.$store.getters["reportMenuDetail/reportMenuDetails"];
        //     } catch (error) {
        //         this.$swal({
        //             icon: "error",
        //             html: `<p class="${this.textAlertClass}">${error}</p>`,
        //             confirmButtonText: this.lang.close,
        //             allowOutsideClick: false,
        //         });
        //         this.isLoading = false;        
        //     }
        // },
        validateData() {
            if (this.letterEndDate == null) {
                this.letterEndDateError = true;
                return true;
            }
            if (this.letterDocumentDate == null) {
                this.letterDocumentDateError = true;
                return true;
            }
            if (this.letterConfirmDate == null) {
                this.letterConfirmDateError = true;
                return true;
            }
        },
        resetLetterEndDateError() {
            this.letterEndDateError = false;  
        },
        resetLetterDocumentDateError() {
            this.letterDocumentDateError = false;  
        },
        resetLetterConfirmDateError() {
            this.letterConfirmDateError = false;  
        },     
        downloadExcel() {
            if (this.validateData()) {
                return;
            }
            // console.log('downloadExcel.selectedLetterEndDate=',this.selectedLetterEndDate);
        },
		async download(typeReport) {
            if (this.validateData()) {
                return;
            }
            this.setLoading(true);
            this.getPayload(typeReport);
            try {
                await this.$store.dispatch("shared/printReport", this.payload);
                this.setLoading(false);
                // console.log(this.fileBlob);
                if (typeReport === "PDF") {
                this.getPreviewBlob(this.fileBlob);
                } else {
                const d = new Date();
                let dateText = d.toISOString();
                this.getDownloadBlob(
                    `${this.payload.fileName}_${dateText}.xlsx`,
                    this.fileBlob
                );
                }
            } catch (error) {
                this.$toast.add({
                severity: "error",
                summary: this.lang.notificationDialogHeader,
                detail: error,
                life: 3000,
                });
            }
            this.setLoading(false);
            // if (this.reportMenu) {
            //     this.reportMenuId = this.reportMenu.id;
            // }
            // if (this.reportMenuDetail
            // ) {
            //     this.reportMenuDetailId = this.reportMenuDetail.id;
            // }        
            // this.openReport = true;
            // this.showReportPrintModal = true;
            // if(this.reportObj.length == 1) {
            //     this.showReportPrintModal = false;
            // }	
		},
        getPayload(typeReport) {
            this.setLoading(true);
            let path = "invoice/outstanding_letter";
            if (typeReport == "PDF") {
                path = path + ".pdf";
            } else if (typeReport == "JASPEREXCEL") {
                path = path + ".xlsx";
            } else {
                path = path + "_excel.xlsx";
            }
            this.payload = {
                docType: typeReport,
                path: path,
                fileName: "outstanding_letter",
                parameter: JSON.stringify(this.parameter),
            };
        },
    },
    computed: {
		title() {
			return this.lang.title;
		},
		selectedProject() {
			return this.$store.getters.selectedProject;
		},
		dataButtons() {
			let dataButtons = [
				{
                    id: "editCaption",
					caption: this.lang.buttonPrint,
					class: "mr-2",
					icon: "icon-printer",
					iconColor: "white",
					action: "gotoCreate",
					isShow: true,
				},
			]
			return dataButtons
		},
        selectedLetterEndDate() {
            let letterEndDate = null;
            if (this.letterEndDate) {
                letterEndDate = this.formatDateToISOString(this.letterEndDate);
            }
            return letterEndDate;
        },
        selectedLetterDocumentDate() {
            let letterDocumentDate = null;
            if (this.letterDocumentDate) {
                letterDocumentDate = this.formatDateToISOString(this.letterDocumentDate);
            }
            return letterDocumentDate;
        },        
        selectedLetterConfirmDate() {
            let letterConfirmDate = null;
            if (this.letterConfirmDate) {
                letterConfirmDate = this.formatDateToISOString(this.letterConfirmDate);
            }
            return letterConfirmDate;
        },
        parameter() {
        let parameterData = {
            ProjectId: this.selectedProject.id,
            EndDate: this.selectedLetterEndDate,
            DocumentDate: this.selectedLetterDocumentDate,
            ConfirmDate: this.selectedLetterConfirmDate,
            DocumentBy: this.selectedLetterDocumentBy,
        };
        return parameterData;
        },
        fileBlob() {
            return this.$store.getters["shared/blob"];
        },
    },
}
</script>