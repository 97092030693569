import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default function useGetAccountingFrequently() {
  const store = useStore();
  let searchText = ref(null);

  const accountingFrequentlies = computed(() => {
    return store.getters["shared/accountingFrequentlyAccountings"];
  });

  const accountingFrequentlyIsLastPage = computed(() => {
    return store.getters["shared/accountingFrequentlyIsLastPage"];
  });

  const filteredAccountingFrequentlies = computed(() => {
    if (!searchText.value) {
      //ช่องว่าง
      const result = [...accountingFrequentlies.value];
      return result;
    } else {
      return accountingFrequentlies.value.filter((accountingFrequently) => {
        return accountingFrequently.searchText
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const searchAccountingFrequently = function (event) {
    // console.log(event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchText.value = "";
      searchText.value = null;
    } else {
      searchText.value = event.query;
    }
  };

  let isSharedAccountingFrequentlyReady = ref(false);

  const getAllAccountingFrequentlies = async function () {
    if (!accountingFrequentlyIsLastPage.value) {
      await store.dispatch("shared/getAllAccountingFrequentlies");
    }
    isSharedAccountingFrequentlyReady.value = true;
  };

  onMounted(async () => {
    await getAllAccountingFrequentlies();
  });

  return {
    searchAccountingFrequently,
    filteredAccountingFrequentlies,
    searchText,
    isSharedAccountingFrequentlyReady
  };
}
