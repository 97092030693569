<template>
	<PvAutoComplete
		:dropdown="true"
		:suggestions="accountingParents"
		field="codeAndName"
		@complete="searchAccountingParent"
		@dropdown-click="accountingParents"
		forceSelection
	/>
</template>

<script>
import useGetAccountingParent from "../../hooks/getAccountingParent.js";
export default {
	props: ["category"],
	setup() {
		const { filteredAccountingParents, searchTextAccountingParent, isSharedAccountingParentReady, searchAccountingParent } =
		useGetAccountingParent();

		return {
			searchAccountingParent,
			filteredAccountingParents,
			searchTextAccountingParent,
			isSharedAccountingParentReady,
		};
	},
	emits: ["load-finish"],
	computed: {
		accountingParents() {
			let result = this.filteredAccountingParents;
			if(this.category) {
				result = this.filteredAccountingParents.filter((data) => data.type.trim() == this.category.trim());
			}
			return result
		}
	},
	watch: {
		isSharedAccountingParentReady(curValue) {
			if (curValue) {
				this.$emit("load-finish");
			}
		},
	},
};
</script>
