import { useStore } from "vuex";
import { ref, computed } from "vue";
export default function useGetSupplier() {
  const store = useStore();
  let searchText = ref(null);

  const suppliers = computed(() => {
    return store.getters["shared/supplierSuppliers"];
  });

  const supplierIsLastPage = computed(() => {
    return store.getters["shared/supplierIsLastPage"];
  });

  const filteredSuppliers = computed(() => {
    if (!searchText.value) {
      //ช่องว่าง
      const result = [...suppliers.value];
      return result;
    } else {
      return suppliers.value.filter((supplier) => {
        return supplier.codeAndName
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const searchSupplier = function (event) {
    // console.log(event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchText.value = "";
      searchText.value = null;
    } else {
      searchText.value = event.query;
    }
  };

  let isSharedSupplierReady = ref(false);

  const getAllSuppliers = async function () {
    if (!supplierIsLastPage.value) {
      await store.dispatch("shared/getAllSuppliers");
    }
    isSharedSupplierReady.value = true;
  };

  const getSupplierByCode = function (code) {
    return suppliers.value.find((supplier) => {
      return supplier.code.toLowerCase() === code.trim().toLowerCase();
    });
  };

  // onMounted(async () => {
  //   await getAllSuppliers();
  // });

  return {
    searchSupplier,
    filteredSuppliers,
    searchText,
    getSupplierByCode,
    isSharedSupplierReady,
    getAllSuppliers,
  };
}
