export default {
    title: "ติดตามสถานะปลอดหนี้",
    debtFreeList: "รายการติดตามการปลอดหนี้",
    dateRange: "ช่วงวันที่เอกสาร",
    unitAddress: "ยูนิต/บ้านเลขที่",
    ownerName: "ชื่อเจ้าของ",
    searchTextPlaceholder: "กรอกข้อมูลเพื่อค้นหา",
    followupStatusAll: "สถานะทั้งหมด",
    followupStatus1: "1.เปลี่ยนมือ",
    followupStatus2: "2.ขายไม่ได้",
    followupStatus3: "3.รีไฟแนนซ์",
    followupStatus4: "4.ใบปลอดหนี้หมดอายุ",
    followupStatus5: "5.อื่นๆ",
    unitCode: "ยูนิต",
    unitAddressNo: "บ้านเลขที่",
    documentCode: "เลขที่เอกสาร",
    requestDate: "วันที่ร้องขอ",
    followupStatus: "ผลการติดตาม",
    followupDate: "วันที่ติดตาม",
    followup: "ติดตาม",
    validateUnFollowupTitle: "ทำรายการไม่ได้",
    validateUnFollowupTitleDetail: "รายการดังกล่าวได้รับการปรับปรุงสถานะปลอดหนี้เรียบร้อยแล้ว",
}