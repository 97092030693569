export default {
  async getFiles(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/File";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get file"
      );
      throw error;
    }
    // console.log(responseData);

    // const data = responseData.data;

    context.commit("setFile", responseData);

  },
  async getFile(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/File?Page=1&Limit=10";

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get file"
      );
      throw error;
    }
    // console.log(responseData);

    // const data = responseData.data;
    if (responseData.data.length > 0) {
      context.commit("setSelectedFile", responseData.data[0]);
    }
  },
  async addFile(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/File/Add";

    const fallbackErrorMessage = `Failed to add file`
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }

    context.commit("setSelectedFile", responseData.data);

  },
  async deleteFile(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/File/DeleteDetail";

    const fallbackErrorMessage = `Failed to delete file`
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }

    context.commit("setSelectedFile", responseData.data);

  },
  async uploadFile(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Aws/UploadFileToS3`;
    const fallbackErrorMessage = `Failed to upload file`;
    const token = context.rootGetters.token;

    let formData = new FormData();
    formData.append("file", payload.file);

    // console.log('payload.file=', payload.file);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    });

    const responseData = await response.json();

    if (!response.ok) {
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }
    await context.commit("setSelectedFile", responseData.data);
  },
  async UploadFileToS3AsPublic(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Aws/UploadFileToS3AsPublic`;
    const fallbackErrorMessage = `Failed to upload file`;
    const token = context.rootGetters.token;
    let formData = new FormData();
    formData.append("file", payload.file);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    });
    const responseData = await response.json();
    if (!response.ok) {
      let message = null;
      if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.", "");
        }
      } else if (
        Object.prototype.hasOwnProperty.call(responseData, "message")
      ) {
        message = responseData.message;
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }
    await context.commit("setSelectedFile", responseData.data);
  },
  async downloadFile(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL+`/Aws/${payload.key}`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });  

    response.blob().then(function(blob) {
      var url = window.URL.createObjectURL(blob);
      // window.location.assign(url);
      var a = document.createElement('a');
      a.href = url;
      a.download = `${payload.name}`;
      document.body.appendChild(a);
      a.click();
      a.remove(); //afterwards we remove the element again
    });
  },
  async downloadFile2(context, payload) {
    const token = context.rootGetters.token;

    const response = await fetch(payload, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });  
    let urlBlob = ''  
    
    let blob = await response.blob();
    urlBlob = window.URL.createObjectURL(blob);
    context.commit('setBlob', urlBlob);
  },
  resetSelectedFile(context) {
    context.commit("setSelectedFile",null);
  },
  resetFile(context) {
    context.commit("resetFile",null);
  }
};
