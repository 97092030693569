export default({
	title: "หนังสือยืนยันยอดค้าง",
	buttonPrint: "พิมพ์หนังสือยืนยันยอดค้าง",
	buttonDownloadExcel: "ดาวน์โหลด Excel",
	labelLetterEndDate: "ยอดค้าง ณ วันที่",
	labelLetterDocumentDate: "วันที่เอกสาร",
	labelLetterConfirmDate: "วันที่ยืนยันยอด",
	labelLetterDocumentBy: "หนังสือในนาม",
	letterDocumentBy: [
        { name: "ผู้จัดการอาคาร", code: "Building" },
        { name: "ผู้จัดการหมู่บ้าน", code: "Village" },
        { name: "ผู้จัดการชุมชน", code: "Community" },
    ],	
	validateDateSelect: "กรุณาเลือกวันที่",
})