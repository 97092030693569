<template>
    <PvAutoComplete
      :dropdown="true"
      :suggestions="filteredDbdBusinessType"
      field="codeAndNameDbd"
      @complete="searchDbdBusinessType"
      @dropdown-click="filteredDbdBusinessType"
      forceSelection
    />
  </template>
  
  <script>
  import { onMounted, watch } from "vue";
  import useGetDbdBusinessType from "../../hooks/getDbdBusinessType";
  export default {
    emits: ["load-finish"],
    props: {
      isLoadingData: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, context) {
      const {
        searchDbdBusinessType,
        filteredDbdBusinessType,
        isSharedDbdBusinessTypeReady,
        getAllDbdBusinessType,
      } = useGetDbdBusinessType();
  
      onMounted(async () => {
        if (props.isLoadingData) {
          await getAllDbdBusinessType();
        }
      });
  
      watch(isSharedDbdBusinessTypeReady, function (curValue) {
        if (curValue) {
          context.emit("load-finish");
        }
      });
  
      return {
        searchDbdBusinessType,
        filteredDbdBusinessType,
      };
    },
  };
  </script>  