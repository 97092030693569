import { ref, onMounted, onUnmounted } from 'vue';
export default function useShortcut() {
    const hotkeyF2 = ref(null);
    const hotkeyF3 = ref(null);
    const hotkeyF4 = ref(null);
    const hotkeyF5 = ref(null);
    const hotkeyF6 = ref(null);
    const hotkeyF8 = ref(null);
    const hotkeyF10 = ref(null);
    const hotkeyF11 = ref(null);
    const hotkeyEscape = ref(null);

    const keyUp = function (event) {
        var name = event.key;
        // var code = event.code;
        if (name === "Control" || name === "Alt") {
            // Do nothing.
            return;
        }
        if (name === "F2") {
            processHotkey(hotkeyF2);
        } else if (name === "F3") {
            processHotkey(hotkeyF3);
        } else if (name === "F4") {
            processHotkey(hotkeyF4);
        } else if (name === "F5") {
            processHotkey(hotkeyF5);
        } else if (name === "F6") {
            processHotkey(hotkeyF6);
        } else if (name === "F8") {
            processHotkey(hotkeyF8);
        } else if (name === "F10") {
            processHotkey(hotkeyF10);
        } else if (name === "F11") {
            processHotkey(hotkeyF11);
        } else if (name === "Escape") {
            processHotkey(hotkeyEscape);
        }  
        // console.log(
        //     `Key pressed ${name} \n Key code Value: ${code}`
        // );
    }

    const processHotkey = (object) => {
        // console.log(object);
        if (object.value) {
            if (object.value.$el.tagName === "BUTTON") {
                object.value.$el.click();
            }
        }
    }

    onMounted(() => {
        document.addEventListener("keyup", keyUp, false);
    });

    onUnmounted(() => {
        document.removeEventListener("keyup", keyUp, false);
    });

    return {
        hotkeyF2,
        hotkeyF3,
        hotkeyF4,
        hotkeyF5,
        hotkeyF6,
        hotkeyF8,
        hotkeyF10,
        hotkeyF11,
        hotkeyEscape
    };
}