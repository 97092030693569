<template>
	<PvDialog
		:breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
		:style="{ width: '90vw' }"
		:modal="true"
		:closable="false">
		<base-form-print-modal
			v-model:visible="showFormPrintModal"
			:search="searchTextPrint"
			@close="showFormPrintModal = false"
			@path-selected="formPrintModalSelected"></base-form-print-modal>

		<PvDialog
			:breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
			:style="{ width: '80vw' }"
			:modal="true"
			:closable="false"
			v-model:visible="showWithholdingTax">
			<base-withholding-tax-view 
				:rvhid="this.ptId" 
				:supplierId="selectedReceiptSupplier.id"
				:receiptDifferenceAmount="receiptDifferenceAmount">
			</base-withholding-tax-view>
			<template #footer>
				<PvButton
					:label="lang.close"
					icon="pi pi-times"
					class="p-button-text"
					@click="closeWithholdingTax()" />
			</template>
		</PvDialog>

		<PvDialog
			:breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
			:style="{ width: '90vw' }"
			:modal="true"
			:closable="false"
			v-model:visible="showPayout">
			<base-bank-view :type="-1" :rvhid="this.ptId" :supplierCode="selectedReceiptSupplier.code">
			</base-bank-view>
			<template #footer>
				<PvButton
					:label="lang.close"
					icon="pi pi-times"
					class="p-button-text"
					@click="closePayout()" />
			</template>
		</PvDialog>

		<PvDialog
			:breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
			:style="{ width: '90vw' }"
			:modal="true"
			:closable="false"
			v-model:visible="showImportPurchaseInvoice">
			<base-purchase-invoice-dialog
				:receiptSupplierId="this.selectedReceiptSupplier.id"
				:invouceCode="this.invouceCode"
				:moduleCode="this.moduleCode"
				:vatRate="this.vatDocumentBookRate"
				@import="setImportPurchaseInvoice"
				@close="closeImportPurchaseInvoice()"></base-purchase-invoice-dialog>
			<!-- <template #footer>
				<PvButton
					:label="lang.close"
					icon="pi pi-times"
					class="p-button-text"
					@click="closeImportPurchaseInvoice()"
				/>
			</template> -->
		</PvDialog>

		<div class="flex justify-content-between align-items-center" style="padding-top: 20px">
			<div class="my-3">
				<PvButton
					icon="pi pi-chevron-left"
					:label="lang.back"
					@click="goBack"
					class="mr-2 p-button-secondary"
					v-if="isHideBack" />
			</div>
			<h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
		</div>

		<transition name="fade" mode="out-in">
			<div class="surface-card shadow-2 p-3 border-round">
				<div class="pt-2 pb-1">
					<PvButton
						icon="pi pi-file"
						:label="lang.create"
						@click="createNewDocument()"
						class="p-button-secondary mr-2 mb-2" />
					<PvButton
						icon="pi pi-save"
						:label="lang.save"
						class="mr-2 mb-2"
						@click="savePurchaseReceipt()"
						:disabled="isDocumentLocked" />
					<PvButton
						icon="pi pi-file-pdf"
						:label="lang.printPdf"
						class="mr-2 mb-2"
						@click="selectPrintForm()"
						:disabled="isCreateDocument" />
					<PvButton
						icon="pi pi-download"
						:label="lang.importPurchaseInvoice"
						class="mr-2 mb-2"
						@click="getImportPurchaseInvoice()"
						:disabled="isDocumentLocked" />
					<PvButton
						icon="pi pi-times"
						:label="lang.cancel"
						class="p-button-outlined p-button-danger mr-2 mb-2"
						@click="askForCancel()"
						:disabled="isDocumentLocked || isCreateDocument" />
				</div>
				<div class="flex flex-wrap" :class="textFormClass">
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="receiptCode">{{ lang.inputReceiptCode }}</label>
						<PvInputText
							id="receiptCode"
							v-model="receiptCode"
							type="text"
							class="inputfield w-full mt-1 p input-disable"
							readonly />
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="receiptReferenceCode">{{ lang.inputReceiptReferenceCode }}</label>
						<PvInputText
							id="receiptReferenceCode"
							v-model="receiptReferenceCode"
							type="text"
							class="inputfield w-full mt-1 p"
							:readonly="isDocumentLocked" />
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="receiptDate">{{ lang.inputReceiptDate }}</label>
						<PvCalendar
							id="receiptDate"
							v-model="receiptDate"
							class="inputfield w-full mt-1"
							showIcon
							dateFormat="dd/mm/yy"
							:disabled="isDocumentLocked" />
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="receiptVatSendDate">{{ lang.inputReceiptVatSendDate }}</label>
						<PvCalendar
							id="receiptVatSendDate"
							v-model="receiptVatSendDate"
							class="inputfield w-full mt-1"
							showIcon
							dateFormat="dd/mm/yy"
							:disabled="isDocumentLocked" />
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="receiptSupplier">{{ lang.inputReceiptSupplier }}</label>
						<base-supplier-auto-complete
							id="receiptSupplier"
							v-model="selectedReceiptSupplier"
							class="inputfield w-full mt-1"
							@load-finish="supplierDataReady()"
							:disabled="isDocumentLocked || !isCreateDocument"
							:class="{ 'p-invalid': receiptSupplierError }" />
						<small v-if="receiptSupplierError" id="supplier-help" class="p-error">{{
							lang.validateNoReceiptSupplierSelect
						}}</small>
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="receiptEmployee">{{ lang.inputEmployeeName }}</label>
						<!-- <PvInputText
							id="receiptEmployeeName"
							type="text"
							class="inputfield w-full mt-1"
							readonly
							v-model.trim="receiptEmployeeName"
						/> -->
						<base-employee-auto-complete
							id="receiptEmployee"
							v-model="selectedReceiptEmployee"
							class="inputfield w-full mt-1"
							@load-finish="employeeDataReady()"
							:disabled="isDocumentLocked"
							:class="{ 'p-invalid': receiptEmployeeError }" />
						<small v-if="receiptEmployeeError" id="employee-help" class="p-error">{{
							lang.validateNoReceiptEmployeeSelect
						}}</small>
					</div>

					<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
						<label for="receiptRemark1">{{ lang.inputRemark1 }}</label>
						<PvInputText
							id="receiptRemark1"
							type="text"
							class="inputfield w-full mt-1"
							v-model.trim="receiptRemark1" />
					</div>
					<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
						<label for="receiptRemark2">{{ lang.inputRemark2 }}</label>
						<PvInputText
							id="receiptRemark2"
							type="text"
							class="inputfield w-full mt-1"
							v-model.trim="receiptRemark2" />
					</div>
				</div>
				<section class="flex flex-wrap mt-5" :class="textFormClass">
					<div class="pl-0 pr-2">
						<PvButton
							type="button"
							icon="pi pi-file"
							class="mr-2 mb-2"
							:label="lang.buttonWithholdingTax"
							@click="getWithholdingTax()" />
						<PvButton
							type="button"
							icon="pi pi-file"
							class="mr-2 mb-2"
							:label="lang.buttonPayout"
							@click="getPayout()" />
					</div>
				</section>

				<PvDataTable
					showGridlines
					:value="receiptDetails"
					editMode="cell"
					dataKey="id"
					responsiveLayout="scroll"
					:scrollHeight="halfViewportHeight + 'px'"
					class="p-datatable-sm my-5"
					:class="textTableClass"
					:loading="isLoading"
					@cell-edit-complete="onCellEditComplete"
					:stripedRows="true">
					<template #empty>
						<div class="w-full p-3" Style="text-align: center;">
						<label>{{ lang.tableEmpty }}</label>
						</div>
					</template>
					<PvColumn
						field="itemNo"
						:header="lang.columnItemNo"
						style="min-width: 1rem"
						headerClass="h-center"
						bodyClass="text-center">
					</PvColumn>
					<!-- <PvColumn
						field="moduleCode"
						:header="lang.columnModuleCode"
						style="min-width: 2rem"
						headerClass="h-center"
						bodyClass="text-center"
					>
					</PvColumn> -->
					<PvColumn
						field="documentBookCode"
						:header="lang.columnDocumentBookCode"
						style="min-width: 2rem"
						headerClass="h-center"
						bodyClass="text-center">
						<!-- <template #editor="{ data, field }">
							<PvInputText type="text" v-model.trim="data[field]" autofocus />
						</template> -->
					</PvColumn>
					<PvColumn
						field="receiptDetailCode"
						:header="lang.columnReceiptDetailCode"
						style="min-width: 6rem"
						headerClass="h-center">
						<template #editor="{ data, field }" v-if="isCreateDocument">
							<PvInputText type="text" v-model.trim="data[field]" autofocus />
						</template>
					</PvColumn>
					<PvColumn
						field="referenceDocument"
						:header="lang.columnReferenceDocument"
						style="min-width: 3rem"
						headerClass="h-center">
					</PvColumn>
					<PvColumn
						field="receiptDetailDate"
						:header="lang.columnReceiptDetailDate"
						style="min-width: 3rem"
						headerClass="h-center"
						bodyClass="text-center">
						<template #body="slotProps">
							<div v-if="slotProps.data.receiptId">
								{{ formatDate(slotProps.data.receiptDetailDate) }}
							</div>
						</template>
					</PvColumn>
					<!-- <PvColumn
						field="statusPaid"
						:header="lang.columnStatusPaid"
						style="min-width: 1rem"
						headerClass="h-center"
						bodyClass="text-center"
					>
					</PvColumn>
					<PvColumn
						field="statusWithholdingTax"
						:header="lang.columnStatusWithholdingTax"
						style="min-width: 1rem"
						headerClass="h-center"
						bodyClass="text-center"
					>
					</PvColumn> -->
					<PvColumn
						field="netAmount"
						:header="lang.columnNetAmount"
						style="min-width: 2rem"
						headerClass="h-center"
						bodyClass="text-right">
						<template #body="slotProps">
							<div v-if="slotProps.data.netAmount">
								{{ formatCurrency(slotProps.data.netAmount) }}
							</div>
						</template>
					</PvColumn>
					<PvColumn
						field="differenceAmount"
						:header="lang.columnDifferenceAmount"
						style="min-width: 2rem"
						headerClass="h-center"
						bodyClass="text-right">
						<template #body="slotProps">
							<div v-if="slotProps.data.differenceAmount">
								{{ formatCurrency(slotProps.data.differenceAmount) }}
							</div>
						</template>
					</PvColumn>
					<!-- <PvColumn
						field="actualPayAmount"
						:header="lang.columnPayAmount"
						style="min-width: 2rem"
						headerClass="h-center"
						bodyClass="text-right">
						<template #body="slotProps">
							<div v-if="slotProps.data.actualPayAmount">
								{{ formatCurrency(slotProps.data.actualPayAmount) }}
							</div>
						</template>

						<template #editor="{ data, field }" v-if="!isDocumentLocked">
							<PvInputNumber
								class="inputfield w-full mt-1"
								v-model="data[field]"
								mode="decimal"
								:minFractionDigits="2"
								:maxFractionDigits="2"
								autofocus
              					@focus="$event.target.select()" />
						</template>
					</PvColumn> -->
					<PvColumn
						field="receiptPayAmount"
						:header="lang.columnPayAmount"
						style="min-width: 2rem"
						headerClass="h-center"
						bodyClass="text-right">
						<template #body="slotProps">
							<div v-if="slotProps.data.receiptPayAmount">
								{{ formatCurrency(slotProps.data.receiptPayAmount) }}
							</div>
						</template>

						<template #editor="{ data, field }" v-if="!isDocumentLocked">
							<PvInputNumber
								class="inputfield w-full mt-1"
								v-model="data[field]"
								mode="decimal"
								:minFractionDigits="2"
								:maxFractionDigits="2"
								autofocus
              					@focus="$event.target.select()" />
						</template>
					</PvColumn>
					<PvColumn
						field="actualPayAmount"
						:header="lang.columnPaidAmount"
						style="min-width: 2rem"
						headerClass="h-center"
						bodyClass="text-right">
						<template #body="slotProps">
							<div v-if="slotProps.data.actualPayAmount">
								{{ formatCurrency(slotProps.data.actualPayAmount) }}
							</div>
						</template>
					</PvColumn>
					<PvColumn>
						<template #body="slotProps">
							<PvButton
								type="button"
								class="p-button-danger p-button-text py-0"
								icon="pi pi-times"
								@click="deleteReceiptDetail(slotProps.data)"></PvButton
						></template>
					</PvColumn>
				</PvDataTable>

				<div class="flex flex-wrap" :class="textFormClass">
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="discountAmount">{{ lang.inputDiscount }}</label>
						<PvInputText
							id="discountAmount"
							type="text"
							class="inputfield w-full mt-1"
							v-model.trim="discountAmount"
							:readonly="isDocumentLocked"
							@blur="calculateDiscountAmount()" />
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="receiptDiscountAmount">{{ lang.inputDiscountAmount }}</label>
						<PvInputNumber
							id="receiptDiscountAmount"
							class="inputfield w-full mt-1"
							inputClass="input-disable"
							v-model="receiptDiscountAmount"
							mode="decimal"
							:minFractionDigits="2"
							:maxFractionDigits="2"
							readonly>
							<small v-if="receiptDiscountAmountError" id="discount-help" class="p-error">{{
								lang.validateDiscountAmount
							}}</small>
						</PvInputNumber>
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="receiptWithholdingTaxAmount">{{ lang.inputWithholdingTaxAmount }}</label>
						<PvInputNumber
							id="receiptWithholdingTaxAmount"
							class="inputfield w-full mt-1"
							inputClass="input-disable"
							v-model="receiptWithholdingTaxAmount"
							mode="decimal"
							:minFractionDigits="2"
							:maxFractionDigits="2"
							readonly />
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="receiptAddAmount">{{ lang.inputAddAmount }}</label>
						<PvInputNumber
							id="receiptAddAmount"
							class="inputfield w-full mt-1"
							v-model="receiptAddAmount"
							mode="decimal"
							:minFractionDigits="2"
							:maxFractionDigits="2"
							:readonly="isDocumentLocked" />
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="receiptSubAmount">{{ lang.inputSubAmount }}</label>
						<PvInputNumber
							id="receiptSubAmount"
							class="inputfield w-full mt-1"
							v-model="receiptSubAmount"
							mode="decimal"
							:minFractionDigits="2"
							:maxFractionDigits="2"
							:readonly="isDocumentLocked" />
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="receiptActualPayAmount">{{ lang.inputActualPayAmount }}</label>
						<PvInputNumber
							id="receiptActualPayAmount"
							class="inputfield w-full mt-1"
							inputClass="input-disable"
							v-model="receiptActualPayAmount"
							mode="decimal"
							:minFractionDigits="2"
							:maxFractionDigits="2"
							readonly />
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="receiptDifferenceAmount">{{ lang.inputDifferenceAmount }}</label>
						<PvInputNumber
							id="receiptDifferenceAmount"
							class="inputfield w-full mt-1"
							inputClass="input-disable"
							v-model="receiptDifferenceAmount"
							mode="decimal"
							:minFractionDigits="2"
							:maxFractionDigits="2"
							readonly />
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="receiptTotalAmount">{{ lang.inputTotalAmount }}</label>
						<PvInputNumber
							id="receiptTotalAmount"
							class="inputfield w-full mt-1"
							inputClass="input-disable"
							v-model="receiptTotalAmount"
							mode="decimal"
							:minFractionDigits="2"
							:maxFractionDigits="2"
							readonly />
					</div>
					<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
						<label for="receiptNetAmount">{{ lang.inputNetAmount }}</label>
						<PvInputNumber
							id="receiptNetAmount"
							class="inputfield w-full mt-1"
							inputClass="input-disable"
							v-model="receiptNetAmount"
							mode="decimal"
							:minFractionDigits="2"
							:maxFractionDigits="2"
							readonly />
					</div>
				</div>
			</div>
		</transition>
		<template #footer>
			<PvButton
				:label="lang.close"
				icon="pi pi-times"
				class="p-button-text"
				@click="closeDialog()" />
		</template>
	</PvDialog>
</template>
<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetFormPrint from "../../hooks/getFormPrint.js";
import baseWithholdingTaxView from "../../components/withholdingTax/BaseWithholdingTaxView.vue";
import baseBankView from "../../components/bank/BaseBankView.vue";
import basePurchaseInvoiceDialog from "../../components/purchaseReceipt/BasePurchaseInvoiceDialog.vue";
export default {
	props: ["id", "apBook", "openFrom"],
	emits: ["import", "close"],
	components: {
		baseWithholdingTaxView,
		baseBankView,
		basePurchaseInvoiceDialog,
	},
	setup() {
		const { lang } = useLang();
		const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
		const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
		const { getFormPrints, searchTextPrint, isSharedFormPrintReady, getPreviewBlob, filteredFormPrints} =
			useGetFormPrint();
		const store = useStore();
		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return { ...lang.th.base, ...lang.th.ap.purchaseReceipt.edit };
			} else {
				return { ...lang.en.base, ...lang.en.ap.purchaseReceipt.edit };
			}
		});

		const isHideBack = inject("isHideBack");

		return {
			lang: mergeLang,

			textHeaderClass,
			textFormClass,
			textTableClass,

			formatCurrency,
			formatDate,
			formatDateToISOString,

			getFormPrints,
			searchTextPrint,
			isSharedFormPrintReady,
			getPreviewBlob,
			filteredFormPrints,

			isHideBack,
		};
	},
	data() {
		return {
			receiptCode: null,
			receiptReferenceCode: null,
			receiptDate: null,
			receiptVatSendDate: null,
			selectedReceiptSupplier: null,
			selectedReceiptEmployee: null,
			receiptEmployeeName: null,
			receiptRemark1: null,
			receiptRemark2: null,

			receiptTotalAmount: 0,
			receiptNetAmount: 0,
			receiptActualPayAmount: 0,
			receiptVatrate: 0,
			receiptWithholdingTaxAmount: 0,
			receiptDifferenceAmount: 0,
			receiptDiscountAmount: 0,
			receiptDiscountAmountError: false,
			discountAmount: "0",
			receiptSubAmount: 0,
			receiptAddAmount: 0,
			receiptDetails: [],
			currencyCode: null,
			vatInclude: null,
			invouceCode: null,

			receiptSupplier: null,
			isSharedSupplierReady: false,
			receiptSupplierError: false,

			receiptEmployee: null,
			isSharedEmployeeReady: false,
			receiptEmployeeError: false,

			isLoading: false,
			editingRows: [],

			showWithholdingTax: false,
			showPayout: false,
			showImportPurchaseInvoice: false,

			selectedPage: 1,
			rowsPerPage: 10,
			vatDocumentBookRate: 0,
			moduleCode: "",
			vatDocumentBookInclude: "",
			selectedBookCode: "",
			selectedBookId: "",

			showFormPrintModal: false,
			ptId: this.id,
		};
	},
	methods: {
		async getPurchaseReceipt() {
			this.setLoading(true);
			const where = {
				projectId: this.selectedProject.id,
				receiptId: this.ptId,
			};
			await this.$store.dispatch("apPurchaseReceipt/getPurchaseReceipt", { where });
			this.setLoading(false);
		},
		setLoading(bool) {
			this.$store.dispatch("setLoading", { value: bool });
		},
		goBack() {
			this.$emit("close");
		},
		async closeDialog() {
			await this.$store.dispatch("bank/resetBank");
			await this.$store.dispatch("bank/resetBanksSelected");

			await this.$store.dispatch("apWithholdingTax/resetWithholdingTax");
			await this.$store.dispatch("apWithholdingTax/resetWithholdingTaxsImport");
			this.$emit("close");
		},
		supplierDataReady() {
			this.isSharedSupplierReady = true;
		},
		employeeDataReady() {
			this.isSharedEmployeeReady = true;
		},
		checkShouldSetData() {
			if (this.isSharedSupplierReady && this.isSharedEmployeeReady && this.purchaseReceipt && !this.isCreateDocument) {
				this.setData();
			} else if (this.isSharedSupplierReady && this.isSharedEmployeeReady && this.isCreateDocument){
				this.initiateData();
			}
		},
		async initiateData() {
			// console.log("initiateData", this.selectedUserName);
			this.ptId = "create";
			this.selectedReceiptEmployee = this.sharedEmployees.find(
				(employee) => employee.code === this.selectedUserName.code
			);
			this.selectedReceiptSupplier = null;
			this.receiptCode = null;
			this.receiptReferenceCode = null;
			this.receiptDate = new Date();
			this.receiptVatSendDate = new Date();
			// this.receiptEmployeeName = this.selectedUserName.createBy;
			this.receiptRemark1 = null;
			this.receiptRemark2 = null;

			this.receiptTotalAmount = 0;
			this.receiptNetAmount = 0;
			this.receiptActualPayAmount = 0;
			this.receiptVatrate = 0;
			this.receiptWithholdingTaxAmount = 0;
			this.receiptDifferenceAmount = 0;
			this.receiptDiscountAmount = 0;
			this.discountAmount = "0";
			this.receiptSubAmount = 0;
			this.receiptAddAmount = 0;
			this.currencyCode = null;

			this.receiptEmployeeError = false;
			this.receiptSupplierError = false;
			this.receiptDiscountAmountError = false;

			this.receiptDetails = [];

			this.addEmptyRow();
		},
		setData() {
			// console.log("setData", this.purchaseReceipt);
			if (this.purchaseReceipt) {
				// console.log("if_setData", this.purchaseReceipt);

				this.selectedReceiptSupplier = this.sharedSuppliers.find(
					(supplier) => supplier.id === this.purchaseReceipt.receiptSupplierId
				);

				this.selectedReceiptEmployee = this.sharedEmployees.find(
					(employee) => employee.id === this.purchaseReceipt.receiptEmployee
				);

				// let empName = this.purchaseReceipt.receiptUpdateBy ?? this.purchaseReceipt.receiptCreateBy;

				this.ptId = this.purchaseReceipt.receiptId;
				this.receiptCode = this.purchaseReceipt.receiptCode;
				this.receiptReferenceCode = this.purchaseReceipt.receiptRefCode;
				this.receiptDate = new Date(this.purchaseReceipt.receiptDate);
				this.receiptVatSendDate = new Date(this.purchaseReceipt.receiptVatSendDate);
				// this.receiptEmployeeName = empName;
				this.receiptRemark1 = this.purchaseReceipt.receiptRemark1;
				this.receiptRemark2 = this.purchaseReceipt.receiptRemark2;

				this.receiptTotalAmount = this.purchaseReceipt.receiptTotalAmount;
				this.receiptNetAmount = this.purchaseReceipt.receiptNetAmount;
				this.receiptActualPayAmount = this.purchaseReceipt.receiptActualPayAmount;
				this.receiptVatrate = this.purchaseReceipt.receiptVatrate;
				this.receiptWithholdingTaxAmount = this.purchaseReceipt.receiptWithholdingTaxAmount;
				this.receiptDifferenceAmount = this.purchaseReceipt.receiptDifferenceAmount;
				this.receiptDiscountAmount = this.purchaseReceipt.receiptDiscountAmount;
				this.receiptSubAmount = this.purchaseReceipt.receiptSubAmount;
				this.receiptAddAmount = this.purchaseReceipt.receiptAddAmount;
				this.currencyCode = this.purchaseReceipt.currencyCode;

				this.receiptDetails = this.purchaseReceipt.referencePurchaseReceipt;
				this.discountAmount =
					this.purchaseReceipt.discountStr == "" ? "0" : this.purchaseReceipt.discountStr;
				this.updateItemNo();
				if (!this.isDocumentLocked) {
					this.addEmptyRow();
				}
				this.calculateTotalAmount();
			}
		},
		addEmptyRow() {
			this.receiptDetails.push({
				id: "dummy" + (parseInt(this.receiptDetails.length) + 1),
				receiptId: null,
				actualPayAmount: null,
				receiptPayAmount: null,
				addAmount: 0,
				differenceAmount: 0,
				discountAmount: "0",
				documentBookCode: null,
				documentBookId: null,
				documentBookName: null,
				itemNo: null,
				moduleCode: null,
				moduleName: null,
				netAmount: 0,
				postStep: null,
				receiptDetailCode: null,
				receiptDetailDate: new Date(),
				receiptDetailDueDate: new Date(),
				receiptHeaderId: null,
				referenceDocument: null,
				statusCancel: null,
				statusPaid: null,
				statusWithholdingTax: null,
				supplierCode: null,
				supplierId: null,
				supplierName: null,
				totalAmount: 0,
				vatAmount: 0,
				vatInclude: null,
				vatRate: 0,
				withholdingTaxAmount: 0,
			});
			this.updateItemNo();
		},
		createNewDocument() {
			this.$swal({
				icon: "info",
				html: `<p class="${this.textAlertClass}">${this.lang.askForCreateNewDocument}</p>`,
				confirmButtonText: this.lang.confirm,
				showCancelButton: true,
				cancelButtonText: this.lang.cancel,
				allowOutsideClick: false,
			}).then((result) => {
				//callback to execute when user confirms the action
				if (result.isConfirmed) {
					this.receiptNetAmount = 0;
					this.receiptDifferenceAmount = 0;
					this.initiateData();
				} else if (result.isDenied) {
					//
				}
			});
		},
		async savePurchaseReceipt() {
			this.setLoading(true);
			// console.log("savePurchaseReceipt");
			if (this.validateData("save")) {
				this.setLoading(false);
				return;
			}

			try {
				if (this.receiptDetails.length > 1) {
					this.receiptDetails.splice(this.receiptDetails.length - 1, 1);
				}
				if (this.isCreateDocument) {
					await this.$store.dispatch("apPurchaseReceipt/addPurchaseReceiptVoucher", this.payload);

					if (this.banksSelected) {
						await this.importCheque();
					}

					if (this.withholdingTaxsImport) {
						await this.importWithholdingTax();
					}

					this.$swal({
						icon: "success",
						title: this.lang.saveSuccess,
						confirmButtonText: this.lang.close,
						allowOutsideClick: false,
					});

					if (this.isCreateDocument) {
						this.setData();
					}
				} else {
					const payload = this.purchaseReceipt;
					payload.projectId = this.selectedProject.id;
					payload.documentBookId = this.selectedBookId;

					payload.receiptRefCode = this.receiptReferenceCode;
					payload.receiptDate = new Date(this.receiptDate);
					payload.receiptVatSendDate = new Date(this.receiptVatSendDate);
					payload.receiptEmployee = this.selectedUserName.id;
					payload.receiptRemark1 = this.receiptRemark1;
					payload.receiptRemark2 = this.receiptRemark2;

					payload.receiptTotalAmount = this.receiptTotalAmount;
					payload.receiptNetAmount = this.receiptNetAmount;
					payload.receiptActualPayAmount = this.receiptActualPayAmount;
					payload.receiptVatrate = this.receiptVatrate;
					payload.receiptVatamount = this.receiptVatamount;
					payload.receiptWithholdingTaxAmount = this.receiptWithholdingTaxAmount;
					payload.receiptDifferenceAmount = this.receiptDifferenceAmount;
					payload.receiptDiscountAmount = this.receiptDiscountAmount;
					payload.discountStr = this.discountAmount;
					payload.receiptSubAmount = this.receiptSubAmount;
					payload.receiptAddAmount = this.receiptAddAmount;

					payload.referencePurchaseReceipt = this.receiptDetails;

					if (this.banksSelected) {
						await this.importCheque();
					}

					if (this.withholdingTaxsImport) {
						await this.importWithholdingTax();
					}

					// console.log("update", payload);
					await this.$store.dispatch("apPurchaseReceipt/updatePurchaseReceipt", payload);
					this.$swal({
						icon: "success",
						title: this.lang.saveSuccess,
						confirmButtonText: this.lang.close,
						allowOutsideClick: false,
					});
				}
				await this.$store.dispatch("glPurchaseReceipt/setSelectedVoucherReferenceDocument", [this.purchaseReceipt]);


				this.setLoading(false);
			} catch (error) {
				this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				this.setLoading(false);
			}
		},
		async importCheque() {
			this.banksSelected.forEach((item) => {
				let bankVoucher = {
					PayId: item.id,
					VoucherId: this.purchaseReceipt.receiptId,
					RefType: "RVH",
					PayAmount: item.payAmount,
					ProjectId: this.selectedProject.id,
				};
				// console.log(bankVoucher);
				this.$store.dispatch("bank/AddBankVoucher", bankVoucher);
			});
			await this.$store.dispatch("bank/resetBanksSelected");
		},
		async importWithholdingTax() {
			this.withholdingTaxsImport.forEach((item) => {
				let whtImport = {
					TaxId: item.id,
					RVHId: this.purchaseReceipt.receiptId,
					RefType: "RVH",
					taxTotalAmount: item.taxTotalAmount,
					ProjectId: this.selectedProject.id,
					documentBookCode: item.documentBookCode,
				};
				// console.log(whtImport);
				this.$store.dispatch("apWithholdingTax/addWithholdingTaxImport", whtImport);
			});
			await this.$store.dispatch("apWithholdingTax/resetWithholdingTaxsImport");
		},
		async askForCancel() {
			const result = await this.$swal({
				icon: "info",
				html: `<p class="${this.textAlertClass}">${this.lang.askForCancelDocument}</p>`,
				confirmButtonText: this.lang.confirm,
				showCancelButton: true,
				cancelButtonText: this.lang.close,
				allowOutsideClick: false,
				confirmButtonColor: "#EF4444",
				cancelButtonColor: "#64748B",
			});

			if (result.isConfirmed) {
				await this.cancelPurchaseReceipt();
			} else if (result.isDenied) {
				//
			}
		},
		async cancelPurchaseReceipt() {
			// console.log("cancelPurchaseReceipt");
			this.setLoading(true);
			try {
				let payload = {
					id: this.ptId,
				};
				await this.$store.dispatch("apPurchaseReceipt/cancelPurchaseReceipt", payload);
				this.$swal({
					icon: "success",
					title: this.lang.cancelSuccess,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				this.setLoading(false);
			} catch (error) {
				this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				this.setLoading(false);
			}
		},
		deleteReceiptDetail(receiptDetail) {
			// console.log("deleteReceiptDetail.1", this.receiptDetails);
			if (receiptDetail.receiptId) {
				let i = this.receiptDetails.map((item) => item.receiptId).indexOf(receiptDetail.receiptId);
				this.receiptDetails.splice(i, 1);
				// console.log("deleteReceiptDetail.2", this.receiptDetails);
				if (this.receiptDetails.length === 0) {
					this.addEmptyRow();
				}
				this.calculateTotalAmount();
				this.updateItemNo();
			}
		},
		async selectPrintForm() {
			// console.log("selectPrintForm");
			await this.$store.dispatch("shared/resetFormPrint");
			this.dataPrint = this.purchaseReceipt;
			let where = {
				projectId: this.selectedProject.id,
				documentBookCode: this.selectedBookCode,
			};
			const payload = { where };
			await this.getFormPrints(payload);
			
			if(this.filteredFormPrints.length == 1) {
				this.path = this.filteredFormPrints[0].path;
				this.printPurchaseReceipt();
			}else{
				this.showFormPrintModal = true;
			}
		},
		async formPrintModalSelected(event) {
			this.setLoading(true);
			this.path = event.path;
			//this.categoryPrint = event.category;

			await this.printPurchaseReceipt();

			this.showFormPrintModal = false;
			this.setLoading(false);
		},
		async printPurchaseReceipt() {
			const payLoad = {
				Id: this.dataPrint.receiptId,
				path: this.path,
				EmployeeName: this.selectedUserName.createBy,
			};
			// console.log(this.selectedUserName.createBy);
			try {
				await this.$store.dispatch("bank/PrintCheque", payLoad);
				let blob = this.$store.getters["bank/blob"];
				this.getPreviewBlob(blob);
			} catch (error) {
				this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
			}
		},
		validateData(func) {
			// console.log("validateData", this.selectedReceiptSupplier.code);
			if (!this.selectedReceiptSupplier) {
				this.$swal({
					icon: "warning",
					title: this.lang.validateNoReceiptSupplierSelect,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				}).then((result) => {
					if (result.isConfirmed) {
						this.receiptSupplierError = true;
					}
				});
				return true;
			}
			if (!this.selectedReceiptEmployee) {
				this.$swal({
					icon: "warning",
					title: this.lang.validateNoReceiptEmployeeSelect,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				}).then((result) => {
					if (result.isConfirmed) {
						this.receiptEmployeeError = true;
					}
				});
				return true;
			}
			if (this.receiptDetails.length === 0 && func === "save") {
				this.$swal({
					icon: "warning",
					title: this.lang.validateReceiptDetails,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				return true;
			} else if (this.receiptDetails[0].receiptId === null && func === "save") {
				this.$swal({
					icon: "warning",
					title: this.lang.validateReceiptDetails,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				return true;
			} else if (
				this.receiptDiscountAmount > this.receiptTotalAmount ||
				(this.receiptDiscountAmount < 0 && func === "save")
			) {
				this.$swal({
					icon: "warning",
					title: this.lang.validateDiscountAmount,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				}).then((result) => {
					if (result.isConfirmed) {
						this.receiptDiscountAmountError = true;
					}
				});
				return true;
			} else if (func === "save") {
				// let actualPayAmount = 0;
				// this.receiptDetails.forEach((item) => {
				// 	actualPayAmount += item.actualPayAmount;
				// });
				let receiptPayAmount = 0;
				this.receiptDetails.forEach((item) => {
					receiptPayAmount += item.receiptPayAmount;
				});

				let taxPayTotalAmount = 0;
				this.withholdingTaxsImport.forEach((item) => {
					taxPayTotalAmount += item.payTotalAmount-item.taxTotalAmount;
				});
				let totalAmount = this.receiptActualPayAmount + this.receiptWithholdingTaxAmount;
				
				//แก้ actualPayAmount เป็น receiptPayAmount
				if (totalAmount > 0 && receiptPayAmount === 0) {
					this.$swal({
						icon: "warning",
						title: this.lang.validateActualPayAmount,
						confirmButtonText: this.lang.close,
						allowOutsideClick: false,
					});
					return true;
				} else if (totalAmount > 0 && receiptPayAmount.toFixed(2) > totalAmount.toFixed(2)) {
					this.$swal({
						icon: "warning",
						title: this.lang.validateMoreActualPayAmount,
						confirmButtonText: this.lang.close,
						allowOutsideClick: false,
					});
					return true;
				} else if (this.receiptActualPayAmount === 0 && receiptPayAmount > 0) {
					this.$swal({
						icon: "warning",
						title: this.lang.validateCheque,
						confirmButtonText: this.lang.close,
						allowOutsideClick: false,
					});
					return true;
				} else if (
					this.receiptDetails.length >= 1 &&
					this.receiptDetails[1].receiptId &&
					receiptPayAmount < this.receiptDetails[0].receiptPayAmount
				) {
					this.$swal({
						icon: "warning",
						title: this.lang.validateCheque,
						confirmButtonText: this.lang.close,
						allowOutsideClick: false,
					});
					return true;
				} else if (taxPayTotalAmount > this.receiptActualPayAmount) {
					this.$swal({
						icon: "warning",
						title: this.lang.validateTax,
						confirmButtonText: this.lang.close,
						allowOutsideClick: false,
					});
					return true;
				}
			}
			return false;
		},
		async onCellEditComplete(event) {
			// console.log("onCellEditComplete", event);
			let { data, newValue, field, value, newData, index } = event;

			if (!this.isDocumentLocked) {
				if (field === "receiptDetailCode") {
					this.isLoading = true;
					const receiptDetail = await this.getReceiptDetailByCode(newValue);
					// console.log("receiptDetailCode>>>>", data);
					// console.log("receiptDetailCode>>>>", receiptDetail);
					this.isLoading = false;

					if (receiptDetail) {
						if (newValue === "") {
							return;
						}
						if (!value) {
							this.receiptDetailSelect(data, receiptDetail);
						}
					}
				// } else if (field === "actualPayAmount") {
				// 	this.isLoading = true;
				// 	this.receiptDetails[index] = newData;
				// 	this.isLoading = false;
				// }
				} else if (field === "receiptPayAmount") {
					this.isLoading = true;
					this.receiptDetails[index] = newData;
					this.calculateTotalAmount();
					this.isLoading = false;
				}
			}
		},
		getImportPurchaseInvoice() {
			if (this.validateData("import")) {
				return;
			}
			this.invouceCode = null;
			this.showImportPurchaseInvoice = true;
		},
		updateItemNo() {
			let number = 1;
			if (this.receiptDetails) {
				this.receiptDetails.forEach((element) => {
					element.itemNo = number;
					number++;
				});
			}
		},
		async setImportPurchaseInvoice(detail) {
			this.showImportPurchaseInvoice = false;
			let checkPI = true;
			if (this.receiptDetails) {
				this.receiptDetails.forEach((element) => {
					if (element.receiptId === detail.receiptId) {
						this.$swal({
							icon: "warning",
							title: this.lang.validatePurchaseInvoice,
							confirmButtonText: this.lang.close,
							allowOutsideClick: false,
						});
						checkPI = false;
						// console.log(element);
					}
				});
			}
			// console.log("setImportPurchaseInvoice", this.receiptDetails[0]);

			// this.receiptRemark1 = detail.remark1;
			// this.receiptRemark2 = detail.remark2;
			// this.receiptDetails.unshift(detail);
			if (checkPI) {
				this.receiptDetailSelect(this.receiptDetails[this.receiptDetails.length - 1], detail);
			}

			await this.calculateTotalAmount();
			this.calculateDiscountAmount();
			// this.addEmptyRow();
			this.updateItemNo();
		},
		async calculateTotalAmount() {
			// let netAmount = 0;
			// let vatAmount = 0;
			// if (this.receiptDetails) {
			// 	this.receiptDetails.forEach((item) => {
			// 		netAmount += item.netAmount;
			// 		vatAmount += item.vatAmount;
			// 	});
			// }
			// this.receiptTotalAmount = netAmount;

			// this.receiptVatamount = vatAmount;
			// this.receiptNetAmount = netAmount;

			// this.discountAmount = this.discountAmount ?? "0";
			// this.receiptDiscountAmount = this.receiptDiscountAmount ?? 0;
			// this.receiptAddAmount = this.receiptAddAmount ?? 0;
			// this.receiptSubAmount = this.receiptSubAmount ?? 0;

			// this.receiptDifferenceAmount =
			// 	this.receiptDifferenceAmount ??
			// 	this.receiptTotalAmount -
			// 		this.receiptActualPayAmount -
			// 		this.receiptWithholdingTaxAmount -
			// 		this.discountAmount -
			// 		this.receiptSubAmount +
			// 		this.receiptAddAmount;
			let netAmount = 0;
			let vatAmount = 0;
			let detailReceiptPayAmount = 0;
			if (this.receiptDetails) {
				this.receiptDetails.forEach((item) => {
					netAmount += item.netAmount;
					vatAmount += item.vatAmount;
					detailReceiptPayAmount += item.receiptPayAmount ?? 0;
				});
			}
			this.receiptTotalAmount = netAmount;

			this.receiptVatamount = vatAmount;
			this.receiptNetAmount = netAmount;

			this.discountAmount = this.discountAmount ?? "0";
			this.receiptDiscountAmount = this.receiptDiscountAmount ?? 0;
			this.receiptAddAmount = this.receiptAddAmount ?? 0;
			this.receiptSubAmount = this.receiptSubAmount ?? 0;

			this.receiptDifferenceAmount = detailReceiptPayAmount -
				this.receiptActualPayAmount -
				this.receiptWithholdingTaxAmount -
				this.discountAmount -
				this.receiptSubAmount +
				this.receiptAddAmount;
		},
		async getReceiptDetailByCode(receiptDetail) {
			// console.log("getReceiptDetailByCode", receiptDetail);
			if (this.validateData("import")) {
				return;
			}
			this.invouceCode = receiptDetail;
			try {
				let where = {
					projectId: this.selectedProject.id,
					supplierId: this.selectedReceiptSupplier.id,
					vatRate: this.vatDocumentBookRate,
					receiptDetailCode: this.invouceCode,
					moduleCode: this.moduleCode,
				};

				const payload = {
					page: this.selectedPage,
					limit: this.rowsPerPage,
					where,
				};

				// console.log("getPurchaseInvoice", payload);
				await this.$store.dispatch("apPurchaseReceipt/getPurchaseInvoiceFroImport", payload);
				// console.log(this.$store.getters["apPurchaseReceipt/purchaseInvoices"]);
				const purchaseInvoices = this.$store.getters["apPurchaseReceipt/purchaseInvoices"];
				if (purchaseInvoices.length > 1) {
					this.showImportPurchaseInvoice = true;
				} else {
					return purchaseInvoices[0];
				}
			} catch (error) {
				this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
			}
		},
		async receiptDetailSelect(data, receiptDetail) {
			// console.log("receiptDetailSelect", data);

			data.receiptId = receiptDetail.receiptId;
			data.actualPayAmount = receiptDetail.actualPayAmount;
			data.receiptPayAmount = receiptDetail.receiptPayAmount;
			data.addAmount = receiptDetail.addAmount;
			data.differenceAmount = receiptDetail.differenceAmount;
			data.discountAmount = receiptDetail.discountAmount;
			data.documentBookCode = receiptDetail.documentBookCode;
			data.documentBookId = receiptDetail.documentBookId;
			data.documentBookName = receiptDetail.documentBookName;
			data.moduleCode = receiptDetail.moduleCode;
			data.moduleName = receiptDetail.moduleName;
			data.netAmount = receiptDetail.netAmount;
			data.postStep = receiptDetail.postStep;
			data.receiptDetailCode = receiptDetail.receiptDetailCode;
			data.receiptDetailDate = new Date(receiptDetail.receiptDetailDate);
			data.receiptDetailDueDate = new Date(receiptDetail.receiptDetailDueDate);
			data.receiptHeaderId = receiptDetail.receiptHeaderId;
			data.referenceDocument = receiptDetail.referenceDocument;
			data.statusCancel = receiptDetail.statusCancel;
			data.statusPaid = receiptDetail.statusPaid;
			data.statusWithholdingTax = receiptDetail.statusWithholdingTax;
			data.supplierCode = receiptDetail.supplierCode;
			data.supplierId = receiptDetail.supplierId;
			data.supplierName = receiptDetail.supplierName;
			data.totalAmount = receiptDetail.totalAmount;
			data.vatAmount = receiptDetail.vatAmount;
			data.vatInclude = receiptDetail.vatInclude;
			data.vatRate = receiptDetail.vatRate;
			data.withholdingTaxAmount = receiptDetail.withholdingTaxAmount;

			this.receiptRemark1 = receiptDetail.remark1;
			this.receiptRemark2 = receiptDetail.remark2;

			this.addRow(data);
			//หาว่ามีบรรทัดที่ รหัสเป็น null หรือยัง (บรรทัดว่าง)
			const emptyRow = this.receiptDetails.find((detail) => !detail.receiptDetailCode);
			if (!emptyRow) {
				//ถ้ายังไม่มีให้เพิ่มไว้
				this.addEmptyRow();
			}
			await this.calculateTotalAmount();
			this.updateItemNo();
		},
		async addRow(receiptDetails) {
			// console.log("addRow", this.receiptDetails);
			this.receiptDetails.forEach((element) => {
				if (element.receiptId === receiptDetails.receiptId) {
					element = { ...element, ...receiptDetails };
					// console.log(element);
				}
			});
			await this.calculateTotalAmount();
			this.updateItemNo();
		},

		async getWithholdingTax() {
			if (this.validateData("import")) {
				return;
			}
			await this.$store.dispatch("apWithholdingTax/resetWithholdingTax");
			this.showWithholdingTax = true;
		},
		async getPayout() {
			if (this.validateData("import")) {
				return;
			}
			await this.$store.dispatch("bank/resetBank");
			this.showPayout = true;
		},
		closeImportPurchaseInvoice() {
			this.showImportPurchaseInvoice = false;
		},
		async closeWithholdingTax() {
			this.showWithholdingTax = false;
			this.calculateTax();
		},
		async closePayout() {
			this.showPayout = false;
			this.calculateCheque();
		},
		calculateDiscountAmount(discountText = this.discountAmount) {
			// let nowDiscount = parseFloat(this.discountAmount);
			// // console.log("calculateDiscountAmount", nowDiscount);
			// // console.log("this.discountAmount", this.discountAmount);
			// if (this.discountAmount.includes("%")) {
			// 	nowDiscount = (parseFloat(this.discountAmount) / 100) * this.receiptTotalAmount;
			// }

			// this.receiptDiscountAmount = nowDiscount;

			// this.receiptDifferenceAmount =
			// 	this.receiptTotalAmount -
			// 	nowDiscount -
			// 	this.receiptSubAmount +
			// 	this.receiptAddAmount -
			// 	this.receiptWithholdingTaxAmount -
			// 	this.receiptActualPayAmount;

			let disocuntAmount = 0;
			let detailReceiptPayAmount = 0;
			this.receiptDetails.forEach((item) => {
				detailReceiptPayAmount += item.receiptPayAmount ?? 0;
			});
			if (discountText.includes("%")) {
				disocuntAmount = (parseFloat(discountText) / 100) * this.receiptTotalAmount;
			}
			this.discountAmount = discountText;
			disocuntAmount = discountText ? parseFloat(discountText) : 0;

			this.receiptDiscountAmount = disocuntAmount;

			this.receiptDifferenceAmount =
				detailReceiptPayAmount -
				disocuntAmount -
				this.receiptSubAmount +
				this.receiptAddAmount -
				this.receiptWithholdingTaxAmount -
				this.receiptActualPayAmount;
		},
		calculateCheque() {
			let payAmount = 0;
			let cheque = [...this.banks, ...this.banksSelected];

			cheque.forEach((item) => {
				payAmount += item.payAmount;
			});

			// console.log("calculateCheque", payAmount);
			this.receiptActualPayAmount = payAmount;
			this.calculateDiscountAmount();
		},
		calculateTax() {
			let taxAmount = 0;
			let withholdingTax = [...this.withholdingTaxs, ...this.withholdingTaxsImport];

			withholdingTax.forEach((item) => {
				taxAmount += item.taxTotalAmount;
			});

			// console.log("calculateTax", taxAmount);
			this.receiptWithholdingTaxAmount = taxAmount;
			this.calculateDiscountAmount();
		},
	},
	computed: {
		title() {
			if (this.selectedBookCode) {
				return this.lang.title + " : " + this.selectedBookCode;
			} else {
				return this.lang.title;
			}
		},
		parameterButtonText() {
			return this.isParameterVisible ? this.lang.hideParameters : this.lang.showParameters;
		},
		halfViewportHeight() {
			return this.$store.getters.halfViewportHeight;
		},
		selectedProject() {
			return this.$store.getters.selectedProject;
		},
		selectedUserName() {
			return this.$store.getters.employee;
		},
		totalPage() {
			return this.$store.getters["apPurchaseReceipt/totalPage"];
		},
		totalRecords() {
			return this.totalPage * this.rowsPerPage;
		},
		isCreateDocument() {
			return this.ptId === "create";
		},
		isDocumentLocked() {
			if (this.purchaseReceipt && this.ptId !== "create") {
				if (
					this.purchaseReceipt.receiptCancelStatus === "C" ||
					this.purchaseReceipt.receiptPostStep === "A"
				) {
					return true;
				}
			}
			return false;
		},

		purchaseReceipt() {
			return this.$store.getters["apPurchaseReceipt/selectedPurchaseReceipt"];
		},
		sharedSuppliers() {
			return this.$store.getters["shared/supplierSuppliers"];
		},
		sharedEmployees() {
			return this.$store.getters["shared/employeeEmployees"];
		},

		selectedReceiptDate() {
			let receiptDate = null;
			if (this.receiptDate) {
				receiptDate = this.formatDateToISOString(this.receiptDate);
			} else {
				receiptDate = this.formatDateToISOString(new Date());
			}
			return receiptDate;
		},
		selectedReceiptVatSendDate() {
			let receiptVatSendDate = null;
			if (this.receiptVatSendDate) {
				receiptVatSendDate = this.formatDateToISOString(this.receiptVatSendDate);
			} else {
				receiptVatSendDate = this.formatDateToISOString(new Date());
			}
			return receiptVatSendDate;
		},
		banksSelected() {
			return this.$store.getters["bank/banksSelected"];
		},
		banks() {
			return this.$store.getters["bank/banksAr"];
		},
		withholdingTaxsImport() {
			return this.$store.getters["apWithholdingTax/withholdingTaxsImport"];
		},
		withholdingTaxs() {
			return this.$store.getters["apWithholdingTax/withholdingTaxs"];
		},
		payload() {
			return {
				projectId: this.selectedProject.id,
				documentBookId: this.selectedBookId,
				documentBookCode: this.selectedBookCode,

				receiptRefCode: this.receiptReferenceCode,
				receiptDate: this.selectedReceiptDate,
				receiptVatSendDate: this.selectedReceiptVatSendDate,
				receiptEmployee: this.selectedUserName.id,
				receiptSupplierId: this.selectedReceiptSupplier.id,
				receiptRemark1: this.receiptRemark1,
				receiptRemark2: this.receiptRemark2,

				receiptTotalAmount: this.receiptTotalAmount,
				receiptNetAmount: this.receiptNetAmount,
				receiptActualPayAmount: this.receiptActualPayAmount,
				receiptVatrate: this.vatDocumentBookRate,
				receiptVatamount: this.receiptVatamount,
				receiptWithholdingTaxAmount: this.receiptWithholdingTaxAmount,
				receiptDifferenceAmount: this.receiptDifferenceAmount,
				receiptDiscountAmount: this.receiptDiscountAmount,
				discountStr: this.discountAmount,
				receiptSubAmount: this.receiptSubAmount,
				receiptAddAmount: this.receiptAddAmount,
				receiptVatreturn: "Y",
				receiptVatinclude: this.vatDocumentBookInclude,

				referencePurchaseReceipt: this.receiptDetails,
			};
		},
	},
	watch: {
		purchaseReceipt() {
			this.checkShouldSetData();
		},
		isSharedSupplierReady() {
			this.checkShouldSetData();
		},
		isSharedEmployeeReady() {
			this.checkShouldSetData();
		},
		selectedReceiptSupplier(curValue, oldValue) {
			if (oldValue === null && curValue) {
				this.receiptSupplierError = false;
			}
		},
		selectedReceiptEmployee(curValue, oldValue) {
			if (oldValue === null && curValue) {
				this.receiptEmployeeError = false;
			}
		},
		discountAmount(curValue) {
			// console.log(curValue);
			if (curValue === "" || !curValue) {
				curValue = "0";
				this.discountAmount = "0";
				this.receiptDiscountAmount = 0;
			}

			let isnum = /(^[0-9]+$|[.]|[%])/g.test(curValue);
			if (!isnum) {
				this.$swal({
					icon: "warning",
					title: this.lang.validateReceiptDiscount,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				}).then((result) => {
					if (result.isConfirmed) {
						this.discountAmount = "0";
						this.receiptDiscountAmount = 0;
					}
				});
			}
		},
		receiptSubAmount(curValue, oldValue) {
			if (!curValue || curValue === 0) {
				this.receiptSubAmount = 0;
			} else {
				this.receiptNetAmount += oldValue - curValue;
				this.receiptDifferenceAmount += oldValue - curValue;
			}
		},
		receiptAddAmount(curValue, oldValue) {
			// console.log(curValue, oldValue);
			if (!curValue || curValue === 0) {
				this.receiptAddAmount = 0;
			} else {
				this.receiptNetAmount -= oldValue - curValue;
				this.receiptDifferenceAmount -= oldValue - curValue;
			}
		},
		openFrom() {
			if (this.id) this.ptId = this.id;
			if (this.apBook) {
				let bookModalSelected = this.apBook;
				this.vatDocumentBookRate = bookModalSelected.taxRate;
				this.moduleCode = bookModalSelected.moduleCode;
				this.selectedBookCode = bookModalSelected.bookCode;
				this.selectedBookId = bookModalSelected.bookId;
				this.vatDocumentBookInclude = bookModalSelected.vatInclude;
			}

			if (!this.isCreateDocument) {
				this.getPurchaseReceipt();
			} else {
				this.initiateData();
				this.$store.dispatch("apPurchaseReceipt/resetPurchaseReceipts");
			}
		},
	},
};
</script>
