export default {
	setVoucherReferenceDocuments(state, payload) {
		state.voucherReferenceDocuments = payload.data;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
	setSelectedVoucherReferenceDocument(state, payload) {
		state.selectedVoucherReferenceDocument = payload;
	},
	resetSelectedVoucherReferenceDocument(state) {
		state.selectedVoucherReferenceDocument = [];
	},
};
