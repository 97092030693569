export default {
  async getBookBanks(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/BookBank";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get book bank"
      );
      throw error;
    }
    // console.log("bookbank", responseData);

    // const data = responseData.data;

    context.commit("setBookBank", responseData);
  },
  async getBookBank(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/BookBank?Page=1&Limit=10";

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to get book bank"
      );
      throw error;
    }
    // console.log(responseData);

    // const data = responseData.data;
    if (responseData.data.length > 0) {
      context.commit("setSelectedBookBank", responseData.data[0]);
    }
  },
  async createOrUpdateBookBank(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/BookBank/${payload.type}`;

    const fallbackErrorMessage = `Failed to ${payload.type} book bank`
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: payload.type === 'Add' ? 'post' : 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || fallbackErrorMessage);
      throw error;
    }

    context.commit("setSelectedBookBank", responseData.data);

  },
  async deleteBookBank(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/BookBank/Delete`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to delete book bank');
      throw error;
    }

    context.commit("setSelectedBookBank",responseData.data);
  },
  resetSelectedBookBank(context) {
    context.commit("setSelectedBookBank",null);
  },
  async getAllBookBanks(context, payload) {
    while (!context.getters.bookBankIsLastPage) {
      let where = {
        projectId: context.rootGetters.selectedProject.id,
        bankBookUseType: payload.bankBookUseType,
        activeStatus: payload.activeStatus,
      };

      const payloadNew = {
        page: context.getters.bookBankNextPage || 1,
        where,
      };
      await context.dispatch("getBookBanks", payloadNew);
    }
    
  },

  async setActiveStatus(context, activeStatus) {
		await context.commit("setActiveStatus", activeStatus);
	},
  reseBookBank(context) {
		context.commit("resetBookBank");
	},
};
