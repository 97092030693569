export default {
    prepaids(state) {
      return state.prepaids;
    },
    selectedPrepaid(state) {
      return state.selectedPrepaid;
    },    
    totalPage(state) {
      return state.totalPage;
    },
    currentPage(state) {
      return state.currentPage;
    },
    rows(state) {
      return state.rows;
    },
    prepaidReceipts(state) {
      return state.prepaidReceipts;
    },
    blob(state) {
      return state.blob;
    }
  };
  