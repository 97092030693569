export default {
    setPettyCash(state, payload){
        state.pettyCash = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },
    setBlob(state, payload){        
        // console.log("setBlob:",payload);
        state.blob = payload
    },
    resetPettyCash(state) {
        state.pettyCash = []
        state.totalPage = 0
        state.currentPage = 0
    },
    setPettyCashUseOtherExpenses(state, payload){
        state.pettyCashUseOtherExpenses = payload.data
    },
};