export default {
    setPurchaseInvoices(state, payload){
        state.purchaseInvoices = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },    
    setSelectedPurchaseInvoice(state, payload){
        state.selectedPurchaseInvoice = payload
    },
    setBlob(state, payload){        
        state.blob = payload
    },    
    setPurchaseOrders(state, payload){
        state.purchaseOrders = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    }, 
    setPurchaseInvoiceReference(state, payload){
        state.purchaseInvoiceReference = payload
    }, 
};