export default({
    createKeycard: "Create Keycard",
    editKeycard: "Edit Keycard",
    title: "Keycard Details",
    details: "Detals",
    date: "Date",
    validateDateError: "Please select date",
    createBy: "Create By",
    createDate: "Create Date",
    productCode: "Product Code",
    validateProduct: "Please select product code",
    runningNo: "Running No.",
    validateRunningNo: "Please enter Running No.",
    serialNo: "Serial No.",
    status: "Status",
    remark: "Remark",
    validateRemark: "Please enter remark",
    statusReady: "Ready",
    statusSold: "Sold",
    statusLost: "Lost",
    statusBroken: "Broken",
    statusSpare: "Spare",
    statusOther: "Other",

    ownerDetails: "Owner Details",
    unit: "Unit/ Address No.",
    validateUnit: "Please select unit/ address no.",

    //history
    editHistory: "History",
    updateDate: "Update Date",
    updateBy: "Update By",
    receiptCode: "Receipt Code",
    unitCode: "Unit",
    unitAddressNo: "Address No.",
    historyDetails: "History Details",

    stockKeycardSaved: "Stock keycard saved",
    validateDuplicateCode: "Can not save, because running no. : :code is duplicate",
    validateChangeStatusLine1: "Changing the data of a sold item may result in inaccuracies from the actual data.",
    validateChangeStatusLine2: "Additionally, changing the status from sold to another status will result in the deletion of unit/address and invoice code data currently referenced in this item",
    validateChangeStatusLine3: "Are you sure you want to save changes?",

    //stock keycard dialog
    selectStockKeycardTitle: "Select Keycard (:productCode : :productName)",
    selectedStockKeycard: "Select Keycard",
    validateNoSelectStockKeycard: "Please select keycard.",
})