export default {
	general: "General",
	adjust: "Adjust",
	closePeriod: "Close Period",
	posting: "Posting",
	nonPosting: "Accounting Not Posting",
	createAccountingEntry: "Create Accounting Entry",
	createReceiptToGl: "Create Receipt To GL",
	createInvoiceToGl: "Create Invoice To GL",
	editAccountingEntry: "Edit Accounting Entry",
	editReceiptToGl: "Edit Receipt To GL",
	editInvoiceToGl: "Edit Invoice To GL",
	accountingFrequently: "Accounting Frequently",
	total: "Total Amount",
	validateDetailSelect: "Please select Item.",
	accountingItem: "Accounting Item",
	askForSelectAccounting: "Can't find accounting code : :code. Do you want to search?",
	askForAccountingChange: "Are you sure you want to update Accounting code?",
	importDocument: "Import Document",
	removeDocument: "Remove All Document ",
	getDocument: "Document (F3)",
	invoiceItem: "Invoice Item",
	codeReference: "Code Reference",
	unitCode: "Unit Code",
	unitAddress: "Address No.",
	customerCode: "Customer Code",
	customerName: "Customer Name",
	customerSearch: "Customer Code or Name",
	searchUnit: "Search Unit",
	receiptTitle: "Receipt To GL",
	listReceiptTitle: "List Receipt To GL",
	payType: "PayType",
	compound: "Compound Cash Epay",
	meterToGl: "Meter to GL",
	revert: "Revert",
	validateInvoiceToGl: "No accounting binding to invoice settlement",
	meterToGlItem: "List Water/Electric Meter To GL",
	meterToGlTitle: "Water/Electric Meter To GL",
	meterWater: "Water to GL",
	meterElectric: "Electric to GL",
	voucherCode: "Voucher Code",
	waterVoucher: "Water charges of this period has already voucher",
	electricVoucher: "Electric charges of this period has already voucher",
	meterAlertAccountingFrequently: "Accounting Frequently could not be found.",
	periodStart: "Period Start",
	periodEnd: "Period End",
	unVoucherOnly: "Unvoucher only",
	autoGl: "Auto GL",
	validateTotalAmount: "Total debit not equal total credit.",
	notApprove: "Not Approve",
	validateTotalAmount2: "Total debit and credit must be greater than 0.",
	reasonNonApprove: "Reason Not Approve",
	createPettyCashToGl: "Create Petty Cash",
	editPettyCashToGl: "Edit Petty Cash",
	pettyCashPeriod: "Period",
	validatePettyCashPeriod: "Document can't approve",
	cancelApprovePettyCash: "Document has approved.",
	withholdingTax: "Withholding Tax",
	validateCancel: "Can't cancel document because already have reference document.",
	validateNonApprove: "ReasonNonApprove couldn't be empty.",
	validateApprovePermission: "You don't have approve permission",

	payinPayoutBtn: "Payment",
	validateTotalAmount3: "Don't have credit or debit amount.",

	validateInvoiceCheckGlBook:
		"The selected account book :code doesn't link with invoice document book (SJ). If you want to change please contact our customer service.",
	validateReceiptCheckGlBook:
		"The selected account book :code doesn't link with receipt document book (RV). If you want to change please contact our customer service.",
	validateBillAcceptanceCheckGlBook:
		"The selected account book :code doesn't link with billAcceptance document book (PJ). If you want to change please contact our customer service.",

	validatePurchaseReceiptCheckGlBook:
		"The selected account book :code doesn't link with billAcceptance document book (PV). If you want to change please contact our customer service.",

	titleImportBillAcceptance: "Import BillAcceptance",
	documentBookCode: "BookCode",
	documentBookName: "BookName",
	bookCode: "BookCode",
	btnConfirm: "ConfirmSelected",

	titleImportPurchaseReceipt: "Import Document",
	deleteDocumentAll: "Delete All Document",
	receiptToGl: "Receipt To GL",
	insertRow: "Insert Row (F10)",
	removeRow: "Remove Row (F11)",
	createNewDocument: "Create Document (F2)",
	saveDocument: "Save (F8)",
	downloadPdf: "Download PDF (F6)",
	difference: "Difference (F4)",
	validateTotalAmount4: "One or more record has debit or credit amount but account hasn't selected",
	validateDescriptionLength: "item number :number <br>description specify a message with a maximum length of 500 characters.",
	titleImportPurchaseInvoice: "Import Document",
	activeLockStatus: "The document is locked.",
	activeUnlockStatus: "The document is unlocked.",
	switchDebitCredit: "Switch Dr/Cr",
	titleRevert: "Revert acounting",
	validateSaveAndGotoRevert: "Please save before revert accounting.",
	validateRevertAccountingIsNotReady:  "In process...",
	validateRevertAccountingNoDetail: "Data not found.",
};
