<template>
	<div>
		<PvDialog
			:header="lang.logoutMessage"
			v-model:visible="isloggingOut"
			modal
			position="top"
			:closable="false"
			:breakpoints="{ '960px': '75vw', '640px': '100vw' }"
			:style="{ width: '50vw' }"
		>
			<!-- {{ errorMessage }} -->
			<template #footer>
				<PvButton :label="lang.yes" @click="logout" class="p-button-danger" />
				<PvButton :label="lang.no" @click="isloggingOut = false" class="p-button-secondary" />
			</template>
		</PvDialog>
		<PvMenubar :model="items">
			<!-- <template #start>
        <img
          src="@/assets/images/logo.svg"
          alt="Image"
          height="30"
        />
    </template> -->
			<template #end>
				<div class="flex justify-content-end align-items-center" style="width: 300px">
					<p class="m-0 text-right white-space-nowrap overflow-hidden text-overflow-ellipsis">
						{{ projectName }}
					</p>
				</div>
			</template>
		</PvMenubar>
	</div>
</template>

<script>
import useLang from "../../hooks/lang.js";
import { useStore } from "vuex";
import { ref, computed, watch, onMounted } from "vue";
export default {
	setup() {
		//hooks
		const { lang } = useLang();
		const store = useStore();

		//data
		let items = ref(null);
		let reportmenu = ref(null);
		let isloggingOut = ref(false);

		//computed
		const selectedLanguage = computed(() => store.getters.selectedLanguage);
		const selectedProject = computed(() => store.getters.selectedProject);
		const userName = computed(() => store.getters.userName);
		const allMenu = computed(() => store.getters["report/reportMenu"]);
		const projectName = computed(() => {
			if (!selectedProject.value) {
				return "";
			}
			let pName = "";
			if (selectedLanguage.value === "TH") {
				pName = selectedProject.value.nameTh;
			} else {
				pName = selectedProject.value.nameEn;
			}
			return selectedProject.value.code + " : " + pName;
		});

		const mergeLang = computed(() => {
			if (store.getters.selectedLanguage === "TH") {
				return { ...lang.th.base, ...lang.th.menu };
			} else {
				return { ...lang.en.base, ...lang.en.menu };
			}
		});

		//methods
		function changeMenuLanguage(language) {
			let menu = {};
			if (language === "TH") {
				menu = { ...lang.th.menu };
			} else {
				menu = { ...lang.en.menu };
			}
			items.value = [
				{
					label: menu.ar,
					icon: "pi pi-fw pi-file",
					items: [
						{
							label: menu.arInvoice,
							icon: "pi pi-fw pi-file",
							to: "/ar/invoice",
						},
						{
							label: menu.arPrepaidRefund,
							icon: "pi pi-fw pi-file",
							to: "/ar/prepaid-refund",
						},
						{
							label: menu.arBank,
							icon: "pi pi-fw pi-file",
							to: "/ar/bank-ar",
						},
					],
				},
				{
					label: menu.ap,
					icon: "pi pi-fw pi-file",
					items: [
						{
							label: menu.apWithholdingTax,
							icon: "pi pi-fw pi-file",
							to: "/ap/withholding-tax",
						},
						{
							label: menu.apBank,
							icon: "pi pi-fw pi-file",
							to: "/ap/bank-ap",
						},
						{
							label: menu.apPurchaseRequisition,
							icon: "pi pi-fw pi-file",
							to: "/ap/purchase-requisition",
						},
						{
							label: menu.apPurchaseOrder,
							icon: "pi pi-fw pi-file",
							to: "/ap/purchase-order",
						},
						{
							label: menu.apPurchaseInvoice,
							icon: "pi pi-fw pi-file",
							to: "/ap/purchase-invoice",
						},
						{
							label: menu.apReceipt,
							icon: "pi pi-fw pi-file",
							to: "/ap/purchase-receipt",
						},
					],
				},
				{
					label: menu.gl,
					icon: "pi pi-fw pi-file",
					items: [
						{
							label: menu.glInvoice,
							icon: "pi pi-fw pi-file",
							to: "/gl/invoice",
						},
						{
							label: menu.glReceipt,
							icon: "pi pi-fw pi-file",
							to: "/gl/receipt",
						},
						{
							label: menu.glAccounting,
							icon: "pi pi-fw pi-file",
							to: "/gl/accounting",
						},
						{
							label: menu.glPettyCash,
							icon: "pi pi-fw pi-file",
							to: "/gl/pettycash",
						},
					],
				},
				{
					label: menu.asset,
					icon: "pi pi-fw pi-file",
					items: [
						{
							label: menu.assetRegister,
							icon: "pi pi-fw pi-file",
							to: "/asset/register",
						},
					]
				},
				{
					id: "report",
					label: menu.report,
					icon: "pi pi-fw pi-file",
					items: reportmenu.value,
				},
				{
					label: menu.admin,
					icon: "pi pi-fw pi-shield",
					items: [
						{
							label: menu.adminFormManage,
							icon: "pi pi-fw pi-folder",
							to: "/admin/form",
						},
					],
				},
				{
					label: menu.master,
					icon: "pi pi-fw pi-shield",
					items: [
						{
							label: menu.masterProject,
							icon: "pi pi-fw pi-folder",
							to: "/master/project",
						},
						{
							label: menu.projectDocument,
							icon: "pi pi-fw pi-folder",
							to: "/project-document",
						},
						{
							label: menu.masterAccountChart,
							icon: "pi pi-fw pi-folder",
							to: "/account-chart",
						},
						{
							label: menu.masterAdvertisement,
							icon: "pi pi-fw pi-folder",
							to: "/master/product-advertisement/edit",
						}
					],
				},
				{
					label: menu.residentInformation,
					icon: "pi pi-fw pi-shield",
					items: [
						{
							label: menu.unit,
							icon: "pi pi-fw pi-folder",
							to: "/unit",
						},
					]
				},
				{
					label: userName.value || "user",
					icon: "pi pi-fw pi-user",
					items: [
						{
							label: menu.changeProject,
							icon: "pi pi-fw pi-building",
							to: "/select-project",
						},
						{
							label: menu.setting,
							icon: "pi pi-fw pi-cog",
							to: "/setting",
						},
						{
							label: menu.logout,
							icon: "pi pi-fw pi-power-off",
							command: () => {
								isloggingOut.value = true;
							},
						},
					],
				},
			];
		}

		function getReportMenu(language) {
			// let menus = allMenu.value;
			// console.log(menus);

			let menuList = [];
			allMenu.value.forEach((menu) => {
				if (menu.referId == null) {
					let nowMenu = {};
					// let refMenu = {};
					nowMenu.icon = "pi pi-fw pi-file";
					nowMenu.to = menu.path;
					// nowMenu.items = [];

					if (language === "TH") {
						nowMenu.label = menu.nameThai;
					} else {
						nowMenu.label = menu.nameEnglish;
					}

					// menus.find((menuL2) => {
					// 	if (menuL2.referId == menu.id) {
					// 		console.log("if", menu);
					// 		refMenu.icon = "pi pi-fw pi-file";
					// 		refMenu.to = menuL2.path;
					// 		if (language === "TH") {
					// 			refMenu.label = menuL2.nameThai;
					// 		} else {
					// 			refMenu.label = menuL2.nameEnglish;
					// 		}
					// 		nowMenu.items.push(refMenu);
					// 	}
					// });

					menuList.push(nowMenu);
					// console.log(menuList);
				}
			});
			reportmenu.value = menuList;
			// console.log(reportmenu.value);
		}

		const logout = function () {
			store.dispatch("autoLogout");
		};

		//created
		onMounted(async () => {
			const payload = {
				page: 1,
				limit: 100,
			};
			await store.dispatch("report/getReportMenu", payload);

			await getReportMenu(selectedLanguage.value);
			await changeMenuLanguage(selectedLanguage.value);
		});

		//watcher
		watch(selectedLanguage, function (curValue, oldValue) {
			if (curValue !== oldValue) {
				getReportMenu(curValue);
				changeMenuLanguage(curValue);
			}
		});

		return { items, projectName, lang: mergeLang, logout, isloggingOut };
	},
	//  data() {
	//      return {
	//          items: [
	//              {
	//                 label:'File',
	//                 icon:'pi pi-fw pi-file',
	//                 items:[
	//                    {
	//                       label:'New',
	//                       icon:'pi pi-fw pi-plus',
	//                       items:[
	//                          {
	//                             label:'Bookmark',
	//                             icon:'pi pi-fw pi-bookmark'
	//                          },
	//                          {
	//                             label:'Video',
	//                             icon:'pi pi-fw pi-video'
	//                          },

	//                       ]
	//                    },
	//                    {
	//                       label:'Delete',
	//                       icon:'pi pi-fw pi-trash'
	//                    },
	//                    {
	//                       separator:true
	//                    },
	//                    {
	//                       label:'Export',
	//                       icon:'pi pi-fw pi-external-link'
	//                    }
	//                 ]
	//              },
	//              {
	//                 label:'Edit',
	//                 icon:'pi pi-fw pi-pencil',
	//                 items:[
	//                    {
	//                       label:'Left',
	//                       icon:'pi pi-fw pi-align-left'
	//                    },
	//                    {
	//                       label:'Right',
	//                       icon:'pi pi-fw pi-align-right'
	//                    },
	//                    {
	//                       label:'Center',
	//                       icon:'pi pi-fw pi-align-center'
	//                    },
	//                    {
	//                       label:'Justify',
	//                       icon:'pi pi-fw pi-align-justify'
	//                    },

	//                 ]
	//              },
	//              {
	//                 label:'Users',
	//                 icon:'pi pi-fw pi-user',
	//                 items:[
	//                    {
	//                       label:'New',
	//                       icon:'pi pi-fw pi-user-plus',

	//                    },
	//                    {
	//                       label:'Delete',
	//                       icon:'pi pi-fw pi-user-minus',

	//                    },
	//                    {
	//                       label:'Search',
	//                       icon:'pi pi-fw pi-users',
	//                       items:[
	//                          {
	//                             label:'Filter',
	//                             icon:'pi pi-fw pi-filter',
	//                             items:[
	//                                {
	//                                   label:'Print',
	//                                   icon:'pi pi-fw pi-print'
	//                                }
	//                             ]
	//                          },
	//                          {
	//                             icon:'pi pi-fw pi-bars',
	//                             label:'List'
	//                          }
	//                       ]
	//                    }
	//                 ]
	//              },
	//              {
	//                 label:'Events',
	//                 icon:'pi pi-fw pi-calendar',
	//                 items:[
	//                    {
	//                       label:'Edit',
	//                       icon:'pi pi-fw pi-pencil',
	//                       items:[
	//                          {
	//                             label:'Save',
	//                             icon:'pi pi-fw pi-calendar-plus'
	//                          },
	//                          {
	//                             label:'Delete',
	//                             icon:'pi pi-fw pi-calendar-minus'
	//                          },

	//                       ]
	//                    },
	//                    {
	//                       label:'Archieve',
	//                       icon:'pi pi-fw pi-calendar-times',
	//                       items:[
	//                          {
	//                             label:'Remove',
	//                             icon:'pi pi-fw pi-calendar-minus'
	//                          }
	//                       ]
	//                    }
	//                 ]
	//              },
	//              {
	//                 label:'Quit',
	//                 icon:'pi pi-fw pi-power-off'
	//              }
	//           ]
	//      }
	//  }
};
</script>
