export default {
  async getUnitProducts(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Unit/GetUnitProductSetupForInvoice";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }
    url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get productAR");
      throw error;
    }
    // console.log('productAR',responseData);
    context.commit("setUnitProducts", responseData);
  },  
  
  async AddUnitProduct(context, payload) {
		// console.log('AddBankTransaction',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/Unit/UnitProductSetupForInvoice/Add";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("AddBankTransaction>>>",responseData);
			const error = new Error(responseData.message || "Failed to add PenaltyRateStep");
			throw error;
		}
		//console.log("AddPrepaid_responseData>>>",responseData);
		await context.commit("setUnitProducts", responseData);
	},
    
  async EditUnitProduct(context, payload) {
		// console.log('AddBankTransaction',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/Unit/UnitProductSetupForInvoice/Update";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("AddBankTransaction>>>",responseData);
			const error = new Error(responseData.message || "Failed to add PenaltyRateStep");
			throw error;
		}
		//console.log("AddPrepaid_responseData>>>",responseData);
		await context.commit("setUnitProducts", responseData);
	},

  async DeleteUnitProduct(context, payload) {
		// console.log('AddBankTransaction',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/Unit/UnitProductSetupForInvoice/Delete";

		if (payload.PenaltyId) {
			url = url + "?PenaltyId=" + payload.PenaltyId;
		}
		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "Delete",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("AddBankTransaction>>>",responseData);
			const error = new Error(responseData.message || "Failed to add PenaltyRateStep");
			throw error;
		}
		//console.log("AddPrepaid_responseData>>>",responseData);
		await context.commit("setUnitProducts", responseData);
	},

};
