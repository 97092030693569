export default {
    setReportMenu(state, payload) {
      state.reportMenus = payload.data;
      state.totalPage = payload.page.pageCount;
      state.currentPage = payload.page.currentPage;
    },
    setSelectedReportMenu(state, payload) {
      state.selectedReportMenu = payload;
    },
    resetReportMenu(state) {
      state.reportMenus = [];
      state.totalPage = 0;
      state.currentPage = 0;
    },
};