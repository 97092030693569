export default {
    paymentRequests(state) {
        return state.paymentRequests;
      },
      selectedPaymentRequest(state) {
        return state.selectedPaymentRequest;
      },
      totalPage(state) {
        return state.totalPage;
      },
      currentPage(state) {
        return state.currentPage;
      },
      rows(state) {
        return state.rows;
      },
      paymentRequestForAutoGl(state) {
        return state.paymentRequestForAutoGl;
      },
      budgetSummary(state) {
        return state.budgetSummary;
      },
      paymentRequestReference(state) {
        return state.paymentRequestReference;
      }
    
}