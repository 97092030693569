export default {
    debtFreeRequests(state) {
      return state.debtFreeRequests;
    },
    totalPage(state) {
      return state.totalPage;
    },
    currentPage(state) {
      return state.currentPage;
    },
    rows(state) {
      return state.rows;
    },
    selectedDebtFreeRequest(state){
      return state.selectedDebtFreeRequest;
    },
    selectedUnit(state){
      return state.selectedUnit;
    },
};
  