<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="8.99884"
      cy="9.99909"
      rx="6.0025"
      ry="2.50104"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.0013 13.501C15.0013 14.8823 12.3139 16.002 8.99884 16.002C5.68375 16.002 2.99634 14.8823 2.99634 13.501"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.004 9.99902C21.004 11.3803 18.3166 12.5001 15.0015 12.5001"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.0013 9.99902V17.5021C15.0013 18.8834 12.3139 20.0032 8.99884 20.0032C5.68375 20.0032 2.99634 18.8834 2.99634 17.5021V9.99902"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.004 6.49805V14.0012C21.004 15.3825 18.3166 16.5022 15.0015 16.5022"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.49899 7.50853C9.202 7.2536 9.02143 6.88886 8.99878 6.49811C8.99878 5.11754 11.6899 3.99707 15.0013 3.99707C18.3127 3.99707 21.0038 5.11754 21.0038 6.49811C21.0038 7.87869 18.3127 8.99915 15.0013 8.99915C14.8312 8.99915 14.6611 8.99915 14.5011 8.98915"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
