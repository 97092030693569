export default {
  title: "รายการใบเสร็จ Visitor control",
  labelSearchText:"เลขที่เอกสาร",
  searchTextPlaceholder: "กรอกข้อมูลเลขที่เอกสารเพื่อค้นหา",
  dropdownCash: "เงินสด",
  dropdownTransfer: "เงินโอน",
  dropdownCheque: "เช็ค",
  dropdownCreditCard: "บัตรเครดิต",
  tableEmpty: "ไม่มีรายการ",
  documentCode: "เลขที่เอกสาร",
  date: "วันที่เอกสาร",
  unit: "ยูนิต",
  address:"บ้านเลขที่",
  customerName: "ชื่อลูกค้า",
  price: "ราคา",
	validateRoleSelect: "คุณไม่มีสิทธิ์ เข้าถึงเอกสาร",
};
