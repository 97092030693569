export default {
	createTitle: "Create Payin",
	editTitle: "Edit Payin",
	detailTitle: "Payin Detail",

	netAmount: "Net Amount",
	documentCode: "Document Code",
	createNew: "CreateNew",
	searchBy: "SearchBy",
	payType: "Payin Type",
	status: "Status",
	bankPayin: "Bank Payin",
	cross: "crossed cheque",
	holder: 'cross out "Or holder"',
	unitCode: "Unit Code",
	dateAt: "Document Date",
	customerName: "Customer Name",
	customerSearch: "Customer Code or Name",
	employeeName: "Employee Name",
	receiveMan: "Receive To",
	remark: "Remark",
	payDate: "Payin Date",
	dueDate: "Due Date",
	toBankDatePayin: "To Bank Date",
  	cancelPayinSuccess: "Cancel Payin {code} Success",

	referenceReceipt: "Reference Receipt",

	validateBookbank: "Please select Bookbank",
	validateNoCustomerSelect: "Please select Customer",
	validateReceiptExtra: "No more Receipt",
	validatePayType: "Please select Payin Type",
	validateReference: "This payin has reference in the system.",

	validateInvalidSetupBookbank: "Bookbank is invalid setup bank.",
	validateTotalAmount: "Total amount must be greater than or equal 0.",
	usedAmount: "Used Amount",
    balance: "Balance",
	feeAmount: "Fee Amount",
};
