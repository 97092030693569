export default {
	voucherReferenceDocuments(state) {
		return state.voucherReferenceDocuments;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	selectedVoucherReferenceDocument(state) {
		return state.selectedVoucherReferenceDocument;
	},
};
