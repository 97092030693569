<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
  >
    <path
      d="M11.3333 11.3333V12.6667C11.3334 13.0203 11.193 13.3595 10.9429 13.6096C10.6928 13.8597 10.3537 14.0001 10 14H4C3.64635 14.0001 3.30715 13.8597 3.05708 13.6096C2.80701 13.3595 2.66657 13.0203 2.66667 12.6667V5.33333C2.66657 4.97968 2.80701 4.64049 3.05708 4.39042C3.30715 4.14035 3.64635 3.9999 4 4H5.33333"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9062 3.64694L11.6864 2.42713C11.4129 2.15364 11.042 2 10.6552 2H6.79167C5.98625 2 5.33333 2.65292 5.33333 3.45833V9.875C5.33333 10.6804 5.98625 11.3333 6.79167 11.3333H11.875C12.6804 11.3333 13.3333 10.6804 13.3333 9.875V4.67813C13.3333 4.29136 13.1797 3.92043 12.9062 3.64694Z"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.33333 7.66666H10.3333"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.33333 6.66667V8.66667"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3333 5H11.3333C10.781 5 10.3333 4.55228 10.3333 4V2"
      :class="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "primary",
    },
  },
  computed:{
    strokeColor(){
        return "p-stroke-"+this.iconColor;
    }
  }
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>