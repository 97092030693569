<template>
  <base-toolbar
    :title="lang.titleEdit"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @goto-approve="askBeforeApprove"
    @goto-disapprove="askBeforeDisapprove"
  ></base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :classTitle="baseDialogClassTitle"
      :titleConfirm="titleConfirm"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="confirmBaseDialog"
      @close="closeBaseDialog"
      @goto-approve="approve"
      @goto-disapprove="disapprove"
    >
      <template #dialogBody v-if="isDisApprove">
        <div class="col-12 p-0 w-full">
          <PvDropdown
            id="cancelReasons"
            class="inputfield w-full"
            :options="reasons"
            optionLabel="name"
            optionValue="name"
            v-model="disapproveReason"
            :placeholder="lang.reasonPlaceholder"
            :class="{ 'p-invalid': reasonsError }"
            @change="reasonsError = false"
          />
          <small v-if="reasonsError" id="reason-help" class="p-error">{{
            lang.validateReason
          }}</small>
        </div>
      </template>
    </base-dialog>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{ lang.title }}</label>

      <PvDivider></PvDivider>
<!-- ส่วนที่ 1 -->
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="documentDate">{{ lang.documentDate }}</label>
          <PvCalendar
            id="documentDate"
            v-model="documentDate"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            inputClass="p-inputtext-disabled"
            disabled
          />
        </div>

        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
          <label for="unitCode">{{ lang.unitCode }}</label>
          <PvInputText
            id="unitCode"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="unitCode"
            disabled
          />
        </div>

        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
          <label for="unitAddressNo">{{ lang.unitAddressNo }}</label>
          <PvInputText
            id="unitAddressNo"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="unitAddressNo"
            disabled
          />
        </div>

        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="ownerName">{{ lang.customerName }}</label>
          <PvInputText
            id="ownerName"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="ownerName"
            disabled
          />
        </div>

        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="ownerIdCardAddress">{{ lang.address }}</label>
          <PvInputText
            id="ownerIdCardAddress"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="ownerIdCardAddress"
            disabled
          />
        </div>

        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="unitArea">{{ lang.unitArea }}</label>
          <!-- <PvInputText
              id="unitArea"
              type="text"
              class="inputfield w-full mt-1 p-inputtext-disabled"
              v-model="unitArea"
              disabled
            /> -->
          <PvInputNumber
            id="unitArea"
            class="inputfield w-full mt-1"
            inputClass="p-inputtext-disabled"
            v-model="unitArea"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            disabled
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="unitCount">{{ lang.unitCount }}</label>
          <PvInputNumber
            id="unitCount"
            class="inputfield w-full mt-1"
            inputClass="p-inputtext-disabled"
            v-model="unitCount"
            disabled
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="pendingCalculateDate">{{ lang.pendingDate }}</label>
          <PvCalendar
            id="pendingCalculateDate"
            v-model="pendingCalculateDate"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            inputClass="p-inputtext-disabled"
            disabled
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="pendingAmount">{{ lang.pendingAmount }}</label>
          <PvInputNumber
            id="pendingAmount"
            class="inputfield w-full mt-1"
            inputClass="p-inputtext-disabled text-right"
            v-model="pendingAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            disabled
          />
        </div>
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="requestDate">{{ lang.requestDate }}</label>
          <PvCalendar
            id="requestDate"
            v-model="requestDate"
            class="inputfield w-full mt-1"
            inputClass="p-inputtext-disabled"
            showIcon
            dateFormat="dd/mm/yy"
            disabled
          />
        </div>
        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
          <label for="requestBy">{{ lang.requestBy }}</label>
          <PvInputText
            id="requestBy"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="requestBy"
            disabled
          />
        </div>
        <div class="lg:col-3 md:col-6 col-12 pl-0 pr-2">
          <label for="requestPhone">{{ lang.phoneNumber }}</label>
          <PvInputText
            id="requestPhone"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="requestPhone"
            disabled
          />
        </div>
      </section>

<!-- ส่วนที่ 2 -->
      <section class="flex flex-wrap fontlabel pt-4" :class="textFormClass">
        <!-- left -->
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <div class="content-border h-full">
            <label class="font-lg font-normal">{{
              lang.requestDocument
            }}</label>
            <PvDivider></PvDivider>
            <div class="pt-1 field-checkbox">
              <PvCheckbox
                id="isGetCopyOfOwnerIdCard"
                inputId="binary"
                class="mt-1"
                v-model="isGetCopyOfOwnerIdCard"
                :binary="true"
                :disabled="true"
              />
              <label for="isGetCopyOfOwnerIdCard" class="pl-2">{{
                lang.isGetCopyOfOwnerIdCard
              }}</label>
            </div>
            <div class="pt-1 field-checkbox">
              <PvCheckbox
                id="isGetCopyOfOwnerHouseRegistration"
                inputId="binary"
                class="mt-1"
                v-model="isGetCopyOfOwnerHouseRegistration"
                :binary="true"
                :disabled="true"
              />
              <label for="isGetCopyOfOwnerHouseRegistration" class="pl-2">{{
                lang.isGetCopyOfOwnerHouseRegistration
              }}</label>
            </div>
            <div class="pt-1 field-checkbox">
              <PvCheckbox
                id="isGetCopyOfJointOwnerShip"
                inputId="binary"
                class="mt-1"
                v-model="isGetCopyOfJointOwnerShip"
                :binary="true"
                :disabled="true"
              />
              <label for="isGetCopyOfJointOwnerShip" class="pl-2">{{
                lang.isGetCopyOfJointOwnerShip
              }}</label>
            </div>
            <div class="pt-1 field-checkbox">
              <PvCheckbox
                id="isGetPowerOfAttorney"
                inputId="binary"
                class="mt-1"
                v-model="isGetPowerOfAttorney"
                :binary="true"
                :disabled="true"
              />
              <label for="isGetPowerOfAttorney" class="pl-2">{{
                lang.isGetPowerOfAttorney
              }}</label>
            </div>
            <div class="pt-1 field-checkbox">
              <PvCheckbox
                id="isGetCopyOfProxyIdCard"
                inputId="binary"
                class="mt-1"
                v-model="isGetCopyOfProxyIdCard"
                :binary="true"
                :disabled="true"
              />
              <label for="isGetCopyOfProxyIdCard" class="pl-2">{{
                lang.isGetCopyOfProxyIdCard
              }}</label>
            </div>
            <div class="pt-1 field-checkbox">
              <PvCheckbox
                id="isGetCopyOfNameChangedCertificate"
                inputId="binary"
                class="mt-1"
                v-model="isGetCopyOfNameChangedCertificate"
                :binary="true"
                :disabled="true"
              />
              <label for="isGetCopyOfNameChangedCertificate" class="pl-2">{{
                lang.isGetCopyOfNameChangedCertificate
              }}</label>
            </div>
            <div class="flex flex-wrap align-items-center">
              <div class="field-checkbox mb-0">
                <PvCheckbox
                  id="isOtherDocument"
                  inputId="binary"
                  class=""
                  v-model="isOtherDocument"
                  :binary="true"
                  :disabled="true"
                />
                <label for="isOtherDocument" class="w-full pl-2 pr-2">{{
                  lang.isOtherDocument
                }}</label>
              </div>
              <div class="w-8 pl-0">
                <PvInputText
                  id="otherDocumentDescription"
                  type="text"
                  class="inputfield w-full p-inputtext-disabled"
                  v-model="otherDocumentDescription"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <!-- right -->
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <div class="content-border h-full">
            <label class="font-lg font-normal">{{ lang.requestDetail }}</label>
            <PvDivider></PvDivider>
            <div class="pt-1 field-checkbox mb-0">
              <PvCheckbox
                id="isRequestCutOfPublicUtilities"
                inputId="binary"
                class="mt-1"
                v-model="isRequestCutOfPublicUtilities"
                :binary="true"
                :disabled="true"
              />
              <label for="isRequestCutOfPublicUtilities" class="pl-2">{{
                lang.isRequestCutOfPublicUtilities
              }}</label>
            </div>
            <div
              class="align-items-center flex flex-wrap w-auto pl-5 pb-1 mt-1"
            >
              <label
                for="startCutOfPublicUtilitiesDate"
                class="pl-1 ml-1 pr-2"
                >{{ lang.date }}</label
              >
              <div class="col-10 p-0 flex align-items-center">
                <PvCalendar
                  id="startCutOfPublicUtilitiesDate"
                  v-model="startCutOfPublicUtilitiesDate"
                  class="inputfield w-full"
                  inputClass="p-inputtext-disabled"
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled
                />
                <label for="endCutOfPublicUtilitiestDate" class="mr-2 ml-2">{{
                  "-"
                }}</label>
                <PvCalendar
                  id="endCutOfPublicUtilitiestDate"
                  v-model="endCutOfPublicUtilitiestDate"
                  class="inputfield w-full"
                  inputClass="p-inputtext-disabled"
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled
                />
              </div>
            </div>
            <div class="pt-2 field-checkbox">
              <PvCheckbox
                id="isPaidFee"
                inputId="binary"
                class="mt-1"
                v-model="isPaidFee"
                :binary="true"
                :disabled="true"
              />
              <label for="isPaidFee" class="pl-2">{{ lang.isPaidFee }}</label>
            </div>
            <div class="pt-1 field-checkbox">
              <PvCheckbox
                id="isPaidExpenses"
                inputId="binary"
                class="mt-1"
                v-model="isPaidExpenses"
                :binary="true"
                :disabled="true"
              />
              <label for="isPaidExpenses" class="pl-2">{{
                lang.isPaidExpenses
              }}</label>
            </div>
            <div class="flex flex-wrap pl-5 align-items-center">
              <label for="receiptCode" class="pl-1 ml-1 pr-2">{{
                lang.receiptCode
              }}</label>
              <div class="col-9 p-0">
                <PvInputText
                  id="receiptCode"
                  type="text"
                  class="inputfield w-full p-inputtext-disabled"
                  v-model="receiptCode"
                  disabled
                />
              </div>
            </div>
            <div class="flex flex-wrap pt-2 pb-2 pl-5 align-items-center">
              <label for="receiptAmount" class="pl-1 ml-1 pr-4 mr-0">{{
                lang.receiptAmount
              }}</label>
              <div class="col-9 p-0">
                <PvInputNumber
                  id="receiptAmount"
                  class="inputfield w-full"
                  inputClass="p-inputtext-disabled text-right"
                  v-model="receiptAmount"
                  mode="decimal"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </section>
<!-- ส่วนที่ 3 -->
      <section
        class="flex flex-wrap fontlabel pt-4"
        :class="textFormClass"
      >
        <div class="col-12 pb-0 pl-0 pr-2 field-checkbox">
          <PvCheckbox
            id="isValidateExpenses"
            inputId="binary"
            class="mt-1"
            v-model="isValidateExpenses"
            :binary="true"
            :disabled="true"
          />
          <label for="isValidateExpenses" class="pl-2">{{
            lang.isValidateExpenses
          }}</label>
        </div>
        <!-- <div class="flex-wrap col-12 p-0 flex align-items-center">
          <div class="flex flex-wrap p-0">
            <div
              v-for="debtFreeApproval of debtFreeApprovals"
              :key="debtFreeApproval.code"
              class="field-radiobutton mr-5 mb-0"
            >
              <PvRadioButton
                class="mr-2"
                :inputId="debtFreeApproval.code"
                name="debtFreeApproval"
                :value="debtFreeApproval.code"
                v-model="isDebtfreeApproval"
                @change="changeApproval"
              />
              <label :for="debtFreeApproval.code" class="ml-0">{{
                debtFreeApproval.name
              }}</label>
            </div>
          </div>
          <div class="ml-0 w-4 p-2">
            <PvDropdown
              id="cancelReasons"
              class="inputfield w-full"
              :options="reasons"
              optionLabel="name"
              optionValue="name"
              v-model="disapproveReason"
              :placeholder="lang.reasonPlaceholder"
              :class="{ 'p-invalid': reasonsError }"
              @change="reasonsError = false"
            />
            <small v-if="reasonsError" id="reason-help" class="p-error">{{
              lang.validateReason
            }}</small>
          </div>
        </div> -->
      </section>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import usePageDirty from "../../../hooks/pageDirty";
export default {
  props: ["id", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.debtFree.request.edit,
          ...lang.th.debtFree.approve.listing,
          ...lang.th.debtFree.approve.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.debtFree.request.edit,
          ...lang.en.debtFree.approve.listing,
          ...lang.en.debtFree.approve.edit,
        };
      }
    });

    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    const { compareObject } = usePageDirty();

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      compareObject,
    };
  },
  data() {
    return {
      storageName: "",
      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: "",
      validateWording: "",

      // ส่วนที่ 1
      documentDate: null,
      unitCode: "",
      unitAddressNo: "",
      ownerName: "",
      ownerIdCardAddress: "",
      unitArea: 0,
      pendingCalculateDate: null,
      pendingAmount: 0,
      requestDate: null,
      requestBy: "",
      requestPhone: "",

      // ส่วนที่ 2
      isGetCopyOfOwnerIdCard: false,
      isGetCopyOfOwnerHouseRegistration: false,
      isGetCopyOfJointOwnerShip: false,
      isGetPowerOfAttorney: false,
      isGetCopyOfProxyIdCard: false,
      isGetCopyOfNameChangedCertificate: false,
      isOtherDocument: false,
      otherDocumentDescription: "",
      isRequestCutOfPublicUtilities: false,
      startCutOfPublicUtilitiesDate: null,
      endCutOfPublicUtilitiestDate: null,
      isPaidFee: false,
      isPaidExpenses: false,
      receiptCode: "",
      receiptAmount: 0,

      // ส่วนที่ 3
      isValidateExpenses: false,
      debtFreeApprovals: [
        { name: this.lang.approve, code: "Y" },
        { name: this.lang.disApprove, code: "N" },
      ],
      reasons: [
        { name: this.lang.cancelReason1, code: "1" },
        { name: this.lang.cancelReason2, code: "2" },
        { name: this.lang.cancelReason3, code: "3" },
      ],
      // isDebtfreeApproval: null,
      disapproveReason: "",
      reasonsError: false,
      debtFreeCode: "",

      isSaveSuccess: false,
      objToCompare: null,
      switchButtonDialog: "",
      isDisApprove: false,
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    await this.roleByMenuDataReady();
    await this.checkShouldSetData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      const localSearch = localStorage.getItem(this.storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      localStorage.setItem(this.storageName, JSON.stringify(search));
      this.$router.push({name: "debt-free-approve"});
    },
    async roleByMenuDataReady() {
      if (this.isSharedRoleByMenuReady) {
        await this.getDebtFreeApprove();
      }
    },
    async initiateData() {
      this.storageName = `erp_search_DebtFreeApproveList_${this.selectedProject.code}_${this.userCode}`;

      // ส่วนที่ 1
      this.documentDate = new Date();
      this.unitCode = "";
      this.unitAddressNo = "";
      this.ownerName = "";
      this.ownerIdCardAddress = "";
      this.unitArea = 0;
      this.pendingCalculateDate = null;
      this.pendingAmount = 0;
      this.requestDate = null;
      this.requestBy = "";
      this.requestPhone = "";

      // ส่วนที่ 2
      this.isGetCopyOfOwnerIdCard = false;
      this.isGetCopyOfOwnerHouseRegistration = false;
      this.isGetCopyOfJointOwnerShip = false;
      this.isGetPowerOfAttorney = false;
      this.isGetCopyOfProxyIdCard = false;
      this.isGetCopyOfNameChangedCertificate = false;
      this.isOtherDocument = false;
      this.otherDocumentDescription = "";
      this.isRequestCutOfPublicUtilities = false;
      this.startCutOfPublicUtilitiesDate = null;
      this.endCutOfPublicUtilitiestDate = null;
      this.isPaidFee = false;
      this.isPaidExpenses = false;
      this.receiptCode = "";
      this.receiptAmount = 0;

      // ส่วนที่ 3
      this.isValidateExpenses = false;
      // this.isDebtfreeApproval = null;
      this.disapproveReason = "";
      this.reasonsError = false;
      this.debtFreeCode = "";

      this.isSaveSuccess = false;
      this.switchButtonDialog = "";
      this.isDisApprove = false;
    },
    async getDebtFreeApprove() {
      try {
        await this.$store.dispatch(
          "debtFreeApprove/getDebtFreeApproveById",
          this.id
        );

        if (this.debtFreeApprove) {
          await this.getUnitCountByCustomerId();
        }
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    async getUnitCountByCustomerId() {
      try {
        await this.$store.dispatch( "masterResident/getUnitCountById", this.debtFreeApprove.ownerId );

      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    async checkShouldSetData() {
      if (this.isSharedRoleByMenuReady) {
        if (this.debtFreeApprove) {
          await this.setData();
        }
        await this.setCompareData();
      }
    },
    async setData() {
      // console.log(this.debtFreeApprove);

      // ส่วนที่ 1
      this.documentDate = new Date(this.debtFreeApprove.documentDate);
      this.unitCode = this.debtFreeApprove.unitCode;
      this.unitAddressNo = this.debtFreeApprove.unitAddressNo;
      this.ownerName = this.debtFreeApprove.debtfreeRequestOwnerName;
      this.ownerIdCardAddress =
        this.debtFreeApprove.ownerIdCardAddress1 +
        " " +
        this.debtFreeApprove.ownerIdCardAddress2 +
        " " +
        this.debtFreeApprove.ownerIdCardAddress3 +
        " " +
        this.debtFreeApprove.ownerIdCardPostCode;
      this.unitArea = this.debtFreeApprove.unitArea;
      this.pendingCalculateDate = new Date(
        this.debtFreeApprove.pendingCalculateDate
      );
      this.pendingAmount = this.debtFreeApprove.pendingAmount;
      this.requestDate = new Date(this.debtFreeApprove.requestDate);
      this.requestBy = this.debtFreeApprove.requestBy;
      this.requestPhone = this.debtFreeApprove.requestPhone;

      // ส่วนที่ 2
      this.isGetCopyOfOwnerIdCard =
        this.debtFreeApprove.isGetCopyOfOwnerIdCard == "Y" ? true : false;
      this.isGetCopyOfOwnerHouseRegistration =
        this.debtFreeApprove.isGetCopyOfOwnerHouseRegistration == "Y"
          ? true
          : false;
      this.isGetCopyOfJointOwnerShip =
        this.debtFreeApprove.isGetCopyOfJointOwnerShip == "Y" ? true : false;
      this.isGetPowerOfAttorney =
        this.debtFreeApprove.isGetPowerOfAttorney == "Y" ? true : false;
      this.isGetCopyOfProxyIdCard =
        this.debtFreeApprove.isGetCopyOfProxyIdCard == "Y" ? true : false;
      this.isGetCopyOfNameChangedCertificate =
        this.debtFreeApprove.isGetCopyOfNameChangedCertificate == "Y"
          ? true
          : false;
      this.isOtherDocument =
        this.debtFreeApprove.isOtherDocument == "Y" ? true : false;
      this.otherDocumentDescription =
        this.debtFreeApprove.otherDocumentDescription;
      this.isRequestCutOfPublicUtilities =
        this.debtFreeApprove.isRequestCutOfPublicUtilities == "Y"
          ? true
          : false;
      this.startCutOfPublicUtilitiesDate = new Date(
        this.debtFreeApprove.startCutOfPublicUtilitiesDate
      );
      this.endCutOfPublicUtilitiestDate = new Date(
        this.debtFreeApprove.endCutOfPublicUtilitiestDate
      );
      this.isPaidFee = this.debtFreeApprove.isPaidFee == "Y" ? true : false;
      this.isPaidExpenses =
        this.debtFreeApprove.isPaidExpenses == "Y" ? true : false;
      this.receiptCode = this.debtFreeApprove.receiptCode;
      this.receiptAmount =
        this.debtFreeApprove.receiptAmount == ""
          ? 0
          : parseInt(this.debtFreeApprove.receiptAmount);

      // ส่วนที่ 3
      this.isValidateExpenses =
        this.debtFreeApprove.isValidateExpenses == "Y" ? true : false;
      // this.isDebtfreeApproval = this.debtFreeApprove.isDebtfreeApproval;
      this.disapproveReason = this.debtFreeApprove.disapproveReason;
      this.debtFreeCode = this.debtFreeApprove.debtFreeCode;
      this.isDisApprove = this.debtFreeApprove.isDebtfreeApproval=="N"?true:false;

      this.setLoading(false);
    },
    async setCompareData() {
      let objToCompare = {
        id: this.id,
        isDebtfreeApproval: this.isDebtfreeApproval,
        disapproveReason: this.disapproveReason,
      };

      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
      // console.log('this.objToCompare',this.objToCompare);
      // console.log('this.payload',this.payload);
      // console.log(this.isPageDirty);
    },
    async confirmBaseDialog() {
      let element = document.getElementsByTagName("body")[0];
      element.style.overflow = "scroll";
      
      if( this.isSaveSuccess ) {
        await this.$router.replace({name: "debt-free-approve"});
      }
      else {
        this.showValidateDialog = false;
        this.isSaveSuccess = false;
        this.closeBaseDialog();
      }
    },
    validateData() {
      if (this.isDebtfreeApproval == "N" && !this.disapproveReason) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.validateReason;
        // this.validateWording = this.lang.validateReason;
        this.showValidateDialog = true;
        this.reasonsError = true;
        return true;
      }

      return false;
    },
    async save() {
      this.switchButtonDialog = "";
      // console.log(this.payload);

      this.setLoading(true);
      try {
        await this.$store.dispatch("debtFreeApprove/approveDebtfree", this.payload );


        let validateWording = this.isDisApprove?
          this.lang.debtFreeDisapproveSuccess :
          [
            this.lang.debtFreeApproveSuccess,
            this.lang.code.replace(
              ":code",
              this.debtFreeApprove.debtfreeCode
            )
          ];
        this.clearBaseDialog();
        this.isSaveSuccess = true;
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.successfully;
        this.validateWording = validateWording;
        this.showValidateDialog = true;
        await this.setCompareData();
        // console.log(this.debtFreeApprove);

      } catch (error) {
        this.closeBaseDialog();
        this.isSaveSuccess = false;
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    changeApproval() {
      this.reasonsError = false;
      this.disapproveReason = "";
    },
    askBeforeApprove() {
      let element = document.getElementsByTagName("body")[0];
      element.style.overflow = "hidden";
      
      this.switchButtonDialog = "confirmApprove";

      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.validateWording = this.lang.askForApproveDocument;
      this.showValidateDialog = true;
    },
    askBeforeDisapprove() {
      let element = document.getElementsByTagName("body")[0];
      element.style.overflow = "hidden";

      this.switchButtonDialog = "confirmDisapprove";
      this.isDisApprove = true;

      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.validateReason;
      this.validateWording = "";
      this.showValidateDialog = true;
    },
    async disapprove() {
      this.isDisApprove = true;

      if (this.validateData()) {
        return;
      }
      await this.save();
    },
    async approve() {
      this.isDisApprove = false;
      await this.save();
    },
    closeBaseDialog() {
      let element = document.getElementsByTagName("body")[0];
      element.style.overflow = "scroll";

      this.clearBaseDialog();
      this.showValidateDialog = false;
    },
    clearBaseDialog() {
      this.isDisApprove = false;
      this.isSaveSuccess = false;
      this.disapproveReason = "";
      this.reasonsError = false;

      this.switchButtonDialog = "";
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.validateWording = "";
    },
  },
  computed: {
    payload() {
      let payload = {
        id: this.id,
        isDebtfreeApproval: this.isDebtfreeApproval,
        disapproveReason: this.disapproveReason,
      };

      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    isCreateDocument() {
      return this.id === "create";
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmCOR");
    },
    isRoleApprove() {
      if (this.roleByMenu && this.roleByMenu.approve == "Y") {
        return true;
      }
      return false;
    },
    isDisableSave() {
      if (!this.isRoleApprove) {
        return true;
      }
      return false;
    },
    debtFreeApprove() {
      return this.$store.getters["debtFreeApprove/selectedDebtFreeApprove"];
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "disApprove",
          caption: this.lang.disApprove,
          class: "p-button-outlined p-button-danger mr-2",
          icon: "icon-cancel",
          iconColor: "error",
          action: "goto-disapprove",
          isShow: true,
          isDisable: this.isDisableSave,
        },
        {
          id: "approve",
          caption: this.lang.approve,
          class: "mr-2",
          icon: "icon-path",
          iconColor: "white",
          action: "goto-approve",
          isShow: true,
          isDisable: this.isDisableSave,
        },
      ];
      return dataButtons;
    },
    dataButtonDialog() {
      let dataButtons = [
      {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];
      if(this.switchButtonDialog == "confirmApprove") {
        dataButtons = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "gotoApprove",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-approve",
          },
        ];
      }
      else if(this.switchButtonDialog == "confirmDisapprove") {
        dataButtons = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "gotoDisapprove",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-disapprove",
          },
        ];
      }
      return dataButtons;
    },
    unitCountByCustomerId() {
      return this.$store.getters["masterResident/unitCountById"];
    },
    unitCount() {
      let unit = 0;
      if(this.unitCountByCustomerId) {
        unit = this.unitCountByCustomerId.unitCount;
      }

      return unit;
    },
    isDebtfreeApproval() {
      let result ="Y";
      if(this.isDisApprove) {
        result = "N";
      }

      return result;
    },
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.roleByMenuDataReady();
    },
    debtFreeApprove() {
      this.checkShouldSetData();
    },
    payload(curValue) {
      if (this.objToCompare) {
        this.compareObject(this.objToCompare, curValue);
      }
    },
  },
};
</script>
