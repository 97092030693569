<template>
  <PvDialog
    :breakpoints="{ '1280px': '50vw', '640px': '80vw' }"
    style="max-width: 720px"
    :modal="true"
    :closable="false"
    :showHeader="false"
    contentStyle="padding: 1rem !important;border-top-right-radius: 6px;border-top-left-radius: 6px;"
  >
    <div>
      <PvGalleria
        :value="images"
        :numVisible="5"
        :circular="true"
        :showItemNavigators="isShowIndicators"
        :showThumbnails="false"
        :showItemNavigatorsOnHover="true"
        :showIndicators="isShowIndicators"
        :autoPlay="isShowIndicators"
        :transitionInterval="10000"
      >
        <template #item="slotProps">
          <PvSkeleton 
            v-show="!isLoadFinish" 
            height="387px"
            width="720px"
          ></PvSkeleton>
          <img
            :src="slotProps.item.imageUrl"
            class="w-full"
            :class="{ 'cursor-pointer': slotProps.item.url != '' }"
            @click="redirectTo(slotProps.item.url)"
            @load="isLoadFinish = true"
            v-show="isLoadFinish"
          />
        </template>
      </PvGalleria>
    </div>

    <template #footer>
      <div class="flex justify-content-between dialog-footer">
        <div class="align-content-center">
          <PvCheckbox
            id="isShowAgainToday"
            inputId="binary"
            v-model="isShowAgainToday"
            :binary="true"
          />
          <label for="isShowAgainToday" class="ml-2">{{
            lang.notShowAgainToday
          }}</label>
        </div>
        <div class="col-3 p-0">
          <PvButton
            :label="lang.close"
            class="p-button-outlined p-button-secondary m-0 w-full"
            @click="closeDialog"
          />
        </div>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import useLang from "../../hooks/lang.js";
import useSetTracking from "../../hooks/setTracking.js";
export default {
  props: ["splashData"],
  emits: ["close"],
  setup(props, context) {
    const store = useStore();
    const { trackingButtonClick } = useSetTracking();
    const { lang } = useLang();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
        };
      } else {
        return {
          ...lang.en.base,
        };
      }
    });

    let isShowAgainToday = ref(false);
    let isLoadFinish = ref(false);

    const images = computed(() => {
      return props.splashData;
    });
    const isShowIndicators = computed(() => {
      return images.value.length > 1;
    });

    const redirectTo = (url) => {
      if (url != "") {
        let payload = {
          name: "splash_dialog_image_click",
          event: { route_to: url }
        }     
        addTracking(payload);
        window.open(url);
      }
    };
    const closeDialog = () => {
      context.emit("close");
      let element = document.getElementsByTagName("body")[0];
      element.style.overflowY = "scroll";

      if(isShowAgainToday.value){
        const dateNow = new Date();
        let storageName = "splash_view_" + userId.value
        localStorage.setItem(storageName, dateNow);        
      }

      let payload = {
        name: "splash_dialog_close_click",
        event: {isNotShowToDay: isShowAgainToday.value}
      }
      addTracking(payload);
    };

    const addTracking = (payload) => {
      trackingButtonClick(payload);
    };

    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });

    const userId = computed(() => {
      return store.getters.userId;
    });

    return {
      lang: mergeLang,

      images,
      isShowAgainToday,
      isShowIndicators,
      isLoadFinish,

      redirectTo,
      closeDialog,
      trackingButtonClick,
      selectedProject,
      userId,
    };
  },
};
</script>

<style scoped>
.dialog-footer {
  padding: 0rem 0.5rem 0rem 0.5rem !important;
}
</style>
