<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoSave="save"
    @gotoCreate="askForCreate"
    @gotoCancel="askForCancel"
    @gotoPrint="getPrintForm"
  />
  
  <base-form-print-modal
    v-model:visible="showFormPrintModal"
    :search="searchTextPrint"
    @close="showFormPrintModal = false"
    @path-selected="formPrintModalSelected"
  ></base-form-print-modal>

  <base-dialog
    v-model:visible="showValidateDialog"
    :titleConfirm="titleConfirm"
    :classTitle="baseDialogClassTitle"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    @close="closeBaseDialog"
    @confirm="confirmBaseDialog"
    @goto-create="createNewDocument"
    @goto-cancel="cancel"
  >
    <template #dialogBody v-if="showCancelDialog">
      <div class="lg:col-12 md:col-12 col-12 p-0 w-full">
        <label for="reasonCancel">{{ lang.cancelReason }}</label>
        <PvInputText
          id="reasonCancel"
          type="text"
          class="inputfield w-full mt-2"
          v-model="reasonCancel"
          :class="{ 'p-invalid': reasonCancelError }"
          @keypress="reasonCancelError = false"
          autofocus
        />
        <small v-if="reasonCancelError" id="reasonCancel-help" class="p-error">
          {{ lang.validateNoCancelReason }}
        </small>
      </div>
    </template>
  </base-dialog>

  <creditNote-pending-dialog
    v-model:visible="showPendingDialog"
    :isOpen="showPendingDialog"
    :unit="selectedUnit"
    :date="date"
    :creditNoteDetails="details"
    @close="showPendingDialog = false"
    @pending-selected="selectPending"
  ></creditNote-pending-dialog>

  <creditNote-detail-dialog
    v-model:visible="showDetailDialog"
    :isOpen="showDetailDialog"
    :data="selectedCreditNoteDetail"
    @close="showDetailDialog = false"
    @confirm-edit="editDetail"
  ></creditNote-detail-dialog>

  <base-container>
    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5">
      <div class="flex justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.creditNoteDetail }}</label>
      </div>

      <PvDivider></PvDivider>

      <section class="flex flex-wrap fontlabel" :class="textFormClass">
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="code">{{ lang.code }}</label>
          <PvInputText
            id="code"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="code"
            disabled
          />
        </div>

        <div class="lg:col-6 md:col-12 col-12 pl-0">
          <label for="date">{{ lang.date }}</label>
          <PvCalendar
            id="date"
            v-model="date"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :inputClass="{'p-inputtext-disabled' : !isCreateDocument}"
            :disabled="!isCreateDocument"
            @date-select="clearDataDetails"
          />
        </div>

        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label>{{ lang.unitCode }}*</label>
          <PvSkeleton
            v-if="!isSharedUnitReady"
            class="inputfield w-full mt-1"
            height="39px"
          />
          <base-unit-auto-complete
            id="unit"
            v-model="selectedUnit"
            class="inputfield w-full mt-1 mr-2"
            @load-finish="unitDataReady"
            @item-select="selectUnit()"
            :inputClass="{ 'p-inputtext-disabled': !isCreateDocument, 'p-invalid': unitError }"
            :disabled="!isCreateDocument"
            v-show="isSharedUnitReady"
            :isValidateActiveStatus="true"
            @is-inactive="initiateData"
          />
          <small v-if="unitError" id="unit-help" class="p-error">
            {{ lang.validateNoUnitSelect }}
          </small>
        </div>

        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="refCode">{{ lang.refCode }}</label>
          <PvInputText
            id="refCode"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="refCode"
            disabled
          />
        </div>

        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="customerName">{{ lang.customerName }}</label>
          <PvInputText
            id="customerName"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model.trim="customerName"
            disabled
          />
        </div>

        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="employeeName">{{ lang.employeeName }}</label>
          <PvInputText
            id="employeeName"
            type="text"
            class="inputfield w-full mt-1 p-inputtext-disabled"
            v-model="employeeName"
            disabled
          />
        </div>
      </section>

      <div class="pt-2 pb-1 pb-4">
        <PvButton
          icon="pi pi-search"
          :label="lang.search + lang.pendingList"
          class="p-button-primary mr-2 mb-2 mt-2"
          @click="openPendingDialog()"
          :disabled="!isSharedUnitReady"
        />
      </div>

      <div v-if="detailError" class="pb-2">
        <PvInlineMessage severity="error" class="w-full justify-content-start">{{ lang.validateDetail}}</PvInlineMessage>
      </div>

      <PvDataTable
        :value="details"
        dataKey="id"
        responsiveLayout="scroll"
        :class="textTableClass"
        v-model:selection="selectedCreditNoteDetail"
        selectionMode="single"
        @row-dblclick="openDetailDialog(this.selectedCreditNoteDetail)"
      >
        <PvColumn
          field="itemNo"
          :header="lang.itemNo"
          style="min-width: 5rem"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="productCode"
          :header="lang.productCode"
          style="min-width: 7rem"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="productName"
          :header="lang.productName"
          style="min-width: 10rem"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="remark"
          :header="lang.description"
          style="min-width: 10rem"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="period"
          :header="lang.period"
          style="min-width: 8rem"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="unitPrice"
          :header="lang.unitPrice"
          style="min-width: 8rem"
          headerClass="h-right"
          bodyClass="text-right"
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.unitPrice) }}
          </template>
        </PvColumn>
        <PvColumn
          field="quantity"
          :header="lang.quantity"
          style="min-width: 8rem"
          headerClass="h-right"
          bodyClass="text-right"
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.quantity) }}
          </template>
        </PvColumn>
        <PvColumn
          field="balance"
          :header="lang.columnBalance"
          style="min-width: 8rem"
          headerClass="h-right"
          bodyClass="text-right"
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.balance) }}
          </template>
        </PvColumn>
        <PvColumn
          field="totalAmount"
          :header="lang.reduceDebt"
          style="min-width: 8rem"
          headerClass="h-right"
          bodyClass="text-right"
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.totalAmount) }}
          </template>
        </PvColumn>

        <PvColumn
          style="min-width: 8rem"
          headerClass="h-center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <PvButton
              type="button"
              class="p-button-secondary p-button-text p-0 mr-2"
              @click="openDetailDialog(slotProps.data)"
            >
              <icon-pen-edit iconColor="secondary" width="24" height="24" />
            </PvButton>
            <PvButton
              type="button"
              class="p-button-danger p-button-text p-0 mr-2"
              @click="deleteRow(slotProps.data.id)"
            >
              <icon-trash-delete-bin
                iconColor="secondary"
                width="24"
                height="24"
              />
            </PvButton>
          </template>
        </PvColumn>

        <template #empty>
          <div class="w-full" style="text-align: center">
            <label>{{ this.lang.tableEmpty }}</label>
          </div>
        </template>
      </PvDataTable>

      <div class="flex flex-wrap pt-4" :class="textFormClass">
        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="reasons">{{ lang.reasons }}*</label>
          <PvInputText
            id="reasons"
            type="text"
            class="inputfield w-full mt-1"
            v-model.trim="reasons"
            :class="{ 'p-invalid': reasonsError,'p-inputtext-disabled': isDocumentLocked }"
            @keydown="reasonsError = false"
            :disabled="isDocumentLocked"
          />
          <small v-if="reasonsError" id="reasons-help" class="p-error">
            {{ lang.validateNoReasons }}
          </small>
        </div>

        <div class="lg:col-6 md:col-12 col-12 pl-0 pr-2">
          <label for="netTotalAmount">{{ lang.totalAmount }}</label>
          <PvInputNumber
            id="netTotalAmount"
            class="inputfield w-full mt-1"
            inputClass="p-inputtext-disabled text-right"
            v-model="netTotalAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            disabled
          />
        </div>

        <div class="col-12 pl-0 pr-2">
          <label for="remark">{{ lang.remark }}</label>
          <PvInputText
            id="remark"
            type="text"
            class="inputfield w-full mt-1"
            :class="{ 'p-inputtext-disabled': isDocumentLocked }"
            v-model.trim="remark"
            :disabled="isDocumentLocked"
          />
        </div>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import usePageDirty from "../../../hooks/pageDirty";
import useGetRoleByBook from "../../../hooks/getRoleByBook.js";
import CreditNotePendingDialog from "../../../components/AR/creditNote/CreditNotePendingDialog.vue";
import CreditNoteDetailDialog from "../../../components/AR/creditNote/CreditNoteDetailDialog";
export default {
  props: ["id", "projectCode"],
  components: {
    CreditNotePendingDialog,
    CreditNoteDetailDialog,
  },
  setup() {
    const { lang } = useLang();
    const { textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.creditNote.listing,
          ...lang.th.ar.creditNote.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.creditNote.listing,
          ...lang.en.ar.creditNote.edit,
        };
      }
    });

    const {
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
    } = useGetFormPrint();
    const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();

    const { compareObject, isCompareObject } = usePageDirty();

    return {
      lang: mergeLang,

      textFormClass,
      textTableClass,

      formatCurrency,
      formatDate,
      formatDateToISOString,

      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,

      compareObject,
      isCompareObject,

      getRoleByBookByCode,
      isSharedRoleByBookReady,
    };
  },
  data() {
    return {
      //data
      code: "",
      date: null,
      refCode: "",
      unit: null,
      selectedUnit: null,
      unitError: false,
      isSharedUnitReady: false,
      customerId: "",
      customerName: "",
      employeeId: "",
      employeeName: "",
      details: [],
      selectedCreditNoteDetail: null,
      reasons: "",
      reasonsError: false,
      remark: "",

      objToCompare: null,
      detailError: false,

      //Dialog
      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: this.lang.notificationDialogHeader,
      validateWording: "",
      isCreateNewDocument: false,
      showPendingDialog: false,
      showDetailDialog: false,
      showCancelDialog: false,
      reasonCancel: "",
      reasonCancelError: false,

      //form
      showFormPrintModal: false,
      path: "",
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();

    if (!this.isCreateDocument) {
      await this.getCreditNote();
      // console.log("update", this.creditNote);
    }
    await this.checkShouldSetData(); 
  },
  methods: {
    // async checkRoleReady() {
    //   let i = true;
    //   while (i) {
    //     if(this.isSharedRoleByBookReady) { 
    //       console.log(this.isSharedRoleByBookReady);
    //       await this.getCreditNote();
    //       i = false; 
    //     }
    //   }
    // },
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.setLocalSearch();
      this.$router.push({name: "ar-credit-note"});
    },
    async initiateData(unSelectUnit = true) {
      await this.$store.dispatch("arCreditNote/resetCreditNote");
      if (unSelectUnit) {
        this.selectedUnit = null;
        this.date = new Date();
      }
      this.code = "";
      this.refCode = "";
      this.unitError = false;
      this.customerId = "";
      this.customerName = "";
      this.employeeId = this.selectedEmployee.id;
      this.employeeName = this.selectedEmployee.name;
      this.details = [];
      this.selectedCreditNoteDetail = null;
      this.reasons = "";
      this.reasonsError = false;
      this.remark = "";

      this.showValidateDialog = false;
      this.clearBaseDialog();
      this.showPendingDialog = false;
      this.showDetailDialog = false;
      this.showCancelDialog = false;
      this.reasonCancel = "";
      this.reasonCancelError = false;
      this.detailError = false;

      //form
      this.showFormPrintModal = false;
      this.path = "";
    },
    setLocalSearch() {
      let storageName = `erp_search_CreditNoteList_${this.selectedProject.code}_${this.userCode}`;
      const localSearch = localStorage.getItem(storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      // console.log("search=",search);
      localStorage.setItem(storageName, JSON.stringify(search));
    },
    async roleByBookDataReady() {
      if (this.isSharedRoleByBookReady) {
        if (!this.isCreateDocument) {
          await this.getCreditNote();
          // console.log("update", this.creditNote);
        }
      }
    },
    async getCreditNote() {
      try {
        await this.$store.dispatch("arCreditNote/getCreditNote", this.id);
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    confirmBaseDialog() {
      this.closeBaseDialog();
    },
    closeBaseDialog() {
      this.showValidateDialog = false;
      this.clearBaseDialog();
    },
    clearBaseDialog() {
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.validateWording = "";

      this.showCancelDialog = false;
      this.reasonCancel = "";
      this.reasonCancelError = false;

      this.isCreateNewDocument = false;
    },
    async askForCreate() {
      if (!this.isRoleCreate) {
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return
      }
      let result = this.isCompareObject(this.objToCompare, this.payload);
      this.clearBaseDialog();
      this.isCreateNewDocument = true;
      if (result) {
        this.titleConfirm = this.lang.createNewCreditNote;
        this.validateWording = [
          this.lang.askForCreate,
          this.lang.askForCreateConfirm,
        ];
        this.showValidateDialog = true;
      } else {
        await this.createNewDocument();
      }
    },
    async createNewDocument() {
      this.showValidateDialog = false;
      this.setLoading(true);
      await this.initiateData();

      await this.$router.push({name: "ar-credit-note-edit", params: {id: "create"}});
      if (this.isSharedUnitReady) {
        this.setLoading(false);
      }

      await this.setCompareData();
    },
    async checkShouldSetData() {
      if(this.isSharedRoleByBookReady) {
        // console.log(this.creditNote);
        if (this.isSharedUnitReady && this.creditNote) {
          // console.log("checkShouldSetData 1");
          await this.setDataUnit();
          await this.setData();
        } else if (!this.isSharedUnitReady && this.creditNote) {
          // console.log("checkShouldSetData 2");
          await this.setData();
        } else if (this.isSharedUnitReady && this.isCreateDocument) {
          // console.log("checkShouldSetData 3");
          this.setLoading(false);
        }
      }
      await this.setCompareData();
    },
    async setData() {
      this.code = this.creditNote.code;
      this.date = new Date(this.creditNote.date);
      this.refCode = this.creditNote.refCode;
      this.customerId = this.creditNote.customerId;
      this.customerName = this.creditNote.customerName;
      this.details = this.creditNote.creditNoteDetails.sort(function (a, b) {
        return a.itemNo - b.itemNo;
      });
      this.employeeId = this.creditNote.employeeId;
      this.employeeName = this.creditNote.employeeName;
      this.reasons = this.creditNote.reasons;
      this.remark = this.creditNote.remark;

      this.setLoading(false);
    },
    async setDataUnit() {
      this.selectedUnit = this.sharedUnits.find(
        (unit) => unit.id === this.creditNote.unitId
      );
    },
    async unitDataReady() {
      this.isSharedUnitReady = true;
      this.checkShouldSetData();
    },
    async selectUnit() {
      // console.log(this.selectedUnit);
      await this.initiateData(false);

      // this.details = [];
      this.customerId = this.selectedUnit.customerId;
      this.customerName = this.selectedUnit.customerName;
      this.customerMobile = this.selectedUnit.customerMobilePhone;
    },
    validateUnit() {
      if (this.isSharedUnitReady && !this.selectedUnit) {
        this.validateWording = this.lang.validateNoUnitSelect;
        this.showValidateDialog = true;
        this.unitError = true;
        return true;
      }
      // console.log(this.selectedUnit);
      // if (this.selectedUnit.isActive == "N") {
      //   this.validateWording = this.lang.validateInactiveUnitSelect;
      //   this.showValidateDialog = true;
      //   if (this.isCreateDocument) {
      //     this.selectedUnit = null;
      //     this.unitError = true;
      //   }
      //   return true;
      // }

      return false;
    },
    openPendingDialog() {
      if (this.validateUnit()) {
        return;
      }

      if (this.validateDocument()) {
        return;
      }
      // this.setLoading(true);

      this.showPendingDialog = true;
    },
    selectPending(data) {
      this.showPendingDialog = false;
      // console.log('edit',data);
      if (data) {
        data.forEach((invoice) => {
          let number = this.countDetails;
          this.refCode =
            "Bill No: " + invoice.documentBookCode + " /" + invoice.code;
          let creditNoteDetail = null;
          creditNoteDetail = this.details.find(
            (detail) => detail.referenceId === invoice.detailId
          );

          if (creditNoteDetail == null) {
            let detail = {
              id: "dummy" + (number + 1),
              itemNo: number + 1,
              headerId: this.id,
              productId: invoice.productId,
              productCode: invoice.productCode,
              productName: invoice.productName,
              productUnitId: invoice.productUnitId,
              productUnitCode: invoice.productUnitCode,
              productUnitName: invoice.productUnitName,
              quantity: invoice.quantity,
              unitPrice: invoice.unitPrice,
              totalAmount: invoice.pendingAmount - invoice.discountAmount,
              balance: invoice.pendingAmount - invoice.discountAmount,
              remark: invoice.detailRemark,
              referenceId: invoice.detailId,
              referenceType: "AMT",
              period: invoice.period,
            };

            this.details.push(detail);
          } else {
            creditNoteDetail.totalAmount =
              invoice.pendingAmount - invoice.discountAmount;
            creditNoteDetail.balance =
              invoice.pendingAmount - invoice.discountAmount;
          }
        });
        this.detailError = false;
      }
    },
    openDetailDialog(data) {
      if (this.validateDocument()) {
        return;
      }

      this.selectedCreditNoteDetail = data;

      this.showDetailDialog = true;
    },
    deleteRow(id) {
      if (this.validateDocument()) {
        return;
      }

      const index = this.details.findIndex((detail) => detail.id === id);
      this.details.splice(index, 1);

      this.updateItemNumber();
    },
    updateItemNumber() {
      let number = 1;
      this.details.forEach((element) => {
        if (element.id) {
          element.itemNo = number;
        }
        number++;
      });
    },
    editDetail(data) {
      // console.log('editDetail',data);
      let detail = this.details.find((detail) => detail.id == data.id);
      detail.totalAmount = data.totalAmount;
    },
    async askForCancel() {
      this.clearBaseDialog();
      if (!this.isRoleCancel) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleCancel;
        this.showValidateDialog = true;
        return
      } 
      else if (this.validateDocument(false)) {
        return;
      }
      this.showCancelDialog = true;
      this.showValidateDialog = true;
    },
    async cancel() {
      if (this.reasonCancel.trim() == "") {
        this.reasonCancelError = true;
        return;
      } else {
        this.showValidateDialog = false;
      }

      this.setLoading(true);
      try {
        let payload = {
          id: this.id,
          cancelReasons: this.reasonCancel,
        };

        // console.log(payload);
        await this.$store.dispatch("arCreditNote/cancelCreditNote", payload);

        this.showCancelDialog = false;
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.cancelSuccess;
        this.validateWording = `${this.lang.cancelCreditNoteSuccess.replace(
          ":code",
          this.code.trim()
        )}`;
        this.showValidateDialog = true;
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
      }
      this.setLoading(false);
    },
    validateData() {
      let returnStatus = false;

      if (this.validateDocument()) {
        return true;
      }

      if (this.isSharedUnitReady && !this.selectedUnit) {
        this.unitError = true;
        returnStatus = true;
      }

      if (this.countDetails <= 0) {
        this.detailError = true;
        returnStatus = true;
      }

      if (this.netTotalAmount <= 0) {
        returnStatus = true;
      }

      if (!this.reasons) {
        this.reasonsError = true;
        returnStatus = true;
      }

      if (returnStatus) {
        this.clearBaseDialog();
        this.baseDialogClassTitle = "p-text-error";
        this.titleConfirm = this.lang.validateSaveTitle;
        this.validateWording = this.wordingValidate();
        this.showValidateDialog = true;
      }

      return returnStatus;
    },
    wordingValidate(){
      if (!this.selectedUnit) {
        return this.lang.validateDropDownNoSelect + this.lang.unitCode;
      }

      if (this.countDetails <= 0) {
        return this.lang.validateDetail;
      }

      if (this.netTotalAmount <= 0) {
        return this.lang.validateNetTotalAmount;
      }

      if (!this.reasons) {
        return this.lang.validateFieldNoValue + this.lang.reasons;
      }
    },
    validateRole() {
      if (this.isCreateDocument && !this.isRoleCreate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return true;
      } else if (!this.isCreateDocument && !this.isRoleUpdate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = this.lang.validateRoleUpdate;
        this.showValidateDialog = true;
        return true;
      }
    },
    async save() {
      this.clearBaseDialog();
      
      if (this.validateRole()) {
        return;
      }

      if (this.validateData()) {
        return;
      }

      this.setLoading(true);
      try {
        // console.log("this.payload", this.payload);
        await this.$store.dispatch(
          "arCreditNote/createOrUpdateCreditNote",
          this.payload
        );

        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.saveSuccess;
        this.validateWording = this.saveEditSuccess;
        this.showValidateDialog = true;
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        if (error.code == "103") {
          this.validateWording = error.message;
        } else if (error.code == "105") {
          this.validateWording = this.lang.validateCreditNoteAmount;
        } else {
          this.validateWording = error.message;
        }
        this.showValidateDialog = true;
      }
      this.setLoading(false);

      if (this.isCreateDocument) {
        await this.$router.push({name: "ar-credit-note-edit", params: {id: this.creditNote.id}});
        await this.setCompareData();
      }
    },
    async getPrintForm() {
      if (!this.isRolePrint) {
        this.validateWording = this.lang.validatePrintPermission;
        this.showValidateDialog = true;
        return
      }

      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: "INVCN",
      };
      const payload = { where };
      await this.getFormPrints(payload);
      // console.log(this.filteredFormPrints);
      if (this.filteredFormPrints.length == 1) {
        this.formPrintModalSelected(this.filteredFormPrints[0]);
      } else {
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path + ".pdf";

      this.printCreditNote();

      this.showFormPrintModal = false;
    },
    async printCreditNote() {
      this.setLoading(true);
      try {
        let parameter = {
          Id: this.id,
        };

        let payload = {
          id: this.id,
          path: this.path,
          fileName: "CreditNote",
          parameter: JSON.stringify(parameter),
        };
        // console.log("print", payload);
        await this.$store.dispatch("arCreditNote/printCreditNote", payload);

        await this.getPreviewBlob(this.fileBlob);
        await this.getCreditNote();
      } catch (error) {
        this.validateWording = error;
        this.showValidateDialog = true;
      }

      this.setLoading(false);
    },
    async setCompareData() {
      let details = [];

      for (const each of this.details.filter((each) => each.productId)) {
        let detail = null;
        if (each.id.includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        details.push(detail);
      }
      let objToCompare = {
        projectId: this.selectedProject.id,
        unitId: this.selectedUnit ? this.selectedUnit.id : "",
        customerId: this.customerId,
        employeeId: this.employeeId,
        refCode: this.refCode,
        date: this.date ? this.formatDateToISOString(this.date) : null,
        reasons: this.reasons,
        remark: this.remark,
        details: details,
        type: "Add",
      };

      if (!this.isCreateDocument) {
        objToCompare = {
          ...objToCompare,
          id: this.id,
          type: "Update",
        };
      }

      this.objToCompare = objToCompare;
      this.compareObject(this.objToCompare, this.payload);
      // console.log('this.objToCompare',this.objToCompare);
      // console.log('this.payload',this.payload);
      // console.log(this.isPageDirty);
    },
    clearDataDetails() {
      this.details = [];
      this.refCode = "";
    },
    validateDocument(isCheck = true) {
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.notificationDialogHeader;
      if (this.creditNote && this.creditNote.cancelStatus.trim().toLowerCase() === "c") {
        this.validateWording = this.lang.validateCancelled;
        this.showValidateDialog = true;
        return true;
      }
      // ERP-5563 ใบลดหนี้ไม่ต้องตรวจสอบ Paid Status และไม่ควรมีระบบใดๆ ที่ส่งผลกับ Paid Status ในใบลดหนี้
      // if (this.creditNote && this.creditNote.paidStatus.trim()) {
      //   this.validateWording = this.lang.validateReferenceDocument;
      //   this.showValidateDialog = true;
      //   return true;
      // }
      if (isCheck && this.creditNote && this.creditNote.postStep.trim()) {
        this.validateWording = this.lang.validatePrinted;
        this.showValidateDialog = true;
        return true;
      }
      if (this.creditNote && this.creditNote.postStep.trim().toLowerCase() === "a") {
        this.validateWording = this.lang.validateReferenceVoucher;
        this.showValidateDialog = true;
        return true;
      }
    },
  },
  computed: {
    payload() {
      let details = [];

      for (const each of this.details.filter((each) => each.productId)) {
        let detail = null;
        if (each.id.includes("dummy")) {
          detail = { ...each, id: null };
        } else {
          detail = { ...each };
        }
        details.push(detail);
      }
      let payload = {
        projectId: this.selectedProject.id,
        unitId: this.selectedUnit ? this.selectedUnit.id : "",
        customerId: this.customerId,
        employeeId: this.employeeId,
        refCode: this.refCode,
        date: this.date ? this.formatDateToISOString(this.date) : null,
        reasons: this.reasons,
        remark: this.remark,
        details: details,
        type: "Add",
      };

      if (!this.isCreateDocument) {
        payload = {
          ...payload,
          id: this.id,
          type: "Update",
        };
      }

      return payload;
    },
    isCreateDocument() {
      return this.id === "create";
    },
    title() {
      let result = this.lang.createCreditNote;
      if (!this.isCreateDocument) {
        result = this.lang.editCreditNote;
      }
      return result;
    },
    dataButtons() {
      let result = [
        {
          id: "cancelCaption",
          action: "gotoCancel",
          type: "cancel",
          isDisable: this.isCreateDocument,
        },
        {
          id: "createCaption",
          action: "gotoCreate",
          type: "create",
        },
        {
          id: "printCaption",
          action: "gotoPrint",
          type: "print",
          isDisable: this.isCreateDocument,
        },
        {
          id: "saveCaption",
          action: "gotoSave",
          type: "save",
          isDisable: !this.isSharedUnitReady,
        },
      ];

      return result;
    },
    roleByDocumentBook() {
      return this.getRoleByBookByCode("INVCN");
    },
    isRoleCreate() {
      let result = false;
      if (this.roleByDocumentBook && this.roleByDocumentBook.insert == "Y") {
        result = true;
      }
      return result;
    },
    isRoleUpdate() {
      let result = false;
      if (this.roleByDocumentBook && this.roleByDocumentBook.update == "Y") {
        result = true;
      }
      return result;
    },
    isRoleCancel() {
      let result = false;
      if (this.roleByDocumentBook && this.roleByDocumentBook.cancel == "Y") {
        result = true;
      }
      return result;
    },
    isRolePrint() {
      let result = false;
      if (this.roleByDocumentBook && this.roleByDocumentBook.print == "Y") {
        result = true;
      }
      return result;
    },
    isDocumentLocked() {
      if (this.creditNote) {
        if (
          this.creditNote.postStep.trim() ||
          this.creditNote.cancelStatus.trim().toLowerCase() === "c"
        ) {
          return true;
        }
      }
      return false;
    },
    isSelectedUnit() {
      if (this.isSharedUnitReady && this.selectedUnit) {
        return true;
      }
      return false;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    selectedEmployee() {
      return this.$store.getters.employee;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    creditNote() {
      return this.$store.getters["arCreditNote/selectedCreditNote"];
    },
    sharedUnits() {
      return this.$store.getters["shared/unitUnits"];
    },
    countDetails() {
      let length = this.details.length;
      return length;
    },
    dataButtonDialog() {
      let result = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];

      if (this.isCreateNewDocument) {
        result = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "gotoCreate",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-create",
          },
        ];
      } else if (this.showCancelDialog) {
        result = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "confirmCancel",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-cancel",
          },
        ];
      }

      return result;
    },
    netTotalAmount() {
      let netAmount = 0;
      if (this.details) {
        this.details.forEach((detail) => {
          netAmount += detail.totalAmount;
        });
      }
      return netAmount;
    },
    fileBlob() {
      return this.$store.getters["arCreditNote/blob"];
    },
    saveEditSuccess() {
      let result = this.lang.editCreditNoteSuccess;
      if (this.isCreateDocument) {
        result = this.lang.createCreditNoteSuccess;
      }
      return result;
    },
  },
  watch: {
    isSharedRoleByBookReady() {
      // this.roleByBookDataReady();
      this.checkShouldSetData(); 
    },
    creditNote() {
      this.checkShouldSetData(); 
    },
    payload(curValue) {
      if (this.objToCompare) {
        this.compareObject(this.objToCompare, curValue);
      }
    },
  },
};
</script>
