<template>
  <base-toolbar
    :title="title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoCreate="askForCreate"
    @gotoSave="save"
    @gotoDelete="askForDelete"
  ></base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :classTitle="baseDialogClassTitle"
      :titleConfirm="titleConfirm"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
      @goto-create="createNew"
      @goto-delete="deleteData"
      @back="goBack"
    ></base-dialog>

    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{ lang.titleEdit }}</label>

      <PvDivider></PvDivider>

      <section class="flex flex-wrap fontlabel" :class="textFormClass">
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pb-3">
          <label for="isParentTitle">{{ lang.isParentTitle }}</label>
          <div class="flex pt-2">
            <div
              v-for="isParent of parents"
              :key="isParent.code"
              class="field-radiobutton pt-2 mr-6 mb-0"
            >
              <PvRadioButton
                class="mr-2"
                :inputId="isParent.code"
                name="isParent"
                :value="isParent.code"
                v-model="selectedIsParent"
                @change="changeIsParent"
              />
              <label :for="isParent.code" class="ml-0">{{
                isParent.name
              }}</label>
            </div>
          </div>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="accountCategory">{{ lang.accountCategory }}*</label>
          <!-- <PvSkeleton
            v-if="!isAccountCategoryReady"
            class="inputfield w-full mt-1"
            height="39px"
          />
          <account-category-auto-complete
            id="id"
            v-model="selectedAccountCategory"
            class="inputfield w-full mt-1"
            @load-finish="accountCategoryDataReady"
            :placeholder="lang.selectAccountCategory"
            v-show="isAccountCategoryReady"
            :class="{ 'p-invalid': accountCategoryError }"
            @item-select="accountCategoryError = false"
          /> -->
          <PvDropdown
            id="accountCategory"
            class="inputfield w-full mt-1"
            :options="accountCategory"
            optionLabel="name"
            optionValue="code"
            v-model="selectedAccountCategory"
            scrollHeight="300px"
            :class="{ 'p-invalid': accountCategoryError }"
            @change="changeAccountCategory"
          />
          <small v-if="accountCategoryError" id="accountCategory-help" class="p-error">
            {{ lang.validateAccountCategory }}
          </small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="accountParent">{{ titleAccountParent }}</label>
          <PvSkeleton
            v-if="!isSharedAccountReady"
            class="inputfield w-full mt-1"
            height="39px"
          />
          <base-accounting-parent-auto-complete
            id="id"
            v-model="selectedAccountParent"
            class="inputfield w-full mt-1"
            @load-finish="accountDataReady"
            :placeholder="lang.selectAccountParent"
            v-show="isSharedAccountReady"
            :inputClass="{ 'p-inputtext-disabled': !selectedAccountCategory }"
            :category="category"
            :disabled="!selectedAccountCategory"
            :class="{ 'p-invalid': accountParentError }"
            @item-select="accountParentError = false"
          />
          <small v-if="accountParentError" id="accountParent-help" class="p-error">
            {{ lang.validateAccountParenty }}
          </small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="accountCode">{{ lang.accountCode }}*</label>
          <PvInputText
            id="accountCode"
            type="text"
            class="inputfield w-full mt-1"
            v-model="accountCode"
            :class="{ 'p-invalid': accountCodeError }"
            @keypress="accountCodeError = false"
          />
          <small v-if="accountCodeError" id="accountCode-help" class="p-error">
            {{ lang.validateAccountCode }}
          </small>
        </div>
        <div class="col-6"></div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="accountNameTh">{{ lang.accountNameTh }}*</label>
          <PvInputText
            id="accountNameTh"
            type="text"
            class="inputfield w-full mt-1"
            v-model="accountNameTh"
            :class="{ 'p-invalid': accountNameThError }"
            @keypress="accountNameThError = false"
          />
          <small v-if="accountNameThError" id="accountNameTh-help" class="p-error">
            {{ lang.validateAccountNameTh }}
          </small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="accountNameEn">{{ lang.accountNameEn }}</label>
          <PvInputText
            id="accountNameEn"
            type="text"
            class="inputfield w-full mt-1"
            v-model="accountNameEn"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="activeStatus">{{ lang.activeStatus }}</label>
          <div class="flex align-items-center pt-3">
            <PvInputSwitch v-model="selectedActiveStatus" class="mr-3" />
            <label for="titleActiveStatus">{{ titleActiveStatus }}</label>
          </div>
        </div>
      </section>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import usePageDirty from "../../../hooks/pageDirty";
import useGetAccountingDetails from "../../../hooks/getAccountingDetail.js";
// import AccountCategoryAutoComplete from "../../../components/master/accountChart/AccountCategoryAutoComplete.vue";
export default {
  props: ["id", "projectCode"],
  // components: {
  //   AccountCategoryAutoComplete,
  // },
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.accountChart.edit,
          ...lang.th.master.accountChart.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.accountChart.edit,
          ...lang.th.master.accountChart.listing,
        };
      }
    });

    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    const { compareObject, isCompareObject } = usePageDirty();

    const { refreshAccountingDetails } = useGetAccountingDetails();

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      compareObject,
      isCompareObject,
      refreshAccountingDetails
    };
  },
  data() {
    return {
      //Dialog
      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: "",
      validateWording: "",

      parents: [
        { name: this.lang.isParent, code: "Y" },
        { name: this.lang.unParent, code: "N" },
      ],
      selectedIsParent: "Y",
      isAccountCategoryReady: false,
      isSharedAccountReady: false,
      selectedAccountCategory: null,
      selectedAccountParent: null,
      accountCode: "",
      accountNameTh: "",
      accountNameEn: "",
      selectedActiveStatus: true,

      //error
      accountCategoryError: false,
      accountParentError: false,
      accountCodeError: false,
      accountNameThError: false,

      isCreateNew: false,
      isDelete: false,
      isDeleteSuccess: false,

      accountCategory: [
        { name: this.lang.accountCategory1, code: "1" },
        { name: this.lang.accountCategory2, code: "2" },
        { name: this.lang.accountCategory3, code: "3" },
        { name: this.lang.accountCategory4, code: "4" },
        { name: this.lang.accountCategory5, code: "5" },
      ],
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
    await this.roleByMenuDataReady();
    await this.checkShouldSetData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.setLocalSearch();
      this.setCompareObject();
      this.$router.push({name: "account-chart"});
    },
    setLocalSearch() {
      let storageName = `erp_search_AccountChartList_${this.selectedProject.code}_${this.userCode}`;
      const localSearch = localStorage.getItem(storageName);
      let search = JSON.parse(localSearch);
      search = {
        ...search,
        entry: "entry",
      };
      localStorage.setItem(storageName, JSON.stringify(search));
    },
    async initiateData() {
      this.selectedIsParent = "Y";
      this.selectedAccountCategory = null;
      this.selectedAccountParent = null;
      this.accountCode = "";
      this.accountNameTh = "";
      this.accountNameEn = "";
      this.selectedActiveStatus = true;
      this.accountCategoryError = false;
      this.accountCodeError = false;
      this.accountNameThError = false;
      this.accountParentError = false;
      await this.$store.dispatch("masterAccountChart/resetSelectedAccountChart");
    },
    clearBaseDialog() {
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.validateWording = "";
      this.isCreateNew = false;
      this.isDelete = false;
      this.isDeleteSuccess = false;
    },
    accountDataReady() {
      this.isSharedAccountReady = true;
    },
    accountCategoryDataReady() {
      this.isAccountCategoryReady = true;
    },
    async roleByMenuDataReady() {
      if(this.isSharedRoleByMenuReady) {
        if(!this.isCreateDocument) {
          await this.getAccountChartById();
        }
      }
    },
    async checkShouldSetData() {
      if(this.isSharedRoleByMenuReady) {
        // if(this.isSharedAccountReady && this.isAccountCategoryReady && this.accountChart) {
        if(this.isSharedAccountReady && this.accountChart) {
          await this.setData();
        } else if(this.isSharedAccountReady && this.isCreateDocument) {
          this.setLoading(false);
        }
        await this.setCompareData();
      }
    },
    async getAccountChartById() {
      try {
        await this.$store.dispatch("masterAccountChart/getAccountChartById", this.id);

        // console.log("getAccountChartById", this.accountChart);

      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
    async setData() {
      this.selectedIsParent = this.accountChart.detailStatus == "Y" ? "N" : "Y";
      // this.selectedAccountCategory = this.accountCategory.find((data) => data.type == this.accountChart.type);
      this.selectedAccountCategory = this.accountChart.type;
      this.selectedAccountParent = this.sharedAccountParent.find((data) => data.id == this.accountChart.parentId);
      this.accountCode = this.accountChart.code;
      this.accountNameTh = this.accountChart.nameTh;
      this.accountNameEn = this.accountChart.nameEn;
      this.selectedActiveStatus = this.accountChart.activeStatus == "Y" ? true : false;
      this.setLoading(false);
    },
    async setCompareData() {
      let objToCompare = {
        projectId: this.selectedProject.id,
        code: this.accountCode.trim(),
        nameTh: this.accountNameTh ? this.accountNameTh.trim() : "",
        nameEn: this.accountNameEn ? this.accountNameEn.trim() : "",
        activeStatus: this.selectedActiveStatus ? "Y" : "N",
        detailStatus: this.selectedIsParent == "N" ? "Y" : "N",
        parentId: this.selectedAccountParent ? this.selectedAccountParent.id : null,
        accountCategory: this.selectedAccountCategory ? this.selectedAccountCategory : null
      }

      if (!this.isCreateDocument) {
        objToCompare = {
          ...objToCompare,
          id: this.id,
        };
      }

      this.objToCompare = objToCompare;
      this.setCompareObject();
    },
    setCompareObject() {
      if (this.isCreateDocument) {
        this.compareObject(this.objToCompare, this.payload);
      } else {
        let payload = {
          ...this.payload,
          id: this.id
        }
        this.compareObject(this.objToCompare, payload);
      }
    },
    async askForCreate() {
      this.clearBaseDialog();
      if(!this.isRoleCreate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return;
      }

      let payload = this.payload;
      if (!this.isCreateDocument) {
        payload = {
          ...this.payload,
          id: this.id
        }
      }
      let result = this.isCompareObject(this.objToCompare, payload);
      this.isCreateNew = true;
      if (result) {
        this.titleConfirm = this.lang.createNewAccountChart;
        this.validateWording = this.lang.askForCreateNewAccountChart;
        this.showValidateDialog = true;
      } else {
        await this.createNew();
      }

    },
    async createNew() {
      this.setLoading(true);
      await this.initiateData();
      await this.$router.push({name: "account-chart-edit", params:{ id: "create" }});
      await this.setCompareData();
      this.showValidateDialog = false; 
      this.setLoading(false);
    },
    validateData() {
      let returnStatus = false;
      this.clearBaseDialog();
      if(this.isCreateDocument && !this.isRoleCreate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return true;
      } else if(!this.isCreateDocument && !this.isRoleUpdate) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateRoleUpdate;
        this.showValidateDialog = true;
        return true;
      }

      if(!this.selectedAccountCategory) {
        this.accountCategoryError = true;
        returnStatus = true;
      }

      if(this.selectedIsParent == "N" && !this.selectedAccountParent) {
        this.accountParentError = true;
        returnStatus = true;
      }

      if(!this.accountCode) {
        this.accountCodeError = true;
        returnStatus = true;
      }

      if(!this.accountNameTh) {
        this.accountNameThError = true;
        returnStatus = true;
      }

      if (returnStatus) {
        this.baseDialogClassTitle = "p-text-error";
        this.titleConfirm = this.lang.validateSaveTitle;
        this.validateWording = this.wordingValidate();
        this.showValidateDialog = true;
      }

      return returnStatus;
    },
    wordingValidate(){
      if (!this.selectedAccountCategory) {
        return this.lang.validateDropDownNoSelect + this.lang.accountCategory;
      }

      if (this.selectedIsParent == "N" && !this.selectedAccountParent) {
        return this.lang.validateDropDownNoSelect + this.lang.accountParent;
      }

      if (!this.accountCode) {
        return this.lang.validateFieldNoValue + this.lang.accountCode;
      }

      if (!this.accountNameTh) {
        return this.lang.validateFieldNoValue + this.lang.accountNameTh;
      }
    },
    async save() {
      if (this.validateData()) {
        return;
      }
      this.clearBaseDialog();
      this.setLoading(true);
      try {
        if(this.isCreateDocument) {
          await this.addData();
        } else {
          await this.updateData();
        }

        this.refreshAccountingDetails();

        // console.log("saved", this.accountChart);

        if(this.accountChart.code == "104") {
          this.baseDialogClassTitle = "";
          this.titleConfirm = this.lang.notificationDialogHeader
          this.validateWording = this.lang.duplicateCode.replace(":code",this.accountCode.trim());
          this.showValidateDialog = true;
          this.setLoading(false);
          return;
        }

        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.saveSuccess;
        this.validateWording = this.lang.accountCode + " " + this.accountChart.data.code;
        this.showValidateDialog = true;
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error.message;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);

      if (this.isCreateDocument) {
        await this.$router.replace({name: "account-chart-edit", params:{ id: this.accountChart.data.id }});
      }
      await this.setCompareData();
    },
    async addData() {
      // console.log("addData", this.payload);
      await this.$store.dispatch("masterAccountChart/createAccountChart", this.payload);
    },
    async updateData() {
      let payload = {
        ...this.payload,
        id: this.id,
      };
      // console.log("updateData", payload);
      await this.$store.dispatch("masterAccountChart/updateAccountChart", payload);
    },
    askForDelete() {
      this.clearBaseDialog();
      if(!this.isCreateDocument && !this.isRoleDelete) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader
        this.validateWording = this.lang.validateRoleDelete;
        this.showValidateDialog = true;
        return;
      }
      this.isDelete = true;
      this.titleConfirm = this.lang.deleteAccountChartTitle;
      this.validateWording = this.lang.askForAccountChart.replace(":code", this.accountCode);
      this.showValidateDialog = true;
    },
    async deleteData() {
      this.setLoading(true);
      try {
        await this.$store.dispatch("masterAccountChart/deleteAccountChart", this.id);
        // console.log("deleted", this.accountChart);
        this.isDelete = false;
        await this.validateAfterDelete();
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error.message;
        this.showValidateDialog = true;
        this.setLoading(false);
      }

      if(this.accountChart.data == true) {
        this.isDeleteSuccess = true;
        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.deleteAccountChartSuccessTitle;
        this.validateWording = this.lang.deleteAccountChartSuccess;
        this.showValidateDialog = true;
      }
      this.setLoading(false);
    },
    async validateAfterDelete() {
      let returnStatus = false;
      if(this.accountChart.code == "130") {
        this.validateWording = this.lang.validateIsParent;
        returnStatus = true;
      }
      if(this.accountChart.code == "131") {
        this.validateWording = this.lang.validateHasVoucher;
        returnStatus = true;
      }
      if(this.accountChart.code == "132") {
        this.validateWording = this.lang.validateHasAccountingFrequently;
        returnStatus = true;
      }
      if(this.accountChart.code == "133") {
        this.validateWording = this.lang.validateHasAccountingPolicy;
        returnStatus = true;
      }
      if(this.accountChart.code == "134") {
        this.validateWording = this.lang.validateHasBookBank;
        returnStatus = true;
      }
      if(this.accountChart.code == "135") {
        this.validateWording = this.lang.validateHasAsset;
        returnStatus = true;
      }
      if(this.accountChart.code == "136") {
        this.validateWording = this.lang.validateHasProduct;
        returnStatus = true;
      }
      if(this.accountChart.code == "137") {
        this.validateWording = this.lang.validateHasBudget;
        returnStatus = true;
      }

      if(returnStatus) {
        this.baseDialogClassTitle = "p-error";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.showValidateDialog = true;
        return;
      }
    },
    changeAccountCategory() {
      this.accountCategoryError = false;
      this.selectedAccountParent = null;
    },
    changeIsParent() {
      if(this.selectedIsParent == "Y") {
        this.accountParentError = false;
      }
    },
  },
  computed: {
    payload() {
      let payload = {
        projectId: this.selectedProject.id,
        code: this.accountCode.trim(),
        nameTh: this.accountNameTh ? this.accountNameTh.trim() : "",
        nameEn: this.accountNameEn ? this.accountNameEn.trim() : "",
        activeStatus: this.selectedActiveStatus ? "Y" : "N",
        detailStatus: this.selectedIsParent == "N" ? "Y" : "N",
        parentId: this.selectedAccountParent ? this.selectedAccountParent.id : null,
        accountCategory: this.selectedAccountCategory ? this.selectedAccountCategory : null
      }
      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmACC");
    },
    isRoleCreate() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      }
      return false;
    },
    isRoleUpdate() {
      if (this.roleByMenu && this.roleByMenu.update == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRoleDelete() {
      if (this.roleByMenu && this.roleByMenu.delete == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isCreateDocument() {
      return this.id === "create";
    },
    title() {
      let result = this.lang.createAccountChart;
      if (!this.isCreateDocument) {
        result = this.lang.editAccountChart;
      }
      return result;
    },
    titleActiveStatus() {
      let result = this.lang.active;
      if (!this.selectedActiveStatus) {
        result = this.lang.inActive;
      }
      return result;
    },
    category() {
      let result = "";
      if (this.selectedAccountCategory) {
        result = this.selectedAccountCategory;
      }
      return result;
    },
    titleAccountParent() {
      let result = this.lang.accountParent;
      if (this.selectedIsParent == "N") {
        result = result + "*";
      }
      return result;
    },
    // accountCategory() {
    //   return this.$store.getters["masterAccountChart/accountCategory"];
    // },
    sharedAccountParent() {
      return this.$store.getters["shared/accountingParentAccounts"];
    },
    accountChart() {
      return this.$store.getters["masterAccountChart/selectedAccountChart"];
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "delete",
          action: "gotoDelete",
          isDisable: this.isCreateDocument || !this.isSharedAccountReady,
          type: "delete"
        },
        {
          id: "create",
          action: "gotoCreate",
          type: "create"
        },
        {
          id: "save",
          action: "gotoSave",
          type: "save",
          isDisable: !this.isSharedAccountReady,
        },
      ];
      return dataButtons;
    },
    dataButtonDialog() {
      let dataButtonDialog = [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ];

      if(this.isCreateNew) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "gotoCreate",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-create",
          },
        ]
      } else if(this.isDelete) {
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "goto-delete",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "goto-delete",
          },
        ]
      } else if(this.isDeleteSuccess) {
        // console.log("isDeleteSuccess", this.isDeleteSuccess);
        dataButtonDialog = [
          {
            id: "close",
            caption: this.lang.close,
            class: "p-button-outlined p-button-secondary w-full",
            action: "back",
          },
        ]
      }
      return dataButtonDialog;
    },
  },
  watch: {
    isSharedAccountReady() {
      this.checkShouldSetData();
    },
    isSharedRoleByMenuReady() {
      this.roleByMenuDataReady();
    },
  }
};
</script>
