export default {
	meterUsageSettingLists(state) {
		return state.meterUsageSettingLists;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	meterUsageSettingType(state) {
		return state.meterUsageSettingType;
	},
	meterUsageSettingNextPage(state) {
		return state.currentPage + 1;
	},
	meterUsageSettingIsLastPage(_, getters) {
		return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
	},
};
