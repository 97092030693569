import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export default {
    namespaced: true,
    state(){
        return {
            isInvoicePrintOrderChanges: false,
            isPrintOrder: false,
            isReceiptPrintOrder: false,
            isNoticePrintOrder: false,
            isWithholdingTaxPrintOrder: false,
        }
    },
    actions,
    getters,
    mutations
}