import actions from '../followUp/actions.js';
import getters from '../followUp/getters.js';
import mutations from '../followUp/mutations.js';

export default {
    namespaced: true,
    state(){
        return {
            debtFreeFollowUpList: [],
            totalPage: 0,
            currentPage: 1,
            rows: 100,
            selectedDebtFreeFollowUp: null,
        }
    },
    actions,
    getters,
    mutations
}