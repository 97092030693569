<template>
  <base-toolbar
    :title="lang.title"
    :dataButtons="dataButtons"
    :hideBackButton="isHideBack"
    @goBack="goBack"
    @createNotice="gotoEdit('create')"
  ></base-toolbar>
  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="lang.notificationDialogHeader"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
    ></base-dialog>
    <report-notice-custom
      v-model:visible="showPrintNoticeCustomDialog"
      :noticePrintSettingHeaderId="noticePrintSettingHeaderId"
      :isSuspend="selectedIsSuspend"
      @close="showPrintNoticeCustomDialog = false"
      @toast-request="toastRequest"
    />
    <base-toast-report-request
      :showToast="showToast"
      :toastMessage="toastMessage"
      :toastLink="toastLink"
      :actionToast="actionToast"
    ></base-toast-report-request>
    <div class="surface-card shadow-2 p-5 border-round mt-5" :class="textFormClass">
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.containerTitle }}</label>
      </div>
      <PvDivider></PvDivider>
      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="noticeCustomReports"
          v-model:selection="selectedNoticeCustomReport"
          selectionMode="single"
          dataKey="id"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          responsiveLayout="scroll"
          @row-dblclick="editNoticeCustomReport(this.selectedNoticeCustomReport.id)"
          scrollable
        >
          <PvColumn
            field="itemNo"
            :header="lang.itemNo"
            style="min-width: 2rem"
            headerClass="h-left"
            bodyClass="text-left tableBodyTop"
            sortable
          />
          <PvColumn
            field="name"
            :header="lang.name"
            style="min-width: 15rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
          </PvColumn>

          <PvColumn
            style="min-width: 12rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                @click="gotoPrint(slotProps.data)"
              >
                <icon-printer iconColor="secondary" width="24" height="24" />
              </PvButton>
            </template>
          </PvColumn>

          <template #footer> </template>

          <template #empty>
            <div class="w-full" style="text-align: center">
              <label>{{ this.lang.tableEmptyList }}</label>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import reportNoticeCustom from "@/components/report/AR/ReportNoticeCustom.vue";

export default {
  components: {
    reportNoticeCustom,
  },
  setup() {
    const { lang } = useLang();
    const { textFormClass, textTableClass } = useTextStyle();
    const isHideBack = inject("isHideBack");
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.notice.customReport,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.th.ar.notice.customReport,
        };
      }
    });
    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      isHideBack,
    };
  },
  data() {
    return {
      isLoading: false,
      noticeCustomReportId: null,
      selectedNoticeCustomReport: [],
      showValidateDialog: false,
      validateWording: "",
      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "w-full p-button-outlined p-button-secondary",
          action: "confirm",
        },
      ],
      showPrintNoticeCustomDialog: false,
      noticePrintSettingHeaderId: null,

      showToast: 0,
      toastMessage: {},
      toastLink: "",
      actionToast: "",
      selectedIsSuspend: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.initiateData();
    await this.getNoticeCustomReportList();
    this.setLoading(false);
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({ name: "home" });
    },
    editNoticeCustomReport(id) {
      this.$router.push({
        name: "notice-custom-report-edit",
        params: { id: encodeURI(id) },
      });
    },
    async initiateData() {
      this.noticeCustomReportId = null;
      this.showValidateDialog = false;
      this.validateWording = "";
    },
    async getNoticeCustomReportList() {
      try {
        this.isLoading = true;
        const payload = {
          projectId: this.selectedProject.id,
          userId: this.userId,
        };
        this.$store.dispatch("noticeCustomReport/noticeCustomReports", payload);
        this.isLoading = false;
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;
        this.isLoading = false;
      }
    },
    async gotoEdit(id) {
      this.$router.push({name: "notice-custom-report-edit", params:{id: id}});
    },
    async gotoPrint(data) {
      // console.log("gotoPrint=", data);
      this.selectedIsSuspend = data.isSuspend === "Y" ? true : false;
      this.showPrintNoticeCustomDialog = true;
      this.noticePrintSettingHeaderId = data.id;
    },
    toastRequest() {
      this.toastMessage = {
        severity: "info",
        summary: this.lang.toastRequestTitle,
        detail: this.lang.toastRequestContent,
        life: 5000,
      };
      this.actionToast = `/${this.selectedProject.code}/report/request?back=${this.$route.fullPath}`;
      this.toastLink = this.lang.toastRequestLink;

      const today = new Date();
      this.showToast = today.getSeconds();
    },
  },
  computed: {
    title() {
      return this.lang.title;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userId() {
      return this.$store.getters.userId;
    },
    noticeCustomReports() {
      let items = this.$store.getters["noticeCustomReport/noticeCustomReports"];
      if (items) {
        let number = 1;
        items.forEach((element) => {
          element.itemNo = number;
          number++;
        });
      }
      return items;
    },
    dataButtons() {
      return [
        {
          id: "createNotice",
          caption: this.lang.createNotice,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "createNotice",
          isShow: true,
        },
      ];
    },
  },
};
</script>