<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
    :header="lang.titleReportPettyCash"
  >
  <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ this.lang.titleReportPettyCash }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>
    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="getGlBook">{{ lang.glBookCode }}</label>
          <!-- <PvDropdown
            class="inputfield w-full mt-1"
            :options="getGlBook"
            :showClear="clearBookCode"
            optionLabel="bookName"
            optionValue="bookId"
            v-model="selectBookId"
            @change="selectBookError = false"
            :class="{ 'p-invalid': selectBookError }"
          /> -->
          <base-account-book-auto-complete
            id="accountBook"
            v-model="selectBookId"
            @load-finish="accountBookDataReady"
            class="inputfield w-full mt-1"
            :isLoadingData="true"
            :class="{ 'p-invalid': selectBookError }"
            @item-select="selectedBook"
            :accountBook="'PC'"
            :key="'BaseReportPettyCash'"
          />
          <small v-if="selectBookError" id="unit-help" class="p-error">{{
            lang.validateGlBookSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="pettyCashType">{{ lang.reportType }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="pettyCashType"
            :showClear="clearPettyCashType"
            optionLabel="name"
            optionValue="code"
            v-model="selectPettyCashType"
            @change="SetPettyCashType"
            scrollHeight="300px"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateStart">{{ lang.dateStart }}</label>
          <PvCalendar
            id="dateStart"
            v-model="dateStart"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateEnd">{{ lang.dateEnd }}</label>
          <PvCalendar
            id="dateEnd"
            v-model="dateEnd"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="approve">{{ lang.columnApprove }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="approve"
            :showClear="clearApproved"
            optionLabel="name"
            optionValue="code"
            v-model="selectApprove"
            :disabled="isApprove"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="periods">{{ lang.columnPeriodC }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="periods"
            :showClear="clearPeriod"
            optionLabel="periodName"
            optionValue="periodId"
            v-model="selectPeriod"
            :filter="true"
            filterPlaceholder="Find Period"
            :disabled="isPeriod"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="sortBy">{{ lang.labelSortBy }}</label>
          <PvDropdown
            class="inputfield w-full mt-1"
            :options="sortBy"
            optionLabel="name"
            optionValue="code"
            v-model="selectSortBy"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText
            id="fileNameText"
            type="text"
            class="inputfield w-full mt-1"
            v-model="fileNameText"
          />
        </div>
      </section>
    </transition>
    
    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
        </div>
      </div>
    </template>

    <!-- <div class="pt-4 pb-1">
      <PvButton
        icon="pi pi-file-pdf"
        :label="lang.downloadPDF"
        @click="downloadPDF"
        class="mr-2 mb-2"
      />
      <PvButton
        icon="pi pi-file-excel"
        :label="lang.downloadExcel"
        @click="downloadExcel"
        class="mr-2 mb-2"
      />
      <PvButton
        icon="pi pi-times"
        :label="lang.close"
        @click="closeDialog"
        class="mr-2 mb-2"
      />
    </div> -->
  </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetGlBook from "../../hooks/getGlBook.js";
import useGetFormPrint from "../../hooks/getFormPrint.js";
import useTracking from "../../hooks/tracking";
import useReportRequestValidate from "../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "toast-request"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { SetBookCode, glBooks } = useGetGlBook();
    const { getPreviewBlob } = useGetFormPrint();
    const { addReportTracking } = useTracking(); 
    const { reportPrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.PettyCash,
          ...lang.th.gl.accounting.listing,
          ...lang.th.gl.accounting.edit,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.PettyCash,
          ...lang.en.gl.accounting.listing,
          ...lang.en.gl.accounting.edit,
          ...lang.en.gl.reportGl,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      SetBookCode,
      glBooks,
      getPreviewBlob,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      dateStart: null,
      dateEnd: null,
      selectBookId: "",
      clearBookCode: false,
      pettyCashType: [
        { name: "1.1 "+this.lang.reportPettyCash, code: "pettycash" },
        { name: "1.2 "+this.lang.reportPettyCashNotWithDraw, code: "nonwithdraw" },
        { name: "1.3 "+this.lang.reportPettyCashWithDraw, code: "withdraw" },
        { name: "1.4 "+this.lang.reportPettyCashNotApprove, code: "nonapprove" },
        { name: "1.5 "+this.lang.reportPettyCashApprove, code: "approve" },
        { name: "1.6 "+this.lang.reportPettyCashToGl, code: "limit" },
      ],
      selectPettyCashType: "pettycash",
      clearPettyCashType: false,
      approve: [
        { name: this.lang.approveAll, code: "All" },
        { name: this.lang.columnApprove, code: "Y" },
        { name: this.lang.notApprove, code: "N" },
      ],
      selectApprove: "All",
      clearApproved: false,
      selectPeriod: "All",
      clearPeriod: false,
      isApprove: false,
      isPeriod: false,
      selectBookError: false,
      
      sortBy: [
        { name: this.lang.sortByCode, code: "code" },
        { name: this.lang.sortByDate, code: "date" },
      ],
      selectSortBy: "code",
      type: "PDF",
      fileNameText: "ReportPettyCash",
      isSharedAccountBookReady: false,
    };
  },
  created() {
    let date = new Date();
    this.dateStart = new Date();
    //this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
    this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.SetBookCode("PC");
    this.getPeriods();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.initiateData();
      this.$emit("close");
    },
    initiateData() {
      let date = new Date();
      this.dateStart = new Date();
      this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.selectBookId = "";
      this.selectBookError = false;
      this.selectPettyCashType = "pettycash";
      this.selectApprove = "All";
      this.selectPeriod = "All";
      this.selectSortBy = "code";
      this.type = "PDF";
      this.fileNameText = "ReportPettyCash";
    },
    async getPeriods() {
      // console.log("where", where);
      let where = {
        projectId: this.selectedProject.id,
      };
      const payload = {
        page: 1,
        limit: 100,
        where,
      };
      await this.$store.dispatch("report/getPeriods", payload);
    },
    SetPettyCashType() {
      if (this.selectPettyCashType == "nonwithdraw") {
        this.isApprove = false;
        this.isPeriod = true;
        this.selectApprove = "All";
        this.selectPeriod = "N";
      } else if (this.selectPettyCashType == "withdraw") {
        this.isApprove = false;
        this.isPeriod = true;
        this.selectApprove = "All";
        this.selectPeriod = "Y";
      } else if (this.selectPettyCashType == "approve") {
        this.isApprove = true;
        this.isPeriod = false;
        this.selectApprove = "Y";
        this.selectPeriod = "All";
      } else if (this.selectPettyCashType == "nonapprove") {
        this.isApprove = true;
        this.isPeriod = false;
        this.selectApprove = "N";
        this.selectPeriod = "All";
      } else {
        this.isApprove = false;
        this.isPeriod = false;
        this.selectApprove = "All";
        this.selectPeriod = "All";
      }
      this.fileNameText = this.fileName
    },
    
    async download(typeReport) {
      this.type = typeReport;
      if (this.validateData()) {
        return;
      }
      this.setLoading(true);
      try {          
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name); 
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
    validateData() {
      if (this.selectBookId === "") {
        this.selectBookError = true;
        return true;
      }

      return false;
    },
    accountBookDataReady() {
      this.isSharedAccountBookReady = true;
      this.setLoading(false);
    },
    async getPettyCashUseOtherExpenses() {
      this.setLoading(true);
      try {
        let glBookId = this.selectBookId ? this.selectBookId.generalLedgerBookId : "";
        await this.$store.dispatch("glPettyCash/getPettyCashUseOtherExpenses", glBookId);
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
    async selectedBook() {
      this.selectBookError = false;
      await this.getPettyCashUseOtherExpenses();
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    getGlBook() {
      let glbookCode = [];
      if (this.glBooks != null) {
        this.glBooks.forEach((data) => {
          let book = {
            bookId: data.generalLedgerBookId,
            bookName:
              data.generalLedgerBookCode + " : " + data.generalLedgerBookName,
          };
          glbookCode.push(book);
        });

        return glbookCode;
      }
      return glbookCode;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    periods() {
      let Response = [
        { periodId: "All", periodName: "ทั้งหมด" },
        { periodId: "Y", periodName: "เบิกคืนแล้ว" },
        { periodId: "N", periodName: "ยังไม่เบิกคืน" },
      ];
      let periods = this.$store.getters["report/periods"];
      if (periods) {
        periods.forEach((data) => {
          let period = {
            periodId: data.period,
            periodName: data.period,
          };
          Response.push(period);
        });
      }
      return Response;
    },  
    fileType() {
      let fileType = this.type == "PDF" ? ".pdf" : ".xlsx";
      return fileType;
    },
    fileName() {      
      let result = "ReportPettyCash";
      if (this.selectPettyCashType == "pettycash") {
        result = "ReportPettyCash";
      } else if (this.selectPettyCashType == "nonwithdraw") {
        result += "NotWithDraw";

      } else if (this.selectPettyCashType == "withdraw") {
        result += "WithDraw";

      } else if (this.selectPettyCashType == "nonapprove") {
        result += "NotApprove";

      } else if (this.selectPettyCashType == "approve") {
        result += "Approve";

      } else if (this.selectPettyCashType == "limit") {
        result += "ToGl";

      } 
      return result ;
    },
    jasperPath() {
      let result = this.reportObj.path + "_refund";
      if (this.selectPettyCashType == "pettycash") {
        result = this.reportObj.path + "_refund";

      } else if (this.selectPettyCashType == "nonwithdraw") {
        result = this.reportObj.path + "_withdraw";

      } else if (this.selectPettyCashType == "withdraw") {
        result = this.reportObj.path + "_withdraw";

      } else if (this.selectPettyCashType == "nonapprove") {
        result = this.reportObj.path + "_approve";

      } else if (this.selectPettyCashType == "approve") {
        result = this.reportObj.path + "_withdraw";

      } else if (this.selectPettyCashType == "limit") {
        result = this.reportObj.path + "_show_account";
      } 

      const otherExpenses = this.isPettyCashUseOtherExpenses == "Y"? "_with_other_expenses":"";
      result += otherExpenses;

      if(this.type == "EXCEL" ){
        if (this.selectPettyCashType == "limit") {
         result += "_excel";
        }
        else{
          result = this.reportObj.path + otherExpenses + "_excel";
        }       
      }

      return result;
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
    payload() {
      let payload = {
        FileName: this.fileNameText + this.fileType,
        JasperPath: this.jasperPath + this.fileType,
        DocType: this.type,
        UserId: this.userId,
        PrintBy: this.userName,
        ProjectId: this.selectedProject.id,
        GeneralLedgerBookId : this.selectBookId ? this.selectBookId.generalLedgerBookId : null,
        DateStart: this.selectedDateStart,
        DateEnd: this.selectedDateEnd,
        IsApprove: this.selectApprove,
        Period: this.selectPeriod,
        ReportType: this.reportJasperTitleName,
        SortBy: this.selectSortBy,
        RequestId: 0,
        ReportName: "PettyCash",
        ReportSelected: this.selectPettyCashType,
        ReportMenuDetailId: this.reportObj.id,
      };
      // let parameter = {     
      //     PrintBy: this.user.userName,
      //     ProjectId: this.selectedProject.id,
      //     GLBId: this.selectBookId,
      //     DateStart: this.selectedDateStart,
      //     DateEnd: this.selectedDateEnd,
      //     IsApprove: this.selectApprove,
      //     Period: this.selectPeriod,
      //     ReportType: this.reportJasperTitleName,
      // };

      // let payload = {
      //   docType: this.type,
      //   path: this.jasperPath + this.fileType,
      //   fileName: this.fileName,
      //   parameter: JSON.stringify(parameter),
      // };
      return payload;
    },
    reportJasperTitleName(){
      let ReportName = this.lang.reportPettyCash;
      if (this.selectPettyCashType == "nonwithdraw") ReportName = this.lang.reportPettyCashNotWithDraw;
      else if (this.selectPettyCashType == "withdraw") ReportName = this.lang.reportPettyCashWithDraw;
      else if (this.selectPettyCashType == "nonapprove") ReportName = this.lang.reportPettyCashNotApprove;
      else if (this.selectPettyCashType == "approve") ReportName = this.lang.reportPettyCashApprove;
      return  ReportName         
    },
    isPettyCashUseOtherExpenses() {
      const result = this.$store.getters["glPettyCash/pettyCashUseOtherExpenses"];
      return result? result.isPettyCashUseOtherExpenses:"";
    },
    
  },
  // watch: {
  //   selectBookId() {
  //     // if (oldValue === null && curValue) {
  //     this.selectBookError = false;
  //     // }
  //   },
  // },
};
</script>

<style></style>
