export default({
    Title: "Petty Cash",
    reasonNonApprove:"Reason",
    refundPettyCash:"Refund Petty Cash",
    approvePettyCash:"Approve PettyCash",
    columnPeriodC:"Period",
    askRefundPettyCash: "Are you sure you want to Refund Petty Cash?",
    askApprovePettyCash: "Are you sure you want to Approve Petty Cash?",
    labelSortBy: "SortBy",
    sortByCode: "SortBy Code",
    sortByDate: "SortBy Date",

    reportPettyCash: "PettyCash Report",
    reportPettyCashNotWithDraw: "PettyCash Not WithDraw Report",
    reportPettyCashWithDraw: "PettyCash WithDraw Report",
    reportPettyCashNotApprove: "PettyCash Not Approve Report",
    reportPettyCashApprove: "PettyCash Approve Report",
    reportPettyCashToGl: "PettyCash To Gl",

    withdrawStatus: "Withdraw Status",
    statusAll: "All",
    statusWait: "Wait",
    statusRequested: "Requested",
  
  });