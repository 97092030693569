export default {
	setPayin(state, payload) {
		state.payin = payload.data;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
	setSelectedBankPayin(state, payload) {
		state.selectedPayin = payload;
	},
	setBlob(state, payload) {
		state.blob = payload;
	},
	resetArBank(state) {
		state.payin = null;
		state.selectedPayin = null;
		state.totalPage = 0;
		state.currentPage = 0;
	},
	setReceiptForPayin(state, payload) {
		state.receiptForPayin = payload;
	},
};
