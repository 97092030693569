<template>
  <base-toolbar :title="lang.toolbarTitle" @goBack="goBack"></base-toolbar>

  <base-dialog
    v-model:visible="showValidateDialog"
    :titleConfirm="lang.notificationDialogHeader"
    :wordingConfirm="validateDialogWording"
    :dataButtons="dataButtonDialog"
    @confirm="showValidateDialog = false"
    @close="showValidateDialog = false"
  ></base-dialog>

  <base-form-print-modal
    v-model:visible="showFormPrintModal"
    :search="searchTextPrint"
    @close="showFormPrintModal = false"
    @path-selected="formPrintModalSelected"
  ></base-form-print-modal>

  <base-container>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{ lang.debtFreeList }}</label>

      <PvDivider></PvDivider>

      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >
        <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            id="filterSearch"
            class="inputfield w-full mr-2"
            :options="filterSearch"
            optionLabel="name"
            optionValue="code"
            v-model="selectedFilterSearch"
            scrollHeight="300px"
            @change="changeFilterSearch"
          />
        </div>
        <div
          class="flex lg:col-6 md:col-12 col-12 pl-0 pr-2 mr-2"
          v-if="isSearchDate"
        >
          <div class="col-6 pl-0 pr-2">
            <PvCalendar
              id="dateStart"
              v-model="dateStart"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
          <label for="dateEnd" class="pt-1 mt-3 mr-2">{{ "-" }}</label>
          <div class="col-6 pl-0 pr-2">
            <PvCalendar
              id="dateEnd"
              v-model="dateEnd"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
        </div>
        <div class="flex lg:col-6 md:col-12 col-12 pl-0 pr-0 mr-2" v-else>
          <div class="col-12 pl-0 pr-0">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchText"
                :placeholder="lang.searchTextPlaceholder"
                @keyup.enter="getDebtFreePrints()"
                autofocus
              />
            </span>
          </div>
        </div>
        <PvButton @click="getDebtFreePrints">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="debtFreePrints"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          scrollable
        >
          <PvColumn
            field="debtfreePrintDate"
            :header="lang.printDate"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ slotProps.data.debtfreePrintDate?formatDate(slotProps.data.debtfreePrintDate):"" }}
            </template>
          </PvColumn>
          <PvColumn
            field="debtfreeCode"
            :header="lang.documentCode"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="documentDate"
            :header="lang.documentDate"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.documentDate) }}
            </template>
          </PvColumn>
          <PvColumn
            field="unitCode"
            :header="lang.unitCode"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="unitAddressNo"
            :header="lang.unitAddressNo"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="ownerName"
            :header="lang.ownerName"
            style="min-width: 12rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="approvedBy"
            :header="lang.approvedBy"
            style="min-width: 12rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />

          <PvColumn
            style="min-width: 12rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                @click="getPrintForm(slotProps.data)"
              >
                <icon-printer iconColor="secondary" width="24" height="24" />
              </PvButton>
            </template>
          </PvColumn>

          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              :first="showCurrentPage"
              class="p-pagination-radius"
            >
              <template #start="slotProps">
                {{ lang.page }}:
                {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
              </template>
            </PvPaginator>
          </template>

          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
export default {
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.debtFree.print.listing,
          ...lang.th.debtFree.request.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.debtFree.print.listing,
          ...lang.en.debtFree.request.listing,
        };
      }
    });

    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();
    const {
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
    } = useGetFormPrint();

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,
      formatDate,
      formatDateToISOString,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,

      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
    };
  },
  data() {
    return {
      filterSearch: [
        { name: this.lang.dateRange, code: "date" },
        { name: this.lang.unitAddress, code: "unitCodeAndAddressNo" },
        { name: this.lang.ownerName, code: "ownerName" },
      ],
      isLoading: false,
      selectedFilterSearch: "date",
      dateStart: null,
      dateEnd: null,
      searchText: "",
      storageName: "",
      selectedDebtFreePrint: null,
      selectedPage: 1,
      rowsPerPage: 10,

      showValidateDialog: false,
      validateDialogWording: "",
      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ],

      //form
      showFormPrintModal: false,
      path: "",
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.initiateData();
    await this.roleByMenuDataReady();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"});
    },
    async initiateData() {
      let date = new Date();
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.searchText = "";
      this.storageName = `erp_search_DebtFreePrintList_${this.selectedProject.code}_${this.userCode}`;
    },
    async roleByMenuDataReady() {
      if (this.isSharedRoleByMenuReady) {
        await this.setLocalSearch();
        await this.getDebtFreePrints();
      }
    },
    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      if (localSearch) {
        let search = JSON.parse(localSearch);
        if (search.isPrinted) {
          if (search.dateStart != null && search.dateStart != "") {
            this.selectedFilterSearch = "date";
            this.dateStart = new Date(search.dateStart);
          } else {
            this.dateStart = "";
          }

          if (search.dateEnd != null && search.dateEnd != "") {
            this.selectedFilterSearch = "date";
            this.dateEnd = new Date(search.dateEnd);
          } else {
            this.dateEnd = "";
          }

          if (
            search.unitCodeAndAddressNo != null &&
            search.unitCodeAndAddressNo != ""
          ) {
            this.selectedFilterSearch = "unitCodeAndAddressNo";
            this.searchText = search.unitCodeAndAddressNo;
          } else if (search.ownerName != null && search.ownerName != "") {
            this.selectedFilterSearch = "ownerName";
            this.searchText = search.ownerName;
          } else {
            this.searchText = "";
          }
          this.rowsPerPage = search.limit;
          this.selectedPage = search.page;

          search.isPrinted = false;
          localStorage.setItem(this.storageName, JSON.stringify(search));
        }
      }
    },
    validateSearchButton() {
      if (
        this.selectedFilterSearch == "date" &&
        (!this.selectedDateStart || !this.selectedDateEnd)
      ) {
        return true;
      } else if (
        this.selectedFilterSearch != "date" &&
        this.searchText.trim() == ""
      ) {
        return true;
      }

      return false;
    },
    async getDebtFreePrints() {
      if (this.validateSearchButton()) {
        this.validateDialogWording = this.lang.validateSearchText;
        this.showValidateDialog = true;
        return;
      }

      this.isLoading = true;
      try {
        // console.log(this.payload);

        await this.$store.dispatch(
          "debtFreePrint/getDebtFreePrints",
          this.payload
        );

        let local = {
          ...this.payload,
          limit: this.rowsPerPage,
          page: this.selectedPage,
        };
        localStorage.setItem(this.storageName, JSON.stringify(local));
      } catch (error) {
        this.validateDialogWording = error;
        this.showValidateDialog = true;
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    changeFilterSearch() {
      this.initiateData();
    },
    async onPageChange(event) {
      if (event.rows != this.rowsPerPage) {
        this.selectedPage = 1;
      } else {
        this.selectedPage = event.page + 1;
      }
      this.rowsPerPage = event.rows;
      await this.getDebtFreePrints();
    },

    async getPrintForm(data) {
      if (!this.isRolePrint) {
        this.validateDialogWording = this.lang.validatePrintPermission;
        this.showValidateDialog = true;
        return true;
      }
      this.selectedDebtFreePrint = data;

      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: "DEBTFREE",
      };
      const payload = { where };
      await this.getFormPrints(payload);
      // console.log(this.filteredFormPrints);
      if (this.filteredFormPrints.length == 1) {
        this.formPrintModalSelected(this.filteredFormPrints[0]);
      } else {
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path + ".pdf";
      this.printDebtFree();
      this.showFormPrintModal = false;
    },
    async printDebtFree() {
      this.setLoading(true);
      try {
        let parameter = {
          Id: this.selectedDebtFreePrint.id,
        };

        let payload = {
          id: this.selectedDebtFreePrint.id,
          path: this.path,
          fileName: "DebtFree",
          parameter: JSON.stringify(parameter),
        };
        // console.log("print", payload);
        await this.$store.dispatch("debtFreePrint/printDebtFree", payload);

        let local = {
          ...this.payload,
          limit: this.rowsPerPage,
          page: this.selectedPage,
          isPrinted: true,
        };
        localStorage.setItem(this.storageName, JSON.stringify(local));

        await this.getPreviewBlob(this.fileBlob);
        this.setLoading(false);
        await this.getDebtFreePrints();
      } catch (error) {
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
    },
  },
  computed: {
    payload() {
      let payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,

        projectId: this.selectedProject.id,
        dateStart:
          this.selectedFilterSearch == "date" ? this.selectedDateStart : null,
        dateEnd:
          this.selectedFilterSearch == "date" ? this.selectedDateEnd : null,
        unitCodeAndAddressNo:
          this.selectedFilterSearch == "unitCodeAndAddressNo"
            ? this.searchText
            : null,
        ownerName:
          this.selectedFilterSearch == "ownerName" ? this.searchText : null,
      };
      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmCOR");
    },
    isRolePrint() {
      if (this.roleByMenu && this.roleByMenu.print == "Y") {
        return true;
      }
      return false;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    debtFreePrints() {
      return this.$store.getters["debtFreePrint/debtFreePrints"];
    },
    totalPage() {
      return this.$store.getters["debtFreePrint/totalPage"];
    },
    currentPage() {
      return this.$store.getters["debtFreePrint/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    showCurrentPage() {
      return this.rowsPerPage * (this.selectedPage - 1);
    },
    isSearchDate() {
      if (this.selectedFilterSearch == "date") {
        return true;
      }
      return false;
    },
    fileBlob() {
      return this.$store.getters["debtFreePrint/blob"];
    },
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.roleByMenuDataReady();
    },
  },
};
</script>
