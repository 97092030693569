<template>
  <base-toolbar
    :title="lang.title"
    :dataButtons="dataButtons"
    @goBack="goBack"
    @gotoCreate="gotoEdit('create')"
    :hideBackButton="isHideBack"
  ></base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="showValidateDialog"
      :titleConfirm="lang.notificationDialogHeader"
      :wordingConfirm="validateWording"
      :dataButtons="dataButtonDialog"
      @confirm="showValidateDialog = false"
      @close="showValidateDialog = false"
    ></base-dialog>

    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchTextPrint"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>

    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <label class="font-lg font-normal">{{ lang.titleListing }}</label>

      <PvDivider></PvDivider>

      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >
        <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            id="filterSearch"
            class="inputfield w-full mr-2"
            :options="filterSearch"
            optionLabel="name"
            optionValue="code"
            v-model="selectedFilterSearch"
            scrollHeight="300px"
            @change="changeFilterSearch"
          />
        </div>
        <div
          class="flex lg:col-6 md:col-12 col-12 pl-0 pr-2 mr-2"
          v-if="isSearchDate"
        >
          <div class="col-6 pl-0 pr-2">
            <PvCalendar
              id="dateStart"
              v-model="dateStart"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
          <label for="dateEnd" class="pt-1 mt-3 mr-2">{{ "-" }}</label>
          <div class="col-6 pl-0 pr-2">
            <PvCalendar
              id="dateEnd"
              v-model="dateEnd"
              class="inputfield w-full"
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>
        </div>
        <div class="flex lg:col-6 md:col-12 col-12 pl-0 pr-0 mr-2" v-else>
          <div class="col-12 pl-0 pr-0">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchText"
                :placeholder="lang.searchTextPlaceholder"
                @keypress="checkInputSearch"
                autofocus
              />
            </span>
          </div>
        </div>
        <PvButton @click="getRefundGuaranteeList">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="refundGuaranteeList"
          v-model:selection="selectedRefundGuarantee"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(this.selectedRefundGuarantee.id)"
          scrollable
        >
          <PvColumn
            field="code"
            :header="lang.documentCode"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <u>{{ slotProps.data.code }}</u>
            </template>
          </PvColumn>
          <PvColumn
            field="date"
            :header="lang.date"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.date) }}
            </template>
          </PvColumn>
          <PvColumn
            field="unitCode"
            :header="lang.unit"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="unitAddress"
            :header="lang.address"
            style="min-width: 8rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="customerName"
            :header="lang.customerName"
            style="min-width: 15rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="netAmount"
            :header="lang.columnNetAmount"
            style="min-width: 10rem; height: 56px"
            headerClass="h-right"
            sortable
          >
            <template #body="slotProps">
              <span class="text-right w-full">{{
                formatCurrency(slotProps.data.netAmount)
              }}</span>
            </template>
          </PvColumn>
          <PvColumn
            field="isPaid"
            :header="lang.status"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <base-tag
                v-if="slotProps.data.isPaid == 'P'"
                :Caption="lang.refunded"
                Class="success"
              />
            </template>
          </PvColumn>
          <PvColumn
            field="cancelStatus"
            :header="lang.cancel"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <base-tag
                v-if="slotProps.data.cancelStatus == 'C'"
                :Caption="lang.cancel"
                Class="error"
              />
            </template>
          </PvColumn>
          <PvColumn
            field="postStep"
            :header="lang.print"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <base-tag
                v-if="slotProps.data.postStep == 'P'"
                :Caption="lang.printed"
                Class="primary"
              />
            </template>
          </PvColumn>
          <PvColumn
            field="createDate"
            :header="lang.columnCreateDate"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.createDate) }}
            </template>
          </PvColumn>
          <PvColumn
            field="updateDate"
            :header="lang.columnUpdateDate"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <label v-if="slotProps.data.updateDate">
                {{ formatDate(slotProps.data.updateDate) }}
              </label>
            </template>
          </PvColumn>
          <PvColumn
            field="createBy"
            :header="lang.columnCreateBy"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="updateBy"
            :header="lang.columnUpdateBy"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            style="min-width: 4rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0"
                @click="getPrintForm(slotProps.data)"
              >
                <icon-printer iconColor="secondary" width="24" height="24" />
              </PvButton>
            </template>
          </PvColumn>

          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ this.lang.tableEmpty }}</label>
            </div>
          </template>

          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              class="p-pagination-radius"
              :first="showCurrentPage"
            >
              <template #start="slotProps">
                {{ lang.page }}:
                {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{ totalPage }}
              </template>
            </PvPaginator>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useGetRoleByBook from "../../../hooks/getRoleByBook.js";
export default {
  setup() {
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.refundGuarantee.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.refundGuarantee.listing,
        };
      }
    });

    const { getRoleByBookByCode, isSharedRoleByBookReady } = useGetRoleByBook();

    const {
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
    } = useGetFormPrint();

    const isHideBack = inject("isHideBack");

    return {
      lang: mergeLang,

      textTableClass,
      textFormClass,

      formatCurrency,
      formatDate,
      formatDateToISOString,
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
      isHideBack,
      getRoleByBookByCode,
      isSharedRoleByBookReady,
    };
  },
  data() {
    return {
      filterSearch: [
        { name: this.lang.dateRange, code: "date" },
        { name: this.lang.documentCode, code: "code" },
        { name: this.lang.unit, code: "unitCode" },
        { name: this.lang.address, code: "unitAddress" },
        { name: this.lang.customerName, code: "customerName" },
      ],
      selectedFilterSearch: "date",

      isLoading: false,
      selectedPage: 1,
      rowsPerPage: 10,
      dateStart: null,
      dateEnd: null,
      searchText: "",
      selectedRefundGuarantee: null,

      showValidateDialog: false,
      validateWording: "",

      showFormPrintModal: false,
      path: "",

      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ],
    };
  },
  async mounted() {
    await this.initiateData();
    await this.roleByBookDataReady();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    gotoEdit(id) {
      if (id != "create" && !this.isRoleSelect) {
        this.validateWording = this.lang.validateRoleSelect;
        this.showValidateDialog = true;
        return
      } else if (id == "create" && !this.isRoleCreate){
        this.validateWording = this.lang.validateRoleInsert;
        this.showValidateDialog = true;
        return
      }
      this.$router.push({name: "ar-refund-guarantee-edit", params: {id: id}});
      
    },
    changeFilterSearch() {
      this.initiateData();
    },
    async checkInputSearch(event) {
      if (this.selectedFilterSearch == "code") {
        const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        const keyPressed = event.key;
        if (event.key != "Enter" && !keysAllowed.includes(keyPressed)) {
          event.preventDefault();
        }
      }

      if (event.key == "Enter") {
        await this.getRefundGuaranteeList();
      }
    },
    async onPageChange(event) {
      if(event.rows != this.rowsPerPage){
        this.selectedPage = 1;
      }else{
        this.selectedPage = event.page + 1;        
      }
      this.rowsPerPage = event.rows;
      await this.getRefundGuaranteeList();
    },
    async initiateData() {
      let date = new Date();
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.searchText = "";
      this.selectedRefundGuarantee = null,

      this.storageName = `erp_search_RefundGuaranteeList_${this.selectedProject.code}_${this.userCode}`;
    },
    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      if (localSearch) {
        let search = JSON.parse(localSearch);
        if (search.entry === "entry") {
          if (search.dateStart != null && search.dateStart != "") {
            this.selectedFilterSearch = "date";
            this.dateStart = new Date(search.dateStart);
          } else this.dateStart = "";

          if (search.dateEnd != null && search.dateEnd != "") {
            this.selectedFilterSearch = "date";
            this.dateEnd = new Date(search.dateEnd);
          } else this.dateEnd = "";

          if (search.code != null && search.code != "") {
            this.selectedFilterSearch = "code";
            this.searchText = search.code;
          } else if (search.unitCode != null && search.unitCode != "") {
            this.selectedFilterSearch = "unitCode";
            this.searchText = search.unitCode;
          } else if (
            search.unitAddress != null &&
            search.unitAddress != ""
          ) {
            this.selectedFilterSearch = "unitAddress";
            this.searchText = search.unitAddress;
          } else if (search.customerName != null && search.customerName != "") {
            this.selectedFilterSearch = "customerName";
            this.searchText = search.customerName;
          } else {
            this.searchText = "";
          }
          this.rowsPerPage = search.rowsPerPage;          
          this.selectedPage = search.selectedPage;

          search.entry = "";

          localStorage.setItem(this.storageName, JSON.stringify(search));
        }
      }
    },
    async roleByBookDataReady() {
      if (this.isSharedRoleByBookReady) {
        await this.setLocalSearch();
        await this.getRefundGuaranteeList();
      }
    },
    async getRefundGuaranteeList() {
      if (this.validateSearchButton()) {
        this.validateWording = this.lang.validateSearchText;
        this.showValidateDialog = true;
        return;
      }

      this.isLoading = true;
      try {
        // console.log("getRefundGuaranteeList", this.payload);
        await this.$store.dispatch(
          "arRefundGuarantee/getRefundGuaranteeList",
          this.payload
        );

        let local = {
          ...this.payload.where,
          rowsPerPage: this.rowsPerPage,
          selectedPage: this.selectedPage,
        };
        localStorage.setItem(this.storageName,JSON.stringify(local));

      } catch (error) {
        this.validateWording = error;
        this.showValidateDialog = true;
        this.isLoading = false;
      }

      this.isLoading = false;
    },
    async getPrintForm(data) {
      if (!this.isRolePrint) {
        this.validateWording = this.lang.validatePrintPermission;
        this.showValidateDialog = true;
        return;
      }

      this.selectedRefundGuarantee = data;
      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: "SC",
      };
      const payload = { where };
      await this.getFormPrints(payload);

      if (this.filteredFormPrints.length == 1) {
        this.formPrintModalSelected(this.filteredFormPrints[0]);
      } else {
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path + ".pdf";
      this.print();
      this.showFormPrintModal = false;
    },
    async print() {
      this.setLoading(true);
      try {
        let parameter = {
          Id: this.selectedRefundGuarantee.id,
        };

        let payload = {
          id: this.selectedRefundGuarantee.id,
          path: this.path,
          fileName: "RefundGuarantee",
          parameter: JSON.stringify(parameter),
        };

        await this.$store.dispatch("arRefundGuarantee/print", payload);
        await this.getPreviewBlob(this.fileBlob);
        await this.getRefundGuaranteeList();
        
      } catch (error) {
        this.validateWording = error;
        this.showValidateDialog = true;
        this.setLoading(false);
      }
      this.setLoading(false);
    },
    validateSearchButton() {
      if (
        this.isSearchDate &&
        (!this.selectedDateStart || !this.selectedDateEnd)
      ) {
        return true;
      } else if (!this.isSearchDate && this.searchText == "") {
        return true;
      }

      return false;
    },
  },
  computed: {
    payload() {
      let where = {
        projectId: this.selectedProject.id,
        dateStart:
          this.selectedFilterSearch == "date" ? this.selectedDateStart : null,
        dateEnd:
          this.selectedFilterSearch == "date" ? this.selectedDateEnd : null,
        code: this.selectedFilterSearch == "code" ? this.searchText : null,
        unitCode:
          this.selectedFilterSearch == "unitCode" ? this.searchText : null,
        unitAddress:
          this.selectedFilterSearch == "unitAddress" ? this.searchText : null,
        customerName:
          this.selectedFilterSearch == "customerName" ? this.searchText : null,
      };

      const payload = {
        page: this.selectedPage,
        limit: this.rowsPerPage,
        where,
      };

      return payload;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    roleByDocumentBook() {
      return this.getRoleByBookByCode("SC");
    },
    isRoleSelect() {
      if (this.roleByDocumentBook && this.roleByDocumentBook.select == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRoleCreate() {
      if (this.roleByDocumentBook && this.roleByDocumentBook.insert == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRolePrint() {
      if (this.roleByDocumentBook && this.roleByDocumentBook.print == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isSearchDate() {
      if (this.selectedFilterSearch == "date") {
        return true;
      } else {
        return false;
      }
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    refundGuaranteeList() {
      return this.$store.getters["arRefundGuarantee/refundGuaranteeList"];
    },
    totalPage() {
      return this.$store.getters["arRefundGuarantee/totalPage"];
    },
    currentPage() {
      return this.$store.getters["arRefundGuarantee/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    fileBlob() {
      return this.$store.getters["arRefundGuarantee/blob"];
    },
    dataButtons() {
      return [
        {
          id: "create",
          caption: this.lang.createRefundGuarantee,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
        },
      ];
    },
    showCurrentPage(){
      let page = 0;
      page = this.rowsPerPage * (this.selectedPage - 1)
      return page;
    },
  },
  watch: {
    isSharedRoleByBookReady() {
      this.roleByBookDataReady();
    },
  },
};
</script>
