<template>
  <PvDialog
    :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
    :style="{ width: '40vw' }"
    :modal="true"
    :closable="false"
  >
    <base-form-print-modal
      v-model:visible="showFormPrintModal"
      :search="searchTextPrint"
      @close="showFormPrintModal = false"
      @path-selected="formPrintModalSelected"
    ></base-form-print-modal>

    <template #header>
      <div
        class="flex justify-content-between align-items-center flex flex-wrap pt-3 pb-3"
      >
        <span class="font-lg">{{ lang.receiptPrintListPage + lang.lot }}</span>
      </div>
      <PvDivider class="mt-3 mb-3" />
    </template>

    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateStart">{{ lang.receiptDateStart }}</label>
          <PvCalendar
            id="dateStart"
            v-model="dateStart"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateStartError }"
            @date-select="dateStartError = false"
          />
          <small v-if="dateStartError" id="unit-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="dateEnd">{{ lang.to }}</label>
          <PvCalendar
            id="dateEnd"
            v-model="dateEnd"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateEndError }"
            @date-select="dateEndError = false"
          />
          <small v-if="dateEndError" id="unit-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="codeStart">{{ lang.receiptCodeStart }}</label>
          <PvInputText
            id="codeStart"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="6601xxxxx"
            v-model="codeStart"
            :class="{ 'p-invalid': codeStartError }"
            @blur="codeStartError = false"
          />
          <small v-if="codeStartError" id="codeStart-help" class="p-error">{{
            lang.validateNoCode
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="codeEnd">{{ lang.to }}</label>
          <PvInputText
            id="codeEnd"
            type="text"
            class="inputfield w-full mt-1"
            placeholder="6601xxxxx"
            v-model="codeEnd"
            :class="{ 'p-invalid': codeEndError }"
            @blur="codeEndError = false"
          />
          <small v-if="codeEndError" id="codeEnd-help" class="p-error">{{
            lang.validateNoCode
          }}</small>
        </div>        
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText
            id="fileNameText"
            type="text"
            class="inputfield w-full mt-1"
            v-model="fileNameText"
          />
        </div>
      </section>
    </transition>

    <template #footer>
      <div
        class="flex justify-content-between align-items-center m-3 mt-0 pt-0"
      >
        <PvButton
          :label="lang.close"
          class="p-button-outlined p-button-secondary w-full"
          @click="closeDialog()"
        />
        <PvButton
          class="w-full"
          :label="lang.confirm"
          @click="getPrintForm()"
        />
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetFormPrint from "../../../hooks/getFormPrint.js";
import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
export default {
  // props: ["documentBookId"],
  emits: ["close", "print-finish","toast-request"],
  setup() {
    const { lang } = useLang();
    const store = useStore();
    const { textFormClass } = useTextStyle();
    const { formatDateToISOString } = useFormat();

    const { getFormPrints, searchTextPrint, getPreviewBlob, filteredFormPrints  } = useGetFormPrint();
    const { receiptPrintOrder } = useReportRequestValidate();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.receipt.form,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.receipt.form,
        };
      }
    });

    return {
      lang: mergeLang,
      textFormClass,
      formatDateToISOString,
      getFormPrints,
      searchTextPrint,
      getPreviewBlob,
      filteredFormPrints,
      receiptPrintOrder,
    };
  },
  data() {
    return {
      dateStart: null,
      dateEnd: null,
      dateStartError: false,
      dateEndError: false,
      codeStart: "",
      codeEnd: "",
      codeStartError: false,
      codeEndError: false,
      showFormPrintModal: false,
      path: "",
      fileNameText: "ReceiptPrintLot",
    };
  },
  async mounted() {
    this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    initiateData() {
      let date = new Date();
      this.dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.dateEnd = new Date();
      this.dateStartError = false;
      this.dateEndError = false;
      this.codeStart = "";
      this.codeEnd = "";
      this.codeStartError = false;
      this.codeEndError = false;
      this.showFormPrintModal = false;
      this.path = "";       
      this.fileNameText = "ReceiptPrintLot";
    },
    validateData() {
      if (this.selectedDateStart == null) {
        this.dateStartError = true;
        return true;
      }
      if (this.selectedDateEnd == null) {
        this.dateEndError = true;
        return true;
      }
      if (this.codeStart.trim() == "") {
        this.codeStartError = true;
        return true;
      }
      if (this.codeEnd.trim() == "") {
        this.codeEndError = true;
        return true;
      }
      return false;
    },
    async getPrintForm() {
      if (this.validateData()) {
        this.setLoading(false);
        return;
      }

      await this.$store.dispatch("shared/resetFormPrint");
      let where = {
        projectId: this.selectedProject.id,
        documentBookCode: "INV",
      };
      const payload = { where };
      await this.getFormPrints(payload);
      
      if(this.filteredFormPrints.length == 1) {
        this.path = this.filteredFormPrints[0].path + ".pdf";
        this.printLot();
      }else{
        this.showFormPrintModal = true;
      }
    },
    formPrintModalSelected(event) {
      this.path = event.path + ".pdf";
      //this.categoryPrint = event.category;

      this.printLot();

      this.showFormPrintModal = false;
      this.closeDialog();
    },
    async printLot() {
      this.setLoading(true);
      try {
        // let parameter = {
        //   ProjectId: this.selectedProject.id,
        //   DateStart: this.selectedDateStart,
        //   DateEnd: this.selectedDateEnd,
        //   CodeStart: this.codeStart,
        //   CodeEnd: this.codeEnd,
        //   ReceiptId: "",
        // };

        // let payload = {
        //   path: this.path,
        //   fileName: "Receipt",
        //   parameter: JSON.stringify(parameter),

        //   projectId: this.selectedProject.id,
        //   documentBookId: this.documentBookId,
        //   codeStart: this.codeStart,
        //   codeEnd: this.codeEnd,
        //   id: "",
        // };
        // // console.log("print lot receipt", payload);
        // await this.$store.dispatch("arReceipt/printReceipt", payload);
        // await this.getPreviewBlob(this.fileBlob);

        let payload = {
          projectId: this.selectedProject.id,
          topic: "report_receipt_print_order",
          documentBookId: this.documentBookId,
          jasperPath: this.path,
          fileName: this.fileNameText+".pdf",
          userId: this.userId,
          printBy: this.userName,
          event: "create",
          dateStart: this.selectedDateStart,
          dateEnd: this.selectedDateEnd,
          codeStart: this.codeStart,
          codeEnd: this.codeEnd,       
        };
       
        // console.log("addReceiptPrintOrder_payload=",payload);        
        // await this.$store.dispatch("produces/addReceiptPrintOrder", payload);        
        // this.$emit("toast-request");
        const response = await this.receiptPrintOrder(payload);
        if (response) {
          this.$emit("toast-request");
        }
      } catch (error) {
        this.baseDialogClassTitle = "";
        this.titleConfirm = this.lang.notificationDialogHeader;
        this.validateWording = error;
        this.showValidateDialog = true;  
        this.setLoading(false);
      }

      this.setLoading(false);
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    selectedDateStart() {
      let dateStart = null;
      if (this.dateStart) {
        dateStart = this.formatDateToISOString(this.dateStart);
      }
      return dateStart;
    },
    selectedDateEnd() {
      let dateEnd = null;
      if (this.dateEnd) {
        dateEnd = this.formatDateToISOString(this.dateEnd);
      }
      return dateEnd;
    },
    printForms() {
      return this.$store.getters["shared/formPrintForms"];
    },
    fileBlob() {
      return this.$store.getters["arReceipt/blob"];
    },
    documentBookId() {
      return this.$store.getters["arReceipt/documentBookId"];
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
  },
};
</script>
