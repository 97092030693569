<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
    :draggable="false"
    :header="lang.addNewFormType"
  >
    <section class="flex flex-wrap" :class="textFormClass">
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="code">{{ lang.columnCode }}</label>
        <PvInputText
          id="code"
          type="text"
          class="inputfield w-full mt-1"
          v-model="code"
        />
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="name">{{ lang.columnName }}</label>
        <PvInputText
          id="name"
          type="text"
          class="inputfield w-full mt-1"
          v-model="name"
        />
      </div>
      <div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
        <label for="documentBook">{{ lang.columnBookCode }}</label>
        <PvDropdown
          id="documentBook"
          type="text"
          class="inputfield w-full mt-1"
          v-model="selectedDocumentBook"
          :options="filteredDocumentBookDistincts"
          optionLabel="code"
          :filter="true"
        />
      </div>
    </section>
    <template #footer>
      <PvButton
        :label="lang.save"
        icon="pi pi-check"
        @click="save"
        autofocus
      />
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        @click="closeModal"
        class="p-button-text"
      />
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useGetDocumentBookDistinct from "../../../hooks/getDocumentBookDistinct.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  props: ['editingId','editingCode','editingName','editingBookCode','editingType'],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass } = useTextStyle();

    const {
      isSharedDocumentBookDistinctReady,
      getDocumentBookDistinctByCode,
      searchText,
      filteredDocumentBookDistincts,
    } = useGetDocumentBookDistinct();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.admin.form.listing };
      } else {
        return { ...lang.en.base, ...lang.en.admin.form.listing };
      }
    });
    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      isSharedDocumentBookDistinctReady,
      getDocumentBookDistinctByCode,
      searchText,
      filteredDocumentBookDistincts,
    };
  },
  emits:['close','save-data'],
  // emits: ["close", "save-data","update:code","update:name","update:documentBook"],
  data(){
    return {
      code:'',
      name:'',
      selectedDocumentBook: null
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    // updateCode(event){
    //   this.$emit('update:code',event.target.value);
    // },
    // updateName(event){
    //   this.$emit('update:name',event.target.value);
    // },
    // updateDocumentBook(event){
    //   this.$emit('update:documentBook',event.value);
    // },
    save() {
      let payload = {
        type: this.editingType,
        code: this.code,
        name: this.name,
        documentBookCode: this.selectedDocumentBook.code,
      };
      if (this.editingId){
        payload = {...payload, id: this.editingId};
      }
      // console.log(payload)
      this.$emit('save-data',payload);
      this.$emit("close");
    },
  },
  watch:{
    editingCode(curValue){
      this.code = curValue;
    },
    editingName(curValue){
      this.name = curValue;
    },
    editingBookCode(curValue){
      this.selectedDocumentBook = this.filteredDocumentBookDistincts.find(c => c.code === curValue);
    }
  }
};
</script>