<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
    :header="lang.title"
  >
    <PvDataTable
      showGridlines
      :value="filteredProducts"
      v-model:selection="selectedProduct"
      selectionMode="single"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollHeight="halfViewportHeight + 'px'"
      class="p-datatable-sm"
      :class="textTableClass"
      :loading="!isSharedProductReady"
      @dblclick="selectProduct"
    >
      <template #empty>
        <div class="w-full p-3" Style="text-align: center;">
          <label>{{ lang.tableEmpty }}</label>
        </div>
      </template>
      <template #header>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <PvInputText v-model="searchText" :placeholder="lang.keywordSearch" autofocus />
        </span>
      </template>
      <PvColumn selectionMode="single"></PvColumn>
      <PvColumn
        field="code"
        :header="lang.columnCode"
        headerClass="h-center"
      ></PvColumn>
      <PvColumn
        field="nameThai"
        :header="lang.columnNameTh"
        headerClass="h-center"
      ></PvColumn>
      <PvColumn
        field="nameEnglish"
        :header="lang.columnNameEn"
        headerClass="h-center"
      ></PvColumn>
    </PvDataTable>
    <template #footer>
      <PvButton
        :label="lang.close"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog"
      />
      <PvButton
        :label="lang.ok"
        icon="pi pi-check"
        autofocus
        @click="selectProduct"
      />
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useGetProductAP from "../../hooks/getProductAP.js";
export default {
  props: {
    shouldGetData: {
      type: Boolean,
      default: true,
    },
    search: {
      type: String
    }
  },
  emits: ["close", "product-selected", "update:search"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textTableClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.shared.productApModal };
      } else {
        return { ...lang.en.base, ...lang.en.shared.productApModal };
      }
    });
    const {
      filteredProducts,
      searchText,
      isSharedProductReady
    } = useGetProductAP();

    return {
      filteredProducts,
      isSharedProductReady,
      textHeaderClass,
      textTableClass,
      lang: mergeLang,
      searchText,
    };
  },
  data() {
    return {
      selectedProduct: null,
    };
  },
  methods: {
    selectProduct() {
      if (this.validateselectProduct()) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoDetailSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return;
      }
      this.$emit("product-selected", this.selectedProduct);
    },
    closeDialog() {
      this.$emit("close");
    }, 
    validateselectProduct() {
      if (!this.selectedProduct) {
        this.$swal({
          icon: "warning",
          title: this.lang.validateNoDetailSelect,
          confirmButtonText: this.lang.close,
          allowOutsideClick: false,
        });
        return true;
      }
    },
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
  },
  watch:{
    search(curValue){
      if(curValue){
        this.searchText = curValue
      }
    }
  }
};
</script>