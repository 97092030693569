export default {
	titleEdit: "Report Detail",
	signatureQuantity: "Signature Quantity",
	none: "None",
	onePerson: "1 Person",
	twoPerson: "2 Person",
	threePerson: "3 Person",
	firstSignator: "First Signator",
	secondSignator: "Second Signator",
	thirdSignator: "Third Signator",
	roleInDocument: "Role in the document.",
	positionAndName: "Position / Name",
	displayPosition: "Display position",
	errorDuplicateReport: "The Report is duplicate. You already add this report to report signature management.",
	errorReportNotFound: "Report not found.",
	errorQuantityNotMatchDetails: "The signatureQuantity not match details.",
};
