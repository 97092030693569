export default {
  productGuarantees(state) {
    return state.productGuarantee.productGuarantees;
  },
  productGuaranteeCurrentPage(state) {
    return state.productGuarantee.currentPage;
  },
  productGuaranteeNextPage(state) {
    return state.productGuarantee.currentPage + 1;
  },
  productGuaranteeTotalPage(state) {
    return state.productGuarantee.totalPage;
  },
  productGuaranteeIsLastPage(_, getters) {
    return getters.productGuaranteeCurrentPage === getters.productGuaranteeTotalPage && getters.productGuaranteeTotalPage !== 0;
  }
};
