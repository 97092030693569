import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export default {
    namespaced: true,
    state(){
        return {
            availableDepositByUnit: null,
            deposits: [],
            totalPage: 0,
            currentPage: 1,
            rows: 100,
            selectedDeposit: null,
            blob: null,
            depositReferenceDocument: [],
            unitExistDeposits: [],
        }
    },
    actions,
    getters,
    mutations
}