export default {
  closingEntrys(state) {
    return state.closingEntrys;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  nextPage(state) {
    return state.currentPage + 1;
  },
  isLastPage(_, getters) {
    return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
  },
  selectedClosingEntry(state) {
    return state.selectedClosingEntry;
  },
  closingVouchers(state) {
    return state.closingVouchers;
  },
};
