<template>
  <PvDialog
    :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
    :style="{ width: '40vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div
        class="flex justify-content-between align-items-center flex flex-wrap pt-3 pb-3"
      >
        <span class="font-lg">{{ lang.addEdit }}</span>
        <!-- <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        /> -->
      </div>
      <PvDivider class="mt-3 mb-3" />
    </template>

    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
          <label for="product">{{ lang.product }}</label>
          <product-invoice-auto-complete
            id="product"
            v-model="selectedProduct"
            class="inputfield w-full mt-1"
            @load-finish="productDataReady"
            :class="{ 'p-invalid': isProductError }"
            @item-select="selectProduct()"
          />
          <small v-if="isProductError" id="product-help" class="p-error">{{
            lang.validateProductSelect
          }}</small>
        </div>

        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
          <label for="remark">{{ lang.description }}</label>
          <PvInputText
            id="remark"
            type="text"
            class="inputfield w-full mt-1"
            v-model="remark"
          />
        </div>

        <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
          <label for="unitPrice">{{ lang.unitPrice }}</label>         
          <PvInputNumber
            id="unitPrice"
            class="mt-1 w-full"
            inputClass="inputfield w-full text-right"
            v-model="unitPrice"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            :min="0"
            :class="{ 'p-invalid': isUnitPriceError }"  
            @focus="$event.target.select()"          
            @input="unitPriceEnter($event)"
          /> 
          <small v-if="isUnitPriceError" id="unitPrice-help" class="p-error">{{
            lang.validateUnitPrice
          }}</small>
        </div>

        <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
          <label for="quantity">{{ lang.quantity }}</label>
          <PvInputNumber
            id="quantity"
            class="mt-1 w-full"
            inputClass="inputfield w-full text-right"
            v-model="quantity"
            :class="{ 'p-invalid': isQuantityError }"
            @focus="$event.target.select()"
            @input="quantityEnter($event)"
            mode="decimal"
            :min="0"
            :minFractionDigits="2"
            :maxFractionDigits="2"
          />
          <small v-if="isQuantityError" id="quantity-help" class="p-error">{{
            lang.validateQuantity
          }}</small>
        </div>

        <div class="lg:col-4 md:col-12 col-12 pl-0 pr-2">
          <label for="totalAmount">{{ lang.columnTotalAmount }}</label>
          <PvInputNumber
            id="totalAmount"
            class="mt-1 w-full"
            v-model="totalAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            @focus="$event.target.select()"
            inputClass="input-number-disable w-full text-right"
            disabled
          />
        </div>

        <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 flex-wrap">
          <label for="period">{{ lang.period }}</label>
          <base-period-auto-complete
            id="period"
            v-model="period"
            class="inputfield w-full mt-1"
            :class="{ 'p-invalid': isPeriodError }"
            :isOrderby="true"
            @item-select="isPeriodError = false"
          ></base-period-auto-complete>
          <small v-if="isPeriodError" id="period-help" class="p-error">
            {{ lang.validatePeriod }}
          </small>
        </div>
      </section>
    </transition>

    <template #footer>
      <div class="flex justify-content-between align-items-center m-3">
        <PvButton
          :label="lang.close"
          class="p-button-outlined p-button-secondary w-full"
          @click="closeDialog()"
        />
        <PvButton :label="lang.confirm" class="w-full" @click="confirm()" :disabled="isLockConfirm" />
      </div>
    </template>
  </PvDialog>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import ProductInvoiceAutoComplete from "../invoice/ProductInvoiceAutoComplete.vue";
export default {
  props: {
    data: {
      type: Object,
    },
    openDialog: {
      type: String,
    },
  },
  emits: ["close", "product-selected"],
  components: {
    ProductInvoiceAutoComplete,
  },
  setup() {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.invoice.listing,
          ...lang.th.ar.invoice.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.th.ar.invoice.listing,
          ...lang.th.ar.invoice.edit,
        };
      }
    });

    return {
      lang: mergeLang,      
      textFormClass,
    };
  },
  data() {
    return {
      selectedProduct: null,
      isProductDataReady: false,
      remark: "",
      unitPrice: 0,
      quantity: 1,
      //totalAmount: 0,
      period: "",

      isProductError: false,
      isUnitPriceError: false,
      isQuantityError: false,
      isPeriodError: false,
    };
  },
  created() {
    this.initiateData();
  },
  methods: {
    initiateData() {
      let date = new Date();
      this.selectedProduct = null;
      this.remark = "";
      this.unitPrice = 0;
      this.quantity = 1;
      //this.totalAmount = 0;
      this.period =
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        (date.getFullYear() + 543);

        
      this.isProductError = false;
      this.isUnitPriceError = false;
      this.isQuantityError = false;
      this.isPeriodError = false;
      this.isProductDataReady = false;
    },
    setData() {
      this.initiateData();
      if (this.data) {
        this.selectedProduct = this.sharedProductArs.find(
          (product) => product.id === this.data.productId
        );
        this.remark = this.data.remark;
        this.unitPrice = this.data.unitPrice;
        this.quantity = this.data.quantity;
        // this.totalAmount = this.data.totalAmount;
        this.period = this.data.period;
      }
    },    
    selectProduct() {
      this.unitPrice = this.selectedProduct.price;
      this.isProductError = false;
    },
    closeDialog() {
      this.$emit("close");
    },
    confirm() {
      if (this.validateData()) {
        return;
      }

      let payload = {
        id: this.data ? this.data.id : null,
        headerId: null,
        productId: this.selectedProduct ? this.selectedProduct.id : "",
        productCode: this.selectedProduct ? this.selectedProduct.code : "",
        productName: this.selectedProduct ? this.selectedProduct.nameThai : "",
        remark: this.remark,
        unitPrice: this.unitPrice,
        quantity: this.quantity,
        totalAmount: this.totalAmount,
        period: this.period.name ? this.period.name : this.period,
      };
      this.$emit("product-selected", payload);
      this.$emit("close");
    },
    validateData() {
      if (!this.selectedProduct) {
        this.isProductError = true;
        return true;
      }
      if (this.unitPrice < 0) {
        this.isUnitPriceError = true;
        return true;
      }
      if (this.quantity <= 0) {
        this.isQuantityError = true;
        return true;
      }
      if (this.period == "") {
        this.isPeriodError = true;
        return true;
      }
      return false;
    },
    unitPriceEnter($event){
      this.unitPrice = $event.value;
      this.isUnitPriceError = false; 
    },
    quantityEnter($event){
      this.quantity = $event.value;
      this.isQuantityError = false;
    },
    productDataReady() {
      this.isProductDataReady = true;
    },
    checkShouldSetData() {
      if(this.sharedProductArs && this.isProductDataReady){
        this.setData();
      }
    },
  },
  computed: {
    sharedProductArs() {
      return this.$store.getters["shared/productArProducts"];
    },
    totalAmount() {
      return this.unitPrice * this.quantity;
    },
    isLockConfirm(){
      if (!this.selectedProduct) {
        return true;
      }
      if (this.unitPrice < 0) {
        return true;
      }
      if (this.quantity <= 0) {
        return true;
      }
      if (this.period == "") {
        return true;
      }

      return false;
    },
  },
  watch: {    
    openDialog() {
      this.checkShouldSetData();
    },
    isProductDataReady(){
      this.checkShouldSetData();
    }
    
  },
};
</script>

<style></style>
