export default {
	reportMenuDetails(state) {
		return state.reportMenuDetails;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	selectedReportMenuDeatil(state) {
		return state.selectedReportMenuDeatil;
	},
	isLastPage(state) {
		return state.currentPage === state.totalPage && state.totalPage !== 0;
	},
	reportMenuDetailDropDown(state) {
		return state.reportMenuDetailDropDown;
	},
	currentPageDropDown(state) {
		return state.currentPageDropDown;
	},
	totalPageDropDown(state) {
		return state.totalPageDropDown;
	},
	isNextPageDropDown(state) {
		return state.currentPageDropDown + 1;
	},
	isLastPageDropDown(_,getters) {
		return getters.currentPageDropDown === getters.totalPageDropDown && getters.totalPageDropDown !== 0;
	},
};
