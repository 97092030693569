export default {
  async getAccountCharts(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/V2/Accounting/AccountChart";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.projectId) {
      url = url + "&projectId=" + payload.projectId;
    }

    if (payload.code) {
      url = url + "&code=" + payload.code;
    }

    if (payload.name) {
      url = url + "&name=" + payload.name;
    }

    if (payload.type) {
      url = url + "&type=" + payload.type;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get account chart"
      );
      throw error;
    }

    // console.log("getAccountCharts",responseData);

    context.commit("setAccountCharts", responseData);
  },
  async getAllAccountChart(context, payload) {
    while (!context.getters.accountChartIsLastPage) {
      const payloadAccountChart = {
        page: context.getters.accountChartNextPage || 1,
        projectId : payload.projectId,
        code: payload.code,
        name: payload.name
      };
      await context.dispatch("getAccountCharts", payloadAccountChart);
    }
  },
  resetAccountCharts(context) {
    context.commit("resetAccountCharts");
  },
  async getAccountCategory(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/V2/Accounting/AccountChart";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.projectId) {
      url = url + "&projectId=" + payload.projectId;
    }

    if (payload.type) {
      url = url + "&type=" + payload.type;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get account category"
      );
      throw error;
    }

    // console.log("getAccountCategory",responseData);

    context.commit("setAccountCategory", responseData);
  },
  async getAllAccountCategory(context) {
    while (!context.getters.accountCategoryIsLastPage) {
      const payload = {
        page: context.getters.accountCategoryNextPage || 1,
        projectId: context.rootGetters.selectedProject.id,
        type: "category"
      };
      await context.dispatch("getAccountCategory", payload);
    }
  },
  async getAccountChartById(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Accounting/AccountChart/${payload}`;
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get accounting chart by id."
      );
      throw error;
    }
    // console.log("getAccountChartById",responseData);

    context.commit("setSelectedAccountChart", responseData.data);
  },
  resetSelectedAccountChart(context) {
    context.commit("setSelectedAccountChart", null);
  },
  async createAccountChart(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Accounting/AccountChart/Add";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || "Failed to add account chart");
      throw error;
    }
    // console.log(responseData);

    context.commit("setSelectedAccountChart", responseData);
  },
  async updateAccountChart(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Accounting/AccountChart/Update";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || "Failed to update account chart");
      throw error;
    }
    // console.log(responseData);

    context.commit("setSelectedAccountChart", responseData);
  },
  async deleteAccountChart(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + `/Accounting/AccountChart/Delete/${payload}`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "Delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || "Failed to delete account chart");
      throw error;
    }
    // console.log(responseData);

    context.commit("setSelectedAccountChart", responseData);
  },
};
