export default {
	createTitle: "สร้างทะเบียนรายจ่าย",
	editTitle: "แก้ไขทะเบียนรายจ่าย",	
	productApDetail: "รายละเอียดทะเบียนรายจ่าย",
	code: "รหัสรายจ่าย",
	nameThai: "ชื่อ (ภาษาไทย)",
	nameEnglish: "ชื่อ (ภาษาอังกฤษ)",
	price: "ราคา (บาท)",
	withholding: "หัก ณ ที่จ่าย (%)",
	unit: "หน่วยนับ",
	type: "ประเภท",
	isActive : "สถานะใช้งาน",
	isLock: "ล็อกข้อมูล",
	editTitleAccount: "การบันทึกบัญชี",	
	accountCash: "บัญชีซื้อสด (DR)",
	accountCredit:"บัญชีซื้อเชื่อ (DR)",
	accountDebit:"บัญชีซื้อค้างจ่าย (CR)",
	createNewProductAp: "สร้างรายการรายจ่ายใหม่",
	askForCreateNewProductAp: "หากคุณสร้างรายการรายจ่ายใหม่ระบบจะไม่บันทึกการแก้ไข",
	askForCreateNewProductApConfirm: "คุณยืนยันที่จะสร้างรายการรายจ่ายใหม่ใช่หรือไม่?",
	createProductApSuccess: "คุณสร้างรายการรายจ่ายสำเร็จ",
	editProductApSuccess: "คุณบันทึกการแก้ไขรายการรายจ่ายสำเร็จ",
	cancelProductApSuccess: "คุณยกเลิกรายการรายจ่าย :code สำเร็จ", 
	validateCode: "กรุณาระบุ รหัสรายจ่าย",
	validateNameThai: "กรุณาระบุ ชื่อ (ภาษาไทย)",
	validateNameEnglish: "กรุณาระบุ ชื่อ (ภาษาอังกฤษ)",
	validateProductUnit: "กรุณาเลือกหน่วยนับ",
	deleteProductAp: "ลบข้อมูล",
	settingAccountPq: "การบันทึกบัญชีใบตั้งเบิก (ตั้งค่าใช้จ่าย)",
	accountPqDebit: "ผังบัญชี Debit",
	accountPqCredit: "ผังบัญชี Credit",
	labelAcountPqDebit: "ย้อนหลัง/ก่อนหน้า, ปัจจุบัน, ถัดไป/ล่วงหน้า",
	labelAcountPqCreditPrevious: "ย้อนหลัง/ก่อนหน้า",
	labelAcountPqCreditCurrent: "ปัจจุบัน",
	labelAcountPqCreditNext: "ถัดไป/ล่วงหน้า",
};
