<template>
    <!-- v-model="selectedUnit" -->
    <PvAutoComplete
      :dropdown="true"
      :suggestions="filteredBookBankAr"
      field="codeAndName"
      @complete="searchBookBankAr"
      @dropdown-click="filteredBookBankAr"
      forceSelection
    />
  </template>
  
  <script>
  import useGetBookBankAr from "../../hooks/getBookBankAr";
  export default {
    setup() {
      const {
        filteredBookBankAr,
        isSharedBookBankArReady,
        searchBookBankAr,
      } = useGetBookBankAr();
  
      return {
        filteredBookBankAr,
        isSharedBookBankArReady,
        searchBookBankAr,
      };
    },
    emits: ["load-finish"],
    watch: {
        isSharedBookBankArReady(curValue) {
        if (curValue) {
          this.$emit("load-finish");
        }
      },
    },
  };
  </script>
  