export default {
  nationalityNationalities(state) {
    return state.nationality.nationalities;
  },
  nationalityCurrentPage(state) {
    return state.nationality.currentPage;
  },
  nationalityNextPage(state) {
    return state.nationality.currentPage + 1;
  },
  nationalityTotalPage(state) {
    return state.nationality.totalPage;
  },
  nationalityIsLastPage(_, getters) {
    return getters.nationalityCurrentPage === getters.nationalityTotalPage && getters.nationalityTotalPage !== 0;
  }
};
