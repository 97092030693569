export default {
    debtFreePrints(state) {
      return state.debtFreePrints;
    },
    totalPage(state) {
      return state.totalPage;
    },
    currentPage(state) {
      return state.currentPage;
    },
    rows(state) {
      return state.rows;
    },
    blob(state) {
      return state.blob;
    },
};
  