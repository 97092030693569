export default {
  accountCharts(state) {
    return state.accountCharts;
  },
  selectedAccountChart(state) {
    return state.selectedAccountChart;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
  accountChartNextPage(state) {
    return state.currentPage + 1;
  },
  accountChartIsLastPage(_, getters) {
    return getters.currentPage === getters.totalPage && getters.totalPage !== 0;
  },
  accountCategory(state) {
    return state.accountCategory;
  },
  currentPageAccountCategory(state) {
    return state.currentPageAccountCategory;
  },
  totalPageAccountCategory(state) {
    return state.currentPageAccountCategory;
  },
  accountCategoryIsLastPage(_, getters) {
    return getters.currentPageAccountCategory === getters.totalPageAccountCategory && getters.totalPageAccountCategory !== 0;
  },
  accountCategoryNextPage(state) {
    return state.currentPageAccountCategory + 1;
  },
};
