<template>
  <base-toolbar
    :title="lang.autoCreateMeter"
    :dataButtons="dataButtons"
    @goBack="goBack()"
    @confirmAutoCreate="confirmAutoCreate()"
  ></base-toolbar>
  <base-dialog
    v-model:visible="showConfirmDialog"
    :titleConfirm="confirmTitle"
    :wordingConfirm="confirmContent"
    :dataButtons="isConfirm ? dataConfirmButtons : dataAlertButtons"
    @confirm="autoCreateMeter()"
    @close="showConfirmDialog = false"
  >
    <template #dialogBody>
      <div v-if="isConfirm" class="w-full">
        <PvCalendar
          id="receiptDate"
          v-model="activeDate"
          class="inputfield w-full mt-1"
          showIcon
          dateFormat="dd/mm/yy"
        />
      </div>
    </template>
  </base-dialog>
  <base-container>
    <div class="surface-card shadow-2 py-2 px-5 border-round my-5">
      <div
        class="flex justify-content-between align-items-center"
        style="padding-top: 20px"
      >
        <h2 :class="textHeaderClass" class="my-0">{{ lang.title }}</h2>
      </div>
      <PvDivider></PvDivider>

      <PvDataTable
        :value="meterForAutoCreates"
        dataKey="id"
        v-model:selection="selectedMeterData"
        selectionMode="single"
        responsiveLayout="scroll"
        :class="textTableClass"
        class="p-datatable-xs"
        :loading="isLoading"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100]"
        :currentPageReportTemplate="lang.page + ` {currentPage} / {totalPages}`"
        :paginator="true"
        :rows="rowsPerPage"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn
          field="unitCode"
          :header="lang.columnUnitCode"
          headerClass="h-left"
          bodyClass="text-left"
          style="height: 56px"
        />
        <PvColumn
          field="unitAddressNo"
          :header="lang.columnAddressNo"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="productCode"
          :header="lang.columnProductCode"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="productName"
          :header="lang.columnProductName"
          headerClass="h-left"
          bodyClass="text-left"
        />
        <PvColumn
          field="status"
          :header="lang.columnStatus"
          headerClass="h-center"
          bodyClass="text-center"
        >
          <template #body="slotProps">
            <base-tag
              v-if="!slotProps.data.status"
              :Caption="lang.waiting"
              Class="warning"
            />
            <base-tag
              v-if="slotProps.data.status == 'Y'"
              :Caption="lang.created"
              Class="success"
            />
            <base-tag
              v-if="slotProps.data.status == 'N'"
              :Caption="lang.error"
              Class="error"
            />
          </template>
        </PvColumn>
      </PvDataTable>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
export default {
  props: ["meterType", "projectCode"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textTableClass } = useTextStyle();
    const { formatDateToISOString } = useFormat();
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.master.meter.autoCreate };
      } else {
        return { ...lang.en.base, ...lang.en.master.meter.autoCreate };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textTableClass,
      formatDateToISOString,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
    };
  },

  data() {
    return {
      isLoading: false,
      meterForAutoCreates: [],
      selectedMeterData: [],
      rowsPerPage: 10,
      confirmTitle: "",
      confirmContent: "",
      confirmClassTitle: "",
      isConfirm: false,
      showConfirmDialog: false,
      activeDate: "",
      isDisable: false,
    };
  },
  async mounted() {
    await this.initiateData();
  },
  methods: {
    goBack() {
      this.$router.push({name: "master-meter", params:{ meterType: this.meterType }});
    },
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    async initiateData() {
      this.activeDate = new Date();
      this.meterForAutoCreates = this.meterForAutoCreateByProject;
    },
    async confirmAutoCreate() {
      this.openAlertDialog({
        severity: "error",
        title: this.lang.activeDate,
        content: "",
        isConfirm: true,
      });
    },
    async autoCreateMeter() {
      // console.log("autoCreateMeter", this.selectedActiveDate);
      await this.validateData();
      this.showConfirmDialog = false;
      for (const element of this.meterForAutoCreates) {
        this.setLoading(true);
        if (!element.status || element.status == "N") {
          let payload = {
            type: "save",
            activeDate: this.selectedActiveDate,
            remark: "",
            isActinve: "A",
            productId: element.productId,
            meterCode: element.unitCode,
            roomId: element.unitId,
            projectId: this.selectedProject.id,
            meterType: this.meterType,
            id: "create",
          };
          // console.log(payload);
          let status = await this.save(payload);
          if (status === "success") {
            element.status = "Y";
          } else {
            this.setLoading(false);
            element.status = "N";
            this.openAlertDialog({
              severity: "error",
              title: this.lang.notificationDialogHeader,
              content: status,
              isConfirm: false,
            });
            return;
          }
        }
      }
      this.isDisable = true;
      this.setLoading(false);
    },
    async save(payload) {
      try {
        await this.$store.dispatch("meter/addMeter", payload);
        return "success";
      } catch (error) {
        let message = error.message;
        if (error.code == "101") {
          message = this.lang.errorMeterDuplicate;
        } else if (error.code == "111") {
          message = this.lang.validateRoleInsert;
        } else if (error.code == "112") {
          message = this.lang.validateRoleUpdate;
        } else if (error.code == "127") {
          message = this.lang.errorMeterActiveDate;
        } else if (error.code == "130") {
          message = this.lang.errorProductType;
        }
        return message;
      }
    },
    openAlertDialog(dataConfig) {
      if (dataConfig.severity === "") {
        this.confirmClassTitle = "";
      } else if (dataConfig.severity === "success") {
        this.confirmClassTitle = "p-text-success";
      } else if (dataConfig.severity === "error") {
        this.confirmClassTitle = "p-error";
      }

      this.isConfirm = dataConfig.isConfirm;
      this.confirmTitle = dataConfig.title;
      this.confirmContent = dataConfig.content;
      this.showConfirmDialog = true;
    },
    async validateData() {
      if (this.isSharedRoleByMenuReady === true && this.roleByMenu.insert === "N") {
        this.openDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateRoleInsert,
          isConfirm: false,
        });
        return true;
      }
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    meterForAutoCreateByProject() {
      return this.$store.getters["meter/meterForAutoCreateByProject"].filter(
        (meterForAutoCreate) => {
          return meterForAutoCreate.productCode
            .toLowerCase()
            .includes(this.meterType.toLowerCase());
        }
      );
    },
    totalPage() {
      return this.$store.getters["meter/totalPage"];
    },
    currentPage() {
      return this.$store.getters["meter/currentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    selectedActiveDate() {
      let activeDate = null;
      if (this.activeDate) {
        activeDate = this.formatDateToISOString(this.activeDate);
      } else {
        activeDate = this.formatDateToISOString(new Date());
      }
      return activeDate;
    },
    dataButtons() {
      return [
        {
          id: "confirmAutoCreate",
          caption: this.lang.confirmAutoCreate,
          class: "mr-2",
          icon: "pi-check",
          iconColor: "white",
          action: "confirmAutoCreate",
          isShow: true,
          isDisable: this.isDisable,
        },
      ];
    },
    dataAlertButtons() {
      let dataButtons = [
        {
          id: "close",
          caption: this.lang.buttonDialogSuccess,
          class: "w-full",
          action: "close",
        },
      ];
      return dataButtons;
    },
    dataConfirmButtons() {
      let dataButtons = [
        {
          id: "close",
          caption: this.lang.buttonDialogCancel,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
        {
          id: "confirm",
          caption: this.lang.buttonDialogConfirm,
          class: "w-full",
          action: "confirm",
        },
      ];
      return dataButtons;
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmPRO");
    },
  },
};
</script>
