<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
  >
    <path
      d="M16.5019 22.0042C14.0158 22.0042 12 19.9883 12 17.5023C12 15.0163 14.0158 13.0004 16.5019 13.0004C18.9889 13.0004 21.0037 15.0163 21.0037 17.5023C21.0037 19.9883 18.9889 22.0042 16.5019 22.0042"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.0025 9.99916V7.82626C18.0025 7.29604 17.7914 6.78683 17.4162 6.41167L14.5871 3.58249C14.2119 3.20733 13.7027 2.99625 13.1725 2.99625H5.99749C4.89203 2.99625 3.99666 3.89162 3.99666 4.99708V19.0029C3.99666 20.1084 4.89203 21.0037 5.99749 21.0037H9.99916"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.0025 7.99833H14.0008C13.4486 7.99833 13.0004 7.55014 13.0004 6.99791V2.99625"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.1596 16.674L16.0867 18.7468L14.8442 17.5023"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "primary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
