export default {
	title: "ภาษีหัก ณ ที่จ่าย",
	selectTaxBook: "เลือกเล่มภาษี",
	type: "ประเภท",
	book: "เล่มที่",
	customerSearch: "ลูกค้า/ผู้จัดจำหน่าย",
	dateWhtStart: "วันที่จ่ายเริ่มต้น",
	dateWhtEnd: "วันที่จ่ายสิ้นสุด",
	columnDate: "วันที่จ่าย",
	columnDueDate: "วันที่นำส่งภาษี",
	columnCode: "เลขที่",
	columnBook: "เล่มที่",
	columnType: "ประเภท",
	columnPrice: "จำนวนเงินที่จ่าย",
	columnIsCancel: "ยกเลิก",
	columnVoucherCode: "ใบสำคัญ",
	changeBook: "เปลี่ยนเล่มเอกสาร",
	columnTaxAmount: "ภาษีที่หักไว้",
	unLink: "ยกเลิกความสัมพันธ์ไม่สำเร็จ เนื่องจากมีเอกสารอ้างถึง",
	importDocument: "นำเข้าเอกสาร",
	confirmDocument: "ยืนยันผูกเอกสาร",
	removeDocument: "นำเอกสารออกทั้งหมด",
	withholdingTaxPrintLot: "พิมพ์รายการภาษีหัก ณ ที่จ่ายเป็นชุด",
	validateDateStart: "กรุณาเลือกวันที่จ่ายเริ่มต้น",
	validateDateEnd: "กรุณาเลือกวันที่จ่ายสิ้นสุด",
	validateCodeStart: "กรุณาระบุเลขที่เริ่มต้น",
	validateCodeEnd: "กรุณาระบุเลขที่สิ้นสุด"
};
