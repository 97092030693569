export default {
	setPurchaseReceipts(state, payload) {
		// state.project.length = 0;
		state.purchaseReceipts = payload.data;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
	setSelectedPurchaseReceipt(state, payload) {
		state.selectedPurchaseReceipt = payload;
	},
	setBlob(state, payload) {
		state.blob = payload;
	},
	resetPurchaseReceipts(state) {
		state.purchaseReceipts = [];
	},
	resetSelectedPurchaseReceipt(state) {
		state.selectedPurchaseReceipt = null;
	},
	setPurchaseInvoiceFroImport(state, payload) {
		state.purchaseInvoices = payload.data;
		state.totalPageInvoice = payload.page.pageCount;
		state.currentPageInvoice = payload.page.currentPage;
	},
	resetPurchaseInvoiceFroImport(state) {
		state.purchaseInvoice = [];
	},
	setPurchaseReceiptImport(state, payload) {
		state.purchaseReceiptImport = payload;
	},
	resetPurchaseReceiptImport(state) {
		state.purchaseReceiptImport = [];
	},
	setPurchaseReceiptToGl(state, payload) {
		state.purchaseReceiptsToGl = payload;
	},
	setPurchaseReceiptsVoucher(state, payload) {
		// state.project.length = 0;
		state.purchaseReceiptsVoucher = payload.data;
		state.totalPageVoucher = payload.page.pageCount;
		state.currentPageVoucher = payload.page.currentPage;
	},
	resetPurchaseReceiptsVoucher(state) {
		state.purchaseReceiptsVoucher = [];
	},
    setPurchaseReceiptReference(state, payload){
        state.purchaseReceiptReference = payload
    }, 
};
