export default {
  createInvoice:"สร้างใบแจ้งหนี้",
  editInvoice:"แก้ไขใบแจ้งหนี้",
  descriptionInvoice:"รายละเอียดใบแจ้งหนี้",
  askForProductChange: "คุณยืนยันที่จะแก้ไขรหัสรายรับใช่หรือไม่?",
  askForSelectProduct: "ไม่พบรายรับรหัส : :code ต้องการค้นหาหรือไม่?",
  askForCreateNewDocument: "คุณยืนยันที่จะแก้ไข เอกสารใช่หรือไม่?",
  askForNonExistPeriod: "งวดที่ระบุ :period ไม่ถูกต้อง ระบบจะใช้งวดในบรรทัดแรกแทน หากไม่มีจะใช้งวดปัจจุบัน",
  date: "วันที่ออกเอกสาร",
  titleDetail: "รายการใช้จ่าย",
  addProduct:"เพิ่มรายการ",  
	totalAmount: "ยอดรวมทั้งหมด",
	paidAmount: "ยอดชำระ",
  printInvoice: "พิมพ์รายละเอียดใบแจ้งหนี้",
  cancelDocument: "ยกเลิกเอกสาร",
  addEdit: "เพิ่ม/ แก้ไขรายการ",
  product: "รายรับ*",
  unitPrice: "ราคา*",
  quantity: "จำนวน*",
  period: "งวดเดือน* (งวดเดือนเริ่มต้นของการระบุจำนวนงวด)",
	validateProductSelect: "กรุณาเลือกรายรับ",
  validateUnitPrice:"ราคา ต้องมากกว่า 0",
  validateQuantity:"จำนวน ต้องมากกว่า 0",
	validatePeriod: "กรุณาเลือกงวด",
	confirmCancel: "ยืนยันยกเลิก",
  generateProduct:"สร้างรายการเพิ่มตามจำนวนที่ระบุ",
  createItem: "สร้างรายการ",
	validateInactiveUnitSelect: "ห้องนี้ปิดการใช้งาน",
	validateDetailSelect: "กรุณาเลือกรายการ",
  validateTotalAmount: "ยอดรวมทั้งหมด ต้องมากกว่า 0",
  validateCustomerId: "ข้อมูลลูกค้าไม่ถูกต้อง กรุณาติดต่อผู้ดูระบบ",
  validateEmployeeId: "ข้อมูลพักงานไม่ถูกต้อง กรุณาติดต่อผู้ดูระบบ",
  askForCancelInvoiceDocument: "คุณต้องการยกเลิกเอกสารใบแจ้งหนี้หมายเลข :code หรือไม่?",
  createNewInvoice: "สร้างใบแจ้งหนี้ใหม่",
  askForCreateNewInvoice: "หากคุณสร้างใบแจ้งหนี้ใหม่ระบบจะไม่บันทึกการแก้ไข",
  askForCreateNewInvoiceConfirm: "คุณยืนยันที่จะสร้างใบแจ้งหนี้ใหม่ใช่หรือไม่?",
  createInvoiceSuccess: "คุณสร้างรายการใบแจ้งหนี้สำเร็จ",
  editInvoiceSuccess: "คุณบันทึกการแก้ไขรายการใบแจ้งหนี้สำเร็จ",
  cancelInvoiceSuccess: "คุณยกเลิกเอกสารใบแจ้งหนี้ :code สำเร็จ",  
	dueDate: "วันที่ครบกำหนด",
  tableEmpty: "ไม่มีรายการใช้จ่าย",
  showRemark: "แสดงข้อมูลหมายเหตุ",
  hideRemark: "ซ่อนข้อมูลหมายเหตุ",

  validateInvoiceIsPaid: "เอกสารนี้ถูกชำระแล้ว",
  validateDate: "กรุณาเลือกวันที่ออกเอกสาร",
  validateDueDate: "กรุณาเลือกวันที่ครบกำหนด",
  validateDueDateIsInvalid: "วันที่ครบกำหนดต้องไม่น้อยกว่าวันที่เอกสาร",
};
