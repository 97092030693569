<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 13.7617L12.194 17.9277C12.419 19.2807 11.376 20.5117 10.005 20.5117V20.5117C8.92 20.5117 7.994 19.7277 7.816 18.6567L7 13.7617"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.75 13.7617H5.875C4.011 13.7617 2.5 12.2507 2.5 10.3867V10.3867C2.5 8.52272 4.011 7.01172 5.875 7.01172H13.75"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.75 7.01187L18.751 3.67787C19.499 3.17987 20.5 3.71587 20.5 4.61487V16.1599C20.5 17.0589 19.499 17.5939 18.751 17.0959L13.75 13.7619"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.75 13.7598V7.00977"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 14,
    },
    height: {
      type: [Number, String],
      default: 14,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -4px !important;
  margin-right: 4px;
}
</style>
