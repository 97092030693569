export default({    
	editProject:"Project",
	projectTitleGeneral: "Project",
	projectTitleAddress: "Address",
	projectTitleRegistered: "Registration",
	projectTitlePerson: "Person",
	projectTitleAdvertise: "Advertise",
	projectCode:"ProjectCode",
	projectType:"ProjectType",
	projectNameTh:"ProjectName(Thai)",
	projectNameEn:"ProjectName(English)",
	projectTel:"Tel",
	projectHouseTel:"HouseTel",
	projectFax:"Fax",
	projectTaxId:"TaxId",
	projectComNickName:"ComNickName",
	projectComShortCode:"ComShortCode",
    
    //ข้อมูลที่อยู่
	tabAddressProject: "AddressProject ",
	tabAddressForFinancialDocuments: "AddressForFinancialDocuments ",
	tabAddressParcel: "AddressFlash ",
	address1: "Address",	
	addressTh: "Address (Thai)",	
	addressEn: "Address (English)",
	address2: "Area, District",
	address3: "province, postcode",

	parcelName: "Contact Name",
	parcelTel: "Tel",
	parcelEmail: "Email",
	
    //ข้อมูลบุคคล
	projectManagerNameTh: "ProjectManagerName (Thai)",
	projectManagerNameEn: "ProjectManagerName (English)",
	projectManagerEmail: "ProjectManagerEmail",
	debtFreeAuthEmail: "DebtFreeAuthEmail",
	communityManagerNameTh: "CommunityManagerName (Thai)",
	communityManagerNameEn: "CommunityManagerName (English)",
	accountantNameTh: "AccountantName (Thai)",
	accountantNameEn: "AccountantName (English)",
	accManagerName: "AccManagerName",
	communityManageBy: "CommunityManageBy",

	//ประชาสัมพันธ์ - โฆษณา
	tabAdvertiseInvoice: "Invoice",
	tabAdvertiseNotice: "Notice",
	tabAdvertiseReceipt: "Receipt",
	tabAdvertiseInvoiceReceipt: "Advertise Invoice/Receipt",
	advertiseInvoiceTh: "AdvertiseInvoice (Thai)",
	advertiseInvoiceEn: "AdvertiseInvoice (English)",
	advertiseNoticeTh: "AdvertiseNotice (Thai)",
	advertiseNoticeEn: "AdvertiseNotice (English)",
	advertiseReceiptTh: "AdvertiseReceipt (Thai)",
	advertiseReceiptEn: "AdvertiseReceipt (English)",
	invoiceFooter: "Rnvoice Footer",
	receiptFooter: "Receipt Footer",

    //ข้อมูลการจดทะเบียน
	juristicId: "JuristicId",
	juristicDate: "JuristicDate",
	averageWaterMeter: "AverageWaterMeter",
	capitalFirst: "CapitalFirst",
	capitalBalance: "CapitalBalance",
	ownershipRatio: "ownershipRatio",
	projectArea: "ProjectArea",
	financialStatements: "FinancialStatements",
	budget: "Budget",
	numberofRoom: "NumberofRoom",
	numberofStores: "NumberofStores",
	numberofCars: "NumberofCars",
	
	province: "Province",
	district: "Area/District",
	subarea: "Sub-area/Sub-district",
	postcode: "Post Code",
	selectAddress: "Select Address",
	
	FinanceDateStart: "From FinanceDate",
	FinanceDateEnd: "To FinanceDate",
	BudgetDateStart: "From BudgetDate",
	BudgetDateEnd:"To BudgetDate",
	
	validateProjectCode: "Enter ProjectCode",
	validateProjectType: "Select ProjectType",
	validateProjectName: "Enter ProjectName",
	validateProjectNickName: "Enter ProjectNickName",
	validateProjectShortCode: "Enter ShortCode",
	validateProjectTel: "Enter Tel",
	validateProjectFax: "Enter Fax",
	validateProjectHouseTel: "Enter HouseTel",
	
	validateParcelAddress1: "Please Enter Address",
	validateParcelAddress2: "Enter Area, District",
	validateParcelAddress3: "Enter Province, Post Code",
	validateParcelName: "Enter Contact Name",
	validateParcelEmail: "Enter Email",
	
	validateProjectManagerNameTh: "Enter ProjectManagerName (Thai)",
	validateProjectManagerNameEn: "Enter ProjectManagerName (English)",
	validateProjectManagerEmail: "Enter ProjectManagerEmail",
	validateDebtFreeAuthEmail: "Enter DebtFreeAuthEmail",
	validateCommunityManagerNameTh: "Enter CommunityManagerName (Thai)",
	validateCommunityManagerNameEn: "Enter CommunityManagerName (English)",
	validateAccountantNameTh: "Enter AccountantName (Thai)",
	validateAccountantNameEn: "Enter AccountantName (English)",
	validateAccManagerName: "Enter AccManagerName",
	validateCommunityManageBy: "Enter CommunityManageBy",
	
	validateAdvertiseInvoiceTh: "Enter AdvertiseInvoice (Thai)",
	validateAdvertiseInvoiceEn: "Enter AdvertiseInvoice (English)",
	validateAdvertiseNoticeTh: "Enter AdvertiseNotice (Thai)",
	validateAdvertiseNoticeEn: "Enter AdvertiseNotice (English)",
	validateAdvertiseReceiptTh: "Enter AdvertiseReceipt (Thai)",
	validateAdvertiseReceiptEn: "Enter AdvertiseReceipt (English)",
	validateInvoiceFooter: "Enter InvoiceFooter",
	validateReceiptFooter: "Enter ReceiptFooter",

	validateJuristicId: "Enter JuristicId",
	validateAverageWaterMeter: "Enter AverageWaterMeter",
	validateOwnershipRatio: "Enter OwnershipRatio",
	validateProjectArea: "Enter ProjectArea",
	validateNumberofRoom: "Enter NumberofRoom",
	validateNumberofStores: "Enter NumberofStores",
	validateNumberofCars: "Enter NumberofCars",
	validateNumber: "Please enter numbers only.", 	
	validateDate: "Select date",
	titleEdit: "Edit Data",	
	validateProvince: "Enter Province",
	searchText: "Enter Search",	
	validateTaxNo: "Enter TaxNo example 0-0000-00000-00-0",
	projectTypes: [
        { name: "Condominium", code: "Condominium" },
        { name: "Dormitory/Apartment", code: "Dormitory/Apartment" },
        { name: "Village", code: "Village" },
        { name: "Rental Space/Community mall", code: "Rental Space/Community mall" },
        { name: "Baan Ua-athon", code: "Baan Ua-athon" },
      ],
	validateTel:"Please enter numbers only. and Special Character +,(),-",
	validateEmailError: "Please enter valid format Email",

	searchaddress: "SearchAddress",
	tooltipAddressParcel: "For receiving parcels",
	tooltipTaxNo:"Type tax ID issued by the Department of Business Development, 13 digits ex. 0-0000-00000-00-0.",
	textTaxNo:"Required to issue receipt.",
	editAdvertisement:"Data Advertisement",
	suffix: "Suffix (Bill payment)",
	suffixPlaceholder: "Enter suffix bill payment example 00",
	validateSuffixError: "Please enter suffix bill payment 2 digits example 00",


})

