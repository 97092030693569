import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export default {
    namespaced: true,
    state(){
        return {
            invoiceGenerateQueueStatus: null,
            invoiceReadyGenerateBill: null,
            selectedinvoiceGenerateQueue: null,
            invoices: [],
            selectedInvoice: null,
            invoicesTotalPage: 0,
            invoicesCurrentPage: 1,
            invoicesRows: 100,
            invoiceIsCanceled: null,
            generatedInvoice: null,
            isUsedPrepaid: false,
            isUsedDeposit: false,
        }
    },
    actions,
    getters,
    mutations
}