export default {
    setInvoice(state, payload){
        // state.invoices = payload.data
        // state.totalPage = payload.page.pageCount
        // state.currentPage = payload.page.currentPage

        let invoices = [...state.invoices];
        for (const invoice of payload.invoices) {
            if(invoices.findIndex(c => c.id === invoice.id) < 0){
                invoices.push(invoice);
            }
        }
        state.invoices = invoices;
        state.currentPage = payload.currentPage;
        state.totalPage = payload.totalPage;
    },
    resetInvoiceList(state) {
        state.invoices = [];
        state.currentPage = 0;
        state.totalPage = 0;
    },
    setSelectedInvoice(state, payload){
        state.selectedInvoice = payload
    },
    getInvoiceSelected(state, payload){
        // state.selectedInvoice = payload.data
        // state.totalListPage = payload.page.pageCount
        // state.currentPage = payload.page.currentPage

        let invoiceSelected = [...state.selectedInvoice];
        for (const invoice of payload.invoices) {
            if(invoiceSelected.findIndex(c => c.id === invoice.id) < 0){
                invoiceSelected.push(invoice);
            }
        }
        state.selectedInvoice = invoiceSelected;
        state.currentPage = payload.currentPage;
        state.totalPage = payload.totalPage;
    },
    
    resetInvoiceSelectedList(state) {
        state.selectedInvoice = [];
        state.currentPage = 0;
        state.totalPage = 0;
    },
    setInvoiceToGl(state, payload){
        state.invoiceToGl = payload.data
    }
};