export default {
  setRoleByBook(state, payload) {
    let roleByBooks = [...state.roleByBook.roleByBooks];
    for (const roleByBook of payload.roleByBooks) {
      if (roleByBooks.findIndex((c) => c.documentBookId === roleByBook.documentBookId) < 0) {
        roleByBooks.push(roleByBook);
      }
    }
    state.roleByBook.roleByBooks = roleByBooks;
    state.roleByBook.currentPage = payload.currentPage;
    state.roleByBook.totalPage = payload.totalPage;
  },
  resetRoleByBook(state) {
    const roleByBook = {
      roleByBooks: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.roleByBook = roleByBook;
  },
};