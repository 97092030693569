<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.0008 14.901V20.6038C14.0018 21.377 15.5683 22.0042 17.5018 22.0042C19.4353 22.0042 21.0017 21.377 21.0027 20.6038V14.901"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M14.0018 17.7524C14.0018 18.5256 15.5693 19.1528 17.5028 19.1528C19.4363 19.1528 21.0037 18.5256 21.0037 17.7524"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M5.9975 10.9996H6.99791"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M9.99918 10.9996H10.9996"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M5.9975 14.0008H6.99791"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M9.99918 14.0008H10.9996"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M5.9975 10.9996H6.99791"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M9.99918 10.9996H10.9996"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M5.9975 17.0021H6.99791"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M9.99918 17.0021H10.9996"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M5.9975 17.0021H6.99791"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M9.99918 17.0021H10.9996"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M18.0025 7.99832H2.99625"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M10.9996 20.0033H4.99708C3.89205 20.0033 2.99625 19.1075 2.99625 18.0025V4.99708C2.99625 3.89205 3.89205 2.99625 4.99708 2.99625H16.0017C17.1067 2.99625 18.0025 3.89205 18.0025 4.99708V10.9996"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M5.9975 10.9996H6.99791"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M9.99918 10.9996H10.9996"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M14.0008 10.9996H15.0012"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M14.0018 14.901C14.0018 15.6742 15.5693 16.3014 17.5028 16.3014C19.4363 16.3014 21.0027 15.6742 21.0027 14.901C21.0027 14.1268 19.4343 13.5006 17.5018 13.5006C15.5693 13.5006 14.0018 14.1278 14.0008 14.901"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: 24,
		},
		height: {
			type: [Number, String],
			default: 24,
		},
		iconColor: {
			type: String,
			default: "secondary",
		},
	},
	computed: {
		strokeColor() {
			return "p-stroke-" + this.iconColor;
		},
	},
};
</script>

<style lang="scss" scoped>
svg {
	display: inline-block;
	vertical-align: baseline;
	margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
