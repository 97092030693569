export default {
	editTitle: "Edit Account Receivable Product",
	addTitle: "Add Account Receivable Product",
	editSubTitle: "Edit Account Receivable Product",
	addSubTitle: "Add Account Receivable Product",
	createProduct: "Create Product",

	toastHeaderMsgDefault: "Notification",
	toastMsgRoleInsert: "You don't have permission to create document.",
	toastMsgRoleUpdate: "You don't have permission to update document.",
	toastMsgRoleCancel: "You don't have permission to cancel document.",
	toastMsgRoleApprove: "You don't have permission to approve document.",
	toastMsgRoleDelete: "You don't have permission to delete data.",
	toastHeaderMsgAdd: "Add product successfully",
	toastMsgAdd: "Add :productName",

	toastHeaderMsgEdit: "Update product successfully",
	toastMsgEdit: "Update :productName",
	toastHeaderMsgDuplicate: "Unable to add products",
	toastMsgDuplicate: "The product is already in the system",
	toastHeaderMsgError: "Failed to save data",
	toastMsgError: "Can not edit :productName",

	saveButtonCaption: "Confirm",
	setupButtonCaption: "Setup Product",

	titleGeneral: "Account Receivable Product Information",
	titleAccountChart: "Account Chart",

	tabInvoice: "Invoice",
	tabReceipt: "Receipt",
	tabDiscount: "Discount",

	labelCode: "Product code",
	placeholderCode: "Please enter product code.",
	validateCode: "Please enter product code.",
	labelPaidPriority: "Paid priority",
	placeholderPaidPriority: "Please enter paid priority.",
	labelNameThai: "Product name thai",
	placeholderNameThai: "Please enter product name thai.",
	validateNameThai: "Please enter product name thai.",
	labelNameEnglish: "Product name english",
	placeholderNameEnglish: "Please enter product name english.",
	validateNameEnglish: "Please enter product name english.",
	labelPrice: "Price",
	placeholderPrice: "Please enter price. (Baht)",
	labelProductUnit: "Product Unit",
	placeholderProductUnit: "Please enter product unit.",
	labelProductType: "Product type",
	placeholderProductType: "Please enter product type.",
	validateProductType: "Please enter product type.",
	tooltipProductType: "Used for receiving payment records",
	itemProductType1: "Cash (Do not invoice)",
	itemProductType2: "Invoice",
	itemProductType3: "Prepaid",

	labelProductCalculation: "Product calculation",
	placeholderProductCalculation: "Please enter product calculation.",
	validateProductCalculation: "Please enter product calculation.",
	tooltipProductCalculation: "Calculation method : price x debt calculation<br>(except pattern \"equal static for all units\")",
	itemProductCalculation1: "Fixed the same for all units",
	itemProductCalculation2: "Price x Area",
	itemProductCalculation3: "Price x Owner ratio",

	labelIsCalculatePenalty: "Calculate penalty",
	placeholderIsCalculatePenalty: "Please enter calculate penalty.",
	validateIsCalculatePenalty: "Please enter calculate penalty.",
	itemIsCalculatePenalty1: "Calculation of penalty from the system",
	itemIsCalculatePenalty2: "No penalty are required.",

	labelProductPenalty: "Product penalty",
	placeholderProductPenalty: "Please enter product penalty.",
	validateProductPenalty: "Please enter product penalty.",

	labelProductPrepaid: "Product prepaid",
	placeholderProductPrepaid: "Please enter product prepaid.",

	placeholderAccount: "Please enter account chart.",
	labelAccountDr: "Account Chart Dedit",
	labelAcountDrInvoicePrevious: "Previous",
	labelAccountDrInvoiceCurrent: "Current",
	labelAccountDrInvoiceForward: "Forward",
	labelAccountDrReceiptCash: "Cash",
	labelAccountDrReceiptCheque: "Cheque",
	labelAccountDrReceiptTransfer: "Transfer (Current)",
	labelAccountDrReceiptTransferPrevious: "Transfer (Previous)",
	labelAccountDrReceiptDigitalPayment: "Transfer (Digital payment)",
	labelAccountDrReceiptCreditCard: "Credit card",
	labelAccountDrReceiptDeposit: "Deposit",
	labelAccountDrReceiptOther: "Other",
	labelAccountDrReceiptDiscount: "Discount",
	labelAccountCr: "Account Chart Credit",
	labelAcountCrInvoicePrevious: "Previous",
	labelAccountCrInvoiceCurrent: "Current",
	labelAccountCrInvoiceForward: "Forward",
	labelAccountCrReceiptCash: "Cash",
	labelAccountCrReceiptCheque: "Cheque",
	labelAccountCrReceiptTransfer: "Transfer (Current)",
	labelAccountCrReceiptTransferPrevious: "Transfer (Previous)",
	labelAccountCrReceiptDigitalPayment: "Transfer (Digital payment)",
	labelAccountCrReceiptCreditCard: "Credit card",
	labelAccountCrReceiptDeposit: "Deposit",
	labelAccountCrReceiptOther: "Other",
	labelAccountCrReceiptDiscount: "Discount",

	labelIsIgnoreDuplicatePeriod: "Cancel the period check in the system.",

	labelIsVat: "Calculate vat",
	itemIsVats1: "Calculate vat",
	itemIsVats2: "No calculate vat",

	tabPenaltyProduct: "Setting Penalty",
	tabAddProductToRoom: "Add product to room",
	noRecordsFound: "No records found",

	labelSearchBy: "Search By",
	itemSearchByAll: "Search By All",
	itemSearchByUnitCode: "Search By Unit Code",
	itemSearchByUnitAddress: "Search By Address No",
	labelSearchStart: "Search Start",
	labelSearchEnd: "Search End",
	validateSearch: "Please send a search message.",
	labelAddAllRoom: "Add All Room",
	labelDeleteAllRoom: "Delete All Room",

	columnUnitCode: "Unit Code",
	columnUnitAddress: "Unit Address",
	columnProductCode: "Product Code",
	columnProductName: "Product Name",
	columnStatus: "Status",
	columnAction: "Action",

	labelAddToRoom: "Add To Room",
	labelDeleteFromRoom: "Delete From Room",

	labelTagAdded: "added",
	labelTagPending: "pending",
	labelTagWaiting: "waiting",

	titleAddedConfirmDialog: "Confirm the addition of the product.",
	wordingAddedDialog: "Add product {product} to {num} room \ntotal {total} room",
	titleDeleteConfirmDialog: "Confirm the deleting of the product.",
	wordingDeleteDialog: "Delete product {product} form {num} room \ntotal {total} room",
	arProductDetail: "Product Detaill",
	deleteArProductTitle: "Confirm delete",
	askForDeleteArProductTitle: "Are you sure you want to delete :code ?",
	deleteArProductSuccess: "Delete successfully",
	errorArProductReference: "Can not delete. Beacuse having reference.",

	labelIsProductPenalty: "Because product penalty not allowed setting penalty.",
};
