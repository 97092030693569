export default{    
	title: "Account Chart",
	createAccountChart: "Create Account Chart",
	editAccountChart: "Edit Account Chart",
	titleEdit: "Account Chart Detail",
	isParentTitle: "Is use as a Parent",
	isParent: "Yes",
	unParent: "No",
	accountCategory: "Account Category",
	selectAccountCategor: "Select Account Categor",
	accountParent: "Account Parent",
	selectAccountParent: "Select Account Parent",
	activeStatus: "Active Status",
	validateAccountCategory: "Please select account category",
	validateAccountParenty: "Please select account parent",
	validateAccountCode: "Please enter account code",
	validateAccountNameTh: "Please enter account name (Thai)",
	createNewAccountChart: "Create new account chart",
	askForCreateNewAccountChart: "Are you sure you want to discard change and create new ?",
	deleteAccountChartTitle: "Confirm Delete",
	askForAccountChart: "Are you sure you want to delete account chart :code ?",
	deleteAccountChartSuccessTitle: "Delete success",
	deleteAccountChartSuccess: "Delete account chart success",
	duplicateCode: "Can not save, the account chart code : :code is duplicate",
	validateIsParent: "Can't Delete, because the account chart is parent", //130
	validateHasVoucher: "Can't Delete, because the account chart has voucher", //131
	validateHasAccountingFrequently: "Can't Delete, because the account chart has accounting frequently", //132
	validateHasAccountingPolicy: "Can't Delete, because the account chart has accounting policy", //133
	validateHasBookBank: "Can't Delete, because the account chart has book bank", //134
	validateHasAsset: "Can't Delete, because the account chart has asset", //135
	validateHasProduct: "Can't Delete, because the account chart has product", //136
	validateHasBudget: "Can't Delete, because the account chart has already used to create budget", //137
	accountCategory1: "1 : Asset",
	accountCategory2: "2 : Debt",
	accountCategory3: "3 : Capital",
	accountCategory4: "4 : Income",
	accountCategory5: "5 : Expenses",

	validateActiveStatusTitle: "Can't select account chart",
	validateActiveStatusWording: "Account chart :code is inActive.",
	pleaseSelectNewAccountChart: "Please select new account chart.",
};