export default {
  meters(state) {
    return state.meters;
  },
  totalPage(state) {
    return state.totalPage;
  },
  currentPage(state) {
    return state.currentPage;
  },
  rows(state) {
    return state.rows;
  },
  meterSelected(state) {
    return state.meterSelected;
  },
  meterToGl(state) {
    return state.meterToGl;
  },
};
