<template>
    <PvDialog
        :breakpoints="{ '1280px': '60vw', '640px': '80vw' }"
        :style="{ width: '60vw' }"
        :modal="true"
        :closable="false"
    >
        <template #header>
            <div class="flex justify-content-between align-items-right">
                <div class="pb-1">
                    <label class="font-lg">{{ lang.titlePreviewVoucher }}</label>
                </div>
                <div>
                    <PvButton
                        icon="pi pi-times"
                        class="p-button-link p-icon-secondary pb-0 pt-0"
                        style="width: 15px"
                        @click="closeDialog()"
                    />
                </div>
            </div>
            <PvDivider></PvDivider>
        </template>

        <transition>
            <section class="flex flex-wrap" :class="textFormClass">
                <div class="col-12 pl-0 pt-0 pr-2">
                    <label class="font-lg" for="ledgerBookForAutoGl">{{ ledgerBookForAutoGl }}</label>
                </div>
                <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
                    <label for="voucherDate">{{ lang.documentDate }}</label>
                    <PvCalendar
                        id="voucherDate"
                        v-model="voucherDate"
                        class="inputfield w-full mt-1"
                        inputClass="p-inputtext-disabled"
                        showIcon
                        dateFormat="dd/mm/yy"
                        disabled
                    />
                </div>
                <div class="lg:col-8 md:col-8 col-12 pl-0 pr-2">
                    <label for="description">{{ lang.description }}</label>
                    <PvInputText
                        id="description"
                        type="text"
                        class="inputfield w-full mt-1 input-disable"
                        v-model="description"
                        disabled
                    />
                </div>

                <div class="col-12 p-datatable-border my-2 pl-0">
                    <PvDataTable
                        :value="details"
                        selectionMode="single"
                        dataKey="id"
                        responsiveLayout="scroll"
                        class="p-datatable-xs"
                        :class="textTableClass"
                        :loading="isLoading"
                        scrollable
                    >
                        <template #empty>
                            <div class="w-full p-3" style="text-align: center">
                                <label>{{ lang.tableEmpty }}</label>
                            </div>
                        </template>
                        <PvColumn
                            field="itemNumber"
                            :header="lang.itemNo"
                            style="max-width: 6rem; height: 56px"
                            headerClass="h-left"
                            bodyClass="text-left"
                        />
                        <PvColumn
                            field="accountCode"
                            :header="lang.accountCode"
                            style="min-width: 10rem; height: 56px"
                            headerClass="h-left"
                            bodyClass="text-left"
                        />
                        <PvColumn
                            field="accountName"
                            :header="lang.accountName"
                            style="min-width: 16rem; height: 56px"
                            headerClass="h-left"
                            bodyClass="text-left"
                        />
                        <PvColumn
                            field="description"
                            :header="lang.description"
                            style="min-width: 14rem; height: 56px"
                            headerClass="h-left"
                            bodyClass="text-left"
                        />
                        <PvColumn
                            field="debitAmount"
                            :header="lang.debitAmount"
                            style="min-width: 8rem; height: 56px"
                            headerClass="h-right"
                            bodyClass="text-right"
                        >
                            <template #body="slotProps">
                            <span class="text-right w-full">{{
                                formatCurrency(slotProps.data.debitAmount)
                            }}</span>
                            </template>
                        </PvColumn>
                        <PvColumn
                            field="creditAmount"
                            :header="lang.creditAmount"
                            style="min-width: 8rem; height: 56px"
                            headerClass="h-right"
                            bodyClass="text-right"
                        >
                            <template #body="slotProps">
                            <span class="text-right w-full">{{
                                formatCurrency(slotProps.data.creditAmount)
                            }}</span>
                            </template>
                        </PvColumn>
                    </PvDataTable>
                </div>

                <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
                    <label for="totalDebitAmount">{{ lang.totalDebitAmount }}</label>
                    <PvInputNumber
                        id="totalDebitAmount"
                        class="inputfield w-full mt-1"
                        inputClass="p-inputtext-disabled text-right w-full"
                        v-model="totalDebitAmount"
                        mode="decimal"
                        :minFractionDigits="2"
                        :maxFractionDigits="2"
                        disabled
                    />
                </div>
                <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
                    <label for="totalDebitAmount">{{ lang.totalDebitAmount }}</label>
                    <PvInputNumber
                        id="totalCreditAmount"
                        class="inputfield w-full mt-1"
                        inputClass="p-inputtext-disabled text-right w-full"
                        v-model="totalCreditAmount"
                        mode="decimal"
                        :minFractionDigits="2"
                        :maxFractionDigits="2"
                        disabled
                    />
                </div>
                <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
                    <label for="totalDiffAmount">{{ lang.totalDiffAmount }}</label>
                    <PvInputNumber
                        id="totalDiffAmount"
                        class="inputfield w-full mt-1"
                        inputClass="p-inputtext-disabled text-right w-full"
                        v-model="totalDiffAmount"
                        mode="decimal"
                        :minFractionDigits="2"
                        :maxFractionDigits="2"
                        disabled
                    />
                </div>
            </section>
        </transition>

        <template #footer>
            <div class="pr-3">
                <PvButton
                    :label="lang.close"
                    class="p-button-outlined p-button-secondary w-2 ml-3 mr-2"
                    @click="closeDialog()"
                />
            </div>
        </template>
    </PvDialog>
</template>

<script>
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import { useStore } from "vuex";
import { computed, ref, onMounted, watch } from "vue";
import useGetAccounting from "../../hooks/getAccounting.js";
export default {
    emits: ["close"],
    props: {
        data: {
            type: Object,
        }
    },
    setup(props, { emit }) {
        const store = useStore();
        const { lang } = useLang();
        const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
        const { formatCurrency, formatDate } = useFormat();
        const { isSharedAccountingReady, getAllAccountings, accountings } = useGetAccounting();
        const mergeLang = computed(function () {
            if (store.getters.selectedLanguage === "TH") {
                return {
                ...lang.th.base,
                ...lang.th.ap.paymentRequest.listing,
                };
            } else {
                return {
                ...lang.en.base,
                ...lang.en.ap.paymentRequest.listing,
                };
            }
        });

        let selectedPaymentRequestForAutoGl = ref(null);
        let details = ref([]);
        let isLoading = ref(false);
        let ledgerBookForAutoGl = ref("");
        let voucherDate = ref(null);
        let description = ref("");
        let totalDebitAmount = ref(0);
        let totalCreditAmount = ref(0);
        let totalDiffAmount = ref(0);

        onMounted(() => {
            isLoading.value = true;
            getAllAccountings();
            selectedPaymentRequestForAutoGl.value = props.data;
            setData();
        });

        //computed

        //methods
        const closeDialog = () => {
            emit("close");
        };

        const setData = () => {
            // console.log("selectedPaymentRequestForAutoGl", props.data);            
            let data = selectedPaymentRequestForAutoGl.value;
            if (data && isSharedAccountingReady.value) {
                ledgerBookForAutoGl.value = `${data.ledgerBookCode} : ${data.ledgerBookName}`;
                voucherDate.value = formatDate(data.voucherDate);
                description.value = data.description;
                let tempDetails = [];
                data.details.forEach((detail) => {
                    let account = accountings.value.find((data) => data.id.trim() == detail.accountId.trim());
                    let data = {
                        ...detail,
                        accountCode: account.code.trim(),
                        accountName: account.name.trim(),
                    }
                    totalDebitAmount.value += detail.debitAmount;
                    totalCreditAmount.value += detail.creditAmount;
                    tempDetails.push(data);
                });
                details.value = tempDetails;
                totalDiffAmount.value = totalDebitAmount.value - totalCreditAmount.value;
            }
            isLoading.value = false;
        };

        watch(isSharedAccountingReady, async () => {
            setData();
        });

        return {
            lang: mergeLang,
            textHeaderClass,
            textFormClass,
            textTableClass,
            formatCurrency,

            //data
            isLoading,
            details,
            ledgerBookForAutoGl,
            voucherDate,
            description,
            totalDebitAmount,
            totalCreditAmount,
            totalDiffAmount,

            //methods
            closeDialog,

            //computed
        }
    }
}
</script>