<template>
  <PvDialog
    :breakpoints="{ '1280px': '40vw', '640px': '60vw' }"
    :style="{ width: '576px' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div 
        class="flex justify-content-between align-items-center" 
        :style="{ height: '48px' }"
      >
        <div class="pt-0 pb-0">
          <label class="font-lg font-normal">{{ lang.titleLanguage }}</label>
        </div>
      </div>
      <PvDivider class="mt-0 mb-3"></PvDivider>
    </template>

    <div class="col-12 pl-0 pr-2 lg:pr-4 md:pr-4 mt-0 mb-0 pt-0 pb-0">
			<label for="language">{{ lang.language }}</label>
			  <div class="flex">
          <div
            v-for="language of languages"
            :key="language.code"
            class="field-radiobutton pt-3 mr-5">
            <PvRadioButton
              class="mr-2"
              :inputId="language.code"
              name="language"
              :value="language.code"
              v-model="selectedLanguage"
            />
            <label :for="language.code">{{ language.name }}</label>
          </div>
			</div>
		</div>

    <template #footer>
      <div class="flex justify-content-between align-items-center m-1">

          <PvButton
            :label="lang.close"
            class="p-button-outlined p-button-secondary w-full"
            @click="closeDialog"
          />
          <PvButton 
            :label="lang.buttonDialogSuccess"
            class="w-full"
            @click="changeLanguage" 
          />

      </div>
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
export default {
  data() {
    return {
      languages: [
          { name: "ภาษาไทย", code: "TH" },
          { name: "English", code: "EN" },
        ],
      selectedLanguage: this.$store.getters.selectedLanguage,
    };
  },
  setup() {
    const { lang } = useLang();
    const store = useStore();

    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.setting };
      } else {
        return { ...lang.en.base, ...lang.en.setting };
      }
    });

    return {
      lang: mergeLang,
    };
  },
  methods: {
    changeLanguage() {
        this.$store.dispatch("setLanguage", { value: this.selectedLanguage });
        this.$emit("close");
    },
    closeDialog() {
        this.selectedLanguage = this.$store.getters.selectedLanguage,
        this.$emit("close");
    },
  },
};
</script>

<style>
.p-dialog .p-dialog-content {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}
</style>