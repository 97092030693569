export default {
  uploadFile: "อัปโหลดไฟล์",
  dragFile: "ลากไฟล์มาวาง หรือ ",
  addFile: "เพิ่มไฟล์ใหม่",
  importFileDesc: "กรุณาเพิ่มไฟล์ทีละ 1 ไฟล์ ที่มีนามสกุล .XLSX เท่านั้น หรือ ",
  fileName: "ชื่อเอกสาร",
  validateLimitFile: "สามารถอัปโหลดไฟล์ได้ทีละ 1 ไฟล์",
  validateFileType: "ไม่สามารถอัปโหลดไฟล์ :name ได้",
  validateImportFile: "สกุลไฟล์ที่อนุญาตมีดังนี้ .XLSX เท่านั้น",
  validateImportNoData: "เอกสาร : :file ไม่มีข้อมูล",
  validateImportDate: "รูปแบบวันที่นำเข้าไม่ถูกต้อง",
  
  creatingDeposit: "กำลังสร้างใบรับฝากเงิน",
  generating: "กำลังสร้าง...",
  wordingConfirmImportDeposit: "จาก :total รายการ ท่านสามารถสร้างรายการได้เพียง :value รายการ เนื่องจากมีรายการค่าไม่ถูกต้องจำนวน :invalidValue รายการ",
  deposit: "ใบรับฝากเงิน",
  importDeposit: "นำเข้าใบรับฝากเงิน",
	detailDeposit: "รายละเอียดใบรับฝากเงิน",
};
