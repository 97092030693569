export default {
  setCustomer(state, payload) {
    // state.project.length = 0;
    let customers = [...state.customer.customers];
    for (const customer of payload.customers) {
      if(customers.findIndex(c => c.id === customer.id) < 0){
        customers.push(customer);
      }
    }
    // console.log('setCustomer',customers);
    state.customer.customers = customers;
    state.customer.currentPage = payload.currentPage;
    state.customer.totalPage = payload.totalPage;
  },
  resetCustomer(state) {
    const customer = {
      customers: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.customer = {...state.customer, ...customer};
  },
  setCustomerGroup(state, payload) {
    // state.project.length = 0;
    let customerGroups = [...state.customer.customerGroups];
    for (const customerGroup of payload.customerGroups) {
      if(customerGroups.findIndex(c => c.id === customerGroup.id) < 0){
        customerGroups.push(customerGroup);
      }
    }
    // console.log('setCustomer',customers);
    state.customer.customerGroups = customerGroups;
    state.customer.customerGroupCurrentPage = payload.currentPage;
    state.customer.customerGroupTotalPage = payload.totalPage;
  },
  resetCustomerGroup(state) {
    state.customer.customerGroups = [];
    state.customer.customerGroupCurrentPage = 0;
    state.customer.customerGroupTotalPage = 0;
  },
};
  