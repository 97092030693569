<template>
  <base-container>
    <base-withholding-tax-edit-view :id="id"></base-withholding-tax-edit-view>
  </base-container>
</template>

<script>
import baseWithholdingTaxEditView from "../../../components/withholdingTax/BaseEditWithholdingTaxView.vue";
export default {
  props: ["id", "projectCode"],
  components: {
    baseWithholdingTaxEditView,
  },
};
</script>
