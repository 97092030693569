export default {
	async getMeterUsageSettingType(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Meter/GetMeterUsageSettingType";

		if (!payload.limit) {
			url = url + "?limit=10";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Purchase Receipt");
			throw error;
		}
		// console.log(responseData);
		context.commit("setMeterUsageSettingType", responseData);
	},
	async getMeterUsageSettingList(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/Meter/GetMeterUsageSetting";

		if (!payload.limit) {
			url = url + "?limit=100";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.where) {
			url = url + "&where=" + JSON.stringify(payload.where);
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get Purchase Receipt");
			throw error;
		}
		// console.log(responseData);
		context.commit("setMeterUsageSettingList", responseData);
	},
	resetMeter(context) {
		context.commit("resetMeter");
	},
	async updateMeterUsageSetting(context, payload) {
		// console.log('updateMeterUsageSetting',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/Meter/UpdateMeterUsageSetting";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("updateMeterUsageSetting>>>", responseData);
			// const error = new Error(responseData.message || "Failed to Add Purchase Receipt");
			throw responseData;
		}
		// console.log("updateMeterUsageSetting>>>", responseData);
		await context.commit("resetMeterUsageSettingList");
		await context.commit("setMeterUsageSettingList", responseData);
	},
	async getAllMeterUsageSetting(context, payload) {
		await context.commit("resetMeterUsageSettingList");
		while (!context.getters.meterUsageSettingIsLastPage) {
		  const payloadMeterUsageSetting = {
			page: context.getters.meterUsageSettingNextPage || 1,
			where : payload.where
		  };
		  await context.dispatch("getMeterUsageSettingList", payloadMeterUsageSetting);
		}
	},
	resetMeterUsageSettingList(context) {
		context.commit("resetMeterUsageSettingList", null);
	}
};
