<template>
	<base-container>
		<base-edit-bank-view :id="id" :type="-1"></base-edit-bank-view>
	</base-container>
</template>

<script>
import BaseEditBankView from "../../../components/bank/BaseEditBankView.vue";
export default {
	props: ["id", "projectCode"],
	components: {
		BaseEditBankView,
	},
};
</script>

<style scoped>
::v-deep(.editable-cells-table td.p-cell-editing) {
	padding-top: 0;
	padding-bottom: 0;
}

.p-button-text {
	width: 2rem;
}
</style>
