<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M19 12V5C19 3.89543 18.1046 3 17 3H5C3.89543 3 3 3.89543 3 5V19"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M7 3V17"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M12 21H5C3.89543 21 3 20.1046 3 19V19C3 17.8954 3.89543 17 5 17H11"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M21.3874 18.3939L19.6581 19.1207C19.417 19.2221 19.2252 19.4138 19.1238 19.6548L18.3947 21.3877C18.2307 21.7775 17.8393 22.022 17.4171 21.9983C16.9949 21.9747 16.6332 21.688 16.5137 21.2824L15.042 16.2837C14.9387 15.9326 15.0354 15.5531 15.2941 15.2943C15.5529 15.0355 15.9324 14.9387 16.2835 15.042L21.2822 16.5128C21.688 16.6322 21.9747 16.994 21.9983 17.4162C22.0219 17.8385 21.7773 18.23 21.3874 18.3939Z"
			:class="strokeColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>
<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: 24,
		},
		height: {
			type: [Number, String],
			default: 24,
		},
		iconColor: {
			type: String,
			default: "secondary",
		},
	},
	computed: {
		strokeColor() {
			return "p-stroke-" + this.iconColor;
		},
	},
};
</script>

<style lang="scss" scoped>
svg {
	display: inline-block;
	vertical-align: baseline;
	margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
