import { useStore } from "vuex";
import { ref, computed, onMounted} from "vue";
export default function useGetProject() {
  const store = useStore();
  let searchText = ref(null);

  const projects = computed(() => {
    return store.getters["shared/projectData"];
  });

  const projectIsLastPage = computed(() => {
    return store.getters["shared/projectIsLastPage"];
  });

  const filteredProjects = computed(() => {
    if (!searchText.value) {
      //ช่องว่าง
      const result = [...projects.value];
      return result;
    } else {
      return projects.value.filter((project) => {
        return project.search
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const searchProject = function (event) {
    // console.log(event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchText.value = "";
      searchText.value = null;
    } else {
      searchText.value = event.query;
    }
  };

  let isSharedProjectReady = ref(false);

  const getAllProjects = async function () {
    if (!projectIsLastPage.value) {
      await store.dispatch("shared/getAllProjects");
    }
    isSharedProjectReady.value = true;
  };

  const getProjectByCode = function (code) {
    return projects.value.find((project) => {
      return project.code.toLowerCase() === code.trim().toLowerCase();
    });
  };

  const getProjectById = function (id) {
    return projects.value.find((project) => {
      return project.id.toLowerCase() === id.trim().toLowerCase();
    });
  };

  onMounted(async () => {
    await getAllProjects();
  });


  return {
    searchProject,
    filteredProjects,
    searchText,
    getProjectByCode,
    getProjectById,
    isSharedProjectReady,
  };
}
