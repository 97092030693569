export default {
  async getUnits(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Unit";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + encodeURIComponent(JSON.stringify(payload.where));
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get unit"
      );
      throw error;
    }
    // console.log("getUnits", responseData);

    // const data = responseData.data;

    context.commit("setUnit", responseData);
  },
  async getUnitById(context, id) {
    let url = process.env.VUE_APP_BACKEND_URL + "/Unit/" + id;
    url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get unit"
      );
      throw error;
    }
    // console.log(responseData);

    // const data = responseData.data;
    context.commit("setSelectedUnit", responseData.data);
  },
  async createOrUpdateUnit(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/Unit/${payload.type}`;

    // const fallbackErrorMessage = `Failed to ${payload.type} unit`
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: payload.type === 'Add' ? 'post' : 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();
    if (!response.ok) {
      // let message = null
      // if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
      //   message = responseData.title;
      //   for (const key in responseData.errors) {
      //     message += ", " + key.replace("$.","")
      //   }
      // } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
      //   message = responseData.message
      // }
      // const error = new Error(message || fallbackErrorMessage);
      // throw error;
      throw responseData;
    }

    context.commit("setSelectedUnit", responseData.data);

  },
  resetSelectedUnit(context) {
    context.commit("setSelectedUnit",null);
  },
};
