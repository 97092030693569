export default {
	async getPhoneBooks(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/PhoneBook";

		if (!payload.limit) {
			url = url + "?limit=100";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		if (payload.projectId) {
			url = url + "&projectId=" + payload.projectId;
		}
	
		if (payload.name) {
			url = url + "&name=" + payload.name;
		}
	
		if (payload.phoneNumber) {
			url = url + "&phoneNumber=" + payload.phoneNumber;
		}
	
		if (payload.contactType) {
			url = url + "&contactType=" + payload.contactType;
		}

		if (payload.phoneType) {
			url = url + "&phoneType=" + payload.phoneType;
		}

		if (payload.contactTypeId) {
			url = url + "&contactTypeId=" + payload.contactTypeId;
		}
		url = encodeURI(url);

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get phone book");
			throw error;
		}
		// console.log("phone book", responseData);

		context.commit("setPhoneBooks", responseData);
	},
	async getAllPhoneBook(context,payload) {
		await context.commit("resetPhoneBook");
		while (!context.getters.phoneBookIsLastPage) {
			const where = {
				page: context.getters.phoneBookNextPage || 1,
				...payload,
			};
			await context.dispatch("getPhoneBooks", where);
		}
	},
	async getPhoneBookById(context, id) {
		let url = process.env.VUE_APP_BACKEND_URL + "/PhoneBook/" + id;
		url = encodeURI(url);

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get phone book");
			throw error;
		}
		// console.log("phoneBook", responseData);

		context.commit("setSelectedPhoneBook", responseData.data);
	},
	async resetPhoneBook(context) {
		await context.commit("resetPhoneBook");
	},
	async deletePhoneBook(context, payload) {
		// console.log('deletePhoneBook',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/PhoneBook/Delete";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("deletePhoneBook>>>", responseData);
			throw responseData;
		}
		// console.log("deletePhoneBook>>>", responseData);
	},
	async addPhoneBook(context, payload) {
		// console.log('addPhoneBook',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/PhoneBook/Add";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("addPhoneBook>>>", responseData);
			// const error = new Error(responseData.message || "Failed to Add Purchase Receipt");
			throw responseData;
		}
		// console.log("addPhoneBook>>>", responseData);
		context.commit("setSelectedPhoneBook", responseData.data);
	},
	async updatePhoneBook(context, payload) {
		// console.log('updatePhoneBook',payload);
		let url = process.env.VUE_APP_BACKEND_URL + "/PhoneBook/Update";

		const token = context.rootGetters.token;
		//  console.log("payload=",payload);

		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify(payload),
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log("updatePhoneBook>>>", responseData);
			// const error = new Error(responseData.message || "Failed to Add Purchase Receipt");
			throw responseData;
		}
		context.commit("setSelectedPhoneBook", responseData.data);
	},

	async getContactTypes(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/PhoneBook/ContactType";

		if (!payload.limit) {
			url = url + "?limit=100";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get contact type");
			throw error;
		}
		// console.log("phone book", responseData);

		context.commit("setContactTypes", responseData);
	},
	async resetContactType(context) {
		await context.commit("resetContactType");
	},
	async getAllContactType(context) {
		await context.dispatch("resetContactType");
		while (!context.getters.contactTypeIsLastPage) {
			const where = {
				page: context.getters.contactTypeNextPage || 1,
			};
			await context.dispatch("getContactTypes", where);
		}
	},
	
	async getPhoneTypes(context, payload) {
		let url = process.env.VUE_APP_BACKEND_URL + "/PhoneBook/Category";

		if (!payload.limit) {
			url = url + "?limit=100";
		} else {
			url = url + "?limit=" + payload.limit;
		}

		if (payload.page) {
			url = url + "&page=" + payload.page;
		}

		const token = context.rootGetters.token;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		const responseData = await response.json();

		if (!response.ok) {
			// console.log(responseData);
			const error = new Error(responseData.message || "Failed to get phone type");
			throw error;
		}
		// console.log("phone book", responseData);

		context.commit("setPhoneTypes", responseData);
	},
	async resetPhoneType(context) {
		await context.commit("resetPhoneType");
	},
	async getAllPhoneType(context) {
		await context.dispatch("resetPhoneType");
		while (!context.getters.phoneTypeIsLastPage) {
			const where = {
				page: context.getters.phoneTypeNextPage || 1,
			};
			await context.dispatch("getPhoneTypes", where);
		}
	},
};
