export default {
  userId(state) {
    return state.userId;
  },
  userCode(state) {
    return state.userCode;
  },
  userName(state) {
    return state.userName;
  },
  token(state) {
    return state.token;
  },
  isAuthenticated(state) {
    if (state.token) {
      return true
    }
    return false
  },
  didAutoLogout(state) {
    return state.didAutoLogout;
  },
  tokenLosaPM(state) {
    return state.tokenLosaPM;
  },
}