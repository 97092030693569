export default {
    bookPurchases(state) {
      return state.bookPurchase.bookPurchases;
    },
    bookPurchaseCurrentPage(state) {
      return state.bookPurchase.currentPage; 
    },
    bookPurchaseNextPage(state) {
      return state.bookPurchase.currentPage + 1;
    },
    bookPurchaseTotalPage(state) {
      return state.bookPurchase.totalPage;
    },
    bookPurchaseIsLastPage(_, getters) {
      return (getters.bookPurchaseCurrentPage === getters.bookPurchaseTotalPage) && (getters.bookPurchaseTotalPage !== 0);
    }
};
  