export default {
	logout: "Logout",
	logoutMessage: "Are you sure you want to loggout?",
	admin: "Admin",
	report: "Report",
	glReport: "Report GL",

	home: "Home",

	dashboard: "Dashboard",
	debtDashboard: "Debt Dashboard",
	parcelDashboard: "Parcel Dashboard",
	livingDashboard: "Living Dashboard",
	mobileReigterDashboard: "Register LOS APP Dashboard",

	chat: "Chat",

	parcel: "Parcel",
	parcelReport: "Parcel Report",

	serviceMaintenance: "Service Maintenance",
	repairReport: "Repair Report",
	monthlyReport: "Monthly Report",

	productAndService: "Product & Service",

	visitor: "Visitor",

	reservation: "Reservation",

	residentInformation: "Resident Information",

	financialAccounting: "Financial Accounting",
	settingAccount: "Setting Account",
	bookBank: "Book Bank",
	penaltyCalculation: "penaltyCalculation",
	ar: "Receiveable",
	accountReceivable: "Account Receivable",
	accountReceivableProduct: "Account Receivable Product",
	debtManagement: "Debt Management",
	invoiceCalculation: "Invoice Calculation",
	arInvoice: "Invoice",
	arOutstandingLetter: "Outstanding Letter",
	acceptPayment: "Accept Payment",
	arReceipt: "Receipt",
	arDeposit: "Deposit",
	arReduceDebt: "Reduce Debt",
	arBank: "PayIn",
	guarantee: "Guarantee",
	receivableReport: "Receivable Report",
	moneyTransfer: "Money Transfer",
	arPrepaidRefund: "Refund Prepaid",
	notice: "Notice",
	noticeReport: "Notice Report",
	noticeCalculation: "Notice Calculation",
	noticeFormat: "Notice Format",
	ap: "Payable",
	apWithholdingTax: "Withholding Tax",
	apBank: "PayOut",
	apPurchaseRequisition: "PurchaseRequisition",
	apPurchaseOrder: "Purchase Order",
	apPurchaseInvoice: "Billing Note/Invoice",
	apReceipt: "Purchase Receipt",
	reportAP: "Report AP",
	reportWithholdingTax: "Report WithholdingTax",
	gl: "Accounting",
	glInvoice: "Invoice To GL",
	glAccounting: "Accounting",
	glReceipt: "Receipt To GL",
	glPettyCash: "Petty Cash",
	reportGL: "Report GL",
	reportPC: "Report PC",
	asset: "Asset",
	assetRegister: "Asset Register",
	reportAsset: "Report Asset",

	master: "master",
	masterProject: "Project",
	project: "Project",
	projectDocument: "Project Document",
	masterAdvertisement: "Advertisement",
	masterAccountChart: "Account Chart",
	meterRegistration: "Meter Registration",
	meterRegister: "Meter",
	calculationFormat: "Calculation Format",
	meter: "Meter",
	meterUsageWater: "Water Meter Usage",
	meterUsageElectricity : "Electricity Meter Usage",
	meterUsageCoolingAir: "Cooling Air Meter Usage",
	meterUsageReport: "Report Meter Usage",
	policy: "Policy",

	smartAudit: "Smart Audit",

	news: "News",

	userManual: "User Manual",

	help: "Help",
	setting: "Setting",
	changeProject: "Change Project",
	adminFormManage: "Form Management",
	formVersion: "Form Version Setting",

	incomeRegister: "Income Register",

	online: "online",
	productArPenalty: "PenaltyProductAr",
	unitProductInvoice: "UnitProductSetupForInvoice",
	unitInformation: "Room/House List",
	resident: "Resident",
	unit: "Room/House",
	reportManage: "Report Management",
	masterAccountBook: "Account Book",

	reportRequest: "Request List",
	reportReceipt: "Receipt report",

	usePrepaidAndDeposit: "Use Prepaid And Deposit",
	masterCustomerGroup: "Customer Group",
	refundGuarantee: "Refund Guarantee",
	debtFree: "Debt Free",
	debtFreeMenu: "Debt Free Menu",
	productAp: "ProductAp",
	supplier: "Supplier",
	closingEntry: "Closing Entry",
	accountingFrequently: "Accounting Frequently",
	accountingPolicy: "Accounting Policy",
	masterPhoneBook: "Phone Book",
	searchMenu: "Search Menu",
	menuEmpty: "Data not found please enter other values.",
	changePassword: "Change Password",
	changeLanguage: "Change Language",
    passwordNotMatch: "New Password do not match ",
    titleChangePasswordSuccess: "Change Password Success",
    changePasswordSuccess: "change password successfully",
	oldPasswordNotMatch: "The old password is incorrect",
	menuTitle: "PMS LivingOS",
	subMenuTitle: "Better Living Everyday",
};
