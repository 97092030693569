export default {
	importReceiptTitle: "Import Receipt",
	extra: "Extra",
	cashType: "Cash Type",
	cash: "Cash",
	creditCard: "Credit Card",
	transfer: "Transfer",
	cheque: "Cheque",
	epay: "E-Pay",
	startDate: "Start Date",
	endDate: "End Date",
	receiptCode: "Receipt Code",
	toBankDatePayin: "To Bank Date",
	searchReceiptLabel: "Search Receipt",
	confirmImport: "Confirm Selected",
	deleteReceiptAll: "Delete all receipt",

	columnCodeReceipt: "Code Receipt",
	columnReceiptDate: "Receipt Date",
	columnDate: "Transfer/Cheque Date",
	columnInvoice: "Invoice",
	columnUnitCode: "Unit Code",
	columnTotal: "Total",
	columnCustomerName: "Customer Name",
};
