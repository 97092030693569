export default {
	purchaseInvoices(state) {
		return state.purchaseInvoices;
	},
	purchaseInvoiceSelected(state) {
		return state.purchaseInvoiceSelected;
	},
	purchaseInvoiceToGl(state) {
		return state.purchaseInvoiceToGl;
	},
	cancelPurchaseInvoice(state) {
		return state.cancelPurchaseInvoice;
	},
};
