export default {
    title: "ขอหนังสือรับรองหนี้",
    addDebtFree: "สร้างหนังสือรับรองหนี้",
    debtFreeList: "รายการหนังสือรับรองหนี้",
    dateRange: "ช่วงวันที่เอกสาร",
    unitAddress: "ยูนิต/บ้านเลขที่",
    ownerName: "ชื่อเจ้าของ",
    searchTextPlaceholder: "กรอกข้อมูลเพื่อค้นหา",
    unitCode: "ยูนิต",
    unitAddressNo: "บ้านเลขที่",
    requestDate: "วันที่ร้องขอ",
    requestBy: "ผู้ร้องขอ",
    requestPhone: "เบอร์ผู้ร้องขอ",
    approve: "อนุมัติ",
    createDate: "วันที่สร้าง",
    createBy: "ผู้สร้าง",
    disApprove: "ไม่อนุมัติ",
}