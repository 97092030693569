export default {
    noticeGenerateQueueStatus(state) {
        return state.noticeGenerateQueueStatus;
    },
    selectedNoticeGenerateQueue(state) {
        return state.selectedNoticeGenerateQueue;
    },
    notices(state) {
        return state.notices;
    },
    selectedNotice(state) {
        return state.selectedNotice;
    },
    noticeTotalPage(state) {
        return state.noticeTotalPage;
    },
    noticeCurrentPage(state) {
        return state.noticeCurrentPage;
    },
    noticeRows(state) {
        return state.noticeRows;
    },
    noticeIsCanceled(state) {
        return state.noticeIsCanceled;
    },
    generatedNotice(state) {
        return state.generatedNotice;
    },
}