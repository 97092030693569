export default {
  setAccountingParent(state, payload) {
    // state.project.length = 0;
    let accountingParents = [...state.accountingParent.accountingParents];
    for (const accountingParent of payload.accountingParents) {
      if (accountingParents.findIndex((c) => c.id === accountingParent.id) < 0) {
        accountingParents.push(accountingParent);
      }
    }
    state.accountingParent.accountingParents = accountingParents;
    state.accountingParent.currentPage = payload.currentPage;
    state.accountingParent.totalPage = payload.totalPage;
  },
  resetAccountingParent(state) {
    // const accountingParent = {
    //   accountingParents: [],
    //   totalPage: 0,
    //   currentPage: 0,
    // };
    // state.accountingParents = accountingParent;
    state.accountingParent.accountingParents = []
    state.accountingParent.totalPage = 0
    state.accountingParent.currentPage = 0
  },
  setAccountingParentReload(state, payload) {
    state.accountingParent.isReload = payload;
  },
};
