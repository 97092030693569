export default {
	title: "Closing entry",
	createCaption: "Closing entry",

	containerTitle: "Closing entry listing.",

	columnCode: "Code",
	columnDate: "Date",
	colummBookCode: "Book",
	colummCancelStatus: "Status",
};
