export default({
    title:"คืนเงินรับล่วงหน้า",
    titleList:"รายการ คืนเงินรับล่วงหน้า",
    columnDate: "วันที่",
    columnCode: "เลขที่",
    columnUnit: "ยูนิต",
    columnAddress:"บ้านเลขที่",
    columnCustomerName: "ชื่อลูกค้า",
    columnPrice:"จำนวนเงิน",
    columnIsPaid:"ชำระ",
    columnIsCancel:"ยกเลิก",
    columnPostStep:"พิมพ์/ลงบัญชี",
    columnPostEdit:"แก้ไข",

    CreateNew: "สร้างใหม่",
    SearchBy: "ค้นหาโดย",
    UnitCode:"รหัสห้อง/บ้าน",
    DateAt:"วันที่",
    CustomerName:"ชื่อลูกค้า",
    DocumentCode:"เลขที่ใบคืนเงิน",
    customerSearch:"รหัสหรือชื่อลูกค้า",

    
    columnCodeReceipt: "เลขที่ใบเสร็จ",
    titleReceiptList:"รายการ รับล่วงหน้าคงเหลือ",
    columnPeriod: "งวด",
    columnBalance: "คงเหลือ",
    columnProductCode: "รหัส",
    columnProductName: "ชื่อ",
    columnQuantity: "จำนวน",
    columnTotal: "ยอดคืน",
    employeeName:"ชื่อพนักงาน",
    columnRemark: "รายละเอียด",
    ReceiveMan: "ชื่อผู้รับเงินคืน",
    Remark: "สาเหตุการคืนรับล่วงหน้า",

    createPrepaid:"สร้างใบคืนเงินรับล่วงหน้า",
    editPrepaid:"แก้ไขใบคืนเงินรับล่วงหน้า",

    Create: "สร้างใหม่",
    Add:"เพิ่ม",
    Edit: "แก้ไข",
    Print: "พิมพ์",
    Cancel: "ยกเลิก",

    searchReceived:"รายการรับล่วงหน้าคงเหลือ",
    
    askForSaveData: "บันทึกข้อมูลสำเร็จ",

    checkRefundAmount: "ยอดคืนต้องน้อยกว่าหรือเท่ากับค่าคงเหลือ",
    validateNoDetail: "กรุณาเลือกรายการ",
    validateNoRemark: "กรุณาระบุสาเหตุการคืนเงินรับล่วงหน้า",
    downloadPDFSuccess: "ดาวน์โหลดสำเร็จ",
    
})