<template>
  <base-toolbar :title="lang.titleList" @goBack="goBack"></base-toolbar>

  <base-container>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.titleList }}</label>
      </div>

      <PvDivider></PvDivider>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="signatureManagements"
          v-model:selection="selectedSignatureManagement"
          selectionMode="single"
          dataKey="reportMenuDetailId"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(selectedSignatureManagement)"
          scrollable
        >
          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>

          <PvColumn
            field="reportMenuDetail"
            :header="lang.reportName"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="reportMenu"
            :header="lang.reportHeaderName"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="signatureQuantity"
            :header="lang.signatureQuantity"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />

          <PvColumn
            style="max-width: 5rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="gotoEdit(slotProps.data)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
            </template>
          </PvColumn>

          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              class="p-pagination-radius"
              :first="showCurrentPage"
            >
              <template #start="slotProps">
                {{ lang.page }}:
                {{ totalPage ? slotProps.state.page + 1 : 0 }}/{{ totalPage }}
              </template>
            </PvPaginator>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref, computed, onMounted, watch } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import BaseDialog from "../../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
export default {
  props: ["projectCode"],
  setup() {
    const router = useRouter();
    const store = useStore();
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    let dataConfigDialog = ref(null);
    let isLoading = ref(true);
    let selectedSignatureManagement = ref(null);
    let selectedPage = ref(1);
    let rowsPerPage = ref(10);

    onMounted(async () => {
      isLoading.value = false;
      initiateData();
      await roleByMenuDataReady();
    });

    //computeds
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.signatureManagement.signatureManagement.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.signatureManagement.signatureManagement.listing,
        };
      }
    });
    const roleByMenu = computed(() => {
      return getRoleByMenuByCode("pms_rpt_signature");
    });
    const isRoleSelect = computed(() => {
      if (roleByMenu.value && roleByMenu.value.select == "Y") {
        return true;
      }
      return false;
    });
    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const signatureManagements = computed(() => {
      return store.getters["signatureManagement/signatureManagements"];
    });
    const totalPage = computed(() => {
      return store.getters["signatureManagement/totalPage"];
    });
    const totalRecords = computed(() => {
      return totalPage.value * rowsPerPage.value;
    });
    const showCurrentPage = computed(() => {
      let page = 0;
      page = rowsPerPage.value * (selectedPage.value - 1);
      return page;
    });
    const payloadGetDatas = computed(() => {
      return {
        page: selectedPage.value,
        limit: rowsPerPage.value,
        projectId: selectedProject.value.id,
      };
    });

    //methods
    const goBack = () => {
      router.push({ name: "home" });
    };
    const initiateData = () => {
      dataConfigDialog.value = null;
      selectedSignatureManagement.value = null;
      selectedPage.value = 1;
      rowsPerPage.value = 10;
    };
    const roleByMenuDataReady = async () => {
      if (isSharedRoleByMenuReady.value) {
        await getSignatureManagements();
      }
    };
    const gotoEdit = (data) => {
      if (!isRoleSelect.value) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.validateRoleSelect,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        return true;
      }

      const id = data.id != 0 ? data.id:"create";
      router.push({
        name: "signature-management-edit",
        params: { reportMenuDetailId: data.reportMenuDetailId, id: id },
      });
    };
    const setDialog = (dataConfig) => {
      const classMap = {
        success: "p-text-success",
        error: "p-error",
      };

      dataConfig.classTitle = classMap[dataConfig.classTitle] || "";

      dataConfig.button = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "cancel",
        },
      ];

      if (dataConfig.type === "confirm") {
        dataConfig.button.push({
          id: "confirm",
          caption: mergeLang.value.buttonDialogConfirm,
          class: "w-full",
          action: "confirm",
        });
      }

      dataConfigDialog.value = {
        titleConfirm: dataConfig.title,
        classTitle: dataConfig.classTitle,
        wordingConfirm: dataConfig.message,
        dataButtons: dataConfig.button,
        display: true,
      };
    };
    const getSignatureManagements = async () => {
      isLoading.value = true;
      try {
        // console.log(payloadGetDatas.value);
        await store.dispatch(
          "signatureManagement/getSignatureManagements",
          payloadGetDatas.value
        );
      } catch (error) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: error,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        isLoading.value = false;
      }
      isLoading.value = false;
    };
    const onPageChange = async (event) => {
      if (event.rows != rowsPerPage.value) {
        selectedPage.value = 1;
      } else {
        selectedPage.value = event.page + 1;
      }
      rowsPerPage.value = event.rows;
      await getSignatureManagements();
    };

    watch(isSharedRoleByMenuReady, function () {
      roleByMenuDataReady();
    });

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,

      isLoading,
      selectedSignatureManagement,
      rowsPerPage,

      //computeds
      signatureManagements,
      totalPage,
      totalRecords,
      showCurrentPage,

      //methods
      goBack,
      gotoEdit,
      onPageChange,
    };
  },
};
</script>
