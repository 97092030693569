<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ this.title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>
    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="bookbank">{{ lang.bookbank }}</label>
          <base-book-bank-ar-auto-complete
            id="selectedBookbank"
            v-model="selectedBookbank"
            class="inputfield w-full mt-1"
            @load-finish="bookbankDataReady"
            :class="{'p-invalid': bookBankError }"
            @item-select="bookBankError = false"
          />
          <small v-if="bookBankError" id="bookBank-help" class="p-error">
            {{ this.lang.validateBank }}
          </small>
        </div>
        <div class="lg:col-6 md:col-6 col-0 pl-0 pr-2"></div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="bankDateStart">{{ lang.bankDateStart }}</label>
          <PvCalendar
            id="bankDateStart"
            v-model="bankDateStart"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': bankDateStartError }"
            @date-select="bankDateStartError = false"
          />
          <small v-if="bankDateStartError" id="unit-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="bankDateEnd">{{ lang.to }}</label>
          <PvCalendar
            id="bankDateEnd"
            v-model="bankDateEnd"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': bankDateEndError }"
            @date-select="bankDateEndError = false"
          />
          <small v-if="bankDateEndError" id="unit-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="receiptDateStart">{{ lang.receiptDateStart }}</label>
          <PvCalendar
            id="receiptDateStart"
            v-model="receiptDateStart"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': receiptDateStartError }"
            @date-select="receiptDateStartError = false"
          />
          <small v-if="receiptDateStartError" id="unit-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="receiptDateEnd">{{ lang.to }}</label>
          <PvCalendar
            id="receiptDateEnd"
            v-model="receiptDateEnd"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': receiptDateEndError }"
            @date-select="receiptDateEndError = false"
          />
          <small v-if="receiptDateEndError" id="unit-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>
				<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
					<label for="fileNameText">{{ lang.fileName }}</label>
					<PvInputText
						id="fileNameText"
						type="text"
						class="inputfield w-full mt-1"
						v-model="fileNameText"
					/>
				</div>
      </section>
    </transition>
    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
        </div>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useTracking from "../../../hooks/tracking";
import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "toast-request"],
  setup() {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const { formatDateToISOString } = useFormat();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
        };
      }
    });
    return {
      lang: mergeLang,
			textFormClass,
			formatDateToISOString,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
      selectedBookbank: null,
      bookBankError: false,
      isSharedBookBankReady: false,
      bankDateStart: null,
      bankDateEnd: null,
      bankDateStartError: false,
      bankDateEndError: false,
      receiptDateStart: null,
      receiptDateEnd: null,
      receiptDateStartError: false,
      receiptDateEndError: false,
			type: "PDF",
      fileNameText: "bank_statement_audit",
    };
  },
  mounted() {
    this.setLoading(true);
    this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    initiateData() {
      let date = new Date();
      this.bankDateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.bankDateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.receiptDateStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.receiptDateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.selectedBookbank = null;
      this.bookBankError = false;
      this.isSharedBookBankReady = false;
      this.bankDateStartError = false;
      this.bankDateEndError = false;
      this.receiptDateStartError = false;
      this.receiptDateEndError = false;
			this.type = "PDF";
      this.fileNameText = "bank_statement_audit";
    },
    bookbankDataReady() {
      this.isSharedBookBankReady = true;
      this.checkShouldSetData();
    },
    checkShouldSetData() {
      if (this.isSharedBookBankReady) {
        this.setData();
        this.setLoading(false);
      }
    },
    setData() {
      this.selectedBookbank = this.bookbanks.find(
        (bookbank) => bookbank.projectId === this.selectedProject.id
      );
    },
    validateData() {
      let validateError = false;
      if (!this.selectedBookbank) {
        this.bookBankError = true;
        validateError = true;
      }
      if (this.selectedBankDateStart == null) {
        this.bankDateStartError = true;
        validateError = true;
      }
      if (this.selectedBankDateEnd == null) {
        this.bankDateEndError = true;
        validateError = true;
      }
      if (this.selectedReceiptDateStart == null) {
        this.receiptDateStartError = true;
        validateError = true;
      }
      if (this.selectedReceiptDateEnd == null) {
        this.receiptDateEndError = true;
        validateError = true;
      }
      return validateError;
    },
    async download(typeReport) {
			this.type = typeReport;
			if (this.validateData()) {
				return;
			}
			this.setLoading(true);
			try {
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
				const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
				this.setLoading(false);
			} catch (error) {
				this.$toast.add({
					severity: "error",
					summary: this.lang.notificationDialogHeader,
					detail: error,
					life: 3000,
				});
				this.setLoading(false);
			}
		},
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    title() {
      if (this.$store.getters.selectedLanguage === "TH")
        return this.reportObj.nameThai;
      else return this.reportObj.nameEnglish;
    },
		userId() {
        	return this.$store.getters.userId;
		},
		userName() {
			return this.$store.getters.userName;
		},
    selectedBankDateStart() {
      let bankDateStart = null;
      if (this.bankDateStart) {
        bankDateStart = this.formatDateToISOString(this.bankDateStart);
      }
      return bankDateStart;
    },
    selectedBankDateEnd() {
      let bankDateEnd = null;
      if (this.bankDateEnd) {
        bankDateEnd = this.formatDateToISOString(this.bankDateEnd);
      }
      return bankDateEnd;
    },
    selectedReceiptDateStart() {
      let receiptDateStart = null;
      if (this.receiptDateStart) {
        receiptDateStart = this.formatDateToISOString(this.receiptDateStart);
      }
      return receiptDateStart;
    },
    selectedReceiptDateEnd() {
      let receiptDateEnd = null;
      if (this.receiptDateEnd) {
        receiptDateEnd = this.formatDateToISOString(this.receiptDateEnd);
      }
      return receiptDateEnd;
    },
    bookbanks() {
      return this.$store.getters["shared/bookBankArs"];
    },
		fileName() {
			let fileName = this.type == "PDF" ? ".pdf" : ".xlsx";
			return this.fileNameText + fileName;
		},
		fileType() {
			let fileType =
				this.type == "EXCEL"
				? "_excel.xlsx"
				: this.type == "JASPEREXCEL"
				? ".xlsx"
				: ".pdf";
			return fileType;
		},
    payload() {
      let payload = {
        ProjectId: this.selectedProject.id,
          Bookbank: this.selectedBookbank.code,
          ToBankDateStart: this.selectedBankDateStart,
          ToBankDateEnd: this.selectedBankDateEnd,
          ReceiptDateStart: this.selectedReceiptDateStart,
          ReceiptDateEnd: this.selectedReceiptDateEnd,
				FileName: this.fileName,
				JasperPath: this.reportObj.path + this.fileType,
				DocType: this.type,
        UserId: this.userId,
        PrintBy: this.userName,
				RequestId: 0,
        ReportName: "AccruedTransferIncome",
      };
      return payload;
    },
  },
	watch: {
		reportObj() {
			this.initiateData();
		},
  },
};
</script>
