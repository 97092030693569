<template>
	<!-- <div class="p-inputgroup"> -->
	<PvAutoComplete
		:dropdown="true"
		:suggestions="filteredEmployees"
		field="codeAndName"
		@complete="searchEmployee"
		@dropdown-click="filteredEmployees"
		forceSelection
	/>
	<!-- <PvButton icon="pi pi-plus" class="p-button-rounded p-button-outlined ml-2" />
	</div> -->
</template>

<script>
import useGetEmployee from "../../hooks/getEmployee.js";
export default {
	setup() {
		const { filteredEmployees, searchText, isSharedEmployeeReady, searchEmployee } =
			useGetEmployee();

		return {
			filteredEmployees,
			searchText,
			isSharedEmployeeReady,
			searchEmployee,
		};
	},
	emits: ["load-finish"],
	watch: {
		isSharedEmployeeReady(curValue) {
			if (curValue) {
				this.$emit("load-finish");
			}
		},
	},
};
</script>
