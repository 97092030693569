export default {
	title: "ตรวจสอบเงินโอน",
	status: "สถานะ",
	all: "ทั้งหมด",
	waiting: "รอตรวจสอบ",
	receiptCreated: "ออกใบเสร็จแล้ว",
	rejected: "ไม่ผ่าน",
	uploadedPeriod: "ช่วงวันที่อัปโหลด",
	invoiceCode: "เลขที่ใบแจ้งหนี้",
	unitCode: "ยูนิต",
	unitAddressNo: "บ้านเลขที่",
	payerName: "ชื่อผู้ชำระ",
	totalAmount: "ยอดรวม",
	columnStatus: "สถานะ",
	columnUploadDate: "วันที่อัปโหลด",
	columnInvoiceCode: "เลขที่ใบแจ้งหนี้",
	columnUnitCode: "ยูนิต",
	columnUnitAddressNo: "บ้านเลขที่",
	columnPayerName: "ชื่อผู้ชำระ",
	columnTotalAmount: "ยอดรวม",

};

