export default {
	setEmployee(state, payload) {
		let employees = [...state.employee.employees];
		for (const employee of payload.employees) {
			if (employees.findIndex((c) => c.id === employee.id) < 0) {
				employees.push(employee);
			}
		}
		state.employee.employees = employees;
		state.employee.currentPage = payload.currentPage;
		state.employee.totalPage = payload.totalPage;
	},
	resetEmployee(state) {
		const employee = {
			employees: [],
			totalPage: 0,
			currentPage: 0,
		};
		state.employee = employee;
	},
	setEmployeesByUserIds(state, payload){
		state.employee.employeesByUserIds = payload;
	}
};
