export default {
    toolbarTitle: "รายงานลงทะเบียน LOS APP",
    title: "ข้อมูลการลงทะเบียนการใช้แอป",
    owner: "เจ้าของ",
    rential: "ผู้เช่า",
    stay: "ผู้พักอาศัย",

    allStatusContent: "{number} คน",
    otherStatusContent: "({number} คน)",

    allStatus: "ทั้งหมด",
    registered: "ลงทะเบียนแล้ว",
    waitRegister: "รอลงทะเบียน",
    waitApprove: "รออนุมัติ",

    columnUnitCode: "ยูนิต",
    columnAddressNo: "บ้านเลขที่",
    columnCustomerName: "ชื่อลูกค้า",
    columnCustomerMobile: "เบอร์โทรศัพท์",
    columnLivingType: "ประเภทการอยู่อาศัย",
    columnRegisterStatus: "สถานะการลงทะเบียน",

	dialogTitleExported: "ทำรายการสำเร็จ",
	dialogContentExported: "ส่งออกข้อมูลสำเร็จ",
}