export default {
  async getAccountingFrequentlys(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/AccountingFrequently/AccountigFrequentlys";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.projectId) {
      url = url + "&projectId=" + payload.projectId;
    }

    if (payload.accountBookId) {
      url = url + "&accountBookId=" + payload.accountBookId;
    }

    if (payload.accountCode) {
      url = url + "&accountCode=" + payload.accountCode;
    }

    if (payload.accountName) {
      url = url + "&accountName=" + payload.accountName;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get accounting frequently"
      );
      throw error;
    }

    // console.log("getAccountingFrequentlys",responseData);

    context.commit("setAccountingFrequentlys", responseData);
  },
  async getAccountingFrequentlyById(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + '/AccountingFrequently/' + payload;
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(
        responseData.message || "Failed to get accounting frequently by id."
      );
      throw error;
    }
    // console.log("getAccountingFrequentlyById",responseData);

    context.commit("setSelectedAccountingFrequently", responseData.data);
  },
  resetSelectedAccountingFrequently(context) {
    context.commit("setSelectedAccountingFrequently", null);
  },
  async createAccountingFrequently(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + "/AccountingFrequently/Add";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || "Failed to add accounting frequently");
      throw error;
    }
    // console.log(responseData);

    context.commit("setSelectedAccountingFrequently", responseData);
  },
  async updateAccountingFrequently(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + "/AccountingFrequently/Update";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || "Failed to update accounting frequently");
      throw error;
    }
    // console.log(responseData);

    context.commit("setSelectedAccountingFrequently", responseData);
  },
  async deleteAccountingFrequently(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + "/AccountingFrequently/Delete";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "Delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || "Failed to delete accounting frequently");
      throw error;
    }
    // console.log(responseData);

    context.commit("setSelectedAccountingFrequently", responseData);
  },
  resetAccountingFrequentlys(context) {
    context.commit("resetAccountingFrequentlys");
  }
};
