<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.budgetDetail }}</label>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>
    <section class="flex flex-wrap">
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
        <label class="p-text-secondary">{{ lang.columnName }}</label>
        <div>
          <span class="font-md font-normal p-text-secondary">{{
            budget.name
          }}</span>
        </div>
      </div>
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
        <label class="p-text-secondary">{{ lang.columnYear }}</label>
        <div>
          <span class="font-md font-normal p-text-secondary">{{
            concatYear(budget.yearOf).name
          }}</span>
        </div>
      </div>
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2">
        <label class="p-text-secondary">{{ lang.remarkBudget }}</label>
        <div>
          <span class="font-md font-normal p-text-secondary">{{
            budget.remark
          }}</span>
        </div>
      </div>

      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label class="p-text-secondary">{{ lang.periodBudgetFrom }}</label>
        <div>
          <span class="font-md font-normal p-text-secondary">{{
            formatDateToPeriod(budget.dateStart)
          }}</span>
        </div>
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
        <label class="p-text-secondary">{{ lang.periodBudgetTo }}</label>
        <div>
          <span class="font-md font-normal p-text-secondary">{{
            formatDateToPeriod(budget.dateEnd)
          }}</span>
        </div>
      </div>
      
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label class="p-text-secondary">{{ lang.createBy }}</label>
        <div>
          <span class="font-md font-normal p-text-secondary">{{
            budget.createBy
          }}</span>
        </div>
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
        <label class="p-text-secondary">{{ lang.createDate }}</label>
        <div>
          <span class="font-md font-normal p-text-secondary">{{
            formatDate(budget.createDate, false)
          }}</span>
        </div>
      </div>

      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
        <label class="p-text-secondary">{{ lang.updateBy }}</label>
        <div>
          <span class="font-md font-normal p-text-secondary">{{
            budget.updateBy
          }}</span>
        </div>
      </div>
      <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
        <label class="p-text-secondary">{{ lang.updateDate }}</label>
        <div>
          <span class="font-md font-normal p-text-secondary">{{
            formatDate(budget.updateDate, false)
          }}</span>
        </div>
      </div>
      
    </section>

    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-end align-items-center"
      >
        <div class="lg:col-3 md:col-4 col-6 pl-1 pr-1">
          <PvButton
            :label="lang.close"
            class="p-button-outlined p-button-secondary w-full"
            @click="closeDialog()"
          />
        </div>
      </div>
    </template>
  </PvDialog>
</template>
<script>
import useLang from "../../hooks/lang.js";
import useFormat from "../../hooks/format.js";
import useGetBudgetYear from "../../hooks/getBudgetYear.js";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  emits: ["close"],
  props: {
    budget: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { lang } = useLang();
    const store = useStore();
    const { concatYear } = useGetBudgetYear();
    const mergeLang = computed(() => {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.budget.listing,
          ...lang.th.budget.edit,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.budget.listing,
          ...lang.en.budget.edit,
        };
      }
    });
    const { formatDateToPeriod, formatDate } = useFormat();

    const closeDialog = () => {
      emit("close");
    };

    return {
      lang: mergeLang,
      closeDialog,
      concatYear,
      formatDateToPeriod,
      formatDate,
    };
  },
};
</script>
