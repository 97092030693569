<template>
  <PvAutoComplete
    :dropdown="true"
    :suggestions="filteredAssetRegisters"
    field="codeAndName"
    @complete="searchAssetRegister"
    @dropdown-click="filteredAssetRegisters"
    forceSelection
  />
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default {
  emits: ["load-finish"],
  setup() {
    const store = useStore();
    let searchText = ref(null);

    const assetRegisters = computed(() => {
      return store.getters["assetAssetRegister/assetRegisterDropDowns"]
    });

    // const assetRegisterIsLastPage = computed(() => {
    //   return store.getters["assetAssetRegister/assetRegisterIsLastPage"];
    // });

    const filteredAssetRegisters = computed(() => {
      if (!searchText.value) {
        //ช่องว่าง
        const result = [...assetRegisters.value];
        return result;
      } else {
        return assetRegisters.value.filter((asset) => {
          return asset.codeAndName
            .toLowerCase()
            .includes(searchText.value.toLowerCase());
        });
      }
    });

    const searchAssetRegister = function (event) {
      // console.log(event);
      if (!event.query.trim().length) {
        //ช่องว่าง
        // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
        searchText.value = "";
        searchText.value = null;
      } else {
        searchText.value = event.query;
      }
    };

    let isAssetRegisterReady = ref(false);

    // const getAllAssetRegisters = async function () {
    //    if (!assetRegisterIsLastPage.value) {
    //     await store.dispatch("assetAssetRegister/getAllAssetRegisters");
    //    }
    //   isAssetRegisterReady.value = true;
    // };

    const getAssetRegisterByCode = function (code) {
      return assetRegisters.value.find((asset) => {
        return asset.code.toLowerCase() === code.trim().toLowerCase();
      });
    };

    onMounted(async () => {
      await store.dispatch("assetAssetRegister/getAllAssetRegisters");
      isAssetRegisterReady.value = true;
    });

    return {
      searchText,
      filteredAssetRegisters,
      searchAssetRegister,
      getAssetRegisterByCode,
      isAssetRegisterReady,
    };
  },
  watch: {
    isAssetRegisterReady(curValue) {
      if (curValue) {
        this.$emit("load-finish");
      }
    },
  },
};
</script>
