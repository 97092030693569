import { useStore } from "vuex";
import { ref, computed, onMounted} from "vue";
export default function useGetProductAP() {
  const store = useStore();
  let searchText = ref(null);

  const products = computed(() => {
    return store.getters["shared/productApProducts"];
  });

  const productIsLastPage = computed(() => {
    return store.getters["shared/productApIsLastPage"];
  });

  const filteredProducts = computed(() => {
    if (!searchText.value) {
      //ช่องว่าง
      const result = [...products.value];
      return result;
    } else {
      return products.value.filter((product) => {
        return product.searchText
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const searchProduct = function (event) {
    // console.log(event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchText.value = "";
      searchText.value = null;
    } else {
      searchText.value = event.query;
    }
  };

  let isSharedProductReady = ref(false);

  const getAllProducts = async function () {
    if (!productIsLastPage.value) {
      await store.dispatch("shared/getAllProductAPs");
    }
    isSharedProductReady.value = true;
  };

  const getProductByCode = function (code) {
    return products.value.find((product) => {
      return product.code.toLowerCase() === code.trim().toLowerCase();
    });
  };

  onMounted(async () => {
    await getAllProducts();
  });


  return {
    searchProduct,
    filteredProducts,
    searchText,
    getProductByCode,
    isSharedProductReady,
  };
}
