export default {
  async getPurchaseInvoices(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseInvoice";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    // console.log("getPurchaseInvoices_payload=",payload);
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get PurchaseInvoice");
      throw error;
    }
    //  console.log("getPurchaseInvoices=",responseData);
    context.commit("setPurchaseInvoices", responseData);
  },
  async getPurchaseInvoice(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseInvoice?Page=1&Limit=10";

    if(payload.where){
        url = url + '&where=' + JSON.stringify(payload.where);
    }
    // console.log("getPrepaid_URL>>>",url);
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {      
      const error = new Error(responseData.message || "Failed to get PurchaseInvoice.");
      throw error;
    }
      // console.log("setSelectedPurchaseInvoice=",responseData);
    if(responseData.data.length > 0){
      context.commit('setSelectedPurchaseInvoice', responseData.data[0]);
    }   
        
  },

  resetSelectedPurchaseInvoice(context) {
    // console.log("resetAPBook");
    context.commit("setSelectedPurchaseInvoice",null);
  },
  
  async getPurchaseOrders(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseOrder/PurchaseOrderToPI";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + encodeURI(JSON.stringify(payload.where));
    }

    // console.log("getPurchaseOrders_payload=",payload);
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get Purchase Orders");
      throw error;
    }
    // console.log("setPurchaseOrders=",responseData);
    context.commit("setPurchaseOrders", responseData);
  },
    
  async AddUpdatePurchaseInvoice(context, payload) {

    let url = process.env.VUE_APP_BACKEND_URL + `/PurchaseInvoice/${payload.typeInsert}`;
		const fallbackErrorMessage = `Failed to ${payload.typeInsert} voucher`;
		const token = context.rootGetters.token;     
    //  console.log("payload=",payload);
    
		const response = await fetch(url, {
			method: payload.typeInsert === "Add" ? "post" : "put",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},

			body: JSON.stringify(payload),
		});

    //  console.log("payload=",JSON.stringify(payload));
    const responseData = await response.json();
    
		if (!response.ok) {
			// console.log(responseData);
			let message = null;
			if (Object.prototype.hasOwnProperty.call(responseData, "errors")) {
				message = responseData.title;
				for (const key in responseData.errors) {
					message += ", " + key.replace("$.", "");
				}
			} else if (Object.prototype.hasOwnProperty.call(responseData, "message")) {
				message = responseData.message;
			}
			const error = new Error(message || fallbackErrorMessage);
			throw error;
		}
    // console.log("AddPrepaid_responseData>>>",responseData);            
    await context.commit('setSelectedPurchaseInvoice', responseData.data);      
        
  },

  async ApprovePurchaseInvoice(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/PurchaseInvoice/Approve`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to revert');
      throw error;
    }

    // console.log("ApprovePurchaseInvoice=",responseData);
    if(payload.FormApprove === "Entry"){
      if(responseData.data.length > 0){
        context.commit('setSelectedPurchaseInvoice', responseData.data[0]);
      }   
    }
    else{
      context.commit("setPurchaseInvoices", responseData);
    }
        
  },
  
  async CancelPurchaseInvoice(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/PurchaseInvoice/Cancel`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to revert');
      throw error;
    }

    // console.log("CancelPurchaseInvoice=",responseData);
    if(payload.FormCancel === "Entry"){
      if(responseData.data.length > 0){
        context.commit('setSelectedPurchaseInvoice', responseData.data[0]);
      }   
    }
    else{
      context.commit("setPurchaseInvoices", responseData);
    }
        
  },

  
  async DeletePurchaseInvoice(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/PurchaseInvoice/Delete`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to revert');
      throw error;
    }

    context.commit("setPurchaseInvoices", responseData);
    
        
  },

  async PrintPurchaseInvoice(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseInvoice/Print";
    
    const token = context.rootGetters.token;
    
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(payload),
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      
    });  
           
    // response.blob().then(function(blob) {
    //   // console.log("PrintPrepaid_blob>>>",blob);
    //     var url = window.URL.createObjectURL(blob);
    //     //window.location.assign(url);
    //     var a = document.createElement('a');
    //     a.href = url;
    //     const d = new Date();
    //     let dateText = d.toISOString();
    //     a.download = `PettyCash_${dateText}.pdf`;
    //     document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    //     a.click();
    //     a.remove(); //afterwards we remove the element again
    // });
    
    let urlBlob = ''
    let blob = await response.blob();
    urlBlob = window.URL.createObjectURL(blob);
    context.commit('setBlob', urlBlob);
     
  },


  async RevertApprovePurchaseInvoice(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + `/PurchaseInvoice/RevertApprove`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to revert');
      throw error;
    }

    // console.log("ApprovePurchaseInvoice=",responseData);
    if(payload.FormApprove === "Entry"){
      if(responseData.data.length > 0){
        context.commit('setSelectedPurchaseInvoice', responseData.data[0]);
      }   
    }
    else{
      context.commit("setPurchaseInvoices", responseData);
    }
        
  },   
  async getPurchaseInvoiceReference(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/PurchaseInvoice/PurchaseInvoiceReference";
   
    const token = context.rootGetters.token;
   
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to get Purchase Orders");
      throw error;
    }
    // console.log("setPurchaseInvoiceReference=",responseData);
    context.commit("setPurchaseInvoiceReference", responseData.data);
  },


};
