export default {
    backTitle: "Edit Payment Request",
    title: "Payment Request Detail",
    withholdingTaxButton: "Withholding Tax",
    paymentButton: "Payment",
    settingGL: "setting GL",
    autoGL: "save GL",
    activeLockStatus: "Document Lock",
    activeUnlockStatus: "Document Unlock",

    code: "Code",
    paymentRequestType: "Payment Request Type",
    budgetYear: "Year Budaget",
    supplier: "Supplier",
    remark: "Remark",

    detail: "Detail",
    addDetail: "Add Detail",

    columnItemNo: "No",
    columnProductCode: "Product Code",
    columnProductName: "Product Name",
    columnBudgetWithin: "Budget Within",
    columnRemark: "Remark",
    columnPeriod: "Period",
    columnPrice: "Price",
    columnQuantity: "Quantity",
    columnCode: "Code",

    totalAmount: "Total Amount",
    discountText: "Discount",
    discountTip: "*You can specify a percentage, such as 10%.",
    discountAmount: "Discount Amount",
    beforeVat: "Before Vat",
    vatRate: "Vat Rate",
    vatAmount: "Vat Amount",
    vatCalculatedFromSystem: "*Calculation value recommended by the system : ",
    netAmount: "Net Amount",
    taxAmount: "Tax Amount",
    paymentAmount: "Payment Amount",
    validateCheckDiscount: "Discount format can be enter only numbers and %.",
    validateDiscountAmount: "Discount amount must not be more than total amount.",
    validateNoSupplierSelect: "Please select supplier",
    validateDetailSelect: "Please select Item.",

    titleDetailDialog: "Add/Edit Product",
    validateNoProductSelect: "Please select product.",
    validateNoPrice: "Please enter price.",
    validateNoQuantity: "Please enter quantity.",
    product: "Product*",
    price: "Price*",
    quantity: "Quantity*",
    discountDetail: "Discount",
    discountHeader: "Discount bill",
    totalAmountDetail: "Total",
    titleCompareBudget: "Compare Budget",
    expenseAccumulated: "Expense Accumulated",
    expenseBalance: "Expense Balance",
    paymentAccumulated: "Payment Accumulated",
    paymentBalance: "Payment Balance",
    accountCompare: "Account Compare",

    withinBudget: "Within Budget",
    overBudget: "Over Budget",
    budgetNotfound: "Budget Not found",
    generateProduct: "Generate Product By Input Number",
    createItem: "Create Item",

    titleSettingDialog: "Setting Voucher",
    descriptionSettingDialog: "The information set here will be referenced for batch accounting and individual invoice accounting.",
    confirmUseVoucher: "Confirm Voucher Book",
    isUseExpenseVoucher: "PJ : Purchase Journal",
    isUsePaymentVoucher: "PV : Purchase Voucher",
    documentDateSetting: "Document Date in PV : Purchase Voucher",
    productVoucherSetting: "Product to Save PJ : Purchase Journal",
    haveReferVoucerWording: "Selection is not possible because this item has already in Voucher.",
    productHasVoucher: "Product has PJ : Purchase Journal"
}