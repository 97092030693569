// วิธีใช้งาน
// 1.สร้าง route ใน router/index.js
// 2.ระบุ navigation ทั้ง 2 ภาษาใน lang/navigation/th,en
//     - name  : navigation จะใช้ name ในการ map กับแต่ละ route
//               **ซึ่งต้องระบุให้ตรงกับ name ที่ระบุใน file router/index.js
//     - items : label --> ระบุคำ/ชื่อที่จะแสดง สำหรับแต่ละ item
//               url --> ระบุ path เพื่อ link ไป path ต่างๆ (ใช้ path ที่ระบุใน router/index.js)

//ตัวอย่างข้อมูลสำหรับ Set navigation
// {
//     name: 'ap-purchase-order-edit',
//     items: [
//         { label: "Purchase Order", url: "/ap/purchase-order/:apBookId" },
//         { label: "{text}Purchase Order", url: "/ap/purchase-order/:apBookId/:id", type: "edit" },
//     >> type: "edit" ระบุเพื่อบอกว่าหน้าไหนต้องมีการแก้ไข
//     ]
// },

const navigations = [
	{
		name: "project-document",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Project", url: "" },
			{ label: "Project Document", url: "" },
		],
	},
	{
		name: "master-project",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Project", url: "" },
			{ label: "Project Information", url: "" },
		],
	},
	{
		name: "master-project-edit",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Project", url: "" },
			{ label: "Project Information", url: "/master/project" },
			{ label: "Edit Project Information", url: "" },
		],
	},
	{
		name: "product-advertisement-edit",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Project", url: "" },
			{ label: "Edit Advertisementt", url: "" },
		],
	},
	{
		name: "master-meter",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Meter", url: "" },
		],
	},
	{
		name: "master-meter-edit",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Meter", url: "/meter/:meterType" },
			{ label: "{text} Meter", url: "", type: "edit" },
		],
	},
	{
		name: "master-meter-auto-create",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Meter", url: "/meter/:meterType" },
			{ label: "Auto create Meter", url: "" },
		],
	},
	{
		name: "master-meter-usage-seting",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Meter", url: "" },
			{ label: "Meter Usage Setting", url: "" },
		],
	},
	{
		name: "project-penaltycalculation-edit",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Project Penalty Calculation", url: "" },
		],
	},
	{
		name: "product-penaltycalculation-edit",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Account Receivable Product", url: "/ar-product" },
			{ label: "Penalty", url: "" },
		],
	},
	{
		name: "unit",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			{ label: "Unit", url: "" },
		],
	},
	{
		name: "unit-edit",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			{ label: "Unit", url: "/unit" },
			{ label: "{text} Unit", url: "", type: "edit" },
		],
	},
	{
		name: "unit-resident-edit",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			{ label: "Room/House", url: "/unit" },
			{ label: "{text} Resident", url: "/unit/:unitId", type: "edit" },
			{ label: "{text} Resident", url: "", type: "edit" },
		],
	},
	{
		name: "resident",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			{ label: "Resident", url: "" },
		],
	},
	{
		name: "resident-edit",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			{ label: "Resident", url: "/resident" },
			{ label: "{text} Resident", url: "", type: "edit" },
		],
	},
	{
		name: "ar-product",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Account Receivable Product", url: "" },
		],
	},
	{
		name: "ar-product-edit",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Account Receivable Product", url: "/ar-product" },
			{ label: "{text} Account Receivable Product", url: "", type: "edit" },
		],
	},
	{
		name: "unit-product-setup-invoice",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			{ label: "Unit", url: "/unit" },
			{ label: "UnitProductSetupInvoice", url: "" },
		],
	},
	{
		name: "bookBank",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Account Setup", url: "" },
			{ label: "Bookbank", url: "" },
		],
	},
	{
		name: "book-bank-edit",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Account Setup", url: "" },
			{ label: "Bookbank", url: "/book-bank" },
			{ label: "{text} Bookbank", url: "", type: "edit" },
		],
	},
	{
		name: "form-version",
		items: [
			{ label: "Help", url: "" },
			{ label: "Form Version Setting", url: "" },
		],
	},
	{
		name: "meter-usage-water",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Meter", url: "" },
			{ label: "Water Meter Usage", url: "" },
		],
	},
	{
		name: "meter-usage-electricity",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Meter", url: "" },
			{ label: "Electricity Meter Usage", url: "" },
		],
	},
	{
		name: "meter-usage-cooling-air",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Meter", url: "" },
			{ label: "Cooling Air Meter Usage", url: "" },
		],
	},
	{
		name: "meter-usage-water-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Meter", url: "" },
			{ label: "Water Meter Usage", url: "/meter-usage/water/W" },
			{ label: "Edit Water Meter Usage", url: "" },
		],
	},
	{
		name: "meter-usage-electricity-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Meter", url: "" },
			{ label: "Electricity Meter Usage", url: "/meter-usage/electricity/E" },
			{ label: "Edit Electricity Meter Usage", url: "" },
		],
	},
	{
		name: "meter-usage-cooling-air-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Meter", url: "" },
			{ label: "Cooling Air Meter Usage", url: "/meter-usage/cooling-air/A" },
			{ label: "Edit Cooling Air Meter Usage", url: "" },
		],
	},
	{
		name: "ar-invoice",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "invoice System", url: "" },
			{ label: "Invoice", url: "" },
		],
	},
	{
		name: "ar-invoice-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "invoice System", url: "" },
			{ label: "Invoice", url: "/ar/invoice" },
			{ label: "Edit Invoice", url: "", type: "edit" },
		],
	},
	{
		name: "outstanding-letter",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "invoice System", url: "" },
			{ label: "Outstanding Letter", url: "" },
		],
	},
	{
		name: "account-book",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Account Setup", url: "" },
			{ label: "Account Book", url: "" },
		],
	},
	{
		name: "account-book-edit",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Account Setup", url: "" },
			{ label: "Account Book", url: "/account-book" },
			{ label: "{text} Account Book", url: "", type: "edit" },
		],
	},
	{
		name: "ar-receipt",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Receipt System", url: "" },
			{ label: "Receipt", url: "" },
		],
	},
	{
		name: "ar-receipt-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Receipt System", url: "" },
			{ label: "Receipt", url: "/ar/receipt" },
			{ label: "{text} Receipt", url: "", type: "edit" },
		],
	},
	{
		name: "visitor-control",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Receipt System", url: "" },
			{ label: "Receipt", url: "/ar/receipt" },
			{ label: "Receipt Visitor control", url: "" },
		],
	},
	{
		name: "visitor-control-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Receipt System", url: "" },
			{ label: "Receipt", url: "/ar/receipt" },
			{ label: "Receipt visitor control", url: "/ar/visitor-control" },
			{ label: "Edit receipt Visitor control", url: "", type: "edit" },
		],
	},
	{
		name: "ar-receipt-import",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Receipt System", url: "" },
			{ label: "Receipt", url: "/ar/receipt" },
			{ label: "Import Receipt", url: "" },
		],
	},
	{
		name: "report-request",
		items: [
			{ label: "List Request", url: "" },
		],
	},
	{
		name: "ar-notice",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Notice", url: "" },
			{ label: "Notice", url: "" },
		],
	},
	{
		name: "ar-notice-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Notice", url: "" },
			{ label: "Notice", url: "/ar/notice" },
			{ label: "Notice Detail", url: "", type: "edit" },
		],
	},
	{
		name: "ar-calculate-invoice",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "invoice System", url: "" },
			{ label: "Calculate Invoice", url: "" },
		],
	},
	{
		name: "ar-invoice-wait-for-approve",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "invoice System", url: "" },
			{ label: "Calculate Invoice", url: "" },
		],
	},
	{
		name: "ar-edit-invoice-wait-for-approve",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "invoice System", url: "" },
			{ label: "Calculate Invoice", url: "" },
			{ label: "Calculate Invoice Detail", url: "" },
		],
	},
	{
		name: "ar-use-prepaid-and-deposit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Receipt System", url: "" },
			{ label: "Use Prepaid And Deposit", url: "" },
		],
	},
	{
		name: "ar-preview-use-prepaid-and-deposit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Receipt System", url: "" },
			{ label: "Use Prepaid And Deposit", url: "" },
			{ label: "Preview Use Prepaid And Deposit", url: "" },
		],
	},
	{
		name: "ar-calculate-notice",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Notice", url: "" },
			{ label: "Calculate Notice", url: "" },
		],
	},
	{
		name: "ar-notice-wait-for-approve",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Notice", url: "" },
			{ label: "Calculate Notice", url: "" },
		],
	},
	{
		name: "ar-edit-notice-wait-for-approve",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Notice", url: "" },
			{ label: "Calculate Notice", url: "" },
			{ label: "Notice Detail", url: "" },
		],
	},
	{
		name: "master-policy",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Policy", url: "" },
		],
	},
	{
		name: "master-policy-edit",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Policy", url: "/master/policy" },
			{ label: "{text} Policy", url: "", type: "edit" },
		],
	},
	{
		name: "ar-bank-ar",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Receipt System", url: "" },
			{ label: "Bank AR", url: "" },
		],
	},
	{
		name: "ar-bank-ar-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Receipt System", url: "" },
			{ label: "Bank AR", url: "/ar/bank-ar" },
			{ label: "{text} Bank AR", url: "", type: "edit" },
		],
	},
	{
		name: "ar-deposit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Receipt System", url: "" },
			{ label: "Deposit", url: "" },
		]
	},
	{
		name: "ar-deposit-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Receipt System", url: "" },
			{ label: "Deposit", url: "/ar/deposit" },
			{ label: "{text} Deposit", url: "", type: "edit" },
		]
	},
	{
		name: "ar-credit-note",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Receipt System", url: "" },
			{ label: "Credit Note", url: "" },
		]
	},
	{
		name: "ar-credit-note-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Receipt System", url: "" },
			{ label: "Credit Note", url: "/ar/credit-note" },
			{ label: "{text} Credit Note", url: "", type: "edit" },
		]
	},
	{
		name: "customer-group",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			{ label: "Customer Group", url: "" },
		],
	},
	{
		name: "customer-group-edit",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			{ label: "Customer Group", url: "/customer-group" },
			{ label: "{text} Customer Group", url: "", type: "edit" },
		],
	},
	{
		name: "ar-refund-guarantee",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Refund System", url: "" },
			{ label: "Refund Guarantee", url: "" },
		],
	},
	{
		name: "ar-refund-guarantee-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Refund System", url: "" },
			{ label: "Refund Guarantee", url: "/ar/refund-guarantee" },
			{ label: "{text} Refund Guarantee", url: "", type: "edit" },
		]
	},
	{
		name: "product-ap",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Product AP", url: "" },
		],
	},
	{
		name: "product-ap-edit",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Product AP", url: "/product-ap" },
			{ label: "{text} Product AP", url: "", type: "edit" },
		],
	},
	{
		name: "debt-free",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			{ label: "Debt Free", url: "" },
		],
	},
	{
		name: "debt-free-request",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			// { label: "Debt Free", url: "/debt-free" },
			{ label: "Debt Free Request", url: "" },
		],
	},
	{
		name: "debt-free-request-edit",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			// { label: "Debt Free", url: "/debt-free" },
			{ label: "Debt Free Request", url: "/debt-free/request" },
			{ label: "{text} Debt Free Request", url: "", type: "edit" },
		]
	},
	{
		name: "debt-free-approve",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			// { label: "Debt Free", url: "/debt-free" },
			{ label: "Debt Free Approve", url: "" },
		],
	},
	{
		name: "debt-free-approve-edit",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			// { label: "Debt Free", url: "/debt-free" },
			{ label: "Debt Free Approve", url: "/debt-free/approve" },
			{ label: "Edit Debt Free Approve", url: "" },
		]
	},
	{
		name: "debt-free-print",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			// { label: "Debt Free", url: "/debt-free" },
			{ label: "Debt Free Print", url: "" },
		],
	},
	{
		name: "debt-free-follow-up",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			// { label: "Debt Free", url: "/debt-free" },
			{ label: "Debt Free Follow Up", url: "" },
		],
	},
	{
		name: "debt-free-follow-up-edit",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			// { label: "Debt Free", url: "/debt-free" },
			{ label: "Debt Free Follow Up", url: "/debt-free/follow-up" },
			{ label: "{text} Debt Free Follow Up", url: "", type: "edit" },
		],
	},
	{
		name: "supplier",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Supplier", url: "" },
		],
	},
	{
		name: "supplier-edit",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Supplier", url: "/supplier" },
			{ label: "{text} Supplier", url: "", type: "edit" },
		]
	},
	{
		name: "closing-entry",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Accounting", url: "" },
			{ label: "Closing Entry", url: "" },
		],
	},
	{
		name: "closing-entry-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Accounting", url: "" },
			{ label: "Closing Entry", url: "/gl/closing-entry" },
			{ label: "Closing Entry Information", url: "" },
		],
	},
	{
		name: "accounting-frequently",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Account Setup", url: "" },
			{ label: "Account Frequently", url: "" },
		],
	},
	{
		name: "accounting-frequently-accountBookId",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Account Setup", url: "" },
			{ label: "Account Frequently", url: "" },
		],
	},
	{
		name: "accounting-frequently-accountBookId-edit",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Account Setup", url: "" },
			{ label: "Account Frequently", url: "/accounting-frequently" },
			{ label: "{text} Account Frequently", url: "", type: "edit" },
		],
	},
	{
		name: "account-chart",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Account Setup", url: "" },
			{ label: "Account Chart", url: "" },
		],
	},
	{
		name: "account-chart-edit",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Account Setup", url: "" },
			{ label: "Account Chart", url: "/account-chart" },
			{ label: "{text} Account Chart", url: "", type: "edit" },
		]
	},
	{
		name: "dashboard-mobile-register",
		items: [
			{ label: "Register Application", url: "" },
		]
	},
	{
		name: "accounting-policy",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Account Setup", url: "" },
			{ label: "Account Policy", url: "" },
		]
	},
	{
		name: "phone-book",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Project", url: "" },
			{ label: "Phone Book", url: "" },
		],
	},
	{
		name: "phone-book-edit",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Project", url: "" },
			{ label: "Phone Book", url: "/phone-book" },
			{ label: "{text} Phone Book", url: "", type: "edit" },
		],
	},
	{
		name: "home",
		items: [
			{ label: "", url: "" },
		],
	},
	{
		name: "report-ar",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "AR Report", url: "" },
		],
	},
	{
		name: "report-receipt",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Receipt System", url: "" },
			{ label: "Receipt Report", url: "" },
		],
	},
	{
		name: "report-notice",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Notice", url: "" },
			{ label: "Notice Report", url: "" },
		],
	},
	{
		name: "report-ap",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "AP Report", url: "" },
		],
	},
	{
		name: "report-withholding-tax",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Withholding Tax Report", url: "" },
		],
	},
	{
		name: "report-gl",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Accounting", url: "" },
			{ label: "Accounting Report", url: "" },
		],
	},
	{
		name: "report-pc",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Accounting", url: "" },
			{ label: "Petty Cash Report", url: "" },
		],
	},
	{
		name: "report-asset",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Asset", url: "" },
			{ label: "Asset Report", url: "" },
		],
	},
	{
		name: "report-meter",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Meter", url: "" },
			{ label: "Meter Report", url: "" },
		],
	},
	{
		name: "report-debt-free",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			{ label: "Debt Free Report", url: "" },
		],
	},
	{
		name: "asset-register",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Asset", url: "" },
			{ label: "Asset Register", url: "" },
		],
	},
	{
		name: "asset-register-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Asset", url: "" },
			{ label: "Asset Register", url: "/asset/register" },
			{ label: "{text} Asset Register", url: "", type: "edit" },
		]
	},
	{
		name: "ar-prepaid-refund",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Refund System", url: "" },
			{ label: "Prepaid Refund", url: "" },
		],
	},
	{
		name: "ar-prepaid-refund-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Refund System", url: "" },
			{ label: "Prepaid Refund", url: "/ar/prepaid-refund" },
			{ label: "{text} Prepaid Refund", url: "", type: "edit" },
		],
	},
	{
		name: "ap-withholding-tax",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Withholding Tax", url: "" },
		],
	},
	{
		name: "ap-withholding-tax-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Withholding Tax", url: "/ap/withholding-tax" },
			{ label: "{text} Withholding Tax", url: "", type: "edit" },
		],
	},
	{
		name: "ap-bank-ap",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Bank AP", url: "" },
		],
	},
	{
		name: "ap-bank-ap-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Bank AP", url: "/ap/bank-ap" },
			{ label: "{text} Bank AP", url: "", type: "edit" },
		],
	},
	{
		name: "ap-purchase-requisition",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Purchase Requisition", url: ""},
		],
	},
	{
		name: "ap-purchase-requisition-apBook",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Purchase Requisition", url: "" },
		],
	},
	{
		name: "ap-purchase-requisition-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Purchase Requisition", url: "/ap/purchase-requisition/:apBook" },
			{ label: "{text} Purchase Requisition", url: "", type: "edit" },
		],
	},
	{
		name: "ap-purchase-order",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Purchase Order", url: ""},
		],
	},
	{
		name: "ap-purchase-order-apBookId",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Purchase Order", url: "" },
		],
	},
	{
		name: "ap-purchase-order-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Purchase Order", url: "/ap/purchase-order/:apBookId" },
			{ label: "{text} Purchase Order", url: "", type: "edit" },
		],
	},
	{
		name: "ap-purchase-invoice",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Purchase Invoice", url: ""},
		],
	},
	{
		name: "ap-purchase-invoice-apBook",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Purchase Invoice", url: "" },
		],
	},
	{
		name: "ap-purchase-invoice-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Purchase Invoice", url: "/ap/purchase-invoice/:apBook" },
			{ label: "{text} Purchase Invoice", url: "", type: "edit" },
		],
	},
	{
		name: "ap-purchase-receipt",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Purchase Receipt", url: ""},
		],
	},
	{
		name: "ap-purchase-receipt-apbook",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Purchase Receipt", url: "" },
		],
	},
	{
		name: "ap-purchase-receipt-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Purchase Receipt", url: "/ap/purchase-receipt/:apBook" },
			{ label: "{text} Purchase Receipt", url: "", type: "edit" },
		],
	},
	{
		name: "gl-invoice",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Accounting", url: "" },
			{ label: "Invoice To GL", url: ""},
		],
	},
	{
		name: "gl-invoice-book",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Accounting", url: "" },
			{ label: "Invoice To GL", url: "" },
		],
	},
	{
		name: "gl-invoice-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Accounting", url: "" },
			{ label: "Invoice To GL", url: "/gl/invoice/:glBook/:idBook" },
			{ label: "{text} Invoice To GL", url: "", type: "edit" },
		],
	},
	{
		name: "revert-accounting",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Accounting", url: "" },
			{ label: "Invoice To GL", url: "/gl/invoice/:glBook/:idBook" },
			{ label: "{text} Invoice To GL", url: "/gl/invoice/:glBook/:idBook/:id", type: "edit" },
			{ label: "Revert Accounting", url: "", type: "edit" },
		],
	},
	{
		name: "gl-receipt",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Accounting", url: "" },
			{ label: "Receipt To GL", url: ""},
		],
	},
	{
		name: "gl-receipt-book",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Accounting", url: "" },
			{ label: "Receipt To GL", url: "" },
		],
	},
	{
		name: "gl-receipt-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Accounting", url: "" },
			{ label: "Receipt To GL", url: "/gl/receipt/:glBook/:idBook" },
			{ label: "{text} Receipt To GL", url: "", type: "edit" },
		],
	},
	{
		name: "gl-accounting",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Accounting", url: "" },
			{ label: "Accounting", url: ""},
		],
	},
	{
		name: "gl-accounting-book",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Accounting", url: "" },
			{ label: "Accounting", url: "" },
		],
	},
	{
		name: "gl-accounting-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Accounting", url: "" },
			{ label: "Accounting", url: "/gl/accounting/:glBook/:idBook" },
			{ label: "{text} Accounting", url: "", type: "edit" },
		],
	},
	{
		name: "gl-pettycash",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Accounting", url: "" },
			{ label: "Petty Cash", url: ""},
		],
	},
	{
		name: "gl-pettycash-book",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Accounting", url: "" },
			{ label: "Petty Cash", url: "" },
		],
	},
	{
		name: "gl-pettycash-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Accounting", url: "" },
			{ label: "Petty Cash", url: "/gl/pettycash/:glBook/:idBook" },
			{ label: "{text} Petty Cash", url: "", type: "edit" },
		],
	},
	{
		name: "notice-print-setting",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Notice Print Setting", url: "" },
		],
	},
	{
		name: "report-notice-print-setting",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Notice Print Setting", url: "" },
		],
	},
	{
		name: "ar-invoice-import",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "invoice System", url: "" },
			{ label: "Invoice", url: "/ar/invoice" },
			{ label: "Import Invoice", url: "" },
		],
	},
	{
		name: "ar-invoice-notification",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "invoice System", url: "" },
			{ label: "Invoice", url: "/ar/invoice" },
			{ label: "Invoice Notification", url: "" },
		],
	},
	{
		name: "notice-custom-report",
		items: [
			{ label: "AR System", url: "" },
			{ label: "Notice", url: "" },
			{ label: "Notice Custom Report", url: "" },
		],
	},
	{
		name: "ar-receipt-bill-payment",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Receipt System", url: "" },
			{ label: "Receipt", url: "/ar/receipt" },
			{ label: "Validation", url: "" },
		],
	},
	{
		name: "transfer-slip",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Receipt System", url: "" },
			{ label: "TransferSlip", url: "" },
		],
	},
	{
		name: "transfer-slip-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Receipt System", url: "" },
			{ label: "TransferSlip", url: "ar/transfer-slip" },
			{ label: "TransferSlip Detail", url: "" },
			],
	},
	{
		name: "ar-stock-keycard",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Stock Keycard System", url: "" },
			{ label: "Stock Keycard", url: "" },
		],
	},
	{
		name: "ar-stock-keycard-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Keycard", url: "" },
			{ label: "Stock Keycard", url: "/ar/stock-keycard" },
			{ label: "{text} Stock Keycard", url: "", type: "edit" },
		],
	},
	{
		name: "master-project",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Control Stock Keycard", url: "" },
		],
	},
	{
		name: "preview-stock-keycard",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Keycard", url: "" },
			{ label: "Stock Keycard", url: "/ar/stock-keycard" },
			{ label: "Import Stock Keycard", url: "" },
		],
	},
	{
		name: "report-stock-keycard",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Stock Keycard System", url: "" },
			{ label: "Report Stock Keycard", url: "" },
		],
	},
	{
		name: "preview-resident",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			{ label: "Resident", url: "/resident" },
			{ label: "Import Resident", url: "" },
		],
	},
	{
		name: "preview-unit",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			{ label: "Unit", url: "/unit" },
			{ label: "Import Unit", url: "" },
		],
	},
	{
		name: "preview-unit-resident",
		items: [
			{ label: "Manage Residents", url: "" },
			{ label: "Residents", url: "" },
			{ label: "Unit", url: "/unit" },
			{ label: "Import UnitResident", url: "", type: "edit" },
		],
	},
	{
		name: "preview-supplier",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Supplier", url: "/supplier" },
			{ label: "Import Supplier", url: "", type: "edit" },
		]
	},
	{
		name: "preview-deposit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AR System", url: "" },
			{ label: "Receipt System", url: "" },
			{ label: "Deposit", url: "/ar/deposit" },
			{ label: "Import Deposit", url: ""},
		],
	},
	{
		name: "preview-asset",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Asset", url: "" },
			{ label: "Asset Register", url: "/asset/register" },
			{ label: "Import Asset", url: "" },
		]
	},
	{
		name: "gl-budget",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Budget", url: "" },
			{ label: "Setting Budget", url: "" },
		],
	},
	{
		name: "gl-budget-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Budget", url: "" },
			{ label: "Setting Budget", url: "/budget" },
			{ label: "{text} Budget", url: "", type: "edit" },
		],
	},
	{
		name: "report-budget",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "Budget", url: "" },
			{ label: "Report Budget", url: "" },
		],
	},
	{
		name: "signature",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Signature Setup", url: "" },
			{ label: "Report Signator", url: "" },
		],
	},
	{
		name: "signature-edit",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Signature Setup", url: "/signature" },
			{ label: "Report Signator", url: "" },
			{ label: "{text} Signator", url: "", type: "edit" },
		],
	},
	{
		name: "signature-management",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Signature Setup", url: "" },
			{ label: "Signator Management", url: "" },
		],
	},
	{
		name: "signature-management-edit",
		items: [
			{ label: "Setting", url: "" },
			{ label: "Financial Account", url: "" },
			{ label: "Signature Setup", url: "/signature-management" },
			{ label: "Signator Management", url: "" },
			{ label: "Report Detail", url: "", type: "edit" },
		],
	},
	{
		name: "payment-request-type",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Payment Request", url: "" },
			{ label: "Payment Request Setting", url: "" },
		],
	},
	{
		name: "payment-request-type-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Payment Request", url: "" },
			{ label: "Payment Request Setting", url: "" },
			{ label: "{text} Payment Request Type", url: "", type: "edit" },
		],
	},
	{
		name: "payment-request",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Payment Request", url: "" },
			{ label: "Payment Request Management", url: "" },
		],
	},
	{
		name: "payment-request-edit",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Payment Request", url: "" },
			{ label: "Payment Request Management", url: "" },
			{ label: "{text} Payment Request Management", url: "", type: "edit" },
		],
	},
	{
		name: "report-budget",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Payment Request", url: "" },
			{ label: "Payment Request Report", url: "" },
		],
	},
	{
		name: "preview-payment-request-auto-gl",
		items: [
			{ label: "Financial Account", url: "" },
			{ label: "AP System", url: "" },
			{ label: "Payment Request", url: "" },
			{ label: "Payment Request Management", url: "" },
			{ label: "Auto Gl", url: "" },
		],
	},
];

export default navigations;
