<template>
  <base-toolbar
    :title="lang.titleToolbar"
    @goBack="goBack()"
    @gotoCreate="gotoEdit('create')"
    :dataButtons="dataButtons"
    :hideBackButton="isHideBack"
  ></base-toolbar>

  <base-dialog
    v-model:visible="showValidateDialog"
    :titleConfirm="titleConfirm"
    :classTitle="baseDialogClassTitle"
    :wordingConfirm="validateWording"
    :dataButtons="dataButtonDialog"
    @confirm="showValidateDialog = false"
    @close="showValidateDialog = false"
    @gotoDelete="deleteProductAp"
  ></base-dialog>

  <base-container>
    <div class="surface-card shadow-2 p-5 border-round my-5">
      <div class="flex justify-content-between align-items-center">
        <!-- <h2 :class="textHeaderClass" class="my-0" style="font-size:18px">{{ title }}</h2> -->
        <label class="font-lg font-normal">{{ lang.title }}</label>
      </div>
      <PvDivider></PvDivider>
      <section
        class="flex flex-wrap justify-content align-items-center"
        :class="textFormClass"
      >      
        <div class="lg:col-2 md:col-12 col-12 pl-0 pr-2">
          <PvDropdown
            id="filterSearch"
            class="inputfield w-full mr-2"
            :options="filterSearch"
            optionLabel="name"
            optionValue="code"
            v-model="selectedFilterSearch"
            scrollHeight="300px"
            @change="changeFilterSearch"
          />
        </div>
        <div class="flex lg:col-6 md:col-12 col-12 p-0 mr-2" v-if="!isSearchAll">
          <div class="col-12 pl-0 pr-0">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <PvInputText
                class="inputfield w-full"
                v-model="searchText"
                :placeholder="lang.searchTextPlaceholder"
                @keyup.enter="searchTextEnter"
                autofocus
              />
            </span>
          </div>
        </div>

        <PvButton @click="getProductAps">
          <span class="font-normal">{{ lang.search }}</span>
        </PvButton>
      </section>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="productAps"
          v-model:selection="selectedProductAp"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(this.selectedProductAp.id)"
          scrollable
        >
          <template #empty>
            <div class="w-full p-3" Style="text-align: center;">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>
          <PvColumn
            field="code"
            :header="lang.columnCode"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <u>{{ slotProps.data.code }}</u>
            </template>
          </PvColumn>

          <PvColumn
            field="nameThai"
            :header="lang.columnNameTh"
            style="min-width: 25rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />

          <PvColumn
            field="nameEnglish"
            :header="lang.columnNameEn"
            style="min-width: 25rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />

          <PvColumn
            :header="lang.columnType"
            style="min-width: 10rem"
            headerClass="h-left"
            bodyClass="text-left font-normal"
          >
            <template #body="slotProps">
              <base-tag
                :Caption="lang.active"
                Class="success"
                v-if="slotProps.data.isActive == 'Y'"
              />
              <base-tag
                :Caption="lang.inActive"
                Class="secondary"
                v-else-if="slotProps.data.isActive == 'N'"
              />
            </template>
          </PvColumn>

          <!-- <PvColumn
            field="createDate"
            :header="lang.columnCreateDate"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.createDate) }}
            </template>
          </PvColumn>

          <PvColumn
            field="updateDate"
            :header="lang.columnUpdateDate"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left"
          >
            <template #body="slotProps">
              <label v-if="slotProps.data.updateDate">
                {{ formatDate(slotProps.data.updateDate) }}
              </label>
            </template>
          </PvColumn>

          <PvColumn
            field="createBy"
            :header="lang.columnCreateBy"
            style="min-width: 8rem;"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />

          <PvColumn
            field="updateBy"
            :header="lang.columnUpdateBy"
            style="min-width: 8rem;"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          /> -->
          <PvColumn
            style="min-width: 5rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>

              <!-- <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="askForDelete(slotProps.data)"
                :disabled="!isRoleDelete"
              >
                <icon-trash-delete-bin
                  iconColor="secondary"
                  width="24"
                  height="24"
                />
              </PvButton> -->
            </template>
          </PvColumn>

          <template #footer>
            <div>
              <PvPaginator
                :rows="rowsPerPage"
                :totalRecords="totalRecords"
                @page="onPageChange($event)"
                :rowsPerPageOptions="[10, 50, 100]"
                class="p-pagination-radius"
                :first="showCurrentPage"
              >
                <template #start="slotProps">
                  {{ lang.page }}:
                  {{ totalPage ? slotProps.state.page + 1 : 0 }} /{{
                    totalPage
                  }}
                </template>
              </PvPaginator>
            </div>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
export default {
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    const isHideBack = inject("isHideBack");
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.productAp.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.productAp.listing,
        };
      }
    });
    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
      isHideBack,
    };
  },
  data() {
    return {
      isLoading: false,
      selectedPage: 1,
      rowsPerPage: 10,
      searchText: "",
      selectedProductAp: null,

      //dialog
      showValidateDialog: false,
      baseDialogClassTitle: "",
      titleConfirm: this.lang.notificationDialogHeader,
      validateWording: "",
      dataButtonDialog: [
        {
          id: "confirm",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "confirm",
        },
      ],
      filterSearch: [
        { name: this.lang.all, code: "all" },
        { name: this.lang.code, code: "code" },
        { name: this.lang.name, code: "name" },
      ],
      selectedFilterSearch: "all",
    };
  },
  async mounted() {
    this.isLoading = true;
    this.initiateData();
    this.roleByMenuDataReady();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    goBack() {
      this.$router.push({name: "home"})
    },
    async gotoEdit(id) {
      if (id != "create") {
        if(!this.isRoleSelect) {
          this.validateWording = this.lang.validateRoleSelect;
          this.showValidateDialog = true;
          return;
        }
        this.$router.push({name: "product-ap-edit", params: { id: encodeURI(id) }})
      }
      else {
        if(!this.isRoleCreate) {
          this.validateWording = this.lang.validateRoleInsert;
          this.showValidateDialog = true;
          return;
        }
        this.$router.push({name: "product-ap-edit", params: { id: id }})
      }
    },
    initiateData() {
      this.isLoading = false;
      this.searchText = "";
      this.baseDialogClassTitle = "";
      this.storageName = `erp_search_ProductAPList_${this.selectedProject.code}_${this.userCode}`;
    },
    async roleByMenuDataReady() {
      if (this.isSharedRoleByMenuReady) {
        await this.setLocalSearch();
        await this.getProductAps();
      }
    },
    searchTextEnter(event) {
      if (event.key == "Enter") {
        this.getProductAps();
      }
    },
    validateSearchButton() {
      if (!this.isSearchAll && this.searchText == "") {
        return true;
      }
      return false;
    },
    async getProductAps() {
      if (this.validateSearchButton()) {
        this.validateWording = this.lang.validateSearchText;
        this.showValidateDialog = true;
        return;
      }

      this.isLoading = true;
      try {
        let payload = {
          page: this.selectedPage,
          limit: this.rowsPerPage,
          projectId: this.selectedProject.id,
          code: this.selectedFilterSearch == "code" ? this.searchText : null,
          name: this.selectedFilterSearch == "name" ? this.searchText : null
        };

        await this.$store.dispatch("productAp/getProductAps", payload);

        payload = {
          ...payload,
          rowsPerPage: this.rowsPerPage,
          selectedPage: this.selectedPage,
          searchText: this.searchText,
        };

        localStorage.setItem(this.storageName, JSON.stringify(payload));
      } catch (error) {
        this.openAlertDialogError(error);
      }

      this.isLoading = false;
    },

    async onPageChange(event) {
      if (event.rows != this.rowsPerPage) {
        this.selectedPage = 1;
      } else {
        this.selectedPage = event.page + 1;
      }
      this.rowsPerPage = event.rows;
      await this.getProductAps();
    },

    async setLocalSearch() {
      const localSearch = localStorage.getItem(this.storageName);
      if (localSearch) {
        let search = JSON.parse(localSearch);
        if (search.entry === "entry") {
          if (search.code != null && search.code != "") {
            this.selectedFilterSearch = "code";
          } else if (search.name != null && search.name != "") {
            this.selectedFilterSearch = "name";
          } else {
            this.searchText = "";
            this.selectedFilterSearch = "all";
          }
          
          this.searchText = search.searchText;
          this.rowsPerPage = search.rowsPerPage;
          this.selectedPage = search.selectedPage;
          search.entry = "";

          localStorage.setItem(this.storageName, JSON.stringify(search));
        }
      }
    },
    askForDelete(data) {
      this.dataButtonDialog = [
        {
          id: "close",
          caption: this.lang.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "close",
        },
        {
          id: "gotoDelete",
          caption: this.lang.buttonDialogConfirm,
          class: "w-full",
          action: "gotoDelete",
        },
      ];
      this.baseDialogClassTitle = "";
      this.titleConfirm = this.lang.deleteProductAp;
      this.validateWording = `${this.lang.askForDeleteProductAp.replace(
        ":code",
        data.code
      )}`;
      this.selectedProductAp = data;
      this.showValidateDialog = true;
    },
    async deleteProductAp() {      
      this.showValidateDialog = false;
      try {
        let payload = {
          projectId: this.selectedProject.id,
          id: this.selectedProductAp.id,
        };

        await this.$store.dispatch("productAp/deleteProductAp", payload);

        this.dataButtonDialog = [
          {
            id: "confirm",
            caption: this.lang.confirm,
            class: "w-full",
            action: "confirm",
          },
        ];

        this.baseDialogClassTitle = "p-text-success";
        this.titleConfirm = this.lang.deleteSuccess;
        this.validateWording = `${this.lang.deleteProductApSuccess.replace(
          ":code",
          this.selectedProductAp.code
        )}`;
        this.showValidateDialog = true;

        this.getProductAps();

      } catch (error) {
        this.openAlertDialogError(error);
      }
    },
    openAlertDialogError(error) {
      this.dataButtonDialog = [
        {
          id: "confirm",
          caption: this.lang.confirm,
          class: "w-full",
          action: "confirm",
        },
      ];
      this.baseDialogClassTitle = "p-error";
      this.titleConfirm = this.lang.notificationDialogHeader;
      this.validateWording = error.message;
      this.showValidateDialog = true;
    },    
    changeFilterSearch() {
      this.initiateData();
    },
  },
  computed: {    
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    userCode() {
      return this.$store.getters.userCode;
    },
    totalPage() {
      return this.$store.getters["productAp/productApTotalPage"];
    },
    currentPage() {
      return this.$store.getters["productAp/productApCurrentPage"];
    },
    totalRecords() {
      return this.totalPage * this.rowsPerPage;
    },
    productAps() {
      return this.$store.getters["productAp/productAps"];
    },
    roleByMenu() {
      return this.getRoleByMenuByCode("tmPRO");
    },
    isRoleSelect() {
      if (this.roleByMenu && this.roleByMenu.select == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRoleCreate() {
      if (this.roleByMenu && this.roleByMenu.insert == "Y") {
        return true;
      } else {
        return false;
      }
    },
    isRoleDelete() {
      let result = false;
      if (this.roleByMenu && this.roleByMenu.delete == "Y") {
        result = true;
      }
      return result;
    },
    showCurrentPage() {
      return this.rowsPerPage * (this.selectedPage - 1);
    },
    
    isSearchAll() {
      if (this.selectedFilterSearch == "all") {
        return true;
      } else {
        return false;
      }
    },
    dataButtons() {
      let dataButtons = [
        {
          id: "editCaption",
          caption: this.lang.createProductAp,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
        },
      ];

      return dataButtons;
    },
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.roleByMenuDataReady();
    },
  },
};
</script>

<style></style>
