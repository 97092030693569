export default {
  usePrepaidAndDeposits(state) {
    return state.usePrepaidAndDeposits;
  },
  unitExistPrepaids(state) {
    return state.unitExistPrepaids;
  },
  selectedUsePrepaid(state){
    return state.selectedUsePrepaid;
  },
};