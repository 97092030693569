export default {
  setDocumentBookDistinct(state, payload) {
    // state.project.length = 0;
    let documentBookDistincts = [...state.documentBookDistinct.data];
    for (const documentBookDistinct of payload.documentBookDistincts) {
      if(documentBookDistincts.findIndex(c => c.code === documentBookDistinct.code) < 0){
        documentBookDistincts.push(documentBookDistinct);
      }
    }
    state.documentBookDistinct.data = documentBookDistincts;
    state.documentBookDistinct.currentPage = payload.currentPage;
    state.documentBookDistinct.totalPage = payload.totalPage;
  },
  resetDocumentBookDistinct(state) {
    const documentBookDistinct = {
      data: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.documentBookDistinct = documentBookDistinct;
  },
};
