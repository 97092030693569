export default {
    setRefundGuaranteeList(state, payload){
        state.refundGuaranteeList = payload.data
        state.totalPage = payload.page.pageCount
        state.currentPage = payload.page.currentPage
    },
    setSelectedRefundGuarantee(state, payload){
        state.selectedRefundGuarantee = payload
    },
    setBlob(state, payload){        
        state.blob = payload
    },
    setPendingList(state, payload){
        state.pendingList = payload.data
        state.pendingListTotalPage = payload.page.pageCount
        state.pendingListCurrentPage = payload.page.currentPage
    },
    resetPendingList(state) {
		state.pendingList = [];
		state.pendingListTotalPage = 0;
		state.pendingListCurrentPage = 0;
	},
}