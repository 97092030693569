export default {
	purchaseReceipts(state) {
		return state.purchaseReceipts;
	},
	totalPage(state) {
		return state.totalPage;
	},
	currentPage(state) {
		return state.currentPage;
	},
	rows(state) {
		return state.rows;
	},
	selectedPurchaseReceipt(state) {
		return state.selectedPurchaseReceipt;
	},
	blob(state) {
		return state.blob;
	},
	totalPageInvoice(state) {
		return state.totalPageInvoice;
	},
	currentPageInvoice(state) {
		return state.currentPageInvoice;
	},
	purchaseInvoices(state) {
		return state.purchaseInvoices;
	},
	purchaseReceiptImport(state) {
		return state.purchaseReceiptImport;
	},
	purchaseReceiptsToGl(state) {
		return state.purchaseReceiptsToGl;
	},
	purchaseReceiptsVoucher(state) {
		return state.purchaseReceiptsVoucher;
	},
	purchaseReceiptReference(state) {
		return state.purchaseReceiptReference;
	},
};
