export default {
    setAddNoticeByUnit(state, payload){
        state.addNoticeByUnit = payload
    },
    setNotices(state, payload) {
		state.notices = payload.data;
		state.totalPage = payload.page.pageCount;
		state.currentPage = payload.page.currentPage;
	},
    setNotice(state, payload) {
		state.notice = payload;
	},	
    setNoticesGroupDate(state, payload) {
		state.noticesGroupDate = payload.data;
	},
    setNoticesByAtDate(state, payload) {
		state.noticesByAtDate = payload.data;
	},
    setBlob(state, payload){        
        state.blob = payload
    },
};