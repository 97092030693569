<template>
	<!-- <div class="lg:col-10 lg:col-offset-1 lg:px-0 px-2 py-0"> -->
	<div class="col-12 px-6 py-0">
		<slot></slot>
	</div>
</template>

<script>
export default {
  props: {
    isBackground: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    document.querySelector("body").style.background = this.isBackground ? "#EFF3F8" : "";
  },
};
</script>