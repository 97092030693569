export default {
  title: "Notification List",
  titleToolbaar: "Send Notification",
  unPaidOnly: "Unpaid only",
  clearFilter: "Clear Filter",
  code: "Code",
  unit: "Unit",
  address: "Address",
  customerName: "CustomerName",
  allStatus: "All",
  installAppStatus: "Install App Status",
  isInstall: "Install App",
  isNotInstall: "Not Install App",
  sendNotifyStatus: "Send Notify Status",
  sendNotify: "Send",
  notSendNotify: "Not Send",
  readNotifyStatus: "Read Notify Status",
  readNotify: "Read",
  notReadNotify: "Not Read",
  date: "Date",
  gotoSendNotify: "Send all notifications",
  columnDate: "Date",
  columnCode: "Code",
  columnUnit: "Unit No.",
  columnAddress: "Addr No.",
  columnCustomerName: "Customer Name",
  columnPrice: "Price",
  columnIsPaid: "Paid",
  columnIsCancel: "Canceled",
  columnPostStep: "Printed/Debited",
  columnInstallApp:"Installed",
  columnSendNotify:"Send",
  columnReadNotify:"Read",
  // unPaidOptions: [
  //   { name: "Yes", code: "A1" },
  //   { name: "No", code: "A2" }
  // ],
  // createInvoice: "CreateInvoice",
  // sendNotification: "Send Notification",
  // printInvoice: "Print Invoice",
  // date: "Date",
  // price: "Price",
  // unlockDocument: "Unlock Document",
  // referenceDocument: "Reference Document",
	// validateNumber: "Please enter numbers only.", 	  
  // contactNumber: "Contact Number",
  // remark1: "Remark (1)",
  // remark2: "Remark (2)",
  // fullPayment: "Full Payment",
  // splitRefund: "Split Refund",
  printed: "Printed",
  // accRecorded: "Acc Recorded",
  sendNotifySuccess: "Send Notify Success",
  sendNotifyError: "Send Notify Error. Please try again",
  notifyStatus: "Send notification",
  sending: "sending...",
  stopProgressBar: "Pause send notify",
  continueProgressBar: "Continue send notify",
  errorSendAllNotify: "Failed to send notification please try again",
  tryAgainSendNotify: "Try again",
  confirmSendAllWording: "Confirm all notifications?",

};
