export default {
    importStockKeycards(state) {
        return state.importStockKeycards;
    },
    products(state) {
        return state.products;
    },
    units(state) {
        return state.units;
    },
    runningNumbers(state) {
        return state.runningNumbers;
    },
}