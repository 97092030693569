import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default function useGetPayType() {
	const store = useStore();
	let searchText = ref(null);

	const payTypes = computed(() => {
		return store.getters["bank/transactionType"];
	});

	const type = computed(() => {
		return store.getters["bank/typeInOut"];
	});

	const filteredPayTypes = computed(() => {
		// console.log('filteredPayTypes',payTypes.value);
		if (!searchText.value) {
			//ช่องว่าง
			// const result = payTypes.value;
			// return result;
			if (payTypes.value) {
				// console.log('filteredPayTypes if',JSON.parse(JSON.stringify(payTypes.value)));
				const result = JSON.parse(JSON.stringify(payTypes.value));
				return result;
			} else {
				return null;
			}
		} else {
			return payTypes.value.filter((payTypes) => {
				return payTypes.code.toLowerCase().includes(searchText.value.toLowerCase());
			});
		}
	});

	const searchPayType = function (event) {
		// console.log('searchPayType',event);
		if (!event.query.trim().length) {
			//ช่องว่าง
			// จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
			searchText.value = "";
			searchText.value = null;
		} else {
			searchText.value = event.query;
		}
	};

	let isSharedPayTypeReady = ref(false);

	const getPayInTypes = async function () {
		const payload = {
			InOut: 1,
		};
		// console.log('getPayInTypes',payload);
		await store.dispatch("bank/getTransactionType", payload);
		isSharedPayTypeReady.value = true;
	};

	const getPayOutTypes = async function () {
		const payload = {
			InOut: -1,
		};
		await store.dispatch("bank/getTransactionType", payload);
		isSharedPayTypeReady.value = true;
	};

	onMounted(async () => {
		if (type.value === 1) {
			await getPayInTypes();
		} else if (type.value === -1) {
			await getPayOutTypes();
		}
	});

	return {
		searchPayType,
		filteredPayTypes,
		searchText,
		isSharedPayTypeReady,
	};
}
