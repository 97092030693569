export default {
	projectOverview: "ภาพรวมโครงการ",
	registerApp: "ลงทะเบียนแอป",


	manageResidents: "จัดการผู้พักอาศัย",
	residents: "ผู้พักอาศัย",
	unit: "ห้อง/บ้าน",
	customer: "สมาชิก/ลูกค้า",
	customerGroup: "ช่องทางการส่งเอกสาร",
	debtfree: "ปลอดหนี้",
	invitationCode: "รหัสคำเชิญ",


	financialAccount: "บัญชีการเงิน",
	arSystem: "ลูกหนี้ AR",

	meter: "ทะเบียนมิเตอร์",
	meterUsageWater: "บันทึกมิเตอร์ประปา",
	meterUsageElectric: "บันทึกมิเตอร์ไฟ",
	meterUsageCoolingAir: "บันทึกมิเตอร์ความเย็น",
	meterUsageReport: "รายงานมิเตอร์",

	invoiceSystem: "แจ้งหนี้",
	invoice: "ใบแจ้งหนี้",
	calculateInvoice: "คำนวณใบแจ้งหนี้",
	outstandingLetter: "หนังสือยืนยันยอดค้าง",

	noticeSystem: "ทวงถาม",
	notice: "หนังสือทวงถาม",
	calculateNotice: "คำนวณหนังสือทวงถาม",
	noticeReport: "หนังสือทวงถามรูปแบบอื่น",

	receiptSystem: "รับชำระ",
	receipt: "ใบเสร็จ",
	receiptReport: "รายงานใบเสร็จ",
	deposit: "ใบรับฝากเงิน",
	creditNote: "ใบลดหนี้",
	bankAR: "ใบนำฝาก (Bank AR)",
	usePrepaidAndDeposit: "ตัดรับล่วงหน้าและเงินฝาก",
	previewUsePrepaidAndDeposit: "รายการรอการตัด",
	refundSystem: "คืนเงิน",
	prepaidRefund: "คืนเงินรับล่วงหน้า",
	refundGuarantee: "คืนเงินค้ำประกัน",
	arReport: "รายงานลูกหนี้",
	checkTransfer: "ตรวจการโอนเงิน",


	apSystem: "เจ้าหนี้ AP",
	purchaseRequisition: "ใบขอซื้อ PR",
	purchaseOrder: "ใบสั่งซื้อ PO",
	purchaseInvoice: "ใบรับสินค้า/ใบรับวางบิล",
	purchaseReceipt: "ใบเสร็จด้านจ่าย",
	bankAP: "จ่ายชำระ",
	withholdingTax: "ภาษีหัก ณ ที่จ่าย",
	apReport: "รายงานเจ้าหนี้",
	withholdingTaxReport: "รายงาน ภ.ง.ด.",

	glSystem: "บัญชี GL",
	glPettyCash: "เงินสดย่อย",
	pettyCashReport: "รายงานเงินสดย่อย",
	glInvoice: "ใบแจ้งหนี้มาลงบัญชี (ลูกหนี้)",
	glReceipt: "ใบเสร็จมาลงบัญชี (ลูกหนี้)",
	glAccounting: "บันทึกบัญชี-ทั่วไป",
	revertAccounting: "กลับรายการบัญชี",
	closingEntry: "ปิดรอบบัญชี",
	glReport: "รายงานบัญชี",

	assetSystem: "ทรัพย์สิน",
	assetRegister: "ทะเบียนทรัพย์สิน",
	reportAsset: "รายงานทรัพย์สิน",


	setting: "ตั้งค่าโครงการ",
	meterUsageSetting: "รูปแบบการคำนวณมิเตอร์",
	productAR: "รายรับ",
	policy: "ชุดเรียกเก็บ",
	projectPenaltyCalculation: "กำหนดอัตราค่าปรับ",
	supplier: "ผู้จัดจำหน่าย",
	productAP: "รายจ่าย",
	noticePrintSetting: "รูปแบบหนังสือทวงถาม",
	noticeCustomReport: "หนังสือทวงถามกำหนดเอง",

	accountSetup: "ตั้งค่าบัญชี",
	accountChart: "ผังบัญชี",
	bookbank: "บัญชีธนาคาร",
	accountBook: "ตั้งค่าสมุดบัญชี",
	accountFormat: "กำหนดรูปแบบการบันทึกบัญชี",
	accountFrequently: "รูปแบบบัญชีใช้บ่อย",
	accountPolicy: "นโยบายการบันทึกบัญชี",

	projectSetup: "โครงการ",
	projectInformation: "ข้อมูลโครงการ",
	projectDocument: "เอกสารโครงการ",
	announceAdvertising: "ประชาสัมพันธ์ - โฆษณา",
	phoneBook: "สมุดโทรศัพท์",
	smartAudit: "Smart audit",


	other: "จัดการทั่วไป",
	parcel: "พัสดุ",
	parcelReport: "รายงานฝากส่งพัสดุ",
	chat: "แชท",
	news: "ประกาศข่าวสาร",
	facilityBooking: "จองส่วนกลาง",
	serviceMaintenence: "แจ้งซ่อม",
	visitorControl: "ระบบผู้ติดต่อ",


	help: "ช่วยเหลือ",
	manualWebService: "คู่มือการใช้งาน",
	contactStaff: "ติดต่อเจ้าหน้าที่",


	productAndService: "สินค้าและบริการ",
	reportRequest: "รายการคำขอ",

	notification: "รายการแจ้งเตือน",
	voucherBook: "สมุดบัญชี",
	report: "รายงาน",
	autoCreateMeter: "สร้างมิเตอร์อัตโนมัติ",
	settingUnitProduct: "ตั้งค่าการแจ้งหนี้",
	importReceipts: "นำเข้าข้อมูลใบเสร็จ",

	adminFormManage: "จัดการฟอร์มพิมพ์",
	formVersion: "ตั้งค่าเวอร์ชันแบบฟอร์ม",
	reportManage: "จัดการรายงาน",

	debtfreeRequest: "ขอหนังสือรับรองหนี้",
	debtfreeApprove: "อนุมัติปลอดหนี้",
	debtfreePrint: "พิมพ์ใบปลอดหนี้",
	debtfreeFollowUp: "ติดตามสถานะปลอดหนี้",
	debtfreeReport: "รายงานปลอดหนี้",
	importInvoices: "นำเข้าข้อมูลใบแจ้งหนี้",

	billPayment: "Bill Payment",
	transferSlip: "ตรวจสอบเงินโอน",
	stockKeycardSystem: "คีย์การ์ด",
	stockKeycard: "ทะเบียนคีย์การ์ด",
	controlStockKeycard: "สต๊อกคีย์การ์ด",
	stockKeycardReport: "รายงานคีย์การ์ด",

	importResident: "นำเข้าสมาชิก/ลูกค้า",
	importUnit: "นำเข้าห้อง/บ้าน",
	importSupplier: "นำเข้าผู้จัดจำหน่าย",
	importDeposit: "นำเข้าใบรับฝากเงิน",
	importAsset: "นำเข้าทรัพย์สิน",
	manageBudget: 'จัดการงบประมาณ',
	budget: "งบประมาณ",
	budgetReport: "รายงานงบประมาณ",
	signatureSetup: "ตั้งค่าการลงนาม",
	reportSignator: "ผู้ลงนามในรายงาน",
	signator: "ผู้ลงนาม",
	signatureManagement: "รายงานที่สามารถตั้งค่า",

	paymentRequest: "ใบตั้งเบิก",
	paymentRequestManagement: "จัดการใบตั้งเบิก",
	paymentRequestReport: "รายงานใบตั้งเบิก",
	paymentRequestType: "ตั้งค่าใบตั้งเบิก",
};
