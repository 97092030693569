import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
export default function useGetReportPrint() {
    const store = useStore();
    let searchTextReportPrint = ref(null);

    const reportMenuDetails = computed(() => {
        return store.getters["reportMenuDetail/reportMenuDetails"];
    });

    const reportMenuDetailIsLastPage = computed(() => {
        return store.getters["reportMenuDetail/isLastPage"];
    });

    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });

    const filteredReportPrints = computed(() => {
        let reportPrints = [];
        let reportDefault = reportMenuDetails.value.filter((e) => {return (e.category.toLowerCase() == "all")});
        let reportByProject = reportMenuDetails.value.filter((e) => {return (e.category.toLowerCase() != "all" && e.projectId == selectedProject.value.id)});

        if(reportByProject.length == 0) {
          reportPrints = reportDefault;
        } else {
          reportPrints = reportByProject;
        }

        let reportPrintsAddItem = [];
        let number = 1;
        if(reportPrints){
            reportPrints.forEach((e) => {
            reportPrintsAddItem.push({...e, itemNo: number});
            number++;
          });
        }

        if (!searchTextReportPrint.value) {
            //ช่องว่าง
            const result = [...reportPrintsAddItem];
            return result;
        } else {
            return reportPrintsAddItem.filter((report) => {
              return (
                report.nameThai.toLowerCase().includes(searchTextReportPrint.value.toLowerCase())
              );
            });
        }
    });

    let isSharedReportPrintReady = ref(true);

    const getReportPrints = async function (payload) {
        isSharedReportPrintReady.value = true;
        if (payload != null) {
          if (!reportMenuDetailIsLastPage.value) {
            await store.dispatch("reportMenuDetail/getReportMenuDetail", payload);
          }
        }    
        isSharedReportPrintReady.value = false;
    };

    const getPreviewBlob = async function (blob) {
        if (blob != null) {
          window.open(blob);
        }
    };

    const getDownloadBlob = async function (filename, blob) {
        if (blob != null) {
          // window.location.assign(blob);
          let a = document.createElement('a');
          a.href = blob;
          a.download = filename;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        }
    };

    onMounted(async () => {
        await getReportPrints();
    });

    return {
        searchTextReportPrint,
        isSharedReportPrintReady,
        getPreviewBlob,
        getDownloadBlob,
        getReportPrints,
        filteredReportPrints,
    }
}