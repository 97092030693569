export default {
	title: "Outstanding letter.",
	buttonPrint: "Print outstanding letter.",
	buttonDownloadExcel: "Download excel",
	labelLetterEndDate: "Balance date.",
	labelLetterDocumentDate: "Document date.",
	labelLetterConfirmDate: "Confirm date.",
	labelLetterDocumentBy: "Document by.",
	letterDocumentBy: [
        { name: "Building manager", code: "Building" }, // fcProjectManager
        { name: "Village manager", code: "Village" }, // fcProjectManager
        { name: "Community manager", code: "Community" }, // fcComManager
    ],	
	validateDateSelect: "Please select date.",
};
