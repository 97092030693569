export default {
	titleEdit: "รายละเอียดรายงาน",
	signatureQuantity: "จำนวนผู้ลงนาม",
	none: "ไม่มี",
	onePerson: "1 คน",
	twoPerson: "2 คน",
	threePerson: "3 คน",
	firstSignator: "ลงนามลำดับที่ 1",
	secondSignator: "ลงนามลำดับที่ 2",
	thirdSignator: "ลงนามลำดับที่ 3",
	roleInDocument: "บทบาทในเอกสาร",
	positionAndName: "ตำแหน่ง / ชื่อ",
	displayPosition: "ระบุชื่อตำแหน่งในรายงาน",
	errorDuplicateReport: "คุณเคยเพิ่มรายงานนี้ที่รายการรายงานที่สามารถตั้งค่าไปแล้ว",
	errorReportNotFound: "ไม่พบรายงานนี้",
	errorQuantityNotMatchDetails: "จำนวนผู้ลงนามไม่สอดคล้องกับข้อมูลผู้ลงนาม",
};
