<template>
  <base-toolbar
    :title="lang.titleBack"
    @goBack="goBack()"
    :hideBackButton="isHideBack"
    :dataButtons="dataButtons"
    @createPolicy="createPolicy()"
  ></base-toolbar>

  <base-container>
    <base-dialog
      v-model:visible="showConfirmDialog"
      :titleConfirm="confirmTitle"
      :wordingConfirm="confirmWording"
      :classTitle="confirmClassTitle"
      :dataButtons="dialogButton"
      @confirmDelete="deletePolicy()"
      @close="showConfirmDialog = false"
    ></base-dialog>
    <div class="surface-card shadow-2 p-5 border-round mt-5 mb-5" :class="textFormClass">
      <div class="flex flex-wrap justify-content-between align-items-center">
        <div>
          <label class="font-lg font-normal">{{ lang.title }}</label>
        </div>
      </div>

      <PvDivider></PvDivider>
      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="policyList"
          v-model:selection="selectedPolicy"
          selectionMode="single"
          dataKey="id"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          responsiveLayout="scroll"
          @row-dblclick="editPolicy(this.selectedPolicy.id)"
          scrollable
        >
          <template #empty>
            <div
              style="height: 56px"
              class="text-center flex align-items-center justify-content-center w-full"
            >
              <label class="font-normal">
                {{ lang.emptyPolicy }}
              </label>
            </div>
          </template>
          <PvColumn
            field="itemNo"
            :header="lang.columnItemNo"
            style="min-width: 2rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="code"
            :header="lang.columnCode"
            style="min-width: 2rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="name"
            :header="lang.columnName"
            style="min-width: 15rem"
            headerClass="h-left"
            bodyClass="text-left"
          />
          <PvColumn
            field="createDate"
            :header="lang.columnCreateDate"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.createDate) }}
            </template>
          </PvColumn>
          <PvColumn
            field="updateDate"
            :header="lang.columnUpdateDate"
            style="min-width: 11rem"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          >
            <template #body="slotProps">
              <div v-if="slotProps.data.updateDate">
                {{ formatDate(slotProps.data.updateDate) }}
              </div>
            </template>
          </PvColumn>
          <PvColumn
            field="createBy"
            :header="lang.columnCreateBy"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            field="updateBy"
            :header="lang.columnUpdateBy"
            style="min-width: 8rem"
            headerClass="h-left"
            bodyClass="text-left"
          ></PvColumn>
          <PvColumn
            style="max-width: 6rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0"
                @click="editPolicy(slotProps.data.id)"
                :disabled="!this.isRoleUpdate"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
              <!-- <PvButton
                type="button"
                class="p-button-danger p-button-text p-0"
                @click="askForDeletePolicy(slotProps.data)"
                :disabled="!this.isRoleDelete"
              >
                <icon-trash-delete-bin iconColor="error" />
              </PvButton> -->
            </template>
          </PvColumn>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>
<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed, inject } from "vue";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";

export default {
  setup() {
    const { lang } = useLang();
    const { textFormClass, textTableClass } = useTextStyle();
    const { formatDate } = useFormat();
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();
    const isHideBack = inject("isHideBack");
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return { ...lang.th.base, ...lang.th.master.policy.listing };
      } else {
        return { ...lang.en.base, ...lang.en.master.policy.listing };
      }
    });

    return {
      lang: mergeLang,
      textFormClass,
      textTableClass,
      formatDate,
      isHideBack,
      getRoleByMenuByCode,
      isSharedRoleByMenuReady,
    };
  },
  data() {
    return {
      showConfirmDialog: false,
      confirmTitle: "",
      confirmWording: "",
      confirmClassTitle: "",
      policyList: null,
      selectedPolicy: [],
      isLoading: false,
      policyId: null,
      dialogButton: [],
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.initiateData();
    await this.roleByMenuDataReady();
  },
  methods: {
    goBack() {
      this.$router.push({name: "home"})
    },
    createPolicy() {
      if(!this.isRoleInsert) {
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateRoleInsert,
          isConfirm: false,
        });
        return;
      }
      this.$router.push({name: "master-policy-edit", params: {id: "create"}});
    },
    editPolicy(id) {
      if(!this.isRoleSelect) {
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: this.lang.validateRoleSelect,
          isConfirm: false,
        });
        return;
      }
      this.$router.push({name: "master-policy-edit", params: {id: id}});
    },
    askForDeletePolicy(data) {
      this.policyId = data.id;
      this.openAlertDialog({
        severity: "",
        title: this.lang.notificationDialogHeader,
        content: this.lang.askForDeletePolicy.replaceAll(
          "{policyCodeAndName}",
          data.codeAndName
        ),
        isConfirm: true,
      });
    },
    async deletePolicy() {
      try {
        this.showConfirmDialog = false;
        this.isLoading = true;
        let payloadDelete = {
          id: this.policyId,
          projectId: this.selectedProject.id,
        };
        await this.$store.dispatch("policy/deletePolicy", payloadDelete);
        await this.getPolicyList();
      } catch (error) {
        this.showConfirmDialog = false;
        this.openAlertDialog({
          severity: "error",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.isLoading = false;
      }
    },
    async initiateData() {
      this.policyList = null;
      this.policyId = null;
    },
    async getPolicyList() {
      try {
        this.isLoading = true;
        await this.$store.dispatch("policy/getAllPolicy");
        await this.updateItemNo();
        this.policyList = this.policy;
        this.isLoading = false;
      } catch (error) {
        this.openAlertDialog({
          severity: "",
          title: this.lang.notificationDialogHeader,
          content: error,
          isConfirm: false,
        });
        this.isLoading = false;
      }
    },
    async updateItemNo() {
      let number = 1;
      if (this.policy) {
        this.policy.forEach((element) => {
          element.itemNo = number;
          number++;
        });
      }
    },
    openAlertDialog(dataConfig) {
      if (dataConfig.severity === "") {
        this.confirmClassTitle = "";
      } else if (dataConfig.severity === "success") {
        this.confirmClassTitle = "p-text-success";
      } else if (dataConfig.severity === "error") {
        this.confirmClassTitle = "p-error";
      }
      this.confirmTitle = dataConfig.title;
      this.confirmWording = dataConfig.content;
      this.showConfirmDialog = true;
      this.dialogButton = [];
      if (dataConfig.isConfirm) {
        this.dialogButton = [
          {
            id: "close",
            caption: this.lang.buttonDialogCancel,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
          {
            id: "confirmDelete",
            caption: this.lang.buttonDialogConfirm,
            class: "w-full",
            action: "confirmDelete",
          },
        ];
      } else {
        this.dialogButton = [
          {
            id: "close",
            caption: this.lang.buttonDialogCancel,
            class: "p-button-outlined p-button-secondary w-full",
            action: "close",
          },
        ];
      }
    },
    async roleByMenuDataReady() {
      if (this.isSharedRoleByMenuReady) {
        await this.getPolicyList();
      }
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    roleByBook() {
      return this.getRoleByMenuByCode("erp_policy");
    },
    isRoleSelect() {
      if (this.roleByBook && this.roleByBook.select == "Y") {
        return true;
      }
      return false;
      // return true;
    },
    isRoleInsert() {
      if (this.roleByBook && this.roleByBook.insert == "Y") {
        return true;
      }
      return false;
      // return true;
    },
    isRoleUpdate() {
      if (this.roleByBook && this.roleByBook.update == "Y") {
        return true;
      }
      return false;
      // return true;
    },
    isRoleDelete() {
      if (this.roleByBook && this.roleByBook.delete == "Y") {
        return true;
      }
      return false;
      // return true;
    },
    policy() {
      return this.$store.getters["policy/policy"];
    },
    dataButtons() {
      return [
        {
          id: "createPolicy",
          caption: this.lang.createPolicy,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "createPolicy",
          isShow: true,
        },
      ];
    },
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.roleByMenuDataReady();
    },
  },
};
</script>
