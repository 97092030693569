<template>
  <PvDialog
    :breakpoints="{ '1280px': '70vw', '640px': '75vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">{{ lang.invoiceDetail }}</label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <div class="p-datatable-border my-2">
      <PvDataTable
        :value="details"
        dataKey="id"
        :class="textTableClass"
        :scrollHeight="halfViewportHeight + 'px'"
      >
        <template #empty>
          <div class="w-full p-3" Style="text-align: center;">
            <label>{{ lang.tableEmpty }}</label>
          </div>
        </template>
        <PvColumn
            field="itemNo"
            :header="lang.itemNo"
            style="min-width: 3rem; max-width: 5rem; "
            headerClass="h-left "
            bodyClass="font-normal"
          >
          </PvColumn>
        <PvColumn
          field="productCode"
          :header="lang.columnProductCode"
          style="min-width: 10rem"
          headerClass="h-left"
          bodyClass="font-normal"
        >
        </PvColumn>
        <PvColumn
          field="period"
          :header="lang.columnPeriod"
          style="min-width: 6rem"
          headerClass="h-left"
          bodyClass="font-normal"
        >
        </PvColumn>
        <PvColumn
          field="remark"
          :header="lang.columnDescription"
          style="min-width: 10rem"
          headerClass="h-left"
          bodyClass="font-normal"
        >
        </PvColumn>
        <PvColumn
          field="totalAmount"
          :header="lang.columnAmount"
          style="min-width: 8rem"
          headerClass="h-right"
          bodyClass="text-right font-normal"
        >
          <template #body="slotProps">
            <span>{{ formatCurrency(slotProps.data.totalAmount) }}</span>
          </template>
        </PvColumn>
      </PvDataTable>
    </div>

    <template #footer></template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  props: ["details"],
  emits: ["close"],
  setup() {
    const { lang } = useLang();
    const { textTableClass } = useTextStyle();
    const { formatCurrency } = useFormat();

    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.ar.invoice.import,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.ar.invoice.import,
        };
      }
    });

    return {
      lang: mergeLang,
      textTableClass,
      formatCurrency,
    };
  },
  data() {
    return {};
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
  },
  computed: {
    halfViewportHeight() {
      return this.$store.getters.halfViewportHeight;
    },
  },
};
</script>
