export default {
  countryCodes(state) {
    return state.countryCode.countryCodes;
  },
  countryCodeCurrentPage(state) {
    return state.countryCode.currentPage;
  },
  countryCodeNextPage(state) {
    return state.countryCode.currentPage + 1;
  },
  countryCodeTotalPage(state) {
    return state.countryCode.totalPage;
  },
  countryCodeIsLastPage(_, getters) {
    return (
      getters.countryCodeCurrentPage === getters.countryCodeTotalPage &&
      getters.countryCodeTotalPage !== 0
    );
  },
};
