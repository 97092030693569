<template>
  <PvDialog
    :breakpoints="{ '1280px': '60vw', '960px': '80vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center pt-0">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ this.title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>
    <transition>
      <section class="flex flex-wrap" :class="textFormClass">
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="type">{{ lang.outstandingAgingByMonthType }}</label>
          <PvDropdown
            id="filterReportType"
            class="inputfield w-full mr-2 mt-1"
            :options="filterReportType"
            optionLabel="name"
            optionValue="code"
            v-model="selectedFilterReportType"
            scrollHeight="300px"
          />
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="dateAt">{{ lang.dateAt }}</label>
          <PvCalendar
            id="dateAt"
            v-model="dateAt"
            class="inputfield w-full mt-1"
            showIcon
            dateFormat="dd/mm/yy"
            :class="{ 'p-invalid': dateAtError }"
            @date-select="dateAtError = false"
          />
          <small v-if="dateAtError" id="unit-help" class="p-error">{{
            lang.validateDateSelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="unitStart">{{ lang.unitStart }}</label>
          <base-unit-auto-complete
            id="unit"
            v-model="selectedUnitStart"
            class="inputfield w-full mt-1"
            @load-finish="unitDataReady"
            :class="{ 'p-invalid': unitStartError }"
            @item-select="unitStartError = false"
          />
          <small v-if="unitStartError" id="unit-help" class="p-error">{{
            lang.validateNoUnitSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label for="unitEnd">{{ lang.to }}</label>
          <base-unit-auto-complete
            id="unit"
            v-model="selectedUnitEnd"
            class="inputfield w-full mt-1"
            @load-finish="unitDataReady"
            :class="{ 'p-invalid': unitEndError }"
            @item-select="unitEndError = false"
          />
          <small v-if="unitEndError" id="unit-help" class="p-error">{{
            lang.validateNoUnitSelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2" v-show="!isReportTypeSummary">
          <label for="productStart">{{ lang.productStart }}</label>
          <base-product-ar-auto-complete
            id="productStart"
            v-model="selectedProductStart"
            class="inputfield w-full mt-1"
            @load-finish="productDataReady"
            :class="{ 'p-invalid': productStartError }"
            @item-select="productStartError = false"
          />
          <small v-if="productStartError" id="unit-help" class="p-error">{{
            lang.validateNoProductSelect
          }}</small>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0" v-show="!isReportTypeSummary">
          <label for="productEnd">{{ lang.to }}</label>
          <base-product-ar-auto-complete
            id="productEnd"
            v-model="selectedProductEnd"
            class="inputfield w-full mt-1"
            @load-finish="productDataReady"
            :class="{ 'p-invalid': productEndError }"
            @item-select="productEndError = false"
          />
          <small v-if="productEndError" id="unit-help" class="p-error">{{
            lang.validateNoProductSelect
          }}</small>
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label>{{ lang.pending }}</label>
          <PvInputNumber
            class="inputfield w-full mt-1"
            inputClass="w-full text-right"
            v-model="pending"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            placeholder="0.00"
            @focus="$event.target.select()"
          />
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-0">
          <label>{{ lang.overDueDay }}</label>
          <PvInputNumber
            id="overDueDay"
            class="inputfield w-full mt-1"
            inputClass="w-full text-right"
            v-model="overDueDay"
            placeholder="0"
            @focus="$event.target.select()"
          />
        </div>

        <div class="lg:col-6 md:col-6 col-12 pl-0 pr-2">
          <label for="fileNameText">{{ lang.fileName }}</label>
          <PvInputText
            id="fileNameText"
            type="text"
            class="inputfield w-full mt-1"
            v-model="fileNameText"
          />
        </div>

        <div class="col-12 pl-0 pr-2 flex">
          <PvCheckbox
            id="isPrintZero"
            inputId="binary"
            v-model="isPrintZero"
            :binary="true"
          />
          <label for="isPrintZero" class="pl-3">{{
            lang.captionCheckboxPrintZero
          }}</label>
        </div>

        <!-- <div
          class="lg:col-6 md:col-6 col-12 pl-0 pr-2 align-self-center pt-5"
          v-if="!isReportByMonth"
        >
          <PvCheckbox
            id="withProduct"
            inputId="binary"
            v-model="withProduct"
            :binary="true"
          />
          <label for="withProduct" class="pl-3">{{
            lang.showProduct + " (" + lang.accountStatement + " )"
          }}</label>
        </div> -->
      </section>
    </transition>
    <template #footer>
      <div
        class="pt-4 pb-1 pl-3 pr-3 flex flex-wrap justify-content-between align-items-center"
      >
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-pdf"
            :label="lang.downloadPDF"
            @click="download('PDF')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcel"
            @click="download('JASPEREXCEL')"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-1 pr-1">
          <PvButton
            icon="pi pi-file-excel"
            :label="lang.downloadExcelRaw"
            @click="download('EXCEL')"
            class="w-full"
          />
        </div>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useTextStyle from "../../../hooks/textStyle.js";
import useLang from "../../../hooks/lang.js";
import useFormat from "../../../hooks/format.js";
import useTracking from "../../../hooks/tracking";
import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { lang } = useLang();
    const store = useStore();
    const { textFormClass } = useTextStyle();
    const { formatDateToISOString, formatPeriodToISOString, removeSpecialCharacter } = useFormat();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.gl.reportGl,
        };
      }
    });

    return {
      lang: mergeLang,
      textFormClass,
      formatDateToISOString,
      formatPeriodToISOString,
      addReportTracking,
      reportPrintOrder,
      removeSpecialCharacter
    };
  },
  data() {
    return {
      dateAt: "",
      dateAtError: false,
      selectedUnitStart: "",
      selectedUnitEnd: "",
      isSharedUnitReady: false,
      unitEndError: false,
      unitStartError: false,
      selectedProductStart: null,
      selectedProductEnd: null,
      productStartError: false,
      productEndError: false,
      isSharedProductReady: false,
      withProduct: true,
      pending: 0.0,
      overDueDay: 0,
      type: "PDF",
      fileNameText: "outstanding_aging_by_month",

      filterReportType: [
        { name: this.lang.typeSummary, code: "N" },
        { name: this.lang.typeDetailByUnit, code: "Y" },
      ],
      selectedFilterReportType: "N",
      isPrintZero: true,
      reportSplitRange: 400,
    };
  },
  mounted() {
    this.setLoading(true);
    this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
    },
    initiateData() {
      this.dateAt = new Date();
      this.dateAtError = false;
      this.selectedUnitStart = null;
      this.selectedUnitEnd = null;
      this.isSharedUnitReady = false;
      this.unitStartError = false;
      this.unitEndError = false;
      this.selectedProductStart = null;
      this.selectedProductEnd = null;
      this.unitStartError = false;
      this.unitEndError = false;
      this.productStartError = false;
      this.productEndError = false;
      this.isSharedProductReady = false;
      this.withProduct = true;
      this.pending = 0.0;
      this.overDueDay = 0;
      this.type = "PDF";
      this.fileNameText = "outstanding_aging_by_month";
      this.selectedFilterReportType = "N";
      this.isPrintZero = true;
    },
    setData() {
      this.selectedUnitStart = this.units[0];
      this.selectedUnitEnd = this.units[this.units.length - 1];
      this.selectedProductStart = this.products[0];
      this.selectedProductEnd = this.products[this.products.length - 1];
      this.setLoading(false);
    },
    unitDataReady() {
      this.isSharedUnitReady = true;
      this.checkShouldSetData();
    },
    productDataReady() {
      this.isSharedProductReady = true;
      this.checkShouldSetData();
    },
    checkShouldSetData() {
      if (this.isSharedProductReady && this.isSharedUnitReady) {
        this.setData();
      }
    },
    async download(typeReport) {
      this.type = typeReport;
      if (this.validateData()) {
        return;
      }

      this.setLoading(true);
      try {
        let getPayloadArray = this.getPayload();
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);

        if (Array.isArray(getPayloadArray) && getPayloadArray.length > 0) {
          for (let i = 0; i < getPayloadArray.length; i++) {
            const response = await this.reportPrintOrder(getPayloadArray[i]);
            if (response) {
              this.$emit("toast-request");
            }
          }
        } else {
          const response = await this.reportPrintOrder(this.payload);
          if (response) {
            this.$emit("toast-request");
          }
        }

        // this.$emit("toast-request");
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
    validateData() {
      let returnStatus = false;
      if (this.selectedDateAt == null) {
        this.dateAtError = true;
        returnStatus = true;
      }
      if(!this.isReportTypeSummary && this.selectedUnitStart == null) {
        this.unitStartError = true;
        returnStatus = true;
      }
      return returnStatus;
    },
    getPayload() {
      let unitStart = this.selectedUnitStart ? this.selectedUnitStart.code : this.units[0].code;
      let unitEnd = this.selectedUnitEnd ? this.selectedUnitEnd.code : this.units[this.units.length - 1].code;

      let payload = {
        ProjectId: this.selectedProject.id,
        DateAt: this.selectedDateAt,
        ProductStart: this.selectedProductStart
          ? this.selectedProductStart.code
          : this.products[0].code,
        ProductEnd: this.selectedProductEnd ? this.selectedProductEnd.code : this.products[this.products.length - 1].code,
        Pending: this.pending ? this.pending : "0.00",
        OverDue: this.overDueDay ? this.overDueDay : "0",
        SplitProduct: this.selectedFilterReportType,
        IsPrintZero: this.isPrintZero ? "Y" : "N",
        RequestId: 0,
        UserId: this.userId,
        PrintBy: this.userName,
        JasperPath: this.jasperPath,
        DocType: this.type,
      };

      let indexStart = this.units.map((item) => item.code).indexOf(unitStart);
      let indexEnd = this.units.map((item) => item.code).indexOf(unitEnd);
      const rangeSize = (indexEnd - indexStart) + 1;

      if (rangeSize <= this.reportSplitRange) {
        const mapPayload = {
          UnitStart: unitStart,
          UnitEnd: unitEnd,
          FileName: this.fileNameText + this.fileType
        }
        payload = { ...payload, ...mapPayload }
        
        return payload;
      } else {
        let allPayload = [];
        let currentIndexStart = indexStart;
        let currentIndexEnd = indexStart + this.reportSplitRange;
        
        
        while (currentIndexStart < rangeSize) {
          let newFileName = `${this.fileNameText}_${this.units[currentIndexStart].code}_${this.units[currentIndexEnd].code}${this.fileType}`;
          
          const mapPayload = {
            UnitStart: this.units[currentIndexStart].code,
            UnitEnd: this.units[currentIndexEnd].code,
            FileName: this.removeSpecialCharacter(newFileName)
          }
          payload = { ...payload, ...mapPayload };
          allPayload.push(payload)

          currentIndexStart = currentIndexEnd + 1;
          if (rangeSize - currentIndexEnd > this.reportSplitRange) {
            currentIndexEnd = currentIndexEnd + this.reportSplitRange;
          } else {
            currentIndexEnd = rangeSize - 1;
          }
        }
        return allPayload;
      }
    }
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    title() {
      if (this.$store.getters.selectedLanguage === "TH")
        return this.reportObj.nameThai;
      else return this.reportObj.nameEnglish;
    },
    fileName() {
      let fileName = "";
      if (this.reportObj.path == "receipt/outstanding_aging_by_month") {
        fileName = "outstanding_aging_by_month";
      }
      if (this.reportObj.path == "receipt/outstanding_aging_by_year") {
        fileName = "outstanding_aging_by_year";
      }
      return fileName;
    },
    units() {
      return this.$store.getters["shared/unitUnits"];
    },
    products() {
      return this.$store.getters["shared/productArProducts"];
    },
    selectedDateAt() {
      let dateAt = null;
      if (this.dateAt) {
        dateAt = this.formatDateToISOString(this.dateAt);
      }
      return dateAt;
    },
    payload() {
      let payload = {
        ProjectId: this.selectedProject.id,
        DateAt: this.selectedDateAt,
        UnitStart: this.selectedUnitStart
          ? this.selectedUnitStart.code
          : this.units[0].code,
        UnitEnd: this.selectedUnitEnd ? this.selectedUnitEnd.code : this.units[this.units.length - 1].code,
        ProductStart: this.selectedProductStart
          ? this.selectedProductStart.code
          : this.products[0].code,
        ProductEnd: this.selectedProductEnd ? this.selectedProductEnd.code : this.products[this.products.length - 1].code,
        Pending: this.pending ? this.pending : "0.00",
        OverDue: this.overDueDay ? this.overDueDay : "0",
        SplitProduct: this.selectedFilterReportType,
        IsPrintZero: this.isPrintZero ? "Y" : "N",
        RequestId: 0,
        UserId: this.userId,
        PrintBy: this.userName,
        FileName: this.fileNameText + this.fileType,
        JasperPath: this.jasperPath,
        DocType: this.type,
      };
      return payload;
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
    fileType() {
      let fileType = this.type == "PDF" ? ".pdf" : ".xlsx";
      return fileType;
    },
    jasperPath() {
      let path = this.reportObj.path;
      if (this.isReportTypeSummary && this.type != "EXCEL") {
        path = path + "_v2";
      } else if (!this.isReportTypeSummary) {
        path = path + "_with_product";
      }
      if (this.type == "EXCEL") {
        path = path + "_excel";
      }
      return path + this.fileType;
    },
    isReportByMonth() {
      if (this.reportObj.path == "receipt/outstanding_aging_by_month") {
        return true;
      }
      return false;
    },
    isReportTypeSummary() {
      if(this.selectedFilterReportType == "N") {
          return true;
      }
      return false;
    },
  },
  watch: {
    reportObj() {
      this.initiateData();
    },
  },
};
</script>
