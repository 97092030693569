export default {
	title: "รายงาน",
	searchReport: "ค้นหารายงาน",
	reportNotfound: "ขออภัยไม่สามารถแสดงข้อมูลได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง",
	viewhReportButton: "ดูรายงาน",
	titleReportPettyCash: "รายงานเงินสดย่อย",
	approveAll: "ทั้งหมด",
	glBookCode: "เล่มเอกสาร",
	reportType: "เลือกเอกสาร",
	downloadPDF: "Download PDF",
	downloadExcel: "Download Excel",
	selectGlBook: "เลือกเล่มเอกสาร",
	titleAccountFrequentlyReport: "รายงานบัญชีใช้บ่อย",
	titleAccountReport: "รายงานผังบัญชี",
	accountCodeFrom: "รหัสบัญชี ตั้งแต่",
	to: "ถึง",
	validateGlBookSelect: "กรุณาเลือกเล่มเอกสาร",
	validateAccountSelect: "กรุณาเลือกรหัสบัญชี",
	ledgerAcoountReport: "รายงานบัญชีแยกประเภท",
	descriptionPrint: "พิมพ์รายละเอียด",
	descriptionPrint1: "1) รายละเอียด 1",
	descriptionPrint2: "2) รายละเอียด 2",
	descriptionPrint3: "3) รายละเอียด 1+2",
	descriptionLinePrint: "พิมพ์รายละเอียดหลายบรรทัด",
	yes: "ใช่",
	no: "ไม่ใช่",
	newAccountNewPage: "เมื่อขึ้นรหัสบัญชีใหม่ให้ขึ้นหน้าใหม่",
	accountMovementPrint: "พิมพ์รายการบัญชีที่",
	accountMovementPrint1: "1) มีการเคลื่อนไหวหรือมียอดยกมา",
	accountMovementPrint2: "2) มีการเคลื่อนไหว",
	statusPrint: "พิมพ์สถานะ",
	statusPrint1: "1) รวมทั้งที่ยังไม่ Post(A) และ Post(P) แล้ว",
	statusPrint2: "2) A: ลงบัญชีแต่ยังไม่ผ่านรายการ (Post)",
	statusPrint3: "3) P: ผ่านรายการ (Post)",
	titleTrialBalance: "งบทดลอง",
	validateDateSelect: "กรุณาเลือกวันที่",
	titleWorksheet: "กระดาษทำการ",

	titleAccountingEntryReport: "รายงานการบันทึกบัญชี",
	validateCodeSelect: "กรุณาเลือกเลขที่",
	codeFrom: "เลขที่ ตั้งแต่",
	codeTo: "ถึง",
	typeReport: "รูปแบบรายงาน",
	orderReport: "เรียงตาม",
	typeSummary: "แบบสรุป",
	typeDetail: "แบบละเอียด",
	orderCode: "เรียงตามเลขที่",
	orderDate: "เรียงตามวันที่",
	askForSelectBook: "ไม่พบเอกสาร",

	columnSequence: "ลำดับ",
	columnName: "รายงาน",

	titleDocumentNoReferenceReport: "รายงานตรวจสอบเอกสารที่ยังไม่ได้นำมาลงบัญชี",
	dateStart: "ตั้งแต่วันที่",
	dateEnd: "ถึงวันที่",
	dateAt: "ณ วันที่",

	titleIncomeAndExpense: "งบรายรับ/รายจ่าย (แบบละเอียด)",
	titleIncomeAndExpenseSummary: "งบรายรับ/รายจ่าย (แบบสรุป)",
	titleCompareIncomeAndExpense: "งบรายรับ/รายจ่าย (แบบเปรียบเทียบ)",
	titleStatementOfFinancialPosition: "งบแสดงฐานะการเงิน (แบบละเอียด)",
	titleStatementOfFinancialPositionSummary: "งบแสดงฐานะการเงิน (แบบสรุป)",
	titleCompareStatementOfFinancialPosition: "งบแสดงฐานะการเงิน (แบบเปรียบเทียบ)",
	type: "ประเภท",
	byMonth: "รายเดือน",
	byYear: "รายปี",

	downloadExcelRaw: "Excel Raw",
	dateAtData: "ข้อมูลแสดง ณ วันที่",
	monthStart: "ตั้งแต่เดือนที่",
	monthEnd: "ถึงเดือนที่",

	titleAssetWithDetailReport: "รายงานตรวจสอบรายการทรัพย์สิน แบบแสดงรายละเอียด",
	assetCodeStart: "รหัสทรัพย์สิน ตั้งแต่",
	assetCodeEnd: "รหัสทรัพย์สิน ถึง",
	validateAssetCodeSelect: "กรุณาเลือกรหัสทรัพย์สิน",
	titleAssetAndDepreciationExpenseReport: "รายงานทะเบียนทรัพย์สิน และค่าเสื่อมราคา",
	assetStatus: "สถานะทรัพย์สิน",
	assetStatus0: "ทั้งหมด",
	assetStatus1: "ที่มีอยู่จริง",
	assetStatus2: "ตัดจำหน่าย",
	accountCodeStart: "รหัสบัญชี ตั้งแต่",
	accountCodeEnd: "รหัสบัญชี ถึง",
	calculationType: "รูปแบบการคำนวณค่าเสื่อมราคา",
	calculationType1: "ปัดเศษทศนิยมครั้งเดียว",
	calculationType2: "ปัดเศษทศนิยมรายวัน",

	supplierStart: "ผู้จัดจำหน่ายเริ่มต้น",
	supplierEnd: "ผู้จัดจำหน่ายสิ้นสุด",
	bookCode: "เล่มเอกสาร",
	reportPortraitOrLandscape: "รูปแบบรายงาน",
	portrait: "แบบสรุป",
	landscape: "แบบละเอียด",
	language: "Thai",

	titleWithholdingTaxAttachmentPnd: "ใบแนบ ภ.ง.ด. (รายการหักภาษี)",
	taxType: "แบบ ภ.ง.ด.",
	validateNoTaxTypeSelect: "กรุณาเลือกเลือกแบบ ภ.ง.ด.",
	position: "ตำแหน่ง",
	submissionDate: "วันที่ยื่น",
	portraitPage: "แนวตั้ง",
	landscapePage: "แนวนอน",
	titleWithholdingTaxCoverPage: "ใบยื่น ภ.ง.ด. (ใบปะหน้า)",
	payMonth: "เดือนที่จ่ายเงินได้พึ่งประเมิน",
	validatePayMonthSelect: "กรุณาเลือกเดือนที่จ่ายเงินได้พึ่งประเมิน",
	law: "นำส่งภาษีตาม",
	pnd3Law1: "มาตรา 3 เตรส",
	pnd3Law2: "มาตรา 48 ทวิ",
	pnd3Law3: "มาตรา 50 (3) (4) (5)",
	pnd53Law1: "มาตรา 3 เตรส แห่งประมวลรัษฎากร",
	pnd53Law2: "มาตรา 65 จัตวา แห่งประมวลรัษฎากร",
	pnd53Law3: "มาตรา 69 ทวิ แห่งประมวลรัษฎากร",
	taxFilingType: "ลักษณะการยื่นภาษี",
	taxFilingType1: "ยื่นปกติ",
	taxFilingType2: "ยื่นเพิ่มเติม",
	pageNumber: "จำนวนแผ่น",
	time: "ยื่นเพิ่มเติมครั้งที่",
	titleWithholdingTaxText: "ยื่นออนไลน์ (ส่งออกไฟล์ข้อความ)",
	downloadText: "Download Text",
	status: "สถานะ",
	bankPayoutStart: "สมุดบัญชีเริ่มต้น",
	bankPayoutEnd: "สมุดบัญชีสิ้นสุด",
	dateType: "วันที่ค้นหา",
	sortBy: "เรียงตาม",
	validateType: "กรุณาเลือกประเภท",
	taxDueDateStart: "ตั้งแต่วันที่นำส่งภาษี",
	taxDueDateEnd: "ถึงวันที่นำส่งภาษี",
	validateTaxDueDateSelect: "กรุณาเลือกวันที่นำส่งภาษี",

	atDate: "ณ วันที่",
	annualClosing: "แสดงรายการปิดงวด",
	invoiceDateStart: "วันที่ใบแจ้งหนี้ตั้งแต่",
	unitStart: "ยูนิตตั้งแต่",
	productStart: "รายรับตั้งแต่",
	penaltyStart: "ค่าปรับตั้งแต่",
	validateNoProductSelect: "กรุณาเลือกรายรับ",
	receiptInformation: "ข้อมูลการรับชำระ",
	receiveDateAt: "รับชำระถึงวันที่",
	receiveAll: "ใบเสร็จรับชำระจริงทั้งหมด",
	receiveDate: "ใบเสร็จรับชำระถึงวันที่",
	periodStart: "งวดตั้งแต่",

	creditNoteDateStart: "วันที่ใบลดหนี้ ตั้งแต่",
	accountPeriodStart: "งวดบัญชี ตั้งแต่",

	bookBankNumberStart: "เลขที่บัญชี ตั้งแต่",
	checkedAllCode: "แสดงทุกเลขที่",
	invoiceCodeStart: "เลขที่เอกสาร ตั้งแต่",
	cashType: "ประเภท",
	customerStart: "รหัสลูกค้า ตั้งแต่",
	validateCustomer: "กรุณาเลือกลูกค้า",
	validateBank: "กรุณาเลือกบัญชีธนาคาร",
	date: "วันที่เอกสาร",
	dueDate: "วันที่ครบกำหนด",
	toBankDate: "วันที่นำฝาก",
	passDate: "วันที่ผ่าน",
	returnDate: "วันที่คืน",
	typeRefDoc: "เฉพาะที่ไม่มีใบแจ้งหนี้",
	paperType: "ประเภทกระดาษ",
	documentDateStart: "วันที่เอกสาร ตั้งแต่",
	
	showProduct: "แสดงรายรับ",
	accountStatement: "แจกแจงรายการบัญชี",
	receiptableDate: "ลูกหนี้ยกมา ณ วันที่",
	ignoreCancelDate: "ไม่สนวันที่ยกเลิก",
    pending: "จำนวนเงินที่ค้าง มากกว่า (บาท)",
    overDueDay: "จำนวนวันที่เกิดกำหนด มากกว่า (วัน)",
	captionCheckboxPrintZero: "พิมพ์มูลค่า 0 บาท",
	showDocumentCode: "แสดงเลขที่เอกสาร",
	showPenalty: "แสดงค่าปรับ/เงินเพิ่ม",

	createReportToPdf: "สร้างรายงานเป็น PDF",
	createReportToExcel: "สร้างรายงานเป็น Excel",
	
	unitReportType: "ประเภทรางาน",
	unitReportTypeByUnit: "ห้อง",
	unitReportTypeByAddressNo: "บ้านเลขที่",
	unitReportUnitCodeStart: "ยูนิตเริ่มต้น",	
	unitReportUnitCodeEnd: "ถึง",
	unitReportBuilding: "อาคาร",
	unitReportBuildingPlaceholder: "เลือกอาคาร",
	unitReportUnitType: "ประเภทห้องชุด",
	unitReportCustomerType: "ประเภทสมาชิก",
	unitReportAddressType: "ประเภทที่อยู่",
	unitReportPlaceholderAll: "ทั้งหมด",
	unitReportPlaceholderUnit: "เลือกยูนิต",
	unitReportPlaceholderBuilding: "เลือกอาคาร",
	unitReportUnitType1: "โอนแล้ว-อยู่เอง",
	unitReportUnitType2: "โอนแล้ว-ยังไม่เข้าพักอาศัย",
	unitReportUnitType3: "โอนแล้ว-ปล่อยเช่า",
	unitReportUnitType4: "ยังไม่โอน",
	unitReportUnitType5: "เช่าพื้นที่ส่วนกลาง/ร้านค้า",
	unitReportCustomerType1: "เจ้าของ 1",
	unitReportCustomerType2: "เจ้าของ 2",
	unitReportCustomerType3: "เจ้าของ 3",
	unitReportCustomerType4: "ผู้เช่า",	
	unitReportAddressType1: "ที่อยู่ตามบัตรประชาชน",
	unitReportAddressType2: "ที่อยู่ที่ติดต่อได้",
	unitReportAddressType3: "ที่อยู่แจ้งหนี้",
	type1: "แบบที่ 1",
	type2: "แบบที่ 2",
	cancelDate: "วันที่ยกเลิก",
	receiptDate: "วันที่ใบเสร็จ",
	from: "ตั้งแต่",
    group:"รวมรายการ", 
	receiptDateStart: "วันที่ใบเสร็จตั้งแต่",
	bankDateStart: "วันที่เงินเข้าธนาคารตั้งแต่",
	bookbank: "สมุดบัญชีธนาคาร",
	dear: "เรียน",
	signature: "ข้อความส่วนลงนาม",
	dataType: "ตัวเลือกการแสดงข้อมูล",
    ratio: "อัตราส่วนกรรมสิทธิ์",
    area: "พื้นที่",
	fileName: "ชื่อไฟล์",

	saveMessageSuccess: "คุณบันทึกข้อความสำเร็จ",
	isIgnoreZero: "แสดงเฉพาะรายการที่มีมูลค่าคงเหลือ",

	outstandingAgingByMonthType: "รูปแบบการแสดงรายการบัญชี",
	typeDetailByUnit: "แบบแจกแจง",

	budgetYearCurrent: "ปีงบประมาณ (ปีตั้งต้น)",
	budgetYearCompare: "ปีงบประมาณ (ปีที่จะเปรียบเทียบ)",
	validateCodeStart: "กรุณาระบุเลขที่เริ่มต้น",
	validateCodeEnd: "กรุณาระบุเลขที่สิ้นสุด"
};
