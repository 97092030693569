<template>
  <base-toolbar
    :title="lang.titleList"
    :dataButtons="toolbarDataButtons"
    @goBack="goBack"
    @gotoCreate="gotoEdit('create')"
  ></base-toolbar>

  <base-container>
    <base-reference-document-dialog
      v-model:visible="showReferenceDialog"
      @close="showReferenceDialog = false"
      :dataTable="dataReference"
      :columns="columnsReferenceDialog"
      :title="lang.signedDocument"
    ></base-reference-document-dialog>
    <div
      class="surface-card shadow-2 p-5 border-round mt-5 mb-5"
      :class="textFormClass"
    >
      <div class="flex flex-wrap justify-content-between align-items-center">
        <label class="font-lg font-normal">{{ lang.titleList }}</label>
      </div>

      <PvDivider></PvDivider>

      <div class="p-datatable-border my-2">
        <PvDataTable
          :value="signatures"
          v-model:selection="selectedSignature"
          selectionMode="single"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-xs"
          :class="textTableClass"
          :loading="isLoading"
          @row-dblclick="gotoEdit(selectedSignature.id)"
          scrollable
        >
          <template #empty>
            <div class="w-full p-3" style="text-align: center">
              <label>{{ lang.tableEmpty }}</label>
            </div>
          </template>

          <PvColumn
            field="position"
            :header="lang.position"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />
          <PvColumn
            field="name"
            :header="lang.signatorName"
            style="min-width: 10rem; height: 56px"
            headerClass="h-left"
            bodyClass="text-left"
            sortable
          />

          <PvColumn
            style="max-width: 10rem"
            headerClass="h-center"
            bodyStyle="text-align: center; justify-content: center; align-items: center;"
            alignFrozen="right"
            frozen
          >
            <template #body="slotProps">
              <PvButton
                type="button"
                class="p-button-secondary p-button-text p-0 mr-3"
                @click="gotoEdit(slotProps.data.id)"
              >
                <icon-pen-edit iconColor="secondary" width="24" height="24" />
              </PvButton>
              <PvButton
                type="button"
                class="p-button-secondary p-button-text py-0 px-2"
                @click="openReferenceDocument(slotProps.data.id)"
              >
                <icon-attachment-link
                  width="24"
                  height="24"
                ></icon-attachment-link>
              </PvButton>
            </template>
          </PvColumn>

          <template #footer>
            <PvPaginator
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              @page="onPageChange($event)"
              :rowsPerPageOptions="[10, 50, 100]"
              class="p-pagination-radius"
              :first="showCurrentPage"
            >
              <template #start="slotProps">
                {{ lang.page }}:
                {{ totalPage ? slotProps.state.page + 1 : 0 }}/{{ totalPage }}
              </template>
            </PvPaginator>
          </template>
        </PvDataTable>
      </div>
    </div>
  </base-container>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import { useStore } from "vuex";
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js";
import BaseDialog from "../../../components/UI/BaseDialog.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
export default {
  props: ["projectCode"],
  setup() {
    const router = useRouter();
    const store = useStore();
    const { lang } = useLang();
    const { textTableClass, textFormClass } = useTextStyle();
    const { getRoleByMenuByCode, isSharedRoleByMenuReady } = useGetRoleByMenu();

    let dataConfigDialog = ref(null);
    let isLoading = ref(true);
    let selectedSignature = ref(null);
    let selectedPage = ref(1);
    let rowsPerPage = ref(10);
    let showReferenceDialog = ref(false);

    onMounted(async () => {
      isLoading.value = false;
      initiateData();
      await roleByMenuDataReady();
    });

    //computeds
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.signatureManagement.signature.listing,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.signatureManagement.signature.listing,
        };
      }
    });
    const toolbarDataButtons = computed(() => {
      let dataButtons = [
        {
          id: "create",
          caption: mergeLang.value.createSignator,
          class: "mr-2",
          icon: "icon-plus-add",
          iconColor: "white",
          action: "gotoCreate",
          isShow: true,
        },
      ];
      return dataButtons;
    });
    const roleByMenu = computed(() => {
      return getRoleByMenuByCode("pms_rpt_signature");
    });
    const isRoleCreate = computed(() => {
      if (roleByMenu.value && roleByMenu.value.insert == "Y") {
        return true;
      }
      return false;
    });
    const isRoleSelect = computed(() => {
      if (roleByMenu.value && roleByMenu.value.select == "Y") {
        return true;
      }
      return false;
    });
    const selectedProject = computed(() => {
      return store.getters.selectedProject;
    });
    const signatures = computed(() => {
      return store.getters["signature/signatures"];
    });
    const totalPage = computed(() => {
      return store.getters["signature/totalPage"];
    });
    const totalRecords = computed(() => {
      return totalPage.value * rowsPerPage.value;
    });
    const showCurrentPage = computed(() => {
      let page = 0;
      page = rowsPerPage.value * (selectedPage.value - 1);
      return page;
    });
    const payloadGetDatas = computed(() => {
      return {
        page: selectedPage.value,
        limit: rowsPerPage.value,
        projectId: selectedProject.value.id,
      };
    });
    const dataReference = computed(() => {
      return store.getters["signature/signatureReference"];
    });
    const columnsReferenceDialog = computed(() => {
      return [
        {
          field: "reportMenuDetail",
          header: mergeLang.value.reportName,
          type: "string",
          style: "width: 10%;height: 56px;",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
          sortable: true,
        },
        {
          field: "reportMenu",
          header: mergeLang.value.reportMainMenu,
          type: "string",
          style: "width: 10%;height: 56px;",
          headerClass: "h-left font-sm font-normal",
          bodyClass: "text-left p-text-secondary",
        },
      ];
    });

    //methods
    const setLoading = (bool) => {
      store.dispatch("setLoading", { value: bool });
    };
    const roleByMenuDataReady = async () => {
      if (isSharedRoleByMenuReady.value) {
        await getSignatures();
      }
    };
    const goBack = () => {
      router.push({ name: "home" });
    };
    const gotoEdit = (id) => {
      if (id == "create" && !isRoleCreate.value) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.validateRoleInsert,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        return true;
      } else if (id != "create" && !isRoleSelect.value) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.validateRoleSelect,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        return true;
      }

      router.push({ name: "signature-edit", params: { id: id } });
    };
    const setDialog = (dataConfig) => {
      const classMap = {
        success: "p-text-success",
        error: "p-error",
      };

      dataConfig.classTitle = classMap[dataConfig.classTitle] || "";

      dataConfig.button = [
        {
          id: "close",
          caption: mergeLang.value.close,
          class: "p-button-outlined p-button-secondary w-full",
          action: "cancel",
        },
      ];

      if (dataConfig.type === "confirm") {
        dataConfig.button.push({
          id: "confirm",
          caption: mergeLang.value.buttonDialogConfirm,
          class: "w-full",
          action: "confirm",
        });
      }

      dataConfigDialog.value = {
        titleConfirm: dataConfig.title,
        classTitle: dataConfig.classTitle,
        wordingConfirm: dataConfig.message,
        dataButtons: dataConfig.button,
        display: true,
      };
    };
    const initiateData = () => {
      dataConfigDialog.value = null;
      selectedSignature.value = null;
      selectedPage.value = 1;
      rowsPerPage.value = 10;
      showReferenceDialog.value = false;
    };
    const getSignatures = async () => {
      isLoading.value = true;
      try {
        // console.log(payloadGetDatas.value);
        await store.dispatch("signature/getSignatures", payloadGetDatas.value);
      } catch (error) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: error,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        isLoading.value = false;
      }
      isLoading.value = false;
    };
    const onPageChange = async (event) => {
      if (event.rows != rowsPerPage.value) {
        selectedPage.value = 1;
      } else {
        selectedPage.value = event.page + 1;
      }
      rowsPerPage.value = event.rows;
      await getSignatures();
    };
    const openReferenceDocument = async (id) => {
      if (!isRoleSelect.value) {
        setDialog({
          classTitle: "",
          title: mergeLang.value.notificationDialogHeader,
          message: mergeLang.value.validateRoleSelect,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
        return true;
      }
      setLoading(true);

      try {
        await store.dispatch("signature/getSignatureReferences", id);

        setLoading(false);
        showReferenceDialog.value = true;
      } catch (error) {
        setDialog({
          classTitle: "error",
          title: mergeLang.value.notificationDialogHeader,
          message: error,
        });
        createConfirmDialog(BaseDialog, dataConfigDialog.value).reveal();
      }
    };

    return {
      lang: mergeLang,
      textTableClass,
      textFormClass,

      isLoading,
      selectedSignature,
      rowsPerPage,

      //computeds
      toolbarDataButtons,
      signatures,
      totalPage,
      totalRecords,
      showCurrentPage,
      dataReference,
      columnsReferenceDialog,
      showReferenceDialog,

      //methods
      roleByMenuDataReady,
      goBack,
      gotoEdit,
      onPageChange,
      openReferenceDocument,
    };
  },
  watch: {
    isSharedRoleByMenuReady() {
      this.roleByMenuDataReady();
    },
  },
};
</script>
