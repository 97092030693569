export default {
  async getCreditNotes(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/CreditNote";

    if (!payload.limit) {
      url = url + "?limit=10";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.where) {
      url = url + "&where=" + JSON.stringify(payload.where);
    }

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get credit note");
      throw error;
    }

    context.commit("setCreditNotes", responseData);
  },
  async getCreditNote(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/CreditNote";

    if (payload) {
      url = url + "/" + payload;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get credit note");
      throw error;
    }
    // console.log(responseData.data);

    context.commit("setSelectedCreditNote", responseData.data);
  },
  resetCreditNote(context) {
    context.commit("setSelectedCreditNote", null);
  },
  async getCreditNoteReferenceDocument(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL + "/ReferenceDocument/CreditNote";

    if (!payload.limit) {
      url = url + "?limit=100";
    } else {
      url = url + "?limit=" + payload.limit;
    }

    if (payload.page) {
      url = url + "&page=" + payload.page;
    }

    if (payload.projectId) {
      url = url + "&projectId=" + payload.projectId;
    }

    if (payload.id) {
      url = url + "&id=" + payload.id;
    }
		url = encodeURI(url);

    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      const error = new Error(responseData.message || "Failed to get credit note reference");
      throw error;
    }
    // console.log(responseData);

    // const data = responseData.data;
    if (responseData.data.length > 0) {
      context.commit("setCreditNoteReferenceDocument", responseData.data);
    }
  },
  async printCreditNote(context, payload) {
    let url = process.env.VUE_APP_BACKEND_URL +"/CreditNote/Print";
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(payload),
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      
    });  
    
    let urlBlob = ''
    let blob = await response.blob();
    urlBlob = window.URL.createObjectURL(blob);
    context.commit('setBlob', urlBlob);
  },
  async cancelCreditNote(context,payload){
    let url = process.env.VUE_APP_BACKEND_URL + `/CreditNote/Cancel`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      // console.log(responseData);
      let message = null
      if(Object.prototype.hasOwnProperty.call(responseData, 'errors')){
        message = responseData.title;
        for (const key in responseData.errors) {
          message += ", " + key.replace("$.","")
        }
      } else if(Object.prototype.hasOwnProperty.call(responseData, 'message')){
        message = responseData.message
      }
      const error = new Error(message || 'Failed to cancel credit note');
      throw error;
    }
    
    context.commit("setSelectedCreditNote", responseData.data);
  },
  async createOrUpdateCreditNote(context, payload){
    let url = process.env.VUE_APP_BACKEND_URL + `/CreditNote/${payload.type}`;
    const token = context.rootGetters.token;

    const response = await fetch(url, {
      method: payload.type === 'Add' ? 'post' : 'put',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw responseData;
    }
    // console.log(responseData);

    context.commit("setSelectedCreditNote", responseData.data);
  },
};
