export default {
	title: "ผังบัญชี",
	titleListing: "รายการผังบัญชี",
	createAccountChart: "สร้างผังบัญชี",
	all: "ทั้งหมด",
	accountCode: "รหัสผังบัญชี",
	accountName: "ชื่อผังบัญชี",
	searchTextPlaceholder: "กรอกข้อมูลเพื่อค้นหา",
	accountNameTh: "ชื่อผังบัญชี (ภาษาไทย)",
	accountNameEn: "ชื่อผังบัญชี (ภาษาอังกฤษ)",
	parentCode: "รหัสบัญชีคุม",
	active: "ใช้งาน",
    inActive: "ไม่ใช้งาน",
};
