<template>
  <PvDialog
    :breakpoints="{ '1280px': '75vw', '640px': '90vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <div class="pt-2 pb-1">
          <label class="font-lg font-normal">
            {{ title }}
          </label>
        </div>
        <PvButton
          icon="pi pi-times"
          class="p-button-link p-icon-secondary"
          @click="closeDialog"
        />
      </div>
      <PvDivider></PvDivider>
    </template>

    <section class="flex flex-wrap" :class="textFormClass">
      <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
        <label for="date">{{ lang.date }}</label>
        <PvCalendar
          id="date"
          v-model="date"
          class="inputfield w-full mt-1"
          showIcon
          dateFormat="dd/mm/yy"
          :class="{ 'p-invalid': dateError }"
          @date-select="dateError = false"
        />
        <small v-if="dateError" id="date-help" class="p-error">{{
          lang.validateDateSelect
        }}</small>
      </div>
      <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
        <label for="dueDate">{{ lang.dueDate }}</label>
        <PvCalendar
          id="dueDate"
          v-model="dueDate"
          class="inputfield w-full mt-1"
          showIcon
          dateFormat="dd/mm/yy"
          :class="{ 'p-invalid': dueDateError }"
          @date-select="dueDateError = false"
        />
        <small v-if="dueDateError" id="dueDate-help" class="p-error">{{
          lang.validateDateSelect
        }}</small>
      </div>
      <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
        <label for="printAmountMoreThan">{{ lang.printAmountMoreThan }}</label>
        <PvInputNumber
          id="printAmountMoreThan"
          class="inputfield w-full mt-1"
          inputClass="text-right"
          v-model="printAmountMoreThan"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="2"
          @focus="$event.target.select()"
        />
      </div>
      <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
        <label for="unitCodeStart">{{ lang.unitCodeStart }}</label>
        <base-unit-auto-complete
          id="unitCodeStart"
          v-model="selectedUnitStart"
          class="inputfield w-full mt-1"
          :isLoadingData="true"
          @load-finish="unitDataReady"
        />
      </div>
      <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
        <label for="unitCodeEnd">{{ lang.unitCodeEnd }}</label>
        <base-unit-auto-complete
          id="unitCodeEnd"
          v-model="selectedUnitEnd"
          class="inputfield w-full mt-1"
          @load-finish="unitDataReady"
        />
      </div>
      <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2 pb-0">
        <label for="fileNameText">{{ lang.fileName }}</label>
        <PvInputText
          id="fileNameText"
          type="text"
          class="inputfield w-full mt-1"
          v-model="fileNameText"
        />
      </div>
    </section>

    <template #footer>
      <div
        class="flex flex-wrap pt-4 pl-3 pr-2"
      >
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-2">
          <PvButton
            icon="pi pi-print"
            :label="lang.print"
            @click="download"
            class="w-full"
          />
        </div>
        <div class="lg:col-4 md:col-4 col-12 pl-0 pr-3 pb-0">
          <PvButton class="p-button-outlined w-full" @click="printSetting">
            <icon-setting iconColor="primary" width="16" height="16" />
            <span class="p-button-label">{{ lang.setupProduct }}</span>
          </PvButton>
        </div>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import useTracking from "../../../hooks/tracking";
import useReportRequestValidate from "../../../hooks/reportRequestValidate.js";
export default {
  props: {
    reportObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "toast-request"],
  setup() {
    const { lang } = useLang();
    const { textFormClass } = useTextStyle();
    const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
    const { addReportTracking } = useTracking();
    const { reportPrintOrder } = useReportRequestValidate();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.gl.reportGl,
          ...lang.th.ar.notice.report,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.th.gl.reportGl,
          ...lang.en.ar.notice.report,
        };
      }
    });

    return {
      lang: mergeLang,
      textFormClass,
      formatCurrency,
      formatDate,
      formatDateToISOString,
      addReportTracking,
      reportPrintOrder,
    };
  },
  data() {
    return {
        date: null,
        dateError: false,
        dueDate: null,
        dueDateError: false,
        isSharedUnitReady: false,
        selectedUnitStart: null,
        selectedUnitEnd: null,
        printAmountMoreThan: 0,

        fileNameText: "notice_by_product",
    }
  },
  async mounted() {
    this.setLoading(true);
    await this.initiateData();
  },
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
      this.initiateData();
    },
    async initiateData() {
        this.date = new Date();
        this.dueDate = new Date();
        this.dateError = false;
        this.dueDateError = false;
        this.selectedUnitStart = null;
        this.selectedUnitEnd = null;
        this.printAmountMoreThan = 0;
        this.fileNameText = "notice_by_product";
    },
    unitDataReady() {
        this.isSharedUnitReady = true;
        this.setDataUnit();
    },
    setDataUnit() {
        this.selectedUnitStart = this.unitStart;
        this.selectedUnitEnd = this.unitEnd;

        this.setLoading(false);
    },
    validateData() {
        let returnStatus = false;
        if (this.selectedDate == null) {
            this.dateError = true;
            returnStatus = true;
        }
        if (this.selectedDueDate == null) {
            this.dueDateError = true;
            returnStatus = true;
        }
        return returnStatus;
    },
    async download() {
      if (this.validateData()) {
        return;
      }

      this.setLoading(true);
      try {
        this.addReportTracking(this.payload, this.reportObj, this.selectedProject, this.$route.name);
        const response = await this.reportPrintOrder(this.payload);
        if (response) {
          this.$emit("toast-request");
        }
        this.setLoading(false);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: this.lang.notificationDialogHeader,
          detail: error,
          life: 3000,
        });
        this.setLoading(false);
      }
    },
    printSetting() {
      this.$router.push({name: "report-notice-print-setting"})
    },
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    fileBlob() {
      return this.$store.getters["shared/blob"];
    },
    title() {
      if (this.$store.getters.selectedLanguage === "TH")
        return this.reportObj.nameThai;
      else return this.reportObj.nameEnglish;
    },
    userId() {
      return this.$store.getters.userId;
    },
    userName() {
      return this.$store.getters.userName;
    },
    sharedUnits() {
      let result = this.$store.getters["shared/unitUnits"];
      return result.sort((a, b) => a.code - b.code );
    },
    unitStart() {
      if(this.sharedUnits.length > 0) {
          return this.sharedUnits[0];
      }
      return null;
    },
    unitEnd() {
      let unitCount = this.sharedUnits.length;
      if(this.sharedUnits.length > 0) {
          return this.sharedUnits[unitCount - 1];
      }
      return null;
    },
    selectedDate() {
      let date = null;
      if (this.date) {
        date = this.formatDateToISOString(this.date);
      }
      return date;
    },
    selectedDueDate() {
      let dueDate = null;
      if (this.dueDate) {
        dueDate = this.formatDateToISOString(this.dueDate);
      }
      return dueDate;
    },
    payload() {
        let payload = {
            FileName: this.fileNameText + ".pdf",
            JasperPath: this.reportObj.path + ".pdf",
            DocType: "PDF",
            UserId: this.userId,
            PrintBy: this.userName,
            RequestId: 0,

            ProjectId: this.selectedProject.id,
            Date: this.selectedDate,
            DueDate: this.selectedDueDate,
            UnitCodeStart: this.selectedUnitStart ? this.selectedUnitStart.code : this.unitStart.code,
            UnitCodeEnd: this.selectedUnitEnd ? this.selectedUnitEnd.code : this.unitEnd.code,
            AmountMoreThan: this.printAmountMoreThan,
            LetterBy: "proj",
            IsGroupProduct: "Y",
            IsShowPenalty: "Y",
        }
        return payload;
    },
  },
  watch: {
    reportObj() {
      this.initiateData();
    },
  },
};
</script>
