export default {
    title: "Deposit",
    addDeposit: "Add Deposit",
    dateRange: "Date Range",
    documentCode: "Document Code",
    date: "Document Date",
    unit: "Unit",
    address:"Address no.",
    customerName: "Customer Name",
    searchTextPlaceholder: "Fill up to search.",
    payAmount: "Amount",
    paidAmount: "Paid Amount",
    balance: "Balance",
    columnCreateDate:"Create Date",
    columnUpdateDate:"Update Date", 
    columnCreateBy: "Create By",
    columnUpdateBy: "Update By",
    validateRoleSelect: "You don't have permission to select document.",
    statusAll: "All",
    statusWaitForPaid: "Waiting For Deduct",
    statusDeducted: "Deducted",
    statusCancel: "Cancel",
    documentBookCode: "DocumentBook Code",
    receiptCode: "Receipt Code",
    receiptDate: "Receipt Date",
    titleListing: "Deposit List",
    importDeposit: "Import Deposit",
}