<template>
  <PvDialog
    :breakpoints="{ '1280px': '578px', '640px': '90vw' }"
    :style="{ width: '578px' }"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <div
        class="flex justify-content-between align-items-center flex flex-wrap"
      >
        <h2 class="font-lg font-weight-400">{{ lang.addPenaltyRateStep }}</h2>

        <!-- <PvButton
            icon="pi pi-times"
            class="p-button-link fontsize"
            @click="closeDialog"
          /> -->
      </div>
    </template>
    <PvDivider class="mt-1 mb-3" />
    <section class="flex flex-wrap">
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
        <label>{{ lang.productCode }}</label>
        <PvInputText
          type="text"
          v-model="ProductMethodCode"
          class="inputfield w-full mt-1 disableInputText"
          :placeholder="lang.validateProductMethodCode"
          readonly
        />
      </div>
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
        <label>{{ lang.penaltyCode }}</label>
        <PvInputText
          type="text"
          v-model="PenaltyMethodCode"
          class="inputfield w-full mt-1 disableInputText"
          :placeholder="lang.validatePenaltyMethodCode"
          readonly
        />
      </div>
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
        <label>{{ lang.columndateStart }}</label>
        <PvInputNumber
          id="DateStart"
          class="inputfield w-full mt-1"
          v-model="DateStart"
          :placeholder="lang.validateProductMethodCode"
          @focus="$event.target.select()"
        />
      </div>
      <div class="lg:col-12 md:col-12 col-12 pl-0 pr-2 pt-3">
        <label>{{ lang.columnpenaltyRate }}</label>
        <PvInputNumber
          id="PenaltyRate"
          class="inputfield w-full mt-1"
          v-model="PenaltyRate"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="2"
          :min="0"
          placeholder="0.00"
          @focus="$event.target.select()"
        />
      </div>
    </section>

    <template #footer>
      <!-- <div class="md:text-center mb-5">
          <PvButton
            :label="lang.cancel"
            @click="closeDialog"
            class="p-button-outlined font-weight-400"
            :style="{ color: '#585a5c', width: '240px' }"
          />
          <PvButton
            :label="lang.saveCaption"
            class="font-weight-400"
            @click="SelectedAddress()"
            :style="{ width: '240px' }"
          />
        </div> -->
      <div class="flex flex-wrap">
        <div class="lg:col-6 md:col-6 col-12 pl-2 pr-1 pt-1">
          <PvButton
            @click="closeDialog"
            class="p-button-outlined p-text-secondary w-full"
          >
            <span class="p-button-label p-text-secondary font-normal">{{
              lang.cancel
            }}</span>
          </PvButton>
        </div>
        <div class="lg:col-6 md:col-6 col-12 pl-1 pr-2 pt-1">
          <PvButton
            class="w-full"
            @click="saveData()"
          >
            <span class="p-button-label font-normal">{{
              lang.buttonDialogConfirm
            }}</span>
          </PvButton>
        </div>
      </div>
    </template>
  </PvDialog>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
export default {
  props: ["openFrom", "productCode", "penaltyCode", "dateStartLast", "dataUpdatePenalty"],
  emits: ["close", "penalty-rate-step"],
  setup() {
    const { lang } = useLang();
    const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
    const store = useStore();
    const mergeLang = computed(function () {
      if (store.getters.selectedLanguage === "TH") {
        return {
          ...lang.th.base,
          ...lang.th.master.product,
        };
      } else {
        return {
          ...lang.en.base,
          ...lang.en.master.product,
        };
      }
    });

    return {
      lang: mergeLang,
      textHeaderClass,
      textFormClass,
      textTableClass,
    };
  },
  data() {
    return {
      DateStart: 0,
      PenaltyRate: 0,
      ProductMethodCode: this.productCode,
      PenaltyMethodCode: this.penaltyCode,
    };
  },
  async created() {},
  methods: {
    setLoading(bool) {
      this.$store.dispatch("setLoading", { value: bool });
    },
    closeDialog() {
      this.$emit("close");
    },
    saveData() {
      // console.log("this.dateStartLast=",this.dateStartLast);
      // console.log("this.DateStart=",this.DateStart);
      if (this.dateStartLast >= this.DateStart && !this.dataUpdatePenalty) {
        this.$toast.add({
          severity: "warn", // severity:'success',severity:'info',severity:'warn',severity:'error'
          summary: this.lang.notificationDialogHeader, //TitleHeader
          detail: this.lang.validateValueDataStart + this.dateStartLast ,
          life: 5000, //TimeClose
        });
        return true;
      }
      
      if (this.PenaltyRate < 0) {
        this.$toast.add({
          severity: "warn", // severity:'success',severity:'info',severity:'warn',severity:'error'
          summary: this.lang.notificationDialogHeader, //TitleHeader
          detail: this.lang.validateValuePenaltyRate ,
          life: 5000, //TimeClose
        });
        return true;
      }
      let data = { dateStart: this.DateStart, penaltyRate: this.PenaltyRate };
      this.$emit("penalty-rate-step", data);
      this.$emit("close");
    },
  },
  watch: {
    openFrom() {
      // this.initiateData();
      this.ProductMethodCode = this.productCode;
      this.PenaltyMethodCode = this.penaltyCode;
      this.DateStart = 0;
      this.PenaltyRate = 0;
      if (this.dataUpdatePenalty) {
        this.DateStart = this.dataUpdatePenalty.dateStart;
        this.PenaltyRate = this.dataUpdatePenalty.penaltyRate;
      }
    },
  },
};
</script>

<style scoped>
.p-dialog .p-dialog-header {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

.font-weight-400 {
  font-weight: 400 !important;
}
</style>
