<template>
	<base-form-print-modal
		v-model:visible="showFormPrintModal"
		:search="searchTextPrint"
		@close="showFormPrintModal = false"
		@path-selected="formPrintModalSelected"
	></base-form-print-modal>

	<div class="flex justify-content-between align-items-center">
		<div class="my-3">
			<PvButton
				icon="pi pi-chevron-left"
				:label="lang.back"
				@click="goBack"
				class="mr-2 p-button-secondary"
				v-if="isNotAccounting"
			/>
		</div>
		<h2 :class="textHeaderClass" class="my-0">{{ title }}</h2>
	</div>
	<transition name="fade" mode="out-in">
		<div class="surface-card shadow-2 p-3 border-round">
			<div class="pt-2 pb-1">
				<PvButton
					icon="pi pi-file"
					:label="lang.create"
					@click="createDocument"
					class="p-button-secondary mr-2 mb-2"
				/>
				<PvButton
					icon="pi pi-save"
					:label="lang.save"
					class="mr-2 mb-2"
					@click="save"
					:disabled="isCanceled"
				/>
				<PvButton
					icon="pi pi-file-pdf"
					:label="lang.printPdf"
					class="mr-2 mb-2"
					:disabled="isCreateDocument && isCreateVoucher"
					@click="selectPrintForm"
				/>
				<PvButton
					icon="pi pi-times"
					:label="lang.cancel"
					class="p-button-outlined p-button-danger mr-2 mb-2"
					:disabled="(isCreateDocument && isCreateVoucher) || isCanceled"
					@click="askForCancel"
				/>
			</div>
			<div class="flex flex-wrap" :class="textFormClass">
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="taxBook">{{ lang.taxbook }}</label>
					<base-tax-book-auto-complete
						id="taxBook"
						v-model="selectedTaxBook"
						class="inputfield w-full mt-1"
						@load-finish="taxBookDataReady"
						:disabled="isCanceled"
						:class="{ 'p-invalid': taxBookError }"
						@item-select="selectTaxBook()"
					/>
					<small v-if="taxBookError" id="tax-book-help" class="p-error">{{
						lang.validateNoTaxBookSelect
					}}</small>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="code">{{ lang.columnCode }}</label>
					<PvInputText
						id="code"
						v-model="code"
						type="text"
						class="inputfield w-full mt-1 p input-disable"
						readonly
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="date">{{ lang.taxDate }}</label>
					<PvCalendar
						id="date"
						v-model="date"
						class="inputfield w-full mt-1"
						showIcon
						dateFormat="dd/mm/yy"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="dueDate">{{ lang.taxDueDate }}</label>
					<PvCalendar
						id="dueDate"
						v-model="dueDate"
						class="inputfield w-full mt-1"
						showIcon
						dateFormat="dd/mm/yy"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="supplier">{{ lang.supplier }}</label>
					<base-supplier-auto-complete
						id="supplier"
						v-model="selectedSupplier"
						class="inputfield w-full mt-1"
						@load-finish="supplierDataReady"
						:disabled="isCanceled"
						:class="{ 'p-invalid': supplierError }"
						@item-select="selectSupplier()"
					/>
					<small v-if="supplierError" id="supplier-help" class="p-error">{{
						lang.validateNoSupplierSelect
					}}</small>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="address1">{{ lang.address1 }}</label>
					<PvInputText
						id="address1"
						v-model="address1"
						type="text"
						class="inputfield w-full mt-1 p"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="address2">{{ lang.address2 }}</label>
					<PvInputText
						id="address2"
						v-model="address2"
						type="text"
						class="inputfield w-full mt-1 p"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="address3">{{ lang.address3 }}</label>
					<PvInputText
						id="address3"
						v-model="address3"
						type="text"
						class="inputfield w-full mt-1 p"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="taxId">{{ lang.taxId }}</label>
					<PvInputText
						id="taxId"
						v-model="taxId"
						type="text"
						class="inputfield w-full mt-1 p"
						:disabled="isCanceled"
					/>
				</div>
				
				<div class="lg:col-4 md:col-6 col-12 pl-0 pr-2">
					<label for="status">{{ lang.status }}</label>
					<PvDropdown
						class="inputfield w-full mt-1"
						:options="status"
						optionLabel="name"
						optionValue="code"
						v-model="selectStatus"
					/>
				</div>
			</div>
			<div class="flex flex-wrap" :class="textFormClass">
				<div class="lg:col-12 md:col-12 col-12 flex flex-wrap">
					<div 
						class="lg:col-7 md:col-6 col-12 p-fluid"
						style="text-align: center"
					>
						<label class="text-center">{{ lang.typOfIncome }}</label>
					</div>
					<div 
						class="lg:col-1 md:col-6 col-12 p-fluid"
						style="text-align: center"
					>
						<label class="text-center">{{ lang.rate }}</label>
					</div>
					<div 
						class="lg:col-2 md:col-6 col-12 p-fluid"
						style="text-align: center"
					>
						<label class="text-center">{{ lang.paidAmount }}</label>
					</div>
					<div 
					class="lg:col-2 md:col-6 col-12 p-fluid"
						style="text-align: center"
					>
						<label class="text-center">{{ lang.taxAmount }}</label>
					</div>
				</div>
				<div class="lg:col-7 md:col-6 col-12 p-0 p-fluid">
					<!-- <p class="text-center">{{ lang.typOfIncome }}</p> -->
					<PvInputText
						id="line01Description"
						v-model="line01Description"
						type="text"
						readonly
						class="inputfield w-full mt-1 p"
						:placeholder="lang.line01Description"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-1 md:col-6 col-12 p-0 p-fluid">
					<!-- <p class="text-center">{{ lang.rate }}</p> -->
					<PvInputNumber
						id="line01Rate"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line01Rate"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00%"
						suffix="%"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<!-- <p class="text-center">{{ lang.paidAmount }}</p> -->
					<PvInputNumber
						id="line01PayAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line01PayAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<!-- <p class="text-center">{{ lang.taxAmount }}</p> -->
					<PvInputNumber
						id="line01TaxAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line01TaxAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						disabled
					/>
				</div>
				<div class="lg:col-7 md:col-6 col-12 p-0 p-fluid">
					<PvInputText
						id="line02Description"
						v-model="line02Description"
						type="text"
						readonly
						class="inputfield w-full mt-1 p"
						:placeholder="lang.line02Description"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-1 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line02Rate"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line02Rate"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00%"
						suffix="%"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line02PayAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line02PayAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line02TaxAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line02TaxAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						disabled
					/>
				</div>
				<div class="lg:col-7 md:col-6 col-12 p-0 p-fluid">
					<PvInputText
						id="line03Description"
						v-model="line03Description"
						type="text"
						readonly
						class="inputfield w-full mt-1 p"
						:placeholder="lang.line03Description"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-1 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line03Rate"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line03Rate"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00%"
						suffix="%"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line03PayAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line03PayAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line03TaxAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line03TaxAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						disabled
					/>
				</div>
				<div class="lg:col-7 md:col-6 col-12 p-0 p-fluid">
					<PvInputText
						id="line04Description"
						v-model="line04Description"
						type="text"
						readonly
						class="inputfield w-full mt-1 p"
						:placeholder="lang.line04Description"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-1 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line04Rate"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line04Rate"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00%"
						suffix="%"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line04PayAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line04PayAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line04TaxAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line04TaxAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						disabled
					/>
				</div>
				<div class="lg:col-7 md:col-6 col-12 p-0 p-fluid">
					<PvInputText
						id="line05Description"
						v-model="line05Description"
						type="text"
						readonly
						class="inputfield w-full mt-1 p"
						:placeholder="lang.line05Description"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-1 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line05Rate"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line05Rate"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00%"
						suffix="%"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line05PayAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line05PayAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line05TaxAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line05TaxAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						disabled
					/>
				</div>
				<div class="lg:col-7 md:col-6 col-12 p-0 p-fluid">
					<PvInputText
						id="line06Description"
						v-model="line06Description"
						type="text"
						readonly
						class="inputfield w-full mt-1 p"
						:placeholder="lang.line06Description"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-1 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line06Rate"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line06Rate"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00%"
						suffix="%"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line06PayAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line06PayAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line06TaxAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line06TaxAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						disabled
					/>
				</div>
				<div class="lg:col-7 md:col-6 col-12 p-0 p-fluid">
					<PvInputText
						id="line07Description"
						v-model="line07Description"
						type="text"
						class="inputfield w-full mt-1 p"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-1 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line07Rate"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line07Rate"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00%"
						suffix="%"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line07PayAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line07PayAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line07TaxAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line07TaxAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						disabled
					/>
				</div>
				<div class="lg:col-7 md:col-6 col-12 p-0 p-fluid">
					<PvInputText
						id="line08Description"
						v-model="line08Description"
						type="text"
						class="inputfield w-full mt-1 p"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-1 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line08Rate"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line08Rate"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00%"
						suffix="%"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line08PayAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line08PayAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line08TaxAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line08TaxAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						disabled
					/>
				</div>
				<div class="lg:col-7 md:col-6 col-12 p-0 p-fluid">
					<PvInputText
						id="line09Description"
						v-model="line09Description"
						type="text"
						class="inputfield w-full mt-1 p"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-1 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line09Rate"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line09Rate"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00%"
						suffix="%"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line09PayAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line09PayAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line09TaxAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line09TaxAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						disabled
					/>
				</div>
				<div class="lg:col-7 md:col-6 col-12 p-0 p-fluid">
					<PvInputText
						id="line10Description"
						v-model="line10Description"
						type="text"
						class="inputfield w-full mt-1 p"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-1 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line10Rate"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line10Rate"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00%"
						suffix="%"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line10PayAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line10PayAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						:disabled="isCanceled"
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="line10TaxAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right"
						v-model="line10TaxAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						disabled
					/>
				</div>
				<div class="lg:col-7 md:col-6 col-12 p-0"></div>
				<div class="lg:col-1 md:col-6 col-12 p-0 p-fluid">
					<p class="text-center">{{ lang.totalAmount }}</p>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="payTotalAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right; color:black"
						v-model="payTotalAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						disabled
					/>
				</div>
				<div class="lg:col-2 md:col-6 col-12 p-0 p-fluid">
					<PvInputNumber
						id="taxTotalAmount"
						class="inputfield w-full mt-1"
						inputStyle="text-align: right; color:black"
						v-model="taxTotalAmount"
						mode="decimal"
						:minFractionDigits="2"
						:maxFractionDigits="2"
						placeholder="0.00"
						disabled
					/>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useLang from "../../hooks/lang.js";
import useTextStyle from "../../hooks/textStyle.js";
import useFormat from "../../hooks/format.js";
import useGetFormPrint from "../../hooks/getFormPrint.js";
import useCalculation from "../../hooks/calculation.js";
export default {
	props: ["id", "voucherId", "rvhid", "supplierId"],
	setup() {
		const { lang } = useLang();
		const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
		const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
		const { getFormPrints, searchTextPrint, isSharedFormPrintReady, getPreviewBlob, filteredFormPrints } =
			useGetFormPrint();
        const { round, sum } = useCalculation();
		const store = useStore();
		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return {
					...lang.th.base,
					...lang.th.ap.withholdingTax.listing,
					...lang.th.ap.withholdingTax.edit,
				};
			} else {
				return {
					...lang.en.base,
					...lang.en.ap.withholdingTax.listing,
					...lang.en.ap.withholdingTax.edit,
				};
			}
		});
		return {
			textHeaderClass,
			textTableClass,
			textFormClass,
			lang: mergeLang,
			formatCurrency,
			formatDate,
			formatDateToISOString,
			getFormPrints,
			searchTextPrint,
			isSharedFormPrintReady,
			getPreviewBlob,
			filteredFormPrints,
			round,
			sum,
		};
	},
	data() {
		return {
			taxType: "",
			code: "",
			date: null,
			dueDate: null,

			address1: "",
			address2: "",
			address3: "",
			taxId: "",
			remark: "",

			supplier: null,
			selectedSupplier: null,
			isSharedSupplierReady: false,
			supplierError: false,

			selectedTaxBook: null,
			taxBookId: "",
			isSharedTaxBookReady: false,
			taxTypeId: "",
			taxBookError: false,

			// payTotalAmount: 0,
			// taxTotalAmount: 0,
			line01Description: "",
			line01Rate: 0,
			line01PayAmount: 0,
			// line01TaxAmount: 0,
			line02Description: "",
			line02Rate: 0,
			line02PayAmount: 0,
			// line02TaxAmount: 0,
			line03Description: "",
			line03Rate: 0,
			line03PayAmount: 0,
			// line03TaxAmount: 0,
			line04Description: "",
			line04Rate: 0,
			line04PayAmount: 0,
			// line04TaxAmount: 0,
			line05Description: "",
			line05Rate: 0,
			line05PayAmount: 0,
			// line05TaxAmount: 0,
			line06Description: "",
			line06Rate: 0,
			line06PayAmount: 0,
			// line06TaxAmount: 0,
			line07Description: "",
			line07Rate: 0,
			line07PayAmount: 0,
			// line07TaxAmount: 0,
			line08Description: "",
			line08Rate: 0,
			line08PayAmount: 0,
			// line08TaxAmount: 0,
			line09Description: "",
			line09Rate: 0,
			line09PayAmount: 0,
			// line09TaxAmount: 0,
			line10Description: "",
			line10Rate: 0,
			line10PayAmount: 0,
			// line10TaxAmount: 0,

			showFormPrintModal: false,
			path: "",
			dataPrint: [],
			isPrinted: false,
			isNotAccounting: true,
			beforePath: "",
			isCreateVoucher: true,

			WhtId: this.id,
			status: [
				{ name: this.lang.status0, code: "0" },
				{ name: this.lang.status1, code: "1" },
				{ name: this.lang.status2, code: "2" },
				{ name: this.lang.status3, code: "3" },
			],
			selectStatus: "0",
		};
	},
	async created() {
		this.setLoading(true);
		if (!this.isCreateDocument) {
			this.$store.dispatch("apWithholdingTax/resetWithholdingTaxWithVoucher");
			this.isCreateVoucher = false;
			const where = {
				id: this.WhtId,
			};
			await this.$store.dispatch("apWithholdingTax/getWithholdingTax", {
				where,
			});
			this.checkBeforePath();
			// this.setLoading(false);
		} else {
			this.checkBeforePath();
			this.initiateData();
		}

		if (this.rvhid && this.purchaseReceipt) {
			// console.log(this.purchaseReceipt);
			let amount = this.purchaseReceipt.receiptTotalAmount - this.purchaseReceipt.receiptVatamount;
			this.line07PayAmount = amount;
			this.selectedSupplier = this.sharedSuppliers.find(
				(supplier) => supplier.id === this.purchaseReceipt.receiptSupplierId
			);
		}
	},
	methods: {
		setLoading(bool) {
			this.$store.dispatch("setLoading", { value: bool });
		},
		createDocument() {
			this.$swal({
				icon: "info",
				html: `<p class="${this.textAlertClass}">${this.lang.askForCreateNewDocument}</p>`,
				confirmButtonText: this.lang.confirm,
				showCancelButton: true,
				cancelButtonText: this.lang.cancel,
				allowOutsideClick: false,
			}).then((result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					if (!this.isCreateDocument && !this.beforePath) {
						this.$router.replace({ name: "ap-withholding-tax-edit", params: { id: "create" } })
					} else {
						//
					}
					this.initiateData();
				} else if (result.isDenied) {
					//
				}
			});
		},
		goBack() {
			let storageName = `erp_search_withholdingTaxList_${this.selectedProject.code}_${this.userCode}`
			const localSearch = localStorage.getItem(storageName);
			let search = JSON.parse(localSearch);
			search = {
				...search,
				entry: "Entry",
			};
			localStorage.setItem(storageName, JSON.stringify(search));
			this.$router.push({ name: "ap-withholding-tax" })
		},
		checkShouldSetData() {
			if (this.isSharedSupplierReady && this.isSharedTaxBookReady && this.withholdingTax) {
				this.setData();
			}
		},
		setData() {
			this.setLoading(true);
			this.WhtId = this.withholdingTax.id;
			if (!this.isCreateDocument || !this.isCreateVoucher) {
				this.selectedSupplier = this.sharedSuppliers.find(
					(supplier) => supplier.id === this.withholdingTax.customerId
				);
				this.selectedTaxBook = this.sharedTaxBooks.find(
					(taxBook) => taxBook.id === this.withholdingTax.documentBookId
				);
				this.code = this.withholdingTax.code;
				this.date = new Date(this.withholdingTax.date);
				this.dueDate = new Date(this.withholdingTax.dueDate);
				this.address1 = this.withholdingTax.customerAddress1;
				this.address2 = this.withholdingTax.customerAddress2;
				this.address3 = this.withholdingTax.customerAddress3;
				this.taxId = this.withholdingTax.customerTaxId;
				// this.line01Description = "";
				this.line01Rate = this.withholdingTax.line01Rate;
				this.line01PayAmount = this.withholdingTax.line01PayAmount;
				// this.line01TaxAmount = this.withholdingTax.line01TaxAmount;
				// this.line02Description = "";
				this.line02Rate = this.withholdingTax.line02Rate;
				this.line02PayAmount = this.withholdingTax.line02PayAmount;
				// this.line02TaxAmount = this.withholdingTax.line02TaxAmount;
				// this.line03Description = "";
				this.line03Rate = this.withholdingTax.line03Rate;
				this.line03PayAmount = this.withholdingTax.line03PayAmount;
				// this.line03TaxAmount = this.withholdingTax.line03TaxAmount;
				// this.line04Description = "";
				this.line04Rate = this.withholdingTax.line04Rate;
				this.line04PayAmount = this.withholdingTax.line04PayAmount;
				// this.line04TaxAmount = this.withholdingTax.line04TaxAmount;
				// this.line05Description = "";
				this.line05Rate = this.withholdingTax.line05Rate;
				this.line05PayAmount = this.withholdingTax.line05PayAmount;
				// this.line05TaxAmount = this.withholdingTax.line05TaxAmount;
				// this.line06Description = "";
				this.line06Rate = this.withholdingTax.line06Rate;
				this.line06PayAmount = this.withholdingTax.line06PayAmount;
				// this.line06TaxAmount = this.withholdingTax.line06TaxAmount;
				this.line07Description = this.withholdingTax.line07Description;
				this.line07Rate = this.withholdingTax.line07Rate;
				this.line07PayAmount = this.withholdingTax.line07PayAmount;
				// this.line07TaxAmount = this.withholdingTax.line07TaxAmount;
				this.line08Description = this.withholdingTax.line08Description;
				this.line08Rate = this.withholdingTax.line08Rate;
				this.line08PayAmount = this.withholdingTax.line08PayAmount;
				// this.line08TaxAmount = this.withholdingTax.line08TaxAmount;
				this.line09Description = this.withholdingTax.line09Description;
				this.line09Rate = this.withholdingTax.line09Rate;
				this.line09PayAmount = this.withholdingTax.line09PayAmount;
				// this.line09TaxAmount = this.withholdingTax.line09TaxAmount;
				this.line10Description = this.withholdingTax.line10Description;
				this.line10Rate = this.withholdingTax.line10Rate;
				this.line10PayAmount = this.withholdingTax.line10PayAmount;
				// this.line10TaxAmount = this.withholdingTax.line10TaxAmount;
				// this.payTotalAmount = this.withholdingTax.payTotalAmount;
				// this.taxTotalAmount = this.withholdingTax.taxTotalAmount;
				this.selectStatus = this.withholdingTax.status;
			}

			this.setLoading(false);
		},
		initiateData() {
			if (this.supplierId) {
				this.selectedSupplier = this.sharedSuppliers.find(
					(supplier) => supplier.id === this.supplierId
				);
				this.address1 = this.selectedSupplier.address1;
				this.address2 = this.selectedSupplier.address2;
				this.address3 = this.selectedSupplier.address3.trim() + " " + this.selectedSupplier.postCode;
				this.taxId = this.selectedSupplier.taxId;
			} else {
				this.selectedSupplier = null;
				this.address1 = "";
				this.address2 = "";
				this.address3 = "";
				this.taxId = "";
			}
			this.selectedTaxBook = null;
			this.code = "";
			this.date = new Date();
			this.dueDate = new Date();
			// this.line01Description = "";
			this.line01Rate = 0;
			this.line01PayAmount = 0;
			// this.line01TaxAmount = 0;
			// this.line02Description = "";
			this.line02Rate = 0;
			this.line02PayAmount = 0;
			// this.line02TaxAmount = 0;
			// this.line03Description = "";
			this.line03Rate = 0;
			this.line03PayAmount = 0;
			// this.line03TaxAmount = 0;
			// this.line04Description = "";
			this.line04Rate = 0;
			this.line04PayAmount = 0;
			// this.line04TaxAmount = 0;
			// this.line05Description = "";
			this.line05Rate = 0;
			this.line05PayAmount = 0;
			// this.line05TaxAmount = 0;
			// this.line06Description = "";
			this.line06Rate = 0;
			this.line06PayAmount = 0;
			// this.line06TaxAmount = 0;
			this.line07Description = "";
			this.line07Rate = 0;
			this.line07PayAmount = 0;
			// this.line07TaxAmount = 0;
			this.line08Description = "";
			this.line08Rate = 0;
			this.line08PayAmount = 0;
			// this.line08TaxAmount = 0;
			this.line09Description = "";
			this.line09Rate = 0;
			this.line09PayAmount = 0;
			// this.line09TaxAmount = 0;
			this.line10Description = "";
			this.line10Rate = 0;
			this.line10PayAmount = 0;
			// this.line10TaxAmount = 0;

			// this.payTotalAmount = 0;
			// this.taxTotalAmount = 0;
			this.isPrinted = false;

			this.$store.dispatch("apWithholdingTax/resetWithholdingTax");
			this.$store.dispatch("apWithholdingTax/resetWithholdingTaxWithVoucher");
			this.isCreateVoucher = true;
			this.WhtId = "create";
			this.selectStatus = "0";

			if (this.voucherId) {
				// this.isCreateVoucher = true;
				if (this.dataVoucher) {
					// console.log("clearBank", this.dataVoucher);

					this.date = new Date(this.dataVoucher.date);
					this.dueDate = new Date(this.dataVoucher.date);
				}
			}
			this.taxBookError = false;
			this.supplierError = false;
			this.setLoading(false);
		},
		supplierDataReady() {
			this.isSharedSupplierReady = true;
		},
		taxBookDataReady() {
			this.isSharedTaxBookReady = true;
		},
		validateData() {
			if (!this.selectedTaxBook) {
				this.$swal({
					icon: "warning",
					title: this.lang.validateNoTaxBookSelect,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				}).then((result) => {
					/* Read more about isConfirmed, isDenied below */
					if (result.isConfirmed) {
						this.taxBookError = true;
					}
				});
				return true;
			}
			if (!this.selectedSupplier) {
				this.$swal({
					icon: "warning",
					title: this.lang.validateNoSupplierSelect,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				}).then((result) => {
					/* Read more about isConfirmed, isDenied below */
					if (result.isConfirmed) {
						this.supplierError = true;
					}
				});
				return true;
			}
			if (this.payTotalAmount < 0) {
				this.$swal({
					icon: "warning",
					title: this.lang.validateTotalAmount,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				return true;
			}
			return false;
		},
		async save() {
			if (this.validateData()) {
				return;
			}
			this.setLoading(true);
			// console.log("payload", this.payload);
			try {
				await this.getTaxType();
				// console.log("payload", this.payload);
				let payload = this.payload;
				if (this.rvhid) {
					payload = { ...this.payload, ...{ RVHId: this.rvhid } };
				}

				await this.$store.dispatch("apWithholdingTax/createOrUpdateWithholdingTax", payload);
				if (!payload.id && this.rvhid === "create") {
					let withholdingTaxCreateImport = [];
					withholdingTaxCreateImport = [...this.withholdingTaxsImport, ...[this.withholdingTax]];
					await this.$store.dispatch(
						"apWithholdingTax/setWithholdingTaxsImport",
						withholdingTaxCreateImport
					);
				} else {
					let data = this.withholdingTaxsImport;
					let i = data.map((item) => item.id).indexOf(this.withholdingTax.id);
					data[i] = this.withholdingTax;
				}
				// console.log(payload);
				this.$swal({
					icon: "success",
					title: this.lang.saveSuccess,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});

				this.checkBeforePath();

				// if (this.isCreateDocument) {
				if (this.id === "create" && !this.beforePath) {
					this.$router.replace({ name: "ap-withholding-tax-edit", params: { id: this.withholdingTax.id } })
				} 
					// else if (this.id === "create" && this.beforePath){
					// 	this.WhtId = this.withholdingTax.id;
					// 	const where = {
					// 		id: this.withholdingTax.id,
					// 	};
					// 	await this.$store.dispatch("apWithholdingTax/getWithholdingTax", {
					// 		where,
					// 	});
					// 	this.isCreateVoucher = false;

					// 	this.setData();
					// }
					// this.$router.replace(`/ap/withholding-tax/${this.withholdingTax.id}`);
				// }
			} catch (error) {
				this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
			}
			// this.$emit("close");
			this.setLoading(false);
		},
		async askForCancel() {
			const result = await this.$swal({
				icon: "info",
				html: `<p class="${this.textAlertClass}">${this.lang.askForCancelDocument}</p>`,
				confirmButtonText: this.lang.confirm,
				showCancelButton: true,
				cancelButtonText: this.lang.close,
				allowOutsideClick: false,
				confirmButtonColor: "#EF4444",
				cancelButtonColor: "#64748B",
			});

			if (result.isConfirmed) {
				this.setLoading(true);
				if(this.isNotAccounting){
					const where = {id: this.WhtId,};
					try {
						await this.$store.dispatch("apWithholdingTax/getWithholdingTaxWithVoucher", {where,});
						this.setLoading(false);
					} catch (error) {
						this.$swal({
							icon: "error",
							html: `<p class="${this.textAlertClass}">${error}</p>`,
							confirmButtonText: this.lang.close,
							allowOutsideClick: false,
						});
						this.setLoading(false);
					}
					if(this.withholdingTaxWithVoucher){
						// console.log(this.withholdingTaxWithVoucher);
						this.$swal({
							icon: "warning",
							title: this.lang.validateCancel+"\n"+this.withholdingTaxWithVoucher.rvhbookCode+" "+this.withholdingTaxWithVoucher.rvhcode+" "+this.withholdingTaxWithVoucher.glbcode+" "+this.withholdingTaxWithVoucher.voucherCode,
							confirmButtonText: this.lang.close,
							allowOutsideClick: false,
						});
						this.setLoading(false);
						return true;
					}
					else {
						await this.cancel();
						// console.log("if",this.withholdingTaxWithVoucher);
					}

				}
				else {
					await this.cancel();
					// console.log("else");
				}
			} else if (result.isDenied) {
				//
			}
		},
		async cancel() {
			this.setLoading(true);

			try {
				await this.$store.dispatch("apWithholdingTax/cancelWithholdingTax", {
					id: this.WhtId,
					documentBookCode: this.withholdingTax.documentBookCode.trim(),
				});
				this.$swal({
					icon: "success",
					title: this.lang.cancelSuccess,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
			} catch (error) {
				this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
			}
			// if (this.beforePath) {
			//   this.$emit("close");
			// }
			this.setLoading(false);
		},
		async selectPrintForm() {
			if (this.selectedTaxBook.print == "N") {
				this.$swal({
					icon: "warning",
					title: this.lang.validatePrintPermission,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});
				return true;
			} else {
				await this.$store.dispatch("shared/resetFormPrint");
				let where = {
					projectId: this.selectedProject.id,
					documentBookCode: this.selectedTaxBook.code,
				};
				const payload = { where };
				await this.getFormPrints(payload);

				if(this.filteredFormPrints.length == 1) {
					this.path = this.filteredFormPrints[0].path;
					this.PrintWithholdingTax();
				}else{
					this.showFormPrintModal = true;
     	 		}
			}
		},
		formPrintModalSelected(event) {
			this.path = event.path;
			this.PrintWithholdingTax();
			this.showFormPrintModal = false;
		},
		async PrintWithholdingTax() {
			this.setLoading(true);
			const WithholdingTax = {
				id: this.WhtId,
				projectId: this.selectedProject.id,
				path: this.path,
			};

			// console.log("PrintVoucher=", WithholdingTax);
			try {
				await this.$store.dispatch("apWithholdingTax/PrintWithholdingTax", WithholdingTax);
				this.isPrinted = true;
			} catch (error) {
				// console.log("error=", error);
				this.$swal({
					icon: "error",
					html: `<p class="${this.textAlertClass}">${error}</p>`,
					confirmButtonText: this.lang.close,
					allowOutsideClick: false,
				});

				this.isPrinted = false;
				this.setLoading(false);
			}
			let blob = this.$store.getters["apWithholdingTax/blob"];
			// console.log('blob',blob);
			this.getPreviewBlob(blob);

			this.setLoading(false);
		},
		async getTaxType() {
			let taxTypeCode = this.selectedTaxBook.typeCode.trim() + this.selectedTaxBook.code.trim();

			let where = {
				projectId: this.selectedProject.id,
				code: taxTypeCode,
			};

			const payload = {
				page: 1,
				limit: 100,
				where,
			};

			await this.$store.dispatch("shared/getTaxTypes", payload);

			// this.taxTypeId = this.taxTypes.find((taxType) => taxType.code === taxTypeCode);

			this.taxTypes.forEach((data) => {
				if (data.code === taxTypeCode) {
					this.taxBookId = data.id;
				}
			});
		},
		checkBeforePath() {
			this.beforePath =
				this.$route.fullPath.includes("gl/") ||
				this.$route.fullPath.includes("ap/purchase-receipt/");
			if (this.beforePath) {
				this.isNotAccounting = false;
			}
		},
		selectSupplier() {
			this.address1 = this.selectedSupplier.address1;
			this.address2 = this.selectedSupplier.address2;
			this.address3 = this.selectedSupplier.address3.trim() + " " + this.selectedSupplier.postCode;
			this.taxId = this.selectedSupplier.taxId;
			this.supplierError = false;
		},
		selectTaxBook() {
			this.taxBookError = false;
		},
	},
	computed: {
		isCreateDocument() {
			return this.WhtId === "create";
		},
		// isDocumentLocked() {
		//   if (this.withholdingTax) {
		//     if (this.withholdingTax.cancelStatus === "C") {
		//       return true;
		//     }
		//   }
		//   return false;
		// },
		isCanceled() {
			if (this.withholdingTax) {
				if (this.withholdingTax.cancelStatus === "C") {
					return true;
				}
			}
			return false;
		},
		selectedProject() {
			return this.$store.getters.selectedProject;
		},
		userCode() {
			return this.$store.getters.userCode;
		},
		withholdingTax() {
			return this.$store.getters["apWithholdingTax/selectedWithholdingTax"];
		},
		taxTypes() {
			return this.$store.getters["shared/taxTypes"];
		},
		sharedTaxBooks() {
			return this.$store.getters["shared/taxBookTaxBooks"];
		},
		sharedSuppliers() {
			return this.$store.getters["shared/supplierSuppliers"];
		},
		payload() {
			let linkVoucherId = "";
			if (this.voucherId) {
				linkVoucherId = this.voucherId;
			}

			let payload = {
				projectId: this.selectedProject.id,
				documentBookId: this.selectedTaxBook.id,
				documentBookCode: this.selectedTaxBook.code,
				date: this.formatDateToISOString(this.date),
				duedate: this.formatDateToISOString(this.dueDate),
				taxTypeId: this.taxBookId,
				customerId: this.selectedSupplier.id,
				customerName: this.selectedSupplier.prefix ? this.selectedSupplier.prefix + " " + this.selectedSupplier.name : this.selectedSupplier.name,
				customerAddress1: this.address1,
				customerAddress2: this.address2,
				customerAddress3: this.address3,
				customerTaxId: this.taxId,
				payTotalAmount: this.payTotalAmount,
				taxTotalAmount: this.taxTotalAmount,
				line01Description: this.line01Description ? this.line01Description : "",
				line01Rate: this.line01Rate ? this.line01Rate : 0,
				line01PayAmount: this.line01PayAmount ? this.line01PayAmount : 0,
				line01TaxAmount: this.line01TaxAmount ? this.line01TaxAmount : 0,
				line02Description: this.line02Description ? this.line02Description : "",
				line02Rate: this.line02Rate ? this.line02Rate : 0,
				line02PayAmount: this.line02PayAmount ? this.line02PayAmount : 0,
				line02TaxAmount: this.line02TaxAmount ? this.line02TaxAmount : 0,
				line03Description: this.line03Description ? this.line03Description : "",
				line03Rate: this.line03Rate ? this.line03Rate : 0,
				line03PayAmount: this.line03PayAmount ? this.line03PayAmount : 0,
				line03TaxAmount: this.line03TaxAmount ? this.line03TaxAmount : 0,
				line04Description: this.line04Description ? this.line04Description : "",
				line04Rate: this.line04Rate ? this.line04Rate : 0,
				line04PayAmount: this.line04PayAmount ? this.line04PayAmount : 0,
				line04TaxAmount: this.line04TaxAmount ? this.line04TaxAmount : 0,
				line05Description: this.line05Description ? this.line05Description : "",
				line05Rate: this.line05Rate ? this.line05Rate : 0,
				line05PayAmount: this.line05PayAmount ? this.line05PayAmount : 0,
				line05TaxAmount: this.line05TaxAmount ? this.line05TaxAmount : 0,
				line06Description: this.line06Description ? this.line06Description : "",
				line06Rate: this.line06Rate ? this.line06Rate : 0,
				line06PayAmount: this.line06PayAmount ? this.line06PayAmount : 0,
				line06TaxAmount: this.line06TaxAmount ? this.line06TaxAmount : 0,
				line07Description: this.line07Description ? this.line07Description : "",
				line07Rate: this.line07Rate ? this.line07Rate : 0,
				line07PayAmount: this.line07PayAmount ? this.line07PayAmount : 0,
				line07TaxAmount: this.line07TaxAmount ? this.line07TaxAmount : 0,
				line08Description: this.line08Description ? this.line08Description : "",
				line08Rate: this.line08Rate ? this.line08Rate : 0,
				line08PayAmount: this.line08PayAmount ? this.line08PayAmount : 0,
				line08TaxAmount: this.line08TaxAmount ? this.line08TaxAmount : 0,
				line09Description: this.line09Description ? this.line09Description : "",
				line09Rate: this.line09Rate ? this.line09Rate : 0,
				line09PayAmount: this.line09PayAmount ? this.line09PayAmount : 0,
				line09TaxAmount: this.line09TaxAmount ? this.line09TaxAmount : 0,
				line10Description: this.line10Description ? this.line10Description : "",
				line10Rate: this.line10Rate ? this.line10Rate : 0,
				line10PayAmount: this.line10PayAmount ? this.line10PayAmount : 0,
				line10TaxAmount: this.line10TaxAmount ? this.line10TaxAmount : 0,
				voucherId: linkVoucherId,
				type: "Add",
				status: this.selectStatus,
			};
			if (!this.isCreateDocument) {
				payload = { ...payload, id: this.WhtId, type: "Update" };
			}
			return payload;
		},
		title() {
			let result = this.lang.editWht;
			if (this.isCreateDocument) {
				result = this.lang.createWht;
			}
			return result;
		},
		dataVoucher() {
			if (this.voucherId) {
				return this.$store.getters["glAccountingEntry/selectedAccountingEntry"];
			}
			return null;
		},
		purchaseReceipt() {
			return this.$store.getters["apPurchaseReceipt/selectedPurchaseReceipt"];
		},
		withholdingTaxsImport() {
			return this.$store.getters["apWithholdingTax/withholdingTaxsImport"];
		},
		withholdingTaxWithVoucher() {
			return this.$store.getters["apWithholdingTax/selectedWithholdingTaxWithVoucher"];
		},
		line01TaxAmount() {
			let line01TaxAmount = 0;
			line01TaxAmount = (this.line01PayAmount * this.line01Rate) / 100;
			return this.round(line01TaxAmount);
		},
		line02TaxAmount() {
			let line02TaxAmount = 0;
			line02TaxAmount = (this.line02PayAmount * this.line02Rate) / 100;
			return this.round(line02TaxAmount);
		},
		line03TaxAmount() {
			let line03TaxAmount = 0;
			line03TaxAmount = (this.line03PayAmount * this.line03Rate) / 100;
			return this.round(line03TaxAmount);
		},
		line04TaxAmount() {
			let line04TaxAmount = 0;
			line04TaxAmount = (this.line04PayAmount * this.line04Rate) / 100;
			return this.round(line04TaxAmount);
		},
		line05TaxAmount() {
			let line05TaxAmount = 0;
			line05TaxAmount = (this.line05PayAmount * this.line05Rate) / 100;
			return this.round(line05TaxAmount);
		},
		line06TaxAmount() {
			let line06TaxAmount = 0;
			line06TaxAmount = (this.line06PayAmount * this.line06Rate) / 100;
			return this.round(line06TaxAmount);
		},
		line07TaxAmount() {
			let line07TaxAmount = 0;
			line07TaxAmount = (this.line07PayAmount * this.line07Rate) / 100;
			return this.round(line07TaxAmount)
		},
		line08TaxAmount() {
			let line08TaxAmount = 0;
			line08TaxAmount = (this.line08PayAmount * this.line08Rate) / 100;
			return this.round(line08TaxAmount);
		},
		line09TaxAmount() {
			let line09TaxAmount = 0;
			line09TaxAmount = (this.line09PayAmount * this.line09Rate) / 100;
			return this.round(line09TaxAmount);
		},
		line10TaxAmount() {
			let line10TaxAmount = 0;
			line10TaxAmount = (this.line10PayAmount * this.line10Rate) / 100;
			return this.round(line10TaxAmount);
		},
		payTotalAmount() {
			let payTotalAmount = 0;
			payTotalAmount = this.sum([this.line01PayAmount,this.line02PayAmount,this.line03PayAmount,this.line04PayAmount,this.line05PayAmount,this.line06PayAmount,this.line07PayAmount,this.line08PayAmount,this.line09PayAmount,this.line10PayAmount])
			return payTotalAmount;
		},
		taxTotalAmount() {
			let taxTotalAmount = 0;
			taxTotalAmount = this.sum([this.line01TaxAmount,this.line02TaxAmount,this.line03TaxAmount,this.line04TaxAmount,this.line05TaxAmount,this.line06TaxAmount,this.line07TaxAmount,this.line08TaxAmount,this.line09TaxAmount,this.line10TaxAmount])
			return taxTotalAmount;
		},
	},
	watch: {
		isSharedTaxBookReady() {
			this.checkShouldSetData();
		},
		isSharedSupplierReady() {
			this.checkShouldSetData();
		},
		withholdingTax() {
			this.checkShouldSetData();
		},
		// selectedSupplier(curValue, oldValue) {
		// 	if (oldValue === null && curValue) {
		// 		this.address1 = curValue.address1;
		// 		this.address2 = curValue.address2;
		// 		this.address3 = curValue.address3 + " " + curValue.postCode;
		// 		this.taxId = curValue.taxId;
		// 		this.supplierError = false;
		// 	}
		// },
		// selectedTaxBook(curValue, oldValue) {
		// 	if (oldValue === null && curValue) {
		// 		this.taxBookError = false;
		// 	}
		// },
	},
};
</script>

<style>
.swal2-container {
	z-index: 100000 !important;
}
</style>
