export default {
  title: "Forms of book code :",
  addNewFormDetail: "Add new form",
  columnCategory:"Category",
  columnSequence: 'Seq',
  columnName: "Name",
  columnPath: "Path",
  columnBookCode: "Book Code",
  columnProject: "Project",
  isActive:"Is Active",
  columnProjectCode: "Project Code",
};
