<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.3012 7.57399C15.2259 8.03661 15.9752 8.78729 16.4361 9.71288C17.623 12.0067 16.8664 14.827 14.6909 16.2188C12.5153 17.6106 9.63759 17.1152 8.05259 15.0761C6.87009 13.5321 6.67868 11.4464 7.56039 9.71288C8.0223 8.78667 8.77306 8.03591 9.69928 7.57399C9.8552 7.49715 10.0398 7.50676 10.1869 7.59939C10.334 7.69201 10.4225 7.85436 10.4206 8.02818V11.9998H13.5839V8.02818C13.5827 7.8555 13.6706 7.6944 13.8165 7.60202C13.9624 7.50964 14.1456 7.49904 14.3012 7.57399Z"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="12.0001"
      cy="11.9998"
      r="9.00375"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.49899 16.3271V20.6509"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.5012 16.3281V20.6509"
      :class="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 14,
    },
    height: {
      type: [Number, String],
      default: 14,
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    strokeColor() {
      return "p-stroke-" + this.iconColor;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -4px !important;
  margin-right: 4px;
}
</style>
