export default {
	title: "Purchase Order",
	changeBook: "Change Book",

	columnApprove: "Approve",
	columnCancelStatus: "cancel",
	columnPaidStatus: "Status",
	columnCode: "Code",
	columnReferenceDocument: "Reference Document",
	columnDate: "Date",
	columnDueDate: "Document DueDate",
	columnSupplierCode: "Supplier Code",
	columnSupplierName: "Supplier Name",
	columnNetAmount: "Net Amount",
	columnActualPayAmount: "Actual Pay Amount",
	columnCurrencyUnit: "Currency Unit",
};
