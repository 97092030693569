import { useStore } from "vuex";
import { ref, computed, onMounted} from "vue";
export default function useGetAccountCodeEquity() {
  const store = useStore();
  let searchText = ref(null);
  let isShowParent = ref(false);
  

  const accountCodes = computed(() => {
    return store.getters["shared/accountingAccounts"];
  });

  const accountCodeEquityIsLastPage = computed(() => {
    return store.getters["shared/accountingIsLastPage"];
  });

  const filteredAccountCodeEquitys = computed(() => {
    if (!searchText.value) {
      //ช่องว่าง
      const result = [...getAccountCodeEquitys.value];
      return result;
    } else {
      return getAccountCodeEquitys.value.filter((accounting) => {
        return accounting.searchText
          .toLowerCase()
          .includes(searchText.value.toLowerCase());
      });
    }
  });

  const searchAccountCodeEquity = function (event) {
    // console.log(event);
    if (!event.query.trim().length) {
      //ช่องว่าง
      // จำเป็นมาก autocomplete ต้องการการเปลี่ยนแปลงของค่าเซิชเพื่อทำงาน
      searchText.value = "";
      searchText.value = null;
    } else {
      searchText.value = event.query;
    }
  };

  let isSharedAccountCodeEquityReady = ref(false);

  const getAllAccountCodeEquitys = async function () {
    if (!accountCodeEquityIsLastPage.value) {
      await store.dispatch("shared/getAllAccountings");
    }
    isSharedAccountCodeEquityReady.value = true;
  };

  const getAccountCodeEquitys = computed(() => {
    let data = [];
    if(accountCodes.value){       
        let accountCodeEquitys = accountCodes.value.filter((e) => {return (e.code.substring(0, 1) == "3")});        
        accountCodeEquitys.forEach((e) => {
            if(!isShowParent.value){  
                if(e.isDetail == "Y"){     
                    data.push(e); 
                }
            }else{
                data.push(e); 
            }                    
        });
    }
    return data;
  });

  const setShowParent = function(val) {
    isShowParent.value = val;
  };

  onMounted(async () => {
    await getAllAccountCodeEquitys();
  });

  return {
    searchAccountCodeEquity,
    filteredAccountCodeEquitys,
    searchText,
    isSharedAccountCodeEquityReady,
    accountCodes,
    getAllAccountCodeEquitys,
    setShowParent,
  };
}
