import { useStore } from "vuex";
import { computed } from "vue";
export default function useGetImportExistUnit() {

    const store = useStore();

    const params = computed(() => {
        return store.getters["importExistUnit/params"];
    });

    const resetImportExistUnit = () => {
        store.dispatch("importExistUnit/resetImportExistUnits");
    };

    const setImportExistUnitParams = (data, field) => {
        const uniqueDatas = [...new Set(data.map(item => item[field]))];
        store.dispatch("importExistUnit/setParams",uniqueDatas);
    };

    const loadImportExistUnit = async (field = "unitCode",limit = 100) => {
        let payload = {
            field: field,
            limit: limit ,
        };
        if (params.value && params.value.length > 0) {
            await store.dispatch("importExistUnit/getImportExistUnits", payload);
        }
    }    

    const getImportExistUnit = () => {
        return store.getters["importExistUnit/importExistUnits"];
    }

    return {
        resetImportExistUnit,
        setImportExistUnitParams,
        loadImportExistUnit,
        getImportExistUnit,
    };
}