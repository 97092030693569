export default {
  projectData(state) {
    return state.project.data;
  },
  projectCurrentPage(state) {
    return state.project.currentPage;
  },
  projectNextPage(state) {
    return state.project.currentPage + 1;
  },
  projectTotalPage(state) {
    return state.project.totalPage;
  },
  projectIsLastPage(_, getters) {
    return getters.projectCurrentPage === getters.projectTotalPage && getters.projectTotalPage !== 0;
  }
};
