export default {
  setProductGuarantee(state, payload) {
    let productGuarantees = [...state.productGuarantee.productGuarantees];
    for (const productGuarantee of payload.productGuarantees) {
      if(productGuarantees.findIndex(c => c.id === productGuarantee.id) < 0){
        productGuarantees.push(productGuarantee);
      }
    }
    state.productGuarantee.productGuarantees = productGuarantees;
    state.productGuarantee.currentPage = payload.currentPage;
    state.productGuarantee.totalPage = payload.totalPage;
  },
  resetProductGuarantee(state) {
    const productGuarantee = {
      productGuarantees: [],
      totalPage: 0,
      currentPage: 0,
    };
    state.productGuarantee = productGuarantee;
  },
};
