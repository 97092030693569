<template>
	<base-toolbar 
        :title="titleToolBar"
		:dataButtons="dataButtons"  
        @goBack="goBack" 
        @gotoSave="saveData"
		@gotoDelete="confirmDelete"
	></base-toolbar>
	<base-dialog
		v-model:visible="showConfirmDialog"
		:titleConfirm="confirmTitle"
		:wordingConfirm="confirmContent"
		:classTitle="confirmClassTitle"
		:dataButtons="isConfirmDialog ? dataConfirmButtons : dataAlertButtons" 
		@confirm="confirmDialog()"
		@close="closeDialog()"
	>
	</base-dialog>
	<base-container>
		<div class="surface-card shadow-2 p-5 border-round my-5">
			<div class="flex justify-content-between align-items-center">
				<label class="font-lg font-normal">{{ title }}</label>
			</div>
			<PvDivider></PvDivider>
			<section class="flex flex-wrap fontlabel pl-6" :class="textFormClass">
				
				<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
					<!-- <label>{{ lang.labelCode }}</label>  -->
					<div class="flex justify-content-between">
						<label for="code">{{ lang.labelCode }}</label>
						<small style="color: #bebebe" class="mr-2">
							{{ TextLength }}/{{ limitMessage }}</small
						>
					</div>
					<div class="mt-1">
						<PvInputText
							id="code"
							type="text"
							class="inputfield w-full mt-1"
							v-model="code"
							:placeholder="lang.placeholderCode"
							:class="{ 'p-invalid': codeError }" 
							:maxlength="limitMessage"
							@keyup="validateChangeDataAndDisableSaveButton('codeError')"
						/>
						<small v-if="codeError" id="code-help" class="p-error">
							{{ this.isDuplicateCode ? lang.validateDuplicateCode : lang.validateNoneCode }}
						</small>
					</div>
				</div>	

			</section>

			<section class="flex flex-wrap fontlabel pl-6" :class="textFormClass">
				
				<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
					<label>{{ lang.labelNameThai }}</label>
					<div class="mt-1">
						<PvInputText
							type="text"
							class="inputfield w-full mt-1"
							v-model="nameTh"
							:placeholder="lang.placeholderNameThai"
							:class="{ 'p-invalid': nameThaiError }" 
							@keyup="validateChangeDataAndDisableSaveButton('nameThaiError')"
						/>
						<small v-if="nameThaiError" id="nameThai-help" class="p-error">
							{{ lang.validateNameThai }}
						</small>
					</div>
				</div>	

				<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
					<label>{{ lang.labelNameEnglish }}</label>
					<div class="mt-1">
						<PvInputText
							type="text"
							class="inputfield w-full mt-1"
							v-model="nameEn"
							:placeholder="lang.placeholderNameEnglish"
							:class="{ 'p-invalid': nameEnglishError }" 
							@keyup="validateChangeDataAndDisableSaveButton('nameEnglishError')"
						/>
						<small v-if="nameEnglishError" id="nameen-help" class="p-error">
							{{ lang.validateNameEnglish }}
						</small>
					</div>
				</div>	

				<div class="lg:col-6 md:col-6 col-12 pl-0 pr-2 pt-3 lg:pr-4 md:pr-4">
					<label>{{ lang.labelLockDate }}</label>
					<div class="mt-1">
						<PvCalendar
							id="lockDateEnd"
							v-model="lockDateEnd"
							placeholder="DD/MM/YYYY"
							class="inputfield w-full mt-1"
							showIcon
							dateFormat="dd/mm/yy" 
							@date-select="validateChangeDataAndDisableSaveButton"
							@change="validateChangeDataAndDisableSaveButton"
						/>
					</div>
				</div>
				<div class="col-12 pl-0 pr-2 pt-5" v-if="isShowDefaultDate">
					<PvCheckbox
						id="selectedIsDefaultVoucherDate"
						v-model="selectedIsDefaultVoucherDate"
						:binary="true"
						class="overflow-hidden mr-2"
					/>
					<label for="checkboxIsDefaultVoucherDate">
						{{ lang.checkboxIsDefaultVoucherDate }}
					</label>
				</div>

			</section>

			<div v-if="isPettyCashAccountBook" class="col-12 p-0" >
				<base-card
					:titleHeader="lang.settingPettyCashAccount"
					:isVisibleExpandButton="true"
					:isHideBackground="true"
					:isVisibleCard="true"
					class="pb-0"
				>
					<div class="lg:col-6 md:col-6 col-12 pl-0 pr-0 pt-3">
						<label for="pettyCashAccount">{{ lang.pettyCashAccount }}</label>
						<PvSkeleton
							v-if="!isSharedAccountReady"
							class="inputfield w-full mt-1"
							height="39px"
						/>
						<base-accounting-detail-auto-complete
							id="account"
							v-model="selectedPettyCashAccount"
							class="inputfield w-full mt-2"
							@load-finish="accountDataReady"
							:isLoadingData="true"
							v-show="isSharedAccountReady"
							:class="{ 'p-invalid': selectedPettyCashAccountError }"
          					@item-select="selectedPettyCashAccountError = false"
							@is-inactive="selectedPettyCashAccount = null"
							:placeholder="lang.enterPettyCashAccount"
						/>
						<small v-if="selectedPettyCashAccountError" id="selectedPettyCashAccount-help" class="p-error">
							{{ lang.validateSelectedPettyCashAccount }}
						</small>
					</div>

					<div class="field-checkbox mt-3 mb-3">
						<PvCheckbox
							id="isPettyCashUseOtherExpenses"
							v-model="isPettyCashUseOtherExpenses"
							:binary="true"
							@click="showPettyCashUseOtherExpenses"
						/>
						<label for="isPettyCashUseOtherExpenses">{{ lang.showPettyCashUseOtherExpenses }}</label>           
					</div>

					<div v-if="isPettyCashUseOtherExpenses" class="lg:col-6 md:col-6 col-12 pl-0 pr-0 pt-3">
						<label for="pettyCashUseOtherExpensesAcoount">{{ lang.pettyCashUseOtherExpensesAcoount }}</label>
						<PvSkeleton
							v-if="!isSharedAccountReady"
							class="inputfield w-full mt-1"
							height="39px"
						/>
						<base-accounting-detail-auto-complete
							id="account"
							v-model="selectedPettyCashOtherExpensesAccount"
							class="inputfield w-full mt-2"
							@load-finish="accountDataReady"
							v-show="isSharedAccountReady"
							:class="{ 'p-invalid': selectedPettyCashOtherExpensesAccountError }"
          					@item-select="selectedPettyCashOtherExpensesAccountError = false"
							@is-inactive="selectedPettyCashOtherExpensesAccount = null"
							:placeholder="lang.enterPettyCashUseOtherExpensesAcoount"
						/>
						<small v-if="selectedPettyCashOtherExpensesAccountError" id="selectedPettyCashAccount-help" class="p-error">
							{{ lang.validateSelectedPettyCashUseOtherExpensesAcoount }}
						</small>
					</div>
				</base-card>
			</div>

			<!-- <section class="flex flex-wrap fontlabel pt-3" :class="textFormClass">
				
				<div>
					<PvButton 
						class="p-button-outlined p-button-danger" 
						@click="confirmDelete"
						v-if="!isCreateDocument"
					>
						<icon-printer
							class="p-button-outlined mr-2"
							iconColor="error"
							height="16"
							width="16"
						/>
						<span style="font-size: 14px">
							{{ lang.buttonDelete }}
						</span>
					</PvButton>
				</div>

			</section> -->

		</div>
	</base-container> 
</template>

<script>
import useLang from "../../../hooks/lang.js";
import useTextStyle from "../../../hooks/textStyle.js";
import useFormat from "../../../hooks/format.js";
import { useStore } from "vuex";
import { computed } from "vue";
import usePageDirty from "../../../hooks/pageDirty";
import useGetRoleByMenu from "../../../hooks/getRoleByMenu.js"
import _ from 'underscore';
export default {
	props: ["id", "projectCode"],
    setup() {
		const { lang } = useLang();
		const { textHeaderClass, textFormClass, textTableClass } = useTextStyle();
		const { formatCurrency, formatDate, formatDateToISOString } = useFormat();
		const { getRoleByMenuByCode, isSharedRoleByMenuReady }  = useGetRoleByMenu();
		const store = useStore();
		const mergeLang = computed(function () {
			if (store.getters.selectedLanguage === "TH") {
				return {
					...lang.th.base,
					...lang.th.master.accountBook.edit,
				};
			} else {
				return {
					...lang.en.base,
					...lang.en.master.accountBook.edit,
				};
			}
		});
		const { compareObject } = usePageDirty();
		return {
			lang: mergeLang,
			textHeaderClass,
			textFormClass,
			textTableClass,
			formatCurrency,
			formatDate,
			formatDateToISOString,
			compareObject,
			getRoleByMenuByCode,
			isSharedRoleByMenuReady,
		};
	},
	data() {
		return {
			code: "",
			codeError:false,
			limitMessage: 10,
			isDuplicateCode: false,
			nameTh: "",
			nameThaiError: false,
			nameEn: "",
			nameEnglishError: false,
			lockDateEnd: null,
			accountBooks: null,
			objToCompare: null,
			isDisableSaveButton: true,
			isDeleteAccountBook: false,
			showConfirmDialog: false,
			confirmTitle: "",
			confirmContent: "",	
			confirmClassTitle: "", 
			isConfirmDialog: false,
			selectedIsDefaultVoucherDate: false,
			isSharedAccountReady: false,
			selectedPettyCashAccount: null,
			isPettyCashUseOtherExpenses: false,
			selectedPettyCashAccountError: false,
			selectedPettyCashOtherExpensesAccount: null,
			selectedPettyCashOtherExpensesAccountError: false,
		};
	},
	async mounted() {
		this.setLoading(true);
		await this.initiateData();
		await this.getAccountBooks();
		if (!this.isCreateDocument) {
			await this.getAccountBook();
			await this.setData();
			this.deleteAccountBooks(this.id);
		} else {
			await this.initiateData();
		}
		this.setLoading(false);
	},
	methods: {
		async setLoading(bool) {
			this.$store.dispatch("setLoading", { value: bool });
		},
		goBack() {
			if (this.objToCompare) this.compareObject(this.objToCompare, this.payload);
			this.$router.push({name: "account-book"});
		},
		async initiateData() {
			await this.$store.dispatch("masterAccountBook/resetAccountBook");
			this.code = "";
			this.nameTh = "";
			this.nameEn = "";
			this.lockDateEnd = null; // new Date();
			await this.setCompareData();
		},
		async getAccountBooks() {
			try {
				let where = {
					projectId: this.selectedProject.id,
				};
				if (this.searchText != null) {
					where = {
						...where,
						searchText: this.searchText,
					};
				}
				const payload = {
					page: 1,
					limit: 100,
					where,
				};
				await this.$store.dispatch("masterAccountBook/getAccountBooks", payload);
				this.accountBooks = this.$store.getters["masterAccountBook/accountBooks"];
			} catch (error) {
				// this.$toast.add({
				// 	severity: "error",
				// 	summary: this.lang.dialogTitleNotification,
				// 	detail: error,
				// 	life: 3000,
				// });
				this.openDialog({
					severity: "error",
					title: this.lang.dialogTitleNotification,
					content: error,
					isConfirm: false,
				});
			}
		},
		async getAccountBook() {
			try {
				await this.$store.dispatch("masterAccountBook/getAccountBook", this.id);
			} catch (error) {
				// this.$toast.add({
				// 	severity: "error",
				// 	summary: this.lang.dialogTitleNotification,
				// 	detail: error,
				// 	life: 3000,
				// });
				this.openDialog({
					severity: "error",
					title: this.lang.dialogTitleNotification,
					content: error,
					isConfirm: false,
				});
			}
		},
		async setData() {
			if (this.selectedAccountBook) {
				const accountBook = this.selectedAccountBook;
				this.code = accountBook.code.trim();
				this.nameTh = accountBook.nameTh.trim();
				this.nameEn = accountBook.nameEn.trim();
				this.lockDateEnd = accountBook.lockDateEnd ? new Date(accountBook.lockDateEnd) : null;
				this.isPettyCashUseOtherExpenses = accountBook.isPettyCashUseOtherExpenses == "Y" ? true : false;

				if (this.isSharedAccountReady) {
					this.selectedPettyCashAccount = this.sharedAccounts.find((account) => account.id == accountBook.pettyCashAccountId);
					this.selectedPettyCashOtherExpensesAccount = this.sharedAccounts.find((account) => account.id == accountBook.pettyCashOtherExpensesAccountId);
				}
				
				if (this.isShowDefaultDate) {
					this.selectedIsDefaultVoucherDate = accountBook.isDefaultDateByRefDoc && accountBook.isDefaultDateByRefDoc.trim() == "Y" ? true : false;
				}
				await this.setCompareData();
			}
		},
		async setCompareData() {
			let type = "add";
			if (!this.isCreateDocument) {
				type = "update";
			}
			const objToCompare = {
				type: type,
				id: this.id,
				projectId: this.selectedProject.id,
				code: this.code,
				nameTh: this.nameTh,
				nameEn: this.nameEn,
				lockDateEnd: this.selectedLockDate,
				pettyCashAccountId: this.selectedPettyCashAccount ? this.selectedPettyCashAccount.id : null,
				isPettyCashUseOtherExpenses: this.isPettyCashUseOtherExpenses ? "Y" : "N",
				pettyCashOtherExpensesAccountId: this.selectedPettyCashOtherExpensesAccount ? this.selectedPettyCashOtherExpensesAccount.id : null
			};
			if (this.isShowDefaultDate) {
				objToCompare.isDefaultDateByRefDoc = this.selectedIsDefaultVoucherDate ? "Y" : "N";
			}
			this.objToCompare = objToCompare;
			this.validateChangeDataAndDisableSaveButton();
			this.compareObject(objToCompare, this.payload);
		},
		deleteAccountBooks(id){
			const index = this.accountBooks.findIndex((data) => data.id.trim() == id.trim());
			this.accountBooks.splice(index, 1);
		},
		validateChangeDataAndDisableSaveButton(resetValidate) {
			if (!_.isEqual(this.payload, this.objToCompare)) {		
				this.isDisableSaveButton = false;
				if (resetValidate != null || resetValidate != "") {
					if (resetValidate == "codeError") {
						this.codeError = false;
						this.validateDuplicateCode(this.code);
					} else if (resetValidate == "nameThaiError") {
						this.nameThaiError = false;
					} else if (resetValidate == "nameEnglishError") {
						this.nameEnglishError = false;
					}
				}	
			} else {
				this.isDisableSaveButton = true;
			}
		},
		validateDuplicateCode(code) {
			const index = this.accountBooks.findIndex((data) => data.code.trim() == code.trim());
			if (index >= 0) {
				this.codeError = true;
				this.isDuplicateCode = true;
			} else {
				this.codeError = false;
				this.isDuplicateCode = false;
			}		
		},
		wordingValidate() {
			if(!this.code) {
				return this.lang.validateFieldNoValue + this.lang.labelCode.replace("*","");
			}

			if(!this.nameTh) {
				return this.lang.validateFieldNoValue + this.lang.labelNameThai.replace("*","");
			}

			if(this.nameTh.trim().length > 50) {
				return this.lang.validateLimitText.replace("{0}",this.lang.labelNameThai.replace("*",""));
			}

			if(this.nameEn.trim().length > 50) {
				return this.lang.validateLimitText.replace("{0}",this.lang.labelNameThai.replace("*",""));
			}

			if ( this.code != this.objToCompare.code && this.objToCompare.type === "update") {
				const array1 = ["CR","JV","PC1","PC2","PJ","PV","RV","SJ","WT"];
				if (array1.includes(this.objToCompare.code)) {
					return this.lang.validateChangeUniqueCode.replace("{0}",this.objToCompare.code);
				}
      		}

			if (!this.isCharactersOrNumbers(this.code)) {
				return this.lang.validateChangeCode.replace("{0}",this.code);		
			}

			if(this.isPettyCashUseOtherExpenses && !this.selectedPettyCashOtherExpensesAccount) {
				return this.lang.validateSelectedPettyCashUseOtherExpensesAcoount;
			}
		},
		validateData() {
			let returnStatus = false;
			if (!this.code) {
				this.codeError = true;
				returnStatus = true;
			}
			if (!this.nameTh) {
				this.nameThaiError = true;
				returnStatus = true;
			}
			if (this.nameTh.trim().length > 50) {
				this.nameThaiError = true;
				returnStatus = true;
			}
			if (this.nameEn.trim().length > 50) {
				this.nameEnglishError = true;
				returnStatus = true;
			}
			if ( this.code != this.objToCompare.code && this.objToCompare.type === "update") {
				this.codeError = true;
				returnStatus = true;
      		}
			if (!this.isCharactersOrNumbers(this.code)) {
				this.codeError = true;
				returnStatus = true;	
			}
			if(this.isPettyCashUseOtherExpenses && !this.selectedPettyCashOtherExpensesAccount) {
				this.selectedPettyCashOtherExpensesAccountError = true;
				returnStatus = true;
			}

			if(returnStatus) {
				this.typeButtonDialog = "error"
				this.openDialog({
					severity: "error",
					title: this.lang.validateSaveTitle,
					content: this.wordingValidate(),
					isConfirm: false
				});
			}

			return returnStatus;
		},
		isCharactersOrNumbers(str) {
			return /^[A-Za-z0-9*&@!-_]+$/.test(str);		
		},
		validateRole() {
			this.isConfirmDialog = false;
			if(this.isCreateDocument && !this.isRoleCreate) {
				this.confirmClassTitle = "";
				this.confirmTitle = this.lang.notificationDialogHeader
				this.confirmContent = this.lang.validateRoleInsert;
				this.showConfirmDialog = true;
				return true;
			} else if(!this.isCreateDocument && !this.isRoleUpdate) {
				this.confirmClassTitle = "";
				this.confirmTitle = this.lang.notificationDialogHeader
				this.confirmContent = this.lang.validateRoleUpdate;
				this.showConfirmDialog = true;
				return true;
			}
			return false;
		},
		async saveData() {
			if (this.validateRole()) {
				return;
			}
			if (this.validateData()) 
			{
				return;
			}
			try {
				this.setLoading(true);

				let titleMessage = "";
				let contentMessage = "";
				if (this.payload.type === "add") {
					titleMessage = this.lang.dialogTitleAddSuccess;
					contentMessage = this.lang.dialogContentAddSuccess;
				} else {
					titleMessage = this.lang.dialogTitleEditSuccess;
					contentMessage = this.lang.dialogContentEditSuccess;
				}

				await this.$store.dispatch("masterAccountBook/createOrUpdateAccountBook", this.payload);
				if (this.isCreateDocument) {
					this.$router.replace({name: "account-book-edit", params:{id: this.selectedAccountBook.id}});
				}
				await this.$store.dispatch("masterAccountBook/getAccountBook", this.selectedAccountBook.id);
				await this.setCompareData();
				// this.$toast.add({
				// 	severity: "success",
				// 	summary: this.lang.dialogTitleAlert,
				// 	detail: this.lang.saveSuccess,
				// 	life: 5000,
				// });
				this.openDialog({
					severity: "success",
					title: titleMessage,
					content: contentMessage,
					isConfirm: false,
				});
				this.setLoading(false);
			} 
			catch (error) 
			{	
				let message = error.message;
				if (error.code == "122") {
					message = this.lang.validateParams;
				} 
				else if (error.code == "111") {
					message = this.lang.validateRole;
				} 
				else if (error.code == "101") {
					message = this.lang.validateDuplicate;
				} 
				this.openDialog({
					severity: "error",
					title: this.lang.notificationDialogHeader,
					content: message,
					isConfirm: false,
				});
				this.setLoading(false);
			}
			this.setLoading(false);
		},
		async deleteData() {
			this.setLoading(true);
			try {
				this.setLoading(true);
				await this.$store.dispatch("masterAccountBook/deleteAccountBook", this.id);
				this.isDeleteAccountBook = true;
				this.openDialog({
					severity: "success",
					title: this.lang.dialogTitleDeleteSuccess,
					content: this.lang.dialogContentDeleteSuccess,
					isConfirm: false,
				});
				this.setLoading(false);
			} 
			catch (error) 
			{
				let message = error.message;
				if (error.code == "122") {
					message = this.lang.validateParams;
				} 
				else if (error.code == "103") {
					message = this.lang.validateNotFound;
				} 
				else if (error.code == "111") {
					message = this.lang.validateRole;
				} 
				else if (error.code == "126") {
					message = this.lang.validateReference;
				} 
				this.openDialog({
					severity: "error",
					title: this.lang.dialogTitleNotification,
					content: message,
					isConfirm: false,
				});
				this.setLoading(false);
			}
			this.setLoading(false);
		},
		confirmDelete() {
			if (this.isSharedRoleByMenuReady === true && this.roleByMenu.delete === "N") {
				this.openDialog({
					severity: "error",
					title: this.lang.notificationDialogHeader,
					content: this.lang.validateRoleDelete,
					isConfirm: false,
				});
				return;
			}
			if (!this.id && this.id !=="create") 
			{
				this.openDialog({
					severity: "error",
					title: this.lang.dialogTitleNotification,
					content: this.lang.validateNoneId,
					isConfirm: false,
				});
				this.setLoading(false);
				return;
			}
			this.openDialog({
				severity: "",
				title: this.lang.dialogTitleDeleteConfirm,
				content: this.lang.dialogContentDeleteConfirm.replace("{0}",this.code),
				isConfirm: true,
			});
		},
		openDialog(dataConfig) {
			if (dataConfig.severity === "") {
				this.confirmClassTitle = "";
			} else if (dataConfig.severity === "success") {
				this.confirmClassTitle = "p-text-success";
			} else if (dataConfig.severity === "error") {
				this.confirmClassTitle = "p-error";
			}
			this.confirmTitle = dataConfig.title; 
			this.confirmContent = dataConfig.content; 
			if (dataConfig.isConfirm) {
				this.isConfirmDialog = true;
			} else {
				this.isConfirmDialog = false;
			}
			this.showConfirmDialog = true;
		},
		async confirmDialog() {
			await this.deleteData();
		},
		closeDialog() {
			if (this.isDeleteAccountBook) {
				this.$router.push({name: "account-book"});
			}
			this.showConfirmDialog = false;	
		},
		accountDataReady() {
			this.isSharedAccountReady = true;
			this.setData();
		},
		showPettyCashUseOtherExpenses() {
			this.selectedPettyCashOtherExpensesAccount = null;
		},
	},
	computed: {
        title() {
            return this.lang.editTitle;
        },
        selectedProject() {
            return this.$store.getters.selectedProject;
        },
		roleByMenu() {
			return this.getRoleByMenuByCode("tmGLB");
		},
        isCreateDocument() {
            return this.id === "create";
        },
		selectedAccountBook() {
			return this.$store.getters["masterAccountBook/selectedAccountBook"];
		},
		selectedLockDate() {
			let lockDateEnd = null;
			if (this.lockDateEnd) {
				lockDateEnd = this.formatDateToISOString(this.lockDateEnd);
			} else {
				lockDateEnd = null; // this.formatDateToISOString(new Date());
			}
			return lockDateEnd;
		},
		dataButtons() {
			let dataButtons = [
				{
					id: "delete",
					action: "gotoDelete",
					isDisable: this.isCreateDocument,
					type: "delete"
				},
				{
					id: "saveCaption",
					action: "gotoSave",
					// isDisable: this.isDisableSaveButton,
					type: "save"
				},
			]
			return dataButtons
		},
		dataAlertButtons() {
			let dataButtons = [
				{
					id: "close",
					caption: this.lang.buttonDialogSuccess,
					class: "p-button-outlined p-button-secondary w-full",
					action: "close",
				},
			]
			return dataButtons
		},
		dataConfirmButtons() {
			let dataButtons = [
			{
					id: "close",
					caption: this.lang.buttonDialogCancel,
					class: "p-button-outlined p-button-secondary w-full",
					action: "close",
				},
				{
					id: "confirm",
					caption: this.lang.buttonDialogConfirm,
					class: "w-full",
					action: "confirm",
				},
			]
			return dataButtons
		},
		payload() {
			let type = "add";
			let payload = {};
			if (!this.isCreateDocument) {
				type = "update";
			}
			payload = {
				type: type,
				id: this.id,
				projectId: this.selectedProject.id,
				code: this.code,
				nameTh: this.nameTh,
				nameEn: this.nameEn,
				lockDateEnd: this.selectedLockDate,
				pettyCashAccountId: this.selectedPettyCashAccount ? this.selectedPettyCashAccount.id : null,
				isPettyCashUseOtherExpenses: this.isPettyCashUseOtherExpenses ? "Y" : "N",
				pettyCashOtherExpensesAccountId: this.selectedPettyCashOtherExpensesAccount ? this.selectedPettyCashOtherExpensesAccount.id : null
			};
			if (this.isShowDefaultDate) {
				payload.isDefaultDateByRefDoc = this.selectedIsDefaultVoucherDate ? "Y" : "N";
			}
			return payload;
		},
		TextLength() {
			if (this.code) return this.code.length;
			else return 0;
		},
		titleToolBar() {
			let result = this.lang.titleCretae;
			if (!this.isCreateDocument) {
				result = this.lang.titleEdit;
			}
			return result;
		},
		isRoleCreate() {
			if (this.roleByMenu && this.roleByMenu.insert == "Y") {
				return true;
			} else {
				return false;
			}
		},
		isRoleUpdate() {
			if (this.roleByMenu && this.roleByMenu.update == "Y") {
				return true;
			} else {
				return false;
			}
		},
		isRoleDelete() {
			if (this.roleByMenu && this.roleByMenu.delete == "Y") {
				return true;
			} else {
				return false;
			}
		},
		isPettyCashAccountBook() {
			let book = this.selectedAccountBook ? this.selectedAccountBook.code.substring(0,2).toLowerCase() : "";
			
			if(!this.isCreateDocument && book == "pc") {
				return true;
			}
			return false;
		},
		sharedAccounts() {
			return this.$store.getters["shared/accountingDetailAccounts"];
		},
		isShowDefaultDate() {
			if (this.code == "PV" || this.code == "PJ") return true
			return false;
		},
    },
}
</script>