export default {
	title: "สมุดโทรศัพท์",
	titleList: "รายการสมุดโทรศัพท์",
    createPhoneBook: "สร้างสมุดโทรศัพท์",
	namePhoneBook: "ชื่อสมุดโทรศัพท์",
	phone: "เบอร์โทร",
	contactType: "ประเภทผู้ติดต่อ",
	phoneType: "ประเภทเบอร์โทร",
	dropdownSearchPlaceholder: "เลือกข้อมูลเพื่อค้นหา",
	validateDropdownSearchText: "กรุณาเลือกข้อมูลเพื่อค้นหา",
};
